/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { makeStyles, Typography, FormControlLabel, Switch, Button } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { updatePrivacySetting } from 'configurations/redux/userSlice.js';

import { isDesktop } from 'react-device-detect';
import profilePageStyle from 'assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import ProfileStrengthTip from 'views/Page/ProfilePage/ProfileSection/ProfileStrengthTip';
import { useModal } from 'hooks';
import ConfirmDialog from 'components/CustomDialog/ConfirmDialog.js';
import { deactivateEditType } from 'configurations/redux/editProfileModalSlice';

const useStyles = makeStyles(profilePageStyle);
const PrivacySettings = ({ masterExtension, saveDetailModalProps, onCancelChangesConfirm, setIsChanged }) => {
  /*   const { masterExtension, saveDetailModalProps, onCancelChangesConfirm, setIsChanged } = props; */
  const styles = useStyles();
  const initialSettings = useSelector(state => state.user.privacySetting);
  React.useEffect(() => {
    setButtonVisible(false);
  }, []);
  const [buttonVisible, setButtonVisible] = React.useState(false);
  const userStatus = useSelector(state => state.auth.user);
  const isMainModalCloseRequest = useSelector(state => state.editProfileModal.isMainModalCloseRequest);

  const pDispatch = useDispatch();

  const privacyReducer = (state, action) => {
    switch (action.type) {
      case 'CHANGE_PRIVACY_TRUE':
        return state.map(item => {
          if (item.settingName === action.settingName) {
            return { ...item, settingValue: 1 };
          } else {
            return item;
          }
        });
      case 'CHANGE_PRIVACY_FALSE':
        return state.map(item => {
          if (item.settingName === action.settingName) {
            return { ...item, settingValue: 0 };
          } else {
            return item;
          }
        });
      default:
        return state;
    }
  };
  const [setting, dispatch] = React.useReducer(privacyReducer, initialSettings);
  const cancelForm = {
    title: 'Discard Action',
    text: 'All the actions will be discarded',
    cancelText: 'No',
    confirmText: 'Yes',
  };
  const handleChange = e => {
    setButtonVisible(true);
    setIsChanged(true);
    let currentSetting = setting
      .filter(items => {
        if (items.settingName === e.target.name) {
          return items;
        }
      })
      .map(items => {
        return items;
      });
    if (currentSetting[0].settingValue === 1) {
      dispatch({
        type: 'CHANGE_PRIVACY_FALSE',
        settingName: currentSetting[0].settingName,
      });
    } else {
      dispatch({
        type: 'CHANGE_PRIVACY_TRUE',
        settingName: currentSetting[0].settingName,
      });
    }
  };

  return (
    <GridContainer id="modalPrivacy">
      <GridItem xs={12} sm={12} md={12} className={styles.gridItem}>
        {masterExtension && <ProfileStrengthTip extension={masterExtension} />}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <h4 className={styles.title}>Privacy Setting</h4>
          <Button
            style={{ visibility: buttonVisible ? 'visible' : 'hidden' }}
            onClick={() => {
              console.log(setting);
              const requestProfile = {
                email: userStatus.email,
                acctId: userStatus.acctId,
                privacySetting: setting,
                accessToken: localStorage.getItem('token'),
              };
              console.log(requestProfile);
              pDispatch(updatePrivacySetting(requestProfile));
              setButtonVisible(false);
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 24 18">
              <path id="Path" d="M7.636,14.239,1.909,8.6,0,10.478,7.636,18,24,1.881,22.091,0Z" fill="#0ab98d" />
            </svg>
            Submit
          </Button>
        </div>

        <Card className={styles.cardFrame}>
          <CardBody>
            <GridContainer justifyContent="center" className={styles.verticalAligned}>
              <GridItem xs={9} sm={8}>
                <h5 className={styles.title}>Show "Profile" To Public</h5>
              </GridItem>
              <GridItem xs={3} sm={2}>
                <div className={styles.switchCenter}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={setting[0].settingValue === 1}
                        onChange={handleChange}
                        name="showProfile"
                        styles={{
                          root: styles.switchRoot,
                          switchBase: styles.switchBase,
                          checked: styles.switchChecked,
                          thumb: styles.switchIcon,
                          track: styles.switchBar,
                        }}
                      />
                    }
                  />
                </div>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        <Card className={styles.cardFrame}>
          <CardBody>
            <GridContainer justifyContent="center" className={styles.verticalAligned}>
              <GridItem xs={9} sm={8}>
                <h5 className={styles.title}>Show "Education" To Public</h5>
              </GridItem>
              <GridItem xs={3} sm={2}>
                <div className={styles.switchCenter}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={setting[0].settingValue === 1 ? setting[1].settingValue === 1 : false}
                        onChange={handleChange}
                        name="showEdu"
                        disabled={setting[0].settingValue === 1 ? false : true}
                        styles={{
                          root: styles.switchRoot,
                          switchBase: styles.switchBase,
                          checked: styles.switchChecked,
                          thumb: styles.switchIcon,
                          track: styles.switchBar,
                        }}
                      />
                    }
                  />
                </div>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        <Card className={styles.cardFrame}>
          <CardBody>
            <GridContainer justifyContent="center" className={styles.verticalAligned}>
              <GridItem xs={9} sm={8}>
                <h5 className={styles.title}>Show "Headline" To Public</h5>
              </GridItem>
              <GridItem xs={3} sm={2}>
                <div className={styles.switchCenter}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={setting[0].settingValue === 1 ? setting[5].settingValue === 1 : false}
                        onChange={handleChange}
                        name="showHeadline"
                        disabled={setting[0].settingValue === 1 ? false : true}
                        styles={{
                          root: styles.switchRoot,
                          switchBase: styles.switchBase,
                          checked: styles.switchChecked,
                          thumb: styles.switchIcon,
                          track: styles.switchBar,
                        }}
                      />
                    }
                  />
                </div>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        <Card className={styles.cardFrame}>
          <CardBody>
            <GridContainer justifyContent="center" className={styles.verticalAligned}>
              <GridItem xs={9} sm={8}>
                <h5 className={styles.title}>Show "Contact Number" To Public</h5>
              </GridItem>
              <GridItem xs={3} sm={2}>
                <div className={styles.switchCenter}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={setting[0].settingValue === 1 ? setting[6].settingValue === 1 : false}
                        onChange={handleChange}
                        name="showContactNo"
                        disabled={setting[0].settingValue === 1 ? false : true}
                        styles={{
                          root: styles.switchRoot,
                          switchBase: styles.switchBase,
                          checked: styles.switchChecked,
                          thumb: styles.switchIcon,
                          track: styles.switchBar,
                        }}
                      />
                    }
                  />
                </div>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        <Card className={styles.cardFrame}>
          <CardBody>
            <GridContainer justifyContent="center" className={styles.verticalAligned}>
              <GridItem xs={9} sm={8}>
                <h5 className={styles.title}>Show "Experience" To Public</h5>
              </GridItem>
              <GridItem xs={3} sm={2}>
                <div className={styles.switchCenter}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={setting[0].settingValue === 1 ? setting[2].settingValue === 1 : false}
                        onChange={handleChange}
                        name="showExp"
                        disabled={setting[0].settingValue === 1 ? false : true}
                        styles={{
                          root: styles.switchRoot,
                          switchBase: styles.switchBase,
                          checked: styles.switchChecked,
                          thumb: styles.switchIcon,
                          track: styles.switchBar,
                        }}
                      />
                    }
                  />
                </div>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        <Card className={styles.cardFrame}>
          <CardBody>
            <GridContainer justifyContent="center" className={styles.verticalAligned}>
              <GridItem xs={9} sm={8}>
                <h5 className={styles.title}>Show "Profile Picture" To Public</h5>
              </GridItem>
              <GridItem xs={3} sm={2}>
                <div className={styles.switchCenter}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={setting[0].settingValue === 1 ? setting[3].settingValue === 1 : false}
                        onChange={handleChange}
                        name="showProfilePic"
                        disabled={setting[0].settingValue ? false : true}
                        styles={{
                          root: styles.switchRoot,
                          switchBase: styles.switchBase,
                          checked: styles.switchChecked,
                          thumb: styles.switchIcon,
                          track: styles.switchBar,
                        }}
                      />
                    }
                  />
                </div>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        <Card className={styles.cardFrame}>
          <CardBody>
            <GridContainer justifyContent="center" className={styles.verticalAligned}>
              <GridItem xs={9} sm={8}>
                <h5 className={styles.title}>Show "About Me" To Public</h5>
              </GridItem>
              <GridItem xs={3} sm={2}>
                <div className={styles.switchCenter}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={setting[0].settingValue === 1 ? setting[4].settingValue === 1 : false}
                        onChange={handleChange}
                        name="showAboutMe"
                        disabled={setting[0].settingValue === 1 ? false : true}
                        styles={{
                          root: styles.switchRoot,
                          switchBase: styles.switchBase,
                          checked: styles.switchChecked,
                          thumb: styles.switchIcon,
                          track: styles.switchBar,
                        }}
                      />
                    }
                  />
                </div>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>

      <GridItem xs={12} sm={12} md={12} className={styles.gridItem}>
        <h4 className={styles.title}>Email Setting</h4>

        <Card className={styles.cardFrame}>
          <CardBody>
            <GridContainer justifyContent="center" className={styles.verticalAligned}>
              <GridItem xs={9} sm={8}>
                <h5 className={styles.title}>Show "Email" To Public</h5>
              </GridItem>
              <GridItem xs={3} sm={2}>
                <div className={styles.switchCenter}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={setting[0].settingValue === 1 ? setting[7].settingValue === 1 : false}
                        onChange={handleChange}
                        name="showEmail"
                        disabled={setting[0].settingValue === 1 ? false : true}
                        styles={{
                          root: styles.switchRoot,
                          switchBase: styles.switchBase,
                          checked: styles.switchChecked,
                          thumb: styles.switchIcon,
                          track: styles.switchBar,
                        }}
                      />
                    }
                  />
                </div>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        <Card className={styles.cardFrame}>
          <CardBody>
            <GridContainer justifyContent="center" className={styles.verticalAligned}>
              <GridItem xs={9} sm={8}>
                <h5 className={styles.title}>Allow "Email" Notifications</h5>
              </GridItem>
              <GridItem xs={3} sm={2}>
                <div className={styles.switchCenter}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={setting[0]?.settingValue === 1 ? setting[8]?.settingValue === 1 : false}
                        onChange={handleChange}
                        name="emailNotification"
                        disabled={setting[0].settingValue === 1 ? false : true}
                        styles={{
                          root: styles.switchRoot,
                          switchBase: styles.switchBase,
                          checked: styles.switchChecked,
                          thumb: styles.switchIcon,
                          track: styles.switchBar,
                        }}
                      />
                    }
                  />
                </div>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        <Card className={styles.cardFrame}>
          <CardBody>
            <GridContainer justifyContent="center" className={styles.verticalAligned}>
              <GridItem xs={9} sm={8}>
                <h5 className={styles.title}>Allow "Email" for upcoming interviews</h5>
              </GridItem>
              <GridItem xs={3} sm={2}>
                <div className={styles.switchCenter}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={setting[0]?.settingValue === 1 ? setting[9]?.settingValue === 1 : false}
                        onChange={handleChange}
                        name="emailUpcomingInterview"
                        disabled={setting[0].settingValue === 1 ? false : true}
                        styles={{
                          root: styles.switchRoot,
                          switchBase: styles.switchBase,
                          checked: styles.switchChecked,
                          thumb: styles.switchIcon,
                          track: styles.switchBar,
                        }}
                      />
                    }
                  />
                </div>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <ConfirmDialog
        {...{ ...saveDetailModalProps }}
        bodyContent={cancelForm}
        modalOpen={buttonVisible && saveDetailModalProps.modalOpen}
        handleSubmit={() => {
          setButtonVisible(false);
          if (isMainModalCloseRequest) {
            setIsChanged(false);
            pDispatch(deactivateEditType());
            saveDetailModalProps.setModalOpen(false);
          } else {
            onCancelChangesConfirm();
          }
        }}
      />
    </GridContainer>
  );
};

export default PrivacySettings;
