import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import DirectionsIcon from "@material-ui/icons/Directions";
import Button from "components/CustomButtons/Button.js";
import { ReactComponent as SendIcon } from "../../assets/svg/send-svgrepo-com.svg";
import { Popover } from "@material-ui/core";
import Picker from "emoji-picker-react";
import { isMobile } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: "2px 4px",
		display: "flex",
		alignItems: "center",
		width: "auto",
	},
	input: {
		marginLeft: "20px",
		flex: 1,
	},
	iconButton: {
		// padding: 10,
		borderRadius: "50px",
		marginRight: "-2px",
		backgroundColor: "#fff",
		border: "2px solid #6F52ED",
	},
	divider: {
		height: 28,
		margin: 4,
	},
	sendButton: {
		stroke: "#fff",
	},
	iconButtonHeader: {
		"& > button:hover": {
			backgroundColor: "#6F52ED",
			border: "2px solid #6F52ED",
			"& > span > svg > path": {
				fill: "#fff",
			},
		},
	},
}));

export default function CustomMessageInput(props) {
	const classes = useStyles();
	const {
		formControlProps,
		labelText,
		id,
		readOnly,

		labelProps,
		inputProps,
		error,
		white,
		inputRootCustomClasses,
		success,
		onChange,
		onClick,
		onBlur,
		values,
		onKeyPress,
		onKeyDown,
		placeholder,
		className,
		setFieldValue,
		isCreating,
		setConvAttachment,
	} = props;

	let newInputProps = {
		maxLength: inputProps ? inputProps.maxLength : undefined,
		minLength: inputProps ? inputProps.minLength : undefined,
	};
	const [anchorElEmoji, setAnchorElEmoji] = React.useState(null);
	const [value, setValue] = React.useState("");
	let fileInput = React.createRef();

	const onEmojiClick = (event, emojiObject) => {
		console.log(value + emojiObject.emoji);
		if (emojiObject.emoji != "🥲") {
			setFieldValue("description", value + emojiObject.emoji);
			setValue(value + emojiObject.emoji);
		}
	};

	const handleOnchange = (event) => {
		setValue(event.target.value);
		onChange(event);
	};

	const onSendClick = () => {
		onClick();
	};

	const onFileChange = async (e) => {
		console.log(e);
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0];
			console.log(file);
			setConvAttachment(file);
			// setCommentImageIsEdit(false);
			// setCommentImageModal(true);
		}
	};

	const handleImageClick = () => {
		fileInput.current.value = null;
		fileInput.current.click();
	};

	useEffect(() => {
		console.log(isCreating);
		if (!isCreating) setValue("");
	}, [isCreating]);

	return (
		<div component="form" className={classes.root + " " + className}>
			<InputBase
				className={classes.input}
				id={id}
				onKeyPress={onKeyPress}
				onKeyDown={onKeyDown}
				{...inputProps}
				inputProps={newInputProps}
				value={value}
				// values={values}
				readOnly={readOnly}
				onChange={handleOnchange}
				onBlur={onBlur}
				placeholder={labelText !== undefined ? labelText : null}
			/>
			{/* <Button
				type="submit"
				onClick={onClick}
				className={classes.iconButton}
				aria-label="search"
				color="oppty"
			>
				SEND
			</Button> */}
			<div>
				<div style={{ paddingRight: "10px" }}>
					{/* <IconButton
						onClick={(e) => {
							setAnchorElEmoji(e.currentTarget);
						}}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="21"
							height="21"
							viewBox="0 0 21 21"
						>
							<path
								id="Shape"
								d="M3.075,17.924A10.5,10.5,0,0,1,17.924,3.075,10.5,10.5,0,0,1,3.075,17.924ZM1,10.5A9.5,9.5,0,1,0,10.5,1,9.511,9.511,0,0,0,1,10.5Zm5.207,4.419a.5.5,0,1,1,.6-.8,6.327,6.327,0,0,0,3.727,1.127A6.084,6.084,0,0,0,14.2,14.124a.5.5,0,0,1,.7.09.5.5,0,0,1-.09.7,7,7,0,0,1-4.277,1.331A7.236,7.236,0,0,1,6.207,14.919ZM12.5,8.5a1,1,0,1,1,1,1A1,1,0,0,1,12.5,8.5Zm-6,0a1,1,0,1,1,1,1A1,1,0,0,1,6.5,8.5Z"
								fill="#92929d"
							/>
						</svg>
					</IconButton>
					<Popover
						id={anchorElEmoji ? "emoji-popover" : undefined}
						open={anchorElEmoji ? true : false}
						anchorEl={anchorElEmoji}
						onClose={() => {
							setAnchorElEmoji(null);
						}}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "center",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "center",
						}}
					>
						<div>
							<Picker
								onEmojiClick={onEmojiClick}
								groupVisibility={{
									flags: false,
									recently_used: false,
								}}
							/>
						</div>
					</Popover> */}
					<IconButton onClick={handleImageClick}>
						<svg
							id="ic_attachment"
							xmlns="http://www.w3.org/2000/svg"
							width="21"
							height="21"
							viewBox="0 0 26.982 31.335"
						>
							<path
								id="Shape"
								d="M12.589,24.071a3.8,3.8,0,0,1-5.337-.08,3.914,3.914,0,0,1,0-5.487l11.92-12.07a.759.759,0,0,1,1.084,0,.783.783,0,0,1,0,1.1L8.336,19.6a2.349,2.349,0,0,0,0,3.292,2.278,2.278,0,0,0,3.251,0l11.92-12.07a5.48,5.48,0,0,0,0-7.681,5.316,5.316,0,0,0-7.585,0L4,15.213a8.612,8.612,0,0,0,0,12.07,8.354,8.354,0,0,0,11.92,0l9.753-9.876a.76.76,0,0,1,1.084,0,.783.783,0,0,1,0,1.1L17,28.381a9.873,9.873,0,0,1-14.087,0A10.178,10.178,0,0,1,2.838,14.2l12-12.152a6.835,6.835,0,0,1,9.753,0,7.046,7.046,0,0,1,0,9.876l-12,12.151Z"
								fill="#92929d"
							/>
						</svg>
					</IconButton>
					<div className={"fileinput text-center"}>
						<input
							type="file"
							onChange={onFileChange}
							// accept="image/jpg, image/jpeg, image/gif, image/png, image/apng, image/bmp, image/gif"
							ref={fileInput}
						/>
					</div>
				</div>
			</div>
			<div className={classes.iconButtonHeader}>
				<IconButton onClick={onSendClick} className={classes.iconButton}>
					{/* <SendIcon
						sx={{
							color: "#92929d",
							margin: "5px",
						}}
						className={classes.sendButton}
					/> */}
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="21"
						height="21"
						viewBox="0 0 26.25 23.25"
					>
						<path
							id="Shape"
							d="M0,14.208l18.75-2.583L0,9.041.013,0,26.25,11.625.013,23.25Z"
							fill="#6f52ed"
						/>
					</svg>
				</IconButton>
			</div>
			{/* <IconButton
				type="submit"
				className={classes.iconButton}
				aria-label="search"
			>
				<SearchIcon />
			</IconButton> */}
		</div>
	);
}

CustomMessageInput.propTypes = {
	labelText: PropTypes.node,
	labelProps: PropTypes.object,
	id: PropTypes.string,
	inputProps: PropTypes.object,
	formControlProps: PropTypes.object,
	inputRootCustomClasses: PropTypes.string,
	error: PropTypes.bool,
	readOnly: PropTypes.bool,
	success: PropTypes.bool,
	white: PropTypes.bool,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	onKeyPress: PropTypes.func,
	onKeyDown: PropTypes.func,
	onClick: PropTypes.func,
};
