import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";

const masterFunctionSlice = createSlice({
	name: "masterFunction",
	initialState: {
		functionCode: null,
		extension: null,
	},
	reducers: {
		MASTER_CODE_ACTIVATED: (state, action) => {
			state.functionCode = action.payload.functionCode;
			state.extension = action.payload.extension;
		},
		MASTER_CODE_DEACTIVATED: (state) => {
			state.functionCode = null;
			state.extension = null;
		},
		MASTER_CODE_CLEAN: (state) => {
			state.functionCode = null;
			state.extension = null;
		},
	},
});

const {
	MASTER_CODE_ACTIVATED,
	MASTER_CODE_DEACTIVATED,
	MASTER_CODE_CLEAN,
} = masterFunctionSlice.actions;

export const activateMasterCode = ({ functionCode, extension }) => (
	dispatch
) => {
	const response = { functionCode, extension };
	dispatch(MASTER_CODE_ACTIVATED(response));
};

export const deactivateMasterCode = () => (dispatch) => {
	dispatch(MASTER_CODE_DEACTIVATED());
};
export const cleanMasterCode = () => (dispatch) => {
	dispatch(MASTER_CODE_CLEAN());
};

export default masterFunctionSlice.reducer;
