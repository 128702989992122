import * as React from 'react';
import { Link as RouteLink } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import { ListItemIcon } from '@mui/material';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ConfirmModal from './ConfirmModal';
import { useModal } from 'hooks';
import Pagination from 'views/Component/PaginationWithPage.js';
import PopupMedia from './PopupMedia';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import PollIcon from '@mui/icons-material/Poll';
import ShareIcon from '@mui/icons-material/Share';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import { encryptData } from 'configurations/configurations';

const PostMoreButton = React.memo(({ actions, post }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const confirmModalProps = useModal();
  const [bodyContent, setBodyContent] = React.useState({ title: '', text: '' });
  const [handleSubmit, setHandleSubmit] = React.useState({ click: () => {} });

  const onMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const onMenuClose = () => {
    setAnchorEl(null);
  };

  const onMenuItemClick = option => {
    setHandleSubmit({
      click: () => {
        option.onClick(post).then(() => {
          onMenuClose();
          confirmModalProps.setModalOpen(false);
        });
      },
    });
    setBodyContent(option.getConfirmModalContent(post));
    confirmModalProps.setModalOpen(true);
  };
  return (
    <>
      <IconButton
        onClick={onMenuClick}
        id="group-action-button"
        aria-label="group-action-button"
        aria-controls={open ? 'group-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="group-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onMenuClose}
        MenuListProps={{
          'aria-labelledby': 'group-action-button',
        }}
      >
        {actions.map((option, index) => {
          return (
            <MenuItem onClick={() => onMenuItemClick(option)} key={index}>
              <option.Icon />
              {option.text}
            </MenuItem>
          );
        })}
      </Menu>
      <ConfirmModal {...{ ...confirmModalProps, bodyContent }} handleSubmit={handleSubmit.click} />
    </>
  );
});

const PostContent = ({ post }) => {
  const { modalOpen, toggle } = useModal();
  return (
    <>
      {post.postTypes?.length > 0 ||
        (post.sharedId > 0 && (
          <>
            <Link component="button" variant="body2" onClick={toggle}>
              {post.typeOptionId == 1 ? (
                <ImageOutlinedIcon fontSize="small" />
              ) : post.typeOptionId == 2 ? (
                <OndemandVideoIcon fontSize="small" />
              ) : post.typeOptionId == 3 ? (
                <PollIcon fontSize="small" />
              ) : post.typeOptionId == 4 ? (
                <ShareIcon fontSize="small" />
              ) : (
                post.typeOptionId == 5 && <WorkOutlineIcon fontSize="small" />
              )}
              <span style={{ verticalAlign: 'top' }}>
                {post.typeOptionId == 1
                  ? ' Image'
                  : post.typeOptionId == 2
                  ? ' Video'
                  : post.typeOptionId == 3
                  ? ' Poll'
                  : post.typeOptionId == 4
                  ? ' Share Post'
                  : post.typeOptionId == 5 && ' Share Job'}
              </span>
            </Link>
            <br />
          </>
        ))}

      {post.description ? (
        <div
          dangerouslySetInnerHTML={{
            __html: post.description.replaceAll('target="_blank"', ''),
          }}
        />
      ) : null}

      <PopupMedia open={modalOpen} onClose={toggle} post={post} />
    </>
  );
};
export default function PostList({ posts, actions = [], perPageCount, paginate }) {
  const [checked, setChecked] = React.useState([]);
  const onCheckboxClick = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <>
      <List dense sx={{ width: '100%', height: '450px', overflow: 'auto', padding: '8px 0px 0px 8px' }}>
        {posts && posts.length > 0 ? (
          posts.map((post, index) => {
            const labelId = `manage-group-list-item-${post.postId}`;
            const encryptedAcctId = encryptData(post?.acctId);
            return (
              <ListItem divider key={index} disablePadding secondaryAction={<PostMoreButton {...{ post, actions }} />}>
                {/* <ListItemIcon>
								<Checkbox
									edge="end"
									onChange={onCheckboxClick(index)}
									checked={checked.indexOf(index) !== -1}
									inputProps={{ "aria-labelledby": labelId }}
								/>
							</ListItemIcon> */}
                <ListItemAvatar>
                  <RouteLink style={{ color: 'inherit' }} to={`/view-profile/${encryptedAcctId}`}>
                    <Avatar alt={`Avatar n°${post.postId}`} src={post.profilePic} />
                  </RouteLink>
                </ListItemAvatar>
                <ListItemText
                  id={labelId}
                  disableTypography
                  style={{ maxWidth: '80%' }}
                  primary={
                    <RouteLink
                      style={{ color: 'inherit' }}
                      to={`/view-profile/${encryptedAcctId}`}
                    >{`${post.firstName} ${post.surname}`}</RouteLink>
                  }
                  secondary={
                    <Typography component={'div'}>
                      <PostContent post={post} />
                    </Typography>
                  }
                />
              </ListItem>
            );
          })
        ) : (
          <p style={{ textAlign: 'center', fontWeight: '500' }}>No Pending Post Found.</p>
        )}
      </List>
      {posts.length > 0 && (
        <Box width="100%" bgcolor="#EFF3FE">
          <Pagination totalPosts={posts[0].totalPost} postsPerPage={perPageCount} paginate={paginate} />
        </Box>
      )}
    </>
  );
}
