import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Header from "../../Component/Header";
import LeftSidebar from "../../Component/LeftSidebar.js";

import { makeStyles, Hidden, Box, CircularProgress } from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { useModal } from "hooks";
import groupPageStyles from "assets/jss/material-kit-pro-react/views/pageStyle/GroupsPage";
import { suggestedGroupData, browseByIndustryData } from "./mocks";
import CreateGroupModal from "./CreateGroupModal";
import MyGroupComponent from "./MyGroupComponent";
import {
	getAllIndustry,
	getAllCountries,
} from "configurations/redux/userSlice";
import { getGroupsByAcctId, getGroups } from "configurations/redux/groupsSlice";
import GroupContainer from "./GroupContainer";
import GroupCard from "./GroupCard";
import GroupsPageHeader from "./GroupsPageHeader";
import GroupByIndustryCard from "./GroupByIndustryCard";
import {
	userGroupRoles,
	userGroupStatus,
	groupPermissions,
} from "configurations/configurations";
import { joinUserGroup } from "configurations/redux/groupsSlice";
import Footer from "views/Component/Footer.js";

import NoResultCard from "./NoResultCard";

const useStyles = makeStyles(groupPageStyles);

const GroupsPage = () => {
	const classes = useStyles();
	const createGroupModalProps = useModal();
	const accessToken = localStorage.getItem("token");
	const dispatch = useDispatch();

	const {
		groups,
		isGroupsLoading,
		groupsByAccount,
		isGroupsByAccountLoading,
	} = useSelector((state) => state.groups);
	const user = useSelector((state) => state.auth.user);
	const [groupContent, setGroupContent] = React.useState("default");

	const getMyGroups = () => {
		const body = {
			accessToken,
			statusId: 1,
			acctId: user.acctId,
			email: user.email,
			lastRecordSize: 0,
			requestRecordSize: 4,
			keyword: "",
		};
		dispatch(getGroupsByAcctId(body));
	};
	const getAllGroups = () => {
		const body = {
			accessToken,
			acctId: user.acctId,
			email: user.email,
			lastRecordSize: 0,
			requestRecordSize: 4,
			keyword: "",
		};
		dispatch(getGroups(body));
	};

	const joinGroup = (group) => {
		const { ALLOW_ANYONE_TO_JOIN } = groupPermissions;
		const userStatusId =
			group.permissionId.indexOf(`${ALLOW_ANYONE_TO_JOIN.id}`) === -1
				? userGroupStatus.REQUESTED.id
				: userGroupStatus.ACTIVE.id;

		const body = {
			accessToken,
			email: user.email,
			groupId: group.groupId,
			acctId: user.acctId,
			userId: user.acctId,
			userStatusId,
			roleId: userGroupRoles.MEMBER.id,
		};
		dispatch(joinUserGroup(body));
	};

	useEffect(() => {
		dispatch(getAllIndustry());
		dispatch(getAllCountries());
		getMyGroups();
		getAllGroups();
	}, [groupContent]);
	const Loader = () => (
		<Box display="flex" justifyContent="center" alignItems="center">
			<CircularProgress />
		</Box>
	);

	const MyGroups = () => (
		<GroupContainer
			title="My groups"
			subTitle="Groups which you created or joined."
			onSeeAllClick={setGroupContent}
		>
			<GridContainer spacing={2}>
				{groupsByAccount &&
					groupsByAccount.length > 0 &&
					groupsByAccount.slice(0, 4).map((data) => (
						<GridItem sm={12} md={6} lg={3} key={data.groupId}>
							<GroupCard group={data} isGroupJoined={true} />
						</GridItem>
					))}

				{groupsByAccount && groupsByAccount.length === 0 && (
					<GridItem>
						<div
							style={{
								display: "flex",
								width: "100%",
								justifyContent: "center",
							}}
						>
							<h4
								style={{
									fontSize: "18px",
									color: "rgba(0,0,0,0.6)",
									fontWeight: "500",
									textAlign: "center",
								}}
							>
								You have not joined into any groups yet. Click Join button to
								join
							</h4>
						</div>
					</GridItem>
				)}
			</GridContainer>
		</GroupContainer>
	);
	console.log(groupContent);
	const BrowseGroups = memo(({ isGroupsLoading, groups, joinGroup }) => (
		<GroupContainer
			title="Browse groups"
			subTitle="Browse of list of all groups."
			onSeeAllClick={setGroupContent}
		>
			<GridContainer spacing={2}>
				{groups &&
					groups.length > 0 &&
					groups.slice(0, 4).map((data) => (
						<GridItem sm={12} md={6} lg={3} key={data.groupId}>
							<GroupCard
								group={data}
								/* 		isGroupJoined={data.isGroupMember} */
								{...{ joinGroup }}
							/>
						</GridItem>
					))}

				{groups && groups.length === 0 && (
					<GridItem>
						<div
							style={{
								display: "flex",
								width: "100%",
								justifyContent: "center",
							}}
						>
							<h4
								style={{
									fontSize: "18px",
									color: "rgba(0,0,0,0.6)",
									fontWeight: "500",
									textAlign: "center",
								}}
							>
								There is no group available in Oppty! Be the first one to create
								the group
							</h4>
						</div>
					</GridItem>
				)}
			</GridContainer>
		</GroupContainer>
	));

	const GroupsByIndustry = () => (
		<GroupContainer
			title="Browse by Industry"
			subTitle="Discover groups from the various industries that you are interested in."
		>
			<GridContainer spacing={2}>
				{browseByIndustryData.map((data) => (
					<GridItem sm={12} md={6} lg={2} key={data.id}>
						<GroupByIndustryCard group={data} />
					</GridItem>
				))}
			</GridContainer>
		</GroupContainer>
	);
	const SuggestedGroups = () => (
		<GroupContainer
			title="Suggested Groups"
			subTitle="Groups you might be interested in."
		>
			<GridContainer spacing={1}>
				{suggestedGroupData.map((data) => (
					<GridItem sm={12} md={6} lg={3} key={data.id}>
						<GroupCard group={data} />
					</GridItem>
				))}
			</GridContainer>
		</GroupContainer>
	);
	return (
		<div>
			<Header />
			<div className={classes.body}>
				<div
					id="pageHeader"
					className={classes.pageHeader}
					style={{
						backgroundSize: "cover",
						backgroundPosition: "top center",
					}}
				>
					<div
						className={classes.container}
						style={{ background: "#f2f2f2" }}
						id="socialFeedPage"
					>
						<GridContainer spacing={2}>
							<Hidden mdDown>
								<GridItem md={2}>
									<LeftSidebar />
								</GridItem>
							</Hidden>
							<GridItem sm={12} md={12} lg={10}>
								<GroupsPageHeader
									openCreateGroupModal={createGroupModalProps.toggle}
								/>
								{groupContent === "default" && (
									<React.Fragment>
										<MyGroups
											{...{ isGroupsByAccountLoading, groupsByAccount }}
										/>
										<BrowseGroups {...{ isGroupsLoading, groups, joinGroup }} />
									</React.Fragment>
								)}

								{groupContent === "My groups" && (
									<MyGroupComponent onSeeAllClick={setGroupContent} />
								)}
							</GridItem>
						</GridContainer>
					</div>
				</div>

				<CreateGroupModal {...{ ...createGroupModalProps }} />

				<Footer />
			</div>
		</div>
	);
};

export default GroupsPage;
