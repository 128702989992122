import React from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import Button from "components/CustomButtons/Button.js";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Close from "@material-ui/icons/Close";

import style from "assets/jss/material-kit-pro-react/modalStyle.js";
import IconLibrary from "../IconLibrary";

const useStyles = makeStyles(style);

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

const ImgDialog = ({ img, open, onClose }) => {
	const classes = useStyles();
	const imageClasses = classNames(
		classes.imgRaised,
		classes.imgRoundedCircle,
		classes.img
	);

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.paper,
			}}
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={onClose}
			aria-labelledby="classic-modal-slide-title"
			aria-describedby="classic-modal-slide-description"
		>
			<DialogTitle
				id="classic-modal-slide-title"
				disableTypography
				className={classes.modalHeader}
			>
				<Button
					simple
					className={classes.modalCloseButton}
					key="close"
					aria-label="Close"
					onClick={onClose}
				>
					{" "}
					<IconLibrary.Close />
				</Button>
				<h6 className={classes.modalTitle}>Preview Your Picture</h6>
			</DialogTitle>
			<DialogContent
				id="classic-modal-slide-description"
				className={classes.modalBody}
			>
				<div className={classes.imgContainer}>
					<img src={img} alt="Cropped" className={imageClasses} />
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default ImgDialog;
