import { createSlice } from '@reduxjs/toolkit';

import axios from 'axios';
import { instanceUrl, header, emptyCache } from 'configurations/configurations.js';

const searchProfileSlice = createSlice({
  name: 'searchProfile',
  initialState: {
    searchResult: [],
    searchParams: [],
    lastRecordSize: 0,
    requestRecordSize: 0,
    isLoading: true,
    isEnd: false,
    isSearching: false,
  },
  reducers: {
    PROFILE_LOADING: (state, action) => {
      state.isLoading = true;
    },
    PROFILE_SEARCH_SUCCESS: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      if (!action.payload.genericSearch) {
        state.searchResult = [...state.searchResult, ...action.payload.searchResult];
      } else state.searchResult = action.payload.searchResult;
      state.searchParams = action.payload.searchParams;
      state.lastRecordSize = state.lastRecordSize + action.payload.requestRecordSize;
      state.requestRecordSize = action.payload.requestRecordSize;
      state.isLoading = false;
    },
    PROFILE_SEARCH_END: (state, action) => {
      state.isEnd = true;
    },
    DEFAULT_PROFILE_SEARCH: state => {
      state.searchResult = [];
      state.searchParams = [];
      state.lastRecordSize = 0;
      state.requestRecordSize = 0;
      state.isEnd = false;
      state.isSearching = true;
    },
    PROFILE_SEARCH_FAIL: (state, action) => {
      return state;
    },
  },
});

const {
  PROFILE_SEARCH_SUCCESS,
  PROFILE_LOADING,
  PROFILE_SEARCH_FAIL,
  PROFILE_SEARCH_END,
  DEFAULT_PROFILE_SEARCH,
} = searchProfileSlice.actions;

/* export const defaultSearch = () => (dispatch) => {
  dispatch(DEFAULT_PROFILE_SEARCH());
}; */
export const searchProfileInfo = ({
  acctId,
  email,
  accessToken,
  keyword,
  countryId,
  locationId,
  lastRecordSize,
  requestRecordSize,
  genericSearch,
}) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    keyword: keyword && keyword.charAt(0) == '#' ? keyword.slice(1) : keyword,
    countryId,
    locationId,
    lastRecordSize,
    requestRecordSize,
    genericSearch,
  };
  console.log(body);
  dispatch(PROFILE_LOADING());
  if (!genericSearch) {
    if (lastRecordSize === 0) {
      dispatch(DEFAULT_PROFILE_SEARCH());
    }
  }

  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/portal/search-profile`,
    params: body,
  })
    .then(res => {
      console.log(res);
      if (res.status === 201) {
        console.log(res);
        const response = {
          searchResult: res.data.payload.profileList,
          searchParams: {
            keyword,
            countryId,
            locationId,
          },
          lastRecordSize,
          genericSearch,
          requestRecordSize: res.data.payload.profileList.length,
          token: res.data.token,
        };
        dispatch(PROFILE_SEARCH_SUCCESS(response));
        if (requestRecordSize > res.data.payload.profileList.length) {
          dispatch(PROFILE_SEARCH_END());
        }
      } else if (res.status === 202) {
        dispatch(PROFILE_SEARCH_END());
      } else {
        dispatch(PROFILE_SEARCH_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export default searchProfileSlice.reducer;
