import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
	instanceUrl,
	header,
	emptyCache,
} from "configurations/configurations.js";

const recommendedGroupsSlice = createSlice({
	name: "recommendedGroups",
	initialState: {
		recommendedGroupsResult: [],
		recommendedGroupsParams: [],
		lastRecordSize: 0,
		requestRecordSize: 0,
		isLoading: false,
		isEnd: false,
		isSearching: false,
		isUpdated: true,
	},
	reducers: {
		RG_LOADING: (state, action) => {
			state.isLoading = true;
		},
		RG_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.recommendedGroupsResult = [
				// ...state.recommendedGroupsResult,
				...action.payload.recommendedGroupsResult,
			];
			state.recommendedGroupsParams = action.payload.recommendedGroupsParams;
			state.lastRecordSize =
				state.lastRecordSize + action.payload.requestRecordSize;
			state.requestRecordSize = action.payload.requestRecordSize;
			state.isLoading = false;
			state.isUpdated = true;
		},
		RG_END: (state, action) => {
			state.isEnd = true;
		},
		RG_SEARCH: (state) => {
			state.recommendedGroupsResult = [];
			state.recommendedGroupsParams = [];
			state.lastRecordSize = 0;
			state.requestRecordSize = 0;
			state.isEnd = false;
			state.isSearching = true;
		},
		RG_FAIL: (state) => {
			state.isUpdated = false;
		},
	},
});

const {
	RG_LOADING,
	RG_SUCCESS,
	RG_END,
	RG_SEARCH,
	RG_FAIL,
} = recommendedGroupsSlice.actions;

export const recommendedGroupsInfo = ({
	email,
	acctId,
	lastRecordSize,
	requestRecordSize,
	accessToken,
}) => async (dispatch) => {
	const body = {
		email,
		acctId,
		lastRecordSize,
		requestRecordSize,
		accessToken,
	};

	dispatch(RG_LOADING());
	if (lastRecordSize === 0) {
		dispatch(RG_SEARCH());
	}

	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/sg/get-suggested-group-by-acct-id`,
		params: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				const response = {
					recommendedGroupsResult: res.data.payload.groupList,
					recommendedGroupsParams: {},
					lastRecordSize,
					requestRecordSize: res.data.payload.groupList.length,
					token: res.data.token,
				};
				dispatch(RG_SUCCESS(response));
				if (requestRecordSize > res.data.payload.groupList.length) {
					dispatch(RG_END());
				}
			} else if (res.status === 202) {
				dispatch(RG_END());
			} else {
				dispatch(RG_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export default recommendedGroupsSlice.reducer;
