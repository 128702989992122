import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { departmentViewRoadmap, viewRoadmapRoleByRoleId } from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Department_Data_Science_AI_Science from "assets/img/Career_Roadmap/Data_Artificial_Intelligence/Department_Data_Science_AI_Science_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentDataScienceAIScience() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Data_Science_AI_Science;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "dsas",
				shape: "poly",
				coords: [81,388,73,391,69,394,65,399,63,405,63,463,65,467,68,473,74,476,80,479,172,479,179,477,186,473,189,469,192,461,191,406,188,399,184,394,179,390,173,389,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "173",
				shape: "poly",
				coords: [216,393,211,395,208,398,205,401,204,405,204,461,206,467,209,471,214,474,219,475,399,475,404,472,408,469,411,465,412,460,411,408,410,401,407,397,402,393,396,392,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "174",
				shape: "poly",
				coords: [586,394,580,398,576,401,573,405,572,410,571,465,573,469,577,474,580,476,586,478,865,478,871,476,875,473,877,469,879,462,879,409,877,403,874,399,870,397,864,395,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "175",
				shape: "poly",
				coords: [1131,391,1125,392,1121,395,1117,400,1115,407,1115,464,1117,470,1120,473,1124,476,1130,478,1308,478,1315,476,1318,473,1322,469,1323,464,1323,407,1322,400,1319,395,1314,392,1308,391,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "176",
				shape: "poly",
				coords: [1643,392,1637,395,1634,398,1632,402,1630,406,1630,462,1632,466,1634,470,1638,472,1646,475,1825,474,1832,471,1835,468,1837,465,1837,460,1838,408,1837,401,1834,396,1829,393,1823,391,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "dsas") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo)
			dispatch(viewRoadmapRoleByRoleId(requestInfo));
			
			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// console.log(coords.x + "," + coords.y + ",");
		// navigator.clipboard.writeText(coords.x + "," + coords.y + ",");
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
