import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
	departmentViewRoadmap,
	viewRoadmapRoleByRoleId,
} from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Industry_Healthcare from "assets/img/Career_Roadmap/Healthcare/Industry_Healthcare_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function IndustryHealthcare() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Industry_Healthcare;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "o",
				shape: "poly",
				coords: [74,63,66,66,60,70,57,76,55,82,56,121,58,128,62,134,68,138,77,139,180,139,188,136,192,133,196,126,197,121,198,81,195,74,191,68,185,66,178,63,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "ot",
				shape: "poly",
				coords: [72,189,64,192,60,196,58,200,56,206,56,247,58,253,62,259,68,263,73,264,180,263,187,262,191,258,194,254,197,247,197,208,196,200,191,194,185,190,179,188,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "ps",
				shape: "poly",
				coords: [77,320,67,322,62,326,60,331,58,336,56,378,58,384,62,389,66,393,73,396,179,396,187,393,191,391,194,386,197,380,197,339,196,332,192,327,187,323,180,320,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "p",
				shape: "poly",
				coords: [74,452,67,455,62,458,60,463,58,469,59,512,61,517,64,521,71,526,77,527,182,526,188,523,192,520,196,515,198,510,199,467,196,462,192,456,186,453,180,451,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "st",
				shape: "poly",
				coords: [74,588,66,590,62,594,58,599,56,605,57,646,58,651,62,657,68,660,75,663,179,664,186,661,192,658,196,652,197,646,198,604,195,598,191,592,185,589,178,587,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "n",
				shape: "poly",
				coords: [76,716,67,718,62,722,59,728,57,733,57,774,58,778,62,784,66,788,73,791,179,791,188,789,192,785,195,781,197,774,197,733,195,726,191,721,184,716,179,714,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "o") {
			dispatch(departmentViewRoadmap(41));
		} else if (area.name === "ot") {
			dispatch(departmentViewRoadmap(42));
		} else if (area.name === "ps") {
			dispatch(departmentViewRoadmap(43));
		} else if (area.name === "p") {
			dispatch(departmentViewRoadmap(44));
		} else if (area.name === "st") {
			dispatch(departmentViewRoadmap(45));
		} else if (area.name === "n") {
			dispatch(departmentViewRoadmap(46));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo);
			dispatch(viewRoadmapRoleByRoleId(requestInfo));

			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
