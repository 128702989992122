import React from "react";

import SuggestedGroupCard from "../SuggestedGroups/SuggestedGroupCard";

import { Button, makeStyles, Typography } from "@material-ui/core";

import groupImage1 from "assets/img/Banner/landscape1.png";
import groupImage2 from "assets/img/Banner/landscape2.png";
import groupImage3 from "assets/img/Banner/landscape3.jpeg";

import suggestedGroupsStyles from "assets/jss/material-kit-pro-react/views/componentStyle/discoverGroups/suggestedGroupsStyles.js"

const useStyles = makeStyles(suggestedGroupsStyles);

const SuggestedGroups = () => {
  const styles = useStyles();

  return (
    <Typography component="div" className={styles.main}>

      <Typography component="div" className={styles.header}>
        <Typography component="div">
          <Typography component="h2" className={styles.mainTitle}>
            Suggested Groups
          </Typography>
          <Typography component="p" className={styles.mainDescription}>
            Groups you might be interested in
          </Typography>
        </Typography>

        <Button className={styles.seeAllBtn}>SEE ALL</Button>
      </Typography>

      <Typography component="div" className={styles.groupCardsContainer}>
        <SuggestedGroupCard
          image={groupImage3}
          name="Dribbble Designer Pro ID"
          description="Yogyakarta"
          members="2,351"
        />
        <SuggestedGroupCard
          image={groupImage3}
          name="Urban Sneakers Society ID"
          description="Jakarta"
          members="2,35M"
        />
        <SuggestedGroupCard
          image={groupImage3}
          name="Microstock Community"
          description="Yogyakarta"
          members="2,351"
        />
        {/* <SuggestedGroupCard
          image={groupImage3}
          name="Dribbble Designer Pro ID"
          description="Yogyakarta"
          members="2,351"
        /> */}
      </Typography>



    </Typography>
  )
}

export default SuggestedGroups;