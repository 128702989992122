import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
	departmentViewRoadmap,
	viewRoadmapRoleByRoleId,
} from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Industry_Banking_Risk_Compliance from "assets/img/Career_Roadmap/Banking_Risk_Compliance/Industry_Banking_Risk_Compliance_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function IndustryBankingRiskCompliance() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Industry_Banking_Risk_Compliance;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "r",
				shape: "poly",
				coords: [73,316,64,318,60,321,57,327,55,333,55,374,57,379,60,384,64,388,72,391,176,391,184,389,189,385,193,379,195,372,196,331,193,325,188,320,183,317,176,315,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "c",
				shape: "poly",
				coords: [72,501,63,504,59,508,55,513,54,518,55,559,56,564,59,568,63,572,71,576,176,577,184,574,190,570,192,566,195,559,195,518,193,512,188,506,184,503,178,500,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "465",
				shape: "poly",
				coords: [294,314,285,317,281,320,278,324,277,330,278,376,280,384,283,388,288,391,294,392,482,392,492,389,496,385,498,381,500,374,500,332,499,322,494,317,487,315,480,314,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "466",
				shape: "poly",
				coords: [626,313,617,317,613,320,610,324,609,332,608,375,610,382,612,386,617,390,625,392,810,392,821,388,826,386,829,382,830,378,830,329,828,322,824,317,817,315,811,314,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "467",
				shape: "poly",
				coords: [954,314,947,316,944,319,941,324,940,332,940,377,942,384,944,388,950,391,959,392,1145,391,1154,389,1157,385,1159,383,1161,377,1162,332,1160,322,1155,318,1150,315,1144,314,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "468",
				shape: "poly",
				coords: [1286,314,1278,316,1275,319,1272,324,1271,330,1271,376,1272,383,1275,387,1281,391,1288,392,1475,392,1484,390,1488,387,1491,382,1492,377,1492,334,1492,324,1488,320,1484,316,1479,314,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "469",
				shape: "poly",
				coords: [1618,314,1610,316,1606,319,1602,325,1601,334,1602,376,1604,383,1606,388,1612,391,1620,392,1804,392,1815,390,1819,387,1822,383,1824,378,1824,332,1822,323,1819,319,1815,316,1806,314,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "470",
				shape: "poly",
				coords: [292,500,284,502,280,506,278,512,277,521,277,561,279,568,283,573,288,576,298,578,482,577,492,575,496,571,498,566,500,558,499,519,498,510,496,505,492,502,484,500,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "471",
				shape: "poly",
				coords: [624,500,616,502,612,506,610,509,608,516,609,562,610,568,613,572,618,576,626,578,812,578,823,575,827,570,829,566,830,560,830,518,829,510,826,505,821,500,816,500,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "472",
				shape: "poly",
				coords: [956,499,947,502,943,506,940,510,939,519,939,561,941,569,945,572,950,576,960,578,1140,578,1152,575,1156,572,1160,567,1161,560,1161,521,1160,510,1158,505,1152,501,1145,500,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "473",
				shape: "poly",
				coords: [1286,500,1279,502,1274,506,1271,510,1270,517,1271,561,1273,568,1276,573,1281,576,1289,578,1475,578,1484,576,1488,573,1491,568,1492,560,1492,518,1491,509,1488,504,1483,501,1476,500,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "474",
				shape: "poly",
				coords: [1622,500,1610,502,1606,505,1602,510,1601,517,1602,560,1603,568,1606,573,1612,576,1806,578,1815,576,1819,573,1823,567,1824,559,1823,517,1822,508,1818,504,1812,501,1806,500,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "r") {
			dispatch(departmentViewRoadmap(57));
		} else if (area.name === "c") {
			dispatch(departmentViewRoadmap(58));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo);
			dispatch(viewRoadmapRoleByRoleId(requestInfo));

			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
