import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { departmentViewRoadmap, viewRoadmapRoleByRoleId } from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Department_Machine_Learning_Intelligence from "assets/img/Career_Roadmap/Data_Artificial_Intelligence/Department_Machine_Learning_Intelligence_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentMachineLearningEngineering() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Machine_Learning_Intelligence;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "mli",
				shape: "poly",
				coords: [81,394,73,397,68,400,65,406,62,413,62,454,64,460,67,464,72,469,78,471,171,472,178,469,184,465,188,460,190,455,190,413,188,406,184,401,179,397,171,395,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "168",
				shape: "poly",
				coords: [216,389,209,391,206,395,203,399,201,405,201,457,203,464,205,468,211,472,216,473,393,473,400,471,405,467,407,464,408,457,408,405,407,397,404,393,399,391,392,390,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "169",
				shape: "poly",
				coords: [575,390,569,393,565,396,563,400,561,406,561,456,563,464,566,468,570,471,576,473,751,473,759,472,763,470,767,464,769,458,768,405,767,399,764,394,760,392,754,390,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "170",
				shape: "poly",
				coords: [933,390,927,392,923,394,919,398,918,403,918,458,919,464,922,468,926,471,933,473,1111,473,1117,470,1121,466,1124,464,1125,457,1125,405,1123,398,1120,394,1115,392,1110,390,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "171",
				shape: "poly",
				coords: [1280,390,1274,392,1270,395,1268,400,1267,405,1266,459,1268,464,1270,468,1274,471,1281,473,1458,473,1465,471,1471,467,1473,462,1474,454,1473,406,1473,399,1468,394,1463,391,1457,390,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "172",
				shape: "poly",
				coords: [1644,390,1636,392,1632,395,1628,400,1627,407,1627,455,1628,463,1631,468,1635,471,1641,473,1818,472,1826,471,1829,467,1833,464,1835,460,1834,405,1833,399,1830,394,1824,391,1816,390,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "mli") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo)
			dispatch(viewRoadmapRoleByRoleId(requestInfo));
			
			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// console.log(coords.x + "," + coords.y + ",");
		// navigator.clipboard.writeText(coords.x + "," + coords.y + ",");
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
