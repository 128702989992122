import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

// core components
import Button from "components/CustomButtons/Button.js";

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import Close from "@material-ui/icons/Close";

import CommentImageCrop from "../../Component/ImageCrop/CommentImageCrop.js";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/LandingPageFeed/CommentStyle.js";
import style from "assets/jss/material-kit-pro-react/modalStyle.js";
import IconLibrary from "views/Component/IconLibrary.js";
import Compressor from "compressorjs";
import reactImageSize from "react-image-size";

const useStyles = makeStyles(profilePageStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

const CommentImageModal = ({
	open,
	onClose,
	pictureSource,
	isEdit,
	fileName,
	saveCroppedPhoto,
}) => {
	// console.log(pictureSource);
	const classes = useStyles();
	const [imageSrc, setImageSrc] = React.useState(pictureSource);
	const [dimension, setDimension] = React.useState(null);
	React.useEffect(() => {
		const loadImage = async (e) => {
			console.log(pictureSource);
			if (pictureSource) {
				const sizeOf = require("image-size");
				let imageDataUrl = isEdit
					? pictureSource
					: await readFile(pictureSource);
				// var img = "";
				// if (isEdit) {
				// 	img = imageDataUrl;
				// } else {
				// 	img = Buffer.from(imageDataUrl.split(";base64,").pop(), "base64");
				// }
				// console.log(img);
				// console.log(imageDataUrl);
				if (!isEdit) fileName(pictureSource.name);
				// console.log(sizeOf(img));
				try {
					const { width, height } = await reactImageSize(imageDataUrl);
					var dimensions = { width: width, height: height };
					// var dimensions = sizeOf(img);
					if (dimensions) {
						if (
							pictureSource.size > 1000000 ||
							dimensions.width > 1200 ||
							dimensions.height > 1200
						) {
							new Compressor(pictureSource, {
								quality: 0.8,
								maxWidth: 1200,
								maxHeight: 1200,
								checkOrientation: false,
								success: (compressedResult) => {
									console.log(compressedResult);
									const readImage = async (e) => {
										let compressImage = isEdit
											? pictureSource
											: await readFile(compressedResult);
										// console.log(compressImage);
										// img = Buffer.from(
										// 	compressImage.split(";base64,").pop(),
										// 	"base64"
										// );
										// dimensions = sizeOf(img);
										const { width, height } = await reactImageSize(
											imageDataUrl
										);
										var dimensions = { width: width, height: height };
										setImageSrc(compressImage);
										setDimension(dimensions);
										if (!isEdit) fileName(compressedResult.name);
									};
									readImage();
								},
							});
						} else {
							setImageSrc(imageDataUrl);
							setDimension(dimensions);
						}
					}
				} catch (e) {}
			}
		};
		loadImage();
	}, []);

	const readFile = (file) => {
		console.log(file);
		return new Promise((resolve) => {
			const reader = new FileReader();
			reader.addEventListener("load", () => resolve(reader.result), false);
			reader.readAsDataURL(file);
		});
	};

	return (
		<React.Fragment>
			<Dialog
				classes={{
					root: classes.modalRoot,
					paper: classes.modalCommentEdit + " " + classes.modal,
				}}
				open={open}
				// TransitionComponent={Transition}
				// keepMounted
				// disableBackdropClick
				onClose={(event, reason) => {
					if (reason !== "backdropClick") {
						onClose(event, reason);
					}
				}}
				aria-labelledby="classic-modal-slide-title"
				aria-describedby="classic-modal-slide-description"
			>
				<DialogTitle
					id="classic-modal-slide-title"
					disableTypography
					className={classes.modalTitleEdit}
				>
					<Button
						simple
						className={classes.modalCloseButton}
						key="close"
						aria-label="Close"
						onClick={onClose}
					>
						<IconLibrary.Close />
					</Button>
					<h6 className={classes.title + " " + classes.titleAlign}>
						Edit your image
					</h6>
				</DialogTitle>
				<DialogContent
					id="classic-modal-slide-description"
					className={classes.modalBody}
				>
					<CommentImageCrop
						pictureSource={imageSrc}
						backgroundTemplateFlag={0}
						dimension={dimension}
						fileName={fileName}
						saveCroppedPhoto={saveCroppedPhoto}
						// requestRecord={userStatus}
						// onClose={onClose}
					/>
				</DialogContent>
				<div className={classes.modalFooter}></div>
			</Dialog>
		</React.Fragment>
	);
};
const mapStateToProps = (state) => ({
	userStatus: state.auth.user,
	// image: state.user.image,
});
export default connect(mapStateToProps, null)(CommentImageModal);
