import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCandidateSelectJobPosts } from "configurations/redux/jobManagementSlice.js";
import { TimeFormat, encryptData } from "configurations/configurations.js";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { Link, useHistory } from "react-router-dom";

import Tooltip from "@material-ui/core/Tooltip";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Primary from "components/Typography/Primary.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";

import jobStatusPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/JobStatusPage/jobStatusPageStyle.js";
import businessPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/BusinessPage/businessPageStyle";
// import Pagination from "../../../Component/PaginationWithPage.js";

import defaultAvatar from "assets/img/corporate_avatar.jpeg";
import EditIcon from "@material-ui/icons/Edit";
import LoadingImage from "assets/img/Loading-pana.png";

import { Divider } from "@material-ui/core";
import {
	getAppliedJobPostList,
	getAppliedJobPost,
} from "configurations/redux/appliedJobSlice";
import { resetNotificationItem } from "configurations/redux/eventNotificationSlice";
import IconLibrary from "views/Component/IconLibrary.js";
import {
	getContacts,
	sendContactInvitation,
} from "configurations/redux/userSlice.js";
import Backdrop from "../../Component/Backdrop.js";
import { Hidden } from "@material-ui/core";
import { isDesktop } from "react-device-detect";
import {
	validateBizPage,
	bizPageInfo,
	deleteBusinessPage,
	resetSelectBizPage,
} from "configurations/redux/businessSlice";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import CustomDialog2 from "../../../components/CustomDialog/CustomDialog2.js";
import { getCorpProfiles } from "configurations/redux/corpUserSlice.js";
import { messageActiveInboxInfo } from "configurations/redux/messageInboxSlice";
import { messageChatroomListReset } from "configurations/redux/messageChatroomListSlice.js";
import { messageConversationReset } from "configurations/redux/messageConversationSlice.js";
import { messageChatroomInfo } from "configurations/redux/messageChatroomSlice.js";
import { messageChatroomReset } from "configurations/redux/messageChatroomSlice.js";

const publicIp = require("public-ip");

const useStyles = makeStyles(businessPageStyle); //profilePageStyle

export default function BusinessCardSection(editList) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const userStatus = useSelector((state) => state.auth.user);
	const history = useHistory();
	const searchResult = useSelector((state) => state.business.searchResult);

	const isLoading = useSelector((state) => state.business.isLoading);

	const bizId = useSelector((state) => state.business.bizId);
	const profileRecord = useSelector((state) => state.corpUser.profileRecord);

	const modalResize = () => {
		var modalResize = document.getElementById("templateCard");
		if (modalResize) {
			var maxSize = window.innerHeight - 74 - 120 - 70;
			if (maxSize < 0) maxSize = 0;
			modalResize.style.height = maxSize + "px";
		}
	};

	const dialogDefault = {
		title: "",
		description: "",
		buttonYes: "",
		buttonNo: "",
	};
	const [confirmDialog, setConfirmDialog] = React.useState({
		isOpen: false,
		dialogDefault,
	});

	React.useEffect(() => {
		if (document.readyState === "complete") {
			setTimeout(() => {
				modalResize();
			}, 500);
		}
		window.addEventListener("resize", modalResize);
		return function cleanup() {
			window.removeEventListener("resize", modalResize);
		};
	});

	useEffect(() => {
		if (bizId && profileRecord && bizId > 0) {
			history.push(
				`/corporate-profile-page/${encryptData(
					userStatus.acctId
				)}/${encryptData(bizId)}`
			);
		}
	}, [bizId, profileRecord]);

	const searchResultChatroom = useSelector(
		(state) => state.messageChatroom.searchResult
	);


	useEffect(() => {
		console.log("reset chatroom");
		const requestInbox = {
			acctId: userStatus.acctId,
			accessToken: localStorage.getItem("token"),
			email: userStatus.email,
		};
		dispatch(messageActiveInboxInfo(requestInbox));
		dispatch(messageChatroomListReset());
		dispatch(messageConversationReset());
		dispatch(messageChatroomReset());
	}, [searchResult]);

	const deleteBizPage = (deleteBizId) => {
		const dialogDefault = {
			title: "Alert",
			description: "You are about to delete your business page",
			description2:
				"This action cannot be undone and any data and information lost cannot be recovered.",
			buttonYes: "Proceed",
			buttonNo: "Cancel",
		};
		setConfirmDialog({
			isOpen: true,
			dialogDefault,
			onConfirm: () => {
				console.log(userStatus);
				const request = {
					acctId: userStatus.acctId,
					email: userStatus.email,
					accessToken: localStorage.getItem("token"),
					bizId: deleteBizId,
					bizPageList: searchResult,
				};
				dispatch(deleteBusinessPage(request));
				setConfirmDialog({
					isOpen: false,
					dialogDefault,
				});
			},
			onCancel: () => {
				setConfirmDialog({
					isOpen: false,
					dialogDefault,
				});
			},
		});
	};

	const SearchResult =
		searchResult &&
		searchResult.map((item, index) => {
			console.log('searchResult');
			console.log(item);
			console.log(index);
			var contactCard = classes.cardClick + " " + classes.contactCard;
			if (searchResult) {
				if (searchResult[0].bizId === item.bizId) {
					contactCard =
						classes.cardClick +
						" " +
						classes.contactCard +
						" " +
						classes.contactCardFirst;
				} else if (searchResult[searchResult.length - 1].bizId === item.bizId) {
					contactCard =
						classes.cardClick +
						" " +
						classes.contactCard +
						" " +
						classes.contactCardBorder +
						" " +
						classes.contactCardLast;
				} else {
					contactCard =
						classes.cardClick +
						" " +
						classes.contactCard +
						" " +
						classes.contactCardBorder;
				}
				if (searchResult.length === 1) {
					contactCard =
						classes.cardClick +
						" " +
						classes.contactCard +
						" " +
						classes.contactCardOnly;
				}
			}
			return (
				<Card
					// className={classes.cardClick + " " + classes.cardFrame}
					className={contactCard}
					key={index}
				>
					<CardBody className={classes.cardPaddingLeft}>
						<GridContainer>
							<GridItem>
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
										alignContent: "center",
									}}
								>
									{/* <GridContainer alignContent="center" alignItems="center"> */}
									<React.Fragment>
										{/* <GridItem xs={10} sm={10} md={10}>
											<GridContainer alignContent="center" alignItems="center">
												<GridItem className={classes.gridItemMargin}> */}
										<h4
											className={classes.contactTitle}
											style={{
												textTransform: "capitalize",
											}}
										>
											{item.companyName}{" "}
											{item.pageName.length > 0 ? `(${item.pageName})` : ""}
										</h4>
										{/* </GridItem>
											</GridContainer>
										</GridItem> */}
									</React.Fragment>
									<div>
										{/* <GridItem xs={2} sm={2} md={2} className={classes.itemCenter}> */}
										<Hidden mdUp>
											{editList.editList && (
												<Button
													onClick={(event) => {
														event.preventDefault();
														deleteBizPage(item.bizId);
													}}
													color="discardNoBackground"
													className={classes.buttonInvite}
												>
													<DeleteForeverOutlinedIcon />
												</Button>
											)}
											<Button
												onClick={(event) => {
													event.preventDefault();
													console.log(userStatus);
													const requestProfile = {
														acctId: userStatus.acctId,
														bizId: item.bizId,
														ipAddr: localStorage.getItem("ipAddr"), //clientIp,
														userId: userStatus.acctId,
														accessToken: localStorage.getItem("token"),
													};
													dispatch(getCorpProfiles(requestProfile));
													const request = {
														acctId: userStatus.acctId,
														email: userStatus.email,
														accessToken: localStorage.getItem("token"),
														bizId: item.bizId,
													};
													dispatch(validateBizPage(request));
												}}
												color="opptyWrapBorder"
												className={classes.buttonInvite}
											>
												<CorporateFareOutlinedIcon />
											</Button>
										</Hidden>
										<Hidden smDown>
											{editList.editList && (
												<Button
													onClick={(event) => {
														event.preventDefault();
														deleteBizPage(item.bizId);
													}}
													color="discardNoBackground"
													className={classes.buttonInvite}
												>
													<DeleteForeverOutlinedIcon />
													Delete
												</Button>
											)}

											
											<Button
												onClick={(event) => {
													event.preventDefault();
													console.log(userStatus);
													const requestProfile = {
														acctId: userStatus.acctId,
														bizId: item.bizId,
														ipAddr: localStorage.getItem("ipAddr"), //clientIp,
														userId: userStatus.acctId,
														accessToken: localStorage.getItem("token"),
													};
													dispatch(getCorpProfiles(requestProfile));
													const request = {
														acctId: userStatus.acctId,
														email: userStatus.email,
														accessToken: localStorage.getItem("token"),
														bizId: item.bizId,
													};
													dispatch(validateBizPage(request));
												}}
												color="opptyWrapBorder"
												className={classes.buttonInvite}
											>
												<CorporateFareOutlinedIcon />
												Manage Page
											</Button>
										</Hidden>
									</div>
								</div>
								{/* </GridItem>
								</GridContainer> */}
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
			);
		});

	const NoContact = () => {
		return (
			<Card className={classes.cardFrame + " " + classes.cardFrameMargin}>
				<CardBody>
					<GridContainer justifyContent="center">
						<GridItem xs={12} sm={12} md={12} className={classes.itemCenter}>
							<h5 className={classes.title + " " + classes.titleWeight}>
								No business page found in this account
							</h5>
						</GridItem>
					</GridContainer>
				</CardBody>
			</Card>
		);
	};
	const LoadingContact = () => {
		return (
			<Card className={classes.cardFrame + " " + classes.cardFrameMargin}>
				<CardBody>
					<img
						src={LoadingImage}
						className={classes.backGroundImage}
						alt="Expired Session"
					/>
				</CardBody>
			</Card>
		);
	};
	// console.log(contactList);
	return (
		<React.Fragment>
			<Card className={classes.modalCardBorder + " " + classes.tabCardBorder}>
				<CardBody
					className={
						isDesktop
							? classes.smallModalBodyDesktop +
							" " +
							classes.modalBackground +
							" " +
							//   classes.modalBackgroundScroll +
							" " +
							classes.cropContainer
							: classes.smallModalBody +
							" " +
							classes.modalBackground +
							" " +
							//   classes.modalBackgroundScroll +
							" " +
							classes.cropContainer
					}
					style={{
						border: "2px solid rgb(217, 225, 231)",
						borderRadius: "18px",
					}}
					id="templateCard"
				>
					<div id="contactAccountSection"></div>

					<GridContainer>
						<GridItem>
							<div id="contactCard">
								{/* <GridContainer>
									<GridItem> */}
								{isLoading ? <Backdrop /> : null}
								{searchResult && searchResult.length > 0 ? (
									<React.Fragment>
										<div className={classes.contactPadding}>{SearchResult}</div>
									</React.Fragment>
								) : (
									<NoContact />
								)}
								{/* </GridItem>
								</GridContainer> */}
							</div>
						</GridItem>
					</GridContainer>
				</CardBody>
			</Card>
			<CustomDialog2
				confirmDialog={confirmDialog}
				setConfirmDialog={setConfirmDialog}
			/>
		</React.Fragment>
	);
}
