import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { instanceUrl, header, headerFormData, emptyCache, encryptData } from 'configurations/configurations.js';

const subscriptionSetting = createSlice({
    name: "subscription",
    initialState: {
        subscriptionPlans: [],
        currentSubscription: [],
        currentSubsPlanCandidate: [],
        currentSubsPlanOfStorage: [],
        getSubsCandidate: [],
        roleNames: [],
        paymentList: [],
        accountPages: [],
        businessPages: []
    },
    reducers: {
        SUBS_SUBSCRIPTION_PLAN: (state, action) => {
            state.subscriptionPlans = action.payload.payload
        },
        SUBS_CURRENT_PLAN: (state, action) => {
            state.currentSubscription = action.payload.payload.subscriptionList
        },
        SUBS_ACTIVE_PLAN_CANDIDATE: (state, action) => {
            if (action.payload.payload.subscriptionList[0].planId != 0) {
                state.currentSubsPlanCandidate = action.payload.payload.subscriptionList
            }
            else {
                state.currentSubsPlanCandidate = []
            }
        },
        SUBS_ACTIVE_PLAN_CANDIDATE_OF_STORAGE: (state, action) => {
            if (action.payload.payload.subscriptionList[0].planId != 0) {
                state.currentSubsPlanOfStorage = action.payload.payload.subscriptionList
            } else {
                state.currentSubsPlanOfStorage = []
            }
        },
        SUBS_GET_CANDIDATE_PLAN: (state, action) => {
            state.getSubsCandidate = action.payload.payload
        },
        SUBS_GET_ROLES: (state, action) => {
            state.roleNames = action.payload.payload
        },
        SUBS_GET_PAYMENT_LIST: (state, action) => {
            state.paymentList = action.payload.payload
        },
        SUBS_GET_ACCOUNT_PAGES: (state, action) => {
            state.accountPages = action.payload.payload.businessPageList
        },
        SUBS_ACCOUNT_PAGES: (state, action) => {
            state.businessPages = action.payload.payload.businessPageUserList
        },
    },
});
const {
    SUBS_SUBSCRIPTION_PLAN,
    SUBS_CURRENT_PLAN,
    SUBS_GET_ROLES,
    SUBS_GET_PAYMENT_LIST,
    SUBS_GET_CANDIDATE_PLAN,
    SUBS_GET_ACCOUNT_PAGES,
    SUBS_ACCOUNT_PAGES,
    SUBS_ACTIVE_PLAN_CANDIDATE,
    SUBS_ACTIVE_PLAN_CANDIDATE_OF_STORAGE
} = subscriptionSetting.actions;

export const getSubscriptionPlan = ({ type }) => async (dispatch) => {
    await axios({
        method: "GET",
        headers: header,
        url: `${instanceUrl}/api/md/get-subscription-plan-by-type-id?typeId=${type}`
    })
        .then((res) => {
            console.log(res);
            if (res.status === 201) {
                dispatch(SUBS_SUBSCRIPTION_PLAN(res.data));
            }
        })
        .catch(err => {
            if (err.message.includes('503')) {
                emptyCache();
            }
            console.log(err);
        });
};

export const getSubscriptionPlanCandidate = ({ type }) => async (dispatch) => {
    await axios({
        method: "GET",
        headers: header,
        url: `${instanceUrl}/api/md/get-subscription-plan-by-type-id?typeId=${type}`
    })
        .then((res) => {
            console.log(res);
            if (res.status === 201) {
                dispatch(SUBS_GET_CANDIDATE_PLAN(res.data));
            }
        })
        .catch(err => {
            if (err.message.includes('503')) {
                emptyCache();
            }
            console.log(err);
        });
};

export const getCurrentSubscribePack = ({ acctId, email, accessToken, recordId, typeId }) => async (dispatch) => {
    const body = { acctId, email, accessToken, recordId, typeId };
    await axios({
        method: "GET",
        headers: header,
        url: `${instanceUrl}/api/um/get-user-subscription-by-record-id`,
        params: body,
    })
        .then((res) => {
            if (res.status === 201) {
                dispatch(SUBS_CURRENT_PLAN(res.data));
            }
        })
        .catch(err => {
            if (err.message.includes('503')) {
                emptyCache();
            }
            console.log(err);
        });
}



export const checkoutSubscriptionPlan = ({ email, acctId, accessToken, priceCode }, cb) => async () => {
    const body = { email, acctId, accessToken, priceCode };
    await axios({
        method: "POST",
        headers: header,
        url: `${instanceUrl}/api/pay/create-checkout-session`,
        data: body,
    })
        .then((res) => {
            // cb(res.data);
            if (res.status === 201) {
                cb(res.data);
            }
        })
        .catch(err => {
            if (err.message.includes('503')) {
                emptyCache();
            }
            console.log(err);
        });
}

export const getSubscriptionList = ({ acctId }) => async (dispatch) => {
    const body = { acctId };
    await axios({
        method: "GET",
        headers: header,
        url: `${instanceUrl}/api/pay/get-invoice-by-acct-id`,
        params: body,
    })
        .then((res) => {
            // cb(res.data);
            if (res.status === 201) {
                dispatch(SUBS_GET_PAYMENT_LIST(res.data));
            }
        })
        .catch(err => {
            if (err.message.includes('503')) {
                emptyCache();
            }
            console.log(err);
        });
}

export const getSubscriptionPaymentInfo = ({ acctId, email, accessToken }, cb) => async () => {
    const body = { acctId, email, accessToken };
    await axios({
        method: "POST",
        headers: header,
        url: `${instanceUrl}/api/pay/open-customer-portal-by-acct-id`,
        data: body,
    })
        .then((res) => {
            // cb(res.data);
            if (res.status === 201) {
                cb(res.data);
            }
        })
        .catch(err => {
            if (err.message.includes('503')) {
                emptyCache();
            }
            console.log(err);
        });
}

export const cancelSubscription = ({ email, acctId, accessToken, subscriptionId }, cb) => async () => {
    const body = { email, acctId, accessToken, subscriptionId };
    console.log(body)
    await axios({
        method: "PUT",
        headers: header,
        url: `${instanceUrl}/api/um/delete-user-subscription`,
        data: body,
    })
        .then((res) => {
            if (res.status === 201) {
                cb(res.data)
            }
        })
        .catch(err => {
            if (err.message.includes('503')) {
                emptyCache();
            }
            console.log(err);
        });
}

export const getAllRoleOfAccount = () => async (dispatch) => {
    await axios({
        method: "GET",
        headers: header,
        url: `${instanceUrl}/api/md/get-biz-page-user-role`,
    })
        .then((res) => {
            // cb(res.data);
            if (res.status === 201) {
                dispatch(SUBS_GET_ROLES(res.data));
            }
        })
        .catch(err => {
            if (err.message.includes('503')) {
                emptyCache();
            }
            console.log(err);
        });
}

export const getAllAccountPages = ({ acctId, email, accessToken, lastRecordSize, requestRecordSize }) => async (dispatch) => {
    const body = { acctId, email, accessToken, lastRecordSize, requestRecordSize }
    await axios({
        method: "GET",
        headers: header,
        url: `${instanceUrl}/api/bp/business-page-get-business-user-by-role`,
        params: body,
    })
        .then((res) => {
            // cb(res.data);
            if (res.status === 201) {
                dispatch(SUBS_GET_ACCOUNT_PAGES(res.data))
            }
        })
        .catch(err => {
            if (err.message.includes('503')) {
                emptyCache();
            }
            console.log(err);
        });
}

export const activeCandidatePlan = (data, cb) => async (dispatch) => {
    const body = data
    await axios({
        method: "GET",
        headers: header,
        url: `${instanceUrl}/api/um/get-user-subscription-by-record-id`,
        params: body,
    })
        .then((res) => {
            if (res.status === 201) {
                if (data.planId == 6) {
                    dispatch(SUBS_ACTIVE_PLAN_CANDIDATE(res.data))
                } else {
                    dispatch(SUBS_ACTIVE_PLAN_CANDIDATE_OF_STORAGE(res.data))
                }

            }
        })
        .catch(err => {
            if (err.message.includes('503')) {
                emptyCache();
            }
            console.log(err);
        });
}

export const deletePermission = ({ email, acctId, accessToken, bpUserId }, cb) => async () => {
    const body = { email, acctId, accessToken, bpUserId }

    await axios({
        method: "PUT",
        headers: header,
        url: `${instanceUrl}/api/bp/business-page-delete-business-user`,
        data: body,
    })
        .then((res) => {
            // cb(res.data);
            if (res.status === 201) {
                cb(res.data)
            }
        })
        .catch(err => {
            if (err.message.includes('503')) {
                emptyCache();
            }
            console.log(err);
        });
}

export const getPermissionGrant = (data, cb) => async () => {
    const body = data
    await axios({
        method: "POST",
        headers: header,
        url: `${instanceUrl}/api/bp/business-page-create-business-user`,
        data: body,
    })
        .then((res) => {
            if (res.status === 201) {
                cb(res.data)
            }
        })
        .catch(err => {
            if (err.message.includes('503')) {
                emptyCache();
            }
            console.log(err);
        });
}

export const checkoutFreeSubscription = (data, cb) => async (dispatch) => {
    const body = data;
    await axios({
        method: "POST",
        headers: header,
        url: `${instanceUrl}/api/um/create-user-subscription`,
        data: body,
    })
        .then((res) => {
            cb(res.data)
        })
        .catch(err => {
            if (err.message.includes('503')) {
                emptyCache();
            }
            console.log(err);
        });
}

export const getlistingBusinessPages = ({ bizId, lastRecordSize, requestRecordSize, email, accessToken }, cb) => async (dispatch) => {
    const body = { bizId, lastRecordSize, requestRecordSize, email, accessToken }
    await axios({
        method: "GET",
        headers: header,
        url: `${instanceUrl}/api/bp/business-page-get-business-user-by-biz-id`,
        params: body,
    })
        .then((res) => {
            // cb(res.data);
            if (res.status === 201) {
                dispatch(SUBS_ACCOUNT_PAGES(res.data))
            }
        })
        .catch(err => {
            if (err.message.includes('503')) {
                emptyCache();
            }
            console.log(err);
        });
}


export const getUserSubscriptionByRecordId = ({ email, acctId, accessToken, recordId, typeId }, cb) => async () => {
    const body = { email, acctId, accessToken, recordId, typeId };
    console.log('=======db======');
    console.log(body);
    await axios({
        method: "GET",
        headers: header,
        url: `${instanceUrl}/api/um/get-user-subscription-by-record-id`,
        params: body,
    })
        .then((res) => {
            // cb(res.data);
            if (res.status === 201) {
                cb(res.data);
            }
        })
        .catch(err => {
            if (err.message.includes('503')) {
                emptyCache();
            }
            console.log(err);
        });
}


export default subscriptionSetting.reducer;