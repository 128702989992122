import React from "react";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles(profilePageStyle);

export default function SkillsAndAttributes() {
	const hashTagsList = useSelector((state) => state.user.hashtags);
	const classes = useStyles();
	const dispatch = useDispatch();
	const isViewed = useSelector((state) => state.user.isViewed);
	const user = useSelector((state) => state.user);
	const privacySetting = useSelector((state) => state.user.privacySetting);

	const showProfile =
		privacySetting &&
		privacySetting
			.filter((item) => {
				return item.settingName === "showProfile";
			})
			.map((item) => {
				return item.settingValue === 1;
			})[0];

	const HashTagList = () => {
		return (
			hashTagsList &&
			hashTagsList.map((item) => {
				if (isViewed) {
					return (
						<Chip
							key={item.hashId}
							label={<p className={classes.tagDescription}>{item.hashtag}</p>}
							className={classes.chip}
							color={"secondary"}
						/>
					);
				} else {
					return (
						<Chip
							key={item.hashId}
							label={<p className={classes.tagDescription}>{item.hashtag}</p>}
							className={classes.chip}
							color={"secondary"}
						/>
					);
				}
			})
		);
	};
	return (
		<React.Fragment>
			{showProfile ? (
				<HashTagList />
			) : (
				<h5 className={classes.title}>
					"Skills {"&"} Attribute" Detail is currently private.
				</h5>
			)}
		</React.Fragment>
	);
}
