import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const editProfileModalSlice = createSlice({
  name: 'editProfileModal',
  initialState: {
    type: null,
    extension: null,
    isShow: false,
    tabType: null,
    isTabShow: false,
    isMainModalCloseRequest: false,
  },
  reducers: {
    TYPE_ACTIVATE: (state, action) => {
      state.type = action.payload.type;
      state.extension = action.payload.extension;
      state.tabType = action.payload.tabType;
      state.isShow = true;
      state.isTabShow = action.payload.isTabShow;
      state.isDiaglogShow = action.payload.isDiaglogShow;
      state.isMainModalCloseRequest = false;
    },
    TYPE_DEACTIVATE: state => {
      state.type = null;
      state.extension = null;
      state.tabType = null;
      state.isShow = false;
      state.isTabShow = false;
      state.isMainModalCloseRequest = false;
    },
    TYPE_RESET: state => {
      state.type = null;
      state.extension = null;
      state.tabType = null;
      state.isShow = false;
      state.isTabShow = false;
    },
    MAIN_MODAL_CLOSE_REQUEST: state => {
      state.isMainModalCloseRequest = true;
    },
  },
});

const { TYPE_ACTIVATE, TYPE_DEACTIVATE, TYPE_RESET, MAIN_MODAL_CLOSE_REQUEST } = editProfileModalSlice.actions;

export const activateEditType = ({ type, extension, tabType, isTabShow, isDiaglogShow }) => dispatch => {
  const response = { type, extension, tabType, isTabShow, isDiaglogShow };
  /* 	console.log(response); */
  dispatch(TYPE_ACTIVATE(response));
};

export const deactivateEditType = () => dispatch => {
  dispatch(TYPE_DEACTIVATE());
};
export const mainModalCloseRequest = () => dispatch => {
  dispatch(MAIN_MODAL_CLOSE_REQUEST());
};
export default editProfileModalSlice.reducer;
