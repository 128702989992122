import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { departmentViewRoadmap, viewRoadmapRoleByRoleId } from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Department_Sales_Leasing from "assets/img/Career_Roadmap/Real_Estate/Department_Sales_Leasing_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentSalesLeasing() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Sales_Leasing;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "sl",
				shape: "poly",
				coords: [77,388,70,391,66,393,63,397,60,401,58,406,58,448,60,454,64,458,69,462,74,464,167,465,173,463,177,461,180,459,184,455,187,448,187,407,185,402,183,398,179,394,175,391,169,389,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "133",
				shape: "poly",
				coords: [216,388,209,388,205,390,202,392,200,396,199,400,199,455,199,459,200,465,205,468,211,471,389,471,396,470,399,467,403,463,406,453,406,400,404,394,400,391,395,389,390,388,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "134",
				shape: "poly",
				coords: [570,388,563,389,559,392,556,396,555,402,554,453,555,460,557,465,562,469,569,470,744,471,751,469,755,466,759,462,761,456,761,400,758,394,754,391,750,389,744,388,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "135",
				shape: "poly",
				coords: [924,388,917,390,914,392,911,396,909,402,909,450,910,461,913,465,918,469,925,471,1100,471,1108,468,1111,466,1115,461,1116,456,1116,403,1115,397,1112,392,1107,390,1101,388,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "136",
				shape: "poly",
				coords: [1282,388,1272,389,1269,393,1266,397,1264,404,1264,459,1268,464,1272,468,1278,471,1457,469,1464,467,1467,465,1470,461,1471,453,1471,401,1470,395,1465,391,1460,389,1456,388,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "137",
				shape: "poly",
				coords: [1636,388,1632,390,1629,394,1626,398,1625,402,1626,456,1627,463,1629,466,1634,469,1639,471,1819,470,1823,468,1826,466,1829,463,1831,460,1833,455,1833,404,1832,399,1829,394,1826,391,1822,389,1818,387,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "sl") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo)
			dispatch(viewRoadmapRoleByRoleId(requestInfo));
			
			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// console.log(coords.x + "," + coords.y + ",");
		// navigator.clipboard.writeText(coords.x + "," + coords.y + ",");
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
