import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
	departmentViewRoadmap,
	viewRoadmapRoleByRoleId,
} from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Department_Corporate_Banking from "assets/img/Career_Roadmap/Banking_SME_Corporate_Commercial/Department_Corporate_Banking_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentCorporateBanking() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Corporate_Banking;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "corb",
				shape: "poly",
				coords: [77,395,70,396,65,400,61,404,59,410,59,454,61,460,65,464,70,467,77,470,178,471,187,468,192,465,196,460,200,452,200,415,198,407,193,400,186,395,180,393,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "479",
				shape: "poly",
				coords: [296,390,285,392,281,395,278,399,277,405,276,453,278,460,281,463,285,467,292,469,482,469,489,467,493,464,497,460,499,453,499,407,496,400,493,394,487,392,481,390,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "420",
				shape: "poly",
				coords: [618,390,610,393,606,397,603,403,602,408,602,455,604,460,608,464,613,467,620,468,805,468,813,467,819,464,823,459,824,454,823,405,821,398,817,394,812,392,806,391,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "421",
				shape: "poly",
				coords: [944,390,935,393,931,396,928,401,927,408,927,454,929,460,931,463,937,467,943,468,1134,469,1141,466,1145,462,1147,458,1150,452,1149,405,1147,397,1143,394,1137,391,1130,390,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "422",
				shape: "poly",
				coords: [1269,391,1262,393,1256,397,1253,403,1252,408,1252,452,1254,459,1258,464,1263,467,1271,469,1457,468,1465,466,1470,462,1474,458,1475,453,1474,405,1471,398,1467,394,1462,390,1455,390,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "479",
				shape: "poly",
				coords: [1596,390,1587,392,1582,396,1579,402,1579,408,1579,454,1580,460,1583,465,1589,468,1597,468,1782,469,1791,467,1795,463,1798,460,1801,454,1800,407,1798,400,1794,394,1789,392,1783,391,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "corb") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo);
			dispatch(viewRoadmapRoleByRoleId(requestInfo));

			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
