import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  instanceUrl,
  header,
  emptyCache,
} from "configurations/configurations.js";

const getUserAttachmentByAcctIdSlice = createSlice({
  name: "getUserAttachmentByAcctId",
  initialState: {
    getUserAttachmentByAcctIdResult: [],
    lastRecordSize: 0,
    requestRecordSize: 0,
    isLoading: false,
    isEnd: false,
    isUpdated: true,
  },
  reducers: {
    GUABAI_LOADING: (state, action) => {
      state.isLoading = true;
    },
    GUABAI_SUCCESS: (state, action) => {
      localStorage.setItem("token", action.payload.token);
      state.getUserAttachmentByAcctIdResult =
        action.payload.getUserAttachmentByAcctIdResult;
      state.lastRecordSize =
        state.lastRecordSize + action.payload.requestRecordSize;
      state.requestRecordSize = action.payload.requestRecordSize;
      state.isLoading = false;
      state.isUpdated = true;
    },
    GUABAI_END: (state, action) => {
      state.isEnd = true;
    },
    GUABAI_FAIL: (state) => {
      state.isUpdated = false;
    },
  },
});

const {
  GUABAI_LOADING,
  GUABAI_SUCCESS,
  GUABAI_END,
  GUABAI_FAIL,
} = getUserAttachmentByAcctIdSlice.actions;

export const getUserAttachmentByAcctIdInfo = ({
  email,
  acctId,
  keyword,
  lastRecordSize,
  requestRecordSize,
  accessToken,
}) => async (dispatch) => {
  const body = {
    email,
    acctId,
    keyword,
    lastRecordSize,
    requestRecordSize,
    accessToken,
  };
  dispatch(GUABAI_LOADING());

  await axios({
    method: "GET",
    headers: header,
    url: `${instanceUrl}/api/um/get-user-attachment-by-acct-id`,
    params: body,
  })
    .then((res) => {
      if (res.status === 201) {
        const response = {
          getUserAttachmentByAcctIdResult: res.data.payload.userAttachmentList,
          lastRecordSize,
          requestRecordSize,
          token: res.data.token,
        };
        dispatch(GUABAI_SUCCESS(response));
        if (requestRecordSize > 100) {
          dispatch(GUABAI_END());
        }
      } else if (res.status === 202) {
        dispatch(GUABAI_END());
      } else {
        dispatch(GUABAI_FAIL());
      }
    })
    .catch((err) => {
      if (err.message.includes("503")) {
        emptyCache();
      }
      console.log(err);
    });
};

export default getUserAttachmentByAcctIdSlice.reducer;
