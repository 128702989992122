const workExperienceStyles = () => ({
	main: {
		marginTop: "15px",
		borderRadius: "15px",
		backgroundColor: "#fff",
		padding: "10px 15px",
	},

	header: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",

		"& > h2": {
			color: "#000000",
			fontSize: "16px",
			fontWeight: 700,
			fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		},

		"& > button": {
			color: "#6F52ED",
			fontSize: "15px",
			fontWeight: 500,
			fontFamily: `"Roboto", "Helvetica", sans-serif !important`,

			"& svg": {
				marginRight: "5px",
				width: "14px",
				height: "14px",
			},
		},
	},
});

export default workExperienceStyles;
