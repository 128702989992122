import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
	departmentViewRoadmap,
	viewRoadmapRoleByRoleId,
} from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Department_Aircraft_Engine_Component_Maintenance from "assets/img/Career_Roadmap/Aerospace_Engineering/Department_Aircraft_Engine_Component_Maintenance_1.jpg";
import Aerospace_Engineering from "../../../../../assets/json/Career_Roadmap/Aerospace_Engineering.json";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentAircraftEngineComponentMaintenance() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	// const jsonData = Aerospace_Engineering.department.filter((item) => {
	// 	return item.department === "Department_Aircraft_Engine_Component_Maintenance";
	// });
	
	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Aircraft_Engine_Component_Maintenance;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "aecm",
				shape: "poly",
				coords: [81,64,74,66,70,70,65,75,64,82,64,784,66,791,70,796,75,800,84,802,176,802,183,799,187,794,191,789,192,783,192,83,191,77,187,70,181,66,173,64,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "213",
				shape: "poly",
				coords: [222,136,216,138,212,140,210,145,208,151,208,202,210,207,213,210,216,213,222,215,329,214,335,212,336,208,338,204,340,200,338,150,336,143,333,140,328,138,323,136,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "212",
				shape: "poly",
				coords: [219,266,214,268,211,272,209,276,208,280,208,331,209,338,212,341,216,343,221,345,322,345,328,342,333,339,336,336,338,331,337,278,336,273,331,269,327,266,323,266,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "221",
				shape: "poly",
				coords: [219,397,214,399,211,402,208,407,208,413,207,462,210,466,213,470,217,474,222,476,324,476,329,472,333,469,336,464,338,459,337,408,335,403,331,398,326,397,320,396,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "214",
				shape: "poly",
				coords: [424,136,418,138,414,142,411,148,410,154,410,201,413,207,415,212,419,214,424,215,528,214,532,212,536,210,539,205,540,200,540,150,537,143,534,140,530,136,526,136,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "231",
				shape: "poly",
				coords: [424,294,418,296,414,300,411,304,410,310,410,362,412,366,414,369,418,371,422,372,526,373,532,372,536,368,539,363,540,358,540,307,538,300,533,296,527,294,523,294,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "227",
				shape: "poly",
				coords: [423,396,418,398,414,401,410,405,410,410,411,460,412,466,414,469,417,472,424,474,526,474,534,472,538,468,539,464,540,458,540,408,539,403,536,400,530,396,525,396,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "222",
				shape: "poly",
				coords: [420,498,416,500,414,502,412,504,410,511,410,563,412,567,414,571,418,574,424,577,527,576,532,573,536,571,540,567,540,560,540,511,538,504,534,500,530,498,526,497,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "215",
				shape: "poly",
				coords: [628,135,622,137,616,140,614,143,612,148,612,202,613,207,617,211,619,214,625,215,728,214,733,214,737,210,740,205,742,200,742,150,740,144,737,140,732,137,727,135,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "232",
				shape: "poly",
				coords: [626,294,620,297,615,300,613,304,611,310,612,357,613,363,615,368,620,371,625,373,729,372,735,370,738,367,740,363,741,359,742,307,739,301,736,297,732,295,727,294,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "228",
				shape: "poly",
				coords: [629,396,620,398,615,402,612,406,611,412,611,461,613,467,616,470,621,473,625,475,728,475,735,472,739,468,741,463,742,458,742,411,740,403,736,399,731,397,726,396,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "223",
				shape: "poly",
				coords: [625,498,619,500,616,502,612,507,612,514,612,563,614,568,616,572,620,574,626,576,728,576,733,574,737,571,740,568,741,562,742,510,740,504,737,501,732,498,728,496,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "216",
				shape: "poly",
				coords: [824,136,818,139,816,142,814,146,812,152,812,200,814,206,817,210,821,213,826,215,929,214,936,212,940,208,942,204,943,199,943,152,942,146,938,140,934,137,929,136,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "233",
				shape: "poly",
				coords: [826,266,821,268,818,270,815,273,813,280,813,331,815,337,816,341,823,344,830,345,930,344,936,342,939,338,942,335,943,329,943,278,941,272,936,270,932,266,928,265,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "229",
				shape: "poly",
				coords: [827,396,821,398,818,400,814,404,813,410,813,464,816,468,819,472,823,474,828,475,928,475,934,474,936,471,940,467,943,461,942,411,941,403,938,399,934,396,928,396,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "235",
				shape: "poly",
				coords: [826,527,821,528,818,530,814,536,813,542,812,591,814,597,816,602,821,604,826,606,926,606,934,604,938,599,942,596,943,590,944,543,942,535,937,530,932,527,928,526,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "224",
				shape: "poly",
				coords: [828,658,821,660,817,663,814,667,813,672,813,722,815,728,818,732,822,735,828,736,928,736,936,734,939,732,941,728,943,722,943,674,942,666,939,662,934,659,929,657,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "217",
				shape: "poly",
				coords: [1030,135,1021,138,1018,141,1015,146,1014,153,1014,202,1017,208,1020,211,1024,215,1030,215,1133,215,1139,212,1141,208,1144,203,1146,197,1146,149,1143,143,1139,140,1135,137,1130,135,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "234",
				shape: "poly",
				coords: [1029,265,1023,268,1019,269,1015,274,1014,280,1015,332,1016,337,1019,341,1024,344,1029,345,1130,344,1137,342,1141,340,1143,335,1145,330,1144,279,1142,273,1139,269,1134,267,1129,266,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "230",
				shape: "poly",
				coords: [1028,395,1022,398,1018,401,1015,409,1014,415,1014,456,1016,466,1018,469,1022,472,1029,475,1131,475,1137,472,1141,469,1144,462,1145,456,1144,413,1143,404,1140,399,1134,396,1129,396,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "236",
				shape: "poly",
				coords: [1029,527,1024,529,1020,531,1017,536,1015,542,1016,592,1017,598,1021,602,1026,605,1032,606,1133,605,1138,602,1142,600,1144,596,1146,592,1146,542,1144,536,1142,532,1138,529,1132,526,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "225",
				shape: "poly",
				coords: [1028,657,1023,659,1019,662,1016,666,1014,674,1014,720,1016,729,1019,732,1023,736,1029,737,1132,737,1137,733,1141,730,1144,726,1144,720,1145,675,1144,666,1139,661,1134,658,1128,657,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "218",
				shape: "poly",
				coords: [1286,224,1278,228,1273,230,1271,235,1269,243,1270,304,1272,311,1274,314,1279,318,1285,319,1430,319,1438,316,1444,312,1448,308,1448,301,1449,242,1447,234,1442,228,1437,226,1430,224,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "226",
				shape: "poly",
				coords: [1286,538,1278,538,1275,542,1271,547,1270,553,1270,618,1273,624,1276,628,1280,630,1286,632,1435,631,1443,628,1445,625,1448,622,1449,615,1449,552,1447,544,1443,542,1438,539,1434,537,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "219",
				shape: "poly",
				coords: [1469,380,1460,382,1456,384,1453,391,1451,398,1452,459,1455,466,1457,469,1462,472,1468,473,1618,473,1623,470,1628,466,1630,463,1631,458,1631,394,1628,388,1625,384,1622,381,1616,380,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "220",
				shape: "poly",
				coords: [1673,380,1667,381,1663,384,1659,389,1658,395,1658,458,1660,464,1662,468,1668,471,1676,472,1823,472,1829,470,1833,466,1836,462,1836,457,1837,395,1834,388,1831,382,1825,380,1820,380,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "aecm") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo);
			dispatch(viewRoadmapRoleByRoleId(requestInfo));

			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
