import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ConfirmModal = ({ modalOpen, toggle, bodyContent, handleSubmit = () => {}, handleCancel = () => {} }) => {
  const onClose = () => {
    handleCancel();
    toggle();
  };

  return (
    <Dialog disableEnforceFocus open={modalOpen} onClose={toggle}>
      <DialogTitle>{bodyContent?.title ?? ''}</DialogTitle>
      <DialogContent>
        <DialogContentText>{bodyContent?.text ?? ''}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{bodyContent?.cancelText ?? 'Cancel'}</Button>
        <Button color={bodyContent?.confirmBtnColor ?? 'primary'} onClick={handleSubmit}>
          {bodyContent?.confirmText ?? 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmModal;
