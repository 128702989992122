/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  makeStyles,
  MenuItem,
  Modal,
  Select,
  Popover,
  IconButton,
  // TextField,
} from '@material-ui/core';

import CreatePostModalStyle from 'assets/jss/material-kit-pro-react/components/CreatePostModalStyle.js';
import profilePageStyle from 'assets/jss/material-kit-pro-react/views/pageStyle/LandingPageFeed/CommentStyle.js';
import Button from 'components/CustomButtons/Button.js';
// import profilePicture from "assets/img/faces/camp.jpg";
import closeIcon from 'assets/images/closeIcon.svg';
import comment from 'assets/images/comment.svg';
// import public_black from "assets/images/public_black.svg";
import penIcon from 'assets/images/penIcon.svg';
import trash from 'assets/images/trash.svg';
import cross from 'assets/img/cross.png';
import globe from 'assets/img/icons/globe.png';
import people from 'assets/img/icons/people.png';
import avatar from 'assets/img/candidate_avatar.jpeg';
import noComment from 'assets/img/icons/no-comment.png';
import shareLink from 'assets/img/shareLink.jpg';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import VideoFileOutlinedIcon from '@mui/icons-material/VideoFileOutlined';
import PollOutlinedIcon from '@mui/icons-material/PollOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import SentimentSatisfiedAltOutlinedIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import GroupIcon from '@mui/icons-material/Group';
// import customDropdownStyle from "assets/jss/material-kit-pro-react/components/customDropdownStyle.js";
// import PostImageCrop from "views/Component/ImageCrop/PostImageCrop.js";
// import Separator from "assets/images/Separator.svg";
// import imageUpload from "assets/images/imageupload.svg";
// import video from "assets/images/video.svg";
// import poll from "assets/images/poll.svg";
// import noComments from "assets/images/noComments.svg";
// import connectionOnly from "assets/images/connectionOnly.svg";
// import { imageListItemBarClasses } from "@mui/material";

import axios from 'axios';
import {
  instanceUrl,
  getSocialHashtag,
  getSocialPostProfileTagByAcctId,
  // createSocialPost,
  createSocialHashtag,
  createSocialPostHashtag,
  createSocialPostProfileTag,
  getVisibleOption,
  getSocialPostTypeOption,
  getCommentPermissionByPermissionType,
} from 'configurations/configurations.js';
import PostInput from './PostInput.js';

import { createSocialPost } from 'configurations/redux/socialFeedSlice.js';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconLibrary from 'views/Component/IconLibrary.js';
import Compressor from 'compressorjs';
import reactImageSize from 'react-image-size';
import Picker from 'emoji-picker-react';
import ProfileViewActivityCard from '../../views/Component/ProfileView/MyActivity/ProfileViewActivityCard';
import { ReactTinyLink } from 'react-tiny-link';
import { updateSocialPost } from 'configurations/redux/socialFeedSlice.js';
import JobCard from '../../views/Page/SearchPage/SearchJobPage/JobCard';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import { resetPostClick } from 'configurations/redux/socialFeedSlice.js';
import { isMobileOnly } from 'react-device-detect';
import CustomDialog from '../../components/CustomDialog/CustomDialog.js';
// import styles from "assets/jss/material-kit-pro-react/views/ecommerceStyle";
// import imageToBase64 from "image-to-base64/browser";

// const useStylesDropDown = makeStyles(customDropdownStyle);

const CreatePostModal = props => {
  const classes = makeStyles(CreatePostModalStyle)();
  const classesDialog = makeStyles(profilePageStyle)();
  const dispatch = useDispatch();

  const { user, oriVisibleOptions, oriSocialPostTypeOptions, oriCommentabilityOptions } = props;
  const {
    question,
    options,
    pollTiming,
    pollDurationOptions,
    setPostsList,
    editPost,
    sharePost,
    shareJob,
    groupId,
    postStatus,
  } = props;

  const history = useHistory();
  const [visibility, setVisibility] = useState('');
  const [textValue, setTextValue] = useState('');
  const [socialpostTypeId, setSocialPostTypeId] = useState(0);
  const [commentability, setCommentability] = useState(1);

  const [socialHashTags, setSocialHashTags] = useState([]); // list of hashtags containing keyword
  const [socialProfileTags, setSocialProfileTags] = useState([]); // list of profile tags containing keyword
  const [mappedHashTags, setMappedHashTags] = useState([]); // list of hashtags mapped to the react-mentions data format
  const [mappedProfileTags, setMappedProfileTags] = useState([]); // list of profile tags mapped to the react-mentions data format
  const [hashTagKeyword, setHashTagKeyword] = useState('');
  const [profileTagKeyword, setProfileTagKeyword] = useState('');
  const [newHashTagList, setNewHashTagList] = useState([]); //
  const [addedPostHashTagList, setAddedPostHashTagList] = useState([]); //
  const [addedPostProfileTagList, setAddedPostProfileTagList] = useState([]); //

  const [commentWordNumber, setCommentWordNumber] = React.useState(null);
  const [commentText, setCommentText] = React.useState(null);
  const [commentContent, setCommentContent] = React.useState(null);
  const [commentHTML, setCommentHTML] = React.useState(null);
  const [emojiChoose, setEmojiChoose] = React.useState(null);
  const [anchorElEmoji, setAnchorElEmoji] = useState(null);

  const [visibleOptions, setVisibleOptions] = useState(null);
  const [commentabilityOptions, setCommentabilityOptions] = useState(null);
  const [socialPostTypeOptions, setSocialPostTypeOptions] = useState(null);

  const [addLink, setAddLink] = useState(null);
  const [editPostId, setEditPostId] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [createPost, setCreatePost] = useState(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const snackBarShow = (message, variant, action) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, {
      variant: variant,
      action,
    });
  };
  const onClickDismiss = key => () => {
    closeSnackbar(key);
  };

  const snackbarCustom = title => {
    const action = key => (
      <React.Fragment>
        <IconButton onClick={onClickDismiss(key)}>
          <CloseIcon />
        </IconButton>
      </React.Fragment>
    );
    snackBarShow(title, 'default', action);
  };

  const onEmojiClick = (event, emojiObject) => {
    if (emojiObject.emoji != '🦫') setEmojiChoose(emojiObject.emoji);
  };
  const quillRef = React.createRef(null);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      dispatch(resetPostClick());
      if (oriVisibleOptions) setVisibleOptions(oriVisibleOptions);
      else {
        getVisibleOption()
          .then(res => {
            if (isMounted) setVisibleOptions(res.data.payload);
          })
          .catch(err => console.log(err));
      }
      if (oriSocialPostTypeOptions) setSocialPostTypeOptions(oriSocialPostTypeOptions);
      else {
        getSocialPostTypeOption()
          .then(res => {
            if (isMounted) setSocialPostTypeOptions(res.data.payload);
          })
          .catch(err => console.log(err));
      }
      // Permission type 1 and 2
      if (oriCommentabilityOptions) setCommentabilityOptions(oriCommentabilityOptions);
      else {
        var type = '1,2';
        if (groupId > 0) type = '3';
        getCommentPermissionByPermissionType(type)
          .then(res => {
            if (isMounted) setCommentabilityOptions(res.data.payload);
          })
          .catch(err => console.log(err));
      }
    }
    // console.log(editPost);
    // console.log(sharePost);
    // console.log(shareJob);
    // console.log(groupId);
    if (editPost) {
      setVisibility(editPost.visibleId);
      setCommentability(editPost.cpId);
      setSocialPostTypeId(editPost.typeOptionId);
      setEditPostId(editPost.postId);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      var type = '1,2';
      if (groupId > 0) type = '3';
      getCommentPermissionByPermissionType(type)
        .then(res => {
          if (isMounted) setCommentabilityOptions(res.data.payload);
        })
        .catch(err => console.log(err));
    }
    return () => {
      isMounted = false;
    };
  }, [groupId]);

  useEffect(() => {
    if (visibleOptions) {
      if (groupId == 0) setVisibility(visibleOptions[0].visibleId);
      else if (groupId > 0) setVisibility(visibleOptions[3].visibleId);
    }
  }, [visibleOptions]);

  useEffect(() => {
    if (socialPostTypeOptions) setSocialPostTypeId(socialPostTypeOptions[0].typeOptionId);
  }, [socialPostTypeOptions]);

  useEffect(() => {
    if (commentabilityOptions) setCommentability(commentabilityOptions[0].cpId);
  }, [commentabilityOptions]);

  useEffect(() => {
    let isMounted = true;
    getSocialHashtag({
      email: user.email,
      acctId: user.acctId,
      keyword: hashTagKeyword,
      lastRecordSize: 0,
      requestRecordSize: 10,
      accessToken: localStorage.getItem('token'),
    })
      .then(res => {
        if (isMounted) setSocialHashTags(res.data.payload.hashtagList);
      })
      .catch(err => console.log(err));
    return () => {
      isMounted = false;
    };
  }, [hashTagKeyword]);

  useEffect(() => {
    let isMounted = true;
    getSocialPostProfileTagByAcctId({
      email: user.email,
      acctId: user.acctId,
      keyword: profileTagKeyword,
      lastRecordSize: 0,
      requestRecordSize: 10,
      accessToken: localStorage.getItem('token'),
    })
      .then(res => {
        if (isMounted) setSocialProfileTags(res.data.payload.profileList);
      })
      .catch(err => console.log(err));
    return () => {
      isMounted = false;
    };
  }, [profileTagKeyword]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setMappedHashTags(
        socialHashTags.map(tag => ({
          id: tag.hashId,
          display: tag.hashtagName,
        }))
      );
    }
    return () => {
      isMounted = false;
    };
  }, [socialHashTags]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setMappedProfileTags(
        socialProfileTags.map(tag => ({
          id: tag.acctId,
          display: `${tag.firstName} ${tag.middleName} ${tag.surname}`,
          profilePic: tag.profilePic,
        }))
      );
    }
    return () => {
      isMounted = false;
    };
  }, [socialProfileTags]);

  const storeNewHashTag = name => {
    createSocialHashtag({
      email: user.email,
      acctId: user.acctId,
      hashtagName: name,
      accessToken: localStorage.getItem('token'),
    })
      .then(res => {
        setHashTagKeyword('');
        console.log(res.data);
        setAddedPostHashTagList([...addedPostHashTagList, res.data.payload.social]);
      })
      .catch(err => console.log(err));
  };

  // useEffect(() => {
  // 	if (
  // 		!props.uploadedPhotos.length > 0 &&
  // 		!props.uploadedVideos.length > 0 &&
  // 		!question &&
  // 		(!sharePost || !shareJob)
  // 	) {
  // 		// console.log(commentContent);
  // 		try {
  // 			for (var i = 0; i < commentContent.ops.length; i++) {
  // 				try {
  // 					console.log(commentContent.ops[i].attributes.link);
  // 					if (
  // 						commentContent.ops[i].attributes.link &&
  // 						commentContent.ops[i].attributes.link.length > 0
  // 					) {
  // 						setAddLink(commentContent.ops[i].attributes.link);
  // 						break;
  // 					} else {
  // 						setAddLink(null);
  // 					}
  // 				} catch (e) {
  // 					setAddLink(null);
  // 				}
  // 			}
  // 		} catch (e) {}
  // 	} else {
  // 		// console.log(
  // 		// 	props.uploadedPhotos,
  // 		// 	props.uploadedVideos,
  // 		// 	question,
  // 		// 	sharePost,
  // 		// 	shareJob
  // 		// );
  // 		setAddLink(null);
  // 	}
  // }, [commentContent]);

  useEffect(() => {
    if (props.uploadedPhotos.length > 0 || props.uploadedVideos.length > 0 || question || sharePost || shareJob)
      setAddLink(null);
    // modalResize();
  }, [addLink]);

  useEffect(() => {
    if (createPost) handleSharePostClick(createPost);
  }, [createPost]);

  const handleSharePostClick = description => {
    // console.log("click");
    // console.log(quillRefPush);
    // console.log(quillRefPush.current);
    console.log(description);
    let postTypeId = 0;
    var descHTML = commentHTML;
    var descCount = commentWordNumber;
    var descContent = commentContent;
    if (description) {
      descHTML = description.html;
      descCount = description.length;
      descContent = description.content;
    }
    // if (quillRefPush && quillRefPush.current) {
    // 	quillRefPush.current.editor.focus();
    // 	quillRefPush.current.editor.setSelection(
    // 		quillRefPush.current.editor.getLength(),
    // 		quillRefPush.current.editor.getLength()
    // 	);
    // 	var comment = quillRefPush.current;
    // 	descHTML = comment.state.value;
    // 	descCount =
    // 		comment.state.selection.index + comment.state.selection.length;

    // 	descContent = comment.editor.editor.delta;
    // }
    if (
      !props.uploadedPhotos.length > 0 &&
      !props.uploadedVideos.length > 0 &&
      !question &&
      !descCount > 0 &&
      // (!commentText || !descCheck) &&
      (!sharePost || !shareJob)
    ) {
      snackbarCustom('The post should not be empty.');
      setDisabled(false);
    } else if (descCount > 3000) {
      snackbarCustom('The post exceeding maximum 3000 characters.');
      setDisabled(false);
    } else {
      let postFormData = new FormData();
      console.log(shareJob, sharePost);
      if (shareJob) {
        setSocialPostTypeId(5);
        postTypeId = 5;
      } else if (sharePost) {
        setSocialPostTypeId(4);
        postTypeId = 4;
      } else if (props.uploadedPhotos.length) {
        setSocialPostTypeId(1);
        postTypeId = 1;
      } else if (props.uploadedVideos.length) {
        setSocialPostTypeId(2);
        postTypeId = 2;
      } else if (question) {
        setSocialPostTypeId(3);
        postTypeId = 3;
      } else {
        setSocialPostTypeId(0);
        console.log(0);
      }
      // if (descHTML && descHTML.length > 0)
      // 	descHTML = descHTML.replaceAll('target="_blank"', "");
      // console.log(commentHTML, commentContent, commentWordNumber);
      // console.log(descHTML, descContent, descCount);
      if (editPost) {
        postFormData.append('postId', editPostId);
      }
      postFormData.append('email', user.email);
      postFormData.append('acctId', user.acctId);
      postFormData.append('description', descHTML ? descHTML : ''); // post text
      postFormData.append('visibleId', visibility); // to which type of connections/accounts, this post is visible
      postFormData.append('recordId', groupId); // it tells whether this post is for social feed or group
      var shareId = 0;
      if (sharePost) shareId = sharePost.postId;
      if (shareJob) shareId = shareJob[0].jobPostId;
      postFormData.append('sharedId', shareId);
      postFormData.append('typeOptionId', postTypeId); // type id of the post - there are 4 types of post and each type has its id
      postFormData.append('commentPermissionId', commentability); // who can comment

      var j = 0;
      var k = 0;
      if (descCount > 0) {
        for (let i = 0; i < descContent.ops.length; i++) {
          let mention = descContent.ops[i].insert.mention;
          // console.log(mention);
          if (mention) {
            if (mention.denotationChar == '#') {
              var hashtagName = mention.value.replace(/<a\b[^>]*>/i, '').replace(/<\/a>/i, '');
              postFormData.append(`hashTags[${j}][postHashId]`, 0);
              postFormData.append(`hashTags[${j}][hashtagName]`, hashtagName);
              j++;
            } else if (mention.denotationChar == '@') {
              postFormData.append(`profileTags[${k}][postProfileId]`, 0);
              postFormData.append(`profileTags[${k}][profileId]`, mention.id);
              k++;
            }
          }
        }
      }
      if (!sharePost) {
        if (postTypeId === 1) {
          for (let i = 0; i < props.uploadedPhotos.length; i++) {
            console.log(props.uploadedPhotos[i]);
            try {
              if (props.uploadedPhotos[i].includes('data:image/jpeg;base64')) {
                postFormData.append('attachments', dataURLtoFile(props.uploadedPhotos[i], 'attachment.jpg'));
              }
            } catch (e) {
              postFormData.append('attachments', props.uploadedPhotos[i]);
            }
          }
        } else if (postTypeId === 2) {
          for (let i = 0; i < props.uploadedVideos.length; i++) {
            postFormData.append('attachments', props.uploadedVideos[i]);
          }
        } else if (postTypeId === 3) {
          if (question) {
            postFormData.append('question', question);
            switch (pollTiming) {
              case '3 days':
                postFormData.append('durationId', 2);
                break;
              case '1 week':
                postFormData.append('durationId', 3);
                break;
              case '2 weeks':
                postFormData.append('durationId', 4);
                break;
              default:
                postFormData.append('durationId', 1);
            }
            for (let i = 0; i < options.length; i++) {
              postFormData.append(`poolOptions[${i}][optionDesc]`, options[i]);
            }
          }
        }
      }
      postFormData.append('accessToken', localStorage.getItem('token'));
      postFormData.append('status', postStatus);
      console.log(...postFormData);

      if (editPost) dispatch(updateSocialPost(postFormData, postStatus, groupId));
      else dispatch(createSocialPost(postFormData, postStatus, groupId));

      if (!shareJob) {
        props.savePostData(textValue);
        setTextValue('');
        setSocialPostTypeId(0);
        setVisibility(1);
        setCommentability(1);
        props.selectPhotos(null);
        props.selectVideos(null);
        props.removePoll();
      }
      props.statusChangeHandler('posted');
      if (window.location.href && window.location.href.includes('social-feed/3/')) {
        //in hashtag social page, need return to main page
        history.replace('/social-feed/1/0/');
      }
      setDisabled(false);
    }
  };

  const checkPhoto = async photos => {
    console.log(props.uploadedPhotos);
    props.setError('');
    if (photos.length > 0) props.isLoading(true);
    console.log(photos);
    var newPhotos = [];
    for (var i = 0; i < photos.length; i++) {
      var newPhtoto = await compressPhoto(photos[i]);

      console.log(newPhtoto);
      if (newPhtoto) newPhotos.push(newPhtoto);
      else props.setError('Invalid Image Format Found');
    }
    props.selectPhotos([...props.uploadedPhotos, ...newPhotos]);
    props.isLoading(false);
  };

  const compressPhoto = pictureSource => {
    return new Promise(function (resolve, reject) {
      const processPhoto = async pictureSource => {
        if (pictureSource.type.includes('image')) {
          let imageDataUrl = typeof pictureSource === 'string' ? pictureSource : await readFile(pictureSource);
          const { width, height } = await reactImageSize(imageDataUrl);
          var dimensions = { width: width, height: height };
          if (dimensions) {
            // if (
            // 	pictureSource.size > 10000000 ||
            // 	dimensions.width > 1600 ||
            // 	dimensions.height > 1600
            // ) {
            await new Compressor(pictureSource, {
              quality: 0.8,
              maxWidth: 1600,
              maxHeight: 1600,
              checkOrientation: false,
              success: compressedResult => {
                console.log(compressedResult);
                if (pictureSource.size > 10000000 || dimensions.width > 1600 || dimensions.height > 1600) {
                  // newPhoto.push(compressedResult);
                  resolve(compressedResult);
                  return;
                } else {
                  // newPhoto.push(pictureSource);
                  resolve(pictureSource);
                  return;
                }
              },
            });
          } else {
            // props.isLoading(false);
            resolve(null);
            return;
          }
        } else {
          // props.isLoading(false);
          resolve(null);
          return;
        }
      };
      processPhoto(pictureSource);
    });
  };

  const readFile = file => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const modalResize = () => {
    // console.log("trigger");
    var timer = setInterval(() => {
      try {
        var quillPostEditor = document.getElementById('quillPostEditor');
        var lines = quillPostEditor.getElementsByTagName('p');
        // console.log(lines.length);
        var editorHeight = lines.length * 19.88;
        var oriEditorHeight = quillPostEditor.clientHeight;
        var postInput = document.getElementById('postInput');
        var postAddOn = document.getElementById('postAddOn');
        if (postInput && quillPostEditor && postAddOn) {
          clearInterval(timer);
          var maxSize = window.innerHeight - 74 - 150 - 70;
          if (isMobileOnly) maxSize = window.innerHeight - 74 - 270 - 70;
          if (quillPostEditor.clientHeight > editorHeight) editorHeight = quillPostEditor.clientHeight;
          var postAddOnHeight = 0;
          if (addLink || shareJob || sharePost || question) postAddOnHeight = postAddOn.clientHeight;
          var requiredSize = postAddOnHeight + editorHeight + 50;
          if (isMobileOnly) requiredSize = postAddOnHeight + editorHeight - 20;
          if (maxSize < 0) maxSize = 0;
          if (maxSize > requiredSize) maxSize = requiredSize;
          // else
          postInput.style.overflowY = 'auto';

          // if (modalResize.clientHeight < maxSize)
          postInput.style.height = maxSize + 'px';
          // console.log(oriEditorHeight, postAddOnHeight);
          if (postAddOnHeight + oriEditorHeight > 180) {
            quillPostEditor.style.height = 'auto';
            quillPostEditor.style.minHeight = '180px';
            // postAddOnHeight + oriEditorHeight + "px";
          }
          // postInput.style.maxHeight = maxSize + "px";
          // else modalResize.style.height = "auto";
          // } else {
          // 	setTimeout(() => {
          // 		clearInterval(timer);
          // 	}, 3000);
        }
      } catch (e) {}
    }, 300);
    return () => clearInterval(timer);
  };

  React.useEffect(() => {
    if (document.readyState === 'complete') {
      setTimeout(() => {
        modalResize();
      }, 500);
    }
    window.addEventListener('resize', modalResize);
    return function cleanup() {
      window.removeEventListener('resize', modalResize);
    };
  });

  React.useEffect(() => {
    if (props?.question?.length > 0 || sharePost || props.uploadedPhotos || props.uploadedVideos) {
      console.log(props?.question?.length, sharePost);
      modalResize();
    }
  }, [props?.question, sharePost, props.uploadedPhotos, props.uploadedVideos]);

  const [quillRefPush, setQuillRefPush] = React.useState(null);
  // React.useEffect(() => {
  // 	console.log(quillRefPush, commentHTML, commentWordNumber, commentContent);
  // }, [quillRefPush]);

  const dialogDefault = {
    title: '',
    description: '',
    buttonYes: '',
    buttonNo: '',
  };
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    dialogDefault,
  });

  const confirmProceed = (title, description, buttonYes, buttonNo) => {
    const dialogDefault = {
      title: title,
      description: description,
      buttonYes: buttonYes,
      buttonNo: buttonNo,
    };
    setConfirmDialog({
      isOpen: true,
      dialogDefault,
      onConfirm: () => {
        setConfirmDialog({
          isOpen: false,
          dialogDefault,
        });
      },
      onCancel: () => {
        props.statusChangeHandler('post');
        setConfirmDialog({
          isOpen: false,
          dialogDefault,
        });
      },
    });
  };

  return (
    <React.Fragment>
      {confirmDialog && <CustomDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />}
      <Dialog
        classes={{
          root: classesDialog.modalRoot,
          paper: classesDialog.modalCommentEdit + ' ' + classesDialog.modal + ' ' + classes.dialogStyle,
        }}
        open={props?.isOpen}
        // TransitionComponent={Transition}
        // keepMounted
        // disableBackdropClick
        // onClose={(event, reason) => {
        // 	if (reason !== "backdropClick") {
        // 		onClose(event, reason);
        // 	}
        // }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classesDialog.modalTitleEdit + ' ' + classes.modalHeader}
        >
          <Button
            simple
            className={classesDialog.modalCloseButton}
            key="close"
            aria-label="Close"
            onClick={() => {
              if (
                !props.uploadedPhotos.length > 0 &&
                !props.uploadedVideos.length > 0 &&
                !question &&
                !commentWordNumber > 0 &&
                // (!commentText || !descCheck) &&
                (!sharePost || !shareJob)
              ) {
                props.statusChangeHandler('post');
              } else {
                confirmProceed(
                  'Do you want..',
                  'Do you want to close this post? Changes you have made will not be saved.',
                  'Stay',
                  'Leave'
                );
              }
            }}
          >
            <IconLibrary.Close />
          </Button>
          <h6 className={classesDialog.title}>Post Something</h6>
        </DialogTitle>
        <DialogContent id="classic-modal-slide-description" className={classesDialog.modalBody}>
          <div id="quillEditorPost" style={{ display: 'none' }} />
          {/* <Formik
						initialValues={{
							commentWordNumber: "",
							commentText: "",
							commentHTML: "",
							commentContent: "",
						}}
						onSubmit={(data) => {
							setDisabled(true);
							handleSharePostClick();
						}}
					>
						{({
							handleChange,
							values,
							errors,
							touched,
							handleBlur,
							handleSubmit,
							setFieldValue,
						}) => (
							<form onSubmit={handleSubmit}> */}
          {/* <div className={classes.closeIcon_Infotitle}>
						<p className={classes.InfoTitle}>Post Something </p>
						<img
							src={closeIcon}
							className={classes.closeIcon}
							onClick={() => props.statusChangeHandler("post")}
						/>
					</div> */}

          <hr className={classes.separator} />

          <div className={classes.postSection}>
            <div className={classes.profileSection}>
              <img src={user.profilePic ? user.profilePic : avatar} className={classes.profileImg} />

              <div className={classes.profileInfo}>
                <div className={classes.profileName}>
                  {user.firstName ? user.firstName + ' ' : ''}
                  {user.middleName ? user.middleName + ' ' : ''}
                  {user.surname ? user.surname : ''}
                </div>
                <span className={classes.visibility}>
                  <span className={classes.visibleto}>visible to :</span>
                  {visibleOptions && visibleOptions.length ? (
                    <Select
                      value={visibility}
                      autoWidth
                      onChange={e => setVisibility(e.target.value)}
                      displayEmpty
                      variant="outlined"
                      className={classes.Select}
                      inputProps={{ 'aria-label': 'Without label' }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {groupId == 0 &&
                        visibleOptions.slice(0, 3).map((option, index) => {
                          return (
                            <MenuItem className={classes.MenuItem} value={option.visibleId} key={'menuItem' + index}>
                              <img
                                className={classes.menuItemImg}
                                src={index !== 2 ? globe : people}
                                key={'image' + index}
                              />
                              <span className={classes.mainmenutext} key={'span' + index}>
                                {option.visibleTitle}
                              </span>
                              <div className={classes.mainmenusubtext} key={'div' + index}>
                                {option.visibleDescription}
                              </div>
                            </MenuItem>
                          );
                        })}
                      {groupId > 0 &&
                        visibleOptions.slice(3, 4).map((option, index) => {
                          return (
                            <MenuItem className={classes.MenuItem} value={option.visibleId} key={'menuItem' + index}>
                              <img className={classes.menuItemImg} src={people} key={'image' + index} />
                              <span className={classes.mainmenutext} key={'span' + index}>
                                {option.visibleTitle}
                              </span>
                              <div className={classes.mainmenusubtext} key={'div' + index}>
                                {option.visibleDescription}
                              </div>
                            </MenuItem>
                          );
                        })}
                    </Select>
                  ) : (
                    <></>
                  )}
                </span>
              </div>
            </div>
            <div id="postInput" className={classes.postInput}>
              <div className={classes.inputfield}>
                {/* <div className={classes.input}> */}
                <PostInput
                  post={editPost}
                  emojiChoose={emojiChoose}
                  resetEmoji={setEmojiChoose}
                  getLength={setCommentWordNumber}
                  // getText={setCommentText}
                  getHTML={setCommentHTML}
                  getContent={setCommentContent}
                  // onChange={handleChange}
                  setAddLink={setAddLink}
                  postId={editPost ? editPost.postId : 0}
                  quillRefPush={setQuillRefPush}
                  postClick={disabled}
                  createPost={setCreatePost}
                  groupId={groupId}
                />
              </div>
              <div id="postAddOn" style={{ padding: '5px' }}>
                {addLink &&
                props.uploadedPhotos.length == 0 &&
                props.uploadedVideos.length == 0 &&
                props?.question?.length == 0 &&
                !sharePost ? (
                  <div className={classes.shareLink}>
                    <ReactTinyLink
                      cardSize="small"
                      showGraphic={true}
                      maxLine={2}
                      minLine={1}
                      url={addLink}
                      defaultMedia={shareLink}
                      proxyUrl={instanceUrl}
                    />
                  </div>
                ) : null}
                {sharePost && (
                  <div className={classes.sharePost}>
                    <ProfileViewActivityCard
                      // key={post.postId}
                      post={sharePost}
                      reportTypesList={null}
                      reportReasonsList={null}
                      updateFeedHandler={null}
                      isCopyPost={false}
                      handleEditPostClick={null}
                      hashtagMatch={null}
                      hideReactionBar={true}
                      handleSharePostClick={null}
                      searchPost={false}
                      isGroupMember={false}
                    />
                  </div>
                )}

                {shareJob && shareJob.length > 0 && (
                  <div className={classes.sharePost}>
                    {/* <ShareJobCard
											// key={post.postId}
											job={shareJob}
										/> */}
                    <JobCard item={shareJob[0]} />
                  </div>
                )}

                {props?.question?.length > 0 && (
                  <div className={classes.pollSectionInPost}>
                    {!editPost && (
                      <div className={classes.editanddelete}>
                        <div
                          onClick={() => {
                            props.statusChangeHandler('poll');
                            props.handleCreatePostClick('poll');
                          }}
                        >
                          <EditOutlinedIcon
                            sx={{
                              color: '#6F52ED',
                              width: '24px',
                              height: '24px',
                              marginBottom: '-6px',
                            }}
                          />
                          {/* <img src={penIcon} /> */}
                          <span className={classes.edit}>Edit</span>
                        </div>
                        <div
                          onClick={() => {
                            props.removePoll();
                          }}
                        >
                          <DeleteForeverOutlinedIcon
                            style={{
                              fill: '#E42B4D',
                              width: '24px',
                              height: '24px',
                              marginBottom: '-6px',
                            }}
                          />
                          {/* <img src={trash} /> */}
                          <span className={classes.delete}>Delete</span>
                        </div>
                      </div>
                    )}
                    <div className={classes.QuestionandOptions}>
                      <div className={classes.Question}>
                        <span>{props?.question}</span>
                      </div>
                      <div className={classes.optionGroup}>
                        {props?.options?.map((item, index) => (
                          <div className={classes.Options} key={'Options ' + index}>
                            <span>{item}</span>
                          </div>
                        ))}
                      </div>
                      <div className={classes.pollDetails}>
                        <span className={classes.viewandresult}>0 votes</span>
                        <span className={classes.daysLeft}>{props?.pollTiming} left </span>
                        <span className={classes.viewandresult}>view result</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <hr className={classes.separator} />

          {props.uploadedPhotos && props.uploadedPhotos.length ? (
            <div className={classes.attachmentList}>
              {props.uploadedPhotos.map((image, index) => (
                <div
                  draggable={!editPost ? true : false}
                  className={classes.attachmentListItem}
                  onDragOver={e => e.preventDefault()}
                  onDragStart={e => {
                    e.target.style.opacity = 0.4;
                    props.onPhotosListItemDragStart(index);
                  }}
                  onDragEnd={e => (e.target.style.opacity = 1)}
                  onDrop={() => props.onPhotosListItemDrop(index)}
                  key={'attachmentListItem' + index}
                >
                  <img
                    src={cross}
                    alt=""
                    className={classes.removeAttachment}
                    onClick={e => {
                      e.stopPropagation();
                      props.removeAnUploadedPhoto(index);
                    }}
                    key={'removeAttachment' + index}
                    style={{
                      visibility: editPost ? 'hidden' : 'visible',
                    }}
                  />
                  <div
                    className={classes.attachmentListItemAttachmentContainer}
                    key={'attachmentListItemAttachmentContainer' + index}
                  >
                    <img
                      src={typeof image === 'string' ? image : URL.createObjectURL(image)}
                      alt=""
                      className={classes.attachmentListItemContent}
                      key={'attachmentListItemContent' + index}
                    />
                  </div>
                  <div
                    className={classes.attachmentListItemAttachmentContainerOverlay}
                    onClick={() => {
                      if (!editPost) {
                        props.statusChangeHandler('photo');
                        props.onPhotosListItemClick(index);
                      }
                    }}
                  ></div>
                </div>
              ))}
            </div>
          ) : (
            <></>
          )}

          {props.uploadedVideos && props.uploadedVideos.length ? (
            <div className={classes.attachmentList}>
              {props.uploadedVideos.map((video, index) => (
                <div
                  draggable={false}
                  className={classes.attachmentListItem}
                  // onDragOver={(e) => e.preventDefault()}
                  // onDragStart={(e) => {
                  // 	e.target.style.opacity = 0.4;
                  // 	props.onVideoListItemDragStart(index);
                  // }}
                  // onDragEnd={(e) => {
                  // 	e.target.style.opacity = 1;
                  // }}
                  // onDrop={() => {
                  // 	props.onVideoListItemDrop(index);
                  // }}
                  key={'attachmentListItem' + index}
                  onClick={() => {
                    console.log('click');
                    if (!editPost) {
                      props.statusChangeHandler('video');
                      props.onVideosListItemClick(index);
                    }
                  }}
                >
                  <img
                    src={cross}
                    alt=""
                    className={classes.removeAttachment}
                    onClick={() => {
                      if (!editPost) props.removeAnUploadedVideo(index);
                    }}
                    key={'removeAttachment' + index}
                    style={{
                      visibility: editPost ? 'hidden' : 'visible',
                    }}
                  />
                  <div
                    className={classes.attachmentListItemAttachmentContainer}
                    key={'attachmentListItemAttachmentContainer' + index}
                  >
                    <video
                      className={classes.attachmentListItemContent}
                      onClick={() => {
                        if (!editPost) {
                          props.statusChangeHandler('video');
                          props.onVideosListItemClick(index);
                        }
                      }}
                      key={'attachmentListItemContent' + index}
                    >
                      <source
                        src={
                          typeof video === 'string' && video.includes('https://') ? video : URL.createObjectURL(video)
                        }
                        key={'source' + index}
                      />
                    </video>
                  </div>
                  <div className={classes.attachmentListItemAttachmentContainerOverlay}></div>
                </div>
              ))}
            </div>
          ) : (
            <></>
          )}

          <div className={classes.createPostFooter}>
            <div className={classes.createPostFooterLeft}>
              {!sharePost && !shareJob && !editPost && (
                <div className={classes.postTypeOptionContainer}>
                  <label htmlFor="upload-photo">
                    {/* <svg
										xmlns="http://www.w3.org/2000/svg"
										width="22.5"
										height="22.5"
										viewBox="0 0 22.5 22.5"
									>
										<path
											id="Path"
											d="M20,0H2.5A2.507,2.507,0,0,0,0,2.5V20a2.507,2.507,0,0,0,2.5,2.5H20A2.507,2.507,0,0,0,22.5,20V2.5A2.507,2.507,0,0,0,20,0Z"
											fill="#6f52ed"
										/>
									</svg> */}

                    <PhotoOutlinedIcon
                      style={{
                        fill:
                          (props.uploadedVideos && props.uploadedVideos.length > 0) || props?.question?.length > 0
                            ? 'rgb(111, 82, 237, 0.5)'
                            : '#6f52ed',
                        width: '24px',
                        height: '24px',
                      }}
                      // className={styles.clapIcon}
                    />
                    <p
                      className={classes.imageText}
                      style={{
                        color:
                          (props.uploadedVideos && props.uploadedVideos.length > 0) || props?.question?.length > 0
                            ? 'rgb(111, 82, 237, 0.5)'
                            : '#6f52ed',
                      }}
                    >
                      Image{' '}
                    </p>
                  </label>
                  <input
                    type="file"
                    id="upload-photo"
                    name="photo"
                    multiple
                    accept="image/*"
                    style={{ display: 'none' }}
                    // value=""
                    onClick={e => {
                      var elem = document.getElementById('upload-photo');
                      if (elem) elem.value = '';
                      if ((props.uploadedVideos && props.uploadedVideos.length > 0) || props?.question?.length > 0) {
                        e.preventDefault();
                      } else {
                        props.handleCreatePostClick('image');
                        props.selectPhotos(null);
                        props.selectVideos(null);
                        props.removePoll();
                      }
                    }}
                    onChange={e => {
                      // props.selectVideos([]);
                      setSocialPostTypeId(1);
                      checkPhoto(e.target.files);
                      // props.selectPhotos([
                      // 	...props.uploadedPhotos,
                      // 	...e.target.files,
                      // ]);
                      props.statusChangeHandler('photo');
                    }}
                  />
                </div>
              )}
              {!sharePost && !shareJob && !editPost && (
                <div className={classes.postTypeOptionContainer}>
                  <label htmlFor="upload-video">
                    {/* <svg
										xmlns="http://www.w3.org/2000/svg"
										width="28"
										height="19"
										viewBox="0 0 28 19"
									>
										<path
											id="Path"
											d="M20.222,0H1.556A1.574,1.574,0,0,0,0,1.583V17.417A1.574,1.574,0,0,0,1.556,19H20.222a1.574,1.574,0,0,0,1.556-1.583V11.875L28,18.208V.792L21.778,7.125V1.583A1.574,1.574,0,0,0,20.222,0Z"
											fill="#6f52ed"
										/>
									</svg> */}

                    <VideoFileOutlinedIcon
                      style={{
                        fill:
                          (props.uploadedPhotos && props.uploadedPhotos.length > 0) || props?.question?.length > 0
                            ? 'rgb(111, 82, 237, 0.5)'
                            : '#6f52ed',
                        width: '24px',
                        height: '24px',
                      }}
                      // className={styles.clapIcon}
                    />
                    <p
                      className={classes.imageText}
                      style={{
                        color:
                          (props.uploadedPhotos && props.uploadedPhotos.length > 0) || props?.question?.length > 0
                            ? 'rgb(111, 82, 237, 0.5)'
                            : '#6f52ed',
                      }}
                    >
                      Video
                    </p>
                  </label>
                  <input
                    type="file"
                    // multiple
                    accept="video/*"
                    name="video"
                    id="upload-video"
                    style={{ display: 'none' }}
                    value=""
                    onClick={e => {
                      if ((props.uploadedPhotos && props.uploadedPhotos.length > 0) || props?.question?.length > 0) {
                        e.preventDefault();
                      } else {
                        props.handleCreatePostClick('video');
                        props.selectPhotos(null);
                        props.selectVideos(null);
                        props.removePoll();
                      }
                    }}
                    onChange={e => {
                      // props.selectPhotos([]);
                      setSocialPostTypeId(2); // id is 2 when post type is video
                      // props.isLoading(true);
                      props.selectVideos([
                        // ...props.uploadedVideos,
                        ...e.target.files,
                      ]);
                      props.statusChangeHandler('video');
                    }}
                  />
                </div>
              )}
              {!sharePost && !shareJob && !editPost && (
                <div
                  className={classes.postTypeOptionContainer}
                  onClick={e => {
                    if (
                      (props.uploadedPhotos && props.uploadedPhotos.length > 0) ||
                      (props.uploadedVideos && props.uploadedVideos.length > 0)
                    ) {
                      e.preventDefault();
                    } else {
                      props.selectPhotos(null);
                      props.selectVideos(null);
                      props.statusChangeHandler('poll');
                      props.removePoll();
                      props.handleCreatePostClick('poll');
                      setSocialPostTypeId(3); // id is 3 when post type is poll
                    }
                  }}
                >
                  <label>
                    {/* <svg
										onClick={() => {
											props.selectPhotos(null);
											props.selectVideos(null);
											props.statusChangeHandler("post");
											props.removePoll();
											props.handleCreatePostClick("poll");
											setSocialPostTypeId(3); // id is 3 when post type is poll
										}}
										xmlns="http://www.w3.org/2000/svg"
										width="22.5"
										height="22.5"
										viewBox="0 0 22.5 22.5"
									>
										<path
											id="Path"
											d="M20,0H2.5A2.507,2.507,0,0,0,0,2.5V20a2.507,2.507,0,0,0,2.5,2.5H20A2.507,2.507,0,0,0,22.5,20V2.5A2.507,2.507,0,0,0,20,0Z"
											fill="#6f52ed"
										/>
									</svg> */}
                    <PollOutlinedIcon
                      style={{
                        fill:
                          (props.uploadedPhotos && props.uploadedPhotos.length > 0) ||
                          (props.uploadedVideos && props.uploadedVideos.length > 0)
                            ? 'rgb(111, 82, 237, 0.5)'
                            : '#6f52ed',
                        width: '24px',
                        height: '24px',
                      }}
                    />
                    <p
                      className={classes.imageText}
                      style={{
                        color:
                          (props.uploadedPhotos && props.uploadedPhotos.length > 0) ||
                          (props.uploadedVideos && props.uploadedVideos.length > 0)
                            ? 'rgb(111, 82, 237, 0.5)'
                            : '#6f52ed',
                      }}
                    >
                      Poll
                    </p>
                  </label>
                </div>
              )}
              {/* <div
											className={classes.postTypeOptionContainer}
											onClick={(e) => {
												setAnchorElEmoji(e.currentTarget);
											}}
										>
											<label>
												<SentimentSatisfiedAltOutlinedIcon
													style={{
														fill: "#6f52ed",
														width: "24px",
														height: "24px",
													}}
												/>
												<p className={classes.imageText}>Emoji</p>
											</label>
										</div> */}
            </div>
            {/* <Popover
										id={anchorElEmoji ? "emoji-popover" : undefined}
										open={anchorElEmoji ? true : false}
										anchorEl={anchorElEmoji}
										onClose={() => {
											setAnchorElEmoji(null);
										}}
										anchorOrigin={{
											vertical: "bottom",
											horizontal: "center",
										}}
										transformOrigin={{
											vertical: "top",
											horizontal: "center",
										}}
									>
										<div>
											<Picker
												onEmojiClick={onEmojiClick}
												groupVisibility={{
													flags: false,
													recently_used: false,
												}}
											/>
										</div>
									</Popover> */}
            <div className={classes.createPostFooterRight}>
              <div className={classes.footerCommentSection}>
                <div className={classes.commentability}>
                  {/* <img src={comment}></img> */}
                  <ChatOutlinedIcon
                    style={{
                      fill: '#383838',
                      width: '22.5px',
                      height: '22.5px',
                    }}
                  />
                  <span className={classes.visibleto}>who can comment</span>
                </div>
                {commentabilityOptions && commentabilityOptions.length ? (
                  <Select
                    value={commentability}
                    autoWidth
                    onChange={e => setCommentability(e.target.value)}
                    displayEmpty
                    variant="outlined"
                    className={classes.Select}
                    inputProps={{ 'aria-label': 'Without label' }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {commentabilityOptions.map((option, index) => (
                      <MenuItem className={classes.MenuItem} value={option.cpId} key={'MenuItem-' + index}>
                        <img
                          className={classes.menuItemImg}
                          src={groupId > 0 ? (index !== 1 ? globe : noComment) : index !== 2 ? globe : noComment}
                          key={'menuItemImg-' + index}
                        />
                        <span className={classes.mainmenutext} key={'mainmenutext-' + index}>
                          {option.cpTitle}
                        </span>
                        <div className={classes.mainmenusubtext} key={'mainmenusubtext-' + index}>
                          {option.cpDesciption}
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  <></>
                )}
              </div>

              <Button
                color="oppty"
                className={classes.sharePostBtn}
                onClick={() => {
                  // handleSubmit();
                  setTimeout(() => {
                    setDisabled(true);
                  }, 500);
                  // setDisabled(true);
                  // handleSharePostClick();
                }}
                disabled={disabled}
              >
                Share Post
              </Button>
            </div>
          </div>
          {/* </form>
						)}
					</Formik> */}
        </DialogContent>
        <div className={classesDialog.modalFooter}></div>
      </Dialog>
    </React.Fragment>
  );
};

export default React.memo(CreatePostModal);
