import React, { useState, useEffect, useCallback } from "react";
import clsx from "clsx";

import Cropper from "react-easy-crop";
import Slider from "@material-ui/core/Slider";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { makeStyles } from "@material-ui/core/styles";

import { getCroppedImg, getRotatedImage } from "./canvasUtils";
import { getOrientation } from "get-orientation/browser";
import Card from "components/Card/Card";
import CardMedia from "@material-ui/core/CardMedia";

import PostImageDialog from "./PostImageDialog";
// import CustomAlertDialog from "components/CustomeDialog/CustomeAlertDialog";

import imageCropStyle from "../../../assets/jss/material-kit-pro-react/views/pageStyle/ImageCropComponent/imageCropStyle.js";
import postImageCropStyles from "../../../assets/jss/material-kit-pro-react/components/postImageCropStyles.js";
import Compressor from "compressorjs";
import reactImageSize from "react-image-size";

const useStyles = makeStyles(imageCropStyle);
const useStylesPostImageCrop = makeStyles(postImageCropStyles);

const ORIENTATION_TO_ANGLE = {
	3: 180,
	6: 90,
	8: -90,
};

const PostImageCrop = ({
	pictureSource,
	backgroundTemplateFlag,
	saveCroppedPhoto,
	dimension,
	// uploadedPhotos,
}) => {
	const classes = useStyles();
	const classesPost = useStylesPostImageCrop();

	const [aspectRatio, setAspectRatio] = useState({
		width: 1,
		height: 1,
	});
	const [activeRatioIcon, setActiveRatioIcon] = useState("original");
	const [previewPhotoIndex, setPreviewPhotoIndex] = useState(0);

	useEffect(() => {
		switch (activeRatioIcon) {
			case "free":
				setAspectRatio({
					width: 1,
					height: 1,
				});
				break;
			case "square":
				setAspectRatio({
					width: 1,
					height: 1,
				});
				break;
			case "2:3":
				setAspectRatio({
					width: 2,
					height: 3,
				});
				break;
			case "3:2":
				setAspectRatio({
					width: 3,
					height: 2,
				});
				break;
			case "4:3":
				setAspectRatio({
					width: 4,
					height: 3,
				});
				break;
			case "4:5":
				setAspectRatio({
					width: 4,
					height: 5,
				});
				break;
			default:
				setAspectRatio({
					width: dimensions ? dimensions.width : 1,
					height: dimensions ? dimensions.height : 1,
				});
		}
	}, [activeRatioIcon]);

	const [imageSrc, setImageSrc] = useState(null);
	const [disableSave, setDisableSave] = useState(true);
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [rotation, setRotation] = useState(0);
	const [zoom, setZoom] = useState(1);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
	const [croppedImage, setCroppedImage] = useState(null);
	const [isPostImageDialogOpen, setIsPostImageDialogOpen] = useState(false);
	const [isURLPic, setIsURLPic] = useState(false);
	const [dimensions, setDimensions] = useState(null);
	const [originalSpec, setOriginalSpec] = useState(null);

	// useEffect(() => {
	//   if (uploadedPhotos && uploadedPhotos.length) {
	//     setImageSrc(
	//       typeof uploadedPhotos[0] == "string"
	//         ? uploadedPhotos[0]
	//         : URL.createObjectURL(uploadedPhotos[0])
	//     );
	//   }
	// }, []);

	// useEffect(() => {
	//   setImageSrc(
	//     typeof uploadedPhotos[previewPhotoIndex] == "string"
	//       ? uploadedPhotos[previewPhotoIndex]
	//       : URL.createObjectURL(uploadedPhotos[previewPhotoIndex])
	//   );
	// }, [previewPhotoIndex]);

	React.useEffect(() => {
		if (dimension) {
			// console.log(dimension);
			if (dimension.orientation) {
				var dimensionExchange = {
					width: dimension.height,
					height: dimension.width,
				};
				setDimensions(dimensionExchange);
				setAspectRatio(dimensionExchange);
			} else {
				setDimensions(dimension);
				setAspectRatio({
					width: dimension.width,
					height: dimension.height,
				});
			}
		}
		setOriginalSpec({
			crop: crop,
			rotation: rotation,
			zoom: zoom,
			activeRatioIcon: activeRatioIcon,
		});
	}, [dimension]);

	useEffect(() => {
		if (backgroundTemplateFlag === 0) setImageSrc(pictureSource);
		else setImageSrc(null);
		setDisableSave(true);
	}, [pictureSource]);

	const readFile = (file) => {
		return new Promise((resolve) => {
			const reader = new FileReader();
			reader.addEventListener("load", () => resolve(reader.result), false);
			reader.readAsDataURL(file);
		});
	};

	useEffect(() => {
		setIsURLPic(false);
	}, [imageSrc]);

	const showCroppedImage = useCallback(async () => {
		try {
			if (
				originalSpec.crop.x == crop.x &&
				originalSpec.crop.y == crop.y &&
				originalSpec.rotation == rotation &&
				originalSpec.zoom == zoom &&
				originalSpec.activeRatioIcon == activeRatioIcon
			) {
				// console.log(imageSrc);
				saveCroppedPhoto(imageSrc);
			} else {
				const croppedImage = await getCroppedImg(
					imageSrc,
					croppedAreaPixels,
					rotation
				);
				setCroppedImage(croppedImage);
				setIsPostImageDialogOpen(true);
			}
		} catch (e) {
			console.error(e);
		}
	}, [imageSrc, croppedAreaPixels, rotation]);

	const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels);
	}, []);

	return (
		<React.Fragment>
			{/* <CustomAlertDialog
        confirmDialog={errorDialog}
        setConfirmDialog={setErrorDialog}
      /> */}
			{imageSrc && !isURLPic ? (
				<div>
					<React.Fragment>
						<div className={classesPost.ratios}>
							{/* <div
                className={classesPost.singleRatioContainer}
                onClick={() => {
                  setActiveRatioIcon("original");
                }}
              >
                <div
                  className={
                    activeRatioIcon == "original"
                      ? classesPost.ratioIconActive
                      : classesPost.ratioIconNormal
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23.684"
                    height="23.557"
                    viewBox="0 0 23.684 23.557"
                  >
                    <g id="Icon" transform="translate(1 1)">
                      <rect
                        id="Rectangle"
                        width="21.27"
                        height="21.143"
                        rx="2"
                        fill={
                          activeRatioIcon == "original" ? "#574FFF" : "none"
                        }
                        stroke={
                          activeRatioIcon == "original" ? "#fff" : "#77838f"
                        }
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-miterlimit="10"
                        stroke-width="2"
                      />
                      <ellipse
                        id="Oval"
                        cx="1.772"
                        cy="1.762"
                        rx="1.772"
                        ry="1.762"
                        transform="translate(4.727 4.698)"
                        fill={
                          activeRatioIcon == "original" ? "#574FFF" : "none"
                        }
                        stroke={
                          activeRatioIcon == "original" ? "#fff" : "#77838f"
                        }
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-miterlimit="10"
                        stroke-width="2"
                      />
                      <path
                        id="Path"
                        d="M18.907,5.873,13,0,0,12.921"
                        transform="translate(2.363 8.222)"
                        fill={
                          activeRatioIcon == "original" ? "#574FFF" : "none"
                        }
                        stroke={
                          activeRatioIcon == "original" ? "#fff" : "#77838f"
                        }
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-miterlimit="10"
                        stroke-width="2"
                      />
                    </g>
                  </svg>
                </div>
                <div
                  className={
                    activeRatioIcon == "original"
                      ? classesPost.ratioTextActive
                      : classesPost.ratioTextNormal
                  }
                >
                  Original
                </div>
              </div>

              <div
                className={classesPost.singleRatioContainer}
                onClick={() => {
                  setActiveRatioIcon("free");
                }}
              >
                <div
                  className={
                    activeRatioIcon == "free"
                      ? classesPost.ratioIconActive
                      : classesPost.ratioIconNormal
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22.952"
                    height="23.143"
                    viewBox="0 0 22.952 23.143"
                  >
                    <path
                      id="Icon"
                      d="M18.625,21.143H15.133l5.819-5.873v3.524A2.341,2.341,0,0,1,18.625,21.143Zm-12.8,0H2.328A2.341,2.341,0,0,1,0,18.794V15.269l5.819,5.872ZM0,5.873V2.349A2.341,2.341,0,0,1,2.328,0H5.82L0,5.872Zm20.952,0,0,0L15.133,0h3.492a2.341,2.341,0,0,1,2.327,2.349V5.873Z"
                      transform="translate(1 1)"
                      fill={activeRatioIcon == "free" ? "#574FFF" : "#fff"}
                      stroke={activeRatioIcon == "free" ? "#fff" : "#77838f"}
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                      stroke-width="2"
                    />
                  </svg>
                </div>
                <div
                  className={
                    activeRatioIcon == "free"
                      ? classesPost.ratioTextActive
                      : classesPost.ratioTextNormal
                  }
                >
                  Free
                </div>
              </div> */}
							<div
								className={classesPost.singleRatioContainer}
								onClick={() => {
									setActiveRatioIcon("original");
								}}
							>
								<div
									className={
										activeRatioIcon == "original"
											? classesPost.ratioIconActive
											: classesPost.ratioIconNormal
									}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="23.684"
										height="23.557"
										viewBox="0 0 23.684 23.557"
									>
										<g id="Icon" transform="translate(1 1)">
											<rect
												id="Rectangle"
												width="21.27"
												height="21.143"
												rx="2"
												fill={
													activeRatioIcon == "original" ? "#574FFF" : "none"
												}
												stroke={
													activeRatioIcon == "original" ? "#fff" : "#77838f"
												}
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeMiterlimit="10"
												strokeWidth="2"
											/>
											<ellipse
												id="Oval"
												cx="1.772"
												cy="1.762"
												rx="1.772"
												ry="1.762"
												transform="translate(4.727 4.698)"
												fill={
													activeRatioIcon == "original" ? "#574FFF" : "none"
												}
												stroke={
													activeRatioIcon == "original" ? "#fff" : "#77838f"
												}
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeMiterlimit="10"
												strokeWidth="2"
											/>
											<path
												id="Path"
												d="M18.907,5.873,13,0,0,12.921"
												transform="translate(2.363 8.222)"
												fill={
													activeRatioIcon == "original" ? "#574FFF" : "none"
												}
												stroke={
													activeRatioIcon == "original" ? "#fff" : "#77838f"
												}
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeMiterlimit="10"
												strokeWidth="2"
											/>
										</g>
									</svg>
								</div>
								<div
									className={
										activeRatioIcon == "original"
											? classesPost.ratioTextActive
											: classesPost.ratioTextNormal
									}
								>
									Original
								</div>
							</div>
							<div
								className={classesPost.singleRatioContainer}
								onClick={() => {
									setActiveRatioIcon("square");
								}}
							>
								<div
									className={
										activeRatioIcon == "square"
											? classesPost.ratioIconActive
											: classesPost.ratioIconNormal
									}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="25.263"
										height="25.371"
										viewBox="0 0 25.263 25.371"
									>
										<g
											id="Rectangle"
											fill={activeRatioIcon == "square" ? "#574FFF" : "#fff"}
											stroke={activeRatioIcon == "square" ? "#fff" : "#77838f"}
											strokeMiterlimit="10"
											strokeWidth="2"
										>
											<rect
												width="25.263"
												height="25.371"
												rx="4"
												stroke="none"
											/>
											<rect
												x="1"
												y="1"
												width="23.263"
												height="23.371"
												rx="3"
												fill={activeRatioIcon == "square" ? "#574FFF" : "none"}
											/>
										</g>
									</svg>
								</div>
								<div
									className={
										activeRatioIcon == "square"
											? classesPost.ratioTextActive
											: classesPost.ratioTextNormal
									}
								>
									Square
								</div>
							</div>

							<div
								className={classesPost.singleRatioContainer}
								onClick={() => {
									setActiveRatioIcon("2:3");
								}}
							>
								<div
									className={
										activeRatioIcon == "2:3"
											? classesPost.ratioIconActive
											: classesPost.ratioIconNormal
									}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="14.667"
										height="25.371"
										viewBox="0 0 14.667 25.371"
									>
										<g
											id="Rectangle"
											fill={activeRatioIcon == "2:3" ? "#574FFF" : "#fff"}
											stroke={activeRatioIcon == "2:3" ? "#fff" : "#77838f"}
											strokeMiterlimit="10"
											strokeWidth="2"
										>
											<rect
												width="14.667"
												height="25.371"
												rx="4"
												stroke="none"
											/>
											<rect
												x="1"
												y="1"
												width="12.667"
												height="23.371"
												rx="3"
												fill={activeRatioIcon == "2:3" ? "#574FFF" : "none"}
											/>
										</g>
									</svg>
								</div>
								<div
									className={
										activeRatioIcon == "2:3"
											? classesPost.ratioTextActive
											: classesPost.ratioTextNormal
									}
								>
									2:3
								</div>
							</div>

							<div
								className={classesPost.singleRatioContainer}
								onClick={() => {
									setActiveRatioIcon("3:2");
								}}
							>
								<div
									className={
										activeRatioIcon == "3:2"
											? classesPost.ratioIconActive
											: classesPost.ratioIconNormal
									}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="25.143"
										height="14.8"
										viewBox="0 0 25.143 14.8"
									>
										<g
											id="Rectangle"
											fill={activeRatioIcon == "3:2" ? "#574FFF" : "#fff"}
											stroke={activeRatioIcon == "3:2" ? "#fff" : "#77838f"}
											strokeMiterlimit="10"
											strokeWidth="2"
										>
											<rect width="25.143" height="14.8" rx="4" stroke="none" />
											<rect
												x="1"
												y="1"
												width="23.143"
												height="12.8"
												rx="3"
												fill={activeRatioIcon == "3:2" ? "#574FFF" : "none"}
											/>
										</g>
									</svg>
								</div>
								<div
									className={
										activeRatioIcon == "3:2"
											? classesPost.ratioTextActive
											: classesPost.ratioTextNormal
									}
								>
									3:2
								</div>
							</div>

							<div
								className={classesPost.singleRatioContainer}
								onClick={() => {
									setActiveRatioIcon("4:3");
								}}
							>
								<div
									className={
										activeRatioIcon == "4:3"
											? classesPost.ratioIconActive
											: classesPost.ratioIconNormal
									}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="31.429"
										height="23.257"
										viewBox="0 0 31.429 23.257"
									>
										<g
											id="Rectangle"
											fill={activeRatioIcon == "4:3" ? "#574FFF" : "#fff"}
											stroke={activeRatioIcon == "4:3" ? "#fff" : "#77838f"}
											strokeMiterlimit="10"
											strokeWidth="2"
										>
											<rect
												width="31.429"
												height="23.257"
												rx="4"
												stroke="none"
											/>
											<rect
												x="1"
												y="1"
												width="29.429"
												height="21.257"
												rx="3"
												fill={activeRatioIcon == "4:3" ? "#574FFF" : "none"}
											/>
										</g>
									</svg>
								</div>
								<div
									className={
										activeRatioIcon == "4:3"
											? classesPost.ratioTextActive
											: classesPost.ratioTextNormal
									}
								>
									4:3
								</div>
							</div>

							<div
								className={classesPost.singleRatioContainer}
								onClick={() => {
									setActiveRatioIcon("4:5");
								}}
							>
								<div
									className={
										activeRatioIcon == "4:5"
											? classesPost.ratioIconActive
											: classesPost.ratioIconNormal
									}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="20.952"
										height="25.371"
										viewBox="0 0 20.952 25.371"
									>
										<g
											id="Rectangle"
											fill={activeRatioIcon == "4:5" ? "#574FFF" : "#fff"}
											stroke={activeRatioIcon == "4:5" ? "#fff" : "#77838f"}
											strokeMiterlimit="10"
											strokeWidth="2"
										>
											<rect
												width="20.952"
												height="25.371"
												rx="4"
												stroke="none"
											/>
											<rect
												x="1"
												y="1"
												width="18.952"
												height="23.371"
												rx="3"
												fill={activeRatioIcon == "4:5" ? "#574FFF" : "none"}
											/>
										</g>
									</svg>
								</div>
								<div
									className={
										activeRatioIcon == "4:5"
											? classesPost.ratioTextActive
											: classesPost.ratioTextNormal
									}
								>
									4:5
								</div>
							</div>
						</div>

						<div className={classes.cropContainer}>
							<Cropper
								image={imageSrc}
								crop={crop}
								rotation={rotation}
								zoom={zoom}
								cropShape="rect"
								aspect={aspectRatio.width / aspectRatio.height}
								onCropChange={setCrop}
								onRotationChange={setRotation}
								onCropComplete={onCropComplete}
								onZoomChange={setZoom}
							/>
						</div>

						<PostImageDialog
							img={croppedImage}
							isOpen={isPostImageDialogOpen}
							closeDialog={() => {
								setIsPostImageDialogOpen(false);
							}}
							saveCroppedPhoto={(photo) => {
								saveCroppedPhoto(photo);
							}}
						/>
					</React.Fragment>

					<div className={classesPost.options}>
						<div className={classesPost.optionName}>Zoom:</div>
						<div className={classesPost.slider}>
							<Slider
								value={zoom}
								min={1}
								max={3}
								step={0.1}
								aria-labelledby="Zoom"
								//classes={{ container: "slider" }}
								onChange={(e, zoom) => setZoom(zoom)}
							/>
						</div>
						<div className={classesPost.optionName}>Rotate:</div>
						<div className={classesPost.slider}>
							<Slider
								value={rotation}
								min={0}
								max={360}
								step={1}
								aria-labelledby="Rotation"
								//classes={{ container: "slider" }}
								onChange={(e, rotation) => setRotation(rotation)}
							/>
						</div>
					</div>

					{/* <div className={classesPost.photosList}>
            <div className={classesPost.photosListContainer}>
              {uploadedPhotos
                ? uploadedPhotos.map((image, index) => (
                    <div className={classesPost.photosListItem}>
                      <div
                        className={clsx({
                          [classesPost.photosListItemPhotoContainer]: true,
                          [classesPost.selectedPhoto]:
                            index === previewPhotoIndex,
                        })}
                      >
                        <img
                          className={classesPost.photosListItemPhoto}
                          src={URL.createObjectURL(image)}
                          alt=""
                          onClick={() => {
                            setPreviewPhotoIndex(index);
                          }}
                        />
                      </div>
                    </div>
                  ))
                : ""}
            </div>
          </div> */}

					<div className={classesPost.saveEdit}>
						<button
							onClick={() => {
								showCroppedImage();
								setIsPostImageDialogOpen(true);
							}}
						>
							Save Edits
						</button>
					</div>
				</div>
			) : (
				<React.Fragment>
					{imageSrc && isURLPic ? (
						<Card className={classes.cardMargin}>
							<CardMedia className={classes.mediaHeight} image={imageSrc} />
						</Card>
					) : (
						""
					)}
				</React.Fragment>
			)}
		</React.Fragment>
	);
};

export default PostImageCrop;
