import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
	departmentViewRoadmap,
	viewRoadmapRoleByRoleId,
} from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Industry_Chemical_Engineering from "assets/img/Career_Roadmap/Chemical_Engineering/Industry_Chemical_Engineering_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function IndustryChemicalEngineering() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Industry_Chemical_Engineering;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "ce",
				shape: "poly",
				coords: [74,292,68,296,64,299,60,304,59,310,59,492,62,499,64,502,69,506,76,509,170,509,177,506,182,502,185,498,187,492,186,308,184,302,180,296,175,294,169,292,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "274",
				shape: "poly",
				coords: [223,434,212,435,209,437,206,442,204,449,205,494,207,501,209,506,212,508,219,510,352,509,359,508,362,505,364,502,366,496,366,444,363,438,360,435,354,432,348,432,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "275",
				shape: "poly",
				coords: [586,432,581,436,578,439,575,444,574,449,575,498,576,503,580,506,584,508,591,510,720,510,728,508,732,505,736,501,737,495,738,451,736,442,732,438,726,434,722,433,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "276",
				shape: "poly",
				coords: [959,368,954,370,949,373,947,377,945,382,944,431,948,438,950,441,955,445,962,446,1094,445,1101,442,1104,439,1106,435,1108,430,1108,382,1105,376,1102,372,1097,369,1091,368,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "277",
				shape: "poly",
				coords: [1330,368,1324,371,1320,375,1318,380,1317,385,1317,430,1320,436,1322,442,1326,445,1332,446,1464,446,1471,443,1474,441,1478,437,1480,431,1480,384,1478,377,1474,373,1468,370,1464,368,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "278",
				shape: "poly",
				coords: [1701,368,1694,371,1691,374,1688,378,1687,384,1688,432,1690,438,1693,441,1698,444,1704,445,1842,444,1849,442,1852,439,1856,433,1856,429,1857,383,1854,375,1850,370,1845,368,1840,367,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "279",
				shape: "poly",
				coords: [589,292,583,293,580,295,578,299,576,305,576,354,578,360,581,365,586,367,592,369,726,368,731,365,735,361,738,357,738,351,738,305,736,298,733,294,728,292,723,291,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "ce") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo);
			dispatch(viewRoadmapRoleByRoleId(requestInfo));

			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
