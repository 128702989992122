import React from "react";
import dialogStyle from "assets/jss/material-kit-pro-react/views/pageStyle/ProfileViewPage/dialogStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { createConnect } from "configurations/redux/userSlice.js";

const useStyles = makeStyles(dialogStyle);

export default function CreateConnectionDialog({ open, onClose }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [text, setText] = React.useState("");
	const userStatus = useSelector((state) => state.auth.user);
	const extension = useSelector((state) => state.editProfileModal.extension);

	const handleSubmit = () => {
		const requestProfile = {
			receiverAcctId: extension.receiverAcctId,
			acctId: userStatus.acctId,
			email: userStatus.email,
			connMessageDesc: text,
			// ipAddr: localStorage.getItem("ipAddr"), //clientIp,
			accessToken: localStorage.getItem("token"),
		};
		console.log(requestProfile);
		dispatch(createConnect(requestProfile));
		onClose();
	};
	return (
		<Dialog
			open={open}
			onClose={(event, reason) => {
				if (reason && reason === "backdropClick") return;
				onClose();
			}}
		>
			<DialogTitle>Send Request</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Personalise your connection request with custom message.
				</DialogContentText>
				<TextField
					autoFocus
					margin="dense"
					id="customMessage"
					label="Custom Message"
					value={text}
					onChange={(event) => setText(event.target.value)}
					fullWidth
					variant="standard"
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<Button onClick={handleSubmit}>Connect</Button>
			</DialogActions>
		</Dialog>
	);
}
