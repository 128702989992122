import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "@mui/material/Button";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js";

import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { activateMasterCode } from "configurations/redux/masterFunctionSlice.js";
import {
	activateEditType,
	deactivateEditType,
} from "configurations/redux/editProfileModalSlice";
const useStyles = makeStyles(profilePageStyle);

export default function ProfileStrengthSection() {
	const [openl, setOpenl] = React.useState(false);

	const handleClick1 = () => {
		setOpenl(!openl);
	};
	const classes = useStyles();
	const dispatch = useDispatch();
	const profileStrength = useSelector((state) => state.user.profileStrength);
	const isViewed = useSelector((state) => state.user.isViewed);

	const profileStrengthBar = [
		{ color: "#FF0000" }, //0 => 1 Attribute
		{ color: "#FF4500" }, //1 => 2 Attribute
		{ color: "#ff8c00" }, //2 => 3 Attribute
		{ color: "#ffa500" }, //3 => 4 Attribute
		{ color: "#ffd700" }, //4 => 5 Attribute
		{ color: "#ffff00" }, //5 => 6 Attribute
		{ color: "#ADFF2F" }, //6 => 7 Attribute
		{ color: "#00FF00" }, //7 => 8 Attribute
	];

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const ActiveStrength =
		profileStrength &&
		profileStrength.length > 0 &&
		profileStrength[0].strengthList
			.filter((item) => {
				return item.scoreExclusion === 0;
			})
			.filter((item) => {
				return item.value === 1;
			})
			.map((item) => {
				return (
					<div
						style={{
							width: `calc( 100% / ${profileStrength[0].strengthList.filter((item) => {
								return item.scoreExclusion === 0;
							}).length
								} - 3px )`,
							height: "7px",
							backgroundColor:
								profileStrengthBar[
									`${profileStrength[0].strengthList
										.filter((item) => {
											return item.scoreExclusion === 0;
										})
										.filter((item) => {
											return item.value === 1;
										}).length - 1
									}`
								].color,
							display: "inline-block",
							marginRight: "2px",
						}}
						key={item.strength}
					>
						{/* <LinearProgress
						sx={{ height: 10 }}
						variant="determinate"
						value={100}
						color="secondary"
					/> */}
					</div>
				);
			});

	const InActiveStrength =
		profileStrength &&
		profileStrength.length > 0 &&
		profileStrength[0].strengthList
			.filter((item) => {
				return item.scoreExclusion === 0;
			})
			.filter((item) => {
				return item.value === 0;
			})
			.map((item) => {
				return (
					<div
						style={{
							width: `calc( 100% / ${profileStrength[0].strengthList.filter((item) => {
								return item.scoreExclusion === 0;
							}).length
								} - 3px )`,
							display: "inline-block",
							height: "7px",
							backgroundColor: "rgb(128 128 128 / 70%)",
							marginRight: "2px",
						}}
						key={item.strength}
					>
						{/* <LinearProgress
						color="secondary"
						sx={{ height: 10 }}
						variant="determinate"
						value={0}
					/> */}
					</div>
				);
			});
	const ProfileImprovement =
		profileStrength &&
		profileStrength.length > 0 &&
		profileStrength[0].strengthList
			.filter((item) => {
				return item.scoreExclusion === 0;
			})
			.filter((item) => {
				return item.value === 0;
			})
			.map((item, index) => {
				return (
					<div key={index}>
						<Button
							className="improveProfile"
							variant="text"
							onClick={(e) => {
								e.persist();
								const response = {
									functionCode: item.improvementTip.functionCode,
									extension: item.improvementTip,
								};
								dispatch(activateMasterCode(response));
								const editResponse = {
									type:
										item.improvementTip.functionCode ===
											"MF_PM_MODAL_PROFILE_PIC"
											? "EDIT_PROFILE_PICTURE"
											: "EDIT_PROFILE",
									tabType:
										item.improvementTip.functionCode ===
											"MF_PM_MODAL_EXPERIENCE"
											? "EXPERIENCE"
											: item.improvementTip.functionCode ===
												"MF_PM_MODAL_EDUCATION"
												? "EDUCATION"
												: item.improvementTip.functionCode ===
													"MF_PM_MODAL_PRIVACY_SETTING"
													? "PRIVACY_SETTING"
													: "GENERAL_PROFILE",
								};

								dispatch(activateEditType(editResponse));
							}}
							className={classes.profileStrengthButton}
						>
							{item.improvementTip.Text}
						</Button>
					</div>
				);
			});
	return (
		<React.Fragment>
			{!isViewed && profileStrength.length > 0 && (
				<Card
					style={{ marginTop: "0px ", marginBottom: "0px" }}
					className={classes.cardFrame}
				>
					<CardBody style={{ padding: "10px" }}>
						{/*<h4 className={classes.title}>Profile Strength</h4>*/}
						<div className='infoAreaContainer'>
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
								}}
							>
								<h4 className='profileStrenghtTitle'>
									Profile Strength: <b>{profileStrength[0].strengthLevel}</b>
								</h4>
								<div
									style={{
										width: "50px",
										height: "auto",
										display: "inline-block",
										verticalAlign: "top",
									}}
								>
									<CircularProgressbarWithChildren
										value={profileStrength[0].score}
									>
										<span
											style={{
												margin: "0px !important",
												fontSize: "15px",
												fontWeight: "600",
											}}
										>{`${Math.floor(profileStrength[0].score)}%`}</span>
									</CircularProgressbarWithChildren>
								</div>
							</div>

							{ActiveStrength}
							{InActiveStrength}
							{/* {profileStrength[0].score === 100 ? (
								<h4 className={classes.profileStrengthLabel}>
									Awesome! You have fully optimised your profile!
								</h4>
							) : (
								<h4 className={classes.profileStrengthLabel}>
									Optimise your profile strength by completing the following
									tasks
								</h4>

							)} */}
							{/* <Button
								id="basic-button11"
								aria-controls={open ? 'basic-menu' : undefined}
								aria-haspopup="true"
								aria-expanded={open ? 'true' : undefined}
								onClick={handleClick}
							>
								Improve Your Profile <i class="fa-solid fa-angle-down"></i>
							</Button>
							<Menu
								id="basic-menu11"
								anchorEl={anchorEl}
								open={open}
								onClose={handleClose}
								MenuListProps={{
									'aria-labelledby': 'basic-button',
								}}
							>
								<MenuItem onClick={handleClose}><i class="far fa-circle-check"></i>Add a profile photo</MenuItem>
								<MenuItem onClick={handleClose}><i class="far fa-circle-check"></i>Write a headline about the role you are looking for</MenuItem>
								<MenuItem onClick={handleClose}><i class="far fa-circle-check"></i>Highlight your skills and strengths</MenuItem>
								<MenuItem onClick={handleClose}><i class="far fa-circle-check"></i>Write at least 1000 characters about yourself</MenuItem>
								<MenuItem onClick={handleClose}><i class="far fa-circle-check"></i>Add tags of your skills and attributes</MenuItem>
								<MenuItem onClick={handleClose}><i class="far fa-circle-check"></i>Complete your experience history</MenuItem>
								<MenuItem onClick={handleClose}><i class="far fa-circle-check"></i>Complete your education history</MenuItem>
							</Menu> */}
							<List
								sx={{ width: '100%', bgcolor: 'background.paper' }}
								component="nav"
								aria-labelledby="nested-list-subheader"
								className='cusList'
							>
								<ListItemButton onClick={handleClick1}>
									<ListItemText className='improveTitle'>
										Improve Your Profile
									</ListItemText>
									{/* {openl ? <ExpandLess /> : <ExpandMore />} */}
								</ListItemButton>
								<Collapse in={openl} timeout="auto" unmountOnExit>
									<List component="div" className='customListItem' disablePadding>
										<ListItemButton sx={{ pl: 4 }}>
											<ListItemIcon>
												<CheckCircleOutlineIcon className='greycolor' />
											</ListItemIcon>
											<ListItemText primary="Add a profile photo" />
										</ListItemButton>
										<ListItemButton sx={{ pl: 4 }}>
											<ListItemIcon>
												<CheckCircleOutlineIcon />
											</ListItemIcon>
											<ListItemText primary="Write a headline about the role you are looking for" />
										</ListItemButton>
										<ListItemButton sx={{ pl: 4 }}>
											<ListItemIcon>
												<CheckCircleOutlineIcon />
											</ListItemIcon>
											<ListItemText primary="Highlight your skills and strengths" />
										</ListItemButton>
										<ListItemButton sx={{ pl: 4 }}>
											<ListItemIcon>
												<CheckCircleOutlineIcon />
											</ListItemIcon>
											<ListItemText primary="Write at least 1000 characters about yourself" />
										</ListItemButton>
										<ListItemButton sx={{ pl: 4 }}>
											<ListItemIcon>
												<CheckCircleOutlineIcon />
											</ListItemIcon>
											<ListItemText primary="Add tags of your skills and attributes" />
										</ListItemButton>
										<ListItemButton sx={{ pl: 4 }}>
											<ListItemIcon>
												<CheckCircleOutlineIcon />
											</ListItemIcon>
											<ListItemText primary="Complete your experience history" />
										</ListItemButton>
										<ListItemButton sx={{ pl: 4 }}>
											<ListItemIcon>
												<CheckCircleOutlineIcon />
											</ListItemIcon>
											<ListItemText primary="Complete your education history" />
										</ListItemButton>
									</List>
								</Collapse>
							</List>
							{/* <div
								style={{
									display: "inline-block",
								}}
							>
								{ProfileImprovement}
							</div> */}
						</div>
					</CardBody>
				</Card>
			)}
		</React.Fragment>
	);
}
