import React from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { instanceUrl } from "configurations/configurations.js";
import axios from "axios";
import { activateMasterCode } from "configurations/redux/masterFunctionSlice.js";

import { makeStyles } from "@material-ui/core/styles";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// import InfoArea from "components/InfoArea/InfoArea.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// import Email from "@material-ui/icons/MailOutline";
// import Phone from "@material-ui/icons/PhoneOutlined";
// import FontIcon from "views/Component/FontIcon.js";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
// import IconButton from "@material-ui/core/IconButton";
// import EditIcon from "@material-ui/icons/CreateOutlined";
import Tooltip from "@material-ui/core/Tooltip";
// import BasicProfileEditModal from "./BasicProfileEditModal.js";
import IconLibrary from "views/Component/IconLibrary.js";
import Button from "components/CustomButtons/Button.js";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js";

const useStyles = makeStyles(profilePageStyle);

export const BasicInfoSection = ({ user }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	// const [countryList, setCountryList] = React.useState([]);
	const [areaCodeList, setAreaCodeList] = React.useState([]);

	const privacySetting = useSelector((state) => state.user.privacySetting);
	const isViewed = useSelector((state) => state.user.isViewed);
	const profileRecord = useSelector((state) => state.user.profileRecord);
	// const [basicProfileEditModal, setBasicProfileEditModal] = React.useState(false);
	// const closeBasicProfileEditModal = () => {
	// 	setBasicProfileEditModal(false);
	// };

	const showProfile =
		privacySetting &&
		privacySetting
			.filter((item) => {
				return item.settingName === "showProfile";
			})
			.map((item) => {
				return item.settingValue === 1;
			})[0];

	const showEmail =
		privacySetting &&
		privacySetting
			.filter((item) => {
				return item.settingName === "showEmail";
			})
			.map((item) => {
				return item.settingValue === 1;
			})[0];

	const showContactNo =
		privacySetting &&
		privacySetting
			.filter((item) => {
				return item.settingName === "showContactNo";
			})
			.map((item) => {
				return item.settingValue === 1;
			})[0];

	React.useEffect(() => {
		let mounted = true;
		let CancelToken = axios.CancelToken;
		const source = CancelToken.source();
		// const fetchCountryList = async () => {
		// 	try {
		// 		const res = await axios.get(`${instanceUrl}/api/md/get-all-country`, {
		// 			cancelToken: source.token,
		// 		});
		// 		setCountryList(res.data.payload);
		// 	} catch (error) {
		// 		if (axios.isCancel(error)) {
		// 			//console.log("unmount caused");
		// 		} else {
		// 			throw error;
		// 		}
		// 	}
		// };
		const fetchAreaList = async () => {
			try {
				const res = await axios.get(`${instanceUrl}/api/md/get-all-area-code`, {
					cancelToken: source.token,
				});
				setAreaCodeList(res.data.payload);
			} catch (error) {
				if (axios.isCancel(error)) {
					//console.log("unmount caused");
				} else {
					throw error;
				}
			}
		};
		if (mounted) {
			// fetchCountryList();
			fetchAreaList();
		}
		return () => {
			mounted = false;
			source.cancel();
		};
	}, []);

	return (
		<React.Fragment>
			<Card className={classes.cardFrame}>
				<CardBody>
					<GridContainer
						justifyContent="space-between"
						alignContent="center"
						alignItems="center"
					>
						<GridItem xs={9} sm={9} md={9}>
							<h4 className={classes.title}>Basic Information</h4>
						</GridItem>
						<GridItem
							xs={2}
							sm={2}
							md={2}
							lg={2}
							className={classes.buttonColor}
						>
							{isViewed ? null : (
								<Tooltip
									title={
										<p className={classes.tooltipDescription}>Edit Profile</p>
									}
									placement="top"
									classes={{ tooltip: classes.tooltip }}
								>
									<Button
										justIcon
										style={{ boxShadow: "none" }}
										color="transparent"
										className={classes.buttonIconMargin}
										onClick={() => {
											/* setBasicProfileEditModal(true); */
											const response = {
												functionCode: "MF_PM_MODAL_BASIC_PROFILE",
												extension: null,
											};
											dispatch(activateMasterCode(response));
										}}
									>
										<IconLibrary.Pen />
									</Button>
								</Tooltip>
							)}
						</GridItem>
						{/* <BasicProfileEditModal
							open={basicProfileEditModal}
							onClose={closeBasicProfileEditModal}
						/> */}
					</GridContainer>

					<GridContainer justifyContent="center">
						<GridItem xs={12} sm={12} md={12}>
							<div className={classes.infoAreaContainer}>
								{isViewed ? (
									showProfile ? (
										showEmail ? (
											user.email && (
												<div className={classes.infoAreaStyle}>
													<IconLibrary.Mail />

													{user.email}
												</div>
											)
										) : (
											<div className={classes.infoAreaStyle}>
												<IconLibrary.Mail />
												{profileRecord.firstName} is not yet ready to show Email
											</div>
										)
									) : (
										<div className={classes.infoAreaStyle}>
											<IconLibrary.Mail />
											Email is currently private
										</div>
									)
								) : (
									user.email && (
										<div className={classes.infoAreaStyle}>
											<IconLibrary.Mail />

											{user.email}
										</div>
									)
								)}
								{/**<InfoArea
												className={classes.info}
												title={user.email}
												description={""}
												icon={Email}
											/> */}
								{isViewed ? (
									showProfile ? (
										showContactNo ? (
											profileRecord.contactNo && (
												<div className={classes.infoAreaStyle}>
													<IconLibrary.Phone />
													{"+" +
														areaCodeList
															.filter((item) => {
																return (
																	item.areaCodeId === profileRecord.areaCodeId
																);
															})
															.map((item) => {
																return item.areaCode;
															}) +
														" " +
														profileRecord.contactNo}
												</div>
											)
										) : (
											<div className={classes.infoAreaStyle}>
												<IconLibrary.Phone />
												{profileRecord.firstName} is not yet ready to show
												Contact Number
											</div>
										)
									) : (
										<div className={classes.infoAreaStyle}>
											<IconLibrary.Phone />
											Contact Number is currently private
										</div>
									)
								) : (
									profileRecord.contactNo && (
										<div className={classes.infoAreaStyle}>
											<IconLibrary.Phone />
											{"+" +
												areaCodeList
													.filter((item) => {
														return item.areaCodeId === profileRecord.areaCodeId;
													})
													.map((item) => {
														return item.areaCode;
													}) +
												" " +
												profileRecord.contactNo}
										</div>
									)
								)}
							</div>
						</GridItem>
					</GridContainer>

					{/* 	{showProfile ? (
						<React.Fragment>
							{profileRecord.countryId && (
								<InfoArea
									className={classes.info}
									title="Location"
									description={
										<p className={classes.cardDetail}>
											{countryList
												.filter((item) => {
													return item.countryId === profileRecord.countryId;
												})
												.map((item) => {
													return item.countryName;
												})}
										</p>
									}
									icon={LocationOnIcon}
									iconColor="info"
								/>
							)}
						</React.Fragment>
					) : (
						<React.Fragment>
							{isViewed ? (
								<InfoArea
									className={classes.info}
									title="Location"
									description={
										<p className={classes.cardDetail}>
											"Location" Detail is currently private.
										</p>
									}
									icon={LocationOnIcon}
									iconColor="info"
								/>
							) : (
								profileRecord.countryId && (
									<InfoArea
										className={classes.info}
										title="Location"
										description={
											<p className={classes.cardDetail}>
												{countryList
													.filter((item) => {
														return item.countryId === profileRecord.countryId;
													})
													.map((item) => {
														return item.countryName;
													})}
											</p>
										}
										icon={LocationOnIcon}
										iconColor="info"
									/>
								)
							)}
						</React.Fragment>
					)} */}
				</CardBody>
			</Card>
		</React.Fragment>
	);
};

const mapStateToProps = (state) => ({
	user: state.user,
});

export default connect(mapStateToProps, null)(BasicInfoSection);
