import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { departmentViewRoadmap, viewRoadmapRoleByRoleId } from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Industry_Marketing from "assets/img/Career_Roadmap/Marketing_Communication/Industry_Marketing_3.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function IndustryMarketingCommunication() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Industry_Marketing;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "ad",
				shape: "poly",
				coords: [
					72,65,165,66,173,67,178,70,181,75,184,80,184,123,180,130,176,135,168,140,74,140,66,138,61,134,55,124,55,83,58,75,64,69,69,66,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "pr",
				shape: "poly",
				coords: [
					71,196,164,195,174,198,179,203,183,211,184,372,184,372,175,388,166,390,73,391,65,387,60,382,57,378,54,372,55,215,55,209,55,209,55,209,65,198,72,195,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "mr",
				shape: "poly",
				coords: [
					75,453,165,454,174,456,180,462,180,462,185,469,185,510,182,518,178,523,178,523,169,529,73,528,64,525,59,520,57,516,57,516,56,471,57,466,57,466,65,457,72,454,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "m",
				shape: "poly",
				coords: [
					74,581,165,581,172,583,179,587,183,593,184,598,184,637,181,646,178,651,173,654,168,656,72,656,65,653,60,648,57,644,55,640,55,598,57,592,61,587,66,583,73,581,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "bm",
				shape: "poly",
				coords: [
					75,713,165,712,172,714,178,718,182,723,185,730,184,768,183,776,179,781,174,785,168,788,73,788,66,784,60,780,57,775,56,771,55,730,57,725,60,720,64,717,68,714,73,712,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "1",
				shape: "poly",
				coords: [
					208,64,341,64,348,66,353,69,355,72,357,76,357,127,356,132,353,136,350,139,346,140,208,140,203,138,200,135,196,130,195,78,197,73,200,69,203,66,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "2",
				shape: "poly",
				coords: [
					438,64,573,64,579,66,582,68,585,70,587,74,588,80,589,124,587,130,583,135,581,138,584,134,576,140,440,140,434,138,430,135,428,132,426,128,425,77,427,73,429,69,432,66,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "130",
				shape: "poly",
				coords: [
					770,154,992,154,1001,156,1007,160,1011,164,1012,170,1011,217,1009,223,1005,228,999,230,995,232,769,231,764,229,760,225,756,222,756,170,757,163,760,159,764,156,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "4",
				shape: "poly",
				coords: [
					1131,65,1313,65,1318,65,1324,68,1327,73,1328,76,1328,126,1326,131,1324,136,1320,139,1316,141,1132,140,1127,138,1123,134,1121,131,1120,127,1120,78,1121,74,1123,70,1126,68,1130,65,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "131",
				shape: "poly",
				coords: [
					1461,156,1599,155,1605,155,1612,157,1616,159,1618,162,1620,165,1622,169,1621,218,1620,223,1618,226,1615,229,1610,232,1606,233,1462,233,1455,230,1451,226,1449,224,1448,220,1448,169,1448,166,1450,162,1453,160,1454,157,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "7",
				shape: "poly",
				coords: [
					1684,127,1847,127,1853,129,1858,133,1862,135,1863,141,1862,194,1861,201,1857,206,1851,209,1684,210,1676,207,1672,204,1669,199,1668,140,1670,136,1674,132,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "8",
				shape: "poly",
				coords: [
					209,251,427,250,437,253,441,256,444,260,446,265,446,310,444,317,440,323,437,326,432,328,210,328,203,324,200,322,197,316,196,265,198,259,200,256,204,253,207,251,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "10",
				shape: "poly",
				coords: [
					1133,226,1133,226,1307,225,1315,227,1320,229,1324,232,1326,236,1328,242,1328,287,1326,295,1323,298,1319,303,1314,303,1135,303,1130,302,1125,299,1125,299,1120,292,1119,284,1119,244,1119,244,1119,244,1124,230,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "6",
				shape: "poly",
				coords: [
					1583,303,1728,302,1737,306,1741,310,1743,315,1743,364,1742,370,1738,375,1734,378,1727,380,1587,381,1578,379,1575,375,1571,371,1570,367,1570,320,1570,315,1573,310,1575,306,1578,305,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "12",
				shape: "poly",
				coords: [
					213,450,370,450,382,451,386,453,390,457,393,467,393,512,392,518,388,523,378,527,370,527,211,528,201,524,196,516,195,512,194,469,195,462,200,453,205,451,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "13",
				shape: "poly",
				coords: [
					482,450,645,449,658,453,665,458,666,469,666,509,663,519,656,526,644,527,481,527,466,526,460,518,458,508,458,471,460,460,465,454,476,450,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "14",
				shape: "poly",
				coords: [
					787,450,983,450,997,450,1007,455,1011,463,1012,510,1009,519,1001,527,989,527,786,528,774,526,765,520,762,509,761,471,765,459,771,452,777,451,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "132",
				shape: "poly",
				coords: [
					1137,452,1463,451,1475,452,1486,457,1493,465,1492,509,1491,520,1486,526,1473,529,1140,529,1128,529,1121,525,1115,515,1114,474,1117,460,1123,455,1127,453,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "22",
				shape: "poly",
				coords: [
					1683,450,1856,451,1866,453,1872,458,1876,466,1875,512,1873,521,1868,526,1855,527,1691,528,1678,526,1671,521,1669,513,1667,474,1667,464,1670,458,1675,453,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "17",
				shape: "poly",
				coords: [
					216,579,367,579,373,580,382,585,381,585,387,591,387,653,384,664,379,670,367,672,217,673,204,670,198,665,197,654,196,595,197,589,201,583,206,580,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "18",
				shape: "poly",
				coords: [
					457,581,552,580,561,582,568,586,571,591,571,600,571,655,570,664,564,670,557,674,548,673,454,673,447,673,442,669,437,663,437,657,436,602,437,591,442,584,449,582,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "19",
				shape: "poly",
				coords: [
					647,580,742,580,750,580,757,582,764,589,766,595,765,604,765,652,764,662,760,668,752,673,742,673,648,673,637,670,632,664,630,655,629,603,632,590,638,583,641,581,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "20",
				shape: "poly",
				coords: [
					838,581,990,581,1003,582,1011,588,1014,595,1014,640,1012,648,1006,655,994,658,837,659,826,656,823,651,819,643,819,599,821,591,825,584,829,582,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "23",
				shape: "poly",
				coords: [
					209,713,288,712,296,713,303,718,307,726,306,736,307,777,302,785,294,789,282,790,208,788,199,785,195,778,194,769,194,727,198,720,201,716,205,713,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "24",
				shape: "poly",
				coords: [
					379,704,474,704,483,705,490,712,494,718,494,732,494,777,492,787,488,792,480,795,378,795,369,794,361,787,359,776,359,729,362,714,365,708,370,706,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "25",
				shape: "poly",
				coords: [
					551,711,646,711,657,713,663,718,666,728,667,770,664,780,660,784,643,789,550,788,539,785,534,779,533,769,532,731,534,719,539,714,542,712,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "26",
				shape: "poly",
				coords: [
					752,712,846,711,859,713,866,719,869,726,869,773,867,780,862,786,851,789,751,789,741,787,735,782,732,773,731,729,734,721,738,715,743,713,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		if (area.name === "ad") {
			dispatch(departmentViewRoadmap(1));
		} else if (area.name === "pr") {
			dispatch(departmentViewRoadmap(2));
		} else if (area.name === "mr") {
			dispatch(departmentViewRoadmap(3));
		} else if (area.name === "m") {
			dispatch(departmentViewRoadmap(4));
		} else if (area.name === "bm") {
			dispatch(departmentViewRoadmap(5));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo)
			dispatch(viewRoadmapRoleByRoleId(requestInfo));

			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// console.log(coords.x + "," + coords.y + ",");
		// navigator.clipboard.writeText(coords.x + "," + coords.y + ",");
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
