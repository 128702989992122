import { useDispatch } from "react-redux";
import React from "react";
// import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.js";

import style from "assets/jss/material-kit-pro-react/modalStyle.js";
import IconLibrary from "views/Component/IconLibrary";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

export default function CustomDialog(children) {
	const useStyles = makeStyles(style);
	const classes = useStyles();
	const { confirmDialog, setConfirmDialog } = children;

	return (
		<React.Fragment>
			<Dialog
				classes={{
					root: classes.modalRoot,
					paper: classes.modalLoader,
				}}
				className={classes.modalIndex}
				open={confirmDialog.isOpen}
				TransitionComponent={Transition}
				keepMounted
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle
					id="alert-dialog-slide-title"
					disableTypography
					className={classes.modalHeader}
				>
					<Button
						simple
						className={classes.modalCloseButton}
						key="close"
						aria-label="Close"
						onClick={() =>
							setConfirmDialog({ ...confirmDialog, isOpen: false })
						}
					>
						{" "}
						<IconLibrary.Close />
					</Button>
					<h4>
						<b>{confirmDialog.dialogDefault.title}</b>
					</h4>
				</DialogTitle>
				<DialogContent
					id="classic-modal-slide-description"
					className={classes.modalBody}
				>
					<DialogContentText id="alert-dialog-slide-description">
						{confirmDialog.dialogDefault.description}
					</DialogContentText>
				</DialogContent>
				<DialogActions className={classes.modalFooter}>
					{confirmDialog.onConfirm ? (
						<Button onClick={confirmDialog.onConfirm} color="opptyWrap" round>
							{confirmDialog.dialogDefault.buttonYes}
						</Button>
					) : null}
					{confirmDialog.dialogDefault.buttonNo !== "" ? (
						confirmDialog.onCancel ? (
							<Button
								onClick={confirmDialog.onCancel}
								color="opptyWrapBorder"
								round
							>
								{confirmDialog.dialogDefault.buttonNo}
							</Button>
						) : (
							<Button
								onClick={() =>
									setConfirmDialog({ ...confirmDialog, isOpen: false })
								}
								color="opptyWrapBorder"
								round
							>
								{confirmDialog.dialogDefault.buttonNo}
							</Button>
						)
					) : null}
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}
