import React from 'react';

import { Typography, makeStyles, Button } from '@material-ui/core';

import groupSummaryCardStyles from 'assets/jss/material-kit-pro-react/views/componentStyle/manageGroup/groupSummaryCardStyles.js';
import { userGroupRoles } from 'configurations/configurations';
import { Skeleton } from '@mui/material';
const useStyles = makeStyles(groupSummaryCardStyles);

const GroupSummaryCard = React.memo(
  ({ image, name, posts, members, onGroupInviteClick, leaveBtnProps, isUserMember, isUserOwner, groupIsLoading }) => {
    const styles = useStyles();

    return (
      <>
        <Typography component="div" className={styles.main}>
          {!groupIsLoading ? (
            <>
              <Typography component="div" className={styles.header}>
                <img style={{ objectFit: 'cover' }} src={image} alt="" />
                <Typography component="h1" className={styles.name}>
                  {name}
                </Typography>
              </Typography>

              <Typography component="div" className={styles.stats}>
                <Typography component="div">
                  <Typography component="p" className={styles.numbers}>
                    {posts}
                  </Typography>
                  <Typography component="p" className={styles.statName}>
                    Posts
                  </Typography>
                </Typography>

                <Typography component="div">
                  <Typography component="p" className={styles.numbers}>
                    {members}
                  </Typography>
                  <Typography component="p" className={styles.statName}>
                    Members
                  </Typography>
                </Typography>

                {/* <Typography component='div'>
          <Typography component='p' className={styles.numbers}>
            {media}
          </Typography>
          <Typography component='p' className={styles.statName}>
            Media
          </Typography>
        </Typography>

        <Typography component='div'>
          <Typography component='p' className={styles.numbers}>
            {events}
          </Typography>
          <Typography component='p' className={styles.statName}>
            Events
          </Typography>
        </Typography> */}
              </Typography>

              <Typography component="div" className={styles.buttons}>
                {!isUserOwner && (
                  <Button className={styles.leaveBtn} onClick={leaveBtnProps.onClick}>
                    {leaveBtnProps.text}
                  </Button>
                )}
                {isUserMember && (
                  <Button className={styles.inviteBtn} onClick={onGroupInviteClick}>
                    Group Invite
                  </Button>
                )}
              </Typography>
            </>
          ) : (
            <>
              <Skeleton variant="circular" width={100} height={100} />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </>
          )}
        </Typography>
      </>
    );
  }
);
export default GroupSummaryCard;
/* <Typography component="div" className={styles.suggestedGroup}>
                <Typography component="div" className={styles.suggestedGroupHeader}>
                  <Typography component="h2" className={styles.suggestedGroupHeaderTitle}>
                    Suggested Group
                  </Typography>
                  <Button>SEE ALL</Button>
                </Typography>

                <hr className={styles.suggestedGroupDivider} />

                <Typography component="div" className={styles.suggestedGroupMain}>
                  <Typography component="div" className={styles.suggestedGroupProfile}>
                    <img src={img2} alt="" className={styles.suggestedGroupProfilePic} />

                    <Typography component="div" className={styles.suggestedGroupName}>
                      <Typography component="h3">
                        Freelancer Designer Wordlwide
                      </Typography>
                      <Typography component="p">
                        <span>Technology</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4">
                          <circle id="Oval" cx="2" cy="2" r="2" fill="#d8d8d8" />
                        </svg>
                        <span>15,321</span> Total Members
                      </Typography>

                    </Typography>

                  </Typography>

                  <Typography component="div" className={styles.suggestedGroupBanner}>
                    <Typography component="div" className={styles.suggestedGroupBannerImage}>
                      <img src={img1} alt="" />
                    </Typography>

                    <Button>
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18.75" viewBox="0 0 18 18.75">
                        <path id="Path" d="M10,8.333h7a1.043,1.043,0,0,1,0,2.083H10v7.292a1,1,0,1,1-2,0V10.417H1A1.043,1.043,0,0,1,1,8.333H8V1.042a1,1,0,1,1,2,0Z" fill="#92929d" />
                      </svg>
                      Join Group
                    </Button>
                  </Typography>
                </Typography>

              </Typography> */
