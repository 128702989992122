import {
	container,
	boxShadow,
	hexToRgb,
	grayColor,
	blackColor,
	opptyDesignColor,
} from "assets/jss/material-kit-pro-react.js";

const connectionPageStyle = (theme) => ({
	container: {
		"& > div": {
			gap: 0,
		},
	},
	body: {
		overflowY: "scroll",
		overflowX: "hidden",
		marginTop: "93px",
		height: "calc(100vh - 100px)",
		[theme.breakpoints.down("md")]: {
			marginTop: "85px",
		},
	},
	cardFrame: {
		borderRadius: "18px",
		boxShadow: "none",
		width: "auto",
		margin: "10px 0px",
	},
	cardClick: {
		cursor: "pointer",
		minHeight: "105px",
		"&:hover,&:focus": {
			//backgroundColor: grayColor[0],
			boxShadow:
				"0 14px 26px -12px rgba(" +
				hexToRgb(grayColor[0]) +
				", 0.42), 0 4px 23px 0px rgba(" +
				hexToRgb(blackColor) +
				", 0.12), 0 8px 10px -5px rgba(" +
				hexToRgb(grayColor[0]) +
				", 0.2)",
		},
	},
	pageHeader: {
		position: "relative",
		/* 		display: "flex !important", */
		alignItems: "center",
		margin: "0",
		border: "0",
		/* minHeight: "100vh", */
		height: "auto",
		// color: "#fff",
	},
	tabContainer: {
		display: "flex",
		justifyContent: "space-evenly",
		height: "90px",
		backgroundColor: "white",
		borderRadius: "10px",
		flexDirection: "row",
		position: "relative",
	},
	contentContainer: {
		position: "relative",
		display: "flex",
		alignItems: "center",
		flexBasis: "20%",
		minWidth: "150px",
		maxWidth: "180px",
		flexDirection: "column",
		cursor: "pointer",

		"@media (max-width: 500px)": {
			flexBasis: "33.33%",
			minWidth: "33.33%",
			maxWidth: "100%",
			flexBasis: "100%",
		},
	},

	container: {
		...container,
		zIndex: "5",
		/* "@media (min-width: 576px)": {
			flexBasis: "98%",
		},
		"@media (min-width: 768px)": {
			flexBasis: "98%",
		},
		"@media (min-width: 960px)": {
			flexBasis: "98%",
		},
		"@media (min-width: 992px)": {
			flexBasis: "98%",
		},
		"@media (min-width: 1200px)": {
			flexBasis: "98%",
		}, */
		"@media (min-width: 576px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 768px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 992px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 1200px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 1400px)": {
			maxWidth: "95vw",
		},
	},
	tabTxt1: {
		fontSize: "16px",
		fontWeight: "normal",
		marginTop: "10px",
	},
	tabTxt2: {
		fontSize: "18px",
		fontWeight: "600",
		marginTop: "10px",
	},
	tabIndicator: {
		position: "absolute",
		backgroundColor: "#6F52ED",
		/* 		borderTopLeftRadius: "5px",
		borderTopRightRadius: "5px", */
		height: "6px",
		bottom: "0px",
		width: "100%",
	},
	cardsContainer: {
		display: "flex",
		flexDirection: "row",
		marginTop: "20px",
		backgroundColor: "white",
		borderRadius: "10px",

		"@media (max-width: 500px)": {
			"& > div": {
				"& > div": {
					"& > button": {
						width: "90%",
						height: "32px",
					},

					"& > p:first-of-type": {
						fontSize: "22px",
						textAlign: "center",
					},

					"& > p:last-of-type": {
						fontSize: "12px",
						textAlign: "center",
					},
				},
			},
		},
	},
	searchContainer: {
		flexDirection: "row",
		justifyContent: "space-between",
		display: "flex",
	},
	search: {
		display: "flex",
		flexDirection: "column",
		width: "60%",
	},
	txtSerach: {
		color: "#000000",
		fontSize: "14px",
		fontWeight: "500",
		marginTop: "21px",
		display: "flex",
		marginLeft: "10px",
	},
	sortContainer: {
		marginTop: "21px",
		width: "25%",
	},
	sortTxt: {
		color: "black",
		fontSize: "14px",
		fontWeight: "500",
	},
	sortContainer2: {
		justifyContent: "center",
		alignItems: "center",
		border: "3px solid #4A4A4A",
		width: "100%",
		height: "50px",
		borderRadius: "15px",
		display: "flex",
		marginTop: "11px",
	},
	optionContainer: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		justifyContent: "space-around",
		alignItems: "center",
	},
	optionTxt: {
		color: "#06152B",
		fontSize: "14px",
		fontWeight: "normal",
	},
	optionDiv: {
		backgroundColor: "white",
		flex: "1",
		borderRadius: "10px",
		width: "230px",
		paddingTop: "20px",
		paddingBottom: "20px",
	},
	optionDivtxt: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		marginTop: "10px",
		justifyContent: "space-between",
		padddingLeft: "20px",
		paddingRight: "20px",
		height: "30px",
	},
	optionDivtxt2: {
		color: "#44444F",
		fontSize: "14px",
		fontWeight: "normal",
		paddingLeft: "20px",
	},
	cardsContainer2: {
		display: "flex",
		gap: "15px",
		flexWrap: "wrap",
		flexDirection: "row",
		marginTop: "50px",
	},
	resultContainer: {
		display: "flex",
		minHeight: "81px",
		/* padding: "20px 10px", */
	},
	imgContainer: {
		display: "flex",

		"& img": {
			width: "48px",
			height: "48px",
			borderRadius: "50%",
			marginTop: "5px",
		},
	},
	descriptionContainer: {
		width: "100%",
		display: "flex",
		marginLeft: "10px",
		justifyContent: "space-between",
		"& h4": {
			marginBottom: "0px !important",
			marginTop: "5px !important",
		},
		[theme.breakpoints.down("xs")]: {
			display: "block",
		},
	},
	buttonMobile: {
		[theme.breakpoints.down("xs")]: {
			padding: "10px",
			margin: "10px 10px 10px 0px",
		},
	},
	title: {
		fontWeight: 700,
		fontSize: "16px",
	},
	description: {
		fontWeight: 500,
		fontSize: "14px",
		color: "rgba(0,0,0,0.6)",
	},
	mainContainer: {
		backgroundColor: "#f2f3f7",
		padding: "5px 10px",
		margin: "10px 0px",
		borderRadius: "18px",
	},
	cardBodyPadding: {
		padding: "10px",
	},
	componentTitle: {
		fontWeight: 700,
		fontSize: "20px",
		marginLeft: "15px",
		marginTop: "5px",
	},
	buttonViewMore: {
		color: opptyDesignColor,
		textTransform: "none",
	},
});

export default connectionPageStyle;
