import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
	departmentViewRoadmap,
	viewRoadmapRoleByRoleId,
} from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Department_Aircraft_Maintenance from "assets/img/Career_Roadmap/Aerospace_Engineering/Department_Aircraft_Maintenance_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentAircraftMaintenance() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Aircraft_Maintenance;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "am",
				shape: "poly",
				coords: [81,64,75,67,72,70,69,74,66,82,66,785,69,791,72,795,77,799,84,802,178,801,184,799,190,795,193,791,195,784,195,82,193,75,189,70,184,66,179,64,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "183",
				shape: "poly",
				coords: [222,433,217,436,213,438,211,442,210,449,210,498,212,503,214,506,217,509,222,512,325,512,331,510,336,507,338,503,340,496,340,447,338,440,335,437,331,434,324,433,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "184",
				shape: "poly",
				coords: [379,64,375,65,371,69,368,72,367,77,367,128,369,133,371,136,374,140,379,143,487,142,491,138,494,135,496,131,497,126,497,75,496,71,493,67,489,65,485,64,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "192",
				shape: "poly",
				coords: [379,433,375,435,371,438,368,442,367,448,367,499,370,503,372,507,376,511,381,512,485,511,491,508,494,504,496,500,497,495,497,445,496,440,492,437,489,434,483,433,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "185",
				shape: "poly",
				coords: [535,64,531,66,529,68,526,72,525,76,525,130,527,134,529,137,532,140,537,143,642,142,647,140,650,136,653,132,654,127,654,77,653,71,649,68,646,65,641,64,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "204",
				shape: "poly",
				coords: [538,155,533,157,529,160,526,164,525,169,526,221,527,226,531,229,535,231,541,233,643,233,649,230,652,226,655,223,656,219,656,168,655,162,651,158,647,155,641,154,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "193",
				shape: "poly",
				coords: [540,557,533,559,529,561,527,564,525,570,526,625,528,629,531,632,536,634,540,635,643,635,647,634,651,630,653,627,655,622,655,570,654,564,651,560,647,558,642,557,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "186",
				shape: "poly",
				coords: [692,64,688,67,685,68,683,72,682,76,682,131,684,135,686,138,689,141,693,142,800,142,804,140,808,137,810,134,811,129,811,75,809,69,807,66,802,65,799,64,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "205",
				shape: "poly",
				coords: [696,154,690,156,687,159,685,162,682,168,683,221,686,226,688,228,691,232,697,233,801,233,806,229,809,227,812,223,813,219,812,165,811,160,808,157,804,155,800,154,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "208",
				shape: "poly",
				coords: [696,284,690,287,686,288,683,293,682,297,682,351,684,356,687,360,690,361,696,362,800,363,804,360,808,357,811,354,812,349,811,295,809,290,806,287,802,285,797,284,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "194",
				shape: "poly",
				coords: [699,517,692,519,688,521,686,526,684,532,685,585,687,589,690,593,695,595,700,597,803,596,807,593,811,590,812,586,814,582,814,531,813,526,810,521,805,518,800,517,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "199",
				shape: "poly",
				coords: [697,608,691,610,687,613,684,617,683,623,683,674,684,680,687,683,691,685,697,688,800,687,806,685,810,681,811,677,813,671,812,620,810,615,807,611,804,609,800,608,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "187",
				shape: "poly",
				coords: [852,63,848,65,844,67,841,71,839,77,839,130,842,134,844,138,849,140,855,142,957,142,960,140,965,136,968,133,968,128,969,77,966,70,964,66,960,64,954,63,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "206",
				shape: "poly",
				coords: [853,154,847,156,843,160,840,165,840,170,841,220,843,225,844,229,850,232,855,233,956,233,962,230,966,227,969,223,970,216,970,169,969,162,965,158,961,154,954,153,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "209",
				shape: "poly",
				coords: [854,245,849,247,844,250,842,256,841,261,841,307,842,312,844,317,848,321,855,324,958,323,964,321,968,316,971,312,971,306,971,261,970,253,967,248,962,246,956,245,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "211",
				shape: "poly",
				coords: [854,336,848,338,845,341,842,346,841,352,841,402,843,408,845,411,849,413,855,415,959,414,964,412,968,409,970,405,972,401,971,350,969,344,966,339,962,336,957,336,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "198",
				shape: "poly",
				coords: [854,427,849,429,846,432,843,436,842,443,842,494,845,497,846,501,850,503,855,506,961,504,966,502,968,499,970,496,972,491,972,439,970,432,967,431,963,428,959,426,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "195",
				shape: "poly",
				coords: [854,517,850,519,846,522,842,525,842,531,841,582,843,587,845,591,849,595,855,597,958,596,963,594,967,590,970,586,971,581,971,531,969,526,966,522,962,519,957,516,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "202",
				shape: "poly",
				coords: [858,607,850,610,846,612,842,618,841,622,842,676,844,680,847,683,850,686,855,688,957,686,963,685,967,681,969,678,971,673,972,624,971,617,966,612,962,610,957,608,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "200",
				shape: "poly",
				coords: [852,698,848,700,844,704,841,708,839,713,840,765,842,769,844,773,848,775,853,778,957,778,962,776,965,774,968,770,970,763,970,714,968,706,964,702,961,700,956,698,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "188",
				shape: "poly",
				coords: [1055,62,1048,66,1045,68,1042,73,1041,78,1041,128,1042,134,1046,138,1050,140,1054,142,1159,142,1164,140,1167,137,1169,132,1172,127,1171,77,1170,71,1167,67,1162,64,1157,63,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "207",
				shape: "poly",
				coords: [1056,154,1049,157,1046,159,1044,163,1042,168,1042,221,1044,226,1047,229,1052,232,1057,233,1158,233,1164,231,1168,227,1171,224,1172,218,1172,169,1171,162,1167,158,1163,155,1157,153,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "210",
				shape: "poly",
				coords: [1058,244,1052,246,1048,250,1044,254,1043,261,1043,311,1045,316,1048,320,1052,322,1059,323,1161,323,1165,321,1170,318,1172,313,1174,307,1173,261,1173,254,1170,249,1166,246,1160,244,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "196",
				shape: "poly",
				coords: [1058,517,1053,520,1049,521,1046,525,1044,532,1045,585,1048,588,1050,592,1054,595,1058,596,1161,596,1166,594,1170,592,1173,587,1174,583,1174,530,1172,524,1170,520,1165,518,1160,517,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "203",
				shape: "poly",
				coords: [1058,608,1052,610,1049,612,1044,617,1043,622,1043,673,1045,679,1049,683,1053,685,1059,687,1159,687,1166,684,1169,681,1172,678,1174,672,1174,624,1172,616,1169,612,1164,608,1159,607,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "201",
				shape: "poly",
				coords: [1056,699,1052,701,1049,704,1046,708,1044,713,1045,766,1047,771,1050,774,1054,776,1058,778,1160,778,1165,777,1169,774,1172,769,1175,765,1175,713,1172,706,1170,702,1166,700,1161,698,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "197",
				shape: "poly",
				coords: [1286,225,1280,227,1276,230,1272,235,1271,240,1272,304,1272,311,1275,314,1278,318,1284,318,1435,318,1440,317,1445,314,1448,310,1450,304,1449,239,1447,232,1443,229,1438,226,1433,225,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "189",
				shape: "poly",
				coords: [1285,538,1280,541,1276,544,1272,549,1272,555,1273,616,1274,624,1277,628,1282,631,1290,631,1432,631,1442,630,1445,626,1448,622,1451,618,1451,555,1450,548,1446,542,1442,539,1436,538,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "190",
				shape: "poly",
				coords: [1469,380,1462,382,1458,384,1456,389,1454,396,1454,459,1456,464,1458,468,1463,471,1471,472,1620,472,1626,469,1629,466,1631,462,1632,458,1632,395,1630,388,1627,384,1621,380,1616,380,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "191",
				shape: "poly",
				coords: [1673,381,1666,382,1663,384,1660,389,1658,396,1660,458,1661,464,1664,468,1669,472,1676,474,1823,472,1829,470,1834,467,1836,462,1838,457,1838,393,1836,386,1833,384,1826,381,1822,380,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "am") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo);
			dispatch(viewRoadmapRoleByRoleId(requestInfo));

			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
