import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { eventNotificationGet } from "configurations/redux/eventNotificationSlice.js";
import InfiniteScroll from "react-infinite-scroll-component";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import MUICard from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "components/CustomButtons/Button.js";

import CircularProgress from "@material-ui/core/CircularProgress";

// import listNotificationPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/SearchJobPage/searchJobPageStyle.js";
import notificationPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/NotificationPage/notificationPageStyle.js";
import ListResultCard from "./ListResultCard";
import NoActivityStatusModal from "../NoActivityStatusModal/NoActivityStatusModal";
import LoadingModal from "views/Component/LoadingModal.js";
import CardFooter from "components/Card/CardFooter";
import Backdrop from "../../Component/Backdrop.js";

const useStyles = makeStyles(notificationPageStyle);
export default function NotificationResultSection() {
	const classes = useStyles();
	const searchResult = useSelector(
		(state) => state.eventNotification.searchResult
	);
	const currentSize = useSelector(
		(state) => state.eventNotification.lastRecordSize
	);
	const userStatus = useSelector((state) => state.auth.user);
	const isEnd = useSelector((state) => state.eventNotification.isEnd);
	const isUpdated = useSelector((state) => state.eventNotification.isUpdated);
	const isLoading = useSelector((state) => state.eventNotification.isLoading);
	const navigateNotificationLink = useSelector(
		(state) => state.eventNotification.navigateNotificationLink
	);
	const bizId = useSelector((state) => state.business.bizId);

	const dispatch = useDispatch();

	const ResultCard = searchResult.map((item, index) => {
		return (
			<GridItem xs={12} sm={12} md={12} key={index}>
				<ListResultCard key={item.notificationListId} item={item} />
			</GridItem>
		);
	});

	const loadNotification = () => {
		const requestProfile = {
			acctId: userStatus.acctId,
			accessToken: localStorage.getItem("token"),
			email: userStatus.email,
			lastRecordSize: currentSize,
			requestRecordSize: 5,
			bizId: bizId,
		};
		dispatch(eventNotificationGet(requestProfile));
	};

	return (
		<React.Fragment>
			{navigateNotificationLink ? (
				// <LoadingModal />
				<Backdrop />
			) : isUpdated ? (
				<div>
					{searchResult.length > 0 ? (
						<MUICard variant="outlined" className={classes.cardFrame}>
							<CardContent>
								<InfiniteScroll
									style={{ overflowX: "hidden" }}
									dataLength={searchResult.length}
									next={loadNotification}
									hasMore={!isEnd}
									height={
										searchResult.length < 4
											? 260
											: window.innerWidth < 1366
											? 360
											: 430
									}
									loader={
										<CircularProgress className={classes.loadingProgress} />
									}
									endMessage={
										searchResult.length >= 50 ? (
											<h6 className={classes.endMessage}>
												Check More In Notification Page
											</h6>
										) : (
											<h6 className={classes.endMessage}>
												End Of Notification
											</h6>
										)
									}
								>
									<GridContainer>{ResultCard}</GridContainer>
								</InfiniteScroll>
							</CardContent>
							<CardFooter>
								<Button
									className={classes.searchBarButton}
									color="oppty"
									round
									fullWidth
									href="/notification-page/0/"
								>
									See All In Notification Page
								</Button>
							</CardFooter>
						</MUICard>
					) : isLoading ? (
						// <LoadingModal />
						<MUICard variant="outlined">
							<CardContent>
								<CircularProgress className={classes.loadingProgress} />
							</CardContent>
						</MUICard>
					) : (
						isEnd &&
						searchResult.length === 0 && (
							<MUICard variant="outlined">
								<CardContent>
									<h6 className={classes.endMessage}>
										No available notification
									</h6>
								</CardContent>
							</MUICard>
						)
					)}
				</div>
			) : (
				<NoActivityStatusModal />
			)}
		</React.Fragment>
	);
}
