import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import DirectionsIcon from "@material-ui/icons/Directions";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: "2px 4px",
		display: "flex",
		alignItems: "center",
		width: "auto",
	},
	input: {
		marginLeft: theme.spacing(1),
		flex: 1,
	},
	iconButton: {
		padding: 10,
	},
	divider: {
		height: 28,
		margin: 4,
	},
}));

export default function CustomSearchInput(props) {
	const classes = useStyles();
	const {
		formControlProps,
		labelText,
		id,
		readOnly,

		labelProps,
		inputProps,
		error,
		white,
		inputRootCustomClasses,
		success,
		onChange,
		onBlur,
		values,
		onKeyPress,
		onKeyDown,
		placeholder,
		className,
		close,
		cancel,
	} = props;

	let newInputProps = {
		maxLength: inputProps ? inputProps.maxLength : undefined,
		minLength: inputProps ? inputProps.minLength : undefined,
	};

	return (
		<div component="form" className={classes.root + " " + className}>
			<InputBase
				className={classes.input}
				id={id}
				onKeyPress={onKeyPress}
				onKeyDown={onKeyDown}
				{...inputProps}
				inputProps={newInputProps}
				values={values}
				readOnly={readOnly}
				onChange={onChange}
				onBlur={onBlur}
				placeholder={labelText !== undefined ? labelText : null}
			/>
			{close && (
				<IconButton
					type="submit"
					className={classes.iconButton}
					aria-label="search"
				>
					<SearchIcon />
				</IconButton>
			)}
			{cancel && (
				<IconButton
					type="reset"
					className={classes.iconButton}
					aria-label="cancel"
				>
					<CloseIcon />
				</IconButton>
			)}
		</div>
	);
}

CustomSearchInput.propTypes = {
	labelText: PropTypes.node,
	labelProps: PropTypes.object,
	id: PropTypes.string,
	inputProps: PropTypes.object,
	formControlProps: PropTypes.object,
	inputRootCustomClasses: PropTypes.string,
	error: PropTypes.bool,
	readOnly: PropTypes.bool,
	success: PropTypes.bool,
	white: PropTypes.bool,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	onKeyPress: PropTypes.func,
	onKeyDown: PropTypes.func,
};
