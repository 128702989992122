import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
	departmentViewRoadmap,
	viewRoadmapRoleByRoleId,
} from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Department_Affluence_Banking from "assets/img/Career_Roadmap/Banking_Wealth_Management/Department_Affluence_Banking_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentAffluenceBanking() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Affluence_Banking;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "os",
				shape: "poly",
				coords: [72,288,64,291,59,294,56,299,55,304,54,346,58,353,61,358,66,361,72,364,177,364,184,361,188,357,192,353,195,347,195,305,192,297,188,293,183,289,176,288,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "os",
				shape: "poly",
				coords: [71,474,64,477,60,480,57,485,55,492,54,530,56,537,60,543,64,546,72,549,177,549,184,547,189,544,192,538,195,532,194,490,192,484,188,479,183,475,176,474,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "396",
				shape: "poly",
				coords: [297,286,287,289,284,292,280,295,278,303,278,347,280,354,282,358,288,362,295,364,481,364,490,362,495,359,499,354,500,348,501,307,500,296,496,292,491,288,486,287,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "397",
				shape: "poly",
				coords: [736,286,727,290,723,293,721,298,720,304,720,350,722,356,724,359,728,362,736,364,922,364,932,362,936,359,940,354,941,347,941,304,940,295,936,291,930,287,925,286,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "398",
				shape: "poly",
				coords: [1176,286,1170,288,1165,292,1163,295,1161,302,1161,345,1162,354,1166,359,1170,362,1178,365,1365,365,1373,362,1379,358,1381,354,1382,345,1383,306,1381,296,1378,291,1373,288,1366,286,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "399",
				shape: "poly",
				coords: [1618,286,1608,290,1604,293,1602,298,1601,304,1602,349,1604,357,1607,360,1612,363,1620,364,1808,365,1816,362,1820,359,1822,354,1824,348,1823,303,1822,294,1818,291,1813,288,1807,286,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "401",
				shape: "poly",
				coords: [294,472,286,475,282,477,279,482,278,490,278,530,281,541,282,545,285,548,294,550,484,550,493,546,497,544,499,538,500,531,500,489,500,482,494,476,488,474,483,473,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "402",
				shape: "poly",
				coords: [740,472,729,474,724,477,721,481,720,490,720,532,722,542,726,547,732,550,740,550,926,550,934,547,938,544,940,540,942,534,941,488,940,480,936,477,931,473,926,472,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "403",
				shape: "poly",
				coords: [1176,471,1168,474,1164,477,1162,482,1161,489,1160,533,1163,540,1165,544,1171,548,1179,550,1365,550,1375,548,1379,544,1382,539,1383,532,1383,490,1381,482,1377,476,1372,473,1366,472,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "404",
				shape: "poly",
				coords: [1618,472,1610,474,1606,477,1603,482,1602,490,1602,537,1604,543,1608,546,1614,550,1620,550,1809,550,1816,548,1819,545,1822,541,1824,536,1824,492,1823,481,1818,476,1813,474,1807,473,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "os") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo);
			dispatch(viewRoadmapRoleByRoleId(requestInfo));

			const viewport = document.querySelector('meta[name="viewport"]');
			// console.log(viewport)
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
