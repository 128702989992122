import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Header from '../../Component/Header.js';
import LeftSidebar from 'views/Component/LeftSidebar.js';
// import RecommendedJobs from "views/Component/RecommendedJobs.js";
import SocialFeed from 'views/Component/SocialFeed/SocialFeed';
import CreatePostModal from '../../../components/CreatePostModal/CreatePostModal';
import CreatePollModal from '../../../components/CreatePostModal/CreatePollModal';
import PreviewPostPhotosModal from '../../Component/PreviewPostPhotosModal';
import PreviewPostVideosModal from '../../Component/PreviewPostVideosModal';
import RightSideBarUserList from 'views/Component/RightSideBarUserList.js';
import Footer from 'views/Component/Footer.js';
import Backdrop from '../../Component/Backdrop.js';

import Button from 'components/CustomButtons/Button.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from '../../../components/Grid/GridItem.js';

import { makeStyles } from '@material-ui/core';

import { errorCleanUp } from 'configurations/redux/authSlice.js';
import loginImage from 'assets/img/Login_Image.png';
import { Grid, Hidden } from '@material-ui/core';
import Cookies from 'js-cookie';
import CustomDialog from '../../../components/CustomDialog/CustomDialog.js';
import { isDesktop, isTablet } from 'react-device-detect';
import SnackbarContent from 'components/Snackbar/SnackbarContent.js';
import { resendVerificationEmail } from 'configurations/redux/authSlice.js';
import BottomNavBar from 'components/BottomNavBar/BottomNavBar.js';
import {
  getSocialPostByRecent,
  getSocialPostByTop,
  getSocialPostByPostId,
  resetPostGet,
  getHashtagMatch,
  getSocialPostByHashId,
  resetHashtagMatch,
} from 'configurations/redux/socialFeedSlice.js';

import {
  decryptData,
  getVisibleOption,
  getSocialPostTypeOption,
  getCommentPermissionByPermissionType,
  getPollDurationOption,
  gaPageView,
} from 'configurations/configurations';

import LandingPageFeedStyle from 'assets/jss/material-kit-pro-react/views/pageStyle/LandingPageFeed/LandingPageFeedStyle.js';

import SocialFeedControl from '../LandingPageFeed/SocialFeedControl';

import MetatagHelmet from '../../Component/MetatagHelmet';

const useStyles = makeStyles(LandingPageFeedStyle);

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  React.useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const LandingPageFeed = ({ match }) => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();
  var isKicked = Cookies.get('isKicked');

  const auth = useSelector(state => state.auth);
  const isVerified = useSelector(state => state.auth.isVerified);
  const isResend = useSelector(state => state.auth.isResend);
  const user = useSelector(state => state.auth.user);

  const [emailSend, setEmailSend] = useState(false);
  const isError = useSelector(state => state.auth.isError);

  // #region <------- Component States -------->
  const [isCreatePostModalOpen, setIsCreatePostModalOpen] = useState(false);
  const [isCreatePollModalOpen, setIsCreatePollModalOpen] = useState(false);
  const [question, setQuestion] = useState('');
  const [options, setOptions] = useState(['', '']);
  const [pollTiming, setPollTiming] = useState('1 day');
  const [postedData, setPostedData] = useState({});

  const [uploadedPhotos, setUploadedPhotos] = useState([]);
  const [isPreviewPostPhotosOpen, setIsPreviewPostPhotosOpen] = useState(false);
  const [previewPhoto, setPreviewPhoto] = useState();
  const [uploadedVideos, setUploadedVideos] = useState([]);
  const [isPreviewPostVideosOpen, setIsPreviewPostVideosOpen] = useState(false);
  const [previewVideo, setPreviewVideo] = useState();
  const [draggedItemindex, setDraggedItemIdex] = useState(null);
  const [uploadError, setError] = useState('');

  const [visibleOptions, setVisibleOptions] = useState(null);
  const [commentabilityOptions, setCommentabilityOptions] = useState(null);
  const [socialPostTypeOptions, setSocialPostTypeOptions] = useState(null);
  const [pollDurationOptions, setPollDurationOptions] = useState(null);
  const [postsList, setPostsList] = useState([]);

  const [width, height] = useWindowSize();

  const [candidateShow, setCandidateShow] = useState(true);
  const [corporateShow, setCorporateShow] = useState(false);
  const [cardAnimation, setCardAnimation] = useState('cardHidden');
  const [isLoading, setIsLoading] = useState(false);
  const [editPost, setEditPost] = useState(null);
  const [sharePost, setSharePost] = useState(null);
  const [shareJob, setShareJob] = useState(null);
  const hashtagMatch = useSelector(state => state.socialFeed.hashtagMatch);

  const dialogDefault = {
    title: 'Warning',
    description: 'Your session has expired and auto logged out by system. Thank you for using our system.',
    buttonYes: '',
    buttonNo: 'OK',
  };
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    dialogDefault,
  });

  // #endregion

  window.onbeforeunload = function () {
    // window.scrollTo({ top: 0, behavior: "smooth" });
    scrollToTop();
  };

  useEffect(() => {
    let isMounted = true;
    scrollToTop();
    // window.scrollTo({ top: 0, behavior: "smooth" });
    history.replace(match.url);
    if (isError) {
      dispatch(errorCleanUp());
    }

    if (isKicked != undefined && isKicked == 1) {
      Cookies.remove('isKicked');
      setConfirmDialog({
        isOpen: true,
        dialogDefault,
        onCancel: () => {
          setConfirmDialog({
            isOpen: false,
            dialogDefault,
          });
        },
      });
    }

    getVisibleOption()
      .then(res => {
        if (isMounted) setVisibleOptions(res.data.payload);
      })
      .catch(err => console.log(err));

    getSocialPostTypeOption()
      .then(res => {
        if (isMounted) setSocialPostTypeOptions(res.data.payload);
      })
      .catch(err => console.log(err));

    // Permission type 1 and 2
    getCommentPermissionByPermissionType('1,2')
      .then(res => {
        if (isMounted) setCommentabilityOptions(res.data.payload);
      })
      .catch(err => console.log(err));

    getPollDurationOption()
      .then(res => {
        if (isMounted) setPollDurationOptions(res.data.payload);
      })
      .catch(err => console.log(err));

    gaPageView(window.location.pathname);
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (match.params.type) {
      // console.log(match);
      var type = match.params.type;
      var id = match.params.id;
      if (type != 1) {
        if (type == 2 && id.length > 1) {
          history.replace(match.url.replace('/' + type + '/' + id, ''));
          dispatch(resetHashtagMatch());
          //get post by post id
          const requestProfile = {
            acctId: user.acctId,
            accessToken: localStorage.getItem('token'),
            email: user.email,
            lastRecordSize: 0,
            requestRecordSize: 1,
            postId: decryptData(id),
            postList: [],
            parentId: 0,
            requestRecordSizeComment: 3,
            commentRequestLength: 0,
            visibleIds: '1,2',
            groupId: 0,
          };
          dispatch(getSocialPostByPostId(requestProfile));
        } else if (type == 3 && id.length > 1) {
          //get post by post id
          dispatch(resetPostGet());
          const requestProfile = {
            acctId: user.acctId,
            accessToken: localStorage.getItem('token'),
            email: user.email,
            hashId: decryptData(id),
          };
          dispatch(getHashtagMatch(requestProfile));
        }
      }
    } else {
      dispatch(resetPostGet());
      dispatch(resetHashtagMatch());
      loadFeed();
    }
  }, []);

  useEffect(() => {
    if (match.params.type) {
      var type = match.params.type;
      var id = match.params.id;
      if (type == 1) {
        // console.log(match);
        history.replace(match.url.replace('/1/0', ''));
        scrollToTop();
        dispatch(resetPostGet());
        dispatch(resetHashtagMatch());
        loadFeed();
      } else if (type == 2 && id.length > 1) {
        history.replace(match.url.replace('/' + type + '/' + id, ''));
        dispatch(resetHashtagMatch());
        //get post by post id
        const requestProfile = {
          acctId: user.acctId,
          accessToken: localStorage.getItem('token'),
          email: user.email,
          lastRecordSize: 0,
          requestRecordSize: 1,
          postId: decryptData(id),
          postList: [],
          parentId: 0,
          requestRecordSizeComment: 3,
          commentRequestLength: 0,
          visibleIds: '1,2',
          groupId: 0,
        };
        dispatch(getSocialPostByPostId(requestProfile));
      }
    }
  }, [match]);

  const scrollToTop = () => {
    var timer = setInterval(() => {
      var result = document.getElementById('socialFeedPage');
      if (result) {
        result.scrollIntoView();
        window.scrollTo(0, 0);
        clearInterval(timer);
      }
    }, 100);
  };

  const loadFeed = () => {
    var previousPostListType = localStorage.getItem('postListType');
    const requestProfile = {
      acctId: user.acctId,
      accessToken: localStorage.getItem('token'),
      email: user.email,
      lastRecordSize: 0,
      requestRecordSize: 5,
      hashId: 0,
      userId: 0,
      groupId: 0,
      pageId: 0,
      sortBy: previousPostListType ? previousPostListType : 'recent',
      keyword: '',
      visibleIds: '1,2',
    };
    // console.log(requestProfile);
    // console.log(previousPostListType);
    // if (previousPostListType || previousPostListType == "Recent")
    dispatch(getSocialPostByRecent(requestProfile));
    // else dispatch(getSocialPostByTop(requestProfile));
  };

  /* 	useEffect(() => {
		var header = document.getElementById("pageHeader");
		if (isError) {
			if (header) header.style.height = "auto";
		} else {
			if (header && window.innerHeight > 1000 && window.innerWidth > 1900) {
				header.style.height = "100vh";
			}
		}
	}, [isError]); */

  // const resendEmail = () => {
  //   const requestProfile = {
  //     acctTypeId: userStatus.acctTypeId,
  //     email: userStatus.email,
  //   };
  //   console.log(requestProfile);
  //   dispatch(resendVerificationEmail(requestProfile));
  //   setEmailSend(true);
  // };

  /* React.useEffect(() => {
    if (userStatus && !isError) {
      history.push("/community-page");
    }
  }, []);
  /* React.useEffect(() => {
  if (userStatus && !isError) {
    history.push("/community-page");
  }
  }, []); */
  // const clickCandidateTab = () => {
  //   setCandidateShow(true);
  //   setCorporateShow(false);
  // };

  // const clickCorporateTab = () => {
  //   setCorporateShow(true);
  //   setCandidateShow(false);
  // };

  setTimeout(function () {
    setCardAnimation('');
  }, 700);

  const removePollData = () => {
    setQuestion('');
    setOptions([]);
    setPollTiming('');
  };

  const statusChangeHandler = item => {
    console.log(item);
    // setIsCreatePostModalOpen(false);
    // if (item == "post" || item === "edit" || item === "posted") {
    if (item == 'post' || item === 'posted') {
      removePollData();
      setEditPost(null);
      setSharePost(null);
      setIsCreatePostModalOpen(false);
      setIsCreatePollModalOpen(false);
      setUploadedPhotos([]);
      setUploadedVideos([]);
    }
    // else if (item === "poll") {
    // 	setIsCreatePollModalOpen(false);
    // }
  };

  const handleCreatePostClick = item => {
    if (item === 'post') {
      setIsCreatePostModalOpen(true);
      setIsCreatePollModalOpen(false);
    } else if (item === 'poll') {
      // setIsCreatePostModalOpen(false);
      setIsCreatePollModalOpen(true);
    }
  };

  const handleEditPostClick = item => {
    console.log(item);
    if (item) {
      // statusChangeHandler("edit");
      setEditPost(item);
      setSharePost(null);
      setIsCreatePostModalOpen(true);
      setUploadedPhotos([]);
      setUploadedVideos([]);
      removePollData();
      savePostData('');
      handleCreatePostClick('post');
      setShareJob(null);
      setSharePost(null);

      if (item.typeOptionId == 0) {
      } else if (item.typeOptionId == 1) {
        var newPhotoList = [];
        for (var i = 0; i < item.postTypes.length; i++) {
          newPhotoList.push(item.postTypes[i].attachment);
        }
        setUploadedPhotos([...newPhotoList]);
      } else if (item.typeOptionId == 2) {
        var newVideoList = [];
        for (var i = 0; i < item.postTypes.length; i++) {
          newVideoList.push(item.postTypes[i].attachment);
        }
        setUploadedVideos([...newVideoList]);
      } else if (item.typeOptionId == 3) {
        for (var i = 0; i < item.postTypes.length; i++) {
          for (var j = 0; j < item.postTypes[i].poolList.length; j++) {
            var options = [];
            for (var k = 0; k < item.postTypes[i].poolList[j].poolOptionList.length; k++) {
              options.push(item.postTypes[i].poolList[j].poolOptionList[k].optionDesc);
            }
            savePollData(item.postTypes[i].poolList[j].question, options, item.postTypes[i].poolList[j].durationName);
          }
        }
      } else if (item.typeOptionId == 4) {
        setSharePost(item.sharedPost.postList[0]);
      } else if (item.typeOptionId == 5) {
        setShareJob(item.sharedJob);
      }
    }
  };

  const handleSharePostClick = item => {
    console.log(item);
    if (item) {
      // statusChangeHandler("edit");
      setEditPost(null);
      setSharePost(item);
      setIsCreatePostModalOpen(true);
      setUploadedPhotos([]);
      setUploadedVideos([]);
      removePollData();
      savePostData('');
      handleCreatePostClick('post');
    }
  };

  const savePollData = (question, options, pollTiming) => {
    console.log(question, options, pollTiming);
    setQuestion(question);
    setOptions(options);
    setPollTiming(pollTiming);
  };
  const savePostData = (text, image, video) => {
    let tempData = {};
    tempData.text = text;
    if (question.length > 0) {
      tempData.question = question;
      tempData.pollTiming = pollTiming;
      tempData.options = options;
    }
    setPostedData(tempData);
  };

  const selectPhotos = photos => {
    if (photos) {
      // setIsLoading(true);
      checkError('photos', photos);
      console.log(photos);
    } else {
      // setUploadedPhotos([]);
    }
  };

  const selectVideos = videos => {
    if (videos) {
      checkError('videos', videos);
      console.log(videos);
    } else {
      setUploadedVideos([]);
    }
  };

  const removeSinglePhoto = index => {
    console.log(index, uploadedPhotos);
    setUploadedPhotos(uploadedPhotos.filter((val, i) => index != i));
    console.log(uploadedPhotos);
  };

  const removeSingleVideo = index => setUploadedVideos(uploadedVideos.filter((val, i) => index != i));

  const checkError = (attachmentType, payload) => {
    console.log(payload);
    if (attachmentType === 'photos') {
      // let files = payload.filter((file) => file.size > 15728640);
      // let invalidFiles = payload.filter(
      // 	(file) => file && file["type"].split("/")[0] != "image"
      // );

      if (payload.length > 9) {
        console.log(payload);
        setError('You can upload maximum of 9 photos');
        payload = payload.filter((file, index) => index < 9);
      }
      if (payload.length <= 9) {
        setUploadedPhotos(payload);
        setPreviewPhoto(0);
        // setIsCreatePostModalOpen(false);
        setIsPreviewPostPhotosOpen(true);
      }
      // if (invalidFiles && invalidFiles.length) {
      // 	console.log(payload);
      // 	setError("Please upload only images");
      // 	payload = payload.filter(
      // 		(file) => file && file["type"].split("/")[0] == "image"
      // 	);
      // }
      // if (files && files.length) {
      // 	console.log(payload);
      // 	setError(
      // 		"Some files exceed max. allowed size of 15MB and cannot be uploaded"
      // 	);
      // 	payload = payload.filter((file) => file.size < 15728640);
      // }
      // if (payload.length) {
      // 	setUploadedPhotos(payload);
      // 	setPreviewPhoto(0);
      // 	setIsCreatePostModalOpen(false);
      // 	setIsPreviewPostPhotosOpen(true);
      // 	setIsPreviewPostPhotosOpen(true);
      // }
      // setError("");
    } else if (attachmentType === 'videos') {
      setIsPreviewPostVideosOpen(true);
      let files = payload.filter(file => file.size > 62914560);
      let invalidFiles = payload.filter(file => file && file['type'].split('/')[0] !== 'video');

      if (payload.length > 1) {
        setError('You can upload maximum of 1 video');
        payload = payload.filter((file, index) => index < 1);
      }
      if (invalidFiles && invalidFiles.length) {
        setError('Please upload only videos');
        payload = payload.filter(file => file && file['type'].split('/')[0] == 'video');
      }
      if (files && files.length) {
        setError('The video files exceed allowed size of 60MB and cannot be uploaded');
        payload = payload.filter(file => file.size < 62914560);
      }
      console.log(payload);
      if (payload.length) {
        setUploadedVideos(payload);
        setPreviewVideo(0);
        // setIsCreatePostModalOpen(false);
        setIsPreviewPostVideosOpen(true);
      } else {
        setUploadedVideos(null);
      }
      // setError("");
    }
  };

  /* 	const modalResize = () => {
		var timerApplied = setInterval(() => {
			var leftBar = document.getElementById("socialFeedLeftBar");
			if (leftBar) {
				clearInterval(timerApplied);
				// console.log(leftBar, rightBar);
				if (window.innerHeight < leftBar.clientHeight + 150) {
					leftBar.style.top =
						window.innerHeight - leftBar.clientHeight - 30 + "px";
				} else {
					leftBar.style.top = "125px";
				}
			}
		}, 50);
		return () => clearInterval(timerApplied);
	};

	React.useEffect(() => {
		if (document.readyState === "complete") modalResize();
		window.addEventListener("resize", modalResize);
		return function cleanup() {
			window.removeEventListener("resize", modalResize);
		};
	});
 */
  const [createPost, setCreatePost] = React.useState(false);
  const onClosePost = () => {
    setCreatePost(false);
  };

  return (
    <React.Fragment>
      <MetatagHelmet
        metaTag={{
          title: 'Social Feed',
          description: 'Social Feed',
          url: window.location.href,
        }}
      />
      <div>
        <div id="LandingSocialFeed" className={classes.body}>
          <Header />
          {confirmDialog && <CustomDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />}

          <div className={classes.container} id="socialFeedPage">
            <GridContainer justifyContent="center" spacing={2}>
              <Hidden smDown>
                <GridItem
                  xs={3}
                  sm={3}
                  md={3}
                  lg={2}
                  xl={2}
                  /* className={classes.leftSideBar} */
                >
                  <LeftSidebar />
                </GridItem>
              </Hidden>
              <GridItem xs={12} sm={9} md={6} lg={8} xl={5} className={classes.middlePart}>
                <Hidden mdUp>
                  <Button
                    fullWidth
                    color="oppty"
                    onClick={ev => {
                      // removePollData();
                      // savePostData("");
                      // handleCreatePostClick("post");
                      setCreatePost(true);
                    }}
                    className={classes.createPostBtn}
                    // style={width > 1200 ? { display: "none" } : {}}
                  >
                    Create Post
                  </Button>
                </Hidden>

                {/* <RecommendedJobs /> */}
                <SocialFeedControl
                  match={match}
                  hideAddon={false}
                  showFeed={true}
                  createPost={createPost}
                  onClosePost={onClosePost}
                  groupId={0}
                  scrollableTarget="LandingSocialFeed"
                  isSearch={false}
                  isGroupMember={false}
                  postStatus={1}
                  isInGroup={false}
                  // match={match}
                  // postedData={postedData}
                  // handleEditPostClick={handleEditPostClick}
                  // handleSharePostClick={handleSharePostClick}
                  // hideAddon={false}
                />
              </GridItem>
              <Hidden smDown>
                <GridItem xs={3} sm={3} md={3} lg={2} xl={2} className={classes.rightSidebar}>
                  {/* <Button
										fullWidth
										color="oppty"
										onClick={(ev) => {
											removePollData();
											savePostData("");
											handleCreatePostClick("post");
										}}
										className={classes.createPostBtn}
										// style={props.width < 1200 ? { display: "none" } : {}}
									>
										Create Post
									</Button> */}
                  <RightSideBarUserList
                    createPostClick={setCreatePost}
                    isOwner={true}
                    // removePoll={removePollData}
                    // savePostData={savePostData}
                    // handleCreatePostClick={handleCreatePostClick}
                    // width={width}
                  />
                </GridItem>
              </Hidden>
            </GridContainer>
          </div>
          <Footer />
          {/* <Hidden mdDown>
						
					</Hidden>
					<Hidden mdUp>
						<div className={classes.footerNav}>
							<BottomNavBar />
						</div>
					</Hidden> */}
        </div>
        {user &&
        user.acctId &&
        visibleOptions &&
        socialPostTypeOptions &&
        commentabilityOptions &&
        isCreatePostModalOpen ? (
          <CreatePostModal
            isOpen={isCreatePostModalOpen}
            setPostsList={setPostsList}
            user={user}
            oriVisibleOptions={visibleOptions}
            oriSocialPostTypeOptions={socialPostTypeOptions}
            oriCommentabilityOptions={commentabilityOptions}
            statusChangeHandler={statusChangeHandler}
            handleCreatePostClick={handleCreatePostClick}
            question={question}
            options={options}
            pollTiming={pollTiming}
            removePoll={removePollData}
            savePostData={savePostData}
            selectPhotos={selectPhotos}
            selectVideos={selectVideos}
            uploadedPhotos={uploadedPhotos}
            uploadedVideos={uploadedVideos}
            removeAnUploadedPhoto={removeSinglePhoto}
            removeAnUploadedVideo={removeSingleVideo}
            onPhotosListItemClick={photoIndex => {
              setPreviewPhoto(photoIndex);
              setIsPreviewPostPhotosOpen(true);
            }}
            onVideosListItemClick={videoIndex => {
              setPreviewVideo(videoIndex);
              setIsPreviewPostVideosOpen(true);
            }}
            onPhotosListItemDragStart={draggedItemindex => setDraggedItemIdex(draggedItemindex)}
            onPhotosListItemDrop={droppedItemindex => {
              if (draggedItemindex !== null) {
                let photos = uploadedPhotos;
                let photo = photos[draggedItemindex];
                photos[draggedItemindex] = uploadedPhotos[droppedItemindex];
                photos[droppedItemindex] = photo;
                setUploadedPhotos(photos);
                setDraggedItemIdex(null);
              }
            }}
            onVideosListItemDragStart={draggedItemindex => setDraggedItemIdex(draggedItemindex)}
            onVideosListItemDrop={droppedItemindex => {
              if (draggedItemindex !== null) {
                let videos = uploadedVideos;
                let video = videos[draggedItemindex];
                videos[draggedItemindex] = uploadedVideos[droppedItemindex];
                videos[droppedItemindex] = video;
                setUploadedVideos(videos);
                setDraggedItemIdex(null);
              }
            }}
            isLoading={setIsLoading}
            setError={setError}
            editPost={editPost}
            sharePost={sharePost}
            shareJob={shareJob}
            groupId={0}
            postStatus={1}
          />
        ) : (
          <></>
        )}
        <PreviewPostPhotosModal
          isOpen={isPreviewPostPhotosOpen}
          uploadedPhotos={uploadedPhotos}
          previewPhotoIndex={previewPhoto}
          removeAnUploadedPhoto={removeSinglePhoto}
          setPreviewPhoto={setPreviewPhoto}
          selectPhotos={selectPhotos}
          onClose={() => {
            setIsPreviewPostPhotosOpen(false);
            removePollData();
            savePostData('');
            setIsCreatePostModalOpen(true);
            // handleCreatePostClick("post");
          }}
          error={uploadError}
          onPhotosListItemDragStart={draggedItemindex => setDraggedItemIdex(draggedItemindex)}
          onPhotosListItemDrop={droppedItemindex => {
            if (draggedItemindex !== null) {
              let photos = uploadedPhotos;
              let photo = photos[draggedItemindex];
              photos[draggedItemindex] = uploadedPhotos[droppedItemindex];
              photos[droppedItemindex] = photo;
              setUploadedPhotos(photos);
              setDraggedItemIdex(null);
            }
          }}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          setError={setError}
        />
        <PreviewPostVideosModal
          isOpen={isPreviewPostVideosOpen}
          uploadedVideos={uploadedVideos}
          previewVideoIndex={previewVideo}
          removeAnUploadedVideo={removeSingleVideo}
          setPreviewVideo={setPreviewVideo}
          selectVideos={selectVideos}
          onClose={() => {
            setIsPreviewPostVideosOpen(false);
            removePollData();
            savePostData('');
            setIsCreatePostModalOpen(true);
            // handleCreatePostClick("post");
          }}
          error={uploadError}
          onVideosListItemDragStart={draggedItemindex => setDraggedItemIdex(draggedItemindex)}
          onVideosListItemDrop={droppedItemindex => {
            if (draggedItemindex !== null) {
              let videos = uploadedVideos;
              let video = videos[draggedItemindex];
              videos[draggedItemindex] = uploadedVideos[droppedItemindex];
              videos[droppedItemindex] = video;
              setUploadedVideos(videos);
              setDraggedItemIdex(null);
            }
          }}
          setError={setError}
        />
        <CreatePollModal
          isOpen={isCreatePollModalOpen}
          pollDurationOptions={pollDurationOptions}
          statusChangeHandler={statusChangeHandler}
          handleCreatePostClick={handleCreatePostClick}
          savePollData={savePollData}
          editQuestion={question}
          editOptions={options}
          editPollTiming={pollTiming}
        />
      </div>
      {isLoading ? <Backdrop /> : null}
    </React.Fragment>
  );
};

export default LandingPageFeed;
