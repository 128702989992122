import React, { Fragment, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Header from "views/Component/Header.js";
import Footer from "views/Component/Footer.js";
import Hidden from "@material-ui/core/Hidden";
import clsx from 'clsx';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import testimonialsPageStyles from 'assets/jss/material-kit-pro-react/views/pageStyle/TestimonialsPage/testimonialsPageStyles';
import { Box, Button, makeStyles, Modal, Typography,IconButton } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import Avatar from "@mui/material/Avatar";
import Skeleton from "@mui/material/Skeleton";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { decryptData,TimeFormat } from 'configurations/configurations.js';
import axios from "axios";
import Cookies from "js-cookie";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import dp from "assets/img/faces/marc.jpg";
import cannotMessage from "assets/img/cannot-message.png";

import { useSnackbar } from "notistack";

/*Sourabh sir start*/
import {
	updateJobPostStatus,
	getJobApplications, 
	jobSelectReset,
    getJobApplicationsWithStatus, 
} from "configurations/redux/jobManagementSlice.js";
import { getSelectJobPosts,createUserEvent,updateApplicantStatus} from "configurations/redux/jobManagementSlice.js";


import {
	getProfiles
} from "configurations/redux/userSlice.js";

/*Sourabh sir end*/
import Profile from "assets/img/profileImg.jpg";
import IconLibrary from "views/Component/IconLibrary.js";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

import {
	activateEditType,
	deactivateEditType,
} from "configurations/redux/editProfileModalSlice";

import Tab from '@mui/material/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
// Research how to combine multiple style files in makeStyles


const HiredTab = (match) => {   

/*sandeep mam start*/
    const [progress, setProgress] = React.useState(10);
  
    /* React.useEffect(() => {
      const timer = setInterval(() => {
        setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
      }, 8000);
      return () => {
        clearInterval(timer);
      };
    }, []);  */
  
    // return <CircularProgressWithLabel value={progress} />;
  const [open11, setOpen11] = React.useState(false);
  const handleOpen11 = () => setOpen11(true);
  const handleClose11 = () => setOpen11(false);
  
      const [openPreMove, setOpenPreMove] = React.useState(false);
  const handleOpenPreMove = () => setOpenPreMove(true);
  const handleClosePreMove = () => setOpenPreMove(false);
  
  
  const [open22, setOpen22] = React.useState(false);
  const handleOpen22 = () => setOpen22(true);
  const handleClose22 = () => setOpen22(false);
  const [open222, setOpen222] = React.useState(false);
  const handleOpen222 = () => setOpen222(true);
  const handleClose222 = () => setOpen222(false);
  const [open336, setOpen336] = React.useState(false);
  const handleOpen336 = () => setOpen336(true);
  const handleClose336 = () => setOpen336(false);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };
 const [value, setValue] = React.useState('1');

  const handleChange111 = (event, newValue) => {
    setValue(newValue);
  };

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
      borderRadius: 10,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '16.5px 14px',
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 10,
        borderColor: '#3f51b5',
      },
    },
  }));

/*sandeep mam end*/
  
    const styles = makeStyles(testimonialsPageStyles)();
    const [optionToShow, setOption] = useState('myTestimonials');
    const getVideosHandler = () => {
        console.log("I M CLICKED");
    }
    const [attachmentType, setAttachmentType] = React.useState(4);
    const userStatus = useSelector(state => state.auth.user);
    //const isMe = decryptData(match.params.acctId) === userStatus.acctId;
    const tabs = ['myTestimonials', 'pendingRequests', 'testimonialsGiven'];

    const [payemtSubscriptionErr, setPayemtSubscriptionErr] = React.useState('');
    const [videoSizeErr, setvideoSizeErr] = React.useState('');
    const bytesToMegaBytes = size => size / (1024 ** 2);
	const history = useHistory();
	const dispatch = useDispatch();
	var isKicked = Cookies.get("isKicked");

	const auth = useSelector((state) => state.auth);
	const currUser = useSelector((state) => state.auth.user);
	const isError = useSelector((state) => state.auth.isError);
	const isVerified = useSelector((state) => state.auth.isVerified);
	const isResend = useSelector((state) => state.auth.isResend);
	const buildProfileFlag = useSelector((state) => state.auth.buildProfileFlag);
	const isViewed = useSelector((state) => state.user.isViewed);
	var user = useSelector((state) => state.user);
	const isLoading = useSelector((state) => state.user.isLoading);
	const blockCount = useSelector((state) => state.user.blockCount);
	const isUpdated = useSelector((state) => state.user.isUpdated);

	const [myself, setMyself] = useState(false);
	const [emailSend, setEmailSend] = useState(false);
	const [userData, setUserData] = useState(null);
	const [connStatus, setConnStatus] = useState(null);

	const [headerTab, setHeaderTab] = useState("myActivity");
	const [isEditProfileOpen, setIsEditProfileOpen] = useState(false);
	const [editOption, setEditOption] = useState("");
	const [isCannotMessageModalOpen, setIsCannotMessageModalOpen] = useState(
		false
	);
	const [createPost, setCreatePost] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


console.log('user');
console.log(user);


	/*Sourabh sir start*/
	const [jobPostId, setJobPostId] = React.useState();
	/* const applicationTabJobPost = useSelector(
		(state) => state.jobManagement.applications
	);
	 */
	
			
	   const updateJobStatus = async (stateJobPostId,applicantId,status_id) => {


      
            console.log('------stateJobPostId---------' + stateJobPostId+'----applicantId--'+applicantId);
           
			
			/* 	const request = {
			acctId: userStatus.acctId,
			email: userStatus.email,
			accessToken: localStorage.getItem("token"),

			applicantId:applicantId,
			applId:stateJobPostId,
			applStatusId: 3

			}; */
			const request = {
			acctId: 139,
			email: 'hwanjinchoi0424@gmail.com',
			accessToken: localStorage.getItem("token"),
			//jobPostId: stateJobPostId,
				 
				applicantId:applicantId,
				applId:stateJobPostId,
				applStatusId: status_id
				
			};

			dispatch(updateApplicantStatus(request));
          
		match.handleJobLoderStatusMethod(true);
		setTimeout(() => {getCandidateInfo()}, 3000);
           // window.location.reload(false);
        

		};
	
	const getCandidateInfo = () => {
        /* const requestProfile = {
			acctId: userStatus.acctId,
			email: userStatus.email,
			accessToken: localStorage.getItem("token"),
			jobPostId: stateJobPostId,
			ipAddr: localStorage.getItem("ipAddr"),
			lastRecordSize: 0,
			requestRecordSize: 1,
		}; */
			const requestProfile = {
			acctId: userStatus.acctId,
			email: userStatus.email,
			accessToken: localStorage.getItem("token"),
			jobPostId: stateJobPostId,
			ipAddr: localStorage.getItem("ipAddr"),
			lastRecordSize: 0,
			requestRecordSize: 100,
		};

		dispatch(getJobApplicationsWithStatus(requestProfile));
		match.handleJobLoderStatusMethod(false);
        console.log("I am clicked Here in Rahul Code");
       // console.log(applications);
        console.log("I am clicked Here in Rahul Code");
        
    }

	
	
	 const handleArchive = (id) => {
		 
		 //getUserProfile(id)
		 const requestAcrchiveProfile = {
			acctId: 139,
			email: 'hwanjinchoi0424@gmail.com',
			userId: 251,
			accessToken: localStorage.getItem("token"),
		};
		dispatch(getProfiles(requestAcrchiveProfile));
		 handleOpen336();
		 
	 }

//	const stateJobPostId = decryptData(match.params.jobPostId);
	const stateJobPostId = match.jobPostId;
	console.log('matchhire');
//	console.log(applicationTabJobPost);
	
	
/* 	const selectedJobPost = useSelector(
		(state) => state.jobManagement.corpSelectedJobPost
	);
    const applicantList = useSelector(
		(state) => state.jobManagement.applicantList
	);
    const applications = useSelector(
		(state) => state.jobManagement.applications
	);
    
	const bizId = useSelector((state) => state.business.bizId);
	
	
	const applicationJobPost = useSelector(
		(state) => state.jobManagement
	);
	console.log('applicationJobPost');
	
	console.log(applicationJobPost.applications);
	console.log('corpSelectedJobPost');
	console.log(applicationJobPost.corpSelectedJobPost); */

const IsolatedMenu = props => {

}


    /* END RAHUL CODE */

	return (<div className='appTabContentInner hired'>
                                                       <Typography className='appliTitle'>Hired</Typography>
           {match.applicantListMainData && match.applicantListMainData.hired[0].candidateCount > 0 && match.applicantListMainData.hired.map((item, index) => {

                                                            return(                                            
                                                       <Typography  key={index}  className='applicationMain'>
                                                         <Typography className='applicationTop'>
                                                            <span className='appSpan'></span>
                                                            <Typography className='appCen'>
                                                                <div className="progress progressRed">
                                                                <div className="barOverflow">
                                                                <div className="bar"></div>
                                                                </div>
                                                                <span>80</span>
                                                                </div>
                                                            </Typography>
                                                            <Typography className='applicationTopRight'>
                                                            
                                                            </Typography>
                                                         </Typography>
                                                         <Typography className='appNamee'>{item.firstName}</Typography>
                                                         <p className='desc'>{item.headline}</p>
                                                         <span className="TimeeSpan">{TimeFormat(item.applicationDate)}</span>
                                                         <Typography component='div' className='dayOuter btnOuter rejectShortlist'>
                                                            <Typography component='div' className='dayRight'>
                                                              <Button onClick={handleOpenPreMove}  className='rejectBtn'>Cancel</Button>
															  
															  <Modal
                                                                open={openPreMove}
                                                                onClose={handleClosePreMove}
                                                                aria-labelledby="modal-modal-title"
                                                                aria-describedby="modal-modal-description"
                                                                >
                                                                <Box className='customBox' sx={style}>
                                                                    <Link to="#" onClick={handleClosePreMove}  className='crossBtn'><i class="fas fa-xmark"></i></Link>
                                                                    <Typography className='folderDiv'><i class="fas fa-folder"></i></Typography>
                                                                    <Typography className='shortlistTitle'>
                                                                     Move <b>{item.firstName}</b> to   
                                                                     <span> Offer?</span>
                                                                    </Typography>
                                                                    <Typography component='div' className='dayOuter btnOuter rejectShortlist interviewBtns'>
                                                                        <Typography component='div' className='dayRight'>
                                                                        <Button onClick={handleClosePreMove}  className='rejectBtn'>Undo</Button>
                                                                        </Typography>
                                                                        <Typography component='div' className='dayLeft'>
                                                                        <Button onClick={() => updateJobStatus(item.applId,item.acctId,15)} className='applyBtn'>Confirm Move</Button>
                                                                        </Typography>
                                                                    </Typography>
                                                                </Box>
                                                                </Modal>
															  
															  
                                                            </Typography>
                                                            <Typography component='div' className='dayLeft'>
                                                             <Button className='applyBtn archiveBtn' onClick={()=>handleArchive(item.applId)}>Archive</Button>
                                                             <Modal
                                                                open={open336}
                                                                onClose={handleClose336}
                                                                aria-labelledby="modal-modal-title"
                                                                aria-describedby="modal-modal-description"
                                                                className='customModal applicantModal'
                                                                >
                                                                <Box className='customBox' sx={style}>
                                                                   <div className='projectDiv'>
                                                                     <div className='projectDivLeft'>
                                                                        <img src={user.profilePic?user.profilePic:Profile}  />
                                                                        <div className='projectleftInner'>
                                                                            <div className='profileT'>{user.profileRecord.firstName?user.profileRecord.firstName:''} {user.profileRecord.surname?user.profileRecord.surname:''}</div>
                                                                            <p>{user.profileRecord.firstName?user.profileRecord.headline:''}</p>
                                                                        </div>
                                                                        <Typography className='appCen'>
                                                                            <div className="progress">
                                                                            <div className="barOverflow">
                                                                            <div className="bar"></div>
                                                                            </div>
                                                                            <span>{user.profileStrength[0].score?user.profileStrength[0].score:0}</span>
                                                                            </div>
                                                                        </Typography>
                                                                     </div>
                                                                     <div className='projectDivRight'>
                                                                        <ul>
                                                                            <li><a href=""><i class="fa-solid fa-envelope"></i><span>Message</span></a></li>
                                                                            <li><a href=""><i class="fa-solid fa-envelope"></i><span>Save</span></a></li>
                                                                        </ul>
                                                                     </div>
                                                                   </div>
                                                                   <Box sx={{ width: '100%', typography: 'body1' }} className='customTabs'>
                                                                    <TabContext value={value}>
                                                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                                        <TabList onChange={handleChange111} aria-label="lab API tabs example">
                                                                            <Tab className='active' label="Details" value="1" />
                                                                            <Tab label="Education" value="2" />
                                                                            <Tab label="Experience" value="3" />
                                                                            <Tab label="Resume" value="4" />
                                                                            <Tab label="Psychometric" value="5" />
                                                                            <Tab label="Video" value="6" />
                                                                        </TabList>
                                                                        </Box>
                                                                        <TabPanel value="1">
                                                                            <div className="basicInfo">
                                                                              <div className="basicInfoTitle">Basic Information</div>
                                                                              <div className="basicInfoBody">
                                                                              <GridContainer justifyContent="center" spacing={2}>
                                                                                <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                  <label className="basicLabel">Name</label>
                                                                                  <p className="basic_p">{user.profileRecord.firstName?user.profileRecord.firstName:''} {user.profileRecord.surname?user.profileRecord.surname:''}</p>
                                                                                </GridItem>
                                                                                <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                  <label className="basicLabel">Email</label>
                                                                                  <p className="basic_p">{user.email?user.email:''}</p>
                                                                                </GridItem>
                                                                            </GridContainer>
                                                                            <GridContainer justifyContent="center" spacing={2}>
                                                                                <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                  <label className="basicLabel">Phone Number</label>
                                                                                  <p className="basic_p">{user.profileRecord.contactNo?user.profileRecord.contactNo:''}</p>
                                                                                </GridItem>
                                                                                <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                  <label className="basicLabel">Origin</label>
                                                                                  <p className="basic_p">Invited</p>
                                                                                </GridItem>
                                                                            </GridContainer>
                                                                            <GridContainer justifyContent="center" spacing={2}>
                                                                                <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                                  <label className="basicLabel">Location</label>
                                                                                  <p className="basic_p">Singapore</p>
                                                                                </GridItem>
                                                                            </GridContainer>
                                                                              </div>
                                                                            </div>

                                                                            <div className="basicInfo">
                                                                              <div className="basicInfoTitle">Questionnaire Answers</div>
                                                                              <div className="basicInfoBody">
                                                                              <GridContainer justifyContent="center" spacing={2}>
                                                                                <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                                  <label className="basicLabel">Have you done more than 3 UI projects in the past year?</label>
                                                                                  <p className="basic_p">Yes</p>
                                                                                </GridItem>
                                                                              </GridContainer>
                                                                              <GridContainer justifyContent="center" spacing={2}>
                                                                                <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                                  <label className="basicLabel">Do you have experience with UX animation in Figma?</label>
                                                                                  <p className="basic_p">No</p>
                                                                                </GridItem>
                                                                              </GridContainer>
                                                                            <GridContainer justifyContent="center" spacing={2}>
                                                                                <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                                  <label className="basicLabel">Are you familiar with Adobe Aftereffects or Premiere Pro?</label>
                                                                                  <p className="basic_p">Yes</p>
                                                                                </GridItem>
                                                                            </GridContainer>
                                                                            <GridContainer justifyContent="center" spacing={2}>
                                                                                <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                                  <label className="basicLabel">Have you worked with clients who generate more than $1 million sales?</label>
                                                                                  <p className="basic_p">No</p>
                                                                                </GridItem>
                                                                            </GridContainer>
                                                                              </div>
                                                                            </div>
                                                                            <Typography component='div' className='dayOuter btnOuter rejectShortlist'>
                                                                                <Typography component='div' className='dayRight'>
                                                                                <Button className='rejectBtn'>Reject</Button>
                                                                                </Typography>
                                                                                <Typography component='div' className='dayLeft'>
                                                                                <Button className='applyBtn'>Shortlist</Button>
                                                                                </Typography>
                                                                            </Typography>
                                                                        </TabPanel>
                                                                        <TabPanel value="2">
                                                                         <div className="basicInfoBody">
                                                                            <GridContainer justifyContent="center" spacing={2}>
																			
																			
																			
																			{user.educationRecords && user.educationRecords.map((itemedu, indexedu) => {
																				return(
                                                                                <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                                  <div className='eduTab'>
                                                                                  <div className='universityTitel'>{itemedu.school}</div>
                                                                                  <p>{itemedu.studyField}</p>
                                                                                  <p className="basic_p">{itemedu.courseName}</p>
                                                                                  <label className="basicLabel">{itemedu.startDate} - {itemedu.endDate} </label>
																					{itemedu.description}
                                                                                  </div>
                                                                                </GridItem>
																				)
																			}) 
																			}
                                                                            </GridContainer>
                                                                         </div>
                                                                        <Typography component='div' className='dayOuter btnOuter rejectShortlist'>
                                                                            <Typography component='div' className='dayRight'>
                                                                            <Button className='rejectBtn'>Reject</Button>
                                                                            </Typography>
                                                                            <Typography component='div' className='dayLeft'>
                                                                            <Button className='applyBtn'>Shortlist</Button>
                                                                            </Typography>
                                                                        </Typography>
                                                                        </TabPanel>
                                                                        <TabPanel value="3">
                                                                        <div className="basicInfoBody">
                                                                            <GridContainer justifyContent="center" spacing={2}>
																			
																			
																			{user.experienceRecords && user.experienceRecords.map((itemexp, indexexp) => {
																				return(
                                                                                <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                                  <div className='eduTab'>
                                                                                  <label className="basicLabel">
																				  {itemexp.isVisible == 0 ? (
																						'Not Visible to public'
																					) : (
																					' Visible to public'
																					)}
																				 
																				  
																				  </label>
                                                                                  <div className='universityTitel'>{itemexp.title}</div>
                                                                                  <p>{itemexp.organization}</p>
                                                                                  <label className="basicLabel">{itemexp.startDate} - {itemexp.endDate}</label>
                                                                                   <hr />
                                                                                 {itemexp.description}
                                                                                 
                                                                                  </div>
                                                                                
                                                                                </GridItem>
																			)
																			}) 
																			}	
                                                                            </GridContainer>
                                                                         </div>
                                                                        <Typography component='div' className='dayOuter btnOuter rejectShortlist'>
                                                                            <Typography component='div' className='dayRight'>
                                                                            <Button className='rejectBtn'>Reject</Button>
                                                                            </Typography>
                                                                            <Typography component='div' className='dayLeft'>
                                                                            <Button className='applyBtn'>Shortlist</Button>
                                                                            </Typography>
                                                                        </Typography>
                                                                        </TabPanel>
                                                                        <TabPanel value="4">
                                                                        <div className="basicInfoBody">
                                                                            <GridContainer justifyContent="center" spacing={2}>
                                                                                <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                                  <div className='eduTab'>
                                                                                  <p>      </p>
                                                                                  </div>
                                                                                </GridItem>
                                                                            </GridContainer>
                                                                         </div>
                                                                        <Typography component='div' className='dayOuter btnOuter rejectShortlist'>
                                                                            <Typography component='div' className='dayRight'>
                                                                            <Button className='rejectBtn'>Reject</Button>
                                                                            </Typography>
                                                                            <Typography component='div' className='dayLeft'>
                                                                            <Button className='applyBtn'>Shortlist</Button>
                                                                            </Typography>
                                                                        </Typography>
                                                                        </TabPanel>
                                                                        <TabPanel value="5">
                                                                        <div className="basicInfoBody">
                                                                            <GridContainer justifyContent="center" spacing={2}>
                                                                                <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                                  <div className='eduTab'>
                                                                                  <div className='universityTitel'>INTJ- The Analytical Mind</div>
                                                                                  <label className="basicLabel">INTJs are analytical problem-solvers, eager to improve systems and processes with their innovative ideas. They have a talent for seeing possibilities for improvement, whether at work, at home, or in themselves.</label>
                                                                                  </div>
                                                                                </GridItem>
                                                                            </GridContainer>
                                                                            <GridContainer justifyContent="center" spacing={2}>
                                                                                <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                                
                                                                                </GridItem>
                                                                            </GridContainer>
                                                                         </div>
                                                                        <Typography component='div' className='dayOuter btnOuter rejectShortlist'>
                                                                            <Typography component='div' className='dayRight'>
                                                                            <Button className='rejectBtn'>Reject</Button>
                                                                            </Typography>
                                                                            <Typography component='div' className='dayLeft'>
                                                                            <Button className='applyBtn'>Shortlist</Button>
                                                                            </Typography>
                                                                        </Typography>
                                                                        </TabPanel>
                                                                        <TabPanel value="6">
                                                                        <div className="basicInfoBody">
                                                                            <GridContainer justifyContent="center" spacing={2}>
                                                                                <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                                  <div className='eduTab'>
                                                                                  <p></p>
                                                                                  </div>
                                                                                </GridItem>
                                                                            </GridContainer>
                                                                         </div>
                                                                        <Typography component='div' className='dayOuter btnOuter rejectShortlist'>
                                                                            <Typography component='div' className='dayRight'>
                                                                            <Button className='rejectBtn'>Reject</Button>
                                                                            </Typography>
                                                                            <Typography component='div' className='dayLeft'>
                                                                            <Button className='applyBtn'>Shortlist</Button>
                                                                            </Typography>
                                                                        </Typography>
                                                                        </TabPanel>
                                                                    </TabContext>
                                                                    </Box>
                                                                </Box>
                                                             </Modal>
                                                            </Typography>
                                                         </Typography>
                                                         </Typography>
                                                         
                                                       
                                                        )
													   })
													   
													   }

                                                       
                                                    </div>
                                          
		
	);
};

export default HiredTab;
