import {
	container,
	cardTitle,
	title,
	mlAuto,
	mrAuto,
	main,
	whiteColor,
	mainRaised,
	grayColor,
	blackColor,
	hexToRgb,
	cardDetail,
	opptyDesignColor,
} from "assets/jss/material-kit-pro-react.js";

import imagesStyle from "assets/jss/material-kit-pro-react/imagesStyles.js";
import customSelectStyle from "assets/jss/material-kit-pro-react/customSelectStyle.js";
import customCheckboxRadioSwitchStyle from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.js";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.js";
import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.js";
import hashTagStyle from "../../componentStyle/hashTagStyle.js";

const corporateProfilePageStyle = (theme) => ({
	...imagesStyle,
	...customCheckboxRadioSwitchStyle,
	...modalStyle(theme),
	...customSelectStyle,
	...tooltipsStyle,
	...hashTagStyle,
	cardTitleWhite: {
		...cardTitle,

		color: whiteColor + "  !important",
	},
	cardDetail: {
		...cardDetail,
		whiteSpace: "pre-line",
	},

	container: {
		...container,
		"@media (min-width: 576px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 768px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 992px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 1200px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 1400px)": {
			maxWidth: "80vw",
		},
		// "@media (min-width: 576px)": {
		// 	maxWidth: "540px",
		// },
		// "@media (min-width: 768px)": {
		// 	maxWidth: "720px",
		// },
		// "@media (min-width: 992px)": {
		// 	maxWidth: "960px",
		// },
		// "@media (min-width: 1200px)": {
		// 	maxWidth: "90vw",
		// },
		// "@media (min-width: 1400px)": {
		// 	maxWidth: "90vw",
		// },
	},
	cardTitle,
	profile: {
		textAlign: "center",
		"& img": {
			width: "140px",
			height: "140px",
			/* height: "100%" */
			width: "100%",
			margin: "0 auto",
			transform: "translate3d(0, -70%, 0)", //"translate3d(0, -50%, 0)",
			cursor: "pointer",
			"&:hover,&:focus": {
				//color: whiteColor,
				//backgroundColor: grayColor[0],
				boxShadow:
					"0 14px 26px -12px rgba(" +
					hexToRgb(grayColor[0]) +
					", 0.42), 0 4px 23px 0px rgba(" +
					hexToRgb(blackColor) +
					", 0.12), 0 8px 10px -5px rgba(" +
					hexToRgb(grayColor[0]) +
					", 0.2)",
			},
			[theme.breakpoints.down("xs")]: {
				width: "auto",
				height: "120px",
			},
		},
	},
	cardFrame: {
		borderRadius: "18px",
		boxShadow: "none",
		[theme.breakpoints.down("xs")]: {
			marginTop: "10px",
			marginBottom: "10px",
		},
	},
	/* 	description: {
		margin: "1.071rem auto 0",
		maxWidth: "600px",
		color: grayColor[0],
	}, */
	// autoCompleteSelect: {
	// 	marginTop: "0 !important",
	// },
	textCenter: {
		textAlign: "center !important",
	},
	textValue: {
		color: "blackColor[0] !important",
		textDecoration: "none",
	},
	name: {
		marginTop: "-80px",
	},

	main: {
		...main,
	},
	mainRaised: {
		marginTop: "30vh",
		...mainRaised,
		background: "#F2F3F7",
		// [theme.breakpoints.down("sm")]: {
		// 	marginTop: "200px",
		// },
		margin: "0px !important",
		borderRadius: "0px 0px 6px 6px",
		boxShadow: "none",
	},
	title: {
		...title,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		position: "relative",
		whiteSpace: "pre-line",
		marginTop: "5px",
		marginBottom: "5px",
		margin: "5px 0px",
		minHeight: "0px",
		fontSize: "16px",
		textDecoration: "none",
		color: blackColor + "!important",
		"& svg": {
			width: "32px",
			height: "32px",
			marginRight: "20px",
			marginTop: "10px",
		},
		[theme.breakpoints.down("xs")]: {
			marginTop: "0!important",
			marginBottom: "5px!important",
			fontSize: "15px",
		},
	},
	textCenter: {
		textAlign: "center !important",
	},
	description: {
		margin: "0px 0px !important",
		/* maxWidth: "600px", */
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		color: blackColor[0],
		fontWeight: "500",
		whiteSpace: "pre-line",
		"& p": {
			margin: "15px 0px",
		},
		[theme.breakpoints.down("sm")]: {
			fontSize: "15px !important",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "11px !important",
			margin: "0!important",
		},
	},
	mediumDescription: {
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px!important",
			lineHeight: "1rem",
			margin: "0!important",
		},
	},
	smallDescription: {
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		color: blackColor[0],
		fontSize: "14px",
		fontWeight: "400",
	},
	follow: {
		position: "absolute",
		top: "0",
		right: "calc(24% + 50px)",
		"@media (min-width: 0px)": {
			right: "50px",
		},
		"@media (min-width: 576px)": {
			right: "calc(16% + 50px)",
		},
		"@media (min-width: 768px)": {
			right: "calc(24% + 50px)",
		},
		"@media (min-width: 960px)": {
			right: "calc(10% + 50px)",
		},
		"@media (min-width: 992px)": {
			right: "calc(18% + 50px)",
		},
		"@media (min-width: 1200px)": {
			right: "calc(24% + 50px)",
		},
	},
	edit: {
		position: "absolute",
		top: "-16px",
		right: "-20px",
		[theme.breakpoints.down("xs")]: {
			right: "-10px",
		},
	},
	followIcon: {
		width: "80px",
		height: "20px",
	},
	followButton: {
		marginTop: "-30px !important",
		marginRight: "100px !imporant",
	},
	gridItem: {
		...mlAuto,
		...mrAuto,
	},
	collections: {
		marginTop: "20px",
	},
	cardBody: {
		display: "flex",
		boxOrient: "vertical",
		boxDirection: "normal",
		flexDirection: "column",
		boxPack: "center",
		justifyContent: "center",
	},
	badge: {
		display: "inline-table",
		margin: "0 auto",
	},
	listUnstyled: {
		paddingLeft: "0",
		listStyle: "none",
		"& > li": {
			padding: "5px 0px",
			fontSize: "1em",
		},
	},
	profileTabs: {
		marginTop: "4.284rem",
		marginBottom: "50px",
	},
	card: {
		textAlign: "left !important",
	},
	custInput: {
		width: "100%",
		margin: "auto",
	},
	block: {
		color: "inherit",
		padding: "0.9375rem",
		fontWeight: "500",
		fontSize: "12px",
		textTransform: "uppercase",
		borderRadius: "3px",
		textDecoration: "none",
		position: "relative",
		display: "block",
	},
	updateButton: {
		paddingBottom: "20px !important",
	},
	cardWidth: {
		width: "550px",
	},
	infoArea: {
		padding: "10px 0 5px 0",
		"& h5": {
			textDecoration: "none",
			//marginTop: "30px !important",
			color: blackColor + "!important",
			fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
			fontWeight: "400",
			fontSize: "16px",
			textAlign: "left",
		},
	},
	tooltipDescription: {
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		color: blackColor[0],
		fontWeight: "500",
		whiteSpace: "pre-line",
		margin: "10px 0px !important",
	},
	iconWrapper: {
		float: "left",
		width: "2.25rem",
		height: "2.25rem",
		marginTop: "24px",
		marginRight: "10px",
		zIndex: "4",
	},

	inlineBlock: {
		display: "inline-block",
		padding: "0px",
		width: "auto",
	},
	list: {
		marginBottom: "0",
		padding: "0",
		marginTop: "0",
	},
	left: {
		float: "left!important",
		display: "block",
	},
	right: {
		padding: "15px 0",
		margin: "0",
		float: "right",
	},
	icon: {
		width: "18px",
		height: "18px",
		top: "3px",
		position: "relative",
	},
	parallax: {
		// height: "700px",
		// backgroundPosition: "top center",
		background: "#fff",
		height: "30vh", //"40vh",
		// margin: "90px 0px 0px 0px",
		marginTop: "90px",
		"@media (max-width: 830px)": {
			margin: "90px 10px 0px 10px",
		},
		backgroundColor: "#ffffff",
		[theme.breakpoints.down("xs")]: {
			height: "15vh",
		},
	},
	/* info: {
		paddingBottom: 0,
		paddingTop: 0,
		margin: "0",
		width: "auto",
	}, */
	fontStyle: {
		fontFamily: `"Roboto Slab", "Times New Roman", serif`,
		textDecoration: "none",
	},
	modalProfileEdit: {
		backgroundColor: "#f2f3f7",
		maxWidth: "1000px",
		width: "100%",
		"& $modalHeader": {
			paddingTop: "0",
		},
		"& $modalTitle": {
			width: "100%",
			marginTop: "0.625rem",
		},
		"& $modalBody": {
			// padding: "25px",
			paddingBottom: "0",
			paddingTop: "0",
		},
	},
	modalProfilePicEdit: {
		backgroundColor: "#f2f3f7",
		maxWidth: "1000px",
		width: "100%",
		"& $modalHeader": {
			paddingTop: "0",
		},
		"& $modalTitle": {
			width: "100%",
			marginTop: "0.625rem",
		},
		"& $modalBody": {
			paddingBottom: "0",
			paddingTop: "20px",
		},
	},
	modalTitle: {
		fontFamily: "Roboto, Helvetica, sans-serif",
		fontSize: "17px",
		fontWeight: "700",
		position: "relative",
		margin: "0px 0px 24px 0px !important",
	},
	modalTitleFlex: {
		[theme.breakpoints.down("xs")]: {
			display: "block",
			paddingBottom: "10px",
		},
		display: "flex",
		justifyContent: "space-between",
		"& > h4": {
			marginBottom: "10px!important",
		},
		"& > span": {
			whiteSpace: "nowrap",
			[theme.breakpoints.down("xs")]: {
				whiteSpace: "normal",
			},
		},
	},
	textCenter: {
		textAlign: "center",
	},
	gridItem: {
		...mlAuto,
		...mrAuto,
	},
	dialogPadding: {
		padding: "25px",
	},
	// editButton: {
	// 	padding: "40px 25px",
	// },
	editButton: {
		minWidth: "35px",
		width: "35px",
		height: "35px",
	},
	backGroundImage: {
		width: "100%",
		height: "100%",
	},
	hashTagContainer: {
		border: grayColor[0] + " solid 2px",
		borderRadius: "18px",
		padding: "15px",
	},
	cardOverviewType: {
		...cardDetail,
		color: blackColor + " !important",
	},
	info: {
		paddingBottom: 0,
		paddingTop: 0,
		margin: "0",
		width: "auto",
		"& svg": {
			color: "#000000",
		},
		"& h4": {
			marginLeft: "15px !important",
			/* margin: "15px 0px !important", */
			/* maxWidth: "600px", */
			fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
			color: blackColor,
			fontWeight: "600",
			whiteSpace: "pre-line",
		},
		"& a": {
			textDecoration: "none !important",
			color: blackColor,
			"&:hover,&:focus": {
				color: opptyDesignColor,
			},
		},
	},
	jobCountTitle: {
		fontSize: "40px",
		[theme.breakpoints.down("md")]: {
			textAlign: "center",
		},
	},
	jobCountGrid: {
		borderRadius: "18px",
		border: grayColor[0] + " solid 2px",
		maxWidth: "150px",
		[theme.breakpoints.down("md")]: {
			maxWidth: "100%",
			margin: "10px 0px",
		},
	},

	autoCompleteLabel: {
		"$ #MuiFormLabel-root": {
			fontSize: "12px !important",
		},
	},
	gridHeight: {
		height: "80px",
	},
	cardBorder: {
		boxShadow: "none!important",
		border: "2px solid #E6E7EC",
		borderRadius: "12px",
		color: "#9a9a9a",
	},
	autoHeightBody: {
		height: "auto",
		backgroundColor: "#F2F3F7",
		overflowY: "auto",
	},
	modalCardBorder: {
		borderRadius: "18px",
		boxShadow: "none",
		padding: "10px 0px 10px 0px",
		marginBottom: "0px",
	},
	modalBackground: {
		background: "#fff",
		"@media (max-height: 1280px)": {
			height: "calc(100vh - 250px)",
		},
	},
	titleAlign: {
		textAlign: "center",
	},
	profilePic: {
		margin: "auto",
		width: "140px",
		position: "relative",
	},
	grayFont: {
		color: "#99B2C6 !important",
	},
	grayFontCard: {
		color: "#9F9FB7 !important",
	},
	buttonColor: {
		/* margin: "13px 10px  0px 0px !important", */
		marginRight: "10px",
		padding: "0 !important",
		minHeight: "32px",
		color: opptyDesignColor,
		textAlign: "right",
		/* "&:hover,&:focus": {
			boxShadow:
				"0 14px 26px -12px rgba(" +
				hexToRgb(grayColor[0]) +
				", 0.42), 0 4px 23px 0px rgba(" +
				hexToRgb(blackColor) +
				", 0.12), 0 8px 10px -5px rgba(" +
				hexToRgb(grayColor[0]) +
				", 0.2)",
		}, */
		"& svg": {
			width: "32px",
			height: "32px",
		},
	},
	infoAreaStyle: {
		"& svg": {
			width: "25px !important",
			height: "25px !important",
			color: "#959595",
			marginRight: "15px",
		},
		display: "flex",
		alignItems: "center",
		fontWeight: "700",
		fontSize: "14px",
		margin: "15px 0px",
		"& a": {
			textDecoration: "none !important",
			color: blackColor,
			"&:hover,&:focus": {
				color: opptyDesignColor,
			},
		},
		[theme.breakpoints.down("sm")]: {
			margin: "10px 0px",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
			margin: "5px 0px",
			"& svg": {
				width: "20px !important",
				height: "20px !important",
			},
		},
	},
	infoAreaContainer: {
		padding: "10px",
		border: "#f2f3f7 solid 2px",
		borderRadius: "11px",
	},
	buttonIconMargin: {
		"& svg": {
			width: "28px !important",
			height: "28px !important",
			color: opptyDesignColor,
			border: "3px solid" + opptyDesignColor,
			padding: "1px",
			borderRadius: "5px",
			/* backgroundColor: "#000000", */
			[theme.breakpoints.down("xs")]: {
				width: "22px !important",
				height: "22px !important",
				border: "2px solid" + opptyDesignColor,
			},
		},
		display: "flex",
		alignItems: "center",

		float: "right",
		/* margin: "5px 5px !important", */
		/* 	border: "3px solid" + opptyDesignColor, */

		padding: "0px !important",
	},
	infoAreaContainer: {
		padding: "10px",
		border: "#f2f3f7 solid 2px",
		borderRadius: "11px",
	},
	userTitle: {
		fontSize: "24px",
		margin: "15px 0 10px 0",
		[theme.breakpoints.down("xs")]: {
			fontSize: "16px",
			margin: "0 0 5px 0",
		},
	},
	buttonHeight: {
		[theme.breakpoints.down("xs")]: {
			height: "30px",
		},
	},
	coverIcon: {
		position: "absolute",
		top: "-20px",
		right: "10px",
	},
	iconHide: {
		visibility: "hidden",
		opacity: "0",
		transition: "opacity 2s ease",
	},
	iconShow: {
		visibility: "visible",
		opacity: "1",
	},
});

export default corporateProfilePageStyle;
