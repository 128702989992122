import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SmallCardLoading from "../SmallCardLoading";
import { useSelector, useDispatch } from "react-redux";

import searchPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/SearchPage/searchPageStyle.js";

import PublicViewActivityCard from "views/Component/ProfileView/MyActivity/PublicViewActivityCard";
const useStyles = makeStyles(searchPageStyle);

export default function PostCard({ item }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const isLoading = useSelector((state) => state.searchPost.isLoading);

	const PostComponent = () => {
		return (
			<div key={item.postId}>
				<PublicViewActivityCard
					post={item}
					hideReactionBar={false}
					searchPost={false}
					isCopyPost={false}
				/>
			</div>
		);
	};
	return <div>{isLoading ? <SmallCardLoading /> : <PostComponent />}</div>;
}
