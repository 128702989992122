const popoverStyle = (theme) => ({
  container: {
    backgroundColor: "white",
    width: "220px",
    borderRadius: "10px",
    padding: "17px",
  },
  optContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
  txt1: {
    color: "#44444F",
    fontSize: "14px",
    fontWeight: "normal",
    marginLeft: "13px",
  },
  optContainer2: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "10px",
  },
  txt2: {
    color: "#44444F",
    fontSize: "14px",
    fontWeight: "normal",
    marginLeft: "16px",
  },
  txt3: {
    color: "#44444F",
    fontSize: 14,
    fontWeight: "normal",
    marginLeft: 17,
    marginBottom: 5,
  },
});

export default popoverStyle;
