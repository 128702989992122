import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { departmentViewRoadmap, viewRoadmapRoleByRoleId } from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";
import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Department_Valuation from "assets/img/Career_Roadmap/Real_Estate/Department_Valuation_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentValuations() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Valuation;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "v",
				shape: "poly",
				coords: [81,378,75,380,71,383,67,386,64,390,62,398,62,437,65,444,68,448,72,452,78,454,171,454,179,452,185,447,189,443,190,437,191,398,190,393,188,389,184,384,179,381,174,379,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "144",
				shape: "poly",
				coords: [226,374,215,375,210,378,206,380,203,386,202,392,202,444,204,450,208,454,214,457,219,459,391,459,400,457,404,455,407,452,410,446,410,440,410,389,408,383,405,381,400,377,394,376,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "145",
				shape: "poly",
				coords: [570,375,565,378,562,381,559,384,558,390,558,443,560,450,563,454,567,457,575,459,751,458,757,456,760,453,764,449,765,443,765,386,762,381,759,379,754,376,749,375,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "146",
				shape: "poly",
				coords: [932,375,924,377,919,378,915,383,913,390,913,445,915,451,920,456,927,458,1101,458,1111,456,1115,454,1117,451,1119,446,1120,440,1120,388,1119,383,1115,379,1110,377,1104,376,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "147",
				shape: "poly",
				coords: [1285,375,1277,378,1274,380,1271,384,1268,391,1268,439,1269,447,1271,453,1276,456,1285,459,1456,459,1464,456,1470,452,1473,449,1475,443,1476,391,1474,385,1471,380,1467,377,1461,375,1456,375,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "148",
				shape: "poly",
				coords: [1649,376,1638,377,1633,380,1630,384,1628,392,1629,445,1631,451,1637,456,1646,458,1820,458,1829,456,1831,453,1834,451,1836,441,1836,394,1836,387,1832,382,1829,379,1825,376,1820,375,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "v") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo)
			dispatch(viewRoadmapRoleByRoleId(requestInfo));
			
			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// console.log(coords.x + "," + coords.y + ",");
		// navigator.clipboard.writeText(coords.x + "," + coords.y + ",");
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
