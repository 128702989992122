import React from "react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import searchPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/SearchPage/searchPageStyle.js";

import { makeStyles } from "@material-ui/core/styles";
import NoData from "assets/img/No data-rafiki.png";
const useStyles = makeStyles(searchPageStyle);

export default function NoResultCard({ status }) {
	const classes = useStyles();
	return (
		<Card className={classes.cardFrame}>
			<CardBody
				className={classes.cardBody}
				style={{ display: "flex", flexDirection: "column" }}
			>
				<div
					style={{
						width: "100%",
						flexGrow: 1,
						flexDirection: "column",
						display: "flex",
					}}
				>
					<h4 className={classes.noResultTitle}>{status}</h4>
				</div>

				<div
					style={{
						width: "100%",
						flexGrow: 2,
						display: "flex",
						justifyContent: "center",
					}}
				>
					<img
						src={NoData}
						alt="Expired Session"
						style={{ width: "50%", height: "50%" }}
					/>
				</div>
			</CardBody>
		</Card>
	);
}
