import React from 'react';
import profilePageStyle from 'assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js';
import { isDesktop } from 'react-device-detect';
import IconLibrary from 'views/Component/IconLibrary.js';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import {
  getCountryId,
  getLocationId,
  getLocationIdByCountry,
  getDepartmentIdByIndustry,
  getStartYearData,
  getEndYearData,
  getMonthData,
} from 'configurations/configurations.js';
import { experienceUpdateSchema } from 'configurations/configurations.js';
import { updateExperienceProfile, editExperienceProfile } from 'configurations/redux/userSlice.js';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import Check from '@material-ui/icons/Check';
import { getIndustryId } from 'configurations/configurations';
import Switch from '@material-ui/core/Switch';
import { Typography, Button, Modal } from '@material-ui/core';
import { activateEditType } from 'configurations/redux/editProfileModalSlice';
import { useModal } from 'hooks';
import ConfirmDialog from 'components/CustomDialog/ConfirmDialog.js';
import { deactivateEditType } from 'configurations/redux/editProfileModalSlice';

const useStyles = makeStyles(profilePageStyle);
const filter = createFilterOptions();

export default function EditExperience({
  expRecId,
  expRecord,
  saveDetailModalProps,
  onCancelChangesConfirm,
  setIsChanged,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);

  const experienceRecords = useSelector(state => state.user.experienceRecords);
  const [countryList, setCountryList] = React.useState([]);
  const [locationList, setLocationList] = React.useState([]);
  const [industryList, setIndustryList] = React.useState([]);
  const [currentStatus, setCurrentStatus] = React.useState([0, 1]);
  const [countryChange, setCountryChange] = React.useState();
  const [industryChange, setIndustryChange] = React.useState();
  const [departmentList, setDepartmentList] = React.useState([]);
  const [buttonVisible, setButtonVisible] = React.useState(false);

  const startYearDataList = getStartYearData();
  const endYearDataList = getEndYearData();
  const monthDataList = getMonthData();

  const [isCancelDialogOpen, setIsCancelDialogOpen] = React.useState(false);
  const isMainModalCloseRequest = useSelector(state => state.editProfileModal.isMainModalCloseRequest);

  const fetchCountryList = async () => {
    const res = await getCountryId();
    setCountryList(res.data.payload);
  };
  const fetchLocationList = async () => {
    const res = await getLocationId();
    setLocationList(res.data.payload);
  };
  const fetchIndustryList = async () => {
    const res = await getIndustryId();
    setIndustryList(res.data.payload);
  };
  React.useEffect(() => {
    fetchCountryList();
    // fetchLocationList();
    fetchIndustryList();
  }, []);

  React.useEffect(() => {
    if (countryChange) {
      const fetchLocationListByCountry = async countryId => {
        const res = await getLocationIdByCountry(countryId);
        setLocationList(res.data.payload);
      };
      fetchLocationListByCountry(countryChange);
    }
  }, [countryChange]);
  React.useEffect(() => {
    if (industryChange) {
      const fetchDepartmentByIndustry = async industryId => {
        const res = await getDepartmentIdByIndustry(industryId);
        setDepartmentList(res.data.payload);
      };
      fetchDepartmentByIndustry(industryChange);
    }
  }, [industryChange]);
  React.useEffect(() => {
    setButtonVisible(false);
  }, [expRecId]);

  const modalResize = () => {
    // var timer = setInterval(() => {
    var modalResize = document.getElementById('modalCard');
    if (modalResize) {
      // clearInterval(timer);
      var maxSize = window.innerHeight - 74 - 80 - 70;
      if (maxSize < 0) maxSize = 0;
      modalResize.style.height = maxSize * 0.8 + 'px';
    }
  };
  const cancelForm = {
    title: 'Discard Action',
    text: 'All the actions will be discarded',
    cancelText: 'No',
    confirmText: 'Yes',
  };

  /* React.useEffect(() => {
		if (open) {
			if (document.readyState === "complete") {
				if (expRecord) setCountryChange(expRecord.countryId);
				setTimeout(() => {
					modalResize();
				}, 500);
			}
			window.addEventListener("resize", modalResize);
			return function cleanup() {
				window.removeEventListener("resize", modalResize);
			};
		}
	}, [open]); */
  console.log(saveDetailModalProps);
  return (
    <div key={expRecId}>
      <Formik
        enableReinitialize
        key={expRecord}
        initialValues={{
          title: expRecord ? expRecord.title : '',
          organization: expRecord ? expRecord.organization : '',
          countryId: expRecord ? expRecord.countryId : '',
          locationId: expRecord ? expRecord.locationId : '',
          startYear: expRecord ? expRecord.startDate.substring(0, 4) : '',
          startMonth: expRecord ? expRecord.startDate.substring(5, 7) : '',
          endYear: expRecord ? (expRecord.currentInProgressFlag === 1 ? '0' : expRecord.endDate.substring(0, 4)) : '0',
          endMonth: expRecord ? (expRecord.currentInProgressFlag === 1 ? '0' : expRecord.endDate.substring(5, 7)) : '0',

          currentInProgressFlag: expRecord ? expRecord.currentInProgressFlag : 0,
          isVisible: expRecord ? expRecord.isVisible : 1,
          achievement: expRecord ? expRecord.achievement : '',
          description: expRecord ? expRecord.description : '',
          industryId: expRecord ? expRecord.industryId : '',
          customIndustryName: expRecord ? expRecord.customIndustryName : '',
          departmentId: expRecord ? expRecord.departmentId : '',
          customDepartmentName: expRecord ? expRecord.customDepartmentName : '',
          experienceAutoEnd: experienceRecords
            .filter(item => {
              return item.expRecId !== expRecId;
            })
            .filter(item => {
              return item.currentInProgressFlag !== 0;
            })
            .map(item => {
              const record = { recId: item.expRecId, value: true };
              return record;
            }),
          /* requestNotifyRecord: expRecord.requestNotifyRecord
						? expRecord.requestNotifyRecord
						: false, */
          requestNotifyRecord:
            expRecId === -1 ? true : expRecord.requestNotifyRecord ? expRecord.requestNotifyRecord : false,
        }}
        validationSchema={experienceUpdateSchema}
        onSubmit={(data, { resetForm }) => {
          const endDate = data.currentInProgressFlag === 1 ? null : data.endYear + '-' + data.endMonth + '-01';

          const requestData = {
            email: user.email,
            acctId: user.acctId,
            expRecId: expRecId,
            title: data.title.trim(),
            organization: data.organization.trim(),
            countryId: data.countryId,
            locationId: data.locationId,
            startDate: data.startYear + '-' + data.startMonth + '-01',
            endDate: endDate,
            currentInProgressFlag: data.currentInProgressFlag,
            achievement: data.achievement.trim(),
            description: data.description.trim(),
            accessToken: localStorage.getItem('token'),
            industryId: data.industryId,
            departmentId: data.departmentId,
            customDepartmentName: data.customDepartmentName,
            customIndustryName: data.customIndustryName,
            experienceAutoEnd: data.experienceAutoEnd,
            requestNotifyRecord: data.requestNotifyRecord,
            isVisible: data.isVisible,
          };
          //console.log(requestData);
          {
            expRecId === -1
              ? dispatch(updateExperienceProfile(requestData))
              : dispatch(editExperienceProfile(requestData));
          }
          resetForm();

          if (data) {
            const response = {
              type: 'EDIT_PROFILE',
              tabType: 'EXPERIENCE',
              isTabShow: false,
            };

            dispatch(activateEditType(response));
            setIsChanged(false);
          }
        }}
      >
        {({ handleChange, values, errors, touched, handleBlur, handleSubmit, setFieldValue, resetForm }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <Card className={classes.modalCardBorder} style={{ marginTop: '10px' }}>
                <CardBody id="modalCard">
                  <GridContainer>
                    <GridItem className={classes.buttonHeader + ' ' + classes.gridItem}>
                      <Typography
                        component="h2"
                        className={classes.tabTypeTitleSection}
                        /* sx={{ margin: "0 !important" }} */
                      >
                        Experience
                      </Typography>

                      <Typography
                        component="div"
                        style={{
                          visibility: buttonVisible ? 'visible' : 'hidden',
                        }}
                      >
                        <Button className={classes.saveProfileInformationBtn} onClick={handleSubmit}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 24 18">
                            <path
                              id="Path"
                              d="M7.636,14.239,1.909,8.6,0,10.478,7.636,18,24,1.881,22.091,0Z"
                              fill="#0ab98d"
                            />
                          </svg>
                          <span>SAVE</span>
                        </Button>
                        <Button
                          className={classes.cancelEditBtn}
                          onClick={() => {
                            saveDetailModalProps.setModalOpen(true);
                          }}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                            <path
                              id="Path"
                              d="M14,1.41,12.59,0,7,5.59,1.41,0,0,1.41,5.59,7,0,12.59,1.41,14,7,8.41,12.59,14,14,12.59,8.41,7Z"
                              fill="#4a4a4a"
                            />
                          </svg>
                          <span>CANCEL</span>
                        </Button>
                      </Typography>
                    </GridItem>

                    <GridItem>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.isVisible === 1 ? true : false}
                            onChange={(event, value) => {
                              if (value) {
                                setFieldValue('isVisible', 1);
                              } else {
                                setFieldValue('isVisible', 0);
                              }
                              setButtonVisible(true);
                              setIsChanged(true);
                            }}
                            //value="checkedA"
                            name="isVisible"
                            classes={{
                              root: classes.switchRoot,
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar,
                            }}
                          />
                        }
                        label={
                          <span style={{ color: '#000' }} className={classes.checkBoxLabel}>
                            Visible to Public
                          </span>
                        }
                      />
                    </GridItem>

                    {expRecId === -1 && (
                      <GridItem style={{ marginBottom: '10px' }}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={values.requestNotifyRecord}
                              onChange={(event, value) => {
                                if (value) {
                                  setFieldValue('requestNotifyRecord', true);
                                } else {
                                  setFieldValue('requestNotifyRecord', false);
                                }
                                setButtonVisible(true);
                                setIsChanged(true);
                              }}
                              //value="checkedA"
                              name="notifyRecord"
                              classes={{
                                root: classes.switchRoot,
                                switchBase: classes.switchBase,
                                checked: classes.switchChecked,
                                thumb: classes.switchIcon,
                                track: classes.switchBar,
                              }}
                            />
                          }
                          label={
                            <React.Fragment>
                              <div style={{ display: 'block !important' }}>
                                <span
                                  style={{
                                    color: '#000',
                                    marginBottom: '0px !important',
                                  }}
                                  className={classes.checkBoxLabel}
                                >
                                  Notify your Connections
                                </span>
                              </div>
                            </React.Fragment>
                          }
                        />
                        <div>
                          <span
                            style={{
                              color: '#000',
                              fontSize: '14px',
                              display: 'block !important',
                            }}
                          >
                            Inform your Connections whenever you update your social feed.
                          </span>
                        </div>
                      </GridItem>
                    )}
                    <GridItem className={classes.gridHeight}>
                      <TextField
                        variant="outlined"
                        id="title"
                        label="Title"
                        name="title"
                        value={values.title}
                        helperText={touched.title ? errors.title : ''}
                        error={touched.title && Boolean(errors.title)}
                        onChange={event => {
                          handleChange(event);
                          setButtonVisible(true);
                          setIsChanged(true);
                        }}
                        onBlur={handleBlur}
                        fullWidth
                        InputProps={{
                          autoComplete: 'off',
                        }}
                      />
                    </GridItem>
                    <GridItem className={classes.gridHeight}>
                      <TextField
                        variant="outlined"
                        id="organization"
                        label="Organization"
                        name="organization"
                        value={values.organization}
                        helperText={touched.organization ? errors.organization : ''}
                        error={touched.organization && Boolean(errors.organization)}
                        onChange={event => {
                          handleChange(event);
                          setButtonVisible(true);
                          setIsChanged(true);
                        }}
                        onBlur={handleBlur}
                        fullWidth
                        InputProps={{
                          autoComplete: 'off',
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} className={classes.gridHeight}>
                      <Autocomplete
                        options={countryList}
                        onBlur={handleBlur}
                        freeSolo
                        value={
                          countryList.filter(item => {
                            return item.countryId === values.countryId;
                          })[0] || ''
                        }
                        getOptionSelected={(option, value) => {
                          if (!value) {
                            return false;
                          }
                          return option.countryId === value.countryId;
                        }}
                        getOptionLabel={option => (option.countryName ? option.countryName : '')}
                        filterOptions={createFilterOptions({
                          matchFrom: 'start',
                          stringify: option => option.countryName,
                        })}
                        onChange={(event, value) => {
                          if (value) {
                            setFieldValue('countryId', value.countryId);
                            setCountryChange(value.countryId);
                          } else {
                            setFieldValue('countryId', '');
                            setFieldValue('locationId', '');
                          }
                          setButtonVisible(true);
                          setIsChanged(true);
                        }}
                        id="countryId"
                        name="countryId"
                        renderInput={params => (
                          <TextField
                            {...params}
                            label={'Country'}
                            variant="outlined"
                            helperText={touched.countryId ? errors.countryId : ''}
                            error={touched.countryId && Boolean(errors.countryId)}
                          />
                        )}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} className={classes.gridHeight}>
                      <React.Fragment>
                        <Autocomplete
                          options={locationList}
                          disabled={!values.countryId}
                          onBlur={handleBlur}
                          // freeSolo
                          value={
                            locationList.filter(item => {
                              return item.locationId === values.locationId;
                            })[0] || ''
                          }
                          getOptionSelected={(option, value) => option === value}
                          getOptionLabel={option => (option.locationName ? option.locationName : '')}
                          onChange={(event, value) => {
                            if (value) {
                              setFieldValue('locationId', value.locationId);
                            } else {
                              setFieldValue('locationId', '');
                            }
                            setButtonVisible(true);
                            setIsChanged(true);
                          }}
                          id="locationId"
                          name="locationId"
                          renderInput={params => (
                            <TextField
                              {...params}
                              label={'Location'}
                              variant="outlined"
                              helperText={touched.locationId ? errors.locationId : ''}
                              error={touched.locationId && Boolean(errors.locationId)}
                            />
                          )}
                        />
                      </React.Fragment>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} className={classes.gridHeight}>
                      <React.Fragment>
                        <Autocomplete
                          options={industryList}
                          name="industryId"
                          id="industryId"
                          getOptionLabel={option => (option.industryName ? option.industryName : option)}
                          freeSolo
                          /* filterOptions={(option) => option.schoolName} */
                          value={
                            industryList.filter(item => {
                              return item.industryId === values.industryId;
                            })[0] || values.customIndustryName
                            /* 	? values.school
																	: null */
                          }
                          onChange={(event, value) => {
                            /* console.log(event.target.value); */
                            if (industryList.includes(value)) {
                              setFieldValue('industryId', value.industryId);
                              setFieldValue('customIndustryName', '');
                            } else if (value) {
                              if (value.inputValue) {
                                setFieldValue('customIndustryName', value.inputValue);
                                setFieldValue('industryId', 0);
                              } else {
                                setFieldValue('customIndustryName', value);
                                setFieldValue('industryId', 0);
                              }
                            }
                            setButtonVisible(true);
                            setIsChanged(true);
                          }}
                          onBlur={(event, value) => {
                            const index = industryList.findIndex(item => item.industryName === event.target.value);
                            if (index === -1) {
                              setFieldValue('customIndustryName', event.target.value);
                              setFieldValue('industryId', 0);
                            }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            return filtered;
                          }}
                          renderInput={params => <TextField {...params} label={'Industry'} variant="outlined" />}
                        />
                      </React.Fragment>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} className={classes.gridHeight}>
                      <React.Fragment>
                        <Autocomplete
                          options={departmentList}
                          name="departmentId"
                          id="departmentId"
                          getOptionLabel={option => (option.departmentName ? option.departmentName : option)}
                          freeSolo
                          /* filterOptions={(option) => option.schoolName} */
                          value={
                            departmentList.filter(item => {
                              return item.departmentId === values.departmentId;
                            })[0] || values.customDepartmentName
                            /* 	? values.school
																	: null */
                          }
                          onChange={(event, value) => {
                            /* console.log(event.target.value); */
                            if (departmentList.includes(value)) {
                              setFieldValue('departmentId', value.departmentId);
                              setFieldValue('customDepartmentName', '');
                            } else if (value) {
                              if (value.inputValue) {
                                setFieldValue('customDepartmentName', value.inputValue);
                                setFieldValue('departmentId', 0);
                              } else {
                                setFieldValue('customDepartmentName', value);
                                setFieldValue('departmentId', 0);
                              }
                            }
                            setButtonVisible(true);
                            setIsChanged(true);
                          }}
                          onBlur={(event, value) => {
                            const index = departmentList.findIndex(item => item.departmentName === event.target.value);
                            if (index === -1) {
                              setFieldValue('customDepartmentName', event.target.value);
                              setFieldValue('departmentId', 0);
                            }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            return filtered;
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label={'Department'}
                              variant="outlined"
                              /* helperText={
																		values.schoolId != -1
																			? touched.schoolId
																				? errors.schoolId
																				: ""
																			: touched.school
																			? errors.school
																			: ""
																	}
																	error={
																		values.schoolId != -1
																			? touched.schoolId &&
																			  Boolean(errors.schoolId)
																			: touched.school && Boolean(errors.school)
																	} */
                            />
                          )}
                        />
                      </React.Fragment>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <h4 className={classes.modalTitle + ' ' + classes.grayFont}>Starting From</h4>
                    </GridItem>
                    <Hidden smDown>
                      <GridItem xs={12} sm={12} md={6}>
                        {values.currentInProgressFlag === 0 ? (
                          <h4 className={classes.modalTitle + ' ' + classes.grayFont}>Ending In</h4>
                        ) : null}
                      </GridItem>
                    </Hidden>
                    <GridItem xs={6} sm={6} md={3} className={classes.gridHeight}>
                      <React.Fragment>
                        <Autocomplete
                          options={monthDataList}
                          onBlur={handleBlur}
                          // freeSolo
                          value={
                            monthDataList.filter(item => {
                              return item.monthId === values.startMonth;
                            })[0] || null
                          }
                          getOptionLabel={option => (option.monthName ? option.monthName : '')}
                          getOptionSelected={(option, value) => option.monthName === value.monthName}
                          onChange={(event, value) => {
                            console.log(value);
                            if (value) {
                              setFieldValue('startMonth', value.monthId);
                            } else {
                              setFieldValue('startMonth', '0');
                            }
                            setButtonVisible(true);
                            setIsChanged(true);
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label={'Month'}
                              variant="outlined"
                              helperText={touched.startMonth ? errors.startMonth : ''}
                              error={touched.startMonth && Boolean(errors.startMonth)}
                            />
                          )}
                        />
                      </React.Fragment>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={3} className={classes.gridHeight}>
                      <React.Fragment>
                        <Autocomplete
                          options={startYearDataList}
                          onBlur={handleBlur}
                          // freeSolo
                          value={
                            startYearDataList.filter(item => {
                              return item === values.startYear;
                            })[0] || null
                          }
                          getOptionSelected={(option, value) => option === value}
                          getOptionLabel={option => option}
                          onChange={(event, value) => {
                            if (value) {
                              setFieldValue('startYear', value);
                            } else {
                              setFieldValue('startYear', '0');
                              setFieldValue('startMonth', '0');
                            }
                            setButtonVisible(true);
                            setIsChanged(true);
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label={'Year'}
                              variant="outlined"
                              helperText={touched.startYear ? errors.startYear : ''}
                              error={touched.startYear && Boolean(errors.startYear)}
                            />
                          )}
                        />
                      </React.Fragment>
                    </GridItem>
                    {values.currentInProgressFlag === 0 ? (
                      <Hidden mdUp>
                        <GridItem xs={12} sm={12} md={12}>
                          <h4 className={classes.modalTitle + ' ' + classes.grayFont}>Ending In</h4>
                        </GridItem>
                      </Hidden>
                    ) : null}
                    {values.currentInProgressFlag === 0 ? (
                      <React.Fragment>
                        <GridItem xs={6} sm={6} md={3} className={classes.gridHeight}>
                          <React.Fragment>
                            <Autocomplete
                              options={monthDataList}
                              onBlur={handleBlur}
                              // freeSolo
                              value={
                                monthDataList.filter(item => {
                                  return item.monthId === values.endMonth;
                                })[0] || null
                              }
                              getOptionLabel={option => (option.monthName ? option.monthName : '')}
                              getOptionSelected={(option, value) => option.monthName === value.monthName}
                              onChange={(event, value) => {
                                console.log(value);
                                if (value) {
                                  setFieldValue('endMonth', value.monthId);
                                } else {
                                  setFieldValue('endMonth', '0');
                                }
                                setButtonVisible(true);
                                setIsChanged(true);
                              }}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  label={'Month'}
                                  variant="outlined"
                                  helperText={touched.endMonth ? errors.endMonth : ''}
                                  error={touched.endMonth && Boolean(errors.endMonth)}
                                />
                              )}
                            />
                          </React.Fragment>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} className={classes.gridHeight}>
                          <React.Fragment>
                            <Autocomplete
                              options={endYearDataList}
                              onBlur={handleBlur}
                              value={
                                endYearDataList.filter(item => {
                                  return item === values.endYear;
                                })[0] || null
                              }
                              getOptionSelected={(option, value) => option === value}
                              getOptionLabel={option => option}
                              onChange={(event, value) => {
                                // console.log(value)
                                if (value) {
                                  setFieldValue('endYear', value);
                                } else {
                                  setFieldValue('endYear', '0');
                                  setFieldValue('endMonth', '0');
                                }
                                setButtonVisible(true);
                                setIsChanged(true);
                              }}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  label={'Year'}
                                  variant="outlined"
                                  helperText={touched.endYear ? errors.endYear : ''}
                                  error={touched.endYear && Boolean(errors.endYear)}
                                />
                              )}
                            />
                          </React.Fragment>
                        </GridItem>
                      </React.Fragment>
                    ) : null}
                    <GridItem>
                      <FormControlLabel
                        classes={{
                          label: classes.label,
                        }}
                        control={
                          <Checkbox
                            //tabIndex={-1}
                            onChange={(event, value) => {
                              if (value) {
                                setFieldValue('currentInProgressFlag', 1);
                              } else {
                                setFieldValue('currentInProgressFlag', 0);
                              }
                              setButtonVisible(true);
                              setIsChanged(true);
                            }}
                            name="currentInProgressFlag"
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                            checked={values.currentInProgressFlag === 1 ? true : false}
                          />
                        }
                        label={
                          <span className={classes.checkBoxLabel + ' ' + classes.grayFont}>
                            I am currently working in this organization
                          </span>
                        }
                      />
                    </GridItem>
                    <GridItem>
                      {experienceRecords &&
                        values.experienceAutoEnd.length > 0 &&
                        experienceRecords
                          .filter(item => {
                            return item.expRecId !== expRecId;
                          })
                          .filter(item => {
                            return item.currentInProgressFlag !== 0;
                          })
                          .map(item => {
                            return (
                              <div key={item.expRecId}>
                                <FormControlLabel
                                  key={item.recId}
                                  control={
                                    <Switch
                                      checked={
                                        values.experienceAutoEnd.filter(list => {
                                          return list.recId === item.expRecId;
                                        })[0].value
                                      }
                                      onChange={(event, value) => {
                                        const autoEndArray = values.experienceAutoEnd.filter(list => {
                                          if (list.recId === item.expRecId) {
                                            list.value = list.value === true ? false : true;
                                          }
                                          return list;
                                        });
                                        setFieldValue('experienceAutoEnd', autoEndArray);
                                        setButtonVisible(true);
                                        setIsChanged(true);
                                      }}
                                      classes={{
                                        root: classes.switchRoot,
                                        switchBase: classes.switchBase,
                                        checked: classes.switchChecked,
                                        thumb: classes.switchIcon,
                                        track: classes.switchBar,
                                      }}
                                    />
                                  }
                                  label={
                                    <span style={{ color: '#000' }} className={classes.checkBoxLabel}>
                                      {`Would you like to set the end date of "` + item.title + `" to Today?`}
                                    </span>
                                  }
                                />
                              </div>
                            );
                          })}
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12} className={classes.gridPadding}>
                      <TextField
                        variant="outlined"
                        id="achievement"
                        label="Achievement"
                        name="achievement"
                        value={values.achievement}
                        helperText={touched.achievement ? errors.achievement : ''}
                        error={touched.achievement && Boolean(errors.achievement)}
                        onChange={event => {
                          handleChange(event);
                          setButtonVisible(true);
                          setIsChanged(true);
                        }}
                        onBlur={handleBlur}
                        fullWidth
                        InputProps={{
                          multiline: true,
                          rows: 5,
                          // defaultValue: values.achievement,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} className={classes.gridPadding}>
                      <TextField
                        variant="outlined"
                        id="description"
                        label="Description"
                        name="description"
                        value={values.description}
                        helperText={touched.description ? errors.description : ''}
                        error={touched.description && Boolean(errors.description)}
                        onChange={event => {
                          handleChange(event);
                          setButtonVisible(true);
                          setIsChanged(true);
                        }}
                        onBlur={handleBlur}
                        fullWidth
                        InputProps={{
                          multiline: true,
                          rows: 5,
                          // defaultValue: values.description,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </div>
            <ConfirmDialog
              {...{ ...saveDetailModalProps }}
              bodyContent={cancelForm}
              modalOpen={buttonVisible && saveDetailModalProps.modalOpen}
              handleSubmit={() => {
                resetForm();
                setButtonVisible(false);
                if (isMainModalCloseRequest) {
                  setIsChanged(false);
                  dispatch(deactivateEditType());
                  saveDetailModalProps.setModalOpen(false);
                } else {
                  onCancelChangesConfirm();
                }
              }}
            />
          </form>
        )}
      </Formik>
    </div>
  );
}
