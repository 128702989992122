import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

import Typography from "@material-ui/core/Typography";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";

// import searchJobPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/SearchJobPage/searchJobPageStyle.js";
import notificationPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/NotificationPage/notificationPageStyle.js";
import FiberManualRecordSharp from "@material-ui/icons/FiberManualRecordSharp";
import { Link } from "react-router-dom";
import { getJobPosts } from "configurations/redux/jobManagementSlice.js";
import { getAppliedJobPostList } from "configurations/redux/appliedJobSlice";
import { updateNotification } from "configurations/redux/eventNotificationSlice.js";
import { TimeFormat } from "configurations/configurations";

import { encryptData } from "configurations/configurations.js";

const useStyles = makeStyles(notificationPageStyle);
export default function ListResultCard({ item }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const userStatus = useSelector((state) => state.auth.user);
	const [clientIp, setClientIp] = React.useState();
	const jobPostList = useSelector((state) => state.jobManagement.jobPostList);
	const appliedJobPostList = useSelector(
		(state) => state.appliedJob.jobPostList
	);

	const requestProfile = {
		acctId: userStatus.acctId,
		email: userStatus.email,
		accessToken: localStorage.getItem("token"),
		ipAddr: clientIp,
		lastRecordSize: 0,
		requestRecordSize: 1,
		paginationClick: false,
		statusId: -1,
	};
	const applyJobRequest = {
		acctId: userStatus.acctId,
		email: userStatus.email,
		accessToken: localStorage.getItem("token"),
		lastRecordSize: 0,
		requestRecordSize: 1,
		paginationClick: false,
	};
	const requestNotificationStatusUpdate = {
		acctId: userStatus.acctId,
		email: userStatus.email,
		accessToken: localStorage.getItem("token"),
		ipAddr: clientIp,
		notificationListId: item.notificationListId,
		eventListId: item.eventListId,
		notificationStatus: "read",
		item: item,
	};
	const getNotificationNavigate = (item) => {
		if (item.eventTypeId == 1) {
			if (!jobPostList.length > 0) {
				dispatch(getJobPosts(requestProfile));
			}
		} else if (item.eventListId == 4) {
			if (!appliedJobPostList.length > 0) {
				dispatch(getAppliedJobPostList(applyJobRequest));
			}
		}
		console.log(requestNotificationStatusUpdate);
		dispatch(updateNotification(requestNotificationStatusUpdate));
	};

	var linkTarget = {};
	console.log(item);
	if (item.eventTypeId == 1 || item.eventTypeId == 3) {
		linkTarget = {
			pathname: `/corporate-job-view-page/${encryptData(item.jobPostId)}`,
			// state: {
			// 	jobPostId: item.jobPostId,
			// },
		};
	} else if (item.eventTypeId == 4) {
		linkTarget = {
			pathname: "/job-status-page",
		};
	} else if (item.eventTypeId == 11 || item.eventTypeId == 12) {
		linkTarget = {
			pathname: `/manage-connection/${encryptData(userStatus.acctId)}`,
		};
	} else if (item.eventTypeId >= 13 && item.eventTypeId <= 26) {
		if (item.groupId && item.groupId > 0) {
			linkTarget = {
				pathname: `/manage-group/${encryptData(item.groupId)}/${encryptData(
					item.postId
				)}`,
			};
		} else {
			linkTarget = {
				pathname: `/social-feed/2/${encryptData(item.postId)}`,
			};
		}
	} else if (item.eventTypeId >= 28 && item.eventTypeId <= 33) {
		linkTarget = {
			pathname: `/testimonials/${encryptData(userStatus.acctId)}`,
		};
	}

	var contentClass = null;
	if (item.statusName == "read") contentClass = classes.grayFont;

	return (
		<React.Fragment>
			<div>
				<List className={classes.root}>
					<Link to={linkTarget}>
						<ListItem
							alignItems="flex-start"
							button
							key={item.notificationListId}
							onClick={() => {
								getNotificationNavigate(item);
							}}
						>
							<ListItemText
								primary={
									<Typography color="textPrimary">
										<b className={contentClass}>
											{item.title}

											{item.statusName == "unread" ? (
												<FiberManualRecordSharp
													color="error"
													className={classes.unreadIcon}
												/>
											) : null}
										</b>
										<span className={classes.timeInfo + " " + contentClass}>
											{TimeFormat(item.createDate)}
										</span>
									</Typography>
								}
								secondary={
									<React.Fragment>
										<Typography
											component="span"
											variant="body2"
											className={classes.inline + " " + contentClass}
											color="textPrimary"
										>
											{item.description}
										</Typography>
										{/* {" — "}
										{TimeFormat(item.createDate)} */}
									</React.Fragment>
								}
							/>
						</ListItem>
					</Link>
					<Divider component="li" />
				</List>
			</div>
		</React.Fragment>
	);
}
