import React, { Fragment, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';

import { Box, Button, makeStyles, Modal, Typography, Popover } from '@material-ui/core';

import Header from 'views/Component/Header.js';
import LeftSidebar from 'views/Component/LeftSidebar.js';
import Footer from 'views/Component/Footer.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import { encryptData, decryptData, TimeFormat } from 'configurations/configurations.js';
import Hidden from '@material-ui/core/Hidden';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import testimonialsPageStyles from 'assets/jss/material-kit-pro-react/views/pageStyle/TestimonialsPage/testimonialsPageStyles';

import AppliedJobSectionNew from 'views/Page/JobStatusPage/AppliedJobSectionNew';
import OfferedJobSectionNew from 'views/Page/JobStatusPage/OfferedJobSectionNew';
import PendingedJobSectionNew from 'views/Page/JobStatusPage/PendingJobSectionNew';

import {
  getAppliedJobPostList,
  getAppliedJobPost,
  getOfferJobPostList,
  getOfferJobPost,
  getSeekerJobPostCount
} from "configurations/redux/appliedJobSlice";

/*Sourabh start */

import ResultJobModal from '../SearchJobPage/ResultJobModal.js';

import CreatePostModal from "../../../components/CreatePostModal/CreatePostModal";

import { getFavJobPostList, removeFavJob } from "configurations/redux/favJobSlice";


import { getCandidateSelectJobPosts } from 'configurations/redux/jobManagementSlice.js';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import style from "assets/jss/material-kit-pro-react/modalStyle.js";
import defaultAvatar from "assets/img/corporate_avatar.jpeg";
/*Sourabh end */


const useStyles = makeStyles(style);

const JobApplicationLanding = ({ match }) => {
  const styles = makeStyles(testimonialsPageStyles)();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [optionToShow, setOption] = useState('myTestimonials');
  const [isViewed, setIsViewed] = useState();
  const userStatus = useSelector(state => state.auth.user);
  const isMe = decryptData(match.params.acctId) === userStatus.acctId;
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  /*Sourabh start */
  const [openDeleteDiolog, setOpenDeleteDiolog] = React.useState(false);

  const handleClickToOpenDeleteDiolog = (id) => {
    setDeleteSavedId(id);
    setOpenDeleteDiolog(true);
  };

  const handleToCloseDeleteDiolog = () => {
    setOpenDeleteDiolog(false);
  };
  const [deleteSavedId, setDeleteSavedId] = React.useState(0);

  const [jobViewModal, setJobViewModal] = React.useState(false);

  const favJobPostList = useSelector((state) => state.favJob.jobPostList);

  const seekerJobCountList = useSelector(
    (state) => state.appliedJob.jobPostCount
  );

  const [createPost, setCreatePost] = React.useState(false);
  const [selectedJobPost, setSelectedJobPost] = React.useState([]);

  console.log('favJobPostList');
  console.log(favJobPostList);
  console.log('favJobPostList');

  const [currentPage, setCurrentPage] = React.useState(5);
  const [jobsPerPage, setJobsPerPage] = React.useState(5);

  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;


  const onJobModelClose = () => {
    setJobViewModal(false);
  };

  const getJobPostModel = async (jobPostSelId) => {
    const requestProfile = {
      acctId: userStatus.acctId,
      email: userStatus.email,
      accessToken: localStorage.getItem("token"),
      // acctId: auth.isPublic ? 0 : userStatus.acctId,
      // accessToken: auth.isPublic ? auth.publicToken : localStorage.getItem('token'),
      ipAddr: localStorage.getItem('ipAddr'), //await publicIp.v4(),
      jobPostId: jobPostSelId,
      item: null,
    };
    console.log(requestProfile);
    dispatch(getCandidateSelectJobPosts(requestProfile));
    setJobViewModal(true);
  };

  /*Sourabh end */

  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 46,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#554BE9',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  /*  const [anchorEl, setAnchorEl] = React.useState(null);
   const open = Boolean(anchorEl);
   const [open1, setOpen] = React.useState(false);
   const handleOpen = () => setOpen(true);
   const handleClick = (event) => {
     setAnchorEl(event.currentTarget);
   };
   const handleClose = () => {
     setAnchorEl(null);
   };
    */
  console.log(match);
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    history.replace(match.url);
  }, []);

  React.useEffect(() => {
    const decryptId = decryptData(match.params.acctId);
    console.log(decryptId);
    if (decryptId === userStatus.acctId) {
      setIsViewed(false);
    } else {
      setIsViewed(true);
    }
  }, []);

  console.log(isViewed);
  const tabs = ['myTestimonials', 'pendingRequests', 'testimonialsGiven'];

  const isEmptyState = false;

  //Applied job section
  const [appliedJobCount, setAppliedJobCount] = React.useState(0);
  const [offerJobCount, setOfferJobCount] = React.useState(0);
  const [pendingJobCount, setPendingJobCount] = React.useState(0);


  const appliedJobsCount = appliedCount => {
    setAppliedJobCount(appliedCount);
  };
  const offerJobsCount = offerCount => {
    setOfferJobCount(offerCount);
  };

  const pendingJobsCount = pendingCount => {
    setPendingJobCount(pendingCount);
  };

  const appliedJobPost = useSelector((state) => state.appliedJob.jobPost);

  console.log("+++++++++++++++++++++");
  console.log(appliedJobCount);
  console.log(offerJobCount);

  console.log("++++++++++++++++");

  //END APPLIED JOB SECTION

  /** Sourabh start */

  const statusChangeHandler = (item) => {
    console.log(item);
    setCreatePost(false);
    setSelectedJobPost([]);
  };


  React.useEffect(() => {
    requestSaveJobData();
    getSeekerJobPostCountMethod();
  }, []);
  //const selectedJobPost = useSelector(
  // (state) => state.jobManagement.selectedJobPost
  //);

  const getSeekerJobPostCountMethod = async () => {
    const requestProfile = {
      acctId: userStatus.acctId,
      email: userStatus.email,
      accessToken: localStorage.getItem("token"),

    };
    console.log(requestProfile);
    dispatch(getSeekerJobPostCount(requestProfile));

  };

  const requestSaveJobData = async () => {
    const request = {
      email: userStatus.email,
      acctId: userStatus.acctId,
      accessToken: localStorage.getItem('token'),
      keyword: '',
      lastRecordSize: 0,
      requestRecordSize: 100,
      optionId: -1,
    };

    const defaultJobRequest = {
      acctId: userStatus.acctId,

      accessToken: localStorage.getItem("token"),
      lastRecordSize: 0,
      requestRecordSize: 5,
      paginationClick: false,
    };


    dispatch(getFavJobPostList(defaultJobRequest));

    console.log('++++++++++++++++++++++++++++++++');
  };


  const createPostAction = (index) => {
    console.log('selectedJobPostttttttt');
    console.log(index);

    setCreatePost(true);
    console.log('selectedJobPost');
    console.log(favJobPostList[index]);
    setSelectedJobPost([favJobPostList[index]]);
    // console.log(encryptData(favItem.jobPostId));
  };

  const deleteSavedJob = (favId) => {
    //alert(favId);
    console.log('------ID---------' + favId);

    const updateRequest = {
      acctId: userStatus.acctId,
      email: userStatus.email,
      accessToken: localStorage.getItem("token"),
      favId,
    };
    dispatch(removeFavJob(updateRequest));
    //      handleClose();
  };

  const moreSavedJob = (curcount) => {
    const defaultJobRequest = {
      acctId: userStatus.acctId,

      accessToken: localStorage.getItem("token"),
      lastRecordSize: 0,
      requestRecordSize: currentPage + 5,
      paginationClick: false,
    };
    // let newsavesize = pasetInt(currentPage) + pasetInt(5);
    let newsavesize = currentPage + 5;
    console.log('------newsavesize---------' + newsavesize);
    setCurrentPage(newsavesize);
    dispatch(getFavJobPostList(defaultJobRequest));

  };


  const IsolatedMenu = props => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    //   console.log('props');
    //  console.log(props);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    //console.log(props.id.attachmentId );
    return (<Typography className='basicMenu'>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' + props.id.favId : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <i className='fas fa-ellipsis'></i>
      </Button>
      <Menu
        elevation={0}
        id={'basic-menu' + props.id.favId}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem className='menuTitle' onClick={() => handleClickToOpenDeleteDiolog(props.id.favId)} ><i className="fa-regular fa-trash-can"></i>Delete</MenuItem>

        <MenuItem className='menuTitle' onClick={() => createPostAction(props.dataindex)}  ><i className="fas fa-share-nodes"></i>Share </MenuItem>
      </Menu>
    </Typography>)
  }

  /** Sourabh end */

  return (
    <div>
      {/* {user && */}
      <Header color={'transparent'} loggedInStatus={false} />
      <div className={styles.body}>
        <div id="pageHeader" className={styles.pageHeader}>
          <div className={styles.container} id="testimonialPage">

            <GridContainer spacing={2} justifyContent="center">
              <Hidden smDown>
                <GridItem md={3} lg={2}>
                  <LeftSidebar />
                </GridItem>
              </Hidden>

              <GridItem xs={12} sm={12} md={9} lg={10}>
                <GridContainer spacing={2} justifyContent="center">
                  <GridItem xs={12} sm={12} md={3} lg={3}>
                    <Card className={styles.cardFrame}>
                      <CardBody className='customCardBody'>
                        <Typography component='div' className='AppliedJobTitle'>Applied Jobs ({seekerJobCountList && seekerJobCountList.appliedJob && seekerJobCountList.appliedJob > 0
                          ? seekerJobCountList.appliedJob
                          : 0})</Typography>
                        <AppliedJobSectionNew appliedJobCounter={seekerJobCountList && seekerJobCountList.appliedJob && seekerJobCountList.appliedJob > 0
                          ? seekerJobCountList.appliedJob
                          : 0} handleAppliedJobsCount={appliedJobsCount} />
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3} lg={3}>
                    <Card className={styles.cardFrame}>
                      <CardBody className='customCardBody'>
                        <Typography component='div' className='AppliedJobTitle'>Saved Jobs ({seekerJobCountList && seekerJobCountList.savedJob && seekerJobCountList.savedJob > 0
                          ? seekerJobCountList.savedJob
                          : 0})</Typography>

                        {
                          favJobPostList && favJobPostList.length > 0 &&

                          favJobPostList.map(function (favJobPostListItem, index) {

                            return (<Typography key={favJobPostListItem.favId} component='div' className='jobInvitation grey'>
                              <Typography component='div' className='inviteJob'>
                                <Typography component='div' className='inviteJobLeft'>
                                  <img width="40px"
                                    src={favJobPostListItem.profilePic ? favJobPostListItem.profilePic : defaultAvatar}
                                    alt="..."
                                  />
                                </Typography>
                                <IsolatedMenu dataindex={index} id={favJobPostListItem} />

                              </Typography>
                              <Link onClick={() => getJobPostModel(favJobPostListItem.jobPostId)} to="#" >
                                <Typography component='div' className='AppliedJobTitle designTitle'>{favJobPostListItem.title}</Typography>
                                <Typography component='div' className='appliedJobSubtitle'>{favJobPostListItem.companyName}</Typography>
                                <Typography component='div' className='priceJob'>{favJobPostListItem.currencyCode} {favJobPostListItem.salaryMin} - {favJobPostListItem.salaryMax}</Typography>
                                <Typography component='div' className='singaporeTitle'>{favJobPostListItem.countryName}</Typography>
                                <Typography component='div' className='dayOuter'>
                                  <Typography component='div' className='dayLeft'>{TimeFormat(favJobPostListItem.favDate)} </Typography>
                                  <Typography component='div' className='dayRight'>
                                    <span>{favJobPostListItem.jobPostType}</span>
                                  </Typography>
                                </Typography>
                              </Link>
                            </Typography>

                            )

                          })
                        }

                        {favJobPostList[0] && favJobPostList[0].favCount > currentPage ? (

                          <Button onClick={() => moreSavedJob(currentPage)} className='moreJobs'>{favJobPostList[0].favCount - 5} more jobs</Button>
                        ) : (
                          ''
                        )}
                      </CardBody>
                      {createPost ? (
                        <CreatePostModal

                          isOpen={createPost}
                          setPostsList={null}
                          user={userStatus}
                          oriVisibleOptions={null}
                          oriSocialPostTypeOptions={null}
                          oriCommentabilityOptions={null}
                          statusChangeHandler={statusChangeHandler}
                          handleCreatePostClick={null}
                          question={null}
                          options={null}
                          pollTiming={null}
                          removePoll={null}
                          savePostData={null}
                          selectPhotos={null}
                          selectVideos={null}
                          uploadedPhotos={[]}
                          uploadedVideos={[]}
                          removeAnUploadedPhoto={null}
                          removeAnUploadedVideo={null}
                          onPhotosListItemClick={null}
                          onVideosListItemClick={null}
                          onPhotosListItemDragStart={null}
                          onPhotosListItemDrop={null}
                          onVideosListItemDragStart={null}
                          onVideosListItemDrop={null}
                          isLoading={null}
                          setError={null}
                          editPost={null}
                          sharePost={null}
                          shareJob={selectedJobPost}
                          groupId={0}
                          postStatus={1}
                        />
                      ) : null}
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3} lg={3}>
                    <Card className={styles.cardFrame}>
                      <CardBody className='customCardBody'>
                        <Typography component='div' className='AppliedJobTitle'>Job Offers ({seekerJobCountList && seekerJobCountList.jobOffer && seekerJobCountList.jobOffer > 0
                          ? seekerJobCountList.jobOffer
                          : 0})</Typography>
                        <OfferedJobSectionNew appliedJobCounter={seekerJobCountList && seekerJobCountList.jobOffer && seekerJobCountList.jobOffer > 0
                          ? seekerJobCountList.jobOffer
                          : 0} handleOfferJobsCount={offerJobsCount} />

                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3} lg={3}>
                    <Card className={styles.cardFrame}>
                      <CardBody className='customCardBody'>
                        <Typography component='div' className='AppliedJobTitle'>Pending Interviews ({seekerJobCountList && seekerJobCountList.pendingInterview && seekerJobCountList.pendingInterview > 0
                          ? seekerJobCountList.pendingInterview
                          : 0})</Typography>
                        <PendingedJobSectionNew appliedJobCounter={seekerJobCountList && seekerJobCountList.pendingInterview && seekerJobCountList.pendingInterview > 0
                          ? seekerJobCountList.pendingInterview
                          : 0} handlePendingJobsCount={pendingJobsCount} />
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </div>
        </div>

        <Footer />
      </div>
      {jobViewModal ? <ResultJobModal open={jobViewModal} onClose={onJobModelClose} /> : null}

      {/* } */}


      <Dialog

        classes={{
          root: classes.modalRoot,
          paper: classes.modalLoader,
        }}
        open={openDeleteDiolog} onClose={handleToCloseDeleteDiolog}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"

      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        ><h4 className="withdrawtItle">
            <b>Delete Saved Job?</b>
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <DialogContentText className="withdrawjObtitle" id="alert-dialog-slide-description">
            Are you sure to delete saved job?
          </DialogContentText>
        </DialogContent>
        <DialogActions className='btnsfooter'>
          <Button className="undobtn" color="opptyWrapBorder" round onClick={handleToCloseDeleteDiolog}>
            Undo
          </Button>
          <Button className="withdrawbtn"
            onClick={() => {

              deleteSavedJob(deleteSavedId);
              // dispatch(withdrawAppliedJob(updateRequest));
              //  setTimeout(() => { getSeekerJobPostCountMethod(); }, 1000);
              handleToCloseDeleteDiolog();
            }}
            color="opptyWrap"
            round
          >
            <b>Delete</b>
          </Button>
        </DialogActions>

      </Dialog>



    </div>


  );
};

export default JobApplicationLanding;
