import React, { useState, useEffect, Fragment } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useHistory, Redirect } from "react-router-dom";
import axios from "axios";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import RectangleRoundedIcon from "@mui/icons-material/RectangleRounded";

import Badge from "@material-ui/core/Badge";

import {
	logout,
	publicMode,
	publicModeReset,
} from "configurations/redux/authSlice.js";
import {
	encryptData,
	instanceUrl,
	header,
} from "configurations/configurations.js";

import HeaderFunction from "../../components/Header/Header";
import Button from "components/CustomButtons/Button.js";
import Select from "react-select";
import headerStyle from "../../assets/jss/material-kit-pro-react/views/componentStyle/headerStyle.js";
// import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import InputAdornment from "@mui/material/InputAdornment";
import Popper from "components/Popper/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {
	eventNotificationGet,
	checkNotificationUnread,
	resetNotificationItem,
} from "configurations/redux/eventNotificationSlice.js";
import {
	checkMessageUnread,
	messageNewConversationInfo,
	messageConversationStatusInfo,
	messageConversationByConversationId,
	messageConversationByReadId,
} from "configurations/redux/messageConversationSlice.js";
import {
	getContactAccountStatus,
	getContactAccount,
	removeContactAcctARToken,
	errorContactImport,
} from "configurations/redux/userSlice";
import { messageNewChatroomInfo } from "configurations/redux/messageChatroomSlice.js";
import { isMobile, isTablet } from "react-device-detect";
import firebase from "../../firebase.js";
import AsyncSelect from "react-select/async";

import { resetRoadmap } from "configurations/redux/roadmapSlice.js";
import { useSnackbar } from "notistack";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";
import IdleTimerContainer from "../Component/IdleTimerContainer.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import SearchIcon from "@mui/icons-material/Search";
import ProfileBuildModal from "../Component/ProfileBuildModal.js";
import {
	faSignOutAlt,
	faNewspaper,
	faSignInAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
	getSearchResult,
	saveKeyword,
} from "configurations/redux/genericSearchSlice.js";

// import careerRoadMap from "assets/svg/signs-post-solid.svg";

// import defaultProfile from "assets/img/Default_Profile.png";
import IconLibrary from "./IconLibrary.js";
import candidate_avatar from "assets/img/candidate_avatar.jpeg";
import corporate_avatar from "assets/img/corporate_avatar.jpeg";
import { Hidden, TextField, Typography } from "@material-ui/core";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import { resetSelectBizPage } from "configurations/redux/businessSlice";
import { openSearchBox } from "configurations/redux/genericSearchSlice";
import { closeSearchBox } from "configurations/redux/genericSearchSlice";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import { emptyCacheNoReload } from "../../configurations/configurations.js";
const publicIp = require("public-ip");

const Header = ({ isAuthenticated, payload, buildProfileFlag }) => {
	const classes = makeStyles(headerStyle)();
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();
	const keyword = useSelector((state) => state.genericSearch.keyword);
	const gsLoading = useSelector((state) => state.genericSearch.isLoading);
	const isPublic = useSelector((state) => state.auth.isPublic);
	const publicEmail = useSelector((state) => state.auth.publicEmail);
	const publicToken = useSelector((state) => state.auth.publicToken);

	const connectionSelectStyles = {
		option: (provided, state) => ({
			...provided,
			fontWeight: 500,
			fontSize: "14px",
			backgroundColor: state.isSelected ? "#687bfe" : "white",
			color: state.isSelected ? "white" : "black",
			cursor: "pointer",
		}),
		input: (provided, state) => ({
			...provided,
			fontWeight: 700,
			fontSize: "14px",
		}),
		singleValue: (provided, state) => ({
			...provided,
			fontWeight: 500,
			fontSize: "14px",
		}),
		placeholder: (provided, state) => ({
			...provided,
			fontWeight: 500,
			fontSize: "14px",
		}),
		control: (provided, state) => ({
			...provided,
			height: "40px",
			width: "300px",
			borderRadius: "18px",
		}),
		noOptionsMessage: (provided, state) => ({
			...provided,
			fontWeight: 500,
			fontSize: "14px",
		}),
		loadingMessage: (provided, state) => ({
			...provided,
			fontWeight: 500,
			fontSize: "14px",
		}),
	};
	const isSearchBox = useSelector((state) => state.genericSearch.isSearchBox);

	const [acctId, setAcctId] = useState();
	const [anchorEl, setAnchorEl] = useState(null);
	const [anchorElBiz, setAnchorElBiz] = useState(null);
	const [isPopperOpen, setIsPopperOpen] = useState(false);
	const [isPopperOpenBiz, setIsPopperOpenBiz] = useState(false);
	// const [onlineStatus, setOnlineStatus] = useState("online");
	const [activeStatus, setActiveStatus] = useState();
	const [isFirebaseMessage, setIsFirebaseMessage] = useState();
	const [isFirebaseSeen, setIsFirebaseSeen] = useState();
	const [query, setQuery] = React.useState("");
	// const [isMounted, setIsMounted] = useState(false);
	// const [notify, setNotify] = useState({
	// 	isOpen: false,
	// 	message: "",
	// 	type: "",
	// });
	// const [notifyMsg, setNotifyMsg] = useState({
	// 	isOpen: false,
	// 	message: "",
	// 	type: "",
	// });
	const searchResultList = useSelector(
		(state) => state.genericSearch.searchResultList
	);
	const searchCount = useSelector(
		(state) => state.eventNotification.searchCount
	);
	const openNotification = useSelector(
		(state) => state.eventNotification.openNotification
	);
	const messageCount = useSelector(
		(state) => state.messageConversation.searchCount
	);
	// const isUpdated = useSelector((state) => state.eventNotification.isUpdated);
	const conversationId = useSelector(
		(state) => state.messageConversation.conversationId
	);
	const searchResult = useSelector(
		(state) => state.messageConversation.searchResult
	);
	const chatroomId = useSelector(
		(state) => state.messageChatroomList.chatroomId
	);
	const searchChatroom = useSelector(
		(state) => state.messageChatroom.searchResult
	);
	const activeBizId = useSelector((state) => state.messageChatroom.activeBizId);
	const auth = useSelector((state) => state.auth.user); // on initial login track down profile pic
	const profilePic = useSelector((state) => state.user.profilePic); // once they have access the profile page
	const corpProfilePic = useSelector((state) => state.corpUser.profilePic);
	const contactAcctList = useSelector((state) => state.user.contactAcctList);
	const bizId = useSelector((state) => state.business.bizId);
	const [isPublicMode, setIsPublicMode] = useState(false);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const snackBarShow = (message, variant, action) => {
		// variant could be success, error, warning, info, or default
		if (isAuthenticated) {
			enqueueSnackbar(message, { variant: variant, action });
		}
	};
	const onClickDismiss = (key) => closeSnackbar(key);

	useEffect(() => {
		if (
			// location.pathname.includes("public-") &&
			!isPublic &&
			!isAuthenticated
		) {
			console.log("create public mode");
			dispatch(publicMode());
		} else if (
			// location.pathname.includes("public-") &&
			isPublic &&
			!isAuthenticated
		) {
			console.log("update public mode");
			dispatch(publicMode());
		} else if (!location.pathname.includes("public-") && !isAuthenticated) {
			dispatch(publicModeReset());
		}
		if (isAuthenticated && location.pathname.includes("public-")) {
			dispatch(publicModeReset());
			history.replace(location.pathname.replace("public-", ""));
		}
		getIpAddr();
		return () => {
			console.log("refresh firebase", new Date().toLocaleTimeString());
			unsubFromNotificationRef.current && unsubFromNotificationRef.current();
		};
	}, []);

	useEffect(() => {
		if (isPublic) {
			setIsPublicMode(true);
		}
	}, [publicToken]);

	// useEffect(() => {
	// 	setIsPopperOpen(Boolean(anchorEl));
	// }, [anchorEl]);

	// useEffect(() => {
	// 	setIsPopperOpenBiz(Boolean(anchorElBiz));
	// }, [anchorElBiz]);

	useEffect(() => {
		// console.log(contactAcctList);
	}, [contactAcctList]);

	useEffect(() => {
		if (isAuthenticated) {
			loadMessageUnreadBadge();
			loadNotificationUnreadBadge();
		}
	}, [activeStatus]);

	React.useEffect(() => {
		let mounted = true;
		let CancelToken = axios.CancelToken;
		const source = CancelToken.source();

		if (mounted && isAuthenticated) {
			const request = {
				cancelToken: source.token,
				accessToken: isPublic ? publicToken : localStorage.getItem("token"),
				acctId: isPublic ? 0 : auth.acctId,
				email: isPublic ? publicEmail : auth.email,
				requestRecordSize: 5,
				keyword: "",
			};
			console.log(request);
			dispatch(getSearchResult(request));
		}
		return () => {
			mounted = false;
			source.cancel("cancelling in tabs action switch");
		};
	}, []);

	useEffect(() => {
		if (openNotification == null) {
			setIsPopperOpen(false);
			setIsPopperOpenBiz(false);
		}
		// setAnchorEl(openNotification);
	}, [openNotification]);

	React.useEffect(() => {
		let mounted = true;
		let CancelToken = axios.CancelToken;
		const source = CancelToken.source();
		if (query && (isAuthenticated || isPublic)) {
			const request = {
				cancelToken: source.token,
				accessToken: isPublic ? publicToken : localStorage.getItem("token"),
				acctId: isPublic ? 0 : auth.acctId,
				email: isPublic ? publicEmail : auth.email,
				requestRecordSize: 5,
				keyword: query,
			};

			dispatch(getSearchResult(request));
		}

		/* return () => {
			source.cancel();
		}; */
	}, [query]);
	const createKeywordRecord = async (query) => {
		const body = {
			/* accessToken: localStorage.getItem("token"),
			email: auth.email, */
			acctId: isPublic ? 0 : auth.acctId,
			accessToken: isPublic ? publicToken : localStorage.getItem("token"),
			email: isPublic ? publicEmail : auth.email,
			shDescription: query,
			/* 	acctId: auth.acctId, */
		};
		await axios({
			method: "POST",
			headers: header,
			url: `${instanceUrl}/api/gs/create-generic-search-history`,
			data: body,
		});
	};
	const loadOption = async (query, callback) => {
		let CancelToken = axios.CancelToken;
		const source = CancelToken.source();

		const body = {
			email: auth.email,
			acctId: auth.acctId,
			accessToken: localStorage.getItem("token"),
			keyword: query,
			cancelToken: source.token,
		};
		await axios({
			method: "GET",
			headers: header,
			url: `${instanceUrl}/api/gs/get-search-result-by-keyword`,
			params: body,
		})
			.then((res) => {
				if (res.status === 201) {
					var searchList = [];
					for (var i = 0; i < res.data.payload.searchResultList.length; i++) {
						searchList.push({
							value: res.data.payload.searchResultList[i].shDescription,
							name: res.data.payload.searchResultList[i].shDescription,
							totalCount: res.data.payload.searchResultList[i].totalCount,
						});
					}
					callback(searchList);
				}
			})
			.catch((err) => {
				if (axios.isCancel(err)) {
					source.cancel("turned");
					callback(null);
				}
			});
	};
	useEffect(() => {
		if (isAuthenticated) {
			const hashId = encryptData(payload.acctId);
			setAcctId(hashId);
			getNotification();
			loadNotificationUnreadBadge();
		}

		if (window.location.href && window.location.href.includes("https://www.")) {
			const newURL = window.location.href.replace("https://www.", "https://");
			window.location.replace(newURL);
		}
	}, [isAuthenticated]);

	useEffect(() => {
		if (isAuthenticated && isFirebaseMessage != undefined) {
			console.log("load firebase message");
			console.log(chatroomId, searchResult);
			loadNewConversation();
			loadNewChatroom();
		}
	}, [isFirebaseMessage]);

	useEffect(() => {
		if (isAuthenticated && isFirebaseSeen != undefined) {
			// console.log("load firebase seen");
			loadConversationStatus();
		}
	}, [isFirebaseSeen]);

	window.addEventListener("focus", updateActiveStatus);

	function updateActiveStatus(event) {
		if (activeStatus != undefined) setActiveStatus(event);
	}

	// console.log(anchorEl);
	const handleClick = (event) => {
		if (isAuthenticated) {
			if (isMobile || window.innerWidth < 1280) {
				history.push("/notification-page/0/");
				// loadNotificationList();
			} else {
				// console.log(location.pathname);
				if (location.pathname != "/notification-page/0/") {
					if (acctId != "null" && acctId != undefined) {
						loadNotificationList();
						if (bizId > 0) {
							setAnchorElBiz(event.currentTarget);
							setIsPopperOpenBiz(isPopperOpenBiz ? false : true);
						} else {
							setAnchorEl(event.currentTarget);
							setIsPopperOpen(isPopperOpen ? false : true);
						}
					}
					if (location.pathname == "/job-status-page")
						dispatch(resetNotificationItem());
				} else {
					// const action = (key) => (
					// 	<React.Fragment>
					// 		<IconButton onClick={onClickDismiss(key)}>
					// 			<CloseIcon />
					// 		</IconButton>
					// 	</React.Fragment>
					// );
					// snackBarShow("You are in the notification page", "warning", action);
				}
			}
		}
	};
	// const ProfileBuildModalShow = (acctId) => {
	//   console.log(location.pathname.split("/")[1]);
	//   if (isAuthenticated && buildProfileFlag === 0) {
	//     if (
	//       location.pathname.split("/")[1] === "profile-page" ||
	//       location.pathname.split("/")[1] === "corproate-profile-page"
	//     ) {
	//       return null;
	//     } else {
	//       return <ProfileBuildModal acctId={acctId} />;
	//     }
	//   } else {
	//     return null;
	//   }
	// };

	function loadNotificationList() {
		const requestProfile = {
			acctId: payload.acctId,
			accessToken: localStorage.getItem("token"),
			email: payload.email,
			lastRecordSize: 0,
			requestRecordSize: 10,
			bizId: bizId,
		};
		dispatch(eventNotificationGet(requestProfile));
	}
	function loadNotificationUnreadBadge() {
		const requestProfile = {
			acctId: payload.acctId,
			accessToken: localStorage.getItem("token"),
			email: payload.email,
			bizId: bizId,
		};
		dispatch(checkNotificationUnread(requestProfile));
	}
	function loadMessageUnreadBadge() {
		const requestProfile = {
			acctId: payload.acctId,
			accessToken: localStorage.getItem("token"),
			email: payload.email,
		};
		dispatch(checkMessageUnread(requestProfile));
	}

	const loadNewChatroom = () => {
		const requestProfile = {
			acctId: payload.acctId,
			accessToken: localStorage.getItem("token"),
			email: payload.email,
			searchResult: searchChatroom,
			bizId: activeBizId,
		};
		// console.log(searchChatroom);
		dispatch(messageNewChatroomInfo(requestProfile));
	};
	const loadNewConversation = () => {
		// console.log(searchResult);
		const requestProfile = {
			acctId: payload.acctId,
			accessToken: localStorage.getItem("token"),
			email: payload.email,
			conversationId: conversationId,
			chatroomId: chatroomId,
			searchResult: searchResult,
		};
		// console.log(requestProfile);
		dispatch(messageNewConversationInfo(requestProfile));
		loadMessageUnreadBadge();
	};
	const loadConversationStatus = () => {
		// console.log(searchResult);
		const requestProfile = {
			acctId: payload.acctId,
			accessToken: localStorage.getItem("token"),
			email: payload.email,
			conversationId: conversationId,
			chatroomId: chatroomId,
			searchResult: searchResult,
		};
		// console.log(requestProfile);
		dispatch(messageConversationStatusInfo(requestProfile));
	};

	const fdb = firebase.firestore();
	const col = fdb.collection("users");
	var loadFirebase = 0;
	const unsubFromNotificationRef = React.useRef();

	const getNotification = () => {
		// console.log("start firebase", new Date().toLocaleTimeString());
		const unsubFromNotification = col
			.doc("U" + payload.acctId)
			.onSnapshot((doc) => {
				// console.log(
				//   "loop firebase",
				//   new Date().toLocaleTimeString(),
				//   loadFirebase
				// );
				// console.log("Current data: ", doc.data());
				if (doc.data() == undefined) {
					col.doc("U" + payload.acctId).set({
						eventTypeId: 0,
						edited: firebase.firestore.FieldValue.serverTimestamp(),
					});
				} else if (loadFirebase > 0) {
					const requestProfile = {
						acctId: payload.acctId,
						accessToken: localStorage.getItem("token"),
						email: payload.email,
						bizId: bizId,
					};
					if (doc.data().eventTypeId == 1) {
						dispatch(checkNotificationUnread(requestProfile));
						// console.log(searchCount);
						if (searchCount > 0) {
							const action = (key) => (
								<React.Fragment>
									<IconButton
										onClick={() => {
											history.push(
												"/notification-page/" + doc.data().recId + "/"
											);
										}}
									>
										<VisibilityIcon />
									</IconButton>
									<IconButton onClick={onClickDismiss(key)}>
										<CloseIcon />
									</IconButton>
								</React.Fragment>
							);
							snackBarShow("You have a new notification", "info", action);
						}
						if (location.pathname == "/notification-page/0/")
							loadNotificationList();
					} else if (doc.data().eventTypeId == 5) {
						dispatch(checkMessageUnread(requestProfile));
						// console.log(messageCount);
						// if (messageCount > 0) {
						const action = (key) => (
							<React.Fragment>
								<IconButton
									onClick={() => {
										if (location.pathname.includes("/message-page/")) {
											const requestInfo = {
												acctId: payload.acctId,
												accessToken: localStorage.getItem("token"),
												email: payload.email,
												lastRecordSize: 0,
												requestRecordSize: isTablet ? 30 : 15,
												readId: doc.data().recId,
											};
											dispatch(messageConversationByReadId(requestInfo));
										} else
											history.push(
												"/message-page/0/0/" + doc.data().recId + "/"
											);
									}}
								>
									<VisibilityIcon />
								</IconButton>
								<IconButton onClick={onClickDismiss(key)}>
									<CloseIcon />
								</IconButton>
							</React.Fragment>
						);
						snackBarShow("You have a new message", "info", action);
						if (location.pathname == "/message-page/0/0/") {
							setIsFirebaseMessage(doc.data().edited);
						}
					} else if (doc.data().eventTypeId == 6) {
						dispatch(checkMessageUnread(requestProfile));
						const action = (key) => (
							<React.Fragment>
								<IconButton
									onClick={() => {
										if (location.pathname.includes("/message-page/")) {
											const requestInfo = {
												acctId: payload.acctId,
												accessToken: localStorage.getItem("token"),
												email: payload.email,
												lastRecordSize: 0,
												requestRecordSize: isTablet ? 30 : 15,
												conversationId: doc.data().recId,
											};
											dispatch(
												messageConversationByConversationId(requestInfo)
											);
										} else
											history.push(
												"/message-page/0/N" + doc.data().recId + "/"
											);
									}}
								>
									<VisibilityIcon />
								</IconButton>
								<IconButton onClick={onClickDismiss(key)}>
									<CloseIcon />
								</IconButton>
							</React.Fragment>
						);
						snackBarShow("Message has been seen", "success", action);
						if (location.pathname == "/message-page/0/0/") {
							setIsFirebaseSeen(doc.data().edited);
						}
					} else if (doc.data().eventTypeId == 8) {
						dispatch(checkMessageUnread(requestProfile));
						loadNewChatroom();
					} else if (doc.data().eventTypeId == 9) {
						const requestInfo = {
							acctId: payload.acctId,
							accessToken: localStorage.getItem("token"),
							email: payload.email,
							caId: doc.data().recId,
							resetStatus: 0,
						};
						if (
							localStorage.getItem("MCA") == "" ||
							localStorage.getItem("GCA") == ""
						)
							dispatch(getContactAccount(requestInfo));
						else dispatch(getContactAccountStatus(requestInfo));
					} else if (doc.data().eventTypeId == 10) {
						const request = {
							acctId: payload.acctId,
							email: payload.email,
							caId: doc.data().recId,
							accessToken: localStorage.getItem("token"),
						};
						dispatch(removeContactAcctARToken(request));
						dispatch(errorContactImport("Insufficient Permission"));
					} else if (doc.data().eventTypeId == 999) {
						dispatch(logout(0));
					}
				}
				loadFirebase++;
			});

		unsubFromNotificationRef.current = unsubFromNotification;
	};

	// const closeNotification = () => {
	// 	console.log("close firebase", new Date().toLocaleTimeString());
	// 	unsubFromNotificationRef.current && unsubFromNotificationRef.current();
	// };

	const getIpAddr = async () => {
		var ipAddr = localStorage.getItem("ipAddr");
		var newIpAddr = await publicIp.v4();
		if (newIpAddr) {
			localStorage.setItem("ipAddr", newIpAddr);
		}
	};

	// React.useEffect(() => {
	// 	setTimeout(() => {
	// 		setIsMounted(true);
	// 	}, 3000);
	// }, []);
	function logoutHandle() {
		// closeNotification();
		dispatch(logout(0));
		emptyCacheNoReload();
	}

	// console.log(buildProfileFlag, acctId);

	const [searchBarValue, setSearchBarValue] = useState("");

	return (
		<ClickAwayListener
			onClickAway={() => {
				setIsPopperOpenBiz(false);
				setAnchorElBiz(null);
				setIsPopperOpen(false);
				setAnchorEl(null);
			}}
		>
			<Fragment>
				<HeaderFunction
					fixed
					color={bizId > 0 ? "oppty" : "white"}
					description={""}
					links={
						<Card className={classes.cardFrame}>
							<CardBody
								className={classes.cardBody}
								style={{ backgroundColor: bizId > 0 ? "#6F52ED" : "" }}
							>
								{/* <SearchBoxArea /> */}
								<Hidden only={["sm", "md", "lg", "xl"]}>
									{isSearchBox && (
										<IconButton
											style={{ marginRight: "10px" }}
											onClick={() => {
												dispatch(closeSearchBox());
											}}
										>
											<ArrowBackIosRoundedIcon />
										</IconButton>
									)}
								</Hidden>
								{bizId === 0 && (
									<Autocomplete
										id="asynchronous-demo"
										className={classes.textAutoComplete}
										/* 	sx={{ width: 300 }} */
										value={keyword || ""}
										/*    isOptionEqualToValue={(option, value) => option.title === value.title} */
										getOptionLabel={(option) =>
											option.value ? option.value : option
										}
										options={searchResultList}
										loading={gsLoading}
										onFocus={() => {
											dispatch(openSearchBox());
										}}
										onBlur={() => {
											dispatch(closeSearchBox());
										}}
										onChange={(event, value) => {
											if (value) {
												const response = {
													keyword: value.value ? value.value : value,
												};
												dispatch(closeSearchBox());
												dispatch(saveKeyword(response));
												createKeywordRecord(value.value ? value.value : value);
												if (!isAuthenticated) {
													setIsPublicMode(false);
													dispatch(publicMode());
													var timer = setInterval(() => {
														if (isPublicMode) {
															clearInterval(timer);
															history.replace("/public-search-page/");
														}
													}, 300);
												} else history.replace("/search-page");
											}
										}}
										/* freeSolo */
										onInputChange={(event, newInputValue) => {
											setQuery(newInputValue);
										}}
										disableClearable
										freeSolo
										onKeyDown={(e) => {
											if (e.key === "Enter") {
												e.preventDefault();
												dispatch(closeSearchBox());
												const response = {
													keyword: e.target.value,
												};

												if (e.target.value) {
													createKeywordRecord(e.target.value);
												}
												dispatch(saveKeyword(response));
												if (!isAuthenticated) {
													setIsPublicMode(false);
													dispatch(publicMode());
													var timer = setInterval(() => {
														if (isPublicMode) {
															clearInterval(timer);
															history.replace("/public-search-page/");
														}
													}, 300);
												} else history.replace("/search-page");
											}
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Search"
												variant="outlined"
												className={classes.autoComplete.custom_autocomplete}
												InputProps={{
													...params.InputProps,
													endAdornment: (
														<React.Fragment>
															{gsLoading ? (
																<IconButton>
																	<CircularProgress color="inherit" size={24} />
																</IconButton>
															) : (
																<IconButton
																	onClick={(e) => {
																		e.preventDefault();
																		dispatch(closeSearchBox());
																		const response = {
																			keyword: e.target.value,
																		};

																		if (e.target.value) {
																			createKeywordRecord(e.target.value);
																		}
																		dispatch(saveKeyword(response));
																		if (!isAuthenticated) {
																			setIsPublicMode(false);
																			dispatch(publicMode());
																			var timer = setInterval(() => {
																				if (isPublicMode) {
																					clearInterval(timer);
																					history.replace(
																						"/public-search-page/"
																					);
																				}
																			}, 300);
																		} else history.replace("/search-page");
																	}}
																>
																	<SearchIcon fontSize="medium" />
																</IconButton>
															)}
															{params.InputProps.endAdornment}
														</React.Fragment>
													),
												}}
											/>
										)}
									/>
								)}
								<Hidden mdDown>
									<List className={classes.list + " " + classes.mlAuto}>
										<IdleTimerContainer />
										{/* 	<ProfileBuildModalShow acctId={acctId} /> */}
										{isAuthenticated ? (
											<Fragment>
												{bizId == 0 && (
													<ListItem className={classes.listPadding}>
														<Link
															className={classes.textDeco}
															to={`/user-dashboard/${acctId}`}
															//to="/social-feed/1/0"
															color="transparent"
														>
															<Button
																className={
																	bizId > 0
																		? classes.bizNavLink
																		: classes.navLink
																}
																color="transparent"
																fullWidth
															>
																<div
																	className={
																		location.pathname === "/social-feed/1/0"
																			? classes.bizSelectedIcon
																			: null
																	}
																>
																	<GridContainer
																		justifyContent="center"
																		alignContent="center"
																		alignItems="center"
																		className={classes.headerButton}
																	>
																		<GridItem xs={6} sm={6} md={12}>
																			<IconLibrary.Home />
																		</GridItem>

																		<GridItem xs={6} sm={6} md={12}>
																			Home
																		</GridItem>
																	</GridContainer>
																</div>
															</Button>
														</Link>
													</ListItem>
												)}

												{bizId > 0 && (<ListItem className={classes.listPadding}>
													<Link
														className={classes.textDeco}
														to={{
															pathname: `/corporate-dashboard-page/${acctId}/${encryptData(
																bizId
															)}` /* state: { acctId: 134 }, */,
														}}
														color="transparent"
													>
														<Button
															className={classes.bizNavLink}
															color="transparent"
															fullWidth
														>
															<div
																className={
																	location.pathname.includes(
																		`/corporate-dashboard-page/`
																	)
																		? classes.bizSelectedIcon
																		: null
																}
															>
																<GridContainer
																	justifyContent="center"
																	className={classes.headerButton}
																>
																	<GridItem xs={6} sm={6} md={12}>
																		<IconLibrary.Home />
																	</GridItem>
																	<GridItem xs={6} sm={6} md={12}>
																		Home
																	</GridItem>
																</GridContainer>
															</div>
														</Button>
													</Link>
												</ListItem>)}

												<ListItem
													className={classes.listPadding}
													style={{ textAlign: "center" }}
												>
													{/* <Notification notify={notifyMsg} setNotify={setNotifyMsg} /> */}
													<Link
														className={classes.textDeco}
														to="/message-page/0/0/"
														color="transparent"
													>
														<Button
															className={
																bizId > 0 ? classes.bizNavLink : classes.navLink
															}
															color="transparent"
														>
															<div
																className={
																	location.pathname === "/message-page/0/0/"
																		? bizId > 0
																			? classes.bizSelectedIcon
																			: classes.selectedIcon
																		: null
																}
															>
																<GridContainer
																	justifyContent="center"
																	className={classes.headerButton}
																>
																	<GridItem xs={6} sm={6} md={12}>
																		<Badge
																			classes={{
																				badge:
																					bizId > 0
																						? classes.bizBadgeColor
																						: classes.badgeColor,
																			}}
																			badgeContent={messageCount}
																		>
																			<IconLibrary.MailInbox />
																		</Badge>
																	</GridItem>
																	<GridItem xs={6} sm={6} md={12}>
																		Inbox
																	</GridItem>
																</GridContainer>
															</div>
														</Button>
													</Link>
												</ListItem>

												<ListItem className={classes.listPadding}>
													<Link
														className={classes.textDeco}
														to="/resources"
														color="transparent"
													>
														<Button
															className={
																bizId > 0 ? classes.bizNavLink : classes.navLink
															}
															color="transparent"
															fullWidth
															onClick={() => dispatch(resetRoadmap())}
														>
															<div
																className={
																	location.pathname === "/resources"
																		? bizId > 0
																			? classes.bizSelectedIcon
																			: classes.selectedIcon
																		: null
																}
															>
																<GridContainer
																	justifyContent="center"
																	alignContent="center"
																	alignItems="center"
																	className={classes.headerButton}
																>
																	<GridItem xs={6} sm={6} md={12}>
																		<IconLibrary.SignBoard />
																	</GridItem>

																	<GridItem xs={6} sm={6} md={12}>
																		Resources
																	</GridItem>
																</GridContainer>
															</div>
														</Button>
													</Link>
												</ListItem>

												{/* {payload.acctTypeId === 2 ? ( */}
												{bizId && bizId > 0 ? (
													<Fragment>
														<ListItem className={classes.listPadding}>
															<Link
																className={classes.textDeco}
																to={{
																	pathname:
																		"/search-profile-page" /* state: { acctId: 134 }, */,
																}}
																color="transparent"
															>
																<Button
																	className={classes.bizNavLink}
																	color="transparent"
																	fullWidth
																>
																	<div
																		className={
																			location.pathname ===
																				"/search-profile-page"
																				? classes.bizSelectedIcon
																				: null
																		}
																	>
																		<GridContainer
																			justifyContent="center"
																			className={classes.headerButton}
																		>
																			<GridItem xs={6} sm={6} md={12}>
																				<IconLibrary.Candidates />
																			</GridItem>
																			<GridItem xs={6} sm={6} md={12}>
																				Candidates
																			</GridItem>
																		</GridContainer>
																	</div>
																</Button>
															</Link>
														</ListItem>

														<ListItem className={classes.listPadding}>
															<Link
																className={classes.textDeco}
																to="/corporate-job-management-page"
																color="transparent"
															>
																<Button
																	className={classes.bizNavLink}
																	color="transparent"
																	fullWidth
																>
																	<div
																		className={
																			location.pathname ===
																				"/corporate-job-management-page"
																				? classes.bizSelectedIcon
																				: null
																		}
																	>
																		<GridContainer
																			justifyContent="center"
																			className={classes.headerButton}
																		>
																			<GridItem xs={6} sm={6} md={12}>
																				<IconLibrary.Jobs />
																			</GridItem>
																			<GridItem xs={6} sm={6} md={12}>
																				Job Dashboard
																			</GridItem>
																		</GridContainer>
																	</div>
																</Button>
															</Link>
														</ListItem>



														<ListItem className={classes.listPadding}>
															<Link
																className={classes.textDeco}
																to={{
																	pathname: `/corporate-profile-page/${acctId}/${encryptData(
																		bizId
																	)}` /* state: { acctId: 134 }, */,
																}}
																color="transparent"
															>
																<Button
																	className={classes.bizNavLink}
																	color="transparent"
																	fullWidth
																>
																	<div
																		className={
																			location.pathname.includes(
																				`/corporate-profile-page/`
																			)
																				? classes.bizSelectedIcon
																				: null
																		}
																	>
																		<GridContainer
																			justifyContent="center"
																			className={classes.headerButton}
																		>
																			<GridItem xs={6} sm={6} md={12}>
																				<img
																					src={
																						auth
																							? corpProfilePic
																								? corpProfilePic
																								: auth.profilePic
																									? auth.profilePic
																									: corporate_avatar
																							: null
																					}
																					alt="..."
																					className={classes.profilePicture}
																				/>
																			</GridItem>
																			<GridItem xs={6} sm={6} md={12}>
																				Profile
																			</GridItem>
																		</GridContainer>
																	</div>
																</Button>
															</Link>
														</ListItem>
														<ListItem className={classes.listPadding}>
															{/* <Notification notify={notify} setNotify={setNotify} /> */}
															<Button
																className={classes.bizNavLink}
																color="transparent"
																fullWidth
																onClick={handleClick}
															>
																<div
																	className={
																		anchorElBiz ? classes.bizSelectedIcon : null
																	}
																>
																	<GridContainer
																		justifyContent="center"
																		className={classes.headerButton}
																	>
																		<GridItem xs={6} sm={6} md={12}>
																			<Badge
																				classes={{
																					badge: classes.bizBadgeColor,
																				}}
																				badgeContent={searchCount}
																			>
																				<IconLibrary.NotificationOutline />
																			</Badge>
																		</GridItem>
																		<GridItem xs={6} sm={6} md={12}>
																			Alerts
																		</GridItem>
																	</GridContainer>
																</div>
															</Button>
															<Popper
																id={
																	isPopperOpenBiz
																		? "simple-popover-biz"
																		: undefined
																}
																open={isPopperOpenBiz}
																anchorEl={anchorElBiz}
															/>
														</ListItem>
													</Fragment>
												) : bizId == 0 ? (
													<Fragment>
														<ListItem className={classes.listPadding}>
															<Link
																className={classes.textDeco}
																to="/search-page-job"
																color="transparent"
															>
																<Button
																	className={classes.navLink}
																	color="transparent"
																	fullWidth
																>
																	<div
																		className={
																			location.pathname === "/search-page-job"
																				? classes.selectedIcon
																				: null
																		}
																	>
																		<GridContainer
																			justifyContent="center"
																			className={classes.headerButton}
																		>
																			<GridItem xs={6} sm={6} md={12}>
																				<IconLibrary.Jobs />
																			</GridItem>
																			<GridItem xs={6} sm={6} md={12}>
																				Jobs
																			</GridItem>
																		</GridContainer>
																	</div>
																</Button>
															</Link>
														</ListItem>

														<ListItem className={classes.listPadding}>
															{/* <Notification notify={notify} setNotify={setNotify} /> */}
															<Button
																className={classes.navLink}
																color="transparent"
																fullWidth
																onClick={handleClick}
															>
																<div
																	className={
																		anchorEl ? classes.selectedIcon : null
																	}
																>
																	<GridContainer
																		justifyContent="center"
																		className={classes.headerButton}
																	>
																		<GridItem xs={6} sm={6} md={12}>
																			<Badge
																				classes={{ badge: classes.badgeColor }}
																				badgeContent={searchCount}
																			>
																				<IconLibrary.NotificationOutline />
																			</Badge>
																		</GridItem>
																		<GridItem xs={6} sm={6} md={12}>
																			Alerts
																		</GridItem>
																	</GridContainer>
																</div>
															</Button>
															<Popper
																id={isPopperOpen ? "simple-popover" : undefined}
																open={isPopperOpen}
																anchorEl={anchorEl}
															/>
														</ListItem>

														<ListItem className={classes.listPadding}>
															<Link
																className={classes.textDeco}
																to={`/view-profile/${acctId}`}
																color="transparent"
															>
																<Button
																	className={classes.navLink}
																	color="transparent"
																	fullWidth
																>
																	<div
																		className={
																			location.pathname.includes(
																				`/view-profile/`
																			)
																				? classes.selectedIcon
																				: null
																		}
																	>
																		<GridContainer
																			justifyContent="center"
																			className={classes.headerButton}
																		>
																			<GridItem xs={6} sm={6} md={12}>
																				<img
																					src={
																						/* auth.user
																						? auth.user.profilePic
																							? auth.user.profilePic
																							: candidate_avatar
																						: candidate_avatar */
																						auth.profilePic
																							? auth.profilePic
																							: candidate_avatar
																					}
																					alt="..."
																					className={classes.profilePicture}
																				/>
																			</GridItem>
																			<GridItem xs={6} sm={6} md={12}>
																				Profile
																			</GridItem>
																		</GridContainer>
																	</div>
																</Button>
															</Link>
														</ListItem>
													</Fragment>
												) : null}
												{bizId == 0 ? (
													<ListItem className={classes.listPadding}>
														<Button
															className={classes.navLink}
															onClick={() => {
																setTimeout(() => {
																	logoutHandle();
																}, 1000);
															}}
															fullWidth
															color="transparent"
														>
															<div /* className={location.pathname === "/search-job-page" ?
                              classes.selectedIcon : null
                          } */
															>
																<GridContainer
																	justifyContent="center"
																	className={classes.headerButton}
																>
																	<GridItem xs={6} sm={6} md={12}>
																		<FontAwesomeIcon
																			icon={faSignOutAlt}
																			size={"2x"}
																		/>
																	</GridItem>
																	<GridItem xs={6} sm={6} md={12}>
																		Sign Out
																	</GridItem>
																</GridContainer>
															</div>
														</Button>
													</ListItem>
												) : bizId && bizId > 0 ? (
													<ListItem className={classes.listPadding}>
														<Button
															className={
																bizId > 0 ? classes.bizNavLink : classes.navLink
															}
															onClick={() => {
																setTimeout(() => {
																	dispatch(resetSelectBizPage());
																	history.push(
																		`/biz-page/${encryptData(auth.acctId)}`
																	);
																}, 1000);
															}}
															fullWidth
															color="transparent"
														>
															<div>
																<GridContainer
																	justifyContent="center"
																	className={classes.headerButton}
																>
																	<GridItem xs={6} sm={6} md={12}>
																		<FontAwesomeIcon
																			icon={faSignOutAlt}
																			size={"2x"}
																		/>
																	</GridItem>
																	<GridItem xs={6} sm={6} md={12}>
																		Exit Page
																	</GridItem>
																</GridContainer>
															</div>
														</Button>
													</ListItem>
												) : null}
											</Fragment>
										) : (
											<Fragment>
												<ListItem className={classes.listPadding}>
													<Link
														className={classes.textDeco}
														to="/login-page"
														color="transparent"
													>
														<Button
															className={classes.navLink}
															color="transparent"
														>
															<div
																className={
																	location.pathname === `/login-page`
																		? classes.selectedIcon
																		: null
																}
															>
																<GridContainer
																	justifyContent="center"
																	className={classes.headerButton}
																>
																	<GridItem xs={6} sm={6} md={12}>
																		<FontAwesomeIcon
																			icon={faSignInAlt}
																			size={"2x"}
																		/>
																	</GridItem>
																	<GridItem xs={6} sm={6} md={12}>
																		Sign In
																	</GridItem>
																</GridContainer>

																{/* 	<div className={classes.drawerButton}>
																<FontAwesomeIcon
																	icon={faSignInAlt}
																	size={"2x"}
																/>{" "}
																Sign In
															</div> */}
															</div>
														</Button>
													</Link>
												</ListItem>

												<ListItem className={classes.listPadding}>
													<Link
														className={classes.textDeco}
														to="/registration-page"
														color="transparent"
													>
														<Button
															className={classes.navButton}
															color="oppty"
															round
														>
															<b>Join Now</b>
														</Button>
													</Link>
												</ListItem>
											</Fragment>
										)}
									</List>
								</Hidden>
								{bizId > 0 ? (
									<Hidden lgUp>
										<List className={classes.list + " " + classes.mlAuto}>
											<IdleTimerContainer />
											{/* 	<ProfileBuildModalShow acctId={acctId} /> */}
											{isAuthenticated ? (
												<Fragment>
													<ListItem className={classes.listPadding}>
														<Link
															className={classes.textDeco}
															to={{
																pathname: `/corporate-dashboard-page/${acctId}/${encryptData(
																	bizId
																)}` /* state: { acctId: 134 }, */,
															}}
															color="transparent"
														>
															<Button
																className={classes.bizNavLink}
																color="transparent"
																fullWidth
															>
																<div
																	className={
																		location.pathname.includes(
																			`/corporate-dashboard-page/`
																		)
																			? classes.bizSelectedIcon
																			: null
																	}
																>
																	<GridContainer
																		justifyContent="center"
																		className={classes.headerButton}
																	>
																		<GridItem xs={6} sm={6} md={12}>
																			<IconLibrary.Jobs />
																		</GridItem>
																		<GridItem xs={6} sm={6} md={12}>
																			Corporate Dashboard
																		</GridItem>
																	</GridContainer>
																</div>
															</Button>
														</Link>
													</ListItem>

													{/* <ListItem className={classes.listPadding}>
														<Link
															className={classes.textDeco}
															to={{
																pathname: `/chatbot-landing/${acctId}/${encryptData(
																	bizId
																)}`,
															}}
															color="transparent"
														>
															<Button
																className={classes.bizNavLink}
																color="transparent"
																fullWidth
															>
																<div
																	className={
																		location.pathname.includes(
																			`/chatbot-landing/`
																		)
																			? classes.bizSelectedIcon
																			: null
																	}
																>
																	<GridContainer
																		justifyContent="center"
																		className={classes.headerButton}
																	>
																		<GridItem xs={6} sm={6} md={12}>
																			<IconLibrary.Jobs />
																		</GridItem>
																		<GridItem xs={6} sm={6} md={12}>
																			ChatBot Jarvis
																		</GridItem>
																	</GridContainer>
																</div>
															</Button>
														</Link>
													</ListItem> */}

													<ListItem
														className={classes.listPadding}
														style={{ textAlign: "center" }}
													>
														{/* <Notification notify={notifyMsg} setNotify={setNotifyMsg} /> */}
														<Link
															className={classes.textDeco}
															to="/message-page/0/0/"
															color="transparent"
														>
															<Button
																className={
																	bizId > 0
																		? classes.bizNavLink
																		: classes.navLink
																}
																color="transparent"
															>
																<div
																	className={
																		location.pathname === "/message-page/0/0/"
																			? bizId > 0
																				? classes.bizSelectedIcon
																				: classes.selectedIcon
																			: null
																	}
																>
																	<GridContainer
																		justifyContent="center"
																		className={classes.headerButton}
																	>
																		<GridItem xs={6} sm={6} md={12}>
																			<Badge
																				classes={{
																					badge:
																						bizId > 0
																							? classes.bizBadgeColor
																							: classes.badgeColor,
																				}}
																				badgeContent={messageCount}
																			>
																				<IconLibrary.MailInbox />
																			</Badge>
																		</GridItem>
																		<GridItem xs={6} sm={6} md={12}>
																			Inbox
																		</GridItem>
																	</GridContainer>
																</div>
															</Button>
														</Link>
													</ListItem>

													<ListItem className={classes.listPadding}>
														<Link
															className={classes.textDeco}
															to="/resources"
															color="transparent"
														>
															<Button
																className={
																	bizId > 0
																		? classes.bizNavLink
																		: classes.navLink
																}
																color="transparent"
																fullWidth
																onClick={() => dispatch(resetRoadmap())}
															>
																<div
																	className={
																		location.pathname === "/resources"
																			? bizId > 0
																				? classes.bizSelectedIcon
																				: classes.selectedIcon
																			: null
																	}
																>
																	<GridContainer
																		justifyContent="center"
																		alignContent="center"
																		alignItems="center"
																		className={classes.headerButton}
																	>
																		<GridItem xs={6} sm={6} md={12}>
																			<IconLibrary.SignBoard />
																		</GridItem>

																		<GridItem xs={6} sm={6} md={12}>
																			Resources
																		</GridItem>
																	</GridContainer>
																</div>
															</Button>
														</Link>
													</ListItem>

													{/* {payload.acctTypeId === 2 ? ( */}
													{bizId && bizId > 0 ? (
														<Fragment>
															<ListItem className={classes.listPadding}>
																<Link
																	className={classes.textDeco}
																	to={{
																		pathname:
																			"/search-profile-page" /* state: { acctId: 134 }, */,
																	}}
																	color="transparent"
																>
																	<Button
																		className={classes.bizNavLink}
																		color="transparent"
																		fullWidth
																	>
																		<div
																			className={
																				location.pathname ===
																					"/search-profile-page"
																					? classes.bizSelectedIcon
																					: null
																			}
																		>
																			<GridContainer
																				justifyContent="center"
																				className={classes.headerButton}
																			>
																				<GridItem xs={6} sm={6} md={12}>
																					<IconLibrary.Candidates />
																				</GridItem>
																				<GridItem xs={6} sm={6} md={12}>
																					Candidates
																				</GridItem>
																			</GridContainer>
																		</div>
																	</Button>
																</Link>
															</ListItem>

															<ListItem className={classes.listPadding}>
																<Link
																	className={classes.textDeco}
																	to="/corporate-job-management-page"
																	color="transparent"
																>
																	<Button
																		className={classes.bizNavLink}
																		color="transparent"
																		fullWidth
																	>
																		<div
																			className={
																				location.pathname ===
																					"/corporate-job-management-page"
																					? classes.bizSelectedIcon
																					: null
																			}
																		>
																			<GridContainer
																				justifyContent="center"
																				className={classes.headerButton}
																			>
																				<GridItem xs={6} sm={6} md={12}>
																					<IconLibrary.Jobs />
																				</GridItem>
																				<GridItem xs={6} sm={6} md={12}>
																					Job Dashboard
																				</GridItem>
																			</GridContainer>
																		</div>
																	</Button>
																</Link>
															</ListItem>

															<ListItem className={classes.listPadding}>
																<Link
																	className={classes.textDeco}
																	to={{
																		pathname: `/corporate-profile-page/${acctId}/${encryptData(
																			bizId
																		)}` /* state: { acctId: 134 }, */,
																	}}
																	color="transparent"
																>
																	<Button
																		className={classes.bizNavLink}
																		color="transparent"
																		fullWidth
																	>
																		<div
																			className={
																				location.pathname.includes(
																					`/corporate-profile-page/`
																				)
																					? classes.bizSelectedIcon
																					: null
																			}
																		>
																			<GridContainer
																				justifyContent="center"
																				className={classes.headerButton}
																			>
																				<GridItem xs={6} sm={6} md={12}>
																					<img
																						src={
																							auth
																								? corpProfilePic
																									? corpProfilePic
																									: auth.profilePic
																										? auth.profilePic
																										: corporate_avatar
																								: null
																						}
																						alt="..."
																						className={classes.profilePicture}
																					/>
																				</GridItem>
																				<GridItem xs={6} sm={6} md={12}>
																					Profile
																				</GridItem>
																			</GridContainer>
																		</div>
																	</Button>
																</Link>
															</ListItem>
															<ListItem className={classes.listPadding}>
																{/* <Notification notify={notify} setNotify={setNotify} /> */}
																<Button
																	className={classes.bizNavLink}
																	color="transparent"
																	fullWidth
																	onClick={handleClick}
																>
																	<div
																		className={
																			anchorElBiz
																				? classes.bizSelectedIcon
																				: null
																		}
																	>
																		<GridContainer
																			justifyContent="center"
																			className={classes.headerButton}
																		>
																			<GridItem xs={6} sm={6} md={12}>
																				<Badge
																					classes={{
																						badge: classes.bizBadgeColor,
																					}}
																					badgeContent={searchCount}
																				>
																					<IconLibrary.NotificationOutline />
																				</Badge>
																			</GridItem>
																			<GridItem xs={6} sm={6} md={12}>
																				Alerts
																			</GridItem>
																		</GridContainer>
																	</div>
																</Button>
																<Popper
																	id={
																		isPopperOpenBiz
																			? "simple-popover-biz"
																			: undefined
																	}
																	open={isPopperOpenBiz}
																	anchorEl={anchorElBiz}
																/>
															</ListItem>
														</Fragment>
													) : null}
													{bizId && bizId > 0 ? (
														<ListItem className={classes.listPadding}>
															<Button
																className={
																	bizId > 0
																		? classes.bizNavLink
																		: classes.navLink
																}
																onClick={() => {
																	setTimeout(() => {
																		dispatch(resetSelectBizPage());
																		history.push(
																			`/biz-page/${encryptData(auth.acctId)}`
																		);
																	}, 1000);
																}}
																fullWidth
																color="transparent"
															>
																<div>
																	<GridContainer
																		justifyContent="center"
																		className={classes.headerButton}
																	>
																		<GridItem xs={6} sm={6} md={12}>
																			<FontAwesomeIcon
																				icon={faSignOutAlt}
																				size={"2x"}
																			/>
																		</GridItem>
																		<GridItem xs={6} sm={6} md={12}>
																			Exit Page
																		</GridItem>
																	</GridContainer>
																</div>
															</Button>
														</ListItem>
													) : null}
												</Fragment>
											) : (
												<Fragment>
													<ListItem className={classes.listPadding}>
														<Link
															className={classes.textDeco}
															to="/login-page"
															color="transparent"
														>
															<Button
																className={classes.navLink}
																color="transparent"
															>
																<div
																	className={
																		location.pathname === `/login-page`
																			? classes.selectedIcon
																			: null
																	}
																>
																	<GridContainer
																		justifyContent="center"
																		className={classes.headerButton}
																	>
																		<GridItem xs={6} sm={6} md={12}>
																			<FontAwesomeIcon
																				icon={faSignInAlt}
																				size={"2x"}
																			/>
																		</GridItem>
																		<GridItem xs={6} sm={6} md={12}>
																			Sign In
																		</GridItem>
																	</GridContainer>

																	{/* 	<div className={classes.drawerButton}>
																<FontAwesomeIcon
																	icon={faSignInAlt}
																	size={"2x"}
																/>{" "}
																Sign In
															</div> */}
																</div>
															</Button>
														</Link>
													</ListItem>

													<ListItem className={classes.listPadding}>
														<Link
															className={classes.textDeco}
															to="/registration-page"
															color="transparent"
														>
															<Button
																className={classes.navButton}
																color="oppty"
																round
															>
																<b>Join Now</b>
															</Button>
														</Link>
													</ListItem>
												</Fragment>
											)}
										</List>
									</Hidden>
								) : null}
								:
							</CardBody>
						</Card>
					}
					changeColorOnScroll={{
						height: 200,
						color: bizId > 0 ? "oppty" : "white",
					}}
				/>
			</Fragment>
		</ClickAwayListener>
	);
};

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
	payload: state.auth.user,
	buildProfileFlag: state.auth.buildProfileFlag,
});

export default connect(mapStateToProps, null)(Header);
