import React from 'react';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import { useSelector, useDispatch } from 'react-redux';
import { getCandidateSelectJobPosts } from 'configurations/redux/jobManagementSlice.js';
import { Link, useHistory } from 'react-router-dom';

import defaultAvatar from 'assets/img/candidate_avatar.jpeg';

import searchPageStyle from 'assets/jss/material-kit-pro-react/views/pageStyle/SearchPage/searchPageStyle.js';
import searchJobPageStyle from 'assets/jss/material-kit-pro-react/views/pageStyle/SearchJobPage/searchJobPageStyle.js';

import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import SmallCardLoading from './SmallCardLoading.js';
import ResultJobModal from '../SearchJobPage/ResultJobModal.js';
import { saveOption } from 'configurations/redux/genericSearchSlice.js';
import NoResultCard from './NoResultCard';
import { decryptData } from 'configurations/configurations.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import SearchResultCard from '../SearchJobPage/SearchResultCard';

const useStyles = makeStyles(searchPageStyle);

export default function JobComponent({ match }) {
  const classes = useStyles();
  const jobStyle = makeStyles(searchJobPageStyle)();
  const dispatch = useDispatch();
  const userStatus = useSelector(state => state.auth.user);
  const searchResult = useSelector(state => state.searchJob.searchResult);
  const requestRecordSize = useSelector(state => state.searchJob.requestRecordSize);
  const isLoading = useSelector(state => state.searchJob.isLoading);
  const [jobViewModal, setJobViewModal] = React.useState(false);
  const [jobItem, setJobItem] = React.useState();
  const auth = useSelector(state => state.auth);

  const ProfileList =
    searchResult &&
    searchResult.length > 0 &&
    searchResult.map((item, index) => {
      return (
        <GridItem xs={12} sm={6} md={6} lg={4} xl={3} key={index}>
          <div style={{ marginTop: '-15px', marginBottom: '15px' }}>
            <SearchResultCard key={item.jobPostId} item={item} />
          </div>
        </GridItem>
        // <Card
        //   key={item.jobPostId}
        //   className={classes.cardFrame + ' ' + classes.cardClick}
        //   onClick={() => {
        //     setJobItem(item);
        //     getJobPost(item);
        //   }}
        // >
        //   <CardBody>
        //     <div className={classes.resultContainer}>
        //       <div className={classes.imgContainer}>
        //         <img src={item.profilePic ? item.profilePic : defaultAvatar} />
        //       </div>
        //       <div className={classes.descriptionContainer}>
        //         <div>
        //           <h4 className={classes.title}>{item.title}</h4>
        //           <h4 className={classes.description}>{item.companyName}</h4>
        //           <h4 className={classes.description}>{item.countryName}</h4>
        //           <h4 className={classes.description}>{item.industryName}</h4>
        //         </div>
        //       </div>
        //     </div>
        //   </CardBody>
        // </Card>
      );
    });

  const loadingFields = [];
  for (let i = 0; i < 3; i++) {
    loadingFields.push(
      <div key={i}>
        <SmallCardLoading />
      </div>
    );
  }

  React.useEffect(() => {
    if (match.params.type && auth.publicToken) {
      var type = match.params.type;
      var id = match.params.id;
      if (type == 'job' && id.length > 1) {
        const item = {
          jobPostId: decryptData(id),
        };
        getJobPost(item);
      }
    }
  }, [auth.publicToken]);

  React.useEffect(() => {
    if (match.params.type && auth.isAuthenticated) {
      var type = match.params.type;
      var id = match.params.id;
      if (type == 'job' && id.length > 1) {
        const item = {
          jobPostId: decryptData(id),
        };
        getJobPost(item);
      }
    }
  }, []);

  const onClose = () => {
    setJobViewModal(false);
  };

  const getJobPost = async item => {
    const requestProfile = {
      acctId: auth.isPublic ? 0 : userStatus.acctId,
      accessToken: auth.isPublic ? auth.publicToken : localStorage.getItem('token'),
      ipAddr: localStorage.getItem('ipAddr'), //await publicIp.v4(),
      jobPostId: item.jobPostId,
      item: null,
    };
    console.log(requestProfile);
    dispatch(getCandidateSelectJobPosts(requestProfile));
    setJobViewModal(true);
  };

  return (
    <Card className={classes.cardFrame}>
      <CardBody className={classes.cardBodyPadding}>
        <h4 className={classes.componentTitle}>Jobs</h4>
        {isLoading &&
          loadingFields.map(item => {
            return item;
          })}
        <div className={classes.mainContainer}>
          {/* {ProfileList} */}
          <GridContainer className={jobStyle.jobCardGrid}>{ProfileList}</GridContainer>
          {!isLoading && searchResult.length === 0 && <NoResultCard showImage={false} />}
        </div>
        {!isLoading && (
          <Link
            className={classes.textDeco}
            to={auth.isPublic ? `/public-search-page-job` : `/search-page-job`}
            color="transparent"
          >
            <Button
              onClick={() => {
                const response = {
                  option: auth.isPublic ? '/public-search-page-job' : '/search-page-job',
                };
                dispatch(saveOption(response));
              }}
              className={classes.buttonViewMore}
              fullWidth
            >
              See All Job Result
            </Button>
          </Link>
        )}
        {jobViewModal ? <ResultJobModal open={jobViewModal} onClose={onClose} item={jobItem} /> : null}
      </CardBody>
    </Card>
  );
}
