import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
	departmentViewRoadmap,
	viewRoadmapRoleByRoleId,
} from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Department_Trading from "assets/img/Career_Roadmap/Banking_Investment_Banking/Department_Trading_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentTrading() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Trading;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "t",
				shape: "poly",
				coords: [73,394,64,397,60,401,57,405,55,411,55,453,57,459,61,464,66,467,74,470,177,470,184,468,189,465,193,460,195,452,195,411,192,402,187,398,182,395,176,394,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "440",
				shape: "poly",
				coords: [284,393,278,395,275,398,271,403,270,410,270,457,272,462,275,467,279,470,287,472,473,471,483,469,487,465,490,461,491,455,492,412,490,404,487,399,481,396,474,394,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "441",
				shape: "poly",
				coords: [744,393,735,396,732,399,729,404,727,410,728,456,730,463,734,467,739,470,747,472,935,470,942,468,947,464,949,459,949,454,949,409,948,402,944,398,938,395,930,393,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "442",
				shape: "poly",
				coords: [1200,393,1191,396,1187,399,1185,404,1184,410,1184,456,1186,462,1189,466,1193,469,1201,471,1346,470,1353,469,1357,465,1359,461,1360,455,1360,406,1357,400,1353,397,1350,395,1344,393,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "443",
				shape: "poly",
				coords: [1611,394,1603,397,1600,400,1597,405,1596,410,1597,457,1599,463,1602,467,1607,469,1614,471,1760,470,1767,468,1770,464,1772,460,1773,454,1773,410,1771,402,1766,397,1763,395,1757,394,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "t") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo);
			dispatch(viewRoadmapRoleByRoleId(requestInfo));

			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
