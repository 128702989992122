import React from "react";
import { logout } from "configurations/redux/authSlice.js";
import { useSelector, useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.js";

import style from "assets/jss/material-kit-pro-react/modalStyle.js";
import { checkLogout } from "../../../configurations/redux/authSlice.js";

const useStyles = makeStyles(style);

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

const NoActivityStatusModal = () => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [activityStatusModal, setActivityStatusModal] = React.useState(true);

	const userStatus = useSelector((state) => state.auth.user);
	const isTokenExpired = useSelector((state) => state.auth.isTokenExpired);
	const isLogout = useSelector((state) => state.auth.isLogout);
	console.log(isTokenExpired, isLogout);

	dispatch(checkLogout());

	React.useEffect(() => {
		if (isTokenExpired) {
			setActivityStatusModal(true);
		} else {
			setActivityStatusModal(false);
		}
	}, [isTokenExpired]);

	React.useEffect(() => {
		if (isLogout) {
			setActivityStatusModal(true);
		} else {
			setActivityStatusModal(false);
		}
	}, [isLogout]);

	return (
		<React.Fragment>
			<Dialog
				classes={{
					root: classes.modalRoot,
					paper: classes.modalLoader,
				}}
				open={activityStatusModal}
				TransitionComponent={Transition}
				keepMounted
				// disableBackdropClick
				onClose={() => {
					setActivityStatusModal(false);
					dispatch(logout(userStatus && userStatus.acctId, 0));
				}}
				aria-labelledby="classic-modal-slide-title"
				aria-describedby="classic-modal-slide-description"
			>
				<DialogTitle
					id="classic-modal-slide-title"
					disableTypography
					className={classes.modalHeader}
				>
					<h4>
						<b>Session Expired</b>
					</h4>
				</DialogTitle>
				<DialogContent
					id="classic-modal-slide-description"
					className={classes.modalBody}
				>
					<DialogContentText id="alert-dialog-slide-description">
						OppTy cannot find your current activity! Please Login Again to use
						OppTy.
					</DialogContentText>
					{/* <h6>OppTy cannot find your current activity!</h6>
					<h6>Please Login Again to use OppTy.</h6> */}
				</DialogContent>
				<DialogActions className={classes.modalFooter}>
					<Button
						onClick={() => {
							dispatch(logout(userStatus && userStatus.acctId, 0));
						}}
						color="opptyWrap"
						round
					>
						Login
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
};

export default NoActivityStatusModal;
