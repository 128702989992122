import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { departmentViewRoadmap, viewRoadmapRoleByRoleId } from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";
import ImageMapper from "react-image-mapper";
import Department_Hedge_Fund from "assets/img/Career_Roadmap/Finance/Department_Hedge_Fund_2.jpg";
import RoleInfoModal from "../RoleInfoModal.js";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentHedgeFund() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Hedge_Fund;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "hf",
				shape: "poly",
				coords: [75,350,67,354,63,357,60,360,58,364,57,370,57,423,59,430,62,435,67,439,73,441,160,442,169,441,174,439,180,436,183,431,186,423,186,372,184,363,179,356,175,353,168,351,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "t",
				shape: "poly",
				coords: [219,505,208,505,203,508,199,510,197,515,195,520,195,567,196,573,198,576,203,581,208,582,499,582,506,581,509,579,511,577,514,572,516,566,515,521,513,515,511,511,508,508,504,506,499,505,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "33",
				shape: "poly",
				coords: [208,355,203,358,199,361,197,365,195,370,196,419,197,425,201,430,210,433,345,433,350,431,353,427,356,422,358,416,357,370,355,362,350,358,346,356,340,355,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "34",
				shape: "poly",
				coords: [572,359,566,360,562,363,559,367,556,374,556,418,557,426,559,430,563,433,568,436,706,436,712,433,715,432,717,427,717,420,718,373,717,368,713,363,709,361,704,359,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "35",
				shape: "poly",
				coords: [972,360,963,360,959,362,957,364,954,367,952,373,952,422,953,430,957,433,963,436,1097,438,1104,436,1108,432,1111,427,1113,422,1114,374,1112,369,1108,365,1103,362,1098,360,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "36",
				shape: "poly",
				coords: [1349,360,1337,362,1333,363,1330,366,1327,370,1325,376,1324,419,1325,428,1328,432,1332,436,1338,438,1471,438,1479,435,1482,433,1485,429,1487,423,1487,376,1485,369,1483,366,1478,363,1472,361,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "37",
				shape: "poly",
				coords: [1687,359,1677,360,1672,362,1669,366,1667,370,1665,376,1666,423,1668,428,1671,432,1675,435,1683,436,1810,436,1823,435,1827,433,1830,431,1833,427,1835,421,1834,369,1831,365,1827,362,1823,360,1818,359,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "hf") {
			dispatch(departmentViewRoadmap(null));
		} else if (area.name === "t") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo)
			dispatch(viewRoadmapRoleByRoleId(requestInfo));
			
			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1"); 
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// console.log(coords.x + "," + coords.y + ",");
		// navigator.clipboard.writeText(coords.x + "," + coords.y + ",");
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}

	function enterArea(area) {
		console.log(area);
	}

	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
				onMouseEnter={(area) => enterArea(area)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
