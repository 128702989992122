import {
	container,
	defaultFont,
	primaryColor,
	harmfulColor,
	infoColor,
	successColor,
	warningColor,
	dangerColor,
	roseColor,
	transition,
	boxShadow,
	drawerWidth,
	blackColor,
	whiteColor,
	grayColor,
	lightBlueColor,
	greenColor,
	blueColor,
	opptyDesignColor,
	hexToRgb,
} from "assets/jss/material-kit-pro-react.js";

const headerStyle = (theme) => ({
	appBar: {
		display: "flex",
		border: "0",
		borderRadius: "3px",
		padding: "0.625rem 0",
		marginBottom: "20px",
		color: grayColor[15],
		width: "100%",
		backgroundColor: whiteColor,
		boxShadow:
			"0 4px 18px 0px rgba(" +
			hexToRgb(blackColor) +
			", 0.12), 0 7px 10px -5px rgba(" +
			hexToRgb(blackColor) +
			", 0.15)",
		transition: "all 150ms ease 0s",
		alignItems: "center",
		flexFlow: "row nowrap",
		justifyContent: "flex-start",
		position: "relative",
	},
	bizAppBar: {
		display: "flex",
		border: "0",
		borderRadius: "3px",
		padding: "0.625rem 0",
		marginBottom: "20px",
		color: grayColor[15],
		width: "100%",
		backgroundColor: opptyDesignColor,
		boxShadow:
			"0 4px 18px 0px rgba(" +
			hexToRgb(blackColor) +
			", 0.12), 0 7px 10px -5px rgba(" +
			hexToRgb(blackColor) +
			", 0.15)",
		transition: "all 150ms ease 0s",
		alignItems: "center",
		flexFlow: "row nowrap",
		justifyContent: "flex-start",
		position: "relative",
	},
	logoImage: {
		height: "60px",
		[theme.breakpoints.down("sm")]: {
			height: "30px",
		},
	},
	absolute: {
		position: "absolute",
		top: "auto",
	},
	fixed: {
		position: "fixed",
	},
	myContainer: {
		position: "relative",
		display: "flex",
		gap: "15px",
		[theme.breakpoints.down("sm")]: {
			gap: "5px",
		},
		justifyContent: "space-between",
		alignItems: "center",
		flexWrap: "nowrap",
		marginLeft: "auto",
		marginRight: "auto",
		paddingLeft: "16px",
		paddingRight: "16px",
		width: "100%",
		minHeight: "56px",
	},
	"@media(min-width: 1200px)": {
		myContainer: {
			maxWidth: "1300px",
		},
	},
	"@media (min-width: 992px)": {
		myContainer: {
			maxWidth: "100%",
		},
	},
	"@media (min-width: 768px)": {
		myContainer: {
			maxWidth: "100%",
		},
	},
	"@media (min-width: 600px)": {
		myContainer: {
			paddingLeft: "24px",
			paddingRight: "24px",
			minHeight: "64px",
		},
	},
	"@media (min-width: 576px)": {
		myContainer: {
			maxWidth: "100%",
		},
	},
	"@media (min-width: 0px) and (orientation: landscape)": {
		myContainer: {
			minHeight: "48px",
		},
	},
	container: {
		...container,
		"@media (min-width: 576px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 768px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 992px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 1200px)": {
			maxWidth: "1200px",
		},
		"@media (min-width: 1400px)": {
			maxWidth: "80vw",
		},
		minHeight: "50px",
		alignItems: "center",
		justifyContent: "space-between",
		display: "flex",
		flexWrap: "nowrap",
	},
	title: {
		letterSpacing: "unset",
		"&,& a": {
			...defaultFont,
			minWidth: "unset",
			lineHeight: "30px",
			fontSize: "22px",
			borderRadius: "3px",
			textTransform: "none",
			whiteSpace: "nowrap",

			color: "inherit",
			"&:hover,&:focus": {
				color: "inherit",
				background: "transparent",
			},
			padding: "none !important",
		},
	},
	appResponsive: {
		margin: "20px 10px",
		marginTop: "0px",
	},
	primary: {
		backgroundColor: primaryColor[0],
		color: whiteColor,
		boxShadow:
			"0 4px 20px 0px rgba(" +
			hexToRgb(blackColor) +
			", 0.14), 0 7px 12px -5px rgba(" +
			hexToRgb(primaryColor[0]) +
			", 0.46)",
	},

	info: {
		backgroundColor: infoColor[0],
		color: whiteColor,
		boxShadow:
			"0 4px 20px 0px rgba(" +
			hexToRgb(blackColor) +
			", 0.14), 0 7px 12px -5px rgba(" +
			hexToRgb(infoColor[0]) +
			", 0.46)",
	},
	success: {
		backgroundColor: successColor[0],
		color: whiteColor,
		boxShadow:
			"0 4px 20px 0px rgba(" +
			hexToRgb(blackColor) +
			", 0.14), 0 7px 12px -5px rgba(" +
			hexToRgb(successColor[0]) +
			", 0.46)",
	},

	warning: {
		backgroundColor: warningColor[0],
		color: whiteColor,
		boxShadow:
			"0 4px 20px 0px rgba(" +
			hexToRgb(blackColor) +
			", 0.14), 0 7px 12px -5px rgba(" +
			hexToRgb(warningColor[0]) +
			", 0.46)",
	},
	harmful: {
		backgroundColor: harmfulColor[0],
		color: whiteColor,
		boxShadow:
			"0 4px 20px 0px rgba(" +
			hexToRgb(blackColor) +
			", 0.14), 0 7px 12px -5px rgba(" +
			hexToRgb(harmfulColor[0]) +
			", 0.46)",
	},
	lightBlue: {
		backgroundColor: lightBlueColor[0],
		color: whiteColor,
		boxShadow:
			"0 4px 20px 0px rgba(" +
			hexToRgb(blackColor) +
			", 0.14), 0 7px 12px -5px rgba(" +
			hexToRgb(lightBlueColor[0]) +
			", 0.46)",
	},
	green: {
		backgroundColor: greenColor[0],
		color: whiteColor,
		boxShadow:
			"0 4px 20px 0px rgba(" +
			hexToRgb(blackColor) +
			", 0.14), 0 7px 12px -5px rgba(" +
			hexToRgb(greenColor[0]) +
			", 0.46)",
	},
	blue: {
		backgroundColor: blueColor[0],
		color: whiteColor,
		boxShadow:
			"0 4px 20px 0px rgba(" +
			hexToRgb(blackColor) +
			", 0.14), 0 7px 12px -5px rgba(" +
			hexToRgb(blueColor[0]) +
			", 0.46)",
	},
	danger: {
		backgroundColor: dangerColor[0],
		color: whiteColor,
		boxShadow:
			"0 4px 20px 0px rgba(" +
			hexToRgb(blackColor) +
			", 0.14), 0 7px 12px -5px rgba(" +
			hexToRgb(dangerColor[0]) +
			", 0.46)",
	},
	rose: {
		backgroundColor: roseColor[0],
		color: whiteColor,
		boxShadow:
			"0 4px 20px 0px rgba(" +
			hexToRgb(blackColor) +
			", 0.14), 0 7px 12px -5px rgba(" +
			hexToRgb(roseColor[0]) +
			", 0.46)",
	},
	transparent: {
		backgroundColor: "transparent !important",
		boxShadow: "none",
		paddingTop: "25px",
		color: whiteColor,
	},
	dark: {
		color: whiteColor,
		backgroundColor: grayColor[9] + " !important",
		boxShadow:
			"0 4px 20px 0px rgba(" +
			hexToRgb(blackColor) +
			", 0.14), 0 7px 12px -5px rgba(" +
			hexToRgb(grayColor[9]) +
			", 0.46)",
	},
	white: {
		border: "0",
		marginBottom: "20px",
		color: grayColor[15],
		backgroundColor: whiteColor + " !important",
		boxShadow:
			"0 4px 18px 0px rgba(" +
			hexToRgb(blackColor) +
			", 0.12), 0 7px 10px -5px rgba(" +
			hexToRgb(blackColor) +
			", 0.15)",
	},
	oppty: {
		border: "0",
		marginBottom: "20px",
		color: grayColor[15],
		backgroundColor: opptyDesignColor[0] + " !important",
		boxShadow:
			"0 4px 18px 0px rgba(" +
			hexToRgb(blackColor) +
			", 0.12), 0 7px 10px -5px rgba(" +
			hexToRgb(blackColor) +
			", 0.15)",
	},
	drawerPaper: {
		border: "none",
		bottom: "0",
		transitionProperty: "top, bottom, width",
		transitionDuration: ".2s, .2s, .35s",
		transitionTimingFunction: "linear, linear, ease",
		width: drawerWidth,
		...boxShadow,
		position: "fixed",
		display: "block",
		top: "0",
		height: "100vh",
		right: "0",
		left: "auto",
		visibility: "visible",
		// overflowY: "visible",
		borderTop: "none",
		textAlign: "left",
		paddingRight: "0px",
		paddingLeft: "0",
		...transition,
	},
	hidden: {
		width: "100%",
	},
	collapse: {
		[theme.breakpoints.up("md")]: {
			display: "flex !important",
			MsFlexPreferredSize: "auto",
			flexBasis: "auto",
		},
		WebkitBoxFlex: "1",
		MsFlexPositive: "1",
		flexGrow: "1",
		WebkitBoxAlign: "center",
		MsFlexAlign: "center",
		alignItems: "center",
	},
	closeButtonDrawer: {
		position: "absolute",
		right: "8px",
		top: "9px",
		zIndex: "1",
	},
	navButton: {
		position: "relative",
		fontWeight: "400",
		fontSize: "14px",
		textTransform: "uppercase",
		lineHeight: "20px",
		textDecoration: "none",
		margin: "0px",
		display: "inline-flex",
		[theme.breakpoints.down("sm")]: {
			width: "calc(100% - 30px)",
			marginLeft: "15px",
			marginBottom: "5px",
			marginTop: "5px",
			textAlign: "left",
			"& > span:first-child": {
				justifyContent: "flex-start",
			},
		},
		/* "& $icons": {
			marginRight: "3px",
		}, */
	},
	textDeco: {
		textDecoration: "none",
		color: "inherit",
	},
	headerShadow: {
		boxShadow: "none",
		borderBottom: "2px solid #D0DDE7",
		borderRadius: "0",
		// zIndex: "99999"
	},
	popOverContainer: {},
	popOver: {
		/* 	width: "91vw",
		height: "80vh", */
	},
	popOverMenu: {
		display: "flex",
		flexDirection: "column",
	},
	/* popOverHeaders: {
		color: "#92929D",
		fontWeight: "bolder",
	}, */
	badgeColor: {
		backgroundColor: opptyDesignColor,
		color: "white",
		top: "5px",
		right: "0px",
		[theme.breakpoints.down("md")]: {
			right: "5px",
		},
	},
	headerButton: {
		"& svg": {
			fontSize: "37px",
			color: "grey",
			/* 	margin: "0 0 0 10px", */

			width: "25px",
			height: "25px",
		},
		padding: "12px",
		fontSize: "14px",
		textTransform: "capitalize",
		display: "flex",
		flexDirection: "row-reverse",
		// margin: "0 0 4px",
		"& .MuiBadge-colorSecondary": {
			backgroundColor: "#6F52ED",
			top: "15px",
			right: "10px",
		},
	},
	headerButton2: {
		"& svg": {
			fontSize: "45px",
			color: "grey",
			/* margin: "0 0 0 20px", */

			width: "25px",
			height: "25px",
		},
		padding: "12px",
		fontSize: "14px",
		textTransform: "capitalize",
		display: "flex",
		"& .MuiBadge-colorSecondary": {
			backgroundColor: "#6F52ED",
			top: "15px",
			right: "10px",
		},
	},
	rightHeader: {
		display: "flex",
		alignItems: "center",
	},
	badgeInbox: {
		backgroundColor: "red",
		width: "19px",
		height: "19px",
		borderRadius: "40px",
		textAlign: "center",
		position: "fixed",
		color: "white",
		right: "11px",
		"@media(min-width: 484px)": {
			right: "20px",
		},
	},
	badgeNotif: {
		backgroundColor: "#6F52ED",
		width: "19px",
		height: "19px",
		borderRadius: "40px",
		textAlign: "center",
		position: "fixed",
		color: "white",
		margin: "0 -4px",
	},
	searchButton: {
		border: "solid 1px #6F52ED",
		borderRadius: "10px",
		backgroundColor: "#6F52ED",
		width: "35px",
		height: "34px",
		marginLeft: "20px",

		"& svg": {
			width: "14px",
			height: "14px",
		},
	},
});

export default headerStyle;
