import React, { Fragment, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Header from "views/Component/Header.js";
import LeftSidebar from "views/Component/LeftSidebar.js";
import ProfileHeader from "./ProfileViewSections/ProfileHeader";
import ActionButtons from "./ProfileViewSections/ActionButtons";
import ProfileStrengthCard from "../../Component/ProfileView/ProfileStrengthCard";
import ProfileViewAboutMe from "../../Component/ProfileView/ProfileViewAboutMe";
import MyActivity from "../../Component/ProfileView/MyActivity/MyActivity";
import WorkExperience from "../../Component/ProfileView/WorkExperience/WorkExperience";
import Education from "../../Component/ProfileView/Education/Education";
import EditProfile from "../../Component/ProfileView/EditProfile/EditProfile";
import RightSideBarUserList from "views/Component/RightSideBarUserList.js";
import Footer from "views/Component/Footer.js";
import Hidden from "@material-ui/core/Hidden";
import { getUserPsychometricResults } from "../../../configurations/redux/userSlice";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import { Box, Button, makeStyles, Modal, Typography } from "@material-ui/core";
import Avatar from "@mui/material/Avatar";
import Skeleton from "@mui/material/Skeleton";

import CustomAlertDialog from "../../../components/CustomDialog/CustomAlertDialog.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";

import axios from "axios";
import Cookies from "js-cookie";

import { errorCleanUp } from "configurations/redux/authSlice.js";
import {
	getProfiles,
	getInterestedIndustries,
	createConnect,
} from "configurations/redux/userSlice.js";
import {
	activateMasterCode,
	cleanMasterCode,
} from "configurations/redux/masterFunctionSlice.js";
import {
	hashKey,
	decryptData,
	instanceUrl,
	header,
	gaPageView,
	encryptData
} from "configurations/configurations.js";

import dp from "assets/img/faces/marc.jpg";
import cannotMessage from "assets/img/cannot-message.png";

import profileViewStyles from "assets/jss/material-kit-pro-react/views/pageStyle/ProfileViewPage/profileViewStyles";
import EditModal from "views/Component/ProfileView/EditProfile/EditModal";
import {
	activateEditType,
	deactivateEditType,
} from "configurations/redux/editProfileModalSlice";

import InterestIndustry from "views/Component/ProfileView/InterestIndustry.js";
import SkillsAndAttributes from "views/Component/ProfileView/SkillsAndAttributes";
import LoadingSkeleton from "./ProfileViewSections/LoadingSkeleton.js";
import AuthorTestimonialComponent from "../TestimonialsPage/AuthorTestimonialComponent";
import Testimonial from "views/Component/ProfileView/Testimonial";
import NoActivityStatusModal from "../NoActivityStatusModal/NoActivityStatusModal";
import MetatagHelmet from "views/Component/MetatagHelmet";
import BlockAccount from "./BlockAccount.js";
import ProfileStrengthSection from "../ProfilePage/ProfileSection/ProfileStrengthSection";
import VidImg from 'assets/images/video.png';
import DocImg from 'assets/images/doc.png';
import CvImg from 'assets/images/cv1.png';

// Research how to combine multiple style files in makeStyles

const useStyles = makeStyles(profileViewStyles);

const ProfileViewPage = ({ match }) => {
	console.log('-----match------');
	console.log(match);
	const classes = useStyles();
	const styles = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	var isKicked = Cookies.get("isKicked");

	const auth = useSelector((state) => state.auth);
	const currUser = useSelector((state) => state.auth.user);
	const isError = useSelector((state) => state.auth.isError);
	const isVerified = useSelector((state) => state.auth.isVerified);
	const isResend = useSelector((state) => state.auth.isResend);
	const buildProfileFlag = useSelector((state) => state.auth.buildProfileFlag);
	const isViewed = useSelector((state) => state.user.isViewed);
	var user = useSelector((state) => state.user);
	const isLoading = useSelector((state) => state.user.isLoading);
	const blockCount = useSelector((state) => state.user.blockCount);
	const isUpdated = useSelector((state) => state.user.isUpdated);
	const allResults = useSelector((state) => state.user.psychometricUserResults);
	const [myself, setMyself] = useState(false);
	const [emailSend, setEmailSend] = useState(false);
	const [userData, setUserData] = useState(null);
	const [connStatus, setConnStatus] = useState(null);

	const [headerTab, setHeaderTab] = useState("myActivity");
	const [isEditProfileOpen, setIsEditProfileOpen] = useState(false);
	const [editOption, setEditOption] = useState("");
	const [isCannotMessageModalOpen, setIsCannotMessageModalOpen] = useState(
		false
	);
	const [createPost, setCreatePost] = React.useState(false);
	const location = useLocation();



	const requestProfile = {
		acctId: currUser.acctId,
		userId: decryptData(match.params.acctId),
		ipAddr: localStorage.getItem("ipAddr"), //clientIp,
		accessToken: localStorage.getItem("token"),
	};
	useEffect(() => {
		gaPageView(window.location.pathname);
		history.replace(match.url);
		if (currUser.acctId === decryptData(match.params.acctId)) {
			setMyself(true);
		}

		if (isError) {
			dispatch(errorCleanUp());
		}
		dispatch(getProfiles(requestProfile));
		dispatch(cleanMasterCode());
		dispatch(deactivateEditType());
		const req = {
			acctId: user.acctId,
			accessToken: localStorage.getItem("token"),
			email: user.email,
		};

		setTimeout(() => {
			if (buildProfileFlag === 0 && !isViewed) {
				const response = {
					type: "EDIT_PROFILE",
					tabType: "GENERAL_PROFILE",
				};

				dispatch(activateEditType(response));
			}
		}, 500);
	}, [match.params.acctId]);

	React.useEffect(() => {
		let mounted = true;
		let CancelToken = axios.CancelToken;
		const source = CancelToken.source();

		return () => {
			mounted = false;
			source.cancel("cancelling in tabs action switch");
		};
	}, []);

	React.useEffect(() => {
		let CancelToken = axios.CancelToken;
		const source = CancelToken.source();

		return () => {
			source.cancel();
		};
	}, []);

	React.useEffect(() => {
		if (isError) {
			dispatch(errorCleanUp());
		}

		callResults();
	}, []);

	function callResults() {
		const request = {
			email: user.email,
			acctId: user.acctId,
			accessToken: localStorage.getItem('token')
		};
		dispatch(getUserPsychometricResults(request));
	}

	return (
		<>
			<Header color={"transparent"} loggedInStatus={false} />
			<div id="ProfileSocialFeed" className={styles.body}>
				<div id="pageHeader" className={styles.pageHeader}>
					<div className={styles.container} id="socialFeedPage">
						<GridContainer spacing={2} justifyContent="center">
							<Hidden mdDown>
								<GridItem md={2} lg={2}>
									<LeftSidebar />
								</GridItem>
							</Hidden>
							<GridItem sm={12} md={12} lg={8}>
								{isLoading ? (
									<LoadingSkeleton />
								) : isUpdated ? (
									blockCount === 0 ? (
										<GridContainer spacing={2}>
											<GridItem md={12}>
												<ProfileHeader
													backgroundPic={user.backgroundPic}
													profilePic={user.profilePic}
													firstName={user.profileRecord?.firstName}
													middleName={user.profileRecord?.middleName}
													surname={user.profileRecord?.surname}
													headline={user.profileRecord?.headline}
													loadTab={setHeaderTab}
												/>
												<MetatagHelmet
													metaTag={{
														title: user.profileRecord
															? user.profileRecord?.firstName +
															" " +
															user.profileRecord?.surname
															: "Profile",
														description: user.profileRecord
															? user.profileRecord?.headline
															: "Job Post",
														url: window.location.href,
													}}
												/>
											</GridItem>
											<GridItem md={4}>
												{isViewed && (
													<ActionButtons
														setIsCannotMessageModalOpen={
															setIsCannotMessageModalOpen
														}
														match={match}
													/>
												)}
												{isUpdated && !isLoading && isViewed ? null : (
													<ProfileStrengthSection />
												)}
												<ProfileViewAboutMe
													match={match}
													aboutMe={user.profileRecord?.aboutMe}
													website={user.profileRecord?.website}
													countryId={user.profileRecord?.countryId}
													locationId={user.profileRecord?.locationId}
													areaCodeId={user.profileRecord?.areaCodeId}
													experienceRecords={
														user.experienceRecords &&
															user.experienceRecords.length
															? user.experienceRecords[0]
															: null
													}
													educationRecords={
														user.educationRecords &&
															user.educationRecords?.length
															? user.educationRecords[0]
															: null
													}
													onEdit={() => {
														setEditOption("generalProfile");
														setIsEditProfileOpen(true);
														const response = {
															type: "EDIT_PROFILE",
															tabType: "GENERAL_PROFILE",
														};

														dispatch(activateEditType(response));
													}}
													style={{ marginTop: "15px !important" }}
												/>
												{
													currUser.acctId === decryptData(match.params.acctId) &&
													<Typography
														component="div"
														className={styles.interestedIndustriesBody}
													>
														<Typography
															component="div"
															className={styles.cardHeader}
														>
															<Typography
																component="h2"
																className={styles.cardTitle}
															>
																Your Psychometric Test
															</Typography>
														</Typography>

														<hr className={styles.divider} />



														<Typography
															component="div"
															className={styles.cardMain}
														>
															<Typography
																component="div"
																className={styles.industryNamesContainer}
															>
																{allResults && allResults.resultType.totalCount > 0 &&
																	<Typography className='retakeResultBtns'>
																		<Button href={`/psychometric-result-view/${encryptData(currUser.acctId)}`}>Retake Test</Button>
																		<Button className='seeResult' href={`/psychometric-result-view/${encryptData(currUser.acctId)}`}>See my Results</Button>
																	</Typography>}

																{(allResults && allResults.resultType.totalCount == 0) && <Typography className='retakeResultBtns'>
																	<Button className='takeTest' style={{ width: '95%' }} href={`/psychometric-result-view/${encryptData(currUser.acctId)}`}>Take The Test</Button>
																</Typography>}

															</Typography>
														</Typography>

													</Typography>
												}

												<Typography
													component="div"
													className={styles.interestedIndustriesBody}
												>
													<Typography
														component="div"
														className={styles.cardHeader}
													>
														<Typography
															component="h2"
															className={styles.cardTitle}
														>
															Industries Interested In
														</Typography>

														{!isViewed && (
															<Button
																className={styles.cardTitleBtn}
																onClick={() => {
																	const response = {
																		type: "EDIT_PROFILE",
																		tabType: "GENERAL_PROFILE",
																		extension: {
																			type: "EDIT_INTEREST_INDUSTRY",
																		},
																	};

																	dispatch(activateEditType(response));
																}}
															>
																Edit
															</Button>
														)}
													</Typography>

													<hr className={styles.divider} />

													<Typography
														component="div"
														className={styles.cardMain}
													>
														<Typography
															component="div"
															className={styles.industryNamesContainer}
														>
															<InterestIndustry />
														</Typography>
													</Typography>
												</Typography>
												{ /*
												<Typography
													component="div"
													className={styles.interestedIndustriesBody}
												>
													<Typography
														component="div"
														className={styles.cardHeader}
													>
														<Typography
															component="h2"
															className={styles.cardTitle}
														>
															Your CV, Video, and Documents
														</Typography>

														{!isViewed && (
															<Button
																className={styles.cardTitleBtn}
																onClick={() => {
																	const response = {
																		type: "EDIT_PROFILE",
																		tabType: "GENERAL_PROFILE",
																		extension: {
																			type: "EDIT_INTEREST_INDUSTRY",
																		},
																	};

																	dispatch(activateEditType(response));
																}}
															>
																Manage
															</Button>
														)}
													</Typography>
												</Typography>
								*/}


												<Typography
													component="div"
													className={styles.interestedIndustriesBody}
												>
													<Typography
														component="div"
														className={styles.cardHeader}
													>
														<Typography
															component="h2"
															className={styles.cardTitle}
														>
															Your CV, Video, and Documents
														</Typography>

														{!isViewed && (
															<Link to={`/upload-attachment/video/${encryptData(currUser.acctId)}`}
																className={styles.cardTitleBtn}

															>
																Manage
															</Link>
														)}
													</Typography>

													<hr className={styles.divider} />

													<Typography
														component="div"
														className={styles.cardMain}
													>
														<Typography
															component="div"
															className={styles.industryNamesContainer}
														>
															<Link to={`/upload-attachment/video/${encryptData(currUser.acctId)}`} ><span class="appSpan cvspan">   <img src={VidImg} alt="" /></span></Link>
															<Link to={`/upload-attachment/document/${encryptData(currUser.acctId)}`} ><span class="appSpan cvspan"><img src={DocImg} alt="" /></span></Link>
															<Link to={`/upload-attachment/resume/${encryptData(currUser.acctId)}`} ><span class="appSpan cvspan"><img src={CvImg} alt="" /></span></Link>
														</Typography>
													</Typography>
												</Typography>

												<Typography
													component="div"
													className={styles.interestedIndustriesBody}
												>
													<Typography
														component="div"
														className={styles.cardHeader}
													>
														<Typography
															component="h2"
															className={styles.cardTitle}
														>
															Skills and Attributes
														</Typography>

														{!isViewed && (
															<Button
																className={styles.cardTitleBtn}
																onClick={() => {
																	const response = {
																		type: "EDIT_PROFILE",
																		tabType: "GENERAL_PROFILE",
																		extension: {
																			type: "EDIT_SKILLS_ATTRIBUTES",
																		},
																	};

																	dispatch(activateEditType(response));
																}}
															>
																Edit
															</Button>
														)}
													</Typography>

													<hr className={styles.divider} />

													<Typography
														component="div"
														className={styles.cardMain}
													>
														<Typography
															component="div"
															className={styles.industryNamesContainer}
														>
															<SkillsAndAttributes />
														</Typography>
													</Typography>
												</Typography>

												<Testimonial match={match} />
											</GridItem>
											<GridItem md={8}>
												{headerTab === "myActivity" && (
													<MyActivity
														match={match}
														createPostClick={createPost}
														setCreatePostClick={setCreatePost}
													/>
												)}
												{headerTab === "workExperience" && !isLoading && (
													<WorkExperience />
												)}
												{headerTab === "education" && !isLoading && (
													<Education />
												)}
											</GridItem>
										</GridContainer>
									) : (
										//create for blocked account

										<BlockAccount />
									)
								) : (
									<NoActivityStatusModal />
								)}
							</GridItem>

							<Hidden mdDown>
								<GridItem md={2}>
									<RightSideBarUserList
										createPostClick={setCreatePost}
										isOwner={myself}
									/>
								</GridItem>
							</Hidden>
						</GridContainer>
					</div >
				</div >

				<EditModal />

				<Modal
					open={isCannotMessageModalOpen}
					onClose={() => setIsCannotMessageModalOpen(false)}
				>
					<Box className={styles.cannotMessageModalBox}>
						<Button onClick={() => setIsCannotMessageModalOpen(false)}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="14"
								height="14"
								viewBox="0 0 14 14"
							>
								<path
									id="Path"
									d="M14,1.41,12.59,0,7,5.59,1.41,0,0,1.41,5.59,7,0,12.59,1.41,14,7,8.41,12.59,14,14,12.59,8.41,7Z"
								/>
							</svg>
						</Button>

						<img src={cannotMessage} alt="" />
						<h2 className={styles.dialogTitle}>
							You need to be connected to send a message
						</h2>
						<p className={styles.dialogText}>
							Please send a connection request to{" "}
							{userData?.profileRecord?.firstName}.
						</p>
					</Box>
				</Modal>

				{/* 			<CustomAlertDialog
					confirmDialog={connectDialog}
					setConfirmDialog={setConnectDialog}
				/>
				<CustomAlertDialog
					confirmDialog={cancelConnectionDialog}
					setConfirmDialog={setCancelConnectionDialog}
				/>
				<CustomAlertDialog
					confirmDialog={connectedDialog}
					setConfirmDialog={setConnectedDialog}
				/>
				<CustomAlertDialog
					confirmDialog={blockDialog}
					setConfirmDialog={setBlockDialog}
				/>
				<CustomAlertDialog
					confirmDialog={reportDialog}
					setConfirmDialog={setReportDialog}
				/> */}

				<Footer />
			</div >
		</>
	);
};

export default ProfileViewPage;
