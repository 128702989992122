import React, { useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

// core components
import Button from "components/CustomButtons/Button.js";

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import Close from "@material-ui/icons/Close";

import profileViewActivityPostCardStyles from "../../../../assets/jss/material-kit-pro-react/views/componentStyle/profileView/myActivity/profileViewActivityPostCardStyles.js";
import profileViewActivityPollCardStyles from "../../../../assets/jss/material-kit-pro-react/views/componentStyle/profileView/myActivity/profileViewActivityPollCardStyles.js";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/LandingPageFeed/CommentStyle.js";
import style from "assets/jss/material-kit-pro-react/modalStyle.js";
import IconLibrary from "views/Component/IconLibrary.js";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { getPollResultUserList } from "configurations/redux/socialFeedSlice.js";
import defaultAvatar from "assets/img/candidate_avatar.jpeg";
import { Link } from "react-router-dom";
import { instanceUrl, encryptData } from "configurations/configurations.js";
import ImageGallery from "react-image-gallery";
import GridItem from "../../../../components/Grid/GridItem.js";
import GridContainer from "../../../../components/Grid/GridContainer.js";
import ProfileViewPopupModal from "./ProfileViewPopupModal";

const useStyles = makeStyles(profilePageStyle);
const useStylesPost = makeStyles(profileViewActivityPostCardStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

const PublicMediaModal = ({ open, onClose, post }) => {
	// console.log(pictureSource);
	const classes = useStyles();
	const classesPost = useStylesPost();
	const [value, setValue] = React.useState(0);
	const dispatch = useDispatch();
	const popupResult = useSelector((state) => state.socialFeed.popupResult);

	useEffect(() => {
		console.log(popupResult);
	}, [popupResult]);

	const modalResize = () => {
		var modalResize = document.getElementById("pollResultUser");
		if (modalResize) {
			modalResize.style.maxHeight = "440px";
			var maxSize = window.innerHeight - 74 - 80 - 70;
			if (maxSize < 0) maxSize = 0;
			modalResize.style.height = maxSize + "px";
		}
	};

	React.useEffect(() => {
		if (open) {
			if (document.readyState === "complete") {
				setTimeout(() => {
					modalResize();
				}, 500);
			}
			window.addEventListener("resize", modalResize);
			return function cleanup() {
				window.removeEventListener("resize", modalResize);
			};
		}
	}, [open]);

	return (
		<React.Fragment>
			<Dialog
				classes={{
					root: classes.modalRoot,
					paper: classes.modalCommentEdit + " " + classes.modal,
				}}
				open={open}
				// TransitionComponent={Transition}
				// keepMounted
				// disableBackdropClick
				onClose={(event, reason) => {
					if (reason !== "backdropClick") {
						onClose(event, reason);
					}
				}}
				aria-labelledby="classic-modal-slide-title"
				aria-describedby="classic-modal-slide-description"
			>
				<DialogTitle
					id="classic-modal-slide-title"
					disableTypography
					className={classes.modalTitleEdit}
				>
					<Button
						simple
						className={classes.modalCloseButton}
						key="close"
						aria-label="Close"
						onClick={onClose}
					>
						<IconLibrary.Close />
					</Button>
				</DialogTitle>
				<DialogContent
					id="classic-modal-slide-description"
					className={classes.modalBody}
				>
					<div className={classesPost.imageGalleryMargin}>
						{popupResult && popupResult.postTypes ? (
							<ImageGallery
								items={popupResult.postTypes}
								showFullscreenButton={false}
								showPlayButton={false}
								lazyLoad={true}
							/>
						) : null}
					</div>
				</DialogContent>
				<div className={classes.modalFooter}></div>
			</Dialog>
		</React.Fragment>
	);
};
const mapStateToProps = (state) => ({
	// userStatus: state.auth.user,
	// image: state.user.image,
});
export default connect(mapStateToProps, null)(PublicMediaModal);
