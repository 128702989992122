import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
	departmentViewRoadmap,
	viewRoadmapRoleByRoleId,
} from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Industry_Mechanical_Engineering from "assets/img/Career_Roadmap/Mechanical_Engineering/Industry_Mechanical_Engineering_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function IndustryMechanicalEngineering() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Industry_Mechanical_Engineering;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "me",
				shape: "poly",
				coords: [83, 351, 76, 354, 72, 357, 68, 361, 66, 367, 65, 423, 68, 430, 71, 436, 77, 439, 84, 442, 176, 442, 183, 439, 188, 434, 192, 430, 194, 423, 194, 370, 192, 362, 187, 356, 181, 353, 175, 351,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "306",
				shape: "poly",
				coords: [228, 356, 217, 358, 215, 361, 212, 366, 211, 371, 211, 419, 213, 424, 216, 428, 222, 432, 230, 434, 355, 433, 365, 431, 369, 428, 372, 423, 374, 417, 374, 372, 372, 364, 368, 360, 361, 357, 356, 355,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "307",
				shape: "poly",
				coords: [700, 356, 694, 358, 691, 361, 688, 366, 686, 373, 687, 416, 688, 424, 691, 428, 696, 431, 705, 433, 832, 433, 840, 431, 844, 428, 847, 425, 849, 418, 850, 370, 846, 362, 842, 358, 837, 356, 833, 355,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "308",
				shape: "poly",
				coords: [1204, 354, 1195, 357, 1191, 362, 1189, 367, 1188, 374, 1189, 415, 1192, 424, 1194, 428, 1199, 430, 1207, 432, 1335, 431, 1343, 428, 1346, 425, 1349, 421, 1351, 413, 1351, 369, 1350, 362, 1346, 358, 1340, 355, 1334, 353,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "309",
				shape: "poly",
				coords: [1703, 353, 1696, 356, 1694, 360, 1692, 364, 1690, 370, 1690, 417, 1693, 423, 1697, 427, 1702, 430, 1708, 431, 1846, 430, 1852, 428, 1855, 424, 1858, 421, 1859, 416, 1858, 371, 1857, 359, 1852, 356, 1848, 354, 1842, 353,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "me") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo);
			dispatch(viewRoadmapRoleByRoleId(requestInfo));

			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
						"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
	// 	const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
	// 	document.getElementById("coordinateGet").value =
	// 		coords.x +
	// 		"," +
	// 		coords.y +
	// 		"," +
	// 		document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
