import { container } from "assets/jss/material-kit-pro-react.js";
const testimonialsPageStyles = (theme) => ({
	body: {
		overflowY: "scroll",
		overflowX: "hidden",
		marginTop: "93px",
		height: "calc(100vh - 100px)",
		[theme.breakpoints.down("md")]: {
			marginTop: "85px",
		},
	},

	pageHeader: {
		position: "relative",
		display: "flex !important",
		alignItems: "center",
		margin: "0",
		border: "0",
		height: "auto",
		color: "#fff",

		"&:before,&:after": {
			position: "absolute",
			zIndex: "1",
			width: "100%",
			height: "100%",
			display: "block",
			left: "0",
			top: "0",
			content: '""',
		},
	},

	container: {
		...container,
		zIndex: "5",
		/* "@media (min-width: 576px)": {
			flexBasis: "98%",
		},
		"@media (min-width: 768px)": {
			flexBasis: "98%",
		},
		"@media (min-width: 960px)": {
			flexBasis: "98%",
		},
		"@media (min-width: 992px)": {
			flexBasis: "98%",
		},
		"@media (min-width: 1200px)": {
			flexBasis: "98%",
		}, */
		"@media (min-width: 576px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 768px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 992px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 1200px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 1400px)": {
			maxWidth: "95vw",
		},
	},
	leftSideBar: {
		flexBasis: "19%",
		maxWidth: "250px",
		padding: "16px 10px",
	},
	rightSideBar: {
		flexBasis: "23%",
		maxWidth: "285px",
		padding: "16px 10px",
	},
	epmtyStateContent: {
		marginTop: "100px",
		color: "#0A1F44",
		fontSize: "14px",
		fontWeight: 500,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		textAlign: "center",
	},

	gridMain: {
		flexBasis: "59%",
		padding: "16px 10px",
	},

	testimonialsMain: {
		padding: "15px 15px 40px",
		borderRadius: "15px",
		backgroundColor: "#fff",
		minHeight: "900px",
	},

	testimonialsHeader: {
		display: "flex",
		alignItems: "center",
		backgroundColor: "#F4F7FF",
		width: "100%",
		height: "160px",
	},
	cardFrame: {
		borderRadius: "18px",
		boxShadow: "none",
		marginTop: "10px",
		marginBottom: "10px",
		[theme.breakpoints.down("xs")]: {
			marginTop: "10px",
			marginBottom: "10px",
		},
	},
	headerLeft: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: "270px",
		height: "100%",

		"& > img": {
			width: "auto",
			height: "90%",
		},
	},

	headerRight: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		height: "100%",

		"& > h2": {
			marginBottom: "10px",
			color: "#171725",
			fontSize: "16px",
			fontWeight: 500,
			fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		},
	},

	headerPoint: {
		display: "flex",
		margin: "5px 0",
		color: "#4A4A4A",
		fontSize: "12px",
		fontWeight: 400,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,

		"& > svg": {
			marginRight: "10px",
			width: "18px",
			height: "18px",
		},
	},

	testimonialsContentContainer: {
		margin: "30px 20px 0",
	},

	testimonialsContentHeader: {
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "center",

		"& > button": {
			boxSizing: "border-box",
			margin: "0 5px",
			borderRadius: "24px",
			width: "200px",
			height: "45px",
			fontSize: "15px",
			fontWeight: 500,
			fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		},

		"& > button:first-of-type": {
			border: "solid 1px transparent",
			color: "#6F52ED",
		},

		"& > button:last-of-type": {
			border: "solid 1px #687BFE",
			backgroundColor: "#687BFE",
			color: "#fff",

			"&:hover": {
				backgroundColor: "transparent",
				color: "#687BFE",
			},
		},
		[theme.breakpoints.down("sm")]: {
			justifyContent: "center",
		},
	},

	testimonialsContent: {
		marginTop: "5px",
	},

	testimonialsContentTabsContainer: {
		display: "flex",
		marginTop: "10px",
		borderBottom: "solid 1px #979797",
		backgroundColor: "transparent",
		height: "50px",
		textTransform: "uppercase",
	},

	tab: {
		position: "relative",
		zIndex: 3,
		boxSizing: "border-box",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: "10px 0 0",
		width: "160px",
		height: "100%",
		color: "#92929D",
		cursor: "pointer",
		transition: "0.2s ease-in-out",
	},

	tabActive: {
		color: "#6F52ED",
	},

	tabTitle: {
		fontSize: "14px",
		fontWeight: 500,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
	},

	tabActiveBorder: {
		position: "absolute",
		left: 0,
		right: 0,
		bottom: "0",
		marginTop: "10px",
		border: "none",
		borderTop: "solid 3px #6F52ED",
		borderRadius: "10px 10px 0 0",
		width: "100%",
	},

	singleTestimonialsCard: {
		width: "250px",
	},

	testimonialsDetail: {
		color: "#0A1F44",
		fontSize: "14px",
		fontWeight: 400,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,

		"& > div": {
			display: "flex",
			alignItems: "center",
			margin: "5px 0",
		},

		"& > div > div:first-child": {
			width: "110px",
		},
	},

	editProfileModalBox: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		overflow: "hidden",
		borderRadius: "15px",
		backgroundColor: "#fff",
		maxWidth: "800px",
		/* minWidth: "720px", */
		width: "60vw",

		/* height: "90vh",
		 */
		"& > button": {
			position: "absolute",
			top: "20px",
			right: "20px",
			padding: "0",
			minWidth: "fit-content",

			"& svg": {
				width: "14px",
				height: "14px",
			},
		},

		[theme.breakpoints.down("sm")]: {
			width: "100vw",
			height: "auto",
		},
	},
});

export default testimonialsPageStyles;
