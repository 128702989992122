import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import messageDashboardStyle from "assets/jss/material-kit-pro-react/views/pageStyle/MessagePage/messagePageStyle.js";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Alert } from "@material-ui/lab";
import CustomCard from "components/Card/Card.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import { Formik } from "formik";
import {
	messageConversationSearchInfo,
	messageConversationBySearchId,
	messageConversationSearchReset,
} from "configurations/redux/messageConversationSearchSlice.js";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as ChatElements from "react-chat-elements";
import { TimeFormatWithoutTimeAgo } from "configurations/configurations.js";
import { isMobile, isMobileOnly, isTablet } from "react-device-detect";
import Close from "@material-ui/icons/Close";
import CustomSearchInput from "components/CustomInput/CustomSearchInput";
import IconLibrary from "views/Component/IconLibrary";
import Linkify from "react-linkify";

const useStyles = makeStyles(messageDashboardStyle);

const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
});

export default function CustomDialogSearchMessage(children) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { customDialog, setCustomDialog } = children;
	const [searchKeyword, setSearchKeyword] = React.useState("");
	const [fullWidth, setFullWidth] = React.useState(true);
	const [maxWidth, setMaxWidth] = React.useState("sm");
	const userStatus = useSelector((state) => state.auth.user);
	const searchResult = useSelector(
		(state) => state.messageConversationSearch.searchResult
	);
	const isEnd = useSelector((state) => state.messageConversationSearch.isEnd);
	const searchCount = useSelector(
		(state) => state.messageConversationSearch.searchCount
	);
	const currentSize = useSelector(
		(state) => state.messageConversationSearch.lastRecordSize
	);
	const handleClose = () => {
		setCustomDialog({ ...customDialog, isOpen: false });
		dispatch(messageConversationSearchReset());
	};

	const mobileResize = () => {
		if (isMobile) {
			var timer = setInterval(() => {
				var conversation_search = document.getElementById(
					"conversation_search"
				);
				if (conversation_search) {
					clearInterval(timer);
					conversation_search.style.height = window.innerHeight - 220 + "px";
				} else {
					setTimeout(() => {
						clearInterval(timer);
					}, 3000);
				}
			}, 50);
		}
	};

	const DialogTitle = withStyles(styles)((props) => {
		const { children, classes, onClose, ...other } = props;
		return (
			<MuiDialogTitle disableTypography className={classes.root} {...other}>
				{children}
				{onClose ? (
					<IconButton
						aria-label="close"
						className={classes.closeButton}
						onClick={handleClose}
					>
						<CloseIcon />
					</IconButton>
				) : null}
			</MuiDialogTitle>
		);
	});

	const loadConversation = () => {
		const requestInfo = {
			acctId: customDialog.customDialogDefault.acctId,
			accessToken: localStorage.getItem("token"),
			email: customDialog.customDialogDefault.email,
			lastRecordSize: currentSize,
			requestRecordSize: isTablet ? 30 : 15,
			chatroomId: customDialog.customDialogDefault.chatroomId,
			searchResult: searchResult,
			keyword: searchKeyword.trim(),
		};
		console.log(requestInfo);
		dispatch(messageConversationSearchInfo(requestInfo));
	};

	React.useEffect(() => {
		console.log(currentSize, searchResult);
	}, [currentSize]);

	const loadMessage = (conversationId) => {
		const requestProfile = {
			searchId: conversationId,
		};
		dispatch(messageConversationBySearchId(requestProfile));
	};

	const ResultCard = searchResult.map((item) => {
		mobileResize();
		// console.log(item);
		// var replyTitle = item.replyTitle;
		// if (item.replyTitle != null)
		// 	if (item.replyTitle.length > 25)
		// 		replyTitle = item.replyTitle.substring(0, 22).trim() + "...";

		var replyTitle = item.replyFullName;
		var replyDescription = item.replyDescription;
		if (item.replyAcctId === userStatus.acctId) replyTitle = "You";
		if (replyDescription && replyDescription.split("\n").length > 5) {
			var replySplit = replyDescription.split("\n");
			replyDescription = "";
			for (var i = 0; i < 5; i++) {
				if (i < 4) replyDescription += replySplit[i] + "\n";
				else replyDescription += replySplit[i] + "...";
			}
		}

		return item.acctId == userStatus.acctId ? (
			<div
				id={item.conversationId}
				key={"S" + item.conversationId}
				style={{ cursor: "pointer" }}
			>
				<ChatElements.MessageBox
					key={item.conversationId}
					position={"right"}
					type={"text"}
					onTitleClick={() => {
						// getProfileNavigate(item);
					}}
					// text={item.description}
					text={
						<span>
							{item.description.length > 0 ? (
								<Linkify
									componentDecorator={(decoratedHref, decoratedText, key) => (
										<a target="blank" href={decoratedHref} key={key}>
											{decoratedText}
										</a>
									)}
								>
									{item.description}
								</Linkify>
							) : null}
							{item.description.length > 0 &&
							item.attachments.attachmentList.length > 0 ? (
								<br />
							) : null}
							{item.attachments.attachmentList.length > 0 ? (
								<span>
									<Button
										className={classes.attachmentButton}
										onClick={() => {
											window.open(
												item.attachments.attachmentList[0].attachmentPath
											);
										}}
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="16"
											height="20"
											viewBox="0 0 16 20"
										>
											<path
												id="Shape"
												d="M1.99,20A2,2,0,0,1,0,18V2A2.006,2.006,0,0,1,2,0h8l6,6V18a2.006,2.006,0,0,1-2,2Z"
											/>
										</svg>
										<span>{item.attachments.attachmentList[0].attachmentName}</span>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="19.642"
											height="17.857"
											viewBox="0 0 19.642 17.857"
										>
											<path
												id="Shape"
												d="M0,17.857v-2.1H19.642v2.1ZM0,6.3H5.612V0h8.419V6.3h5.611L9.822,13.656Z"
												fill="#6f52ed"
											/>
										</svg>
									</Button>
								</span>
							) : null}
						</span>
					}
					dateString={TimeFormatWithoutTimeAgo(item.createDate)}
					status={item.statusName}
					reply={
						item.replyId != null
							? {
									title: replyTitle,
									titleColor: "#8717ae",
									message: replyDescription.length > 0
									? replyDescription
									: "Attachment...",
							  }
							: null
					}
					onClick={() => {
						loadMessage(item.conversationId);
					}}
				/>
			</div>
		) : (
			<div
				id={item.conversationId}
				key={"S" + item.conversationId}
				style={{ cursor: "pointer" }}
			>
				<ChatElements.MessageBox
					key={item.conversationId}
					position={"left"}
					type={"text"}
					title={item.fullName != null ? item.fullName : null}
					// title={
					// 	isMobileOnly
					// 		? item.companyName != null
					// 			? item.companyName.length > 15
					// 				? item.companyName.substring(0, 12).trim() + "..."
					// 				: item.companyName
					// 			: item.fullName
					// 			? item.fullName.length > 15
					// 				? item.fullName.substring(0, 12).trim() + "..."
					// 				: item.fullName
					// 			: null
					// 		: item.companyName != null
					// 		? item.companyName.length > 25
					// 			? item.companyName.substring(0, 22).trim() + "..."
					// 			: item.companyName
					// 		: item.fullName
					// 		? item.fullName.length > 25
					// 			? item.fullName.substring(0, 22).trim() + "..."
					// 			: item.fullName
					// 		: null
					// }
					onTitleClick={() => {
						// getProfileNavigate(item);
					}}
					// text={item.description}
					text={
						<span>
							{item.description.length > 0 ? (
								<Linkify
									componentDecorator={(decoratedHref, decoratedText, key) => (
										<a target="blank" href={decoratedHref} key={key}>
											{decoratedText}
										</a>
									)}
								>
									{item.description}
								</Linkify>
							) : null}
							{item.description.length > 0 &&
							item.attachments.attachmentList.length > 0 ? (
								<br />
							) : null}
							{item.attachments.attachmentList.length > 0 ? (
								<span>
									<Button
										className={classes.attachmentButton}
										onClick={() => {
											window.open(
												item.attachments.attachmentList[0].attachmentPath
											);
										}}
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="16"
											height="20"
											viewBox="0 0 16 20"
										>
											<path
												id="Shape"
												d="M1.99,20A2,2,0,0,1,0,18V2A2.006,2.006,0,0,1,2,0h8l6,6V18a2.006,2.006,0,0,1-2,2Z"
											/>
										</svg>
										<span>{item.attachments.attachmentList[0].attachmentName}</span>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="19.642"
											height="17.857"
											viewBox="0 0 19.642 17.857"
										>
											<path
												id="Shape"
												d="M0,17.857v-2.1H19.642v2.1ZM0,6.3H5.612V0h8.419V6.3h5.611L9.822,13.656Z"
												fill="#6f52ed"
											/>
										</svg>
									</Button>
								</span>
							) : null}
						</span>
					}
					dateString={TimeFormatWithoutTimeAgo(item.createDate)}
					status={item.statusName}
					reply={
						item.replyId != null
							? {
									title: replyTitle,
									titleColor: "#8717ae",
									message: replyDescription.length > 0
									? replyDescription
									: "Attachment...",
							  }
							: null
					}
					onClick={() => {
						loadMessage(item.conversationId);
					}}
				/>
			</div>
		);
	});

	const handleSearch = () => {
		const requestInfo = {
			acctId: customDialog.customDialogDefault.acctId,
			accessToken: localStorage.getItem("token"),
			email: customDialog.customDialogDefault.email,
			lastRecordSize: 0,
			requestRecordSize: isTablet ? 30 : 15,
			chatroomId: customDialog.customDialogDefault.chatroomId,
			searchResult: null,
			keyword: searchKeyword.trim().length > 0 ? searchKeyword.trim() : "",
		};
		// console.log(requestInfo);
		dispatch(messageConversationSearchInfo(requestInfo));
	};
	return (
		<React.Fragment>
			{isMobileOnly ? (
				<Dialog
					fullScreen
					fullWidth={fullWidth}
					maxWidth={maxWidth}
					open={customDialog.isOpen}
					onClose={handleClose}
					aria-labelledby="max-width-dialog-title"
				>
					<DialogTitle
						id="customized-dialog-title"
						onClose={handleClose}
						className={classes.titlePadding8}
					>
						<h3
							className={classNames(classes.title, classes.conversationTitle)}
						>
							{customDialog.customDialogDefault.title}
						</h3>
					</DialogTitle>
					<DialogContent>
						<GridContainer>
							<GridItem xs={12} sm={12} md={12}>
								<Formik
									initialValues={{
										keyword: "",
									}}
									onSubmit={(data) => {
										const requestProfile = {
											acctId: customDialog.customDialogDefault.acctId,
											accessToken: localStorage.getItem("token"),
											email: customDialog.customDialogDefault.email,
											lastRecordSize: 0,
											requestRecordSize: isTablet ? 30 : 15,
											chatroomId: customDialog.customDialogDefault.chatroomId,
											searchResult: null,
											keyword:
												data.keyword.trim().length > 0 ? data.keyword : "",
										};
										// console.log(requestProfile);
										dispatch(messageConversationSearchInfo(requestProfile));
									}}
								>
									{({ values, handleChange, handleSubmit, setFieldValue }) => (
										<form onSubmit={handleSubmit}>
											<GridContainer className={classes.chatRoomSearchBox}>
												<GridItem
													xs={12}
													sm={12}
													md={12}
													className={classes.searchBarInput}
												>
													<CustomSearchInput
														labelText="Search Keyword"
														id="keyword"
														name="keyword"
														values={values.keyword}
														// onChange={handleChange}
														onChange={(event) => {
															setSearchKeyword(event.target.value.trim());
															setFieldValue("keyword", event.target.value);
														}}
														onClick={handleSubmit}
														fullWidth
														inputProps={{
															autoComplete: "off",
														}}
														close={true}
														className={classes.borderRadiusSearch}
													/>
												</GridItem>
											</GridContainer>
										</form>
									)}
								</Formik>
							</GridItem>
							<GridItem
								xs={12}
								sm={12}
								md={12}
								className={classes.cardBodyPadding}
							>
								{searchCount > 0 && (
									<div className={classes.searchButton}>
										<CustomCard
											className={
												classes.conversationMargin10 +
												" " +
												classes.searchCardBorder
											}
										>
											<div
												className={classes.conversationSearch}
												id="conversation_search"
											>
												<InfiniteScroll
													dataLength={searchResult.length}
													next={loadConversation}
													hasMore={!isEnd}
													style={{
														display: "flex",
														flexDirection: "column-reverse",
														overflow: "hidden",
													}}
													inverse={true}
													// scrollThreshold={0.6}
													// height={searchResult.length < 4 ? 520 : 600}
													loader={
														searchResult.length > 0 && (
															<CircularProgress
																className={classes.loadingProgress}
															/>
														)
													}
													endMessage={
														searchResult.length > 15 ? (
															<h6 className={classes.endMessage}>
																Nothing More
															</h6>
														) : null
													}
													scrollableTarget="conversation_search"
												>
													{searchResult.length > 0 ? (
														<div>{ResultCard}</div>
													) : (
														<Alert severity="info">No Result Found</Alert>
													)}
												</InfiniteScroll>
											</div>
										</CustomCard>
										<Button
											round
											fullWidth
											type="submit"
											onClick={handleSearch}
											aria-label="search"
											color="oppty"
										>
											SEARCH
										</Button>
									</div>
								)}
								{searchCount == 0 && (
									<div className={classes.searchButton}>
										<Button
											round
											fullWidth
											type="submit"
											onClick={handleSearch}
											aria-label="search"
											color="oppty"
										>
											SEARCH
										</Button>
										<CustomCard
											className={
												classes.conversationMargin10 +
												" " +
												classes.searchCardBorder
											}
										>
											<div className={classes.conversationInfo}>
												<GridContainer>
													<GridItem>
														<Alert severity="info">
															{customDialog.customDialogDefault.description}
														</Alert>
													</GridItem>
												</GridContainer>
											</div>
										</CustomCard>
									</div>
								)}
							</GridItem>
						</GridContainer>
					</DialogContent>
				</Dialog>
			) : (
				<Dialog
					fullWidth={fullWidth}
					maxWidth={maxWidth}
					open={customDialog.isOpen}
					onClose={handleClose}
					aria-labelledby="max-width-dialog-title"
				>
					<DialogTitle
						id="alert-dialog-slide-title"
						disableTypography
						className={classes.modalHeader}
					>
						<Button
							simple
							className={classes.modalCloseButton}
							key="close"
							aria-label="Close"
							onClick={handleClose}
						>
							{" "}
							<IconLibrary.Close />
						</Button>
						<h4>
							<b>{customDialog.customDialogDefault.title}</b>
						</h4>
					</DialogTitle>
					{/* <DialogTitle
						id="customized-dialog-title"
						onClose={handleClose}
						className={classes.titlePadding8}
					>
						<h3
							className={classNames(classes.title, classes.conversationTitle)}
						>
							{customDialog.customDialogDefault.title}
						</h3>
					</DialogTitle> */}
					<DialogContent>
						<GridContainer>
							<GridItem xs={12} sm={12} md={12}>
								<Formik
									initialValues={{
										keyword: "",
									}}
									onSubmit={(data) => {
										const requestProfile = {
											acctId: customDialog.customDialogDefault.acctId,
											accessToken: localStorage.getItem("token"),
											email: customDialog.customDialogDefault.email,
											lastRecordSize: 0,
											requestRecordSize: isTablet ? 30 : 15,
											chatroomId: customDialog.customDialogDefault.chatroomId,
											searchResult: null,
											keyword:
												data.keyword.trim().length > 0
													? data.keyword.trim()
													: "",
										};
										// console.log(requestProfile);
										dispatch(messageConversationSearchInfo(requestProfile));
									}}
								>
									{({ values, handleChange, handleSubmit, setFieldValue }) => (
										<form onSubmit={handleSubmit}>
											<GridContainer className={classes.chatRoomSearchBox}>
												<GridItem
													xs={12}
													sm={12}
													md={12}
													className={classes.searchBarInput}
												>
													<CustomSearchInput
														labelText="Search Keyword"
														id="keyword"
														name="keyword"
														values={values.keyword}
														// onChange={handleChange}
														onChange={(event) => {
															setFieldValue("keyword", event.target.value);
															setSearchKeyword(event.target.value.trim());
														}}
														onClick={handleSubmit}
														fullWidth
														inputProps={{
															autoComplete: "off",
														}}
														close={true}
														className={classes.borderRadiusSearch}
													/>
												</GridItem>
											</GridContainer>
										</form>
									)}
								</Formik>
							</GridItem>
							<GridItem xs={12} sm={12} md={12}>
								{searchCount > 0 && (
									<div>
										<CustomCard
											className={
												classes.conversationMargin10 +
												" " +
												classes.searchCardBorder
											}
										>
											<div
												className={classes.conversationSearch}
												id="conversation_search"
											>
												<InfiniteScroll
													dataLength={searchResult.length}
													next={loadConversation}
													hasMore={!isEnd}
													style={{
														display: "flex",
														flexDirection: "column-reverse",
														overflow: "hidden",
													}}
													inverse={true}
													// scrollThreshold={0.6}
													// height={searchResult.length < 4 ? 520 : 600}
													loader={
														searchResult.length > 0 && (
															<CircularProgress
																className={classes.loadingProgress}
															/>
														)
													}
													endMessage={
														searchResult.length > 15 ? (
															<h6 className={classes.endMessage}>
																Nothing More
															</h6>
														) : null
													}
													scrollableTarget="conversation_search"
												>
													{searchResult.length > 0 ? (
														<div>{ResultCard}</div>
													) : (
														<h6 className={classes.endMessage}>
															No Result Found
														</h6>
													)}
												</InfiniteScroll>
											</div>
										</CustomCard>
										<Button
											round
											fullWidth
											type="submit"
											onClick={handleSearch}
											aria-label="search"
											color="oppty"
										>
											SEARCH
										</Button>
									</div>
								)}
								{searchCount == 0 && (
									<div className={classes.searchButton}>
										<Button
											round
											fullWidth
											type="submit"
											onClick={handleSearch}
											aria-label="search"
											color="oppty"
										>
											SEARCH
										</Button>
										<CustomCard
											className={
												classes.conversationMargin10 +
												" " +
												classes.searchCardBorder
											}
										>
											<div className={classes.conversationInfo}>
												<GridContainer>
													<GridItem>
														<Alert severity="info">
															{customDialog.customDialogDefault.description}
														</Alert>
													</GridItem>
												</GridContainer>
											</div>
										</CustomCard>
									</div>
								)}
							</GridItem>
						</GridContainer>
					</DialogContent>
				</Dialog>
			)}
		</React.Fragment>
	);
}
