import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import InfiniteScroll from "react-infinite-scroll-component";
import CardBody from "components/Card/CardBody.js";

import CircularProgress from "@material-ui/core/CircularProgress";

import MessageChatroomCard from "./MessageChatroomCard.js";
import messageDashboardStyle from "assets/jss/material-kit-pro-react/views/pageStyle/MessagePage/messagePageStyle.js";
import { messageChatroomInfo } from "configurations/redux/messageChatroomSlice.js";
import List from "@material-ui/core/List";
import Accordion from "components/Accordion/Accordion.js";
import NoActivityStatusModal from "../NoActivityStatusModal/NoActivityStatusModal";
import { isDesktop, isMobile, isMobileOnly } from "react-device-detect";

const useStyles = makeStyles(messageDashboardStyle);

export default function SearchResultSection() {
	const classes = useStyles();
	const searchResult = useSelector(
		(state) => state.messageChatroom.searchResult
	);
	const currentSize = useSelector(
		(state) => state.messageChatroom.lastRecordSize
	);
	const isLoading = useSelector((state) => state.messageChatroom.isLoading);
	const isSearching = useSelector((state) => state.messageChatroom.isSearching);
	const userStatus = useSelector((state) => state.auth.user);
	const isEnd = useSelector((state) => state.messageChatroom.isEnd);
	const isUpdated = useSelector((state) => state.messageChatroom.isUpdated);
	const dispatch = useDispatch();
	console.log(searchResult);
	const ResultCard = searchResult
		.filter((item) => item.status < 3)
		.map((item, index) => {
			return <MessageChatroomCard key={item.chatroomId} item={item} />;
		});
	const ResultCardClose = searchResult
		.filter((item) => item.status == 3)
		.map((item, index) => {
			return <MessageChatroomCard key={item.chatroomId} item={item} />;
		});

	const loadChatroom = () => {
		const requestInfo = {
			acctId: userStatus.acctId,
			accessToken: localStorage.getItem("token"),
			email: userStatus.email,
			lastRecordSize: currentSize,
			requestRecordSize: 2,
			searchResult: searchResult,
			keyword: "",
			bizId: 0,
		};
		console.log(requestInfo);
		dispatch(messageChatroomInfo(requestInfo));
	};

	const resizeChatroomList = () => {
		// var data = document.getElementById("conversationCard");
		// if (data === null) {
		var list = document.getElementById("conversation_list");
		var listScroll = document.getElementById("conversation_list_scroll");
		var height = 300;
		if (isMobileOnly) height = 360;
		if (list != null && listScroll != null) {
			list.style.height = window.innerHeight - height + "px";
			listScroll.style.height = window.innerHeight - height + "px";
		}
		// }
	};

	window.onresize = function (e) {
		resizeChatroomList();
	};

	React.useEffect(() => {
		resizeChatroomList();
	}, [searchResult]);

	return (
		<div>
			{searchResult && searchResult.length > 0 ? (
				<div
					className={(classes.chatroomMargin0, classes.chatroomHeight)}
					id="conversation_list_scroll"
				>
					<InfiniteScroll
						dataLength={searchResult.length}
						next={loadChatroom}
						hasMore={!isEnd}
						style={{ overflow: "hidden" }}
						scrollableTarget="conversation_list_scroll"
						loader={<CircularProgress className={classes.loadingProgress} />}
						// endMessage={
						// 	<h6 className={classes.endConversation}>End of conversation</h6>
						// }
					>
						<List className={classes.messageList}>
							{ResultCard}
							{ResultCardClose && ResultCardClose.length > 0 ? (
								<Accordion
									active={0}
									className={classes.accordionStyle}
									activeColor="primary"
									collapses={[
										{
											title: (
												<h6 className={classes.closedConversation}>
													Closed Conversation
												</h6>
											),
											content: <div>{ResultCardClose}</div>,
										},
									]}
								/>
							) : null}
						</List>
					</InfiniteScroll>
				</div>
			) : null}
			{isSearching && searchResult && searchResult.length == 0 && (
				<div className={classes.chatroomMargin0}>
					<CardBody className={classes.messageList}>
						{isLoading ? (
							<CircularProgress className={classes.loadingProgress} />
						) : (
							<h6 className={classes.endMessage}>No available conversation</h6>
						)}
					</CardBody>
				</div>
			)}
			{!isUpdated && <NoActivityStatusModal />}
		</div>
	);
}
