import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { departmentViewRoadmap,viewRoadmapRoleByRoleId } from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";
import ImageMapper from "react-image-mapper";
import Department_Public_Relation from "assets/img/Career_Roadmap/Marketing_Communication/Department_Public_Relation_1.jpg";
import RoleInfoModal from "../RoleInfoModal.js";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentMarketing() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Public_Relation;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "pr",
				shape: "poly",
				coords: [
					74,357,163,357,174,360,180,366,183,371,183,376,184,410,183,417,180,424,176,429,170,432,161,434,74,433,68,431,60,426,57,420,55,414,55,377,56,371,59,366,64,361,68,358,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "8",
				shape: "poly",
				coords: [220,336,374,336,384,338,391,343,395,351,395,362,394,429,391,442,382,449,366,450,217,451,205,448,198,441,197,430,197,358,199,346,205,340,210,337,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "130",
				shape: "poly",
				coords: [536,349,683,349,692,350,699,355,703,361,703,370,703,420,701,429,696,436,684,440,542,440,527,437,521,431,520,426,519,366,522,357,526,352,531,350,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "10",
				shape: "poly",
				coords: [835,347,959,346,969,346,978,349,983,356,985,364,984,419,982,430,975,438,963,440,832,439,824,435,819,430,817,422,817,367,819,355,825,350,829,347,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "11",
				shape: "poly",
				coords: [1108,354,1236,354,1248,358,1254,365,1255,375,1254,414,1252,423,1247,429,1234,433,1108,433,1097,431,1091,427,1088,421,1087,373,1091,361,1097,356,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "6",
				shape: "poly",
				coords: [1376,354,1502,354,1512,355,1518,359,1524,369,1524,414,1522,423,1516,429,1505,432,1377,432,1367,431,1361,427,1357,421,1356,374,1357,364,1364,357,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "7",
				shape: "poly",
				coords: [1673,355,1844,355,1854,359,1861,363,1864,369,1863,382,1863,414,1860,423,1854,429,1840,432,1674,432,1662,429,1656,423,1655,374,1655,365,1662,358,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "pr") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo)
			dispatch(viewRoadmapRoleByRoleId(requestInfo));
			
			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// console.log(coords.x + "," + coords.y + ",");
		// navigator.clipboard.writeText(coords.x + "," + coords.y + ",");
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
