import { createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import {
	instanceUrl,
	header,
	emptyCache,
} from "configurations/configurations.js";
import { bizPageInfo } from "./businessSlice";

const corpUserSlice = createSlice({
	name: "corpUser",
	initialState: {
		profileRecord: [],
		hashtags: [],
		profilePic: null,
		backgroundPic: null,
		backgroundTemplateFlag: null,
		backgroundPicFileName: null,
		acctId: null,
		acctStatusId: null,
		acctTypeId: null,
		primaryEmail: null,
		secondaryEmail: null,
		isUpdated: true,
		isLoading: true,
		isViewed: false,
		isUploaded: false,
		buildProfileFlag: null,
	},

	reducers: {
		CORP_PROFILE_LOAD_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.profileRecord = action.payload.profileRecord;
			state.hashtags = action.payload.hashtags;
			state.acctId = action.payload.acctId;
			state.profilePic = action.payload.profilePic;
			state.backgroundPic = action.payload.backgroundPic;
			state.backgroundPicFileName = action.payload.backgroundPicFileName;
			state.backgroundTemplateFlag = action.payload.backgroundTemplateFlag;
			state.acctStatusId = action.payload.acctStatusId;
			state.acctTypeId = action.payload.acctTypeId;
			state.isUpdated = true;
			state.isLoading = false;
			state.isViewed = false;
			state.buildProfileFlag = action.payload.buildProfileFlag;
		},
		CORP_PROFILE_VIEW_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.profileRecord = action.payload.profileRecord;
			state.hashtags = action.payload.hashtags;
			state.acctId = action.payload.acctId;
			state.profilePic = action.payload.profilePic;
			state.backgroundPic = action.payload.backgroundPic;
			state.backgroundPicFileName = action.payload.backgroundPicFileName;
			state.backgroundTemplateFlag = action.payload.backgroundTemplateFlag;
			state.acctStatusId = action.payload.acctStatusId;
			state.acctTypeId = action.payload.acctTypeId;
			state.isUpdated = true;
			state.isLoading = false;
			state.isViewed = true;
		},
		CORP_PROFILE_LOAD_FAIL: (state, action) => {
			localStorage.removeItem("token");
			state.profileRecord = state.profileRecord;
			state.hashtags = state.hashtags;
			state.privacySetting = null;
			state.isUpdated = false;
			state.isLoading = false;
		},
		CORP_BASIC_INFO_UPDATE_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.profileRecord = action.payload.profileRecord;
			state.buildProfileFlag = 1;
		},
		CORP_PICTURE_UPDATE_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.profilePic = action.payload.profilePic;
		},
		CORP_RESET_UPDATE_BACKGROUND: (state, action) => {
			state.isUploaded = false;
		},
		CORP_BACKGROUND_UPDATE_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.backgroundTemplateFlag = action.payload.backgroundTemplateFlag;
			if (action.payload.backgroundTemplateFlag === 0) {
				state.backgroundPic = action.payload.backgroundPic;
				state.backgroundPicFileName = null;
			} else {
				state.backgroundPic = action.payload.backgroundPicBase64;
				state.backgroundPicFileName = action.payload.backgroundPic;
			}
			state.isUploaded = true;
		},
		CORP_HASHTAG_UPDATE_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.hashtags.push(action.payload.hashtags);
		},
		CORP_HASHTAG_DELETE_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.hashtags = state.hashtags.filter((item) => {
				return item.hashId !== action.payload.hashId;
			});
		},
		CORP_PROFILE_LOADING: (state) => {
			state.isLoading = true;
		},
		UPDATE_FAIL: (state) => {
			return state;
		},
		CORP_PROFILE_RESET: (state) => {
			state.profileRecord = [];
			state.buildProfileFlag = null;
		},
	},
});

const {
	CORP_PROFILE_LOAD_SUCCESS,
	CORP_PROFILE_LOAD_FAIL,
	CORP_BASIC_INFO_UPDATE_SUCCESS,
	CORP_HASHTAG_DELETE_SUCCESS,
	CORP_HASHTAG_UPDATE_SUCCESS,
	CORP_PICTURE_UPDATE_SUCCESS,
	CORP_RESET_UPDATE_BACKGROUND,
	CORP_BACKGROUND_UPDATE_SUCCESS,
	UPDATE_FAIL,
	CORP_PROFILE_LOADING,
	CORP_PROFILE_VIEW_SUCCESS,
	CORP_PROFILE_RESET,
} = corpUserSlice.actions;

export const getCorpProfiles = ({
	acctId,
	bizId,
	userId,
	accessToken,
	ipAddr,
}) => async (dispatch) => {
	dispatch(CORP_PROFILE_LOADING());
	const body = { acctId, bizId, ipAddr, accessToken };
	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/pm/get-corporate-profile`,
		params: body,
	})
		.then((res) => {
			console.log(res.data);
			if (res.status === 201) { 
				const response = {
					profileRecord: {
						areaCodeId: res.data.payload.corporateProfile.areaCodeId,
						companyProfile: res.data.payload.corporateProfile.companyProfile,
						companySize: res.data.payload.corporateProfile.companySize,
						companyTypeId: res.data.payload.corporateProfile.companyTypeId,
						contactNo: res.data.payload.corporateProfile.contactNo,
						companyName: res.data.payload.corporateProfile.companyName,
						countryId: res.data.payload.corporateProfile.countryId,
						// firstName: res.data.payload.corporateProfile.firstName,
						industryId: res.data.payload.corporateProfile.industryId,
						locationId: res.data.payload.corporateProfile.locationId,
						// middleName: res.data.payload.corporateProfile.middleName,
						// primaryEmail: res.data.payload.corporateProfile.primaryEmail,
						// secondaryEmail: res.data.payload.corporateProfile.secondaryEmail,
						// surname: res.data.payload.corporateProfile.surname,
						url: res.data.payload.corporateProfile.url,
						email: res.data.payload.corporateProfile.email,
						pageName: res.data.payload.corporateProfile.pageName,
						
						profilePic: res.data.payload.corporateProfile.profilePic,
						companySize: res.data.payload.corporateProfile.companySize,
						jobsActive: res.data.payload.corporateProfile.jobsActive,
						upcomingInterviews: res.data.payload.corporateProfile.upcomingInterviews,
						industryId: res.data.payload.corporateProfile.industryId,
						
					},
					profilePic: res.data.payload.corporateProfile.profilePic,
					backgroundPic: res.data.payload.corporateProfile.backgroundPic,
					backgroundPicFileName:
						res.data.payload.corporateProfile.backgroundPicFileName,
					backgroundTemplateFlag:
						res.data.payload.corporateProfile.backgroundPicTemplateFlag,
					// acctId: res.data.payload.corporateProfile.acctId,
					// acctStatusId: res.data.payload.corporateProfile.acctStatusId,
					// acctTypeId: res.data.payload.corporateProfile.acctTypeId,

					hashtags: res.data.payload.corporateProfile.hashtags,
					buildProfileFlag: res.data.payload.corporateProfile.buildProfileFlag,
					token: res.data.token,
				};

				if (acctId === userId) {
					dispatch(CORP_PROFILE_LOAD_SUCCESS(response));
				} else {
					dispatch(CORP_PROFILE_VIEW_SUCCESS(response));
				}
			} else {
				console.log(res);
				dispatch(CORP_PROFILE_LOAD_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};
export const updateBasicInfo = ({
	acctId,
	surname,
	firstName,
	middleName,
	countryId,
	locationId,
	areaCodeId,
	contactNo,

	aboutMe,
	companyName,
	url,
	industryId,
	companyProfile,
	companySize,
	companyTypeId,
	accessToken,
	primaryEmail,
	secondaryEmail,
}) => async (dispatch) => {
	const body = {
		acctId,
		surname,
		firstName,
		middleName,
		countryId,
		locationId,
		areaCodeId,
		contactNo,

		aboutMe,
		companyName,
		url,
		primaryEmail,
		secondaryEmail,
		industryId,
		companyProfile,
		companySize,
		companyTypeId,
		accessToken,
	};

	await axios({
		method: "PUT",
		headers: header,
		url: `${instanceUrl}/api/pm/update-corporate-profile`,
		data: body,
	})
		.then((res) => {
			if (res.status === 201) {
				console.log(res);
				const response = {
					profileRecord: {
						surname,
						firstName,
						middleName,
						countryId,
						locationId,
						areaCodeId,
						contactNo,

						aboutMe,
						companyName,
						url,
						industryId,
						companyProfile,
						companySize,
						companyTypeId,
						primaryEmail,
						secondaryEmail,
					},
					token: res.data.token,
				};
				dispatch(CORP_BASIC_INFO_UPDATE_SUCCESS(response));
			} else {
				dispatch(UPDATE_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};
export const insertUpdateBasicInfo = ({
	acctId,
	email,
	countryId,
	locationId,
	areaCodeId,
	contactNo,
	companyName,
	url,
	industryId,
	customIndustryId,
	companyProfile,
	companySize,
	companyTypeId,
	bizId,
	pageName,
	accessToken,
}) => async (dispatch) => {
	const body = {
		acctId,
		email,
		countryId,
		locationId,
		areaCodeId,
		contactNo,
		companyName,
		url,
		industryId,
		customIndustryId,
		companyProfile,
		companySize,
		companyTypeId,
		bizId,
		pageName,
		accessToken,
	};

	await axios({
		method: "PUT",
		headers: header,
		url: `${instanceUrl}/api/pm/insert-update-corporate-profile`,
		data: body,
	})
		.then((res) => {
			if (res.status === 201) {
				console.log(res);
				const response = {
					profileRecord: {
						acctId,
						email,
						countryId,
						locationId,
						areaCodeId,
						contactNo,
						companyName,
						url,
						industryId,
						customIndustryId,
						companyProfile,
						companySize,
						companyTypeId,
						bizId,
						pageName,
					},
					token: res.data.payload.profile.accessToken,
				};
				dispatch(CORP_BASIC_INFO_UPDATE_SUCCESS(response));
				const request = {
					email: email,
					acctId: acctId,
					accessToken: localStorage.getItem("token"),
				};
				dispatch(bizPageInfo(request));
			} else {
				dispatch(UPDATE_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};
export const updateHashtag = ({
	acctId,
	accessToken,
	email,
	hashId,
	hashtag,
	bizId,
}) => async (dispatch) => {
	const body = { acctId, accessToken, email, hashId, hashtag, bizId };
	console.log(body);
	await axios({
		method: "PUT",
		headers: header,
		url: `${instanceUrl}/api/pm/insert-update-corporate-hashtag`,
		data: body,
	})
		.then((res) => {
			//console.log(res);
			if (res.status === 201) {
				//console.log(res);
				//recordId,
				const response = {
					hashtags: {
						hashId: res.data.payload.recordId,
						hashtag,
					},
					token: res.data.token,
				};
				dispatch(CORP_HASHTAG_UPDATE_SUCCESS(response));
			} else {
				dispatch(UPDATE_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			dispatch(UPDATE_FAIL());
		});
};
export const deleteHashtag = ({
	acctId,
	accessToken,
	email,
	hashId,
	bizId,
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		email,
		hashId,
		bizId,
	};
	await axios({
		method: "DELETE",
		headers: header,
		url: `${instanceUrl}/api/pm/remove-corporate-hashtag`,
		data: body,
	})
		.then((res) => {
			if (res.status === 201) {
				const response = {
					hashId,
					token: res.data.token,
				};
				dispatch(CORP_HASHTAG_DELETE_SUCCESS(response));
			} else {
				dispatch(UPDATE_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			dispatch(UPDATE_FAIL());
		});
};
export const updateCorporateProfilePicture = ({
	acctId,
	email,
	profilePic,
	accessToken,
	bizId,
}) => async (dispatch) => {
	const body = {
		acctId,
		email,
		profilePic,
		accessToken,
		bizId,
	};
	console.log(body);
	await axios({
		method: "PUT",
		headers: header,
		url: `${instanceUrl}/api/pm/upload-corporate-profile-pic`,
		data: body,
	})
		.then((res) => {
			if (res.status === 201) {
				const response = {
					profilePic,
					token: res.data.token,
				};
				dispatch(CORP_PICTURE_UPDATE_SUCCESS(response));
			} else {
				dispatch(UPDATE_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};
export const updateCorporateBackgroundPicture = ({
	acctId,
	email,
	backgroundPic,
	backgroundTemplateFlag,
	accessToken,
	backgroundPicBase64,
	bizId,
}) => async (dispatch) => {
	const body = {
		acctId,
		email,
		backgroundPic,
		backgroundTemplateFlag,
		accessToken,
		bizId,
	};
	dispatch(CORP_RESET_UPDATE_BACKGROUND());
	await axios({
		method: "PUT",
		headers: header,
		url: `${instanceUrl}/api/pm/upload-corporate-background-pic`,
		data: body,
	})
		.then((res) => {
			if (res.status === 201) {
				const response = {
					backgroundPic,
					backgroundTemplateFlag,
					token: res.data.token,
					backgroundPicBase64: backgroundPicBase64,
				};
				dispatch(CORP_BACKGROUND_UPDATE_SUCCESS(response));
			} else {
				dispatch(UPDATE_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const resetCorpProfile = () => async (dispatch) => {
	dispatch(CORP_PROFILE_RESET());
};
export default corpUserSlice.reducer;
