import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { instanceUrl, header, emptyCache, communityArticlePath } from 'configurations/configurations.js';
require('dotenv').config();

const communitySlice = createSlice({
  name: 'community',
  initialState: {
    searchResult: [],
    keywordResult: [],
    searchParams: [],
    lastRecordSize: 0,
    requestRecordSize: 0,
    isLoading: false,
    isSearching: false,
    cardAdsLoading: false,
    bannerAdsLoading: false,
    isUpdated: true,
    isSelected: true,
    selectedArticle: [],
    searchTag: [],
    defaultTag: [],
    defaultFilterTag: [],
    searchFilterTag: [],
    communityArticlePath: communityArticlePath,
    keyword: '',
    cardAds: [],
    bannerAds: [],
    cardAdsCount: null,
    bannerAdsCount: null,
    adsCountLoading: null,
  },
  reducers: {
    ARTICLE_SELECT_SUCCESS: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      state.selectedArticle = action.payload.articleList;
      state.isSelected = true;
      state.isLoading = false;
      state.isUpdated = true;
    },
    ARTICLE_SELECT_FAIL: state => {
      localStorage.removeItem('token');
      state.isSelected = false;
      state.isLoading = false;
      state.isUpdated = false;
    },
    CARD_ADS_COUNT_LOADING: state => {
      state.adsCountLoading = true;
      state.cardAdsCount = null;
    },
    CARD_ADS_COUNT_LOAD_SUCCESS: (state, action) => {
      state.adsCountLoading = false;
      state.cardAdsCount = action.payload.cardAdsCount;
      localStorage.setItem('token', action.payload.token);
    },
    DEFAULT_ARTICLE_SEARCH: state => {
      state.searchResult = [];
      state.searchParams = [];
      state.lastRecordSize = 0;
      state.requestRecordSize = 0;
      state.isSearching = false;
      state.keyword = '';
    },
    ARTICLE_LOADING: (state, action) => {
      state.isLoading = true;
    },
    CARD_ADS_LOADING: state => {
      state.cardAdsLoading = true;
    },
    BANNER_ADS_LOADING: state => {
      state.bannerAdsLoading = true;
    },
    ARTICLE_CATEGORY_LOAD_SUCCESS: (state, action) => {
      state.isLoading = false;
      state.defaultTag = [...action.payload.defaultTag];
      state.searchTag = [...action.payload.defaultTag];
      state.defaultFilterTag = [...action.payload.defaultFilterTag];
      state.searchFilterTag = [...action.payload.defaultFilterTag];
    },
    ARTICLE_SEARCH_SUCCESS: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      state.searchResult = [...action.payload.searchResult];
      if (action.payload.defaultLoad) {
        state.keywordResult = [...state.searchResult];
      } else {
        if (action.payload.keyword === '') state.keywordResult = [...state.searchResult];
        else state.keywordResult = [...action.payload.keywordResult];
      }
      state.searchParams = action.payload.searchParams;
      state.lastRecordSize = state.lastRecordSize + action.payload.requestRecordSize;
      state.requestRecordSize = action.payload.requestRecordSize;
      console.log(state.lastRecordSize, state.requestRecordSize);
      state.isLoading = false;
      state.isUpdated = true;
      state.keyword = action.payload.keyword;
      state.isSearching = false;
    },
    ARTICLE_SEARCH_FAIL: state => {
      state.isUpdated = false;
      state.loadingArticleList = false;
    },
    SEARCH_TAG_ADD: (state, action) => {
      state.isSearching = true;
      state.searchFilterTag = [...action.payload.searchFilterTag, ...action.payload.newFilterTag];
      state.searchTag = [...action.payload.remainTag, ...action.payload.newTag];
    },
    ADD_ALL_TAG: (state, action) => {
      state.isSearching = true;
      state.searchFilterTag = [...action.payload.searchFilterTag, ...action.payload.newFilterTag];
      state.searchTag = [...action.payload.remainTag, ...action.payload.newTag];
    },
    REMOVE_ALL_TAG: (state, action) => {
      state.isSearching = true;
      state.searchFilterTag = [...action.payload.searchFilterTag];
      state.searchTag = [...action.payload.newTag];
    },
    ARTICLE_SEARCH_BY_KEYWORD: (state, action) => {
      state.keyword = action.payload.keyword;
      state.isSearching = true;
    },
    SET_KEYWORD: (state, action) => {
      state.keyword = action.payload.keyword;
    },
    CARD_ADS_SELECT_SUCCESS: (state, action) => {
      state.cardAds = action.payload.cardAds;
      localStorage.setItem('token', action.payload.token);
      state.cardAdsLoading = false;
    },
    CARD_ADS_SELECT_FAIL: (state, action) => {
      state.adsLoading = false;
    },
    BANNER_ADS_SELECT_SUCCESS: (state, action) => {
      state.bannerAds = action.payload.bannerAds;
      localStorage.setItem('token', action.payload.token);
      state.bannerAdsLoading = false;
    },
    BANNER_ADS_SELECT_FAIL: state => {
      state.adsLoading = false;
    },
  },
});

const {
  ARTICLE_SELECT_SUCCESS,
  ARTICLE_SELECT_FAIL,
  DEFAULT_ARTICLE_SEARCH,
  ARTICLE_LOADING,
  ARTICLE_PAGINATION_LOADING,
  ARTICLE_CATEGORY_LOAD_SUCCESS,
  ARTICLE_SEARCH_SUCCESS,
  ARTICLE_SEARCH_FAIL,
  SEARCH_TAG_ADD,
  ADD_ALL_TAG,
  REMOVE_ALL_TAG,
  ARTICLE_SEARCH_BY_KEYWORD,
  SET_KEYWORD,
  CARD_ADS_SELECT_SUCCESS,
  CARD_ADS_LOADING,
  BANNER_ADS_LOADING,
  CARD_ADS_SELECT_FAIL,
  BANNER_ADS_SELECT_FAIL,
  BANNER_ADS_SELECT_SUCCESS,
  CARD_ADS_COUNT_LOADING,
  CARD_ADS_COUNT_LOAD_SUCCESS,
} = communitySlice.actions;

export const searchArticleInfo = ({ acctId, accessToken, email, articleId }) => async dispatch => {
  const body = {
    acctId,
    accessToken,
    email,
    articleId,
  };
  dispatch(ARTICLE_LOADING());
  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/cc/get-article-by-article-id`,
    params: body,
  })
    .then(res => {
      if (res.status === 201) {
        console.log(res);

        const response = {
          articleList: res.data.payload.articleList,
          token: res.data.token,
        };
        dispatch(ARTICLE_SELECT_SUCCESS(response));
      } else {
        dispatch(ARTICLE_SELECT_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const getArticleSubcategoryByCategoryId = ({  accessToken, email, catId, lastRecordSize,requestRecordSize }) => async dispatch => {
  const body = {
    accessToken,
    email,
    catId,
    lastRecordSize,
	requestRecordSize,
  };
  dispatch(ARTICLE_LOADING());
  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/cc/get-article-subcategory-by-category-id`,
    params: body,
  })
    .then(res => {
      if (res.status === 201) {
        console.log(res);

        const response = {
          articleList: res.data.payload.subCategoryList,
		  resultCount: res.data.payload.resultCount,
          token: res.data.token,
        };
        dispatch(ARTICLE_SELECT_SUCCESS(response));
      } else {
        dispatch(ARTICLE_SELECT_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const getArticleBySubcategoryId = ({  accessToken, email, subCatId, lastRecordSize,requestRecordSize,keyword }) => async dispatch => {
  const body = {
    accessToken,
    email,
    subCatId,
    lastRecordSize,
	requestRecordSize,
	keyword
  };
  dispatch(ARTICLE_LOADING());
  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/cc/get-article-by-subcategory-id`,
    params: body,
  })
    .then(res => {
      if (res.status === 201) {
        console.log(res);

        const response = {
          articleList: res.data.payload.subCategoryList,
		  resultCount: res.data.payload.resultCount,
          token: res.data.token,
        };
        dispatch(ARTICLE_SELECT_SUCCESS(response));
      } else {
        dispatch(ARTICLE_SELECT_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};


export const getArticleList = ({
  acctId,
  acctTypeId,
  accessToken,
  email,
  categoryIds,
  subCategoryIds,
  lastRecordSize,
  requestRecordSize,
  paginationClick,
  defaultLoad,
  keyword,
}) => async dispatch => {
  const countBody = {
    accessToken,
    email,
    adsTypeId: 2,
  };
  dispatch(ARTICLE_LOADING());
  if (lastRecordSize === 0) {
    dispatch(DEFAULT_ARTICLE_SEARCH());
  }
  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/adsm/get-advertisement-count-by-type-id`,
    params: countBody,
  }).then(res => {
    if (res.status === 201) {
      console.log(res);
      const response = {
        token: res.data.token,
        cardAdsCount: res.data.payload.adsList[0].count,
      };
      const body = {
        acctId,
        acctTypeId,
        accessToken,
        email,
        categoryIds,
        subCategoryIds,
        lastRecordSize,
        requestRecordSize: res.data.payload.adsList[0].count > 0 ? 11 : 12,
        keyword: keyword && keyword.charAt(0) == '#' ? keyword.slice(1) : keyword,
      };
      console.log(body);
      dispatch(CARD_ADS_COUNT_LOAD_SUCCESS(response));

      axios({
        method: 'GET',
        headers: header,
        url: `${instanceUrl}/api/cc/get-article-by-category-id`,
        params: body,
      })
        .then(res => {
          console.log(res, 'reduxxxxx');
          setTimeout(() => {
            if (res.status === 201) {
              var keywordFilter = [];
              for (var i = 0; i < res.data.payload.articleList.length; i++) {
                if (keyword.trim() != '') {
                  var articleName = res.data.payload.articleList[i].articleName.toLowerCase();
                  if (articleName.includes(keyword.toLowerCase())) keywordFilter.push(res.data.payload.articleList[i]);
                }
              }
              // console.log(res.data.payload.articleList, keywordFilter);
              const response = {
                searchResult: res.data.payload.articleList,
                keywordResult: keywordFilter,
                searchParams: {
                  categoryIds,
                  subCategoryIds,
                },
                lastRecordSize,
                requestRecordSize: res.data.payload.articleList.length,
                token: res.data.token,
                defaultLoad: defaultLoad,
                keyword: keyword && keyword.charAt(0) == '#' ? keyword.slice(1) : keyword,
              };
              dispatch(ARTICLE_SEARCH_SUCCESS(response));
            } else {
              dispatch(ARTICLE_SEARCH_FAIL());
            }
          }, 500);
        })
        .catch(err => {
          if (err.message.includes('503')) {
            emptyCache();
          }
          console.log(err);
        });
    } else {
      dispatch(CARD_ADS_SELECT_FAIL());
    }
  });
};
export const getArticleListGS = ({
  acctId,
  acctTypeId,
  accessToken,
  email,
  categoryIds,
  subCategoryIds,
  lastRecordSize,
  requestRecordSize,
  paginationClick,
  defaultLoad,
  keyword,
}) => async dispatch => {
  const countBody = {
    accessToken,
    email,
    adsTypeId: 2,
  };
  dispatch(ARTICLE_LOADING());
  const body = {
    acctId,
    acctTypeId,
    accessToken,
    email,
    categoryIds,
    subCategoryIds,
    lastRecordSize,
    requestRecordSize,
    keyword,
  };
  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/cc/get-article-by-category-id`,
    params: body,
  })
    .then(res => {
      console.log(res, 'reduxxxxx');
      setTimeout(() => {
        if (res.status === 201) {
          var keywordFilter = [];
          for (var i = 0; i < res.data.payload.articleList.length; i++) {
            if (keyword && keyword.length > 0 && keyword.trim() != '') {
              var articleName = res.data.payload.articleList[i].articleName.toLowerCase();
              if (articleName.includes(keyword.toLowerCase())) keywordFilter.push(res.data.payload.articleList[i]);
            }
          }
          // console.log(res.data.payload.articleList, keywordFilter);
          const response = {
            searchResult: res.data.payload.articleList,
            keywordResult: keywordFilter,
            searchParams: {
              categoryIds,
              subCategoryIds,
            },
            lastRecordSize,
            requestRecordSize: res.data.payload.articleList.length,
            token: res.data.token,
            defaultLoad: defaultLoad,
            keyword: keyword,
          };
          dispatch(ARTICLE_SEARCH_SUCCESS(response));
        } else {
          dispatch(ARTICLE_SEARCH_FAIL());
        }
      }, 500);
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};
export const getArticleListByKeyword = ({ keyword }) => async dispatch => {
  const response = {
    keyword: keyword.trim(),
  };
  dispatch(ARTICLE_SEARCH_BY_KEYWORD(response));
};

export const setKeyword = keyword => async dispatch => {
  const response = {
    keyword: keyword.trim(),
  };
  dispatch(SET_KEYWORD(response));
};

export const searchTagAdd = (
  mainCatId,
  subCatId,
  searchTag,
  defaultTag,
  searchFilterTag,
  defaultFilterTag
) => dispatch => {
  var newTag = [];
  var newSubCat = '';
  var checkSubCat = [];
  var newMainCat = defaultTag.filter(item => item.mainCatId === mainCatId);
  if (newMainCat && newMainCat.length === 1) {
    newSubCat = newMainCat[0].subCat.filter(item => item.subCatId === subCatId);
  }
  var checkSearchTag = searchTag.filter(item => item.mainCatId === mainCatId);
  if (checkSearchTag && checkSearchTag.length > 0) {
    var checkDuplicateTag = checkSearchTag[0].subCat.filter(item => item.subCatId === subCatId);
    if (checkDuplicateTag.length === 0) checkSubCat.push(...newSubCat);
    checkSubCat.push(...checkSearchTag[0].subCat.filter(item => item.subCatId !== subCatId));
  } else checkSubCat.push(...newSubCat);
  var newCat = {
    mainCatId: newMainCat[0].mainCatId,
    mainCat: newMainCat[0].mainCat,
    subCat: checkSubCat,
  };
  newTag.push(newCat);
  var remainTag = searchTag.filter(item => item.mainCatId !== mainCatId);
  var newFilterTag = [];
  // console.log(searchFilterTag, defaultFilterTag);
  // console.log(mainCatId, subCatId);
  for (var i = 0; i < defaultFilterTag.length; i++) {
    if (defaultFilterTag[i].catId === mainCatId || defaultFilterTag[i].catId === mainCatId + '_' + subCatId) {
      var filterTag = {
        catId: defaultFilterTag[i].catId,
        cat: defaultFilterTag[i].cat,
      };
      var checkDuplicateTag = searchFilterTag.filter(item => {
        return item.catId === defaultFilterTag[i].catId;
      });
      if (checkDuplicateTag.length === 0) newFilterTag.push(filterTag);
    }
  }
  const response = {
    mainCatId,
    subCatId,
    remainTag,
    newTag,
    searchFilterTag,
    newFilterTag,
  };
  // console.log(response);
  dispatch(SEARCH_TAG_ADD(response));
};

export const searchTagRemove = (mainCatId, subCatId, searchTag, searchFilterTag, defaultFilterTag) => dispatch => {
  // console.log(mainCatId, subCatId, searchTag, searchFilterTag);
  var newTag = [];
  var splitCatId = '';
  if (subCatId === 'tag') {
    if (mainCatId.toString().includes('_')) {
      //subcat
      splitCatId = mainCatId.split('_');
      searchFilterTag = searchFilterTag.filter(item => item.catId !== mainCatId);
      var newFilterTag = searchFilterTag.filter(item => item.catId.toString().includes(splitCatId[0] + '_'));
      if (newFilterTag.length === 0) searchFilterTag = searchFilterTag.filter(item => item.catId !== splitCatId[0]);
    } else {
      //maincat
      searchFilterTag = searchFilterTag.filter(
        item => item.catId !== mainCatId && !item.catId.toString().includes(mainCatId + '_')
      );
    }
  } else {
    searchFilterTag = searchFilterTag.filter(item => item.catId !== mainCatId + '_' + subCatId);
    var newFilterTag = searchFilterTag.filter(item => item.catId.toString().includes(mainCatId + '_'));
    if (newFilterTag.length === 0) searchFilterTag = searchFilterTag.filter(item => item.catId !== mainCatId);
  }
  if (mainCatId.toString().includes('_')) {
    mainCatId = splitCatId[0];
    subCatId = splitCatId[1];
  }
  if (subCatId !== 'tag') {
    var newMainCat = searchTag.filter(item => item.mainCatId.toString() === mainCatId.toString());
    if (newMainCat && newMainCat.length === 1) {
      var newSubCat = newMainCat[0].subCat.filter(item => item.subCatId.toString() !== subCatId.toString());
      var newCat = {
        mainCatId: newMainCat[0].mainCatId,
        mainCat: newMainCat[0].mainCat,
        subCat: newSubCat,
      };
      if (newSubCat.length > 0) newTag.push(newCat);
    }
  }
  var remainTag = searchTag.filter(item => item.mainCatId.toString() !== mainCatId.toString());
  var newFilterTag = [];
  // console.log(mainCatId, subCatId, remainTag, newTag, searchFilterTag);
  const response = {
    mainCatId,
    subCatId,
    remainTag,
    newTag,
    searchFilterTag,
    newFilterTag,
  };
  dispatch(SEARCH_TAG_ADD(response));
};

export const addAllTag = (mainCatId, searchTag, defaultTag, searchFilterTag, defaultFilterTag) => dispatch => {
  var newFilterTag = [];
  var remainTag = searchTag.filter(item => item.mainCatId !== mainCatId);
  var newTag = defaultTag.filter(item => item.mainCatId === mainCatId);
  for (var i = 0; i < defaultFilterTag.length; i++) {
    if (defaultFilterTag[i].catId === mainCatId || defaultFilterTag[i].catId.toString().includes(mainCatId + '_')) {
      var filterTag = {
        catId: defaultFilterTag[i].catId,
        cat: defaultFilterTag[i].cat,
      };
      var checkDuplicateTag = searchFilterTag.filter(item => {
        return item.catId === defaultFilterTag[i].catId;
      });
      if (checkDuplicateTag.length === 0) newFilterTag.push(filterTag);
    }
  }
  // console.log(searchTag, defaultTag, newTag, remainTag);
  const response = {
    mainCatId,
    remainTag,
    newTag,
    searchFilterTag,
    newFilterTag,
  };
  dispatch(ADD_ALL_TAG(response));
};

export const removeAllTag = (mainCatId, searchTag, defaultTag, searchFilterTag, defaultFilterTag) => dispatch => {
  var newTag = searchTag.filter(item => item.mainCatId !== mainCatId);
  searchFilterTag = searchFilterTag.filter(
    item => item.catId !== mainCatId && !item.catId.toString().includes(mainCatId + '_')
  );
  // console.log(searchTag, defaultTag, newTag);
  const response = { mainCatId, newTag, searchFilterTag };
  dispatch(REMOVE_ALL_TAG(response));
};

export const getArticleCategory = acctTypeId => async dispatch => {
  const body = {
    acctTypeId,
  };
  dispatch(ARTICLE_LOADING());
  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/md/get-all-article-category`,
    params: body,
  })
    .then(res => {
      console.log(res.data.payload);
      if (res.status === 201) {
        var defaultTag = [];
        var defaultFilterTag = [];
        var mainCatId = -1;
        var mainCat = '';
        var subCatId = -1;
        var subCat = '';
        var newList = res.data.payload.sort(function (a, b) {
          if (a.categoryId < b.categoryId) {
            return -1;
          }
          if (a.categoryId > b.categoryId) {
            return 1;
          }

          return 0;
        });
        for (var i = 0; i < newList.length; i++) {
          if (newList[i].categoryId !== mainCatId) {
            mainCatId = newList[i].categoryId;
            mainCat = newList[i].category;
            var mainFilterTag = {
              catId: mainCatId,
              cat: mainCat,
            };
            defaultFilterTag.push(mainFilterTag);
            var subTag = [];
            for (var j = 0; j < newList.length; j++) {
              var category = newList[j].subCategory;
              console.log(category);
              for (var k = 0; k < category.length; k++) {
                if (category[k].categoryId === mainCatId) {
                  subCatId = category[k].subCategoryId;
                  subCat = category[k].subCategory;
                  var tag = {
                    subCatId: subCatId,
                    subCat: subCat,
                  };
                  var checkDuplicateTag = subTag.filter(item => {
                    return item.subCatId === subCatId;
                  });
                  if (checkDuplicateTag.length === 0) {
                    subTag.push(tag);
                    var subFilterTag = {
                      catId: mainCatId + '_' + subCatId,
                      cat: subCat,
                    };
                    defaultFilterTag.push(subFilterTag);
                  }
                }
              }
            }
            var mainTag = {
              mainCatId: mainCatId,
              mainCat: mainCat,
              subCat: subTag,
            };
            defaultTag.push(mainTag);
          }
        }
      }
      const response = {
        defaultTag: defaultTag,
        defaultFilterTag: defaultFilterTag,
      };
      dispatch(ARTICLE_CATEGORY_LOAD_SUCCESS(response));
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};
export const getCardAds = ({ accessToken, email, adsTypeId }) => async dispatch => {
  const body = {
    accessToken,
    email,
    adsTypeId,
  };

  dispatch(CARD_ADS_LOADING());
  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/adsm/get-advertisement-by-type-id`,
    params: body,
  })
    .then(res => {
      console.log(res);
      if (res.status === 201) {
        console.log(res);

        const response = {
          cardAds: res.data.payload.adsList,
          token: res.data.token,
        };
        dispatch(CARD_ADS_SELECT_SUCCESS(response));
      } else {
        dispatch(CARD_ADS_SELECT_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};
export const getBannerAds = ({ accessToken, email, adsTypeId }) => async dispatch => {
  const body = {
    accessToken,
    email,
    adsTypeId,
  };

  dispatch(BANNER_ADS_LOADING());
  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/adsm/get-advertisement-by-type-id`,
    params: body,
  })
    .then(res => {
      console.log(res);
      if (res.status === 201) {
        console.log(res);

        const response = {
          bannerAds: res.data.payload.adsList,
          token: res.data.token,
        };
        dispatch(BANNER_ADS_SELECT_SUCCESS(response));
      } else {
        dispatch(BANNER_ADS_SELECT_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};
export const getCardAdsCount = ({ accessToken, email, adsTypeId }) => async dispatch => {
  const body = {
    accessToken,
    email,
    adsTypeId,
  };
  dispatch(ARTICLE_LOADING());
  dispatch(CARD_ADS_COUNT_LOADING());
  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/adsm/get-advertisement-count-by-type-id`,
    params: body,
  })
    .then(res => {
      console.log(res);
      if (res.status === 201) {
        const response = {
          token: res.data.token,
          cardAdsCount: res.data.payload.adsList[0].count,
        };
        console.log(response);
        dispatch(CARD_ADS_COUNT_LOAD_SUCCESS(response));
      } else {
        dispatch(CARD_ADS_SELECT_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export default communitySlice.reducer;
