/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

import OwnPost from './OwnPost';
import OtherUserPost from './OtherUserPost';
import SponsoredAd from './SponsoredAd.js';
import ProfileViewActivityCard from '../ProfileView/MyActivity/ProfileViewActivityCard';
import ProfileViewActivityCardNew from '../ProfileView/MyActivity/ProfileViewActivityCardNew';
import ProfileViewActivityPostCard from '../ProfileView/MyActivity/ProfileViewActivityPostCard';
import ProfileViewActivityPollCard from '../ProfileView/MyActivity/ProfileViewActivityPollCard';
import Job from './Job.js';
import CardSkeleton from './CardSkeleton';
import PostSomethingCard from '../ProfileView/MyActivity/PostSomethingCard';

import axios from 'axios';
import clsx from 'clsx';
import dateFormat from 'dateformat';

import {
  instanceUrl,
  header,
  decryptData,
  // getSocialPostByTop,
  getSavePostByAcctId,
  getReportType,
  getReportReasonByReasonType,
} from 'configurations/configurations';

import {
  makeStyles,
  Card,
  CardContent,
  Typography,
  Button,
  Input,
  IconButton,
  CardHeader,
  Popover,
} from '@material-ui/core';

import RecommendedJobs from 'views/Component/RecommendedJobs.js';

import camp from 'assets/img/faces/camp.jpg';
import dotBtn from 'assets/images/dotBtn.png';
import like from 'assets/images/like.png';
import comment from 'assets/images/comment.png';
import share from 'assets/images/share.png';
import saved from 'assets/images/saved.png';
import file from 'assets/images/file.png';
import emoji from 'assets/images/emoji.png';
import gallery from 'assets/images/documentation.png';
import commentStyle from '../../../assets/jss/material-kit-pro-react/views/componentStyle/socialFeed/commentStyles';
import socialFeedStyles from '../../../assets/jss/material-kit-pro-react/views/componentStyle/socialFeed/socialFeedStyles.js';
import profileViewActivityPostCardStyles from '../../../assets/jss/material-kit-pro-react/views/componentStyle/profileView/myActivity/profileViewActivityPostCardStyles.js';

import {
  getSocialHashtag,
  getSocialProfileTag,
  getSocialPostByRecent,
  getSocialPostByTop,
  getSocialPostByHashId,
  resetHashtagMatch,
  resetPostGet,
  getSocialProfileTagByGroupId,
  getSocialPostByRecommended,
} from 'configurations/redux/socialFeedSlice.js';

import { createBlockProfile, resetBlockProfile } from 'configurations/redux/userSlice.js';
import { resetReport } from 'configurations/redux/reportSlice.js';
import { isMobile, isMobileOnly } from 'react-device-detect';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CustomDialog from '../../../components/CustomDialog/CustomDialog.js';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import NoPostCard from '../../../views/Component/ProfileView/MyActivity/NoPostCard';

const SocialRecommendedFeed = props => {
  const {
    handleEditPostClick,
    handleSharePostClick,
    hideAddon,
    handleCreatePostClick,
    scrollableTarget,
    isGroupMember,
    isInfiniteEnabled = true,
    postStatus,
    isInGroup,
    isSearchPage,
  } = props;
  const history = useHistory();
  const user = useSelector(state => state.auth.user);
  const auth = useSelector(state => state.auth);
  const styles = makeStyles(socialFeedStyles)();
  const classes = makeStyles(commentStyle)();
  const styles2 = makeStyles(profileViewActivityPostCardStyles)();
  const dispatch = useDispatch();

  const ariaLabel = { 'aria-label': 'description' };
  const [reportTypesList, setReportTypesList] = useState([]);
  const [reportReasonsList, setReportReasonsList] = useState([]);
  const [reportReasonsListHashtag, setReportReasonsListHashtag] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);
  const { scrollRef } = useRef(null);
  const isFirstLoading = useSelector(state => state.socialFeed.isFirstLoading);
  const isLoadingPost = useSelector(state => state.socialFeed.isLoadingPost);
  const isEnd = useSelector(state => state.socialFeed.isEnd);
  const isUpdated = useSelector(state => state.socialFeed.isUpdated);
  const [anchorEl, setAnchorEl] = useState(null);
  var previousPostListType = localStorage.getItem('postListType');
  const [postListType, setPostListType] = useState(previousPostListType ? previousPostListType : 'Recent');
  const hashtagMatch = useSelector(state => state.socialFeed.hashtagMatch);
  const isLoadingHashtagMatch = useSelector(state => state.socialFeed.isLoadingHashtagMatch);
  const hashId = useSelector(state => state.socialFeed.hashId);
  const userId = useSelector(state => state.socialFeed.userId);
  const groupId = useSelector(state => state.socialFeed.groupId);
  const pageId = useSelector(state => state.socialFeed.pageId);
  const keyword = useSelector(state => state.socialFeed.keyword);
  const reportRecord = useSelector(state => state.report.report);
  const blockId = useSelector(state => state.user.blockId);

  const dialogDefault = {
    title: '',
    description: '',
    buttonYes: '',
    buttonNo: '',
  };
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    dialogDefault,
  });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const snackBarShow = (message, variant, action) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, {
      variant: variant,
      action,
    });
  };

  const onClickDismiss = key => () => {
    closeSnackbar(key);
  };
  const snackbarCustom = title => {
    const action = key => (
      <React.Fragment>
        <IconButton onClick={onClickDismiss(key)}>
          <CloseIcon />
        </IconButton>
      </React.Fragment>
    );
    snackBarShow(title, 'default', action);
  };

  useEffect(() => {
    if (reportRecord) {
      if (reportRecord.userId) {
        dispatch(resetReport());
        var dialogDesc = 'Do you wish to block this account?';
        if (reportRecord.reportTypeId == 2)
          dialogDesc = 'The social post has been reported. Do you wish to block the author of this social post?';
        else if (reportRecord.reportTypeId == 3)
          dialogDesc = 'The comment has been reported. Do you wish to block the author of this comment?';
        const dialogDefault = {
          title: 'Alert',
          description: dialogDesc,
          buttonYes: 'Proceed',
          buttonNo: 'Cancel',
        };
        setConfirmDialog({
          isOpen: true,
          dialogDefault,
          onConfirm: () => {
            const request = {
              email: user.email,
              acctId: user.acctId,
              blockAcctId: reportRecord.userId,
              accessToken: localStorage.getItem('token'),
            };
            dispatch(createBlockProfile(request));
            setConfirmDialog({
              isOpen: false,
              dialogDefault,
            });
          },
          onCancel: () => {
            setConfirmDialog({
              isOpen: false,
              dialogDefault,
            });
          },
        });
      } else {
        if (reportRecord.reportTypeId == 5)
          snackbarCustom('The hashtag has been reported. The report will be reviewed shortly.');
      }
    }

    return () => { };
  }, [reportRecord]);

  useEffect(() => {
    if (blockId && blockId > 0) {
      snackbarCustom('The account has been blocked. The social post related to the account will be remove shortly.');
      dispatch(resetBlockProfile());
    }
  }, [blockId]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchReportReasonList();
      fetchReportTypeList();

      const profileRequest = {
        email: auth.isPublic ? auth.publicEmail : user.email,
        acctId: auth.isPublic ? 0 : user.acctId,
        keyword: '',
        groupId: groupId ? groupId : 0,
        lastRecordSize: 0,
        requestRecordSize: 10,
        accessToken: auth.isPublic ? auth.publicToken : localStorage.getItem('token'),
      };
      // console.log("groupId", profileRequest);
      if (groupId > 0) {
        dispatch(getSocialProfileTagByGroupId(profileRequest));
      } else {
        dispatch(getSocialProfileTag(profileRequest));
      }
      dispatch(getSocialHashtag(profileRequest));

      const requestProfile1 = {
        acctId: user.acctId,
        accessToken: localStorage.getItem('token'),
        email: user.email,
        lastRecordSize: 0,
        requestRecordSize: 5,
      };

      dispatch(getSocialPostByRecommended(requestProfile1));
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const fetchReportReasonList = async () => {
    const res = await getReportReasonByReasonType(1);
    // console.log(res);
    setReportReasonsList(res.data.payload);
  };

  const fetchReportReasonListHashtag = async () => {
    const res = await getReportReasonByReasonType(5);
    // console.log(res);
    setReportReasonsListHashtag(res.data.payload);
  };

  const fetchReportTypeList = async () => {
    const res = await getReportType();
    // console.log(res);
    setReportTypesList(res.data.payload);
  };

  const searchResult = useSelector(state => state.socialFeed.searchResult);
  const searchResult1 = useSelector(state => state.socialFeed.searchResult1);

  const postResult = useSelector(state => state.socialFeed.postResult);

  // useEffect(() => {
  // 	if (searchResult) console.log(searchResult);
  // }, [searchResult]);
  const ResultCard =
    searchResult && searchResult.length > 0 ? (
      searchResult.map((post, index) => {
        return (
          <div key={post.postId}>
            {post.typeOptionId > -1 ? (
              <ProfileViewActivityCardNew
                // key={post.postId}
                post={post}
                reportTypesList={reportTypesList}
                reportReasonsList={reportReasonsList}
                updateFeedHandler={updateFeedHandler}
                isCopyPost={false}
                handleEditPostClick={handleEditPostClick}
                hashtagMatch={null}
                hideReactionBar={false}
                handleSharePostClick={handleSharePostClick}
                searchPost={false}
                isGroupMember={isGroupMember}
                postStatus={postStatus}
                isInGroup={isInGroup}
              />
            ) : null}
          </div>
        );
      })
    ) : postResult && postResult.length > 0 ? (
      postResult.map((post, index) => {
        return (
          <div key={post.postId}>
            {post.typeOptionId > -1 ? (
              <ProfileViewActivityCardNew
                // key={post.postId}
                post={post}
                reportTypesList={reportTypesList}
                reportReasonsList={reportReasonsList}
                updateFeedHandler={updateFeedHandler}
                isCopyPost={true}
                handleEditPostClick={handleEditPostClick}
                hashtagMatch={null}
                hideReactionBar={false}
                handleSharePostClick={handleSharePostClick}
                searchPost={false}
                isGroupMember={isGroupMember}
                postStatus={postStatus}
                isInGroup={isInGroup}
              />
            ) : null}
          </div>
        );
      })
    ) : (
      <b>No Recommended Posts Found</b>
    );

  const PostCard =
    postResult &&
    postResult.length > 0 &&
    searchResult &&
    searchResult.length > 0 &&
    postResult.map((post, index) => {
      return (
        <div key={post.postId}>
          {post.typeOptionId > -1 ? (
            <ProfileViewActivityCard
              // key={post.postId}
              post={post}
              reportTypesList={reportTypesList}
              reportReasonsList={reportReasonsList}
              updateFeedHandler={updateFeedHandler}
              isCopyPost={true}
              handleEditPostClick={handleEditPostClick}
              hashtagMatch={null}
              hideReactionBar={false}
              handleSharePostClick={handleSharePostClick}
              searchPost={false}
              isGroupMember={isGroupMember}
              postStatus={postStatus}
              isInGroup={isInGroup}
            />
          ) : null}
        </div>
      );
    });

  const HashtagCard = hashtagMatch ? (
    <div key={hashtagMatch.hashId}>
      <ProfileViewActivityCard
        // key={post.postId}
        post={null}
        reportTypesList={reportTypesList}
        reportReasonsList={reportReasonsListHashtag}
        updateFeedHandler={updateFeedHandler}
        isCopyPost={false}
        handleEditPostClick={handleEditPostClick}
        hashtagMatch={hashtagMatch}
        hideReactionBar={false}
        handleSharePostClick={handleSharePostClick}
        searchPost={false}
        isGroupMember={false}
        postStatus={postStatus}
        isInGroup={isInGroup}
      />
    </div>
  ) : null;

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchReportReasonListHashtag();
      getByHashId(0);
    }
    return () => {
      isMounted = false;
    };
  }, [hashtagMatch]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (postResult && postResult.length > 0) {
        scrollToTop();
        // window.scrollTo({ top: 0, behavior: "smooth" });
        loadFirst();
      }
    }
    return () => {
      isMounted = false;
    };
  }, [postResult]);

  const scrollToTop = () => {
    var timer = setInterval(() => {
      var result = document.getElementById('socialFeedPage');
      if (result) {
        result.scrollIntoView();
        window.scrollTo(0, 0);
        clearInterval(timer);
      }
    }, 100);
  };

  const loadFirst = () => {
    // if (postListType == "Recent")
    getByRecent(0);
    // else getByTop(0);
  };

  // load more on scroll to endRef
  const loadMore = () => {
    // console.log("load more");
    var postCount = 0;
    if (postResult && postResult.length > 0) postCount = postResult.length;
    if (hashtagMatch) {
      getByHashId(searchResult.length + postCount);
    } else {
      // if (postListType == "Recent")
      getByRecent(searchResult.length + postCount);
      // else getByTop(searchResult.length + postCount);
    }
  };

  const getByRecent = lastRecordSize => {
    var previousPostListType = localStorage.getItem('postListType');
    const requestProfile = {
      acctId: user.acctId,
      accessToken: localStorage.getItem('token'),
      email: user.email,
      lastRecordSize: lastRecordSize,
      requestRecordSize: 5,
      hashId: hashId,
      userId: userId,
      groupId: groupId,
      pageId: pageId,
      sortBy: previousPostListType ? previousPostListType : 'recent',
      keyword: keyword,
      visibleIds: '1,2',
    };
    //dispatch(getSocialPostByRecent(requestProfile));

    const requestProfile1 = {
      acctId: user.acctId,
      accessToken: localStorage.getItem('token'),
      email: user.email,
      lastRecordSize: lastRecordSize,
      requestRecordSize: 5,
      // hashId: hashId,
      // userId: userId,
      // groupId: groupId,
      // pageId: pageId,
      // sortBy: previousPostListType ? previousPostListType : 'recent',
      // keyword: keyword,
      // visibleIds: '1,2',
    };
    dispatch(getSocialPostByRecommended(requestProfile1));

  };

  const getByHashId = lastRecordSize => {
    var previousPostListType = localStorage.getItem('postListType');
    if (hashtagMatch) {
      const requestProfile = {
        acctId: user.acctId,
        accessToken: localStorage.getItem('token'),
        email: user.email,
        lastRecordSize: lastRecordSize,
        requestRecordSize: 5,
        // hashId: hashtagMatch.hashId,
        // userId: 0,
        // groupId: 0,
        // pageId: 0,
        // sortBy: previousPostListType ? previousPostListType : 'recent',
        // keyword: keyword,
        // visibleIds: '1,2',
      };
      // console.log(requestProfile);
      //dispatch(getSocialPostByHashId(requestProfile));

      const requestProfile1 = {
        acctId: user.acctId,
        accessToken: localStorage.getItem('token'),
        email: user.email,
        lastRecordSize: lastRecordSize,
        requestRecordSize: 5,

      };

      dispatch(getSocialPostByRecommended(requestProfile1));

    }
  };

  const updateFeedHandler = () => {
    let isMounted = true;

    // console.log("Updated");
  };

  const handleClick = () => {
    handleCreatePostClick('post');
  };

  //   useEffect(
  //     () =>
  //       history.listen(() => {
  //         let isMounted = true;
  //         console.log('route change');
  //         dispatch(resetHashtagMatch());
  //         dispatch(resetPostGet());
  //         return () => {
  //           isMounted = false;
  //         };
  //       }),
  //     []
  //   );

  return (
    <React.Fragment>
      {confirmDialog && <CustomDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />}
      {isUpdated ? (
        <div id="socialFeedList">

          {isLoadingPost && !isEnd ? (
            <div>
              <CardSkeleton />
            </div>
          ) : (
            <div>{PostCard}</div>
          )}
          {hashtagMatch && !hideAddon ? (
            isLoadingHashtagMatch ? (
              <div>
                <CardSkeleton />
              </div>
            ) : (
              <div>{HashtagCard}</div>
            )
          ) : null}

           {isFirstLoading ? (
            <div>
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
            </div>
          ) : postResult && postResult.length > 0 ?(
            <div>
              <InfiniteScroll
                className={styles.infiScroll}
                dataLength={searchResult.length}
                next={loadMore}
                hasMore={isInfiniteEnabled && !isEnd}
                // scrollThreshold={0.7}
                loader={<CardSkeleton />}
                scrollableTarget={scrollableTarget}
                // height={window.innerHeight}
                style={isMobile ? { overflowX: 'hidden' } : { overflow: 'hidden' }}
              >
                {ResultCard}
              </InfiniteScroll>
            </div>
          ):  ResultCard}{' '}
        </div>
      ) : null}
      <Popover
        id={anchorEl ? 'simple-popover' : undefined}
        open={anchorEl ? true : false}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <div className={styles.popoverBox}>
          <Typography
            component="div"
            className={classes.popoverListItem}
            onClick={() => {
              setPostListType('Recent');
              localStorage.setItem('postListType', 'Recent');
              setAnchorEl(null);
              getByRecent(0);
            }}
          >
            <div className={classes.popoverListItemText}>
              <div
                className={classes.popoverListItemTitle}
                style={{ fontWeight: postListType == 'Recent' ? 500 : 400 }}
              >
                Recent
              </div>
            </div>
          </Typography>
          <Typography
            component="div"
            className={classes.popoverListItem}
            onClick={() => {
              setPostListType('Top');
              localStorage.setItem('postListType', 'Top');
              setAnchorEl(null);
              getByRecent(0);
            }}
          >
            <Typography component="div" className={classes.popoverListItemText}>
              <Typography
                component="div"
                className={classes.popoverListItemTitle}
                style={{ fontWeight: postListType == 'Top' ? 500 : 400 }}
              >
                Top
              </Typography>
            </Typography>
          </Typography>
        </div>
      </Popover>
    </React.Fragment>
  );
};

export default SocialRecommendedFeed;
