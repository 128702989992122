import React from 'react';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import { useSelector, useDispatch } from 'react-redux';

import searchPageStyle from 'assets/jss/material-kit-pro-react/views/pageStyle/SearchPage/searchPageStyle.js';
import searchJobPageStyle from 'assets/jss/material-kit-pro-react/views/pageStyle/SearchJobPage/searchJobPageStyle.js';

import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import SmallCardLoading from '../SmallCardLoading.js';
import { encryptData, instanceUrl, header } from 'configurations/configurations.js';
import Pagination from 'views/Component/PaginationWithPage.js';
import { searchJobInfo } from 'configurations/redux/searchJobSlice';
import JobCard from './JobCard.js';
import NoResultCard from '../NoResultCard';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import SearchResultCard from '../../SearchJobPage/SearchResultCard';

const useStyles = makeStyles(searchPageStyle);

export default function JobComponentInPage() {
  const classes = useStyles();
  const jobStyle = makeStyles(searchJobPageStyle)();
  const dispatch = useDispatch();
  const searchResult = useSelector(state => state.searchJob.searchResult);
  const searchParams = useSelector(state => state.searchJob.searchParams);
  const userStatus = useSelector(state => state.auth.user);
  const isLoading = useSelector(state => state.searchJob.isLoading);
  const auth = useSelector(state => state.auth);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [jobPerPage, setJobPerPage] = React.useState(12);
  const indexOfLast = currentPage * jobPerPage;
  const indexOfFirst = indexOfLast - jobPerPage;

  const paginate = pageNumber => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setCurrentPage(pageNumber);
    var indexOfFirst = pageNumber * jobPerPage - jobPerPage;

    const requestProfile = {
      acctId: auth.isPublic ? 0 : userStatus.acctId,
      accessToken: auth.isPublic ? auth.publicToken : localStorage.getItem('token'),
      email: auth.isPublic ? auth.publicEmail : userStatus.email,
      keyword: searchParams.keyword,
      countryId: searchParams.countryId,
      locationId: searchParams.locationId,
      companyName: '',
      industryId: searchParams.industryId,
      hotJobFlag: 0,
      jobPostTypeId: searchParams.jobPostTypeId,
      jobTypeId: searchParams.jobTypeId,
      lastRecordSize: indexOfFirst,
      experienceId: searchParams.experienceId,
      currencyId: searchParams.currencyId,
      salaryMin: searchParams.salaryMin,
      salaryMax: searchParams.salaryMax,
      requestRecordSize: jobPerPage,
      genericSearch: true,
    };
    dispatch(searchJobInfo(requestProfile));
    scrollToTop();
  };
  const scrollToTop = () => {
    var timer = setInterval(() => {
      var jobPostResult = document.getElementById('searchResultJob');
      if (jobPostResult) {
        jobPostResult.scrollIntoView();
        window.scrollTo(0, 0);
        clearInterval(timer);
      }
    }, 100);
  };
  const JobList =
    searchResult &&
    searchResult.length > 0 &&
    searchResult.map((item, index) => {
      return (
        <GridItem xs={12} sm={6} md={6} lg={4} xl={3} key={index}>
          <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
            <SearchResultCard key={item.jobPostId} item={item} />
          </div>
        </GridItem>
        // <div key={item.jobPostId}>
        //   <JobCard item={item} />
        // </div>
      );
    });

  const loadingFields = [];
  for (let i = 0; i < jobPerPage - searchResult.length; i++) {
    loadingFields.push(
      <div key={i}>
        <SmallCardLoading />
      </div>
    );
  }
  return (
    <Card className={classes.cardFrame}>
      <CardBody className={classes.cardBodyPadding}>
        <h4 className={classes.componentTitle}>Job</h4>
        {isLoading &&
          loadingFields.map(item => {
            return item;
          })}
        <div className={classes.mainContainer}>
          {/* {JobList}{" "} */}
          <GridContainer className={jobStyle.jobCardGrid}>{JobList}</GridContainer>
          {!isLoading && searchResult.length === 0 && <NoResultCard showImage={true} />}{' '}
        </div>
        {searchResult && searchResult.length > 0 && (
          <Pagination totalPosts={searchResult[0].totalCount} postsPerPage={jobPerPage} paginate={paginate} />
        )}
      </CardBody>
    </Card>
  );
}
