import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { getJobPosts, getCoporateJobPosts } from "configurations/redux/jobManagementSlice.js";

//Page Components
import Header from "views/Component/Header.js";
import Footer from "views/Component/Footer.js";

// import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
// import EditOutlinedIcon from "@material-ui/icons/EditOutlined";

// import Divider from "@material-ui/core/Divider";

import NoActivityStatusModal from "../NoActivityStatusModal/NoActivityStatusModal.js";

// import classNames from "classnames";

import { makeStyles } from "@material-ui/core/styles";

import corporateJobManagementPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/CorporateJobManagementPage/corporateJobManagementPageStyle.js";
import CorporateJobCard from "./CorporateJobCard.js";
import Pagination from "../../Component/PaginationWithPage.js";
// import LoadingModal from "views/Component/LoadingModal.js";
import Backdrop from "../../Component/Backdrop.js";
import IconLibrary from "views/Component/IconLibrary.js";
import { gaPageView } from "configurations/configurations.js";
import { Typography } from "@material-ui/core";

import { getUserSubscriptionByRecordId, getCurrentSubscribePack } from "configurations/redux/subscriptionSetting";

const useStyles = makeStyles(corporateJobManagementPageStyle);

export default function CorporateJobManagementPage() {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [currentPage, setCurrentPage] = React.useState(1);
	const [jobsPerPage, setJobsPerPage] = React.useState(5);
	// const [clientIp, setClientIp] = React.useState();
	const [draftJobShow, setDraftJobShow] = React.useState(false);
	const [publishedJobShow, setPublishedJobShow] = React.useState(false);
	const [deactivatedJobShow, setDeactivatedJobShow] = React.useState(false);
	const [archievedJobShow, setArchievedJobShow] = React.useState(false);
	const [headHuntingJobShow, setheadHuntingJobShow] = React.useState(false);
	const [statusId, setStatusId] = React.useState(1);
	const [typeId, setTypeId] = React.useState(1);
	const [active, setActive] = React.useState(true);

	const userStatus = useSelector((state) => state.auth.user);
	const isUpdated = useSelector((state) => state.jobManagement.isUpdated);
	const jobPostList = useSelector((state) => state.jobManagement.jobPostList);
	const statusDraft = useSelector((state) => state.jobManagement.statusDraft);
	const statusPublished = useSelector(
		(state) => state.jobManagement.statusPublished
	);
	const statusDeactivated = useSelector(
		(state) => state.jobManagement.statusDeactivated
	);

	const statusHeadhunting = useSelector(
		(state) => state.jobManagement.statusHeadhunting
	);

	// const currentStatusId = useSelector((state) => state.jobManagement.statusId);

	const isLoading = useSelector((state) => state.jobManagement.isLoading);
	const isDeleted = useSelector((state) => state.jobManagement.isDeleted);
	const isStatusChanged = useSelector(
		(state) => state.jobManagement.isStatusChanged
	);
	const loadingJobPostList = useSelector(
		(state) => state.jobManagement.loadingJobPostList
	);
	const bizId = useSelector((state) => state.business.bizId)


	// const requestProfile = {
	// 	acctId: userStatus.acctId,
	// 	accessToken: localStorage.getItem("token"),
	// 	lastRecordSize: 0,
	// 	requestRecordSize: 5,
	// 	paginationClick: false,
	// 	statusId: 1,
	// };

	// const indexOfLastJob = currentPage * jobsPerPage;
	// const indexOfFirstJob = indexOfLastJob - jobsPerPage;
	// const currentJobs = jobPostList.slice(indexOfFirstJob, indexOfLastJob);
	/* console.log(jobPostList); */
	const paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
		var indexOfFirstJob = pageNumber * jobsPerPage - jobsPerPage;
		getJobPost(indexOfFirstJob);
		window.scrollTo(0, 0);
	};
	/* 	console.log(currentJobs); */

	const jobShowReset = () => {
		setDraftJobShow(false);
		setPublishedJobShow(false);
		setDeactivatedJobShow(false);
		setArchievedJobShow(false);
		setActive(false)
		setheadHuntingJobShow(false)
	};

	let tabClickedClass = '';
	const clickDraftJobTab = () => {
		setCurrentPage(1);
		setStatusId(0);
		setTypeId(-1);
		jobShowReset();
		setDraftJobShow(true);
		const requestProfile = {
			acctId: userStatus.acctId,
			bizId: bizId,
			accessToken: localStorage.getItem("token"),
			lastRecordSize: 0,
			requestRecordSize: 5,
			paginationClick: false,
			statusId: 0,
			typeId: -1,
		};
		dispatch(getJobPosts(requestProfile));
	};
	const clickPublishedJobTab = () => {
		setCurrentPage(1);
		setStatusId(1);
		setTypeId(1);
		jobShowReset();
		setActive(true)
		setPublishedJobShow(true);
		const requestProfile = {
			acctId: userStatus.acctId,
			bizId: bizId,
			accessToken: localStorage.getItem("token"),
			lastRecordSize: 0,
			requestRecordSize: 5,
			paginationClick: false,
			statusId: 1,
			typeId: 1,
		};
		dispatch(getCoporateJobPosts(requestProfile));
	};
	const clickDeactivatedJobTab = () => {
		setCurrentPage(1);
		setStatusId(2);
		jobShowReset();
		setDeactivatedJobShow(true);
		const requestProfile = {
			acctId: userStatus.acctId,
			bizId: bizId,
			accessToken: localStorage.getItem("token"),
			lastRecordSize: 0,
			requestRecordSize: 5,
			paginationClick: false,
			statusId: 2,
		};
		dispatch(getJobPosts(requestProfile));
	};

	const clickArchievedJobTab = () => {
		setCurrentPage(1);
		setStatusId(2);
		setTypeId(-1);
		jobShowReset();
		setArchievedJobShow(true);
		const requestProfile = {
			acctId: userStatus.acctId,
			bizId: bizId,
			accessToken: localStorage.getItem("token"),
			lastRecordSize: 0,
			requestRecordSize: 5,
			paginationClick: false,
			statusId: 2,
			typeId: -1


		};
		dispatch(getCoporateJobPosts(requestProfile));
	};

	const clickHeadHuntingJobTab = () => {
		setCurrentPage(1);
		setStatusId(1);
		setTypeId(2);
		jobShowReset();
		setheadHuntingJobShow(true);
		const requestProfile = {
			acctId: userStatus.acctId,
			bizId: bizId,
			accessToken: localStorage.getItem("token"),
			lastRecordSize: 0,
			requestRecordSize: 5,
			paginationClick: false,
			statusId: 1,
			typeId: 2
		};
		dispatch(getCoporateJobPosts(requestProfile));
	}


	const getJobPost = async (indexOfFirstJob) => {
		const requestProfile = {
			acctId: userStatus.acctId,
			bizId: bizId,
			accessToken: localStorage.getItem("token"),
			lastRecordSize: indexOfFirstJob,
			requestRecordSize: jobsPerPage,
			paginationClick: true,
			statusId: statusId,
			typeId: typeId,
		};
		console.log('oagi');
		dispatch(getCoporateJobPosts(requestProfile));
		//dispatch(getJobPosts(requestProfile));
	};

	React.useEffect(() => {
		if (isDeleted) {
			var oldPage = currentPage;
			var newPage = 0;
			if (jobPostList.length > 0) newPage = currentPage;
			else newPage = currentPage - 1;
			var indexOfFirstJob = newPage * jobsPerPage - jobsPerPage;
			console.log(newPage);
			setCurrentPage(newPage);
			getJobPost(indexOfFirstJob);
			if (newPage != oldPage) window.scrollTo(0, 0);
		}
	}, [isDeleted]);

	React.useEffect(() => {
		if (isStatusChanged) {
			var newPage = 0;
			if (jobPostList.length > 0) newPage = currentPage;
			else newPage = currentPage - 1;
			var indexOfFirstJob = newPage * jobsPerPage - jobsPerPage;
			console.log(newPage);
			setCurrentPage(newPage);
			getJobPost(indexOfFirstJob);
			window.scrollTo(0, 0);
		}
	}, [isStatusChanged]);


	React.useEffect(() => {
		console.log('running');
		const request = {
			email: userStatus.email,
			acctId: userStatus.acctId,
			accessToken: localStorage.getItem('token'),
			recordId: userStatus.acctId,
			typeId: 2,
			plan_id: 1
		};
		console.log(request)

		dispatch(getCurrentSubscribePack(request));
	}, []);

	const subscriptionPlans = useSelector((state) => state.subscriptionSetting.subscriptionPlans);
	const currentSubscribed = useSelector((state) => state.subscriptionSetting.currentSubscription);

	//console.log('subscriptionPlans', subscriptionPlans);


	// React.useEffect(() => {
	// 	console.log(currentStatusId);
	// 	if (currentStatusId === 0) {
	// 		setCurrentPage(1);
	// 		setStatusId(0);
	// 		jobShowReset();
	// 		setDraftJobShow(true);
	// 	} else if (currentStatusId === 1) {
	// 		setCurrentPage(1);
	// 		setStatusId(0);
	// 		jobShowReset();
	// 		setPublishedJobShow(true);
	// 	} else if (currentStatusId === 2) {
	// 		setCurrentPage(1);
	// 		setStatusId(0);
	// 		jobShowReset();
	// 		setDeactivatedJobShow(true);
	// 	}
	// }, [currentStatusId]);

	React.useEffect(() => {

		gaPageView(window.location.pathname);
		clickPublishedJobTab();
		window.scrollTo(0, 0);
	}, []);

	// React.useEffect(() => {
	// 	dispatch(getJobPosts(requestProfile));
	// 	setPublishedJobShow(true);
	// 	window.scrollTo(0, 0);
	// }, [clientIp]);

	return (
		<div>
			<Header />
			<div className={classes.main + " " + classes.mainRaised}>
				<div className={classes.container}>
					{isLoading ? (
						<Backdrop />
					) : isUpdated ? (
						<React.Fragment>
							<GridContainer
								justifyContent="space-between"
								alignContent="center"
								alignItems="center"
								className='jobDashbaordOuter'
							>
								<GridItem xs={12} sm={12} md={5} lg={5}>
									<h4 className='jobTitle'>Job Dashboard</h4>
								</GridItem>
								<GridItem
									xs={12}
									sm={12}
									md={7}
									lg={7}
									className={classes.gridPadding}
								>
									<div className='createJobRightOuter'>
										<div className='createJobRightq'>
											<p className='upgradeText'>
												{currentSubscribed && currentSubscribed.length > 0 && currentSubscribed[0].planId === 0 ? 'You do not have any active subscription. Please subscribe to a paid plan to post non-internship job ads.' : 'You have used 2/5 job posts for the month.'}
											</p>
											<Button
												href="/corporate-job-create-page"
												// round
												color="oppty"
												fullWidth
												className={classes.buttonBorder}
											/* 	startIcon={<EditOutlinedIcon />} */
											>
												{/* <IconLibrary.Edit /> */}
												<b>Create Job Post</b>
											</Button>
										</div>
									</div>

								</GridItem>
							</GridContainer>

							<GridContainer>
								<GridItem
									xs={12}
									sm={12}
									md={12}
									lg={12}
								>
									<Typography component='div' className='tabsOuter'>
										<Button
											fullWidth
											round
											className={(publishedJobShow) ? 'active' : ''}
											// className={
											// 	publishedJobShow
											// 		? classes.selectedTabButton
											// 		: classes.tabButton
											// }
											onClick={() => {
												clickPublishedJobTab();
											}}
										>
											<b>
												Active ({statusPublished})
											</b>
										</Button>
										<Button
											fullWidth
											round
											className={(headHuntingJobShow) ? 'active' : ''}
											onClick={() => {
												clickHeadHuntingJobTab();
											}}
										// className={
										// 	publishedJobShow
										// 		? classes.selectedTabButton
										// 		: classes.tabButton
										// }
										>
											<b>
												Headhunting  ({statusHeadhunting})
											</b>
										</Button>
										<Button
											fullWidth
											round
											className={(archievedJobShow) ? 'active' : ''}
											onClick={() => {
												clickArchievedJobTab();
											}}
										// className={
										// 	deactivatedJobShow
										// 		? classes.selectedTabButton
										// 		: classes.tabButton
										// }
										>
											<b>
												Deactivated  (
												{statusDeactivated})
											</b>
										</Button>

										<Button
											fullWidth
											round
											className={(draftJobShow) ? 'active' : ''}
											onClick={() => {
												clickDraftJobTab();
											}}
										>
											<b>
												Draft  (
												{statusDraft})
											</b>
										</Button>
									</Typography>
								</GridItem>
							</GridContainer>

							{/* <GridContainer justifyContent="center">
								<GridItem
									xs={4}
									sm={4}
									md={4}
									lg={4}
									className={classes.gridPadding}
								>
									<Button
										fullWidth
										round
										className={
											draftJobShow
												? classes.selectedTabButton
												: classes.tabButton
										}
										onClick={() => {
											clickDraftJobTab();
										}}
									>
										<b>
											Draft Job{statusDraft > 1 && "s"} ({statusDraft})
										</b>
									</Button>
								</GridItem>
								<GridItem
									xs={4}
									sm={4}
									md={4}
									lg={4}
									className={classes.gridPadding}
								>
									<Button
										fullWidth
										round
										onClick={() => {
											clickPublishedJobTab();
										}}
										className={
											publishedJobShow
												? classes.selectedTabButton
												: classes.tabButton
										}
									>
										<b>
											Published Job{statusPublished > 1 && "s"} (
											{statusPublished})
										</b>
									</Button>
								</GridItem>
								<GridItem
									xs={4}
									sm={4}
									md={4}
									lg={4}
									className={classes.gridPadding}
								>
									<Button
										fullWidth
										round
										onClick={() => {
											clickDeactivatedJobTab();
										}}
										className={
											deactivatedJobShow
												? classes.selectedTabButton
												: classes.tabButton
										}
									>
										<b>
											Deactivated Job{statusDeactivated > 1 && "s"} (
											{statusDeactivated})
										</b>
									</Button>
								</GridItem>
							</GridContainer> */}

							<GridContainer>
								<GridItem className={classes.gridPadding}>
									{/* 	{isLoading && <Backdrop />} */}
									{/* <Card className={classes.modalCardBorder}>
										<CardBody className={classes.modalCardPadding}> */}
									<CorporateJobCard
										jobPostList={jobPostList}
										statusId={statusId}
									/>
									{/* </CardBody>
									</Card> */}
									<div className={classes.paginationAligned}>
										<Pagination
											totalPosts={
												jobPostList.length > 0
													? jobPostList[0].jobPostCount != undefined
														? jobPostList[0].jobPostCount
														: null
													: null
											}
											postsPerPage={jobsPerPage}
											paginate={paginate}
											page={currentPage}
										/>
									</div>
								</GridItem>
							</GridContainer>
						</React.Fragment>
					) : (
						<NoActivityStatusModal />
					)}
					{loadingJobPostList ? <Backdrop /> : null}
				</div>
			</div>
			<Footer />
		</div>
	);
}
