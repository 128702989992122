import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useLocation, useHistory } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import IconLibrary from "../../views/Component/IconLibrary.js";
import { useSelector } from "react-redux";
import candidate_avatar from "assets/img/candidate_avatar.jpeg";
import { encryptData } from "configurations/configurations.js";

const useStyles = makeStyles({
	root: {
		width: "100%",
		background: "white",
		height: "7vh",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	profilePicture: {
		width: "20px",
		height: "20px",
		borderRadius: "50%",
		border: "2px solid grey",
	},
	textDeco: {
		textDecoration: "none",
		color: "inherit",
		"&:hover,&:focus": {
			color: "inherit",
		},
	},
	listPadding: {
		width: "25%",
		"&.MuiListItem-gutters": {
			padding: "0",
		},
	},
	selectedIcon: {
		color: "#6F52ED",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		fontWeight: "bold",
		"&:hover": {
			color: "#6F52ED",
		},
	},
	navLink: {
		color: "#C0CEFF",
	},
	unSelectedIcon: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	},
});

export default function LabelBottomNavigation() {
	const classes = useStyles();
	const location = useLocation();
	const [acctId, setAcctId] = React.useState();
	const user = useSelector((state) => state.auth.user);
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
	const profilePic = useSelector((state) => state.user.profilePic);
	const auth = useSelector((state) => state.auth.user);

	React.useEffect(() => {
		if (isAuthenticated) {
			const hashId = encryptData(user.acctId);
			setAcctId(hashId);
		}

		if (window.location.href && window.location.href.includes("https://www.")) {
			const newURL = window.location.href.replace("https://www.", "https://");
			window.location.replace(newURL);
		}
	}, [isAuthenticated]);

	return (
		<div className={classes.root}>
			<div className={classes.listPadding}>
				<Link
					className={classes.textDeco}
					to="/social-feed"
					color="transparent"
				>
					<Button className={classes.navLink} color="transparent" fullWidth>
						<div
							className={
								location.pathname === "/social-feed"
									? classes.selectedIcon
									: classes.unSelectedIcon
							}
						>
							<IconLibrary.Home />
							<>Home</>
						</div>
					</Button>
				</Link>
			</div>
			<div className={classes.listPadding}>
				<Link
					className={classes.textDeco}
					to="/search-page-job"
					color="transparent"
				>
					<Button className={classes.navLink} color="transparent" fullWidth>
						<div
							className={
								location.pathname === "/search-page-job"
									? classes.selectedIcon
									: classes.unSelectedIcon
							}
						>
							<IconLibrary.Jobs />
							<>Jobs</>
						</div>
					</Button>
				</Link>
			</div>
			<div className={classes.listPadding}>
				<Link className={classes.textDeco} to="/resources" color="transparent">
					<Button className={classes.navLink} color="transparent" fullWidth>
						<div
							className={
								location.pathname === "/resources"
									? classes.selectedIcon
									: classes.unSelectedIcon
							}
						>
							<IconLibrary.SignBoard />
							<>Resources</>
						</div>
					</Button>
				</Link>
			</div>
			<div className={classes.listPadding}>
				<Link
					className={classes.textDeco}
					to={`/view-profile/${acctId}`}
					color="transparent"
				>
					<Button className={classes.navLink} color="transparent" fullWidth>
						<div
							className={
								location.pathname === `/view-profile/${acctId}`
									? classes.selectedIcon
									: classes.unSelectedIcon
							}
						>
							<img
								src={
									auth
										? profilePic
											? profilePic
											: auth.profilePic
											? auth.profilePic
											: candidate_avatar
										: null
								}
								alt="..."
								className={classes.profilePicture}
							/>
							<>Profile</>
						</div>
					</Button>
				</Link>
			</div>
		</div>
	);
}
