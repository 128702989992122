import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
	departmentViewRoadmap,
	viewRoadmapRoleByRoleId,
} from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Department_Operations from "assets/img/Career_Roadmap/Healthcare/Department_Operations_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentOperations() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Operations;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "o",
				shape: "poly",
				coords: [78,383,70,386,65,389,62,394,60,401,60,445,63,451,67,456,73,460,82,461,169,462,178,460,182,456,186,450,189,442,189,402,187,394,182,388,176,384,171,382,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "319",
				shape: "poly",
				coords: [240,374,235,376,231,378,227,383,225,389,226,456,227,462,230,466,234,468,240,470,366,470,373,467,377,465,380,461,381,454,381,390,379,381,376,378,370,375,364,374,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "320",
				shape: "poly",
				coords: [455,374,444,376,440,378,437,383,436,389,435,455,438,461,442,466,447,469,454,470,574,469,582,468,588,462,590,460,592,454,592,390,590,382,586,378,581,374,576,374,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "321",
				shape: "poly",
				coords: [661,374,654,377,649,380,647,386,645,392,646,456,648,461,651,466,656,469,664,470,786,470,794,466,798,462,801,457,802,450,802,393,800,384,798,379,792,376,785,374,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "322",
				shape: "poly",
				coords: [871,374,864,375,859,379,857,382,856,389,856,456,857,462,861,466,868,469,874,470,992,470,1003,468,1006,465,1010,460,1011,454,1012,390,1008,380,1003,376,998,374,992,374,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "323",
				shape: "poly",
				coords: [1079,374,1074,376,1070,379,1068,384,1066,391,1065,452,1067,461,1070,466,1076,469,1085,470,1206,470,1213,467,1218,463,1221,458,1222,453,1222,392,1219,382,1215,378,1210,375,1203,374,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "324",
				shape: "poly",
				coords: [1292,373,1284,376,1280,380,1276,384,1275,391,1276,452,1277,461,1280,466,1287,469,1296,470,1408,470,1421,469,1426,466,1429,461,1431,454,1431,396,1430,384,1426,377,1420,374,1412,374,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "325",
				shape: "poly",
				coords: [1500,374,1494,376,1490,380,1487,385,1485,391,1486,454,1487,461,1491,466,1496,469,1504,470,1624,470,1632,468,1636,465,1640,461,1642,453,1641,392,1639,382,1635,377,1628,375,1622,374,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "326",
				shape: "poly",
				coords: [1708,374,1699,377,1695,381,1692,385,1691,392,1692,456,1694,463,1697,466,1703,469,1712,470,1826,470,1837,469,1844,464,1846,459,1848,455,1848,391,1844,380,1840,376,1833,373,1828,374,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "o") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo);
			dispatch(viewRoadmapRoleByRoleId(requestInfo));

			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
