import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import InfiniteScroll from "react-infinite-scroll-component";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import CircularProgress from "@material-ui/core/CircularProgress";

import SearchResultCard from "./SearchResultCard.js";
import searchProfilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/SearchProfilePage/searchProfilePageStyle.js";
import notificationPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/NotificationPage/notificationPageStyle.js";
import { eventNotificationSearch } from "configurations/redux/eventNotificationSlice.js";
import NoActivityStatusModal from "../NoActivityStatusModal/NoActivityStatusModal";
import { classicNameResolver } from "typescript";
import { isMobile } from "react-device-detect";
import SearchImage from "assets/img/Search_Image.jpg";
import NoResultImage from "assets/img/No_Result.jpg";
const useStyles = makeStyles(notificationPageStyle);

export default function SearchResultSection() {
	const classes = useStyles();
	const searchResult = useSelector(
		(state) => state.eventNotification.searchResult
	);
	const currentSize = useSelector(
		(state) => state.eventNotification.lastRecordSize
	);
	const isSearching = useSelector(
		(state) => state.eventNotification.isSearching
	);
	const isLoading = useSelector((state) => state.eventNotification.isLoading);
	const userStatus = useSelector((state) => state.auth.user);
	const isUpdated = useSelector((state) => state.user.isUpdated);
	const isEnd = useSelector((state) => state.eventNotification.isEnd);
	const keyword = useSelector((state) => state.eventNotification.keyword);
	const bizId = useSelector((state) => state.business.bizId);
	const dispatch = useDispatch();

	const ResultCard = searchResult.map((item, index) => {
		return (
			<GridItem xs={12} sm={12} md={12} key={index}>
				<SearchResultCard
					key={item.acctId}
					item={item}
					profileAcctId={item.acctId}
				/>
			</GridItem>
		);
	});

	const loadNotification = () => {
		const requestProfile = {
			acctId: userStatus.acctId,
			accessToken: localStorage.getItem("token"),
			email: userStatus.email,
			lastRecordSize: currentSize,
			requestRecordSize: 5,
			keyword: keyword,
			bizId: bizId,
		};
		dispatch(eventNotificationSearch(requestProfile));
	};

	return (
		<div>
			{!isUpdated ? <NoActivityStatusModal /> : null}
			{searchResult.length > 0 ? (
				<InfiniteScroll
					style={{ overflow: "hidden" }}
					dataLength={searchResult.length}
					next={loadNotification}
					hasMore={!isEnd}
					// height={searchResult.length < 4 ? 420 : 600}
					// loader={<CircularProgress className={classes.loadingProgress} />}
					loader={
						<div className={classes.loadingContainer}>
							<CircularProgress />
						</div>
					}
					endMessage={
						<h4 className={classes.title + " " + classes.textCenter}>
							End of record
						</h4>
					}
					scrollThreshold={0.6}
					// style={isMobile ? { overflow: "hidden" } : { overflow: "hidden" }}
				>
					<GridContainer>{ResultCard}</GridContainer>
				</InfiniteScroll>
			) : null}
			{isEnd && searchResult.length === 0 && (
				<Card className={classes.cardFrame}>
					<CardBody>
						<h5 className={classes.title + " " + classes.textCenter}>
							No available result. Please use different filter options.
						</h5>
						<GridContainer justifyContent="center">
							<GridItem xs={12} sm={12} md={6}>
								<img
									src={NoResultImage}
									alt="..."
									className={classes.loadingImage}
								/>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
			)}

			{isLoading && searchResult.length === 0 && (
				<GridContainer justifyContent="center">
					<GridItem xs={12} sm={12} md={12}>
						<div className={classes.loadingContainer}>
							<CircularProgress />
						</div>
					</GridItem>
					<GridItem xs={12} sm={12} md={6}>
						<img src={SearchImage} alt="..." className={classes.loadingImage} />
					</GridItem>
				</GridContainer>
			)}
		</div>
	);
}
