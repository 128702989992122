import React, { useEffect, useState } from "react";

import SingleWorkExperienceCard from "./SingleWorkExperienceCard";

import { Typography, Button, makeStyles } from "@material-ui/core";

import { decryptData } from "configurations/configurations";
import { useSelector, useDispatch } from "react-redux";

import workExperienceStyles from "assets/jss/material-kit-pro-react/views/pageStyle/ProfileViewPage/workExperienceStyles";
import { activateEditType } from "configurations/redux/editProfileModalSlice";

import ExperienceCardSection from "./ExperienceCardSection.js";
const WorkExperience = (props) => {
	const styles = makeStyles(workExperienceStyles)();
	const { match, experienceRecords, onEdit } = props;
	const dispatch = useDispatch();
	const user = useSelector((state) => state.auth.user);
	const isViewed = useSelector((state) => state.user.isViewed);
	const [myself, setMyself] = useState(false);
	const [mockExperiences, setMockExperiences] = useState([
		{
			expRecId: "",
			title: "Executive Director",
			organization: "Mondale International Executive Search",
			startDate: "2020-07-01 00:00:00",
			currentInProgressFlag: 0,
			endDate: "2021-03-01 00:00:00",
			countryId: 5,
			locationId: 0,
			achievement: "Get the best employee of the year",
			description: "Designing social media and many channel platform",
		},
		{
			expRecId: "",
			title: "Floor Director",
			organization: "Waiki Production House",
			startDate: "2021-05-01 00:00:00",
			currentInProgressFlag: 1,
			endDate: "",
			countryId: 15,
			locationId: 0,
			achievement: "Get the best employee of the year",
			description: "Designing social media and many channel platform",
		},
	]);

	return (
		<Typography component="div" className={styles.main}>
			<Typography component="div" className={styles.header}>
				<Typography component="h2">Work Experience</Typography>

				{!isViewed ? (
					<Button
						onClick={() => {
							const response = {
								type: "EDIT_PROFILE",
								tabType: "EXPERIENCE",
								isTabShow: true,
								extension: {
									recordId: -1,
								},
							};

							dispatch(activateEditType(response));
						}}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							viewBox="0 0 20 20"
						>
							<path
								id="icon-2"
								d="M4.709,20H1.176A1.178,1.178,0,0,1,0,18.824v-3.53a1.167,1.167,0,0,1,.345-.831L14.462.345a1.175,1.175,0,0,1,1.663,0l3.53,3.53a1.177,1.177,0,0,1,0,1.664L5.538,19.658A1.169,1.169,0,0,1,4.709,20Zm6.97-13.571h0L2.143,15.965v1.892H4.035L13.571,8.32,11.679,6.429Zm3.677-3.571h0L13.571,4.643l1.786,1.786,1.786-1.786L15.357,2.858Z"
								transform="translate(0 0)"
								fill="#6f52ed"
							/>
						</svg>
						EDIT
					</Button>
				) : (
					""
				)}
			</Typography>

			<ExperienceCardSection />
		</Typography>
	);
};
export default WorkExperience;
