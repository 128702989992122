import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { googleContactSync } from "configurations/redux/userSlice.js";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
// @material-ui/icons
// import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.js";
import IconLibrary from "views/Component/IconLibrary.js";
import style from "assets/jss/material-kit-pro-react/modalStyle.js";
// import googleIcon from "assets/svg/google_light.svg";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js";
import { ReactComponent as GoogleIconSvg } from "assets/svg/Google__G__Logo.svg";

const useStyles = makeStyles(style);
const useProfileStyles = makeStyles(profilePageStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

const SyncContactGoogleModal = ({ open, onClose, userStatus }) => {
	const classes = useStyles();
	const classesProfile = useProfileStyles();
	const dispatch = useDispatch();
	// const user = useSelector((state) => state.user);

	return (
		<React.Fragment>
			<Dialog
				classes={{
					root: classes.modalRoot,
					paper: classes.modalLoader + " " + classesProfile.googleModal,
				}}
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={onClose}
				aria-labelledby="classic-modal-slide-title"
				aria-describedby="classic-modal-slide-description"
			>
				<DialogTitle
					id="classic-modal-slide-title"
					disableTypography
					className={classes.modalHeader}
				>
					<Button
						simple
						className={classes.modalCloseButton}
						key="close"
						aria-label="Close"
						onClick={onClose}
					>
						<IconLibrary.Close />
					</Button>
					<h4>
						<b>Sync your contacts</b>
					</h4>
				</DialogTitle>
				<DialogContent
					id="classic-modal-slide-description"
					className={classes.modalBody}
				>
					<DialogContentText id="alert-dialog-slide-description">
						Import your Google contacts so you can start networking with friends
						today!
					</DialogContentText>
					<DialogContentText
						id="alert-dialog-slide-description"
						className={classesProfile.googleTerm}
					>
						By clicking on below button you agree to the provider's Terms of Use
						and Privacy Policy.
					</DialogContentText>
				</DialogContent>
				<DialogActions
					className={classes.modalFooter}
					style={{ justifyContent: "center" }}
				>
					{/* <Button
						onClick={() => {
							// dispatch(deleteEducationProfile(updateRequest));
							onClose();
						}}
						color="opptyWrap"
						round
					>
						<b>Delete </b>
					</Button> */}
					{/* <Button
						onClick={() => {
							const requestProfile = {
								acctId: userStatus.acctId,
								email: userStatus.email,
								accessToken: localStorage.getItem("token"),
							};
							dispatch(googleContactSync(requestProfile));
							onClose();
						}}
						color="google"
						// fullWidth
						style={{ backgroundColor: "#4285F4" }}
						className={classes.buttonBorder}
						startIcon={
							<img
								src={googleIcon}
								style={{
									width: "44px",
									position: "absolute",
									top: "0px",
									left: "0px",
								}}
							/>
						}
					>
						<span className={classesProfile.socialMargin}>
							Sign In with Google
						</span>
					</Button> */}
					<div
						className={classesProfile.googleButtonContainer}
						onClick={() => {
							const requestProfile = {
								acctId: userStatus.acctId,
								email: userStatus.email,
								accessToken: localStorage.getItem("token"),
							};
							dispatch(googleContactSync(requestProfile));
							onClose();
						}}
					>
						<GoogleIconSvg />
						<div className={classesProfile.socialButtonText}>
							<span>Sign In With Google</span>
						</div>
					</div>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
};

export default SyncContactGoogleModal;
