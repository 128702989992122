const groupSummaryCardStyles = () => ({
  main: {
    borderRadius: '15px',
    backgroundColor: '#fff',
    marginTop: '60px',
    padding: '20px',
    width: '100%',
    maxHeight: '320px',
  },

  header: {
    textAlign: 'center',

    '& > img': {
      marginTop: '-70px',
      width: '100px',
      aspectRatio: 1,
      border: 'solid 5px #fff',
      borderRadius: '10px',
    },
  },

  name: {
    marginTop: '20px',
    color: '#171725',
    fontSize: '16px',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    fontWeight: 600,
  },

  userName: {
    color: '#B5B5BE',
    fontSize: '12px',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },

  stats: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: '10px',
    borderTop: 'solid 1px #F1F1F5',
    borderBottom: 'solid 1px #F1F1F5',
    padding: '10px 0',
  },

  numbers: {
    color: '#44444F',
    fontSize: '14px',
    fontWeight: 600,
    textAlign: 'center',
  },

  statName: {
    color: '#92929D',
    fontSize: '12px',
    textAlign: 'center',
  },

  buttons: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '20px',

    '& > button': {
      borderRadius: '10px',
      padding: '7px 15px',
      fontSize: '11px',
      fontWeight: 600,
      textTransform: 'capitalize',
    },
  },

  leaveBtn: {
    backgroundColor: '#0062FF',
    color: '#fff',
    margin: '0 3px',

    '&:hover': {
      color: '#0062FF',
    },
  },

  inviteBtn: {
    backgroundColor: '#F1F1F5',
    color: '#696974',
    margin: '0 3px',
  },
});

export default groupSummaryCardStyles;
