const popularGroupCardStyles = () => ({

  main: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  imageContainer: {
    overflow: "hidden",
    borderRadius: "15px",
    width: "27%",
    height: "110px",

    '& > img': {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    }
  },

  contentContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "solid 1px #F1F1F5",
    borderRadius: "15px",
    padding: "16px",
    width: "70%",
  },

  heading: {
    color: "#171725",
    fontSize: "14px",
    fontWeight: 600,
  },

  details: {
    marginTop: "10px",

    '& > div': {
      display: "flex",
      alignItems: "center",
      marginBottom: "5px",
    }
  },

  description: {
    color: "#92929D",
    fontSize: "14px",
  },

  members: {
    display: "flex",
    alignItems: "center",
    marginLeft: "20px",
    color: "#92929D",
    fontSize: "14px",

    '& > svg': {
      marginRight: "5px",
    },
  },

  friendsProfiles: {
    display: "flex",

    '& > img': {
      marginRight: "5px",
      borderRadius: "50%",
      width: "20px",
      height: "20px",
    },
  },

  greaterThenThreeImages: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "5px",
    border: "solid 1px #E2E2EA",
    borderRadius: "50%",
    width: "20px",
    height: "20px",
    color: "#696974",
    fontSize: "7px",
  },


  friendsConnect: {
    marginLeft: "10px",
    color: "#92929D",
    fontSize: "12px",
  },

  joinBtn: {

    '& > button': {
      borderRadius: "10px",
      backgroundColor: "#F1F1F5",
      padding: "7px 20px",
      color: "#696974",
      fontSize: "12px",
      fontWeight: 600,
      textTransform: "capitalize",
    },
  },

})

export default popularGroupCardStyles;