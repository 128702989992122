import React from "react";

import { connect, useSelector, useDispatch } from "react-redux";
import { activateMasterCode } from "configurations/redux/masterFunctionSlice.js";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js";
import defaultAvatar from "assets/img/candidate_avatar.jpeg";
import {
	activateEditType,
	deactivateEditType,
} from "configurations/redux/editProfileModalSlice";

// import ProfilePictureEditModal from "./ProfilePictureEditModal.js";

const useStyles = makeStyles(profilePageStyle);
const ProfilePictureSection = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	// const [isOpen, setOpen] = React.useState(false);
	// const closeModal = () => {
	// 	setOpen(false);
	// };
	const user = useSelector((state) => state.user);
	const isViewed = useSelector((state) => state.user.isViewed);

	const privacySetting = useSelector((state) => state.user.privacySetting);

	const showProfilePic =
		privacySetting &&
		privacySetting
			.filter((item) => {
				return item.settingName === "showProfilePic";
			})
			.map((item) => {
				return item.settingValue === 1;
			})[0];

	const showProfile =
		privacySetting &&
		privacySetting
			.filter((item) => {
				return item.settingName === "showProfile";
			})
			.map((item) => {
				return item.settingValue === 1;
			})[0];

	const imageClasses = classNames(
		classes.imgRaised,
		classes.imgRoundedCircle,
		classes.imgProfile
	);

	return (
		<React.Fragment>
			{isViewed ? (
				showProfile ? (
					showProfilePic ? (
						user.profilePic ? (
							<React.Fragment>
								<img
									src={user.profilePic}
									alt="..."
									className={imageClasses}
									onClick={() => {
										/* const response = {
											functionCode: "MF_PM_MODAL_PROFILE_PIC",
											extension: null,
										};
										const editResponse = {
											type: "EDIT_PROFILE_PICTURE",
										};
										dispatch(activateEditType(editResponse));
										dispatch(activateMasterCode(response)); */
									}}
								/>
							</React.Fragment>
						) : (
							<img
								src={defaultAvatar}
								alt="..."
								className={imageClasses}
								onClick={() => {
									/* const response = {
										functionCode: "MF_PM_MODAL_PROFILE_PIC",
										extension: null,
									};
									const editResponse = {
										type: "EDIT_PROFILE_PICTURE",
									};
									dispatch(activateEditType(editResponse));
									dispatch(activateMasterCode(response)); */
								}}
							/>
						)
					) : (
						<img
							src={defaultAvatar}
							alt="..."
							className={imageClasses}
							onClick={() => {
								/* const response = {
									functionCode: "MF_PM_MODAL_PROFILE_PIC",
									extension: null,
								};
								dispatch(activateMasterCode(response)); */
							}}
						/>
					)
				) : (
					<img
						src={defaultAvatar}
						alt="..."
						className={imageClasses}
						onClick={() => {
							/* const response = {
								functionCode: "MF_PM_MODAL_PROFILE_PIC",
								extension: null,
							};
							const editResponse = {
								type: "EDIT_PROFILE_PICTURE",
							};
							dispatch(activateEditType(editResponse));
							dispatch(activateMasterCode(response)); */
						}}
					/>
				)
			) : user.profilePic ? (
				<React.Fragment>
					<img
						src={user.profilePic}
						alt="..."
						className={imageClasses}
						onClick={() => {
							const response = {
								functionCode: "MF_PM_MODAL_PROFILE_PIC",
								extension: null,
							};
							const editResponse = {
								type: "EDIT_PROFILE_PICTURE",
								isShow: true,
							};
							dispatch(activateEditType(editResponse));
							dispatch(activateMasterCode(response));
						}}
					/>
				</React.Fragment>
			) : (
				<React.Fragment>
					<img
						src={defaultAvatar}
						alt="..."
						className={imageClasses}
						onClick={() => {
							const response = {
								functionCode: "MF_PM_MODAL_PROFILE_PIC",
								extension: null,
							};

							dispatch(activateMasterCode(response));
							const editResponse = {
								type: "EDIT_PROFILE_PICTURE",
								isShow: true,
							};
							dispatch(activateEditType(editResponse));
						}}
					/>
				</React.Fragment>
			)}

			{/* {isViewed ? null : (
				<ProfilePictureEditModal
					open={isOpen}
					onClose={closeModal}
					pictureSource={user.profilePic}
				/>
			)} */}
		</React.Fragment>
	);
};
/* const mapStateToProps = (state) => ({
	user: state.user,
	isViewed: state.user.isViewed,
}); */
export default ProfilePictureSection;
