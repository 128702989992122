import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
	instanceUrl,
	header,
	emptyCache,
} from "configurations/configurations.js";

const peopleYouMayKnowSlice = createSlice({
	name: "peopleYouMayKnow",
	initialState: {
		peopleYouMayKnowResult: [],
		peopleYouMayKnowParams: [],
		lastRecordSize: 0,
		requestRecordSize: 0,
		isLoading: false,
		isEnd: false,
		isSearching: false,
		isUpdated: true,
	},
	reducers: {
		PYMK_LOADING: (state, action) => {
			state.isLoading = true;
		},
		PYMK_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.peopleYouMayKnowResult = [
				...state.peopleYouMayKnowResult,
				...action.payload.peopleYouMayKnowResult,
			];
			// console.log("people you may know", state.peopleYouMayKnowResult);
			state.peopleYouMayKnowResult = state.peopleYouMayKnowResult.filter(
				(value, index, self) =>
					index === self.findIndex((t) => t.acctId == value.acctId)
			);
			state.peopleYouMayKnowParams = action.payload.peopleYouMayKnowParams;
			state.lastRecordSize =
				state.lastRecordSize + action.payload.requestRecordSize;
			state.requestRecordSize = action.payload.requestRecordSize;
			state.isLoading = false;
			state.isUpdated = true;
		},
		PYMK_END: (state, action) => {
			state.isEnd = true;
		},
		PYMK_SEARCH: (state) => {
			state.peopleYouMayKnowResult = [];
			state.peopleYouMayKnowParams = [];
			state.lastRecordSize = 0;
			state.requestRecordSize = 0;
			state.isEnd = false;
			state.isSearching = true;
		},
		PYMK_FAIL: (state) => {
			state.isUpdated = false;
		},
	},
});

const {
	PYMK_LOADING,
	PYMK_SUCCESS,
	PYMK_END,
	PYMK_SEARCH,
	PYMK_FAIL,
} = peopleYouMayKnowSlice.actions;

export const peopleYouMayKnowInfo = ({
	email,
	acctId,
	lastRecordSize,
	requestRecordSize,
	accessToken,
}) => async (dispatch) => {
	const body = {
		email,
		acctId,
		lastRecordSize,
		requestRecordSize,
		accessToken,
	};

	dispatch(PYMK_LOADING());
	if (lastRecordSize === 0) {
		dispatch(PYMK_SEARCH());
	}

	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/um/get-suggested-profile-by-acct-id`,
		params: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				const response = {
					peopleYouMayKnowResult: res.data.payload.suggestedProfileList,
					peopleYouMayKnowParams: {},
					lastRecordSize,
					requestRecordSize: res.data.payload.suggestedProfileList.length,
					token: res.data.token,
				};
				dispatch(PYMK_SUCCESS(response));
				if (requestRecordSize > res.data.payload.suggestedProfileList.length) {
					dispatch(PYMK_END());
				}
			} else if (res.status === 202) {
				dispatch(PYMK_END());
			} else {
				dispatch(PYMK_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export default peopleYouMayKnowSlice.reducer;
