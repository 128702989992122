import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  makeStyles,
  Typography,
  Button,
  TextField,
  Hidden,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Switch,
  Slide,
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { CheckBox, CheckBoxOutlineBlankOutlined } from '@material-ui/icons';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import BasicInfoSection from './BasicInfoSection.js';
import TagSection from './TagSection.js';
import InterestIndustrySection from './InterestIndustrySection.js';

import axios from 'axios';
import { Formik } from 'formik';
import ProfileStrengthTip from 'views/Page/ProfilePage/ProfileSection/ProfileStrengthTip.js';
import {
  instanceUrl,
  countMonthYear,
  getCountryId,
  getLocationId,
  getLocationIdByCountry,
  getStartYearData,
  getEndYearData,
  getMonthData,
  experienceUpdateSchema,
} from 'configurations/configurations.js';

import { useField } from 'formik';
import {
  getAllIndustry,
  createInterestIndustry,
  deleteInterestIndustry,
  getInterestedIndustries,
} from 'configurations/redux/userSlice.js';
import editGeneralProfileStyles from 'assets/jss/material-kit-pro-react/views/componentStyle/profileView/editProfile/generalProfile/editGeneralProfileStyles';
import CloseIcon from '@mui/icons-material/Close';

const EditGeneralProfile = props => {
  const styles = makeStyles(editGeneralProfileStyles)();
  const { match, extension, saveDetailModalProps, onCancelChangesConfirm, setIsChanged } = props;

  const dispatch = useDispatch();

  const user = useSelector(state => state.user);
  const profileRecord = useSelector(state => state.user.profileRecord);

  const interestedIndustry = user.interestedIndustry;

  const startYearDataList = getStartYearData();
  const endYearDataList = getEndYearData();
  const monthDataList = getMonthData();

  const [isHeaderButtonVisible, setIsHeaderButtonVisible] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [countryChange, setCountryChange] = React.useState();
  const [recordId, setRecordId] = useState();
  const [industryChange, setIndustryChange] = React.useState();
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);

  /* useEffect(() => {
		let mounted = true;
		let CancelToken = axios.CancelToken;
		const source = CancelToken.source();

		dispatch(getAllIndustry());

		if (mounted) {
			axios
				.get(`${instanceUrl}/api/md/get-all-country`, {
					cancelToken: source.token,
				})
				.then((res) => {
					setCountryList(res.data.payload);
				})
				.catch((error) => {
					if (axios.isCancel(error)) {
						console.log("unmount caused");
					} else {
						console.log(error);
					}
				});
		}
		return () => {
			mounted = false;
			source.cancel();
		};
	}, []); */

  useEffect(() => {
    const req = {
      acctId: user.acctId,
      accessToken: localStorage.getItem('token'),
      email: user.email,
    };
    dispatch(getInterestedIndustries(req));
  }, []);

  return (
    <Typography component="div" className={styles.body} id="modalInfo">
      {props.extension && (
        <ProfileStrengthTip
          extension={
            props.extension &&
            props.extension
              .filter(item => {
                return item.improvementTip.functionCode === 'MF_PM_MODAL_BASIC_PROFILE';
              })
              .map(item => {
                return item.improvementTip;
              })
          }
        />
      )}
      <BasicInfoSection
        saveDetailModalProps={props.saveDetailModalProps}
        onCancelChangesConfirm={props.onCancelChangesConfirm}
        setIsChanged={props.setIsChanged}
      />

      <TagSection
        extension={
          props.extension &&
          props.extension
            .filter(item => {
              return item.improvementTip.functionCode === 'MF_PM_SECTION_SKILL_ATTRIBUTE';
            })
            .map(item => {
              return item.improvementTip;
            })
        }
      />

      <InterestIndustrySection
        saveDetailModalProps={props.saveDetailModalProps}
        onCancelChangesConfirm={props.onCancelChangesConfirm}
        setIsChanged={props.setIsChanged}
      />

      {/* <div
				className={styles.dialogContainer}
				onClick={() => {
					setIsCancelDialogOpen(false);
				}}
				style={{ display: isCancelDialogOpen ? "block" : "none" }}
			>
				<div className={styles.dialog}>
					<h5 className={styles.dialogTitle}>Discard This Action?</h5>
					<p className={styles.dialogText}>This action cannot be undone.</p>

					<div className={styles.dialogActions}>
						<Button onClick={() => setIsCancelDialogOpen(false)}>Cancel</Button>
						<Button onClick={() => setIsCancelDialogOpen(true)}>Confirm</Button>
					</div>
				</div>
			</div> */}
    </Typography>
  );
};

export default EditGeneralProfile;
