import {
  container,
  boxShadow,
  hexToRgb,
  grayColor,
  blackColor,
  opptyDesignColor,
} from 'assets/jss/material-kit-pro-react.js';
import customSelectStyle from 'assets/jss/material-kit-pro-react/customSelectStyle.js';
import styles from 'assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js';
import customCheckboxRadioSwitchStyle from 'assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.js';
const searchPageStyle = theme => ({
  ...customSelectStyle,
  ...styles,
  ...customCheckboxRadioSwitchStyle,

  cardFrame: {
    borderRadius: '18px',
    boxShadow: 'none',
    width: 'auto',
    margin: '10px 10px',
  },
  cardClick: {
    cursor: 'pointer',
    '&:hover,&:focus': {
      //backgroundColor: grayColor[0],
      boxShadow:
        '0 14px 26px -12px rgba(' +
        hexToRgb(grayColor[0]) +
        ', 0.42), 0 4px 23px 0px rgba(' +
        hexToRgb(blackColor) +
        ', 0.12), 0 8px 10px -5px rgba(' +
        hexToRgb(grayColor[0]) +
        ', 0.2)',
    },
  },
  body: {
    /* overflowY: "scroll", */
    overflowX: 'hidden',
    marginTop: '94px',
    height: 'calc(100vh - 100px)',
    [theme.breakpoints.down('sm')]: {
      marginTop: '85px',
    },
  },
  filterBody: {
    overflowX: 'hidden',
    marginTop: '158px',
    height: 'calc(100vh - 158px)',
    [theme.breakpoints.down('sm')]: {
      marginTop: '85px',
    },
  },
  container: {
    ...container,
    zIndex: '5',
    '@media (min-width: 576px)': {
      maxWidth: '100%',
    },
    '@media (min-width: 768px)': {
      maxWidth: '100%',
    },
    '@media (min-width: 992px)': {
      maxWidth: '100%',
    },
    '@media (min-width: 1200px)': {
      maxWidth: '100%',
    },
    '@media (min-width: 1400px)': {
      maxWidth: '95vw',
    },
  },
  pageHeader: {
    /* position: "relative",
		display: "flex !important",
		alignItems: "center", */
    margin: '0',
    border: '0',
    height: 'auto',
    color: '#fff',
  },

  resultContainer: {
    display: 'flex',

    /* padding: "20px 10px", */
  },
  imgContainer: {
    display: 'flex',

    '& img': {
      width: '48px',
      height: '48px',
      borderRadius: '50%',
      marginTop: '5px',
    },
  },
  descriptionContainer: {
    width: '100%',
    display: 'flex',
    marginLeft: '10px',

    '& h4': {
      marginBottom: '0px !important',
      marginTop: '5px !important',
    },
  },
  title: {
    fontWeight: 700,
    fontSize: '16px',
  },
  description: {
    fontWeight: 500,
    fontSize: '14px',
    color: 'rgba(0,0,0,0.6)',
  },
  mainContainer: {
    backgroundColor: '#f2f3f7',
    padding: '5px 10px',
    margin: '10px 0px',
    borderRadius: '18px',
  },
  cardBodyPadding: {
    padding: '10px',
  },
  componentTitle: {
    fontWeight: 700,
    fontSize: '20px',
    marginLeft: '15px',
    marginTop: '5px',
  },
  buttonViewMore: {
    color: opptyDesignColor,
    textTransform: 'none',
  },
  select: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '18px',
      /* border: "3px solid " + opptyDesignColor, */
      /* "&:hover, &:focus": {
				border: "3px solid " + opptyDesignColor,
			}, */
    },
    '& .MuiOutlinedInput-input': {
      padding: '10px!important',
    },
    width: '200px',
  },
  appBarStyle: {
    marginTop: '93px',
    backgroundColor: '#fff !important',
    boxShadow: 'none !important',
    borderBottom: '2px solid #D0DDE7',
    zIndex: '1099!important',
    [theme.breakpoints.down('sm')]: {
      marginTop: '88px',
    },
  },
  resetButton: {
    width: '100px',
    borderRadius: '18px',
    backgroundColor: 'red',
    color: '#fff',
    marginLeft: '10px',
    height: '43px',
    '&:hover': {
      border: '3px solid ' + 'red',
      color: 'red',
    },
  },
  filterButton: {
    width: '100px',
    borderRadius: '18px',
    backgroundColor: opptyDesignColor,
    color: '#fff',
    marginLeft: '10px',
    height: '43px',
    '&:hover': {
      border: '3px solid ' + opptyDesignColor,
      color: opptyDesignColor,
    },
  },
  drawer: {
    '& .MuiDrawer-paperAnchorRight': {
      height: 'calc(100vh - 158px)',
      flexDirection: 'column',
      top: '158px !important',
      width: '500px',
      [theme.breakpoints.down('xs')]: {
        width: 'auto',
        top: '0px !important',
      },
      backgroundColor: '#f2f3f7 !important',
    },
  },
  cardFrameFilter: {
    marginBottom: '0px',
    width: '100%',
    height: 'calc(100% - 70px)',
    borderRadius: '18px',
    overflowY: 'scroll',
    boxShadow: 'none',
  },
  autoCompleteSelect: {
    marginTop: '12px !important',
  },
  buttonContainer: {
    height: '70px',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    marginTop: 'auto',

    borderTop: '2px solid #D0DDE7',
  },
  noResultTitle: {
    fontWeight: 500,
    fontSize: '16px',
    color: 'rgba(0,0,0,0.6)',
    textAlign: 'center',
  },
  filterSelect: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '18px',
      /* border: "3px solid " + opptyDesignColor, */
      /* "&:hover, &:focus": {
                    border: "3px solid " + opptyDesignColor,
                }, */
    },
    '& .MuiOutlinedInput-input': {
      padding: '10px!important',
    },
  },
  accordionTitle: {
    padding: '0',
    borderBottom: '0',
  },
  accordionRoot: {
    marginBottom: '0',
  },
  accordionDetailClass: {
    padding: '0 0 0 20px',
  },
  accordionLabel: {
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: '-16px',
    // marginRight: "16px",
    verticalAlign: 'middle',
    WebkitTapHighlightColor: 'transparent',
    color: '#000000',
    fontWeight: '700',
  },

  opptyColor: {
    color: opptyDesignColor + '!important',
  },
});

export default searchPageStyle;
