import React from "react";
import { appVersion } from "configurations/configurations.js";
import { makeStyles } from "@material-ui/core/styles";
import pageNotFoundPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/PageNotFoundPage/pageNotFoundPageStyle.js";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import maintenanceImage from "assets/img/Maintenance_Image.png";

import Header from "views/Component/Header.js";
import Footer from "views/Component/Footer.js";

import { Hidden } from "@material-ui/core";

const useStyles = makeStyles(pageNotFoundPageStyle);

export default function PageNotFoundPage() {
	const classes = useStyles();
	return (
		<>
			<Header />
			<div className={classes.body}>
				<div className={classes.pageHeader}>
					<div className={classes.container}>
						<GridContainer
							justifyContent="center"
							alignContent="center"
							alignItems="center"
						>
							<GridItem xs={12} sm={12} md={7}>
								<div className={classes.infoArea}>
									{/* 	<h2 className={classes.leftInfoTitle}>Opps! Page Not Found</h2> */}
									{/* <h5 className={classes.leftInfoDescription}>Page Not Found</h5> */}
									<Hidden smDown>
										<img
											src={maintenanceImage}
											className={classes.infoImage + " " + classes.textCenter}
										/>
									</Hidden>
								</div>
							</GridItem>
							<GridItem xs={12} sm={12} md={5}>
								<h1 className={classes.title}>404</h1>
								<h2 className={classes.leftInfoDescription}>
									Opps! Page Not Found
								</h2>
								<Card className={classes.pageNotFoundCard}>
									<CardBody className={classes.pageNotFoundCardBody}>
										<GridContainer
											justifyContent="center"
											alignContent="center"
											alignItems="center"
										>
											<GridItem xs={12} sm={12} md={12}>
												<h4 className={classes.title + " " + classes.textCenter}>
													Important Notice!
												</h4>

												<h4
													className={
														classes.infoDescription + " " + classes.textCenter
													}
												>
													Uh oh, Oppty can't seem to find the page you are looking
													for. Try going back or Contact our team for more
													information
												</h4>
												<h4 style={{ color: "#ffffff" }}>{appVersion}</h4>
											</GridItem>
										</GridContainer>
									</CardBody>
								</Card>
							</GridItem>
						</GridContainer>
					</div>
				</div>
				<Footer />
			</div>
		</>
	);
}
