import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

import store from "configurations/redux/store.js";
import { SnackbarProvider } from "notistack";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";

import App from "./App.js";
import Header from "components/Header/Header.js";
const persistor = persistStore(store);

const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
	notistackRef.current.closeSnackbar(key);
};

ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<SnackbarProvider
				style={{ backgroundColor: "#6F52ED" }}
				maxSnack={3}
				preventDuplicate
				autoHideDuration={2000}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				ref={notistackRef}
				action={(key) => (
					<IconButton onClick={onClickDismiss(key)}>
						<CloseIcon />
					</IconButton>
				)}
			>
				<App />
			</SnackbarProvider>
		</PersistGate>
	</Provider>,
	document.getElementById("root")
);
