const previewPostVideosModalStyles = () => ({
	videoPreviewModalBox: {
		position: "absolute",
		zIndex: "10",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		overflow: "hidden",
		border: "solid 1px grey",
		borderRadius: "15px",
		"-moz-border-radius": "15px",
		"-webkit-border-radius": "15px",
		backgroundColor: "white",
		padding: "0",
		width: "495px",
		maxHeight: "600px",
	},

	container: {
		overflow: "hidden",
		overflowY: "scroll",
		height: "555px",

		"&::-webkit-scrollbar": {
			width: "0",
		},
	},

	boxTitle: {
		position: "relative",
		padding: "10px 0",
		color: "#171725",
		fontSize: "14px",
		fontWeight: "700",
		textAlign: "center",
		verticalAlign: "middle",

		"& > h1": {
			display: "inline-block",
			margin: "0",
			fontSize: "14px",
			fontWeight: 600,
		},

		"& > button": {
			position: "absolute",
			right: 0,
			marginTop: "3px",
			marginRight: "13px",
			border: "none",
			backgroundColor: "transparent",
			cursor: "pointer",
		},
	},

	videoPreview: {
		display: "flex",
		alignItems: "center",
		overflow: "hidden",
		backgroundColor: "#000",
		padding: "0", //"25px 0 0",
		width: "100%",
		height: "400px",

		"& > video": {
			width: "100%",
		},
	},

	videosList: {
		display: "flex",
		padding: "10px",
	},

	videosListContainer: {
		display: "flex",
		flexWrap: "wrap",
	},

	videosListItem: {
		position: "relative",
		margin: "6px 8px",
		width: "102px",
		height: "120px",
		border: "1px solid #92929D",
		borderRadius: "15px",

		"& > div": {
			overflow: "hidden",
			marginTop: "-24px",
			width: "100%",
			height: "100%",
		},
	},

	removeVideo: {
		position: "relative",
		zIndex: "2",
		top: "-9px",
		right: "-87px",
		width: "20px",
		height: "auto",
		cursor: "pointer",
	},

	videosListItemVideoContainerOverlay: {
		position: "absolute",
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
	},

	videosListItemVideoContainer: {
		display: "inline-block",
		cursor: "pointer",
	},

	selectedVideo: {
		boxSizing: "border-box",
		border: "solid 3px #20F0A1",
	},

	videosListItemVideo: {
		width: "100%",
		height: "100%",
		objectFit: "cover",
		objectPostion: "center",
	},

	error: {
		// position: "absolute",
		zIndex: "2",
		bottom: "0",
		backgroundColor: "white",
		padding: "10px 0",
		width: "100%",
		textAlign: "center",
		fontSize: "12px",
		fontWeight: "500",
		color: "red",
	},

	saveButton: {
		textAlign: "center",
		margin: "20px",

		"& > label > span": {
			border: "none",
			borderRadius: "7px",
			backgroundColor: "#fff",
			padding: "10px 30px",
			color: "#6F52ED",
			fontSize: "12px",
			fontWeight: "600",
			cursor: "pointer",
			margin: "0 5px",
			border: "2px solid #6F52ED",
		},

		"& > button": {
			border: "none",
			borderRadius: "7px",
			backgroundColor: "#6F52ED",
			padding: "10px 35px",
			color: "white",
			fontSize: "12px",
			fontWeight: "600",
			cursor: "pointer",
			margin: "0 5px",
			border: "2px solid #6F52ED",
		},
	},
});

export default previewPostVideosModalStyles;
