import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { connect, useSelector, useDispatch } from 'react-redux';
import { decryptData, gaPageView, communityArticlePath } from 'configurations/configurations.js';
import { getJobPosts } from 'configurations/redux/jobManagementSlice.js';
import { getFavProfileListNew } from "configurations/redux/favProfileSlice";

import axios from 'axios';
import { instanceUrl, encryptData } from 'configurations/configurations.js';

import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
//Page Components
import Header from 'views/Component/Header.js';
import Footer from 'views/Component/Footer.js';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Parallax from 'components/Parallax/Parallax.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import { Box, Modal, CardMedia, Chip } from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import Chatboticon from 'assets/images/chatboticon.png';
import CreateIcon from '@material-ui/icons/Create';
import corporateProfilePageStyle from 'assets/jss/material-kit-pro-react/views/pageStyle/CorporateProfilePage/corporateProfilePageStyle.js';
import NoActivityStatusModal from '../NoActivityStatusModal/NoActivityStatusModal.js';
import CorporateJobTemplateSection from './CorporateProfileSection/CorporateJobTemplateSection.js';

/* import CorporateProfilePictureSection from './CorporateProfileSection/CorporateProfilePictureSection.js';
import CorporateBasicProfileEditModal from './CorporateProfileSection/CorporateBasicProfileEditModal.js';
import CorporateResetPasswordModal from './CorporateProfileSection/CorporateResetPasswordModal.js';
import CorporateBasicProfileSection from './CorporateProfileSection/CorporateBasicProfileSection.js'; */

import { getCorpProfiles } from 'configurations/redux/corpUserSlice.js';
/* import CorporateManagerDetailSection from './CorporateProfileSection/CorporateManagerDetailSection.js';
import CorporateTagSection from './CorporateProfileSection/CorporateTagSection.js';
import CorporateJobLinkSection from './CorporateProfileSection/CorporateJobLinkSection.js';
import CorporateFavouriteProfileSection from './CorporateProfileSection/CorporateFavouriteProfileSection.js'; */
import LoadingModal from '../../Component/LoadingModal.js';
import Backdrop from '../../Component/Backdrop.js';
import LoadingImage from 'assets/img/Loading-pana.png';
import FontIcon from 'views/Component/FontIcon.js';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/CreateOutlined';
import IconLibrary from 'views/Component/IconLibrary.js';
import CoverPictureSection from '../ProfilePage/ProfileSection/CoverPictureSection.js';
import CoverPictureEditModal from '../ProfilePage/ProfileSection/CoverPictureEditModal.js';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
//Rahul Code
import { Grid, Hidden, Typography } from '@material-ui/core';
import SocialRecommendedFeed from 'views/Component/SocialFeed/SocialRecommendedFeed';
import CalendarSection from "../../Component/CalendarSection";
import {
  getSocialPostByRecent,
  getSocialPostByTop,
  getSocialPostByPostId,
  resetPostGet,
  getHashtagMatch,
  getSocialPostByHashId,
  resetHashtagMatch,
  getRecommendedPostByAccId,
} from 'configurations/redux/socialFeedSlice.js';

import { getArticlesRecommendedByAcct } from 'configurations/redux/userDashboardSlice';

import ProfileStrengthSection from "../ProfilePage/ProfileSection/ProfileStrengthSection";
import communityPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/CommunityPage/communityPageStyle.js";

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ProfileImg from 'assets/img/profileImg.jpg';
import Psy from 'assets/images/psy.png';
import R from 'assets/img/r.jpg';
import Pimg from 'assets/img/pimg.jpg';
import defaultAvatar from 'assets/img/corporate_avatar.jpeg';

const useStyles = makeStyles(corporateProfilePageStyle);
const useStyles1 = makeStyles(communityPageStyle);

const CryptoJS = require('crypto-js');
const publicIp = require('public-ip');

export const CorporateDashboardPage = ({ match }) => {

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };


  function imageExists(image_url) {
    var http = new XMLHttpRequest();

    try {
      http.open("HEAD", image_url, false);
      http.send();
    } catch (error) { }

    return http.status == 200;
  }


  const classes = useStyles();
  const articleclasses = useStyles1();
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.corpUser.isLoading);
  const isUpdated = useSelector(state => state.corpUser.isUpdated);
  const userStatus = useSelector(state => state.auth.user);
  const user = useSelector(state => state.corpUser);
  // const buildProfileFlag = useSelector((state) => state.auth.buildProfileFlag);
  const isViewed = useSelector(state => state.corpUser.isViewed);

  const [clientIp, setClientIp] = React.useState();
  const [industryList, setIndustryList] = React.useState([]);
  const [countryList, setCountryList] = React.useState([]);
  const [locationList, setLocationList] = React.useState([]);
  const [changePasswordModal, setChangePasswordModal] = React.useState(false);
  const [careerGuidesList, setCareerGuidesList] = React.useState([]);


  const [isOpen, setOpen] = React.useState(false);
  const closeModal = () => {
    setOpen(false);
  };
  const auth = useSelector(state => state.auth);
  const requestProfile = {
    acctId: decryptData(match.params.acctId),
    bizId: decryptData(match.params.bizId),
    ipAddr: localStorage.getItem('ipAddr'), //clientIp,
    userId: userStatus.acctId,
    accessToken: localStorage.getItem('token'),
  };
  const requestJobProfile = {
    acctId: userStatus.acctId,
    bizId: decryptData(match.params.bizId),
    accessToken: localStorage.getItem('token'),
    lastRecordSize: 0,
    requestRecordSize: 1,
    paginationClick: false,
    statusId: -1,
  };

  const [basicProfileEditModal, setBasicProfileEditModal] = React.useState(false);

  const closeBasicProfileEditModal = () => {
    setBasicProfileEditModal(false);
  };
  const closeChangePasswordModal = () => {
    setChangePasswordModal(false);
  };
  const profileRecord = useSelector(state => state.corpUser.profileRecord);
  const buildProfileFlag = useSelector(state => state.corpUser.buildProfileFlag);
  const jobPostList = useSelector(state => state.jobManagement.jobPostList);

  const favProfileList = useSelector((state) => state.favProfile.profileList);
  console.log('--------favProfileList-------------');
  console.log(favProfileList);

  (async () => {
    // setClientIp(localStorage.getItem("ipAddr"));
  })();

  React.useEffect(() => {
    // console.log(clientIp);
    if (clientIp) {
      // dispatch(getCorpProfiles(requestProfile));
      // dispatch(getJobPosts(requestJobProfile));
    }
  }, [clientIp]);

  React.useEffect(() => {
    gaPageView(window.location.pathname);
    //Rahul Code
    const requestJobProfileNew = {
      //  acctId: userStatus.acctId,
      // bizId: decryptData(match.params.bizId),
      acctId: 137,
      bizId: 20,
      accessToken: localStorage.getItem('token'),
      lastRecordSize: 0,
      requestRecordSize: 100,
      paginationClick: false,
      email: userStatus.acctId,
      statusId: 1,
      typeId: -1,
    };

    dispatch(getJobPosts(requestJobProfileNew));

    const defaultJobRequest = {
      acctId: userStatus.acctId,
      email: userStatus.email,
      accessToken: localStorage.getItem("token"),
      lastRecordSize: 0,
      requestRecordSize: 10,
      bizId: decryptData(match.params.bizId), //match.params.bizId,//decryptData(match.params.bizId),

      paginationClick: false,
    };

    dispatch(getFavProfileListNew(defaultJobRequest));

    if (buildProfileFlag === 0) {
      setBasicProfileEditModal(true);
    }

    const requestRecommendData = {
      acctId: user.acctId,
      accessToken: localStorage.getItem('token'),
      email: user.email,
      lastRecordSize: 0,
      requestRecordSize: 5
    };

    dispatch(getRecommendedPostByAccId(requestRecommendData));

    /*    const requestCorpProfile = {
         acctId: decryptData(match.params.acctId),
         bizId: decryptData(match.params.bizId),
         email: userStatus.email,
         ipAddr: localStorage.getItem('ipAddr'), //clientIp,
         userId: userStatus.acctId,
         accessToken: localStorage.getItem('token'),
       };
      */
    const requestCorpProfile = {
      acctId: 139,
      bizId: 20,
      email: 'fengip4@gmail.com',
      ipAddr: localStorage.getItem('ipAddr'), //clientIp,
      userId: userStatus.acctId,
      accessToken: localStorage.getItem('token'),
    };
    dispatch(getCorpProfiles(requestCorpProfile));

    const payloadRequested = {
      email: userStatus.email,
      acctId: userStatus.acctId,
      accessToken: localStorage.getItem('token'),
      lastRecordSize: 0,
      requestRecordSize: 6
    };

    dispatch(getArticlesRecommendedByAcct(payloadRequested, (result) => {
      setCareerGuidesList(result.payload.articleRecommendationList);
    }));



  }, []);

  return (
    <div>
      <Header />
      <Link to={{
        pathname: `/chatbot-landing/${encryptData(userStatus.acctId)}/${match.params.bizId}/0`
      }}
        className='chatboticon'><img src={Chatboticon} alt='chatboticon' /></Link>

      <div className='corpoarteOuter'>
        <GridContainer className='customContainer' justifyContent="center" spacing={4}>
          <GridItem xs={12} sm={12} md={8} lg={8} xl={8} className={classes.middlePart}>
            <GridContainer justifyContent="center" spacing={2}>
              <GridItem xs={12} sm={12} md={12} lg={12} xl={12} className={classes.middlePart}>
                <div className='dashbaordTitle'>Dashboard</div>
              </GridItem>
            </GridContainer>
            <GridContainer justifyContent="center" spacing={2}>
              <GridItem xs={12} sm={12} md={6} lg={6} xl={6} className={classes.middlePart}>
                <div className='dashbaordDiv'>
                  <Typography className='viewedProfileDiv'>
                    <Typography className='viewedProfileLeft'>

                      <Link to={`/corporate-profile-page/${encryptData(userStatus.acctId)}/${match.params.bizId}`} >
                        <img className='profileImg' src={profileRecord.profilePic ? profileRecord.profilePic : ProfileImg} />
                      </Link>
                    </Typography>
                    <Typography className='viewedProfileRight'>
                      <Typography className='viewProfileRightUpper'>
                        <Typography className='viewProfileRightUpperLeft'>
                          <Typography className='proTitle'>{profileRecord.companyName}</Typography>
                          <p className='pText'>{profileRecord.companyProfile ? profileRecord.companyProfile : ''}</p>
                        </Typography>
                      </Typography>
                      <Link to={`/corporate-profile-page/${encryptData(userStatus.acctId)}/${match.params.bizId}`} className='improveProfile'>Manage Business Page Settings</Link>
                    </Typography>
                  </Typography>
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={3} lg={3} xl={3} className={classes.middlePart}>
                <Link to={`/corporate-job-management-page/`} >
                  <div className='dashbaordDiv jobs'>
                    <div className='jobCount'>{profileRecord.jobsActive ? profileRecord.jobsActive : '0'}</div>
                    Jobs Active
                  </div>
                </Link>
              </GridItem>
              <GridItem xs={12} sm={12} md={3} lg={3} xl={3} className={classes.middlePart}>
                <Link href="#">
                  <div className='dashbaordDiv upcoming'>
                    <div className='jobCount'>{profileRecord.upcomingInterviews ? profileRecord.upcomingInterviews : '0'}</div>
                    Upcoming <br />Interviews
                    {/* <Link to="#" className='jobActive'>Upcoming <br />Interviews</Link> */}
                  </div>
                </Link>
              </GridItem>
            </GridContainer>
            <GridContainer justifyContent="center" spacing={2}>
              <GridItem xs={12} sm={12} md={12} lg={12} xl={12} className={classes.middlePart}>
                <div className='dashbaordDiv hirePipeline'>
                  <Typography component='div' className='upcomingDiv'>
                    <Typography component='div' className='upcomingDivLeft'>
                      <Typography className='UpcomingTitle hireTitle'>Hiring Pipeline</Typography>
                    </Typography>
                    <Typography component='div' className='upcomingDivRight'>
                      <Link to={`/corporate-job-management-page/`} >View all jobs</Link>
                    </Typography>
                  </Typography>
                  <div className='hirePipelineOuter'>
                    <div className='hirePipelineOuter1'>
                      <div className='hirePipelineinner'>
                        <div className='hirePipelineFirst first'>
                          <span>Jobs</span>
                        </div>
                        <div className='hirePipelineFirst'>
                          <span>New Applied</span>
                        </div>
                        <div className='hirePipelineFirst'>
                          <span>Screening</span>
                        </div>
                        <div className='hirePipelineFirst'>
                          <span>Interview</span>
                        </div>
                        <div className='hirePipelineFirst'>
                          <span>Offer</span>
                        </div>
                        <div className='hirePipelineFirst'>
                          <span>Hired</span>
                        </div>
                      </div>
                      {jobPostList && jobPostList.map((item, index) => {
                        return (
                          <div className='hirePipelineinner'>
                            <div className='hirePipelineFirst first'>
                              <div className='businessTitle'>{item.jobPostInfo.title}</div>
                              <span className='jobSpan'>Total applications: {item.jobPostInfo.applicationsCount ? item.jobPostInfo.applicationsCount : 0}</span>
                            </div>
                            <div className='hirePipelineFirst'>
                              <span className='spanCandidate lightY'>{item.jobPostInfo.applCount ? item.jobPostInfo.applCount : 0} Candidates</span>
                            </div>
                            <div className='hirePipelineFirst'>
                              <span className='spanCandidate lightY'>{item.jobPostInfo.shortlistedCount ? item.jobPostInfo.shortlistedCount : 0} Candidates</span>
                            </div>
                            <div className='hirePipelineFirst'>
                              <span className='spanCandidate yellow'>{item.jobPostInfo.interviewCount ? item.jobPostInfo.interviewCount : 0} Candidates</span>
                            </div>
                            <div className='hirePipelineFirst'>
                              <span className='spanCandidate'>{item.jobPostInfo.offerCount ? item.jobPostInfo.offerCount : 0} Candidates</span>
                            </div>
                            <div className='hirePipelineFirst'>
                              <span className='spanCandidate blue'>{item.jobPostInfo.hiredCount ? item.jobPostInfo.hiredCount : 0} Candidates</span>
                            </div>
                          </div>)
                      })
                      }
                    </div>
                  </div>
                </div>
              </GridItem>
            </GridContainer>

            <GridContainer justifyContent="center" spacing={2}>
              <GridItem xs={12} sm={12} md={12} lg={12} xl={12} className={classes.middlePart}>
                <div className='dashbaordDiv upcomingDashbaordDiv'>
                  <Typography component='div' className='upcomingDiv'>
                    <Typography component='div' className='upcomingDivLeft'>
                      <Typography className='UpcomingTitle hireTitle'>Upcoming Interviews</Typography>
                    </Typography>
                    {/* <Typography component='div' className='upcomingDivRight'>
                            <Link>View all jobs</Link>
                          </Typography> */}
                  </Typography>

                  <CalendarSection bizId={decryptData(match.params.bizId)} data='corporate' />
                </div>
              </GridItem>
            </GridContainer>
          </GridItem>
          <Hidden smDown>
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4} className={classes.middlePart}>
              <Typography component='div' className='upcomingDiv'>
                <Typography component='div' className='upcomingDivLeft'>
                  <Typography className='UpcomingTitle hireTitle candidateTitle'>Candidates</Typography>
                </Typography>
              </Typography>
              <Card className='cardCustom customCaRd'>
                <CardBody className='customCardBody cardcardcustom'>
                  <div className="customcardbodyinner">
                    <Typography className='myApplicationDiv'>
                      <Typography className='myAppTitle'>Saved Candidates</Typography>
                      <Link to={`/favourite-profile-page/`} className='manageLink'>View All</Link>
                    </Typography>

                    <Typography className='myApplicationDivMain'>

                      {
                        favProfileList && favProfileList.map((item, index) => {
                          return (
                            <Link to={`/view-profile/${encryptData(item.profileId)}`} >
                              <Typography className='viewedProfileDiv saveCandidate'>
                                <Typography className='viewedProfileLeft'>
                                  <img className='profileImg' src={item.profilePic ? item.profilePic : ProfileImg} />
                                </Typography>
                                <Typography className='viewedProfileRight'>
                                  <Typography className='viewProfileRightUpper'>
                                    <Typography className='viewProfileRightUpperLeft'>
                                      <Typography className='proTitle'>{item.firstName}</Typography>
                                      <p className='pText'>{item.countryName}</p>
                                    </Typography>
                                  </Typography>
                                </Typography>
                              </Typography>
                            </Link>
                          )
                        })
                      }
                    </Typography>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          </Hidden>
        </GridContainer>
        <GridContainer className='customContainer' justifyContent="center" spacing={8}>
          <GridItem xs={12} sm={12} md={6} lg={6} xl={6} className={classes.middlePart}>
            <Typography component='div' className='upcomingDiv resourceDiv'>
              <Typography component='div' className='upcomingDivLeft'>
                <Typography className='UpcomingTitle hireTitle'>Recruitment Resources</Typography>
              </Typography>
              <Typography component='div' className='upcomingDivRight'>
                <Link to={`/resources/`} className='manageLink'>View All</Link>
              </Typography>
            </Typography>
            <GridContainer>
              {(careerGuidesList.length > 0 && careerGuidesList.length <= 6) && careerGuidesList.map((item, index) => {
                return (
                  <GridItem xs={12} sm={6} md={4} lg={4} xl={4}>
                    <Link
                      className={classes.textDeco}
                      target="_blank"
                      to={{
                        pathname: auth.isPublic
                          ? `/public-community-detail-page/${encryptData(item.articleId)}`
                          : `/community-detail-page/${encryptData(item.articleId)}`,
                        //state: { acctId: item.acctId },
                      }}
                      color="transparent"
                      key={item.articleId}
                    >
                      <div className='cardStyleDivOuter' style={{ height: "290px" }} key="0">
                        <Card
                          className='cardStyleDiv'
                          style={{ height: "100%" }}
                        >
                          <CardMedia
                            className={articleclasses.cardImage}
                            image={
                              item.coverPic &&
                                imageExists(communityArticlePath + item.coverPic)
                                ? communityArticlePath + item.coverPic
                                : defaultAvatar
                            }
                            //  image={defaultAvatar}
                            title={item.articleName}
                          />

                          <CardBody className={articleclasses.cardPadding}>
                            <div>
                              <GridContainer>
                                <GridItem xs={12}>
                                  <Chip
                                    key="2"
                                    label={
                                      <p
                                        className={
                                          articleclasses.createdDescription +
                                          " " +
                                          articleclasses.createdDescFont
                                        }
                                      >
                                        {item.subcategory}
                                      </p>
                                    }
                                    className={articleclasses.chip}
                                    color={"secondary"}
                                  />
                                </GridItem>
                                {/* <GridItem xs={6} className={articleclasses.textAlignRight}>
                                <span className={articleclasses.createdDescFont}>
                                  
                                  16 June 2022
                                </span>
                              </GridItem> */}
                              </GridContainer>
                            </div>
                            <div className={articleclasses.titleDiv}>
                              <h4
                                className={
                                  articleclasses.articleTitle + " " + articleclasses.articleCardTitle
                                }
                              >
                                {item.articleName}
                              </h4>
                            </div>
                            <div>
                              <span className={articleclasses.createdDescFont}> By : </span>
                              <span
                                className={
                                  articleclasses.createdDescription +
                                  " " +
                                  articleclasses.createdDescFont
                                }
                              >
                                Oppty
                              </span>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </Link>
                  </GridItem>
                )
              })}

            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={6} xl={6} className={classes.middlePart}>
            <Typography component='div' className='upcomingDiv resourceDiv'>
              <Typography component='div' className='upcomingDivLeft'>

                <Typography className='UpcomingTitle hireTitle'>Job Templates <i class="fa-solid fa-circle-question"></i></Typography>
              </Typography>
              <Typography component='div' className='upcomingDivRight'>
                <Link to={`/search-page-article/`} className='manageLink'>View All</Link>
              </Typography>
            </Typography>
            <CorporateJobTemplateSection />


          </GridItem>
        </GridContainer>
        <GridContainer className='customContainer' justifyContent="center" spacing={2}>
          <GridItem xs={12} sm={12} md={7} lg={7} xl={7} className={classes.middlePart}>
            <Typography component='div' className='upcomingDiv resourceDiv'>
              <Typography component='div' className='upcomingDivLeft'>
                <Typography className='UpcomingTitle hireTitle'>Recommended Posts</Typography>
              </Typography>
            </Typography>

            <div class="recommend_post_main">
              <SocialRecommendedFeed />
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={5} lg={5} xl={5} className={classes.middlePart}>
          </GridItem>
        </GridContainer>
      </div>

      <Footer />
    </div>
  );
};

export default CorporateDashboardPage;
