import React from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { activateMasterCode } from "configurations/redux/masterFunctionSlice.js";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js";

// import EducationEditModal from "./EducationEditModal.js";
import EducationCard from "./EducationCard.js";
import IconLibrary from "views/Component/IconLibrary.js";
const useStyles = makeStyles(profilePageStyle);

export const EducationSection = ({ isViewed, educationRecord }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const profileStrength = useSelector((state) => state.user.profileStrength);

	// const [editEducationModal, setEditEducationModal] = React.useState(false);
	// const [eduRecordId, setEduRecordId] = React.useState();
	// const closeEducationEditModal = () => {
	// 	setEditEducationModal(false);
	// 	setEduRecordId();
	// };
	return (
		<div>
			<Card className={classes.cardFrame}>
				<CardBody>
					<GridContainer
						justifyContent="space-between"
						alignContent="center"
						alignItems="center"
					>
						<GridItem xs={9} sm={9} md={9}>
							{isViewed ? (
								educationRecord.length > 0 ? (
									<h4 className={classes.title}>Education</h4>
								) : (
									<h4 className={classes.title}>No Education Record</h4>
								)
							) : (
								<React.Fragment>
									<h4 className={classes.title}>Education</h4>
								</React.Fragment>
							)}
						</GridItem>

						<GridItem
							xs={2}
							sm={2}
							md={2}
							lg={2}
							className={classes.buttonColor}
						>
							{isViewed ? null : (
								<Tooltip
									id="tooltip-top"
									title={
										<p className={classes.tooltipDescription}>Add Education</p>
									}
									placement="top"
									classes={{ tooltip: classes.tooltip }}
								>
									{/* <IconButton
										onClick={() => {
											setEduRecordId(-1);
											setEditEducationModal(true);
										}}
										color="inherit"
										edge="end"
									>
										
										<IconLibrary.CreateSquare width={32} height={32} />
									</IconButton> */}
									<Button
										justIcon
										style={{ boxShadow: "none" }}
										color="white"
										className={classes.buttonIconMargin}
										onClick={() => {
											// setEduRecordId(-1);
											const extension =
												profileStrength[0].strengthList.filter((item) => {
													return (
														item.improvementTip.functionCode ===
														"MF_PM_MODAL_EDUCATION"
													);
												})[0].value === 0
													? profileStrength[0].strengthList.filter((item) => {
															return (
																item.improvementTip.functionCode ===
																"MF_PM_MODAL_EDUCATION"
															);
													  })[0].improvementTip
													: null;
											console.log(extension);
											const response = {
												functionCode: "MF_PM_MODAL_EDUCATION",
												extension: extension,
											};
											dispatch(activateMasterCode(response));
											/* 	setEditEducationModal(true); */
										}}
									>
										<IconLibrary.Create />
									</Button>
								</Tooltip>
							)}
						</GridItem>
						<GridItem xs={12} sm={12} md={12}>
							<EducationCard />
						</GridItem>
					</GridContainer>
				</CardBody>
			</Card>

			{/* {eduRecordId ? (
				<EducationEditModal
					open={editEducationModal}
					onClose={closeEducationEditModal}
					eduRecId={eduRecordId}
					eduRecord={null}
				/>
			) : null} */}
		</div>
	);
};

const mapStateToProps = (state) => ({
	profileRecord: state.user.profileRecord,
	isViewed: state.user.isViewed,
	educationRecord: state.user.educationRecord,
});

export default connect(mapStateToProps, null)(EducationSection);
