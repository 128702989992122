import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
	departmentViewRoadmap,
	viewRoadmapRoleByRoleId,
} from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Department_Physiotherapy from "assets/img/Career_Roadmap/Healthcare/Department_Physiotherapy_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentPhysiotherapy() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Physiotherapy;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "p",
				shape: "poly",
				coords: [84,125,76,128,72,131,68,137,66,143,66,736,68,743,71,748,76,753,84,756,190,755,197,752,202,749,206,744,209,736,208,145,206,136,202,130,195,126,187,124,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "348",
				shape: "poly",
				coords: [241,405,233,408,230,411,227,416,226,422,226,483,228,491,230,496,235,499,241,501,353,501,361,499,366,494,369,489,370,483,371,424,369,415,366,410,361,406,353,405,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "349",
				shape: "poly",
				coords: [440,405,431,407,428,410,424,415,423,421,424,483,426,492,428,496,434,500,443,501,551,501,559,498,563,495,566,491,568,484,567,421,565,413,562,409,555,406,550,405,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "350",
				shape: "poly",
				coords: [639,405,629,407,626,410,623,415,622,424,622,484,624,491,625,495,630,499,638,501,750,500,757,498,762,494,765,489,766,484,765,423,763,411,758,407,753,405,749,405,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "351",
				shape: "poly",
				coords: [833,405,827,407,824,410,822,414,820,418,820,489,823,494,826,497,832,500,839,501,948,500,955,498,959,495,961,492,964,486,964,419,962,413,958,409,954,407,950,405,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "352",
				shape: "poly",
				coords: [1031,405,1024,409,1021,411,1019,417,1017,423,1018,485,1020,493,1023,497,1027,499,1034,501,1149,500,1154,497,1158,495,1160,493,1161,487,1161,420,1159,413,1155,409,1150,406,1145,405,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "355",
				shape: "poly",
				coords: [1234,123,1224,126,1220,129,1217,134,1215,141,1217,215,1218,222,1222,226,1227,229,1232,230,1408,230,1417,227,1421,224,1424,220,1425,215,1426,140,1424,132,1420,128,1415,125,1409,124,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "356",
				shape: "poly",
				coords: [1495,123,1487,126,1484,129,1481,133,1479,139,1480,215,1480,221,1482,224,1486,227,1486,226,1490,229,1496,230,1674,229,1680,227,1684,224,1687,221,1689,216,1689,141,1689,133,1685,128,1680,125,1674,123,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "357",
				shape: "poly",
				coords: [1231,299,1223,301,1220,305,1216,311,1216,317,1216,391,1217,397,1220,401,1224,404,1232,406,1409,405,1417,403,1421,400,1424,394,1425,389,1425,315,1423,308,1420,303,1415,300,1409,299,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "358",
				shape: "poly",
				coords: [1495,299,1488,301,1483,304,1481,310,1479,316,1479,388,1482,397,1485,402,1489,404,1497,406,1674,405,1682,402,1686,399,1689,394,1690,389,1690,316,1687,306,1681,301,1675,299,1667,299,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "353",
				shape: "poly",
				coords: [1229,475,1222,477,1218,481,1216,487,1215,494,1216,565,1217,573,1220,577,1225,580,1230,580,1231,581,1412,580,1418,577,1422,574,1425,570,1425,563,1425,489,1423,482,1418,477,1413,475,1408,474,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "354",
				shape: "poly",
				coords: [1495,474,1488,476,1483,480,1480,486,1479,494,1480,566,1481,572,1484,577,1490,580,1498,581,1676,580,1683,578,1686,575,1688,569,1689,563,1689,490,1687,483,1684,478,1679,475,1672,475,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "359",
				shape: "poly",
				coords: [1230,650,1223,653,1219,656,1216,661,1216,667,1216,740,1217,746,1218,751,1224,754,1232,756,1410,755,1417,753,1421,750,1424,745,1425,740,1425,666,1423,658,1420,653,1415,651,1410,650,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "360",
				shape: "poly",
				coords: [1497,650,1488,652,1484,654,1481,659,1480,667,1480,740,1481,748,1485,753,1490,755,1498,756,1673,756,1681,753,1686,750,1688,745,1689,739,1690,669,1687,659,1684,654,1679,651,1673,649,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "p") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo);
			dispatch(viewRoadmapRoleByRoleId(requestInfo));

			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
