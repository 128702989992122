/* eslint-disable react/prop-types */
import React, { createRef, Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import { MentionsInput, Mention } from "react-mentions";
import ReactMarkdown from "react-markdown";

import Comment from "../../SocialFeed/Comment.js";

import axios from "axios";
import clsx from "clsx";
import defaultAvatar from "assets/img/candidate_avatar.jpeg";

import {
	Card,
	CardContent,
	Typography,
	Button,
	Input,
	IconButton,
	makeStyles,
	Popover,
	Modal,
	Box,
	Popper,
	Grow,
	ClickAwayListener,
	Paper,
} from "@material-ui/core";
import { ReactComponent as HandClapIcon } from "assets/svg/clapping.svg";
import { ReactComponent as SendIcon } from "assets/svg/send-svgrepo-com.svg";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import MoodBadOutlinedIcon from "@mui/icons-material/MoodBadOutlined";
import SentimentVerySatisfiedOutlinedIcon from "@mui/icons-material/SentimentVerySatisfiedOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import cross from "assets/img/cross.png";

import {
	header,
	handleFollow,
	handleUnfollow,
	createHidePost,
} from "configurations/configurations.js";

import dotBtn from "assets/images/dotBtn.png";
import leftArrow from "assets/img/arrow-left.png";
import rightArrow from "assets/img/arrow-right.png";
import like from "assets/images/like.png";
import comment from "assets/images/comment.png";
import share from "assets/images/share.png";
import saved from "assets/images/saved.png";
import emoji from "assets/images/emoji.png";
import gallery from "assets/images/documentation.png";
import avatar from "assets/img/candidate_avatar.jpeg";
import video from "assets/img/video.png";

import { Close } from "@material-ui/icons";
import commentStyle from "assets/jss/material-kit-pro-react/views/componentStyle/socialFeed/commentStyles";
import profileViewActivityCardStyles from "assets/jss/material-kit-pro-react/views/componentStyle/profileView/myActivity/profileViewActivityCardStyles.js";
import profileViewActivityPollCardStyles from "../../../../assets/jss/material-kit-pro-react/views/componentStyle/profileView/myActivity/profileViewActivityPollCardStyles.js";
import ReportModal from "views/Component/ReportModal.js";
import { getProfiles } from "configurations/redux/userSlice.js";
import CommentSkeleton from "../../SocialFeed/CommentSkeleton.js";
import GridItem from "../../../../components/Grid/GridItem.js";
import GridContainer from "../../../../components/Grid/GridContainer.js";
import Picker from "emoji-picker-react";
import { createReport } from "configurations/redux/reportSlice.js";
import CommentImageModal from "../../SocialFeed/CommentImageModal.js";
import {
	getSocialCommentByPostId,
	createSocialComment,
	createSocialReaction,
	deleteSocialReaction,
	createSocialHashtag,
	getSocialHashtag,
	getSocialProfileTag,
	resetCreatedHashtag,
	resetPostComment,
} from "configurations/redux/socialFeedSlice.js";
import {
	instanceUrl,
	encryptData,
	dataURLtoFile,
	TimeFormatUniqueId,
	TimeFormatSocialFeed,
} from "configurations/configurations.js";
import "quill-mention";
import "react-quill/dist/quill.bubble.css";
import ReactQuill, { Quill } from "react-quill";
import { isMobile } from "react-device-detect";

const ProfileViewActivityPollCard = ({
	post,
	reportTypesList,
	reportReasonsList,
	updateFeedHandler,
}) => {
	const classes = makeStyles(commentStyle)();
	const styles = makeStyles(profileViewActivityCardStyles)();
	const stylesPoll = makeStyles(profileViewActivityPollCardStyles)();
	const user = useSelector((state) => state.auth.user);

	const imageSlider = createRef();

	const [postType, setPostType] = useState("");
	const [hashTagList, setHashTagList] = useState([]);
	const [postList, setPostList] = useState([]);
	const [commentList, setCommentList] = useState([]);
	const [reportTypeIndex, setReportTypeIndex] = useState(null);
	const [reportReasonIndex, setReportReasonsIndex] = useState(null);
	const [reportSuccessResponse, setReportSuccessResponse] = useState(null);
	const [optionClicked, setOptionClicked] = useState(-1);

	const dispatch = useDispatch();

	const quillRef = React.useRef(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const [anchorElEmoji, setAnchorElEmoji] = useState(null);
	const anchorRef = React.useRef(null);
	const [showReactionPaper, setShowReactionPaper] = useState(false);
	const [anchorShareEl, setAnchorShareEl] = useState(null);
	const [isPostSaved, setIsPostSaved] = useState(false);
	const [saveId, setSaveId] = useState(null);
	const [commentsExpanded, setCommentState] = useState(false);
	const [reportModal, setReportModal] = useState(false);
	const [showComment, setShowComment] = useState(true);
	const searchResult = useSelector((state) => state.socialFeed.searchResult);

	const userStatus = useSelector((state) => state.auth.user);
	const postUser = useSelector((state) => state.user);
	const isOnAmend = useSelector((state) => state.socialFeed.isOnAmend);
	const isCreating = useSelector((state) => state.socialFeed.isCreating);
	const amendPostId = useSelector((state) => state.socialFeed.amendPostId);
	const [skeletonShow, setSkeletonShow] = React.useState(false);
	const isEndComment = useSelector((state) => state.socialFeed.isEndComment);
	const isLoadingComment = useSelector(
		(state) => state.socialFeed.isLoadingComment
	);
	const likeId = useSelector((state) => state.socialFeed.likeId);
	const [likeColor, setLikeColor] = React.useState("#92929d");
	const [reactionCount, setReactionCount] = React.useState(post.reactionCount);
	const [likeShow, setLikeShow] = React.useState(
		post.ownerLike ? post.ownerLike.socialReactionUserList.length : 0
	);
	const [reactionId, setReactionId] = React.useState(
		post.ownerLike
			? post.ownerLike.socialReactionUserList.length > 0
				? post.ownerLike.socialReactionUserList[0].reactionId
				: null
			: null
	);

	let fileInput = React.createRef();
	const [commentImageModal, setCommentImageModal] = React.useState(false);
	const [commentImage, setCommentImage] = React.useState(null);
	const [commentImageSaved, setCommentImageSaved] = React.useState(null);
	const [commentImageIsEdit, setCommentImageIsEdit] = React.useState(false);
	const [fileName, setFileName] = React.useState(null);

	const hashtagList = useSelector((state) => state.socialFeed.hashtagList);
	const profileTagList = useSelector(
		(state) => state.socialFeed.profileTagList
	);
	const hashtagKeyword = useSelector(
		(state) => state.socialFeed.hashtagKeyword
	);
	const profileTagKeyword = useSelector(
		(state) => state.socialFeed.profileTagKeyword
	);
	const hashValue = useSelector((state) => state.socialFeed.hashValue);
	const createHashtagPostId = useSelector(
		(state) => state.socialFeed.createHashtagPostId
	);
	const enterKey = useSelector((state) => state.socialFeed.enterKey);
	const lastOpenCommentPostId = useSelector(
		(state) => state.socialFeed.lastOpenCommentPostId
	);

	const mentionModuleConfig = {
		allowedChars: /^[A-Za-z0-9\sÅÄÖåäö]*$/,
		mentionDenotationChars: ["@", "#"],
		source: function (searchTerm, renderList, mentionChar) {
			// console.log(searchTerm);

			if (mentionChar === "@") {
				renderList(profileTagList);
			} else {
				renderList(hashtagList);
			}

			if (
				profileTagKeyword != searchTerm &&
				mentionChar != "" &&
				mentionChar === "@"
			) {
				getProfileTagList(searchTerm);
			} else if (
				hashtagKeyword != searchTerm &&
				mentionChar != "" &&
				mentionChar === "#"
			) {
				getHashTagList(searchTerm);
				if (searchTerm.slice(-1) === " ") {
					var newHashTag = searchTerm.trim();
					// console.log(newHashTag);
					storeNewHashTag(newHashTag);
				}
			}
		},
	};

	const handleImageClick = () => {
		fileInput.current.value = null;
		fileInput.current.click();
	};

	const onEmojiClick = (event, emojiObject) => {
		quillRef.current.editor.insertText(
			quillRef.current.editor.getLength() - 1,
			emojiObject.emoji,
			"true"
		);
	};

	useEffect(() => {
		if (post.ownerLike && post.ownerLike.socialReactionUserList.length)
			setLikeColor("#6F52ED");
	}, [post]);

	useEffect(() => {
		if (!isEndComment) setCommentState(true);
	}, [isEndComment]);

	const collapseComment = () => {
		var elmnt = document.getElementById("socialPost_" + post.postId);
		if (elmnt != null) {
			elmnt.scrollIntoView({
				behavior: "smooth",
				block: "start",
			});
			setTimeout(() => {
				setCommentState(false);
			}, 500);
		}
	};

	useEffect(() => {
		let mounted = true;

		// getSocialHashtag({
		//   email: user.email,
		//   acctId: user.acctId,
		//   keyword: hashTagKeyword,
		//   lastRecordSize: 0,
		//   requestRecordSize: 10,
		//   accessToken: localStorage.getItem("token"),
		// })
		//   .then((res) => {
		//     if (mounted) setSocialHashTags(res.data.payload.hashtagList);
		//   })
		//   .catch((err) => console.log(err));

		// getSocialPostProfileTagByAcctId({
		//   email: user.email,
		//   acctId: user.acctId,
		//   keyword: profileTagKeyword,
		//   lastRecordSize: 0,
		//   requestRecordSize: 10,
		//   accessToken: localStorage.getItem("token"),
		// })
		//   .then((res) => {
		//     if (mounted) setSocialProfileTags(res.data.payload.profileList);
		//   })
		//   .catch((err) => console.log(err));

		// getSavePostByAcctId({
		//   email: user.email,
		//   acctId: user.acctId,
		//   lastRecordSize: 0,
		//   requestRecordSize: 50,
		//   accessToken: localStorage.getItem("token"),
		// })
		//   .then((res) => {
		//     if (!res.data.error) {
		//       let savedPost = res.data.payload.savePostList.filter(
		//         (savedPost) => savedPost.postId === post.postId
		//       )[0];
		//       if (savedPost) {
		//         setSaveId(savedPost.saveId);
		//         setIsPostSaved(true);
		//       }
		//     } else {
		//       console.error(res);
		//     }
		//   })
		//   .catch((error) => {
		//     console.error(error);
		//   });
		// getComments();

		return () => (mounted = false);
	}, []);

	useEffect(() => {
		// setPostType(post.typeOption);
		// setProfileTagList(post.profileTags.profileTagList);
		// setHashTagList(post.hashTags.hashagList); // "hashagList" spelling mistake in response
		// setPostList(post.postTypes.postTypeList);
		// if (post.comments && post.comments.length > 0) {
		// 	console.log(post.comments);
		// 	let comments = post.comments.commentList.filter(
		// 		(comment) => !comment.parentId
		// 	);
		// 	let replies = post.comments.commentList.filter(
		// 		(comment) => comment.parentId
		// 	);
		// 	setCommentList(
		// 		comments.map((com) => {
		// 			com["replyList"] = replies.filter(
		// 				(rep) => rep.parentId === com.commentId
		// 			);
		// 			return com;
		// 		})
		// 	);
		// }

		if (post.ownerLike && post.ownerLike.socialReactionUserList.length)
			setLikeColor("#6F52ED");
	}, [post]);

	const savePost = () => {
		if (!isPostSaved) {
			axios({
				method: "POST",
				url: `${instanceUrl}/api/sf/create-save-post`,
				headers: header,
				data: {
					email: user.email,
					acctId: user.acctId,
					postId: post.postId,
					accessToken: localStorage.getItem("token"),
				},
			})
				.then((res) => {
					if (!res.data.error) {
						setSaveId(res.data.payload.social.saveId);
						setIsPostSaved(true);
					} else {
						console.error(res);
					}
				})
				.catch((error) => {
					console.error(error);
				});
		} else {
			axios({
				method: "PUT",
				url: `${instanceUrl}/api/sf/delete-save-post`,
				headers: header,
				data: {
					email: user.email,
					acctId: user.acctId,
					saveId: saveId,
					accessToken: localStorage.getItem("token"),
				},
			})
				.then((res) => {
					if (!res.data.error) {
						setSaveId(null);
						setIsPostSaved(false);
					} else {
						console.error(res);
					}
				})
				.catch((error) => {
					console.error(error);
				});
		}
	};

	const reportPost = (reasonId, reportDesc) => {
		createReport({
			email: user.email,
			acctId: user.acctId,
			reportTypeId: 2, // 2 for report a social post
			recordId: post.postId, //
			reasonId: reasonId,
			reportDesc: reportDesc,
			accessToken: localStorage.getItem("token"),
		})
			.then((res) => {
				if (!res.data.error) {
					setReportSuccessResponse(res.data.payload);
					setReportReasonsIndex(null);
				} else {
					console.error(res);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const hidePost = (statusId) => {
		createHidePost({
			email: user.email,
			acctId: user.acctId,
			postId: post.postId,
			authorId: post.acctId,
			reasonId: 1,
			statusId: statusId, // statusId 1 is hide for specific post
			accessToken: localStorage.getItem("token"),
		})
			.then((res) => {
				if (!res.data.error) {
					console.log("Post Hidden Successfully");
					updateFeedHandler();
				} else {
					console.error(res);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const registerPostReaction = (reactionId) => {
		createSocialReaction = {
			email: user.email,
			acctId: user.acctId,
			reactionId: reactionId,
			postId: post.postId,
			accessToken: localStorage.getItem("token"),
		}
			.then((res) => {
				if (!res.data.error) {
					console.log(res.data);
				} else {
					console.error(res);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	// #region attachment gallary scroller events handlers
	let pos = { top: 0, left: 0, x: 0, y: 0 };
	const mouseMoveHandler = (e) => {
		let imageSliderNode = imageSlider.current;

		if (imageSliderNode) {
			// How far the mouse has been moved
			const dx = e.clientX - pos.x;
			const dy = e.clientY - pos.y;

			// Scroll the element
			imageSliderNode.scrollTop = pos.top - dy;
			imageSliderNode.scrollLeft = pos.left - dx;
		}
	};
	const mouseUpHandler = () => {
		let imageSliderNode = imageSlider.current;

		if (imageSliderNode) {
			imageSliderNode.style.removeProperty("user-select");

			imageSliderNode.removeEventListener("mousemove", mouseMoveHandler);
			imageSliderNode.removeEventListener("mouseup", mouseUpHandler);
		}
	};
	const mouseDownHandler = (e) => {
		let imageSliderNode = imageSlider.current;

		if (imageSliderNode) {
			imageSliderNode.style.userSelect = "none";

			imageSliderNode.addEventListener("mouseup", mouseUpHandler);
			imageSliderNode.addEventListener("mousemove", mouseMoveHandler);

			pos = {
				// The current scroll
				left: imageSliderNode.scrollLeft,
				top: imageSliderNode.scrollTop,
				// Get the current mouse position
				x: e.clientX,
				y: e.clientY,
			};
		}
	};

	const getComments = (lastRecordSize) => {
		dispatch(resetPostComment({ postId: null }));
		var requestProfile = {
			email: user.email,
			acctId: user.acctId,
			postId: post.postId,
			lastRecordSize: lastRecordSize,
			requestRecordSize: 5,
			accessToken: localStorage.getItem("token"),
			postList: searchResult,
		};
		console.log(requestProfile);
		dispatch(getSocialCommentByPostId(requestProfile));
	};

	const storeNewHashTag = (name, enterKey) => {
		const requestProfile = {
			email: user.email,
			acctId: user.acctId,
			hashtagName: name,
			accessToken: localStorage.getItem("token"),
			commentId: null,
			postId: post.postId,
			enterKey: enterKey,
		};
		dispatch(createSocialHashtag(requestProfile));
	};

	const getHashTagList = (searchTerm) => {
		const profileRequest = {
			email: user.email,
			acctId: user.acctId,
			keyword: searchTerm,
			lastRecordSize: 0,
			requestRecordSize: 10,
			accessToken: localStorage.getItem("token"),
		};
		dispatch(getSocialHashtag(profileRequest));
	};

	const getProfileTagList = (searchTerm) => {
		const profileRequest = {
			email: user.email,
			acctId: user.acctId,
			keyword: searchTerm,
			lastRecordSize: 0,
			requestRecordSize: 10,
			accessToken: localStorage.getItem("token"),
		};
		dispatch(getSocialProfileTag(profileRequest));
	};

	useEffect(() => {
		let mounted = true;
		var loadingTimeout = null;
		if (mounted) {
			if ((isOnAmend || isCreating) && amendPostId == post.postId)
				setSkeletonShow(true);
			else {
				loadingTimeout = setTimeout(() => {
					setSkeletonShow(false);
				}, 1000);
			}
		}
		return () => {
			mounted = false;
			clearTimeout(loadingTimeout);
		};
	}, [isCreating, isOnAmend]);

	useEffect(() => {
		if (createHashtagPostId == post.postId) {
			if (hashValue) {
				resetCreatedHashtag();
			}
			var quillEditor = null;
			var quillContent = null;
			quillEditor = quillRef.current;
			quillContent = quillRef.current.editor.getContents();
			if (quillEditor) {
				for (let i = 0; i < quillContent.ops.length; i++) {
					let insert = quillContent.ops[i].insert;
					try {
						console.log(insert.mention.denotationChar);
					} catch (e) {
						if (insert) {
							let hashtag = insert.replace("\n", "");
							if (hashtag.charAt(0) == " ") hashtag = hashtag.substring(1);
							var splitHash = hashtag.split(" ");
							// console.log(splitHash);
							for (var j = 0; j < splitHash.length; j++) {
								if (splitHash[j].charAt(0) == "#") {
									var newEditor = quillEditor.getEditor();
									var unprivilegedEditor = quillEditor.makeUnprivilegedEditor(
										newEditor
									);

									let newHashtag =
										'<span class="mention" data-index="0" data-denotation-char="#" data-id="' +
										hashValue.id +
										'" data-value="<a href=&quot;' +
										hashValue.link +
										"&quot; target=_blank>" +
										hashValue.value +
										'" data-link="' +
										hashValue.link +
										'"><span contenteditable="false"><span class="ql-mention-denotation-char">#</span><a href="' +
										hashValue.link +
										'" target="_blank">' +
										hashValue.value +
										"</a></span></span>";
									var newDescription = unprivilegedEditor
										.getHTML()
										.replace(splitHash[j].replace("\n", ""), newHashtag);
									// console.log(newDescription);
									let newQuill = new Quill("#quillEditor");
									let initialContent = newQuill.clipboard.convert(
										newDescription
									);
									quillEditor.editor.setContents(initialContent, "silent");
									quillEditor.editor.focus();
									quillEditor.editor.insertText(
										quillEditor.editor.getLength() - 1,
										enterKey ? "\n" : " "
									);
									quillEditor.editor.setSelection(
										quillEditor.editor.getLength(),
										quillEditor.editor.getLength()
									);
								}
							}
						}
					}
				}
			}
		}
	}, [hashValue, createHashtagPostId]);

	const checkNewHashTag = (newHashtag, enterKey) => {
		var splitText = newHashtag.split(" ");
		for (var i = 0; i < splitText.length; i++) {
			if (splitText[i].charAt(0) == "#") {
				storeNewHashTag(splitText[i].substring(1).trim(), enterKey);
			}
		}
	};

	const postComment = (quillEditor) => {
		var newEditor = quillEditor.getEditor();
		var unprivilegedEditor = quillEditor.makeUnprivilegedEditor(newEditor);
		if (
			unprivilegedEditor.getText().trim() == "" &&
			unprivilegedEditor.getContents().ops[0].insert.mention.denotationChar ==
				undefined
		) {
			console.error("There is nothing to reply");
		} else {
			var file = "";
			if (commentImageSaved) {
				console.log(TimeFormatUniqueId() + "." + fileName.split(".").pop());
				file = dataURLtoFile(
					commentImageSaved,
					TimeFormatUniqueId() + "." + fileName.split(".").pop()
				);
			}
			let commentFormData = new FormData();

			commentFormData.append("email", user.email);
			commentFormData.append("acctId", user.acctId);
			commentFormData.append("parentId", 0); // 0 means it's not a reply otherwise commentId is passesd
			commentFormData.append("postId", post.postId);

			let newQuill = new Quill("#quillEditor");
			let initialContent = newQuill.clipboard.convert(
				unprivilegedEditor.getHTML()
			);
			console.log(initialContent);
			quillEditor.editor.setContents(initialContent, "silent");
			var customEditor = quillEditor.getEditor();
			var customUnprivilegedEditor = quillEditor.makeUnprivilegedEditor(
				customEditor
			);

			commentFormData.append("description", customUnprivilegedEditor.getHTML()); // comment text

			commentFormData.append("attachments", file);
			commentFormData.append("status", 1);

			var j = 0;
			var k = 0;
			for (let i = 0; i < unprivilegedEditor.getContents().ops.length; i++) {
				let mention = unprivilegedEditor.getContents().ops[i].insert.mention;
				// console.log(mention);
				if (mention) {
					if (mention.denotationChar == "#") {
						var hashtagName = mention.value
							.replace(/<a\b[^>]*>/i, "")
							.replace(/<\/a>/i, "");
						commentFormData.append(`hashTags[${j}][postHashId]`, 0);
						commentFormData.append(`hashTags[${j}][hashtagName]`, hashtagName);
						j++;
					} else if (mention.denotationChar == "@") {
						commentFormData.append(`profileTags[${k}][postProfileId]`, 0);
						commentFormData.append(`profileTags[${k}][profileId]`, mention.id);
						k++;
					}
				}
			}

			commentFormData.append("accessToken", localStorage.getItem("token"));

			console.log(...commentFormData);
			var postList = {
				postList: searchResult,
			};
			console.log(postList);
			dispatch(createSocialComment(commentFormData, postList, post.postId, 0));
			setCommentImageSaved(null);
			// setComment("");
		}
	};

	const addLikePost = (reactionId) => {
		setShowReactionPaper(false);
		const requestProfile = {
			acctId: user.acctId,
			accessToken: localStorage.getItem("token"),
			email: user.email,
			reactionId: reactionId,
			postId: post.postId,
		};
		console.log(requestProfile);
		dispatch(createSocialReaction(requestProfile));
		setLikeColor("#6F52ED");
		setReactionCount(reactionCount + 1);
		setLikeShow(1);
		setReactionId(reactionId);
	};

	const removeLikePost = () => {
		const requestProfile = {
			acctId: user.acctId,
			accessToken: localStorage.getItem("token"),
			email: user.email,
			likeId:
				likeId && likeId > 0
					? likeId
					: post.ownerLike.socialReactionUserList
					? post.ownerLike.socialReactionUserList[0].likeId
					: null,
		};
		console.log(requestProfile);
		dispatch(deleteSocialReaction(requestProfile));
		setLikeColor("#92929d");
		setReactionCount(reactionCount - 1);
		setLikeShow(0);
	};

	const onFileChange = async (e) => {
		console.log(e);
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0];
			setCommentImage(file);
			setCommentImageIsEdit(false);
			setCommentImageModal(true);
		}
	};

	// #endregion
	console.log(post);
	return post.description ? (
		<Fragment>
			<div
				className={styles.body}
				id={"socialPost_" + post.postId}
				key={post.postId}
			>
				<div id="quillEditor" style={{ display: "none" }} />
				<div className={styles.header}>
					<div className={styles.headerLeft}>
						<Link
							to={{
								pathname: `/view-profile/${encryptData(post.acctId)}`,
							}}
							target="_blank"
							rel="noopener noreferrer"
						>
							<div className={styles.profilePic}>
								<img
									src={post.profilePic ? post.profilePic : defaultAvatar}
									alt=""
								/>
							</div>
						</Link>

						<div>
							<Link
								to={{
									pathname: `/view-profile/${encryptData(post.acctId)}`,
								}}
								target="_blank"
								rel="noopener noreferrer"
							>
								<h6 className={styles.name}>
									{post.firstName}
									{post.middleName ? " " + post.middleName : ""}
									{" " + post.surname}
								</h6>
							</Link>

							<span className={styles.time}>
								{TimeFormatSocialFeed(post.createdDate)}
							</span>
						</div>
					</div>

					<button
						onClick={(e) => {
							setAnchorEl(e.currentTarget);
							// const requestProfile = {
							// 	acctId: userStatus.acctId,
							// 	userId: post.acctId,
							// 	ipAddr: "",
							// 	accessToken: localStorage.getItem("token"),
							// };

							// dispatch(getProfiles(requestProfile));
						}}
					>
						<img src={dotBtn} alt="" />
					</button>
				</div>
				{post.description ? (
					<div
						className={styles.feedTitle}
						dangerouslySetInnerHTML={{ __html: post.description }}
					/>
				) : (
					<p className={styles.feedTitle}>
						There is no description. After that, We have applied validation that
						user cannot create post if there is no description, attachment or
						poll.
					</p>
				)}

				<Typography className={stylesPoll.pollTitle}>
					{post.postTypes[0].poolList[0].question}
				</Typography>
				<Typography className={stylesPoll.feedTitle}>
					The author can see how you vote
				</Typography>

				<Typography component="div" className={stylesPoll.pollGallery}>
					<div className={stylesPoll.QuestionandOptions}>
						<div className={stylesPoll.optionGroup}>
							{post.postTypes[0].poolList[0].poolOptionList
								? post.postTypes[0].poolList[0].poolOptionList.map(
										(item, index) => (
											<div
												key={index}
												className={clsx({
													[stylesPoll.Options]: optionClicked < 0,
													[stylesPoll.Optionselected]:
														optionClicked > -1 && optionClicked === 1,
													[stylesPoll.Optionnotselected]:
														optionClicked > -1 && optionClicked !== 1,
												})}
												onClick={() => {
													const request = {
														poolId: item.poolId,
														poolOptionId: item.poolOptionId,
													};
													setOptionClicked(1);
												}}
											>
												{item.optionDesc}
												{optionClicked === 1 ? (
													<span className={stylesPoll.percentage}>100%</span>
												) : (
													""
												)}
											</div>
										)
								  )
								: null}
							{/* <div
									className={clsx({
										[styles.Options]: optionClicked < 0,
										[styles.Optionselected]:
											optionClicked > -1 && optionClicked === 1,
										[styles.Optionnotselected]:
											optionClicked > -1 && optionClicked !== 1,
									})}
									onClick={() => {
										setOptionClicked(1);
									}}
								>
									
									{optionClicked === 1 ? (
										<span className={styles.percentage}>100%</span>
									) : (
										""
									)}
								</div>
								<div
									className={clsx({
										[styles.Options]: optionClicked < 0,
										[styles.Optionselected]:
											optionClicked > -1 && optionClicked === 2,
										[styles.Optionnotselected]:
											optionClicked > -1 && optionClicked !== 2,
									})}
									onClick={() => {
										setOptionClicked(2);
									}}
								>
									Mobile
									{optionClicked === 2 ? (
										<span className={styles.percentage}>100%</span>
									) : (
										""
									)}
								</div> */}
						</div>
						<div className={stylesPoll.pollDetails}>
							<span className={stylesPoll.viewandresult}>1 votes</span>
							<span className={stylesPoll.daysLeft}> 1 week left </span>
						</div>
					</div>
				</Typography>

				<div className={styles.feedActions}>
					<Button
						onClick={() => {
							dispatch(resetPostComment({ postId: post.postId }));
							if (showComment && lastOpenCommentPostId == post.postId) {
								setShowComment(false);
							} else {
								setShowComment(true);
							}
						}}
					>
						{/* <img src={commentIcon} alt="" /> */}
						<svg
							id="ic_comment"
							xmlns="http://www.w3.org/2000/svg"
							width="30.221"
							height="31.343"
							viewBox="0 0 30.221 31.343"
						>
							<g id="Comments">
								<path id="Path" d="M0,0H30.221V31.343H0Z" fill="none" />
								<g
									id="iconspace_Chat_3_25px"
									data-name="iconspace_Chat 3_25px"
									transform="translate(2.518 3.761)"
								>
									<path
										id="Path-2"
										data-name="Path"
										d="M6.478,23.824A1.935,1.935,0,0,1,4.543,21.9l0-2.9A5.9,5.9,0,0,1,0,13.244V5.917A5.928,5.928,0,0,1,5.926,0H19.258a5.928,5.928,0,0,1,5.926,5.918v7.327a5.928,5.928,0,0,1-5.926,5.918H13.239l-5.6,4.264A1.9,1.9,0,0,1,6.478,23.824ZM5.926,2.507a3.413,3.413,0,0,0-3.407,3.41v7.327A3.39,3.39,0,0,0,5.83,16.654,1.249,1.249,0,0,1,7.055,17.9l0,2.805,4.989-3.8a1.269,1.269,0,0,1,.765-.258h6.445a3.413,3.413,0,0,0,3.407-3.41V5.917a3.413,3.413,0,0,0-3.407-3.41Z"
										fill="#92929d"
									/>
								</g>
							</g>
						</svg>
						{post.commentCount} Comments
					</Button>
					<Button
						ref={anchorRef}
						onClick={() => {
							// console.log(likeColor);

							if (isMobile) {
								if (likeColor == "#6F52ED") removeLikePost();
								else {
									console.log(showReactionPaper);
									if (showReactionPaper) setShowReactionPaper(false);
									else setShowReactionPaper(true);
								}
							} else {
								if (likeColor == "#92929d") setShowReactionPaper(true);
								else removeLikePost();
							}
						}}
						onMouseEnter={() => {
							if (!isMobile) setShowReactionPaper(true);
						}}
						onMouseLeave={() => {
							if (!isMobile) setShowReactionPaper(false);
						}}
					>
						<Popper
							className={styles.likePopper}
							anchorEl={anchorRef.current}
							open={showReactionPaper && likeColor == "#92929d"}
							role={undefined}
							placement="top"
							transition
							disablePortal
						>
							{({ TransitionProps, placement }) => (
								<ClickAwayListener
									onClickAway={() =>
										!isMobile ? setShowReactionPaper(false) : null
									}
								>
									{/* <Grow
										{...TransitionProps}
										style={{
											transformOrigin: placement === "top" ? "top" : "top",
										}}
									> */}
									<Paper>
										<div
											style={{
												display: "flex",
												flexDirection: "row",
												margin: "10px",
											}}
										>
											<ThumbUpAltOutlinedIcon
												sx={{
													color: "#6F52ED",
													margin: "5px",
													transition: "all .5s",
													"&:hover": {
														transform: "scale(1.5)",
													},
												}}
												onClick={() => addLikePost(1)}
											/>
											<FavoriteBorderOutlinedIcon
												sx={{
													color: "#6F52ED",
													margin: "5px",
													transition: "all .5s",
													"&:hover": {
														transform: "scale(1.5)",
													},
												}}
												onClick={() => addLikePost(2)}
											/>
											<HandClapIcon
												style={{
													fill: "#6F52ED",
													width: "22px",
													height: "24px",
													margin: "5px",
													transition: "all .5s",
													"&:hover": {
														transform: "scale(1.5)",
													},
												}}
												className={styles.clapIcon}
												onClick={() => addLikePost(3)}
											/>
											<SentimentVeryDissatisfiedIcon
												sx={{
													color: "#6F52ED",
													margin: "5px",
													transition: "all .5s",
													"&:hover": {
														transform: "scale(1.5)",
													},
												}}
												onClick={() => addLikePost(4)}
											/>
											<MoodBadOutlinedIcon
												sx={{
													color: "#6F52ED",
													margin: "5px",
													transition: "all .5s",
													"&:hover": {
														transform: "scale(1.5)",
													},
												}}
												onClick={() => addLikePost(5)}
											/>
											<SentimentVerySatisfiedOutlinedIcon
												sx={{
													color: "#6F52ED",
													margin: "5px",
													transition: "all .5s",
													"&:hover": {
														transform: "scale(1.5)",
													},
												}}
												onClick={() => addLikePost(6)}
											/>
										</div>
									</Paper>
									{/* </Grow> */}
								</ClickAwayListener>
							)}
						</Popper>
						{likeShow > 0 ? (
							reactionId == 1 ? (
								<ThumbUpAltOutlinedIcon
									sx={{
										color: likeColor,
										margin: "5px",
										transition: "all .5s",
										"&:hover": {
											transform: "scale(1.5)",
										},
									}}
								/>
							) : reactionId == 2 ? (
								<FavoriteBorderOutlinedIcon
									sx={{
										color: likeColor,
										margin: "5px",
										transition: "all .5s",
										"&:hover": {
											transform: "scale(1.5)",
										},
									}}
								/>
							) : reactionId == 3 ? (
								<HandClapIcon
									style={{
										fill: likeColor,
										width: "22px",
										height: "24px",
										margin: "5px",
										transition: "all .5s",
									}}
									className={styles.clapIcon}
								/>
							) : reactionId == 4 ? (
								<SentimentVeryDissatisfiedIcon
									sx={{
										color: likeColor,
										margin: "5px",
										transition: "all .5s",
										"&:hover": {
											transform: "scale(1.5)",
										},
									}}
								/>
							) : reactionId == 5 ? (
								<MoodBadOutlinedIcon
									sx={{
										color: likeColor,
										margin: "5px",
										transition: "all .5s",
										"&:hover": {
											transform: "scale(1.5)",
										},
									}}
								/>
							) : reactionId == 6 ? (
								<SentimentVerySatisfiedOutlinedIcon
									sx={{
										color: likeColor,
										margin: "5px",
										transition: "all .5s",
										"&:hover": {
											transform: "scale(1.5)",
										},
									}}
								/>
							) : (
								<FavoriteBorderOutlinedIcon
									sx={{
										color: likeColor,
										margin: "5px",
										transition: "all .5s",
										"&:hover": {
											transform: "scale(1.5)",
										},
									}}
									alt=""
								/>
							)
						) : (
							<svg
								alt=""
								xmlns="http://www.w3.org/2000/svg"
								width="30.221"
								height="30.09"
								viewBox="0 0 30.221 30.09"
							>
								<g id="ic_like" transform="translate(0 0)">
									<g id="Likes">
										<ellipse
											id="Oval"
											cx="15.11"
											cy="15.045"
											rx="15.11"
											ry="15.045"
											transform="translate(0 0)"
											fill="none"
										/>
										<path
											id="Combined_Shape_Copy"
											data-name="Combined Shape Copy"
											d="M13.851,24.53a2.51,2.51,0,0,1-1.756-.71l-.05-.049L2.314,13.809a8.208,8.208,0,0,1,0-11.425,7.837,7.837,0,0,1,11.258,0l.279.285.279-.285a7.837,7.837,0,0,1,11.258,0,8.206,8.206,0,0,1,0,11.425l-9.732,9.962A2.5,2.5,0,0,1,13.851,24.53ZM7.943,2.507A5.311,5.311,0,0,0,4.119,4.132a5.707,5.707,0,0,0,0,7.928l9.732,9.963,9.732-9.963a5.707,5.707,0,0,0,0-7.928,5.312,5.312,0,0,0-7.647,0l-1.182,1.21a1.264,1.264,0,0,1-1.805,0l-1.182-1.21A5.311,5.311,0,0,0,7.943,2.507Z"
											transform="translate(1.259 2.507)"
											fill={likeColor}
										/>
									</g>
								</g>
							</svg>
						)}
						{reactionCount} {reactionCount > 1 ? "Likes" : "Like"}
					</Button>
					<Button
						onClick={(e) => {
							setAnchorShareEl(e.currentTarget);
						}}
					>
						{/* <img src={share} alt="" /> */}
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="30.221"
							height="30.09"
							viewBox="0 0 30.221 30.09"
						>
							<g id="ic_Share" transform="translate(0 0)">
								<g id="Share">
									<ellipse
										id="Oval"
										cx="15.11"
										cy="15.045"
										rx="15.11"
										ry="15.045"
										transform="translate(0 0)"
										fill="none"
									/>
									<g
										id="Group_24_Copy"
										data-name="Group 24 Copy"
										transform="translate(28.962 1.254)"
									>
										<path
											id="Combined_Shape"
											data-name="Combined Shape"
											d="M0,22.567a5.031,5.031,0,0,0-5.036-5.015,5.042,5.042,0,0,0-4.157,2.186l-8.691-4.37a4.976,4.976,0,0,0,.255-1.577,4.974,4.974,0,0,0-.293-1.686l8.741-4.243A5.042,5.042,0,0,0-5.036,10.03,5.031,5.031,0,0,0,0,5.015,5.031,5.031,0,0,0-5.036,0a5.032,5.032,0,0,0-5.037,5.015q0,.242.023.48l-9.307,4.517a5.027,5.027,0,0,0-3.307-1.236A5.032,5.032,0,0,0-27.7,13.791a5.032,5.032,0,0,0,5.037,5.015,5.029,5.029,0,0,0,3.4-1.321l9.209,4.63q-.02.223-.02.452a5.032,5.032,0,0,0,5.037,5.015A5.031,5.031,0,0,0,0,22.567Zm-2.518,0a2.515,2.515,0,0,1-2.518,2.507,2.516,2.516,0,0,1-2.519-2.507,2.516,2.516,0,0,1,2.519-2.507A2.516,2.516,0,0,1-2.518,22.567Zm-17.629-8.776A2.516,2.516,0,0,1-22.666,16.3a2.516,2.516,0,0,1-2.519-2.507,2.516,2.516,0,0,1,2.519-2.507A2.516,2.516,0,0,1-20.147,13.791ZM-2.518,5.015A2.516,2.516,0,0,1-5.036,7.522,2.516,2.516,0,0,1-7.556,5.015,2.516,2.516,0,0,1-5.036,2.507,2.516,2.516,0,0,1-2.518,5.015Z"
											fill="#92929d"
										/>
									</g>
								</g>
							</g>
						</svg>
						{post.shareCount} Shares
					</Button>
					<Button>
						{/* <img src={saved} alt="" /> */}
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="30.221"
							height="30.09"
							viewBox="0 0 30.221 30.09"
						>
							<g id="ic_Saved" transform="translate(0 0)">
								<g id="Saved">
									<ellipse
										id="Oval"
										cx="15.11"
										cy="15.045"
										rx="15.11"
										ry="15.045"
										transform="translate(0 0)"
										fill="none"
									/>
									<g
										id="Group_13_Copy"
										data-name="Group 13 Copy"
										transform="translate(4.296 0.507)"
									>
										<path
											id="Rectangle_92"
											data-name="Rectangle 92"
											d="M18.888,26.415a2.409,2.409,0,0,1-1.5-.524l-6.746-5.374L3.9,25.891A2.4,2.4,0,0,1,0,24.027V4.776A4.792,4.792,0,0,1,4.8,0H16.49a4.792,4.792,0,0,1,4.8,4.776V24.027A2.4,2.4,0,0,1,18.888,26.415Zm-8.245-8.286a2.416,2.416,0,0,1,1.5.523l6.746,5.375V4.776a2.4,2.4,0,0,0-2.4-2.389H4.8A2.4,2.4,0,0,0,2.4,4.776V24.027l6.746-5.375A2.413,2.413,0,0,1,10.643,18.129Z"
											transform="translate(0.801 0.806)"
											fill="#92929d"
										/>
									</g>
								</g>
							</g>
						</svg>
						{post.saveCount} Saved
					</Button>
				</div>
				{lastOpenCommentPostId == post.postId ? (
					<div className={styles.feedCommentBx}>
						<GridContainer>
							<GridItem xs={1} sm={1} md={1} style={{ paddingRight: "0px" }}>
								<div className={styles.feedCommentUserBx}>
									<img src={user.profilePic} alt="" />
								</div>
							</GridItem>
							<GridItem xs={11} sm={11} md={11}>
								<div className={styles.commentInputWrap}>
									<div className={classes.mentionsInput}>
										<ReactQuill
											ref={quillRef}
											placeholder="Write your comment"
											className={classes.quillCommentEditor}
											theme="bubble"
											multiline={true}
											// contenteditable={true}
											modules={{
												mention: mentionModuleConfig,
											}}
											onKeyDown={(e) => {
												if (e.key === "Enter") {
													checkNewHashTag(
														quillRef.current.editor.getText(),
														true
													);
													if (e.shiftKey) {
														e.preventDefault();
														postComment(quillRef.current);
														quillRef.current.editor.setText("");
													}
												}
											}}
											// onBlur={(e) => {
											// 	try {
											// 		console.log(e);
											// 		// quillRef.current.editor.off();
											// 		quillRef.current.editor.enable(false);
											// 		// quillRef.current.editor.blur();
											// 	} catch (e) {}
											// }}
											// onFocus={(e) => {
											// 	getHashTagList("");
											// 	getProfileTagList("");
											// }}
										/>
										{/* <MentionsInput
										placeholder="Write your comment"
										className={classes.inputfield}
										multiline={true}
										value={textValue}
										inputRef={commentInput}
										onKeyDown={(e) => {
											if (e.key === " " && hashTagKeyword.length)
												storeNewHashTag(hashTagKeyword);
											if (e.key === "Enter") {
												if (e.shiftKey && textValue.trim() != "") {
													e.preventDefault();
													postComment();
													setTextValue("");
													setOpenEmojiOption(false);
												}
											}
										}}
										onChange={(e) => setTextValue(e.target.value)}
									>
										<Mention
											trigger="#"
											markup="[#__display__](__id__)"
											appendSpaceOnAdd
											data={(keyword) => {
												setHashTagKeyword(keyword);
												return mappedHashTags.filter((tag) =>
													tag.display.includes(keyword)
												);
											}}
											displayTransform={(id, display) => `#${display}`}
											onAdd={(id) =>
												setAddedPostHashTagList([
													...addedPostHashTagList,
													socialHashTags.filter((tag) => tag.hashId === id)[0],
												])
											}
											renderSuggestion={(sugg) => <p>{sugg.display}</p>}
										/>

										<Mention
											trigger="@"
											markup="[@__display__](__id__)"
											appendSpaceOnAdd
											data={(keyword) => {
												setProfileTagKeyword(keyword);
												return mappedProfileTags.filter((tag) =>
													tag.display.includes(keyword)
												);
											}}
											displayTransform={(id, display) => `@ ${display}`}
											onAdd={(id) =>
												setAddedPostProfileTagList([
													...addedPostProfileTagList,
													socialProfileTags.filter(
														(tag) => tag.acctId === id
													)[0],
												])
											}
											renderSuggestion={(sugg) => (
												<>
													<img
														src={sugg.profilePic ? sugg.profilePic : avatar}
														alt=""
													/>
													<p>{sugg.display}</p>
												</>
											)}
										/>
									</MentionsInput> */}

										{/* <TextField
                fullWidth
                value={textValue}
                id="standard-textarea"
                placeholder="What's on your mind?"
                multiline
                InputProps={{
                  disableUnderline: true,
                  className: classes.input,
                }}
                onChangeCapture={(e) => {
                  setTextValue(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "#") {
                    console.log("#");
                  } else if (e.key === " ") {
                    console.log("space");
                  }
                }}
              /> */}
									</div>
									{/* <Input
									placeholder="Write your comment"
									inputProps={{ "aria-label": "description" }}
									multiline
									style={{
										backgroundColor: "#FAFAFB",
										border: "1px solid #F1F1F5",
										borderRadius: "15px",
										padding: "15px 156px 15px 15px",
									}}
									inputRef={commentInput}
									// value={comment}
									// onChange={(e) => setComment(e.target.value)}
									onKeyDown={(e) => {
										if (e.key === "Enter") {
											if (
												e.shiftKey &&
												commentInput.current.value.trim() != ""
											) {
												e.preventDefault();
												postComment(e.target.value);
												commentInput.current.value = "";
												setOpenEmojiOption(false);
											}
										}
									}}
								/> */}
									<div className={styles.commentActionBtns}>
										{/* <IconButton>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="17.607"
											height="20.192"
											viewBox="0 0 17.607 20.192"
										>
											<path
												id="Shape"
												d="M8.215,15.512a2.5,2.5,0,0,1-3.483-3.587L12.51,4.146a.5.5,0,1,1,.707.707L5.439,12.632a1.5,1.5,0,0,0,2.121,2.121l7.778-7.778a3.5,3.5,0,0,0-4.95-4.95L2.611,9.8a5.5,5.5,0,0,0,7.778,7.778l6.364-6.364a.5.5,0,1,1,.707.707L11.1,18.289a6.5,6.5,0,0,1-9.244-9.14l7.83-7.831a4.5,4.5,0,1,1,6.364,6.364l-7.83,7.83Z"
												transform="translate(0 0)"
												fill="#92929d"
											/>
										</svg>
									</IconButton> */}
										{/* <IconButton
											onClick={(e) => {
												setAnchorElEmoji(e.currentTarget);
												// openEmojiOption
												// 	? setOpenEmojiOption(false)
												// 	: setOpenEmojiOption(true);
											}}
											// ref={anchorRefEmoji}
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="21"
												height="21"
												viewBox="0 0 21 21"
											>
												<path
													id="Shape"
													d="M3.075,17.924A10.5,10.5,0,0,1,17.924,3.075,10.5,10.5,0,0,1,3.075,17.924ZM1,10.5A9.5,9.5,0,1,0,10.5,1,9.511,9.511,0,0,0,1,10.5Zm5.207,4.419a.5.5,0,1,1,.6-.8,6.327,6.327,0,0,0,3.727,1.127A6.084,6.084,0,0,0,14.2,14.124a.5.5,0,0,1,.7.09.5.5,0,0,1-.09.7,7,7,0,0,1-4.277,1.331A7.236,7.236,0,0,1,6.207,14.919ZM12.5,8.5a1,1,0,1,1,1,1A1,1,0,0,1,12.5,8.5Zm-6,0a1,1,0,1,1,1,1A1,1,0,0,1,6.5,8.5Z"
													fill="#92929d"
												/>
											</svg>
										</IconButton>
										<Popover
											id={anchorElEmoji ? "emoji-popover" : undefined}
											open={anchorElEmoji ? true : false}
											anchorEl={anchorElEmoji}
											onClose={() => {
												setAnchorElEmoji(null);
											}}
											anchorOrigin={{
												vertical: "bottom",
												horizontal: "center",
											}}
											transformOrigin={{
												vertical: "top",
												horizontal: "center",
											}}
										>
											<div>
												<Picker
													onEmojiClick={onEmojiClick}
													groupVisibility={{
														flags: false,
														recently_used: false,
													}}
												/>
											</div>
										</Popover> */}
										<IconButton onClick={handleImageClick}>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="21"
												height="20"
												viewBox="0 0 21 20"
											>
												<path
													id="Shape"
													d="M17.5,20H3.5A3.431,3.431,0,0,1,0,16.65V3.35A3.431,3.431,0,0,1,3.5,0h14A3.431,3.431,0,0,1,21,3.35v13.3A3.431,3.431,0,0,1,17.5,20ZM15.286,8.775l-4.714,4.253L15.961,19H17.5A2.431,2.431,0,0,0,20,16.65V13.071Zm-8.139,1.95h0L1.009,16.849A2.458,2.458,0,0,0,3.5,19H14.613L7.148,10.725ZM3.5,1A2.431,2.431,0,0,0,1,3.35v12.1l5.814-5.8a.5.5,0,0,1,.724.019L9.9,12.286l5.051-4.557a.5.5,0,0,1,.671,0L20,11.718V3.35A2.431,2.431,0,0,0,17.5,1ZM6.691,6.7A1.925,1.925,0,1,1,8.619,4.775,1.929,1.929,0,0,1,6.691,6.7Zm0-2.849a.925.925,0,1,0,.929.925A.928.928,0,0,0,6.691,3.85Z"
													transform="translate(0 0)"
													fill="#92929d"
												/>
											</svg>
										</IconButton>
										<div className={"fileinput text-center"}>
											<input
												type="file"
												onChange={onFileChange}
												accept="image/jpg, image/jpeg, image/gif, image/png, image/apng, image/bmp, image/gif"
												ref={fileInput}
											/>
										</div>
										<IconButton
											onClick={() => {
												postComment(quillRef.current);
												quillRef.current.editor.setText("");
												// setOpenEmojiOption(false);
												// if (textValue.trim() != "") {
												// 	postComment();
												// 	setTextValue("");
												// }
											}}
										>
											<SendIcon
												sx={{
													color: "#92929d",
													margin: "5px",
												}}
												className={styles.sendButton}
											/>
										</IconButton>
									</div>
									{commentImageSaved ? (
										<div className={classes.attachmentListItem}>
											<img
												src={cross}
												alt=""
												className={classes.removeAttachment}
												onClick={(e) => {
													e.stopPropagation();
													setCommentImageSaved(null);
												}}
											/>
											<div
												className={
													classes.attachmentListItemAttachmentContainer
												}
											>
												<img
													src={commentImageSaved}
													alt=""
													className={classes.attachmentListItemContent}
												/>
											</div>
											<div
												className={
													classes.attachmentListItemAttachmentContainerOverlay
												}
												onClick={() => {
													setCommentImageIsEdit(true);
													setCommentImage(commentImageSaved);
													setCommentImageModal(true);
												}}
											></div>
										</div>
									) : null}
								</div>
							</GridItem>
						</GridContainer>
					</div>
				) : null}

				{showComment ? (
					post.comments.commentList.length ? (
						<div className={styles.postCommentsMain}>
							{/* <div className={styles.recentCommentsButton}>
								<button>Most recent comments</button>
							</div> */}
							{skeletonShow ? <CommentSkeleton /> : null}
							{post.comments.commentList.length && !commentsExpanded
								? post.comments.commentList
										.slice(0, 3)
										.map((comment, index) => (
											<div key={comment.commentId}>
												<Comment
													comment={comment}
													postId={post.postId}
													profilePic={user.profilePic}
													commentCount={post.comments.commentList.length}
													reportReasonsList={reportReasonsList}
													sectionCount={1}
												/>
											</div>
										))
								: null}
							{post.comments.commentList.length && commentsExpanded
								? post.comments.commentList.map((comment, index) => (
										<div key={comment.commentId}>
											<Comment
												comment={comment}
												postId={post.postId}
												profilePic={user.profilePic}
												commentCount={post.comments.commentList.length}
												reportReasonsList={reportReasonsList}
												sectionCount={1}
											/>
										</div>
								  ))
								: null}

							{isEndComment || !commentsExpanded ? (
								<Typography component="div" className={styles.moreComments}>
									<button onClick={() => collapseComment()}>
										Collapse comments
									</button>
								</Typography>
							) : isLoadingComment ? (
								<CommentSkeleton />
							) : (
								<Typography component="div" className={styles.moreComments}>
									<button
										onClick={() =>
											getComments(post.comments.commentList.length)
										}
									>
										Load more comments
									</button>
								</Typography>
							)}
						</div>
					) : (
						<></>
					)
				) : null}

				<Popover
					id={anchorEl ? "simple-popover" : undefined}
					open={anchorEl ? true : false}
					anchorEl={anchorEl}
					onClose={() => {
						setAnchorEl(null);
					}}
					anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
					transformOrigin={{ vertical: "top", horizontal: "right" }}
				>
					<Typography
						component="div"
						className={styles.popoverListItem}
						onClick={savePost}
					>
						<Typography component="div" className={styles.popoverListItemIcon}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16.48"
								height="20"
								viewBox="0 0 16.48 20"
							>
								<path
									id="Path"
									d="M3.714,0h9.052A3.666,3.666,0,0,1,16.48,3.616V18.192A1.833,1.833,0,0,1,14.623,20a1.889,1.889,0,0,1-1.16-.4L8.24,15.534,3.017,19.6a1.891,1.891,0,0,1-2.61-.282A1.779,1.779,0,0,1,0,18.192V3.616A3.666,3.666,0,0,1,3.714,0Z"
									fill="#b6b7b8"
								/>
								<path
									id="Path-2"
									fill={isPostSaved ? "#b6b7b8" : "#fff"}
									data-name="Path"
									d="M1.857,0A1.833,1.833,0,0,0,0,1.808V16.383l5.223-4.069a1.9,1.9,0,0,1,2.32,0l5.223,4.069V1.808A1.833,1.833,0,0,0,10.909,0Z"
									transform="translate(1.857 1.808)"
								/>
							</svg>
						</Typography>
						<Typography component="div" className={styles.popoverListItemText}>
							<Typography component="p" className={styles.popoverListItemTitle}>
								{isPostSaved ? "Saved" : "Save Post"}
							</Typography>
						</Typography>
					</Typography>

					<Typography component="div" className={styles.popoverListItem}>
						<Typography component="div" className={styles.popoverListItemIcon}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16.48"
								height="20"
								viewBox="0 0 16.48 20"
							>
								<path
									id="Path"
									d="M3.714,0h9.052A3.666,3.666,0,0,1,16.48,3.616V18.192A1.833,1.833,0,0,1,14.623,20a1.889,1.889,0,0,1-1.16-.4L8.24,15.534,3.017,19.6a1.891,1.891,0,0,1-2.61-.282A1.779,1.779,0,0,1,0,18.192V3.616A3.666,3.666,0,0,1,3.714,0Z"
									fill="#b6b7b8"
								/>
								<path
									id="Path-2"
									fill="#fff"
									data-name="Path"
									d="M1.857,0A1.833,1.833,0,0,0,0,1.808V16.383l5.223-4.069a1.9,1.9,0,0,1,2.32,0l5.223,4.069V1.808A1.833,1.833,0,0,0,10.909,0Z"
									transform="translate(1.857 1.808)"
								/>
							</svg>
						</Typography>
						<Typography component="div" className={styles.popoverListItemText}>
							<Typography component="p" className={styles.popoverListItemTitle}>
								Copy Post Link
							</Typography>
						</Typography>
					</Typography>

					<Typography component="div" className={styles.popoverListItem}>
						<Typography component="div" className={styles.popoverListItemIcon}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="21"
								height="21"
								viewBox="0 0 21 21"
							>
								<path
									id="Shape"
									d="M13.437,0H5.563L0,5.563v7.874L5.563,19h7.874L19,13.437V5.563Z"
									transform="translate(1 1)"
									fill="#fff"
									stroke="#b6b7b8"
									strokeWidth="2"
								/>
								<g
									id="Group_1"
									data-name="Group 1"
									transform="translate(9.5 4.5)"
								>
									<circle
										id="Oval"
										cx="1"
										cy="1"
										r="1"
										transform="translate(0 10)"
										fill="#b6b7b8"
									/>
									<path id="Path" d="M0,0H2V8H0Z" fill="#b6b7b8" />
								</g>
							</svg>
						</Typography>
						<Typography component="div" className={styles.popoverListItemText}>
							<Typography
								component="p"
								className={styles.popoverListItemTitle}
								onClick={() => {
									setReportModal(!reportModal);
								}}
							>
								Report Post
							</Typography>
						</Typography>
					</Typography>

					{reportModal && (
						<ReportModal
							open={reportModal}
							onClose={() => {
								setReportModal(false);
								setAnchorEl(null);
							}}
							recordType={"Report"}
							reportReasonsList={reportReasonsList}
							reportTypeId
							reportPost={reportPost}
						/>
					)}

					<Typography
						component="div"
						className={styles.popoverListItem}
						onClick={() => hidePost(1)}
					>
						<Typography component="div" className={styles.popoverListItemIcon}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="22"
								height="22"
								viewBox="0 0 20 20"
							>
								<path
									id="Path"
									d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z"
									fill="#b5b5be"
								/>
								<path
									id="Path-2"
									data-name="Path"
									d="M8.182,0a8.182,8.182,0,1,0,8.182,8.182A8.182,8.182,0,0,0,8.182,0Z"
									transform="translate(1.818 1.818)"
									fill="#fff"
								/>
								<path
									id="Path-3"
									fill="#b5b5be"
									data-name="Path"
									d="M2.195,3.48.266,1.552A.909.909,0,0,1,1.552.266L3.48,2.195,5.409.266A.909.909,0,0,1,6.695,1.552L4.766,3.48,6.695,5.409A.909.909,0,0,1,5.409,6.695L3.48,4.766,1.552,6.695A.909.909,0,0,1,.266,5.409L2.195,3.48Z"
									transform="translate(6.364 6.364)"
								/>
							</svg>
						</Typography>
						<Typography component="div" className={styles.popoverListItemText}>
							<Typography component="p" className={styles.popoverListItemTitle}>
								Hide Post
							</Typography>
							<Typography
								component="p"
								className={styles.popoverListItemDescription}
							>
								Remove post from your feed
							</Typography>
						</Typography>
					</Typography>

					<Typography
						component="div"
						className={styles.popoverListItem}
						onClick={() => hidePost(2)}
					>
						<Typography component="div" className={styles.popoverListItemIcon}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="22"
								height="22"
								viewBox="0 0 22 22"
							>
								<g
									id="Path"
									transform="translate(0 0)"
									fill="#fff"
									strokeMiterlimit="10"
								>
									<path
										d="M7,0h8a7,7,0,0,1,7,7v8a7,7,0,0,1-7,7H7a7,7,0,0,1-7-7V7A7,7,0,0,1,7,0Z"
										stroke="none"
									/>
									<path
										d="M 7 2 C 4.242990493774414 2 2 4.242990493774414 2 7 L 2 15 C 2 17.75700950622559 4.242990493774414 20 7 20 L 15 20 C 17.75700950622559 20 20 17.75700950622559 20 15 L 20 7 C 20 4.242990493774414 17.75700950622559 2 15 2 L 7 2 M 7 0 L 15 0 C 18.86598968505859 0 22 3.134010314941406 22 7 L 22 15 C 22 18.86598968505859 18.86598968505859 22 15 22 L 7 22 C 3.134010314941406 22 0 18.86598968505859 0 15 L 0 7 C 0 3.134010314941406 3.134010314941406 0 7 0 Z"
										stroke="none"
										fill="#b5b5be"
									/>
								</g>
								<g
									id="Path-2"
									data-name="Path"
									transform="translate(7 7)"
									fill="none"
									strokeMiterlimit="10"
								>
									<path
										d="M2.414,3.828.293,1.707A1,1,0,0,1,1.707.293L3.828,2.414,5.95.293A1,1,0,0,1,7.364,1.707L5.243,3.828,7.364,5.95A1,1,0,0,1,5.95,7.364L3.828,5.243,1.707,7.364A1,1,0,0,1,.293,5.95L2.414,3.828Z"
										stroke="none"
									/>
									<path
										d="M 1.000001907348633 -4.76837158203125e-07 C 1.255924701690674 -4.76837158203125e-07 1.511847019195557 0.09763193130493164 1.707107067108154 0.2928972244262695 L 3.828427076339722 2.414216995239258 L 5.949747085571289 0.2928972244262695 C 6.145007133483887 0.09763193130493164 6.40092945098877 -4.76837158203125e-07 6.656852245330811 -4.76837158203125e-07 C 6.912774562835693 -4.76837158203125e-07 7.168696880340576 0.09763193130493164 7.363956928253174 0.2928972244262695 C 7.754487037658691 0.6834168434143066 7.754487037658691 1.316586971282959 7.363956928253174 1.707107067108154 L 5.242637157440186 3.828427076339722 L 7.363956928253174 5.949747085571289 C 7.754487037658691 6.340267181396484 7.754487037658691 6.973437309265137 7.363956928253174 7.363956928253174 C 7.168696880340576 7.559222221374512 6.912774562835693 7.656854629516602 6.656852245330811 7.656854629516602 C 6.40092945098877 7.656854629516602 6.145007133483887 7.559222221374512 5.949747085571289 7.363956928253174 L 3.828427076339722 5.242637157440186 L 1.707107067108154 7.363956928253174 C 1.511847019195557 7.559222221374512 1.255924701690674 7.656854629516602 1.000001907348633 7.656854629516602 C 0.74407958984375 7.656854629516602 0.4881572723388672 7.559222221374512 0.2928972244262695 7.363956928253174 C -0.09763288497924805 6.973437309265137 -0.09763288497924805 6.340267181396484 0.2928972244262695 5.949747085571289 L 2.414216995239258 3.828427076339722 L 0.2928972244262695 1.707107067108154 C -0.09763288497924805 1.316586971282959 -0.09763288497924805 0.6834168434143066 0.2928972244262695 0.2928972244262695 C 0.4881572723388672 0.09763193130493164 0.74407958984375 -4.76837158203125e-07 1.000001907348633 -4.76837158203125e-07 Z"
										stroke="none"
										fill="#b5b5be"
									/>
								</g>
							</svg>
						</Typography>
						<Typography component="div" className={styles.popoverListItemText}>
							<Typography component="p" className={styles.popoverListItemTitle}>
								Hide all from &ldquo;{post.firstName}&ldquo;
							</Typography>
							<Typography
								component="p"
								className={styles.popoverListItemDescription}
							>
								Stop seeing post from this person
							</Typography>
						</Typography>
					</Typography>

					<Typography component="div" className={styles.popoverListItem}>
						<Typography component="div" className={styles.popoverListItemIcon}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16.84"
								height="19.88"
								viewBox="0 0 16.84 19.88"
							>
								<g
									id="Path"
									transform="translate(3.622)"
									fill="#fff"
									strokeMiterlimit="10"
								>
									<path
										d="M4.655,9.41A4.68,4.68,0,0,0,9.31,4.705,4.68,4.68,0,0,0,4.655,0,4.68,4.68,0,0,0,0,4.705,4.68,4.68,0,0,0,4.655,9.41Z"
										stroke="none"
									/>
									<path
										d="M 4.65500020980835 7.410000324249268 C 6.118970394134521 7.410000324249268 7.310000419616699 6.196540355682373 7.310000419616699 4.705000400543213 C 7.310000419616699 3.213460445404053 6.118970394134521 2.000000476837158 4.65500020980835 2.000000476837158 C 3.191030263900757 2.000000476837158 2.000000238418579 3.213460445404053 2.000000238418579 4.705000400543213 C 2.000000238418579 6.196540355682373 3.191030263900757 7.410000324249268 4.65500020980835 7.410000324249268 M 4.65500020980835 9.410000801086426 C 2.084110260009766 9.410000801086426 2.098083484725066e-07 7.303500175476074 2.098083484725066e-07 4.705000400543213 C 2.098083484725066e-07 2.106500387191772 2.084110260009766 4.005432003850729e-07 4.65500020980835 4.005432003850729e-07 C 7.225890159606934 4.005432003850729e-07 9.310000419616699 2.106500387191772 9.310000419616699 4.705000400543213 C 9.310000419616699 7.303500175476074 7.225890159606934 9.410000801086426 4.65500020980835 9.410000801086426 Z"
										stroke="none"
										fill="#b5b5be"
									/>
								</g>
								<g
									id="Path-2"
									data-name="Path"
									transform="translate(0 9.47)"
									fill="#fff"
									strokeMiterlimit="10"
								>
									<path
										d="M8.42,0C3.829,0,.1,4.64,0,10.41H16.84C16.745,4.64,13.011,0,8.42,0Z"
										stroke="none"
									/>
									<path
										d="M 8.420000076293945 2 C 5.478047370910645 2 2.956839561462402 4.733343601226807 2.21738338470459 8.409999847412109 L 14.62261772155762 8.409999847412109 C 13.88316059112549 4.733343601226807 11.36195278167725 2 8.420000076293945 2 M 8.420000076293945 0 C 13.01138973236084 0 16.7446403503418 4.640219688415527 16.84000015258789 10.40999984741211 L 0 10.40999984741211 C 0.09535980224609375 4.640219688415527 3.828610420227051 0 8.420000076293945 0 Z"
										stroke="none"
										fill="#b5b5be"
									/>
								</g>
							</svg>
						</Typography>
						<Typography component="div" className={styles.popoverListItemText}>
							<Typography
								component="p"
								className={styles.popoverListItemTitle}
								onClick={() => {
									let requestData = {
										email: userStatus.email,
										acctId: userStatus.acctId,
										followId: postUser.acctId,
										accessToken: localStorage.getItem("token"),
									};
									postUser.followCount > 0
										? handleUnfollow(requestData)
										: handleFollow(requestData);
								}}
							>
								{postUser.followCount > 0 ? "Unfollow " : "Follow "}
								&ldquo;{post.firstName}&ldquo;
							</Typography>
							<Typography
								component="p"
								className={styles.popoverListItemDescription}
							>
								{postUser.followCount > 0 ? "Disconnect with" : "Connect with"}{" "}
								this person
							</Typography>
						</Typography>
					</Typography>
				</Popover>

				<Popover
					id={anchorShareEl ? "simple-popover" : undefined}
					open={anchorShareEl ? true : false}
					anchorEl={anchorShareEl}
					onClose={() => {
						setAnchorShareEl(null);
					}}
					anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
					anchorPosition={{ top: 10, left: 10 }}
					anchorReference="anchorEl"
				>
					<Typography component="div" className={styles.popoverListItem}>
						<Typography component="div" className={styles.popoverListItemText}>
							<Typography component="p" className={styles.popoverListItemTitle}>
								Share to feed
							</Typography>
							<Typography
								component="p"
								className={styles.popoverListItemDescription}
							>
								Share this post on your feed
							</Typography>
						</Typography>
					</Typography>

					<Typography component="div" className={styles.popoverListItem}>
						<Typography component="div" className={styles.popoverListItemText}>
							<Typography component="p" className={styles.popoverListItemTitle}>
								Write post
							</Typography>
							<Typography
								component="p"
								className={styles.popoverListItemDescription}
							>
								Compose a post with this content
							</Typography>
						</Typography>
					</Typography>

					<Typography component="div" className={styles.popoverListItem}>
						<Typography component="div" className={styles.popoverListItemText}>
							<Typography component="p" className={styles.popoverListItemTitle}>
								Share to Chat
							</Typography>
						</Typography>
					</Typography>

					<Typography component="div" className={styles.popoverListItem}>
						<Typography component="div" className={styles.popoverListItemText}>
							<Typography component="p" className={styles.popoverListItemTitle}>
								Share to Group
							</Typography>
						</Typography>
					</Typography>

					<Typography component="div" className={styles.popoverListItem}>
						<Typography component="div" className={styles.popoverListItemText}>
							<Typography component="p" className={styles.popoverListItemTitle}>
								Copy Post Link
							</Typography>
						</Typography>
					</Typography>
				</Popover>
				{commentImageModal ? (
					<CommentImageModal
						open={commentImageModal}
						onClose={() => {
							setCommentImageModal(false);
						}}
						pictureSource={commentImage}
						isEdit={commentImageIsEdit}
						fileName={(e) => {
							setFileName(e);
						}}
						saveCroppedPhoto={(e) => {
							setCommentImageModal(false);
							setCommentImageSaved(e);
						}}
					/>
				) : null}
			</div>
		</Fragment>
	) : null;
};

export default ProfileViewActivityPollCard;
