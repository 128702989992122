import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import PostSomethingCard from '../../../Component/ProfileView/MyActivity/PostSomethingCard';
import ProfileViewActivityCard from '../../../Component/ProfileView/MyActivity/ProfileViewActivityCard';
import ProfileViewActivityPostCard from '../../../Component/ProfileView/MyActivity/ProfileViewActivityPostCard';

import axios from 'axios';
import { instanceUrl, header, decryptData } from 'configurations/configurations';
import { getSocialPostByRecent } from 'configurations/redux/socialFeedSlice';
import LoadingModal from 'views/Component/LoadingModal';
import SocialFeed from 'views/Component/SocialFeed/SocialFeed';
import SocialFeedControl from '../../../Page/LandingPageFeed/SocialFeedControl';

// import { Typography, makeStyles } from "@material-ui/core";

// import { name } from "assets/jss/material-kit-pro-react/views//{name}.js"

const MyActivity = ({ match, createPostClick, setCreatePostClick }) => {
  // const styles = makeStyles({ name })();
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);

  const [myself, setMyself] = useState(false);
  const [socialPostList, setSocialPostList] = useState([]);

  const [createPost, setCreatePost] = React.useState(false);

  useEffect(() => {
    let isMounted = true;
    if (user.acctId === decryptData(match.params.acctId)) {
      setMyself(true);
    }

    getByRecent();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (createPostClick) setCreatePost(createPostClick);
  }, [createPostClick]);

  const getByRecent = () => {
    var previousPostListType = localStorage.getItem('postListType');
    const requestProfile = {
      acctId: user.acctId,
      accessToken: localStorage.getItem('token'),
      email: user.email,
      lastRecordSize: 0,
      requestRecordSize: 5,
      hashId: 0,
      userId: decryptData(match.params.acctId),
      groupId: 0,
      pageId: 0,
      sortBy: previousPostListType ? previousPostListType : 'recent',
      keyword: '',
      visibleIds: '1,2,4',
    };
    console.log(requestProfile);
    dispatch(getSocialPostByRecent(requestProfile));
  };

  const onClosePost = () => {
    setCreatePostClick(false);
    setCreatePost(false);
  };

  return (
    <Fragment>
      {myself ? <PostSomethingCard createPost={setCreatePost} /> : <div style={{ paddingBottom: '25px' }}></div>}
      <SocialFeedControl
        match={match}
        hideAddon={true}
        showFeed={true}
        createPost={createPost}
        onClosePost={onClosePost}
        groupId={0}
        scrollableTarget="ProfileSocialFeed"
        isSearch={false}
        isGroupMember={false}
        postStatus={1}
        isInGroup={false}
      />
      {/* {socialPostList && socialPostList.length && socialPostList.map((post, index) =>
        post.typeOption === "none" ?
          <ProfileViewActivityCard post={post} key={index} />
          : post.typeOption === "image" || post.typeOption === "video" ?
            <ProfileViewActivityPostCard post={post} key={index} />
            : post.typeOption === "pool" ?
              <ProfileViewActivityPostCard post={post} key={index} /> : ""
      )} */}
    </Fragment>
  );
};
export default MyActivity;
