import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
	instanceUrl,
	header,
	emptyCache,
	TimeFormatFirstDateOfMonth,
} from "configurations/configurations.js";

const testimonialSlice = createSlice({
	name: "testimonial",
	initialState: {
		isLoading: false,
		isUpdated: false,
		ownTestimonialList: [], //someone else wrote for me
		authorTestimonialList: [], //i write for someone else
		pendingTestimonialList: [], //anyone write for me that needs my request,
		isDeleted: false,
		isStatusChanged: false,
		isCreated: false,
		connectionList: null,
	},
	reducers: {
		TESTIMONIAL_LOADING: (state) => {
			state.isLoading = true;
		},
		OWN_TESTIMONIAL_GET_SUCCESS: (state, action) => {
			state.ownTestimonialList = action.payload.ownTestimonialList;
			state.isLoading = false;
			state.isUpdated = true;
			state.isDeleted = false;
			localStorage.setItem("token", action.payload.token);
			state.isStatusChanged = false;
			state.isCreated = false;
		},
		AUTHOR_TESTIMONIAL_GET_SUCCESS: (state, action) => {
			state.authorTestimonialList = action.payload.authorTestimonialList;
			state.isLoading = false;
			state.isUpdated = true;
			state.isDeleted = false;
			localStorage.setItem("token", action.payload.token);
			state.isStatusChanged = false;
			state.isCreated = false;
		},
		PENDING_TESTIMONIAL_GET_SUCCESS: (state, action) => {
			state.pendingTestimonialList = action.payload.pendingTestimonialList;
			state.isLoading = false;
			state.isUpdated = true;
			state.isDeleted = false;
			localStorage.setItem("token", action.payload.token);
			state.isStatusChanged = false;
			state.isCreated = false;
		},
		OWN_TESTIMONIAL_DELETE_SUCCESS: (state, action) => {
			state.ownTestimonialList = state.ownTestimonialList.filter((item) => {
				return item.testimonialId !== action.payload.testimonialId;
			});
			state.isDeleted = true;
			localStorage.setItem("token", action.payload.token);
			state.isLoading = false;
			state.isStatusChanged = false;
			state.isCreated = false;
		},
		AUTHOR_TESTIMONIAL_DELETE_SUCCESS: (state, action) => {
			state.authorTestimonialList = state.authorTestimonialList.filter(
				(item) => {
					return item.testimonialId !== action.payload.testimonialId;
				}
			);
			state.isDeleted = true;
			localStorage.setItem("token", action.payload.token);
			state.isLoading = false;
			state.isStatusChanged = false;
			state.isCreated = false;
		},
		PENDING_TESTIMONIAL_DELETE_SUCCESS: (state, action) => {
			state.pendingTestimonialList = state.pendingTestimonialList.filter(
				(item) => {
					return item.testimonialId !== action.payload.testimonialId;
				}
			);
			state.isDeleted = true;
			localStorage.setItem("token", action.payload.token);
			state.isLoading = false;
			state.isStatusChanged = false;
		},
		OWN_TESTIMONIAL_STATUS_UPDATE_SUCCESS: (state, action) => {
			state.ownTestimonialList = state.ownTestimonialList.filter((item) => {
				return item.testimonialId !== action.payload.testimonialId;
			});
			state.isDeleted = false;
			localStorage.setItem("token", action.payload.token);
			state.isLoading = false;
			state.isStatusChanged = true;
			state.isCreated = false;
		},
		PENDING_TESTIMONIAL_STATUS_UPDATE_SUCCESS: (state, action) => {
			state.pendingTestimonialList = state.pendingTestimonialList.filter(
				(item) => {
					return item.testimonialId !== action.payload.testimonialId;
				}
			);
			state.isDeleted = false;
			localStorage.setItem("token", action.payload.token);
			state.isLoading = false;
			state.isStatusChanged = true;
			state.isCreated = false;
		},
		AUTHOR_TESTIMONIAL_STATUS_UPDATE_SUCCESS: (state, action) => {
			state.authorTestimonialList = state.authorTestimonialList.filter(
				(item) => {
					return item.testimonialId !== action.payload.testimonialId;
				}
			);
			state.isDeleted = false;
			localStorage.setItem("token", action.payload.token);
			state.isLoading = false;
			state.isStatusChanged = true;
			state.isCreated = false;
		},
		TESTIMONIAL_FAILED: (state, action) => {
			state.isLoading = false;
			state.isUpdated = false;
		},
		TESTIMONIAL_CREATE_SUCCESS: (state, action) => {
			state.isLoading = false;
			state.isCreated = true;
			localStorage.setItem("token", action.payload.token);
		},
		CONNECTION_LIST_GET_SUCCESS: (state, action) => {
			state.connectionList = action.payload.connectionList;
			localStorage.setItem("token", action.payload.token);
			state.isLoading = false;
		},
		OWN_TESTIMONIAL_UPDATE_SUCCESS: (state, action) => {
			state.ownTestimonialList = state.ownTestimonialList.filter((item) => {
				if (item.testimonialId === action.payload.testimonialId) {
					return (
						(item.message = action.payload.message),
						(item.testimonial = action.payload.testimonial),
						(item.statusId = action.payload.statusId),
						(item.statusName = action.payload.statusName)
					);
				} else {
					return item;
				}
			});
			state.isStatusChanged = true;
			localStorage.setItem("token", action.payload.token);
			state.isDeleted = false;
			state.isLoading = false;
			state.isCreated = false;
		},
		PENDING_TESTIMONIAL_UPDATE_SUCCESS: (state, action) => {
			state.pendingTestimonialList = state.pendingTestimonialList.filter(
				(item) => {
					if (item.testimonialId === action.payload.testimonialId) {
						return (
							(item.message = action.payload.message),
							(item.testimonial = action.payload.testimonial),
							(item.statusId = action.payload.statusId),
							(item.statusName = action.payload.statusName)
						);
					} else {
						return item;
					}
				}
			);
			state.isStatusChanged = true;
			localStorage.setItem("token", action.payload.token);
			state.isDeleted = false;
			state.isLoading = false;
			state.isCreated = false;
		},
		AUTHOR_TESTIMONIAL_UPDATE_SUCCESS: (state, action) => {
			state.authorTestimonialList = state.authorTestimonialList.filter(
				(item) => {
					if (item.testimonialId === action.payload.testimonialId) {
						return (
							(item.message = action.payload.message),
							(item.testimonial = action.payload.testimonial),
							(item.statusId = action.payload.statusId),
							(item.statusName = action.payload.statusName)
						);
					} else {
						return item;
					}
				}
			);
			state.isStatusChanged = true;
			localStorage.setItem("token", action.payload.token);
			state.isDeleted = false;
			state.isLoading = false;
			state.isCreated = false;
		},
	},
});

const {
	TESTIMONIAL_FAILED,
	TESTIMONIAL_LOADING,
	AUTHOR_TESTIMONIAL_DELETE_SUCCESS,
	AUTHOR_TESTIMONIAL_GET_SUCCESS,
	AUTHOR_TESTIMONIAL_STATUS_UPDATE_SUCCESS,
	OWN_TESTIMONIAL_DELETE_SUCCESS,
	OWN_TESTIMONIAL_GET_SUCCESS,
	OWN_TESTIMONIAL_STATUS_UPDATE_SUCCESS,
	PENDING_TESTIMONIAL_DELETE_SUCCESS,
	PENDING_TESTIMONIAL_GET_SUCCESS,
	PENDING_TESTIMONIAL_STATUS_UPDATE_SUCCESS,
	TESTIMONIAL_CREATE_SUCCESS,
	CONNECTION_LIST_GET_SUCCESS,
	AUTHOR_TESTIMONIAL_UPDATE_SUCCESS,
	OWN_TESTIMONIAL_UPDATE_SUCCESS,
	PENDING_TESTIMONIAL_UPDATE_SUCCESS,
} = testimonialSlice.actions;

export const createTestimonial = ({
	email,
	accessToken,
	acctId,
	authorAcctId,
	ownerAcctId,
	relationship,
	tsPosition,
	message,
	testimonial,
	statusId,
}) => async (dispatch) => {
	const body = {
		email,
		accessToken,
		acctId,
		authorAcctId,
		ownerAcctId,
		relationship,
		tsPosition,
		message,
		testimonial,
		statusId,
	};
	dispatch(TESTIMONIAL_LOADING());
	await axios({
		method: "POST",
		headers: header,
		url: `${instanceUrl}/api/um/create-user-testimonial`,
		data: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				const response = {
					token: res.data.token,
				};
				dispatch(TESTIMONIAL_CREATE_SUCCESS(response));
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			dispatch(TESTIMONIAL_FAILED());

			console.log(err);
		});
};
export const getConnectionList = ({
	acctId,
	accessToken,
	email,
	lastRecordSize,
	requestRecordSize,
	statusId,
	keyword,
	sortBy,
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		email,
		lastRecordSize,
		requestRecordSize,
		statusId,
		keyword,
		sortBy,
	};

	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/um/get-connection-profile-by-acct-id`,
		params: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				var profileValues = [];
				for (var i = 0; i < res.data.payload.connectionList.length; i++) {
					profileValues.push({
						value: res.data.payload.connectionList[i].receiverAcctId,
						name:
							res.data.payload.connectionList[i].firstName +
							" " +
							res.data.payload.connectionList[i].lastName,
						profilePic: res.data.payload.connectionList[i].profilePic,
					});
				}
				const response = {
					connectionList: profileValues,
					token: res.data.token,
				};
				dispatch(CONNECTION_LIST_GET_SUCCESS(response));
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};
export const getOwnerTestimonial = ({
	acctId,
	accessToken,
	email,
	lastRecordSize,
	requestRecordSize,
	cancelToken,
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		email,
		lastRecordSize,
		requestRecordSize,
	};
	dispatch(TESTIMONIAL_LOADING());

	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/um/get-user-testimonial-by-owner-acct-id`,
		params: body,
		cancelToken: cancelToken,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				const response = {
					ownTestimonialList: res.data.payload.testimonialList,
					token: res.data.token,
				};
				dispatch(OWN_TESTIMONIAL_GET_SUCCESS(response));
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};
export const getAuthorTestimonial = ({
	acctId,
	accessToken,
	email,
	lastRecordSize,
	requestRecordSize,
	cancelToken,
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		email,
		lastRecordSize,
		requestRecordSize,
	};
	dispatch(TESTIMONIAL_LOADING());

	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/um/get-user-testimonial-by-author-acct-id`,
		params: body,
		cancelToken: cancelToken,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				const response = {
					authorTestimonialList: res.data.payload.testimonialList,
					token: res.data.token,
				};
				dispatch(AUTHOR_TESTIMONIAL_GET_SUCCESS(response));
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};
export const getPendingTestimonial = ({
	acctId,
	accessToken,
	email,
	lastRecordSize,
	requestRecordSize,
	cancelToken,
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		email,
		lastRecordSize,
		requestRecordSize,
	};
	dispatch(TESTIMONIAL_LOADING());

	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/um/get-user-testimonial-pending-by-acct-id`,
		params: body,
		cancelToken: cancelToken,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				const response = {
					pendingTestimonialList: res.data.payload.testimonialList,
					token: res.data.token,
				};
				dispatch(PENDING_TESTIMONIAL_GET_SUCCESS(response));
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};
export const deleteTestimonial = ({
	acctId,
	accessToken,
	email,
	testimonialId,
	cancelToken,
	tsStatus,
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		email,
		testimonialId,
	};
	dispatch(TESTIMONIAL_LOADING());
	console.log(body);
	await axios({
		method: "PUT",
		headers: header,
		url: `${instanceUrl}/api/um/delete-user-testimonial`,
		data: body,
		cancelToken: cancelToken,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				const response = {
					token: res.data.token,
					testimonialId,
				};
				if (tsStatus === 1) {
					dispatch(OWN_TESTIMONIAL_DELETE_SUCCESS(response));
				} else if (tsStatus === 2) {
					dispatch(PENDING_TESTIMONIAL_DELETE_SUCCESS(response));
				} else {
					dispatch(AUTHOR_TESTIMONIAL_DELETE_SUCCESS(response));
				}
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};
export const updateTestimonial = ({
	acctId,
	accessToken,
	email,
	testimonialId,
	cancelToken,
	tsStatus,
	statusId,
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		email,
		testimonialId,
		statusId,
	};
	dispatch(TESTIMONIAL_LOADING());
	console.log(body);
	await axios({
		method: "PUT",
		headers: header,
		url: `${instanceUrl}/api/um/update-user-testimonial-status`,
		data: body,
		cancelToken: cancelToken,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				const response = {
					token: res.data.token,
					testimonialId,
				};
				if (tsStatus === 1) {
					dispatch(OWN_TESTIMONIAL_STATUS_UPDATE_SUCCESS(response));
				} else if (tsStatus === 2) {
					dispatch(PENDING_TESTIMONIAL_STATUS_UPDATE_SUCCESS(response));
				} else {
					dispatch(AUTHOR_TESTIMONIAL_STATUS_UPDATE_SUCCESS(response));
				}
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};
export const updateTestimonialContent = ({
	acctId,
	accessToken,
	email,
	testimonialId,
	cancelToken,
	tsStatus,
	statusId,
	authorAcctId,
	ownerAcctId,
	relationship,
	tsPosition,
	message,
	testimonial,
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		email,
		testimonialId,
		statusId,
		authorAcctId,
		ownerAcctId,
		relationship,
		tsPosition,
		message,
		testimonial,
	};
	dispatch(TESTIMONIAL_LOADING());
	console.log(body);
	await axios({
		method: "PUT",
		headers: header,
		url: `${instanceUrl}/api/um/update-user-testimonial`,
		data: body,
		cancelToken: cancelToken,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				/* const response = {
					token: res.data.token,
					testimonialId,
				};
				if (tsStatus === 1) {
					dispatch(OWN_TESTIMONIAL_STATUS_UPDATE_SUCCESS(response));
				} else if (tsStatus === 2) {
					dispatch(PENDING_TESTIMONIAL_STATUS_UPDATE_SUCCESS(response));
				} else {
					dispatch(AUTHOR_TESTIMONIAL_STATUS_UPDATE_SUCCESS(response));
				} */
				const response = {
					token: res.data.token,
					testimonialId,
					statusId,
					statusName: res.data.payload.statusName,
					message,
					testimonial,
				};
				if (tsStatus === 1) {
					dispatch(OWN_TESTIMONIAL_UPDATE_SUCCESS(response));
				} else if (tsStatus === 2) {
					dispatch(PENDING_TESTIMONIAL_UPDATE_SUCCESS(response));
				} else {
					dispatch(AUTHOR_TESTIMONIAL_UPDATE_SUCCESS(response));
				}
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export default testimonialSlice.reducer;
