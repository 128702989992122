import { createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import {
	instanceUrl,
	header,
	emptyCache,
} from "configurations/configurations.js";

const businessSlice = createSlice({
	name: "business",
	initialState: {
		searchCount: 0,
		searchResult: [],
		lastRecordSize: 0,
		requestRecordSize: 0,
		isLoading: true,
		isEnd: false,
		isSearching: false,
		isUpdated: true,
		inboxId: 0,
		isCreated: false,
		bizId: 0,
		bizPage: null,
	},
	reducers: {
		BIZ_LOADING: (state, action) => {
			state.isLoading = true;
		},
		BIZ_SEARCH_SUCCESS: (state, action) => {
			console.log(action);
			localStorage.setItem("token", action.payload.token);
			state.searchResult = [...action.payload.searchResult];
			// state.lastRecordSize =
			// 	state.lastRecordSize + action.payload.requestRecordSize;
			state.lastRecordSize = state.searchResult.length;
			state.requestRecordSize = action.payload.requestRecordSize;
			state.isLoading = false;
		},
		DEFAULT_BIZ_SEARCH: (state) => {
			state.searchResult = [];
			state.lastRecordSize = 0;
			state.requestRecordSize = 0;
			state.isEnd = false;
			state.isSearching = true;
		},
		BIZ_DELETE_SUCCESS: (state, action) => {
			console.log(action);
			localStorage.setItem("token", action.payload.token);
			state.searchResult = [...action.payload.bizPageList];
			if (state.bizId == action.payload.bizId) {
				state.bizId = 0;
			}
		},
		BIZ_SEARCH_FAIL: (state) => {
			state.isUpdated = false;
		},
		BIZ_SELECT_SUCCESS: (state, action) => {
			console.log(action.payload);
			state.bizId = action.payload.bizId;
			state.bizPage = action.payload.bizPage;
		},
		BIZ_SELECT_RESET: (state) => {
			state.bizId = 0;
			state.bizPage = null;
		},
	},
});

const {
	BIZ_LOADING,
	BIZ_SEARCH_SUCCESS,
	BIZ_SEARCH_END,
	DEFAULT_BIZ_SEARCH,
	BIZ_SEARCH_FAIL,
	BIZ_SELECT_SUCCESS,
	BIZ_SELECT_RESET,
	BIZ_DELETE_SUCCESS,
} = businessSlice.actions;

export const bizPageInfo = ({ acctId, accessToken, email }) => async (
	dispatch
) => {
	const body = {
		acctId,
		accessToken,
		email,
	};
	dispatch(DEFAULT_BIZ_SEARCH());
	dispatch(BIZ_LOADING());

	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/bp/get-business-page-by-acct-id`,
		params: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				const response = {
					searchResult: res.data.payload.businessPageList,
					token: res.data.token,
				};
				dispatch(BIZ_SEARCH_SUCCESS(response));
			} else {
				dispatch(BIZ_SEARCH_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const deleteBusinessPage = ({
	acctId,
	accessToken,
	email,
	bizId,
	bizPageList,
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		email,
		bizId,
	};
	await axios({
		method: "PUT",
		headers: header,
		url: `${instanceUrl}/api/bp/delete-business-page`,
		data: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				bizPageList = bizPageList.filter((item) => item.bizId != bizId);
				console.log(bizPageList);
				const response = {
					bizPageList,
					bizId,
					token: res.data.token,
				};
				dispatch(BIZ_DELETE_SUCCESS(response));
			} else {
				dispatch(BIZ_SEARCH_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const validateBizPage = ({
	acctId,
	accessToken,
	email,
	bizId,
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		email,
		bizId,
	};
	console.log(body);
	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/bp/business-page-validation`,
		params: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				const response = {
					bizPage: res.data.payload,
					bizId: bizId,
					token: res.data.token,
				};
				dispatch(BIZ_SELECT_SUCCESS(response));
			} else {
				dispatch(BIZ_SEARCH_FAIL());
				dispatch(BIZ_SELECT_RESET());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const resetSelectBizPage = () => async (dispatch) => {
	dispatch(BIZ_SELECT_RESET());
};

export default businessSlice.reducer;
