import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { TimeFormat } from "configurations/configurations.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Tooltip from "@material-ui/core/Tooltip";
import Divider from "@material-ui/core/Divider";
import Button from "components/CustomButtons/Button.js";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CardBody from "components/Card/CardBody.js";

import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js";

const useStyles = makeStyles(profilePageStyle);

export default function CoverPictureListCard({
	item,
	isSelected,
	isDefault,
	setIsSelected,
	setSelectedCover,
}) {
	const classes = useStyles();
	const handleClick = (pic, name) => {
		// console.log(pic);
		setIsSelected(name);
		setSelectedCover(pic);
	};
	const [height, setHeight] = React.useState("0");
	React.useEffect(() => {
		if (document.readyState === "complete") templateHeight();
		window.addEventListener("resize", templateHeight);
		return function cleanup() {
			window.removeEventListener("resize", templateHeight);
		};
	});

	const templateHeight = () => {
		var coverCard = document.getElementById("coverCard");
		var windowHeight = coverCard.clientWidth / 5;
		if (windowHeight < 80) windowHeight = 80;
		setHeight(windowHeight + "px");
	};

	const NoCoverPicList = () => {
		return (
			<Card className={classes.cardFrame}>
				<CardBody>
					<GridContainer justifyContent="center">
						<GridItem xs={12} sm={12} md={12} className={classes.itemCenter}>
							<h4 className={classes.title}>No Result Found</h4>
						</GridItem>
					</GridContainer>
				</CardBody>
			</Card>
		);
	};

	return (
		<div id="coverCard">
			<Card
				className={
					isSelected === item.fileName
						? classes.cardMargin + " " + classes.cardSelected
						: classes.cardMargin
				}
				key={item.fileName}
				id={item.fileName}
			>
				<CardActionArea
					onClick={() => {
						handleClick(item.image, item.fileName);
					}}
				>
					{isDefault === item.fileName && (
						<div className={classes.cornerRibbon}>Current Banner</div>
					)}
					<CardMedia
						className={classes.mediaHeight}
						image={item.image}
						title={item.alt_description}
						style={{ height: height }}
					/>
				</CardActionArea>
			</Card>
		</div>
	);
}
