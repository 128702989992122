const actionCardStyle = (theme) => ({
	container: {
		flexDirection: "row",
		display: "flex",
		// width: "25%",
		width: "33.33%",
	},
	groupContainer: {
		flexDirection: "row",
		display: "flex",
		// width: "25%",
		width: "50%",
	},
	contentContainer: {
		flexDirection: "column",
		display: "flex",
		alignItems: "center",
		justifyContent: "end",
		marginTop: "20px",
		marginBottom: "20px",
		flex: 1,
	},
	txt1: {
		color: "#171725",
		fontSize: "30px",
		fontWeight: "500",
	},
	txt2: {
		color: "#0A1F44",
		fontSize: "14px",
		fontWeight: "500",
	},
	actionContainer: {
		marginTop: "13px",
		borderRadius: "20px",
		border: "3px solid #6F52ED",
		backgroundColor: "white",
		height: "40px",
		width: "150px",
		color: "#6F52ED",
		fontSize: "14px",
		fontWeight: 400,
		textTransform: "capitalize",
	},
	divider: {
		width: "1px",
		backgroundColor: "#979797",
		marginTop: "21px",
		marginBottom: "16px",
	},
});

export default actionCardStyle;
