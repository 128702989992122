import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Formik } from "formik";

import loginPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/LoginPage/loginPageStyle.js";

import { loginSchema } from "configurations/configurations.js";

import { useDispatch, useSelector } from "react-redux";

// import CustomInput from "components/CustomInput/CustomInput.js";
// import Danger from "components/Typography/Danger.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import FacebookLoginBtn from "views/Page/LoginPage/Facebook.js";
import GoogleLoginBtn from "views/Page/LoginPage/Google.js";
import InputAdornment from "@material-ui/core/InputAdornment";
// import Email from "@material-ui/icons/Email";
// import Icon from "@material-ui/core/Icon";
import {
	loginAction,
	// resendVerificationEmail,
} from "configurations/redux/authSlice.js";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { TextField } from "@material-ui/core";
const publicIp = require("public-ip");

const useStyles = makeStyles(loginPageStyle);

const CandidateLoginForm = () => {
	const classes = useStyles();
	const dispatch = useDispatch();

	// const isError = useSelector((state) => state.auth.isError);
	// const isVerified = useSelector((state) => state.auth.isVerified);
	// const auth = useSelector((state) => state.auth);
	const userStatus = useSelector((state) => state.auth.user);
	// const isResend = useSelector((state) => state.auth.isResend);
	// const isLoading = useSelector((state) => state.auth.isLoading);
	// console.log(isError, isVerified, userStatus);
	// const [emailSend, setEmailSend] = React.useState(false);
	const [showPassword, setShowPassword] = React.useState(false);
	const [click, setClick] = React.useState(false);

	React.useEffect(() => {
		setClick(false);
		return () => {
			setClick();
		};
	}, [userStatus]);

	// const resendEmail = () => {
	// 	const requestProfile = {
	// 		acctTypeId: userStatus.acctTypeId,
	// 		email: userStatus.email,
	// 	};
	// 	console.log(requestProfile);
	// 	dispatch(resendVerificationEmail(requestProfile));
	// 	setEmailSend(true);
	// };
	const handleClickShowPassword = () => {
		if (showPassword) setShowPassword(false);
		else setShowPassword(true);
	};
	return (
		<React.Fragment>
			<Formik
				initialValues={{
					email: "",
					password: "",
				}}
				validationSchema={loginSchema}
				onSubmit={(data) => {
					setClick(true);
					const getLogin = async () => {
						var ipAddr = localStorage.getItem("ipAddr");
						if (ipAddr == null) ipAddr = await publicIp.v4();
						var lastTime = Date.now();
						var timer = setInterval(() => {
							if (ipAddr) {
								console.log(ipAddr);
								clearInterval(timer);
								const requestBody = {
									email: data.email.trim(),
									password: data.password,
									acctTypeId: 1,
									ipAddr: ipAddr,
								};
								dispatch(loginAction(requestBody));
							}
							//  if (Date.now() - lastTime > 3000)
							else {
								clearInterval(timer);
								console.log(Date.now() - lastTime);
								const requestBody = {
									email: data.email.trim(),
									password: data.password,
									acctTypeId: 1,
									ipAddr: null,
								};
								dispatch(loginAction(requestBody));
							}
						}, 500);
					};
					getLogin();
				}}
			>
				{({
					handleChange,
					values,
					errors,
					touched,
					handleBlur,
					handleSubmit,
				}) => (
					<form onSubmit={handleSubmit}>
						<GridContainer>
							<GridItem xs={12} sm={12} md={12}>
								{/* {isError &&
									!isVerified &&
									(userStatus === 1 || userStatus === 3) && (
										<SnackbarContent
											message={
												<span>
													<b>{auth.message}</b>
												</span>
											}
											close
											color="opptyWarning"
											icon="info_outline"
											className={classes.banner}
										/>
									)}
								{isError && isVerified && userStatus != null
									? userStatus.acctTypeId === 1 && (
											<SnackbarContent
												message={
													<div>
														<b>{auth.message}</b>
														<GridContainer justifyContent="center">
															<GridItem xs={12} sm={12} md={12}>
																<Button
																	fullWidth
																	color="primary"
																	onClick={() => {
																		resendEmail();
																	}}
																>
																	Resend
																</Button>
															</GridItem>
														</GridContainer>
													</div>
												}
												close
												color="opptyInfo"
												icon="info_outline"
												className={classes.warningBanner}
											/>
									  )
									: null}
								{isResend && (
									<SnackbarContent
										message={
											<span>
												<b>
													Verification Email has successfully sent. Please check
													your inbox
												</b>
											</span>
										}
										close
										color="opptyInfo"
										icon="info_outline"
									/>
								)} */}
								<GridItem
									className={classes.gridHeight + " " + classes.marginTop}
								>
									<h5 className={classes.textFieldLabel}>Email</h5>
									<TextField
										variant="outlined"
										id="email"
										label={
											touched.email && Boolean(errors.email) ? errors.email : ""
										}
										name="email"
										// helperText={touched.email ? errors.email : ""}
										error={touched.email && Boolean(errors.email)}
										onChange={handleChange}
										onBlur={handleBlur}
										onKeyDown={(event) => {
											if (event.key === "Enter") {
												handleSubmit();
											}
										}}
										// placeholder="Required"
										fullWidth
										InputProps={{
											type: "text",
											// startAdornment: (
											// 	<InputAdornment position="start">
											// 		<Email className={classes.inputIconsColor} />
											// 	</InputAdornment>
											// ),
										}}
									/>
								</GridItem>
								<GridItem
									className={classes.gridHeight + " " + classes.marginTop}
								>
									<h5 className={classes.textFieldLabel}>Password</h5>
									<TextField
										variant="outlined"
										id="password"
										label={
											touched.password && Boolean(errors.password)
												? errors.password
												: ""
										}
										name="password"
										// helperText={touched.password ? errors.password : ""}
										error={touched.password && Boolean(errors.password)}
										onChange={handleChange}
										onBlur={handleBlur}
										onKeyDown={(event) => {
											if (event.key === "Enter") {
												handleSubmit();
											}
										}}
										// placeholder="Required"
										fullWidth
										InputProps={{
											type: showPassword ? "text" : "password",
											// startAdornment: (
											// 	<InputAdornment position="start">
											// 		<Icon className={classes.inputIconsColor}>
											// 			lock_outline
											// 		</Icon>
											// 	</InputAdornment>
											// ),
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={handleClickShowPassword}
														edge="end"
													>
														{showPassword ? <Visibility /> : <VisibilityOff />}
													</IconButton>
												</InputAdornment>
											),
											autoComplete: "off",
										}}
									/>
								</GridItem>
								<GridItem className={classes.marginTop}>
									<Button
										type="submit"
										disabled={click}
										onClick={() => {
											handleSubmit();
										}}
										fullWidth
										color="oppty"
										className={classes.buttonBorder}
									>
										Login
									</Button>
								</GridItem>
							</GridItem>
						</GridContainer>
					</form>
				)}
			</Formik>
			<GridContainer className={classes.loginSocialGrid}>
				<GridItem>
					<h5 className={classes.loginSocial}>OR</h5>
				</GridItem>
			</GridContainer>
			{/* <GridContainer justifyContent="center">
				<GridItem
					xs={12}
					sm={10}
					md={10}
					lg={8}
					className={classes.loginSocialMargin}
				>
					<FacebookLoginBtn />
				</GridItem>
			</GridContainer> */}
			<GridContainer justifyContent="center">
				<GridItem
					xs={12}
					sm={10}
					md={10}
					lg={8}
					className={classes.loginSocialMargin}
				>
					<GoogleLoginBtn />
				</GridItem>
			</GridContainer>
		</React.Fragment>
	);
};

export default CandidateLoginForm;
