import React from "react";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js";
import IconLibrary from "views/Component/IconLibrary.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(profilePageStyle);

export default function ProfileStrengthTip({ extension }) {
	const classes = useStyles();
	// console.log(extension);
	return (
		<React.Fragment>
			{extension && Array.isArray(extension) ? (
				extension.length > 0 ? (
					<SnackbarContent
						message={
							<div className={classes.tipTextSection}>
								<IconLibrary.Lightbulb />
								<div className={classes.tipTextContainer}>
									<p className={classes.tipHeadline}>{null}</p>
									{/* <p className={classes.tipText}>{Text}</p> */}
									{extension.map((item, index) => {
										return (
											<p key={index} className={classes.tipText}>
												{item.Text}
											</p>
										);
									})}
								</div>
							</div>
						}
						close
						color="opptyTip"
						size="userTip"
					/>
				) : (
					extension.Text && (
						<SnackbarContent
							message={
								<div className={classes.tipTextSection}>
									<IconLibrary.Lightbulb />
									<div className={classes.tipTextContainer}>
										<p className={classes.tipHeadline}>{null}</p>
										{<p className={classes.tipText}>{extension.Text}</p>}
									</div>
								</div>
							}
							close
							color="opptyTip"
							size="userTip"
						/>
					)
				)
			) : null}
		</React.Fragment>
	);
}
