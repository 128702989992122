import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Snack from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
// import Close from "@material-ui/icons/Close";
// core components
import IconLibrary from "views/Component/IconLibrary.js";

import styles from "assets/jss/material-kit-pro-react/components/snackbarContentStyle.js";

const useStyles = makeStyles(styles);

export default function SnackbarContent(props) {
	const {
		message,
		color,
		close,
		size,
		icon,
		// open,
		handleClose,
		className,
	} = props;
	const classes = useStyles();
	var changeSize = null;
	var changeIconMargin = null;
	var changePadding = null;
	// var userTip = null;
	if (size === "small") {
		changeSize = classes.size;
		changePadding = classes.padding;
		changeIconMargin = classes.iconMargin;
	} else if (size === "message") {
		changeSize = classes.size + " " + classes.messageWidth;
		changePadding = classes.padding;
		changeIconMargin = classes.iconMargin;
	} else if (size === "userTip") {
		changePadding = classes.userTip;
	}
	var action = [];
	const closeAlert = () => {
		setAlert(null);
	};
	if (close !== undefined) {
		action = [
			<IconButton
				className={classes.iconButton}
				key="close"
				aria-label="Close"
				color="inherit"
				onClick={closeAlert}
			>
				{/* <Close className={classes.close} /> */}
				<IconLibrary.Close />
			</IconButton>,
		];
	}
	let snackIcon = null;
	switch (typeof icon) {
		case "object":
			snackIcon = (
				<props.icon className={classes.icon + " " + changeIconMargin} />
			);
			break;
		case "string":
			snackIcon = (
				<Icon className={classes.icon + " " + changeIconMargin}>
					{props.icon}
				</Icon>
			);
			break;
		default:
			snackIcon = null;
			break;
	}
	const [alert, setAlert] = React.useState(
		<Snack
			message={
				<div>
					{snackIcon}
					{message}
					{close !== undefined ? action : null}
				</div>
			}
			/* action={close ? action : null} */
			onClose={handleClose}
			classes={{
				root:
					classes.root +
					" " +
					classes[color] +
					" " +
					changeSize +
					" " +
					className,
				message:
					classes.message + " " + classes.container + " " + changePadding,
			}}
		/>
	);
	return alert;
}

SnackbarContent.propTypes = {
	message: PropTypes.node.isRequired,
	color: PropTypes.oneOf([
		"info",
		"success",
		"warning",
		"danger",
		"primary",
		"opptyWarning",
		"opptyInfo",
		"opptyWhite",
		"opptyTip",
	]),
	close: PropTypes.bool,
	icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	size: PropTypes.node,
};
