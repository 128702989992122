var regular = 400;
var medium = 500;
var semiBold = 600;
var bold = 700;

const profileViewActivityPostCardStyles = (theme) => ({
	modalPositionCenter: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
	},
	clapIcon: {
		"&:hover": {
			transform: "scale(1.5)",
		},
	},
	main: {
		boxShadow: "none",
		borderRadius: "15px!important",
		padding: "20px 20px 10px",
		margin: "0 0 15px",

		"& h4": {
			fontSize: "18px",
			fontWeight: "600",
			color: "#4A4A4A",
			margin: "0",
		},

		"& .MuiCardContent-root": {
			paddingBottom: "0",
		},
	},

	socialFeedCardContent: {
		padding: 0,
	},

	postHeader: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		margin: "0 0 20px",

		"& > button": {
			backgroundColor: "transparent",
			border: "none",
			cursor: "pointer",
		},
	},

	postAuthorDetails: {
		display: "flex",
		alignItems: "center",
	},

	postAuthorProfilePic: {
		overflow: "hidden",
		borderRadius: "50%",
		marginRight: "15px",
		width: "53px",
		maxHeight: "53px",

		"& > img": {
			width: "100%",
		},
	},

	postAuthorName: {
		color: "#171725",
		fontSize: "14px",
		fontWeight: 700,

		"& > span": {
			marginLeft: "20px",
			border: "solid 1px #20F0A1",
			borderRadius: "10px",
			padding: "7px 20px 6px",
			color: "#20F0A1",
			fontSize: "15px",
			fontWeight: 500,
			textTransform: "capitalize",
		},
	},

	postCreationDate: {
		fontSize: "12px",
		color: "#92929D",
		fontWeight: 500,
	},

	postText: {
		overflowWrap: "anywhere",
		color: "#44444F",
		fontSize: "14px",
		margin: "0 0 30px",

		"& > p": {
			whiteSpace: "break-spaces",
			wordWrap: "break-word",
		},
	},

	sharedPost: {
		border: "1px solid #F2F2F2",
		borderRadius: "15px",
	},

	postGraphicalContent: {
		// position: 'relative',
		overflow: "hidden",
		borderRadius: "15px",
		width: "100%",
	},
	postContentContainer: {
		position: "relative",
		display: "flex",
		justifyContent: "center",
		// overflow: "hidden",
		borderRadius: "15px",
		// backgroundColor: "#000", //"#e3e3e3",
		width: "100%",
		// height: "400px",

		"&:hover > button": {
			opacity: 1,
		},

		"& > button": {
			opacity: 0,
			backgroundColor: "#0f0f0f",
			transition: "opacity 300ms",
		},

		"& > button > img": {
			width: "10px",
			height: "auto",
		},

		"& > img, & > video": {
			width: "100%",
			height: "100%",
			objectFit: "cover",
		},

		// "@media (max-width: 1200px)": {
		// 	height: "auto",
		// 	aspectRatio: 1.3,
		// },
	},
	leftPicBtn: {
		position: "absolute",
		zIndex: 1,
		top: "50%",
		left: "10px",
		transform: "translate(0, -50%)",
		border: "none",
		borderRadius: "50%",
		backgroundColor: "black",
		padding: "18px 22px",
		cursor: "pointer",
	},
	rightPicBtn: {
		position: "absolute",
		zIndex: 1,
		top: "50%",
		right: "10px",
		transform: "translate(0, -50%)",
		border: "none",
		borderRadius: "50%",
		backgroundColor: "black",
		padding: "18px 22px",
		cursor: "pointer",
	},

	feedActions: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		borderTop: "1px solid #F1F1F5",
		margin: "15px 0 0",
		padding: "10px 0",

		"& > button": {
			color: "#44444F",
			fontSize: "12px",
			fontWeight: 400,
		},

		"& .MuiButton-label": {
			textTransform: "capitalize",

			"& img": {
				marginRight: "5px",
				width: "22px",
			},
		},
	},

	postCreateCommentSection: {
		display: "flex",
		position: "relative",
		alignItems: "center",
		borderTop: "1px solid #F1F1F5",
		padding: "20px 0",
		width: "100%",

		"& .MuiTypography-body1, .MuiInputBase-root": {
			width: "100%",
		},

		"& .MuiInput-underline:before, .Mui-focused:after": {
			display: "none",
		},
	},

	postCommenterProfilePic: {
		flex: "0 0 8%",
		alignSelf: "first baseline",
		maxWidth: "45px",
		maxHeight: "45px",
		borderRadius: "50%",
		overflow: "hidden",
		margin: "0 15px 0 0",

		"& > img": {
			width: "100%",
		},
	},

	commentInputWrap: {
		position: "relative",
		display: "flex",
	},

	input: {
		flexBasis: "calc(93% - 15px)",
		border: "1px solid #F1F1F5",
		borderRadius: "15px",
		backgroundColor: "#FAFAFB",
		maxHeight: "10rem",
		padding: "15px 156px 15px 15px",

		"& textarea": {
			fontSize: 14,
			fontWeight: 400,
			fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
			background: "none",
			border: "none",
			padding: "14px",

			"&::placeholder": {
				color: "#afafb0",
			},
		},

		"& > div:nth-child(1)": {
			lineHeight: "1.3",

			"& strong": {
				position: "relative",
				zIndex: 1,
				top: 0,
				left: 1,
				backgroundColor: "#fff",
				color: "#7474a8",
			},

			"& .textarea": {
				zIndex: 0,
			},
		},

		"& > div:nth-child(2)": {
			boxShadow:
				"0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
			marginTop: "20px !important",
			borderRadius: "10px",
			border: "1px solid rgba(0,0,0,0.15)",
			backgroundColor: "white",
			padding: 5,

			"& li": {
				display: "flex",
				alignItems: "center",
				margin: 0,
				borderRadius: "5px",
				padding: "5px 10px 5px",
				color: "#44444F",
				cursor: "pointer",

				"&:hover": {
					backgroundColor: "#d7d7d7",
				},

				"&focused": {
					backgroundColor: "#cee4e5",
				},

				"& > img": {
					borderRadius: "50%",
					width: "20px",
				},

				"& > p": {
					margin: "0 0 0 7px",
				},
			},
		},
	},

	commentActionBtnsModal: {
		position: "absolute",
		top: "3px",
		right: 0,
		width: "90px!important",

		"& > button": {
			width: "45px",
			height: "45px",
		},
	},

	commentActionBtns: {
		position: "absolute",
		top: "24px",
		right: 0,
		width: "90px!important",

		"& > button": {
			width: "45px",
			height: "45px",
		},
	},

	photosList: {
		display: "flex",
		overflow: "hidden",
		overflowX: "scroll",
		borderRadius: "15px",
		marginTop: "10px",

		"&::-webkit-scrollbar": {
			height: "0px",
		},

		"&:hover": {
			cursor: "grab",
		},
	},

	photosListContainer: {
		display: "flex",
		gap: "10px",
	},

	photosListItem: {
		overflow: "hidden",
		borderRadius: "15px",
		width: "130px",
		height: "auto",

		"& > div": {
			overflow: "hidden",
			width: "100%",
			height: "100%",
		},
	},

	photosListItemPhoto: {
		position: "relative",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: "100%",
		height: "100%",

		"user-drag": "none",
		"-webkit-user-drag": "none",
		"user-select": "none",
		"-moz-user-select": "none",
		"-webkit-user-select": "none",
		"-ms-user-select": "none",
	},

	postCommentsMain: {
		marginTop: "10px",
	},

	recentCommentsButton: {
		marginBottom: "30px",

		"& > button": {
			border: "none",
			backgroundColor: "transparent",
			color: "#44444F",
			fontSize: "14px",
			fontWeight: 600,
			fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
			cursor: "",
		},
	},

	moreComments: {
		marginTop: "10px",
		color: "#4A4A4A",
		textAlign: "center",
		fontSize: "16px",
		fontWeight: 600,
		cursor: "pointer",

		"& > button": {
			border: "none",
			backgroundColor: "transparent",
			padding: "5px 15px",
		},
	},

	// #region popover styles

	popoverListItem: {
		display: "flex",
		alignItems: "center",
		borderRadius: "5px",
		padding: "5px 10px 5px 5px",
		minWidth: "250px",
		cursor: "pointer",

		"&:hover": {
			backgroundColor: "#e3e3e3",
		},
	},
	popoverListItemIcon: {
		display: "flex",
		justifyContent: "center",
		marginRight: "15px",
		width: "24px",
		textAlign: "center",
	},
	popoverListItemText: {},
	popoverListItemTitle: {
		color: "#44444F",
		fontSize: "14px",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	popoverListItemDescription: {
		color: "#92929D",
		fontSize: "12px",
	},
	imageGallery: {
		backgroundColor: "black",
		"& .image-gallery-thumbnail .image-gallery-thumbnail-image": {
			maxWidth: "none",
			height: "86px",
			margin: 0,
			width: "auto",
		},
		"& .image-gallery-thumbnail": {
			width: "auto",
		},
		"& .image-gallery-thumbnails .image-gallery-thumbnails-container": {
			cursor: "auto",
		},
		"& .image-gallery-thumbnail img": {
			cursor: "auto",
		},
		"& .image-gallery-slide .image-gallery-image": {
			height: "315px",
		},
	},
	imgHeight: {
		"& > ul > li": {
			height: "auto!important",
			maxHeight: "400px",
		},
	},
	imgHeightLimit: {
		"& > ul > li": {
			padding: "0px!important",
		},
	},
	imgBackground: {
		filter: "blur(4px)",
		webkitFilter: "blur(4px)",
		height: "100%",
	},
	imageGalleryMargin: {
		"& .image-gallery-thumbnail img": {
			margin: 0,
			height: "80px",
			border: "3px solid",
			backgroundSize: "cover",
			backgroundRepeat: "no-repeat",
		},
		"& .image-gallery-slide .image-gallery-image": {
			height: "600px",
			[theme.breakpoints.down("md")]: {
				height: "auto",
				maxHeight: "calc(100vh - 100px)",
			},
			[theme.breakpoints.down("xs")]: {
				height: "auto",
				maxHeight: "calc(100vh - 180px)",
			},
		},
		"& .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg": {
			height: "520PX",
			width: "40px",
			[theme.breakpoints.down("xs")]: {
				height: "120PX",
			},
		},
		"& .image-gallery-left-nav": {
			left: "-20px",
			[theme.breakpoints.down("xs")]: {
				top: "50%",
			},
		},
		"& .image-gallery-right-nav": {
			[theme.breakpoints.down("xs")]: {
				top: "50%",
			},
		},
		"& .image-gallery-icon:focus": { outline: "0px" },
		"& .image-gallery-right-nav::before": { content: "none" },
		"& .image-gallery-left-nav::before": { content: "none" },
	},

	attachmentListItemDisplay: {
		position: "relative",
		margin: "0px 0px",
		// width: "50%",
		// height: "120px",

		"& > div": {
			overflow: "hidden",
			marginTop: "0px",
			width: "100%",
			height: "100%",
		},
	},
	attachmentListItemContent: {
		width: "250px",
		height: "250px",
		objectFit: "cover",
		objectPosition: "center",
		border: "1px solid #92929D",
		borderRadius: "15px",
		cursor: "pointer",

		[theme.breakpoints.down("xs")]: {
			width: "100%",
			height: "150px",
		},
	},
	attachmentListItemContentSingle: {
		height: "auto",
		maxHeight: "500px",
	},
	attachmentListItemContentMultiple: {
		height: "200px",
	},
	maxModalSize: {
		maxWidth: "1400px!important",
		borderRadius: "18px",
		"@media (max-width: 1400px)": {
			maxWidth: "1200px!important",
			maxHeight: "calc(100vh - 20px)",
			"& .popupPostComment": {
				height: "calc(100vh - 131px)",
			},
			"& .image-gallery-slide-wrapper": {
				height: "calc(100vh - 100px)",
			},
		},
	},
	gridBackground: { backgroundColor: "#000", borderRadius: "18px 0 0 18px" },
	videoThumbnail: {
		width: "100%",
		"& > div > div": {
			backgroundSize: "contain!important",
			backgroundRepeat: "no-repeat",
		},
	},

	// #endregion
});

export default profileViewActivityPostCardStyles;
