/* eslint-disable react/prop-types */
import React, { Fragment, useState, useEffect, useRef, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import ReactMarkdown from "react-markdown";

import PublicComment from "../../../Component/SocialFeed/PublicComment.js";
import ReportModal from "../../ReportModal.js";
import defaultAvatar from "assets/img/candidate_avatar.jpeg";
import hashtagImg from "assets/img/hashtag.png";
import comment from "assets/images/comment.png";
import clsx from "clsx";

import axios from "axios";

import {
	Typography,
	makeStyles,
	Button,
	Input,
	IconButton,
	Popover,
	Popper,
	Grow,
	ClickAwayListener,
	Paper,
	Modal,
	Box,
	Grid,
	CardHeader,
	Avatar,
	Card,
	CardContent,
	Divider,
} from "@material-ui/core";

import { ReactComponent as HandClapIcon } from "assets/svg/clapping.svg";
import { ReactComponent as SendIcon } from "assets/svg/send-svgrepo-com.svg";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import MoodBadOutlinedIcon from "@mui/icons-material/MoodBadOutlined";
import SentimentVerySatisfiedOutlinedIcon from "@mui/icons-material/SentimentVerySatisfiedOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import cross from "assets/img/cross.png";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

import {
	header,
	handleFollow,
	handleUnfollow,
	// createHidePost,
} from "configurations/configurations.js";

import dotBtn from "assets/images/dotBtn.png";
import leftArrow from "assets/img/arrow-left.png";
import rightArrow from "assets/img/arrow-right.png";

import like from "assets/images/like.png";
import commentIcon from "assets/images/comment.png";
import share from "assets/images/share.png";
import saved from "assets/images/saved.png";
import commentStyle from "assets/jss/material-kit-pro-react/views/componentStyle/socialFeed/commentStyles";
import profileViewActivityCardStyles from "assets/jss/material-kit-pro-react/views/componentStyle/profileView/myActivity/profileViewActivityCardStyles.js";
import profileViewActivityPollCardStyles from "../../../../assets/jss/material-kit-pro-react/views/componentStyle/profileView/myActivity/profileViewActivityPollCardStyles.js";
import profileViewActivityPostCardStyles from "../../../../assets/jss/material-kit-pro-react/views/componentStyle/profileView/myActivity/profileViewActivityPostCardStyles.js";
import { getProfiles } from "configurations/redux/userSlice.js";
import {
	getSocialCommentByPostId,
	createSocialComment,
	createSocialReaction,
	deleteSocialReaction,
	createSocialHashtag,
	resetCreatedHashtag,
	resetPostComment,
	createPollResult,
	deletePollResult,
	getPostReactionCount,
	getSocialPostPopup,
	createSavePost,
	deleteSavePost,
	resetPostCreate,
	deleteSocialPost,
	createSocialPost,
	createFollowHashtag,
	deleteFollowHashtag,
	createHidePost,
	createFollowProfile,
	deleteFollowProfile,
} from "configurations/redux/socialFeedSlice.js";
import { createReport, resetReport } from "configurations/redux/reportSlice.js";
import CommentSkeleton from "../../SocialFeed/CommentSkeleton.js";
import GridItem from "../../../../components/Grid/GridItem.js";
import GridContainer from "../../../../components/Grid/GridContainer.js";
import Picker from "emoji-picker-react";
import { isMobile } from "react-device-detect";
import CommentImageModal from "../../SocialFeed/CommentImageModal.js";
import {
	instanceUrl,
	encryptData,
	dataURLtoFile,
	TimeFormatUniqueId,
	TimeFormatSocialFeed,
	TimeFormatPollLeft,
	TimeFormatPollConvert,
	getSocialReaction,
} from "configurations/configurations.js";
import "quill-mention";
import "react-quill/dist/quill.bubble.css";
import ReactQuill, { Quill } from "react-quill";
import Countdown from "react-countdown";
import PollResultUserListModal from "./PollResultUserListModal.js";
import CommentInput from "../../SocialFeed/CommentInput.js";
import ReactionUserListModal from "../../SocialFeed/ReactionUserListModal";
import PublicMediaModal from "./PublicMediaModal.js";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSnackbar } from "notistack";
import PostVideoList from "./PostVideoList.js";
import CustomDialog2 from "../../../../components/CustomDialog/CustomDialog2.js";
import shareLink from "assets/img/shareLink.jpg";
import { ReactTinyLink } from "react-tiny-link";
import ShortcutOutlinedIcon from "@mui/icons-material/ShortcutOutlined";
import JobCard from "../../../Page/SearchPage/SearchJobPage/JobCard";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import clapIcon from "assets/img/icons/clap.png";
import happyIcon from "assets/img/icons/happy.png";
import loveIcon from "assets/img/icons/love.png";
import likeIcon from "assets/img/icons/like.png";
import sadIcon from "assets/img/icons/sad.png";
import angryIcon from "assets/img/icons/angry.png";

const PublicViewActivityCard = ({
	post,
	hideReactionBar,
	searchPost,
	isCopyPost,
}) => {
	const classes = makeStyles(commentStyle)();
	const styles = makeStyles(profileViewActivityCardStyles)();
	const stylesPoll = makeStyles(profileViewActivityPollCardStyles)();
	const stylesPost = makeStyles(profileViewActivityPostCardStyles)();
	const user = useSelector((state) => state.auth.user);
	const dispatch = useDispatch();
	const history = useHistory();

	const quillRef = React.useRef(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const [anchorElHashtag, setAnchorElHashtag] = useState(null);
	const [anchorElEmoji, setAnchorElEmoji] = useState(null);
	const anchorRef = React.useRef(null);
	const [showReactionPaper, setShowReactionPaper] = useState(false);
	const [anchorShareEl, setAnchorShareEl] = useState(null);
	const [isPostSaved, setIsPostSaved] = useState(false);
	// const [saveId, setSaveId] = useState(null);
	const [commentsExpanded, setCommentState] = useState(false);
	const [reportModal, setReportModal] = useState(false);
	const [showComment, setShowComment] = useState(true);
	const searchResult = useSelector((state) => state.socialFeed.searchResult);
	const postResult = useSelector((state) => state.socialFeed.postResult);

	const userStatus = useSelector((state) => state.auth.user);
	const postUser = useSelector((state) => state.user);
	const isOnAmend = useSelector((state) => state.socialFeed.isOnAmend);
	const isCreating = useSelector((state) => state.socialFeed.isCreating);
	const amendPostId = useSelector((state) => state.socialFeed.amendPostId);
	const [skeletonShow, setSkeletonShow] = React.useState(false);
	const isEndComment = useSelector((state) => state.socialFeed.isEndComment);
	const isLoadingComment = useSelector(
		(state) => state.socialFeed.isLoadingComment
	);
	const likeId = useSelector((state) => state.socialFeed.likeId);
	const [likeColor, setLikeColor] = React.useState("#92929d");
	const [reactionCount, setReactionCount] = React.useState(
		post && post.reactionCount
	);
	const [likeShow, setLikeShow] = React.useState(
		post && post.ownerLike ? post.ownerLike.socialReactionUserList.length : 0
	);
	const [reactionId, setReactionId] = React.useState(
		post && post.ownerLike
			? post.ownerLike.socialReactionUserList.length > 0
				? post.ownerLike.socialReactionUserList[0].reactionId
				: null
			: null
	);
	const saveId = useSelector((state) => state.socialFeed.saveId);
	const [saveColor, setSaveColor] = React.useState("#92929d");
	const [saveCount, setSaveCount] = React.useState(post && post.saveCount);

	let fileInput = React.createRef();
	const [commentImageModal, setCommentImageModal] = React.useState(false);
	const [commentImage, setCommentImage] = React.useState(null);
	const [commentImageSaved, setCommentImageSaved] = React.useState(null);
	const [commentImageIsEdit, setCommentImageIsEdit] = React.useState(false);
	const [fileName, setFileName] = React.useState(null);

	const hashtagList = useSelector((state) => state.socialFeed.hashtagList);
	const profileTagList = useSelector(
		(state) => state.socialFeed.profileTagList
	);
	const hashtagKeyword = useSelector(
		(state) => state.socialFeed.hashtagKeyword
	);
	const profileTagKeyword = useSelector(
		(state) => state.socialFeed.profileTagKeyword
	);
	const hashValue = useSelector((state) => state.socialFeed.hashValue);
	const createHashtagPostId = useSelector(
		(state) => state.socialFeed.createHashtagPostId
	);
	const enterKey = useSelector((state) => state.socialFeed.enterKey);
	const lastOpenCommentPostId = useSelector(
		(state) => state.socialFeed.lastOpenCommentPostId
	);
	const [reportSuccessResponse, setReportSuccessResponse] = useState(null);
	const [optionClicked, setOptionClicked] = useState(-1);
	const [poll, setPoll] = useState(null);
	const poolOptionId = useSelector((state) => state.socialFeed.poolOptionId);
	const poolResultId = useSelector((state) => state.socialFeed.poolResultId);
	const poolOptionList = useSelector(
		(state) => state.socialFeed.poolOptionList
	);
	const isChosenOption = useSelector(
		(state) => state.socialFeed.isChosenOption
	);
	const pollOptionPostId = useSelector(
		(state) => state.socialFeed.pollOptionPostId
	);
	const [poolResultUserListModal, setPoolResultUserListModal] = React.useState(
		false
	);
	const [commentWordNumber, setCommentWordNumber] = React.useState(null);
	const [commentText, setCommentText] = React.useState(null);
	const [commentContent, setCommentContent] = React.useState(null);
	const [commentHTML, setCommentHTML] = React.useState(null);
	const [emojiChoose, setEmojiChoose] = React.useState(null);
	const [submitComment, setSubmitComment] = React.useState(false);
	const [reactionUserListModal, setReactionUserListModal] = React.useState(
		false
	);
	const [reactionList, setReactionList] = React.useState(null);
	const [previewAttachmentIndex, setPreviewAttachmentIndex] = useState(0);
	const [isViewAttachmentModalOpen, setIsViewAttachmentModalOpen] = useState(
		false
	);
	const [submitCommentId, setSubmitCommentId] = React.useState(null);
	const isLoadingSocialPost = useSelector(
		(state) => state.socialFeed.isLoadingSocialPost
	);
	const isCreatedPost = useSelector((state) => state.socialFeed.isCreatedPost);
	const isDeletedPost = useSelector((state) => state.socialFeed.isDeletedPost);
	const isUpdatedPost = useSelector((state) => state.socialFeed.isUpdatedPost);
	const createdPostId = useSelector((state) => state.socialFeed.createdPostId);
	const deletedPostId = useSelector((state) => state.socialFeed.deletedPostId);
	const updatedPostId = useSelector((state) => state.socialFeed.updatedPostId);
	const newFollowHashtagId = useSelector(
		(state) => state.socialFeed.followProfileId
	);
	const newFollowProfileId = useSelector(
		(state) => state.socialFeed.followProfileId
	);
	const [followProfileId, setFollowProfileId] = React.useState(
		post && post.followId
	);
	const [followProfileColor, setFollowProfileColor] = React.useState("#92929d");
	const [addLink, setAddLink] = useState(null);

	const [copied, setCopied] = React.useState(false);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const dialogDefault = {
		title: "",
		description: "",
		buttonYes: "",
		buttonNo: "",
	};
	const [confirmDialog, setConfirmDialog] = React.useState({
		isOpen: false,
		dialogDefault,
	});

	useEffect(() => {
		// console.log(post);
		if (post) {
			if (post.ownerLike && post.ownerLike.socialReactionUserList.length)
				setLikeColor("#6F52ED");
			if (post.saveCount > 0) setSaveColor("#6F52ED");
			if (post.followStatus > 0) setFollowProfileColor("#6F52ED");
			// var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
			// var url = post.description.match(urlRegex)[1];
			// console.log(url);

			var rawHTML = post.description;

			var doc = document.createElement("html");
			doc.innerHTML = rawHTML;
			var links = doc.getElementsByTagName("a");
			var urls = [];

			for (var i = 0; i < links.length; i++) {
				if (links[i].getAttribute("rel") == "noopener noreferrer")
					urls.push(links[i].getAttribute("href"));
			}
			if (urls.length > 0) {
				setAddLink(urls[0]);
			}
		}
	}, [post]);

	React.useEffect(() => {
		if (copied) {
			setCopied(false);
			const action = (key) => (
				<React.Fragment>
					<IconButton onClick={onClickDismiss(key)}>
						<CloseIcon />
					</IconButton>
				</React.Fragment>
			);
			snackbarCustom(
				"The share link has been copy into clipboard, kindly paste and share."
			);
		}
	}, [copied]);

	const snackBarShow = (message, variant, action) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(message, {
			variant: variant,
			action,
		});
	};
	const onClickDismiss = (key) => () => {
		closeSnackbar(key);
	};

	// Renderer callback with condition
	const renderer = ({ days, hours, minutes, seconds, completed }) => {
		if (completed) {
			return <span className={stylesPoll.pollClosed}> • </span>;
		} else {
			// Render a countdown
			return (
				<span className={stylesPoll.daysLeft}>
					{" "}
					{days > 0 ? days : ""}
					{days > 0 ? (days > 1 ? " days " : " day ") : ""}
					{hours > 0 ? hours : ""}
					{hours > 0 ? (hours > 1 ? " hours " : " hour ") : ""}
					{minutes > 0 ? minutes : ""}
					{minutes > 0 ? (minutes > 1 ? " minutes " : " minute ") : ""}
					{minutes <= 5 ? (seconds > 0 ? seconds : "") : ""}
					{minutes <= 5
						? seconds > 0
							? seconds > 1
								? " seconds "
								: " second "
							: ""
						: ""}
					{" left"}
				</span>
			);
		}
	};

	const shareToChat = () => {
		console.log(post);
	};

	const shareToGroup = () => {
		console.log(post);
	};

	const snackbarCustom = (title) => {
		const action = (key) => (
			<React.Fragment>
				<IconButton onClick={onClickDismiss(key)}>
					<CloseIcon />
				</IconButton>
			</React.Fragment>
		);
		snackBarShow(title, "default", action);
	};

	const signUpDialog = () => {
		console.log(post);
		const dialogDefault = {
			title: "Info",
			description: "Want to view more information?",
			description2:
				"Sign-up for an account with OppTy now to start connecting with others on our fast-growing career platform!",
			buttonYes: "Proceed",
			buttonNo: "Cancel",
		};
		setConfirmDialog({
			isOpen: true,
			dialogDefault,
			onConfirm: () => {
				history.push("/login-page");
				setConfirmDialog({
					isOpen: false,
					dialogDefault,
				});
			},
			onCancel: () => {
				setConfirmDialog({
					isOpen: false,
					dialogDefault,
				});
			},
		});
	};

	return (
		<Fragment>
			<CustomDialog2
				confirmDialog={confirmDialog}
				setConfirmDialog={setConfirmDialog}
			/>
			{post ? (
				<div
					className={clsx({
						[styles.body]: true,
						[styles.sharedBody]: hideReactionBar,
					})}
					// className={styles.body}
					id={"socialPost_" + post.postId}
					key={post.postId}
				>
					<div className={styles.header}>
						<div className={styles.headerLeft}>
							{/* <Link
								to={{
									pathname: `/view-profile/${encryptData(post.acctId)}`,
								}}
								target="_blank"
								rel="noopener noreferrer"
							> */}
							<div className={styles.profilePic}>
								<img
									src={post.profilePic ? post.profilePic : defaultAvatar}
									alt=""
								/>
							</div>
							{/* </Link> */}

							<div>
								{/* <Link
									to={{
										pathname: `/view-profile/${encryptData(post.acctId)}`,
									}}
									target="_blank"
									rel="noopener noreferrer"
								> */}
								<h6 className={styles.name}>
									{post.firstName}
									{post.middleName ? " " + post.middleName : ""}
									{" " + post.surname}
								</h6>
								{/* </Link> */}

								<span className={styles.time}>
									{TimeFormatSocialFeed(post.createdDate)}
								</span>
							</div>
						</div>
						{!hideReactionBar ? (
							searchPost ? (
								<button
									// style={{ position: "absolute", top: "32px", right: "24px" }}
									onClick={(e) => {
										history.replace(
											`/social-feed/2/${encryptData(post.postId)}`
										);
									}}
								>
									<ShortcutOutlinedIcon />
								</button>
							) : (
								<button
									onClick={(e) => {
										signUpDialog();
									}}
								>
									<img src={dotBtn} alt="" />
								</button>
							)
						) : null}
					</div>

					{
						post.description ? (
							<div
								className={styles.feedTitle}
								dangerouslySetInnerHTML={{
									__html: post.description.replaceAll('target="_blank"', ""),
								}}
							/>
						) : null
						// <p className={styles.feedTitle}>
						// 	There is no description. After that, We have applied validation that
						// 	user cannot create post if there is no description, attachment or
						// 	poll.
						// </p>
					}
					{post.typeOptionId == 0 && addLink && (
						<div className={stylesPost.sharedPost} style={{ padding: "10px" }}>
							<ReactTinyLink
								cardSize="small"
								showGraphic={true}
								maxLine={2}
								minLine={1}
								url={addLink}
								defaultMedia={shareLink}
								proxyUrl={instanceUrl}
							/>
						</div>
					)}
					{post.typeOptionId == 5 &&
					post.sharedJob &&
					post.sharedJob.length > 0 ? (
						<div className={stylesPost.sharedPost}>
							<div style={{ padding: "0 15px" }}>
								<h6
									style={{
										fontSize: "14px",
										fontWeight: "600",
										marginTop: "15px",
										marginBottom: "0",
									}}
								>
									Job Post
								</h6>
								<hr style={{ margin: "10px 0 0 0" }} />
							</div>
							<JobCard item={post.sharedJob[0]} />
						</div>
					) : null}
					{post.typeOptionId == 4 &&
					post.sharedPost &&
					post.sharedPost.postList.length > 0 ? (
						<div className={stylesPost.sharedPost}>
							<PublicViewActivityCard
								// key={post.postId}
								post={post.sharedPost.postList[0]}
								hideReactionBar={true}
								isCopyPost={false}
							/>
						</div>
					) : null}
					{post.postTypes && post.postTypes.length > 0 ? (
						post.typeOptionId == 3 ? (
							<div className={stylesPoll.pollSection}>
								<Typography className={stylesPoll.pollTitle}>
									{post.postTypes[0].poolList[0].question}
								</Typography>
								{/* <Typography className={stylesPoll.feedTitle}>
									The author can see how you vote
								</Typography> */}

								<Typography component="div" className={stylesPoll.pollGallery}>
									<div className={stylesPoll.QuestionandOptions}>
										<div className={stylesPoll.optionGroup}>
											{poolOptionList &&
											poolOptionList.length > 0 &&
											pollOptionPostId == post.postId
												? poolOptionList.map((item, index) => (
														<div
															key={index}
															className={
																isChosenOption &&
																pollOptionPostId == post.postId
																	? item.ownerChosenOption == item.poolOptionId
																		? stylesPoll.Optionselected
																		: stylesPoll.Optionnotselected
																	: stylesPoll.Options
															}
															onClick={() => {
																signUpDialog();
															}}
														>
															<div className={stylesPoll.optionDesc}>
																{item.optionDesc}
															</div>
															{isChosenOption &&
															pollOptionPostId == post.postId ? (
																<div className={stylesPoll.percentage}>
																	{item.optionPercentage.toFixed(0)}
																	{"%"}
																</div>
															) : (
																""
															)}
														</div>
												  ))
												: post.postTypes[0].poolList[0].poolOptionList
												? post.postTypes[0].poolList[0].poolOptionList.map(
														(item, index) => (
															<div
																key={index}
																className={
																	post.postTypes[0].poolList[0].choosen
																		? item.ownerChosenOption ==
																		  item.poolOptionId
																			? stylesPoll.Optionselected
																			: stylesPoll.Optionnotselected
																		: stylesPoll.Options
																}
																onClick={() => {
																	signUpDialog();
																}}
															>
																<div className={stylesPoll.optionDesc}>
																	{item.optionDesc}
																</div>
																{post.postTypes[0].poolList[0].choosen ? (
																	<div className={stylesPoll.percentage}>
																		{item.optionPercentage.toFixed(0)}
																		{"%"}
																	</div>
																) : (
																	""
																)}
															</div>
														)
												  )
												: null}
										</div>
										<div className={stylesPoll.pollDetails}>
											<span
												className={stylesPoll.pollVote}
												onClick={() => {
													signUpDialog();
												}}
											>
												{poolOptionList.length > 0 &&
												pollOptionPostId == post.postId ? (
													<span className={stylesPoll.viewandresult}>
														{poolOptionList[0].totalResult
															? poolOptionList[0].totalResult
															: 0}{" "}
														{poolOptionList[0].totalResult &&
														poolOptionList[0].totalResult > 1
															? "votes"
															: "vote"}
													</span>
												) : post.postTypes[0].poolList[0].poolOptionList
														.length > 0 ? (
													<span className={stylesPoll.viewandresult}>
														{post.postTypes[0].poolList[0].poolOptionList[0]
															.totalResult
															? post.postTypes[0].poolList[0].poolOptionList[0]
																	.totalResult
															: 0}{" "}
														{post.postTypes[0].poolList[0].poolOptionList[0]
															.totalResult &&
														post.postTypes[0].poolList[0].poolOptionList[0]
															.totalResult > 1
															? "votes"
															: "vote"}
													</span>
												) : null}
											</span>
											<Countdown
												date={TimeFormatPollConvert(
													post.postTypes[0].poolList[0].endDate
												)}
												renderer={renderer}
											/>
											<span
												className={
													TimeFormatPollLeft(
														post.postTypes[0].poolList[0].endDate
													)
														? stylesPoll.pollUndo
														: stylesPoll.pollClosed
												}
												onClick={(e) => {
													signUpDialog();
												}}
											>
												{TimeFormatPollLeft(
													post.postTypes[0].poolList[0].endDate
												)
													? post.postTypes[0].poolList[0].choosen ||
													  (isChosenOption && pollOptionPostId == post.postId)
														? "Undo"
														: null
													: "Poll closed"}
											</span>
										</div>
									</div>
								</Typography>
							</div>
						) : null
					) : null}

					{post.postTypes && post.postTypes.length > 0 ? (
						post.typeOptionId == 1 || post.typeOptionId == 2 ? (
							<Typography
								component="div"
								className={stylesPost.postGraphicalContent}
							>
								<Typography
									component="div"
									className={stylesPost.postContentContainer}
									onClick={() => {
										if (post.typeOptionId == 1) {
											dispatch(getSocialPostPopup(post));
											setIsViewAttachmentModalOpen(true);
										}
									}}
								>
									<Fragment>
										{post.typeOptionId == 1 ? (
											<div>
												<GridContainer
													style={{ margin: 0 }}
													justifyContent="center"
												>
													{post.postTypes.length > 0
														? post.postTypes.length == 4
															? post.postTypes.map((item, index) => (
																	<GridItem
																		key={index}
																		xs={6}
																		sm={6}
																		md={6}
																		style={{ padding: "0 2px" }}
																	>
																		<div
																			className={
																				stylesPost.attachmentListItemDisplay
																			}
																		>
																			<div
																				className={
																					classes.attachmentListItemAttachmentContainer
																				}
																			>
																				<img
																					src={item.attachment}
																					alt=""
																					className={
																						stylesPost.attachmentListItemContent
																					}
																					style={{
																						width: "100%",
																					}}
																				/>
																			</div>
																		</div>
																	</GridItem>
															  ))
															: post.postTypes.length == 3
															? post.postTypes.map((item, index) => (
																	<GridItem
																		key={index}
																		xs={4}
																		sm={4}
																		md={4}
																		style={{ padding: "0 2px" }}
																	>
																		<div
																			className={
																				stylesPost.attachmentListItemDisplay
																			}
																		>
																			<div
																				className={
																					classes.attachmentListItemAttachmentContainer
																				}
																			>
																				<img
																					src={item.attachment}
																					alt=""
																					className={
																						stylesPost.attachmentListItemContent
																					}
																					style={{
																						// height: "200px",
																						width: "100%",
																					}}
																				/>
																			</div>
																		</div>
																	</GridItem>
															  ))
															: post.postTypes.length == 2
															? post.postTypes.map((item, index) => (
																	<GridItem
																		key={index}
																		xs={6}
																		sm={6}
																		md={6}
																		style={{ padding: "0 2px" }}
																	>
																		<div
																			className={
																				stylesPost.attachmentListItemDisplay
																			}
																		>
																			<div
																				className={
																					classes.attachmentListItemAttachmentContainer
																				}
																			>
																				<img
																					src={item.attachment}
																					alt=""
																					className={
																						stylesPost.attachmentListItemContent
																					}
																					style={{
																						height:
																							post.postTypes.length == 4
																								? "200px"
																								: post.postTypes.length > 4
																								? "150px"
																								: null,
																					}}
																				/>
																			</div>
																		</div>
																	</GridItem>
															  ))
															: post.postTypes.length == 1
															? post.postTypes.map((item, index) => (
																	<GridItem
																		key={index}
																		xs={12}
																		sm={12}
																		md={12}
																		style={{ padding: "0 2px" }}
																	>
																		<div
																			className={
																				stylesPost.attachmentListItemDisplay
																			}
																		>
																			<div
																				className={
																					classes.attachmentListItemAttachmentContainer
																				}
																			>
																				<img
																					src={item.attachment}
																					alt=""
																					className={
																						stylesPost.attachmentListItemContent
																					}
																				/>
																			</div>
																		</div>
																	</GridItem>
															  ))
															: post.postTypes.length > 4 &&
															  post.postTypes.map(
																	(item, index) =>
																		index < 4 && (
																			<GridItem
																				key={index}
																				xs={6}
																				sm={6}
																				md={6}
																				style={{ padding: "0 2px" }}
																			>
																				<div
																					className={
																						stylesPost.attachmentListItemDisplay
																					}
																				>
																					<div
																						className={
																							classes.attachmentListItemAttachmentContainer
																						}
																					>
																						{index < 3 ? (
																							<img
																								src={item.attachment}
																								alt=""
																								className={
																									stylesPost.attachmentListItemContent
																								}
																								style={{
																									width: "100%",
																								}}
																							/>
																						) : (
																							<div
																								className={
																									stylesPost.attachmentListItemContent
																								}
																								style={{
																									textAlign: "center",
																									cursor: "pointer",
																									width: "100%",
																									backgroundImage: `url(${item.attachment})`,
																									filter: "brightness(0.7)",
																									backgroundRepeat: "no-repeat",
																									backgroundSize: "cover",
																								}}
																							>
																								<AddOutlinedIcon
																									sx={{
																										color: "white",
																										height: "100%",
																										width: "35px",
																										// margin: "5px",
																										transition: "all .5s",
																										"&:hover": {
																											transform: "scale(1.5)",
																										},
																									}}
																									alt=""
																								/>
																							</div>
																						)}
																					</div>
																				</div>
																			</GridItem>
																		)
															  )
														: null}
												</GridContainer>
											</div>
										) : post.typeOptionId == 2 ? (
											<PostVideoList attachmentList={post.postTypes} />
										) : null}
									</Fragment>
								</Typography>
							</Typography>
						) : null
					) : null}

					{!hideReactionBar ? (
						<div className={styles.feedActions}>
							{post.cpId != 3 ? (
								<Button
									onClick={() => {
										signUpDialog();
									}}
								>
									{/* <img src={commentIcon} alt="" /> */}
									<svg
										id="ic_comment"
										xmlns="http://www.w3.org/2000/svg"
										width="30.221"
										height="31.343"
										viewBox="0 0 30.221 31.343"
									>
										<g id="Comments">
											<path id="Path" d="M0,0H30.221V31.343H0Z" fill="none" />
											<g
												id="iconspace_Chat_3_25px"
												data-name="iconspace_Chat 3_25px"
												transform="translate(2.518 3.761)"
											>
												<path
													id="Path-2"
													data-name="Path"
													d="M6.478,23.824A1.935,1.935,0,0,1,4.543,21.9l0-2.9A5.9,5.9,0,0,1,0,13.244V5.917A5.928,5.928,0,0,1,5.926,0H19.258a5.928,5.928,0,0,1,5.926,5.918v7.327a5.928,5.928,0,0,1-5.926,5.918H13.239l-5.6,4.264A1.9,1.9,0,0,1,6.478,23.824ZM5.926,2.507a3.413,3.413,0,0,0-3.407,3.41v7.327A3.39,3.39,0,0,0,5.83,16.654,1.249,1.249,0,0,1,7.055,17.9l0,2.805,4.989-3.8a1.269,1.269,0,0,1,.765-.258h6.445a3.413,3.413,0,0,0,3.407-3.41V5.917a3.413,3.413,0,0,0-3.407-3.41Z"
													fill={post.commentCount > 0 ? "#6F52ED" : "#92929d"}
												/>
											</g>
										</g>
									</svg>
									{post.commentCount}{" "}
									{post.commentCount > 1 ? "Comments" : "Comment"}
								</Button>
							) : null}
							<Button
								ref={anchorRef}
								onClick={() => {
									signUpDialog();
								}}
							>
								{likeShow > 0 ? (
									reactionId == 1 ? (
										<img src={likeIcon} alt="" />
									) : // <ThumbUpAltOutlinedIcon
									// 	sx={{
									// 		color: likeColor,
									// 		margin: "5px",
									// 		transition: "all .5s",
									// 		"&:hover": {
									// 			transform: "scale(1.5)",
									// 		},
									// 	}}
									// />
									reactionId == 2 ? (
										<img src={loveIcon} alt="" />
									) : // <FavoriteBorderOutlinedIcon
									// 	sx={{
									// 		color: likeColor,
									// 		margin: "5px",
									// 		transition: "all .5s",
									// 		"&:hover": {
									// 			transform: "scale(1.5)",
									// 		},
									// 	}}
									// />
									reactionId == 3 ? (
										<img src={clapIcon} alt="" />
									) : // <HandClapIcon
									// 	style={{
									// 		fill: likeColor,
									// 		width: "22px",
									// 		height: "24px",
									// 		margin: "5px",
									// 		transition: "all .5s",
									// 	}}
									// 	className={styles.clapIcon}
									// />
									reactionId == 4 ? (
										<img src={angryIcon} alt="" />
									) : // <SentimentVeryDissatisfiedIcon
									// 	sx={{
									// 		color: likeColor,
									// 		margin: "5px",
									// 		transition: "all .5s",
									// 		"&:hover": {
									// 			transform: "scale(1.5)",
									// 		},
									// 	}}
									// />
									reactionId == 5 ? (
										<img src={sadIcon} alt="" />
									) : (
										// <MoodBadOutlinedIcon
										// 	sx={{
										// 		color: likeColor,
										// 		margin: "5px",
										// 		transition: "all .5s",
										// 		"&:hover": {
										// 			transform: "scale(1.5)",
										// 		},
										// 	}}
										// />
										reactionId == 6 && (
											<img src={happyIcon} alt="" />
											// <SentimentVerySatisfiedOutlinedIcon
											// 	sx={{
											// 		color: likeColor,
											// 		margin: "5px",
											// 		transition: "all .5s",
											// 		"&:hover": {
											// 			transform: "scale(1.5)",
											// 		},
											// 	}}
											// />
										)
									)
								) : (
									<svg
										alt=""
										xmlns="http://www.w3.org/2000/svg"
										width="30.221"
										height="30.09"
										viewBox="0 0 30.221 30.09"
									>
										<g id="ic_like" transform="translate(0 0)">
											<g id="Likes">
												<ellipse
													id="Oval"
													cx="15.11"
													cy="15.045"
													rx="15.11"
													ry="15.045"
													transform="translate(0 0)"
													fill="none"
												/>
												<path
													id="Combined_Shape_Copy"
													data-name="Combined Shape Copy"
													d="M13.851,24.53a2.51,2.51,0,0,1-1.756-.71l-.05-.049L2.314,13.809a8.208,8.208,0,0,1,0-11.425,7.837,7.837,0,0,1,11.258,0l.279.285.279-.285a7.837,7.837,0,0,1,11.258,0,8.206,8.206,0,0,1,0,11.425l-9.732,9.962A2.5,2.5,0,0,1,13.851,24.53ZM7.943,2.507A5.311,5.311,0,0,0,4.119,4.132a5.707,5.707,0,0,0,0,7.928l9.732,9.963,9.732-9.963a5.707,5.707,0,0,0,0-7.928,5.312,5.312,0,0,0-7.647,0l-1.182,1.21a1.264,1.264,0,0,1-1.805,0l-1.182-1.21A5.311,5.311,0,0,0,7.943,2.507Z"
													transform="translate(1.259 2.507)"
													fill={likeColor}
												/>
											</g>
										</g>
									</svg>
								)}
								<span
									className={classes.likeReaction}
									onClick={() => {
										signUpDialog();
									}}
								>
									{reactionCount} {reactionCount > 1 ? "Likes" : "Like"}
								</span>
							</Button>
							<CopyToClipboard
								onCopy={() => setCopied(true)}
								text={
									post
										? window.location.origin +
										  "/public-search-page/post/" +
										  encryptData(post.postId)
										: ""
								}
							>
								<Button
									onClick={(e) => {
										setCopied(true);
									}}
								>
									{/* <img src={share} alt="" /> */}
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="30.221"
										height="30.09"
										viewBox="0 0 30.221 30.09"
									>
										<g id="ic_Share" transform="translate(0 0)">
											<g id="Share">
												<ellipse
													id="Oval"
													cx="15.11"
													cy="15.045"
													rx="15.11"
													ry="15.045"
													transform="translate(0 0)"
													fill="none"
												/>
												<g
													id="Group_24_Copy"
													data-name="Group 24 Copy"
													transform="translate(28.962 1.254)"
												>
													<path
														id="Combined_Shape"
														data-name="Combined Shape"
														d="M0,22.567a5.031,5.031,0,0,0-5.036-5.015,5.042,5.042,0,0,0-4.157,2.186l-8.691-4.37a4.976,4.976,0,0,0,.255-1.577,4.974,4.974,0,0,0-.293-1.686l8.741-4.243A5.042,5.042,0,0,0-5.036,10.03,5.031,5.031,0,0,0,0,5.015,5.031,5.031,0,0,0-5.036,0a5.032,5.032,0,0,0-5.037,5.015q0,.242.023.48l-9.307,4.517a5.027,5.027,0,0,0-3.307-1.236A5.032,5.032,0,0,0-27.7,13.791a5.032,5.032,0,0,0,5.037,5.015,5.029,5.029,0,0,0,3.4-1.321l9.209,4.63q-.02.223-.02.452a5.032,5.032,0,0,0,5.037,5.015A5.031,5.031,0,0,0,0,22.567Zm-2.518,0a2.515,2.515,0,0,1-2.518,2.507,2.516,2.516,0,0,1-2.519-2.507,2.516,2.516,0,0,1,2.519-2.507A2.516,2.516,0,0,1-2.518,22.567Zm-17.629-8.776A2.516,2.516,0,0,1-22.666,16.3a2.516,2.516,0,0,1-2.519-2.507,2.516,2.516,0,0,1,2.519-2.507A2.516,2.516,0,0,1-20.147,13.791ZM-2.518,5.015A2.516,2.516,0,0,1-5.036,7.522,2.516,2.516,0,0,1-7.556,5.015,2.516,2.516,0,0,1-5.036,2.507,2.516,2.516,0,0,1-2.518,5.015Z"
														fill={saveColor}
													/>
												</g>
											</g>
										</g>
									</svg>
									{post.shareCount} Shares
								</Button>
							</CopyToClipboard>

							<Button>
								<VisibilityOutlinedIcon
									sx={{
										color: post.viewCount > 0 ? "#6F52ED" : "#92929d",
										margin: "5px",
										transition: "all .5s",
										"&:hover": {
											transform: "scale(1.5)",
										},
									}}
									alt=""
								/>
								{post.viewCount} Viewed
							</Button>
						</div>
					) : null}
					{lastOpenCommentPostId == post.postId ? (
						<div className={styles.feedCommentBx}>
							<GridContainer>
								<GridItem xs={1} sm={1} md={1} style={{ paddingRight: "0px" }}>
									<div className={styles.feedCommentUserBx}>
										<img src={user.profilePic} alt="" />
									</div>
								</GridItem>
								<GridItem xs={11} sm={11} md={11}>
									<div className={styles.commentInputWrap}>
										<div className={classes.mentionsInput}>
											<CommentInput
												post={post}
												comment={null}
												emojiChoose={emojiChoose}
												resetEmoji={setEmojiChoose}
												getLength={setCommentWordNumber}
												getText={setCommentText}
												getHTML={setCommentHTML}
												getContent={setCommentContent}
												onSubmit={setSubmitComment}
												submitCommentId={setSubmitCommentId}
												parentId={0}
											/>
										</div>

										<div className={styles.commentActionBtns}>
											<IconButton
												onClick={(e) => {
													signUpDialog();
												}}
												// ref={anchorRefEmoji}
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="21"
													height="21"
													viewBox="0 0 21 21"
												>
													<path
														id="Shape"
														d="M3.075,17.924A10.5,10.5,0,0,1,17.924,3.075,10.5,10.5,0,0,1,3.075,17.924ZM1,10.5A9.5,9.5,0,1,0,10.5,1,9.511,9.511,0,0,0,1,10.5Zm5.207,4.419a.5.5,0,1,1,.6-.8,6.327,6.327,0,0,0,3.727,1.127A6.084,6.084,0,0,0,14.2,14.124a.5.5,0,0,1,.7.09.5.5,0,0,1-.09.7,7,7,0,0,1-4.277,1.331A7.236,7.236,0,0,1,6.207,14.919ZM12.5,8.5a1,1,0,1,1,1,1A1,1,0,0,1,12.5,8.5Zm-6,0a1,1,0,1,1,1,1A1,1,0,0,1,6.5,8.5Z"
														fill="#92929d"
													/>
												</svg>
											</IconButton>
											<IconButton
												onClick={() => {
													signUpDialog();
												}}
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="21"
													height="20"
													viewBox="0 0 21 20"
												>
													<path
														id="Shape"
														d="M17.5,20H3.5A3.431,3.431,0,0,1,0,16.65V3.35A3.431,3.431,0,0,1,3.5,0h14A3.431,3.431,0,0,1,21,3.35v13.3A3.431,3.431,0,0,1,17.5,20ZM15.286,8.775l-4.714,4.253L15.961,19H17.5A2.431,2.431,0,0,0,20,16.65V13.071Zm-8.139,1.95h0L1.009,16.849A2.458,2.458,0,0,0,3.5,19H14.613L7.148,10.725ZM3.5,1A2.431,2.431,0,0,0,1,3.35v12.1l5.814-5.8a.5.5,0,0,1,.724.019L9.9,12.286l5.051-4.557a.5.5,0,0,1,.671,0L20,11.718V3.35A2.431,2.431,0,0,0,17.5,1ZM6.691,6.7A1.925,1.925,0,1,1,8.619,4.775,1.929,1.929,0,0,1,6.691,6.7Zm0-2.849a.925.925,0,1,0,.929.925A.928.928,0,0,0,6.691,3.85Z"
														transform="translate(0 0)"
														fill="#92929d"
													/>
												</svg>
											</IconButton>
											<IconButton
												onClick={() => {
													signUpDialog();
												}}
											>
												<SendIcon
													sx={{
														color: "#92929d",
														margin: "5px",
													}}
													className={styles.sendButton}
												/>
											</IconButton>
										</div>
									</div>
								</GridItem>
							</GridContainer>
						</div>
					) : null}

					{showComment && !hideReactionBar ? (
						post.comments.commentList && post.comments.commentList.length ? (
							<div className={styles.postCommentsMain}>
								{skeletonShow ? <CommentSkeleton /> : null}
								{post.comments.commentList && post.comments.commentList.length
									? // &&
									  // commentsExpanded
									  post.comments.commentList.map((comment, index) => (
											<div key={comment.commentId}>
												<PublicComment
													comment={comment}
													postId={post.postId}
													profilePic={defaultAvatar}
													commentCount={1}
													reportReasonsList={null}
													sectionCount={1}
													isCopyPost={isCopyPost}
													signUpDialog={() => {
														signUpDialog();
													}}
												/>
											</div>
									  ))
									: null}

								{isEndComment || !commentsExpanded ? (
									<Typography component="div" className={styles.moreComments}>
										<button
											onClick={() => {
												signUpDialog();
											}}
										>
											Hide comments
										</button>
									</Typography>
								) : isLoadingComment ? (
									<CommentSkeleton />
								) : post.actualCommentCount >
								  post.comments.commentList.length ? (
									<Typography component="div" className={styles.moreComments}>
										<button onClick={() => signUpDialog()}>
											Load more comments
										</button>
									</Typography>
								) : null}
							</div>
						) : (
							<></>
						)
					) : null}
				</div>
			) : null}
			{isViewAttachmentModalOpen ? (
				<PublicMediaModal
					open={isViewAttachmentModalOpen}
					onClose={() => {
						setIsViewAttachmentModalOpen(false);
					}}
					post={post}
				/>
			) : null}
		</Fragment>
	);
};

export default PublicViewActivityCard;
