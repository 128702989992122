import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
	departmentViewRoadmap,
	viewRoadmapRoleByRoleId,
} from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Department_Occupational_Therapy from "assets/img/Career_Roadmap/Healthcare/Department_Occupational_Therapy_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentOccupationalTherapy() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Occupational_Therapy;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "ot",
				shape: "poly",
				coords: [83,125,73,128,69,130,66,136,63,143,64,737,65,744,70,749,75,753,82,755,173,755,180,753,186,749,190,744,192,737,192,145,190,136,185,130,179,126,172,125,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "327",
				shape: "poly",
				coords: [228,405,218,406,213,410,211,416,210,421,210,485,212,492,214,496,220,500,225,500,338,501,345,498,349,494,352,489,353,482,353,425,353,416,350,410,344,407,340,405,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "328",
				shape: "poly",
				coords: [422,404,414,408,411,411,408,415,407,422,407,485,409,492,413,497,418,500,424,500,534,500,544,497,546,493,550,489,551,484,551,423,549,413,545,408,539,406,533,405,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "329",
				shape: "poly",
				coords: [619,405,612,408,609,410,606,415,605,422,605,487,607,492,610,497,615,500,621,500,734,501,740,498,744,495,747,492,749,485,749,422,747,414,743,408,737,406,731,405,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "330",
				shape: "poly",
				coords: [817,405,811,407,806,411,804,417,803,422,803,488,805,493,808,497,813,500,821,500,930,500,938,498,942,495,946,489,946,484,946,425,945,415,942,410,937,406,930,405,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "331",
				shape: "poly",
				coords: [1018,405,1009,406,1005,410,1002,416,1001,422,1001,486,1003,492,1006,497,1012,500,1017,500,1127,500,1136,498,1141,494,1144,487,1145,482,1145,425,1144,415,1142,410,1136,406,1128,405,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "334",
				shape: "poly",
				coords: [1215,124,1208,126,1204,128,1201,133,1198,140,1199,216,1200,222,1203,225,1208,228,1214,230,1390,230,1398,229,1403,225,1407,219,1409,214,1409,141,1407,132,1403,127,1398,124,1390,122,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "335",
				shape: "poly",
				coords: [1480,124,1469,127,1466,131,1464,136,1462,142,1462,211,1464,221,1468,226,1474,229,1482,230,1654,231,1661,229,1666,226,1671,221,1672,215,1672,142,1671,132,1667,128,1662,125,1656,124,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "336",
				shape: "poly",
				coords: [1214,298,1206,301,1202,305,1199,311,1199,317,1199,392,1201,398,1204,402,1207,404,1214,405,1389,406,1400,403,1404,400,1407,393,1408,388,1408,316,1407,307,1403,302,1397,300,1390,300,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "337",
				shape: "poly",
				coords: [1478,299,1471,301,1466,305,1464,309,1462,314,1463,391,1465,397,1469,402,1475,404,1482,406,1656,405,1662,403,1666,402,1670,396,1672,390,1672,312,1669,306,1665,302,1660,299,1655,299,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "332",
				shape: "poly",
				coords: [1219,474,1208,476,1204,479,1200,485,1199,491,1200,568,1202,574,1204,578,1209,580,1216,581,1394,580,1402,578,1406,574,1408,569,1409,563,1408,489,1405,481,1401,477,1396,475,1389,474,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "333",
				shape: "poly",
				coords: [1479,474,1470,477,1466,481,1464,487,1462,494,1463,568,1465,573,1468,577,1474,580,1480,582,1656,580,1664,578,1669,574,1672,570,1673,564,1672,493,1670,483,1666,477,1661,474,1655,474,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "338",
				shape: "poly",
				coords: [1213,650,1207,652,1202,656,1200,661,1199,666,1200,741,1202,750,1206,753,1211,755,1219,756,1393,756,1401,754,1404,750,1407,746,1408,742,1408,664,1406,658,1403,653,1396,650,1390,650,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "339",
				shape: "poly",
				coords: [1480,650,1471,652,1468,655,1464,661,1463,668,1464,740,1466,749,1470,753,1474,755,1481,756,1656,756,1663,754,1668,750,1672,746,1673,741,1673,667,1670,657,1667,653,1660,650,1654,650,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "ot") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo);
			dispatch(viewRoadmapRoleByRoleId(requestInfo));

			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
