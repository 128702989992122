import React from "react";
import { Link } from "react-router-dom";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import {
	encryptData,
	instanceUrl,
	header,
} from "configurations/configurations.js";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import searchPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/SearchPage/searchPageStyle.js";
import SmallCardLoading from "../SmallCardLoading";
import defaultAvatar from "assets/img/candidate_avatar.jpeg";

const useStyles = makeStyles(searchPageStyle);

export default function PeopleCard({ item }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const isLoading = useSelector((state) => state.searchProfile.isLoading);

	const CardComponent = () => {
		return (
			<Link
				className={classes.textDeco}
				to={`/view-profile/${encryptData(item.acctId)}`}
				color="transparent"
				target="_blank"
				key={item.acctId}
			>
				<Card className={classes.cardFrame + " " + classes.cardClick}>
					<CardBody>
						<div className={classes.resultContainer}>
							<div className={classes.imgContainer}>
								<img src={item.profilePic ? item.profilePic : defaultAvatar} />
							</div>
							<div className={classes.descriptionContainer}>
								<div>
									<h4 className={classes.title}>
										{item.firstName + " " + item.surname}
									</h4>
									<h4 className={classes.description}>{item.headline}</h4>
									<h4 className={classes.description}>{item.countryName}</h4>
								</div>
							</div>
						</div>
					</CardBody>
				</Card>
			</Link>
		);
	};

	return <div>{isLoading ? <SmallCardLoading /> : <CardComponent />}</div>;
}
