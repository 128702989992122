import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

import Clearfix from "components/Clearfix/Clearfix.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import footerStyle from "assets/jss/material-kit-pro-react/components/alphaFooterStyle.js";

const useStyles = makeStyles(footerStyle);

export default function AlphaFooter({ ...rest }) {
	const classes = useStyles();

	return (
		<React.Fragment>
			<Clearfix />
			<Footer
				className={classes.footer}
				content={
					<div className={classNames(classes.pullCenter, classes.copyRight)}>
						Copyright &copy; {1900 + new Date().getYear()}{" "}
						<a
							href="https://www.creative-tim.com?ref=mkpr-footer-components"
							target="_blank"
							className={classes.copyRightWordings}
						>
							<h6>OppTy</h6>
						</a>{" "}
						All Rights Reserved.
					</div>
				}
			>
				<div className={classes.footer}>
					<GridContainer>
						<GridItem xs={12} sm={12} md={12}>
							<h5>About</h5>
							<ul className={classes.linksVertical}>
								<li>
									<a href="#pablo">About us</a>
								</li>

								<li>
									<a href="#pablo">Contact us</a>
								</li>

								<li>
									<a href="/alpha-terms-conditions-page">Terms & Conditions </a>
								</li>
							</ul>
						</GridItem>
						<GridItem xs={12} sm={12} md={12}>
							<h5>Connect With Us</h5>
							<p>Follow us and get informations </p>
							<ul className={classes.socialButtons}>
								<li>
									<Button color="facebook" justIcon>
										<i className="fab fa-facebook-f" />
									</Button>
								</li>

								<li className={classes.liSocialButtons}>
									<Button color="google" justIcon>
										<i className="fab fa-google" />
									</Button>
								</li>
								<li className={classes.liSocialButtons}>
									<Button color="linkedin" justIcon>
										<i className="fab fa-linkedin-in" />
									</Button>
								</li>
							</ul>
						</GridItem>
						<GridItem xs={12} sm={12} md={12}>
							<a href="#pablo">
								<h5>Any Inquiries</h5>
							</a>
							<p>Feel Free to contact us about any concerns</p>
							<Button color="primary">Email Us</Button>
						</GridItem>
					</GridContainer>
				</div>
			</Footer>
		</React.Fragment>
	);
}
