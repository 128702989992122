import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { getProfiles } from 'configurations/redux/userSlice.js';
import { updateApplicantStatus } from 'configurations/redux/jobManagementSlice.js';
import { resetNotificationItem } from 'configurations/redux/eventNotificationSlice';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';

import defaultAvatar from 'assets/img/candidate_avatar.jpeg';

import corporateJobManagementPageStyle from 'assets/jss/material-kit-pro-react/views/pageStyle/CorporateJobManagementPage/corporateJobManagementPageStyle.js';
import ApplicantProfileModal from './ApplicantProfileModal.js';
import Primary from 'components/Typography/Primary.js';
import Danger from 'components/Typography/Danger.js';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';

import { TimeFormat } from 'configurations/configurations.js';
import { Hidden } from '@material-ui/core';

const useStyles = makeStyles(corporateJobManagementPageStyle);
const publicIp = require('public-ip');

export default function ApplicantProfileSection({ profileList }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userStatus = useSelector(state => state.auth.user);
  const applicantList = useSelector(state => state.jobManagement.applicantList);
  const [applicantProfileModal, setApplicantProfileModal] = React.useState(false);
  const [selectedAppl, setSelectedAppl] = React.useState();
  const notificationProfileItem = useSelector(state => state.eventNotification.item);
  const onClose = () => {
    setApplicantProfileModal(false);
  };

  const viewProfile = async item => {
    if (item.applicationStatusId === 1) {
      const updateProfile = {
        acctId: userStatus.acctId,
        accessToken: localStorage.getItem('token'),
        email: userStatus.email,
        applicantId: item.acctId,
        applStatusId: 2,
        applId: applicantList
          .filter(list => {
            return list.acctId === item.acctId;
          })
          .map(list => {
            return list.applId;
          })[0],
      };
      console.log(updateProfile);
      dispatch(updateApplicantStatus(updateProfile));
    }
    const requestProfile = {
      userId: item.acctId,
      acctId: userStatus.acctId,
      ipAddr: localStorage.getItem('ipAddr'), //await publicIp.v4(),
      accessToken: localStorage.getItem('token'),
    };

    dispatch(getProfiles(requestProfile));
    setSelectedAppl(item);
    setApplicantProfileModal(true);
  };

  React.useEffect(() => {
    // console.log(notificationProfileItem, applicantList);
    if (notificationProfileItem > 0 && applicantList.length > 0) {
      setApplicantProfileModal(true);
      dispatch(resetNotificationItem());
    }
  }, [notificationProfileItem, applicantList]);

  const ApplicantProfile = profileList
    .filter(item => {
      return item.applicationStatusId !== 8;
    })
    .map(item => {
      const showHeadline = item.privacySetting
        .filter(item => {
          return item.settingName === 'showHeadline';
        })
        .map(item => {
          return item.settingValue === 1;
        })[0];
      const showProfilePic = item.privacySetting
        .filter(item => {
          return item.settingName === 'showProfilePic';
        })
        .map(item => {
          return item.settingValue === 1;
        })[0];
      const showProfile = item.privacySetting
        .filter(item => {
          return item.settingName === 'showProfile';
        })
        .map(item => {
          return item.settingValue === 1;
        })[0];

      var statusColor = classes.statusUnsuccessful;
      // console.log(item);
      if (item.applicationStatusId === 1 || item.applicationStatusId === 4 || item.applicationStatusId === 7)
        statusColor = classes.statusSubmitted;
      else if (item.applicationStatusId === 2) statusColor = classes.statusReviewing;
      else if (item.applicationStatusId === 3) statusColor = classes.statusShortlisted;
      else if (item.applicationStatusId === 5) statusColor = classes.statusRejected;
      else if (item.applicationStatusId === 6) statusColor = classes.statusUnsuccessful;
      else if (item.applicationStatusId === 8) statusColor = classes.statusWithdraw;

      return (
        <GridItem
          xs={12}
          sm={12}
          md={12}
          key={item.applId}
          onClick={() => {
            viewProfile(item);
          }}
        >
          <Card className={classes.cardApplicantClick + ' ' + classes.cardHeight + ' ' + classes.cardSpace}>
            <CardBody>
              <GridContainer justifyContent="space-between" alignContent="center" alignItems="center">
                <GridItem>
                  <GridContainer alignContent="center" alignItems="center">
                    <GridItem xs={3} sm={2} md={2} lg={2} className={classes.itemCenter}>
                      <img
                        className={classes.cardImage + ' ' + classes.cardImageCandidate}
                        src={
                          showProfile
                            ? showProfilePic
                              ? item.profilePic
                                ? item.profilePic
                                : defaultAvatar
                              : defaultAvatar
                            : defaultAvatar
                        }
                        alt="..."
                      />
                    </GridItem>
                    <GridItem xs={9} sm={10} md={10} lg={10}>
                      <Hidden xsDown>
                        <div className={classes.rightAligned}>
                          <Primary>
                            <h6>{TimeFormat(item.applicationDate)}</h6>
                          </Primary>
                        </div>
                      </Hidden>
                      <h4 className={classes.title + ' ' + classes.titleInfo}>
                        {item.firstName}
                        {item.middleName ? ' ' + item.middleName + ' ,' : null} {item.surname}
                      </h4>
                      <div>
                        <React.Fragment>
                          {/* <div className={classes.iconWrapper}>
																<LocationOnRoundedIcon />
															</div> */}
                          <h4 className={classes.candidateDescription + ' ' + classes.titleLocation}>
                            {showProfile ? (item.countryName ? item.countryName : null) : null}
                          </h4>{' '}
                        </React.Fragment>
                      </div>
                      <h5 className={classes.description + ' ' + classes.descInfo}>
                        {showProfile ? (showHeadline ? (item.headline ? item.headline : '') : '') : ''}
                      </h5>
                      <div>
                        <Danger>
                          <p className={classes.description + ' ' + classes.applicationStatus + ' ' + statusColor}>
                            {item.applicationStatus}
                          </p>
                        </Danger>
                      </div>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              {/* {item.privacySetting
								.filter((item) => {
									return item.settingName === "showProfile";
								})
								.map((item) => {
									return item.settingValue === 1;
								})[0] ? (
								<React.Fragment>
									{item.privacySetting
										.filter((item) => {
											return item.settingName === "showProfilePic";
										})
										.map((item) => {
											return item.settingValue === 1;
										})[0] ? (
										<img
											className={classes.cardImage}
											src={item.profilePic ? item.profilePic : defaultAvatar}
											alt="..."
										/>
									) : (
										<img
											className={classes.cardImage}
											src={defaultAvatar}
											alt="..."
										/>
									)}

									{item.privacySetting
										.filter((item) => {
											return item.settingName === "showHeadline";
										})
										.map((item) => {
											return item.settingValue === 1;
										})[0] ? (
										<h5 className={classes.cardTitle}>{item.headline}</h5>
									) : (
										<h5 className={classes.cardTitle}>
											"{item.firstName}" is not yet ready to show "Headline"
										</h5>
									)}

									<div>
										<Primary>
											<h6>{TimeFormat(item.applicationDate)}</h6>
										</Primary>
									</div>
									<div>
										<Danger>
											<h6>{item.applicationStatus}</h6>
										</Danger>
									</div>
								</React.Fragment>
							) : (
								<React.Fragment>
									<img
										className={classes.cardImage}
										src={defaultAvatar}
										alt="..."
									/>
									<h4 className={classes.cardTitle}>
										{item.firstName}
										{item.middleName ? " " + item.middleName + " ," : null}{" "}
										{item.surname}
									</h4>
									<div>
										<Primary>
											<h6>{TimeFormat(item.applicationDate)}</h6>
										</Primary>
									</div>
									<div>
										<Danger>
											<h6>{item.applicationStatus}</h6>
										</Danger>
									</div>
								</React.Fragment>
							)} */}
            </CardBody>
          </Card>
        </GridItem>
      );
    });
  return (
    <React.Fragment>
      {ApplicantProfile}
      {applicantProfileModal && (
        <ApplicantProfileModal open={applicantProfileModal} onClose={onClose} selectedAppl={selectedAppl} />
      )}
    </React.Fragment>
  );
}
