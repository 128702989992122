import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { departmentViewRoadmap, viewRoadmapRoleByRoleId } from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Industry_Data_Artificial_Intelligence from "assets/img/Career_Roadmap/Data_Artificial_Intelligence/Industry_Data_Artificial_Intelligence_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function IndustryDataArtificialIntelligence() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Industry_Data_Artificial_Intelligence;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "bi",
				shape: "poly",
				coords: [81,145,75,146,70,149,67,151,64,154,62,158,61,164,61,203,62,208,64,211,67,215,70,218,77,221,169,222,176,220,180,217,184,214,187,210,189,203,189,164,188,158,184,153,181,149,176,146,171,145,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "de",
				shape: "poly",
				coords: [77,270,72,272,67,276,63,283,61,289,60,328,63,334,65,338,70,341,77,345,170,346,177,344,185,337,189,328,189,287,187,281,185,276,179,272,171,270,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "dsas",
				shape: "poly",
				coords: [79,395,75,396,71,397,66,401,62,406,59,412,59,467,62,475,65,479,70,483,77,486,169,486,176,485,179,481,184,477,188,468,188,410,185,405,181,401,175,397,169,395,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "aar",
				shape: "poly",
				coords: [78,521,72,523,67,526,65,531,62,538,61,594,63,599,66,604,72,609,78,611,172,612,179,609,183,606,187,602,190,595,189,536,186,529,183,526,179,522,172,521,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "mli",
				shape: "poly",
				coords: [79,663,72,666,68,668,64,673,62,678,62,721,63,727,67,732,71,735,77,738,172,740,178,737,184,733,187,729,190,720,190,680,187,673,184,669,179,665,171,663,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "180",
				shape: "poly",
				coords: [214,145,209,146,206,148,203,152,200,157,202,469,202,475,206,479,210,482,216,483,394,484,400,481,404,478,407,475,409,468,408,161,406,152,403,148,397,145,391,143,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "161",
				shape: "poly",
				coords: [570,145,564,146,560,149,557,153,556,157,556,211,557,216,559,221,563,223,571,227,1107,226,1113,223,1116,220,1118,216,1119,212,1119,156,1116,150,1113,147,1104,144,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "162",
				shape: "poly",
				coords: [1279,144,1273,147,1270,150,1268,153,1266,159,1267,216,1269,220,1271,223,1275,226,1281,227,1461,227,1465,224,1469,221,1472,217,1473,213,1474,157,1472,152,1469,148,1464,145,1459,144,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "164",
				shape: "poly",
				coords: [570,263,565,266,560,268,559,271,556,279,556,330,557,336,560,341,565,345,570,346,748,345,754,344,759,341,761,336,763,329,764,278,762,271,757,267,751,264,745,264,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "165",
				shape: "poly",
				coords: [925,263,918,265,915,268,913,272,911,277,911,332,912,336,914,340,918,342,924,345,1106,345,1110,342,1114,340,1117,336,1118,331,1118,275,1117,271,1113,266,1108,263,1102,262,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "166",
				shape: "poly",
				coords: [1278,263,1272,265,1270,268,1267,272,1265,277,1266,332,1267,337,1269,340,1273,342,1279,345,1462,344,1465,341,1469,340,1471,335,1472,330,1472,274,1471,270,1468,266,1463,264,1458,262,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "174",
				shape: "poly",
				coords: [571,396,564,398,560,400,557,405,554,410,555,464,556,468,558,473,564,476,570,478,1111,477,1118,475,1122,472,1124,468,1126,463,1125,409,1123,403,1119,399,1113,396,1107,395,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "181",
				shape: "poly",
				coords: [1278,392,1274,393,1271,396,1268,400,1267,404,1267,728,1268,732,1271,736,1276,738,1282,739,1457,739,1463,738,1466,736,1470,733,1472,729,1473,725,1473,407,1472,400,1469,396,1464,393,1458,391,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "182",
				shape: "poly",
				coords: [1636,391,1632,393,1629,396,1628,401,1627,405,1627,462,1630,467,1634,470,1638,472,1644,473,1820,473,1823,472,1828,469,1831,466,1834,462,1835,457,1834,404,1833,398,1829,394,1823,391,1818,390,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "177",
				shape: "poly",
				coords: [219,524,210,526,207,528,204,531,202,535,201,539,201,593,203,599,206,602,210,605,213,607,1110,606,1117,604,1121,601,1124,597,1125,590,1125,540,1124,534,1121,529,1116,526,1110,524,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "168",
				shape: "poly",
				coords: [215,656,209,658,205,661,203,666,201,671,201,726,203,732,206,735,211,738,215,740,396,739,400,737,404,734,406,730,408,725,408,673,407,667,404,661,397,657,391,656,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "169",
				shape: "poly",
				coords: [571,656,565,657,561,660,559,664,557,668,557,726,559,731,561,734,565,737,571,739,749,738,755,738,760,734,762,731,765,725,765,671,763,663,759,659,753,657,747,655,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "170",
				shape: "poly",
				coords: [934,655,927,657,923,659,921,661,918,666,917,670,917,727,918,732,921,735,925,738,932,740,1108,739,1114,738,1118,736,1121,733,1123,728,1125,723,1124,672,1123,666,1119,661,1114,657,1109,656,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "bi") {
			dispatch(departmentViewRoadmap(23));
		} else if (area.name === "de") {
			dispatch(departmentViewRoadmap(24));
		} else if (area.name === "dsas") {
			dispatch(departmentViewRoadmap(26));
		} else if (area.name === "aar") {
			dispatch(departmentViewRoadmap(27));
		} else if (area.name === "mli") {
			dispatch(departmentViewRoadmap(25));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo)
			dispatch(viewRoadmapRoleByRoleId(requestInfo));
			
			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// console.log(coords.x + "," + coords.y + ",");
		// navigator.clipboard.writeText(coords.x + "," + coords.y + ",");
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
