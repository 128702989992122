import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import { ListItemIcon } from '@mui/material';
import { Box, IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { encryptData, userGroupRoles } from 'configurations/configurations';
import ConfirmModal from './ConfirmModal';
import { useModal } from 'hooks';
import Pagination from 'views/Component/PaginationWithPage.js';
import { Link } from 'react-router-dom';

const MemberMoreButton = React.memo(({ actions, user }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const confirmModalProps = useModal();
  const [bodyContent, setBodyContent] = React.useState({ title: '', text: '' });
  const [handleSubmit, setHandleSubmit] = React.useState({ click: () => {} });

  const onMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const onMenuClose = () => {
    setAnchorEl(null);
  };

  const onMenuItemClick = option => {
    setHandleSubmit({
      click: () => {
        option.onClick(user).then(() => {
          onMenuClose();
          confirmModalProps.setModalOpen(false);
        });
      },
    });
    setBodyContent(option.getConfirmModalContent(user));
    confirmModalProps.setModalOpen(true);
  };
  return (
    <>
      <IconButton
        onClick={onMenuClick}
        id="group-action-button"
        aria-label="group-action-button"
        aria-controls={open ? 'group-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="group-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onMenuClose}
        MenuListProps={{
          'aria-labelledby': 'group-action-button',
        }}
      >
        {actions.map((option, index) => {
          const showOption = option.roles.find(r => r === user.roleId);
          if (showOption) {
            return (
              <MenuItem onClick={() => onMenuItemClick(option)} key={index}>
                <option.Icon />
                {option.text}
              </MenuItem>
            );
          }
        })}
      </Menu>
      <ConfirmModal {...{ ...confirmModalProps, bodyContent }} handleSubmit={handleSubmit.click} />
    </>
  );
});

export default function MemberList({ users, actions = [], currentUser, perPageCount, paginate }) {
  const [checked, setChecked] = React.useState([]);
  const isUserRoleMember = currentUser.roleId === userGroupRoles.MEMBER.id;
  const onCheckboxClick = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <>
      <List dense sx={{ width: '100%', height: '450px', overflow: 'auto', padding: '8px 0px 0px 8px' }}>
        {users && users.length > 0 ? (
          users.map((user, index) => {
            const labelId = `manage-group-list-item-${user.groupUserId}`;
            const roleName = Object.values(userGroupRoles).find(u => u.id === user.roleId).name;
            const isUserCurrent = user.groupUserId === currentUser.groupUserId;
            const encryptedAcctId = encryptData(user?.acctId);
            return (
              <ListItem
                divider
                key={index}
                disablePadding
                secondaryAction={!isUserRoleMember && !isUserCurrent && <MemberMoreButton {...{ user, actions }} />}
              >
                {/* <ListItemIcon>
                  <Checkbox
                    edge="end"
                    onChange={onCheckboxClick(index)}
                    checked={checked.indexOf(index) !== -1}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon> */}
                <ListItemAvatar>
                  <Link style={{ color: 'inherit' }} to={`/view-profile/${encryptedAcctId}`}>
                    <Avatar alt={`Avatar n°${user.groupUserId}`} src={user.profilePic} />
                  </Link>
                </ListItemAvatar>
                <Link style={{ color: 'inherit' }} to={`/view-profile/${encryptedAcctId}`}>
                  <ListItemText id={labelId} primary={`${user.firstName} ${user.surname}`} secondary={roleName} />
                </Link>
              </ListItem>
            );
          })
        ) : (
          <p style={{ textAlign: 'center', fontWeight: '500' }}>No Pending User Found.</p>
        )}
      </List>
      {users.length > 0 && (
        <Box width="100%" bgcolor="#EFF3FE">
          <Pagination totalPosts={users[0].totalCount} postsPerPage={perPageCount} paginate={paginate} />
        </Box>
      )}
    </>
  );
}
