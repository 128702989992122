import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import termsConditionsPageStyle from "assets/jss/material-kit-pro-react/components/termsConditionsPageStyle.js";
import classNames from "classnames";
// import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Accordion from "components/Accordion/Accordion.js";
import Header from "views/Component/Header.js";
import Footer from "views/Component/Footer.js";
import { gaPageView } from "configurations/configurations";
const useStyles = makeStyles(termsConditionsPageStyle);

export default function TermsConditionsPage({ ...rest }) {
	const classes = useStyles();
	React.useEffect(() => {
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;
	});

	React.useEffect(() => {
		gaPageView(window.location.pathname);
	}, []);
	return (
		<div>
			<Header />
			<Parallax
				image={require("assets/img/bg8.jpg")}
				filter="dark"
				className={classes.parallax}
			>
				<div className={classes.container}>
					<GridContainer>
						<GridItem
							xs={12}
							sm={8}
							md={8}
							className={classNames(
								classes.mlAuto,
								classes.mrAuto,
								classes.textCenter
							)}
						>
							<h1 className={classes.whiteTitle}>Privacy Policy</h1>
						</GridItem>
					</GridContainer>
				</div>
			</Parallax>
			<div className={classes.main + " " + classes.mainRaised}>
				<div className={classes.container}>
					<GridContainer>
						<GridItem xs={12} sm={12} md={12}>
							<h3 className={classes.blackTitle}>Privacy Policy</h3>
							<h6 className={classes.description}>
								PLEASE READ THESE PRIVACY POLICY OF USE CAREFULLY BEFORE USING
								THIS WEB SITE.
							</h6>
							<Accordion
								active={0}
								className={classes.accordionStyle}
								activeColor="success"
								collapses={[
									{
										title: (
											<h3 className={classes.inheritTitle}>1.Personal Data</h3>
										),
										content: (
											<div>
												<p className={classes.description}>
													You hereby agree that the Group may collect, store,
													process, disclose, access, review and/or use personal
													data (including sensitive personal data) about you,
													whether obtained from you or from other sources, for
													the purposes set out below and/or any other
													administrative or operational purposes and/or the
													purpose of managing your relationship as a customer
													with the Company or any other company within the
													Group:-
												</p>
												<p className={classes.description}>
													(i) providing services to you in relation to your
													sales transactions with the Group;
													<br />
													(ii) enabling your use of the services which the Group
													provides, including, where necessary, contacting you
													(whether by SMS, email or otherwise);
													<br />
													(iii) dealing with enquiries made by you;
													<br />
													(iv) the provision of warranty services;
													<br />
													(v) maintenance and updating of the data;
													<br />
													(vi) statistical analysis;
													<br />
													(vii) administrative or operational purposes;
													<br />
													(viii) tax filing preparation;
													<br />
													(ix) processing credit notes and processing refunds;
													<br />
													(x) collection of fees, charges and expenses for
													services provided;
													<br />
													(xi) verification and identification purposes;
													<br />
													(xii) facilitating the making and payment of claims,
													including payments by cheque, bank transfers or other
													means;
													<br />
													(xiii) carrying out billing, accounting, auditing and
													the maintenance of proper book-keeping to explain the
													Company’s operations and business; and/or
													<br />
													(xiv) the disclosure of the relevant books, documents,
													records and information (in hard or soft copy) to the
													auditors for the preparation of financial reports.
												</p>
											</div>
										),
									},
								]}
							/>
							<Accordion
								className={classes.accordionStyle}
								activeColor="success"
								collapses={[
									{
										title: (
											<h3 className={classes.inheritTitle}>2.Data Quality</h3>
										),
										content: (
											<div>
												<p className={classes.description}>
													The Company will take reasonable steps to make sure
													that the personal data it collects, uses or discloses
													is accurate, complete and up to date.
												</p>
											</div>
										),
									},
								]}
							/>
							<Accordion
								className={classes.accordionStyle}
								activeColor="success"
								collapses={[
									{
										title: (
											<h3 className={classes.inheritTitle}>3.Data Security</h3>
										),
										content: (
											<div>
												<p className={classes.description}>
													The Company will take reasonable steps to protect the
													personal data it holds from misuse and loss and from
													unauthorized access, modification or disclosure.
												</p>
												<p className={classes.description}>
													The Company will not keep personal data for longer
													than is necessary and will take reasonable steps to
													destroy or permanently de-identify personal data if it
													is no longer needed.
												</p>
											</div>
										),
									},
								]}
							/>
							<Accordion
								className={classes.accordionStyle}
								activeColor="success"
								collapses={[
									{
										title: (
											<h3 className={classes.inheritTitle}>
												4.Access and Correction
											</h3>
										),
										content: (
											<div>
												<p className={classes.description}>
													You are entitled to have access to the personal data
													about you that is in the possession or under the
													control of the organization and information about the
													ways in which the personal data has been or may have
													been used or disclosed within a year before the date
													of the request. This can be done by you making a
													written application to the Designated Person (as
													defined below) requesting for any such information.
													The Company reserves the right to charge a fee
													(representing its costs in administering your request)
													for supplying such information and to refuse requests
													which, in its opinion, occur with unreasonable
													frequency.
												</p>
												<p className={classes.description}>
													The Company will also, where you have requested that
													it correct an error or omission in the personal data
													about you that is kept with the Company, correct such
													data as soon as practicable and send the corrected
													personal data to every organization to which the
													personal data was sent before it had been corrected,
													if applicable, unless that organization does not need
													the corrected personal data for any legal or business
													purpose.
												</p>
												<p className={classes.description}>
													The Company may however choose not to provide you with
													access to or correct such information, in accordance
													with the exceptions under the PDPA. This would include
													cases where:-
												</p>
												<p className={classes.description}>
													(i) The Company is satisfied on reasonable grounds
													that the correction should not be made;
													<br />
													(ii) The request for access is frivolous or vexatious
													or the information requested is trivial;
													<br />
													(iii) The personal data is related to a prosecution
													and all the proceedings related to the prosecution
													have not been completed;
													<br />
													(iv) The personal data, if disclosed, would reveal
													confidential commercial information that could, in the
													opinion of a reasonable person, harm the competitive
													position of the organisation; and
													<br />
													(v) The personal data was collected, used or disclosed
													for the purposes of an investigation and associated
													proceedings and appeals have not been completed.
												</p>
											</div>
										),
									},
								]}
							/>
							<Accordion
								className={classes.accordionStyle}
								activeColor="success"
								collapses={[
									{
										title: (
											<h3 className={classes.inheritTitle}>
												5.Transborder Data Flows
											</h3>
										),
										content: (
											<div>
												<p className={classes.description}>
													If your personal data is transferred to a country or
													territory outside Singapore, for instance, if your
													personal data is required for administrative or
													operational purposes in other companies within the
													Group, we will ensure that the recipients thereof
													provide a standard of protection to your personal data
													so transferred that is comparable to that which is
													provided herein.
												</p>
											</div>
										),
									},
								]}
							/>
							<Accordion
								className={classes.accordionStyle}
								activeColor="success"
								collapses={[
									{
										title: (
											<h3 className={classes.inheritTitle}>
												6.Enquiries and Complaints
											</h3>
										),
										content: (
											<div>
												<p className={classes.description}>
													The Company has designated the person whose details
													are set out below as the person (“Designated Person”)
													who will be responsible for ensuring the Company’s
													compliance with applicable data protection laws. If
													you have any queries or requests or wish to make any
													applications concerning your personal information or
													data, please contact the Designated Person:-
												</p>
												<p className={classes.description}>
													[Contact Details of Designated Person]
												</p>
											</div>
										),
									},
								]}
							/>
						</GridItem>
					</GridContainer>
				</div>
			</div>

			<Footer />
		</div>
	);
}
