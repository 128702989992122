import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import ProfileStrengthTip from "./ProfileStrengthTip.js";

// core components
import Button from "components/CustomButtons/Button.js";

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import Close from "@material-ui/icons/Close";

import ImageCrop from "../../../Component/ImageCrop/ImageCrop.js";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js";
import style from "assets/jss/material-kit-pro-react/modalStyle.js";
import IconLibrary from "views/Component/IconLibrary.js";

const useStyles = makeStyles(profilePageStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

const ProfilePictureEditModal = ({
	open,
	onClose,
	profilePic,
	userStatus,
	extension,
}) => {
	const classes = useStyles();
	console.log(extension);
	return (
		<React.Fragment>
			<Dialog
				classes={{
					root: classes.modalRoot,
					paper: classes.modalProfilePicEdit + " " + classes.modal,
				}}
				open={open}
				// TransitionComponent={Transition}
				// keepMounted

				onClose={onClose}
				aria-labelledby="classic-modal-slide-title"
				aria-describedby="classic-modal-slide-description"
			>
				<DialogTitle
					id="classic-modal-slide-title"
					disableTypography
					className={classes.modalTitleEdit}
				>
					<Button
						simple
						className={classes.modalCloseButton}
						key="close"
						aria-label="Close"
						onClick={onClose}
					>
						<IconLibrary.Close />
					</Button>
					<h3 className={classes.title + " " + classes.titleAlign}>
						Edit your profile picture
					</h3>
				</DialogTitle>
				<DialogContent
					id="classic-modal-slide-description"
					className={classes.modalBody}
				>
					{extension && <ProfileStrengthTip extension={extension} />}

					<ImageCrop
						pictureSource={profilePic}
						requestRecord={userStatus}
						onClose={onClose}
					/>
				</DialogContent>
				<div className={classes.modalFooter}></div>
			</Dialog>
		</React.Fragment>
	);
};
const mapStateToProps = (state) => ({
	userStatus: state.auth.user,
	profilePic: state.user.profilePic,
});
export default connect(mapStateToProps, null)(ProfilePictureEditModal);
