import React from 'react';
import SelectOption from 'react-select';

import { useSelector, useDispatch } from 'react-redux';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import searchPageStyle from 'assets/jss/material-kit-pro-react/views/pageStyle/SearchPage/searchPageStyle';
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import TextField from '@material-ui/core/TextField';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import {
  getCountryId,
  getLocationId,
  getIndustryId,
  getJobPostType,
  getJobType,
  getLocationIdByCountry,
  getExperienceLevelList,
  getCurrencyId,
  getJobTypeIdByJobPostType,
} from 'configurations/configurations.js';
import { Button } from '@material-ui/core';
import axios from 'axios';
import { instanceUrl } from 'configurations/configurations.js';
import AsyncSelect from 'react-select/async';
import { saveKeyword } from 'configurations/redux/genericSearchSlice.js';
import Checkbox from '@material-ui/core/Checkbox';
import Accordion from 'components/Accordion/Accordion';
import { searchProfileInfo } from 'configurations/redux/searchProfileSlice.js';
import { searchJobInfo } from 'configurations/redux/searchJobSlice.js';
import { saveParams, searchPost } from 'configurations/redux/searchPostSlice';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  getArticleCategory,
  getArticleList,
  getArticleListByKeyword,
  removeAllTag,
  addAllTag,
  searchTagAdd,
  searchTagRemove,
  // setKeyword,
  getCardAds,
  getBannerAds,
  // getCardAdsCount,
} from 'configurations/redux/communitySlice.js';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { getSocialPostByRecent } from 'configurations/redux/socialFeedSlice';
const useStyles = makeStyles(searchPageStyle);

export default function FilterDrawer({ match, setDrawer }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const option = useSelector(state => state.genericSearch.option);
  const oriKeyword = useSelector(state => state.genericSearch.keyword);
  const [keyword, setKeyword] = React.useState(oriKeyword);
  const userStatus = useSelector(state => state.auth.user);
  const oriPreviousPostListType = localStorage.getItem('postListType');
  const oriPostSearchParams = useSelector(state => state.searchPost.searchParams);
  const [postSearchParams, setPostSearchParams] = React.useState(oriPostSearchParams);
  const oriProfileSearchParams = useSelector(state => state.searchProfile.searchParams);
  const [profileSearchParams, setProfileSearchParams] = React.useState(oriProfileSearchParams);
  const oriJobSearchParams = useSelector(state => state.searchJob.searchParams);
  const [jobSearchParams, setJobSearchParams] = React.useState(oriJobSearchParams);
  const [countryList, setCountryList] = React.useState([]);
  const [locationList, setLocationList] = React.useState([]);
  const [industryList, setIndustryList] = React.useState([]);
  const [jobPostTypeList, setJobPostType] = React.useState([]);
  const [jobTypeList, setJobType] = React.useState([]);
  const [experienceLevelList, setExperienceLevelList] = React.useState([]);
  const [currencyList, setCurrencyList] = React.useState([]);
  const auth = useSelector(state => state.auth);
  const defaultFilterTag = useSelector(state => state.community.defaultFilterTag);

  const searchFilterTag = useSelector(state => state.community.searchFilterTag);
  const searchTag = useSelector(state => state.community.searchTag);

  const defaultTag = useSelector(state => state.community.defaultTag);
  const bizId = useSelector(state => state.business.bizId);
  const [countryChange, setCountryChange] = React.useState();
  const [jobPostTypeChange, setJobPostTypeChange] = React.useState();
  React.useEffect(() => {
    fetchCountryList();
    fetchIndustryList();
    fetchJobPostTypeList();
    /* 	fetchJobTypeList(); */
    fetchExperienceLevelList();
    fetchCurrencyList();

    return () => {
      setCountryList([]);
      setLocationList([]);
      setIndustryList([]);
      setJobPostType([]);
      setJobType([]);
      setExperienceLevelList([]);
      setCurrencyList([]);
    };
  }, []);

  const connectionSelectStyles = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: 500,
      fontSize: '14px',
      backgroundColor: state.isSelected ? '#687bfe' : 'white',
      color: state.isSelected ? 'white' : 'black',

      color: 'black',
      cursor: 'pointer',
    }),
    input: (provided, state) => ({
      ...provided,
      fontWeight: 700,
      fontSize: '14px',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontWeight: 500,
      fontSize: '14px',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontWeight: 500,
      fontSize: '14px',
    }),
    control: (provided, state) => ({
      ...provided,
      height: '40px',
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      fontWeight: 500,
      fontSize: '14px',
    }),
    loadingMessage: (provided, state) => ({
      ...provided,
      fontWeight: 500,
      fontSize: '14px',
    }),
    menu: (provided, state) => ({ ...provided, zIndex: 9999 }),
  };

  const searchCount = mainCatId => {
    var tag = searchTag
      .filter(item => {
        return item.mainCatId === mainCatId;
      })
      .map(item => {
        return item.subCat.length;
      });
    return tag[0];
  };
  const defaultCount = mainCatId => {
    var tag = defaultTag
      .filter(item => {
        return item.mainCatId === mainCatId;
      })
      .map(item => {
        return item.subCat.length;
      });
    return tag[0];
  };
  const addTag = (mainCatId, subCatId) => {
    // console.log("add");
    dispatch(searchTagAdd(mainCatId, subCatId, searchTag, defaultTag, searchFilterTag, defaultFilterTag));
  };

  const addAll = mainCatId => {
    // console.log("add");
    dispatch(addAllTag(mainCatId, searchTag, defaultTag, searchFilterTag, defaultFilterTag));
  };

  const deleteTag = (mainCatId, subCatId) => {
    // console.log("remove");
    dispatch(searchTagRemove(mainCatId, subCatId, searchTag, searchFilterTag, defaultFilterTag));
  };

  const deleteAll = mainCatId => {
    // console.log("remove");
    dispatch(removeAllTag(mainCatId, searchTag, defaultTag, searchFilterTag, defaultFilterTag));
  };
  const checkTag = subCatId => {
    return searchTag.some(item => {
      return item.subCat.some(ele => {
        return ele.subCatId === subCatId;
      });
    });
  };
  const fetchCountryList = async () => {
    const res = await getCountryId();
    var list = [];
    list.push({ value: -1, label: 'All' });
    for (var i = 0; i < res.data.payload.length; i++) {
      list.push({
        value: res.data.payload[i].countryId,
        label: res.data.payload[i].countryName,
      });
    }
    setCountryList(list);
  };
  const fetchExperienceLevelList = async () => {
    const res = await getExperienceLevelList();
    var list = [];
    list.push({ value: -1, label: 'All' });
    for (var i = 0; i < res.data.payload.length; i++) {
      list.push({
        value: res.data.payload[i].experienceId,
        label: res.data.payload[i].experience,
      });
    }
    setExperienceLevelList(list);
  };
  // console.log(getCurrencyId());
  const fetchCurrencyList = async () => {
    const res = await getCurrencyId();

    var list = [];
    list.push({ value: -1, label: 'All' });
    for (var i = 0; i < res.data.payload.length; i++) {
      list.push({
        value: res.data.payload[i].currencyId,
        label: res.data.payload[i].currencyCode,
      });
    }
    setCurrencyList(list);
  };

  const fetchIndustryList = async () => {
    const res = await getIndustryId();
    var list = [];
    list.push({ value: -1, label: 'All' });
    for (var i = 0; i < res.data.payload.length; i++) {
      list.push({
        value: res.data.payload[i].industryId,
        label: res.data.payload[i].industryName,
      });
    }
    setIndustryList(list);
  };
  const fetchJobPostTypeList = async () => {
    const res = await getJobPostType();
    var list = [];
    list.push({ value: -1, label: 'All' });
    for (var i = 0; i < res.data.payload.length; i++) {
      list.push({
        value: res.data.payload[i].jobPostTypeId,
        label: res.data.payload[i].jobPostType,
      });
    }
    setJobPostType(list);
  };

  React.useEffect(() => {
    // console.log(countryChange);
    if (countryChange) {
      const fetchLocationListByCountry = async countryId => {
        const res = await getLocationIdByCountry(countryId);
        var list = [];
        list.push({ locationId: -1, locationName: 'All' });
        for (var i = 0; i < res.data.payload.length; i++) {
          list.push({
            value: res.data.payload[i].locationId,
            label: res.data.payload[i].locationName,
          });
        }
        setLocationList(list);
      };
      fetchLocationListByCountry(countryChange);
    }
  }, [countryChange]);

  React.useEffect(() => {
    if (jobPostTypeChange) {
      const fetchJobTypeByJobPostType = async jobPostTypeId => {
        const res = await getJobTypeIdByJobPostType(jobPostTypeId);
        var list = [];
        list.push({ value: -1, label: 'All' });
        for (var i = 0; i < res.data.payload.length; i++) {
          list.push({
            value: res.data.payload[i].jobTypeId,
            label: res.data.payload[i].jobType,
          });
        }
        setJobType(list);
      };
      fetchJobTypeByJobPostType(jobPostTypeChange);
    }
  }, [jobPostTypeChange]);

  const selectInputRef = React.useRef();
  const onClear = () => {
    selectInputRef.current.select.clearValue();
  };

  React.useEffect(() => {
    if (jobSearchParams && (match.path === '/search-page-job' || match.path === '/public-search-page-job')) {
      if (jobSearchParams?.countryId > -1 && jobSearchParams?.locationId > -1) {
        setCountryChange(jobSearchParams.countryId);
      }
      if (jobSearchParams?.jobPostTypeId > -1 && jobSearchParams?.jobTypeId > -1) {
        setJobPostTypeChange(jobSearchParams.jobPostTypeId);
      }
    }
    if (
      oriProfileSearchParams &&
      (match.path === '/search-page-people' || match.path === '/public-search-page-people')
    ) {
      if (oriProfileSearchParams?.countryId > -1 && oriProfileSearchParams?.locationId > -1) {
        setCountryChange(oriProfileSearchParams.countryId);
      }
    }
  }, []);

  const handleResetFilter = () => {
    if (match.path === '/search-page-post' || match.path === '/public-search-page-post') {
      setPostSearchParams([]);
      setKeyword('');
    }
    if (match.path === '/search-page-people' || match.path === '/public-search-page-people') {
      setProfileSearchParams([]);
      setKeyword('');
    }
    if (match.path === '/search-page-job' || match.path === '/public-search-page-job') {
      setJobSearchParams([]);
      setKeyword('');
    }
    if (match.path === '/search-page-article' || match.path === '/public-search-page-article') {
      addAll(1);
      setKeyword('');
    }
  };

  const PeopleDrawer = () => {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <Formik
          initialValues={{
            keyword: keyword ? keyword : '',
            countryId: profileSearchParams?.countryId ? profileSearchParams.countryId : '',
            locationId: profileSearchParams?.locationId ? profileSearchParams.locationId : '',
            locationList: [],
            isLoading: false,
          }}
          onSubmit={value => {
            const requestProfile = {
              acctId: auth.isPublic ? 0 : userStatus.acctId,
              accessToken: auth.isPublic ? auth.publicToken : localStorage.getItem('token'),
              email: auth.isPublic ? auth.publicEmail : userStatus.email,
              keyword: value.keyword,
              countryId: value.countryId,
              locationId: value.locationId,
              lastRecordSize: 0,
              requestRecordSize: 5,
              genericSearch: true,
            };
            dispatch(searchProfileInfo(requestProfile));
            const response = {
              keyword: value.keyword,
              countryId: value.countryId,
              locationId: value.locationId,
            };
            dispatch(saveKeyword(response));
            setDrawer(false);
          }}
        >
          {({ values, handleSubmit, setFieldValue }) => (
            <div
              onSubmit={handleSubmit}
              style={{
                height: '100%',

                margin: '0px !important',
              }}
            >
              <div className={classes.cardFrameFilter}>
                <CardBody>
                  <GridContainer>
                    <GridItem className={classes.autoCompleteSelect}>
                      <h4 className={classes.title}>Keyword</h4>
                      <TextField
                        label="Keyword"
                        id="keyword"
                        variant="outlined"
                        name="keyword"
                        value={values.keyword}
                        onChange={event => {
                          setFieldValue('keyword', event.target.value);
                        }}
                        fullWidth
                      />
                    </GridItem>
                    <GridItem className={classes.autoCompleteSelect}>
                      <h4 className={classes.title}>Country</h4>

                      <SelectOption
                        options={countryList}
                        styles={connectionSelectStyles}
                        formatOptionLabel={list => (
                          <div>
                            <span>{list.label}</span>
                          </div>
                        )}
                        defaultValue={
                          countryList.filter(item => {
                            return item.value === values.countryId;
                          })[0] || null
                        }
                        onChange={(event, target) => {
                          setFieldValue('countryId', event.value);
                          setFieldValue('locationId', '');
                        }}
                        placeholder="Country"
                      />
                    </GridItem>

                    <GridItem className={classes.autoCompleteSelect}>
                      <h4 className={classes.title}>Location</h4>
                      <AsyncSelect
                        /* 	defaultOption={locationList} */
                        defaultOptions={values.locationList.length > 0 ? values.locationList : locationList}
                        styles={connectionSelectStyles}
                        formatOptionLabel={list => (
                          <div>
                            <span>{list.label}</span>
                          </div>
                        )}
                        value={
                          values.locationList.length > 0
                            ? values.locationList.filter(item => {
                                return item.value === values.locationId;
                              })[0] || null
                            : locationList.filter(item => {
                                return item.value === values.locationId;
                              })[0] || null
                        }
                        onFocus={() => {
                          setFieldValue('isLoading', true);
                          return new Promise((resolve, reject) => {
                            axios
                              .get(`${instanceUrl}/api/md/get-all-location-by-country`, {
                                cancelToken: undefined,
                                params: { countryId: values.countryId },
                              })
                              .then(res => {
                                var list = [];
                                list.push({ value: -1, label: 'All' });
                                if (res.status === 201) {
                                  for (var i = 0; i < res.data.payload.length; i++) {
                                    list.push({
                                      value: res.data.payload[i].locationId,
                                      label: res.data.payload[i].locationName,
                                    });
                                  }
                                }
                                setFieldValue('locationList', list);
                                setFieldValue('isLoading', false);

                                resolve(list);
                              })
                              .catch(reject);
                          });
                        }}
                        isLoading={values.isLoading}
                        onChange={(event, target) => {
                          console.log(event);
                          setFieldValue('locationId', event.value);
                        }}
                        placeholder="Location"
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </div>
              <div className={classes.buttonContainer}>
                <Button style={{ marginRight: '10px' }} className={classes.resetButton} onClick={handleResetFilter}>
                  Reset
                </Button>
                <Button style={{ marginRight: '10px' }} className={classes.filterButton} onClick={handleSubmit}>
                  Filter
                </Button>
              </div>
            </div>
          )}
        </Formik>
      </div>
    );
  };
  const ArticleDrawer = () => {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <Formik
          initialValues={{
            keyword: keyword ? keyword : '',
          }}
          onSubmit={value => {
            var mainCat = '0,';

            searchTag.forEach(tag => (mainCat = mainCat + tag.mainCatId + ','));
            mainCat = mainCat.substring(0, mainCat.length - 1);
            var subCat = '0,';
            searchTag.forEach(tag => tag.subCat.forEach(ele => (subCat = subCat + ele.subCatId + ',')));
            subCat = subCat.substring(0, subCat.length - 1);
            const requestProfile = {
              acctId: userStatus.acctId,
              acctTypeId: bizId > 0 ? 2 : userStatus.acctTypeId,
              accessToken: localStorage.getItem('token'),
              email: userStatus.email,
              categoryIds: mainCat,
              subCategoryIds: subCat,
              lastRecordSize: 0,
              requestRecordSize: 12,
              paginationClick: false,
              defaultLoad: false,
              keyword: value.keyword,
            };
            dispatch(getArticleList(requestProfile));
            const response = {
              keyword: value.keyword,
              categoryIds: mainCat,
              subCategoryIds: subCat,
            };
            dispatch(saveKeyword(response));
            setDrawer(false);
          }}
        >
          {({ values, handleSubmit, setFieldValue }) => (
            <div
              onSubmit={handleSubmit}
              style={{
                height: '100%',

                margin: '0px !important',
              }}
            >
              <div className={classes.cardFrameFilter}>
                <CardBody>
                  <GridContainer>
                    <GridItem className={classes.autoCompleteSelect}>
                      <h4 className={classes.title}>Keyword</h4>
                      <TextField
                        label="Keyword"
                        id="keyword"
                        variant="outlined"
                        name="keyword"
                        value={values.keyword}
                        onChange={event => {
                          setFieldValue('keyword', event.target.value);
                        }}
                        fullWidth
                      />
                      <h4 className={classes.title}>Filter Tag</h4>
                      {defaultTag.map((item, index) => {
                        return (
                          <div className={classes.autoCompleteSelect} key={item.mainCatId}>
                            <Accordion
                              /* active={1} */
                              active={window.innerWidth <= 10 ? 1 : 0}
                              className={classes.accordionStyle}
                              rootClass={classes.accordionTitle}
                              divRootClass={classes.accordionRoot}
                              accordionDetailClass={classes.accordionDetailClass}
                              activeColor="secondary"
                              collapses={[
                                {
                                  title: (
                                    <div>
                                      <FormControlLabel
                                        classes={{
                                          label: classes.label,
                                        }}
                                        key={item.mainCatId}
                                        control={
                                          <Checkbox
                                            //tabIndex={-1}
                                            indeterminate={
                                              searchCount(item.mainCatId) > 0 ||
                                              searchCount(item.mainCatId) === defaultCount(item.mainCatId)
                                                ? defaultCount(item.mainCatId) > searchCount(item.mainCatId) && true
                                                : null
                                            }
                                            name={item.mainCat}
                                            onClick={e => {
                                              e.stopPropagation();
                                              if (searchCount(item.mainCatId) === defaultCount(item.mainCatId)) {
                                                deleteAll(item.mainCatId);
                                              } else {
                                                addAll(item.mainCatId);
                                              }
                                            }}
                                            classes={{
                                              checked: classes.opptyColor,
                                              root: classes.checkRoot + ' ' + classes.checkOpptyColor,
                                            }}
                                            checked={
                                              searchCount(item.mainCatId) === defaultCount(item.mainCatId) && true
                                            }
                                          />
                                        }
                                      />
                                      <span className={classes.accordionLabel}>{item.mainCat}</span>
                                    </div>
                                  ),
                                  content: item.subCat.map((ele, index) => {
                                    return (
                                      <div key={item.mainCatId + '-' + ele.subCatId}>
                                        <FormControlLabel
                                          classes={{
                                            label: classes.label,
                                          }}
                                          key={item.mainCatId + '-' + ele.subCatId}
                                          control={
                                            <Checkbox
                                              //tabIndex={-1}
                                              name={item.desc}
                                              onClick={() => {
                                                if (checkTag(ele.subCatId)) {
                                                  deleteTag(item.mainCatId, ele.subCatId);
                                                } else {
                                                  addTag(item.mainCatId, ele.subCatId);
                                                }
                                              }}
                                              classes={{
                                                checked: classes.opptyColor,
                                                root: classes.checkRoot + ' ' + classes.checkOpptyColor,
                                              }}
                                              checked={checkTag(ele.subCatId)}
                                            />
                                          }
                                          label={<span style={{ color: '#000000' }}>{ele.subCat}</span>}
                                        />
                                      </div>
                                    );
                                  }),
                                },
                              ]}
                            />
                          </div>
                        );
                      })}
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </div>
              <div className={classes.buttonContainer}>
                <Button style={{ marginRight: '10px' }} className={classes.resetButton} onClick={handleResetFilter}>
                  Reset
                </Button>
                <Button style={{ marginRight: '10px' }} className={classes.filterButton} onClick={handleSubmit}>
                  Filter
                </Button>
              </div>
            </div>
          )}
        </Formik>
      </div>
    );
  };
  const PostDrawer = () => {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <Formik
          initialValues={{
            keyword: keyword ? keyword : '',
            sortBy: postSearchParams?.sortBy ? postSearchParams.sortBy : 'recent',
          }}
          onSubmit={value => {
            console.log(value);
            if (auth.isPublic) {
              const requestProfile = {
                acctId: 0,
                accessToken: auth.publicToken,
                email: auth.publicEmail,
                keyword: value.keyword,
                lastRecordSize: 0,
                requestRecordSize: 5,
                hashId: 0,
                userId: 0,
                groupId: 0,
                pageId: 0,
                sortBy: value.sortBy,
                visibleIds: '1',
              };
              dispatch(searchPost(requestProfile));
            } else {
              const requestProfile = {
                acctId: userStatus.acctId,
                accessToken: localStorage.getItem('token'),
                email: userStatus.email,
                lastRecordSize: 0,
                requestRecordSize: 3,
                hashId: 0,
                userId: 0,
                groupId: 0,
                pageId: 0,
                sortBy: value.sortBy,
                keyword: value.keyword,
                visibleIds: '1,2',
              };
              console.log(requestProfile);
              dispatch(saveParams(requestProfile));
              dispatch(getSocialPostByRecent(requestProfile));
            }
            localStorage.setItem('postListType', value.sortBy);
            const response = {
              keyword: value.keyword,
            };
            dispatch(saveKeyword(response));
            setDrawer(false);
          }}
        >
          {({ values, handleSubmit, setFieldValue }) => (
            <div
              onSubmit={handleSubmit}
              style={{
                height: '100%',

                margin: '0px !important',
              }}
            >
              <div className={classes.cardFrameFilter}>
                <CardBody>
                  <GridContainer>
                    <GridItem className={classes.autoCompleteSelect}>
                      <h4 className={classes.title}>Keyword</h4>
                      <TextField
                        label="Keyword"
                        id="keyword"
                        variant="outlined"
                        name="keyword"
                        value={values.keyword}
                        onChange={event => {
                          setFieldValue('keyword', event.target.value);
                        }}
                        fullWidth
                      />
                    </GridItem>
                    <GridItem className={classes.autoCompleteSelect}>
                      <h4 className={classes.title}>Sort By</h4>
                      <FormControl fullWidth>
                        <Select
                          value={values.sortBy}
                          className={classes.filterSelect}
                          style={{ width: '100% !important' }}
                          onChange={(event, value) => {
                            console.log(event.target.value);
                            setFieldValue('sortBy', event.target.value);
                          }}
                        >
                          <MenuItem value={'recent'}>Recent</MenuItem>
                          <MenuItem value={'top'}>Top</MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </div>
              <div className={classes.buttonContainer}>
                <Button style={{ marginRight: '10px' }} className={classes.resetButton} onClick={handleResetFilter}>
                  Reset
                </Button>
                <Button style={{ marginRight: '10px' }} className={classes.filterButton} onClick={handleSubmit}>
                  Filter
                </Button>
              </div>
            </div>
          )}
        </Formik>
      </div>
    );
  };
  const JobDrawer = () => {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <Formik
          initialValues={{
            keyword: keyword ? keyword : '',
            countryId: jobSearchParams?.countryId ? jobSearchParams.countryId : '',
            locationId: jobSearchParams?.locationId ? jobSearchParams.locationId : '',
            location: jobSearchParams?.location ? jobSearchParams.location : '',
            // jobType: jobSearchParams?.jobType ? jobSearchParams.jobType : '',
            industryId: jobSearchParams?.industryId ? jobSearchParams.industryId : '',
            jobPostTypeId: jobSearchParams?.jobPostTypeId ? jobSearchParams.jobPostTypeId : '',
            jobTypeId: jobSearchParams?.jobTypeId ? jobSearchParams.jobTypeId : '',
            experienceId: jobSearchParams?.experienceId ? jobSearchParams.experienceId : '',
            currencyId: jobSearchParams?.currencyId ? jobSearchParams.currencyId : '',
            salaryMin: jobSearchParams?.salaryMin ? jobSearchParams.salaryMin : '',
            salaryMax: jobSearchParams?.salaryMax ? jobSearchParams.salaryMax : '',

            locationList: [],
            jobTypeList: [],
            isLoading: false,
          }}
          onSubmit={data => {
            const requestProfile = {
              acctId: auth.isPublic ? 0 : userStatus.acctId,
              accessToken: auth.isPublic ? auth.publicToken : localStorage.getItem('token'),
              email: auth.isPublic ? auth.publicEmail : userStatus.email,
              keyword: data.keyword,
              countryId: data.countryId ? data.countryId : -1,
              locationId: data.locationId ? data.locationId : -1,
              companyName: data.companyName,
              industryId: data.industryId ? data.industryId : -1,
              hotJobFlag: 0, //data.hotJobFlag,
              jobPostTypeId: data.jobPostTypeId ? data.jobPostTypeId : 0,

              jobTypeId: data.jobTypeId ? data.jobTypeId : -1,

              lastRecordSize: 0,
              requestRecordSize: 12,
              salaryMin: data.salaryMin ? Number(data.salaryMin) : -1,
              salaryMax: data.salaryMax ? Number(data.salaryMax) : -1,
              experienceId: data.experienceId ? data.experienceId : -1,
              currencyId: data.currencyId ? data.currencyId : -1,
              genericSearch: true,
            };
            console.log(requestProfile);
            dispatch(searchJobInfo(requestProfile));
            const response = {
              keyword: data.keyword,
            };
            dispatch(saveKeyword(response));
            setDrawer(false);
          }}
        >
          {({ values, handleSubmit, setFieldValue }) => (
            <div
              onSubmit={handleSubmit}
              style={{
                height: '100%',

                margin: '0px !important',
              }}
            >
              <div className={classes.cardFrameFilter}>
                <CardBody>
                  <GridContainer>
                    <GridItem className={classes.autoCompleteSelect}>
                      <h4 className={classes.title}>Keyword</h4>
                      <TextField
                        label="Keyword"
                        id="keyword"
                        variant="outlined"
                        name="keyword"
                        value={values.keyword}
                        onChange={event => {
                          setFieldValue('keyword', event.target.value);
                        }}
                        fullWidth
                      />
                    </GridItem>
                    <GridItem className={classes.autoCompleteSelect}>
                      <h4 className={classes.title}>Country</h4>

                      <SelectOption
                        options={countryList}
                        styles={connectionSelectStyles}
                        formatOptionLabel={list => (
                          <div>
                            <span>{list.label}</span>
                          </div>
                        )}
                        defaultValue={
                          countryList.filter(item => {
                            return item.value === values.countryId;
                          })[0] || null
                        }
                        onChange={(event, target) => {
                          setFieldValue('countryId', event.value);
                          if (values.locationId) {
                            setFieldValue('locationId', '');
                            setFieldValue('location', []);
                          }
                        }}
                        placeholder="Country"
                      />
                    </GridItem>

                    <GridItem className={classes.autoCompleteSelect}>
                      <h4 className={classes.title}>Location</h4>
                      <AsyncSelect
                        defaultOptions={values.locationList.length > 0 ? values.locationList : locationList}
                        styles={connectionSelectStyles}
                        formatOptionLabel={list => (
                          <div>
                            <span>{list.label}</span>
                          </div>
                        )}
                        value={
                          values.locationList.length > 0
                            ? values.locationList.filter(item => {
                                return item.value === values.locationId;
                              })[0] || null
                            : locationList.filter(item => {
                                return item.value === values.locationId;
                              })[0] || null
                        }
                        //{value:1, label:sg}
                        //value, label, etc
                        onFocus={() => {
                          setFieldValue('isLoading', true);
                          return new Promise((resolve, reject) => {
                            axios
                              .get(`${instanceUrl}/api/md/get-all-location-by-country`, {
                                cancelToken: undefined,
                                params: { countryId: values.countryId },
                              })
                              .then(res => {
                                var list = [];
                                list.push({ value: -1, label: 'All' });
                                if (res.status === 201) {
                                  for (var i = 0; i < res.data.payload.length; i++) {
                                    list.push({
                                      value: res.data.payload[i].locationId,
                                      label: res.data.payload[i].locationName,
                                    });
                                  }
                                }
                                setFieldValue('locationList', list);
                                setFieldValue('isLoading', false);

                                resolve(list);
                              })
                              .catch(reject);
                          });
                        }}
                        isLoading={values.isLoading}
                        onChange={(event, target) => {
                          console.log(event);
                          setFieldValue('locationId', event.value);
                          // setFieldValue('location', event);
                        }}
                        placeholder="Location"
                      />
                    </GridItem>

                    <GridItem className={classes.autoCompleteSelect}>
                      <h4 className={classes.title}>Industry</h4>

                      <SelectOption
                        options={industryList}
                        styles={connectionSelectStyles}
                        formatOptionLabel={list => (
                          <div>
                            <span>{list.label}</span>
                          </div>
                        )}
                        defaultValue={
                          industryList.filter(item => {
                            return item.value === values.industryId;
                          })[0] || null
                        }
                        onChange={(event, target) => {
                          setFieldValue('industryId', event.value);
                        }}
                        placeholder="Industry"
                      />
                    </GridItem>
                    <GridItem className={classes.autoCompleteSelect}>
                      <h4 className={classes.title}>Job Post Type</h4>

                      <SelectOption
                        options={jobPostTypeList}
                        styles={connectionSelectStyles}
                        formatOptionLabel={list => (
                          <div>
                            <span>{list.label}</span>
                          </div>
                        )}
                        defaultValue={
                          jobPostTypeList.filter(item => {
                            return item.value === values.jobPostTypeId;
                          })[0] || null
                        }
                        onChange={(event, target) => {
                          setFieldValue('jobPostTypeId', event.value);
                          // setJobPostTypeChange(event.value);
                          setFieldValue('jobTypeId', '');
                          if (values.jobType) {
                            setFieldValue('jobType', []);
                          }
                        }}
                        placeholder="Job Post Type"
                      />
                    </GridItem>

                    {(values.jobPostTypeId === 1 || values.jobPostTypeId === 2) && (
                      <GridItem xs={12} sm={12} md={12} className={classes.autoCompleteSelect}>
                        <AsyncSelect
                          defaultOptions={values.jobTypeList.length > 0 ? values.jobTypeList : jobTypeList}
                          styles={connectionSelectStyles}
                          formatOptionLabel={list => (
                            <div>
                              <span>{list.label}</span>
                            </div>
                          )}
                          value={
                            values.jobTypeList.length > 0
                              ? values.jobTypeList.filter(item => {
                                  return item.value === values.jobTypeId;
                                })[0] || null
                              : jobTypeList.filter(item => {
                                  return item.value === values.jobTypeId;
                                })[0] || null
                          }
                          onFocus={() => {
                            setFieldValue('isLoading', true);
                            return new Promise((resolve, reject) => {
                              axios
                                .get(`${instanceUrl}/api/md/get-job-type-by-job-post-type-id`, {
                                  cancelToken: undefined,
                                  params: {
                                    jobPostTypeId: values.jobPostTypeId,
                                  },
                                })
                                .then(res => {
                                  console.log(res);
                                  var list = [];
                                  list.push({ value: -1, label: 'All' });
                                  if (res.status === 201) {
                                    for (var i = 0; i < res.data.payload.length; i++) {
                                      list.push({
                                        value: res.data.payload[i].jobTypeId,
                                        label: res.data.payload[i].jobType,
                                      });
                                    }
                                  }
                                  setFieldValue('jobTypeList', list);
                                  setFieldValue('isLoading', false);

                                  resolve(list);
                                })
                                .catch(reject);
                            });
                          }}
                          isLoading={values.isLoading}
                          onChange={(event, target) => {
                            console.log(event);
                            setFieldValue('jobTypeId', event.value);
                            // setFieldValue('jobType', event);
                          }}
                          placeholder="Job Type"
                        />
                      </GridItem>
                    )}

                    <GridItem className={classes.autoCompleteSelect}>
                      <h4 className={classes.title}>Experience Level</h4>

                      <SelectOption
                        options={experienceLevelList}
                        styles={connectionSelectStyles}
                        formatOptionLabel={list => (
                          <div>
                            <span>{list.label}</span>
                          </div>
                        )}
                        defaultValue={
                          experienceLevelList.filter(item => {
                            return item.value === values.experienceId;
                          })[0] || null
                        }
                        onChange={(event, target) => {
                          setFieldValue('experienceId', event.value);
                        }}
                        placeholder="Experience Level"
                      />
                    </GridItem>

                    <GridItem className={classes.autoCompleteSelect}>
                      <h4 className={classes.title}>Minimum Salary</h4>
                      <TextField
                        label="Minimum Salary"
                        id="Minimum Salary"
                        variant="outlined"
                        name="Minimum Salary"
                        // value={values.salaryMin}
                        value={values.salaryMin > 0 ? values.salaryMin : ''}
                        onChange={event => {
                          setFieldValue('salaryMin', event.target.value);
                        }}
                        fullWidth
                      />
                    </GridItem>
                    <GridItem className={classes.autoCompleteSelect}>
                      <h4 className={classes.title}>Maximum Salary</h4>
                      <TextField
                        label="Maximum Salary"
                        id="Maximum Salary"
                        variant="outlined"
                        name="Maximum Salary"
                        // value={values.salaryMax}
                        value={values.salaryMax > 0 ? values.salaryMax : ''}
                        onChange={event => {
                          setFieldValue('salaryMax', event.target.value);
                        }}
                        fullWidth
                      />
                    </GridItem>

                    <GridItem className={classes.autoCompleteSelect}>
                      <h4 className={classes.title}>Currency</h4>

                      <SelectOption
                        options={currencyList}
                        styles={connectionSelectStyles}
                        formatOptionLabel={list => (
                          <div>
                            <span>{list.label}</span>
                          </div>
                        )}
                        defaultValue={
                          currencyList.filter(item => {
                            return item.value === values.currencyId;
                          })[0] || null
                        }
                        onChange={(event, target) => {
                          setFieldValue('currencyId', event.value);
                        }}
                        placeholder="Currency"
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </div>
              <div className={classes.buttonContainer}>
                <Button style={{ marginRight: '10px' }} className={classes.resetButton} onClick={handleResetFilter}>
                  Reset
                </Button>
                <Button style={{ marginRight: '10px' }} className={classes.filterButton} onClick={handleSubmit}>
                  Filter
                </Button>
              </div>
            </div>
          )}
        </Formik>
      </div>
    );
  };

  return (
    <React.Fragment>
      {(match.path === '/search-page-people' || match.path === '/public-search-page-people') && <PeopleDrawer />}
      {(match.path === '/search-page-job' || match.path === '/public-search-page-job') && <JobDrawer />}
      {(match.path === '/search-page-article' || match.path === '/public-search-page-article') && <ArticleDrawer />}
      {(match.path === '/search-page-post' || match.path === '/public-search-page-post') && <PostDrawer />}
    </React.Fragment>
  );
}
