import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { encryptData } from "configurations/configurations.js";
import { Link, useHistory } from "react-router-dom";
import { Button, IconButton } from "@material-ui/core";

import ConnectionCardStyle from "assets/jss/material-kit-pro-react/components/connectionPageStyle.js";

import defaultAvatar from "assets/img/candidate_avatar.jpeg";
import { getGroupList } from "configurations/redux/manageConnectionSlice.js";
import MoreVert from "@mui/icons-material/MoreVert";
import ConnectionCard from "./ConnectionCard.js";
import NoResultCard from "./NoResultCard.js";
import SmallCardLoading from "./SmallCardLoading.js";

import Pagination from "views/Component/PaginationWithPage.js";

const useStyles = makeStyles(ConnectionCardStyle);

export default function GroupCardComponent() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const groupRecords = useSelector(
		(state) => state.manageConnection.groupRecords
	);
	const groupCount = useSelector((state) => state.manageConnection.groupCount);
	const userStatus = useSelector((state) => state.auth.user);
	const [currentPage, setCurrentPage] = React.useState(1);
	const [profilePerPage, setProfilePerPage] = React.useState(10);
	const indexOfLast = currentPage * profilePerPage;
	const indexOfFirst = indexOfLast - profilePerPage;
	const isLoading = useSelector((state) => state.manageConnection.isLoading);
	const isStatusUpdated = useSelector(
		(state) => state.manageConnection.isStatusUpdated
	);
	React.useEffect(() => {
		const response = {
			acctId: userStatus.acctId,
			email: userStatus.email,
			accessToken: localStorage.getItem("token"),
			lastRecordSize: 0,
			requestRecordSize: 10,
			keyword: "",
			statusId: 1,
		};
		dispatch(getGroupList(response));
	}, []);

	const paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
		var indexOfFirst = pageNumber * profilePerPage - profilePerPage;

		const requestProfile = {
			acctId: userStatus.acctId,
			accessToken: localStorage.getItem("token"),
			email: userStatus.email,
			keyword: "",
			lastRecordSize: indexOfFirst,
			requestRecordSize: profilePerPage,
			statusId: 1,
		};
		dispatch(getGroupList(requestProfile));
	};
	React.useEffect(() => {
		if (isStatusUpdated) {
			var newPage = 0;
			if (groupRecords.length > 0) newPage = currentPage;
			else newPage = currentPage - 1;
			var indexOfFirst = newPage * profilePerPage - profilePerPage;
			setCurrentPage(newPage);

			const requestProfile = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				keyword: "",
				lastRecordSize: indexOfFirst,
				requestRecordSize: profilePerPage,
				statusId: 1,
			};
			dispatch(getGroupList(requestProfile));
		}
	}, [isStatusUpdated]);
	const ProfileList =
		groupRecords &&
		groupRecords.length > 0 &&
		groupRecords.map((item) => {
			return (
				<div key={item.groupId}>
					<ConnectionCard item={item} status={"Group"} />
				</div>
			);
		});
	const loadingFields = [];
	for (let i = 0; i < profilePerPage - groupRecords.length; i++) {
		loadingFields.push(
			<div key={i}>
				<SmallCardLoading />
			</div>
		);
	}
	return (
		<Card className={classes.cardFrame}>
			<CardBody className={classes.cardBodyPadding}>
				<h4 className={classes.componentTitle}>Group</h4>
				{isLoading &&
					loadingFields.map((item) => {
						return item;
					})}
				<div className={classes.mainContainer}>
					{ProfileList}{" "}
					{!isLoading && groupRecords.length === 0 && (
						<NoResultCard status={"You have no joined group"} />
					)}
				</div>
				{groupRecords && groupRecords.length > 0 && (
					<Pagination
						totalPosts={groupCount}
						postsPerPage={profilePerPage}
						paginate={paginate}
					/>
				)}
			</CardBody>
		</Card>
	);
}
