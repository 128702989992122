import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import { encryptData } from "configurations/configurations.js";
import {
	createChatroom,
	createChatroomReset,
} from "configurations/redux/messageChatroomSlice.js";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded";
import LocationOnRoundedIcon from "@material-ui/icons/LocationOnRounded";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";

import defaultAvatar from "assets/img/candidate_avatar.jpeg";

import FontIcon from "views/Component/FontIcon.js";
import {
	faMapMarkerAlt,
	faEye,
	faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { Divider, Hidden } from "@material-ui/core";
import IconLibrary from "views/Component/IconLibrary.js";
import searchProfilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/SearchProfilePage/searchProfilePageStyle.js";

const useStyles = makeStyles(searchProfilePageStyle);

export default function SearchResultCard({
	item,
	profileAcctId,
	getMessageNavigate,
}) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const [acctId, setAcctId] = React.useState();
	const userStatus = useSelector((state) => state.auth.user);
	React.useEffect(() => {
		const hashId = encryptData(item.acctId);
		setAcctId(hashId);
	}, []);
	const showProfile = item.privacySetting
		.filter((item) => {
			return item.settingName === "showProfile";
		})
		.map((item) => {
			return item.settingValue === 1;
		})[0];

	const showProfilePic = item.privacySetting
		.filter((item) => {
			return item.settingName === "showProfilePic";
		})
		.map((item) => {
			return item.settingValue === 1;
		})[0];
	const showHeadline = item.privacySetting
		.filter((item) => {
			return item.settingName === "showHeadline";
		})
		.map((item) => {
			return item.settingValue === 1;
		})[0];

	return (
		<div key={item.acctId}>
			<Link
				className={classes.textDeco}
				target="_blank"
				to={{
					pathname: `/view-profile/${acctId}`,
					//state: { acctId: item.acctId },
				}}
				color="transparent"
			>
				<Card className={classes.cardClick + " " + classes.cardFrame}>
					<CardBody>
						<div className={classes.cardBody}>
							{/* <GridContainer
								justify="space-between"
								alignContent="center"
								alignItems="center"
							>
								<GridItem xs={10} sm={9} md={9} lg={9}> */}
							<GridContainer
								alignContent="center"
								alignItems="center"
								className={classes.gridContainerPadding}
							>
								<GridItem
									xs={3}
									sm={3}
									md={2}
									lg={1}
									className={
										classes.itemCenter + " " + classes.gridRightLeftMargin
									}
								>
									<img
										className={classes.cardImage}
										src={
											showProfile
												? showProfilePic
													? item.profilePic
														? item.profilePic
														: defaultAvatar
													: defaultAvatar
												: defaultAvatar
										}
										alt="..."
									/>
								</GridItem>
								<GridItem
									xs={7}
									sm={7}
									md={7}
									lg={9}
									className={
										classes.gridRightLeftMargin + " " + classes.infoGrid
									}
								>
									<h4 className={classes.title + " " + classes.titleInfo}>
										{item.firstName}
										{item.middleName ? " " + item.middleName + " ," : null}{" "}
										{item.surname}
									</h4>
									<div>
										<h5
											className={classes.description + " " + classes.descInfo}
										>
											{showProfile
												? showHeadline
													? item.headline
														? item.headline
														: " \n"
													: "\n "
												: "\n"}
										</h5>
									</div>

									{/* <div className={classes.titleLocation}> */}
									{showProfile ? (
										item.countryName ? (
											<React.Fragment>
												<p
													className={
														classes.candidateDescription +
														" " +
														classes.titleLocation
													}
												>
													{item.countryName}
												</p>
											</React.Fragment>
										) : null
									) : null}
									{/* 		</div> */}
								</GridItem>
								<GridItem
									xs={2}
									sm={2}
									md={3}
									lg={2}
									className={
										classes.gridRightLeftMargin + " " + classes.itemRight
									}
								>
									<Hidden mdUp>
										<Button
											onClick={(event) => {
												event.preventDefault();
												console.log(item.acctId);
												getMessageNavigate(item.acctId);
											}}
											color="opptyNoBorder"
											justIcon
											className={classes.buttonMessage}
										>
											<IconLibrary.Mail />
										</Button>
									</Hidden>
									<Hidden smDown>
										<Button
											onClick={(event) => {
												event.preventDefault();
												console.log(item.acctId);
												getMessageNavigate(item.acctId);
											}}
											color="opptyNoBorder"
											className={classes.buttonMessage}
										>
											<IconLibrary.Mail /> Send A Message
										</Button>
									</Hidden>
								</GridItem>
							</GridContainer>
							{/* </GridItem> */}

							{/* <GridItem xs={2} sm={3} md={3} lg={3}>
									<Button
										onClick={(event) => {
											event.preventDefault();
											console.log(item.acctId);
											getMessageNavigate(item.acctId);
										}}
										fullWidth
										color="opptyNoBorder"
										className={classes.buttonMessage}
									>
										<FontIcon
											icon={faEnvelope}
											text={
												<Hidden smDown>
													<span>Message</span>
												</Hidden>
											}
											className={classes.messageIcon}
										/>
									</Button>
								</GridItem> */}
							{/* 	</GridContainer> */}
							<Divider />

							<GridContainer alignContent="center" alignItems="center">
								<GridItem xs={12} sm={12} md={12} lg={12}>
									<div className={classes.viewAreaStyle}>
										<IconLibrary.Eye /> {item.viewCount} Viewed
									</div>
								</GridItem>
							</GridContainer>
						</div>
					</CardBody>

					{/* <CardFooter className={classes.cardFooterBorder}>
						<Button
							round
							onClick={(event) => {
								event.preventDefault();
								console.log("hi");
							}}
							fullWidth
							color="oppty"
						>
							<b>Message</b>
						</Button>
					</CardFooter> */}
				</Card>
			</Link>
		</div>
	);
}
