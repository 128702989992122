import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { instanceUrl, header } from "configurations/configurations.js";

// import accountVerificationPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/AccountVerificationPage/accountVerificationPageStyle.js";
import passwordRecoveryPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/PasswordRecoveryPage/passwordRecoveryPageStyle.js";
// import loginPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/LoginPage/loginPageStyle.js";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Header from "views/Component/Header.js";
import Footer from "views/Component/Footer.js";

import image from "assets/img/bg7.jpg";
import { Hidden, TextField } from "@material-ui/core";
import verificationImage from "assets/img/Registration_Image.png";
import { gaPageView } from "configurations/configurations";

const useStyles = makeStyles(passwordRecoveryPageStyle);

export default function AccountVerificationPage(props) {
	const classes = useStyles();
	React.useEffect(() => {
		gaPageView(window.location.pathname);
		/* window.scrollTo(0, 0);
		document.body.scrollTop = 0; */
		requestData();
	}, []);

	console.log(props.match.params);
	const [valid, setValid] = React.useState();

	const requestData = async () => {
		await axios({
			method: "GET",
			headers: header,
			url: `${instanceUrl}/api/um/email-verification`,
			params: {
				email: props.match.params.AccountEmail,
				acctTypeId: props.match.params.AccountTypeId,
				verificationCode: props.match.params.verificationCode,
			},
		})
			.then((res) => {
				if (res.request.status === 201) {
					setValid(true);
				} else {
					setValid(false);
				}
			})
			.catch((err) => {
				console.log(err);
				setValid(false);
			});
	};

	React.useEffect(() => {
		pageResize();
	}, []);

	window.onresize = function (e) {
		pageResize();
	};

	const pageResize = () => {
		var mainPage = document.getElementById("mainPage");
		var footer = document.getElementById("footer");

		var timer = setInterval(() => {
			if (mainPage && footer) {
				clearInterval(timer);
				if (window.innerWidth >= 960) {
					mainPage.style.minHeight = "498px";
					mainPage.style.height =
						window.innerHeight - footer.clientHeight + "px";
				} else {
					mainPage.style.minHeight = "1px";
					mainPage.style.height = "auto";
				}
			} else {
				setTimeout(() => {
					clearInterval(timer);
				}, 3000);
			}
		}, 50);
	};

	return (
		<>
			<Header color={"transparent"} loggedInStatus={false} />
			<div className={classes.body}>
				<div
					id="mainPage"
					className={classes.pageHeader + " " + classes.pageHeaderHeight}
					style={{
						// backgroundImage: "url(" + image + ")",
						backgroundSize: "cover",
						backgroundPosition: "top center",
					}}
				>
					<div className={classes.container} id="verificationMainPage">
						<GridContainer
							justifyContent="center"
							alignContent="center"
							alignItems="center"
						>
							<GridItem xs={12} sm={12} md={7}>
								{/* <Hidden smDown> */}
								<div className={classes.infoArea}>
									<h2 className={classes.leftInfoTitle}>Email Verification</h2>
									<h5 className={classes.leftInfoDescription}>
										Verifying your email to make sure it's valid.
									</h5>
									{/* <img src={verificationImage} className={classes.infoImage} /> */}
								</div>
								{/* </Hidden> */}
							</GridItem>
							<GridItem xs={12} sm={12} md={5} className={classes.loginGrid}>
								{valid && (
									// <div className={classes.container}>
									// 	<GridContainer justifyContent="center">
									// 		<GridItem xs={12} sm={12} md={5}>
									<Card className={classes.passwordCard}>
										<CardBody>
											<GridContainer
												justifyContent="center"
												alignContent="center"
												alignItems="center"
											>
												<GridItem xs={12} sm={12} md={12}>
													<GridItem>
														<div>
															<h4
																className={
																	classes.title + " " + classes.textCenter
																}
															>
																Verification Successful
															</h4>
														</div>
													</GridItem>{" "}
													<h5
														className={
															classes.cardDescription + " " + classes.textCenter
														}
													>
														Your Account has been Verified! <br></br>Click here
														to start!
													</h5>
												</GridItem>
											</GridContainer>
											<GridContainer>
												<GridItem>
													<Button
														href="/login-page"
														fullWidth
														round
														color="oppty"
													>
														Login Now
													</Button>
													{/* <div className={classes.textCenter}>
													<Link to="/login-page" >
														<h5
															className={
																classes.infoTitle + " " + classes.textCenter
															}
														>
															Login Now
														</h5>
													</Link>
												</div> */}
												</GridItem>
											</GridContainer>
										</CardBody>
									</Card>
									// <Card>
									// 	<CardHeader color="primary" className={classes.cardHeader}>
									// 		<h4 className={classes.cardTitle}>
									// 			Verification Successful
									// 		</h4>
									// 	</CardHeader>
									// 	<CardBody>
									// 		<h5
									// 			className={
									// 				classes.cardDescription + " " + classes.textCenter
									// 			}
									// 		>
									// 			Your Account has been Verified! <br></br>Click here to
									// 			start!
									// 		</h5>
									// 		<Link to="/login-page">
									// 			<Button fullWidth color="success">
									// 				Login Now
									// 			</Button>
									// 		</Link>
									// 	</CardBody>
									// </Card>
									// 		</GridItem>
									// 	</GridContainer>
									// </div>
								)}
								{valid === false && (
									// <div className={classes.container}>
									// 	<GridContainer justifyContent="center">
									// 		<GridItem xs={12} sm={12} md={5}>
									<Card className={classes.passwordCard}>
										<CardBody>
											<GridContainer
												justifyContent="center"
												alignContent="center"
												alignItems="center"
											>
												<GridItem xs={12} sm={12} md={12}>
													<GridItem>
														<div>
															<h4
																className={
																	classes.title + " " + classes.textCenter
																}
															>
																Invalide Code
															</h4>
														</div>
													</GridItem>{" "}
													<h5
														className={
															classes.cardDescription + " " + classes.textCenter
														}
													>
														Email Verification was not successful <br></br>{" "}
														Please contact OppTy Support for further help
													</h5>
												</GridItem>
											</GridContainer>
											<GridContainer>
												<GridItem>
													<div className={classes.textCenter}>
														<Button
															href="mailto:inquiry@richdaleintles.com"
															fullWidth
															round
															color="oppty"
														>
															Contact OppTy
														</Button>
														{/* <Link to="/login-page">
														<h5
															className={
																classes.infoTitle + " " + classes.textCenter
															}
														>
															Contact OppTy
														</h5>
													</Link> */}
													</div>
												</GridItem>
											</GridContainer>
										</CardBody>
									</Card>
									// <Card>
									// 	<CardHeader color="primary" className={classes.cardHeader}>
									// 		<h4 className={classes.cardTitle}>Invalide Code</h4>
									// 	</CardHeader>
									// 	<CardBody>
									// 		<h5
									// 			className={
									// 				classes.cardDescription + " " + classes.textCenter
									// 			}
									// 		>
									// 			Email Verification was not successful <br></br> Please
									// 			contact OppTy Support for further help
									// 		</h5>

									// 		<Button
									// 			href="mailto:inquiry@richdaleintles.com"
									// 			fullWidth
									// 			color="success"
									// 		>
									// 			Contact OppTy
									// 		</Button>
									// 	</CardBody>
									// </Card>
									// 		</GridItem>
									// 	</GridContainer>
									// </div>
								)}
							</GridItem>
						</GridContainer>
					</div>
				</div>
				<div id="footer">
					<Footer />
				</div>
			</div>
		</>
	);
}
