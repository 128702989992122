import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Formik } from "formik";

import { regularRegisterSchema } from "configurations/configurations.js";
import { registerAction } from "configurations/redux/authSlice.js";
import { useDispatch, useSelector } from "react-redux";

import Divider from "@material-ui/core/Divider";
import People from "@material-ui/icons/People";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Email from "@material-ui/icons/Email";
import Icon from "@material-ui/core/Icon";
import CheckIcon from "@material-ui/icons/Check";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Danger from "components/Typography/Danger.js";
import Button from "components/CustomButtons/Button.js";

import FacebookRegister from "views/Page/RegistrationPage/FacebookRegister.js";
import GoogleRegister from "views/Page/RegistrationPage/GoogleRegister.js";
import registrationPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/RegistrationPage/registrationPageStyle.js";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { TextField } from "@material-ui/core";

const useStyles = makeStyles(registrationPageStyle);
const CandidateForm = () => {
	const classes = useStyles();

	const dispatch = useDispatch();

	const [checked, setChecked] = React.useState(false);
	const [click, setClick] = React.useState(false);
	const message = useSelector((state) => state.auth.message);

	React.useEffect(() => {
		setTimeout(() => {
			setClick(false);
		}, 1000);
	}, [message]);
	const [showPassword, setShowPassword] = React.useState(false);
	const handleClickShowPassword = () => {
		if (showPassword) setShowPassword(false);
		else setShowPassword(true);
	};
	React.useEffect(() => {
		console.log("rendered candidate");
	}, []);
	return (
		<div>
			<Formik
				initialValues={{
					firstName: "",
					middleName: "",
					surname: "",
					companyName: "",
					email: "",
					password: "",
					checkPassword: "",
					checkedTerms: false,
				}}
				validationSchema={regularRegisterSchema}
				onSubmit={(data) => {
					console.log(data);

					const requestBody = {
						email: data.email.trim(),
						password: data.password,
						surname: data.surname.trim(),
						firstName: data.firstName.trim(),
						middleName: data.middleName.trim(),
						companyName: data.companyName.trim(),
						acctStatusId: 0,
						acctTypeId: 1,
						socialAcctTypeId: 0,
						createdAcctId: 0,
						updateAcctId: 0,
					};
					setClick(true);
					dispatch(registerAction(requestBody));
				}}
			>
				{({
					handleChange,
					values,
					errors,
					touched,
					handleBlur,
					handleSubmit,
					setFieldValue,
				}) => (
					<form onSubmit={handleSubmit}>
						<GridContainer>
							<GridItem
								xs={12}
								sm={6}
								md={6}
								className={classes.gridHeight + " " + classes.marginTop}
							>
								<h5 className={classes.textFieldLabel}>First Name</h5>
								<TextField
									variant="outlined"
									label={
										touched.firstName && Boolean(errors.firstName)
											? errors.firstName
											: ""
									}
									id="firstName"
									name="firstName"
									// placeholder="Required"
									values={values.firstName}
									onChange={handleChange}
									onBlur={handleBlur}
									fullWidth
									// helperText={touched.firstName ? errors.firstName : ""}
									error={touched.firstName && Boolean(errors.firstName)}
									InputProps={{
										type: "text",
										// startAdornment: (
										// 	<InputAdornment position="start">
										// 		<People className={classes.inputIconsColor} />
										// 	</InputAdornment>
										// ),
										autoComplete: "off",
									}}
								/>
							</GridItem>

							<GridItem
								xs={12}
								sm={6}
								md={6}
								className={classes.gridHeight + " " + classes.marginTop}
							>
								<h5 className={classes.textFieldLabel}>Middle Name</h5>
								<TextField
									variant="outlined"
									label=""
									id="middleName"
									name="middleName"
									placeholder="Optional"
									// helperText={"Optional"}
									values={values.middleName}
									onChange={handleChange}
									fullWidth
									InputProps={{
										type: "text",
										// startAdornment: (
										// 	<InputAdornment position="start">
										// 		<People className={classes.inputIconsColor} />
										// 	</InputAdornment>
										// ),
										autoComplete: "off",
									}}
								/>
							</GridItem>
							<GridItem
								xs={12}
								sm={12}
								md={12}
								className={classes.gridHeight + " " + classes.marginTop}
							>
								<h5 className={classes.textFieldLabel}>Surname/Family Name</h5>
								<TextField
									variant="outlined"
									label={
										touched.surname && Boolean(errors.surname)
											? errors.surname
											: ""
									}
									id="surname"
									name="surname"
									autoComplete="off"
									onChange={handleChange}
									onBlur={handleBlur}
									values={values.surname}
									fullWidth
									// placeholder="Required"
									// helperText={touched.surname ? errors.surname : ""}
									error={touched.surname && Boolean(errors.surname)}
									InputProps={{
										type: "text",
										// startAdornment: (
										// 	<InputAdornment position="start">
										// 		<PersonAddIcon className={classes.inputIconsColor} />
										// 	</InputAdornment>
										// ),
										autoComplete: "off",
									}}
								/>
							</GridItem>
							<GridItem
								xs={12}
								sm={12}
								md={12}
								className={classes.gridHeight + " " + classes.marginTop}
							>
								<h5 className={classes.textFieldLabel}>Email</h5>
								<TextField
									variant="outlined"
									label={
										touched.email && Boolean(errors.email) ? errors.email : ""
									}
									id="email"
									name="email"
									// placeholder="Required"
									onChange={handleChange}
									onBlur={handleBlur}
									values={values.email}
									fullWidth
									// helperText={touched.email ? errors.email : ""}
									error={touched.email && Boolean(errors.email)}
									InputProps={{
										type: "text",
										// startAdornment: (
										// 	<InputAdornment position="start">
										// 		<Email className={classes.inputIconsColor} />
										// 	</InputAdornment>
										// ),
									}}
								/>
							</GridItem>
							<GridItem
								xs={12}
								sm={6}
								md={6}
								className={classes.gridHeight + " " + classes.marginTop}
							>
								<h5 className={classes.textFieldLabel}>Password</h5>
								<TextField
									variant="outlined"
									label={
										touched.password && Boolean(errors.password)
											? errors.password
											: ""
									}
									id="password"
									name="password"
									// placeholder="Required"
									onChange={handleChange}
									onBlur={handleBlur}
									values={values.password}
									fullWidth
									// helperText={touched.password ? errors.password : ""}
									error={touched.password && Boolean(errors.password)}
									InputProps={{
										type: showPassword ? "text" : "password",
										// startAdornment: (
										// 	<InputAdornment position="start">
										// 		<Icon className={classes.inputIconsColor}>
										// 			lock_outline
										// 		</Icon>
										// 	</InputAdornment>
										// ),
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleClickShowPassword}
													edge="end"
												>
													{showPassword ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										),
										autoComplete: "off",
									}}
								/>
							</GridItem>
							<GridItem
								xs={12}
								sm={6}
								md={6}
								className={classes.gridHeight + " " + classes.marginTop}
							>
								<h5 className={classes.textFieldLabel}>Confirm Password</h5>
								<TextField
									variant="outlined"
									label={
										touched.checkPassword && Boolean(errors.checkPassword)
											? errors.checkPassword
											: ""
									}
									id="checkPassword"
									name="checkPassword"
									// placeholder="Required"
									onChange={handleChange}
									onBlur={handleBlur}
									values={values.checkPassword}
									fullWidth
									// helperText={touched.checkPassword ? errors.checkPassword : ""}
									error={touched.checkPassword && Boolean(errors.checkPassword)}
									InputProps={{
										type: showPassword ? "text" : "password",
										// startAdornment: (
										// 	<InputAdornment position="start">
										// 		<CheckIcon className={classes.inputIconsColor}>
										// 			lock_outline
										// 		</CheckIcon>
										// 	</InputAdornment>
										// ),
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleClickShowPassword}
													edge="end"
												>
													{showPassword ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										),
										autoComplete: "off",
									}}
								/>
							</GridItem>
							<GridItem
								xs={12}
								sm={12}
								md={12}
								className={classes.gridHeight + " " + classes.marginTop}
							>
								<h5 className={classes.textFieldLabel}>Corporate Name</h5>
								<TextField
									variant="outlined"
									label={
										touched.companyName && Boolean(errors.companyName)
											? errors.companyName
											: ""
									}
									id="companyName"
									name="companyName"
									placeholder="Optional"
									values={values.companyName}
									onChange={handleChange}
									onBlur={handleBlur}
									fullWidth
									// helperText={touched.companyName ? errors.companyName : ""}
									error={touched.companyName && Boolean(errors.companyName)}
									InputProps={{
										type: "text",
										// startAdornment: (
										// 	<InputAdornment position="start">
										// 		<People className={classes.inputIconsColor} />
										// 	</InputAdornment>
										// ),
										autoComplete: "off",
									}}
								/>
							</GridItem>
							<GridItem xs={12} sm={12} md={12}>
								<div>
									<FormControlLabel
										classes={{
											label: classes.label,
										}}
										control={
											<Checkbox
												//tabIndex={-1}
												onChange={handleChange}
												name="checkedTerms"
												onClick={() => {
													if (values.checkedTerms) {
														setFieldValue("checkedTerms", false);
													} else {
														setFieldValue("checkedTerms", true);
													}
												}}
												checkedIcon={<Check className={classes.checkedIcon} />}
												icon={<Check className={classes.uncheckedIcon} />}
												classes={{
													checked: classes.checked,
													root: classes.checkRoot,
												}}
												checked={values.checkedTerms}
											/>
										}
										label={
											<span>
												I have read and agreed to the{" "}
												<a href="https://oppty.ai/terms-of-use" target="_blank">
													terms and conditions
												</a>
												.
											</span>
										}
									/>
								</div>
								{errors.checkedTerms && touched.checkedTerms && (
									<Danger>
										<h6>{errors.checkedTerms}</h6>
									</Danger>
								)}
							</GridItem>

							{/* <GridContainer>
								{/* <GridItem
										xs={6}
										sm={6}
										md={6}
										className={classes.dividerRight}
									>
										<div>
											<Button
												onClick={handleSubmit}
												fullWidth
												color="info"
												//type="submit"
												disabled={click}
												simple
											>
												Register
											</Button>
										</div>
									</GridItem>
									<GridItem
										xs={6}
										sm={6}
										md={6}
										//className={classes.gridItemMargin}
									>
										<Link to="/login-page">
											<Button fullWidth color="primary" simple>
												Registered Already?
											</Button>
										</Link>
									</GridItem> */}
							<GridItem xs={12} sm={12} md={12}>
								<Button
									fullWidth
									color="oppty"
									disabled={click}
									onClick={handleSubmit}
									className={classes.buttonBorder}
								>
									<b>Register Now</b>
								</Button>
							</GridItem>
						</GridContainer>
					</form>
				)}
			</Formik>
			{/* <Divider /> */}

			<GridContainer className={classes.registerSocialGrid}>
				<GridItem>
					<h5 className={classes.registerSocial}>OR</h5>
				</GridItem>
			</GridContainer>
			{/* <GridContainer justifyContent="center">
				<GridItem
					xs={12}
					sm={10}
					md={10}
					lg={8}
					className={classes.registerSocialMargin}
				>
					<FacebookRegister />
				</GridItem>
			</GridContainer> */}
			<GridContainer justifyContent="center">
				<GridItem
					xs={12}
					sm={10}
					md={10}
					lg={8}
					className={classes.registerSocialMargin}
				>
					<GoogleRegister />
				</GridItem>
			</GridContainer>
		</div>
	);
};

export default CandidateForm;
