import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Header from '../../Component/Header.js';
import LeftSidebar from 'views/Component/LeftSidebar.js';
// import RecommendedJobs from "views/Component/RecommendedJobs.js";
import SocialFeed from 'views/Component/SocialFeed/SocialFeed';
import CreatePostModal from '../../../components/CreatePostModal/CreatePostModal';
import CreatePollModal from '../../../components/CreatePostModal/CreatePollModal';
import PreviewPostPhotosModal from '../../Component/PreviewPostPhotosModal';
import PreviewPostVideosModal from '../../Component/PreviewPostVideosModal';
import RightSideBarUserList from 'views/Component/RightSideBarUserList.js';
import Footer from 'views/Component/Footer.js';
import Backdrop from '../../Component/Backdrop.js';

import Button from 'components/CustomButtons/Button.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from '../../../components/Grid/GridItem.js';

import { makeStyles } from '@material-ui/core';

import { errorCleanUp } from 'configurations/redux/authSlice.js';
import loginImage from 'assets/img/Login_Image.png';
import { Grid, Hidden } from '@material-ui/core';
import Cookies from 'js-cookie';
import CustomDialog from '../../../components/CustomDialog/CustomDialog.js';
import { isDesktop, isTablet } from 'react-device-detect';
import SnackbarContent from 'components/Snackbar/SnackbarContent.js';
import { resendVerificationEmail } from 'configurations/redux/authSlice.js';
import BottomNavBar from 'components/BottomNavBar/BottomNavBar.js';
import {
  getSocialPostByRecent,
  getSocialPostByTop,
  getSocialPostByPostId,
  resetPostGet,
  getHashtagMatch,
  getSocialPostByHashId,
  resetHashtagMatch,
} from 'configurations/redux/socialFeedSlice.js';

import {
  decryptData,
  getVisibleOption,
  getSocialPostTypeOption,
  getCommentPermissionByPermissionType,
  getPollDurationOption,
  gaPageView,
} from 'configurations/configurations';

import LandingPageFeedStyle from 'assets/jss/material-kit-pro-react/views/pageStyle/LandingPageFeed/LandingPageFeedStyle.js';

const useStyles = makeStyles(LandingPageFeedStyle);

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  React.useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const SocialFeedControl = ({
  onClosePost,
  createPost,
  hideAddon,
  showFeed,
  match,
  groupId,
  scrollableTarget,
  isSearch,
  isGroupMember,
  isInfiniteEnabled,
  postStatus,
  isInGroup,
  isSearchPage = false,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  var isKicked = Cookies.get('isKicked');

  const auth = useSelector(state => state.auth);
  const isVerified = useSelector(state => state.auth.isVerified);
  const isResend = useSelector(state => state.auth.isResend);
  const user = useSelector(state => state.auth.user);

  const [emailSend, setEmailSend] = useState(false);
  const isError = useSelector(state => state.auth.isError);

  // #region <------- Component States -------->
  const [isCreatePostModalOpen, setIsCreatePostModalOpen] = useState(false);
  const [isCreatePollModalOpen, setIsCreatePollModalOpen] = useState(false);
  const [question, setQuestion] = useState('');
  const [options, setOptions] = useState(['', '']);
  const [pollTiming, setPollTiming] = useState('1 day');
  const [postedData, setPostedData] = useState({});

  const [uploadedPhotos, setUploadedPhotos] = useState([]);
  const [isPreviewPostPhotosOpen, setIsPreviewPostPhotosOpen] = useState(false);
  const [previewPhoto, setPreviewPhoto] = useState();
  const [uploadedVideos, setUploadedVideos] = useState([]);
  const [isPreviewPostVideosOpen, setIsPreviewPostVideosOpen] = useState(false);
  const [previewVideo, setPreviewVideo] = useState();
  const [draggedItemindex, setDraggedItemIdex] = useState(null);
  const [uploadError, setError] = useState('');

  const [visibleOptions, setVisibleOptions] = useState(null);
  const [commentabilityOptions, setCommentabilityOptions] = useState(null);
  const [socialPostTypeOptions, setSocialPostTypeOptions] = useState(null);
  const [pollDurationOptions, setPollDurationOptions] = useState(null);
  const [postsList, setPostsList] = useState([]);

  const [width, height] = useWindowSize();

  const [candidateShow, setCandidateShow] = useState(true);
  const [corporateShow, setCorporateShow] = useState(false);
  const [cardAnimation, setCardAnimation] = useState('cardHidden');
  const [isLoading, setIsLoading] = useState(false);
  const [editPost, setEditPost] = useState(null);
  const [sharePost, setSharePost] = useState(null);
  const [shareJob, setShareJob] = useState(null);
  const hashtagMatch = useSelector(state => state.socialFeed.hashtagMatch);

  // #endregion

  useEffect(() => {
    console.log('create post', createPost);
    if (createPost) {
      setEditPost(null);
      setSharePost(null);
      setShareJob(null);
      setIsCreatePostModalOpen(true);
      setUploadedPhotos([]);
      setUploadedVideos([]);
      removePollData();
      savePostData('');
      handleCreatePostClick('post');
    }
  }, [createPost]);

  useEffect(() => {
    let isMounted = true;
    if (isError) {
      dispatch(errorCleanUp());
    }

    getVisibleOption()
      .then(res => {
        if (isMounted) setVisibleOptions(res.data.payload);
      })
      .catch(err => console.log(err));

    getSocialPostTypeOption()
      .then(res => {
        if (isMounted) setSocialPostTypeOptions(res.data.payload);
      })
      .catch(err => console.log(err));

    // Permission type 1 and 2
    var type = '1,2';
    if (groupId > 0) type = '3';
    getCommentPermissionByPermissionType(type)
      .then(res => {
        if (isMounted) setCommentabilityOptions(res.data.payload);
      })
      .catch(err => console.log(err));

    getPollDurationOption()
      .then(res => {
        if (isMounted) setPollDurationOptions(res.data.payload);
      })
      .catch(err => console.log(err));

    gaPageView(window.location.pathname);
    return () => {
      isMounted = false;
    };
  }, []);

  const removePollData = () => {
    setQuestion('');
    setOptions([]);
    setPollTiming('');
  };

  const statusChangeHandler = item => {
    console.log(item);
    // setIsCreatePostModalOpen(false);
    // if (item == "post" || item === "edit" || item === "posted") {
    if (item == 'post' || item == 'posted') {
      removePollData();
      setEditPost(null);
      setSharePost(null);
      setIsCreatePostModalOpen(false);
      setIsCreatePollModalOpen(false);
      setUploadedPhotos([]);
      setUploadedVideos([]);
      onClosePost(false);
    }
    // else if (item === "poll") {
    // 	setIsCreatePollModalOpen(false);
    // }
  };

  const handleCreatePostClick = item => {
    console.log(item);
    if (item === 'post') {
      setIsCreatePostModalOpen(true);
      setIsCreatePollModalOpen(false);
    } else if (item === 'poll') {
      // setIsCreatePostModalOpen(false);
      setUploadedPhotos([]);
      setUploadedVideos([]);
      setIsCreatePollModalOpen(true);
    } else if (item === 'image') {
      setUploadedVideos([]);
    } else if (item === 'video') {
      setUploadedPhotos([]);
    }
  };

  const handleEditPostClick = item => {
    console.log(item);
    if (item) {
      // statusChangeHandler("edit");
      setEditPost(item);
      setSharePost(null);
      setIsCreatePostModalOpen(true);
      setUploadedPhotos([]);
      setUploadedVideos([]);
      removePollData();
      savePostData('');
      handleCreatePostClick('post');
      setShareJob(null);

      if (item.typeOptionId == 0) {
      } else if (item.typeOptionId == 1) {
        var newPhotoList = [];
        for (var i = 0; i < item.postTypes.length; i++) {
          newPhotoList.push(item.postTypes[i].attachment);
        }
        setUploadedPhotos([...newPhotoList]);
      } else if (item.typeOptionId == 2) {
        var newVideoList = [];
        for (var i = 0; i < item.postTypes.length; i++) {
          newVideoList.push(item.postTypes[i].attachment);
        }
        setUploadedVideos([...newVideoList]);
      } else if (item.typeOptionId == 3) {
        for (var i = 0; i < item.postTypes.length; i++) {
          for (var j = 0; j < item.postTypes[i].poolList.length; j++) {
            var options = [];
            for (var k = 0; k < item.postTypes[i].poolList[j].poolOptionList.length; k++) {
              options.push(item.postTypes[i].poolList[j].poolOptionList[k].optionDesc);
            }
            savePollData(item.postTypes[i].poolList[j].question, options, item.postTypes[i].poolList[j].durationName);
          }
        }
      } else if (item.typeOptionId == 4) {
        setSharePost(item.sharedPost.postList[0]);
      } else if (item.typeOptionId == 5) {
        setShareJob(item.sharedJob);
      }
    }
  };

  const handleSharePostClick = item => {
    console.log(item);
    if (item) {
      // statusChangeHandler("edit");
      setEditPost(null);
      setSharePost(item);
      setShareJob(null);
      setIsCreatePostModalOpen(true);
      setUploadedPhotos([]);
      setUploadedVideos([]);
      removePollData();
      savePostData('');
      handleCreatePostClick('post');
    }
  };

  const savePollData = (question, options, pollTiming) => {
    console.log(question, options, pollTiming);
    setQuestion(question);
    setOptions(options);
    setPollTiming(pollTiming);
  };
  const savePostData = (text, image, video) => {
    let tempData = {};
    tempData.text = text;
    if (question.length > 0) {
      tempData.question = question;
      tempData.pollTiming = pollTiming;
      tempData.options = options;
    }
    setPostedData(tempData);
  };

  const selectPhotos = photos => {
    if (photos) {
      setError('');
      // setIsLoading(true);
      checkError('photos', photos);
      console.log(photos);
    } else {
      // setUploadedPhotos([]);
    }
  };

  const selectVideos = videos => {
    if (videos) {
      setError('');
      checkError('videos', videos);
      console.log(videos);
    } else {
      setUploadedVideos([]);
    }
  };

  const removeSinglePhoto = index => {
    console.log(index, uploadedPhotos);
    setUploadedPhotos(uploadedPhotos.filter((val, i) => index != i));
    console.log(uploadedPhotos);
  };

  const removeSingleVideo = index => setUploadedVideos(uploadedVideos.filter((val, i) => index != i));

  const checkError = (attachmentType, payload) => {
    console.log(payload);
    if (attachmentType === 'photos') {
      // let files = payload.filter((file) => file.size > 15728640);
      // let invalidFiles = payload.filter(
      // 	(file) => file && file["type"].split("/")[0] != "image"
      // );

      if (payload.length > 9) {
        console.log(payload);
        setError('You can upload maximum of 9 photos');
        payload = payload.filter((file, index) => index < 9);
      }
      if (payload.length <= 9) {
        setUploadedPhotos(payload);
        setPreviewPhoto(0);
        // setIsCreatePostModalOpen(false);
        setIsPreviewPostPhotosOpen(true);
      }
      // if (invalidFiles && invalidFiles.length) {
      // 	console.log(payload);
      // 	setError("Please upload only images");
      // 	payload = payload.filter(
      // 		(file) => file && file["type"].split("/")[0] == "image"
      // 	);
      // }
      // if (files && files.length) {
      // 	console.log(payload);
      // 	setError(
      // 		"Some files exceed max. allowed size of 15MB and cannot be uploaded"
      // 	);
      // 	payload = payload.filter((file) => file.size < 15728640);
      // }
      // if (payload.length) {
      // 	setUploadedPhotos(payload);
      // 	setPreviewPhoto(0);
      // 	setIsCreatePostModalOpen(false);
      // 	setIsPreviewPostPhotosOpen(true);
      // 	setIsPreviewPostPhotosOpen(true);
      // }
      // setError("");
    } else if (attachmentType === 'videos') {
      setIsPreviewPostVideosOpen(true);
      let files = payload.filter(file => file.size > 62914560);
      let invalidFiles = payload.filter(file => file && file['type'].split('/')[0] !== 'video');

      if (payload.length > 1) {
        setError('You can upload maximum of 1 video');
        payload = payload.filter((file, index) => index < 1);
      }
      if (invalidFiles && invalidFiles.length) {
        setError('Please upload only videos');
        payload = payload.filter(file => file && file['type'].split('/')[0] == 'video');
      }
      if (files && files.length) {
        setError('The video files exceed allowed size of 60MB and cannot be uploaded');
        payload = payload.filter(file => file.size < 62914560);
      }
      console.log(payload);
      if (payload.length) {
        setUploadedVideos(payload);
        setPreviewVideo(0);
        // setIsCreatePostModalOpen(false);
        setIsPreviewPostVideosOpen(true);
      } else {
        setUploadedVideos(null);
      }
      // setError("");
    }
  };

  return (
    <React.Fragment>
      <div>
        {showFeed && (
          <SocialFeed
            match={match}
            postedData={postedData}
            handleEditPostClick={handleEditPostClick}
            handleSharePostClick={handleSharePostClick}
            handleCreatePostClick={handleCreatePostClick}
            hideAddon={hideAddon}
            scrollableTarget={scrollableTarget}
            isSearch={isSearch}
            isGroupMember={isGroupMember}
            postStatus={postStatus}
            isInfiniteEnabled={isInfiniteEnabled}
            isInGroup={isInGroup}
            isSearchPage={isSearchPage}
          />
        )}
        {user &&
        user.acctId &&
        visibleOptions &&
        socialPostTypeOptions &&
        commentabilityOptions &&
        isCreatePostModalOpen ? (
          <CreatePostModal
            isOpen={isCreatePostModalOpen}
            setPostsList={setPostsList}
            user={user}
            oriVisibleOptions={visibleOptions}
            oriSocialPostTypeOptions={socialPostTypeOptions}
            oriCommentabilityOptions={commentabilityOptions}
            statusChangeHandler={statusChangeHandler}
            handleCreatePostClick={handleCreatePostClick}
            question={question}
            options={options}
            pollTiming={pollTiming}
            removePoll={removePollData}
            savePostData={savePostData}
            selectPhotos={selectPhotos}
            selectVideos={selectVideos}
            uploadedPhotos={uploadedPhotos}
            uploadedVideos={uploadedVideos}
            removeAnUploadedPhoto={removeSinglePhoto}
            removeAnUploadedVideo={removeSingleVideo}
            onPhotosListItemClick={photoIndex => {
              setPreviewPhoto(photoIndex);
              setIsPreviewPostPhotosOpen(true);
            }}
            onVideosListItemClick={videoIndex => {
              setPreviewVideo(videoIndex);
              setIsPreviewPostVideosOpen(true);
            }}
            onPhotosListItemDragStart={draggedItemindex => setDraggedItemIdex(draggedItemindex)}
            onPhotosListItemDrop={droppedItemindex => {
              if (draggedItemindex !== null) {
                let photos = uploadedPhotos;
                let photo = photos[draggedItemindex];
                photos[draggedItemindex] = uploadedPhotos[droppedItemindex];
                photos[droppedItemindex] = photo;
                setUploadedPhotos(photos);
                setDraggedItemIdex(null);
              }
            }}
            onVideosListItemDragStart={draggedItemindex => setDraggedItemIdex(draggedItemindex)}
            onVideosListItemDrop={droppedItemindex => {
              if (draggedItemindex !== null) {
                let videos = uploadedVideos;
                let video = videos[draggedItemindex];
                videos[draggedItemindex] = uploadedVideos[droppedItemindex];
                videos[droppedItemindex] = video;
                setUploadedVideos(videos);
                setDraggedItemIdex(null);
              }
            }}
            isLoading={setIsLoading}
            setError={setError}
            editPost={editPost}
            sharePost={sharePost}
            shareJob={shareJob}
            groupId={groupId}
            postStatus={postStatus}
          />
        ) : (
          <></>
        )}
        <PreviewPostPhotosModal
          isOpen={isPreviewPostPhotosOpen}
          uploadedPhotos={uploadedPhotos}
          previewPhotoIndex={previewPhoto}
          removeAnUploadedPhoto={removeSinglePhoto}
          setPreviewPhoto={setPreviewPhoto}
          selectPhotos={selectPhotos}
          onClose={() => {
            setIsPreviewPostPhotosOpen(false);
            removePollData();
            savePostData('');
            setIsCreatePostModalOpen(true);
            // handleCreatePostClick("post");
          }}
          error={uploadError}
          onPhotosListItemDragStart={draggedItemindex => setDraggedItemIdex(draggedItemindex)}
          onPhotosListItemDrop={droppedItemindex => {
            if (draggedItemindex !== null) {
              let photos = uploadedPhotos;
              let photo = photos[draggedItemindex];
              photos[draggedItemindex] = uploadedPhotos[droppedItemindex];
              photos[droppedItemindex] = photo;
              setUploadedPhotos(photos);
              setDraggedItemIdex(null);
            }
          }}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          setError={setError}
        />
        <PreviewPostVideosModal
          isOpen={isPreviewPostVideosOpen}
          uploadedVideos={uploadedVideos}
          previewVideoIndex={previewVideo}
          removeAnUploadedVideo={removeSingleVideo}
          setPreviewVideo={setPreviewVideo}
          selectVideos={selectVideos}
          onClose={() => {
            setIsPreviewPostVideosOpen(false);
            removePollData();
            savePostData('');
            setIsCreatePostModalOpen(true);
            // handleCreatePostClick("post");
          }}
          error={uploadError}
          onVideosListItemDragStart={draggedItemindex => setDraggedItemIdex(draggedItemindex)}
          onVideosListItemDrop={droppedItemindex => {
            if (draggedItemindex !== null) {
              let videos = uploadedVideos;
              let video = videos[draggedItemindex];
              videos[draggedItemindex] = uploadedVideos[droppedItemindex];
              videos[droppedItemindex] = video;
              setUploadedVideos(videos);
              setDraggedItemIdex(null);
            }
          }}
          setError={setError}
        />
        <CreatePollModal
          isOpen={isCreatePollModalOpen}
          pollDurationOptions={pollDurationOptions}
          statusChangeHandler={statusChangeHandler}
          handleCreatePostClick={handleCreatePostClick}
          savePollData={savePollData}
          editQuestion={question}
          editOptions={options}
          editPollTiming={pollTiming}
          removePoll={removePollData}
        />
      </div>
      {isLoading ? <Backdrop /> : null}
    </React.Fragment>
  );
};

export default SocialFeedControl;
