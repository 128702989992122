import React, { useState, useCallback } from "react";

import ReactDOM from "react-dom";

import { connect, useDispatch, useSelector } from "react-redux";
import { updateProfilePicture } from "configurations/redux/userSlice.js";
import { updateCorporateProfilePicture } from "configurations/redux/corpUserSlice.js";

import Cropper from "react-easy-crop";
import Slider from "@material-ui/core/Slider";
import Divider from "@material-ui/core/Divider";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import { makeStyles } from "@material-ui/core/styles";

import { getCroppedImg, getRotatedImage } from "./canvasUtils";
import { getOrientation } from "get-orientation/browser";

import imageCropStyle from "assets/jss/material-kit-pro-react/views/pageStyle/ImageCropComponent/imageCropStyle.js";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js";

import ImgDialog from "./ImgDialog";
import CardBody from "components/Card/CardBody";
import Card from "components/Card/Card";
import IconLibrary from "../IconLibrary";
import Compressor from "compressorjs";
import CustomDialog from "../../../components/CustomDialog/CustomDialog.js";

const useStyles = makeStyles(imageCropStyle);
const useStylesProfile = makeStyles(profilePageStyle);

const ORIENTATION_TO_ANGLE = {
	"3": 180,
	"6": 90,
	"8": -90,
};

const ImageCrop = ({ pictureSource, requestRecord, onClose }) => {
	const classes = useStyles();
	const classesProfile = useStylesProfile();
	const dispatch = useDispatch();

	const [imageSrc, setImageSrc] = React.useState(pictureSource);
	const [disableSave, setDisableSave] = useState(true);
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [rotation, setRotation] = useState(0);
	const [zoom, setZoom] = useState(1);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
	const [croppedImage, setCroppedImage] = useState(null);
	const [isOpen, setOpen] = useState(false);
	const bizId = useSelector((state) => state.business.bizId);
	const dialogDefault = {
		title: "Warning",
		description: "The file format is not supported. Please use different image",
		buttonYes: "",
		buttonNo: "OK",
	};
	const [errorDialog, setErrorDialog] = React.useState({
		isOpen: false,
		dialogDefault,
	});

	const closeModal = () => {
		setOpen(false);
	};

	React.useEffect(() => {
		setImageSrc(pictureSource);
		setDisableSave(true);
	}, [pictureSource]);
	let fileInput = React.createRef();

	const showCroppedImage = useCallback(async () => {
		try {
			const croppedImage = await getCroppedImg(
				imageSrc,
				croppedAreaPixels,
				rotation
			);
			setCroppedImage(croppedImage);
			console.log(croppedImage);
			//setOpen(true);
			// setDisableSave(false);

			const updateRequest = {
				acctId: requestRecord.acctId,
				email: requestRecord.email,
				profilePic: croppedImage,
				accessToken: localStorage.getItem("token"),
				bizId: bizId,
			};
			if (bizId > 0) {
				dispatch(updateCorporateProfilePicture(updateRequest));
			} else {
				dispatch(updateProfilePicture(updateRequest));
			}

			setTimeout(() => {
				onClose();
			}, 500);
		} catch (e) {
			console.error(e);
		}
	}, [imageSrc, croppedAreaPixels, rotation]);

	const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels);
	}, []);
	const sendRequest = () => {
		console.log(croppedImage);
	};

	const handleClick = () => {
		fileInput.current.click();
	};
	const onFileChange = async (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0];
			let imageDataUrl = await readFile(file);
			const sizeOf = require("image-size");
			let dimensions = "";
			try {
				var img = Buffer.from(imageDataUrl.split(";base64,").pop(), "base64");
				dimensions = sizeOf(img);
			} catch (error) {
				console.log(error);
				setErrorDialog({
					isOpen: true,
					dialogDefault,
					onCancel: () => {
						setErrorDialog({
							isOpen: false,
							dialogDefault,
						});
					},
				});
			}
			// console.log(file);
			const orientation = await getOrientation(file);
			const rotation = ORIENTATION_TO_ANGLE[orientation];
			if (rotation) {
				imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
			}
			if (dimensions != "") {
				if (
					file.size > 1000000 ||
					dimensions.width > 1200 ||
					dimensions.height > 1200
				) {
					new Compressor(file, {
						quality: 0.8,
						maxWidth: 1200,
						maxHeight: 1200,
						checkOrientation: false,
						success: (compressedResult) => {
							console.log(compressedResult);
							const readImage = async (e) => {
								let compressImage = await readFile(compressedResult);
								console.log(compressImage);
								setImageSrc(compressImage);
							};
							readImage();
						},
					});
				} else {
					setImageSrc(imageDataUrl);
				}
			}
			// const imageDataUrl = await resizeFile(file);
			// apply rotation if needed
			// const orientation = await getOrientation(file);
			// const rotation = ORIENTATION_TO_ANGLE[orientation];
			// if (rotation) {
			// 	imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
			// }

			// console.log(imageDataUrl);
			// setImageSrc(imageDataUrl);
		}
	};
	const readFile = (file) => {
		return new Promise((resolve) => {
			const reader = new FileReader();
			reader.addEventListener("load", () => resolve(reader.result), false);
			reader.readAsDataURL(file);
		});
	};

	const updateRequest = {
		acctId: requestRecord.acctId,
		email: requestRecord.email,
		profilePic: croppedImage,
		accessToken: localStorage.getItem("token"),
		bizId: bizId,
	};
	const submitProfilePicture = () => {
		if (bizId > 0) {
			dispatch(updateCorporateProfilePicture(updateRequest));
		} else {
			dispatch(updateProfilePicture(updateRequest));
		}

		onClose();
	};

	return (
		<div>
			<CustomDialog
				confirmDialog={errorDialog}
				setConfirmDialog={setErrorDialog}
			/>
			{imageSrc ? (
				<div>
					<React.Fragment>
						<div className={classes.cropContainer}>
							<Cropper
								image={imageSrc}
								crop={crop}
								rotation={rotation}
								zoom={zoom}
								cropShape={requestRecord.acctTypeId === 2 ? "rect" : "round"}
								aspect={1}
								onCropChange={setCrop}
								onRotationChange={setRotation}
								onCropComplete={onCropComplete}
								onZoomChange={setZoom}
							/>
						</div>

						<ImgDialog img={croppedImage} open={isOpen} onClose={closeModal} />
					</React.Fragment>
					<GridContainer
						justifyContent="center"
						className={classes.gridControl}
					>
						<GridItem xs={12} sm={1} md={1}>
							<p>
								<b>Zoom:</b>
							</p>
						</GridItem>
						<GridItem xs={12} sm={4} md={4}>
							<Slider
								value={zoom}
								min={1}
								max={3}
								step={0.1}
								aria-labelledby="Zoom"
								//classes={{ container: "slider" }}
								onChange={(e, zoom) => setZoom(zoom)}
							/>
						</GridItem>
						<GridItem xs={12} sm={1} md={1}>
							<p>
								<b>Rotate:</b>
							</p>
						</GridItem>
						<GridItem xs={12} sm={4} md={4}>
							<Slider
								value={rotation}
								min={0}
								max={360}
								step={1}
								aria-labelledby="Rotation"
								//classes={{ container: "slider" }}
								onChange={(e, rotation) => setRotation(rotation)}
							/>
						</GridItem>
					</GridContainer>

					{/* <GridContainer justifyContent="flex-end">
						<GridItem xs={12} sm={4} md={4}>
							
						</GridItem>
						<GridItem xs={12} sm={4} md={4}>
							
						</GridItem>
					</GridContainer> */}

					<div className={"fileinput text-center"}>
						<input
							type="file"
							onChange={onFileChange}
							accept="image/*"
							ref={fileInput}
						/>
					</div>

					<div className={classes.pictureButtonFooter}>
						<Button color="grayWrapBorder" round onClick={handleClick}>
							<b> Change</b>
						</Button>
						<Button onClick={() => showCroppedImage()} round color="oppty">
							<b>Update</b>
						</Button>
					</div>
				</div>
			) : (
				<React.Fragment>
					<div>
						<Card className={classesProfile.modalCardBorder}>
							<CardBody className={classesProfile.modalBackground}>
								<GridContainer
									justifyContent="center"
									alignContent="center"
									alignItems="center"
									className={
										classesProfile.uploadCardBackground +
										" " +
										classes.cropContainer
									}
								>
									<GridItem>
										<div className={"fileinput text-center"}>
											<input
												type="file"
												onChange={onFileChange}
												accept="image/*"
												ref={fileInput}
											/>
										</div>
										<div>
											<h5
												className={
													classesProfile.title +
													" " +
													classesProfile.uploadIcon +
													" " +
													classesProfile.textCenter
												}
											>
												<IconLibrary.Upload />
											</h5>
											<h5
												className={
													classesProfile.title +
													" " +
													classesProfile.uploadTitle +
													" " +
													classesProfile.textCenter
												}
											>
												You have not set up your profile picture yet.
											</h5>
											<h5
												className={
													classesProfile.title +
													" " +
													classesProfile.uploadSubtitle +
													" " +
													classesProfile.textCenter
												}
											>
												Please set up your profile picture to have higher chance
												in opportunity!
											</h5>
										</div>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
						<div className={classesProfile.cardBodyFooter}>
							<Button round color="oppty" onClick={() => handleClick()}>
								<b>UPLOAD</b>
							</Button>
						</div>
					</div>
					{/* <Divider />
					<GridContainer justifyContent="center" className={classes.gridButton}>
						<GridItem xs={12} sm={4} md={4}>
							<Button
								color="oppty"
								round
								fullWidth
								onClick={() => handleClick()}
							>
								<b>Upload</b>
							</Button>
						</GridItem>
					</GridContainer> */}
				</React.Fragment>
			)}
		</div>
	);
};

export default ImageCrop;
