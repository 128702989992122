import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";

import Header from "views/Component/Header.js";
import Footer from "views/Component/Footer.js";

import NavPills from "components/NavPills/NavPills.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import Parallax from "components/Parallax/Parallax.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

import jobStatusPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/JobStatusPage/jobStatusPageStyle.js";
import { getAppliedJobPostList } from "configurations/redux/appliedJobSlice";
import { getFavJobPostList } from "configurations/redux/favJobSlice";

import LoadingModal from "views/Component/LoadingModal";
import NoActivityStatusModal from "../NoActivityStatusModal/NoActivityStatusModal";
import SavedJobSection from "./SavedJobSection.js";
import AppliedJobSection from "./AppliedJobSection.js";
import Backdrop from "../../Component/Backdrop.js";
import { gaPageView } from "configurations/configurations";
const useStyles = makeStyles(jobStatusPageStyle);

export default function JobStatusPage() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const userStatus = useSelector((state) => state.auth.user);

	const [favJobShow, setFavJobShow] = React.useState(false);
	const [appliedJobShow, setAppliedJobShow] = React.useState(false);

	const favJobLoading = useSelector((state) => state.favJob.isLoading); //initial loading for fav job
	const appliedJobLoading = useSelector((state) => state.appliedJob.isLoading); //initial loading for applied job

	const loadingAppliedJobList = useSelector(
		(state) => state.appliedJob.loadingJobList
	);
	const loadingFavJobList = useSelector((state) => state.favJob.loadingJobList);

	const favJobUpdated = useSelector((state) => state.favJob.isUpdated);
	const appliedJobUpdated = useSelector((state) => state.appliedJob.isUpdated);

	const favJobPostList = useSelector((state) => state.favJob.jobPostList);
	const appliedJobPostList = useSelector(
		(state) => state.appliedJob.jobPostList
	);
	const favJobStatusLoading = useSelector((state) => state.favJob.isChanged);
	const appliedJobStatusLoading = useSelector(
		(state) => state.appliedJob.isChanged
	);
	const notificationClick = useSelector(
		(state) => state.eventNotification.notificationClick
	);
	const applyJobRequest = {
		acctId: userStatus.acctId,
		email: userStatus.email,
		accessToken: localStorage.getItem("token"),
		lastRecordSize: 0,
		requestRecordSize: 5,
		paginationClick: false,
	};
	const favJobRequest = {
		acctId: userStatus.acctId,
		accessToken: localStorage.getItem("token"),
	};

	const clickFavJobTab = () => {
		setFavJobShow(true);
		setAppliedJobShow(false);
		dispatch(getFavJobPostList(applyJobRequest));
	};
	const clickAppliedJobTab = () => {
		setAppliedJobShow(true);
		setFavJobShow(false);
		dispatch(getAppliedJobPostList(applyJobRequest));
	};

	React.useEffect(() => {
		if (document.readyState === "complete") clickAppliedJobTab();
	}, [notificationClick]);

	React.useEffect(() => {
		gaPageView(window.location.pathname);
		const defaultJobRequest = {
			acctId: userStatus.acctId,
			email: userStatus.email,
			accessToken: localStorage.getItem("token"),
			lastRecordSize: 0,
			requestRecordSize: 1,
			paginationClick: false,
		};
		dispatch(getFavJobPostList(defaultJobRequest));
		if (document.readyState !== "loading") clickAppliedJobTab();
		window.scrollTo(0, 0);
		// let CancelToken = axios.CancelToken;
		// const source = CancelToken.source();
		// let mounted = true;
		// setAppliedJobShow(true);
		// /* dispatch(getAppliedJobPostList(applyJobRequest));
		// dispatch(getFavJobPostList(favJobRequest)); */
		// const getAppliedJob = async () => {
		// 	try {
		// 		await dispatch(getAppliedJobPostList(applyJobRequest));
		// 	} catch (error) {
		// 		if (axios.isCancel(error)) {
		// 			console.log(error);
		// 		} else {
		// 			throw error;
		// 		}
		// 	}
		// };
		// const getSavedJob = async () => {
		// 	try {
		// 		await dispatch(getFavJobPostList(applyJobRequest));
		// 	} catch (error) {
		// 		if (axios.isCancel(error)) {
		// 			console.log(error);
		// 		} else {
		// 			throw error;
		// 		}
		// 	}
		// };
		// if (mounted) {
		// 	getAppliedJob();
		// 	window.scrollTo(0, 0);
		// }
		// return () => {
		// 	mounted = false;
		// 	source.cancel();
		// };
	}, []);

	return (
		<>
			<Header />
			<div className={classes.body}>
				{/* <Parallax
				image={require("assets/img/bg-13.jpg")}
				className={classes.parallax}
			/> */}
				<div
					className={
						classes.mainRaised + " " + classes.main + " " + classes.pageHeader
					}
					id="pageHeader"
				>
					<div className={classes.container}>
						{favJobUpdated && appliedJobUpdated ? (
							<React.Fragment>
								{/* {favJobStatusLoading || appliedJobLoading ? (
								<LoadingModal />
							) : null} */}
								<GridContainer justifyContent="center">
									<GridItem>
										<h3 className={classes.title}>Applications</h3>
									</GridItem>
									<GridItem
										xs={6}
										sm={6}
										md={6}
										className={classes.gridPadding}
									>
										<Button
											fullWidth
											round
											className={
												appliedJobShow
													? classes.selectedTabButton
													: classes.tabButton
											}
											onClick={() => {
												clickAppliedJobTab();
											}}
										>
											<b>
												Applied Job
												{appliedJobPostList.length > 0 &&
													appliedJobPostList[0].jobCount > 1 &&
													"s"}{" "}
												(
												{appliedJobPostList.length > 0
													? appliedJobPostList[0].jobCount
													: 0}
												)
											</b>
										</Button>
									</GridItem>
									<GridItem
										xs={6}
										sm={6}
										md={6}
										className={classes.gridPadding}
									>
										<Button
											fullWidth
											round
											onClick={() => {
												clickFavJobTab();
											}}
											className={
												favJobShow
													? classes.selectedTabButton
													: classes.tabButton
											}
										>
											<b>
												Saved Job
												{favJobPostList.length > 0 &&
													favJobPostList[0].favCount > 1 &&
													"s"}{" "}
												(
												{favJobPostList.length > 0
													? favJobPostList[0].favCount
													: 0}
												)
											</b>
										</Button>
									</GridItem>
								</GridContainer>

								<GridContainer>
									<GridItem className={classes.gridPadding}>
										{appliedJobShow && <AppliedJobSection />}
										{favJobShow && <SavedJobSection />}
									</GridItem>
								</GridContainer>
							</React.Fragment>
						) : (
							<NoActivityStatusModal />
						)}
						{loadingAppliedJobList ? <Backdrop /> : null}
						{loadingFavJobList ? <Backdrop /> : null}
					</div>
				</div>
				<Footer />
			</div>
		</>
	);
}
