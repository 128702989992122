import React from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import {
	getCountryId,
	getLocationId,
	getAreaCodeId,
	instanceUrl,
} from "configurations/configurations.js";

import { makeStyles } from "@material-ui/core/styles";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import Email from "@material-ui/icons/Email";
import Phone from "@material-ui/icons/Phone";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PeopleIcon from "@material-ui/icons/People";

import corporateProfilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/CorporateProfilePage/corporateProfilePageStyle.js";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/CreateOutlined";
import CorporateBasicProfileEditModal from "./CorporateBasicProfileEditModal.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Tooltip from "@material-ui/core/Tooltip";
import IconLibrary from "views/Component/IconLibrary.js";
import Button from "components/CustomButtons/Button.js";

const useStyles = makeStyles(corporateProfilePageStyle);

export default function CorporateManagerDetailSection() {
	const classes = useStyles();
	const isViewed = useSelector((state) => state.corpUser.isViewed);
	const profileRecord = useSelector((state) => state.corpUser.profileRecord);
	const user = useSelector((state) => state.auth);
	const [countryList, setCountryList] = React.useState([]);
	const [areaCodeList, setAreaCodeList] = React.useState([]);
	const [locationList, setLocationList] = React.useState([]);
	const [basicProfileEditModal, setBasicProfileEditModal] = React.useState(
		false
	);

	const closeBasicProfileEditModal = () => {
		setBasicProfileEditModal(false);
	};

	const fetchAreaList = async (token) => {
		const res = await getAreaCodeId(token);
		setAreaCodeList(res.data.payload);
	};
	const fetchLocationList = async (token) => {
		const res = await getLocationId();
		setLocationList(res.data.payload);
	};
	const fetchCountryList = async (token) => {
		const res = await getCountryId(token);
		setCountryList(res.data.payload);
	};

	React.useEffect(() => {
		let CancelToken = axios.CancelToken;
		const source = CancelToken.source();

		const fetchCountryList = async () => {
			try {
				const res = await axios.get(`${instanceUrl}/api/md/get-all-country`, {
					cancelToken: source.token,
				});
				setCountryList(res.data.payload);
			} catch (error) {
				if (axios.isCancel(error)) {
					//console.log("unmount caused");
				} else {
					throw error;
				}
			}
		};
		const fetchAreaList = async () => {
			try {
				const res = await axios.get(`${instanceUrl}/api/md/get-all-area-code`, {
					cancelToken: source.token,
				});
				setAreaCodeList(res.data.payload);
			} catch (error) {
				if (axios.isCancel(error)) {
					//console.log("unmount caused");
				} else {
					throw error;
				}
			}
		};
		const fetchLocationList = async () => {
			try {
				const res = await axios.get(`${instanceUrl}/api/md/get-all-location`, {
					cancelToken: source.token,
				});
				setLocationList(res.data.payload);
			} catch (error) {
				if (axios.isCancel(error)) {
					//console.log("unmount caused");
				} else {
					throw error;
				}
			}
		};
		let mounted = true;
		if (mounted) {
			fetchCountryList();
			fetchLocationList();
			fetchAreaList();
		}

		return () => {
			mounted = false;
			source.cancel("Unmonunted Triggered");
		};
	}, []);

	return (
		<React.Fragment>
			<Card className={classes.cardFrame}>
				<CardBody>
					<GridContainer
						justifyContent="space-between"
						alignContent="center"
						alignItems="center"
					>
						<GridItem xs={9} sm={9} md={9}>
							<h4 className={classes.title}>Contact Detail</h4>
						</GridItem>
						<GridItem
							xs={2}
							sm={2}
							md={2}
							lg={2}
							className={classes.buttonColor}
						>
							{isViewed ? null : (
								<Tooltip
									title={
										<p className={classes.tooltipDescription}>Edit Profile</p>
									}
									placement="top"
									classes={{ tooltip: classes.tooltip }}
								>
									<Button
										justIcon
										style={{ boxShadow: "none" }}
										color="transparent"
										className={classes.buttonIconMargin}
										onClick={() => {
											setBasicProfileEditModal(true);
										}}
									>
										<IconLibrary.Pen />
									</Button>
								</Tooltip>
							)}
						</GridItem>
					</GridContainer>
					<div className={classes.infoAreaContainer}>
						{profileRecord.email ? (
							<div className={classes.infoAreaStyle}>
								<IconLibrary.Mail />
								{profileRecord.email}
							</div>
						) : null}

						{profileRecord.contactNo && profileRecord.areaCodeId ? (
							<div className={classes.infoAreaStyle}>
								<IconLibrary.Phone />
								{" + " +
									areaCodeList
										.filter((item) => {
											return item.areaCodeId === profileRecord.areaCodeId;
										})
										.map((item) => {
											return item.areaCode;
										}) +
									" " +
									profileRecord.contactNo}
							</div>
						) : null}

						{profileRecord.countryId ? (
							<div className={classes.infoAreaStyle}>
								<IconLibrary.LocationOutlined />
								{profileRecord.locationId !== 0 && profileRecord.locationId
									? countryList
											.filter((item) => {
												return item.countryId === profileRecord.countryId;
											})
											.map((item) => {
												return item.countryName;
											}) +
									  ", " +
									  locationList
											.filter((item) => {
												return item.locationId === profileRecord.locationId;
											})
											.map((item) => {
												return item.locationName;
											})
									: countryList
											.filter((item) => {
												return item.countryId === profileRecord.countryId;
											})
											.map((item) => {
												return item.countryName;
											})}
							</div>
						) : // <InfoArea
						// 	className={classes.info}
						// 	title={
						// 		profileRecord.locationId !== 0 && profileRecord.locationId
						// 			? countryList
						// 					.filter((item) => {
						// 						return item.countryId === profileRecord.countryId;
						// 					})
						// 					.map((item) => {
						// 						return item.countryName;
						// 					}) +
						// 			  ", " +
						// 			  locationList
						// 					.filter((item) => {
						// 						return item.locationId === profileRecord.locationId;
						// 					})
						// 					.map((item) => {
						// 						return item.locationName;
						// 					})
						// 			: countryList
						// 					.filter((item) => {
						// 						return item.countryId === profileRecord.countryId;
						// 					})
						// 					.map((item) => {
						// 						return item.countryName;
						// 					})
						// 		/* (countryList
						// 			.filter((item) => {
						// 				return item.countryId === profileRecord.countryId;
						// 			})
						// 			.map((item) => {
						// 				return item.countryName;
						// 			}),
						// 		profileRecord.locationId !== 0 && "hi")
						// 		/* 	" ," +
						// 			locationList
						// 				.filter((item) => {
						// 					return item.locationId === profileRecord.locationId;
						// 				})
						// 				.map((item) => {
						// 					return item.locationName;
						// 				}) */
						// 	}
						// 	description={""}
						// 	icon={LocationOnIcon}
						// />
						null}
						{/* {user.buildProfileFlag === 1 ? (
							<div className={classes.infoAreaStyle}>
								<IconLibrary.User />
								{profileRecord.firstName +
									" " +
									profileRecord.middleName +
									" " +
									profileRecord.surname}
							</div>
						) : null} */}
					</div>
				</CardBody>
			</Card>
			<CorporateBasicProfileEditModal
				open={basicProfileEditModal}
				onClose={closeBasicProfileEditModal}
			/>
		</React.Fragment>
	);
}
