import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
	instanceUrl,
	header,
	emptyCache,
	TimeFormatFirstDateOfMonth,
} from "configurations/configurations.js";

const genericSearchSlice = createSlice({
	name: "genericSearch",
	initialState: {
		isLoading: false,
		searchResultList: [],
		keyword: "",
		option: "", //filter page type ex. people, job
		isSearchBox: false, //used for mobile
	},
	reducers: {
		SEARCH_LOADING: (state) => {
			state.isLoading = true;
		},
		SEARCH_KEYWORD_GET_SUCCESS: (state, action) => {
			state.isLoading = false;
			state.searchResultList = action.payload.searchResultList;
			localStorage.setItem("token", action.payload.token);
		},
		SEARCH_KEYWORD_FAILED: (state) => {
			state.isLoading = false;
		},
		SEARCH_KEYWORD_SAVE: (state, action) => {
			state.keyword = action.payload.keyword;
		},
		SEARCH_OPTION_SAVE: (state, action) => {
			state.option = action.payload.option;
		},
		SEARCH_BOX_OPEN: (state, action) => {
			state.isSearchBox = true;
		},
		SEARCH_BOX_CLOSE: (state, action) => {
			state.isSearchBox = false;
		},
	},
});

const {
	SEARCH_LOADING,
	SEARCH_KEYWORD_FAILED,
	SEARCH_KEYWORD_GET_SUCCESS,
	SEARCH_KEYWORD_SAVE,
	SEARCH_OPTION_SAVE,
	SEARCH_BOX_CLOSE,
	SEARCH_BOX_OPEN,
} = genericSearchSlice.actions;

export const getSearchResult = ({
	emmail,
	acctId,
	accessToken,
	keyword,
	requestRecordSize,
	cancelToken,
}) => async (dispatch) => {
	const body = {
		emmail,
		acctId,
		accessToken,
		keyword,
		requestRecordSize,
	};
	dispatch(SEARCH_LOADING());

	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/gs/get-search-result-by-keyword`,
		params: body,
		cancelToken: cancelToken,
	})
		.then((res) => {
			if (res.status === 201) {
				var searchList = [];
				for (var i = 0; i < res.data.payload.searchResultList.length; i++) {
					searchList.push({
						value: res.data.payload.searchResultList[i].shDescription,
						name: res.data.payload.searchResultList[i].shDescription,
						totalCount: res.data.payload.searchResultList[i].totalCount,
					});
				}
				const response = {
					token: res.data.token,
					searchResultList: searchList,
				};
				dispatch(SEARCH_KEYWORD_GET_SUCCESS(response));
			}
		})
		.catch((err) => {
			/* if (err.message.includes("503")) {
				emptyCache();
			} */
			console.log(err);
		});
};

export const saveKeyword = ({ keyword }) => (dispatch) => {
	const response = {
		keyword,
	};
	dispatch(SEARCH_KEYWORD_SAVE(response));
};
export const saveOption = ({ option }) => (dispatch) => {
	const response = {
		option,
	};
	dispatch(SEARCH_OPTION_SAVE(response));
};
export const openSearchBox = () => (dispatch) => {
	dispatch(SEARCH_BOX_OPEN());
};
export const closeSearchBox = () => (dispatch) => {
	dispatch(SEARCH_BOX_CLOSE());
};
export default genericSearchSlice.reducer;
