import React, { Fragment, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import axios from 'axios';
import clsx from 'clsx';

import { Box, Button, makeStyles, Modal, Typography, Popover } from '@material-ui/core';

import Header from 'views/Component/Header.js';
import LeftSidebar from 'views/Component/LeftSidebar.js';

import Footer from 'views/Component/Footer.js';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

import { activateMasterCode, cleanMasterCode } from 'configurations/redux/masterFunctionSlice.js';
import { activateEditType, deactivateEditType } from 'configurations/redux/editProfileModalSlice';
import { errorCleanUp } from 'configurations/redux/authSlice.js';
import { decryptData } from 'configurations/configurations.js';
import { getProfiles } from 'configurations/redux/userSlice.js';
import Hidden from '@material-ui/core/Hidden';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import testimonialsHeader from 'assets/img/testimonials-header.png';

import testimonialsPageStyles from 'assets/jss/material-kit-pro-react/views/pageStyle/TestimonialsPage/testimonialsPageStyles';
import RightSideBarUserList from 'views/Component/RightSideBarUserList.js';
import OwnTestimonialComponent from './OwnTestimonialComponent.js';
import AuthorTestimonialComponent from './AuthorTestimonialComponent.js';
import PendingTestimonialComponent from './PendingTestimonialComponent.js';
import EditModal from 'views/Component/ProfileView/EditProfile/EditModal.js';

const TestimonialsPage = ({ match }) => {
  const styles = makeStyles(testimonialsPageStyles)();
  const history = useHistory();
  const dispatch = useDispatch();
  const [optionToShow, setOption] = useState('myTestimonials');
  const [isViewed, setIsViewed] = useState();
  const userStatus = useSelector(state => state.auth.user);
  const isMe = decryptData(match.params.acctId) === userStatus.acctId;

  console.log(match);
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    history.replace(match.url);
  }, []);
  React.useEffect(() => {
    const decryptId = decryptData(match.params.acctId);
    console.log(decryptId);
    if (decryptId === userStatus.acctId) {
      setIsViewed(false);
    } else {
      setIsViewed(true);
    }
  }, []);
  console.log(isViewed);
  const tabs = ['myTestimonials', 'pendingRequests', 'testimonialsGiven'];

  const isEmptyState = false;
  const TestimonialDescription = () => {
    return (
      <React.Fragment>
        <div className={styles.testimonialsHeader}>
          <Hidden mdDown>
            <div className={styles.headerLeft}>
              <img src={testimonialsHeader} alt="" />
            </div>
          </Hidden>

          <div className={styles.headerRight}>
            <Typography component="h2">How To Request For Testimonials and Boost Your Profile</Typography>

            <div className={styles.headerPoint}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <path
                  id="Shape"
                  d="M0,10A10,10,0,0,1,10,0a9.9,9.9,0,0,1,5.72,1.81L14.28,3.25A7.918,7.918,0,0,0,10,2a8.046,8.046,0,1,0,7.79,6.22L19.4,6.61A10,10,0,1,1,0,10Zm4.35.36L5.76,8.95,8.59,11.78l10-10L20,3.18,8.59,14.6Z"
                  fill="#20f0a1"
                />
              </svg>
              Only ask people you know professionally and have worked with
            </div>

            <div className={styles.headerPoint}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <path
                  id="Shape"
                  d="M0,10A10,10,0,0,1,10,0a9.9,9.9,0,0,1,5.72,1.81L14.28,3.25A7.918,7.918,0,0,0,10,2a8.046,8.046,0,1,0,7.79,6.22L19.4,6.61A10,10,0,1,1,0,10Zm4.35.36L5.76,8.95,8.59,11.78l10-10L20,3.18,8.59,14.6Z"
                  fill="#20f0a1"
                />
              </svg>
              Ask different people to highlight a wide range of your skills
            </div>

            <div className={styles.headerPoint}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <path
                  id="Shape"
                  d="M0,10A10,10,0,0,1,10,0a9.9,9.9,0,0,1,5.72,1.81L14.28,3.25A7.918,7.918,0,0,0,10,2a8.046,8.046,0,1,0,7.79,6.22L19.4,6.61A10,10,0,1,1,0,10Zm4.35.36L5.76,8.95,8.59,11.78l10-10L20,3.18,8.59,14.6Z"
                  fill="#20f0a1"
                />
              </svg>
              Write a Testimonial for someone else and it will be easier to get them to <br />
              reciprocate and write one for you too!
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };
  return (
    <div>
      {/* {user && */}
      <Header color={'transparent'} loggedInStatus={false} />
      <div className={styles.body}>
        <div id="pageHeader" className={styles.pageHeader}>
          <div className={styles.container} id="testimonialPage">
            <GridContainer spacing={2} justifyContent="center">
              <Hidden smDown>
                <GridItem md={3} lg={2}>
                  <LeftSidebar />
                </GridItem>
              </Hidden>

              <GridItem xs={12} sm={12} md={9} lg={8}>
                <Card className={styles.cardFrame}>
                  <CardBody>
                    <h4 style={{ fontSize: '20px', fontWeight: 700 }}>
                      {isMe ? 'Manage Testimonials' : 'View Testimonials'}
                    </h4>
                    <div className={styles.testimonialsContentHeader}>
                      <Button
                        onClick={() => {
                          const response = {
                            type: 'WRITE_TESTIMONIAL',
                          };
                          dispatch(activateEditType(response));
                        }}
                      >
                        Submit
                      </Button>
                      <Button
                        onClick={() => {
                          const response = {
                            type: 'REQUEST_TESTIMONIAL',
                          };
                          dispatch(activateEditType(response));
                        }}
                      >
                        Request
                      </Button>
                    </div>
                    <div className={styles.testimonialsContentTabsContainer}>
                      <div
                        className={clsx({
                          [styles.tab]: true,
                          [styles.tabActive]: optionToShow == 'myTestimonials',
                        })}
                        onClick={() => {
                          setOption('myTestimonials');
                        }}
                      >
                        <p className={styles.tabTitle}>Received</p>
                        <div
                          className={clsx({
                            [styles.tabActiveBorder]: optionToShow == 'myTestimonials',
                          })}
                        ></div>
                      </div>
                      {isMe && (
                        <div
                          className={clsx({
                            [styles.tab]: true,
                            [styles.tabActive]: optionToShow == 'pendingRequests',
                          })}
                          onClick={() => {
                            setOption('pendingRequests');
                          }}
                        >
                          <p className={styles.tabTitle}>Pending</p>
                          <div
                            className={clsx({
                              [styles.tabActiveBorder]: optionToShow == 'pendingRequests',
                            })}
                          ></div>
                        </div>
                      )}

                      <div
                        className={clsx({
                          [styles.tab]: true,
                          [styles.tabActive]: optionToShow == 'testimonialsGiven',
                        })}
                        onClick={() => {
                          setOption('testimonialsGiven');
                        }}
                      >
                        <p className={styles.tabTitle}>Given</p>
                        <div
                          className={clsx({
                            [styles.tabActiveBorder]: optionToShow == 'testimonialsGiven',
                          })}
                        ></div>
                      </div>
                    </div>
                    <div className={styles.testimonialsContent}>
                      {optionToShow === tabs[0] && <OwnTestimonialComponent match={match} />}
                      {optionToShow === tabs[1] && <PendingTestimonialComponent match={match} />}
                      {optionToShow === tabs[2] && <AuthorTestimonialComponent match={match} />}
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
              <Hidden mdDown>
                <GridItem md={2}>
                  <RightSideBarUserList />
                </GridItem>
              </Hidden>
            </GridContainer>
          </div>
        </div>

        <EditModal />
        <Footer />
      </div>
      {/* } */}
    </div>
  );
};

export default TestimonialsPage;
