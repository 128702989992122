import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import ImageList from "@material-ui/core/ImageList";
// import ImageListItem from "@material-ui/core/ImageListItem";
import profileViewActivityPostCardStyles from "../../../../assets/jss/material-kit-pro-react/views/componentStyle/profileView/myActivity/profileViewActivityPostCardStyles.js";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "space-around",
		overflow: "hidden",
		backgroundColor: theme.palette.background.paper,
	},
	imageList: {
		width: 500,
		height: 450,
	},
}));

export default function PostImageList({ attachmentList }) {
	const classes = useStyles();
	const stylesPost = makeStyles(profileViewActivityPostCardStyles)();
	console.log(attachmentList);
	return (
		<div className={classes.root + " " + stylesPost.imgHeight}>
			{/* <ImageList cols={attachmentList.length}>
				{attachmentList.map((item, index) =>
					index < 2 ? (
						<ImageListItem key={item.postTypeId}>
							<img src={item.original} className={stylesPost.imgBackground} />
							<img src={item.original} />
						</ImageListItem>
					) : null
				)}
			</ImageList>
			{attachmentList.length > 2 ? (
				<ImageList cols={attachmentList.length}>
					{attachmentList.map((item, index) =>
						index > 1 ? (
							<ImageListItem key={item.postTypeId}>
								<img src={item.original} />
							</ImageListItem>
						) : null
					)}
				</ImageList>
			) : null} */}
		</div>
	);
}
