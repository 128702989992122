import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { educationUpdateSchema } from 'configurations/configurations.js';
import {
  getCountryId,
  getFieldStudyId,
  getLocationId,
  getLocationIdByCountry,
  getEducationLevelId,
  getSchoolId,
  getStartYearData,
  getEndYearData,
  getMonthData,
} from 'configurations/configurations.js';
import { updateEducationProfile, editEducationProfile } from 'configurations/redux/userSlice.js';
/* import ProfileStrengthTip from "./ProfileStrengthTip.js"; */
import Switch from '@material-ui/core/Switch';

import Hidden from '@material-ui/core/Hidden';
import FormControl from '@material-ui/core/FormControl';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DialogActions from '@material-ui/core/DialogActions';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import Danger from 'components/Typography/Danger.js';

import Close from '@material-ui/icons/Close';
import SchoolIcon from '@material-ui/icons/School';
import Check from '@material-ui/icons/Check';

import profilePageStyle from 'assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js';
import { isDesktop } from 'react-device-detect';
import IconLibrary from 'views/Component/IconLibrary.js';
import { Typography, Button, Modal } from '@material-ui/core';
import { activateEditType } from 'configurations/redux/editProfileModalSlice';
import ConfirmDialog from 'components/CustomDialog/ConfirmDialog.js';
import { deactivateEditType } from 'configurations/redux/editProfileModalSlice';


const useStyles = makeStyles(profilePageStyle);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const filter = createFilterOptions();

export default function EditEducationModal({
  eduRecord,
  eduRecId,
  saveDetailModalProps,
  onCancelChangesConfirm,
  setIsChanged,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const educationRecords = useSelector(state => state.user.educationRecords);
  const [countryList, setCountryList] = React.useState([]);
  const [locationList, setLocationList] = React.useState([]);
  const [studyFieldList, setStudyFieldList] = React.useState([]);
  const [educationLevelList, setEducationLevelList] = React.useState([]);
  const [schoolList, setSchoolList] = React.useState([]);
  const startYearDataList = getStartYearData();
  const endYearDataList = getEndYearData();
  const monthDataList = getMonthData();
  const [currentStatus, setCurrentStatus] = React.useState([0, 1]);
  const [countryChange, setCountryChange] = React.useState();
  const [buttonVisible, setButtonVisible] = React.useState(false);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = React.useState(false);
  const isMainModalCloseRequest = useSelector(state => state.editProfileModal.isMainModalCloseRequest);

  const handleToggle = value => {
    const currentIndex = currentStatus.indexOf(value);
    const newChecked = [...currentStatus];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCurrentStatus(newChecked);
  };

  const fetchCountryList = async () => {
    const res = await getCountryId();
    setCountryList(res.data.payload);
  };
  const fetchLocationList = async () => {
    const res = await getLocationId();
    setLocationList(res.data.payload);
  };
  const fetchStudyFieldList = async () => {
    const res = await getFieldStudyId();
    setStudyFieldList(res.data.payload);
  };
  const fetchEducationLevelList = async () => {
    const res = await getEducationLevelId();
    setEducationLevelList(res.data.payload);
  };
  const fetchSchoolList = async () => {
    const res = await getSchoolId();
    setSchoolList(res.data.payload);
  };

  React.useEffect(() => {
    fetchCountryList();
    // fetchLocationList();
    fetchStudyFieldList();
    fetchEducationLevelList();
    fetchSchoolList();
  }, []);

  React.useEffect(() => {
    if (countryChange) {
      const fetchLocationListByCountry = async countryId => {
        const res = await getLocationIdByCountry(countryId);
        setLocationList(res.data.payload);
      };
      fetchLocationListByCountry(countryChange);
    }
  }, [countryChange]);

  const modalResize = () => {
    // var timer = setInterval(() => {
    var modalResize = document.getElementById('modalCard');
    if (modalResize) {
      // clearInterval(timer);
      var maxSize = window.innerHeight - 74 - 80 - 70;
      if (maxSize < 0) maxSize = 0;
      modalResize.style.height = maxSize * 0.8 + 'px';
      // } else {
      // 	setTimeout(() => {
      // 		clearInterval(timer);
      // 	}, 3000);
    }
    // }, 300);
    // return () => clearInterval(timer);
  };
  React.useEffect(() => {
    setButtonVisible(false);
  }, [eduRecId]);

  const cancelForm = {
    title: 'Discard Action',
    text: 'All the actions will be discarded',
    cancelText: 'No',
    confirmText: 'Yes',
  };

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        initialValues={{
          school: eduRecord ? eduRecord.school : '',
          schoolId: eduRecord ? eduRecord.schoolId : '',
          countryId: eduRecord ? eduRecord.countryId : '',
          locationId: eduRecord ? eduRecord.locationId : '',
          studyFieldId: eduRecord ? eduRecord.studyFieldId : '',
          eduLvlId: eduRecord ? eduRecord.eduLvlId : '',
          courseName: eduRecord ? eduRecord.courseName : '',
          startYear: eduRecord ? eduRecord.startDate.substring(0, 4) : '',
          startMonth: eduRecord ? eduRecord.startDate.substring(5, 7) : '',
          endYear: eduRecord ? (eduRecord.currentInProgressFlag === 1 ? '0' : eduRecord.endDate.substring(0, 4)) : '0',
          endMonth: eduRecord ? (eduRecord.currentInProgressFlag === 1 ? '0' : eduRecord.endDate.substring(5, 7)) : '0',
          currentInProgressFlag: eduRecord ? eduRecord.currentInProgressFlag : 0,
          achievement: eduRecord ? eduRecord.achievement : '',
          extraCurricular: eduRecord ? eduRecord.extraCurricular : '',
          description: eduRecord ? eduRecord.description : '',
          studyField: eduRecord ? eduRecord.studyField : '',
          educationAutoEnd: educationRecords
            .filter(item => {
              return item.eduRecId !== eduRecId;
            })
            .filter(item => {
              return item.currentInProgressFlag !== 0;
            })
            .map(item => {
              const record = { recId: item.eduRecId, value: true };
              return record;
            }),
          /* requestNotifyRecord: expRecord.requestNotifyRecord
            ? expRecord.requestNotifyRecord
            : false, */
          requestNotifyRecord: eduRecId === -1 ? true : false,
          /* requestNotifyRecord: true, */
          isVisible: eduRecord ? eduRecord.isVisible : 1,
        }}
        validationSchema={educationUpdateSchema}
        onSubmit={data => {
          console.log(data);
          const endDate = data.currentInProgressFlag === 1 ? null : data.endYear + '-' + data.endMonth + '-01';
          //console.log(endDate);
          const requestData = {
            email: user.email,
            acctId: user.acctId,
            eduRecId: eduRecId,
            school: data.school.trim(),
            schoolId: data.schoolId,
            countryId: data.countryId,
            locationId: data.locationId,
            studyFieldId: data.studyFieldId,
            eduLvlId: data.eduLvlId,
            courseName: data.courseName.trim(),
            startDate: data.startYear + '-' + data.startMonth + '-01',
            endDate: endDate,
            studyField: data.studyField,
            currentInProgressFlag: data.currentInProgressFlag,
            achievement: data.achievement.trim(),
            extraCurricular: data.extraCurricular.trim(),
            description: data.description.trim(),
            accessToken: localStorage.getItem('token'),
            isVisible: data.isVisible,
            requestNotifyRecord: data.requestNotifyRecord,
            educationAutoEnd: data.educationAutoEnd,
          };

          {
            eduRecId === -1
              ? dispatch(updateEducationProfile(requestData))
              : dispatch(editEducationProfile(requestData));
          }
          console.log(requestData);
          if (data) {
            const response = {
              type: 'EDIT_PROFILE',
              tabType: 'EDUCATION',
              isTabShow: false,
              /* extension: {
                recordId: -1,
              }, */
            };

            dispatch(activateEditType(response));
            setIsChanged(false);
          }
        }}
      >
        {({
          handleChange,
          values,
          errors,
          touched,
          handleBlur,
          handleSubmit,
          setFieldValue,
          resetForm,
          setFieldError,
          setErrors,
        }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <Card className={classes.modalCardBorder} style={{ marginTop: '10px' }}>
                <CardBody id="modalCard">
                  <GridContainer>
                    <GridItem className={classes.buttonHeader + ' ' + classes.gridItem}>
                      <Typography component="h2" className={classes.tabTypeTitleSection}>
                        Education
                      </Typography>

                      <Typography
                        component="div"
                        style={{
                          visibility: buttonVisible ? 'visible' : 'hidden',
                        }}
                      >
                        <Button className={classes.saveProfileInformationBtn} onClick={handleSubmit}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 24 18">
                            <path
                              id="Path"
                              d="M7.636,14.239,1.909,8.6,0,10.478,7.636,18,24,1.881,22.091,0Z"
                              fill="#0ab98d"
                            />
                          </svg>
                          <span>SAVE</span>
                        </Button>
                        <Button
                          className={classes.cancelEditBtn}
                          onClick={() => {
                            saveDetailModalProps.setModalOpen(true);
                          }}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                            <path
                              id="Path"
                              d="M14,1.41,12.59,0,7,5.59,1.41,0,0,1.41,5.59,7,0,12.59,1.41,14,7,8.41,12.59,14,14,12.59,8.41,7Z"
                              fill="#4a4a4a"
                            />
                          </svg>
                          <span>CANCEL</span>
                        </Button>
                      </Typography>
                    </GridItem>
                    <GridItem>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.isVisible === 1 ? true : false}
                            onChange={(event, value) => {
                              if (value) {
                                setFieldValue('isVisible', 1);
                              } else {
                                setFieldValue('isVisible', 0);
                              }
                              setButtonVisible(true);
                              setIsChanged(true);
                            }}
                            //value="checkedA"
                            name="isVisible"
                            classes={{
                              root: classes.switchRoot,
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar,
                            }}
                          />
                        }
                        label={
                          <span style={{ color: '#000' }} className={classes.checkBoxLabel}>
                            Visible to Public
                          </span>
                        }
                      />
                    </GridItem>
                    {eduRecId === -1 && (
                      <GridItem style={{ marginBottom: '10px' }}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={values.requestNotifyRecord}
                              onChange={(event, value) => {
                                if (value) {
                                  setFieldValue('requestNotifyRecord', true);
                                } else {
                                  setFieldValue('requestNotifyRecord', false);
                                }
                                setButtonVisible(true);
                                setIsChanged(true);
                              }}
                              //value="checkedA"
                              name="notifyRecord"
                              classes={{
                                root: classes.switchRoot,
                                switchBase: classes.switchBase,
                                checked: classes.switchChecked,
                                thumb: classes.switchIcon,
                                track: classes.switchBar,
                              }}
                            />
                          }
                          label={
                            <React.Fragment>
                              <div style={{ display: 'block !important' }}>
                                <span
                                  style={{
                                    color: '#000',
                                    marginBottom: '0px !important',
                                  }}
                                  className={classes.checkBoxLabel}
                                >
                                  Notify your Connections
                                </span>
                              </div>
                            </React.Fragment>
                          }
                        />
                        <div>
                          <span
                            style={{
                              color: '#000',
                              fontSize: '14px',
                              display: 'block !important',
                            }}
                          >
                            Inform your Connections whenever you update your social feed.
                          </span>
                        </div>
                      </GridItem>
                    )}
                    <GridItem className={classes.gridHeight}>
                      <React.Fragment>
                        <Autocomplete
                          options={schoolList}
                          name="schoolId"
                          id="schoolId"
                          getOptionLabel={option => (option.schoolName ? option.schoolName : option)}
                          freeSolo
                          value={
                            schoolList.filter(item => {
                              return item.schoolId === values.schoolId;
                            })[0] || values.school
                          }
                          onChange={(event, value) => {
                            /* console.log(event.target.value); */
                            if (schoolList.includes(value)) {
                              setFieldValue('school', value.schoolName);
                              setFieldValue('schoolId', value.schoolId);
                            } else if (value) {
                              if (value.inputValue) {
                                setFieldValue('school', value.inputValue);
                                setFieldValue('schoolId', -1);
                              } else {
                                setFieldValue('school', value);
                                setFieldValue('schoolId', -1);
                              }
                            }
                            setButtonVisible(true);
                            setIsChanged(true);
                          }}
                          onBlur={(event, value) => {
                            const index = schoolList.findIndex(item => item.schoolName === event.target.value);
                            if (index === -1) {
                              setFieldValue('school', event.target.value);
                              setFieldValue('schoolId', -1);
                            }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            return filtered;
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label={'School'}
                              variant="outlined"
                              helperText={
                                values.schoolId != -1
                                  ? touched.schoolId
                                    ? errors.schoolId
                                    : ''
                                  : touched.school
                                    ? errors.school
                                    : ''
                              }
                              error={
                                values.schoolId != -1
                                  ? touched.schoolId && Boolean(errors.schoolId)
                                  : touched.school && Boolean(errors.school)
                              }
                            />
                          )}
                        />
                      </React.Fragment>
                    </GridItem>

                    <GridItem xs={12} sm={6} md={6} className={classes.gridHeight}>
                      <Autocomplete
                        options={countryList}
                        value={
                          countryList.filter(item => {
                            return item.countryId === values.countryId;
                          })[0] || null
                        }
                        getOptionLabel={option => (option.countryName ? option.countryName : '')}
                        filterOptions={createFilterOptions({
                          matchFrom: 'start',
                          stringify: option => option.countryName,
                        })}
                        getOptionSelected={(option, value) => option.countryName === value.countryName}
                        onChange={(event, value) => {
                          console.log(value);
                          if (value) {
                            setFieldValue('countryId', value.countryId);
                            setCountryChange(value.countryId);
                          } else {
                            setFieldValue('countryId', '');
                            setFieldValue('locationId', '');
                          }
                          setButtonVisible(true);
                          setIsChanged(true);
                        }}
                        id="countryId"
                        name="countryId"
                        renderInput={params => (
                          <TextField
                            {...params}
                            label={'Country'}
                            variant="outlined"
                            helperText={touched.countryId ? errors.countryId : ''}
                            error={touched.countryId && Boolean(errors.countryId)}
                          />
                        )}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} className={classes.gridHeight}>
                      <React.Fragment>
                        <Autocomplete
                          options={locationList}
                          disabled={!values.countryId}
                          onBlur={handleBlur}
                          value={
                            locationList.filter(item => {
                              return item.locationId === values.locationId;
                            })[0] || null
                          }
                          getOptionSelected={(option, value) => option.locationName === value.locationName}
                          getOptionLabel={option => (option.locationName ? option.locationName : '')}
                          onChange={(event, value) => {
                            if (value) {
                              setFieldValue('locationId', value.locationId);
                            } else {
                              setFieldValue('locationId', '');
                            }
                            setButtonVisible(true);
                            setIsChanged(true);
                          }}
                          id="locationId"
                          name="locationId"
                          renderInput={params => (
                            <TextField
                              {...params}
                              label={'Location'}
                              variant="outlined"
                              helperText={touched.locationId ? errors.locationId : ''}
                              error={touched.locationId && Boolean(errors.locationId)}
                            />
                          )}
                        />
                      </React.Fragment>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} className={classes.gridHeight}>
                      <React.Fragment>
                        <Autocomplete
                          options={studyFieldList}
                          freeSolo
                          value={
                            studyFieldList.filter(item => {
                              return item.studyFieldId === values.studyFieldId;
                            })[0] || values.studyField
                              ? values.studyField
                              : null
                          }
                          getOptionLabel={option => (option.studyFieldName ? option.studyFieldName : option)}
                          onChange={(event, value) => {
                            console.log(value);
                            if (studyFieldList.includes(value)) {
                              setFieldValue('studyFieldId', value.studyFieldId);
                              setFieldValue('studyField', value.studyFieldName);
                            } else if (value) {
                              if (value.inputValue) {
                                setFieldValue('studyFieldId', -1);
                                setFieldValue('studyField', value.inputValue);
                              } else {
                                setFieldValue('studyFieldId', -1);
                                setFieldValue('studyField', value);
                              }
                            }
                            setButtonVisible(true);
                            setIsChanged(true);
                          }}
                          onBlur={event => {
                            const index = studyFieldList.findIndex(item => item.studyFieldName === event.target.value);
                            if (index === -1) {
                              if (event.target.value) {
                                setFieldValue('studyField', event.target.value);
                                setFieldValue('studyFieldId', -1);
                              } else {
                                setFieldValue('studyField', '');
                                setFieldValue('studyFieldId', '');
                              }
                            }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            return filtered;
                          }}
                          id="studyFieldId"
                          name="studyFieldId"
                          renderInput={params => (
                            <TextField
                              {...params}
                              label={'Field of Study'}
                              variant="outlined"
                              helperText={touched.studyFieldId ? errors.studyFieldId : ''}
                              error={touched.studyFieldId && Boolean(errors.studyFieldId)}
                            />
                          )}
                        />
                      </React.Fragment>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} className={classes.gridHeight}>
                      <React.Fragment>
                        <Autocomplete
                          options={educationLevelList}
                          onBlur={handleBlur}
                          value={
                            educationLevelList.filter(item => {
                              return item.eduLvlId === values.eduLvlId;
                            })[0] || null
                          }
                          getOptionSelected={(option, value) => {
                            if (!value) {
                              return false;
                            }
                            return option.countryId === value.countryId;
                          }}
                          getOptionLabel={option => (option.eduLvlName ? option.eduLvlName : '')}
                          onChange={(event, value) => {
                            if (value) {
                              setFieldValue('eduLvlId', value.eduLvlId);
                            } else {
                              setFieldValue('eduLvlId', '');
                            }
                            setButtonVisible(true);
                            setIsChanged(true);
                          }}
                          id="eduLvlId"
                          name="eduLvlId"
                          renderInput={params => (
                            <TextField
                              {...params}
                              label={'Level of Education'}
                              variant="outlined"
                              helperText={touched.eduLvlId ? errors.eduLvlId : ''}
                              error={touched.eduLvlId && Boolean(errors.eduLvlId)}
                            />
                          )}
                        />
                      </React.Fragment>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} className={classes.gridHeight}>
                      <TextField
                        variant="outlined"
                        id="courseName"
                        label="Course Name"
                        name="courseName"
                        value={values.courseName}
                        helperText={touched.courseName ? errors.courseName : ''}
                        error={touched.courseName && Boolean(errors.courseName)}
                        onChange={event => {
                          handleChange(event);
                          setButtonVisible(true);
                          setIsChanged(true);
                        }}
                        onBlur={handleBlur}
                        fullWidth
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <h4 className={classes.modalTitle + ' ' + classes.grayFont}>Starting From</h4>
                    </GridItem>
                    <Hidden smDown>
                      <GridItem xs={12} sm={12} md={6}>
                        {values.currentInProgressFlag === 0 ? (
                          <h4 className={classes.modalTitle + ' ' + classes.grayFont}>Ending In</h4>
                        ) : null}
                      </GridItem>
                    </Hidden>
                    <GridItem xs={6} sm={6} md={3} className={classes.gridHeight}>
                      <React.Fragment>
                        <Autocomplete
                          options={monthDataList}
                          onBlur={handleBlur}
                          // freeSolo
                          value={
                            monthDataList.filter(item => {
                              return item.monthId === values.startMonth;
                            })[0] || null
                          }
                          getOptionLabel={option => (option.monthName ? option.monthName : '')}
                          getOptionSelected={(option, value) => option.monthName === value.monthName}
                          onChange={(event, value) => {
                            console.log(value);
                            if (value) {
                              setFieldValue('startMonth', value.monthId);
                            } else {
                              setFieldValue('startMonth', '0');
                            }
                            setButtonVisible(true);
                            setIsChanged(true);
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label={'Month'}
                              variant="outlined"
                              helperText={touched.startMonth ? errors.startMonth : ''}
                              error={touched.startMonth && Boolean(errors.startMonth)}
                            />
                          )}
                        />
                      </React.Fragment>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={3} className={classes.gridHeight}>
                      <React.Fragment>
                        <Autocomplete
                          options={startYearDataList}
                          onBlur={handleBlur}
                          // freeSolo
                          value={
                            startYearDataList.filter(item => {
                              return item === values.startYear;
                            })[0] || null
                          }
                          getOptionSelected={(option, value) => option === value}
                          getOptionLabel={option => option}
                          onChange={(event, value) => {
                            if (value) {
                              setFieldValue('startYear', value);
                            } else {
                              setFieldValue('startYear', '0');
                              setFieldValue('startMonth', '0');
                            }
                            setButtonVisible(true);
                            setIsChanged(true);
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label={'Year'}
                              variant="outlined"
                              helperText={touched.startYear ? errors.startYear : ''}
                              error={touched.startYear && Boolean(errors.startYear)}
                            />
                          )}
                        />
                      </React.Fragment>
                    </GridItem>
                    {values.currentInProgressFlag === 0 ? (
                      <Hidden mdUp>
                        <GridItem xs={12} sm={12} md={12}>
                          <h4 className={classes.modalTitle + ' ' + classes.grayFont}>Ending In</h4>
                        </GridItem>
                      </Hidden>
                    ) : null}
                    {values.currentInProgressFlag === 0 ? (
                      <React.Fragment>
                        <GridItem xs={6} sm={6} md={3} className={classes.gridHeight}>
                          <React.Fragment>
                            <Autocomplete
                              options={monthDataList}
                              onBlur={handleBlur}
                              // freeSolo
                              value={
                                monthDataList.filter(item => {
                                  return item.monthId === values.endMonth;
                                })[0] || null
                              }
                              getOptionLabel={option => (option.monthName ? option.monthName : '')}
                              getOptionSelected={(option, value) => option.monthName === value.monthName}
                              onChange={(event, value) => {
                                console.log(value);
                                if (value) {
                                  setFieldValue('endMonth', value.monthId);
                                } else {
                                  setFieldValue('endMonth', '0');
                                }
                                setButtonVisible(true);
                                setIsChanged(true);
                              }}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  label={'Month'}
                                  variant="outlined"
                                  helperText={touched.endMonth ? errors.endMonth : ''}
                                  error={touched.endMonth && Boolean(errors.endMonth)}
                                />
                              )}
                            />
                          </React.Fragment>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} className={classes.gridHeight}>
                          <React.Fragment>
                            <Autocomplete
                              options={endYearDataList}
                              onBlur={handleBlur}
                              value={
                                endYearDataList.filter(item => {
                                  return item === values.endYear;
                                })[0] || null
                              }
                              getOptionSelected={(option, value) => option === value}
                              getOptionLabel={option => option}
                              onChange={(event, value) => {
                                // console.log(value)
                                if (value) {
                                  setFieldValue('endYear', value);
                                } else {
                                  setFieldValue('endYear', '0');
                                  setFieldValue('endMonth', '0');
                                }
                                setButtonVisible(true);
                                setIsChanged(true);
                              }}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  label={'Year'}
                                  variant="outlined"
                                  helperText={touched.endYear ? errors.endYear : ''}
                                  error={touched.endYear && Boolean(errors.endYear)}
                                />
                              )}
                            />
                          </React.Fragment>
                        </GridItem>
                      </React.Fragment>
                    ) : null}
                    <GridItem>
                      <div className={classes.checkMargin}>
                        <FormControlLabel
                          classes={{
                            label: classes.label,
                          }}
                          control={
                            <Checkbox
                              //tabIndex={-1}
                              onChange={(event, value) => {
                                if (value) {
                                  setFieldValue('currentInProgressFlag', 1);
                                } else {
                                  setFieldValue('currentInProgressFlag', 0);
                                }
                                setButtonVisible(true);
                                setIsChanged(true);
                              }}
                              name="currentInProgressFlag"
                              checkedIcon={<Check className={classes.checkedIcon} />}
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                              checked={values.currentInProgressFlag === 1 ? true : false}
                            />
                          }
                          label={
                            <span className={classes.checkBoxLabel + ' ' + classes.grayFont}>
                              Currently enrolled in this school
                            </span>
                          }
                        />
                      </div>
                    </GridItem>
                    <GridItem>
                      {educationRecords &&
                        values.educationAutoEnd.length > 0 &&
                        educationRecords
                          .filter(item => {
                            return item.eduRecId !== eduRecId;
                          })
                          .filter(item => {
                            return item.currentInProgressFlag !== 0;
                          })
                          .map(item => {
                            /* 						console.log(item); */
                            return (
                              <div key={item.eduRecId}>
                                <FormControlLabel
                                  key={item.recId}
                                  control={
                                    <Switch
                                      checked={
                                        values.educationAutoEnd.filter(list => {
                                          return list.recId === item.eduRecId;
                                        })[0].value
                                      }
                                      onChange={(event, value) => {
                                        const autoEndArray = values.educationAutoEnd.filter(list => {
                                          if (list.recId === item.eduRecId) {
                                            list.value = list.value === true ? false : true;
                                          }
                                          return list;
                                        });
                                        setFieldValue('educationAutoEnd', autoEndArray);
                                        setButtonVisible(true);
                                        setIsChanged(true);
                                      }}
                                      classes={{
                                        root: classes.switchRoot,
                                        switchBase: classes.switchBase,
                                        checked: classes.switchChecked,
                                        thumb: classes.switchIcon,
                                        track: classes.switchBar,
                                      }}
                                    />
                                  }
                                  label={
                                    <span style={{ color: '#000' }} className={classes.checkBoxLabel}>
                                      {`Would you like to set the end date of Education: "`}
                                      <b>{item.school}</b>
                                      {`" to Today?`}
                                    </span>
                                  }
                                />
                              </div>
                            );
                          })}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} className={classes.gridPadding}>
                      <TextField
                        variant="outlined"
                        id="achievement"
                        label="Achievement"
                        name="achievement"
                        value={values.achievement}
                        helperText={touched.achievement ? errors.achievement : ''}
                        error={touched.achievement && Boolean(errors.achievement)}
                        onChange={event => {
                          handleChange(event);
                          setButtonVisible(true);
                          setIsChanged(true);
                        }}
                        onBlur={handleBlur}
                        fullWidth
                        InputProps={{
                          multiline: true,
                          rows: 5,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} className={classes.gridPadding}>
                      <TextField
                        variant="outlined"
                        id="description"
                        label="Description"
                        name="description"
                        value={values.description}
                        helperText={touched.description ? errors.description : ''}
                        error={touched.description && Boolean(errors.description)}
                        onChange={event => {
                          handleChange(event);
                          setButtonVisible(true);
                          setIsChanged(true);
                        }}
                        onBlur={handleBlur}
                        fullWidth
                        InputProps={{
                          multiline: true,
                          rows: 5,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} className={classes.gridPadding}>
                      <TextField
                        variant="outlined"
                        id="extraCurricular"
                        label="Extra Curricular"
                        name="extraCurricular"
                        value={values.extraCurricular}
                        helperText={touched.extraCurricular ? errors.extraCurricular : ''}
                        error={touched.extraCurricular && Boolean(errors.extraCurricular)}
                        onChange={event => {
                          handleChange(event);
                          setButtonVisible(true);
                          setIsChanged(true);
                        }}
                        onBlur={handleBlur}
                        fullWidth
                        InputProps={{
                          multiline: true,
                          rows: 5,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12}>
                      <Typography className="customInputCheckbox">
                        <Checkbox /> I do not wish to receive job recommendations relating to this work experience
                      </Typography>
                    </GridItem>

                  </GridContainer>
                </CardBody>
              </Card>
            </div>
            <ConfirmDialog
              {...{ ...saveDetailModalProps }}
              bodyContent={cancelForm}
              modalOpen={buttonVisible && saveDetailModalProps.modalOpen}
              handleSubmit={() => {
                resetForm();
                setButtonVisible(false);

                if (isMainModalCloseRequest) {
                  setIsChanged(false);
                  dispatch(deactivateEditType());
                  saveDetailModalProps.setModalOpen(false);
                } else {
                  onCancelChangesConfirm();
                }
              }}
            />
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
}
