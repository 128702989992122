import React from "react";
import axios from "axios";

import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";

import { instanceUrl } from "configurations/configurations.js";
import {
	resetPassword,
	resetPasswordMsg,
} from "configurations/redux/userSlice.js";
import { resetPasswordSchema } from "configurations/configurations.js";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Danger from "components/Typography/Danger.js";

import Close from "@material-ui/icons/Close";
import People from "@material-ui/icons/People";
import Phone from "@material-ui/icons/Phone";

import corporateProfilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/CorporateProfilePage/corporateProfilePageStyle.js";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Icon from "@material-ui/core/Icon";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { ReactReduxContext } from "react-redux";
import { isDesktop } from "react-device-detect";
import IconLibrary from "views/Component/IconLibrary";

const useStyles = makeStyles(corporateProfilePageStyle);
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

const CorporateResetPasswordModal = ({ open, onClose }) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const profileRecord = useSelector((state) => state.user.profileRecord);
	const userStatus = useSelector((state) => state.user);
	const user = useSelector((state) => state.auth.user);
	const [passwordError, setPasswordError] = React.useState(false);
	const [currentPassword, setCurrentPassword] = React.useState(true);
	const [passwordUpdated, setPasswordUpdated] = React.useState(false);
	const isCurrentPassword = useSelector(
		(state) => state.user.isCurrentPassword
	);
	const isPasswordUpdated = useSelector(
		(state) => state.user.isPasswordUpdated
	);
	const [showPassword, setShowPassword] = React.useState(false);
	const handleClickShowPassword = () => {
		if (showPassword) setShowPassword(false);
		else setShowPassword(true);
	};

	React.useEffect(() => {
		setCurrentPassword(isCurrentPassword);
	}, [isCurrentPassword]);

	React.useEffect(() => {
		console.log(isPasswordUpdated);
		setPasswordUpdated(isPasswordUpdated);
	}, [isPasswordUpdated]);

	React.useEffect(() => {
		dispatch(resetPasswordMsg());
		setPasswordError(false);
		setCurrentPassword(true);
		setPasswordUpdated(false);
		setShowPassword(false);
	}, [onClose]);

	const modalResize = () => {
		// var timer = setInterval(() => {
		var modalResize = document.getElementById("modalPassword");
		if (modalResize) {
			// clearInterval(timer);
			modalResize.style.maxHeight = "420px";
			var maxSize = window.innerHeight - 127 - 90 - 30;
			if (maxSize < 0) maxSize = 0;
			modalResize.style.height = maxSize + "px";
			// } else {
			// 	setTimeout(() => {
			// 		clearInterval(timer);
			// 	}, 3000);
		}
		// }, 300);
		// return () => clearInterval(timer);
	};

	// React.useEffect(() => {
	// 	window.addEventListener("resize", modalResize);
	// });

	React.useEffect(() => {
		if (open) {
			if (document.readyState === "complete") {
				setTimeout(() => {
					modalResize();
				}, 500);
			}
			window.addEventListener("resize", modalResize);
			return function cleanup() {
				window.removeEventListener("resize", modalResize);
			};
		}
	}, [open]);

	// React.useEffect(() => {
	// 	if (open) modalResize();
	// 	else {
	// 		try {
	// 			window.removeEventListener("resize");
	// 		} catch (error) {}
	// 	}
	// 	return function cleanup() {
	// 		try {
	// 			window.removeEventListener("resize");
	// 		} catch (error) {}
	// 	};
	// }, [open]);

	return (
		<React.Fragment>
			<Dialog
				classes={{
					root: classes.modalRoot,
					paper: classes.modalProfileEdit + " " + classes.modal,
				}}
				open={open}
				// TransitionComponent={Transition}
				// keepMounted
				onClose={onClose}
				aria-labelledby="classic-modal-slide-title"
				aria-describedby="classic-modal-slide-description"
			>
				<DialogTitle
					id="signup-modal-slide-title"
					disableTypography
					className={classes.modalTitleEdit}
				>
					<Button
						simple
						className={classes.modalCloseButton}
						key="close"
						aria-label="Close"
						onClick={onClose}
					>
						{" "}
						<IconLibrary.Close />
					</Button>
					<h3 className={classes.title + " " + classes.titleAlign}>
						Reset Password
					</h3>
				</DialogTitle>

				<Formik
					initialValues={{
						currentPassword: "",
						password: "",
						checkPassword: "",
					}}
					validationSchema={resetPasswordSchema}
					onSubmit={(data) => {
						dispatch(resetPasswordMsg());
						setPasswordError(false);
						setCurrentPassword(true);
						setPasswordUpdated(false);
						if (data.currentPassword === data.password) {
							setPasswordError(true);
						} else {
							const updateRequest = {
								acctId: user.acctId,
								acctTypeId: user.acctTypeId,
								socialAcctId: "",
								email: user.email,
								currentPassword: data.currentPassword,
								password: data.password,
								accessToken: localStorage.getItem("token"),
							};
							console.log(updateRequest);
							dispatch(resetPassword(updateRequest));
							// if (data) {
							//     onClose();
							// }
						}
					}}
				>
					{({
						handleChange,
						values,
						errors,
						touched,
						handleBlur,
						handleSubmit,
						setFieldValue,
					}) => (
						<form onSubmit={handleSubmit}>
							<div
								className={
									isDesktop
										? classes.smallModalBodyDesktop
										: classes.smallModalBody
								}
								id="modalPassword"
							>
								<DialogContent
									id="classic-modal-slide-description"
									className={classes.modalBody}
								>
									<Card className={classes.modalCardBorder}>
										<CardBody>
											<GridContainer justifyContent="center">
												<GridItem>
													<div>
														<h4 className={classes.title}>
															Change Your Password
														</h4>
													</div>
												</GridItem>
												<GridItem>
													{passwordError && (
														<SnackbarContent
															message={
																<span>
																	<b>
																		New Password and Current Password cannot be
																		the same
																	</b>
																</span>
															}
															close
															color="opptyWarning"
															icon="info_outline"
														/>
													)}
													{!currentPassword && (
														<SnackbarContent
															message={
																<span>
																	<b>Current Password is wrong</b>
																</span>
															}
															close
															color="opptyWarning"
															icon="info_outline"
														/>
													)}
													{passwordUpdated && (
														<SnackbarContent
															message={
																<span>
																	<b>Password Reset is successful</b>
																</span>
															}
															close
															color="opptyInfo"
															icon="info_outline"
														/>
													)}
												</GridItem>
												<GridItem className={classes.gridHeight}>
													<TextField
														variant="outlined"
														id="currentPassword"
														label="Current Password"
														name="currentPassword"
														helperText={
															touched.currentPassword
																? errors.currentPassword
																: ""
														}
														error={
															touched.currentPassword &&
															Boolean(errors.currentPassword)
														}
														onChange={handleChange}
														onBlur={handleBlur}
														placeholder="Required"
														fullWidth
														InputProps={{
															type: showPassword ? "text" : "password",
															// startAdornment: (
															// 	<InputAdornment position="start">
															// 		<Icon className={classes.inputIconsColor}>
															// 			lock_outline
															// 		</Icon>
															// 	</InputAdornment>
															// ),
															endAdornment: (
																<InputAdornment position="end">
																	<IconButton
																		aria-label="toggle password visibility"
																		onClick={handleClickShowPassword}
																		edge="end"
																	>
																		{showPassword ? (
																			<Visibility />
																		) : (
																			<VisibilityOff />
																		)}
																	</IconButton>
																</InputAdornment>
															),
														}}
													/>
												</GridItem>
												<GridItem className={classes.gridHeight}>
													<TextField
														variant="outlined"
														id="password"
														label="New Password"
														name="password"
														helperText={touched.password ? errors.password : ""}
														error={touched.password && Boolean(errors.password)}
														onChange={handleChange}
														onBlur={handleBlur}
														placeholder="Required"
														fullWidth
														InputProps={{
															type: showPassword ? "text" : "password",
															// startAdornment: (
															// 	<InputAdornment position="start">
															// 		<Icon className={classes.inputIconsColor}>
															// 			lock_outline
															// 		</Icon>
															// 	</InputAdornment>
															// ),
															endAdornment: (
																<InputAdornment position="end">
																	<IconButton
																		aria-label="toggle password visibility"
																		onClick={handleClickShowPassword}
																		edge="end"
																	>
																		{showPassword ? (
																			<Visibility />
																		) : (
																			<VisibilityOff />
																		)}
																	</IconButton>
																</InputAdornment>
															),
														}}
													/>
												</GridItem>
												{/**LastName */}
												<GridItem className={classes.gridHeight}>
													<TextField
														variant="outlined"
														id="checkPassword"
														label="Confirm Password"
														name="checkPassword"
														helperText={
															touched.checkPassword ? errors.checkPassword : ""
														}
														error={
															touched.checkPassword &&
															Boolean(errors.checkPassword)
														}
														onChange={handleChange}
														onBlur={handleBlur}
														placeholder="Required"
														fullWidth
														InputProps={{
															type: showPassword ? "text" : "password",
															// startAdornment: (
															// 	<InputAdornment position="start">
															// 		<Icon className={classes.inputIconsColor}>
															// 			lock_outline
															// 		</Icon>
															// 	</InputAdornment>
															// ),
															endAdornment: (
																<InputAdornment position="end">
																	<IconButton
																		aria-label="toggle password visibility"
																		onClick={handleClickShowPassword}
																		edge="end"
																	>
																		{showPassword ? (
																			<Visibility />
																		) : (
																			<VisibilityOff />
																		)}
																	</IconButton>
																</InputAdornment>
															),
														}}
													/>
												</GridItem>
											</GridContainer>
										</CardBody>
									</Card>
								</DialogContent>
							</div>
							<DialogActions className={classes.modalFooterEdit}>
								<Button round onClick={handleSubmit} color="oppty">
									<b>Save</b>
								</Button>
							</DialogActions>
						</form>
					)}
				</Formik>
			</Dialog>
		</React.Fragment>
	);
};

export default CorporateResetPasswordModal;
