import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import ImageList from "@material-ui/core/ImageList";
// import ImageListItem from "@material-ui/core/ImageListItem";
import profileViewActivityPostCardStyles from "../../../../assets/jss/material-kit-pro-react/views/componentStyle/profileView/myActivity/profileViewActivityPostCardStyles.js";
import ReactPlayer from "react-player";
import loadingGif from "assets/img/loading.gif";
import PlayCircleOutlineOutlinedIcon from "@mui/icons-material/PlayCircleOutlineOutlined";
import VideoThumbnail from "react-video-thumbnail";
import { isMobileOnly } from "react-device-detect";

const PostVideoList = ({ attachmentList }) => {
	const styles = makeStyles(profileViewActivityPostCardStyles)();
	const [videoThumbnail, setVideoThumbnail] = useState(loadingGif);

	return (
		// <div className={classes.root + " " + stylesPost.imgHeight}>
		<React.Fragment>
			<div style={{ display: "none" }}>
				<VideoThumbnail
					videoUrl={attachmentList[0].attachment}
					thumbnailHandler={(thumbnail) => {
						setVideoThumbnail(thumbnail);
						// console.log(thumbnail);
					}}
					// snapshotAtTime={1}
				/>
			</div>
			<div className={styles.videoThumbnail}>
				<ReactPlayer
					url={attachmentList[0].attachment}
					controls={true}
					light={videoThumbnail}
					width="100%"
					height={isMobileOnly ? "100%" : "360px"}
					pip={false}
					playing={true}
					style={{
						backgroundColor: "#000",
					}}
					playIcon={
						<PlayCircleOutlineOutlinedIcon
							sx={{
								color: "#b6b7b8",
								margin: "5px",
								width: "30%",
								height: "30%",
							}}
						/>
					}
				/>
			</div>
		</React.Fragment>
		// </div>
	);
};

export default PostVideoList;
