import React from 'react';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import { useSelector, useDispatch } from 'react-redux';

import searchPageStyle from 'assets/jss/material-kit-pro-react/views/pageStyle/SearchPage/searchPageStyle.js';

import SearchResultCard from 'views/Page/CommunityPage/SearchResultCard.js';
import { getArticleList } from 'configurations/redux/communitySlice.js';

import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import SmallCardLoading from '../SmallCardLoading.js';
import { encryptData, instanceUrl, header } from 'configurations/configurations.js';
import Pagination from 'views/Component/PaginationWithPage.js';
import NoResultCard from '../NoResultCard';
import ArticleCard from './ArticleCard.js';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

const useStyles = makeStyles(searchPageStyle);

export default function ArticleComponentInPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const keyword = useSelector(state => state.genericSearch.keyword);

  const searchParams = useSelector(state => state.community.searchParams);
  const userStatus = useSelector(state => state.auth.user);
  const isLoading = useSelector(state => state.community.isLoading);
  const auth = useSelector(state => state.auth);
  const searchResult = useSelector(state => state.community.searchResult);
  const keywordResult = useSelector(state => state.community.keywordResult);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [articlesPerPage, setArticlesPerPage] = React.useState(12);
  const bizId = useSelector(state => state.business.bizId);

  const paginate = pageNumber => {
    setCurrentPage(pageNumber);
    var indexOfFirstJob = pageNumber * articlesPerPage - articlesPerPage;
    getArticlePost(indexOfFirstJob);
    window.scrollTo(0, 0);
    scrollToTop();
  };
  const getArticlePost = async indexOfFirstJob => {
    const requestProfile = {
      acctId: userStatus.acctId,
      acctTypeId: bizId > 0 ? 2 : userStatus.acctTypeId,
      accessToken: localStorage.getItem('token'),
      email: userStatus.email,
      categoryIds: searchParams.categoryIds,
      subCategoryIds: searchParams.subCategoryIds,
      lastRecordSize: indexOfFirstJob,
      requestRecordSize: articlesPerPage,
      paginationClick: true,
      defaultLoad: false,
      keyword: keyword,
    };
    dispatch(getArticleList(requestProfile));
  };
  const scrollToTop = () => {
    var timer = setInterval(() => {
      var jobPostResult = document.getElementById('searchResultArticle');
      if (jobPostResult) {
        jobPostResult.scrollIntoView();
        window.scrollTo(0, 0);
        clearInterval(timer);
      }
    }, 100);
  };

  const loadingFields = [];
  //articlesPerPage - searchResult.length
  for (let i = 0; i < 24; i++) {
    loadingFields.push(
      //   <div key={i}>

      <GridItem xs={12} sm={6} md={6} lg={4} xl={3} key={i}>
        <SmallCardLoading />
      </GridItem>
      //   </div>
    );
  }
  const ArticleList =
    searchResult &&
    searchResult.length > 0 &&
    searchResult.map(item => {
      return (
        <GridItem xs={12} sm={6} md={6} lg={4} xl={3} key={item.articleId}>
          <ArticleCard item={item} />
        </GridItem>
      );
    });

  return (
    <Card className={classes.cardFrame}>
      <CardBody className={classes.cardBodyPadding}>
        <h4 className={classes.componentTitle}>Article</h4>
        <div className={classes.mainContainer}>
          <GridContainer>
            {isLoading &&
              loadingFields.map(item => {
                return item;
              })}
          </GridContainer>
          <GridContainer>{ArticleList}</GridContainer>
          {!isLoading && searchResult.length === 0 && <NoResultCard showImage={true} />}
        </div>
        <Pagination
          totalPosts={
            searchResult.length > 0
              ? searchResult[0].totalArticleCount != undefined
                ? searchResult[0].totalArticleCount
                : 0
              : 0
          }
          postsPerPage={articlesPerPage}
          paginate={paginate}
          page={currentPage}
        />
        {/* <SearchResultSection /> */}
      </CardBody>
    </Card>
  );
}
