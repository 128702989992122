import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import Popover from "@mui/material/Popover";
import axios from "axios";
import { decryptData } from "configurations/configurations.js";

import ConnectionCard from "../../Component/ConnectionCard.js";
import PendingRequest from "../../Component/PendinRequest";
import PendingApproval from "../../Component/PendingApproval";
import ActionCard from "../../Component/ActionCard";
import LandingPageFeedStyle from "assets/jss/material-kit-pro-react/views/pageStyle/LandingPageFeed/LandingPageFeedStyle.js";
import ConnectionPageStyle from "assets/jss/material-kit-pro-react/components/connectionPageStyle";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Header from "views/Component/Header.js";
import { errorCleanUp } from "configurations/redux/authSlice.js";
import arrow_down from "assets/images/arrow_down.png";
import tick from "assets/images/tick.png";
import CustomDialog from "../../../components/RequestDialog/RequestDialog.js";
import Footer from "views/Component/Footer.js";
import LeftSidebar from "views/Component/LeftSidebar.js";
import CustomSearchInput from "components/CustomInput/CustomSearchInput";

import SyncContactModal from "../ProfilePage/ProfileSection/SyncContactModal";
import { header } from "configurations/configurations.js";

import { getNetworkCount } from "configurations/redux/manageConnectionSlice.js";
import ConnectionCardComponent from "./ConnectionCardComponent.js";
import RequestPendingComponent from "./RequestPendingComponent.js";
import RequestApprovalComponent from "./RequestApprovalComponent.js";
import EditModal from "views/Component/ProfileView/EditProfile/EditModal.js";
import BlockComponent from "./BlockComponent.js";
import Hidden from "@material-ui/core/Hidden";
import GroupCardComponent from "./GroupCardComponent.js";
import RightSideBarUserList from "views/Component/RightSideBarUserList.js";
import PendingApprovalGroupComponent from "./PendingApprovalGroupComponent.js";
import PendingRequestGroupComponent from "./PendingRequestGroupComponent.js";

const useStyles = makeStyles(ConnectionPageStyle);

const ConnectionPage = ({ match }) => {
	const history = useHistory();
	const classes = useStyles();
	const dispatch = useDispatch();
	const isStatusUpdated = useSelector(
		(state) => state.manageConnection.isStatusUpdated
	);
	const blockCount = useSelector((state) => state.manageConnection.blockCount);
	const connectionCount = useSelector(
		(state) => state.manageConnection.connectionCount
	);
	const pendingApprovalCount = useSelector(
		(state) => state.manageConnection.pendingApprovalCount
	);
	const pendingApprovalGroupCount = useSelector(
		(state) => state.manageConnection.pendingApprovalGroupCount
	);
	const pendingRequestGroupCount = useSelector(
		(state) => state.manageConnection.pendingRequestGroupCount
	);
	const pendingRequestCount = useSelector(
		(state) => state.manageConnection.pendingRequestCount
	);
	const connectedCount = useSelector(
		(state) => state.manageConnection.connectedCount
	);
	const groupCount = useSelector((state) => state.manageConnection.groupCount);
	const followHashtagCount = useSelector(
		(state) => state.manageConnection.followHashtagCount
	);
	const followProfileCount = useSelector(
		(state) => state.manageConnection.followProfileCOunt
	);
	const isLoading = useSelector((state) => state.manageConnection.isLoading);

	const isError = useSelector((state) => state.auth.isError);
	const userStatus = useSelector((state) => state.auth.user);

	const [anchorEl, setAnchorEl] = useState(null);
	const [sortText, setSortText] = useState("A-Z");
	const [tabText, setTabText] = useState("Connections");
	const [action, setAction] = useState("Connections");
	const [request, setRequest] = useState("Connections");
	const [syncContactModal, setSyncContactModal] = useState(false);

	const connectionRecords = useSelector(
		(state) => state.manageConnection.connectionRecords
	);

	React.useEffect(() => {
		const request = {
			acctId: decryptData(match.params.acctId),
			accessToken: localStorage.getItem("token"),
			email: userStatus.email,
		};
		dispatch(getNetworkCount(request));
	}, [isStatusUpdated]);
	const ConnectionActionTab = () => {
		return (
			<div className={classes.cardsContainer}>
				<ActionCard
					title={`Pending Request`}
					action={"View"}
					count={pendingRequestCount}
					onClick={() => {
						setRequest("Pending Request");
						setTabText("");
					}}
				/>
				<ActionCard
					title={"Pending Approval"}
					action={"View"}
					count={pendingApprovalCount}
					onClick={() => {
						setRequest("Pending Approval");
						setTabText("");
					}}
				/>

				<ActionCard
					title={"Import your Contacts"}
					action={"Import"}
					isNumber={false}
					isDivider={false}
					onClick={() => {
						setSyncContactModal(true);
						setTabText("");
					}}
				/>
				<SyncContactModal
					open={syncContactModal}
					onClose={closeSyncContactModal}
				/>
			</div>
		);
	};
	const GroupActionTab = () => {
		return (
			<div className={classes.cardsContainer}>
				<ActionCard
					title={`Pending Request`}
					action={"View"}
					isGroup={true}
					count={pendingRequestGroupCount}
					onClick={() => {
						setRequest("Pending Request Group");
						setTabText("");
					}}
				/>
				<ActionCard
					title={"Pending Approval"}
					action={"View"}
					isDivider={false}
					isGroup={true}
					count={pendingApprovalGroupCount}
					onClick={() => {
						setRequest("Pending Approval Group");
						setTabText("");
					}}
				/>
			</div>
		);
	};
	const ConnectionTab = () => {
		return (
			<div style={{ marginTop: "10px" }}>
				<div className={classes.tabContainer}>
					<div
						className={classes.contentContainer}
						onClick={() => {
							setRequest("Connections");
							setTabText("Connections");
							setAction("Connections");
						}}
					>
						<p
							className={classes.tabTxt1}
							style={{
								color: tabText == "Connections" ? "#6F52ED" : "#92929D",
							}}
						>
							Connections
						</p>
						<p
							className={classes.tabTxt2}
							style={{
								color: tabText === "Connections" ? "#6F52ED" : "#44444F",
							}}
						>
							{connectedCount}
						</p>
						{tabText == "Connections" && (
							<div className={classes.tabIndicator} />
						)}
					</div>

					<div
						className={classes.contentContainer}
						onClick={() => {
							setRequest("Groups");
							setTabText("Groups");
							setAction("Groups");
						}}
					>
						<p
							className={classes.tabTxt1}
							style={{
								color: tabText == "Groups" ? "#6F52ED" : "#92929D",
							}}
						>
							Groups
						</p>
						<p
							className={classes.tabTxt2}
							style={{
								color: tabText === "Groups" ? "#6F52ED" : "#44444F",
							}}
						>
							{groupCount}
						</p>
						{tabText == "Groups" && <div className={classes.tabIndicator} />}
					</div>

					<div
						className={classes.contentContainer}
						onClick={() => {
							setRequest("Blocked");
							setTabText("Blocked");
						}}
					>
						<p
							className={classes.tabTxt1}
							style={{
								color: tabText == "Blocked" ? "#6F52ED" : "#92929D",
							}}
						>
							Blocked
						</p>
						<p
							className={classes.tabTxt2}
							style={{
								color: tabText === "Blocked" ? "#6F52ED" : "#44444F",
							}}
						>
							{blockCount}
						</p>
						{tabText == "Blocked" && <div className={classes.tabIndicator} />}
					</div>
				</div>
			</div>
		);
	};

	const closeSyncContactModal = () => {
		setSyncContactModal(false);
	};

	return (
		<div>
			<Header color={"transparent"} loggedInStatus={false} />
			<div className={classes.body}>
				<div
					id="pageHeader"
					className={classes.pageHeader}
					style={{
						backgroundSize: "cover",
						backgroundPosition: "top center",
					}}
				>
					<div
						className={classes.container}
						style={{ background: "#f2f2f2" }}
						id="socialFeedPage"
					>
						<GridContainer spacing={2}>
							<Hidden smDown>
								<GridItem md={3} lg={2}>
									<LeftSidebar />
								</GridItem>
							</Hidden>
							<GridItem xs={12} sm={12} md={9} lg={8}>
								<ConnectionTab />

								{action === "Connections" && <ConnectionActionTab />}
								{action === "Groups" && <GroupActionTab />}

								{request === "Pending Approval" && <RequestApprovalComponent />}
								{request === "Connections" && <ConnectionCardComponent />}
								{request === "Pending Request" && <RequestPendingComponent />}
								{request === "Blocked" && <BlockComponent />}
								{request === "Groups" && <GroupCardComponent />}
								{request === "Pending Approval Group" && (
									<PendingApprovalGroupComponent />
								)}
								{request === "Pending Request Group" && (
									<PendingRequestGroupComponent />
								)}
							</GridItem>
							<Hidden mdDown>
								<GridItem md={2}>{/* <RightSideBarUserList /> */}</GridItem>
							</Hidden>
						</GridContainer>
					</div>
				</div>
				<EditModal />
				<Footer />
			</div>
		</div>
	);
};

export default ConnectionPage;
