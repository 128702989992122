import React from "react";
import { Helmet } from "react-helmet";
import Artboard from "assets/img/Oppty_Logo/Final-02.png";

const MetatagHelmet = ({ metaTag }) => {
	return (
		<Helmet>
			{metaTag.title && <title>{`OppTy - ${metaTag.title}`}</title>}
			{metaTag.title && (
				<meta name="title" content={`OppTy - ${metaTag.title}`} />
			)}
			{metaTag.description && (
				<meta name="description" content={metaTag.description} />
			)}
			<meta
				property="image"
				content={metaTag.image ? metaTag.image : Artboard}
			/>
			{/* facebook */}
			<meta property="og:type" content="website" />
			{metaTag.url && <meta property="og:url" content={metaTag.url} />}
			{metaTag.title && (
				<meta property="og:title" content={`OppTy - ${metaTag.title}`} />
			)}
			{metaTag.description && (
				<meta property="og:description" content={metaTag.description} />
			)}
			<meta
				property="og:image"
				content={metaTag.image ? metaTag.image : Artboard}
			/>
			{/* twitter */}
			{metaTag.image && (
				<meta property="twitter:card" content={metaTag.image} />
			)}
			{metaTag.url && <meta property="twitter:url" content={metaTag.url} />}
			{metaTag.title && (
				<meta property="twitter:title" content={`OppTy - ${metaTag.title}`} />
			)}
			{metaTag.description && (
				<meta property="twitter:description" content={metaTag.description} />
			)}
			<meta
				property="twitter:image"
				content={metaTag.image ? metaTag.image : Artboard}
			/>
		</Helmet>
	);
};

export default MetatagHelmet;
