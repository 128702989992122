import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
	instanceUrl,
	header,
	emptyCache,
} from "configurations/configurations.js";

const roadmapSlice = createSlice({
	name: "roadmap",
	initialState: {
		industryKeyword: null,
		departmentKeyword: null,
		isLoading: false,
		isSelected: true,
		isUpdated: true,
		selectedRoadMapRole: [],
		isRedirected: false,
		industryList: [],
	},
	reducers: {
		ROADMAP_SELECT_SUCCESS: (state, action) => {
			state.industryKeyword = action.payload.industryKeyword;
			state.departmentKeyword = action.payload.departmentKeyword;
		},
		INDUSTRY_SELECT_SUCCESS: (state, action) => {
			state.industryKeyword = action.payload.industryKeyword;
		},
		DEPARTMENT_SELECT_SUCCESS: (state, action) => {
			state.departmentKeyword = action.payload.departmentKeyword;
		},
		ROADMAP_RESET: (state) => {
			state.industryKeyword = null;
			state.departmentKeyword = null;
		},
		ROADMAP_ROLE_LOADING: (state) => {
			state.isLoading = true;
		},
		ROADMAP_ROLE_SELECT_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.selectedRoadMapRole = action.payload.careerRoadMapRoleList;
			state.isSelected = true;
			state.isLoading = false;
			state.isUpdated = true;
		},
		ROADMAP_ROLE_SELECT_FAIL: (state) => {
			localStorage.removeItem("token");
			state.isSelected = false;
			state.isLoading = false;
			state.isUpdated = false;
		},
		ROADMAP_JOB_CREATE_REDIRECT: (state) => {
			state.isRedirected = true;
		},
		ROADMAP_JOB_CREATE_DONE: (state) => {
			state.isRedirected = false;
		},
		ROADMAP_LIST_CREATE_DONE: (state, action) => {
			state.industryList = action.payload;
			console.log(state.industryList);
		},
	},
});

const {
	ROADMAP_SELECT_SUCCESS,
	INDUSTRY_SELECT_SUCCESS,
	DEPARTMENT_SELECT_SUCCESS,
	ROADMAP_RESET,
	ROADMAP_ROLE_LOADING,
	ROADMAP_ROLE_SELECT_SUCCESS,
	ROADMAP_ROLE_SELECT_FAIL,
	ROADMAP_JOB_CREATE_REDIRECT,
	ROADMAP_JOB_CREATE_DONE,
	ROADMAP_LIST_CREATE_DONE,
} = roadmapSlice.actions;

export const viewRoadmap = ({ industryKeyword, departmentKeyword }) => (
	dispatch
) => {
	const response = { industryKeyword, departmentKeyword };

	dispatch(ROADMAP_SELECT_SUCCESS(response));
};

export const industryViewRoadmap = (industryKeyword) => (dispatch) => {
	const response = { industryKeyword };
	dispatch(INDUSTRY_SELECT_SUCCESS(response));
};

export const departmentViewRoadmap = (departmentKeyword) => (dispatch) => {
	const response = { departmentKeyword };
	dispatch(DEPARTMENT_SELECT_SUCCESS(response));
};

export const resetRoadmap = () => (dispatch) => {
	dispatch(ROADMAP_RESET());
};

export const viewRoadmapRoleByRoleId = ({
	acctId,
	accessToken,
	email,
	roleId,
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		email,
		roleId,
	};
	dispatch(ROADMAP_ROLE_LOADING());
	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/crmm/get-career-roadmap-role-by-role-id`,
		params: body,
	})
		.then((res) => {
			if (res.status === 201) {
				console.log(res);

				const response = {
					careerRoadMapRoleList: res.data.payload.careerRoadMapRoleList,
					token: res.data.token,
				};
				dispatch(ROADMAP_ROLE_SELECT_SUCCESS(response));
			} else {
				dispatch(ROADMAP_ROLE_SELECT_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};
export const roadmapJobCreateRedirect = () => (dispatch) => {
	dispatch(ROADMAP_JOB_CREATE_REDIRECT());
};
export const roadmapJobCreateDone = () => (dispatch) => {
	dispatch(ROADMAP_JOB_CREATE_DONE());
};
export const getRoadMapList = () => (dispatch) => {
	const industryList = [
		{
			industryName: "Marketing & Communication",
			industryId: 1,
			departmentList: [
				{
					roleName: "Advertising",
					roleId: 1,
				},
				{
					roleName: "Public Relation",
					roleId: 2,
				},
				{
					roleName: "Marketing Research",
					roleId: 3,
				},
				{
					roleName: "Marketing",
					roleId: 4,
				},
				{
					roleName: "Brand Management",
					roleId: 5,
				},
			],
		},
		{
			industryName: "Finance",
			industryId: 2,
			departmentList: [
				{
					roleName: "Personal Finance",
					roleId: 6,
				},
				{
					roleName: "Hedge Fund",
					roleId: 7,
				},
				{
					roleName: "Accounting",
					roleId: 8,
				},
				{
					roleName: "Public Finance Management",
					roleId: 9,
				},
				{
					roleName: "Investor Relations",
					roleId: 10,
				},
				{
					roleName: "Invesment & Securities",
					roleId: 11,
				},
			],
		},
		{
			industryName: "Hospitality",
			industryId: 3,
			departmentList: [
				{
					roleName: "Revenue & Distribution",
					roleId: 12,
				},
				{
					roleName: "Attractions Management",
					roleId: 13,
				},
				{
					roleName: "Housekeeping",
					roleId: 14,
				},
				{
					roleName: "Event Manager",
					roleId: 15,
				},
				{
					roleName: "Travel Management & Operations",
					roleId: 16,
				},
				{
					roleName: "Front Office",
					roleId: 17,
				},
			],
		},
		{
			industryName: "Real Estate",
			industryId: 4,
			departmentList: [
				{
					roleName: "Sales & Leasing",
					roleId: 18,
				},
				{
					roleName: "Property & Asset Management",
					roleId: 19,
				},
				{
					roleName: "Valuations",
					roleId: 20,
				},
				{
					roleName: "Research & Consultancy",
					roleId: 21,
				},
				{
					roleName: "Urban Planning",
					roleId: 22,
				},
			],
		},
		{
			industryName: "Data Artificial Intelligence",
			industryId: 5,
			departmentList: [
				{
					roleName: "Business Intelligence",
					roleId: 23,
				},
				{
					roleName: "Data Engineering",
					roleId: 24,
				},
				{
					roleName: "Machine Learning Intelligence",
					roleId: 25,
				},
				{
					roleName: "Data Science & AI Science",
					roleId: 26,
				},
				{
					roleName: "AI Applied Research",
					roleId: 27,
				},
			],
		},
		{
			industryName: "Aerospace Engineering",
			industryId: 6,
			departmentList: [
				{
					roleName: "Aircraft Maintenance",
					roleId: 28,
				},
				{
					roleName: "Aircraft Engine / Component Maintenance",
					roleId: 29,
				},
				{
					roleName: "Manufacturing",
					roleId: 30,
				},
				{
					roleName: "Fleet Management",
					roleId: 31,
				},
			],
		},
		{
			industryName: "Biomedical Engineering",
			industryId: 7,
			departmentList: [
				{
					roleName: "Biomedical Engineering",
					roleId: 7,
				},
			],
		},
		{
			industryName: "Chemical Engineering",
			industryId: 8,
			departmentList: [
				{
					roleName: "Chemical Engineering",
					roleId: 8,
				},
			],
		},
		{
			industryName: "Civil Engineering",
			industryId: 9,
			departmentList: [
				{
					roleName: "Civil Engineering",
					roleId: 9,
				},
			],
		},
		{
			industryName: "Electrical Engineering",
			industryId: 10,
			departmentList: [
				{
					roleName: "Electrical Engineering",
					roleId: 10,
				},
			],
		},
		{
			industryName: "Marine Engineering",
			industryId: 11,
			departmentList: [
				{
					roleName: "Port (Sea Transport)",
					roleId: 36,
				},
				{
					roleName: "The Engine Department",
					roleId: 37,
				},
			],
		},
		{
			industryName: "Mechanical Engineering",
			industryId: 12,
			departmentList: [
				{
					roleName: "Mechanical Engineering",
					roleId: 12,
				},
			],
		},
		{
			industryName: "Product Engineering",
			industryId: 13,
			departmentList: [
				{
					roleName: "Product Engineering",
					roleId: 13,
				},
			],
		},
		{
			industryName: "Software Engineering",
			industryId: 14,
			departmentList: [
				{
					roleName: "Software Engineering",
					roleId: 14,
				},
			],
		},
		{
			industryName: "Healthcare",
			industryId: 15,
			departmentList: [
				{
					roleName: "Operations",
					roleId: 41,
				},
				{
					roleName: "Occupatinal Therapy",
					roleId: 42,
				},
				{
					roleName: "Pharmacy Support",
					roleId: 43,
				},
				{
					roleName: "Physiotherapy",
					roleId: 44,
				},
				{
					roleName: "Speech Therapy",
					roleId: 45,
				},
				{
					roleName: "Nursing",
					roleId: 46,
				},
			],
		},
		{
			industryName: "Banking - Wealth Management",
			industryId: 16,
			departmentList: [
				{
					roleName: "Retail Banking",
					roleId: 47,
				},
				{
					roleName: "Affluence Banking",
					roleId: 48,
				},
				{
					roleName: "Private Banking",
					roleId: 49,
				},
			],
		},
		{
			industryName: "Banking - SME-Corporate-Commercial",
			industryId: 17,
			departmentList: [
				{
					roleName: "SME",
					roleId: 50,
				},
				{
					roleName: "Corporate Banking",
					roleId: 51,
				},
				{
					roleName: "Commercial Banking",
					roleId: 52,
				},
			],
		},
		{
			industryName: "Banking - Investment Banking",
			industryId: 18,
			departmentList: [
				{
					roleName: "Capital Market",
					roleId: 53,
				},
				{
					roleName: "Merger & Acquisition",
					roleId: 54,
				},
				{
					roleName: "Corporate Finance",
					roleId: 55,
				},
				{
					roleName: "Trading",
					roleId: 56,
				},
			],
		},
		{
			industryName: "Banking - Risk & Compliance",
			industryId: 19,
			departmentList: [
				{
					roleName: "Risk",
					roleId: 57,
				},
				{
					roleName: "Compliance",
					roleId: 58,
				},
			],
		},
	];
	const sortedIndustryList = industryList.sort((a, b) => {
		a.departmentList.sort((a, b) => {
			if (a.roleName < b.roleName) {
				return -1;
			}
			if (a.roleName > b.roleName) {
				return 1;
			}
		});
		b.departmentList.sort((a, b) => {
			if (a.roleName < b.roleName) {
				return -1;
			}
			if (a.roleName > b.roleName) {
				return 1;
			}
		});
		if (a.industryName < b.industryName) {
			return -1;
		}
		if (a.industryName > b.industryName) {
			return 1;
		}
	});
	dispatch(ROADMAP_LIST_CREATE_DONE(sortedIndustryList));
};
export default roadmapSlice.reducer;
