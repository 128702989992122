import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
	departmentViewRoadmap,
	viewRoadmapRoleByRoleId,
} from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Department_Fleet_Management from "assets/img/Career_Roadmap/Aerospace_Engineering/Department_Fleet_Management_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentFleetManagement() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Fleet_Management;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "fm",
				shape: "poly",
				coords: [84,65,76,68,72,70,69,75,66,82,66,785,69,791,73,796,78,799,85,802,186,801,193,799,199,796,202,790,204,784,204,82,201,74,198,69,190,65,185,63,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "256",
				shape: "poly",
				coords: [228,274,223,276,219,279,216,285,215,291,215,351,217,358,219,362,223,366,230,367,361,367,367,365,372,361,374,356,375,351,374,289,373,282,370,278,363,275,358,273,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "257",
				shape: "poly",
				coords: [517,273,509,276,505,280,502,283,501,290,501,352,504,360,506,362,508,365,514,368,646,367,652,365,656,362,659,358,660,353,661,291,660,282,656,278,651,275,647,274,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "258",
				shape: "poly",
				coords: [799,274,794,276,790,280,788,284,786,290,786,353,789,360,792,364,797,366,804,368,934,367,939,364,942,362,945,356,946,352,946,290,946,284,942,279,937,276,930,274,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "259",
				shape: "poly",
				coords: [1087,274,1080,276,1076,279,1074,283,1072,288,1072,355,1075,360,1077,363,1082,366,1086,367,1218,367,1224,365,1228,362,1231,357,1233,353,1233,288,1230,282,1226,278,1222,275,1216,274,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "261",
				shape: "poly",
				coords: [1678,278,1671,280,1668,283,1664,288,1663,293,1664,359,1666,364,1669,367,1675,370,1681,371,1832,370,1836,368,1840,364,1842,360,1843,356,1844,295,1841,287,1838,281,1833,278,1827,277,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "262",
				shape: "poly",
				coords: [800,452,794,454,790,457,788,462,786,468,787,532,789,537,792,542,797,544,803,545,931,544,938,542,942,539,945,534,946,528,946,470,946,461,942,456,937,452,930,451,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "263",
				shape: "poly",
				coords: [1087,451,1080,454,1076,458,1074,462,1072,467,1072,532,1074,538,1076,540,1080,542,1086,545,1218,544,1224,541,1228,538,1230,536,1232,531,1233,469,1232,460,1227,455,1221,452,1216,451,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "260",
				shape: "poly",
				coords: [1376,457,1366,460,1362,463,1360,469,1359,475,1359,536,1360,543,1364,547,1368,550,1376,552,1504,551,1512,549,1515,545,1518,539,1519,532,1518,474,1517,465,1514,461,1508,459,1502,458,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "264",
				shape: "poly",
				coords: [804,629,796,630,792,632,788,638,787,646,787,706,788,714,791,718,796,721,804,721,927,722,936,720,942,718,944,714,946,706,946,648,945,637,940,632,933,629,928,628,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "265",
				shape: "poly",
				coords: [1086,629,1080,632,1077,634,1073,640,1072,644,1073,712,1076,716,1078,718,1082,720,1088,722,1218,722,1225,720,1229,717,1232,713,1233,707,1233,646,1231,636,1228,633,1222,630,1217,628,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "266",
				shape: "poly",
				coords: [804,96,796,98,792,101,788,105,786,111,786,174,789,182,792,186,796,190,805,191,927,190,935,189,940,186,944,181,946,176,946,120,946,108,942,101,937,98,929,96,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "267",
				shape: "poly",
				coords: [1090,96,1081,99,1077,101,1074,105,1072,114,1073,175,1075,181,1079,188,1085,190,1092,190,1213,190,1223,188,1227,185,1230,180,1233,175,1232,110,1230,104,1227,101,1223,98,1217,96,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "268",
				shape: "poly",
				coords: [1373,103,1365,106,1362,110,1358,115,1358,122,1358,180,1360,188,1364,191,1368,194,1374,196,1504,196,1511,193,1514,190,1517,186,1518,180,1518,117,1516,110,1511,105,1506,103,1501,103,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "fm") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo);
			dispatch(viewRoadmapRoleByRoleId(requestInfo));

			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
