import React, { Fragment, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Input,
  IconButton,
  makeStyles,
  Popover,
} from "@material-ui/core";
import popover from "views/Component/popover.js";
import Comment from "./Comment.js";
import socialFeedStyles from "../../../assets/jss/material-kit-pro-react/views/componentStyle/socialFeed/socialFeedStyles";
import sponsoredAdStyles from "assets/jss/material-kit-pro-react/views/componentStyle/socialFeedPosts/sponsoredAdStyles.js";
import userProfile from "assets/images/userProfile.png";
import job from "assets/img/job.png";
import kendall from "assets/img/faces/kendall.jpg";
import avatar from "assets/img/faces/avatar.jpg";
import postLeft from "assets/images/PostLeft.png";
import postRight1 from "assets/images/PostRight1.png";
import postRight2 from "assets/images/PostRight2.png";
import dotBtn from "assets/images/dotBtn.png";
import like from "assets/images/like.png";
import comment from "assets/images/comment.png";
import share from "assets/images/share.png";
import saved from "assets/images/saved.png";
import file from "assets/images/file.png";
import emoji from "assets/images/emoji.png";
import gallery from "assets/images/documentation.png";
import video from "assets/img/video.png";

const useStyles = makeStyles(socialFeedStyles);
const useSponsoredAdStyles = makeStyles(sponsoredAdStyles);

const SponsoredAd = (props) => {
  const classes = useStyles();
  const sponsoredAdClasses = useSponsoredAdStyles();
  const ariaLabel = { "aria-label": "description" };

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorShareEl, setAnchorShareEl] = useState(null);

  return (
    <Fragment>
      <Card className={classes.socialFeedCard}>
        <CardContent className={classes.socialFeedCardContent}>
          <Typography component="div">
            <Typography component="div" className={classes.feedHeader}>
              <Typography component="div" className={classes.feedHeaderUserBx}>
                <Typography component="div" className={classes.feedUserImgBx}>
                  <img src={kendall} alt="" />
                </Typography>
                <Typography component="div" className={classes.feedUserNameBx}>
                  <Typography component="h6">
                    Ahmad Nur Fawaid
                    <Typography component="span">SponsoredAd</Typography>
                  </Typography>
                  <Typography component="span">12 April at 09.28 PM</Typography>
                </Typography>
              </Typography>
              <button onClick={(e) => { setAnchorEl(e.currentTarget) }}>
                <img src={dotBtn} alt="" />
              </button>

            </Typography>

            <Typography className={classes.feedTitle}>
              One of the perks of working in an international company is sharing
              knowledge with your colleagues.
            </Typography>

            <Typography component="div" className={classes.postGraphicalContent}>
              <Typography component="div" className={classes.postContentContainer}>

                {props.hasVideo ?
                  <img src={video} alt="" />
                  :
                  <img src={job} alt="" />
                }

              </Typography>
            </Typography>

            <Typography component="div" className={sponsoredAdClasses.postActions}>
              <button className={sponsoredAdClasses.learnMoreBtn}>
                Learn More
              </button>

              <button className={sponsoredAdClasses.contactBtn}>
                Contact
              </button>

              <button className={sponsoredAdClasses.regularAction}>
                <img src={like} alt="" />
                120k Likes
              </button>
              <button className={sponsoredAdClasses.regularAction} onClick={(e) => { setAnchorShareEl(e.currentTarget) }}>
                <img src={share} alt="" />
                123 Share
              </button>

              <Popover
                id={anchorShareEl ? "simple-popover" : undefined}
                open={anchorShareEl ? true : false}
                anchorEl={anchorShareEl}
                onClose={() => { setAnchorShareEl(null) }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                anchorPosition={{
                  top: 10,
                  left: 10,
                }}
                anchorReference='anchorEl'
              >
                <Typography component="div" className={classes.popoverListItem}>
                  <Typography component="div" className={classes.popoverListItemText}>
                    <Typography component="p" className={classes.popoverListItemTitle}>
                      Share to feed
                    </Typography>
                    <Typography component="p" className={classes.popoverListItemDescription}>
                      Share this post on your feed
                    </Typography>
                  </Typography>
                </Typography>

                <Typography component="div" className={classes.popoverListItem}>
                  <Typography component="div" className={classes.popoverListItemText}>
                    <Typography component="p" className={classes.popoverListItemTitle}>
                      Write post
                    </Typography>
                    <Typography component="p" className={classes.popoverListItemDescription}>
                      Compose a post with this content
                    </Typography>
                  </Typography>
                </Typography>

                <Typography component="div" className={classes.popoverListItem}>
                  <Typography component="div" className={classes.popoverListItemText}>
                    <Typography component="p" className={classes.popoverListItemTitle}>
                      Share to Chat
                    </Typography>
                  </Typography>
                </Typography>

                <Typography component="div" className={classes.popoverListItem}>
                  <Typography component="div" className={classes.popoverListItemText}>
                    <Typography component="p" className={classes.popoverListItemTitle}>
                      Share to Group
                    </Typography>
                  </Typography>
                </Typography>

                <Typography component="div" className={classes.popoverListItem}>
                  <Typography component="div" className={classes.popoverListItemText}>
                    <Typography component="p" className={classes.popoverListItemTitle}>
                      Copy Post Link
                    </Typography>
                  </Typography>
                </Typography>
              </Popover>
            </Typography>

            <Typography component="div" className={classes.feedCommentBx}>
              <Typography component="div" className={classes.feedCommentUserBx}>
                <img src={kendall} alt="" />
              </Typography>
              <Typography component="div" className={classes.commentInputWrap}>
                <Input
                  placeholder="Write your comment"
                  inputProps={ariaLabel}
                />
                <Typography
                  component="div"
                  className={classes.commentActionBtns}
                >
                  {/* <IconButton>
                    <img src={file} alt="" />
                  </IconButton> */}
                  <IconButton>
                    <img src={emoji} alt="" />
                  </IconButton>
                  <IconButton>
                    <img src={gallery} alt="" />
                  </IconButton>
                </Typography>
              </Typography>
            </Typography>

            <Typography component="div" className={classes.postCommentsMain}>
              {/* <Typography component="div" className={classes.recentCommentsButton}>
                <button>Most recent comments</button>
              </Typography> */}

              <Comment haveComments={false} />

              {/* <Typography component="div" className={classes.moreComments}>
                <button>
                  Load more comments
                </button>
              </Typography> */}
            </Typography>
          </Typography>
        </CardContent>
      </Card>
    </Fragment >
  )
}

export default SponsoredAd;