import React from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import Chat from "@material-ui/icons/Chat";
import Search from "@material-ui/icons/Search";
import Work from "@material-ui/icons/Work";

import landingPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/LandingPage/landingPageStyle.js";

import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(landingPageStyle);
export default function InformationSections() {
	const classes = useStyle();
	return (
		<div className={classes.informationSectionContainer}>
			<GridContainer justifyContent="center">
				<GridItem xs={12} sm={8} md={8}>
					<h2 className={classes.cardTitle}>Why OppTy is your next choice</h2>
					<h6 className={classes.cardDescription}>
						Achieve your next opportunities with OppTy
					</h6>
				</GridItem>
			</GridContainer>
			<GridContainer>
				<GridItem xs={12} sm={4} md={4}>
					<InfoArea
						title="Messaging"
						description="Testing by junior"
						icon={Chat}
						iconColor="info"
						vertical
					/>
				</GridItem>
				<GridItem xs={12} sm={4} md={4}>
					<InfoArea
						title="Search"
						description="Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough."
						icon={Search}
						iconColor="success"
						vertical
					/>
				</GridItem>
				<GridItem xs={12} sm={4} md={4}>
					<InfoArea
						title="Work"
						description="Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough."
						icon={Work}
						iconColor="danger"
						vertical
					/>
				</GridItem>
			</GridContainer>
		</div>
	);
}
