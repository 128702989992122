import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { searchJobInfo } from "configurations/redux/searchJobSlice.js";
import InfiniteScroll from "react-infinite-scroll-component";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import CircularProgress from "@material-ui/core/CircularProgress";

import searchJobPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/SearchJobPage/searchJobPageStyle.js";
import SearchResultCard from "./SearchResultCard";
import NoActivityStatusModal from "../NoActivityStatusModal/NoActivityStatusModal";
import SearchImage from "assets/img/Search_Image.jpg";
import NoResultImage from "assets/img/No_Result.jpg";

import { isMobile } from "react-device-detect";

const useStyles = makeStyles(searchJobPageStyle);
export default function SearchResultSection() {
	const classes = useStyles();

	const searchResult = useSelector((state) => state.searchJob.searchResult);
	const searchParams = useSelector((state) => state.searchJob.searchParams);
	const currentSize = useSelector((state) => state.searchJob.lastRecordSize);
	const isSearching = useSelector((state) => state.searchJob.isSearching);
	const userStatus = useSelector((state) => state.auth.user);
	const isEnd = useSelector((state) => state.searchJob.isEnd);
	const isUpdated = useSelector((state) => state.searchJob.isUpdated);
	const isLoading = useSelector((state) => state.searchJob.isLoading);

	const dispatch = useDispatch();

	const ResultCard = searchResult.map((item, index) => {
		return (
			<GridItem xs={12} sm={6} md={6} lg={4} xl={3} key={index}>
				<SearchResultCard key={item.jobPostId} item={item} />
			</GridItem>
		);
	});

	const loadJob = () => {
		const requestProfile = {
			acctId: userStatus.acctId,
			accessToken: localStorage.getItem("token"),
			email: userStatus.email,
			keyword: searchParams.keyword,
			countryId: searchParams.countryId,
			locationId: searchParams.locationId,
			industryId: searchParams.industryId,
			companyName: searchParams.companyName,
			hotJobFlag: searchParams.hotJobFlag,
			experienceId: searchParams.experienceId,
			currencyId: searchParams.currencyId,
			salaryMin: searchParams.salaryMin,
			salaryMax: searchParams.salaryMax,
			lastRecordSize: currentSize,
			requestRecordSize: 4,
			genericSearch: false,
		};

		dispatch(searchJobInfo(requestProfile));
	};

	return (
		<React.Fragment>
			{isUpdated ? (
				<div>
					{searchResult.length > 0 ? (
						<InfiniteScroll
							dataLength={searchResult.length}
							next={loadJob}
							hasMore={!isEnd}
							loader={
								<GridContainer
									justifyContent="center"
									alignContent="center"
									alignItems="center"
								>
									<GridItem xs={12} sm={12} md={12}>
										<div className={classes.loadingContainer}>
											<CircularProgress />
										</div>
									</GridItem>
								</GridContainer>
							}
							endMessage={
								<h4
									className={
										classes.title +
										" " +
										classes.textCenter +
										" " +
										classes.endTitle
									}
								>
									End of record
								</h4>
							}
							scrollThreshold={0.6}
							style={
								isMobile ? { overflowX: "hidden" } : { overflow: "hidden" }
							}
						>
							<div id="jobPostResult"></div>
							<GridContainer className={classes.jobCardGrid}>
								{ResultCard}
							</GridContainer>
						</InfiniteScroll>
					) : null}

					{isEnd && searchResult.length === 0 && (
						<Card className={classes.cardFrame}>
							<CardBody>
								<h5
									className={
										classes.title +
										" " +
										classes.textCenter +
										" " +
										classes.endTitle
									}
								>
									No available Job. Please use different filter options.
								</h5>
								<GridContainer justifyContent="center">
									<GridItem xs={12} sm={12} md={6}>
										<img
											src={NoResultImage}
											alt="..."
											className={classes.loadingImage}
										/>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					)}

					{isLoading && searchResult.length === 0 && (
						<GridContainer justifyContent="center">
							<GridItem xs={12} sm={12} md={12}>
								<div className={classes.loadingContainer}>
									<CircularProgress />
								</div>
							</GridItem>
							<GridItem xs={12} sm={12} md={6}>
								<img
									src={SearchImage}
									alt="..."
									className={classes.loadingImage}
								/>
							</GridItem>
						</GridContainer>
					)}
				</div>
			) : (
				<NoActivityStatusModal />
			)}
		</React.Fragment>
	);
}
