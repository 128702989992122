import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
// import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
// import { spacing } from "@material-ui/system";
import Hidden from "@material-ui/core/Hidden";
// import Drawer from "@material-ui/core/Drawer";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
import IconLibrary from "views/Component/IconLibrary.js";
import RectangleRoundedIcon from "@mui/icons-material/RectangleRounded";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import Autocomplete from "@mui/material/Autocomplete";

// import Close from "@material-ui/icons/Close";
// core components

import styles from "assets/jss/material-kit-pro-react/components/headerStyle.js";

import alphaLogoOrangeWhite from "assets/img/alpha_logo/alpha_logo_orange_white.png";

import Artboard from "assets/img/Oppty_Logo/Final-02.png";
import bizArtboard from "assets/img/Oppty_Logo/biz-01.png";
// import ArtboardBig from "assets/img/Oppty_Logo/artboard.png";

import CookieConsent, { Cookies } from "react-cookie-consent";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { TextField } from "@material-ui/core";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import history from "../../history.js";
import Popover from "@material-ui/core/Popover";
// import Typography from "@material-ui/core/Typography";
import Backdrop from "@material-ui/core/Backdrop";
import MobileLeftSidebar from "../../views/Component/MobileLeftSideBar";
import NotificationPage from "views/Page/NotificationPage/NotificationPage.js";
import NotificationResultSection from "views/Page/NotificationListing/NotificationResultSection.js";
import { eventNotificationGet } from "configurations/redux/eventNotificationSlice.js";
import Badge from "@material-ui/core/Badge";
import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from "@mui/material/CircularProgress";
import {
	logout,
	publicMode,
	publicModeReset,
} from "configurations/redux/authSlice.js";
import {
	getSearchResult,
	saveKeyword,
} from "configurations/redux/genericSearchSlice.js";
import {
	openSearchBox,
	closeSearchBox,
} from "configurations/redux/genericSearchSlice.js";

function useWindowSize() {
	const [size, setSize] = React.useState([0, 0]);
	React.useLayoutEffect(() => {
		function updateSize() {
			setSize([window.innerWidth, window.innerHeight]);
		}
		window.addEventListener("resize", updateSize);
		updateSize();
		return () => window.removeEventListener("resize", updateSize);
	}, []);
	return size;
}

const useStyles = makeStyles(styles);

const Header = (props) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [searchBox, setSearchBox] = React.useState(false);
	const [backDrop, setBackDrop] = React.useState(false);
	const keyword = useSelector((state) => state.genericSearch.keyword);
	const gsLoading = useSelector((state) => state.genericSearch.isLoading);
	const [width, height] = useWindowSize();

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
		setBackDrop(true);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setBackDrop(false);
	};
	const searchResultList = useSelector(
		(state) => state.genericSearch.searchResultList
	);
	const dispatch = useDispatch();

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	// const [logoImg, setLogoImg] = React.useState(alphaLogoOrangeWhite);
	const [showNotice, setShowNotice] = React.useState(false);
	const [mobileQuery, setQueryMobile] = React.useState("");
	const [isPublicMode, setIsPublicMode] = React.useState(false);

	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
	const user = useSelector((state) => state.auth.user);
	const searchCount = useSelector(
		(state) => state.eventNotification.searchCount
	);
	const messageCount = useSelector(
		(state) => state.messageConversation.searchCount
	);
	const isSearchBox = useSelector((state) => state.genericSearch.isSearchBox);

	const bizId = useSelector((state) => state.business.bizId);
	const isPublic = useSelector((state) => state.auth.isPublic);
	const publicEmail = useSelector((state) => state.auth.publicEmail);
	const publicToken = useSelector((state) => state.auth.publicToken);
	const classes = useStyles();
	React.useEffect(() => {
		if (props.changeColorOnScroll) {
			window.addEventListener("scroll", headerColorChange);
		}
		return function cleanup() {
			if (props.changeColorOnScroll) {
				window.removeEventListener("scroll", headerColorChange);
			}
		};
	});
	// const handleDrawerToggle = () => {
	// 	setMobileOpen(!mobileOpen);
	// };
	const headerColorChange = () => {
		const { color, changeColorOnScroll } = props;
		const windowsScrollTop = window.pageYOffset;
		try {
			if (windowsScrollTop > changeColorOnScroll.height) {
				document.body
					.getElementsByTagName("header")[0]
					.classList.remove(classes[color]);
				document.body
					.getElementsByTagName("header")[0]
					.classList.add(classes[changeColorOnScroll.color]);
				//setLogoImg(alphaLogoOrangeBlack);
			} else {
				document.body
					.getElementsByTagName("header")[0]
					.classList.add(classes[color]);
				document.body
					.getElementsByTagName("header")[0]
					.classList.remove(classes[changeColorOnScroll.color]);
				//setLogoImg(alphaLogoOrangeWhite);
			}
		} catch (e) {}
	};
	const auth = useSelector((state) => state.auth.user); // on initial login track down profile pic

	const { color, links, description, fixed, absolute } = props;
	const appBarClasses = classNames(
		{
			[classes.appBar]: bizId == 0 ? true : false,
			[classes[color]]: color,
			[classes.absolute]: absolute,
			[classes.fixed]: fixed,
			[classes.bizAppBar]: bizId > 0 ? true : false,
		},
		classes.headerShadow
	);

	// console.log(isAuthenticated);
	React.useEffect(() => {
		if (description && description != "") {
			setShowNotice(true);
		}
	}, [description]);

	React.useEffect(() => {
		let mounted = true;
		let CancelToken = axios.CancelToken;
		const source = CancelToken.source();
		if (mobileQuery && (isAuthenticated || isPublic)) {
			const request = {
				cancelToken: source.token,
				accessToken: isPublic ? publicToken : localStorage.getItem("token"),
				acctId: isPublic ? 0 : auth.acctId,
				email: isPublic ? publicEmail : auth.email,
				requestRecordSize: 5,
				keyword: mobileQuery,
			};

			dispatch(getSearchResult(request));
		}

		/* return () => {
			source.cancel();
		}; */
	}, [mobileQuery]);

	window.onresize = function () {
		handleClose();
	};
	// React.useEffect(() => {
	// 	var cookie = Cookies.get("maintenanceNotice")
	// 	var root = document.getElementById("root");
	// 	var header = document.getElementById("header");
	// 	if (header != undefined) {
	// 		console.log(showNotice)
	// 		if (showNotice && !cookie && isAuthenticated) {
	// 			root.style.paddingTop = "70px"
	// 			header.style.marginTop = "70px"
	// 		} else {
	// 			root.style.paddingTop = "0px"
	// 			header.style.marginTop = "0px"
	// 		}
	// 	}
	// }, [showNotice])
	React.useEffect(() => {
		dispatch(closeSearchBox());
	}, []);

	return (
		<div>
			{showNotice && isAuthenticated && (
				<CookieConsent
					cookieName="maintenanceNotice"
					hideOnAccept={false}
					location="top"
					buttonText="Close"
					style={{
						background: "#6969F7",
						height: "auto",
						alignItems: "center",
						justifyContent: "center",
						zIndex: "9999",
					}}
					buttonStyle={{
						backgroundColor: "#ffffff",
						fontSize: "13px",
						fontWeight: "300",
						textTransform: "uppercase",
						width: "140px",
						height: "auto",
						border: "none",
						borderRadius: "30px",
						verticalAlign: "middle",
						padding: "12px 30px",

						boxShadow: "#ffffff",
					}}
					onAccept={() => {
						setShowNotice(false);
					}}
				>
					<GridContainer justify="center">
						<GridItem xs={12} sm={12} md={8}>
							{description}
						</GridItem>
					</GridContainer>
				</CookieConsent>
			)}

			<AppBar className={appBarClasses} id="header">
				<div className={classes.myContainer}>
					{bizId > 0 ? (
						<Button className={classes.title}>
							<Link
								to={{
									pathname: ``,
								}}
								onClick={() => {
									if (user && isAuthenticated) {
										history.push("/search-profile-page");
										// if (user.acctTypeId === 1 || user.acctTypeId === 3)
										//   history.push("/search-job-page");
										// else history.push("/search-profile-page");
									} else history.push("/login-page");
								}}
							>
								<div>
									<img
										className={classes.logoImage}
										src={bizArtboard}
										alt="..."
									/>
								</div>
							</Link>
						</Button>
					) : (
						<Button className={classes.title}>
							<Link
								to={{
									pathname: ``,
								}}
								onClick={() => {
									if (user && isAuthenticated) {
										history.push("/social-feed/1/0");
										// if (user.acctTypeId === 1 || user.acctTypeId === 3)
										//   history.push("/search-job-page");
										// else history.push("/search-profile-page");
									} else history.push("/login-page");
									dispatch(closeSearchBox());
								}}
							>
								<div>
									<img className={classes.logoImage} src={Artboard} alt="..." />
								</div>
							</Link>
						</Button>
					)}
					{bizId > 0 ? (
						<Hidden mdDown implementation="css" className={classes.hidden}>
							<div className={classes.collapse}>{links}</div>
						</Hidden>
					) : (
						<div className={classes.collapse}>{links}</div>
					)}
					{!isSearchBox && (
						<Hidden only={["lg", "xl"]}>
							<div className={classes.rightHeader}>
								{isAuthenticated && bizId == 0 ? (
									<Link to="/notification-page/0/">
										{/* <div className={classes.headerButton}> */}
										<Badge
											color="secondary"
											variant="dot"
											className={classes.headerButton}
											invisible={searchCount > 0 ? false : true}
										>
											<NotificationsNoneOutlinedIcon />
										</Badge>
										{/* {searchCount ? (
											<div className={classes.badgeNotif}>{searchCount}</div>
										) : null}
									</div> */}
									</Link>
								) : null}
								{isAuthenticated && bizId == 0 ? (
									<Link to="/message-page/0/0/">
										{/* <div className={classes.headerButton2}> */}
										<Badge
											color="secondary"
											variant="dot"
											className={classes.headerButton2}
											invisible={messageCount > 0 ? false : true}
										>
											<MailOutlinedIcon />
										</Badge>
										{/* {messageCount ? (
											<div className={classes.badgeInbox}>{messageCount}</div>
										) : null}
									</div> */}
									</Link>
								) : null}

								<IconButton
									color="inherit"
									aria-label="open drawer"
									onClick={handleClick}
								>
									<Menu
										style={{ fill: bizId > 0 ? "white" : "currentColor" }}
									/>
								</IconButton>
							</div>
						</Hidden>
					)}
					{isSearchBox && (
						<Hidden only={["xs", "lg", "xl"]}>
							<div className={classes.rightHeader}>
								{isAuthenticated ? (
									<Link to="/notification-page/0/">
										{/* <div className={classes.headerButton}> */}
										<Badge
											color="secondary"
											variant="dot"
											className={classes.headerButton}
											invisible={searchCount > 0 ? false : true}
										>
											<NotificationsNoneOutlinedIcon />
										</Badge>
										{/* {searchCount ? (
											<div className={classes.badgeNotif}>{searchCount}</div>
										) : null}
									</div> */}
									</Link>
								) : null}
								{isAuthenticated ? (
									<Link to="/message-page/0/0/">
										{/* <div className={classes.headerButton2}> */}
										<Badge
											color="secondary"
											variant="dot"
											className={classes.headerButton2}
											invisible={messageCount > 0 ? false : true}
										>
											<MailOutlinedIcon />
										</Badge>
										{/* {messageCount ? (
											<div className={classes.badgeInbox}>{messageCount}</div>
										) : null}
									</div> */}
									</Link>
								) : null}

								<IconButton
									color="inherit"
									aria-label="open drawer"
									onClick={handleClick}
								>
									<Menu />
								</IconButton>
							</div>
						</Hidden>
					)}
				</div>

				<Hidden lgUp implementation="js">
					<Popover
						id={id}
						open={open}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "center",
						}}
						className={classes.popOverContainer}
						transformOrigin={{
							vertical: "top",
							horizontal: "center",
						}}
					>
						{bizId > 0 ? (
							<div>{links}</div>
						) : (
							<div className={classes.popOver}>
								<MobileLeftSidebar />
							</div>
						)}
					</Popover>
				</Hidden>
			</AppBar>
		</div>
	);
};

Header.defaultProp = {
	color: "white",
};

Header.propTypes = {
	color: PropTypes.oneOf([
		"primary",
		"info",
		"success",
		"warning",
		"danger",
		"transparent",
		"white",
		"rose",
		"dark",
		"blue",
		"oppty",
	]),
	links: PropTypes.node,
	brand: PropTypes.string,

	fixed: PropTypes.bool,
	absolute: PropTypes.bool,
	// this will cause the sidebar to change the color from
	// props.color (see above) to changeColorOnScroll.color
	// when the window.pageYOffset is heigher or equal to
	// changeColorOnScroll.height and then when it is smaller than
	// changeColorOnScroll.height change it back to
	// props.color (see above)
	changeColorOnScroll: PropTypes.shape({
		height: PropTypes.number.isRequired,
		color: PropTypes.oneOf([
			"primary",
			"info",
			"success",
			"warning",
			"danger",
			"transparent",
			"white",
			"rose",
			"dark",
			"blue",
			"oppty",
		]).isRequired,
	}),
};

export default Header;
