import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { Formik } from "formik";
import { instanceUrl } from "configurations/configurations.js";
import { corporateProfileSchema } from "configurations/configurations.js";
import { insertUpdateBasicInfo } from "configurations/redux/corpUserSlice.js";
import { buildProfile } from "configurations/redux/authSlice.js";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Autocomplete, {
	createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Slider from "@material-ui/core/Slider";
import Input from "@material-ui/core/Input";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Danger from "components/Typography/Danger.js";

import Close from "@material-ui/icons/Close";
import People from "@material-ui/icons/People";
import RemoveCircleRoundedIcon from "@material-ui/icons/RemoveCircleRounded";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import WorkRoundedIcon from "@material-ui/icons/WorkRounded";
import LanguageRoundedIcon from "@material-ui/icons/LanguageRounded";
import IconLibrary from "views/Component/IconLibrary.js";
import Hidden from "@material-ui/core/Hidden";

import Phone from "@material-ui/icons/Phone";

import corporateProfilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/CorporateProfilePage/corporateProfilePageStyle.js";
import { isDesktop } from "react-device-detect";

const useStyles = makeStyles(corporateProfilePageStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});
const sizeMarks = [
	{
		value: 100,
		label: "100",
	},
	{
		value: 300,
		label: "300",
	},
	{
		value: 500,
		label: "500",
	},
	{
		value: 700,
		label: "700",
	},
	{
		value: 900,
		label: "900",
	},
];
export const CorporateBasicProfileEditModal = ({ open, onClose }) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const buildProfileFlag = useSelector((state) => state.auth.buildProfileFlag);
	// const corpUser = useSelector((state) => state.corpUser);
	const bizPage = useSelector((state) => state.corpUser.profileRecord);
	const user = useSelector((state) => state.auth.user);
	const [countryList, setCountryList] = React.useState([]);
	const [areaCodeList, setAreaCodeList] = React.useState([]);
	const [locationList, setLocationList] = React.useState([]);
	const [industryList, setIndustryList] = React.useState([]);
	const [companyTypeList, setCompanyTypeList] = React.useState([]);
	const [countryChange, setCountryChange] = React.useState();
	// const bizPage = useSelector((state) => state.business.bizPage);
	const bizId = useSelector((state) => state.business.bizId);
	// console.log(bizPage);
	React.useEffect(() => {
		let mounted = true;
		let CancelToken = axios.CancelToken;
		const source = CancelToken.source();
		const fetchCountryList = async () => {
			try {
				const res = await axios.get(`${instanceUrl}/api/md/get-all-country`, {
					cancelToken: source.token,
				});
				setCountryList(res.data.payload);
			} catch (error) {
				if (axios.isCancel(error)) {
					//console.log("unmount caused");
				} else {
					throw error;
				}
			}
		};
		const fetchAreaList = async () => {
			try {
				const res = await axios.get(`${instanceUrl}/api/md/get-all-area-code`, {
					cancelToken: source.token,
				});
				setAreaCodeList(res.data.payload);
			} catch (error) {
				if (axios.isCancel(error)) {
					//console.log("unmount caused");
				} else {
					throw error;
				}
			}
		};
		const fetchLocationList = async () => {
			try {
				const res = await axios.get(`${instanceUrl}/api/md/get-all-location`, {
					cancelToken: source.token,
				});
				setLocationList(res.data.payload);
			} catch (error) {
				if (axios.isCancel(error)) {
					//console.log("unmount caused");
				} else {
					throw error;
				}
			}
		};
		const fetchIndustryList = async () => {
			try {
				const res = await axios.get(`${instanceUrl}/api/md/get-all-industry`, {
					cancelToken: source.token,
				});
				setIndustryList(res.data.payload);
			} catch (error) {
				if (axios.isCancel(error)) {
					//console.log("unmount caused");
				} else {
					throw error;
				}
			}
		};
		const fetchCompanyList = async () => {
			try {
				const res = await axios.get(
					`${instanceUrl}/api/md/get-all-company-type`,
					{
						cancelToken: source.token,
					}
				);
				setCompanyTypeList(res.data.payload);
			} catch (error) {
				if (axios.isCancel(error)) {
					//console.log("unmount caused");
				} else {
					throw error;
				}
			}
		};

		if (mounted) {
			fetchCountryList();
			fetchAreaList();
			// fetchLocationList();
			fetchIndustryList();
			fetchCompanyList();
		} else {
			return null;
		}
		return () => {
			mounted = false;
			source.cancel();
		};
	}, []);

	React.useEffect(() => {
		if (countryChange) {
			console.log(countryChange);
			let CancelToken = axios.CancelToken;
			const source = CancelToken.source();
			const fetchLocationList = async (countryId) => {
				try {
					const res = await axios.get(
						`${instanceUrl}/api/md/get-all-location-by-country`,
						{
							cancelToken: source.token,
							params: { countryId: countryChange },
						}
					);
					setLocationList(res.data.payload);
				} catch (error) {
					if (axios.isCancel(error)) {
						//console.log("unmount caused");
					} else {
						throw error;
					}
				}
			};
			fetchLocationList(countryChange);
		}
	}, [countryChange]);

	const modalResize = () => {
		// var timer = setInterval(() => {
		var modalResize = document.getElementById("modalCard");
		if (modalResize) {
			// clearInterval(timer);
			var maxSize = window.innerHeight - 74 - 90 - 70;
			if (maxSize < 0) maxSize = 0;
			modalResize.style.height = maxSize + "px";
			// } else {
			// 	setTimeout(() => {
			// 		clearInterval(timer);
			// 	}, 3000);
		}
		// }, 300);
		// return () => clearInterval(timer);
	};

	// React.useEffect(() => {
	// 	window.addEventListener("resize", modalResize);
	// });

	React.useEffect(() => {
		if (open) {
			if (document.readyState === "complete") {
				if (bizPage) setCountryChange(bizPage.countryId);
				// setTimeout(() => {
				// 	modalResize();
				// }, 500);
			}
			// window.addEventListener("resize", modalResize);
			// return function cleanup() {
			// 	window.removeEventListener("resize", modalResize);
			// };
		}
	}, [open]);

	// React.useEffect(() => {
	// if (open) {
	// 	// modalResize();
	// 	if (bizPage) {
	// 		setCountryChange(bizPage.countryId);
	// 	}
	// }
	//  else {
	// 	try {
	// 		window.removeEventListener("resize");
	// 	} catch (error) {}
	// }
	// return function cleanup() {
	// 	try {
	// 		window.removeEventListener("resize");
	// 	} catch (error) {}
	// };
	// }, [open]);

	return (
		<React.Fragment>
			<Dialog
				classes={{
					root: classes.modalRoot,
					paper: classes.modalProfileEdit + " " + classes.modal,
				}}
				open={open}
				// TransitionComponent={Transition}
				// keepMounted
				onClose={onClose}
				aria-labelledby="classic-modal-slide-title"
				aria-describedby="classic-modal-slide-description"
			>
				<DialogTitle
					id="signup-modal-slide-title"
					disableTypography
					className={classes.modalTitleEdit}
				>
					<Button
						simple
						className={classes.modalCloseButton}
						key="close"
						aria-label="Close"
						onClick={onClose}
					>
						<IconLibrary.Close />
					</Button>
					<h4 className={classes.title + " " + classes.titleAlign}>
						{bizId === 0 ? "Add New Business Page" : "Edit Business Page"}
					</h4>
				</DialogTitle>

				<Formik
					initialValues={{
						pageName: bizPage && bizPage.pageName ? bizPage.pageName : "",
						countryId: bizPage && bizPage.countryId ? bizPage.countryId : 0,
						locationId: bizPage && bizPage.locationId ? bizPage.locationId : 0,
						areaCodeId: bizPage && bizPage.areaCodeId ? bizPage.areaCodeId : 0,
						contactNo: bizPage && bizPage.contactNo ? bizPage.contactNo : "",

						companyName:
							bizPage && bizPage.companyName ? bizPage.companyName : "",
						email: bizPage && bizPage.email ? bizPage.email : "",
						url: bizPage && bizPage.url ? bizPage.url : "",
						industryId: bizPage && bizPage.industryId ? bizPage.industryId : 0,
						customIndustryId:
							bizPage && bizPage.customIndustryId
								? bizPage.customIndustryId
								: 0,
						companyProfile:
							bizPage && bizPage.companyProfile
								? bizPage && bizPage.companyProfile
								: "",
						companySize:
							bizPage && bizPage.companySize ? bizPage.companySize : 0,
						companyTypeId:
							bizPage && bizPage.companyTypeId ? bizPage.companyTypeId : 0,
					}}
					validationSchema={corporateProfileSchema}
					onSubmit={(data) => {
						console.log(data);
						const updateRequest = {
							acctId: user.acctId,
							bizId: bizId,
							pageName: data.pageName.trim(),
							countryId: data.countryId,
							locationId: data.locationId,
							areaCodeId: data.areaCodeId,
							contactNo: data.contactNo.trim(),

							companyName: data.companyName.trim(),
							email: data.email.trim(),
							url: data.url.trim(),
							industryId: data.industryId,
							customIndustryId: data.customIndustryId,
							companyProfile: data.companyProfile.trim(),
							companySize: data.companySize,
							companyTypeId: data.companyTypeId,
							accessToken: localStorage.getItem("token"),
						};
						console.log(updateRequest);
						dispatch(insertUpdateBasicInfo(updateRequest));
						if (buildProfileFlag === 0) {
							dispatch(buildProfile());
						}
						if (data) {
							onClose();
						}
					}}
				>
					{({
						handleChange,
						values,
						errors,
						touched,
						handleBlur,
						handleSubmit,
						setFieldValue,
					}) => (
						<form onSubmit={handleSubmit}>
							<div className={classes.autoHeightBody}>
								<DialogContent
									id="classic-modal-slide-description"
									className={classes.modalBody}
								>
									<Card className={classes.modalCardBorder}>
										<CardBody
											className={
												isDesktop
													? classes.smallModalBodyDesktop +
													  " " +
													  classes.modalBackground
													: classes.smallModalBody +
													  " " +
													  classes.modalBackground
											}
											id="modalCard"
										>
											<GridContainer alignContent="center" alignItems="center">
												<GridItem>
													<div className={classes.modalTitleFlex}>
														<h4 className={classes.modalTitle}>
															Page or Brand Name
														</h4>
														<span>
															This can be your brand or for a specific hiring
															department
														</span>
													</div>
												</GridItem>
												<GridItem
													xs={12}
													sm={12}
													md={12}
													className={classes.gridHeight}
												>
													<TextField
														variant="outlined"
														id="pageName"
														label="Page or Brand Name"
														name="pageName"
														value={values.pageName}
														helperText={touched.pageName ? errors.pageName : ""}
														error={touched.pageName && Boolean(errors.pageName)}
														onChange={(event) => {
															setFieldValue("pageName", event.target.value);
														}}
														placeholder="Required"
														fullWidth
														/* InputProps={{
															startAdornment: (
																<InputAdornment position="start">
																	<People className={classes.inputIconsColor} />
																</InputAdornment>
															),
														}} */
													/>
												</GridItem>
												<GridItem>
													<div>
														<h4 className={classes.modalTitle}>Address</h4>
													</div>
												</GridItem>

												<GridItem
													xs={12}
													sm={6}
													md={6}
													className={classes.gridHeight}
												>
													<Autocomplete
														options={countryList}
														value={
															countryList.filter((item) => {
																return item.countryId === values.countryId;
															})[0] || null
														}
														filterOptions={createFilterOptions({
															matchFrom: "start",
															stringify: (option) => option.countryName,
														})}
														getOptionLabel={(option) =>
															option.countryName ? option.countryName : ""
														}
														getOptionSelected={(option, value) =>
															option.countryId === value.countryId
														}
														onChange={(event, value) => {
															console.log(value);
															if (value) {
																setFieldValue("countryId", value.countryId);
																setCountryChange(value.countryId);
															} else {
																setFieldValue("countryId", "");
																setFieldValue("locationId", "");
															}
														}}
														id="countryId"
														name="countryId"
														renderInput={(params) => (
															<TextField
																{...params}
																helperText={
																	touched.countryId ? errors.countryId : ""
																}
																error={
																	touched.countryId && Boolean(errors.countryId)
																}
																label={"Country"}
																variant="outlined"
															/>
														)}
													/>
												</GridItem>
												<GridItem
													xs={12}
													sm={6}
													md={6}
													className={classes.gridHeight}
												>
													<Autocomplete
														options={locationList}
														disabled={!values.countryId}
														value={
															locationList.filter((item) => {
																return item.locationId === values.locationId;
															})[0] || null
														}
														getOptionSelected={(option, value) =>
															option.locationName === value.locationName
														}
														getOptionLabel={(option) =>
															option.locationName ? option.locationName : ""
														}
														onChange={(event, value) => {
															if (value) {
																setFieldValue("locationId", value.locationId);
															} else {
																setFieldValue("locationId", "");
															}
														}}
														id="locationId"
														name="locationId"
														renderInput={(params) => (
															<TextField
																{...params}
																label={"Location"}
																variant="outlined"
																helperText={
																	touched.locationId ? errors.locationId : ""
																}
																error={
																	touched.locationId &&
																	Boolean(errors.locationId)
																}
															/>
														)}
													/>
												</GridItem>
												<GridItem className={classes.phoneContact}>
													<div>
														<h4 className={classes.modalTitle}>
															Contact Detail
														</h4>
													</div>
												</GridItem>
												<GridItem
													xs={12}
													sm={12}
													md={12}
													className={classes.gridHeight}
												>
													<TextField
														variant="outlined"
														id="email"
														label="Email"
														name="email"
														value={values.email}
														helperText={touched.email ? errors.email : ""}
														error={touched.email && Boolean(errors.email)}
														onChange={(event) => {
															setFieldValue("email", event.target.value);
														}}
														fullWidth
														/* 	InputProps={{
															startAdornment: (
																<InputAdornment position="start">
																	<EmailRoundedIcon
																		className={classes.inputIconsColor}
																	/>
																</InputAdornment>
															),
														}} */
													/>
												</GridItem>

												<GridItem
													xs={12}
													sm={4}
													md={4}
													className={classes.gridHeight}
												>
													<Autocomplete
														options={areaCodeList}
														className={classes.autoCompleteLabel}
														value={
															areaCodeList.filter((item) => {
																return item.areaCodeId === values.areaCodeId;
															})[0] || null
														}
														getOptionSelected={(option, value) =>
															option.areaCodeId === value.areaCodeId
														}
														getOptionLabel={(option) =>
															option.areaCode ? "+" + option.areaCode : ""
														}
														onChange={(event, value) => {
															console.log(value);
															if (value) {
																setFieldValue("areaCodeId", value.areaCodeId);
															} else {
																setFieldValue("areaCodeId", "");
															}
														}}
														id="areaCodeId"
														name="areaCodeId"
														renderInput={(params) => (
															<TextField
																{...params}
																label={"Area Code"}
																variant="outlined"
															/>
														)}
													/>
												</GridItem>
												<GridItem
													xs={12}
													sm={8}
													md={8}
													className={classes.gridHeight}
												>
													<TextField
														variant="outlined"
														id="contactNo"
														label="Phone Number"
														name="contactNo"
														value={values.contactNo}
														helperText={
															touched.contactNo ? errors.contactNo : ""
														}
														error={
															touched.contactNo && Boolean(errors.contactNo)
														}
														onChange={(event) => {
															setFieldValue("contactNo", event.target.value);
														}}
														fullWidth
														/* InputProps={{
															startAdornment: (
																<InputAdornment position="start">
																	<Phone className={classes.inputIconsColor} />
																</InputAdornment>
															),
														}} */
													/>
												</GridItem>

												<GridItem>
													<div>
														<h4 className={classes.modalTitle}>
															About Company
														</h4>
													</div>
												</GridItem>
												<GridItem
													xs={12}
													sm={6}
													md={6}
													className={classes.gridHeight}
												>
													<Autocomplete
														options={industryList}
														// freeSolo
														value={
															industryList.filter((item) => {
																return item.industryId === values.industryId;
															})[0] || null
														}
														getOptionLabel={(option) =>
															option.industryName ? option.industryName : ""
														}
														getOptionSelected={(option, value) =>
															option.industryId === value.industryId
														}
														onChange={(event, value) => {
															if (value) {
																setFieldValue("industryId", value.industryId);
															} else {
																setFieldValue("industryId", "");
															}
														}}
														id="industryId"
														name="industryId"
														renderInput={(params) => (
															<TextField
																{...params}
																label={"Company Industry"}
																variant="outlined"
															/>
														)}
													/>
													{errors.industryId && touched.industryId && (
														<Danger>
															<h6>{errors.industryId}</h6>
														</Danger>
													)}
												</GridItem>
												<GridItem
													xs={12}
													sm={6}
													md={6}
													className={classes.gridHeight}
												>
													<Autocomplete
														options={companyTypeList}
														// freeSolo
														value={
															companyTypeList.filter((item) => {
																return (
																	item.companyTypeId === values.companyTypeId
																);
															})[0] || null
														}
														getOptionSelected={(option, value) =>
															option.companyTypeId === value.companyTypeId
														}
														getOptionLabel={(option) =>
															option.companyTypeName
																? option.companyTypeName
																: ""
														}
														onChange={(event, value) => {
															if (value) {
																setFieldValue(
																	"companyTypeId",
																	value.companyTypeId
																);
															} else {
																setFieldValue("companyTypeId", "");
															}
														}}
														id="companyTypeId"
														name="companyTypeId"
														renderInput={(params) => (
															<TextField
																{...params}
																label={"Company Type"}
																variant="outlined"
															/>
														)}
													/>
													{errors.companyTypeId && touched.companyTypeId && (
														<Danger>
															<h6>{errors.companyTypeId}</h6>
														</Danger>
													)}
												</GridItem>
												<GridItem
													xs={12}
													sm={12}
													md={6}
													className={classes.gridHeight}
												>
													<TextField
														variant="outlined"
														id="companyName"
														label="Company Name"
														name="companyName"
														value={values.companyName}
														helperText={
															touched.companyName ? errors.companyName : ""
														}
														error={
															touched.companyName && Boolean(errors.companyName)
														}
														onChange={(event) => {
															setFieldValue("companyName", event.target.value);
														}}
														placeholder="Required"
														fullWidth
														/* 	InputProps={{
															startAdornment: (
																<InputAdornment position="start">
																	<WorkRoundedIcon
																		className={classes.inputIconsColor}
																	/>
																</InputAdornment>
															),
														}} */
													/>
												</GridItem>

												<GridItem
													xs={12}
													sm={12}
													md={6}
													className={classes.gridHeight}
												>
													<TextField
														variant="outlined"
														id="url"
														label="Company Url"
														name="url"
														value={values.url}
														helperText={touched.url ? errors.url : ""}
														error={touched.url && Boolean(errors.url)}
														onChange={(event) => {
															setFieldValue("url", event.target.value);
														}}
														fullWidth
														/* InputProps={{
															startAdornment: (
																<InputAdornment position="start">
																	<LanguageRoundedIcon
																		className={classes.inputIconsColor}
																	/>
																</InputAdornment>
															),
														}} */
													/>
												</GridItem>

												<GridItem xs={12} sm={12} md={12}>
													<TextField
														variant="outlined"
														label="Company Profile"
														name="modalCard"
														value={values.companyProfile}
														helperText={
															touched.companyProfile
																? errors.companyProfile
																: ""
														}
														error={
															touched.companyProfile &&
															Boolean(errors.companyProfile)
														}
														onChange={(event) => {
															setFieldValue(
																"companyProfile",
																event.target.value
															);
														}}
														fullWidth
														InputProps={{
															multiline: true,
															rows: 10,
														}}
													/>
												</GridItem>
												<GridItem>
													<h4 className={classes.title}>
														Employee Size{" "}
														<b className={classes.grayFont}>
															{values.companySize} Employee
														</b>
													</h4>
												</GridItem>
												<Hidden xsDown>
													<GridItem
														xs={12}
														sm={12}
														md={12}
														/* className={classes.textCenter} */
													>
														<IconButton
															disabled={values.companySize <= 0 ? true : false}
															onClick={() => {
																setFieldValue(
																	"companySize",
																	values.companySize - 1
																);
															}}
														>
															<RemoveCircleOutlineIcon fontSize="large" />
														</IconButton>

														<TextField
															variant="outlined"
															value={values.companySize || 0}
															/* 	label="Company Size" */
															onChange={(event) => {
																const regex = /^[0-9\b]+$/;
																if (
																	event.target.value === "" ||
																	regex.test(event.target.value)
																) {
																	setFieldValue(
																		"companySize",
																		event.target.value === ""
																			? ""
																			: Number(event.target.value)
																	);
																}
															}}
															inputProps={{
																min: 0,
																className: classes.textCenter,
															}}
														/>
														<IconButton
															onClick={() => {
																setFieldValue(
																	"companySize",
																	values.companySize + 1
																);
															}}
														>
															<AddCircleOutlineIcon fontSize="large" />
														</IconButton>
													</GridItem>
												</Hidden>
												<Hidden smUp>
													<GridItem
														xs={3}
														sm={3}
														className={classes.textCenter}
													>
														<IconButton
															disabled={values.companySize <= 0 ? true : false}
															onClick={() => {
																setFieldValue(
																	"companySize",
																	values.companySize - 1
																);
															}}
														>
															<RemoveCircleOutlineIcon fontSize="large" />
														</IconButton>
													</GridItem>
													<GridItem xs={6} sm={6}>
														<TextField
															variant="outlined"
															value={values.companySize || 0}
															/* 	label="Company Size" */
															fullWidth
															onChange={(event) => {
																const regex = /^[0-9\b]+$/;
																if (
																	event.target.value === "" ||
																	regex.test(event.target.value)
																) {
																	setFieldValue(
																		"companySize",
																		event.target.value === ""
																			? ""
																			: Number(event.target.value)
																	);
																}
															}}
															inputProps={{
																min: 0,
																className: classes.textCenter,
															}}
														/>
													</GridItem>
													<GridItem
														xs={3}
														sm={3}
														className={classes.textCenter}
													>
														<IconButton
															onClick={() => {
																setFieldValue(
																	"companySize",
																	values.companySize + 1
																);
															}}
														>
															<AddCircleOutlineIcon fontSize="large" />
														</IconButton>
													</GridItem>
												</Hidden>
												{/* 
												<GridItem
													xs={12}
													sm={3}
													md={3}
													className={classes.textCenter}
												>
												</GridItem>

												<GridItem
													xs={12}
													sm={2}
													md={2}
													className={classes.textCenter}
												>
													
												</GridItem> */}
											</GridContainer>
										</CardBody>
									</Card>
								</DialogContent>
							</div>

							<DialogActions className={classes.modalFooterEdit}>
								<Button round onClick={handleSubmit} color="oppty">
									<b>Save</b>
								</Button>
							</DialogActions>
						</form>
					)}
				</Formik>
			</Dialog>
		</React.Fragment>
	);
};

export default CorporateBasicProfileEditModal;
