import {
	grayColor,
	primaryColor,
	title,
	blackColor,
	whiteColor,
	container,
	opptyDesignColor,
	hexToRgb,
} from "assets/jss/material-kit-pro-react.js";

const RightSideBarUserListStyle = (theme) => ({
	LeftSidebar_box: {
		position: "sticky",
		top: "10px",
		overflow: "hidden",
	},
	RightSidebar_box: {
		/* marginTop: "5px", */
		backgroundColor: whiteColor,
		padding: "20px 10px",
		// minHeight: "150px",
		maxHeight: "fit-content",
		height: "auto",
		borderRadius: "18px",
		scrollbarWidth: "none",
		"&::-webkit-scrollbar": {
			display: "none",
		},
		"@media (max-height: 450px)": {
			height: "calc(100vh - 180px)",
			overflow: "scroll",
		},
	},
	RightSideBarOverflow: {
		overflowY: "auto",
		height: "auto",
	},
	createPostBtn: {
		// backgroundColor: "#6F52ED",
		fontSize: "15px",
		fontWeight: "700",
		borderRadius: "20px",
		textTransform: "uppercase",
		margin: "0 0 10px",
	},

	sideBarMenu: {
		marginLeft: "8px",
	},

	sideBarMenuBx: {
		margin: "0 0 15px",

		"& h6": {
			fontSize: "14px",
			color: "#92929D",
			margin: "0",
		},

		"& ul": {
			padding: "0",
			margin: "0",
			listStyle: "none",
		},

		"& li": {
			padding: "0",
			margin: "0",
			listStyle: "none",
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
		},

		"& .MuiListItem-gutters": {
			fontSize: "13px",
			color: "#171725",
			padding: "10px 0",
		},
		"& .MuiListItemIcon-root": {
			minWidth: "30px",
			marginRight: "15px",
			justifyContent: "center",
		},
	},

	profileImg: {
		width: "32px",
		height: "32px",
		borderRadius: "50%",
		overflow: "hidden",
	},

	onlineStatus: {
		width: "10px",
		height: "10px",
		borderRadius: "50%",
		backgroundColor: "#82C43C",
		display: "inline-block",
	},

	searchBar: {
		borderTop: "1px solid #92929D",
		fontSize: "14px",
		width: "calc(100% + 20px)",
		margin: "0 -10px",
		position: "relative",
		"& .MuiTypography-body1, .MuiInputBase-root": {
			width: "100%",
		},
		"& input": {
			backgroundColor: "#fff",
			padding: "15px 15px 15px 40px",
		},
		"& .MuiInput-underline:before, .Mui-focused:after": {
			display: "none",
		},
	},

	searchIco: {
		position: "absolute",
		left: "15px",
		top: "15px",
	},

	skeletonGrp: {
		display: "flex",
		alignItems: "center",
	},
	sideBarGroupHead: {
		margin: "0 0 10px 0",
	},
	sideBarNoResultText: {
		fontSize: "14px",
		fontWeight: 500,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		color: "#000",
		textAlign: "center",
		margin: "20px",
		paddingBottom: "20px",
	},
	sideBarNavigateButton: {
		textAlign: "center",
		paddingBottom: "40px",
		"& > a": {
			padding: "10px 12px",
			borderRadius: "15px",
			color: "#6F52ED",
			borderColor: "#6F52ED",
			border: "2px solid",
			background: "white",
			whiteSpace: "normal",
			fontSize: "14px",
			fontWeight: "500",
			whiteSpace: "normal",
			border: "2px solid",
			background: "white",
			boxShadow:
				"0 2px 2px 0 rgba(" +
				hexToRgb(opptyDesignColor[0]) +
				", 0.14), 0 3px 1px -2px rgba(" +
				hexToRgb(opptyDesignColor[0]) +
				", 0.2), 0 1px 5px 0 rgba(" +
				hexToRgb(opptyDesignColor[0]) +
				", 0.12)",
			"&:hover,&:focus": {
				backgroundColor: "white",
				color: opptyDesignColor[0],
				boxShadow:
					"0 14px 26px -12px rgba(" +
					hexToRgb(opptyDesignColor[0]) +
					", 0.42), 0 4px 23px 0px rgba(" +
					hexToRgb(blackColor) +
					", 0.12), 0 8px 10px -5px rgba(" +
					hexToRgb(opptyDesignColor[0]) +
					", 0.2)",
			},
		},
	},
});
export default RightSideBarUserListStyle;
