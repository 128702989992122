import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { searchProfileInfo } from "configurations/redux/searchProfileSlice.js";
import InfiniteScroll from "react-infinite-scroll-component";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import CircularProgress from "@material-ui/core/CircularProgress";

import SearchResultCard from "./SearchResultCard.js";
import searchProfilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/SearchProfilePage/searchProfilePageStyle.js";
import { isMobile } from "react-device-detect";
import SearchImage from "assets/img/Search_Image.jpg";
import NoResultImage from "assets/img/No_Result.jpg";
import {
	createChatroom,
	createChatroomReset,
} from "configurations/redux/messageChatroomSlice.js";

const useStyles = makeStyles(searchProfilePageStyle);

export default function SearchResultSection() {
	const classes = useStyles();
	const searchResult = useSelector((state) => state.searchProfile.searchResult);
	const searchParams = useSelector((state) => state.searchProfile.searchParams);
	const currentSize = useSelector(
		(state) => state.searchProfile.lastRecordSize
	);
	const isSearching = useSelector((state) => state.searchProfile.isSearching);
	const isLoading = useSelector((state) => state.searchProfile.isLoading);

	const userStatus = useSelector((state) => state.auth.user);
	const isEnd = useSelector((state) => state.searchProfile.isEnd);
	const bizId = useSelector((state) => state.business.bizId);
	const dispatch = useDispatch();

	const getMessageNavigate = async (item) => {
		var chatroomList = [];
		chatroomList.push({ chatroomListId: -1, acctId: userStatus.acctId });
		chatroomList.push({ chatroomListId: -1, acctId: item });
		const messageApplicant = {
			acctId: userStatus.acctId,
			accessToken: localStorage.getItem("token"),
			email: userStatus.email,
			chatroomTitle: "business_" + item + "_" + bizId,
			chatroomList: chatroomList,
			receiverId: item,
			bizId: bizId,
			receiverBizId: 0,
			typeId: 2,
		};
		console.log(messageApplicant);
		dispatch(createChatroom(messageApplicant));
	};

	const chatroomId = useSelector((state) => state.messageChatroom.chatroomId);
	const isCreated = useSelector((state) => state.messageChatroom.isCreated);
	React.useEffect(() => {
		console.log(chatroomId);
		if (isCreated && chatroomId > 0) {
			// window.open("/message-page/C" + chatroomId + "/", "_blank");
			window.open(`/message-page/0/C${chatroomId}/`, "_blank");
			// history.push("/message-page/C" + chatroomId + "/");
		}
		dispatch(createChatroomReset());
	}, [isCreated]);

	const ResultCard = searchResult.map((item, index) => {
		return (
			<GridItem
				xs={12}
				sm={6}
				md={12}
				lg={12}
				key={index}
				className={classes.cardSpace}
			>
				<SearchResultCard
					key={item.acctId}
					item={item}
					profileAcctId={item.acctId}
					getMessageNavigate={getMessageNavigate}
				/>
			</GridItem>
		);
	});

	const loadProfile = () => {
		const requestProfile = {
			acctId: userStatus.acctId,
			accessToken: localStorage.getItem("token"),
			email: userStatus.email,
			keyword: searchParams.keyword,
			countryId: searchParams.countryId,
			locationId: searchParams.locationId,
			/* 			studyFieldId: searchParams.studyFieldId, */
			lastRecordSize: currentSize,
			requestRecordSize: 4,
		};
		console.log(requestProfile);
		dispatch(searchProfileInfo(requestProfile));
	};

	return (
		<div>
			{searchResult.length > 0 ? (
				<InfiniteScroll
					dataLength={searchResult.length}
					next={loadProfile}
					hasMore={!isEnd}
					loader={
						<GridContainer
							justifyContent="center"
							alignContent="center"
							alignItems="center"
						>
							<GridItem xs={12} sm={12} md={12}>
								<div
									className={classes.loadingContainer + " " + classes.endSearch}
								>
									<CircularProgress />
								</div>
							</GridItem>
						</GridContainer>
					}
					endMessage={
						<h4
							className={
								classes.title +
								" " +
								classes.textCenter +
								" " +
								classes.endSearch
							}
						>
							End of record
						</h4>
					}
					scrollThreshold={0.6}
					style={{ overflow: "hidden" }}
				>
					<div id="profileResult"></div>
					<GridContainer className={classes.profileCardGrid}>
						{ResultCard}
					</GridContainer>
				</InfiniteScroll>
			) : null}
			{isEnd && searchResult.length === 0 && (
				<Card className={classes.cardFrame}>
					<CardBody>
						<h5 className={classes.title + " " + classes.textCenter}>
							No available result. Please use different filter options.
						</h5>
						<GridContainer justifyContent="center">
							<GridItem xs={12} sm={12} md={6}>
								<img
									src={NoResultImage}
									alt="..."
									className={classes.loadingImage}
								/>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
			)}

			{isLoading && searchResult.length === 0 && (
				<GridContainer justifyContent="center">
					<GridItem xs={12} sm={12} md={12}>
						<div className={classes.loadingContainer}>
							<CircularProgress />
						</div>
					</GridItem>
					<GridItem xs={12} sm={12} md={6}>
						<img src={SearchImage} alt="..." className={classes.loadingImage} />
					</GridItem>
				</GridContainer>
			)}
		</div>
	);
}
