import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import { Typography, makeStyles, IconButton } from '@material-ui/core';

import dp from 'assets/img/faces/marc.jpg';
import gallery from 'assets/images/documentation.png';
import video from 'assets/img/video.png';
import { ReactComponent as SendIcon } from 'assets/svg/send-svgrepo-com.svg';

import avatar from 'assets/img/candidate_avatar.jpeg';
import postSomethingCardStyles from '../../../../assets/jss/material-kit-pro-react/views/componentStyle/profileView/myActivity/postSomethingCardStyles.js';
import NoSocialPost from 'assets/img/no-social-post.jpg';

const NoPostCard = ({ isSearchPage }) => {
  const styles = makeStyles(postSomethingCardStyles)();
  const user = useSelector(state => state.auth.user);

  return (
    <Fragment>
      <div className={styles.body} style={{ cursor: 'pointer' }}>
        <Typography component="div" className={styles.header}>
          <Typography component="h2" className={styles.title} style={{ textAlign: 'center' }}>
            {isSearchPage ? 'No Post Found' : 'No Posts Created Yet'}
          </Typography>
        </Typography>

        <hr className={styles.divider} />

        <Typography component="div" className={styles.main}>
          <img src={NoSocialPost} style={{ width: '100%' }} />
        </Typography>
      </div>
    </Fragment>
  );
};

export default NoPostCard;
