import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
	departmentViewRoadmap,
	viewRoadmapRoleByRoleId,
} from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Industry_Product_Engineering from "assets/img/Career_Roadmap/Product_Engineering/Industry_Product_Engineering_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function IndustryProductEngineering() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Industry_Product_Engineering;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "pe",
				shape: "poly",
				coords: [84,351,75,354,72,357,67,362,66,367,65,424,67,430,71,436,77,440,85,441,175,442,184,439,189,435,192,429,194,422,194,370,192,361,187,356,180,352,174,351,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "310",
				shape: "poly",
				coords: [228,355,219,358,214,362,212,366,211,371,210,420,214,426,217,430,223,432,230,434,360,432,367,430,370,427,371,424,373,419,374,370,371,362,367,358,361,356,353,355,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "311",
				shape: "poly",
				coords: [703,356,694,358,690,361,688,367,687,373,688,420,690,427,693,431,699,433,704,434,833,433,841,431,846,427,848,423,849,418,850,375,849,366,844,360,838,357,832,356,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "312",
				shape: "poly",
				coords: [1202,354,1194,357,1191,362,1189,366,1188,372,1188,414,1189,422,1192,427,1196,429,1202,431,1333,432,1343,429,1347,426,1350,420,1351,415,1351,370,1349,362,1344,357,1340,354,1334,354,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "313",
				shape: "poly",
				coords: [1702,353,1697,356,1692,360,1690,364,1690,370,1690,418,1692,423,1696,426,1700,430,1705,431,1844,430,1851,428,1855,426,1857,420,1858,416,1859,367,1856,360,1852,356,1846,354,1841,353,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "pe") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo);
			dispatch(viewRoadmapRoleByRoleId(requestInfo));

			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
