import React from 'react';
import axios from 'axios';

import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';

import { instanceUrl } from 'configurations/configurations.js';
import { updateBasicInfo } from 'configurations/redux/userSlice.js';
import { buildProfile } from 'configurations/redux/authSlice.js';
import { profileUpdateSchema } from 'configurations/configurations.js';
/* import ProfileStrengthTip from "./ProfileStrengthTip.js"; */
import { Typography, Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import Danger from 'components/Typography/Danger.js';

import Close from '@material-ui/icons/Close';
import People from '@material-ui/icons/People';
import Phone from '@material-ui/icons/Phone';

import profilePageStyle from 'assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js';
import { isDesktop } from 'react-device-detect';
import IconLibrary from 'views/Component/IconLibrary.js';
import { useModal } from 'hooks';
import ConfirmDialog from 'components/CustomDialog/ConfirmDialog.js';
import { deactivateEditType } from 'configurations/redux/editProfileModalSlice';
const useStyles = makeStyles(profilePageStyle);

const BasicInfoSection = ({ saveDetailModalProps, onCancelChangesConfirm, setIsChanged }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const profileRecord = useSelector(state => state.user.profileRecord);
  const user = useSelector(state => state.user);
  const isMainModalCloseRequest = useSelector(state => state.editProfileModal.isMainModalCloseRequest);

  const [countryList, setCountryList] = React.useState([]);
  const [areaCodeList, setAreaCodeList] = React.useState([]);
  const [locationList, setLocationList] = React.useState([]);
  const [countryChange, setCountryChange] = React.useState();
  const [suggestedAreaCode, setSuggestedAreaCode] = React.useState();
  const [contactNoChange, setContactNoChange] = React.useState();
  const [buttonVisible, setButtonVisible] = React.useState(false);
  const cancelFormProps = useModal();
  React.useEffect(() => {
    let mounted = true;
    console.log(saveDetailModalProps.modalOpen);
    let CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchCountryList = async () => {
      try {
        const res = await axios.get(`${instanceUrl}/api/md/get-all-country`, {
          cancelToken: source.token,
        });

        setCountryList(res.data.payload);
      } catch (error) {
        if (axios.isCancel(error)) {
          //console.log("unmount caused");
        } else {
          throw error;
        }
      }
    };
    const fetchAreaList = async () => {
      try {
        const res = await axios.get(`${instanceUrl}/api/md/get-all-area-code`, {
          cancelToken: source.token,
        });
        setAreaCodeList(res.data.payload);
      } catch (error) {
        if (axios.isCancel(error)) {
          //console.log("unmount caused");
        } else {
          throw error;
        }
      }
    };
    if (mounted) {
      fetchCountryList();
      fetchAreaList();
      // fetchLocationList();
    }

    return () => {
      mounted = false;
      source.cancel();
    };
  }, []);
  const cancelForm = {
    title: 'Discard Action',
    text: 'All the actions will be discarded',
    cancelText: 'No',
    confirmText: 'Yes',
  };

  React.useEffect(() => {
    if (countryChange) {
      console.log(countryChange);
      let CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const fetchLocationList = async countryId => {
        try {
          const res = await axios.get(`${instanceUrl}/api/md/get-all-location-by-country`, {
            cancelToken: source.token,
            params: { countryId: countryId },
          });
          setLocationList(res.data.payload);
        } catch (error) {
          if (axios.isCancel(error)) {
            //console.log("unmount caused");
          } else {
            throw error;
          }
        }
      };
      fetchLocationList(countryChange);
      const suggestedAreaCode =
        areaCodeList &&
        areaCodeList.filter(item => {
          return item.countryId === countryChange;
        })[0];
      if (profileRecord.contactNo === '' || contactNoChange === '') setSuggestedAreaCode(suggestedAreaCode);
    }
  }, [countryChange]);
  return (
    <React.Fragment>
      <Formik
        initialValues={{
          surname: profileRecord.surname ? profileRecord.surname : '',
          firstName: profileRecord.firstName ? profileRecord.firstName : '',
          middleName: profileRecord.middleName ? profileRecord.middleName : '',
          countryId: profileRecord.countryId,
          locationId: profileRecord.locationId,
          areaCodeId: profileRecord.areaCodeId,
          website: profileRecord.website ? profileRecord.website : '',
          contactNo: profileRecord.contactNo ? profileRecord.contactNo : '',
          headline: profileRecord.headline ? profileRecord.headline : '',
          aboutMe: profileRecord.aboutMe ? profileRecord.aboutMe : '',
        }}
        validationSchema={profileUpdateSchema}
        onSubmit={data => {
          const updateRequest = {
            acctId: user.acctId,
            surname: data.surname.trim(),
            firstName: data.firstName.trim(),
            middleName: data.middleName.trim(),
            countryId: data.countryId,
            locationId: data.locationId,
            areaCodeId: data.areaCodeId,
            contactNo: data.contactNo.trim(),
            headline: data.headline.trim(),
            aboutMe: data.aboutMe.trim(),
            url: data.website.trim(),
            accessToken: localStorage.getItem('token'),
          };
          dispatch(updateBasicInfo(updateRequest)).then(() => setIsChanged(false));
          /* if (buildProfileFlag === 0) {
							dispatch(buildProfile());
						} */
          setButtonVisible(false);
        }}
      >
        {({ handleChange, values, errors, touched, handleBlur, handleSubmit, setFieldValue, resetForm, dirty }) => {
          if (dirty) {
          }
          return (
            <form onSubmit={handleSubmit}>
              <div className={classes.autoHeightBody}>
                <Card className={classes.modalCardBorder}>
                  <CardBody id="modalCard">
                    <GridContainer justifyContent="center">
                      <GridItem xs={12} className={classes.buttonHeader + ' ' + classes.gridItem}>
                        <Typography component="h2" className={classes.tabTypeTitleSection}>
                          Profile
                        </Typography>

                        <Typography
                          component="div"
                          style={{
                            display: buttonVisible ? 'block' : 'none',
                          }}
                        >
                          <Button className={classes.saveProfileInformationBtn} onClick={handleSubmit}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 24 18">
                              <path
                                id="Path"
                                d="M7.636,14.239,1.909,8.6,0,10.478,7.636,18,24,1.881,22.091,0Z"
                                fill="#0ab98d"
                              />
                            </svg>
                            <span>SAVE</span>
                          </Button>
                          <Button
                            className={classes.cancelEditBtn}
                            onClick={() => {
                              /*     setIsCancelDialogOpen(true); */
                              saveDetailModalProps.setModalOpen(true);
                              /*                resetForm(); */
                            }}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                              <path
                                id="Path"
                                d="M14,1.41,12.59,0,7,5.59,1.41,0,0,1.41,5.59,7,0,12.59,1.41,14,7,8.41,12.59,14,14,12.59,8.41,7Z"
                                fill="#4a4a4a"
                              />
                            </svg>
                            <span>CANCEL</span>
                          </Button>
                        </Typography>
                      </GridItem>

                      {/**FirstName */}
                      <GridItem>
                        <div>
                          <h4 className={classes.title + ' ' + classes.modalSubTitle}>Name</h4>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={4} md={4} className={classes.gridHeight}>
                        <TextField
                          variant="outlined"
                          id="firstName"
                          label="First Name"
                          name="fisrtName"
                          value={values.firstName}
                          helperText={touched.firstName ? errors.firstName : ''}
                          error={touched.firstName && Boolean(errors.firstName)}
                          onChange={event => {
                            const regex = /^[A-Za-z]+$/;
                            if (event.target.value === '' || regex.test(event.target.value)) {
                              setFieldValue('firstName', event.target.value);
                              setButtonVisible(true);

                              setIsChanged(true);
                            }
                          }}
                          placeholder="Required"
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <People className={classes.inputIconsColor} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={4} md={4} className={classes.gridHeight}>
                        <TextField
                          variant="outlined"
                          id="middleName"
                          label="Middle Name"
                          name="middleName"
                          value={values.middleName}
                          helperText={touched.middleName ? errors.middleName : ''}
                          error={touched.middleName && Boolean(errors.middleName)}
                          onChange={event => {
                            const regex = /^[A-Za-z]+$/;
                            if (event.target.value === '' || regex.test(event.target.value)) {
                              setFieldValue('middleName', event.target.value);
                              setButtonVisible(true);
                              setIsChanged(true);
                            }
                          }}
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <People className={classes.inputIconsColor} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </GridItem>
                      {/**LastName */}
                      <GridItem xs={12} sm={4} md={4} className={classes.gridHeight}>
                        <TextField
                          variant="outlined"
                          id="surname"
                          label="Last Name/Surname"
                          name="surname"
                          value={values.surname}
                          helperText={touched.surname ? errors.surname : ''}
                          error={touched.surname && Boolean(errors.surname)}
                          onChange={event => {
                            const regex = /^[A-Za-z]+$/;
                            if (event.target.value === '' || regex.test(event.target.value)) {
                              setFieldValue('surname', event.target.value);
                              setButtonVisible(true);
                              setIsChanged(true);
                            }
                          }}
                          placeholder="Required"
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <People className={classes.inputIconsColor} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </GridItem>
                      <GridItem>
                        <div>
                          <h4 className={classes.title + ' ' + classes.modalSubTitle}>Address</h4>
                        </div>
                      </GridItem>

                      <GridItem xs={12} sm={6} md={6} className={classes.gridHeight}>
                        <Autocomplete
                          options={countryList}
                          value={
                            countryList.filter(item => {
                              return item.countryId === values.countryId;
                            })[0] || null
                          }
                          getOptionLabel={option => (option.countryName ? option.countryName : '')}
                          getOptionSelected={(option, value) => option.countryId === value.countryId}
                          filterOptions={createFilterOptions({
                            matchFrom: 'start',
                            stringify: option => option.countryName,
                          })}
                          onChange={(event, value) => {
                            console.log(value);
                            setButtonVisible(true);
                            setIsChanged(true);

                            if (value) {
                              setFieldValue('countryId', value.countryId);
                              setFieldValue('locationId', '');
                              setCountryChange(value.countryId);
                            } else {
                              setFieldValue('countryId', '');
                              setFieldValue('locationId', '');
                            }
                          }}
                          id="countryId"
                          name="countryId"
                          renderInput={params => (
                            <TextField
                              {...params}
                              helperText={touched.countryId ? errors.countryId : ''}
                              error={touched.countryId && Boolean(errors.countryId)}
                              label={'Country'}
                              variant="outlined"
                            />
                          )}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={6} md={6} className={classes.gridHeight}>
                        <Autocomplete
                          options={locationList}
                          disabled={!values.countryId}
                          value={
                            locationList.filter(item => {
                              return item.locationId === values.locationId;
                            })[0] || null
                          }
                          getOptionSelected={(option, value) => option.locationName === value.locationName}
                          getOptionLabel={option => (option.locationName ? option.locationName : '')}
                          onChange={(event, value) => {
                            setButtonVisible(true);
                            setIsChanged(true);

                            if (value) {
                              setFieldValue('locationId', value.locationId);
                            } else {
                              setFieldValue('locationId', '');
                            }
                          }}
                          id="locationId"
                          name="locationId"
                          renderInput={params => (
                            <TextField
                              {...params}
                              label={'Location'}
                              variant="outlined"
                              helperText={touched.locationId ? errors.locationId : ''}
                              error={touched.locationId && Boolean(errors.locationId)}
                            />
                          )}
                        />
                      </GridItem>
                      <GridItem className={classes.phoneContact}>
                        <div>
                          <h4 className={classes.title + ' ' + classes.modalSubTitle}>Phone Contact</h4>
                        </div>
                      </GridItem>

                      <GridItem xs={6} sm={4} md={4} className={classes.gridHeight}>
                        <Autocomplete
                          options={areaCodeList}
                          className={classes.autoCompleteLabel}
                          value={
                            suggestedAreaCode ||
                            areaCodeList.filter(item => {
                              return item.areaCodeId === values.areaCodeId;
                            })[0] ||
                            null
                          }
                          getOptionSelected={(option, value) => {
                            if (option.areaCodeId === value.areaCodeId) {
                              //console.log(option, value);
                              return true;
                            } else {
                              return false;
                            }
                          }}
                          getOptionLabel={option => (option.areaCode ? '+' + option.areaCode : '')}
                          onChange={(event, value) => {
                            console.log(value);
                            setButtonVisible(true);
                            setIsChanged(true);

                            if (value) {
                              setFieldValue('areaCodeId', value.areaCodeId);
                            } else {
                              setFieldValue('areaCodeId', null);
                            }
                          }}
                          id="areaCodeId"
                          name="areaCodeId"
                          renderInput={params => <TextField {...params} label={'Area Code'} variant="outlined" />}
                        />
                        {errors.areaCodeId && touched.areaCodeId && (
                          <Danger>
                            <h6>{errors.areaCodeId}</h6>
                          </Danger>
                        )}
                      </GridItem>
                      <GridItem xs={6} sm={8} md={8} className={classes.gridHeight}>
                        <TextField
                          variant="outlined"
                          id="contactNo"
                          label="Phone Number"
                          name="contactNo"
                          value={values.contactNo}
                          helperText={touched.contactNo ? errors.contactNo : ''}
                          error={touched.contactNo && Boolean(errors.contactNo)}
                          onChange={event => {
                            setButtonVisible(true);
                            setIsChanged(true);

                            setFieldValue('contactNo', event.target.value);
                            setContactNoChange(event.target.value);
                            if (suggestedAreaCode) {
                              setFieldValue('areaCodeId', suggestedAreaCode.areaCodeId);
                            }
                            console.log(values.areaCodeId);
                          }}
                          fullWidth
                        />
                      </GridItem>
                      <GridItem>
                        <div>
                          <h4 className={classes.title + ' ' + classes.modalSubTitle}>Headline</h4>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12} className={classes.gridHeight}>
                        <TextField
                          variant="outlined"
                          id="headline"
                          label="Headline"
                          name="headline"
                          value={values.headline}
                          helperText={touched.headline ? errors.headline : ''}
                          error={touched.headline && Boolean(errors.headline)}
                          onChange={event => {
                            setFieldValue('headline', event.target.value);
                            setButtonVisible(true);
                            setIsChanged(true);
                          }}
                          placeholder="Write an engaging profile headline that introduces what you do"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </GridItem>
                      <GridItem>
                        <div>
                          <h4 className={classes.title + ' ' + classes.modalSubTitle}>Website</h4>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12} className={classes.gridHeight}>
                        <TextField
                          variant="outlined"
                          id="website"
                          label="website"
                          name="Website/URL"
                          value={values.website}
                          helperText={touched.website ? errors.website : ''}
                          error={touched.website && Boolean(errors.website)}
                          onChange={event => {
                            setFieldValue('website', event.target.value);
                            setButtonVisible(true);
                            setIsChanged(true);
                          }}
                          placeholder="optional"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </GridItem>
                      <GridItem>
                        <div>
                          <h4 className={classes.title + ' ' + classes.modalSubTitle}>About Me</h4>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          variant="outlined"
                          id="aboutMe"
                          label="About Me"
                          name="aboutMe"
                          value={values.aboutMe}
                          helperText={
                            touched.aboutMe
                              ? errors.aboutMe
                                ? errors.aboutMe
                                : `${values.aboutMe.length}/1200`
                              : `${values.aboutMe.length}/1200`
                          }
                          error={touched.aboutMe && Boolean(errors.aboutMe)}
                          onChange={event => {
                            setFieldValue('aboutMe', event.target.value);
                            setButtonVisible(true);
                            setIsChanged(true);
                          }}
                          fullWidth
                          placeholder="Describe yourself and highlight why potential employers should consider you"
                          InputProps={{
                            multiline: true,
                            rows: 5,
                            maxLength: 1200,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </div>
              <ConfirmDialog
                {...{ ...saveDetailModalProps }}
                bodyContent={cancelForm}
                modalOpen={buttonVisible && saveDetailModalProps.modalOpen}
                handleSubmit={() => {
                  resetForm();
                  setButtonVisible(false);
                  if (isMainModalCloseRequest) {
                    setIsChanged(false);
                    dispatch(deactivateEditType());
                    saveDetailModalProps.setModalOpen(false);
                  } else {
                    onCancelChangesConfirm();
                  }
                }}
              />
            </form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

export default BasicInfoSection;
