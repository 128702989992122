import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	getArticleCategory,
	getArticleList,
	getArticleListByKeyword,
	removeAllTag,
	addAllTag,
	searchTagAdd,
	searchTagRemove,
	// setKeyword,
	getCardAds,
	getBannerAds,
	// getCardAdsCount,
} from "configurations/redux/communitySlice.js";
import { Formik } from "formik";
import { makeStyles } from "@material-ui/core/styles";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

//Page Components
import Header from "views/Component/Header.js";
import Footer from "views/Component/Footer.js";

import communityPageStyle from "../../../assets/jss/material-kit-pro-react/views/pageStyle/CommunityPage/communityPageStyle.js";
import SearchResultSection from "./SearchResultSection";
import CustomSearchInput from "components/CustomInput/CustomSearchInput";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import Accordion from "components/Accordion/Accordion";
import { Chip } from "@material-ui/core";
import Backdrop from "../../Component/Backdrop.js";
import CommunityBannerAds from "./CommunityBannerAds.js";
import BottomNavBar from "components/BottomNavBar/BottomNavBar.js";
import { gaPageView } from "configurations/configurations.js";

const useStyles = makeStyles(communityPageStyle);

function useWindowSize() {
	const [size, setSize] = React.useState([0, 0]);
	React.useLayoutEffect(() => {
		function updateSize() {
			setSize([window.innerWidth, window.innerHeight]);
		}
		window.addEventListener("resize", updateSize);
		updateSize();
		return () => window.removeEventListener("resize", updateSize);
	}, []);
	return size;
}

export default function SearchCommunityPage() {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [width, height] = useWindowSize();
	const userStatus = useSelector((state) => state.auth.user);
	// const [checkedTag, setCheckedTag] = React.useState([]);
	// const searchResult = useSelector((state) => state.community.searchResult);
	// const keywordResult = useSelector((state) => state.community.keywordResult);

	// const cardAdsCount = useSelector((state) => state.community.cardAdsCount);
	const searchTag = useSelector((state) => state.community.searchTag);
	const defaultFilterTag = useSelector(
		(state) => state.community.defaultFilterTag
	);

	const searchFilterTag = useSelector(
		(state) => state.community.searchFilterTag
	);
	const defaultTag = useSelector((state) => state.community.defaultTag);
	const keyword = useSelector((state) => state.community.keyword);
	const isSearching = useSelector((state) => state.community.isSearching);
	const isLoading = useSelector((state) => state.community.isLoading);
	const bizId = useSelector((state) => state.business.bizId);
	// const adsCountLoading = useSelector(
	// 	(state) => state.community.adsCountLoading
	// );
	// const loadingArticleList = useSelector(
	// 	(state) => state.community.loadingArticleList
	// );

	useEffect(() => {
		gaPageView(window.location.pathname);
		const getCount = async () => {
			try {
				/* const requestCountProfile = {
					email: userStatus.email,
					adsTypeId: 2,
					accessToken: localStorage.getItem("token"),
				};
				await dispatch(getCardAdsCount(requestCountProfile)); */
				const requestProfile = {
					acctId: userStatus.acctId,
					acctTypeId: bizId > 0 ? 2 : userStatus.acctTypeId,
					accessToken: localStorage.getItem("token"),
					email: userStatus.email,
					categoryIds: "",
					subCategoryIds: "",
					lastRecordSize: 0,
					requestRecordSize: 12,
					paginationClick: false,
					defaultLoad: true,
					keyword: "",
				};
				dispatch(getArticleList(requestProfile));
				/* 
				console.log(cardAdsCount); */
				dispatch(getArticleCategory(bizId > 0 ? 2 : userStatus.acctTypeId));

				scrollToTop();
				const requestCardProfile = {
					email: userStatus.email,
					adsTypeId: 2,
					accessToken: localStorage.getItem("token"),
				};
				dispatch(getCardAds(requestCardProfile));

				const requestBannerProfile = {
					email: userStatus.email,
					adsTypeId: 1,
					accessToken: localStorage.getItem("token"),
				};

				dispatch(getBannerAds(requestBannerProfile));
			} catch (e) {
				console.log(e);
			}
		};

		getCount();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	/* 
	React.useEffect(() => {
		if (cardAdsCount > 0) {
			const requestProfile = {
				acctId: userStatus.acctId,
				acctTypeId: userStatus.acctTypeId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				categoryIds: "",
				subCategoryIds: "",
				lastRecordSize: 0,
				requestRecordSize: cardAdsCount > 0 ? 11 : 12,
				paginationClick: false,
				defaultLoad: true,
				keyword: "",
			};

			dispatch(getArticleList(requestProfile));
		} else if (cardAdsCount === 0) {
			const requestProfile = {
				acctId: userStatus.acctId,
				acctTypeId: userStatus.acctTypeId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				categoryIds: "",
				subCategoryIds: "",
				lastRecordSize: 0,
				requestRecordSize: 12,
				paginationClick: false,
				defaultLoad: true,
				keyword: "",
			};

			dispatch(getArticleList(requestProfile));
		}
	}, [cardAdsCount]); */
	const scrollToTop = () => {
		var timer = setInterval(() => {
			var articleResult = document.getElementById("resourcesPage");
			console.log(articleResult);
			if (articleResult) {
				articleResult.scrollIntoView();
				window.scrollTo(0, 0);
				clearInterval(timer);
			}
		}, 100);
	};

	useEffect(() => {
		if (isSearching) {
			var mainCat = "0,";

			searchTag.forEach((tag) => (mainCat = mainCat + tag.mainCatId + ","));
			mainCat = mainCat.substring(0, mainCat.length - 1);
			var subCat = "0,";
			searchTag.forEach((tag) =>
				tag.subCat.forEach((ele) => (subCat = subCat + ele.subCatId + ","))
			);
			subCat = subCat.substring(0, subCat.length - 1);
			const requestProfile = {
				acctId: userStatus.acctId,
				acctTypeId: bizId > 0 ? 2 : userStatus.acctTypeId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				categoryIds: mainCat,
				subCategoryIds: subCat,
				lastRecordSize: 0,
				requestRecordSize: 12,
				paginationClick: false,
				defaultLoad: false,
				keyword: keyword,
			};
			dispatch(getArticleList(requestProfile));
		}
		scrollToTop();
	}, [searchTag, isSearching, keyword]);

	const addTag = (mainCatId, subCatId) => {
		// console.log("add");
		dispatch(
			searchTagAdd(
				mainCatId,
				subCatId,
				searchTag,
				defaultTag,
				searchFilterTag,
				defaultFilterTag
			)
		);
	};

	const addAll = (mainCatId) => {
		// console.log("add");
		dispatch(
			addAllTag(
				mainCatId,
				searchTag,
				defaultTag,
				searchFilterTag,
				defaultFilterTag
			)
		);
	};

	const deleteTag = (mainCatId, subCatId) => {
		// console.log("remove");
		dispatch(
			searchTagRemove(
				mainCatId,
				subCatId,
				searchTag,
				searchFilterTag,
				defaultFilterTag
			)
		);
	};

	const deleteAll = (mainCatId) => {
		// console.log("remove");
		dispatch(
			removeAllTag(
				mainCatId,
				searchTag,
				defaultTag,
				searchFilterTag,
				defaultFilterTag
			)
		);
	};

	const deleteSearch = (mainCatId, subCatId) => () => {
		// console.log("remove", mainCatId, subCatId);
		dispatch(
			searchTagRemove(
				mainCatId,
				subCatId,
				searchTag,
				searchFilterTag,
				defaultFilterTag
			)
		);
	};

	const searchForKeyword = (keyword) => {
		const requestProfile = {
			keyword: keyword,
		};
		// console.log(requestProfile);
		dispatch(getArticleListByKeyword(requestProfile));
	};

	// const setSearchKeyword = (keyword) => {
	// 	dispatch(setKeyword(keyword));
	// };

	const checkTag = (subCatId) => {
		return searchTag.some((item) => {
			return item.subCat.some((ele) => {
				return ele.subCatId === subCatId;
			});
		});
	};

	const searchCount = (mainCatId) => {
		var tag = searchTag
			.filter((item) => {
				return item.mainCatId === mainCatId;
			})
			.map((item) => {
				return item.subCat.length;
			});
		return tag[0];
	};
	const defaultCount = (mainCatId) => {
		var tag = defaultTag
			.filter((item) => {
				return item.mainCatId === mainCatId;
			})
			.map((item) => {
				return item.subCat.length;
			});
		return tag[0];
	};

	const checkedTagResult = searchFilterTag.map((item) => {
		return (
			<Chip
				key={item.catId}
				label={<p className={classes.createdDescFont}>{item.cat}</p>}
				onDelete={deleteSearch(item.catId, "tag")}
				className={classes.chip + " " + classes.searchChip}
				color={"secondary"}
			/>
		);
	});

	return (
		<React.Fragment>
			{/* {loadingArticleList && <Backdrop />} */}
			<div className={classes.body}>
				<div className={classes.pageHeader}>
					<div className={classes.container}>
						{isLoading && <Backdrop />}
						<GridContainer justifyContent="center">
							<GridItem
								className={classes.searchGrid}
								sm={12}
								md={12}
								lg={12}
								xl={11}
							>
								{/* <h4>Test</h4> */}
								<CommunityBannerAds />
							</GridItem>
							<GridItem xs={12} sm={12} md={4} lg={3} xl={3}>
								<GridContainer
									alignContent="center"
									alignItems="center"
								/* 	className={classes.searchTag} */
								>
									<GridItem>
										<span className={classes.createdDescFont}>
											Filter applied:
										</span>
										{checkedTagResult}
									</GridItem>
								</GridContainer>
								<Card>
									<CardBody
										className={classes.searchCardBody}
										id="searchArticle"
									>
										<div>
											<h3 className={classes.title}>Filter post by:</h3>

											<Card className={classes.cardBorder}>
												<CardBody>
													<GridContainer>
														{defaultTag.map((item, index) => {
															return (
																<GridItem key={item.mainCatId}>
																	<div>
																		<Accordion
																			active={window.innerWidth <= 960 ? 1 : 0}
																			className={classes.accordionStyle}
																			rootClass={classes.accordionTitle}
																			divRootClass={classes.accordionRoot}
																			accordionDetailClass={
																				classes.accordionDetailClass
																			}
																			activeColor="primary"
																			collapses={[
																				{
																					title: (
																						<div>
																							<FormControlLabel
																								classes={{
																									label: classes.label,
																								}}
																								key={item.mainCatId}
																								control={
																									<Checkbox
																										//tabIndex={-1}
																										indeterminate={
																											searchCount(
																												item.mainCatId
																											) > 0 ||
																												searchCount(
																													item.mainCatId
																												) ===
																												defaultCount(
																													item.mainCatId
																												)
																												? defaultCount(
																													item.mainCatId
																												) >
																												searchCount(
																													item.mainCatId
																												) && true
																												: null
																										}
																										name={item.mainCat}
																										onClick={(e) => {
																											e.stopPropagation();
																											if (
																												searchCount(
																													item.mainCatId
																												) ===
																												defaultCount(
																													item.mainCatId
																												)
																											) {
																												deleteAll(
																													item.mainCatId
																												);
																											} else {
																												addAll(item.mainCatId);
																											}
																										}}
																										classes={{
																											checked:
																												classes.opptyColor,
																											root:
																												classes.checkRoot +
																												" " +
																												classes.checkOpptyColor,
																										}}
																										checked={
																											searchCount(
																												item.mainCatId
																											) ===
																											defaultCount(
																												item.mainCatId
																											) && true
																										}
																									/>
																								}
																							// label={<span>Jobseeker</span>}
																							/>
																							<span
																								className={
																									classes.accordionLabel
																								}
																							>
																								{item.mainCat}
																							</span>
																						</div>
																					),
																					content: item.subCat.map(
																						(ele, index) => {
																							return (
																								<div
																									key={
																										item.mainCatId +
																										"-" +
																										ele.subCatId
																									}
																								>
																									<FormControlLabel
																										classes={{
																											label: classes.label,
																										}}
																										key={
																											item.mainCatId +
																											"-" +
																											ele.subCatId
																										}
																										control={
																											<Checkbox
																												//tabIndex={-1}
																												name={item.desc}
																												onClick={() => {
																													if (
																														checkTag(
																															ele.subCatId
																														)
																													) {
																														deleteTag(
																															item.mainCatId,
																															ele.subCatId
																														);
																													} else {
																														addTag(
																															item.mainCatId,
																															ele.subCatId
																														);
																													}
																												}}
																												classes={{
																													checked:
																														classes.opptyColor,
																													root:
																														classes.checkRoot +
																														" " +
																														classes.checkOpptyColor,
																												}}
																												checked={checkTag(
																													ele.subCatId
																												)}
																											/>
																										}
																										label={
																											<span>{ele.subCat}</span>
																										}
																									/>
																								</div>
																							);
																						}
																					),
																				},
																			]}
																		/>
																	</div>
																</GridItem>
															);
														})}
													</GridContainer>
												</CardBody>
											</Card>
										</div>
									</CardBody>
								</Card>
							</GridItem>
							<GridItem xs={12} sm={12} md={8} lg={9} xl={8}>
								<Formik
									initialValues={{
										keyword: "",
									}}
									onSubmit={(data) => {
										searchForKeyword(data.keyword);
									}}
								>
									{({ values, handleChange, handleSubmit, setFieldValue }) => (
										<form onSubmit={handleSubmit} className={classes.cardFrame}>
											<GridContainer
												justifyContent="center"
												alignContent="center"
												alignItems="center"
											>
												<GridItem
													xs={8}
													sm={9}
													md={10}
													className={classes.gridSearchLeft}
												>
													<CustomSearchInput
														labelText="Search Keyword"
														id="keyword"
														name="keyword"
														values={keyword}
														onChange={(event) => {
															setFieldValue("keyword", event.target.value);
														}}
														onClick={handleSubmit}
														fullWidth
														inputProps={{
															autoComplete: "off",
														}}
														close={false}
														className={classes.borderRadius}
													/>
												</GridItem>
												<GridItem
													xs={4}
													sm={3}
													md={2}
													className={classes.gridSearchRight}
												>
													<Button
														onClick={handleSubmit}
														color="oppty"
														round
														fullWidth
													>
														Search
													</Button>
												</GridItem>
											</GridContainer>
										</form>
									)}
								</Formik>
								<div id="articleResult"></div>
								<SearchResultSection />
							</GridItem>
						</GridContainer>
					</div>
				</div>

				{/* {width > 1200 ? (
					<Footer />
				) : (
					<div className={classes.footerNav}>
						<BottomNavBar />
					</div>
				)} */}
				<Footer />
			</div>
		</React.Fragment>
	);
}
