const editProfileStyles = (theme) => ({
	body: {
		height: "100%",
	},

	header: {
		display: "flex",
		justifyContent: "center",
		alignItems: "end",
		marginTop: "20px",
		paddingBottom: "5px",
		color: "#000000",
		fontSize: "16px",
		fontWeight: 700,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,

		textAlign: "center",
	},

	contentContainer: {
		display: "grid",
		gridAutoFlow: "column",
		/* gridAutoColumns: "180px calc(100% - 180px)", */
		backgroundColor: "#F2F3F7",
		height: "calc(100% - 50px)",
		/* "@media (min-width: 1200px)": {
			gridAutoColumns: "180px calc(100% - 180px)",
		}, */
		[theme.breakpoints.up("lg")]: {
			gridAutoColumns: "180px calc(100% - 180px)",
		},
	},

	navBar: {
		backgroundColor: "#fff",
		width: "180px",
	},
	cardBorder: {
		boxShadow: "none!important",
		border: "2px solid #E6E7EC",
		borderRadius: "12px",
		color: "#9a9a9a",
	},
	navItem: {
		"& .MuiTypography-body1": {
			color: "#929293",
			fontSize: "15px",
			fontWeight: 600,
			textAlign: "center",
		},
	},

	navItemActive: {
		"& .MuiTypography-body1": {
			color: "#6F52ED",
		},
	},

	navItemRightBorder: {
		/* 	position: "absolute",
		right: "-2.5px", */
		borderRight: "solid 5px #6F52ED",
		height: "100%",
	},

	main: {
		overflowY: "scroll",
		padding: "15px 25px",
		/* 	width: "calc(100% - 180px)", */
		width: "100%",
		backgroundColor: "transparent",
		height: "100%",
	},
	menuItem: {
		display: "flex",
		/* alignItems: "center" */
		padding: "6px 16px !important",
		cursor: "pointer",
		justifyContent: "flex-start",
	},
});

export default editProfileStyles;
