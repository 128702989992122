import {
	container,
	boxShadow,
	hexToRgb,
	grayColor,
	blackColor,
	opptyDesignColor,
} from "assets/jss/material-kit-pro-react.js";

const searchJobPageStyle = (theme) => ({
	cardFrame: {
		borderRadius: "18px",
		boxShadow: "none",
		width: "auto",
		margin: "10px 10px",
	},
	cardClick: {
		cursor: "pointer",
		"&:hover,&:focus": {
			//backgroundColor: grayColor[0],
			boxShadow:
				"0 14px 26px -12px rgba(" +
				hexToRgb(grayColor[0]) +
				", 0.42), 0 4px 23px 0px rgba(" +
				hexToRgb(blackColor) +
				", 0.12), 0 8px 10px -5px rgba(" +
				hexToRgb(grayColor[0]) +
				", 0.2)",
		},
	},
	body: {
		/* overflowY: "scroll", */
		overflowX: "hidden",
		marginTop: "160px",
		height: "calc(100vh - 160px)",
		[theme.breakpoints.down("md")]: {
			marginTop: "85px",
		},
	},
	container: {
		...container,
		zIndex: "5",
		"@media (min-width: 576px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 768px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 992px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 1200px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 1400px)": {
			maxWidth: "95vw",
		},
	},
	pageHeader: {
		/* position: "relative",
		display: "flex !important",
		alignItems: "center", */
		margin: "0",
		border: "0",
		height: "auto",
		color: "#fff",
	},

	resultContainer: {
		display: "flex",

		/* padding: "20px 10px", */
	},
	imgContainer: {
		display: "flex",

		"& img": {
			width: "48px",
			height: "48px",
			borderRadius: "50%",
			marginTop: "5px",
		},
	},
	descriptionContainer: {
		width: "100%",
		display: "flex",
		marginLeft: "10px",

		"& h4": {
			marginBottom: "0px !important",
			marginTop: "5px !important",
		},
	},
	title: {
		fontWeight: 700,
		fontSize: "16px",
	},
	description: {
		fontWeight: 500,
		fontSize: "14px",
		color: "rgba(0,0,0,0.6)",
	},
	mainContainer: {
		backgroundColor: "#f2f3f7",
		padding: "5px 10px",
		margin: "10px 0px",
		borderRadius: "18px",
	},
	cardBodyPadding: {
		padding: "10px",
	},
	componentTitle: {
		fontWeight: 700,
		fontSize: "20px",
		marginLeft: "15px",
		marginTop: "5px",
	},
	buttonViewMore: {
		color: opptyDesignColor,
		textTransform: "none",
	},
});

export default searchJobPageStyle;
