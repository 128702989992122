import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import landingPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/LandingPage/landingPageStyle.js";
import appleLogo from "assets/img/job_logo/apple_logo_black.png";
import Carousel from "react-slick";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles(landingPageStyle);
export default function PartnerSections() {
	const classes = useStyles();
	const cardAds = useSelector((state) => state.community.cardAds);
	const partnerCarouselSettings = {
		dots: true,
		infinite: true,
		speed: 1000,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
	};
	return (
		<div className={classes.sectionDark}>
			<GridContainer justifyContent="center">
				<GridItem xs={12} sm={8} md={8}></GridItem>
				<GridItem xs={12} sm={12} md={3} className={classes.marginAuto}>
					<Card plain>
						<Carousel {...partnerCarouselSettings}>
							<div>
								<div className="slick-image">
									<img
										className={classes.logoImage}
										src={cardAds[1].adsMedia}
										style={{ width: "250px", height: "250px" }}
										alt="..."
									/>
								</div>
							</div>
							<div>
								<div className="slick-image">
									<img
										className={classes.logoImage}
										src={cardAds[1].adsMedia}
										alt="..."
										style={{ width: "250px", height: "250px" }}
									/>
								</div>
							</div>
							{/* <div>
								<div className="slick-image">
									<img
										className={classes.logoImage}
										src={cardAds[0].adsMedia}
										alt="..."
									/>
								</div>
							</div> */}
							{/* 	<div>
								<div className="slick-image">
									<Card plain className={classes.cardHeight}>
										<CardAvatar profile className={classes.avatarPadding}>
											<img
												className={classes.logoImage}
												src={appleLogo}
												alt="..."
											/>
										</CardAvatar>
										<CardBody>
											<h6 className={classes.cardTitle}>Apple</h6>
										</CardBody>
									</Card>
								</div>
							</div>
							<div>
								<div className="slick-image">
									<Card plain className={classes.cardHeight}>
										<CardAvatar profile className={classes.avatarPadding}>
											<img
												className={classes.logoImage}
												src={appleLogo}
												alt="..."
											/>
										</CardAvatar>
										<CardBody>
											<h6 className={classes.cardTitle}>Apple</h6>
										</CardBody>
									</Card>
								</div>
							</div> */}
						</Carousel>
					</Card>
				</GridItem>
			</GridContainer>
		</div>
	);
}
