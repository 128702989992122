import {
	container,
	cardTitle,
	title,
	mlAuto,
	mrAuto,
	main,
	mainRaised,
	grayColor,
	blackColor,
	// cardDetail,
	hexToRgb,
	opptyDesignColor,
} from "assets/jss/material-kit-pro-react.js";
import customSelectStyle from "assets/jss/material-kit-pro-react/customSelectStyle.js";
import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js";
import customCheckboxRadioSwitchStyle from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.js";
import headersSection from "assets/jss/material-kit-pro-react/components/headerStyle.js";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.js";
import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";
import hashTagStyle from "../../componentStyle/hashTagStyle.js";
import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.js";

const searchJobPageStyle = (theme) => ({
	...headersSection(theme),
	...modalStyle(theme),
	...styles,
	...hashTagStyle,
	...imagesStyles,
	...customSelectStyle,
	...tooltipsStyle,

	main,
	mainRaised,
	mlAuto,
	mrAuto,

	cardTitle,
	...styles,
	...customCheckboxRadioSwitchStyle,
	...customSelectStyle,
	textCenter: {
		textAlign: "center",
	},

	avatarPadding: {
		marginTop: "10px",
	},
	parallax: {
		height: "20vh",
		backgroundPosition: "top center",
	},

	container: {
		...container,
		"@media (min-width: 576px)": {
			maxWidth: "100%",
			// maxWidth: "540px",
		},
		"@media (min-width: 768px)": {
			maxWidth: "100%",
			// maxWidth: "750px",
		},
		"@media (min-width: 992px)": {
			maxWidth: "100%",
			// maxWidth: "960px",
		},
		"@media (min-width: 1200px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 1400px)": {
			maxWidth: "90vw",
		},
	},
	textRight: {
		[theme.breakpoints.up("md")]: {
			textAlign: "right",
		},
	},
	description: {
		margin: "15px 0 0 0  !important",
		/* maxWidth: "600px", */
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		color: blackColor,
		fontWeight: 500,
		whiteSpace: "pre-line",
		"& p": {
			margin: "15px 0px",
		},
		[theme.breakpoints.down("sm")]: {
			fontSize: "15px !important",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "11px !important",
		},
	},
	title: {
		...title,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		position: "relative",
		// marginTop: "25px",
		margin: "5px 0 !important",
		minHeight: "0px",
		textDecoration: "none",
		verticalAlign: "middle",
		color: blackColor + "!important",
		fontSize: "16px",
		"& svg": {
			width: "32px",
			height: "32px",
			marginRight: "20px",
			marginTop: "10px",
		},
		[theme.breakpoints.down("xs")]: {
			marginTop: "0!important",
			marginBottom: "5px!important",
		},
	},
	jobTitle: {
		textDecoration: "none",
		color: blackColor + "!important",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		fontWeight: "700",
		fontSize: "16px",
		margin: "auto 0 !important",
		//minHeight: "60px",
		[theme.breakpoints.down("xs")]: {
			fontSize: "14px",
			minHeight: "14px",
		},
	},
	jobCardTitle: {
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap",
	},
	companyName: {
		textDecoration: "none",
		color: opptyDesignColor + "!important",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		fontWeight: "600",
		fontSize: "16px",
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap",
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
			margin: "0",
		},
	},
	jobDescription: {
		textDecoration: "none",
		//marginTop: "30px !important",
		color: grayColor[0] + "!important",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		fontWeight: "400",
		fontSize: "16px",
		textAlign: "left",
		//padding: "0 !important",
		"& svg": {
			color: grayColor[0],
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
		},
	},
	modalJobTitle: {
		color: blackColor + "!important",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		fontWeight: "600",
		fontSize: "15px",
		whiteSpace: "pre-line",
		margin: "35px 0 0 0!important",
		[theme.breakpoints.down("xs")]: {
			fontSize: "14px",
			margin: "25px 0 0 0!important",
		},
	},
	modalJobDescription: {
		color: blackColor + "!important",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		fontWeight: "400",
		fontSize: "14px",
		whiteSpace: "pre-line",
		margin: "0",
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
		},
	},
	titleDiv: {
		// minHeight: "60px",
		paddingTop: "25px",
		display: "flex",
		verticalAlign: "middle",
		justifyContent: "center",
		alignItems: "center",
		[theme.breakpoints.down("xs")]: {
			paddingTop: "10px",
		},
	},
	companyNameDiv: {
		// minHeight: "58px",
		/* 	display: "flex", */
	},
	iconWrapper: {
		float: "left",
		width: "36px",
		height: "36px",
		color: grayColor[0] + "!important",
		marginTop: "10px",
		marginRight: "10px",
	},
	hotjobIconWrapper: {
		width: "36px",
		height: "36px",
		marginTop: "10px",
		marginRight: "10px",
		top: 0,
		right: 0,
		position: "absolute",
		color: opptyDesignColor,
	},
	infoArea: {
		padding: "10px 0 5px 0",
	},
	mainRaisedGrid: {
		margin: "25px",
		boxShadow: "",
	},
	searchBarButton: {
		marginTop: "10px !important",
		/* marginBottom: "10px !important", */
		[theme.breakpoints.down("xs")]: {
			marginTop: "0!important",
			marginBottom: "0!important",
		},
	},
	cardImage: {
		width: "60px",
		height: "60px",
		overflow: "hidden",
		padding: "0",
		/* borderRadius: "50%", */
		/* boxShadow:
			"0 16px 38px -12px rgba(" +
			hexToRgb(blackColor) +
			", 0.56), 0 4px 25px 0px rgba(" +
			hexToRgb(blackColor) +
			", 0.12), 0 8px 10px -5px rgba(" +
			hexToRgb(blackColor) +
			", 0.2)", */
		/* "&$cardAvatarPlain": {
			marginTop: "0",
		}, */
		/* margin: "-50px auto 0", */
	},
	cardClick: {
		cursor: "pointer",
		"&:hover,&:focus": {
			//backgroundColor: grayColor[0],
			boxShadow:
				"0 14px 26px -12px rgba(" +
				hexToRgb(grayColor[0]) +
				", 0.42), 0 4px 23px 0px rgba(" +
				hexToRgb(blackColor) +
				", 0.12), 0 8px 10px -5px rgba(" +
				hexToRgb(grayColor[0]) +
				", 0.2)",
		},
	},
	cardCategory: {
		color: blackColor,
		textDecoration: "none",
	},
	cardHeight: {
		height: "auto",
		[theme.breakpoints.down("md")]: {
			height: "auto",
		},
	},
	cardViewCount: {
		marginTop: "20px",
	},
	cardFooterBorder: {
		borderTop: "2px solid rgba(" + hexToRgb(grayColor[0]) + ", 0.35)",
	},
	cardResultSection: {
		paddingTop: "15px",
		paddingBottom: "15px",
		//overflowY: "scroll",
	},
	profileResult: {
		minHeight: "420px",
		height: "750px",
		overflowY: "scroll",
	},
	endMessage: {
		textAlign: "center",
	},
	cardBodyPadding: {
		paddingRight: "10px",
		paddingLeft: "10px",
	},
	hashtagDescription: {
		margin: "15px 0px !important",
		/* maxWidth: "600px", */
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		color: blackColor[0],
		fontWeight: "400",
		whiteSpace: "pre-line",
		"& p": {
			margin: "15px 0px",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
		},
	},
	modalJobView: {
		maxWidth: "900px",
		width: "100%",
		borderRadius: "18px",
		backgroundColor: "#f2f3f7",
		"& $modalHeader": {
			paddingTop: "0",
		},
		"& $modalTitle": {
			textAlign: "center",
			width: "100%",
			marginTop: "0.625rem",
		},
		"& $modalBody": {
			// padding: "25px",
			paddingBottom: "0",
			paddingTop: "0",
		},
		"& $modalFooter": {
			marginBottom: "1.125rem",
		},
	},
	modalProfileView: {
		maxWidth: "900px",
		width: "100%",
	},
	companyModalLogo: {
		maxWidth: "70px",
		maxHeight: "70px",
		height: "70px",
		width: "70px",
		boxShadow: "none",
		[theme.breakpoints.down("xs")]: {
			height: "60px",
			width: "60px",
		},
	},
	profilePictureModal: {
		maxWidth: "90px", //"160px",
		maxHeight: "90px", //"160px",
		height: "100%",
		width: "100%",
	},
	paperClass: {
		borderRight: "1px solid rgba(" + hexToRgb(blackColor) + ", 0.35)",
		[theme.breakpoints.down("md")]: {
			borderRight: "0",
		},
	},
	gridItemMargin: {
		marginTop: "10px",
		marginBottom: "10px",
	},
	gridItemPadding: {
		paddingLeft: "15px",
		paddingRight: "15px",
	},
	itemCenter: {
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
	},
	itemCenterLg: {
		justifyContent: "center",
		display: "flex",
		[theme.breakpoints.down("md")]: {
			// alignItems: "center",
			paddingRight: "0px",
			paddingLeft: "30px",
		},
		[theme.breakpoints.down("xs")]: {
			paddingRight: "0px",
			paddingLeft: "10px",
		},
	},
	textInline: {
		display: "inline-block",
	},
	timeInfo: {
		marginLeft: "15px !important",
		color: grayColor[1],
		fontStyle: "italic",
		[theme.breakpoints.down("xs")]: {
			marginLeft: "0px !important",
		},
	},
	cardMargin: {
		margin: "5px !important",
	},

	cardDetail: {
		whiteSpace: "pre-line",
	},
	cardInfo: {
		backgroundColor: "#F2F3F7",
		borderRadius: "12px",
		boxShadow: "none",
		[theme.breakpoints.up("xs")]: {
			marginTop: "0",
			marginBottom: "15px",
		},
		[theme.breakpoints.down("xs")]: {
			marginTop: "5px",
			marginBottom: "0",
		},
	},
	info: {
		paddingBottom: 0,
		paddingTop: 0,
		margin: "0",
		maxWidth: "1000px !important",
	},
	autoCompleteSelect: {
		marginTop: "15px !important",
		marginBottom: "5px !important",
		[theme.breakpoints.down("sm")]: {
			marginTop: "0 !important",
			marginBottom: "15px !important",
		},
	},
	privacyTrueCard: {
		minHeight: "150px",
		"& h5": {
			marginTop: "50px !important",
			textAlign: "center",
		},
	},
	privacyTitle: {
		"& h5": {
			margin: "0 !important",
			padding: "0 4vw",
			color: "#58586A!important",
			fontWeight: "500",
			fontSize: "16px",
			textAlign: "center",
			marginBottom: "15px!important",
			[theme.breakpoints.down("xs")]: {
				fontSize: "13px",
			},
		},
	},
	experienceText: {
		whiteSpace: "pre-line",
	},
	jobCardGrid: {
		marginLeft: "-10px",
		marginRight: "-10px",
	},
	cardPadding: {
		paddingRight: "5px",
		paddingLeft: "5px",
	},
	gridPadding: {
		paddingRight: "10px",
		paddingLeft: "10px",
	},
	searchBarHeight: {
		maxHeight: "auto",
		overflowY: "auto",
		overflowX: "hidden",
	},
	cardFooterPadding: {
		padding: "0px",
	},
	footerHeight: {
		maxHeight: "70vh",
		overflowY: "auto",
		overflowX: "auto",
	},

	searchTab: {
		position: "fixed",
		top: "90px",
		borderRadius: "18px",
		width: "20%",
		height: "auto",
		[theme.breakpoints.down("md")]: {
			position: "relative",
			width: "100%",
			// height: "auto",
		},
		// [theme.breakpoints.down("sm")]: {
		// 	height: "auto",
		// },
	},
	applySnackBar: {
		margin: "0px !important",
	},
	snackbarMobile: {
		"& div": {
			[theme.breakpoints.down("xs")]: {
				fontSize: "12px",
				maxWidth: "100vw",
			},
		},
		"& svg": {
			[theme.breakpoints.down("xs")]: {
				fontSize: "18px",
			},
		},
	},
	searchCard: {
		position: "sticky",
		top: "125px",
		marginTop: "120px",
		borderRadius: "18px",
		boxShadow: "none",
		paddingTop: "15px",
		paddingBottom: "15px",
		[theme.breakpoints.down("sm")]: {
			marginBottom: "0",
			paddingBottom: "0",
			paddingTop: "0",
		},
	},
	searchCardBody: {
		/* maxHeight: "85vh", */

		[theme.breakpoints.down("sm")]: {
			paddingTop: "0",
			paddingBottom: "0",
		},
		padding: "0px 15px",
	},
	searchCardDiv: {
		// overflowX: "hidden",
	},
	searchGrid: {
		marginTop: "95px",
		[theme.breakpoints.down("sm")]: {
			marginTop: "0",
		},
	},
	searchItem: {
		backgroundColor: "#223322",
	},
	bodyDiv: {
		paddingBottom: "60px",
	},
	loadingContainer: {
		padding: "30px 0px",
		alignItems: "center",
		display: "flex",
		justifyContent: "center",
	},
	loadingImage: {
		width: "100%",
		height: "100%",
		borderRadius: "12px",
	},
	cardFrame: {
		borderRadius: "18px",
		boxShadow: "none",
		marginBottom: "0",
		[theme.breakpoints.down("xs")]: {
			marginTop: "10px",
		},
	},
	profileModalButton: {
		[theme.breakpoints.down("xs")]: {
			display: "none",
		},
	},
	largeProfileModalButton: {
		[theme.breakpoints.up("sm")]: {
			display: "none",
		},
		justifyContent: "center",
	},
	gridHeight: {
		height: "90px",
	},
	applicationStatus: {
		// color: "#54c154 !important",
		// textTransform: "uppercase",
		// fontSize: "14px",
		// fontWeight: "500 !important",
		// whiteSpace: "nowrap",
	},
	statusSubmitted: {
		textTransform: "uppercase",
		fontSize: "14px",
		fontWeight: "500 !important",
		whiteSpace: "nowrap",
		textAlign: "right",
		color: "#1AD598",
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
		},
	},
	statusReviewing: {
		textTransform: "uppercase",
		fontSize: "14px",
		fontWeight: "500 !important",
		whiteSpace: "nowrap",
		textAlign: "right",
		color: "#FFAE00",
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
		},
	},
	statusRejected: {
		textTransform: "uppercase",
		fontSize: "14px",
		fontWeight: "500 !important",
		whiteSpace: "nowrap",
		textAlign: "right",
		color: "#F6777B",
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
		},
	},
	statusUnsuccessful: {
		textTransform: "uppercase",
		fontSize: "14px",
		fontWeight: "500 !important",
		whiteSpace: "nowrap",
		textAlign: "right",
		color: blackColor[0],
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
		},
	},
	statusWithdraw: {
		textTransform: "uppercase",
		fontSize: "14px",
		fontWeight: "500 !important",
		whiteSpace: "nowrap",
		textAlign: "right",
		color: "#687bfe",
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
		},
	},
	statusShortlisted: {
		textTransform: "uppercase",
		fontSize: "14px",
		fontWeight: "500 !important",
		whiteSpace: "nowrap",
		textAlign: "right",
		color: "#D277F6",
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
		},
	},
	autoHeightBody: {
		height: "auto",
		backgroundColor: "#F2F3F7",
		overflowY: "auto",
	},
	modalCardBorder: {
		/* borderRadius: "18px", */
		boxShadow: "none",
		padding: "10px 0px 10px 0px",
		marginBottom: "0px",
	},
	modalBackground: {
		background: "#fff",
		transition: "height 0.5s ease",
		// overflowY: "hidden",
		height: "calc(100vh - 300px)",
	},
	modalBackgroundRadius: {
		borderRadius: "0px",
		transition: "height 0.5s ease",
		overflowY: "scroll",
	},
	titleDescription: {
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		color: blackColor[0],
		fontSize: "16px",
		fontWeight: "700",
		margin: "0px !important",
		[theme.breakpoints.down("xs")]: {
			fontSize: "15px",
		},
	},
	smallDescription: {
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		color: blackColor[0],
		fontSize: "12px",
		fontWeight: "400",
		margin: "0px !important",
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
		},
	},
	mediumDescription: {
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		color: blackColor[0],
		fontSize: "14px",
		fontWeight: "500",
		margin: "0px !important",
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
			lineHeight: "1rem",
		},
	},
	descriptionContainer: {
		marginTop: "5px",
		marginBottom: "10px",
		"& p": {
			margin: "-5px 0px 0px 0px !important",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
			marginBottom: "0",
			"& p": {
				paddingBottom: "5px",
			},
		},
	},
	grayFont: {
		color: "#99B2C6 !important",
	},
	grayFontCard: {
		color: "#9F9FB7 !important",
	},
	opptyColorFont: {
		color: opptyDesignColor,
	},
	iconFontSize: {
		fontSize: "14px!important",
		color: "#959595!important",
		[theme.breakpoints.down("xs")]: {
			fontSize: "11px !important",
			padding: "0 5px!important",
		},
	},
	iconLibrary: {
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap",
		fontSize: "16px",
		padding: "5px 0px",
		"& p": { marginLeft: "5px" },
		"& svg": { width: "1.25em!important" },
	},
	titleAlign: {
		textAlign: "center",
	},
	cardBodyMobile: {
		padding: "5px 7px !important",
		overflowX: "hidden",
	},
	descFontLimit: {
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap",
	},
	verticalAligned: {
		alignItems: "center",
		textAlign: "left",
	},
	textAligned: {
		alignItems: "center",
		textAlign: "left",
		[theme.breakpoints.down("sm")]: {
			textAlign: "center",
		},
	},
	titleInfo: {
		minHeight: "1px",
		margin: "0!important",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		overflow: "hidden",
		[theme.breakpoints.down("sm")]: {
			fontSize: "15px",
			minHeight: "15px",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "13px",
			minHeight: "13px",
		},
	},
	titleLocation: {
		minHeight: "26px",
		margin: "0!important",
		color: "#959595!important",
		fontWeight: "normal",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		overflow: "hidden",
		[theme.breakpoints.down("sm")]: {
			fontSize: "14px",
			minHeight: "18px",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
			minHeight: "18px",
		},
	},
	endTitle: {
		margin: "35px 0px !important",
	},
	descInfo: {
		fontSize: "14px",
		minHeight: "19px",
		margin: "0!important",
		// marginTop: "10px!important",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		overflow: "hidden",
		[theme.breakpoints.down("lg")]: {
			fontSize: "14px",
			minHeight: "18px",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
			minHeight: "18px",
		},
	},
	descInfoMobile: {
		[theme.breakpoints.down("xs")]: {
			padding: "10px 0 0 0",
			overflow: "visible",
			whiteSpace: "normal",
		},
	},
	paginationAligned: {
		float: "right",
	},
	jobCardDescription: {
		"& svg": {
			color: "#959595",
			marginRight: "5px",
		},
	},
	viewCountDescription: {
		"& svg": {
			color: "#99b2c6",
			marginRight: "5px",
		},
		fontSize: "18px",
		color: "#99b2c6",
	},
	dividerContainer: {
		margin: "15px 0px",
		[theme.breakpoints.down("xs")]: {
			margin: "5px 0px",
		},
	},
	loadingHeight: {
		height: "100px",
	},
	cardBody: {
		[theme.breakpoints.down("xs")]: {
			padding: "10px",
		},
	},
	buttonModal: {
		[theme.breakpoints.down("sm")]: {
			fontSize: "11px",
			padding: "12px 10px",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "10px",
			padding: "10px 8px",
		},
	},
	buttonApply: {
		[theme.breakpoints.down("xs")]: {
			fontSize: "10px",
		},
	},
	descriptionMargin: {
		[theme.breakpoints.down("xs")]: {
			margin: "0!important",
		},
	},
	gridCardPadding: {
		paddingBottom: "0",
		paddingLeft: "15px",
		paddingRight: "15px",
	},
	accordionTitle: {
		// padding: "0",
		borderBottom: "0",
	},
	accordionLabel: {
		cursor: "pointer",
		display: "inline-flex",
		alignItems: "center",
		marginLeft: "-16px",
		// marginRight: "16px",
		verticalAlign: "middle",
		WebkitTapHighlightColor: "transparent",
		color: "#AAAAAA",
		fontWeight: "400",
	},
	accordionGrid: {
		width: "100%",
		paddingLeft: "24px",
	},
	footerNav: {
		position: "fixed",
		left: 0,
		bottom: 0,
		width: "100%",
		textAlign: "center",
		alignItems: "center",
		margin: 0,
		border: "2px solid #D0DDE7",
	},
});

export default searchJobPageStyle;
