import {
  container,
  title,
  whiteColor,
  mainRaised,
  blackColor,
  main,
  hexToRgb,
  grayColor,
  mrAuto,
  mlAuto,
  // opptyDesignColor,
} from 'assets/jss/material-kit-pro-react.js';
import modalStyle from 'assets/jss/material-kit-pro-react/modalStyle.js';

const opptyRoadMapPageStyle = theme => ({
  ...modalStyle(theme),
  parallax: {
    height: '600px',
  },
  main: {
    ...main,
  },
  mrAuto: {
    ...mrAuto,
  },
  mlAuto: {
    ...mlAuto,
  },
  mainRaised: {
    ...mainRaised,
  },

  title: {
    ...title,
    fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
    position: 'relative',
    // marginTop: "25px",
    margin: '5px 0 !important',
    minHeight: '0px',
    textDecoration: 'none',
    color: blackColor + '!important',
    fontSize: '16px',
    '& svg': {
      width: '32px',
      height: '32px',
      marginRight: '20px',
      marginTop: '10px',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '0!important',
      marginBottom: '5px!important',
      fontSize: '14px',
    },
  },
  blackTitle: {
    ...title,
    display: 'inline-block',
    position: 'relative',
    marginTop: '30px',
    minHeight: '32px',
    color: blackColor,
    textDecoration: 'none',
  },
  textCenter: {
    textAlign: 'center',
    '& h4': {
      ...title,
    },
  },
  whiteTitle: {
    ...title,
    display: 'inline-block',
    position: 'relative',
    marginTop: '30px',
    minHeight: '32px',
    color: whiteColor,
    textDecoration: 'none',
  },
  inheritTitle: {
    ...title,
    color: 'inherit',
  },
  description: {
    color: blackColor,
  },
  accordionStyle: {
    '& title': {
      color: whiteColor,
    },
  },
  selectPaddingDiv: {
    paddingTop: '25px !important',
    paddingBottom: '25px !important',
  },
  careerCardMargin: {
    marginTop: '50px !important',
    alignItems: 'center',
    justifyContent: 'center',
    height: '75px',
    cursor: 'pointer',
    '&:hover,&:focus': {
      //color: whiteColor,
      //backgroundColor: grayColor[0],
      boxShadow:
        '0 14px 26px -12px rgba(' +
        hexToRgb(grayColor[0]) +
        ', 0.42), 0 4px 23px 0px rgba(' +
        hexToRgb(blackColor) +
        ', 0.12), 0 8px 10px -5px rgba(' +
        hexToRgb(grayColor[0]) +
        ', 0.2)',
    },
  },
  fixedBottomCard: {
    position: 'absolute',
    bottom: '0',
  },
  smallCardSize: {
    width: '200px',
    height: '150px',
  },
  searchBarContainer: {
    marginRight: 'auto',
    marginLeft: 'auto',

    '@media (min-width: 576px)': {
      maxWidth: '540px',
    },
    '@media (min-width: 768px)': {
      maxWidth: '720px',
    },
    '@media (min-width: 992px)': {
      maxWidth: '960px',
    },
    '@media (min-width: 1200px)': {
      maxWidth: '1140px',
    },
  },
  roadmapContainer: {
    paddingRight: '15px',
    paddingLeft: '15px',
  },
  buttonPadding: {
    paddingTop: '10px',
  },
  cardClick: {
    cursor: 'pointer',
    '&:hover,&:focus': {
      //color: whiteColor,
      //backgroundColor: grayColor[0],
      boxShadow:
        '0 14px 26px -12px rgba(' +
        hexToRgb(grayColor[0]) +
        ', 0.42), 0 4px 23px 0px rgba(' +
        hexToRgb(blackColor) +
        ', 0.12), 0 8px 10px -5px rgba(' +
        hexToRgb(grayColor[0]) +
        ', 0.2)',
    },
  },
  appbar: {
    color: grayColor[15],
    width: '100%',
    backgroundColor: whiteColor,
    zIndex: theme.zIndex.drawer + 1,
  },
  roleModal: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '500px',
      margin: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '700px',
      margin: 'auto',
    },
    borderRadius: '6px',

    width: '100%',
  },
  roleModalPaper: {
    maxWidth: '900px',
    overflowY: 'visible',
    width: '100%',
    '& $modalCloseButton': {
      color: whiteColor,
      top: '-10px',
      right: '10px',
      textShadow: 'none',
      position: 'relative',
    },
    '& $modalHeader': {
      borderBottom: 'none',
      paddingTop: '24px',
      paddingRight: '24px',
      paddingBottom: '0',
      paddingLeft: '24px',
    },
    '& $modalBody': {
      paddingBottom: '0',
      paddingTop: '0',
    },
    '& $modalFooter': {
      paddingBottom: '0',
      paddingTop: '0',
    },
  },
  modalRoadMapShow: {
    maxWidth: '1000px',
    width: '100%',
    '& $modalHeader': {
      paddingTop: '0',
    },
    '& $modalTitle': {
      textAlign: 'center',
      width: '100%',
      marginTop: '0.625rem',
    },
    '& $modalBody': {
      paddingBottom: '0',
      paddingTop: '0',
    },
  },
  modalFooterPadding: {
    paddingBottom: '20px',
    borderRadius: '0 0 18px 18px',
  },
  cardNoShadow: {
    boxShadow: 'none',
    borderRadius: '18px',
    [theme.breakpoints.down('xs')]: {
      margin: '10px 0',
    },
  },
  gridHeight: {
    height: '90px',
  },
  container: {
    ...container,
    zIndex: '5',
    '@media (min-width: 576px)': {
      maxWidth: '540px',
    },
    '@media (min-width: 768px)': {
      maxWidth: '100%',
    },
    '@media (min-width: 992px)': {
      maxWidth: '960px',
    },
    '@media (min-width: 1200px)': {
      maxWidth: '1200px',
    },
    /* [theme.breakpoints.down("sm")]: {
			paddingBottom: "100px",
		}, */
  },
  roadmapCard: {
    borderRadius: '18px',
    overflowX: 'hidden',
    boxShadow: 'none',
  },
  roadmapGrid: {
    zIndex: '1',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      // marginTop: "70px",
    },
  },

  body: {
    overflow: 'scroll',
  },

  pageHeader: {
    position: 'relative',
    display: 'flex !important',
    alignItems: 'center',
    margin: '0',
    border: '0',
    // minHeight: '100vh',
    height: 'auto',
    color: whiteColor,

    '&:before,&:after': {
      position: 'absolute',
      zIndex: '1',
      width: '100%',
      height: '100%',
      display: 'block',
      left: '0',
      top: '0',
      content: '""',
    },
  },

  infoArea: {
    /* [theme.breakpoints.down("sm")]: {
			display: "none",
		}, */
  },
  infoTitle: {
    color: blackColor,
    fontFamily: `"Roboto Medium", "Helvetica", "Arial", sans-serif`,
    fontSize: '45px',
    fontWeight: 'bold',
    // color: opptyDesignColor,
    // fontWeight: "bolder",
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      fontSize: '30px',
    },
  },
  infoDescription: {
    color: '#959595',
    fontFamily: `"Roboto Bold", "Helvetica", "Arial", sans-serif`,
    fontSize: '20px',
    // color: grayColor[0],
    // fontWeight: 500,
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      fontSize: '15px',
    },
  },
  infoImage: {
    width: '100%',
    height: 'inherit',

    padding: '0px 50px !important',
  },
  autoCompleteSelect: {
    marginTop: '15px !important',
    marginBottom: '5px !important',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0 !important',
      marginBottom: '15px !important',
    },
  },
  gridMargin: {
    marginTop: '-40px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '0px',
    },
  },
  textAligned: {
    textAlign: 'center',
  },
  careerRoleName: {
    margin: '25px 0px 0px 0px !important',
    fontSize: '25px !important',
    color: '#585B77 !important',
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px !important',
    },
  },
  roleDescription: {
    fontWeight: '500',
    whiteSpace: 'pre-line',
  },
  footerNav: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
    textAlign: 'center',
    alignItems: 'center',
    margin: 0,
    border: '2px solid #D0DDE7',
  },
});
export default opptyRoadMapPageStyle;
