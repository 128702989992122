import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function FontIcon({ icon, text, color, path, className }) {
	return (
		<div
			style={{
				textOverflow: "ellipsis",
				overflow: "hidden",
				whiteSpace: "nowrap",
				fontSize: "16px",
				padding: "5px 0px",
				"& p": { marginLeft: "5px" },
			}}
			className={className}
		>
			<FontAwesomeIcon icon={icon} fixedWidth color={color} /> {text}
		</div>
	);
}
