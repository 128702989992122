/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";

import {
	Card,
	CardContent,
	Typography,
	Button,
	Input,
	IconButton,
	makeStyles,
	Popover,
	ClickAwayListener,
	Grow,
	Paper,
	Popper,
} from "@material-ui/core";

import { ReactComponent as HandClapIcon } from "assets/svg/clapping.svg";
import { ReactComponent as SendIcon } from "assets/svg/send-svgrepo-com.svg";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import MoodBadOutlinedIcon from "@mui/icons-material/MoodBadOutlined";
import SentimentVerySatisfiedOutlinedIcon from "@mui/icons-material/SentimentVerySatisfiedOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import cross from "assets/img/cross.png";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";

import dotBtn from "assets/images/dotBtn.png";
import candidate_avatar from "../../../assets/img/candidate_avatar.jpeg";

import commentStyle from "../../../assets/jss/material-kit-pro-react/views/componentStyle/socialFeed/commentStyles";
import profileViewActivityCardStyles from "assets/jss/material-kit-pro-react/views/componentStyle/profileView/myActivity/profileViewActivityCardStyles.js";
import { useSelector, useDispatch } from "react-redux";
import ReportModal from "../ReportModal.js";
import { TimeFormat } from "configurations/configurations.js";
import {
	createSocialComment,
	updateSocialComment,
	getSocialCommentByPostId,
	getSocialPostByPostId,
	createCommentReaction,
	deleteCommentReaction,
	deleteSocialComment,
	resetDeleteCommentId,
	getSocialHashtag,
	getSocialProfileTag,
	createSocialHashtag,
	resetCreatedHashtag,
	resetComment,
	getSocialReplyByCommentId,
	getCommentReactionCount,
} from "configurations/redux/socialFeedSlice.js";
import { createReport, resetReport } from "configurations/redux/reportSlice.js";
import CommentSkeleton from "../SocialFeed/CommentSkeleton.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Picker from "emoji-picker-react";
import { isMobile } from "react-device-detect";
import CommentImageModal from "./CommentImageModal.js";
import {
	dataURLtoFile,
	TimeFormatUniqueId,
	encryptData,
	getSocialReaction,
} from "configurations/configurations.js";
import { Link } from "react-router-dom";
import "quill-mention";
import "react-quill/dist/quill.bubble.css";
import ReactQuill, { Quill } from "react-quill";
import CommentInput from "./CommentInput.js";
import ReactionUserListModal from "./ReactionUserListModal";
import CommentMediaModal from "./CommentMediaModal";

const PublicComment = ({
	comment,
	postId,
	profilePic,
	commentCount,
	reportReasonsList,
	sectionCount,
	isCopyPost,
	signUpDialog,
}) => {
	const quillRef = React.useRef(null);
	const editQuillRef = React.useRef(null);
	const classes = makeStyles(commentStyle)();
	const styles = makeStyles(profileViewActivityCardStyles)();
	const user = useSelector((state) => state.auth.user);
	const dispatch = useDispatch();
	const anchorRef = React.useRef(null);
	const [reportSuccessResponse, setReportSuccessResponse] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const [anchorElEmoji, setAnchorElEmoji] = useState(null);
	const [replyList, setReplyList] = useState([]);
	const [reportModal, setReportModal] = useState(false);
	const [replyClicked, setReplyClicked] = useState(false);
	const [reply, setReply] = React.useState("");
	const [skeletonShow, setSkeletonShow] = React.useState(false);
	const [showReactionPaper, setShowReactionPaper] = useState(false);
	const searchResult = useSelector((state) => state.socialFeed.searchResult);
	const postResult = useSelector((state) => state.socialFeed.postResult);
	const isOnAmend = useSelector((state) => state.socialFeed.isOnAmendReply);
	const isCreating = useSelector((state) => state.socialFeed.isCreatingReply);
	const amendPostId = useSelector((state) => state.socialFeed.amendPostId);
	const amendCommentId = useSelector(
		(state) => state.socialFeed.amendCommentId
	);
	const likeId = useSelector((state) => state.socialFeed.commentLikeId);
	const [likeColor, setLikeColor] = React.useState("#92929d");
	const [reactionCount, setReactionCount] = React.useState(null);
	const [likeShow, setLikeShow] = React.useState(
		comment.ownerLike ? comment.ownerLike.commentReactionUserList.length : 0
	);
	const [reactionId, setReactionId] = React.useState(
		comment.ownerLike
			? comment.ownerLike.commentReactionUserList.length > 0
				? comment.ownerLike.commentReactionUserList[0].reactionId
				: null
			: null
	);
	let fileInput = React.createRef();
	const [commentImageModal, setCommentImageModal] = React.useState(false);
	const [commentImage, setCommentImage] = React.useState(null);
	const [commentImageSaved, setCommentImageSaved] = React.useState(null);
	const [commentImageIsEdit, setCommentImageIsEdit] = React.useState(false);
	const [fileName, setFileName] = React.useState(null);
	const isDeletedComment = useSelector(
		(state) => state.socialFeed.isDeletedComment
	);
	const deletedCommentId = useSelector(
		(state) => state.socialFeed.deletedCommentId
	);
	const [editCommentShow, setEditCommentShow] = React.useState(null);
	const isLoadingReport = useSelector((state) => state.report.isLoading);
	const isCreatedReport = useSelector((state) => state.report.isCreated);
	const report = useSelector((state) => state.report.report);
	const hashtagList = useSelector((state) => state.socialFeed.hashtagList);
	const profileTagList = useSelector(
		(state) => state.socialFeed.profileTagList
	);
	const hashtagKeyword = useSelector(
		(state) => state.socialFeed.hashtagKeyword
	);
	const profileTagKeyword = useSelector(
		(state) => state.socialFeed.profileTagKeyword
	);
	const hashValue = useSelector((state) => state.socialFeed.hashValue);
	const createHashtagCommentId = useSelector(
		(state) => state.socialFeed.createHashtagCommentId
	);
	const enterKey = useSelector((state) => state.socialFeed.enterKey);
	const lastOpenCommentId = useSelector(
		(state) => state.socialFeed.lastOpenCommentId
	);
	const isLoadingReply = useSelector(
		(state) => state.socialFeed.isLoadingReply
	);
	const [commentWordNumber, setCommentWordNumber] = React.useState(null);
	const [commentText, setCommentText] = React.useState(null);
	const [commentContent, setCommentContent] = React.useState(null);
	const [commentHTML, setCommentHTML] = React.useState(null);
	const [emojiChoose, setEmojiChoose] = React.useState(null);
	const [submitComment, setSubmitComment] = React.useState(false);
	const [submitCommentId, setSubmitCommentId] = React.useState(null);
	const [loadedCommentId, setLoadedCommentId] = React.useState(null);
	const [reactionUserListModal, setReactionUserListModal] = React.useState(
		false
	);
	const [reactionList, setReactionList] = React.useState(null);
	const reportRecord = useSelector((state) => state.report.report);
	const [isViewerOpen, setIsViewerOpen] = useState(false);

	const onEmojiClick = (event, emojiObject) => {
		if (emojiObject.emoji != "🦫") setEmojiChoose(emojiObject.emoji);
	};

	useEffect(() => {
		// let mounted = true;
		if (comment.ownerLike && comment.ownerLike.commentReactionUserList.length)
			setLikeColor("#6F52ED");
		// return () => (mounted = false);
		return () => {};
	}, []);

	return (
		<div
			key={"comment_" + comment.commentId}
			className={classes.postSingleCommentWithReplies}
			id={"comment_" + comment.commentId}
		>
			{/* <div id="quillEditor" style={{ display: "none" }} /> */}
			<GridContainer>
				<GridItem xs={1} sm={1} md={1} style={{ paddingRight: "0px" }}>
					{/* <Link
						to={{
							pathname: `/view-profile/${encryptData(comment.acctId)}`,
						}}
						target="_blank"
						rel="noopener noreferrer"
					> */}
					<div className={classes.commenterProfilePic}>
						<img
							src={comment.profilePic ? comment.profilePic : candidate_avatar}
							alt=""
						/>
					</div>
					{/* </Link> */}
				</GridItem>
				<GridItem xs={11} sm={11} md={11}>
					<div className={classes.postSingleCommentWithRepliesRight}>
						<div className={classes.commentDetails}>
							<div className={classes.commentHeader}>
								<h2 style={{ color: "#000000", marginTop: "0px" }}>
									{/* <Link
										style={{ color: "#000000" }}
										to={{
											pathname: `/view-profile/${encryptData(comment.acctId)}`,
										}}
										target="_blank"
										rel="noopener noreferrer"
									> */}
									{comment.firstName}
									{comment.middleName ? " " + comment.middleName : ""}
									{comment.surname ? " " + comment.surname : ""}
									{/* </Link> */}
								</h2>
								<div style={{ color: "#000000" }}>
									{TimeFormat(comment.lastUpdateDate)}
									<button
										onClick={(e) => {
											signUpDialog();
										}}
									>
										<img src={dotBtn} alt="" />
									</button>
								</div>
							</div>

							<div className={classes.commentText}>
								<div
									dangerouslySetInnerHTML={{
										__html: comment.description.replaceAll(
											'target="_blank"',
											""
										),
									}}
								/>
							</div>
							{comment.attachments.attachmentList.length > 0 ? (
								<div
									className={classes.attachmentListItemDisplay}
									onClick={() => {
										signUpDialog();
									}}
								>
									<div
										className={classes.attachmentListItemAttachmentContainer}
									>
										<img
											src={comment.attachments.attachmentList[0].attachmentPath}
											alt=""
											className={classes.attachmentListItemContent}
										/>
									</div>
								</div>
							) : null}
							{isViewerOpen && (
								<CommentMediaModal
									pictureSource={
										comment.attachments.attachmentList[0].attachmentPath
									}
									onClose={() => {
										signUpDialog();
									}}
									open={isViewerOpen}
								/>
							)}
							{editCommentShow && lastOpenCommentId == comment.commentId ? (
								<div className={styles.feedCommentBx}>
									<div className={styles.commentInputWrap}>
										<div className={classes.mentionsInput}>
											<CommentInput
												comment={comment}
												emojiChoose={emojiChoose}
												resetEmoji={setEmojiChoose}
												getLength={setCommentWordNumber}
												getText={setCommentText}
												getHTML={setCommentHTML}
												getContent={setCommentContent}
												onSubmit={setSubmitComment}
												submitCommentId={setSubmitCommentId}
												parentId={comment.commentId}
											/>
										</div>
										<div className={styles.commentActionBtns}>
											<IconButton
												onClick={(e) => {
													signUpDialog();
												}}
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="21"
													height="21"
													viewBox="0 0 21 21"
												>
													<path
														id="Shape"
														d="M3.075,17.924A10.5,10.5,0,0,1,17.924,3.075,10.5,10.5,0,0,1,3.075,17.924ZM1,10.5A9.5,9.5,0,1,0,10.5,1,9.511,9.511,0,0,0,1,10.5Zm5.207,4.419a.5.5,0,1,1,.6-.8,6.327,6.327,0,0,0,3.727,1.127A6.084,6.084,0,0,0,14.2,14.124a.5.5,0,0,1,.7.09.5.5,0,0,1-.09.7,7,7,0,0,1-4.277,1.331A7.236,7.236,0,0,1,6.207,14.919ZM12.5,8.5a1,1,0,1,1,1,1A1,1,0,0,1,12.5,8.5Zm-6,0a1,1,0,1,1,1,1A1,1,0,0,1,6.5,8.5Z"
														fill="#92929d"
													/>
												</svg>
											</IconButton>
											<IconButton
												onClick={() => {
													signUpDialog();
												}}
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="21"
													height="20"
													viewBox="0 0 21 20"
												>
													<path
														id="Shape"
														d="M17.5,20H3.5A3.431,3.431,0,0,1,0,16.65V3.35A3.431,3.431,0,0,1,3.5,0h14A3.431,3.431,0,0,1,21,3.35v13.3A3.431,3.431,0,0,1,17.5,20ZM15.286,8.775l-4.714,4.253L15.961,19H17.5A2.431,2.431,0,0,0,20,16.65V13.071Zm-8.139,1.95h0L1.009,16.849A2.458,2.458,0,0,0,3.5,19H14.613L7.148,10.725ZM3.5,1A2.431,2.431,0,0,0,1,3.35v12.1l5.814-5.8a.5.5,0,0,1,.724.019L9.9,12.286l5.051-4.557a.5.5,0,0,1,.671,0L20,11.718V3.35A2.431,2.431,0,0,0,17.5,1ZM6.691,6.7A1.925,1.925,0,1,1,8.619,4.775,1.929,1.929,0,0,1,6.691,6.7Zm0-2.849a.925.925,0,1,0,.929.925A.928.928,0,0,0,6.691,3.85Z"
														transform="translate(0 0)"
														fill="#92929d"
													/>
												</svg>
											</IconButton>
											<IconButton
												onClick={() => {
													signUpDialog();
												}}
											>
												<SendIcon
													sx={{
														color: "#92929d",
														margin: "5px",
													}}
													className={styles.sendButton}
												/>
											</IconButton>
										</div>
									</div>
								</div>
							) : null}
						</div>
						{sectionCount <= 4 ? (
							<div className={classes.commentActions}>
								{/* <button onClick={()=>likeComment()}>Like</button> */}
								<Button
									style={{
										color: likeColor,
										textTransform: "capitalize",
										padding: "1px 6px",
									}}
									ref={anchorRef}
									onClick={() => {
										signUpDialog();
									}}
								>
									{likeShow > 0 ? (
										reactionId == 1 ? (
											<ThumbUpAltOutlinedIcon
												sx={{
													color: likeColor,
													margin: "5px",
													transition: "all .5s",
													"&:hover": {
														transform: "scale(1.5)",
													},
												}}
											/>
										) : reactionId == 2 ? (
											<FavoriteBorderOutlinedIcon
												sx={{
													color: likeColor,
													margin: "5px",
													transition: "all .5s",
													"&:hover": {
														transform: "scale(1.5)",
													},
												}}
											/>
										) : reactionId == 3 ? (
											<HandClapIcon
												style={{
													fill: likeColor,
													width: "22px",
													height: "24px",
													margin: "5px",
													transition: "all .5s",
												}}
												className={styles.clapIcon}
											/>
										) : reactionId == 4 ? (
											<SentimentVeryDissatisfiedIcon
												sx={{
													color: likeColor,
													margin: "5px",
													transition: "all .5s",
													"&:hover": {
														transform: "scale(1.5)",
													},
												}}
											/>
										) : reactionId == 5 ? (
											<MoodBadOutlinedIcon
												sx={{
													color: likeColor,
													margin: "5px",
													transition: "all .5s",
													"&:hover": {
														transform: "scale(1.5)",
													},
												}}
											/>
										) : reactionId == 6 ? (
											<SentimentVerySatisfiedOutlinedIcon
												sx={{
													color: likeColor,
													margin: "5px",
													transition: "all .5s",
													"&:hover": {
														transform: "scale(1.5)",
													},
												}}
											/>
										) : (
											<FavoriteBorderOutlinedIcon
												sx={{
													color: likeColor,
													margin: "5px",
													transition: "all .5s",
													"&:hover": {
														transform: "scale(1.5)",
													},
												}}
												alt=""
											/>
										)
									) : (
										"Like"
									)}{" "}
									{likeShow ? "Like" : null}
								</Button>
								{reactionCount || comment.reactionCount ? (
									<span
										className={classes.likeReaction}
										onClick={() => {
											signUpDialog();
										}}
									>
										{reactionCount ? reactionCount : comment.reactionCount}{" "}
										{reactionCount > 1 || comment.reactionCount > 1
											? "Likes"
											: "Like"}
									</span>
								) : null}
								{sectionCount <= 4 ? (
									<div className={classes.verticalLine}></div>
								) : null}
								{sectionCount <= 4 ? (
									<button
										style={{ color: "#6F52ED" }}
										onClick={(e) => {
											signUpDialog();
										}}
									>
										Reply
									</button>
								) : null}
								{sectionCount <= 4 &&
								comment.replyCount > 0 &&
								comment.replyList.commentList &&
								comment.replyCount > comment.replyList.commentList.length ? (
									<span
										className={classes.commentReplyClick}
										onClick={() => {
											signUpDialog();
										}}
									>
										{comment.replyCount}{" "}
										{comment.replyCount > 1 ? "Replies" : "Reply"}
									</span>
								) : null}
							</div>
						) : (
							<div className={classes.horizontalLine}></div>
						)}

						{skeletonShow ? <CommentSkeleton /> : null}
						{replyClicked && lastOpenCommentId == comment.commentId ? (
							<div className={styles.feedCommentBx}>
								<GridContainer>
									<GridItem
										xs={1}
										sm={1}
										md={1}
										style={{ paddingRight: "0px" }}
									>
										<div className={styles.feedCommentUserBx}>
											<img src={profilePic} alt="" />
										</div>
									</GridItem>
									<GridItem xs={11} sm={11} md={11}>
										<div className={styles.commentInputWrap}>
											<div className={classes.mentionsInput}>
												<CommentInput
													comment={comment}
													emojiChoose={emojiChoose}
													resetEmoji={setEmojiChoose}
													getLength={setCommentWordNumber}
													getText={setCommentText}
													getHTML={setCommentHTML}
													getContent={setCommentContent}
													onSubmit={setSubmitComment}
													submitCommentId={setSubmitCommentId}
													parentId={0}
												/>
											</div>
											<div className={styles.commentActionBtns}>
												<IconButton
													onClick={(e) => {
														signUpDialog();
													}}
												>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="21"
														height="21"
														viewBox="0 0 21 21"
													>
														<path
															id="Shape"
															d="M3.075,17.924A10.5,10.5,0,0,1,17.924,3.075,10.5,10.5,0,0,1,3.075,17.924ZM1,10.5A9.5,9.5,0,1,0,10.5,1,9.511,9.511,0,0,0,1,10.5Zm5.207,4.419a.5.5,0,1,1,.6-.8,6.327,6.327,0,0,0,3.727,1.127A6.084,6.084,0,0,0,14.2,14.124a.5.5,0,0,1,.7.09.5.5,0,0,1-.09.7,7,7,0,0,1-4.277,1.331A7.236,7.236,0,0,1,6.207,14.919ZM12.5,8.5a1,1,0,1,1,1,1A1,1,0,0,1,12.5,8.5Zm-6,0a1,1,0,1,1,1,1A1,1,0,0,1,6.5,8.5Z"
															fill="#92929d"
														/>
													</svg>
												</IconButton>
												<IconButton
													onClick={() => {
														signUpDialog();
													}}
												>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="21"
														height="20"
														viewBox="0 0 21 20"
													>
														<path
															id="Shape"
															d="M17.5,20H3.5A3.431,3.431,0,0,1,0,16.65V3.35A3.431,3.431,0,0,1,3.5,0h14A3.431,3.431,0,0,1,21,3.35v13.3A3.431,3.431,0,0,1,17.5,20ZM15.286,8.775l-4.714,4.253L15.961,19H17.5A2.431,2.431,0,0,0,20,16.65V13.071Zm-8.139,1.95h0L1.009,16.849A2.458,2.458,0,0,0,3.5,19H14.613L7.148,10.725ZM3.5,1A2.431,2.431,0,0,0,1,3.35v12.1l5.814-5.8a.5.5,0,0,1,.724.019L9.9,12.286l5.051-4.557a.5.5,0,0,1,.671,0L20,11.718V3.35A2.431,2.431,0,0,0,17.5,1ZM6.691,6.7A1.925,1.925,0,1,1,8.619,4.775,1.929,1.929,0,0,1,6.691,6.7Zm0-2.849a.925.925,0,1,0,.929.925A.928.928,0,0,0,6.691,3.85Z"
															transform="translate(0 0)"
															fill="#92929d"
														/>
													</svg>
												</IconButton>
												<IconButton
													onClick={() => {
														signUpDialog();
													}}
												>
													<SendIcon
														sx={{
															color: "#92929d",
															margin: "5px",
														}}
														className={styles.sendButton}
													/>
												</IconButton>
											</div>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						) : null}

						{comment.replyCount > 0 && comment.replyList.commentList
							? comment.replyList.commentList.map((reply, index) => (
									<div key={reply.commentId}>
										<PublicComment
											comment={reply}
											postId={postId}
											profilePic={profilePic}
											reportReasonsList={reportReasonsList}
											sectionCount={sectionCount + 1}
											commentCount={
												comment.replyList.commentList
													? comment.replyList.commentList.length + commentCount
													: commentCount + 1
											}
											isCopyPost={isCopyPost}
										/>
									</div>
							  ))
							: null}
						{isLoadingReply && loadedCommentId == comment.commentId ? (
							<CommentSkeleton />
						) : null}
						{sectionCount <= 4 &&
						comment.replyCount > 0 &&
						comment.replyList.commentList &&
						comment.replyCount > comment.replyList.commentList.length &&
						comment.replyList.commentList.length > 2 ? (
							<div className={classes.moreReplies}>
								<button
									onClick={() => {
										signUpDialog();
									}}
								>
									Load previous replies
								</button>
							</div>
						) : null}
					</div>
				</GridItem>
			</GridContainer>
		</div>
	);
};

export default PublicComment;
