import React, { Fragment, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Header from "views/Component/Header.js";
import Footer from "views/Component/Footer.js";
import Hidden from "@material-ui/core/Hidden";
import clsx from 'clsx';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import testimonialsPageStyles from 'assets/jss/material-kit-pro-react/views/pageStyle/TestimonialsPage/testimonialsPageStyles';
import { Box, Button, makeStyles, Modal, Typography } from "@material-ui/core";
import Avatar from "@mui/material/Avatar";
import Skeleton from "@mui/material/Skeleton";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Logo from "assets/images/logo.png";
import Leftimg from "assets/images/leftimg.png";


const SignUpTest = ({ match }) => {

  return (
    <>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <div className='leftDiv'>
              <img className='logoImg' src={Logo} alt='logo' />
              <div className="middleImge"><img className='leftImg' src={Leftimg} alt='leftimg' /></div>
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <div className="scrolladd">
              <div className="menulist">
                <ul>

                  <li class="customactive"><Link to="#"><i>Work</i></Link></li>
                  <li><Link to="#"><i>Service</i></Link></li>
                  <li><Link to="#"><i>Contact</i></Link></li>
                </ul>
              </div>
              <div class="form-container customform">
                <form action="" role="form">
                  <input id='step2' type='checkbox' />
                  <input id='step3' type='checkbox' />

                  <div id="part1" className="form-group customform1">
                    <div class="panel panel-primary">
                      <div class="panel-heading panelHeading">
                        <h5>To begin, please enter your email:</h5>
                        <p>Don’t worry, we won’t be sending you any spam mails! We just want to send you the link that grants you access to your personality result.</p>
                      </div>
                      <input type="text" id="name" class="form-control" placeholder="Enter your email here" aria-describedby="sizing-addon1" />
                      <div class="btn-group btn-group-lg customgroup" role="group" aria-label="...">
                        <label for='step2' id="continue-step2" class="continue customcontinue">
                          <div class="btn btn-default btn-success btn-lg">Start The quiz</div>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div id="part2" class="form-group customform1 custommform2">
                    <div class="panel panel-primary">
                      <p class="MuiTypography-root innerText PsychometricText MuiTypography-body1">
                        <h4>Question 1 of 20</h4>
                        <h5>What do you most relate to?</h5>
                        <div class="greyOuter">
                          <p>I prefer to spend most of my time in my own mind and thoughts.</p></div><div class="blueOuter"><p>I prefer to spend most of my time interacting with people,<br /> things, and situations around me</p>
                        </div>
                      </p>
                      <div class="btn-group btn-group-lg btn-group-justified" role="group" aria-label="...">

                        <label for='step3' id="continue-step3" class="continue">
                          <div class="btn btn-default btn-success btn-lg abort" role="button">Sign me up!</div>
                        </label>

                      </div>
                    </div>
                  </div>

                  <div id="part3" class="form-group custommform2">
                    <div class="panel panel-primary">
                      <p class="MuiTypography-root innerText PsychometricText MuiTypography-body1 sign6">
                        <h4>You’re almost there!</h4>
                        <h5 class="colorbl">Did you know that the number one key factor in job satisfaction and performance is being able to find a role that suits your personality type?What do you most relate to?</h5>

                        <h5>Register for an account with OppTy now to view your results and to start boosting your career potential!
                        </h5>

                      </p>
                      <label class="continue">
                        <button type="submit" class="btn btn-default btn-success btn-lg">Sign me up!</button>
                      </label>
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </>
  );
};

export default SignUpTest;
