import React, { useState } from 'react';

import AddNewEducationCard from './AddNewEducationCard';
import SingleEducationCard from '../../Education/SingleEducationCard';

import { makeStyles, Typography, Button } from '@material-ui/core';

import noEducationPic from 'assets/img/no-education.png';
import editEducationStyles from 'assets/jss/material-kit-pro-react/views/componentStyle/profileView/editProfile/education/editEducationStyles';
import EducationCardSection from './EducationCardSection.js';
import { useSelector, useDispatch } from 'react-redux';

import { activateEditType } from 'configurations/redux/editProfileModalSlice';
import EditEducationModal from './EditEducationModal.js';
import ProfileStrengthTip from 'views/Page/ProfilePage/ProfileSection/ProfileStrengthTip';
const EditEducation = props => {
  const styles = makeStyles(editEducationStyles)();
  const { match, educations, masterExtension, saveDetailModalProps, onCancelChangesConfirm, setIsChanged } = props;
  const dispatch = useDispatch();

  const [isAddNewFormOpen, setIsAddNewFormOpen] = useState(false);

  const educationRecords = useSelector(state => state.user.educationRecords);

  const type = useSelector(state => state.editProfileModal.type);
  const extension = useSelector(state => state.editProfileModal.extension);
  const isShow = useSelector(state => state.editProfileModal.isShow);
  const isTabShow = useSelector(state => state.editProfileModal.isTabShow);

  return (
    <Typography component="div" className={styles.body}>
      {props.masterExtension && <ProfileStrengthTip extension={props.masterExtension} />}
      <Typography component="div" className={styles.addNewBtnContainer}>
        <Button
          className={styles.addNewBtn}
          onClick={() => {
            const response = {
              type: 'EDIT_PROFILE',
              tabType: 'EDUCATION',
              isTabShow: true,
              extension: {
                recordId: -1,
              },
            };
            /* setRecordId(-1); */

            dispatch(activateEditType(response));
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
            <path
              id="icon-3_path"
              data-name="icon-3 path"
              d="M8.468,17a2.117,2.117,0,0,1-2.085-2.147V10.616h-4.3a2.118,2.118,0,0,1,.061-4.235H6.383V2.147A2.117,2.117,0,0,1,8.468,0H8.53a2.117,2.117,0,0,1,2.088,2.147V6.382h4.3a2.118,2.118,0,0,1-.061,4.235H10.617v4.3A2.117,2.117,0,0,1,8.5,17Z"
              transform="translate(0)"
              fill="#687bfe"
            />
          </svg>
          <span>ADD NEW</span>
        </Button>
      </Typography>

      {isTabShow && (
        <EditEducationModal
          eduRecId={extension.recordId}
          eduRecord={
            educationRecords
              .filter(item => {
                return item.eduRecId === extension.recordId;
              })
              .map(item => item)[0]
          }
          key={extension.recordId}
          saveDetailModalProps={props.saveDetailModalProps}
          onCancelChangesConfirm={props.onCancelChangesConfirm}
          setIsChanged={props.setIsChanged}
        />
      )}
      <EducationCardSection />
    </Typography>
  );
};

export default EditEducation;
