import React, { Fragment } from "react";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import Header from "views/Component/Header";
import LeftSidebar from "views/Component/LeftSidebar";
import SuggestedGroups from "views/Component/DiscoverGroups/SuggestedGroups.js";
import GroupCategoryCard from "views/Component/GroupCategoryCard";
import PopularGroups from "views/Component/DiscoverGroups/PopularGroups.js";
import Footer from "views/Component/Footer";

import discoverGroupsPageStyles from "assets/jss/material-kit-pro-react/views/pageStyle/DiscoverGroupsPage";
import { IconButton, InputBase, makeStyles, Typography } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';

import img1 from "assets/img/examples/blog1.jpg"
import img2 from "assets/img/examples/blog2.jpg"
import img3 from "assets/img/examples/blog3.jpg"
import img4 from "assets/img/examples/blog4.jpg"
import img5 from "assets/img/examples/blog5.jpg"


const useStyles = makeStyles(discoverGroupsPageStyles);

const DiscoverGroupsPage = (props) => {
  const styles = useStyles();

  return (
    <div className={styles.body}>
      <Typography component="div" className={styles.pageHeader}>
        <Typography component="div" className={styles.container}>
          <Header />

          <GridContainer container={true} spacing={4}>

            <GridItem className={styles.leftSideBar}>
              <LeftSidebar />
            </GridItem>

            <GridItem className={styles.main}>

              <Typography component="div" className={styles.header}>
                <Typography component="h1" className={styles.heading}>Discover Groups</Typography>
                <Typography component="div" className={styles.headerRight}>
                  <Typography component="div" className={styles.headerSearchGroup}>
                    <input type="text" placeholder="Search Groups" />
                    <button>
                      <SearchIcon />
                    </button>
                  </Typography>
                  <IconButton aria-label="add" className={styles.addGroupBtn}>
                    <AddIcon />
                    Create Group
                  </IconButton>
                </Typography>
              </Typography>

              <SuggestedGroups />

              <Typography component="div" className={styles.groupsTypesContainer}>
                <GroupCategoryCard
                  image={img1}
                  name={"Hobby"}
                  icon={<svg xmlns="http://www.w3.org/2000/svg" width="43.56" height="40.92" viewBox="0 0 43.56 40.92">
                    <path id="Shape" d="M21.78,40.92a22.365,22.365,0,0,1-15.4-5.993,19.6,19.6,0,0,1,0-28.935A22.365,22.365,0,0,1,21.78,0a22.365,22.365,0,0,1,15.4,5.993,19.6,19.6,0,0,1,0,28.935A22.365,22.365,0,0,1,21.78,40.92ZM19.875,30.536a37.615,37.615,0,0,0-4.166.427c1.091,2.752,2.57,4.773,4.166,5.69Zm3.96.008v6.019a11.536,11.536,0,0,0,4.017-5.6A37.5,37.5,0,0,0,23.835,30.544Zm7.9,1.289h0a21.563,21.563,0,0,1-1.642,3.439,17.944,17.944,0,0,0,3.923-2.641,23.8,23.8,0,0,0-2.281-.8Zm-19.906,0a23.8,23.8,0,0,0-2.281.8,17.944,17.944,0,0,0,3.923,2.641A21.565,21.565,0,0,1,11.827,31.833ZM33.614,22.32a34.068,34.068,0,0,1-.808,5.931,26.723,26.723,0,0,1,3.847,1.435,16.116,16.116,0,0,0,2.839-7.366Zm-29.545,0a16.115,16.115,0,0,0,2.839,7.366,26.722,26.722,0,0,1,3.847-1.435,34.07,34.07,0,0,1-.808-5.931Zm19.766,0v4.5a41.574,41.574,0,0,1,5.116.548,30.609,30.609,0,0,0,.7-5.047Zm-9.923,0a30.6,30.6,0,0,0,.7,5.047,41.8,41.8,0,0,1,5.266-.555V22.32ZM36.018,10.392h0a27.93,27.93,0,0,1-3.474,1.224,33.366,33.366,0,0,1,1.07,6.984h5.877a16.129,16.129,0,0,0-3.473-8.208Zm-7.336,2.077a41.711,41.711,0,0,1-4.847.5V18.6h5.813A29.879,29.879,0,0,0,28.682,12.469Zm-13.8,0h0a29.878,29.878,0,0,0-.966,6.131h5.962V12.98a42.013,42.013,0,0,1-5-.51ZM7.542,10.392A16.129,16.129,0,0,0,4.069,18.6H9.946a33.366,33.366,0,0,1,1.07-6.984A28.076,28.076,0,0,1,7.542,10.392ZM19.875,4.268A10.146,10.146,0,0,0,16.164,8.9c1.194.175,2.442.3,3.711.356Zm3.96.089V9.247c1.2-.061,2.4-.178,3.561-.348A10.422,10.422,0,0,0,23.835,4.357Zm6.257,1.292a20.626,20.626,0,0,1,1.234,2.428c.6-.166,1.187-.354,1.758-.559A18.073,18.073,0,0,0,30.092,5.648Zm-16.623,0a18.071,18.071,0,0,0-2.992,1.869c.56.2,1.152.39,1.758.559A20.631,20.631,0,0,1,13.469,5.648Z" transform="translate(0 0)" fill="#50b5ff" />
                  </svg>
                  }
                />
                <GroupCategoryCard
                  image={img2}
                  name={"Entertainment"}
                  icon={<svg xmlns="http://www.w3.org/2000/svg" width="43.89" height="37.2" viewBox="0 0 43.89 37.2">
                    <path id="Combined_Shape" data-name="Combined Shape" d="M7.98,37.2A7.733,7.733,0,0,1,0,29.76V7.44A7.734,7.734,0,0,1,7.98,0H35.91a7.734,7.734,0,0,1,7.98,7.44V29.76A7.733,7.733,0,0,1,35.91,37.2ZM3.99,7.44V29.76A3.867,3.867,0,0,0,7.98,33.48H35.91a3.866,3.866,0,0,0,3.99-3.72V7.44A3.867,3.867,0,0,0,35.91,3.72H7.98A3.867,3.867,0,0,0,3.99,7.44ZM14.474,28.4a2.817,2.817,0,0,1-.509-1.609V10.409A3.085,3.085,0,0,1,17.148,7.44a3.34,3.34,0,0,1,1.728.475L32.46,16.107a2.948,2.948,0,0,1,1.392,1.91,2.79,2.79,0,0,1-.445,2.192,3.123,3.123,0,0,1-.947.884L18.876,29.285a3.348,3.348,0,0,1-1.724.475A3.235,3.235,0,0,1,14.474,28.4Zm3.481-2.991L29.248,18.6,17.955,11.791Z" fill="#82c43c" />
                  </svg>
                  }
                />
                <GroupCategoryCard
                  image={img3}
                  name={"Business"}
                  icon={<svg xmlns="http://www.w3.org/2000/svg" width="43.56" height="35.34" viewBox="0 0 43.56 35.34">
                    <path id="Combined_Shape" data-name="Combined Shape" d="M5.94,35.34A5.775,5.775,0,0,1,0,29.76V11.16A5.775,5.775,0,0,1,5.94,5.58h5.94A5.776,5.776,0,0,1,17.82,0h7.92a5.776,5.776,0,0,1,5.94,5.58h5.94a5.775,5.775,0,0,1,5.94,5.58v18.6a5.775,5.775,0,0,1-5.94,5.58ZM37.62,31.62A1.925,1.925,0,0,0,39.6,29.76V11.16A1.925,1.925,0,0,0,37.62,9.3h-.99V31.62Zm-4.95,0V9.3H10.89V31.62ZM3.96,11.16v18.6A1.925,1.925,0,0,0,5.94,31.62h.99V9.3H5.94A1.925,1.925,0,0,0,3.96,11.16ZM27.72,5.58a1.926,1.926,0,0,0-1.98-1.86H17.82a1.926,1.926,0,0,0-1.98,1.86Z" fill="#ff974a" />
                  </svg>
                  }
                />
                <GroupCategoryCard
                  image={img4}
                  name={"Fashion"}
                  icon={<svg xmlns="http://www.w3.org/2000/svg" width="43.566" height="40.925" viewBox="0 0 43.566 40.925">
                    <path id="Shape" d="M33.656,40.925a2.059,2.059,0,0,1-1.092-.317L21.782,33.855,11,40.608a2.06,2.06,0,0,1-2.649-.388A1.749,1.749,0,0,1,7.943,38.8l1.84-12.1-9.2-8.643a1.756,1.756,0,0,1-.5-1.85A1.93,1.93,0,0,1,1.7,14.9L14.46,13.187l5.5-12.059a2.033,2.033,0,0,1,3.64,0l5.5,12.059L41.863,14.9A1.93,1.93,0,0,1,43.478,16.2a1.756,1.756,0,0,1-.5,1.85l-9.2,8.643,1.84,12.1a1.749,1.749,0,0,1-.409,1.423A2.074,2.074,0,0,1,33.656,40.925ZM21.782,29.76a2.065,2.065,0,0,1,1.1.313l8.2,5.132-1.354-8.9a1.8,1.8,0,0,1,.56-1.579l7.1-6.67-9.94-1.334a1.98,1.98,0,0,1-1.54-1.109l-4.12-9.031-4.119,9.031a1.98,1.98,0,0,1-1.54,1.109l-9.94,1.334,7.1,6.67a1.8,1.8,0,0,1,.56,1.579l-1.354,8.9,8.2-5.132A2.065,2.065,0,0,1,21.782,29.76Z" transform="translate(0.001 0)" fill="#ff9ad5" />
                  </svg>
                  }
                />
                <GroupCategoryCard
                  image={img5}
                  name={"News Update"}
                  icon={<svg xmlns="http://www.w3.org/2000/svg" width="39.9" height="37.2" viewBox="0 0 39.9 37.2">
                    <path id="Shape" d="M31.92,37.2H7.98A7.733,7.733,0,0,1,0,29.76V7.44A7.733,7.733,0,0,1,7.98,0H31.92A7.733,7.733,0,0,1,39.9,7.44V29.76A7.732,7.732,0,0,1,31.92,37.2ZM7.98,3.72A3.867,3.867,0,0,0,3.99,7.44V29.76A3.867,3.867,0,0,0,7.98,33.48H31.92a3.866,3.866,0,0,0,3.99-3.72V7.44A3.867,3.867,0,0,0,31.92,3.72ZM19.95,29.76H9.975a1.865,1.865,0,1,1,0-3.721H19.95a1.865,1.865,0,1,1,0,3.721Zm9.975-9.3H9.975a1.864,1.864,0,1,1,0-3.72h19.95a1.864,1.864,0,1,1,0,3.72Zm0-9.3H9.975A1.933,1.933,0,0,1,7.98,9.3,1.933,1.933,0,0,1,9.975,7.44h19.95a1.933,1.933,0,0,1,2,1.859A1.934,1.934,0,0,1,29.925,11.16Z" transform="translate(0 0)" fill="#a461d8" />
                  </svg>
                  }
                />
              </Typography>

              <PopularGroups />

            </GridItem>
          </GridContainer>

          <Footer />
        </Typography>
      </Typography>
    </div>
  )
}

export default DiscoverGroupsPage;