import React from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import { instanceUrl } from "configurations/configurations.js";
import { makeStyles } from "@material-ui/core/styles";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Tooltip from "@material-ui/core/Tooltip";

import PeopleAltRoundedIcon from "@material-ui/icons/PeopleAltRounded";
import LanguageRoundedIcon from "@material-ui/icons/LanguageRounded";
import WorkRoundedIcon from "@material-ui/icons/WorkRounded";
import DomainRoundedIcon from "@material-ui/icons/DomainRounded";

import corporateProfilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/CorporateProfilePage/corporateProfilePageStyle.js";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/CreateOutlined";
import CorporateBasicProfileEditModal from "./CorporateBasicProfileEditModal.js";
import IconLibrary from "views/Component/IconLibrary.js";
import Button from "components/CustomButtons/Button.js";

const useStyles = makeStyles(corporateProfilePageStyle);

export default function CorporateBasicProfileSection() {
	const classes = useStyles();
	const isViewed = useSelector((state) => state.corpUser.isViewed);
	const profileRecord = useSelector((state) => state.corpUser.profileRecord);
	const [industryList, setIndustryList] = React.useState([]);
	const [companyTypeList, setCompanyTypeList] = React.useState([]);
	const [basicProfileEditModal, setBasicProfileEditModal] = React.useState(
		false
	);

	const closeBasicProfileEditModal = () => {
		setBasicProfileEditModal(false);
	};

	React.useEffect(() => {
		let mounted = true;
		let CancelToken = axios.CancelToken;
		const source = CancelToken.source();

		const fetchIndustryList = async () => {
			try {
				const res = await axios.get(`${instanceUrl}/api/md/get-all-industry`, {
					cancelToken: source.token,
				});
				setIndustryList(res.data.payload);
			} catch (error) {
				if (axios.isCancel(error)) {
					//console.log("unmount caused");
				} else {
					throw error;
				}
			}
		};
		const fetchCompanyList = async () => {
			try {
				const res = await axios.get(
					`${instanceUrl}/api/md/get-all-company-type`,
					{
						cancelToken: source.token,
					}
				);
				setCompanyTypeList(res.data.payload);
			} catch (error) {
				if (axios.isCancel(error)) {
					//console.log("unmount caused");
				} else {
					throw error;
				}
			}
		};
		if (mounted) {
			fetchIndustryList();
			fetchCompanyList();
		}
		return () => {
			source.cancel();
			mounted = false;
		};
	}, []);

	return (
		<React.Fragment>
			<Card className={classes.cardFrame}>
				<CardBody>
					<GridContainer
						justifyContent="space-between"
						alignContent="center"
						alignItems="center"
					>
						<GridItem xs={9} sm={9} md={9}>
							<h4 className={classes.title}>Overview</h4>
						</GridItem>
						<GridItem
							xs={2}
							sm={2}
							md={2}
							lg={2}
							className={classes.buttonColor}
						>
							{isViewed ? null : (
								<Tooltip
									title={
										<p className={classes.tooltipDescription}>Edit Profile</p>
									}
									placement="top"
									classes={{ tooltip: classes.tooltip }}
								>
									{/* <IconButton
										onClick={() => setBasicProfileEditModal(true)}
										color="inherit"
										edge="end"
									>
										<EditIcon />
									</IconButton> */}
									<Button
										justIcon
										style={{ boxShadow: "none" }}
										color="transparent"
										className={classes.buttonIconMargin}
										onClick={() => {
											setBasicProfileEditModal(true);
										}}
									>
										<IconLibrary.Pen />
									</Button>
								</Tooltip>
							)}
						</GridItem>
					</GridContainer>
					{profileRecord.companySize ||
					profileRecord.url ||
					profileRecord.industryId ||
					profileRecord.companyTypeId ? (
						<GridContainer
								justifyContent="space-between"
							alignContent="center"
							alignItems="center"
						>
							{profileRecord.companySize ? (
								<GridItem xs={12} sm={12} md={12}>
									<div className={classes.infoAreaStyle}>
										<IconLibrary.User />
										{profileRecord.companySize} Employee
									</div>
									{/* <InfoArea
									className={classes.info}
									title={profileRecord.companySize + " Employee +"}
									description={""}
									icon={PeopleAltRoundedIcon}
								/> */}
								</GridItem>
							) : null}

							{profileRecord.url ? (
								<GridItem>
									<div className={classes.infoAreaStyle}>
										<IconLibrary.Globe />
										<a
											target={profileRecord.url}
											href={`https://` + profileRecord.url}
											rel="noopener noreferrer"
										>
											{profileRecord.url}
										</a>
									</div>
									{/* <InfoArea
									className={classes.info}
									title={
										
									}
									description={""}
									icon={LanguageRoundedIcon}
								/> */}
								</GridItem>
							) : null}

							{profileRecord.industryId ? (
								<GridItem xs={12} sm={12} md={12}>
									<div className={classes.infoAreaStyle}>
										<IconLibrary.Gear />
										{industryList
											.filter((item) => {
												return item.industryId === profileRecord.industryId;
											})
											.map((item) => {
												return item.industryName;
											})}
									</div>
									{/* <InfoArea
									className={classes.info}
									title=
									description={""}
									icon={WorkRoundedIcon}
								/> */}
								</GridItem>
							) : null}

							{profileRecord.companyTypeId ? (
								<GridItem xs={12} sm={12} md={12}>
									<div className={classes.infoAreaStyle}>
										<IconLibrary.Building />
										{companyTypeList
											.filter((item) => {
												return (
													item.companyTypeId === profileRecord.companyTypeId
												);
											})
											.map((item) => {
												return item.companyTypeName;
											})}
									</div>
									{/* <InfoArea
									className={classes.info}
									title=
									description={""}
									icon={DomainRoundedIcon}
								/> */}
								</GridItem>
							) : null}
						</GridContainer>
					) : (
						<Card className={classes.cardBorder}>
							<CardBody>
								<h5 className={classes.smallDescription}>
									No information filled yet. Please update Company Profile
									Records.
								</h5>
							</CardBody>
						</Card>
					)}
				</CardBody>
			</Card>
			<CorporateBasicProfileEditModal
				open={basicProfileEditModal}
				onClose={closeBasicProfileEditModal}
			/>
		</React.Fragment>
	);
}
