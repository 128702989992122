import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
	departmentViewRoadmap,
	viewRoadmapRoleByRoleId,
} from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Industry_Biomedical_Engineering from "assets/img/Career_Roadmap/Biomedical_Engineering/Industry_Biomedical_Engineering_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function IndustryBiomedicalEngineering() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Industry_Biomedical_Engineering;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "be",
				shape: "poly",
				coords: [75,350,64,354,59,358,56,362,54,370,54,423,56,430,60,436,65,439,71,441,162,442,171,439,176,436,180,430,182,424,183,371,180,362,175,356,169,352,164,351,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "269",
				shape: "poly",
				coords: [213,356,208,358,204,360,201,364,200,370,200,420,203,426,204,428,209,432,214,434,348,432,354,430,358,427,360,423,362,419,362,370,360,363,355,359,350,357,346,356,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "270",
				shape: "poly",
				coords: [584,356,578,360,574,361,572,367,571,372,571,418,572,424,575,429,580,431,585,433,719,434,726,431,729,428,732,425,733,419,733,372,731,363,728,359,723,356,718,356,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "271",
				shape: "poly",
				coords: [956,361,949,363,945,366,943,369,942,376,942,424,943,430,946,434,951,437,957,438,1089,437,1098,434,1101,431,1102,428,1104,423,1104,377,1102,370,1099,366,1096,364,1091,361,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "272",
				shape: "poly",
				coords: [1326,361,1320,363,1318,366,1315,369,1314,373,1313,378,1314,427,1316,431,1318,434,1322,436,1328,438,1465,438,1470,435,1472,432,1474,428,1476,423,1475,377,1474,370,1471,366,1468,364,1463,361,1460,361,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "273",
				shape: "poly",
				coords: [1698,360,1692,363,1687,365,1684,372,1684,378,1684,425,1687,431,1690,433,1694,437,1700,438,1839,438,1845,436,1850,432,1852,428,1853,424,1853,375,1850,366,1846,363,1842,361,1836,360,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "be") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo);
			dispatch(viewRoadmapRoleByRoleId(requestInfo));

			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
