import React from "react";
import Card from "@mui/material/Card";
import groupPageStyles from "assets/jss/material-kit-pro-react/views/pageStyle/GroupsPage";
import { makeStyles } from "@material-ui/core";

import CardContent from "@mui/material/CardContent";

import Skeleton from "@mui/material/Skeleton";
const useStyles = makeStyles(groupPageStyles);

export default function GroupLoader() {
	const classes = useStyles();
	return (
		<div className={classes.cardContainer}>
			<Skeleton
				sx={{
					height: 140,
					borderTopLeftRadius: "1rem",
					borderTopRightRadius: "1rem",
				}}
				animation="wave"
				variant="rectangular"
			/>

			<CardContent>
				<React.Fragment>
					<Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
					<Skeleton animation="wave" height={10} width="80%" />
					<Skeleton
						sx={{
							height: 40,
							width: "100%",
							margin: "10px auto",
						}}
						animation="wave"
						variant="rectangular"
					/>
				</React.Fragment>
			</CardContent>
		</div>
	);
}
