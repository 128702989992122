import React, { useEffect } from "react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { useSelector, useDispatch } from "react-redux";

import searchPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/SearchPage/searchPageStyle.js";

import { makeStyles } from "@material-ui/core/styles";

import SmallCardLoading from "../SmallCardLoading.js";
import Pagination from "views/Component/PaginationWithPage.js";
import NoResultCard from "../NoResultCard";
import { searchPost } from "configurations/redux/searchPostSlice.js";
import PostCard from "./PostCard.js";
const useStyles = makeStyles(searchPageStyle);

export default function PostComponentInPage() {
	const classes = useStyles();
	const dispatch = useDispatch();

	const searchResult = useSelector((state) => state.searchPost.searchResult);
	const searchParams = useSelector((state) => state.searchPost.searchParams);
	const userStatus = useSelector((state) => state.auth.user);
	const isLoading = useSelector((state) => state.searchPost.isLoading);
	const auth = useSelector((state) => state.auth);
	const [currentPage, setCurrentPage] = React.useState(1);
	const [postPerPage, setPostPerPage] = React.useState(5);

	const indexOfLast = currentPage * postPerPage;
	const indexOfFirst = indexOfLast - postPerPage;

	const paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
		var indexOfFirst = pageNumber * postPerPage - postPerPage;

		const requestProfile = {
			acctId: auth.isPublic ? 0 : userStatus.acctId,
			accessToken: auth.isPublic
				? auth.publicToken
				: localStorage.getItem("token"),
			email: auth.isPublic ? auth.publicEmail : userStatus.email,
			keyword: searchParams.keyword,
			lastRecordSize: indexOfFirst,
			requestRecordSize: postPerPage,
			hashId: 0,
			userId: 0,
			groupId: 0,
			pageId: 0,
			sortBy: searchParams.sortBy,
			visibleIds: auth.isPublic ? "1" : "1,2",
		};
		dispatch(searchPost(requestProfile));
		scrollToTop();
	};
	const scrollToTop = () => {
		var timer = setInterval(() => {
			var jobPostResult = document.getElementById("searchResultPost");
			if (jobPostResult) {
				jobPostResult.scrollIntoView();
				window.scrollTo(0, 0);
				clearInterval(timer);
			}
		}, 100);
	};
	const PostList =
		searchResult &&
		searchResult.length > 0 &&
		searchResult.map((item) => {
			return (
				<div key={item.postId}>
					<PostCard item={item} />
				</div>
			);
		});
	const loadingFields = [];
	for (let i = 0; i < postPerPage - searchResult.length; i++) {
		loadingFields.push(
			<div key={i}>
				<SmallCardLoading />
			</div>
		);
	}

	return (
		<Card className={classes.cardFrame}>
			<CardBody className={classes.cardBodyPadding}>
				<h4 className={classes.componentTitle}>Post</h4>
				{isLoading &&
					loadingFields.map((item) => {
						return item;
					})}
				<div className={classes.mainContainer}>
					{PostList}{" "}
					{!isLoading && searchResult.length === 0 && (
						<NoResultCard showImage={true} />
					)}{" "}
				</div>
				{searchResult && searchResult.length > 0 && (
					<Pagination
						totalPosts={searchResult[0].totalPost}
						postsPerPage={postPerPage}
						paginate={paginate}
					/>
				)}
			</CardBody>
		</Card>
	);
}
