import React from 'react';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import { useSelector, useDispatch } from 'react-redux';

import ArticleCard from './SearchArticlePage/ArticleCard.js';
import { Button } from '@material-ui/core';
import NoResultCard from './NoResultCard.js';
import { saveOption } from 'configurations/redux/genericSearchSlice.js';
import { Link, useHistory } from 'react-router-dom';
import searchPageStyle from 'assets/jss/material-kit-pro-react/views/pageStyle/SearchPage/searchPageStyle.js';
import { makeStyles } from '@material-ui/core/styles';
import SmallCardLoading from './SmallCardLoading.js';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

const useStyles = makeStyles(searchPageStyle);

export default function ArticleComponent() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector(state => state.auth);

  const searchResult = useSelector(state => state.community.searchResult);
  const isLoading = useSelector(state => state.community.isLoading);

  const ArticleList =
    searchResult &&
    searchResult.length > 0 &&
    searchResult.map(item => {
      return (
        // <div key={item.articleId}>
        <GridItem xs={12} sm={6} md={6} lg={4} xl={3} key={item.articleId}>
          <ArticleCard item={item} />
        </GridItem>
        // </div>
      );
    });

  const loadingFields = [];
  for (let i = 0; i < 4; i++) {
    loadingFields.push(
      // <div key={i}>
      <GridItem xs={12} sm={6} md={6} lg={4} xl={3} key={i}>
        <SmallCardLoading />
      </GridItem>
      // </div>
    );
  }

  return (
    <Card className={classes.cardFrame}>
      <CardBody className={classes.cardBodyPadding}>
        <h4 className={classes.componentTitle}>Article</h4>
        <div className={classes.mainContainer}>
          <GridContainer>
            {isLoading &&
              loadingFields.map(item => {
                return item;
              })}
          </GridContainer>
          <GridContainer>{ArticleList}</GridContainer>
          {!isLoading && searchResult.length === 0 && <NoResultCard showImage={false} />}
        </div>

        {!isLoading && (
          <Link
            className={classes.textDeco}
            to={auth.isPublic ? `/public-search-page-article` : `/search-page-article`}
            color="transparent"
          >
            <Button
              fullWidth
              onClick={() => {
                const response = {
                  option: auth.isPublic ? '/public-search-page-article' : '/search-page-article',
                };
                dispatch(saveOption(response));
              }}
              className={classes.buttonViewMore}
            >
              See All Article Result
            </Button>
          </Link>
        )}
      </CardBody>
    </Card>
  );
}
