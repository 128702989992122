import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCandidateSelectJobPosts } from "configurations/redux/jobManagementSlice.js";
import { TimeFormat } from "configurations/configurations.js";
import { makeStyles } from "@material-ui/core/styles";
import { encryptData } from 'configurations/configurations.js';

import classNames from "classnames";
import { Link } from "react-router-dom";

import Tooltip from "@material-ui/core/Tooltip";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Primary from "components/Typography/Primary.js";
import Danger from "components/Typography/Danger.js";

import jobStatusPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/JobStatusPage/jobStatusPageStyle.js";
import Pagination from "../../Component/Pagination.js";
import ResultJobModal from "../SearchJobPage/ResultJobModal.js";
import CreatePostModal from "../../../components/CreatePostModal/CreatePostModal";
import WithdrawlModal from "./WithdrawlModal.js";
import AppliedJobSideOptions from "./AppliedJobSideOptions";
import NoRecordModal from "../../Component/NoRecordModal.js";

import defaultAvatar from "assets/img/corporate_avatar.jpeg";
import EditIcon from "@material-ui/icons/Edit";
import LoadingImage from "assets/img/Loading-pana.png";

import { Divider } from "@material-ui/core";
import {
  getAppliedJobPostList,
  getAppliedJobPost,
  getSeekerJobPostList,
} from "configurations/redux/appliedJobSlice";
import Backdrop from "../../Component/Backdrop.js";
import { resetNotificationItem } from "configurations/redux/eventNotificationSlice";
import IconLibrary from "views/Component/IconLibrary.js";
import { Box, Modal, Button, Typography, Popover } from '@material-ui/core';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import AcceptAppliedModal from "./AcceptAppliedModal";
import RejectAppliedModal from "./RejectAppliedModal";

/*Sourabh start */


const publicIp = require("public-ip");

const useStyles = makeStyles(jobStatusPageStyle);


export default function AppliedJobSection(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userStatus = useSelector((state) => state.auth.user);

  const appliedJobPostList = useSelector(
    (state) => state.appliedJob.jobPostList
  );
  console.log('appliedJobPostListLLLLLLLLLLL');
  console.log(appliedJobPostList);
  const appliedJobPost = useSelector((state) => state.appliedJob.jobPost);

  const isLoading = useSelector((state) => state.appliedJob.isLoading);
  const isLoadingJob = useSelector((state) => state.appliedJob.isLoadingJob);

  const loadingAppliedJobList = useSelector(
    (state) => state.appliedJob.loadingJobList
  );

  //const [currentPage, setCurrentPage] = React.useState(1);
  const [jobsPerPage, setJobsPerPage] = React.useState(5);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pendingJobPage, setPendingJobPage] = React.useState(0);


  const [clientIp, setClientIp] = React.useState();
  const [jobViewModal, setJobViewModal] = React.useState(false);
  const [withdrawModal, setWithdrawModal] = React.useState(false);
  const [noRecordModal, setNoRecordModal] = React.useState(false);
  const [createPost, setCreatePost] = React.useState(false);
  const [selectedPost, setSelectedPost] = React.useState(false);

  /*Sourabh start */
  const [acceptModal, setAcceptModal] = React.useState(false);
  const [rejectModal, setRejectModal] = React.useState(false);

  //const [jobViewModal, setJobViewModal] = React.useState(false);

  const [selectedJobPost, setSelectedJobPost] = React.useState([]);
  const getJobPostModel = async (jobPostSelId) => {


    const requestProfile = {
      acctId: userStatus.acctId,
      email: userStatus.email,
      accessToken: localStorage.getItem("token"),
      // acctId: auth.isPublic ? 0 : userStatus.acctId,
      // accessToken: auth.isPublic ? auth.publicToken : localStorage.getItem('token'),
      ipAddr: localStorage.getItem('ipAddr'), //await publicIp.v4(),
      jobPostId: jobPostSelId,
      item: null,
    };
    console.log(requestProfile);
    dispatch(getCandidateSelectJobPosts(requestProfile));
    setJobViewModal(true);
  };
  const onJobModelClose = () => {
    setJobViewModal(false);
  };

  /*  const selectedJobPost = useSelector(
     (state) => state.jobManagement.selectedJobPost
   ); */
  const statusChangeHandler = (item) => {
    console.log(item);
    setCreatePost(false);
    setSelectedPost(false);
  };

  const [jobPostId, setJobPostId] = React.useState();
  const [applId, setApplId] = React.useState();
  const notificationJobPostItem = useSelector(
    (state) => state.eventNotification.item
  );



  const onClose = () => {
    setJobPostId();
    setJobViewModal(false);
    setCurrentPage(currentPage);
    var indexOfFirstJob = currentPage * jobsPerPage - jobsPerPage;
    getJobPostList(indexOfFirstJob, false);
  };
  const noRecordOnClose = () => {
    setNoRecordModal(false);
  };

  const withdrawOnClose = () => {
    setApplId();
    setWithdrawModal(false);
  };

  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgProfile,
    classes.logoImage
  );


  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    var indexOfFirstJob = pageNumber * jobsPerPage - jobsPerPage;
    getJobPostList(indexOfFirstJob, true);
  };

  const getJobPost = async (item) => {
    console.log(item);
    if (item && item.jobPostStatusId === 1) {
      const requestProfile = {
        acctId: userStatus.acctId,
        accessToken: localStorage.getItem("token"),
        ipAddr: localStorage.getItem("ipAddr"), //await publicIp.v4(),
        jobPostId: item.jobPostId,
        item: item,
      };
      console.log(requestProfile);
      dispatch(getAppliedJobPostList(requestProfile));
      setJobViewModal(true);
    } else {
      setNoRecordModal(true);
    }
  };

  /*   const getJobPostList = async (indexOfFirstJob, scroll) => {
      const requestProfile = {
        acctId: userStatus.acctId,
        email: userStatus.email,
        accessToken: localStorage.getItem("token"),
        lastRecordSize: indexOfFirstJob,
        requestRecordSize: jobsPerPage,
        paginationClick: false,
      };
      dispatch(getAppliedJobPostList(requestProfile));
      if (scroll) window.scrollTo(0, 0);
    }; */

  const getJobPostList = async (curPageSize, scroll) => {
    const requestProfile = {
      acctId: userStatus.acctId,
      email: userStatus.email,
      accessToken: localStorage.getItem("token"),
      lastRecordSize: 0,
      requestRecordSize: curPageSize,//jobsPerPage,

      statusIds: '0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 16 | 17 | 18'
    };
    console.log(' 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 16 | 17 | 18');
    console.log('getSeekerJobPostList');
    console.log(requestProfile);
    dispatch(getSeekerJobPostList(requestProfile));

    if (scroll) window.scrollTo(0, 0);
  };


  /*
    React.useEffect(() => {
      if (notificationJobPostItem > 0) {
        console.log(notificationJobPostItem);
        const requestProfile = {
          acctId: userStatus.acctId,
          email: userStatus.email,
          accessToken: localStorage.getItem("token"),
          applId: notificationJobPostItem,
        };
        dispatch(getAppliedJobPost(requestProfile));
      }
    }, [notificationJobPostItem]);
    */
  /**Sourabh new start */

  /**Sourabh new end */

  React.useEffect(() => {
    if (notificationJobPostItem > 0) {
      if (appliedJobPost) {
        getJobPost(appliedJobPost[0]);
        dispatch(resetNotificationItem());
      } else {
        setNoRecordModal(true);
        dispatch(resetNotificationItem());
      }
      pageResize();
    }
    pageResize();
  }, [appliedJobPost]);

  React.useEffect(() => {
    // pageResize();
    getJobPostList(jobsPerPage);
  }, []);

  const pageResize = () => {
    var timerApplied = setInterval(() => {
      if (document.readyState === "complete") {
        var pageHeader = document.getElementById("pageHeader");
        var appliedCard = document.getElementById("appliedCard");
        if (pageHeader && appliedCard) {
          clearInterval(timerApplied);
          pageHeader.style.minHeight = "468px";
          if (appliedCard.clientHeight <= 450) {
            pageHeader.style.height = "468px";
          } else {
            pageHeader.style.minHeight = "1px";
            pageHeader.style.height = "auto";
          }
        } else {
          setTimeout(() => {
            clearInterval(timerApplied);
          }, 3000);
        }
      }
    }, 50);
    return () => clearInterval(timerApplied);
  };


  /*   const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElNew, setAnchorElNew] = React.useState(null);
    const open = Boolean(anchorEl);
    const open2 = Boolean(anchorElNew);
    const [open1, setOpen] = React.useState(false);
    const [open1Withdraw, setOpenWithdraw] = React.useState(false);
  
    const handleOpen = () => setOpen(true);
  
    const handleClick = (event) => {
      //alert('handleClick');
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    }; 
    const handleOpenWithdraw = () => setOpenWithdraw(true);
    const handleClickWithdraw = (event) => {
      //alert('handleClickWithdraw');
      setAnchorElNew(event.currentTarget);
    };
  
    const handleCloseWithdraw = () => {
      setAnchorElNew(null);
    };
    */


  let totalJobCount = props.appliedJobCounter;


  if (totalJobCount > currentPage) {
    let indexOfLastJobNew = 0;
    let cpage = currentPage;
    let ppage = jobsPerPage;
    /* cpage = parseInt(cpage)+parseInt(jobsPerPage);
    setCurrentPage(cpage);
    console.log('cpage ==========='+cpage);
    indexOfLastJobNew = totalJobCount-currentPage;
    setPendingJobPage(indexOfLastJobNew); */
  }

  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = appliedJobPostList.slice(indexOfFirstJob, indexOfLastJob);



  const moreAppliedJobs = (curcount) => {

    // let newsavesize = pasetInt(currentPage) + pasetInt(5);
    let newsavesize = jobsPerPage + 5;
    console.log('------newsavesize---------' + newsavesize);


    setJobsPerPage(newsavesize);
    getJobPostList(newsavesize);

    //dispatch(getAppliedJobPostList(defaultJobRequest));

  };


  const createPostApplyJobAction = (thisSelectedItem) => {


    setCreatePost(true);

    console.log('index===1');
    console.log(thisSelectedItem);
    console.log('index===1');


    setSelectedJobPost([thisSelectedItem]);
    console.log('index===');
    console.log(selectedJobPost);
    console.log('index===');
  }

  const handleOpenSharePopup = (event) => {

    setCreatePost(true);
    setSelectedPost([event.target.getAttribute("data-item")]);

  }


  const acceptOnClose = () => {
    setApplId();
    setAcceptModal(false);
  };

  const rejectOnClose = () => {
    setApplId();
    setRejectModal(false);
  };


  const return_label_class = (curStatus, name, jobHuntTypeId) => {

    switch (curStatus) {
      case 5:
        return (<span className="Rejected">{name} </span>);
      case 16:
        return (<span className="Rejected">{name} </span>);
      case (curStatus == 0 && jobHuntTypeId == 1):
        return (<span className="headHunted jobOffered">{name} </span>);
      case "completed":
        return (<span className="pending hinterviewing">{name} </span>);
      case "withdraw":
        return (<span className="pending ">{name} </span>);
      case "submitted":
        return (<span className="pending hired">{name} </span>);
      default: return (<span className="pending ">{name} </span>);
    }

  }

  const AppliedJob = appliedJobPostList && appliedJobPostList.map((item, index) => {
    const createPostApplyJobAction1 = (index) => {
      console.log('selectedJobPost');
      console.log(index);

      setCreatePost(true);
      console.log('selectedJobPost==========');
      console.log(AppliedJob[index]);
      setSelectedJobPost([AppliedJob[index]]);
      // console.log(encryptData(favItem.jobPostId));
    };

    const IsolatedAppliedJobMenu = props => {
      const [anchorEl, setAnchorEl] = React.useState(null);
      const open = Boolean(anchorEl);
      //  console.log('props');
      //  console.log(props);
      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };

      const [anchorElNew, setAnchorElNew] = React.useState(null);

      const open2 = Boolean(anchorElNew);
      const [open1, setOpen] = React.useState(false);
      const [open1Withdraw, setOpenWithdraw] = React.useState(false);

      const handleOpen = () => setOpen(true);

      const handleClose = () => {
        setAnchorEl(null);
      };

      const handleOpenWithdraw = () => setOpenWithdraw(true);
      const handleClickWithdraw = (event) => {
        //alert('handleClickWithdraw');
        setAnchorElNew(event.currentTarget);
      };

      const handleCloseWithdraw = () => {
        setAnchorElNew(null);
      };

      //   console.log('props.dataitem '); //applId
      //   console.log(props.dataitem);
      return (<Typography className='basicMenu'>

        {(props.dataitem.applStatusId === 8 || props.dataitem.applStatusId === 5) ? (
          <>
            <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' + props.dataitem.applId : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <i className='fas fa-ellipsis'></i>
            </Button>
            <Menu
              elevation={0}
              id={'basic-menu' + props.dataitem.applId}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem className='menuTitle' data-item={props.dataitem} onClick={() => createPostApplyJobAction(item)} ><i className="fas fa-share-nodes"></i>Share</MenuItem>
            </Menu>
          </>
        ) : (
          <>
            <Button
              id="basic-button1"
              aria-controls={open2 ? 'basic-menu1' : undefined}
              aria-haspopup="true"
              aria-expanded={open2 ? 'true' : undefined}
              onClick={handleClickWithdraw}
            >
              <i className='fas fa-ellipsis'></i>
            </Button>
            <Menu
              id="basic-menu1"
              anchorEl={anchorElNew}
              open={open2}
              onClose={handleCloseWithdraw}
              MenuListProps={{
                'aria-labelledby': 'basic-button1',
              }}
            >
              <MenuItem
                id="tooltip-top"
                title="Withdraw Job Application"
                className='menuTitle'
                onClick={(event) => {
                  event.stopPropagation();
                  setApplId(props.dataitem.applId);
                  setWithdrawModal(true);
                }}
              >
                <i className="fa-regular fa-trash-can"></i>
                Withdraw Application
              </MenuItem>
              <MenuItem className='menuTitle' data-item={props.dataitem} onClick={() => createPostApplyJobAction(item)} ><i className="fas fa-share-nodes"></i>Share</MenuItem>

              {/* <MenuItem className='menuTitle' data-item={props.dataitem} onClick={handleOpenSharePopup}><i className="fas fa-share-nodes"></i>Share</MenuItem>*/}
            </Menu>

          </>
        )}

      </Typography>)
    }

    // if (index < currentPage) {
    return (
      <div key={item.applId} >
        <Typography key={item.applId} component='div' className={` ${item.applStatusId == 0 && (item.jobHuntTypeId == 2) ? "jobInvitation " : " jobInvitation grey "}`}>
          {(item.applStatusId == 0 && item.jobHuntTypeId == 2) ? (
            <>
              <p>You have a new job invitation!</p>
            </>
          ) : (<></>)}
          <Typography component='div' className='inviteJob'>
            <Link to="#" onClick={() => getJobPostModel(item.jobPostId)} >
              <Typography component='div' className='inviteJobLeft'>
                <img width="40px"
                  src={item.profilePic ? item.profilePic : defaultAvatar}
                  alt="..."
                />
              </Typography>
              {(item.jobHuntTypeId != 2) && <Typography component='div' className='inviteJobRight'>
                {return_label_class(item.applStatusId, item.applStatusName, item.jobHuntTypeId)}
              </Typography>}
              {((item.applStatusId == 0 || item.applStatusId == 10 || item.applStatusId == 11) && (item.jobHuntTypeId == 2)) && <Typography component='div' className='inviteJobRight'>
                <span className="headHunted jobOffered">Headhunted Job </span>
              </Typography>}
            </Link>
            <IsolatedAppliedJobMenu applStatusId={item.applStatusId} dataindex={index} dataitem={item} />

          </Typography>

          <Link to="#" onClick={() => getJobPostModel(item.jobPostId)} >
            <Typography component='div' className='AppliedJobTitle designTitle'>{item.title}</Typography>
            <Typography component='div' className='appliedJobSubtitle'>{item.companyName}</Typography>

            <Typography component='div' className='priceJob'>{item.currencyCode} {item.salaryMin} - {item.salaryMax}</Typography>
            <Typography component='div' className='singaporeTitle'>{item.countryName}</Typography>
            <Typography component='div' className='dayOuter'>
              <Typography component='div' className='dayLeft'>Applied {TimeFormat(item.applDate)} </Typography>
              <Typography component='div' className='dayRight'>
                <span>{item.jobPostType}{" "}
                  {/*item.jobPostTypeId === 2
                    ? " - " + item.jobTypeId
                    : null */}</span>
              </Typography>

            </Typography>
          </Link>

          <Typography component='div' className='dayOuter btnOuter'>

            {(item.applStatusId == 0 && item.jobHuntTypeId == 1) ? (
              <>
                <Typography component='div' className='dayLeft'>
                  <Button
                    className='applyBtn'
                    onClick={() => getJobPostModel(item.jobPostId)}
                  // onClick={(event) => {
                  //   event.stopPropagation();
                  //   setApplId(item.applId);
                  //   setAcceptModal(true);
                  // }}
                  >Apply</Button>
                </Typography>
                <Typography component='div' className='dayRight'>
                  <Button
                    className='rejectBtngrey'
                    onClick={(event) => {
                      event.stopPropagation();
                      setApplId(item.applId);
                      setRejectModal(true);
                    }}
                  >Reject</Button>
                </Typography>
              </>
            ) : (<></>)}

            {((item.applStatusId == 0 || item.applStatusId == 10 || item.applStatusId == 11) && (item.jobHuntTypeId == 2)) ? (
              <>
                <Typography component='div' className='dayLeft11'>
                  <Link to={{
                    pathname: `/chatbot-landing/${encryptData(userStatus.acctId)}/0/${item.applId}`
                  }}>
                    <Button
                      className='applyBtn'
                    >Speak to Jarvis</Button>
                  </Link>
                </Typography>
              </>
            ) : (<></>)}
          </Typography>

        </Typography>

      </div>
    );
    // }
  });

  const AppliedJobMore = appliedJobPostList && appliedJobPostList.length > 0 && totalJobCount > jobsPerPage ? (

    <Button onClick={() => moreAppliedJobs(currentPage)} className='moreJobs'>{totalJobCount - jobsPerPage} more jobs</Button>
  ) : (
    ''
  );

  const NoAppliedJobPost = () => {
    return (
      <div >
        <Typography component='div'>
          <h5 >
            You haven't applied any jobs.
          </h5>
        </Typography>
        <Typography component='div'>
          <Link

            to={{
              pathname: "/search-job-page",
            }}
            color="transparent"
          >
            <Button>
              <b>BROWSE JOB BOARD</b>
            </Button>
          </Link>
        </Typography>
      </div>
    );
  };
  const LoadingJob = () => {
    return (

      <Typography component='div' className={classes.cardFrame}>
        <img
          src={LoadingImage}

          alt="Expired Session"
        />
      </Typography>

    );
  };

  return (
    <div>
      {appliedJobPostList.length > 0 ? (
        <>
          {AppliedJob}
          {AppliedJobMore}
        </>
      ) : isLoading ? (
        <LoadingJob />
      ) : (
        <NoAppliedJobPost />
      )}

      {isLoadingJob && <Backdrop />}
      {jobViewModal && (
        <ResultJobModal
          open={jobViewModal}
          onClose={onClose}
          jobPostId={jobPostId}
          clientIp={clientIp}
        />
      )}
      {noRecordModal && (
        <NoRecordModal
          open={noRecordModal}
          onClose={noRecordOnClose}
          recordType={"Job Post"}
        />
      )}

      {acceptModal && (
        <AcceptAppliedModal
          open={acceptModal}
          onClose={acceptOnClose}
          applId={applId}
        />
      )}

      {rejectModal && (
        <RejectAppliedModal
          open={rejectModal}
          onClose={rejectOnClose}
          applId={applId}
        />
      )}

      {withdrawModal && (
        <WithdrawlModal
          open={withdrawModal}
          onClose={withdrawOnClose}
          applId={applId}
        />
      )}
      {createPost ? (
        <CreatePostModal
          isOpen={createPost}
          setPostsList={null}
          user={userStatus}
          oriVisibleOptions={null}
          oriSocialPostTypeOptions={null}
          oriCommentabilityOptions={null}
          statusChangeHandler={statusChangeHandler}
          handleCreatePostClick={null}
          question={null}
          options={null}
          pollTiming={null}
          removePoll={null}
          savePostData={null}
          selectPhotos={null}
          selectVideos={null}
          uploadedPhotos={[]}
          uploadedVideos={[]}
          removeAnUploadedPhoto={null}
          removeAnUploadedVideo={null}
          onPhotosListItemClick={null}
          onVideosListItemClick={null}
          onPhotosListItemDragStart={null}
          onPhotosListItemDrop={null}
          onVideosListItemDragStart={null}
          onVideosListItemDrop={null}
          isLoading={null}
          setError={null}
          editPost={null}
          sharePost={null}
          shareJob={selectedJobPost}
          groupId={0}
          postStatus={1}
        />
      ) : null}


      {jobViewModal ? <ResultJobModal open={jobViewModal} onClose={onJobModelClose} /> : null}
    </div>
  );
}
