import React from "react";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { makeStyles } from "@material-ui/core/styles";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js";
import IconLibrary from "views/Component/IconLibrary.js";

const useStyles = makeStyles(profilePageStyle);
{
	/* <GridContainer className={classes.tipTextSection}>
						<GridItem xs={1} sm={1} md={1} className={classes.tipIconGrid}>
							<IconLibrary.Lightbulb />
						</GridItem>
						<GridItem xs={10} sm={10} md={11} className={classes.tipTextGrid}>
							<p className={classes.tipHeadline}>Oppty Tips</p>

							<p>
								70% of employers check on the social media profiles of
								candidates, and 54% of them have rejected candidates based on
								what they’ve found. While you may keep your work and personal
								life separate, remember that the public information you share on
								social media could affect your chances of getting that dream
								job.
							</p>
						</GridItem>
					</GridContainer> */
}

export default function UserTipSection({ randomNumber, className }) {
	/* const useStyles = makeStyles({
		textDeco: {
			textAlign: "center",
			fontSize: "18px",
			fontWeight: "500",
		}, 
	}); */
	const classes = useStyles();
	const RandomToolTip = (randomNumber) => {
		/* return ( */
		/* 	<SnackbarContent
				message={
					<div className={classes.tipTextSection}>
						<IconLibrary.Lightbulb />

						<div className={classes.tipTextContainer}>
							<p className={classes.tipHeadline}>Oppty Tips</p>

							<p className={classes.tipText}>
								The colour black is associated with authority, sophistication,
								and exclusivity. Many businesses have used this hue in their
								branding to show that they are the best in their field. Because
								this is a powerful colour, it’s a go-to for high-powered
								interviews, such as those for top jobs or management positions.
							</p>
						</div>
					</div>
				}
				close
				color="opptyTip"
				size="userTip"
				className={className}
			/>
		); */
		if (randomNumber === 1) {
			return (
				<SnackbarContent
					message={
						<div className={classes.tipTextSection}>
							<IconLibrary.Lightbulb />
							<div className={classes.tipTextContainer}>
								<p className={classes.tipHeadline}>Oppty Tips</p>

								<p className={classes.tipText}>
									70% of employers check on the social media profiles of
									candidates, and 54% of them have rejected candidates based on
									what they’ve found. While you may keep your work and personal
									life separate, remember that the public information you share
									on social media could affect your chances of getting that
									dream job.
								</p>
							</div>
							{/* <b>Oppty Tips</b>
							<p>
								
							</p> */}
						</div>
					}
					close
					color="opptyTip"
					size="userTip"
					className={className}
				/>
			);
		} else if (randomNumber === 2) {
			return (
				<SnackbarContent
					message={
						<div className={classes.tipTextSection}>
							<IconLibrary.Lightbulb />
							<div className={classes.tipTextContainer}>
								<p className={classes.tipHeadline}>Oppty Tips</p>

								<p className={classes.tipText}>
									A well-written résumé increases your earning potential by 7%.
									Make sure to use OppTy’s CV Creation & Advice features to help
									you land not just the job, but the salary you deserve!
								</p>
							</div>
							{/* <b>Oppty Tips</b>
							<p>
								
							</p> */}
						</div>
					}
					close
					color="opptyTip"
					size="userTip"
					className={className}
				/>
			);
		} else if (randomNumber === 3) {
			return (
				<SnackbarContent
					message={
						<div className={classes.tipTextSection}>
							<IconLibrary.Lightbulb />
							<div className={classes.tipTextContainer}>
								<p className={classes.tipHeadline}>Oppty Tips</p>

								<p className={classes.tipText}>
									Your “thank you” could be the deciding factor. When pitted
									against a pool of candidates with similar skills, a sincere
									and thoughtful thank you note after each interview can make
									you stand out from the competition.
								</p>
							</div>
						</div>
					}
					close
					color="opptyTip"
					size="userTip"
					className={className}
				/>
			);
		} else if (randomNumber === 4) {
			return (
				<SnackbarContent
					message={
						<div className={classes.tipTextSection}>
							<IconLibrary.Lightbulb />
							<div className={classes.tipTextContainer}>
								<p className={classes.tipHeadline}>Oppty Tips</p>

								<p className={classes.tipText}>
									At least 60% of jobs are discovered through networking, thus
									you cannot afford to disregard the value of meeting people
									through the right avenues. OppTy’s AI-based algorithms help
									you become more strategic about your connections.
								</p>
							</div>

							{/* <b>Oppty Tips</b>
							<p>
							
							</p> */}
						</div>
					}
					close
					color="opptyTip"
					size="userTip"
					className={className}
				/>
			);
		} else if (randomNumber === 5) {
			return (
				<SnackbarContent
					message={
						<div className={classes.tipTextSection}>
							<IconLibrary.Lightbulb />
							<div className={classes.tipTextContainer}>
								<p className={classes.tipHeadline}>Oppty Tips</p>

								<p className={classes.tipText}>
									It's now more important than ever to show that you can work in
									a virtual environment. With the rise of remote work, job
									applicants must demonstrate how they can be responsive,
									productive, and cooperative in a tech-savvy workplace.
								</p>
							</div>
						</div>
					}
					close
					color="opptyTip"
					size="userTip"
					className={className}
				/>
			);
		} else if (randomNumber === 6) {
			return (
				<SnackbarContent
					message={
						<div className={classes.tipTextSection}>
							<IconLibrary.Lightbulb />
							<div className={classes.tipTextContainer}>
								<p className={classes.tipHeadline}>Oppty Tips</p>

								<p className={classes.tipText}>
									Typos are a resume killer. Take the time to read your resume
									from top to bottom, making sure to focus on each line, or get
									a fresh set of eyes to read it for you. You can also use free
									tools such as Grammarly to check for grammar, spelling, and
									punctuation errors.
								</p>
							</div>
						</div>
					}
					close
					color="opptyTip"
					size="userTip"
					className={className}
				/>
			);
		} else if (randomNumber === 7) {
			return (
				<SnackbarContent
					message={
						<div className={classes.tipTextSection}>
							<IconLibrary.Lightbulb />
							<div className={classes.tipTextContainer}>
								<p className={classes.tipHeadline}>Oppty Tips</p>

								<p className={classes.tipText}>
									For your interview, prepare your questions, too! Round up some
									sensible questions for the interviewer that indicate your
									familiarity with the company and your serious intent about the
									job opening. Start with something along the lines of “What do
									the role's day-to-day responsibilities entail?”
								</p>
							</div>
						</div>
					}
					close
					color="opptyTip"
					size="userTip"
					className={className}
				/>
			);
		} else if (randomNumber === 8) {
			return (
				<SnackbarContent
					message={
						<div className={classes.tipTextSection}>
							<IconLibrary.Lightbulb />
							<div className={classes.tipTextContainer}>
								<p className={classes.tipHeadline}>Oppty Tips</p>

								<p className={classes.tipText}>
									First impressions DO count! According to some research,
									interviewers make up their minds about candidates in the first
									5 minutes of the interview, then spend the rest of the time
									hunting for evidence to back up their choice! Make sure that
									you open the interview with enthusiasm and a positive
									greeting.
								</p>
							</div>
						</div>
					}
					close
					color="opptyTip"
					size="userTip"
					className={className}
				/>
			);
		} else if (randomNumber === 9) {
			return (
				<SnackbarContent
					message={
						<div className={classes.tipTextSection}>
							<IconLibrary.Lightbulb />
							<div className={classes.tipTextContainer}>
								<p className={classes.tipHeadline}>Oppty Tips</p>

								<p className={classes.tipText}>
									The colour black is associated with authority, sophistication,
									and exclusivity. Many businesses have used this hue in their
									branding to show that they are the best in their field.
									Because this is a powerful colour, it’s a go-to for
									high-powered interviews, such as those for top jobs or
									management positions.
								</p>
							</div>
						</div>
					}
					close
					color="opptyTip"
					size="userTip"
					className={className}
				/>
			);
		} else if (randomNumber === 10) {
			return (
				<SnackbarContent
					message={
						<div className={classes.tipTextSection}>
							<IconLibrary.Lightbulb />
							<div className={classes.tipTextContainer}>
								<p className={classes.tipHeadline}>Oppty Tips</p>

								<p className={classes.tipText}>
									Be prepared to talk about behaviour. It's now quite common for
									interviewers to ask candidates to discuss experiences they
									have had that reflect behaviours that the organization
									believes are relevant for a specific role. This can be an
									instance when you took an unpopular course of action,
									demonstrated persistence, or made a decision under time
									constraints and with minimal information.
								</p>
							</div>
						</div>
					}
					close
					color="opptyTip"
					size="userTip"
					className={className}
				/>
			);
		} else if (randomNumber === 11) {
			return (
				<SnackbarContent
					message={
						<div className={classes.tipTextSection}>
							<IconLibrary.Lightbulb />
							<div className={classes.tipTextContainer}>
								<p className={classes.tipHeadline}>Oppty Tips</p>

								<p className={classes.tipText}>
									There is such a thing as overdoing your smile during
									interviews. To ensure that your smile comes off friendly but
									still confident, try practising with a friend and ask for
									feedback.
								</p>
							</div>
						</div>
					}
					close
					color="opptyTip"
					size="userTip"
					className={className}
				/>
			);
		} else if (randomNumber === 12) {
			return (
				<SnackbarContent
					message={
						<div className={classes.tipTextSection}>
							<IconLibrary.Lightbulb />
							<div className={classes.tipTextContainer}>
								<p className={classes.tipHeadline}>Oppty Tips</p>

								<p className={classes.tipText}>
									Keep the best stuff "Above the Fold". It may be a marketing
									practice, but in terms of your resume, this means that your
									best experiences and accomplishments should be visible on the
									top third of your CV. This top piece is what the hiring
									manager will notice first—and what will entice them to
									continue reading.
								</p>
							</div>
						</div>
					}
					close
					color="opptyTip"
					size="userTip"
					className={className}
				/>
			);
		} else if (randomNumber === 13) {
			return (
				<SnackbarContent
					message={
						<div className={classes.tipTextSection}>
							<IconLibrary.Lightbulb />
							<div className={classes.tipTextContainer}>
								<p className={classes.tipHeadline}>Oppty Tips</p>

								<p className={classes.tipText}>
									The good old reverse chronological resume is still your best
									bet. List your most recent experience first, it's still the
									most common, credible, and practical resume format.
								</p>
							</div>
						</div>
					}
					close
					color="opptyTip"
					size="userTip"
					className={className}
				/>
			);
		} else if (randomNumber === 14) {
			return (
				<SnackbarContent
					message={
						<div className={classes.tipTextSection}>
							<IconLibrary.Lightbulb />
							<div className={classes.tipTextContainer}>
								<p className={classes.tipHeadline}>Oppty Tips</p>

								<p className={classes.tipText}>
									If you're just starting out, don't worry too much about the
									experience. Focus your CV on your relevant and transferrable
									skills, academic endeavours, and support it with a cover
									letter that explains why you're excellent for the job.
								</p>
							</div>
						</div>
					}
					close
					color="opptyTip"
					size="userTip"
					className={className}
				/>
			);
		} else if (randomNumber === 15) {
			return (
				<SnackbarContent
					message={
						<div className={classes.tipTextSection}>
							<IconLibrary.Lightbulb />
							<div className={classes.tipTextContainer}>
								<p className={classes.tipHeadline}>Oppty Tips</p>

								<p className={classes.tipText}>
									Save your resume as a PDF. This way, none of your formattings
									will be lost when the hiring manager accesses it on their
									computer.
								</p>
							</div>
						</div>
					}
					close
					color="opptyTip"
					size="userTip"
					className={className}
				/>
			);
		} else if (randomNumber === 16) {
			return (
				<SnackbarContent
					message={
						<div className={classes.tipTextSection}>
							<IconLibrary.Lightbulb />
							<div className={classes.tipTextContainer}>
								<p className={classes.tipHeadline}>Oppty Tips</p>

								<p className={classes.tipText}>
									8 out of 10 recruiters use references to screen their
									candidates, so don’t leave out this important detail from your
									resume!
								</p>
							</div>
						</div>
					}
					close
					color="opptyTip"
					size="userTip"
					className={className}
				/>
			);
		} else if (randomNumber === 17) {
			return (
				<SnackbarContent
					message={
						<div className={classes.tipTextSection}>
							<IconLibrary.Lightbulb />
							<div className={classes.tipTextContainer}>
								<p className={classes.tipHeadline}>Oppty Tips</p>

								<p className={classes.tipText}>
									Eye contact? Remember the 50/70 rule! Ensure eye contact 50%
									of the time when speaking and 70% of the time when listening.
									This helps you balance the amount of time for eye contact and
									leave a positive impression on your interviewer.
								</p>
							</div>
						</div>
					}
					close
					color="opptyTip"
					size="userTip"
					className={className}
				/>
			);
		} else if (randomNumber === 18) {
			return (
				<SnackbarContent
					message={
						<div className={classes.tipTextSection}>
							<IconLibrary.Lightbulb />
							<div className={classes.tipTextContainer}>
								<p className={classes.tipHeadline}>Oppty Tips</p>

								<p className={classes.tipText}>
									Let them know they can count on you! According to studies,
									hiring managers rate intelligence as the least important
									characteristic in a candidate and reliability as the most
									important.
								</p>
							</div>
						</div>
					}
					close
					color="opptyTip"
					size="userTip"
					className={className}
				/>
			);
		} else if (randomNumber === 19) {
			return (
				<SnackbarContent
					message={
						<div className={classes.tipTextSection}>
							<IconLibrary.Lightbulb />
							<div className={classes.tipTextContainer}>
								<p className={classes.tipHeadline}>Oppty Tips</p>

								<p className={classes.tipText}>
									Did you know that a typical job search lasts 16.9 weeks? By
									signing up with OppTy, you get help from an AI-based platform
									providing talent management services to produce suitable and
									tangible results.
								</p>
							</div>
						</div>
					}
					close
					color="opptyTip"
					size="userTip"
					className={className}
				/>
			);
		} else if (randomNumber === 20) {
			return (
				<SnackbarContent
					message={
						<div className={classes.tipTextSection}>
							<IconLibrary.Lightbulb />
							<div className={classes.tipTextContainer}>
								<p className={classes.tipHeadline}>Oppty Tips</p>

								<p className={classes.tipText}>
									Choose your email wisely! A study found that an unprofessional
									email address is a deal-breaker for 46% of hiring managers.
								</p>
							</div>
						</div>
					}
					close
					color="opptyTip"
					size="userTip"
					className={className}
				/>
			);
		} else {
			return null;
		}
	};
	return <div>{RandomToolTip(randomNumber)}</div>;
}
