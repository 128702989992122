import { grayColor } from "assets/jss/material-kit-pro-react.js";

const imageCropStyle = (theme) => ({
	cropContainer: {
		position: "relative",
		width: "100%",
		height: 200,
		background: "#333",
		[theme.breakpoints.up("sm")]: {
			height: 350,
		},
	},
	cropButton: {
		flexShrink: 0,
		marginLeft: 16,
	},
	controls: {
		padding: 16,
		display: "flex",
		flexDirection: "column",
		alignItems: "stretch",
		[theme.breakpoints.up("sm")]: {
			flexDirection: "row",
			alignItems: "center",
		},
	},
	sliderContainer: {
		display: "flex",
		flex: "1",
		alignItems: "center",
	},
	sliderLabel: {
		[theme.breakpoints.down("xs")]: {
			minWidth: 65,
		},
	},
	slider: {
		padding: "22px 0px",
		marginLeft: 16,
		[theme.breakpoints.up("sm")]: {
			flexDirection: "row",
			alignItems: "center",
			margin: "0 16px",
		},
	},
	gridControl: {
		marginTop: "20px",
	},
	gridButton: {
		marginTop: "16px !important",
	},
	gridDescription: {
		marginBottom: "15px !important",
	},
	pictureButtonFooter: {
		justifyContent: "flex-end",
		display: "flex",

		"& button": {
			marginLeft: "8px",
		},
	},
	mediaHeight: {
		height: "35vh",
	},
});

export default imageCropStyle;
