/* eslint-disable react/prop-types */
import React, { Fragment, useState, useEffect, useRef, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

import Comment from '../../../Component/SocialFeed/Comment.js';
import ReportModal from '../../ReportModal.js';
import defaultAvatar from 'assets/img/candidate_avatar.jpeg';
import hashtagImg from 'assets/img/hashtag.png';
import comment from 'assets/images/comment.png';
import clapIcon from 'assets/img/icons/clap.png';
import happyIcon from 'assets/img/icons/happy.png';
import loveIcon from 'assets/img/icons/love.png';
import likeIcon from 'assets/img/icons/like.png';
import sadIcon from 'assets/img/icons/sad.png';
import angryIcon from 'assets/img/icons/angry.png';

import axios from 'axios';

import {
  Typography,
  makeStyles,
  Button,
  Input,
  IconButton,
  Popover,
  Popper,
  Grow,
  ClickAwayListener,
  Paper,
  Modal,
  Box,
  Grid,
  CardHeader,
  Avatar,
  Card,
  CardContent,
  Divider,
} from '@material-ui/core';

import { ReactComponent as HandClapIcon } from 'assets/svg/clapping.svg';
import { ReactComponent as SendIcon } from 'assets/svg/send-svgrepo-com.svg';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import MoodBadOutlinedIcon from '@mui/icons-material/MoodBadOutlined';
import SentimentVerySatisfiedOutlinedIcon from '@mui/icons-material/SentimentVerySatisfiedOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import cross from 'assets/img/cross.png';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

import {
  header,
  handleFollow,
  handleUnfollow,
  // createHidePost,
} from 'configurations/configurations.js';

import dotBtn from 'assets/images/dotBtn.png';
import leftArrow from 'assets/img/arrow-left.png';
import rightArrow from 'assets/img/arrow-right.png';

import like from 'assets/images/like.png';
import commentIcon from 'assets/images/comment.png';
import share from 'assets/images/share.png';
import saved from 'assets/images/saved.png';
import commentStyle from 'assets/jss/material-kit-pro-react/views/componentStyle/socialFeed/commentStyles';
import profileViewActivityCardStyles from 'assets/jss/material-kit-pro-react/views/componentStyle/profileView/myActivity/profileViewActivityCardStyles.js';
import profileViewActivityPollCardStyles from '../../../../assets/jss/material-kit-pro-react/views/componentStyle/profileView/myActivity/profileViewActivityPollCardStyles.js';
import profileViewActivityPostCardStyles from '../../../../assets/jss/material-kit-pro-react/views/componentStyle/profileView/myActivity/profileViewActivityPostCardStyles.js';
import { getProfiles } from 'configurations/redux/userSlice.js';
import {
  getSocialCommentByPostId,
  createSocialComment,
  createSocialReaction,
  deleteSocialReaction,
  createSocialHashtag,
  resetCreatedHashtag,
  resetPostComment,
  createPollResult,
  deletePollResult,
  getPostReactionCount,
  getSocialPostPopup,
  createSavePost,
  deleteSavePost,
  resetPostCreate,
  deleteSocialPost,
  createSocialPost,
  createFollowHashtag,
  deleteFollowHashtag,
  createHidePost,
  createFollowProfile,
  deleteFollowProfile,
  resetHidePost,
} from 'configurations/redux/socialFeedSlice.js';
import { createReport, resetReport } from 'configurations/redux/reportSlice.js';
import CommentSkeleton from '../../SocialFeed/CommentSkeleton.js';
import GridItem from '../../../../components/Grid/GridItem.js';
import GridContainer from '../../../../components/Grid/GridContainer.js';
import Picker from 'emoji-picker-react';
import { isMobile, isMobileOnly } from 'react-device-detect';
import CommentImageModal from '../../SocialFeed/CommentImageModal.js';
import {
  instanceUrl,
  encryptData,
  dataURLtoFile,
  TimeFormatUniqueId,
  TimeFormatSocialFeed,
  TimeFormatPollLeft,
  TimeFormatPollConvert,
  getSocialReaction,
} from 'configurations/configurations.js';
import 'quill-mention';
import 'react-quill/dist/quill.bubble.css';
import ReactQuill, { Quill } from 'react-quill';
import Countdown from 'react-countdown';
import PollResultUserListModal from './PollResultUserListModal.js';
import CommentInput from '../../SocialFeed/CommentInput.js';
import ReactionUserListModal from '../../SocialFeed/ReactionUserListModal';
import PostMediaModal from './PostMediaModal.js';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSnackbar } from 'notistack';
import PostVideoList from './PostVideoList.js';
import CustomDialog from '../../../../components/CustomDialog/CustomDialog.js';
import shareLink from 'assets/img/shareLink.jpg';
import { ReactTinyLink } from 'react-tiny-link';
import ShortcutOutlinedIcon from '@mui/icons-material/ShortcutOutlined';
import JobCard from '../../../Page/SearchPage/SearchJobPage/JobCard';
import PublicMediaModal from './PublicMediaModal.js';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import clsx from 'clsx';

const ProfileViewActivityCard = ({
  post,
  reportTypesList,
  reportReasonsList,
  updateFeedHandler,
  isCopyPost,
  handleEditPostClick,
  hashtagMatch,
  hideReactionBar,
  handleSharePostClick,
  searchPost,
  isGroupMember,
  postStatus,
  isInGroup,
}) => {
  const classes = makeStyles(commentStyle)();
  const styles = makeStyles(profileViewActivityCardStyles)();
  const stylesPoll = makeStyles(profileViewActivityPollCardStyles)();
  const stylesPost = makeStyles(profileViewActivityPostCardStyles)();
  const user = useSelector(state => state.auth.user);
  const dispatch = useDispatch();
  const history = useHistory();

  const quillRef = React.useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElHashtag, setAnchorElHashtag] = useState(null);
  const [anchorElEmoji, setAnchorElEmoji] = useState(null);
  const anchorRef = React.useRef(null);
  const [showReactionPaper, setShowReactionPaper] = useState(false);
  const [anchorShareEl, setAnchorShareEl] = useState(null);
  const [isPostSaved, setIsPostSaved] = useState(false);
  // const [saveId, setSaveId] = useState(null);
  const [commentsExpanded, setCommentState] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [showComment, setShowComment] = useState(true);
  const searchResult = useSelector(state => state.socialFeed.searchResult);
  const postResult = useSelector(state => state.socialFeed.postResult);

  const userStatus = useSelector(state => state.auth.user);
  const postUser = useSelector(state => state.user);
  const isOnAmend = useSelector(state => state.socialFeed.isOnAmend);
  const isCreating = useSelector(state => state.socialFeed.isCreating);
  const amendPostId = useSelector(state => state.socialFeed.amendPostId);
  const [skeletonShow, setSkeletonShow] = React.useState(false);
  const isEndComment = useSelector(state => state.socialFeed.isEndComment);
  const isLoadingComment = useSelector(state => state.socialFeed.isLoadingComment);
  const likeId = useSelector(state => state.socialFeed.likeId);
  const [likeColor, setLikeColor] = React.useState('#92929d');
  const [reactionCount, setReactionCount] = React.useState(post && post.reactionCount);
  const [likeShow, setLikeShow] = React.useState(
    post && post.ownerLike ? post.ownerLike.socialReactionUserList.length : 0
  );
  const [reactionId, setReactionId] = React.useState(
    post && post.ownerLike
      ? post.ownerLike.socialReactionUserList.length > 0
        ? post.ownerLike.socialReactionUserList[0].reactionId
        : null
      : null
  );
  const saveId = useSelector(state => state.socialFeed.saveId);
  const hideId = useSelector(state => state.socialFeed.hideId);
  const [saveColor, setSaveColor] = React.useState('#92929d');
  const [saveCount, setSaveCount] = React.useState(post && post.saveCount);

  let fileInput = React.createRef();
  const [commentImageModal, setCommentImageModal] = React.useState(false);
  const [commentImage, setCommentImage] = React.useState(null);
  const [commentImageSaved, setCommentImageSaved] = React.useState(null);
  const [commentImageIsEdit, setCommentImageIsEdit] = React.useState(false);
  const [fileName, setFileName] = React.useState(null);

  const hashtagList = useSelector(state => state.socialFeed.hashtagList);
  const profileTagList = useSelector(state => state.socialFeed.profileTagList);
  const hashtagKeyword = useSelector(state => state.socialFeed.hashtagKeyword);
  const profileTagKeyword = useSelector(state => state.socialFeed.profileTagKeyword);
  const hashValue = useSelector(state => state.socialFeed.hashValue);
  const createHashtagPostId = useSelector(state => state.socialFeed.createHashtagPostId);
  const enterKey = useSelector(state => state.socialFeed.enterKey);
  const lastOpenCommentPostId = useSelector(state => state.socialFeed.lastOpenCommentPostId);
  const [reportSuccessResponse, setReportSuccessResponse] = useState(null);
  const [optionClicked, setOptionClicked] = useState(-1);
  const [poll, setPoll] = useState(null);
  const poolOptionId = useSelector(state => state.socialFeed.poolOptionId);
  const poolResultId = useSelector(state => state.socialFeed.poolResultId);
  const poolOptionList = useSelector(state => state.socialFeed.poolOptionList);
  const isChosenOption = useSelector(state => state.socialFeed.isChosenOption);
  const pollOptionPostId = useSelector(state => state.socialFeed.pollOptionPostId);
  const [poolResultUserListModal, setPoolResultUserListModal] = React.useState(false);
  const [commentWordNumber, setCommentWordNumber] = React.useState(null);
  const [commentText, setCommentText] = React.useState(null);
  const [commentContent, setCommentContent] = React.useState(null);
  const [commentHTML, setCommentHTML] = React.useState(null);
  const [emojiChoose, setEmojiChoose] = React.useState(null);
  const [submitComment, setSubmitComment] = React.useState(false);
  const [reactionUserListModal, setReactionUserListModal] = React.useState(false);
  const [reactionList, setReactionList] = React.useState(null);
  const [previewAttachmentIndex, setPreviewAttachmentIndex] = useState(0);
  const [isViewAttachmentModalOpen, setIsViewAttachmentModalOpen] = useState(false);
  const [submitCommentId, setSubmitCommentId] = React.useState(null);
  const isLoadingSocialPost = useSelector(state => state.socialFeed.isLoadingSocialPost);
  const isCreatedPost = useSelector(state => state.socialFeed.isCreatedPost);
  const isDeletedPost = useSelector(state => state.socialFeed.isDeletedPost);
  const isUpdatedPost = useSelector(state => state.socialFeed.isUpdatedPost);
  const createdPostId = useSelector(state => state.socialFeed.createdPostId);
  const deletedPostId = useSelector(state => state.socialFeed.deletedPostId);
  const updatedPostId = useSelector(state => state.socialFeed.updatedPostId);
  const newPostStatus = useSelector(state => state.socialFeed.postStatus);
  const newFollowHashtagId = useSelector(state => state.socialFeed.followProfileId);
  const [followCount, setFollowCount] = React.useState(hashtagMatch ? hashtagMatch.followCount : 0);
  const [followHashtagId, setFollowHashtagId] = React.useState(hashtagMatch && hashtagMatch.followId);
  const newFollowProfileId = useSelector(state => state.socialFeed.followProfileId);
  const [followProfileId, setFollowProfileId] = React.useState(post && post.followId);
  const [followProfileColor, setFollowProfileColor] = React.useState('#92929d');
  const [addLink, setAddLink] = useState(null);

  const [copied, setCopied] = React.useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const dialogDefault = {
    title: '',
    description: '',
    buttonYes: '',
    buttonNo: '',
  };
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    dialogDefault,
  });

  const fetchSocialReactionList = async () => {
    const request = {
      email: user.email,
      acctId: user.acctId,
      accessToken: localStorage.getItem('token'),
      postId: post.postId,
      firstLoad: true,
    };
    console.log(request);
    dispatch(getPostReactionCount(request));
    const res = await getSocialReaction();
    setReactionList(res.data.payload);
  };

  useEffect(() => {
    // console.log(reactionList);
    if (reactionList) setReactionUserListModal(true);
  }, [reactionList]);

  // useEffect(() => {
  // 	console.log(pollOptionPostId, post && post.postId, poolOptionList);
  // }, [pollOptionPostId]);

  useEffect(() => {
    console.log('post status', isCreatedPost, createdPostId, newPostStatus);
    if (isCreatedPost && createdPostId > 0) {
      if (newPostStatus && newPostStatus > 0) snackbarCustom('Your content has been successfully posted!');
      else snackbarCustom('Your post has been submitted and is currently pending approval from the group admin.');
      dispatch(resetPostCreate());
    }
    if (isUpdatedPost && updatedPostId > 0) {
      if (newPostStatus && newPostStatus > 0) snackbarCustom('Your content has been successfully updated');
      else snackbarCustom('Your post has been updated and is currently pending approval from the group admin.');
      dispatch(resetPostCreate());
    }
    if (isDeletedPost && deletedPostId > 0) {
      snackbarCustom('Your content has been successfully deleted');
      dispatch(resetPostCreate());
    }
  }, [isLoadingSocialPost]);

  useEffect(() => {
    if (submitComment) {
      postComment(0);
      setSubmitComment(false);
    }
  }, [submitComment]);

  const handleImageClick = () => {
    fileInput.current.value = null;
    fileInput.current.click();
  };

  const onEmojiClick = (event, emojiObject) => {
    if (emojiObject.emoji != '🦫') setEmojiChoose(emojiObject.emoji);
    // quillRef.current.editor.insertText(
    // 	quillRef.current.editor.getLength() - 1,
    // 	emojiObject.emoji,
    // 	"true"
    // );
  };

  useEffect(() => {
    // console.log(post);
    if (post) {
      if (post.ownerLike && post.ownerLike.socialReactionUserList.length) setLikeColor('#6F52ED');
      if (post.saveCount > 0) setSaveColor('#6F52ED');
      if (post.followStatus > 0) setFollowProfileColor('#6F52ED');
      // var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
      // var url = post.description.match(urlRegex)[1];
      // console.log(url);

      var rawHTML = post.description;

      var doc = document.createElement('html');
      doc.innerHTML = rawHTML;
      var links = doc.getElementsByTagName('a');
      var urls = [];

      for (var i = 0; i < links.length; i++) {
        if (links[i].getAttribute('rel') == 'noopener noreferrer') urls.push(links[i].getAttribute('href'));
      }
      if (urls.length > 0) {
        setAddLink(urls[0]);
      }
    }
  }, [post]);

  useEffect(() => {
    if (!isEndComment) setCommentState(true);
  }, [isEndComment]);

  const collapseComment = () => {
    var elmnt = document.getElementById('socialPost_' + post.postId);
    if (elmnt != null) {
      elmnt.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      setTimeout(() => {
        // setCommentState(false);
        setShowComment(false);
      }, 500);
    }
  };

  const reportPost = (reportTypeId, reasonId, reportDesc, recordId) => {
    var requestProfile = {
      email: user.email,
      acctId: user.acctId,
      reportTypeId: reportTypeId,
      recordId: recordId,
      reasonId: reasonId,
      reportDesc: reportDesc,
      userId: reportTypeId == 2 ? post.postId : null,
      accessToken: localStorage.getItem('token'),
    };
    dispatch(createReport(requestProfile));
  };

  const getComments = lastRecordSize => {
    if (lastRecordSize > 0) dispatch(resetPostComment({ postId: null }));
    var requestProfile = {
      email: user.email,
      acctId: user.acctId,
      postId: post.postId,
      lastRecordSize: lastRecordSize,
      requestRecordSize: 5,
      accessToken: localStorage.getItem('token'),
      postList: isCopyPost ? postResult : searchResult,
      isCopyPost: isCopyPost,
    };
    console.log(requestProfile);
    dispatch(getSocialCommentByPostId(requestProfile));
  };

  const storeNewHashTag = (name, enterKey) => {
    const requestProfile = {
      email: user.email,
      acctId: user.acctId,
      hashtagName: name,
      accessToken: localStorage.getItem('token'),
      commentId: null,
      postId: post.postId,
      enterKey: enterKey,
    };
    dispatch(createSocialHashtag(requestProfile));
  };

  React.useEffect(() => {
    if (copied) {
      setCopied(false);
      const action = key => (
        <React.Fragment>
          <IconButton onClick={onClickDismiss(key)}>
            <CloseIcon />
          </IconButton>
        </React.Fragment>
      );
      snackbarCustom('The share link has been copy into clipboard, kindly paste and share.');
    }
  }, [copied]);

  const snackBarShow = (message, variant, action) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, {
      variant: variant,
      action,
    });
  };
  const onClickDismiss = key => () => {
    closeSnackbar(key);
  };

  useEffect(() => {
    let mounted = true;
    var loadingTimeout = null;
    if (mounted) {
      if (!hashtagMatch && (isOnAmend || isCreating) && amendPostId == post.postId) setSkeletonShow(true);
      else {
        loadingTimeout = setTimeout(() => {
          setSkeletonShow(false);
        }, 1000);
      }
    }
    return () => {
      mounted = false;
      clearTimeout(loadingTimeout);
    };
  }, [isCreating, isOnAmend]);

  useEffect(() => {
    if (post && createHashtagPostId == post.postId && quillRef.current) {
      if (hashValue) {
        resetCreatedHashtag();
      }
      var quillEditor = null;
      var quillContent = null;
      quillEditor = quillRef.current;
      quillContent = quillRef.current.editor.getContents();
      if (quillEditor) {
        for (let i = 0; i < quillContent.ops.length; i++) {
          let insert = quillContent.ops[i].insert;
          try {
            console.log(insert.mention.denotationChar);
          } catch (e) {
            if (insert) {
              let hashtag = insert.replace('\n', '');
              if (hashtag.charAt(0) == ' ') hashtag = hashtag.substring(1);
              var splitHash = hashtag.split(' ');
              // console.log(splitHash);
              for (var j = 0; j < splitHash.length; j++) {
                if (splitHash[j].charAt(0) == '#') {
                  var newEditor = quillEditor.getEditor();
                  var unprivilegedEditor = quillEditor.makeUnprivilegedEditor(newEditor);

                  let newHashtag =
                    '<span class="mention" data-index="0" data-denotation-char="#" data-id="' +
                    hashValue.id +
                    '" data-value="<a href=&quot;' +
                    hashValue.link +
                    '&quot; target=_blank>' +
                    hashValue.value +
                    '" data-link="' +
                    hashValue.link +
                    '"><span contenteditable="false"><span class="ql-mention-denotation-char">#</span><a href="' +
                    hashValue.link +
                    '" target="_blank">' +
                    hashValue.value +
                    '</a></span></span>';
                  var newDescription = unprivilegedEditor.getHTML().replace(splitHash[j].replace('\n', ''), newHashtag);
                  // console.log(newDescription);
                  let newQuill = new Quill('#quillEditor');
                  let initialContent = newQuill.clipboard.convert(newDescription);
                  quillEditor.editor.setContents(initialContent, 'silent');
                  quillEditor.editor.focus();
                  quillEditor.editor.insertText(quillEditor.editor.getLength() - 1, enterKey ? '\n' : ' ');
                  quillEditor.editor.setSelection(quillEditor.editor.getLength(), quillEditor.editor.getLength());
                }
              }
            }
          }
        }
      }
    }
  }, [hashValue, createHashtagPostId]);

  const checkNewHashTag = (newHashtag, enterKey) => {
    var splitText = newHashtag.split(' ');
    for (var i = 0; i < splitText.length; i++) {
      if (splitText[i].charAt(0) == '#') {
        storeNewHashTag(splitText[i].substring(1).trim(), enterKey);
      }
    }
  };

  const postComment = () => {
    // var descCheck = false;
    // if (commentText && commentContent.ops.length > 0) {
    // 	for (var i = 0; i < commentContent.ops.length; i++) {
    // 		try {
    // 			if (commentContent.ops[i].insert.mention) {
    // 				descCheck = true;
    // 				break;
    // 			} else if (
    // 				commentContent.ops[i].insert &&
    // 				commentContent.ops[i].insert.trim().length > 0
    // 			) {
    // 				descCheck = true;
    // 				break;
    // 			}
    // 		} catch (e) {}
    // 	}
    // }
    // console.log(descCheck);
    var descHTML = commentHTML;
    var descCount = commentWordNumber;
    var descContent = commentContent;
    // if (quillRefPush.current) {
    // 	var comment = quillRefPush.current;
    // 	descHTML = comment.state.value;
    // 	descCount =
    // 		comment.state.selection.index + comment.state.selection.length;

    // 	descContent = comment.editor.editor.delta;
    // }
    if (!descCount > 0) {
      snackbarCustom('The comment should not be empty.');
    } else if (descCount > 200) {
      snackbarCustom('The comment exceeding maximum 200 characters.');
    } else {
      // console.log(commentHTML);
      // console.log(commentContent);
      // console.log(commentText);
      var file = '';
      if (commentImageSaved) {
        console.log(TimeFormatUniqueId() + '.' + fileName.split('.').pop());
        file = dataURLtoFile(commentImageSaved, TimeFormatUniqueId() + '.' + fileName.split('.').pop());
      }
      if (descHTML && descHTML.length > 0) descHTML = descHTML.replaceAll('target="_blank"', '');
      let commentFormData = new FormData();

      commentFormData.append('email', user.email);
      commentFormData.append('acctId', user.acctId);
      commentFormData.append('parentId', 0); // 0 means it's not a reply otherwise commentId is passesd
      commentFormData.append('postId', post.postId);

      commentFormData.append('description', descHTML ? descHTML : ''); // comment text

      commentFormData.append('attachments', file);
      commentFormData.append('status', 1);

      var j = 0;
      var k = 0;
      if (descCount > 0) {
        for (let i = 0; i < descContent.ops.length; i++) {
          let mention = descContent.ops[i].insert.mention;
          // console.log(mention);
          if (mention) {
            if (mention.denotationChar == '#') {
              var hashtagName = mention.value.replace(/<a\b[^>]*>/i, '').replace(/<\/a>/i, '');
              commentFormData.append(`hashTags[${j}][postHashId]`, 0);
              commentFormData.append(`hashTags[${j}][hashtagName]`, hashtagName);
              j++;
            } else if (mention.denotationChar == '@') {
              commentFormData.append(`profileTags[${k}][postProfileId]`, 0);
              commentFormData.append(`profileTags[${k}][profileId]`, mention.id);
              k++;
            }
          }
        }
      }
      commentFormData.append('accessToken', localStorage.getItem('token'));

      console.log(...commentFormData);
      var postList = {
        postList: isCopyPost ? postResult : searchResult,
      };
      console.log(postList);
      dispatch(createSocialComment(commentFormData, postList, post.postId, 0, 0, isCopyPost));
      setCommentImageSaved(null);
      dispatch(resetPostComment({ postId: null }));
      // setComment("");
    }
  };

  const addLikePost = reactionId => {
    setShowReactionPaper(false);
    const requestProfile = {
      acctId: user.acctId,
      accessToken: localStorage.getItem('token'),
      email: user.email,
      reactionId: reactionId,
      postId: post.postId,
    };
    console.log(requestProfile);
    dispatch(createSocialReaction(requestProfile));
    setLikeColor('#6F52ED');
    setReactionCount(reactionCount + 1);
    setLikeShow(1);
    setReactionId(reactionId);
  };

  const removeLikePost = () => {
    const requestProfile = {
      acctId: user.acctId,
      accessToken: localStorage.getItem('token'),
      email: user.email,
      likeId:
        likeId && likeId > 0
          ? likeId
          : post.ownerLike.socialReactionUserList
          ? post.ownerLike.socialReactionUserList[0].likeId
          : null,
    };
    console.log(requestProfile);
    dispatch(deleteSocialReaction(requestProfile));
    setLikeColor('#92929d');
    setReactionCount(reactionCount - 1);
    setLikeShow(0);
  };

  const onFileChange = async e => {
    console.log(e);
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setCommentImage(file);
      setCommentImageIsEdit(false);
      setCommentImageModal(true);
    }
  };

  const addPollResult = (poolOptionId, poolId, postId) => {
    setShowReactionPaper(false);
    const requestProfile = {
      acctId: user.acctId,
      accessToken: localStorage.getItem('token'),
      email: user.email,
      poolOptionId: poolOptionId,
      poolId: poolId,
      postId: postId,
    };
    console.log(requestProfile);
    dispatch(createPollResult(requestProfile));
  };

  const removePollResult = (poolResultId, poolId, postId) => {
    const requestProfile = {
      acctId: user.acctId,
      accessToken: localStorage.getItem('token'),
      email: user.email,
      poolResultId: poolResultId,
      poolId: poolId,
      postId: postId,
    };
    console.log(requestProfile);
    dispatch(deletePollResult(requestProfile));
  };

  const hideAll = () => {
    console.log(post);
    const dialogDefault = {
      title: 'Confirmation',
      description: 'Are you sure to hide all post of this author from your feed?',
      buttonYes: 'Proceed',
      buttonNo: 'Cancel',
    };
    setConfirmDialog({
      isOpen: true,
      dialogDefault,
      onConfirm: () => {
        const request = {
          email: user.email,
          acctId: user.acctId,
          postId: post.postId,
          authorId: post.acctId,
          reasonId: 1,
          statusId: 2, // statusId 2 is for hide all post from the author
          accessToken: localStorage.getItem('token'),
        };
        dispatch(createHidePost(request));
        setConfirmDialog({
          isOpen: false,
          dialogDefault,
        });
      },
      onCancel: () => {
        setConfirmDialog({
          isOpen: false,
          dialogDefault,
        });
      },
    });
  };

  const hidePost = () => {
    console.log(post);
    const dialogDefault = {
      title: 'Confirmation',
      description: 'Are you sure to hide this post from your feed?',
      buttonYes: 'Proceed',
      buttonNo: 'Cancel',
    };
    setConfirmDialog({
      isOpen: true,
      dialogDefault,
      onConfirm: () => {
        const request = {
          email: user.email,
          acctId: user.acctId,
          postId: post.postId,
          authorId: post.acctId,
          reasonId: 1,
          statusId: 1, // statusId 1 is hide for specific post
          accessToken: localStorage.getItem('token'),
        };
        dispatch(createHidePost(request));
        setConfirmDialog({
          isOpen: false,
          dialogDefault,
        });
      },
      onCancel: () => {
        setConfirmDialog({
          isOpen: false,
          dialogDefault,
        });
      },
    });
  };

  React.useEffect(() => {
    if (hideId && hideId > 0) {
      snackbarCustom('The hide post process is submitted, system will process shortly.');
      dispatch(resetHidePost());
    }
  }, [hideId]);
  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span className={stylesPoll.pollClosed}> • </span>;
    } else {
      // Render a countdown
      return (
        <span className={stylesPoll.daysLeft}>
          {' '}
          {days > 0 ? days : ''}
          {days > 0 ? (days > 1 ? ' days ' : ' day ') : ''}
          {hours > 0 ? hours : ''}
          {hours > 0 ? (hours > 1 ? ' hours ' : ' hour ') : ''}
          {days > 0 || hours > 0 ? '' : minutes > 0 ? minutes : ''}
          {days > 0 || hours > 0 ? '' : minutes > 0 ? (minutes > 1 ? ' minutes ' : ' minute ') : ''}
          {days > 0 || hours > 0 ? '' : minutes <= 5 ? (seconds > 0 ? seconds : '') : ''}
          {days > 0 || hours > 0
            ? ''
            : minutes <= 5
            ? seconds > 0
              ? seconds > 1
                ? ' seconds '
                : ' second '
              : ''
            : ''}
          {' left'}
        </span>
      );
    }
  };

  const addSavePost = () => {
    setShowReactionPaper(false);
    const requestProfile = {
      acctId: user.acctId,
      accessToken: localStorage.getItem('token'),
      email: user.email,
      postId: post.postId,
    };
    console.log(requestProfile);
    dispatch(createSavePost(requestProfile));
    setSaveColor('#6F52ED');
    setSaveCount(saveCount + 1);
    snackbarCustom('The social post has been saved.');
  };

  const removeSavePost = () => {
    const requestProfile = {
      acctId: user.acctId,
      accessToken: localStorage.getItem('token'),
      email: user.email,
      saveId: saveId && saveId > 0 ? saveId : post.saveCount > 0 ? post.saveId : null,
    };
    console.log(requestProfile);
    dispatch(deleteSavePost(requestProfile));
    setSaveColor('#92929d');
    setSaveCount(saveCount - 1);
    snackbarCustom('The saved post has been removed.');
  };

  const editSocialPost = () => {
    handleEditPostClick(post);
  };

  const removeSocialPost = () => {
    console.log(post);
    const requestProfile = {
      acctId: user.acctId,
      accessToken: localStorage.getItem('token'),
      email: user.email,
      postId: post.postId,
    };
    console.log(requestProfile);
    const dialogDefault = {
      title: 'Confirmation',
      description: 'Are you sure to delete this post?',
      buttonYes: 'Proceed',
      buttonNo: 'Cancel',
    };
    setConfirmDialog({
      isOpen: true,
      dialogDefault,
      onConfirm: () => {
        dispatch(deleteSocialPost(requestProfile));
        setConfirmDialog({
          isOpen: false,
          dialogDefault,
        });
      },
      onCancel: () => {
        setConfirmDialog({
          isOpen: false,
          dialogDefault,
        });
      },
    });
  };

  const shareToChat = () => {
    console.log(post);
  };

  const shareToGroup = () => {
    console.log(post);
  };

  const shareToFeed = () => {
    console.log(post);
    let postFormData = new FormData();
    postFormData.append('email', user.email);
    postFormData.append('acctId', user.acctId);
    postFormData.append('description', '');
    if (post.visibleId == 4) postFormData.append('visibleId', 4);
    else postFormData.append('visibleId', 1);
    postFormData.append('sharedId', post.postId);
    if (post.visibleId == 4 && post.recordId > 0) postFormData.append('recordId', post.recordId);
    else postFormData.append('recordId', 0);
    postFormData.append('typeOptionId', 4);
    postFormData.append('commentPermissionId', 1);
    if (post.visibleId == 4) postFormData.append('status', postStatus);
    else postFormData.append('status', 1);
    postFormData.append('accessToken', localStorage.getItem('token'));
    console.log(...postFormData);

    const dialogDefault = {
      title: 'Confirmation',
      description: 'Are you sure to share this post to feed?',
      buttonYes: 'Proceed',
      buttonNo: 'Cancel',
    };
    setConfirmDialog({
      isOpen: true,
      dialogDefault,
      onConfirm: () => {
        dispatch(createSocialPost(postFormData));
        setConfirmDialog({
          isOpen: false,
          dialogDefault,
        });
      },
      onCancel: () => {
        setConfirmDialog({
          isOpen: false,
          dialogDefault,
        });
      },
    });
  };

  const writePost = () => {
    handleSharePostClick(post);
  };

  const snackbarCustom = title => {
    const action = key => (
      <React.Fragment>
        <IconButton onClick={onClickDismiss(key)}>
          <CloseIcon />
        </IconButton>
      </React.Fragment>
    );
    snackBarShow(title, 'default', action);
  };

  const followHashtag = () => {
    setFollowHashtagId(1);
    const requestProfile = {
      acctId: user.acctId,
      accessToken: localStorage.getItem('token'),
      email: user.email,
      hashId: hashtagMatch && hashtagMatch.hashId,
    };
    console.log(requestProfile);
    dispatch(createFollowHashtag(requestProfile));
    setFollowCount(followCount + 1);
    snackbarCustom('The hashtag has been followed.');
  };

  const unfollowHashtag = () => {
    setFollowHashtagId(null);
    const requestProfile = {
      acctId: user.acctId,
      accessToken: localStorage.getItem('token'),
      email: user.email,
      saveId: newFollowHashtagId
        ? newFollowHashtagId
        : hashtagMatch && hashtagMatch.followId
        ? hashtagMatch.followId
        : null,
    };
    console.log(requestProfile);
    dispatch(deleteFollowHashtag(requestProfile));
    setFollowCount(followCount - 1);
    snackbarCustom('The following hashtag has been removed.');
  };

  useEffect(() => {
    if (newFollowHashtagId) setFollowHashtagId(newFollowHashtagId);
  }, [newFollowHashtagId]);

  const followProfile = () => {
    setFollowProfileId(1);
    const requestProfile = {
      acctId: user.acctId,
      accessToken: localStorage.getItem('token'),
      email: user.email,
      followAcctId: post && post.acctId,
    };
    console.log(requestProfile);
    dispatch(createFollowProfile(requestProfile));
    setFollowProfileColor('#6F52ED');
    snackbarCustom('The author has been followed.');
  };

  const unfollowProfile = () => {
    setFollowProfileId(null);
    const requestProfile = {
      acctId: user.acctId,
      accessToken: localStorage.getItem('token'),
      email: user.email,
      followId: newFollowProfileId ? newFollowProfileId : post && post.followId ? post.followId : null,
    };
    console.log(requestProfile);
    dispatch(deleteFollowProfile(requestProfile));
    setFollowProfileColor('#92929d');
    snackbarCustom('The author has been unfollowed.');
  };

  useEffect(() => {
    if (newFollowProfileId) setFollowProfileId(newFollowProfileId);
  }, [newFollowProfileId]);

  const [quillRefPush, setQuillRefPush] = React.useState(null);

  return (
    <Fragment>
      {confirmDialog && <CustomDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />}
      {hashtagMatch ? (
        <div
          className={clsx({
            [styles.body]: true,
            [styles.groupBody]: isInGroup,
          })}
          id={'socialHashtag_' + hashtagMatch.hashId}
          key={hashtagMatch.hashId}
        >
          <div className={styles.header}>
            <div className={styles.headerLeft}>
              <div className={styles.profilePic + ' ' + styles.hashtagPic}>
                <img src={hashtagImg} alt="" />
              </div>

              <div>
                <h6 className={styles.name + ' ' + styles.hashtagName}>#{hashtagMatch.hashtagName}</h6>

                <span className={styles.time}>
                  {followCount == 0
                    ? 'Be the first one to follow this Hashtag'
                    : followCount > 1
                    ? `${followCount} follower`
                    : `${followCount} followers`}
                </span>
                <div>
                  <button
                    className={styles.followButton}
                    onClick={e => {
                      if (!followHashtagId) followHashtag();
                      else unfollowHashtag();
                    }}
                  >
                    {!followHashtagId ? 'Follow' : 'Following'}
                  </button>
                </div>
              </div>
            </div>
            <button
              // style={{ position: "absolute", top: "32px", right: "24px" }}
              onClick={e => {
                setAnchorElHashtag(e.currentTarget);
                // const requestProfile = {
                // 	acctId: userStatus.acctId,
                // 	userId: post.acctId,
                // 	ipAddr: "",
                // 	accessToken: localStorage.getItem("token"),
                // };

                // dispatch(getProfiles(requestProfile));
              }}
            >
              <img src={dotBtn} alt="" />
            </button>
          </div>
        </div>
      ) : null}
      {post ? (
        <div
          className={clsx({
            [styles.body]: true,
            [styles.sharedBody]: hideReactionBar,
            [styles.groupBody]: isInGroup,
          })}
          // className={styles.body}
          id={'socialPost_' + post.postId}
          key={post.postId}
        >
          <div id="quillEditor" style={{ display: 'none' }} />
          <div className={styles.header}>
            <div className={styles.headerLeft}>
              <Link
                to={{
                  pathname: `/view-profile/${encryptData(post.acctId)}`,
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles.profilePic}>
                  <img src={post.profilePic ? post.profilePic : defaultAvatar} alt="" />
                </div>
              </Link>

              <div>
                <Link
                  to={{
                    pathname: `/view-profile/${encryptData(post.acctId)}`,
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h6 className={styles.name}>
                    {post.firstName}
                    {post.middleName ? ' ' + post.middleName : ''}
                    {' ' + post.surname}
                  </h6>
                </Link>

                <span className={styles.time}>{TimeFormatSocialFeed(post.createdDate)}</span>
              </div>
            </div>
            {!hideReactionBar ? (
              searchPost && post.acctId == user.acctId ? (
                <button
                  // style={{ position: "absolute", top: "32px", right: "24px" }}
                  onClick={e => {
                    history.replace(`/social-feed/2/${encryptData(post.postId)}`);
                  }}
                >
                  <ShortcutOutlinedIcon />
                </button>
              ) : (
                <button
                  onClick={e => {
                    setAnchorEl(e.currentTarget);
                    // const requestProfile = {
                    // 	acctId: userStatus.acctId,
                    // 	userId: post.acctId,
                    // 	ipAddr: "",
                    // 	accessToken: localStorage.getItem("token"),
                    // };

                    // dispatch(getProfiles(requestProfile));
                  }}
                >
                  <img src={dotBtn} alt="" />
                </button>
              )
            ) : null}
          </div>

          {
            post.description ? (
              <div
                className={styles.feedTitle}
                dangerouslySetInnerHTML={{
                  __html: post.description.replaceAll('target="_blank"', ''),
                }}
              />
            ) : null
            // <p className={styles.feedTitle}>
            // 	There is no description. After that, We have applied validation that
            // 	user cannot create post if there is no description, attachment or
            // 	poll.
            // </p>
          }
          {post.typeOptionId == 0 && addLink && (
            <div className={stylesPost.sharedPost} style={{ padding: '10px' }}>
              <ReactTinyLink
                cardSize="small"
                showGraphic={true}
                maxLine={2}
                minLine={1}
                url={addLink}
                defaultMedia={shareLink}
                proxyUrl={instanceUrl}
              />
            </div>
          )}
          {post.typeOptionId == 5 && post.sharedJob && post.sharedJob.length > 0 ? (
            <div className={stylesPost.sharedPost}>
              <div style={{ padding: '0 15px' }}>
                <h6
                  style={{
                    fontSize: '14px',
                    fontWeight: '600',
                    marginTop: '15px',
                    marginBottom: '0',
                  }}
                >
                  Job Post
                </h6>
                <hr style={{ margin: '10px 0 0 0' }} />
              </div>
              <JobCard item={post.sharedJob[0]} />
            </div>
          ) : null}
          {post.typeOptionId == 4 &&
          post.sharedPost &&
          post.sharedPost.postList &&
          post.sharedPost.postList.length > 0 ? (
            <div className={stylesPost.sharedPost}>
              <ProfileViewActivityCard
                // key={post.postId}
                post={post.sharedPost.postList[0]}
                reportTypesList={reportTypesList}
                reportReasonsList={reportReasonsList}
                updateFeedHandler={updateFeedHandler}
                isCopyPost={false}
                handleEditPostClick={handleEditPostClick}
                hashtagMatch={null}
                hideReactionBar={true}
                handleSharePostClick={handleSharePostClick}
                searchPost={false}
                isGroupMember={false}
              />
            </div>
          ) : null}
          {post.postTypes && post.postTypes.length > 0 ? (
            post.typeOptionId == 3 ? (
              <div className={stylesPoll.pollSection}>
                <Typography className={stylesPoll.pollTitle}>
                  {post.postTypes[0].poolList[0] && post.postTypes[0].poolList[0].question}
                </Typography>
                {/* <Typography className={stylesPoll.feedTitle}>
									The author can see how you vote
								</Typography> */}

                <Typography component="div" className={stylesPoll.pollGallery}>
                  <div className={stylesPoll.QuestionandOptions}>
                    <div className={stylesPoll.optionGroup}>
                      {poolOptionList && poolOptionList.length > 0 && pollOptionPostId == post.postId
                        ? poolOptionList.map((item, index) => (
                            <div
                              key={index}
                              className={
                                TimeFormatPollLeft(post.postTypes[0].poolList[0].endDate)
                                  ? isChosenOption && pollOptionPostId == post.postId
                                    ? item.ownerChosenOption == item.poolOptionId
                                      ? stylesPoll.Optionselected
                                      : stylesPoll.Optionnotselected
                                    : stylesPoll.Options
                                  : stylesPoll.Optionselected
                              }
                              onClick={() => {
                                if (!isChosenOption) {
                                  dispatch(resetPostComment({ postId: null }));
                                  addPollResult(item.poolOptionId, item.poolId, post.postId);
                                  // setOptionClicked(1);
                                }
                              }}
                            >
                              <div className={stylesPoll.optionDesc}>{item.optionDesc}</div>
                              {isChosenOption && pollOptionPostId == post.postId ? (
                                <div className={stylesPoll.percentage}>
                                  {item.optionPercentage.toFixed(0)}
                                  {'%'}
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          ))
                        : post.postTypes[0].poolList[0].poolOptionList
                        ? post.postTypes[0].poolList[0].poolOptionList.map((item, index) => (
                            <div
                              key={index}
                              className={
                                TimeFormatPollLeft(post.postTypes[0].poolList[0].endDate)
                                  ? post.postTypes[0].poolList[0].choosen
                                    ? item.ownerChosenOption == item.poolOptionId
                                      ? stylesPoll.Optionselected
                                      : stylesPoll.Optionnotselected
                                    : stylesPoll.Options
                                  : stylesPoll.Optionselected
                              }
                              onClick={() => {
                                if (!post.postTypes[0].poolList[0].choosen) {
                                  dispatch(resetPostComment({ postId: null }));
                                  addPollResult(item.poolOptionId, item.poolId, post.postId);
                                  // setOptionClicked(1);
                                }
                              }}
                            >
                              <div className={stylesPoll.optionDesc}>{item.optionDesc}</div>
                              {post.postTypes[0].poolList[0].choosen ? (
                                <div className={stylesPoll.percentage}>
                                  {item.optionPercentage.toFixed(0)}
                                  {'%'}
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          ))
                        : null}
                    </div>
                    <div className={stylesPoll.pollDetails}>
                      <span
                        className={stylesPoll.pollVote}
                        onClick={() => {
                          setPoll(post.postTypes[0].poolList[0]);
                          setPoolResultUserListModal(true);
                        }}
                      >
                        {poolOptionList.length > 0 && pollOptionPostId == post.postId ? (
                          <span className={stylesPoll.viewandresult}>
                            {poolOptionList[0].totalResult ? poolOptionList[0].totalResult : 0}{' '}
                            {poolOptionList[0].totalResult && poolOptionList[0].totalResult > 1 ? 'votes' : 'vote'}
                          </span>
                        ) : post.postTypes[0].poolList[0].poolOptionList.length > 0 ? (
                          <span className={stylesPoll.viewandresult}>
                            {post.postTypes[0].poolList[0].poolOptionList[0].totalResult
                              ? post.postTypes[0].poolList[0].poolOptionList[0].totalResult
                              : 0}{' '}
                            {post.postTypes[0].poolList[0].poolOptionList[0].totalResult &&
                            post.postTypes[0].poolList[0].poolOptionList[0].totalResult > 1
                              ? 'votes'
                              : 'vote'}
                          </span>
                        ) : null}
                      </span>
                      <Countdown
                        date={TimeFormatPollConvert(post.postTypes[0].poolList[0].endDate)}
                        renderer={renderer}
                      />
                      <span
                        className={
                          TimeFormatPollLeft(post.postTypes[0].poolList[0].endDate)
                            ? stylesPoll.pollUndo
                            : stylesPoll.pollClosed
                        }
                        onClick={e => {
                          if (
                            TimeFormatPollLeft(post.postTypes[0].poolList[0].endDate) &&
                            (post.postTypes[0].poolList[0].choosen || isChosenOption)
                          ) {
                            var poolResult = null;
                            var poolId = null;
                            if (isChosenOption) {
                              for (var i = 0; i < poolOptionList.length; i++) {
                                var ownerChosenResult = poolOptionList[i].ownerChosenResult;
                                if (ownerChosenResult) {
                                  poolResult = ownerChosenResult;
                                  poolId = poolOptionList[i].poolId;
                                }
                              }
                            } else if (post.postTypes[0].poolList[0].choosen) {
                              for (var i = 0; i < post.postTypes[0].poolList[0].poolOptionList.length; i++) {
                                var ownerChosenResult =
                                  post.postTypes[0].poolList[0].poolOptionList[i].ownerChosenResult;
                                if (ownerChosenResult) {
                                  poolResult = ownerChosenResult;
                                  poolId = post.postTypes[0].poolList[0].poolOptionList[i].poolId;
                                }
                              }
                            }

                            if (poolResult) removePollResult(poolResult, poolId, post.postId);
                            // setOptionClicked(1);
                          }
                        }}
                      >
                        {TimeFormatPollLeft(post.postTypes[0].poolList[0].endDate)
                          ? post.postTypes[0].poolList[0].choosen || (isChosenOption && pollOptionPostId == post.postId)
                            ? 'Undo'
                            : null
                          : 'Poll closed'}
                      </span>
                    </div>
                  </div>
                </Typography>
              </div>
            ) : null
          ) : null}

          {post.postTypes && post.postTypes.length > 0 ? (
            post.typeOptionId == 1 || post.typeOptionId == 2 ? (
              <Typography component="div" className={stylesPost.postGraphicalContent}>
                <Typography
                  component="div"
                  className={stylesPost.postContentContainer}
                  onClick={() => {
                    if (post.typeOptionId == 1) {
                      dispatch(getSocialPostPopup(post));
                      setIsViewAttachmentModalOpen(true);
                    }
                  }}
                >
                  <Fragment>
                    {post.typeOptionId == 1 ? (
                      <div>
                        <GridContainer style={{ margin: 0 }} justifyContent="center">
                          {post.postTypes.length > 0
                            ? post.postTypes.length == 4
                              ? post.postTypes.map((item, index) => (
                                  <GridItem key={index} xs={6} sm={6} md={6} style={{ padding: '0 2px' }}>
                                    <div className={stylesPost.attachmentListItemDisplay}>
                                      <div className={classes.attachmentListItemAttachmentContainer}>
                                        <img
                                          src={item.attachment}
                                          alt=""
                                          className={stylesPost.attachmentListItemContent}
                                          style={{
                                            width: '100%',
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </GridItem>
                                ))
                              : post.postTypes.length == 3
                              ? post.postTypes.map((item, index) => (
                                  <GridItem key={index} xs={4} sm={4} md={4} style={{ padding: '0 2px' }}>
                                    <div className={stylesPost.attachmentListItemDisplay}>
                                      <div className={classes.attachmentListItemAttachmentContainer}>
                                        <img
                                          src={item.attachment}
                                          alt=""
                                          className={stylesPost.attachmentListItemContent}
                                          style={{
                                            // height: "200px",
                                            width: '100%',
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </GridItem>
                                ))
                              : post.postTypes.length == 2
                              ? post.postTypes.map((item, index) => (
                                  <GridItem key={index} xs={6} sm={6} md={6} style={{ padding: '0 2px' }}>
                                    <div className={stylesPost.attachmentListItemDisplay}>
                                      <div className={classes.attachmentListItemAttachmentContainer}>
                                        <img
                                          src={item.attachment}
                                          alt=""
                                          className={stylesPost.attachmentListItemContent}
                                          style={{
                                            width: '100%',
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </GridItem>
                                ))
                              : post.postTypes.length == 1
                              ? post.postTypes.map((item, index) => (
                                  <GridItem key={index} xs={12} sm={12} md={12} style={{ padding: '0 2px' }}>
                                    <div className={stylesPost.attachmentListItemDisplay}>
                                      <div className={classes.attachmentListItemAttachmentContainer}>
                                        <img
                                          src={item.attachment}
                                          alt=""
                                          className={stylesPost.attachmentListItemContent}
                                        />
                                      </div>
                                    </div>
                                  </GridItem>
                                ))
                              : post.postTypes.length > 4 &&
                                post.postTypes.map(
                                  (item, index) =>
                                    index < 4 && (
                                      <GridItem key={index} xs={6} sm={6} md={6} style={{ padding: '0 2px' }}>
                                        <div className={stylesPost.attachmentListItemDisplay}>
                                          <div className={classes.attachmentListItemAttachmentContainer}>
                                            {index < 3 ? (
                                              <img
                                                src={item.attachment}
                                                alt=""
                                                className={stylesPost.attachmentListItemContent}
                                                style={{
                                                  width: '100%',
                                                }}
                                              />
                                            ) : (
                                              <div
                                                className={stylesPost.attachmentListItemContent}
                                                style={{
                                                  textAlign: 'center',
                                                  cursor: 'pointer',
                                                  width: '100%',
                                                  backgroundImage: `url(${item.attachment})`,
                                                  filter: 'brightness(0.7)',
                                                  backgroundRepeat: 'no-repeat',
                                                  backgroundSize: 'cover',
                                                }}
                                              >
                                                <AddOutlinedIcon
                                                  sx={{
                                                    color: 'white',
                                                    height: '100%',
                                                    width: '35px',
                                                    // margin: "5px",
                                                    transition: 'all .5s',
                                                    '&:hover': {
                                                      transform: 'scale(1.5)',
                                                    },
                                                  }}
                                                  alt=""
                                                />
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </GridItem>
                                    )
                                )
                            : null}
                        </GridContainer>
                      </div>
                    ) : post.typeOptionId == 2 ? (
                      <PostVideoList attachmentList={post.postTypes} />
                    ) : null}
                  </Fragment>
                </Typography>
              </Typography>
            ) : null
          ) : null}

          {!hideReactionBar ? (
            <div className={styles.feedActions}>
              {post.cpId == 1 ||
              (post.cpId == 2 && (post.connId > 0 || post.acctId == userStatus.acctId)) ||
              (post.visibleId == 4 && post.cpId == 4 && isGroupMember) ? (
                <Button
                  onClick={() => {
                    if (!isCopyPost) {
                      getComments(0);
                    }
                    dispatch(resetPostComment({ postId: post.postId }));
                    if (showComment && lastOpenCommentPostId == post.postId) {
                      setShowComment(false);
                    } else {
                      setShowComment(true);
                    }
                  }}
                >
                  {/* <img src={commentIcon} alt="" /> */}
                  <svg
                    id="ic_comment"
                    xmlns="http://www.w3.org/2000/svg"
                    width="30.221"
                    height="31.343"
                    viewBox="0 0 30.221 31.343"
                  >
                    <g id="Comments">
                      <path id="Path" d="M0,0H30.221V31.343H0Z" fill="none" />
                      <g
                        id="iconspace_Chat_3_25px"
                        data-name="iconspace_Chat 3_25px"
                        transform="translate(2.518 3.761)"
                      >
                        <path
                          id="Path-2"
                          data-name="Path"
                          d="M6.478,23.824A1.935,1.935,0,0,1,4.543,21.9l0-2.9A5.9,5.9,0,0,1,0,13.244V5.917A5.928,5.928,0,0,1,5.926,0H19.258a5.928,5.928,0,0,1,5.926,5.918v7.327a5.928,5.928,0,0,1-5.926,5.918H13.239l-5.6,4.264A1.9,1.9,0,0,1,6.478,23.824ZM5.926,2.507a3.413,3.413,0,0,0-3.407,3.41v7.327A3.39,3.39,0,0,0,5.83,16.654,1.249,1.249,0,0,1,7.055,17.9l0,2.805,4.989-3.8a1.269,1.269,0,0,1,.765-.258h6.445a3.413,3.413,0,0,0,3.407-3.41V5.917a3.413,3.413,0,0,0-3.407-3.41Z"
                          fill={post.commentCount > 0 ? '#6F52ED' : '#92929d'}
                        />
                      </g>
                    </g>
                  </svg>
                  {post.commentCount} {post.commentCount > 1 ? 'Comments' : 'Comment'}
                </Button>
              ) : null}
              <Button
                ref={anchorRef}
                onClick={() => {
                  // console.log(likeColor);

                  if (isMobile) {
                    if (likeColor == '#6F52ED') removeLikePost();
                    else {
                      console.log(showReactionPaper);
                      if (showReactionPaper) setShowReactionPaper(false);
                      else setShowReactionPaper(true);
                    }
                  } else {
                    if (likeColor == '#92929d') setShowReactionPaper(true);
                    else removeLikePost();
                  }
                }}
                onMouseEnter={() => {
                  if (!isMobile) setShowReactionPaper(true);
                }}
                onMouseLeave={() => {
                  if (!isMobile) setShowReactionPaper(false);
                }}
              >
                <Popper
                  className={styles.likePopper}
                  anchorEl={anchorRef.current}
                  open={showReactionPaper && likeColor == '#92929d'}
                  role={undefined}
                  placement="top-start"
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <ClickAwayListener onClickAway={() => (!isMobile ? setShowReactionPaper(false) : null)}>
                      {/* <Grow
										{...TransitionProps}
										style={{
											transformOrigin: placement === "top" ? "top" : "top",
										}}
									> */}
                      <Paper>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            margin: '10px',
                          }}
                        >
                          <img src={likeIcon} alt="" onClick={() => addLikePost(1)} />
                          {/* <ThumbUpAltOutlinedIcon
														sx={{
															color: "#6F52ED",
															margin: "5px",
															transition: "all .5s",
															"&:hover": {
																transform: "scale(1.5)",
															},
														}}
														onClick={() => addLikePost(1)}
													/> */}
                          <img src={loveIcon} alt="" onClick={() => addLikePost(2)} />
                          {/* <FavoriteBorderOutlinedIcon
														sx={{
															color: "#6F52ED",
															margin: "5px",
															transition: "all .5s",
															"&:hover": {
																transform: "scale(1.5)",
															},
														}}
														onClick={() => addLikePost(2)}
													/> */}
                          <img src={clapIcon} alt="" onClick={() => addLikePost(3)} />
                          {/* <HandClapIcon
														style={{
															fill: "#6F52ED",
															width: "22px",
															height: "24px",
															margin: "5px",
															transition: "all .5s",
															"&:hover": {
																transform: "scale(1.5)",
															},
														}}
														className={styles.clapIcon}
														onClick={() => addLikePost(3)}
													/> */}
                          <img src={angryIcon} alt="" onClick={() => addLikePost(4)} />
                          {/* <SentimentVeryDissatisfiedIcon
														sx={{
															color: "#6F52ED",
															margin: "5px",
															transition: "all .5s",
															"&:hover": {
																transform: "scale(1.5)",
															},
														}}
														onClick={() => addLikePost(4)}
													/> */}
                          <img src={sadIcon} alt="" onClick={() => addLikePost(5)} />
                          {/* <MoodBadOutlinedIcon
														sx={{
															color: "#6F52ED",
															margin: "5px",
															transition: "all .5s",
															"&:hover": {
																transform: "scale(1.5)",
															},
														}}
														onClick={() => addLikePost(5)}
													/> */}
                          <img src={happyIcon} alt="" onClick={() => addLikePost(6)} />
                          {/* <SentimentVerySatisfiedOutlinedIcon
														sx={{
															color: "#6F52ED",
															margin: "5px",
															transition: "all .5s",
															"&:hover": {
																transform: "scale(1.5)",
															},
														}}
														onClick={() => addLikePost(6)}
													/> */}
                        </div>
                      </Paper>
                      {/* </Grow> */}
                    </ClickAwayListener>
                  )}
                </Popper>
                {likeShow > 0 ? (
                  reactionId == 1 ? (
                    <img src={likeIcon} alt="" />
                  ) : // <ThumbUpAltOutlinedIcon
                  // 	sx={{
                  // 		color: likeColor,
                  // 		margin: "5px",
                  // 		transition: "all .5s",
                  // 		"&:hover": {
                  // 			transform: "scale(1.5)",
                  // 		},
                  // 	}}
                  // />
                  reactionId == 2 ? (
                    <img src={loveIcon} alt="" />
                  ) : // <FavoriteBorderOutlinedIcon
                  // 	sx={{
                  // 		color: likeColor,
                  // 		margin: "5px",
                  // 		transition: "all .5s",
                  // 		"&:hover": {
                  // 			transform: "scale(1.5)",
                  // 		},
                  // 	}}
                  // />
                  reactionId == 3 ? (
                    <img src={clapIcon} alt="" />
                  ) : // <HandClapIcon
                  // 	style={{
                  // 		fill: likeColor,
                  // 		width: "22px",
                  // 		height: "24px",
                  // 		margin: "5px",
                  // 		transition: "all .5s",
                  // 	}}
                  // 	className={styles.clapIcon}
                  // />
                  reactionId == 4 ? (
                    <img src={angryIcon} alt="" />
                  ) : // <SentimentVeryDissatisfiedIcon
                  // 	sx={{
                  // 		color: likeColor,
                  // 		margin: "5px",
                  // 		transition: "all .5s",
                  // 		"&:hover": {
                  // 			transform: "scale(1.5)",
                  // 		},
                  // 	}}
                  // />
                  reactionId == 5 ? (
                    <img src={sadIcon} alt="" />
                  ) : (
                    // <MoodBadOutlinedIcon
                    // 	sx={{
                    // 		color: likeColor,
                    // 		margin: "5px",
                    // 		transition: "all .5s",
                    // 		"&:hover": {
                    // 			transform: "scale(1.5)",
                    // 		},
                    // 	}}
                    // />
                    reactionId == 6 && (
                      <img src={happyIcon} alt="" />
                      // <SentimentVerySatisfiedOutlinedIcon
                      // 	sx={{
                      // 		color: likeColor,
                      // 		margin: "5px",
                      // 		transition: "all .5s",
                      // 		"&:hover": {
                      // 			transform: "scale(1.5)",
                      // 		},
                      // 	}}
                      // />
                    )
                  )
                ) : (
                  <svg
                    alt=""
                    xmlns="http://www.w3.org/2000/svg"
                    width="30.221"
                    height="30.09"
                    viewBox="0 0 30.221 30.09"
                  >
                    <g id="ic_like" transform="translate(0 0)">
                      <g id="Likes">
                        <ellipse
                          id="Oval"
                          cx="15.11"
                          cy="15.045"
                          rx="15.11"
                          ry="15.045"
                          transform="translate(0 0)"
                          fill="none"
                        />
                        <path
                          id="Combined_Shape_Copy"
                          data-name="Combined Shape Copy"
                          d="M13.851,24.53a2.51,2.51,0,0,1-1.756-.71l-.05-.049L2.314,13.809a8.208,8.208,0,0,1,0-11.425,7.837,7.837,0,0,1,11.258,0l.279.285.279-.285a7.837,7.837,0,0,1,11.258,0,8.206,8.206,0,0,1,0,11.425l-9.732,9.962A2.5,2.5,0,0,1,13.851,24.53ZM7.943,2.507A5.311,5.311,0,0,0,4.119,4.132a5.707,5.707,0,0,0,0,7.928l9.732,9.963,9.732-9.963a5.707,5.707,0,0,0,0-7.928,5.312,5.312,0,0,0-7.647,0l-1.182,1.21a1.264,1.264,0,0,1-1.805,0l-1.182-1.21A5.311,5.311,0,0,0,7.943,2.507Z"
                          transform="translate(1.259 2.507)"
                          fill={likeColor}
                        />
                      </g>
                    </g>
                  </svg>
                )}
                <span
                  className={classes.likeReaction}
                  // onClick={() => {
                  // 	fetchSocialReactionList();
                  // }}
                >
                  {reactionCount} {reactionCount > 1 ? 'Likes' : 'Like'}
                </span>
              </Button>
              <Button
                onClick={e => {
                  setAnchorShareEl(e.currentTarget);
                }}
              >
                {/* <img src={share} alt="" /> */}
                <svg xmlns="http://www.w3.org/2000/svg" width="30.221" height="30.09" viewBox="0 0 30.221 30.09">
                  <g id="ic_Share" transform="translate(0 0)">
                    <g id="Share">
                      <ellipse
                        id="Oval"
                        cx="15.11"
                        cy="15.045"
                        rx="15.11"
                        ry="15.045"
                        transform="translate(0 0)"
                        fill="none"
                      />
                      <g id="Group_24_Copy" data-name="Group 24 Copy" transform="translate(28.962 1.254)">
                        <path
                          id="Combined_Shape"
                          data-name="Combined Shape"
                          d="M0,22.567a5.031,5.031,0,0,0-5.036-5.015,5.042,5.042,0,0,0-4.157,2.186l-8.691-4.37a4.976,4.976,0,0,0,.255-1.577,4.974,4.974,0,0,0-.293-1.686l8.741-4.243A5.042,5.042,0,0,0-5.036,10.03,5.031,5.031,0,0,0,0,5.015,5.031,5.031,0,0,0-5.036,0a5.032,5.032,0,0,0-5.037,5.015q0,.242.023.48l-9.307,4.517a5.027,5.027,0,0,0-3.307-1.236A5.032,5.032,0,0,0-27.7,13.791a5.032,5.032,0,0,0,5.037,5.015,5.029,5.029,0,0,0,3.4-1.321l9.209,4.63q-.02.223-.02.452a5.032,5.032,0,0,0,5.037,5.015A5.031,5.031,0,0,0,0,22.567Zm-2.518,0a2.515,2.515,0,0,1-2.518,2.507,2.516,2.516,0,0,1-2.519-2.507,2.516,2.516,0,0,1,2.519-2.507A2.516,2.516,0,0,1-2.518,22.567Zm-17.629-8.776A2.516,2.516,0,0,1-22.666,16.3a2.516,2.516,0,0,1-2.519-2.507,2.516,2.516,0,0,1,2.519-2.507A2.516,2.516,0,0,1-20.147,13.791ZM-2.518,5.015A2.516,2.516,0,0,1-5.036,7.522,2.516,2.516,0,0,1-7.556,5.015,2.516,2.516,0,0,1-5.036,2.507,2.516,2.516,0,0,1-2.518,5.015Z"
                          fill={saveColor}
                        />
                      </g>
                    </g>
                  </g>
                </svg>
                {post.shareCount} Shares
              </Button>
              <Button>
                {/* <img src={saved} alt="" /> */}
                {/* <svg
							xmlns="http://www.w3.org/2000/svg"
							width="30.221"
							height="30.09"
							viewBox="0 0 30.221 30.09"
						>
							<g id="ic_Saved" transform="translate(0 0)">
								<g id="Saved">
									<ellipse
										id="Oval"
										cx="15.11"
										cy="15.045"
										rx="15.11"
										ry="15.045"
										transform="translate(0 0)"
										fill="none"
									/>
									<g
										id="Group_13_Copy"
										data-name="Group 13 Copy"
										transform="translate(4.296 0.507)"
									>
										<path
											id="Rectangle_92"
											data-name="Rectangle 92"
											d="M18.888,26.415a2.409,2.409,0,0,1-1.5-.524l-6.746-5.374L3.9,25.891A2.4,2.4,0,0,1,0,24.027V4.776A4.792,4.792,0,0,1,4.8,0H16.49a4.792,4.792,0,0,1,4.8,4.776V24.027A2.4,2.4,0,0,1,18.888,26.415Zm-8.245-8.286a2.416,2.416,0,0,1,1.5.523l6.746,5.375V4.776a2.4,2.4,0,0,0-2.4-2.389H4.8A2.4,2.4,0,0,0,2.4,4.776V24.027l6.746-5.375A2.413,2.413,0,0,1,10.643,18.129Z"
											transform="translate(0.801 0.806)"
											fill="#92929d"
										/>
									</g>
								</g>
							</g>
						</svg> */}
                <VisibilityOutlinedIcon
                  sx={{
                    color: post.viewCount > 0 ? '#6F52ED' : '#92929d',
                    margin: '5px',
                    transition: 'all .5s',
                    '&:hover': {
                      transform: 'scale(1.5)',
                    },
                  }}
                  alt=""
                />
                {post.viewCount} Viewed
              </Button>
            </div>
          ) : null}
          {lastOpenCommentPostId == post.postId ? (
            <div className={styles.feedCommentBx}>
              <GridContainer>
                <GridItem className={styles.feedCommentFlex}>
                  <div className={styles.feedCommentUserBx}>
                    <img src={user.profilePic} alt="" />
                  </div>
                  <div className={styles.commentInputWrap}>
                    <div className={classes.mentionsInput}>
                      <div>
                        <CommentInput
                          post={post}
                          comment={null}
                          emojiChoose={emojiChoose}
                          resetEmoji={setEmojiChoose}
                          getLength={setCommentWordNumber}
                          // getText={setCommentText}
                          getHTML={setCommentHTML}
                          getContent={setCommentContent}
                          onSubmit={setSubmitComment}
                          submitCommentId={setSubmitCommentId}
                          parentId={0}
                          quillRefPush={setQuillRefPush}
                        />
                      </div>
                      <div className={styles.commentActionBtns}>
                        {/* {!isMobile && (
													<div>
														<IconButton
															onClick={(e) => {
																setAnchorElEmoji(e.currentTarget);
															}}
															// ref={anchorRefEmoji}
														>
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width="21"
																height="21"
																viewBox="0 0 21 21"
															>
																<path
																	id="Shape"
																	d="M3.075,17.924A10.5,10.5,0,0,1,17.924,3.075,10.5,10.5,0,0,1,3.075,17.924ZM1,10.5A9.5,9.5,0,1,0,10.5,1,9.511,9.511,0,0,0,1,10.5Zm5.207,4.419a.5.5,0,1,1,.6-.8,6.327,6.327,0,0,0,3.727,1.127A6.084,6.084,0,0,0,14.2,14.124a.5.5,0,0,1,.7.09.5.5,0,0,1-.09.7,7,7,0,0,1-4.277,1.331A7.236,7.236,0,0,1,6.207,14.919ZM12.5,8.5a1,1,0,1,1,1,1A1,1,0,0,1,12.5,8.5Zm-6,0a1,1,0,1,1,1,1A1,1,0,0,1,6.5,8.5Z"
																	fill="#92929d"
																/>
															</svg>
														</IconButton>
														<Popover
															id={anchorElEmoji ? "emoji-popover" : undefined}
															open={anchorElEmoji ? true : false}
															anchorEl={anchorElEmoji}
															onClose={() => {
																setAnchorElEmoji(null);
															}}
															anchorOrigin={{
																vertical: "bottom",
																horizontal: "center",
															}}
															transformOrigin={{
																vertical: "top",
																horizontal: "center",
															}}
														>
															<div>
																<Picker
																	onEmojiClick={onEmojiClick}
																	groupVisibility={{
																		flags: false,
																		recently_used: false,
																	}}
																/>
															</div>
														</Popover>
													</div>
												)} */}
                        <IconButton onClick={handleImageClick}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20">
                            <path
                              id="Shape"
                              d="M17.5,20H3.5A3.431,3.431,0,0,1,0,16.65V3.35A3.431,3.431,0,0,1,3.5,0h14A3.431,3.431,0,0,1,21,3.35v13.3A3.431,3.431,0,0,1,17.5,20ZM15.286,8.775l-4.714,4.253L15.961,19H17.5A2.431,2.431,0,0,0,20,16.65V13.071Zm-8.139,1.95h0L1.009,16.849A2.458,2.458,0,0,0,3.5,19H14.613L7.148,10.725ZM3.5,1A2.431,2.431,0,0,0,1,3.35v12.1l5.814-5.8a.5.5,0,0,1,.724.019L9.9,12.286l5.051-4.557a.5.5,0,0,1,.671,0L20,11.718V3.35A2.431,2.431,0,0,0,17.5,1ZM6.691,6.7A1.925,1.925,0,1,1,8.619,4.775,1.929,1.929,0,0,1,6.691,6.7Zm0-2.849a.925.925,0,1,0,.929.925A.928.928,0,0,0,6.691,3.85Z"
                              transform="translate(0 0)"
                              fill="#92929d"
                            />
                          </svg>
                        </IconButton>
                        <div className={'fileinput text-center'}>
                          <input
                            type="file"
                            onChange={onFileChange}
                            accept="image/jpg, image/jpeg, image/gif, image/png, image/apng, image/bmp, image/gif"
                            ref={fileInput}
                          />
                        </div>
                        <IconButton
                          onClick={() => {
                            postComment(post.postId);
                            // quillRef.current.editor.setText("");
                            // setOpenEmojiOption(false);
                            // if (textValue.trim() != "") {
                            // 	postComment();
                            // 	setTextValue("");
                            // }
                          }}
                        >
                          <SendIcon
                            sx={{
                              color: '#92929d',
                              margin: '5px',
                            }}
                            className={styles.sendButton}
                          />
                        </IconButton>
                      </div>
                    </div>
                    {/* {commentWordNumber && commentWordNumber > 0 ? (
											<div>
												{commentWordNumber > 200 ? (
													<ErrorOutlinedIcon
														sx={{
															color: "#6F52ED",
															margin: "0 5px -7px 0",
															width: "24px",
															transition: "all .5s",
															"&:hover": {
																transform: "scale(1.5)",
															},
														}}
													/>
												) : null}
												<span style={{ fontSize: "14px" }}>
													{commentWordNumber > 200
														? "You have exceeded the maximum character limit."
														: null}
													({commentWordNumber}
													/200)
												</span>
											</div>
										) : null} */}
                    {commentImageSaved ? (
                      <div className={classes.attachmentListItem}>
                        <img
                          src={cross}
                          alt=""
                          className={classes.removeAttachment}
                          onClick={e => {
                            e.stopPropagation();
                            setCommentImageSaved(null);
                          }}
                        />
                        <div className={classes.attachmentListItemAttachmentContainer}>
                          <img src={commentImageSaved} alt="" className={classes.attachmentListItemContent} />
                        </div>
                        <div
                          className={classes.attachmentListItemAttachmentContainerOverlay}
                          onClick={() => {
                            setCommentImageIsEdit(true);
                            setCommentImage(commentImageSaved);
                            setCommentImageModal(true);
                          }}
                        ></div>
                      </div>
                    ) : null}
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          ) : null}

          {showComment && !hideReactionBar ? (
            post.comments && post.comments.commentList && post.comments.commentList.length ? (
              <div className={styles.postCommentsMain}>
                {skeletonShow && lastOpenCommentPostId == post.postId ? <CommentSkeleton /> : null}
                {post.comments && post.comments.commentList && post.comments.commentList.length
                  ? // &&
                    // commentsExpanded
                    post.comments.commentList.map((comment, index) => (
                      <div key={comment.commentId}>
                        <Comment
                          comment={comment}
                          postId={post.postId}
                          profilePic={user.profilePic}
                          commentCount={1}
                          reportReasonsList={reportReasonsList}
                          sectionCount={1}
                          isCopyPost={isCopyPost}
                        />
                      </div>
                    ))
                  : null}

                {(isEndComment || !commentsExpanded) && lastOpenCommentPostId == post.postId ? (
                  <Typography component="div" className={styles.moreComments}>
                    <button onClick={() => collapseComment()}>Hide comments</button>
                  </Typography>
                ) : isLoadingComment && lastOpenCommentPostId == post.postId ? (
                  <CommentSkeleton />
                ) : post.actualCommentCount > post.comments.commentList.length ? (
                  <Typography component="div" className={styles.moreComments}>
                    <button onClick={() => getComments(post.comments.commentList.length)}>Load more comments</button>
                  </Typography>
                ) : null}
              </div>
            ) : (
              <></>
            )
          ) : null}
        </div>
      ) : null}
      {post && !hideReactionBar ? (
        <Popover
          id={anchorEl ? 'simple-popover' : undefined}
          open={anchorEl ? true : false}
          anchorEl={anchorEl}
          onClose={() => {
            setAnchorEl(null);
          }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <div className={styles.popoverBox}>
            {userStatus.acctId == post.acctId ? (
              <div
                className={styles.popoverListItem}
                onClick={() => {
                  editSocialPost();
                  setAnchorEl(null);
                }}
              >
                <div className={styles.popoverListItemIcon}>
                  <EditOutlinedIcon
                    sx={{
                      color: '#b6b7b8',
                      margin: '5px',
                    }}
                  />
                </div>
                <div className={styles.popoverListItemText}>
                  <Typography component="p" className={styles.popoverListItemTitle}>
                    Edit Post
                  </Typography>
                </div>
              </div>
            ) : (
              <div
                className={styles.popoverListItem}
                onClick={() => {
                  if (saveColor == '#92929d') addSavePost();
                  else removeSavePost();
                  // if ((saveId && saveId > 0) || post.saveCount > 0) removeSavePost();
                  // else addSavePost();
                }}
              >
                <div className={styles.popoverListItemIcon}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16.48" height="20" viewBox="0 0 16.48 20">
                    <path
                      id="Path"
                      d="M3.714,0h9.052A3.666,3.666,0,0,1,16.48,3.616V18.192A1.833,1.833,0,0,1,14.623,20a1.889,1.889,0,0,1-1.16-.4L8.24,15.534,3.017,19.6a1.891,1.891,0,0,1-2.61-.282A1.779,1.779,0,0,1,0,18.192V3.616A3.666,3.666,0,0,1,3.714,0Z"
                      fill={saveColor}
                    />
                    <path
                      id="Path-2"
                      fill="#fff" //{saveId > 0 || post.saveCount > 0 ? "#b6b7b8" : "#fff"}
                      data-name="Path"
                      d="M1.857,0A1.833,1.833,0,0,0,0,1.808V16.383l5.223-4.069a1.9,1.9,0,0,1,2.32,0l5.223,4.069V1.808A1.833,1.833,0,0,0,10.909,0Z"
                      transform="translate(1.857 1.808)"
                    />
                  </svg>
                </div>
                <div className={styles.popoverListItemText}>
                  <Typography component="p" className={styles.popoverListItemTitle}>
                    {saveColor == '#6F52ED' ? 'Saved' : 'Save Post'}
                  </Typography>
                </div>
              </div>
            )}
            <CopyToClipboard
              // options={{ debug: props.debug, message: "" }}
              onCopy={() => setCopied(true)}
              text={
                post
                  ? post.visibleId == 4 && post.recordId > 0
                    ? `${window.location.origin}/manage-group/${encryptData(post.recordId)}/${encryptData(post.postId)}`
                    : `${window.location.origin}/public-search-page/post/${encryptData(post.postId)}`
                  : ''
              }
            >
              <div
                className={styles.popoverListItem}
                onClick={() => {
                  setCopied(true);
                }}
              >
                <div className={styles.popoverListItemIcon}>
                  {/* <svg
								xmlns="http://www.w3.org/2000/svg"
								width="16.48"
								height="20"
								viewBox="0 0 16.48 20"
							>
								<path
									id="Path"
									d="M3.714,0h9.052A3.666,3.666,0,0,1,16.48,3.616V18.192A1.833,1.833,0,0,1,14.623,20a1.889,1.889,0,0,1-1.16-.4L8.24,15.534,3.017,19.6a1.891,1.891,0,0,1-2.61-.282A1.779,1.779,0,0,1,0,18.192V3.616A3.666,3.666,0,0,1,3.714,0Z"
									fill="#b6b7b8"
								/>
								<path
									id="Path-2"
									fill="#fff"
									data-name="Path"
									d="M1.857,0A1.833,1.833,0,0,0,0,1.808V16.383l5.223-4.069a1.9,1.9,0,0,1,2.32,0l5.223,4.069V1.808A1.833,1.833,0,0,0,10.909,0Z"
									transform="translate(1.857 1.808)"
								/>
							</svg> */}
                  <ContentCopyOutlinedIcon
                    sx={{
                      color: '#b6b7b8',
                      margin: '5px',
                    }}
                  />
                </div>
                <div className={styles.popoverListItemText}>
                  <Typography component="p" className={styles.popoverListItemTitle}>
                    Copy Post Link
                  </Typography>
                </div>
              </div>
            </CopyToClipboard>
            {userStatus.acctId == post.acctId ? (
              <div
                className={styles.popoverListItem}
                onClick={() => {
                  removeSocialPost();
                  setAnchorEl(null);
                }}
              >
                <div className={styles.popoverListItemIcon}>
                  <DeleteForeverOutlinedIcon
                    sx={{
                      color: '#b6b7b8',
                      margin: '5px',
                    }}
                  />
                </div>
                <div className={styles.popoverListItemText}>
                  <Typography component="p" className={styles.popoverListItemTitle}>
                    Delete Post
                  </Typography>
                </div>
              </div>
            ) : (
              <div>
                <div
                  className={styles.popoverListItem}
                  onClick={() => {
                    setReportModal(!reportModal);
                  }}
                >
                  <div className={styles.popoverListItemIcon}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
                      <path
                        id="Shape"
                        d="M13.437,0H5.563L0,5.563v7.874L5.563,19h7.874L19,13.437V5.563Z"
                        transform="translate(1 1)"
                        fill="#fff"
                        stroke="#b6b7b8"
                        strokeWidth="2"
                      />
                      <g id="Group_1" data-name="Group 1" transform="translate(9.5 4.5)">
                        <circle id="Oval" cx="1" cy="1" r="1" transform="translate(0 10)" fill="#b6b7b8" />
                        <path id="Path" d="M0,0H2V8H0Z" fill="#b6b7b8" />
                      </g>
                    </svg>
                  </div>
                  <div className={styles.popoverListItemText}>
                    <Typography component="p" className={styles.popoverListItemTitle}>
                      Report Post
                    </Typography>
                  </div>
                </div>

                {reportModal && (
                  <ReportModal
                    open={reportModal}
                    onClose={() => {
                      setReportModal(false);
                      setAnchorEl(null);
                    }}
                    recordType={'Report'}
                    reportReasonsList={reportReasonsList}
                    reportTypeId={2}
                    recordId={post.postId}
                    reportPost={reportPost}
                  />
                )}
                <Typography component="div" className={styles.popoverListItem} onClick={hidePost}>
                  <Typography component="div" className={styles.popoverListItemIcon}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 20 20">
                      <path id="Path" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#b5b5be" />
                      <path
                        id="Path-2"
                        data-name="Path"
                        d="M8.182,0a8.182,8.182,0,1,0,8.182,8.182A8.182,8.182,0,0,0,8.182,0Z"
                        transform="translate(1.818 1.818)"
                        fill="#fff"
                      />
                      <path
                        id="Path-3"
                        fill="#b5b5be"
                        data-name="Path"
                        d="M2.195,3.48.266,1.552A.909.909,0,0,1,1.552.266L3.48,2.195,5.409.266A.909.909,0,0,1,6.695,1.552L4.766,3.48,6.695,5.409A.909.909,0,0,1,5.409,6.695L3.48,4.766,1.552,6.695A.909.909,0,0,1,.266,5.409L2.195,3.48Z"
                        transform="translate(6.364 6.364)"
                      />
                    </svg>
                  </Typography>
                  <Typography component="div" className={styles.popoverListItemText}>
                    <Typography component="p" className={styles.popoverListItemTitle}>
                      Hide Post
                    </Typography>
                    <Typography component="p" className={styles.popoverListItemDescription}>
                      Remove post from your feed
                    </Typography>
                  </Typography>
                </Typography>

                <Typography component="div" className={styles.popoverListItem} onClick={hideAll}>
                  <Typography component="div" className={styles.popoverListItemIcon}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                      <g id="Path" transform="translate(0 0)" fill="#fff" strokeMiterlimit="10">
                        <path d="M7,0h8a7,7,0,0,1,7,7v8a7,7,0,0,1-7,7H7a7,7,0,0,1-7-7V7A7,7,0,0,1,7,0Z" stroke="none" />
                        <path
                          d="M 7 2 C 4.242990493774414 2 2 4.242990493774414 2 7 L 2 15 C 2 17.75700950622559 4.242990493774414 20 7 20 L 15 20 C 17.75700950622559 20 20 17.75700950622559 20 15 L 20 7 C 20 4.242990493774414 17.75700950622559 2 15 2 L 7 2 M 7 0 L 15 0 C 18.86598968505859 0 22 3.134010314941406 22 7 L 22 15 C 22 18.86598968505859 18.86598968505859 22 15 22 L 7 22 C 3.134010314941406 22 0 18.86598968505859 0 15 L 0 7 C 0 3.134010314941406 3.134010314941406 0 7 0 Z"
                          stroke="none"
                          fill="#b5b5be"
                        />
                      </g>
                      <g id="Path-2" data-name="Path" transform="translate(7 7)" fill="none" strokeMiterlimit="10">
                        <path
                          d="M2.414,3.828.293,1.707A1,1,0,0,1,1.707.293L3.828,2.414,5.95.293A1,1,0,0,1,7.364,1.707L5.243,3.828,7.364,5.95A1,1,0,0,1,5.95,7.364L3.828,5.243,1.707,7.364A1,1,0,0,1,.293,5.95L2.414,3.828Z"
                          stroke="none"
                        />
                        <path
                          d="M 1.000001907348633 -4.76837158203125e-07 C 1.255924701690674 -4.76837158203125e-07 1.511847019195557 0.09763193130493164 1.707107067108154 0.2928972244262695 L 3.828427076339722 2.414216995239258 L 5.949747085571289 0.2928972244262695 C 6.145007133483887 0.09763193130493164 6.40092945098877 -4.76837158203125e-07 6.656852245330811 -4.76837158203125e-07 C 6.912774562835693 -4.76837158203125e-07 7.168696880340576 0.09763193130493164 7.363956928253174 0.2928972244262695 C 7.754487037658691 0.6834168434143066 7.754487037658691 1.316586971282959 7.363956928253174 1.707107067108154 L 5.242637157440186 3.828427076339722 L 7.363956928253174 5.949747085571289 C 7.754487037658691 6.340267181396484 7.754487037658691 6.973437309265137 7.363956928253174 7.363956928253174 C 7.168696880340576 7.559222221374512 6.912774562835693 7.656854629516602 6.656852245330811 7.656854629516602 C 6.40092945098877 7.656854629516602 6.145007133483887 7.559222221374512 5.949747085571289 7.363956928253174 L 3.828427076339722 5.242637157440186 L 1.707107067108154 7.363956928253174 C 1.511847019195557 7.559222221374512 1.255924701690674 7.656854629516602 1.000001907348633 7.656854629516602 C 0.74407958984375 7.656854629516602 0.4881572723388672 7.559222221374512 0.2928972244262695 7.363956928253174 C -0.09763288497924805 6.973437309265137 -0.09763288497924805 6.340267181396484 0.2928972244262695 5.949747085571289 L 2.414216995239258 3.828427076339722 L 0.2928972244262695 1.707107067108154 C -0.09763288497924805 1.316586971282959 -0.09763288497924805 0.6834168434143066 0.2928972244262695 0.2928972244262695 C 0.4881572723388672 0.09763193130493164 0.74407958984375 -4.76837158203125e-07 1.000001907348633 -4.76837158203125e-07 Z"
                          stroke="none"
                          fill="#b5b5be"
                        />
                      </g>
                    </svg>
                  </Typography>
                  <Typography component="div" className={styles.popoverListItemText}>
                    <Typography component="p" className={styles.popoverListItemTitle}>
                      Hide all from &ldquo;{post.firstName}&ldquo;
                    </Typography>
                    <Typography component="p" className={styles.popoverListItemDescription}>
                      Stop seeing post from this person
                    </Typography>
                  </Typography>
                </Typography>

                <Typography component="div" className={styles.popoverListItem}>
                  <Typography component="div" className={styles.popoverListItemIcon}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16.84" height="19.88" viewBox="0 0 16.84 19.88">
                      <g id="Path" transform="translate(3.622)" fill="#fff" strokeMiterlimit="10">
                        <path
                          d="M4.655,9.41A4.68,4.68,0,0,0,9.31,4.705,4.68,4.68,0,0,0,4.655,0,4.68,4.68,0,0,0,0,4.705,4.68,4.68,0,0,0,4.655,9.41Z"
                          stroke="none"
                        />
                        <path
                          d="M 4.65500020980835 7.410000324249268 C 6.118970394134521 7.410000324249268 7.310000419616699 6.196540355682373 7.310000419616699 4.705000400543213 C 7.310000419616699 3.213460445404053 6.118970394134521 2.000000476837158 4.65500020980835 2.000000476837158 C 3.191030263900757 2.000000476837158 2.000000238418579 3.213460445404053 2.000000238418579 4.705000400543213 C 2.000000238418579 6.196540355682373 3.191030263900757 7.410000324249268 4.65500020980835 7.410000324249268 M 4.65500020980835 9.410000801086426 C 2.084110260009766 9.410000801086426 2.098083484725066e-07 7.303500175476074 2.098083484725066e-07 4.705000400543213 C 2.098083484725066e-07 2.106500387191772 2.084110260009766 4.005432003850729e-07 4.65500020980835 4.005432003850729e-07 C 7.225890159606934 4.005432003850729e-07 9.310000419616699 2.106500387191772 9.310000419616699 4.705000400543213 C 9.310000419616699 7.303500175476074 7.225890159606934 9.410000801086426 4.65500020980835 9.410000801086426 Z"
                          stroke="none"
                          fill={followProfileColor}
                        />
                      </g>
                      <g id="Path-2" data-name="Path" transform="translate(0 9.47)" fill="#fff" strokeMiterlimit="10">
                        <path d="M8.42,0C3.829,0,.1,4.64,0,10.41H16.84C16.745,4.64,13.011,0,8.42,0Z" stroke="none" />
                        <path
                          d="M 8.420000076293945 2 C 5.478047370910645 2 2.956839561462402 4.733343601226807 2.21738338470459 8.409999847412109 L 14.62261772155762 8.409999847412109 C 13.88316059112549 4.733343601226807 11.36195278167725 2 8.420000076293945 2 M 8.420000076293945 0 C 13.01138973236084 0 16.7446403503418 4.640219688415527 16.84000015258789 10.40999984741211 L 0 10.40999984741211 C 0.09535980224609375 4.640219688415527 3.828610420227051 0 8.420000076293945 0 Z"
                          stroke="none"
                          fill={followProfileColor}
                        />
                      </g>
                    </svg>
                  </Typography>
                  <Typography
                    component="div"
                    className={styles.popoverListItemText}
                    onClick={() => {
                      if (!followProfileId) followProfile();
                      else unfollowProfile();
                    }}
                  >
                    <Typography component="p" className={styles.popoverListItemTitle}>
                      {followProfileId ? 'Unfollow ' : 'Follow '}
                      &ldquo;{post.firstName}&ldquo;
                    </Typography>
                    <Typography component="p" className={styles.popoverListItemDescription}>
                      {followProfileId ? 'Disconnect with' : 'Connect with'} this person
                    </Typography>
                  </Typography>
                </Typography>
              </div>
            )}
          </div>
        </Popover>
      ) : null}
      {post && !hideReactionBar ? (
        <Popover
          id={anchorShareEl ? 'simple-popover' : undefined}
          open={anchorShareEl ? true : false}
          anchorEl={anchorShareEl}
          onClose={() => {
            setAnchorShareEl(null);
          }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          anchorPosition={{ top: 10, left: 10 }}
          anchorReference="anchorEl"
        >
          <div className={styles.popoverBox}>
            {userStatus.acctId != post.acctId ? (
              <div>
                <div className={styles.popoverListItem} onClick={shareToFeed}>
                  <Typography component="div" className={styles.popoverListItemText}>
                    <Typography component="p" className={styles.popoverListItemTitle}>
                      {post.visibleId == 4 ? 'Share to group feed' : 'Share to feed'}
                    </Typography>
                    <Typography component="p" className={styles.popoverListItemDescription}>
                      {post.visibleId == 4 ? 'Share this post on the group feed' : 'Share this post on your feed'}
                    </Typography>
                  </Typography>
                </div>

                <div className={styles.popoverListItem} onClick={writePost}>
                  <Typography component="div" className={styles.popoverListItemText}>
                    <Typography component="p" className={styles.popoverListItemTitle}>
                      Write post
                    </Typography>
                    <Typography component="p" className={styles.popoverListItemDescription}>
                      Compose a post with this content
                    </Typography>
                  </Typography>
                </div>
              </div>
            ) : null}
            {/* <Typography
							component="div"
							className={styles.popoverListItem}
							onClick={shareToChat}
						>
							<Typography
								component="div"
								className={styles.popoverListItemText}
							>
								<Typography
									component="p"
									className={styles.popoverListItemTitle}
								>
									Share to Chat
								</Typography>
							</Typography>
						</Typography>

						<Typography
							component="div"
							className={styles.popoverListItem}
							onClick={shareToGroup}
						>
							<Typography
								component="div"
								className={styles.popoverListItemText}
							>
								<Typography
									component="p"
									className={styles.popoverListItemTitle}
								>
									Share to Group
								</Typography>
							</Typography>
						</Typography> */}
            <CopyToClipboard
              // options={{ debug: props.debug, message: "" }}
              onCopy={() => setCopied(true)}
              text={
                post
                  ? post.visibleId == 4 && post.recordId > 0
                    ? `${window.location.origin}/manage-group/${encryptData(post.recordId)}/${encryptData(post.postId)}`
                    : `${window.location.origin}/public-search-page/post/${encryptData(post.postId)}`
                  : ''
              }
            >
              <Typography
                component="div"
                className={styles.popoverListItem}
                onClick={() => {
                  setCopied(true);
                }}
              >
                <Typography component="div" className={styles.popoverListItemText}>
                  <Typography component="p" className={styles.popoverListItemTitle}>
                    Copy Post Link
                  </Typography>
                </Typography>
              </Typography>
            </CopyToClipboard>
          </div>
        </Popover>
      ) : null}
      {hashtagMatch && !hideReactionBar ? (
        <Popover
          id={anchorElHashtag ? 'simple-popover' : undefined}
          open={anchorElHashtag ? true : false}
          anchorEl={anchorElHashtag}
          onClose={() => {
            setAnchorElHashtag(null);
          }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <div className={styles.popoverBox}>
            <Typography
              component={Link}
              to={`/following-page/${encryptData(user.acctId)}`}
              className={classes.profileCard}
            >
              <div className={styles.popoverListItem} onClick={() => {}}>
                <div className={styles.popoverListItemIcon}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16.48" height="20" viewBox="0 0 16.48 20">
                    <path
                      id="Path"
                      d="M3.714,0h9.052A3.666,3.666,0,0,1,16.48,3.616V18.192A1.833,1.833,0,0,1,14.623,20a1.889,1.889,0,0,1-1.16-.4L8.24,15.534,3.017,19.6a1.891,1.891,0,0,1-2.61-.282A1.779,1.779,0,0,1,0,18.192V3.616A3.666,3.666,0,0,1,3.714,0Z"
                      fill="#b6b7b8"
                    />
                    <path
                      id="Path-2"
                      fill="#fff" //{saveId > 0 || post.saveCount > 0 ? "#b6b7b8" : "#fff"}
                      data-name="Path"
                      d="M1.857,0A1.833,1.833,0,0,0,0,1.808V16.383l5.223-4.069a1.9,1.9,0,0,1,2.32,0l5.223,4.069V1.808A1.833,1.833,0,0,0,10.909,0Z"
                      transform="translate(1.857 1.808)"
                    />
                  </svg>
                </div>
                <div className={styles.popoverListItemText}>
                  <Typography component="p" className={styles.popoverListItemTitle}>
                    Manage Hashtag
                  </Typography>
                </div>
              </div>
            </Typography>
            <div>
              <div
                className={styles.popoverListItem}
                onClick={() => {
                  setReportModal(!reportModal);
                }}
              >
                <div className={styles.popoverListItemIcon}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
                    <path
                      id="Shape"
                      d="M13.437,0H5.563L0,5.563v7.874L5.563,19h7.874L19,13.437V5.563Z"
                      transform="translate(1 1)"
                      fill="#fff"
                      stroke="#b6b7b8"
                      strokeWidth="2"
                    />
                    <g id="Group_1" data-name="Group 1" transform="translate(9.5 4.5)">
                      <circle id="Oval" cx="1" cy="1" r="1" transform="translate(0 10)" fill="#b6b7b8" />
                      <path id="Path" d="M0,0H2V8H0Z" fill="#b6b7b8" />
                    </g>
                  </svg>
                </div>
                <div className={styles.popoverListItemText}>
                  <Typography component="p" className={styles.popoverListItemTitle}>
                    Report Hashtag
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          {reportModal && (
            <ReportModal
              open={reportModal}
              onClose={() => {
                setReportModal(false);
                setAnchorElHashtag(null);
              }}
              recordType={'Report'}
              reportReasonsList={reportReasonsList}
              reportTypeId={5}
              recordId={hashtagMatch.hashId}
              reportPost={reportPost}
            />
          )}
        </Popover>
      ) : null}
      {commentImageModal && !hideReactionBar ? (
        <CommentImageModal
          open={commentImageModal}
          onClose={() => {
            setCommentImageModal(false);
          }}
          pictureSource={commentImage}
          isEdit={commentImageIsEdit}
          fileName={e => {
            setFileName(e);
          }}
          saveCroppedPhoto={e => {
            setCommentImageModal(false);
            setCommentImageSaved(e);
          }}
        />
      ) : null}
      {poolResultUserListModal ? (
        <PollResultUserListModal
          open={poolResultUserListModal}
          onClose={() => {
            setPoolResultUserListModal(false);
          }}
          poll={poll}
        />
      ) : null}
      {reactionUserListModal && !hideReactionBar ? (
        <ReactionUserListModal
          open={reactionUserListModal}
          onClose={() => {
            setReactionUserListModal(false);
          }}
          comment={null}
          post={post}
          reactionList={reactionList}
          reactionType="post"
        />
      ) : null}
      {isViewAttachmentModalOpen ? (
        !isMobile ? (
          <PostMediaModal
            open={isViewAttachmentModalOpen}
            onClose={() => {
              setIsViewAttachmentModalOpen(false);
            }}
            post={post}
            reportTypesList={reportTypesList}
            reportReasonsList={reportReasonsList}
            updateFeedHandler={updateFeedHandler}
            isCopyPost={isCopyPost}
            isGroupMember={isGroupMember}
          />
        ) : (
          <PublicMediaModal
            open={isViewAttachmentModalOpen}
            onClose={() => {
              setIsViewAttachmentModalOpen(false);
            }}
            post={post}
          />
        )
      ) : null}
    </Fragment>
  );
};

export default ProfileViewActivityCard;
