import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getFavProfileList } from "configurations/redux/favProfileSlice.js";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import Header from "views/Component/Header.js";
import Footer from "views/Component/Footer.js";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";

import favProfilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/FavouriteProfilePage/favProfilePageStyle.js";

import NoActivityStatusModal from "../NoActivityStatusModal/NoActivityStatusModal";
import LoadingModal from "views/Component/LoadingModal";
import FavProfileCard from "./FavProfileCard";
import FavProfileSection from "./FavProfileSection.js";
import Pagination from "../../Component/PaginationWithPage.js";
import Backdrop from "../../Component/Backdrop.js";
import { gaPageView } from "configurations/configurations";

const useStyles = makeStyles(favProfilePageStyle);
export default function FavProfilePage() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [currentPage, setCurrentPage] = React.useState(1);
	const [profilePerPage, setProfilePerPage] = React.useState(6);

	const userStatus = useSelector((state) => state.auth.user);
	const isUpdated = useSelector((state) => state.favProfile.isUpdated);
	const isLoading = useSelector((state) => state.favProfile.isLoading);
	const loadingFavProfileList = useSelector(
		(state) => state.favProfile.loadingFavProfileList
	);
	const isRemoved = useSelector((state) => state.favProfile.isRemoved);
	const profileList = useSelector((state) => state.favProfile.profileList);
	const requestProfile = {
		email: userStatus.email,
		acctId: userStatus.acctId,
		accessToken: localStorage.getItem("token"),
		lastRecordSize: 0,
		requestRecordSize: 6,
		paginationClick: false,
	};
	const indexOfLastProfile = currentPage * profilePerPage;
	const indexOfFirstProfile = indexOfLastProfile - profilePerPage;
	const currentProfileList = profileList.slice(
		indexOfFirstProfile,
		indexOfLastProfile
	);
	/*  const ResultCard = profileList.map((item, index) => {
    return (
      <GridItem xs={12} sm={12} md={4} key={index}>
        <FavProfileCard key={item.profileId} item={item} />
      </GridItem>
    );
  }); */

	React.useEffect(() => {
		gaPageView(window.location.pathname);
		dispatch(getFavProfileList(requestProfile));
		var timer = setInterval(() => {
			if (profileList && profileList.length > 0) {
				clearInterval(timer);
				window.scrollTo(0, 0);
			}
		}, 50);
	}, []);

	const paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
		var indexOfFirstProfile = pageNumber * profilePerPage - profilePerPage;
		getFavProfile(indexOfFirstProfile);
		window.scrollTo(0, 0);
	};

	React.useEffect(() => {
		console.log(currentPage);
		if (isRemoved) {
			var oldPage = currentPage;
			var newPage = 0;
			if (profileList.length > 0) newPage = currentPage;
			else newPage = currentPage - 1;
			var indexOfFirstProfile = newPage * profilePerPage - profilePerPage;
			console.log(newPage);
			setCurrentPage(newPage);
			getFavProfile(indexOfFirstProfile);
			if (newPage != oldPage) window.scrollTo(0, 0);
		}
	}, [isRemoved]);

	const getFavProfile = (indexOfFirstProfile) => {
		const requestProfile = {
			email: userStatus.email,
			acctId: userStatus.acctId,
			accessToken: localStorage.getItem("token"),
			lastRecordSize: indexOfFirstProfile,
			requestRecordSize: profilePerPage,
			paginationClick: true,
		};
		dispatch(getFavProfileList(requestProfile));
	};
	console.log(profileList);

	const NoFavProfileList = () => {
		return (
			<Card className={classes.cardFrame}>
				<CardBody>
					<GridContainer justifyContent="center">
						<GridItem xs={12} sm={12} md={12} className={classes.itemCenter}>
							<h5 className={classes.title}>No favourite profile yet</h5>
						</GridItem>
						<GridItem className={classes.itemCenter}>
							<Link
								className={classes.textDeco}
								to={{
									pathname: "/search-profile-page",
								}}
								color="transparent"
							>
								<Button round color="oppty">
									<b>Search Profile</b>
								</Button>
							</Link>
						</GridItem>
					</GridContainer>
				</CardBody>
			</Card>
		);
	};

	return (
		<div>
			<Header />
			<div className={classes.main + " " + classes.mainRaised}>
				<div className={classes.container}>
					{isLoading ? (
						<Backdrop />
					) : isUpdated ? (
						<React.Fragment>
							<GridContainer
								justifyContent="space-between"
								alignContent="center"
								alignItems="center"
								/* className={classes.titleMargin} */
							>
								<GridItem xs={12} sm={12} md={12} lg={12}>
									<h4 className={classes.title}>
										Manage Your Favourite Profile
									</h4>
								</GridItem>
							</GridContainer>

							<GridContainer>
								<GridItem>
									{profileList && profileList.length > 0 ? (
										<div>
											<FavProfileSection profileList={profileList} />
											<div className={classes.paginationAligned}>
												<Pagination
													totalPosts={profileList[0].profileCount}
													postsPerPage={profilePerPage}
													paginate={paginate}
													page={currentPage}
												/>
											</div>
										</div>
									) : (
										<NoFavProfileList />
									)}
								</GridItem>
							</GridContainer>
						</React.Fragment>
					) : (
						<NoActivityStatusModal />
					)}
					{/* <GridContainer justifyContent="center">
            <GridItem xs={12} sm={12} md={12}>
              <h3 className={classes.cardTitle + " " + classes.textCenter}>
                Manage Your Favourite Profiles
              </h3>
            </GridItem>
          </GridContainer>

          <GridContainer justifyContent="center">
            <Card>
              <CardBody>
                {isUpdated ? (
                  isLoading ? (
                    // <LoadingModal />
                    <Backdrop />
                  ) : profileList.length > 0 ? (
                    <GridContainer>
                      <FavProfileSection profileList={profileList} />
                      <GridItem>
                        <Pagination
                          totalPosts={profileList[0].profileCount}
                          postsPerPage={profilePerPage}
                          paginate={paginate}
                          page={currentPage}
                        />
                      </GridItem>
                    </GridContainer>
                  ) : (
                    <GridContainer justifyContent="center">
                      <GridItem xs={12} sm={12} md={12}>
                        <h5
                          className={
                            classes.cardTitle + " " + classes.textCenter
                          }
                        >
                          You have no favourite profile yet!
                        </h5>
                        <h5
                          className={
                            classes.cardTitle + " " + classes.textCenter
                          }
                        >
                          Search for your Candidate!
                        </h5>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <Button
                          href="/search-profile-page"
                          fullWidth
                          color="oppty"
                        >
                          Search Profile
                        </Button>
                      </GridItem>
                    </GridContainer>
                  )
                ) : (
                  <NoActivityStatusModal />
                )}
              </CardBody>
            </Card>
          </GridContainer> */}
					{loadingFavProfileList ? <Backdrop /> : null}
				</div>
			</div>
			<Footer />
		</div>
	);
}
