import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import ConnectionCardStyle from "assets/jss/material-kit-pro-react/components/connectionPageStyle.js";
import { getFollowProfileList } from "configurations/redux/manageConnectionSlice.js";

import Pagination from "views/Component/PaginationWithPage.js";
import NoResultCard from "./NoResultCard.js";
import SmallCardLoading from "../ConnectionPage/SmallCardLoading.js";
import FollowCard from "./FollowCard.js";
import { getHashtagList } from "configurations/redux/manageConnectionSlice.js";
const useStyles = makeStyles(ConnectionCardStyle);

export default function HashtagComponent() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const hashtagRecords = useSelector(
		(state) => state.manageConnection.hashtagRecords
	);
	const userStatus = useSelector((state) => state.auth.user);
	const [currentPage, setCurrentPage] = React.useState(1);
	const [profilePerPage, setProfilePerPage] = React.useState(10);
	const indexOfLast = currentPage * profilePerPage;
	const indexOfFirst = indexOfLast - profilePerPage;
	const isLoading = useSelector((state) => state.manageConnection.isLoading);
	const isStatusUpdated = useSelector(
		(state) => state.manageConnection.isStatusUpdated
	);
	const followHashtagCount = useSelector(
		(state) => state.manageConnection.followHashtagCount
	);

	React.useEffect(() => {
		const response = {
			acctId: userStatus.acctId,
			email: userStatus.email,
			accessToken: localStorage.getItem("token"),
			lastRecordSize: 0,
			requestRecordSize: 10,
			keyword: "",
		};
		dispatch(getHashtagList(response));
	}, []);
	React.useEffect(() => {
		if (isStatusUpdated) {
			var newPage = 0;
			if (hashtagRecords.length > 0) newPage = currentPage;
			else newPage = currentPage - 1;
			var indexOfFirst = newPage * profilePerPage - profilePerPage;
			setCurrentPage(newPage);

			const requestProfile = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				keyword: "",
				lastRecordSize: indexOfFirst,
				requestRecordSize: profilePerPage,
			};
			dispatch(getHashtagList(requestProfile));
		}
	}, [isStatusUpdated]);
	const paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
		var indexOfFirst = pageNumber * profilePerPage - profilePerPage;

		const requestProfile = {
			acctId: userStatus.acctId,
			accessToken: localStorage.getItem("token"),
			email: userStatus.email,
			keyword: "",
			lastRecordSize: indexOfFirst,
			requestRecordSize: profilePerPage,
		};
		dispatch(getHashtagList(requestProfile));
	};

	const ProfileList =
		hashtagRecords &&
		hashtagRecords.length > 0 &&
		hashtagRecords.map((item) => {
			return (
				<div key={item.followId}>
					<FollowCard item={item} status={"Hashtag"} />
				</div>
			);
		});
	const loadingFields = [];
	for (let i = 0; i < profilePerPage - hashtagRecords.length; i++) {
		loadingFields.push(
			<div key={i}>
				<SmallCardLoading />
			</div>
		);
	}
	return (
		<Card className={classes.cardFrame}>
			<CardBody className={classes.cardBodyPadding}>
				<h4 className={classes.componentTitle}>Followed Hashtag</h4>
				{isLoading &&
					loadingFields.map((item) => {
						return item;
					})}
				<div className={classes.mainContainer}>
					{ProfileList}{" "}
					{!isLoading && hashtagRecords.length === 0 && (
						<NoResultCard description={"You have no following hashtag"} />
					)}{" "}
				</div>
				{hashtagRecords && hashtagRecords.length > 0 && (
					<Pagination
						totalPosts={followHashtagCount}
						postsPerPage={profilePerPage}
						paginate={paginate}
					/>
				)}
			</CardBody>
		</Card>
	);
}
