import { container } from "assets/jss/material-kit-pro-react.js";
const resumePageStyles = (theme) => ({
	body: {
		overflowY: "scroll",
		overflowX: "hidden",
		marginTop: "93px",
		height: "calc(100vh - 100px)",
		[theme.breakpoints.down("md")]: {
			marginTop: "85px",
		},
	},

	pageHeader: {
		position: "relative",
		display: "flex !important",
		alignItems: "center",
		margin: "0",
		border: "0",
		/* minHeight: "100vh", */
		height: "auto",
		color: "#fff",

		/* "&:before,&:after": {
			position: "absolute",
			zIndex: "1",
			width: "100%",
			height: "100%",
			display: "block",
			left: "0",
			top: "0",
			content: '""',
		}, */
	},

	container: {
		...container,
		zIndex: "5",
		/* "@media (min-width: 576px)": {
			flexBasis: "98%",
		},
		"@media (min-width: 768px)": {
			flexBasis: "98%",
		},
		"@media (min-width: 960px)": {
			flexBasis: "98%",
		},
		"@media (min-width: 992px)": {
			flexBasis: "98%",
		},
		"@media (min-width: 1200px)": {
			flexBasis: "98%",
		}, */
		"@media (min-width: 576px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 768px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 992px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 1200px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 1400px)": {
			maxWidth: "95vw",
		},
	},
	leftSideBar: {
		flexBasis: "19%",
		maxWidth: "250px",
		padding: "16px 10px",
	},
	rightSideBar: {
		flexBasis: "23%",
		maxWidth: "285px",
		padding: "16px 10px",
	},
	emptyStateContent: {
		margin: "30px 0px",
		textAlign: "center",

		"& > p": {
			color: "#6D7278",
			fontSize: "14px",
			fontWeight: 500,
			fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
			textAlign: "center",
		},

		"& > img": {
			margin: "30px 0px",
			width: "50%",
			objectFit: "cover",
			objectPosition: "center",
		},
	},

	gridMain: {
		flexBasis: "59%",
		padding: "16px 10px",
		"@media (max-width: 1300px)": {
			flexBasis: "98%",
		},
	},

	resumeMain: {
		padding: "15px 15px 40px",
		borderRadius: "15px",
		backgroundColor: "#fff",
	},

	resumeHeader: {
		display: "flex",
		alignItems: "center",
		backgroundColor: "#F4F7FF",
		width: "100%",
		height: "160px",
	},

	headerLeft: {
		display: "flex",
		alignItems: "center",
		width: "270px",
		height: "100%",

		"& > img": {
			width: "100%",
			height: "100%",
			objectFit: "cover",
			objectPostion: "center",
		},
	},

	headerRight: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		height: "100%",

		"& > h2": {
			marginBottom: "10px",
			color: "#171725",
			fontSize: "16px",
			fontWeight: 500,
			fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		},
	},

	headerPoint: {
		display: "flex",
		alignItems: "center",
		margin: "5px 0",
		color: "#4A4A4A",
		fontSize: "12px",
		fontWeight: 400,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,

		"& > svg": {
			marginRight: "10px",
			width: "18px",
			height: "18px",
		},
	},

	resumeContentContainer: {
		margin: "20px 20px 0",
	},

	resumeContentHeader: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		margin: "10px 0px",
		"& > h3": {
			color: "#000000",
			fontSize: "16px",
			fontWeight: 700,
			fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		},

		"& > button": {
			color: "#6F52ED",
			fontSize: "15px",
			fontWeight: 500,
			fontFamily: `"Roboto", "Helvetica", sans-serif !important`,

			"& svg": {
				marginRight: "5px",
				width: "14px",
				height: "14px",
			},
		},
	},

	resumeContent: {
		marginTop: "5px",
	},

	singleResumeCard: {
		width: "250px",
	},
	cardFrame: {
		borderRadius: "18px",
		boxShadow: "none",
		marginTop: "10px",
		marginBottom: "10px",
		[theme.breakpoints.down("xs")]: {
			marginTop: "10px",
			marginBottom: "10px",
		},
	},
	resumeThumbnail: {
		width: "250px",
		height: "250px",

		"& > img": {
			width: "100%",
			height: "100%",
			objectFit: "cover",
			objectPosition: "center",
		},
	},

	resumeDetail: {
		color: "#0A1F44",
		fontSize: "14px",
		fontWeight: 400,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,

		"& > div": {
			display: "flex",
			alignItems: "center",
			margin: "5px 0",
		},

		"& > div > div:first-child": {
			width: "110px",
		},
	},

	editProfileModalBox: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		overflow: "hidden",
		borderRadius: "15px",
		backgroundColor: "#fff",
		maxWidth: "1000px",
		minWidth: "720px",
		width: "65vw",
		maxHeight: "1000px",
		height: "90vh",

		"& > button": {
			position: "absolute",
			top: "13px",
			right: "15px",
			padding: "0",
			minWidth: "fit-content",

			"& svg": {
				width: "10px",
				height: "10px",
			},
		},
	},
});

export default resumePageStyles;
