import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getProfiles } from "configurations/redux/userSlice.js";
import Avtar from "assets/images/avtar.png";
import CloseIcon from "@mui/icons-material/Close";
import {
    Card,
    CardContent,
    Typography,
    Button,
    Input,
    IconButton,
    makeStyles,
    Popover,
    ClickAwayListener,
    Grow,
    Paper,
    Popper,
    Modal,
    Box
} from "@material-ui/core";
import CardBody from 'components/Card/CardBody.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from '../../components/Grid/GridItem.js';
import { useSnackbar } from "notistack";
import Cookies from 'js-cookie';
import Checkbox from '@mui/material/Checkbox';
import moment from 'moment';
import {
    zoomAPIIntegrationById
} from "configurations/redux/jobManagementSlice.js";
import { instanceUrl, encryptData } from 'configurations/configurations.js';
import {
    getEventsByAcct,
    getEventDetailByEventId,
    confirmEventScheduleStatus,
    getReportReasonByType,
    updateUserEventReschedules,
    declineEventScheduleStatus,
    createReport
} from 'configurations/redux/userDashboardSlice';

import { updatePrivacySingleSetting, getPrivacySettings } from 'configurations/redux/userSlice.js';
import { getCandidateSelectJobPosts } from 'configurations/redux/jobManagementSlice.js';

import ResultJobModal from '../Page/SearchJobPage/ResultJobModal.js';
import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
import { Eventcalendar, getJson, toast } from '@mobiscroll/react';
import Fb from 'assets/img/fb.png';
import Zoom from 'assets/img/zoom.png';
import Switch from '@mui/material/Switch';
import LandingPageFeedStyle from 'assets/jss/material-kit-pro-react/views/pageStyle/LandingPageFeed/LandingPageFeedStyle.js';

const useStyles = makeStyles(LandingPageFeedStyle);

const CalendarSection = (props) => {

    const history = useHistory();
    const userStatus = useSelector(state => state.auth.user);
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const [myEvents, setEvents] = React.useState([]);  // calendar functionality
    const [modalEventDetail, setModalEventDetail] = React.useState();
    const [modalScheduleEvents, setModalScheduleEvents] = React.useState([]);
    const [minDate, setMinDate] = useState();
    const [dateSelection1, setDateSelection1] = useState();
    const [dateSelection2, setDateSelection2] = useState();
    const [dateSelection3, setDateSelection3] = useState();
    const [timeSelection1, setTimeSelection1] = useState();
    const [timeSelection2, setTimeSelection2] = useState();
    const [timeSelection3, setTimeSelection3] = useState();
    const [reasonRescheduling, setReasonRescheduling] = useState('');
    const [confirmTimeSlot, setConfirmTimeSlot] = useState();
    const [reasonList, setReasonList] = useState([]);
    const [open22, setOpen22] = React.useState(false);
    const handleClose22 = () => { setOpen22(false); }
    const [proposedModal, setProposedModal] = React.useState(false);
    const handleCloseProposedModal = () => setProposedModal(false);
    const [confirmInterviewModal, setConfirmInterviewModal] = React.useState(false);
    const handleCloseConfirmInterviewModal = () => setConfirmInterviewModal(false);
    const [declineInterviewModal, setDeclineInterviewModal] = React.useState(false);
    const handleCloseDeclineModal = () => setDeclineInterviewModal(false);
    const [proposedSubmittedModal, setProposedSubmittedModal] = React.useState(false);
    const handleProposedSubmittedModal = () => setProposedSubmittedModal(false);
    const [upcomingInterviewAccepted, setUpcomingInterviewAccepted] = React.useState(false);
    const handleUpcomingInterviewAcceptedModal = () => setUpcomingInterviewAccepted(false);
    const [rescheduleInterviewModal, setRescheduleInterviewModal] = React.useState(false);
    const handleRescheduleInterviewModal = () => setRescheduleInterviewModal(false);
    const [rescheduleInterviewConfirmModal, setRescheduleInterviewConfirmModal] = React.useState(false);
    const handleRescheduleInterviewConfirmModal = () => setRescheduleInterviewConfirmModal(false);
    const [manageCalendarModal, setManageCalendarModal] = React.useState(false);
    const handleOpenCalendarModal = () => setManageCalendarModal(true);
    const handleCloseCalendarModal = () => setManageCalendarModal(false);
    const [interviewDate, setInterviewDate] = React.useState('')
    const [open222, setOpen222] = React.useState(false);
    const [openModel, setOpenModel] = React.useState(false);
    const handleOpen222 = () => setOpen222(true);
    const handleClose222 = () => setOpen222(false);
    const handleCloseModel = () => setOpenModel(false);
    const privacySetting = useSelector((state) => state.user.privacySetting);

    const label = { inputProps: { 'aria-label': 'Switch demo' } };
    const label1 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    const [jobViewModal, setJobViewModal] = React.useState(false);
    const onJobModelClose = () => {
        setJobViewModal(false);
    };


    useEffect(() => {
        var dtToday = new Date();

        var month = dtToday.getMonth() + 1;
        var day = dtToday.getDate();
        var year = dtToday.getFullYear();
        if (month < 10)
            month = '0' + month.toString();
        if (day < 10)
            day = '0' + day.toString();

        var maxDate = year + '-' + month + '-' + day;
        setMinDate(maxDate);

        callEvents();

    }, []);

    const callEvents = () => {
        let requestProfile = {
            email: userStatus.email,
            acctId: userStatus.acctId,
            accessToken: localStorage.getItem('token'),
            fromDate: '2021-01-01',
            toDate: '2022-12-31'
        };

        if (props.bizId) {
            requestProfile = {
                email: userStatus.email,
                acctId: userStatus.acctId,
                accessToken: localStorage.getItem('token'),
                fromDate: '2021-01-01',
                toDate: '2022-12-31',
                bizId: props.bizId
            };
        }

        const colors = ["#ff6d42", "#7bde83", "#7bde83", "#913aa7", "#6e7f29", "#bb0000", "#00aabb", "#cfc014", "#d7be0d", "#a71111", "#de3d83", "#f67944", "#a144f6", "#37bbe4", "#228c73", "#ca4747", "#56ca70", "#99ff33", "#e7b300", "#669900", "#6699ff", "#cc9900", "#339966", "#8701a9", "#cc6699", "#ff0066", "#99ccff"];

        dispatch(getEventsByAcct(requestProfile, (result) => {
            var arr = [];
            result.payload.eventList.map(function (event, index) {
                arr.push({
                    'color': colors[Math.floor(Math.random() * colors.length)], //Math.floor(Math.random() * colors.length),
                    'end': event.endDate,
                    'id': event.eventId,
                    'start': event.startDate,
                    'title': event.title,
                    'description': event
                });

            });

            if (arr.length > 0) {
                setEvents(arr);
            }

        }));
    }

    const handleDateOneRange = (e) => {
        setDateSelection1(e.target.value)
    }

    const handleDateTwoRange = (e) => {
        setDateSelection2(e.target.value)
    }

    const handleDateThreeRange = (e) => {
        setDateSelection3(e.target.value)
    }

    const handleTimeOneRange = (e) => {
        setTimeSelection1(e.target.value);
    }

    const zoomAPIIntegrationBy = () => {
        const requestProfile = {
            acctId: userStatus.acctId,
        };
        dispatch(zoomAPIIntegrationById(requestProfile))
    }

    const handleTimeTwoRange = (e) => {
        setTimeSelection2(e.target.value);
    }

    const handleTimeThreeRange = (e) => {
        setTimeSelection3(e.target.value);
    }

    const handleReasonRescheduling = (e) => {
        setReasonRescheduling(e.target.value);
    }

    const onEventClick = React.useCallback((event) => {

        const eventDetailRequested = {
            acctId: userStatus.acctId,
            email: userStatus.email,
            eventId: event.event.id,
            accessToken: localStorage.getItem('token')
        };

        dispatch(getEventDetailByEventId(eventDetailRequested, (result) => {
            if (result.payload.eventList[0]) {
                setModalEventDetail(result.payload.eventList[0]);
                if (result.payload.eventList[0].eventSchedule && result.payload.eventList[0].eventSchedule.eventScheduleList.length > 0) {
                    if (result.payload.eventList[0].eventSchedule.eventScheduleList.length == 1) {
                        setConfirmTimeSlot({ datetime: result.payload.eventList[0].eventSchedule.eventScheduleList[0].startDate, scheduleId: result.payload.eventList[0].eventSchedule.eventScheduleList[0].scheduleId, endDate: result.payload.eventList[0].eventSchedule.eventScheduleList[0].endDate });
                        //  setUpcomingInterviewAccepted(true);
                    } else {
                        result.payload.eventList[0].eventSchedule.eventScheduleList.map((event, index) => {
                            event.class = "timeDiv";
                        });
                        setModalScheduleEvents(result.payload.eventList[0].eventSchedule.eventScheduleList);
                    }
                }
            }

        }));

        console.log(event);
        //if (event.statusId == 1) {
        if (props.data == 'user') {
            setOpen22(true);
        } else if (props.data == 'corporate') {
            setOpenModel(true)
        }
        //}

    }, []);

    const activateDateSelection = (data) => {
        let views = (modalScheduleEvents).slice();
        for (let i in views) {
            views[i].class = 'timeDiv';
            if (views[i].scheduleId == data) {
                views[i].class = 'timeDiv active';
                var startdate = moment(views[i].startDate).format('YYYY-MM-DD HH:mm:ss');
                var returned_endate = moment(startdate).add(1, 'hours').format('YYYY-MM-DD HH:mm:ss');
                setConfirmTimeSlot({ datetime: views[i].startDate, scheduleId: views[i].scheduleId, endDate: returned_endate });
            } else {
                views[i].class = 'timeDiv';
            }
        }
        setModalScheduleEvents(views);
    }

    const setEventScheduleStatus = (id, status) => {
        if (confirmTimeSlot.scheduleId) {
            const payloadRequested = {
                acctId: userStatus.acctId,
                email: userStatus.email,
                accessToken: localStorage.getItem('token'),
                eventId: id,
                scheduleId: confirmTimeSlot.scheduleId,
                statusId: status
            };
            dispatch(confirmEventScheduleStatus(payloadRequested, (result) => {
                setOpen22(false);
                if (status == 1) {
                    setConfirmInterviewModal(true);
                }
                callEvents();
            }));
        } else {
            alert("Please make some selection out of the time slots mentioned.");
        }
    }

    const updateProfile = () => {
        const requestProfile = {
            acctId: userStatus.acctId,
            userId: userStatus.acctId,
            ipAddr: "",
            accessToken: localStorage.getItem("token"),
        };

        dispatch(getProfiles(requestProfile));
    }

    const declineEventSchedule = (id, statusId) => {
        const payloadRequested = {
            acctId: userStatus.acctId,
            email: userStatus.email,
            accessToken: localStorage.getItem('token'),
            eventId: id,
            statusId: statusId
        };

        dispatch(declineEventScheduleStatus(payloadRequested, (result) => {
            setOpen22(false);
            if (statusId == 3) {
                setDeclineInterviewModal(true);

                dispatch(getReportReasonByType({ reasonType: 9 }, (response) => {
                    if (response.payload && response.payload.length > 0) {
                        response.payload.map((reason, index) => {
                            reason.reasonCheckbox = 0;
                        });
                        setReasonList(response.payload);
                        callEvents();
                    }
                }));
            } else {
                callEvents();
            }
        }));
    }

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const onClickDismiss = (key) => () => {
        closeSnackbar(key);
    };

    const snackbarCustom = (title) => {
        const action = (key) => (
            <React.Fragment>
                <IconButton onClick={onClickDismiss(key)}>
                    <CloseIcon />
                </IconButton>
            </React.Fragment>
        );
        snackBarShow(title, "default", action);
    };

    const snackBarShow = (message, variant, action) => {
        // variant could be success, error, warning, info, or default
        enqueueSnackbar(message, {
            variant: variant,
            action,
        });
    };

    const rescheduleEventSchedule = () => {
        setUpcomingInterviewAccepted(false);
        setOpenModel(false);
        setOpen22(false);
        setUpcomingInterviewAccepted(false);
        setRescheduleInterviewModal(true);
    }

    const setReasonSelection = (data) => {
        let reasons = (reasonList).slice();
        for (let i in reasons) {
            if (reasons[i].reasonId == data) {
                reasons[i].reasonCheckbox = 1;
            } else {
                reasons[i].reasonCheckbox = 0;
            }
        }
        setReasonList(reasons);
    }

    const handleProposedTimingsModal = () => {
        setOpen22(false);
        setProposedModal(true);
    }

    const submitAlternateTimingsCandidate = (eventId, bit) => {
        var startdate1 = moment(dateSelection1 + 'T' + timeSelection1).format('YYYY-MM-DD HH:mm:ss');
        var returned_endate1 = moment(startdate1).add(1, 'hours').format('YYYY-MM-DD HH:mm:ss');

        var startdate2 = moment(dateSelection2 + 'T' + timeSelection2).format('YYYY-MM-DD HH:mm:ss');
        var returned_endate2 = moment(startdate2).add(1, 'hours').format('YYYY-MM-DD HH:mm:ss');

        var startdate3 = moment(dateSelection3 + 'T' + timeSelection3).format('YYYY-MM-DD HH:mm:ss');
        var returned_endate3 = moment(startdate3).add(1, 'hours').format('YYYY-MM-DD HH:mm:ss');

        if (startdate1 == 'Invalid date') {
            snackbarCustom("Select first time-slot.");
        }
        else if (startdate2 == 'Invalid date') {
            snackbarCustom("Select second time-slot.");
        }
        else if (startdate3 == 'Invalid date') {
            snackbarCustom("Select third time-slot.");
        }
        else if (moment(startdate2).isBefore(startdate1)) {
            snackbarCustom("Second time-slot cannot be the same or less is First or Third.");
        }
        else if (moment(startdate3).isBefore(startdate2)) {

            snackbarCustom("Third time-slot cannot be the same or less is First or Second.");
        }
        //  else if (slot_one_time.isBefore(slot_two_time) == false) {
        else {

            const payloadRequested = {
                accessToken: localStorage.getItem('token'),
                email: userStatus.email,
                acctId: userStatus.acctId,
                eventId: parseInt(eventId),
                schedules: [
                    {
                        "startDate": startdate1,
                        "endDate": returned_endate1
                    },
                    {
                        "startDate": startdate2,
                        "endDate": returned_endate2
                    },
                    {
                        "startDate": startdate3,
                        "endDate": returned_endate3
                    }
                ]
            };


            dispatch(updateUserEventReschedules(payloadRequested, (response) => {
                if (response.payload.result == true) {
                    callEvents();
                    setProposedModal(false);
                    if (bit == 0) {
                        setProposedSubmittedModal(true);
                    } else {
                        setRescheduleInterviewConfirmModal(true);
                    }
                    setRescheduleInterviewModal(false);
                }
            }));
        }

    }

    function in_array(array, id) {
        for (var i = 0; i < array.length; i++) {
            if (array[i].reasonCheckbox === id)
                return array[i];
        }
        return false;
    }

    const createReasonReport = () => {
        var result = in_array(reasonList, 1);
        if (result) {
            const payloadRequested = {
                acctId: userStatus.acctId,
                email: userStatus.email,
                accessToken: localStorage.getItem('token'),
                reportTypeId: 9,
                recordId: modalEventDetail.eventId,
                reasonId: result.reasonId,
                reportDesc: result.reasonDesc
            };

            dispatch(createReport(payloadRequested, (result) => {
                if (result.message) {
                    setDeclineInterviewModal(false);
                    callEvents();
                    // toast({
                    //     message: result.message
                    // });
                }

            }));
        }

    }

    const zoomAPIIntegration = async function handleZoom() {
        await fetch(`https://api.zoom.us/v2/users/neelbhardwaj97@gmail.com/meetings`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOm51bGwsImlzcyI6Im5qeEpzNUZyUUVTdU5WVTNqZF9menciLCJleHAiOjE2NTcwMjM5NzcsImlhdCI6MTY1NzAxODU3OH0.jAWYuBxqcRb9vSiu5r9KOyuIASXPx1W9CmYI7RA7pCE'
            },
            body: {
                "topic": "The title of your zoom meeting",
                "type": 2,
                "start_time": moment().format(),
                "duration": "45",
                "timezone": "Europe/Madrid",
                "agenda": "test",
                "recurrence": {
                    "type": 1,
                    "repeat_interval": 1
                },
                "settings": {
                    "host_video": "true",
                    "participant_video": "true",
                    "join_before_host": "False",
                    "mute_upon_entry": "False",
                    "watermark": "true",
                    "audio": "voip",
                    "auto_recording": "cloud"
                }
            }
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const view = React.useMemo(() => {
        return {
            schedule: { labels: 'all', type: 'week', }
        };
    }, []);

    const handlePrivacySetting = (setting) => {
        const newPrivacySetting = privacySetting.map(obj => {
            if (obj.settingId == setting.settingId) {
                return { ...obj, settingId: setting.settingId, settingName: setting.settingName, settingValue: setting.settingValue }
            }
            return obj
        });
        const request = {
            email: userStatus.email,
            acctId: userStatus.acctId,
            privacySetting: newPrivacySetting,
            accessToken: localStorage.getItem('token'),
        };
        dispatch(updatePrivacySingleSetting(request, (result) => {
            if (result.error == false) {
                updateProfile()
            }
        }));

    }

    useEffect(() => {
        const request = {
            email: userStatus.email,
            acctId: userStatus.acctId,
            accessToken: localStorage.getItem('token'),
        };
        dispatch(getPrivacySettings(request))
    }, []);

    const getJobPostModel = (jobPostSelId) => {
        console.log(jobPostSelId);
        const requestProfile = {
            acctId: userStatus.acctId,
            email: userStatus.email,
            accessToken: localStorage.getItem("token"),
            ipAddr: localStorage.getItem('ipAddr'),
            jobPostId: jobPostSelId,
            item: null,
        };
        dispatch(getCandidateSelectJobPosts(requestProfile));
        setJobViewModal(true);
    };

    return (
        <div>
            <Card className='upcomingCard'>
                <CardBody className='customCardBody customCardBodyDiv'>
                    <Eventcalendar
                        theme="ios"
                        themeVariant="light"
                        clickToCreate={false}
                        dragToCreate={false}
                        dragToMove={false}
                        dragToResize={false}
                        eventDelete={false}
                        data={myEvents}
                        view={view}
                        onEventClick={onEventClick}
                    />
                </CardBody>
            </Card>
            <a className='manageSetting' onClick={handleOpenCalendarModal}>Manage Calendar Settings</a>
            {/* <a className='manageSetting' onClick={() => { setUpcomingInterviewAccepted(true) }}>Zoom Modal</a> */}

            <Modal
                open={manageCalendarModal}
                onClose={handleCloseCalendarModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='customModal calendarPopup'
            >
                <Box className='customBox calendarBox manageCalender' sx={style}>
                    <div className='CusBox'>
                        <div className='calendarUpper'>
                            <Typography className='manageTitle'>Manage Calendar Settings</Typography>
                            <a className='crossBtn' onClick={handleCloseCalendarModal}><i class="fas fa-xmark"></i></a>
                        </div>
                        <div className='emailNotifications'>
                            <div className='emailNotificationsinner'>
                                <div className='emailNotificationTitle'>Manage Email Notifications</div>
                                <div className='emailNotiFirst'>
                                    <p>Receive All Email Notifications</p>
                                    <div className='emailFirstright'>
                                        <Switch {...label} defaultChecked={privacySetting.length > 13 && privacySetting[8].settingValue == 1 ? true : false} onClick={(e) => handlePrivacySetting({ 'settingId': 9, 'settingName': "emailNotification", 'settingValue': (e.target.checked == true ? 1 : 0) })} />
                                    </div>
                                </div>
                                <div className='emailNotiFirst'>
                                    <p>Email me when event status changes</p>
                                    <div className='emailFirstright'>
                                        <Switch {...label} defaultChecked={privacySetting.length > 13 && privacySetting[11].settingValue == 1 ? true : false} onClick={(e) => handlePrivacySetting({ 'settingId': 12, 'settingName': "emailEventStatusChange", 'settingValue': (e.target.checked == true ? 1 : 0) })} />
                                    </div>
                                </div>
                                <div className='emailNotiFirst'>
                                    <p>Email me for events I accepted</p>
                                    <div className='emailFirstright'>
                                        <Switch {...label} defaultChecked={privacySetting.length > 13 && privacySetting[12].settingValue == 1 ? true : false} onClick={(e) => handlePrivacySetting({ 'settingId': 13, 'settingName': "emailEventAccepted", 'settingValue': (e.target.checked == true ? 1 : 0) })} />
                                    </div>
                                </div>
                                <div className='emailNotiFirst'>
                                    <p>Email me a reminder 1 day before event</p>
                                    <div className='emailFirstright'>
                                        <Switch {...label} defaultChecked={privacySetting.length > 13 && privacySetting[13].settingValue == 1 ? true : false} onClick={(e) => handlePrivacySetting({ 'settingId': 14, 'settingName': "emailEventRemindOneDayBefore", 'settingValue': (e.target.checked == true ? 1 : 0) })} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>

            {/* Reschedule Interview */}
            <Modal
                open={rescheduleInterviewModal}
                onClose={handleRescheduleInterviewModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='customModal calendarPopup'
            >
                <Box className='customBox calendarBox rescheduleInterview' sx={style}>
                    <div className='CusBox'>
                        <div className='rescheduleInner'>
                            <div className='rescheduleInnerLeft'>
                                <i class="fas fa-circle-exclamation customIcon"></i>
                                <div className='rescheduleTitle'>By rescheduling the interview,
                                    you agree to these points:</div>
                                <ul className='rescheduleUl'>
                                    <li><span className='dotSpan'></span>
                                        <span className='textSpan'>The new interview slot is only confirmed once it has been accepted by the Employer. </span>
                                    </li>
                                    <li><span className='dotSpan'></span>
                                        <span className='textSpan'>You are allowed up to a maximum of 3 reschedules per job application. </span>
                                    </li>
                                    <li><span className='dotSpan'></span>
                                        <span className='textSpan'>You are not allowed to reschedule lless than 1 hour before your interview time. </span>
                                    </li>
                                    <li><span className='dotSpan'></span>
                                        <span className='textSpan'>If you are unable to make it for your interview less than 1 hour before the scheduled time, please contact the Employer directly.</span>
                                    </li>
                                </ul>
                            </div>
                            <div className='rescheduleInnerRight'>
                                <div className='calendarUpper'>
                                    <Typography className='manageTitle'>Reschedule Interview</Typography>
                                    <a className='crossBtn' onClick={handleRescheduleInterviewModal}><i class="fas fa-xmark"></i></a>
                                </div>
                                <div className='emailNotifications'>
                                    <p className='submitP'>Submit 3 alternative dates and/or timings of your availability</p>
                                    <div className='emailNotificationsinner'>
                                        <GridContainer justifyContent="center" spacing={6}>
                                            <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Typography className='inputOuter1'>
                                                    <label className='labelCustom'>Timeslot 1 Date</label>
                                                    <input className='customInput custom1' type='date' value={dateSelection1} onChange={handleDateOneRange} min={minDate} />
                                                </Typography>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Typography className='inputOuter1'>
                                                    <label className='labelCustom'>Timeslot 1 Time</label>
                                                    <input className='customInput custom1' type='time' value={timeSelection1} onChange={handleTimeOneRange} />
                                                </Typography>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer justifyContent="center" spacing={6}>
                                            <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Typography className='inputOuter1'>
                                                    <label className='labelCustom'>Timeslot 2 Date</label>
                                                    <input className='customInput custom1' type='date' value={dateSelection2} onChange={handleDateTwoRange} min={minDate} />
                                                </Typography>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Typography className='inputOuter1'>
                                                    <label className='labelCustom'>Timeslot 2 Time</label>
                                                    <input className='customInput custom1' type='time' value={timeSelection2} onChange={handleTimeTwoRange} />
                                                </Typography>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer justifyContent="center" spacing={6}>
                                            <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Typography className='inputOuter1'>
                                                    <label className='labelCustom'>Timeslot 3 Date</label>
                                                    <input className='customInput custom1' type='date' value={dateSelection3} onChange={handleDateThreeRange} min={minDate} />
                                                </Typography>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Typography className='inputOuter1'>
                                                    <label className='labelCustom'>Timeslot 3 Time</label>
                                                    <input className='customInput custom1' type='time' value={timeSelection3} onChange={handleTimeThreeRange} />
                                                </Typography>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer justifyContent="center" spacing={6}>
                                            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Typography className='inputOuter1'>
                                                    <label className='labelCustom'>Reason for Rescheduling</label>
                                                    <input className='customInput custom1' type='text' value={reasonRescheduling} onChange={handleReasonRescheduling} />
                                                </Typography>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer justifyContent="center" spacing={6}>
                                            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Typography className='rescheduleOuter'>
                                                    <Button type="button" className='ReschedulBtn' onClick={() => { submitAlternateTimingsCandidate(`${modalEventDetail.eventId}`, 1) }}>Reschedule</Button>
                                                </Typography>
                                            </GridItem>
                                        </GridContainer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>

            {/* <a className='manageSetting' onClick={handleOpen222}>Interview reschedule popup</a> */}

            {/* Interview Reschedule Submitted */}
            <Modal
                open={rescheduleInterviewConfirmModal}
                onClose={handleRescheduleInterviewConfirmModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className='customBox submittedPopup' sx={style}>
                    <div className='calendarUpper'>
                        <i class="fa-regular fa-circle-check customCheckIcon"></i>
                        <Typography className='manageTitle'>Your interview reschedule request has been submitted!</Typography>
                        <a className='crossBtn' onClick={handleRescheduleInterviewConfirmModal}><i class="fas fa-xmark"></i></a>
                    </div>
                    <p className='submitp'><b>You will receive a notification once the Employer has confirmed the new interview timeslot.</b></p>
                </Box>
            </Modal>

            {/* Interview schedule for corporate side */}
            <Modal
                open={openModel}
                onClose={handleCloseModel}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='customModal calendarPopup'
            >
                <Box className='customBox calendarBox' sx={style}>
                    {(modalEventDetail?.applStatusId == 9 || modalEventDetail?.applStatusId == 12 || modalEventDetail?.applStatusId == 5) && <div className='CusBox'>
                        <div className='calendarUpper'>
                            <Typography className='manageTitle'>{modalEventDetail?.applStatusId == 9 ? 'Interview Invitation' : modalEventDetail?.applStatusId == 12 ? 'Interview Reschedule' : modalEventDetail?.applStatusId == 5 ? 'Interview Invitation Rejected' : ''} </Typography>

                            <a className='crossBtn' onClick={handleCloseModel}><i class="fas fa-xmark"></i></a>
                        </div>
                        <div className='emailNotifications'>
                            <div className='emailNotificationsinner'>
                                {
                                    modalEventDetail?.applStatusId == 9 ?
                                        <div>
                                            <div className='emailNotificationTitle'><b>Congratulations! You have shortlisted an interview for interview</b></div>
                                            <p className='p'>You have 48 hours to respond to the invitation before the proposed interview times expire. </p>
                                        </div>
                                        :
                                        modalEventDetail?.applStatusId == 12 ?
                                            <div>
                                                <div className='emailNotificationTitle'><b>The Employer has requested to reschedule your interview</b></div>
                                                <p className='p'>You have 48 hours to respond to the invitation before the proposed interview times expire. </p>
                                            </div>
                                            :
                                            <div></div>
                                }

                                <div className='fbOuter'>
                                    {
                                        (modalEventDetail?.companyProfilePic == '' || modalEventDetail?.companyProfilePic == null) ? <img src={Avtar} alt='Fb' style={{ height: '50px' }} /> :
                                            <img src={modalEventDetail?.companyProfilePic} alt='Fb' style={{ height: '50px' }} />
                                    }

                                    <div className='fbRight'>
                                        <div className='emailNotificationTitle'><Link to={`/view-profile/${encryptData(modalEventDetail?.acctId)}`} ><b>{modalEventDetail?.firstName + ' ' + modalEventDetail?.surname}</b></Link></div>

                                        <Link to="#" onClick={() => getJobPostModel(modalEventDetail?.jobPostId)} ><p className='purpleP'>{modalEventDetail?.jobTitle}</p></Link>
                                        <p className='purpleP'>{modalEventDetail?.jobPostType ? '(' + modalEventDetail?.jobPostType + ')' : ''}</p>
                                    </div>
                                </div>

                                {modalEventDetail && modalEventDetail?.eventSchedule && <div className='selectTimeSlot'>
                                    {modalEventDetail?.eventSchedule.eventScheduleList.length > 0 && <div className='emailNotificationTitle'><b>Please select your interview timeslot</b></div>}

                                    {(modalEventDetail?.eventSchedule.eventScheduleList.length > 0 && modalEventDetail?.applStatusId === 9 && modalEventDetail?.rescheduleAcctId !== userStatus.acctId) && modalEventDetail?.eventSchedule.eventScheduleList.map((event, index) => {
                                        return (<div className={event.class} onClick={() => { activateDateSelection(`${event.scheduleId}`) }}>
                                            <span>{moment(event.startDate).format('dddd, MMM D')}</span>
                                            <span>{moment(event.startDate).format('LT')} - {moment(event.endDate).format('LT')}</span>
                                        </div>)
                                    })}

                                    {(modalEventDetail?.eventSchedule.eventScheduleList.length > 0 && modalEventDetail?.applStatusId === 9 && modalEventDetail?.rescheduleAcctId == userStatus.acctId) && modalEventDetail?.eventSchedule.eventScheduleList.map((event, index) => {
                                        return (<div className={event.class}>
                                            <span>{moment(event.startDate).format('dddd, MMM D')}</span>
                                            <span>{moment(event.startDate).format('LT')} - {moment(event.endDate).format('LT')}</span>
                                        </div>)
                                    })}

                                    {modalEventDetail?.eventSchedule.eventScheduleList.length > 0 && (modalEventDetail?.applStatusId === 12 && modalEventDetail?.rescheduleAcctId === userStatus.acctId) && modalEventDetail?.eventSchedule.eventScheduleList.map((event, index) => {
                                        return (<div className={event.class} >
                                            <span>{moment(event.startDate).format('dddd, MMM D')}</span>
                                            <span>{moment(event.startDate).format('LT')} - {moment(event.endDate).format('LT')}</span>
                                        </div>)
                                    })}

                                    {modalEventDetail?.eventSchedule.eventScheduleList.length > 0 && (modalEventDetail?.applStatusId === 12 && modalEventDetail?.rescheduleAcctId !== userStatus.acctId) && modalEventDetail?.eventSchedule.eventScheduleList.map((event, index) => {
                                        return (<div className={event.class} onClick={() => { activateDateSelection(`${event.scheduleId}`) }}>
                                            <span>{moment(event.startDate).format('dddd, MMM D')}</span>
                                            <span>{moment(event.startDate).format('LT')} - {moment(event.endDate).format('LT')}</span>
                                        </div>)
                                    })}

                                    <p className='purpleP' onClick={handleProposedTimingsModal}>I am not available at any of the above timeslots.</p>
                                </div>}
                            </div>
                            <GridContainer justifyContent="center" spacing={6}>
                                <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Typography className='rescheduleOuter declineOuter'>
                                        {(modalEventDetail?.rescheduleAcctId !== userStatus.acctId) && <Button className='ReschedulBtn decline' onClick={() => { declineEventSchedule(`${modalEventDetail?.eventId}`, 3) }}>Decline Interview</Button>}

                                        {(modalEventDetail?.rescheduleAcctId !== userStatus.acctId) && <Button className='ReschedulBtn' onClick={() => { setEventScheduleStatus(`${modalEventDetail?.eventId}`, 1) }}>Confirm Interview</Button>}

                                        {((modalEventDetail?.applStatusId === 12 || modalEventDetail?.applStatusId === 9) && modalEventDetail?.rescheduleAcctId === userStatus.acctId) && <Button className='ReschedulBtn' onClick={() => { declineEventSchedule(`${modalEventDetail?.eventId}`, 3) }}>Cancel Interview</Button>}

                                    </Typography>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </div>}

                    {(modalEventDetail?.applStatusId == 11) && <div className='CusBox'>
                        <div className='calendarUpper'>
                            <Typography className='manageTitle'>Interview with {modalEventDetail?.companyName}</Typography>
                            {confirmTimeSlot && <p>{moment(confirmTimeSlot.datetime).format('dddd, MMM D')} • {moment(confirmTimeSlot.datetime).format('LT')} - {moment(confirmTimeSlot.endDate).format('LT')}</p>}

                            <a className='crossBtn' onClick={handleUpcomingInterviewAcceptedModal}><i class="fas fa-xmark"></i></a>
                        </div>
                        <div className='emailNotifications'>
                            <div className='emailNotificationsinner'>

                                <div className='fbOuter'>
                                    <img className='zoomImg' src={Zoom} alt='Zoom' />
                                    <div className='fbRight'>
                                        <p><b>{modalEventDetail?.typeName}</b></p>

                                        {(modalEventDetail?.typeName == "Zoom Meeting" && modalEventDetail?.location) && <a href={modalEventDetail?.location != "" ? modalEventDetail?.location : '#'} target="_blank">{modalEventDetail?.location != "" ? modalEventDetail?.location : 'No link yet'}</a>}

                                        {(modalEventDetail?.typeName == "Zoom Meeting" && !modalEventDetail?.location) && <p>No link yet</p>}

                                        {modalEventDetail?.typeName != "Zoom Meeting" && <p>{modalEventDetail?.location}</p>}

                                        {/* <Button type="button" className='ReschedulBtn decline joinwithzoom' onClick={zoomAPIIntegrationBy}>Join with Zoom</Button> */}
                                    </div>
                                </div>

                                <div className='fbOuter'>
                                    {
                                        (modalEventDetail?.companyProfilePic == '' || modalEventDetail?.companyProfilePic == null) ? <img src={Avtar} alt='Fb' style={{ height: '50px' }} /> :
                                            <img src={modalEventDetail?.companyProfilePic} alt='Fb' style={{ height: '50px' }} />
                                    }
                                    <div className='fbRight'>
                                        <div className='emailNotificationTitle'><Link to={`/view-profile/${encryptData(modalEventDetail?.acctId)}`} ><b>{modalEventDetail?.firstName + ' ' + modalEventDetail?.surname}</b></Link></div>
                                        <Link to="#" onClick={() => getJobPostModel(modalEventDetail?.jobPostId)} ><p className='purpleP'>{modalEventDetail?.jobTitle}</p></Link>
                                        <p className='purpleP'>{modalEventDetail?.jobPostType ? '(' + modalEventDetail.jobPostType + ')' : ''} </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <GridContainer justifyContent="center" spacing={6}>
                            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography className='rescheduleOuter declineOuter'>
                                    <Button className='ReschedulBtn decline' onClick={rescheduleEventSchedule}>Reschedule</Button>
                                    <Button className='ReschedulBtn' onClick={() => { setEventScheduleStatus(`${modalEventDetail.eventId}`, 2) }}>Cancel Interview</Button>
                                </Typography>
                            </GridItem>
                        </GridContainer>
                    </div>}
                </Box>
            </Modal>

            {/* Interview Invitation  for user side*/}
            <Modal
                open={open22}
                onClose={handleClose22}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='customModal calendarPopup'
            >
                <Box className='customBox calendarBox' sx={style}>
                    {(modalEventDetail?.applStatusId == 9 || modalEventDetail?.applStatusId == 12 || modalEventDetail?.applStatusId == 5) && <div className='CusBox'>
                        <div className='calendarUpper'>
                            <Typography className='manageTitle'>{modalEventDetail?.applStatusId == 9 ? 'Interview Invitation' : modalEventDetail?.applStatusId == 12 ? 'Interview Reschedule' : modalEventDetail?.applStatusId == 5 ? 'Interview Invitation Rejected' : ''} </Typography>
                            <a className='crossBtn' onClick={handleClose22}><i class="fas fa-xmark"></i></a>
                        </div>
                        <div className='emailNotifications'>
                            <div className='emailNotificationsinner'>
                                {
                                    modalEventDetail?.applStatusId == 9 ?
                                        <div>
                                            <div className='emailNotificationTitle'><b>Congratulations! You have been shortlisted for an interview</b></div>
                                            <p className='p'>You have 48 hours to respond to the invitation before the proposed interview times expire. </p>
                                        </div>
                                        :
                                        modalEventDetail?.applStatusId == 12 ?
                                            <div>
                                                <div className='emailNotificationTitle'><b>The Employer has requested to reschedule your interview</b></div>
                                                <p className='p'>You have 48 hours to respond to the invitation before the proposed interview times expire. </p>
                                            </div>
                                            :
                                            <div></div>
                                }

                                <div className='fbOuter'>
                                    {
                                        (modalEventDetail?.companyProfilePic == '' || modalEventDetail?.companyProfilePic == null) ? <img src={Avtar} alt='Fb' style={{ height: '50px' }} /> :
                                            <img src={modalEventDetail?.companyProfilePic} alt='Fb' style={{ height: '50px' }} />
                                    }
                                    <div className='fbRight'>
                                        <div className='emailNotificationTitle'><Link to={`/corporate-profile-page/${encryptData(modalEventDetail?.acctId)}/${encryptData(modalEventDetail?.bizId)}`} ><b>{modalEventDetail?.companyName}</b></Link></div>
                                        <Link to="#" onClick={() => getJobPostModel(modalEventDetail?.jobPostId)} ><p className='purpleP'>{modalEventDetail?.jobTitle}</p></Link>
                                        <p className='purpleP'>{modalEventDetail?.jobPostType ? '(' + modalEventDetail?.jobPostType + ')' : ''}</p>
                                    </div>
                                </div>

                                {modalEventDetail && modalEventDetail?.eventSchedule && <div className='selectTimeSlot'>
                                    <div className='emailNotificationTitle'><b>Please select your interview timeslot</b></div>

                                    {modalEventDetail?.eventSchedule.eventScheduleList.length > 0 && modalEventDetail?.applStatusId === 9 && modalEventDetail?.eventSchedule.eventScheduleList.map((event, index) => {
                                        return (<div className={event.class} onClick={() => { activateDateSelection(`${event.scheduleId}`) }}>
                                            <span>{moment(event.startDate).format('dddd, MMM D')}</span>
                                            <span>{moment(event.startDate).format('LT')} - {moment(event.endDate).format('LT')}</span>
                                        </div>)
                                    })}

                                    {modalEventDetail?.eventSchedule.eventScheduleList.length > 0 && (modalEventDetail?.applStatusId === 12 && modalEventDetail?.rescheduleAcctId === userStatus.acctId) && modalEventDetail?.eventSchedule.eventScheduleList.map((event, index) => {
                                        return (<div className={event.class} >
                                            <span>{moment(event.startDate).format('dddd, MMM D')}</span>
                                            <span>{moment(event.startDate).format('LT')} - {moment(event.endDate).format('LT')}</span>
                                        </div>)
                                    })}

                                    {modalEventDetail?.eventSchedule.eventScheduleList.length > 0 && (modalEventDetail?.applStatusId === 12 && modalEventDetail?.rescheduleAcctId !== userStatus.acctId) && modalEventDetail?.eventSchedule.eventScheduleList.map((event, index) => {
                                        return (<div className={event.class} onClick={() => { activateDateSelection(`${event.scheduleId}`) }}>
                                            <span>{moment(event.startDate).format('dddd, MMM D')}</span>
                                            <span>{moment(event.startDate).format('LT')} - {moment(event.endDate).format('LT')}</span>
                                        </div>)
                                    })}


                                    {modalEventDetail?.eventSchedule.eventScheduleList.length > 0 && (modalEventDetail?.applStatusId !== 12 && modalEventDetail?.applStatusId !== 9) && modalEventDetail?.eventSchedule.eventScheduleList.map((event, index) => {
                                        return (<div className={event.class} >
                                            <span>{moment(event.startDate).format('dddd, MMM D')}</span>
                                            <span>{moment(event.startDate).format('LT')} - {moment(event.endDate).format('LT')}</span>
                                        </div>)
                                    })}

                                    <p className='purpleP' onClick={handleProposedTimingsModal}>I am not available at any of the above timeslots.</p>
                                </div>}
                            </div>
                            <GridContainer justifyContent="center" spacing={6}>
                                <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                    {
                                        modalEventDetail?.applStatusId == 5 ?
                                            <Typography className='rescheduleOuter declineOuter'>
                                                <Button className='ReschedulBtn decline' disabled>Rejected</Button>
                                            </Typography>
                                            :
                                            <Typography className='rescheduleOuter declineOuter'>
                                                {modalEventDetail?.applStatusId !== 12 && <Button className='ReschedulBtn decline' onClick={() => { declineEventSchedule(`${modalEventDetail?.eventId}`, 3) }}>Decline Interview</Button>}
                                                {modalEventDetail?.applStatusId !== 12 && <Button className='ReschedulBtn' onClick={() => { setEventScheduleStatus(`${modalEventDetail?.eventId}`, 1) }}>Confirm Interview</Button>}

                                                {(modalEventDetail?.applStatusId === 12 && modalEventDetail?.rescheduleAcctId === userStatus.acctId) && <Button className='ReschedulBtn' onClick={() => { declineEventSchedule(`${modalEventDetail?.eventId}`, 3) }}>Cancel Interview</Button>}
                                            </Typography>
                                    }

                                </GridItem>
                            </GridContainer>
                        </div>
                    </div>}

                    {(modalEventDetail?.applStatusId == 11) && <div className='CusBox'>
                        <div className='calendarUpper'>
                            <a className='crossBtn' onClick={handleUpcomingInterviewAcceptedModal}><i class="fas fa-xmark"></i></a>
                        </div>
                        <div className='emailNotifications'>
                            <div className='emailNotificationsinner'>
                                <Typography className='manageTitle'>Interview with {modalEventDetail?.companyName}</Typography>
                                {confirmTimeSlot && <p>{moment(confirmTimeSlot.datetime).format('dddd, MMM D')} • {moment(confirmTimeSlot.datetime).format('LT')} - {moment(confirmTimeSlot.endDate).format('LT')}</p>}

                                <div className='fbOuter'>
                                    <img className='zoomImg' src={Zoom} alt='Zoom' />
                                    {/* <img src={Avtar} alt='Fb' style={{ height: '50px' }} /> */}
                                    <div className='fbRight'>
                                        <p><b>{modalEventDetail?.typeName}</b></p>

                                        {(modalEventDetail?.typeName == "Zoom Meeting" && modalEventDetail?.location) && <a href={modalEventDetail?.location != "" ? modalEventDetail?.location : '#'} target="_blank">{modalEventDetail?.location}</a>}

                                        {(modalEventDetail?.typeName == "Zoom Meeting" && !modalEventDetail?.location) && <p>No link yet</p>}

                                        {modalEventDetail?.typeName != "Zoom Meeting" && <p>{modalEventDetail?.location}</p>}

                                        {/* <Button type="button" className='ReschedulBtn decline joinwithzoom' onClick={zoomAPIIntegrationBy}>Join with Zoom</Button> */}
                                    </div>
                                </div>

                                <div className='fbOuter'>
                                    {
                                        (modalEventDetail?.companyProfilePic == '' || modalEventDetail?.companyProfilePic == null) ? <img src={Avtar} alt='Fb' style={{ height: '50px' }} /> :
                                            <img src={modalEventDetail?.companyProfilePic} alt='Fb' style={{ height: '50px' }} />
                                    }
                                    <div className='fbRight'>
                                        <div className='emailNotificationTitle'><Link to={`/corporate-profile-page/${encryptData(modalEventDetail?.acctId)}/${encryptData(modalEventDetail?.bizId)}`} ><b>{modalEventDetail?.companyName}</b></Link></div>
                                        <Link to="#" onClick={() => getJobPostModel(modalEventDetail?.jobPostId)} ><p className='purpleP'>{modalEventDetail?.jobTitle}</p></Link>
                                        <p className='purpleP'>{modalEventDetail?.jobPostType ? '(' + modalEventDetail.jobPostType + ')' : ''} </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <GridContainer justifyContent="center" spacing={6}>
                            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography className='rescheduleOuter declineOuter'>
                                    <Button className='ReschedulBtn decline' onClick={rescheduleEventSchedule}>Reschedule</Button>
                                    <Button className='ReschedulBtn' onClick={() => { setEventScheduleStatus(`${modalEventDetail.eventId}`, 2) }}>Cancel Interview</Button>
                                </Typography>
                            </GridItem>
                        </GridContainer>
                    </div>}
                </Box>
            </Modal>

            {/* Interview Confirmed Modal */}
            <Modal
                open={upcomingInterviewAccepted}
                onClose={handleUpcomingInterviewAcceptedModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='customModal calendarPopup'
            >
                <Box className='customBox calendarBox' sx={style}>
                    <div className='CusBox'>
                        <div className='calendarUpper'>
                            <Typography className='manageTitle'>Interview with {modalEventDetail?.companyName}</Typography>
                            {confirmTimeSlot && <p>{moment(confirmTimeSlot.datetime).format('dddd, MMM D')} • {moment(confirmTimeSlot.datetime).format('LT')} - {moment(confirmTimeSlot.endDate).format('LT')}</p>}

                            <a className='crossBtn' onClick={handleUpcomingInterviewAcceptedModal}><i class="fas fa-xmark"></i></a>
                        </div>
                        <div className='emailNotifications'>
                            <div className='emailNotificationsinner'>

                                <div className='fbOuter'>
                                    <img className='zoomImg' src={Zoom} alt='Zoom' />
                                    <div className='fbRight'>
                                        <p><b>{modalEventDetail?.typeName}</b></p>

                                        {modalEventDetail?.typeName == "Zoom Meeting" && <a href={modalEventDetail?.location} target="_blank">{modalEventDetail?.location}</a>}
                                        {modalEventDetail?.typeName != "Zoom Meeting" && <p>{modalEventDetail?.location}</p>}

                                        {/* <Button type="button" className='ReschedulBtn decline joinwithzoom' onClick={zoomAPIIntegrationBy}>Join with Zoom</Button> */}
                                    </div>
                                </div>

                                <div className='fbOuter'>
                                    {
                                        (modalEventDetail?.companyProfilePic == '' || modalEventDetail?.companyProfilePic == null) ? <img src={Avtar} alt='Fb' style={{ height: '50px' }} /> :
                                            <img src={modalEventDetail?.companyProfilePic} alt='Fb' style={{ height: '50px' }} />
                                    }
                                    <div className='fbRight'>
                                        <div className='emailNotificationTitle'><Link to={`/corporate-profile-page/${encryptData(modalEventDetail?.acctId)}/${encryptData(modalEventDetail?.bizId)}`} ><b>{modalEventDetail?.companyName}</b></Link></div>
                                        <Link to="#" onClick={() => getJobPostModel(modalEventDetail?.jobPostId)} ><p className='purpleP'>{modalEventDetail?.jobTitle}</p></Link>
                                        <p className='purpleP'>{modalEventDetail?.jobPostType ? '(' + modalEventDetail.jobPostType + ')' : ''} </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <GridContainer justifyContent="center" spacing={6}>
                            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography className='rescheduleOuter declineOuter'>
                                    <Button className='ReschedulBtn decline' onClick={rescheduleEventSchedule}>Reschedule</Button>
                                    <Button className='ReschedulBtn' onClick={() => { setEventScheduleStatus(`${modalEventDetail.eventId}`, 2) }}>Cancel Interview</Button>
                                </Typography>
                            </GridItem>
                        </GridContainer>
                    </div>
                </Box>
            </Modal>

            {/* Interview Booking Confirmed */}
            {modalEventDetail && <Modal
                open={confirmInterviewModal}
                onClose={handleCloseConfirmInterviewModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='customModal calendarPopup'
            >
                <Box className='customBox calendarBox' sx={style}>
                    <div className='CusBox'>
                        <div className='calendarUpper'>
                            <i class="fa-regular fa-circle-check customCheckIcon"></i>
                            <Typography className='manageTitle'>Your interview booking has <br />been confirmed!</Typography>
                            <a className='crossBtn' onClick={handleCloseConfirmInterviewModal}><i class="fas fa-xmark"></i></a>
                        </div>
                        <div className='fbCalendar'>
                            <div className='fbOuter'>
                                <img src={Avtar} alt='Fb' style={{ height: '50px' }} />
                                <div className='fbRight'>
                                    <div className='emailNotificationTitle'><Link to={`/corporate-profile-page/${encryptData(modalEventDetail?.acctId)}/${encryptData(modalEventDetail?.bizId)}`} ><b>{modalEventDetail?.companyName}</b></Link></div>
                                    <Link to="#" onClick={() => getJobPostModel(modalEventDetail?.jobPostId)} ><p className='purpleP'>{modalEventDetail?.jobTitle}</p></Link>
                                    <p className='purpleP'>({modalEventDetail?.jobPostType})</p>
                                </div>
                            </div>
                            <div className='dateTimeDiv'>
                                <label>Date and Time</label>
                                {(confirmTimeSlot && confirmTimeSlot.datetime) && <p>{moment(confirmTimeSlot.datetime).format('dddd, MMM D')} • {moment(confirmTimeSlot.datetime).format('LT')} - {moment(confirmTimeSlot.endDate).format('LT')}</p>}
                                <label>Interview Method</label>
                                <p>{modalEventDetail.typeName}</p>
                                {modalEventDetail?.typeName == "Zoom Meeting" && <a href={modalEventDetail?.location} target="_blank">{modalEventDetail?.location}</a>}
                                {modalEventDetail?.typeName != "Zoom Meeting" && <p>{modalEventDetail?.location}</p>}


                                {/* {modalEventDetail.typeId == 2 && <p>{modalEventDetail.typeName}</p>}
                                {modalEventDetail.typeId != 2 &&
                                    <div className='zoomDiv'>
                                        <img className='zoomImg' src={Zoom} alt='Zoom' />
                                        <div className='zoomright'>
                                            <Button className='takeTest FindMore joinzoom'>Join with Google Meet</Button>
                                            <a href="#">meet.google.com/ofw-hsw-dgd</a>
                                        </div>
                                    </div>
                                } */}
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>}

            {/* Propose Alternative Timings */}
            <Modal
                open={proposedModal}
                onClose={handleCloseProposedModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='customModal calendarPopup'
            >
                <Box className='customBox calendarBox' sx={style}>
                    <div className='CusBox'>
                        <div className='rescheduleInner proprosePopup'>
                            <div className='rescheduleInnerRight'>
                                <div className='calendarUpper'>
                                    <Typography className='manageTitle'>Propose Alternative Timings</Typography>
                                    <a className='crossBtn' onClick={handleCloseProposedModal}><i class="fas fa-xmark"></i></a>
                                </div>
                                <div className='emailNotifications'>
                                    <div className='emailNotificationsinner'>
                                        <p className='submitP proposeP'>Submit 3 alternative dates and/or timings of your availability</p>
                                        <GridContainer justifyContent="center" spacing={6}>
                                            <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Typography className='inputOuter1'>
                                                    <label className='labelCustom'>Timeslot 1 Date</label>
                                                    <input type='date' className='customInput custom1' value={dateSelection1} onChange={handleDateOneRange} min={minDate} />
                                                </Typography>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Typography className='inputOuter1'>
                                                    <label className='labelCustom'>Timeslot 1 Time</label>
                                                    <input type='time' className='customInput custom1' value={timeSelection1} onChange={handleTimeOneRange} />
                                                </Typography>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer justifyContent="center" spacing={6}>
                                            <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Typography className='inputOuter1'>
                                                    <label className='labelCustom'>Timeslot 2 Date</label>
                                                    <input className='customInput custom1' type='date' value={dateSelection2} onChange={handleDateTwoRange} min={minDate} />
                                                </Typography>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Typography className='inputOuter1'>
                                                    <label className='labelCustom'>Timeslot 2 Time</label>
                                                    <input className='customInput custom1' type='time' value={timeSelection2} onChange={handleTimeTwoRange} />
                                                </Typography>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer justifyContent="center" spacing={6}>
                                            <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Typography className='inputOuter1'>
                                                    <label className='labelCustom'>Timeslot 3 Date</label>
                                                    <input className='customInput custom1' type='date' value={dateSelection3} onChange={handleDateThreeRange} min={minDate} />
                                                </Typography>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Typography className='inputOuter1'>
                                                    <label className='labelCustom'>Timeslot 3 Time</label>
                                                    <input className='customInput custom1' type='time' value={timeSelection3} onChange={handleTimeThreeRange} />
                                                </Typography>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer justifyContent="center" spacing={6}>
                                            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Typography className='inputOuter1'>
                                                    <label className='labelCustom'>Reason for Rescheduling</label>
                                                    <input type="text" className='customInput custom1' value={reasonRescheduling} onChange={handleReasonRescheduling} />
                                                </Typography>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer justifyContent="center" spacing={6}>
                                            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Typography className='rescheduleOuter submitOut'>
                                                    <Button type="button" className='ReschedulBtn' onClick={() => { submitAlternateTimingsCandidate(`${modalEventDetail.eventId}`, 0) }}>Submit</Button>
                                                </Typography>
                                            </GridItem>
                                        </GridContainer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>

            {/* Alternate proposed timimg Submitted */}
            <Modal
                open={proposedSubmittedModal}
                onClose={handleProposedSubmittedModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className='customBox submittedPopup' sx={style}>
                    <div className='calendarUpper'>
                        <i class="fa-regular fa-circle-check customCheckIcon"></i>
                        <Typography className='manageTitle'>Your alternative proposed timings have been submitted!</Typography>
                        <a className='crossBtn' onClick={handleProposedSubmittedModal}><i class="fas fa-xmark"></i></a>
                    </div>
                    <p className='submitp'><b>You will receive a notification once the Employer has confirmed the new interview timeslot.</b></p>
                </Box>
            </Modal>

            {/* Decline Interview */}
            <Modal
                open={declineInterviewModal}
                onClose={handleCloseDeclineModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='customModal calendarPopup'
            >
                <Box className='customBox calendarBox' sx={style}>
                    <div className='CusBox'>
                        <div className='calendarUpper'>
                            <Typography className='manageTitle'>Decline Interview</Typography>
                            <a className='crossBtn' onClick={handleCloseDeclineModal}><i class="fas fa-xmark"></i></a>
                        </div>
                        <div className='emailNotifications'>
                            <div className='emailNotificationsinner'>
                                <div className='emailNotificationTitle centerText'><b>Please select the reasons why you would
                                    like to decline the interview.</b></div>
                                <p className='p'>The reasons you choose will not be shown on your profile or visible to anyone other than the Employer. </p>
                                <div className='selectTimeSlot'>
                                    {reasonList && reasonList.map((reason, index) => {
                                        return (<div className='timeDiv checkboxDiv'>
                                            <span>{reason.reasonDesc}</span>
                                            <span className='checkSpan'>
                                                <Checkbox {...label1} name="reasonCheckbox"
                                                    onChange={() => { setReasonSelection(`${reason.reasonId}`) }}
                                                    checked={
                                                        reason.reasonCheckbox === 1 ? true : false
                                                    } />
                                            </span>
                                        </div>)
                                    })}

                                </div>
                            </div>
                            <GridContainer justifyContent="center" spacing={6}>
                                <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Typography className='rescheduleOuter submitOut orangeSubmit'>
                                        <Button type="button" className='ReschedulBtn' onClick={createReasonReport}>Submit</Button>
                                    </Typography>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </div>
                </Box>
            </Modal>
            {jobViewModal ? <ResultJobModal open={jobViewModal} onClose={onJobModelClose} /> : null}
        </div>
    );
};

export default CalendarSection;
