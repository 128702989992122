import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Formik } from "formik";

import { corporateRegisterSchema } from "configurations/configurations.js";
import { corpRegisterAction } from "configurations/redux/authSlice.js";
import { useDispatch, useSelector } from "react-redux";

import People from "@material-ui/icons/People";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Email from "@material-ui/icons/Email";
import Icon from "@material-ui/core/Icon";
import CheckIcon from "@material-ui/icons/Check";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Danger from "components/Typography/Danger.js";
import Button from "components/CustomButtons/Button.js";

import registrationPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/RegistrationPage/registrationPageStyle.js";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { TextField } from "@material-ui/core";
const useStyles = makeStyles(registrationPageStyle);

const CorporateForm = () => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const [checked, setChecked] = React.useState(false);
	const [click, setClick] = React.useState(false);
	const message = useSelector((state) => state.auth.message);

	React.useEffect(() => {
		setTimeout(() => {
			setClick(false);
		}, 1000);
	}, [message]);
	const [showPassword, setShowPassword] = React.useState(false);
	const handleClickShowPassword = () => {
		if (showPassword) setShowPassword(false);
		else setShowPassword(true);
	};

	return (
		<div>
			<Formik
				initialValues={{
					companyName: "",
					email: "",
					password: "",
					checkPass: "",
					checkedTerms: false,
				}}
				validationSchema={corporateRegisterSchema}
				onSubmit={(data) => {
					console.log(data);

					const requestBody = {
						email: data.email.trim(),
						password: data.password,
						companyName: data.companyName.trim(),
						surname: "",
						firstName: "",
						middleName: "",
						acctStatusId: 0,
						acctTypeId: 2,
						socialAcctTypeId: 0,
						createdAcctId: 0,
						updateAcctId: 0,
					};
					setClick(true);
					dispatch(corpRegisterAction(requestBody));
				}}
			>
				{({
					handleSubmit,
					handleChange,
					values,
					errors,
					touched,
					handleBlur,
					setFieldValue,
				}) => (
					<form className={classes.form} onSubmit={handleSubmit}>
						<GridContainer>
							<GridItem
								className={classes.gridHeight + " " + classes.marginTop}
							>
								<h5 className={classes.textFieldLabel}>Corporate Name</h5>
								<TextField
									variant="outlined"
									label={
										touched.companyName && Boolean(errors.companyName)
											? errors.companyName
											: ""
									}
									id="companyName"
									name="companyName"
									// placeholder="Required"
									values={values.companyName}
									onChange={handleChange}
									onBlur={handleBlur}
									fullWidth
									// helperText={touched.companyName ? errors.companyName : ""}
									error={touched.companyName && Boolean(errors.companyName)}
									InputProps={{
										type: "text",
										// startAdornment: (
										// 	<InputAdornment position="start">
										// 		<People className={classes.inputIconsColor} />
										// 	</InputAdornment>
										// ),
										autoComplete: "off",
									}}
								/>
							</GridItem>
							<GridItem
								className={classes.gridHeight + " " + classes.marginTop}
							>
								<h5 className={classes.textFieldLabel}>Email</h5>
								<TextField
									variant="outlined"
									label={
										touched.email && Boolean(errors.email) ? errors.email : ""
									}
									id="email"
									name="email"
									// placeholder="Required"
									onChange={handleChange}
									onBlur={handleBlur}
									values={values.email}
									fullWidth
									// helperText={touched.email ? errors.email : ""}
									error={touched.email && Boolean(errors.email)}
									InputProps={{
										type: "text",
										// startAdornment: (
										// 	<InputAdornment position="start">
										// 		<Email className={classes.inputIconsColor} />
										// 	</InputAdornment>
										// ),
									}}
								/>
							</GridItem>
							<GridItem
								xs={12}
								sm={6}
								md={6}
								className={classes.gridHeight + " " + classes.marginTop}
							>
								<h5 className={classes.textFieldLabel}>Password</h5>
								<TextField
									variant="outlined"
									label={
										touched.password && Boolean(errors.password)
											? errors.password
											: ""
									}
									id="password"
									name="password"
									// placeholder="Required"
									onChange={handleChange}
									onBlur={handleBlur}
									values={values.password}
									fullWidth
									// helperText={touched.password ? errors.password : ""}
									error={touched.password && Boolean(errors.password)}
									InputProps={{
										type: showPassword ? "text" : "password",
										// startAdornment: (
										// 	<InputAdornment position="start">
										// 		<Icon className={classes.inputIconsColor}>
										// 			lock_outline
										// 		</Icon>
										// 	</InputAdornment>
										// ),
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleClickShowPassword}
													edge="end"
												>
													{showPassword ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										),
										autoComplete: "off",
									}}
								/>
							</GridItem>
							<GridItem
								xs={12}
								sm={6}
								md={6}
								className={classes.gridHeight + " " + classes.marginTop}
							>
								<h5 className={classes.textFieldLabel}>Confirm Password</h5>
								<TextField
									variant="outlined"
									label={
										touched.checkPass && Boolean(errors.checkPass)
											? errors.checkPass
											: ""
									}
									id="checkPass"
									name="checkPass"
									onChange={handleChange}
									onBlur={handleBlur}
									values={values.checkPass}
									fullWidth
									// helperText={touched.checkPass ? errors.checkPass : ""}
									error={touched.checkPass && Boolean(errors.checkPass)}
									InputProps={{
										type: showPassword ? "text" : "password",
										// startAdornment: (
										// 	<InputAdornment position="start">
										// 		<CheckIcon className={classes.inputIconsColor}>
										// 			lock_outline
										// 		</CheckIcon>
										// 	</InputAdornment>
										// ),
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleClickShowPassword}
													edge="end"
												>
													{showPassword ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										),
										autoComplete: "off",
									}}
								/>
							</GridItem>

							<GridItem>
								<div>
									<FormControlLabel
										classes={{
											label: classes.label,
										}}
										control={
											<Checkbox
												//tabIndex={-1}
												onChange={handleChange}
												name="checkedTerms"
												onClick={() => {
													if (values.checkedTerms) {
														setFieldValue("checkedTerms", false);
													} else {
														setFieldValue("checkedTerms", true);
													}
												}}
												checkedIcon={<Check className={classes.checkedIcon} />}
												icon={<Check className={classes.uncheckedIcon} />}
												classes={{
													checked: classes.checked,
													root: classes.checkRoot,
												}}
												checked={values.checkedTerms}
											/>
										}
										label={
											<span>
												I have read and agreed to the{" "}
												<a href="https://oppty.ai/terms-of-use" target="_blank">
													terms and conditions
												</a>
												.
											</span>
										}
									/>
								</div>
								{errors.checkedTerms && touched.checkedTerms && (
									<Danger>
										<h6>{errors.checkedTerms}</h6>
									</Danger>
								)}
							</GridItem>
							<GridItem xs={12} sm={12} md={12}>
								<Button
									fullWidth
									color="oppty"
									disabled={click}
									onClick={handleSubmit}
									className={classes.buttonBorder}
								>
									<b>Register Now</b>
								</Button>
							</GridItem>
						</GridContainer>
					</form>
				)}
			</Formik>
		</div>
	);
};

export default CorporateForm;
