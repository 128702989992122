const connectionCardStyle = (theme) => ({
  container: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    flexBasis: "calc(33.3333% - 10px)",
    backgroundColor: "white",
    borderRadius: "15px",
    padding: "20px 10px",
    height: "310px",

    "@media (max-width: 500px)": {
      flexBasis: "100%",
    },

  },
  cardContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  imgContainer: {
    borderRadius: "120px",
    height: "130px",
    width: "130px",
    color: "#000",
  },
  txt1: {
    margin: "0 0 10px",
    color: "#171725",
    fontSize: "14px",
    fontWeight: "600",
    marginTop: "20px",
  },
  txt2: {
    marginTop: "5px",
    color: "#4A4A4A",
    fontSize: "16px",
    fontWeight: "normal",
    textAlign: "center",
  },
  actionContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    flexDirection: "row",
    marginTop: "24px",
    alignItems: "center",
    width: "100%",

  },
  btnContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "36px",
    width: "90px",

  },
  btnTxt: {
    fontSize: "14px",
    fontWeight: "400",
  },
  dotContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "50px",
    width: "50px",
  },
});

export default connectionCardStyle;
