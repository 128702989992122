import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
	departmentViewRoadmap,
	viewRoadmapRoleByRoleId,
} from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Industry_Aerospace_Engineering from "assets/img/Career_Roadmap/Aerospace_Engineering/Industry_Aerospace_Engineering_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function IndustryAerospaceEngineering() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Industry_Aerospace_Engineering;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "am",
				shape: "poly",
				coords: [77,188,69,192,64,197,62,203,60,206,60,248,63,252,65,256,70,261,78,263,185,264,193,262,199,256,204,250,205,244,204,207,202,199,199,194,193,190,186,189,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "aecm",
				shape: "poly",
				coords: [77,312,70,316,66,320,64,324,62,330,62,401,64,408,68,413,74,418,80,420,187,420,194,418,200,414,202,409,206,403,206,329,203,323,199,318,193,314,185,312,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "m",
				shape: "poly",
				coords: [78,468,72,471,67,474,63,479,61,486,62,541,64,546,67,550,71,554,78,558,188,558,195,555,200,551,204,547,206,540,206,485,203,478,199,472,193,469,187,467,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "fm",
				shape: "poly",
				coords: [78,605,72,607,68,611,65,615,62,622,62,675,64,680,68,685,72,689,79,691,188,692,195,689,199,686,204,681,206,676,206,623,204,617,200,611,195,607,188,605,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "am") {
			dispatch(departmentViewRoadmap(28));
		} else if (area.name === "aecm") {
			dispatch(departmentViewRoadmap(29));
		} else if (area.name === "m") {
			dispatch(departmentViewRoadmap(30));
		} else if (area.name === "fm") {
			dispatch(departmentViewRoadmap(31));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo);
			dispatch(viewRoadmapRoleByRoleId(requestInfo));

			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
