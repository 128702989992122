import React from "react";
import { makeStyles, Typography, Button } from "@material-ui/core";
import profileViewStyles from "assets/jss/material-kit-pro-react/views/pageStyle/ProfileViewPage/profileViewStyles";
import AuthorTestimonialComponent from "views/Page/TestimonialsPage/AuthorTestimonialComponent";
import OwnTestimonialComponent from "views/Page/TestimonialsPage/OwnTestimonialComponent";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { decryptData } from "configurations/configurations";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import { useSelector, useDispatch } from "react-redux";
import {
	activateEditType,
	deactivateEditType,
} from "configurations/redux/editProfileModalSlice";
import Fade from "@material-ui/core/Fade";

const useStyles = makeStyles(profileViewStyles);

export default function Testimonial({ match }) {
	const styles = useStyles();
	const tabs = ["myTestimonials", "pendingRequests", "testimonialsGiven"];
	const [optionToShow, setOption] = React.useState("myTestimonials");
	const dispatch = useDispatch();

	const acctId = decryptData(match.params.acctId);

	const MenuItemList = () => {
		const [anchorEl, setAnchorEl] = React.useState(null);
		const open = Boolean(anchorEl);
		const handleClick = (event) => {
			setAnchorEl(event.currentTarget);
		};
		const handleClose = () => {
			setAnchorEl(null);
		};

		return (
			<React.Fragment>
				<IconButton
					aria-controls="simple-menu"
					aria-haspopup="true"
					aria-label="Open to show more"
					title="Open to show more"
					onClick={handleClick}
				>
					<MoreVertIcon />
				</IconButton>
				<Menu
					TransitionComponent={Fade}
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
				>
					<MenuItem
						className={styles.menuItem}
						onClick={() => {
							handleClose();

							const response = {
								type: "REQUEST_TESTIMONIAL",
							};
							dispatch(activateEditType(response));
						}}
					>
						Request Testimonial
					</MenuItem>
					<MenuItem
						className={styles.menuItem}
						onClick={() => {
							handleClose();
							const response = {
								type: "WRITE_TESTIMONIAL",
							};
							dispatch(activateEditType(response));
						}}
					>
						Submit Testimonial
					</MenuItem>
				</Menu>
			</React.Fragment>
		);
	};

	return (
		<Typography component="div" className={styles.interestedIndustriesBody}>
			<Typography component="div" className={styles.cardHeader}>
				<Typography component="h2" className={styles.cardTitle}>
					Testimonial
				</Typography>

				<MenuItemList />
			</Typography>

			<hr className={styles.divider} />
			<div className={styles.testimonialsContentTabsContainer}>
				<div
					className={clsx({
						[styles.tab]: true,
						[styles.tabActive]: optionToShow == "myTestimonials",
					})}
					onClick={() => {
						setOption("myTestimonials");
					}}
				>
					<p className={styles.tabTitle}>Received</p>
					<div
						className={clsx({
							[styles.tabActiveBorder]: optionToShow == "myTestimonials",
						})}
					></div>
				</div>
				<div
					className={clsx({
						[styles.tab]: true,
						[styles.tabActive]: optionToShow == "testimonialsGiven",
					})}
					onClick={() => {
						setOption("testimonialsGiven");
					}}
				>
					<p className={styles.tabTitle}>Given</p>
					<div
						className={clsx({
							[styles.tabActiveBorder]: optionToShow == "testimonialsGiven",
						})}
					></div>
				</div>
			</div>

			<div className={styles.testimonialsContent}>
				{optionToShow === tabs[0] && <OwnTestimonialComponent match={match} />}

				{optionToShow === tabs[2] && (
					<AuthorTestimonialComponent match={match} />
				)}

				<Link
					className={styles.textDeco}
					to={`/testimonials/${match.params.acctId}`}
					color="transparent"
					target="_blank"
					rel="noopener noreferrer"
				>
					<Button fullWidth>View More</Button>
				</Link>
			</div>
		</Typography>
	);
}
