import React from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { activateMasterCode } from "configurations/redux/masterFunctionSlice.js";

//Material Icons
// import AddIcon from "@material-ui/icons/Add";
// import IconButton from "@material-ui/core/IconButton";
// import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js";
import ExperienceCard from "./ExperienceCard.js";
// import ExperienceEditModal from "./ExperienceEditModal.js";
import IconLibrary from "views/Component/IconLibrary.js";

const useStyles = makeStyles(profilePageStyle);

export const ExperienceSection = ({ isViewed, experienceRecord }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const profileStrength = useSelector((state) => state.user.profileStrength);

	// const [editExperienceModal, setExperienceModal] = React.useState(false);
	// const [expRecordId, setExpRecordId] = React.useState();
	// const closeExperienceEditModal = () => {
	// 	setExperienceModal(false);
	// 	setExpRecordId();
	// };
	return (
		<React.Fragment>
			<Card className={classes.cardFrame}>
				<CardBody>
					<GridContainer
						justifyContent="space-between"
						alignContent="center"
						alignItems="center"
					>
						<GridItem xs={9} sm={9} md={9}>
							{isViewed ? (
								experienceRecord.length > 0 ? (
									<h4 className={classes.title}>Work Experience</h4>
								) : (
									<h4 className={classes.title}>No Work Experience</h4>
								)
							) : (
								<h4 className={classes.title}>Work Experience</h4>
							)}
						</GridItem>
						<GridItem xs={2} sm={2} md={2} className={classes.buttonColor}>
							{isViewed ? null : (
								<Tooltip
									title={
										<p className={classes.tooltipDescription}>Add Experience</p>
									}
									placement="top"
									classes={{ tooltip: classes.tooltip }}
								>
									{/* <IconButton
										onClick={() => {
											setExpRecordId(-1);
											setExperienceModal(true);
										}}
										color="inherit"
										edge="end"
									>
										<IconLibrary.CreateSquare width={32} height={32} />
									</IconButton> */}
									<Button
										justIcon
										style={{ boxShadow: "none" }}
										color="white"
										className={classes.buttonIconMargin}
										/* onClick={() => {
											setExpRecordId(-1);
											setExperienceModal(true);
										}} */
										onClick={() => {
											/* setEduRecordId(-1); */
											const extension =
												profileStrength[0].strengthList.filter((item) => {
													return (
														item.improvementTip.functionCode ===
														"MF_PM_MODAL_EXPERIENCE"
													);
												})[0].value === 0
													? profileStrength[0].strengthList.filter((item) => {
														return (
															item.improvementTip.functionCode ===
															"MF_PM_MODAL_EXPERIENCE"
														);
													})[0].improvementTip
													: null;
											console.log(extension);
											const response = {
												functionCode: "MF_PM_MODAL_EXPERIENCE",
												extension: extension,
											};
											dispatch(activateMasterCode(response));
										}}
									>
										<IconLibrary.Create />
									</Button>
								</Tooltip>
							)}
						</GridItem>

						<GridItem xs={12} sm={12} md={12}>
							<ExperienceCard />
						</GridItem>
					</GridContainer>
				</CardBody>
			</Card>
			{/* 
			{expRecordId ? (
				<ExperienceEditModal
					open={editExperienceModal}
					onClose={closeExperienceEditModal}
					expRecId={expRecordId}
					expRecord={null}
				/>
			) : null} */}
		</React.Fragment>
	);
};

const mapStateToProps = (state) => ({
	profileRecord: state.user.profileRecord,
	experienceRecord: state.user.experienceRecord,
	isViewed: state.user.isViewed,
});

export default connect(mapStateToProps, null)(ExperienceSection);
