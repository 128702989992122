import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
	departmentViewRoadmap,
	viewRoadmapRoleByRoleId,
} from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Department_Commercial_Banking from "assets/img/Career_Roadmap/Banking_SME_Corporate_Commercial/Department_Commercial_Banking_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentCommercialBanking() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Commercial_Banking;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "comb",
				shape: "poly",
				coords: [72,387,64,391,59,395,55,399,52,406,52,446,54,452,57,457,62,461,69,464,175,465,182,463,187,459,192,453,194,447,194,408,192,400,188,395,180,390,173,388,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "478",
				shape: "poly",
				coords: [291,391,283,392,278,396,275,401,274,406,274,452,276,459,279,464,283,467,479,468,487,467,491,464,494,460,495,452,495,407,493,398,489,393,482,390,476,389,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "416",
				shape: "poly",
				coords: [616,390,608,393,603,397,600,403,599,409,599,454,601,461,605,465,611,467,619,468,800,468,811,467,816,464,820,459,821,454,821,406,819,398,815,394,810,391,803,390,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "417",
				shape: "poly",
				coords: [941,390,931,393,928,397,925,402,925,408,924,452,925,458,929,463,935,467,942,468,1127,469,1137,467,1141,463,1145,460,1146,454,1146,407,1144,398,1141,394,1136,391,1129,390,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "418",
				shape: "poly",
				coords: [1267,391,1258,393,1254,396,1251,401,1250,409,1250,452,1252,461,1255,465,1261,468,1269,469,1453,468,1462,467,1467,464,1470,460,1471,453,1472,407,1470,399,1466,395,1461,392,1453,390,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "479",
				shape: "poly",
				coords: [1594,390,1583,393,1580,396,1576,401,1576,406,1576,454,1578,460,1581,465,1586,467,1594,469,1775,468,1787,468,1791,464,1795,460,1797,453,1798,406,1795,398,1790,394,1785,391,1779,391,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "comb") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo);
			dispatch(viewRoadmapRoleByRoleId(requestInfo));

			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
