import React from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import Button from "components/CustomButtons/Button.js";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Close from "@material-ui/icons/Close";

import postImageDialogStyles from "assets/jss/material-kit-pro-react/postImageDialogStyles.js";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/LandingPageFeed/CommentStyle.js";
import IconLibrary from "../IconLibrary";

const useStyles = makeStyles(postImageDialogStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

const PostImageDialog = ({ img, isOpen, closeDialog, saveCroppedPhoto }) => {
	const classes = useStyles();
	const classesDialog = makeStyles(profilePageStyle)();

	return (
		<Dialog
			classes={{ root: classes.modalRoot, paper: classes.modalPaper }}
			open={isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={(e, reason) => {
				if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
					closeDialog();
				}
			}}
			aria-labelledby="classic-modal-slide-title"
			aria-describedby="classic-modal-slide-description"
		>
			<DialogTitle
				id="classic-modal-slide-title"
				disableTypography
				className={classesDialog.modalTitleEdit + " " + classes.modalHeader}
			>
				<Button
					simple
					className={classesDialog.modalCloseButton}
					key="close"
					aria-label="Close"
					onClick={() => closeDialog()}
				>
					<IconLibrary.Close />
				</Button>
				<h6 className={classesDialog.title}>Preview Your Cropped Picture</h6>
				{/* <h6 className={classes.modalTitle}>Preview Your Cropped Picture</h6>
				<button
					className={classes.modalCloseButton}
					onClick={() => closeDialog()}
				>
					<IconLibrary.Close />
				</button> */}
			</DialogTitle>
			<DialogContent
				id="classic-modal-slide-description"
				className={classes.modalContentRoot}
			>
				<img src={img} alt="Cropped image" />
			</DialogContent>
			<div className={classes.saveButton}>
				<button
					onClick={() => {
						saveCroppedPhoto(img);
						closeDialog();
					}}
				>
					Save
				</button>
			</div>
		</Dialog>
	);
};

export default PostImageDialog;
