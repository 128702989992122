import React from 'react';
import { Button, InputLabel, makeStyles, TextField, Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import {
  instanceUrl,
  header,
  emptyCache,
  TimeFormatFirstDateOfMonth,
  getRelationshipByAcctId,
  getPositionByAcctId,
  requestRevisionTestimonialSchema,
} from 'configurations/configurations.js';
import {
  getConnectionList,
  createTestimonial,
  updateTestimonialContent,
} from 'configurations/redux/testimonialSlice.js';
import { Form, Formik } from 'formik';
import AsyncSelect from 'react-select/async';
import defaultAvatar from 'assets/img/candidate_avatar.jpeg';
import Select from 'react-select';
import requestTestimonialStyles from '../../../assets/jss/material-kit-pro-react/views/pageStyle/TestimonialsPage/requestTestimonialStyles';
import { activateEditType, deactivateEditType } from 'configurations/redux/editProfileModalSlice';
import ConfirmDialog from 'components/CustomDialog/ConfirmDialog.js';

export default function RequestRevisionComponent({ isChanged, onClose, saveSettingsModalProps, setIsChanged }) {
  const styles = makeStyles(requestTestimonialStyles)();
  const dispatch = useDispatch();
  const [relationshipNature, setRelationshipNature] = React.useState([]);
  const [relationChange, setRelationChange] = React.useState();
  const userStatus = useSelector(state => state.auth.user);
  const isCreated = useSelector(state => state.testimonial.isCreated);
  const connectionList = useSelector(state => state.testimonial.connectionList);
  const [relationship, setRelationShip] = React.useState(null);
  const [position, setPosition] = React.useState(null);
  const testimonialExtension = useSelector(state => state.editProfileModal.extension);
  const extension = testimonialExtension && testimonialExtension.tsBody;
  const cancelForm = {
    title: 'Discard Action',
    text: 'All the actions will be discarded',
    cancelText: 'No',
    confirmText: 'Yes',
  };
  return (
    <div className={styles.body}>
      <h3 className={styles.title}>Request Revision for a Testimonials</h3>

      <div className={styles.formContainer}>
        <Formik
          enableReinitialize
          validationSchema={requestRevisionTestimonialSchema}
          initialValues={{
            message: '',
            /* authorAcctId: "",
						relationship: "",
						position: "", */
          }}
          /* validationSchema={requestTestimonialSchema} */
          onSubmit={(values, actions) => {
            const request = {
              authorAcctId: extension.authorAcctId,
              tsPosition: extension.tsPosition,
              relationship: extension.relationship,
              message: values.message,
              ownerAcctId: extension.ownerAcctId,
              email: userStatus.email,
              acctId: userStatus.acctId,
              accessToken: localStorage.getItem('token'),
              testimonial: '',
              statusId: 3, //requesting
              tsStatus: testimonialExtension.tsStatus,
              testimonialId: extension.testimonialId,
            };
            console.log(request);
            dispatch(updateTestimonialContent(request));
            setTimeout(() => {
              dispatch(deactivateEditType());
            }, 1300);
          }}
        >
          {({ handleChange, values, errors, touched, handleBlur, handleSubmit, setFieldValue, resetForm }) => (
            <div style={{ height: '100%' }} onSubmit={handleSubmit}>
              <div className={styles.formArea}>
                <h4 className={styles.inputLabel}>Who do you want to ask?</h4>

                <div style={{ display: 'flex' }}>
                  <img
                    src={extension.authorProfilePic ? extension.authorProfilePic : defaultAvatar}
                    style={{
                      width: '48px',
                      height: '48px',
                      borderRadius: '50%',
                    }}
                  />
                  <h4
                    style={{
                      marginLeft: '10px',
                      fontWeight: 700,
                      fontSize: '16px',
                      color: 'rgba(0,0,0,0.6)',
                    }}
                  >
                    {extension.authorFirstName + ' ' + extension.authorLastName}
                  </h4>
                </div>
                {errors.authorAcctId && touched.authorAcctId && (
                  <h5 className={styles.errorMessage}>{errors.authorAcctId}</h5>
                )}

                <h4 className={styles.inputLabel}>What is the nature of your relationship?</h4>
                <h4
                  style={{
                    fontWeight: 700,
                    fontSize: '16px',
                    color: 'rgba(0,0,0,0.6)',
                  }}
                >
                  {extension.relationship}
                </h4>

                {errors.relationship && touched.relationship && (
                  <h5 className={styles.errorMessage}>{errors.relationship}</h5>
                )}
                <InputLabel className={styles.inputLabel}>
                  What was the organisation/project/company that you worked on or in together?{' '}
                </InputLabel>
                <h4
                  style={{
                    fontWeight: 700,
                    fontSize: '16px',
                    color: 'rgba(0,0,0,0.6)',
                  }}
                >
                  {extension.tsPosition}
                </h4>

                {errors.position && touched.position && <h5 className={styles.errorMessage}>{errors.position}</h5>}
                <InputLabel className={styles.inputLabel}>
                  Include a personalised message with your revision request
                </InputLabel>
                <TextField
                  className={styles.textField}
                  variant="outlined"
                  fullWidth
                  id="message"
                  name="message"
                  placeholder="Send a personalized message to the person you are asking for a recommendation, and include a brief note about what you expect."
                  value={values.message}
                  onChange={event => {
                    handleChange(event);
                    setIsChanged(true);
                  }}
                  onBlur={handleBlur}
                  InputProps={{
                    multiline: true,
                    rows: 10,
                    border: '4px solid hsl(0, 0%, 80%) ',
                  }}
                  inputProps={{ className: styles.inputField }}
                />
                <h5 style={{ fontSize: '0.75rem' }}>{values.message.length + '/2000'}</h5>
                {errors.message && touched.message && <h5 className={styles.errorMessage}>{errors.message}</h5>}
              </div>

              <div className={styles.submitButtonContainer}>
                <Button type="submit" onClick={handleSubmit} className={styles.submitButton}>
                  SUBMIT
                </Button>
              </div>
              <ConfirmDialog
                {...{ ...saveSettingsModalProps }}
                bodyContent={cancelForm}
                modalOpen={isChanged && saveSettingsModalProps.modalOpen}
                handleSubmit={() => {
                  setIsChanged(false);
                  onClose();
                }}
              />
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
}
