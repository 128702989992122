import React from 'react';
import { Button, InputLabel, makeStyles, TextField, Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import {
  instanceUrl,
  header,
  emptyCache,
  TimeFormatFirstDateOfMonth,
  getRelationshipByAcctId,
  getPositionByAcctId,
  requestTestimonialSchema,
} from 'configurations/configurations.js';
import { getConnectionList, createTestimonial } from 'configurations/redux/testimonialSlice.js';
import { Form, Formik } from 'formik';
import AsyncSelect from 'react-select/async';
import defaultAvatar from 'assets/img/candidate_avatar.jpeg';
import Select from 'react-select';
import requestTestimonialStyles from '../../../assets/jss/material-kit-pro-react/views/pageStyle/TestimonialsPage/requestTestimonialStyles';
import { activateEditType, deactivateEditType } from 'configurations/redux/editProfileModalSlice';
import ConfirmDialog from 'components/CustomDialog/ConfirmDialog.js';

export default function RequestTestimonialComponent({ isChanged, onClose, saveSettingsModalProps, setIsChanged }) {
  const styles = makeStyles(requestTestimonialStyles)();
  const dispatch = useDispatch();
  const [relationshipNature, setRelationshipNature] = React.useState([]);
  const [relationChange, setRelationChange] = React.useState();
  const userStatus = useSelector(state => state.auth.user);
  const isCreated = useSelector(state => state.testimonial.isCreated);
  const connectionList = useSelector(state => state.testimonial.connectionList);
  const [relationship, setRelationShip] = React.useState(null);
  const [position, setPosition] = React.useState(null);
  const cancelForm = {
    title: 'Discard Action',
    text: 'All the actions will be discarded',
    cancelText: 'No',
    confirmText: 'Yes',
  };
  const connectionSelectStyles = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: 500,
      fontSize: '14px',
      backgroundColor: state.isSelected ? '#687bfe' : 'white',
      color: state.isSelected ? 'white' : 'black',
      cursor: 'pointer',
    }),
    input: (provided, state) => ({
      ...provided,
      fontWeight: 700,
      fontSize: '14px',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontWeight: 500,
      fontSize: '14px',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontWeight: 500,
      fontSize: '14px',
    }),
    control: (provided, state) => ({
      ...provided,
      height: '40px',
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      fontWeight: 500,
      fontSize: '14px',
    }),
    loadingMessage: (provided, state) => (
      console.log(state),
      {
        ...provided,
        fontWeight: 500,
        fontSize: '14px',
      }
    ),
  };

  React.useEffect(() => {
    const request = {
      acctId: userStatus.acctId,
      accessToken: localStorage.getItem('token'),
      email: userStatus.email,
      lastRecordSize: 0,
      requestRecordSize: 5,
      statusId: 1,
      keyword: '',
      sortBy: 1,
    };
    dispatch(getConnectionList(request));
    const getUserPosition = async acctId => {
      const res = await getPositionByAcctId(acctId);
      var positionValues = [];
      for (var i = 0; i < res.data.payload.length; i++) {
        positionValues.push({
          value: i,
          label: res.data.payload[i].position,
        });
      }
      console.log(res.data.payload);
      setPosition(positionValues);
    };

    getUserPosition(userStatus.acctId);
  }, []);
  React.useEffect(() => {
    if (relationChange) {
      const body = {
        authorAcctId: relationChange,
        ownerAcctId: userStatus.acctId,
      };
      const getRelationship = async body => {
        const res = await getRelationshipByAcctId(body);
        console.log(res);
        var relationshipValues = [];
        for (var i = 0; i < res.data.payload.length; i++) {
          relationshipValues.push({
            value: i,
            label: res.data.payload[i].relationship,
          });
        }
        setRelationShip(relationshipValues);
      };
      getRelationship(body);
    }
  }, [relationChange]);

  return (
    <div className={styles.body}>
      <h3 className={styles.title}>Request for a Testimonials</h3>

      <div className={styles.formContainer}>
        <Formik
          enableReinitialize
          initialValues={{
            message: '',
            authorAcctId: '',
            relationship: '',
            position: '',
          }}
          validationSchema={requestTestimonialSchema}
          onSubmit={(values, actions) => {
            const request = {
              authorAcctId: values.authorAcctId,
              tsPosition: values.position,
              relationship: values.relationship,
              message: values.message,
              ownerAcctId: userStatus.acctId,
              email: userStatus.email,
              acctId: userStatus.acctId,
              accessToken: localStorage.getItem('token'),
              testimonial: '',
              statusId: 1, //requesting
            };

            dispatch(createTestimonial(request));
            setTimeout(() => {
              dispatch(deactivateEditType());
            }, 1300);
          }}
        >
          {({ handleChange, values, errors, touched, handleBlur, handleSubmit, setFieldValue, resetForm }) => (
            <div onSubmit={handleSubmit} style={{ height: '100%' }}>
              <div className={styles.formArea}>
                <h4 className={styles.inputLabel}>Who do you want to ask?</h4>

                <AsyncSelect
                  cacheOptions
                  defaultOptions={connectionList}
                  styles={connectionSelectStyles}
                  onChange={(event, target) => {
                    setFieldValue('authorAcctId', event.value);
                    setRelationChange(event.value);
                    setIsChanged(true);
                  }}
                  formatOptionLabel={connection => (
                    <div className="connection-option">
                      <img
                        style={{
                          borderRadius: '50%',
                          verticalAlign: 'middle',
                          marginRight: 10,
                          marginLeft: 2,
                          width: '35px',
                          height: '35px',
                        }}
                        src={connection.profilePic ? connection.profilePic : defaultAvatar}
                        alt="connection-image"
                      />
                      <span>{connection.name}</span>
                    </div>
                  )}
                  loadOptions={query => {
                    const body = {
                      acctId: userStatus.acctId,
                      accessToken: localStorage.getItem('token'),
                      email: userStatus.email,
                      lastRecordSize: 0,
                      requestRecordSize: 5,
                      statusId: 1,
                      keyword: query,
                      sortBy: 1,
                    };
                    return new Promise((resolve, reject) => {
                      axios({
                        method: 'GET',
                        params: body,
                        url: `${instanceUrl}/api/um/get-connection-profile-by-acct-id`,
                        headers: header,
                      })
                        .then(res => {
                          if (res.status === 201) {
                            var profileValues = [];
                            for (var i = 0; i < res.data.payload.connectionList.length; i++) {
                              profileValues.push({
                                value: res.data.payload.connectionList[i].receiverAcctId,
                                name:
                                  res.data.payload.connectionList[i].firstName +
                                  ' ' +
                                  res.data.payload.connectionList[i].lastName,
                                profilePic: res.data.payload.connectionList[i].profilePic,
                              });
                            }
                            resolve(profileValues);
                          }
                        })
                        .catch(reject);
                    });
                  }}
                  placeholder="Search Your Connections"
                />
                {errors.authorAcctId && touched.authorAcctId && (
                  <h5 className={styles.errorMessage}>{errors.authorAcctId}</h5>
                )}

                <h4 className={styles.inputLabel}>What is the nature of your relationship?</h4>
                <Select
                  options={relationship}
                  isDisabled={relationship ? false : true}
                  styles={connectionSelectStyles}
                  onChange={(event, target) => {
                    setFieldValue('relationship', event.label);
                  }}
                  placeholder="Relationship"
                />
                {errors.relationship && touched.relationship && (
                  <h5 className={styles.errorMessage}>{errors.relationship}</h5>
                )}
                <InputLabel className={styles.inputLabel}>
                  What was the organisation/project/company that you worked on or in together?{' '}
                </InputLabel>
                <Select
                  options={position}
                  styles={connectionSelectStyles}
                  onChange={(event, target) => {
                    setFieldValue('position', event.label);
                    setIsChanged(true);
                  }}
                  placeholder="Position"
                />
                {errors.position && touched.position && <h5 className={styles.errorMessage}>{errors.position}</h5>}
                <InputLabel className={styles.inputLabel}>Include a personalised message with your request</InputLabel>
                <TextField
                  className={styles.textField}
                  variant="outlined"
                  fullWidth
                  id="message"
                  name="message"
                  placeholder="Send a personalized message to the person you are asking for a recommendation, and include a brief note about what you expect."
                  /* value={values.message} */
                  /* helperText={touched.message ? errors.message : ""}
								error={touched.message && Boolean(errors.message)} */
                  onChange={event => {
                    handleChange(event);
                    setIsChanged(true);
                  }}
                  onBlur={handleBlur}
                  InputProps={{
                    multiline: true,
                    rows: 10,
                    border: '4px solid hsl(0, 0%, 80%) ',
                  }}
                  inputProps={{ className: styles.inputField }}
                />
                <h5 style={{ fontSize: '0.75rem' }}>{values.message.length + '/2000'}</h5>
                {errors.message && touched.message && <h5 className={styles.errorMessage}>{errors.message}</h5>}
              </div>

              <div className={styles.submitButtonContainer}>
                <Button type="submit" onClick={handleSubmit} className={styles.submitButton}>
                  SUBMIT
                </Button>
              </div>
              <ConfirmDialog
                {...{ ...saveSettingsModalProps }}
                bodyContent={cancelForm}
                modalOpen={isChanged && saveSettingsModalProps.modalOpen}
                handleSubmit={() => {
                  setIsChanged(false);
                  onClose();
                }}
              />
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
}
