import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { departmentViewRoadmap, viewRoadmapRoleByRoleId } from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";
import ImageMapper from "react-image-mapper";
import Department_Travel_Management_Operations from "assets/img/Career_Roadmap/Hospitality/Department_Travel_Management_Operations_1.jpg";
import RoleInfoModal from "../RoleInfoModal.js";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentTravelManagementOperations() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Travel_Management_Operations;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "tmo",
				shape: "poly",
				coords: [84,74,75,76,69,81,64,86,62,94,62,769,64,775,70,781,81,785,166,785,166,785,177,782,183,778,189,770,191,764,190,101,189,89,187,84,182,78,177,74,170,74,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "101",
				shape: "poly",
				coords: [228,71,221,71,216,74,212,78,209,84,208,156,210,161,213,165,216,169,222,171,386,171,393,168,396,164,400,159,399,83,396,78,394,75,389,72,385,71,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "100",
				shape: "poly",
				coords: [227,459,217,460,212,462,208,464,205,469,203,474,203,520,205,526,207,530,212,533,220,535,379,535,386,533,390,530,393,526,395,523,394,472,392,466,389,462,385,461,380,459,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "99",
				shape: "poly",
				coords: [230,572,218,572,211,574,209,576,207,580,205,583,204,588,204,649,205,655,206,659,209,662,214,665,218,666,378,666,386,665,390,662,392,659,394,653,394,585,392,579,390,577,385,573,378,572,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "102",
				shape: "poly",
				coords: [565,72,556,73,552,74,549,77,545,82,542,87,543,156,544,162,546,166,550,169,553,170,559,172,714,171,721,171,728,168,730,164,733,160,734,155,734,88,732,81,729,78,726,76,721,74,716,73,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "103",
				shape: "poly",
				coords: [563,214,553,215,550,217,545,220,543,223,541,228,542,281,544,283,547,286,550,290,555,291,716,291,723,289,727,286,730,283,732,278,732,227,730,221,727,219,723,216,720,214,714,214,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "104",
				shape: "poly",
				coords: [564,333,555,334,549,336,546,338,543,344,540,349,540,396,542,402,544,405,547,408,551,410,558,412,714,411,721,410,725,408,728,405,731,400,733,396,731,348,730,341,726,337,722,335,716,333,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "105",
				shape: "poly",
				coords: [559,453,551,453,548,456,545,459,542,463,541,468,541,514,542,521,545,524,548,527,556,530,711,529,721,528,724,526,728,523,730,520,731,514,731,471,732,466,730,461,726,458,716,453,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "106",
				shape: "poly",
				coords: [562,571,554,573,549,575,545,578,543,582,541,588,541,651,542,657,544,660,548,663,552,666,558,667,713,666,720,665,724,663,726,660,729,657,732,650,731,590,731,584,729,578,726,575,721,573,718,572,714,572,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "107",
				shape: "poly",
				coords: [555,710,549,711,546,713,543,718,541,723,541,773,543,777,546,781,549,783,554,786,716,785,723,784,725,781,729,778,731,774,733,726,732,720,731,717,728,713,723,711,719,710,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "110",
				shape: "poly",
				coords: [925,73,917,75,913,77,911,81,909,88,909,158,911,163,913,167,917,170,923,171,1085,171,1091,169,1094,166,1097,162,1099,157,1100,89,1099,82,1096,78,1092,75,1086,73,1082,72,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "109",
				shape: "poly",
				coords: [923,452,915,454,913,456,910,458,907,463,905,468,905,513,907,519,909,522,911,526,915,529,922,530,1076,531,1085,529,1089,527,1092,524,1094,521,1096,515,1096,468,1095,462,1093,459,1089,457,1085,454,1080,453,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "108",
				shape: "poly",
				coords: [926,709,918,709,912,712,909,716,906,720,905,725,905,768,906,775,908,778,911,781,915,784,922,786,1078,786,1086,784,1089,782,1092,781,1094,776,1096,772,1096,725,1095,720,1094,716,1091,712,1086,710,1081,710,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "111",
				shape: "poly",
				coords: [1288,72,1280,73,1277,74,1274,77,1271,81,1269,86,1270,157,1271,162,1274,165,1278,168,1284,171,1442,171,1451,170,1453,167,1457,163,1460,160,1461,153,1460,88,1460,81,1458,77,1454,75,1449,73,1443,72,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "112",
				shape: "poly",
				coords: [1287,452,1279,454,1276,456,1273,460,1271,464,1269,470,1270,517,1271,522,1274,525,1278,528,1286,530,1445,529,1450,528,1454,525,1457,521,1460,517,1459,463,1457,459,1455,456,1451,455,1448,454,1445,453,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "113",
				shape: "poly",
				coords: [1290,710,1280,710,1276,713,1273,716,1270,721,1270,726,1270,774,1272,779,1275,782,1279,784,1284,786,1447,785,1451,784,1456,781,1459,778,1460,773,1460,725,1460,719,1458,716,1454,713,1449,710,1445,710,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "114",
				shape: "poly",
				coords: [1656,439,1647,440,1644,442,1641,445,1639,447,1637,453,1638,528,1638,533,1639,536,1642,541,1648,543,1654,546,1834,544,1840,542,1845,539,1848,534,1850,529,1849,453,1848,448,1846,444,1843,441,1837,440,1833,439,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "tmo") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo)
			dispatch(viewRoadmapRoleByRoleId(requestInfo));
			
			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// console.log(coords.x + "," + coords.y + ",");
		// navigator.clipboard.writeText(coords.x + "," + coords.y + ",");
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}

	function enterArea(area) {
		console.log(area);
	}

	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
				onMouseEnter={(area) => enterArea(area)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
