import * as Yup from 'yup';
import axios from 'axios';
import moment from 'moment';

import dateFormat from 'dateformat';
import TimeAgo from 'javascript-time-ago';
// English.
import en from 'javascript-time-ago/locale/en';
import ReactGA from 'react-ga';

const CryptoJS = require('crypto-js');
TimeAgo.addDefaultLocale(en);
const ta = new TimeAgo('en-US');
//url for webserver
require('dotenv').config();
const nocache = require('nocache');

export const instanceUrl = process.env.REACT_APP_APPSERVER_URL; //change to REACT_APP_APPSERVER_URL when build
export const communityArticlePath = process.env.REACT_APP_COMMUNITY_ARTICLE_PATH;
export const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;
export const googleCliendId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const googleCliendSecret = process.env.REACT_APP_GOOGLE_CLIENT_SECRET;
export const appVersion = process.env.REACT_APP_VERSION;
export const publicEmail = process.env.REACT_APP_PUBLIC_EMAIL;

ReactGA.initialize(process.env.REACT_APP_GA_CODE);

if (process.env.REACT_APP_MODE === 'production') {
  console.log = function () {};
}

const today = new Date();
const currentYear = today.getFullYear();
const currentMonth = today.getMonth() + 1;

//header configuration
export const header = { headers: { 'Content-type': 'application/json' } };
export const headerFormData = {
  headers: { 'Content-Type': 'multipart/form-data' },
};
export const userGroupRoles = {
  OWNER: { id: 1, name: 'Owner' },
  MANAGER: { id: 2, name: 'Manager' },
  MEMBER: { id: 3, name: 'Member' },
};
export const userGroupStatus = {
  ACTIVE: { id: 1, name: 'Active' },
  INVITED: { id: 2, name: 'Invited' },
  REQUESTED: { id: 3, name: 'Requested' },
  INACTIVE: { id: 4, name: 'Inactive' },
};
export const groupPermissions = {
  ALLOW_ANYONE_TO_JOIN: { id: 1, name: 'Allow anyone to join' },
  AUTO_APPROVE_POSTS: { id: 2, name: 'Auto-approve posts' },
};

//Register Schema for Regular Account
export const regularRegisterSchema = Yup.object({
  firstName: Yup.string()
    .trim()
    .required('* Please enter your first name')
    .max(50, 'First Name cannot exceed 50 characters'),
  middleName: Yup.string().trim().notRequired().max(50, 'Middle Name cannot exceed 50 characters'),
  surname: Yup.string()
    .trim()
    .required('* Please enter your last name')
    .max(50, 'Family Name cannot exceed 50 characters'),
  companyName: Yup.string().trim().notRequired().max(100, 'Company Name cannot exceed 100 characters'),
  email: Yup.string()
    .trim()
    .required('* Please enter your email address')
    .email('* Please enter a valid email address')
    .max(200, 'Email cannot exceed 200 characters'),
  password: Yup.string()
    .required('* Password is required')
    .matches(/[A-Z]/, '* Uppercase required')
    .min(8, '* Your password must have at least 8 characters')
    .max(128, 'Password cannot exceed 128 characters'),
  checkPassword: Yup.string()
    .required('* Please confirm your password')
    .oneOf([Yup.ref('password'), null], '* Passwords must match'),
  checkedTerms: Yup.boolean()
    .required('* Terms and Conditions is required')
    .oneOf([true], '* Please agree to the Terms and Conditions'),
});

//Reset Password Schema
export const resetPasswordSchema = Yup.object({
  currentPassword: Yup.string()
    .required('* Password is required')
    .matches(/[A-Z]/, '* Uppercase required')
    .min(8, '* Your password must have at least 8 characters')
    .max(128, 'Password cannot exceed 128 characters'),
  password: Yup.string()
    .required('* Password is required')
    .matches(/[A-Z]/, '* Uppercase required')
    .min(8, '* Your password must have at least 8 characters')
    .max(128, 'Password cannot exceed 128 characters'),
  checkPassword: Yup.string()
    .required('* Please confirm your password')
    .oneOf([Yup.ref('password'), null], '* Passwords must match'),
});

//Register Schema for Corporate Account
export const corporateRegisterSchema = Yup.object({
  companyName: Yup.string()
    .trim()
    .required('* Corporate Name is required')
    .max(100, 'Company Name cannot exceed 100 characters'),
  email: Yup.string()
    .trim()
    .required('* Please enter your email address')
    .email('* Please enter a valid email address')
    .max(200, 'Email cannot exceed 200 characters'),
  password: Yup.string()
    .required('* Password is required')
    //.matches("^[a-zA-Z0-9]+$","* ")
    .matches(/[A-Z]/, '* Uppercase required')
    .min(8, '* Your password must have at least 8 characters')
    .max(128, 'Password can not exceed 128 characters'),
  checkPass: Yup.string()
    .required('* Please confirm your password')
    .oneOf([Yup.ref('password'), null], '* Passwords must match'),
  checkedTerms: Yup.boolean()
    .required('* Terms and Conditions is required')
    .oneOf([true], '* Please agree to the Terms and Conditions'),
});

//Login Schema for Corporate & Candidate Account
export const loginSchema = Yup.object({
  email: Yup.string().required('* Please enter your email address'),
  password: Yup.string().required('* Password is required'),
});
export const requestTestimonialSchema = Yup.object({
  message: Yup.string().trim().required('* Message is required').max(2000, '* Message Cannot Exceed 2000 Characters'),
  authorAcctId: Yup.number().required('* Request Connection is required'),
  relationship: Yup.string().trim().required('* Relationship is required'),
  position: Yup.string().trim().required('* Position is required'),
});
export const requestRevisionTestimonialSchema = Yup.object({
  message: Yup.string().trim().required('* Message is required').max(2000, '* Message Cannot Exceed 2000 Characters'),
});
export const writeTestimonialWithoutRequestSchema = Yup.object({
  ownerAcctId: Yup.number().required('* Request Connection is required'),
  relationship: Yup.string().trim().required('* Relationship is required'),
  position: Yup.string().trim().required('* Position is required'),
  testimonial: Yup.string()
    .required('* Testimonial is required')
    .max(3000, '* Testimonial Cannot Exceed 3000 Characters'),
});
export const replyTestimonialSchema = Yup.object({
  testimonial: Yup.string()
    .required('* Testimonial is required')
    .max(3000, '* Testimonial Cannot Exceed 3000 Characters'),
});
//Profile Update Schema for Croprate
export const profileUpdateSchema = Yup.object({
  surname: Yup.string()
    .trim()
    .required('* Surname/Last Name is required')
    .max(50, '* Surname/Last Name cannot exceed 50 Characters'),
  firstName: Yup.string()
    .trim()
    .required('* Please enter your First Name')
    .max(50, '* First Name cannot exceed 50 Characters'),
  middleName: Yup.string().trim().notRequired().max(50, 'Middle Name cannot exceed 50 characters'),
  countryId: Yup.mixed().required('* Please enter the country'),
  // locationId: Yup.mixed().required("* Location is Required"),
  areaCodeId: Yup.mixed().required('* Please enter the Area Code'),
  contactNo: Yup.string()
    .trim()
    .matches(/^\d+$/, '* This entry can only contain numbers')
    .nullable(true)
    .required('* Phone number is Required')
    .max(50, '* Phone number cannot exceed 50 numbers'),
  headline: Yup.string().trim().notRequired().nullable(true).max(100, '* Headline cannot exceed 100 Characters'),
  aboutMe: Yup.string().trim().notRequired().nullable(true).max(1200, '* About Me cannot exceed 1200 Characters'),
});

export const educationUpdateSchema = Yup.object().shape({
  school: Yup.string()
    .trim()
    .required('* Please enter your school name')
    .max(100, '*School Name cannot exceed 100 Characters'),
  schoolId: Yup.number().required('* Please enter your school name'),
  countryId: Yup.number().required('* Please enter the country'),
  // locationId: Yup.number().required("* Location is Required"),
  studyFieldId: Yup.number().required('* Please enter your field of study'),
  eduLvlId: Yup.number().required('* Please enter your highest level of education'),
  courseName: Yup.string()
    .trim()
    .required('* Please enter your course name')
    .max(200, '*Course Name cannot exceed 200 Characters'),
  currentInProgressFlag: Yup.number().notRequired(),
  startYear: Yup.number().when('currentInProgressFlag', {
    is: currentInProgressFlag => currentInProgressFlag === 0,
    then: Yup.number().required('* Please enter the start year'),
    then: Yup.number().min(1, '* Please enter the start year'),
    otherwise: Yup.number().max(currentYear, 'Start year cannot be later than current year'),
  }),
  startMonth: Yup.number().when('currentInProgressFlag', {
    is: currentInProgressFlag => currentInProgressFlag === 0,
    then: Yup.number().required('* Please enter the start month'),
    then: Yup.number().min(1, '* Please enter the start month'),
    otherwise: Yup.number().when({
      is: startMonth => startMonth > currentMonth,
      then: Yup.number().when('startYear', {
        is: startYear => startYear > currentYear,
        then: Yup.number().max(currentYear, 'Start year cannot be later than current year'),
        otherwise: Yup.number().when('startYear', {
          is: startYear => startYear == currentYear,
          then: Yup.number().max(currentMonth, 'Start month cannot be later than current month'),
          otherwise: Yup.number().min(1, '* Please enter the end of the month'),
        }),
      }),
      otherwise: Yup.number().min(1, '* Please enter the end of the month'),
    }),
  }),
  endYear: Yup.number().when('currentInProgressFlag', {
    is: currentInProgressFlag => currentInProgressFlag === 0,
    then: Yup.number().required('* Please enter the end year'),
    then: Yup.number().when({
      is: endYear => endYear > 0,
      then: Yup.number().min(Yup.ref('startYear'), 'End year must be later than the start year'),
      otherwise: Yup.number().min(1, '* Please enter the end year'),
    }),
    otherwise: Yup.number(),
  }),
  endMonth: Yup.number().when('currentInProgressFlag', {
    is: currentInProgressFlag => currentInProgressFlag === 0,
    then: Yup.number().required('* Please enter the end of the month'),
    then: Yup.number().min(1, '* Please enter the end of the month'),
    then: Yup.number().when({
      is: endMonth => endMonth > 0,
      then: Yup.number().when(['endYear', 'startYear'], {
        is: (endYear, startYear) => endYear > startYear,
        otherwise: Yup.number().min(Yup.ref('startMonth'), 'End Month cannot be earlier than Start Month'),
      }),
      otherwise: Yup.number().min(1, '* Please enter the end of the month'),
    }),
    otherwise: Yup.number(),
  }),

  achievement: Yup.string().trim().notRequired().nullable(true).max(500, '* Achievement cannot exceed 500 Characters'),
  extraCurricular: Yup.string()
    .trim()
    .notRequired()
    .nullable(true)
    .max(500, '* Extra-Curricular cannot exceed 500 Characters'),
  description: Yup.string()
    .trim()
    .notRequired()
    .nullable(true)
    .max(1000, '* Description cannot exceed 1000 Characters'),
});

export const experienceUpdateSchema = Yup.object().shape({
  title: Yup.string().trim().required('* Please enter the title').max(100, '* Title cannot exceed 100 Characters'),
  organization: Yup.string()
    .trim()
    .required('* Please enter the organization name ')
    .max(100, '* Organization cannot exceed 100 Characters'),
  countryId: Yup.number().required('* Please enter the country'),
  // locationId: Yup.number().required("* Location is Required"),
  currentInProgressFlag: Yup.number().notRequired(),
  startYear: Yup.number()
    .required('* Please enter the start year')
    .max(currentYear, 'Start year can’t be past today’s year'),
  startMonth: Yup.number()
    .required('* Please enter the start month')
    .when(['startYear'], {
      is: startYear => startYear == currentYear,
      then: Yup.number().max(currentMonth, 'Start month can’t be past today’s month'),
      otherwise: Yup.number().min(1, '* Please enter the start month'),
    }),
  endYear: Yup.number().when('currentInProgressFlag', {
    is: currentInProgressFlag => currentInProgressFlag === 0,
    then: Yup.number().min(1, '* Please enter the end year'),
    then: Yup.number().when({
      is: endYear => endYear > 0,
      then: Yup.number()
        .min(Yup.ref('startYear'), 'End year must be later than the start year')
        .max(currentYear, 'End year can’t be past today’s year'),
      otherwise: Yup.number().min(1, '* Please enter the end year'),
    }),
    otherwise: Yup.number(),
  }),
  endMonth: Yup.number().when('currentInProgressFlag', {
    is: currentInProgressFlag => currentInProgressFlag === 0,
    then: Yup.number().min(1, '* Please enter the end of the month'),
    then: Yup.number().when({
      is: endMonth => endMonth > 0,
      then: Yup.number()
        .when(['endYear', 'startYear'], {
          is: (endYear, startYear) => endYear == startYear,
          then: Yup.number().min(Yup.ref('startMonth'), 'End Month cannot be earlier than Start Month'),
        })
        .when(['endYear'], {
          is: endYear => endYear == currentYear,
          then: Yup.number().max(currentMonth, 'End month can’t be past today’s month'),
        }),
      otherwise: Yup.number().min(1, '* Please enter the end of the month'),
    }),
    otherwise: Yup.number(),
  }),
  achievement: Yup.string().trim().notRequired().nullable(true).max(500, '* Achievement cannot exceed 500 Characters'),
  description: Yup.string()
    .trim()
    .notRequired()
    .nullable(true)
    .max(2000, '* Description cannot exceed 2000 Characters'),
});

export const corporateProfileSchema = Yup.object().shape({
  // surname: Yup.string()
  // 	.trim()
  // 	.required("* Please enter your last name")
  // 	.max(50, "Last Name cannot exceed 50 Characters"),
  // firstName: Yup.string()
  // 	.trim()
  // 	.required("* Please enter your first name")
  // 	.max(50, "First Name cannot exceed 50 Characters"),
  // middleName: Yup.string().trim().notRequired(),
  pageName: Yup.string().trim().notRequired().max(200, 'Page Name cannot exceed 50 Characters'),
  countryId: Yup.mixed().required('* Please enter the country'),
  // locationId: Yup.mixed().required("* Location is Required"),
  email: Yup.string()
    .trim()
    .required('* Please enter your email address')
    .email('* Please enter a valid email address')
    .max(200, 'Email can not exceed 200 characters'),
  areaCodeId: Yup.mixed().notRequired(),
  contactNo: Yup.string()
    .trim()
    .notRequired()
    .nullable(true)
    .matches(/^\d+$/, '* This entry can only contain numbers')
    .max(50, '* Phone number cannot exceed 50 numbers'),
  headline: Yup.string().trim().notRequired(),
  aboutMe: Yup.string().trim().notRequired(),
  companyName: Yup.string()
    .trim()
    .required('* Please enter your company name')
    .max(100, '* Company Name cannot exceed 100 Characters'),
  url: Yup.string().trim().notRequired().nullable(true).max(100, '* Company URL cannot exceed 100 Characters'),
  industryId: Yup.number().notRequired(),
  companyProfile: Yup.string()
    .trim()
    .notRequired()
    .nullable(true)
    .max(1200, '* Company Profile cannot exceed 1200 Characters'),
  companySize: Yup.number().notRequired(),
  companyTypeId: Yup.number().notRequired(),
});

export const corporateCreateJobSchema = Yup.object().shape({
  draft: Yup.number().notRequired(),
  title: Yup.string().trim().required('* Please enter the title').max(100, '* Title cannot exceed 100 Characters'),
  industryId: Yup.number().when('draft', {
    is: draft => draft === 1,
    then: Yup.number().notRequired(),
    otherwise: Yup.number().required('* Please enter your industry'),
  }),
  countryId: Yup.number().when('draft', {
    is: draft => draft === 1,
    then: Yup.number().notRequired(),
    otherwise: Yup.number().required('* Please enter the country'),
  }),
  // locationId: Yup.number().required("* Location is required"),
  // location: Yup.string().required("* Location is required"),
  eduLvlId: Yup.number().when('draft', {
    is: draft => draft === 1,
    then: Yup.number().notRequired(),
    otherwise: Yup.number().required('* Please enter your highest level of education'),
  }),
  jobTypeId: Yup.number().when('draft', {
    is: draft => draft === 1,
    then: Yup.number().notRequired(),
    otherwise: Yup.number().required('* Please select a job post type'),
  }),
  jobPostTypeId: Yup.number().when('draft', {
    is: draft => draft === 1,
    then: Yup.number().notRequired(),
    otherwise: Yup.number().required('* Please enter the job type'),
  }),
  role: Yup.string()
    .trim()
    .when('draft', {
      is: draft => draft === 1,
      then: Yup.string().notRequired(),
      otherwise: Yup.string().required('* Please enter a role'),
    })
    .max(100, '* Role cannot exceed 100 Characters'),
  servicePeriod: Yup.number()
    .when('draft', {
      is: draft => draft === 1,
      then: Yup.number().notRequired(),
      otherwise: Yup.number().required('* Please enter your service period '),
    })
    .max(60, '* Service Period cannot exceed 5 years'),
  currencyId: Yup.number().when('draft', {
    is: draft => draft === 1,
    then: Yup.number().notRequired(),
    otherwise: Yup.number().required('* Please enter the currency'),
  }),
  // responsibility: Yup.string()
  // 	.trim()
  // 	.when("draft", {
  // 		is: (draft) => draft === 1,
  // 		then: Yup.string().notRequired(),
  // 		otherwise: Yup.string().required("* Please enter the responsibility"),
  // 	})
  // 	.max(3000, "* Responsibility cannot exceed 3000 Characters"),
  // requirement: Yup.string()
  // 	.trim()
  // 	.when("draft", {
  // 		is: (draft) => draft === 1,
  // 		then: Yup.string().notRequired(),
  // 		otherwise: Yup.string().required("* Please enter a requirement"),
  // 	})
  // 	.max(3000, "* Requirement cannot exceed 3000 Characters"),
  salaryMin: Yup.number()
    .when('draft', {
      is: draft => draft === 1,
      then: Yup.number().notRequired(),
      otherwise: Yup.number().required('* Please enter the minimum salary'),
    })
    .max(Yup.ref('salaryMax'), 'Minimum Salary should be lower than Maximum Salary'),

  salaryMax: Yup.number()
    .when('draft', {
      is: draft => draft === 1,
      then: Yup.number().notRequired(),
      otherwise: Yup.number().required('* Please enter the maximum salary'),
    })
    .min(Yup.ref('salaryMin'), 'Maximum Salary should be higher than Minimum Salary'),
});

export const corporateEditJobSchema = Yup.object().shape({
  draft: Yup.number().notRequired(),
  title: Yup.string().trim().required('* Please enter the title').max(100, '* Title cannot exceed 100 Characters'),
  industryId: Yup.number().when('draft', {
    is: draft => draft === 0,
    then: Yup.number().notRequired(),
    otherwise: Yup.number().required('* Please enter your industry'),
  }),
  countryId: Yup.number().when('draft', {
    is: draft => draft === 0,
    then: Yup.number().notRequired(),
    otherwise: Yup.number().required('* Please enter the country'),
  }),
  eduLvlId: Yup.number().when('draft', {
    is: draft => draft === 0,
    then: Yup.number().notRequired(),
    otherwise: Yup.number().required('* Please enter your highest level of education'),
  }),
  /* 	jobPostTypeId: Yup.number().required("* Job Post Type is required"),
	jobTypeId: Yup.number().when("jobPostTypeId", {
		is: (jobPostTypeId) => jobPostTypeId === 2,
		then: Yup.number().required("* Job Type is required"),
		otherwise: Yup.number().notRequired(),
	}), */
  jobTypeId: Yup.number().when('draft', {
    is: draft => draft === 0,
    then: Yup.number().notRequired(),
    otherwise: Yup.number().required('* Please select a job post type'),
  }),
  jobPostTypeId: Yup.number().when('draft', {
    is: draft => draft === 0,
    then: Yup.number().notRequired(),
    otherwise: Yup.number().required('* Please enter the job type'),
  }),
  role: Yup.string()
    .trim()
    .when('draft', {
      is: draft => draft === 0,
      then: Yup.string().notRequired(),
      otherwise: Yup.string().required('* Please enter a role'),
    })
    .max(100, '* Role cannot exceed 100 Characters'),
  servicePeriod: Yup.number()
    .when('draft', {
      is: draft => draft === 0,
      then: Yup.number().notRequired(),
      otherwise: Yup.number().required('* Please enter your service period '),
    })
    .max(60, '* Service Period cannot exceed 5 years'),
  currencyId: Yup.number().when('draft', {
    is: draft => draft === 0,
    then: Yup.number().notRequired(),
    otherwise: Yup.number().required('* Please enter the currency'),
  }),
  // responsibility: Yup.string()
  // 	.trim()
  // 	.when("draft", {
  // 		is: (draft) => draft === 0,
  // 		then: Yup.string().notRequired(),
  // 		otherwise: Yup.string().required("* Please enter the responsibility"),
  // 	})
  // 	.max(3000, "* Responsibility cannot exceed 3000 Characters"),
  // requirement: Yup.string()
  // 	.trim()
  // 	.when("draft", {
  // 		is: (draft) => draft === 0,
  // 		then: Yup.string().notRequired(),
  // 		otherwise: Yup.string().required("* Please enter a requirement"),
  // 	})
  // 	.max(3000, "* Requirement cannot exceed 3000 Characters"),
  salaryMin: Yup.number()
    .when('draft', {
      is: draft => draft === 1,
      then: Yup.number().notRequired(),
      otherwise: Yup.number().required('* Please enter the minimum salary'),
    })
    .max(Yup.ref('salaryMax'), 'Minimum Salary should be lower than Maximum Salary'),

  salaryMax: Yup.number()
    .when('draft', {
      is: draft => draft === 1,
      then: Yup.number().notRequired(),
      otherwise: Yup.number().required('* Please enter the maximum salary'),
    })
    .min(Yup.ref('salaryMin'), 'Maximum Salary should be higher than Minimum Salary'),
}); /* Yup.number().required("* Minimum Salary is required") */

export const groupValidationSchema = Yup.object().shape({
  profilePic: Yup.string().required('Profile picture is required'),
  groupName: Yup.string().required('Group name is required'),
  groupDescription: Yup.string().required('Group description is required.'),
  industryId: Yup.number().required('Industry is required.').nullable(),
  countryId: Yup.number().required('Country is required.').nullable(),
  customIndustryName: Yup.string().nullable(),
  groupRule: Yup.string().required('Group rules is required'),
  visibleId: Yup.string().nullable(),
  permissionId: Yup.array().of(Yup.number()),
});

const localTimeCount = time => {
  const momentTime = moment(time); // convert into moment time from db
  const timezone = moment().utcOffset() / 60; // get client timezone hour
  var localTime;
  if (timezone > 0) {
    // GMT + N
    localTime = momentTime.add(timezone, 'h');
  } else {
    localTime = momentTime.subtract(timezone, 'h');
  }

  return localTime;
};

export const TimeFormat = formatTime => {
  var localTime = localTimeCount(formatTime);
  const formattedTime = ta.format(new Date(localTime));

  return formattedTime;
};

export const TimeFormatWithoutTimeAgo = formatTime => {
  var localTime = localTimeCount(formatTime);
  var diffTime = Date.now() - localTime;
  var finalTime = new Date(localTime);
  var formattedTime = ta.format(finalTime);
  if (diffTime > 1000 * 60 * 24) {
    formattedTime = dateFormat(finalTime, 'dd mmm HH:MM');
  }

  return formattedTime;
};

export const TimeFormatOnlyDate = formatTime => {
  var localTime = localTimeCount(formatTime);
  var formattedTime = dateFormat(localTime, 'dd mmm yyyy');

  return formattedTime;
};

export const TimeFormatFirstDateOfMonth = formatTime => {
  var localTime = localTimeCount(formatTime.replace(/-/g, '/'));
  var formattedTime = dateFormat(localTime, 'yyyy/mm/dd');

  return formattedTime;
};

export const TimeFormatSocialFeed = formatTime => {
  var localTime = localTimeCount(formatTime);
  var formattedTime = dateFormat(localTime, "d mmmm 'at' HH:MM TT");

  return formattedTime;
};

export const TimeFormatPollConvert = formatTime => {
  try {
    var localTime = localTimeCount(formatTime);
    var formattedTime = dateFormat(localTime, "yyyy-mm-dd'T'HH:MM:ss");

    return formattedTime;
  } catch (error) {
    return null;
  }
};

export const TimeFormatPollLeft = formatTime => {
  var localTime = localTimeCount(formatTime);
  var formattedTime = new Date(localTime);
  var nowTime = new Date();
  // var diff = parseInt(
  // 	(formattedTime.getTime() - nowTime.getTime()) / (24 * 3600 * 1000)
  // );
  var diff = formattedTime.getTime() >= nowTime.getTime();
  return diff;
};

export const TimeFormatUniqueId = () => {
  const d = new Date();
  const date = d.toISOString().split('T')[0];
  const time = d.toTimeString().split(' ')[0];
  const second = d.getMilliseconds();
  const uniqueId = date.split('-').join('') + time.split(':').join('') + second;
  var formattedTime = uniqueId;

  return formattedTime;
};

export const gaPageView = pagePath => {
  ReactGA.pageview(pagePath);
};

export const createSocialPost = (headers, data) =>
  axios.post(`${instanceUrl}/api/sf/create-social-post`, data, {
    headers: { ...headers },
    data: { ...data },
  });

export const createReport = data => axios.post(`${instanceUrl}/api/rpt/create-report`, { data: { ...data } });

export const createHideReport = data => axios.post(`${instanceUrl}/api/sf/create-hide-report`, { data: { ...data } });

export const createSocialHashtag = data =>
  axios.post(`${instanceUrl}/api/sf/create-social-hashtag`, {
    data: { ...data },
  });

export const createSocialPostHashtag = data =>
  axios.post(`${instanceUrl}/api/sf/create-social-post-hashtag`, {
    data: { ...data },
  });

export const createSocialPostProfileTag = data =>
  axios.post(`${instanceUrl}/api/sf/create-social-post-profile-tag`, {
    data: { ...data },
  });

export const createPostType = data => axios.post(`${instanceUrl}/api/sf/create-post-type`, { data: { ...data } });

export const createPollQuestion = data =>
  axios.post(`${instanceUrl}/api/sf/create-pool-question`, {
    data: { ...data },
  });

export const createPollOption = data => axios.post(`${instanceUrl}/api/sf/create-pool-option`, { data: { ...data } });

export const createSocialReaction = (headers, data) =>
  axios.post(`${instanceUrl}/api/sf/create-social-reaction`, {
    headers: { ...headers },
    data: { ...data },
  });

export const createSocialComment = (headers, data) =>
  axios.post(`${instanceUrl}/api/sf/create-social-comment`, data, {
    headers: { ...headers },
    data: { ...data },
  });
// export const createSocialComment = (headers, data) => { console.log(...data); return axios.post(`${instanceUrl}/api/sf/create-social-comment`, { headers: { ...headers }, data: { ...data } }) }

export const createHidePost = params => axios.post(`${instanceUrl}/api/sf/create-hide-post`, { ...params });

export const getSocialPostByTop = params =>
  axios.get(`${instanceUrl}/api/sf/get-social-post-by-top`, {
    params: { ...params },
  });

export const getSocialPostByRecent = params =>
  axios.get(`${instanceUrl}/api/sf/get-social-post-by-recent`, {
    params: { ...params },
  });

export const getSocialCommentByPostId = params =>
  axios.get(`${instanceUrl}/api/sf/get-social-comment-by-post-id`, {
    params: { ...params },
  });

export const getSocialReplyByCommentId = params =>
  axios.get(`${instanceUrl}/api/sf/get-social-reply-by-comment-id`, {
    params: { ...params },
  });

export const getVisibleOption = () => axios.get(`${instanceUrl}/api/md/get-visible-option`);

export const getSocialPostTypeOption = () => axios.get(`${instanceUrl}/api/md/get-social-post-type-option`);

export const getCommentPermissionByPermissionType = types =>
  axios.get(`${instanceUrl}/api/md/get-comment-permission-by-permission-type?permissionType=${types}`);

export const getPollDurationOption = () => axios.get(`${instanceUrl}/api/md/get-pool-duration-option`);

export const getSocialPostByUserId = params =>
  axios.get(`${instanceUrl}/api/sf/get-social-post-by-user-id`, {
    params: { ...params },
  });

export const getSavePostByAcctId = params =>
  axios.get(`${instanceUrl}/api/sf/get-save-post-by-acct-id`, {
    params: { ...params },
  });

export const getSocialHashtag = params =>
  axios.get(`${instanceUrl}/api/sf/get-social-hashtag`, {
    params: { ...params },
  });

export const getSocialPostProfileTagByAcctId = params =>
  axios.get(`${instanceUrl}/api/sf/get-social-post-profile-tag-by-acct-id`, {
    params: { ...params },
  });

export const getSocialPostHashtagByPostId = params =>
  axios.get(`${instanceUrl}/api/sf/get-social-post-hashtag-by-post-id`, {
    params: { ...params },
  });

export const getSocialPostProfileTagByPostId = params =>
  axios.get(`${instanceUrl}/api/sf/get-social-post-profile-tag-by-post-id`, {
    params: { ...params },
  });

export const handleFollow = data => {
  axios.post(`${instanceUrl}/api/um/create-follow-profile`, data);
};

export const handleUnfollow = data => {
  axios.put(`${instanceUrl}/api/um/delete-follow-profile`, data);
};

export const getFollowHashtagByAcctId = params =>
  axios.get(`${instanceUrl}/api/sf/get-follow-hashtag-by-acct-id`, {
    params: { ...params },
  });

export const getFollowProfileByAcctId = params =>
  axios.get(`${instanceUrl}/api/um/get-follow-profile-by-acct-id`, {
    params: { ...params },
  });
/////

export const getCountryId = token => {
  const res = axios.get(`${instanceUrl}/api/md/get-all-country`, {
    cancelToken: token,
  });
  return res;
};

export const getAreaCodeId = token => {
  const res = axios.get(`${instanceUrl}/api/md/get-all-area-code`, {
    cancelToken: token,
  });
  return res;
};

export const getLocationId = token => {
  const res = axios.get(`${instanceUrl}/api/md/get-all-location`, {
    cancelToken: token,
  });
  return res;
};

export const getLocationIdByCountry = countryId => {
  const res = axios.get(`${instanceUrl}/api/md/get-all-location-by-country`, {
    cancelToken: undefined,
    params: { countryId: countryId },
  });
  return res;
};
export const getDepartmentIdByIndustry = industryId => {
  const res = axios.get(`${instanceUrl}/api/md/get-all-department-by-industry`, {
    params: { industryId: industryId },
  });
  return res;
};

export const getJobTypeIdByJobPostType = jobPostTypeId => {
  const res = axios.get(`${instanceUrl}/api/md/get-job-type-by-job-post-type-id`, {
    cancelToken: undefined,
    params: { jobPostTypeId },
  });
  console.log(jobPostTypeId);
  return res;
};

export const getRelationshipByAcctId = async ({ authorAcctId, ownerAcctId }) => {
  const body = {
    authorAcctId,
    ownerAcctId,
    typeId: 1,
  };
  const res = axios.get(`${instanceUrl}/api/md/get-user-relationship-by-type-id`, { params: body });
  return res;
};

export const getPositionByAcctId = async acctId => {
  const res = axios.get(`${instanceUrl}/api/md/get-user-position`, {
    params: { acctId },
  });

  return res;
};

export const getExperienceLevelList = () => {
  const res = axios.get(`${instanceUrl}/api/md/get-all-job-experience`);
  return res;
};

export const getEducationLevelId = token => {
  const res = axios.get(`${instanceUrl}/api/md/get-all-education-level`, {
    cancelToken: token,
  });
  return res;
};
export const getIndustryId = token => {
  const res = axios.get(`${instanceUrl}/api/md/get-all-industry`, {
    cancelToken: token,
  });
  return res;
};
export const getLocationById = countryId => {};
export const getSchoolId = token => {
  const res = axios.get(`${instanceUrl}/api/md/get-all-school`, {
    cancelToken: token,
  });
  return res;
};

export const getFieldStudyId = token => {
  const res = axios.get(`${instanceUrl}/api/md/get-all-study-field`, {
    cancelToken: token,
  });
  return res;
};

export const getCurrencyId = token => {
  const res = axios.get(`${instanceUrl}/api/md/get-all-currency`, {
    cancelToken: token,
  });
  return res;
};
export const getCompanyTypeId = token => {
  const res = axios.get(`${instanceUrl}/api/md/get-all-company-type`, {
    cancelToken: token,
  });
  return res;
};
export const getJobPostType = () => {
  const res = axios.get(`${instanceUrl}/api/md/get-all-job-post-type`);
  return res;
};
export const getJobType = () => {
  const res = axios.get(`${instanceUrl}/api/md/get-all-job-type`);
  return res;
};
export const getReportReasonByReasonType = reasonType => {
  const res = axios.get(`${instanceUrl}/api/md/get-report-reason-by-reason-type`, {
    params: { reasonType: reasonType },
  });
  return res;
};
export const getReportType = () => {
  const res = axios.get(`${instanceUrl}/api/md/get-report-type`);
  return res;
};
export const getSocialReaction = () => {
  const res = axios.get(`${instanceUrl}/api/md/get-social-reaction`);
  return res;
};
export const getBackgroundPictureTemplates = (lastRecordSize, requestRecordSize) => {
  const body = {
    lastRecordSize,
    requestRecordSize,
  };
  const res = axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/md/get-background-picture-templates`,
    params: body,
  });
  return res;
};

export const getRecomendedJobPost = params => {
  console.log(params);
  const res = axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/portal/search-job-oppty-recommend`,
    params: params,
  });
  return res;
};

export const getMonthData = () => {
  const month = [
    { monthId: '01', monthName: 'Jan' },
    { monthId: '02', monthName: 'Feb' },
    { monthId: '03', monthName: 'Mar' },
    { monthId: '04', monthName: 'Apr' },
    { monthId: '05', monthName: 'May' },
    { monthId: '06', monthName: 'Jun' },
    { monthId: '07', monthName: 'Jul' },
    { monthId: '08', monthName: 'Aug' },
    { monthId: '09', monthName: 'Sep' },
    { monthId: '10', monthName: 'Oct' },
    { monthId: '11', monthName: 'Nov' },
    { monthId: '12', monthName: 'Dec' },
  ];
  return month;
};

export const getStartYearData = () => {
  const startYear = new Date().getFullYear();
  const min = startYear - 60;
  const startYearData = [];

  for (var i = startYear; i >= min; i--) {
    startYearData.push(JSON.stringify(i));
  }

  return startYearData;
};
export const getEndYearData = () => {
  const initialYear = new Date().getFullYear(); //2021
  const min = initialYear - 60; //2001
  const endYearData = [];

  //2021 + 5

  for (var i = initialYear + 5; i >= min; i--) {
    endYearData.push(JSON.stringify(i));
  }

  return endYearData;
};

export const salaryRange = [
  {
    minSalary: 600,
    maxSalary: 1000,
    step: 50,
    minRange: 0,
    maxRange: 1500,
  },
  {
    minSalary: 1800,
    maxSalary: 3000,
    step: 100,
    minRange: 0,
    maxRange: 4500,
  },
];

export const hashKey = () => {
  const hashKey = 'oppty';
  return hashKey;
};

export const encryptData = keyword =>
  CryptoJS.AES.encrypt(JSON.stringify(keyword), hashKey()).toString().replace(/\//g, '*');
export const decryptData = keyword => {
  const rawText = keyword.replace(/\*/g, '/');
  const bytes = CryptoJS.AES.decrypt(rawText, hashKey());
  const decrypt = bytes.toString(CryptoJS.enc.Utf8);
  const decryptedData = Number(decrypt);

  return decryptedData;
};
export const decryptDataString = keyword => {
  const rawText = keyword.replace(/\*/g, '/');
  const bytes = CryptoJS.AES.decrypt(rawText, hashKey());
  const decrypt = bytes.toString(CryptoJS.enc.Utf8);
  // const decryptedData = Number(decrypt);

  return decrypt;
};
export const countMonthYear = (startDate, endDate) => {
  startDate = startDate.replace(/-/g, '/');
  endDate = endDate && endDate.replace(/-/g, '/');

  var startCount = new Date(startDate);
  /* startCount = startCount.replace(/-/g, "/"); */

  var endCount = endDate ? new Date(endDate) : new Date();
  /* endCount = endCount.replace(/-/g, "/");
   */

  var startYear = startCount.getFullYear();
  var endYear = endCount.getFullYear();
  var startMonth = startCount.getMonth();
  var endMonth = endCount.getMonth();

  if (startMonth === 0) {
    //Have to take into account
    startMonth++;
    endMonth++;
  }
  if (startCount && endCount) {
    const numberOfMonths = (endYear - startYear) * 12 + (endMonth - startMonth);
    var lengthOfDates = '';

    /* console.log(numberOfMonths);
		console.log(Math.floor(numberOfMonths / 12));
		console.log(numberOfMonths % 12); */
    if (numberOfMonths >= 12) {
      // if the condition is more than a year
      if (numberOfMonths % 12) {
        if (Math.floor(numberOfMonths / 12) > 1 && numberOfMonths % 12 > 1) {
          lengthOfDates = Math.floor(numberOfMonths / 12) + ' Years ' + (numberOfMonths % 12) + ' Months ';
        } else if (Math.floor(numberOfMonths / 12) > 1 && numberOfMonths % 12 === 1) {
          lengthOfDates = Math.floor(numberOfMonths / 12) + ' Years ' + (numberOfMonths % 12) + ' Month ';
        } else if (Math.floor(numberOfMonths / 12) === 1 && numberOfMonths % 12 > 1) {
          lengthOfDates = Math.floor(numberOfMonths / 12) + ' Year ' + (numberOfMonths % 12) + ' Months ';
        } else {
          lengthOfDates = Math.floor(numberOfMonths / 12) + ' Year ' + (numberOfMonths % 12) + ' Month ';
        }
      } else {
        lengthOfDates =
          Math.floor(numberOfMonths / 12) > 1
            ? Math.floor(numberOfMonths / 12) + ' Years '
            : Math.floor(numberOfMonths / 12) + ' Year ';
      }
    } else {
      if (numberOfMonths > 1) {
        lengthOfDates = numberOfMonths + ' Months';
      } else if (numberOfMonths === 0) {
        lengthOfDates = '1 Month';
      } else {
        lengthOfDates = numberOfMonths + ' Month';
      }
    }
    return lengthOfDates;
  } else {
    return null;
  }
};

export const emptyCacheNoReload = () => {
  if ('caches' in window) {
    caches.keys().then(names => {
      // Delete all the cache files
      names.forEach(name => {
        caches.delete(name);
      });
    });
    nocache();
  }
};

export const emptyCache = () => {
  if ('caches' in window) {
    caches.keys().then(names => {
      // Delete all the cache files
      names.forEach(name => {
        caches.delete(name);
      });
    });
    nocache();
    // Makes sure the page reloads. Changes are only visible after you refresh.
    window.location.reload(true);
    window.location.replace('/');
    console.log('no cache');
  }
};

export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const blobToB64 = blob =>
  new Promise((resolve, reject) => {
    console.log(blob);
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
