import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'quill-mention';
import 'react-quill/dist/quill.bubble.css';
import ReactQuill, { Quill } from 'react-quill';
import MagicUrl from 'quill-magic-url';
import commentStyle from '../../assets/jss/material-kit-pro-react/views/componentStyle/socialFeed/commentStyles';
import { makeStyles } from '@material-ui/core';
import {
  getSocialHashtag,
  getSocialProfileTag,
  createSocialHashtag,
  resetCreatedHashtag,
  getSocialProfileTagByGroupId,
} from 'configurations/redux/socialFeedSlice.js';
import defaultAvatar from 'assets/img/candidate_avatar.jpeg';
import { debounce } from 'lodash';

const PostInput = ({
  post,
  emojiChoose,
  resetEmoji,
  getLength,
  getText,
  getHTML,
  getContent,
  setAddLink,
  postId,
  quillRefPush,
  postClick,
  createPost,
  groupId,
}) => {
  const quillRef = React.createRef(null);
  const classes = makeStyles(commentStyle)();
  const dispatch = useDispatch();
  const hashtagList = useSelector(state => state.socialFeed.hashtagList);
  const hashtagKeyword = useSelector(state => state.socialFeed.hashtagKeyword);
  const profileTagList = useSelector(state => state.socialFeed.profileTagList);
  const profileTagKeyword = useSelector(state => state.socialFeed.profileTagKeyword);
  const hashValue = useSelector(state => state.socialFeed.hashValue);
  const createHashtagCommentId = useSelector(state => state.socialFeed.createHashtagCommentId);
  const createHashtagPostId = useSelector(state => state.socialFeed.createHashtagPostId);
  const enterKey = useSelector(state => state.socialFeed.enterKey);

  const user = useSelector(state => state.auth.user);
  const [checkLastHashtag, setCheckLastHashtag] = useState(false);

  const mentionModuleConfig = {
    allowedChars: /^[A-Za-z0-9\sÅÄÖåäö]*$/,
    mentionDenotationChars: ['@', '#'],
    source: function (searchTerm, renderList, mentionChar) {
      // console.log(searchTerm);

      if (mentionChar === '@') {
        renderList(profileTagList);
      } else {
        renderList(hashtagList);
      }

      if (profileTagKeyword != searchTerm && mentionChar != '' && mentionChar === '@') {
        getProfileTagDebounce(searchTerm);
      } else if (hashtagKeyword != searchTerm && mentionChar != '' && mentionChar === '#') {
        getHashtagDebounce(searchTerm);
        if (searchTerm.slice(-1) === ' ') {
          var newHashTag = searchTerm.trim();
          // console.log(newHashTag);
          storeNewHashTag(newHashTag);
        }
      }
    },
    renderItem: function (item, searchTerm) {
      // console.log(item.value);
      return `<div>
						${
              item.mentionChar == '@'
                ? `<img
									style="width:40px;border-radius:50%;border:1px solid"
									src=${item.profilePic ? item.profilePic : defaultAvatar}
									alt=""/>
									<span style="margin-left:5px">${item.value}</span>`
                : `<span>${item.value}</span>`
            }
					</div>`;
    },
  };

  const modules = {
    mention: mentionModuleConfig,
    magicUrl: true,
    clipboard: {
      matchers: [
        [
          Node.ELEMENT_NODE,
          (node, delta) => {
            let ops = [];
            delta.ops.forEach(op => {
              if (op.insert && typeof op.insert === 'string') {
                ops.push({
                  insert: op.insert.trim(),
                });
              }
            });
            delta.ops = ops;
            return delta;
          },
        ],
      ],
    },
  };

  const getProfileTagDebounce = debounce(searchTerm => {
    // console.log(searchTerm);
    getProfileTagList(searchTerm);
  }, 200);

  const getHashtagDebounce = debounce(searchTerm => {
    // console.log(searchTerm);
    getHashTagList(searchTerm);
  }, 200);

  useEffect(() => {
    var postId = post ? post.postId : 0;
    if (createHashtagPostId == postId) {
      if (hashValue) {
        resetCreatedHashtag();
      }
      var quillEditor = null;
      var quillContent = null;
      quillEditor = quillRef.current;
      quillContent = quillRef.current.editor.getContents();
      if (quillEditor) {
        for (let i = 0; i < quillContent.ops.length; i++) {
          let insert = quillContent.ops[i].insert;
          try {
            console.log(insert.mention.denotationChar);
          } catch (e) {
            if (insert) {
              let hashtag = insert.replace('\n', '');
              if (hashtag.charAt(0) == ' ') hashtag = hashtag.substring(1);
              var splitHash = hashtag.split(' ');
              console.log(splitHash);
              for (var j = 0; j < splitHash.length; j++) {
                if (splitHash[j].charAt(0) == '#') {
                  var newEditor = quillEditor.getEditor();
                  var unprivilegedEditor = quillEditor.makeUnprivilegedEditor(newEditor);
                  if (hashValue) {
                    let newHashtag =
                      '<span class="mention" data-index="0" data-denotation-char="#" data-id="' +
                      hashValue.id +
                      '" data-value="<a href=&quot;' +
                      hashValue.link +
                      '&quot; target=_blank>' +
                      hashValue.value +
                      '" data-link="' +
                      hashValue.link +
                      '"><span contenteditable="false"><span class="ql-mention-denotation-char">#</span><a href="' +
                      hashValue.link +
                      '" target="_blank">' +
                      hashValue.value +
                      '</a></span></span>';
                    var newDescription = unprivilegedEditor
                      .getHTML()
                      .replace(splitHash[j].replace('\n', ''), newHashtag);
                    console.log(newDescription);
                    let newQuill = new Quill('#quillEditorPost');
                    let initialContent = newQuill.clipboard.convert(newDescription);
                    quillEditor.editor.setContents(initialContent, 'silent');
                    quillEditor.editor.focus();
                    quillEditor.editor.insertText(quillEditor.editor.getLength() - 1, enterKey ? '\n' : ' ');
                    quillEditor.editor.setSelection(quillEditor.editor.getLength(), quillEditor.editor.getLength());
                  }
                }
              }
            }
          }
        }
      }
    }
  }, [hashValue, createHashtagPostId]);

  const checkNewHashTag = (newHashtag, enterKey) => {
    var splitText = newHashtag.split(' ');
    if (splitText && splitText.length > 0) {
      if (splitText[splitText.length - 1].charAt(0) == '#') {
        if (splitText[splitText.length - 1])
          storeNewHashTag(splitText[splitText.length - 1].substring(1).trim(), enterKey);
      } else {
        quillRef.current.editor.insertText(quillRef.current.editor.getLength() - 1, ' ');
        quillRef.current.editor.deleteText(quillRef.current.editor.getLength() - 2, 1, 'silent');
      }
    }
  };

  const storeNewHashTag = (name, enterKey) => {
    const requestProfile = {
      email: user.email,
      acctId: user.acctId,
      hashtagName: name.toLowerCase(),
      accessToken: localStorage.getItem('token'),
      commentId: 0,
      postId: post ? post.postId : 0,
      enterKey: enterKey,
    };
    dispatch(createSocialHashtag(requestProfile));
  };

  const getHashTagList = searchTerm => {
    const profileRequest = {
      email: user.email,
      acctId: user.acctId,
      keyword: searchTerm,
      lastRecordSize: 0,
      requestRecordSize: 10,
      accessToken: localStorage.getItem('token'),
    };
    dispatch(getSocialHashtag(profileRequest));
  };

  const getProfileTagList = searchTerm => {
    const profileRequest = {
      email: user.email,
      acctId: user.acctId,
      keyword: searchTerm,
      groupId: groupId ? groupId : 0,
      lastRecordSize: 0,
      requestRecordSize: 10,
      accessToken: localStorage.getItem('token'),
    };
    // console.log(profileRequest);
    if (groupId > 0) {
      dispatch(getSocialProfileTagByGroupId(profileRequest));
    } else {
      dispatch(getSocialProfileTag(profileRequest));
    }
  };

  const onQuillChange = (content, delta, source, editor) => {
    // console.log(content, delta, source, editor);
    // console.log(editor);
    var counter = document.getElementById('quillPostCounter');
    if (counter) {
      var wordCount = editor.getLength() - 1;
      if (wordCount <= 3000) {
        counter.innerHTML = '(' + wordCount + '/3000)';
        counter.style.color = '#000000DE';
      } else {
        counter.innerHTML = 'Description is excceding maximum character limit (' + wordCount + '/3000)';
        counter.style.color = 'red';
      }
    }

    if (emojiChoose || hashValue) {
      // setTimeout(() => {
      // 	console.log("reset");
      // 	dispatch(resetCreatedHashtag());
      // }, 3000);
      // getLength(editor.getText().length - 1);
      // getText(editor.getText());
      // getHTML(editor.getHTML());
      // getContent(editor.getContents());
    }

    debouceRequest(editor.getText().length - 1, editor.getHTML().replaceAll(`&nbsp;`, ''), editor.getContents());

    // get
    // quillRefPush(quillRef);s
  };

  const debouceRequest = debounce((length, html, content) => {
    console.log(length, html, content);
    getLength(length);
    // getHTML(html);
    // getContent(content);

    try {
      for (var i = 0; i < content.ops.length; i++) {
        // try {
        console.log(content.ops[i].attributes.link);
        if (content.ops[i].attributes.link && content.ops[i].attributes.link.length > 0) {
          // setAddLink(content.ops[i].attributes.link);
          break;
        } else {
          setAddLink(null);
        }
        // } catch (e) {
        // 	setAddLink(null);
        // }
      }
    } catch (e) {
      setAddLink(null);
    }

    if (postClick && checkLastHashtag) {
      console.log(postClick, checkLastHashtag);
      var latestDescription = { length: length, html: html, content: content };
      setCheckLastHashtag(false);
      setTimeout(() => {
        createPost(latestDescription);
      }, 1000);
    }
  }, 500);

  useEffect(() => {
    console.log('postCLick', postClick);
    if (postClick) {
      setCheckLastHashtag(true);
      // console.log(quillRef.current);
      if (quillRef.current.editor.getLength() == 1) {
        var quillEditor = quillRef.current;
        var newEditor = quillEditor.getEditor();
        var unprivilegedEditor = quillEditor.makeUnprivilegedEditor(newEditor);
        console.log(unprivilegedEditor);
        var latestDescription = {
          length: unprivilegedEditor.getLength() - 1,
          html: unprivilegedEditor.getHTML(),
          content: unprivilegedEditor.getContents(),
        };
        setTimeout(() => {
          createPost(latestDescription);
        }, 1000);
      } else checkNewHashTag(quillRef.current.editor.getText(), false);
    }
  }, [postClick]);

  console.log(postClick, createPost);
  useEffect(() => {
    if (postId > 0 && post) editPost(post);
  }, []);

  const editPost = post => {
    var timer = setInterval(() => {
      console.log(quillRef);
      try {
        if (quillRef) {
          clearInterval(timer);
          let newQuill = new Quill('#quillEditor');
          let initialContent = newQuill.clipboard.convert(post.description);
          quillRef.current.editor.setContents(initialContent, 'silent');
          quillRef.current.editor.focus();
          quillRef.current.editor.setSelection(
            quillRef.current.editor.getLength(),
            quillRef.current.editor.getLength()
          );
        }
      } catch (e) {}
    }, 100);
  };

  Quill.register('modules/magicUrl', MagicUrl);

  return (
    <div>
      <ReactQuill
        ref={quillRef}
        placeholder="What's on your mind?"
        className={classes.quillCommentEditor}
        id="quillPostEditor"
        style={{ height: '180px' }}
        theme="bubble"
        multiline={true}
        // contenteditable={true}
        modules={modules}
        // defaultValue={postId > 0 && post ? post.description : ""}
        // onChange={setValue}
        // value={value}
        onChange={onQuillChange}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            checkNewHashTag(quillRef.current.editor.getText(), true);
          }
        }}
      ></ReactQuill>
      <div style={{ fontSize: '14px' }} id="quillPostCounter">
        (0/3000)
      </div>
    </div>
  );
};

export default React.memo(PostInput);
