import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { messageConversationInfo } from "configurations/redux/messageConversationSlice.js";
import { messageChatroomListInfo } from "configurations/redux/messageChatroomListSlice.js";
import {
	deleteChatroom,
	messageNewChatroomInfo,
} from "configurations/redux/messageChatroomSlice";
import Dialog from "../../../components/CustomDialog/CustomDialog.js";
import { TimeFormat } from "configurations/configurations";
import { ChatItem } from "react-chat-elements";
// import defaultAvatar from "assets/img/placeholder.jpg";
import candidate_avatar from "assets/img/candidate_avatar.jpeg";
import corporate_avatar from "assets/img/corporate_avatar.jpeg";
import { isTablet } from "react-device-detect";
export default function MessageChatroomCard({ item, props }) {
	// console.log(item)
	// const useStyles = makeStyles((theme) => ({
	// 	listItemUnread: {
	// 		backgroundColor: "#DBF9FF",
	// 	},
	// 	listSpaceRemoved: {
	// 		paddingTop: "0px!important",
	// 		paddingBottom: "0px!important",
	// 	},
	// }));
	// const classes = useStyles();
	const dispatch = useDispatch();
	const userStatus = useSelector((state) => state.auth.user);
	const activeBizId = useSelector((state) => state.messageChatroom.activeBizId);
	const [clientIp, setClientIp] = React.useState();
	const searchResult = useSelector(
		(state) => state.messageChatroom.searchResult
	);
	const showProfilePic =
		item.privacySetting &&
		item.privacySetting
			.filter((item) => {
				return item.settingName === "showProfilePic";
			})
			.map((item) => {
				return item.settingValue === 1;
			})[0];
	const showProfile =
		item.privacySetting &&
		item.privacySetting
			.filter((item) => {
				return item.settingName === "showProfile";
			})
			.map((item) => {
				return item.settingValue === 1;
			})[0];

	const dialogDefault = {
		title: "Alert",
		description: "Are you sure to delete this conversation?",
		buttonYes: "Delete",
		buttonNo: "Cancel",
		chatroomId: item.chatroomId,
	};
	const [confirmDialog, setConfirmDialog] = React.useState({
		isOpen: false,
		dialogDefault,
	});

	const requestProfile = {
		acctId: userStatus.acctId,
		email: userStatus.email,
		accessToken: localStorage.getItem("token"),
		ipAddr: clientIp,
	};
	const requestChatroomStatusUpdate = {
		acctId: userStatus.acctId,
		email: userStatus.email,
		accessToken: localStorage.getItem("token"),
		ipAddr: clientIp,
		chatroomId: item.chatroomId,
		chatroomStatus: "read",
		searchResult: searchResult,
	};

	const getChatroomNavigate = (item) => {
		const requestProfile = {
			acctId: userStatus.acctId,
			email: userStatus.email,
			accessToken: localStorage.getItem("token"),
			lastRecordSize: 0,
			requestRecordSize: isTablet ? 30 : 15,
			chatroomId: item.chatroomId,
			keyword: "",
		};
		dispatch(messageChatroomListInfo(requestProfile));
		dispatch(messageConversationInfo(requestProfile));
		setTimeout(() => {
			loadNewChatroom();
		}, 1000);
	};

	const loadNewChatroom = () => {
		const requestProfile = {
			acctId: userStatus.acctId,
			accessToken: localStorage.getItem("token"),
			email: userStatus.email,
			searchResult: searchResult,
			bizId: activeBizId,
		};
		console.log(requestProfile);
		dispatch(messageNewChatroomInfo(requestProfile));
	};

	const deleteHandle = (item) => {
		setConfirmDialog({
			isOpen: true,
			dialogDefault,
			onConfirm: () => {
				dispatch(deleteChatroom(requestChatroomStatusUpdate));
				setConfirmDialog({
					isOpen: false,
					dialogDefault,
				});
			},
		});
	};

	return (
		<React.Fragment>
			<div>
				<ChatItem
					avatar={
						showProfile
							? showProfilePic && item.profilePic !== null
								? item.profilePic
								: item.acctTypeId === 1 || item.acctTypeId === 3
								? candidate_avatar
								: corporate_avatar
							: item.acctTypeId === 1 || item.acctTypeId === 3
							? candidate_avatar
							: corporate_avatar
					}
					alt={item.title}
					title={item.title}
					subtitle={
						item.lastMessage != null
							? // ? item.lastMessage.length > 25
							  // 	? item.lastMessage.substring(0, 25) + "..."
							  item.lastMessage
							: "No Message Created Yet."
					}
					dateString={
						item.lastUpdateDate != null ? TimeFormat(item.lastUpdateDate) : null
					}
					unread={item.readStatus}
					onClick={() => {
						getChatroomNavigate(item);
					}}
				/>

				<Dialog
					confirmDialog={confirmDialog}
					setConfirmDialog={setConfirmDialog}
				/>
			</div>
		</React.Fragment>
	);
}
