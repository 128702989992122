import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { withdrawAppliedJob } from "configurations/redux/appliedJobSlice.js";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
// @material-ui/icons
// import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.js";

import style from "assets/jss/material-kit-pro-react/modalStyle.js";
import IconLibrary from "views/Component/IconLibrary";

const useStyles = makeStyles(style);
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

export default function WithdrawlModal({ open, onClose, applId }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const userStatus = useSelector((state) => state.auth.user);

	const updateRequest = {
		acctId: userStatus.acctId,
		email: userStatus.email,
		accessToken: localStorage.getItem("token"),
		applId,
		applicantId: userStatus.acctId,
		applStatusId: 8,
	};
	return (
		<React.Fragment>
			<Dialog
				classes={{
					root: classes.modalRoot,
					paper: classes.modalLoader,
				}}
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={onClose}
				aria-labelledby="classic-modal-slide-title"
				aria-describedby="classic-modal-slide-description"
			>
				<DialogTitle
					id="classic-modal-slide-title"
					disableTypography
					className={classes.modalHeader}
				>
					<Button
						simple
						className={classes.modalCloseButton}
						key="close"
						aria-label="Close"
						onClick={onClose}
					>
						{" "}
						<IconLibrary.Close />
					</Button>
					<h4 className="withdrawtItle">
						<b>Withdraw Job Application</b>
					</h4>
				</DialogTitle>
				<DialogContent
					id="classic-modal-slide-description"
					className={classes.modalBody}
				>
					<DialogContentText className="withdrawjObtitle" id="alert-dialog-slide-description">
						Are you sure to withdraw this Job Application?
					</DialogContentText>
					{/* <h5>
						<b>Are you sure to withdraw this Job Application?</b>
					</h5> */}
				</DialogContent>
				<DialogActions className='btnsfooter'>
					<Button className="undobtn" color="opptyWrapBorder" round onClick={onClose}>
						Undo
					</Button>
					<Button className="withdrawbtn"
						onClick={() => {
							dispatch(withdrawAppliedJob(updateRequest));
							onClose();
						}}
						color="opptyWrap"
						round
					>
						<b>Withdraw</b>
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}
