import {
  container,
  description,
  title,
  cardTitle,
  blackColor,
  whiteColor,
  grayColor,
  opptyDesignColor,
  hexToRgb,
} from "assets/jss/material-kit-pro-react.js";

const pageNotFoundPageStyle = (theme) => ({
  title: {
    ...title,
    fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
    position: "relative",
    marginTop: "25px",
    minHeight: "32px",
    textDecoration: "none",
    color: blackColor + "!important",
    "& svg": {
      width: "32px",
      height: "32px",
      marginRight: "20px",
      marginTop: "10px",
    },
  },
  infoArea: {
    paddingLeft: "5px",
    /* [theme.breakpoints.down("sm")]: {
			display: "none",
		}, */
  },
  leftInfoTitle: {
    color: blackColor,
    fontFamily: `"Roboto Medium", "Helvetica", "Arial", sans-serif`,
    fontSize: "45px",
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      fontSize: "35px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "25px",
      margin: "0",
    },
  },
  leftInfoDescription: {
    color: "#959595",
    fontFamily: `"Roboto Bold", "Helvetica", "Arial", sans-serif`,
    fontSize: "25px",
    // fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },

  body: {
    overflow: "scroll",
    marginTop: "95px",
    height: "calc(100vh - 95px)",
  },

  pageHeader: {
    position: "relative",
    display: "flex !important",
    alignItems: "center",
    margin: "0",
    border: "0",
    minHeight: "100vh",
    height: "auto",
    color: whiteColor,

    "&:before,&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: '""',
    },
  },

  infoDescription: {
    color: grayColor[0],
    fontWeight: 500,
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
    },
  },
  infoImage: {
    width: "75%", //"100%",
    height: "inherit",

    // padding: "0px 50px !important",
  },
  container: {
    ...container,
    zIndex: "5",
    "@media (min-width: 576px)": {
      maxWidth: "576px",
    },
    "@media (min-width: 768px)": {
      maxWidth: "768px",
    },
    // "@media (min-width: 992px)": {
    // 	maxWidth: "992px",
    // },
    "@media (min-width: 960px)": {
      maxWidth: "90vw",
    },
    "@media (min-width: 992px)": {
      maxWidth: "90vw",
    },
    "@media (min-width: 1200px)": {
      maxWidth: "90vw",
    },
  },
  textCenter: {
    textAlign: "center",
  },
  pageNotFoundCard: {
    borderRadius: "18px",
    overflowX: "hidden",
    boxShadow: "none",
    backgroundColor: "#fff",
    marginBottom: "0px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0",
    },
  },
  pageNotFoundCardBody: {
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
    },
  },
});
export default pageNotFoundPageStyle;
