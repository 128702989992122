import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import axios from "axios";

import { Box, makeStyles, Modal, Typography } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";

import Header from "views/Component/Header.js";
import LeftSidebar from "views/Component/LeftSidebar.js";
import EditProfile from "views/Component/ProfileView/EditProfile/EditProfile";
import Footer from "views/Component/Footer.js";
import { encryptData } from "configurations/configurations.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { errorCleanUp } from "configurations/redux/authSlice.js";
import Cookies from "js-cookie";
import {
	hashKey,
	decryptData,
	instanceUrl,
	header,
} from "configurations/configurations.js";
import { getProfiles } from "configurations/redux/userSlice.js";
import {
	createAttachment,
	getAttachment,
} from "configurations/redux/attachmentUserSlice.js";
import resumeHeader from "assets/img/resume-header.png";
import resumeThumbnail from "assets/img/resume-thumbnail.png";
import noResumePic from "assets/img/no-resume.png";
import {
	validateBizPage,
	bizPageInfo,
	deleteActiveInbox,
	resetSelectBizPage,
} from "configurations/redux/businessSlice";
import resumePageStyles from "assets/jss/material-kit-pro-react/views/pageStyle/ResumePage/resumePageStyles";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js";
import RightSideBarUserList from "views/Component/RightSideBarUserList.js";
import ResumeSection from "views/Component/ProfileView/EditProfile/Resume/ResumeSection.js";
import EditModal from "views/Component/ProfileView/EditProfile/EditModal";
import Hidden from "@material-ui/core/Hidden";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import BusinessCardSection from "./BusinessCardSection";
import AddIcon from "@mui/icons-material/Add";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CorporateBasicProfileEditModal from "../CorporateProfilePage/CorporateProfileSection/CorporateBasicProfileEditModal.js";
import { resetCorpProfile } from "configurations/redux/corpUserSlice";
import businessPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/BusinessPage/businessPageStyle";

function useWindowSize() {
	const [size, setSize] = React.useState([0, 0]);
	React.useLayoutEffect(() => {
		function updateSize() {
			setSize([window.innerWidth, window.innerHeight]);
		}
		window.addEventListener("resize", updateSize);
		updateSize();
		return () => window.removeEventListener("resize", updateSize);
	}, []);
	return size;
}

const BusinessPage = ({ match }) => {
	const styles = makeStyles(resumePageStyles)();
	const stylesBusiness = makeStyles(businessPageStyle)();
	const classes = makeStyles(profilePageStyle)(); //profilePageStyle

	const history = useHistory();
	const dispatch = useDispatch();
	var isKicked = Cookies.get("isKicked");
	const isError = useSelector((state) => state.auth.isError);
	const userStatus = useSelector((state) => state.auth.user);

	const searchResult = useSelector((state) => state.business.searchResult);
	const [connStatus, setConnStatus] = React.useState(null);

	const [isEditProfileOpen, setIsEditProfileOpen] = useState(false);

	const [width, height] = useWindowSize();

	const [basicProfileEditModal, setBasicProfileEditModal] = React.useState(
		false
	);
	const [editList, setEditList] = React.useState(false);

	const closeBasicProfileEditModal = () => {
		setBasicProfileEditModal(false);
	};
	const bizId = useSelector((state) => state.business.bizId);


	/* React.useEffect(() => {
		var header = document.getElementById("pageHeader");
		if (isError) {
			if (header) header.style.height = "auto";
		} else {
			if (header && window.innerHeight > 1000 && window.innerWidth > 1900) {
				header.style.height = "100vh";
			}
		}
	}, [isError]); */

	React.useEffect(() => {
		if (isError) {
			dispatch(errorCleanUp());
		}
	}, []);

	React.useEffect(() => {
		const request = {
			email: userStatus.email,
			acctId: userStatus.acctId,
			accessToken: localStorage.getItem("token"),
		};
		dispatch(bizPageInfo(request));
		if (bizId == 0) {
			dispatch(resetCorpProfile());
		}
	}, []);


	return (
		<div>
			<Header color={"transparent"} loggedInStatus={false} />
			<div className={styles.body}>
				<div id="pageHeader" className={styles.pageHeader}>
					<div className={styles.container}>
						<GridContainer justifyContent="center" spacing={1}>
							<Hidden mdDown>
								<GridItem md={2}>
									<LeftSidebar />
								</GridItem>
							</Hidden>

							<GridItem xs={12} sm={12} md={12} lg={8}>
								<Card className={styles.cardFrame}>
									<CardBody>
										<div className={styles.resumeContentHeader}>
											<Typography component="h3">Business Page </Typography>
											<GridContainer alignContent="center" alignItems="center">
												<GridItem className={classes.itemCenter}>
													<Hidden mdUp>
														<Button
															onClick={(event) => {
																if (editList) setEditList(false);
																else setEditList(true);
															}}
															color="opptyNoBackground"
															className={stylesBusiness.buttonInvite}
														>
															<EditOutlinedIcon />
														</Button>
														<Button
															onClick={(event) => {
																event.preventDefault();
																setBasicProfileEditModal(true);
															}}
															color="opptyNoBorder"
															className={stylesBusiness.buttonInvite}
														>
															<AddIcon />
														</Button>
													</Hidden>
													<Hidden smDown>
													<div className="subscription_heading"><Link to={`/subscription-settings/${encryptData(userStatus.acctId)}`}>Subscription Settings </Link></div>
														<Button
															onClick={(event) => {
																if (editList) setEditList(false);
																else setEditList(true);
															}}
															color="opptyNoBackground"
															className={stylesBusiness.buttonInvite}
														>
															<EditOutlinedIcon />
															Corporate Account Settings
														</Button>
														<Button
															onClick={(event) => {
																event.preventDefault();
																setBasicProfileEditModal(true);
															}}
															color="opptyNoBorder"
															className={stylesBusiness.buttonInvite}
														>
															<AddIcon />
															Add New Page
														</Button>
													</Hidden>
												</GridItem>
											</GridContainer>
										</div>
										<div className={styles.resumeContentHeader}>
											<BusinessCardSection editList={editList} />
										</div>
									</CardBody>
								</Card>
							</GridItem>

							<Hidden mdDown>
								<GridItem md={2}>
									<RightSideBarUserList />
								</GridItem>
							</Hidden>
						</GridContainer>
					</div>
					<CorporateBasicProfileEditModal
						open={basicProfileEditModal}
						onClose={closeBasicProfileEditModal}
					/>
				</div>

				<EditModal />

				<Footer />
			</div>
		</div>
	);
};

export default BusinessPage;
