import React from "react";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

import loginPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/LoginPage/loginPageStyle.js";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { encryptData } from "configurations/configurations.js";

import NavPills from "components/NavPills/NavPills.js";

import Header from "views/Component/Header.js";
import CandidateLoginForm from "./CandidateLoginForm.js";
import CorporateLoginForm from "./CorporateLoginForm.js";

import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";

import image from "assets/img/bg7.jpg";
import { errorCleanUp } from "configurations/redux/authSlice.js";
import loginImage from "assets/img/Login_Image.png";
import { Hidden } from "@material-ui/core";
import Cookies from "js-cookie";
import CustomDialog from "../../../components/CustomDialog/CustomDialog.js";
import { isDesktop, isTablet } from "react-device-detect";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { resendVerificationEmail } from "configurations/redux/authSlice.js";
import Footer from "views/Component/Footer.js";
import { gaPageView } from "configurations/configurations.js";
import MetatagHelmet from "../../Component/MetatagHelmet";
import { resetSelectBizPage } from "configurations/redux/businessSlice.js";
const useStyles = makeStyles(loginPageStyle);

const LoginPage = ({ match }) => {
	const history = useHistory();
	const classes = useStyles();
	const dispatch = useDispatch();
	var isKicked = Cookies.get("isKicked");
	const auth = useSelector((state) => state.auth);
	const isError = useSelector((state) => state.auth.isError);
	const isVerified = useSelector((state) => state.auth.isVerified);
	const isResend = useSelector((state) => state.auth.isResend);
	const userStatus = useSelector((state) => state.auth.user);
	const [emailSend, setEmailSend] = React.useState(false);
	const resendEmail = () => {
		const requestProfile = {
			acctTypeId: userStatus.acctTypeId,
			email: userStatus.email,
		};
		console.log(requestProfile);
		dispatch(resendVerificationEmail(requestProfile));
		setEmailSend(true);
	};

	React.useEffect(() => {
		var header = document.getElementById("pageHeader");
		if (isError) {
			if (header) header.style.height = "auto";
		} else {
			if (header && window.innerHeight > 1000 && window.innerWidth > 1900) {
				header.style.height = "100vh";
			}
		}
	}, [isError]);

	React.useEffect(() => {
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;
		dispatch(resetSelectBizPage());
		history.replace(match.url);
	}, []);

	React.useEffect(() => {
		if (isError) {
			dispatch(errorCleanUp());
		}
	}, []);
	// React.useEffect(() => {
	// 	/* const hashId = encryptData(userStatus.acctId); */
	// 	if (userStatus && !isError) {
	// 		history.push(`/search-job-page`);
	// 	}
	// }, []);
	const [cardAnimation, setCardAnimation] = React.useState("cardHidden");
	setTimeout(function () {
		setCardAnimation("");
	}, 700);

	const dialogDefault = {
		title: "Warning",
		description:
			"Your session has expired and auto logged out by system. Thank you for using our system.",
		buttonYes: "",
		buttonNo: "OK",
	};
	const [confirmDialog, setConfirmDialog] = React.useState({
		isOpen: false,
		dialogDefault,
	});
	// React.useEffect(() => {
	// 	return () => {
	// 		setConfirmDialog();
	// 	};
	// }, []);
	const [candidateShow, setCandidateShow] = React.useState(true);
	const [corporateShow, setCorporateShow] = React.useState(false);

	const clickCandidateTab = () => {
		setCandidateShow(true);
		setCorporateShow(false);
	};
	const clickCorporateTab = () => {
		setCorporateShow(true);
		setCandidateShow(false);
	};

	React.useEffect(() => {
		gaPageView(window.location.pathname);
		if (isKicked != undefined && isKicked == 1) {
			Cookies.remove("isKicked");
			setConfirmDialog({
				isOpen: true,
				dialogDefault,
				onCancel: () => {
					setConfirmDialog({
						isOpen: false,
						dialogDefault,
					});
				},
			});
		} else {
			return () => {
				setConfirmDialog();
			};
		}
	}, []);

	/* React.useEffect(() => {
		resizePage();
	}, []);

	window.onresize = function (e) {
		resizePage();
	}; */

	// const resizePage = () => {
	// 	var timer = setInterval(() => {
	// 		// alert(window.innerWidth);
	// 		var login = document.getElementById("loginMainPage");
	// 		if (login) {
	// 			clearInterval(timer);
	// 			// console.log(window.innerWidth, window.innerHeight, login.clientHeight);
	// 			login.style.paddingTop = "5vh";
	// 			if (!isTablet)
	// 				login.style.zoom = window.innerHeight / (login.clientHeight + 120);
	// 			if (window.innerWidth <= 1280 && isDesktop) {
	// 				login.style.paddingTop = "13vh";
	// 				if (window.innerWidth <= 1097) login.style.paddingTop = "20vh";
	// 				if (window.innerWidth <= 960) login.style.paddingTop = "30vh";
	// 			}
	// 		} else {
	// 			setTimeout(() => {
	// 				clearInterval(timer);
	// 			}, 3000);
	// 		}
	// 	}, 50);
	// };

	return (
		<>
			<MetatagHelmet
				metaTag={{
					title: "Login",
					description: "Login",
					url: window.location.href,
				}}
			/>
			<Header color={"transparent"} loggedInStatus={false} />
			<div className={classes.body}>
				<CustomDialog
					confirmDialog={confirmDialog}
					setConfirmDialog={setConfirmDialog}
				/>
				<div
					id="pageHeader"
					className={classes.pageHeader}
					style={{
						backgroundSize: "cover",
						backgroundPosition: "top center",
					}}
				>
					<div className={classes.container} id="loginMainPage">
						<GridContainer
							justifyContent="center"
							alignContent="center"
							alignItems="center"
						>
							<GridItem xs={12} sm={12} md={7}>
								<div className={classes.infoArea} id="infoArea">
									<h1 className={classes.leftInfoTitle}>Welcome Back!</h1>
									<h2 className={classes.leftInfoDescription}>
										{candidateShow
											? "Login to start expanding your network and connections"
											: null}
									</h2>
									<Hidden smDown>
										<img src={loginImage} className={classes.infoImage} />
									</Hidden>
								</div>
							</GridItem>
							<GridItem xs={12} sm={12} md={5} className={classes.loginGrid}>
								<Card className={classes.loginCard}>
									{/* <CardHeader color="success" className={classes.cardHeader}>
									<h4 className={classes.cardTitle}>Begin Your Journey</h4>
								</CardHeader> */}
									<CardBody className={classes.loginCardBody}>
										{/* <h6
										className={classes.description + " " + classes.textCenter}
									>
										Explore Your Dream
									</h6> */}
										{isError &&
											!isVerified &&
											(userStatus === 1 ||
												userStatus === 2 ||
												userStatus === 3) && (
												<SnackbarContent
													message={
														<span>
															<b>{auth.message}</b>
														</span>
													}
													close
													color="opptyWarning"
													icon="info_outline"
													className={
														classes.banner + " " + classes.snackbarMobile
													}
												/>
											)}
										{isError && isVerified && userStatus != null ? (
											userStatus.acctTypeId === 1 ||
											userStatus.acctTypeId === 2 ? (
												<SnackbarContent
													message={
														<div>
															<b>{auth.message}</b>
															<GridContainer justifyContent="center">
																<GridItem xs={12} sm={12} md={12}>
																	<Button
																		fullWidth
																		color="primary"
																		className={classes.buttonBorderResend}
																		onClick={() => {
																			resendEmail();
																		}}
																	>
																		Resend
																	</Button>
																</GridItem>
															</GridContainer>
														</div>
													}
													close
													color="opptyInfo"
													icon="info_outline"
													className={
														classes.warningBanner + " " + classes.snackbarMobile
													}
												/>
											) : null
										) : null}
										{isResend && (
											<SnackbarContent
												message={
													<span>
														<b>
															Verification Email has successfully sent. Please
															check your inbox
														</b>
													</span>
												}
												close
												color="opptyInfo"
												icon="info_outline"
												className={classes.snackbarMobile}
											/>
										)}
										{/* <GridContainer
											justifyContent="center"
											alignContent="center"
											alignItems="center"
										>
											<GridItem
												xs={6}
												sm={6}
												md={6}
												className={classes.gridPaddingRight}
											>
												<Button
													fullWidth
													round
													className={
														candidateShow
															? classes.selectedTabButton
															: classes.tabButton
													}
													onClick={() => {
														clickCandidateTab();
														dispatch(errorCleanUp());
													}}
												>
													<span>
														<b>User Login</b>
													</span>
												</Button>
											</GridItem>
											<GridItem
												xs={6}
												sm={6}
												md={6}
												className={classes.gridPaddingLeft}
											>
												<Button
													fullWidth
													round
													onClick={() => {
														clickCorporateTab();
														dispatch(errorCleanUp());
													}}
													className={
														corporateShow
															? classes.selectedTabButton +
															  " " +
															  classes.selectedTabButtonRight
															: classes.tabButton + " " + classes.tabButtonRight
													}
												>
													<span>
														<b>Corporate Login</b>
													</span>
												</Button>
											</GridItem>
										</GridContainer> */}

										<GridContainer>
											<GridItem>
												<div
													className={classes.tabBorder}
													style={{ borderRadius: "18px 18px 0 0" }}
												>
													{candidateShow && <CandidateLoginForm />}
													{/* {corporateShow && <CorporateLoginForm />} */}
												</div>
											</GridItem>
										</GridContainer>
										<GridContainer>
											<GridItem>
												<div
													className={
														classes.textCenter + " " + classes.tabBottom
													}
												>
													<Link to="/password-recovery-page">
														<span
															className={
																classes.infoTitle + " " + classes.textCenter
															}
														>
															Forgot your password?
														</span>
													</Link>
												</div>
											</GridItem>
										</GridContainer>
									</CardBody>
								</Card>
								<GridContainer justifyContent="center">
									<GridItem xs={12} md={10} sm={9}>
										<h5
											className={
												classes.infoDescription + " " + classes.textCenter
											}
										>
											Don't have an account yet?
											<Link to="/registration-page">
												<span
													className={
														classes.infoTitle + " " + classes.textCenter
													}
												>
													{" "}
													Register Here
												</span>
											</Link>
										</h5>
									</GridItem>
								</GridContainer>
							</GridItem>
						</GridContainer>
					</div>
				</div>
				<Footer />
			</div>
		</>
	);
};

export default LoginPage;
