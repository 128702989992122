import React from "react";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Button, makeStyles, Typography } from "@material-ui/core";

import PopularGroupCard from "../PopularGroups/PopularGroupCard";

import img1 from "assets/img/examples/blog1.jpg"
import img2 from "assets/img/examples/blog2.jpg"
// import img3 from "assets/img/examples/blog3.jpg"
// import img4 from "assets/img/examples/blog4.jpg"
// import img5 from "assets/img/examples/blog5.jpg"

import popularGroupsStyles from "assets/jss/material-kit-pro-react/views/componentStyle/discoverGroups/popularGroupsStyles.js"

const useStyles = makeStyles(popularGroupsStyles);

const PopularGroups = () => {
  const styles = useStyles();

  return (
    <Typography component="div" className={styles.main}>

      <Typography component="div" className={styles.header}>
        <Typography component="div">
          <Typography component="h2" className={styles.mainTitle}>
            Popular Groups
          </Typography>
          <Typography component="p" className={styles.mainDescription}>
            Groups that your friends are in
          </Typography>
        </Typography>

        <Button className={styles.seeAllBtn}>SEE ALL</Button>
      </Typography>

      <GridContainer className={styles.groupCardsContainer}>
        <GridItem md={6} className={styles.groupCard}>
          <PopularGroupCard
            image={img1}
            name={"Dribbble Designer Pro ID"}
            description={"Yogyakarta"}
            members={"2,351"}
          />
        </GridItem>
        <GridItem md={6} className={styles.groupCard}>
          <PopularGroupCard
            image={img2}
            name={"Urban Sneakers Society ID"}
            description={"Yogyakarta"}
            members={"2,351"}
          />
        </GridItem>
        <GridItem md={6} className={styles.groupCard}>
          <PopularGroupCard
            image={img1}
            name={"Dribbble Designer Pro ID"}
            description={"Yogyakarta"}
            members={"2,351"}
          />
        </GridItem>
        <GridItem md={6} className={styles.groupCard}>
          <PopularGroupCard
            image={img2}
            name={"Urban Sneakers Society ID"}
            description={"Yogyakarta"}
            members={"2,351"}
          />
        </GridItem>
        <GridItem md={6} className={styles.groupCard}>
          <PopularGroupCard
            image={img1}
            name={"Dribbble Designer Pro ID"}
            description={"Yogyakarta"}
            members={"2,351"}
          />
        </GridItem>
        <GridItem md={6} className={styles.groupCard}>
          <PopularGroupCard
            image={img2}
            name={"Urban Sneakers Society ID"}
            description={"Yogyakarta"}
            members={"2,351"}
          />
        </GridItem>
        <GridItem md={6} className={styles.groupCard}>
          <PopularGroupCard
            image={img1}
            name={"Dribbble Designer Pro ID"}
            description={"Yogyakarta"}
            members={"2,351"}
          />
        </GridItem>
        <GridItem md={6} className={styles.groupCard}>
          <PopularGroupCard
            image={img2}
            name={"Urban Sneakers Society ID"}
            description={"Yogyakarta"}
            members={"2,351"}
          />
        </GridItem>
        <GridItem md={6} className={styles.groupCard}>
          <PopularGroupCard
            image={img1}
            name={"Dribbble Designer Pro ID"}
            description={"Yogyakarta"}
            members={"2,351"}
          />
        </GridItem>
        <GridItem md={6} className={styles.groupCard}>
          <PopularGroupCard
            image={img2}
            name={"Urban Sneakers Society ID"}
            description={"Yogyakarta"}
            members={"2,351"}
          />
        </GridItem>
      </GridContainer>

    </Typography>
  )
}

export default PopularGroups;