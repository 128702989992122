import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { departmentViewRoadmap, viewRoadmapRoleByRoleId } from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Department_Data_Engineering from "assets/img/Career_Roadmap/Data_Artificial_Intelligence/Department_Data_Engineering_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentDataEngineering() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Data_Engineering;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "de",
				shape: "poly",
				coords: [74,386,67,389,62,393,59,399,57,405,57,444,59,451,62,455,66,459,72,462,168,462,174,460,179,457,183,452,185,446,186,405,183,398,179,392,174,388,167,386,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "163",
				shape: "poly",
				coords: [212,380,207,382,203,385,200,388,198,395,198,448,199,454,201,458,206,461,212,464,391,463,397,460,400,457,403,454,405,448,405,396,404,389,400,385,395,382,389,380,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "164",
				shape: "poly",
				coords: [566,381,561,382,558,385,555,388,553,391,553,397,552,448,554,453,556,457,560,460,564,463,748,463,752,460,756,458,759,454,760,448,760,399,759,390,756,386,752,382,746,380,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "165",
				shape: "poly",
				coords: [923,379,917,381,913,383,910,387,909,394,908,448,909,454,912,458,915,460,921,462,1101,462,1107,460,1110,457,1113,454,1114,448,1115,393,1113,387,1110,383,1106,381,1102,379,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "166",
				shape: "poly",
				coords: [1275,379,1268,381,1266,384,1263,388,1261,392,1262,448,1263,454,1266,457,1270,460,1275,462,1455,461,1461,459,1466,457,1467,453,1469,447,1469,393,1468,387,1464,383,1460,380,1454,379,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "167",
				shape: "poly",
				coords: [1636,380,1631,382,1628,385,1625,389,1624,394,1624,449,1626,454,1627,458,1631,461,1637,464,1818,463,1823,460,1827,457,1830,453,1831,449,1831,395,1830,389,1827,385,1823,382,1818,381,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "de") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo)
			dispatch(viewRoadmapRoleByRoleId(requestInfo));
			
			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// console.log(coords.x + "," + coords.y + ",");
		// navigator.clipboard.writeText(coords.x + "," + coords.y + ",");
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
