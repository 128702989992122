import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import Popper from "@material-ui/core/Popper";
import NotificationResultSection from "../../views/Page/NotificationListing/NotificationResultSection";
import { showNotification } from "../../configurations/redux/eventNotificationSlice.js";

import listNotificationPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/SearchJobPage/searchJobPageStyle.js";
const useStyles = makeStyles(listNotificationPageStyle);

export default function NotificationBadge(props) {
	console.log(props);
	return (
		<React.Fragment>
			<div>
				<Popper
					style={{ zIndex: 9999, width: 345, maxWidth: 345 }}
					id={props.id}
					open={props.open}
					anchorEl={props.anchorEl}
					disablePortal
					anchororigin={{
						vertical: "bottom",
						horizontal: "center",
					}}
					transformorigin={{
						vertical: "top",
						horizontal: "center",
					}}
				>
					<NotificationResultSection />
				</Popper>
			</div>
		</React.Fragment>
	);
}
