import {
	container,
	description,
	title,
	cardTitle,
	blackColor,
	whiteColor,
	grayColor,
	opptyDesignColor,
	hexToRgb,
	facebookColor,
} from "assets/jss/material-kit-pro-react.js";
import customCheckboxRadioSwitch from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.js";
const loginPageStyle = (theme) => ({
	...customCheckboxRadioSwitch,
	description,
	title: {
		...title,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		position: "relative",
		marginTop: "25px",
		minHeight: "32px",
		textDecoration: "none",
		color: blackColor + "!important",
		"& svg": {
			width: "32px",
			height: "32px",
			marginRight: "20px",
			marginTop: "10px",
		},
	},
	cardTitle: {
		...cardTitle,
		color: whiteColor + "  !important",
	},
	infoArea: {
		// paddingTop: "6vh",
		paddingLeft: "30px",
		[theme.breakpoints.down("sm")]: {
			paddingTop: "0",
			paddingLeft: "0",
		},
	},
	leftInfoTitle: {
		color: blackColor,
		fontFamily: `"Roboto Medium", "Helvetica", "Arial", sans-serif`,
		fontSize: "45px", //"60px",
		fontWeight: "500",
		[theme.breakpoints.down("md")]: {
			fontSize: "40px", //"45px",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "35px",
		},
	},
	leftInfoDescription: {
		color: "#959595",
		fontFamily: `"Roboto Medium", "Helvetica", "Arial", sans-serif`,
		fontWeight: "500",
		fontSize: "30px", //"35px",
		// fontWeight: "bold",
		[theme.breakpoints.down("md")]: {
			fontSize: "25px", //"30px",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "20px",
		},
	},
	infoTitle: {
		color: opptyDesignColor,
		fontWeight: "bold",
		textTransform: "uppercase",
		fontSize: "15px",
		[theme.breakpoints.down("xs")]: {
			fontSize: "13px",
		},
	},
	infoDescription: {
		color: grayColor[0],
		fontWeight: 500,
		[theme.breakpoints.down("xs")]: {
			fontSize: "13px",
		},
	},
	infoImage: {
		width: "75%", //"100%",
		height: "inherit",

		// padding: "0px 50px !important",
	},
	banner: {
		borderRadius: "12px",
		backgroundColor: "#fb8082 !important",
	},
	snackbarMobile: {
		"& div": {
			[theme.breakpoints.down("xs")]: {
				fontSize: "12px",
				maxWidth: "100vw",
			},
		},
		"& svg": {
			height: "20px",
			marginLeft: "10px",
		},
	},
	label: {
		cursor: "pointer",
		paddingLeft: "0",
		color: blackColor,
		fontSize: "16px",
		lineHeight: "1.428571429",
		fontWeight: "400",
		display: "inline-flex",
		transition: "0.3s ease all",
		letterSpacing: "unset",
	},
	container: {
		...container,
		zIndex: "5",
		"@media (min-width: 576px)": {
			maxWidth: "576px",
		},
		"@media (min-width: 768px)": {
			maxWidth: "768px",
		},
		// "@media (min-width: 992px)": {
		// 	maxWidth: "992px",
		// },
		"@media (min-width: 960px)": {
			maxWidth: "90vw",
		},
		"@media (min-width: 992px)": {
			maxWidth: "90vw",
		},
		"@media (min-width: 1200px)": {
			maxWidth: "90vw",
		},
	},
	loginCard: {
		// borderRadius: "18px",
		overflowX: "hidden",
		boxShadow: "none",
		backgroundColor: "#f2f3f7",
		marginBottom: "0px",
		[theme.breakpoints.down("sm")]: {
			marginTop: "0",
		},
	},
	loginCardBody: {
		[theme.breakpoints.down("md")]: {
			paddingLeft: 0,
			paddingRight: 0,
			paddingTop: 0,
		},
	},
	passwordCard: {
		borderRadius: "18px",
		overflowX: "hidden",
		boxShadow: "none",
		backgroundColor: "#fff",
		marginBottom: "0px",
	},
	loginGrid: {
		zIndex: "1",
		height: "100%",
		[theme.breakpoints.down("sm")]: {
			// marginTop: "70px",
		},
	},

	body: {
		overflow: "scroll",
		marginTop: "95px",
		height: "calc(100vh - 95px)",
	},

	pageHeader: {
		position: "relative",
		display: "flex !important",
		alignItems: "center",
		margin: "0",
		border: "0",
		minHeight: "100vh",
		height: "auto",
		color: whiteColor,

		"&:before,&:after": {
			position: "absolute",
			zIndex: "1",
			width: "100%",
			height: "100%",
			display: "block",
			left: "0",
			top: "0",
			content: '""',
		},
	},

	form: {
		margin: "0",
	},
	buttonBorder: {
		borderRadius: "6px",
		//height: "55px",
		fontSize: "16px",
		[theme.breakpoints.down("md")]: {
			fontSize: "15px",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "14px",
		},
		"& svg": {
			width: "40px",
			height: "40px",
		},
	},
	buttonBorderResend: {
		borderRadius: "11px",
	},
	cardHeader: {
		width: "auto",
		textAlign: "center",
	},
	socialLine: {
		marginTop: "1rem",
		textAlign: "center",
		padding: "0",
	},
	inputIconsColor: {
		color: grayColor[13],
	},
	textCenter: {
		textAlign: "center",
	},
	iconButtons: {
		marginRight: "3px !important",
		marginLeft: "3px !important",
	},
	block: {
		color: "inherit",
		padding: "0.9375rem",
		fontWeight: "500",
		fontSize: "12px",
		textTransform: "uppercase",
		borderRadius: "3px",
		textDecoration: "none",
		position: "relative",
		display: "block",
	},
	inlineBlock: {
		display: "inline-block",
		padding: "0px",
		width: "auto",
	},
	list: {
		marginBottom: "0",
		padding: "0",
		marginTop: "0",
	},
	left: {
		float: "left!important",
		display: "block",
		"&,& *,& *:hover,& *:focus": {
			color: blackColor + "  !important",
		},
	},
	right: {
		padding: "15px 0",
		margin: "0",
		float: "right",
		"&,& *,& *:hover,& *:focus": {
			color: blackColor + "  !important",
		},
	},
	icon: {
		width: "18px",
		height: "18px",
		top: "3px",
		position: "relative",
	},
	footer: {
		position: "absolute",
		width: "100%",
		background: "transparent",
		bottom: "0",
		color: whiteColor,
		zIndex: "2",
	},
	socialLogin: {
		padding: "10px 10px 0px 10px !important",
	},
	marginAuto: {
		marginRight: "auto !important",
		marginLeft: "auto !important",
	},
	formContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		jusityContent: "center",
		margin: "5% 0",
	},
	gridHeight: {
		height: "90px",
	},
	gridPaddingRight: {
		paddingRight: "0px",
		zIndex: "1",
		marginBottom: "-7px",
		paddingLeft: "14px",
	},
	gridPaddingLeft: {
		paddingLeft: "0px",
		zIndex: "1",
		marginBottom: "-7px",
		paddingRight: "16px",
	},
	selectedTabButton: {
		height: "60px",
		borderRadius: "18px 18px 0px 0px",
		fontSize: "18px",
		color: opptyDesignColor,
		backgroundColor: whiteColor,
		textTransform: "none",
		// border: "#E6E7EC 2px solid",
		boxShadow: "none",
		"&:hover": {
			color: opptyDesignColor,
			backgroundColor: whiteColor,
			/* border: opptyDesignColor + " 1px solid", */
			borderRadius: "18px 18px 0 0",
			boxShadow: "none",
		},
		"&:focus": {
			color: opptyDesignColor,
			backgroundColor: whiteColor,
			boxShadow: "none",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "14px",
		},
	},
	selectedTabButtonRight: {
		borderRadius: "18px 18px 0px 0px",
	},
	tabButton: {
		height: "60px",
		borderRadius: "18px 18px 0px 0px",
		fontSize: "18px",
		color: grayColor[0],
		backgroundColor: "#f2f3f7",
		textTransform: "none",
		boxShadow: "none",
		"&:hover": {
			/* border: opptyDesignColor + " 1px solid", */
			borderRadius: "18px 18px 0 0",
			backgroundColor: "#f2f3f7",
			color: grayColor[0],
			boxShadow: "none",
		},
		"&:focus": {
			backgroundColor: "#f2f3f7",
			color: grayColor[0],
			boxShadow: "none",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "14px",
		},
	},
	tabButtonRight: {
		borderRadius: "18px 18px 0px 0px",
	},
	tabBorder: {
		// border: "2px solid #E6E7EC",
		// borderRadius: "0px 0px 18px 18px",
		padding: "30px 10px 10px 10px",
		background: "#fff",
	},
	tabBottom: {
		// border: "2px solid #E6E7EC",
		borderRadius: "0px 0px 18px 18px",
		padding: "10px 10px 20px 10px",
		background: "#fff",
	},
	loginSocial: {
		textAlign: "center",
		color: "#959595!important",
		fontWeight: "bolder",
		margin: "0px !important",
		[theme.breakpoints.down("xs")]: {
			fontSize: "14px",
		},
	},
	loginSocialGrid: {
		marginBottom: "1.125rem",
	},
	socialMargin: {
		// marginRight: "auto!important",
	},
	pageHeaderHeight: {
		minHeight: "1px",
		height: "auto",
	},
	buttonSend: {
		borderRadius: "50px",
		fontSize: "15px",
		textTransform: "uppercase",
	},
	textFieldLabel: {
		color: "#959595!important",
		fontWeight: "bolder",
		margin: "0 0 5px 0",
		fontSize: "16px",
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
		},
	},
	marginTop: {
		marginTop: "15px",
	},
	loginSocialMargin: {
		margin: "10px 0px !important",
		[theme.breakpoints.down("md")]: {
			margin: "10px 0px !important",
		},
	},
	googleButtonContainer: {
		backgroundColor: "#FFFFFF",
		width: "100%",
		/* height: "50px", */
		display: "flex",
		alignItems: "center",
		borderRadius: "5px",
		transition: "background-color .218s, border-color .218s, box-shadow .218s",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		cursor: "pointer",
		color: "gray",
		fontSize: "16px",
		lineHeight: "45px",
		/* border: "1px solid gray", */
		boxShadow:
			"0 2px 2px 0 rgba(" +
			hexToRgb(grayColor[0]) +
			", 0.14), 0 3px 1px -2px rgba(" +
			hexToRgb(grayColor[0]) +
			", 0.2), 0 1px 5px 0 rgba(" +
			hexToRgb(grayColor[0]) +
			", 0.12)",
		"& svg": {
			background: "#FFFFFF",
			borderRadius: "2px",
			textAlign: "center",
			verticalAlign: "top",
			width: "25px",
			height: "25px",
			marginLeft: "10px",
		},
		"&:hover": {
			boxShadow:
				"0 14px 26px -12px rgba(" +
				hexToRgb(grayColor[0]) +
				", 0.42), 0 4px 23px 0px rgba(" +
				hexToRgb(grayColor[0]) +
				", 0.12), 0 8px 10px -5px rgba(" +
				hexToRgb(grayColor[0]) +
				", 0.2)",
		},
		"  &:active": {
			background: "#ffffff",
		},
	},
	socialButtonText: {
		width: "calc(100% - 48px)",
		display: "inline-block",
		/* 	paddingLeft: "15px", */
		fontWeight: "500",
		textAlign: "center",
	},
	facebookButtonContainer: {
		backgroundColor: "#3b5998",
		width: "100%",

		display: "flex",
		alignItems: "center",
		borderRadius: "5px",
		transition: "background-color .218s, border-color .218s, box-shadow .218s",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		cursor: "pointer",
		color: "#fff",
		fontSize: "16px",
		lineHeight: "45px",

		boxShadow:
			"0 2px 2px 0 rgba(" +
			hexToRgb(facebookColor) +
			", 0.14), 0 3px 1px -2px rgba(" +
			hexToRgb(facebookColor) +
			", 0.2), 0 1px 5px 0 rgba(" +
			hexToRgb(facebookColor) +
			", 0.12)",

		"& svg": {
			borderRadius: "2px",
			textAlign: "center",
			verticalAlign: "top",
			width: "25px !important",
			height: "25px",
			marginLeft: "10px",
			fill: "#FFFFFF",
			stroke: "#FFFFFF",
		},

		"&:hover,&:focus": {
			backgroundColor: facebookColor,
			color: whiteColor,
			boxShadow:
				"0 14px 26px -12px rgba(" +
				hexToRgb(facebookColor) +
				", 0.42), 0 4px 23px 0px rgba(" +
				hexToRgb(blackColor) +
				", 0.12), 0 8px 10px -5px rgba(" +
				hexToRgb(facebookColor) +
				", 0.2)",
		},
	},
});

export default loginPageStyle;
