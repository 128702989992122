import React, { useEffect, useState } from 'react';
import manageGroupPageStyles from 'assets/jss/material-kit-pro-react/views/pageStyle/ManageGroupPage';
import { Typography, makeStyles, Button, Avatar, Box, Tabs, Tab, Collapse } from '@material-ui/core';
import { encryptData, userGroupRoles, decryptData } from 'configurations/configurations';
import { Link } from 'react-router-dom';
import SocialFeedControl from '../../Page/LandingPageFeed/SocialFeedControl';
import { useDispatch } from 'react-redux';
import PostSomethingCard from '../../Component/ProfileView/MyActivity/PostSomethingCard';
import { Skeleton } from '@mui/material';
import { ArrowDownwardOutlined, ArrowUpwardOutlined } from '@material-ui/icons';
import { useModal } from 'hooks';
import Media from './Media';

const useStyles = makeStyles(manageGroupPageStyles);

const GroupMainContent = React.memo(
  ({
    group,
    activeUsers,
    manageGroupClick,
    editProfileClick,
    mediaSeeAllClick,
    isUserManagerOrOwner,
    isUserRoleMember,
    currentUser,
    createPostClick,
    setCreatePostClick,
    groupIsLoading,
    membersIsLoading,
    getMedia,
    getByRecent,
  }) => {
    const styles = useStyles();
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [groupDescCollapsed, setGroupDescCollapsed] = React.useState(false);
    const [createPost, setCreatePost] = React.useState(false);
    const [isGroupMember, setIsGroupMember] = React.useState(false);
    const [postStatus, setPostStatus] = React.useState(isUserManagerOrOwner ? 1 : 0);
    const [mediaIsLoading, setMediaIsLoading] = useState(true);
    const [mediaForMainPage, setMediaForMainPage] = useState([]);
    console.log('main content');
    useEffect(() => {
      if (createPostClick) setCreatePost(createPostClick);
    }, [createPostClick]);

    const onClosePost = () => {
      setCreatePostClick(false);
      setCreatePost(false);
    };

    function a11yProps(index) {
      return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
        value: index,
      };
    }

    const onCollapseClick = () => {
      setGroupDescCollapsed(!groupDescCollapsed);
    };

    const onSeeAnotherPostClick = () => {
      setSelectedTab(1);
    };

    useEffect(() => {
      getMedia().then(res => {
        setMediaIsLoading(false);
        setMediaForMainPage(res);
      });
    }, []);

    useEffect(() => {
      if (selectedTab === 0) {
        getByRecent();
      }
    }, [selectedTab]);

    useEffect(() => {
      if (currentUser && currentUser.groupUserId > 0 && currentUser.statusId == 1) {
        setIsGroupMember(true);
      }
      if (group && group.permissionId && group.permissionId != '0') {
        if (group.permissionId.includes('2')) setPostStatus(1);
        else setPostStatus(0);
      }
      if (isUserManagerOrOwner) setPostStatus(1);
      console.log(currentUser, group, isUserManagerOrOwner);
    }, [currentUser, group]);

    return (
      <>
        <Typography component="div" className={styles.groupBanner}>
          {!groupIsLoading ? (
            <>
              <img src={group?.profilePic} alt="" />

              {isUserManagerOrOwner && (
                <button className={styles.editProfileBtn} onClick={editProfileClick}>
                  Edit Profile
                </button>
              )}
            </>
          ) : (
            <>
              <Skeleton variant="rectangular" height="inherit" />
            </>
          )}
        </Typography>

        <Box className={styles.groupTabsBox}>
          <Tabs
            value={selectedTab}
            onChange={(event, tab) => {
              setSelectedTab(tab);
            }}
            sx={{ borderRight: 1, borderColor: 'divider' }}
            TabIndicatorProps={{ style: { background: '#6F52ED' } }}
          >
            <Tab label="Home" {...a11yProps(0)} />
            <Tab label="Activity" {...a11yProps(1)} />
          </Tabs>
        </Box>

        {selectedTab === 0 && (
          <Typography component="div" className={styles.manageGroupBox}>
            <Box display="flex" justifyContent="space-between">
              {!membersIsLoading ? (
                <>
                  <Typography component="p">
                    Member's
                    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4">
                      <circle id="Oval" cx="2" cy="2" r="2" fill="#d8d8d8" />
                    </svg>
                    <span>{activeUsers?.length}</span>
                  </Typography>

                  {isUserRoleMember && <Button onClick={manageGroupClick}>VIEW GROUP</Button>}
                  {isUserManagerOrOwner && <Button onClick={manageGroupClick}>MANAGE GROUP</Button>}
                </>
              ) : (
                <>
                  <Skeleton variant="text" width="100%" />
                </>
              )}
            </Box>

            <Box display="flex" gridGap="10px" flexWrap="wrap">
              {!membersIsLoading ? (
                <>
                  {activeUsers.slice(0, 10).map(user => (
                    <Link key={user.acctId} to={`/view-profile/${encryptData(user.acctId)}`}>
                      <Avatar src={user.profilePic} style={{ width: '40px' }} />
                    </Link>
                  ))}
                </>
              ) : (
                <>
                  <Skeleton variant="circular" width={40} height={40} />
                  <Skeleton variant="circular" width={40} height={40} />
                  <Skeleton variant="circular" width={40} height={40} />
                  <Skeleton variant="circular" width={40} height={40} />
                  <Skeleton variant="circular" width={40} height={40} />
                </>
              )}
            </Box>

            {!groupIsLoading ? (
              <>
                <Collapse
                  classes={{ root: !groupDescCollapsed && styles.groupCollapseHidden }}
                  collapsedSize="100px"
                  in={groupDescCollapsed}
                >
                  <Typography component="h4" className={styles.aboutGroupTitle}>
                    Group Name
                  </Typography>
                  <Typography component="p" className={styles.aboutGroupText}>
                    {group?.groupName}
                  </Typography>

                  <Typography component="h4" className={styles.aboutGroupTitle}>
                    Description
                  </Typography>
                  <Typography component="p" className={styles.aboutGroupText}>
                    {group?.groupDescription}
                  </Typography>

                  <Typography component="h4" className={styles.aboutGroupTitle}>
                    Rules
                  </Typography>
                  <Typography component="p" className={styles.aboutGroupText}>
                    {group?.groupRule}
                  </Typography>
                </Collapse>
                <Box display="flex" justifyContent="center">
                  <Button
                    onClick={onCollapseClick}
                    endIcon={groupDescCollapsed ? <ArrowUpwardOutlined /> : <ArrowDownwardOutlined />}
                  >
                    {groupDescCollapsed ? 'Show less' : 'Show more'}
                  </Button>
                </Box>
              </>
            ) : (
              <>
                <Skeleton variant="text" width="100%" />
                <Skeleton variant="text" width="100%" />
              </>
            )}
          </Typography>
        )}

        {selectedTab === 0 && (
          <Typography component="div" className={styles.groupMedia}>
            <Typography component="div" className={styles.groupMediaHeader}>
              <Typography component="p">Media</Typography>
              <Button onClick={mediaSeeAllClick}>SEE ALL</Button>
            </Typography>
            <Media mediaList={mediaForMainPage} mediaIsLoading={mediaIsLoading} />
          </Typography>
        )}

        <div>
          {isGroupMember && selectedTab === 1 ? (
            <PostSomethingCard createPost={setCreatePost} />
          ) : (
            <div style={{ paddingBottom: '25px' }}></div>
          )}

          <Typography component="div" className={styles.popularPosts}>
            {selectedTab === 0 && (
              <Typography component="h2" className={styles.popularPostsHeader}>
                Popular Posts
              </Typography>
            )}
            <SocialFeedControl
              match={null}
              hideAddon={true}
              showFeed={true}
              createPost={createPost}
              onClosePost={onClosePost}
              groupId={group?.groupId}
              scrollableTarget="GroupSocialFeed"
              isSearch={selectedTab === 0 ? true : false}
              isInfiniteEnabled={selectedTab === 0 ? false : true}
              isGroupMember={isGroupMember}
              postStatus={postStatus}
              isInGroup={true}
            />
            {selectedTab === 0 && (
              <Box display="flex" justifyContent="center">
                <Button style={{ color: '#0062FF' }} onClick={onSeeAnotherPostClick}>
                  See another posts
                </Button>
              </Box>
            )}
          </Typography>
        </div>
      </>
    );
  }
);

export default GroupMainContent;
