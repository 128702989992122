import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { departmentViewRoadmap, viewRoadmapRoleByRoleId } from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Industry_Real_Estate from "assets/img/Career_Roadmap/Real_Estate/Industry_Real_Estate_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function IndustryRealEstate() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Industry_Real_Estate;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "sl",
				shape: "poly",
				coords: [79,145,71,146,67,148,63,150,60,153,57,157,56,164,56,202,57,208,59,212,62,215,66,219,72,221,166,221,173,219,176,216,179,213,183,208,184,201,184,163,183,158,180,153,175,148,171,146,166,145,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "v",
				shape: "poly",
				coords: [75,269,69,271,65,274,60,278,57,282,55,288,56,328,58,334,60,337,63,341,68,344,74,345,163,345,168,345,173,343,178,340,181,335,184,329,184,286,182,280,178,276,172,272,166,270,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "rc",
				shape: "poly",
				coords: [77,402,70,403,65,405,61,409,59,414,57,420,57,462,59,467,63,472,68,475,75,478,164,478,170,477,175,474,180,470,183,466,185,458,185,418,183,414,181,410,178,406,173,403,167,402,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "pam",
				shape: "poly",
				coords: [78,526,70,526,67,528,62,532,59,536,56,543,56,597,58,602,60,606,63,610,69,614,75,616,167,616,174,614,177,611,182,607,184,602,186,597,185,543,184,538,182,535,178,530,173,527,166,525,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "up",
				shape: "poly",
				coords: [72,663,67,665,62,668,60,673,58,679,57,722,59,727,63,732,68,736,73,738,165,738,171,737,176,735,180,732,183,727,185,719,184,679,183,674,181,670,177,666,172,664,166,662,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "133",
				shape: "poly",
				coords: [218,144,206,146,202,147,199,150,198,154,196,160,196,208,197,216,199,220,203,224,210,227,388,226,394,225,397,223,400,219,402,216,403,208,403,161,402,154,399,150,396,147,393,145,387,144,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "134",
				shape: "poly",
				coords: [569,145,560,146,556,149,552,154,550,160,550,214,553,220,559,225,566,227,747,226,752,223,754,220,756,217,758,212,758,156,756,152,754,149,749,146,744,144,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "135",
				shape: "poly",
				coords: [926,143,916,145,912,149,908,153,907,159,907,213,909,219,912,224,920,227,1100,226,1105,223,1109,221,1112,217,1113,211,1114,159,1113,154,1110,149,1107,147,1103,145,1098,144,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "136",
				shape: "poly",
				coords: [1277,144,1269,147,1265,151,1263,155,1261,161,1261,208,1263,216,1266,222,1271,226,1278,227,1448,227,1457,225,1463,223,1465,219,1468,215,1469,209,1469,158,1468,154,1465,150,1462,148,1459,145,1454,144,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "137",
				shape: "poly",
				coords: [1642,144,1632,145,1628,147,1626,150,1624,154,1622,160,1623,213,1625,219,1629,224,1635,226,1641,227,1814,226,1819,226,1821,223,1826,221,1828,218,1829,211,1830,161,1828,154,1826,151,1824,148,1820,145,1816,144,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "144",
				shape: "poly",
				coords: [211,266,206,268,202,271,198,275,196,281,196,330,196,340,201,344,206,347,212,349,376,349,391,349,396,346,399,344,401,340,404,333,403,282,402,276,400,272,397,270,393,267,388,267,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "145",
				shape: "poly",
				coords: [569,267,559,269,558,269,555,272,552,277,550,282,551,335,554,341,556,345,562,348,567,349,742,350,748,348,753,345,755,341,758,334,758,281,756,274,753,271,749,268,743,266,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "146",
				shape: "poly",
				coords: [924,266,917,267,912,269,909,273,907,279,906,330,907,338,909,342,914,346,920,349,1097,350,1103,347,1107,346,1110,343,1112,339,1113,333,1113,279,1110,272,1106,269,1102,267,1098,267,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "147",
				shape: "poly",
				coords: [1281,266,1273,267,1268,270,1265,273,1263,276,1262,280,1262,336,1263,341,1266,345,1270,348,1276,350,1451,349,1459,348,1462,345,1466,343,1467,339,1469,332,1468,282,1467,276,1466,272,1462,270,1459,267,1455,266,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "148",
				shape: "poly",
				coords: [1637,266,1632,267,1629,270,1625,274,1623,278,1622,283,1622,334,1624,340,1627,344,1630,347,1637,349,1816,349,1820,347,1824,344,1827,340,1829,335,1830,283,1828,275,1825,272,1822,269,1818,267,1812,266,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "149",
				shape: "poly",
				coords: [215,397,206,399,203,401,201,403,198,408,197,412,196,467,198,474,201,478,206,480,211,481,385,482,392,480,396,479,400,475,402,470,403,464,404,415,402,409,401,405,396,402,392,400,387,399,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "150",
				shape: "poly",
				coords: [569,398,562,399,558,401,555,404,553,407,552,411,551,416,550,464,552,472,555,476,559,479,566,482,742,481,747,480,750,478,754,476,757,473,758,467,758,416,758,410,755,406,753,402,750,400,745,398,742,398,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "151",
				shape: "poly",
				coords: [922,398,916,400,913,401,911,404,908,407,906,413,906,464,906,471,909,476,913,479,918,481,1098,481,1104,480,1107,477,1110,475,1112,471,1113,466,1113,416,1112,409,1111,405,1107,402,1103,399,1098,398,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "152",
				shape: "poly",
				coords: [1275,399,1269,401,1266,403,1263,408,1262,414,1262,465,1264,474,1266,476,1268,478,1272,480,1277,481,1450,482,1457,480,1461,479,1464,476,1467,472,1469,464,1468,411,1466,406,1462,402,1456,400,1451,398,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "153",
				shape: "poly",
				coords: [1637,399,1631,400,1629,402,1626,404,1623,409,1622,415,1622,468,1624,474,1627,478,1630,480,1636,482,1812,481,1819,480,1823,478,1826,476,1828,472,1829,466,1830,413,1828,408,1826,405,1823,402,1819,400,1813,398,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "138",
				shape: "poly",
				coords: [214,525,207,526,203,527,200,530,199,532,197,535,195,543,196,595,199,602,202,604,205,606,211,608,389,608,395,606,397,604,400,601,403,596,403,589,403,541,402,534,400,531,396,528,392,526,386,525,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "139",
				shape: "poly",
				coords: [493,524,485,525,482,526,479,528,477,532,475,535,474,540,474,592,475,599,478,602,482,606,489,607,662,608,670,606,674,604,677,602,679,598,681,591,681,538,679,533,676,529,673,526,670,525,666,524,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "140",
				shape: "poly",
				coords: [772,524,764,526,760,527,757,529,755,534,753,539,752,594,756,601,757,604,761,607,766,608,943,608,949,606,953,603,957,600,959,595,960,589,960,544,960,536,957,532,954,529,949,527,944,525,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "141",
				shape: "poly",
				coords: [1044,525,1041,527,1037,529,1034,531,1031,535,1029,541,1029,595,1031,600,1034,604,1038,606,1044,608,1224,608,1229,606,1233,603,1235,601,1237,597,1238,591,1238,539,1236,534,1233,530,1230,527,1225,526,1220,525,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "142",
				shape: "poly",
				coords: [1327,525,1319,527,1314,530,1311,535,1309,542,1309,597,1311,601,1316,606,1323,608,1501,608,1506,607,1511,604,1515,599,1517,591,1516,540,1514,535,1511,530,1508,527,1503,526,1499,525,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "143",
				shape: "poly",
				coords: [1635,525,1630,527,1627,530,1624,533,1622,541,1621,592,1623,598,1626,603,1630,607,1637,608,1811,608,1819,607,1823,605,1826,603,1829,597,1829,592,1830,539,1828,534,1824,530,1818,526,1813,525,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "154",
				shape: "poly",
				coords: [213,660,205,662,201,664,199,668,196,673,196,679,196,725,197,733,200,737,205,741,213,743,381,743,390,742,396,741,399,737,402,732,403,726,404,676,401,668,397,664,393,662,388,660,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "155",
				shape: "poly",
				coords: [492,659,483,661,480,663,477,665,475,668,474,676,473,725,475,731,476,736,481,740,487,742,660,743,670,741,675,739,678,735,681,732,681,726,681,674,679,667,677,663,673,661,666,659,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "156",
				shape: "poly",
				coords: [769,660,761,662,757,665,754,669,752,676,752,728,754,735,757,739,763,742,770,743,946,743,951,741,956,737,958,734,961,728,959,674,957,667,953,663,948,661,940,660,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "157",
				shape: "poly",
				coords: [1051,660,1038,661,1036,663,1034,665,1032,670,1030,676,1030,725,1031,733,1033,736,1035,739,1038,741,1044,744,1222,743,1228,742,1230,740,1234,738,1236,733,1238,727,1238,674,1236,669,1233,665,1230,663,1225,660,1221,660,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "158",
				shape: "poly",
				coords: [1333,660,1322,661,1317,662,1314,665,1311,668,1310,675,1308,723,1309,731,1311,737,1315,740,1321,743,1496,743,1504,742,1508,740,1513,736,1515,728,1515,677,1514,669,1510,664,1503,662,1496,660,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "159",
				shape: "poly",
				coords: [1640,661,1632,662,1627,665,1624,669,1622,675,1622,727,1624,735,1627,739,1632,742,1640,743,1813,744,1820,741,1824,738,1827,734,1829,729,1829,676,1829,670,1825,666,1821,663,1816,661,1811,660,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "sl") {
			dispatch(departmentViewRoadmap(18));
		} else if (area.name === "v") {
			dispatch(departmentViewRoadmap(20));
		} else if (area.name === "rc") {
			dispatch(departmentViewRoadmap(21));
		} else if (area.name === "pam") {
			dispatch(departmentViewRoadmap(19));
		} else if (area.name === "up") {
			dispatch(departmentViewRoadmap(22));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo)
			dispatch(viewRoadmapRoleByRoleId(requestInfo));
			
			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// console.log(coords.x + "," + coords.y + ",");
		// navigator.clipboard.writeText(coords.x + "," + coords.y + ",");
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
