import React from 'react';
import { searchPost, saveParams } from 'configurations/redux/searchPostSlice';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Header from 'views/Component/Header.js';
import FilterComponent from 'views/Page/SearchPage/FilterComponent.js';

import LeftSidebar from 'views/Component/LeftSidebar.js';
import Hidden from '@material-ui/core/Hidden';

import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import searchProfilePageStyle from 'assets/jss/material-kit-pro-react/views/pageStyle/SearchPage/searchProfilePageStyle.js';
import PostComponentInPage from './PostComponentInPage.js';
import SocialFeedControl from 'views/Page/LandingPageFeed/SocialFeedControl.js';
import { getSocialPostByRecent, getSocialPostByPostId } from 'configurations/redux/socialFeedSlice.js';
import { decryptData } from 'configurations/configurations.js';

const useStyles = makeStyles(searchProfilePageStyle);

export default function SearchPostPage({ match }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const searchParams = useSelector(state => state.searchPost.searchParams);
  const userStatus = useSelector(state => state.auth.user);
  const keyword = useSelector(state => state.genericSearch.keyword);
  const auth = useSelector(state => state.auth);

  React.useEffect(() => {
    if (auth.isPublic) {
      const requestProfile = {
        acctId: auth.isPublic ? 0 : userStatus.acctId,
        accessToken: auth.isPublic ? auth.publicToken : localStorage.getItem('token'),
        email: auth.isPublic ? auth.publicEmail : userStatus.email,
        keyword: searchParams.keyword,
        lastRecordSize: 0,
        requestRecordSize: 3,
        hashId: 0,
        userId: 0,
        groupId: 0,
        pageId: 0,
        sortBy: searchParams.sortBy,
        visibleIds: auth.isPublic ? '1' : '1,2',
      };
      dispatch(searchPost(requestProfile));
    } else getByRecent();
  }, []);

  const getByRecent = () => {
    var previousPostListType = localStorage.getItem('postListType');
    const requestProfile = {
      acctId: auth.isPublic ? 0 : userStatus.acctId,
      accessToken: auth.isPublic ? auth.publicToken : localStorage.getItem('token'),
      email: auth.isPublic ? auth.publicEmail : userStatus.email,
      lastRecordSize: 0,
      requestRecordSize: 5,
      hashId: 0,
      userId: 0,
      groupId: 0,
      pageId: 0,
      sortBy: searchParams.sortBy,
      keyword: searchParams.keyword,
      visibleIds: auth.isPublic ? '1' : '1,2',
    };
    console.log(requestProfile);

    dispatch(saveParams(requestProfile));
    dispatch(getSocialPostByRecent(requestProfile));
  };
  const [createPost, setCreatePost] = React.useState(false);
  const onClosePost = () => {
    setCreatePost(false);
  };
  return (
    <div>
      <Header />
      <FilterComponent match={match} />
      <div id="SearchSocialFeed" className={classes.filterBody}>
        <div className={classes.pageHeader} id="searchResultPost">
          <div className={classes.container}>
            <GridContainer spacing={2}>
              <Hidden smDown>
                <GridItem md={3}>{auth.isAuthenticated && <LeftSidebar />}</GridItem>
              </Hidden>
              <GridItem xs={12} sm={12} md={9}>
                <div style={{ paddingTop: '15px' }}></div>
                {auth.isPublic ? (
                  <PostComponentInPage />
                ) : (
                  <SocialFeedControl
                    match={match}
                    hideAddon={true}
                    showFeed={true}
                    createPost={null}
                    onClosePost={onClosePost}
                    groupId={0}
                    scrollableTarget="SearchSocialFeed"
                    isSearch={true}
                    isGroupMember={false}
                    postStatus={1}
                    isInGroup={false}
                    isSearchPage={true}
                  />
                )}
              </GridItem>
              <Hidden mdDown>
                <GridItem md={2}></GridItem>
              </Hidden>
            </GridContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
