import React from "react";
import { ReactComponent as CloseIcon } from "assets/svg/times-solid.svg";
import { ReactComponent as EditIcon } from "assets/svg/edit-solid.svg";
import { ReactComponent as PencilIcon } from "assets/svg/pencil-alt-solid.svg";
import { ReactComponent as BulbIcon } from "assets/svg/lightbulb-on-regular.svg";
import { ReactComponent as CreateSquareIcon } from "assets/svg/plus-square-regular.svg";
import { ReactComponent as LocationIcon } from "assets/svg/map-marker-alt-solid.svg";
import { ReactComponent as LocationOutlinedIcon } from "assets/svg/location-dot-regular.svg";
import { ReactComponent as DollarIcon } from "assets/svg/dollar-sign-solid.svg";
import { ReactComponent as CalendarIcon } from "assets/svg/calendar-solid.svg";
import { ReactComponent as MaleIcon } from "assets/svg/envelope-regular.svg";
import { ReactComponent as CreateIcon } from "assets/svg/plus-solid.svg";
import { ReactComponent as PenIcon } from "assets/svg/pen-regular.svg";
import { ReactComponent as TrashIcon } from "assets/svg/trash-regular.svg";
import { ReactComponent as JobsIcon } from "../../assets/svg/cases_black.svg";
import { ReactComponent as PhoneIcon } from "assets/svg/phone-regular.svg";
import { ReactComponent as NotificationIconOutline } from "assets/svg/notifications_bl.svg";
import { ReactComponent as UserIcon } from "assets/svg/user-regular.svg";
import { ReactComponent as GearIcon } from "assets/svg/gears-light.svg";
import { ReactComponent as GlobeIcon } from "assets/svg/globe-regular.svg";
import { ReactComponent as BuildingIcon } from "assets/svg/buildings-regular.svg";
import { ReactComponent as EyeIcon } from "assets/svg/eye-regular.svg";
import { ReactComponent as SignBoardIcon } from "assets/svg/signs-post-solid.svg";
import { ReactComponent as InboxIcon } from "assets/svg/comment-dots-solid.svg";
import { ReactComponent as NotificationIcon } from "assets/svg/bell-solid.svg";
import { ReactComponent as CandidatesIcon } from "assets/svg/magnifying-glass-solid.svg";
import { ReactComponent as SignInIcon } from "assets/svg/sign-in-alt-solid.svg";
import { ReactComponent as SignOutIcon } from "assets/svg/sign-out-alt-solid.svg";
import { ReactComponent as UploadIcon } from "assets/svg/upload.svg";
import { ReactComponent as InfoIcon } from "assets/svg/circle-info-regular.svg";
import { ReactComponent as HeadSideIcon } from "assets/svg/head-side-brain-solid.svg";
import { ReactComponent as FacebookIcon } from "assets/svg/facebook-square-brands.svg";
import { ReactComponent as InstagramIcon } from "assets/svg/instagram-brands.svg";
import { ReactComponent as LinkedInIcon } from "assets/svg/linkedin-brands.svg";
import { ReactComponent as SyncIcon } from "assets/svg/sync.svg";
import { ReactComponent as GoogleContactIcon } from "assets/svg/google-contact.svg";
import { ReactComponent as MicrosoftContactIcon } from "assets/svg/microsoft-contact.svg";
import { ReactComponent as MailIcon } from "assets/svg/email_black.svg";
import { ReactComponent as HomeIcon } from "assets/svg/home_black.svg";
import { ReactComponent as FileIcon } from "assets/svg/white-page-with-folded-corner.svg";
import { ReactComponent as ThreeDotIcon } from "assets/svg/3-vertical-dots.svg";

const Close = ({ width, height }) => {
	return (
		<CloseIcon
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		/>
	);
};
const ThreeDot = ({ width, height }) => {
	return (
		<ThreeDotIcon
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		/>
	);
};

const Home = ({ width, height }) => {
	return (
		<HomeIcon
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		/>
	);
};
const Edit = ({ width, height }) => {
	return (
		<EditIcon
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		/>
	);
};
const Pencil = ({ width, height }) => {
	return (
		<PencilIcon
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		/>
	);
};
const Lightbulb = ({ width, height }) => {
	return (
		<BulbIcon
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		/>
	);
};
const CreateSquare = ({ width, height }) => {
	return (
		<CreateSquareIcon
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		/>
	);
};
const Location = ({ width, height }) => {
	return (
		<LocationIcon
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		/>
	);
};
const LocationOutlined = ({ width, height }) => {
	return (
		<LocationOutlinedIcon
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		/>
	);
};
const Dollar = ({ width, height }) => {
	return (
		<DollarIcon
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		/>
	);
};
const Calendar = ({ width, height }) => {
	return (
		<CalendarIcon
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		/>
	);
};
const Mail = ({ width, height }) => {
	return (
		<MaleIcon
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		/>
	);
};
const Create = ({ width, height }) => {
	return (
		<CreateIcon
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		/>
	);
};
const Pen = ({ width, height }) => {
	return (
		<PenIcon
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		/>
	);
};
const Trash = ({ width, height }) => {
	return (
		<TrashIcon
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		/>
	);
};
const Phone = ({ width, height }) => {
	return (
		<PhoneIcon
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		/>
	);
};
const User = ({ width, height }) => {
	return (
		<UserIcon
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		/>
	);
};
const Globe = ({ width, height }) => {
	return (
		<GlobeIcon
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		/>
	);
};
const Building = ({ width, height }) => {
	return (
		<BuildingIcon
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		/>
	);
};

const NotificationOutline = ({ width, height }) => {
	return (
		<NotificationIconOutline
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		/>
	);
};
const Gear = ({ width, height }) => {
	return (
		<GearIcon
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		/>
	);
};
const Eye = ({ width, height }) => {
	return (
		<EyeIcon
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		/>
	);
};
const SignBoard = ({ width, height }) => {
	return (
		<SignBoardIcon
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		/>
	);
};
const Inbox = ({ width, height }) => {
	return (
		<InboxIcon
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		/>
	);
};
const Notification = ({ width, height }) => {
	return (
		<NotificationIcon
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		/>
	);
};
const Candidates = ({ width, height }) => {
	return (
		<CandidatesIcon
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		/>
	);
};

const SignIn = ({ width, height }) => {
	return (
		<SignInIcon
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		/>
	);
};
const SignOut = ({ width, height }) => {
	return (
		<SignOutIcon
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		/>
	);
};
const Upload = ({ width, height }) => {
	return (
		<UploadIcon
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		/>
	);
};
const Info = ({ width, height }) => {
	return (
		<InfoIcon
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		/>
	);
};
const HeadSide = ({ width, height }) => {
	return (
		<HeadSideIcon
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		/>
	);
};
const Facebook = ({ width, height }) => {
	return (
		<FacebookIcon
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		/>
	);
};
const LinkedIn = ({ width, height }) => {
	return (
		<LinkedInIcon
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		/>
	);
};
const Instagram = ({ width, height }) => {
	return (
		<InstagramIcon
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		/>
	);
};
const Sync = ({ width, height }) => {
	return (
		<SyncIcon
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		/>
	);
};
const GoogleContact = ({ width, height }) => {
	return (
		<GoogleContactIcon
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		/>
	);
};
const MicrosoftContact = ({ width, height }) => {
	return (
		<MicrosoftContactIcon
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		/>
	);
};

const Jobs = ({ width, height }) => {
	return (
		<JobsIcon
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		/>
	);
};

const MailInbox = ({ width, height }) => {
	return (
		<MailIcon
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		/>
	);
};

export default {
	Close,
	Edit,
	Pencil,
	Lightbulb,
	CreateSquare,
	Location,
	LocationOutlined,
	Dollar,
	Calendar,
	Mail,
	Create,
	Pen,
	Trash,
	Phone,
	User,
	Gear,
	Building,
	Globe,
	Eye,
	SignBoard,
	Inbox,
	Notification,
	Candidates,
	SignIn,
	SignOut,
	Upload,
	Info,
	HeadSide,
	Facebook,
	LinkedIn,
	Instagram,
	Sync,
	GoogleContact,
	MicrosoftContact,
	Jobs,
	MailInbox,
	NotificationOutline,
	Home,
	ThreeDot,
};
