import React from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { decryptData, gaPageView } from 'configurations/configurations.js';
import { getJobPosts } from 'configurations/redux/jobManagementSlice.js';

import axios from 'axios';
import { instanceUrl } from 'configurations/configurations.js';

import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
//Page Components
import Header from 'views/Component/Header.js';
import Footer from 'views/Component/Footer.js';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Parallax from 'components/Parallax/Parallax.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';

import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';

import CreateIcon from '@material-ui/icons/Create';

import corporateProfilePageStyle from 'assets/jss/material-kit-pro-react/views/pageStyle/CorporateProfilePage/corporateProfilePageStyle.js';
import NoActivityStatusModal from '../NoActivityStatusModal/NoActivityStatusModal.js';
import CorporateProfilePictureSection from './CorporateProfileSection/CorporateProfilePictureSection.js';
import CorporateBasicProfileEditModal from './CorporateProfileSection/CorporateBasicProfileEditModal.js';
import CorporateResetPasswordModal from './CorporateProfileSection/CorporateResetPasswordModal.js';
import CorporateBasicProfileSection from './CorporateProfileSection/CorporateBasicProfileSection.js';

import { getCorpProfiles } from 'configurations/redux/corpUserSlice.js';
import CorporateManagerDetailSection from './CorporateProfileSection/CorporateManagerDetailSection.js';
import CorporateTagSection from './CorporateProfileSection/CorporateTagSection.js';
import CorporateJobLinkSection from './CorporateProfileSection/CorporateJobLinkSection.js';
import CorporateFavouriteProfileSection from './CorporateProfileSection/CorporateFavouriteProfileSection.js';
import LoadingModal from '../../Component/LoadingModal.js';
import Backdrop from '../../Component/Backdrop.js';
import LoadingImage from 'assets/img/Loading-pana.png';
import FontIcon from 'views/Component/FontIcon.js';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/CreateOutlined';
import IconLibrary from 'views/Component/IconLibrary.js';
import CoverPictureSection from '../ProfilePage/ProfileSection/CoverPictureSection.js';
import CoverPictureEditModal from '../ProfilePage/ProfileSection/CoverPictureEditModal.js';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';

const useStyles = makeStyles(corporateProfilePageStyle);

const CryptoJS = require('crypto-js');
const publicIp = require('public-ip');

export const CorporateProfilePage = ({ match }) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.corpUser.isLoading);
  const isUpdated = useSelector(state => state.corpUser.isUpdated);
  const userStatus = useSelector(state => state.auth.user);
  const user = useSelector(state => state.corpUser);
  // const buildProfileFlag = useSelector((state) => state.auth.buildProfileFlag);
  const isViewed = useSelector(state => state.corpUser.isViewed);

  const [clientIp, setClientIp] = React.useState();
  const [industryList, setIndustryList] = React.useState([]);
  const [countryList, setCountryList] = React.useState([]);
  const [locationList, setLocationList] = React.useState([]);
  const [changePasswordModal, setChangePasswordModal] = React.useState(false);
  const [isOpen, setOpen] = React.useState(false);
  const closeModal = () => {
    setOpen(false);
  };

  const requestProfile = {
    acctId: decryptData(match.params.acctId),
    bizId: decryptData(match.params.bizId),
    ipAddr: localStorage.getItem('ipAddr'), //clientIp,
    userId: userStatus.acctId,
    accessToken: localStorage.getItem('token'),
  };
  const requestJobProfile = {
    acctId: userStatus.acctId,
    bizId: decryptData(match.params.bizId),
    accessToken: localStorage.getItem('token'),
    lastRecordSize: 0,
    requestRecordSize: 1,
    paginationClick: false,
    statusId: -1,
  };

  const [basicProfileEditModal, setBasicProfileEditModal] = React.useState(false);

  const closeBasicProfileEditModal = () => {
    setBasicProfileEditModal(false);
  };
  const closeChangePasswordModal = () => {
    setChangePasswordModal(false);
  };
  const profileRecord = useSelector(state => state.corpUser.profileRecord);
  const buildProfileFlag = useSelector(state => state.corpUser.buildProfileFlag);

  (async () => {
    // setClientIp(localStorage.getItem("ipAddr"));
  })();

  React.useEffect(() => {
    // console.log(clientIp);
    if (clientIp) {
      // dispatch(getCorpProfiles(requestProfile));
      // dispatch(getJobPosts(requestJobProfile));
    }
  }, [clientIp]);

  React.useEffect(() => {
    gaPageView(window.location.pathname);
    dispatch(getCorpProfiles(requestProfile));
    dispatch(getJobPosts(requestJobProfile));
    if (buildProfileFlag === 0) {
      setBasicProfileEditModal(true);
    }
  }, []);

  return (
    <div>
      <Header />
      <div
      // image={require("assets/img/bg-13.jpg")}
      // filter="dark"
      // className={classes.parallax}
      ></div>
      <CoverPictureSection isViewed={isViewed} isLoading={isLoading} pictureSource={profileRecord} user={user} />
      {/**Modal Section */}
      {isUpdated ? (
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            {!isViewed && (
              <div className={classes.coverIcon}>
                <Tooltip
                  id="tooltip-top"
                  title="Change Cover Photo"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <div className={isOpen ? classes.iconHide : classes.iconHide + ' ' + classes.iconShow}>
                    <Button
                      justIcon
                      round
                      color="oppty"
                      onClick={() => setOpen(true)}
                      className={classes.followButton + ' ' + classes.editButton}
                    >
                      {/* <CreateIcon className={classes.followIcon} /> */}
                      <PhotoCameraIcon className={classes.followIcon} />
                    </Button>
                  </div>
                </Tooltip>
              </div>
            )}
            {isViewed ? null : (
              <CoverPictureEditModal
                open={isOpen}
                onClose={closeModal}
                backgroundPic={user.backgroundPic}
                backgroundPicFileName={user.backgroundPicFileName}
                backgroundTemplateFlag={user.backgroundTemplateFlag}
                user={userStatus}
              />
            )}
            {isLoading ? (
              <GridContainer justifyContent="center">
                <GridItem>
                  <img src={LoadingImage} className={classes.backGroundImage} alt="Expired Session" />
                  <Backdrop />
                </GridItem>
              </GridContainer>
            ) : (
              <React.Fragment>
                <GridContainer justifyContent="center">
                  <GridItem xs={12} sm={12} md={8}>
                    <div className={classes.profile}>
                      <div className={classes.profilePic}>
                        <CorporateProfilePictureSection />
                        {!isViewed && (
                          <div className={classes.edit}>
                            <Tooltip title="Edit Profile" placement="top" classes={{ tooltip: classes.tooltip }}>
                              <Button
                                justIcon
                                round
                                color="oppty"
                                onClick={() => setBasicProfileEditModal(true)}
                                className={classes.editButton}
                              >
                                <CreateIcon className={classes.followIcon} />
                              </Button>
                            </Tooltip>
                          </div>
                        )}
                      </div>
                      <div className={classes.name}>
                        <h3 className={classes.title + ' ' + classes.userTitle}>
                          {profileRecord.companyName}{' '}
                          {profileRecord?.pageName?.length > 0 ? `(${profileRecord.pageName})` : ''}
                        </h3>
                        <h5
                          className={classes.description + ' ' + classes.grayFontCard + ' ' + classes.mediumDescription}
                        >
                          {industryList
                            .filter(item => {
                              return item.industryId === profileRecord.industryId;
                            })
                            .map(item => {
                              return item.industryName;
                            })}
                        </h5>
                        {/* {profileRecord.countryId !== 0 &&
													profileRecord.countryId && (
														<div className={classes.grayFontCard}>
															<FontIcon
																icon={faMapMarkerAlt}
																text={
																	<span className={classes.description}>
																		{profileRecord.countryId &&
																			profileRecord.countryId !== 0 &&
																			countryList
																				.filter((item) => {
																					return (
																						item.countryId ===
																						profileRecord.countryId
																					);
																				})
																				.map((item) => {
																					return item.countryName;
																				})}
																		{profileRecord.locationId !== 0 &&
																			profileRecord.locationId &&
																			" , " +
																				locationList
																					.filter((item) => {
																						return (
																							item.locationId ===
																							profileRecord.locationId
																						);
																					})
																					.map((item) => {
																						return item.locationName;
																					})}
																	</span>
																}
															/>
														</div>
													)} */}
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>
                <CorporateBasicProfileEditModal open={basicProfileEditModal} onClose={closeBasicProfileEditModal} />
                <CorporateResetPasswordModal open={changePasswordModal} onClose={closeChangePasswordModal} />
                <GridContainer justifyContent="center">
                  <GridItem xs={12} sm={12} md={6}>
                    <Card className={classes.cardFrame}>
                      <CardBody>
                        <GridContainer justifyContent="space-between" alignContent="center" alignItems="center">
                          <GridItem xs={9} sm={9} md={9}>
                            <h4 className={classes.title}>About</h4>
                          </GridItem>
                          <GridItem xs={2} sm={2} md={2} lg={2} className={classes.buttonColor}>
                            {isViewed ? null : (
                              <Tooltip
                                title={<p className={classes.tooltipDescription}>Edit Profile</p>}
                                placement="top"
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <Button
                                  onClick={() => setBasicProfileEditModal(true)}
                                  style={{ boxShadow: 'none' }}
                                  color="white"
                                  justIcon
                                  className={classes.buttonIconMargin}
                                >
                                  <IconLibrary.Pen />
                                </Button>
                              </Tooltip>
                            )}
                          </GridItem>
                        </GridContainer>
                        {profileRecord.companyProfile ? (
                          <p className={classes.description}>{profileRecord.companyProfile}</p>
                        ) : (
                          <Card className={classes.cardBorder}>
                            <CardBody>
                              <h5 className={classes.smallDescription}>
                                No information filled yet. Please update Company Profile Records.
                              </h5>
                            </CardBody>
                          </Card>
                        )}
                      </CardBody>
                    </Card>
                    <CorporateBasicProfileSection />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CorporateTagSection />
                    <CorporateManagerDetailSection />
                    {isViewed ? null : (
                      <React.Fragment>
                        <CorporateJobLinkSection />
                        {/* <CorporateFavouriteProfileSection /> */}
                        {userStatus.acctTypeId === 2 ? (
                          <Card className={classes.cardFrame}>
                            <CardBody>
                              <GridContainer justifyContent="space-between" alignContent="center" alignItems="center">
                                <GridItem xs={8} sm={8} md={8}>
                                  <h4 className={classes.title}>Reset Password</h4>
                                </GridItem>
                                <GridItem xs={4} md={4} sm={4}>
                                  <Button
                                    onClick={() => setChangePasswordModal(true)}
                                    fullWidth
                                    round
                                    color="oppty"
                                    className={classes.buttonHeight}
                                  >
                                    <b>Reset</b>
                                  </Button>
                                </GridItem>
                              </GridContainer>
                            </CardBody>
                          </Card>
                        ) : null}
                      </React.Fragment>
                    )}
                  </GridItem>
                </GridContainer>
              </React.Fragment>
            )}
          </div>
        </div>
      ) : (
        <NoActivityStatusModal />
      )}

      <Footer />
    </div>
  );
};

export default CorporateProfilePage;
