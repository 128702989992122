import { blackColor } from "assets/jss/material-kit-pro-react.js";

var regular = 400;
var medium = 500;
var semiBold = 600;
var bold = 700;
/* var Roboto = "'Roboto', 'Helvetica', 'Arial', sans-serif";
var Poppins = "'Poppins', 'Helvetica', 'Arial', sans-serif"; */

const CreatePostModalStyle = (theme) => ({
	BoxStyle: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		overflowX: "hidden",
		borderRadius: "15px",
		backgroundColor: "#fff",
		padding: "15px 20px",
		width: "60%",
		minHeight: "401px",
		maxHeight: "92%",
		"&:focus-visible": {
			outline: "none",
		},
	},

	closeIcon_Infotitle: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		borderRadius: "0.5rem",
		width: "100%",
	},

	InfoTitle: {
		margin: 0,
		color: blackColor,
		fontSize: "14px",
		fontWeight: medium,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
	},

	closeIcon: {
		width: "1rem",
		cursor: "pointer",
	},

	separator: {
		margin: "12px 0 15px 0%",
		borderColor: "#DCDCDF",
		width: "100%",
	},

	postSection: {
		marginTop: ".5rem",
		height: "auto",
	},

	profileSection: {
		display: "flex",
	},

	profileImg: {
		marginRight: ".5rem",
		borderRadius: "55%",
		width: "55px",
		height: "55px",
	},

	profileInfo: {
		display: "flex",
		flexDirection: "column",
	},

	profileName: {
		color: "#171725",
		fontSize: "14px",
		fontWeight: medium,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
	},

	visibility: {
		display: "flex",
		fontSize: "14px",
		[theme.breakpoints.down("sm")]: {
			display: "flex",
			flexDirection: "column",
		},
	},

	postInput: {
		// maxHeight: "11rem",
		// overflowY: "auto",
	},

	inputfield: {
		// overflow: "hidden",
		// overflowY: "auto",
		marginTop: "15px",

		"& textarea": {
			border: "none",
			height: "100%",
		},
	},

	input: {
		height: "10rem",
		fontSize: 14,
		fontWeight: regular,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,

		"& > div:nth-child(1)": {
			lineHeight: "1.3",

			"& strong": {
				position: "relative",
				zIndex: 1,
				top: 0,
				left: 1,
				backgroundColor: "#fff",
				color: "#7474a8",
			},

			"& .textarea": {
				zIndex: 0,
			},
		},

		"& > div:nth-child(2)": {
			boxShadow:
				"0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
			marginTop: "20px !important",
			borderRadius: "10px",
			border: "1px solid rgba(0,0,0,0.15)",
			backgroundColor: "white",
			padding: 5,

			"& li": {
				display: "flex",
				alignItems: "center",
				margin: 0,
				borderRadius: "5px",
				padding: "5px 10px 5px",
				color: "#44444F",
				cursor: "pointer",

				"&:hover": {
					backgroundColor: "#d7d7d7",
				},

				"&focused": {
					backgroundColor: "#cee4e5",
				},

				"& > img": {
					borderRadius: "50%",
					width: "20px",
				},

				"& > p": {
					margin: "0 0 0 7px",
				},
			},
		},
	},

	uploadedImages: {
		position: "absolute",
		zIndex: "10",
		top: "0",
		left: "50%",
		transform: "translate(-50%, 0)",
		border: "solid 1px grey",
		borderRadius: "10px",
		backgroundColor: "white",
		width: "500px",
	},

	uploadedImagesTitle: {
		padding: "10px 0",
		color: "#171725",
		fontSize: "14px",
		fontWeight: bold,
		textAlign: "center",
		verticalAlign: "middle",

		"& > img": {
			float: "right",
			marginTop: "3px",
			marginRight: "13px",
			cursor: "pointer",
		},
	},

	previewImage: {
		backgroundColor: "#efefef",
		width: "100%",
		height: "400px",
		textAlign: "center",

		"& > img": {
			height: "100%",
			maxHeight: "400px",
		},
	},

	attachmentList: {
		overflow: "auto",
		display: "flex",
		flexWrap: "wrap",
		padding: "10px 5px",
		height: "155px",
		[theme.breakpoints.down("sm")]: {
			padding: "0",
			height: "75px",
		},
	},
	attachmentListItem: {
		position: "relative",
		margin: "6px 8px",
		width: "100px",
		height: "120px",
		[theme.breakpoints.down("sm")]: {
			width: "50px",
			height: "60px",
			margin: "8px 0 0 0",
		},
		"& > div": {
			overflow: "hidden",
			marginTop: "-24px",
			width: "100%",
			height: "100%",
		},
	},
	removeAttachment: {
		position: "relative",
		zIndex: "2",
		top: "-9px",
		right: "-87px",
		width: "20px",
		height: "auto",
		cursor: "pointer",
		[theme.breakpoints.down("sm")]: {
			right: "-40px",
		},
	},
	attachmentListItemAttachmentContainerOverlay: {
		position: "absolute",
		top: "25px",
		right: 0,
		bottom: 0,
		left: 0,
		cursor: "pointer",
	},
	attachmentListItemAttachmentContainer: {
		display: "inline-block",
	},
	attachmentListItemContent: {
		width: "100%",
		height: "100%",
		objectFit: "cover",
		objectPosition: "center",
		border: "1px solid #92929D",
		borderRadius: "15px",
	},

	createPostFooter: {
		display: "flex",
		justifyContent: "space-between",
		marginTop: "1rem",
		// ["@media (max-width:1000px)"]: {
		// 	display: "flex",
		// 	flexDirection: "column",
		// 	justifyContent: "center",
		// 	alignItems: "center",
		// },
		[theme.breakpoints.down("xs")]: {
			marginTop: "5px",
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
		},
	},

	createPostFooterLeft: {
		display: "flex",
	},

	postTypeOptionContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		margin: "0 15px",
		cursor: "pointer",

		"& label": {
			height: "22px",
			textAlign: "center",
			cursor: "inherit",
		},

		"& svg": {
			width: "25px",
			height: "20px",
		},
	},

	postTypeOption: {},

	imageText: {
		margin: 0,
		color: "#6F52ED",
		fontSize: "14px",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		textAlign: "center",
	},

	createPostFooterRight: {
		display: "flex",
		alignItems: "center",
		[theme.breakpoints.down("sm")]: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
		},
		[theme.breakpoints.down("xs")]: {
			marginTop: "50px",
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
		},
	},

	commentability: {
		display: "flex",
		marginBottom: "5px",
		fontSize: "14px",
		fontWeight: regular,
	},

	visibleto: {
		color: "#4A4A4A",
		marginRight: ".5rem",
		marginLeft: "10px",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
	},

	Select: {
		overflow: "hidden",
		height: "1.7rem",
		borderRadius: ".5rem",
		// paddingTop: "19px",
		width: "190px",
		"& .MuiSelect-select:focus": {
			background: "none",
		},

		"& .MuiSelect-selectMenu": {
			padding: "5px 32px 5px 14px",

			"& > div": {
				display: "none !important",
			},
		},
	},

	MenuItem: {
		display: "block",
		paddingRight: "40px",
	},
	menuItemImg: {
		marginRight: "8px",
		width: "18px",
		height: "18px",
	},
	mainmenutext: {
		fontSize: ".875rem",
		fontWeight: regular,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
	},
	mainmenusubtext: {
		marginLeft: "26px",
		fontSize: ".75rem",
		fontWeight: regular,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		lineHeight: 1,
	},

	footerCommentSection: {
		display: "grid",
	},

	sharePostBtn: {
		marginLeft: "2rem",
		borderRadius: "10px",
		// backgroundColor: "#6F52ED",
		width: "145px",
		height: "40px",
		fontWeight: medium,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		textTransform: "capitalize",
		[theme.breakpoints.down("sm")]: { marginLeft: 0 },
	},

	pollSectionInPost: {
		width: "99%",
		background: "#F8FAFF",
		borderRadius: ".625rem",
		marginBottom: "1rem",
	},
	editanddelete: {
		display: "flex",
		justifyContent: "right",
		marginRight: "1rem",
		paddingTop: "1rem",
		textTransform: "uppercase",
		cursor: "pointer",

		"& > :nth-child(1)": {
			"& img": {
				width: "20x",
				height: "20px",
			},
		},

		"& > :nth-child(2)": {
			"& img": {
				width: "15x",
				height: "15px",
			},
		},
	},

	edit: {
		fontSize: ".875rem",
		fontWeight: regular,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		color: "#6F52ED",
		margin: "0rem 1.1rem 0rem 5px",
	},
	delete: {
		fontSize: ".875rem",
		fontWeight: regular,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		color: "#E42B4D",
		margin: "0rem 1.1rem 0rem 5px",
	},
	QuestionandOptions: {
		paddingBottom: "1rem",
	},
	Question: {
		display: "flex",
		justifyContent: "center",
		fontSize: "1.125rem",
		fontWeight: medium,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		margin: "1rem 0rem",
		"& > span": {
			overflowWrap: "anywhere",
			textAlign: "center",
			padding: "0 20px",
		},
	},

	Options: {
		display: "flex",
		justifyContent: "center",
		width: "75%",
		height: "auto",
		minHeight: "2.625rem",
		borderRadius: ".5rem",
		backgroundColor: "#FFFFFF",
		border: "1px solid #979797",
		fontSize: "1rem",
		fontWeight: medium,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		margin: "1rem 12.5%",
		padding: ".5rem",
		"& > span": {
			overflowWrap: "anywhere",
			textAlign: "center",
			padding: "0 20px",
		},
	},
	pollDetails: {
		margin: "0rem 0rem 1rem 12.5%",

		"& > :nth-child(3)": {
			textTransform: "capitalize",
		},
	},
	daysLeft: {
		fontSize: ".875rem",
		fontWeight: regular,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		marginLeft: ".5rem",
		marginRight: ".5rem",
	},
	viewandresult: {
		color: "#6F52ED",
		fontSize: ".875rem",
		fontWeight: medium,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
	},
	modalHeader: {
		padding: "0 24px",
	},
	dialogStyle: {
		maxWidth: "1250px!important",
		// width: "60%!important",
		minHeight: "401px",
		maxHeight: "92%",
	},
	sharePost: {
		border: "1px solid #DCDCDF",
		borderRadius: "15px",
	},
	shareLink: {
		border: "1px solid #DCDCDF",
		borderRadius: "15px",
		padding: "10px",
		width: "40%",
		[theme.breakpoints.down("sm")]: { width: "100%" },
	},
});

export default CreatePostModalStyle;
