import React, { Fragment } from "react";
// import ReactDOM from "react-dom";
// import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import "assets/scss/material-kit-pro-react.scss?v=1.9.0";

// import AlphaLoginPage from "alpha_views/AlphaLoginPage.js";
// import AlphaRegistrationPage from "alpha_views/AlphaRegistrationPage.js";
// import AlphaProfilePage from "alpha_views/AlphaProfilePage.js";
// import AlphaJobSearchPortalPage from "alpha_views/AlphaJobSearchPortalPage.js";
// import AlphaJobManagementPage from "alpha_views/AlphaJobManagementPage.js";
// import AlphaJobViewPage from "alpha_views/AlphaJobViewPage.js";
// import AlphaJobCreatePage from "alpha_views/AlphaJobCreatePage.js";
// import AlphaMessageDashboardPage from "alpha_views/AlphaMessageDashboardPage.js";
// import AlphaLandingPage from "alpha_views/AlphaLandingPage.js";
// import AlphaTermsConditionsPage from "alpha_views/AlphaTermsConditionsPage.js";
// import AlphaCandidateSearchPortalPage from "alpha_views/AlphaCandidateSearchPortalPage.js";
// import AlphaHeaderTest from "alpha_views/AlphaHeaderTest.js";
// import AlphaJobStatusPage from "alpha_views/AlphaJobStatusPage.js";
// import AlphaCorporateProfilePage from "alpha_views/AlphaCorporateProfilePage.js";
// import AlphaPasswordRecoveryPage from "alpha_views/AlphaPasswordRecoveryPage.js";
// import AlphaErrorPage from "alpha_views/AlphaErrorPage.js";

//actual deployment files
import AccountVerificationPage from "views/Page/AccountVerificationPage/AccountVerificationPage.js";
import CommunityDetailPage from "views/Page/CommunityPage/CommunityDetailPage.js";
import ConnectionPage from "views/Page/ConnectionPage/ConnectionPage";
import CorporateJobCreatePage from "views/Page/CorporateJobPage/CorporateJobCreatePage.js";
import CorporateJobManagementPage from "views/Page/CorporateJobPage/CorporateJobManagementPage.js";
import CorporateJobViewPage from "views/Page/CorporateJobPage/CorporateJobViewPage.js";
import CorporateProfilePage from "views/Page/CorporateProfilePage/CorporateProfilePage.js";
import CorporateDashboardPage from "views/Page/CorporateDashboardPage/CorporateDashboardPage.js";
import DiscoverGroupsPage from "views/Page/DiscoverGroupsPage";
import FavProfilePage from "views/Page/FavouriteProfilePage.js/FavProfilePage.js";
import FollowingPage from "views/Page/FollowPage/FollowPage.js";
import JobStatusPage from "views/Page/JobStatusPage/JobStatusPage.js";
import LandingPage from "views/Page/LandingPage/LandingPage.js";
import LoginPage from "./views/Page/LoginPage/LoginPage.js";
import MaintenancePage from "views/Page/MaintenancePage/MaintenancePage.js";
import ManageGroupPage from "views/Page/ManageGroupPage";
import MessageDashboardPage from "views/Page/MessagePage/MessageDashboardPage.js";
import NotificationPage from "views/Page/NotificationPage/NotificationPage.js";
import Resources from "views/Page/OpptyRoadMapPage/Resources.js";
import PageNotFoundPage from "views/Page/PageNotFoundPage/PageNotFoundPage.js";
import PasswordRecoveryPage from "views/Page/PasswordRecoveryPage/PasswordRecoveryPage.js";
import PasswordResetPage from "views/Page/PasswordRecoveryPage/PasswordResetPage.js";
import ProfilePage from "views/Page/ProfilePage/ProfilePage.js";
import ProfileViewPage from "views/Page/ProfileViewPage/ProfileViewPage";
import JobApplicationLanding from "views/Page/JobStatusPage/JobApplicationLanding";
import ApplicationManagement from "views/Page/ApplicationManagement.js";
import SignUpTest from "views/Page/SignUpTest.js";
import TakeTest from "views/Page/TakeTest.js";
import RedirectCallBackPage from "views/Page/RedirectCallBackPage.js";
import RegistrationPage from "views/Page/RegistrationPage/RegistrationPage.js";
import ResumePage from "views/Page/ResumePage/ResumePage";
import SearchCommunityPage from "views/Page/CommunityPage/SearchCommunityPage.js";
import SearchJobPages from "views/Page/SearchJobPage/SearchJobPage.js";
import SearchProfilePage from "views/Page/SearchProfilePage/SearchProfilePage.js";
import TermsConditionsPage from "views/Page/TermsConditionsPage.js";
import TestimonialsPage from "./views/Page/TestimonialsPage/TestimonialsPage";
import UploadAttachmentsPage from "./views/Page/UploadAttachmentsPage/UploadLanding";
import UserDownloadAttachment from "./views/Page/UploadAttachmentsPage/UserDownloadAttachment";
import GroupsPage from "views/Page/GroupsPage";

import AuthRoute from "./views/Page/AuthRoute";
import SearchPage from "./views/Page/SearchPage/SearchPage.js";
import SearchPeoplePage from "./views/Page/SearchPage/SearchPeoplePage/SearchPeoplePage.js";
import SearchJobPage from "./views/Page/SearchPage/SearchJobPage/SearchJobPage.js";
import SearchPostPage from "./views/Page/SearchPage/SearchPostPage/SearchPostPage";
import SearchArticlePage from "./views/Page/SearchPage/SearchArticlePage/SearchArticlePage.js";

import MyGroupPage from "views/Page/GroupsPage/MyGroupComponent.js";
import BrowseGroupPage from "views/Page/GroupsPage/BrowseGroupComponent.js";

import history from "./history.js";
import { Search } from "@material-ui/icons";

import "../src/assets/css/custom.css";

// social landing feed pages  added
import LandingPageFeed from "views/Page/LandingPageFeed/LandingPageFeed.js";
import UserDashboard from "views/Page/LandingPageFeed/UserDashboard.js";

import ForTestPurposePage from "./views/Page/ForTestPurposePage";
import Header from "views/Component/Header.js";
import BusinessPage from "views/Page/BusinessPage/BusinessPage.js";
import SubscriptionSettingsPage from "views/Page/BusinessPage/SubscriptionSettingsPage.js";
import PaymentGateway from "views/Page/BusinessPage/PaymentGateway.js";
import Psychometric from "views/Page/PsychometricPage/Psychometric.js";
import ResultView from "views/Page/PsychometricPage/ResultView.js";
import PsychometricTestLanding from "views/Page/PsychometricPage/PsychometricTestLanding.js";
import { Helmet } from "react-helmet";
import ChatBotRobot from "views/Page/ChatbotPage/ChatBotRobot.js";
import ChatBotInterface from "views/Page/ChatbotPage/ChatBotInterface.js";

const App = () => {
	const authenticated = useSelector((state) => state.auth.isAuthenticated);
	const user = useSelector((state) => state.auth.user);
	const profileBuildFlag = useSelector((state) => state.auth.buildProfileFlag);
	const isError = useSelector((state) => state.auth.isError);
	const bizId = useSelector((state) => state.business.bizId);
	// console.log(profileBuildFlag);

	return (
		<Fragment>
			<Helmet>
				<title>
					OppTy - The Smart Career Planning Eco-System | Career Development
				</title>
				<meta
					name="description"
					content="The Smart Career Planning Eco-System | Career Development"
				/>
			</Helmet>
			<Router history={history}>
				<Switch>
					{/* <Route path="/alpha-login-page" component={AlphaLoginPage} />
				<Route
					path="/alpha-password-recovery-page"
					component={AlphaPasswordRecoveryPage}
				/>
				<Route
					path="/alpha-registration-page"
					component={AlphaRegistrationPage}
				/>
				<Route path="/alpha-profile-page" component={AlphaProfilePage} />
				<Route
					path="/alpha-job-management-page"
					component={AlphaJobManagementPage}
				/>
				<Route path="/alpha-job-view-page" component={AlphaJobViewPage} />
				<Route
					path="/alpha-job-search-portal-page"
					component={AlphaJobSearchPortalPage}
				/>
				<Route
					path="/alpha-message-dashboard"
					component={AlphaMessageDashboardPage}
				/>
				<Route
					path="/alpha-terms-conditions-page"
					component={AlphaTermsConditionsPage}
				/>
				<Route
					path="/alpha-candidate-search-page"
					component={AlphaCandidateSearchPortalPage}
				/>
				<Route
					path="/alpha-corporate-profile-page"
					component={AlphaCorporateProfilePage}
				/>
				<Route path="/alpha-error-page" component={AlphaErrorPage} />
				<Route path="/alpha-job-status-page" component={AlphaJobStatusPage} />
				<Route path="/alpha-header-test" component={AlphaHeaderTest} />
				<Route path="/alpha-landing-page" component={AlphaLandingPage} />
				<Route path="/alpha-job-create-page" component={AlphaJobCreatePage} /> */}
					{/* <Route path="/alpha-error-page" component={AlphaErrorPage} /> */}
					{/**Actual Dev */}

					<Route path="/registration-page" component={RegistrationPage} />
					<Route path="/login-page" component={LoginPage} />
					<Route path="/sign-up-test" component={SignUpTest} />
					<Route path="/take-test" component={TakeTest} />
					<Route path="/maintenance-page" component={MaintenancePage} />
					<Route path="/test-page" component={LandingPage} />
					{user && !isError ? (
						<AuthRoute
							user={user}
							pageType={0}
							bizId={bizId}
							path="/login-page"
							authenticated={authenticated}
							render={(props) => <LoginPage {...props} />}
						/>
					) : (
						<Route path="/login-page" component={LoginPage} />
					)}

					<Route
						exact
						path="/AccountVerification/:AccountEmail/:AccountTypeId/:verificationCode"
						component={AccountVerificationPage}
					/>
					<Route
						exact
						path="/PasswordRecovery/:AccountEmail/:AccountTypeId/:verificationCode"
						component={PasswordResetPage}
					/>
					<Route
						path="/password-recovery-page"
						component={PasswordRecoveryPage}
					/>
					<Route path="/test" component={ForTestPurposePage} />
					{/* path="/social-feed" component={LandingPageFeed}  */}

					<AuthRoute
						user={user}
						pageType={0}
						bizId={bizId}
						/* profileBuildFlag={profileBuildFlag} */
						authenticated={authenticated}
						path="/profile-page/:acctId"
						render={(props) => <ProfilePage {...props} />}
					/>
					<AuthRoute
						user={user}
						pageType={0}
						bizId={bizId}
						/* profileBuildFlag={profileBuildFlag} */
						authenticated={authenticated}
						path="/corporate-profile-page/:acctId/:bizId"
						render={(props) => <CorporateProfilePage {...props} />}
					/>
					<AuthRoute
						user={user}
						pageType={0}
						bizId={bizId}
						/* profileBuildFlag={profileBuildFlag} */
						authenticated={authenticated}
						path="/corporate-dashboard-page/:acctId/:bizId"
						render={(props) => <CorporateDashboardPage {...props} />}
					/>
					<AuthRoute
						user={user}
						pageType={2}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/corporate-job-create-page"
						render={(props) => <CorporateJobCreatePage {...props} />}
					/>
					<AuthRoute
						user={user}
						pageType={2}
						bizId={bizId}
						authenticated={authenticated}
						profileBuildFlag={profileBuildFlag}
						path="/corporate-job-management-page"
						render={(props) => <CorporateJobManagementPage {...props} />}
					/>
					<AuthRoute
						user={user}
						pageType={2}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/corporate-job-view-page/:jobPostId"
						render={(props) => <CorporateJobViewPage {...props} />}
					/>
					<AuthRoute
						user={user}
						pageType={2}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/search-profile-page"
						render={(props) => <SearchProfilePage {...props} />}
					/>
					<AuthRoute
						user={user}
						pageType={1}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/search-job-page/:jobPostId"
						render={(props) => <SearchJobPages {...props} />}
					/>
					<AuthRoute
						user={user}
						pageType={1}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/search-job-page"
						render={(props) => <SearchJobPages {...props} />}
					/>

					<AuthRoute
						user={user}
						pageType={1}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/social-feed/:type/:id"
						render={(props) => <LandingPageFeed {...props} />}
					/>

					<AuthRoute
						user={user}
						pageType={1}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/social-feed"
						render={(props) => <LandingPageFeed {...props} />}
					/>

					<AuthRoute
						user={user}
						pageType={0}
						bizId={bizId}
						/* profileBuildFlag={profileBuildFlag} */
						authenticated={authenticated}
						path="/view-profile/:acctId"
						render={(props) => <ProfileViewPage {...props} />}
					/>

					<AuthRoute
						user={user}
						pageType={0}
						bizId={bizId}
						/* profileBuildFlag={profileBuildFlag} */
						authenticated={authenticated}
						path="/view-user-profile/:acctId/:bizId/"
						render={(props) => <ProfileViewPage {...props} />}
					/>

					<AuthRoute
						user={user}
						pageType={2}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/application-job-landing/:jobPostId"
						render={(props) => <ApplicationManagement {...props} />}
					/>
					<AuthRoute
						user={user}
						pageType={2}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/corporate-job-view-page/:jobPostId"
						render={(props) => <CorporateJobViewPage {...props} />}
					/>

					<AuthRoute
						user={user}
						pageType={0}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/application-job-landing/:acctId"
						render={(props) => <ApplicationManagement {...props} />}
					/>

					<AuthRoute
						user={user}
						pageType={2}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/favourite-profile-page"
						render={(props) => <FavProfilePage {...props} />}
					/>
					<AuthRoute
						user={user}
						pageType={1}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/job-status-page"
						render={(props) => <JobStatusPage {...props} />}
					/>
					<AuthRoute
						user={user}
						pageType={0}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/message-page/:inboxId/:recId"
						render={(props) => <MessageDashboardPage {...props} />}
					/>
					<AuthRoute
						user={user}
						pageType={0}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/notification-page/:recId"
						render={(props) => <NotificationPage {...props} />}
					/>
					<AuthRoute
						user={user}
						pageType={0}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/resources"
						render={(props) => <Resources {...props} />}
					/>
					<AuthRoute
						user={user}
						pageType={0}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/community-detail-page/:articleId"
						render={(props) => <CommunityDetailPage {...props} />}
					/>
					{/* <Route path="/oppty-road-map-page" component={OpptyRoadMapPage} /> */}
					<Route
						path="/terms-conditions-page"
						component={TermsConditionsPage}
					/>
					<Route path="/page-not-found-page" component={PageNotFoundPage} />
					{/* <Route path="/connection" component={ConnectionPage} /> */}
					<Route
						path="/redirect-callback-page/:account"
						component={RedirectCallBackPage}
					/>
					<Route
						path="/public-search-page/:type/:id"
						render={(props) => <SearchPage {...props} />}
					/>
					<Route
						path="/public-search-page"
						render={(props) => <SearchPage {...props} />}
					/>
					<Route
						path="/public-search-page-people"
						render={(props) => <SearchPeoplePage {...props} />}
					/>
					<Route
						path="/public-search-page-job"
						render={(props) => <SearchJobPage {...props} />}
					/>
					<Route
						path="/public-search-page-post"
						render={(props) => <SearchPostPage {...props} />}
					/>
					<Route
						path="/public-community-detail-page/:articleId"
						render={(props) => <CommunityDetailPage {...props} />}
					/>

					<Route
						path="/user-download-attachment/:recId"
						render={(props) => <UserDownloadAttachment {...props} />}
					/>



					<AuthRoute
						user={user}
						pageType={0}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/manage-connection/:acctId"
						render={(props) => <ConnectionPage {...props} />}
					/>
					<AuthRoute
						user={user}
						pageType={0}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/groups/:acctId"
						render={(props) => <GroupsPage {...props} />}
					/>
					<AuthRoute
						user={user}
						pageType={0}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/following-page/:acctId"
						render={(props) => <FollowingPage {...props} />}
					/>
					<AuthRoute
						user={user}
						pageType={0}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/resume/:acctId"
						render={(props) => <ResumePage {...props} />}
					/>
					<AuthRoute
						user={user}
						pageType={0}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/biz-page/:acctId"
						render={(props) => <BusinessPage {...props} />}
					/>
					<AuthRoute
						user={user}
						pageType={0}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/subscription-settings/:acctId"
						render={(props) => <SubscriptionSettingsPage {...props} />}
					/>
					<AuthRoute
						user={user}
						pageType={0}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/payment-gateway/:acctId"
						render={(props) => <PaymentGateway {...props} />}
					/>
					<AuthRoute
						user={user}
						pageType={0}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/psychometric-test/:acctId"
						render={(props) => <Psychometric {...props} />}
					/>
					<AuthRoute
						user={user}
						pageType={0}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/psychometric-result-view/:acctId"
						render={(props) => <ResultView {...props} />}
					/>
					<AuthRoute
						user={user}
						pageType={0}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/psychometric-test-landing/:acctId"
						render={(props) => <PsychometricTestLanding {...props} />}
					/>

					<AuthRoute
						user={user}
						pageType={0}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/chatbot-landing/:acctId/:bizId/:applId"
						render={(props) => <ChatBotRobot {...props} />}
					/>

					<AuthRoute
						user={user}
						pageType={0}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/chatbot-interface/:acctId/:bizId/:applId"
						render={(props) => <ChatBotInterface {...props} />}
					/>

					<AuthRoute
						user={user}
						pageType={0}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/testimonials/:acctId"
						render={(props) => <TestimonialsPage {...props} />}
					/>

					<AuthRoute
						user={user}
						pageType={0}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/upload-attachment/:type/:acctId"
						render={(props) => <UploadAttachmentsPage {...props} />}
					/>

					<AuthRoute
						user={user}
						pageType={1}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/user-dashboard/:acctId"
						render={(props) => <UserDashboard {...props} />}
					/>
					<AuthRoute
						user={user}
						pageType={0}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/job-application-landing/:acctId"
						render={(props) => <JobApplicationLanding {...props} />}
					/>

					<AuthRoute
						user={user}
						pageType={0}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/discover-groups/:acctId"
						render={(props) => <DiscoverGroupsPage {...props} />}
					/>
					<AuthRoute
						user={user}
						pageType={0}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/manage-group/:groupId/:postId"
						render={(props) => <ManageGroupPage {...props} />}
					/>
					<AuthRoute
						user={user}
						pageType={0}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/manage-group/:groupId"
						render={(props) => <ManageGroupPage {...props} />}
					/>
					<AuthRoute
						user={user}
						pageType={0}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/search-page/:type/:id"
						render={(props) => <SearchPage {...props} />}
					/>
					<AuthRoute
						user={user}
						pageType={0}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/search-page"
						render={(props) => <SearchPage {...props} />}
					/>
					<AuthRoute
						user={user}
						pageType={0}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/search-page-people"
						render={(props) => <SearchPeoplePage {...props} />}
					/>
					<AuthRoute
						user={user}
						pageType={0}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/search-page-job"
						render={(props) => <SearchJobPage {...props} />}
					/>
					<AuthRoute
						user={user}
						pageType={0}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/search-page-post"
						render={(props) => <SearchPostPage {...props} />}
					/>
					<AuthRoute
						user={user}
						pageType={0}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/search-page-article"
						render={(props) => <SearchArticlePage {...props} />}
					/>
					<AuthRoute
						user={user}
						pageType={0}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/my-groups"
						render={(props) => <MyGroupPage {...props} />}
					/>
					<AuthRoute
						user={user}
						pageType={0}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/browse-groups/:keyword"
						render={(props) => <BrowseGroupPage {...props} />}
					/>
					<AuthRoute
						user={user}
						pageType={0}
						bizId={bizId}
						profileBuildFlag={profileBuildFlag}
						authenticated={authenticated}
						path="/browse-groups"
						render={(props) => <BrowseGroupPage {...props} />}
					/>
					{/* <Route path="/" component={LoginPage} /> */}
					{user && !isError ? (
						<AuthRoute
							user={user}
							pageType={0}
							bizId={bizId}
							path="/"
							authenticated={authenticated}
							render={(props) => <LoginPage {...props} />}
						/>
					) : (
						<Route path="/" component={LoginPage} />
					)}
				</Switch>
			</Router>
		</Fragment>
	);
};

export default App;
