import { createSlice } from '@reduxjs/toolkit';

import axios from 'axios';
import { instanceUrl, header, headerFormData, emptyCache, encryptData } from 'configurations/configurations.js';

const socialFeedSlice = createSlice({
  name: 'socialFeed',
  initialState: {
    acctId: null,
    acctStatusId: null,
    acctTypeId: null,
    hashId: null,
    hashtagName: null,
    createdDate: null,
    hashtagCount: 0,
    followId: null,
    saveId: null,
    postId: null,
    postTypeId: null,
    description: null,
    visibleId: null,
    recordId: null,
    typeOptionId: null,
    typeOption: null,
    poolOptionId: null,
    optionDesc: null,
    poolResultId: null,
    pollResultUserList: [],
    commentPermissionId: null,
    hashTags: [],
    profileTags: [],
    poolId: null,
    question: null,
    poolOptionList: [],
    attachments: [],
    status: 1,
    lastUpdateDate: null,
    profileList: [],
    profileTagList: [],
    hashtagList: [],
    authorId: null,
    hideId: null,
    hidePostList: [],
    searchResult: [],
    searchResult1: [],
    searchParams: [],
    lastRecordSize: 0,
    requestRecordSize: 0,
    sortBy: 0,
    datePosted: 0,
    postedBy: '',
    postShareUserList: [],
    shareId: null,
    parentId: null,
    commentId: null,
    reactionId: null,
    likeId: null,
    commentList: [],
    replyList: [],
    socialReactionCount: [],
    socialReactionUserList: [],
    commentReactionCount: [],
    commentReactionUserList: [],
    isLoading: false,
    isEnd: false,
    isSearching: false,
    isUpdated: true,
    isFirstLoading: false,
    searchResultComment: [],
    lastRecordSizeComment: 0,
    requestRecordSizeComment: 0,
    isLoadingComment: false,
    isLoadingReply: false,
    isEndComment: false,
    isUpdatedComment: true,
    comment: null,
    isCreating: false,
    isOnAmend: false,
    isCreatingReply: false,
    isOnAmendReply: false,
    amendPostId: 0,
    amendCommentId: 0,
    commentLikeId: null,
    isDeletedComment: false,
    deletedCommentId: 0,
    hashValue: null,
    createHashtagCommentId: null,
    enterKey: false,
    lastOpenCommentPostId: null,
    lastOpenCommentId: null,
    isChosenOption: false,
    isLoadingPoll: false,
    isEndPoll: false,
    lastRecordSizePoll: 0,
    requestRecordSizePoll: 0,
    reactionUserList: [],
    isLoadingReaction: false,
    isEndgReaction: false,
    lastRecordSizeReaction: 0,
    requestRecordSizeReaction: 0,
    reactionCount: [],
    postResult: [],
    isLoadingPost: false,
    popupResult: [],
    isLoadingSocialPost: false,
    post: null,
    isDeletedPost: false,
    isUpdatedPost: false,
    isCreatedPost: false,
    deletedPostId: 0,
    updatedPostId: 0,
    createdPostId: 0,
    pollOptionPostId: 0,
    hashtagMatch: null,
    isLoadingHashtagMatch: false,
    hashId: 0,
    userId: 0,
    groupId: 0,
    pageId: 0,
    followProfileId: null,
    keyword: '',
    postStatus: 1,
  },
  reducers: {
    POST_CREATING_DEFAULT: (state, action) => {
      state.isLoadingSocialPost = false;
      state.post = null;
      state.isCreatedPost = false;
      state.isDeletedPost = false;
      state.isUpdatedPost = false;
      state.createdPostId = 0;
      state.deletedPostId = 0;
      state.updatedPostId = 0;
      state.postStatus = 1;
    },
    POST_LOADING_CREATE: (state, action) => {
      state.isLoadingSocialPost = true;
    },
    POST_CREATE_SUCCESS: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      state.isLoadingSocialPost = false;
      state.post = action.payload.post;
      state.isCreatedPost = true;
      state.createdPostId = action.payload.postId;
      state.postStatus = action.payload.postStatus;
    },
    POST_DELETE_SUCCESS: (state, action) => {
      state.isLoadingSocialPost = false;
      state.isDeletedPost = true;
      state.deletedPostId = action.payload.postId;
      var newPostResult = state.postResult.filter((item, index) => {
        return item.postId != action.payload.postId;
      });
      state.postResult = [...newPostResult];
      var newSearchResult = state.searchResult.filter((item, index) => {
        return item.postId != action.payload.postId;
      });
      state.searchResult = [...newSearchResult];
    },
    POST_UPDATE_SUCCESS: (state, action) => {
      state.isLoadingSocialPost = false;
      state.isUpdatedPost = true;
      state.post = action.payload.post;
      state.updatedPostId = action.payload.postId;
      state.postStatus = action.payload.postStatus;
    },
    POST_CREATE_FAIL: (state, action) => {
      return state;
    },
    POST_LOADING: (state, action) => {
      state.isLoading = true;
    },
    POST_FITST_LOADING: (state, action) => {
      state.isFirstLoading = true;
    },
    POST_GET_LOADING: (state, action) => {
      state.isLoadingPost = true;
    },
    POST_GET_SUCCESS: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      state.postResult = [...action.payload.searchResult];
      state.isLoadingPost = false;
      state.groupId = action.payload.groupId;
    },
    POST_GET_RESET: (state, action) => {
      state.postResult = [];
      state.isLoadingPost = false;
    },
    POST_SEARCH_SUCCESS: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      state.searchResult = [...state.searchResult, ...action.payload.searchResult];
      state.searchResult1 = [...state.searchResult, ...action.payload.searchResult];
      state.searchParams = action.payload.searchParams;
      state.lastRecordSize = state.lastRecordSize + action.payload.requestRecordSize;
      state.requestRecordSize = action.payload.requestRecordSize;
      state.sortBy = action.payload.sortBy;
      state.datePosted = action.payload.datePosted;
      state.postedBy = action.payload.postedBy;
      state.hashId = action.payload.hashId;
      state.userId = action.payload.userId;
      state.groupId = action.payload.groupId;
      state.pageId = action.payload.pageId;
      state.keyword = action.payload.keyword;
      // state.lastOpenCommentPostId = action.payload.lastPostId;
      state.isLoading = false;
      state.isFirstLoading = false;
      if (state.postResult && state.postResult.length > 0) {
        var duplicate = state.searchResult.filter((item, index) => {
          return item.postId == state.postResult[0].postId;
        });
        if (duplicate.length > 0) {
          var newResult = state.searchResult.filter((item, index) => {
            return item.postId != state.postResult[0].postId;
          });
          state.searchResult = [...newResult];
        }
      }
      // var newResult = state.searchResult.filter((item, index) => {
      // 	return state.searchResult.indexOf(item) === index;
      // });
      // state.searchResult = [...newResult];
      var seen = {};
      var newResult = state.searchResult.filter(function (item) {
        var k = JSON.stringify(item.postId);
        return seen.hasOwnProperty(k) ? false : (seen[k] = true);
      });
      state.searchResult = [...newResult];
    },
    POST_AMEND_LOADING: (state, action) => {
      state.amendPostId = action.payload.postId;
      state.amendCommentId = action.payload.parentId;
      if (action.payload.parentId == 0) state.isOnAmend = true;
      else if (action.payload.parentId > 0) state.isOnAmendReply = true;
    },
    POST_AMEND_SUCCESS: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      // console.log(action.payload.postId, action.payload.postList);
      var a = action.payload.postList;
      // console.log(action.payload.searchResult[0]);
      a = a.map(function (item) {
        return item.postId == action.payload.postId ? action.payload.searchResult[0] : item;
      });
      state.searchResult = [...a];
      state.isOnAmend = false;
      state.isOnAmendReply = false;
    },
    POST_SEARCH_END: (state, action) => {
      state.isEnd = true;
    },
    POST_SEARCH_FAIL: (state, action) => {
      return state;
    },
    POST_COMMENT_RESET: (state, action) => {
      state.lastOpenCommentPostId = action.payload.postId;
      state.lastOpenCommentId = null;
    },
    COMMENT_RESET: (state, action) => {
      state.lastOpenCommentId = action.payload.commentId;
      state.lastOpenCommentPostId = null;
    },
    POST_CLICK_RESET: (state, action) => {
      state.lastOpenCommentId = null;
      state.lastOpenCommentPostId = null;
    },
    DEFAULT_POST_SEARCH: (state, action) => {
      state.searchResult = [];
      state.searchParams = [];
      state.lastRecordSize = 0;
      state.requestRecordSize = 0;
      state.sortBy = 0;
      state.datePosted = 0;
      state.postedBy = '';
      state.lastOpenCommentPostId = null;
      state.lastOpenCommentId = null;
      state.isEnd = false;
      state.isSearching = true;
      state.poolOptionId = null;
      state.poolResultId = null;
      state.poolOptionList = [];
      state.isChosenOption = false;
      state.popupResult = [];
      state.hashId = 0;
      state.userId = 0;
      if (action.payload == 1) state.groupId = 0;
      state.pageId = 0;
      state.keyword = '';
    },
    COMMENT_SEARCH_FAIL: (state, action) => {
      state.isUpdateComment = false;
      return state;
    },
    COMMENT_LOADING: (state, action) => {
      state.isLoadingComment = true;
    },
    REPLY_LOADING: (state, action) => {
      state.isLoadingReply = true;
    },
    COMMENT_SEARCH_SUCCESS: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      // state.searchResultComment = [
      // 	...state.searchResultComment,
      // 	...action.payload.searchResult,
      // ];
      // console.log(action.payload.postId, action.payload.postList);
      // var originalPostIndex = action.payload.postList.findIndex(
      // 	(x) => x.postId == action.payload.postId
      // );
      var originalPost = action.payload.postList.filter(x => x.postId == action.payload.postId);
      var newComment = null;
      var newReply = null;
      var isLoadMoreComment = action.payload.isLoadMoreComment;
      var isCreateComment = action.payload.isCreateComment;
      var isDeleteComment = action.payload.isDeleteComment;
      var isCopyPost = action.payload.isCopyPost;
      var originalCommentId = action.payload.originalCommentId;
      var checkCommentId = action.payload.commentId;
      var newSearchResult = action.payload.searchResult;
      var firstCommentId = checkCommentId;
      var continueGoDown = true;
      var newCommentCount = null;
      var newActualCommentCount = null;
      // console.log(newSearchResult);
      // console.log(isCopyPost);
      // console.log(checkCommentId);
      if (action.payload.commentId > 0) {
        let x = JSON.parse(JSON.stringify(originalPost[0].comments.commentList));
        // console.log(x);
        for (var a = 0; a < x.length; a++) {
          var reply_1 = x[a];
          // if (reply_1.replyCount > 0) {
          // console.log(reply_1.commentId, checkCommentId);
          if (reply_1.commentId == checkCommentId) {
            continueGoDown = false;
            firstCommentId = checkCommentId;
            // console.log("1", reply_1);
            if (isLoadMoreComment) reply_1.replyList.commentList.push(...newSearchResult);
            else {
              if (isDeleteComment) {
                reply_1.replyCount -= 1;
                reply_1.replyList.commentList = reply_1.replyList.commentList.filter((item, index) => {
                  return item.commentId == checkCommentId;
                });
              } else if (isCreateComment) {
                reply_1.replyCount += 1;
                reply_1.replyList.commentList.unshift(...newSearchResult);
              } else {
                console.log(newSearchResult);
                var commentId = newSearchResult[0].commentId;
                // console.log(commentId);
                for (var i = 0; i < reply_1.replyList.commentList.length; i++) {
                  var oldCommentId = reply_1.replyList.commentList[i].commentId;
                  // console.log(commentId, oldCommentId);
                  if (commentId == oldCommentId) {
                    reply_1.replyList.commentList[i] = {
                      ...newSearchResult[0],
                    };
                  }
                }
              }
            }
            newReply = reply_1.replyList.commentList.map(function (item) {
              return item.commentId == checkCommentId ? reply_1 : item;
            });
          } else if (continueGoDown) {
            firstCommentId = reply_1.commentId;
            // console.log(firstCommentId);
            for (var b = 0; b < reply_1.replyList.commentList.length; b++) {
              var reply_2 = reply_1.replyList.commentList[b];
              // if (reply_2.replyCount > 0) {
              // console.log(reply_2.commentId, checkCommentId);
              if (reply_2.commentId == checkCommentId) {
                continueGoDown = false;
                // console.log("2", reply_2);
                if (isLoadMoreComment) reply_2.replyList.commentList.push(...newSearchResult);
                else {
                  if (isDeleteComment) {
                    reply_2.replyCount -= 1;
                    reply_2.replyList.commentList = reply_2.replyList.commentList.filter((item, index) => {
                      return item.commentId == checkCommentId;
                    });
                  } else if (isCreateComment) {
                    reply_2.replyCount += 1;
                    reply_2.replyList.commentList.unshift(...newSearchResult);
                  } else {
                    var commentId = newSearchResult[0].commentId;
                    // console.log(commentId);
                    for (var i = 0; i < reply_2.replyList.commentList.length; i++) {
                      // console.log(newSearchResult[0]);
                      var oldCommentId = reply_2.replyList.commentList[i].commentId;
                      if (commentId == oldCommentId) {
                        reply_2.replyList.commentList[i] = {
                          ...newSearchResult[0],
                        };
                      }
                    }
                  }
                }
                newReply = reply_1.replyList.commentList.map(function (item) {
                  return item.commentId == checkCommentId ? reply_2 : item;
                });
              } else if (continueGoDown) {
                for (var c = 0; c < reply_2.replyList.commentList.length; c++) {
                  var reply_3 = reply_2.replyList.commentList[c];
                  // if (reply_3.replyCount > 0) {
                  // console.log(reply_3.commentId, checkCommentId);
                  if (reply_3.commentId == checkCommentId) {
                    continueGoDown = false;
                    // console.log("3", reply_3);
                    if (isLoadMoreComment) reply_3.replyList.commentList.push(...newSearchResult);
                    else {
                      if (isDeleteComment) {
                        reply_3.replyCount -= 1;
                        reply_3.replyList.commentList = reply_3.replyList.commentList.filter((item, index) => {
                          return item.commentId == checkCommentId;
                        });
                      } else if (isCreateComment) {
                        reply_3.replyCount += 1;
                        reply_3.replyList.commentList.unshift(...newSearchResult);
                      } else {
                        var commentId = newSearchResult[0].commentId;
                        // console.log(commentId);
                        for (var i = 0; i < reply_3.replyList.commentList.length; i++) {
                          var oldCommentId = reply_3.replyList.commentList[i].commentId;
                          if (commentId == oldCommentId) {
                            reply_3.replyList.commentList[i] = {
                              ...newSearchResult[0],
                            };
                          }
                        }
                      }
                    }
                    newReply = reply_2.replyList.commentList.map(function (item) {
                      return item.commentId == checkCommentId ? reply_3 : item;
                    });
                    newReply = reply_1.replyList.commentList.map(function (item) {
                      return item.commentId == reply_1.commentId ? newReply : item;
                    });
                  } else if (continueGoDown) {
                    for (var d = 0; d < reply_3.replyList.commentList.length; d++) {
                      var reply_4 = reply_3.replyList.commentList[d];
                      // if (reply_4.replyCount > 0) {
                      // console.log(reply_4.commentId, checkCommentId);
                      if (reply_4.commentId == checkCommentId) {
                        continueGoDown = false;
                        // console.log("4", reply_4);
                        if (isLoadMoreComment) reply_4.replyList.commentList.push(...newSearchResult);
                        else {
                          if (isDeleteComment) {
                            reply_4.replyCount -= 1;
                            reply_4.replyList.commentList = reply_4.replyList.commentList.filter((item, index) => {
                              return item.commentId == checkCommentId;
                            });
                          } else if (isCreateComment) {
                            reply_4.replyCount += 1;
                            reply_4.replyList.commentList.unshift(...newSearchResult);
                          } else {
                            var commentId = newSearchResult[0].commentId;
                            // console.log(commentId);
                            for (var i = 0; i < reply_4.replyList.commentList.length; i++) {
                              var oldCommentId = reply_4.replyList.commentList[i].commentId;
                              if (commentId == oldCommentId) {
                                reply_4.replyList.commentList[i] = {
                                  ...newSearchResult[0],
                                };
                              }
                            }
                          }
                        }
                        newReply = reply_3.replyList.commentList.map(function (item) {
                          return item.commentId == reply_3.commentId ? reply_4 : item;
                        });
                        newReply = reply_2.replyList.commentList.map(function (item) {
                          return item.commentId == reply_2.commentId ? newReply : item;
                        });
                        newReply = reply_1.replyList.commentList.map(function (item) {
                          return item.commentId == reply_1.commentId ? newReply : item;
                        });
                      }
                      // }
                    }
                  }
                  // }
                }
              }
              // }
            }
          }
          // }
        }
        // if (newReply == null) newReply = action.payload.searchResult;
        // }

        var originalComment = originalPost[0].comments.commentList.filter(x => x.commentId == firstCommentId);

        state.isLoadingReply = false;

        // console.log(newReply);
        // console.log(firstCommentId);
        var comment = {
          commentId: originalComment[0].commentId,
          parentId: originalComment[0].parentId,
          postId: originalComment[0].postId,
          description: originalComment[0].description,
          acctId: originalComment[0].acctId,
          status: originalComment[0].status,
          lastUpdateDate: originalComment[0].lastUpdateDate,
          reactionCount: originalComment[0].reactionCount,
          profilePic: originalComment[0].profilePic,
          email: originalComment[0].email,
          surname: originalComment[0].surname,
          firstName: originalComment[0].firstName,
          middleName: originalComment[0].middleName,
          headline: originalComment[0].headline,
          countryId: originalComment[0].countryId,
          countryName: originalComment[0].countryName,
          replyCount: newReply ? newReply.length : 0, //originalComment[0].replyCount,
          profileTags: {
            profileTagList: originalComment[0].profileTags ? originalComment[0].profileTags.profileTagList : [],
          },
          hashTags: {
            hashtagList: originalComment[0].hashTags ? originalComment[0].hashTags.hashtagList : [],
          },
          attachments: {
            attachmentList: originalComment[0].attachments ? originalComment[0].attachments.attachmentList : [],
          },
          ownerLike: {
            commentReactionUserList: originalComment[0].ownerLike
              ? originalComment[0].ownerLike.commentReactionUserList
              : [],
          },
          replyList: { commentList: newReply },
        };
        // console.log(comment);
        newComment = originalPost[0].comments.commentList.map(function (item) {
          return item.commentId == firstCommentId ? comment : item;
        });
      } else {
        if (isLoadMoreComment) {
          newComment = originalPost[0].comments.commentList.concat(action.payload.searchResult);
          console.log(newComment);
          newComment = newComment.filter(
            (value, index, self) => index === self.findIndex(t => t.commentId == value.commentId)
          );
          console.log(newComment);
          newCommentCount = newComment.length;
          newActualCommentCount = newComment.length;
        } else if (isCreateComment) {
          newComment = [...action.payload.searchResult, ...originalPost[0].comments.commentList];
          newCommentCount = newComment.length;
          newActualCommentCount = newComment.length;
        } else if (isDeleteComment) {
          newComment = originalPost[0].comments.commentList.filter((item, index) => {
            return item.commentId != originalCommentId;
          });
          newCommentCount = newComment.length;
          newActualCommentCount = newComment.length;
        } else {
          let x = JSON.parse(JSON.stringify(originalPost[0].comments.commentList));
          var commentId = action.payload.searchResult[0].commentId;
          // console.log(commentId);
          for (var i = 0; i < x.length; i++) {
            var oldCommentId = x[i].commentId;
            if (commentId == oldCommentId) {
              x[i] = {
                ...action.payload.searchResult[0],
              };
            }
          }
          newComment = x;
          newCommentCount = newComment.length;
          newActualCommentCount = newComment.length;
        }
      }
      if (!newCommentCount) newCommentCount = originalPost[0].commentCount;
      if (!newActualCommentCount) newActualCommentCount = originalPost[0].actualCommentCount;
      // console.log(newCommentCount);
      // console.log(newActualCommentCount);
      // console.log(newComment);
      var newPost = {
        postId: originalPost[0].postId,
        acctId: originalPost[0].acctId,
        commentCount: newCommentCount,
        actualCommentCount: newActualCommentCount,
        comments: { commentList: newComment },
        countryId: originalPost[0].countryId,
        countryName: originalPost[0].countryName,
        cpDescription: originalPost[0].cpDescription,
        cpId: originalPost[0].cpId,
        cpTitle: originalPost[0].cpTitle,
        cpType: originalPost[0].cpType,
        cpTypeId: originalPost[0].cpTypeId,
        createdDate: originalPost[0].createdDate,
        description: originalPost[0].description,
        firstName: originalPost[0].firstName,
        followStatus: originalPost[0].followStatus,
        hashTags: {
          hashtagList: originalPost[0].hashTags ? originalPost[0].hashTags.hashtagList : [],
        },
        headline: originalPost[0].headline,
        middleName: originalPost[0].middleName,
        ownerViewCount: originalPost[0].ownerViewCount,
        profilePic: originalPost[0].profilePic,
        profileTags: {
          profileTagList: originalPost[0].profileTags ? originalPost[0].profileTags.profileTagList : [],
        },
        postTypes: originalPost[0].postTypes ? originalPost[0].postTypes : null,
        reactionCount: originalPost[0].reactionCount,
        recordId: originalPost[0].recordId,
        saveCount: originalPost[0].saveCount,
        shareCount: originalPost[0].shareCount,
        sharedId: originalPost[0].sharedId,
        sharedPost: originalPost[0].sharedPost,
        status: originalPost[0].status,
        surname: originalPost[0].surname,
        typeOption: originalPost[0].typeOption,
        typeOptionId: originalPost[0].typeOptionId,
        viewCount: originalPost[0].viewCount,
        visibleDescription: originalPost[0].visibleDescription,
        visibleId: originalPost[0].visibleId,
        visibleTitle: originalPost[0].visibleTitle,
        ownerLike: {
          socialReactionUserList: originalPost[0].ownerLike ? originalPost[0].ownerLike.socialReactionUserList : [],
        },
      };
      var a = action.payload.postList;
      // console.log(newPost);
      // console.log(action.payload.searchResult, originalPost, originalPostIndex);
      a = a.map(function (item) {
        return item.postId == action.payload.postId ? newPost : item;
      });
      // console.log(a);
      // console.log(isCopyPost);
      if (isCopyPost) state.postResult = [...a];
      else state.searchResult = [...a];

      state.lastRecordSizeComment = state.lastRecordSizeComment + action.payload.requestRecordSize;
      state.requestRecordSizeComment = action.payload.requestRecordSize;
      state.isLoadingComment = false;
      state.isEndComment = false;
      state.popupResult = newPost;
      // console.log(state.popupResult);
    },
    POST_ADD_POPUP: (state, action) => {
      // console.log(action);
      state.popupResult = action.payload;
    },
    COMMENT_SEARCH_END: (state, action) => {
      state.isEndComment = true;
    },
    COMMENT_SEARCH_RESET: (state, action) => {
      state.isLoadingComment = false;
    },
    DEFAULT_COMMENT_SEARCH: state => {
      state.searchResultComment = [];
      state.lastRecordSizeComment = 0;
      state.requestRecordSizeComment = 0;
      state.isEndComment = false;
    },
    COMMENT_SEARCH_FAIL: (state, action) => {
      state.isUpdateComment = false;
      return state;
    },
    COMMENT_CREATING: (state, action) => {
      state.amendPostId = action.payload.postId;
      state.amendCommentId = action.payload.parentId;
      if (action.payload.parentId == 0) state.isCreating = true;
      else if (action.payload.parentId > 0) state.isCreatingReply = true;
    },
    COMMENT_ADD_SUCCESS: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      state.isCreating = false;
      state.isCreatingReply = false;
      state.comment = action.payload.comment;
    },
    COMMENT_EDIT_SUCCESS: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      state.isUpdatedComment = true;
      state.comment = action.payload.comment;
    },
    COMMENT_DELETE_SUCCESS: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      state.deletedCommentId = action.payload.commentId;
      state.isDeletedComment = true;
    },
    POST_ADD_REACTION: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      state.likeId = action.payload.likeId;
    },
    POST_DELETE_REACTION: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      state.likeId = null;
    },
    POST_UPDATE_FAIL: (state, action) => {
      return state;
    },
    COMMENT_ADD_REACTION: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      state.commentLikeId = action.payload.likeId;
    },
    COMMENT_DELETE_REACTION: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      state.commentLikeId = null;
    },
    COMMENT_UPDATE_FAIL: (state, action) => {
      return state;
    },
    COMMENT_DELETE_RESET: (state, action) => {
      state.deletedCommentId = null;
      state.isDeletedComment = false;
    },
    HASHTAG_SEARCH_SUCCESS: (state, action) => {
      state.hashtagList = action.payload.searchResult;
      state.hashtagKeyword = action.payload.keyword;
      localStorage.setItem('token', action.payload.token);
    },
    HASHTAG_SEARCH_FAIL: (state, action) => {
      return state;
      // localStorage.setItem("token", action.payload.token);
    },
    PROFILETAG_SEARCH_SUCCESS: (state, action) => {
      state.profileTagList = action.payload.searchResult;
      state.profileTagKeyword = action.payload.keyword;
      localStorage.setItem('token', action.payload.token);
    },
    PROFILETAG_SEARCH_FAIL: (state, action) => {
      return state;
      // localStorage.setItem("token", action.payload.token);
    },
    HASHTAG_ADD_SUCCESS: (state, action) => {
      // state.hashtagList.push(action.payload.hashValue);
      state.hashValue = action.payload.hashValue;
      state.createHashtagCommentId = action.payload.commentId;
      state.createHashtagPostId = action.payload.postId;
      state.enterKey = action.payload.enterKey;
      localStorage.setItem('token', action.payload.token);
    },
    HASHTAG_ADD_FAIL: (state, action) => {
      state.hashValue = null;
      state.createHashtagCommentId = null;
      state.enterKey = false;
      localStorage.setItem('token', action.payload.token);
    },
    HASHTAG_ADD_RESET: (state, action) => {
      state.hashValue = null;
      state.createHashtagCommentId = null;
      state.enterKey = false;
    },
    POLL_ADD_RESULT: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      // state.poolOptionId = action.payload.poolOptionId;
      // state.poolResultId = action.payload.poolResultId;
      state.poolOptionList = action.payload.poolOptionList;
      state.isChosenOption = action.payload.isChosenOption;
      state.pollOptionPostId = action.payload.postId;
    },
    POLL_DELETE_RESULT: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      // state.poolOptionId = null;
      // state.poolResultId = null;
      state.poolOptionList = action.payload.poolOptionList;
      state.isChosenOption = action.payload.isChosenOption;
      state.pollOptionPostId = action.payload.postId;
    },
    POLL_ADD_FAIL: (state, action) => {
      return state;
    },
    POLL_ADD_RESET: (state, action) => {
      // state.poolOptionId = null;
      // state.poolResultId = null;
      // state.poolOptionList = [];
      state.isChosenOption = false;
      state.pollOptionPostId = 0;
    },
    DEFAULT_POLL_RESULT_USER_GET: (state, action) => {
      state.pollResultUserList = [];
      state.isLoadingPoll = false;
      state.isEndPollResult = false;
      state.lastRecordSizePoll = 0;
      state.requestRecordSizePoll = 0;
    },
    POLL_RESULT_USER_GET_LOADING: (state, action) => {
      state.isLoadingPoll = true;
    },
    POLL_RESULT_USER_GET_SUCCESS: (state, action) => {
      // console.log(action.payload.searchResult);
      localStorage.setItem('token', action.payload.token);
      state.pollResultUserList = [...state.pollResultUserList, ...action.payload.searchResult];
      state.lastRecordSizePoll = state.lastRecordSizePoll + action.payload.requestRecordSize;
      state.requestRecordSizePoll = action.payload.requestRecordSize;
      state.isLoadingPoll = false;
    },
    POLL_RESULT_USER_GET_END: (state, action) => {
      state.isEndPoll = true;
    },
    POLL_RESULT_USER_GET_FAIL: (state, action) => {
      return state;
    },
    DEFAULT_REACTION_USER_GET: (state, action) => {
      state.reactionUserList = [];
      state.isLoadingReaction = false;
      state.isEndReaction = false;
      state.lastRecordSizeReaction = 0;
      state.requestRecordSizeReaction = 0;
    },
    REACTION_USER_GET_LOADING: (state, action) => {
      state.isLoadingReaction = true;
    },
    REACTION_USER_GET_SUCCESS: (state, action) => {
      // console.log(action.payload.searchResult);
      localStorage.setItem('token', action.payload.token);
      state.reactionUserList = [...state.reactionUserList, ...action.payload.searchResult];
      state.lastRecordSizeReaction = state.lastRecordSizeReaction + action.payload.requestRecordSize;
      state.requestRecordSizeReaction = action.payload.requestRecordSize;
      state.isLoadingReaction = false;
    },
    REACTION_USER_GET_END: (state, action) => {
      state.isEndReaction = true;
    },
    REACTION_USER_GET_FAIL: (state, action) => {
      return state;
    },
    REACTION_COUNT_GET_SUCCESS: (state, action) => {
      // console.log(action.payload.searchResult);
      localStorage.setItem('token', action.payload.token);
      state.reactionCount = action.payload.searchResult;
    },
    REACTION_COUNT_GET_FAIL: (state, action) => {
      return state;
    },
    REACTION_USER_LIST_RESET: (state, action) => {
      state.reactionUserList = [];
    },
    POST_SAVE_SUCCESS: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      state.saveId = action.payload.saveId;
    },
    POST_SAVE_DELETE: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      state.saveId = null;
    },
    POST_SAVE_FAIL: (state, action) => {
      return state;
    },
    POST_HIDE_SUCCESS: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      state.hideId = action.payload.hideId;
    },
    POST_HIDE_DELETE: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      state.hideId = null;
    },
    POST_HIDE_RESET: (state, action) => {
      state.hideId = null;
    },
    POST_HIDE_FAIL: (state, action) => {
      return state;
    },
    HASHTAG_MATCH_DEFAULT: (state, action) => {
      state.hashtagMatch = null;
      state.isLoadingHashtagMatch = false;
    },
    HASHTAG_MATCH_LOADING: (state, action) => {
      state.hashtagMatch = null;
      state.isLoadingHashtagMatch = true;
    },
    HASHTAG_MATCH_SUCCESS: (state, action) => {
      state.hashtagMatch = action.payload.hashtagMatch;
      state.isLoadingHashtagMatch = false;
    },
    HASHTAG_MATCH_FAIL: (state, action) => {
      return state;
    },
    HASHTAG_FOLLOW_SUCCESS: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      state.followId = action.payload.followId;
    },
    HASHTAG_FOLLOW_DELETE: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      state.followId = null;
    },
    HASHTAG_FOLLOW_FAIL: (state, action) => {
      return state;
    },
    PROFILE_FOLLOW_SUCCESS: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      state.followProfileId = action.payload.followId;
    },
    PROFILE_FOLLOW_DELETE: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      state.followProfileId = null;
    },
    PROFILE_FOLLOW_FAIL: (state, action) => {
      return state;
    },
  },
});

const {
  POST_SEARCH_SUCCESS,
  POST_GET_SUCCESS,
  POST_GET_LOADING,
  POST_GET_RESET,
  POST_LOADING,
  POST_FITST_LOADING,
  POST_SEARCH_FAIL,
  POST_SEARCH_END,
  POST_ADD_POPUP,
  POST_CREATING_DEFAULT,
  POST_LOADING_CREATE,
  POST_CREATE_SUCCESS,
  POST_DELETE_SUCCESS,
  POST_UPDATE_SUCCESS,
  POST_CREATE_FAIL,
  POST_COMMENT_RESET,
  COMMENT_RESET,
  POST_CLICK_RESET,
  DEFAULT_POST_SEARCH,
  COMMENT_LOADING,
  REPLY_LOADING,
  COMMENT_SEARCH_SUCCESS,
  COMMENT_SEARCH_END,
  COMMENT_SEARCH_FAIL,
  DEFAULT_COMMENT_SEARCH,
  COMMENT_CREATING,
  COMMENT_ADD_SUCCESS,
  COMMENT_EDIT_SUCCESS,
  COMMENT_DELETE_SUCCESS,
  POST_AMEND_SUCCESS,
  POST_AMEND_LOADING,
  POST_ADD_REACTION,
  POST_DELETE_REACTION,
  POST_UPDATE_FAIL,
  COMMENT_ADD_REACTION,
  COMMENT_DELETE_REACTION,
  COMMENT_UPDATE_FAIL,
  COMMENT_DELETE_RESET,
  HASHTAG_SEARCH_SUCCESS,
  HASHTAG_SEARCH_FAIL,
  PROFILETAG_SEARCH_SUCCESS,
  PROFILETAG_SEARCH_FAIL,
  HASHTAG_ADD_SUCCESS,
  HASHTAG_ADD_FAIL,
  HASHTAG_ADD_RESET,
  POLL_ADD_RESULT,
  POLL_ADD_FAIL,
  POLL_DELETE_RESULT,
  POLL_ADD_RESET,
  POLL_RESULT_USER_GET_SUCCESS,
  POLL_RESULT_USER_GET_END,
  POLL_RESULT_USER_GET_FAIL,
  POLL_RESULT_USER_GET_LOADING,
  DEFAULT_POLL_RESULT_USER_GET,
  COMMENT_SEARCH_RESET,
  REACTION_USER_GET_SUCCESS,
  REACTION_USER_GET_END,
  REACTION_USER_GET_FAIL,
  REACTION_USER_GET_LOADING,
  REACTION_USER_LIST_RESET,
  DEFAULT_REACTION_USER_GET,
  REACTION_COUNT_GET_SUCCESS,
  REACTION_COUNT_GET_FAIL,
  POST_SAVE_SUCCESS,
  POST_SAVE_FAIL,
  POST_SAVE_DELETE,
  POST_HIDE_SUCCESS,
  POST_HIDE_FAIL,
  POST_HIDE_DELETE,
  POST_HIDE_RESET,
  HASHTAG_MATCH_DEFAULT,
  HASHTAG_MATCH_SUCCESS,
  HASHTAG_MATCH_FAIL,
  HASHTAG_MATCH_LOADING,
  HASHTAG_FOLLOW_SUCCESS,
  HASHTAG_FOLLOW_FAIL,
  HASHTAG_FOLLOW_DELETE,
  PROFILE_FOLLOW_SUCCESS,
  PROFILE_FOLLOW_DELETE,
  PROFILE_FOLLOW_FAIL,
} = socialFeedSlice.actions;

/* export const defaultSearch = () => (dispatch) => {
  dispatch(DEFAULT_PROFILE_SEARCH());
}; */
export const getSocialPostByRecent = ({
  acctId,
  email,
  accessToken,
  lastRecordSize,
  requestRecordSize,
  hashId,
  userId,
  groupId,
  pageId,
  sortBy,
  keyword,
  visibleIds,
}) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    lastRecordSize,
    requestRecordSize,
    hashId,
    userId,
    groupId,
    pageId,
    keyword: keyword && keyword.charAt(0) == '#' ? keyword.slice(1) : keyword,
    visibleIds,
  };
  console.log(body);
  if (lastRecordSize == 0) {
    dispatch(DEFAULT_COMMENT_SEARCH());
    dispatch(DEFAULT_POST_SEARCH(0));
  }
  if (lastRecordSize == 0) dispatch(POST_FITST_LOADING());
  else dispatch(POST_LOADING());

  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/sf/get-social-post-by-${sortBy}`,
    params: body,
  })
    .then(res => {
      console.log(res);
      if (res.status === 201) {
        const response = {
          searchResult: res.data.payload.postList,
          searchParams: {},
          lastRecordSize,
          requestRecordSize: res.data.payload.postList.length,
          sortBy: 0,
          datePosted: 0,
          postedBy: '',
          token: res.data.token,
          // lastPostId: res.data.payload.postList[0].postId,
          hashId: hashId,
          userId: userId,
          groupId: groupId,
          pageId: pageId,
          keyword: keyword,
        };
        console.log('get post', response);
        dispatch(POST_SEARCH_SUCCESS(response));
        if (requestRecordSize > res.data.payload.postList.length) {
          dispatch(POST_SEARCH_END());
        }
      } else if (res.status === 202) {
        dispatch(POST_SEARCH_END());
      } else {
        dispatch(POST_SEARCH_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};
export const getSocialPostByRecommended = ({
  acctId,
  email,
  accessToken,
  lastRecordSize,
  requestRecordSize,
  // hashId,
  // userId,
  // groupId,
  // pageId,
  // sortBy,
  // keyword,
  // visibleIds,
}) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    lastRecordSize,
    requestRecordSize,
    // hashId,
    // userId,
    // groupId,
    // pageId,
    // keyword: keyword && keyword.charAt(0) == '#' ? keyword.slice(1) : keyword,
    // visibleIds,
  };
  console.log("body");
  console.log(body);
  console.log("body");
  if (lastRecordSize == 0) {
    dispatch(DEFAULT_COMMENT_SEARCH());
    dispatch(DEFAULT_POST_SEARCH(0));
  }
  if (lastRecordSize == 0) dispatch(POST_FITST_LOADING());
  else dispatch(POST_LOADING());

  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/um/get-user-post-recommendation-by-acct-id`,
    params: body,
  })
    .then(res => {
      console.log(res);
      if (res.status === 201) {
        const response = {
          searchResult: res.data.payload.postRecommendationList,
          searchResult1: res.data.payload.postRecommendationList,
          searchParams: {},
          lastRecordSize,
          requestRecordSize: res.data.payload.postRecommendationList.length,
          //sortBy: 0,
          datePosted: 0,
          postedBy: '',
          token: res.data.token,
          // lastPostId: res.data.payload.postList[0].postId,
          // hashId: hashId,
          // userId: userId,
          // groupId: groupId,
          // pageId: pageId,
          // keyword: keyword,
        };
        console.log('get post', response);
        console.log('get post', response);
        console.log('get post', response);
        dispatch(POST_SEARCH_SUCCESS(response));
        if (res.data.payload.postList && (requestRecordSize > res.data.payload.postList.length)) {
          dispatch(POST_SEARCH_END());
        }
      } else if (res.status === 202) {
        dispatch(POST_SEARCH_END());
      } else {
        dispatch(POST_SEARCH_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};
export const getSocialPostByTop = ({
  acctId,
  email,
  accessToken,
  lastRecordSize,
  requestRecordSize,
  hashId,
  userId,
  groupId,
  pageId,
  sortBy,
  keyword,
  type,
}) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    lastRecordSize,
    requestRecordSize,
    hashId,
    userId,
    groupId,
    pageId,
    keyword: keyword && keyword.charAt(0) == '#' ? keyword.slice(1) : keyword,
    type,
  };
  // console.log(body);
  if (lastRecordSize === 0) {
    dispatch(DEFAULT_COMMENT_SEARCH());
    dispatch(DEFAULT_POST_SEARCH(0));
  }
  if (lastRecordSize == 0) dispatch(POST_FITST_LOADING());
  else dispatch(POST_LOADING());

  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/sf/get-social-post-by-${sortBy}`,
    params: body,
  })
    .then(res => {
      // console.log(res);
      if (res.status === 201) {
        const response = {
          searchResult: res.data.payload.postList,
          searchParams: {},
          lastRecordSize,
          requestRecordSize: res.data.payload.postList.length,
          sortBy: 0,
          datePosted: 0,
          postedBy: '',
          token: res.data.token,
          // lastPostId: res.data.payload.postList[0].postId,
          hashId: hashId,
          userId: userId,
          groupId: groupId,
          pageId: pageId,
          keyword: keyword,
        };
        console.log('get post', response);
        dispatch(POST_SEARCH_SUCCESS(response));
        if (requestRecordSize > res.data.payload.postList.length) {
          dispatch(POST_SEARCH_END());
        }
      } else if (res.status === 202) {
        dispatch(POST_SEARCH_END());
      } else {
        dispatch(POST_SEARCH_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};
export const getSocialPostByHashId = ({
  acctId,
  email,
  accessToken,
  lastRecordSize,
  requestRecordSize,
  hashId,
  userId,
  groupId,
  pageId,
  sortBy,
  keyword,
  visibleIds,
}) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    lastRecordSize,
    requestRecordSize,
    hashId,
    userId,
    groupId,
    pageId,
    keyword,
    visibleIds,
  };
  // console.log(body);
  if (lastRecordSize === 0) {
    dispatch(DEFAULT_COMMENT_SEARCH());
    dispatch(DEFAULT_POST_SEARCH(0));
  }
  if (lastRecordSize == 0) dispatch(POST_FITST_LOADING());
  else dispatch(POST_LOADING());

  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/sf/get-social-post-by-${sortBy}`,
    params: body,
  })
    .then(res => {
      // console.log(res);
      if (res.status === 201) {
        const response = {
          searchResult: res.data.payload.postList,
          searchParams: {},
          lastRecordSize,
          requestRecordSize: res.data.payload.postList.length,
          sortBy: 0,
          datePosted: 0,
          postedBy: '',
          token: res.data.token,
          hashId,
          userId,
          groupId,
          pageId,
          keyword,
          // lastPostId: res.data.payload.postList[0].postId,
        };
        dispatch(POST_SEARCH_SUCCESS(response));
        if (requestRecordSize > res.data.payload.postList.length) {
          dispatch(POST_SEARCH_END());
        }
      } else if (res.status === 202) {
        dispatch(POST_SEARCH_END());
      } else {
        dispatch(POST_SEARCH_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const getSocialPostPopup = post => async dispatch => {
  dispatch(POST_ADD_POPUP(post));
};

export const getSocialCommentByPostId = ({
  acctId,
  email,
  accessToken,
  lastRecordSize,
  requestRecordSize,
  postId,
  postList,
  isCopyPost,
}) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    lastRecordSize,
    requestRecordSize,
    postId,
  };
  console.log(body);
  if (lastRecordSize == 0) dispatch(DEFAULT_COMMENT_SEARCH());
  dispatch(COMMENT_LOADING());
  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/sf/get-social-comment-by-post-id`,
    params: body,
  })
    .then(res => {
      console.log(res);
      if (res.status === 201) {
        const response = {
          searchResult: res.data.payload.commentList,
          lastRecordSize,
          requestRecordSize: res.data.payload.commentList.length,
          token: res.data.token,
          postId: postId,
          commentId: 0,
          postList: postList,
          isLoadMoreComment: true,
          isCreateComment: false,
          isDeleteComment: false,
          isCopyPost: isCopyPost,
        };
        dispatch(COMMENT_SEARCH_SUCCESS(response));
        if (requestRecordSize > res.data.payload.commentList.length) {
          dispatch(COMMENT_SEARCH_END());
        }
      } else if (res.status === 202) {
        dispatch(COMMENT_SEARCH_END());
      } else {
        dispatch(COMMENT_SEARCH_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
      dispatch(COMMENT_SEARCH_RESET());
    });
};

export const getSocialReplyByCommentId = ({
  acctId,
  email,
  accessToken,
  lastRecordSize,
  requestRecordSize,
  commentId,
  postId,
  postList,
  isLoadMoreComment,
  isCreateComment,
  isDeleteComment,
  isCopyPost,
}) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    lastRecordSize,
    requestRecordSize,
    commentId,
  };
  console.log(body);
  if (lastRecordSize == 0) dispatch(DEFAULT_COMMENT_SEARCH());
  dispatch(REPLY_LOADING());
  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/sf/get-social-reply-by-comment-id`,
    params: body,
  })
    .then(res => {
      // console.log(res);
      if (res.status === 201) {
        const response = {
          searchResult: res.data.payload.replyList,
          lastRecordSize,
          requestRecordSize: res.data.payload.replyList.length,
          token: res.data.token,
          commentId: commentId,
          postId: postId,
          postList: postList,
          isLoadMoreComment: isLoadMoreComment,
          isCreateComment: isCreateComment,
          isDeleteComment: isDeleteComment,
          isCopyPost: isCopyPost,
        };
        // console.log(response);
        dispatch(COMMENT_SEARCH_SUCCESS(response));
        // if (requestRecordSize > res.data.payload.replyList.length) {
        // 	dispatch(COMMENT_SEARCH_END());
        // }
      } else if (res.status === 202) {
        dispatch(COMMENT_SEARCH_END());
      } else {
        dispatch(COMMENT_SEARCH_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
      dispatch(COMMENT_SEARCH_RESET());
    });
};

export const getSocialCommentByCommentId = ({
  acctId,
  email,
  accessToken,
  lastRecordSize,
  requestRecordSize,
  commentId,
  postId,
  postList,
  isLoadMoreComment,
  isCreateComment,
  isDeleteComment,
  isCopyPost,
}) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    lastRecordSize,
    requestRecordSize,
    commentId,
  };
  // console.log(body);
  if (lastRecordSize == 0) dispatch(DEFAULT_COMMENT_SEARCH());
  dispatch(REPLY_LOADING());
  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/sf/get-social-comment-by-comment-id`,
    params: body,
  })
    .then(res => {
      // console.log(res);
      if (res.status === 201) {
        const response = {
          searchResult: res.data.payload.commentList,
          lastRecordSize,
          requestRecordSize: res.data.payload.commentList.length,
          token: res.data.token,
          commentId: res.data.payload.commentList.length > 0 ? res.data.payload.commentList[0].parentId : 0,
          postId: postId,
          postList: postList,
          isLoadMoreComment: isLoadMoreComment,
          isCreateComment: isCreateComment,
          isDeleteComment: isDeleteComment,
          originalCommentId: commentId,
          isCopyPost: isCopyPost,
        };
        // console.log(response);
        dispatch(COMMENT_SEARCH_SUCCESS(response));
        // if (requestRecordSize > res.data.payload.replyList.length) {
        // 	dispatch(COMMENT_SEARCH_END());
        // }
      } else if (res.status === 202) {
        dispatch(COMMENT_SEARCH_END());
      } else {
        dispatch(COMMENT_SEARCH_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
      dispatch(COMMENT_SEARCH_RESET());
    });
};

export const createSocialComment = (
  data,
  { postList },
  postId,
  parentId,
  lastRecordSize,
  isCopyPost
) => async dispatch => {
  // console.log(postList);
  var request = {
    parentId: parentId,
    postId: postId,
  };
  dispatch(COMMENT_CREATING(request));
  await axios({
    method: 'POST',
    headers: headerFormData,
    url: `${instanceUrl}/api/sf/create-social-comment`,
    data: data,
  })
    .then(res => {
      // console.log(res);
      if (res.status === 201) {
        var originalPost = postList.filter(x => x.postId == postId);
        if (parentId == 0) {
          var requestProfile = {
            acctId: res.data.payload.social.acctId,
            accessToken: res.data.payload.social.accessToken,
            email: res.data.payload.social.email,
            postId: res.data.payload.social.postId,
            postList: postList,
            commentId: res.data.payload.social.commentId,
            lastRecordSize: lastRecordSize,
            requestRecordSize: 1,
            isLoadMoreComment: false,
            isCreateComment: true,
            isDeleteComment: false,
            isCopyPost: isCopyPost,
          };
          dispatch(getSocialCommentByCommentId(requestProfile));
        } else {
          var requestProfile = {
            acctId: res.data.payload.social.acctId,
            accessToken: res.data.payload.social.accessToken,
            email: res.data.payload.social.email,
            postId: res.data.payload.social.postId,
            postList: postList,
            commentId: parentId,
            lastRecordSize: lastRecordSize,
            requestRecordSize: 1,
            isLoadMoreComment: false,
            isCreateComment: true,
            isDeleteComment: false,
            isCopyPost: isCopyPost,
          };
          dispatch(getSocialReplyByCommentId(requestProfile));
        }
        const response = {
          token: res.data.token,
          comment: res.data.payload.social,
          parentId: parentId,
        };
        dispatch(COMMENT_ADD_SUCCESS(response));
      } else {
        dispatch(COMMENT_UPDATE_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const updateSocialComment = (
  data,
  { postList },
  postId,
  parentId,
  lastRecordSize,
  isCopyPost
) => async dispatch => {
  // console.log(...data);
  var request = {
    parentId: parentId,
    postId: postId,
  };
  dispatch(COMMENT_CREATING(request));
  await axios({
    method: 'PUT',
    headers: headerFormData,
    url: `${instanceUrl}/api/sf/update-social-comment`,
    data: data,
  })
    .then(res => {
      // console.log(res);
      if (res.status === 201) {
        var originalPost = postList.filter(x => x.postId == postId);
        // const requestProfile = {
        // 	acctId: res.data.payload.social.acctId,
        // 	accessToken: res.data.payload.social.accessToken,
        // 	email: res.data.payload.social.email,
        // 	postId: res.data.payload.social.postId,
        // 	postList: postList,
        // 	parentId: parentId,
        // 	requestRecordSizeComment: originalPost[0].comments.commentList.length,
        // 	commentRequestLength: commentRequestLength,
        // };
        // dispatch(getSocialPostByPostId(requestProfile));
        // var requestProfile = {
        // 	acctId: res.data.payload.social.acctId,
        // 	accessToken: res.data.payload.social.accessToken,
        // 	email: res.data.payload.social.email,
        // 	postId: res.data.payload.social.postId,
        // 	postList: postList,
        // 	commentId: parentId,
        // 	lastRecordSize: lastRecordSize,
        // 	requestRecordSize: 1,
        // 	isLoadMoreComment: false,
        // 	isCreateComment: false,
        // 	isCopyPost: isCopyPost,
        // };
        // dispatch(getSocialCommentByCommentId(requestProfile));

        if (parentId == 0) {
          var requestProfile = {
            acctId: res.data.payload.social.acctId,
            accessToken: res.data.payload.social.accessToken,
            email: res.data.payload.social.email,
            postId: res.data.payload.social.postId,
            postList: postList,
            commentId: res.data.payload.social.commentId,
            lastRecordSize: lastRecordSize,
            requestRecordSize: 1,
            isLoadMoreComment: false,
            isCreateComment: false,
            isDeleteComment: false,
            isCopyPost: isCopyPost,
          };
          dispatch(getSocialCommentByCommentId(requestProfile));
        } else {
          var requestProfile = {
            acctId: res.data.payload.social.acctId,
            accessToken: res.data.payload.social.accessToken,
            email: res.data.payload.social.email,
            postId: res.data.payload.social.postId,
            postList: postList,
            commentId: parentId,
            lastRecordSize: lastRecordSize,
            requestRecordSize: 1,
            isLoadMoreComment: false,
            isCreateComment: false,
            isDeleteComment: false,
            isCopyPost: isCopyPost,
          };
          dispatch(getSocialReplyByCommentId(requestProfile));
        }

        const response = {
          token: res.data.token,
          comment: res.data.payload.social,
          parentId: parentId,
        };
        dispatch(COMMENT_ADD_SUCCESS(response));
      } else {
        dispatch(COMMENT_UPDATE_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const getSocialPostByPostId = ({
  acctId,
  email,
  accessToken,
  postId,
  postList,
  parentId,
  requestRecordSizeComment,
  commentRequestLength,
  visibleIds,
  groupId,
}) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    postId,
    parentId,
    requestRecordSizeComment,
    commentRequestLength,
    visibleIds,
    groupId,
  };
  // console.log(body);
  // var request = {
  // 	parentId: parentId,
  // 	postId: postId,
  // };
  // dispatch(POST_AMEND_LOADING(parentId));
  dispatch(POST_GET_LOADING());
  dispatch(DEFAULT_POST_SEARCH(0));

  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/sf/get-social-post-by-post-id`,
    params: body,
  })
    .then(res => {
      console.log(res);
      if (res.status === 201) {
        const response = {
          searchResult: res.data.payload.postList,
          token: res.data.token,
          postId: postId,
          postList: postList,
          parentId: parentId,
          groupId: groupId,
        };
        dispatch(POST_GET_SUCCESS(response));
        // dispatch(POST_AMEND_SUCCESS(response));
      } else {
        dispatch(POST_SEARCH_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const getRecommendedPostByAccId = ({
  acctId,
  email,
  accessToken,
  lastRecordSize,
  requestRecordSize,
}) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    lastRecordSize,
    requestRecordSize,
  };

  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/um/get-user-post-recommendation-by-acct-id`,
    params: body,
  })
    .then(res => {
      console.log(res);
      if (res.status === 201) {
        const response = {
          searchResult: res.data.payload.postList,
          token: res.data.token
        };
        dispatch(POST_GET_SUCCESS(response));

      } else {
        dispatch(POST_SEARCH_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const resetSocialPost = () => async dispatch => {
  dispatch(DEFAULT_POST_SEARCH(1));
};

export const resetPostGet = () => async dispatch => {
  dispatch(POST_GET_RESET());
};

export const resetPostComment = ({ postId }) => async dispatch => {
  const response = {
    postId: postId,
  };
  dispatch(POST_COMMENT_RESET(response));
};

export const resetComment = ({ commentId }) => async dispatch => {
  const response = {
    commentId: commentId,
  };
  dispatch(COMMENT_RESET(response));
};

export const resetPostClick = () => async dispatch => {
  dispatch(POST_CLICK_RESET());
};

export const createSocialReaction = ({ acctId, email, accessToken, reactionId, postId }) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    reactionId,
    postId,
  };
  // console.log(body);
  await axios({
    method: 'POST',
    headers: header,
    url: `${instanceUrl}/api/sf/create-social-reaction`,
    data: body,
  })
    .then(res => {
      // console.log(res);
      if (res.status === 201) {
        const response = {
          token: res.data.token,
          likeId: res.data.payload.social.likeId,
          createdDate: res.data.payload.social.createdDate,
        };
        dispatch(POST_ADD_REACTION(response));
      } else {
        dispatch(POST_UPDATE_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const deleteSocialReaction = ({ likeId, acctId, email, accessToken }) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    likeId,
  };
  await axios({
    method: 'PUT',
    headers: header,
    url: `${instanceUrl}/api/sf/delete-social-reaction`,
    data: body,
  })
    .then(res => {
      if (res.status === 201) {
        const response = {
          likeId,
          token: res.data.token,
        };
        dispatch(POST_DELETE_REACTION(response));
      } else {
        dispatch(POST_UPDATE_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const createCommentReaction = ({
  acctId,
  email,
  accessToken,
  reactionId,
  commentId,
  postList,
  postId,
  isCopyPost,
}) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    reactionId,
    commentId,
    postList,
    postId,
  };
  // console.log(body);
  await axios({
    method: 'POST',
    headers: header,
    url: `${instanceUrl}/api/sf/create-comment-reaction`,
    data: body,
  })
    .then(res => {
      // console.log(res);
      if (res.status === 201) {
        var requestProfile = {
          acctId: acctId,
          accessToken: accessToken,
          email: email,
          postId: postId,
          postList: postList,
          commentId: commentId,
          lastRecordSize: 0,
          requestRecordSize: 1,
          isLoadMoreComment: false,
          isCreateComment: false,
          isDeleteComment: false,
          isCopyPost: isCopyPost,
        };
        dispatch(getSocialCommentByCommentId(requestProfile));
        const response = {
          token: res.data.token,
          likeId: res.data.payload.social.likeId,
          createdDate: res.data.payload.social.createdDate,
        };
        dispatch(COMMENT_ADD_REACTION(response));
      } else {
        dispatch(COMMENT_UPDATE_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const deleteCommentReaction = ({
  likeId,
  acctId,
  email,
  accessToken,
  postList,
  postId,
  commentId,
  isCopyPost,
}) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    likeId,
  };
  await axios({
    method: 'PUT',
    headers: header,
    url: `${instanceUrl}/api/sf/delete-comment-reaction`,
    data: body,
  })
    .then(res => {
      if (res.status === 201) {
        var requestProfile = {
          acctId: acctId,
          accessToken: accessToken,
          email: email,
          postId: postId,
          postList: postList,
          commentId: commentId,
          lastRecordSize: 0,
          requestRecordSize: 1,
          isLoadMoreComment: false,
          isCreateComment: false,
          isDeleteComment: false,
          isCopyPost: isCopyPost,
        };
        dispatch(getSocialCommentByCommentId(requestProfile));
        const response = {
          likeId,
          token: res.data.token,
        };
        dispatch(COMMENT_DELETE_REACTION(response));
      } else {
        dispatch(COMMENT_UPDATE_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const deleteSocialComment = ({
  commentId,
  acctId,
  email,
  accessToken,
  postList,
  postId,
  parentId,
}) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    commentId,
  };
  // console.log(body);
  await axios({
    method: 'PUT',
    headers: header,
    url: `${instanceUrl}/api/sf/delete-social-comment`,
    data: body,
  })
    .then(res => {
      // console.log(res);
      if (res.status === 201) {
        if (parentId == 0) {
          var requestProfile = {
            acctId: acctId,
            accessToken: accessToken,
            email: email,
            postId: postId,
            postList: postList,
            commentId: commentId,
            lastRecordSize: 0,
            requestRecordSize: 1,
            isLoadMoreComment: false,
            isCreateComment: false,
            isDeleteComment: true,
            isCopyPost: false,
          };
          dispatch(getSocialCommentByCommentId(requestProfile));
        } else {
          var requestProfile = {
            acctId: acctId,
            accessToken: accessToken,
            email: email,
            postId: postId,
            postList: postList,
            commentId: parentId,
            lastRecordSize: 0,
            requestRecordSize: 1,
            isLoadMoreComment: false,
            isCreateComment: false,
            isDeleteComment: true,
            isCopyPost: false,
          };
          dispatch(getSocialReplyByCommentId(requestProfile));
        }
        const response = {
          commentId: res.data.payload.commentId,
          token: res.data.token,
        };
        dispatch(COMMENT_DELETE_SUCCESS(response));
      } else {
        dispatch(COMMENT_UPDATE_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const resetDeleteCommentId = () => async dispatch => {
  dispatch(COMMENT_DELETE_RESET());
};

export const getSocialHashtag = ({
  acctId,
  email,
  accessToken,
  keyword,
  lastRecordSize,
  requestRecordSize,
}) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    keyword,
    lastRecordSize,
    requestRecordSize,
  };
  // console.log(body);

  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/sf/get-social-hashtag`,
    params: body,
  })
    .then(res => {
      // console.log(res);
      if (res.status === 201) {
        var hashValues = [];
        for (var i = 0; i < res.data.payload.hashtagList.length; i++) {
          hashValues.push({
            id: res.data.payload.hashtagList[i].hashId,
            value: res.data.payload.hashtagList[i].hashtagName,
            link: window.location.origin + '/social-feed/3/' + encryptData(res.data.payload.hashtagList[i].hashId),
            mentionChar: '#',
          });
        }
        const response = {
          searchResult: hashValues,
          keyword: keyword,
          token: res.data.token,
        };
        dispatch(HASHTAG_SEARCH_SUCCESS(response));
      } else {
        dispatch(HASHTAG_SEARCH_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const getSocialProfileTag = ({
  acctId,
  email,
  accessToken,
  keyword,
  lastRecordSize,
  requestRecordSize,
}) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    keyword,
    lastRecordSize,
    requestRecordSize,
    statusId: 1,
    sortBy: 1,
  };
  // console.log(body);

  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/um/get-connection-profile-by-acct-id`,
    params: body,
  })
    .then(res => {
      // console.log(res);
      if (res.status === 201) {
        var profileValues = [];
        for (var i = 0; i < res.data.payload.connectionList.length; i++) {
          profileValues.push({
            id: res.data.payload.connectionList[i].receiverAcctId,
            value: res.data.payload.connectionList[i].firstName + ' ' + res.data.payload.connectionList[i].lastName,
            link:
              window.location.origin +
              '/view-profile/' +
              encryptData(res.data.payload.connectionList[i].receiverAcctId),
            // renderItem: {
            // 	profileTag: res.data.payload.connectionList[i],
            // },
            profilePic: res.data.payload.connectionList[i].profilePic,
            mentionChar: '@',
          });
        }
        const response = {
          searchResult: profileValues,
          keyword: keyword,
          token: res.data.token,
        };
        dispatch(PROFILETAG_SEARCH_SUCCESS(response));
      } else {
        dispatch(PROFILETAG_SEARCH_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const getSocialProfileTagByGroupId = ({
  acctId,
  email,
  accessToken,
  keyword,
  lastRecordSize,
  requestRecordSize,
  groupId,
}) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    keyword,
    lastRecordSize,
    requestRecordSize,
    groupId,
  };
  console.log(body);

  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/sf/get-social-post-profile-tag-by-group-id`,
    params: body,
  })
    .then(res => {
      // console.log(res);
      if (res.status === 201) {
        var profileValues = [];
        for (var i = 0; i < res.data.payload.profileList.length; i++) {
          profileValues.push({
            id: res.data.payload.profileList[i].acctId,
            value: res.data.payload.profileList[i].firstName + ' ' + res.data.payload.profileList[i].surname,
            link: window.location.origin + '/view-profile/' + encryptData(res.data.payload.profileList[i].acctId),
            // renderItem: {
            // 	profileTag: res.data.payload.connectionList[i],
            // },
            profilePic: res.data.payload.profileList[i].profilePic,
            mentionChar: '@',
          });
        }
        const response = {
          searchResult: profileValues,
          keyword: keyword,
          token: res.data.token,
        };
        dispatch(PROFILETAG_SEARCH_SUCCESS(response));
      } else {
        dispatch(PROFILETAG_SEARCH_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const createSocialHashtag = ({
  acctId,
  email,
  accessToken,
  hashtagName,
  commentId,
  postId,
  enterKey,
}) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    hashtagName,
  };
  // console.log(body);
  await axios({
    method: 'POST',
    headers: header,
    url: `${instanceUrl}/api/sf/create-social-hashtag`,
    data: body,
  })
    .then(res => {
      // console.log(res);
      if (res.status === 201) {
        var hashValue = {
          id: res.data.payload.social.hashId,
          value: res.data.payload.social.hashtagName,
          link: window.location.origin + '/social-feed/3/' + encryptData(res.data.payload.social.hashId),
        };
        const response = {
          token: res.data.token,
          social: res.data.payload.social,
          hashValue: hashValue,
          commentId: commentId,
          postId: postId,
          enterKey: enterKey,
        };
        dispatch(HASHTAG_ADD_SUCCESS(response));
      } else {
        dispatch(HASHTAG_ADD_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const resetCreatedHashtag = () => async dispatch => {
  dispatch(HASHTAG_ADD_RESET());
};

export const createPollResult = ({ acctId, email, accessToken, poolOptionId, poolId, postId }) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    poolOptionId,
    poolId,
  };
  // console.log(body);
  dispatch(POLL_ADD_RESET());
  await axios({
    method: 'POST',
    headers: header,
    url: `${instanceUrl}/api/sf/create-pool-result`,
    data: body,
  })
    .then(res => {
      // console.log(res);
      if (res.status === 201) {
        const response = {
          token: res.data.token,
          poolOptionId: res.data.payload.social.poolOptionId,
          poolResultId: res.data.payload.social.poolResultId,
          poolOptionList: res.data.payload.social.poolOptionList,
          createdDate: res.data.payload.social.createdDate,
          postId: postId,
          isChosenOption: true,
        };
        dispatch(POLL_ADD_RESULT(response));
      } else {
        dispatch(POLL_ADD_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const deletePollResult = ({ poolResultId, acctId, email, accessToken, poolId, postId }) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    poolResultId,
    poolId,
  };
  dispatch(POLL_ADD_RESET());
  await axios({
    method: 'PUT',
    headers: header,
    url: `${instanceUrl}/api/sf/delete-pool-result`,
    data: body,
  })
    .then(res => {
      // console.log(res);
      if (res.status === 201) {
        const response = {
          poolResultId,
          token: res.data.token,
          poolOptionList: res.data.payload.result.poolOptionList,
          isChosenOption: false,
          postId: postId,
        };
        dispatch(POLL_DELETE_RESULT(response));
      } else {
        dispatch(POLL_ADD_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const getPollResultUserList = ({
  acctId,
  email,
  accessToken,
  poolOptionId,
  lastRecordSize,
  requestRecordSize,
}) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    poolOptionId,
    lastRecordSize,
    requestRecordSize,
  };
  // console.log(body);
  if (lastRecordSize === 0) {
    dispatch(DEFAULT_POLL_RESULT_USER_GET());
  }
  dispatch(POLL_RESULT_USER_GET_LOADING());

  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/sf/get-pool-result-user-list-by-pool-option-id`,
    params: body,
  })
    .then(res => {
      // console.log(res);
      if (res.status === 201) {
        const response = {
          searchResult: res.data.payload.poolResultUserList,
          token: res.data.token,
          poolOptionId: poolOptionId,
          lastRecordSize: res.data.payload.poolResultUserList.length,
        };
        dispatch(POLL_RESULT_USER_GET_SUCCESS(response));
        if (requestRecordSize > res.data.payload.poolResultUserList.length) {
          dispatch(POLL_RESULT_USER_GET_END());
        }
      } else {
        dispatch(POLL_RESULT_USER_GET_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const getCommentReactionUserList = ({
  acctId,
  email,
  accessToken,
  commentId,
  reactionId,
  lastRecordSize,
  requestRecordSize,
}) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    commentId,
    reactionId,
    lastRecordSize,
    requestRecordSize,
  };

  // console.log(body);
  if (lastRecordSize === 0) {
    dispatch(DEFAULT_REACTION_USER_GET());
  }
  dispatch(REACTION_USER_GET_LOADING());

  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/sf/get-comment-reaction-user-list-by-comment-id`,
    params: body,
  })
    .then(res => {
      // console.log(res);
      if (res.status === 201) {
        const response = {
          searchResult: res.data.payload.commentReactionUserList,
          token: res.data.token,
          reactionId: reactionId,
          lastRecordSize: res.data.payload.commentReactionUserList.length,
        };
        dispatch(REACTION_USER_GET_SUCCESS(response));
        if (requestRecordSize > res.data.payload.commentReactionUserList.length) {
          dispatch(REACTION_USER_GET_END());
        }
      } else {
        dispatch(REACTION_USER_GET_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const getPostReactionUserList = ({
  acctId,
  email,
  accessToken,
  postId,
  reactionId,
  lastRecordSize,
  requestRecordSize,
}) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    postId,
    reactionId,
    lastRecordSize,
    requestRecordSize,
  };

  // console.log(body);
  if (lastRecordSize === 0) {
    dispatch(DEFAULT_REACTION_USER_GET());
  }
  dispatch(REACTION_USER_GET_LOADING());

  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/sf/get-social-reaction-user-list-by-post-id`,
    params: body,
  })
    .then(res => {
      // console.log(res);
      if (res.status === 201) {
        const response = {
          searchResult: res.data.payload.socialReactionUserList,
          token: res.data.token,
          reactionId: reactionId,
          lastRecordSize: res.data.payload.socialReactionUserList.length,
        };
        dispatch(REACTION_USER_GET_SUCCESS(response));
        if (requestRecordSize > res.data.payload.socialReactionUserList.length) {
          dispatch(REACTION_USER_GET_END());
        }
      } else {
        dispatch(REACTION_USER_GET_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const getCommentReactionCount = ({ acctId, email, accessToken, commentId, firstLoad }) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    commentId,
  };
  if (firstLoad) dispatch(REACTION_USER_LIST_RESET());
  // console.log(body);
  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/sf/get-comment-reaction-count-by-comment-id`,
    params: body,
  })
    .then(res => {
      // console.log(res);
      if (res.status === 201) {
        const response = {
          searchResult: res.data.payload.commentReactionCount,
          token: res.data.token,
          commentId: commentId,
        };
        dispatch(REACTION_COUNT_GET_SUCCESS(response));
      } else {
        dispatch(REACTION_COUNT_GET_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const getPostReactionCount = ({ acctId, email, accessToken, postId, firstLoad }) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    postId,
  };
  if (firstLoad) dispatch(REACTION_USER_LIST_RESET());
  // console.log(body);
  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/sf/get-social-reaction-count-by-post-id`,
    params: body,
  })
    .then(res => {
      // console.log(res);
      if (res.status === 201) {
        const response = {
          searchResult: res.data.payload.socialReactionCount,
          token: res.data.token,
          postId: postId,
        };
        dispatch(REACTION_COUNT_GET_SUCCESS(response));
      } else {
        dispatch(REACTION_COUNT_GET_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const createSavePost = ({ acctId, email, accessToken, postId }) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    postId,
  };
  // console.log(body);
  await axios({
    method: 'POST',
    headers: header,
    url: `${instanceUrl}/api/sf/create-save-post`,
    data: body,
  })
    .then(res => {
      // console.log(res);
      if (res.status === 201) {
        const response = {
          token: res.data.token,
          saveId: res.data.payload.social.saveId,
          createdDate: res.data.payload.social.createdDate,
        };
        dispatch(POST_SAVE_SUCCESS(response));
      } else {
        dispatch(POST_SAVE_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const deleteSavePost = ({ saveId, acctId, email, accessToken }) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    saveId,
  };
  await axios({
    method: 'PUT',
    headers: header,
    url: `${instanceUrl}/api/sf/delete-save-post`,
    data: body,
  })
    .then(res => {
      if (res.status === 201) {
        const response = {
          saveId,
          token: res.data.token,
        };
        dispatch(POST_SAVE_DELETE(response));
      } else {
        dispatch(POST_SAVE_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const createSocialPost = (data, postStatus, groupId) => async dispatch => {
  dispatch(POST_CREATING_DEFAULT());
  dispatch(POST_LOADING_CREATE());
  await axios({
    method: 'POST',
    headers: headerFormData,
    url: `${instanceUrl}/api/sf/create-social-post`,
    data: data,
  })
    .then(res => {
      if (res.status === 201) {
        if (postStatus == 1) {
          const requestProfile = {
            acctId: res.data.payload.social.acctId,
            accessToken: res.data.payload.social.accessToken,
            email: res.data.payload.social.email,
            postId: res.data.payload.social.postId,
            lastRecordSize: 0,
            requestRecordSize: 1,
            postList: [],
            parentId: 0,
            requestRecordSizeComment: 3,
            commentRequestLength: 0,
            visibleIds: '1,2',
            groupId: groupId,
          };
          dispatch(getSocialPostByPostId(requestProfile));
        }
        const response = {
          token: res.data.token,
          post: res.data.payload.social,
          postId: res.data.payload.social.postId,
          postStatus: postStatus,
        };
        dispatch(POST_CREATE_SUCCESS(response));
      } else {
        dispatch(POST_CREATE_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const updateSocialPost = (data, postStatus, groupId) => async dispatch => {
  dispatch(POST_CREATING_DEFAULT());
  dispatch(POST_LOADING_CREATE());
  await axios({
    method: 'PUT',
    headers: headerFormData,
    url: `${instanceUrl}/api/sf/update-social-post`,
    data: data,
  })
    .then(res => {
      console.log(res);
      if (res.status === 201) {
        if (postStatus == 1) {
          const requestProfile = {
            acctId: res.data.payload.social.acctId,
            accessToken: res.data.payload.social.accessToken,
            email: res.data.payload.social.email,
            postId: res.data.payload.social.postId,
            lastRecordSize: 0,
            requestRecordSize: 1,
            postList: [],
            parentId: 0,
            requestRecordSizeComment: 3,
            commentRequestLength: 0,
            visibleIds: '1,2',
            groupId: groupId,
          };
          dispatch(getSocialPostByPostId(requestProfile));
        }
        const response = {
          token: res.data.token,
          post: res.data.payload.social,
          postId: res.data.payload.postId,
          postStatus: postStatus,
        };
        dispatch(POST_UPDATE_SUCCESS(response));
      } else {
        dispatch(POST_CREATE_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const deleteSocialPost = ({ acctId, email, accessToken, postId }) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    postId,
  };
  dispatch(POST_CREATING_DEFAULT());
  dispatch(POST_LOADING_CREATE());
  // console.log(body);
  await axios({
    method: 'PUT',
    headers: header,
    url: `${instanceUrl}/api/sf/delete-social-post`,
    data: body,
  })
    .then(res => {
      // console.log(res);
      if (res.status === 201) {
        const response = {
          postId: res.data.payload.postId,
          token: res.data.token,
        };
        dispatch(POST_DELETE_SUCCESS(response));
      } else {
        dispatch(POST_CREATE_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const resetPostCreate = () => async dispatch => {
  dispatch(POST_CREATING_DEFAULT());
};

export const resetHashtagMatch = () => async dispatch => {
  console.log('trigger');
  dispatch(HASHTAG_MATCH_DEFAULT());
};

export const getHashtagMatch = ({ acctId, email, accessToken, hashId }) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    hashId,
  };
  // console.log(body);
  dispatch(HASHTAG_MATCH_LOADING());
  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/sf/get-social-hashtag-by-hash-id`,
    params: body,
  })
    .then(res => {
      // console.log(res);
      if (res.status === 201) {
        const response = {
          hashtagMatch: res.data.payload.hashtag,
          token: res.data.token,
        };
        dispatch(HASHTAG_MATCH_SUCCESS(response));
      } else {
        dispatch(HASHTAG_MATCH_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const createFollowHashtag = ({ acctId, email, accessToken, hashId }) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    hashId,
  };
  // console.log(body);
  await axios({
    method: 'POST',
    headers: header,
    url: `${instanceUrl}/api/sf/create-follow-hashtag`,
    data: body,
  })
    .then(res => {
      // console.log(res);
      if (res.status === 201) {
        const response = {
          token: res.data.token,
          followId: res.data.payload.social.followId,
          createdDate: res.data.payload.social.createdDate,
        };
        dispatch(HASHTAG_FOLLOW_SUCCESS(response));
      } else {
        dispatch(HASHTAG_FOLLOW_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const deleteFollowHashtag = ({ followId, acctId, email, accessToken }) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    followId,
  };
  await axios({
    method: 'PUT',
    headers: header,
    url: `${instanceUrl}/api/sf/delete-follow-hashtag`,
    data: body,
  })
    .then(res => {
      if (res.status === 201) {
        const response = {
          followId,
          token: res.data.token,
        };
        dispatch(HASHTAG_FOLLOW_DELETE(response));
      } else {
        dispatch(HASHTAG_FOLLOW_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const createHidePost = ({
  acctId,
  email,
  accessToken,
  postId,
  authorId,
  reasonId,
  statusId,
}) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    postId,
    authorId,
    reasonId,
    statusId,
  };
  // console.log(body);
  await axios({
    method: 'POST',
    headers: header,
    url: `${instanceUrl}/api/sf/create-hide-post`,
    data: body,
  })
    .then(res => {
      // console.log(res);
      if (res.status === 201) {
        const response = {
          token: res.data.token,
          hideId: res.data.payload.social.hideId,
          createdDate: res.data.payload.social.createdDate,
        };
        dispatch(POST_HIDE_SUCCESS(response));
      } else {
        dispatch(POST_HIDE_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const resetHidePost = () => async dispatch => {
  dispatch(POST_HIDE_RESET());
};

export const deleteHidePost = ({ hideId, acctId, email, accessToken }) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    hideId,
  };
  await axios({
    method: 'PUT',
    headers: header,
    url: `${instanceUrl}/api/sf/delete-hide-post`,
    data: body,
  })
    .then(res => {
      if (res.status === 201) {
        const response = {
          hideId,
          token: res.data.token,
        };
        dispatch(POST_HIDE_DELETE(response));
      } else {
        dispatch(POST_HIDE_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const createFollowProfile = ({ acctId, email, accessToken, followAcctId }) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    followAcctId,
  };
  // console.log(body);
  await axios({
    method: 'POST',
    headers: header,
    url: `${instanceUrl}/api/um/create-follow-profile`,
    data: body,
  })
    .then(res => {
      // console.log(res);
      if (res.status === 201) {
        const response = {
          token: res.data.token,
          followId: res.data.payload.user.flpfId,
          createdDate: res.data.payload.user.createdDate,
        };
        dispatch(PROFILE_FOLLOW_SUCCESS(response));
      } else {
        dispatch(PROFILE_FOLLOW_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const deleteFollowProfile = ({ followId, acctId, email, accessToken }) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    followId,
  };
  await axios({
    method: 'PUT',
    headers: header,
    url: `${instanceUrl}/api/um/delete-follow-profile`,
    data: body,
  })
    .then(res => {
      if (res.status === 201) {
        const response = {
          followId,
          token: res.data.token,
        };
        dispatch(PROFILE_FOLLOW_DELETE(response));
      } else {
        dispatch(PROFILE_FOLLOW_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};
export default socialFeedSlice.reducer;
