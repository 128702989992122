import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	addFavouriteProfile,
	removeFavouriteProfile,
} from "configurations/redux/userSlice.js";

import { makeStyles } from "@material-ui/core/styles";

import Tooltip from "@material-ui/core/Tooltip";

import Button from "components/CustomButtons/Button.js";

import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js";
const useStyles = makeStyles(profilePageStyle);

export default function FavoriteProfileButton(profileId) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const favouriteProfile = useSelector((state) => state.user.favouriteProfile);
	const favId = useSelector((state) => state.user.favId);
	const userStatus = useSelector((state) => state.auth.user);
	const addFavProfile = () => {
		const requestProfile = {
			email: userStatus.email,
			acctId: userStatus.acctId,
			profileId: profileId.profileId,
			accessToken: localStorage.getItem("token"),
		};
		console.log(requestProfile);
		dispatch(addFavouriteProfile(requestProfile));
	};
	console.log(profileId.profileId);
	const deleteFavProfile = () => {
		const requestProfile = {
			email: userStatus.email,
			acctId: userStatus.acctId,
			favId: favId,
			accessToken: localStorage.getItem("token"),
		};
		console.log(requestProfile);
		dispatch(removeFavouriteProfile(requestProfile));
	};
	return (
		<React.Fragment>
			{favouriteProfile ? (
				<div className={classes.follow}>
					<Tooltip
						id="tooltip-top"
						title="Unsave This Profile"
						placement="top"
						classes={{ tooltip: classes.tooltip }}
					>
						<Button
							justIcon
							round
							color="rose"
							className={classes.followButton}
							onClick={deleteFavProfile}
						>
							<FavoriteIcon className={classes.followIcon} />
						</Button>
					</Tooltip>
				</div>
			) : (
				<div className={classes.follow}>
					<Tooltip
						id="tooltip-top"
						title="Save This Profile"
						placement="top"
						classes={{ tooltip: classes.tooltip }}
					>
						<Button
							justIcon
							round
							color="rose"
							className={classes.followButton}
							onClick={addFavProfile}
						>
							<FavoriteBorderIcon className={classes.followIcon} />
						</Button>
					</Tooltip>
				</div>
			)}
		</React.Fragment>
	);
}
