import React from "react";
import dialogStyle from "assets/jss/material-kit-pro-react/views/pageStyle/ProfileViewPage/dialogStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { updateConnect } from "configurations/redux/userSlice.js";
import { updateConnectionStatus } from "configurations/redux/manageConnectionSlice.js";

const useStyles = makeStyles(dialogStyle);

export default function CancelConnectionRequstDialog({ open, onClose }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const userStatus = useSelector((state) => state.auth.user);
	const extension = useSelector((state) => state.editProfileModal.extension);

	const handleSubmit = () => {
		const requestProfile = {
			receiverAcctId: extension.receiverAcctId,
			acctId: userStatus.acctId,
			email: userStatus.email,
			connMessageDesc: "",
			connId: extension.connId,
			connStatusId: 4,

			// ipAddr: localStorage.getItem("ipAddr"), //clientIp,
			accessToken: localStorage.getItem("token"),
		};
		console.log(requestProfile);
		if (extension.source && extension.source === "CONNECTION_PAGE") {
			dispatch(updateConnectionStatus(requestProfile));
		} else {
			dispatch(updateConnect(requestProfile));
		}

		onClose();
	};
	return (
		<Dialog
			open={open}
			onClose={(event, reason) => {
				if (reason && reason === "backdropClick") return;
				onClose();
			}}
		>
			<DialogTitle>Cancel Connection Request?</DialogTitle>
			<DialogContent>
				<DialogContentText>
					You will not be able to request to this user for 24 hours
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<Button onClick={handleSubmit}>Confirm</Button>
			</DialogActions>
		</Dialog>
	);
}
