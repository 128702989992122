import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Card from 'components/Card/Card.js';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';

import messageDashboardStyle from 'assets/jss/material-kit-pro-react/views/pageStyle/MessagePage/messagePageStyle.js';
import {
  messageConversationInfo,
  messageConversationByReadIdReset,
} from 'configurations/redux/messageConversationSlice.js';
import { messageNewChatroomInfo } from 'configurations/redux/messageChatroomSlice.js';
import { messageChatroomListInfo } from 'configurations/redux/messageChatroomListSlice.js';

import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import MessageConversationHeader from '../MessagePage/MessageConversationHeader.js';
import MessageConversationDefault from '../MessagePage/MessageConversationDefault.js';
import MessageConversationDetails from '../MessagePage/MessageConversationDetails.js';
import { isMobile, isMobileOnly } from 'react-device-detect';
import Backdrop from '../../Component/Backdrop.js';

const useStyles = makeStyles(messageDashboardStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SearchResultSection() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const searchResult = useSelector(state => state.messageConversation.searchResult);
  const userStatus = useSelector(state => state.auth.user);
  const readId = useSelector(state => state.messageConversation.readId);
  const chatroomId = useSelector(state => state.messageChatroom.chatroomId);
  const receiptList = useSelector(state => state.messageChatroomList.searchResult);
  const isLoadingConversation = useSelector(state => state.messageConversation.isLoadingConversation);
  const isLoading = useSelector(state => state.messageChatroomList.isLoading);
  const searchChatroom = useSelector(state => state.messageChatroom.searchResult);
  const [openChatroom, setOpenChatroom] = React.useState(false);
  const activeBizId = useSelector(state => state.messageChatroom.activeBizId);

  const loadReceiptList = () => {
    if (receiptList.length > 0) {
      console.log(receiptList);
      const requestProfile = {
        acctId: userStatus.acctId,
        email: userStatus.email,
        accessToken: localStorage.getItem('token'),
        lastRecordSize: 0,
        requestRecordSize: 15,
        chatroomId: receiptInfo[0].chatroomId,
        keyword: '',
      };
      dispatch(messageChatroomListInfo(requestProfile));
      dispatch(messageConversationInfo(requestProfile));
    } else if (chatroomId > 0) {
      const requestProfile = {
        acctId: userStatus.acctId,
        email: userStatus.email,
        accessToken: localStorage.getItem('token'),
        lastRecordSize: 0,
        requestRecordSize: 15,
        chatroomId: chatroomId,
        keyword: '',
      };
      dispatch(messageChatroomListInfo(requestProfile));
      dispatch(messageConversationInfo(requestProfile));
    }
  };

  React.useEffect(() => {
    if (chatroomId > 0) {
      loadReceiptList();
    }
  }, [chatroomId]);

  const receiptInfo = receiptList.filter(item => item.acctId != userStatus.acctId);

  console.log(receiptList);

  React.useEffect(() => {
    if (readId != undefined && readId > 0 && searchResult.length > 0) {
      var elmnt = document.getElementById(searchResult[searchResult.length - 1].conversationId);
      if (elmnt != null) {
        dispatch(messageConversationByReadIdReset());
        elmnt.scrollIntoView();
        window.scrollTo(0, 0);
      }
    }
  }, [readId, searchResult]);

  React.useEffect(() => {
    if (isMobile) {
      if (receiptList.length > 0) {
        setOpenChatroom(true);
      } else {
        setOpenChatroom(false);
      }
    }
  }, [receiptList]);

  const handleCloseChatroom = () => {
    setOpenChatroom(false);
    loadNewChatroom();
  };

  const loadNewChatroom = () => {
    const requestProfile = {
      acctId: userStatus.acctId,
      accessToken: localStorage.getItem('token'),
      email: userStatus.email,
      searchResult: searchChatroom,
      bizId: activeBizId,
    };
    // console.log(requestProfile);

    dispatch(messageNewChatroomInfo(requestProfile));
  };

  return (
    <div>
      {isMobileOnly ? (
        <Dialog fullScreen open={openChatroom} onClose={handleCloseChatroom} TransitionComponent={Transition}>
          <AppBar className={classes.appbar} id="messageAppBar">
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleCloseChatroom} aria-label="close">
                <ArrowBackIosIcon />
              </IconButton>
              {receiptInfo.length > 0 && <MessageConversationHeader>{receiptInfo[0]}</MessageConversationHeader>}
            </Toolbar>
          </AppBar>
          <AppBar className={classes.appbarBottom}>
            {receiptInfo.length > 0 && <MessageConversationDetails>{receiptInfo[0]}</MessageConversationDetails>}
          </AppBar>
          {receiptInfo.length == 0 && isLoading == false ? <MessageConversationDefault /> : null}
        </Dialog>
      ) : (
        <div id="messageConversationSection">
          {receiptInfo.length > 0 ? <MessageConversationHeader>{receiptInfo[0]}</MessageConversationHeader> : null}
          {receiptInfo.length > 0 ? <MessageConversationDetails>{receiptInfo[0]}</MessageConversationDetails> : null}
          {!isMobileOnly && receiptInfo.length == 0 && isLoading == false ? (
            <MessageConversationDefault type="default" />
          ) : null}
          {!isMobileOnly && receiptInfo.length == 0 && isLoading == true ? (
            <MessageConversationDefault type="loading" />
          ) : null}
        </div>
      )}
      {/* {isLoadingConversation && <Backdrop />} */}
    </div>
  );
}
