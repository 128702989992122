import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import registrationPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/RegistrationPage/registrationPageStyle.js";

import registrationImage from "assets/img/Registration_Image.png";
import Header from "views/Component/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import NavPills from "components/NavPills/NavPills.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { Hidden } from "@material-ui/core";

import CandidateRegisterForm from "views/Page/RegistrationPage/CandidateRegisterForm.js";
import CorporateRegisterForm from "views/Page/RegistrationPage/CorporateRegisterForm.js";
import { connect } from "react-redux";
import {
	errorCleanUp,
	socialLoginAction,
} from "configurations/redux/authSlice";
import Button from "components/CustomButtons/Button.js";
import Footer from "views/Component/Footer.js";
import { gaPageView } from "configurations/configurations";
import MetatagHelmet from "views/Component/MetatagHelmet";

const useStyles = makeStyles(registrationPageStyle);

const RegistrationPage = ({}) => {
	const classes = useStyles();
	const history = useHistory();

	const dispatch = useDispatch();
	const message = useSelector((state) => state.auth.message);
	const isError = useSelector((state) => state.auth.isError);
	const isSocial = useSelector((state) => state.auth.isSocial);
	const isRegistered = useSelector((state) => state.auth.isRegistered);
	const socialAutoLogin = useSelector((state) => state.auth.socialAutoLogin);
	const registeredEmail = useSelector((state) => state.auth.registeredEmail);

	const userStatus = useSelector((state) => state.auth.user);

	/* React.useEffect(() => {
		dispatch(errorCleanUp());
	}, []); */
	React.useEffect(() => {
		gaPageView(window.location.pathname);
		if (isError) {
			dispatch(errorCleanUp());
		}
	}, []);
	React.useEffect(() => {
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;
	});
	React.useEffect(() => {
		if (userStatus && !isError) {
			history.push("/community-page");
		}
	}, []);
	React.useEffect(() => {
		setTimeout(() => {
			if (isRegistered && socialAutoLogin.email != undefined) {
				const requestBody = {
					email: socialAutoLogin.email,
					password: "",
					acctTypeId: 3,
					socialAcctId: socialAutoLogin.socialAcctId,
				};
				dispatch(socialLoginAction(requestBody));
			}
		}, 1000);
	}, [isRegistered, socialAutoLogin]);

	const [candidateShow, setCandidateShow] = React.useState(true);
	const [corporateShow, setCorporateShow] = React.useState(false);

	const clickCandidateTab = () => {
		setCandidateShow(true);
		setCorporateShow(false);
	};
	const clickCorporateTab = () => {
		setCorporateShow(true);
		setCandidateShow(false);
	};

	return (
		<>
			<MetatagHelmet
				metaTag={{
					title: "Signup",
					description: "Signup",
					url: window.location.href,
				}}
			/>
			<Header />
			<div className={classes.body}>
				<div
					className={classes.pageHeader}
					style={{
						backgroundSize: "cover",
						backgroundPosition: "top center",
					}}
				>
					<div className={classes.container}>
						<GridContainer
							justifyContent="center"
							alignContent="center"
							alignItems="center"
						>
							<GridItem xs={12} sm={12} md={7}>
								<div className={classes.infoArea}>
									<h2 className={classes.leftInfoTitle}>
										Sign Up Now To Begin Your Journey
									</h2>
									<h5 className={classes.leftInfoDescription}>
										You are just one step away from a whole new career journey
									</h5>
									<Hidden smDown>
										<img
											src={registrationImage}
											className={classes.infoImage}
										/>
									</Hidden>
								</div>
							</GridItem>
							<GridItem xs={12} sm={12} md={5}>
								<Card className={classes.registrationCard}>
									<CardBody className={classes.registrationCardBody}>
										{/* <GridContainer justifyContent="center"> */}
										{isRegistered ? (
											isError ? (
												<SnackbarContent
													message={
														isError == 1 ? (
															isSocial ? (
																<span>
																	<b>
																		Your account is already linked to this
																		social login account. Please sign up with
																		another method.
																	</b>
																</span>
															) : (
																<span>
																	<b>This Email had been registered before.</b>
																</span>
															)
														) : (
															<span>
																<b>
																	Registration has failed, Please check your
																	account detail
																</b>
															</span>
														)
													}
													close
													color="opptyWarning"
													icon="info_outline"
													className={classes.snackbarMobile}
												/>
											) : (
												<SnackbarContent
													message={
														socialAutoLogin.length > 0 ? (
															<span>
																<b>
																	Registration successful! Kindly login to enjoy
																	our service
																</b>
															</span>
														) : (
															<b>
																{/* Hello! Thank you for signing up; we would like
																you to have your email verified. You can find
																our verification email in your inbox or spam
																mail. */}
																Thank you for signing up! We've sent an email to{" "}
																{registeredEmail} to verify your email address.
																Please click on the link provided in the email
																to activate your account.
															</b>
														)
													}
													close
													autoHideDuration={1000}
													color="opptyInfo"
													icon="info_outline"
													className={classes.snackbarMobile}
												/>
											)
										) : null}
										{/* <GridContainer
											justifyContent="center"
											alignContent="center"
											alignItems="center"
										>
											<GridItem
												xs={6}
												sm={6}
												md={6}
												className={classes.gridPaddingRight}
											>
												<Button
													fullWidth
													round
													className={
														candidateShow
															? classes.selectedTabButton
															: classes.tabButton
													}
													onClick={() => {
														clickCandidateTab();
														dispatch(errorCleanUp());
													}}
												>
													<b>User Sign-up</b>
												</Button>
											</GridItem>
											<GridItem
												xs={6}
												sm={6}
												md={6}
												className={classes.gridPaddingLeft}
											>
												<Button
													fullWidth
													round
													onClick={() => {
														clickCorporateTab();
														dispatch(errorCleanUp());
													}}
													className={
														corporateShow
															? classes.selectedTabButton +
															  " " +
															  classes.selectedTabButtonRight
															: classes.tabButton + " " + classes.tabButtonRight
													}
												>
													<b>Corporate Sign-up</b>
												</Button>
											</GridItem>
										</GridContainer> */}

										<GridContainer>
											<GridItem>
												<div
													className={classes.tabBorder}
													style={{ borderRadius: "18px 18px 18px 18px" }}
												>
													{candidateShow && <CandidateRegisterForm />}
													{/* {corporateShow && <CorporateRegisterForm />} */}
												</div>
											</GridItem>
										</GridContainer>
										{/* <GridItem
											xs={12}
											sm={12}
											md={12}
											//className={classes.formMarginAuto}
										>
											<NavPills
												alignCenter
												color="oppty"
												//className={classes.formMarginAuto}
												tabs={[
													{
														tabButton: "Candidate Register",
														tabContent: <CandidateRegisterForm />,
													},
													{
														tabButton: "Corporate Register",
														tabContent: <CorporateRegisterForm />,
													},
												]}
											/>
										</GridItem> */}
										{/* </GridContainer> */}
									</CardBody>
								</Card>
								<GridContainer justifyContent="center">
									<GridItem xs={12} md={10} sm={9}>
										<h5
											className={
												classes.infoDescription + " " + classes.textCenter
											}
										>
											Already have an Account?
											<Link to="/login-page">
												<span
													className={
														classes.infoTitle + " " + classes.textCenter
													}
												>
													{" "}
													Login Here
												</span>
											</Link>
										</h5>
									</GridItem>
								</GridContainer>
							</GridItem>
						</GridContainer>
					</div>
				</div>
				<Footer />
			</div>
		</>
	);
};
const mapStateToProps = (state) => ({
	isRegistered: state.auth.isRegistered,
	message: state.auth.message,
});

export default connect(mapStateToProps)(RegistrationPage);
