import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import axios from "axios";
import { Link } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import {
	instanceUrl,
	encryptData,
	countMonthYear,
	getMonthData,
} from "configurations/configurations.js";
import { createJobApplication } from "configurations/redux/jobManagementSlice.js";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Chip } from "@material-ui/core";
import InfoArea from "components/InfoArea/InfoArea.js";
import DialogActions from "@material-ui/core/DialogActions";
import Hidden from "@material-ui/core/Hidden";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

import Close from "@material-ui/icons/Close";
import SchoolIcon from "@material-ui/icons/School";
import WorkIcon from "@material-ui/icons/Work";

import defaultAvatar from "assets/img/candidate_avatar.jpeg";
import NoActivityStatusModal from "../NoActivityStatusModal/NoActivityStatusModal";
import Pagination from "../../Component/Pagination.js";
import PrivacySettingModal from "../ProfilePage/ProfileSection/PrivacySettingModal.js";

import searchJobPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/SearchJobPage/searchJobPageStyle.js";
import { isDesktop } from "react-device-detect";
import LockIcon from "@material-ui/icons/Lock";
import IconLibrary from "views/Component/IconLibrary.js";
import EditModal from "views/Component/ProfileView/EditProfile/EditModal";
import {
	activateEditType,
	deactivateEditType,
} from "configurations/redux/editProfileModalSlice";
const useStyles = makeStyles(searchJobPageStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});
export default function ApplyProfileModal({ open, onClose }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const userStatus = useSelector((state) => state.auth.user);
	const [countryList, setCountryList] = React.useState([]);
	const [areaCodeList, setAreaCodeList] = React.useState([]);
	const [locationList, setLocationList] = React.useState([]);
	const [studyFieldList, setStudyFieldList] = React.useState([]);
	const [educationLevelList, setEducationLevelList] = React.useState([]);

	const [currentEduPage, setCurrentEduPage] = React.useState(1);
	const [eduPerPage, setEduPerPage] = React.useState(1);
	const [currentExpPage, setCurrentExpPage] = React.useState(1);
	const [expPerPage, setExpPerPage] = React.useState(1);

	const isUpdated = useSelector((state) => state.user.isUpdated);
	const isLoading = useSelector((state) => state.user.isLoading);
	const user = useSelector((state) => state.user);
	const selectedJobPost = useSelector(
		(state) => state.jobManagement.selectedJobPost
	);
	const privacySetting = useSelector((state) => state.user.privacySetting);
	const experienceRecord = useSelector((state) => state.user.experienceRecords);
	const educationRecord = useSelector((state) => state.user.educationRecords);
	const [changePrivacyModal, setChangePrivacyModal] = React.useState(false);
	const monthDataList = getMonthData();

	const closeChangePrivacyModal = () => {
		setChangePrivacyModal(false);
	};

	const indexOfLastEdu = currentEduPage * eduPerPage;
	const indexOfFirstEdu = indexOfLastEdu - eduPerPage;
	const currentEdu = educationRecord
		? educationRecord.slice(indexOfFirstEdu, indexOfLastEdu)
		: null;

	const indexOfLastExp = currentExpPage * expPerPage;
	const indexOfFirstExp = indexOfLastExp - expPerPage;
	const currentExp = experienceRecord
		? experienceRecord.slice(indexOfFirstExp, indexOfLastExp)
		: null;
	const eduPaginate = (pageNumber) => {
		setCurrentEduPage(pageNumber);
	};
	const expPaginate = (pageNumber) => {
		setCurrentExpPage(pageNumber);
	};
	const imageClasses = classNames(
		classes.imgRaised,
		classes.imgRoundedCircle,
		classes.imgProfile,
		classes.profilePictureModal
	);

	const showProfile =
		privacySetting &&
		privacySetting
			.filter((item) => {
				return item.settingName === "showProfile";
			})
			.map((item) => {
				return item.settingValue === 1;
			})[0];
	const showAboutMe =
		privacySetting &&
		privacySetting
			.filter((item) => {
				return item.settingName === "showAboutMe";
			})
			.map((item) => {
				return item.settingValue === 1;
			})[0];
	const showEdu =
		privacySetting &&
		privacySetting
			.filter((item) => {
				return item.settingName === "showEdu";
			})
			.map((item) => {
				return item.settingValue === 1;
			})[0];
	const showExp =
		privacySetting &&
		privacySetting
			.filter((item) => {
				return item.settingName === "showExp";
			})
			.map((item) => {
				return item.settingValue === 1;
			})[0];

	const showHeadline =
		privacySetting &&
		privacySetting
			.filter((item) => {
				return item.settingName === "showHeadline";
			})
			.map((item) => {
				return item.settingValue === 1;
			})[0];
	const showProfilePic =
		privacySetting &&
		privacySetting
			.filter((item) => {
				return item.settingName === "showProfilePic";
			})
			.map((item) => {
				return item.settingValue === 1;
			})[0];

	const applyJob = () => {
		const currentDate = new Date();
		const currentApplDate =
			currentDate.getFullYear() +
			"-" +
			(currentDate.getMonth() + 1) +
			"-" +
			currentDate.getDate();
		const requestProfile = {
			acctId: userStatus.acctId,
			accessToken: localStorage.getItem("token"),
			email: userStatus.email,
			applicantId: userStatus.acctId,
			jobPostId: selectedJobPost[0].jobPostId,
			applStatusId: 1,
			applDate: currentApplDate,
		};

		dispatch(createJobApplication(requestProfile));
	};

	const experienceCard = currentExp
		? currentExp.map((experienceData) => {
				return (
					<div key={experienceData.expRecId}>
						<Card className={classes.cardInfo}>
							<CardBody>
								<GridContainer
									justifyContent="space-between"
									alignContent="center"
									alignItems="center"
								>
									<GridItem className={classes.cardPadding}>
										<div className={classes.cardDetail}>
											<div className={classes.descriptionContainer}>
												<h4 className={classes.jobTitle}>
													{experienceData.title}
												</h4>
												<h5 className={classes.smallDescription}>
													{experienceData.organization}
												</h5>
											</div>

											<div className={classes.descriptionContainer}>
												<span
													className={
														classes.mediumDescription +
														" " +
														classes.grayFontCard
													}
												>
													<b>
														{monthDataList
															.filter(
																(month) =>
																	month.monthId ===
																	experienceData.startDate.substring(5, 7)
															)
															.map((month) => month.monthName)}{" "}
														{experienceData.startDate.substring(0, 4)}
														{" - "}
														{experienceData.currentInProgressFlag !== 1 &&
															monthDataList
																.filter(
																	(month) =>
																		month.monthId ===
																		experienceData.endDate.substring(5, 7)
																)
																.map((month) => month.monthName)}{" "}
														{experienceData.currentInProgressFlag === 1
															? `Present`
															: experienceData.endDate.substring(0, 4)}
													</b>
												</span>
												<span
													className={
														classes.mediumDescription +
														" " +
														classes.mobileSpan +
														" " +
														classes.grayFontCard
													}
												>
													<b>
														{countMonthYear(
															experienceData.startDate,
															experienceData.endDate
														) &&
															" • " +
																countMonthYear(
																	experienceData.startDate,
																	experienceData.endDate
																)}
													</b>
												</span>

												<p
													className={
														classes.mediumDescription +
														" " +
														classes.grayFontCard
													}
												>
													<b>
														{countryList
															.filter((item) => {
																return (
																	item.countryId === experienceData.countryId
																);
															})
															.map((item) => {
																return item.countryName;
															})}
													</b>
												</p>
											</div>
										</div>
									</GridItem>
									<GridItem className={classes.cardPadding}>
										<div>
											{experienceData.achievement ? (
												<div>
													<Divider />
													<p
														className={
															classes.description +
															" " +
															classes.descriptionMargin
														}
													>
														{experienceData.achievement}
													</p>
												</div>
											) : null}
										</div>

										<div>
											{experienceData.description ? (
												<div>
													{experienceData.achievement ? null : <Divider />}
													<p
														className={
															classes.description +
															" " +
															classes.descriptionMargin
														}
													>
														{experienceData.description}
													</p>
												</div>
											) : null}
										</div>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</div>
				);
		  })
		: null;
	const educationCard = currentEdu
		? currentEdu.map((educationData) => {
				return (
					<div key={educationData.eduRecId}>
						<Card className={classes.cardInfo}>
							<CardBody>
								<GridContainer
									justifyContent="space-between"
									alignContent="center"
									alignItems="center"
								>
									<GridItem className={classes.cardPadding}>
										<div className={classes.cardDetail}>
											<h4 className={classes.titleDescription}>
												{educationData.school}
											</h4>
											<div className={classes.descriptionContainer}>
												<h5 className={classes.smallDescription}>
													{educationLevelList
														.filter((item) => {
															return item.eduLvlId === educationData.eduLvlId;
														})
														.map((item) => {
															return item.eduLvlName;
														})}
													{` of `}

													{educationData.courseName}
												</h5>
												<h5 className={classes.mediumDescription}>
													<b>
														{/* {studyFieldList
															.filter((item) => {
																return (
																	item.studyFieldId ===
																	educationData.studyFieldId
																);
															})
															.map((item) => {
																return item.studyFieldName;
															})}{" "} */}
														{educationData.studyField}
													</b>
												</h5>
											</div>

											<div className={classes.descriptionContainer}>
												<span
													className={
														classes.smallDescription +
														" " +
														classes.grayFontCard
													}
												>
													<b>
														{monthDataList
															.filter(
																(month) =>
																	month.monthId ===
																	educationData.startDate.substring(5, 7)
															)
															.map((month) => month.monthName)}{" "}
														{educationData.startDate.substring(0, 4)}
														{" - "}
														{educationData.currentInProgressFlag !== 1 &&
															monthDataList
																.filter(
																	(month) =>
																		month.monthId ===
																		educationData.endDate.substring(5, 7)
																)
																.map((month) => month.monthName)}{" "}
														{educationData.currentInProgressFlag === 1
															? `Present`
															: educationData.endDate.substring(0, 4)}
													</b>
												</span>

												<span
													className={
														classes.smallDescription +
														" " +
														classes.mobileSpan +
														" " +
														classes.grayFontCard
													}
												>
													{" "}
													<b>
														{countMonthYear(
															educationData.startDate,
															educationData.endDate
														) &&
															" • " +
																countMonthYear(
																	educationData.startDate,
																	educationData.endDate
																)}
													</b>
												</span>

												<p
													className={
														classes.smallDescription +
														" " +
														classes.grayFontCard
													}
												>
													<b>
														{countryList
															.filter((item) => {
																return (
																	item.countryId === educationData.countryId
																);
															})
															.map((item) => {
																return item.countryName;
															})}
													</b>
												</p>
											</div>

											{/* <span className={classes.divider}>{"  |  "}</span> */}
										</div>
									</GridItem>

									<GridItem className={classes.cardPadding}>
										<div>
											{educationData.achievement ? (
												<div>
													<Divider />
													<p
														className={
															classes.description +
															" " +
															classes.descriptionMargin
														}
													>
														{educationData.achievement}
													</p>
												</div>
											) : null}
										</div>

										<div>
											{educationData.description ? (
												<div
													className={
														classes.description +
														" " +
														classes.descriptionMargin
													}
												>
													{educationData.achievement ? null : <Divider />}
													<p
														className={
															classes.description +
															" " +
															classes.descriptionMargin
														}
													>
														{educationData.description}
													</p>
												</div>
											) : null}
										</div>

										<div>
											{educationData.extraCurricular ? (
												<div>
													{educationData.achievement ? null : educationData.description ? null : (
														<Divider />
													)}
													<p
														className={
															classes.description +
															" " +
															classes.descriptionMargin
														}
													>
														{educationData.extraCurricular}
													</p>
												</div>
											) : null}
										</div>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</div>
				);
		  })
		: null;

	React.useEffect(() => {
		let mounted = true;
		let CancelToken = axios.CancelToken;
		const source = CancelToken.source();
		const fetchCountryList = async () => {
			try {
				const res = await axios.get(`${instanceUrl}/api/md/get-all-country`, {
					cancelToken: source.token,
				});
				setCountryList(res.data.payload);
			} catch (error) {
				if (axios.isCancel(error)) {
					//console.log("unmount caused");
				} else {
					throw error;
				}
			}
		};
		const fetchAreaList = async () => {
			try {
				const res = await axios.get(`${instanceUrl}/api/md/get-all-area-code`, {
					cancelToken: source.token,
				});
				setAreaCodeList(res.data.payload);
			} catch (error) {
				if (axios.isCancel(error)) {
					//console.log("unmount caused");
				} else {
					throw error;
				}
			}
		};
		const fetchLocationList = async () => {
			try {
				const res = await axios.get(`${instanceUrl}/api/md/get-all-location`, {
					cancelToken: source.token,
				});
				setLocationList(res.data.payload);
			} catch (error) {
				if (axios.isCancel(error)) {
					//console.log("unmount caused");
				} else {
					throw error;
				}
			}
		};
		const fetchStudyFieldList = async () => {
			try {
				const res = await axios.get(
					`${instanceUrl}/api/md/get-all-study-field`,
					{
						cancelToken: source.token,
					}
				);
				setStudyFieldList(res.data.payload);
			} catch (error) {
				if (axios.isCancel(error)) {
					//console.log("unmount caused");
				} else {
					throw error;
				}
			}
		};
		const fetchEducationLevelList = async () => {
			try {
				const res = await axios.get(
					`${instanceUrl}/api/md/get-all-education-level`,
					{
						cancelToken: source.token,
					}
				);
				setEducationLevelList(res.data.payload);
			} catch (error) {
				if (axios.isCancel(error)) {
					//console.log("unmount caused");
				} else {
					throw error;
				}
			}
		};
		if (mounted) {
			fetchCountryList();
			fetchAreaList();
			fetchLocationList();
			fetchStudyFieldList();
			fetchEducationLevelList();
		}

		return () => {
			mounted = false;
			source.cancel();
		};
	}, []);

	const modalResize = () => {
		// var timer = setInterval(() => {
		var modalResize = document.getElementById("modalApply");
		if (modalResize) {
			// clearInterval(timer);
			var maxSize = window.innerHeight - 70 - 30 - 64;
			if (maxSize < 0) maxSize = 0;
			// if (modalResize.clientHeight < maxSize)
			modalResize.style.height = maxSize + "px";
			// else modalResize.style.height = "auto";
			// } else {
			// 	setTimeout(() => {
			// 		clearInterval(timer);
			// 	}, 3000);
		}
		// }, 300);
		// return () => clearInterval(timer);
	};

	// React.useEffect(() => {
	// 	window.addEventListener("resize", modalResize);
	// });

	React.useEffect(() => {
		if (open) {
			if (document.readyState === "complete") {
				setTimeout(() => {
					modalResize();
				}, 500);
			}
			window.addEventListener("resize", modalResize);
			return function cleanup() {
				window.removeEventListener("resize", modalResize);
			};
		}
	}, [open]);

	// React.useEffect(() => {
	// 	if (open) modalResize();
	// 	else {
	// 		try {
	// 			window.removeEventListener("resize");
	// 		} catch (error) {}
	// 	}
	// 	return function cleanup() {
	// 		try {
	// 			window.removeEventListener("resize");
	// 		} catch (error) {}
	// 	};
	// }, [open]);

	return (
		<div>
			<Dialog
				classes={{
					root: classes.modalRoot,
					paper: classes.modal + " " + classes.modalJobView,
				}}
				open={open}
				// keepMounted
				// TransitionComponent={Transition}
				onClose={(event, reason) => {
					if (reason && reason == "backdropClick") return;
					onClose();
				}}
				// disableBackdropClick
				aria-labelledby="classic-modal-slide-title"
				aria-describedby="classic-modal-slide-description"
			>
				<DialogTitle
					id="signup-modal-slide-title"
					disableTypography
					className={classes.modalTitleEdit}
				>
					<Button
						simple
						className={classes.modalCloseButton}
						key="close"
						aria-label="Close"
						onClick={onClose}
					>
						<IconLibrary.Close />
						{/* <Close className={classes.modalClose} /> */}
					</Button>
					<h3 className={classes.title + " " + classes.titleAlign}>
						Profile Preview
					</h3>
				</DialogTitle>
				<div
					className={
						isDesktop
							? classes.modalBodyHeightJobDesktop +
							  " " +
							  classes.modalBackgroundRadius
							: classes.modalBodyHeightJob + " " + classes.modalBackgroundRadius
					}
					id="modalApply"
				>
					<DialogContent className={classes.modalBody}>
						<GridContainer>
							{isLoading ? (
								<GridItem xs={12} sm={12} md={2} className={classes.itemCenter}>
									<CircularProgress />
								</GridItem>
							) : (
								<React.Fragment>
									{isUpdated ? (
										<GridItem>
											<Card className={classes.cardFrame}>
												<CardBody className={classes.cardBody}>
													<GridContainer className={classes.verticalAligned}>
														<GridItem
															xs={3}
															sm={2}
															md={2}
															className={classes.itemCenter}
														>
															<Link
																className={classes.textDeco}
																target="_blank"
																to={{
																	pathname: `/view-profile/${encryptData(
																		user.acctId
																	)}`,
																}}
																color="transparent"
															>
																{showProfile ? (
																	showProfilePic ? (
																		<img
																			src={
																				user.profilePic
																					? user.profilePic
																					: defaultAvatar
																			}
																			alt="..."
																			className={imageClasses}
																		/>
																	) : (
																		<img
																			src={defaultAvatar}
																			alt="..."
																			className={imageClasses}
																		/>
																	)
																) : (
																	<img
																		src={defaultAvatar}
																		alt="..."
																		className={imageClasses}
																	/>
																)}
															</Link>
														</GridItem>
														<GridItem xs={9} sm={6} md={7}>
															{showProfile ? (
																<div>
																	<Link
																		className={classes.textDeco}
																		target="_blank"
																		to={{
																			pathname: `/view-profile/${encryptData(
																				user.acctId
																			)}`,
																		}}
																		color="transparent"
																	>
																		<h4
																			className={
																				classes.title + " " + classes.titleInfo
																			}
																		>
																			{user.profileRecord.firstName}
																			{user.profileRecord.middleName
																				? " " +
																				  user.profileRecord.middleName +
																				  " ,"
																				: null}{" "}
																			{user.profileRecord.surname}
																		</h4>
																	</Link>

																	<h5
																		className={
																			classes.title +
																			" " +
																			classes.titleLocation
																		}
																	>
																		{countryList
																			.filter((item) => {
																				return (
																					item.countryId ===
																					user.profileRecord.countryId
																				);
																			})
																			.map((item) => {
																				return item.countryName;
																			})}
																		{user.profileRecord.locationId !== "0" &&
																		user.profileRecord.locationId
																			? ", "
																			: " "}
																		{locationList
																			.filter((item) => {
																				return (
																					item.locationId ===
																					user.profileRecord.locationId
																				);
																			})
																			.map((item) => {
																				return item.locationName;
																			})}
																	</h5>
																	<Hidden xsDown>
																		{showHeadline ? (
																			<h5
																				className={
																					classes.description +
																					" " +
																					classes.descInfo +
																					" " +
																					classes.descInfoMobile
																				}
																			>
																				{user.profileRecord.headline}
																			</h5>
																		) : null}
																	</Hidden>
																</div>
															) : (
																<div>
																	<Link
																		className={classes.textDeco}
																		target="_blank"
																		to={{
																			pathname: `/view-profile/${encryptData(
																				user.acctId
																			)}`,
																		}}
																		color="transparent"
																	>
																		<h4
																			className={
																				classes.title + " " + classes.titleInfo
																			}
																		>
																			{user.profileRecord.firstName}
																			{user.profileRecord.middleName
																				? " " +
																				  user.profileRecord.middleName +
																				  " ,"
																				: null}{" "}
																			{user.profileRecord.surname}
																		</h4>
																	</Link>
																</div>
															)}
														</GridItem>
														<Hidden smUp>
															<GridItem xs={12} sm={false} md={false}>
																{showHeadline ? (
																	<h5
																		className={
																			classes.description +
																			" " +
																			classes.descInfo +
																			" " +
																			classes.descInfoMobile
																		}
																	>
																		{user.profileRecord.headline}
																	</h5>
																) : null}
															</GridItem>
														</Hidden>
														<GridItem xs={12} sm={4} md={3}>
															<GridContainer>
																<GridItem
																	xs={6}
																	sm={12}
																	md={12}
																	className={classes.cardBody}
																>
																	<Button
																		onClick={() => {
																			const response = {
																				type: "EDIT_PROFILE",
																				tabType: "PRIVACY_SETTING",
																			};
																			dispatch(activateEditType(response));
																		}}
																		fullWidth
																		round
																		color="opptyWrapBorder"
																		className={classes.buttonModal}
																	>
																		<b>Change Privacy</b>
																	</Button>
																</GridItem>
																<GridItem
																	xs={6}
																	sm={12}
																	md={12}
																	className={classes.cardBody}
																>
																	<Link
																		className={classes.textDeco}
																		target="_blank"
																		to={{
																			pathname: `/view-profile/${encryptData(
																				user.acctId
																			)}`,
																		}}
																		color="transparent"
																	>
																		<Button
																			fullWidth
																			round
																			color="opptyWrap"
																			className={classes.buttonModal}
																		>
																			<b>View Profile</b>
																		</Button>
																	</Link>
																</GridItem>
															</GridContainer>
														</GridItem>
													</GridContainer>
												</CardBody>
											</Card>
											{selectedJobPost[0].appliedJobPost && (
												<Card className={classes.cardFrame}>
													<CardBody className={classes.gridCardPadding}>
														<GridContainer>
															<GridItem>
																<SnackbarContent
																	message={
																		<span>
																			<b>Successfully Applied!</b>
																		</span>
																	}
																	close
																	className={
																		({
																			root: classes.applySnackBar,
																		},
																		classes.snackbarMobile)
																	}
																	color="opptyInfo"
																	icon="info_outline"
																/>
															</GridItem>
														</GridContainer>
													</CardBody>
												</Card>
											)}
											{showProfile ? (
												<React.Fragment>
													{/**About Me Component */}
													{user.profileRecord.aboutMe && (
														<Card className={classes.cardFrame}>
															<CardBody>
																<GridContainer>
																	<GridItem>
																		<h5
																			className={
																				classes.title + " " + classes.titleInfo
																			}
																		>
																			About Me
																		</h5>
																		{showAboutMe ? (
																			<h5 className={classes.description}>
																				{user.profileRecord.aboutMe}
																			</h5>
																		) : (
																			<div className={classes.privacyTitle}>
																				<h5 className={classes.description}>
																					<LockIcon />
																					<br />
																					Your 'About Me' section is currently
																					private. To make it visible, please
																					change your privacy settings{" "}
																					<a
																						className={classes.opptyColorFont}
																						href=""
																						onClick={(e) => {
																							e.preventDefault();
																							const response = {
																								type: "EDIT_PROFILE",
																								tabType: "PRIVACY_SETTING",
																							};
																							dispatch(
																								activateEditType(response)
																							);
																						}}
																					>
																						here
																					</a>
																					.
																				</h5>
																			</div>
																		)}
																	</GridItem>
																</GridContainer>
															</CardBody>
														</Card>
													)}
													{/**Experiecne Section */}
													{experienceRecord && experienceRecord.length > 0 ? (
														<React.Fragment>
															<Card className={classes.cardFrame}>
																<CardBody>
																	<GridContainer
																		className={classes.verticalAligned}
																	>
																		<GridItem xs={12} sm={6}>
																			<h5
																				className={
																					classes.title +
																					" " +
																					classes.titleInfo
																				}
																			>
																				Work Experiences
																			</h5>
																		</GridItem>
																		{showExp && (
																			<GridItem xs={12} sm={6}>
																				<Hidden xsDown>
																					<div
																						className={
																							classes.paginationAligned
																						}
																					>
																						<Pagination
																							totalPosts={
																								experienceRecord.length
																							}
																							postsPerPage={expPerPage}
																							paginate={expPaginate}
																						/>
																					</div>
																				</Hidden>
																			</GridItem>
																		)}
																	</GridContainer>
																	{showExp ? (
																		<div>
																			{experienceCard}
																			<Hidden
																				smUp
																				className={classes.verticalAligned}
																			>
																				<div
																					className={classes.paginationAligned}
																				>
																					<Pagination
																						totalPosts={experienceRecord.length}
																						postsPerPage={expPerPage}
																						paginate={expPaginate}
																					/>
																				</div>
																			</Hidden>
																		</div>
																	) : (
																		<div className={classes.privacyTitle}>
																			<h5 className={classes.description}>
																				<LockIcon />
																				<br />
																				Your 'Experience' section is currently
																				private. To make it visible, please
																				change your privacy settings{" "}
																				<a
																					className={classes.opptyColorFont}
																					href=""
																					onClick={(e) => {
																						e.preventDefault();
																						const response = {
																							type: "EDIT_PROFILE",
																							tabType: "PRIVACY_SETTING",
																						};
																						dispatch(
																							activateEditType(response)
																						);
																					}}
																				>
																					here
																				</a>
																				.
																			</h5>
																		</div>
																	)}
																</CardBody>
															</Card>
														</React.Fragment>
													) : (
														<Card className={classes.cardFrame}>
															<CardBody>
																<h5
																	className={
																		classes.title + " " + classes.titleInfo
																	}
																>
																	No Experience Records
																</h5>
															</CardBody>
														</Card>
													)}
													{/**Education Component */}
													{educationRecord && educationRecord.length > 0 ? (
														<React.Fragment>
															<Card className={classes.cardFrame}>
																<CardBody>
																	<GridContainer>
																		<GridItem>
																			<GridContainer
																				className={classes.verticalAligned}
																			>
																				<GridItem xs={12} sm={6}>
																					<h5
																						className={
																							classes.title +
																							" " +
																							classes.titleInfo
																						}
																					>
																						Education
																					</h5>
																				</GridItem>
																				{showEdu && (
																					<GridItem xs={12} sm={6}>
																						<Hidden xsDown>
																							<div
																								className={
																									classes.paginationAligned
																								}
																							>
																								<Pagination
																									totalPosts={
																										educationRecord.length
																									}
																									postsPerPage={eduPerPage}
																									paginate={eduPaginate}
																								/>
																							</div>
																						</Hidden>
																					</GridItem>
																				)}
																			</GridContainer>
																			{showEdu ? (
																				<div>
																					{educationCard}
																					<Hidden
																						smUp
																						className={classes.verticalAligned}
																					>
																						<div
																							className={
																								classes.paginationAligned
																							}
																						>
																							<Pagination
																								totalPosts={
																									educationRecord.length
																								}
																								postsPerPage={eduPerPage}
																								paginate={eduPaginate}
																							/>
																						</div>
																					</Hidden>
																				</div>
																			) : (
																				<div className={classes.privacyTitle}>
																					<h5 className={classes.description}>
																						<LockIcon />
																						<br />
																						Your 'Education' section is
																						currently private. To make it
																						visible, please change your privacy
																						settings{" "}
																						<a
																							className={classes.opptyColorFont}
																							href=""
																							onClick={(e) => {
																								e.preventDefault();
																								const response = {
																									type: "EDIT_PROFILE",
																									tabType: "PRIVACY_SETTING",
																								};
																								dispatch(
																									activateEditType(response)
																								);
																							}}
																						>
																							here
																						</a>
																						.
																					</h5>
																				</div>
																			)}
																		</GridItem>
																	</GridContainer>
																</CardBody>
															</Card>
														</React.Fragment>
													) : (
														<Card className={classes.cardFrame}>
															<CardBody>
																<h5
																	className={
																		classes.title + " " + classes.titleInfo
																	}
																>
																	No Education Records
																</h5>
															</CardBody>
														</Card>
													)}

													{/**HashTag Component */}
													{user.hashtags[0] && (
														<Card className={classes.cardFrame}>
															<CardBody>
																<GridContainer>
																	<GridItem>
																		<h5
																			className={
																				classes.title + " " + classes.titleInfo
																			}
																		>
																			Skills And Attributes
																		</h5>
																		{showProfile ? (
																			user.hashtags.map((item, index) => {
																				return (
																					<Chip
																						key={index}
																						label={
																							<p
																								className={
																									classes.hashtagDescription
																								}
																							>
																								{item.hashtag}
																							</p>
																						}
																						className={classes.chip}
																						color="secondary"
																					/>
																				);
																			})
																		) : (
																			<div className={classes.privacyTitle}>
																				<h5 className={classes.description}>
																					<LockIcon />
																					<br />
																					Your 'Profile' is currently private.
																					To make it visible, please change your
																					privacy settings{" "}
																					<a
																						className={classes.opptyColorFont}
																						href=""
																						onClick={(e) => {
																							e.preventDefault();
																							const response = {
																								type: "EDIT_PROFILE",
																								tabType: "PRIVACY_SETTING",
																							};
																							dispatch(
																								activateEditType(response)
																							);
																						}}
																					>
																						here
																					</a>
																					.
																				</h5>
																			</div>
																		)}
																	</GridItem>
																</GridContainer>
															</CardBody>
														</Card>
													)}
												</React.Fragment>
											) : (
												<React.Fragment>
													<Card
														className={
															// classes.privacyTrueCard +
															" " +
															classes.cardFrame +
															" " +
															classes.privacyTitle
														}
													>
														<CardBody>
															<h5 className={classes.title}>
																<LockIcon />
																<br />
																Your 'Profile' is currently private. To make it
																visible, please change your privacy settings{" "}
																<a
																	className={classes.opptyColorFont}
																	href=""
																	onClick={(e) => {
																		e.preventDefault();
																		const response = {
																			type: "EDIT_PROFILE",
																			tabType: "PRIVACY_SETTING",
																		};
																		dispatch(activateEditType(response));
																	}}
																>
																	here
																</a>
																.
															</h5>
														</CardBody>
													</Card>
												</React.Fragment>
											)}
											{/* <PrivacySettingModal
												open={changePrivacyModal}
												onClose={closeChangePrivacyModal}
											/> */}
											<EditModal />
										</GridItem>
									) : (
										<NoActivityStatusModal />
									)}
								</React.Fragment>
							)}
						</GridContainer>
					</DialogContent>
				</div>
				<DialogActions
					className={classes.modalFooterPrivacy + " " + classes.modalFooter}
				>
					{showProfile ? (
						<Button
							color="oppty"
							disabled={selectedJobPost[0].appliedJobPost ? true : false}
							round
							onClick={() => applyJob()}
							className={classes.buttonApply}
						>
							<b>{selectedJobPost[0].appliedJobPost ? "Applied" : "Apply"}</b>
						</Button>
					) : (
						<Button
							color="oppty"
							round
							disabled={true}
							onClick={() => applyJob()}
							className={classes.buttonApply}
						>
							<b>Apply</b>
						</Button>
					)}
				</DialogActions>
			</Dialog>
		</div>
	);
}
