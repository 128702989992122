const sponsoredAdStyles = () => ({

  postActions: {
    display: 'flex',
    justifyContent: "space-evenly",
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTop: '1px solid #F1F1F5',
    margin: '15px 0 0',
    padding: '10px 0',

    '& > button': {
      height: "40px",
      cursor: "pointer",
    },
  },

  learnMoreBtn: {
    boxSizing: "border-box",
    border: "solid 1px #6F52ED",
    borderRadius: "10px",
    backgroundColor: "#6F52ED",
    width: "150px",
    color: "#fff",
    fontSize: "15px",
    fontWeight: 600,

    '&:hover': {
      backgroundColor: "",
      color: "",
    },
  },

  contactBtn: {
    boxSizing: "border-box",
    border: "solid 1px #6F52ED",
    borderRadius: "10px",
    backgroundColor: "#fff",
    width: "150px",
    color: "#6F52ED",
    fontSize: "15px",
    fontWeight: 600,
    cursor: "pointer",

    '&:hover': {
      backgroundColor: "",
      color: "",
    },
  },

  regularAction: {
    border: "none",
    borderRadius: "4px",
    backgroundColor: "transparent",
    padding: "0 10px",
    color: "#44444F",
    fontSize: '14px',
    fontWeight: 400,

    '&:hover': {
      backgroundColor: "#0000000a",
      transition: "background-color 250ms",
    },

    '& > img': {
      marginRight: "5px",
      width: "25px",
    }
  },
})

export default sponsoredAdStyles;