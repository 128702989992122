import { opptyDesignColor } from "assets/jss/material-kit-pro-react";
import {
	grayColor,
	primaryColor,
	title,
	blackColor,
	whiteColor,
	container,
} from "assets/jss/material-kit-pro-react.js";

const LeftSidebarStyle = (theme) => ({
	LeftSidebarGridItem: {
		flexBasis: "19%",
		maxWidth: "250px",
	},
	LeftSidebar_box: {
		position: "sticky",
		overflow: "auto",
		top: "10px",
		marginTop: "10px",
		backgroundColor: whiteColor,
		padding: "20px 20px",
		minHeight: "150px",
		maxHeight: "fit-content",
		/* 		height: "82.7vh", */
		borderRadius: "18px",
		scrollbarWidth: "none",
		"&::-webkit-scrollbar": {
			display: "none",
		},
		"@media (max-height: 830px)": {
			height: "calc(100vh - 120px)",
			overflow: "scroll",
		},
	},
	profileCard: {
		backgroundColor: "#FAFAFB",
		borderRadius: "10px",
		padding: "10px 8px",
		display: "flex",
		alignItems: "center",
		marginBottom: "20px",

		"& h6": {
			fontSize: "14px",
			color: "#171725",
			margin: "0",
		},

		"& span": {
			fontSize: "12px",
			color: "#92929D",
		},
	},
	profileCard_image: {
		flex: "0 0 32px",
		maxWidth: "32px",
		marginRight: "10px",
		maxHeight: "32px",
		overflow: "hidden",
		borderRadius: "50%",
	},
	navLinkItems: {
		"&:hover": {
			"& svg": {
				color: "#6F52ED !important",
			},
			color: "#6F52ED !important",
		},
	},
	currentLinkItems: {
		"& svg": {
			color: "#6F52ED !important",
		},
		color: "#6F52ED !important",
	},
	currentLinkItems: {
		"& span": {
			color: "#6F52ED !important",
		},
		color: "#6F52ED !important",
	},
	scroll: {
		overflow: "scroll",
	},
	sideBarMenuBx: {
		margin: "0 0 15px",

		"& h6": {
			fontSize: "13px",
			color: "#92929D",
			margin: "0",
		},

		"& ul": {
			padding: "0",
			margin: "0",
			listStyle: "none",
		},

		"& .MuiListItem-gutters": {
			fontSize: "13px",
			color: "#171725",
			padding: "10px 0",
		},
		"& .MuiListItemIcon-root": {
			minWidth: "30px",
			marginRight: "15px",
			justifyContent: "center",
		},
	},
	sideBarGroupHead: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",

		"& .MuiButton-label": {
			fontSize: "13px",
			color: "#6F52ED",
		},
	},

	hashTagsContainer: {
		display: "flex",
		flexWrap: "wrap",
		color: "#171725",

		"& > div": {
			marginTop: "5px",
			marginRight: "5px",
			border: "solid 1px #979797",
			borderRadius: "8px",
			padding: "0 10px",
			cursor: "pointer",
			transition: "0.2s ease-in-out",
		},

		"& > div:hover": {
			backgroundColor: "#f1efff",
		},
	},
});
export default LeftSidebarStyle;
