import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
// @material-ui/icons
// import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.js";

import style from "assets/jss/material-kit-pro-react/modalStyle.js";
import IconLibrary from "./IconLibrary";

const useStyles = makeStyles(style);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function NoRecordModal({ open, onClose, recordType }) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalLoader,
        }}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        // disableBackdropClick
        onClose={onClose}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            onClick={onClose}
          >
            {" "}
            <IconLibrary.Close />
          </Button>
          <h4>
            <b>{recordType} Not Available</b>
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <DialogContentText id="alert-dialog-slide-description">
            {" "}
            {recordType} is currently unavailable.
          </DialogContentText>
          {/* <h5 className={classes.modalTitle}>
						{" "}
						{recordType} is currently unavailable.
					</h5> */}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={onClose} color="opptyWrap" round>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
