import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
	departmentViewRoadmap,
	viewRoadmapRoleByRoleId,
} from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Department_Capital_Market from "assets/img/Career_Roadmap/Banking_Investment_Banking/Department_Capital_Market_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentCapitalMarket() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Capital_Market;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "cm",
				shape: "poly",
				coords: [70,387,62,392,58,394,55,400,53,406,52,446,54,452,58,456,62,460,71,464,175,464,183,460,188,457,192,452,194,445,194,406,192,400,187,394,181,390,175,388,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "430",
				shape: "poly",
				coords: [293,386,286,388,282,391,279,395,277,403,278,450,279,456,282,460,288,463,294,464,482,464,490,462,494,459,496,456,498,450,498,402,497,394,493,389,486,387,481,386,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "431",
				shape: "poly",
				coords: [598,386,588,388,584,392,582,396,581,402,580,446,582,456,586,458,589,462,598,464,783,465,793,462,798,459,800,455,802,448,802,403,800,394,796,390,791,388,785,386,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "432",
				shape: "poly",
				coords: [899,387,891,390,888,392,885,398,884,404,884,449,886,456,889,460,895,463,903,464,1044,464,1054,461,1058,458,1060,453,1061,447,1061,404,1060,395,1056,390,1050,388,1044,386,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "433",
				shape: "poly",
				coords: [1163,386,1151,388,1146,392,1144,397,1142,404,1142,448,1143,455,1146,460,1152,464,1159,464,1301,464,1311,463,1316,460,1318,454,1319,447,1319,404,1316,393,1312,389,1306,387,1300,386,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "434",
				shape: "poly",
				coords: [1416,386,1408,389,1404,391,1402,396,1401,405,1402,449,1403,457,1407,461,1413,464,1420,464,1559,465,1570,462,1574,458,1577,454,1578,447,1578,404,1577,395,1572,390,1566,387,1560,387,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "435",
				shape: "poly",
				coords: [1678,387,1669,388,1664,392,1662,396,1660,402,1660,449,1662,457,1666,461,1670,463,1678,465,1823,464,1829,461,1832,458,1834,454,1836,447,1836,401,1833,394,1830,390,1824,387,1818,387,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "cm") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo);
			dispatch(viewRoadmapRoleByRoleId(requestInfo));

			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
