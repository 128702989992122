import {
	container,
	cardTitle,
	title,
	mlAuto,
	mrAuto,
	main,
	containerFluid,
	whiteColor,
	grayColor,
	blackColor,
	// defaultFont,
	cardDetail,
	mainRaised,
	hexToRgb,
	opptyDesignColor,
} from "assets/jss/material-kit-pro-react.js";

import imagesStyle from "assets/jss/material-kit-pro-react/imagesStyles.js";
import customSelectStyle from "assets/jss/material-kit-pro-react/customSelectStyle.js";
import customCheckboxRadioSwitchStyle from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.js";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.js";
import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.js";
import hashTagStyle from "../../componentStyle/hashTagStyle.js";

const corporateJobManagementPageStyle = (theme) => ({
	...imagesStyle,
	...customCheckboxRadioSwitchStyle,
	...modalStyle(theme),
	...customSelectStyle,
	...tooltipsStyle,
	...hashTagStyle,
	...hexToRgb,

	cardDetail: {
		...cardDetail,
		whiteSpace: "pre-line",
	},
	...containerFluid,

	name: {
		marginTop: "-80px",
	},
	main: {
		...main,
	},
	title: {
		...title,
		position: "relative",
		marginTop: "25px",
		minHeight: "32px",
		color: blackColor + "!important",
		fontSize: "20px",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		textDecoration: "none",
		"& svg": {
			width: "32px",
			height: "32px",
			marginRight: "20px",
			marginTop: "10px",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "16px",
			marginTop: "5px",
			marginBottom: "0",
		},
	},

	jobInfo: {
		marginTop: "-80px",
	},
	modalProfileEdit: {
		maxWidth: "1000px",
		width: "100%",
		backgroundColor: "#f2f3f7",
		"& $modalHeader": {
			paddingTop: "0",
		},
		"& $modalTitle": {
			textAlign: "center",
			width: "100%",
			marginTop: "0.625rem",
		},
		"& $modalBody": {
			// padding: "25px",
			paddingBottom: "0",
			paddingTop: "0",
		},
		"& $modalFooter": {
			marginBottom: "1.125rem",
		},
	},
	jobContainer: {
		...containerFluid,
		maxWidth: "1400px",
	},
	textCenter: {
		textAlign: "center",
	},
	gridItem: {
		...mlAuto,
		...mrAuto,
	},
	mlAuto,
	// autoCompleteSelect: {
	// 	marginTop: "12px !important",
	// },
	left: {
		float: "left!important",
		display: "block",
	},
	iconMargin: {
		marginTop: "12px !important",
		marginRight: "10px !important",
	},
	inlineBlock: {
		display: "inline-block",
		padding: "0px",
		width: "auto",
	},
	list: {
		marginBottom: "0",
		padding: "0",
		marginTop: "0",
	},
	block: {
		color: "inherit",
		padding: "0.9375rem",
		fontWeight: "500",
		fontSize: "12px",
		textTransform: "uppercase",
		borderRadius: "3px",
		textDecoration: "none",
		position: "relative",
		display: "block",
	},
	itemCenter: {
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		[theme.breakpoints.down("xs")]: {
			paddingRight: "0",
		},
	},
	right: {
		padding: "15px 0",
		margin: "0",
		float: "right",
	},
	profile: {
		textAlign: "center",
		"& img": {
			// maxWidth: "160px",
			width: "100%",
			margin: "20px auto 0",
			transform: "translate3d(0, -70%, 0)",
			[theme.breakpoints.down("xs")]: {
				width: "auto",
				height: "120px",
			},
		},
	},
	paperClass: {
		borderRight: "1px solid rgba(" + hexToRgb(blackColor) + ", 0.35)",
	},
	gridItemMargin: {
		margin: "10px 0px",
		[theme.breakpoints.down("sm")]: {
			margin: "15px 0px",
		},
		[theme.breakpoints.down("xs")]: {
			margin: "0px 0px",
		},
	},
	paginationPadding: {
		paddingTop: "15px",
		paddingBottom: "15px",
		align: "center",
	},
	modalProfileView: {
		maxWidth: "900px",
		width: "100%",
		minHeight: "900px",
	},
	// privacyTrueCard: {
	// 	height: "150px",
	// 	alignItems: "center",
	// 	justifyContent: "center",
	// 	display: "flex",
	// },
	privacyTrueCard: {
		minHeight: "150px",
		"& h5": {
			marginTop: "50px !important",
			textAlign: "center",
		},
	},
	privacyTitle: {
		"& h5": {
			margin: "0 !important",
			padding: "0 4vw",
			color: "#58586A!important",
			fontWeight: "500",
			fontSize: "16px",
			textAlign: "center",
			marginBottom: "15px!important",
		},
		[theme.breakpoints.down("sm")]: {
			fontSize: "14px",
		},
	},
	container: {
		...container,
		"@media (min-width: 576px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 768px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 992px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 1200px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 1400px)": {
			maxWidth: "80vw",
		},
		// "@media (min-width: 576px)": {
		// 	maxWidth: "100%",
		// },
		// "@media (min-width: 768px)": {
		// 	maxWidth: "100%",
		// },
		// "@media (min-width: 992px)": {
		// 	maxWidth: "100%",
		// },
		// "@media (min-width: 1200px)": {
		// 	maxWidth: "90vw",
		// },
		// "@media (min-width: 1400px)": {
		// 	maxWidth: "90vw",
		// },
	},
	cardFrame: {
		borderRadius: "18px",
		boxShadow: "none",
		[theme.breakpoints.down("xs")]: {
			marginTop: "10px",
			marginBottom: "10px",
		},
	},
	cardJobSummary: {
		top: "121px",
		position: "sticky",
		paddingBottom: "0.1px",
	},
	cardClick: {
		cursor: "pointer",
		"&:hover,&:focus": {
			//color: whiteColor,
			//backgroundColor: grayColor[0],
			boxShadow:
				"0 9px 0px 0px white, 0 -9px 0px 0px white, 12px 0 15px -4px rgba(" +
				hexToRgb(grayColor[0]) +
				", 0.5), -12px 0 15px -4px rgba(" +
				hexToRgb(grayColor[0]) +
				", 0.5)",
		},
	},
	cardApplicantClick: {
		cursor: "pointer",
		"&:hover,&:focus": {
			//color: whiteColor,
			//backgroundColor: grayColor[0],
			boxShadow:
				"0 14px 26px -12px rgba(" +
				hexToRgb(grayColor[0]) +
				", 0.42), 0 4px 23px 0px rgba(" +
				hexToRgb(blackColor) +
				", 0.12), 0 8px 10px -5px rgba(" +
				hexToRgb(grayColor[0]) +
				", 0.2)",
		},
	},
	mainRaised: {
		...mainRaised,
		marginTop: "100px", //"20vh",
		background: "#fff",
		boxShadow: "none",
		paddingTop: "1px",
		paddingLeft: "30px",
		paddingRight: "30px",
		margin: "0px",
		minHeight: "1450px",
		"@media (max-width: 576px)": {
			marginTop: "100px",
			paddingLeft: "10px",
			paddingRight: "10px"
		},
	},
	jobViewMargin: {
		marginTop: "220px", //"24vh",
	},
	backGroundImage: {
		width: "100%",
		height: "100%",
	},
	applicationStatus: {
		// color: "#54c154 !important",
		textTransform: "uppercase",
		fontSize: "14px",
		fontWeight: "500 !important",
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
			minHeight: "18px",
			margin: "0 0 10px 0!important",
		},
	},
	statusSubmitted: {
		color: "#1AD598",
	},
	statusReviewing: {
		color: "#FFC300",
	},
	statusRejected: {
		color: "#fb8082",
	},
	statusUnsuccessful: {
		color: blackColor[0],
	},
	statusWithdraw: {
		color: "#687bfe",
	},
	statusShortlisted: {
		color: "#D278E7",
	},
	logoImage: {
		maxWidth: "150px",
		maxHeight: "150px",
		height: "100%",
		width: "100%",
		margin: "15px 0px",
	},
	companyModalLogo: {
		maxWidth: "90px",
		maxHeight: "90px",
		// height: "150px",
		// width: "150px",
	},
	rightGrid: {
		textAlign: "right",
		[theme.breakpoints.down("xs")]: {
			textAlign: "left",
		},
	},
	noDecoText: {
		textDecoration: "none",
		color: blackColor,
	},
	buttonCenter: {
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		[theme.breakpoints.down("xs")]: {
			display: "block",
		},
	},
	buttonColor: {
		color: opptyDesignColor + "!important",
		[theme.breakpoints.down("xs")]: {
			paddingRight: "36px",
		},
	},
	selectedTabButton: {
		height: "60px",
		backgroundColor: opptyDesignColor,
		borderRadius: "8px",
		color: whiteColor,
		fontSize: "16px",
		boxShadow:
			"0 2px 2px 0 rgba(" +
			hexToRgb(opptyDesignColor[0]) +
			", 0.14), 0 3px 1px -2px rgba(" +
			hexToRgb(opptyDesignColor[0]) +
			", 0.2), 0 1px 5px 0 rgba(" +
			hexToRgb(opptyDesignColor[0]) +
			", 0.12)",
		"&:hover,&:focus": {
			backgroundColor: opptyDesignColor,

			boxShadow:
				"0 14px 26px -12px rgba(" +
				hexToRgb(opptyDesignColor[0]) +
				", 0.42), 0 4px 23px 0px rgba(" +
				hexToRgb(opptyDesignColor[0]) +
				", 0.12), 0 8px 10px -5px rgba(" +
				hexToRgb(opptyDesignColor[0]) +
				", 0.2)",
		},
		[theme.breakpoints.down("sm")]: {
			fontSize: "14px",
			whiteSpace: "normal",
			height: "50px",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
			whiteSpace: "normal",
			height: "55px",
		},
	},
	tabButton: {
		height: "60px",
		backgroundColor: "#f2f3f7",
		border: "#99B2C6 2px solid",
		borderRadius: "8px",
		fontSize: "16px",
		color: "#99B2C6",
		boxShadow:
			"0 2px 2px 0 rgba(" +
			hexToRgb("#99B2C6") +
			", 0.14), 0 3px 1px -2px rgba(" +
			hexToRgb("#99B2C6") +
			", 0.2), 0 1px 5px 0 rgba(" +
			hexToRgb("#99B2C6") +
			", 0.12)",
		"&:hover,&:focus": {
			backgroundColor: whiteColor,
			color: "#99B2C6",

			boxShadow:
				"0 14px 26px -12px rgba(" +
				hexToRgb("#99B2C6") +
				", 0.42), 0 4px 23px 0px rgba(" +
				hexToRgb("#99B2C6") +
				", 0.12), 0 8px 10px -5px rgba(" +
				hexToRgb("#99B2C6") +
				", 0.2)",
		},
		[theme.breakpoints.down("sm")]: {
			fontSize: "14px",
			whiteSpace: "normal",
			height: "50px",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
			whiteSpace: "normal",
			border: "#99B2C6 1px solid",
			height: "55px",
		},
	},
	description: {
		margin: "15px 0px !important",
		/* maxWidth: "600px", */
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		color: blackColor[0],
		fontWeight: "400",
		// whiteSpace: "pre-line",
		"& p": {
			margin: "15px 0px",
		},
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		overflow: "hidden",
	},
	cardImage: {
		width: "90px", //"130px",
		// height: "130px",
		overflow: "hidden",
		padding: "0",
		// borderRadius: "50%",
		/* boxShadow:
			"0 16px 38px -12px rgba(" +
			hexToRgb(blackColor) +
			", 0.56), 0 4px 25px 0px rgba(" +
			hexToRgb(blackColor) +
			", 0.12), 0 8px 10px -5px rgba(" +
			hexToRgb(blackColor) +
			", 0.2)", */
		///margin: "-50px auto 0",
		[theme.breakpoints.up("xs")]: {
			width: "80px",
			// height: "140px",
		},
	},
	cardImageCandidate: {
		borderRadius: "50%",
	},
	cardInfo: {
		backgroundColor: "#F2F3F7",
		borderRadius: "12px",
		boxShadow: "none",
	},
	smallDescription: {
		margin: "0 !important",
		color: blackColor[0],
		fontSize: "12px",
		fontWeight: "400",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
	},
	titleMargin: {
		marginBottom: "20px",
	},
	jobTitle: {
		...title,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		position: "relative",
		margin: "0 !important",

		textDecoration: "none",
		color: blackColor + "!important",
		"& svg": {
			width: "32px",
			height: "32px",
			marginRight: "20px",
			marginTop: "10px",
		},
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		overflow: "hidden",
		[theme.breakpoints.down("xs")]: {
			fontSize: "13px",
			minHeight: "13px",
		},
	},
	jobPostStatus: {
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		margin: "0",
		textDecoration: "none",
		color: grayColor[0] + "!important",
		fontWeight: "400",
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap",
		[theme.breakpoints.down("sm")]: {
			fontSize: "14px",
			minHeight: "18px",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
			minHeight: "18px",
		},
	},
	jobTime: {
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		margin: "0",
		textDecoration: "none",
		color: grayColor[0] + "!important",
		fontWeight: "400",
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap",
		fontSize: "14px",
		[theme.breakpoints.down("sm")]: {
			fontSize: "12px",
			minHeight: "18px",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "10px",
		},
	},
	companyDesc: {
		color: "#999",
		[theme.breakpoints.down("sm")]: {
			fontSize: "14px",
			margin: "0!important",
		},
	},
	gridHeight: {
		/* 	height: "90px", */
		padding: "20px 15px !important",
	},
	cardHeight: {
		height: "auto",
		borderRadius: "18px",
		boxShadow: "none",
	},
	jobDescription: {
		color: blackColor + "!important",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		fontWeight: "400",
		fontSize: "16px",
		whiteSpace: "pre-line",
		[theme.breakpoints.down("xs")]: {
			fontSize: "13px",
		},
	},
	candidateDescription: {
		textDecoration: "none",
		//marginTop: "30px !important",
		color: grayColor[0] + "!important",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		fontWeight: "400",
		fontSize: "16px",
		textAlign: "left",
		//padding: "0 !important",
	},
	infoArea: {
		padding: "10px 0 5px 0",
	},
	info: {
		paddingBottom: 0,
		paddingTop: 0,
		margin: "0",
		maxWidth: "1000px !important",
	},
	iconWrapper: {
		float: "left",
		width: "36px",
		height: "36px",
		color: grayColor[0] + "!important",
		marginTop: "10px",
		marginRight: "10px",
	},
	autoHeightBody: {
		height: "auto",
		backgroundColor: "#F2F3F7",
		overflowY: "auto",
	},
	modalCardBorder: {
		borderRadius: "18px",
		boxShadow: "none",
		padding: "10px 0px 10px 0px",
		marginBottom: "0px",
	},
	modalBackground: {
		background: "#fff",
		transition: "height 0.5s ease",
		overflowY: "scroll",
	},
	modalBackgroundTransition: {
		transition: "height 0.5s ease",
		overflowY: "scroll",
	},
	titleAlign: {
		textAlign: "center",
	},
	modalCardPadding: {
		padding: "0px",
	},
	jobCard: {
		borderRadius: "0px",
		margin: "0px",
		boxShadow: "none",
	},
	jobCardBorder: {
		borderTop: "2px solid #f2f3f7",
	},
	jobCardFirst: {
		"&:hover,&:focus": {
			borderRadius: "18px 18px 0 0",
		},
	},
	jobCardLast: {
		"&:hover,&:focus": {
			borderRadius: "0 0 18px 18px",
		},
	},
	jobCardOnly: {
		"&:hover,&:focus": {
			borderRadius: "18px",
		},
	},
	fontMuted: {
		fontSize: "13px",
		fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
		fontWeight: "500",
		lineHeight: "1.5em",
		color: "#6c757d",
	},
	fontLimit: {
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		textDecoration: "none",
		overflow: "hidden",
	},
	verticalAligned: {
		alignItems: "center",
		textAlign: "left",
	},
	hashtagDescription: {
		margin: "15px 0px !important",
		/* maxWidth: "600px", */
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		color: blackColor[0],
		fontWeight: "400",
		whiteSpace: "pre-line",
		"& p": {
			margin: "15px 0px",
		},
	},
	titleDescription: {
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		color: blackColor[0],
		fontSize: "16px",
		fontWeight: "700",
		margin: "0px !important",
		[theme.breakpoints.down("xs")]: {
			fontSize: "15px",
		},
	},
	mediumDescription: {
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		color: blackColor[0],
		fontSize: "14px",
		fontWeight: "500",
		margin: "0px !important",
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
			lineHeight: "1rem",
		},
	},
	descriptionContainer: {
		marginTop: "5px",
		marginBottom: "10px",
		"& p": {
			margin: "-5px 0px 0px 0px !important",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
			marginBottom: "0",
			"& p": {
				paddingBottom: "5px",
			},
		},
	},
	grayFont: {
		color: "#99B2C6 !important",
	},
	grayFontCard: {
		color: "#9F9FB7 !important",
	},
	opptyColorFont: {
		color: opptyDesignColor,
	},
	description: {
		margin: "15px 0px 0px 0px !important",
		/* maxWidth: "600px", */
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		color: blackColor[0],
		fontWeight: "500",
		whiteSpace: "pre-line",
		[theme.breakpoints.down("sm")]: {
			fontSize: "15px !important",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px !important",
			margin: "0!important",
		},
	},
	tagDescription: {
		marginBottom: "0px !improtant",
		/* maxWidth: "600px", */
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		color: blackColor[0],
		fontWeight: "500",
		whiteSpace: "pre-line",
		[theme.breakpoints.down("sm")]: {
			fontSize: "15px !important",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px !important",
			margin: "0!important",
		},
	},
	titleInfo: {
		minHeight: "1px",
		margin: "0!important",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		overflow: "hidden",
		[theme.breakpoints.down("sm")]: {
			fontSize: "15px",
			minHeight: "15px",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "13px",
			minHeight: "13px",
		},
	},
	titleLocation: {
		minHeight: "26px",
		margin: "0!important",
		color: "#959595!important",
		fontWeight: "normal",
		fontSize: "16px",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		overflow: "hidden",
		[theme.breakpoints.down("sm")]: {
			fontSize: "14px",
			minHeight: "18px",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
			minHeight: "18px",
		},
	},
	descInfo: {
		minHeight: "19px",
		margin: "0!important",
		// marginTop: "10px!important",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		overflow: "hidden",
		[theme.breakpoints.down("sm")]: {
			fontSize: "14px",
			minHeight: "18px",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
			minHeight: "18px",
		},
	},
	descInfoMobile: {
		[theme.breakpoints.down("xs")]: {
			padding: "10px 0 0 0",
			overflow: "visible",
			whiteSpace: "normal",
		},
	},
	paginationAligned: {
		float: "right",
	},
	gridPadding: {
		paddingLeft: "3px",
		paddingRight: "3px",
	},
	rightAligned: {
		float: "right",
	},
	cardSpace: {
		margin: "10px 0 0 0",
	},
	cardBody: {
		[theme.breakpoints.down("xs")]: {
			padding: "10px",
		},
	},
	buttonModal: {
		[theme.breakpoints.down("sm")]: {
			fontSize: "11px",
			padding: "12px 10px",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "10px",
			padding: "10px 8px",
		},
	},
	buttonBorder: {
		borderRadius: "8px",
		fontSize: "14px",
	},
	// buttonEdit: {
	// 	borderRadius: "8px",
	// 	padding: "5px 15px 5px 20px",
	// 	marginRight: "20px",
	// 	fontSize: "15px",
	// 	[theme.breakpoints.down("xs")]: {
	// 		// padding: "3px 0px 4px 4px",
	// 	},
	// },
	buttonEdit: {
		borderRadius: "7px",
		"& svg": {
			[theme.breakpoints.up("md")]: {
				marginRight: "10px",
			},
			[theme.breakpoints.down("sm")]: {
				marginRight: "0px",
				width: "20px!important",
			},
		},
		textTransform: "none",
		fontSize: "16px",
		padding: "3px 15px !important",
		[theme.breakpoints.down("sm")]: {
			padding: "5px !important",
		},
	},
	buttonRight: {
		borderRadius: "11px",
		/* padding: "5px 10px 5px 15px", */
		/* marginRight: "10px",
		[theme.breakpoints.down("xs")]: {
			padding: "0px 11px 1px 15px",
			borderRadius: "4px",
		}, */
		"& svg": {
			width: "25px !important",
			height: "25px !important",
			[theme.breakpoints.up("md")]: {
				marginRight: "10px",
			},
		},
		fontWeight: "700",
		textTransform: "none",
		fontSize: "15px",
		padding: "10px !important",
	},
	servicePeriod: {
		padding: "0px !important",
	},
	buttonDelete: {
		borderRadius: "7px",
		"& svg": {
			marginRight: "0px",
			width: "20px!important",
			// [theme.breakpoints.up("md")]: {
			// 	marginRight: "10px",
			// },
		},
		textTransform: "none",
		fontSize: "16px",
		padding: "5px !important",
		marginLeft: "10px",
		[theme.breakpoints.down("xs")]: {
			marginLeft: "2px",
		},
	},
	grayButton: {
		"& svg": {
			color: "#959595 !important",
			border: "2px solid #959595 !important",
			borderRadius: "5px",
		},
	},
	jobCreateTitle: {
		fontSize: "16px",
		margin: "5px 0px",
		fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
		fontWeight: "700",
		[theme.breakpoints.down("sm")]: {
			fontSize: "14px",
			margin: "3px 0px",
		},
	},
	jobViewTitle: {
		fontSize: "28px",
		margin: "5px 0px",
		fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
		fontWeight: "700",
		[theme.breakpoints.down("sm")]: {
			fontSize: "20px",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "16px",
		},
	},
	grayDescription: {
		color: "#99B2C6 !important",
		marginTop: "5px !important",
		fontSize: "13px !important",
	},
	itemRight: {
		display: "flex",
		justifyContent: "flex-end",
	},
	buttonIconMargin: {
		"& svg": {
			width: "28px !important",
			height: "28px !important",
			color: opptyDesignColor,
			border: "3px solid" + opptyDesignColor,
			padding: "1px",
			borderRadius: "5px",
			/* backgroundColor: "#000000", */
		},
		display: "flex",
		alignItems: "center",

		float: "right",
		/* margin: "5px 5px !important", */
		/* 	border: "3px solid" + opptyDesignColor, */

		padding: "0px !important",
	},
	buttonIconEdit: {
		"& svg": {
			color: whiteColor + "!important",
			marginRight: "10px",
			/* backgroundColor: "#000000", */
		},
		padding: "3px 10px !important",
		borderRadius: "9px",
		fontWeight: "700",
	},
	jobDetail: {
		"& span": {
			fontSize: "16px",
			marginRight: "10px",
			fontWeight: "700",
			[theme.breakpoints.down("xs")]: {
				fontSize: "13px",
			},
		},
		fontSize: "14px",
		fontWeight: "500",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
		},

		/* display: "flex",
		alignItems: "center", */
	},
	grayJobType: {
		color: "#959595 !important",
	},

	jobSummary: {
		textTransform: "uppercase",
		fontSize: "14px",
		margin: "5px 0px",
		fontWeight: "400",
		"& span": {
			fontWeight: "700",
			marginRight: "10px",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
		},
	},

	blueStatus: {
		color: "#687bfe !important",
	},
	greenStatus: {
		color: "#1ad598 !important",
	},
	redStatus: {
		color: "#fb8082 !important",
	},
	hashTagWidth: {
		[theme.breakpoints.down("xs")]: {
			minWidth: 0,
		},
	},
	buttonHeight: {
		[theme.breakpoints.down("xs")]: {
			height: "30px",
		},
	},
	editIcon: {
		position: "absolute",
		right: "10px",
		top: "6px",
	},
	jobSummaryTitle: {
		paddingRight: "80px",
		[theme.breakpoints.down("sm")]: {
			paddingRight: "20px",
		},
	},
	infoIcon: {
		boxShadow: "none",
		height: 0,
		padding: 0,
		width: "24px",
		minWidth: "24px",
	},
	addTagPos: {
		position: "absolute",
		right: "20px",
		bottom: "22px",
		"& svg": {
			color: "#959595 !important",
			border: "none !important",
			fontSize: "18px",
		},
	},
	noResultImage: {
		width: "75%",
		borderRadius: "12px",
	},
	noResultGrid: {
		textAlign: "-webkit-center",
	},
	quillEditor: {
		"& > .ql-container.ql-snow": {
			minHeight: "530px",
			height: "530px",
		},
	},
	quillEditorSingleRow: {
		"& > .ql-container.ql-snow": {
			minHeight: "300px",
			height: "300px",
		},
	},
});

export default corporateJobManagementPageStyle;
