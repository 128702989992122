import { createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import {
	instanceUrl,
	header,
	emptyCache,
} from "configurations/configurations.js";

const messageChatroomListSlice = createSlice({
	name: "messageChatroomList",
	initialState: {
		searchCount: 0,
		searchResult: [],
		searchParams: [],
		lastRecordSize: 0,
		requestRecordSize: 0,
		isLoading: false,
		isEnd: false,
		isSearching: false,
		isUpdated: true,
		chatroomId: 0,
	},
	reducers: {
		CHATROOM_LIST_LOADING: (state, action) => {
			state.isLoading = true;
		},
		CHATROOM_LIST_SEARCH_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.searchResult = [
				...state.searchResult,
				...action.payload.searchResult,
			];
			state.searchParams = action.payload.searchParams;
			state.lastRecordSize =
				state.lastRecordSize + action.payload.requestRecordSize;
			state.requestRecordSize = action.payload.requestRecordSize;
			state.isLoading = false;
			state.chatroomId = action.payload.chatroomId;
		},
		CHATROOM_LIST_SEARCH_END: (state, action) => {
			state.isEnd = true;
		},
		DEFAULT_CHATROOM_LIST_SEARCH: (state) => {
			state.searchResult = [];
			state.searchParams = [];
			state.lastRecordSize = 0;
			state.requestRecordSize = 0;
			state.isEnd = false;
			state.isSearching = true;
			state.chatroomId = 0;
		},
		CHATROOM_LIST_SEARCH_FAIL: (state) => {
			state.isUpdated = false;
		},
		CHATROOM_LIST_ADD_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.isUpdated = true;
			state.isLoading = false;
		},
		CHATROOM_LIST_UPDATE_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.isUpdated = true;
			state.isLoading = false;
		},
		CHATROOM_LIST_DELETE_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.isSearching = false;
			state.searchResult = action.payload.searchResult;
		},
	},
});

const {
	CHATROOM_LIST_LOADING,
	CHATROOM_LIST_SEARCH_SUCCESS,
	CHATROOM_LIST_SEARCH_END,
	DEFAULT_CHATROOM_LIST_SEARCH,
	CHATROOM_LIST_SEARCH_FAIL,
	CHATROOM_LIST_ADD_SUCCESS,
	CHATROOM_LIST_UPDATE_SUCCESS,
	CHATROOM_LIST_DELETE_SUCCESS,
} = messageChatroomListSlice.actions;

export const messageChatroomListInfo = ({
	acctId,
	accessToken,
	email,
	chatroomId,
	lastRecordSize,
	requestRecordSize,
	keyword,
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		email,
		chatroomId,
		lastRecordSize,
		requestRecordSize,
		keyword,
	};
	dispatch(CHATROOM_LIST_LOADING());
	if (lastRecordSize === 0) {
		dispatch(DEFAULT_CHATROOM_LIST_SEARCH());
	}
	// console.log(body)
	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/mm/get-chatroom-list`,
		params: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				const response = {
					searchResult: res.data.payload.chatroomUserList,
					lastRecordSize,
					requestRecordSize: res.data.payload.chatroomUserList.length,
					token: res.data.token,
					chatroomId: chatroomId,
				};
				dispatch(CHATROOM_LIST_SEARCH_SUCCESS(response));
				if (requestRecordSize > res.data.payload.chatroomUserList.length) {
					dispatch(CHATROOM_LIST_SEARCH_END());
				}
			} else if (res.status === 202) {
				dispatch(CHATROOM_LIST_SEARCH_END());
			} else {
				dispatch(CHATROOM_LIST_SEARCH_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const createChatroomList = ({
	acctId,
	accessToken,
	email,
	chatroomId,
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		email,
		chatroomId,
	};

	await axios({
		method: "POST",
		headers: header,
		url: `${instanceUrl}/api/mm/create-chatroom-list`,
		data: body,
	})
		.then((res) => {
			console.log(res);

			const response = {
				token: res.data.token,
			};
			if (res.status === 201) {
				dispatch(CHATROOM_LIST_ADD_SUCCESS(response));
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const updateChatroomList = ({
	acctId,
	accessToken,
	email,
	chatroomListId,
	chatroomListStatus,
}) => async (dispatch) => {
	dispatch(CHATROOM_LIST_LOADING());
	const body = {
		acctId,
		accessToken,
		email,
		chatroomListId,
		chatroomListStatus,
	};
	console.log(body);
	await axios({
		method: "PUT",
		headers: header,
		url: `${instanceUrl}/api/mm/update-chatroom-list-status`,
		data: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				const response = {
					chatroomListId,
					token: res.data.token,
				};
				dispatch(CHATROOM_LIST_UPDATE_SUCCESS(response));
			} else {
				dispatch(CHATROOM_LIST_SEARCH_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const deleteChatroomList = ({
	acctId,
	accessToken,
	email,
	chatroomListId,
	searchResult,
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		email,
		chatroomListId,
		searchResult,
	};
	await axios({
		method: "DELETE",
		headers: header,
		url: `${instanceUrl}/api/mm/delete-chatroom-list`,
		data: body,
	})
		.then((res) => {
			if (res.status === 201) {
				searchResult = searchResult.filter(
					(item) => item.chatroomListId != chatroomListId
				);
				const response = {
					searchResult,
					chatroomListId,
					token: res.data.token,
				};
				dispatch(CHATROOM_LIST_DELETE_SUCCESS(response));
			} else {
				dispatch(CHATROOM_LIST_SEARCH_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const messageChatroomListReset = () => async (dispatch) => {
	dispatch(DEFAULT_CHATROOM_LIST_SEARCH());
};

export default messageChatroomListSlice.reducer;
