import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import axios from "axios";
import { instanceUrl, encryptData } from "configurations/configurations";

const ForTestPurposePage = () => {
  const user = useSelector((state) => state.auth.user);
  const [profiles, setProfiles] = useState([]);

  // var profiless = [];
  useEffect(() => {
    const acctIds = [239, 235, 233, 220, 219, 218, 216, 212, 193, 186, 181, 153, 152, 151, 150, 143, 141, 140, 139, 138, 134]
    for (let i = 0; i < acctIds.length; i++) {
      axios.get(`${instanceUrl}/api/pm/get-non-corporate-profile?acctId=${acctIds[i]}&userId=${user.acctId}&email=${user.email}&ipAddr=${localStorage.getItem("ipAddr")}&accessToken=${localStorage.getItem('token')}`)
        .then((res) => {
          if (!res.data.error) {
            // profiless = [...profiless, res.data.payload.regularProfile]
            setProfiles([...profiles, res.data.payload.regularProfile])
          } else { console.log(res.data.message) }
        })
        .catch((err) => console.log(err))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <div>
      {profiles.map((profile) => {
        return (
          <div>
            <Link to={`/view-profile/${encryptData(profile.acctId)}`}>{" "}profile{" "}</Link>
          </div>
        )
      })}
    </div>
  )
}

export default ForTestPurposePage