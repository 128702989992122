import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
	instanceUrl,
	countMonthYear,
	getMonthData,
} from "configurations/configurations.js";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Tooltip from "@material-ui/core/Tooltip";
import Divider from "@material-ui/core/Divider";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Primary from "components/Typography/Primary.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import EditIcon from "@material-ui/icons/CreateOutlined";
import ClearIcon from "@material-ui/icons/DeleteOutlineOutlined";
import WorkIcon from "@material-ui/icons/Work";
import IconButton from "@material-ui/core/IconButton";

import DeleteExperienceModal from "views/Page/ProfilePage/ProfileSection/DeleteExperienceModal.js";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js";
/* import ExperienceEditModal from "./ExperienceEditModal.js";
import DeleteExperienceModal from "./DeleteExperienceModal.js"; */
import IconLibrary from "views/Component/IconLibrary.js";
import { activateEditType } from "configurations/redux/editProfileModalSlice";

const useStyles = makeStyles(profilePageStyle);

export default function ExperienceCardSection() {
	const experienceRecords = useSelector(
		(state) => state.user.experienceRecords
	);
	const extension = useSelector((state) => state.editProfileModal.extension);

	const isViewed = useSelector((state) => state.user.isViewed);
	const dispatch = useDispatch();
	const classes = useStyles();
	const monthDataList = getMonthData();
	const [countryList, setCountryList] = React.useState([]);
	const [recordId, setRecordId] = React.useState();
	const [deleteExperienceModal, setDeleteExperienceModal] = React.useState(
		false
	);
	const [udpateExperienceModal, setUpdateExperienceModal] = React.useState(
		false
	);
	const privacySetting = useSelector((state) => state.user.privacySetting);

	const closeExperienceDeleteModal = () => {
		setDeleteExperienceModal(false);
		setRecordId();
	};

	const closeUpdateExperienceModal = () => {
		setUpdateExperienceModal(false);
		setRecordId();
	};
	const showProfile =
		privacySetting &&
		privacySetting
			.filter((item) => {
				return item.settingName === "showProfile";
			})
			.map((item) => {
				return item.settingValue === 1;
			})[0];
	const onClick = (data) => {
		setRecordId(data);
		setUpdateExperienceModal(true);
		const response = {
			type: "EDIT_PROFILE",
			tabType: "EXPERIENCE",
			isTabShow: true,
			extension: {
				recordId: data,
			},
		};

		/* setRecordId(-1); */

		dispatch(activateEditType(response));
	};

	const showExp =
		privacySetting &&
		privacySetting
			.filter((item) => {
				return item.settingName === "showExp";
			})
			.map((item) => {
				return item.settingValue === 1;
			})[0];
	const deleteClick = (data) => {
		setRecordId(data);
		const response = {
			/* type: "EDIT_PROFILE",
			tabType: "EXPERIENCE",
			isTabShow: false, */
			extension: {
				recordId: data,
			},
		};
		console.log(response);
		/* setRecordId(-1); */
		setDeleteExperienceModal(true);
		dispatch(activateEditType(response));
	};
	React.useEffect(() => {
		let mounted = true;
		let CancelToken = axios.CancelToken;
		const source = CancelToken.source();
		const fetchCountryList = async () => {
			try {
				const res = await axios.get(`${instanceUrl}/api/md/get-all-country`, {
					cancelToken: source.token,
				});
				setCountryList(res.data.payload);
			} catch (error) {
				if (axios.isCancel(error)) {
					//console.log("unmount caused");
				} else {
					throw error;
				}
			}
		};
		if (mounted) {
			fetchCountryList();
		}
		return () => {
			mounted = false;
			source.cancel();
		};
	}, []);
	const NoExperienceList = () => {
		if (isViewed) {
			return (
				<Card className={classes.cardBorder}>
					<CardBody>
						<h5>
							<b>No Experience List</b>
						</h5>
					</CardBody>
				</Card>
			);
		} else {
			return (
				<div>
					<Card className={classes.cardBorder}>
						<CardBody>
							<h5>
								<b>
									No information filled yet. Please update your experience
									records
								</b>
							</h5>
						</CardBody>
					</Card>
				</div>
			);
		}
	};
	const ExperienceList = experienceRecords.map((experienceData) => {
		return (
			<div key={experienceData.expRecId}>
				<Card className={classes.cardInfo}>
					<CardBody>
						{isViewed ? (
							experienceData.isVisible === 1 ? (
								<GridContainer
									justifyContent="space-between"
									alignContent="center"
									alignItems="center"
								>
									<GridItem
										xs={9}
										sm={10}
										md={10}
										className={classes.cardPadding}
									>
										<div className={classes.cardDetail}>
											<div className={classes.descriptionContainer}>
											    
												<h4 className={classes.jobTitle}>
													{experienceData.title}
												</h4>
												<h5 className={classes.smallDescription}>
													{experienceData.organization}
												</h5>
											</div>

											<div className={classes.descriptionContainer}>
												<span
													className={
														classes.smallDescription +
														" " +
														classes.grayFontCard
													}
												>
													<b>
														{monthDataList
															.filter(
																(month) =>
																	month.monthId ===
																	experienceData.startDate.substring(5, 7)
															)
															.map((month) => month.monthName)}{" "}
														{experienceData.startDate.substring(0, 4)}
														{" - "}
														{experienceData.currentInProgressFlag !== 1 &&
															monthDataList
																.filter(
																	(month) =>
																		month.monthId ===
																		experienceData.endDate.substring(5, 7)
																)
																.map((month) => month.monthName)}{" "}
														{experienceData.currentInProgressFlag === 1
															? `Present`
															: experienceData.endDate.substring(0, 4)}
													</b>
												</span>
												<span
													className={
														classes.smallDescription +
														" " +
														classes.mobileSpan +
														" " +
														classes.grayFontCard
													}
												>
													<b>
														{countMonthYear(
															experienceData.startDate,
															experienceData.endDate
														) &&
															" • " +
																countMonthYear(
																	experienceData.startDate,
																	experienceData.endDate
																)}
													</b>
												</span>

												<p
													className={
														classes.smallDescription +
														" " +
														classes.grayFontCard
													}
												>
													<b>
														{countryList
															.filter((item) => {
																return (
																	item.countryId === experienceData.countryId
																);
															})
															.map((item) => {
																return item.countryName;
															})}
													</b>
												</p>
												<span
													className={
														classes.smallDescription +
														" " +
														classes.mobileSpan +
														" " +
														classes.grayFontCard
													}
												>
													<b>
														{experienceData.industryId > 0
															? experienceData.industryName
															: experienceData.customIndustryName}
													</b>
												</span>
												<span
													className={
														classes.smallDescription +
														" " +
														classes.mobileSpan +
														" " +
														classes.grayFontCard
													}
												>
													<b>
														{experienceData.departmentId > 0
															? " - " + experienceData.departmentName
															: experienceData.customDepartmentName
															? " - " + experienceData.customDepartmentName
															: null}
													</b>
												</span>
											</div>
										</div>
									</GridItem>
									<GridItem xs={3} sm={2} md={2} className={classes.menuIcon}>
										<div>
											{isViewed ? null : (
												<React.Fragment>
													

													<Tooltip
														title={
															<p className={classes.tooltipDescription}>
																Edit Experience
															</p>
														}
														placement="top"
														classes={{ tooltip: classes.tooltip }}
													>
														<Button
															justIcon
															style={{ boxShadow: "none" }}
															color="transparent"
															className={classes.buttonIconMargin}
															onClick={() => {
																onClick(experienceData.expRecId);
															}}
														>
															<IconLibrary.Pen />
															Edit
														</Button>
													</Tooltip>
													<Tooltip
														id="tooltip-top"
														title={
															<p className={classes.tooltipDescription}>
																Delete Experience
															</p>
														}
														placement="top"
														classes={{ tooltip: classes.tooltip }}
													>
														<Button
															onClick={() => {
																deleteClick(experienceData.expRecId);
															}}
															justIcon
															className={
																classes.buttonIconMargin +
																" " +
																classes.grayButton
															}
															color="transparent"
														> 
														   
															<IconLibrary.Trash />
															Delete
														</Button>
													</Tooltip>
												</React.Fragment>
											)}
										</div>
									</GridItem>
									<GridItem className={classes.cardPadding}>
										<div>
											{experienceData.achievement ? (
												<div>
													<Divider />
													<p className={classes.description}>
														{experienceData.achievement}
													</p>
												</div>
											) : null}
										</div>

										<div>
											{experienceData.description ? (
												<div>
													{experienceData.achievement ? null : <Divider />}
													<p className={classes.description}>
														{experienceData.description}
													</p>
												</div>
											) : null}
										</div>
									</GridItem>
								</GridContainer>
							) : (
								<Card className={classes.cardBorder}>
									<CardBody>
										<h5>
											<b>This Experience Section is private</b>
										</h5>
									</CardBody>
								</Card>
							)
						) : (
							<GridContainer
								justifyContent="space-between"
								alignContent="center"
								alignItems="center"
							>
								<GridItem
									xs={9}
									sm={10}
									md={10}
									className={classes.cardPadding}
								>
									<div className={classes.cardDetail}>
										<div className={classes.descriptionContainer}>
										<Typography className='visible_text'><i class="fa-regular fa-eye-slash"></i> Not visible to public</Typography>
											<h4 className={classes.jobTitle}>
												{experienceData.title}
											</h4>
											<h5 className={classes.smallDescription}>
												{experienceData.organization}
											</h5>
										</div>

										<div className={classes.descriptionContainer}>
											<span
												className={
													classes.smallDescription + " " + classes.grayFontCard
												}
											>
												<b>
													{monthDataList
														.filter(
															(month) =>
																month.monthId ===
																experienceData.startDate.substring(5, 7)
														)
														.map((month) => month.monthName)}{" "}
													{experienceData.startDate.substring(0, 4)}
													{" - "}
													{experienceData.currentInProgressFlag !== 1 &&
														monthDataList
															.filter(
																(month) =>
																	month.monthId ===
																	experienceData.endDate.substring(5, 7)
															)
															.map((month) => month.monthName)}{" "}
													{experienceData.currentInProgressFlag === 1
														? `Present`
														: experienceData.endDate.substring(0, 4)}
												</b>
											</span>
											<span
												className={
													classes.smallDescription +
													" " +
													classes.mobileSpan +
													" " +
													classes.grayFontCard
												}
											>
												<b>
													{countMonthYear(
														experienceData.startDate,
														experienceData.endDate
													) &&
														" • " +
															countMonthYear(
																experienceData.startDate,
																experienceData.endDate
															)}
												</b>
											</span>

											<p
												className={
													classes.smallDescription + " " + classes.grayFontCard
												}
											>
												<b>
													{countryList
														.filter((item) => {
															return (
																item.countryId === experienceData.countryId
															);
														})
														.map((item) => {
															return item.countryName;
														})}
												</b>
											</p>
											<span
												className={
													classes.smallDescription +
													" " +
													classes.mobileSpan +
													" " +
													classes.grayFontCard
												}
											>
												<b>
													{experienceData.industryId > 0
														? experienceData.industryName
														: experienceData.customIndustryName}
												</b>
											</span>
											<span
												className={
													classes.smallDescription +
													" " +
													classes.mobileSpan +
													" " +
													classes.grayFontCard
												}
											>
												<b>
													{experienceData.departmentId > 0
														? " - " + experienceData.departmentName
														: experienceData.customDepartmentName
														? " - " + experienceData.customDepartmentName
														: null}
												</b>
											</span>
										</div>
									</div>
								</GridItem>
								<GridItem xs={3} sm={2} md={2} className={classes.menuIcon}>
									<div>
										{isViewed ? null : (
											<React.Fragment>
												<Tooltip
													id="tooltip-top"
													title={
														<p className={classes.tooltipDescription}>
															Delete Experience
														</p>
													}
													placement="top"
													classes={{ tooltip: classes.tooltip }}
												>
													<Button
														onClick={() => {
															deleteClick(experienceData.expRecId);
														}}
														justIcon
														className={
															classes.buttonIconMargin +
															" " +
															classes.grayButton
														}
														color="transparent"
													>
														<IconLibrary.Trash />
													</Button>
												</Tooltip>

												<Tooltip
													title={
														<p className={classes.tooltipDescription}>
															Edit Experience
														</p>
													}
													placement="top"
													classes={{ tooltip: classes.tooltip }}
												>
													<Button
														justIcon
														style={{ boxShadow: "none" }}
														color="transparent"
														className={classes.buttonIconMargin}
														onClick={() => {
															onClick(experienceData.expRecId);
														}}
													>
														<IconLibrary.Pen />
													</Button>
												</Tooltip>
											</React.Fragment>
										)}
									</div>
								</GridItem>
								<GridItem className={classes.cardPadding}>
									<div>
										{experienceData.achievement ? (
											<div>
												<Divider />
												<p className={classes.description}>
													{experienceData.achievement}
												</p>
											</div>
										) : null}
									</div>

									<div>
										{experienceData.description ? (
											<div>
												{experienceData.achievement ? null : <Divider />}
												<p className={classes.description}>
													{experienceData.description}
												</p>
											</div>
										) : null}
									</div>
								</GridItem>
							</GridContainer>
						)}
					</CardBody>
				</Card>
			</div>
		);
	});
	return (
		<div>
			{extension && extension.recordId && (
				<DeleteExperienceModal
					open={deleteExperienceModal}
					onClose={closeExperienceDeleteModal}
					expRecId={extension.recordId}
				/>
			)}
			{experienceRecords.length > 0 ? (
				isViewed ? (
					showProfile ? (
						showExp ? (
							ExperienceList
						) : (
							<Card className={classes.cardBorder}>
								<CardBody>
									<h5>
										<b>Experience Record is currently private</b>
									</h5>
								</CardBody>
							</Card>
						)
					) : (
						<Card className={classes.cardBorder}>
							<CardBody>
								<h5>
									<b>This user is currently private</b>
								</h5>
							</CardBody>
						</Card>
					)
				) : (
					ExperienceList
				)
			) : (
				<NoExperienceList />
			)}
		</div>
	);
}
