import React, { Fragment, useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Input,
  IconButton,
  makeStyles,
  Popover,
} from "@material-ui/core";
import popover from "views/Component/popover.js";
import Comment from "./Comment.js";
import socialFeedStyles from "../../../assets/jss/material-kit-pro-react/views/componentStyle/socialFeed/socialFeedStyles";
import ownPostStyles from "assets/jss/material-kit-pro-react/views/componentStyle/socialFeedPosts/ownPostStyles.js";
import userProfile from "assets/images/userProfile.png";
import avatar from "assets/img/faces/avatar.jpg";
import camp from "assets/img/faces/camp.jpg";
import marc from "assets/img/faces/marc.jpg";
import christian from "assets/img/faces/christian.jpg";
import kendall from "assets/img/faces/kendall.jpg";
import postLeft from "assets/images/PostLeft.png";
import postRight1 from "assets/images/PostRight1.png";
import postRight2 from "assets/images/PostRight2.png";
import dotBtn from "assets/images/dotBtn.png";
import like from "assets/images/like.png";
import comment from "assets/images/comment.png";
import share from "assets/images/share.png";
import saved from "assets/images/saved.png";
import file from "assets/images/file.png";
import emoji from "assets/images/emoji.png";
import gallery from "assets/images/documentation.png";
import leftArrow from "assets/img/arrow-left.png";
import rightArrow from "assets/img/arrow-right.png";

const useStyles = makeStyles(socialFeedStyles);
const useOwnPostStyles = makeStyles(ownPostStyles);

const OwnPost = (props) => {
  const classes = useStyles();
  const ownPostClasses = useOwnPostStyles();

  const ariaLabel = { "aria-label": "description" };

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorShareEl, setAnchorShareEl] = useState(null);
  const [previewPhotoIndex, setPreviewPhotoIndex] = useState(0);
  const imageSlider = React.createRef();

  // const images = [avatar, camp, kendall, marc, christian, camp, avatar, kendall, avatar];
  const images = [avatar, camp, kendall, marc];

  // useEffect(() => {
  //   console.log(props)
  // }, [])

  // #region image gallary scroll logic
  let pos = { top: 0, left: 0, x: 0, y: 0 };
  const mouseMoveHandler = (e) => {
    let imageSliderNode = imageSlider.current;

    if (imageSliderNode) {
      // How far the mouse has been moved
      const dx = e.clientX - pos.x;
      const dy = e.clientY - pos.y;

      // Scroll the element
      imageSliderNode.scrollTop = pos.top - dy;
      imageSliderNode.scrollLeft = pos.left - dx;
    }
  };
  const mouseUpHandler = () => {
    let imageSliderNode = imageSlider.current;

    if (imageSliderNode) {
      imageSliderNode.style.removeProperty('user-select');

      imageSliderNode.removeEventListener('mousemove', mouseMoveHandler);
      imageSliderNode.removeEventListener('mouseup', mouseUpHandler);
    }
  };
  const mouseDownHandler = (e) => {
    let imageSliderNode = imageSlider.current;

    if (imageSliderNode) {
      imageSliderNode.style.userSelect = 'none';

      imageSliderNode.addEventListener('mouseup', mouseUpHandler);
      imageSliderNode.addEventListener('mousemove', mouseMoveHandler);

      pos = {
        // The current scroll
        left: imageSliderNode.scrollLeft,
        top: imageSliderNode.scrollTop,
        // Get the current mouse position
        x: e.clientX,
        y: e.clientY,
      };
    }
  };
  //  #endregion

  return (
    <Fragment>
      <Card className={classes.socialFeedCard}>
        <CardContent className={classes.socialFeedCardContent}>
          <Typography component="div">
            <Typography component="div" className={classes.feedHeader}>
              <Typography component="div" className={classes.feedHeaderUserBx}>
                <Typography component="div" className={classes.feedUserImgBx}>
                  <img src={props.postData.profilePic} alt="" />
                </Typography>
                <Typography component="div" className={classes.feedUserNameBx}>
                  <Typography component="h6">
                    {props.postData.firstName} {props.postData.middleName} {props.postData.surName}
                  </Typography>
                  <Typography component="span">{props.postData.createdDate}</Typography>
                </Typography>
              </Typography>
              <button onClick={(e) => { setAnchorEl(e.currentTarget) }}>
                <img src={dotBtn} alt="" />
              </button>

              <Popover
                id={anchorEl ? "simple-popover" : undefined}
                open={anchorEl ? true : false}
                anchorEl={anchorEl}
                onClose={() => { setAnchorEl(null) }}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <Typography component="div" className={classes.popoverListItem}>
                  <Typography component="div" className={classes.popoverListItemIcon}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16.48" height="20" viewBox="0 0 16.48 20">
                      <path id="Path" d="M3.714,0h9.052A3.666,3.666,0,0,1,16.48,3.616V18.192A1.833,1.833,0,0,1,14.623,20a1.889,1.889,0,0,1-1.16-.4L8.24,15.534,3.017,19.6a1.891,1.891,0,0,1-2.61-.282A1.779,1.779,0,0,1,0,18.192V3.616A3.666,3.666,0,0,1,3.714,0Z" fill="#b6b7b8" />
                      <path id="Path-2" data-name="Path" d="M1.857,0A1.833,1.833,0,0,0,0,1.808V16.383l5.223-4.069a1.9,1.9,0,0,1,2.32,0l5.223,4.069V1.808A1.833,1.833,0,0,0,10.909,0Z" transform="translate(1.857 1.808)" fill="#fff" />
                    </svg>
                  </Typography>
                  <Typography component="div" className={classes.popoverListItemText}>
                    <Typography component="p" className={classes.popoverListItemTitle}>
                      Save Post
                    </Typography>
                  </Typography>
                </Typography>

                <Typography component="div" className={classes.popoverListItem}>
                  <Typography component="div" className={classes.popoverListItemIcon}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16.48" height="20" viewBox="0 0 16.48 20">
                      <path id="Path" d="M3.714,0h9.052A3.666,3.666,0,0,1,16.48,3.616V18.192A1.833,1.833,0,0,1,14.623,20a1.889,1.889,0,0,1-1.16-.4L8.24,15.534,3.017,19.6a1.891,1.891,0,0,1-2.61-.282A1.779,1.779,0,0,1,0,18.192V3.616A3.666,3.666,0,0,1,3.714,0Z" fill="#b6b7b8" />
                      <path id="Path-2" data-name="Path" d="M1.857,0A1.833,1.833,0,0,0,0,1.808V16.383l5.223-4.069a1.9,1.9,0,0,1,2.32,0l5.223,4.069V1.808A1.833,1.833,0,0,0,10.909,0Z" transform="translate(1.857 1.808)" fill="#fff" />
                    </svg>
                  </Typography>
                  <Typography component="div" className={classes.popoverListItemText}>
                    <Typography component="p" className={classes.popoverListItemTitle}>
                      Copy Post Link
                    </Typography>
                  </Typography>
                </Typography>

                <Typography component="div" className={classes.popoverListItem}>
                  <Typography component="div" className={classes.popoverListItemIcon}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
                      <path id="Shape" d="M13.437,0H5.563L0,5.563v7.874L5.563,19h7.874L19,13.437V5.563Z" transform="translate(1 1)" fill="#fff" stroke="#b6b7b8" stroke-width="2" />
                      <g id="Group_1" data-name="Group 1" transform="translate(9.5 4.5)">
                        <circle id="Oval" cx="1" cy="1" r="1" transform="translate(0 10)" fill="#b6b7b8" />
                        <path id="Path" d="M0,0H2V8H0Z" fill="#b6b7b8" />
                      </g>
                    </svg>
                  </Typography>
                  <Typography component="div" className={classes.popoverListItemText}>
                    <Typography component="p" className={classes.popoverListItemTitle}>
                      Report Post
                    </Typography>
                  </Typography>
                </Typography>

                <Typography component="div" className={classes.popoverListItem}>
                  <Typography component="div" className={classes.popoverListItemIcon}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 20 20">
                      <path id="Path" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#b5b5be" />
                      <path id="Path-2" data-name="Path" d="M8.182,0a8.182,8.182,0,1,0,8.182,8.182A8.182,8.182,0,0,0,8.182,0Z" transform="translate(1.818 1.818)" fill="#fff" />
                      <path id="Path-3" data-name="Path" d="M2.195,3.48.266,1.552A.909.909,0,0,1,1.552.266L3.48,2.195,5.409.266A.909.909,0,0,1,6.695,1.552L4.766,3.48,6.695,5.409A.909.909,0,0,1,5.409,6.695L3.48,4.766,1.552,6.695A.909.909,0,0,1,.266,5.409L2.195,3.48Z" transform="translate(6.364 6.364)" fill="#b5b5be" />
                    </svg>
                  </Typography>
                  <Typography component="div" className={classes.popoverListItemText}>
                    <Typography component="p" className={classes.popoverListItemTitle}>
                      Hide Post
                    </Typography>
                    <Typography component="p" className={classes.popoverListItemDescription}>
                      Remove post from your feed
                    </Typography>
                  </Typography>
                </Typography>

                <Typography component="div" className={classes.popoverListItem}>
                  <Typography component="div" className={classes.popoverListItemIcon}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                      <g id="Path" transform="translate(0 0)" fill="#fff" stroke-miterlimit="10">
                        <path d="M7,0h8a7,7,0,0,1,7,7v8a7,7,0,0,1-7,7H7a7,7,0,0,1-7-7V7A7,7,0,0,1,7,0Z" stroke="none" />
                        <path d="M 7 2 C 4.242990493774414 2 2 4.242990493774414 2 7 L 2 15 C 2 17.75700950622559 4.242990493774414 20 7 20 L 15 20 C 17.75700950622559 20 20 17.75700950622559 20 15 L 20 7 C 20 4.242990493774414 17.75700950622559 2 15 2 L 7 2 M 7 0 L 15 0 C 18.86598968505859 0 22 3.134010314941406 22 7 L 22 15 C 22 18.86598968505859 18.86598968505859 22 15 22 L 7 22 C 3.134010314941406 22 0 18.86598968505859 0 15 L 0 7 C 0 3.134010314941406 3.134010314941406 0 7 0 Z" stroke="none" fill="#b5b5be" />
                      </g>
                      <g id="Path-2" data-name="Path" transform="translate(7 7)" fill="none" stroke-miterlimit="10">
                        <path d="M2.414,3.828.293,1.707A1,1,0,0,1,1.707.293L3.828,2.414,5.95.293A1,1,0,0,1,7.364,1.707L5.243,3.828,7.364,5.95A1,1,0,0,1,5.95,7.364L3.828,5.243,1.707,7.364A1,1,0,0,1,.293,5.95L2.414,3.828Z" stroke="none" />
                        <path d="M 1.000001907348633 -4.76837158203125e-07 C 1.255924701690674 -4.76837158203125e-07 1.511847019195557 0.09763193130493164 1.707107067108154 0.2928972244262695 L 3.828427076339722 2.414216995239258 L 5.949747085571289 0.2928972244262695 C 6.145007133483887 0.09763193130493164 6.40092945098877 -4.76837158203125e-07 6.656852245330811 -4.76837158203125e-07 C 6.912774562835693 -4.76837158203125e-07 7.168696880340576 0.09763193130493164 7.363956928253174 0.2928972244262695 C 7.754487037658691 0.6834168434143066 7.754487037658691 1.316586971282959 7.363956928253174 1.707107067108154 L 5.242637157440186 3.828427076339722 L 7.363956928253174 5.949747085571289 C 7.754487037658691 6.340267181396484 7.754487037658691 6.973437309265137 7.363956928253174 7.363956928253174 C 7.168696880340576 7.559222221374512 6.912774562835693 7.656854629516602 6.656852245330811 7.656854629516602 C 6.40092945098877 7.656854629516602 6.145007133483887 7.559222221374512 5.949747085571289 7.363956928253174 L 3.828427076339722 5.242637157440186 L 1.707107067108154 7.363956928253174 C 1.511847019195557 7.559222221374512 1.255924701690674 7.656854629516602 1.000001907348633 7.656854629516602 C 0.74407958984375 7.656854629516602 0.4881572723388672 7.559222221374512 0.2928972244262695 7.363956928253174 C -0.09763288497924805 6.973437309265137 -0.09763288497924805 6.340267181396484 0.2928972244262695 5.949747085571289 L 2.414216995239258 3.828427076339722 L 0.2928972244262695 1.707107067108154 C -0.09763288497924805 1.316586971282959 -0.09763288497924805 0.6834168434143066 0.2928972244262695 0.2928972244262695 C 0.4881572723388672 0.09763193130493164 0.74407958984375 -4.76837158203125e-07 1.000001907348633 -4.76837158203125e-07 Z" stroke="none" fill="#b5b5be" />
                      </g>
                    </svg>
                  </Typography>
                  <Typography component="div" className={classes.popoverListItemText}>
                    <Typography component="p" className={classes.popoverListItemTitle}>
                      Hide all from “Pan Feng Shui”
                    </Typography>
                    <Typography component="p" className={classes.popoverListItemDescription}>
                      Stop seeing post from this person
                    </Typography>
                  </Typography>
                </Typography>

                <Typography component="div" className={classes.popoverListItem}>
                  <Typography component="div" className={classes.popoverListItemIcon}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16.84" height="19.88" viewBox="0 0 16.84 19.88">
                      <g id="Path" transform="translate(3.622)" fill="#fff" stroke-miterlimit="10">
                        <path d="M4.655,9.41A4.68,4.68,0,0,0,9.31,4.705,4.68,4.68,0,0,0,4.655,0,4.68,4.68,0,0,0,0,4.705,4.68,4.68,0,0,0,4.655,9.41Z" stroke="none" />
                        <path d="M 4.65500020980835 7.410000324249268 C 6.118970394134521 7.410000324249268 7.310000419616699 6.196540355682373 7.310000419616699 4.705000400543213 C 7.310000419616699 3.213460445404053 6.118970394134521 2.000000476837158 4.65500020980835 2.000000476837158 C 3.191030263900757 2.000000476837158 2.000000238418579 3.213460445404053 2.000000238418579 4.705000400543213 C 2.000000238418579 6.196540355682373 3.191030263900757 7.410000324249268 4.65500020980835 7.410000324249268 M 4.65500020980835 9.410000801086426 C 2.084110260009766 9.410000801086426 2.098083484725066e-07 7.303500175476074 2.098083484725066e-07 4.705000400543213 C 2.098083484725066e-07 2.106500387191772 2.084110260009766 4.005432003850729e-07 4.65500020980835 4.005432003850729e-07 C 7.225890159606934 4.005432003850729e-07 9.310000419616699 2.106500387191772 9.310000419616699 4.705000400543213 C 9.310000419616699 7.303500175476074 7.225890159606934 9.410000801086426 4.65500020980835 9.410000801086426 Z" stroke="none" fill="#b5b5be" />
                      </g>
                      <g id="Path-2" data-name="Path" transform="translate(0 9.47)" fill="#fff" stroke-miterlimit="10">
                        <path d="M8.42,0C3.829,0,.1,4.64,0,10.41H16.84C16.745,4.64,13.011,0,8.42,0Z" stroke="none" />
                        <path d="M 8.420000076293945 2 C 5.478047370910645 2 2.956839561462402 4.733343601226807 2.21738338470459 8.409999847412109 L 14.62261772155762 8.409999847412109 C 13.88316059112549 4.733343601226807 11.36195278167725 2 8.420000076293945 2 M 8.420000076293945 0 C 13.01138973236084 0 16.7446403503418 4.640219688415527 16.84000015258789 10.40999984741211 L 0 10.40999984741211 C 0.09535980224609375 4.640219688415527 3.828610420227051 0 8.420000076293945 0 Z" stroke="none" fill="#b5b5be" />
                      </g>
                    </svg>
                  </Typography>
                  <Typography component="div" className={classes.popoverListItemText}>
                    <Typography component="p" className={classes.popoverListItemTitle}>
                      Unfollow “Pan Feng Shui”
                    </Typography>
                    <Typography component="p" className={classes.popoverListItemDescription}>
                      Disconnect with this person
                    </Typography>
                  </Typography>
                </Typography>
                {/* </Typography> */}
              </Popover>
            </Typography>

            <Typography className={classes.feedTitle}>
              {props.postData.description}
            </Typography>

            {props.postData.attachments && props.postData.attachments.length ?
              <Typography component="div" className={classes.postGraphicalContent}>

                <Typography component="div" className={classes.postContentContainer}>
                  <button
                    style={{ display: props.postData.attachments.length > 4 ? "initial" : "none" }}
                    className={classes.leftPicBtn}
                    onClick={() => {
                      previewPhotoIndex == 0
                        ? setPreviewPhotoIndex(props.postData.attachments.length - 1)
                        : setPreviewPhotoIndex(previewPhotoIndex - 1)
                    }}>
                    <img src={leftArrow} alt="" />
                  </button>

                  {images.map((photo) =>
                    <img src={photo} alt="" />
                  )}
                  {/* {props.postData.attachments.length > 4 ?
                    props.postData.attachments.map((photo) =>
                      <img src={photo} alt="" />
                    )
                    : <img src={props.postData.attachments[previewPhotoIndex] ? props.postData.attachments[previewPhotoIndex] : props.postData.attachments[0]} alt="" />
                  } */}

                  <button
                    style={{ display: props.postData.attachments.length > 4 ? "initial" : "none" }}
                    className={classes.rightPicBtn}
                    onClick={() => {
                      previewPhotoIndex == props.postData.attachments.length - 1
                        ? setPreviewPhotoIndex(0)
                        : setPreviewPhotoIndex(previewPhotoIndex + 1)
                    }}>
                    <img src={rightArrow} alt="" />
                  </button>
                </Typography>

                {props.postData.attachments.lenght > 4 ?
                  <Typography
                    component="div"
                    className={ownPostClasses.photosList}
                    ref={imageSlider}
                    onMouseDown={(e) => {
                      mouseDownHandler(e);
                    }}
                  >
                    <Typography
                      component="div"
                      className={ownPostClasses.photosListContainer}
                    >

                      {props.postData.attachments.map((image, index) => {
                        return (
                          <Typography component="div" className={ownPostClasses.photosListItem} key={"outer div " + index} >
                            <div key={"inner div " + index}>
                              <img
                                className={ownPostClasses.photosListItemPhoto}
                                src={image}
                                alt=""
                                onClick={() => {
                                  setPreviewPhotoIndex(index);
                                }}
                                key={"img " + index}
                              />
                            </div>
                          </Typography>
                        )
                      })}

                    </Typography>
                  </Typography> : <></>}

              </Typography> : <></>}

          </Typography>

          <Typography component="div" className={classes.feedActions}>
            <Button>
              <img src={comment} alt="" />
              25 Comments
            </Button>
            <Button>
              <img src={like} alt="" />
              120 Likes
            </Button>
            <Button onClick={(e) => { setAnchorShareEl(e.currentTarget) }}>
              <img src={share} alt="" />
              231 Shares
            </Button>

            <Popover
              id={anchorShareEl ? "simple-popover" : undefined}
              open={anchorShareEl ? true : false}
              anchorEl={anchorShareEl}
              onClose={() => { setAnchorShareEl(null) }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Typography component="div" className={classes.popoverListItem}>
                <Typography component="div" className={classes.popoverListItemText}>
                  <Typography component="p" className={classes.popoverListItemTitle}>
                    Share to feed
                  </Typography>
                  <Typography component="p" className={classes.popoverListItemDescription}>
                    Share this post on your feed
                  </Typography>
                </Typography>
              </Typography>

              <Typography component="div" className={classes.popoverListItem}>
                <Typography component="div" className={classes.popoverListItemText}>
                  <Typography component="p" className={classes.popoverListItemTitle}>
                    Write post
                  </Typography>
                  <Typography component="p" className={classes.popoverListItemDescription}>
                    Compose a post with this content
                  </Typography>
                </Typography>
              </Typography>

              <Typography component="div" className={classes.popoverListItem}>
                <Typography component="div" className={classes.popoverListItemText}>
                  <Typography component="p" className={classes.popoverListItemTitle}>
                    Share to Chat
                  </Typography>
                </Typography>
              </Typography>

              <Typography component="div" className={classes.popoverListItem}>
                <Typography component="div" className={classes.popoverListItemText}>
                  <Typography component="p" className={classes.popoverListItemTitle}>
                    Share to Group
                  </Typography>
                </Typography>
              </Typography>

              <Typography component="div" className={classes.popoverListItem}>
                <Typography component="div" className={classes.popoverListItemText}>
                  <Typography component="p" className={classes.popoverListItemTitle}>
                    Copy Post Link
                  </Typography>
                </Typography>
              </Typography>
            </Popover>

            <Button>
              <img src={saved} alt="" />
              12 Saved
            </Button>
          </Typography>

          <Typography component="div" className={classes.feedCommentBx}>
            <Typography component="div" className={classes.feedCommentUserBx}>
              <img src={marc} alt="" />
            </Typography>
            <Typography component="div" className={classes.commentInputWrap}>
              <Input
                placeholder="Write your comment"
                inputProps={ariaLabel}
              />
              <Typography
                component="div"
                className={classes.commentActionBtns}
              >
                {/* <IconButton>
                    <img src={file} alt="" />
                  </IconButton> */}
                <IconButton>
                  <img src={emoji} alt="" />
                </IconButton>
                <IconButton>
                  <img src={gallery} alt="" />
                </IconButton>
              </Typography>
            </Typography>
          </Typography>

          {props.postData.commentCount ?
            <Typography component="div" className={classes.postCommentsMain}>
              <Typography component="div" className={classes.recentCommentsButton}>
                <button>Most recent comments</button>
              </Typography>

              {/* <Comment haveComments={true} /> */}

              <Typography component="div" className={classes.moreComments}>
                <button>
                  Load more comments
                </button>
              </Typography>
            </Typography> : <></>}

        </CardContent>
      </Card>
    </Fragment >
  )
}

export default OwnPost;