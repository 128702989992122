const requestTestimonialStyles = () => ({
	body: {
		padding: "10px 0px 0px 0px",
		height: "100%",
	},

	formContainer: {
		overflowY: "auto",
		marginTop: "10px",
		backgroundColor: "#F2F3F7",
		padding: "0 10px 5px",
		/* 	height: "93%", */
	},

	title: {
		color: "#000000",
		fontSize: "18px",
		fontWeight: 600,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		textAlign: "center",
	},

	inputLabel: {
		margin: "5px 0px !important",
		color: "#000000",
		fontSize: "14px",
		fontWeight: 700,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
	},

	textField: {
		borderRadius: "5px",
		backgroundColor: "#fff",

		/* "& fieldset": {
			border: "none",
		}, */
	},

	inputField: {
		fontSize: "14px",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,

		/* "&::placeholder": {
			color: "#99B2C6",
		}, */
	},

	autoCompleteInputContainer: {
		padding: "0 40px 0 0",

		"& > div": {
			right: "9px",
		},
	},

	submitButtonContainer: {
		textAlign: "center",
	},

	submitButton: {
		margin: "5px auto 0",
		border: "solid 1px #687BFE",
		borderRadius: "24px",
		backgroundColor: "#687BFE",
		width: "200px",
		height: "45px",
		color: "#fff",
		fontSize: "14px",
		fontWeight: 500,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,

		"&:hover": {
			backgroundColor: "#fff",
			color: "#687BFE",
		},
	},
	errorMessage: {
		fontSize: "14px",
		fontWeight: 500,
		color: "red",
	},
	formArea: {
		"@media (max-height: 700px)": {
			height: "300px",
			overflowY: "scroll",
			paddingRight: "10px",
		},
	},
});

export default requestTestimonialStyles;
