import {
	container,
	cardTitle,
	title,
	mlAuto,
	mrAuto,
	main,
	mainRaised,
	grayColor,
	blackColor,
	hexToRgb,
	whiteColor,
	opptyDesignColor,
} from "assets/jss/material-kit-pro-react.js";
import customSelectStyle from "assets/jss/material-kit-pro-react/customSelectStyle.js";
import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js";
import customCheckboxRadioSwitchStyle from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.js";
import headersSection from "assets/jss/material-kit-pro-react/components/headerStyle.js";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.js";
import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";
import hashTagStyle from "../../componentStyle/hashTagStyle.js";

const messagePageStyle = (theme) => ({
	...headersSection(theme),
	...modalStyle(theme),
	...styles,
	...hashTagStyle,
	...imagesStyles,
	...customSelectStyle,

	main,
	mainRaised,
	mlAuto,
	mrAuto,

	cardTitle,
	...styles,
	...customCheckboxRadioSwitchStyle,
	...customSelectStyle,
	mainRaisedMobile: {
		// marginTop: "-60px",
		paddingLeft: "15px",
		paddingRight: "15px",
	},
	mainRaisedTablet: {
		marginTop: "0px",
		paddingLeft: "15px",
		paddingRight: "15px",
	},
	container: {
		...container,
		position: "relative",
		zIndex: "2",
		margin: "30px",

		"& h1, & h4, & h6": {
			color: blackColor,
		},
	},
	title: {
		...title,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		position: "relative",
		marginTop: "-5px", //"25px",
		minHeight: "1px", //"32px",
		marginBottom: "0px",
		textDecoration: "none",
		color: blackColor + "!important",
		"& svg": {
			width: "32px",
			height: "32px",
			marginRight: "20px",
			marginTop: "10px",
		},
	},
	titleMobile: {
		...title,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		position: "relative",
		marginTop: "0px",
		marginBottom: "0px",
		minHeight: "1px",
		textDecoration: "none",
		color: blackColor + "!important",
	},
	textOverflowHide: {
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap",
	},
	appbar: {
		color: grayColor[15],
		width: "100%",
		backgroundColor: whiteColor,
		zIndex: theme.zIndex.drawer + 1,
		boxShadow: "none",
		borderBottom: "3px solid #D9E1E7",
	},
	appbarBottom: {
		color: grayColor[15],
		width: "100%",
		backgroundColor: whiteColor,
		top: "auto",
		bottom: "0",
		position: "fixed",
	},
	textCenter: {
		textAlign: "center",
	},
	avatarPadding: {
		marginTop: "10px",
	},
	parallax: {
		height: "92px",
		backgroundPosition: "top center",
	},
	parallaxMobile: {
		height: "36vh",
	},
	searchBarButton: {
		marginTop: "17px",
	},
	sendButton: {
		// marginTop: "12px",
		borderRadius: "50px",
		padding: "15px 15px",
	},
	sendIcon: {
		width: "22px!important",
		height: "22px!important",
		marginRight: "0px!important",
	},
	cardImage: {
		width: "140px",
		height: "140px",
		overflow: "hidden",
		padding: "0",
		borderRadius: "50%",
		boxShadow:
			"0 16px 38px -12px rgba(" +
			hexToRgb(blackColor) +
			", 0.56), 0 4px 25px 0px rgba(" +
			hexToRgb(blackColor) +
			", 0.12), 0 8px 10px -5px rgba(" +
			hexToRgb(blackColor) +
			", 0.2)",
		"&$cardAvatarPlain": {
			marginTop: "0",
		},
		margin: "-50px auto 0",
	},
	cardClick: {
		cursor: "pointer",
		"&:hover,&:focus": {
			//backgroundColor: grayColor[0],
			boxShadow:
				"0 14px 26px -12px rgba(" +
				hexToRgb(grayColor[0]) +
				", 0.42), 0 4px 23px 0px rgba(" +
				hexToRgb(blackColor) +
				", 0.12), 0 8px 10px -5px rgba(" +
				hexToRgb(grayColor[0]) +
				", 0.2)",
		},
	},
	cardCategory: {
		color: blackColor,
		textDecoration: "none",
	},
	cardHeight: {
		height: "430px",
		paddingTop: "15px",
		paddingBottom: "15px",
	},
	cardViewCount: {
		marginTop: "20px",
	},
	cardFooterBorder: {
		borderTop: "1px solid rgba(" + hexToRgb(blackColor) + ", 0.35)",
	},
	cardResultSection: {
		paddingTop: "15px",
		paddingBottom: "15px",
		//overflowY: "scroll",
	},
	profileResult: {
		minHeight: "420px",
		height: "750px",
		overflowY: "scroll",
	},
	message: {
		paddingRight: "0px",
	},
	endMessage: {
		textAlign: "center",
		paddingTop: "30vh",
		// transform: "rotate(180deg)",
	},
	endConversation: {
		textAlign: "center",
	},
	closedConversation: {
		paddingLeft: "10px",
	},
	cardBodyPadding: {
		paddingRight: "10px",
		paddingLeft: "10px",

		[theme.breakpoints.down("xs")]: {
			padding: "0",
		},
	},
	modalJobView: {
		maxWidth: "900px",
		width: "100%",
		minHeight: "900px",
	},
	companyModalLogo: {
		maxWidth: "120px",
		maxHeight: "120px",
		height: "100%",
		width: "100%",
	},
	paperClass: {
		borderRight: "1px solid rgba(" + hexToRgb(blackColor) + ", 0.35)",
	},
	gridItemMargin: {
		marginTop: "10px",
		marginBottom: "10px",
	},
	cardMargin: {
		margin: "5px !important",
	},
	cardDetail: {
		whiteSpace: "pre-line",
	},
	messageList: {
		// height: "67vh",
		overflowY: "auto",
		overflowX: "hidden",
		marginTop: "0px!impotant",
		// border: "1px solid rgba(" + hexToRgb(blackColor) + ", 0.35)",
		//borderTop: "1px solid rgba(" + hexToRgb(blackColor) + ", 0.35)",
		//borderBottom: "1px solid rgba(" + hexToRgb(blackColor) + ", 0.35)",
	},
	conversation: {
		border: "1px solid rgba(" + hexToRgb(blackColor) + ", 0.35)",
		height: "100%",
	},
	conversationData: {
		height: "1vh",
		overflowY: "scroll",
		padding: "10px",
		//margin: "0px 5px 0px 5px",
		minHeight: "100%",
		display: "flex",
		flexDirection: "column-reverse",
		backgroundColor: "#f2f3f7",
		borderTop: "3px solid #D9E1E7",
		// borderBottom: "1px solid black",
	},
	conversationDataMobile: {
		height: "1vh",
		overflowY: "scroll",
		padding: "10px 10px 10px 5px",
		// margin: "0px 5px 0px 5px",
		minHeight: "100%",
		display: "flex",
		flexDirection: "column-reverse",
		borderTop: "2px solid #D9E1E7",
		borderBottom: "2px solid #D9E1E7",
		backgroundColor: "#f2f3f7",
	},
	conversationSearch: {
		height: "56vh",
		overflowY: "scroll",
		padding: "0px 0 10px 0",
		minHeight: "100%",
		display: "flex",
		flexDirection: "column-reverse",
	},
	conversationSend: {
		float: "left",
		clear: "both",
		padding: "0px",
		boxSizing: "border-box",
		wordWrap: "break-word",
		marginTop: "10px",
		backgroundColor: "#1D3A76",
		color: "white",
		minWidth: "50px",
		maxWidth: "500px",
		borderRadius: "10px",
	},
	conversationReceive: {
		float: "right",
		clear: "both",
		padding: "0px",
		boxSizing: "border-box",
		wordWrap: "break-word",
		marginTop: "10px",
		backgroundColor: "#171717",
		color: "white",
		minWidth: "100px",
		maxWidth: "500px",
		borderRadius: "10px",
	},
	conversationInput: {
		// height: "150px",
		// border: "1px solid rgba(" + hexToRgb(blackColor) + ", 0.35)",
		// padding: "10px",
		/* padding: "8px 10px 5px 10px",
		margin: "5px" */
		/* borderTop: "1px solid black",
		position: "fixed",

		bottom: "0", */
		// minHeight: "80px",
		padding: "0px 20px 0px 5px",
		margin: "5px",
	},
	conversationInfo: {
		// height: "150px",
		// border: "1px solid rgba(" + hexToRgb(blackColor) + ", 0.35)",
		// padding: "10px",

		padding: "8px 10px 4px 10px",
		margin: "5px",
	},
	conversationEmptyInfo: {
		// height: "65vh",
		padding: "4px 10px 4px 10px",
		margin: "5px",
		// backgroundColor: "rgb(105, 105, 247, 0.3)",
		borderRadius: "18px",
	},
	conversationDefaultInfoCard: {
		borderRadius: "18px",
		boxShadow: "none",
		verticalAlign: "center",
	},
	infoImage: {
		width: "100%",
		height: "auto",

		padding: "0px 50px !important",
	},
	conversationMargin10: {
		marginBottom: "0px",
		marginTop: "10px",
		// paddingBottom: "10px",
	},
	conversationMargin0: {
		marginBottom: "0px",
		marginTop: "0px",
	},
	conversationMargin0Mobile: {
		marginBottom: "0px",
		marginTop: "0px",
		borderRadius: "0px",
	},
	chatroomMargin0: {
		marginBottom: "0px",
		marginTop: "0px",
	},
	messageProfilePic: {
		/* marginTop: "0",
		marginLeft: "33%",
		width: "auto",
		maxWidth: "74px", */
		// boxShadow: "0 16px 38px -12px rgb(0 0 0 / 0%), 0 4px 25px 0px rgb(0 0 0 / 0%), 0 8px 10px -5px rgb(0 0 0 / 0%)",
	},
	messageProfile: {
		boxShadow: "none",
		border: "1px solid",
		borderColor: grayColor[0],
		borderRadius: "50%",
		width: "60px",
		height: "60px",
		cursor: "pointer",
	},
	messageProfilePicMobile: {
		width: "60px",
		height: "60px",
	},
	messageTitleMobile: {
		marginLeft: "15px",
		flexGrow: "1",
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		width: 0,
	},
	messageTitle: {
		marginTop: "2%",
	},
	messageMargin0: {
		marginBottom: "0px",
		marginTop: "0px",
	},
	messageMargin10: {
		// marginBottom: "0px",
		marginTop: "10px",
	},
	messageMargin: {
		marginBottom: "10px",
		marginTop: "10px",
	},
	gridMargin: {
		marginLeft: "0px",
		marginRight: "0px",
		padding: "20px 0px",
		alignItems: "center",
	},
	messageCustomInput: {
		marginBottom: "0px!important",
		paddingTop: "0px!important",
	},
	conversationTitle: {
		marginTop: "5px",
		marginBottom: "25px",
		[theme.breakpoints.down("xs")]: {
			fontSize: "16px",
		},
	},
	titlePadding8: {
		padding: "8px 24px",
	},
	chatRoomSearchBox: {
		marginBottom: "-17px!important",
		justifyContent: "center",
	},
	searchBarInput: {
		margin: "0 0 -7px 0!important",
	},
	searchButton: {
		margin: "15px 0 0 0",
	},
	borderRadius: {
		borderRadius: "50px",
		boxShadow: "none",
		border: "2px solid",
		borderColor: "#D9E1E7",
	},
	borderRadiusSearch: {
		borderRadius: "10px",
		boxShadow: "none",
		border: "2px solid",
		borderColor: "#D9E1E7",
	},
	borderRadiusConv: {
		borderRadius: "18px",
		boxShadow: "none",
		border: "1px solid",
		borderColor: grayColor[0],
		padding: "15px 0px",
		backgroundColor: "#f2f3f7",
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
	},
	fab: {
		boxShadow: "0 0 0 0",
		opacity: "0.4",
		marginLeft: "47%",
		position: "absolute",
		zIndex: theme.zIndex.drawer,
	},
	fabMobile: {
		boxShadow: "0 0 0 0",
		opacity: "0.4",
		position: "absolute",
		zIndex: theme.zIndex.drawer,
		left: theme.spacing(1),
		// bottom: theme.spacing(1),
	},
	snackbarCustom: {
		backgroundColor: "#1D3A76",
	},
	messageCard: {
		marginTop: "15px",
		//margin: "0px -30px 0px 20px",
		/* height: "100vh", */
	},
	messageConversationCard: {
		/* margin: "0px 20px 0px 20px", */
	},
	messageCardBorder: {
		// borderRadius: "18px",
		overflow: "hidden",
		boxShadow: "none",
		marginTop: "15px",
	},
	messageCardBody: {
		padding: "0",
		/* height: "70vh", */
		overflow: "auto",
		/* height: "100vh", */
		marginTop: "15px",
	},
	messageCardBodyMobile: {
		padding: "0",
		maxHeight: "70vh",
		height: "60vh",
		marginTop: "15px",
		// overflow: "auto",
	},
	messageCardHeader: {
		padding: "1rem 0 0 0",
		marginTop: "0px",
	},
	messageCardBodyList: {
		padding: "10px 10px 10px 10px",
		/* padding: "0 !important", */
		textAlign: "left",
	},
	messageCardBodyFooter: {
		padding: "0px 10px 10px 10px",
		/* padding: "0 !important", */
		textAlign: "left",
	},
	messageCardBodyListMobile: {
		padding: "1rem 15px 0 15px",
	},
	messageCardFooter: {
		margin: "0px 0 10px 0",
		// borderRadius: "18px",
		// border: "1px solid",
		// borderColor: grayColor[0],
		boxShadow: "none",
	},
	messageCardFooterMobile: {
		margin: "0px 0 0px 0",
		// borderTop: "1px solid black",
		// borderRadius: "18px",
		boxShadow: "none",
	},
	popoverFrame: {
		borderRadius: "18px!important",
	},
	chatroomHeight: {
		height: "1vh",
		overflowY: "scroll",
		minHeight: "100%",
	},
	footerHeight: {
		maxHeight: "70vh",
		overflowY: "auto",
		overflowX: "hidden",
	},
	messageGrid: {
		width: "100%",
		height: "100vh",
		/* height: "100%", */
		backgroundColor: "#f2f3f7",
	},
	leftGrid: {
		borderLeft: "1px solid #D9E1E7",
		borderRight: "3px solid #D9E1E7",
		height: "calc(100vh - 92px)",
		/* height: "100%", */
		backgroundColor: "#ffffff",
		paddingLeft: "10px",
		paddingRight: "0px",
		zIndex: 1,
	},
	rightGrid: {
		borderRight: "1px solid #D9E1E7",
		/* maxH */ height: "calc(100vh - 92px)",
		/* height: "100%", */
		backgroundColor: "#ffffff",
		padding: "0",
	},
	itemCenter: {
		textAlign: "center",
	},
	gridPadding: {
		paddingRight: "0",
	},
	defaultGrid: {
		height: "calc(100vh - 92px)",
	},
	defaultInfo: {
		position: "relative",
		top: "50%",
		transform: "translateY(-50%)",
		textAlign: "center",
		width: "80%",
		margin: "auto",
		"@media (min-width: 576px)": {
			top: "40%",
			width: "50%",
		},
		"@media (min-width: 768px)": {
			top: "45%",
			width: "50%",
		},
		"@media (min-width: 992px)": {
			top: "50%",
			width: "70%",
		},
	},
	infoTitle: {
		color: opptyDesignColor,
		fontWeight: "bolder",
		"@media (min-width: 576px)": {
			fontSize: "30px",
		},
		"@media (min-width: 768px)": {
			fontSize: "30px",
		},
		"@media (min-width: 992px)": {
			fontSize: "2.25rem",
		},
	},
	infoDescription: {
		color: grayColor[0],
		fontWeight: 500,
		"@media (min-width: 576px)": {
			fontSize: "12px",
		},
		"@media (min-width: 768px)": {
			fontSize: "12px",
		},
		"@media (min-width: 992px)": {
			fontSize: "1.0625rem",
		},
	},
	modalHeader: {
		borderBottom: "none",
		paddingTop: "24px",
		paddingRight: "24px",
		paddingBottom: "0",
		paddingLeft: "24px",
		minHeight: "16.43px",
	},
	modalCloseButton: {
		"&, &:hover": {
			color: blackColor,
		},
		"&:hover": {
			opacity: "1",
		},
		cursor: "pointer",
		padding: "1rem",
		margin: "-1rem -1rem -1rem auto",
		backgroundColor: "transparent",
		border: "0",
		WebkitAppearance: "none",
		float: "right",
		fontSize: "1.5rem",
		fontWeight: "500",
		lineHeight: "1",
		textShadow: "0 1px 0 " + whiteColor,
		opacity: ".5",
	},
	modalClose: {
		width: "16px",
		height: "16px",
	},
	searchCardBorder: {
		// border: "1px solid gray",
		// borderRadius: "18px",
		padding: "10px 0px",
		boxShadow: "none",
	},
	iconButton: {
		position: "absolute",
		right: "10px",
		top: 0,
	},
	iconButtonDelete: {
		position: "absolute",
		right: 0,
		top: 0,
	},
	iconPosition: {
		top: "24px",
	},
	alertPadding: {
		paddingLeft: "80px",
		color: "#99B2C6",
		fontWeight: "400",
	},

	commentActionBtns: {
		position: "absolute",
		top: "3px",
		right: 0,
		// width: "140px!important",

		"& > button": {
			width: "45px",
			height: "45px",
		},
	},
	attachmentButton: {
		backgroundColor: "#fff",
		color: "#000",
		padding: "5px",
		borderRadius: "8px",
		justifyContent: "space-between",
		marginRight: "10px",
		maxWidth: "100%",
		"& > span": {
			"& > span": {
				overflow: "hidden",
				textOverflow: "ellipsis",
				whiteSpace: "nowrap",
				padding: "2px 10px 0 5px",
			},
			"& > svg": {
				minWidth: "18px",
			},
		},
	},
});

export default messagePageStyle;
