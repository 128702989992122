import React, { useEffect, useMemo, useState } from 'react';

import Button from 'components/CustomButtons/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { userGroupRoles, userGroupStatus } from 'configurations/configurations';
import profilePageStyle from 'assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js';
import { Box, FormControl, makeStyles, MenuItem, Select, Tab, Tabs } from '@material-ui/core';
import IconLibrary from 'views/Component/IconLibrary';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import MemberList from './MemberList';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import PersonRemoveOutlinedIcon from '@mui/icons-material/PersonRemoveOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import {
  deleteGroupPost,
  getGroupUsersByStatus,
  getPendingPostsByGroupId,
  leaveUserGroup,
  updateGroupPostStatus,
  updateUserGroupRole,
  updateUserGroupStatus,
} from 'configurations/redux/groupsSlice';
import GroupSettings from './GroupSettings';
import PostList from './PostList';
import { useModal } from 'hooks';

const useStyles = makeStyles(profilePageStyle);

const ManageGroupModal = ({ modalOpen, toggle, initialTab, setInitialTab, group }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { groupId } = group;

  const { usersByStatus, usersByGroupId, pendingPostsByGroupId } = useSelector(state => state.groups);
  const user = useSelector(state => state.auth.user);

  const [selectedTab, setSelectedTab] = useState(initialTab);
  const [tabSelectedBeforeConfirm, setTabSelectedBeforeConfirm] = useState(initialTab);
  const [isGroupSettingsChanged, setIsGroupSettingsChanged] = useState(false);
  const [pendingTab, setPendingTab] = useState(0);
  const mobileWidth = 960;
  const [isDesktop, setDesktop] = useState(window.innerWidth > mobileWidth);

  const saveSettingsModalProps = useModal();
  const perPageCount = 7;
  const accessToken = localStorage.getItem('token');

  const { currentUser } = useMemo(() => {
    const users = {};
    users.currentUser = usersByGroupId.find(u => u.acctId === user.acctId) ?? {};
    return users;
  }, [usersByGroupId]);

  const isUserRoleMember = currentUser.roleId === userGroupRoles.MEMBER.id;

  const onTabChange = (event, tab) => {
    console.log(isUserRoleMember);
    if (isGroupSettingsChanged) {
      if (selectedTab === userGroupStatus.INACTIVE.id) {
        if (tab !== userGroupStatus.INACTIVE.id) {
          saveSettingsModalProps.setModalOpen(true);
          setTabSelectedBeforeConfirm(tab);
        }
      }
    } else {
      setSelectedTab(tab);
    }
  };

  const onSaveChangesConfirm = () => {
    if (saveSettingsModalProps.modalOpen) {
      setSelectedTab(tabSelectedBeforeConfirm);
      setIsGroupSettingsChanged(false);
      saveSettingsModalProps.setModalOpen(false);
    }
  };

  const groupActions = {
    members: {
      [userGroupRoles.OWNER.id]: [
        {
          roles: [userGroupRoles.MEMBER.id, userGroupRoles.MANAGER.id, userGroupRoles.OWNER.id],
          text: 'Remove from group',
          onClick: ({ groupUserId }) => leaveGroup({ groupUserId }),
          getConfirmModalContent: ({ firstName }) => {
            return {
              title: `Remove ${firstName} ?`,
              text: `${firstName} will no longer be a member of this group.`,
            };
          },
          Icon: HighlightOffOutlinedIcon,
        },
        {
          roles: [userGroupRoles.MEMBER.id, userGroupRoles.MANAGER.id],
          text: 'Add as Owner',
          onClick: ({ groupUserId }) => updateUserRole({ groupUserId, roleId: userGroupRoles.OWNER.id }),
          getConfirmModalContent: ({ firstName }) => {
            return {
              title: `Add ${firstName} as Owner?`,
              text: `${firstName} will be able to add and remove Owners and Managers, edit all group information and settings, as well as manage posts and membership requests.`,
            };
          },
          Icon: PersonAddAlt1OutlinedIcon,
        },
        {
          roles: [userGroupRoles.MEMBER.id],
          text: 'Add as Manager',
          onClick: ({ groupUserId }) => updateUserRole({ groupUserId, roleId: userGroupRoles.MANAGER.id }),
          getConfirmModalContent: ({ firstName }) => {
            return {
              title: `Add ${firstName} as Manager?`,
              text: `${firstName} will be able to edit all group information and settings, as well as manage posts and membership requests. `,
            };
          },
          Icon: PersonAddAlt1OutlinedIcon,
        },
        {
          roles: [userGroupRoles.MANAGER.id],
          text: 'Remove as Manager',
          onClick: ({ groupUserId }) => updateUserRole({ groupUserId, roleId: userGroupRoles.MEMBER.id }),
          getConfirmModalContent: ({ firstName }) => {
            return {
              title: `Remove ${firstName} as Manager?`,
              text: `${firstName} will no longer have Manager permissions, but will still be a member of the group.`,
            };
          },
          Icon: PersonRemoveOutlinedIcon,
        },
        {
          roles: [userGroupRoles.OWNER.id],
          text: 'Remove as Owner',
          onClick: ({ groupUserId }) => updateUserRole({ groupUserId, roleId: userGroupRoles.MEMBER.id }),
          getConfirmModalContent: ({ firstName }) => {
            return {
              title: `Remove ${firstName} as Owner?`,
              text: `${firstName} will no longer have Owner permissions, but will still be a member of the group.`,
            };
          },
          Icon: PersonRemoveOutlinedIcon,
        },
      ],
      [userGroupRoles.MANAGER.id]: [
        {
          roles: [userGroupRoles.MEMBER.id],
          text: 'Remove from group',
          onClick: ({ groupUserId }) => leaveGroup({ groupUserId }),
          getConfirmModalContent: ({ firstName }) => {
            return {
              title: `Remove ${firstName} ?`,
              text: `${firstName} will no longer be a member of this group.`,
            };
          },
          Icon: HighlightOffOutlinedIcon,
        },
        {
          roles: [userGroupRoles.MEMBER.id],
          text: 'Add as Manager',
          onClick: ({ groupUserId }) => updateUserRole({ groupUserId, roleId: userGroupRoles.MANAGER.id }),
          getConfirmModalContent: ({ firstName }) => {
            return {
              title: `Add ${firstName} as Manager?`,
              text: `${firstName} will be able to edit all group information and settings, as well as manage posts and membership requests. `,
            };
          },
          Icon: PersonAddAlt1OutlinedIcon,
        },
        {
          roles: [userGroupRoles.MANAGER.id],
          text: 'Remove as Manager',
          onClick: ({ groupUserId }) => updateUserRole({ groupUserId, roleId: userGroupRoles.MEMBER.id }),
          getConfirmModalContent: ({ firstName }) => {
            return {
              title: `Remove ${firstName} as Manager?`,
              text: `${firstName} will no longer have Manager permissions, but will still be a member of the group.`,
            };
          },
          Icon: PersonRemoveOutlinedIcon,
        },
      ],
      [userGroupRoles.MEMBER.id]: [],
    },
    invited: [
      {
        roles: [userGroupRoles.MEMBER.id],
        text: 'Cancel invite',
        onClick: ({ groupUserId }) => leaveGroup({ groupUserId }),
        getConfirmModalContent: () => {
          return {
            title: 'Cancel invite?',
            text: 'The person you invited won’t get notified that you cancelled the invite.',
          };
        },
        Icon: ClearOutlinedIcon,
      },
      // {
      //   roles: [userGroupRoles.MEMBER.id, userGroupRoles.OWNER.id],
      //   text: 'Approve',
      //   onClick: ({ groupUserId }) => updateUserStatus({ groupUserId, userStatusId: userGroupStatus.ACTIVE.id }),
      //   getConfirmModalContent: () => {
      //     return {
      //       title: 'Approve request?',
      //       text: 'The person became as member of this group.',
      //     };
      //   },
      //   Icon: CheckOutlinedIcon,
      // },
    ],
    requested: [
      {
        roles: [userGroupRoles.MEMBER.id, userGroupRoles.OWNER.id],
        text: 'Approve',
        onClick: ({ groupUserId }) =>
          updateUserStatus({
            groupUserId,
            userStatusId: userGroupStatus.ACTIVE.id,
          }),
        getConfirmModalContent: () => {
          return {
            title: 'Approve request?',
            text: 'The person became as member of this group.',
          };
        },
        Icon: CheckOutlinedIcon,
      },
      {
        roles: [userGroupRoles.MEMBER.id, userGroupRoles.OWNER.id],
        text: 'Decline',
        onClick: ({ groupUserId }) => leaveGroup({ groupUserId }),
        getConfirmModalContent: () => {
          return {
            title: 'Decline request?',
            text: 'The person request to join the group will be declined.',
          };
        },
        Icon: ClearOutlinedIcon,
      },
    ],
    pendingPosts: [
      {
        text: 'Approve',
        onClick: ({ postId }) => updatePostStatus({ postId }),
        getConfirmModalContent: () => {
          return {
            title: 'Approve post?',
            text: 'The post will be published in the group.',
          };
        },
        Icon: CheckOutlinedIcon,
      },
      {
        text: 'Decline',
        onClick: ({ postId }) => deletePost({ postId }),
        getConfirmModalContent: () => {
          return {
            title: 'Decline post?',
            text: 'The post will be deleted.',
          };
        },
        Icon: ClearOutlinedIcon,
      },
    ],
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
      value: index,
    };
  }

  function updateUserStatus({ groupUserId, userStatusId }) {
    const body = {
      accessToken,
      email: user.email,
      acctId: user.acctId,
      groupUserId,
      userStatusId,
    };
    return dispatch(updateUserGroupStatus(body)).then(() => {
      getMembers();
    });
  }
  function updateUserRole({ groupUserId, roleId }) {
    const body = {
      accessToken,
      email: user.email,
      acctId: user.acctId,
      groupUserId,
      roleId,
    };
    return dispatch(updateUserGroupRole(body)).then(() => {
      getMembers();
    });
  }

  function leaveGroup({ groupUserId }) {
    const body = {
      accessToken,
      email: user.email,
      acctId: user.acctId,
      groupUserId,
      groupId,
    };
    return dispatch(leaveUserGroup(body)).then(() => {
      getMembers();
    });
  }
  function getMembers({ lastRecordSize = 0 } = {}) {
    const { OWNER, MANAGER, MEMBER } = userGroupRoles;
    const body = {
      groupId,
      accessToken,
      keyword: '',
      lastRecordSize: lastRecordSize,
      requestRecordSize: perPageCount,
      email: user.email,
      roleTypeId: [OWNER.id, MANAGER.id, MEMBER.id].toString(),
      statusTypeId: selectedTab,
    };
    dispatch(getGroupUsersByStatus(body));
  }

  function getPendingPosts({ lastRecordSize = 0 } = {}) {
    const body = {
      groupId,
      accessToken,
      email: user.email,
      acctId: user.acctId,
      lastRecordSize: lastRecordSize,
      requestRecordSize: perPageCount,
      status: 0,
    };
    dispatch(getPendingPostsByGroupId(body));
  }

  function updatePostStatus({ postId }) {
    const body = {
      accessToken,
      postId,
      status: 1,
      acctId: user.acctId,
      email: user.email,
    };
    return dispatch(updateGroupPostStatus(body)).then(() => {
      getPendingPosts();
    });
  }

  function deletePost({ postId }) {
    const body = {
      accessToken,
      postId,
      acctId: user.acctId,
      email: user.email,
    };
    return dispatch(deleteGroupPost(body)).then(() => {
      getPendingPosts();
    });
  }

  function paginatePosts(pageNumber) {
    const indexOfFirst = pageNumber * perPageCount - perPageCount;
    getPendingPosts({ lastRecordSize: indexOfFirst });
  }

  function paginateMembers(pageNumber) {
    const indexOfFirst = pageNumber * perPageCount - perPageCount;
    getMembers({ lastRecordSize: indexOfFirst });
  }

  const updateWindow = () => {
    setDesktop(window.innerWidth > mobileWidth);
  };

  useEffect(() => {
    if (!modalOpen) {
      setInitialTab(userGroupStatus.ACTIVE.id);
    } else {
      setSelectedTab(userGroupStatus.ACTIVE.id);
      setPendingTab(0);
    }
  }, [modalOpen]);

  useEffect(() => {
    setSelectedTab(initialTab);
  }, [initialTab]);

  useEffect(() => {
    if (modalOpen && selectedTab !== userGroupStatus.INACTIVE.id) {
      getMembers();
    }
    if (modalOpen && selectedTab === userGroupStatus.REQUESTED.id) {
      getPendingPosts();
    }
  }, [selectedTab, modalOpen]);

  useEffect(() => {
    window.addEventListener('resize', updateWindow);
    return () => window.removeEventListener('resize', updateWindow);
  });

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal + ' ' + classes.modalGroupRoot,
      }}
      fullWidth
      maxWidth="md"
      open={modalOpen}
      onClose={toggle}
    >
      <DialogTitle className={classes.modalTitle}>
        <Button simple className={classes.modalCloseButton} key="close" aria-label="Close" onClick={toggle}>
          <IconLibrary.Close />
        </Button>

        <div className={classes.title + ' ' + classes.titleAlign}>Manage group</div>
      </DialogTitle>
      <DialogContent>
        {/* <Box display='flex' flexDirection='row-reverse' position='sticky' top={0} zIndex={1}>
          <Button size='small' variant='outlined'>Invite to group</Button>
        </Box> */}
        <GridContainer spacing={1}>
          <GridItem md={3}>
            {isDesktop && (
              <Tabs
                orientation={'vertical'}
                variant="scrollable"
                value={selectedTab}
                onChange={onTabChange}
                scrollButtons="auto"
                sx={{ borderRight: 1, borderColor: 'divider' }}
                TabIndicatorProps={{ style: { background: '#6F52ED' } }}
              >
                <Tab label="Members" {...a11yProps(userGroupStatus.ACTIVE.id)} />
                {!isUserRoleMember && <Tab label="Invited" {...a11yProps(userGroupStatus.INVITED.id)} />}
                {!isUserRoleMember && <Tab label="Pending Approval" {...a11yProps(userGroupStatus.REQUESTED.id)} />}
                {!isUserRoleMember && <Tab label="Group Settings" {...a11yProps(userGroupStatus.INACTIVE.id)} />}
              </Tabs>
            )}

            {!isDesktop && (
              <Box display="flex" justifyContent="center">
                <FormControl fullWidth>
                  <Select
                    id="manage-group-select"
                    value={selectedTab}
                    onChange={event => onTabChange(event, event.target.value)}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    <MenuItem value={userGroupStatus.ACTIVE.id}>Members</MenuItem>

                    {!isUserRoleMember && <MenuItem value={userGroupStatus.INVITED.id}>Invited</MenuItem>}
                    {!isUserRoleMember && <MenuItem value={userGroupStatus.REQUESTED.id}>Pending Approval</MenuItem>}
                    {!isUserRoleMember && <MenuItem value={userGroupStatus.INACTIVE.id}>Group Settings</MenuItem>}
                  </Select>
                </FormControl>
              </Box>
            )}
          </GridItem>
          <GridItem md={9} style={{ background: '#EFF3FE' }}>
            {selectedTab === userGroupStatus.ACTIVE.id && (
              <MemberList
                users={usersByStatus}
                currentUser={currentUser}
                actions={groupActions.members?.[currentUser?.roleId]}
                {...{ perPageCount, paginate: paginateMembers }}
              />
            )}

            {selectedTab === userGroupStatus.INVITED.id && (
              <MemberList
                users={usersByStatus}
                currentUser={currentUser}
                actions={groupActions.invited}
                {...{ perPageCount, paginate: paginateMembers }}
              />
            )}

            {selectedTab === userGroupStatus.REQUESTED.id && (
              <>
                <Tabs
                  value={pendingTab}
                  onChange={(event, tab) => {
                    setPendingTab(tab);
                  }}
                  sx={{ borderBottom: 1, borderColor: 'divider' }}
                  TabIndicatorProps={{ style: { background: '#6F52ED' } }}
                >
                  <Tab label="Users" {...a11yProps(0)} />
                  <Tab label="Posts" {...a11yProps(1)} />
                </Tabs>

                {pendingTab === 0 && (
                  <MemberList
                    users={usersByStatus}
                    currentUser={currentUser}
                    actions={groupActions.requested}
                    {...{ perPageCount, paginate: paginateMembers }}
                  />
                )}

                {pendingTab === 1 && (
                  <PostList
                    posts={pendingPostsByGroupId}
                    actions={groupActions.pendingPosts}
                    {...{ perPageCount, paginate: paginatePosts }}
                  />
                )}
              </>
            )}

            {selectedTab === userGroupStatus.INACTIVE.id && (
              <GroupSettings
                {...{ currentUser, saveSettingsModalProps, onSaveChangesConfirm, setIsGroupSettingsChanged, isDesktop }}
              />
            )}
          </GridItem>
        </GridContainer>
      </DialogContent>
    </Dialog>
  );
};
export default ManageGroupModal;
