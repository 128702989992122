import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import RightSideBarUserListStyle from "assets/jss/material-kit-pro-react/views/componentStyle/RightSideBarUserListStyle.js";
import {
	Typography,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
} from "@material-ui/core";
import { Skeleton } from "@mui/material";
import candidate_avatar from "../../assets/img/candidate_avatar.jpeg";
import Logo from "../../assets/images/logo.jpg";
import { useSelector, useDispatch } from "react-redux";
import { peopleYouMayKnowInfo } from "configurations/redux/peopleYouMayKnowSlice.js";
import { recommendedGroupsInfo } from "configurations/redux/recommendedGroupsSlice.js";
import { encryptData } from "configurations/configurations.js";
import Button from "components/CustomButtons/Button.js";

const useStyles = makeStyles(RightSideBarUserListStyle);

export default function RightSideBarUserList(props) {
	const {
		removePoll,
		savePostData,
		handleCreatePostClick,
		isOwner,
		match,
	} = props;
	const classes = useStyles();
	const dispatch = useDispatch();

	const peopleYouMayKnow = useSelector(
		(state) => state.peopleYouMayKnow.peopleYouMayKnowResult
	);
	const peopleYouMayKnowIsLoading = useSelector(
		(state) => state.peopleYouMayKnow.isLoading
	);
	const recommendedGroups = useSelector(
		(state) => state.recommendedGroups.recommendedGroupsResult
	);
	const recommendedGroupsIsLoading = useSelector(
		(state) => state.recommendedGroups.isLoading
	);
	const userStatus = useSelector((state) => state.auth.user);

	React.useEffect(() => {
		const requestProfile = {
			acctId: userStatus.acctId,
			accessToken: localStorage.getItem("token"),
			email: userStatus.email,
			lastRecordSize: 0,
			requestRecordSize: 10,
		};
		dispatch(peopleYouMayKnowInfo(requestProfile));
		dispatch(recommendedGroupsInfo(requestProfile));
	}, []);

	return (
		<React.Fragment>
			<div className={classes.LeftSidebar_box} id="socialFeedRightBar">
				<Button
					style={{ display: isOwner ? "block" : "none" }}
					fullWidth
					color="oppty"
					onClick={(ev) => {
						// props.removePoll();
						// props.savePostData("");
						// props.handleCreatePostClick("post");
						props.createPostClick(true);
					}}
					className={classes.createPostBtn}
				// style={props.width < 1200 ? { display: "none" } : {}}
				>
					Create Post
				</Button>
				<div className={classes.RightSidebar_box}>
					<div className={classes.RightSideBarOverflow}>
						<Typography className={classes.sideBarMenuBx} component="div">
							<Typography className='ad_div'>
								<Link>Ad <i class="fa-solid fa-ellipsis"></i></Link>
							</Typography>
							<Typography className={classes.sideBarGroupHead} component="div">
								<h6 className="LatestJobTitle">Get the latest jobs and industry news</h6>
							</Typography>
							<Typography component="div" className='withLogoOuter'>
								<span className='letterSpan'>S</span>
								<img src={Logo} />
							</Typography>
							<Typography className={classes.sideBarGroupHead} component="div">
								<h6 className="LatestJobTitle">Sarah, explore relevant opportunities with <b>OCSD</b></h6>
							</Typography>
							<Button className='followBtn1'>Follow</Button>
						</Typography>
					</div>
				</div>
				<div className="rightSideBarBottom">
					<div className={classes.RightSidebar_box}>
						<div className={classes.RightSideBarOverflow}>
							<Typography className={classes.sideBarMenuBx} component="div">
								<Typography className={classes.sideBarGroupHead} component="div">
									<h6>People You May Know</h6>
								</Typography>
								<Typography component="div" className='peopleKnow'>
									<span className='peopleKnowLeft'></span>
									<Typography className='peopleKnowTitle'>Stanley Burton</Typography>
								</Typography>
								<Typography component="div" className='peopleKnow'>
									<span className='peopleKnowLeft'></span>
									<Typography className='peopleKnowTitle'>Stanley Burton</Typography>
								</Typography>
								<Typography component="div" className='peopleKnow'>
									<span className='peopleKnowLeft'></span>
									<Typography className='peopleKnowTitle'>Stanley Burton</Typography>
								</Typography>
								<Typography component="div" className='peopleKnow'>
									<span className='peopleKnowLeft'></span>
									<Typography className='peopleKnowTitle'>Stanley Burton</Typography>
								</Typography>
								<Typography component="div" className='peopleKnow'>
									<span className='peopleKnowLeft'></span>
									<Typography className='peopleKnowTitle'>Stanley Burton</Typography>
								</Typography>
							</Typography>
							{/* <Typography className={classes.sideBarMenuBx} component="div">
							<Typography className={classes.sideBarGroupHead} component="div">
								<h6>People You May Know</h6>
							</Typography>
							{peopleYouMayKnowIsLoading ? (
								[1, 2, 3, 4, 5].map((x) => {
									return (
										<div className={classes.skeletonGrp} key={x}>
											<Skeleton
												animation="wave"
												variant="circular"
												height={30}
												width={30}
												style={{ marginBottom: 15, marginRight: 10 }}
											/>
											<Skeleton
												animation="wave"
												height={22}
												width="80%"
												style={{ marginBottom: 15 }}
											/>
										</div>
									);
								})
							) : peopleYouMayKnow.length > 0 ? (
								<List component="nav" className={classes.sideBarMenu}>
									{peopleYouMayKnow.map((x) => {
										return (
											<Link
												to={`/view-profile/${encryptData(x.acctId)}`}
												key={"profile_" + x.acctId}
											>
												<ListItem>
													<ListItemIcon className={classes.profileImg}>
														<img
															src={
																x.profilePic ? x.profilePic : candidate_avatar
															}
															alt=""
														/>
													</ListItemIcon>
													<ListItemText
														primary={`${x.firstName} ${x.middleName} ${x.surname}`}
													/>

													{new Date() - new Date(x.lastLoginDate) < 600000 ? (
														<Typography
															component="span"
															className={classes.onlineStatus}
														></Typography>
													) : null}
												</ListItem>
											</Link>
										);
									})}
								</List>
							) : (
								<List component="nav" className={classes.sideBarMenu}>
									<div className={classes.sideBarNoResultText}>
										Start adding connections to receive profile recommendations!
									</div>

									<div className={classes.sideBarNavigateButton}>
										<Link
											key="manageConnection"
											to={{
												pathname: `/manage-connection/${encryptData(
													userStatus.acctId
												)}`,
											}}
											// target="_blank"
											rel="noopener noreferrer"
										>
											Manage Connections
										</Link>
									</div>
								</List>
							)}
						</Typography> */}
							{/* <Typography className={classes.sideBarMenuBx} component="div">
							<Typography className={classes.sideBarGroupHead} component="div">
								<h6>Recommended Groups</h6>
							</Typography>

							{recommendedGroupsIsLoading ? (
								[1, 2, 3, 4, 5].map((x) => {
									return (
										<div className={classes.skeletonGrp} key={x}>
											<Skeleton
												animation="wave"
												variant="circular"
												height={30}
												width={30}
												style={{ marginBottom: 15, marginRight: 10 }}
											/>
											<Skeleton
												animation="wave"
												height={22}
												width="80%"
												style={{ marginBottom: 15 }}
											/>
										</div>
									);
								})
							) : recommendedGroups.length > 0 ? (
								<List component="nav" className={classes.sideBarMenu}>
									{recommendedGroups.map((x) => {
										return (
											<Link
												to={`/groups/${encryptData(x.groupId)}`}
												key={"group_" + x.groupId}
											>
												<ListItem>
													<ListItemIcon className={classes.profileImg}>
														<img src={x.profilePic} alt="" />
													</ListItemIcon>
													<ListItemText primary={x.groupName} />
												</ListItem>
											</Link>
										);
									})}
								</List>
							) : (
								<List component="nav" className={classes.sideBarMenu}>
									<div className={classes.sideBarNoResultText}>
										Choose the industries you’re interested in to see
										recommended groups for you!
									</div>
									<div className={classes.sideBarNavigateButton}>
										<Link
											key="viewProfile"
											to={{
												pathname: `/view-profile/${encryptData(
													userStatus.acctId
												)}`,
											}}
											// target="_blank"
											rel="noopener noreferrer"
										>
											Manage My Profile
										</Link>
									</div>
								</List>
							)}
						</Typography> */}
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}
