import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Typography,
  makeStyles,
  Button,
  TextField,
  Hidden,
  FormControlLabel,
  Checkbox,
  Switch,
  Slide,
} from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { CheckBox, CheckBoxOutlineBlankOutlined } from "@material-ui/icons";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import {
  instanceUrl,
  getLocationIdByCountry,
  getStartYearData,
  getEndYearData,
  getMonthData,
} from "configurations/configurations.js";
import {
  updateExperienceProfile,
  getAllDep,
  getAllIndustry,
} from "configurations/redux/userSlice.js";
import axios from "axios";
import { Formik } from "formik";

import addNewWorkExperienceCardStyles from "assets/jss/material-kit-pro-react/views/componentStyle/profileView/editProfile/workExperience/addNewWorkExperienceCardStyles";

const AddNewWorkExperience = (props) => {
  const styles = makeStyles(addNewWorkExperienceCardStyles)();

  const { experience, onClose } = props;

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const startYearDataList = getStartYearData();
  const endYearDataList = getEndYearData();
  const monthDataList = getMonthData();

  const [countryList, setCountryList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [countryChange, setCountryChange] = React.useState();
  const [industryChange, setIndustryChange] = React.useState();
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);

  useEffect(() => {
    let mounted = true;
    let CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    dispatch(getAllIndustry());

    if (mounted) {
      axios
        .get(`${instanceUrl}/api/md/get-all-country`, {
          cancelToken: source.token,
        })
        .then((res) => {
          setCountryList(res.data.payload);
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log("unmount caused");
          } else {
            console.log(error);
          }
        });
    }
    return () => {
      mounted = false;
      source.cancel();
    };
  }, []);

  useEffect(() => {
    console.log(countryChange);
    if (countryChange) {
      const fetchLocationListByCountry = async (countryId) => {
        const res = await getLocationIdByCountry(countryId);
        setLocationList(res.data.payload);
      };
      fetchLocationListByCountry(countryChange);
    }
  }, [countryChange]);

  useEffect(() => {
    console.log(industryChange);
    if (industryChange) {
      dispatch(getAllDep(industryChange));
    }
  }, [industryChange]);

  const [recIds, setRecIds] = React.useState([]);

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const validate = (values, props) => {
    const errors = {};

    if (!values.organization) {
      errors.organization = "Required";
    }

    if (!values.title) {
      errors.title = "Required";
    }

    if (!values.startMonth) {
      errors.startMonth = "Required";
    }

    if (!values.startYear) {
      errors.startYear = "Required";
    }

    return errors;
  };

  return (
    <Typography component="div" className={styles.body}>
      <Formik
        enableReinitialize
        initialValues={{
          expRecId: -1,
          title: "",
          countryId: undefined,
          locationId: undefined,
          organization: "",
          startMonth: "",
          startYear: "",
          endMonth: "",
          endYear: "",
          currentInProgressFlag: 0,
          achievement: "",
          description: "",
          industryId: 0,
          departmentId: 0,
          customIndustryName: "",
          customDepartmentName: "",
          autoEnd: "",
          experienceAutoEnd: [],
          requestNotifyRecord: true,
          isVisible: 1,
        }}
        validate={validate}
        onSubmit={async (values, { resetForm }) => {
          await new Promise((resolve) => setTimeout(resolve, 500));

          let experienceAutoEnd = [];

          if (recIds.length === 0) {
            experienceAutoEnd = [];
          } else {
            for (let i = 0; i < recIds.length; i++) {
              let x = {};
              x["recId"] = recIds[i];
              x["value"] = true;
              experienceAutoEnd.push(x);
            }
          }

          const endDate =
            values.currentInProgressFlag === 1
              ? null
              : values.endYear + "-" + values.endMonth + "-01";

          const requestData = {
            expRecId: -1,
            acctId: user.acctId,
            title: values.title,
            countryId: values.countryId,
            locationId: values.locationId,
            organization: values.organization,
            startDate: values.startYear + "-" + values.startMonth + "-01",
            endDate: endDate,
            currentInProgressFlag: values.currentInProgressFlag,
            achievement: values.achievement,
            description: values.description,
            email: user.email,
            industryId: values.industryId,
            departmentId: values.departmentId,
            customIndustryName: values.customIndustryName,
            customDepartmentName: values.customDepartmentName,
            accessToken: localStorage.getItem("token"),
            experienceAutoEnd: experienceAutoEnd,
            requestNotifyRecord: values.requestNotifyRecord,
            isVisible: 1,
          };
          dispatch(updateExperienceProfile(requestData));

          setRecIds([]);

          resetForm();
        }}
      >
        {({
          handleChange,
          values,
          errors,
          touched,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <GridContainer>
              <GridItem className={styles.header}>
                <Typography component="h2" className={styles.title}>
                  Add Work Experience
                </Typography>

                <Typography component="div">
                  <Button
                    className={styles.saveExperienceBtn}
                    disabled={isSubmitting}
                    type="submit"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="18"
                      viewBox="0 0 24 18"
                    >
                      <path
                        id="Path"
                        d="M7.636,14.239,1.909,8.6,0,10.478,7.636,18,24,1.881,22.091,0Z"
                        fill="#0ab98d"
                      />
                    </svg>
                    <span>SAVE</span>
                  </Button>
                  <Button
                    className={styles.cancelEditBtn}
                    onClick={() => setIsCancelDialogOpen(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                    >
                      <path
                        id="Path"
                        d="M14,1.41,12.59,0,7,5.59,1.41,0,0,1.41,5.59,7,0,12.59,1.41,14,7,8.41,12.59,14,14,12.59,8.41,7Z"
                        fill="#4a4a4a"
                      />
                    </svg>
                    <span>CANCEL</span>
                  </Button>
                </Typography>
              </GridItem>

              <GridItem className={styles.gridHeight}>
                <TextField
                  variant="outlined"
                  id="title"
                  name="title"
                  placeholder="Title"
                  value={values.title}
                  helperText={touched.title ? errors.title : ""}
                  error={touched.title && Boolean(errors.title)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  InputProps={{
                    autoComplete: "off",
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={6} md={6} className={styles.gridHeight}>
                <TextField
                  variant="outlined"
                  id="organization"
                  name="organization"
                  placeholder="Organization"
                  value={values.organization}
                  helperText={touched.organization ? errors.organization : ""}
                  error={touched.organization && Boolean(errors.organization)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  InputProps={{
                    autoComplete: "off",
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={6} md={6} className={styles.gridHeight}>
                <Autocomplete
                  options={countryList}
                  onBlur={handleBlur}
                  freeSolo
                  value={
                    countryList.filter((item) => {
                      return item.countryId === values.countryId;
                    })[0] || ""
                  }
                  getOptionSelected={(option, value) => {
                    if (!value) {
                      return false;
                    }
                    return option.countryId === value.countryId;
                  }}
                  getOptionLabel={(option) =>
                    option.countryName ? option.countryName : ""
                  }
                  filterOptions={createFilterOptions({
                    matchFrom: "start",
                    stringify: (option) => option.countryName,
                  })}
                  onChange={(event, value) => {
                    if (value) {
                      setFieldValue("countryId", value.countryId);
                      setCountryChange(value.countryId);
                    } else {
                      setFieldValue("countryId", "");
                      setFieldValue("locationId", "");
                    }
                  }}
                  id="countryId"
                  name="countryId"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Country"
                      helperText={touched.countryId ? errors.countryId : ""}
                      error={touched.countryId && Boolean(errors.countryId)}
                    />
                  )}
                />
              </GridItem>

              <GridItem xs={12} sm={6} md={6} className={styles.gridHeight}>
                <React.Fragment>
                  <Autocomplete
                    options={locationList}
                    disabled={!values.countryId}
                    onBlur={handleBlur}
                    value={
                      locationList.filter((item) => {
                        return item.locationId === values.locationId;
                      })[0] || ""
                    }
                    getOptionSelected={(option, value) => option === value}
                    getOptionLabel={(option) =>
                      option.locationName ? option.locationName : ""
                    }
                    onChange={(event, value) => {
                      if (value) {
                        setFieldValue("locationId", value.locationId);
                      } else {
                        setFieldValue("locationId", "");
                      }
                    }}
                    id="locationId"
                    name="locationId"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Location"
                        helperText={touched.locationId ? errors.locationId : ""}
                        error={touched.locationId && Boolean(errors.locationId)}
                      />
                    )}
                  />
                </React.Fragment>
              </GridItem>

              <GridItem className={styles.gridHeight}>
                <Fragment>
                  <Autocomplete
                    options={user.allIndustry}
                    onBlur={handleBlur}
                    value={
                      user?.allIndustry?.filter((item) => {
                        return item.industryId === values.industryId;
                      })[0] || ""
                    }
                    getOptionSelected={(option, value) => option === value}
                    getOptionLabel={(option) =>
                      option.industryName ? option.industryName : ""
                    }
                    onChange={(event, value) => {
                      console.log(value);
                      if (value) {
                        setFieldValue("industryId", value.industryId);
                        setIndustryChange(value.industryId);
                      } else {
                        setFieldValue("industryId", "");
                      }
                    }}
                    id="industryId"
                    name="industryId"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Industry"
                        helperText={touched.industryId ? errors.industryId : ""}
                        error={touched.industryId && Boolean(errors.industryId)}
                      />
                    )}
                  />
                </Fragment>
              </GridItem>

              <GridItem className={styles.gridHeight}>
                <Fragment>
                  <Autocomplete
                    options={user?.allDep}
                    disabled={!values.industryId}
                    onBlur={handleBlur}
                    value={
                      user?.allDep?.filter((item) => {
                        return item.deptId === values.deptId;
                      })[0] || ""
                    }
                    getOptionSelected={(option, value) => option === value}
                    getOptionLabel={(option) =>
                      option.deptName ? option.deptName : ""
                    }
                    onChange={(event, value) => {
                      console.log(value);
                      if (value) {
                        setFieldValue("industryId", value.deptId);
                        setIndustryChange(value.deptId);
                      } else {
                        setFieldValue("industryId", "");
                      }
                    }}
                    id="departmentId"
                    name="departmentId"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Department"
                        helperText={touched.deptId ? errors.deptId : ""}
                        error={touched.deptId && Boolean(errors.deptId)}
                      />
                    )}
                  />
                </Fragment>
              </GridItem>

              {values.industryId === 0 ? (
                <GridItem className={styles.gridHeight}>
                  <TextField
                    variant="outlined"
                    id="customIndustryName"
                    name="customIndustryName"
                    placeholder="Custom Industry Name"
                    value={values.customIndustryName}
                    helperText={
                      touched.customIndustryName
                        ? errors.customIndustryName
                        : ""
                    }
                    error={
                      touched.customIndustryName &&
                      Boolean(errors.customIndustryName)
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    InputProps={{
                      autoComplete: "off",
                    }}
                  />
                </GridItem>
              ) : null}

              {values.departmentId === 0 ? (
                <GridItem className={styles.gridHeight}>
                  <TextField
                    variant="outlined"
                    id="customDepartmentName"
                    name="customDepartmentName"
                    placeholder="Custom Department Name"
                    value={values.customDepartmentName}
                    helperText={
                      touched.customDepartmentName
                        ? errors.customDepartmentName
                        : ""
                    }
                    error={
                      touched.customDepartmentName &&
                      Boolean(errors.customDepartmentName)
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    InputProps={{
                      autoComplete: "off",
                    }}
                  />
                </GridItem>
              ) : null}

              <GridItem xs={12} sm={12} md={12} lg={6}>
                <h4 className={styles.modalTitle + " " + styles.grayFont}>
                  Starting From
                </h4>
              </GridItem>

              <Hidden mdDown>
                <GridItem xs={12} sm={12} md={12} lg={6}>
                  {values.currentInProgressFlag === 0 ? (
                    <h4 className={styles.modalTitle + " " + styles.grayFont}>
                      Ending In
                    </h4>
                  ) : null}
                </GridItem>
              </Hidden>

              <GridItem
                xs={6}
                sm={6}
                md={6}
                lg={3}
                className={styles.gridHeight}
              >
                <React.Fragment>
                  <Autocomplete
                    options={monthDataList}
                    onBlur={handleBlur}
                    value={
                      monthDataList.filter((item) => {
                        return item.monthId === values.startMonth;
                      })[0] || null
                    }
                    getOptionLabel={(option) =>
                      option.monthName ? option.monthName : ""
                    }
                    getOptionSelected={(option, value) =>
                      option.monthName === value.monthName
                    }
                    onChange={(event, value) => {
                      console.log(value);
                      if (value) {
                        setFieldValue("startMonth", value.monthId);
                      } else {
                        setFieldValue("startMonth", "0");
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Month"
                        helperText={touched.startMonth ? errors.startMonth : ""}
                        error={touched.startMonth && Boolean(errors.startMonth)}
                      />
                    )}
                  />
                </React.Fragment>
              </GridItem>

              <GridItem
                xs={6}
                sm={6}
                md={6}
                lg={3}
                className={styles.gridHeight}
              >
                <React.Fragment>
                  <Autocomplete
                    options={startYearDataList}
                    onBlur={handleBlur}
                    value={
                      startYearDataList.filter((item) => {
                        return item === values.startYear;
                      })[0] || null
                    }
                    getOptionSelected={(option, value) => option === value}
                    getOptionLabel={(option) => option}
                    onChange={(event, value) => {
                      if (value) {
                        setFieldValue("startYear", value);
                      } else {
                        setFieldValue("startYear", "0");
                        setFieldValue("startMonth", "0");
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Year"
                        helperText={touched.startYear ? errors.startYear : ""}
                        error={touched.startYear && Boolean(errors.startYear)}
                      />
                    )}
                  />
                </React.Fragment>
              </GridItem>

              {values.currentInProgressFlag === 0 ? (
                <Hidden lgUp>
                  <GridItem xs={12} sm={12} md={12}>
                    <h4 className={styles.modalTitle + " " + styles.grayFont}>
                      Ending In
                    </h4>
                  </GridItem>
                </Hidden>
              ) : null}

              {values.currentInProgressFlag === 0 ? (
                <React.Fragment>
                  <GridItem
                    xs={6}
                    sm={6}
                    md={6}
                    lg={3}
                    className={styles.gridHeight}
                  >
                    <React.Fragment>
                      <Autocomplete
                        options={monthDataList}
                        onBlur={handleBlur}
                        value={
                          monthDataList.filter((item) => {
                            return item.monthId === values.endMonth;
                          })[0] || null
                        }
                        getOptionLabel={(option) =>
                          option.monthName ? option.monthName : ""
                        }
                        getOptionSelected={(option, value) =>
                          option.monthName === value.monthName
                        }
                        onChange={(event, value) => {
                          console.log(value);
                          if (value) {
                            setFieldValue("endMonth", value.monthId);
                          } else {
                            setFieldValue("endMonth", "0");
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Month"
                            helperText={touched.endMonth ? errors.endMonth : ""}
                            error={touched.endMonth && Boolean(errors.endMonth)}
                          />
                        )}
                      />
                    </React.Fragment>
                  </GridItem>

                  <GridItem
                    xs={6}
                    sm={6}
                    md={6}
                    lg={3}
                    className={styles.gridHeight}
                  >
                    <React.Fragment>
                      <Autocomplete
                        options={endYearDataList}
                        onBlur={handleBlur}
                        value={
                          endYearDataList.filter((item) => {
                            return item === values.endYear;
                          })[0] || null
                        }
                        getOptionSelected={(option, value) => option === value}
                        getOptionLabel={(option) => option}
                        onChange={(event, value) => {
                          if (value) {
                            setFieldValue("endYear", value);
                          } else {
                            setFieldValue("endYear", "0");
                            setFieldValue("endMonth", "0");
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Year"
                            helperText={touched.endYear ? errors.endYear : ""}
                            error={touched.endYear && Boolean(errors.endYear)}
                          />
                        )}
                      />
                    </React.Fragment>
                  </GridItem>
                </React.Fragment>
              ) : null}

              <GridItem className={styles.gridCheckBoxesHeight}>
                <div className={styles.checkMargin}>
                  <FormControlLabel
                    classes={{
                      label: styles.label,
                    }}
                    control={
                      <Checkbox
                        onChange={(event, value) => {
                          if (value) {
                            setFieldValue("currentInProgressFlag", 1);
                          } else {
                            setFieldValue("currentInProgressFlag", 0);
                          }
                        }}
                        name="currentInProgressFlag"
                        checkedIcon={
                          <CheckBox className={styles.checkedIcon} />
                        }
                        icon={
                          <CheckBoxOutlineBlankOutlined
                            className={styles.uncheckedIcon}
                          />
                        }
                        classes={{
                          checked: styles.checked,
                          root: styles.checkRoot,
                        }}
                        checked={
                          values.currentInProgressFlag === 1 ? true : false
                        }
                      />
                    }
                    label={
                      <span
                        className={styles.checkBoxLabel + " " + styles.grayFont}
                      >
                        I am currently working in this organization
                      </span>
                    }
                  />
                </div>
              </GridItem>

              <GridItem xs={12} sm={12} md={12} className={styles.gridPadding}>
                <TextField
                  variant="outlined"
                  id="achievement"
                  name="achievement"
                  placeholder="Achievemnets"
                  value={values.achievement}
                  helperText={touched.achievement ? errors.achievement : ""}
                  error={touched.achievement && Boolean(errors.achievement)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  InputProps={{
                    multiline: true,
                    rows: 3,
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={12} className={styles.gridPadding}>
                <TextField
                  variant="outlined"
                  id="description"
                  name="description"
                  placeholder="Description"
                  value={values.description}
                  helperText={touched.description ? errors.description : ""}
                  error={touched.description && Boolean(errors.description)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  InputProps={{
                    multiline: true,
                    rows: 3,
                  }}
                />
              </GridItem>

              <GridItem xs={12} className={styles.footer}>
                <Typography component="div">
                  <span>Notify Your Connections</span>
                  <FormControlLabel
                    control={
                      <Switch
                        name="notify"
                        checked={values.notify}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                        size="small"
                      />
                    }
                  />
                </Typography>
                <Typography component="p">
                  Turn on to share this work experience to your social profile
                  on OppTy.
                </Typography>
              </GridItem>

              {user?.experienceRecords.map((x, i) => {
                return (
                  <GridItem xs={12} className={styles.footer} key={i}>
                    <Typography component="div">
                      <span>Auto End: &quot;{x.organization}&quot;</span>
                      <FormControlLabel
                        control={
                          <Switch
                            name="autoEnd"
                            checked={values[`autoEnd${i}`] === true}
                            onChange={(event, checked) => {
                              setFieldValue(
                                `autoEnd${i}`,
                                checked ? true : false
                              );
                              setFieldValue(
                                "recordIds",
                                checked
                                  ? setRecIds([...recIds, x?.expRecId])
                                  : recIds.indexOf(x?.expRecId) > -1
                                  ? recIds.splice(
                                      recIds.indexOf(x?.expRecId),
                                      1
                                    )
                                  : null
                              );
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                            size="small"
                          />
                        }
                      />
                    </Typography>
                  </GridItem>
                );
              })}
            </GridContainer>
          </form>
        )}
      </Formik>

      <div
        className={styles.dialogContainer}
        onClick={() => {
          setIsCancelDialogOpen(false);
        }}
        style={{ display: isCancelDialogOpen ? "block" : "none" }}
      >
        <div className={styles.dialog}>
          <h2 className={styles.dialogTitle}>Discard Entry?</h2>
          <p className={styles.dialogText}>
            Any information entered will be lost.
          </p>

          <div className={styles.dialogActions}>
            <Button onClick={() => setIsCancelDialogOpen(false)}>Cancel</Button>
            <Button
              onClick={() => {
                onClose();
                setIsCancelDialogOpen(false);
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </Typography>
  );
};
export default AddNewWorkExperience;
