import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { roadmapJobCreateRedirect } from "configurations/redux/roadmapSlice.js";
import { Link } from "react-router-dom";

// import Button from "@material-ui/core/Button";
import Button from "components/CustomButtons/Button.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Card from "components/Card/Card.js";

import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

// import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js";

import NoActivityStatusModal from "../../NoActivityStatusModal/NoActivityStatusModal";
// import Backdrop from "../../../Component/Backdrop.js";
// import Close from "@material-ui/icons/Close";
// import { ReactReduxContext } from "react-redux";
import IconLibrary from "views/Component/IconLibrary";
import { CircularProgress } from "@material-ui/core";
const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function RoleInfoModal({ open, onClose }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const isUpdated = useSelector((state) => state.roadmap.isUpdated);
	const isLoading = useSelector((state) => state.roadmap.isLoading);
	const user = useSelector((state) => state.auth.user);
	const bizId = useSelector((state) => state.business.bizId);

	const selectedRoadMapRole = useSelector(
		(state) => state.roadmap.selectedRoadMapRole
	);
	const modalResize = () => {
		// var timer = setInterval(() => {
		var modalResize = document.getElementById("modalRole");
		var modalGrid = document.getElementById("modalGrid");
		if (modalResize && modalGrid) {
			var modalSize = modalGrid.clientHeight + 60;
			// clearInterval(timer);
			var maxSize = window.innerHeight - 74 - 90 - 30;
			if (maxSize < 0) maxSize = 0;
			// console.log(maxSize);
			if (maxSize > modalSize) modalResize.style.height = modalSize + "px";
			else modalResize.style.height = maxSize + "px";
			// } else {
			// 	setTimeout(() => {
			// 		clearInterval(timer);
			// 	}, 3000);
		}
		// }, 50);
	};

	// React.useEffect(() => {
	// 	window.addEventListener("resize", modalResize);
	// });

	React.useEffect(() => {
		if (open) {
			if (document.readyState === "complete") {
				setTimeout(() => {
					modalResize();
				}, 500);
			}
			window.addEventListener("resize", modalResize);
			return function cleanup() {
				window.removeEventListener("resize", modalResize);
			};
		}
	}, [open]);

	// React.useEffect(() => {
	// 	if (open) modalResize();
	// 	else {
	// 		try {
	// 			window.removeEventListener("resize");
	// 		} catch (error) {}
	// 	}
	// 	return function cleanup() {
	// 		try {
	// 			window.removeEventListener("resize");
	// 		} catch (error) {}
	// 	};
	// }, [selectedRoadMapRole]);

	// console.log(selectedRoadMapRole);
	return isUpdated ? (
		selectedRoadMapRole[0] !== undefined ? (
			<div>
				<Dialog
					classes={{
						root: classes.modalRoot,
						paper: classes.modalRoadMapShow + " " + classes.modal,
					}}
					open={open}
					onClose={onClose}
					aria-labelledby="alert-dialog-slide-title"
					aria-describedby="alert-dialog-slide-description"
				>
					<DialogTitle
						id="signup-modal-slide-title"
						disableTypography
						className={classes.modalTitleEdit}
					>
						<Button
							simple
							className={classes.modalCloseButton}
							key="close"
							aria-label="Close"
							onClick={onClose}
						>
							{" "}
							<IconLibrary.Close />
						</Button>
						<h3 className={classes.title + " " + classes.textAligned}>
							{/* {selectedRoadMapRole && selectedRoadMapRole[0].roleName} */}
							Career Role Detail
						</h3>
					</DialogTitle>
					<div className={classes.smallModalBody} id="modalRole">
						<DialogContent
							id="classic-modal-slide-description"
							className={classes.modalBody}
						>
							{/* {!isLoading ? null : <Backdrop />} */}
							{isLoading ? (
								<GridContainer justifyContent="center">
									<GridItem className={classes.textAligned}>
										<CircularProgress />
									</GridItem>
								</GridContainer>
							) : (
								<div>
									<h3 className={classes.title + " " + classes.careerRoleName}>
										{selectedRoadMapRole && selectedRoadMapRole[0].roleName}
									</h3>
									<GridContainer id="modalGrid">
										<GridItem>
											{selectedRoadMapRole &&
											selectedRoadMapRole[0].jobDescription !== "" ? (
												<Card className={classes.cardNoShadow}>
													<CardBody>
														<h4 className={classes.title}>Job Description:</h4>
														<p className={classes.roleDescription}>
															{selectedRoadMapRole &&
																selectedRoadMapRole[0].jobDescription}
														</p>
													</CardBody>
												</Card>
											) : null}
										</GridItem>
										{selectedRoadMapRole &&
										selectedRoadMapRole[0].industryName !== "" ? (
											<GridItem
												xs={12}
												sm={6}
												md={6}
												className={classes.gridMargin}
											>
												<Card className={classes.cardNoShadow}>
													<CardBody>
														<h4 className={classes.title}>Industry:</h4>
														<p className={classes.roleDescription}>
															{selectedRoadMapRole[0].industryName}
														</p>
													</CardBody>
												</Card>
											</GridItem>
										) : null}
										{selectedRoadMapRole &&
										selectedRoadMapRole[0].experience !== "" ? (
											<GridItem
												xs={12}
												sm={6}
												md={6}
												className={classes.gridMargin}
											>
												<Card className={classes.cardNoShadow}>
													<CardBody>
														<h4 className={classes.title}>
															Experience Required:
														</h4>
														<p className={classes.roleDescription}>
															{selectedRoadMapRole &&
															selectedRoadMapRole[0].experience === "0"
																? "Entry Level"
																: selectedRoadMapRole[0].experience === "-" ||
																  selectedRoadMapRole[0].experience === ""
																? "N.A"
																: selectedRoadMapRole[0].experience +
																  " year(s)"}
														</p>
													</CardBody>
												</Card>
											</GridItem>
										) : null}
										{selectedRoadMapRole &&
										selectedRoadMapRole[0].salaryRange !== "" ? (
											<GridItem
												xs={12}
												sm={6}
												md={6}
												className={classes.gridMargin}
											>
												<Card className={classes.cardNoShadow}>
													<CardBody>
														<h4 className={classes.title}>Salary Range:</h4>
														<p className={classes.roleDescription}>
															{selectedRoadMapRole &&
																selectedRoadMapRole[0].salaryRange}
														</p>
													</CardBody>
												</Card>
											</GridItem>
										) : null}
										{selectedRoadMapRole &&
										selectedRoadMapRole[0].deptName !== "" ? (
											<GridItem
												xs={12}
												sm={6}
												md={6}
												className={classes.gridMargin}
											>
												<Card className={classes.cardNoShadow}>
													<CardBody>
														<h4 className={classes.title}>Department:</h4>
														<p className={classes.roleDescription}>
															{selectedRoadMapRole &&
																selectedRoadMapRole[0].deptName}
														</p>
													</CardBody>
												</Card>
											</GridItem>
										) : null}
										<GridItem className={classes.gridMargin}>
											{selectedRoadMapRole &&
											selectedRoadMapRole[0].qualification !== "" ? (
												<Card className={classes.cardNoShadow}>
													<CardBody>
														<h4 className={classes.title}>Qualification:</h4>
														<p className={classes.roleDescription}>
															{selectedRoadMapRole &&
																selectedRoadMapRole[0].qualification}
														</p>
													</CardBody>
												</Card>
											) : null}
										</GridItem>
									</GridContainer>
								</div>
							)}
						</DialogContent>
					</div>
					<DialogActions
						className={
							classes.modalFooterEdit + " " + classes.modalFooterPadding
						}
					>
						{bizId > 0 ? (
							<Link
								className={classes.textDeco}
								target="_blank"
								to={{
									pathname: `/corporate-job-create-page`,
									//state: { acctId: item.acctId },
								}}
								color="transparent"
							>
								<Button
									round
									onClick={() => {
										dispatch(roadmapJobCreateRedirect());
										// onClose();
									}}
									color="oppty"
								>
									<b>Create Job Post Using This Template</b>
								</Button>
							</Link>
						) : (
							<Button round onClick={onClose} color="oppty">
								<b>Close</b>
							</Button>
						)}
					</DialogActions>
				</Dialog>
			</div>
		) : (
			<div>
				<Dialog
					classes={{
						root: classes.modalRoot,
						paper: classes.modalRoadMapShow + " " + classes.modal,
					}}
					open={open}
					onClose={onClose}
					aria-labelledby="alert-dialog-slide-title"
					aria-describedby="alert-dialog-slide-description"
				>
					<DialogTitle
						id="signup-modal-slide-title"
						disableTypography
						className={classes.modalTitleEdit}
					>
						<Button
							simple
							className={classes.modalCloseButton}
							key="close"
							aria-label="Close"
							onClick={onClose}
						>
							{" "}
							<IconLibrary.Close />
						</Button>
						<h3 className={classes.title}>No Result Found</h3>
					</DialogTitle>
					<DialogActions
						className={
							classes.modalFooterEdit + " " + classes.modalFooterPadding
						}
					>
						<Button round onClick={onClose} color="oppty">
							<b>Close</b>
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		)
	) : (
		<NoActivityStatusModal />
	);
}
