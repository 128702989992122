import React, { Fragment, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import {
    activeCandidatePlan
} from "configurations/redux/subscriptionSetting";

import { Box, Button, makeStyles, Modal, Typography, Popover, IconButton } from '@material-ui/core';
import CloseIcon from "@mui/icons-material/Close";
import Header from 'views/Component/Header.js';
import LeftSidebar from 'views/Component/LeftSidebar.js';
import Checkbox from '@mui/material/Checkbox';
import Footer from 'views/Component/Footer.js';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

import { activateMasterCode, cleanMasterCode } from 'configurations/redux/masterFunctionSlice.js';
import { activateEditType, deactivateEditType } from 'configurations/redux/editProfileModalSlice';
import { errorCleanUp } from 'configurations/redux/authSlice.js';
import { decryptData, encryptData } from 'configurations/configurations.js';
import { getProfiles } from 'configurations/redux/userSlice.js';
import Hidden from '@material-ui/core/Hidden';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import testimonialsHeader from 'assets/img/testimonials-header.png';

import testimonialsPageStyles from 'assets/jss/material-kit-pro-react/views/pageStyle/TestimonialsPage/testimonialsPageStyles';
import RightSideBarUserList from 'views/Component/RightSideBarUserList.js';
import EditModal from 'views/Component/ProfileView/EditProfile/EditModal.js';

//Attachment Related functions
import { getUserAttachmentTypeInfo } from 'configurations/redux/getUserAttachmentTypeSlice';
import { getUserAttachmentByAcctIdInfo } from 'configurations/redux/getUserAttachmentByAcctIdSlice';
import { createUserAttachmentInfo } from 'configurations/redux/createUserAttachmentSlice';
import { checkDuplicateInfo, checkDuplicateReset } from 'configurations/redux/checkDuplicateSlice';

import { createAttachment, createAttachmentVideo, getDocument, getVideo, createDocumentAttachment, getAttachment, updateAttachment, deleteAttachment, createShareLink, updateAttachmentVisibility } from 'configurations/redux/attachmentUserSlice.js';
import { deleteUserAttachmentInfo } from 'configurations/redux/deleteUserAttachmentSlice';
import { getUserSubscriptionByRecordId } from 'configurations/redux/subscriptionSetting';

import { useSnackbar } from "notistack";

import { CopyToClipboard } from 'react-copy-to-clipboard';
// Loader start
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';


//import { getVideoDurationInSeconds } from 'get-video-duration';


const UploadLanding = ({ match }) => {
    const styles = makeStyles(testimonialsPageStyles)();
    const history = useHistory();
    const dispatch = useDispatch();
    const [optionToShow, setOption] = useState('video');
    const [isViewed, setIsViewed] = useState();
    const userStatus = useSelector(state => state.auth.user);
    const isMe = decryptData(match.params.acctId) === userStatus.acctId;
    //const [attachmentType, setAttachmentType] = React.useState(5);

    //Static payment varible for now
    const [paymentQuotaVideo, setpaymentQuotaVideo] = React.useState(1);
    const [videoQuota, setVideoQuota] = React.useState(-1);
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [open1, setOpen] = React.useState(false);
    const handleOpen1 = () => setOpen(true);
    const handleClose1 = () => setOpen(false);
    const [open2, setOpen1] = React.useState(false);
    const handleOpen2 = () => setOpen1(true);
    const handleClose2 = () => setOpen1(false);

    /*loaderstart start*/
    const [openLoader, setOpenLoader] = React.useState(false);
    const showLoader = () => {
        setOpenLoader(true);
    };
    const hideLoader = () => {
        setOpenLoader(false);
    };
    /*loaderstart end*/

    /*sourabh start*/

    const [isCheck, setIsCheck] = useState([]);
    const [currentOpenTab, setCurrentOpenTab] = React.useState('video');
    const [attachmentType, setAttachmentType] = React.useState(5);
    const [copied, setCopied] = React.useState(false);

    const [clipboardValue, setClipboardValue] = React.useState('');
    const [clipboardCopied, setClipboardCopied] = React.useState(false);

    const [shareRecId, setShareRecId] = React.useState(0);

    /*sourabh end*/

    const [currLocation, setCurrLocation] = React.useState('');

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 46,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#554BE9',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));

    React.useEffect(() => {
        activePlanOfVideo()
    }, []);

    //console.log(match);
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        history.replace(match.url);
    }, []);
    React.useEffect(() => {
        const decryptId = decryptData(match.params.acctId);
        console.log(decryptId);
        if (decryptId === userStatus.acctId) {
            setIsViewed(false);
        } else {
            setIsViewed(true);
        }
    }, []);

    // get latest subscription

    React.useEffect(() => {
        const request121 = {
            email: userStatus.email,
            acctId: userStatus.acctId,
            accessToken: localStorage.getItem('token'),
            recordId: userStatus.acctId,
            typeId: 2,
        };
        dispatch(getUserSubscriptionByRecordId(request121));

        if (userStatus.acctId == 246) {
            setpaymentQuotaVideo(2);
        }

    }, []);

    //setCurrLocation(match.params.type);

    React.useEffect(() => {
        if (match.params.type == 'video') {
            setOption('video');
        }
        if (match.params.type == 'document') {

            showLoader();
            setTimeout(() => { getMyDocumentList() }, 3000);

            setOption('document');
        }
    }, []);

    React.useEffect(() => {
        if (copied) {
            setCopied(false);
            const action = (key) => (
                <React.Fragment>
                    <IconButton onClick={onClickDismiss(key)}>
                        <CloseIcon />
                    </IconButton>
                </React.Fragment>
            );
            snackBarShow(
                "The share link has been copy into clipboard, kindly paste and share.",
                "info",
                action
            );
        }
    }, [copied]);


    //Video Upload Section
    let subscriptionExpiredWarning = <Typography className='warningMessage'>
        <i className="fas fa-triangle-exclamation"></i>
        <span>Your subscription has expired and your video(s) are not visible to others. <br />
            To reactivate your Video Resume function, please <a href="#" onClick={handleOpen2}>sign-up for a paid subscription here</a>.</span>
        <Modal
            className='customModal subscriptionModal'
            open={open2}
            onClose={handleClose2}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className='boxCustom' sx={style}>
                <Typography id="modal-modal-title" className='getSReady' variant="h6" component="h2">
                    Get ready to boost <br />your career today.
                </Typography>
                <Typography className='trialText' id="modal-modal-description" sx={{ mt: 2 }}>
                    30-day free trial, cancel any time. No credit card required.
                </Typography>
                <Typography component='div' className='switchCustom'>
                    <label>Bill Monthly</label>
                    <FormGroup>
                        <FormControlLabel
                            control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                        />
                    </FormGroup>
                    <label>Bill Annually</label>
                    <span className='saveSpan'>Save 10%</span>
                </Typography>
                <Typography component='div' className='planOuter'>
                    <Typography component='div' className='planLeft'>
                        <Typography className='priceTitle'>$0.99 <sup>/month</sup></Typography>
                        <Typography className='storageTitle'>1GB Document Storage</Typography>
                        <Typography className='descTitle'>Access your resume, portfolio files all from one place.</Typography>
                    </Typography>
                    <Typography component='div' className='planLeft planRight'>
                        <Typography className='priceTitle'>1.99 <sup>/month</sup></Typography>
                        <Typography className='storageTitle'>Video Upload</Typography>
                        <Typography className='descTitle'>Upload your video resume and gain more attention from hiring managers.</Typography>
                        <Button className='signUpNow'>Sign Up Now</Button>
                    </Typography>
                </Typography>
            </Box>
        </Modal>
    </Typography>;

    const userAttachmentList = useSelector(state => state.attachmentUser.userAttachmentList);
    const activeSubscription = useSelector(state => state.subscriptionSetting.currentSubsPlanCandidate);

    React.useEffect(() => {

        if (paymentQuotaVideo != 1) {
            //Alert and stop upload video
            const PayemtErr = <Typography className='errorMesaage'>Your payment subscription is expired.</Typography>;
            setPayemtSubscriptionErr(PayemtErr);
            return false;

        }
        const request = {
            email: userStatus.email,
            acctId: userStatus.acctId,
            accessToken: localStorage.getItem('token'),
            keyword: '',
            optionId: 3,
            lastRecordSize: 0,
            requestRecordSize: 1,
        };
        dispatch(getAttachment(request));
    }, []);

    const activePlanOfVideo = () => {
        const request = {
            email: userStatus.email,
            acctId: userStatus.acctId,
            recordId: userStatus.acctId,
            accessToken: localStorage.getItem("token"),
            typeId: 2,
            planId: 6
        };
        dispatch(activeCandidatePlan(request));
    }

    const [payemtSubscriptionErr, setPayemtSubscriptionErr] = React.useState('');
    const [videoSizeErr, setvideoSizeErr] = React.useState('');
    const [videoSizeErrStatus, setvideoSizeErrStatus] = React.useState(false);
    const bytesToMegaBytes = size => size / (1024 ** 2);
    const [videoUploadSuccessMsg, setVideoUploadSuccessMsg] = React.useState('');
    let [durationVideo, setDurationVideo] = React.useState('');
    const [enteredFile, setEnteredFile] = useState('');

    useEffect(() => {
        console.log('activeSubscription', activeSubscription)
        if (activeSubscription && activeSubscription.length > 0) {
            setVideoQuota(activeSubscription[0].subscriptionDetails[0].quantityLeft)
        }
        else {
            setVideoQuota(-1);
        }
    }, []);

    useEffect(() => {
        if (userAttachmentList && userAttachmentList.length > 0 && userAttachmentList[0].uploadedCount && userAttachmentList[0].uploadedCount > videoQuota) {
            setvideoSizeErrStatus(true);
        }
        else {
            setvideoSizeErrStatus(false);
        }

    }, []);

    const uploadVideoHandler = async (event) => {
        console.log('paymentQuotaVideo', videoQuota)

        if (paymentQuotaVideo != 1) {
            //Alert and stop upload video
            const PayemtErr = <Typography className='errorMesaage'>Your payment subscription is expired.</Typography>;
            setpaymentQuotaVideo(2);
            return false;
        }

        if (videoQuota == -1) {
            snackbarCustom("You don't have subscription plan");
            history.push('/subscription-settings/' + encryptData(userStatus.acctId));
            return false
        }

        // console.log("userstatus", userStatus);
        const size = event.target.files[0].size;
        const sizeInMb = bytesToMegaBytes(size).toFixed(2);

        let vid = document.createElement('video');
        var fileURL = URL.createObjectURL(event.target.files[0]);
        vid.src = fileURL;

        vid.ondurationchange = function (e) {
            let videoDurationInSec = this.duration;
            setDurationVideo(videoDurationInSec);
            if (this.duration > 120) {

                //   const videoErrJsxDuration = <Typography className='errorMesaage'>The video you tried to upload exceeds 2 mins.</Typography>;
                //  setvideoSizeErr(videoErrJsxDuration);
                snackbarCustom("The video you tried to upload exceeds 2 mins.");
                return false;
            }
            vid.durationtime = this.duration;
            return vid.durationtime;
        }

        if (paymentQuotaVideo != 1) {
            //Alert and stop upload video
            // const PayemtErr = <Typography className='errorMesaage'>Your payment subscription is expired.</Typography>;
            // setPayemtSubscriptionErr(PayemtErr);
            snackbarCustom("Your payment subscription is expired.");
            return false;

        } else if (sizeInMb > 150) {
            //  const videoErrJsx = <Typography className='errorMesaage'>The video you tried to upload exceeds 150MB.</Typography>;
            //  setvideoSizeErr(videoErrJsx);
            snackbarCustom("The video you tried to upload exceeds 150MB.");
            return false;
        }
        else if (videoSizeErrStatus == true) {
            //const videoErrJsxDuration = <Typography className='errorMesaage'>Sorry! You can upload video maximum 3 times.</Typography>;
            //   setvideoSizeErr(videoErrJsxDuration);
            snackbarCustom("Sorry! You can upload video maximum 3 times.");
            return false;
        }
        else {
            showLoader();

            setEnteredFile(event.target.files[0]);
            // let videoName = Date.now() + '_' + event.target.files[0].name;
            let videoName = event.target.files[0].name;
            let fileFormData = new FormData();
            fileFormData.append('email', userStatus.email);
            fileFormData.append('acctId', userStatus.acctId);
            fileFormData.append('attachment', event.target.files[0]);
            fileFormData.append('title', videoName);
            fileFormData.append('accessToken', localStorage.getItem('token'));
            fileFormData.append('attachmentTypeId', 5);
            fileFormData.append('duration', durationVideo);
            fileFormData.append('fileSize', event.target.files[0].size);
            fileFormData.append('isVisible', 1);
            fileFormData.append('optionId', 3);

            //console.log(fileFormData);

            // vid.addEventListener('loadedmetadata', function () {
            //     let duration = vid.duration;
            //     return duration;
            // }).then((res) => {
            //     console.log("res+++++++++++");
            //     console.log(res);
            //     console.log("res+++++++++++");
            // });

            if (userAttachmentList && userAttachmentList[0]) {

                fileFormData.append('attachmentId', userAttachmentList[0].attachmentId);


                dispatch(updateAttachment(fileFormData, (result) => {
                    console.log(result)
                    if (result.status == 202 && result.uploadedCount > 2) {
                        snackbarCustom('Sorry! You can upload video maximum 3 times.');
                    }
                    else {
                        snackbarCustom(result.message ? result.message : 'Video Updated Successfully.');
                        //setVideoUploadSuccessMsg(<Typography className='errorMesaage'>{localStorage.getItem('limitError') ? localStorage.getItem('limitError') : 'Video Updated Successfully.'}</Typography>);
                    }

                    const video = document.getElementById('vid');

                    video.pause();
                    video.currentTime = 0;
                    video.load();
                    // setEnteredFile('');

                }));
            }
            else {

                dispatch(createAttachmentVideo(fileFormData, (result) => {

                    if (result.status == 202) {
                        snackbarCustom("Video with this title already exist.");
                    }
                    else {
                        snackbarCustom('Video Updated Successfully.');
                        //snackbarCustom(localStorage.getItem('limitError') ? localStorage.getItem('limitError') : 'Video Updated Successfully.');
                        //setVideoUploadSuccessMsg(<Typography className='errorMesaage'>{localStorage.getItem('limitError') ? localStorage.getItem('limitError') : 'Video Updated Successfully.'}</Typography>);
                    }
                    // setEnteredFile('');

                }));
                //snackbarCustom( 'Video Updated Successfully.');


            }
            setEnteredFile('');
            setTimeout(() => { getMyVideoList() }, 3000);

            //snackbarCustom(localStorage.getItem('limitError') ? localStorage.getItem('limitError') : 'Video Updated Successfully.');
            // setVideoUploadSuccessMsg(<Typography className='errorMesaage sucessMsg'>{localStorage.getItem('limitError') ? localStorage.getItem('limitError') : 'Video Updated Successfully.'}</Typography>);


            /*   if (userAttachmentList && userAttachmentList[0]) {
                  //  console.log("i m in update section");
                  fileFormData.append('attachmentId', userAttachmentList[0].attachmentId);
                  //   console.log(userAttachmentList[0].attachmentId);
                  //  console.log("i m in update section");
  
                  const updateResponse = dispatch(updateAttachment(fileFormData));
                  setEnteredFile('');
                  dispatch(updateAttachment(fileFormData, (result) => {
                      if (result.status == 202) {
                          snackbarCustom("Video with this title already exist.");
                      }
                      else {
                          snackbarCustom(localStorage.getItem('limitError') ? localStorage.getItem('limitError') : 'Video Updated Successfully.');
                          //setVideoUploadSuccessMsg(<Typography className='errorMesaage'>{localStorage.getItem('limitError') ? localStorage.getItem('limitError') : 'Video Updated Successfully.'}</Typography>);
                      }
                  }));
              	
                  snackbarCustom(localStorage.getItem('limitError') ? localStorage.getItem('limitError') : 'Video Updated Successfully.');
                 // setVideoUploadSuccessMsg(<Typography className='errorMesaage sucessMsg'>{localStorage.getItem('limitError') ? localStorage.getItem('limitError') : 'Video Updated Successfully.'}</Typography>);
              } else {
                  const createResponse = dispatch(createAttachment(fileFormData));
                  setEnteredFile('');
                  //setVideoUploadSuccessMsg(<Typography className='successMesaage'>Video Uploaded Successfully.</Typography>);
                  //hideLoader();
                  snackbarCustom("Video Uploaded Successfully.");
                  setTimeout(() => { getMyVideoList() }, 3000);
  
              } */

        }
    };

    // const videoData = userAttachmentList &&  
    //                         userAttachmentList.map((video,index) => (
    //                             <GridItem key={index} md={3}>
    //                                 <Typography component="div" className='videoMainOuter'>
    //                                     <Typography component="div" className='videoOuter'>
    //                                         <video id={"vid_"+index} width="320" height="240" controls>
    //                                             <source src={video.attachmentPath} type="video/mp4"/>
    //                                             <source src={video.attachmentPath} type="video/ogg"/>
    //                                             Your browser does not support the video tag.
    //                                         </video>
    //                                     </Typography>
    //                                     <Typography className='videoTitle' >{video.title} <br/><span id={"dur_"+index}> {video.duration} Secs</span></Typography>
    //                                 </Typography>
    //                             </GridItem> 

    //                     ));


    console.log('userAttachmentList');
    console.log(userAttachmentList);

    const videoData = userAttachmentList && userAttachmentList[0] && (<GridItem md={3} sm={6} xs={12}>
        <Typography component="div" className='videoMainOuter'>
            <Typography component="div" className='videoOuter'>
                <video id="vid" width="320" height="240" controls>
                    <source src={userAttachmentList[0].attachmentPath ? userAttachmentList[0].attachmentPath : ''} type="video/mp4" />
                    <source src={userAttachmentList[0].attachmentPath ? userAttachmentList[0].attachmentPath : ''} type="video/ogg" />
                    Your browser does not support the video tag.
                </video>
            </Typography>
            <Typography className='videoTitle' >{userAttachmentList[0].title} <br /><span id="duration"> </span></Typography>
        </Typography>
    </GridItem>);
    let noVideosListings = <Typography className='noupload'>You have no uploaded videos.</Typography>;

    React.useEffect(() => {

        const interval = setInterval(() => {
            let myVideoPlayer = document.getElementById('vid');
            let meta = document.getElementById('duration');
            if (myVideoPlayer) {

                myVideoPlayer.addEventListener('loadedmetadata', function () {
                    let duration = myVideoPlayer.duration;
                    const sec = parseInt(duration, 10);
                    let hours = Math.floor(sec / 3600);
                    let minutes = Math.floor((sec - (hours * 3600)) / 60);
                    let seconds = sec - (hours * 3600) - (minutes * 60);
                    if (hours < 10) { hours = "0" + hours; }
                    if (minutes < 10) { minutes = "0" + minutes; }
                    if (seconds < 10) { seconds = "0" + seconds; }
                    let formatedTime = ((hours > 0) ? (hours + ' Hours ') : '') + ((minutes > 0) ? (minutes + ' Mins ') : '') + (seconds + ' Secs');
                    meta.innerHTML = formatedTime;
                });
            }
        }, 1000);
        return () => clearInterval(interval);


    }, []);

    // userAttachmentList.forEach((el)=>{
    //     console.log("el");
    //     console.log(el);
    // });


    // const i = setInterval(function() {
    //         let videoElement = document.getElementById('vid_0');      
    //             if(videoElement.readyState > 0) {
    //                 var minutes = parseInt(videoElement.duration / 60, 10);
    //                 var seconds = videoElement.duration % 60;

    //                 clearInterval(i);
    //                 return seconds;
    //             }
    //         }, 200);
    // console.log('i');          
    // console.log(i);
    // console.log('i');


    //End video code

    /* Sourabh code */
    const [documentUploadSuccessMsg, setDocumentUploadSuccessMsg] = React.useState('');

    const requestDocumentData = async () => {
        const request = {
            email: userStatus.email,
            acctId: userStatus.acctId,
            accessToken: localStorage.getItem('token'),
            keyword: '',
            lastRecordSize: 0,
            requestRecordSize: 100,
            optionId: -1,
        };
        dispatch(getAttachment(request));

        // console.log('++++++++++++++++++++++++++++++++');
    };



    const deleteDocumentHandler = (id) => {
        //console.log('------ID---------' + id);
        // setAnchorEl(null);
        //if (window.confirm("Are you sure?") == true) {
        // console.log('------ID---------' + id);
        const request = {
            email: userStatus.email,
            acctId: userStatus.acctId,
            accessToken: localStorage.getItem('token'),
            attachmentId: id,

        };
        const updateResponse = dispatch(deleteAttachment(request));
        handleClose2();
        snackbarCustom("Document deleted Successfully.");
        //console.log('updateResponse');
        //console.log(userAttachmentList); 
        // requestDocumentData();
        //window.location.reload(false);
        // }

    };

    const shareDocumentHandler = (id) => {



        console.log('------ID---------' + id);
        const request = {
            email: userStatus.email,
            acctId: userStatus.acctId,
            accessToken: localStorage.getItem('token'),
            attachmentIds: id.toString(),

            expiryDay: '10'

        };
        //const updateResponse = dispatch(createShareLink(request));


        dispatch(createShareLink(request, (result) => {

            console.log('createShareLink');
            console.log(result);
            setShareRecId(result.recId);
            var share_url = window.location.origin.toString() + '/user-download-attachment/' + encryptData(result.recId);
            //alert(share_url);
            setClipboardValue(share_url);


        }));



        //console.log('updateResponse');
        //console.log(updateResponse); 
        handleOpen1();
        // requestDocumentData();
        //window.location.reload(false);


    };


    const updateVisibilityHandler = async (id, stata) => {


        //if (window.confirm("Are you sure?") == true) {
        // console.log('------ID---------' + id);
        const request = {
            email: userStatus.email,
            acctId: userStatus.acctId,
            accessToken: localStorage.getItem('token'),
            attachmentId: id,
            isVisible: stata

        }
        const updateResponse = dispatch(updateAttachmentVisibility(request));
        handleClose1();
        setOption('document');
        snackbarCustom("Visibility Update Successfully");
        //console.log('updateResponse');
        //console.log(updateResponse);
        //requestDocumentData();

        // }

    };

    const uploadDocumentHandler = async (event) => {

        setAttachmentType(1);
        // console.log(userStatus);
        const file = event.target.files[0];
        // alert(file.type);
        const size = event.target.files[0].size;
        const sizeInMb = bytesToMegaBytes(size).toFixed(2);


        if (paymentQuotaVideo != 1) {
            //Alert and stop upload video
            // const PayemtErr = <Typography className='errorMesaage'>Your payment subscription is expired.</Typography>;
            // setPayemtSubscriptionErr(PayemtErr);
            snackbarCustom("Your payment subscription is expired.");
            history.push('/subscription-settings/' + encryptData(userStatus.acctId));
            return false;

        } else if (sizeInMb > 150) {
            // const videoErrJsx = <Typography className='errorMesaage'>The document you tried to upload exceeds 1GB.</Typography>;
            //setvideoSizeErr(videoErrJsx);
            snackbarCustom("The document you tried to upload exceeds 1GB.");
            return false;
        } if (file.type != 'application/pdf' && file.type != 'image/png' && file.type != 'image/jpeg') {
            // const videoErrJsx = <Typography className='errorMesaage'>Only .pdf document allowed.</Typography>;

            //setvideoSizeErr(videoErrJsx);
            snackbarCustom("Only Pdf or Image document allowed.");
            return false;

        } else {
            setEnteredFile(event.target.files[0]);
            //let videoName = Date.now() + '_' + event.target.files[0].name;
            let videoName = event.target.files[0].name;
            let fileFormData = new FormData();
            fileFormData.append('email', userStatus.email);
            fileFormData.append('acctId', userStatus.acctId);
            fileFormData.append('attachment', event.target.files[0]);
            fileFormData.append('title', videoName);
            fileFormData.append('accessToken', localStorage.getItem('token'));
            fileFormData.append('attachmentTypeId', '1');
            fileFormData.append('fileSize', '50');
            fileFormData.append('isVisible', '1');
            fileFormData.append('optionId', '4');
            fileFormData.append('duration', '50');
            //console.log(fileFormData);

            dispatch(createDocumentAttachment(fileFormData, (result) => {
                if (result.status == 202) {
                    snackbarCustom(result.documentError);
                }
                else if (result.status == 201) {
                    snackbarCustom("Document Uploaded Successfully.");
                }
            }))
            //  dispatch(createDocumentAttachment(fileFormData)).then((data)=>{
            //     console.log("dataaaaaaaaaaaaaaa",data);
            // });

            setEnteredFile('');
            setTimeout(() => { getMyDocumentList() }, 3000);



            //alert(documentErrorMessage);
            /* if (documentErrorMessage != '' ) {
                snackbarCustom("Duplicate image name.");
            }
            else
            {
                //   setDocumentUploadSuccessMsg(<Typography className='successMesaage'>Document Uploaded Successfully.</Typography>);
                getMyDocumentList();
        	
                snackbarCustom("Document Uploaded Successfully.");
            } */

        }


    };
    const documentErrorMessage = useSelector(state => state.attachmentUser.documentError);
    // console.log("documentError");
    // console.log(documentErrorMessage);
    // console.log("documentError");

    const getMyDocumentList = async () => {



        const request = {
            email: userStatus.email,
            acctId: userStatus.acctId,
            accessToken: localStorage.getItem('token'),
            keyword: '',
            optionId: 1,
            lastRecordSize: 0,
            requestRecordSize: 100,
        };
        /// console.log('sssss(getAttachment(request))');
        dispatch(getDocument(request));
        hideLoader();


    };



    const getMyVideoList = async () => {



        const request = {
            email: userStatus.email,
            acctId: userStatus.acctId,
            accessToken: localStorage.getItem('token'),
            keyword: '',
            optionId: 3,
            lastRecordSize: 0,
            requestRecordSize: 1,
        };
        //   console.log('sssss(getAttachment(request))');
        dispatch(getVideo(request));
        hideLoader();
        //requestDocumentData();
        // window.location.reload(false);


    };


    const currentSelectedCheckbox = (id) => {
        //console.log('___ID_____' + id);
        let existingCheck = isCheck;


        var index = existingCheck.indexOf(id);
        // console.log('___index_____' + index);
        if (index !== -1) {
            existingCheck.splice(index, 1);
            //  console.log('tempPeopleInfo');
            //  console.log(existingCheck);
            let tempPeopleInfo = [];
            if (existingCheck.length > 0) {
                tempPeopleInfo = existingCheck;
                tempPeopleInfo = tempPeopleInfo.map(Number);
            }
            setIsCheck(tempPeopleInfo);
        }
        else {
            let tempPeopleInfo = [];
            if (existingCheck.length > 0) {
                tempPeopleInfo = existingCheck;
                tempPeopleInfo = tempPeopleInfo.map(Number);
            }

            tempPeopleInfo.push(parseInt(id));
            // console.log('tempPeopleInfo');
            //  console.log(tempPeopleInfo);


            setIsCheck(tempPeopleInfo);
        }

        //console.log('tempPeopleInfo');
        //console.log(tempPeopleInfo);
        //setIsCheck(tempPeopleInfo);
    }

    const multpleShareHandle = () => {
        if (isCheck.length > 0) {
            let ids = isCheck.join(',');
            shareDocumentHandler(ids)

        }
        else {
            snackbarCustom("Please select atleast one item.");
        }
    }

    const multpleDeleteHandle = () => {
        if (isCheck.length > 0) {
            let selected_document_length = isCheck.length;
            let ids = isCheck.join(',');

            isCheck.map(function (eachitem, index) {

                const request = {
                    email: userStatus.email,
                    acctId: userStatus.acctId,
                    accessToken: localStorage.getItem('token'),
                    attachmentId: eachitem,

                };
                const updateResponse = dispatch(deleteAttachment(request));
                currentSelectedCheckbox(eachitem);

            });


            snackbarCustom(selected_document_length + " documents deleted Successfully.");

        }
        else {
            snackbarCustom("Please select at least one item to delete.");
        }
    }

    const handleClipboardCopyAction = () => {
        // setClipboardCopied(true);
        setCopied(true);
    }


    const checkAllCheckbox = (ele) => {

        var checkboxes = document.getElementsByName('inputCheck');
        console.log(checkboxes);
        if (ele.target.checked) {
            //   console.log('------ele1-----');
            let tempPeopleInfo = [];
            for (var i = 0; i < checkboxes.length; i++) {
                if (checkboxes[i].type == 'checkbox') {
                    //  console.log('id==' + checkboxes[i].id);
                    //setIsCheck(isCheck.push(item => item !== checkboxes[i].id));
                    let id_a = checkboxes[i].id;

                    tempPeopleInfo.push(parseInt(checkboxes[i].id));
                    //let old = isCheck;
                    //console.log('old');
                    //console.log(old);
                    //old.push(checkboxes[i].id);

                    //setIsCheck(checkboxes[i].id);


                    // checkboxes[i].checked = true;
                }

            }

            setIsCheck(tempPeopleInfo);
            //console.log(tempPeopleInfo);
            //console.log('------tempPeopleInfo-----');
            // setIsCheck(tempPeopleInfo);
            //setIsCheck.push

        } else {
            //  console.log('------ele2-----');
            setIsCheck([]);
            /*  for (var i = 0; i < checkboxes.length; i++) {
                 console.log(i)
                 if (checkboxes[i].type == 'checkbox') {
                     setIsCheck([]);
                     checkboxes[i].checked = false;
                 }
             } */
        }
    }
    //console.log('------eleeeeisCheckeeeeeeee1-----');
    //console.log(isCheck);




    /*snackbarCustom sir start*/
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const onClickDismiss = (key) => () => {
        closeSnackbar(key);
    };


    const snackbarCustom = (title) => {
        const action = (key) => (
            <React.Fragment>
                <IconButton onClick={onClickDismiss(key)}>
                    <CloseIcon />
                </IconButton>
            </React.Fragment>
        );
        snackBarShow(title, "default", action);
    };
    const snackBarShow = (message, variant, action) => {
        // variant could be success, error, warning, info, or default
        enqueueSnackbar(message, {
            variant: variant,
            action,
        });
    };

    /*snackbarCustom sir end*/



    const NoAttachmentFile = () => {
        return (
            'You have no uploaded documents.'
        );
    };

    const NoVideoAttachmentFile = () => {
        return (
            'You have no uploaded video.'
        );
    };
    const handleCopyText = (e) => {
        setClipboardValue(e.target.value);
    }

    const IsolatedMenu = props => {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);
        //console.log('props');
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        //console.log(props.id.attachmentId );
        return (<Typography component="div" className='documentName threeDot'><Button
            id="basic-button"
            className='basicButton'
            aria-controls={open ? 'basic-menu' + props.id.attachmentId : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick} > <i className='fas fa-ellipsis'></i></Button>
            <Menu
                elevation={0}
                id={'basic-menu' + props.id.attachmentId}
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >

                {props.id.isVisible == 0 ? (
                    <MenuItem className='menuTitle' onClick={() => updateVisibilityHandler(props.id.attachmentId, '1')}><i className="fa-regular fa-eye-slash"></i>Make visible to private</MenuItem>
                ) : (
                    <MenuItem className='menuTitle' onClick={() => updateVisibilityHandler(props.id.attachmentId, '0')}><i className="fa-regular fa-eye"></i>Make visible to public</MenuItem>
                )}
                <MenuItem className='menuTitle' onClick={() => deleteDocumentHandler(props.id.attachmentId)} ><i className="fa-regular fa-trash-can"></i>Delete File</MenuItem>
                <MenuItem className='menuTitle' onClick={() => shareDocumentHandler(props.id.attachmentId)}  ><i className="fas fa-share-nodes"></i>Get Sharing Link</MenuItem>
            </Menu>

        </Typography>)
    }

    const type_format_arr = ["application/vnd.open", "application/pdf", "image/jpeg", "image/jpeg", 'image/png'];




    /* sourabh code end */



    //console.log(isViewed);
    const tabs = ['video', 'document', 'resume'];

    const isEmptyState = false;
    const TestimonialDescription = () => {
        return (

            <React.Fragment>
                <div className={styles.testimonialsHeader}>
                    <Hidden mdDown>
                        <div className={styles.headerLeft}>
                            <img src={testimonialsHeader} alt="" />
                        </div>
                    </Hidden>

                    <div className={styles.headerRight}>
                        <Typography component="h2">How To Request For Testimonials and Boost Your Profile</Typography>

                        <div className={styles.headerPoint}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                <path
                                    id="Shape"
                                    d="M0,10A10,10,0,0,1,10,0a9.9,9.9,0,0,1,5.72,1.81L14.28,3.25A7.918,7.918,0,0,0,10,2a8.046,8.046,0,1,0,7.79,6.22L19.4,6.61A10,10,0,1,1,0,10Zm4.35.36L5.76,8.95,8.59,11.78l10-10L20,3.18,8.59,14.6Z"
                                    fill="#20f0a1"
                                />
                            </svg>
                            Only ask people you know professionally and have worked with
                        </div>

                        <div className={styles.headerPoint}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                <path
                                    id="Shape"
                                    d="M0,10A10,10,0,0,1,10,0a9.9,9.9,0,0,1,5.72,1.81L14.28,3.25A7.918,7.918,0,0,0,10,2a8.046,8.046,0,1,0,7.79,6.22L19.4,6.61A10,10,0,1,1,0,10Zm4.35.36L5.76,8.95,8.59,11.78l10-10L20,3.18,8.59,14.6Z"
                                    fill="#20f0a1"
                                />
                            </svg>
                            Ask different people to highlight a wide range of your skills
                        </div>

                        <div className={styles.headerPoint}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                <path
                                    id="Shape"
                                    d="M0,10A10,10,0,0,1,10,0a9.9,9.9,0,0,1,5.72,1.81L14.28,3.25A7.918,7.918,0,0,0,10,2a8.046,8.046,0,1,0,7.79,6.22L19.4,6.61A10,10,0,1,1,0,10Zm4.35.36L5.76,8.95,8.59,11.78l10-10L20,3.18,8.59,14.6Z"
                                    fill="#20f0a1"
                                />
                            </svg>
                            Write a Testimonial for someone else and it will be easier to get them to <br />
                            reciprocate and write one for you too!
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };
    return (
        <div>
            {/* {user && */}
            <Header color={'transparent'} loggedInStatus={false} />
            <div className={styles.body}>
                <div id="pageHeader" className={styles.pageHeader}>
                    <div className={styles.container} id="testimonialPage">
                        <GridContainer spacing={2} justifyContent="center">
                            <Hidden smDown>
                                <GridItem md={3} lg={2}>
                                    <LeftSidebar />
                                </GridItem>
                            </Hidden>

                            <GridItem xs={12} sm={12} md={9} lg={8}>
                                <Card className={styles.cardFrame}>
                                    <CardBody className='cusUploadBody'>
                                        <div className={styles.testimonialsContentTabsContainer}>
                                            <div
                                                className={clsx({
                                                    [styles.tab]: true,

                                                    [styles.tabActive]: match.params.type == 'video' ? true : false
                                                    // [styles.tabActive]: optionToShow == 'video',
                                                })}
                                                onClick={event => window.location.href = '/upload-attachment/video/' + encryptData(userStatus.acctId)}

                                            /*  onClick={() => {
                                                 getMyVideoList();
                                                 setOption('video');
                                             }} */
                                            >
                                                <p className={styles.tabTitle}>My Videos</p>
                                                <div
                                                    className={clsx({
                                                        [styles.tabActiveBorder]: match.params.type == 'video' ? true : false
                                                        // [styles.tabActiveBorder]: optionToShow == 'video',
                                                    })}
                                                ></div>
                                            </div>
                                            {isMe && (
                                                <div
                                                    className={clsx({
                                                        [styles.tab]: true,
                                                        [styles.tabActive]: match.params.type == 'document' ? true : false
                                                        // [styles.tabActive]: optionToShow == 'document',
                                                    })}
                                                    /*  onClick={() => {
                                                         getMyDocumentList();
 
                                                         setOption('document');
                                                     }} */
                                                    onClick={event => window.location.href = '/upload-attachment/document/' + encryptData(userStatus.acctId)}
                                                >
                                                    <p className={styles.tabTitle}>My Documents</p>
                                                    <div
                                                        className={clsx({
                                                            [styles.tabActiveBorder]: match.params.type == 'document' ? true : false
                                                            //[styles.tabActiveBorder]: optionToShow == 'document',
                                                        })}
                                                    ></div>
                                                </div>
                                            )}

                                            <div
                                                className={clsx({
                                                    [styles.tab]: true,
                                                    [styles.tabActive]: match.params.type == 'resume' ? true : false
                                                    // [styles.tabActive]: optionToShow == 'document',
                                                })}
                                                /*  onClick={() => {
                                                     getMyDocumentList();
 
                                                     setOption('document');
                                                 }} */
                                                onClick={event => window.location.href = '/upload-attachment/resume/' + encryptData(userStatus.acctId)}
                                            >
                                                <p className={styles.tabTitle}>Resume</p>
                                                <div
                                                    className={clsx({
                                                        [styles.tabActiveBorder]: match.params.type == 'resume' ? true : false
                                                        //[styles.tabActiveBorder]: optionToShow == 'document',
                                                    })}
                                                ></div>
                                            </div>
                                        </div>
                                        <div className={styles.testimonialsContent}>
                                            {match.params.type === tabs[0] &&
                                                <div className='tab_content_custom'>
                                                    <div className='nouploaded'>
                                                        <div className="tabUploadButton">
                                                            {/* <Button>Upload Video</Button> */}

                                                            <form>
                                                                <Button>
                                                                    <label>Upload Video</label>
                                                                    <input className="inputFile" type="file" value={enteredFile} name="file" onChange={uploadVideoHandler} accept="video/mp4,video/x-m4v,video/*" />
                                                                </Button>
                                                            </form>
                                                            <span className='tabUploadButtonspan'>Maximum video/file size of 150MB. <br />
                                                                Videos should be no more than 2 minutes. </span>
                                                        </div>
                                                        {videoUploadSuccessMsg}
                                                        {payemtSubscriptionErr}
                                                        {videoSizeErr}
                                                        {(paymentQuotaVideo != 1) ? subscriptionExpiredWarning : ''}

                                                        {videoData ? videoData : noVideosListings}

                                                    </div>
                                                </div>
                                            }
                                            {match.params.type === tabs[1] &&
                                                <div className='tab_content_custom'>
                                                    <div className='nouploaded'>
                                                        <div className="tabUploadButton">
                                                            <form>
                                                                <Button>
                                                                    <label>Upload Document </label>
                                                                    <input className="inputFile" type="file" value={enteredFile} name="file" onChange={uploadDocumentHandler} />
                                                                </Button>
                                                            </form>
                                                            <span className='tabUploadButtonspan'>Total uploads should not exceed 1GB.</span>
                                                        </div>
                                                        {videoSizeErr}
                                                        {documentUploadSuccessMsg}
                                                        <Typography className='noupload'>
                                                            {userAttachmentList && userAttachmentList.length === 0 && <NoAttachmentFile />}

                                                        </Typography>

                                                        <Typography component="div" className='uploadMainDiv'>
                                                            {isCheck.length > 0 ? (
                                                                <Typography component="div" className='uploadHeader shareDelete'>
                                                                    <Typography className='emptyDiv'></Typography>


                                                                    <Typography component="div" className='documentName doc fileselected'>{isCheck.length} file(s) selected</Typography>
                                                                    <Typography component="div" className='documentName dateUpload shareText'><Link onClick={multpleShareHandle} to="#"   ><i className="fas fa-share-nodes"></i>Share</Link></Typography>
                                                                    <Typography component="div" className='documentName fileSize deleteText'><Link onClick={multpleDeleteHandle} to="#"   ><i className="fa-regular fa-trash-can"></i> Delete</Link></Typography>



                                                                    <Typography component="div" className='documentName threeDot'>&nbsp;</Typography>
                                                                </Typography>
                                                            ) : ('')}
                                                            <Typography component="div" className='uploadHeader'>
                                                                <Checkbox name='inputCheckAll'  {...label} onClick={checkAllCheckbox} className='customCheckbox' />
                                                                <Typography component="div" className='documentName doc'>Document Name</Typography>
                                                                <Typography component="div" className='documentName dateUpload'>Date Uploaded</Typography>
                                                                <Typography component="div" className='documentName fileSize'>File Size</Typography>
                                                                <Typography component="div" className='documentName threeDot'>&nbsp;</Typography>
                                                            </Typography>
                                                            {
                                                                userAttachmentList && userAttachmentList.length > 0 &&

                                                                userAttachmentList.map(function (userAttadocument_itemchmentList, index) {
                                                                    //  console.log('252525252252525');
                                                                    //  console.log(userAttachmentList);
                                                                    //  console.log('252525252252525');
                                                                    if (type_format_arr.includes(userAttadocument_itemchmentList.fileFormat)) {
                                                                        //countDocument++;
                                                                        //setDocumentCount(documentCount++);
                                                                        // console.log('252525252252525');
                                                                        //	console.log(isCheck);
                                                                        //  console.log(isCheck.includes(userAttadocument_itemchmentList.attachmentId));
                                                                        return (<Typography key={index} component="div" className='uploadHeader uploadData'>



                                                                            <Checkbox name='inputCheck'  {...label}
                                                                                id={userAttadocument_itemchmentList.attachmentId.toString()}
                                                                                onClick={() => currentSelectedCheckbox(userAttadocument_itemchmentList.attachmentId)}
                                                                                checked={isCheck.includes(userAttadocument_itemchmentList.attachmentId) ? true : false}
                                                                                className='customCheckbox' />

                                                                            <Typography component="div" className='documentName doc'>  {userAttadocument_itemchmentList.title}</Typography>
                                                                            <Typography component="div" className='documentName dateUpload'>
                                                                                <i className="fa-regular fa-eye"></i>
                                                                                {userAttadocument_itemchmentList.isVisible == 0 ? (
                                                                                    <i className="fa-regular fa-eye"></i>
                                                                                ) : (
                                                                                    <i className="fa-regular fa-eye-slash"></i>
                                                                                )}

                                                                                {userAttadocument_itemchmentList.createdDate}</Typography>
                                                                            <Typography component="div" className='documentName fileSize'>{userAttadocument_itemchmentList.totalCount}MB</Typography>

                                                                            <IsolatedMenu id={userAttadocument_itemchmentList} />


                                                                        </Typography>)
                                                                    }
                                                                })

                                                            }

                                                            <Modal

                                                                className='customModal'
                                                                open={open1}
                                                                onClose={handleClose1}
                                                                aria-labelledby="modal-modal-title"
                                                                aria-describedby="modal-modal-description"
                                                            >
                                                                <Box className='boxCustom' sx={style}>
                                                                    <Typography id="modal-modal-title" className='getShareLink' variant="h6" component="h2">
                                                                        <i className="fas fa-share-nodes"></i>Get Sharing Link
                                                                    </Typography>
                                                                    <Typography className='copyLink'>

                                                                        <input value={clipboardValue}
                                                                            onChange={handleCopyText} />



                                                                        <CopyToClipboard text={clipboardValue}
                                                                            onCopy={() => handleClipboardCopyAction()}>
                                                                            <button className='copyBtn'>Copy Link</button>
                                                                        </CopyToClipboard>

                                                                        {clipboardCopied ? <span style={{ color: 'red' }}>Copied.</span> : null}


                                                                    </Typography>
                                                                    <Typography className='descText' id="modal-modal-description" sx={{ mt: 2 }}>
                                                                        Each link will be valid for download for the next 10 days. <br />
                                                                        Download access will automatically expire thereafter.
                                                                    </Typography>
                                                                    <Button onClick={handleClose1} className='doneBtn'>Done</Button>
                                                                </Box>
                                                            </Modal>

                                                        </Typography>
                                                    </div>
                                                </div>
                                            }
                                            {match.params.type === tabs[2] &&
                                                <div className='tab_content_custom'>
                                                    <div className='nouploaded'>
                                                        <div className="tabUploadButton">
                                                            <form>
                                                                <Button>
                                                                    <label>Upload Resume</label>
                                                                    <input className="inputFile" type="file" />
                                                                </Button>
                                                            </form>
                                                            <span className='tabUploadButtonspan'>Only Word and PDF files with maximum size 10mb.</span>
                                                        </div>
                                                        <Typography className='noupload'>You have no uploaded Resumes.</Typography>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </CardBody>
                                </Card>
                            </GridItem>
                            <Hidden mdDown>
                                <GridItem md={2}>
                                    <RightSideBarUserList />
                                </GridItem>
                            </Hidden>
                        </GridContainer>
                    </div>
                </div>

                <EditModal />
                <Footer />
            </div>
            {/* } */}


            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openLoader}
                onClick={hideLoader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>


        </div >
    );
};

export default UploadLanding;
