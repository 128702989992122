import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import axios from "axios";

import { Box, Button, makeStyles, Modal, Typography } from "@material-ui/core";

import Header from "views/Component/Header.js";
import LeftSidebar from "views/Component/LeftSidebar.js";
import EditProfile from "views/Component/ProfileView/EditProfile/EditProfile";
import Footer from "views/Component/Footer.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { errorCleanUp } from "configurations/redux/authSlice.js";
import Cookies from "js-cookie";
import {
	hashKey,
	decryptData,
	instanceUrl,
	header,
} from "configurations/configurations.js";
import { getProfiles } from "configurations/redux/userSlice.js";
import {
	createAttachment,
	getAttachment,
} from "configurations/redux/attachmentUserSlice.js";
import resumeHeader from "assets/img/resume-header.png";
import resumeThumbnail from "assets/img/resume-thumbnail.png";
import noResumePic from "assets/img/no-resume.png";
import {
	activateEditType,
	deactivateEditType,
} from "configurations/redux/editProfileModalSlice";
import resumePageStyles from "assets/jss/material-kit-pro-react/views/pageStyle/ResumePage/resumePageStyles";
import RightSideBarUserList from "views/Component/RightSideBarUserList.js";
import ResumeSection from "views/Component/ProfileView/EditProfile/Resume/ResumeSection.js";
import EditModal from "views/Component/ProfileView/EditProfile/EditModal";
import Hidden from "@material-ui/core/Hidden";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

function useWindowSize() {
	const [size, setSize] = React.useState([0, 0]);
	React.useLayoutEffect(() => {
		function updateSize() {
			setSize([window.innerWidth, window.innerHeight]);
		}
		window.addEventListener("resize", updateSize);
		updateSize();
		return () => window.removeEventListener("resize", updateSize);
	}, []);
	return size;
}

const ResumePage = ({ match }) => {
	const styles = makeStyles(resumePageStyles)();

	const history = useHistory();
	const dispatch = useDispatch();
	var isKicked = Cookies.get("isKicked");
	const isError = useSelector((state) => state.auth.isError);
	const currUser = useSelector((state) => state.auth.user);
	const buildProfileFlag = useSelector((state) => state.auth.buildProfileFlag);
	const isViewed = useSelector((state) => state.user.isViewed);
	const userStatus = useSelector((state) => state.auth.user);

	const userAttachmentList = useSelector(
		(state) => state.attachmentUser.userAttachmentList
	);
	const [connStatus, setConnStatus] = React.useState(null);

	const [isEditProfileOpen, setIsEditProfileOpen] = useState(false);

	const [width, height] = useWindowSize();

	React.useEffect(() => {
		const request = {
			email: userStatus.email,
			acctId: userStatus.acctId,
			accessToken: localStorage.getItem("token"),
			keyword: "",
			lastRecordSize: 0,
			requestRecordSize: 6,
		};
		dispatch(getAttachment(request));
	}, []);

	/* React.useEffect(() => {
		var header = document.getElementById("pageHeader");
		if (isError) {
			if (header) header.style.height = "auto";
		} else {
			if (header && window.innerHeight > 1000 && window.innerWidth > 1900) {
				header.style.height = "100vh";
			}
		}
	}, [isError]); */

	React.useEffect(() => {
		if (isError) {
			dispatch(errorCleanUp());
		}
	}, []);

	const ResumeDescription = () => {
		return (
			<React.Fragment>
				<div className={styles.resumeHeader}>
					<Hidden mdDown>
						<div className={styles.headerLeft}>
							<img src={resumeHeader} alt="" />
						</div>
					</Hidden>

					<div className={styles.headerRight}>
						<Typography component="h2">
							Uploading a resume makes applying to jobs easy!
						</Typography>

						<div className={styles.headerPoint}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="20"
								viewBox="0 0 20 20"
							>
								<path
									id="Shape"
									d="M0,10A10,10,0,0,1,10,0a9.9,9.9,0,0,1,5.72,1.81L14.28,3.25A7.918,7.918,0,0,0,10,2a8.046,8.046,0,1,0,7.79,6.22L19.4,6.61A10,10,0,1,1,0,10Zm4.35.36L5.76,8.95,8.59,11.78l10-10L20,3.18,8.59,14.6Z"
									fill="#20f0a1"
								/>
							</svg>
							Make your resume public to be visible to Hiring Managers and
							Employers
						</div>

						<div className={styles.headerPoint}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="20"
								viewBox="0 0 20 20"
							>
								<path
									id="Shape"
									d="M0,10A10,10,0,0,1,10,0a9.9,9.9,0,0,1,5.72,1.81L14.28,3.25A7.918,7.918,0,0,0,10,2a8.046,8.046,0,1,0,7.79,6.22L19.4,6.61A10,10,0,1,1,0,10Zm4.35.36L5.76,8.95,8.59,11.78l10-10L20,3.18,8.59,14.6Z"
									fill="#20f0a1"
								/>
							</svg>
							Speed up your job application process. You can apply to jobs with
							one click!
						</div>

						<div className={styles.headerPoint}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="20"
								viewBox="0 0 20 20"
							>
								<path
									id="Shape"
									d="M0,10A10,10,0,0,1,10,0a9.9,9.9,0,0,1,5.72,1.81L14.28,3.25A7.918,7.918,0,0,0,10,2a8.046,8.046,0,1,0,7.79,6.22L19.4,6.61A10,10,0,1,1,0,10Zm4.35.36L5.76,8.95,8.59,11.78l10-10L20,3.18,8.59,14.6Z"
									fill="#20f0a1"
								/>
							</svg>
							See similar job titles and skills to help you make your next
							career move.
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	};

	return (
		<div>
			<Header color={"transparent"} loggedInStatus={false} />
			<div className={styles.body}>
				<div id="pageHeader" className={styles.pageHeader}>
					<div className={styles.container}>
						<GridContainer spacing={2} justifyContent="center">
							<Hidden smDown>
								<GridItem md={3} lg={2}>
									<LeftSidebar />
								</GridItem>
							</Hidden>

							<GridItem xs={12} sm={12} md={9} lg={8}>
								<Card className={styles.cardFrame}>
									<CardBody>
										<ResumeDescription />
										<div className={styles.resumeContentHeader}>
											<Typography component="h3">Your Resume</Typography>

											<Button
												onClick={() => {
													const response = {
														type: "EDIT_PROFILE",
														tabType: "RESUME",
													};

													dispatch(activateEditType(response));
												}}
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="20"
													height="20"
													viewBox="0 0 20 20"
												>
													<path
														id="icon-2"
														d="M4.709,20H1.176A1.178,1.178,0,0,1,0,18.824v-3.53a1.167,1.167,0,0,1,.345-.831L14.462.345a1.175,1.175,0,0,1,1.663,0l3.53,3.53a1.177,1.177,0,0,1,0,1.664L5.538,19.658A1.169,1.169,0,0,1,4.709,20Zm6.97-13.571h0L2.143,15.965v1.892H4.035L13.571,8.32,11.679,6.429Zm3.677-3.571h0L13.571,4.643l1.786,1.786,1.786-1.786L15.357,2.858Z"
														transform="translate(0 0)"
														fill="#6f52ed"
													/>
												</svg>
												EDIT
											</Button>
										</div>

										<ResumeSection />

										{userAttachmentList && userAttachmentList.length === 0 && (
											<Typography
												component="div"
												className={styles.emptyStateContent}
												/* style={{ display: isEmptyState ? "block" : "none" }} */
											>
												<Typography component="p">
													Click on the 'Edit' button to add your resume.
												</Typography>
												<img src={noResumePic} alt="" />
											</Typography>
										)}
									</CardBody>
								</Card>
							</GridItem>

							<Hidden mdDown>
								<GridItem md={2}>
									<RightSideBarUserList />
								</GridItem>
							</Hidden>
						</GridContainer>
					</div>
				</div>

				<EditModal />

				<Footer />
			</div>
		</div>
	);
};

export default React.memo(ResumePage);
