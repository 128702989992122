import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { departmentViewRoadmap, viewRoadmapRoleByRoleId } from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";
// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Department_Research_Consultancy from "assets/img/Career_Roadmap/Real_Estate/Department_Research_Consultancy_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentResearchConsultancy() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Research_Consultancy;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "rc",
				shape: "poly",
				coords: [81,384,74,386,71,389,66,393,63,400,64,444,67,449,69,454,74,457,80,459,170,460,177,458,181,457,185,454,188,450,191,445,192,439,192,403,191,397,188,392,184,387,179,385,175,383,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "149",
				shape: "poly",
				coords: [216,380,209,382,207,385,204,389,202,395,202,450,204,455,207,459,211,462,218,463,398,464,403,461,406,457,408,452,410,448,410,395,409,389,405,385,399,381,393,380,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "150",
				shape: "poly",
				coords: [582,380,570,380,566,383,562,386,560,391,558,396,558,447,560,454,563,458,568,461,576,463,750,463,755,462,758,459,762,457,765,451,765,445,765,399,765,393,763,388,760,385,758,382,751,381,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "151",
				shape: "poly",
				coords: [926,380,921,382,917,385,915,390,913,395,913,451,917,457,921,461,928,463,1105,463,1111,461,1116,458,1118,454,1120,447,1120,396,1120,390,1118,387,1114,384,1110,382,1104,381,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "152",
				shape: "poly",
				coords: [1285,380,1280,381,1275,384,1271,387,1269,391,1268,395,1268,449,1269,454,1272,458,1276,462,1284,463,1462,463,1467,461,1470,458,1473,455,1475,449,1476,396,1474,388,1472,385,1469,384,1465,381,1460,381,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "153",
				shape: "poly",
				coords: [1645,380,1637,383,1634,386,1631,389,1629,395,1629,449,1632,456,1634,459,1639,461,1644,463,1823,463,1828,461,1832,458,1834,453,1836,446,1836,399,1835,390,1831,386,1827,382,1821,380,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "rc") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo)
			dispatch(viewRoadmapRoleByRoleId(requestInfo));
			
			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// console.log(coords.x + "," + coords.y + ",");
		// navigator.clipboard.writeText(coords.x + "," + coords.y + ",");
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
