import { fontSize, fontStyle } from "@mui/system";
import {
	container,
	description,
	title,
	cardTitle,
	blackColor,
	whiteColor,
	grayColor,
	opptyDesignColor,
	hexToRgb,
	facebookColor,
} from "assets/jss/material-kit-pro-react.js";
const CreatePollModalStyle = (theme) => ({
	BoxStyle: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: "773px",
		height: "50.75rem",
		maxHeight: "92%",
		padding: 0,
		borderColor: whiteColor,
		borderRadius: "15px",
		backgroundColor: "#FFFFFF",
		border: "2px solid #000",
		overflow: "hidden",
		overflowY: "scroll",
		"&:focus-visible": {
			outline: "none",
		},
	},
	separator: {
		width: "100%",
	},
	separator2: {
		width: "100%",
	},
	backArrow: {
		height: "1.5rem",
		width: "1.5rem",
		marginRight: ".5rem",
		marginTop: "1rem",
	},
	backButtonwithimg: {
		display: "flex",
		cursor: "pointer",
	},
	closeIcon_Infotitle: {
		height: "2.5rem",
		width: "100%",
		display: "flex",
		borderRadius: "0.5rem",
		justifyContent: "space-between",
		alignItems: "center",
		padding: "0 1rem",
		cursor: "pointer",
	},
	InfoTitle: {
		float: "left",
		marginTop: "1rem",
		color: blackColor,
		fontSize: ".875rem",
		fontWeight: "500",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
	},
	closeIcon: {
		width: "1rem",
		marginTop: "1rem",
	},
	pollSection: {
		padding: "0 1rem",
		marginTop: ".5rem",
	},
	Question: {
		fontSize: "1rem",
		fontWeight: "500",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
	},
	Answer: {
		fontSize: "1rem",
		fontWeight: "500",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,

		paddingLeft: "1rem",
	},
	Required: {
		fontSize: ".875rem",
		float: "right",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
	},
	QuestionLabel: {
		display: "flex",
		justifyContent: "space-between",
	},
	QuestionLimit: {
		display: "flex",
		justifyContent: "right",
	},
	QuestionText: {
		overflow: "hidden",
		// height: "3.5rem",
		borderRadius: ".5rem",
		"&:hover": {
			outline: "none",
		},
		"&:focus-visible": {
			outline: "none",
		},
	},
	OptionText: {
		fontWeight: 500,
	},
	QuestionSection: {
		padding: "0rem 1rem",
		marginBottom: "1rem",
	},
	addOption: {
		display: "flex",
		paddingLeft: "1rem",
		margin: "2rem 0rem",
		"& > label": {
			cursor: "pointer",
		},
		// width: "18%",
	},
	addOptionText: {
		color: "#6F52ED",
		fontSize: ".875rem",
		fontWeight: "500",
		float: "right",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,

		marginBottom: "0rem",
		marginLeft: ".5rem",
		marginTop: "4px",
	},
	deleteButton: {
		display: "flex",
		cursor: "pointer",
	},
	trashIcon: {
		width: "1.125rem",
		height: "1.375rem",
	},
	deleteText: {
		color: "#E42B4D",
		margin: "0",
	},
	pollFooter: {
		marginBottom: "20px",
		padding: "0 20px",

		"& > p": {
			margin: 0,
			color: "#171725",
			fontSize: "16px",
			fontWeight: 500,
			fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		},

		"& > div": {
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
		},
	},
	Select: {
		height: "2.25rem",
		width: "17.5rem",
		borderRadius: ".5rem",
		"& .MuiSelect-select:focus": {
			backgroundColor: whiteColor,
		},
		"& .MuiOutlinedInput-input": {
			padding: "8.5px 14px",
		},
	},
	savePostBtn: {
		borderRadius: "10px",
		backgroundColor: "#6F52ED",
		width: "148.42px",
		height: "40px",
		fontWeight: 500,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		textTransform: "capitalize",
	},
	commentActionBtns: {
		position: "absolute",
		top: "6px",
		right: 0,
		// width: "105px!important",

		"& > button": {
			width: "45px",
			height: "45px",
		},
	},
});

export default CreatePollModalStyle;
