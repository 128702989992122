import { createSlice } from '@reduxjs/toolkit';

import axios from 'axios';
import { instanceUrl, header, emptyCache } from 'configurations/configurations.js';

const searchPostSlice = createSlice({
  name: 'searchPost',
  initialState: {
    searchResult: [],
    searchParams: [],
    isLoading: false,
    isUpdated: true,
  },
  reducers: {
    POST_LOADING: state => {
      state.isLoading = true;
    },
    POST_SEARCH_SUCCESS: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      state.searchResult = action.payload.searchResult;
      state.searchParams = action.payload.searchParams;
      state.isLoading = false;
      state.isUpdated = true;
    },
    POST_SEARCH_FAIL: state => {
      state.isUpdated = false;
    },
    POST_SAVE_PARAMS: (state, action) => {
      state.searchParams = action.payload.searchParams;
    },
  },
});

const { POST_LOADING, POST_SEARCH_SUCCESS, POST_SEARCH_FAIL, POST_SAVE_PARAMS } = searchPostSlice.actions;

export const saveParams = ({ sortBy, keyword }) => async dispatch => {
  const response = {
    searchParams: {
      keyword,
      sortBy,
    },
  };
  dispatch(POST_SAVE_PARAMS(response));
};

export const searchPost = ({
  acctId,
  email,
  accessToken,
  lastRecordSize,
  requestRecordSize,
  hashId,
  userId,
  groupId,
  pageId,
  sortBy,
  keyword,
  visibleIds,
}) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    lastRecordSize,
    requestRecordSize,
    hashId,
    userId,
    groupId,
    pageId,
    keyword: keyword && keyword.charAt(0) == '#' ? keyword.slice(1) : keyword,
    visibleIds,
  };
  dispatch(POST_LOADING());

  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/sf/get-social-post-by-top`,
    params: body,
  })
    .then(res => {
      console.log(res);
      if (res.status === 201) {
        console.log(res);
        const response = {
          searchResult: res.data.payload.postList,
          searchParams: {
            keyword,
            sortBy,
          },
          token: res.data.token,
        };
        dispatch(POST_SEARCH_SUCCESS(response));
      } else if (res.status === 202) {
      } else {
        dispatch(POST_SEARCH_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const searchPostByPostId = ({
  acctId,
  email,
  accessToken,
  postId,
  parentId,
  requestRecordSizeComment,
  commentRequestLength,
  visibleIds,
}) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    postId,
    parentId,
    requestRecordSizeComment,
    commentRequestLength,
    visibleIds,
  };
  dispatch(POST_LOADING());

  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/sf/get-social-post-by-post-id`,
    params: body,
  })
    .then(res => {
      console.log(res);
      if (res.status === 201) {
        console.log(res);
        var keyword = '';
        var sortBy = 'top';
        const response = {
          searchResult: res.data.payload.postList,
          searchParams: {
            keyword,
            sortBy,
          },
          token: res.data.token,
        };
        dispatch(POST_SEARCH_SUCCESS(response));
      } else if (res.status === 202) {
      } else {
        dispatch(POST_SEARCH_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export default searchPostSlice.reducer;
