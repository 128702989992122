import React, { useEffect, useState } from 'react';

import EditGeneralProfile from './GeneralProfile/EditGeneralProfile';
import EditWorkExperience from './WorkExperience/EditWorkExperience';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import EditEducation from './Education/EditEducation';
import EditResume from './Resume/EditResume';
import PrivacySettings from '../PrivacySettings/PrivacySettings';
import PrivacySettingModal from '../../../Page/ProfilePage/ProfileSection/PrivacySettingModal.js';
import { useSelector, useDispatch } from 'react-redux';
import { activateEditType, deactivateEditType } from 'configurations/redux/editProfileModalSlice';

import { Typography, makeStyles, List, ListItem, ListItemText, Button } from '@material-ui/core';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';

import clsx from 'clsx';
import Hidden from '@material-ui/core/Hidden';
import MenuItem from '@mui/material/MenuItem';
import editProfileStyles from 'assets/jss/material-kit-pro-react/views/componentStyle/profileView/editProfile/editProfileStyles';
import { useModal } from 'hooks';

const EditProfile = ({ match, edit, extension, saveDetailModalProps, setIsChanged, isChanged }) => {
  const styles = makeStyles(editProfileStyles)();
  const dispatch = useDispatch();
  const [activeNav, setActiveNav] = useState('generalProfile');
  const [addNew, setAddNew] = useState('');
  const user = useSelector(state => state.user);
  const tabType = useSelector(state => state.editProfileModal.tabType);
  const buildProfileFlag = useSelector(state => state.auth.buildProfileFlag);
  const profileStrength = useSelector(state => state.user.profileStrength);
  const [tabSelectedBeforeConfirm, setTabSelectedBeforeConfirm] = useState(edit);

  let mockExperiences = user.experienceRecords;

  let mockEducations = user.educationRecords;

  const navItems = ['GENERAL_PROFILE', 'EXPERIENCE', 'EDUCATION', 'RESUME', 'PRIVACY_SETTING'];

  useEffect(() => {
    setActiveNav(edit);
  }, []);
  console.log(isChanged, 'Checking');
  const onTabChange = tab => {
    if (isChanged) {
      console.log(isChanged);
      saveDetailModalProps.setModalOpen(true);
      setTabSelectedBeforeConfirm(tab);
    } else {
      const response = {
        type: 'EDIT_PROFILE',
        tabType: tab,
      };

      dispatch(activateEditType(response));
    }
  };
  const onCancelChangesConfirm = () => {
    const response = {
      type: 'EDIT_PROFILE',
      tabType: tabSelectedBeforeConfirm,
    };

    dispatch(activateEditType(response));
    setIsChanged(false);
    saveDetailModalProps.setModalOpen(false);
  };

  return (
    <Typography component="div" className={styles.body}>
      <Typography component="h1" className={styles.header}>
        {buildProfileFlag === 1 ? 'Edit Profile' : 'Finish Basic Profile before using Oppty'}
      </Typography>
      <Typography component="div" className={styles.contentContainer}>
        <Hidden mdDown>
          <List component="nav" className={styles.navBar} disablePadding>
            <ListItem
              disableGutters
              alignItems="center"
              button={true}
              className={clsx({
                [styles.navItem]: true,
                [styles.navItemActive]: tabType === navItems[0],
              })}
              onClick={() => {
                /* setActiveNav(navItems[0]);
                setAddNew('');
                const response = {
                  type: 'EDIT_PROFILE',
                  tabType: 'GENERAL_PROFILE',
                };

                dispatch(activateEditType(response)); */
                onTabChange(navItems[0]);
              }}
            >
              <ListItemText primary="General Profile" />
              <div className={tabType === navItems[0] ? styles.navItemRightBorder : ''}></div>
            </ListItem>
            <ListItem
              disableGutters
              alignItems="center"
              button={true}
              className={clsx({
                [styles.navItem]: true,
                [styles.navItemActive]: tabType === navItems[1],
              })}
              onClick={() => {
                onTabChange(navItems[1]);
              }}
            >
              <ListItemText primary="Work Experience" />
              <div className={tabType === navItems[1] ? styles.navItemRightBorder : ''}></div>
            </ListItem>
            <ListItem
              disableGutters
              alignItems="center"
              button={true}
              className={clsx({
                [styles.navItem]: true,
                [styles.navItemActive]: tabType === navItems[2],
              })}
              onClick={() => {
                onTabChange(navItems[2]);
              }}
            >
              <ListItemText primary="Education" />
              <div className={tabType === navItems[2] ? styles.navItemRightBorder : ''}></div>
            </ListItem>
            <ListItem
              disableGutters
              alignItems="center"
              button={true}
              className={clsx({
                [styles.navItem]: true,
                [styles.navItemActive]: tabType === navItems[3],
              })}
              onClick={() => {
                onTabChange(navItems[3]);
              }}
            >
              <ListItemText primary="Your Resume" />
              <div className={tabType === navItems[3] ? styles.navItemRightBorder : ''}></div>
            </ListItem>

            <ListItem
              disableGutters
              alignItems="center"
              button={true}
              className={clsx({
                [styles.navItem]: true,
                [styles.navItemActive]: tabType === navItems[4],
              })}
              onClick={() => {
                onTabChange(navItems[4]);
              }}
            >
              <ListItemText primary="General Settings" />
              <div className={tabType === navItems[5] ? styles.navItemRightBorder : ''}></div>
            </ListItem>
          </List>
        </Hidden>

        <Typography component="div" className={styles.main}>
          <Hidden lgUp>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card className={styles.cardBorder}>
                  <CardBody>
                    <Select
                      value={tabType}
                      variant="standard"
                      fullWidth
                      sx={{ margin: '15px 0px' }}
                      onChange={event => {
                        /* const response = {
                          type: 'EDIT_PROFILE',
                          tabType: event.target.value,
                        };
                        dispatch(activateEditType(response)); */
                        if (isChanged) {
                          saveDetailModalProps.setModalOpen(true);
                          setTabSelectedBeforeConfirm(event.target.value);
                        } else {
                          const response = {
                            type: 'EDIT_PROFILE',
                            tabType: event.target.value,
                          };

                          dispatch(activateEditType(response));
                        }
                      }}
                    >
                      <MenuItem className={styles.menuItem} value={'GENERAL_PROFILE'}>
                        General Profile
                      </MenuItem>
                      <MenuItem className={styles.menuItem} value={'EDUCATION'}>
                        Education
                      </MenuItem>
                      <MenuItem className={styles.menuItem} value={'EXPERIENCE'}>
                        Experience
                      </MenuItem>
                      <MenuItem className={styles.menuItem} value={'RESUME'}>
                        Your Resume
                      </MenuItem>
                      <MenuItem className={styles.menuItem} value={'PRIVACY_SETTING'}>
                        General Settings
                      </MenuItem>
                    </Select>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </Hidden>

          {tabType === navItems[0] && (
            <EditGeneralProfile
              match={match}
              extension={
                profileStrength.length > 0
                  ? profileStrength[0].strengthList
                      .filter(item => {
                        return (
                          item.improvementTip.functionCode === 'MF_PM_MODAL_BASIC_PROFILE' ||
                          item.improvementTip.functionCode === 'MF_PM_SECTION_SKILL_ATTRIBUTE'
                        );
                      })
                      .filter(item => {
                        return item.value === 0;
                      })
                  : null
              }
              saveDetailModalProps={saveDetailModalProps}
              onCancelChangesConfirm={onCancelChangesConfirm}
              setIsChanged={setIsChanged}
            />
          )}
          {tabType === navItems[1] && (
            <EditWorkExperience
              match={match}
              workExperience={mockExperiences}
              key={tabType}
              masterExtension={
                profileStrength.length > 0
                  ? profileStrength[0].strengthList
                      .filter(item => {
                        return item.improvementTip.functionCode === 'MF_PM_MODAL_EXPERIENCE';
                      })
                      .filter(item => {
                        return item.value === 0;
                      })
                      .map(item => {
                        return item.improvementTip;
                      })
                  : null
              }
              saveDetailModalProps={saveDetailModalProps}
              onCancelChangesConfirm={onCancelChangesConfirm}
              setIsChanged={setIsChanged}
            />
          )}
          {tabType === navItems[2] && (
            <EditEducation
              match={match}
              educations={mockEducations}
              masterExtension={
                profileStrength.length > 0
                  ? profileStrength[0].strengthList
                      .filter(item => {
                        return item.improvementTip.functionCode === 'MF_PM_MODAL_EDUCATION';
                      })
                      .filter(item => {
                        return item.value === 0;
                      })
                      .map(item => {
                        return item.improvementTip;
                      })
                  : null
              }
              saveDetailModalProps={saveDetailModalProps}
              onCancelChangesConfirm={onCancelChangesConfirm}
              setIsChanged={setIsChanged}
            />
          )}
          {tabType === navItems[3] && (
            <EditResume
              match={match}
              saveDetailModalProps={saveDetailModalProps}
              onCancelChangesConfirm={onCancelChangesConfirm}
              setIsChanged={setIsChanged}
            />
          )}
          {tabType === navItems[4] && (
            <PrivacySettings
              masterExtension={
                profileStrength.length > 0
                  ? profileStrength[0].strengthList
                      .filter(item => {
                        return item.improvementTip.functionCode === 'MF_PM_MODAL_PRIVACY_SETTING';
                      })
                      .filter(item => {
                        return item.value === 0;
                      })
                      .map(item => {
                        return item.improvementTip;
                      })
                  : null
              }
              saveDetailModalProps={saveDetailModalProps}
              onCancelChangesConfirm={onCancelChangesConfirm}
              setIsChanged={setIsChanged}
            />
          )}
        </Typography>
      </Typography>
    </Typography>
  );
};
export default EditProfile;
