import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
	instanceUrl,
	header,
	emptyCache,
} from "configurations/configurations.js";

const favProfileSlice = createSlice({
	name: "favProfile",
	initialState: {
		profileList: [],
		isUpdated: true,
		isLoading: false,
		isRemoved: false,
		loadingFavProfileList: false,
	},
	reducers: {
		FAV_PROFILE_LOADING: (state) => {
			state.isLoading = true;
		},
		FAV_PROFILE_PAGINATION_LOADING: (state) => {
			state.loadingFavProfileList = true;
		},
		FAV_PROFILE_LOAD_SUCCESS: (state, action) => {
			state.profileList = action.payload.profileList;
			state.isLoading = false;
			state.loadingFavProfileList = false;
			state.isUpdated = true;
			state.isRemoved = false;
			localStorage.setItem("token", action.payload.token);
		},
		REMOVE_FAV_PROFILE: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.profileList = state.profileList.filter((item) => {
				return item.favId !== action.payload.favId;
			});
			state.isLoading = false;
			state.isRemoved = true;
		},
		FAV_PROFILE_LOAD_FAIL: (state, action) => {
			localStorage.removeItem("token");
			state.profileList = [];
			state.isUpdated = false;
			state.isLoading = false;
		},
	},
});

const {
	FAV_PROFILE_LOADING,
	FAV_PROFILE_PAGINATION_LOADING,
	FAV_PROFILE_LOAD_SUCCESS,
	REMOVE_FAV_PROFILE,
	FAV_PROFILE_LOAD_FAIL,
} = favProfileSlice.actions;

export const getFavProfileList = ({
	acctId,
	accessToken,
	email,
	lastRecordSize,
	requestRecordSize,
	paginationClick,
}) => async (dispatch) => {
	if (paginationClick) dispatch(FAV_PROFILE_PAGINATION_LOADING());
	else dispatch(FAV_PROFILE_LOADING());
	const body = {
		acctId,
		accessToken,
		email,
		lastRecordSize,
		requestRecordSize,
	};
	console.log(body);
	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/pm/get-favourite-profile-list`,
		params: body,
	})
		.then((res) => {
			if (res.status === 201) {
				console.log(res);
				const response = {
					profileList: res.data.payload.profileList,
					token: res.data.token,
				};
				dispatch(FAV_PROFILE_LOAD_SUCCESS(response));
			} else {
				console.log(res);
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const getFavProfileListNew = ({
	acctId,
	accessToken,
	email,
	lastRecordSize,
	requestRecordSize,
	paginationClick,
	bizId,
}) => async (dispatch) => {
	if (paginationClick) dispatch(FAV_PROFILE_PAGINATION_LOADING());
	else dispatch(FAV_PROFILE_LOADING());
	const body = {
		acctId,
		accessToken,
		email,
		lastRecordSize,
		requestRecordSize,
		bizId,
	};
	console.log(body);
	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/pm/get-favourite-profile-list`,
		params: body,
	})
		.then((res) => {
			if (res.status === 201) {
				console.log(res);
				const response = {
					profileList: res.data.payload.profileList,
					token: res.data.token,
				};
				dispatch(FAV_PROFILE_LOAD_SUCCESS(response));
			} else {
				console.log(res);
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const removeFavProfile = ({
	acctId,
	email,
	favId,
	accessToken,
}) => async (dispatch) => {
	dispatch(FAV_PROFILE_PAGINATION_LOADING());
	const body = { email, acctId, favId, accessToken };
	await axios({
		method: "DELETE",
		headers: header,
		url: `${instanceUrl}/api/pm/remove-favourite-profile`,
		data: body,
	})
		.then((res) => {
			console.log(res.data);
			if (res.status === 201) {
				console.log(res.data);
				const response = {
					token: res.data.token,
					favId: favId,
				};
				dispatch(REMOVE_FAV_PROFILE(response));
			} else {
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export default favProfileSlice.reducer;
