import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

// core components
import Button from "components/CustomButtons/Button.js";

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import Close from "@material-ui/icons/Close";

import CommentImageCrop from "../../Component/ImageCrop/CommentImageCrop.js";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/LandingPageFeed/CommentStyle.js";
import style from "assets/jss/material-kit-pro-react/modalStyle.js";
import IconLibrary from "views/Component/IconLibrary.js";
import Compressor from "compressorjs";

const useStyles = makeStyles(profilePageStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

const CommentMediaModal = ({ open, onClose, pictureSource }) => {
	// console.log(pictureSource);
	const classes = useStyles();
	const [imageSrc, setImageSrc] = React.useState(pictureSource);

	return (
		<React.Fragment>
			<Dialog
				classes={{
					root: classes.modalRoot,
					paper: classes.modalCommentEdit + " " + classes.modal,
				}}
				open={open}
				// TransitionComponent={Transition}
				// keepMounted
				// disableBackdropClick
				onClose={(event, reason) => {
					if (reason !== "backdropClick") {
						onClose(event, reason);
					}
				}}
				aria-labelledby="classic-modal-slide-title"
				aria-describedby="classic-modal-slide-description"
			>
				<DialogTitle
					id="classic-modal-slide-title"
					disableTypography
					className={classes.modalTitleEdit}
				>
					<Button
						simple
						className={classes.modalCloseButton}
						key="close"
						aria-label="Close"
						onClick={onClose}
					>
						<IconLibrary.Close />
					</Button>
				</DialogTitle>
				<DialogContent
					id="classic-modal-slide-description"
					className={classes.modalBody}
				>
					<img
						src={pictureSource ? pictureSource : null}
						alt=""
						style={{
							width: "100%",
							borderRadius: "18px",
							border: "1px solid #DEDEDE",
						}}
					/>
				</DialogContent>
				<div className={classes.modalFooter}></div>
			</Dialog>
		</React.Fragment>
	);
};
const mapStateToProps = (state) => ({
	userStatus: state.auth.user,
	// image: state.user.image,
});
export default connect(mapStateToProps, null)(CommentMediaModal);
