import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { departmentViewRoadmap, viewRoadmapRoleByRoleId } from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";
import ImageMapper from "react-image-mapper";
import Department_Public_Finance_Management from "assets/img/Career_Roadmap/Finance/Department_Public_Finance_Management_1.jpg";
import RoleInfoModal from "../RoleInfoModal.js";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentPublicFinanceManagement() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Public_Finance_Management;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "pfm",
				shape: "poly",
				coords: [81,351,72,351,69,353,66,355,62,358,60,363,58,368,58,422,58,427,61,431,63,435,67,438,74,442,164,442,171,441,175,439,180,436,184,430,186,423,186,373,186,367,183,360,178,355,173,352,167,351,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "43",
				shape: "poly",
				coords: [218,356,211,357,206,360,203,364,202,371,201,412,201,422,204,426,207,431,212,433,349,434,355,432,359,429,361,425,363,419,363,373,362,366,360,362,355,359,349,357,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "44",
				shape: "poly",
				coords: [470,356,463,356,459,358,456,361,452,365,450,371,451,420,452,425,455,429,460,432,466,434,591,434,600,434,605,432,607,428,610,425,612,420,612,374,611,366,609,363,606,361,603,359,599,357,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "45",
				shape: "poly",
				coords: [711,358,701,359,696,361,693,364,690,368,688,373,687,418,689,426,692,429,695,432,702,436,834,436,842,434,845,432,848,428,851,421,851,372,848,367,846,363,842,360,837,359,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "46",
				shape: "poly",
				coords: [957,356,950,358,946,359,944,362,941,367,940,372,940,417,941,423,944,429,949,432,958,434,1088,433,1096,432,1097,430,1100,426,1102,418,1103,375,1102,368,1100,364,1096,361,1092,358,1088,357,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "47",
				shape: "poly",
				coords: [1198,356,1192,358,1189,360,1185,363,1183,369,1182,422,1186,427,1190,431,1197,434,1331,434,1337,431,1341,428,1343,425,1344,420,1345,371,1343,366,1339,361,1334,358,1330,357,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "48",
				shape: "poly",
				coords: [1444,359,1438,360,1434,362,1431,364,1428,369,1426,375,1426,416,1427,426,1430,431,1434,434,1440,436,1576,435,1582,433,1584,430,1588,426,1589,420,1588,374,1586,366,1584,363,1579,361,1575,359,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "37",
				shape: "poly",
				coords: [1684,358,1674,360,1670,362,1667,367,1665,375,1665,414,1667,424,1669,429,1673,433,1680,436,1818,436,1824,434,1829,431,1832,427,1834,420,1834,372,1831,366,1828,362,1823,360,1818,358,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "pfm") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo)
			dispatch(viewRoadmapRoleByRoleId(requestInfo));
			
			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// console.log(coords.x + "," + coords.y + ",");
		// navigator.clipboard.writeText(coords.x + "," + coords.y + ",");
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}

	function enterArea(area) {
		console.log(area);
	}

	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
				onMouseEnter={(area) => enterArea(area)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
