const resourcesStyle = (theme) => ({
  mainContainer: {
    overflow: "scroll",
    marginTop: "95px",
    paddingTop: "20px",
    height: "calc(100vh - 95px)",
  },
  subHeader: {
    background: "white",
    margin: "0 5vh 5vh 5vh",
    height: "auto",
    borderRadius: "2vh",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    paddingTop: "3vh",
    alignItems: "center",
  },
  textHeader: {
    fontSize: "4vh",
    fontWeight: "bold",
    "&p": {
      margin: 0,
    },
  },
  tabsContainer: {},
  active: {
    color: "#6F52ED",
    textTransform: "none",
  },
  inactive: {
    color: "grey",
    textTransform: "none",
  },
});

export default resourcesStyle;
