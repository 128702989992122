import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { departmentViewRoadmap, viewRoadmapRoleByRoleId } from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";
import ImageMapper from "react-image-mapper";
import Department_Personal_Finance from "assets/img/Career_Roadmap/Finance/Department_Personal_Finance_1.jpg";
import RoleInfoModal from "../RoleInfoModal.js";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentPersonalFinance() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Personal_Finance;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "pf",
				shape: "poly",
				coords: [77,356,68,357,62,360,58,363,54,367,52,374,52,413,54,418,57,424,61,428,64,430,70,432,157,432,165,432,171,429,176,425,179,420,181,412,181,382,182,372,179,368,174,362,169,358,162,356,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "28",
				shape: "poly",
				coords: [220,354,212,356,206,356,202,360,198,363,197,369,197,414,198,420,200,424,205,429,212,432,343,432,350,430,354,428,358,424,360,418,359,366,357,362,354,358,350,356,344,356,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "29",
				shape: "poly",
				coords: [572,355,565,358,561,361,558,364,557,372,557,417,559,423,561,427,566,431,572,433,706,433,711,430,715,428,718,424,719,417,720,372,718,365,715,360,710,357,705,356,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "30",
				shape: "poly",
				coords: [934,354,922,355,918,357,915,361,913,365,911,371,912,420,914,426,918,429,925,432,1058,431,1064,430,1068,428,1071,424,1073,417,1073,372,1072,364,1069,360,1065,356,1060,355,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "31",
				shape: "poly",
				coords: [1290,355,1283,357,1279,359,1276,361,1274,365,1272,372,1272,416,1273,422,1276,427,1279,430,1287,432,1416,433,1424,431,1428,429,1432,424,1433,419,1434,372,1433,365,1430,361,1427,358,1422,356,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "32",
				shape: "poly",
				coords: [1690,359,1683,360,1680,364,1677,367,1675,372,1674,418,1677,426,1680,431,1684,434,1690,436,1817,435,1826,435,1831,432,1833,429,1835,426,1837,421,1836,370,1835,367,1832,363,1829,361,1825,360,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "pf") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo)
			dispatch(viewRoadmapRoleByRoleId(requestInfo));
			
			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// console.log(coords.x + "," + coords.y + ",");
		// navigator.clipboard.writeText(coords.x + "," + coords.y + ",");
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}

	function enterArea(area) {
		console.log(area);
	}

	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
				onMouseEnter={(area) => enterArea(area)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
