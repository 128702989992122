import React from "react";

import { Typography, makeStyles, Button } from "@material-ui/core";

import popularGroupCardStyles from "assets/jss/material-kit-pro-react/views/componentStyle/popularGroups/popularGroupCardStyles.js"
const useStyles = makeStyles(popularGroupCardStyles);

const PopularGroupCard = (props) => {
  const styles = useStyles();

  return (
    <Typography component="div" className={styles.main}>
      <Typography component="div" className={styles.imageContainer}>
        <img src={props.image} alt="" />
      </Typography>

      <Typography component="div" className={styles.contentContainer}>
        <Typography component="div">

          <Typography component="h3" className={styles.heading}>
            {props.name}
          </Typography>

          <Typography component="div" className={styles.details}>
            <Typography component="div">
              <Typography component="p" className={styles.description}>
                {props.description}
              </Typography>
              <Typography component="p" className={styles.members}>
                <svg xmlns="http://www.w3.org/2000/svg" width="5.296" height="4.981" viewBox="0 0 5.296 4.981">
                  <ellipse id="Oval" cx="2.648" cy="2.49" rx="2.648" ry="2.49" fill="#d5d5dc" />
                </svg>
                {props.members} Members
              </Typography>
            </Typography>

            <Typography component="div">
              <Typography component="div" className={styles.friendsProfiles}>
                <img src={props.image} alt="" />
                <img src={props.image} alt="" />
                {/* <img src={props.image} alt="" /> */}
                <Typography component="span" className={styles.greaterThenThreeImages}>
                  +2
                </Typography>
              </Typography>
              <Typography component="p" className={styles.friendsConnect}>
                6 Friends Connect
              </Typography>
            </Typography>

          </Typography>

        </Typography>

        <Typography component="div" className={styles.joinBtn}>
          <Button>Join Group</Button>
        </Typography>
      </Typography>

    </Typography>
  )
}
export default PopularGroupCard;