import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
	departmentViewRoadmap,
	viewRoadmapRoleByRoleId,
} from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Industry_Banking_Wealth_Management from "assets/img/Career_Roadmap/Banking_Wealth_Management/Industry_Banking_Wealth_Management_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function IndustryBankingWealthManagement() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Industry_Banking_Wealth_Management;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "rb",
				shape: "poly",
				coords: [75,263,69,266,65,268,62,273,59,280,60,322,62,327,65,331,69,335,76,338,183,338,189,335,194,332,197,328,200,320,200,280,198,272,192,266,186,263,180,262,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "ab",
				shape: "poly",
				coords: [79,398,71,401,66,405,62,410,60,417,61,483,63,488,66,492,72,497,81,500,184,499,190,496,195,494,198,489,201,481,201,416,200,410,195,404,188,400,181,398,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "pb",
				shape: "poly",
				coords: [79,559,71,562,66,566,62,570,60,577,60,619,62,625,66,629,70,632,77,636,183,636,190,633,195,629,198,624,200,618,200,577,198,571,193,565,188,562,180,560,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "392",
				shape: "poly",
				coords: [633,261,626,264,622,267,620,272,619,276,619,326,622,331,624,335,628,337,635,338,826,339,834,336,838,333,840,329,842,323,841,276,840,270,836,266,832,262,826,262,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "393",
				shape: "poly",
				coords: [998,261,991,263,986,266,984,271,983,277,983,324,985,331,989,335,993,338,1000,339,1156,338,1163,336,1168,333,1170,328,1171,322,1171,278,1170,271,1167,266,1162,264,1156,262,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "394",
				shape: "poly",
				coords: [1322,262,1315,265,1312,268,1310,272,1308,277,1309,324,1310,330,1314,334,1319,337,1326,339,1480,339,1488,337,1492,333,1495,329,1497,324,1496,279,1496,270,1492,266,1487,263,1480,261,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "395",
				shape: "poly",
				coords: [1651,262,1645,264,1640,268,1638,272,1638,278,1638,324,1640,330,1642,334,1647,337,1654,339,1810,339,1818,337,1822,334,1824,330,1826,326,1826,278,1824,270,1821,266,1815,262,1809,261,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "475",
				shape: "poly",
				coords: [273,410,264,412,260,414,256,419,254,425,255,475,257,482,261,486,266,487,273,488,462,488,468,485,472,482,474,477,476,470,475,424,473,417,469,413,465,411,458,410,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "476",
				shape: "poly",
				coords: [268,559,262,561,258,564,255,568,254,574,254,622,255,628,258,632,263,635,271,637,460,637,468,634,472,631,474,626,476,621,476,575,474,568,470,563,463,560,456,558,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "396",
				shape: "poly",
				coords: [632,410,625,414,622,416,619,420,619,427,619,472,622,479,625,483,630,487,636,488,826,488,833,486,836,482,840,479,841,471,841,425,838,418,834,414,828,411,822,410,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "397",
				shape: "poly",
				coords: [1000,410,992,413,987,416,984,420,983,427,983,471,984,478,988,483,993,486,1000,488,1155,488,1163,486,1167,482,1170,477,1171,473,1172,429,1170,420,1166,413,1162,411,1155,409,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "398",
				shape: "poly",
				coords: [1324,410,1317,412,1312,416,1310,420,1308,426,1309,475,1311,479,1314,484,1319,487,1325,488,1482,488,1489,485,1493,482,1495,478,1496,472,1497,427,1496,420,1492,415,1489,412,1481,410,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "408",
				shape: "poly",
				coords: [1654,410,1645,412,1641,416,1638,421,1637,428,1638,473,1639,479,1644,484,1648,487,1658,488,1811,488,1819,484,1822,482,1825,476,1826,470,1826,426,1825,418,1822,414,1816,411,1809,410,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "409",
				shape: "poly",
				coords: [632,558,627,560,623,563,619,567,619,573,618,621,620,628,623,632,628,635,634,636,826,636,834,634,837,631,840,628,841,624,840,572,838,566,835,562,830,560,824,558,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "410",
				shape: "poly",
				coords: [1003,558,992,560,986,565,984,570,983,576,983,615,984,626,987,631,993,635,1000,637,1155,637,1164,634,1168,630,1171,625,1172,621,1172,575,1169,569,1166,564,1162,561,1156,559,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "411",
				shape: "poly",
				coords: [1325,559,1316,562,1312,565,1310,571,1309,576,1309,623,1312,629,1315,633,1320,636,1326,637,1482,636,1489,634,1493,631,1496,626,1497,620,1496,576,1496,569,1492,564,1487,561,1481,559,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "412",
				shape: "poly",
				coords: [1655,559,1646,561,1641,565,1638,572,1637,577,1637,623,1639,629,1643,633,1648,636,1654,637,1810,637,1818,635,1821,632,1824,628,1826,622,1825,573,1823,566,1820,562,1814,559,1809,559,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "rb") {
			dispatch(departmentViewRoadmap(47));
		} else if (area.name === "ab") {
			dispatch(departmentViewRoadmap(48));
		} else if (area.name === "pb") {
			dispatch(departmentViewRoadmap(49));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo);
			dispatch(viewRoadmapRoleByRoleId(requestInfo));

			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
