import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, makeStyles, Modal, Typography } from '@material-ui/core';
import testimonialsPageStyles from 'assets/jss/material-kit-pro-react/views/pageStyle/TestimonialsPage/testimonialsPageStyles';
import { activateEditType, deactivateEditType } from 'configurations/redux/editProfileModalSlice';
import RequestTestimonial from './RequestTestimonialComponent';

import WriteTestimonialComponentWithoutRequest from './WriteTestimonialComponentWithoutRequest.js';
import WriteTestimonialComponentWithRequest from './WriteTestimonialComponentWithRequest.js';
import RequestRevisionComponent from './RequestRevisionComponent.js';
import { useModal } from 'hooks';
import IconLibrary from 'views/Component/IconLibrary';
const useStyles = makeStyles(testimonialsPageStyles);

export default function TestimonialModal({ open, onClose }) {
  const styles = useStyles();
  const isShow = useSelector(state => state.editProfileModal.isShow);
  const tabType = useSelector(state => state.editProfileModal.tabType);
  const type = useSelector(state => state.editProfileModal.type);
  const extension = useSelector(state => state.editProfileModal.extension);
  const [isChanged, setIsChanged] = React.useState(false);
  const saveSettingsModalProps = useModal();

  const modalOnClose = () => {
    if (isChanged) {
      saveSettingsModalProps.setModalOpen(true);
    } else {
      onClose();
    }
  };
  return (
    <Modal
      disableEnforceFocus
      open={open}
      onClose={(event, reason) => {
        if (reason && reason == 'backdropClick') return;
        modalOnClose();
      }}
    >
      <Box className={styles.editProfileModalBox}>
        <Button onClick={modalOnClose}>
          <IconLibrary.Close width={24} height={24} />
        </Button>

        {type === 'REQUEST_TESTIMONIAL' && (
          <RequestTestimonial
            isChanged={isChanged}
            setIsChanged={setIsChanged}
            saveSettingsModalProps={saveSettingsModalProps}
            onClose={onClose}
          />
        )}

        {type === 'WRITE_TESTIMONIAL' && (
          <WriteTestimonialComponentWithoutRequest
            isChanged={isChanged}
            setIsChanged={setIsChanged}
            saveSettingsModalProps={saveSettingsModalProps}
            onClose={onClose}
          />
        )}
        {type === 'REPLY_TESTIMONIAL' && extension && (
          <WriteTestimonialComponentWithRequest
            isChanged={isChanged}
            setIsChanged={setIsChanged}
            saveSettingsModalProps={saveSettingsModalProps}
            onClose={onClose}
          />
        )}
        {type === 'REQUEST_REVISION' && extension && (
          <RequestRevisionComponent
            isChanged={isChanged}
            setIsChanged={setIsChanged}
            saveSettingsModalProps={saveSettingsModalProps}
            onClose={onClose}
          />
        )}
      </Box>
    </Modal>
  );
}
