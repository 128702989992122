import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCandidateSelectJobPosts } from "configurations/redux/jobManagementSlice.js";
import { TimeFormat } from "configurations/configurations.js";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { Link } from "react-router-dom";

import Tooltip from "@material-ui/core/Tooltip";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import Primary from "components/Typography/Primary.js";

import jobStatusPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/JobStatusPage/jobStatusPageStyle.js";
import Pagination from "../../Component/Pagination.js";
import ResultJobModal from "../SearchJobPage/ResultJobModal.js";
import RemoveModal from "./RemoveModal.js";
import NoRecordModal from "../../Component/NoRecordModal.js";

import ClearIcon from "@material-ui/icons/Clear";

import defaultAvatar from "assets/img/corporate_avatar.jpeg";
import { Divider } from "@material-ui/core";
import { getFavJobPostList } from "configurations/redux/favJobSlice";
import LoadingImage from "assets/img/Loading-pana.png";
import IconLibrary from "views/Component/IconLibrary.js";

const useStyles = makeStyles(jobStatusPageStyle);

const publicIp = require("public-ip");

export default function SavedJobSection() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const favJobPostList = useSelector((state) => state.favJob.jobPostList);
	const isDeleted = useSelector((state) => state.favJob.isDeleted);
	const userStatus = useSelector((state) => state.auth.user);
	const isLoading = useSelector((state) => state.favJob.isLoading);

	const [currentPage, setCurrentPage] = React.useState(1);
	const [jobsPerPage, setJobsPerPage] = React.useState(5);
	const [jobViewModal, setJobViewModal] = React.useState(false);
	const [deleteFavModal, setDeleteFavModal] = React.useState(false);
	const [favId, setFavId] = React.useState(false);
	const [favJobModal, setFavJobModal] = React.useState(false);
	const [noRecordModal, setNoRecordModal] = React.useState(false);

	const indexOfLastJob = currentPage * jobsPerPage;
	const indexOfFirstJob = indexOfLastJob - jobsPerPage;
	const currentJobs = favJobPostList.slice(indexOfFirstJob, indexOfLastJob);

	const onClose = () => {
		setJobViewModal(false);
		setCurrentPage(currentPage);
		var indexOfFirstJob = currentPage * jobsPerPage - jobsPerPage;
		getJobPostList(indexOfFirstJob, false);
	};
	const noRecordOnClose = () => {
		setNoRecordModal(false);
	};
	const favJobRemoveOnClose = () => {
		setFavId();
		setFavJobModal(false);
	};
	const paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
		var indexOfFirstJob = pageNumber * jobsPerPage - jobsPerPage;
		getJobPostList(indexOfFirstJob, true);
	};

	const getJobPost = async (item) => {
		if (item.statusId === 1) {
			const requestProfile = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				ipAddr: localStorage.getItem("ipAddr"), //await publicIp.v4(),
				jobPostId: item.jobPostId,
				item: null,
			};
			dispatch(getCandidateSelectJobPosts(requestProfile));
			setJobViewModal(true);
		} else {
			setNoRecordModal(true);
		}
	};

	React.useEffect(() => {
		if (isDeleted) {
			var newPage = 0;
			if (favJobPostList.length > 0) newPage = currentPage;
			else newPage = currentPage - 1;
			var indexOfFirstJob = newPage * jobsPerPage - jobsPerPage;
			console.log(newPage);
			setCurrentPage(newPage);
			getJobPostList(indexOfFirstJob, false);
		}
		pageResize();
	}, [isDeleted, favJobPostList]);

	const pageResize = () => {
		var timerSaved = setInterval(() => {
			var pageHeader = document.getElementById("pageHeader");
			var savedCard = document.getElementById("savedCard");
			if (pageHeader && savedCard) {
				clearInterval(timerSaved);
				pageHeader.style.minHeight = "468px";
				if (savedCard.clientHeight <= 450) {
					pageHeader.style.height = "468px";
				} else {
					pageHeader.style.minHeight = "1px";
					pageHeader.style.height = "auto";
				}
			} else {
				setTimeout(() => {
					clearInterval(timerSaved);
				}, 3000);
			}
		}, 50);
		return () => clearInterval(timerSaved);
	};

	const getJobPostList = async (indexOfFirstJob, scroll) => {
		const requestProfile = {
			acctId: userStatus.acctId,
			email: userStatus.email,
			accessToken: localStorage.getItem("token"),
			lastRecordSize: indexOfFirstJob,
			requestRecordSize: jobsPerPage,
			paginationClick: scroll,
		};
		dispatch(getFavJobPostList(requestProfile));
		if (scroll) window.scrollTo(0, 0);
	};

	const imageClasses = classNames(
		classes.imgRaised,
		classes.imgRoundedCircle,
		classes.imgProfile,
		classes.logoImage
	);

	const FavJobPost = favJobPostList.map((item, index) => {
		var jobCard = classes.cardClick + " " + classes.jobCard;
		if (favJobPostList) {
			if (favJobPostList[0].favId == item.favId) {
				jobCard =
					classes.cardClick +
					" " +
					classes.jobCard +
					" " +
					classes.jobCardFirst;
			} else if (
				favJobPostList[favJobPostList.length - 1].favId == item.favId
			) {
				jobCard =
					classes.cardClick +
					" " +
					classes.jobCard +
					" " +
					classes.jobCardBorder +
					" " +
					classes.jobCardLast;
			} else {
				jobCard =
					classes.cardClick +
					" " +
					classes.jobCard +
					" " +
					classes.jobCardBorder;
			}
		}
		return (
			<Card
				// className={classes.cardClick + " " + classes.cardFrame}
				className={jobCard}
				key={item.favId}
			>
				<CardBody>
					<GridContainer>
						<GridItem>
							<GridContainer
								alignContent="center"
								alignItems="center"
								onClick={() => {
									getJobPost(item);
								}}
							>
								<GridItem xs={3} sm={2} md={1} className={classes.itemCenter}>
									<img
										// className={imageClasses}
										className={classes.cardImage}
										src={item.profilePic ? item.profilePic : defaultAvatar}
										alt="..."
									/>
								</GridItem>
								<GridItem xs={7} sm={9} md={10}>
									<GridContainer alignContent="center" alignItems="center">
										<GridItem
											xs={12}
											sm={7}
											md={8}
											className={classes.gridItemMargin}
										>
											<h4 className={classes.jobTitle}>{item.title}</h4>
											<h5
												className={
													classes.description + " " + classes.companyName
												}
											>
												{item.companyName}
											</h5>
										</GridItem>
										<GridItem
											xs={12}
											sm={5}
											md={4}
											className={
												classes.gridItemMargin + " " + classes.rightGrid
											}
										>
											<h5 className={classes.jobPostStatus}>
												{item.jobPostType}{" "}
												{item.jobPostTypeId === 2
													? " - " + item.jobTypeId
													: null}
											</h5>

											<p className={classes.jobTime}>
												Saved {TimeFormat(item.favDate)}
											</p>
										</GridItem>
									</GridContainer>
								</GridItem>

								<GridItem xs={2} sm={1} md={1} className={classes.itemCenter}>
									<Tooltip
										id="tooltip-top"
										title="Remove Saved Job"
										placement="top"
										classes={{ tooltip: classes.tooltip }}
									>
										<Button
											justIcon
											round
											color="transparent"
											onClick={(event) => {
												event.stopPropagation();

												setFavId(item.favId);
												setFavJobModal(true);
											}}
											className={classes.buttonIcon}
										>
											<IconLibrary.Close />
										</Button>
									</Tooltip>
								</GridItem>
							</GridContainer>
						</GridItem>
					</GridContainer>
				</CardBody>
			</Card>
		);
	});

	const NoFavJobPost = () => {
		return (
			<Card className={classes.cardFrame}>
				<CardBody>
					<GridContainer justifyContent="center">
						<GridItem xs={12} sm={12} md={12} className={classes.itemCenter}>
							<h5 className={classes.title + " " + classes.titleWeight}>
								You haven't saved any jobs.
							</h5>
						</GridItem>
						<GridItem className={classes.itemCenter}>
							<Link
								className={classes.textDeco}
								to={{
									pathname: "/search-job-page",
								}}
								color="transparent"
							>
								<Button round color="oppty">
									<b>BROWSE JOB BOARD</b>
								</Button>
							</Link>
						</GridItem>
					</GridContainer>
				</CardBody>
			</Card>
		);
	};
	const LoadingJob = () => {
		return (
			<Card>
				<CardBody>
					<img
						src={LoadingImage}
						className={classes.backGroundImage}
						alt="Expired Session"
					/>
				</CardBody>
			</Card>
		);
	};
	return (
		<div id="savedCard">
			<GridContainer>
				<GridItem>
					{favJobPostList.length > 0 ? (
						<React.Fragment>
							<Card className={classes.modalCardBorder}>
								<CardBody className={classes.modalCardPadding}>
									{FavJobPost}
								</CardBody>
							</Card>
							<div className={classes.paginationAligned}>
								<Pagination
									totalPosts={favJobPostList[0].favCount}
									postsPerPage={jobsPerPage}
									paginate={paginate}
								/>
							</div>
						</React.Fragment>
					) : isLoading ? (
						<LoadingJob />
					) : (
						<NoFavJobPost />
					)}
				</GridItem>
			</GridContainer>
			{jobViewModal && <ResultJobModal open={jobViewModal} onClose={onClose} />}
			{favJobModal && (
				<RemoveModal
					open={favJobModal}
					onClose={favJobRemoveOnClose}
					favId={favId}
				/>
			)}
			{noRecordModal && (
				<NoRecordModal
					open={noRecordModal}
					onClose={noRecordOnClose}
					recordType={"Job Post"}
				/>
			)}
		</div>
	);
}
