import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
	departmentViewRoadmap,
	viewRoadmapRoleByRoleId,
} from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Department_Pharmacy_Support from "assets/img/Career_Roadmap/Healthcare/Department_Pharmacy_Support_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentPharmacySupport() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Pharmacy_Support;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "ps",
				shape: "poly",
				coords: [94,96,86,100,81,102,78,107,75,114,75,737,76,743,80,748,85,752,92,756,186,754,193,752,198,748,202,744,204,737,204,114,200,106,196,100,190,97,184,96,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "340",
				shape: "poly",
				coords: [244,380,237,382,233,386,230,391,230,399,230,468,231,478,235,481,240,485,248,486,409,485,415,482,418,478,421,474,422,468,422,396,420,388,416,383,410,380,404,379,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "341",
				shape: "poly",
				coords: [604,380,596,382,592,385,590,389,588,397,588,468,590,477,594,481,599,484,606,485,765,485,772,484,777,479,779,475,780,468,780,399,779,387,774,382,766,379,761,379,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "344",
				shape: "poly",
				coords: [1052,203,1042,205,1037,210,1034,217,1033,226,1033,292,1035,300,1038,304,1042,308,1052,310,1228,308,1236,306,1239,302,1242,298,1243,291,1243,220,1241,211,1236,207,1230,204,1227,203,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "345",
				shape: "poly",
				coords: [1419,203,1407,205,1403,208,1400,212,1398,219,1398,292,1401,302,1404,306,1410,309,1419,310,1589,310,1599,307,1604,303,1608,297,1609,292,1608,223,1608,213,1604,208,1596,204,1590,203,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "342",
				shape: "poly",
				coords: [1052,378,1042,381,1036,386,1034,392,1033,398,1033,469,1036,476,1040,482,1046,484,1055,484,1224,484,1235,483,1239,479,1242,474,1243,464,1244,400,1242,390,1238,383,1231,379,1223,379,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "343",
				shape: "poly",
				coords: [1418,378,1407,380,1402,385,1398,391,1398,398,1398,472,1402,479,1406,482,1413,485,1420,484,1587,485,1598,484,1602,481,1606,475,1608,468,1608,399,1608,389,1604,383,1598,379,1590,379,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "346",
				shape: "poly",
				coords: [1051,554,1042,556,1038,559,1035,564,1033,572,1034,641,1035,651,1039,656,1044,658,1051,659,1227,660,1236,657,1240,654,1242,650,1244,643,1243,571,1242,562,1237,558,1231,554,1223,553,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "347",
				shape: "poly",
				coords: [1428,552,1412,554,1404,558,1401,562,1399,570,1399,641,1401,651,1403,655,1408,658,1416,661,1590,660,1600,658,1604,654,1607,650,1609,641,1609,570,1606,561,1600,556,1594,553,1590,553,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "ps") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo);
			dispatch(viewRoadmapRoleByRoleId(requestInfo));

			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
