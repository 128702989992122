import React from "react";

import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import corporateProfilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/CorporateProfilePage/corporateProfilePageStyle.js";
import { Hidden } from "@material-ui/core";

const useStyles = makeStyles(corporateProfilePageStyle);

export default function CorporateFavouriteProfileSection() {
	const classes = useStyles();

	return (
		<div>
			<Card className={classes.cardFrame}>
				<CardBody>
					<GridContainer
						justifyContent="space-between"
						alignContent="center"
						alignItems="center"
					>
						<GridItem xs={8} sm={8} md={8}>
							<h4
								className={classes.title /* + " " + classes.jobCountTitle} */}
							>
								Favourite Candidate
							</h4>
						</GridItem>

						<GridItem xs={4} sm={4} md={4}>
							<Link
								className={classes.textDeco}
								to={{ pathname: "/favourite-profile-page" }}
							>
								<Button
									color="oppty"
									round
									fullWidth
									className={classes.buttonHeight}
								>
									<Hidden xsDown>
										<b>More</b>
									</Hidden>
									<Hidden smUp>
										<b>View</b>
									</Hidden>
								</Button>
							</Link>
						</GridItem>
					</GridContainer>
				</CardBody>
			</Card>
		</div>
	);
}
