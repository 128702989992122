import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
	departmentViewRoadmap,
	viewRoadmapRoleByRoleId,
} from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Department_Nursing from "assets/img/Career_Roadmap/Healthcare/Department_Nursing_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentNursing() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Nursing;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "n",
				shape: "poly",
				coords: [71,232,63,234,59,237,55,243,53,250,53,658,55,665,58,670,63,673,69,676,145,676,152,674,157,670,160,667,164,658,163,251,161,242,157,237,149,233,142,231,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "374",
				shape: "poly",
				coords: [193,318,187,320,183,322,181,326,180,331,180,397,182,403,184,407,190,410,194,413,287,412,293,410,296,406,299,402,300,397,300,331,297,324,293,320,290,318,284,318,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "375",
				shape: "poly",
				coords: [329,317,323,320,320,322,318,326,316,333,316,396,317,402,319,407,323,410,328,413,423,413,429,410,433,406,435,403,437,397,437,335,435,325,432,322,426,318,422,317,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "376",
				shape: "poly",
				coords: [467,317,460,320,457,323,453,327,452,332,453,399,455,404,457,408,462,412,469,412,560,412,565,410,569,406,571,402,572,397,573,331,571,325,568,322,564,319,559,317,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "377",
				shape: "poly",
				coords: [603,317,597,320,593,322,590,325,589,330,588,397,590,404,593,408,597,411,604,413,693,412,699,411,703,408,707,404,709,398,709,333,707,326,704,322,699,319,693,317,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "378",
				shape: "poly",
				coords: [253,492,248,494,244,497,241,503,240,509,241,570,242,578,244,582,249,585,255,587,345,587,352,585,356,582,359,577,360,571,360,508,359,500,356,496,350,492,345,491,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "379",
				shape: "poly",
				coords: [393,491,384,493,380,497,378,502,376,508,377,571,378,578,381,582,385,585,393,587,482,587,489,583,493,580,496,574,498,568,497,507,495,499,491,495,486,493,482,491,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "380",
				shape: "poly",
				coords: [528,492,521,494,516,498,514,502,513,509,513,571,514,578,517,583,522,586,529,587,617,587,623,585,628,582,631,578,633,571,633,512,633,503,630,497,623,493,618,491,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "381",
				shape: "poly",
				coords: [665,491,657,493,653,496,650,501,649,509,649,570,650,577,653,582,658,585,665,587,752,586,761,585,765,581,769,577,770,570,770,508,768,500,765,496,758,492,753,491,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "382",
				shape: "poly",
				coords: [845,230,834,232,829,236,826,241,825,248,824,305,826,316,829,321,835,325,844,326,927,326,937,324,941,320,943,315,946,309,946,252,944,240,940,235,935,232,929,230,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "383",
				shape: "poly",
				coords: [975,230,968,232,964,236,961,240,960,247,960,308,962,316,966,322,971,325,978,326,1061,326,1071,324,1076,322,1079,315,1081,306,1081,247,1078,238,1074,233,1068,230,1062,230,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "388",
				shape: "poly",
				coords: [1113,230,1105,232,1101,235,1098,239,1096,244,1097,312,1099,318,1101,321,1106,324,1111,326,1201,326,1208,324,1212,321,1216,316,1217,309,1217,250,1215,240,1211,233,1207,231,1201,230,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "389",
				shape: "poly",
				coords: [1254,230,1243,232,1238,235,1236,241,1234,249,1234,309,1235,317,1238,322,1244,325,1251,327,1339,326,1347,324,1351,321,1354,316,1354,307,1354,245,1353,240,1350,235,1345,232,1340,230,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "390",
				shape: "poly",
				coords: [1390,230,1379,232,1375,235,1373,240,1370,247,1370,309,1373,318,1377,322,1381,326,1388,326,1475,325,1483,324,1487,320,1489,317,1491,310,1491,250,1489,239,1486,235,1480,231,1475,230,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "386",
				shape: "poly",
				coords: [845,404,836,406,831,409,828,414,826,422,827,481,828,491,830,495,836,499,843,501,932,499,940,497,944,492,946,488,947,482,947,423,945,414,941,409,937,406,931,404,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "387",
				shape: "poly",
				coords: [979,404,969,408,966,410,963,414,961,422,961,481,962,490,966,494,971,499,980,500,1064,500,1073,498,1077,496,1080,490,1082,483,1082,425,1081,415,1078,410,1072,406,1066,405,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "388",
				shape: "poly",
				coords: [1114,405,1107,407,1102,410,1099,415,1097,421,1098,483,1098,491,1101,495,1106,498,1114,500,1200,500,1211,498,1214,494,1217,488,1219,482,1219,425,1216,414,1213,409,1207,406,1203,405,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "389",
				shape: "poly",
				coords: [1254,404,1244,407,1239,411,1236,417,1235,424,1235,483,1237,492,1240,495,1245,499,1253,501,1333,500,1346,499,1351,495,1355,489,1356,480,1356,424,1354,414,1350,409,1345,406,1338,405,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "390",
				shape: "poly",
				coords: [1394,405,1382,406,1376,410,1373,415,1371,424,1371,482,1373,491,1376,495,1384,500,1391,500,1472,501,1483,499,1488,493,1491,489,1492,482,1492,427,1491,416,1487,410,1482,407,1476,405,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "391",
				shape: "poly",
				coords: [1553,405,1540,406,1536,409,1533,413,1531,423,1531,485,1534,493,1538,497,1544,499,1551,501,1663,500,1674,499,1678,496,1682,490,1683,483,1683,423,1682,413,1677,408,1671,406,1664,405,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "384",
				shape: "poly",
				coords: [841,580,833,582,829,586,826,590,825,597,825,656,826,666,829,671,835,675,841,676,929,676,937,674,943,671,945,666,946,660,945,598,943,588,938,583,930,581,926,581,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "385",
				shape: "poly",
				coords: [982,580,969,582,964,586,961,592,960,598,960,659,962,668,965,672,978,677,1062,677,1073,674,1078,669,1081,663,1081,657,1081,602,1080,592,1076,586,1071,583,1065,581,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "388",
				shape: "poly",
				coords: [1113,580,1104,584,1100,587,1097,592,1095,598,1096,653,1097,664,1100,670,1104,673,1112,676,1200,676,1209,674,1213,670,1216,666,1217,659,1217,600,1216,591,1212,585,1207,582,1200,581,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "389",
				shape: "poly",
				coords: [1253,579,1244,582,1239,585,1236,590,1234,597,1234,658,1236,667,1239,672,1244,676,1255,677,1337,676,1347,673,1351,670,1353,664,1355,658,1354,601,1353,590,1349,585,1343,581,1337,581,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "390",
				shape: "poly",
				coords: [1390,580,1379,583,1375,587,1372,591,1370,598,1370,658,1372,668,1375,672,1381,675,1389,677,1478,676,1484,673,1488,671,1490,664,1491,658,1490,598,1488,591,1485,585,1479,582,1473,581,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "n") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo);
			dispatch(viewRoadmapRoleByRoleId(requestInfo));

			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
