import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
	departmentViewRoadmap,
	viewRoadmapRoleByRoleId,
} from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";
// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";
import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Industry_Software_Engineering from "assets/img/Career_Roadmap/Software_Engineering/Industry_Software_Engineering_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function IndustrySoftwareEngineering() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Industry_Software_Engineering;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "se",
				shape: "poly",
				coords: [83,351,72,355,67,358,66,362,63,368,63,426,65,430,69,435,74,438,82,440,174,441,181,439,186,436,189,432,192,426,192,371,190,362,185,357,180,353,172,351,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "314",
				shape: "poly",
				coords: [225,355,216,358,212,361,211,366,210,371,210,420,212,425,213,429,219,432,224,434,356,434,363,431,366,429,370,425,372,420,371,371,370,363,366,359,360,357,354,356,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "315",
				shape: "poly",
				coords: [595,356,587,359,584,362,581,366,580,372,580,417,582,425,585,430,590,432,595,434,728,433,735,430,738,427,741,423,742,418,742,372,740,363,735,359,731,358,726,356,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "316",
				shape: "poly",
				coords: [956,356,946,358,943,362,940,366,939,374,940,419,942,426,944,430,949,432,956,434,1085,432,1093,431,1098,428,1101,424,1102,418,1102,375,1100,364,1097,359,1091,356,1086,355,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "317",
				shape: "poly",
				coords: [1317,358,1306,360,1302,364,1300,369,1299,376,1299,420,1300,426,1304,431,1311,435,1315,436,1446,436,1454,433,1457,430,1460,426,1461,420,1461,371,1458,363,1454,360,1450,358,1445,357,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "318",
				shape: "poly",
				coords: [1706,357,1695,358,1691,362,1688,368,1686,376,1688,421,1691,428,1694,432,1700,435,1707,436,1836,435,1847,434,1852,430,1854,427,1858,421,1857,373,1853,364,1851,361,1845,358,1839,357,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "se") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo);
			dispatch(viewRoadmapRoleByRoleId(requestInfo));

			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
