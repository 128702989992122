import React, { useEffect, useState } from 'react';

import PostImageCrop from '../Component/ImageCrop/PostImageCrop';

import { Modal, Box, makeStyles } from '@material-ui/core';

import clsx from 'clsx';

import cross from 'assets/img/cross.png';
import previewPostPhotosModalStyles from 'assets/jss/material-kit-pro-react/components/previewPostPhotosModalStyles';
import profilePageStyle from 'assets/jss/material-kit-pro-react/views/pageStyle/LandingPageFeed/CommentStyle.js';
import Button from 'components/CustomButtons/Button.js';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconLibrary from 'views/Component/IconLibrary.js';
import Compressor from 'compressorjs';
import reactImageSize from 'react-image-size';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import loadingGif from 'assets/img/loading.gif';
import CustomDialog from '../../components/CustomDialog/CustomDialog.js';

const useStyles = makeStyles(previewPostPhotosModalStyles);

const PreviewPostPhotosModal = props => {
  const classes = useStyles();
  const classesDialog = makeStyles(profilePageStyle)();

  const {
    uploadedPhotos,
    isOpen,
    previewPhotoIndex,
    setPreviewPhoto,
    removeAnUploadedPhoto,
    error,
    onPhotosListItemDragStart,
    onPhotosListItemDrop,
    isEdit,
    setIsLoading,
    isLoading,
    setError,
  } = props;

  const [editOrPreview, setEditOrPreview] = useState('preview');
  const [editPhotoIndex, setEditPhotoIndex] = useState(-1);
  const [dimension, setDimension] = React.useState(null);
  const [imageSrc, setImageSrc] = React.useState(null);
  const [addPhoto, setAddPhoto] = React.useState(false);

  useEffect(() => {
    if (Array.isArray(uploadedPhotos) && addPhoto && !isLoading) {
      setPreviewPhoto(uploadedPhotos.length - 1);
      setAddPhoto(false);
    }
    if (!(Array.isArray(uploadedPhotos) && uploadedPhotos[previewPhotoIndex])) {
      setPreviewPhoto(0);
    }
    if (Array.isArray(uploadedPhotos) && uploadedPhotos.length == 0 && isOpen) {
      props.onClose();
    }
  }, [uploadedPhotos]);

  const checkPhoto = async photos => {
    if (photos.length > 0) props.setIsLoading(true);
    console.log(photos);
    var newPhotos = [];
    for (var i = 0; i < photos.length; i++) {
      var newPhtoto = await compressPhoto(photos[i]);

      console.log(newPhtoto);
      if (newPhtoto) newPhotos.push(newPhtoto);
      else props.setError('Invalid Image Format Found');
    }
    props.selectPhotos([...props.uploadedPhotos, ...newPhotos]);
    props.setIsLoading(false);
  };

  const compressPhoto = pictureSource => {
    return new Promise(function (resolve, reject) {
      const processPhoto = async pictureSource => {
        if (pictureSource.type.includes('image')) {
          let imageDataUrl = typeof pictureSource === 'string' ? pictureSource : await readFile(pictureSource);
          const { width, height } = await reactImageSize(imageDataUrl);
          var dimensions = { width: width, height: height };
          if (dimensions) {
            new Compressor(pictureSource, {
              quality: 0.8,
              maxWidth: 1600,
              maxHeight: 1600,
              checkOrientation: false,
              success: compressedResult => {
                console.log(pictureSource);
                if (pictureSource.size > 10000000 || dimensions.width > 1600 || dimensions.height > 1600) {
                  // newPhoto.push(compressedResult)
                  resolve(compressedResult);
                  return;
                } else {
                  // newPhoto.push(pictureSource)
                  resolve(pictureSource);
                  return;
                }
                // if (i == photos.length) {
                // 	console.log(newPhoto);
                // 	if (newPhoto.length) {
                // 		// setUploadedPhotos(newPhoto);
                // 		props.selectPhotos([...props.uploadedPhotos, ...newPhoto]);
                // 		props.setIsLoading(false);
                // 		// setPreviewPhoto(0);
                // 		// setIsCreatePostModalOpen(false);
                // 		// setIsPreviewPostPhotosOpen(true);
                // 	}
                // }
              },
            });
          } else {
            // props.setIsLoading(false);
            resolve(null);
            return;
          }
        } else {
          // props.setIsLoading(false);
          resolve(null);
          return;
        }
      };
      processPhoto(pictureSource);
    });
  };

  const loadImage = async e => {
    if (uploadedPhotos[previewPhotoIndex]) {
      let pictureSource = uploadedPhotos[previewPhotoIndex];
      let imageDataUrl = typeof pictureSource === 'string' ? pictureSource : await readFile(pictureSource);

      // let imageDataUrl = await readFile(pictureSource);
      // if (!isEdit) fileName(pictureSource.name);
      try {
        const { width, height } = await reactImageSize(imageDataUrl);
        var dimensions = { width: width, height: height };
        if (dimensions) {
          if (pictureSource.size > 10000000 || dimensions.width > 1600 || dimensions.height > 1600) {
            new Compressor(pictureSource, {
              quality: 0.8,
              maxWidth: 1600,
              maxHeight: 1600,
              checkOrientation: false,
              success: compressedResult => {
                console.log(compressedResult);
                const readImage = async e => {
                  let compressImage = isEdit ? pictureSource : await readFile(compressedResult);
                  // console.log(compressImage);
                  // img = Buffer.from(
                  // 	compressImage.split(";base64,").pop(),
                  // 	"base64"
                  // );
                  // dimensions = sizeOf(img);
                  const { width, height } = await reactImageSize(imageDataUrl);
                  var dimensions = { width: width, height: height };
                  setImageSrc(compressImage);
                  setDimension(dimensions);
                  // if (!isEdit) fileName(compressedResult.name);
                };
                readImage();
              },
            });
          } else {
            setImageSrc(imageDataUrl);
            setDimension(dimensions);
          }
        }
      } catch (e) {}
      setEditPhotoIndex(previewPhotoIndex);
      setEditOrPreview('edit');
    }
  };

  const readFile = file => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  const dialogDefault = {
    title: '',
    description: '',
    buttonYes: '',
    buttonNo: '',
  };
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    dialogDefault,
  });

  const confirmProceed = (title, description, buttonYes, buttonNo) => {
    const dialogDefault = {
      title: title,
      description: description,
      buttonYes: buttonYes,
      buttonNo: buttonNo,
    };
    setConfirmDialog({
      isOpen: true,
      dialogDefault,
      onConfirm: () => {
        setConfirmDialog({
          isOpen: false,
          dialogDefault,
        });
      },
      onCancel: () => {
        props.selectPhotos([]);
        props.onClose();
        setConfirmDialog({
          isOpen: false,
          dialogDefault,
        });
      },
    });
  };

  return (
    <React.Fragment>
      {confirmDialog && <CustomDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />}
      <Dialog
        classes={{
          root: classesDialog.modalRoot,
          paper: classesDialog.modalCommentEdit + ' ' + classesDialog.modal + ' ' + classes.dialogStyle,
        }}
        open={isOpen}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classesDialog.modalTitleEdit + ' ' + classes.modalHeader}
        >
          <Button
            simple
            className={classesDialog.modalCloseButton}
            key="close"
            aria-label="Close"
            onClick={() => {
              if (editOrPreview == 'preview') {
                if (props.uploadedPhotos && props.uploadedPhotos.length > 0) {
                  confirmProceed(
                    'Do you want..',
                    'Do you want to close this photo preview? Changes you have made will not be saved.',
                    'Stay',
                    'Leave'
                  );
                } else {
                  props.selectPhotos([]);
                  props.onClose();
                }
              } else setEditOrPreview('preview');
            }}
          >
            <IconLibrary.Close />
          </Button>
          <h6 className={classesDialog.title}>{editOrPreview == 'preview' ? 'Uploaded Photos' : 'Edit Photo'}</h6>
        </DialogTitle>
        <DialogContent id="classic-modal-slide-description" className={classesDialog.modalBody}>
          {editOrPreview == 'preview' ? (
            <div>
              <div className={classes.photoPreview}>
                {Boolean(uploadedPhotos && uploadedPhotos.length && uploadedPhotos[previewPhotoIndex]) ? (
                  <>
                    {/* <div className={classes.previewPhotoContainer}> */}
                    <button
                      className={classes.editButton}
                      onClick={() => {
                        loadImage();
                        // setEditPhotoIndex(previewPhotoIndex);
                        // setEditOrPreview("edit");
                      }}
                    >
                      Edit Photo
                    </button>
                    <img
                      src={
                        typeof uploadedPhotos[previewPhotoIndex] === 'string'
                          ? uploadedPhotos[previewPhotoIndex]
                          : URL.createObjectURL(uploadedPhotos[previewPhotoIndex])
                      }
                      alt=""
                    />
                    {/* </div> */}
                  </>
                ) : (
                  ''
                )}
              </div>

              <div className={classes.photosList}>
                <div className={classes.photosListContainer}>
                  {uploadedPhotos
                    ? uploadedPhotos.map((image, index) => (
                        <div className={classes.photosListItem} key={'photosListItem' + index}>
                          <img
                            src={cross}
                            alt=""
                            className={classes.removePhoto}
                            onClick={e => {
                              e.stopPropagation();
                              removeAnUploadedPhoto(index);
                            }}
                            key={'removePhoto' + index}
                          />
                          <div
                            className={classes.photosListItemPhotoContainerOverlay}
                            onClick={() => setPreviewPhoto(index)}
                            draggable={true}
                            onDragOver={e => e.preventDefault()}
                            onDragStart={e => {
                              e.target.style.opacity = 0.4;
                              onPhotosListItemDragStart(index);
                            }}
                            onDragEnd={e => {
                              e.target.style.opacity = 1;
                            }}
                            onDrop={() => {
                              onPhotosListItemDrop(index);
                            }}
                          ></div>

                          <div
                            className={clsx({
                              [classes.photosListItemPhotoContainer]: true,
                              [classes.selectedPhoto]: index === previewPhotoIndex,
                            })}
                            key={'photosListItemPhotoContainer' + index}
                          >
                            <img
                              src={typeof image === 'string' ? image : URL.createObjectURL(image)}
                              alt=""
                              className={classes.photosListItemPhoto}
                              onClick={() => setPreviewPhoto(index)}
                              key={'photosListItemPhoto' + index}
                            />
                          </div>
                        </div>
                      ))
                    : ''}
                  {uploadedPhotos && uploadedPhotos.length < 9 ? (
                    <div className={classes.photosListItem} key={'photosListItemAddMore'}>
                      <label htmlFor="upload-photo">
                        <div
                          className={classes.photosListItemPhotoContainer}
                          style={{ textAlign: 'center', cursor: 'pointer' }}
                        >
                          <AddOutlinedIcon
                            sx={{
                              color: '#92929D',
                              height: '100%',
                              width: '35px',
                              // margin: "5px",
                              transition: 'all .5s',
                              '&:hover': {
                                transform: 'scale(1.5)',
                              },
                            }}
                            alt=""
                          />
                        </div>
                        <input
                          type="file"
                          id="upload-photo"
                          name="photo"
                          multiple
                          accept="image/*"
                          style={{ display: 'none' }}
                          // onClick={() => {
                          // 	console.log("reset error");
                          // 	props.setError("");
                          // }}
                          // onChange={(e) => {
                          // 	console.log(e);
                          // 	setAddPhoto(true);
                          // 	checkPhoto(e.target.files);
                          // }}
                        />
                      </label>
                    </div>
                  ) : null}
                  <button
                    className={classes.doneButton}
                    onClick={() => (editOrPreview == 'preview' ? props.onClose() : setEditOrPreview('preview'))}
                  >
                    Done
                  </button>
                  {/* <img
										src={
											typeof uploadedPhotos[previewPhotoIndex] === "string"
												? uploadedPhotos[previewPhotoIndex]
												: URL.createObjsectURL(
														uploadedPhotos[previewPhotoIndex]
												  )
										}
										alt=""
									/> */}
                </div>
              </div>
              {error ? <div className={classes.error}>{error}</div> : ''}
            </div>
          ) : (
            <PostImageCrop
              pictureSource={imageSrc}
              backgroundTemplateFlag={0}
              saveCroppedPhoto={photo => {
                uploadedPhotos[previewPhotoIndex] = photo;
                setEditOrPreview('preview');
              }}
              dimension={dimension}
            />
          )}
        </DialogContent>
        <div className={classesDialog.modalFooter}></div>
      </Dialog>
    </React.Fragment>
  );
};

export default PreviewPostPhotosModal;
