import React from "react";

import { connect, useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js";
import defaultAvatar from "assets/img/candidate_avatar.jpeg";

import CoverPictureEditModal from "./CoverPictureEditModal.js";

import Parallax from "../../../../components/Parallax/Parallax.js";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "components/CustomButtons/Button.js";
import CreateIcon from "@material-ui/icons/Create";
import default_cover from "assets/img/default_cover.png";

const useStyles = makeStyles(profilePageStyle);

const CoverPictureSection = ({ isViewed, isLoading, pictureSource, user }) => {
	const classes = useStyles();
	const [isOpen, setOpen] = React.useState(false);
	const closeModal = () => {
		setOpen(false);
	};
	const privacySetting = useSelector((state) => state.user.privacySetting);

	const showProfilePic =
		privacySetting &&
		privacySetting
			.filter((item) => {
				return item.settingName === "showProfilePic";
			})
			.map((item) => {
				return item.settingValue === 1;
			})[0];

	const showProfile =
		privacySetting &&
		privacySetting
			.filter((item) => {
				return item.settingName === "showProfile";
			})
			.map((item) => {
				return item.settingValue === 1;
			})[0];

	const imageClasses = classNames(
		classes.imgRaised,
		classes.imgRoundedCircle,
		classes.imgProfile
	);

	return (
		<React.Fragment>
			{isViewed ? (
				user.backgroundPic ? (
					<React.Fragment>
						<Parallax
							image={!isLoading ? user.backgroundPic : null}
							filter="dark"
							className={classes.parallax}
						/>
					</React.Fragment>
				) : (
					<Parallax
						image={!isLoading ? default_cover : null}
						filter="dark"
						className={classes.parallax}
					/>
				)
			) : user.backgroundPic ? (
				<React.Fragment>
					<Parallax
						image={!isLoading ? user.backgroundPic : null}
						filter="dark"
						className={classes.parallax}
					/>
				</React.Fragment>
			) : (
				<Parallax
					image={!isLoading ? default_cover : null}
					filter="dark"
					className={classes.parallax}
				/>
			)}
		</React.Fragment>
	);
};
export default CoverPictureSection;
