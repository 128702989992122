import React from 'react';
import { makeStyles, Typography } from "@material-ui/core";
import groupPageStyles from "assets/jss/material-kit-pro-react/views/pageStyle/GroupsPage";

const useStyles = makeStyles(groupPageStyles);

const GroupByIndustryCard = ({ group }) => {
    const classes = useStyles();

    return(<div className={classes.browseIndustryCards}>
      <div className={classes.industryCardImage}>
        <div
          className={classes.industryBadge}
          style={{ background: group.bgcolor }}
        >
          <group.icon sx={{ color: group.color }} />
          <Typography className={classes.industryBadgeText}>
            {group.text}
          </Typography>
        </div>
      </div>
    </div>)
  };

  export default GroupByIndustryCard;