const profileViewActivityCardStyles = theme => ({
  body: {
    boxShadow: 'none',
    borderRadius: '15px!important',
    backgroundColor: '#fff',
    padding: '20px 20px 10px',
    margin: '0 0 15px',
    [theme.breakpoints.down('xs')]: {
      padding: '10px 10px 5px',
    },
    // "& h4": {
    // 	fontSize: "18px",
    // 	fontWeight: "600",
    // 	color: "#4A4A4A",
    // 	margin: "0",
    // },

    '& .MuiCardContent-root': {
      paddingBottom: '0',
    },
  },

  groupBody: {
    border: '1px solid rgb(0,0,0,0.2)',
  },

  sharedBody: {
    paddingBottom: 0,
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 0 20px',
    [theme.breakpoints.down('xs')]: {
      margin: '0 0 10px',
    },

    '& > button': {
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
    },
  },

  headerLeft: {
    display: 'flex',
    alignItems: 'center',
  },

  clapIcon: {
    '&:hover': {
      transform: 'scale(1.5)',
    },
  },

  profilePic: {
    overflow: 'hidden',
    borderRadius: '50%',
    marginRight: '15px',
    width: '53px',
    maxHeight: '53px',

    '& > img': {
      width: '100%',
    },
  },

  name: {
    color: '#171725',
    fontSize: '14px',
    fontWeight: 700,
  },

  time: {
    color: '#92929D',
    fontSize: '12px',
    fontWeight: 500,
  },

  feedTitle: {
    overflowWrap: 'anywhere',
    color: '#44444F',
    fontSize: '14px',
    margin: '0 0 10px',
    // "& > p > a": {
    // 	fontWeight: "600",
    // },
    '& > p ': {
      margin: '0 0 5px',
      '& > .mention': {
        fontWeight: '600',
        color: '#7474a8',
        '& > span > a': {
          color: '#7474a8!important',
          textDecoration: 'none',
        },
      },
      '& > br': {
        display: 'none',
      },
    },
  },

  // commentText: {
  // 	margin: "5px 0 5px",
  // 	color: "#4A4A4A",
  // 	overflowWrap: "break-word",
  // 	whiteSpace: "pre-wrap",
  // 	// "& > p > a": {
  // 	// 	fontWeight: "600",
  // 	// },
  // 	"& > div > p ": {
  // 		margin: "0 0 5px",
  // 		"& > .mention": {
  // 			fontWeight: "600",
  // 			color: "#7474a8",
  // 			"& > span > a": {
  // 				color: "#7474a8!important",
  // 				textDecoration: "none",
  // 			},
  // 		},
  // 		"& > br": {
  // 			display: "none",
  // 		},
  // 	},
  // },

  feedActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTop: '1px solid #F1F1F5',
    margin: '15px 0 0',
    padding: '10px 0',
    [theme.breakpoints.down('xs')]: {
      margin: '7px 0 0',
      padding: '0',
    },

    '& > button': {
      color: '#44444F',
      fontSize: '12px',
      fontWeight: 400,
    },

    '& .MuiButton-label': {
      textTransform: 'capitalize',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
      '& img': {
        marginRight: '5px',
        width: '22px',
      },

      '& svg': {
        marginRight: '5px',
        width: '22px',
        [theme.breakpoints.down('xs')]: {
          marginTop: '3px',
        },
      },
    },
  },

  feedCommentBx: {
    display: 'block',
    alignItems: 'center',
    borderTop: '1px solid #F1F1F5',
    padding: '20px 0',

    '& .MuiTypography-body1, .MuiInputBase-root': {
      width: '100%',
    },
    '& input': {
      backgroundColor: '#FAFAFB',
      border: '1px solid #F1F1F5',
      borderRadius: '15px',
      padding: '15px 156px 15px 15px',
    },
    '& .MuiInput-underline:before, .Mui-focused:after': {
      display: 'none',
    },
  },

  feedCommentFlex: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  feedCommentUserBx: {
    // flex: "0 0 45px",
    // maxWidth: "45px",
    // maxHeight: "45px",
    // borderRadius: "50%",
    // overflow: "hidden",
    // margin: "0 15px 0 0",
    // float: "right",
    marginRight: '10px',

    '& > img': {
      // width: "100%",
      width: '40px',
      borderRadius: '50%',
      [theme.breakpoints.down('xs')]: {
        width: '25px',
      },
    },
  },

  commentInputWrap: {
    position: 'relative',
    width: '100%',
    minWidth: '0',
  },

  postCommentsMain: {
    marginTop: '10px',
  },

  recentCommentsButton: {
    marginBottom: '30px',

    '& > button': {
      border: 'none',
      backgroundColor: 'transparent',
      color: '#44444F',
      fontSize: '14px',
      fontWeight: 600,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      cursor: '',
    },
  },

  commentActionBtns: {
    position: 'absolute',
    top: '3px',
    right: 0,
    width: '90px!important',
    textAlign: 'right',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      // width: "55px!important",
      // float:"right",
      position: 'unset',
      marginTop: '5px',
    },
    '& > button': {
      width: '45px',
      height: '45px',
      [theme.breakpoints.down('xs')]: {
        width: '25px',
        height: '25px',
        padding: '3px',
      },
    },
  },

  moreComments: {
    marginTop: '10px',
    color: '#4A4A4A',
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 600,

    '& > button': {
      border: 'none',
      backgroundColor: 'transparent',
      padding: '5px 15px',
      cursor: 'pointer',
    },
  },
  popoverBox: {
    padding: '10px',
    [theme.breakpoints.down('xs')]: {
      padding: '5px',
    },
  },
  popoverListItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 10px 5px 5px',
    minWidth: '250px',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: '#e3e3e3',
    },
  },
  popoverListItemIcon: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: '15px',
    width: '24px',
    textAlign: 'center',
  },
  popoverListItemText: {},
  popoverListItemTitle: {
    color: '#44444F',
    fontSize: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: '0',
  },
  popoverListItemDescription: {
    color: '#92929D',
    fontSize: '12px',
  },
  likePopper: {
    zIndex: '999',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    borderRadius: '28px',
    // top: "-7px!important",
    // left: "22px!important",
    top: '2px!important',
    // left: "30px!important",
    background: 'white',
    '& > div': {
      boxShadow: 'none',
      borderRadius: '18px',
    },
  },
  emojiPopper: {
    left: '-66px!important',
    zIndex: '999',
  },
  sendButton: {
    stroke: '#92929D',
  },
  profileTagList: {
    width: '40px',
    borderRadius: '50%',
  },
  followButton: {
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    padding: '8px 15px',
    fontSize: '13px',
    fontWeight: '700',
    borderRadius: '7px',
    backgroundColor: '#6F52ED',
    marginTop: '6px',
    marginBottom: '14px',
  },
  hashtagName: {
    marginBottom: '0',
    fontSize: '18px',
  },
  hashtagPic: {
    maxHeight: '120px',
    width: '120px',
  },
});

export default profileViewActivityCardStyles;
