import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { departmentViewRoadmap,viewRoadmapRoleByRoleId } from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";
import ImageMapper from "react-image-mapper";
import Department_Marketing from "assets/img/Career_Roadmap/Marketing_Communication/Department_Marketing_1.jpg";
import RoleInfoModal from "../RoleInfoModal.js";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentMarketing() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Marketing;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "m",
				shape: "poly",
				coords: [
					80,352,165,352,172,354,179,357,184,364,186,366,187,372,187,407,186,412,183,419,179,423,175,426,172,427,166,428,79,429,70,426,64,422,60,418,59,413,58,406,58,374,59,368,62,361,65,357,69,355,71,354,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "17",
				shape: "poly",
				coords: [
					215,340,395,339,406,341,413,348,417,358,417,422,416,432,411,437,401,442,212,443,203,440,197,435,194,428,192,360,196,351,199,345,204,342,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "18",
				shape: "poly",
				coords: [
					531,353,672,352,684,355,689,361,689,369,690,414,686,423,682,427,668,430,529,430,519,428,513,423,510,415,510,372,511,363,514,357,520,353,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "19",
				shape: "poly",
				coords: [
					773,355,960,354,970,357,976,361,978,370,978,412,977,421,974,427,971,429,960,433,775,432,766,431,759,426,756,417,755,372,757,363,762,358,766,355,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "20",
				shape: "poly",
				coords: [
					1086,355,1215,355,1223,359,1228,365,1230,374,1230,415,1228,425,1224,429,1216,432,1087,433,1079,430,1073,425,1071,414,1071,372,1072,364,1076,359,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "21",
				shape: "poly",
				coords: [
					1350,356,1506,355,1518,359,1523,364,1525,372,1525,411,1523,422,1519,429,1505,433,1355,433,1346,432,1339,429,1335,423,1334,372,1337,362,1341,358,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "22",
				shape: "poly",
				coords: [
					1673,354,1843,354,1856,356,1861,362,1863,372,1863,415,1860,424,1855,429,1843,431,1678,432,1663,428,1658,422,1656,413,1656,372,1658,363,1663,357,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "m") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo)
			dispatch(viewRoadmapRoleByRoleId(requestInfo));
			
			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// console.log(coords.x + "," + coords.y + ",");
		// navigator.clipboard.writeText(coords.x + "," + coords.y + ",");
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
