const editEducationStyles = (theme) => ({
	body: {},

	addNewBtnContainer: {
		display: "flex",
		justifyContent: "end",
		textAlign: "right",
	},

	addNewBtn: {
		display: "flex",
		alignItems: "center",
		border: "solid 2px #687BFE",
		borderRadius: "8px",
		backgroundColor: "transparent",
		padding: "0 10px 0 8px",
		height: "32px",
		color: "#687BFE",
		fontSize: "15px",
		fontWeight: 700,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,

		cursor: "pointer",
		transition: "250ms",

		"&:hover": {
			backgroundColor: "#687bfe21",
		},

		"& svg": {
			marginRight: "10px",
			width: "11px",
			height: "11px",
		},
		[theme.breakpoints.down("xs")]: {
			width: "100%",
			height: "35px",
		},
	},

	epmtyStateContent: {
		marginTop: "30px",
		textAlign: "center",

		"& > p": {
			color: "#6D7278",
			fontSize: "14px",
			fontWeight: 500,
			fontFamily: `"Roboto", "Helvetica", sans-serif !important`,

			textAlign: "center",
		},

		"& > img": {
			width: "90%",
			objectFit: "cover",
			objectPosition: "center",
		},
	},
});

export default editEducationStyles;
