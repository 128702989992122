import React, { useState, useEffect } from 'react';
import {
  Box,
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  Popover,
  IconButton,
} from '@material-ui/core';
import profileViewActivityCardStyles from 'assets/jss/material-kit-pro-react/views/componentStyle/profileView/myActivity/profileViewActivityCardStyles.js';
import profilePageStyle from 'assets/jss/material-kit-pro-react/views/pageStyle/LandingPageFeed/CommentStyle.js';
import CreatePollModalStyle from 'assets/jss/material-kit-pro-react/components/CreatePollModalStyle.js';
import customDropdownStyle from 'assets/jss/material-kit-pro-react/components/customDropdownStyle.js';
import Button from 'components/CustomButtons/Button.js';
import profilePicture from 'assets/images/PP1.svg';
import Separator from 'assets/images/Separator.svg';
import closeIcon from 'assets/images/closeIcon.svg';
import imageUpload from 'assets/images/imageupload.svg';
import video from 'assets/images/video.svg';
import poll from 'assets/images/poll.svg';
import comment from 'assets/images/comment.svg';
import public_black from 'assets/images/public_black.svg';
import backArrow from 'assets/images/backArrow.svg';
import addOption from 'assets/images/addOption.svg';
import trash from 'assets/images/trash.svg';
import Separator2 from 'assets/images/separator2.svg';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconLibrary from 'views/Component/IconLibrary.js';
import Picker from 'emoji-picker-react';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

import { imageListItemBarClasses } from '@mui/material';
import { debounce } from 'lodash';
import CustomDialog from '../../components/CustomDialog/CustomDialog.js';

const useStyles = makeStyles(CreatePollModalStyle);

const CreatePollModal = props => {
  const classes = useStyles();
  const classesDialog = makeStyles(profilePageStyle)();
  const styles = makeStyles(profileViewActivityCardStyles)();
  const {
    pollDurationOptions,
    editQuestion,
    editOptions,
    editPollTiming,
    statusChangeHandler,
    handleCreatePostClick,
  } = props;
  const [pollTiming, setPollTiming] = useState('1 day');
  const [question, setQuestion] = useState('');
  const [optionValueArray, setOptionValueArray] = useState(['', '']);
  const [optionLabelArray, setOptionLabelArray] = useState(['Option 1', 'Option 2']);
  const [pollDurationId, setPollDurationId] = useState(1);
  const [anchorElEmoji, setAnchorElEmoji] = useState(null);
  const [emptyOption, setEmptyOption] = useState(true);

  const debouceRequest = debounce((value, type) => {
    console.log(value, type);
    if (type == 'question') {
      setQuestion(value);
    } else if (type == 'option') {
      let tempArray = optionValueArray.map((item, index) => {
        return index === value.pos ? value.event : item;
      });

      setOptionValueArray(tempArray);
    }
  }, 200);

  useEffect(() => {
    if (pollDurationOptions && pollDurationOptions.length) {
      setPollDurationId(pollDurationOptions[0].durationId);
    }
  }, [pollDurationOptions]);

  useEffect(() => {
    switch (pollDurationId) {
      case 2:
        setPollTiming('3 days');
        break;
      case 3:
        setPollTiming('1 week');
        break;
      case 4:
        setPollTiming('2 weeks');
        break;
      default:
        setPollTiming('1 day');
    }
  }, [pollDurationId]);

  useEffect(() => {
    // if (editQuestion.length > 0) {
    // 	// setOptionLabelArray([]);
    // 	if (editOptions.length == 3) {
    // 		console.log(editOptions.length);
    // 		setOptionLabelArray([...optionLabelArray, `Option 3`]);
    // 	} else if (editOptions.length == 4) {
    // 		setOptionLabelArray([...optionLabelArray, `Option 3`, `Option 4`]);
    // 	}
    // 	setQuestion(editQuestion);
    // 	setPollTiming(editPollTiming);
    // 	setOptionValueArray(editOptions);
    // }
    // console.log(editQuestion, editPollTiming, editOptions);
    // console.log(question, pollTiming, optionValueArray, optionLabelArray);
    // console.log(statusChangeHandler);
    // console.log(handleCreatePostClick);
  }, [statusChangeHandler, handleCreatePostClick]);

  useEffect(() => {
    // console.log(props?.isOpen);
    if (props?.isOpen) {
      console.log(editQuestion, editPollTiming, editOptions);
      if (editQuestion.length > 0) {
        // setOptionLabelArray([]);
        if (editOptions.length == 3) {
          // console.log(editOptions.length);
          setOptionLabelArray([...optionLabelArray, `Option 3`]);
        } else if (editOptions.length == 4) {
          setOptionLabelArray([...optionLabelArray, `Option 3`, `Option 4`]);
        }
        setQuestion(editQuestion);
        setPollTiming(editPollTiming);
        setOptionValueArray(editOptions);
        switch (editPollTiming) {
          case '3 days':
            setPollDurationId(2);
            break;
          case '1 week':
            setPollDurationId(3);
            break;
          case '2 weeks':
            setPollDurationId(4);
            break;
          default:
            setPollDurationId(1);
        }
      } else {
        setQuestion('');
        setOptionValueArray(['', '']);
        setOptionLabelArray(['Option 1', 'Option 2']);
        setPollDurationId(1);
        setPollTiming('1 day');
      }
    }
  }, [props?.isOpen]);

  const addMoreHandler = event => {
    if (optionLabelArray.length < 4) {
      setOptionLabelArray([...optionLabelArray, `Option ${optionLabelArray.length + 1}`]);
      setOptionValueArray([...optionValueArray, '']);
      modalResize();
    }
  };

  useEffect(() => {
    // console.log(optionValueArray);
    setEmptyOption(false);
    for (var i = 0; i < optionValueArray.length; i++) {
      if (optionValueArray[i].length == 0) {
        setEmptyOption(true);
      }
    }
  }, [optionValueArray]);

  const handleOptionArrayValueChange = (event, pos) => {
    var option = { event: event.target.value, pos: pos };
    debouceRequest(option, 'option');
  };
  const deleteHandler = pos => {
    let tempArray = optionValueArray.filter((item, index) => index !== pos);
    setOptionValueArray(tempArray);
    let tempLabelArray = optionLabelArray.map(item => item);
    tempLabelArray.pop();
    setOptionLabelArray(tempLabelArray);
  };
  useEffect(() => {}, []);

  const onEmojiClick = (event, emojiObject) => {
    if (emojiObject.emoji != '🦫') setQuestion(question + emojiObject.emoji);
  };

  const modalResize = () => {
    var timer = setInterval(() => {
      var dialogHeight = document.getElementById('dialogHeight');
      var pollSection = document.getElementById('pollSection');
      if (pollSection) {
        clearInterval(timer);
        var maxSize = window.innerHeight - 74 - 150 - 70;
        var requiredSize = dialogHeight.clientHeight + pollSection.clientHeight + 50;
        // console.log(requiredSize);
        if (maxSize < 0) maxSize = 0;
        if (maxSize > requiredSize) maxSize = requiredSize;
        else pollSection.style.overflowY = 'auto';

        // if (modalResize.clientHeight < maxSize)
        pollSection.style.maxHeight = maxSize + 'px';
        // else modalResize.style.height = "auto";
        // } else {
        // 	setTimeout(() => {
        // 		clearInterval(timer);
        // 	}, 3000);
      }
    }, 300);
    return () => clearInterval(timer);
  };

  React.useEffect(() => {
    if (document.readyState === 'complete') modalResize();
    window.addEventListener('resize', modalResize);
    return function cleanup() {
      window.removeEventListener('resize', modalResize);
    };
  });

  const dialogDefault = {
    title: '',
    description: '',
    buttonYes: '',
    buttonNo: '',
  };
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    dialogDefault,
  });

  const confirmProceed = (title, description, buttonYes, buttonNo) => {
    const dialogDefault = {
      title: title,
      description: description,
      buttonYes: buttonYes,
      buttonNo: buttonNo,
    };
    setConfirmDialog({
      isOpen: true,
      dialogDefault,
      onConfirm: () => {
        setConfirmDialog({
          isOpen: false,
          dialogDefault,
        });
      },
      onCancel: () => {
        setQuestion('');
        setOptionValueArray(['', '']);
        setOptionLabelArray(['Option 1', 'Option 2']);
        setPollDurationId(1);
        setPollTiming('1 day');
        props.statusChangeHandler('poll');
        props.handleCreatePostClick('post');
        props.removePoll();
        setConfirmDialog({
          isOpen: false,
          dialogDefault,
        });
      },
    });
  };

  return (
    <React.Fragment>
      {confirmDialog && <CustomDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />}
      <Dialog
        classes={{
          root: classesDialog.modalRoot,
          paper: classesDialog.modalCommentEdit + ' ' + classesDialog.modal + ' ' + classes.dialogStyle,
        }}
        open={props?.isOpen}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        id="dialogHeight"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classesDialog.modalTitleEdit + ' ' + classes.modalHeader}
        >
          <Button
            simple
            className={classesDialog.modalCloseButton}
            key="close"
            aria-label="Close"
            onClick={() => {
              if ((question && question.length > 0) || !emptyOption) {
                confirmProceed(
                  'Do you want..',
                  'Do you want to close this poll? Changes you have made will not be saved.',
                  'Stay',
                  'Leave'
                );
              } else {
                setQuestion('');
                setOptionValueArray(['', '']);
                setOptionLabelArray(['Option 1', 'Option 2']);
                setPollDurationId(1);
                setPollTiming('1 day');
                props.statusChangeHandler('poll');
                props.handleCreatePostClick('post');
              }
            }}
          >
            <IconLibrary.Close />
          </Button>
          <h6 className={classesDialog.title}>Create a Poll</h6>
        </DialogTitle>
        <DialogContent id="classic-modal-slide-description" className={classesDialog.modalBody}>
          <img src={Separator} className={classes.separator}></img>
          <div className={classes.pollSection} id="pollSection">
            <div className={classes.QuestionSection}>
              <div className={classes.QuestionLabel}>
                <p className={classes.Question}>Your Question :</p>
                <p className={classes.Required}>(Required)</p>
              </div>
              {/* <div style={{ position: "relative" }}>
								<div className={classes.commentActionBtns}>
									<IconButton
										onClick={(e) => {
											setAnchorElEmoji(e.currentTarget);
										}}
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="21"
											height="21"
											viewBox="0 0 21 21"
										>
											<path
												id="Shape"
												d="M3.075,17.924A10.5,10.5,0,0,1,17.924,3.075,10.5,10.5,0,0,1,3.075,17.924ZM1,10.5A9.5,9.5,0,1,0,10.5,1,9.511,9.511,0,0,0,1,10.5Zm5.207,4.419a.5.5,0,1,1,.6-.8,6.327,6.327,0,0,0,3.727,1.127A6.084,6.084,0,0,0,14.2,14.124a.5.5,0,0,1,.7.09.5.5,0,0,1-.09.7,7,7,0,0,1-4.277,1.331A7.236,7.236,0,0,1,6.207,14.919ZM12.5,8.5a1,1,0,1,1,1,1A1,1,0,0,1,12.5,8.5Zm-6,0a1,1,0,1,1,1,1A1,1,0,0,1,6.5,8.5Z"
												fill="#92929d"
											/>
										</svg>
									</IconButton>
								</div>
							</div> */}
              <TextField
                defaultValue={editQuestion}
                fullWidth
                multiline
                // maxRows={3}
                variant="outlined"
                InputProps={{
                  className: classes.QuestionText,
                }}
                inputProps={{ maxLength: 160 }}
                onChange={e => debouceRequest(e.target.value, 'question')}
              />
              <div className={classes.QuestionLimit}>
                <p className={classes.Required}>{question.length}/160 characters</p>
              </div>
            </div>

            <p className={classes.Answer}>Answer Options :</p>
            {optionLabelArray.map((item, index) => {
              return (
                <div className={classes.QuestionSection} key={'QuestionSection ' + index}>
                  <div className={classes.QuestionLabel} key={'QuestionLabel ' + index}>
                    <p className={classes.Question} key={'Question ' + index}>
                      {item}:
                    </p>
                    {index < 2 ? (
                      <p className={classes.Required} key={'Required ' + index}>
                        (Required)
                      </p>
                    ) : (
                      <div
                        className={classes.deleteButton}
                        onClick={() => {
                          deleteHandler(index);
                        }}
                        key={'deleteButton ' + index}
                      >
                        {/* <img
													src={trash}
													className={classes.trashIcon}
													key={"trashIcon " + index}
												></img> */}
                        <DeleteForeverOutlinedIcon
                          key={'trashIcon ' + index}
                          style={{
                            fill: '#E42B4D',
                            width: '24px',
                            height: '24px',
                          }}
                        />
                        <p className={classes.deleteText} key={'deleteText ' + index}>
                          Delete
                        </p>
                      </div>
                    )}
                  </div>
                  <TextField
                    id="outlined-multiline-flexible"
                    // maxRows={3}
                    variant="outlined"
                    fullWidth
                    multiline
                    defaultValue={editOptions[index]}
                    // value={optionValueArray[index]}
                    // InputProps={{
                    // 	className: classes.QuestionText,
                    // }}
                    inputProps={{
                      className: classes.OptionText,
                      maxLength: 160,
                    }}
                    onChange={ev => {
                      handleOptionArrayValueChange(ev, index);
                    }}
                    key={'QuestionText ' + index}
                  />
                  <div className={classes.QuestionLimit}>
                    <p className={classes.Required}>
                      {optionValueArray[index]
                        ? `${optionValueArray[index].length}/160 characters`
                        : `0/160 characters`}
                    </p>
                  </div>
                </div>
              );
            })}
            {optionLabelArray.length < 4 ? (
              <div
                className={classes.addOption}
                onClick={() => {
                  addMoreHandler();
                }}
              >
                {/* <img src={addOption}></img> */}
                <label>
                  <AddCircleOutlineOutlinedIcon
                    style={{
                      fill: '#6f52ed',
                      width: '24px',
                      height: '24px',
                    }}
                  />
                  <p className={classes.addOptionText}>Add Option</p>
                </label>
              </div>
            ) : null}
          </div>
          <img src={Separator2} className={classes.separator2}></img>
          <div className={classes.pollFooter}>
            <p>Poll Duration</p>
            <div>
              {pollDurationOptions && pollDurationOptions.length ? (
                <Select
                  value={pollDurationId}
                  onChange={e => setPollDurationId(e.target.value)}
                  displayEmpty
                  variant="outlined"
                  className={classes.Select}
                  inputProps={{ 'aria-label': 'Without label' }}
                  MenuProps={{
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                    transformOrigin: { vertical: 'top', horizontal: 'left' },
                    getContentAnchorEl: null,
                  }}
                >
                  {pollDurationOptions.map((option, index) => (
                    <MenuItem value={option.durationId} style={{ textTransform: 'capitalize' }} key={index}>
                      {option.durationName}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                <></>
              )}

              {/* <Popover
								id={anchorElEmoji ? "emoji-popover" : undefined}
								open={anchorElEmoji ? true : false}
								anchorEl={anchorElEmoji}
								onClose={() => {
									setAnchorElEmoji(null);
								}}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "center",
								}}
								transformOrigin={{
									vertical: "top",
									horizontal: "center",
								}}
							>
								<div>
									<Picker
										onEmojiClick={onEmojiClick}
										groupVisibility={{
											flags: false,
											recently_used: false,
										}}
									/>
								</div>
							</Popover> */}
              <Button
                className={classes.savePostBtn}
                disabled={question.length == 0 || emptyOption ? true : false}
                onClick={ev => {
                  props.savePollData(question, optionValueArray, pollTiming);
                  props.statusChangeHandler('poll');
                  props.handleCreatePostClick('post');
                  setQuestion('');
                  setOptionValueArray(['', '']);
                  setOptionLabelArray(['Option 1', 'Option 2']);
                  setPollDurationId(1);
                  setPollTiming('1 day');
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </DialogContent>
        <div className={classesDialog.modalFooter}></div>
      </Dialog>
    </React.Fragment>
  );
};

export default React.memo(CreatePollModal);
