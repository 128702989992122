const editWorkExperienceStyles = (theme) => ({
	body: {
		"& form": {
			margin: 0,
		},
	},

	addNewBtnContainer: {
		display: "flex",
		justifyContent: "end",
		textAlign: "right",
	},

	addNewBtn: {
		display: "flex",
		alignItems: "center",
		border: "solid 2px #687BFE",
		borderRadius: "8px",
		backgroundColor: "transparent",
		padding: "0 10px 0 8px",
		height: "32px",
		color: "#687BFE",
		fontSize: "15px",
		fontWeight: 700,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		cursor: "pointer",
		transition: "250ms",

		"&:hover": {
			backgroundColor: "#687bfe21",
		},

		"& svg": {
			marginRight: "10px",
			width: "11px",
			height: "11px",
		},
		[theme.breakpoints.down("xs")]: {
			width: "100%",
			height: "35px",
		},
	},

	epmtyStateContent: {
		marginTop: "30px",
		textAlign: "center",

		"& > p": {
			color: "#6D7278",
			fontSize: "14px",
			fontWeight: 500,
			fontFamily: `"Roboto", "Helvetica", sans-serif !important`,

			textAlign: "center",
		},

		"& > img": {
			width: "90%",
			objectFit: "cover",
			objectPosition: "center",
		},
	},
	dialogActions: {
		position: "absolute",
		left: 0,
		right: 0,
		bottom: "20px",
		display: "flex",
		justifyContent: "space-evenly",

		"& > button": {
			borderRadius: "24px",
			width: "160px",
			height: "48px",
			fontSize: "16px",
			fontWeight: 500,
			fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		},

		"& > :nth-child(1)": {
			color: "#687BFE",
		},

		"& > :nth-child(2)": {
			backgroundColor: "#687BFE",
			color: "#fff",
		},
	},
	dialogText: {
		color: "#6D7278",
		fontSize: "18px",
		fontWeight: 400,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,

		textAlign: "center",
	},
	dialogTitle: {
		margin: 0,
		color: "#000000",
		fontSize: "20px",
		fontWeight: 700,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,

		textAlign: "center",
	},
	dialog: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		borderRadius: "15px",
		backgroundColor: "#fff",
		padding: "70px 0 15px",
		width: "450px",
		height: "260px",
	},
});

export default editWorkExperienceStyles;
