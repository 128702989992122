/* eslint-disable react/prop-types */
import React, { Fragment, useState, useEffect, useRef, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";

import Comment from "../../../Component/SocialFeed/Comment.js";
import ReportModal from "../../ReportModal.js";
import defaultAvatar from "assets/img/candidate_avatar.jpeg";
import comment from "assets/images/comment.png";
import clsx from "clsx";

import axios from "axios";

import {
	Typography,
	makeStyles,
	Button,
	Input,
	IconButton,
	Popover,
	Popper,
	Grow,
	ClickAwayListener,
	Paper,
	Modal,
	Box,
	Grid,
	CardHeader,
	Avatar,
	Card,
	CardContent,
	Divider,
} from "@material-ui/core";

import { ReactComponent as HandClapIcon } from "assets/svg/clapping.svg";
import { ReactComponent as SendIcon } from "assets/svg/send-svgrepo-com.svg";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import MoodBadOutlinedIcon from "@mui/icons-material/MoodBadOutlined";
import SentimentVerySatisfiedOutlinedIcon from "@mui/icons-material/SentimentVerySatisfiedOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import cross from "assets/img/cross.png";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import CloseIcon from "@mui/icons-material/Close";
import clapIcon from "assets/img/icons/clap.png";
import happyIcon from "assets/img/icons/happy.png";
import loveIcon from "assets/img/icons/love.png";
import likeIcon from "assets/img/icons/like.png";
import sadIcon from "assets/img/icons/sad.png";
import angryIcon from "assets/img/icons/angry.png";

import {
	header,
	handleFollow,
	handleUnfollow,
	createHidePost,
} from "configurations/configurations.js";

import dotBtn from "assets/images/dotBtn.png";
import leftArrow from "assets/img/arrow-left.png";
import rightArrow from "assets/img/arrow-right.png";

import like from "assets/images/like.png";
import commentIcon from "assets/images/comment.png";
import share from "assets/images/share.png";
import saved from "assets/images/saved.png";
import commentStyle from "assets/jss/material-kit-pro-react/views/componentStyle/socialFeed/commentStyles";
import profileViewActivityCardStyles from "assets/jss/material-kit-pro-react/views/componentStyle/profileView/myActivity/profileViewActivityCardStyles.js";
import profileViewActivityPollCardStyles from "../../../../assets/jss/material-kit-pro-react/views/componentStyle/profileView/myActivity/profileViewActivityPollCardStyles.js";
import profileViewActivityPostCardStyles from "../../../../assets/jss/material-kit-pro-react/views/componentStyle/profileView/myActivity/profileViewActivityPostCardStyles.js";
import { getProfiles } from "configurations/redux/userSlice.js";
import {
	getSocialCommentByPostId,
	createSocialComment,
	createSocialReaction,
	deleteSocialReaction,
	createSocialHashtag,
	getSocialHashtag,
	getSocialProfileTag,
	resetCreatedHashtag,
	resetPostComment,
	createPollResult,
	deletePollResult,
	getPostReactionCount,
} from "configurations/redux/socialFeedSlice.js";
import { createReport } from "configurations/redux/reportSlice.js";
import CommentSkeleton from "../../SocialFeed/CommentSkeleton.js";
import GridItem from "../../../../components/Grid/GridItem.js";
import GridContainer from "../../../../components/Grid/GridContainer.js";
import Picker from "emoji-picker-react";
import { isMobile } from "react-device-detect";
import CommentImageModal from "../../SocialFeed/CommentImageModal.js";
import {
	instanceUrl,
	encryptData,
	dataURLtoFile,
	TimeFormatUniqueId,
	TimeFormatSocialFeed,
	TimeFormatPollLeft,
	TimeFormatPollConvert,
	getSocialReaction,
} from "configurations/configurations.js";
import "quill-mention";
import "react-quill/dist/quill.bubble.css";
import ReactQuill, { Quill } from "react-quill";
import Countdown from "react-countdown";
import PollResultUserListModal from "./PollResultUserListModal.js";
import CommentInput from "../../SocialFeed/CommentInput.js";
import ReactionUserListModal from "../../SocialFeed/ReactionUserListModal";
import PostMediaModal from "./PostMediaModal.js";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSnackbar } from "notistack";
import PostImageList from "./PostImageList.js";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

const ProfileViewPopupModal = ({
	post,
	reportTypesList,
	reportReasonsList,
	updateFeedHandler,
	isCopyPost,
	isGroupMember,
}) => {
	const classes = makeStyles(commentStyle)();
	const styles = makeStyles(profileViewActivityCardStyles)();
	const stylesPoll = makeStyles(profileViewActivityPollCardStyles)();
	const stylesPost = makeStyles(profileViewActivityPostCardStyles)();
	const user = useSelector((state) => state.auth.user);
	const dispatch = useDispatch();

	const quillRef = React.useRef(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const [anchorElEmoji, setAnchorElEmoji] = useState(null);
	const anchorRef = React.useRef(null);
	const [showReactionPaper, setShowReactionPaper] = useState(false);
	const [anchorShareEl, setAnchorShareEl] = useState(null);
	const [isPostSaved, setIsPostSaved] = useState(false);
	const [saveId, setSaveId] = useState(null);
	const [commentsExpanded, setCommentState] = useState(false);
	const [reportModal, setReportModal] = useState(false);
	const [showComment, setShowComment] = useState(true);
	const searchResult = useSelector((state) => state.socialFeed.searchResult);

	const postResult = useSelector((state) => state.socialFeed.postResult);
	const userStatus = useSelector((state) => state.auth.user);
	const postUser = useSelector((state) => state.user);
	const isOnAmend = useSelector((state) => state.socialFeed.isOnAmend);
	const isCreating = useSelector((state) => state.socialFeed.isCreating);
	const amendPostId = useSelector((state) => state.socialFeed.amendPostId);
	const [skeletonShow, setSkeletonShow] = React.useState(false);
	const isEndComment = useSelector((state) => state.socialFeed.isEndComment);
	const isLoadingComment = useSelector(
		(state) => state.socialFeed.isLoadingComment
	);
	const likeId = useSelector((state) => state.socialFeed.likeId);
	const [likeColor, setLikeColor] = React.useState("#92929d");
	const [reactionCount, setReactionCount] = React.useState(post.reactionCount);
	const [likeShow, setLikeShow] = React.useState(
		post.ownerLike ? post.ownerLike.socialReactionUserList.length : 0
	);
	const [reactionId, setReactionId] = React.useState(
		post.ownerLike
			? post.ownerLike.socialReactionUserList.length > 0
				? post.ownerLike.socialReactionUserList[0].reactionId
				: null
			: null
	);

	let fileInput = React.createRef();
	const [commentImageModal, setCommentImageModal] = React.useState(false);
	const [commentImage, setCommentImage] = React.useState(null);
	const [commentImageSaved, setCommentImageSaved] = React.useState(null);
	const [commentImageIsEdit, setCommentImageIsEdit] = React.useState(false);
	const [fileName, setFileName] = React.useState(null);

	const hashtagList = useSelector((state) => state.socialFeed.hashtagList);
	const profileTagList = useSelector(
		(state) => state.socialFeed.profileTagList
	);
	const hashtagKeyword = useSelector(
		(state) => state.socialFeed.hashtagKeyword
	);
	const profileTagKeyword = useSelector(
		(state) => state.socialFeed.profileTagKeyword
	);
	const hashValue = useSelector((state) => state.socialFeed.hashValue);
	const createHashtagPostId = useSelector(
		(state) => state.socialFeed.createHashtagPostId
	);
	const enterKey = useSelector((state) => state.socialFeed.enterKey);
	const lastOpenCommentPostId = useSelector(
		(state) => state.socialFeed.lastOpenCommentPostId
	);
	const [reportSuccessResponse, setReportSuccessResponse] = useState(null);
	const [optionClicked, setOptionClicked] = useState(-1);
	const [poll, setPoll] = useState(null);
	const poolOptionId = useSelector((state) => state.socialFeed.poolOptionId);
	const poolResultId = useSelector((state) => state.socialFeed.poolResultId);
	const poolOptionList = useSelector(
		(state) => state.socialFeed.poolOptionList
	);
	const isChosenOption = useSelector(
		(state) => state.socialFeed.isChosenOption
	);
	const [poolResultUserListModal, setPoolResultUserListModal] = React.useState(
		false
	);
	const [commentWordNumber, setCommentWordNumber] = React.useState(null);
	const [commentText, setCommentText] = React.useState(null);
	const [commentContent, setCommentContent] = React.useState(null);
	const [commentHTML, setCommentHTML] = React.useState(null);
	const [emojiChoose, setEmojiChoose] = React.useState(null);
	const [submitComment, setSubmitComment] = React.useState(false);
	const [reactionUserListModal, setReactionUserListModal] = React.useState(
		false
	);
	const [reactionList, setReactionList] = React.useState(null);
	const [previewAttachmentIndex, setPreviewAttachmentIndex] = useState(0);
	const [isViewAttachmentModalOpen, setIsViewAttachmentModalOpen] = useState(
		false
	);
	const imageSlider = createRef();
	const [copied, setCopied] = React.useState(false);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const fetchSocialReactionList = async () => {
		const request = {
			email: user.email,
			acctId: user.acctId,
			accessToken: localStorage.getItem("token"),
			postId: post.postId,
			firstLoad: true,
		};
		console.log(request);
		dispatch(getPostReactionCount(request));
		const res = await getSocialReaction();
		setReactionList(res.data.payload);
	};

	useEffect(() => {
		// console.log(reactionList);
		if (reactionList) setReactionUserListModal(true);
	}, [reactionList]);

	useEffect(() => {
		if (submitComment) {
			postComment(0);
			setSubmitComment(false);
		}
	}, [submitComment]);

	const handleImageClick = () => {
		fileInput.current.value = null;
		fileInput.current.click();
	};

	const onEmojiClick = (event, emojiObject) => {
		if (emojiObject.emoji != "🦫") setEmojiChoose(emojiObject.emoji);
		// quillRef.current.editor.insertText(
		// 	quillRef.current.editor.getLength() - 1,
		// 	emojiObject.emoji,
		// 	"true"
		// );
	};

	useEffect(() => {
		if (post.ownerLike && post.ownerLike.socialReactionUserList.length)
			setLikeColor("#6F52ED");
	}, [post]);

	useEffect(() => {
		if (!isEndComment) setCommentState(true);
	}, [isEndComment]);

	const collapseComment = () => {
		var elmnt = document.getElementById("socialPost_" + post.postId);
		if (elmnt != null) {
			elmnt.scrollIntoView({
				behavior: "smooth",
				block: "start",
			});
			setTimeout(() => {
				setCommentState(false);
			}, 500);
		}
	};

	const getComments = (lastRecordSize) => {
		dispatch(resetPostComment({ postId: null }));
		var requestProfile = {
			email: user.email,
			acctId: user.acctId,
			postId: post.postId,
			lastRecordSize: lastRecordSize,
			requestRecordSize: 5,
			accessToken: localStorage.getItem("token"),
			postList: isCopyPost ? postResult : searchResult,
			isCopyPost: isCopyPost,
		};
		console.log(requestProfile);
		dispatch(getSocialCommentByPostId(requestProfile));
	};

	const storeNewHashTag = (name, enterKey) => {
		const requestProfile = {
			email: user.email,
			acctId: user.acctId,
			hashtagName: name,
			accessToken: localStorage.getItem("token"),
			commentId: null,
			postId: post.postId,
			enterKey: enterKey,
		};
		dispatch(createSocialHashtag(requestProfile));
	};

	React.useEffect(() => {
		if (copied) {
			setCopied(false);
			const action = (key) => (
				<React.Fragment>
					<IconButton onClick={onClickDismiss(key)}>
						<CloseIcon />
					</IconButton>
				</React.Fragment>
			);
			snackbarCustom(
				"The share link has been copy into clipboard, kindly paste and share."
			);
		}
	}, [copied]);

	const snackbarCustom = (title) => {
		const action = (key) => (
			<React.Fragment>
				<IconButton onClick={onClickDismiss(key)}>
					<CloseIcon />
				</IconButton>
			</React.Fragment>
		);
		snackBarShow(title, "default", action);
	};

	const snackBarShow = (message, variant, action) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(message, {
			variant: variant,
			action,
		});
	};
	const onClickDismiss = (key) => () => {
		closeSnackbar(key);
	};

	useEffect(() => {
		let mounted = true;
		var loadingTimeout = null;
		if (mounted) {
			if ((isOnAmend || isCreating) && amendPostId == post.postId)
				setSkeletonShow(true);
			else {
				loadingTimeout = setTimeout(() => {
					setSkeletonShow(false);
				}, 1000);
			}
		}
		return () => {
			mounted = false;
			clearTimeout(loadingTimeout);
		};
	}, [isCreating, isOnAmend]);

	useEffect(() => {
		if (createHashtagPostId == post.postId && quillRef.current) {
			if (hashValue) {
				resetCreatedHashtag();
			}
			var quillEditor = null;
			var quillContent = null;
			quillEditor = quillRef.current;
			quillContent = quillRef.current.editor.getContents();
			if (quillEditor) {
				for (let i = 0; i < quillContent.ops.length; i++) {
					let insert = quillContent.ops[i].insert;
					try {
						console.log(insert.mention.denotationChar);
					} catch (e) {
						if (insert) {
							let hashtag = insert.replace("\n", "");
							if (hashtag.charAt(0) == " ") hashtag = hashtag.substring(1);
							var splitHash = hashtag.split(" ");
							// console.log(splitHash);
							for (var j = 0; j < splitHash.length; j++) {
								if (splitHash[j].charAt(0) == "#") {
									var newEditor = quillEditor.getEditor();
									var unprivilegedEditor = quillEditor.makeUnprivilegedEditor(
										newEditor
									);

									let newHashtag =
										'<span class="mention" data-index="0" data-denotation-char="#" data-id="' +
										hashValue.id +
										'" data-value="<a href=&quot;' +
										hashValue.link +
										"&quot; target=_blank>" +
										hashValue.value +
										'" data-link="' +
										hashValue.link +
										'"><span contenteditable="false"><span class="ql-mention-denotation-char">#</span><a href="' +
										hashValue.link +
										'" target="_blank">' +
										hashValue.value +
										"</a></span></span>";
									var newDescription = unprivilegedEditor
										.getHTML()
										.replace(splitHash[j].replace("\n", ""), newHashtag);
									// console.log(newDescription);
									let newQuill = new Quill("#quillEditor");
									let initialContent = newQuill.clipboard.convert(
										newDescription
									);
									quillEditor.editor.setContents(initialContent, "silent");
									quillEditor.editor.focus();
									quillEditor.editor.insertText(
										quillEditor.editor.getLength() - 1,
										enterKey ? "\n" : " "
									);
									quillEditor.editor.setSelection(
										quillEditor.editor.getLength(),
										quillEditor.editor.getLength()
									);
								}
							}
						}
					}
				}
			}
		}
	}, [hashValue, createHashtagPostId]);

	const postComment = () => {
		// if (commentText.trim() == "" && commentContent.ops[0].insert == undefined) {
		// 	console.error("There is nothing to reply");
		// } else {
		var descHTML = commentHTML;
		var descCount = commentWordNumber;
		var descContent = commentContent;
		// if (quillRefPush.current) {
		// 	var comment = quillRefPush.current;
		// 	descHTML = comment.state.value;
		// 	descCount =
		// 		comment.state.selection.index + comment.state.selection.length;

		// 	descContent = comment.editor.editor.delta;
		// }
		if (!descCount > 0) {
			snackbarCustom("The comment should not be empty.");
		} else if (descCount > 200) {
			snackbarCustom("The comment exceeding maximum 200 characters.");
		} else {
			var file = "";
			if (commentImageSaved) {
				console.log(TimeFormatUniqueId() + "." + fileName.split(".").pop());
				file = dataURLtoFile(
					commentImageSaved,
					TimeFormatUniqueId() + "." + fileName.split(".").pop()
				);
			}
			if (descHTML && descHTML.length > 0)
				descHTML = descHTML.replaceAll('target="_blank"', "");
			let commentFormData = new FormData();

			commentFormData.append("email", user.email);
			commentFormData.append("acctId", user.acctId);
			commentFormData.append("parentId", 0); // 0 means it's not a reply otherwise commentId is passesd
			commentFormData.append("postId", post.postId);

			commentFormData.append("description", descHTML); // comment text

			commentFormData.append("attachments", file);
			commentFormData.append("status", 1);

			var j = 0;
			var k = 0;
			for (let i = 0; i < descContent.ops.length; i++) {
				let mention = descContent.ops[i].insert.mention;
				// console.log(mention);
				if (mention) {
					if (mention.denotationChar == "#") {
						var hashtagName = mention.value
							.replace(/<a\b[^>]*>/i, "")
							.replace(/<\/a>/i, "");
						commentFormData.append(`hashTags[${j}][postHashId]`, 0);
						commentFormData.append(`hashTags[${j}][hashtagName]`, hashtagName);
						j++;
					} else if (mention.denotationChar == "@") {
						commentFormData.append(`profileTags[${k}][postProfileId]`, 0);
						commentFormData.append(`profileTags[${k}][profileId]`, mention.id);
						k++;
					}
				}
			}

			commentFormData.append("accessToken", localStorage.getItem("token"));

			console.log(...commentFormData);
			var postList = {
				postList: isCopyPost ? postResult : searchResult,
			};
			console.log(postList);
			dispatch(
				createSocialComment(
					commentFormData,
					postList,
					post.postId,
					0,
					0,
					isCopyPost
				)
			);
			setCommentImageSaved(null);
			dispatch(resetPostComment({ postId: null }));
			// setComment("");
		}
	};

	const addLikePost = (reactionId) => {
		setShowReactionPaper(false);
		const requestProfile = {
			acctId: user.acctId,
			accessToken: localStorage.getItem("token"),
			email: user.email,
			reactionId: reactionId,
			postId: post.postId,
		};
		console.log(requestProfile);
		dispatch(createSocialReaction(requestProfile));
		setLikeColor("#6F52ED");
		setReactionCount(reactionCount + 1);
		setLikeShow(1);
		setReactionId(reactionId);
	};

	const removeLikePost = () => {
		const requestProfile = {
			acctId: user.acctId,
			accessToken: localStorage.getItem("token"),
			email: user.email,
			likeId:
				likeId && likeId > 0
					? likeId
					: post.ownerLike.socialReactionUserList
					? post.ownerLike.socialReactionUserList[0].likeId
					: null,
		};
		console.log(requestProfile);
		dispatch(deleteSocialReaction(requestProfile));
		setLikeColor("#92929d");
		setReactionCount(reactionCount - 1);
		setLikeShow(0);
	};

	const onFileChange = async (e) => {
		console.log(e);
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0];
			setCommentImage(file);
			setCommentImageIsEdit(false);
			setCommentImageModal(true);
		}
	};

	const [quillRefPush, setQuillRefPush] = React.useState(null);

	return (
		<Fragment>
			<div
				className={styles.body}
				id={"socialPost_" + post.postId}
				key={post.postId}
				style={{ padding: "10px 0 0 0 " }}
			>
				<div id="quillEditor" style={{ display: "none" }} />
				<div className={styles.header}>
					<div className={styles.headerLeft}>
						<Link
							to={{
								pathname: `/view-profile/${encryptData(post.acctId)}`,
							}}
							target="_blank"
							rel="noopener noreferrer"
						>
							<div className={styles.profilePic}>
								<img
									src={post.profilePic ? post.profilePic : defaultAvatar}
									alt=""
								/>
							</div>
						</Link>

						<div>
							<Link
								to={{
									pathname: `/view-profile/${encryptData(post.acctId)}`,
								}}
								target="_blank"
								rel="noopener noreferrer"
							>
								<h6 className={styles.name}>
									{post.firstName}
									{post.middleName ? " " + post.middleName : ""}
									{" " + post.surname}
								</h6>
							</Link>

							<span className={styles.time}>
								{TimeFormatSocialFeed(post.createdDate)}
							</span>
						</div>
					</div>
				</div>
				<div
					style={{
						maxHeight: "600px",
						overflowY: "auto",
						overflowX: "hidden",
						padding: "0 5px 0 20px",
					}}
					className="popupPostComment"
				>
					{post.description ? (
						<div
							className={styles.feedTitle}
							dangerouslySetInnerHTML={{ __html: post.description }}
						/>
					) : null}

					<div className={styles.feedActions}>
						{post.cpId == 1 ||
						(post.cpId == 2 &&
							(post.connId > 0 || post.acctId == userStatus.acctId)) ||
						(post.visibleId == 4 && post.cpId == 4 && isGroupMember) ? (
							<Button
								onClick={() => {
									if (!isCopyPost) {
										getComments(0);
									}
									dispatch(resetPostComment({ postId: post.postId }));
									if (showComment && lastOpenCommentPostId == post.postId) {
										setShowComment(false);
									} else {
										setShowComment(true);
									}
								}}
							>
								{/* <img src={commentIcon} alt="" /> */}
								<svg
									id="ic_comment"
									xmlns="http://www.w3.org/2000/svg"
									width="30.221"
									height="31.343"
									viewBox="0 0 30.221 31.343"
								>
									<g id="Comments">
										<path id="Path" d="M0,0H30.221V31.343H0Z" fill="none" />
										<g
											id="iconspace_Chat_3_25px"
											data-name="iconspace_Chat 3_25px"
											transform="translate(2.518 3.761)"
										>
											<path
												id="Path-2"
												data-name="Path"
												d="M6.478,23.824A1.935,1.935,0,0,1,4.543,21.9l0-2.9A5.9,5.9,0,0,1,0,13.244V5.917A5.928,5.928,0,0,1,5.926,0H19.258a5.928,5.928,0,0,1,5.926,5.918v7.327a5.928,5.928,0,0,1-5.926,5.918H13.239l-5.6,4.264A1.9,1.9,0,0,1,6.478,23.824ZM5.926,2.507a3.413,3.413,0,0,0-3.407,3.41v7.327A3.39,3.39,0,0,0,5.83,16.654,1.249,1.249,0,0,1,7.055,17.9l0,2.805,4.989-3.8a1.269,1.269,0,0,1,.765-.258h6.445a3.413,3.413,0,0,0,3.407-3.41V5.917a3.413,3.413,0,0,0-3.407-3.41Z"
												fill="#92929d"
											/>
										</g>
									</g>
								</svg>
								{post.commentCount} Comments
							</Button>
						) : null}
						<Button
							ref={anchorRef}
							onClick={() => {
								// console.log(likeColor);

								if (isMobile) {
									if (likeColor == "#6F52ED") removeLikePost();
									else {
										console.log(showReactionPaper);
										if (showReactionPaper) setShowReactionPaper(false);
										else setShowReactionPaper(true);
									}
								} else {
									if (likeColor == "#92929d") setShowReactionPaper(true);
									else removeLikePost();
								}
							}}
							onMouseEnter={() => {
								if (!isMobile) setShowReactionPaper(true);
							}}
							onMouseLeave={() => {
								if (!isMobile) setShowReactionPaper(false);
							}}
						>
							<Popper
								className={styles.likePopper}
								anchorEl={anchorRef.current}
								open={showReactionPaper && likeColor == "#92929d"}
								role={undefined}
								placement="top"
								transition
								disablePortal
							>
								{({ TransitionProps, placement }) => (
									<ClickAwayListener
										onClickAway={() =>
											!isMobile ? setShowReactionPaper(false) : null
										}
									>
										{/* <Grow
										{...TransitionProps}
										style={{
											transformOrigin: placement === "top" ? "top" : "top",
										}}
									> */}
										<Paper>
											<div
												style={{
													display: "flex",
													flexDirection: "row",
													margin: "10px",
												}}
											>
												<img
													src={likeIcon}
													alt=""
													onClick={() => addLikePost(1)}
												/>
												{/* <ThumbUpAltOutlinedIcon
													sx={{
														color: "#6F52ED",
														margin: "5px",
														transition: "all .5s",
														"&:hover": {
															transform: "scale(1.5)",
														},
													}}
													onClick={() => addLikePost(1)}
												/> */}
												<img
													src={loveIcon}
													alt=""
													onClick={() => addLikePost(2)}
												/>
												{/* <FavoriteBorderOutlinedIcon
													sx={{
														color: "#6F52ED",
														margin: "5px",
														transition: "all .5s",
														"&:hover": {
															transform: "scale(1.5)",
														},
													}}
													onClick={() => addLikePost(2)}
												/> */}
												<img
													src={clapIcon}
													alt=""
													onClick={() => addLikePost(3)}
												/>
												{/* <HandClapIcon
													style={{
														fill: "#6F52ED",
														width: "22px",
														height: "24px",
														margin: "5px",
														transition: "all .5s",
														"&:hover": {
															transform: "scale(1.5)",
														},
													}}
													className={styles.clapIcon}
													onClick={() => addLikePost(3)}
												/> */}
												<img
													src={angryIcon}
													alt=""
													onClick={() => addLikePost(4)}
												/>
												{/* <SentimentVeryDissatisfiedIcon
													sx={{
														color: "#6F52ED",
														margin: "5px",
														transition: "all .5s",
														"&:hover": {
															transform: "scale(1.5)",
														},
													}}
													onClick={() => addLikePost(4)}
												/> */}
												<img
													src={sadIcon}
													alt=""
													onClick={() => addLikePost(5)}
												/>
												{/* <MoodBadOutlinedIcon
													sx={{
														color: "#6F52ED",
														margin: "5px",
														transition: "all .5s",
														"&:hover": {
															transform: "scale(1.5)",
														},
													}}
													onClick={() => addLikePost(5)}
												/> */}
												<img
													src={happyIcon}
													alt=""
													onClick={() => addLikePost(6)}
												/>
												{/* <SentimentVerySatisfiedOutlinedIcon
													sx={{
														color: "#6F52ED",
														margin: "5px",
														transition: "all .5s",
														"&:hover": {
															transform: "scale(1.5)",
														},
													}}
													onClick={() => addLikePost(6)}
												/> */}
											</div>
										</Paper>
										{/* </Grow> */}
									</ClickAwayListener>
								)}
							</Popper>
							{likeShow > 0 ? (
								reactionId == 1 ? (
									<img src={likeIcon} alt="" />
								) : // <ThumbUpAltOutlinedIcon
								// 	sx={{
								// 		color: likeColor,
								// 		margin: "5px",
								// 		transition: "all .5s",
								// 		"&:hover": {
								// 			transform: "scale(1.5)",
								// 		},
								// 	}}
								// />
								reactionId == 2 ? (
									<img src={loveIcon} alt="" />
								) : // <FavoriteBorderOutlinedIcon
								// 	sx={{
								// 		color: likeColor,
								// 		margin: "5px",
								// 		transition: "all .5s",
								// 		"&:hover": {
								// 			transform: "scale(1.5)",
								// 		},
								// 	}}
								// />
								reactionId == 3 ? (
									<img src={clapIcon} alt="" />
								) : // <HandClapIcon
								// 	style={{
								// 		fill: likeColor,
								// 		width: "22px",
								// 		height: "24px",
								// 		margin: "5px",
								// 		transition: "all .5s",
								// 	}}
								// 	className={styles.clapIcon}
								// />
								reactionId == 4 ? (
									<img src={angryIcon} alt="" />
								) : // <SentimentVeryDissatisfiedIcon
								// 	sx={{
								// 		color: likeColor,
								// 		margin: "5px",
								// 		transition: "all .5s",
								// 		"&:hover": {
								// 			transform: "scale(1.5)",
								// 		},
								// 	}}
								// />
								reactionId == 5 ? (
									<img src={sadIcon} alt="" />
								) : (
									// <MoodBadOutlinedIcon
									// 	sx={{
									// 		color: likeColor,
									// 		margin: "5px",
									// 		transition: "all .5s",
									// 		"&:hover": {
									// 			transform: "scale(1.5)",
									// 		},
									// 	}}
									// />
									reactionId == 6 && (
										<img src={happyIcon} alt="" />
										// <SentimentVerySatisfiedOutlinedIcon
										// 	sx={{
										// 		color: likeColor,
										// 		margin: "5px",
										// 		transition: "all .5s",
										// 		"&:hover": {
										// 			transform: "scale(1.5)",
										// 		},
										// 	}}
										// />
									)
								)
							) : (
								<svg
									alt=""
									xmlns="http://www.w3.org/2000/svg"
									width="30.221"
									height="30.09"
									viewBox="0 0 30.221 30.09"
								>
									<g id="ic_like" transform="translate(0 0)">
										<g id="Likes">
											<ellipse
												id="Oval"
												cx="15.11"
												cy="15.045"
												rx="15.11"
												ry="15.045"
												transform="translate(0 0)"
												fill="none"
											/>
											<path
												id="Combined_Shape_Copy"
												data-name="Combined Shape Copy"
												d="M13.851,24.53a2.51,2.51,0,0,1-1.756-.71l-.05-.049L2.314,13.809a8.208,8.208,0,0,1,0-11.425,7.837,7.837,0,0,1,11.258,0l.279.285.279-.285a7.837,7.837,0,0,1,11.258,0,8.206,8.206,0,0,1,0,11.425l-9.732,9.962A2.5,2.5,0,0,1,13.851,24.53ZM7.943,2.507A5.311,5.311,0,0,0,4.119,4.132a5.707,5.707,0,0,0,0,7.928l9.732,9.963,9.732-9.963a5.707,5.707,0,0,0,0-7.928,5.312,5.312,0,0,0-7.647,0l-1.182,1.21a1.264,1.264,0,0,1-1.805,0l-1.182-1.21A5.311,5.311,0,0,0,7.943,2.507Z"
												transform="translate(1.259 2.507)"
												fill={likeColor}
											/>
										</g>
									</g>
								</svg>
							)}
							<span
								className={classes.likeReaction}
								// onClick={() => {
								// 	fetchSocialReactionList();
								// }}
							>
								{reactionCount} {reactionCount > 1 ? "Likes" : "Like"}
							</span>
						</Button>
						<Button
							onClick={(e) => {
								setAnchorShareEl(e.currentTarget);
							}}
						>
							{/* <img src={share} alt="" /> */}
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="30.221"
								height="30.09"
								viewBox="0 0 30.221 30.09"
							>
								<g id="ic_Share" transform="translate(0 0)">
									<g id="Share">
										<ellipse
											id="Oval"
											cx="15.11"
											cy="15.045"
											rx="15.11"
											ry="15.045"
											transform="translate(0 0)"
											fill="none"
										/>
										<g
											id="Group_24_Copy"
											data-name="Group 24 Copy"
											transform="translate(28.962 1.254)"
										>
											<path
												id="Combined_Shape"
												data-name="Combined Shape"
												d="M0,22.567a5.031,5.031,0,0,0-5.036-5.015,5.042,5.042,0,0,0-4.157,2.186l-8.691-4.37a4.976,4.976,0,0,0,.255-1.577,4.974,4.974,0,0,0-.293-1.686l8.741-4.243A5.042,5.042,0,0,0-5.036,10.03,5.031,5.031,0,0,0,0,5.015,5.031,5.031,0,0,0-5.036,0a5.032,5.032,0,0,0-5.037,5.015q0,.242.023.48l-9.307,4.517a5.027,5.027,0,0,0-3.307-1.236A5.032,5.032,0,0,0-27.7,13.791a5.032,5.032,0,0,0,5.037,5.015,5.029,5.029,0,0,0,3.4-1.321l9.209,4.63q-.02.223-.02.452a5.032,5.032,0,0,0,5.037,5.015A5.031,5.031,0,0,0,0,22.567Zm-2.518,0a2.515,2.515,0,0,1-2.518,2.507,2.516,2.516,0,0,1-2.519-2.507,2.516,2.516,0,0,1,2.519-2.507A2.516,2.516,0,0,1-2.518,22.567Zm-17.629-8.776A2.516,2.516,0,0,1-22.666,16.3a2.516,2.516,0,0,1-2.519-2.507,2.516,2.516,0,0,1,2.519-2.507A2.516,2.516,0,0,1-20.147,13.791ZM-2.518,5.015A2.516,2.516,0,0,1-5.036,7.522,2.516,2.516,0,0,1-7.556,5.015,2.516,2.516,0,0,1-5.036,2.507,2.516,2.516,0,0,1-2.518,5.015Z"
												fill="#92929d"
											/>
										</g>
									</g>
								</g>
							</svg>
							{post.shareCount} Shares
						</Button>
						<Button>
							{/* <img src={saved} alt="" /> */}
							{/* <svg
								xmlns="http://www.w3.org/2000/svg"
								width="30.221"
								height="30.09"
								viewBox="0 0 30.221 30.09"
							>
								<g id="ic_Saved" transform="translate(0 0)">
									<g id="Saved">
										<ellipse
											id="Oval"
											cx="15.11"
											cy="15.045"
											rx="15.11"
											ry="15.045"
											transform="translate(0 0)"
											fill="none"
										/>
										<g
											id="Group_13_Copy"
											data-name="Group 13 Copy"
											transform="translate(4.296 0.507)"
										>
											<path
												id="Rectangle_92"
												data-name="Rectangle 92"
												d="M18.888,26.415a2.409,2.409,0,0,1-1.5-.524l-6.746-5.374L3.9,25.891A2.4,2.4,0,0,1,0,24.027V4.776A4.792,4.792,0,0,1,4.8,0H16.49a4.792,4.792,0,0,1,4.8,4.776V24.027A2.4,2.4,0,0,1,18.888,26.415Zm-8.245-8.286a2.416,2.416,0,0,1,1.5.523l6.746,5.375V4.776a2.4,2.4,0,0,0-2.4-2.389H4.8A2.4,2.4,0,0,0,2.4,4.776V24.027l6.746-5.375A2.413,2.413,0,0,1,10.643,18.129Z"
												transform="translate(0.801 0.806)"
												fill="#92929d"
											/>
										</g>
									</g>
								</g>
							</svg>
							{post.saveCount} Saved */}
							<VisibilityOutlinedIcon
								sx={{
									color: post.viewCount > 0 ? "#6F52ED" : "#92929d",
									margin: "5px",
									transition: "all .5s",
									"&:hover": {
										transform: "scale(1.5)",
									},
								}}
								alt=""
							/>
							{post.viewCount} Viewed
						</Button>
					</div>
					{lastOpenCommentPostId == post.postId ? (
						<div className={styles.feedCommentBx}>
							<GridContainer>
								<GridItem className={styles.feedCommentFlex}>
									<div className={styles.feedCommentUserBx}>
										<img src={user.profilePic} alt="" />
									</div>
									<div className={styles.commentInputWrap}>
										<div className={classes.mentionsInput}>
											<CommentInput
												post={post}
												comment={null}
												emojiChoose={emojiChoose}
												resetEmoji={setEmojiChoose}
												getLength={setCommentWordNumber}
												getText={setCommentText}
												getHTML={setCommentHTML}
												getContent={setCommentContent}
												onSubmit={setSubmitComment}
												parentId={0}
												quillRefPush={setQuillRefPush}
											/>
										</div>

										<div className={styles.commentActionBtns}>
											{/* <IconButton
												onClick={(e) => {
													setAnchorElEmoji(e.currentTarget);
												}}
												// ref={anchorRefEmoji}
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="21"
													height="21"
													viewBox="0 0 21 21"
												>
													<path
														id="Shape"
														d="M3.075,17.924A10.5,10.5,0,0,1,17.924,3.075,10.5,10.5,0,0,1,3.075,17.924ZM1,10.5A9.5,9.5,0,1,0,10.5,1,9.511,9.511,0,0,0,1,10.5Zm5.207,4.419a.5.5,0,1,1,.6-.8,6.327,6.327,0,0,0,3.727,1.127A6.084,6.084,0,0,0,14.2,14.124a.5.5,0,0,1,.7.09.5.5,0,0,1-.09.7,7,7,0,0,1-4.277,1.331A7.236,7.236,0,0,1,6.207,14.919ZM12.5,8.5a1,1,0,1,1,1,1A1,1,0,0,1,12.5,8.5Zm-6,0a1,1,0,1,1,1,1A1,1,0,0,1,6.5,8.5Z"
														fill="#92929d"
													/>
												</svg>
											</IconButton>
											<Popover
												id={anchorElEmoji ? "emoji-popover" : undefined}
												open={anchorElEmoji ? true : false}
												anchorEl={anchorElEmoji}
												onClose={() => {
													setAnchorElEmoji(null);
												}}
												anchorOrigin={{
													vertical: "bottom",
													horizontal: "center",
												}}
												transformOrigin={{
													vertical: "top",
													horizontal: "center",
												}}
											>
												<div>
													<Picker
														onEmojiClick={onEmojiClick}
														groupVisibility={{
															flags: false,
															recently_used: false,
														}}
													/>
												</div>
											</Popover> */}
											<IconButton onClick={handleImageClick}>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="21"
													height="20"
													viewBox="0 0 21 20"
												>
													<path
														id="Shape"
														d="M17.5,20H3.5A3.431,3.431,0,0,1,0,16.65V3.35A3.431,3.431,0,0,1,3.5,0h14A3.431,3.431,0,0,1,21,3.35v13.3A3.431,3.431,0,0,1,17.5,20ZM15.286,8.775l-4.714,4.253L15.961,19H17.5A2.431,2.431,0,0,0,20,16.65V13.071Zm-8.139,1.95h0L1.009,16.849A2.458,2.458,0,0,0,3.5,19H14.613L7.148,10.725ZM3.5,1A2.431,2.431,0,0,0,1,3.35v12.1l5.814-5.8a.5.5,0,0,1,.724.019L9.9,12.286l5.051-4.557a.5.5,0,0,1,.671,0L20,11.718V3.35A2.431,2.431,0,0,0,17.5,1ZM6.691,6.7A1.925,1.925,0,1,1,8.619,4.775,1.929,1.929,0,0,1,6.691,6.7Zm0-2.849a.925.925,0,1,0,.929.925A.928.928,0,0,0,6.691,3.85Z"
														transform="translate(0 0)"
														fill="#92929d"
													/>
												</svg>
											</IconButton>
											<div className={"fileinput text-center"}>
												<input
													type="file"
													onChange={onFileChange}
													accept="image/jpg, image/jpeg, image/gif, image/png, image/apng, image/bmp, image/gif"
													ref={fileInput}
												/>
											</div>
											<IconButton
												onClick={() => {
													postComment(post.postId);
													// quillRef.current.editor.setText("");
													// setOpenEmojiOption(false);
													// if (textValue.trim() != "") {
													// 	postComment();
													// 	setTextValue("");
													// }
												}}
											>
												<SendIcon
													sx={{
														color: "#92929d",
														margin: "5px",
													}}
													className={styles.sendButton}
												/>
											</IconButton>
										</div>
										{/* {commentWordNumber && commentWordNumber > 0 ? (
											<div>
												{commentWordNumber > 200 ? (
													<ErrorOutlinedIcon
														sx={{
															color: "#6F52ED",
															margin: "0 5px -7px 0",
															width: "24px",
															transition: "all .5s",
															"&:hover": {
																transform: "scale(1.5)",
															},
														}}
													/>
												) : null}
												<span style={{ fontSize: "14px" }}>
													{commentWordNumber > 200
														? "You have exceeded the maximum character limit."
														: null}
													({commentWordNumber}
													/200)
												</span>
											</div>
										) : null} */}
										{commentImageSaved ? (
											<div className={classes.attachmentListItem}>
												<img
													src={cross}
													alt=""
													className={classes.removeAttachment}
													onClick={(e) => {
														e.stopPropagation();
														setCommentImageSaved(null);
													}}
												/>
												<div
													className={
														classes.attachmentListItemAttachmentContainer
													}
												>
													<img
														src={commentImageSaved}
														alt=""
														className={classes.attachmentListItemContent}
													/>
												</div>
												<div
													className={
														classes.attachmentListItemAttachmentContainerOverlay
													}
													onClick={() => {
														setCommentImageIsEdit(true);
														setCommentImage(commentImageSaved);
														setCommentImageModal(true);
													}}
												></div>
											</div>
										) : null}
									</div>
								</GridItem>
							</GridContainer>
						</div>
					) : null}

					{showComment ? (
						post.comments &&
						post.comments.commentList &&
						post.comments.commentList.length ? (
							<div className={styles.postCommentsMain}>
								{skeletonShow ? <CommentSkeleton /> : null}
								{post.comments &&
								post.comments.commentList &&
								post.comments.commentList.length
									? // &&
									  // commentsExpanded
									  post.comments.commentList.map((comment, index) => (
											<div
												key={comment.commentId}
												style={{ paddingRight: "5px" }}
											>
												<Comment
													comment={comment}
													postId={post.postId}
													profilePic={user.profilePic}
													commentCount={1}
													reportReasonsList={reportReasonsList}
													sectionCount={1}
												/>
											</div>
									  ))
									: null}

								{isEndComment || !commentsExpanded ? (
									<Typography component="div" className={styles.moreComments}>
										<button onClick={() => collapseComment()}>
											Collapse comments
										</button>
									</Typography>
								) : isLoadingComment ? (
									<CommentSkeleton />
								) : post.actualCommentCount >
								  post.comments.commentList.length ? (
									<Typography component="div" className={styles.moreComments}>
										<button
											onClick={() =>
												getComments(post.comments.commentList.length)
											}
										>
											Load more comments
										</button>
									</Typography>
								) : null}
							</div>
						) : (
							<></>
						)
					) : null}
				</div>
			</div>

			<Popover
				id={anchorShareEl ? "simple-popover" : undefined}
				open={anchorShareEl ? true : false}
				anchorEl={anchorShareEl}
				onClose={() => {
					setAnchorShareEl(null);
				}}
				anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
				anchorPosition={{ top: 10, left: 10 }}
				anchorReference="anchorEl"
			>
				{/* <Typography component="div" className={styles.popoverListItem}>
					<Typography component="div" className={styles.popoverListItemText}>
						<Typography component="p" className={styles.popoverListItemTitle}>
							Share to feed
						</Typography>
						<Typography
							component="p"
							className={styles.popoverListItemDescription}
						>
							Share this post on your feed
						</Typography>
					</Typography>
				</Typography>

				<Typography component="div" className={styles.popoverListItem}>
					<Typography component="div" className={styles.popoverListItemText}>
						<Typography component="p" className={styles.popoverListItemTitle}>
							Write post
						</Typography>
						<Typography
							component="p"
							className={styles.popoverListItemDescription}
						>
							Compose a post with this content
						</Typography>
					</Typography>
				</Typography> */}

				{/* <Typography component="div" className={styles.popoverListItem}>
					<Typography component="div" className={styles.popoverListItemText}>
						<Typography component="p" className={styles.popoverListItemTitle}>
							Share to Chat
						</Typography>
					</Typography>
				</Typography>

				<Typography component="div" className={styles.popoverListItem}>
					<Typography component="div" className={styles.popoverListItemText}>
						<Typography component="p" className={styles.popoverListItemTitle}>
							Share to Group
						</Typography>
					</Typography>
				</Typography> */}
				<CopyToClipboard
					// options={{ debug: props.debug, message: "" }}
					onCopy={() => setCopied(true)}
					text={
						post
							? window.location.origin +
							  "/public-search-page/post/" +
							  encryptData(post.postId)
							: ""
					}
				>
					<Typography
						component="div"
						className={styles.popoverListItem}
						onClick={() => {
							setCopied(true);
						}}
					>
						<Typography
							component="div"
							className={styles.popoverListItem}
							onClick={() => {
								setCopied(true);
							}}
						>
							<Typography
								component="div"
								className={styles.popoverListItemText}
							>
								<Typography
									component="p"
									className={styles.popoverListItemTitle}
								>
									Copy Post Link
								</Typography>
							</Typography>
						</Typography>
					</Typography>
				</CopyToClipboard>
			</Popover>
			{commentImageModal ? (
				<CommentImageModal
					open={commentImageModal}
					onClose={() => {
						setCommentImageModal(false);
					}}
					pictureSource={commentImage}
					isEdit={commentImageIsEdit}
					fileName={(e) => {
						setFileName(e);
					}}
					saveCroppedPhoto={(e) => {
						setCommentImageModal(false);
						setCommentImageSaved(e);
					}}
				/>
			) : null}
			{reactionUserListModal ? (
				<ReactionUserListModal
					open={reactionUserListModal}
					onClose={() => {
						setReactionUserListModal(false);
					}}
					comment={null}
					post={post}
					reactionList={reactionList}
					reactionType="post"
				/>
			) : null}
		</Fragment>
	);
};

export default ProfileViewPopupModal;
