import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
	instanceUrl,
	header,
	emptyCache,
} from "configurations/configurations.js";

const createUserAttachmentSlice = createSlice({
	name: "createUserAttachment",
	initialState: {
		createUserAttachmentResult: {},
		isLoading: false,
		isEnd: false,
		isSearching: false,
		isUpdated: true,
	},
	reducers: {
		CUA_LOADING: (state, action) => {
			state.isLoading = true;
		},
		CUA_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.createUserAttachmentResult = action.payload;
			state.isLoading = false;
			state.isUpdated = true;
		},
		CUA_END: (state, action) => {
			state.isEnd = true;
		},
		CUA_FAIL: (state) => {
			state.isUpdated = false;
		},
	},
});

const {
	CUA_LOADING,
	CUA_SUCCESS,
	CUA_END,
	CUA_FAIL,
} = createUserAttachmentSlice.actions;

export const createUserAttachmentInfo = ({
	email,
	acctId,
	attachment,
	attachmentTypeId,
	title,
	accessToken,
}) => async (dispatch) => {
	const body = {
		email,
		acctId,
		attachment,
		attachmentTypeId,
		title,
		accessToken,
	};

	dispatch(CUA_LOADING());
	await axios({
		method: "POST",
		headers: header,
		url: `${instanceUrl}/api/um/create-user-attachment`,
		params: body,
	})
		.then((res) => {
			if (res.status === 201) {
				const response = {
					createUserAttachmentResult: res.data.payload,
					token: res.data.token,
				};
				console.log(res.data.payload);
				dispatch(CUA_SUCCESS(response));
			} else if (res.status === 202) {
				dispatch(CUA_END());
			} else {
				dispatch(CUA_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export default createUserAttachmentSlice.reducer;
