import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
	instanceUrl,
	header,
	emptyCache,
} from "configurations/configurations.js";

const appliedJobSlice = createSlice({
	name: "appliedJob",
	initialState: {
		jobPost: [],
		jobPostList: [],
		isUpdated: true,
		isLoading: false,
		isChanged: false,
		isLoadingJob: false,
		loadingJobList: false,

		jobPost1: [],
		jobPostList1: [],
		isUpdated1: true,
		isLoading1: false,
		isChanged1: false,
		isLoadingJob1: false,
		loadingJobList1: false,

		jobPostPeding: [],
		jobPostListPeding: [],
		isUpdatedPeding: true,
		isLoadingPeding: false,
		isChangedPeding: false,
		isLoadingJobPeding: false,
		loadingJobListPeding: false,
	},
	reducers: {
		APPLIED_JOB_LOADING: (state) => {
			state.isLoading = true;
		},
		APPLIED_JOB_PAGINATION_LOADING: (state) => {
			state.loadingJobList = true;
		},
		APPLIED_JOB_MODAL_LOADING: (state) => {
			state.isLoadingJob = true;
		},
		Seeker_JOB_LIST_COUNT_SUCCESS: (state, action) => {
			console.log('Seeker_JOB_LIST_COUNT_SUCCESS');
			console.log(action);
			state.isLoading = false;
			state.isLoadingJob = false;
			state.isUpdated = true;
			state.loadingJobList = false;
			state.jobPostCount = action.payload.jobPostList;
		},
		APPLIED_JOB_LOAD_SUCCESS: (state, action) => {
			state.isLoading = false;
			state.isLoadingJob = false;
			state.isUpdated = true;
			state.loadingJobList = false;
			state.jobPost = action.payload.jobPost;
			state.jobPost = action.payload.jobPost.filter(element => {
				return element.applStatusId === 0 || element.applStatusId === 1
					|| element.applStatusId === 2 || element.applStatusId === 3
					|| element.applStatusId === 4 || element.applStatusId === 5
					|| element.applStatusId === 6 || element.applStatusId === 7
					|| element.applStatusId === 8 || element.applStatusId === 16
					|| element.applStatusId === 17 || element.applStatusId === 18;

			});
			localStorage.setItem("token", action.payload.token);
		},
		APPLIED_JOB_LIST_LOAD_SUCCESS: (state, action) => {
			state.isLoading = false;
			state.isUpdated = true;
			state.loadingJobList = false;
			state.jobPostList = action.payload.jobPostList;
			state.jobPostList = action.payload.jobPostList.filter(element => {
				return element.applStatusId === 0 || element.applStatusId === 1
					|| element.applStatusId === 2 || element.applStatusId === 3
					|| element.applStatusId === 4 || element.applStatusId === 5
					|| element.applStatusId === 6 || element.applStatusId === 7
					|| element.applStatusId === 8 || element.applStatusId === 16
					|| element.applStatusId === 17 || element.applStatusId === 18;

			});
			localStorage.setItem("token", action.payload.token);
		},
		APPLIED_JOB_LOAD_FAIL: (state) => {
			state.isUpdated = false;
			state.isLoading = false;
			state.isLoadingJob = false;
			state.loadingJobList = false;
			state.jobPostList = [];
			state.jobPostCount = [];
			localStorage.removeItem("token");
		},
		APPLIED_JOB_NEW_APPLY_SUCCESS: (state, action) => {
			state.jobPostList = [...state.jobPostList, action.payload];
			state.jobPostList = state.jobPostList
				.sort((a, b) => a.applId - b.applId)
				.reverse();
			state.jobPostList = state.jobPostList.filter(element => {
				return element.applStatusId === 0 || element.applStatusId === 1
					|| element.applStatusId === 2 || element.applStatusId === 3
					|| element.applStatusId === 4 || element.applStatusId === 5
					|| element.applStatusId === 6 || element.applStatusId === 7
					|| element.applStatusId === 8 || element.applStatusId === 16
					|| element.applStatusId === 17 || element.applStatusId === 18;

			});
		},
		APPLIED_JOB_WITHDRAW_SUCCESS: (state, action) => {
			state.isUpdated = true;
			state.isChanged = false;



			var pendingIds = new Array(9, 10, 11, 12, 13, 14);

			state.jobPostListPending = state.jobPostListPending.map((item) => {
				if (item.applId === action.payload.applId && pendingIds.includes(action.payload.applStatusId)) {
					const newItem = {
						...item,
						applStatusId: action.payload.applStatusId,
						applStatusName: action.payload.applStatusName,
					};
					return (item = newItem);
				} else {
					return item;
				}
			});





			console.log('state.jobPostCount');
			console.log(action);
			state.jobPostList = state.jobPostList.filter(element => {
				return element.applId != action.payload.applId;
			});
			state.jobPostList1 = state.jobPostList1.filter(element => {
				return element.applId != action.payload.applId;
			});


			/*
						state.jobPostListPending = state.jobPostListPending.filter(element => {
							return element.applId != action.payload.applId;
							//return (pendingIds.includes(action.payload.applStatusId));
						});
						*/
			/* state.jobPostList = state.jobPostList.filter(element => {
				console.log('element.applStatusId');
				console.log(element.applStatusId);
				return element.applStatusId === 0 || element.applStatusId === 1
					|| element.applStatusId === 2 || element.applStatusId === 3
					|| element.applStatusId === 4 || element.applStatusId === 5
					|| element.applStatusId === 6 || element.applStatusId === 7
					|| element.applStatusId === 8 || element.applStatusId === 16
					|| element.applStatusId != 15
					|| element.applStatusId === 17 || element.applStatusId === 18;

			});
 */
			localStorage.setItem("token", action.payload.token);
		},
		APPLIED_JOB_STATUS_LOADING: (state) => {
			state.isChanged = true;
		},

		//OFFER JOB REDUCERS
		OFFER_JOB_LOADING: (state) => {
			state.isLoading1 = true;
		},
		OFFER_JOB_STATUS_LOADING: (state) => {
			state.isChanged1 = true;
		},

		OFFER_JOB_PAGINATION_LOADING: (state) => {
			state.loadingJobList1 = true;
		},
		OFFER_JOB_MODAL_LOADING: (state) => {
			state.isLoadingJob1 = true;
		},
		OFFER_JOB_WITHDRAW_SUCCESS: (state, action) => {
			state.isUpdated1 = true;
			state.isChanged1 = false;

			state.jobPostList1 = state.jobPostList.map((item) => {
				if (item.applId === action.payload.applId) {
					const newItem = {
						...item,
						applStatusId: action.payload.applStatusId,
						applStatusName: action.payload.applStatusName,
					};
					return (item = newItem);
				} else {
					return item;
				}
			});

			state.jobPostList1 = state.jobPostList.filter(element => {
				// state.jobPostList1 = action.payload.jobPostList1.filter(element => {
				// 	return element.applStatusId === 15;
				// 	return element.applStatusId === 0 || element.applStatusId === 1
				// 		  || element.applStatusId === 2 || element.applStatusId === 3
				// 		  || element.applStatusId === 4 || element.applStatusId === 5
				// 		  || element.applStatusId === 6 || element.applStatusId === 7
				// 		  || element.applStatusId === 8 || element.applStatusId === 16
				// 		  || element.applStatusId === 17 || element.applStatusId === 18;

				// });	

			});

			localStorage.setItem("token", action.payload.token);
		},
		OFFER_JOB_LOAD_SUCCESS: (state, action) => {
			state.isLoading1 = false;
			state.isLoadingJob1 = false;
			state.isUpdated1 = true;
			state.loadingJobList1 = false;
			state.jobPost1 = action.payload.jobPost1;
			localStorage.setItem("token", action.payload.token);
		},
		OFFER_JOB_LIST_LOAD_SUCCESS: (state, action) => {
			state.isLoading1 = false;
			state.isUpdated1 = true;
			state.loadingJobList1 = false;
			state.jobPostList1 = action.payload.jobPostList1;
			localStorage.setItem("token", action.payload.token);
		},
		OFFER_JOB_LOAD_FAIL: (state) => {
			state.isUpdated1 = false;
			state.isLoading1 = false;
			state.isLoadingJob1 = false;
			state.loadingJobList1 = false;
			state.jobPostList1 = [];
			localStorage.removeItem("token");
		},


		//PENDING JOB REDUCERS
		PENDING_JOB_LOADING: (state) => {
			state.isLoadingPending = true;
		},
		PENDING_JOB_STATUS_LOADING: (state) => {
			state.isChangedPending = true;
		},

		PENDING_JOB_PAGINATION_LOADING: (state) => {
			state.loadingJobListPending = true;
		},
		PENDING_JOB_MODAL_LOADING: (state) => {
			state.isLoadingJobPending = true;
		},
		PENDING_JOB_WITHDRAW_SUCCESS: (state, action) => {
			state.isUpdatedPendingPending = true;
			state.isChangedPending = false;

			state.jobPostListPending = state.jobPostList.map((item) => {
				if (item.applId === action.payload.applId) {
					const newItem = {
						...item,
						applStatusId: action.payload.applStatusId,
						applStatusName: action.payload.applStatusName,
					};
					return (item = newItem);
				} else {
					return item;
				}
			});

			state.jobPostListPending = state.jobPostList.filter(element => {
				// state.jobPostListPending = action.payload.jobPostListPending.filter(element => {
				// 	return element.applStatusId === 15;
				// 	return element.applStatusId === 0 || element.applStatusId === 1
				// 		  || element.applStatusId === 2 || element.applStatusId === 3
				// 		  || element.applStatusId === 4 || element.applStatusId === 5
				// 		  || element.applStatusId === 6 || element.applStatusId === 7
				// 		  || element.applStatusId === 8 || element.applStatusId === 16
				// 		  || element.applStatusId === 17 || element.applStatusId === 18;

				// });	

			});

			localStorage.setItem("token", action.payload.token);
		},
		PENDING_JOB_DECLINE_SUCCESS: (state, action) => {

			console.log('action')
			console.log(action);

			state.isUpdatedPendingPending = true;
			state.isChangedPending = false;

			var pendingIds = new Array(9, 10, 11, 12, 13, 14);

			state.jobPostListPending = state.jobPostListPending.map((item) => {


				if (item.applId === action.payload.applId) {

					const newItem = {
						...item,
						applStatusId: 13,

					};

					return (item = newItem);
				} else {
					return item;
				}
			});



			localStorage.setItem("token", action.payload.token);


		},
		PENDING_JOB_LOAD_SUCCESS: (state, action) => {
			console.log('PENDING_JOB_LOAD_SUCCESS');
			console.log(action);
			state.isLoadingPending = false;
			state.isLoadingJobPending = false;
			state.isUpdatedPendingPending = true;
			state.loadingJobListPending = false;
			state.jobPostPending = action.payload.jobPostList1;
			localStorage.setItem("token", action.payload.token);
		},
		PENDING_JOB_LIST_LOAD_SUCCESS: (state, action) => {
			console.log('PENDING_JOB_LIST_LOAD_SUCCESS');
			console.log(action);
			state.isLoadingPending = false;
			state.isUpdatedPendingPending = true;
			state.loadingJobListPending = false;
			state.jobPostListPending = action.payload.jobPostList1;
			localStorage.setItem("token", action.payload.token);
		},

		PENDING_JOB_LOAD_FAIL: (state) => {
			state.isUpdatedPendingPending = false;
			state.isLoadingPending = false;
			state.isLoadingJobPending = false;
			state.loadingJobListPending = false;
			state.jobPostListPending = [];
			localStorage.removeItem("token");
		},

	},
});

const {
	APPLIED_JOB_LOADING,
	APPLIED_JOB_PAGINATION_LOADING,
	APPLIED_JOB_MODAL_LOADING,
	APPLIED_JOB_LOAD_FAIL,
	APPLIED_JOB_LOAD_SUCCESS,
	APPLIED_JOB_LIST_LOAD_SUCCESS,
	APPLIED_JOB_WITHDRAW_SUCCESS,
	APPLIED_JOB_STATUS_LOADING,
	APPLIED_JOB_NEW_APPLY_SUCCESS,

	OFFER_JOB_PAGINATION_LOADING,
	OFFER_JOB_LOADING,
	OFFER_JOB_LIST_LOAD_SUCCESS,
	OFFER_JOB_LOAD_FAIL,
	OFFER_JOB_MODAL_LOADING,
	OFFER_JOB_LOAD_SUCCESS,
	OFFER_JOB_WITHDRAW_SUCCESS,
	OFFER_JOB_STATUS_LOADING,
	PENDING_JOB_PAGINATION_LOADING,
	PENDING_JOB_LOADING,
	PENDING_JOB_LIST_LOAD_SUCCESS,
	PENDING_JOB_LOAD_FAIL,
	PENDING_JOB_MODAL_LOADING,
	PENDING_JOB_LOAD_SUCCESS,
	PENDING_JOB_WITHDRAW_SUCCESS,
	PENDING_JOB_DECLINE_SUCCESS,
	PENDING_JOB_STATUS_LOADING,


	Seeker_JOB_LIST_COUNT_SUCCESS,

} = appliedJobSlice.actions;

export const getAppliedJobPostList = ({
	acctId,
	email,
	accessToken,
	lastRecordSize,
	requestRecordSize,
	paginationClick,
}) => async (dispatch) => {
	const body = {
		acctId,
		email,
		accessToken,
		lastRecordSize,
		requestRecordSize,
	};
	if (paginationClick) dispatch(APPLIED_JOB_PAGINATION_LOADING());
	else dispatch(APPLIED_JOB_LOADING());
	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/am/get-job-application-by-user-id`,
		params: body,
	})
		.then((res) => {
			// console.log(res);
			if (res.status === 201) {
				const response = {
					jobPostList: res.data.payload.jobPostList,
					token: res.data.token,
				};
				// console.log(response);
				dispatch(APPLIED_JOB_LIST_LOAD_SUCCESS(response));
			} else {
				dispatch(APPLIED_JOB_LOAD_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};


export const getSeekerJobPostList = ({
	acctId,
	email,
	accessToken,
	lastRecordSize,
	requestRecordSize,

	statusIds,
}) => async (dispatch) => {
	const body = {
		acctId,
		email,
		accessToken,
		lastRecordSize,
		requestRecordSize,
		statusIds,
	};
	//dispatch(APPLIED_JOB_LOADING());
	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/am/get-job-application-by-user-id`,
		params: body,
	})
		.then((res) => {
			// console.log(res);
			if (res.status === 201) {
				const response = {
					jobPostList: res.data.payload.jobPostList,
					token: res.data.token,
				};
				// console.log(response);
				dispatch(APPLIED_JOB_LIST_LOAD_SUCCESS(response));
			} else {
				dispatch(APPLIED_JOB_LOAD_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};


export const getSeekerJobPostCount = ({
	acctId,
	email,
	accessToken,


}) => async (dispatch) => {
	const body = {
		acctId,
		email,
		accessToken,

	};
	//dispatch(APPLIED_JOB_LOADING());
	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/am/get-job-application-count-by-user-id`,
		params: body,
	})
		.then((res) => {
			// console.log(res);
			if (res.status === 201) {
				const response = {
					jobPostList: res.data.payload.jobApplicationCount,
					token: res.data.token,
				};
				// console.log(response);
				dispatch(Seeker_JOB_LIST_COUNT_SUCCESS(response));
			} else {
				dispatch(APPLIED_JOB_LOAD_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};



export const getOfferJobPostList = ({
	acctId,
	email,
	accessToken,
	lastRecordSize,
	requestRecordSize,

	statusIds,
}) => async (dispatch) => {
	const body = {
		acctId,
		email,
		accessToken,
		lastRecordSize,
		requestRecordSize,

		statusIds,
	};

	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/am/get-job-application-by-user-id`,
		params: body,
	})
		.then((res) => {
			// console.log(res);
			if (res.status === 201) {
				const response = {
					jobPostList1: res.data.payload.jobPostList,
					token: res.data.token,
				};
				// console.log(response);
				dispatch(OFFER_JOB_LIST_LOAD_SUCCESS(response));
			} else {
				dispatch(OFFER_JOB_LOAD_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const getPendingJobPostList = ({
	acctId,
	email,
	accessToken,
	lastRecordSize,
	requestRecordSize,

	statusIds,
}) => async (dispatch) => {
	const body = {
		acctId,
		email,
		accessToken,
		lastRecordSize,
		requestRecordSize,

		statusIds,
	};

	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/am/get-job-application-by-user-id`,
		params: body,
	})
		.then((res) => {
			// console.log(res);
			if (res.status === 201) {
				const response = {
					jobPostList1: res.data.payload.jobPostList,
					token: res.data.token,
				};
				// console.log(response);
				dispatch(PENDING_JOB_LIST_LOAD_SUCCESS(response));
			} else {
				dispatch(PENDING_JOB_LOAD_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};



export const declineEventScheduleStatusSeeker = ({
	acctId,
	email,
	accessToken,
	eventId,
	statusId
}) => async dispatch => {
	const body = {
		acctId,
		email,
		accessToken,
		eventId,
		statusId
	};

	await axios({
		method: 'PUT',
		headers: header,
		url: `${instanceUrl}/api/um/update-user-event-status`,
		data: body,
	})
		.then(res => {

			if (res.status === 201) {
				const response = {
					applId: res.data.payload.event.applId,

					token: res.data.token,

				};
				dispatch(PENDING_JOB_DECLINE_SUCCESS(response));
			} else {
				dispatch(PENDING_JOB_LOAD_FAIL());
			}





		})
		.catch(err => {
			if (err.message.includes('503')) {
				emptyCache();
			}
			console.log(err);
		});
};

export const getOfferJobPost = ({
	acctId,
	email,
	accessToken,
	applId,
}) => async (dispatch) => {
	const body = {
		acctId,
		email,
		accessToken,
		applId,
	};
	dispatch(OFFER_JOB_MODAL_LOADING());
	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/am/get-job-application-by-appl-id`,
		params: body,
	})
		.then((res) => {
			// console.log(res);
			if (res.status === 201) {
				const response = {
					jobPost1: res.data.payload.jobPostList,
					token: res.data.token,
				};
				// console.log(response);
				dispatch(OFFER_JOB_LOAD_SUCCESS(response));
			} else {
				dispatch(OFFER_JOB_LOAD_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const getAppliedJobPost = ({
	acctId,
	email,
	accessToken,
	applId,
}) => async (dispatch) => {
	const body = {
		acctId,
		email,
		accessToken,
		applId,
	};
	dispatch(APPLIED_JOB_MODAL_LOADING());
	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/am/get-job-application-by-appl-id`,
		params: body,
	})
		.then((res) => {
			// console.log(res);
			if (res.status === 201) {
				const response = {
					jobPost: res.data.payload.jobPostList,
					token: res.data.token,
				};
				// console.log(response);
				dispatch(APPLIED_JOB_LOAD_SUCCESS(response));
			} else {
				dispatch(APPLIED_JOB_LOAD_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const AcceptRejectJob = ({
	acctId,
	accessToken,
	email,
	applicantId,
	applId,
	applStatusId,
}) => async (dispatch) => {
	dispatch(OFFER_JOB_STATUS_LOADING());
	const body = {
		acctId,
		accessToken,
		email,
		applicantId,
		applId,
		applStatusId,
	};

	await axios({
		method: "PUT",
		headers: header,
		url: `${instanceUrl}/api/am/update-application-status`,
		data: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				const response = {
					applId,
					applStatusId,
					token: res.data.token,
					applStatusName: res.data.payload.newApplStatus,
				};
				dispatch(OFFER_JOB_WITHDRAW_SUCCESS(response));
			} else {
				dispatch(OFFER_JOB_LOAD_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const withdrawAppliedJob = ({
	acctId,
	accessToken,
	email,
	applicantId,
	applId,
	applStatusId,
}) => async (dispatch) => {
	dispatch(APPLIED_JOB_STATUS_LOADING());
	const body = {
		acctId,
		accessToken,
		email,
		applicantId,
		applId,
		applStatusId,
	};

	await axios({
		method: "PUT",
		headers: header,
		url: `${instanceUrl}/api/am/update-application-status`,
		data: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				const response = {
					applId,
					applStatusId,
					token: res.data.token,
					applStatusName: res.data.payload.newApplStatus,
				};
				dispatch(APPLIED_JOB_WITHDRAW_SUCCESS(response));
			} else {
				dispatch(APPLIED_JOB_LOAD_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};


export const appliedJobApply = ({
	applId,
	applStatusId,
	title,
	applDate,
	profilePic,
	jobPostId,
	applStatusName,
	companyName,
	jobPostStatusId,
}) => (dispatch) => {
	const response = {
		applId,
		applStatusId,
		title,
		applDate,
		profilePic,
		jobPostId,
		applStatusName,
		companyName,
		jobPostStatusId,
	};
	console.log(response);
	if (response) {
		dispatch(APPLIED_JOB_NEW_APPLY_SUCCESS(response));
	}
};

export default appliedJobSlice.reducer;
