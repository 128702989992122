import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { getArticleList } from "configurations/redux/communitySlice.js";
import InfiniteScroll from "react-infinite-scroll-component";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import communityPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/CommunityPage/communityPageStyle.js";
import SearchResultCard from "./SearchResultCard";
import NoActivityStatusModal from "../NoActivityStatusModal/NoActivityStatusModal";
import NoResultImage from "assets/img/No_Result.jpg";

import { isMobile } from "react-device-detect";
import Pagination from "../../Component/PaginationWithPage.js";

const useStyles = makeStyles(communityPageStyle);
export default function SearchResultSection() {
	const classes = useStyles();

	const searchResult = useSelector((state) => state.community.searchResult);
	const keywordResult = useSelector((state) => state.community.keywordResult);
	const keyword = useSelector((state) => state.community.keyword);
	const searchParams = useSelector((state) => state.community.searchParams);
	const currentSize = useSelector((state) => state.community.lastRecordSize);
	const userStatus = useSelector((state) => state.auth.user);
	const isEnd = useSelector((state) => state.community.isEnd);
	const isUpdated = useSelector((state) => state.community.isUpdated);
	const isLoading = useSelector((state) => state.community.isLoading);
	const searchTag = useSelector((state) => state.community.searchTag);
	const isSearching = useSelector((state) => state.community.isSearching);
	const bizId = useSelector((state) => state.business.bizId);

	const [currentPage, setCurrentPage] = React.useState(1);
	const [articlesPerPage, setArticlesPerPage] = React.useState(12);
	const indexOfLastJob = currentPage * articlesPerPage;
	const indexOfFirstJob = indexOfLastJob - articlesPerPage;
	const currentArticles = searchResult.slice(indexOfFirstJob, indexOfLastJob);
	const paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
		var indexOfFirstJob = pageNumber * articlesPerPage - articlesPerPage;
		getArticlePost(indexOfFirstJob);
		// window.scrollTo(0, 0);
		scrollToTop();
	};
	const dispatch = useDispatch();
	const getArticlePost = async (indexOfFirstJob) => {
		const requestProfile = {
			acctId: userStatus.acctId,
			acctTypeId: bizId > 0 ? 2 : userStatus.acctTypeId,
			accessToken: localStorage.getItem("token"),
			email: userStatus.email,
			categoryIds: searchParams.categoryIds,
			subCategoryIds: searchParams.subCategoryIds,
			lastRecordSize: indexOfFirstJob,
			requestRecordSize: articlesPerPage,
			paginationClick: true,
			defaultLoad: false,
			keyword: keyword,
		};
		dispatch(getArticleList(requestProfile));
	};

	const scrollToTop = () => {
		var timer = setInterval(() => {
			var articleResult = document.getElementById("resourcesPage");
			console.log(articleResult);
			if (articleResult) {
				articleResult.scrollIntoView();
				window.scrollTo(0, 0);
				clearInterval(timer);
			}
		}, 100);
	};

	React.useEffect(() => {
		setCurrentPage(1);
	}, [searchTag, isSearching, keyword]);
	// console.log(isUpdated, searchResult);
	// React.useEffect(() => {
	// 	console.log(keywordResult, searchResult);
	// }, [keywordResult, searchResult]);
	return (
		<React.Fragment>
			{isUpdated ? (
				<div>
					<SearchResultCard articleList={keywordResult} />
					<div className={classes.paginationAligned}>
						<Pagination
							totalPosts={
								searchResult.length > 0
									? searchResult[0].totalArticleCount != undefined
										? searchResult[0].totalArticleCount
										: 0
									: 0
							}
							postsPerPage={articlesPerPage}
							paginate={paginate}
							page={currentPage}
						/>
					</div>
				</div>
			) : (
				<NoActivityStatusModal />
			)}
		</React.Fragment>
	);
}
