import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import ActionCardStyle from "../../assets/jss/material-kit-pro-react/components/ActionCardStyle";
import { Button } from "@material-ui/core";
const useStyles = makeStyles(ActionCardStyle);

function ActionCard({
	number = "",
	title,
	action,
	isNumber = true,
	isDivider = true,
	isGroup = false,
	onClick,
	count,
}) {
	const classes = useStyles();

	return (
		<div className={isGroup ? classes.groupContainer : classes.container}>
			<div className={classes.contentContainer}>
				{isNumber && <p className={classes.txt1}>{number}</p>}
				<h5 style={{ fontWeight: 700 }}>{count}</h5>
				<p className={classes.txt2} style={{ marginTop: !isNumber ? 30 : 10 }}>
					{title}
				</p>

				<Button className={classes.actionContainer} onClick={onClick}>
					{action}
				</Button>
			</div>
			{isDivider && <div className={classes.divider} />}
		</div>
	);
}

export default ActionCard;
