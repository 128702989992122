const testimonialCardStyles = theme => ({
  mainContainer: {
    display: 'flex',
  },
  cardFrame: {
    borderRadius: '18px',
    boxShadow: 'none',
    margin: '10px 0px',
  },
  cardBody: {
    padding: '15px 15px !important',
  },
  imgContainer: {
    display: 'flex',

    '& img': {
      width: '48px',
      height: '48px',
      borderRadius: '50%',
      marginTop: '5px',
    },
  },
  descriptionContainer: {
    width: '100%',
    display: 'flex',
    marginLeft: '10px',
    justifyContent: 'space-between',
    '& h4': {
      marginBottom: '0px !important',
      marginTop: '5px !important',
    },
  },
  name: {
    fontWeight: 700,
    fontSize: '16px',
  },
  description: {
    fontWeight: 500,
    fontSize: '14px',
    color: 'rgba(0,0,0,0.6)',
    whiteSpace: 'pre-line',
  },
  contentContainer: {
    marginTop: '1.2rem',
  },
  additionalDescription: {
    fontStyle: 'italic',
    fontWeight: 300,
    color: 'rgba(0,0,0,0.6)',
  },
  statusDescription: {
    fontWeight: 500,
    color: 'rgba(0,0,0,0.6)',
    fontSize: '12px',
  },
  textDeco: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover,&:focus': {
      color: 'inherit',
      textDecoration: 'underline',
    },
  },
  //override initial load
  menuItem: {
    display: 'flex',
    /* alignItems: "center" */
    padding: '6px 16px !important',
    cursor: 'pointer',
    justifyContent: 'flex-start',
  },
});

export default testimonialCardStyles;
