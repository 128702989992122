import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

import {
	updateHashtag,
	deleteHashtag,
} from "configurations/redux/corpUserSlice.js";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js";

const useStyles = makeStyles(profilePageStyle);

export default function CorporateTagSection() {
	const classes = useStyles();
	const dispatch = useDispatch();

	const hashTagsList = useSelector((state) => state.corpUser.hashtags);
	const corpUser = useSelector((state) => state.corpUser);
	const email = useSelector((state) => state.corpUser.profileRecord.email);
	const isViewed = useSelector((state) => state.corpUser.isViewed);
	const bizId = useSelector((state) => state.business.bizId);
	const userStatus = useSelector((state) => state.auth.user);

	const [skillTag, setSkillTag] = React.useState([]);
	const [tagInput, setTagInput] = React.useState("");
	const [maxLengthError, setMaxLengthError] = React.useState(false);
	const [error, setError] = React.useState(false);

	const deleteTag = (value) => () => {
		console.log(value);
		const requestProfile = {
			email: email,
			acctId: userStatus.acctId,
			accessToken: localStorage.getItem("token"),
			hashId: value.hashId,
			bizId: bizId,
		};
		console.log(requestProfile);
		dispatch(deleteHashtag(requestProfile));
	};
	const handleChange = (event) => {
		event.preventDefault();
		setTagInput(event.target.value);
	};
	const updateRequest = (hashtag) => {
		const requestProfile = {
			email: email,
			acctId: userStatus.acctId,
			accessToken: localStorage.getItem("token"),
			hashId: -1,
			hashtag: hashtag,
			bizId: bizId,
		};
		dispatch(updateHashtag(requestProfile));
	};

	const updateTag = (data) => {
		if (data.charCode === 13 && data) {
			if (data.target.value.trim().length > 0) {
				if (data.target.value.length > 50) {
					data.preventDefault();
					setMaxLengthError(true);
					// setTagInput("");
					setError(false);
				} else {
					setMaxLengthError(false);
					const index = hashTagsList.findIndex(
						(item) =>
							item.hashtag.toLowerCase() === data.target.value.toLowerCase()
					);

					if (index === -1) {
						updateRequest(data.target.value);
						data.preventDefault();
						setTagInput("");
						setError(false);
					} else {
						data.preventDefault();
						setTagInput("");
						setError(true);
					}
				}
			}
		}
	};
	const HashTagList = () => {
		return (
			<div className={classes.hashTagContainer}>
				{skillTag &&
					skillTag.map((item) => {
						if (isViewed) {
							return (
								<Chip
									key={item.hashId}
									label={
										<p className={classes.tagDescription}>{item.hashtag}</p>
									}
									className={classes.chip}
									color={"secondary"}
								/>
							);
						} else {
							return (
								<Chip
									key={item.hashId}
									label={
										<p className={classes.tagDescription}>{item.hashtag}</p>
									}
									className={classes.chip}
									onDelete={deleteTag(item)}
									color={"secondary"}
								/>
							);
						}
					})}
			</div>
		);
	};

	React.useEffect(() => {
		if (hashTagsList) {
			setSkillTag(hashTagsList);
		}
	}, [hashTagsList]);

	return (
		<div>
			{corpUser && (
				<Card className={classes.cardFrame}>
					<CardBody>
						<h4 className={classes.title}>Company Tags</h4>
						{error ? (
							<SnackbarContent
								message={<b>Duplicate Tag is not allowed</b>}
								close
								color="opptyWarning"
								icon="info_outline"
							/>
						) : null}
						{maxLengthError && (
							<SnackbarContent
								message={<b>Tag cannot exceed 50 characters</b>}
								close
								color="opptyWarning"
								icon="info_outline"
							/>
						)}

						{hashTagsList && hashTagsList[0] && (
							<div className={classes.cardBodyMargin}>
								<HashTagList />
							</div>
						)}

						{isViewed ? null : (
							<TextField
								id="tagInput"
								value={tagInput}
								fullWidth
								disabled={
									skillTag ? (skillTag.length >= 10 ? true : false) : false
								}
								onKeyPress={updateTag}
								onKeyDown={() => {
									if (error || maxLengthError) {
										setError(false);
										setMaxLengthError(false);
									}
								}}
								label={
									skillTag ? (
										skillTag.length >= 10 ? (
											<p>Maximum Tag Reached</p>
										) : (
											<p>Add Tag</p>
										)
									) : (
										<p>Add Tag</p>
									)
								}
								onChange={handleChange}
							/>
						)}
					</CardBody>
				</Card>
			)}
		</div>
	);
}
