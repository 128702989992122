import {
	defaultFont,
	primaryBoxShadow,
	infoBoxShadow,
	successBoxShadow,
	warningBoxShadow,
	dangerBoxShadow,
	container,
	blackColor,
	whiteColor,
	grayColor,
	primaryColor,
	warningColor,
	dangerColor,
	successColor,
	infoColor,
	hexToRgb,
	opptyDesignColor,
	opptyWarningColor,
} from "assets/jss/material-kit-pro-react.js";

const snackbarContentStyle = {
	root: {
		...defaultFont,
		position: "relative",
		padding: "20px 15px",
		lineHeight: "20px",
		marginBottom: "20px",
		fontSize: "14px",
		backgroundColor: "white",
		color: grayColor[15],
		borderRadius: "0px",
		maxWidth: "100%",
		minWidth: "auto",
		boxShadow:
			"0 12px 20px -10px rgba(" +
			hexToRgb(whiteColor) +
			", 0.28), 0 4px 20px 0px rgba(" +
			hexToRgb(blackColor) +
			", 0.12), 0 7px 8px -5px rgba(" +
			hexToRgb(whiteColor) +
			", 0.2)",
	},
	info: {
		backgroundColor: infoColor[3],
		color: whiteColor,
		...infoBoxShadow,
	},
	success: {
		backgroundColor: successColor[3],
		color: whiteColor,
		...successBoxShadow,
	},
	warning: {
		backgroundColor: warningColor[3],
		color: whiteColor,
		...warningBoxShadow,
	},
	danger: {
		backgroundColor: dangerColor[3],
		color: whiteColor,
		...dangerBoxShadow,
	},
	primary: {
		backgroundColor: primaryColor[3],
		color: whiteColor,
		...primaryBoxShadow,
	},
	opptyWarning: {
		backgroundColor: opptyWarningColor,
		color: whiteColor,
		borderRadius: "12px",
	},
	opptyInfo: {
		backgroundColor: opptyDesignColor,
		color: whiteColor,
		borderRadius: "12px",
	},
	opptyWhite: {
		backgroundColor: whiteColor,
		color: blackColor,
		borderRadius: "18px",
		boxShadow: "none",
	},
	opptyTip: {
		backgroundColor: "#D3FFEE",
		color: blackColor,
		borderRadius: "18px",
		/* boxShadow: " 0 16px 23px -7px rgba(" + hexToRgb(blackColor) + ", 0.4)" */
		boxShadow: "none",
		"& button": {
			color: "#6D7278 !important",
		},
		"@media (max-width: 600px)": {
			padding: "20px !important",
		},
	},
	message: {
		padding: "0",
		display: "block",
		maxWidth: "89%",
		/* "& p": {
			marginTop: "10px !important",
		}, */
		lineHeight: "24px!important",
	},
	close: {
		width: "20px",
		height: "20px",
	},
	iconButton: {
		width: "24px",
		height: "24px",
		float: "right",
		fontSize: "1.5rem",
		fontWeight: "500",
		lineHeight: "1",
		position: "absolute",
		right: "-4px",
		top: "0",
		padding: "0",
	},
	icon: {
		display: "block",
		float: "left",
		marginRight: "1.071rem",
	},
	container: {
		/* ...container, */
		width: "90vw",
		paddingRight: "15px",
		paddingLeft: "15px",
		marginRight: "auto",
		marginLeft: "auto",
		position: "relative",
	},
	size: {
		margin: "0",
		padding: "0",
		borderRadius: "4px",
		position: "Absolute",
		minWidth: "69px",
	},
	padding: {
		padding: "3px 4px",
	},
	iconMargin: {
		marginRight: "4px",
	},
	messageWidth: {
		width: "3vw",
		boxShadow: "none",
		marginTop: "-3px",
		borderRadius: "8px",
	},
	userTip: {
		padding: "0",
		display: "block",
		maxWidth: "100%",
		width: "100%",
		marginTop: "-10px",
		marginBottom: "-10px",
		"@media (max-width: 600px)": {
			marginTop: "-5px",
			marginBottom: "-5px",
		},
		"& p": {
			marginTop: "10px ",
		},
		lineHeight: "24px!important",
	},
};

export default snackbarContentStyle;
