import { useDispatch } from "react-redux";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import messageDashboardStyle from "assets/jss/material-kit-pro-react/views/pageStyle/MessagePage/messagePageStyle.js";

const useStyles = makeStyles(messageDashboardStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomDialogFullScreen(props) {
	console.log(props);
	const { title, children, openDialog, setOpenDialog } = props;
	const classes = useStyles();

	const handleCloseDialog = () => {
		setOpenDialog(false);
	};

	return (
		<div>
			<Dialog fullScreen open={openDialog} TransitionComponent={Transition}>
				<AppBar className={classes.appbar}>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={handleCloseDialog}
							aria-label="close"
						>
							<ArrowBackIosIcon />
						</IconButton>

						<h4 className={classNames(classes.cardTitle)}>{title}</h4>
					</Toolbar>
				</AppBar>
				<div style={{ paddingTop: "2vh", overflowY: "hidden" }}>{children}</div>
			</Dialog>
		</div>
	);
}
