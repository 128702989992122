import { createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import {
	instanceUrl,
	header,
	headerFormData,
	emptyCache,
} from "configurations/configurations.js";
import { messageChatroomListInfo } from "configurations/redux/messageChatroomListSlice.js";
// import { logout } from "configurations/redux/authSlice.js";

const messageConversationSlice = createSlice({
	name: "messageConversation",
	initialState: {
		searchCount: 0,
		searchResult: [],
		searchParams: [],
		lastRecordSize: 0,
		requestRecordSize: 0,
		isLoading: true,
		isCreating: false,
		isEnd: false,
		isSearching: false,
		isUpdated: true,
		chatroomId: 0,
		conversationId: 0,
		description: "",
		chatroomList: [],
		readId: "",
		isDeleted: true,
		isLoadingConversation: false,
	},
	reducers: {
		CONVERSATION_LOADING: (state, action) => {
			state.isLoading = true;
		},
		CONVERSATION_CREATING: (state, action) => {
			state.isCreating = true;
		},
		CONVERSATION_ROOM_LOADING: (state, action) => {
			state.isLoadingConversation = true;
		},
		CONVERSATION_SEARCH_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.searchResult = [
				...state.searchResult,
				...action.payload.conversationList,
			];
			state.searchParams = action.payload.searchParams;
			state.lastRecordSize =
				state.lastRecordSize + action.payload.requestRecordSize;
			state.requestRecordSize = action.payload.requestRecordSize;
			state.isLoading = false;
			state.isLoadingConversation = false;
			state.chatroomId = action.payload.chatroomId;
		},
		CONVERSATION_SEARCH_END: (state, action) => {
			state.isEnd = true;
		},
		DEFAULT_CONVERSATION_SEARCH: (state) => {
			state.searchResult = [];
			state.searchParams = [];
			state.lastRecordSize = 0;
			state.requestRecordSize = 0;
			state.isEnd = false;
			state.isSearching = true;
			state.chatroomId = 0;
			state.conversationId = 0;
			state.searchCount = 0;
			state.description = "";
			state.chatroomList = [];
			state.readId = "";
		},
		CONVERSATION_SEARCH_FAIL: (state) => {
			state.isUpdated = false;
			state.isLoadingConversation = false;
		},
		CONVERSATION_ADD_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.isUpdated = true;
			state.isLoading = false;
			state.isCreating = false;
			state.chatroomId = action.payload.chatroomId;
		},
		CONVERSATION_CHECK_UNREAD: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.searchCount = action.payload.searchCount;
			state.isLoading = false;
		},
		CONVERSATION_CHECK_UNREAD_END: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.searchCount = action.payload.searchCount;
			state.isUpdated = false;
		},
		CONVERSATION_CHECK_UNREAD_FAIL: (state, action) => {
			state.searchCount = 0;
			state.isUpdated = false;
		},
		CONVERSATION_UPDATE_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.isUpdated = true;
			state.isLoading = false;
		},
		CONVERSATION_DELETE_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.isSearching = false;
			state.searchResult = action.payload.searchResult;
			state.isDeleted = true;
		},
		CONVERSATION_DELETE_FAIL: (state, action) => {
			state.isDeleted = action.payload.isDeleted;
		},
		CONVERSATION_CHECK_PROFILE: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.receiptAcctId = action.payload.receiptAcctId;
			state.receiptEmail = action.payload.receiptEmail;
			state.isLoading = false;
		},
		CONVERSATION_NEW_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.searchResult = [
				...action.payload.conversationList,
				...action.payload.searchResult,
			];
			state.searchParams = action.payload.searchParams;
			state.lastRecordSize =
				action.payload.lastRecordSize + action.payload.requestRecordSize;
			if (action.payload.requestRecordSize < 0)
				action.payload.requestRecordSize = 1;
			state.requestRecordSize = action.payload.requestRecordSize;
			state.isLoading = false;
			state.chatroomId = action.payload.chatroomId;
		},
		CONVERSATION_LAST_CONVERSATION: (state, action) => {
			state.conversationId = action.payload.conversationId;
		},
		CONVERSATION_STATUS_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.searchResult = [...action.payload.searchResult];
			state.searchParams = action.payload.searchParams;
			state.lastRecordSize =
				state.lastRecordSize + action.payload.requestRecordSize;
			state.requestRecordSize = action.payload.requestRecordSize;
			state.isLoading = false;
			state.chatroomId = action.payload.chatroomId;
		},
		CONVERSATION_READ_ID: (state, action) => {
			state.readId = action.payload.readId;
		},
		CONVERSATION_CONVERSATION_ID: (state, action) => {
			state.conversationId = action.payload.conversationId;
		},
	},
});

const {
	CONVERSATION_LOADING,
	CONVERSATION_CREATING,
	CONVERSATION_SEARCH_SUCCESS,
	CONVERSATION_SEARCH_END,
	DEFAULT_CONVERSATION_SEARCH,
	CONVERSATION_SEARCH_FAIL,
	CONVERSATION_ADD_SUCCESS,
	CONVERSATION_CHECK_UNREAD,
	CONVERSATION_CHECK_UNREAD_END,
	CONVERSATION_CHECK_UNREAD_FAIL,
	CONVERSATION_UPDATE_SUCCESS,
	CONVERSATION_DELETE_SUCCESS,
	// CONVERSATION_CHECK_PROFILE,
	CONVERSATION_NEW_SUCCESS,
	CONVERSATION_LAST_CONVERSATION,
	CONVERSATION_STATUS_SUCCESS,
	CONVERSATION_READ_ID,
	CONVERSATION_CONVERSATION_ID,
	CONVERSATION_DELETE_FAIL,
	CONVERSATION_ROOM_LOADING,
} = messageConversationSlice.actions;

export const checkMessageUnread = ({ acctId, accessToken, email }) => async (
	dispatch
) => {
	const body = {
		acctId,
		accessToken,
		email,
	};
	// console.log(body)
	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/mm/check-unread-message`,
		params: body,
	})
		.then((res) => {
			// console.log(res)
			if (res.status === 201) {
				const response = {
					searchCount: res.data.payload.messageCount,
					token: res.data.token,
				};
				dispatch(CONVERSATION_CHECK_UNREAD(response));
			} else if (res.status === 202) {
				const response = {
					searchCount: res.data.payload.messageCount,
					token: res.data.token,
				};
				dispatch(CONVERSATION_CHECK_UNREAD_END(response));
			} else {
				dispatch(CONVERSATION_CHECK_UNREAD_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
			if (err.message == "Network Error") {
				// dispatch(logout(1));
			}
		});
};

export const messageConversationInfo = ({
	acctId,
	accessToken,
	email,
	lastRecordSize,
	requestRecordSize,
	chatroomId,
	searchResult,
	keyword,
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		email,
		lastRecordSize,
		requestRecordSize,
		chatroomId,
		keyword,
	};
	// dispatch(CONVERSATION_LOADING());
	dispatch(CONVERSATION_ROOM_LOADING());
	if (lastRecordSize === 0) {
		dispatch(DEFAULT_CONVERSATION_SEARCH());
	}

	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/mm/get-conversation`,
		params: body,
	})
		.then((res) => {
			// console.log(res);
			// console.log(searchResult);
			if (res.status === 201) {
				if (searchResult != undefined) {
					if (res.data.payload.conversationList.length > 0) {
						for (var i = 0; i < res.data.payload.conversationList.length; i++) {
							searchResult = searchResult.filter(
								(item) =>
									item.conversationId !=
									res.data.payload.conversationList[i].conversationId
							);
						}
					}
				}
				const response = {
					searchResult,
					conversationList: res.data.payload.conversationList,
					lastRecordSize,
					requestRecordSize: res.data.payload.conversationList.length,
					token: res.data.token,
					chatroomId: chatroomId,
				};
				dispatch(CONVERSATION_SEARCH_SUCCESS(response));
				if (res.data.payload.conversationList.length > 0) {
					const response = {
						conversationId: res.data.payload.conversationList[0].conversationId,
					};
					dispatch(CONVERSATION_LAST_CONVERSATION(response));
				}
				// console.log(requestRecordSize, res.data.payload.conversationList.length)
				if (requestRecordSize > res.data.payload.conversationList.length) {
					dispatch(CONVERSATION_SEARCH_END());
				}
			} else if (res.status === 202) {
				dispatch(CONVERSATION_SEARCH_END());
			} else {
				dispatch(CONVERSATION_SEARCH_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const messageConversationByReadId = ({
	acctId,
	accessToken,
	email,
	lastRecordSize,
	requestRecordSize,
	readId,
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		email,
		lastRecordSize,
		requestRecordSize,
		readId,
	};
	dispatch(CONVERSATION_LOADING());
	if (lastRecordSize === 0) {
		dispatch(DEFAULT_CONVERSATION_SEARCH());
	}

	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/mm/get-conversation-by-read-id`,
		params: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				var recordCount = 15;
				if (res.data.payload.recordCount > 15)
					recordCount = res.data.payload.recordCount;

				const requestProfile = {
					acctId: acctId,
					email: email,
					accessToken: res.data.token,
					lastRecordSize: 0,
					requestRecordSize: recordCount,
					chatroomId: res.data.payload.chatroomId,
					keyword: "",
				};
				dispatch(messageChatroomListInfo(requestProfile));
				dispatch(messageConversationInfo(requestProfile));
				if (res.data.payload.recordCount > 15) {
					const response = {
						readId: readId,
					};
					dispatch(CONVERSATION_READ_ID(response));
				}
			} else if (res.status === 202) {
				dispatch(CONVERSATION_SEARCH_END());
			} else {
				dispatch(CONVERSATION_SEARCH_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const messageConversationByConversationId = ({
	acctId,
	accessToken,
	email,
	lastRecordSize,
	requestRecordSize,
	conversationId,
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		email,
		lastRecordSize,
		requestRecordSize,
		conversationId,
	};
	dispatch(CONVERSATION_LOADING());
	if (lastRecordSize === 0) {
		dispatch(DEFAULT_CONVERSATION_SEARCH());
	}

	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/mm/get-conversation-by-conversation-id`,
		params: body,
	})
		.then((res) => {
			// console.log(res);
			if (res.status === 201) {
				var recordCount = 15;
				if (res.data.payload.recordCount > 15)
					recordCount = res.data.payload.recordCount;

				const requestProfile = {
					acctId: acctId,
					email: email,
					accessToken: res.data.token,
					lastRecordSize: 0,
					requestRecordSize: recordCount,
					chatroomId: res.data.payload.chatroomId,
					keyword: "",
				};
				dispatch(messageChatroomListInfo(requestProfile));
				dispatch(messageConversationInfo(requestProfile));
				if (res.data.payload.recordCount > 15) {
					const response = {
						conversationId: conversationId,
					};
					dispatch(CONVERSATION_CONVERSATION_ID(response));
				}
			} else if (res.status === 202) {
				dispatch(CONVERSATION_SEARCH_END());
			} else {
				dispatch(CONVERSATION_SEARCH_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const messageConversationByReadIdReset = () => async (dispatch) => {
	const response = {
		readId: 0,
	};
	dispatch(CONVERSATION_READ_ID(response));
};

export const messageConversationByConversationIdReset = () => async (
	dispatch
) => {
	const response = {
		conversationId: 0,
	};
	dispatch(CONVERSATION_CONVERSATION_ID(response));
};

export const messageConversationByReplyIdInfo = ({
	acctId,
	accessToken,
	email,
	chatroomId,
	searchResult,
	conversationId,
	replyId,
	lastRecordSize,
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		email,
		chatroomId,
		conversationId,
		replyId,
	};
	dispatch(CONVERSATION_LOADING());

	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/mm/get-conversation-by-reply-id`,
		params: body,
	})
		.then((res) => {
			console.log(res);
			// console.log(searchResult);
			// console.log(res.data.payload.conversationList);
			if (res.status === 201) {
				if (searchResult != undefined) {
					if (res.data.payload.conversationList.length > 0) {
						for (var i = 0; i < res.data.payload.conversationList.length; i++) {
							searchResult = searchResult.filter(
								(item) =>
									item.conversationId !=
									res.data.payload.conversationList[i].conversationId
							);
						}
					}
				}
				// console.log(searchResult);
				const response = {
					searchResult,
					lastRecordSize: 0,
					conversationList: res.data.payload.conversationList,
					requestRecordSize: res.data.payload.conversationList.length,
					token: res.data.token,
					chatroomId: chatroomId,
				};
				dispatch(CONVERSATION_NEW_SUCCESS(response));
				if (res.data.payload.conversationList.length > 0) {
					const response = {
						conversationId: res.data.payload.conversationList[0].conversationId,
					};
					dispatch(CONVERSATION_LAST_CONVERSATION(response));
				}
			} else if (res.status === 202) {
				dispatch(CONVERSATION_SEARCH_END());
			} else {
				dispatch(CONVERSATION_SEARCH_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const messageNewConversationInfo = ({
	acctId,
	accessToken,
	email,
	conversationId,
	chatroomId,
	searchResult,
	lastRecordSize,
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		email,
		conversationId,
		chatroomId,
	};
	dispatch(CONVERSATION_LOADING());
	// if (lastRecordSize === 0) {
	//   dispatch(DEFAULT_CONVERSATION_SEARCH());
	// }
	console.log(body);
	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/mm/get-new-conversation`,
		params: body,
	})
		.then((res) => {
			// console.log(res);
			if (res.status === 201) {
				// console.log(searchResult);
				// console.log(res.data.payload.conversationList);
				if (res.data.payload.conversationList.length > 0) {
					for (var i = 0; i < res.data.payload.conversationList.length; i++) {
						searchResult = searchResult.filter(
							(item) =>
								item.conversationId !=
								res.data.payload.conversationList[i].conversationId
						);
					}
				}
				console.log(searchResult);
				const response = {
					searchResult,
					lastRecordSize,
					conversationList: res.data.payload.conversationList,
					token: res.data.token,
					chatroomId: chatroomId,
					requestRecordSize: res.data.payload.conversationList.length,
				};
				dispatch(CONVERSATION_NEW_SUCCESS(response));
				if (res.data.payload.conversationList.length > 0) {
					const response = {
						conversationId: res.data.payload.conversationList[0].conversationId,
					};
					dispatch(CONVERSATION_LAST_CONVERSATION(response));
				}
				// console.log(requestRecordSize, res.data.payload.conversationList.length)
				// if (requestRecordSize > res.data.payload.conversationList.length) {
				//   dispatch(CONVERSATION_SEARCH_END());
				// }
			} else if (res.status === 202) {
				dispatch(CONVERSATION_SEARCH_END());
			} else {
				dispatch(CONVERSATION_SEARCH_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const messageSenderConversationInfo = ({
	acctId,
	accessToken,
	email,
	conversationId,
	chatroomId,
	searchResult,
	lastRecordSize,
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		email,
		conversationId,
		chatroomId,
	};
	dispatch(CONVERSATION_LOADING());
	// if (lastRecordSize === 0) {
	//   dispatch(DEFAULT_CONVERSATION_SEARCH());
	// }

	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/mm/get-sender-conversation`,
		params: body,
	})
		.then((res) => {
			// console.log(res);
			if (res.status === 201) {
				// console.log(searchResult);
				// console.log(res.data.payload.conversationList);
				if (res.data.payload.conversationList.length > 0) {
					for (var i = 0; i < res.data.payload.conversationList.length; i++) {
						searchResult = searchResult.filter(
							(item) =>
								item.conversationId !=
								res.data.payload.conversationList[i].conversationId
						);
					}
				}
				var count = res.data.payload.conversationList.length;
				// if (searchResult.length > 150) {
				// 	count =
				// 		100 -
				// 		searchResult.length +
				// 		res.data.payload.conversationList.length;
				// 	searchResult = searchResult.splice(0, 100);
				// }
				// console.log(searchResult);
				const response = {
					searchResult,
					lastRecordSize,
					conversationList: res.data.payload.conversationList,
					token: res.data.token,
					chatroomId: chatroomId,
					requestRecordSize: count,
				};
				dispatch(CONVERSATION_NEW_SUCCESS(response));
				if (res.data.payload.conversationList.length > 0) {
					const response = {
						conversationId: res.data.payload.conversationList[0].conversationId,
					};
					dispatch(CONVERSATION_LAST_CONVERSATION(response));
				}
				// console.log(requestRecordSize, res.data.payload.conversationList.length)
				// if (requestRecordSize > res.data.payload.conversationList.length) {
				//   dispatch(CONVERSATION_SEARCH_END());
				// }
			} else if (res.status === 202) {
				dispatch(CONVERSATION_SEARCH_END());
			} else {
				dispatch(CONVERSATION_SEARCH_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const messageConversationStatusInfo = ({
	acctId,
	accessToken,
	email,
	conversationId,
	chatroomId,
	searchResult,
}) => async (dispatch) => {
	var newSearchResult = [];
	// console.log(searchResult);
	if (searchResult.length > 0) {
		for (var i = 0; i < searchResult.length; i++) {
			var conversationData = {
				conversationId: searchResult[i].conversationId,
				acctId: searchResult[i].acctId,
				fullName: searchResult[i].fullName,
				companyName: searchResult[i].companyName,
				description: searchResult[i].description,
				status: searchResult[i].status,
				statusName: "read",
				createDate: searchResult[i].createDate,
				mediaName: searchResult[i].mediaName,
				mediaPath: searchResult[i].mediaPath,
				mediaStatus: searchResult[i].mediaStatus,
				replyId: searchResult[i].replyId,
				replyTitle: searchResult[i].replyTitle,
				replyDescription: searchResult[i].replyDescription,
				updateCount: 0,
			};

			newSearchResult.push(conversationData);
		}
	}
	// console.log(newSearchResult);
	const response = {
		searchResult: newSearchResult,
		token: accessToken,
		chatroomId: chatroomId,
		requestRecordSize: newSearchResult.length,
	};
	dispatch(CONVERSATION_STATUS_SUCCESS(response));
};

export const createConversation = (
	// acctId,
	// accessToken,
	// email,
	// chatroomId,
	// description,
	// chatroomList,
	// replyConversationId,
	data,
	chatroomId
) => async (dispatch) => {
	// const body = {
	// 	acctId,
	// 	accessToken,
	// 	email,
	// 	chatroomId,
	// 	description,
	// 	chatroomList,
	// 	replyConversationId,
	// };
	// console.log(body);
	dispatch(CONVERSATION_CREATING());
	await axios({
		method: "POST",
		headers: headerFormData,
		url: `${instanceUrl}/api/mm/create-conversation`,
		data: data,
	})
		.then((res) => {
			// console.log(res);

			if (res.status === 201) {
				const response = {
					token: res.data.token,
					chatroomId: chatroomId,
				};
				dispatch(CONVERSATION_ADD_SUCCESS(response));

				const conversationId = {
					conversationId: res.data.payload.recordId,
				};
				dispatch(CONVERSATION_LAST_CONVERSATION(conversationId));
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const updateConversation = ({
	acctId,
	accessToken,
	email,
	conversationId,
	conversationStatus,
}) => async (dispatch) => {
	dispatch(CONVERSATION_LOADING());
	const body = {
		acctId,
		accessToken,
		email,
		conversationId,
		conversationStatus,
	};
	console.log(body);
	await axios({
		method: "PUT",
		headers: header,
		url: `${instanceUrl}/api/mm/update-conversation-status`,
		data: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				const response = {
					conversationId,
					token: res.data.token,
				};
				dispatch(CONVERSATION_UPDATE_SUCCESS(response));
			} else {
				dispatch(CONVERSATION_SEARCH_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const deleteConversation = ({
	acctId,
	accessToken,
	email,
	conversationId,
	chatroomList,
	searchResult,
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		email,
		conversationId,
		chatroomList,
		searchResult,
	};
	await axios({
		method: "DELETE",
		headers: header,
		url: `${instanceUrl}/api/mm/delete-conversation`,
		data: body,
	})
		.then((res) => {
			// console.log(res);
			if (res.status === 201) {
				searchResult = searchResult.filter(
					(item) => item.conversationId != conversationId
				);
				const response = {
					searchResult,
					conversationId,
					token: res.data.token,
					isDeleted: true,
				};
				dispatch(CONVERSATION_DELETE_SUCCESS(response));
			} else {
				const response = {
					isDeleted: false,
				};
				dispatch(CONVERSATION_DELETE_FAIL(response));
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const deleteConversationReset = ({ isDeleted }) => async (dispatch) => {
	const response = {
		isDeleted: isDeleted,
	};
	dispatch(CONVERSATION_DELETE_FAIL(response));
};

export const getConversationRead = ({
	acctId,
	accessToken,
	email,
	lastRecordSize,
	requestRecordSize,
	chatroomId,
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		email,
		lastRecordSize,
		requestRecordSize,
		chatroomId,
	};
	dispatch(CONVERSATION_LOADING());
	if (lastRecordSize === 0) {
		dispatch(DEFAULT_CONVERSATION_SEARCH());
	}

	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/mm/get-conversation-read`,
		params: body,
	})
		.then((res) => {
			if (res.status === 201) {
				const response = {
					searchResult: res.data.payload.conversationList,
					lastRecordSize,
					requestRecordSize: res.data.payload.conversationList.length,
					token: res.data.token,
				};
				dispatch(CONVERSATION_SEARCH_SUCCESS(response));
				if (requestRecordSize > res.data.payload.conversationList.length) {
					dispatch(CONVERSATION_SEARCH_END());
				}
			} else if (res.status === 202) {
				dispatch(CONVERSATION_SEARCH_END());
			} else {
				dispatch(CONVERSATION_SEARCH_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const updateConversationReadStatus = ({
	acctId,
	accessToken,
	email,
	conversationId,
	conversationStatus,
}) => async (dispatch) => {
	dispatch(CONVERSATION_LOADING());
	const body = {
		acctId,
		accessToken,
		email,
		conversationId,
		conversationStatus,
	};
	console.log(body);
	await axios({
		method: "PUT",
		headers: header,
		url: `${instanceUrl}/api/mm/update-conversation-read-status`,
		data: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				const response = {
					conversationId,
					token: res.data.token,
				};
				dispatch(CONVERSATION_UPDATE_SUCCESS(response));
			} else {
				dispatch(CONVERSATION_SEARCH_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const deleteConversationRead = ({
	acctId,
	accessToken,
	email,
	conversationId,
	searchResult,
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		email,
		conversationId,
		// searchResult,
	};
	await axios({
		method: "DELETE",
		headers: header,
		url: `${instanceUrl}/api/mm/delete-conversation-read`,
		data: body,
	})
		.then((res) => {
			if (res.status === 201) {
				searchResult = searchResult.filter(
					(item) => item.conversationId != conversationId
				);
				const response = {
					searchResult,
					conversationId,
					token: res.data.token,
				};
				dispatch(CONVERSATION_DELETE_SUCCESS(response));
			} else {
				dispatch(CONVERSATION_SEARCH_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const messageConversationReset = () => async (dispatch) => {
	dispatch(DEFAULT_CONVERSATION_SEARCH());
};
export default messageConversationSlice.reducer;
