import React from "react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import TestimonialMenuComponent from "./TestimonialMenuComponent.js";
import Skeleton from "@mui/material/Skeleton";
import Avatar from "@mui/material/Avatar";
import { Button, makeStyles, Typography } from "@material-ui/core";
import testimonialCardStyles from "assets/jss/material-kit-pro-react/views/pageStyle/TestimonialsPage/testimonialCardStyles.js";

const useStyles = makeStyles(testimonialCardStyles);

export default function TestimonialLoadingCard() {
	const classes = useStyles();

	return (
		<Card className={classes.cardFrame}>
			<CardBody style={{ display: "flex" }} className={classes.cardBody}>
				<div>
					<Skeleton variant="circular" width={48} height={48}>
						<Avatar />
					</Skeleton>
				</div>

				<div style={{ width: "100%", marginLeft: "10px" }}>
					<Skeleton width="30%">.</Skeleton>
					<Skeleton width="40%">.</Skeleton>
					<Skeleton width="90%">.</Skeleton>
					<Skeleton width="90%">.</Skeleton>
				</div>
			</CardBody>
		</Card>
	);
}
