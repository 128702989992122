import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { departmentViewRoadmap, viewRoadmapRoleByRoleId } from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Industry_Finance from "assets/img/Career_Roadmap/Finance/Industry_Finance_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function IndustryFinance() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Industry_Finance;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "pf",
				shape: "poly",
				coords: [80,64,75,66,70,70,66,74,63,84,63,114,64,127,67,132,74,137,80,140,168,140,180,137,184,133,187,128,192,120,192,85,191,77,187,72,183,68,174,64,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "ir",
				shape: "poly",
				coords: [85,190,75,191,71,194,66,198,64,207,64,242,64,251,67,257,74,262,83,263,173,264,183,261,188,254,192,244,192,212,191,202,186,194,178,190,172,188,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "is",
				shape: "poly",
				coords: [83,321,73,323,68,328,64,336,63,377,66,385,72,392,80,395,177,394,184,391,190,385,193,377,193,336,190,330,186,325,181,321,175,320,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "pfm",
				shape: "poly",
				coords: [86,445,78,445,72,447,68,453,64,460,63,514,66,524,72,530,81,533,171,534,182,532,188,525,193,516,191,456,187,451,182,446,174,443,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "hf",
				shape: "poly",
				coords: [85,582,73,585,66,591,64,601,63,640,66,646,72,651,81,656,174,657,183,654,188,648,192,638,193,598,190,590,185,585,178,582,173,581,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "a",
				shape: "poly",
				coords: [91,713,76,714,70,717,67,722,64,732,64,768,66,776,73,783,78,788,174,790,183,785,190,777,194,771,192,727,187,720,183,715,173,712,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "28",
				shape: "poly",
				coords: [214,64,211,65,208,67,205,71,204,76,203,128,206,133,210,137,216,140,354,141,358,138,363,134,364,130,366,124,366,79,365,73,362,70,360,67,356,65,351,64,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "49",
				shape: "poly",
				coords: [222,188,216,189,212,191,208,194,206,197,204,203,204,253,206,259,210,263,217,266,349,267,356,265,361,261,364,257,365,251,366,209,366,200,364,196,359,193,356,190,351,188,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "53",
				shape: "poly",
				coords: [226,320,220,319,213,322,210,325,206,329,205,334,204,379,206,385,208,391,212,395,218,397,351,396,358,395,362,393,364,389,366,383,366,334,365,328,362,325,358,322,353,320,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "43",
				shape: "poly",
				coords: [220,449,213,450,209,454,206,459,203,464,203,513,204,518,208,522,212,525,219,527,349,528,357,527,360,524,363,519,365,514,366,464,363,458,359,454,355,451,350,450,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "33",
				shape: "poly",
				coords: [221,578,214,579,209,582,207,587,206,593,206,638,206,644,208,648,212,651,218,655,354,655,362,652,364,649,366,645,368,638,368,592,366,586,362,581,358,579,353,578,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "38",
				shape: "poly",
				coords: [219,709,212,712,208,716,205,723,204,770,207,777,208,782,214,785,220,787,349,786,355,786,360,782,363,779,366,774,366,724,364,718,361,714,357,710,352,709,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "29",
				shape: "poly",
				coords: [446,64,442,65,437,67,435,70,430,73,428,79,428,124,430,133,432,137,436,142,443,143,571,143,580,143,584,138,588,134,590,130,590,79,587,74,583,69,578,66,571,64,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "50",
				shape: "poly",
				coords: [442,192,436,195,433,197,429,201,428,208,428,252,430,261,433,264,436,267,442,269,580,268,584,266,588,261,590,256,590,203,587,197,583,195,579,193,574,193,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "54",
				shape: "poly",
				coords: [444,323,437,325,433,328,430,333,428,340,429,382,430,388,432,393,438,398,444,401,573,401,581,399,585,396,588,393,591,388,592,337,590,332,587,329,583,325,579,324,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "44",
				shape: "poly",
				coords: [443,452,436,455,433,457,430,460,429,463,428,469,428,516,429,520,432,525,436,528,444,531,577,530,582,528,585,525,588,521,590,516,590,466,589,461,587,458,583,457,580,455,574,452,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "34",
				shape: "poly",
				coords: [579,582,575,584,571,587,568,591,567,598,566,641,568,647,569,651,573,654,576,657,581,658,710,658,718,657,722,653,726,650,728,644,728,593,726,588,722,584,719,583,714,581,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "39",
				shape: "poly",
				coords: [491,713,486,716,484,720,481,723,480,728,481,776,481,729,480,775,482,781,484,784,486,788,492,790,621,791,629,790,635,788,637,785,641,780,642,775,642,728,640,721,636,717,631,714,627,712,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "30",
				shape: "poly",
				coords: [674,68,668,68,664,70,660,73,658,77,656,82,656,128,657,135,660,139,664,143,670,145,796,145,807,144,812,141,816,138,818,132,818,80,816,74,813,71,808,69,802,67,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "51",
				shape: "poly",
				coords: [671,193,665,196,661,198,658,201,656,207,656,255,658,262,661,265,663,268,670,271,805,271,811,268,814,266,816,262,818,254,818,210,817,203,814,198,809,194,803,193,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "55",
				shape: "poly",
				coords: [668,325,663,327,661,329,658,333,657,339,656,388,659,394,662,398,668,400,674,402,803,401,810,401,813,398,817,394,819,389,818,338,817,333,813,329,809,326,805,324,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "45",
				shape: "poly",
				coords: [670,454,664,456,660,460,658,464,656,470,655,514,656,521,660,525,664,529,671,531,802,531,808,530,812,528,815,524,818,519,817,468,815,462,812,458,808,456,804,454,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "40",
				shape: "poly",
				coords: [787,714,780,715,776,719,774,722,772,730,771,773,773,779,775,785,779,787,784,791,917,791,925,789,929,786,932,781,934,777,933,728,932,723,929,718,924,715,920,714,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "31",
				shape: "poly",
				coords: [902,67,892,69,887,72,883,77,882,83,881,133,883,138,889,142,895,145,1027,145,1035,143,1038,140,1041,138,1043,133,1043,128,1043,82,1042,77,1039,73,1034,70,1027,68,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "52",
				shape: "poly",
				coords: [897,193,890,196,887,198,883,203,881,210,881,251,883,260,886,264,890,268,894,270,1027,270,1034,269,1038,266,1041,262,1043,258,1043,209,1041,202,1037,197,1031,195,1025,193,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "56",
				shape: "poly",
				coords: [902,324,893,325,890,327,886,330,884,334,882,341,883,385,884,391,886,395,890,399,895,402,1026,401,1033,401,1038,398,1041,393,1045,387,1044,337,1041,331,1038,327,1033,325,1026,325,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "46",
				shape: "poly",
				coords: [903,454,893,454,887,458,883,462,881,470,880,515,883,523,888,528,897,531,1026,531,1034,530,1039,527,1041,522,1043,517,1043,471,1041,462,1038,458,1034,456,1028,454,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "35",
				shape: "poly",
				coords: [977,582,969,584,966,588,964,590,962,599,962,644,964,651,966,654,970,657,976,659,1109,659,1114,658,1117,656,1121,652,1123,647,1124,596,1123,590,1121,587,1117,584,1113,582,1108,582,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "41",
				shape: "poly",
				coords: [1082,715,1077,717,1073,720,1070,725,1068,730,1068,778,1071,784,1074,789,1078,791,1084,793,1212,792,1221,790,1226,788,1229,784,1230,779,1230,730,1228,723,1224,719,1219,716,1213,715,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "32",
				shape: "poly",
				coords: [1121,67,1115,69,1111,71,1108,76,1106,81,1105,128,1108,137,1111,141,1116,144,1123,146,1255,145,1260,143,1263,140,1265,137,1267,131,1268,83,1266,76,1262,72,1258,69,1252,68,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "57",
				shape: "poly",
				coords: [1125,324,1117,326,1113,328,1108,333,1107,342,1106,382,1107,391,1110,396,1117,400,1125,402,1255,401,1260,399,1265,397,1267,393,1269,386,1268,337,1265,331,1263,328,1258,325,1254,324,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "47",
				shape: "poly",
				coords: [1120,456,1114,457,1110,459,1107,465,1105,471,1104,515,1106,521,1108,526,1114,529,1120,532,1251,531,1259,530,1262,529,1264,525,1267,516,1267,473,1266,466,1262,461,1258,457,1252,455,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "58",
				shape: "poly",
				coords: [1355,324,1344,325,1338,328,1334,332,1332,340,1332,383,1333,391,1336,396,1340,399,1348,401,1478,401,1485,399,1489,397,1492,393,1494,387,1495,344,1494,336,1491,331,1487,327,1479,324,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "48",
				shape: "poly",
				coords: [1349,455,1340,458,1336,462,1334,467,1333,516,1334,525,1339,529,1348,533,1478,532,1486,530,1489,528,1492,524,1494,518,1494,476,1494,466,1491,461,1486,457,1479,455,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "36",
				shape: "poly",
				coords: [1348,583,1342,585,1340,588,1336,593,1334,599,1334,642,1335,650,1338,655,1342,658,1349,661,1478,660,1486,658,1491,657,1494,651,1496,646,1497,600,1495,592,1493,588,1488,585,1483,583,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "42",
				shape: "poly",
				coords: [1355,715,1346,715,1342,717,1338,720,1335,725,1333,732,1333,778,1336,785,1342,790,1351,793,1476,791,1486,790,1491,785,1495,779,1496,733,1493,724,1491,720,1486,717,1480,715,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "59",
				shape: "poly",
				coords: [1697,324,1687,324,1683,326,1680,329,1677,333,1675,339,1676,388,1678,393,1681,397,1685,398,1689,400,1823,401,1829,399,1831,397,1834,393,1836,391,1837,385,1838,338,1836,331,1833,329,1828,326,1821,323,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "37",
				shape: "poly",
				coords: [1693,582,1687,584,1682,586,1679,589,1677,594,1675,600,1675,643,1676,651,1679,654,1683,659,1690,661,1870,660,1875,658,1879,654,1883,650,1884,644,1883,601,1882,594,1880,590,1876,587,1872,584,1866,583,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "pf") {
			dispatch(departmentViewRoadmap(6));
		} else if (area.name === "ir") {
			dispatch(departmentViewRoadmap(10));
		} else if (area.name === "is") {
			dispatch(departmentViewRoadmap(11));
		} else if (area.name === "pfm") {
			dispatch(departmentViewRoadmap(9));
		} else if (area.name === "hf") {
			dispatch(departmentViewRoadmap(7));
		} else if (area.name === "a") {
			dispatch(departmentViewRoadmap(8));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo)
			dispatch(viewRoadmapRoleByRoleId(requestInfo));
			
			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// console.log(coords.x + "," + coords.y + ",");
		// navigator.clipboard.writeText(coords.x + "," + coords.y + ",");
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
