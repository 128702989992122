import React from "react";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";

import paginationStyle from "assets/jss/material-kit-pro-react/views/componentStyle/paginationStyle.js";
const useStyles = makeStyles(paginationStyle);
export default function CustomPagination({
	postsPerPage,
	totalPosts,
	paginate,
}) {
	const classes = useStyles();

	const pageNumbers = [];
	const [page, setPage] = React.useState(1);

	for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
		pageNumbers.push(i);
	}
	const handleChange = (event, value) => {
		setPage(value);
		paginate(value);
		console.log(value);
	};
	return (
		<div>
			<Pagination
				className={classes.paginationContainer}
				count={pageNumbers.length}
				page={page}
				onChange={handleChange}
				variant="outlined"
				shape="rounded"
			/>
		</div>
	);
}
