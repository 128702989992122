/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";

import {
	Card,
	CardContent,
	Typography,
	Button,
	Input,
	IconButton,
	makeStyles,
	Popover,
	ClickAwayListener,
	Grow,
	Paper,
	Popper,
} from "@material-ui/core";

import { ReactComponent as HandClapIcon } from "assets/svg/clapping.svg";
import { ReactComponent as SendIcon } from "assets/svg/send-svgrepo-com.svg";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import MoodBadOutlinedIcon from "@mui/icons-material/MoodBadOutlined";
import SentimentVerySatisfiedOutlinedIcon from "@mui/icons-material/SentimentVerySatisfiedOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import cross from "assets/img/cross.png";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";

import dotBtn from "assets/images/dotBtn.png";
import candidate_avatar from "../../../assets/img/candidate_avatar.jpeg";

import commentStyle from "../../../assets/jss/material-kit-pro-react/views/componentStyle/socialFeed/commentStyles";
import profileViewActivityCardStyles from "assets/jss/material-kit-pro-react/views/componentStyle/profileView/myActivity/profileViewActivityCardStyles.js";
import { useSelector, useDispatch } from "react-redux";
import ReportModal from "../ReportModal.js";
import { TimeFormat } from "configurations/configurations.js";
import {
	createSocialComment,
	updateSocialComment,
	getSocialCommentByPostId,
	getSocialPostByPostId,
	createCommentReaction,
	deleteCommentReaction,
	deleteSocialComment,
	resetDeleteCommentId,
	getSocialHashtag,
	getSocialProfileTag,
	createSocialHashtag,
	resetCreatedHashtag,
	resetComment,
	getSocialReplyByCommentId,
	getCommentReactionCount,
	getSocialProfileTagByGroupId,
} from "configurations/redux/socialFeedSlice.js";
import { createReport, resetReport } from "configurations/redux/reportSlice.js";
import CommentSkeleton from "../SocialFeed/CommentSkeleton.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Picker from "emoji-picker-react";
import { isMobile } from "react-device-detect";
import CommentImageModal from "./CommentImageModal.js";
import {
	dataURLtoFile,
	TimeFormatUniqueId,
	encryptData,
	getSocialReaction,
} from "configurations/configurations.js";
import { Link } from "react-router-dom";
import "quill-mention";
import "react-quill/dist/quill.bubble.css";
import ReactQuill, { Quill } from "react-quill";
import CommentInput from "./CommentInput.js";
import ReactionUserListModal from "./ReactionUserListModal";
import CommentMediaModal from "./CommentMediaModal";
import { useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import clapIcon from "assets/img/icons/clap.png";
import happyIcon from "assets/img/icons/happy.png";
import loveIcon from "assets/img/icons/love.png";
import likeIcon from "assets/img/icons/like.png";
import sadIcon from "assets/img/icons/sad.png";
import angryIcon from "assets/img/icons/angry.png";

import CardBody from 'components/Card/CardBody.js';
import ProfileImg from 'assets/img/profileImg.jpg' ;
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
const CommentNew = ({
	comment,
	postId,
	profilePic,
	commentCount,
	reportReasonsList,
	sectionCount,
	isCopyPost,
}) => {
	const quillRef = React.useRef(null);
	const editQuillRef = React.useRef(null);
	const classes = makeStyles(commentStyle)();
	const styles = makeStyles(profileViewActivityCardStyles)();
	const user = useSelector((state) => state.auth.user);
	const dispatch = useDispatch();
	const anchorRef = React.useRef(null);
	const [reportSuccessResponse, setReportSuccessResponse] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const [anchorElEmoji, setAnchorElEmoji] = useState(null);
	const [replyList, setReplyList] = useState([]);
	const [reportModal, setReportModal] = useState(false);
	const [replyClicked, setReplyClicked] = useState(false);
	const [reply, setReply] = React.useState("");
	const [skeletonShow, setSkeletonShow] = React.useState(false);
	const [showReactionPaper, setShowReactionPaper] = useState(false);
	const searchResult = useSelector((state) => state.socialFeed.searchResult);
	const postResult = useSelector((state) => state.socialFeed.postResult);
	const isOnAmend = useSelector((state) => state.socialFeed.isOnAmendReply);
	const isCreating = useSelector((state) => state.socialFeed.isCreatingReply);
	const amendPostId = useSelector((state) => state.socialFeed.amendPostId);
	const amendCommentId = useSelector(
		(state) => state.socialFeed.amendCommentId
	);
	const likeId = useSelector((state) => state.socialFeed.commentLikeId);
	const [likeColor, setLikeColor] = React.useState("#92929d");
	const [reactionCount, setReactionCount] = React.useState(null);
	const [likeShow, setLikeShow] = React.useState(
		comment.ownerLike ? comment.ownerLike.commentReactionUserList.length : 0
	);
	const [reactionId, setReactionId] = React.useState(
		comment.ownerLike
			? comment.ownerLike.commentReactionUserList.length > 0
				? comment.ownerLike.commentReactionUserList[0].reactionId
				: null
			: null
	);
	let fileInput = React.createRef();
	const [commentImageModal, setCommentImageModal] = React.useState(false);
	const [commentImage, setCommentImage] = React.useState(null);
	const [commentImageSaved, setCommentImageSaved] = React.useState(null);
	const [commentImageIsEdit, setCommentImageIsEdit] = React.useState(false);
	const [fileName, setFileName] = React.useState(null);
	const isDeletedComment = useSelector(
		(state) => state.socialFeed.isDeletedComment
	);
	const deletedCommentId = useSelector(
		(state) => state.socialFeed.deletedCommentId
	);
	const [editCommentShow, setEditCommentShow] = React.useState(null);
	const isLoadingReport = useSelector((state) => state.report.isLoading);
	const isCreatedReport = useSelector((state) => state.report.isCreated);
	const report = useSelector((state) => state.report.report);
	const hashtagList = useSelector((state) => state.socialFeed.hashtagList);
	const profileTagList = useSelector(
		(state) => state.socialFeed.profileTagList
	);
	const hashtagKeyword = useSelector(
		(state) => state.socialFeed.hashtagKeyword
	);
	const profileTagKeyword = useSelector(
		(state) => state.socialFeed.profileTagKeyword
	);
	const hashValue = useSelector((state) => state.socialFeed.hashValue);
	const createHashtagCommentId = useSelector(
		(state) => state.socialFeed.createHashtagCommentId
	);
	const enterKey = useSelector((state) => state.socialFeed.enterKey);
	const lastOpenCommentId = useSelector(
		(state) => state.socialFeed.lastOpenCommentId
	);
	const isLoadingReply = useSelector(
		(state) => state.socialFeed.isLoadingReply
	);
	const [commentWordNumber, setCommentWordNumber] = React.useState(null);
	const [commentText, setCommentText] = React.useState(null);
	const [commentContent, setCommentContent] = React.useState(null);
	const [commentHTML, setCommentHTML] = React.useState(null);
	const [emojiChoose, setEmojiChoose] = React.useState(null);
	const [submitComment, setSubmitComment] = React.useState(false);
	const [submitCommentId, setSubmitCommentId] = React.useState(null);
	const [loadedCommentId, setLoadedCommentId] = React.useState(null);
	const [reactionUserListModal, setReactionUserListModal] = React.useState(
		false
	);
	const [reactionList, setReactionList] = React.useState(null);
	const reportRecord = useSelector((state) => state.report.report);
	const groupId = useSelector((state) => state.socialFeed.groupId);
	const [isViewerOpen, setIsViewerOpen] = useState(false);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const snackBarShow = (message, variant, action) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(message, {
			variant: variant,
			action,
		});
	};
	const onClickDismiss = (key) => () => {
		closeSnackbar(key);
	};

	const snackbarCustom = (title) => {
		const action = (key) => (
			<React.Fragment>
				<IconButton onClick={onClickDismiss(key)}>
					<CloseIcon />
				</IconButton>
			</React.Fragment>
		);
		snackBarShow(title, "default", action);
	};
//Recommended Posts Data
  
const open = Boolean(anchorEl);
const handleClick = (event) => {
  setAnchorEl(event.currentTarget);
};
const handleClose = () => {
  setAnchorEl(null);
};
//End Recommended Posts Data
	useEffect(() => {
		if (submitComment) {
			postReply(submitCommentId);
			setSubmitCommentId(null);
			setSubmitComment(false);
		}
	}, [submitComment]);

	useEffect(() => {
		if (!isLoadingReply) setLoadedCommentId(null);
	}, [isLoadingReply]);

	const handleImageClick = () => {
		fileInput.current.value = null;
		fileInput.current.click();
	};

	const onEmojiClick = (event, emojiObject) => {
		if (emojiObject.emoji != "🦫") setEmojiChoose(emojiObject.emoji);
	};

	// const checkNewHashTag = (newHashtag, enterKey) => {
	// 	var splitText = newHashtag.split(" ");
	// 	for (var i = 0; i < splitText.length; i++) {
	// 		if (splitText[i].charAt(0) == "#") {
	// 			storeNewHashTag(splitText[i].substring(1).trim(), enterKey);
	// 		}
	// 	}
	// };

	// useEffect(() => {
	// 	// console.log(isDeletedComment, deletedCommentId);
	// 	if (isDeletedComment && deletedCommentId && deletedCommentId > 0) {
	// 		var comment = document.getElementById("comment_" + deletedCommentId);
	// 		if (comment) comment.innerHTML = "";
	// 		dispatch(resetDeleteCommentId());
	// 	}
	// }, [isDeletedComment, deletedCommentId]);

	const reportPost = (reportTypeId, reasonId, reportDesc, recordId) => {
		var requestProfile = {
			email: user.email,
			acctId: user.acctId,
			reportTypeId: reportTypeId, // 2 for report a social post
			recordId: recordId,
			reasonId: reasonId,
			reportDesc: reportDesc,
			userId: comment.acctId,
			accessToken: localStorage.getItem("token"),
		};
		dispatch(createReport(requestProfile));
	};

	const postReply = (commentId) => {
		// console.log(commentId);
		// console.log(commentText.trim().length, commentContent.ops[0].insert.length);
		// var descCheck = false;
		// if (commentText && commentContent.ops.length > 0) {
		// 	for (var i = 0; i < commentContent.ops.length; i++) {
		// 		try {
		// 			if (commentContent.ops[i].insert.mention) {
		// 				descCheck = true;
		// 				break;
		// 			} else if (
		// 				commentContent.ops[i].insert &&
		// 				commentContent.ops[i].insert.trim().length > 0
		// 			) {
		// 				descCheck = true;
		// 				break;
		// 			}
		// 		} catch (e) {}
		// 	}
		// }
		// console.log(descCheck);
		var descHTML = commentHTML;
		var descCount = commentWordNumber;
		var descContent = commentContent;
		// if (quillRefPush.current) {
		// 	var commentQuill = quillRefPush.current;
		// 	descHTML = commentQuill.state.value;
		// 	descCount =
		// 		commentQuill.state.selection.index +
		// 		commentQuill.state.selection.length;

		// 	descContent = commentQuill.editor.editor.delta;
		// }
		if (!descCount > 0) {
			snackbarCustom("The comment should not be empty.");
		} else if (descCount > 200) {
			snackbarCustom("The comment exceeding maximum 200 characters.");
		} else {
			// console.log(commentHTML);
			// console.log(commentContent);
			// console.log(commentText);
			var file = "";
			if (commentImageSaved) {
				console.log(TimeFormatUniqueId() + "." + fileName.split(".").pop());
				file = dataURLtoFile(
					commentImageSaved,
					TimeFormatUniqueId() + "." + fileName.split(".").pop()
				);
			}
			if (descHTML && descHTML.length > 0)
				descHTML = descHTML.replaceAll('target="_blank"', "");
			let commentFormData = new FormData();
			commentFormData.append("email", user.email);
			commentFormData.append("acctId", user.acctId);
			commentFormData.append("postId", postId);

			commentFormData.append("description", descHTML ? descHTML : ""); // comment text

			commentFormData.append("status", 1);
			commentFormData.append("attachments", file);
			var j = 0;
			var k = 0;
			if (descCount > 0) {
				for (let i = 0; i < descContent.ops.length; i++) {
					let mention = descContent.ops[i].insert.mention;
					// console.log(mention);
					if (mention) {
						if (mention.denotationChar == "#") {
							var hashtagName = mention.value
								.replace(/<a\b[^>]*>/i, "")
								.replace(/<\/a>/i, "");
							commentFormData.append(`hashTags[${j}][commentHashId]`, 0);
							commentFormData.append(
								`hashTags[${j}][hashtagName]`,
								hashtagName
							);
							j++;
						} else if (mention.denotationChar == "@") {
							commentFormData.append(`profileTags[${k}][commentProfileId]`, 0);
							commentFormData.append(
								`profileTags[${k}][profileId]`,
								mention.id
							);
							k++;
						}
					}
				}
			}

			commentFormData.append("accessToken", localStorage.getItem("token"));

			var postList = {
				postList: isCopyPost ? postResult : searchResult,
			};
			console.log(commentId);
			console.log(comment);

			if (commentId > 0) {
				commentFormData.append("commentId", commentId);
				commentFormData.append("parentId", comment ? comment.parentId : 0);
				console.log(...commentFormData);
				dispatch(
					updateSocialComment(
						commentFormData,
						postList,
						postId,
						comment.parentId ? comment.parentId : 0,
						0,
						isCopyPost
					)
				);
			} else {
				commentFormData.append("parentId", comment.commentId);
				console.log(...commentFormData);
				dispatch(
					createSocialComment(
						commentFormData,
						postList,
						postId,
						comment.commentId,
						0,
						isCopyPost
					)
				);
			}
			setCommentImageSaved(null);
			setReply("");
			setReplyClicked(false);
			setEditCommentShow(false);
		}
	};

	useEffect(() => {
		// let mounted = true;
		if (comment.ownerLike && comment.ownerLike.commentReactionUserList.length)
			setLikeColor("#6F52ED");
		// return () => (mounted = false);
		return () => {};
	}, []);

	useEffect(() => {
		let mounted = true;
		var loadingTimeout = null;
		if (mounted) {
			if (
				(isOnAmend || isCreating) &&
				amendPostId == postId &&
				amendCommentId == comment.commentId
			) {
				setSkeletonShow(true);
			} else {
				loadingTimeout = setTimeout(() => {
					setSkeletonShow(false);
				}, 700);
			}
		}

		return () => {
			mounted = false;
			clearTimeout(loadingTimeout);
		};
	}, [isOnAmend, isCreating]);

	const addLikeComment = (reactionId, reaction) => {
		const requestProfile = {
			acctId: user.acctId,
			accessToken: localStorage.getItem("token"),
			email: user.email,
			reactionId: reactionId,
			commentId: comment.commentId,
			postList: isCopyPost ? postResult : searchResult,
			postId: comment.postId,
			isCopyPost: isCopyPost,
		};
		console.log(requestProfile);
		dispatch(createCommentReaction(requestProfile));
		setLikeColor("#6F52ED");
		// setReactionCount(comment.reactionCount + 1);
		setReactionId(reactionId);
		setLikeShow(1);
	};

	const removeLikeComment = () => {
		const requestProfile = {
			acctId: user.acctId,
			accessToken: localStorage.getItem("token"),
			email: user.email,
			likeId:
				likeId && likeId > 0
					? likeId
					: comment.ownerLike.commentReactionUserList[0].likeId,
			postList: isCopyPost ? postResult : searchResult,
			postId: comment.postId,
			commentId: comment.commentId,
			isCopyPost: isCopyPost,
		};
		console.log(requestProfile);
		dispatch(deleteCommentReaction(requestProfile));
		setLikeColor("#92929d");
		// setReactionCount(comment.reactionCount - 1);
		setLikeShow(0);
	};

	const onFileChange = async (e) => {
		console.log(e);
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0];
			setCommentImage(file);
			setCommentImageIsEdit(false);
			setCommentImageModal(true);
		}
	};

	const editComment = (comment) => {
		dispatch(resetComment({ commentId: comment.commentId }));
		setEditCommentShow(true);
		setReplyClicked(false);
		setAnchorEl(null);
		var timer = setInterval(() => {
			console.log(editQuillRef);
			if (editQuillRef) {
				clearInterval(timer);
				let newQuill = new Quill("#quillEditor");
				let initialContent = newQuill.clipboard.convert(comment.description);
				editQuillRef.current.editor.setContents(initialContent, "silent");
				editQuillRef.current.editor.focus();
				editQuillRef.current.editor.setSelection(
					editQuillRef.current.editor.getLength(),
					editQuillRef.current.editor.getLength()
				);
			}
		}, 100);
	};

	const deleteComment = (comment) => {
		const requestProfile = {
			acctId: user.acctId,
			accessToken: localStorage.getItem("token"),
			email: user.email,
			commentId: comment.commentId,
			postList: isCopyPost ? postResult : searchResult,
			postId: comment.postId,
			parentId: comment.parentId,
			isCopyPost: isCopyPost,
		};
		console.log(requestProfile);
		dispatch(deleteSocialComment(requestProfile));
		setAnchorEl(null);
	};

	const storeNewHashTag = (name, enterKey) => {
		const requestProfile = {
			email: user.email,
			acctId: user.acctId,
			hashtagName: name,
			accessToken: localStorage.getItem("token"),
			commentId: comment.commentId,
			postId: null,
			enterKey: enterKey,
		};
		dispatch(createSocialHashtag(requestProfile));
	};

	const getHashTagList = (searchTerm) => {
		const profileRequest = {
			email: user.email,
			acctId: user.acctId,
			keyword: searchTerm,
			lastRecordSize: 0,
			requestRecordSize: 10,
			accessToken: localStorage.getItem("token"),
		};
		dispatch(getSocialHashtag(profileRequest));
	};

	const getProfileTagList = (searchTerm) => {
		const profileRequest = {
			email: user.email,
			acctId: user.acctId,
			keyword: searchTerm,
			groupId: groupId ? groupId : 0,
			lastRecordSize: 0,
			requestRecordSize: 10,
			accessToken: localStorage.getItem("token"),
		};
		if (groupId > 0) {
			dispatch(getSocialProfileTagByGroupId(profileRequest));
		} else {
			dispatch(getSocialProfileTag(profileRequest));
		}
	};

	const getReply = (lastRecordSize) => {
		setLoadedCommentId(comment.commentId);
		// if (lastRecordSize > 0) setLoadCommentStart(true);
		// console.log(commentsExpanded);
		// console.log(lastRecordSize);
		dispatch(resetComment({ commentId: null }));
		var requestProfile = {
			email: user.email,
			acctId: user.acctId,
			postId: postId,
			commentId: comment.commentId,
			lastRecordSize: lastRecordSize,
			requestRecordSize: 5,
			accessToken: localStorage.getItem("token"),
			postList: isCopyPost ? postResult : searchResult,
			isLoadMoreComment: true,
			isCreateComment: false,
			isDeleteComment: false,
			isCopyPost: isCopyPost,
		};
		console.log(requestProfile);
		dispatch(getSocialReplyByCommentId(requestProfile));
	};

	const fetchSocialReactionList = async () => {
		const request = {
			email: user.email,
			acctId: user.acctId,
			accessToken: localStorage.getItem("token"),
			commentId: comment.commentId,
			firstLoad: true,
		};
		console.log(request);
		dispatch(getCommentReactionCount(request));
		const res = await getSocialReaction();
		setReactionList(res.data.payload);
	};

	useEffect(() => {
		// console.log(reactionList);
		if (reactionList) setReactionUserListModal(true);
	}, [reactionList]);

	const [quillRefPush, setQuillRefPush] = React.useState(null);

	return (
		<div
			key={"comment_" + comment.commentId}
			className={classes.postSingleCommentWithReplies}
			id={"comment_" + comment.commentId}
		>
			{/* <div id="quillEditor" style={{ display: "none" }} /> */}
			<GridContainer>
				<GridItem className={classes.feedCommentFlex}>

                    {/* New Comment Section  */}
                            <Typography className='postDiv recommendPostOuter' component='div'>
                                <Typography className='postDivLeft'>
                                <Link
                                    to={{
                                        pathname: `/view-profile/${encryptData(comment.acctId)}`,
                                    }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <div className={classes.commenterProfilePic}>
                                        <img
                                            src={comment.profilePic ? comment.profilePic : candidate_avatar}
                                            alt=""
                                        />
                                    </div>
                                </Link>
                                </Typography>
                                <Typography className='postDivCenter'>
                                    <Typography className='postPostion'>
                                        <b>
                                            <Link
                                                style={{ color: "#000000" }}
                                                to={{
                                                    pathname: `/view-profile/${encryptData(comment.acctId)}`,
                                                }}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {comment.firstName}
                                                {comment.middleName ? " " + comment.middleName : ""}
                                                {comment.surname ? " " + comment.surname : ""}
                                            </Link>
                                        </b> 
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: comment.description.replaceAll(
                                                                'target="_blank"',
                                                                ""
                                                            ),
                                                        }}
                                                    />

                                            <div>
                                            
                                                {comment.attachments.attachmentList.length > 0 ? (
                                                    <div
                                                        className={classes.attachmentListItemDisplay}
                                                        onClick={() => setIsViewerOpen(true)}
                                                    >
                                                        <div
                                                            className={classes.attachmentListItemAttachmentContainer}
                                                        >
                                                            <img
                                                                src={comment.attachments.attachmentList[0].attachmentPath}
                                                                alt=""
                                                                className={classes.attachmentListItemContent}
                                                            />
                                                        </div>
                                                    </div>
                                                ) : null}
                                                {isViewerOpen && (
                                                    <CommentMediaModal
                                                        pictureSource={
                                                            comment.attachments.attachmentList[0].attachmentPath
                                                        }
                                                        onClose={() => {
                                                            setIsViewerOpen(false);
                                                        }}
                                                        open={isViewerOpen}
                                                    />
                                                )}
                                                {editCommentShow && lastOpenCommentId == comment.commentId ? (
                                                    <div className={styles.feedCommentBx}>
                                                        <div className={styles.commentInputWrap}>
                                                            <div className={classes.mentionsInput + ' Custom_comment_classname'}>
                                                                <CommentInput
                                                                    comment={comment}
                                                                    emojiChoose={emojiChoose}
                                                                    resetEmoji={setEmojiChoose}
                                                                    getLength={setCommentWordNumber}
                                                                    getText={setCommentText}
                                                                    getHTML={setCommentHTML}
                                                                    getContent={setCommentContent}
                                                                    onSubmit={setSubmitComment}
                                                                    submitCommentId={setSubmitCommentId}
                                                                    parentId={comment.commentId}
                                                                    quillRefPush={setQuillRefPush}
                                                                />
                                                            </div>
                                                            <div className={styles.commentActionBtns}>
                                                                
                                                                <IconButton onClick={handleImageClick}>
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="21"
                                                                        height="20"
                                                                        viewBox="0 0 21 20"
                                                                    >
                                                                        <path
                                                                            id="Shape"
                                                                            d="M17.5,20H3.5A3.431,3.431,0,0,1,0,16.65V3.35A3.431,3.431,0,0,1,3.5,0h14A3.431,3.431,0,0,1,21,3.35v13.3A3.431,3.431,0,0,1,17.5,20ZM15.286,8.775l-4.714,4.253L15.961,19H17.5A2.431,2.431,0,0,0,20,16.65V13.071Zm-8.139,1.95h0L1.009,16.849A2.458,2.458,0,0,0,3.5,19H14.613L7.148,10.725ZM3.5,1A2.431,2.431,0,0,0,1,3.35v12.1l5.814-5.8a.5.5,0,0,1,.724.019L9.9,12.286l5.051-4.557a.5.5,0,0,1,.671,0L20,11.718V3.35A2.431,2.431,0,0,0,17.5,1ZM6.691,6.7A1.925,1.925,0,1,1,8.619,4.775,1.929,1.929,0,0,1,6.691,6.7Zm0-2.849a.925.925,0,1,0,.929.925A.928.928,0,0,0,6.691,3.85Z"
                                                                            transform="translate(0 0)"
                                                                            fill="#92929d"
                                                                        />
                                                                    </svg>
                                                                </IconButton>
                                                                <div className={"fileinput text-center"}>
                                                                    <input
                                                                        type="file"
                                                                        onChange={onFileChange}
                                                                        accept="image/jpg, image/jpeg, image/gif, image/png, image/apng, image/bmp, image/gif"
                                                                        ref={fileInput}
                                                                    />
                                                                </div>
                                                                <IconButton
                                                                    onClick={() => {
                                                                        postReply(comment.commentId);
                                                                        // editQuillRef.current.editor.setText("");
                                                                    }}
                                                                >
                                                                    <SendIcon
                                                                        sx={{
                                                                            color: "#92929d",
                                                                            margin: "5px",
                                                                        }}
                                                                        className={styles.sendButton}
                                                                    />
                                                                </IconButton>
                                                            </div>
                                                            {commentImageSaved ? (
                                                                <div className={classes.attachmentListItem}>
                                                                    <img
                                                                        src={cross}
                                                                        alt=""
                                                                        className={classes.removeAttachment}
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            setCommentImageSaved(null);
                                                                        }}
                                                                    />
                                                                    <div
                                                                        className={
                                                                            classes.attachmentListItemAttachmentContainer
                                                                        }
                                                                    >
                                                                        <img
                                                                            src={commentImageSaved}
                                                                            alt=""
                                                                            className={classes.attachmentListItemContent}
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            classes.attachmentListItemAttachmentContainerOverlay
                                                                        }
                                                                        onClick={() => {
                                                                            setCommentImageIsEdit(true);
                                                                            setCommentImage(commentImageSaved);
                                                                            setCommentImageModal(true);
                                                                        }}
                                                                    ></div>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>
                                            {sectionCount <= 4 ? (
                                                <div className={classes.commentActions}>
                                                    <Button
                                                        style={{
                                                            color: likeColor,
                                                            textTransform: "capitalize",
                                                            padding: "1px 6px",
                                                        }}
                                                        ref={anchorRef}
                                                        onClick={() => {
                                                            if (isMobile) {
                                                                if (likeColor == "#6F52ED") removeLikeComment();
                                                                else {
                                                                    console.log(showReactionPaper);
                                                                    if (showReactionPaper) setShowReactionPaper(false);
                                                                    else setShowReactionPaper(true);
                                                                }
                                                            } else {
                                                                if (likeColor == "#92929d") setShowReactionPaper(true);
                                                                else removeLikeComment();
                                                            }
                                                        }}
                                                        onMouseEnter={() => {
                                                            if (!isMobile) setShowReactionPaper(true);
                                                        }}
                                                        onMouseLeave={() => {
                                                            if (!isMobile) setShowReactionPaper(false);
                                                        }}
                                                    >
                                                        <Popper
                                                            className={classes.likePopper}
                                                            anchorEl={anchorRef.current}
                                                            open={showReactionPaper && likeColor == "#92929d"}
                                                            role={undefined}
                                                            placement="top"
                                                            transition
                                                            disablePortal
                                                        >
                                                            {({ TransitionProps, placement }) => (
                                                                <ClickAwayListener
                                                                    onClickAway={() =>
                                                                        !isMobile ? setShowReactionPaper(false) : null
                                                                    }
                                                                >
                                                                    <Grow
                                                                        {...TransitionProps}
                                                                        style={{
                                                                            transformOrigin:
                                                                                placement === "top" ? "top" : "top",
                                                                        }}
                                                                    >
                                                                        <Paper>
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "row",
                                                                                    margin: "10px",
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={likeIcon}
                                                                                    alt=""
                                                                                    onClick={() => addLikeComment(1)}
                                                                                />
                                                                               
                                                                                <img
                                                                                    src={loveIcon}
                                                                                    alt=""
                                                                                    onClick={() => addLikeComment(2)}
                                                                                />
                                                                                
                                                                                <img
                                                                                    src={clapIcon}
                                                                                    alt=""
                                                                                    onClick={() => addLikeComment(3)}
                                                                                />
                                                                              
                                                                                <img
                                                                                    src={angryIcon}
                                                                                    alt=""
                                                                                    onClick={() => addLikeComment(4)}
                                                                                />
                                                                               
                                                                                <img
                                                                                    src={sadIcon}
                                                                                    alt=""
                                                                                    onClick={() => addLikeComment(5)}
                                                                                />
                                                                                
                                                                                <img
                                                                                    src={happyIcon}
                                                                                    alt=""
                                                                                    onClick={() => addLikeComment(6)}
                                                                                />
                                                                                
                                                                            </div>
                                                                        </Paper>
                                                                    </Grow>
                                                                </ClickAwayListener>
                                                            )}
                                                        </Popper>
                                                        {likeShow > 0 ? (
                                                            reactionId == 1 ? (
                                                                <img src={likeIcon} alt="" />
                                                            ) : 
                                                            reactionId == 2 ? (
                                                                <img src={loveIcon} alt="" />
                                                            ) : 
                                                            reactionId == 3 ? (
                                                                <img src={clapIcon} alt="" />
                                                            ) : 
                                                            reactionId == 4 ? (
                                                                <img src={angryIcon} alt="" />
                                                            ) : 
                                                            reactionId == 5 ? (
                                                                <img src={sadIcon} alt="" />
                                                            ) : (
                                                                
                                                                reactionId == 6 && (
                                                                    <img src={happyIcon} alt="" />
                                                                    
                                                                )
                                                            )
                                                        ) : (
                                                            "Like"
                                                        )}{" "}
                                                        {likeShow ? "Like" : null}
                                                    </Button>
                                                    {reactionCount || comment.reactionCount ? (
                                                        <span
                                                            className={classes.likeReaction}
                                                            // onClick={() => {
                                                            // 	fetchSocialReactionList();
                                                            // }}
                                                        >
                                                            {reactionCount ? reactionCount : comment.reactionCount}{" "}
                                                            {reactionCount > 1 || comment.reactionCount > 1
                                                                ? "Likes"
                                                                : "Like"}
                                                        </span>
                                                    ) : null}
                                                    {sectionCount <= 4 ? (
                                                        <div className={classes.verticalLine}></div>
                                                    ) : null}
                                                    {sectionCount <= 4 ? (
                                                        <button
                                                            style={{ color: "#6F52ED" }}
                                                            onClick={(e) => {
                                                                if (!replyClicked) {
                                                                    if (editCommentShow) {
                                                                        setEditCommentShow(false);
                                                                    }
                                                                    getHashTagList("");
                                                                    getProfileTagList("");
                                                                    setReplyClicked(true);
                                                                    dispatch(
                                                                        resetComment({ commentId: comment.commentId })
                                                                    );
                                                                } else setReplyClicked(false);
                                                            }}
                                                        >
                                                            Reply
                                                        </button>
                                                    ) : null}
                                                    {sectionCount <= 4 &&
                                                    comment.replyCount > 0 &&
                                                    comment.replyList.commentList &&
                                                    comment.replyCount > comment.replyList.commentList.length ? (
                                                        <span
                                                            className={classes.commentReplyClick}
                                                            onClick={() =>
                                                                getReply(
                                                                    comment.replyList.commentList
                                                                        ? comment.replyList.commentList.length
                                                                        : 0
                                                                )
                                                            }
                                                        >
                                                            {comment.replyCount}{" "}
                                                            {comment.replyCount > 1 ? "Replies" : "Reply"}
                                                        </span>
                                                    ) : null}
                                                </div>
                                            ) : (
                                                <div className={classes.horizontalLine}></div>
                                            )}

                                            {skeletonShow ? <CommentSkeleton /> : null}
                                            {replyClicked && lastOpenCommentId == comment.commentId ? (
                                                <div className={styles.feedCommentBx}>
                                                    <GridContainer>
                                                        <GridItem className={styles.feedCommentFlex}>
                                                            <div className={styles.feedCommentUserBx}>
                                                                <img src={profilePic} alt="" />
                                                            </div>
                                                            <div className={styles.commentInputWrap}>
                                                                <div className={classes.mentionsInput  + ' Custom_comment_classname'}>
                                                                    <CommentInput
                                                                        comment={comment}
                                                                        emojiChoose={emojiChoose}
                                                                        resetEmoji={setEmojiChoose}
                                                                        getLength={setCommentWordNumber}
                                                                        // getText={setCommentText}
                                                                        getHTML={setCommentHTML}
                                                                        getContent={setCommentContent}
                                                                        onSubmit={setSubmitComment}
                                                                        submitCommentId={setSubmitCommentId}
                                                                        parentId={0}
                                                                        quillRefPush={setQuillRefPush}
                                                                    />
                                                                </div>
                                                                <div className={styles.commentActionBtns}>
                                                                    
                                                                    <IconButton onClick={handleImageClick}>
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="21"
                                                                            height="20"
                                                                            viewBox="0 0 21 20"
                                                                        >
                                                                            <path
                                                                                id="Shape"
                                                                                d="M17.5,20H3.5A3.431,3.431,0,0,1,0,16.65V3.35A3.431,3.431,0,0,1,3.5,0h14A3.431,3.431,0,0,1,21,3.35v13.3A3.431,3.431,0,0,1,17.5,20ZM15.286,8.775l-4.714,4.253L15.961,19H17.5A2.431,2.431,0,0,0,20,16.65V13.071Zm-8.139,1.95h0L1.009,16.849A2.458,2.458,0,0,0,3.5,19H14.613L7.148,10.725ZM3.5,1A2.431,2.431,0,0,0,1,3.35v12.1l5.814-5.8a.5.5,0,0,1,.724.019L9.9,12.286l5.051-4.557a.5.5,0,0,1,.671,0L20,11.718V3.35A2.431,2.431,0,0,0,17.5,1ZM6.691,6.7A1.925,1.925,0,1,1,8.619,4.775,1.929,1.929,0,0,1,6.691,6.7Zm0-2.849a.925.925,0,1,0,.929.925A.928.928,0,0,0,6.691,3.85Z"
                                                                                transform="translate(0 0)"
                                                                                fill="#92929d"
                                                                            />
                                                                        </svg>
                                                                    </IconButton>
                                                                    <div className={"fileinput text-center"}>
                                                                        <input
                                                                            type="file"
                                                                            onChange={onFileChange}
                                                                            accept="image/jpg, image/jpeg, image/gif, image/png, image/apng, image/bmp, image/gif"
                                                                            ref={fileInput}
                                                                        />
                                                                    </div>
                                                                    <IconButton
                                                                        onClick={() => {
                                                                            postReply(0);
                                                                            // quillRef.current.editor.setText("");
                                                                        }}
                                                                    >
                                                                        <SendIcon
                                                                            sx={{
                                                                                color: "#92929d",
                                                                                margin: "5px",
                                                                            }}
                                                                            className={styles.sendButton}
                                                                        />
                                                                    </IconButton>
                                                                </div>
                                                                
                                                                {commentImageSaved ? (
                                                                    <div className={classes.attachmentListItem}>
                                                                        <img
                                                                            src={cross}
                                                                            alt=""
                                                                            className={classes.removeAttachment}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                setCommentImageSaved(null);
                                                                            }}
                                                                        />
                                                                        <div
                                                                            className={
                                                                                classes.attachmentListItemAttachmentContainer
                                                                            }
                                                                        >
                                                                            <img
                                                                                src={commentImageSaved}
                                                                                alt=""
                                                                                className={classes.attachmentListItemContent}
                                                                            />
                                                                        </div>
                                                                        <div
                                                                            className={
                                                                                classes.attachmentListItemAttachmentContainerOverlay
                                                                            }
                                                                            onClick={() => {
                                                                                setCommentImageIsEdit(true);
                                                                                setCommentImage(commentImageSaved);
                                                                                setCommentImageModal(true);
                                                                            }}
                                                                        ></div>
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </GridItem>
                                                    </GridContainer>
                                                </div>
                                            ) : null}

                                            {comment.replyCount > 0 && comment.replyList.commentList
                                                ? comment.replyList.commentList.map((reply, index) => (
                                                        <div key={reply.commentId}>
                                                            <CommentNew
                                                                comment={reply}
                                                                postId={postId}
                                                                profilePic={profilePic}
                                                                reportReasonsList={reportReasonsList}
                                                                sectionCount={sectionCount + 1}
                                                                commentCount={
                                                                    comment.replyList.commentList
                                                                        ? comment.replyList.commentList.length + commentCount
                                                                        : commentCount + 1
                                                                }
                                                                isCopyPost={isCopyPost}
                                                            />
                                                        </div>
                                                ))
                                                : null}
                                            {isLoadingReply && loadedCommentId == comment.commentId ? (
                                                <CommentSkeleton />
                                            ) : null}
                                            {sectionCount <= 4 &&
                                            comment.replyCount > 0 &&
                                            comment.replyList.commentList &&
                                            comment.replyCount > comment.replyList.commentList.length &&
                                            comment.replyList.commentList.length > 2 ? (
                                                <div className={classes.moreReplies}>
                                                    <button
                                                        onClick={() =>
                                                            getReply(
                                                                comment.replyList.commentList
                                                                    ? comment.replyList.commentList.length
                                                                    : 0
                                                            )
                                                        }
                                                    >
                                                        Load previous replies
                                                    </button>
                                                </div>
                                            ) : null}
                                    </Typography>
                                    {/*<ul className='likeUl'>
                                      <li><a href="#">Like</a></li>
                                      <li><a href="#">Reply</a></li>
													</ul>*/}
                                </Typography>
                                <Typography className='postDivRight'>
                                  <span className='timeSpan'>{TimeFormat(comment.lastUpdateDate)}</span>
                                  <Button
                                    id="basic-button"
                                    onClick={(e) => {
                                        setAnchorEl(e.currentTarget);
                                    }}
                                  >
                                    <i className="fa-solid fa-ellipsis"></i>
                                  </Button>
                                  <Menu
                                    id="basic-menu"
                                    MenuListProps={{
                                      'aria-labelledby': 'basic-button',
                                    }}
                                  >
                                    <MenuItem onClick={handleClose}>Profile</MenuItem>
                                    <MenuItem onClick={handleClose}>My account</MenuItem>
                                    <MenuItem onClick={handleClose}>Logout</MenuItem>
                                  </Menu>
                                </Typography>
                            </Typography>

                    {/* END  New Comment Section  */}
				</GridItem>
			</GridContainer>

			{reportModal && (
				<ReportModal
					open={reportModal}
					onClose={() => {
						setReportModal(false);
						setAnchorEl(null);
					}}
					recordType={"Report"}
					reportReasonsList={reportReasonsList}
					reportTypeId={3}
					recordId={comment.commentId}
					reportPost={reportPost}
				/>
			)}

			<Popover
				id={anchorEl ? "simple-popover" : undefined}
				open={anchorEl ? true : false}
				anchorEl={anchorEl}
				onClose={() => {
					setAnchorEl(null);
				}}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				transformOrigin={{ vertical: "top", horizontal: "right" }}
			>
				{user.acctId == comment.acctId ? (
					<div className={styles.popoverBox}>
						<Typography
							component="div"
							className={classes.popoverListItem}
							onClick={() => {
								if (editCommentShow) {
									dispatch(resetComment({ commentId: null }));
									setEditCommentShow(false);
								} else {
									dispatch(resetComment({ commentId: comment.commentId }));
									setCommentImageSaved(
										comment.attachments.attachmentList.length > 0
											? comment.attachments.attachmentList[0].attachmentPath
											: null
									);
									setEditCommentShow(true);
									// getHashTagList(comment);
									// editComment(comment);
								}
								setAnchorEl(null);
							}}
						>
							<Typography
								component="div"
								className={classes.popoverListItemIcon}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="21"
									height="21"
									viewBox="0 0 21 21"
								>
									<path
										id="icon-2"
										d="M4.709,20H1.176A1.178,1.178,0,0,1,0,18.824v-3.53a1.167,1.167,0,0,1,.345-.831L14.462.345a1.175,1.175,0,0,1,1.663,0l3.53,3.53a1.177,1.177,0,0,1,0,1.664L5.538,19.658A1.169,1.169,0,0,1,4.709,20Zm6.97-13.571h0L2.143,15.965v1.892H4.035L13.571,8.32,11.679,6.429Zm3.677-3.571h0L13.571,4.643l1.786,1.786,1.786-1.786L15.357,2.858Z"
										transform="translate(0 0)"
										fill="#b6b7b8"
									/>
								</svg>
							</Typography>
							<div className={classes.popoverListItemText}>
								<div className={classes.popoverListItemTitle}>
									{editCommentShow ? "Cancel Edit" : "Edit Comment"}
								</div>
							</div>
						</Typography>
						<Typography
							component="div"
							className={classes.popoverListItem}
							onClick={() => {
								deleteComment(comment);
							}}
						>
							<Typography
								component="div"
								className={classes.popoverListItemIcon}
							>
								<DeleteOutlineIcon
									sx={{
										color: "#b6b7b8",
									}}
								/>
							</Typography>
							<Typography
								component="div"
								className={classes.popoverListItemText}
							>
								<Typography
									component="div"
									className={classes.popoverListItemTitle}
								>
									Delete Comment
								</Typography>
							</Typography>
						</Typography>
					</div>
				) : (
					<div className={styles.popoverBox}>
						<Typography component="div" className={classes.popoverListItem}>
							<Typography
								component="div"
								className={classes.popoverListItemIcon}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="21"
									height="21"
									viewBox="0 0 21 21"
								>
									<path
										id="Shape"
										d="M13.437,0H5.563L0,5.563v7.874L5.563,19h7.874L19,13.437V5.563Z"
										transform="translate(1 1)"
										fill="#fff"
										stroke="#b6b7b8"
										strokeWidth="2"
									/>
									<g
										id="Group_1"
										data-name="Group 1"
										transform="translate(9.5 4.5)"
									>
										<circle
											id="Oval"
											cx="1"
											cy="1"
											r="1"
											transform="translate(0 10)"
											fill="#b6b7b8"
										/>
										<path id="Path" d="M0,0H2V8H0Z" fill="#b6b7b8" />
									</g>
								</svg>
							</Typography>
							<Typography
								component="div"
								className={classes.popoverListItemText}
							>
								<Typography
									component="div"
									className={classes.popoverListItemTitle}
									onClick={() => {
										setReportModal(!reportModal);
									}}
								>
									Report Comment
								</Typography>
							</Typography>
						</Typography>
					</div>
				)}
			</Popover>
			{commentImageModal ? (
				<CommentImageModal
					open={commentImageModal}
					onClose={() => {
						setCommentImageModal(false);
					}}
					pictureSource={commentImage}
					isEdit={commentImageIsEdit}
					fileName={(e) => {
						setFileName(e);
					}}
					saveCroppedPhoto={(e) => {
						setCommentImageModal(false);
						setCommentImageSaved(e);
					}}
				/>
			) : null}
			{reactionUserListModal ? (
				<ReactionUserListModal
					open={reactionUserListModal}
					onClose={() => {
						setReactionUserListModal(false);
					}}
					comment={comment}
					post={null}
					reactionList={reactionList}
					reactionType="comment"
				/>
			) : null}
		</div>
	);
};

export default CommentNew;
