const profileViewAboutMeStyles = () => ({
	main: {
		marginTop: "10px",
		borderRadius: "15px",
		backgroundColor: "#fff",
		padding: "10px 10px",
		width: "100%",
	},

	header: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},

	title: {
		color: "#171725",
		fontSize: "14px",
		fontWeight: 500,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
	},

	editBtn: {
		color: "#6F52ED",
		fontSize: "12px",
		fontWeight: 500,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
	},

	divider: {
		marginLeft: "-4%",
		width: "108%",
		borderColor: "#F1F1F5",
	},

	aboutMeText: {
		overflowWrap: "break-word",
		borderBottom: "solid 1px #F1F1F5",
		color: "#44444F",
		fontSize: "14px",
		padding: "0 15px 10px",
	},

	list: {
		marginTop: "10px",
	},

	listItem: {
		display: "flex",
		margin: "20px 0",

		"& > svg": {
			display: "flex",
			justifyContent: "center",
			minWidth: "30px",
			width: "30px",
		},
	},

	listItemTextContainer: {
		marginLeft: "10px",
	},

	listItemTextTitle: {
		color: "#171725",
		fontSize: "14px",
		fontWeight: 500,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
	},

	listItemTextDescription: {
		color: "#171725",
		fontSize: "14px",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,

		"& > span": {
			color: "#2B313D",
			fontSize: "14px",
			fontWeight: 700,
		},

		"& > a": {
			color: "#7540EE",
			fontSize: "14px",
			fontWeight: 500,
			textDecoration: "none",
		},
	},
});

export default profileViewAboutMeStyles;
