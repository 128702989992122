import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { departmentViewRoadmap, viewRoadmapRoleByRoleId } from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";
import ImageMapper from "react-image-mapper";
import Department_Accouting from "assets/img/Career_Roadmap/Finance/Department_Accouting_1.jpg";
import RoleInfoModal from "../RoleInfoModal.js";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentAccounting() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Accouting;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "a",
				shape: "poly",
				coords: [84,350,77,352,74,354,71,356,68,359,66,363,64,370,64,422,64,427,67,430,70,436,74,439,81,442,172,442,180,440,183,438,187,434,190,430,193,422,193,370,190,362,188,358,183,354,178,352,172,350,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "38",
				shape: "poly",
				coords: [224,356,219,357,216,360,212,364,210,371,210,420,212,425,214,428,219,430,225,433,358,432,363,431,366,428,370,424,371,418,372,369,369,363,365,359,361,357,357,355,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "39",
				shape: "poly",
				coords: [504,358,496,360,492,362,489,365,486,368,485,372,485,420,487,426,488,430,492,434,498,436,632,436,637,434,640,432,643,429,645,425,647,420,647,372,645,367,641,363,638,361,634,360,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "40",
				shape: "poly",
				coords: [796,359,788,360,785,362,781,365,778,369,777,374,777,422,778,428,781,432,784,435,791,438,923,436,929,435,932,433,934,431,936,428,938,424,939,379,938,372,937,368,934,365,931,362,928,360,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "41",
				shape: "poly",
				coords: [1092,361,1085,362,1081,363,1078,366,1076,369,1074,376,1074,420,1074,428,1077,432,1081,436,1087,438,1221,437,1227,435,1231,433,1233,429,1236,422,1235,376,1235,371,1231,366,1226,362,1221,361,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "42",
				shape: "poly",
				coords: [1386,360,1375,362,1370,364,1366,369,1364,375,1364,422,1366,429,1369,433,1374,437,1380,438,1507,438,1515,436,1520,436,1523,432,1526,428,1527,422,1527,377,1524,369,1522,365,1518,363,1514,361,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "37",
				shape: "poly",
				coords: [1687,360,1679,361,1676,364,1674,368,1672,374,1672,420,1673,427,1676,430,1678,432,1683,434,1688,436,1822,436,1829,434,1834,433,1836,430,1838,426,1840,422,1840,373,1839,367,1837,364,1834,361,1830,360,1824,358,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "a") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo)
			dispatch(viewRoadmapRoleByRoleId(requestInfo));
			
			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// console.log(coords.x + "," + coords.y + ",");
		// navigator.clipboard.writeText(coords.x + "," + coords.y + ",");
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}

	function enterArea(area) {
		console.log(area);
	}

	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
				onMouseEnter={(area) => enterArea(area)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
