import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
	departmentViewRoadmap,
	viewRoadmapRoleByRoleId,
} from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Industry_Banking_Investment_Banking from "assets/img/Career_Roadmap/Banking_Investment_Banking/Industry_Banking_Investment_Banking_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function IndustryBankingInvestmentBanking() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Industry_Banking_Investment_Banking;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "cm",
				shape: "poly",
				coords: [72,177,63,180,58,183,55,188,53,194,53,235,54,240,58,246,62,249,70,252,175,252,182,250,188,246,191,242,194,234,194,195,191,188,188,182,182,179,175,176,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "ma",
				shape: "poly",
				coords: [70,326,62,329,58,333,55,338,53,344,53,384,55,390,58,394,63,398,70,402,176,402,183,399,187,395,192,391,194,382,194,345,191,336,187,330,181,328,175,325,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "cf",
				shape: "poly",
				coords: [73,475,63,477,59,481,55,485,53,493,53,534,55,539,58,544,63,547,71,550,174,550,182,548,187,544,192,540,194,532,194,491,190,484,187,479,182,476,176,475,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "t",
				shape: "poly",
				coords: [72,624,64,625,59,630,55,634,53,641,53,682,55,688,58,692,63,696,71,699,174,699,182,697,187,693,192,688,194,682,193,642,191,634,186,628,180,625,174,624,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "444",
				shape: "poly",
				coords: [292,175,284,178,280,181,278,186,277,191,277,685,279,692,281,696,286,699,294,700,481,700,490,698,493,695,497,690,498,683,498,194,498,185,494,180,488,176,482,175,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "445",
				shape: "poly",
				coords: [598,175,588,178,584,182,582,185,581,193,580,684,582,692,585,695,589,699,596,700,786,700,795,697,798,694,801,690,802,684,802,198,801,186,798,180,792,177,786,175,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "432",
				shape: "poly",
				coords: [900,175,892,178,888,181,885,185,884,190,884,240,887,246,891,250,895,251,904,254,1045,253,1052,251,1056,247,1059,242,1061,236,1060,194,1059,184,1055,179,1049,176,1043,176,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "446",
				shape: "poly",
				coords: [905,324,893,326,889,331,886,335,886,342,885,678,887,690,890,695,896,698,903,700,1044,700,1053,697,1058,694,1061,688,1062,682,1061,346,1061,333,1056,328,1049,326,1043,324,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "433",
				shape: "poly",
				coords: [1158,176,1150,178,1147,181,1144,185,1142,192,1142,236,1144,244,1146,248,1152,252,1160,253,1299,252,1308,251,1313,249,1316,245,1318,236,1318,200,1319,188,1315,181,1309,177,1301,176,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "439",
				shape: "poly",
				coords: [1270,324,1261,326,1257,329,1255,334,1253,342,1254,388,1256,394,1258,398,1264,401,1271,402,1413,402,1422,400,1425,397,1428,393,1430,387,1430,342,1429,333,1424,328,1419,326,1412,324,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "447",
				shape: "poly",
				coords: [1270,473,1262,476,1258,478,1255,483,1253,489,1254,685,1256,692,1260,696,1264,699,1271,700,1412,700,1421,698,1426,695,1428,690,1430,684,1430,492,1428,482,1424,476,1418,474,1412,473,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "434",
				shape: "poly",
				coords: [1422,176,1410,177,1406,180,1402,184,1401,193,1400,233,1402,242,1404,248,1409,252,1419,254,1560,253,1567,251,1573,248,1576,242,1577,235,1577,193,1576,184,1573,179,1566,176,1559,175,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "435",
				shape: "poly",
				coords: [1674,176,1666,178,1662,182,1660,187,1658,193,1659,238,1661,245,1664,248,1670,252,1678,253,1819,254,1828,250,1832,248,1834,243,1836,236,1836,191,1834,184,1831,180,1825,176,1818,175,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "429",
				shape: "poly",
				coords: [1674,474,1667,475,1664,478,1660,485,1658,490,1659,531,1661,542,1664,546,1668,550,1676,551,1821,551,1828,549,1831,546,1834,542,1835,537,1836,488,1833,480,1830,476,1823,474,1817,473,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "cm") {
			dispatch(departmentViewRoadmap(53));
		} else if (area.name === "ma") {
			dispatch(departmentViewRoadmap(54));
		} else if (area.name === "cf") {
			dispatch(departmentViewRoadmap(55));
		} else if (area.name === "t") {
			dispatch(departmentViewRoadmap(56));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo);
			dispatch(viewRoadmapRoleByRoleId(requestInfo));

			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
