import React from "react";
import axios from "axios";

import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";

import { instanceUrl } from "configurations/configurations.js";
import { getContactAccount } from "configurations/redux/userSlice.js";
import { resetPasswordSchema } from "configurations/configurations.js";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Danger from "components/Typography/Danger.js";

import Close from "@material-ui/icons/Close";
import People from "@material-ui/icons/People";
import Phone from "@material-ui/icons/Phone";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Icon from "@material-ui/core/Icon";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { ReactReduxContext } from "react-redux";
import { isDesktop } from "react-device-detect";
import IconLibrary from "views/Component/IconLibrary.js";
import SyncContactTab from "./SyncContactTab.js";

const useStyles = makeStyles(profilePageStyle);
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

const SyncContactModal = ({ open, onClose }) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const profileRecord = useSelector((state) => state.user.profileRecord);
	const userStatus = useSelector((state) => state.user);
	const user = useSelector((state) => state.auth.user);

	React.useEffect(() => {
		// if (open) {
		const request = {
			acctId: user.acctId,
			email: user.email,
			accessToken: localStorage.getItem("token"),
			resetStatus: 1,
		};
		dispatch(getContactAccount(request));
		// }
	}, []);

	const modalResize = () => {
		var modalResize = document.getElementById("modalPassword");
		if (modalResize) {
			modalResize.style.maxHeight = "420px";
			var maxSize = window.innerHeight - 74 - 80 - 70;
			if (maxSize < 0) maxSize = 0;
			modalResize.style.height = maxSize + "px";
		}
	};

	React.useEffect(() => {
		if (open) {
			if (document.readyState === "complete") {
				setTimeout(() => {
					modalResize();
				}, 500);
			}
			window.addEventListener("resize", modalResize);
			return function cleanup() {
				window.removeEventListener("resize", modalResize);
			};
		}
	}, [open]);

	return (
		<React.Fragment>
			<Dialog
				classes={{
					root: classes.modalRoot,
					paper: classes.modalProfilePicEdit + " " + classes.modal,
				}}
				open={open}
				// TransitionComponent={Transition}
				// keepMounted
				// disableBackdropClick
				onClose={onClose}
				aria-labelledby="classic-modal-slide-title"
				aria-describedby="classic-modal-slide-description"
			>
				<DialogTitle
					id="classic-modal-slide-title"
					disableTypography
					className={classes.modalTitleEdit}
				>
					<Button
						simple
						className={classes.modalCloseButton}
						key="close"
						aria-label="Close"
						onClick={onClose}
					>
						<IconLibrary.Close />
					</Button>
					<h3 className={classes.title + " " + classes.titleAlign}>
						Invite Your Contacts
					</h3>
				</DialogTitle>
				<DialogContent
					id="classic-modal-slide-description"
					className={classes.modalBody}
				>
					<div className={classes.tabHeader}>
						<SyncContactTab open={open} user={user} />
					</div>
				</DialogContent>
				<div className={classes.modalFooter}></div>
			</Dialog>
		</React.Fragment>
	);
};

export default SyncContactModal;
