import {
	defaultFont,
	opptyDesignColor,
	opptyColor,
} from "assets/jss/material-kit-pro-react.js";

const ActionButtonsStyles = () => ({
	popoverList: {
		padding: 0,
	},

	popoverListItem: {
		display: "flex",
		alignItems: "center",
		padding: "5px 10px 5px 5px",
		minWidth: "130px",
		cursor: "pointer",

		"&:hover": {
			backgroundColor: "#e3e3e3",
		},
	},

	popoverListItemIcon: {
		display: "flex",
		justifyContent: "center",
		marginRight: "15px",
		width: "24px",
		textAlign: "center",
	},

	popoverListItemTitle: {
		color: "#44444F",
		fontSize: "14px",
	},

	actionButtons: {
		display: "flex",
		padding: "10px 5px",
		width: "100%",
		justifyContent: "space-between",
	},
	connectButton: {
		color: opptyDesignColor[0],
		border: `3px solid ${opptyDesignColor[0]}`,
		fontSize: "14px",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		borderRadius: "10px",
		width: "100px",
		height: "100%",
		maxWidth: "140px",
		maxHeight: "36px",
		marginRight: "10px",
		textTransform: "none",
	},
	pendingButton: {
		color: "#ffffff",
		border: `3px solid ${opptyColor[6]}`,
		backgroundColor: opptyColor[6],
		fontSize: "14px",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		borderRadius: "10px",
		width: "100px",
		height: "100%",
		maxWidth: "140px",
		maxHeight: "36px",
		marginRight: "10px",
		textTransform: "none",
		"&:hover": {
			backgroundColor: opptyColor[6],
		},
	},
	connectedButton: {
		color: "#ffffff",
		border: `2px solid ${opptyDesignColor[0]}`,
		backgroundColor: opptyDesignColor[0],
		fontSize: "14px",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		borderRadius: "10px",
		width: "100px",
		height: "100%",
		maxWidth: "140px",
		maxHeight: "36px",
		marginRight: "10px",
		textTransform: "none",
		"&:hover": {
			backgroundColor: opptyDesignColor[0],
		},
	},
	messageButton: {
		color: opptyColor[7],
		border: `2px solid ${opptyColor[7]}`,
		fontSize: "14px",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		borderRadius: "10px",
		width: "100px",
		height: "100%",
		maxWidth: "140px",
		maxHeight: "36px",
		marginRight: "10px",
		textTransform: "none",
	},
});

export default ActionButtonsStyles;
