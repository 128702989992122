import { createSlice } from '@reduxjs/toolkit';

import axios from 'axios';
import { instanceUrl, header } from 'configurations/configurations.js';
import { appliedJobApply } from 'configurations/redux/appliedJobSlice.js';
import { favJobSave } from 'configurations/redux/favJobSlice.js';
import { encryptData, emptyCache } from 'configurations/configurations.js';
import { getAppliedJobPostList } from 'configurations/redux/appliedJobSlice';

const jobManagementSlice = createSlice({
  name: 'jobManagement',
  initialState: {
    jobPostList: [],
    corpSelectedJobPost: null,
    selectedJobPost: null,
    applicantList: null,
    applications: null,
    questionaires: [],
    isUpdated: false,
    isSelected: true,
    isLoading: false,
    isCreated: false,
    isChanged: false,
    loadingApplicantList: false,
    loadingJobPostList: false,
    isDeleted: false,
    isStatusChanged: false,
    isEdited: false,
    statusDraft: 0,
    statusPublished: 0,
    statusDeactivated: 0,
    statusHeadhunting: 0,
  },
  reducers: {
    CORP_JOB_LOAD_SUCCESS: (state, action) => {

      localStorage.setItem('token', action.payload.token);
      if (action.payload.statusId === -1) state.jobPostList = [];
      else state.jobPostList = action.payload.jobPostList;
      if (action.payload.jobPostList.length > 0) {
        state.statusDraft = action.payload.statusDraft;
        state.statusPublished = action.payload.statusPublished;
        state.statusDeactivated = action.payload.statusDeactivated;
        state.statusHeadhunting = action.payload.statusHeadhunting;

      } else if (action.payload.statusId === -1) {
        state.statusDraft = 0;
        state.statusPublished = 0;
        state.statusDeactivated = 0;
        state.statusHeadhunting = 0;
      }
      state.isUpdated = true;
      state.isLoading = false;
      state.loadingJobPostList = false;
      state.isDeleted = false;
      state.isStatusChanged = false;
    },
    CORP_JOB_LOAD_FAIL: (state, action) => {
      localStorage.removeItem('token');
      state.isLoading = false;
      state.isUpdated = false;
    },
    CORP_JOB_EDIT_SUCCESS: (state, action) => {
      state.jobPostList = state.jobPostList.filter(item => {
        if (item.jobPostId === action.payload.jobPostId) {
          return (
            (item.jobPostInfo = action.payload.jobPostInfo),
            (item.jobPostInfo.statusId = action.payload.jobPostInfo.statusId),
            (item.jobPostInfo.statusName = action.payload.jobPostInfo.statusName)
          );
        } else {
          return item;
        }
      });
      if (action.payload.jobViewPage) {
        state.corpSelectedJobPost = state.corpSelectedJobPost.filter(item => {
          if (item.jobPostId === action.payload.jobPostId) {
            return (
              (item.jobPostInfo = action.payload.jobPostInfo),
              (item.jobPostInfo.statusId = action.payload.jobPostInfo.statusId),
              (item.jobPostInfo.statusName = action.payload.jobPostInfo.statusName)
            );
          } else {
            return item;
          }
        });
      }
      /* if (state.corpSelectedJobPost) {
        console.log("triggered");
      	
      } */
      localStorage.setItem('token', action.payload.token);
      state.isChanged = false;
      state.isStatusChanged = true;
      state.isEdited = true;
    },
    CORP_JOB_LOADING: state => {
      state.isLoading = true;
    },
    CORP_JOB_PAGINATION_LOADING: state => {
      state.loadingJobPostList = true;
    },
    CORP_JOB_DELETE_SUCCESS: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      state.isDeleted = true;
      state.jobPostList = state.jobPostList.filter(item => {
        return item.jobPostId !== action.payload.jobPostId;
      });
    },
    CORP_JOB_SELECT_SUCCESS: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      state.corpSelectedJobPost = action.payload.jobPostList;
      state.jobPostList = action.payload.jobPostList;
      state.applicantList = action.payload.applicantList;
      state.isSelected = true;
      state.isLoading = false;
    },
    JOB_SELECT_RESET: state => {
      state.corpSelectedJobPost = null;
      state.selectedJobPost = null;
      state.isSelected = false;
    },
    CORP_JOB_POST_SUCCESS: (state, action) => {
      localStorage.setItem('token', action.payload.token);
    },
    CORP_JOB_STATUS_UPDATE_SUCCESS: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      state.jobPostList = state.jobPostList.filter(item => {
        if (item.jobPostId === action.payload.jobPostId) {
          return (
            (item.jobPostInfo.statusId = action.payload.statusId),
            (item.jobPostInfo.statusName = action.payload.statusName)
          );
        } else {
          return item;
        }
      });
      if (state.corpSelectedJobPost) {
        state.corpSelectedJobPost = state.corpSelectedJobPost.filter(item => {
          if (item.jobPostId === action.payload.jobPostId) {
            return (
              (item.jobPostInfo.statusId = action.payload.statusId),
              (item.jobPostInfo.statusName = action.payload.statusName)
            );
          } else {
            return item;
          }
        });
      }
      state.isChanged = false;
      state.isStatusChanged = true;
      state.isEdited = false;
    },
    CORP_JOB_STATUS_LOADING: state => {
      state.isChanged = true;
    },
    CANDIDATE_JOB_SELECT_SUCCESS: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      state.selectedJobPost = action.payload.jobPostList;
      state.isSelected = true;
      state.isLoading = false;
    },
    CANDIDATE_JOB_SAVE_SUCCESS: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      state.selectedJobPost = state.selectedJobPost.filter(item => {
        return (item.favouriteJobPost = true);
      });
    },
    CANDIDATE_JOB_APPLY_SUCCESS: (state, action) => {
      localStorage.setItem('token', action.payload.token);

      state.selectedJobPost = state.selectedJobPost.filter(item => {
        return (item.appliedJobPost = true);
      });
    },
    CANDIDATE_JOB_SELECT_FAIL: state => {
      localStorage.removeItem('token');
      state.isSelected = false;
      state.isLoading = false;
    },
    CORP_UPDATE_FAIL: state => {
      return state;
    },
    APPLICANT_STATUS_UPDATE_SUCCESS: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      if (state.applicantList != null) {
        state.applicantList = state.applicantList.map(item => {
          if (item.applId === action.payload.applId) {
            const newItem = {
              ...item,
              applicationStatus: action.payload.applicationStatus,
              applicationStatusId: action.payload.applicationStatusId,
            };
            return (item = newItem);
          } else {
            return item;
          }
        });
      }
      console.log(state.applicantList);
    },
    APPLICANT_RESET_SUCCESS: (state, action) => {

      /* console.log('state.applicantLis 1');
      console.log(state.applicantList);
      console.log('state.applicantLis 2');
    	
      console.log('res.data.payload 1');
      console.log(action.payload);
      console.log('res.data.payload 2'); */

      state.loadingApplicantList = false;
    },
    CORP_JOB_SET_QUESTIONARE: (state, action) => {
      console.log('action', action)
      state.questionaires = action.payload
    },
    APPLICANT_SELECT_SUCCESS: (state, action) => {

      console.log('res.data.payload 1');
      console.log(action.payload.applications);
      console.log('res.data.payload 2');

      localStorage.setItem('token', action.payload.token);
      state.applicantList = action.payload.applicantList;
      state.applications = action.payload.applications;
      state.loadingApplicantList = false;
    },
    APPLICANT_SELECT_LOADING: state => {
      state.applicantList = null;
      state.applications = null;
      state.loadingApplicantList = true;
    },
    APPLICANT_SELECT_FAIL: state => {
      state.loadingApplicantList = false;
    },
  },
});

const {
  CORP_JOB_LOAD_FAIL,
  CORP_JOB_LOAD_SUCCESS,
  CORP_JOB_POST_SUCCESS,
  CORP_UPDATE_FAIL,
  CORP_JOB_SELECT_SUCCESS,
  CORP_JOB_DELETE_SUCCESS,
  CORP_JOB_EDIT_SUCCESS,
  CORP_JOB_LOADING,
  CORP_JOB_SET_QUESTIONARE,
  CORP_JOB_PAGINATION_LOADING,
  CORP_JOB_STATUS_UPDATE_SUCCESS,
  CORP_JOB_STATUS_LOADING,
  JOB_SELECT_RESET,
  CANDIDATE_JOB_SELECT_FAIL,
  CANDIDATE_JOB_SELECT_SUCCESS,
  CANDIDATE_JOB_SAVE_SUCCESS,
  CANDIDATE_JOB_APPLY_SUCCESS,
  APPLICANT_STATUS_UPDATE_SUCCESS,
  APPLICANT_SELECT_SUCCESS,
  APPLICANT_RESET_SUCCESS,
  APPLICANT_SELECT_LOADING,
  APPLICANT_SELECT_FAIL,
} = jobManagementSlice.actions;

export const updateJobPost = ({
  acctId,
  email,
  accessToken,
  title,
  industryId,
  countryId,
  locationId,
  location,
  eduLvlId,
  role,
  servicePeriod,
  responsibility,
  requirement,
  currencyId,
  salaryMin,
  salaryMax,
  statusId,
  expiryDate,
  hashTags,
  questionaires,
  jobPostTypeId,
  jobTypeId,
  complimentaryFlag,
  experienceId,
  bizId,
}) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    title,
    industryId,
    countryId,
    locationId,
    location,
    eduLvlId,
    role,
    servicePeriod,
    responsibility,
    requirement,
    currencyId,
    salaryMin,
    salaryMax,
    expiryDate,
    hashTags,
    questionaires,
    statusId,
    jobTypeId,
    jobPostTypeId,
    complimentaryFlag,
    experienceId,
    bizId,
  };
  console.log(body);
  await axios({
    method: 'POST',
    headers: header,
    url: `${instanceUrl}/api/jm/add-job-post`,
    data: body,
  })
    .then(res => {
      console.log(res);
      const response = {
        token: res.data.token,
      };
      if (res.status === 201) {
        dispatch(CORP_JOB_POST_SUCCESS(response));
      } else {
        dispatch(CORP_UPDATE_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const getJobPosts = ({
  acctId,
  bizId,
  statusId,
  lastRecordSize,
  requestRecordSize,
  accessToken,
  paginationClick,
}) => async dispatch => {
  const body = {
    acctId,
    bizId,
    statusId,
    accessToken,
    lastRecordSize,
    requestRecordSize,
  };
  // console.log(body);
  var jobPostList = [];
  if (paginationClick) dispatch(CORP_JOB_PAGINATION_LOADING());
  else dispatch(CORP_JOB_LOADING());

  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/jm/get-job-post-by-user-id`,
    params: body,
  })
    .then(res => {
      if (res.status === 201) {
        console.log('res', res);
        res.data.payload.jobPostList.map(item => {
          const jobPostDetail = {
            jobPostInfo: {
              title: item.title,
              countryId: item.countryId,
              currencyId: item.currencyId,
              eduLvlId: item.eduLvlId,
              industryId: item.industryId,
              locationId: item.locationId,
              location: item.location,
              requirement: item.requirement,
              responsibility: item.responsibility,
              role: item.role,
              salaryMax: item.salaryMax,
              salaryMin: item.salaryMin,
              servicePeriod: item.servicePeriod,
              title: item.title,
              hashTags: item.hashtagList,
              questions: item.questionaires,
              statusId: item.statusId,
              statusName: item.statusName,
              jobPostType: item.jobPostType,
              jobPostTypeId: item.jobPostTypeId,
              jobType: item.jobType,
              jobTypeId: item.jobTypeId,
              eduLvlName: item.eduLvlName,
              experience: item.experience,
              experienceId: item.experienceId,
              industryName: item.industryName,
              countryName: item.countryName,
              currencyCode: item.currencyCode,
              lastUpdateDate: item.lastUpdateDate,

              applCount: item.applCount,
              hiredCount: item.hiredCount,
              interviewCount: item.interviewCount,
              offerCount: item.offerCount,
              shortlistedCount: item.shortlistedCount,
              applicationsCount: item.applicationsCount,
            },
            profilePic: item.profilePic,
            complimentaryFlag: item.complimentaryFlag,
            companyName: item.companyName,
            createDate: item.createDate,

            expiryDate: item.expiryDate,
            jobPostId: item.jobPostId,
            encryptJobPostId: encryptData(item.jobPostId),
            jobPostCount: item.jobPostCount,
            statusDraft: item.statusDraft,
            statusPublished: item.statusPublished,
            statusDeactivated: item.statusDeactivated,
            statusHeadhunting: item.statusHeadhunting,
          };
          jobPostList.push(jobPostDetail);
        });
        var statusDraft = 0;
        var statusPublished = 0;
        var statusDeactivated = 0;
        var statusHeadhunting = 0;
        if (res.data.payload.jobPostList.length > 0) {
          statusDraft = res.data.payload.jobPostList[0].statusDraft;
          statusPublished = res.data.payload.jobPostList[0].statusPublished;
          statusDeactivated = res.data.payload.jobPostList[0].statusDeactivated;
          statusHeadhunting = res.data.payload.jobPostList[0].statusHeadhunting;
        }
        const response = {
          jobPostList: jobPostList,
          statusDraft: statusDraft,
          statusPublished: statusPublished,
          statusDeactivated: statusDeactivated,
          statusHeadhunting: statusHeadhunting,
          token: res.data.token,
          statusId: statusId,
        };
        if (res.data.payload.jobPostList.length > 0) {
          dispatch(CORP_JOB_LOAD_SUCCESS(response));
        } else {
          if (statusId >= 0) {
            const requestProfile = {
              acctId: acctId,
              bizId: bizId,
              accessToken: accessToken,
              lastRecordSize: 0,
              requestRecordSize: 1,
              paginationClick: false,
              statusId: -1,
            };
            dispatch(getJobPosts(requestProfile));
          } else {
            dispatch(CORP_JOB_LOAD_SUCCESS(response));
          }
        }
      } else {
        dispatch(CORP_JOB_LOAD_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const getCoporateJobPosts = ({
  acctId,
  bizId,
  statusId,
  typeId,
  lastRecordSize,
  requestRecordSize,
  accessToken,
  paginationClick,
}) => async dispatch => {
  const body = {
    acctId,
    bizId,
    statusId,
    typeId,
    accessToken,
    lastRecordSize,
    requestRecordSize,
  };
  // console.log(body);
  var jobPostList = [];
  if (paginationClick) dispatch(CORP_JOB_PAGINATION_LOADING());
  else dispatch(CORP_JOB_LOADING());

  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/jm/get-job-post-by-user-id`,
    params: body,
  })
    .then(res => {
      if (res.status === 201) {
        console.log('res', res);
        res.data.payload.jobPostList.map(item => {
          const jobPostDetail = {
            jobPostInfo: {
              title: item.title,
              countryId: item.countryId,
              currencyId: item.currencyId,
              eduLvlId: item.eduLvlId,
              industryId: item.industryId,
              locationId: item.locationId,
              location: item.location,
              requirement: item.requirement,
              responsibility: item.responsibility,
              role: item.role,
              salaryMax: item.salaryMax,
              salaryMin: item.salaryMin,
              servicePeriod: item.servicePeriod,
              title: item.title,
              hashTags: item.hashtagList,
              questions: item.questionaires,
              statusId: item.statusId,
              statusName: item.statusName,
              jobPostType: item.jobPostType,
              jobPostTypeId: item.jobPostTypeId,
              jobType: item.jobType,
              jobTypeId: item.jobTypeId,
              eduLvlName: item.eduLvlName,
              experience: item.experience,
              experienceId: item.experienceId,
              industryName: item.industryName,
              countryName: item.countryName,
              currencyCode: item.currencyCode,
              lastUpdateDate: item.lastUpdateDate,

              applCount: item.applCount,
              hiredCount: item.hiredCount,
              interviewCount: item.interviewCount,
              offerCount: item.offerCount,
              shortlistedCount: item.shortlistedCount,
              applicationsCount: item.applicationsCount,
            },
            profilePic: item.profilePic,
            complimentaryFlag: item.complimentaryFlag,
            companyName: item.companyName,
            createDate: item.createDate,

            expiryDate: item.expiryDate,
            jobPostId: item.jobPostId,
            encryptJobPostId: encryptData(item.jobPostId),
            jobPostCount: item.jobPostCount,
            statusDraft: item.statusDraft,
            statusPublished: item.statusPublished,
            statusDeactivated: item.statusDeactivated,
            statusHeadhunting: item.statusHeadhunting,
          };
          jobPostList.push(jobPostDetail);
        });
        var statusDraft = 0;
        var statusPublished = 0;
        var statusDeactivated = 0;
        var statusHeadhunting = 0;
        if (res.data.payload.jobPostList.length > 0) {
          statusDraft = res.data.payload.jobPostList[0].statusDraft;
          statusPublished = res.data.payload.jobPostList[0].statusPublished;
          statusDeactivated = res.data.payload.jobPostList[0].statusDeactivated;
          statusHeadhunting = res.data.payload.jobPostList[0].statusHeadhunting;
        }
        const response = {
          jobPostList: jobPostList,
          statusDraft: statusDraft,
          statusPublished: statusPublished,
          statusDeactivated: statusDeactivated,
          statusHeadhunting: statusHeadhunting,
          token: res.data.token,
          statusId: statusId,
        };
        dispatch(CORP_JOB_LOAD_SUCCESS(response));

      } else {
        dispatch(CORP_JOB_LOAD_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};



export const deleteJobPosts = ({ acctId, accessToken, jobPostId }) => async dispatch => {
  const body = {
    acctId,
    accessToken,
    jobPostId,
  };
  await axios({
    method: 'DELETE',
    headers: header,
    url: `${instanceUrl}/api/jm/delete-job-post`,
    data: body,
  })
    .then(res => {
      if (res.status === 201) {
        const response = {
          jobPostId,
          token: res.data.token,
        };
        dispatch(CORP_JOB_DELETE_SUCCESS(response));
      } else {
        dispatch(CORP_UPDATE_FAIL);
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const editJobPosts = ({
  email,
  acctId,
  accessToken,
  jobPostId,
  title,
  industryId,
  countryId,
  locationId,
  location,
  eduLvlId,
  role,
  servicePeriod,
  responsibility,
  requirement,
  currencyId,
  salaryMin,
  salaryMax,
  statusId,
  jobTypeId,
  jobPostTypeId,
  hashTags,
  experienceId,
  jobViewPage,
  bizId,
  questionaires
}) => async dispatch => {
  const body = {
    email,
    acctId,
    accessToken,
    jobPostId,
    title,
    industryId,
    countryId,
    locationId,
    location,
    eduLvlId,
    role,
    servicePeriod,
    responsibility,
    requirement,
    currencyId,
    salaryMin,
    salaryMax,
    statusId,
    jobPostTypeId,
    jobTypeId,
    experienceId,
    hashTags,
    jobViewPage,
    bizId,
    questionaires
  };

  await axios({
    method: 'PUT',
    headers: header,
    url: `${instanceUrl}/api/jm/update-job-post`,
    data: body,
  })
    .then(res => {
      console.log(res);

      if (res.status === 201) {

        const response = {
          token: res.data.token,
          jobPostInfo: {
            title,
            industryId,
            countryId,
            locationId,
            location,
            eduLvlId,
            role,
            servicePeriod,
            responsibility,
            requirement,
            currencyId,
            salaryMin,
            salaryMax,
            hashTags,
            statusId: res.data.payload.jobPost.statusId,
            jobTypeId,
            experienceId,
            experience: res.data.payload.jobPost.experience,
            jobPostTypeId,
            eduLvlName: res.data.payload.jobPost.eduLvlName,
            countryName: res.data.payload.jobPost.countryName,
            locationName: res.data.payload.jobPost.locationName,
            industryName: res.data.payload.jobPost.industryName,
            currencyCode: res.data.payload.jobPost.currencyCode,
            statusName: res.data.payload.jobPost.statusName,
            jobPostType: res.data.payload.jobPost.jobPostType,
            jobType: res.data.payload.jobPost.jobType,
            lastUpdateDate: res.data.payload.jobPost.lastUpdateDate,
          },
          jobPostId: jobPostId,
          jobViewPage,
        };
        console.log('response', response.jobPostInfo);
        dispatch(CORP_JOB_EDIT_SUCCESS(response));
      } else {
        dispatch(CORP_UPDATE_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const getQuestionareOfJobs = ({
  acctId,
  accessToken,
  jobPostId,
  email
}) => async dispatch => {
  const body = {
    acctId,
    accessToken,
    jobPostId,
    email
  };
  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/jm/get-job-post-by-post-id-non-corporate`,
    params: body,
  })
    .then(res => {
      console.log('res', res)
      if (res.status == 201) {
        dispatch(CORP_JOB_SET_QUESTIONARE(res.data.payload.jobPostList[0].questionaires))
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const getSelectJobPosts = ({
  acctId,
  accessToken,
  jobPostId,
  ipAddr,
  lastRecordSize,
  requestRecordSize,
  bizId,
}) => async dispatch => {
  const body = {
    acctId,
    accessToken,
    jobPostId,
    ipAddr,
    lastRecordSize,
    requestRecordSize,
    bizId,
  };
  dispatch(CORP_JOB_LOADING());
  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/jm/get-job-post-by-post-id`,
    params: body,
  })
    .then(res => {
      if (res.status === 201) {
        var applicantList = null;
        console.log(res.data.payload.jobPostList);
        if (res.data.payload.jobPostList[0] != undefined) applicantList = res.data.payload.jobPostList[0].applicantList;

        var jobPostList = [];
        res.data.payload.jobPostList.map(item => {
          const jobPostDetail = {
            jobPostInfo: {
              title: item.title,
              countryId: item.countryId,
              currencyId: item.currencyId,
              eduLvlId: item.eduLvlId,
              industryId: item.industryId,
              location: item.location,
              requirement: item.requirement,
              responsibility: item.responsibility,
              role: item.role,
              jobPostId,
              salaryMax: item.salaryMax,
              salaryMin: item.salaryMin,
              servicePeriod: item.servicePeriod,
              hashTags: item.hashtagList,
              statusId: item.statusId,
              statusName: item.statusName,
              jobPostType: item.jobPostType,
              jobPostTypeId: item.jobPostTypeId,
              jobType: item.jobType,
              jobTypeId: item.jobTypeId,
              eduLvlName: item.eduLvlName,
              industryName: item.industryName,
              countryName: item.countryName,
              locationId: item.locationId,
              locationName: item.locationName,
              experience: item.experience,
              experienceId: item.experienceId,
              currencyCode: item.currencyCode,
            },
            profilePic: item.profilePic,
            complimentaryFlag: item.complimentaryFlag,
            companyName: item.companyName,
            createDate: item.createDate,

            expiryDate: item.expiryDate,
            jobPostId,
            lastUpdateDate: item.lastUpdateDate,
            jobPostCount: item.jobPostCount,
            viewCount: item.viewCount,
            applCount: item.applCount,
            statusDraft: item.statusDraft,
            statusPublished: item.statusPublished,
            statusDeactivated: item.statusDeactivated,
            statusHeadhunting: item.statusHeadhunting,
          };
          jobPostList.push(jobPostDetail);
        });

        const response = {
          jobPostList: jobPostList,
          applicantList: applicantList,
          token: jobPostList.length > 0 ? res.data.token : accessToken,
        };

        // const response = {
        // 	jobPostList: res.data.payload.jobPostList,
        // 	applicantList: applicantList,
        // 	token: res.data.token,
        // };
        dispatch(CORP_JOB_SELECT_SUCCESS(response));
      } else {
        dispatch(CANDIDATE_JOB_SELECT_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};
export const getCandidateSelectJobPosts = ({ acctId, accessToken, jobPostId, ipAddr, item }) => async dispatch => {
  const body = { acctId, accessToken, jobPostId, ipAddr };
  console.log(body);
  dispatch(CORP_JOB_LOADING());
  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/jm/get-job-post-by-post-id-non-corporate`,
    params: body,
  })
    .then(res => {
      if (res.status === 201) {
        var applStatusName = null;
        var applStatusId = null;
        var selectedJobPostList = res.data.payload.jobPostList;
        if (item !== null) {
          applStatusName = item.applStatusName;
          applStatusId = item.applStatusId;
          // jobPostList = jobPostList.concat(
          // 	{
          // 		hashId: -1,
          // 		hashtag: hashtag,
          // 	}
          // );
          selectedJobPostList.push({
            applStatusName: applStatusName,
            applStatusId: applStatusId,
          });
        }
        console.log(selectedJobPostList);
        const response = {
          jobPostList: selectedJobPostList,
          applStatusName: applStatusName,
          applStatusId: applStatusId,
          token: res.data.token,
        };
        dispatch(CANDIDATE_JOB_SELECT_SUCCESS(response));
      } else {
        console.log(res);
        dispatch(CANDIDATE_JOB_SELECT_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      if (err.message.includes('502')) {
        dispatch(CANDIDATE_JOB_SELECT_FAIL());
      }
      console.log(err);
    });
};


export const getCandidateSelectJobPostsOnly = ({ acctId, accessToken, jobPostId, ipAddr, item }) => async dispatch => {
  const body = { acctId, accessToken, jobPostId, ipAddr };
  console.log(body);
  dispatch(CORP_JOB_LOADING());
  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/jm/get-job-post-by-post-id-non-corporate`,
    params: body,
  })
    .then(res => {
      if (res.status === 201) {
        var applStatusName = null;
        var applStatusId = null;
        var selectedJobPostList = res.data.payload.jobPostList;

        console.log(selectedJobPostList);
        const response = {
          jobPostList: selectedJobPostList,
          applStatusName: applStatusName,
          applStatusId: applStatusId,
          token: res.data.token,
        };
        dispatch(CANDIDATE_JOB_SELECT_SUCCESS(response));
      } else {
        console.log(res);
        dispatch(CANDIDATE_JOB_SELECT_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      if (err.message.includes('502')) {
        dispatch(CANDIDATE_JOB_SELECT_FAIL());
      }
      console.log(err);
    });
};


export const updateJobPostStatus = (data, cb) => async dispatch => {
  const { email, acctId, accessToken, jobPostId, statusId } = data;
  const body = data;

  dispatch(CORP_JOB_STATUS_LOADING());
  await axios({
    method: 'PUT',
    headers: header,
    url: `${instanceUrl}/api/jm/update-job-post-status`,
    data: body,
  })
    .then(res => {
      if (res.status === 201) {
        const response = {
          jobPostId,
          statusId,
          statusName: res.data.payload.newStatus,
          token: res.data.token,
        };
        dispatch(CORP_JOB_STATUS_UPDATE_SUCCESS(response));
        cb(res.data)
      } else {
        dispatch(CORP_UPDATE_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const saveJobApplication = ({ acctId, accessToken, jobPostId, email }) => async dispatch => {
  const body = {
    acctId,
    accessToken,
    jobPostId,
    email,
  };

  await axios({
    method: 'PUT',
    headers: header,
    url: `${instanceUrl}/api/jm/add-favourite-job-post`,
    data: body,
  })
    .then(res => {
      const response = {
        token: res.data.token,
      };
      const savedJob = {
        favId: res.data.payload.recordId,
        companyName: res.data.payload.favouriteJobPost.companyName,
        favDate: res.data.payload.favouriteJobPost.favDate,
        profilePic: res.data.payload.favouriteJobPost.companyProfilePic,
        title: res.data.payload.favouriteJobPost.jobPostTitle,
        jobPostId: res.data.payload.favouriteJobPost.jobPostId,
        statusId: res.data.payload.favouriteJobPost.jobPostStatusId,
      };
      if (res.status === 201) {
        dispatch(CANDIDATE_JOB_SAVE_SUCCESS(response));
        const applyJobRequest = {
          acctId: acctId,
          email: email,
          accessToken: accessToken,
          lastRecordSize: 0,
          requestRecordSize: 5,
          paginationClick: false,
        };
        dispatch(favJobSave(applyJobRequest));
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};
export const createJobApplication = ({
  acctId,
  accessToken,
  email,
  applicantId,
  jobPostId,
  applStatusId,
  applDate,
}) => async dispatch => {
  const body = {
    acctId,
    accessToken,
    email,
    applicantId,
    jobPostId,
    applStatusId,
    applDate,
  };
  console.log(body);
  await axios({
    method: 'POST',
    headers: header,
    url: `${instanceUrl}/api/am/create-job-application`,
    data: body,
  })
    .then(res => {
      console.log(res);

      const response = {
        token: res.data.token,
        jobPost: {
          applId: res.data.payload.recordId,
          applStatusId: res.data.payload.jobApplication.applStatusId,
          title: res.data.payload.jobApplication.title,
          applDate: res.data.payload.jobApplication.applDate,
          profilePic: res.data.payload.jobApplication.companyProfilePic,
          jobPostId: res.data.payload.jobApplication.jobPostId,
          applStatusName: res.data.payload.jobApplication.applStatusName,
          companyName: res.data.payload.jobApplication.companyName,
        },
      };
      const appliedJob = {
        applId: res.data.payload.recordId,
        applStatusId: res.data.payload.jobApplication.applStatusId,
        title: res.data.payload.jobApplication.title,
        applDate: res.data.payload.jobApplication.applDate,
        profilePic: res.data.payload.jobApplication.companyProfilePic,
        jobPostId: res.data.payload.jobApplication.jobPostId,
        applStatusName: res.data.payload.jobApplication.applStatusName,
        companyName: res.data.payload.jobApplication.companyName,
        jobPostStatusId: res.data.payload.jobApplication.jobPostStatusId,
      };
      console.log(appliedJob);
      if (res.status === 201) {
        dispatch(CANDIDATE_JOB_APPLY_SUCCESS(response));

        const applyJobRequest = {
          acctId: acctId,
          email: email,
          accessToken: res.data.token,
          lastRecordSize: 0,
          requestRecordSize: 5,
          paginationClick: false,
        };
        dispatch(getAppliedJobPostList(applyJobRequest));
        // dispatch(appliedJobApply(appliedJob));
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};
export const updateApplicantStatus = ({
  acctId,
  accessToken,
  email,
  applicantId,
  applId,
  applStatusId,
}) => async dispatch => {
  const body = {
    acctId,
    accessToken,
    email,
    applicantId,
    applId,
    applStatusId,
  };
  console.log(body);

  await axios({
    method: 'PUT',
    headers: header,
    url: `${instanceUrl}/api/am/update-application-status`,
    data: body,
  })
    .then(res => {
      console.log(res);
      if (res.status === 201) {
        const response = {
          applId,
          applicationStatusId: applStatusId,
          applicationStatus: res.data.payload.newApplStatus,
          token: res.data.token,
        };
        dispatch(APPLICANT_STATUS_UPDATE_SUCCESS(response));
        dispatch(APPLICANT_RESET_SUCCESS(response));
      } else {
        console.log(res);
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const getJobApplications = ({
  acctId,
  email,
  accessToken,
  jobPostId,
  lastRecordSize,
  requestRecordSize,
  ipAddr,
}) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    jobPostId,
    lastRecordSize,
    requestRecordSize,
    ipAddr,
  };
  dispatch(APPLICANT_SELECT_LOADING());
  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/jm/get-job-application-by-job-post-id`,
    params: body,
  })
    .then(res => {
      if (res.status === 201) {
        console.log(res);

        const response = {
          applicantList: res.data.payload.applicantList,
          token: res.data.token,
        };
        dispatch(APPLICANT_SELECT_SUCCESS(response));
      } else {
        dispatch(APPLICANT_SELECT_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const getJobApplicationsWithStatus = ({
  acctId,
  email,
  accessToken,
  jobPostId,
  lastRecordSize,
  requestRecordSize,
  ipAddr,
}) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    jobPostId,
    lastRecordSize,
    requestRecordSize,
    ipAddr,
  };
  dispatch(APPLICANT_SELECT_LOADING());
  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/jm/get-job-application-by-job-post-id-group-by-status`,
    params: body,
  })
    .then(res => {
      if (res.status === 201) {
        //  console.log(res);


        const response = {
          applicantList: res.data.payload.applications,
          applications: res.data.payload,
          token: res.data.token,
        };
        dispatch(APPLICANT_SELECT_SUCCESS(response));
      } else {
        dispatch(APPLICANT_SELECT_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const createUserEvent = ({
  acctId,
  email,
  accessToken,
  bizId,
  title,
  description,
  eventDate,
  location,
  typeId,
  statusId,
  recordTypeId,
  recordId,
  schedules,
  guests
}) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    bizId,
    title,
    description,
    eventDate,
    location,
    typeId,
    statusId,
    recordTypeId,
    recordId,
    schedules,
    guests,
  };
  dispatch(APPLICANT_SELECT_LOADING());
  await axios({
    method: 'POST',
    headers: header,
    url: `${instanceUrl}/api/um/create-user-event`,
    data: body,
  })
    .then(res => {
      if (res.status === 201) {
        console.log('-------------res===========');

        console.log(res);


        const response = {
          applicantList: res.data.payload.applications,
          applications: res.data.payload,
          token: res.data.token,
        };
        // dispatch(APPLICANT_SELECT_SUCCESS(response));
      } else {
        dispatch(APPLICANT_SELECT_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};



export const createJobOffer = ({
  acctId,
  email,
  accessToken,
  applId,
  jobPosition,
  jobPostTypeId,
  servicePeriod,
  offerSalary,
  remark,

}) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    applId,
    jobPosition,
    jobPostTypeId,
    servicePeriod,
    offerSalary,
    remark,
  };

  console.log('---body-----');
  console.log(body);
  await axios({
    method: 'POST',
    headers: header,
    url: `${instanceUrl}/api/am/create-job-offer`,
    data: body,
  })
    .then(res => {
      if (res.status === 201) {
        console.log('-------------res===========');

        console.log(res);


        const response = {
          applicantList: res.data.payload.applications,
          applications: res.data.payload,
          token: res.data.token,
        };
        // dispatch(APPLICANT_SELECT_SUCCESS(response));
      } else {
        dispatch(APPLICANT_SELECT_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const zoomAPIIntegrationById = ({ acctId }) => async (dispatch) => {
  const body = { acctId };
  await axios({
    method: "GET",
    headers: header,
    url: `${instanceUrl}/api/zoom/get-zoom-account-by-acct-id`,
    params: body,
  })
    .then((res) => {
      // cb(res.data);
      const url = res.data.payload.zoomAccount[0].url
      if (url != '') {
        var left = window.innerWidth / 2 - 600 / 2;
        var top = window.innerHeight / 2 - 800 / 2;
        const newWindow = window.open(
          url,
          "mywindow1",
          "status=1, toolbar=1, scrollbars=1, resizable=1, width=600, height=800, top=" +
          top +
          ", left=" +
          left
        );
        if (newWindow) newWindow.opener = null;
        setTimeout(() => {
          if (
            !newWindow ||
            newWindow.closed ||
            typeof newWindow.closed === "undefined"
          ) {
            const response = {
              errorMessage:
                "Please allow pop-ups on your browser in order to import your contacts",
            };

          }
        }, 2000);

      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
}


export const jobSelectReset = () => dispatch => {
  dispatch(JOB_SELECT_RESET());
};
export default jobManagementSlice.reducer;
