import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { departmentViewRoadmap, viewRoadmapRoleByRoleId } from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";
import ImageMapper from "react-image-mapper";
import Department_Housekeeping from "assets/img/Career_Roadmap/Hospitality/Department_Housekeeping_2.jpg";
import RoleInfoModal from "../RoleInfoModal.js";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentHousekeeping() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Housekeeping;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "h",
				shape: "poly",
				coords: [80,231,73,234,69,236,65,241,62,247,62,626,64,632,69,638,73,641,79,643,174,643,181,641,185,637,189,631,191,623,191,249,189,243,184,236,178,232,173,230,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "79",
				shape: "poly",
				coords: [222,229,217,232,214,235,212,238,210,243,209,310,211,316,213,320,218,324,225,325,349,325,356,323,361,320,364,316,365,309,365,242,363,236,359,233,354,231,349,230,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "75",
				shape: "poly",
				coords: [222,511,217,513,214,515,210,519,209,526,209,631,211,637,213,640,218,643,223,644,350,644,357,641,360,638,364,635,365,630,365,525,362,518,359,514,352,511,347,510,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "76",
				shape: "poly",
				coords: [436,370,430,372,427,375,425,378,424,384,425,462,426,467,428,470,433,474,439,475,540,474,548,471,551,468,553,464,554,458,554,385,553,379,550,374,545,371,541,369,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "80",
				shape: "poly",
				coords: [656,230,650,233,647,237,645,242,644,247,644,316,646,322,648,325,653,328,659,330,793,330,798,329,802,326,805,322,807,316,807,246,806,240,803,235,799,231,793,230,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "77",
				shape: "poly",
				coords: [659,472,652,474,648,476,646,480,644,488,644,629,646,635,648,639,652,641,659,644,800,644,805,641,809,638,811,634,812,627,812,486,810,479,807,475,801,473,795,472,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "81",
				shape: "poly",
				coords: [875,229,868,231,863,234,861,238,860,245,860,315,862,322,865,325,869,327,874,329,1011,329,1016,326,1019,323,1022,320,1023,313,1023,245,1023,239,1019,234,1014,230,1007,229,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "78",
				shape: "poly",
				coords: [872,512,866,515,863,518,861,521,860,528,860,592,861,599,864,602,868,605,874,607,1010,607,1018,606,1022,603,1026,599,1027,592,1028,526,1026,520,1022,516,1017,513,1012,511,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "82",
				shape: "poly",
				coords: [1056,375,1050,378,1048,380,1045,386,1043,391,1043,465,1045,470,1048,475,1054,478,1058,480,1171,479,1177,478,1182,476,1185,471,1187,463,1187,391,1187,385,1184,381,1178,377,1173,376,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "83",
				shape: "poly",
				coords: [1256,372,1250,374,1246,376,1243,379,1242,386,1242,464,1244,470,1246,473,1251,476,1256,478,1381,478,1388,477,1392,473,1395,470,1397,464,1398,386,1395,380,1392,376,1388,373,1382,371,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "84",
				shape: "poly",
				coords: [1479,371,1472,373,1469,376,1467,380,1465,386,1465,461,1466,466,1468,471,1472,474,1478,476,1624,477,1630,475,1635,471,1637,468,1638,462,1638,385,1637,379,1633,375,1629,373,1624,371,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "85",
				shape: "poly",
				coords: [1719,372,1714,374,1711,376,1709,380,1707,387,1707,467,1710,471,1712,474,1716,475,1722,477,1839,477,1844,474,1847,471,1849,468,1850,463,1850,384,1848,379,1846,375,1842,372,1838,371,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "h") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo)
			dispatch(viewRoadmapRoleByRoleId(requestInfo));
			
			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// console.log(coords.x + "," + coords.y + ",");
		// navigator.clipboard.writeText(coords.x + "," + coords.y + ",");
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}

	function enterArea(area) {
		console.log(area);
	}

	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
				onMouseEnter={(area) => enterArea(area)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
