import {
	primaryColor,
	grayColor,
	container,
	cardTitle,
	whiteColor,
	blackColor,
	dangerColor,
	hexToRgb,
	opptyDesignColor,
	facebookColor,
} from "assets/jss/material-kit-pro-react.js";

import customCheckboxRadioSwitchStyle from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.js";

const registrationPageStyle = (theme) => ({
	container: {
		...container,
		zIndex: "2",
		position: "relative",
		// paddingTop: "10vh",
		color: whiteColor,

		"@media (min-width: 576px)": {
			maxWidth: "576px",
		},
		"@media (min-width: 768px)": {
			maxWidth: "768px",
		},
		"@media (min-width: 992px)": {
			maxWidth: "992px",
		},
		"@media (min-width: 960px)": {
			maxWidth: "90vw",
		},
		"@media (min-width: 992px)": {
			maxWidth: "90vw",
		},
		"@media (min-width: 1200px)": {
			maxWidth: "90vw",
		},
		// "@media (min-width: 576px)": {
		// 	maxWidth: "540px",
		// },
		// "@media (min-width: 768px)": {
		// 	maxWidth: "100%",
		// },
		// "@media (min-width: 992px)": {
		// 	maxWidth: "100%",
		// },
		// "@media (min-width: 1200px)": {
		// 	maxWidth: "1200px",
		// },
	},
	leftInfoTitle: {
		color: blackColor,
		fontFamily: `"Roboto Medium", "Helvetica", "Arial", sans-serif`,
		fontSize: "45px",
		fontWeight: "bold",
		[theme.breakpoints.down("md")]: {
			fontSize: "35px",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "25px",
			margin: "0",
		},
	},
	leftInfoDescription: {
		color: "#959595",
		fontFamily: `"Roboto Bold", "Helvetica", "Arial", sans-serif`,
		fontSize: "30px",
		// fontWeight: "bold",
		[theme.breakpoints.down("md")]: {
			fontSize: "20px",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "14px",
		},
	},
	infoTitle: {
		color: opptyDesignColor,
		fontWeight: "bold",
		textTransform: "uppercase",
		fontSize: "15px",
		[theme.breakpoints.down("xs")]: {
			fontSize: "13px",
		},
	},
	infoDescription: {
		color: grayColor[0],
		fontWeight: 500,
		[theme.breakpoints.down("xs")]: {
			fontSize: "13px",
		},
	},
	infoImage: {
		width: "75%",
		// height: "100%",
		position: "relative",

		// padding: "0px 50px !important",
		// marginTop: "25%",
		// marginBottom: "25%",
	},
	banner: {
		borderRadius: "12px",
		backgroundColor: "#fb8082 !important",
	},
	snackbarMobile: {
		"& div": {
			[theme.breakpoints.down("xs")]: {
				fontSize: "12px",
				maxWidth: "100vw",
			},
		},
		"& svg": {
			height: "20px",
			marginLeft: "10px",
		},
	},

	body: {
		overflow: "scroll",
		marginTop: "95px",
		height: "calc(100vh - 95px)",
	},

	pageHeader: {
		position: "relative",
		display: "flex !important",
		alignItems: "center",
		margin: "0",
		border: "0",
		minHeight: "100vh",
		height: "auto",
		color: whiteColor,

		"&:before,&:after": {
			position: "absolute",
			zIndex: "1",
			width: "100%",
			height: "100%",
			display: "block",
			left: "0",
			top: "0",
			content: '""',
		},
	},

	cardHeader: {
		width: "auto",
		textAlign: "center",
	},
	cardSignup: {
		borderRadius: "6px",
		boxShadow:
			"0 16px 24px 2px rgba(" +
			hexToRgb(blackColor) +
			", 0.14), 0 6px 30px 5px rgba(" +
			hexToRgb(blackColor) +
			", 0.12), 0 8px 10px -5px rgba(" +
			hexToRgb(blackColor) +
			", 0.2);",
		marginBottom: "100px",
		padding: "40px 0px",
		minHeight: "60vh",
		height: "auto",
	},
	cardSignUpBody: {
		minHeight: "30vh",
		height: "auto",
	},
	cardTitle: {
		...cardTitle,

		textAlign: "center !important",
		marginBottom: "0.75rem",
		color: whiteColor + " !important",
	},
	...customCheckboxRadioSwitchStyle,
	socials: {
		marginTop: "0",
		position: "absolute",
		width: "100%",
		transform: "none",
		left: "0",
		top: "0",
		height: "100%",
		lineHeight: "41px",
		fontSize: "20px",
	},
	textCenter: {
		textAlign: "center",
	},
	inputAdornment: {
		marginRight: "18px",
		position: "relative",
	},
	inputAdornmentIcon: {
		color: primaryColor[1],
	},
	form: {
		margin: "0",
	},
	infoArea: {
		padding: "0px 30px 20px !important",
		[theme.breakpoints.down("sm")]: {
			padding: "0!important",
		},
	},
	errorText: {
		color: grayColor[4],
	},
	registrationCard: {
		// borderRadius: "18px",
		overflowX: "hidden",
		boxShadow: "none",
		backgroundColor: "#f2f3f7",
		marginBottom: "0px",
		[theme.breakpoints.down("sm")]: {
			marginTop: "0",
		},
	},
	registrationCardBody: {
		[theme.breakpoints.down("md")]: {
			paddingLeft: 0,
			paddingRight: 0,
			paddingTop: 0,
		},
	},
	block: {
		color: "inherit",
		padding: "0.9375rem",
		fontWeight: "500",
		fontSize: "12px",
		textTransform: "uppercase",
		borderRadius: "3px",
		textDecoration: "none",
		position: "relative",
		display: "block",
	},
	inlineBlock: {
		display: "inline-block",
		padding: "0px",
		width: "auto",
	},
	list: {
		marginBottom: "0",
		padding: "0",
		marginTop: "0",
	},
	left: {
		float: "left!important",
		display: "block",
		"&,& *,& *:hover,& *:focus": {
			color: whiteColor + "  !important",
		},
	},
	right: {
		padding: "15px 0",
		margin: "0",
		float: "right",
		"&,& *,& *:hover,& *:focus": {
			color: whiteColor + "  !important",
		},
	},
	icon: {
		width: "18px",
		height: "18px",
		top: "3px",
		position: "relative",
	},
	formDescription: {
		...cardTitle,
		color: blackColor + " !important",
		textAlign: "center",
	},
	formMarginAuto: {
		//marginTop: "auto",
		//marginBottom: "auto",
		//textAlign: "center",
		margin: "auto",
	},
	formAccountTypeMargin: {
		paddingTop: "30px",
		paddingBottom: "30px",
	},
	dividerRight: {
		borderRight: "1px solid rgba(" + hexToRgb(blackColor) + ", 0.35)",
	},
	gridItemMargin: {
		marginTop: "10px",
	},
	registerSocial: {
		textAlign: "center",
		color: "#959595!important",
		fontWeight: "bolder",
		[theme.breakpoints.down("xs")]: {
			fontSize: "14px",
		},
	},
	registerSocialGrid: {
		marginBottom: "1.125rem",
	},
	buttonBorder: {
		borderRadius: "6px",
		//height: "55px",
		fontSize: "16px",
		[theme.breakpoints.down("md")]: {
			fontSize: "15px",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "14px",
		},
	},
	gridHeight: {
		height: "90px",
	},
	gridPaddingRight: {
		paddingRight: "0px",
		zIndex: "1",
		marginBottom: "-7px",
		paddingLeft: "14px",
	},
	gridPaddingLeft: {
		paddingLeft: "0px",
		zIndex: "1",
		marginBottom: "-7px",
		paddingRight: "16px",
	},
	selectedTabButton: {
		height: "60px",
		borderRadius: "18px 18px 0px 0px",
		fontSize: "18px",
		color: opptyDesignColor,
		backgroundColor: whiteColor,
		textTransform: "none",
		// border: "#E6E7EC 2px solid",
		boxShadow: "none",
		"&:hover": {
			color: opptyDesignColor,
			backgroundColor: whiteColor,
			borderRadius: "18px 18px 0 0",
			// border: opptyDesignColor + " 1px solid",
			boxShadow: "none",
		},
		"&:focus": {
			color: opptyDesignColor,
			backgroundColor: whiteColor,
			boxShadow: "none",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "14px",
		},
	},
	selectedTabButtonRight: {
		borderRadius: "18px 18px 0px 0px",
	},
	tabButton: {
		height: "60px",
		borderRadius: "18px 18px 0px 0px",
		fontSize: "18px",
		color: grayColor[0],
		backgroundColor: "#f2f3f7",
		textTransform: "none",
		boxShadow: "none",
		"&:hover": {
			// border: opptyDesignColor + " 1px solid",
			borderRadius: "18px 18px 0 0",
			backgroundColor: "#f2f3f7",
			color: grayColor[0],
			boxShadow: "none",
		},
		"&:focus": {
			backgroundColor: "#f2f3f7",
			color: grayColor[0],
			boxShadow: "none",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "14px",
		},
	},
	tabButtonRight: {
		borderRadius: "18px 18px 0px 0px",
	},
	tabBorder: {
		// border: "2px solid #E6E7EC",
		borderRadius: "0px 0px 18px 18px",
		padding: "30px 30px 10px 30px",
		background: "#fff",
	},
	socialMargin: {
		// marginRight: "auto!important",
	},
	textFieldLabel: {
		color: "#959595!important",
		fontWeight: "bolder",
		margin: "0 0 5px 0",
		fontSize: "16px",
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
		},
	},
	marginTop: {
		marginTop: "15px",
	},
	emailSpan: {
		fontWeight: "900 !important",
	},
	googleButtonContainer: {
		backgroundColor: "#FFFFFF",
		width: "100%",
		/* height: "50px", */
		display: "flex",
		alignItems: "center",
		borderRadius: "5px",
		transition: "background-color .218s, border-color .218s, box-shadow .218s",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		cursor: "pointer",
		color: "gray",
		fontSize: "16px",
		lineHeight: "45px",
		/* border: "1px solid gray", */
		boxShadow:
			"0 2px 2px 0 rgba(" +
			hexToRgb(grayColor[0]) +
			", 0.14), 0 3px 1px -2px rgba(" +
			hexToRgb(grayColor[0]) +
			", 0.2), 0 1px 5px 0 rgba(" +
			hexToRgb(grayColor[0]) +
			", 0.12)",
		"& svg": {
			background: "#FFFFFF",
			borderRadius: "2px",
			textAlign: "center",
			verticalAlign: "top",
			width: "25px",
			height: "25px",
			marginLeft: "10px",
		},
		"&:hover": {
			boxShadow:
				"0 14px 26px -12px rgba(" +
				hexToRgb(grayColor[0]) +
				", 0.42), 0 4px 23px 0px rgba(" +
				hexToRgb(grayColor[0]) +
				", 0.12), 0 8px 10px -5px rgba(" +
				hexToRgb(grayColor[0]) +
				", 0.2)",
		},
		"  &:active": {
			background: "#ffffff",
		},
	},
	socialButtonText: {
		width: "calc(100% - 48px)",
		display: "inline-block",
		/* 	paddingLeft: "15px", */
		fontWeight: "500",
		textAlign: "center",
	},
	facebookButtonContainer: {
		backgroundColor: "#3b5998",
		width: "100%",

		display: "flex",
		alignItems: "center",
		borderRadius: "5px",
		transition: "background-color .218s, border-color .218s, box-shadow .218s",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		cursor: "pointer",
		color: "#fff",
		fontSize: "16px",
		lineHeight: "45px",

		boxShadow:
			"0 2px 2px 0 rgba(" +
			hexToRgb(facebookColor) +
			", 0.14), 0 3px 1px -2px rgba(" +
			hexToRgb(facebookColor) +
			", 0.2), 0 1px 5px 0 rgba(" +
			hexToRgb(facebookColor) +
			", 0.12)",

		"& svg": {
			borderRadius: "2px",
			textAlign: "center",
			verticalAlign: "top",
			width: "25px !important",
			height: "25px",
			marginLeft: "10px",
			fill: "#FFFFFF",
			stroke: "#FFFFFF",
		},

		"&:hover,&:focus": {
			backgroundColor: facebookColor,
			color: whiteColor,
			boxShadow:
				"0 14px 26px -12px rgba(" +
				hexToRgb(facebookColor) +
				", 0.42), 0 4px 23px 0px rgba(" +
				hexToRgb(blackColor) +
				", 0.12), 0 8px 10px -5px rgba(" +
				hexToRgb(facebookColor) +
				", 0.2)",
		},
	},
	registerSocialMargin: {
		margin: "10px 0px !important",
		[theme.breakpoints.down("md")]: {
			margin: "10px 0px !important",
		},
	},
});

export default registrationPageStyle;
