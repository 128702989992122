import React, { useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

// core components
import Button from "components/CustomButtons/Button.js";

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import Close from "@material-ui/icons/Close";

import profileViewActivityPollCardStyles from "../../../../assets/jss/material-kit-pro-react/views/componentStyle/profileView/myActivity/profileViewActivityPollCardStyles.js";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/LandingPageFeed/CommentStyle.js";
import style from "assets/jss/material-kit-pro-react/modalStyle.js";
import IconLibrary from "views/Component/IconLibrary.js";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { getPollResultUserList } from "configurations/redux/socialFeedSlice.js";
import defaultAvatar from "assets/img/candidate_avatar.jpeg";
import { Link } from "react-router-dom";
import { instanceUrl, encryptData } from "configurations/configurations.js";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
			style={{ backgroundColor: "#f2f3f7" }}
		>
			{value === index && <Box>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const useStyles = makeStyles(profilePageStyle);
const useStylesPoll = makeStyles(profileViewActivityPollCardStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

const PollResultUserListModal = ({ open, onClose, poll }) => {
	// console.log(pictureSource);
	const classes = useStyles();
	const classesPoll = useStylesPoll();
	const [value, setValue] = React.useState(0);
	const dispatch = useDispatch();
	const userStatus = useSelector((state) => state.auth.user);
	const pollResultUserList = useSelector(
		(state) => state.socialFeed.pollResultUserList
	);
	const isLoadingPoll = useSelector((state) => state.socialFeed.isLoadingPoll);

	React.useEffect(() => {
		console.log(poll);
		loadPollUserList(poll.poolOptionList[0].poolOptionId);
	}, []);

	const loadPollUserList = (poolOptionId) => {
		const request = {
			email: userStatus.email,
			acctId: userStatus.acctId,
			accessToken: localStorage.getItem("token"),
			poolOptionId: poolOptionId,
			lastRecordSize: 0,
			requestRecordSize: 1000,
		};
		console.log(request);
		dispatch(getPollResultUserList(request));
	};

	const handleChange = (event, newValue) => {
		setValue(newValue);
		console.log(newValue);
	};

	useEffect(() => {
		console.log(pollResultUserList);
	}, [pollResultUserList]);

	const modalResize = () => {
		var modalResize = document.getElementById("pollResultUser");
		if (modalResize) {
			modalResize.style.maxHeight = "440px";
			var maxSize = window.innerHeight - 74 - 80 - 70;
			if (maxSize < 0) maxSize = 0;
			modalResize.style.height = maxSize + "px";
		}
	};

	React.useEffect(() => {
		if (open) {
			if (document.readyState === "complete") {
				setTimeout(() => {
					modalResize();
				}, 500);
			}
			window.addEventListener("resize", modalResize);
			return function cleanup() {
				window.removeEventListener("resize", modalResize);
			};
		}
	}, [open]);

	const ResultCards = poll.poolOptionList.map((item, index) => (
		<div key={index} style={{ overflow: "auto", maxHeight: "440px" }}>
			<TabPanel key={index} value={value} index={index}>
				<List sx={{ bgcolor: "background.paper" }}>
					{pollResultUserList ? (
						pollResultUserList.map((item, index) => (
							<Link
								key={"pollUserList_" + index}
								to={{
									pathname: `/view-profile/${encryptData(item.acctId)}`,
								}}
								target="_blank"
								rel="noopener noreferrer"
							>
								<ListItem className={classesPoll.pollUserListItem}>
									<ListItemAvatar>
										<Avatar>
											<img
												style={{ width: "40px" }}
												src={item.profilePic ? item.profilePic : defaultAvatar}
												alt=""
											/>
										</Avatar>
									</ListItemAvatar>
									<ListItemText
										className={classesPoll.pollUserListText}
										primary={item.firstName + " " + item.surname}
										secondary={item.headline}
									/>
								</ListItem>
							</Link>
						))
					) : isLoadingPoll ? (
						<div>Loading...</div>
					) : null}
				</List>
			</TabPanel>
		</div>
	));

	return (
		<React.Fragment>
			<Dialog
				classes={{
					root: classes.modalRoot,
					paper: classes.modalCommentEdit + " " + classes.modal,
				}}
				open={open}
				// TransitionComponent={Transition}
				// keepMounted
				// disableBackdropClick
				onClose={(event, reason) => {
					if (reason !== "backdropClick") {
						onClose(event, reason);
					}
				}}
				aria-labelledby="classic-modal-slide-title"
				aria-describedby="classic-modal-slide-description"
			>
				<DialogTitle
					id="classic-modal-slide-title"
					disableTypography
					className={classes.modalTitleEdit}
				>
					<Button
						simple
						className={classes.modalCloseButton}
						key="close"
						aria-label="Close"
						onClick={onClose}
					>
						<IconLibrary.Close />
					</Button>
					<h6 className={classes.title + " " + classes.titleAlign}>Votes</h6>
				</DialogTitle>
				<DialogContent
					id="classic-modal-slide-description"
					className={classes.modalBody}
				>
					<div>
						<Box sx={{ width: "100%" }}>
							<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
								<Tabs
									value={value}
									onChange={handleChange}
									// variant="fullWidth"
									variant="scrollable"
									scrollButtons
									aria-label="basic tabs example"
									sx={{
										[`& .${tabsClasses.scrollButtons}`]: {
											"&.Mui-disabled": { opacity: 0.3 },
										},
									}}
									className={classesPoll.pollTabs}
								>
									{poll.poolOptionList.map((item, index) => (
										<Tab
											style={{ color: "#6F52ED" }}
											key={item.poolOptionId}
											label={item.optionDesc}
											{...a11yProps(item.poolOptionId)}
											onClick={() => {
												console.log(item.poolOptionId);
												loadPollUserList(item.poolOptionId);
											}}
										/>
									))}
								</Tabs>
							</Box>
							<div
								id="pollResultUser"
								key="pollResultUser"
								style={{
									overflow: "auto",
									height: "440px",
									transition: "height 0.5s ease",
								}}
							>
								{ResultCards}
							</div>
						</Box>
					</div>
				</DialogContent>
				<div className={classes.modalFooter}></div>
			</Dialog>
		</React.Fragment>
	);
};
const mapStateToProps = (state) => ({
	userStatus: state.auth.user,
	// image: state.user.image,
});
export default connect(mapStateToProps, null)(PollResultUserListModal);
