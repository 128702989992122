import {
	whiteColor,
	grayColor,
	hexToRgb,
	blackColor,
	title,
} from "assets/jss/material-kit-pro-react.js";

const modalStyle = (theme) => ({
	modalRoot: {
		overflow: "auto",
		display: "block",
		width: "auto",
	},
	inherit: {
		width: "100%",
		height: "inherit",
	},

	modal: {
		[theme.breakpoints.up("sm")]: {
			maxWidth: "500px",
			margin: "auto",
		},
		[theme.breakpoints.up("md")]: {
			maxWidth: "700px",
			margin: "auto",
		},
		[theme.breakpoints.up("xs")]: {
			maxWidth: "100%",
			margin: "0",
		},
		borderRadius: "6px",
		overflow: "visible",
		maxHeight: "unset",
		width: "100%",
		marginTop: "130px",
	},
	modalPictureEdit: {
		borderRadius: "6px",
		overflow: "visible",
		maxHeight: "unset",
		maxWidth: "80vw !important",

		width: "100%",
		[theme.breakpoints.down("md")]: {
			maxWidth: "700px",
			margin: "auto",
		},
		marginTop: "130px",
	},
	modalGroupRoot: {
		/* height: "100%", */
		maxHeight: "unset !important",
		[theme.breakpoints.down("md")]: {
			maxHeight: "80vh",
			margin: "auto",
		},
	},
	modalCoverPictureEdit: {
		borderRadius: "6px",
		overflow: "visible",
		maxHeight: "unset",
		maxWidth: "80vw !important",

		width: "100%",
		[theme.breakpoints.down("md")]: {
			maxWidth: "700px",
			margin: "auto",
		},
		// marginTop: "130px",
	},
	mediaHeight: {
		height: "35vh",
	},
	cardMargin: {
		marginBottom: "20px",
	},
	itemCenter: {
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
	},
	modalLoader: {
		maxWidth: "500px",
		height: "250px",
		width: "100%",
		margin: "auto",
	},
	modalLoader2: {
		maxWidth: "500px",
		height: "300px",
		width: "100%",
		margin: "auto",
	},
	modalProfileEdit: {
		maxWidth: "90vw",
		height: "auto",
		[theme.breakpoints.up("sm")]: {
			maxHeight: "80vh",
			minHeight: "70vh",
		},
		margin: "auto",
		width: "100% ",
	},
	loadingProgress: {
		margin: "auto",
		align: "center",
		display: "flex",
	},
	gridLoading: {
		marginTop: "40px",
		marginBottom: "40px",
	},
	modalHeader: {
		borderBottom: "none",
		paddingTop: "24px",
		paddingRight: "24px",
		paddingBottom: "0",
		paddingLeft: "24px",
		minHeight: "16.43px",
	},
	modalTitle: {
		color: grayColor[1],
		textDecoration: "none",
		fontWeight: "700",
		fontFamily: `"Roboto Slab", "Times New Roman", serif`,

		margin: "0",
		lineHeight: "1.5",
	},
	modalTitleEdit: {
		color: grayColor[1],
		textDecoration: "none",
		fontWeight: "700",
		fontFamily: `"Roboto Slab", "Times New Roman", serif`,
		paddingTop: "26px",
		margin: "0",
		lineHeight: "1.5",
		backgroundColor: "#fff",
		borderRadius: "18px 18px 0px 0px",
	},
	modalCloseButton: {
		"&, &:hover": {
			color: blackColor,
		},
		"&:hover": {
			opacity: "1",
		},
		cursor: "pointer",
		padding: "1rem",
		margin: "-1rem -1rem -1rem auto",
		backgroundColor: "transparent",
		border: "0",
		WebkitAppearance: "none",
		float: "right",
		fontSize: "1.5rem",
		fontWeight: "500",
		color: blackColor + "!important",
		lineHeight: "1",
		textShadow: "0 1px 0 " + whiteColor,
		opacity: ".8",
		zIndex: "100",
	},
	modalClose: {
		width: "20px",
		height: "20px",
	},
	modalBody: {
		paddingTop: "24px",
		paddingRight: "24px",
		paddingBottom: "16px",
		paddingLeft: "24px",
		position: "relative",
		overflow: "visible",
	},
	modalContent: {
		paddingTop: "24px",
		paddingRight: "24px",
		paddingBottom: "16px",
		paddingLeft: "24px",
		[theme.breakpoints.up("md")]: {
			height: "850px",
		},
		height: "600px",
		overflowY: "scroll",
	},
	modalFooter: {
		padding: "15px",
		textAlign: "right",
		paddingTop: "0",
		margin: "0",
	},
	modalFooterEdit: {
		padding: "15px",
		textAlign: "right",
		paddingBottom: "0",
		margin: "0",
		backgroundColor: "#f2f3f7",
		// borderTop: "2px solid gray",
	},
	modalFooterPrivacy: {
		padding: "15px",
		[theme.breakpoints.up("md")]: {
			textAlign: "right",
		},

		margin: "0",
		paddingBottom: "0",
		margin: "0",
		backgroundColor: "#f2f3f7",
		// borderTop: "2px solid gray",
	},
	modalFooterCenter: {
		marginLeft: "auto",
		marginRight: "auto",
	},
	instructionNoticeModal: {
		marginBottom: "25px",
	},
	imageNoticeModal: {
		maxWidth: "150px",
	},
	modalLarge: {
		[theme.breakpoints.up("md")]: {
			maxWidth: "800px",
		},
	},
	modalSmall: {
		[theme.breakpoints.up("sm")]: {
			width: "300px",
			margin: "auto",
		},
		margin: "0 auto",
	},
	modalSmallBody: {
		marginTop: "20px",
	},
	modalSmallFooterFirstButton: {
		margin: "0",
		paddingLeft: "16px",
		paddingRight: "16px",
		width: "auto",
	},
	modalSmallFooterSecondButton: {
		marginBottom: "0",
		marginLeft: "5px",
	},
	modalLogin: {
		maxWidth: "360px",
		overflowY: "visible",
		width: "100%",
		"& $modalCloseButton": {
			color: whiteColor,
			top: "-10px",
			right: "10px",
			textShadow: "none",
			position: "relative",
		},
		"& $modalHeader": {
			borderBottom: "none",
			paddingTop: "24px",
			paddingRight: "24px",
			paddingBottom: "0",
			paddingLeft: "24px",
		},
		"& $modalBody": {
			paddingBottom: "0",
			paddingTop: "0",
		},
		"& $modalFooter": {
			paddingBottom: "0",
			paddingTop: "0",
		},
	},
	modalLoginCard: {
		marginBottom: "0",
		margin: "0",
		"& $modalHeader": {
			paddingTop: "0",
		},
	},
	modalSignup: {
		maxWidth: "900px",
		width: "100%",
		"& $modalHeader": {
			paddingTop: "0",
		},
		"& $modalTitle": {
			textAlign: "center",
			width: "100%",
			marginTop: "0.625rem",
		},
		"& $modalBody": {
			paddingBottom: "0",
			paddingTop: "0",
		},
	},
	modalSignupCard: {
		padding: "40px 0",
		margin: "0",
	},
	imgContainer: {
		position: "relative",
		flex: 1,
		padding: "30px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	img: {
		height: "200px",
		width: "200px",
	},
	imgRaised: {
		boxShadow:
			"0 5px 15px -8px rgba(" +
			hexToRgb(blackColor) +
			", 0.24), 0 8px 10px -5px rgba(" +
			hexToRgb(blackColor) +
			", 0.2)",
	},
	imgRoundedCircle: {
		borderRadius: "50% !important",
	},
	modalBodyHeight: {
		height: "70vh",
		[theme.breakpoints.up("md")]: {
			height: "70vh",
		},
		padding: "0",
		overflowY: "scroll",
		backgroundColor: "#F2F3F7",
	},
	modalBodyHeightDesktop: {
		height: "15vh", //"70vh",
		// [theme.breakpoints.up("xs")]: {
		// 	height: "600px",
		// 	maxHeight: "45vh",
		// },
		// [theme.breakpoints.up("sm")]: {
		// 	height: "600px",
		// 	maxHeight: "55vh",
		// },
		// [theme.breakpoints.up("md")]: {
		// 	height: "600px",
		// 	maxHeight: "60vh",
		// },
		// [theme.breakpoints.up("lg")]: {
		// 	height: "70vh",
		// 	maxHeight: "70vh",
		// },
		padding: "0",
		overflowY: "scroll",
		backgroundColor: "#F2F3F7",
	},
	modalBodyHeightJob: {
		// [theme.breakpoints.up("md")]: {
		// 	height: "70vh",
		// },
		height: "15vh", //"70vh",
		padding: "0 !important",
		backgroundColor: "#F2F3F7",
		overflowY: "auto",
		overflowX: "hidden",
		/* "& $modalBody": {
			padding: "0 10px !important",
		}, */
		borderRadius: "18px",
	},
	modalBodyHeightJobDesktop: {
		height: "15vh", //"70vh",
		// [theme.breakpoints.up("xs")]: {
		// 	height: "600px",
		// 	maxHeight: "45vh",
		// },
		// [theme.breakpoints.up("sm")]: {
		// 	height: "600px",
		// 	maxHeight: "55vh",
		// },
		// [theme.breakpoints.up("md")]: {
		// 	height: "600px",
		// 	maxHeight: "60vh",
		// },
		// [theme.breakpoints.up("lg")]: {
		// 	height: "70vh",
		// 	maxHeight: "70vh",
		// },
		padding: "0 !important",
		backgroundColor: "#F2F3F7",
		overflowY: "auto",
		overflowX: "hidden",
		/* "& $modalBody": {
			padding: "0 10px !important",
		}, */
		borderRadius: "18px",
	},
	smallModalBody: {
		// height: "15vh", //"55vh",
		/* overflowY: "scroll", */
		overflowY: "auto",
		backgroundColor: "#F2F3F7",
		transition: "height 0.5s ease",
		overflowY: "scroll",
	},
	smallModalBodyDesktop: {
		// height: "0", //"15vh", //"70vh",
		overflowY: "auto",
		backgroundColor: "#F2F3F7",
		transition: "height 0.5s ease",
		/* 	overflowY: "scroll", */
		// [theme.breakpoints.up("xs")]: {
		// 	height: "600px",
		// 	maxHeight: "45vh",
		// },
		// [theme.breakpoints.up("sm")]: {
		// 	height: "600px",
		// 	maxHeight: "55vh",
		// },
		// [theme.breakpoints.up("md")]: {
		// 	height: "600px",
		// 	maxHeight: "60vh",
		// },
		// [theme.breakpoints.up("lg")]: {
		// 	height: "70vh",
		// 	maxHeight: "70vh",
		// },
	},
	modalIndex: {
		zIndex: "9999!important",
	},
});

export default modalStyle;
