const suggestedGroupCardStyles = () => ({

  main: {
    overflow: "hidden",
    flex: "1 1 320px",
    border: "solid 1px #F1F1F5",
    borderRadius: "15px",
    backgroundColor: "#fff",
  },

  groupImageContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "140px",

    '& > img': {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },

  contentContainer: {
    padding: "15px",
  },

  groupName: {
    color: "#171725",
    fontSize: "14px",
    fontWeight: 500,
  },

  groupDetails: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "7px",
  },

  groupDescription: {
    color: "#92929D",
    fontSize: "14px",
    fontWeight: 400,
  },

  groupMembers: {
    display: "flex",
    alignItems: "center",
    marginLeft: "20px",
    color: "#92929D",
    fontSize: "14px",
    fontWeight: 400,

    '& > svg': {
      marginRight: "5px",
    },
  },

  joinBtn: {
    marginTop: "30px",
    borderRadius: "8px",
    backgroundColor: "#F1F1F5",
    padding: "9px",
    width: "100%",
    color: "#696974",
    fontSize: "12px",
    fontWeight: 600,
    textTransform: "capitalize",
  },

})
export default suggestedGroupCardStyles