import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CoverImageCrop from "../../../Component/ImageCrop/CoverImageCrop.js";
import SyncContactAccountSection from "./SyncContactAccountSection.js";
import SyncContactListSection from "./SyncContactListSection.js";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js";
import { Divider } from "@material-ui/core";
import IconLibrary from "views/Component/IconLibrary.js";
import { Hidden, IconButton } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import CustomDialog from "../../../../components/CustomDialog/CustomDialog.js";
import { sendAllContactInvitation } from "configurations/redux/userSlice.js";
import { useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
			style={{ backgroundColor: "#f2f3f7" }}
		>
			{value === index && <Box>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

// const useStyles = makeStyles((theme) => ({
// 	root: {
// 		flexGrow: 1,
// 		backgroundColor: theme.palette.background.paper,
// 	},
// }));

const StyledTabs = withStyles((theme) => ({
	indicator: {
		display: "flex",
		justifyContent: "center",
		backgroundColor: "transparent",
		"& > span": {
			width: "100%",
			backgroundColor: "#687BFE",
			margin: "-2px",
		},
	},
}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
	root: {
		textTransform: "none",
		color: "#959595",
		fontWeight: "500",
		fontSize: "16px",
		marginRight: theme.spacing(1),
		"&:focus": {
			color: "#687BFE",
		},
		"&$selected": {
			color: "#687BFE",
		},
	},
	selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles(profilePageStyle);

const SyncContactTab = ({ open, user }) => {
	const classes = useStyles();
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const contactAcctList = useSelector((state) => state.user.contactAcctList);
	const userStatus = useSelector((state) => state.auth.user);
	const isSendAllSuccess = useSelector((state) => state.user.isSendAllSuccess);
	const dispatch = useDispatch();

	React.useEffect(() => {
		if (open) {
			if (document.readyState === "complete") {
				if (contactAcctList && contactAcctList.length === 0) setValue(1);
			}
		}
	}, [contactAcctList]);

	React.useEffect(() => {
		if (isSendAllSuccess)
			snackbarCustom("The invitation process is submitted successfully.");
	}, [isSendAllSuccess]);

	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const snackBarShow = (message, variant, action) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(message, {
			variant: variant,
			action,
		});
	};
	const onClickDismiss = (key) => () => {
		closeSnackbar(key);
	};

	const snackbarCustom = (title) => {
		const action = (key) => (
			<React.Fragment>
				<IconButton onClick={onClickDismiss(key)}>
					<CloseIcon />
				</IconButton>
			</React.Fragment>
		);
		snackBarShow(title, "default", action);
	};

	return (
		<div className={classes.tabStyle}>
			<AppBar position="static">
				<StyledTabs
					value={value}
					onChange={handleChange}
					aria-label="simple tabs example"
				>
					<StyledTab label="Imported Contacts" {...a11yProps(0)} />
					<StyledTab label="Invite New Contacts" {...a11yProps(1)} />
				</StyledTabs>
			</AppBar>
			<Divider />
			<TabPanel value={value} index={0}>
				<SyncContactListSection open={open} user={user} />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<SyncContactAccountSection open={open} user={user} />
			</TabPanel>
		</div>
	);
};
export default SyncContactTab;
