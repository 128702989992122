import {
	container,
	cardTitle,
	title,
	mlAuto,
	mrAuto,
	main,
	whiteColor,
	grayColor,
	blackColor,
	cardDetail,
	hexToRgb,
	opptyDesignColor,
} from "assets/jss/material-kit-pro-react.js";

import imagesStyle from "assets/jss/material-kit-pro-react/imagesStyles.js";
import customSelectStyle from "assets/jss/material-kit-pro-react/customSelectStyle.js";
import customCheckboxRadioSwitchStyle from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.js";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.js";
import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.js";
import hashTagStyle from "../../componentStyle/hashTagStyle.js";

const profilePageStyles = (theme) => ({
	...imagesStyle,
	...customCheckboxRadioSwitchStyle,
	...modalStyle(theme),
	...customSelectStyle,
	...tooltipsStyle,
	...hashTagStyle,

	body: {
		overflow: "scroll",
		marginTop: "94px",
		height: "calc(100vh - 95px)",
	},

	pageHeader: {
		position: "relative",
		zIndex: 3,
		display: "flex !important",
		alignItems: "center",
		margin: "0",
		backgroundColor: "#F2F3F7",
		border: "0",
		minHeight: "100vh",
		height: "auto",
		color: "#fff",
	},

	container: {
		...container,
		"@media (min-width: 576px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 768px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 992px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 1200px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 1400px)": {
			maxWidth: "80vw",
		},
		// "@media (min-width: 576px)": {
		// 	maxWidth: "540px",
		// },
		// "@media (min-width: 768px)": {
		// 	maxWidth: "720px",
		// },
		// "@media (min-width: 992px)": {
		// 	maxWidth: "960px",
		// },
		// "@media (min-width: 1200px)": {
		// 	maxWidth: "1140px",
		// },
		// "@media (min-width: 1400px)": {
		// 	maxWidth: "80vw",
		// },
	},

	coverPhotoGridItem: {
		padding: 0,
	},

	parallax: {
		backgroundSize: "cover",
		backgroundPosition: "center",
		background: "#fff",
		height: "44vh",
		backgroundColor: "#ffffff",
		[theme.breakpoints.down("xs")]: {
			height: "15vh",
		},
	},

	coverIcon: {
		position: "absolute",
		zIndex: 4,
		top: "40vh",
		right: "10px",
	},

	cardTitleWhite: {
		...cardTitle,

		color: whiteColor + "  !important",
	},

	mlAuto,
	mrAuto,
	cardDetail: {
		...cardDetail,
		whiteSpace: "pre-line",
	},
	cardTitle,
	cardSection: {
		borderRadius: "12px",
	},
	cardInfo: {
		backgroundColor: "#F2F3F7",
		borderRadius: "12px",
		boxShadow: "none",
		[theme.breakpoints.up("xs")]: {
			marginTop: "15px",
			marginBottom: "15px",
		},
		[theme.breakpoints.down("xs")]: {
			marginTop: "5px",
			marginBottom: "0",
		},
	},
	menuIcon: {
		paddingLeft: "0px !important",
		paddingRight: "0px !important",
		/* marginTop: "10px", */
		textAlign: "center",
		"& svg": {
			width: "30px",
			height: "30px",
			color: opptyDesignColor,
		},
		/* "& Button": {
			padding: "0px !important",
		}, */
		/* [theme.breakpoints.down("md")]: {
			"& button": {
				padding: "10px 10px 10px 10px",
			},
			textAlign: "right",
			marginTop: "20px",
			"& svg": {
				width: "30px",
				height: "30px",
			},
		},
		[theme.breakpoints.down("xs")]: {
			textAlign: "left",
			marginTop: "25px",
			"& svg": {
				width: "30px",
				height: "30px",
			},
		}, */
	},
	buttonIconMargin: {
		"& svg": {
			width: "28px !important",
			height: "28px !important",
			color: opptyDesignColor,
			border: "3px solid" + opptyDesignColor,
			padding: "1px",
			borderRadius: "5px",
			/* backgroundColor: "#000000", */
			[theme.breakpoints.down("xs")]: {
				width: "22px !important",
				height: "22px !important",
				border: "2px solid" + opptyDesignColor,
			},
		},
		display: "flex",
		alignItems: "center",

		float: "right",
		/* margin: "5px 5px !important", */
		/* 	border: "3px solid" + opptyDesignColor, */

		padding: "0px !important",
	},
	deleteIcon: {
		"& svg": {
			color: "#bcbcbc !important",
		},
	},
	profile: {
		textAlign: "center",
		"& img": {
			maxWidth: "180px",
			maxHeight: "180px",
			height: "100%",
			width: "100%",
			margin: "0 auto",
			boxShadow: "none",
			transform: "translate3d(0, -70%, 0)",
			cursor: "pointer",
			"&:hover,&:focus": {
				//color: whiteColor,
				//backgroundColor: grayColor[0],
				boxShadow:
					"0 14px 26px -12px rgba(" +
					hexToRgb(grayColor[0]) +
					", 0.42), 0 4px 23px 0px rgba(" +
					hexToRgb(blackColor) +
					", 0.12), 0 8px 10px -5px rgba(" +
					hexToRgb(grayColor[0]) +
					", 0.2)",
			},
			[theme.breakpoints.down("xs")]: {
				width: "auto",
				height: "120px",
			},
		},
	},
	cardFrame: {
		borderRadius: "18px",
		boxShadow: "none",
		[theme.breakpoints.down("xs")]: {
			marginTop: "10px",
			marginBottom: "10px",
		},
	},
	cardPadding: {
		paddingRight: "15px",
		paddingLeft: "15px",
		[theme.breakpoints.down("xs")]: {
			paddingRight: "5px",
			paddingLeft: "5px",
		},
	},
	pictureButtonFooter: {
		justifyContent: "flex-end",
	},
	grayFont: {
		color: "#99B2C6 !important",
	},
	grayFontCard: {
		color: "#9F9FB7 !important",
	},
	jobTitle: {
		...title,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		position: "relative",
		marginBottom: "0px !important",
		marginTop: "10px !important",
		textDecoration: "none",
		color: blackColor + "!important",
		minHeight: "0px",
		fontSize: "16px",
		"& svg": {
			width: "32px",
			height: "32px",
			marginRight: "20px",
			marginTop: "10px",
		},
		[theme.breakpoints.down("xs")]: {
			marginTop: "0!important",
			marginBottom: "5px!important",
		},
	},
	checkBoxLabel: {
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		fontSize: "16px",
	},
	description: {
		margin: "15px 0px 0px 0px !important",
		/* maxWidth: "600px", */
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		color: blackColor[0],
		fontWeight: "500",
		whiteSpace: "pre-line",
		// [theme.breakpoints.down("xs")]: {
		// 	fontSize: "12px!important",
		// 	margin: "5px 0 5px 0!important",
		// },
		[theme.breakpoints.down("sm")]: {
			fontSize: "15px !important",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "11px !important",
			margin: "0!important",
		},
	},
	tagDescription: {
		margin: "15px 0px !important",

		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		color: "#000000",
		fontWeight: "500",
		whiteSpace: "pre-line",
	},
	tooltipDescription: {
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		color: blackColor[0],
		fontWeight: "500",
		whiteSpace: "pre-line",
		margin: "10px 0px !important",
	},
	titleDescription: {
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		color: blackColor[0],
		fontSize: "16px",
		fontWeight: "700",
		margin: "0px !important",
		[theme.breakpoints.down("xs")]: {
			fontSize: "15px",
		},
	},
	smallDescription: {
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		color: blackColor[0],
		fontSize: "12px",
		fontWeight: "400",
		margin: "0px !important",
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
		},
	},
	headlineDescription: {
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		color: blackColor[0],
		fontSize: "16px",
		fontWeight: "500!important",
		margin: "20px 0px !important",
		// [theme.breakpoints.down("xs")]: {
		// 	fontSize: "12px!important",
		// 	margin: "5px 0 5px 0!important",
		// },
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px!important",
			lineHeight: "1rem",
			margin: "0!important",
		},
	},
	mediumDescription: {
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		color: blackColor[0],
		fontSize: "14px",
		fontWeight: "500!important",
		margin: "0px !important",
		// [theme.breakpoints.down("xs")]: {
		// 	fontSize: "12px!important",
		// 	margin: "5px 0 5px 0!important",
		// },
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px!important",
			lineHeight: "1rem",
			margin: "0!important",
		},
	},
	descriptionContainer: {
		marginTop: "5px",
		marginBottom: "10px",
		"& p": {
			margin: "-5px 0px 0px 0px !important",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
			marginBottom: "0",
			"& p": {
				paddingBottom: "5px",
			},
		},
	},
	textCenter: {
		textAlign: "center !important",
	},
	textValue: {
		color: "blackColor[0] !important",
		textDecoration: "none",
	},
	name: {
		marginTop: "-80px",
	},

	main: {
		...main,
	},
	mainRaised: {
		marginTop: "0vh", //"30vh",
		background: "#F2F3F7",
		// [theme.breakpoints.down("sm")]: {
		// 	marginTop: "200px",
		// },
		margin: "0px !important",
		borderRadius: "0px 0px 6px 6px",
	},
	title: {
		...title,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		position: "relative",
		marginTop: "5px",
		marginBottom: "5px",
		fontSize: "16px",
		minHeight: "0px",
		textDecoration: "none",
		color: blackColor + "!important",
		"& svg": {
			width: "32px",
			height: "32px",
			marginRight: "20px",
			marginTop: "10px",
		},
		[theme.breakpoints.down("xs")]: {
			marginTop: "0!important",
			marginBottom: "5px!important",
			fontSize: "15px",
		},
	},
	tabTypeTitleSection: {
		...title,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		position: "relative",
		marginTop: "5px",
		marginBottom: "5px",
		fontSize: "16px",
		minHeight: "0px",
		textDecoration: "none",
		color: blackColor + "!important",
		"& svg": {
			width: "32px",
			height: "32px",
			marginRight: "20px",
			marginTop: "10px",
		},
	},
	userTitle: {
		fontSize: "24px",
		margin: "15px 0 10px 0",
		[theme.breakpoints.down("xs")]: {
			fontSize: "16px",
			margin: "0 0 5px 0",
		},
	},
	titleIcon: {
		width: "32px",
		height: "32px",
		marginRight: "20px",
		marginTop: "10px",
		display: "inline-block",
	},
	message: {
		position: "absolute",
		top: "0",
		right: "24%",
		"@media (min-width: 0px)": {
			right: "0",
		},
		"@media (min-width: 576px)": {
			right: "16%",
		},
		"@media (min-width: 768px)": {
			right: "24%",
		},
		"@media (min-width: 960px)": {
			right: "10%",
		},
		"@media (min-width: 992px)": {
			right: "18%",
		},
		"@media (min-width: 1200px)": {
			right: "24%",
		},
	},
	addIcon: {
		width: "32px",
		height: "32px",
	},
	follow: {
		position: "absolute",
		top: "0",
		right: "calc(24% + 50px)",
		"@media (min-width: 0px)": {
			right: "50px",
		},
		"@media (min-width: 576px)": {
			right: "calc(16% + 50px)",
		},
		"@media (min-width: 768px)": {
			right: "calc(24% + 50px)",
		},
		"@media (min-width: 960px)": {
			right: "calc(10% + 50px)",
		},
		"@media (min-width: 992px)": {
			right: "calc(18% + 50px)",
		},
		"@media (min-width: 1200px)": {
			right: "calc(24% + 50px)",
		},
	},
	edit: {
		position: "absolute",
		top: "-16px",
		right: "-16px",
		[theme.breakpoints.down("xs")]: {
			right: "-5px",
		},
	},
	followButton: {
		marginTop: "-30px !important",

		"&:hover": {
			backgroundColor: "#6F52ED9C",
		},
	},
	followIcon: {
		width: "80px",
		height: "20px",
	},
	editButton: {
		minWidth: "35px",
		width: "35px",
		height: "35px",
	},
	gridItem: {
		...mlAuto,
		...mrAuto,
	},
	infoDescription: {
		fontSize: "16px !important",
		fontWeight: "500",
	},
	collections: {
		marginTop: "20px",
	},
	cardBody: {
		display: "flex",
		boxOrient: "vertical",
		boxDirection: "normal",
		flexDirection: "column",
		boxPack: "center",
		justifyContent: "center",
	},
	badge: {
		display: "inline-table",
		margin: "0 auto",
	},
	listUnstyled: {
		paddingLeft: "0",
		listStyle: "none",
		"& > li": {
			padding: "5px 0px",
			fontSize: "1em",
		},
	},
	profileTabs: {
		marginTop: "4.284rem",
		marginBottom: "50px",
	},
	card: {
		textAlign: "left !important",
	},
	custInput: {
		width: "100%",
		margin: "auto",
	},
	block: {
		color: "inherit",
		padding: "0.9375rem",
		fontWeight: "500",
		fontSize: "12px",
		textTransform: "uppercase",
		borderRadius: "3px",
		textDecoration: "none",
		position: "relative",
		display: "block",
	},
	cardWidth: {
		width: "550px",
	},
	inlineBlock: {
		display: "inline-block",
		padding: "0px",
		width: "auto",
	},
	list: {
		marginBottom: "0",
		padding: "0",
		marginTop: "0",
	},
	left: {
		float: "left!important",
		display: "block",
	},
	right: {
		padding: "15px 0",
		margin: "0",
		float: "right",
	},
	icon: {
		width: "18px",
		height: "18px",
		top: "3px",
		position: "relative",
	},
	info: {
		paddingBottom: 0,
		paddingTop: 0,
		margin: "0",
		width: "auto",
		"& svg": {
			color: "#000000",
		},
		"& h4": {
			marginLeft: "15px !important",
		},
	},
	fontStyle: {
		fontFamily: `"Roboto Slab", "Times New Roman", serif`,
		textDecoration: "none",
	},
	modalProfileEdit: {
		backgroundColor: "#f2f3f7",
		overflow: "hidden !important",
		maxWidth: "1000px",
		width: "100%",
		"& $modalHeader": {
			paddingTop: "0",
		},
		"& $modalTitle": {
			width: "100%",
			paddingBottom: "10px !important",
		},
		"& $modalBody": {
			maxHeight: "73.5vh",
			paddingBottom: "0",
			paddingTop: "25px",
		},
		"& $modalFooter": {
			marginBottom: "1.125rem",
		},
	},
	modalProfilePicEdit: {
		backgroundColor: "#f2f3f7",
		maxWidth: "1000px",
		width: "100%",
		"& $modalHeader": {
			paddingTop: "0",
		},
		"& $modalTitle": {
			width: "100%",
			marginTop: "0.625rem",
		},
		"& $modalBody": {
			paddingBottom: "0",
			paddingTop: "20px",
		},
	},
	blackColor: {
		color: "#000000",
	},
	modalProfileBuild: {
		maxWidth: "900px",
		width: "100%",
		[theme.breakpoints.down("md")]: {
			maxWidth: "80vw",
		},
	},
	modalProfileBuildBody: {
		backgroundColor: "#f2f3f7",
	},
	autoCompleteSelect: {
		marginTop: "12px !important",
	},
	phoneContact: {
		marginTop: "18px !important",
	},
	updateButton: {
		paddingBottom: "20px !important",
	},
	iconMargin: {
		marginTop: "12px !important",
		marginRight: "10px !important",
	},
	titleMargin: {
		marginLeft: "15px !important",
	},
	buttonGrid: {
		justifyContent: "center",
		alignItems: "center",
	},

	buttonColor: {
		/* margin: "13px 10px  0px 0px !important", */
		/* marginRight: "10px", */
		padding: "0 !important",
		minHeight: "32px",
		color: opptyDesignColor,
		textAlign: "right",
		/* "&:hover,&:focus": {
			boxShadow:
				"0 14px 26px -12px rgba(" +
				hexToRgb(grayColor[0]) +
				", 0.42), 0 4px 23px 0px rgba(" +
				hexToRgb(blackColor) +
				", 0.12), 0 8px 10px -5px rgba(" +
				hexToRgb(grayColor[0]) +
				", 0.2)",
		}, */
		"& svg": {
			width: "32px",
			height: "32px",
		},
	},
	updateGridMargin: {
		paddingTop: "15px !important",
		paddingBottom: "15px !important",
	},
	experienceText: {
		"& p": {
			marginTop: "15px !important",
			marginBottom: "15px !important",
		},
	},
	privacyTrueCard: {
		borderRadius: "18px",
		boxShadow: "none",
		"& h5": {
			margin: "5% 0",
			textAlign: "center",
		},
	},
	backGroundImage: {
		width: "100%",
		height: "100%",
	},
	itemCenter: {
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
	},
	hashTagContainer: {
		border: "#f2f3f7 solid 2px",
		borderRadius: "11px",
		padding: "15px",
	},
	contactInfo: {
		display: "inline-block",
	},
	actionButton: {
		alignItems: "center",
		alignContent: "center",

		"& button": {
			fontSize: "14px",
			fontWeight: 600,
			fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		},
	},
	infoArea: {
		"& svg": {
			width: "25px !important",
			height: "25px !important",
			marginRight: "10px",
		},
	},
	infoText: {
		margin: "10px 0px",
		display: "flex",
		alignItems: "center",
	},
	iconWrapper: {
		float: "left",
		width: "36px",
		height: "36px",
		color: grayColor[0] + "!important",
		marginTop: "10px",
		marginRight: "10px",
	},
	gridHeight: {
		height: "80px",
	},
	gridPadding: {
		paddingBottom: "35px",
	},
	checkMargin: {
		// marginTop: "-15px",
		height: "70px",
	},
	cardBorder: {
		boxShadow: "none!important",
		border: "2px solid #E6E7EC",
		borderRadius: "12px",
		color: "#9a9a9a",
	},
	infoCardBorder: {
		boxShadow: "none!important",
		border: "2px solid #E6E7EC",
		borderRadius: "12px",
		color: "#9a9a9a",
		marginBottom: "15px",
		marginTop: "15px",
	},
	tipTextSection: {
		/* 	textAlign: "center", */
		fontWeight: "400",
		/* 	fontSize: "12px", */
		"& b": {
			fontSize: "18px",
			textTransform: "uppercase",
		},
		"& p": {
			fontSize: "14px",
			fontStyle: "italic",
		},
		/* 	margin: "15px 0px !important", */
		"& svg": {
			/* marginRight: "10px",
			width: "18px",
			height: "18px", */
			background: "#FFFFFF",
			width: "60px !important",
			height: "60px",
			borderRadius: "50%",
			textAlign: "center",
			lineHeight: "50px",
			verticalAlign: "top",
			padding: "15px",
			color: "#E3CE04",
		},
		[theme.breakpoints.down("xs")]: {
			"& b": {
				fontSize: "13px",
			},
			"& p": {
				fontSize: "12px",
				margin: "0!important",
				lineHeight: "20px",
			},
			"& svg": {
				width: "40px !important",
				height: "40px !important",
				padding: "7px !important",
			},
		},
		/* display: -webkit-flex; /* Safari */

		/* display: flex;
		-webkit-align-items: center; /* Safari 7.0+ */
		/* align-items: center;
		-webkit-justify-content: center;
		justify-content: center; */

		display: "-webkit-flex",
		display: "flex",
		alignItems: "center",
		/* justifyContent: "center", */
	},

	tipHeadline: {
		fontWeight: "700 !important",
		fontSize: "16px !important",
		margin: "10px 0px 5px 0px !important",
		textTransform: "uppercase",
		display: "block",
		[theme.breakpoints.down("xs")]: {
			fontSize: "15px !important",
			paddingBottom: "10px !important",
		},
		fontStyle: "normal !important",
	},
	tipTextGrid: {
		[theme.breakpoints.up("sm")]: {
			paddingLeft: "3px !important",
		},
		[theme.breakpoints.down("sm")]: {
			paddingLeft: "5px !important",
			paddingRight: "5px !important",
		},
	},
	tipIconGrid: {
		[theme.breakpoints.up("sm")]: {
			paddingRight: "3px !important",
		},
		[theme.breakpoints.down("sm")]: {
			paddingLeft: "5px !important",
			paddingRight: "5px !important",
		},
	},
	tipIconSection: {
		display: "inline-block !important",
		width: "50px",

		/* display: "flex",
		alignItems: "center",
		justifyContern: "center", */
		/* "& svg": {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		}, */
	},
	tipTextContainer: {
		width: "calc(100% - 100px)",
		[theme.breakpoints.down("sm")]: {
			width: "calc(100% - 50px)",
			paddingLeft: "10px !important",
		},
		paddingLeft: "20px",
		display: "inline-block !important",
		"& p": {
			display: "block",
		},
		marginTop: "0px !important",
	},
	tipText: {
		marginTop: "-5px !important",

		fontWeight: "500",
	},
	mobileSpan: {
		[theme.breakpoints.down("xs")]: {
			display: "block",
		},
		margin: "0px !important",
	},
	divider: {
		[theme.breakpoints.down("md")]: {
			display: "none",
		},
	},
	autoHeightBody: {
		height: "auto",
		backgroundColor: "#F2F3F7",
		overflowY: "auto",
	},
	modalCardBorder: {
		borderRadius: "18px",
		boxShadow: "none",
		padding: "10px 0px 10px 0px",
		margin: "0px 0px 20px 0px",
	},
	modalBackground: {
		background: "#fff",
		transition: "height 0.5s ease",
		overflowY: "scroll",
	},
	modalBackgroundScroll: {
		overflowY: "hidden",
	},
	titleAlign: {
		textAlign: "center",
	},
	modalTitle: {
		fontFamily: "Roboto, Helvetica, sans-serif",
		fontSize: "16px",
		fontWeight: "700",
		position: "relative",
		margin: "0px 0px 24px 0px !important",
	},
	modalHeaderTitle: {
		fontFamily: "Roboto, Helvetica, sans-serif",
		fontSize: "16px",
		fontWeight: "700",
	},
	profilePic: {
		margin: "auto",
		width: "140px",
		position: "relative",
	},

	verticalAligned: {
		alignItems: "center",
	},
	// infoAreaStyle: {
	// 	"& svg": {
	// 		width: "25px !important",
	// 		height: "25px !important",

	// 		marginRight: "15px",
	// 	},
	// 	display: "flex",
	// 	alignItems: "center",
	// 	fontWeight: "700",
	// 	fontSize: "14px",
	// 	margin: "15px 0px 0px 10px",
	// },
	infoAreaStyle: {
		"& svg": {
			width: "25px !important",
			height: "25px !important",
			color: "#959595",
			marginRight: "15px",
		},
		display: "flex",
		alignItems: "center",
		fontWeight: "700",
		fontSize: "14px",
		margin: "15px 0px",
		"& a": {
			textDecoration: "none !important",
			color: blackColor,
			"&:hover,&:focus": {
				color: opptyDesignColor,
			},
		},
		[theme.breakpoints.down("sm")]: {
			margin: "10px 0px",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
			margin: "5px 0px",
			"& svg": {
				width: "20px !important",
				height: "20px !important",
			},
		},
	},
	infoAreaContainer: {
		padding: "10px",
		border: "#f2f3f7 solid 2px",
		borderRadius: "11px",
	},
	grayButton: {
		"& svg": {
			color: "#959595 !important",
			border: "3px solid #959595 !important",
			borderRadius: "5px",
			[theme.breakpoints.down("xs")]: {
				border: "2px solid #959595 !important",
			},
		},
	},
	locationTitle: {
		color: "#959595",
		[theme.breakpoints.down("xs")]: {
			fontSize: "13px",
		},
	},
	modalSubTitle: {
		marginBottom: "20px",
	},
	userTipIcon: {
		[theme.breakpoints.down("xs")]: {
			padding: "5px",
			margin: "0",
			"& div": {
				maxWidth: "100%",
			},
			"& button": {
				right: "0",
			},
		},
		"& svg": {
			fontSize: "24px",
			[theme.breakpoints.down("md")]: {
				fontSize: "20px",
			},
			[theme.breakpoints.down("xs")]: {
				fontSize: "16px",
			},
		},
	},
	cardBodyMargin: {
		[theme.breakpoints.down("xs")]: {
			margin: "-5px -25px 0",
		},
	},
	// buttonHeight: {
	// 	[theme.breakpoints.down("xs")]: {
	// 		height: "30px",
	// 	},
	// },
	cardBodyFooter: {
		padding: "10px 10px 0px 10px",
		/* padding: "0 !important", */
		textAlign: "right",
		backgroundColor: "#f2f3f7",
	},
	tabCardBorder: {
		borderRadius: "0px ​0px 18px 18px",
		boxShadow: "none",
		padding: "0 0 15px 0",
		margin: "0",
	},
	mediaHeight: {
		height: "20vh",
	},
	tabBackground: {
		backgroundColor: "#f2f3f7",
	},
	tabStyle: {
		"& header": {
			backgroundColor: "#fff",
			color: "#959595",
			boxShadow: "none",
		},
		// "&:hover,&:focus": {
		// 	//color: whiteColor,
		// 	//backgroundColor: grayColor[0],
		// 	boxShadow:
		// 		"0 14px 26px -12px rgba(" +
		// 		hexToRgb(grayColor[0]) +
		// 		", 0.42), 0 4px 23px 0px rgba(" +
		// 		hexToRgb(blackColor) +
		// 		", 0.12), 0 8px 10px -5px rgba(" +
		// 		hexToRgb(grayColor[0]) +
		// 		", 0.2)",
		// },
	},
	tabButtonStyle: {
		"& > span": {
			color: opptyDesignColor,
			backgroundColor: opptyDesignColor,
			height: "4px",
		},
	},
	tabHeader: {
		backgroundColor: "#fff",
		paddingTop: "20px",
		borderRadius: "18px",
	},
	cardSelected: {
		border: "6px solid" + opptyDesignColor,
		[theme.breakpoints.down("xs")]: {
			border: "4px solid" + opptyDesignColor,
		},
	},
	uploadIcon: {
		color: opptyDesignColor + "!important",
		fontSize: "30px",
	},
	uploadTitle: {
		color: opptyDesignColor + "!important",
		fontWeight: "400",
	},
	uploadSubtitle: {
		color: "#959595!important",
		fontWeight: "400",
	},
	uploadCardBackground: {
		backgroundColor: "#fff",
		margin: "0",
	},
	cropContainer: {
		height: 200,
		[theme.breakpoints.up("sm")]: {
			height: 400,
		},
	},
	cornerRibbon: {
		width: "200px",
		background: opptyDesignColor,
		position: "absolute",
		top: "25px",
		left: "-50px",
		textAlign: "center",
		lineHeight: "50px",
		letterSpacing: "1px",
		color: whiteColor,
		transform: "rotate(-45deg)",
		WebkitTransform: "rotate(-45deg)",
		[theme.breakpoints.down("xs")]: {
			top: "15px",
			left: "-50px",
			fontSize: "8px",
			width: "150px",
			lineHeight: "20px",
		},
	},
	iconHide: {
		visibility: "hidden",
		opacity: "0",
		transition: "opacity 2s ease",
	},
	iconShow: {
		visibility: "visible",
		opacity: "1",
	},

	profileStrengthLevel: {
		fontSize: "12px",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		fontWeight: "500",
		margin: "5px 0px !important",
	},
	profileStrengthLabel: {
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		fontWeight: "700",
		fontSize: "16px",
	},
	profileStrengthButton: {
		textAlign: "left",
		textTransform: "capitalize !important",
		fontSize: "14px ",
		color: "#687BFE !important",
	},
	selectedTabButton: {
		height: "60px",
		backgroundColor: opptyDesignColor,
		borderRadius: "18px",
		color: whiteColor,
		fontSize: "18px",
		boxShadow:
			"0 2px 2px 0 rgba(" +
			hexToRgb(opptyDesignColor[0]) +
			", 0.14), 0 3px 1px -2px rgba(" +
			hexToRgb(opptyDesignColor[0]) +
			", 0.2), 0 1px 5px 0 rgba(" +
			hexToRgb(opptyDesignColor[0]) +
			", 0.12)",
		"&:hover,&:focus": {
			backgroundColor: opptyDesignColor,

			boxShadow:
				"0 14px 26px -12px rgba(" +
				hexToRgb(opptyDesignColor[0]) +
				", 0.42), 0 4px 23px 0px rgba(" +
				hexToRgb(blackColor) +
				", 0.12), 0 8px 10px -5px rgba(" +
				hexToRgb(opptyDesignColor[0]) +
				", 0.2)",
		},
		[theme.breakpoints.down("sm")]: {
			fontSize: "14px",
			whiteSpace: "normal",
			height: "50px",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
			whiteSpace: "normal",
			height: "50px",
		},
	},
	tabButton: {
		height: "60px",
		backgroundColor: "#f2f3f7",
		border: "#99B2C6 2px solid",
		borderRadius: "18px",
		fontSize: "18px",
		color: "#99B2C6",
		boxShadow:
			"0 2px 2px 0 rgba(" +
			hexToRgb("#99B2C6") +
			", 0.14), 0 3px 1px -2px rgba(" +
			hexToRgb("#99B2C6") +
			", 0.2), 0 1px 5px 0 rgba(" +
			hexToRgb("#99B2C6") +
			", 0.12)",
		"&:hover,&:focus": {
			backgroundColor: whiteColor,
			color: "#99B2C6",

			boxShadow:
				"0 14px 26px -12px rgba(" +
				hexToRgb("#99B2C6") +
				", 0.42), 0 4px 23px 0px rgba(" +
				hexToRgb(blackColor) +
				", 0.12), 0 8px 10px -5px rgba(" +
				hexToRgb("#99B2C6") +
				", 0.2)",
		},
		[theme.breakpoints.down("sm")]: {
			fontSize: "14px",
			whiteSpace: "normal",
			height: "50px",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
			whiteSpace: "normal",
			border: "#99B2C6 1px solid",
			height: "50px",
		},
	},
	cardClick: {
		// cursor: "pointer",
		"&:hover,&:focus": {
			//color: whiteColor,
			//backgroundColor: grayColor[0],
			boxShadow:
				// "0 9px 0px 0px white, 0 -9px 0px 0px white, 12px 0 15px -4px rgba(" +
				// hexToRgb(grayColor[0]) +
				// ", 0.5), -12px 0 15px -4px rgba(" +
				// hexToRgb(grayColor[0]) +
				// ", 0.5)",
				"0 14px 26px -12px rgba(" +
				hexToRgb(grayColor[0]) +
				", 0.42), 0 4px 23px 0px rgba(" +
				hexToRgb(blackColor) +
				", 0.12), 0 8px 10px -5px rgba(" +
				hexToRgb(grayColor[0]) +
				", 0.2)",
		},
	},
	contactCard: {
		borderRadius: "0px",
		borderBottom: "2px solid #f2f3f7",
		margin: "0px",
		boxShadow: "none",
	},
	contactCardBorder: {
		// borderTop: "2px solid #f2f3f7",
	},
	contactCardFirst: {
		"&:hover,&:focus": {
			borderRadius: "18px 18px 0 0",
		},
	},
	contactCardLast: {
		"&:hover,&:focus": {
			borderRadius: "0 0 18px 18px",
		},
	},
	contactCardOnly: {
		"&:hover,&:focus": {
			borderRadius: "18px",
		},
	},
	contactPadding: {
		padding: "20px 0 20px 0",
	},
	paginationAligned: {
		float: "right",
	},
	buttonInvite: {
		borderRadius: "11px",
		"& svg": {
			marginRight: "10px",
			width: "auto!important",
			[theme.breakpoints.down("md")]: {
				marginRight: "10px",
			},
			[theme.breakpoints.down("sm")]: {
				marginRight: "0px",
			},
			[theme.breakpoints.up("sm")]: {
				// width: "25px !important",
				// height: "25px !important",
			},
			[theme.breakpoints.down("xs")]: {
				marginRight: "0px",
				// width: "20px !important",
				// height: "20px !important",
			},
		},
		fontWeight: "700",
		textTransform: "none",
		fontSize: "15px",
		padding: "10px !important",
		marginRight: "10px",
		whiteSpace: "nowrap",
		[theme.breakpoints.down("md")]: {
			fontSize: "12px",
			padding: "12px !important",
		},
		[theme.breakpoints.down("xs")]: {
			padding: "8px !important",
			marginRight: "15px",
		},
	},
	buttonRemove: {
		borderRadius: "11px",
		"& svg": {
			marginRight: "10px",
			width: "auto!important",
			[theme.breakpoints.down("md")]: {
				marginRight: "10px",
			},
			[theme.breakpoints.down("sm")]: {
				marginRight: "10px",
			},
			[theme.breakpoints.up("sm")]: {
				// width: "25px !important",
				// height: "25px !important",
			},
			[theme.breakpoints.down("xs")]: {
				marginRight: "0px",
				// width: "20px !important",
				// height: "20px !important",
			},
		},
		fontWeight: "700",
		textTransform: "none",
		fontSize: "14px",
		// padding: "10px !important",
		marginRight: "10px",
		whiteSpace: "nowrap",
		"&:hover,&:focus": {
			fontSize: "15px",
			background: "#f2f3f7",
			color: grayColor[1],
			boxShadow: "none",
		},
		[theme.breakpoints.down("md")]: {
			fontSize: "12px",
			padding: "12px !important",
		},
		[theme.breakpoints.down("xs")]: {
			padding: "8px !important",
			marginRight: "15px",
		},
	},
	contactTitle: {
		...title,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		position: "relative",
		marginBottom: "0px !important",
		marginTop: "0px !important",
		textDecoration: "none",
		color: blackColor + "!important",
		minHeight: "0px",
		fontSize: "18px",
		"& svg": {
			width: "20px", //"32px",
			height: "20px", //"32px",
			marginRight: "10px", //"20px",
			marginTop: "10px",
		},
		[theme.breakpoints.down("md")]: {
			marginBottom: "10px!important",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "16px",
			marginBottom: "5px!important",
		},
	},
	contactEmail: {
		color: "#999",
		[theme.breakpoints.down("sm")]: {
			fontSize: "12px",
			margin: "0!important",
		},
	},
	contactContactNo: {
		color: "#b5b5b5 !important",
		textTransform: "uppercase",
		fontSize: "14px",
		fontWeight: "500 !important",
		margin: "0 0 0 0!important",
		[theme.breakpoints.down("xs")]: {
			fontSize: "11px",
			minHeight: "17px",
		},
	},
	cardFrameMargin: {
		[theme.breakpoints.down("xs")]: {
			marginTop: "25px",
			marginBottom: "10px",
		},
	},
	cardPaddingLeft: {
		[theme.breakpoints.down("xs")]: {
			paddingLeft: "8px",
		},
	},
	socialMargin: {
		margin: "0 -4px 0 32px",
		fontSize: "14px",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		textTransform: "uppercase",
		fontWeight: "400",
	},
	googleModal: {
		height: "300px",
		[theme.breakpoints.down("sm")]: {
			height: "350px",
		},
	},
	googleTerm: {
		textAlign: "center",
		fontSize: "13px",
		padding: "0 55px",
	},
	googleButtonContainer: {
		backgroundColor: "#FFFFFF",
		width: "250px",
		/* height: "50px", */
		display: "flex",
		alignItems: "center",
		borderRadius: "5px",
		transition: "background-color .218s, border-color .218s, box-shadow .218s",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		cursor: "pointer",
		color: "gray",
		fontSize: "16px",
		lineHeight: "45px",
		/* border: "1px solid gray", */
		boxShadow:
			"0 2px 2px 0 rgba(" +
			hexToRgb(grayColor[0]) +
			", 0.14), 0 3px 1px -2px rgba(" +
			hexToRgb(grayColor[0]) +
			", 0.2), 0 1px 5px 0 rgba(" +
			hexToRgb(grayColor[0]) +
			", 0.12)",
		"& svg": {
			background: "#FFFFFF",
			borderRadius: "2px",
			textAlign: "center",
			verticalAlign: "top",
			width: "25px",
			height: "25px",
			marginLeft: "10px",
		},
		"&:hover": {
			boxShadow:
				"0 14px 26px -12px rgba(" +
				hexToRgb(grayColor[0]) +
				", 0.42), 0 4px 23px 0px rgba(" +
				hexToRgb(grayColor[0]) +
				", 0.12), 0 8px 10px -5px rgba(" +
				hexToRgb(grayColor[0]) +
				", 0.2)",
		},
		"  &:active": {
			background: "#ffffff",
		},
	},
	socialButtonText: {
		width: "calc(100% - 48px)",
		display: "inline-block",
		/* 	paddingLeft: "15px", */
		fontWeight: "500",
		textAlign: "center",
	},
	footerNav: {
		position: "fixed",
		left: 0,
		bottom: 0,
		width: "100%",
		textAlign: "center",
		alignItems: "center",
		margin: 0,
		border: "2px solid #D0DDE7",
	},

	saveProfileInformationBtn: {
		color: "#0AB98D",

		"& svg": {
			marginTop: "-3px",
			marginRight: "5px",
			width: "16px",
			height: "16px",
		},
	},

	cancelEditBtn: {
		color: "#4A4A4A",

		"& svg": {
			marginTop: "-2px",
			marginRight: "5px",
			width: "10px",
			height: "10px",
		},
	},

	buttonHeader: {
		display: "flex",
		justifyContent: "space-between",
		marginBottom: "10px",

		"& button": {
			marginLeft: "5px",
			padding: "6px 8px 4px",
			fontSize: "13px",
			fontWeight: 500,
			fontFamily: "Roboto, Helvetica, sans-serif",
			textTransform: "uppercase",
		},
	},
	tagFrame: {
		marginTop: "0px !important",
	},
	dialogActions: {
		position: "absolute",
		left: 0,
		right: 0,
		bottom: "20px",
		display: "flex",
		justifyContent: "space-evenly",

		"& > button": {
			borderRadius: "24px",
			width: "120px",
			height: "40px",
			fontSize: "14px",
			fontWeight: 500,
			fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		},

		"& > :nth-child(1)": {
			color: "#687BFE",
		},

		"& > :nth-child(2)": {
			backgroundColor: "#687BFE",
			color: "#fff",
		},
	},
	dialogText: {
		color: "#6D7278",
		fontSize: "16px",
		fontWeight: 400,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		textAlign: "center",
	},
	dialogTitle: {
		margin: 0,
		color: "#000000",
		fontSize: "18px",
		fontWeight: 700,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		textAlign: "center",
	},
	dialog: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		borderRadius: "15px",
		backgroundColor: "#fff",
		padding: "40px 0 15px",
		width: "350px",
		height: "200px",
	},
});

export default profilePageStyles;
