import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { instanceUrl } from "configurations/configurations.js";
import { useSelector } from "react-redux";

import PendingApprovalStyle from "assets/jss/material-kit-pro-react/components/pendingApprovalStyle";
import dotBtn from "assets/images/dotBtn.png";
import PendinRequest from "./PendinRequest";

const useStyles = makeStyles(PendingApprovalStyle);

export default function PendingApproval({ onClick }) {
  const classes = useStyles();
  const [pendingApprovals, setPendingApprovals] = useState([]);
  const userStatus = useSelector((state) => state.auth.user);

  // const fetchRequest = async () => {
  //   try {

  //     const res = await axios.get(
  //       `${instanceUrl}/api/um/get-connection-request-by-acct-id?email=${userStatus.email
  //       }&acctId=${userStatus.acctId}&accessToken=${localStorage.getItem(
  //         "token"
  //       )}&lastRecordSize=0&requestRecordSize=50`
  //     );
  //     setPendingApprovals(res.data.payload.connectionList);
  //   } catch (error) {
  //     if (axios.isCancel(error)) {
  //       console.log("unmount caused", error);
  //     } else {
  //       throw error;
  //     }
  //   }
  // };
  useEffect(() => {
    let mounted = true;
    let CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    if (mounted) {
      // fetchRequest();

      // Get all pending approvals
      axios.get(`${instanceUrl}/api/um/get-connection-request-by-receiver-acct-id?acctId=${userStatus.acctId}&email=${userStatus.email}&lastRecordSize0&requestRecordSize=50&accessToken=${localStorage.getItem('token')}`)
        .then((res) => {
          console.log(res.data);
          setPendingApprovals(res.data.payload.connectionList)
        })
        .catch((err) => console.log(err))
    }
    return () => {
      source.cancel();
      mounted = false;
    };
  }, []);


  const Card = ({ firstName, lastName, headline, profilePic, connId, index }) => {
    const [isAccepted, setIsAccepted] = useState(false);
    const [isStatusChanged, setIsStatusChanged] = useState(false);

    const changeRequestStatus = (status) => {
      axios.put(`${instanceUrl}/api/um/update-connection-profile-status`, {
        email: userStatus.email,
        acctId: userStatus.acctId,
        accessToken: localStorage.getItem("token"),
        connId,
        connStatusId: status === "accept" ? 1 : 3
      }).then((res) => {
        if (res.status == 201) {
          status === "accept" ? setIsAccepted(true) : setIsAccepted(false)
          setIsStatusChanged(true);
          // fetchRequest()
        }
      }).catch((error) => {
        if (axios.isCancel(error)) {
          console.log("unmount caused", error);
        } else {
          throw error;
        }
      })
    }

    return (
      <div className={classes.cardContainer} style={{ marginLeft: index != 0 ? 20 : 0, marginRight: pendingApprovals.length - 1 == index ? 30 : 0 }} >
        <div className={classes.imgContainer}>
          <img src={`${instanceUrl}/${profilePic}`} alt="" style={{ width: 30 }} />

        </div>
        <div className={classes.titleContainer2}>
          <text className={classes.txt1}>{firstName}{" "}{lastName}</text>
          <text className={classes.txt2}>{headline}</text>
          {isStatusChanged ? (
            isAccepted ? (
              <text className={classes.txt3}>Connection accepted!</text>
            ) : (
              <text className={classes.txt4}>Connection rejected!</text>
            )
          ) : (
            <div className={classes.btnParentContainer}>
                <div className={classes.btnContainer} onClick={() => changeRequestStatus("accept")}>
                <text className={classes.btnTxt}>ACCEPT</text>
              </div>
                <div className={classes.btnContainer2} onClick={() => changeRequestStatus("reject")}>
                <text className={classes.btnTxt2}>REJECT</text>
              </div>
              <img src={dotBtn} alt="" className={classes.img} />
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div  >
      <div className={classes.titleContainer}>
        <text className={classes.title}>Pending Connection Approvals</text>
      </div>
      <div className={classes.cardsContainer}>
        {
          pendingApprovals?.map((item, index) => {
            return (
              <Card key={index} index={index} firstName={item.firstName} lastName={item.lastName} headline={item.headline} profilePic={item.profilePic} connId={item.connId} />
            )
          })
        }


      </div>
    </div>
  );
}
