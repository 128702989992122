/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isDesktop } from "react-device-detect";

import EditSingleEducationCard from "../EditProfile/Education/EditSingleEducationCard";

import {
  Typography,
  makeStyles,
  Button,
  TextField,
  Hidden,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import {
  countMonthYear,
  getCountryId,
  getLocationId,
  getSchoolId,
  getEducationLevelId,
  getFieldStudyId,
  getStartYearData,
  getEndYearData,
  getMonthData,
  educationUpdateSchema,
  decryptData,
} from "configurations/configurations.js";
import {
  updateExperienceProfile,
  editExperienceProfile,
  deleteEducationProfile,
} from "configurations/redux/userSlice.js";
import axios from "axios";
import { Formik } from "formik";
import clsx from "clsx";

import { Check } from "@material-ui/icons";
import singleEducationCardStyles from "assets/jss/material-kit-pro-react/views/componentStyle/profileView/singleEducationCardStyles";

const SingleEducationCard = (props) => {
  const styles = makeStyles(singleEducationCardStyles)();
  const { match, renderIn, education } = props;

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const startYearDataList = getStartYearData();
  const endYearDataList = getEndYearData();
  const monthDataList = getMonthData();

  const [myself, setMyself] = useState(false);
  const [countryList, setCountryList] = useState(null);
  const [schoolCountry, setSchoolCountry] = useState(null);
  const [locationList, setLocationList] = useState(null);
  const [schoolLocation, setSchoolLocation] = useState(null);
  const [educationLevelList, setEducationLevelList] = useState(null);
  const [educationLevel, setEducationLevel] = useState(null);
  const [schoolList, setSchoolList] = useState(null);
  const [school, setSchool] = useState(null);
  const [fieldStudyList, setFieldStudyList] = useState(null);
  const [studyField, setStudyField] = useState(null);

  const [isEditing, setIsEditing] = useState(false);
  const [recordId, setRecordId] = useState();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const fetchCountryList = async () => {
    let res = await getCountryId();
    return res.data.payload;
  };
  const fetchLocationList = async () => {
    let res = await getLocationId();
    return res.data.payload;
  };
  const fetchEducationLevelList = async () => {
    let res = await getEducationLevelId();
    return res.data.payload;
  };
  const fetchSchoolList = async () => {
    let res = await getSchoolId();
    return res.data.payload;
  };
  const fetchStudyFieldList = async () => {
    let res = await getFieldStudyId();
    return res.data.payload;
  };

  const [body, setBody] = React.useState({});

  useEffect(() => {
    let mounted = true;
    setBody({
      eduRecId: education.eduRecId,
      accessToken: localStorage.getItem("token"),
      acctId: user.acctId,
      email: user.email,
    });

    if (user.acctId === decryptData(match.params.acctId)) setMyself(true);

    if (mounted) {
      // fetch lists
      fetchCountryList().then((list) => setCountryList(list));
      fetchLocationList().then((list) => setLocationList(list));
      fetchEducationLevelList().then((list) => setEducationLevelList(list));
      fetchSchoolList().then((list) => setSchoolList(list));
      fetchStudyFieldList().then((list) => setFieldStudyList(list));
    }
    return () => {
      mounted = false;
    };
  }, []);

  // filter field from its list to show in about me section
  useEffect(() => {
    if (educationLevelList)
      setEducationLevel(
        educationLevelList.filter(
          (item) => item.eduLvlId === education.eduLvlId
        )[0]
      );
  }, [educationLevelList]);
  useEffect(() => {
    if (schoolList)
      setSchool(
        schoolList.filter((item) => item.schoolId === education.schoolId)[0]
      );
  }, [schoolList]);
  useEffect(() => {
    if (fieldStudyList)
      setStudyField(
        fieldStudyList.filter(
          (item) => item.studyFieldId === education.studyFieldId
        )[0]
      );
  }, [fieldStudyList]);
  useEffect(() => {
    if (locationList)
      setSchoolLocation(
        locationList.filter(
          (item) => item.locationId === education.locationId
        )[0]
      );
  }, [locationList]);
  useEffect(() => {
    if (countryList)
      setSchoolCountry(
        countryList.filter((item) => item.countryId === education.countryId)[0]
      );
  }, [countryList]);

  return (
    <Typography
      component="div"
      className={styles.main}
      style={{
        backgroundColor:
          renderIn === "editProfile"
            ? "#fff"
            : renderIn === "viewProfile"
            ? "#F8F8F8"
            : "#fff",
      }}
    >
      {!isEditing ? (
        <>
          <Typography component="div" className={styles.header}>
            <Typography component="h2" className={styles.school}>
              {school ? school.schoolName : ""}
            </Typography>

            {renderIn === "editProfile" ? (
              <Typography component="div">
                <Button
                  className={styles.deleteEducationBtn}
                  onClick={() => {
                    setIsDeleteDialogOpen(true);
                    console.log(education);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18.581"
                    height="23.535"
                    viewBox="0 0 18.581 23.535"
                  >
                    <path
                      id="Path"
                      d="M16.794,2.942H12.864V2.206A2.178,2.178,0,0,0,10.72,0H7.861A2.178,2.178,0,0,0,5.717,2.206v.735H1.787A1.815,1.815,0,0,0,0,4.781V7.355a.725.725,0,0,0,.715.735h.391l.617,13.344a2.17,2.17,0,0,0,2.141,2.1H14.717a2.17,2.17,0,0,0,2.141-2.1L17.475,8.09h.391a.725.725,0,0,0,.715-.735V4.781A1.815,1.815,0,0,0,16.794,2.942Z"
                      fill="#e42b4d"
                    />
                  </svg>
                  <span>DELETE</span>
                </Button>

                <Button
                  className={styles.editEducationBtn}
                  onClick={() => setIsEditing(true)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                  >
                    <path
                      id="icon-2"
                      d="M4.709,20H1.176A1.178,1.178,0,0,1,0,18.824v-3.53a1.167,1.167,0,0,1,.345-.831L14.462.345a1.175,1.175,0,0,1,1.663,0l3.53,3.53a1.177,1.177,0,0,1,0,1.664L5.538,19.658A1.169,1.169,0,0,1,4.709,20Zm6.97-13.571h0L2.143,15.965v1.892H4.035L13.571,8.32,11.679,6.429Zm3.677-3.571h0L13.571,4.643l1.786,1.786,1.786-1.786L15.357,2.858Z"
                      transform="translate(0 0)"
                      fill="#6f52ed"
                    />
                  </svg>
                  EDIT
                </Button>
              </Typography>
            ) : (
              <></>
            )}
          </Typography>

          {educationLevel || studyField ? (
            <Typography component="p" className={styles.levelOfEducation}>
              {educationLevel ? educationLevel.eduLvlName : ""}
              {educationLevel && studyField ? " of " : ""}
              {studyField ? studyField.studyFieldName : ""}
            </Typography>
          ) : (
            ""
          )}
          {education.courseName ? (
            <Typography component="p" className={styles.fieldOfStudy}>
              {education.courseName}
            </Typography>
          ) : (
            ""
          )}

          <Typography component="p" className={styles.duration}>
            {monthDataList
              .filter(
                (month) => month.monthId === education.startDate.substring(5, 7)
              )
              .map((month) => month.monthName)}{" "}
            {education.startDate.substring(0, 4)}
            {" - "}
            {education.currentInProgressFlag !== 1 &&
              monthDataList
                .filter(
                  (month) => month.monthId === education.endDate.substring(5, 7)
                )
                .map((month) => month.monthName)}{" "}
            {education.currentInProgressFlag === 1
              ? `Present`
              : education.endDate.substring(0, 4)}
            {countMonthYear(education.startDate, education.endDate) &&
              " • " + countMonthYear(education.startDate, education.endDate)}
          </Typography>

          <Typography component="p" className={styles.location}>
            {schoolLocation ? schoolLocation.locationName : ""}
            {schoolLocation && schoolCountry ? ", " : ""}
            {schoolCountry ? schoolCountry.countryName : ""}
          </Typography>

          <hr className={styles.divider} />

          {education.achievement ? (
            <Typography component="p" className={styles.descriptionLine}>
              {education.achievement}
            </Typography>
          ) : null}

          {education.description ? (
            <Typography component="p" className={styles.descriptionLine}>
              {education.description}
            </Typography>
          ) : null}

          {education.extraCurricular ? (
            <Typography component="p" className={styles.descriptionLine}>
              {education.extraCurricular}
            </Typography>
          ) : null}
        </>
      ) : (
        <EditSingleEducationCard
          cancelEditing={() => {
            setIsEditing(false);
          }}
          education={education}
        />
      )}

      <div
        className={styles.dialogContainer}
        onClick={() => {
          setIsDeleteDialogOpen(false);
        }}
        style={{ display: isDeleteDialogOpen ? "block" : "none" }}
      >
        <div className={styles.dialog}>
          <h2 className={styles.dialogTitle}>Delete This Education?</h2>
          <p className={styles.dialogText}>This action cannot be undone.</p>

          <div className={styles.dialogActions}>
            <Button onClick={() => setIsDeleteDialogOpen(false)}>Cancel</Button>
            <Button
              onClick={() => {
                console.log(body);
                dispatch(deleteEducationProfile(body));
                setIsDeleteDialogOpen(false);
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </Typography>
  );
};
export default SingleEducationCard;
