import React from "react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import defaultAvatar from "assets/img/candidate_avatar.jpeg";

import searchPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/SearchPage/searchPageStyle.js";

import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import SmallCardLoading from "./SmallCardLoading.js";
import {
	encryptData,
	instanceUrl,
	header,
} from "configurations/configurations.js";
import { saveOption } from "configurations/redux/genericSearchSlice.js";
import CustomDialog2 from "../../../components/CustomDialog/CustomDialog2.js";
import NoResultCard from "./NoResultCard.js";

const useStyles = makeStyles(searchPageStyle);

export default function PeopleComponent({ match }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const searchResult = useSelector((state) => state.searchProfile.searchResult);
	const isLoading = useSelector((state) => state.searchProfile.isLoading);
	const auth = useSelector((state) => state.auth);

	const ProfileList =
		searchResult &&
		searchResult.length > 0 &&
		searchResult.map((item) => {
			return auth.isPublic ? (
				<Card
					key={item.acctId}
					className={classes.cardFrame + " " + classes.cardClick}
					onClick={() => {
						signUpDialog();
					}}
				>
					<CardBody>
						<div className={classes.resultContainer}>
							<div className={classes.imgContainer}>
								<img src={item.profilePic ? item.profilePic : defaultAvatar} />
							</div>
							<div className={classes.descriptionContainer}>
								<div>
									<h4 className={classes.title}>
										{item.firstName + " " + item.surname}
									</h4>
									<h4 className={classes.description}>{item.headline}</h4>
									<h4 className={classes.description}>{item.countryName}</h4>
								</div>
							</div>
						</div>
					</CardBody>
				</Card>
			) : (
				<Link
					className={classes.textDeco}
					to={`/view-profile/${encryptData(item.acctId)}`}
					color="transparent"
					target="_blank"
					key={item.acctId}
				>
					<Card className={classes.cardFrame + " " + classes.cardClick}>
						<CardBody>
							<div className={classes.resultContainer}>
								<div className={classes.imgContainer}>
									<img
										src={item.profilePic ? item.profilePic : defaultAvatar}
									/>
								</div>
								<div className={classes.descriptionContainer}>
									<div>
										<h4 className={classes.title}>
											{item.firstName + " " + item.surname}
										</h4>
										<h4 className={classes.description}>{item.headline}</h4>
										<h4 className={classes.description}>{item.countryName}</h4>
									</div>
								</div>
							</div>
						</CardBody>
					</Card>
				</Link>
			);
		});
	const loadingFields = [];
	for (let i = 0; i < 3; i++) {
		loadingFields.push(
			<div key={i}>
				<SmallCardLoading />
			</div>
		);
	}

	const dialogDefault = {};
	const [confirmDialog, setConfirmDialog] = React.useState({
		isOpen: false,
		dialogDefault,
	});

	const signUpDialog = () => {
		const dialogDefault = {
			title: "Info",
			description: "Want to view more information?",
			description2:
				"Sign-up for an account with OppTy now to start connecting with others on our fast-growing career platform!",
			buttonYes: "Proceed",
			buttonNo: "Cancel",
		};
		setConfirmDialog({
			isOpen: true,
			dialogDefault,
			onConfirm: () => {
				history.push("/login-page");
				setConfirmDialog({
					isOpen: false,
					dialogDefault,
				});
			},
			onCancel: () => {
				setConfirmDialog({
					isOpen: false,
					dialogDefault,
				});
			},
		});
	};

	return (
		<Card className={classes.cardFrame}>
			<CardBody className={classes.cardBodyPadding}>
				<h4 className={classes.componentTitle}>People</h4>
				{isLoading &&
					loadingFields.map((item) => {
						return item;
					})}
				<div className={classes.mainContainer}>
					{ProfileList}
					{!isLoading && searchResult.length === 0 && (
						<NoResultCard showImage={false} />
					)}
				</div>

				{!isLoading && (
					<Link
						className={classes.textDeco}
						to={
							auth.isPublic
								? `/public-search-page-people`
								: `/search-page-people`
						}
						color="transparent"
					>
						<Button
							fullWidth
							onClick={() => {
								const response = {
									option: auth.isPublic
										? "/public-search-page-people"
										: "/search-page-people",
								};
								dispatch(saveOption(response));
							}}
							className={classes.buttonViewMore}
						>
							See All People Result
						</Button>
					</Link>
				)}
				<CustomDialog2
					confirmDialog={confirmDialog}
					setConfirmDialog={setConfirmDialog}
				/>
			</CardBody>
		</Card>
	);
}
