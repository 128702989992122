import { createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import {
	instanceUrl,
	header,
	headerFormData,
	emptyCache,
} from "configurations/configurations.js";

const reportSlice = createSlice({
	name: "report",
	initialState: {
		acctId: null,
		reportDesc: null,
		reasonId: null,
		recordId: null,
		reportTypeId: null,
		isLoading: false,
		isCreated: false,
		report: null,
	},
	reducers: {
		REPORT_LOADING: (state, action) => {
			state.isLoading = true;
		},
		DEFAULT_REPORT: (state) => {
			state.acctId = null;
			state.reportDesc = null;
			state.reasonId = null;
			state.recordId = null;
			state.reportTypeId = null;
			state.isLoading = false;
			state.isCreated = false;
			state.report = null;
		},
		REPORT_ADD_SUCCESS: (state, action) => {
			console.log(action.payload);
			localStorage.setItem("token", action.payload.token);
			state.isLoading = false;
			state.isCreated = true;
			state.report = action.payload.report;
		},
		REPORT_UPDATE_FAIL: (state, action) => {
			return state;
		},
	},
});

const {
	REPORT_LOADING,
	DEFAULT_REPORT,
	REPORT_ADD_SUCCESS,
	REPORT_UPDATE_FAIL,
} = reportSlice.actions;

export const resetReport = () => async (dispatch) => {
	dispatch(DEFAULT_REPORT());
};

export const createReport = ({
	email,
	acctId,
	reportTypeId,
	recordId,
	reasonId,
	reportDesc,
	userId,
	accessToken,
}) => async (dispatch) => {
	dispatch(DEFAULT_REPORT());
	const body = {
		email,
		acctId,
		reportTypeId,
		recordId,
		reasonId,
		reportDesc,
		accessToken,
	};
	console.log(body);
	dispatch(REPORT_LOADING());
	await axios({
		method: "POST",
		headers: header,
		url: `${instanceUrl}/api/rpt/create-report`,
		data: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				res.data.payload.report.userId = userId;
				const response = {
					token: res.data.token,
					report: res.data.payload.report,
				};
				dispatch(REPORT_ADD_SUCCESS(response));
			} else {
				dispatch(REPORT_UPDATE_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export default reportSlice.reducer;
