import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { departmentViewRoadmap,viewRoadmapRoleByRoleId } from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";
import ImageMapper from "react-image-mapper";
import Department_Advertising from "assets/img/Career_Roadmap/Marketing_Communication/Department_Advertising_1.jpg";
import RoleInfoModal from "../RoleInfoModal.js";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentAdvertising() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Advertising;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "ad",
				shape: "poly",
				coords: [
					77,357,162,357,169,358,177,363,181,367,183,372,184,378,184,412,183,418,180,425,176,429,171,431,164,434,76,433,66,431,60,426,56,419,55,413,55,377,56,372,59,367,63,362,67,360,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "1",
				shape: "poly",
				coords: [
					208,354,335,354,341,355,347,357,351,360,353,363,354,370,354,414,353,420,350,425,347,427,343,431,337,432,210,432,204,430,199,427,196,422,194,416,193,374,194,366,197,362,199,358,201,356,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "2",
				shape: "poly",
				coords: [
					438,354,570,354,583,359,587,366,587,378,588,413,586,423,580,430,567,432,435,432,426,429,421,423,420,415,420,374,421,365,425,359,431,355,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "3",
				shape: "poly",
				coords: [
					675,346,800,345,809,347,816,351,821,357,821,367,821,415,821,423,819,429,815,434,807,438,673,439,662,436,655,429,653,419,653,368,655,357,661,350,667,346,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "4",
				shape: "poly",
				coords: [
					901,357,1034,357,1043,359,1049,362,1052,367,1055,375,1054,419,1052,427,1047,431,1037,435,906,435,896,432,890,427,888,417,887,374,889,366,892,360,897,357,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "5",
				shape: "poly",
				coords: [
					1139,357,1269,357,1281,362,1286,367,1287,376,1287,418,1284,427,1280,432,1268,435,1139,435,1128,433,1121,426,1120,416,1119,377,1122,367,1128,361,1131,359,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "6",
				shape: "poly",
				coords: [
					1371,360,1503,359,1510,360,1516,363,1520,367,1522,376,1522,417,1521,427,1517,434,1507,437,1379,438,1365,435,1358,430,1356,417,1355,376,1358,366,1364,361,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "7",
				shape: "poly",
				coords: [
					1674,360,1844,359,1857,365,1862,369,1862,378,1863,417,1861,429,1855,435,1842,438,1678,438,1667,436,1660,432,1656,426,1655,379,1658,369,1661,363,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "ad") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo)
			dispatch(viewRoadmapRoleByRoleId(requestInfo));
			
			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// console.log(coords.x + "," + coords.y + ",");
		// navigator.clipboard.writeText(coords.x + "," + coords.y + ",");
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}

	function enterArea(area) {
		console.log(area);
	}

	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
				onMouseEnter={(area) => enterArea(area)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
