import React from "react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import TestimonialMenuComponent from "./TestimonialMenuComponent.js";
import { useSelector, useDispatch, connect } from "react-redux";
import defaultAvatar from "assets/img/candidate_avatar.jpeg";
import { Button, makeStyles, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import testimonialCardStyles from "assets/jss/material-kit-pro-react/views/pageStyle/TestimonialsPage/testimonialCardStyles.js";
import Skeleton from "@mui/material/Skeleton";
import Avatar from "@mui/material/Avatar";
import {
	TimeFormatOnlyDate,
	encryptData,
	decryptData,
} from "configurations/configurations.js";

const useStyles = makeStyles(testimonialCardStyles);

export default function TestimonialCard({ item, tsStatus, match }) {
	const classes = useStyles();
	const userStatus = useSelector((state) => state.auth.user);
	const isLoading = useSelector((state) => state.testimonial.isLoading);
	const isMe = decryptData(match.params.acctId) === userStatus.acctId;

	const myTestimonial = item.ownerAcctId === userStatus.acctId;
	const authorAcctId = encryptData(item.authorAcctId);
	const ownerAcctId = encryptData(item.ownerAcctId);

	const OwnTestimonialComponent = () => {
		return (
			<React.Fragment>
				<div className={classes.mainContainer}>
					<React.Fragment>
						<div className={classes.imgContainer}>
							<Link
								className={classes.textDeco}
								to={`/view-profile/${authorAcctId}`}
								color="transparent"
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									src={
										item.authorProfilePic
											? item.authorProfilePic
											: defaultAvatar
									}
								/>
							</Link>
						</div>

						<div className={classes.descriptionContainer}>
							<div>
								<Link
									className={classes.textDeco}
									to={`/view-profile/${authorAcctId}`}
									color="transparent"
									target="_blank"
									rel="noopener noreferrer"
								>
									<h4 className={classes.name}>
										{item.authorFirstName + " " + item.authorLastName}
									</h4>
								</Link>

								<h4 className={classes.description}>
									{item.relationship + " in " + item.tsPosition}
								</h4>
								<h4 className={classes.description}>
									{TimeFormatOnlyDate(item.lastUpdateDate)}
								</h4>

								<div className={classes.contentContainer}>
									<h4 className={classes.statusDescription}>
										{tsStatus === 2
											? item.statusId === 1
												? "Status: Waiting For Testimonial"
												: item.statusId === 2
												? "Status: Waiting Approval for Testimonial"
												: "Status: Waiting for Revision"
											: null}
									</h4>

									{item.statusId === 1 && (
										<h4 className={classes.description}>
											{item.authorFirstName +
												" has not responeded to your request"}
										</h4>
									)}
									{item.statusId === 2 && (
										<React.Fragment>
											<p className={classes.additionalDescription}>
												{" "}
												This testimonial is currently hidden from your public
												profile and will not be visible to others until you
												approve it.
											</p>
											<h4 className={classes.description}>
												{item.testimonial}
											</h4>
										</React.Fragment>
									)}
									{item.statusId === 3 && (
										<h4 className={classes.description}>
											{item.authorFirstName +
												" has not responeded to your revision request"}
										</h4>
									)}
									{item.statusId === 4 && (
										<h4 className={classes.description}>{item.testimonial}</h4>
									)}
								</div>
							</div>
							{isMe && (
								<TestimonialMenuComponent tsBody={item} tsStatus={tsStatus} />
							)}
						</div>
					</React.Fragment>
				</div>
			</React.Fragment>
		);
	};

	const AuthorTestimonialComponent = () => {
		return (
			<React.Fragment>
				<div className={classes.mainContainer}>
					<div className={classes.imgContainer}>
						<Link
							className={classes.textDeco}
							to={`/view-profile/${ownerAcctId}`}
							color="transparent"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								src={
									item.ownerProfilePic ? item.ownerProfilePic : defaultAvatar
								}
							/>
						</Link>
					</div>
					<div className={classes.descriptionContainer}>
						<div>
							<Link
								className={classes.textDeco}
								to={`/view-profile/${ownerAcctId}`}
								color="transparent"
								target="_blank"
								rel="noopener noreferrer"
							>
								<h4 className={classes.name}>
									{item.ownerFirstName + " " + item.ownerLastName}
								</h4>
							</Link>

							<h4 className={classes.description}>
								{item.relationship + " in " + item.tsPosition}
							</h4>

							<div className={classes.contentContainer}>
								<React.Fragment>
									<h4 className={classes.statusDescription}>
										{tsStatus === 2
											? item.statusId === 1
												? `Status: Requested Testimonial from ${item.ownerFirstName}`
												: item.statusId === 2
												? `Status: Waiting Approval of ${item.ownerFirstName} for Testimonial`
												: "Status: Requested for Revision"
											: null}
									</h4>

									{item.statusId === 1 && (
										<React.Fragment>
											<h4 className={classes.description}>
												{item.ownerFirstName + " has requested Testimonial"}
											</h4>
											<h4 className={classes.description}>{item.message}</h4>
										</React.Fragment>
									)}
									{item.statusId === 2 && (
										<React.Fragment>
											<p className={classes.additionalDescription}>
												{" "}
												This testimonial is currently hidden from{" "}
												{item.ownerFirstname} public profile and will not be
												visible to others until approval.
											</p>
											<h4 className={classes.description}>
												{item.testimonial}
											</h4>
										</React.Fragment>
									)}
									{item.statusId === 3 && (
										<React.Fragment>
											<h4 className={classes.description}>
												{item.ownerFirstName +
													" has requested Revision for Testimonial"}
											</h4>
											<h4 className={classes.description}>{item.message}</h4>
										</React.Fragment>
									)}
									{item.statusId === 4 && (
										<h4 className={classes.description}>{item.testimonial}</h4>
									)}
								</React.Fragment>
							</div>
						</div>
						{isMe && (
							<TestimonialMenuComponent tsBody={item} tsStatus={tsStatus} />
						)}
					</div>
				</div>
			</React.Fragment>
		);
	};

	const LoadingComponent = () => {
		return (
			<div style={{ display: "flex" }}>
				<div>
					<Skeleton variant="circular" width={48} height={48}>
						<Avatar />
					</Skeleton>
				</div>

				<div style={{ width: "100%", marginLeft: "10px" }}>
					<Skeleton width="30%">.</Skeleton>
					<Skeleton width="40%">.</Skeleton>
					<Skeleton width="90%">.</Skeleton>
					<Skeleton width="90%">.</Skeleton>
				</div>
			</div>
		);
	};
	return (
		<div key={item.testimonialId}>
			<Card className={classes.cardFrame}>
				<CardBody className={classes.cardBody}>
					{isLoading ? (
						<LoadingComponent />
					) : myTestimonial ? (
						<OwnTestimonialComponent />
					) : (
						<AuthorTestimonialComponent />
					)}
				</CardBody>
			</Card>
		</div>
	);
}
