const postImageCropStyles = () => ({
  ratios: {
    display: "flex",
    justifyContent: "center",
    borderTop: "solid 1px #e9e9e9",
    paddingBottom: "10px",
  },

  singleRatioContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "5px",
    width: "60px",
    textAlign: "center",
    cursor: "pointer",
  },

  ratioIconNormal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "7px",
    borderRadius: "5px",
    backgroundColor: "#fff",
    width: "45px",
    height: "45px",
    padding: "10px 0",
  },

  ratioIconActive: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "7px",
    borderRadius: "5px",
    backgroundColor: "#574FFF",
    width: "45px",
    height: "45px",
    padding: "10px 0",
  },

  ratioTextNormal: {
    marginTop: "5px",
    color: "#77838F",
    fontSize: "14px",
    fontWeight: "500",
  },

  ratioTextActive: {
    marginTop: "5px",
    color: "#574FFF",
    fontSize: "14px",
    fontWeight: "500",
  },

  options: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 20px 0",
  },

  optionName: {
    width: "10%",
  },

  slider: {
    width: "30%",
  },

  photosList: {
    display: "flex",
    padding: "10px",
  },

  photosListContainer: {
    display: "flex",
    flexWrap: "wrap",
  },

  photosListItem: {
    margin: "6px 8px",
    width: "102px",
    height: "120px",

    "& > div": {
      overflow: "hidden",
      width: "100%",
      height: "100%",
    },
  },

  removePhoto: {
    position: "relative",
    zIndex: "2",
    top: "-9px",
    right: "-87px",
    width: "20px",
    height: "auto",
    cursor: "pointer",
  },

  photosListItemPhotoContainer: {
    display: "inline-block",
  },

  selectedPhoto: {
    boxSizing: "border-box",
    border: "solid 3px #20F0A1",
  },

  photosListItemPhoto: {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    height: "100%",
  },

  saveEdit: {
    padding: "5px 0 15px",
    textAlign: "center",

    "& > button": {
      backgroundColor: "#6F52ED",
      border: "none",
      borderRadius: "7px",
      padding: "15px 40px",
      color: "white",
      fontSize: "11px",
      fontWeight: "700",
      cursor: "pointer",
    },
  },
});

export default postImageCropStyles;
