import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import { makeStyles } from '@material-ui/core/styles';

import { encryptData } from 'configurations/configurations.js';
import { Link, useHistory } from 'react-router-dom';
/* import { Button, IconButton } from "@material-ui/core"; */
import Button from 'components/CustomButtons/Button.js';

import ConnectionPageStyle from 'assets/jss/material-kit-pro-react/components/connectionPageStyle.js';

import defaultAvatar from 'assets/img/candidate_avatar.jpeg';
import SmallCardLoading from './SmallCardLoading.js';
import {
  updateConnectionStatus,
  deleteBlockProfile,
  updateGroupStatus,
  deleteGroupUser,
} from 'configurations/redux/manageConnectionSlice.js';
import { activateEditType, deactivateEditType } from 'configurations/redux/editProfileModalSlice';
import { createChatroom, createChatroomReset } from 'configurations/redux/messageChatroomSlice.js';
import { useModal } from 'hooks';
import ConfirmDialog from 'components/CustomDialog/ConfirmDialog.js';

const useStyles = makeStyles(ConnectionPageStyle);

export default function ConnectionCard({ item, status }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userStatus = useSelector(state => state.auth.user);

  const isLoading = useSelector(state => state.manageConnection.isLoading);
  const [currentReceipient, setCurrentReceipient] = React.useState(null);
  const actionDialog = useModal();
  const getMessageNavigate = async item => {
    setCurrentReceipient(item);
    var chatroomList = [];
    chatroomList.push({ chatroomListId: -1, acctId: userStatus.acctId });
    chatroomList.push({ chatroomListId: -1, acctId: item });
    const messageApplicant = {
      acctId: userStatus.acctId,
      accessToken: localStorage.getItem('token'),
      email: userStatus.email,
      chatroomTitle: 'private_' + item + '_' + userStatus.acctId,
      chatroomList: chatroomList,
      receiverId: item,
      bizId: 0,
      receiverBizId: 0,
      typeId: 1,
    };
    console.log(messageApplicant);
    dispatch(createChatroom(messageApplicant));
  };
  const chatroomId = useSelector(state => state.messageChatroom.chatroomId);
  const isCreated = useSelector(state => state.messageChatroom.isCreated);

  const removeConnectionFunction = closeModal => {
    const response = {
      acctId: userStatus.acctId,
      connId: item.connId,
      connStatusId: 2,
      email: userStatus.email,
      accessToken: localStorage.getItem('token'),
      actionStatus: 'remove_connection',
    };
    dispatch(updateConnectionStatus(response)).then(() => {
      closeModal();
    });
  };

  const unblockConnectionFunction = closeModal => {
    const response = {
      acctId: userStatus.acctId,
      blockId: item.blockId,
      email: userStatus.email,
      accessToken: localStorage.getItem('token'),
      actionStatus: 'unblock_connection',
    };
    dispatch(deleteBlockProfile(response)).then(() => {
      closeModal();
    });
  };
  const rejectConnectionFunction = closeModal => {
    const response = {
      acctId: userStatus.acctId,
      connId: item.connId,
      connStatusId: 2,
      email: userStatus.email,
      accessToken: localStorage.getItem('token'),
      actionStatus: 'reject_connection',
    };
    dispatch(updateConnectionStatus(response)).then(() => {
      closeModal();
    });
  };
  const cancelGroupFunction = closeModal => {
    const response = {
      email: userStatus.email,
      acctId: userStatus.acctId,
      accessToken: localStorage.getItem('token'),
      groupUserId: item.userGroupId,
      groupId: item.groupId,
      actionStatus: 'cancel_group',
    };

    dispatch(deleteGroupUser(response)).then(() => {
      closeModal();
    });
  };
  const rejectGroupFunction = closeModal => {
    const response = {
      email: userStatus.email,
      acctId: userStatus.acctId,
      accessToken: localStorage.getItem('token'),
      groupUserId: item.userGroupId,
      groupId: item.groupId,
      actionStatus: 'reject_group',
    };

    dispatch(deleteGroupUser(response)).then(() => {
      closeModal();
    });
  };

  const leaveGroupFunction = closeModal => {
    const response = {
      email: userStatus.email,
      acctId: userStatus.acctId,
      accessToken: localStorage.getItem('token'),
      groupUserId: item.userGroupId,
      groupId: item.groupId,
      actionStatus: 'leave_group',
    };

    dispatch(deleteGroupUser(response)).then(() => {
      closeModal();
    });
  };

  const removeConnectionModalContent = {
    title: 'Remove Connection',
    text: 'Are you sure to remove connection? User will not be noticed',
    cancelText: 'No',
    confirmText: 'Yes',
  };
  const rejectConnectionModalContent = {
    title: 'Reject Connection',
    text: 'Are you sure to reject connection? User will not be noticed',
    cancelText: 'No',
    confirmText: 'Yes',
  };

  const unblockConnectionModalContent = {
    title: 'Unblock Connection',
    text: 'Are you sure to unblock this profile?',
    cancelText: 'No',
    confirmText: 'Yes',
  };
  const cancelGroupModalContent = {
    title: 'Cancel Request Join Group',
    text: 'Are you sure to cancel request to this group?',
    cancelText: 'No',
    confirmText: 'Yes',
  };
  const rejectGroupModalContent = {
    title: 'Reject Group Invite',
    text: 'Are you sure to reject group invite?',
    cancelText: 'No',
    confirmText: 'Yes',
  };
  const leaveGroupModalContent = {
    title: 'Leave Group',
    text: 'Are you sure to leave this group?',
    cancelText: 'No',
    confirmText: 'Yes',
  };

  const CustomMessage = () => {
    var itemContent = null;
    if (status === 'Connection') {
      itemContent = removeConnectionModalContent;
    } else if (status === 'Pending') {
      itemContent = null;
    } else if (status === 'Approval') {
      itemContent = rejectConnectionModalContent;
    } else if (status === 'Block') {
      itemContent = unblockConnectionModalContent;
    } else {
      if (item.statusId === 1) {
        itemContent = leaveGroupModalContent;
      } else if (item.statusId === 2) {
        itemContent = rejectGroupModalContent;
      } else {
        itemContent = cancelGroupModalContent;
      }
    }

    return (
      <div>
        {!isLoading && (
          <ConfirmDialog
            {...{ ...actionDialog }}
            bodyContent={itemContent}
            handleSubmit={() => {
              if (status === 'Connection') {
                removeConnectionFunction(actionDialog.toggle);
              } else if (status === 'Pending') {
                return;
              } else if (status === 'Approval') {
                rejectConnectionFunction(actionDialog.toggle);
              } else if (status === 'Block') {
                unblockConnectionFunction(actionDialog.toggle);
              } else {
                if (item.statusId === 1) {
                  leaveGroupFunction(actionDialog.toggle);
                } else if (item.statusId === 2) {
                  rejectGroupFunction(actionDialog.toggle);
                } else {
                  cancelGroupFunction(actionDialog.toggle);
                }
              }
            }}
          />
        )}
      </div>
    );
  };

  React.useEffect(() => {
    console.log(chatroomId);
    if (isCreated && chatroomId > 0 && item.receiverAcctId == currentReceipient) {
      console.log('trigger message');
      setCurrentReceipient(null);
      window.open('/message-page/0/C' + chatroomId + '/', '_blank');
      // history.push("/message-page/C" + chatroomId + "/");
    }
    dispatch(createChatroomReset());
  }, [isCreated]);

  const ConnectionButton = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button
          round
          color="oppty"
          className={classes.buttonMobile}
          onClick={e => {
            e.preventDefault();
            console.log(item.receiverAcctId);
            getMessageNavigate(item.receiverAcctId);
          }}
        >
          <b>Message</b>
        </Button>
        <Button
          className={classes.buttonMobile}
          onClick={e => {
            e.preventDefault();
            /*   const response = {
              acctId: userStatus.acctId,
              connId: item.connId,
              connStatusId: 2,
              email: userStatus.email,
              accessToken: localStorage.getItem('token'),
            };
            dispatch(updateConnectionStatus(response)); */
            actionDialog.toggle();
          }}
          color="opptyWrapBorder"
          round
        >
          Remove
        </Button>
      </div>
    );
  };
  const PendingButton = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button
          color="oppty"
          className={classes.buttonMobile}
          onClick={e => {
            e.preventDefault();

            const response = {
              type: 'CANCEL_CONNECTION_REQUEST',
              extension: {
                connId: item.connId,
                source: 'CONNECTION_PAGE',
              },
            };

            dispatch(activateEditType(response));
          }}
          round
        >
          <b>Cancel</b>
        </Button>
      </div>
    );
  };
  const ApprovalButton = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button
          className={classes.buttonMobile}
          onClick={e => {
            e.preventDefault();
            const response = {
              acctId: userStatus.acctId,
              connId: item.connId,
              connStatusId: 1,
              email: userStatus.email,
              actionStatus: 'approve_connection',
              accessToken: localStorage.getItem('token'),
            };
            dispatch(updateConnectionStatus(response));
          }}
          color="oppty"
          round
        >
          <b>Approve</b>
        </Button>
        <Button
          className={classes.buttonMobile}
          onClick={e => {
            e.preventDefault();
            /*     const response = {
              acctId: userStatus.acctId,
              connId: item.connId,
              connStatusId: 2,
              email: userStatus.email,
              accessToken: localStorage.getItem('token'),
            };
            dispatch(updateConnectionStatus(response)); */
            actionDialog.toggle();
          }}
          color="opptyWrapBorder"
          round
        >
          <b>Reject</b>
        </Button>
      </div>
    );
  };
  const BlockButton = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button
          className={classes.buttonMobile}
          onClick={e => {
            e.preventDefault();
            /*  const response = {
              acctId: userStatus.acctId,
              blockId: item.blockId,
              email: userStatus.email,
              accessToken: localStorage.getItem('token'),
            };
            dispatch(deleteBlockProfile(response)); */
            actionDialog.toggle();
          }}
          color="oppty"
          round
        >
          <b>Unblock</b>
        </Button>
      </div>
    );
  };
  const CardComponent = () => {
    return (
      <Link
        className={classes.textDeco}
        to={`/view-profile/${encryptData(item.receiverAcctId)}`}
        color="transparent"
        target="_blank"
        key={item.acctId}
      >
        <Card className={classes.cardFrame + ' ' + classes.cardClick}>
          <CardBody style={{ padding: '10px' }}>
            <div className={classes.resultContainer}>
              <div className={classes.imgContainer}>
                <img src={item.profilePic ? item.profilePic : defaultAvatar} />
              </div>
              <div className={classes.descriptionContainer}>
                <div>
                  <h4 className={classes.title}>{item.firstName + ' ' + item.lastName}</h4>
                  <h4 className={classes.description}>{item.headline}</h4>
                  <h4 className={classes.description}>{item.countryName}</h4>
                </div>
                {status === 'Connection' && <ConnectionButton />}
                {status === 'Pending' && <PendingButton />}

                {status === 'Approval' && <ApprovalButton />}
                {status === 'Block' && <BlockButton />}
              </div>
            </div>
          </CardBody>
        </Card>
      </Link>
    );
  };

  const GroupComponent = () => {
    return (
      <Link
        className={classes.textDeco}
        to={`/manage-group/${encryptData(item.groupId)}`}
        color="transparent"
        target="_blank"
        key={item.groupId}
      >
        <Card className={classes.cardFrame + ' ' + classes.cardClick}>
          <CardBody style={{ padding: '10px' }}>
            <div className={classes.resultContainer}>
              <div className={classes.imgContainer}>
                <img src={item.profilePic ? item.profilePic : defaultAvatar} />
              </div>
              <div className={classes.descriptionContainer}>
                <div>
                  <h4 className={classes.title}>{item.groupName}</h4>
                  <h4 className={classes.description}>{item.groupDescription}</h4>
                </div>

                {item.statusId === 1 && <JoinedGroupButton />}
                {item.statusId === 2 && <PendingApprovalGroupButton />}
                {item.statusId === 3 && <PendingRequestGroupButton />}
              </div>
            </div>
          </CardBody>
        </Card>
      </Link>
    );
  };
  const PendingRequestGroupButton = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button
          color="oppty"
          className={classes.buttonMobile}
          onClick={e => {
            e.preventDefault();

            /*  const response = {
              email: userStatus.email,
              acctId: userStatus.acctId,
              accessToken: localStorage.getItem('token'),
              groupUserId: item.userGroupId,
              groupId: item.groupId,
            };

            dispatch(deleteGroupUser(response)); */
            actionDialog.toggle();
          }}
          round
        >
          <b>Cancel</b>
        </Button>
      </div>
    );
  };
  const PendingApprovalGroupButton = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button
          color="opptyWrapBorder"
          className={classes.buttonMobile}
          onClick={e => {
            e.preventDefault();

            const response = {
              email: userStatus.email,
              acctId: userStatus.acctId,
              accessToken: localStorage.getItem('token'),
              groupUserId: item.userGroupId,
              userStatusId: 1,
              actionStatus: 'approve_group',
            };

            dispatch(updateGroupStatus(response));
          }}
          round
        >
          <b>Approve</b>
        </Button>
        <Button
          color="oppty"
          className={classes.buttonMobile}
          onClick={e => {
            e.preventDefault();

            /* const response = {
              email: userStatus.email,
              acctId: userStatus.acctId,
              accessToken: localStorage.getItem('token'),
              groupUserId: item.userGroupId,
              groupId: item.groupId,
            };

            dispatch(deleteGroupUser(response)); */
            actionDialog.toggle();
          }}
          round
        >
          <b>Reject</b>
        </Button>
      </div>
    );
  };
  const JoinedGroupButton = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button
          color="oppty"
          className={classes.buttonMobile}
          disabled={item.userRoleId === 1 ? true : false}
          onClick={e => {
            e.preventDefault();

            /* const response = {
              email: userStatus.email,
              acctId: userStatus.acctId,
              accessToken: localStorage.getItem('token'),
              groupUserId: item.userGroupId,
              groupId: item.groupId,
            };

            dispatch(deleteGroupUser(response)); */
            actionDialog.toggle();
          }}
          round
        >
          <b>{item.userRoleId === 1 ? 'Owner' : 'Leave'}</b>
        </Button>
      </div>
    );
  };
  return (
    <div>
      {isLoading ? <SmallCardLoading /> : status === 'Group' ? <GroupComponent /> : <CardComponent />}
      <CustomMessage />
    </div>
  );
}
