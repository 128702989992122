import { createSlice } from "@reduxjs/toolkit";
import history from "../../history.js";
import axios from "axios";
import {
	instanceUrl,
	header,
	decryptData,
	encryptData,
	emptyCache,
	publicEmail,
} from "configurations/configurations.js";
import Cookies from "js-cookie";
import { resetSelectBizPage } from "configurations/redux/businessSlice";
require("dotenv").config();

const authSlice = createSlice({
	name: "auth",
	initialState: {
		isAuthenticated: false,
		isError: false,
		isRegistered: false,
		isVerified: false,
		isResend: false,
		isTokenExpired: false,
		message: null,
		user: null,
		buildProfileFlag: null,
		socialAutoLogin: {},
		isLogout: false,
		isForceRefresh: null,
		isSocial: false,
		registeredEmail: null,
		isPublic: false,
		publicEmail: null,
		publicToken: null,
	},
	reducers: {
		LOGIN_SUCCESS: (state, action) => {
			localStorage.setItem("lastActiveTime", Date.now());
			localStorage.setItem("idleProcess", "start");
			localStorage.setItem("token", action.payload.token);
			state.user = action.payload.user;
			state.buildProfileFlag = action.payload.buildProfileFlag;
			state.isError = false;
			state.message = action.payload.message;
			state.isAuthenticated = true;
			state.isLogout = false;
		},
		LOGIN_FAIL: (state, action) => {
			state.user = action.payload.user;
			state.message = action.payload.message;
			state.isAuthenticated = false;
			state.isError = true;
			state.isVerified = action.payload.user
				? action.payload.user.acctStatusId === 0
					? true
					: false
				: false;
			state.isLogout = false;
		},
		VERIFICATION_EMAIL_RESENT_SUCCESS: (state) => {
			state.isResend = true;
		},
		EMAIL_NOT_VERIFIED: (state) => {
			state.isVerified = true;
		},

		REGISTER_SUCCESS: (state, action) => {
			state.message = action.payload.message;
			state.registeredEmail = action.payload.registeredEmail;
			state.isRegistered = true;
			state.isError = false;
		},
		CORP_REGISTER_SUCCESS: (state, action) => {
			state.message = action.payload.message;
			state.registeredEmail = action.payload.registeredEmail;
			state.isRegistered = true;
			state.isError = false;
		},
		REGISTER_FAIL: (state, action) => {
			state.message = action.payload.message;
			state.isRegistered = true;
			state.isError = true;
			if (action.payload.isSocial) state.isSocial = true;
			else state.isSocial = false;
		},
		USER_PROFILE_BUILD: (state) => {
			state.buildProfileFlag = 1;
		},
		DEFAULT_PAGE: (state) => {
			state.isError = false;
			state.isAuthenticated = false;
			state.isRegistered = false;
			state.message = null;
			state.user = null;
			state.buildProfileFlag = null;
			state.isResend = false;
			state.registeredEmail = null;
		},
		LOGOUT: (state, action) => {
			state.isLogout = true;
			state.isAuthenticated = false;
			localStorage.removeItem("token");
			localStorage.removeItem("ipAddr");
			localStorage.setItem("lastActiveTime", Date.now());
			localStorage.setItem("idleProcess", "stop");
			Cookies.set("isKicked", action.payload, { expires: (1 / 1440) * 15 });
			Cookies.remove("maintenanceNotice");
			return state;
		},
		REFRESH_TOKEN_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.isTokenExpired = false;
		},
		REFRESH_TOKEN_FAIL: (state, action) => {
			localStorage.removeItem("token");
			state.isTokenExpired = true;
		},
		CHECK_APP_SUCCESS: (state, action) => {
			state.isForceRefresh = false;
		},
		CHECK_APP_FAIL: (state, action) => {
			state.isForceRefresh = true;
		},
		CHECK_APP_RESET: (state, action) => {
			state.isForceRefresh = null;
		},
		SOCIAL_AUTO_LOGIN: (state, action) => {
			state.socialAutoLogin = action.payload;
		},
		LOGOUT_CHECK: (state, action) => {
			state.isLogout = true;
			state.isAuthenticated = false;
		},
		PUBLIC_MODE_SUCCESS: (state, action) => {
			console.log("set public mode");
			state.isPublic = true;
			state.publicEmail = action.payload.publicEmail;
			state.publicToken = action.payload.publicToken;
		},
		PUBLIC_MODE_FAIL: (state, action) => {
			state.isPublic = false;
			state.publicEmail = null;
			state.publicToken = null;
		},
		PUBLIC_MODE_RESET: (state, action) => {
			console.log("reset public mode");
			state.isPublic = false;
			state.publicEmail = null;
			state.publicToken = null;
		},
		UPDATE_PROFILE_PICTURE: (state, action) => {
			const newArray = {
				...state.user,
				profilePic: action.payload.profilePic,
			};
			state.user = newArray;
		},
	},
});

const {
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	REGISTER_SUCCESS,
	REGISTER_FAIL,
	LOGOUT,
	REFRESH_TOKEN_SUCCESS,
	REFRESH_TOKEN_FAIL,
	CHECK_APP_SUCCESS,
	CHECK_APP_FAIL,
	CHECK_APP_RESET,
	USER_PROFILE_BUILD,
	DEFAULT_PAGE,
	VERIFICATION_EMAIL_RESENT_SUCCESS,
	// EMAIL_NOT_VERIFIED,
	SOCIAL_AUTO_LOGIN,
	LOGOUT_CHECK,
	PUBLIC_MODE_SUCCESS,
	PUBLIC_MODE_FAIL,
	PUBLIC_MODE_RESET,
	UPDATE_PROFILE_PICTURE,
} = authSlice.actions;

export const buildProfile = () => async (dispatch) => {
	dispatch(USER_PROFILE_BUILD());
};
export const errorCleanUp = () => (dispatch) => {
	dispatch(DEFAULT_PAGE());
};
export const resendVerificationEmail = ({ email, acctTypeId }) => async (
	dispatch
) => {
	const body = {
		email,
		acctTypeId,
	};
	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/um/request-verification-email`,
		params: body,
	})
		.then((res) => {
			if (res.status === 201) {
				console.log(res);
				dispatch(VERIFICATION_EMAIL_RESENT_SUCCESS());
			} else {
				console.log("resend verification email has problem");
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};
export const loginAction = ({ email, password, acctTypeId, ipAddr }) => async (
	dispatch
) => {
	const body = {
		email,
		password,
		acctTypeId,
		ipAddr,
	};
	console.log(body);
	dispatch(DEFAULT_PAGE());
	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/um/user-validation`,
		params: body,
	})
		.then((res) => {
			console.log(res);
			const response = {
				user: {
					email: res.data.payload.email,
					firstName: res.data.payload.firstName,
					surname: res.data.payload.surname,
					middleName: res.data.payload.middleName,
					acctTypeId: res.data.payload.acctTypeId,
					socialAcctTypeId: res.data.payload.socialAcctTypeId,
					acctId: res.data.payload.acctId,
					acctStatusId: res.data.payload.acctStatusId,
					profilePic: res.data.payload.profilePic,
				},
				buildProfileFlag: res.data.payload.buildProfileFlag,
				message:
					res.data.payload.acctStatusId === 0
						? `Email is not verfied, Please Verify your Email or If Email is not received, Click Resend Verification Email`
						: "Verified Account",
				token: res.data.token,
			};
			if (res.status === 202) {
				const errorResponse = {
					message: "Please Check your Email and Password",
					user: body.acctTypeId,
				};
				dispatch(LOGIN_FAIL(errorResponse));
			} else {
				if (res.data.payload.acctStatusId === 0) {
					dispatch(LOGIN_FAIL(response));
				} else {
					Cookies.remove("isKicked");
					localStorage.setItem("ipAddr", ipAddr);
					dispatch(resetSelectBizPage());
					dispatch(PUBLIC_MODE_RESET());
					dispatch(LOGIN_SUCCESS(response));
					// var pathName = Cookies.get("navigate_path");
					// var previous_login = Cookies.get("previous_login");
					// if (previous_login !== undefined)
					// 	previous_login = decryptData(previous_login);
					// if (
					// 	pathName !== "" &&
					// 	pathName !== undefined &&
					// 	response.buildProfileFlag !== 0 &&
					// 	// && previous_login === response.user.acctId
					// ) {
					// 	// window.location = pathName;
					// 	console.log(pathName);
					// 	history.push(pathName);
					// } else {
					// history.push("/social-feed");
					// if (res.data.payload.acctTypeId === 1)
					// window.location = "/search-job-page";
					// history.push("/search-job-page");
					// currently replaced with user-dashboard(TANISHA)				history.push("/social-feed");
					history.push(`/view-profile/${encryptData(response.user.acctId)}`);
					// else if (res.data.payload.acctTypeId === 2)
					// window.location = "/search-profile-page";
					// history.push("/search-profile-page");
					// }
					// history.go(0)
					Cookies.remove("navigate_path");
					Cookies.remove("maintenanceNotice");
					Cookies.remove("logout_handle");
					Cookies.set("previous_login", encryptData(response.user.acctId));
				}
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			const errorResponse = {
				message: "Server Error",
				user: body.acctStatusId,
			};
			dispatch(LOGIN_FAIL(errorResponse));
		});
};

export const socialLoginAction = ({
	email,
	password,
	acctTypeId,
	socialAcctId,
	ipAddr,
}) => async (dispatch) => {
	const body = {
		email,
		password,
		acctTypeId,
		socialAcctId,
		ipAddr,
	};
	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/um/user-validation`,
		params: body,
	})
		.then((res) => {
			const response = {
				user: {
					email: res.data.payload.email,
					firstName: res.data.payload.firstName,
					surname: res.data.payload.surname,
					middleName: res.data.payload.middleName,
					acctTypeId: res.data.payload.acctTypeId,
					socialAcctTypeId: res.data.payload.socialAcctTypeId,
					acctId: res.data.payload.acctId,
					acctStatusId: res.data.payload.acctStatusId,
					profilePic: res.data.payload.profilePic,
				},
				buildProfileFlag: res.data.payload.buildProfileFlag,
				message:
					res.data.payload.acctStatusId === 0
						? `Email is not verified, Please Verify your Email or If Email is not received, Click Resend Verification Email`
						: "Verified Account",
				token: res.data.token,
			};
			console.log(res);
			if (res.status === 202) {
				const errorResponse = {
					message:
						"We were not able to find any account associated with this social login. Please try again.",
					user: body.acctTypeId,
				};
				dispatch(LOGIN_FAIL(errorResponse));
			} else {
				if (res.data.payload.acctStatusId === 0) {
					dispatch(LOGIN_FAIL(response));
				} else {
					localStorage.setItem("ipAddr", ipAddr);
					dispatch(LOGIN_SUCCESS(response));
					// var pathName = Cookies.get("navigate_path");
					// var previous_login = Cookies.get("previous_login");
					// if (previous_login !== undefined)
					// 	previous_login = decryptData(previous_login);
					// if (
					// 	pathName !== "" &&
					// 	pathName !== undefined &&
					// 	response.buildProfileFlag !== 0
					// 	// && previous_login === response.user.acctId
					// ) {
					// 	// window.location = pathName;
					// 	history.push(pathName);
					// } else if (res.data.payload.acctTypeId === 3)
					history.push("/social-feed");
					// window.location = "/search-job-page";
					// history.push("/search-job-page");
					Cookies.remove("navigate_path");
					Cookies.remove("isKicked");
					Cookies.remove("maintenanceNotice");
					Cookies.remove("logout_handle");
					Cookies.set("previous_login", encryptData(response.user.acctId));
				}
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			dispatch(LOGIN_FAIL);
		});
};

export const registerAction = ({
	email,
	password,
	surname,
	firstName,
	middleName,
	companyName,
	acctStatusId,
	acctTypeId,
	socialAcctTypeId,
	createdAcctId,
	updateAcctId,
}) => (dispatch) => {
	const body = {
		email,
		password,
		surname,
		firstName,
		middleName,
		companyName,
		acctStatusId,
		acctTypeId,
		socialAcctTypeId,
		createdAcctId,
		updateAcctId,
	};
	dispatch(DEFAULT_PAGE());
	axios({
		method: "POST",
		headers: header,
		url: `${instanceUrl}/api/um/create-regular-acct`,
		data: body,
	})
		.then((res) => {
			console.log(res);
			const response = {
				message: res.data.payload.message,
				registeredEmail: email,
			};
			if (res.status === 201) {
				dispatch(REGISTER_SUCCESS(response));
				//history.push("/");
			} else {
				dispatch(REGISTER_FAIL(response));
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
			dispatch(REGISTER_FAIL());
		});
};

export const socialRegisterAction = ({
	email,
	password,
	surname,
	firstName,
	middleName,
	companyName,
	acctStatusId,
	acctTypeId,
	socialAcctTypeId,
	createdAcctId,
	updateAcctId,
	socialAcctId,
}) => (dispatch) => {
	const body = {
		email,
		password,
		surname,
		firstName,
		middleName,
		companyName,
		acctStatusId,
		acctTypeId,
		socialAcctTypeId,
		createdAcctId,
		updateAcctId,
		socialAcctId,
	};
	console.log(body);
	dispatch(DEFAULT_PAGE());
	axios({
		method: "POST",
		headers: header,
		url: `${instanceUrl}/api/um/create-regular-acct`,
		data: body,
	})
		.then((res) => {
			console.log(res);
			const response = {
				message: res.data.message,
				isSocial: true,
			};
			if (res.status === 201) {
				dispatch(REGISTER_SUCCESS(response));
				dispatch(SOCIAL_AUTO_LOGIN(body));
			} else {
				dispatch(REGISTER_FAIL(response));
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			dispatch(REGISTER_FAIL);
		});
};
export const corpRegisterAction = ({
	email,
	password,
	surname,
	firstName,
	middleName,
	acctStatusId,
	acctTypeId,
	socialAcctTypeId,
	createdAcctId,
	updateAcctId,
	companyName,
}) => (dispatch) => {
	const body = {
		email,
		password,
		surname,
		firstName,
		middleName,
		acctStatusId,
		acctTypeId,
		socialAcctTypeId,
		createdAcctId,
		updateAcctId,
		companyName,
	};
	dispatch(DEFAULT_PAGE());
	axios({
		method: "POST",
		headers: header,
		url: `${instanceUrl}/api/um/create-corporate-acct`,
		data: body,
	})
		.then((res) => {
			console.log(res);
			const response = {
				message: res.data.message,
				registeredEmail: email,
			};
			if (res.status === 201) {
				dispatch(REGISTER_SUCCESS(response));
				//history.push("/");
			} else {
				dispatch(REGISTER_FAIL(response));
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			dispatch(REGISTER_FAIL);
		});
};

export const refreshToken = ({ acctId, accessToken, email }) => async (
	dispatch
) => {
	const body = {
		acctId,
		accessToken,
		email,
	};
	// console.log(body);
	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/um/refresh-token`,
		params: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				const response = {
					token: res.data.token,
				};
				dispatch(REFRESH_TOKEN_SUCCESS(response));
			} else {
				dispatch(REFRESH_TOKEN_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const checkToken = ({ acctId, accessToken, email }) => async (
	dispatch
) => {
	const body = {
		acctId,
		accessToken,
		email,
	};
	// console.log(body);
	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/um/check-token`,
		params: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				const response = {
					token: res.data.token,
				};
				dispatch(REFRESH_TOKEN_SUCCESS(response));
			} else {
				dispatch(REFRESH_TOKEN_FAIL());
				dispatch(logout(1));
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
			if (err.message == "Network Error") {
				dispatch(logout(1));
			}
		});
};

export const checkAppVersion = () => async (dispatch) => {
	// console.log(body);
	dispatch(CHECK_APP_RESET());
	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/md/get-app-version`,
		params: "",
	})
		.then((res) => {
			if (res.status === 201) {
				var frontEndRecord = Cookies.get("versionNumber");
				var backEndVersion = res.data.payload.versionNumber;
				// console.log(frontEndRecord, backEndVersion);
				if (
					frontEndRecord &&
					frontEndRecord != "" &&
					frontEndRecord != undefined
				) {
					if (frontEndRecord == backEndVersion) dispatch(CHECK_APP_SUCCESS());
					else {
						Cookies.set("versionNumber", backEndVersion);
						dispatch(CHECK_APP_FAIL());
					}
				} else {
					Cookies.set("versionNumber", backEndVersion);
					// dispatch(emptyCache());
				}
			} else {
				dispatch(CHECK_APP_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const checkAppVersionReset = () => (dispatch) => {
	dispatch(CHECK_APP_RESET());
};

export const checkLogout = () => async (dispatch) => {
	console.log(localStorage.getItem("token"));
	if (localStorage.getItem("token") == null) {
		dispatch(LOGOUT_CHECK());
	}
};

export const logout = (status) => (dispatch) => {
	// console.log(status);
	dispatch(resetSelectBizPage());
	Cookies.set("logout_handle", true, { expires: (1 / 86400) * 5 });
	dispatch(LOGOUT(status));
	// window.location.reload(true);
};

export const publicMode = () => async (dispatch) => {
	// dispatch(PUBLIC_MODE_RESET());
	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/md/get-access-token`,
		params: "",
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				var publicToken = res.data.payload;
				console.log(publicToken, publicEmail);
				if (publicToken && publicEmail) {
					const response = {
						publicToken: publicToken,
						publicEmail: publicEmail,
					};
					console.log(response);
					dispatch(PUBLIC_MODE_SUCCESS(response));
				}
			} else {
				dispatch(PUBLIC_MODE_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const publicModeReset = () => (dispatch) => {
	dispatch(PUBLIC_MODE_RESET());
};
export const updateProfilePicForAuth = ({ profilePic }) => (dispatch) => {
	const response = {
		profilePic,
	};
	dispatch(UPDATE_PROFILE_PICTURE(response));
};
/* export const requestPasswordRecovery = ({email, acctTypeId}) => (dispatch) => {
	const body = {
		email,
		acctTypeId,
	}

	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/um/request-password-recovery`,
		params: body,
	}).then((res) => {

	})
} */
export default authSlice.reducer;
