import { borderRadius } from "@mui/system";
import { opptyDesignColor } from "assets/jss/material-kit-pro-react";
import {
	grayColor,
	primaryColor,
	title,
	blackColor,
	whiteColor,
	container,
} from "assets/jss/material-kit-pro-react.js";

const RecommendedJobsStyle = (theme) => ({
	recommendedJobsCard: {
		boxShadow: "none",
		borderRadius: "15px!important",
		padding: "15px 20px",
		margin: "0 0 10px",

		"& h4": {
			fontSize: "18px",
			fontWeight: "600",
			color: "#4A4A4A",
			margin: "0",
		},

		"& .MuiCardContent-root": {
			paddingBottom: "0",
		},
	},
	recommendedJobItem: {
		display: "flex",
		borderBottom: "1px solid #D8D8D8",
		padding: "20px 0",
	},
	recommendedJobImage: {
		flex: "0 0 50px",
		maxWidth: "50px",
		maxHeight: "50px",
		borderRadius: "50%",
		overflow: "hidden",
		marginRight: "15px",
	},
	recommendedJobItemContent: {
		"& h5": {
			fontSize: "16px",
			fontWeight: "600",
			color: "#4A4A4A",
			margin: "0",
		},
		"& h6": {
			fontSize: "13px",
			fontWeight: "500",
			color: "#4A4A4A",
			margin: "0",
		},

		"& p": {
			margin: "0",
			fontSize: "12px",
			color: "#92929D",
		},
		"& span": {
			margin: "0",
			fontSize: "11px",
			color: "#92929D",
			display: "block",
		},
	},
	seeAllBtnWrap: {
		textAlign: "center",
		padding: "15px 0 0",

		"& .MuiButton-label": {
			color: "#6F52ED",
			fontSize: "18px",
			fontWeight: "400",
		},
	},
});
export default RecommendedJobsStyle;
