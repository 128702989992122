import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import axios from "axios";

import { Box, makeStyles, Modal, Typography } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import { errorCleanUp } from "configurations/redux/authSlice.js";

import {
	encryptData
} from "configurations/configurations.js";

import Header from "views/Component/Header.js";
import EditProfile from "views/Component/ProfileView/EditProfile/EditProfile";
import Footer from "views/Component/Footer.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import resumePageStyles from "assets/jss/material-kit-pro-react/views/pageStyle/ResumePage/resumePageStyles";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js";
import businessPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/BusinessPage/businessPageStyle";
import psycompleted from "assets/img/completed.png";

import Checkbox from '@mui/material/Checkbox';
import Grid from '@material-ui/core/Grid'
import testuser from "assets/img/test.png";

import { getAllPsychometricTest, submitUserPsychometricTest, getUserPsychometricResults } from "../../../configurations/redux/userSlice";
import TestModals from "./TestModals";

const PsychometricTestLanding = ({ setModalResponse }) => {
	const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

	const styles = makeStyles(resumePageStyles)();
	const stylesBusiness = makeStyles(businessPageStyle)();
	const classes = makeStyles(profilePageStyle)(); //profilePageStyle

	const history = useHistory();
	const dispatch = useDispatch();
	const isError = useSelector((state) => state.auth.isError);
	const userStatus = useSelector((state) => state.auth.user);
	const user = useSelector((state) => state.user);
	const [allPsychometricQuestions, setAllPsychometricQuestions] = useState([]);
	const [questionsAnswers, setQuestionsAnswers] = useState();
	const [allAnswers, setAllAnswers] = useState([]);
	const [answerDone, setAnswersDone] = useState([]);
	const [myself, setMyself] = useState(false);
	const [createPost, setCreatePost] = React.useState(false);
	const [width, height] = useWindowSize();

	const [openQuestionsFlow, setOpenQuestionsFlow] = React.useState(false);
	const handleOpenQuestionsFlow = () => {
		setOpenQuestionsFlow(true);
		initializeData();
	}
	const handleCloseQuestionsFlow = () => setOpenQuestionsFlow(false);

	const [open103, setOpen103] = React.useState(false);
	const handleOpen103 = () => setOpen103(true);
	const handleClose103 = () => setOpen103(false);

	const [open105, setOpen105] = React.useState(false);
	const handleOpen105 = () => setOpen105(true);
	const handleClose105 = () => setOpen105(false);

	const [openAbortQuiz, setOpenAbortQuiz] = React.useState(false);
	const abortQuizNow = () => {
		setOpenAbortQuiz(true);
		setOpenQuestionsFlow(false);
	};
	const handleCloseAbortQuiz = () => setOpenAbortQuiz(false);
	console.log(allPsychometricQuestions)
	const style01 = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 550,
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
	};
	const style02 = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
	};

	function useWindowSize() {
		const [size, setSize] = React.useState([0, 0]);
		React.useLayoutEffect(() => {
			function updateSize() {
				setSize([window.innerWidth, window.innerHeight]);
			}
			window.addEventListener("resize", updateSize);
			updateSize();
			return () => window.removeEventListener("resize", updateSize);
		}, []);
		return size;
	}

	React.useEffect(() => {
		if (isError) {
			dispatch(errorCleanUp());
		}


		initializeData();

	}, []);

	function initializeData() {
		dispatch(getAllPsychometricTest({}, (result) => {
			if (result.length > 0) {
				setAllPsychometricQuestions(result);
				setQuestionsAnswers(result[0]);
				setAllAnswers(result[0].answer);
			}
		}));
	}

	const clickAnswerSelection = (ansId, questId) => {
		setAnswersDone(answerDone => [...answerDone, { "quesId": questId, "ansId": ansId }]);

		let views = allAnswers.slice();

		for (let i in views) {
			views[i].class = 'greyOuter';
			if (views[i].ansId == ansId) {
				views[i].class = 'blueOuter';
			} else {
				views[i].class = 'greyOuter';
			}
			setAllAnswers(views);
		}

		setTimeout(callSecondFunction(), 9000);

	};

	function previousQuestion() {
		if (questionsAnswers.quesId != questionsAnswers.totalCount) {
			allPsychometricQuestions && allPsychometricQuestions.length > 0 && allPsychometricQuestions.map((ans, index) => {
				console.log(ans.quesId, questionsAnswers.quesId)
				if (ans.quesId == questionsAnswers.quesId) {
					if (allPsychometricQuestions[index - 1]) {
						setQuestionsAnswers(allPsychometricQuestions[index - 1]);

						allPsychometricQuestions[index + 1] && (allPsychometricQuestions[index + 1]).answer.map((val, index) => {
							var tempProps = JSON.parse(JSON.stringify(val));
							tempProps.class = 'greyOuter';
							Object.preventExtensions(tempProps);

						});
						setAllAnswers(allPsychometricQuestions[index + 1].answer);
					}

				}
			});
		}
	}

	function callSecondFunction() {
		if (questionsAnswers.quesId != questionsAnswers.totalCount) {
			allPsychometricQuestions && allPsychometricQuestions.length > 0 && allPsychometricQuestions.map((ans, index) => {
				if (ans.quesId == questionsAnswers.quesId) {
					if (allPsychometricQuestions[index + 1]) {
						setQuestionsAnswers(allPsychometricQuestions[index + 1]);

						allPsychometricQuestions[index + 1] && (allPsychometricQuestions[index + 1]).answer.map((val, index) => {
							var tempProps = JSON.parse(JSON.stringify(val));
							tempProps.class = 'greyOuter';
							Object.preventExtensions(tempProps);

						});
						setAllAnswers(allPsychometricQuestions[index + 1].answer);
					}

				}
			});
		}
	}

	const submitPsychometricTest = () => {
		const payloadRequested = {
			acctId: userStatus.acctId,
			email: userStatus.email,
			accessToken: localStorage.getItem('token'),
			results: answerDone
		}

		dispatch(submitUserPsychometricTest(payloadRequested, (result) => {
			//if (result.payload.updateResult) {
			setOpenQuestionsFlow(false);
			setOpen105(true);
			//}
		}));
	}

	const openQuizAgain = () => {
		setOpenAbortQuiz(false);

		setOpenQuestionsFlow(true);
	}

	const confirmToAbort = () => {
		setOpenAbortQuiz(false);

		setAllAnswers([]);
		setQuestionsAnswers({});
	}

	const moveToResults = () => {
		setOpen105(false);
		setModalResponse(1);
	}

	return (
		<div className="ResultViewOter">
			<div className="psycompletedtext">
				<h4>Your Psychometric Test</h4>
				<p><span className="leftOut">1 Out of 3 Tries Left</span><Button type="button" variant="contained" onClick={handleOpenQuestionsFlow}>Take The Test</Button></p>
			</div>

			<hr className="hrouter" />
			<div className="testOuter1">
				<img src={testuser} />
				<h5>You do not have any test results yet</h5>
				<p>Click on the ‘Take the Test’ button to discover the <br />ideal career path and jobs for your personality type.</p>
			</div>

			{/* <TestModals /> */}
			<Modal
				open={openQuestionsFlow}
				onClose={handleCloseQuestionsFlow}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box className='PsychometricModal' sx={style01}>
					<a className='crossBtn' onClick={handleCloseQuestionsFlow}><i className="fas fa-xmark"></i></a>
					{questionsAnswers && <Typography className='innerText PsychometricText'>
						<h4>Question {questionsAnswers.quesId} of {questionsAnswers.totalCount}</h4>
						<h5>{questionsAnswers.question}</h5>
						{allAnswers && allAnswers.length > 0 && allAnswers.map((questAnswer, index) => {
							return (
								<div className={`${questAnswer.class}`} onClick={() => { clickAnswerSelection(questAnswer.ansId, questionsAnswers.quesId) }}>
									<p>{questAnswer.answer}</p>
								</div>)
						})}
					</Typography>}

					<Typography component='div' className='MuiTypography-root dayOuter btnOuter rejectShortlist interviewBtns MuiTypography-body2'>
						<Typography component='div' className='dayRight '>
							<Button type="button" className='rejectBtn psychometricright' onClick={previousQuestion}>Back</Button>
						</Typography>

						{questionsAnswers && <Typography component='div' className='dayLeft psychometricleft'>
							{(questionsAnswers.quesId != questionsAnswers.totalCount) && <Button type="button" className='applyBtn psychometricleft12' onClick={abortQuizNow}>Abort quiz</Button>}

							{(questionsAnswers.quesId == questionsAnswers.totalCount) && <Button type="button" className='applyBtn psychometricleft12' onClick={submitPsychometricTest}>Submit</Button>}
						</Typography>}
					</Typography>
				</Box>
			</Modal>

			<Modal
				open={openAbortQuiz}
				onClose={handleCloseAbortQuiz}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box className='psychometricleftQuik' sx={style02}>
					<Typography className='innerText PsychometricText'>

						<div className="quiz">
							<h5>Abort Quiz?</h5>
							<p>Your answers will not be saved and published.</p>
						</div>

					</Typography>
					<Typography component='div' className='MuiTypography-root dayOuter btnOuter rejectShortlist interviewBtns MuiTypography-body2'>
						<Typography component='div' className='dayRight '>
							<Button type='button' className='rejectBtn psychometricright' onClick={openQuizAgain}>Go back to quiz</Button>
						</Typography>
						<Typography component='div' className='dayLeft psychometricleft'>
							<Button type='button' className='applyBtn' onClick={confirmToAbort}>Confirm</Button>
						</Typography>
					</Typography>
				</Box>
			</Modal>

			<Modal
				open={open105}
				onClose={handleClose105}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box className='PsychometricModal FinalPsych' sx={style01}>
					<a className='crossBtn'>
						<i class="fas fa-xmark"></i></a>
					<Typography className='innerText psyCompletd'>
						<img src={psycompleted} />
						<h5>You have completed the<br /> Psychometric Test! </h5>
					</Typography>

					<Button variant="contained" className="seeyour"><a onClick={moveToResults}>See your results</a></Button>
				</Box>
			</Modal>
		</div>
	);
};

// to={`/resultview/${encryptData(userStatus.acctId)}`}
export default PsychometricTestLanding;
