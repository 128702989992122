import { createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import {
	instanceUrl,
	header,
	emptyCache,
} from "configurations/configurations.js";

const messageConversationSearchSlice = createSlice({
	name: "messageConversationSearch",
	initialState: {
		searchCount: 0,
		searchResult: [],
		searchParams: [],
		lastRecordSize: 0,
		requestRecordSize: 0,
		isLoading: false,
		isEnd: false,
		isSearching: false,
		isUpdated: true,
		chatroomId: 0,
		conversationId: 0,
		description: "",
		chatroomList: [],
		readId: "",
		searchId: 0,
	},
	reducers: {
		CONVERSATION_LOADING: (state, action) => {
			state.isLoading = true;
		},
		CONVERSATION_SEARCH_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			if (action.payload.searchResult) {
				state.searchResult = [
					...action.payload.conversationList,
					...action.payload.searchResult,
				];
			} else {
				state.searchResult = [...action.payload.conversationList];
			}
			// console.log(state.searchResult);
			state.searchParams = action.payload.searchParams;
			// state.lastRecordSize =
			// 	state.lastRecordSize + action.payload.requestRecordSize;
			state.lastRecordSize = state.searchResult.length;
			state.requestRecordSize = action.payload.requestRecordSize;
			state.isLoading = false;
			state.isSearching = true;
			state.isUpdated = true;
			state.chatroomId = action.payload.chatroomId;
			state.searchCount = 1;
		},
		CONVERSATION_SEARCH_END: (state, action) => {
			state.isEnd = true;
		},
		DEFAULT_CONVERSATION_SEARCH: (state) => {
			state.searchResult = [];
			state.searchParams = [];
			state.lastRecordSize = 0;
			state.requestRecordSize = 0;
			state.isEnd = false;
			state.isLoading = false;
			state.isSearching = false;
			state.isUpdated = true;
			state.chatroomId = 0;
			state.conversationId = 0;
			state.description = "";
			state.chatroomList = [];
			state.readId = "";
			state.searchId = 0;
		},
		CONVERSATION_SEARCH_FAIL: (state) => {
			state.isUpdated = false;
		},
		CONVERSATION_LAST_CONVERSATION: (state, action) => {
			state.conversationId = action.payload.conversationId;
		},
		CONVERSATION_SEARCH_ID: (state, action) => {
			state.searchId = action.payload.searchId;
		},
		CONVERSATION_SEARCH_COUNT_RESET: (state, action) => {
			state.searchCount = 0;
		},
	},
});

const {
	CONVERSATION_LOADING,
	CONVERSATION_SEARCH_SUCCESS,
	CONVERSATION_SEARCH_END,
	DEFAULT_CONVERSATION_SEARCH,
	CONVERSATION_SEARCH_FAIL,
	CONVERSATION_LAST_CONVERSATION,
	CONVERSATION_SEARCH_ID,
	CONVERSATION_SEARCH_COUNT_RESET,
} = messageConversationSearchSlice.actions;

export const messageConversationSearchInfo = ({
	acctId,
	accessToken,
	email,
	lastRecordSize,
	requestRecordSize,
	chatroomId,
	searchResult,
	keyword,
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		email,
		lastRecordSize,
		requestRecordSize,
		chatroomId,
		keyword,
	};
	dispatch(CONVERSATION_LOADING());
	if (lastRecordSize === 0) {
		dispatch(DEFAULT_CONVERSATION_SEARCH());
	}

	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/mm/get-conversation`,
		params: body,
	})
		.then((res) => {
			// console.log(body);
			if (res.status === 201) {
				var reversed = res.data.payload.conversationList.reverse();
				// console.log(searchResult);
				if (searchResult) {
					// console.log(res.data.payload.conversationList);
					// console.log(res.data.payload.conversationList.reverse());
					if (res.data.payload.conversationList.length > 0) {
						for (var i = 0; i < res.data.payload.conversationList.length; i++) {
							searchResult = searchResult.filter((item) => item.conversationId != res.data.payload.conversationList[i].conversationId);
						}
					}
				}
				const response = {
					searchResult,
					conversationList: reversed,
					lastRecordSize,
					requestRecordSize: res.data.payload.conversationList.length,
					token: res.data.token,
					chatroomId: chatroomId,
				};
				dispatch(CONVERSATION_SEARCH_SUCCESS(response));
				if (res.data.payload.conversationList.length > 0) {
					const response = {
						conversationId: res.data.payload.conversationList[0].conversationId,
					};
					dispatch(CONVERSATION_LAST_CONVERSATION(response));
				}
				// console.log(requestRecordSize, res.data.payload.conversationList.length)
				if (requestRecordSize > res.data.payload.conversationList.length) {
					dispatch(CONVERSATION_SEARCH_END());
				}
			} else if (res.status === 202) {
				dispatch(CONVERSATION_SEARCH_END());
			} else {
				dispatch(CONVERSATION_SEARCH_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const messageConversationBySearchId = ({ searchId }) => async (
	dispatch
) => {
	const response = {
		searchId: searchId,
	};
	dispatch(CONVERSATION_SEARCH_ID(response));
};

export const messageConversationBySearchIdReset = () => async (dispatch) => {
	const response = {
		searchId: 0,
	};
	dispatch(CONVERSATION_SEARCH_ID(response));
};

export const messageConversationSearchReset = () => async (dispatch) => {
	dispatch(DEFAULT_CONVERSATION_SEARCH());
	dispatch(CONVERSATION_SEARCH_COUNT_RESET());
};
export default messageConversationSearchSlice.reducer;
