import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { TimeFormat } from "configurations/configurations.js";
import history from "../../../history.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Tooltip from "@material-ui/core/Tooltip";
import Divider from "@material-ui/core/Divider";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Danger from "components/Typography/Danger.js";
import Primary from "components/Typography/Primary.js";
import Muted from "components/Typography/Muted.js";

import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";

import corporateJobManagementPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/CorporateJobManagementPage/corporateJobManagementPageStyle.js";
import DeleteJobModal from "./DeleteJobModal.js";
import JobEditModal from "./JobEditModal.js";
import IconLibrary from "views/Component/IconLibrary.js";
import { Hidden } from "@material-ui/core";
import defaultAvatar from "assets/img/corporate_avatar.jpeg";

const useStyles = makeStyles(corporateJobManagementPageStyle);

export default function CorporateJobCard({ jobPostList, statusId, selectedTypeId }) {
	const classes = useStyles();
	//const jobPostList = useSelector((state) => state.jobManagement.jobPostList);

	const [deleteJobModal, setDeleteJobModal] = React.useState(false);
	const [updateJobModal, setUpdateJobModal] = React.useState(false);
	const [jobRecordId, setJobRecordId] = React.useState();
	const userStatus = useSelector((state) => state.auth.user);


	const closeDeleteJobModal = () => {
		setDeleteJobModal(false);
		setJobRecordId();
	};
	const date = new Date();
	const closeUpdateJobModal = () => {
		setUpdateJobModal(false);
		setJobRecordId();
	};
	const NoJobPostList = () => {
		// return (
		// 	<div>
		// 		<Card>
		// 			<CardBody>
		// 				<h5 className={classes.cardTitle}>Not Job Result Found</h5>
		// 			</CardBody>
		// 		</Card>
		// 	</div>
		// );
		return (
			<Card className={classes.cardFrame}>
				<CardBody>
					<GridContainer justifyContent="center">
						<GridItem xs={12} sm={12} md={12} className={classes.itemCenter}>
							<h5 className={classes.title}>
								No{" "}
								{statusId === 0 && selectedTypeId == -1
									? "Draft"
									: statusId === 1 && selectedTypeId == 1
										? "Published"
										: statusId === 1 && selectedTypeId == 2
											? "Head Hunted"
											: statusId === 2
												? "Deactivated"
												: null}{" "}
								Job Post Found
							</h5>
						</GridItem>
						<GridItem className={classes.itemCenter}>
							<Link
								className={classes.textDeco}
								to={{
									pathname: "/corporate-job-create-page",
								}}
								color="transparent"
							>
								<Button round color="oppty">
									<b>Upload New Job Post</b>
								</Button>
							</Link>
						</GridItem>
					</GridContainer>
				</CardBody>
			</Card>
		);
	};


	const JobPostList = jobPostList.map((item) => {
		var jobCard = classes.cardClick + " " + classes.jobCard;
		if (jobPostList) {
			if (jobPostList[0].jobPostId === item.jobPostId) {
				jobCard =
					classes.cardClick +
					" " +
					classes.jobCard +
					" " +
					classes.jobCardFirst;
			} else if (
				jobPostList[jobPostList.length - 1].jobPostId === item.jobPostId
			) {
				jobCard =
					classes.cardClick +
					" " +
					classes.jobCard +
					" " +
					classes.jobCardBorder +
					" " +
					classes.jobCardLast;
			} else {
				jobCard =
					classes.cardClick +
					" " +
					classes.jobCard +
					" " +
					classes.jobCardBorder;
			}
			if (jobPostList.length === 1) {
				jobCard =
					classes.cardClick + " " + classes.jobCard + " " + classes.jobCardOnly;
			}
		}
		return (
			<Link
				key={item.jobPostId}
				className={item.jobPostId}
				to={{
					//pathname: `/corporate-job-view-page/${item.encryptJobPostId}`,
					pathname: `/application-job-landing/${item.encryptJobPostId}`,
					//state: { acctId: item.acctId },
				}}
				target="_blank"
			>
				<Card className='jobCardCustom' key={item.jobPostId}>
					<CardBody>
						<GridContainer>
							<GridItem>
								<GridContainer
									justifyContent="space-between"
									alignContent="center"
									alignItems="center"
								// onClick={() => {
								// 	setJobRecordId(item.jobPostId);
								// }}
								>
									{/* <GridItem xs={3} sm={2} md={1} className={classes.itemCenter}>
										<img
											className={classes.cardImage}
											src={item.profilePic ? item.profilePic : defaultAvatar}
											alt="..."
										/>
									</GridItem> */}
									<GridItem xs={12} sm={12} md={9}>
										<GridContainer alignContent="center" alignItems="center">
											<GridItem
												xs={12}
												sm={12}
												md={8}
												className={classes.gridItemMargin}
											>
												<div className='postJobDivv'>
													<h4 className={classes.jobTitle}>
														{item.jobPostInfo.title}
													</h4>
													<h5
														className={
															classes.description + " " + classes.companyDesc
														}
													>
														{item.jobPostInfo.currencyCode} {item.jobPostInfo.salaryMin ? item.jobPostInfo.salaryMin : 0} - {item.jobPostInfo.salaryMax ? item.jobPostInfo.salaryMax : 0} / {item.jobPostInfo.jobPostType}
													</h5>
													<ul className='posull'>
														<li>
															<span>{item.jobPostInfo.applicationsCount}</span>
															<p>Applications</p>
														</li>
														<li>
															<span>{item.jobPostInfo.shortlistedCount}</span>
															<p>Shortlisted</p>
														</li>
														<li>
															<span>{item.jobPostInfo.interviewCount}</span>
															<p>Interviewed</p>
														</li>
														<li>
															<span>{item.jobPostInfo.offerCount}</span>

															<p>Offered</p>
														</li>
														<li>
															<span>{item.jobPostInfo.hiredCount}</span>
															<p>Hired</p>
														</li>

													</ul>
													<div className='statusDiv'>
														<span>Status: <b style={{ textTransform: 'capitalize' }} >{item.jobPostInfo.statusName}</b></span>
														<span>Published by: <b>{userStatus.firstName}</b></span>
														<span>Modified {TimeFormat(item.jobPostInfo.lastUpdateDate)}</span>
													</div >
													{/* <h5
													className={
														classes.description +
														" " +
														classes.applicationStatus
													}
												>
													{item.jobPostInfo.statusName}
												</h5> */}
												</div >
											</GridItem >
											{/* <GridItem
												xs={12}
												sm={5}
												md={4}
												className={
													classes.gridItemMargin + " " + classes.rightGrid
												}
											>
												<h5 className={classes.jobPostStatus}>
													{item.jobPostInfo.jobPostType}{" "}
													{item.jobPostInfo.jobPostTypeId === 2
														? " - " + item.jobPostInfo.jobType
														: null}
												</h5>

												<h5 className={classes.jobTime}>
													Modified {TimeFormat(item.jobPostInfo.lastUpdateDate)}
												</h5>

												<h5 className={classes.jobTime}>
													Expires {TimeFormat(item.expiryDate)}
												</h5>
											</GridItem> */}
										</GridContainer >
									</GridItem >
									<GridItem
										xs={12}
										sm={12}
										md={3}
										className={classes.buttonCenter}
									>
										<div className='btnEDitdelete'>
											<Tooltip
												id="tooltip-top"
												title="Edit Job Post"
												placement="top"
												classes={{ tooltip: classes.tooltip }}
											>
												<div>
													<Hidden mdUp>
														<Button
															onClick={(event) => {
																event.preventDefault();
																setJobRecordId(item.jobPostId);
																setUpdateJobModal(true);
															}}
															color="opptyWrapBorder"
															className='editBtnn'
														>
															<IconLibrary.Pen />
														</Button>
													</Hidden>
													<Hidden smDown>
														<Button
															onClick={(event) => {
																event.preventDefault();
																setJobRecordId(item.jobPostId);
																setUpdateJobModal(true);
															}}
															color="opptyWrap"
															className='editBtnn'
														>
															<IconLibrary.Pen /> EDIT
														</Button>
													</Hidden>
												</div>
											</Tooltip>
											<Tooltip
												id="tooltip-top"
												title="Delete Job Post"
												placement="top"
												classes={{ tooltip: classes.tooltip }}
											>
												<Button
													onClick={(event) => {
														event.preventDefault();
														setJobRecordId(item.jobPostId);
														setDeleteJobModal(true);
													}}
													// justIcon
													className='deletebtnn'
													color="grayWrapBorder"
												>
													<IconLibrary.Trash /> DELETE
												</Button>
												{/* 		<Button
														
														onClick={(event) => {
															event.preventDefault();

															setJobRecordId(item.jobPostId);
															setDeleteJobModal(true);
														}}
														justIcon
														className={
															classes.buttonRight + " " + classes.buttonDelete
														}
													>
														<IconLibrary.Close />
													</Button> */}
											</Tooltip>
										</div>
									</GridItem>
								</GridContainer >
							</GridItem >
						</GridContainer >
					</CardBody >
				</Card >
			</Link >
		);
	});

	return (
		<div>
			{jobPostList.length > 0 ? JobPostList : <NoJobPostList />}

			{jobRecordId ? (
				<React.Fragment>
					{deleteJobModal && (
						<DeleteJobModal
							open={deleteJobModal}
							onClose={closeDeleteJobModal}
							jobPostId={jobRecordId}
						/>
					)}
					{updateJobModal && (
						<JobEditModal
							open={updateJobModal}
							onClose={closeUpdateJobModal}
							jobPostId={jobRecordId}
							jobPostRecord={
								jobPostList
									.filter((item) => {
										return item.jobPostId === jobRecordId;
									})
									.map((item) => item.jobPostInfo)[0]
							}
						/>
					)}
				</React.Fragment>
			) : null}
		</div>
	);
}
