import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCandidateSelectJobPosts } from "configurations/redux/jobManagementSlice.js";
import { TimeFormat, encryptData } from "configurations/configurations.js";
import { makeStyles } from "@material-ui/core/styles";

import Avtar from "assets/images/avtar.png";

import classNames from "classnames";
import { Link } from "react-router-dom";
import Zoom from 'assets/img/zoom.png';

import Tooltip from "@material-ui/core/Tooltip";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Primary from "components/Typography/Primary.js";
import Danger from "components/Typography/Danger.js";

import jobStatusPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/JobStatusPage/jobStatusPageStyle.js";
import Pagination from "../../Component/Pagination.js";
import ResultJobModal from "../SearchJobPage/ResultJobModal.js";
import WithdrawlModal from "./WithdrawlModal.js";
import AcceptPendingModal from "./AcceptPendingModal";
import RejectPendingModal from "./RejectPendingModal";
import DeclinePendingModal from "./DeclinePendingModal";
import NoRecordModal from "../../Component/NoRecordModal.js";

import defaultAvatar from "assets/img/corporate_avatar.jpeg";
import EditIcon from "@material-ui/icons/Edit";
import LoadingImage from "assets/img/Loading-pana.png";

import { Divider, IconButton } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";

import {
  getPendingJobPostList,
  getOfferJobPost
} from "configurations/redux/appliedJobSlice";

import Backdrop from "../../Component/Backdrop.js";
import { resetNotificationItem } from "configurations/redux/eventNotificationSlice";
import { updateApplicantStatus } from "configurations/redux/jobManagementSlice.js";
import {
  getEventsByAcct,
  getEventDetailByEventId,
  confirmEventScheduleStatus,
  getReportReasonByType,
  updateUserEventReschedules,
  declineEventScheduleStatus,
  createReport
} from 'configurations/redux/userDashboardSlice';

import { confirmEventScheduleStatusSeeker } from "configurations/redux/appliedJobSlice";

import CreatePostModal from "../../../components/CreatePostModal/CreatePostModal";
import IconLibrary from "views/Component/IconLibrary.js";
import { Box, Modal, Button, Typography, Popover } from '@material-ui/core';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fb from 'assets/img/fb.png';
import dateFormat from 'dateformat';
import { useSnackbar } from "notistack";
import moment from 'moment';
const publicIp = require("public-ip");

const useStyles = makeStyles(jobStatusPageStyle);


export default function PendingJobSection(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userStatus = useSelector((state) => state.auth.user);

  const pendingJobPostList = useSelector(
    (state) => state.appliedJob.jobPostListPending
  );

  // const pendingJobPost = useSelector((state) => state.appliedJob.jobPostPending);

  const isLoading = useSelector((state) => state.appliedJob.isLoadingPending);
  const isLoadingJob = useSelector((state) => state.appliedJob.isLoadingJobPending);

  const loadingOfferJobList = useSelector(
    (state) => state.appliedJob.loadingJobListPending
  );

  const [minDate, setMinDate] = React.useState();
  //const [currentPage, setCurrentPage] = React.useState(1);
  const [jobsPerPage, setJobsPerPage] = React.useState(2);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [clientIp, setClientIp] = React.useState();
  const [jobViewModal, setJobViewModal] = React.useState(false);
  const [withdrawModal, setWithdrawModal] = React.useState(false);
  const [acceptModal, setAcceptModal] = React.useState(false);
  const [rejectModal, setRejectModal] = React.useState(false);
  const [declineModal, setDeclineModal] = React.useState(false);

  const [noRecordModal, setNoRecordModal] = React.useState(false);

  const [modalEventDetail, setModalEventDetail] = React.useState();
  const [rescheduleInterviewModal, setRescheduleInterviewModal] = React.useState(false);
  const handleRescheduleInterviewModal = () => setRescheduleInterviewModal(false);
  const [rescheduleInterviewConfirmModal, setRescheduleInterviewConfirmModal] = React.useState(false);
  const handleRescheduleInterviewConfirmModal = () => setRescheduleInterviewConfirmModal(false);

  const [upcomingInterviewAccepted, setUpcomingInterviewAccepted] = React.useState(false);
  const handleUpcomingInterviewAcceptedModal = () => setUpcomingInterviewAccepted(false);


  const [open222, setOpen222] = React.useState(false);
  const handleOpen222 = () => setOpen222(true);
  const handleClose222 = () => setOpen222(false);

  const [dateSelection1, setDateSelection1] = React.useState();
  const [dateSelection2, setDateSelection2] = React.useState();
  const [dateSelection3, setDateSelection3] = React.useState();
  const [timeSelection1, setTimeSelection1] = React.useState();
  const [timeSelection2, setTimeSelection2] = React.useState();
  const [timeSelection3, setTimeSelection3] = React.useState();
  const [reasonRescheduling, setReasonRescheduling] = React.useState('');

  const [dateSlot, setDateSlot] = React.useState([]);
  const [currentEvent, setCurrentEvent] = React.useState([]);
  const [currentJobProfilePic, setCurrentJobProfilePic] = React.useState([]);
  const [viewJobPostItem, setViewJobPostItem] = React.useState([]);

  const [selectedScheduleId, setSelectedScheduleId] = React.useState('');
  const [activeSlotId, setActiveSlotId] = React.useState('');


  const [selectedJobPost, setSelectedJobPost] = React.useState([]);
  const [createPost, setCreatePost] = React.useState(false);
  const [selectedPost, setSelectedPost] = React.useState(false);

  const [proposedModal, setProposedModal] = React.useState(false);
  const handleCloseProposedModal = () => setProposedModal(false);

  const getJobPostModel = async (jobPostSelId) => {
    const requestProfile = {
      acctId: userStatus.acctId,
      email: userStatus.email,
      accessToken: localStorage.getItem("token"),
      // acctId: auth.isPublic ? 0 : userStatus.acctId,
      // accessToken: auth.isPublic ? auth.publicToken : localStorage.getItem('token'),
      ipAddr: localStorage.getItem('ipAddr'), //await publicIp.v4(),
      jobPostId: jobPostSelId,
      item: null,
    };
    console.log(requestProfile);
    dispatch(getCandidateSelectJobPosts(requestProfile));
    setJobViewModal(true);
  };

  const onJobModelClose = () => {
    setJobViewModal(false);
  };

  /*  const selectedJobPost = useSelector(
     (state) => state.jobManagement.selectedJobPost
   ); */
  const statusChangeHandler = (item) => {
    // console.log(item);
    setCreatePost(false);
    setSelectedPost(false);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };


  const [jobPostId, setJobPostId] = React.useState();
  const [applId, setApplId] = React.useState();
  const notificationJobPostItem = useSelector(
    (state) => state.eventNotification.item
  );

  React.useEffect(() => {
    var dtToday = new Date();

    var month = dtToday.getMonth() + 1;
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();
    if (month < 10)
      month = '0' + month.toString();
    if (day < 10)
      day = '0' + day.toString();

    var maxDate = year + '-' + month + '-' + day;
    setMinDate(maxDate);

  }, []);

  const onClose = () => {
    setJobPostId();
    setJobViewModal(false);
    setCurrentPage(currentPage);
    var indexOfFirstJob = currentPage * jobsPerPage - jobsPerPage;
    getJobPostList(indexOfFirstJob, false);
  };
  const noRecordOnClose = () => {
    setNoRecordModal(false);
  };

  const withdrawOnClose = () => {
    setApplId();
    setWithdrawModal(false);
  };

  const acceptOnClose = () => {
    setApplId();
    setAcceptModal(false);
  };

  const rejectOnClose = () => {
    setApplId();
    setRejectModal(false);
  };

  const declineOnClose = () => {
    setApplId();
    setDeclineModal(false);
    handleClose222();
  };

  /*snackbarCustom sir start*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const onClickDismiss = (key) => () => {
    closeSnackbar(key);
  };


  const snackbarCustom = (title) => {
    const action = (key) => (
      <React.Fragment>
        <IconButton onClick={onClickDismiss(key)}>
          <CloseIcon />
        </IconButton>
      </React.Fragment>
    );
    snackBarShow(title, "default", action);
  };
  const snackBarShow = (message, variant, action) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, {
      variant: variant,
      action,
    });
  };

  /*snackbarCustom sir end*/
  const zoomAPIIntegration = async function handleZoom() {
    await fetch(`https://api.zoom.us/v2/users/neelbhardwaj97@gmail.com/meetings`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOm51bGwsImlzcyI6Im5qeEpzNUZyUUVTdU5WVTNqZF9menciLCJleHAiOjE2NTcwMjM5NzcsImlhdCI6MTY1NzAxODU3OH0.jAWYuBxqcRb9vSiu5r9KOyuIASXPx1W9CmYI7RA7pCE'
      },
      body: {
        "topic": "The title of your zoom meeting",
        "type": 2,
        "start_time": moment().format(),
        "duration": "45",
        "timezone": "Europe/Madrid",
        "agenda": "test",
        "recurrence": {
          "type": 1,
          "repeat_interval": 1
        },
        "settings": {
          "host_video": "true",
          "participant_video": "true",
          "join_before_host": "False",
          "mute_upon_entry": "False",
          "watermark": "true",
          "audio": "voip",
          "auto_recording": "cloud"
        }
      }
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const setSlotActive = (id) => {
    setSelectedScheduleId(id);

  }

  const handleDeclineInterview = () => {
    setSelectedScheduleId(0);
  }

  const setEventScheduleStatus = (status) => {

    if (selectedScheduleId) {

      const payloadRequested = {
        acctId: userStatus.acctId,
        email: userStatus.email,
        accessToken: localStorage.getItem('token'),
        eventId: currentEvent.eventId,
        scheduleId: selectedScheduleId,
        statusId: status
      };

      dispatch(confirmEventScheduleStatus(payloadRequested, (result) => {
        //setOpen22(false);
        if (status == 1) {
          snackbarCustom("Job Submit For Reschedule  Successfully.");

          //setConfirmInterviewModal(true);
          //setConfirmTimeSlot();
        }
        else {
          snackbarCustom("Job Submit For Decline  Successfully.");

        }
        handleClose222();
        getJobPostList(jobsPerPage);

      }));

    } else {
      snackbarCustom("Please make some selection out of the time slots mentioned.");
    }

  }

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    var indexOfFirstJob = pageNumber * jobsPerPage - jobsPerPage;
    getJobPostList(indexOfFirstJob, true);
  };

  const getJobPost = async (item) => {
    // console.log(item);
    if (item && item.jobPostStatusId === 1) {
      const requestProfile = {
        acctId: userStatus.acctId,
        accessToken: localStorage.getItem("token"),
        ipAddr: localStorage.getItem("ipAddr"), //await publicIp.v4(),
        jobPostId: item.jobPostId,
        item: item,
      };
      console.log(requestProfile);
      //dispatch(getCandidateSelectJobPosts(requestProfile));
      dispatch(getPendingJobPostList(requestProfile));
      setJobViewModal(true);
    } else {
      setNoRecordModal(true);
    }
  };

  const getJobPostList = async (curPageSize, scroll) => {
    const requestProfile = {
      acctId: userStatus.acctId,
      email: userStatus.email,
      accessToken: localStorage.getItem("token"),
      lastRecordSize: 0,
      requestRecordSize: curPageSize,//jobsPerPage,

      statusIds: '9|10|11|12|13|14'
    };
    console.log(' 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 16 | 17 | 18');
    console.log('getSeekerJobPostList');
    console.log(requestProfile);
    dispatch(getPendingJobPostList(requestProfile));

    if (scroll) window.scrollTo(0, 0);
  };

  /*   React.useEffect(() => {
      if (notificationJobPostItem > 0) {
        console.log(notificationJobPostItem);
        const requestProfile = {
          acctId: userStatus.acctId,
          email: userStatus.email,
          accessToken: localStorage.getItem("token"),
          applId: notificationJobPostItem,
        };
        dispatch(getOfferJobPost(requestProfile));
      }
    }, [notificationJobPostItem]); */

  /*   React.useEffect(() => {
      if (notificationJobPostItem > 0) {
        if (pendingJobPost) {
          getJobPost(pendingJobPost[0]);
          dispatch(resetNotificationItem());
        } else {
          setNoRecordModal(true);
          dispatch(resetNotificationItem());
        }
        pageResize();
      }
      pageResize();
    }, [pendingJobPost]);
   */
  React.useEffect(() => {
    //pageResize();
    getJobPostList(jobsPerPage);
  }, []);

  const pageResize = () => {
    var timerApplied = setInterval(() => {
      if (document.readyState === "complete") {
        var pageHeader = document.getElementById("pageHeader");
        var appliedCard = document.getElementById("appliedCard");
        if (pageHeader && appliedCard) {
          clearInterval(timerApplied);
          pageHeader.style.minHeight = "468px";
          if (appliedCard.clientHeight <= 450) {
            pageHeader.style.height = "468px";
          } else {
            pageHeader.style.minHeight = "1px";
            pageHeader.style.height = "auto";
          }
        } else {
          setTimeout(() => {
            clearInterval(timerApplied);
          }, 3000);
        }
      }
    }, 50);
    return () => clearInterval(timerApplied);
  };


  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [open1, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let totalJobCount = props.appliedJobCounter;


  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = pendingJobPostList && pendingJobPostList.slice(indexOfFirstJob, indexOfLastJob);

  //props.handlePendingJobsCount(totalJobCount);

  const moreAppliedJobs = (curcount) => {

    // let newsavesize = pasetInt(currentPage) + pasetInt(5);
    let newsavesize = jobsPerPage + 2;

    setJobsPerPage(newsavesize);
    getJobPostList(newsavesize);

    //dispatch(getAppliedJobPostList(defaultJobRequest));

  };

  const refreshAppliedJobs = () => {
    const defaultJobRequest = {
      acctId: userStatus.acctId,

      accessToken: localStorage.getItem("token"),
      lastRecordSize: 0,
      requestRecordSize: currentPage,
      paginationClick: false,
    };
    // let newsavesize = pasetInt(currentPage) + pasetInt(5);

    dispatch(getPendingJobPostList(defaultJobRequest));

  };


  const updateJobStatus = async (stateJobPostId, applicantId, status_id) => {
    /* 	const request = {
    acctId: userStatus.acctId,
    email: userStatus.email,
    accessToken: localStorage.getItem("token"),

    applicantId:applicantId,
    applId:stateJobPostId,
    applStatusId: 3

    }; */
    const request = {
      acctId: userStatus.acctId,
      email: userStatus.email,
      accessToken: localStorage.getItem("token"),
      applicantId: applicantId,
      applId: stateJobPostId,
      applStatusId: status_id

    };

    dispatch(updateApplicantStatus(request));
    refreshAppliedJobs();
    if (status_id == 12) {
      snackbarCustom("Job Canceled  Successfully.");
    }
    if (status_id == 13) {
      snackbarCustom("Job Submit For Reschedule  Successfully.");
    }

  };

  const handleClickInterviewDetail = (item) => {
    setCurrentJobProfilePic(item.profilePic);
    setCurrentEvent(item.interview.eventList[0]);

    setViewJobPostItem(item);
    setUpcomingInterviewAccepted(true);
  }

  const handleDateOneRange = (e) => {
    setDateSelection1(e.target.value)
  }

  const handleDateTwoRange = (e) => {
    setDateSelection2(e.target.value)
  }

  const handleDateThreeRange = (e) => {
    setDateSelection3(e.target.value)
  }

  const handleTimeOneRange = (e) => {
    setTimeSelection1(e.target.value);
  }

  const handleTimeTwoRange = (e) => {
    setTimeSelection2(e.target.value);
  }

  const handleTimeThreeRange = (e) => {
    setTimeSelection3(e.target.value);
  }

  const handleReasonRescheduling = (e) => {
    setReasonRescheduling(e.target.value);
  }

  const submitAlternateTimingsCandidate = (eventId, bit) => {
    var startdate1 = moment(dateSelection1 + 'T' + timeSelection1).format('YYYY-MM-DD HH:mm:ss');
    var returned_endate1 = moment(startdate1).add(1, 'hours').format('YYYY-MM-DD HH:mm:ss');

    var startdate2 = moment(dateSelection2 + 'T' + timeSelection2).format('YYYY-MM-DD HH:mm:ss');
    var returned_endate2 = moment(startdate2).add(1, 'hours').format('YYYY-MM-DD HH:mm:ss');

    var startdate3 = moment(dateSelection3 + 'T' + timeSelection3).format('YYYY-MM-DD HH:mm:ss');
    var returned_endate3 = moment(startdate3).add(1, 'hours').format('YYYY-MM-DD HH:mm:ss');

    if (startdate1 == 'Invalid date') {
      snackbarCustom("Select first time-slot.");
    }
    else if (startdate2 == 'Invalid date') {
      snackbarCustom("Select second time-slot.");
    }
    else if (startdate3 == 'Invalid date') {
      snackbarCustom("Select third time-slot.");
    }
    else if (moment(startdate2).isBefore(startdate1)) {
      snackbarCustom("Second time-slot cannot be the same or less is First or Third.");
    }
    else if (moment(startdate3).isBefore(startdate2)) {

      snackbarCustom("Third time-slot cannot be the same or less is First or Second.");
    }
    //  else if (slot_one_time.isBefore(slot_two_time) == false) {
    else {

      const payloadRequested = {
        accessToken: localStorage.getItem('token'),
        email: userStatus.email,
        acctId: userStatus.acctId,
        eventId: parseInt(eventId),
        schedules: [
          {
            "startDate": startdate1,
            "endDate": returned_endate1
          },
          {
            "startDate": startdate2,
            "endDate": returned_endate2
          },
          {
            "startDate": startdate3,
            "endDate": returned_endate3
          }
        ]
      };

      dispatch(updateUserEventReschedules(payloadRequested, (response) => {
        if (response.payload.result == true) {
          getJobPostList(jobsPerPage);
          setProposedModal(false);
          setOpen222(false);
          setRescheduleInterviewConfirmModal(true);
        }
      }));
    }

  }

  //props.handleOfferJobsCount(pendingJobPostList);          
  const AppliedJob = pendingJobPostList && pendingJobPostList.map((item, index) => {

    return (
      <div key={item.applId} >

        <Typography component='div' className='jobInvitation grey'>
          <Typography component='div' className='inviteJob pendingInterview companyA'>
            <Typography component='div' className='inviteJobLeft'>
              <img width="40px"
                src={item.profilePic ? item.profilePic : defaultAvatar}
                alt="..."
              />
            </Typography>
            <Typography component='div' className='inviteJobRight'>

              <Typography component='div' className='AppliedJobTitle designTitle'>{item.title}</Typography>
              <Typography components='div' className='viewJobPost'><Link onClick={() => getJobPostModel(item.jobPostId)} to="#">View job post</Link></Typography>

              <Link to="#" onClick={(event) => {
                if (item.applStatusId != 13) {
                  handleClickInterviewDetail(item)
                }

              }} >
                <span className='pending'>{item.applStatusName}</span>  </Link>
              <Link to="#" onClick={(event) => {
                if (item.applStatusId != 13) {
                  handleClickInterviewDetail(item)
                }

              }} >
                {
                  item.interview && item.interview.eventSchedule ? (
                    <Typography component='div' className='contractDuration'>
                      <Typography component='div' className='appliedJobSubtitle'><i className="far fa-calendar"></i><b>{dateFormat(item.interview.eventList[0].eventSchedule.eventScheduleList[0].startDate, "dS, mmmm yyyy")}</b></Typography>
                      <p className='contractduractionTitle time'>{dateFormat(item.interview.eventList[0].eventSchedule.eventScheduleList[0].startDate, "h:MM TT")}</p>
                    </Typography>
                  ) : ('')

                }

              </Link>
              <Link to="#" onClick={(event) => {
                if (item.applStatusId != 13) {
                  handleClickInterviewDetail(item)
                }
              }} >
                <Typography component='div' className='contractDuration'>

                  <Typography component='div' className='appliedJobSubtitle'>
                    {item.interview && item.interview.eventList.length > 0 && item.interview.eventList[0].typeId == 1 ? (
                      <> <i className="fas fa-globe"></i><b>Via Video</b></>
                    ) : item.interview && item.interview.eventList.length > 0 && item.interview.eventList[0].typeId == 2 ? (
                      <> <i className="fas fa-map"></i><b>Via Visit</b></>
                    ) : item.interview && item.interview.eventList.length > 0 && item.interview.eventList[0].typeId == 3 ? (

                      <> <i className="fas fa-phone"></i><b>Via Phones {item.typeId}</b></>
                    ) : ('')}


                  </Typography>

                </Typography>
              </Link>

            </Typography>
          </Typography>

          {item.applStatusId != 13 ? (

            <Typography component='div' className='dayOuter btnOuter cancelInterviewOuter'>
              <Typography component='div' className='dayLeft'>
                <Button
                  onClick={(event) => {

                    setViewJobPostItem(item);
                    setDateSlot(item.interview.eventList.length > 0 && item.interview.eventList[0].eventSchedule.eventScheduleList);
                    setCurrentEvent(item.interview.eventList.length > 0 && item.interview.eventList[0]);
                    setProposedModal(true);
                    //handleOpen222();


                    //   setApplId(item.applId);
                    //   setAcceptModal(true);


                    // updateJobStatus(item.applId,userStatus.acctId,12); 
                    /* event.stopPropagation();
                    setApplId(item.applId);
                    setAcceptModal(true); */
                  }}
                  className='applyBtn'>Reschedule</Button>
              </Typography>
              <Typography component='div' className='dayRight'>
                <Button

                  onClick={(event) => {
                    //  event.stopPropagation();

                    setApplId(item.applId);
                    setRejectModal(true);
                    //updateJobStatus(item.applId,userStatus.acctId,13);
                  }}
                  className='rejectBtn_yy'>Cancel Interview</Button>
              </Typography>
            </Typography>

          ) : ('')

          }

        </Typography>





      </div>
    );
  });


  const AppliedJobMore = pendingJobPostList && pendingJobPostList.length > 0 && totalJobCount > jobsPerPage ? (

    <Button onClick={() => moreAppliedJobs(currentPage)} className='moreJobs'>{totalJobCount - jobsPerPage} more jobs</Button>
  ) : (
    ''
  );


  const NoAppliedJobPost = () => {
    return (
      <div>
        <Typography component='div' >
          <h5 className={classes.title + " " + classes.titleWeight}>
            You haven't any job offers.
          </h5>
        </Typography>
      </div>
    );
  };

  const LoadingJob = () => {
    return (

      <Typography component='div' className={classes.cardFrame}>
        <img
          src={LoadingImage}
          className={classes.backGroundImage}
          alt="Expired Session"
        />
      </Typography>

    );
  };

  console.log('viewJobPostItem');
  console.log(viewJobPostItem);

  return (
    <div>
      {pendingJobPostList && pendingJobPostList.length > 0 ? (
        <>
          {AppliedJob}
          {AppliedJobMore}
        </>
      ) : isLoading ? (
        <LoadingJob />
      ) : (
        <NoAppliedJobPost />
      )}


      {isLoadingJob && <Backdrop />}
      {jobViewModal && (
        <ResultJobModal
          open={jobViewModal}
          onClose={onClose}
          jobPostId={jobPostId}
          clientIp={clientIp}
        />
      )}
      {noRecordModal && (
        <NoRecordModal
          open={noRecordModal}
          onClose={noRecordOnClose}
          recordType={"Job Post"}
        />
      )}
      {withdrawModal && (
        <WithdrawlModal
          open={withdrawModal}
          onClose={withdrawOnClose}
          applId={applId}
        />
      )}

      {acceptModal && (
        <AcceptPendingModal
          open={acceptModal}
          onClose={acceptOnClose}
          applId={applId}
        />
      )}

      {rejectModal && (
        <RejectPendingModal
          open={rejectModal}
          onClose={rejectOnClose}
          applId={applId}
        />
      )}

      {declineModal && (
        <DeclinePendingModal
          open={declineModal}
          onClose={declineOnClose}
          applId={applId}
        />
      )}

      {createPost ? (
        <CreatePostModal
          isOpen={createPost}
          setPostsList={null}
          user={userStatus}
          oriVisibleOptions={null}
          oriSocialPostTypeOptions={null}
          oriCommentabilityOptions={null}
          statusChangeHandler={statusChangeHandler}
          handleCreatePostClick={null}
          question={null}
          options={null}
          pollTiming={null}
          removePoll={null}
          savePostData={null}
          selectPhotos={null}
          selectVideos={null}
          uploadedPhotos={[]}
          uploadedVideos={[]}
          removeAnUploadedPhoto={null}
          removeAnUploadedVideo={null}
          onPhotosListItemClick={null}
          onVideosListItemClick={null}
          onPhotosListItemDragStart={null}
          onPhotosListItemDrop={null}
          onVideosListItemDragStart={null}
          onVideosListItemDrop={null}
          isLoading={null}
          setError={null}
          editPost={null}
          sharePost={null}
          shareJob={selectedJobPost}
          groupId={0}
          postStatus={1}
        />
      ) : null}

      {/* Propose Alternative Timings */}
      {/* <Modal
        open={proposedModal}
        onClose={handleCloseProposedModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className='customModal calendarPopup'
      >
        <Box className='customBox calendarBox' sx={style}>
          <div className='CusBox'>
            <div className='calendarUpper'>
              <Typography className='manageTitle'>Interview Rescheduling</Typography>
              <a className='crossBtn' onClick={handleClose222}><i className="fas fa-xmark"></i></a>
            </div>
            <div className='emailNotifications'>
              <div className='emailNotificationsinner'>
                <div className='emailNotificationTitle'><b>The Employer has requested to reschedule your interview.</b></div>
                <p className='p'>You have 48 hours to respond to the invitation before the proposed interview times expire. </p>
                <div className='fbOuter'>
                  <img src={viewJobPostItem.profilePic ? viewJobPostItem.profilePic : defaultAvatar} alt='Image2' />
                  <Link to="#" onClick={() => getJobPostModel(viewJobPostItem.jobPostId)}  >

                    <div className='fbRight'>
                      <Link to={`/corporate-profile-page/${encryptData(userStatus.acctId)}/${encryptData(viewJobPostItem.bizId)}`} >  <div className='emailNotificationTitle'><b>{viewJobPostItem.industryName}</b></div></Link>
                      <Link to="#" onClick={() => getJobPostModel(viewJobPostItem.jobPostId)} >   <p className='purpleP'>{viewJobPostItem && viewJobPostItem.title} </p></Link>
                      <p className='purpleP'>{viewJobPostItem.jobType ? ('(' + viewJobPostItem.jobType + ')') : ('')}</p>
                    </div>
                  </Link>
                </div>
                <div className='emailNotifications'>
                  <div className='emailNotificationsinner'>
                    <p className='submitP proposeP'>Submit 3 alternative dates and/or timings of your availability</p>
                    <GridContainer justifyContent="center" spacing={6}>
                      <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography className='inputOuter1'>
                          <label className='labelCustom'>Timeslot 1 Date</label>
                          <input type='date' className='customInput custom1' value={dateSelection1} onChange={handleDateOneRange} min={minDate} />
                        </Typography>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography className='inputOuter1'>
                          <label className='labelCustom'>Timeslot 1 Time</label>
                          <input type='time' className='customInput custom1' value={timeSelection1} onChange={handleTimeOneRange} />
                        </Typography>
                      </GridItem>
                    </GridContainer>
                    <GridContainer justifyContent="center" spacing={6}>
                      <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography className='inputOuter1'>
                          <label className='labelCustom'>Timeslot 2 Date</label>
                          <input className='customInput custom1' type='date' value={dateSelection2} onChange={handleDateTwoRange} min={minDate} />
                        </Typography>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography className='inputOuter1'>
                          <label className='labelCustom'>Timeslot 2 Time</label>
                          <input className='customInput custom1' type='time' value={timeSelection2} onChange={handleTimeTwoRange} />
                        </Typography>
                      </GridItem>
                    </GridContainer>
                    <GridContainer justifyContent="center" spacing={6}>
                      <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography className='inputOuter1'>
                          <label className='labelCustom'>Timeslot 3 Date</label>
                          <input className='customInput custom1' type='date' value={dateSelection3} onChange={handleDateThreeRange} min={minDate} />
                        </Typography>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography className='inputOuter1'>
                          <label className='labelCustom'>Timeslot 3 Time</label>
                          <input className='customInput custom1' type='time' value={timeSelection3} onChange={handleTimeThreeRange} />
                        </Typography>
                      </GridItem>
                    </GridContainer>
                    <GridContainer justifyContent="center" spacing={6}>
                      <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography className='inputOuter1'>
                          <label className='labelCustom'>Reason for Rescheduling</label>
                          <input type="text" className='customInput custom1' value={reasonRescheduling} onChange={handleReasonRescheduling} />
                        </Typography>
                      </GridItem>
                    </GridContainer>
                    <GridContainer justifyContent="center" spacing={6}>
                      <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography className='rescheduleOuter submitOut'>
                          <Button type="button" className='ReschedulBtn' onClick={() => { submitAlternateTimingsCandidate(`${viewJobPostItem.eventId}`, 0) }}>Submit</Button>
                        </Typography>
                      </GridItem>
                    </GridContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal> */}

      <Modal
        open={proposedModal}
        onClose={handleCloseProposedModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className='customModal calendarPopup'
      >
        <Box className='customBox calendarBox rescheduleInterview' sx={style}>
          <div className='CusBox'>
            <div className='rescheduleInner'>
              <div className='rescheduleInnerLeft'>
                <i class="fas fa-circle-exclamation customIcon"></i>
                <div className='rescheduleTitle'>By rescheduling the interview,
                  you agree to these points:</div>
                <ul className='rescheduleUl'>
                  <li><span className='dotSpan'></span>
                    <span className='textSpan'>The new interview slot is only confirmed once it has been accepted by the Employer. </span>
                  </li>
                  <li><span className='dotSpan'></span>
                    <span className='textSpan'>You are allowed up to a maximum of 3 reschedules per job application. </span>
                  </li>
                  <li><span className='dotSpan'></span>
                    <span className='textSpan'>You are not allowed to reschedule lless than 1 hour before your interview time. </span>
                  </li>
                  <li><span className='dotSpan'></span>
                    <span className='textSpan'>If you are unable to make it for your interview less than 1 hour before the scheduled time, please contact the Employer directly.</span>
                  </li>
                </ul>
              </div>
              <div className='rescheduleInnerRight'>
                <div className='calendarUpper'>
                  <Typography className='manageTitle'>Reschedule Interview</Typography>
                  <a className='crossBtn' onClick={handleRescheduleInterviewModal}><i class="fas fa-xmark"></i></a>
                </div>
                <div className='emailNotifications'>
                  <p className='submitP'>Submit 3 alternative dates and/or timings of your availability</p>
                  <div className='emailNotificationsinner'>
                    <GridContainer justifyContent="center" spacing={6}>
                      <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography className='inputOuter1'>
                          <label className='labelCustom'>Timeslot 1 Date</label>
                          <input className='customInput custom1' type='date' value={dateSelection1} onChange={handleDateOneRange} min={minDate} />
                        </Typography>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography className='inputOuter1'>
                          <label className='labelCustom'>Timeslot 1 Time</label>
                          <input className='customInput custom1' type='time' value={timeSelection1} onChange={handleTimeOneRange} />
                        </Typography>
                      </GridItem>
                    </GridContainer>
                    <GridContainer justifyContent="center" spacing={6}>
                      <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography className='inputOuter1'>
                          <label className='labelCustom'>Timeslot 2 Date</label>
                          <input className='customInput custom1' type='date' value={dateSelection2} onChange={handleDateTwoRange} min={minDate} />
                        </Typography>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography className='inputOuter1'>
                          <label className='labelCustom'>Timeslot 2 Time</label>
                          <input className='customInput custom1' type='time' value={timeSelection2} onChange={handleTimeTwoRange} />
                        </Typography>
                      </GridItem>
                    </GridContainer>
                    <GridContainer justifyContent="center" spacing={6}>
                      <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography className='inputOuter1'>
                          <label className='labelCustom'>Timeslot 3 Date</label>
                          <input className='customInput custom1' type='date' value={dateSelection3} onChange={handleDateThreeRange} min={minDate} />
                        </Typography>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography className='inputOuter1'>
                          <label className='labelCustom'>Timeslot 3 Time</label>
                          <input className='customInput custom1' type='time' value={timeSelection3} onChange={handleTimeThreeRange} />
                        </Typography>
                      </GridItem>
                    </GridContainer>
                    <GridContainer justifyContent="center" spacing={6}>
                      <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography className='inputOuter1'>
                          <label className='labelCustom'>Reason for Rescheduling</label>
                          <input className='customInput custom1' type='text' value={reasonRescheduling} onChange={handleReasonRescheduling} />
                        </Typography>
                      </GridItem>
                    </GridContainer>
                    <GridContainer justifyContent="center" spacing={6}>
                      <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography className='rescheduleOuter'>
                          <Button type="button" className='ReschedulBtn' onClick={() => { submitAlternateTimingsCandidate(`${viewJobPostItem.eventId}`, 0) }}>Reschedule</Button>
                        </Typography>
                      </GridItem>
                    </GridContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      {/* Interview Reschedule Submitted */}
      <Modal
        open={rescheduleInterviewConfirmModal}
        onClose={handleRescheduleInterviewConfirmModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className='customBox submittedPopup' sx={style}>
          <div className='calendarUpper'>
            <i className="fa-regular fa-circle-check customCheckIcon"></i>
            <Typography className='manageTitle'>Your interview reschedule request has been submitted!</Typography>
            <a className='crossBtn' onClick={handleRescheduleInterviewConfirmModal}><i className="fas fa-xmark"></i></a>
          </div>
          <p className='submitp'><b>You will receive a notification once the Employer has confirmed the new interview timeslot.</b></p>
        </Box>
      </Modal>

      {/* Interview Accepting Modal */}
      <Modal
        open={upcomingInterviewAccepted}
        onClose={handleUpcomingInterviewAcceptedModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className='customModal calendarPopup'
      >
        <Box className='customBox calendarBox' sx={style}>
          <div className='CusBox'>
            <div className='calendarUpper'>

              {/*confirmTimeSlot && <p>{moment(confirmTimeSlot.datetime).format('dddd, MMM D')} • {moment(confirmTimeSlot.datetime).format('LT')} - {moment(confirmTimeSlot.endDate).format('LT')}</p> */}

              <a className='crossBtn' onClick={handleUpcomingInterviewAcceptedModal}><i class="fas fa-xmark"></i></a>
            </div>
            <div className='emailNotifications'>
              <div className='emailNotificationsinner'>
                <Typography className='manageTitle'>Interview with {currentEvent?.companyName}</Typography>
                <div className='fbOuter'>
                  <img className='zoomImg' src={Zoom} alt='Zoom' />
                  {/* <img src={Avtar} alt='Fb' style={{ height: '50px' }} /> */}
                  <div className='fbRight'>
                    <p><b>{viewJobPostItem?.interview?.eventList[0].typeName}</b></p>

                    {(viewJobPostItem?.interview && viewJobPostItem?.interview?.eventList.length > 0 && viewJobPostItem?.interview?.eventList[0].typeId === 1 && viewJobPostItem?.interview?.eventList[0].location) && <a href={viewJobPostItem?.interview?.eventList[0].location != "" ? viewJobPostItem?.interview?.eventList[0].location : '#'} target="_blank">{viewJobPostItem?.interview?.eventList[0].location}</a>}

                    {(viewJobPostItem?.interview && viewJobPostItem?.interview?.eventList.length > 0 && viewJobPostItem?.interview?.eventList[0].typeId === 2) && <p>Via Visit</p>}

                    {(viewJobPostItem?.interview && viewJobPostItem?.interview?.eventList.length > 0 && viewJobPostItem?.interview?.eventList[0].typeId === 3) && <p>Via Phone</p>}

                    {(viewJobPostItem?.interview && viewJobPostItem?.interview?.eventList.length > 0 && viewJobPostItem?.interview?.eventList[0].typeId == 1 && !viewJobPostItem?.interview?.eventList[0].location) && <p>No Link yet</p>}

                    {/* <Button type="button" className='ReschedulBtn decline joinwithzoom' onClick={zoomAPIIntegrationBy}>Join with Zoom</Button> */}
                  </div>
                </div>
                {/* <div className='fbOuter'>
                    <div className='fbRight'>
                      <Typography component='div' className='appliedJobSubtitle'>
                        {viewJobPostItem.interview && viewJobPostItem.interview.eventList.length > 0 && viewJobPostItem.interview.eventList[0].typeId === 1 ? (
                          <> <i className="fas fa-globe"></i><Button type="button" className='ReschedulBtn decline zoombtn' onClick={zoomAPIIntegration}>Join with Zoom</Button></>
                        ) : viewJobPostItem.interview && viewJobPostItem.interview.eventList.length > 0 && viewJobPostItem.interview.eventList[0].typeId === 2 ? (
                          <> <i className="fas fa-map"></i><b>Via Visit</b></>
                        ) : viewJobPostItem.interview && viewJobPostItem.interview.eventList.length > 0 && viewJobPostItem.interview.eventList[0].typeId === 3 ? (
                          <> <i className="fas fa-phone"></i><b>Via Phone</b></>
                        ) : ('')}

                      </Typography>
                    </div>
                  </div> */}

                <div className='fbOuter'>
                  <Link targer="_blank" to={`/corporate-profile-page/${encryptData(userStatus.acctId)}/${encryptData(viewJobPostItem.bizId)}`} >
                    <img src={viewJobPostItem.profilePic ? viewJobPostItem.profilePic : defaultAvatar} alt='IMages1' />
                  </Link>

                  <div className='fbRight'>
                    <div className='emailNotificationTitle'><b>{viewJobPostItem?.industryName}</b></div>
                    <p className='purpleP'>{viewJobPostItem?.title}</p>
                    <p className='purpleP'>{viewJobPostItem.jobType ? ('(' + viewJobPostItem.jobType + ')') : ('')}</p>
                  </div>

                </div>
              </div>
            </div>
            <GridContainer justifyContent="center" spacing={6}>
              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography className='rescheduleOuter declineOuter'>
                  <Button className='ReschedulBtn decline' onClick={(event) => {
                    setDateSlot(viewJobPostItem.interview.length > 0 && viewJobPostItem.interview.eventList[0].eventSchedule.eventScheduleList);
                    setCurrentEvent(viewJobPostItem.interview.length > 0 && viewJobPostItem.interview.eventList[0]);
                    setProposedModal(true);
                    //  handleOpen222();

                  }} >Reschedule</Button>
                  <Button className='ReschedulBtn' onClick={() => { setRejectModal(true); }}>Cancel Interview</Button>
                </Typography>
              </GridItem>
            </GridContainer>
          </div>
        </Box >
      </Modal >

    </div >
  );
}
