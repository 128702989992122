import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCandidateSelectJobPosts } from "configurations/redux/jobManagementSlice.js";
import { TimeFormat } from "configurations/configurations.js";
import { makeStyles } from "@material-ui/core/styles";

import classNames from "classnames";
import { Link } from "react-router-dom";

import Tooltip from "@material-ui/core/Tooltip";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Primary from "components/Typography/Primary.js";
import Danger from "components/Typography/Danger.js";

import jobStatusPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/JobStatusPage/jobStatusPageStyle.js";
import Pagination from "../../Component/Pagination.js";
import ResultJobModal from "../SearchJobPage/ResultJobModal.js";
import WithdrawlModal from "./WithdrawlModal.js";
import AcceptOfferModal from "./AcceptOfferModal";
import RejectOfferModal from "./RejectOfferModal";
import NoRecordModal from "../../Component/NoRecordModal.js";

import defaultAvatar from "assets/img/corporate_avatar.jpeg";
import EditIcon from "@material-ui/icons/Edit";
import LoadingImage from "assets/img/Loading-pana.png";

import { Divider } from "@material-ui/core";

import {
  getOfferJobPostList,
  getOfferJobPost,
  getSeekerJobPostList
} from "configurations/redux/appliedJobSlice";

import Backdrop from "../../Component/Backdrop.js";
import { resetNotificationItem } from "configurations/redux/eventNotificationSlice";
import IconLibrary from "views/Component/IconLibrary.js";
import { Box, Modal, Button, Typography, Popover } from '@material-ui/core';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const publicIp = require("public-ip");

const useStyles = makeStyles(jobStatusPageStyle);


export default function OfferedJobSection(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userStatus = useSelector((state) => state.auth.user);



  const offeredJobPostList = useSelector(
    (state) => state.appliedJob.jobPostList1
  );

  console.log("offeredJobPostList1");
  console.log(offeredJobPostList);
  console.log("offeredJobPostList1");

  const offeredJobPost = useSelector((state) => state.appliedJob.jobPost1);

  const isLoading = useSelector((state) => state.appliedJob.isLoading1);
  const isLoadingJob = useSelector((state) => state.appliedJob.isLoadingJob1);

  const loadingOfferJobList = useSelector(
    (state) => state.appliedJob.loadingJobList1
  );

  //const [currentPage, setCurrentPage] = React.useState(1);
  const [jobsPerPage, setJobsPerPage] = React.useState(2);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [clientIp, setClientIp] = React.useState();
  const [jobViewModal, setJobViewModal] = React.useState(false);
  const [withdrawModal, setWithdrawModal] = React.useState(false);
  const [acceptModal, setAcceptModal] = React.useState(false);
  const [rejectModal, setRejectModal] = React.useState(false);
  const [noRecordModal, setNoRecordModal] = React.useState(false);

  const [jobPostId, setJobPostId] = React.useState();
  const [applId, setApplId] = React.useState();
  const notificationJobPostItem = useSelector(
    (state) => state.eventNotification.item
  );

  /*Sourabh start */
  const getJobPostModel = async (jobPostSelId) => {


    const requestProfile = {
      acctId: userStatus.acctId,
      email: userStatus.email,
      accessToken: localStorage.getItem("token"),
      // acctId: auth.isPublic ? 0 : userStatus.acctId,
      // accessToken: auth.isPublic ? auth.publicToken : localStorage.getItem('token'),
      ipAddr: localStorage.getItem('ipAddr'), //await publicIp.v4(),
      jobPostId: jobPostSelId,
      item: null,
    };
    console.log(requestProfile);
    dispatch(getCandidateSelectJobPosts(requestProfile));
    setJobViewModal(true);
  };
  const onJobModelClose = () => {
    setJobViewModal(false);
  };

  /*Sourabh end */

  const onClose = () => {
    setJobPostId();
    setJobViewModal(false);
    setCurrentPage(currentPage);
    var indexOfFirstJob = currentPage * jobsPerPage - jobsPerPage;
    getJobPostList(indexOfFirstJob, false);
  };
  const noRecordOnClose = () => {
    setNoRecordModal(false);
  };

  const withdrawOnClose = () => {
    setApplId();
    setWithdrawModal(false);
  };

  const acceptOnClose = () => {
    setApplId();
    setAcceptModal(false);
  };

  const rejectOnClose = () => {
    setApplId();
    setRejectModal(false);
  };





  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = offeredJobPostList && offeredJobPostList.slice(indexOfFirstJob, indexOfLastJob);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    var indexOfFirstJob = pageNumber * jobsPerPage - jobsPerPage;
    getJobPostList(indexOfFirstJob, true);
  };

  const getJobPost = async (item) => {
    console.log(item);
    if (item && item.jobPostStatusId === 1) {
      const requestProfile = {
        acctId: userStatus.acctId,
        accessToken: localStorage.getItem("token"),
        ipAddr: localStorage.getItem("ipAddr"), //await publicIp.v4(),
        jobPostId: item.jobPostId,
        item: item,
      };
      console.log(requestProfile);
      //dispatch(getCandidateSelectJobPosts(requestProfile));
      dispatch(getOfferJobPostList(requestProfile));
      setJobViewModal(true);
    } else {
      setNoRecordModal(true);
    }
  };

  const getJobPostList = async (curPageSize, scroll) => {
    const requestProfile = {
      acctId: userStatus.acctId,
      email: userStatus.email,
      accessToken: localStorage.getItem("token"),
      lastRecordSize: 0,
      requestRecordSize: curPageSize,//jobsPerPage,

      statusIds: '15'
    };
    console.log(' 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 16 | 17 | 18');
    console.log('getSeekerJobPostList');
    console.log(requestProfile);
    dispatch(getOfferJobPostList(requestProfile));

    if (scroll) window.scrollTo(0, 0);
  };



  /*   React.useEffect(() => {
      if (notificationJobPostItem > 0) {
        console.log(notificationJobPostItem);
        const requestProfile = {
          acctId: userStatus.acctId,
          email: userStatus.email,
          accessToken: localStorage.getItem("token"),
          applId: notificationJobPostItem,
        };
        dispatch(getOfferJobPost(requestProfile));
      }
    }, [notificationJobPostItem]);
   */
  /*   React.useEffect(() => {
      if (notificationJobPostItem > 0) {
        if (offeredJobPost) {
          getJobPost(offeredJobPost[0]);
          dispatch(resetNotificationItem());
        } else {
          setNoRecordModal(true);
          dispatch(resetNotificationItem());
        }
        pageResize();
      }
      pageResize();
    }, [offeredJobPost]); */

  React.useEffect(() => {
    //pageResize();
    getJobPostList(jobsPerPage);
  }, []);

  console.log("offeredJobPostList-----");
  console.log(offeredJobPostList);
  console.log("offeredJobPostList--------");
  const pageResize = () => {
    var timerApplied = setInterval(() => {
      if (document.readyState === "complete") {
        var pageHeader = document.getElementById("pageHeader");
        var appliedCard = document.getElementById("appliedCard");
        if (pageHeader && appliedCard) {
          clearInterval(timerApplied);
          pageHeader.style.minHeight = "468px";
          if (appliedCard.clientHeight <= 450) {
            pageHeader.style.height = "468px";
          } else {
            pageHeader.style.minHeight = "1px";
            pageHeader.style.height = "auto";
          }
        } else {
          setTimeout(() => {
            clearInterval(timerApplied);
          }, 3000);
        }
      }
    }, 50);
    return () => clearInterval(timerApplied);
  };


  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [open1, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let totalJobCount = props.appliedJobCounter;


  const moreAppliedJobs = (curcount) => {

    // let newsavesize = pasetInt(currentPage) + pasetInt(5);
    let newsavesize = jobsPerPage + 2;
    console.log('------newsavesize---------' + newsavesize);


    setJobsPerPage(newsavesize);
    getJobPostList(newsavesize);

    //dispatch(getAppliedJobPostList(defaultJobRequest));

  };
  //props.handleOfferJobsCount(offeredJobPostList);  


  console.log('Job Offer NEw1');


  const AppliedJob = offeredJobPostList && offeredJobPostList.map((item, index) => {

    /*  console.log("applStatusId");
     console.log(item.applStatusId);
     console.log(item.statusIds);
     console.log("applStatusId"); */

    return (
      <div key={item.applId} >
        <Typography component='div' className='jobInvitation grey'>

          <Link to="#" onClick={() => getJobPostModel(item.jobPostId)} >
            <Typography component='div' className='inviteJob'>
              <Typography component='div' className='inviteJobLeft'>
                <img width="40px"
                  src={item.profilePic ? item.profilePic : defaultAvatar}
                  alt="..."
                />
              </Typography>
              <Typography component='div' className='inviteJobRight'>
                <span className='headHunted jobOffered'>Job Offered</span>
              </Typography>
            </Typography>
            <Typography component='div' className='AppliedJobTitle designTitle'>{item.title}</Typography>
            <Typography component='div' className='appliedJobSubtitle'><b>{item.companyName}</b> </Typography>
            <Typography component='div' className='contractDuration'>
              <p className='contractduractionTitle'>Contract Duration</p>
              <Typography component='div' className='appliedJobSubtitle'><b>{item.servicePeriod} Weeks</b></Typography>
            </Typography>
            <Typography component='div' className='contractDuration'>
              <p className='contractduractionTitle'>Offer Salary</p>
              <Typography component='div' className='appliedJobSubtitle'><b>{item.currencyCode} {((item.offerJob) && (item.offerJob.offerSalary)) ? (item.offerJob.offerSalary) : '0'}  / Month</b></Typography>
            </Typography>


            <Typography component='div' className='contractDuration'>
              <p className='contractduractionTitle'>Job Type</p>
              <Typography component='div' className='appliedJobSubtitle'><b>
                {item.jobPostType}{" "}
                {item.jobPostTypeId === 2
                  ? " - " + item.jobTypeId
                  : null}</b>
              </Typography>
            </Typography>
          </Link>

          <Typography component='div' className='dayOuter btnOuter'>
            {(item.applStatusId == 1) ?
              (<Typography component='div' className='inviteJobRight'>
                <span className='headHunted jobOffered'>Offer Accepted</span>
              </Typography>)
              : ''}

            {(item.applStatusId == 5) ? (
              <Typography component='div' className='inviteJobRight'>
                <span className='headHunted pending'>Offer Rejected</span>
              </Typography>) : ''}

            {(item.applStatusId === 5 ||
              item.applStatusId === 1) ? (<></>) : (
              <>
                <Typography component='div' className='dayLeft'>
                  <Button
                    className='applyBtn'
                    onClick={(event) => {
                      event.stopPropagation();
                      setApplId(item.applId);
                      setAcceptModal(true);
                    }}
                  >Accept</Button>
                </Typography>
                <Typography component='div' className='dayRight'>
                  <Button
                    className='rejectBtngrey'
                    onClick={(event) => {
                      event.stopPropagation();
                      setApplId(item.applId);
                      setRejectModal(true);
                    }}
                  >Reject</Button>
                </Typography>
              </>
            )}

          </Typography>
        </Typography>
        {jobViewModal ? <ResultJobModal open={jobViewModal} onClose={onJobModelClose} /> : null}
      </div>
    );
  });

  const AppliedJobMore = offeredJobPostList && offeredJobPostList.length > 0 && totalJobCount > jobsPerPage ? (

    <Button onClick={() => moreAppliedJobs(currentPage)} className='moreJobs'>{totalJobCount - jobsPerPage} more jobs</Button>
  ) : (
    ''
  );


  const NoAppliedJobPost = () => {
    return (
      <div>
        <Typography component='div' >
          <h5 className={classes.title + " " + classes.titleWeight}>
            You haven't any job offers.
          </h5>
        </Typography>
      </div>
    );
  };



  const LoadingJob = () => {
    return (

      <Typography component='div' className={classes.cardFrame}>
        <img
          src={LoadingImage}
          className={classes.backGroundImage}
          alt="Expired Session"
        />
      </Typography>

    );
  };




  return (
    <div>
      {offeredJobPostList && offeredJobPostList.length > 0 ? (
        <>
          {AppliedJob}
          {AppliedJobMore}
        </>
      ) : isLoading ? (
        <LoadingJob />
      ) : (
        <NoAppliedJobPost />
      )}

      {isLoadingJob && <Backdrop />}
      {jobViewModal && (
        <ResultJobModal
          open={jobViewModal}
          onClose={onClose}
          jobPostId={jobPostId}
          clientIp={clientIp}
        />
      )}
      {noRecordModal && (
        <NoRecordModal
          open={noRecordModal}
          onClose={noRecordOnClose}
          recordType={"Job Post"}
        />
      )}
      {withdrawModal && (
        <WithdrawlModal
          open={withdrawModal}
          onClose={withdrawOnClose}
          applId={applId}
        />
      )}

      {acceptModal && (
        <AcceptOfferModal
          open={acceptModal}
          onClose={acceptOnClose}
          applId={applId}
        />
      )}

      {rejectModal && (
        <RejectOfferModal
          open={rejectModal}
          onClose={rejectOnClose}
          applId={applId}
        />
      )}

    </div>
  );
}
