import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
	departmentViewRoadmap,
	viewRoadmapRoleByRoleId,
} from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Department_Manufacturing from "assets/img/Career_Roadmap/Aerospace_Engineering/Department_Manufacturing_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentManufacturing() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Manufacturing;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "m",
				shape: "poly",
				coords: [83,64,74,67,69,70,65,74,64,81,64,784,66,791,70,796,76,801,82,802,185,802,192,798,196,794,198,790,202,783,201,82,198,75,194,69,189,66,183,64,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "253",
				shape: "poly",
				coords: [370,136,366,138,362,141,359,146,358,151,358,204,360,208,362,211,366,213,372,215,476,214,482,211,484,208,487,204,488,199,488,152,487,144,483,140,480,138,473,136,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "254",
				shape: "poly",
				coords: [528,136,524,139,520,142,518,148,516,154,517,202,519,206,521,211,525,214,532,216,632,214,639,211,643,209,646,204,648,199,647,149,646,144,643,140,637,137,634,135,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "255",
				shape: "poly",
				coords: [696,136,690,139,686,141,682,147,682,152,682,203,684,207,687,210,690,213,696,215,798,216,804,213,808,209,811,206,812,199,812,149,809,142,807,138,802,136,797,136,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "251",
				shape: "poly",
				coords: [888,136,882,138,878,141,875,144,874,150,873,200,876,206,878,210,881,214,887,215,991,215,995,212,999,210,1002,205,1003,202,1004,153,1002,144,999,140,994,137,990,136,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "252",
				shape: "poly",
				coords: [1083,136,1078,138,1075,141,1073,144,1072,150,1072,200,1073,205,1076,210,1079,213,1086,215,1189,215,1195,211,1198,208,1201,204,1202,199,1202,150,1200,143,1196,139,1191,137,1187,135,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "237",
				shape: "poly",
				coords: [228,274,220,276,216,280,212,285,211,292,212,338,213,345,216,348,221,352,228,354,326,354,334,351,338,348,340,342,342,338,342,292,341,284,338,279,332,275,325,274,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "249",
				shape: "poly",
				coords: [892,265,882,267,878,271,874,276,873,282,873,329,874,336,878,340,884,344,890,345,990,344,996,342,1000,339,1003,335,1004,330,1004,285,1002,273,998,269,993,266,988,265,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "250",
				shape: "poly",
				coords: [1085,265,1080,267,1076,271,1073,275,1072,281,1072,332,1074,338,1077,341,1082,344,1086,345,1187,344,1194,342,1197,339,1200,337,1202,330,1202,280,1200,272,1196,268,1190,266,1184,265,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "248",
				shape: "poly",
				coords: [1338,259,1334,261,1330,264,1328,268,1327,273,1328,327,1330,332,1332,336,1337,338,1341,339,1446,338,1450,336,1454,332,1456,327,1457,322,1457,275,1455,266,1451,263,1446,259,1440,259,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "238",
				shape: "poly",
				coords: [373,396,367,398,362,401,360,405,358,410,358,461,361,467,364,470,368,474,373,475,476,474,482,472,485,470,488,465,489,461,489,410,488,403,484,399,478,396,474,396,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "239",
				shape: "poly",
				coords: [528,394,524,396,521,399,518,404,517,408,517,460,519,466,522,469,527,472,532,474,631,473,638,471,643,468,645,464,647,460,648,410,647,404,644,398,638,395,632,393,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "240",
				shape: "poly",
				coords: [696,396,691,398,686,400,683,404,682,410,682,460,684,467,686,470,690,473,696,474,798,475,803,473,808,469,810,466,812,462,812,411,811,405,809,401,804,398,799,396,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "246",
				shape: "poly",
				coords: [888,398,882,400,878,402,875,405,873,414,873,463,876,469,878,473,882,476,889,477,985,478,992,476,997,473,1000,470,1003,465,1003,415,1003,408,1001,402,995,399,989,398,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "247",
				shape: "poly",
				coords: [1083,398,1078,401,1075,403,1073,408,1071,414,1072,464,1073,469,1076,472,1081,476,1088,477,1184,477,1193,475,1198,470,1200,468,1202,463,1202,416,1201,407,1198,402,1192,399,1186,397,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "244",
				shape: "poly",
				coords: [1468,386,1463,389,1458,393,1456,398,1456,404,1457,466,1459,472,1463,476,1468,478,1474,480,1622,478,1629,476,1632,472,1634,468,1635,463,1636,402,1634,395,1630,390,1626,387,1621,385,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "245",
				shape: "poly",
				coords: [1674,386,1669,388,1665,391,1663,396,1661,402,1662,467,1664,471,1666,475,1670,478,1678,480,1826,479,1833,476,1836,472,1838,469,1840,464,1840,400,1838,394,1834,390,1827,386,1820,385,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "241",
				shape: "poly",
				coords: [888,530,881,533,878,536,875,541,874,546,874,595,875,601,878,605,882,609,889,610,987,610,996,607,1000,602,1003,598,1004,593,1004,549,1003,540,1000,535,994,532,989,530,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "242",
				shape: "poly",
				coords: [1090,531,1081,533,1076,535,1073,540,1072,546,1073,594,1074,601,1077,605,1082,609,1087,610,1190,609,1195,607,1200,604,1202,598,1203,594,1203,548,1202,540,1198,534,1192,532,1188,530,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "243",
				shape: "poly",
				coords: [1342,530,1334,533,1330,536,1328,541,1326,546,1327,599,1329,603,1332,606,1336,608,1343,610,1444,610,1450,608,1453,605,1456,601,1457,595,1457,547,1455,539,1450,534,1446,532,1440,530,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "m") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo);
			dispatch(viewRoadmapRoleByRoleId(requestInfo));

			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
