import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import history from '../../history.js';
import axios from 'axios';
import { instanceUrl, header, emptyCache, TimeFormatFirstDateOfMonth } from 'configurations/configurations.js';

const manageConnectionSlice = createSlice({
  name: 'manageConnection',
  initialState: {
    isUpdated: true,
    isLoading: false,
    isStatusUpdated: false,
    blockCount: null,
    connectionCount: null,
    pendingRequestCount: null,
    pendingApprovalCount: null,
    connectedCount: null,
    groupCount: null,
    pendingApprovalGroupCount: null,
    pendingRequestGroupCount: null,
    followProfileCount: null,
    followHashtagCount: null,
    followPageCount: null,
    connectionRecords: [],
    pendingRequestRecords: [],
    pendingApprovalRecords: [],
    blockRecords: [],
    groupRecords: [],
    pendingApprovalGroupRecords: [],
    pendingRequestGroupRecords: [],
    hashtagRecords: [],
    followRecords: [],
  },
  reducers: {
    GET_COUNT_SUCCESS: (state, action) => {
      state.isUpdated = true;
      state.isLoading = false;
      state.isStatusUpdated = false;
      localStorage.setItem('token', action.payload.token);
      state.blockCount = action.payload.blockCount;
      state.connectionCount = action.payload.connectionCount;
      state.pendingApprovalCount = action.payload.pendingApprovalCount;
      state.pendingRequestCount = action.payload.pendingRequestCount;
      state.connectedCount = action.payload.connectedCount;
      state.groupCount = action.payload.groupCount;
      state.pendingRequestGroupCount = action.payload.pendingRequestGroupCount;
      state.pendingApprovalGroupCount = action.payload.pendingApprovalGroupCount;
      state.followHashtagCount = action.payload.followHashtagCount;
      state.followProfileCount = action.payload.followProfileCount;
    },
    MANAGE_CONNECTION_FAIL: state => {
      state.isUpdated = false;
    },
    CONNECTION_PAGE_LOADING: (state, action) => {
      state.isLoading = true;
    },
    GET_CONNECTION_LIST_SUCCESS: (state, action) => {
      state.isUpdated = true;
      state.isLoading = false;
      state.isStatusUpdated = false;

      localStorage.setItem('token', action.payload.token);
      state.connectionRecords = action.payload.connectionRecords;
    },
    GET_PENDING_REQUEST_LIST_SUCCESS: (state, action) => {
      state.isUpdated = true;
      state.isLoading = false;
      state.isStatusUpdated = false;

      localStorage.setItem('token', action.payload.token);
      state.pendingRequestRecords = action.payload.pendingRequestRecords;
    },
    GET_PENDING_APPROVAL_LIST_SUCCESS: (state, action) => {
      state.isUpdated = true;
      state.isLoading = false;
      state.isStatusUpdated = false;

      localStorage.setItem('token', action.payload.token);
      state.pendingApprovalRecords = action.payload.pendingApprovalRecords;
    },
    GET_BLOCK_LIST_SUCCESS: (state, action) => {
      state.isUpdated = true;
      state.isLoading = false;
      state.isStatusUpdated = false;
      localStorage.setItem('token', action.payload.token);
      state.blockRecords = action.payload.blockRecords;
    },
    GET_GROUP_LIST_SUCCESS: (state, action) => {
      state.isUpdated = true;
      state.isLoading = false;
      state.isStatusUpdated = false;
      localStorage.setItem('token', action.payload.token);
      state.groupRecords = action.payload.groupRecords;
    },
    GET_HASHTAG_LIST_SUCCESS: (state, action) => {
      state.isUpdated = true;
      state.isLoading = false;
      state.isStatusUpdated = false;
      localStorage.setItem('token', action.payload.token);
      state.hashtagRecords = action.payload.hashtagRecords;
    },
    GET_FOLLOW_PROFILE_LIST_SUCCESS: (state, action) => {
      state.isUpdated = true;
      state.isLoading = false;
      state.isStatusUpdated = false;
      localStorage.setItem('token', action.payload.token);
      state.followRecords = action.payload.followRecords;
    },
    GET_PENDING_REQUEST_GROUP_LIST_SUCCESS: (state, action) => {
      state.isUpdated = true;
      state.isLoading = false;
      state.isStatusUpdated = false;
      localStorage.setItem('token', action.payload.token);
      state.pendingRequestGroupRecords = action.payload.groupRecords;
    },
    GET_PENDING_APPROVAL_GROUP_LIST_SUCCESS: (state, action) => {
      state.isUpdated = true;
      state.isLoading = false;
      state.isStatusUpdated = false;
      localStorage.setItem('token', action.payload.token);
      state.pendingApprovalGroupRecords = action.payload.groupRecords;
    },
    UPDATE_STATUS_SUCCESS: (state, action) => {
      state.isUpdated = true;
      state.isLoading = false;
      state.isStatusUpdated = true;
      localStorage.setItem('token', action.payload.token);
    },
    CONNECTION_PAGE_LOADING_FINISH: state => {
      state.isLoading = false;
    },
    GROUP_LEAVE_UPDATE_STATUS_SUCCESS: (state, action) => {
      state.isUpdated = true;
      state.isLoading = false;
      state.isStatusUpdated = true;
      state.groupRecords = state.groupRecords.filter(item => {
        return item.userGroupId !== action.payload.groupUserId;
      });
      localStorage.setItem('token', action.payload.token);
    },
    GROUP_APPROVE_UPDATE_STATUS_SUCCESS: (state, action) => {
      state.isUpdated = true;
      state.isLoading = false;
      state.isStatusUpdated = true;
      state.pendingApprovalGroupRecords = state.pendingApprovalGroupRecords.filter(item => {
        return item.userGroupId !== action.payload.groupUserId;
      });
      localStorage.setItem('token', action.payload.token);
    },
    GROUP_REJECT_UPDATE_STATUS_SUCCESS: (state, action) => {
      state.isUpdated = true;
      state.isLoading = false;
      state.isStatusUpdated = true;
      state.pendingApprovalGroupRecords = state.pendingApprovalGroupRecords.filter(item => {
        return item.userGroupId !== action.payload.groupUserId;
      });
      localStorage.setItem('token', action.payload.token);
    },
    GROUP_CANCEL_UPDATE_STATUS_SUCCESS: (state, action) => {
      state.isUpdated = true;
      state.isLoading = false;
      state.isStatusUpdated = true;
      state.pendingRequestGroupRecords = state.pendingRequestGroupRecords.filter(item => {
        return item.userGroupId !== action.payload.groupUserId;
      });
      localStorage.setItem('token', action.payload.token);
    },
    CONNECTION_REMOVE_UPDATE_STATUS_SUCCESS: (state, action) => {
      state.isUpdated = true;
      state.isLoading = false;
      state.isStatusUpdated = true;
      state.connectionRecords = state.connectionRecords.filter(item => {
        return item.connId !== action.payload.connId;
      });
      localStorage.setItem('token', action.payload.token);
    },
    CONNECTION_APPROVE_UPDATE_STATUS_SUCCESS: (state, action) => {
      state.isUpdated = true;
      state.isLoading = false;
      state.isStatusUpdated = true;
      state.pendingApprovalRecords = state.pendingApprovalRecords.filter(item => {
        return item.connId !== action.payload.connId;
      });
      localStorage.setItem('token', action.payload.token);
    },
    CONNECTION_REJECT_UPDATE_STATUS_SUCCESS: (state, action) => {
      state.isUpdated = true;
      state.isLoading = false;
      state.isStatusUpdated = true;
      state.pendingApprovalRecords = state.pendingApprovalRecords.filter(item => {
        return item.connId !== action.payload.connId;
      });
      localStorage.setItem('token', action.payload.token);
    },
    CONNECTION_CANCEL_UPDATE_STATUS_SUCCESS: (state, action) => {
      state.isUpdated = true;
      state.isLoading = false;
      state.isStatusUpdated = true;
      state.pendingRequestRecords = state.pendingRequestRecords.filter(item => {
        return item.connId !== action.payload.connId;
      });
      localStorage.setItem('token', action.payload.token);
    },
    CONNECTION_UNBLOCK_UPDATE_STATUS_SUCCESS: (state, action) => {
      state.isUpdated = true;
      state.isLoading = false;
      state.isStatusUpdated = true;
      state.blockRecords = state.blockRecords.filter(item => {
        return item.blockId !== action.payload.blockId;
      });
      localStorage.setItem('token', action.payload.token);
    },
    CONNECTION_UNBLOCK_UPDATE_STATUS_SUCCESS: (state, action) => {
      state.isUpdated = true;
      state.isLoading = false;
      state.isStatusUpdated = true;
      state.blockRecords = state.blockRecords.filter(item => {
        return item.blockId !== action.payload.blockId;
      });
      localStorage.setItem('token', action.payload.token);
    },
  },
});

const {
  GET_COUNT_SUCCESS,
  CONNECTION_PAGE_LOADING,
  GET_PENDING_APPROVAL_LIST_SUCCESS,
  GET_PENDING_REQUEST_LIST_SUCCESS,
  GET_CONNECTION_LIST_SUCCESS,
  UPDATE_STATUS_SUCCESS,
  MANAGE_CONNECTION_FAIL,
  GET_BLOCK_LIST_SUCCESS,
  GET_GROUP_LIST_SUCCESS,
  GET_FOLLOW_PROFILE_LIST_SUCCESS,
  GET_HASHTAG_LIST_SUCCESS,
  GET_PENDING_REQUEST_GROUP_LIST_SUCCESS,
  GET_PENDING_APPROVAL_GROUP_LIST_SUCCESS,
  CONNECTION_PAGE_LOADING_FINISH,
  GROUP_LEAVE_UPDATE_STATUS_SUCCESS,
  GROUP_APPROVE_UPDATE_STATUS_SUCCESS,
  GROUP_REJECT_UPDATE_STATUS_SUCCESS,
  GROUP_CANCEL_UPDATE_STATUS_SUCCESS,
  CONNECTION_REMOVE_UPDATE_STATUS_SUCCESS,
  CONNECTION_APPROVE_UPDATE_STATUS_SUCCESS,
  CONNECTION_REJECT_UPDATE_STATUS_SUCCESS,
  CONNECTION_CANCEL_UPDATE_STATUS_SUCCESS,
  CONNECTION_UNBLOCK_UPDATE_STATUS_SUCCESS,
} = manageConnectionSlice.actions;

export const getNetworkCount = ({ acctId, email, accessToken }) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
  };
  console.log(body);

  /* 	dispatch(CONNECTION_PAGE_LOADING()); */

  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/um/get-my-network-count`,
    params: body,
  })
    .then(res => {
      console.log(res);
      if (res.status === 201) {
        console.log(res);

        const response = {
          blockCount: res.data.payload.networkCount[0].blockCount,
          connectionCount: res.data.payload.networkCount[0].connectionCount,
          pendingApprovalCount: res.data.payload.networkCount[0].pendingApprovalCount,
          pendingRequestCount: res.data.payload.networkCount[0].pendingRequestCount,
          connectedCount: res.data.payload.networkCount[0].connectedCount,
          groupCount: res.data.payload.networkCount[0].groupCount,
          pendingRequestGroupCount: res.data.payload.networkCount[0].pendingRequestGroupCount,
          pendingApprovalGroupCount: res.data.payload.networkCount[0].pendingApprovalGroupCount,
          followProfileCount: res.data.payload.networkCount[0].followProfileCount,
          followHashtagCount: res.data.payload.networkCount[0].followHashtagCount,
          followPageCount: res.data.payload.networkCount[0].followPageCount,
          token: res.data.token,
        };
        console.log(response);

        dispatch(GET_COUNT_SUCCESS(response));
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};
export const getConnectionListByStatusId = ({
  acctId,
  email,
  accessToken,
  lastRecordSize,
  requestRecordSize,
  statusId,
  keyword,
  sortBy,
}) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    lastRecordSize,
    requestRecordSize,
    statusId,
    keyword,
    sortBy,
  };
  dispatch(CONNECTION_PAGE_LOADING());

  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/um/get-connection-profile-by-acct-id`,
    params: body,
  })
    .then(res => {
      console.log(res);
      if (res.status === 201) {
        if (statusId === 1) {
          const response = {
            connectionRecords: res.data.payload.connectionList,
            token: res.data.token,
          };
          dispatch(GET_CONNECTION_LIST_SUCCESS(response));
        } else {
          const response = {
            pendingRequestRecords: res.data.payload.connectionList,
            token: res.data.token,
          };
          dispatch(GET_PENDING_REQUEST_LIST_SUCCESS(response));
        }
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const getApprovalList = ({
  acctId,
  email,
  accessToken,
  lastRecordSize,
  requestRecordSize,
}) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    lastRecordSize,
    requestRecordSize,
  };
  dispatch(CONNECTION_PAGE_LOADING());

  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/um/get-connection-request-by-receiver-acct-id`,
    params: body,
  })
    .then(res => {
      console.log(res);
      if (res.status === 201) {
        const response = {
          pendingApprovalRecords: res.data.payload.connectionList,
          token: res.data.token,
        };
        dispatch(GET_PENDING_APPROVAL_LIST_SUCCESS(response));
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};
export const getBlockList = ({ acctId, email, accessToken, lastRecordSize, requestRecordSize }) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    lastRecordSize,
    requestRecordSize,
  };
  dispatch(CONNECTION_PAGE_LOADING());

  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/um/get-block-profile-by-acct-id`,
    params: body,
  })
    .then(res => {
      console.log(res);
      if (res.status === 201) {
        const response = {
          blockRecords: res.data.payload.blockProfileList,
          token: res.data.token,
        };
        dispatch(GET_BLOCK_LIST_SUCCESS(response));
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};
export const getGroupList = ({
  acctId,
  email,
  accessToken,
  lastRecordSize,
  requestRecordSize,
  keyword,
  statusId,
}) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    lastRecordSize,
    requestRecordSize,
    keyword,
    statusId,
  };
  dispatch(CONNECTION_PAGE_LOADING());

  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/sg/get-social-group-by-acct-id`,
    params: body,
  })
    .then(res => {
      console.log(res);
      if (res.status === 201) {
        const response = {
          groupRecords: res.data.payload.groupList,
          token: res.data.token,
        };
        if (statusId === 1) {
          dispatch(GET_GROUP_LIST_SUCCESS(response));
        } else if (statusId === 2) {
          dispatch(GET_PENDING_APPROVAL_GROUP_LIST_SUCCESS(response));
        } else {
          dispatch(GET_PENDING_REQUEST_GROUP_LIST_SUCCESS(response));
        }
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};
export const getHashtagList = ({
  acctId,
  email,
  accessToken,
  lastRecordSize,
  requestRecordSize,
  keyword,
}) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    lastRecordSize,
    requestRecordSize,
    keyword,
  };
  dispatch(CONNECTION_PAGE_LOADING());

  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/sf/get-follow-hashtag-by-acct-id`,
    params: body,
  })
    .then(res => {
      console.log(res);
      if (res.status === 201) {
        const response = {
          hashtagRecords: res.data.payload.followHashtagList,
          token: res.data.token,
        };
        dispatch(GET_HASHTAG_LIST_SUCCESS(response));
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};
export const getFollowProfileList = ({
  acctId,
  email,
  accessToken,
  lastRecordSize,
  requestRecordSize,
  keyword,
}) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    lastRecordSize,
    requestRecordSize,
    keyword,
  };
  dispatch(CONNECTION_PAGE_LOADING());

  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/um/get-follow-profile-by-acct-id`,
    params: body,
  })
    .then(res => {
      console.log(res);
      if (res.status === 201) {
        const response = {
          followRecords: res.data.payload.followProfileList,
          token: res.data.token,
        };
        dispatch(GET_FOLLOW_PROFILE_LIST_SUCCESS(response));
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};
export const updateConnectionStatus = ({
  acctId,
  email,
  accessToken,
  connId,
  connStatusId,
  actionStatus,
}) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    connId,
    connStatusId,
    actionStatus,
  };
  dispatch(CONNECTION_PAGE_LOADING());

  await axios({
    method: 'PUT',
    headers: header,
    url: `${instanceUrl}/api/um/update-connection-profile-status`,
    data: body,
  })
    .then(res => {
      console.log(res);
      if (res.status === 201) {
        const response = {
          connId,
          actionStatus,
          token: res.data.token,
        };
        if (actionStatus === 'remove_connection') {
          dispatch(CONNECTION_REMOVE_UPDATE_STATUS_SUCCESS(response));
        } else if (actionStatus === 'reject_connection') {
          dispatch(CONNECTION_REJECT_UPDATE_STATUS_SUCCESS(response));
        } else {
          dispatch(CONNECTION_APPROVE_UPDATE_STATUS_SUCCESS(response));
        }
        /* dispatch(UPDATE_STATUS_SUCCESS(response)); */
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};
export const updateGroupStatus = ({
  acctId,
  email,
  accessToken,
  groupUserId,
  userStatusId,
  actionStatus,
}) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    groupUserId,
    userStatusId,
    actionStatus,
  };
  dispatch(CONNECTION_PAGE_LOADING());

  await axios({
    method: 'PUT',
    headers: header,
    url: `${instanceUrl}/api/sg/update-social-group-user-status`,
    data: body,
  })
    .then(res => {
      console.log(res);
      if (res.status === 201) {
        const response = {
          token: res.data.token,
          groupUserId,
          actionStatus,
        };
        if (actionStatus === 'leave_group') {
          dispatch(GROUP_LEAVE_UPDATE_STATUS_SUCCESS(response));
        } else if (actionStatus === 'approve_group') {
          dispatch(GROUP_APPROVE_UPDATE_STATUS_SUCCESS(response));
        } else if (actionStatus === 'reject_group') {
          dispatch(GROUP_REJECT_UPDATE_STATUS_SUCCESS(response));
        } else {
          dispatch(GROUP_CANCEL_UPDATE_STATUS_SUCCESS(response));
        }
        /*       dispatch(UPDATE_STATUS_SUCCESS(response)); */
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};
export const deleteGroupUser = ({
  acctId,
  email,
  accessToken,
  groupUserId,
  groupId,
  actionStatus,
}) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    groupUserId,
    groupId,
    actionStatus,
  };
  dispatch(CONNECTION_PAGE_LOADING());
  await axios({
    method: 'PUT',
    headers: header,
    url: `${instanceUrl}/api/sg/delete-social-group-user`,
    data: body,
  })
    .then(res => {
      console.log(res);
      if (res.status === 201) {
        const response = {
          token: res.data.token,
          groupUserId,
          actionStatus,
        };
        if (actionStatus === 'leave_group') {
          dispatch(GROUP_LEAVE_UPDATE_STATUS_SUCCESS(response));
        } else if (actionStatus === 'approve_group') {
          dispatch(GROUP_APPROVE_UPDATE_STATUS_SUCCESS(response));
        } else if (actionStatus === 'reject_group') {
          dispatch(GROUP_REJECT_UPDATE_STATUS_SUCCESS(response));
        } else {
          dispatch(GROUP_CANCEL_UPDATE_STATUS_SUCCESS(response));
        }
        /* dispatch(UPDATE_STATUS_SUCCESS(response)); */
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};
export const deleteFollowHashtag = ({ acctId, email, accessToken, followId }) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    followId,
  };
  dispatch(CONNECTION_PAGE_LOADING());

  await axios({
    method: 'PUT',
    headers: header,
    url: `${instanceUrl}/api/sf/delete-follow-hashtag`,
    data: body,
  })
    .then(res => {
      console.log(res);
      if (res.status === 201) {
        const response = {
          token: res.data.token,
        };
        dispatch(UPDATE_STATUS_SUCCESS(response));
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};
export const deleteFollowProfile = ({ acctId, email, accessToken, followId }) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    followId,
  };
  dispatch(CONNECTION_PAGE_LOADING());

  await axios({
    method: 'PUT',
    headers: header,
    url: `${instanceUrl}/api/um/delete-follow-profile`,
    data: body,
  })
    .then(res => {
      console.log(res);
      if (res.status === 201) {
        const response = {
          token: res.data.token,
        };
        dispatch(UPDATE_STATUS_SUCCESS(response));
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};
export const deleteBlockProfile = ({ acctId, email, accessToken, blockId }) => async dispatch => {
  const body = {
    acctId,
    email,
    accessToken,
    blockId,
  };
  dispatch(CONNECTION_PAGE_LOADING());

  await axios({
    method: 'PUT',
    headers: header,
    url: `${instanceUrl}/api/um/delete-block-profile`,
    data: body,
  })
    .then(res => {
      console.log(res);
      if (res.status === 201) {
        const response = {
          blockId,
          token: res.data.token,
        };
        dispatch(CONNECTION_UNBLOCK_UPDATE_STATUS_SUCCESS(response));
        /* dispatch(UPDATE_STATUS_SUCCESS(response)); */
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};
export default manageConnectionSlice.reducer;
