const previewPostPhotosModalStyles = () => ({
	photoPreviewModalBox: {
		position: "absolute",
		zIndex: "10",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		overflow: "hidden",
		border: "solid 1px grey",
		borderRadius: "15px",
		"-moz-border-radius": "15px",
		"-webkit-border-radius": "15px",
		backgroundColor: "white",
		padding: "0",
		width: "495px",
		maxHeight: "600px",
	},

	container: {
		overflow: "auto",
		// overflowY: "scroll",
		maxHeight: "595px",
		height: "98vh",

		"&::-webkit-scrollbar": {
			width: "0",
		},
	},

	boxTitle: {
		position: "relative",
		padding: "10px 0",
		color: "#171725",
		fontSize: "14px",
		fontWeight: "700",
		textAlign: "center",
		verticalAlign: "middle",

		"& > h1": {
			display: "inline-block",
			margin: "0",
			fontSize: "14px",
			fontWeight: 600,
		},

		"& > button": {
			position: "absolute",
			right: 0,
			marginTop: "3px",
			marginRight: "13px",
			border: "none",
			backgroundColor: "transparent",
			cursor: "pointer",
		},
	},

	photoPreview: {
		position: "relative",
		backgroundColor: "#efefef",
		width: "100%",
		height: "395px",
		textAlign: "center",
		overflow: "hidden",

		"& > img": {
			width: "100%",
			height: "100%",
			objectFit: "contain",
			objectPosition: "center",
		},
	},

	editButton: {
		position: "absolute",
		zIndex: "1",
		top: "25px",
		right: "20px",
		border: "none",
		borderRadius: "50px",
		backgroundColor: "black",
		padding: "12px 22px",
		color: "white",
		fontSize: "9px",
		fontWeight: "700",
		cursor: "pointer",
		transition: "0.2s ease-in-out",

		"&:hover": {
			backgroundColor: "#3e3e3e",
		},
	},

	doneButton: {
		position: "absolute",
		zIndex: "1",
		bottom: "0",
		right: "20px",
		// border: "none",
		// borderRadius: "50px",
		// backgroundColor: "#6F52ED",
		// padding: "12px 22px",
		// color: "white",
		// fontSize: "9px",
		// fontWeight: "700",
		// cursor: "pointer",
		// transition: "0.2s ease-in-out",

		color: "white",
		border: "none",
		cursor: "pointer",
		padding: "15px 40px",
		fontSize: "11px",
		fontWeight: "700",
		borderRadius: "7px",
		backgroundColor: "#6F52ED",

		// "&:hover": {
		// 	backgroundColor: "#6F52ED",
		// },
	},

	photosList: {
		marginTop: "5px",
		overflow: "auto",
		display: "flex",
		padding: "10px 0",
		height: "145px",
	},

	photosListContainer: {
		display: "flex",
		flexWrap: "wrap",
		padding: "0 6px",
	},

	photosListItem: {
		position: "relative",
		margin: "5px 8px 12px",
		width: "102px",
		height: "120px",

		// "& > div:last-of-type": {

		// },
	},

	removePhoto: {
		position: "absolute",
		zIndex: "2",
		top: "-9px",
		right: "-9px",
		width: "20px",
		height: "auto",
		cursor: "pointer",
	},

	photosListItemPhotoContainerOverlay: {
		position: "absolute",
		zIndex: 1,
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		cursor: "pointer",
	},

	photosListItemPhotoContainer: {
		display: "inline-block",
		overflow: "hidden",
		width: "100%",
		height: "100%",
		border: "1px solid #92929D",
		borderRadius: "15px",
	},

	selectedPhoto: {
		boxSizing: "border-box",
		border: "solid 3px #20F0A1",
	},

	photosListItemPhoto: {
		width: "100%",
		height: "100%",
		objectFit: "cover",
		objectPostion: "center",
	},

	error: {
		position: "sticky",
		// zIndex: "2",
		bottom: "0",
		backgroundColor: "white",
		padding: "10px 0",
		width: "100%",
		textAlign: "center",
		fontSize: "12px",
		fontWeight: "500",
		color: "red",
	},

	photoEditModalBox: {
		position: "absolute",
		zIndex: "10",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		overflow: "hidden",
		border: "solid 1px grey",
		borderRadius: "15px",
		"-moz-border-radius": "15px",
		"-webkit-border-radius": "15px",
		backgroundColor: "white",
		padding: "0",
		width: "495px",
		maxHeight: "600px",
	},
	dialogStyle: {
		maxWidth: "1250px!important",
		// width: "60%!important",
		minHeight: "401px",
		maxHeight: "92%",
	},
});

export default previewPostPhotosModalStyles;
