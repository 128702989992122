import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";

import {
	encryptData,
	TimeFormat,
	TimeFormatWithoutTimeAgo,
} from "configurations/configurations.js";
import { Link, useHistory } from "react-router-dom";
/* import { Button, IconButton } from "@material-ui/core"; */
import Button from "components/CustomButtons/Button.js";

import ConnectionPageStyle from "assets/jss/material-kit-pro-react/components/connectionPageStyle.js";

import defaultAvatar from "assets/img/candidate_avatar.jpeg";
import SmallCardLoading from "../ConnectionPage/SmallCardLoading.js";
import {
	updateConnectionStatus,
	deleteBlockProfile,
} from "configurations/redux/manageConnectionSlice.js";
import {
	activateEditType,
	deactivateEditType,
} from "configurations/redux/editProfileModalSlice";
import hashtagImg from "assets/img/hashtag.png";
import { deleteFollowHashtag } from "configurations/redux/manageConnectionSlice.js";
import { deleteFollowProfile } from "configurations/redux/manageConnectionSlice.js";

const useStyles = makeStyles(ConnectionPageStyle);

export default function FollowCard({ item, status }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const userStatus = useSelector((state) => state.auth.user);

	const isLoading = useSelector((state) => state.manageConnection.isLoading);

	const CardComponent = () => {
		return (
			<Link
				className={classes.textDeco}
				to={`/view-profile/${encryptData(item.flpfAcctId)}`}
				color="transparent"
				target="_blank"
				key={item.acctId}
			>
				<Card className={classes.cardFrame + " " + classes.cardClick}>
					<CardBody style={{ padding: "10px" }}>
						<div className={classes.resultContainer}>
							<div className={classes.imgContainer}>
								<img src={item.profilePic ? item.profilePic : defaultAvatar} />
							</div>
							<div className={classes.descriptionContainer}>
								<div>
									<h4 className={classes.title}>
										{item.firstName + " " + item.lastName}
									</h4>
									<h4 className={classes.description}>{item.headline}</h4>
									<h4 className={classes.description}>{item.countryName}</h4>
								</div>
								<div style={{ display: "flex", alignItems: "center" }}>
									<Button
										color="oppty"
										className={classes.buttonMobile}
										onClick={(e) => {
											e.preventDefault();

											const response = {
												email: userStatus.email,
												acctId: userStatus.acctId,
												followId: item.flpfId,
												accessToken: localStorage.getItem("token"),
											};
											console.log(response);
											dispatch(deleteFollowProfile(response));
										}}
										round
									>
										<b>Unfollow</b>
									</Button>
								</div>
							</div>
						</div>
					</CardBody>
				</Card>
			</Link>
		);
	};
	const HashtagComponent = () => {
		return (
			<Link
				className={classes.textDeco}
				to={`/social-feed/3/${encryptData(item.hashId)}`}
				color="transparent"
				target="_blank"
			>
				<Card className={classes.cardFrame + " " + classes.cardClick}>
					<CardBody style={{ padding: "10px" }}>
						<div className={classes.resultContainer}>
							<div className={classes.imgContainer}>
								<img
									style={{ width: "60px", height: "60px" }}
									src={hashtagImg}
								/>
							</div>
							<div className={classes.descriptionContainer}>
								<div>
									<h4 className={classes.title}>
										<b># </b>
										{item.hashtagName}
									</h4>
									<h4 className={classes.description}>
										{TimeFormatWithoutTimeAgo(item.createdDate)}
									</h4>

									<h4 className={classes.description}>
										Followed {TimeFormat(item.createdDate)}
									</h4>
								</div>
								<div style={{ display: "flex", alignItems: "center" }}>
									<Button
										color="oppty"
										className={classes.buttonMobile}
										onClick={(e) => {
											e.preventDefault();

											const response = {
												email: userStatus.email,
												acctId: userStatus.acctId,
												followId: item.followId,
												accessToken: localStorage.getItem("token"),
											};
											console.log(response);

											dispatch(deleteFollowHashtag(response));
										}}
										round
									>
										<b>Unfollow</b>
									</Button>
								</div>
							</div>
						</div>
					</CardBody>
				</Card>
			</Link>
		);
	};

	return (
		<div>
			{isLoading ? (
				<SmallCardLoading />
			) : status === "Hashtag" ? (
				<HashtagComponent />
			) : (
				<CardComponent />
			)}
		</div>
	);
}
