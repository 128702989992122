import LanguageIcon from '@mui/icons-material/Language';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import ArticleIcon from '@mui/icons-material/Article';

export const suggestedGroupData = [
  {
    id: 1,
    header: 'Dribble Designer Pro ID',
    subtext1: 'Yogyakarta',
    subtext2: '• 2351 Members',
  },
  {
    id: 2,
    header: 'Urban Sneakers Society ID',
    subtext1: 'Paris',
    subtext2: '• 1.23M Members',
  },
  {
    id: 3,
    header: 'Microstock Community',
    subtext1: 'Rome',
    subtext2: '• 23513 Members',
  },
  {
    id: 4,
    header: 'Football Europe Update',
    subtext1: 'Jakarta',
    subtext2: '• 351 Members',
  },
];

export const browseByIndustryData = [
  {
    id: 1,
    text: 'Hobby',
    bgcolor: '#E9F3FB',
    color: '#50B5FF',
    icon: LanguageIcon,
  },
  {
    id: 2,
    text: 'Entertainment',
    bgcolor: '#EDF4E7',
    color: '#82C43C',
    icon: LiveTvIcon,
  },
  {
    id: 3,
    text: 'Business',
    bgcolor: '#FAEFE8',
    color: '#FF974A',
    icon: WorkOutlineIcon,
  },
  {
    id: 4,
    text: 'Fashion',
    bgcolor: '#FAEFF6',
    color: '#FF9AD5',
    icon: StarBorderIcon,
  },
  {
    id: 5,
    text: 'News Update',
    bgcolor: '#F5EFFB',
    color: '#A461D8',
    icon: ArticleIcon,
  },
];
