import React, { Fragment } from "react";
import { useSelector } from "react-redux";

import { Typography, makeStyles, IconButton } from "@material-ui/core";

import dp from "assets/img/faces/marc.jpg";
import gallery from "assets/images/documentation.png";
import video from "assets/img/video.png";
import { ReactComponent as SendIcon } from "assets/svg/send-svgrepo-com.svg";

import avatar from "assets/img/candidate_avatar.jpeg";
import postSomethingCardStyles from "../../../../assets/jss/material-kit-pro-react/views/componentStyle/profileView/myActivity/postSomethingCardStyles.js";

const PostSomethingCard = (props) => {
	const styles = makeStyles(postSomethingCardStyles)();
	const user = useSelector((state) => state.auth.user);

	return (
		<Fragment>
			<div
				className={styles.body}
				style={{ cursor: "pointer" }}
				onClick={() => {
					props.createPost(true);
				}}
			>
				<Typography component="div" className={styles.header}>
					<Typography component="h2" className={styles.title}>
						Post Something
					</Typography>
				</Typography>

				<hr className={styles.divider} />

				<Typography component="div" className={styles.main}>
					<img
						src={user.profilePic ? user.profilePic : avatar}
						alt=""
						className={styles.profilePic}
					/>

					<Typography component="div" className={styles.inputContainer}>
						<textarea
							style={{ cursor: "pointer" }}
							type="text"
							placeholder="What’s on your mind?"
							className={styles.postInput}
							onKeyUp={(e) => {
								e.target.style.height = "1px";
								e.target.style.height = 15 + e.target.scrollHeight + "px";
							}}
						/>
						<IconButton className={styles.galleryBtn}>
							{/* <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20">
                <path id="Shape" d="M17.5,20H3.5A3.431,3.431,0,0,1,0,16.65V3.35A3.431,3.431,0,0,1,3.5,0h14A3.431,3.431,0,0,1,21,3.35v13.3A3.431,3.431,0,0,1,17.5,20ZM15.286,8.775l-4.714,4.253L15.961,19H17.5A2.431,2.431,0,0,0,20,16.65V13.071Zm-8.139,1.95h0L1.009,16.849A2.458,2.458,0,0,0,3.5,19H14.613L7.148,10.725ZM3.5,1A2.431,2.431,0,0,0,1,3.35v12.1l5.814-5.8a.5.5,0,0,1,.724.019L9.9,12.286l5.051-4.557a.5.5,0,0,1,.671,0L20,11.718V3.35A2.431,2.431,0,0,0,17.5,1ZM6.691,6.7A1.925,1.925,0,1,1,8.619,4.775,1.929,1.929,0,0,1,6.691,6.7Zm0-2.849a.925.925,0,1,0,.929.925A.928.928,0,0,0,6.691,3.85Z" transform="translate(0 0)" fill="#92929d" />
              </svg> */}
							<SendIcon
								sx={{
									color: "#92929d",
									margin: "5px",
								}}
							/>
							{/* <img src={gallery} alt="" /> */}
						</IconButton>
					</Typography>
				</Typography>
			</div>
		</Fragment>
	);
};

export default PostSomethingCard;
