import React, { useEffect, useState, useMemo, useCallback } from 'react';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

import Header from 'views/Component/Header';
import LeftSidebar from 'views/Component/LeftSidebar';
import GroupSummaryCard from './GroupSummaryCard';
import GroupAdminCard from './GroupAdminCard';
import AboutGroup from './AboutGroup';
import GroupMainContent from './GroupMainContent';
import RightSideBarUserList from 'views/Component/RightSideBarUserList';
import Footer from 'views/Component/Footer';
import CloseIcon from '@mui/icons-material/Close';
import ManageGroupModal from './manageGroupModal';
import { Typography, makeStyles, Button, Modal, Hidden } from '@material-ui/core';
import CustomButton from 'components/CustomButtons/Button';

import manageGroupPageStyles from 'assets/jss/material-kit-pro-react/views/pageStyle/ManageGroupPage';
import { useHistory, useParams } from 'react-router';
import {
  getGroupById,
  joinUserGroup,
  getGroupUserByGroupId,
  leaveUserGroup,
  getMediaByGroupId,
} from 'configurations/redux/groupsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useIsFirstRender, useModal } from 'hooks';
import InviteUserModal from './InviteUserModal';
import ConfirmModal from './ConfirmModal';
import { userGroupStatus, userGroupRoles, groupPermissions, decryptData } from 'configurations/configurations';
import { getAllCountries, getAllIndustry } from 'configurations/redux/userSlice';
import {
  getSocialPostByRecent,
  resetSocialPost,
  resetPostGet,
  resetHashtagMatch,
  getSocialPostByPostId,
} from 'configurations/redux/socialFeedSlice';
import MediaListModal from './MediaListModal';

const useStyles = makeStyles(manageGroupPageStyles);

const ManageGroupPage = () => {
  const history = useHistory();
  const styles = useStyles();
  const { groupId: encryptedGroupId } = useParams();
  const groupId = decryptData(encryptedGroupId);
  const { postId: encryptedPostId } = useParams();
  const postId = encryptedPostId ? decryptData(encryptedPostId) : 0;
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const { groupById: group, usersByGroupId, mediaByGroupId } = useSelector(state => state.groups);
  const isFirstRender = useIsFirstRender();

  const [groupIsLoading, setGroupIsLoading] = useState(true);
  const [membersIsLoading, setMembersIsLoading] = useState(true);

  const [leaveBtnStatus, setLeaveBtnStatus] = useState(userGroupStatus.INACTIVE.id);
  const [manageGroupInitialTab, setManageGroupInitialTab] = useState(userGroupStatus.ACTIVE.id);

  const accessToken = localStorage.getItem('token');

  const leaveUserModalContent = {
    title: 'Leave group',
    text: 'Are you sure you want to leave this group?',
    cancelText: 'No',
    confirmText: 'Yes',
  };
  const cancelRequestModalContent = {
    title: 'Cancel request',
    text: 'Are you sure you want to cancel request to join group?',
    cancelText: 'No',
    confirmText: 'Yes',
  };
  const inviteUserModalProps = useModal();
  const mediaListModalProps = useModal();
  const leaveUserModalProps = useModal();
  const cancelRequestModalProps = useModal();
  const manageGroupModalProps = useModal();

  const buttonStatuses = {
    [userGroupStatus.ACTIVE.id]: {
      text: 'Leave group',
      onClick: leaveUserModalProps.toggle,
    },
    [userGroupStatus.REQUESTED.id]: {
      text: 'Pending',
      onClick: cancelRequestModalProps.toggle,
    },
    [userGroupStatus.INACTIVE.id]: { text: 'Join group', onClick: joinGroup },
  };

  const { currentUser, activeUsers, groupOwner } = useMemo(() => {
    const users = {};
    users.activeUsers = usersByGroupId.filter(u => u.statusId === userGroupStatus.ACTIVE.id);
    users.currentUser = usersByGroupId.find(u => u.acctId === user.acctId) ?? {};
    users.groupOwner = usersByGroupId.find(u => u.roleId === userGroupRoles.OWNER.id);
    return users;
  }, [usersByGroupId]);

  const isUserManagerOrOwner =
    currentUser.roleId === userGroupRoles.OWNER.id || currentUser.roleId === userGroupRoles.MANAGER.id;
  const isUserOwner = currentUser.roleId === userGroupRoles.OWNER.id;
  const isUserRoleMember = currentUser.roleId === userGroupRoles.MEMBER.id;
  const isUserMember = Object.keys(currentUser).length > 0;

  const getMembers = () => {
    const { OWNER, MANAGER, MEMBER } = userGroupRoles;
    const { ACTIVE, INVITED, REQUESTED } = userGroupStatus;
    const body = {
      groupId,
      accessToken,
      keyword: '',
      lastRecordSize: 0,
      requestRecordSize: -1,
      email: user.email,
      roleTypeId: [OWNER.id, MANAGER.id, MEMBER.id].toString(),
      statusTypeId: [ACTIVE.id, INVITED.id, REQUESTED.id].toString(),
    };
    dispatch(getGroupUserByGroupId(body)).then(() => {
      setMembersIsLoading(false);
    });
  };

  const getGroupData = () => {
    dispatch(getGroupById({ email: user.email, accessToken, groupId })).then(() => {
      setGroupIsLoading(false);
    });
  };

  const getMedia = useCallback(({ lastRecordSize = 0, requestRecordSize = 4 } = {}) => {
    const body = {
      groupId,
      accessToken,
      email: user.email,
      acctId: user.acctId,
      lastRecordSize,
      requestRecordSize,
    };
    return dispatch(getMediaByGroupId(body)).then(res => {
      return res;
    });
  }, []);

  function leaveGroup(closeModalOnSuccess) {
    const body = {
      accessToken,
      email: user.email,
      acctId: user.acctId,
      groupUserId: currentUser.groupUserId,
      groupId,
    };
    dispatch(leaveUserGroup(body)).then(() => {
      closeModalOnSuccess();
    });
  }

  function joinGroup() {
    const { ALLOW_ANYONE_TO_JOIN } = groupPermissions;
    const userStatusId =
      group.permissionId.indexOf(`${ALLOW_ANYONE_TO_JOIN.id}`) === -1
        ? userGroupStatus.REQUESTED.id
        : userGroupStatus.ACTIVE.id;
    const body = {
      accessToken,
      email: user.email,
      groupId: group.groupId,
      acctId: user.acctId,
      userId: user.acctId,
      userStatusId,
      roleId: userGroupRoles.MEMBER.id,
    };
    console.log('join group', body);
    dispatch(joinUserGroup(body));
  }

  const moveToGroupSettings = useCallback(() => {
    setManageGroupInitialTab(userGroupStatus.INACTIVE.id);
    manageGroupModalProps.toggle();
  }, []);

  useEffect(() => {
    dispatch(resetSocialPost());
    dispatch(getAllIndustry());
    dispatch(getAllCountries());
    getGroupData();
    getMembers();
  }, []);

  useEffect(() => {
    console.log(currentUser);
    if (!isFirstRender) {
      const userStatus = currentUser?.statusId ?? currentUser?.userStatusId;
      if (userStatus) {
        setLeaveBtnStatus(userStatus);
      } else {
        setLeaveBtnStatus(userGroupStatus.INACTIVE.id);
      }
      if (currentUser.groupUserId > 0 && currentUser.statusId == 1) {
        setMyself(true);
      }
    }
  }, [currentUser]);

  useEffect(() => {
    console.log('post id found', postId);
    if (postId && postId > 0) {
      console.log(history, encryptedPostId);
      history.replace(history.location.pathname.replace('/' + encryptedPostId, ''));
      getSinglePost();
    }
  }, [postId]);

  const [createPost, setCreatePost] = React.useState(false);
  const [myself, setMyself] = useState(false);

  const getSinglePost = () => {
    dispatch(resetPostGet());
    dispatch(resetHashtagMatch());
    const requestProfile = {
      acctId: user.acctId,
      accessToken: localStorage.getItem('token'),
      email: user.email,
      lastRecordSize: 0,
      requestRecordSize: 1,
      postId: postId,
      postList: [],
      parentId: 0,
      requestRecordSizeComment: 3,
      commentRequestLength: 0,
      visibleIds: '4',
      groupId: groupId,
    };
    dispatch(getSocialPostByPostId(requestProfile));
  };

  const getByRecent = useCallback(() => {
    var previousPostListType = localStorage.getItem('postListType');
    const requestProfile = {
      acctId: user.acctId,
      accessToken: localStorage.getItem('token'),
      email: user.email,
      lastRecordSize: 0,
      requestRecordSize: 5,
      hashId: 0,
      userId: 0,
      groupId: groupId,
      pageId: 0,
      sortBy: previousPostListType ? previousPostListType : 'recent',
      keyword: '',
      visibleIds: '1,2',
    };
    // console.log(requestProfile);
    dispatch(getSocialPostByRecent(requestProfile));
  }, [user, groupId]);

  const searchResult = useSelector(state => state.socialFeed.searchResult);

  return (
    <>
      <Header />
      <div className={styles.body} id="GroupSocialFeed">
        <GridContainer container spacing={2}>
          <Hidden mdDown>
            <GridItem md={2}>
              <LeftSidebar />
            </GridItem>
          </Hidden>

          <GridItem sm={12} md={4} lg={2}>
            <GroupSummaryCard
              {...{ isUserOwner, isUserMember, groupIsLoading }}
              onGroupInviteClick={inviteUserModalProps.toggle}
              leaveBtnProps={buttonStatuses[leaveBtnStatus]}
              image={group?.profilePic}
              name={group?.groupName}
              posts={searchResult && searchResult.length > 0 ? searchResult[0].totalPost : 0}
              members={activeUsers.length}
            />

            <GroupAdminCard
              {...{ isUserOwner, groupOwner, groupIsLoading }}
              onAddClick={manageGroupModalProps.toggle}
            />
            <AboutGroup
              description={group?.groupDescription}
              industry={group?.industryName ?? group?.customIndustryName}
              country={group?.countryName}
              onAboutClick={moveToGroupSettings}
              {...{ isUserManagerOrOwner, groupIsLoading }}
            />
          </GridItem>
          <GridItem sm={12} md={8} lg={6}>
            <GroupMainContent
              {...{
                group,
                activeUsers,
                isUserManagerOrOwner,
                isUserRoleMember,
                currentUser,
                groupIsLoading,
                membersIsLoading,
                getByRecent,
                getMedia,
              }}
              mediaSeeAllClick={mediaListModalProps.toggle}
              manageGroupClick={manageGroupModalProps.toggle}
              editProfileClick={moveToGroupSettings}
              createPostClick={createPost}
              setCreatePostClick={setCreatePost}
              postId={postId}
            />
          </GridItem>
          <Hidden mdDown>
            <GridItem md={2} lg={2}>
              {/* <CustomButton round fullWidth color='oppty' onClick={(ev) => {}}>
              Create Post
            </CustomButton> */}
              <RightSideBarUserList createPostClick={setCreatePost} isOwner={myself} />
            </GridItem>
          </Hidden>
        </GridContainer>

        <Footer />

        <ManageGroupModal
          {...{
            ...manageGroupModalProps,
            initialTab: manageGroupInitialTab,
            setInitialTab: setManageGroupInitialTab,
            group,
          }}
        />
        <InviteUserModal {...{ ...inviteUserModalProps, group }} />
        <MediaListModal {...{ ...mediaListModalProps, getMedia }} mediaList={mediaByGroupId} />
        <ConfirmModal
          {...{ ...leaveUserModalProps }}
          bodyContent={leaveUserModalContent}
          handleSubmit={() => leaveGroup(leaveUserModalProps.toggle)}
        />
        <ConfirmModal
          {...{ ...cancelRequestModalProps }}
          bodyContent={cancelRequestModalContent}
          handleSubmit={() => leaveGroup(cancelRequestModalProps.toggle)}
        />
      </div>
    </>
  );
};
export default ManageGroupPage;
