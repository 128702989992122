import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Header from "../../Component/Header";
import LeftSidebar from "../../Component/LeftSidebar.js";
import { Link, useHistory } from "react-router-dom";

import {
	makeStyles,
	Hidden,
	Box,
	CircularProgress,
	IconButton,
	Typography,
} from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { useModal } from "hooks";
import groupPageStyles from "assets/jss/material-kit-pro-react/views/pageStyle/GroupsPage";
import { suggestedGroupData, browseByIndustryData } from "./mocks";
import CreateGroupModal from "./CreateGroupModal";
import {
	getAllIndustry,
	getAllCountries,
} from "configurations/redux/userSlice";
import { getGroupsByAcctId, getGroups } from "configurations/redux/groupsSlice";
import GroupContainer from "./GroupContainer";
import GroupCard from "./GroupCard";
import GroupsPageHeader from "./GroupsPageHeader";
import GroupByIndustryCard from "./GroupByIndustryCard";
import {
	userGroupRoles,
	userGroupStatus,
	groupPermissions,
} from "configurations/configurations";
import { joinUserGroup } from "configurations/redux/groupsSlice";
import Pagination from "views/Component/PaginationWithDisable.js";
import GroupLoader from "./GroupLoader.js";
import SearchIcon from "@material-ui/icons/Search";
import NoResultCard from "./NoResultCard";
import CustomButton from "components/CustomButtons/Button";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles(groupPageStyles);

export default function BrowseGroupComponent({ match }) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const createGroupModalProps = useModal();

	const {
		groups,
		isGroupsLoading,
		groupsByAccount,
		isGroupsByAccountLoading,
		isStatusUpdated,
	} = useSelector((state) => state.groups);
	const user = useSelector((state) => state.auth.user);
	const [currentPage, setCurrentPage] = React.useState(1);
	const [profilePerPage, setProfilePerPage] = React.useState(8);
	const indexOfLast = currentPage * profilePerPage;
	const indexOfFirst = indexOfLast - profilePerPage;
	const [searchText, setSearchText] = React.useState(match.params.keyword);
	const history = useHistory();

	const getAllGroups = () => {
		const body = {
			accessToken: localStorage.getItem("token"),
			acctId: user.acctId,
			email: user.email,
			lastRecordSize: 0,
			requestRecordSize: 8,
			keyword: searchText,
		};
		dispatch(getGroups(body));
	};
	const paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
		var indexOfFirst = pageNumber * profilePerPage - profilePerPage;

		const body = {
			accessToken: localStorage.getItem("token"),
			acctId: user.acctId,
			email: user.email,
			lastRecordSize: indexOfFirst,
			requestRecordSize: profilePerPage,
			keyword: searchText,
		};
		dispatch(getGroups(body));
	};

	React.useEffect(() => {
		getAllGroups();
	}, []);

	const handleKeyPress = (event) => {
		if (event.key === "Enter") {
			console.log(event.target.value);
			const body = {
				accessToken: localStorage.getItem("token"),
				acctId: user.acctId,
				email: user.email,
				lastRecordSize: 0,
				requestRecordSize: 8,
				keyword: searchText,
			};
			dispatch(getGroups(body));
			history.replace("/browse-groups/" + searchText);
		}
		setCurrentPage(1);
	};
	const handleSubmit = () => {
		const body = {
			accessToken: localStorage.getItem("token"),
			acctId: user.acctId,
			email: user.email,
			lastRecordSize: 0,
			requestRecordSize: 8,
			keyword: searchText,
		};
		dispatch(getGroups(body));
		history.replace("/browse-groups/" + searchText);
		setCurrentPage(1);
	};
	const loadingFields = [];
	for (let i = 0; i < profilePerPage - groups.length; i++) {
		loadingFields.push(
			<GridItem xs={12} sm={6} md={6} lg={3} key={i}>
				<GroupLoader />
			</GridItem>
		);
	}
	return (
		<div>
			<Header />
			<div className={classes.body}>
				<div
					id="pageHeader"
					className={classes.pageHeader}
					style={{
						backgroundSize: "cover",
						backgroundPosition: "top center",
					}}
				>
					<div
						className={classes.container}
						style={{ background: "#f2f2f2" }}
						id="socialFeedPage"
					>
						<GridContainer justifyContent="center" spacing={2}>
							<Hidden mdDown>
								<GridItem md={2}>
									<LeftSidebar />
								</GridItem>
							</Hidden>
							<GridItem sm={12} md={10} lg={10}>
								<GridContainer style={{ marginTop: "10px" }}>
									<GridItem
										sm={5}
										style={{ display: "flex", alignItems: "center" }}
									>
										<Typography className={classes.headerText} variant="h5">
											Search Groups in Oppty
										</Typography>
									</GridItem>
									<GridItem sm={12} md={7} lg={7}>
										<div className={classes.headerActions}>
											<div className={classes.searchGroupsInPage}>
												<input
													placeholder="Search Groups"
													onKeyDown={(event) => {
														setSearchText(event.target.value);
														handleKeyPress(event);
													}}
													className={classes.input}
													onChange={(event) => {
														setSearchText(event.target.value);
													}}
													value={searchText || ""}
												/>
												<IconButton onClick={handleSubmit} sx={{ padding: 0 }}>
													<SearchIcon />
												</IconButton>
											</div>
										</div>
									</GridItem>
								</GridContainer>

								<GroupContainer
									title="Browse groups"
									subTitle="Discover groups from the various industries that you are interested in."
									expanded={true}
								>
									<div>
										<GridContainer spacing={1}>
											{isGroupsLoading &&
												loadingFields.map((item) => {
													return item;
												})}
											{groups &&
												groups.length > 0 &&
												groups.map((data, index) => (
													<GridItem
														xs={12}
														sm={6}
														md={6}
														lg={3}
														key={data.groupId}
													>
														<GroupCard group={data} />
													</GridItem>
												))}
										</GridContainer>

										{groups && groups.length > 0 && (
											<Pagination
												totalPosts={groups[0].totalCount}
												postsPerPage={profilePerPage}
												paginate={paginate}
												page={currentPage}
												disabledStatus={isGroupsLoading ? true : false}
											/>
										)}
										{!isGroupsLoading && groups.length === 0 && (
											<NoResultCard
												description={
													"No available group. Please use different keyword"
												}
											/>
										)}
									</div>
								</GroupContainer>
							</GridItem>
						</GridContainer>
					</div>
				</div>
			</div>
			<CreateGroupModal {...{ ...createGroupModalProps }} />
		</div>
	);
}
