import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { PlayCircleOutlineOutlined } from '@material-ui/icons';
import { useModal } from 'hooks';
import PopupMedia from './PopupMedia';
import VideoThumbnail from 'react-video-thumbnail';
import img1 from 'assets/img/examples/blog1.jpg';
import { Skeleton } from '@mui/material';

const Media = React.memo(({ mediaList, mediaIsLoading, loadersCount = 4, rowHeight = 100, minItemWidth = 100 }) => {
  const mediaModal = useModal();
  const [popUpData, setPopUpData] = useState({ attachment: '', typeOptionId: '' });

  const onImgClick = ({ attachment, typeOptionId }) => () => {
    setPopUpData({ attachment, typeOptionId });
    mediaModal.toggle();
  };

  const Thumbnail = ({ url }) => {
    const [thumbnail, setThumbnail] = useState(null);

    return (
      <>
        <Box display="none">
          <VideoThumbnail videoUrl={url} thumbnailHandler={value => setThumbnail(value)} />
        </Box>
        <img style={{ objectFit: 'cover', borderRadius: 8 }} height={rowHeight} width="100%" src={thumbnail} />
      </>
    );
  };

  return (
    <Box maxHeight="76vh" display="flex" gridGap="10px" marginTop="10px" flexWrap="wrap">
      {mediaIsLoading ? (
        <>
          {[...Array(loadersCount)].map(() => (
            <Skeleton
              variant="rectangular"
              height={rowHeight}
              style={{ minWidth: minItemWidth, flexGrow: 1, borderRadius: 8 }}
            />
          ))}
        </>
      ) : (
        <>
          {mediaList.map((media, index) => (
            <Box flex={1} key={index}>
              {media.typeOptionId === 1 && (
                <Box
                  minWidth={minItemWidth}
                  onClick={onImgClick({ attachment: media.attachment.url, typeOptionId: media.typeOptionId })}
                >
                  <img
                    style={{ objectFit: 'cover', borderRadius: 8, cursor: 'pointer' }}
                    height={rowHeight}
                    width="100%"
                    src={media.attachment.url}
                  />
                </Box>
              )}
              {media.typeOptionId === 2 && (
                <Box
                  minWidth={minItemWidth}
                  style={{ cursor: 'pointer' }}
                  position="relative"
                  onClick={onImgClick({ attachment: media.attachment.url, typeOptionId: media.typeOptionId })}
                >
                  <PlayCircleOutlineOutlined
                    style={{
                      position: 'absolute',
                      fontSize: '3rem',
                      color: 'white',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  />

                  <Thumbnail url={media.attachment.url} />
                </Box>
              )}
            </Box>
          ))}
        </>
      )}
      <PopupMedia
        open={mediaModal.modalOpen}
        onClose={mediaModal.toggle}
        post={{
          postTypes: [{ attachment: popUpData.attachment, original: popUpData.attachment }],
          typeOptionId: popUpData.typeOptionId,
        }}
      />
    </Box>
  );
});

export default Media;
