import React from "react";
import { Route, Redirect, BrowserRouter } from "react-router-dom";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import history from "../../history.js";
import { encryptData } from "configurations/configurations.js";

const AuthRoute = ({
	pageType,
	bizId,
	user,
	authenticated,
	component: Component,
	render,
	profileBuildFlag,
	path,
	...rest
}) => {
	var urlPath = rest.location.pathname;
	if (!authenticated) {
		// console.log(Cookies.get("logout_handle"));
		if (!Cookies.get("logout_handle")) Cookies.set("navigate_path", urlPath);
		// history.push("/login-page")
		if (path === "/public-search-page/:type/:id") {
			console.log("is public mode");
		}
	}
	console.log(authenticated, user, bizId, profileBuildFlag, path);
	const acctId = user && encryptData(user.acctId);
	const encryptBizId = bizId > 0 && encryptData(bizId);
	return (
		<Route
			{...rest}
			//
			render={(props) =>
				authenticated ? (
					bizId > 0 ? (
						profileBuildFlag === 0 ? (
							<Redirect
								to={{
									pathname: `/corporate-profile-page/${acctId}/${encryptBizId}`,
								}}
							/>
						) : pageType === 0 || pageType === 2 ? (
							render ? (
								render(props)
							) : (
								<Component {...props} />
							)
						) : (
							<Redirect
								to={{
									pathname: `/corporate-profile-page/${acctId}/${encryptBizId}`,
								}}
							/>
						)
					) : (user.acctTypeId === 1 || user.acctTypeId === 3) && bizId == 0 ? (
						profileBuildFlag === 0 ? (
							<Redirect to={{ pathname: `/view-profile/${acctId}` }} />
						) : pageType === 0 || pageType === 1 ? (
							render ? (
								render(props)
							) : (
								<Component {...props} />
							)
						) : (
							<Redirect to={{ pathname: "/social-feed" }} />
						)
					) : (
						<Redirect to={{ pathname: "/login-page" }} />
					)
				) : (
					<Redirect to={{ pathname: "/login-page" }} />
				)
			}
			// {...rest}
			// render={(props) => (
			// 	<Redirect
			// 		to={{
			// 			pathname: "/" /* state: { from: props.location } */,
			// 		}}
			// 	/>
			// )}
			/*  <Redirect
                to={{
                  pathname: `/corporate-profile-page/${acctId}`,
                }}
              />  */
		/>
	);
};

export default AuthRoute;
