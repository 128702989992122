import React from "react";
import { useSelector, useDispatch, ReactReduxContext } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import communityPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/CommunityPage/communityPageStyle.js";
// import loginPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/LoginPage/loginPageStyle.js";

import Header from "views/Component/Header.js";
import Footer from "views/Component/Footer.js";

import { CircularProgress, Hidden, TextField } from "@material-ui/core";
import IframeResizer from "iframe-resizer-react";
import LoadingImage from "assets/img/Loading-pana.png";
import { searchArticleInfo } from "configurations/redux/communitySlice.js";
import { decryptData } from "configurations/configurations.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import NoResultImage from "assets/img/No_Result.jpg";
import SearchImage from "assets/img/Search_Image.jpg";
import classNames from "classnames";
import { publicMode } from "configurations/redux/authSlice";
import MetatagHelmet from "views/Component/MetatagHelmet";

const useStyles = makeStyles(communityPageStyle);

export default function CommunityDetailPage({ match }) {
	// console.log(decryptData(match.params.articleId));
	const classes = useStyles();
	const dispatch = useDispatch();
	const auth = useSelector((state) => state.auth);
	// const searchResult = useSelector(
	// 	(state) => state.searchCommunity.searchResult
	// );
	// const isLoading = useSelector((state) => state.searchCommunity.isLoading);
	const userStatus = useSelector((state) => state.auth.user);
	const isLoading = useSelector((state) => state.community.isLoading);
	const communityArticlePath = useSelector(
		(state) => state.community.communityArticlePath
	);
	const selectedArticle = useSelector(
		(state) => state.community.selectedArticle
	);
	React.useEffect(() => {
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;
	});

	React.useEffect(() => {
		if (match.path == "/public-community-detail-page/:articleId") {
			dispatch(publicMode());
		} else if (match.params.articleId) {
			var articleId = decryptData(match.params.articleId);
			const requestInfo = {
				/* acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email, */
				acctId: auth.isPublic ? 0 : userStatus.acctId,
				accessToken: auth.isPublic
					? auth.publicToken
					: localStorage.getItem("token"),
				email: auth.isPublic ? auth.publicEmail : userStatus.email,
				articleId: articleId,
			};
			dispatch(searchArticleInfo(requestInfo));
		}
	}, []);

	React.useEffect(() => {
		if (auth.isPublic && auth.publicToken) {
			if (match.params.articleId) {
				var articleId = decryptData(match.params.articleId);
				const requestInfo = {
					/* acctId: userStatus.acctId,
					accessToken: localStorage.getItem("token"),
					email: userStatus.email, */
					acctId: auth.isPublic ? 0 : userStatus.acctId,
					accessToken: auth.isPublic
						? auth.publicToken
						: localStorage.getItem("token"),
					email: auth.isPublic ? auth.publicEmail : userStatus.email,
					articleId: articleId,
				};
				dispatch(searchArticleInfo(requestInfo));
			}
		}
	}, [auth]);

	function urlExists(url) {
		var http = new XMLHttpRequest();
		try {
			http.open("HEAD", url, false);
			http.send();
		} catch (error) {}

		return http.status == 200;
	}
	const dateNow = new Date();
	console.log(selectedArticle);
	return (
		<>
			<Header color={"transparent"} loggedInStatus={false} />
			<div style={{ marginTop: "93px" }} className={classes.body}>
				{selectedArticle.length > 0 &&
				urlExists(communityArticlePath + selectedArticle[0].articlePath) ? (
					<div className={classes.pageHeader + " " + classes.pageHeaderHeight}>
						<MetatagHelmet
							metaTag={{
								title: selectedArticle[0].articleName,
								description: `Title: ${selectedArticle[0].articleName} Author: ${selectedArticle[0].author} Category: ${selectedArticle[0].category} Subcategory: ${selectedArticle[0].subcategory}`,
								url: window.location.href,
							}}
						/>
						{isLoading ? (
							<div className={classes.loadingContainer}>
								<CircularProgress />
							</div>
						) : (
							<IframeResizer
								log
								src={
									communityArticlePath +
									selectedArticle[0].articlePath +
									"?time=" +
									dateNow
								}
								style={{ width: "1px", minWidth: "100%", minHeight: "100vh" }}
							/>
						)}
					</div>
				) : (
					<div className={classNames(classes.main, classes.mainRaised)}>
						<div className={classes.container}>
							<GridContainer justifyContent="center">
								<Card className={classes.cardFrame}>
									<CardBody>
										<GridContainer justifyContent="center">
											<GridItem xs={12} sm={12} md={12}>
												{isLoading ? (
													<div className={classes.loadingContainer}>
														<CircularProgress />
													</div>
												) : (
													<h5
														className={classes.title + " " + classes.textCenter}
													>
														No available Article. Please try for other article.
														[Error Code: {decryptData(match.params.articleId)}]
													</h5>
												)}
											</GridItem>
											<GridItem
												xs={12}
												sm={12}
												md={6}
												className={classes.noResultGrid}
											>
												<img
													src={isLoading ? SearchImage : NoResultImage}
													alt="..."
													className={classes.noResultImage}
												/>
											</GridItem>
										</GridContainer>
									</CardBody>
								</Card>
							</GridContainer>
						</div>
					</div>
				)}
				{/* ) : null} */}
				{/* {isLoading && searchResult.length === 0 && (
				<GridContainer justifyContent="center">
					<GridItem xs={12} sm={12} md={12}>
						<div className={classes.loadingContainer}>
							<CircularProgress />
						</div>
					</GridItem>
					<GridItem xs={12} sm={12} md={6}>
						<img
							src={LoadingImage}
							alt="..."
							className={classes.loadingImage}
						/>
					</GridItem>
				</GridContainer>
			)} */}
				<Footer />
			</div>
		</>
	);
}
