import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import {
	getCountryId,
	getLocationId,
	getLocationIdByCountry,
	getStartYearData,
	getEndYearData,
	getMonthData,
} from "configurations/configurations.js";
import { experienceUpdateSchema } from "configurations/configurations.js";
import {
	updateExperienceProfile,
	editExperienceProfile,
} from "configurations/redux/userSlice.js";
import Hidden from "@material-ui/core/Hidden";
import ProfileStrengthTip from "./ProfileStrengthTip.js";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete, {
	createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import DialogActions from "@material-ui/core/DialogActions";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Danger from "components/Typography/Danger.js";

import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import WorkIcon from "@material-ui/icons/Work";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js";
import { isDesktop } from "react-device-detect";
import IconLibrary from "views/Component/IconLibrary.js";

const useStyles = makeStyles(profilePageStyle);
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

export const ExperienceEditModal = ({
	open,
	onClose,
	expRecId,
	expRecord,
	user,
	extension,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [countryList, setCountryList] = React.useState([]);
	const [locationList, setLocationList] = React.useState([]);
	const [currentStatus, setCurrentStatus] = React.useState([0, 1]);
	const [countryChange, setCountryChange] = React.useState();

	const closeModal = ({ actions }) => {
		onClose();
		//actions.resetForm();
	};
	const startYearDataList = getStartYearData();
	const endYearDataList = getEndYearData();
	const monthDataList = getMonthData();

	const fetchCountryList = async () => {
		const res = await getCountryId();
		setCountryList(res.data.payload);
	};
	const fetchLocationList = async () => {
		const res = await getLocationId();
		setLocationList(res.data.payload);
	};

	React.useEffect(() => {
		fetchCountryList();
		// fetchLocationList();
		console.log(expRecord);
	}, []);

	React.useEffect(() => {
		console.log(countryChange);
		if (countryChange) {
			const fetchLocationListByCountry = async (countryId) => {
				const res = await getLocationIdByCountry(countryId);
				setLocationList(res.data.payload);
			};
			fetchLocationListByCountry(countryChange);
		}
	}, [countryChange]);

	const modalResize = () => {
		// var timer = setInterval(() => {
		var modalResize = document.getElementById("modalCard");
		if (modalResize) {
			// clearInterval(timer);
			var maxSize = window.innerHeight - 74 - 80 - 70;
			if (maxSize < 0) maxSize = 0;
			modalResize.style.height = maxSize * 0.8 + "px";
			// } else {
			// 	setTimeout(() => {
			// 		clearInterval(timer);
			// 	}, 3000);
		}
		// }, 300);
		// return () => clearInterval(timer);
	};

	// React.useEffect(() => {
	// 	window.addEventListener("resize", modalResize);
	// });

	React.useEffect(() => {
		if (open) {
			if (document.readyState === "complete") {
				if (expRecord) setCountryChange(expRecord.countryId);
				setTimeout(() => {
					modalResize();
				}, 500);
			}
			window.addEventListener("resize", modalResize);
			return function cleanup() {
				window.removeEventListener("resize", modalResize);
			};
		}
	}, [open]);

	// React.useEffect(() => {
	// 	if (open) {
	// 		modalResize();
	// 		if (expRecord) {
	// 			setCountryChange(expRecord.countryId);
	// 		}
	// 	} else {
	// 		try {
	// 			window.removeEventListener("resize");
	// 		} catch (error) {}
	// 	}
	// 	return function cleanup() {
	// 		try {
	// 			window.removeEventListener("resize");
	// 		} catch (error) {}
	// 	};
	// }, [open]);

	return (
		<React.Fragment>
			<Dialog
				classes={{
					root: classes.modalRoot,
					paper: classes.modalProfileEdit + " " + classes.modal,
				}}
				open={open}
				// TransitionComponent={Transition}
				keepMounted
				onClose={onClose}
				// disableScrollLock
				aria-labelledby="classic-modal-slide-title"
				aria-describedby="classic-modal-slide-description"
			>
				<DialogTitle disableTypography className={classes.modalTitleEdit}>
					<Button
						simple
						className={classes.modalCloseButton}
						key="close"
						aria-label="Close"
						onClick={onClose}
					>
						<IconLibrary.Close />
					</Button>
					<h3
						className={
							classes.title +
							" " +
							classes.modalHeaderTitle +
							" " +
							classes.titleAlign
						}
					>
						{expRecord ? "Edit Experience" : "Add Experience"}
					</h3>
				</DialogTitle>

				<Formik
					enableReinitialize
					initialValues={{
						title: expRecord ? expRecord.title : "",
						organization: expRecord ? expRecord.organization : "",
						countryId: expRecord ? expRecord.countryId : "",
						locationId: expRecord ? expRecord.locationId : "",
						startYear: expRecord ? expRecord.startDate.substring(0, 4) : "",
						startMonth: expRecord ? expRecord.startDate.substring(5, 7) : "",
						endYear: expRecord
							? expRecord.currentInProgressFlag === 1
								? "0"
								: expRecord.endDate.substring(0, 4)
							: "0",
						endMonth: expRecord
							? expRecord.currentInProgressFlag === 1
								? "0"
								: expRecord.endDate.substring(5, 7)
							: "0",

						currentInProgressFlag: expRecord
							? expRecord.currentInProgressFlag
							: 0,
						achievement: expRecord ? expRecord.achievement : "",
						description: expRecord ? expRecord.description : "",
					}}
					validationSchema={experienceUpdateSchema}
					onSubmit={(data, { resetForm }) => {
						console.log(data);
						const endDate =
							data.currentInProgressFlag === 1
								? null
								: data.endYear + "-" + data.endMonth + "-01";

						const requestData = {
							email: user.email,
							acctId: user.acctId,
							expRecId: expRecId,
							title: data.title.trim(),
							organization: data.organization.trim(),
							countryId: data.countryId,
							locationId: data.locationId,
							startDate: data.startYear + "-" + data.startMonth + "-01",
							endDate: endDate,
							currentInProgressFlag: data.currentInProgressFlag,
							achievement: data.achievement.trim(),
							description: data.description.trim(),
							accessToken: localStorage.getItem("token"),
						};
						//console.log(requestData);
						{
							expRecId === -1
								? dispatch(updateExperienceProfile(requestData))
								: dispatch(editExperienceProfile(requestData));
						}
						resetForm();
						if (data) {
							onClose();
						}
					}}
				>
					{({
						handleChange,
						values,
						errors,
						touched,
						handleBlur,
						handleSubmit,
						setFieldValue,
						resetForm,
					}) => (
						<form onSubmit={handleSubmit}>
							<div className={classes.autoHeightBody}>
								<DialogContent
									id="classic-modal-slide-description"
									className={classes.modalBody}
								>
									{extension && <ProfileStrengthTip extension={extension} />}

									<Card className={classes.modalCardBorder}>
										<CardBody
											className={
												isDesktop
													? classes.smallModalBodyDesktop +
													  " " +
													  classes.modalBackground
													: classes.smallModalBody +
													  " " +
													  classes.modalBackground
											}
											id="modalCard"
										>
											<GridContainer>
												<GridItem>
													<h4 className={classes.modalTitle}>
														Experience Info
													</h4>
												</GridItem>
												<GridItem className={classes.gridHeight}>
													<TextField
														variant="outlined"
														id="title"
														label="Title"
														name="title"
														value={values.title}
														helperText={touched.title ? errors.title : ""}
														error={touched.title && Boolean(errors.title)}
														onChange={handleChange}
														onBlur={handleBlur}
														/* placeholder="Required" */
														fullWidth
														InputProps={{
															/* startAdornment: (
																<InputAdornment position="start">
																	<HowToRegIcon
																		className={classes.inputIconsColor}
																	/>
																</InputAdornment>
															), */
															autoComplete: "off",
															// defaultValue: values.title,
														}}
													/>
												</GridItem>
												<GridItem className={classes.gridHeight}>
													<TextField
														variant="outlined"
														id="organization"
														label="Organization"
														name="organization"
														value={values.organization}
														helperText={
															touched.organization ? errors.organization : ""
														}
														error={
															touched.organization &&
															Boolean(errors.organization)
														}
														onChange={handleChange}
														onBlur={handleBlur}
														/* placeholder="Required" */
														fullWidth
														InputProps={{
															/* startAdornment: (
																<InputAdornment position="start">
																	<WorkIcon
																		className={classes.inputIconsColor}
																	/>
																</InputAdornment>
															), */
															autoComplete: "off",
															// defaultValue: values.organization,
														}}
													/>
												</GridItem>
												<GridItem
													xs={12}
													sm={6}
													md={6}
													className={classes.gridHeight}
												>
													<Autocomplete
														options={countryList}
														onBlur={handleBlur}
														freeSolo
														value={
															countryList.filter((item) => {
																return item.countryId === values.countryId;
															})[0] || ""
														}
														getOptionSelected={(option, value) => {
															if (!value) {
																return false;
															}
															return option.countryId === value.countryId;
														}}
														getOptionLabel={(option) =>
															option.countryName ? option.countryName : ""
														}
														filterOptions={createFilterOptions({
															matchFrom: "start",
															stringify: (option) => option.countryName,
														})}
														onChange={(event, value) => {
															if (value) {
																setFieldValue("countryId", value.countryId);
																setCountryChange(value.countryId);
															} else {
																setFieldValue("countryId", "");
																setFieldValue("locationId", "");
															}
														}}
														id="countryId"
														name="countryId"
														renderInput={(params) => (
															<TextField
																{...params}
																label={"Country"}
																variant="outlined"
																helperText={
																	touched.countryId ? errors.countryId : ""
																}
																error={
																	touched.countryId && Boolean(errors.countryId)
																}
															/>
														)}
													/>
												</GridItem>
												<GridItem
													xs={12}
													sm={6}
													md={6}
													className={classes.gridHeight}
												>
													<React.Fragment>
														<Autocomplete
															options={locationList}
															disabled={!values.countryId}
															onBlur={handleBlur}
															// freeSolo
															value={
																locationList.filter((item) => {
																	return item.locationId === values.locationId;
																})[0] || ""
															}
															getOptionSelected={(option, value) =>
																option === value
															}
															getOptionLabel={(option) =>
																option.locationName ? option.locationName : ""
															}
															onChange={(event, value) => {
																if (value) {
																	setFieldValue("locationId", value.locationId);
																} else {
																	setFieldValue("locationId", "");
																}
															}}
															id="locationId"
															name="locationId"
															renderInput={(params) => (
																<TextField
																	{...params}
																	label={"Location"}
																	variant="outlined"
																	helperText={
																		touched.locationId ? errors.locationId : ""
																	}
																	error={
																		touched.locationId &&
																		Boolean(errors.locationId)
																	}
																/>
															)}
														/>
													</React.Fragment>
												</GridItem>
												<GridItem xs={12} sm={12} md={6}>
													<h4
														className={
															classes.modalTitle + " " + classes.grayFont
														}
													>
														Starting From
													</h4>
												</GridItem>
												<Hidden smDown>
													<GridItem xs={12} sm={12} md={6}>
														{values.currentInProgressFlag === 0 ? (
															<h4
																className={
																	classes.modalTitle + " " + classes.grayFont
																}
															>
																Ending In
															</h4>
														) : null}
													</GridItem>
												</Hidden>
												<GridItem
													xs={6}
													sm={6}
													md={3}
													className={classes.gridHeight}
												>
													<React.Fragment>
														<Autocomplete
															options={monthDataList}
															onBlur={handleBlur}
															// freeSolo
															value={
																monthDataList.filter((item) => {
																	return item.monthId === values.startMonth;
																})[0] || null
															}
															getOptionLabel={(option) =>
																option.monthName ? option.monthName : ""
															}
															getOptionSelected={(option, value) =>
																option.monthName === value.monthName
															}
															onChange={(event, value) => {
																console.log(value);
																if (value) {
																	setFieldValue("startMonth", value.monthId);
																} else {
																	setFieldValue("startMonth", "0");
																}
															}}
															renderInput={(params) => (
																<TextField
																	{...params}
																	label={"Month"}
																	variant="outlined"
																	helperText={
																		touched.startMonth ? errors.startMonth : ""
																	}
																	error={
																		touched.startMonth &&
																		Boolean(errors.startMonth)
																	}
																/>
															)}
														/>
													</React.Fragment>
												</GridItem>
												<GridItem
													xs={6}
													sm={6}
													md={3}
													className={classes.gridHeight}
												>
													<React.Fragment>
														<Autocomplete
															options={startYearDataList}
															onBlur={handleBlur}
															// freeSolo
															value={
																startYearDataList.filter((item) => {
																	return item === values.startYear;
																})[0] || null
															}
															getOptionSelected={(option, value) =>
																option === value
															}
															getOptionLabel={(option) => option}
															onChange={(event, value) => {
																if (value) {
																	setFieldValue("startYear", value);
																} else {
																	setFieldValue("startYear", "0");
																	setFieldValue("startMonth", "0");
																}
															}}
															renderInput={(params) => (
																<TextField
																	{...params}
																	label={"Year"}
																	variant="outlined"
																	helperText={
																		touched.startYear ? errors.startYear : ""
																	}
																	error={
																		touched.startYear &&
																		Boolean(errors.startYear)
																	}
																/>
															)}
														/>
													</React.Fragment>
												</GridItem>
												{values.currentInProgressFlag === 0 ? (
													<Hidden mdUp>
														<GridItem xs={12} sm={12} md={12}>
															<h4
																className={
																	classes.modalTitle + " " + classes.grayFont
																}
															>
																Ending In
															</h4>
														</GridItem>
													</Hidden>
												) : null}
												{values.currentInProgressFlag === 0 ? (
													<React.Fragment>
														<GridItem
															xs={6}
															sm={6}
															md={3}
															className={classes.gridHeight}
														>
															<React.Fragment>
																<Autocomplete
																	options={monthDataList}
																	onBlur={handleBlur}
																	// freeSolo
																	value={
																		monthDataList.filter((item) => {
																			return item.monthId === values.endMonth;
																		})[0] || null
																	}
																	getOptionLabel={(option) =>
																		option.monthName ? option.monthName : ""
																	}
																	getOptionSelected={(option, value) =>
																		option.monthName === value.monthName
																	}
																	onChange={(event, value) => {
																		console.log(value);
																		if (value) {
																			setFieldValue("endMonth", value.monthId);
																		} else {
																			setFieldValue("endMonth", "0");
																		}
																	}}
																	renderInput={(params) => (
																		<TextField
																			{...params}
																			label={"Month"}
																			variant="outlined"
																			helperText={
																				touched.endMonth ? errors.endMonth : ""
																			}
																			error={
																				touched.endMonth &&
																				Boolean(errors.endMonth)
																			}
																		/>
																	)}
																/>
															</React.Fragment>
														</GridItem>
														<GridItem
															xs={6}
															sm={6}
															md={3}
															className={classes.gridHeight}
														>
															<React.Fragment>
																<Autocomplete
																	options={endYearDataList}
																	onBlur={handleBlur}
																	value={
																		endYearDataList.filter((item) => {
																			return item === values.endYear;
																		})[0] || null
																	}
																	getOptionSelected={(option, value) =>
																		option === value
																	}
																	getOptionLabel={(option) => option}
																	onChange={(event, value) => {
																		// console.log(value)
																		if (value) {
																			setFieldValue("endYear", value);
																		} else {
																			setFieldValue("endYear", "0");
																			setFieldValue("endMonth", "0");
																		}
																	}}
																	renderInput={(params) => (
																		<TextField
																			{...params}
																			label={"Year"}
																			variant="outlined"
																			helperText={
																				touched.endYear ? errors.endYear : ""
																			}
																			error={
																				touched.endYear &&
																				Boolean(errors.endYear)
																			}
																		/>
																	)}
																/>
															</React.Fragment>
														</GridItem>
													</React.Fragment>
												) : null}
												<GridItem>
													<div className={classes.checkMargin}>
														<FormControlLabel
															classes={{
																label: classes.label,
															}}
															control={
																<Checkbox
																	//tabIndex={-1}
																	onChange={(event, value) => {
																		if (value) {
																			setFieldValue("currentInProgressFlag", 1);
																		} else {
																			setFieldValue("currentInProgressFlag", 0);
																		}
																	}}
																	name="currentInProgressFlag"
																	checkedIcon={
																		<Check className={classes.checkedIcon} />
																	}
																	icon={
																		<Check className={classes.uncheckedIcon} />
																	}
																	classes={{
																		checked: classes.checked,
																		root: classes.checkRoot,
																	}}
																	checked={
																		values.currentInProgressFlag === 1
																			? true
																			: false
																	}
																/>
															}
															label={
																<span
																	className={
																		classes.checkBoxLabel +
																		" " +
																		classes.grayFont
																	}
																>
																	I am currently working in this organization
																</span>
															}
														/>
													</div>
												</GridItem>
												<GridItem
													xs={12}
													sm={12}
													md={12}
													className={classes.gridPadding}
												>
													<TextField
														variant="outlined"
														id="achievement"
														label="Achievement"
														name="achievement"
														value={values.achievement}
														helperText={
															touched.achievement ? errors.achievement : ""
														}
														error={
															touched.achievement && Boolean(errors.achievement)
														}
														onChange={handleChange}
														onBlur={handleBlur}
														fullWidth
														InputProps={{
															multiline: true,
															rows: 5,
															// defaultValue: values.achievement,
														}}
													/>
												</GridItem>
												<GridItem
													xs={12}
													sm={12}
													md={12}
													className={classes.gridPadding}
												>
													<TextField
														variant="outlined"
														id="description"
														label="Description"
														name="description"
														value={values.description}
														helperText={
															touched.description ? errors.description : ""
														}
														error={
															touched.description && Boolean(errors.description)
														}
														onChange={handleChange}
														onBlur={handleBlur}
														fullWidth
														InputProps={{
															multiline: true,
															rows: 5,
															// defaultValue: values.description,
														}}
													/>
												</GridItem>
											</GridContainer>
										</CardBody>
									</Card>
								</DialogContent>
							</div>
							<DialogActions className={classes.modalFooterEdit}>
								<Button round onClick={handleSubmit} color="oppty">
									<b>Save</b>
								</Button>
							</DialogActions>
						</form>
					)}
				</Formik>
			</Dialog>
		</React.Fragment>
	);
};

const mapStateToProps = (state) => ({
	profileRecord: state.user.profileRecord,

	user: state.user,
});

export default connect(mapStateToProps, null)(ExperienceEditModal);
