import React, { useEffect, useRef, useState } from 'react';
import { Modal, Box, makeStyles } from '@material-ui/core';
import previewPostVideosModalStyles from 'assets/jss/material-kit-pro-react/components/previewPostVideosModalStyles';
import profilePageStyle from 'assets/jss/material-kit-pro-react/views/pageStyle/LandingPageFeed/CommentStyle.js';
import profileViewActivityPostCardStyles from 'assets/jss/material-kit-pro-react/views/componentStyle/profileView/myActivity/profileViewActivityPostCardStyles.js';
import PostImageCrop from 'views/Component/ImageCrop/PostImageCrop';
import Button from 'components/CustomButtons/Button.js';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconLibrary from 'views/Component/IconLibrary.js';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import ReactPlayer from 'react-player';
import clsx from 'clsx';

import closeIcon from 'assets/images/closeIcon.svg';
import cross from 'assets/img/cross.png';
import CustomDialog from '../../components/CustomDialog/CustomDialog.js';

const PreviewPostVideosModal = props => {
  const styles = makeStyles(profileViewActivityPostCardStyles)();
  const classes = makeStyles(previewPostVideosModalStyles)();
  const classesDialog = makeStyles(profilePageStyle)();

  const {
    uploadedVideos,
    isOpen,
    previewVideoIndex,
    setPreviewVideo,
    error,
    onVideosListItemDragStart,
    onVideosListItemDrop,
  } = props;

  useEffect(() => {
    if (!(Array.isArray(uploadedVideos) && uploadedVideos[previewVideoIndex])) {
      setPreviewVideo(0);
    }
    if (Array.isArray(uploadedVideos) && uploadedVideos.length == 0 && isOpen) {
      props.onClose();
    }
  }, [uploadedVideos]);

  const Clip = ({ videoSource }) => {
    const videoRef = useRef();
    const previousUrl = useRef(videoSource);

    useEffect(() => {
      if (previousUrl.current === videoSource) {
        return;
      }

      if (videoRef.current) {
        videoRef.current.load();
      }

      previousUrl.current = videoSource;
    }, [videoSource]);

    return (
      <video controls ref={videoRef}>
        <source src={videoSource} />
      </video>
    );
  };

  const dialogDefault = {
    title: '',
    description: '',
    buttonYes: '',
    buttonNo: '',
  };
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    dialogDefault,
  });

  const confirmProceed = (title, description, buttonYes, buttonNo) => {
    const dialogDefault = {
      title: title,
      description: description,
      buttonYes: buttonYes,
      buttonNo: buttonNo,
    };
    setConfirmDialog({
      isOpen: true,
      dialogDefault,
      onConfirm: () => {
        setConfirmDialog({
          isOpen: false,
          dialogDefault,
        });
      },
      onCancel: () => {
        props.onClose();
        props.selectVideos(null);
        setConfirmDialog({
          isOpen: false,
          dialogDefault,
        });
      },
    });
  };

  return (
    <React.Fragment>
      {confirmDialog && <CustomDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />}
      <Dialog
        classes={{
          root: classesDialog.modalRoot,
          paper: classesDialog.modalCommentEdit + ' ' + classesDialog.modal + ' ' + classes.dialogStyle,
        }}
        open={isOpen}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classesDialog.modalTitleEdit + ' ' + classes.modalHeader}
        >
          <Button
            simple
            className={classesDialog.modalCloseButton}
            key="close"
            aria-label="Close"
            onClick={() => {
              if (props.uploadedVideos && props.uploadedVideos.length > 0) {
                confirmProceed(
                  'Do you want..',
                  'Do you want to close this video preview? Changes you have made will not be saved.',
                  'Stay',
                  'Leave'
                );
              } else {
                props.onClose();
                props.selectVideos(null);
              }
            }}
          >
            <IconLibrary.Close />
          </Button>
          <h6 className={classesDialog.title}>Uploaded Videos</h6>
        </DialogTitle>
        <DialogContent id="classic-modal-slide-description" className={classesDialog.modalBody}>
          {/* <Modal open={isOpen}>
      <Box className={classes.videoPreviewModalBox}>
        <div className={classes.container}>
          <div className={classes.boxTitle}>
            <h1>Uploaded Videos</h1>
            <button onClick={() => {
              props.onClose();
            }}>
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" className="svg-inline--fa fa-times fa-w-11" role="img" viewBox="0 0 352 512">
                <path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path>
              </svg>
            </button>
          </div> */}
          <div className={classes.videoPreview}>
            {Boolean(uploadedVideos && uploadedVideos.length && uploadedVideos[previewVideoIndex]) ? (
              // <Clip
              // 	videoSource={URL.createObjectURL(
              // 		uploadedVideos[previewVideoIndex]
              // 	)}
              // />
              // <video controls>
              // 	<source src={uploadedVideos[previewVideoIndex]} />
              // </video>
              <div className={styles.videoThumbnail}>
                <ReactPlayer
                  url={
                    typeof uploadedVideos[previewVideoIndex] === 'string' &&
                    uploadedVideos[previewVideoIndex].includes('https://')
                      ? uploadedVideos[previewVideoIndex]
                      : URL.createObjectURL(uploadedVideos[previewVideoIndex])
                  }
                  controls={true}
                  width="100%"
                  pip={false}
                  playing={false}
                  style={{
                    backgroundColor: '#000',
                  }}
                  playIcon={
                    <PlayCircleOutlineOutlinedIcon
                      sx={{
                        color: '#b6b7b8',
                        margin: '5px',
                        width: '30%',
                        height: '30%',
                      }}
                    />
                  }
                />
              </div>
            ) : (
              <div style={{ textAlign: 'center', width: '100%' }}>
                <BrokenImageIcon
                  sx={{
                    color: '#b6b7b8',
                    margin: '5px',
                    width: '30%',
                    height: '30%',
                  }}
                />
              </div>
            )}
          </div>
          {/* <div className={classes.videosList}>
            <div className={classes.videosListContainer}>
              {uploadedVideos
                ? uploadedVideos.map((video, index) => (
                  <div
                    className={classes.videosListItem}
                    draggable={true}
                    onDragOver={(e) => e.preventDefault()}
                    onDragStart={(e) => {
                      e.target.style.opacity = 0.4
                      onVideosListItemDragStart(index)
                    }}
                    onDragEnd={(e) => {
                      e.target.style.opacity = 1
                    }}
                    onDrop={() => {
                      onVideosListItemDrop(index)
                    }}
                    key={"videosListItem" + index}
                  >
                    <img
                      className={classes.removeVideo}
                      src={cross}
                      alt=""
                      onClick={() => {
                        props.removeAnUploadedVideo(index);
                      }}
                      key={"removeVideo" + index}
                    />
                    <div
                      className={clsx({
                        [classes.videosListItemVideoContainer]: true,
                        [classes.selectedVideo]:
                          index === previewVideoIndex,
                      })}
                      onClick={() => {
                        setPreviewVideo(index);
                      }}
                      key={"videosListItemVideoContainer" + index}
                    >
                      <video
                        className={classes.videosListItemVideo}
                        key={"videosListItemVideo" + index}>
                        <source src={URL.createObjectURL(video)} key={"videosListItemVideoSource" + index} />
                      </video>
                    </div>
                    <div className={classes.videosListItemVideoContainerOverlay}></div>
                  </div>
                ))
                : ""}
            </div>
          </div>
        </div> */}
          {error ? <div className={classes.error}>{error}</div> : ''}
          {/* </Box>
    </Modal> */}
        </DialogContent>
        <div className={classes.saveButton}>
          <label htmlFor="upload-video">
            <span>Change</span>
            <input
              type="file"
              // multiple
              accept="video/*"
              name="video"
              id="upload-video"
              style={{ display: 'none' }}
              onClick={() => props.setError('')}
              onChange={e => {
                console.log(e);
                props.selectVideos([...e.target.files]);
              }}
            />
          </label>
          {/* </div>
				<div className={classes.saveButton}> */}
          <button onClick={() => props.onClose()}>Done</button>
        </div>
        <div className={classesDialog.modalFooter}></div>
      </Dialog>
    </React.Fragment>
  );
};

export default PreviewPostVideosModal;
