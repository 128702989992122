import React from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import CookieConsent from "react-cookie-consent";

import { makeStyles } from "@material-ui/core/styles";
import Header from "views/Component/Header.js";
import Footer from "views/Component/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";

import landingPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/LandingPage/landingPageStyle.js";
import InformationSection from "views/Page/LandingPage/InformationSections.js";
import InquirySection from "views/Page/LandingPage/InquirySections.js";
import PartnerSection from "views/Page/LandingPage/PartnerSections.js";
const useStyles = makeStyles(landingPageStyle);

const CryptoJS = require("crypto-js");

const LandingPage = ({ isAuthenticated, user, match }) => {
	const classes = useStyles();
	const history = useHistory();
	// const [viewId, setAcctId] = React.useState();

	React.useEffect(() => {
		setTimeout(() => {
			if (isAuthenticated) {
				// const hashId = CryptoJS.AES.encrypt(
				// 	JSON.stringify(user.acctId),
				// 	"oppty"
				// )
				// 	.toString()
				// 	.replace(/\//g, "*");
				// setAcctId(hashId);
			}
		}, 100);
	}, [isAuthenticated]);

	React.useEffect(() => {
		history.replace(match.url);
	}, []);

	return (
		<div>
			<Header loggedInStatus={false} color={"transparent"} />
			<Parallax image={require("assets/img/bg8.jpg")} filter="dark">
				<div className={classes.container}>
					<GridContainer>
						<GridItem xs={12} sm={12} md={12}>
							<h1 className={classes.title}>You Can Do Whatever You Want.</h1>
							<h4 className={classes.whiteColor}>
								Search for your dream opportunities here in OppTy!
							</h4>
							<br />
						</GridItem>
					</GridContainer>
				</div>
			</Parallax>

			<div className={classes.main + " " + classes.mainRaised}>
				<InformationSection />
				<PartnerSection />
				<InquirySection />
			</div>
			<Footer />

			{/* {isAuthenticated ? (
				user.acctTypeId === 2 ? (
					buildProfileFlag === 0 ? (
						<ProfileBuildModal acctId={viewId} />
					) : null
				) : buildProfileFlag === 0 ? (
					<ProfileBuildModal acctId={viewId} />
				) : null
			) : null} */}
			<CookieConsent
				location="bottom"
				buttonText="Close"
				style={{
					background: "#2B373B",
					height: "auto",
					alignItems: "center",
					justifyContent: "center",
				}}
				buttonStyle={{
					backgroundColor: "#ffffff",
					fontSize: "13px",
					fontWeight: "300",
					textTransform: "uppercase",
					width: "140px",
					height: "auto",
					border: "none",
					borderRadius: "30px",
					verticalAlign: "middle",
					padding: "12px 30px",

					boxShadow: "#ffffff",
				}}
			>
				<GridContainer justifyContent="center">
					<GridItem xs={12} sm={12} md={6}>
						<b>
							We use cookies to ensure that we give you the best experience on
							our website. By continuing to use our site, you accept our cookie
							policy.
						</b>
					</GridItem>
				</GridContainer>
			</CookieConsent>
		</div>
	);
};
const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
	user: state.auth.user,
	buildProfileFlag: state.auth.buildProfileFlag,
});

export default connect(mapStateToProps, null)(LandingPage);
