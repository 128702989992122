import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import Divider from "@material-ui/core/Divider";

import corporateJobManagementPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/CorporateJobManagementPage/corporateJobManagementPageStyle.js";
import NoActivityStatusModal from "../NoActivityStatusModal/NoActivityStatusModal.js";
import Pagination from "../../Component/PaginationWithPage.js";
import ApplicantProfileSection from "./ApplicantProfileSection.js";
import {
	getSelectJobPosts,
	getJobApplications,
} from "configurations/redux/jobManagementSlice.js";

import CircularProgress from "@material-ui/core/CircularProgress";
import { decryptData } from "configurations/configurations.js";
import Backdrop from "../../Component/Backdrop.js";

const useStyles = makeStyles(corporateJobManagementPageStyle);
const publicIp = require("public-ip");

export default function JobApplicantList(children) {
	const classes = useStyles();
	const location = useLocation();
	const dispatch = useDispatch();
	const isSelected = useSelector((state) => state.jobManagement.isSelected);
	const loadingApplicantList = useSelector(
		(state) => state.jobManagement.loadingApplicantList
	);
	const applicantList = useSelector(
		(state) => state.jobManagement.applicantList
	);
	const userStatus = useSelector((state) => state.auth.user);

	const [currentPage, setCurrentPage] = React.useState(1);
	const [profilePerPage, setProfilePerPage] = React.useState(9);

	const indexOfLastProfile = currentPage * profilePerPage;
	const indexOfFirstProfile = indexOfLastProfile - profilePerPage;

	const currentProfile = applicantList
		? applicantList.filter((item) => {
				return item.applicationStatusId !== 8;
		  })
		: // .slice(indexOfFirstProfile, indexOfLastProfile)
		  null;
	// console.log(currentProfile, applicantList);

	// React.useEffect(() => {
	// 	console.log(currentProfile, applicantList);
	// }, [applicantList]);

	const paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
		var indexOfFirstAppl = pageNumber * profilePerPage - profilePerPage;
		const getJobAppl = async () => {
			const requestProfile = {
				acctId: userStatus.acctId,
				email: userStatus.email,
				accessToken: localStorage.getItem("token"),
				jobPostId: children.jobPostId,
				ipAddr: localStorage.getItem("ipAddr"), //await publicIp.v4(),
				lastRecordSize: indexOfFirstAppl,
				requestRecordSize: profilePerPage,
			};
			console.log(requestProfile);
			dispatch(getJobApplications(requestProfile));
		};

		getJobAppl();
	};

	// console.log(currentProfile);

	const NoApplicant = () => {
		return (
			<GridItem xs={12} sm={12} md={12}>
				<Card className={classes.cardFrame}>
					<CardBody>
						<h5 className={classes.jobCreateTitle + " " + classes.textCenter}>
							No Applicant Yet
						</h5>
					</CardBody>
				</Card>
			</GridItem>
		);
	};
	const LoadingApplicant = () => {
		return (
			<GridItem xs={12} sm={12} md={12}>
				<Card className={classes.cardFrame}>
					<CardBody>
						<CircularProgress color="inherit" />
					</CardBody>
				</Card>
			</GridItem>
		);
	};
	return (
		<div>
			<GridContainer>
				<GridItem>
					<Divider />
					<h4 className={classes.title}>Applicant List</h4>
				</GridItem>
			</GridContainer>

			<GridContainer>
				{currentProfile && currentProfile.length > 0 ? (
					<React.Fragment>
						<ApplicantProfileSection profileList={currentProfile} />
						<GridItem>
							<div className={classes.paginationAligned}>
								<Pagination
									totalPosts={
										currentProfile
											? currentProfile[0].applicantCount != undefined
												? currentProfile[0].applicantCount
												: null
											: null
									}
									postsPerPage={profilePerPage}
									paginate={paginate}
									page={currentPage}
								/>
							</div>
						</GridItem>
					</React.Fragment>
				) : loadingApplicantList ? (
					<LoadingApplicant />
				) : (
					<NoApplicant />
				)}
			</GridContainer>
		</div>
	);
}
