import { container } from 'assets/jss/material-kit-pro-react.js';

const manageGroupPageStyles = () => ({
  body: {
    overflow: 'scroll',
    marginTop: '95px',
    paddingTop: '20px',
    paddingLeft: '20px',
    paddingRight: '20px',
    position: 'relative',
    height: 'calc(100vh - 95px)',
  },

  pageHeader: {
    position: 'relative',
    display: 'flex !important',
    alignItems: 'center',
    margin: '0',
    border: '0',
    minHeight: '100vh',
    height: 'auto',
    color: '#fff',

    '&:before,&:after': {
      position: 'absolute',
      zIndex: '1',
      width: '100%',
      height: '100%',
      display: 'block',
      left: '0',
      top: '0',
      content: '""',
    },
  },

  container: {
    ...container,
    zIndex: '5',
    '@media (min-width: 576px)': {
      flexBasis: '98%',
    },
    '@media (min-width: 768px)': {
      flexBasis: '98%',
    },
    '@media (min-width: 960px)': {
      flexBasis: '98%',
    },
    '@media (min-width: 992px)': {
      flexBasis: '98%',
    },
    '@media (min-width: 1200px)': {
      flexBasis: '98%',
    },
  },
  leftSideBar: {
    flexBasis: '19%',
    padding: '16px 10px',
  },
  rightSideBar: {
    flexBasis: '23%',
    padding: '16px 10px',
  },

  mainLeft: {
    flexBasis: '20%',
    marginTop: '20px',
    padding: '16px 10px',
  },
  groupAdmin: {
    marginTop: '15px',
    borderRadius: '15px',
    backgroundColor: '#fff',
    padding: '10px 15px',
  },

  groupAdminHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '30px',

    '& > p': {
      color: '#171725',
      fontSize: '13px',
      fontWeight: 400,
      fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
    },

    '& > button': {
      borderRadius: '10px',
      backgroundColor: '#F1F1F5',
      padding: '0 12px',
      minWidth: 'fit-content',
      height: '100%',
      color: '#696974',
      fontSize: '13px',
      fontWeight: 600,
      fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
      textTransform: 'capitalize',

      '& svg': {
        marginRight: '5px',
        width: '15px',
        height: '15px',
      },
    },
  },

  groupAdminMain: {
    marginTop: '10px',
  },

  groupAdminProfile: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    margin: '5px 0',

    '& img': {
      width: '40px',
      aspectRatio: 1,
      borderRadius: '50%',
    },
  },

  groupAdminName: {
    color: '#171725',
    fontSize: '13px',
    fontWeight: 600,
    fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
  },

  groupAdminUsername: {
    marginTop: '3px',
    color: '#92929D',
    fontSize: '11px',
    fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
  },

  suggestedGroup: {
    marginTop: '15px',
    borderRadius: '15px',
    backgroundColor: '#fff',
    padding: '10px 15px',
  },

  suggestedGroupHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '30px',

    '& > button': {
      color: '#0062FF',
    },
  },

  suggestedGroupHeaderTitle: {
    color: '#171725',
    fontSize: '13px',
    fontWeight: 500,
    fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
  },

  suggestedGroupDivider: {
    marginTop: '8px',
    marginBottom: '10px',
    marginLeft: '-7%',
    borderColor: '#F1F1F5',
    width: '115%',
  },

  suggestedGroupMain: {},

  suggestedGroupProfile: {
    display: 'flex',
    gap: '10px',
  },

  suggestedGroupProfilePic: {
    borderRadius: '50%',
    border: 'solid 1px #F1F1F5',
    width: '30px',
    height: '30px',
  },

  suggestedGroupName: {
    '& > h3': {
      color: '#171725',
      fontSize: '11px',
      fontWeight: 600,
      fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
    },

    '& > p': {
      color: '#92929D',
      fontSize: '10px',
      fontFamily: `"Roboto", "Helvetica", sans-serif !important`,

      '& > span': {},

      '& > svg': {
        margin: '0 4px',
      },
    },
  },

  suggestedGroupBanner: {
    overflow: 'hidden',
    marginTop: '10px',
    border: 'solid 1px #F1F1F5',
    borderRadius: '15px',

    '& > button': {
      width: '100%',
      color: '#44444F',
      fontSize: '12px',
      fontFamily: `"Roboto", "Helvetica", sans-serif !important`,

      '& svg': {
        marginRight: '10px',
        width: '12px',
        height: '12px',
      },
    },
  },

  suggestedGroupBannerImage: {
    height: '100px',

    '& > img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
    },
  },

  mainRight: {
    flexBasis: '38%',
    marginTop: '20px',
    padding: '16px 10px',
  },
  groupBanner: {
    position: 'relative',
    overflow: 'hidden',
    height: '200px',
    borderRadius: '15px',

    '& > img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
    },
  },

  editProfileBtn: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    border: 'solid 1px #fff',
    borderRadius: '10px',
    backgroundColor: '#17172580',
    width: '95px',
    height: '35px',
    color: '#fff',
    fontSize: '12px',
    fontWeight: 500,
    fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
    cursor: 'pointer',
  },

  groupTabsBox: {
    borderRadius: '15px',
    backgroundColor: '#fff',
    width: '100%',
  },

  manageGroupBox: {
    borderRadius: '15px',
    marginTop: '15px',
    backgroundColor: '#fff',
    padding: '10px 15px',
    width: '100%',

    '& p': {
      paddingTop: '5px',
      color: '#171725',
      fontSize: '14px',
      fontWeight: 400,
      fontFamily: `"Roboto", "Helvetica", sans-serif !important`,

      '& > svg': {
        margin: '0 4px',
      },
    },

    '& button': {
      height: 'fit-content',
      color: '#0062FF',
      fontSize: '12px',
      fontWeight: 400,
      fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
    },
  },

  membersAndEvents: {
    display: 'flex',
    gap: '15px',
    marginTop: '15px',

    '& > div': {
      borderRadius: '15px',
      backgroundColor: '#fff',
      padding: '10px 15px',
      width: '50%',
    },
  },

  members: {
    borderRadius: '15px',
    backgroundColor: '#fff',
    padding: '10px 15px',
    width: '100%',
    maxHeight: '160px',
  },

  membersHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '30px',

    '& > p': {
      color: '#171725',
      fontSize: '13px',
      fontWeight: 400,
      fontFamily: `"Roboto", "Helvetica", sans-serif !important`,

      '& > svg': {
        margin: '0 4px',
      },
    },

    '& > button': {
      minWidth: 'fit-content',
      height: '100%',
      padding: '0 6px',
      color: '#0062FF',
      fontSize: '11px',
      fontWeight: 400,
      fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
    },
  },

  membersMain: {
    display: 'flex',
    gap: '10px',
    flexWrap: 'wrap',
    marginTop: '10px',

    '& > img': {
      margin: '3px 0',
      width: '16%',
      aspectRatio: 1,
      borderRadius: '50%',
    },
  },

  events: {
    borderRadius: '15px',
    backgroundColor: '#fff',
    padding: '10px 15px',
    width: '100%',
    maxHeight: '160px',
  },

  eventsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '30px',

    '& > p': {
      color: '#171725',
      fontSize: '13px',
      fontWeight: 400,
      fontFamily: `"Roboto", "Helvetica", sans-serif !important`,

      '& > svg': {
        margin: '0 4px',
      },
    },

    '& > button': {
      padding: '0 6px',
      minWidth: 'fit-content',
      height: '100%',
    },
  },

  eventsMain: {
    marginTop: '10px',
  },

  eventContainer: {
    display: 'flex',
    gap: '10px',
    margin: '10px 0',
  },

  eventIcon: {},

  eventDetail: {
    '& > h3': {
      color: '#171725',
      fontSize: '13px',
      fontWeight: 600,
      fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
    },

    '& > p': {
      marginTop: '5px',
      color: '#92929D',
      fontSize: '12px',
      fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
    },

    '& > p > svg': {
      margin: '0 4px',
    },
  },

  aboutGroupTitle: {
    color: '#B5B5BE',
    fontSize: '15px',
    fontWeight: 500,
    marginTop: '15px',
    fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
  },
  aboutGroupText: {
    color: '#B5B5BE',
    fontSize: '13px',
  },

  groupCollapseHidden: {
    '-webkit-mask-image': 'linear-gradient(to bottom, black 50%, transparent 100%)',
  },

  popularPosts: {
    marginTop: '15px',
    borderRadius: '15px',
    backgroundColor: '#fff',
    padding: '10px 15px',
  },

  popularPostsHeader: {
    color: '#171725',
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
    paddingBottom: '10px',
  },

  popularPostsMain: {
    marginTop: '20px',
  },

  postContainer: {
    display: 'flex',
    gap: '15px',
    margin: '15px 0',
    height: '80px',
  },

  postImage: {
    overflow: 'hidden',
    borderRadius: '15px',
    width: '150px',

    '& > img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
    },
  },

  postDetails: {},

  postProfile: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',

    '& > img': {
      width: '30px',
      aspectRatio: 1,
      borderRadius: '50%',
    },
  },

  postProfileName: {
    color: '#171725',
    fontSize: '13px',
    fontWeight: 500,
    fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
  },

  postDate: {
    color: '#92929D',
    fontSize: '11px',
    fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
  },

  postText: {
    marginTop: '5px',
    color: '#696974',
    fontSize: '13px',
    fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
  },

  popularPostDivider: {
    marginTop: '25px',
    marginBottom: '10px',
    marginLeft: '-7%',
    borderColor: '#F1F1F5',
    width: '115%',
  },

  popularPostFooter: {
    textAlign: 'center',

    '& > button': {
      color: '#0062FF',
    },
  },

  groupMedia: {
    marginTop: '15px',
    borderRadius: '15px',
    backgroundColor: '#fff',
    padding: '10px 15px',
    maxHeight: '230px',
    fontSize: '14px',
  },

  groupMediaHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '30px',

    '& > p': {
      color: '#171725',
      fontSize: '14px',
      fontWeight: 400,
      fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
    },

    '& > button': {
      height: '100%',
      color: '#0062FF',
      fontSize: '12px',
      fontWeight: 400,
      fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
    },
  },

  groupMediaGrid: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    margin: '10px 0 0',
  },

  groupMediaGridItem: {
    overflow: 'hidden',
    borderRadius: '10px',
    padding: 0,
    width: '18.2%',
    maxHeight: '80px',

    '& > img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
    },
  },

  gridItemFG2: {
    flexGrow: 2,
    width: '36.4%',
  },

  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'white',
    border: '1px solid #979797',
    borderRadius: '1rem',
    padding: '1rem 0 1rem 1rem',
    boxShadow: 24,
    p: 4,
    maxHeight: '90vh',
    overflow: 'scroll',
    '&::-webkit-scrollbar-thumb': {
      width: 0,
    },
    '&::-webkit-scrollbar': {
      width: 0,
    },
    '&::-webkit-scrollbar-corner': {
      background: 'rgba(0,0,0,0)',
    },
  },
  modalDesktop: {
    width: '65vw',
    height: '90vh',
    overflow: 'hidden',
  },
  modalMobile: {
    width: '95vw',
    height: '90vh',
  },
  modalHeader: {
    position: 'relative',
  },

  modalCloseIcon: {
    position: 'absolute',
    right: '10px',
    top: 0,
    outline: 'none',
    border: 'none',
    background: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

export default manageGroupPageStyles;
