import {
	container,
	cardTitle,
	title,
	mlAuto,
	mrAuto,
	main,
	whiteColor,
	grayColor,
	blackColor,
	cardDetail,
	hexToRgb,
	opptyDesignColor,
} from "assets/jss/material-kit-pro-react.js";

const profileHeaderStyles = (theme) => ({
	main: {
		position: "relative",
		overflow: "hidden",
		borderRadius: "15px",
		backgroundColor: "#fff",
		width: "100%",
		marginTop: "10px",
	},
	coverPicture: {
		overflow: "hidden",
		height: "240px",

		"& > img": {
			width: "100%",
			height: "100%",
			objectFit: "cover",
			objectPosition: "center",
		},
	},

	coverPhotoOverlay: {
		position: "absolute",
		/* 		zIndex: 2, */
		top: 0,
		left: 0,
		right: 0,
		height: "240px",
		backgroundImage: "linear-gradient(to bottom, #fff0, #7a7a8780)",
		"&:hover,&:focus": {
			boxShadow:
				"0 14px 26px -12px rgba(" +
				hexToRgb(grayColor[0]) +
				", 0.42), 0 4px 23px 0px rgba(" +
				hexToRgb(blackColor) +
				", 0.12), 0 8px 10px -5px rgba(" +
				hexToRgb(grayColor[0]) +
				", 0.2)",
			cursor: "pointer",
		},
	},

	userDetails: {
		display: "flex",
		margin: "0 auto",
		position: "relative",
		transform: "translate3d(3%, -70%, 0)",
		"& > div": {
			marginLeft: "25px",
			paddingTop: "35px",
		},
	},

	ProfilePhoto: {
		border: "solid 5px #fff",
		borderRadius: "50%",
		width: "140px",
		aspectRatio: 1,
	},

	name: {
		color: "#FAFAFB",
		fontSize: "24px",
		fontWeight: 600,
		lineHeight: 1,
	},

	headline: {
		color: "#D5D5DC",
		fontSize: "16px",
		lineHeight: 1.4,
	},

	tabsContainer: {
		display: "flex",
		justifyContent: "end",
		backgroundColor: "#fff",
		paddingRight: "10%",
		height: "auto",
		width: "100%",
		marginTop: "-140px",
		textTransform: "uppercase",
		[theme.breakpoints.down("sm")]: {
			justifyContent: "center",
			paddingRight: "0",
			marginTop: "-100px",
			display: "block",
			/* 			height: "140px", */
		},
	},

	tab: {
		position: "relative",
		zIndex: 3,
		boxSizing: "border-box",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: "10px 0 0",
		/* width: "140px", */
		height: "70px",
		color: "#92929D",
		cursor: "pointer",
		transition: "0.2s ease-in-out",
	},

	tabActive: {
		color: "#6F52ED",
	},

	tabActiveBorder: {
		position: "absolute",
		left: 0,
		right: 0,
		bottom: "0",
		marginTop: "10px",
		border: "none",
		borderTop: "solid 3px #6F52ED",
		borderRadius: "10px 10px 0 0",
		width: "100%",
	},

	tabTitle: {
		fontSize: "14px",
		fontWeight: 700,
	},
});

export default profileHeaderStyles;
