import React from "react";
//Redux Imports
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { Link, useHistory } from "react-router-dom";
import { instanceUrl } from "configurations/configurations.js";
import axios from "axios";
import clsx from "clsx";

import {
	hashKey,
	decryptData,
	gaPageView,
} from "configurations/configurations.js";
import { getProfiles } from "configurations/redux/userSlice.js";
//Design Imports
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import classNames from "classnames";
//Page Components
import Header from "views/Component/Header.js";
import Footer from "views/Component/Footer.js";
//Material Components
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Button from "components/CustomButtons/Button.js";
import Divider from "@material-ui/core/Divider";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
//Material Icons
import CreateIcon from "@material-ui/icons/Create";
import MessageIcon from "@material-ui/icons/Message";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import {
	createChatroom,
	createChatroomReset,
} from "configurations/redux/messageChatroomSlice.js";
import {
	activateMasterCode,
	cleanMasterCode,
} from "configurations/redux/masterFunctionSlice.js";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import BottomNavBar from "components/BottomNavBar/BottomNavBar.js";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import NoActivityStatusModal from "../NoActivityStatusModal/NoActivityStatusModal.js";
import ProfilePictureSection from "./ProfileSection/ProfilePictureSection.js";
import CoverPictureSection from "./ProfileSection/CoverPictureSection.js";
import BasicProfileEditModal from "./ProfileSection/BasicProfileEditModal.js";
import EducationSection from "./ProfileSection/EducationSection.js";
import ExperienceSection from "./ProfileSection/ExperienceSection.js";
import TagSection from "./ProfileSection/TagSection.js";
import SyncContactModal from "./ProfileSection/SyncContactModal.js";
import PrivacySettingModal from "./ProfileSection/PrivacySettingModal.js";
import ResetPasswordModal from "./ProfileSection/ResetPasswordModal.js";
import BasicInfoSection from "./ProfileSection/BasicInfoSection.js";
import LoadingModal from "../../Component/LoadingModal.js";
import FavoriteProfileButton from "./ProfileSection/FavoriteProfileButton.js";
import UserTipSection from "./ProfileSection/UserTipSection.js";
import FontIcon from "views/Component/FontIcon.js";

import LoadingImage from "assets/img/Loading-pana.png";
import Backdrop from "../../Component/Backdrop.js";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/CreateOutlined";
import FontIconTest from "views/Component/FontIconTest.js";
import IconLibrary from "views/Component/IconLibrary.js";

import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { Hidden } from "@material-ui/core";
import CoverPictureEditModal from "./ProfileSection/CoverPictureEditModal.js";
import ProfileStrengthSection from "./ProfileSection/ProfileStrengthSection.js";
import ProfileMasterFunction from "./ProfileSection/ProfileMasterFunction.js";

import profilePageStyles from "../../../assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle";
const useStyles = makeStyles(profilePageStyles);
const publicIp = require("public-ip");

function useWindowSize() {
	const [size, setSize] = React.useState([0, 0]);
	React.useLayoutEffect(() => {
		function updateSize() {
			setSize([window.innerWidth, window.innerHeight]);
		}
		window.addEventListener("resize", updateSize);
		updateSize();
		return () => window.removeEventListener("resize", updateSize);
	}, []);
	return size;
}

const ProfilePage = ({ match }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [width, height] = useWindowSize();
	const [randomToolTipInfo, setRandomToolTipInfo] = React.useState();
	const isLoading = useSelector((state) => state.user.isLoading);
	const buildProfileFlag = useSelector((state) => state.auth.buildProfileFlag);
	const [countryList, setCountryList] = React.useState([]);
	const [locationList, setLocationList] = React.useState([]);

	const [basicProfileEditModal, setBasicProfileEditModal] = React.useState(
		false
	);
	const [profileNotBuildModal, setProfileNotBuildModal] = React.useState(false);
	const userStatus = useSelector((state) => state.auth.user);
	const user = useSelector((state) => state.user);
	const isUpdated = useSelector((state) => state.user.isUpdated);
	const isViewed = useSelector((state) => state.user.isViewed);
	const privacySetting = useSelector((state) => state.user.privacySetting);
	const bizId = useSelector((state) => state.business.bizId);
	const [isOpen, setOpen] = React.useState(false);
	const closeModal = () => {
		setOpen(false);
	};

	const showProfile =
		privacySetting &&
		privacySetting
			.filter((item) => {
				return item.settingName === "showProfile";
			})
			.map((item) => {
				return item.settingValue === 1;
			})[0];
	const showAboutMe =
		privacySetting &&
		privacySetting
			.filter((item) => {
				return item.settingName === "showAboutMe";
			})
			.map((item) => {
				return item.settingValue === 1;
			})[0];
	const showEdu =
		privacySetting &&
		privacySetting
			.filter((item) => {
				return item.settingName === "showEdu";
			})
			.map((item) => {
				return item.settingValue === 1;
			})[0];
	const showExp =
		privacySetting &&
		privacySetting
			.filter((item) => {
				return item.settingName === "showExp";
			})
			.map((item) => {
				return item.settingValue === 1;
			})[0];

	const showHeadline =
		privacySetting &&
		privacySetting
			.filter((item) => {
				return item.settingName === "showHeadline";
			})
			.map((item) => {
				return item.settingValue === 1;
			})[0];
	const [clientIp, setClientIp] = React.useState();

	const [changePrivacyModal, setChangePrivacyModal] = React.useState(false);

	const [syncContactModal, setSyncContactModal] = React.useState(false);

	const [changePasswordModal, setChangePasswordModal] = React.useState(false);

	const closeBasicProfileEditModal = () => {
		setBasicProfileEditModal(false);
	};
	const closeSyncContactModal = () => {
		setSyncContactModal(false);
	};
	const closeChangePrivacyModal = () => {
		setChangePrivacyModal(false);
	};
	const closeChangePasswordModal = () => {
		setChangePasswordModal(false);
	};
	const closeProfileNotBuildModal = () => {
		setProfileNotBuildModal(false);
	};
	const classes = useStyles();

	const profileRecord = useSelector((state) => state.user.profileRecord);

	const requestProfile = {
		acctId: decryptData(match.params.acctId),
		userId: userStatus.acctId,
		email: userStatus.email,
		ipAddr: localStorage.getItem("ipAddr"), //clientIp,
		accessToken: localStorage.getItem("token"),
	};

	(async () => {
		// setClientIp(localStorage.getItem("ipAddr")); //await publicIp.v4());
	})();

	React.useEffect(() => {
		if (clientIp) {
			// dispatch(getProfiles(requestProfile));
		}
	}, [clientIp]);

	React.useEffect(() => {
		gaPageView(window.location.pathname);
		dispatch(getProfiles(requestProfile));
		dispatch(cleanMasterCode());
		setTimeout(() => {
			if (buildProfileFlag === 0 && !isViewed) {
				// setProfileNotBuildModal(true);
				const response = {
					functionCode: "MF_PM_MODAL_BASIC_PROFILE",
					extension: null,
				};
				dispatch(activateMasterCode(response));
			}
		}, 500);
	}, []);

	React.useEffect(() => {
		const min = 1;
		const max = 20;
		const rand = Math.floor(min + Math.random() * (max - min));
		// console.log(rand);
		setRandomToolTipInfo(rand);
	}, []);

	React.useEffect(() => {
		let mounted = true;
		let CancelToken = axios.CancelToken;
		const source = CancelToken.source();
		const fetchCountryList = async () => {
			try {
				const res = await axios.get(`${instanceUrl}/api/md/get-all-country`, {
					cancelToken: source.token,
				});
				setCountryList(res.data.payload);
			} catch (error) {
				if (axios.isCancel(error)) {
					//console.log("unmount caused");
				} else {
					throw error;
				}
			}
		};
		const fetchLocationList = async () => {
			try {
				const res = await axios.get(`${instanceUrl}/api/md/get-all-location`, {
					cancelToken: source.token,
				});
				setLocationList(res.data.payload);
			} catch (error) {
				if (axios.isCancel(error)) {
					//console.log("unmount caused");
				} else {
					throw error;
				}
			}
		};
		if (mounted) {
			fetchCountryList();
			fetchLocationList();
		}
		return () => {
			mounted = false;
			source.cancel();
		};
	}, []);
	const getMessageNavigate = async (item) => {
		var chatroomList = [];
		chatroomList.push({ chatroomListId: -1, acctId: userStatus.acctId });
		chatroomList.push({ chatroomListId: -1, acctId: item });
		const messageApplicant = {
			acctId: userStatus.acctId,
			accessToken: localStorage.getItem("token"),
			email: userStatus.email,
			chatroomTitle: "business_" + item + "_" + bizId,
			chatroomList: chatroomList,
			receiverId: item,
			bizId: bizId,
			receiverBizId: 0,
			typeId: 2,
		};
		console.log(messageApplicant);
		dispatch(createChatroom(messageApplicant));
	};
	const profileStrength = useSelector((state) => state.user.profileStrength);
	const chatroomId = useSelector((state) => state.messageChatroom.chatroomId);
	const isCreated = useSelector((state) => state.messageChatroom.isCreated);
	React.useEffect(() => {
		console.log(chatroomId);
		if (isCreated && chatroomId > 0) {
			// history.push("/message-page/C" + chatroomId + "/");
			// window.open("/message-page/C" + chatroomId + "/", "_blank");
			window.open(`/message-page/${bizId}/C${chatroomId}/`, "_blank");
		}
		dispatch(createChatroomReset());
	}, [isCreated]);

	const ProfileBuildModal = ({ open, onClose }) => {
		return (
			<React.Fragment>
				<Dialog
					classes={{
						root: classes.modalRoot,
						paper: classes.modalProfileBuild,
					}}
					open={open}
					keepMounted
					onClose={onClose}
				>
					<DialogTitle
						id="classic-modal-slide-title"
						disableTypography
						className={classes.modalHeader}
					>
						<Button
							simple
							className={classes.modalCloseButton}
							key="close"
							aria-label="Close"
							onClick={onClose}
						>
							{" "}
							<Close className={classes.modalClose} />
						</Button>
						<h4 className={classes.textCenter + " " + classes.title}>
							<b>Profile Not Build Yet</b>
						</h4>
					</DialogTitle>
					<DialogContent className={classes.modalProfileBuildBody}>
						<Card className={classes.cardFrame}>
							<CardBody>
								<GridContainer
									justifyContent="center"
									alignContent="center"
									alignItems="center"
								>
									<GridItem xs={12} sm={12} md={9}>
										<b>
											<p
												className={
													classes.description + " " + classes.blackColor
												}
											>
												You haven't set up your profile info yet. Please set up
												your profile to use Oppty!
											</p>
										</b>
									</GridItem>
									<GridItem xs={12} sm={6} md={3}>
										<Button
											onClick={() => {
												onClose();
												setBasicProfileEditModal(true);
											}}
											fullWidth
											color="opptyWrap"
											round
										>
											<b>Set Up</b>
										</Button>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</DialogContent>
				</Dialog>
			</React.Fragment>
		);
	};
	// React.useEffect(() => {
	// 	console.log(user);
	// }, [user]);

	return (
		<>
			<Header />
			<div className={classes.body}>
				<div className={classNames(classes.pageHeader, classes.container)}>
					{/**Modal Section */}
					<ProfileMasterFunction />

					{!isViewed && (
						<div className={classes.coverIcon}>
							<Tooltip
								id="tooltip-top"
								classes={{ tooltip: classes.tooltip }}
								title="Change Cover Photo"
								placement="top"
							>
								<div
									className={clsx({
										[classes.iconShow]: isOpen,
										[classes.iconHide]: true,
									})}
								>
									<Button
										className={classes.followButton + " " + classes.editButton}
										justIcon
										round
										color="oppty"
										onClick={() => setOpen(true)}
									>
										<PhotoCameraIcon className={classes.followIcon} />
									</Button>
								</div>
							</Tooltip>
						</div>
					)}
					{isViewed ? null : (
						<CoverPictureEditModal
							open={isOpen}
							onClose={closeModal}
							backgroundPic={user.backgroundPic}
							backgroundPicFileName={user.backgroundPicFileName}
							backgroundTemplateFlag={user.backgroundTemplateFlag}
							user={user}
						/>
					)}
					{isLoading ? (
						<GridContainer justifyContent="center">
							<GridItem xs={12} sm={12} md={12}>
								<img
									src={LoadingImage}
									alt="Expired Session"
									className={classes.backGroundImage}
								/>
								<Backdrop />
							</GridItem>
						</GridContainer>
					) : (
						<React.Fragment>
							{isUpdated ? (
								<GridContainer justifyContent="center">
									<GridItem
										className={classes.coverPhotoGridItem}
										xs={12}
										sm={12}
										md={12}
									>
										<CoverPictureSection
											isViewed={isViewed}
											isLoading={isLoading}
											pictureSource={profileRecord}
											user={user}
										/>
									</GridItem>

									<GridItem xs={12} sm={12} md={8}>
										<React.Fragment>
											<div className={classes.profile}>
												<div className={classes.profilePic}>
													<ProfilePictureSection
														pictureSource={profileRecord}
														showProfile={showProfile}
													/>

													{!isViewed && (
														<div className={classes.edit}>
															<Tooltip
																classes={{ tooltip: classes.tooltip }}
																title="Edit Profile"
																placement="top"
															>
																<Button
																	justIcon
																	round
																	color="oppty"
																	className={classes.editButton}
																	onClick={() =>
																		dispatch(
																			activateMasterCode({
																				functionCode:
																					"MF_PM_MODAL_BASIC_PROFILE",
																				extension: null,
																			})
																		)
																	}
																>
																	<CreateIcon className={classes.followIcon} />
																</Button>
															</Tooltip>
														</div>
													)}
												</div>
												<div className={classes.name}>
													{isViewed ? (
														showProfile ? (
															<React.Fragment>
																<h3
																	className={
																		classes.title + " " + classes.userTitle
																	}
																>
																	{profileRecord.firstName}
																	{profileRecord.middleName
																		? " " + profileRecord.middleName
																		: null}{" "}
																	{profileRecord.surname}
																</h3>
																{/* <FontIcon
																					icon={faMapMarkerAlt}
																					text={
																						<span
																							className={classes.description}
																						>
																							{profileRecord.countryId &&
																								profileRecord.countryId !== 0 &&
																								countryList
																									.filter((item) => {
																										return (
																											item.countryId ===
																											profileRecord.countryId
																										);
																									})
																									.map((item) => {
																										return item.countryName;
																									})}
																							{profileRecord.locationId !== 0 &&
																								profileRecord.locationId &&
																								" , " +
																									locationList
																										.filter((item) => {
																											return (
																												item.countryId ===
																												profileRecord.countryId
																											);
																										})
																										.map((item) => {
																											return item.countryName;
																										})}
																						</span>
																					}
																				/> */}
																{profileRecord.countryId !== 0 &&
																	profileRecord.countryId && (
																		<div className={classes.locationTitle}>
																			<IconLibrary.Location />{" "}
																			<span className={classes.description}>
																				{profileRecord.countryId &&
																					profileRecord.countryId !== 0 &&
																					countryList
																						.filter(
																							(item) =>
																								item.countryId ===
																								profileRecord.countryId
																						)
																						.map((item) => item.countryName)}
																				{profileRecord.locationId !== 0 &&
																					profileRecord.locationId &&
																					" , " +
																					locationList
																						.filter(
																							(item) =>
																								item.locationId ===
																								profileRecord.locationId
																						)
																						.map((item) => item.locationName)}
																			</span>
																		</div>
																	)}
															</React.Fragment>
														) : (
															<React.Fragment>
																<h3
																	className={
																		classes.title + " " + classes.userTitle
																	}
																>
																	{profileRecord.firstName}
																	{profileRecord.middleName
																		? " " + profileRecord.middleName
																		: null}{" "}
																	{profileRecord.surname}
																</h3>
																<h5 className={classes.description}>
																	"Location" Detail is currently private.
																</h5>
															</React.Fragment>
														)
													) : (
														<React.Fragment>
															<h3
																className={
																	classes.title + " " + classes.userTitle
																}
															>
																{profileRecord.firstName}
																{profileRecord.middleName
																	? " " + profileRecord.middleName
																	: null}{" "}
																{profileRecord.surname}
															</h3>
															{profileRecord.countryId !== 0 &&
																profileRecord.countryId && (
																	<div className={classes.locationTitle}>
																		<IconLibrary.Location />{" "}
																		<span className={classes.description}>
																			{profileRecord.countryId &&
																				profileRecord.countryId !== 0 &&
																				countryList
																					.filter(
																						(item) =>
																							item.countryId ===
																							profileRecord.countryId
																					)
																					.map((item) => item.countryName)}
																			{profileRecord.locationId !== 0 &&
																				profileRecord.locationId &&
																				" , " +
																				locationList
																					.filter(
																						(item) =>
																							item.locationId ===
																							profileRecord.locationId
																					)
																					.map((item) => item.locationName)}
																		</span>
																	</div>
																)}
														</React.Fragment>
													)}
													<h3
														className={
															classes.title + " " + classes.headlineDescription
														}
													>
														{isViewed ? (
															showProfile ? (
																showHeadline ? (
																	profileRecord.headline
																) : (
																	<h4 className={classes.title}>
																		"{profileRecord.firstName}" is not yet ready
																		to show "Headline" detail
																	</h4>
																)
															) : (
																<h4 className={classes.title}>
																	"Headline" Detail is currently private.
																</h4>
															)
														) : (
															profileRecord.headline
														)}
													</h3>
												</div>
											</div>
											{
												isViewed && (
													<FavoriteProfileButton
														profileId={decryptData(match.params.acctId)}
													/>
												)
												// : (
												// 	<div className={classes.follow}>
												// 		<Tooltip
												// 			title="Edit the profile"
												// 			placement="top"
												// 			classes={{ tooltip: classes.tooltip }}
												// 		>
												// 			<Button
												// 				justIcon
												// 				round
												// 				color="oppty"
												// 				onClick={() => setBasicProfileEditModal(true)}
												// 				className={classes.followButton}
												// 			>
												// 				<CreateIcon className={classes.followIcon} />
												// 			</Button>
												// 		</Tooltip>
												// 	</div>
												// )
											}

											{userStatus.acctId !==
												decryptData(match.params.acctId) && (
													<div className={classes.message}>
														<Tooltip
															id="tooltip-top"
															classes={{ tooltip: classes.tooltip }}
															title="Message This Candidate"
															placement="top"
														>
															<Button
																className={classes.followButton}
																justIcon
																round
																color="oppty"
																onClick={() =>
																	getMessageNavigate(
																		decryptData(match.params.acctId)
																	)
																}
															>
																{/* <MessageIcon className={classes.followIcon} /> */}
																<IconLibrary.Mail />
															</Button>
														</Tooltip>
													</div>
												)}

											{/* 							<BasicProfileEditModal
														open={basicProfileEditModal}
														onClose={closeBasicProfileEditModal}
													/> */}
										</React.Fragment>
									</GridItem>
									{!isViewed && (
										<GridItem xs={12} sm={12} md={12}>
											<UserTipSection
												randomNumber={randomToolTipInfo}
												className={classes.userTipIcon}
											/>
										</GridItem>
									)}

									<GridItem xs={12} sm={12} md={7}>
										{isViewed ? (
											showProfile ? (
												showAboutMe ? (
													<Card
														className={
															classes.cardBody + " " + classes.cardFrame
														}
													>
														<CardBody>
															<h4 className={classes.title}>About Me</h4>

															<p className={classes.title}>
																{" "}
																{profileRecord.aboutMe}
															</p>
														</CardBody>
													</Card>
												) : (
													<Card
														className={
															classes.privacyTrueCard + " " + classes.cardFrame
														}
													>
														<CardBody>
															<h5 className={classes.title}>
																"{profileRecord.firstName}" is not yet ready to
																show "About Me" detail
															</h5>
														</CardBody>
													</Card>
												)
											) : (
												<Card
													className={
														classes.privacyTrueCard + " " + classes.cardFrame
													}
												>
													<CardBody>
														<h5 className={classes.title}>
															"About Me" Detail is currently private.
														</h5>
													</CardBody>
												</Card>
											)
										) : (
											<Card className={classes.cardFrame}>
												<CardBody>
													<GridContainer
														justifyContent="space-between"
														alignContent="center"
														alignItems="center"
													>
														<GridItem xs={9} sm={9} md={9}>
															<h4 className={classes.title}>About Me</h4>
														</GridItem>
														<GridItem
															xs={2}
															sm={2}
															md={2}
															lg={2}
															className={classes.buttonColor}
														>
															{isViewed ? null : (
																<Tooltip
																	classes={{ tooltip: classes.tooltip }}
																	placement="top"
																	title={
																		<p className={classes.tooltipDescription}>
																			Edit Profile
																		</p>
																	}
																>
																	<Button
																		className={classes.buttonIconMargin}
																		justIcon
																		style={{ boxShadow: "none" }}
																		color="white"
																		onClick={() => {
																			/* setBasicProfileEditModal(true); */

																			/* 					const extension =
																				profileStrength[0].strengthList
																					.filter((item) => {
																						return (
																							item.improvementTip
																								.functionCode ===
																							"MF_PM_MODAL_BASIC_PROFILE"
																						);
																					})
																					.filter((item) => {
																						return item.value === 0;
																					}).length > 1
																					? profileStrength[0].strengthList
																							.filter((item) => {
																								return (
																									item.improvementTip
																										.functionCode ===
																									"MF_PM_MODAL_BASIC_PROFILE"
																								);
																							})
																							.filter((item) => {
																								return item.value === 0;
																							})
																							.map((item) => {
																								return item.improvementTip;
																							})
																					: null; */
																			dispatch(
																				activateMasterCode({
																					functionCode:
																						"MF_PM_MODAL_BASIC_PROFILE",
																					extension: null,
																				})
																			);
																		}}
																	>
																		<IconLibrary.Pen />
																	</Button>
																</Tooltip>
															)}
														</GridItem>
													</GridContainer>
													{profileRecord.aboutMe ? (
														<p className={classes.description}>
															{profileRecord.aboutMe}
														</p>
													) : (
														<Card className={classes.cardBorder}>
															<CardBody>
																<h5 className={classes.smallDescription}>
																	No information filled yet. Please update your
																	About Me Records.
																</h5>
															</CardBody>
														</Card>
													)}
												</CardBody>
											</Card>
										)}

										{isViewed ? (
											showProfile ? (
												showExp ? (
													<ExperienceSection />
												) : (
													<Card className={classes.privacyTrueCard}>
														<CardBody>
															<h5 className={classes.title}>
																"{profileRecord.firstName}" is not yet ready to
																show "Experience" detail
															</h5>
														</CardBody>
													</Card>
												)
											) : (
												<Card className={classes.privacyTrueCard}>
													<CardBody>
														<h5 className={classes.title}>
															"Experience" Detail is currently private.
														</h5>
													</CardBody>
												</Card>
											)
										) : (
											<ExperienceSection />
										)}
										{isViewed ? (
											showProfile ? (
												showEdu ? (
													<EducationSection />
												) : (
													<Card className={classes.privacyTrueCard}>
														<CardBody>
															<h5 className={classes.title}>
																"{profileRecord.firstName}" is not yet ready to
																show "Education" detail
															</h5>
														</CardBody>
													</Card>
												)
											) : (
												<Card className={classes.privacyTrueCard}>
													<CardBody>
														<h5 className={classes.title}>
															"Education" Detail is currently private.
														</h5>
													</CardBody>
												</Card>
											)
										) : (
											<EducationSection />
										)}
									</GridItem>

									<GridItem xs={12} sm={12} md={5}>
										<TagSection />
										<BasicInfoSection />

										{isViewed ? null : (
											<React.Fragment>
												<ProfileStrengthSection />
												<Card className={classes.cardFrame}>
													<CardBody>
														<GridContainer
															justifyContent="space-between"
															alignContent="center"
															alignItems="center"
														>
															<GridItem xs={8} sm={8} md={8}>
																<h4 className={classes.title}>
																	View Your Application Status
																</h4>
															</GridItem>
															<GridItem
																className={classes.actionButton}
																xs={4}
																sm={4}
																md={4}
															>
																<Link to={{ pathname: `/job-status-page` }}>
																	<Button
																		className={classes.buttonHeight}
																		fullWidth
																		round
																		color="oppty"
																	>
																		<Hidden xsDown>View More</Hidden>
																		<Hidden smUp>View</Hidden>
																	</Button>
																</Link>
															</GridItem>
														</GridContainer>
													</CardBody>
												</Card>

												<Card className={classes.cardFrame}>
													<CardBody>
														<GridContainer
															justifyContent="space-between"
															alignContent="center"
															alignItems="center"
														>
															<GridItem xs={8} sm={8} md={8}>
																<h4 className={classes.title}>
																	Import Your Contacts
																</h4>
															</GridItem>
															<GridItem
																className={classes.actionButton}
																xs={4}
																md={4}
																sm={4}
															>
																<Button
																	className={classes.buttonHeight}
																	fullWidth
																	round
																	color="oppty"
																	onClick={() => setSyncContactModal(true)}
																>
																	Import
																</Button>
															</GridItem>
														</GridContainer>
													</CardBody>
												</Card>

												<Card className={classes.cardFrame}>
													<CardBody>
														<GridContainer
															justifyContent="space-between"
															alignContent="center"
															alignItems="center"
														>
															<GridItem xs={8} sm={8} md={8}>
																<h4 className={classes.title}>
																	Change Privacy Settings
																</h4>
															</GridItem>
															<GridItem
																className={classes.actionButton}
																xs={4}
																md={4}
																sm={4}
															>
																<Button
																	className={classes.buttonHeight}
																	fullWidth
																	round
																	color="oppty"
																	onClick={() => {
																		const extension =
																			profileStrength[0].strengthList.filter(
																				(item) =>
																					item.improvementTip.functionCode ===
																					"MF_PM_MODAL_PRIVACY_SETTING"
																			)[0].value === 0
																				? profileStrength[0].strengthList.filter(
																					(item) =>
																						item.improvementTip
																							.functionCode ===
																						"MF_PM_MODAL_PRIVACY_SETTING"
																				)[0].improvementTip
																				: null;
																		dispatch(
																			activateMasterCode({
																				functionCode:
																					"MF_PM_MODAL_PRIVACY_SETTING",
																				extension: extension,
																			})
																		);
																	}}
																>
																	Change
																</Button>
															</GridItem>
														</GridContainer>
													</CardBody>
												</Card>

												{userStatus.acctTypeId === 1 ? (
													<Card className={classes.cardFrame}>
														<CardBody>
															<GridContainer
																justifyContent="space-between"
																alignContent="center"
																alignItems="center"
															>
																<GridItem xs={8} sm={8} md={8}>
																	<h4 className={classes.title}>
																		Reset Password
																	</h4>
																</GridItem>
																<GridItem
																	className={classes.actionButton}
																	xs={4}
																	md={4}
																	sm={4}
																>
																	<Button
																		className={classes.buttonHeight}
																		fullWidth
																		round
																		color="oppty"
																		onClick={() => setChangePasswordModal(true)}
																	>
																		Reset
																	</Button>
																</GridItem>
															</GridContainer>
														</CardBody>
													</Card>
												) : null}
											</React.Fragment>
										)}
									</GridItem>
									{/* <PrivacySettingModal
											open={changePrivacyModal}
											onClose={closeChangePrivacyModal}
										/> */}

									<SyncContactModal
										open={syncContactModal}
										onClose={closeSyncContactModal}
									/>
									<ResetPasswordModal
										open={changePasswordModal}
										onClose={closeChangePasswordModal}
									/>
									<ProfileBuildModal
										open={profileNotBuildModal}
										onClose={closeProfileNotBuildModal}
									/>
									<Footer />
								</GridContainer>
							) : (
								<React.Fragment>
									<GridItem xs={12} sm={12} md={12}>
										<img
											src={LoadingImage}
											className={classes.backGroundImage}
											alt="Expired Session"
										/>
									</GridItem>
									<NoActivityStatusModal />
								</React.Fragment>
							)}
						</React.Fragment>
					)}
				</div>
			</div>
		</>
	);
};
{
	/* <NoActivityStatusModal /> */
}

export default ProfilePage;
