import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { departmentViewRoadmap, viewRoadmapRoleByRoleId } from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";
import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Department_Urban_Planning from "assets/img/Career_Roadmap/Real_Estate/Department_Urban_Planning_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentUrbanPlanning() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Urban_Planning;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "ad",
				shape: "poly",
				coords: [75,394,67,397,63,399,60,403,58,407,55,412,56,450,56,455,59,459,61,462,65,467,73,470,166,470,172,468,176,466,180,462,183,457,185,451,185,414,184,408,181,403,177,399,171,395,165,394,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "154",
				shape: "poly",
				coords: [226,392,208,392,203,395,200,397,197,402,196,407,195,458,197,466,199,470,204,473,212,475,387,475,392,475,396,472,401,468,402,460,402,403,399,397,394,394,388,393,382,392,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "155",
				shape: "poly",
				coords: [486,391,481,393,479,395,476,399,474,403,473,458,475,465,477,468,480,471,487,473,662,474,670,473,675,470,679,465,681,459,681,407,679,399,675,395,671,392,664,391,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "156",
				shape: "poly",
				coords: [766,393,759,395,755,398,753,402,752,407,751,460,753,467,755,469,758,471,762,473,768,475,940,476,947,476,951,473,955,470,958,465,959,459,959,408,958,401,956,398,952,395,948,393,943,392,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "157",
				shape: "poly",
				coords: [1047,392,1040,393,1037,395,1034,398,1031,403,1030,408,1030,460,1031,466,1033,470,1037,472,1043,475,1222,475,1229,473,1233,470,1235,467,1237,461,1237,409,1237,402,1234,398,1230,395,1226,393,1221,392,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "158",
				shape: "poly",
				coords: [1330,392,1321,392,1316,394,1313,396,1311,399,1309,404,1308,409,1308,459,1309,466,1312,469,1315,473,1324,476,1499,475,1505,474,1508,471,1511,468,1514,465,1515,459,1515,407,1514,401,1512,399,1507,396,1503,393,1498,392,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "159",
				shape: "poly",
				coords: [1636,393,1633,393,1629,394,1627,396,1625,399,1623,402,1622,407,1622,460,1623,466,1624,468,1627,472,1632,474,1638,475,1811,475,1816,475,1820,473,1824,469,1827,465,1829,458,1829,407,1828,401,1824,396,1821,394,1817,393,1811,392,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "ad") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo)
			dispatch(viewRoadmapRoleByRoleId(requestInfo));
			
			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// console.log(coords.x + "," + coords.y + ",");
		// navigator.clipboard.writeText(coords.x + "," + coords.y + ",");
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
