import { container } from "assets/jss/material-kit-pro-react.js";

const discoverGroupsPageStyles = () => ({
  body: {
    overflow: "scroll",
    marginTop: "95px",
    height: "calc(100vh - 95px)",
  },

  pageHeader: {
    position: "relative",
    display: "flex !important",
    alignItems: "center",
    margin: "0",
    border: "0",
    minHeight: "100vh",
    height: "auto",
    color: "#fff",

    "&:before,&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: '""',
    },
  },

  container: {
    ...container,
    zIndex: "5",
    "@media (min-width: 576px)": {
      flexBasis: "98%",
    },
    "@media (min-width: 768px)": {
      flexBasis: "98%",
    },
    "@media (min-width: 960px)": {
      flexBasis: "98%",
    },
    "@media (min-width: 992px)": {
      flexBasis: "98%",
    },
    "@media (min-width: 1200px)": {
      flexBasis: "98%",
    },
  },
  leftSideBar: {
    flexBasis: "19%",
  },

  main: {
    flexBasis: "81%",
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "transparent",
  },

  heading: {
    paddingLeft: "20px",
    color: "#171725",
    fontSize: "24px",
    fontWeight: 500,
  },

  headerRight: {
    display: "flex",
  },

  headerSearchGroup: {
    display: "flex",
    overflow: "hidden",
    alignItems: "center",
    marginRight: "15px",
    borderRadius: "10px",
    backgroundColor: "#fff",
    padding: "2px 5px 1px 0",
    width: "330px",

    "& > input": {
      border: "none",
      paddingLeft: "20px",
      width: "85%",
      height: "100%",
      color: "#171725",
      fontSize: "14px",
      fontWeight: 400,
    },

    "& > button": {
      border: "none",
      backgroundColor: "transparent",
      width: "15%",
      color: "#92929D",
    },
  },

  addGroupBtn: {
    borderRadius: "10px",
    backgroundColor: "#0062FF",
    padding: "10px 25px 10px 20px",
    color: "#fff",
    fontSize: "14px",
    fontWeight: 600,

    "&:hover": {
      color: "#0062FF",
    },
  },

  groupsTypesContainer: {
    display: "flex",
    gap: "15px",
    marginTop: "30px",
    width: "100%",
  },
});

export default discoverGroupsPageStyles;
