import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

import Carousel from "react-slick";
import { Link } from "react-router-dom";

import Card from "components/Card/Card.js";
import { CardMedia, CardContent, CardHeader } from "@material-ui/core";
import Skeleton from "@mui/material/Skeleton";
import communityPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/CommunityPage/communityPageStyle.js";

const useStyles = makeStyles(communityPageStyle);

export default function CommunityCardAds() {
	const classes = useStyles();
	const dispatch = useDispatch();

	const cardAds = useSelector((state) => state.community.cardAds);
	const adsLoading = useSelector((state) => state.community.cardAdsLoading);

	const partnerCarouselSettings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
	};
	const CarouselLoading = (
		<div>
			<Card
				className={classes.cardClick + " " + classes.cardFrame}
				style={{ height: "290px" }}
			>
				<CardHeader
					avatar={
						<Skeleton
							animation="wave"
							variant="circular"
							width={40}
							height={40}
						/>
					}
					title={
						<Skeleton
							animation="wave"
							height={10}
							width="80%"
							style={{ marginBottom: 6 }}
						/>
					}
				/>
				<Skeleton
					sx={{ height: "calc(290px - 138px)" }}
					/* className={classes.cardMediaSkeleton} */
					animation="wave"
					variant="rectangular"
				/>
				<CardContent>
					<React.Fragment>
						<Skeleton
							animation="wave"
							height={10}
							style={{ marginBottom: 6 }}
						/>
						<Skeleton animation="wave" height={10} width="80%" />
					</React.Fragment>
				</CardContent>
			</Card>
		</div>
	);

	const CarouselCardAds =
		adsLoading && cardAds && cardAds.length < 1
			? CarouselLoading
			: cardAds.length
			? cardAds.map((item) => {
					return (
						<div key={item.adsId}>
							<Link
								className={classes.textDeco}
								target="_blank"
								to={{
									pathname: item.adsURL,
								}}
								color="transparent"
							>
								<Card
									className={
										classes.cardClick +
										" " +
										classes.cardFrame +
										" " +
										classes.cardAds
									}
									style={{
										backgroundImage: `url(${item.adsMedia})`,
									}}
								/>
							</Link>
						</div>
					);
			  })
			: CarouselLoading;

	return <Carousel {...partnerCarouselSettings}>{CarouselCardAds}</Carousel>;
}
