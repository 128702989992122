import React from 'react';
import { useSelector } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

const GroupForm = ({ handleChange, values, errors, touched, handleBlur, setFieldValue }) => {
  const user = useSelector(state => state.user);
  const allIndustry = [...user.allIndustry];
  if (values.customIndustryName && values.industryId === 0) {
    allIndustry.push({
      industryId: 0,
      industryName: values.customIndustryName,
    });
  }
  return (
    <GridContainer spacing={3} justifyContent="center">
      <AvatarUpload
        name="profilePic"
        {...{
          values,
          touched,
          errors,
          setFieldValue,
        }}
      />
      <GridItem xs={12}>
        <StringInput
          name="groupName"
          label="Group Name"
          placeholder="E.g. Graphic Designers in Singapore"
          {...{
            values,
            touched,
            errors,
            setFieldValue,
            handleBlur,
            maxLength: { maxLength: 200 },
          }}
        />
      </GridItem>

      <GridItem xs={12}>
        <StringInput
          name="groupDescription"
          label="Group description"
          placeholder="Describe your group in under 1000 words"
          {...{
            values,
            touched,
            errors,
            setFieldValue,
            handleBlur,
            maxLength: { maxLength: 1000 },
          }}
        />
      </GridItem>
      <GridItem xs={12}>
        <Autocomplete
          options={allIndustry}
          clearOnBlur={false}
          getOptionLabel={option => option.industryName}
          value={allIndustry.find(c => c.industryId === values.industryId) ?? null}
          onChange={(event, value) => {
            setFieldValue('industryId', value?.industryId);
            setFieldValue('customIndustryName', null);
          }}
          onInputChange={(event, value, reason) => {
            console.log(reason);
            setFieldValue('customIndustryName', value);
            setFieldValue('industryId', 0);
          }}
          renderInput={params => (
            <StringInput
              name={values.customIndustryName ? 'customIndustryName' : 'industryId'}
              label="Industry"
              value={
                values.customIndustryName ?? allIndustry.find(c => c.industryId === values.industryId)?.industryName
              }
              onChange={() => {}}
              placeholder="Choose industry"
              {...{
                values,
                touched,
                errors,
                setFieldValue,
                handleBlur,
                maxLength: { maxLength: 100 },
                ...params,
              }}
            />
          )}
        />
      </GridItem>
      <GridItem xs={12}>
        <Autocomplete
          options={user.allCountries}
          getOptionLabel={option => option.countryName}
          onChange={(event, value) => setFieldValue('countryId', value?.countryId)}
          value={user.allCountries.find(c => c.countryId === values.countryId) ?? null}
          renderInput={params => (
            <StringInput
              name="countryId"
              label="Country"
              value={user.allCountries.find(c => c.countryId === values.countryId)?.countryName}
              placeholder="Choose country of your group"
              onChange={() => {}}
              {...{
                values,
                touched,
                errors,
                setFieldValue,
                handleBlur,
                ...params,
              }}
            />
          )}
        />
      </GridItem>
      <GridItem xs={12}>
        <StringInput
          name="groupRule"
          label="Group rules"
          placeholder="Establish the rules and regulations for the group members"
          {...{
            values,
            touched,
            errors,
            setFieldValue,
            handleBlur,
            maxLength: { maxLength: 1000 },
          }}
        />
      </GridItem>
      <GridItem xs={12}>
        <FormControl>
          <FormLabel id="visibleId">Group Privacy</FormLabel>
          <RadioGroup
            aria-labelledby="visibleId"
            name="visibleId"
            value={values.visibleId}
            onChange={event => {
              setFieldValue('visibleId', Number(event.target.value));
            }}
          >
            <FormControlLabel
              value={1}
              control={<Radio />}
              label={
                <CustomLabel
                  text="Public"
                  subText="Group is visible in search results and can be seen by non-members"
                />
              }
            />
            <FormControlLabel
              value={2}
              control={<Radio />}
              label={
                <CustomLabel
                  text="Private"
                  subText="Group is hidden from search results for non-members, and is not visible to non-members"
                />
              }
            />
          </RadioGroup>
        </FormControl>
      </GridItem>
      <GridItem xs={12}>
        <FormControl>
          <FormLabel id="permissionId">Permissions</FormLabel>
          <FormGroup
            onChange={event => {
              const { checked, value } = event.target;
              setFieldValue(
                'permissionId',
                checked
                  ? [...values.permissionId, value]
                  : values.permissionId.filter(permission => permission !== value)
              );
            }}
          >
            <FormControlLabel
              value={1}
              control={<Checkbox checked={Boolean(values.permissionId.find(v => v == 1))} />}
              label={
                <CustomLabel
                  text="Allow anyone to join"
                  subText="Users can join this group without requiring admin approval."
                />
              }
            />
            <FormControlLabel
              value={2}
              control={<Checkbox checked={Boolean(values.permissionId.find(v => v == 2))} />}
              label={
                <CustomLabel
                  text="Auto-approve posts"
                  subText="Allow members to post content on the group without requiring admin approval."
                />
              }
            />
          </FormGroup>
        </FormControl>
      </GridItem>
    </GridContainer>
  );
};
export default GroupForm;

const StringInput = ({
  name,
  label,
  placeholder,
  values,
  touched,
  errors,
  setFieldValue,
  handleBlur,
  maxLength,
  ...rest
}) => (
  <TextField
    variant="outlined"
    id={name}
    label={label}
    name={name}
    inputProps={maxLength}
    multiline
    InputLabelProps={{ shrink: true }}
    value={values[name]}
    helperText={touched[name] ? errors[name] : ''}
    error={touched[name] && Boolean(errors[name])}
    onChange={event => {
      setFieldValue(name, event.target.value);
    }}
    onBlur={handleBlur}
    placeholder={placeholder}
    fullWidth
    {...rest}
  />
);

const AvatarUpload = ({ name, values, errors, touched, setFieldValue }) => (
  <label htmlFor={name}>
    <Input
      inputProps={{ accept: 'image/*' }}
      id={name}
      type="file"
      style={{ display: 'none' }}
      onChange={event => {
        event.target.files[0] &&
          setFieldValue(name, {
            blob: event.target.files[0],
            objURL: URL.createObjectURL(event.target.files[0]),
          });
      }}
    />
    <IconButton style={{ padding: 0 }} aria-label="upload picture" component="span">
      <Avatar
        src={values[name]?.objURL ?? values[name] ?? ''}
        style={{
          width: '120px',
          height: '120px',
          textAlign: 'center',
        }}
      />
    </IconButton>
    {touched[name] && Boolean(errors[name]) && (
      <Typography noWrap style={{ position: 'absolute', left: 0 }} variant="body2" color="error.main">
        {errors[name]}
      </Typography>
    )}
  </label>
);

const CustomLabel = ({ text, subText }) => (
  <div>
    <Typography style={{ color: '#000000', fontSize: '0.85rem', fontWeight: 800 }}>{text}</Typography>
    <Typography style={{ color: '#6D7278', fontSize: '0.8rem' }}>{subText}</Typography>
  </div>
);
