import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
	departmentViewRoadmap,
	viewRoadmapRoleByRoleId,
} from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Department_The_Engine_Department from "assets/img/Career_Roadmap/Marine_Engineering/Department_The_Engine_Department_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentTheEngineDepartment() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_The_Engine_Department;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "ted",
				shape: "poly",
				coords: [82,380,74,382,71,386,67,391,65,396,66,448,67,452,71,458,76,462,84,463,191,463,198,460,202,457,205,453,208,445,208,397,205,389,202,386,196,382,190,380,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "299",
				shape: "poly",
				coords: [239,379,231,382,228,383,226,386,224,393,225,441,226,448,230,452,234,455,240,456,370,455,378,454,381,452,384,449,386,442,387,397,385,386,381,382,376,380,370,379,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "300",
				shape: "poly",
				coords: [478,378,470,382,467,384,464,388,462,394,463,441,465,448,468,452,473,455,479,456,610,456,616,454,621,451,623,448,625,442,625,395,624,387,619,383,615,380,608,379,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "301",
				shape: "poly",
				coords: [719,377,710,380,705,384,702,390,701,395,700,439,704,448,708,452,715,455,721,455,848,455,855,452,859,450,863,444,864,438,864,393,861,385,856,380,850,378,846,377,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "302",
				shape: "poly",
				coords: [956,377,947,380,943,384,941,390,940,395,940,440,942,446,945,450,949,453,956,455,1086,455,1095,452,1098,448,1101,445,1102,439,1102,395,1101,386,1098,382,1092,379,1086,378,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "303",
				shape: "poly",
				coords: [1192,378,1184,380,1182,384,1180,388,1178,395,1179,438,1180,446,1182,450,1186,453,1193,454,1324,456,1332,453,1337,450,1339,445,1341,440,1340,393,1338,384,1334,381,1328,378,1322,377,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "304",
				shape: "poly",
				coords: [1432,377,1424,380,1421,384,1418,389,1416,394,1417,439,1419,446,1421,450,1426,454,1435,455,1560,454,1570,452,1574,450,1578,445,1579,440,1579,395,1578,387,1574,381,1566,378,1559,378,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "305",
				shape: "poly",
				coords: [1704,378,1696,382,1692,384,1690,389,1690,394,1690,442,1694,448,1697,453,1703,456,1712,456,1842,456,1849,454,1854,450,1857,446,1859,440,1859,398,1857,387,1854,382,1847,379,1841,378,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "ted") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo);
			dispatch(viewRoadmapRoleByRoleId(requestInfo));

			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
