import React from 'react';

import { Typography, makeStyles, Button } from '@material-ui/core';

import aboutGroupStyles from 'assets/jss/material-kit-pro-react/views/componentStyle/manageGroup/aboutGroupStyles.js';
import { Skeleton } from '@mui/material';

const AboutGroup = React.memo(
  ({ description = '', industry = '', country, onAboutClick, isUserManagerOrOwner, groupIsLoading }) => {
    const styles = makeStyles(aboutGroupStyles)();
    return (
      <Typography component="div" className={styles.main}>
        {!groupIsLoading ? (
          <>
            <Typography component="div" className={styles.header}>
              <Typography component="h3" className={styles.title}>
                About Group
              </Typography>
              {isUserManagerOrOwner && (
                <Button onClick={onAboutClick} className={styles.editBtn}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="4" viewBox="0 0 18 4">
                    <path
                      id="Shape"
                      d="M0,16a2,2,0,1,1,2,2A2,2,0,0,1,0,16ZM0,9a2,2,0,1,1,2,2A2,2,0,0,1,0,9ZM0,2A2,2,0,1,1,2,4,2,2,0,0,1,0,2Z"
                      transform="translate(0 4) rotate(-90)"
                      fill="#92929d"
                    />
                  </svg>
                </Button>
              )}
            </Typography>

            <hr className={styles.divider} />

            {/* <Typography component='p' className={styles.aboutGroupText}>
              {description}
            </Typography> */}

            <Typography component="h4" className={styles.aboutGroupTypeTitle}>
              Industry
            </Typography>
            <Typography component="p" className={styles.aboutGroupTypeText}>
              {industry}
            </Typography>
            <Typography component="h4" className={styles.aboutGroupTypeTitle}>
              Country
            </Typography>
            <Typography component="p" className={styles.aboutGroupTypeText}>
              {country}
            </Typography>
          </>
        ) : (
          <>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </>
        )}
      </Typography>
    );
  }
);
export default AboutGroup;
