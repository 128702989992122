import React from "react";
import { Button, makeStyles, Typography } from "@material-ui/core";
import groupPageStyles from "assets/jss/material-kit-pro-react/views/pageStyle/GroupsPage";
import { Link, useLocation, useHistory, Redirect } from "react-router-dom";

const useStyles = makeStyles(groupPageStyles);

const GroupContainer = ({
	title,
	subTitle,
	onSeeAllClick,
	children,
	expanded,
}) => {
	const classes = useStyles();

	return (
		<div className={classes.suggestedGroups}>
			<div className={classes.containerGroupHeader}>
				<Typography className={classes.containerGroupHeaderText}>
					{title}
				</Typography>
				{!expanded && (
					<Link
						className={classes.textDeco}
						to={{
							pathname: title === "My groups" ? `/my-groups` : `/browse-groups`,
						}}
						color="transparent"
					>
						<Button variant="text" color="primary">
							SEE MORE
						</Button>
					</Link>
				)}
			</div>
			<div className={classes.subHeader}>
				<Typography className={classes.subHeaderText}>{subTitle}</Typography>
			</div>
			{children}
		</div>
	);
};

export default GroupContainer;
