import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
	departmentViewRoadmap,
	viewRoadmapRoleByRoleId,
} from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Department_Risk from "assets/img/Career_Roadmap/Banking_Risk_Compliance/Department_Risk_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentRisk() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Risk;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "r",
				shape: "poly",
				coords: [75,262,65,265,60,269,56,274,55,281,55,320,57,326,60,331,64,335,71,337,178,338,185,335,190,331,193,327,195,321,195,281,193,273,189,267,183,264,175,262,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "r",
				shape: "poly",
				coords: [76,411,67,413,62,416,58,421,55,426,54,468,56,474,59,480,64,485,72,486,177,486,185,484,190,479,193,475,195,469,196,428,192,420,188,416,182,412,176,411,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "r",
				shape: "poly",
				coords: [74,560,65,563,60,566,57,569,54,578,55,619,57,624,59,629,64,632,72,636,177,636,184,633,189,631,193,626,196,617,195,576,192,569,188,564,184,562,178,560,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "448",
				shape: "poly",
				coords: [262,266,256,269,252,272,249,277,247,281,248,331,250,336,252,339,256,341,262,344,455,344,462,342,465,339,468,334,469,329,469,278,466,274,463,270,458,268,454,266,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "449",
				shape: "poly",
				coords: [598,266,592,268,588,272,584,277,583,282,583,330,586,336,589,339,593,343,599,344,792,344,797,341,801,338,804,334,805,329,806,280,803,274,799,270,794,267,788,266,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "450",
				shape: "poly",
				coords: [942,266,935,269,931,271,928,275,927,282,927,328,928,334,932,339,939,343,944,343,1132,344,1139,342,1144,338,1147,334,1149,328,1149,280,1147,274,1143,270,1138,267,1132,266,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "451",
				shape: "poly",
				coords: [1281,266,1272,268,1267,272,1264,278,1263,283,1263,328,1266,335,1269,339,1275,343,1281,344,1469,344,1476,342,1482,338,1484,334,1485,329,1486,282,1484,276,1481,272,1477,269,1470,266,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "452",
				shape: "poly",
				coords: [1614,266,1607,269,1603,272,1600,277,1599,282,1599,329,1602,336,1604,339,1609,342,1616,344,1807,344,1813,342,1817,338,1820,335,1821,327,1822,281,1819,273,1816,270,1812,267,1805,265,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "453",
				shape: "poly",
				coords: [262,409,256,411,251,415,248,420,247,426,248,474,250,479,253,484,257,487,263,488,455,487,462,485,465,481,468,477,469,472,469,424,467,417,464,413,458,411,453,410,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "454",
				shape: "poly",
				coords: [534,410,526,412,522,415,519,421,517,426,518,472,520,479,523,482,527,486,534,487,722,488,729,486,734,483,738,479,740,473,740,425,738,418,734,415,729,411,723,409,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "455",
				shape: "poly",
				coords: [806,409,797,411,792,416,789,421,788,428,788,472,790,478,793,483,797,486,804,488,994,487,1002,486,1006,482,1009,478,1010,474,1010,429,1009,420,1007,416,1003,413,996,410,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "456",
				shape: "poly",
				coords: [1075,410,1067,412,1063,415,1060,420,1059,426,1059,473,1061,478,1064,483,1068,487,1075,488,1266,487,1272,485,1276,482,1280,478,1281,472,1280,424,1279,418,1276,414,1270,410,1264,410,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "457",
				shape: "poly",
				coords: [1347,409,1338,411,1334,414,1331,419,1330,424,1329,472,1331,479,1333,483,1339,486,1346,487,1534,487,1542,486,1547,482,1550,478,1551,471,1551,427,1549,418,1546,414,1540,411,1534,409,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "458",
				shape: "poly",
				coords: [1615,409,1609,412,1605,415,1601,420,1599,426,1600,473,1602,480,1605,484,1611,487,1617,487,1805,488,1812,486,1817,482,1821,477,1822,472,1821,425,1820,417,1815,413,1810,410,1804,410,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "459",
				shape: "poly",
				coords: [263,559,256,561,251,565,248,569,247,575,247,622,250,629,252,633,258,636,264,636,452,636,460,634,464,631,468,627,469,620,469,575,468,567,465,562,459,560,453,559,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "460",
				shape: "poly",
				coords: [533,558,526,560,522,563,519,568,518,574,517,621,519,628,522,632,526,635,534,636,722,637,730,634,735,630,738,626,740,620,739,572,737,566,734,563,730,560,724,559,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "461",
				shape: "poly",
				coords: [807,559,797,562,792,565,789,570,788,576,788,623,789,628,792,633,797,636,804,638,993,638,1000,636,1005,632,1009,628,1010,622,1010,577,1009,569,1005,564,1000,561,994,560,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "462",
				shape: "poly",
				coords: [1080,559,1069,560,1064,562,1060,569,1058,577,1059,623,1061,628,1064,632,1067,635,1075,637,1264,636,1270,634,1275,631,1279,627,1281,622,1281,573,1279,567,1275,563,1270,560,1265,559,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "462",
				shape: "poly",
				coords: [1350,558,1341,560,1337,563,1334,569,1333,577,1332,621,1335,628,1338,632,1344,635,1351,637,1535,637,1544,634,1549,632,1553,627,1555,621,1555,575,1553,568,1549,563,1544,560,1538,559,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "464",
				shape: "poly",
				coords: [1618,558,1608,560,1604,564,1600,570,1599,577,1599,620,1602,627,1605,632,1612,636,1619,637,1800,636,1811,635,1815,631,1819,627,1822,622,1822,574,1820,567,1817,563,1812,561,1805,559,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "r") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo);
			dispatch(viewRoadmapRoleByRoleId(requestInfo));

			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
