import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { userGroupRoles } from 'configurations/configurations';
import { instanceUrl } from 'configurations/configurations';

const initialState = {
  suggestedGroups: [],
  groups: [],
  groupById: {},
  mediaByGroupId: [],
  usersByGroupId: [],
  usersByStatus: [],
  postsByGroupId: [],
  pendingPostsByGroupId: [],
  groupsByAccount: [],
  isGroupsLoading: false,
  isSuggestedGroupsLoading: false,
  isGroupCreateLoading: false,
  isGroupsByAccountLoading: false,
  isStatusUpdated: false,
  isGroupCreated: false,
  errorMessage: '',
};
const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    CREATE_GROUP_LOADING: state => {
      state.isGroupCreateLoading = true;
    },
    CREATE_GROUP_FAIL: (state, action) => {
      state.isGroupCreateLoading = false;
      state.errorMessage = action.payload;
    },
    CREATE_GROUP_SUCCESS: (state, action) => {
      state.isGroupCreateLoading = false;
      state.isGroupCreated = true;
      state.errorMessage = '';
      state.groupsByAccount = [...state.groupsByAccount, action.payload.socialGroup];
      const groupIndex = state.groupsByAccount.findIndex(g => g.groupId === action.payload.socialGroup.groupId);

      state.groupsByAccount[groupIndex].userRoleId = 1;
      state.groupsByAccount[groupIndex].userGroupId = action.payload.socialGroup.groupUser.groupUserId;
      state.groups = [...state.groups, action.payload.socialGroup];
      state.groups[groupIndex].userRoleId = 1;
      state.groups[groupIndex].userGroupId = action.payload.socialGroup.groupUser.groupUserId;
    },
    UPDATE_GROUP_SUCCESS: (state, action) => {
      state.groupById = action.payload.updatedGroup;
      state.groupById.groupId = action.payload.groupId;
    },
    SUGGESTED_GROUPS_LOADING: state => {
      state.isLoading = true;
      state.errorMessage = '';
    },
    SUGGESTED_GROUPS_SUCCESS: (state, action) => {
      state.suggestedGroups = action.payload.groupList;
      state.isSuggestedGroupsLoading = false;
    },
    GET_GROUPS_LOADING: state => {
      state.isGroupsLoading = true;
    },
    GET_GROUPS_SUCCESS: (state, action) => {
      state.isGroupsLoading = false;
      state.isGroupCreated = false;

      state.groups = action.payload.groupList;
    },
    GET_GROUPS_FAIL: (state, action) => {
      state.isGroupsLoading = false;
      state.errorMessage = action.payload;
    },
    GET_GROUPS_BY_ACCOUNT_LOADING: state => {
      state.isGroupsByAccountLoading = true;
    },
    GET_GROUPS_BY_ACCOUNT_SUCCESS: (state, action) => {
      state.isGroupsByAccountLoading = false;
      state.groupsByAccount = action.payload.groupList;
      state.isStatusUpdated = false;
      state.isGroupCreated = false;
    },
    GET_GROUPS_BY_ACCOUNT_FAIL: (state, action) => {
      state.isGroupsByAccountLoading = false;
      state.errorMessage = action.payload;
    },
    GET_GROUP_BY_ID_SUCCESS: (state, action) => {
      state.groupById = action.payload.groupList[0];
    },
    GET_USER_BY_GROUP_ID_SUCCESS: (state, action) => {
      state.isGroupsLoading = false;

      state.usersByGroupId = action.payload.groupUserList;
    },
    GET_USERS_BY_STATUS: (state, action) => {
      state.usersByStatus = action.payload.groupUserList;
    },
    GET_PENDING_POSTS_BY_GROUP_ID: (state, action) => {
      state.pendingPostsByGroupId = action.payload.postList;
    },
    GET_MEDIA_BY_GROUP_ID: (state, action) => {
      state.mediaByGroupId = action.payload.postTypeList;
    },
    JOIN_USER_GROUP: (state, action) => {
      const groupIndex = state.groups.findIndex(g => g.groupId === action.payload.groupId);
      if (groupIndex !== -1) {
        state.groups[groupIndex].isGroupMember = 1;
        state.groupsByAccount = [...state.groupsByAccount, state.groups[groupIndex]];
        state.groups[groupIndex].userGroupId = action.payload.userGroupId;
        state.groups[groupIndex].userStatusId = action.payload.userStatusId;
        state.groups[groupIndex].userRoleId = action.payload.userRoleId;
      }
      state.usersByGroupId = [...state.usersByGroupId, action.payload.user];
    },
    INVITE_USERS_TO_GROUP_SUCCESS: (state, action) => {},
    LEAVE_USER_GROUP: (state, action) => {
      state.usersByGroupId = state.usersByGroupId.filter(u => u.groupUserId !== action.payload.groupUserId);
    },
    LEAVE_USER_GROUP_IN_PAGE: (state, action) => {
      state.groupsByAccount = state.groupsByAccount.filter(u => u.userGroupId !== action.payload.groupUserId);
      state.isStatusUpdated = true;
      const groupIndex = state.groups.findIndex(g => g.groupId === action.payload.groupId);
      console.log(groupIndex, 'in slice');
      if (groupIndex !== -1) {
        state.groups[groupIndex].userGroupId = null;
        state.groups[groupIndex].userStatusId = null;
        state.groups[groupIndex].userRoleId = null;
      }
    },
    UPDATE_USER_GROUP_STATUS: (state, action) => {
      const userIndex = state.usersByGroupId.findIndex(u => u.groupUserId === action.payload.groupUserId);
      state.usersByGroupId[userIndex].statusId = action.payload.userStatusId;
    },
    UPDATE_USER_GROUP_ROLE: (state, action) => {
      const userIndex = state.usersByGroupId.findIndex(u => u.groupUserId === action.payload.groupUserId);
      state.usersByGroupId[userIndex].roleId = action.payload.roleId;
    },
    REFRESH_TOKEN: (state, action) => {
      localStorage.setItem('token', action.payload.token);
    },
    DEFAULT_GROUP_LOADING: (state, action) => {
      state.isLoading = true;
    },
    JOIN_USER_GROUP_IN_APPROVAL: (state, action) => {
      const groupIndex = state.groups.findIndex(g => g.groupId === action.payload.groupId);
      if (groupIndex !== -1) {
        state.groups[groupIndex].isGroupMember = 1;
        state.groups[groupIndex].userGroupId = action.payload.userGroupId;
        state.groups[groupIndex].userStatusId = action.payload.userStatusId;
        state.groups[groupIndex].userRoleId = action.payload.userRoleId;
      }
      state.usersByGroupId = [...state.usersByGroupId, action.payload.user];
    },
  },
});

const {
  SUGGESTED_GROUPS_LOADING,
  SUGGESTED_GROUPS_SUCCESS,
  CREATE_GROUP_LOADING,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_FAIL,
  UPDATE_GROUP_SUCCESS,
  GET_GROUPS_LOADING,
  GET_GROUPS_SUCCESS,
  GET_GROUPS_FAIL,
  GET_GROUPS_BY_ACCOUNT_LOADING,
  GET_GROUPS_BY_ACCOUNT_SUCCESS,
  GET_GROUPS_BY_ACCOUNT_FAIL,
  GET_GROUP_BY_ID_SUCCESS,
  GET_USERS_BY_STATUS,
  GET_PENDING_POSTS_BY_GROUP_ID,
  GET_USER_BY_GROUP_ID_SUCCESS,
  GET_MEDIA_BY_GROUP_ID,
  JOIN_USER_GROUP,
  INVITE_USERS_TO_GROUP_SUCCESS,
  LEAVE_USER_GROUP,
  UPDATE_USER_GROUP_STATUS,
  UPDATE_USER_GROUP_ROLE,
  REFRESH_TOKEN,
  DEFAULT_GROUP_LOADING,
  LEAVE_USER_GROUP_IN_PAGE,
  JOIN_USER_GROUP_IN_APPROVAL,
} = groupsSlice.actions;

export const joinUserGroup = body => dispatch => {
  return axios.post(`${instanceUrl}/api/sg/create-social-group-user`, body).then(res => {
    if (res.status === 201) {
      console.log(res.data.payload);
      dispatch(REFRESH_TOKEN({ token: res.data.token }));
      if (body.userStatusId !== 3) {
        dispatch(
          JOIN_USER_GROUP({
            groupId: body.groupId,
            user: res.data.payload.result,
            userGroupId: res.data.payload.result.groupUserId,
            userStatusId: res.data.payload.result.userStatusId,
            userRoleId: res.data.payload.result.roleId,
          })
        );
      } else {
        dispatch(
          JOIN_USER_GROUP_IN_APPROVAL({
            groupId: body.groupId,
            user: res.data.payload.result,
            userGroupId: res.data.payload.result.groupUserId,
            userStatusId: res.data.payload.result.userStatusId,
            userRoleId: res.data.payload.result.roleId,
          })
        );
      }
    }
    return res.data.payload.result;
  });
};

export const leaveUserGroup = body => dispatch => {
  console.log(body);
  return axios.put(`${instanceUrl}/api/sg/delete-social-group-user`, body).then(res => {
    if (res.status === 201) {
      dispatch(REFRESH_TOKEN({ token: res.data.token }));
      dispatch(LEAVE_USER_GROUP({ groupUserId: body.groupUserId }));
    }
  });
};
export const leaveUserGroupInPage = body => dispatch => {
  console.log(body);
  return axios.put(`${instanceUrl}/api/sg/delete-social-group-user`, body).then(res => {
    if (res.status === 201) {
      dispatch(REFRESH_TOKEN({ token: res.data.token }));
      dispatch(
        LEAVE_USER_GROUP_IN_PAGE({
          groupUserId: body.groupUserId,
          groupId: body.groupId,
        })
      );
    }
  });
};
export const updateUserGroupStatus = body => dispatch => {
  return axios.put(`${instanceUrl}/api/sg/update-social-group-user-status`, body).then(res => {
    if (res.status === 201) {
      dispatch(REFRESH_TOKEN({ token: res.data.token }));
      dispatch(
        UPDATE_USER_GROUP_STATUS({
          groupUserId: body.groupUserId,
          userStatusId: body.userStatusId,
        })
      );
    }
    return res.data.payload.result;
  });
};
export const updateUserGroupRole = body => dispatch => {
  return axios.put(`${instanceUrl}/api/sg/update-social-group-user-role`, body).then(res => {
    if (res.status === 201) {
      dispatch(REFRESH_TOKEN({ token: res.data.token }));
      dispatch(
        UPDATE_USER_GROUP_ROLE({
          groupUserId: body.groupUserId,
          roleId: body.roleId,
        })
      );
    }
    return res.data.payload.result;
  });
};

export const updateGroupPostStatus = body => dispatch => {
  return axios.put(`${instanceUrl}/api/sf/update-social-post-status`, body).then(res => {
    if (res.status === 201) {
      dispatch(REFRESH_TOKEN({ token: res.data.token }));
    }
  });
};
export const deleteGroupPost = body => dispatch => {
  return axios.put(`${instanceUrl}/api/sf/delete-social-post`, body).then(res => {
    if (res.status === 201) {
      dispatch(REFRESH_TOKEN({ token: res.data.token }));
    }
  });
};

export const inviteUsersToGroup = requestBodies => dispatch => {
  return axios.all(requestBodies.map(body => axios.post(`${instanceUrl}/api/sg/create-social-group-user`, body))).then(
    axios.spread((...responses) => {
      dispatch(
        INVITE_USERS_TO_GROUP_SUCCESS([
          Object.values(responses).map(r => {
            return r.result;
          }),
        ])
      );
    })
  );
};

export const createGroup = body => async dispatch => {
  dispatch(CREATE_GROUP_LOADING());

  await axios
    .post(`${instanceUrl}/api/sg/create-social-group`, body, { timeout: 30000 })
    .then(res => {
      if (res.status === 201) {
        dispatch(REFRESH_TOKEN({ token: res.data.token }));
        dispatch(CREATE_GROUP_SUCCESS(res.data.payload));
      }
    })
    .catch(message => {
      dispatch(CREATE_GROUP_FAIL(message));
      console.log(message);
    });
};

export const updateGroup = body => dispatch => {
  return axios.put(`${instanceUrl}/api/sg/update-social-group`, body, { timeout: 30000 }).then(res => {
    if (res.status === 201) {
      dispatch(REFRESH_TOKEN({ token: res.data.token }));
      dispatch(
        UPDATE_GROUP_SUCCESS({
          updatedGroup: res.data.payload.socialGroup,
          groupId: body.groupId,
        })
      );
    }
  });
};

export const deleteGroup = body => dispatch => {
  return axios.put(`${instanceUrl}/api/sg/delete-social-group`, body, { timeout: 30000 }).then(res => {
    if (res.status === 201) {
      dispatch(REFRESH_TOKEN({ token: res.data.token }));
    }
  });
};

export const checkGroupNameDuplicate = async body => {
  const res = await axios.get(`${instanceUrl}/api/sg/get-social-group-name-duplicate`, {
    params: {
      accessToken: localStorage.getItem('token'),
      ...body,
    },
  });

  return Boolean(res.data.payload.checkDuplicate);
};

export const getGroups = body => async dispatch => {
  dispatch(GET_GROUPS_LOADING());
  await axios
    .get(`${instanceUrl}/api/sg/get-social-group`, { params: body })
    .then(res => {
      if (res.status === 201) {
        dispatch(REFRESH_TOKEN({ token: res.data.token }));
        dispatch(GET_GROUPS_SUCCESS(res.data.payload));
      }
    })
    .catch(message => {
      dispatch(GET_GROUPS_FAIL(message));
    });
};

export const getGroupById = body => async dispatch => {
  dispatch(GET_GROUPS_LOADING());
  await axios
    .get(`${instanceUrl}/api/sg/get-social-group-by-group-id`, { params: body })
    .then(res => {
      if (res.status === 201) {
        dispatch(REFRESH_TOKEN({ token: res.data.token }));
        dispatch(GET_GROUP_BY_ID_SUCCESS(res.data.payload));
      }
    })
    .catch(message => {
      dispatch(GET_GROUPS_FAIL(message));
    });
};

export const getGroupUserByGroupId = body => dispatch => {
  dispatch(GET_GROUPS_LOADING());
  return axios
    .get(`${instanceUrl}/api/sg/get-social-group-user-by-group-id`, {
      params: body,
    })
    .then(res => {
      if (res.status === 201) {
        dispatch(REFRESH_TOKEN({ token: res.data.token }));
        dispatch(GET_USER_BY_GROUP_ID_SUCCESS(res.data.payload));
      }
      return res;
    })
    .catch(message => {
      dispatch(GET_GROUPS_FAIL(message));
    });
};
export const getGroupUsersByStatus = body => dispatch => {
  return axios
    .get(`${instanceUrl}/api/sg/get-social-group-user-by-group-id`, {
      params: body,
    })
    .then(res => {
      if (res.status === 201) {
        dispatch(REFRESH_TOKEN({ token: res.data.token }));
        dispatch(GET_USERS_BY_STATUS(res.data.payload));
      }
      return res;
    });
};

export const getPendingPostsByGroupId = body => dispatch => {
  return axios.get(`${instanceUrl}/api/sf/get-social-post-by-group-id`, { params: body }).then(res => {
    if (res.status === 201) {
      dispatch(REFRESH_TOKEN({ token: res.data.token }));
      dispatch(GET_PENDING_POSTS_BY_GROUP_ID(res.data.payload));
    }
    return res;
  });
};

export const getMediaByGroupId = body => dispatch => {
  return axios.get(`${instanceUrl}/api/sf/get-social-post-type-by-group-id`, { params: body }).then(res => {
    if (res.status === 201) {
      dispatch(REFRESH_TOKEN({ token: res.data.token }));
      dispatch(GET_MEDIA_BY_GROUP_ID(res.data.payload));
    }
    return res.data.payload.postTypeList;
  });
};

export const getGroupsByAcctId = body => async dispatch => {
  dispatch(GET_GROUPS_BY_ACCOUNT_LOADING());
  await axios
    .get(`${instanceUrl}/api/sg/get-social-group-by-acct-id`, { params: body })
    .then(res => {
      if (res.status === 201) {
        console.log(res);
        dispatch(REFRESH_TOKEN({ token: res.data.token }));
        dispatch(GET_GROUPS_BY_ACCOUNT_SUCCESS(res.data.payload));
      }
    })
    .catch(message => {
      dispatch(GET_GROUPS_BY_ACCOUNT_FAIL(message));
    });
};

export const getSuggestedGroups = ({
  email,
  acctId,
  lastRecordSize,
  requestRecordSize,
  accessToken,
}) => async dispatch => {
  dispatch(SUGGESTED_GROUPS_LOADING());
  await axios({
    method: 'GET',
    url: `${instanceUrl}/api/sg/get-suggested-group-by-acct-id`,
    params: {
      email,
      acctId,
      lastRecordSize,
      requestRecordSize,
      accessToken,
    },
  }).then(res => {
    dispatch(SUGGESTED_GROUPS_SUCCESS(res.data.payload));
  });
};

export default groupsSlice.reducer;
