import React from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import {
	createJobApplication,
	jobSelectReset,
} from "configurations/redux/jobManagementSlice.js";
import { getCandidateSelectJobPosts } from "configurations/redux/jobManagementSlice.js";
import { getProfiles } from "configurations/redux/userSlice.js";
import FontIcon from "views/Component/FontIcon.js";

import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Chip } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import {
	faSignOutAlt,
	faMapMarkerAlt,
	faCalendarAlt,
	faDollarSign,
	faEye,
	faUserCheck,
	faChartLine,
	faBriefcase,
	faClock,
	faUserCircle,
	faIdCard,
	faWrench,
} from "@fortawesome/free-solid-svg-icons";
import { encryptData } from "configurations/configurations.js";
import { Link } from "react-router-dom";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Danger from "components/Typography/Danger.js";
import Muted from "components/Typography/Muted.js";
import Button from "components/CustomButtons/Button.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Close from "@material-ui/icons/Close";

import defaultAvatar from "assets/img/corporate_avatar.jpeg";

import searchJobPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/SearchJobPage/searchJobPageStyle.js";
import { saveJobApplication } from "configurations/redux/jobManagementSlice.js";
import NoActivityStatusModal from "../NoActivityStatusModal/NoActivityStatusModal";
import { TimeFormat } from "configurations/configurations";
import ApplyProfileModal from "views/Page/SearchJobPage/ApplyProfileModal.js";
import LocationOnRoundedIcon from "@material-ui/icons/LocationOnRounded";
import AttachMoneyRoundedIcon from "@material-ui/icons/AttachMoneyRounded";
import DateRangeRoundedIcon from "@material-ui/icons/DateRangeRounded";
import { isDesktop, isMobile, isMobileOnly } from "react-device-detect";
import { Hidden } from "@material-ui/core";
import IconLibrary from "views/Component/IconLibrary.js";
import { max } from "moment";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import { useSnackbar } from "notistack";
import CloseIcon from "@material-ui/icons/Close";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CreatePostModal from "../../../components/CreatePostModal/CreatePostModal";
import MetatagHelmet from "views/Component/MetatagHelmet";

const useStyles = makeStyles(searchJobPageStyle);
const publicIp = require("public-ip");

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

export default function ResultJobModal({ open, onClose, item }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const imageClasses = classNames(
		classes.imgRaised,
		// classes.imgRoundedCircle,
		classes.imgProfile,
		classes.companyModalLogo
	);
	const selectedJobPost = useSelector(
		(state) => state.jobManagement.selectedJobPost
	);
	// console.log(selectedJobPost);
	const userStatus = useSelector((state) => state.auth.user);
	const isLoading = useSelector((state) => state.jobManagement.isLoading);
	const isSelected = useSelector((state) => state.jobManagement.isSelected);
	const [isLoadingDelayed, setIsLoadingDelayed] = React.useState(true);
	const auth = useSelector((state) => state.auth);

	const [profileModal, setProfileModal] = React.useState(false);
	const [createPost, setCreatePost] = React.useState(false);
	const [copied, setCopied] = React.useState(false);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const snackBarShow = (message, variant, action) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(message, {
			variant: variant,
			action,
		});
	};
	const onClickDismiss = (key) => () => {
		closeSnackbar(key);
	};

	const applyJob = async () => {
		const requestProfile = {
			acctId: userStatus.acctId,
			accessToken: localStorage.getItem("token"),
			ipAddr: localStorage.getItem("ipAddr"), //await publicIp.v4(),
			userId: userStatus.acctId,
		};
		dispatch(getProfiles(requestProfile));
		setProfileModal(true);
	};
	const profileModalOnClose = () => {
		setProfileModal(false);
	};
	const saveJob = () => {
		const requestProfile = {
			acctId: userStatus.acctId,
			accessToken: localStorage.getItem("token"),
			email: userStatus.email,
			jobPostId: selectedJobPost[0].jobPostId,
		};

		dispatch(saveJobApplication(requestProfile));
	};
	React.useEffect(() => {
		if (copied) {
			setCopied(false);
			const action = (key) => (
				<React.Fragment>
					<IconButton onClick={onClickDismiss(key)}>
						<CloseIcon />
					</IconButton>
				</React.Fragment>
			);
			snackBarShow(
				"The share link has been copy into clipboard, kindly paste and share.",
				"info",
				action
			);
		}
	}, [copied]);
	function yearMonthValue(monthValue) {
		if (monthValue >= 12) {
			if (monthValue % 12 === 0) {
				if (monthValue / 12 > 1) {
					return parseInt(monthValue / 12) + " Years";
				} else {
					return parseInt(monthValue / 12) + " Year";
				}
			} else if (monthValue / 12 > 1 && monthValue % 12 > 1) {
				return (
					parseInt(monthValue / 12) +
					" Years " +
					parseInt(monthValue % 12) +
					" Months"
				);
			} else if (monthValue / 12 > 1 && monthValue % 12 < 2) {
				return (
					parseInt(monthValue / 12) +
					" Years " +
					parseInt(monthValue % 12) +
					" Month"
				);
			} else if (monthValue / 12 < 2 && monthValue % 12 > 1) {
				return (
					parseInt(monthValue / 12) +
					" Year " +
					parseInt(monthValue % 12) +
					" Months"
				);
			} else {
				return (
					parseInt(monthValue / 12) +
					" Year " +
					parseInt(monthValue % 12) +
					" Month"
				);
			}
		} else {
			if (monthValue > 1) {
				return monthValue + " Months";
			} else {
				return monthValue + " Month";
			}
		}
	}

	function yearMonthValueMobile(monthValue) {
		if (monthValue >= 12) {
			if (monthValue % 12 === 0) {
				return parseInt(monthValue / 12) + " Y";
			} else if (monthValue / 12 > 1 && monthValue % 12 > 1) {
				return (
					parseInt(monthValue / 12) + " Y " + parseInt(monthValue % 12) + " M"
				);
			} else {
				return (
					parseInt(monthValue / 12) + " Y " + parseInt(monthValue % 12) + " M"
				);
			}
		} else {
			return monthValue + " M";
		}
	}

	React.useEffect(() => {
		dispatch(jobSelectReset());
	}, []);

	const modalResize = () => {
		var timer = setInterval(() => {
			var modalHeader = document.getElementById("modalHeader");
			var modalDescription = document.getElementById("modalDescription");
			var modalResize = document.getElementById("modalCard");
			if (modalResize && modalHeader && modalDescription) {
				clearInterval(timer);
				var maxSize = window.innerHeight - 74 - 70 - 70;
				var requiredSize =
					modalHeader.clientHeight + modalDescription.clientHeight + 60;
				if (maxSize < 0) maxSize = 0;
				if (maxSize > requiredSize) maxSize = requiredSize;
				else modalResize.style.overflowY = "auto";

				// if (modalResize.clientHeight < maxSize)
				modalResize.style.height = maxSize + "px";
				// else modalResize.style.height = "auto";
				// } else {
				// 	setTimeout(() => {
				// 		clearInterval(timer);
				// 	}, 3000);
			}
		}, 300);
		return () => clearInterval(timer);
	};

	// React.useEffect(() => {
	// 	window.addEventListener("resize", modalResize);
	// });

	// React.useEffect(() => {
	// 	if (open) {
	// 		modalResize();
	// 		window.addEventListener("resize", modalResize);
	// 		return function cleanup() {
	// 			window.removeEventListener("resize", modalResize);
	// 		};
	// 	}
	// }, [open]);

	const statusChangeHandler = (item) => {
		console.log(item);
		setCreatePost(false);
	};

	return (
		<div>
			<Dialog
				classes={{
					root: classes.modalRoot,
					paper: classes.modal + " " + classes.modalJobView,
				}}
				open={open}
				// TransitionComponent={Transition}
				// keepMounted
				onClose={(event, reason) => {
					if (reason && reason == "backdropClick") return;
					onClose();
				}}
				aria-labelledby="classic-modal-slide-title"
				aria-describedby="classic-modal-slide-description"
			>
				<DialogTitle
					id="signup-modal-slide-title"
					disableTypography
					className={classes.modalTitleEdit}
				>
					<Button
						simple
						className={classes.modalCloseButton}
						key="close"
						aria-label="Close"
						onClick={onClose}
					>
						<IconLibrary.Close />
						{/* <Close className={classes.modalClose} /> */}
					</Button>

					<h3 className={classes.title + " " + classes.titleAlign}>Job Post</h3>
				</DialogTitle>
				<div className={classes.autoHeightBody}>
					<DialogContent className={classes.modalBody}>
						<GridContainer justifyContent="center">
							{isLoading ? (
								<GridItem
									xs={12}
									sm={12}
									md={2}
									className={classes.itemCenter + " " + classes.loadingHeight}
								>
									<CircularProgress />
								</GridItem>
							) : (
								<React.Fragment>
									{isSelected ? (
										<React.Fragment>
											<GridItem className={classes.cardPadding}>
												<Card className={classes.modalCardBorder}>
													<CardBody
														className={
															isDesktop
																? classes.smallModalBodyDesktop +
																  " " +
																  classes.modalBackground
																: classes.smallModalBody +
																  " " +
																  classes.modalBackground +
																  " " +
																  classes.cardBodyMobile
														}
														id="modalCard"
													>
														<GridContainer
															justifyContent="center"
															id="modalHeader"
														>
															<GridItem>
																{selectedJobPost[1] &&
																selectedJobPost[1] !== undefined ? (
																	<div
																		className={
																			selectedJobPost[1].applStatusId === 1 ||
																			selectedJobPost[1].applStatusId === 4 ||
																			selectedJobPost[1].applStatusId === 7
																				? classes.statusSubmitted
																				: selectedJobPost[1].applStatusId === 2
																				? classes.statusReviewing
																				: selectedJobPost[1].applStatusId === 3
																				? classes.statusShortlisted
																				: selectedJobPost[1].applStatusId === 5
																				? classes.statusRejected
																				: selectedJobPost[1].applStatusId === 6
																				? classes.statusUnsuccessful
																				: selectedJobPost[1].applStatusId === 8
																				? classes.statusWithdraw
																				: null
																		}
																	>
																		<span>
																			{selectedJobPost[1].applStatusName}
																		</span>
																	</div>
																) : null}
															</GridItem>
															<GridItem
																xs={3}
																sm={2}
																md={2}
																className={classes.itemCenterLg}
															>
																<Link
																	to={{
																		pathname: `/corporate-profile-page/${encryptData(
																			selectedJobPost[0].acctId
																		)}/${encryptData(
																			selectedJobPost[0].bizId
																		)}`,
																	}}
																	target="_blank"
																	rel="noopener noreferrer"
																>
																	{selectedJobPost[0].profilePic ? (
																		<img
																			src={selectedJobPost[0].profilePic}
																			alt="..."
																			className={imageClasses}
																		/>
																	) : (
																		<img
																			src={defaultAvatar}
																			alt="..."
																			className={imageClasses}
																		/>
																	)}
																</Link>
															</GridItem>
															<GridItem xs={9} sm={10} md={10}>
																<GridContainer justifyContent="space-between">
																	<GridItem>
																		<div>
																			<b className={classes.jobTitle}>
																				{selectedJobPost[0].title}
																			</b>
																			<Hidden xsDown>
																				<span
																					className={
																						classes.description +
																						" " +
																						classes.timeInfo
																					}
																				>
																					{"Posted " +
																						TimeFormat(
																							selectedJobPost[0].createDate
																						)}
																				</span>
																			</Hidden>
																		</div>
																	</GridItem>
																</GridContainer>
																<Link
																	to={{
																		pathname: `/corporate-profile-page/${encryptData(
																			selectedJobPost[0].acctId
																		)}/${encryptData(
																			selectedJobPost[0].bizId
																		)}`,
																	}}
																	target="_blank"
																	rel="noopener noreferrer"
																>
																	<h5 className={classes.companyName}>
																		{selectedJobPost[0].companyName}
																	</h5>
																</Link>
																<Hidden smUp>
																	<span
																		className={
																			classes.description +
																			" " +
																			classes.timeInfo
																		}
																	>
																		{"Posted " +
																			TimeFormat(selectedJobPost[0].createDate)}
																	</span>
																</Hidden>
															</GridItem>
															<GridItem xs={false} sm={2} md={2}></GridItem>
															<GridItem xs={12} sm={10} md={10}>
																<GridContainer>
																	<GridItem xs={4} sm={5} md={5}>
																		<FontIcon
																			icon={faMapMarkerAlt}
																			text={
																				<span className={classes.description}>
																					{selectedJobPost[0].countryName}
																					{selectedJobPost[0].locationName &&
																						", " +
																							selectedJobPost[0].locationName}
																				</span>
																			}
																			className={classes.iconFontSize}
																		/>
																		<FontIcon
																			icon={faUserCircle}
																			text={
																				<span className={classes.description}>
																					{selectedJobPost[0].role}
																				</span>
																			}
																			className={classes.iconFontSize}
																		/>
																		{/* <FontIcon
																			icon={faEye}
																			text={
																				<span className={classes.description}>
																					{selectedJobPost[0].viewCount} Viewed
																				</span>
																			}
																			className={classes.iconFontSize}
																		/> */}
																		<FontIcon
																			icon={faDollarSign}
																			text={
																				<span className={classes.description}>
																					{selectedJobPost[0].currencyCode}
																					{selectedJobPost[0].salaryMin} -{" "}
																					{selectedJobPost[0].currencyCode}
																					{selectedJobPost[0].salaryMax}{" "}
																				</span>
																			}
																			className={classes.iconFontSize}
																		/>
																		<FontIcon
																			icon={faUserCheck}
																			text={
																				<span className={classes.description}>
																					{selectedJobPost[0]
																						? selectedJobPost[0].applCount
																						: "0"}{" "}
																					Applied
																				</span>
																			}
																			className={classes.iconFontSize}
																		/>
																	</GridItem>
																	<GridItem xs={8} sm={7} md={7}>
																		<FontIcon
																			icon={faChartLine}
																			text={
																				<span className={classes.description}>
																					{selectedJobPost[0].eduLvlName}
																				</span>
																			}
																			className={classes.iconFontSize}
																		/>
																		<FontIcon
																			icon={faBriefcase}
																			text={
																				<span className={classes.description}>
																					{selectedJobPost[0].industryName}
																				</span>
																			}
																			className={classes.iconFontSize}
																		/>
																		{/* <FontIcon
																			icon={faDollarSign}
																			text={
																				<span className={classes.description}>
																					{selectedJobPost[0].currencyCode}
																					{selectedJobPost[0].salaryMin} -{" "}
																					{selectedJobPost[0].currencyCode}
																					{selectedJobPost[0].salaryMax}{" "}
																				</span>
																			}
																			className={classes.iconFontSize}
																		/> */}
																		<FontIcon
																			icon={faClock}
																			text={
																				<span className={classes.description}>
																					{selectedJobPost[0].jobPostType}{" "}
																					{selectedJobPost[0].jobType
																						? " - " + selectedJobPost[0].jobType
																						: null}
																					{selectedJobPost[0].servicePeriod /
																						4 >
																					0
																						? isMobileOnly
																							? " (" +
																							  yearMonthValueMobile(
																									selectedJobPost[0]
																										.servicePeriod / 4
																							  ) +
																							  ")"
																							: " (" +
																							  yearMonthValue(
																									selectedJobPost[0]
																										.servicePeriod / 4
																							  ) +
																							  ")"
																						: ""}
																				</span>
																			}
																			className={classes.iconFontSize}
																		/>
																		{selectedJobPost[0].experience && (
																			// <FontIcon
																			// 	icon={IconLibrary.HeadSide}
																			// 	text={
																			// 		<span className={classes.description}>
																			// 			{selectedJobPost[0].experience}
																			// 		</span>
																			// 	}
																			// 	className={classes.iconFontSize}
																			// />
																			<div
																				className={
																					classes.iconFontSize +
																					" " +
																					classes.iconLibrary
																				}
																			>
																				<IconLibrary.HeadSide />{" "}
																				<span className={classes.description}>
																					{selectedJobPost[0].experience}
																				</span>
																			</div>
																		)}
																		{/* {selectedJobPost[0].servicePeriod > 0 ? (
																			<FontIcon
																				icon={faIdCard}
																				text={
																					<span className={classes.description}>
																						{selectedJobPost[0].servicePeriod /
																							4}{" "}
																						Months
																					</span>
																				}
																			/>
																		) : null} */}
																	</GridItem>
																</GridContainer>
															</GridItem>
														</GridContainer>

														<GridContainer
															className={classes.gridItemPadding}
															id="modalDescription"
														>
															<GridItem>
																<Divider className={classes.gridItemMargin} />

																<MetatagHelmet
																	metaTag={{
																		title: selectedJobPost[0]
																			? selectedJobPost[0].title
																			: "Job Post",
																		description: selectedJobPost[0]
																			? selectedJobPost[0].responsibility +
																			  " " +
																			  selectedJobPost[0].requirement
																			: "Job Post",
																		url: window.location.href,
																	}}
																/>
															</GridItem>

															<GridItem>
																<h5
																	className={
																		classes.title + " " + classes.modalJobTitle
																	}
																>
																	Job Description
																</h5>
																<h5 className={classes.modalJobDescription}>
																	<div
																		dangerouslySetInnerHTML={{
																			__html: selectedJobPost[0].responsibility,
																		}}
																	/>
																</h5>
															</GridItem>
															<GridItem>
																<h5
																	className={
																		classes.title + " " + classes.modalJobTitle
																	}
																>
																	Job Requirements
																</h5>
																<h5 className={classes.modalJobDescription}>
																	<div
																		dangerouslySetInnerHTML={{
																			__html: selectedJobPost[0].requirement,
																		}}
																	/>
																</h5>
															</GridItem>
															{selectedJobPost[0].hashtagList[0].hashtag ? (
																<GridItem>
																	<h5
																		className={
																			classes.title +
																			" " +
																			classes.modalJobTitle
																		}
																	>
																		Skills And Attributes
																	</h5>
																	{selectedJobPost[0].hashtagList &&
																		selectedJobPost[0].hashtagList.map(
																			(item, index) => {
																				return (
																					<Chip
																						key={index}
																						label={
																							<p
																								className={
																									classes.hashtagDescription
																								}
																							>
																								{item.hashtag}
																							</p>
																						}
																						className={classes.chip}
																						color={"secondary"}
																					/>
																				);
																			}
																		)}
																</GridItem>
															) : null}
														</GridContainer>
													</CardBody>
												</Card>
											</GridItem>
										</React.Fragment>
									) : (
										<NoActivityStatusModal />
									)}
								</React.Fragment>
							)}
						</GridContainer>
					</DialogContent>
				</div>
				<DialogActions
					className={classes.modalFooterPrivacy + " " + classes.modalFooter}
				>
					{auth.isPublic && !auth.isAuthenticated ? (
						<div>
							<TextField
								value={
									selectedJobPost
										? window.location.origin +
										  "/public-search-page/job/" +
										  encryptData(selectedJobPost[0].jobPostId)
										: ""
								}
								id="url"
								style={{ display: "none" }}
							/>
							<CopyToClipboard
								// options={{ debug: props.debug, message: "" }}
								onCopy={() => setCopied(true)}
								text={
									selectedJobPost
										? window.location.origin +
										  "/public-search-page/job/" +
										  encryptData(selectedJobPost[0].jobPostId)
										: ""
								}
							>
								<Button
									onClick={() => setCopied(true)}
									round
									color="oppty"
									className={classes.buttonApply}
								>
									<b>Copy Post Link</b>
								</Button>
							</CopyToClipboard>
						</div>
					) : (
						<div>
							<TextField
								value={
									selectedJobPost
										? window.location.origin +
										  "/public-search-page/job/" +
										  encryptData(selectedJobPost[0].jobPostId)
										: ""
								}
								id="url"
								style={{ display: "none" }}
							/>
							<CopyToClipboard
								// options={{ debug: props.debug, message: "" }}
								onCopy={() => setCopied(true)}
								text={
									selectedJobPost
										? window.location.origin +
										  "/public-search-page/job/" +
										  encryptData(selectedJobPost[0].jobPostId)
										: ""
								}
							>
								<Button
									onClick={() => setCopied(true)}
									round
									color="opptyWrapBorder"
									className={classes.buttonApply}
								>
									<b>Copy Post Link</b>
								</Button>
							</CopyToClipboard>
							<Button
								onClick={() => {
									setCreatePost(true);
									console.log(encryptData(selectedJobPost[0].jobPostId));
								}}
								round
								color="oppty"
								className={classes.buttonApply}
							>
								<b>Share</b>
							</Button>
							<Button
								disabled={
									isLoading
										? true
										: selectedJobPost
										? selectedJobPost[0].favouriteJobPost
											? true
											: false
										: false
								}
								onClick={saveJob}
								round
								color="opptyWrapBorder"
								className={classes.buttonApply}
							>
								<b>
									{selectedJobPost
										? selectedJobPost[0].favouriteJobPost
											? "Saved"
											: "Save"
										: "Save"}
								</b>
							</Button>
							<Button
								disabled={
									isLoading
										? true
										: selectedJobPost
										? selectedJobPost[0].appliedJobPost
											? true
											: false
										: false
								}
								onClick={applyJob}
								round
								color="oppty"
								className={classes.buttonApply}
							>
								<b>
									{selectedJobPost
										? selectedJobPost[0].appliedJobPost
											? "Applied"
											: "Apply"
										: "Apply"}
								</b>
							</Button>
						</div>
					)}
				</DialogActions>
			</Dialog>
			{profileModal && (
				<ApplyProfileModal open={profileModal} onClose={profileModalOnClose} />
			)}
			{createPost ? (
				<CreatePostModal
					isOpen={createPost}
					setPostsList={null}
					user={userStatus}
					oriVisibleOptions={null}
					oriSocialPostTypeOptions={null}
					oriCommentabilityOptions={null}
					statusChangeHandler={statusChangeHandler}
					handleCreatePostClick={null}
					question={null}
					options={null}
					pollTiming={null}
					removePoll={null}
					savePostData={null}
					selectPhotos={null}
					selectVideos={null}
					uploadedPhotos={[]}
					uploadedVideos={[]}
					removeAnUploadedPhoto={null}
					removeAnUploadedVideo={null}
					onPhotosListItemClick={null}
					onVideosListItemClick={null}
					onPhotosListItemDragStart={null}
					onPhotosListItemDrop={null}
					onVideosListItemDragStart={null}
					onVideosListItemDrop={null}
					isLoading={null}
					setError={null}
					editPost={null}
					sharePost={null}
					shareJob={selectedJobPost}
					groupId={0}
					postStatus={1}
				/>
			) : null}
		</div>
	);
}
