import { grayColor, primaryColor } from "assets/jss/material-kit-pro-react.js";

const footerStyle = {
  footer: {
    "& ul li": {
      display: "inline-block",
    },
    "& h4, & h5": {
      color: grayColor[1],
      textDecoration: "none",
    },
    "& small": {
      fontSize: "75%",
      color: grayColor[10],
    },
    padding: "0px !important",
  },

  pullCenter: {
    display: "inline-block",
    float: "none",
  },

  linksVertical: {
    "& li": {
      display: "block !important",
      marginLeft: "-5px",
      marginRight: "-5px",
      "& a": {
        position: "relative",
        display: "block",
        borderRadius: "3px",
        padding: "5px",
        color: "inherit",
        fontSize: "12px",
        fontWeight: "500",
        textDecoration: "none",
        textTransform: "uppercase",
      },
    },
    "@media (min-width: 576px)": {
      align: "center",
    },
    "@media (min-width: 768px)": {
      align: "center",
    },
  },
  copyRight: {
    //paddingTop: "15px",
    textDecoration: "none",
  },
  liSocialButtons: {
    marginLeft: "5px",
  },
  copyRightWordings: {
    color: primaryColor[0] + "!important",
    display: "inline-block",
    padding: "5px 0",
  },
};
export default footerStyle;
