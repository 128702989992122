import { container } from "assets/jss/material-kit-pro-react.js";

const groupPageStyles = () => ({
	body: {
		overflowY: "scroll",
		marginTop: "95px",
		height: "calc(100vh - 95px)",
		overflowX: "hidden",
	},
	pageHeader: {
		position: "relative",
		alignItems: "center",
		margin: "0",
		border: "0",
		height: "auto",
	},
	container: {
		...container,
		zIndex: "5",
		"@media (min-width: 576px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 768px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 992px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 1200px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 1400px)": {
			maxWidth: "95vw",
		},
	},
	leftSideBar: {
		flexBasis: "19%",
	},
	groupsPageContext: {
		flexBasis: "81%",
	},
	groupsPageContextMobile: {
		flexBasis: "100%",
	},
	modal: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 400,
		background: "white",
		border: "1px solid #979797",
		borderRadius: "1rem",
		padding: "1rem",
		boxShadow: 24,
		p: 4,
		maxHeight: "90vh",
		overflow: "scroll",
		"&::-webkit-scrollbar-thumb": {
			width: 0,
		},
		"&::-webkit-scrollbar": {
			width: 0,
		},
		"&::-webkit-scrollbar-corner": {
			background: "rgba(0,0,0,0)",
		},
	},
	modalDesktop: {
		width: "50vw",
	},
	modalMobile: {
		width: "95vw",
	},
	groupHeader: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		flexWrap: "wrap",
		justifyContent: "space-between",
		width: "100%",
	},
	headerText: {
		color: "#000000",
		fontWeight: 800,
	},
	searchGroups: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		width: "75%",
		background: "#ffffff",
		paddingLeft: "0.7rem",
		borderRadius: "0.5rem",
		marginRight: "5px",
	},
	searchGroupsInPage: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		width: "100%",
		background: "#ffffff",
		paddingLeft: "0.7rem",
		borderRadius: "0.5rem",
	},
	input: {
		flex: 1,
		border: "none",
		outline: "none",
		background: "white",
	},
	headerActions: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
	},
	headerActionsDesktop: {
		width: "45%",
	},
	headerActionsMobile: {
		width: "100%",
	},
	createGroupButton: {
		width: "38%",
		background: "#6F52ED",
		border: "none",
		outline: "none",
		color: "white",
		padding: "0.7rem",
		borderRadius: "0.5rem",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		"&:hover": {
			cursor: "pointer",
		},
	},
	suggestedGroups: {
		width: "100%",
		background: "#ffffff",
		marginTop: "2rem",
		borderRadius: "1rem",
		color: "black",
		padding: "1rem",
	},
	containerGroupHeader: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
	},
	containerGroupHeaderText: {
		fontSize: "1.2rem",
		fontWeight: 800,
		color: "#000000",
	},
	seeAllText: {
		color: "#0A94F1",
		fontWeight: 600,
	},
	subHeaderText: {
		color: "gray",
	},
	subHeader: {
		margin: "0.8rem 0",
	},
	groupCards: {
		width: "100%",
		flexWrap: "wrap",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
	},
	cardContainer: {
		background: "#ffffff",
		margin: "0.5rem",
		borderRadius: "1rem",
		cursor: "pointer",
		display: "block",
		color: "inherit",
		boxShadow: "1px 1px 8px #888888",
		"&:hover, &:focus": {
			color: "inherit",
		},
	},
	cardContainerDesktop: {
		width: "17vw",
		height: "17.5vw",
	},
	cardContainerMobile: {
		width: "13.5rem",
		height: "14rem",
	},
	cardImage: {
		height: "140px",
		background: "gray",
		borderRadius: "1rem 1rem 0 0",
		"& img": {
			width: "-webkit-fill-available",
			height: "-webkit-fill-available",
			borderRadius: "1rem 1rem 0 0",
			objectFit: "cover",
			objectPosition: "center",
		},
	},
	cardContentHeader: {
		fontSize: "0.8rem",
		fontWeight: 800,
		display: "-webkit-box",
		"-webkit-line-clamp": 2,
		"-webkit-box-orient": "vertical",
		lineHeight: "1rem",
		height: "2rem",
		overflow: "hidden",
	},
	cardContentDetails: {
		fontSize: "0.7rem",
		color: "gray",
		marginRight: "0.5rem",
		display: "-webkit-box",
		"-webkit-line-clamp": 3,
		"-webkit-box-orient": "vertical",
		lineHeight: "1rem",
		height: "3rem",
		overflow: "hidden",
	},
	cardContent: {
		padding: "1rem",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "space-between",
		/* height: "50%", */
	},
	cardInnerContent: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		width: "100%",
	},
	subContent: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		/* display: "-webkit-box",
		"-webkit-line-clamp": 3,
		"-webkit-box-orient": "vertical",
		height: "3rem",
		overflow: "hidden", */
	},
	cardButton: {
		border: "none",
		width: "100%",
		background: "#F1F1F5",
		padding: "0.7rem",
		borderRadius: "0.5rem",
		color: "#707070",
		transition: "all .3s",
		"&:hover": {
			background: "#707070",
			color: "#ffffff",
			cursor: "pointer",
		},
	},
	cardButtonPopularGroup: {
		border: "none",
		background: "#F1F1F5",
		padding: "0.6rem",
		borderRadius: "0.5rem",
		color: "#707070",
		transition: "all .3s",
		"&:hover": {
			background: "#707070",
			color: "#ffffff",
			cursor: "pointer",
		},
	},
	cardButtonText: {
		fontWeight: 800,
		fontSize: "0.8rem",
	},
	browseByIndustryContainer: {
		background: "none",
		padding: "1rem",
		marginTop: "3rem",
	},
	industryCards: {
		height: "100%",
		width: "18%",
	},
	browseIndustryCards: {
		height: "14vh",
	},
	browseIndustryCardsMobile: {
		height: "11rem",
		width: "11rem",
		margin: "0.5rem",
	},
	industryCardImage: {
		background: "#FC5A5A",
		borderRadius: "1rem",
		height: "14vh",
		position: "relative",
		"& img": {},
	},
	industryBadge: {
		position: "absolute",
		bottom: "-30%",
		borderRadius: "0.6rem",
		width: "60%",
		left: "20%",
		height: "60%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},
	industryBadgeText: {
		color: "#000000",
		fontSize: "0.7rem",
	},
	popularGroupsContainer: {
		background: "#ffffff",
		marginTop: "3rem",
		borderRadius: "1rem",
		color: "black",
		padding: "1rem",
	},
	popularGroupImage: {
		background: "#FC5A5A",
		borderRadius: "1rem",
		height: "7rem",
		margin: "1rem",
	},
	popularGroupContent: {
		height: "7rem",
		borderRadius: "1rem",
		border: "0.1rem solid #F1F1F5",
		margin: "1rem 0",
	},
	popularGroupInnerContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		height: "100%",
	},
	popularGroupMobile: {
		margin: "0 1rem",
	},
	circle: {
		background: "#FC5A5A",
		borderRadius: "1rem",
		height: "1rem",
		width: "1rem",
		margin: "0.1rem",
	},
	modalCloseIcon: {
		position: "absolute",
		right: 0,
		top: 0,
		outline: "none",
		border: "none",
		background: "none",
		"&:hover": {
			cursor: "pointer",
		},
	},
	modalHeader: {
		position: "relative",
	},
	inputFieldHeader: {
		fontWeight: 800,
		fontSize: "0.8rem",
	},
	inputFieldHeaderContainer: {
		marginBottom: "0.3rem",
	},
	inputComponent: {
		border: "none",
		background: "none",
		outline: "none",
		width: "100%",
	},
	inputContainer: {
		border: "1px solid #CFD0D2",
		borderRadius: "1rem",
		padding: "0.4rem",
	},
	optionsContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	optionComponent: {
		position: "relative",
		background: "#6F52ED",
		minWidth: "3rem",
		borderRadius: "1rem",
		margin: "0.3rem",
		color: "white",
		padding: "0.1rem 0.5rem",
	},
	optionsCloseIcon: {
		position: "absolute",
		right: "5px",
		top: "16%",
	},
	inputFieldContainer: {
		marginBottom: "1rem",
	},
	groupPrivacyContainer: {
		marginBottom: "1rem",
	},
	permissionsContainer: {
		marginBottom: "1rem",
	},
	eachOption: {
		marginRight: "1rem",
	},
	bannerContainer: {
		marginBottom: "1rem",
	},
	groupBody: {
		height: "10rem",
		background: "#D8D8D8",
		display: "flex",
		flexDirection: "row-reverse",
		alignItems: "flex-start",
		padding: "1rem",
	},
	editBanner: {
		background: "#171725",
		color: "white",
		borderRadius: "0.3rem",
		border: "none",
		outline: "none",
	},
	editBannerText: {
		color: "white",
		fontSize: "0.8rem",
	},
	editFooter: {
		display: "flex",
		flexDirection: "row-reverse",
	},
	innerCreateGroupButton: {
		background: "#6F52ED",
		color: "white",
		border: "none",
		borderRadius: "1rem",
		padding: "0.5rem",
	},
	privacyContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "flex-start",
		justifyContent: "flext-start",
	},
	permissionsInnerContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "flex-start",
		justifyContent: "flext-start",
	},
});

export default groupPageStyles;
