import React from "react";
import { useDispatch, useSelector } from "react-redux";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { instanceUrl } from "configurations/configurations.js";
import {
	deleteInterestIndustry,
	createInterestIndustry,
} from "configurations/redux/userSlice.js";
import {
	makeStyles,
	Chip,
	TextField,
	Typography,
	Button,
	Box,
} from "@material-ui/core";
import axios from "axios";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
const useStyles = makeStyles(profilePageStyle);

export default function InterestIndustry() {
	const classes = useStyles();
	const interestIndustryList = useSelector(
		(state) => state.user.interestedIndustry
	);
	const IndustryList = () => {
		return (
			interestIndustryList[0] &&
			interestIndustryList.map((item) => {
				return (
					<Chip
						key={item.intsId}
						label={
							<p className={classes.tagDescription}>
								{item.industryName
									? item.industryName
									: item.customIndustryName}
							</p>
						}
						className={classes.chip}
						color={"secondary"}
					/>
				);
			})
		);
	};
	return <div>{interestIndustryList[0] && <IndustryList />}</div>;
}
