import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CustomSearchInput from "components/CustomInput/CustomSearchInput.js";
import Header from "views/Component/Header.js";

import messageDashboardStyle from "assets/jss/material-kit-pro-react/views/pageStyle/MessagePage/messagePageStyle.js";

import MessageChatroomSection from "../MessagePage/MessageChatroomSection";
import MessageConversationSection from "../MessagePage/MessageConversationSection";
import {
	messageChatroomListReset,
	messageChatroomListInfo,
} from "configurations/redux/messageChatroomListSlice.js";
import { messageChatroomInfo } from "configurations/redux/messageChatroomSlice.js";
import {
	messageConversationReset,
	messageConversationByReadId,
	messageConversationByConversationId,
	messageConversationInfo,
} from "configurations/redux/messageConversationSlice.js";

import { Link, useHistory } from "react-router-dom";
import { isMobile, isMobileOnly, isTablet } from "react-device-detect";

import "react-chat-elements/dist/main.css";

import defaultAvatar from "assets/img/placeholder.jpg";
import candidate_avatar from "assets/img/candidate_avatar.jpeg";
import corporate_avatar from "assets/img/corporate_avatar.jpeg";
import Avatar from "@material-ui/core/Avatar";
import { encryptData, gaPageView } from "configurations/configurations.js";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import PropTypes from "prop-types";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import axios from "axios";
import { instanceUrl, header } from "configurations/configurations.js";
import {
	messageInboxInfo,
	messageActiveInboxInfo,
} from "configurations/redux/messageInboxSlice";
import AsyncSelect from "react-select/async";
import { borderBottom } from "@mui/system";
import { createActiveInbox } from "configurations/redux/messageInboxSlice";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { deleteActiveInbox } from "configurations/redux/messageInboxSlice";

const useStyles = makeStyles(messageDashboardStyle);
const MessageDashboardPage = ({ match }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const userStatus = useSelector((state) => state.auth.user);
	const profilePic = useSelector((state) => state.user.profilePic); // once they have access the profile page
	const corpProfilePic = useSelector((state) => state.corpUser.profilePic); // once they have access the profile page
	const [newProfilePic, setNewProfilePic] = React.useState();
	const searchResult = useSelector(
		(state) => state.messageChatroom.searchResult
	);
	const inboxList = useSelector((state) => state.messageInbox.searchResult);
	const activeInboxList = useSelector(
		(state) => state.messageInbox.activeInboxList
	);
	const chatroomUserList = useSelector(
		(state) => state.messageChatroomList.searchResult
	);

	// const chatroomId = useSelector((state)=>state.messageConversation.chatroomId)
	// const connectionList = useSelector((state) => state.user.connectionList);

	const [inbox, setInbox] = React.useState("");

	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setActiveInbox(newValue, true);
	};

	const setActiveInbox = (newValue, reset) => {
		setValue(newValue);
		var inbox = activeInboxList[newValue];
		if (inbox) {
			console.log(inbox);
			if (inbox.type == 1) loadChatroom(inbox.recId);
			else if (inbox.type == 0) loadChatroom(0);
		}
		if (reset) {
			dispatch(messageChatroomListReset());
			dispatch(messageConversationReset());
		}
	};
	// console.log(userStatus);

	// React.useEffect(() => {
	// 	console.log(activeInboxList);
	// 	if (activeInboxList.length > 0) {
	// 		console.log(activeInboxList[0]);
	// 		var timer = setInterval(() => {
	// 			var validTab = document.getElementById(
	// 				"activeInbox_" + activeInboxList[0].value
	// 			);
	// 			console.log(validTab);
	// 			if (validTab) {
	// 				clearInterval(timer);
	// 				setValue(activeInboxList[0].value);
	// 				if (activeInboxList[0].inboxType == 1)
	// 					loadChatroom(activeInboxList[0].value);
	// 				else loadChatroom(0);
	// 			}
	// 		}, 300);
	// 	}
	// }, [activeInboxList]);

	const loadChatroom = (bizId) => {
		const requestInfo = {
			acctId: userStatus.acctId,
			accessToken: localStorage.getItem("token"),
			email: userStatus.email,
			lastRecordSize: 0,
			requestRecordSize: 15,
			searchResult: searchResult,
			keyword: "",
			bizId: bizId,
		};
		console.log(requestInfo);
		dispatch(messageChatroomInfo(requestInfo));
	};

	const loadConversationByReadId = (recId) => {
		const requestInfo = {
			acctId: userStatus.acctId,
			accessToken: localStorage.getItem("token"),
			email: userStatus.email,
			lastRecordSize: 0,
			requestRecordSize: 15,
			readId: recId,
		};
		dispatch(messageConversationByReadId(requestInfo));
	};

	const loadConversationByConversationId = (recId) => {
		const requestInfo = {
			acctId: userStatus.acctId,
			accessToken: localStorage.getItem("token"),
			email: userStatus.email,
			lastRecordSize: 0,
			requestRecordSize: 15,
			conversationId: recId,
		};
		console.log(requestInfo);
		dispatch(messageConversationByConversationId(requestInfo));
	};

	const getChatroomNavigate = (item) => {
		const requestProfile = {
			acctId: userStatus.acctId,
			email: userStatus.email,
			accessToken: localStorage.getItem("token"),
			lastRecordSize: 0,
			requestRecordSize: 15,
			chatroomId: item,
			keyword: "",
		};
		console.log(requestProfile);
		dispatch(messageChatroomListInfo(requestProfile));
		dispatch(messageConversationInfo(requestProfile));
	};

	useEffect(() => {
		var timer = setInterval(() => {
			if (activeInboxList.length > 0) {
				clearInterval(timer);
				if (chatroomUserList && chatroomUserList.length > 0) {
					var currentUser = chatroomUserList.filter((item, index) => {
						return item.acctId == userStatus.acctId;
					});
					if (
						currentUser &&
						currentUser.length > 0 &&
						currentUser[0].bizId > 0
					) {
						addActiveInbox(currentUser[0].bizId, 1);
						console.log("add active inbox");
					}
				}
			}
		}, 500);
	}, [chatroomUserList]);

	useEffect(() => {
		if (activeInboxList && activeInboxList.length > 0) {
			console.log("check active inbox");
			for (var i = 0; i < activeInboxList.length; i++) {
				var currentUser = chatroomUserList.filter((item, index) => {
					return item.acctId == userStatus.acctId;
				});
				console.log(currentUser);
				if (currentUser && currentUser.length > 0 && currentUser[0].bizId > 0) {
					if (activeInboxList[i].recId == currentUser[0].bizId) {
						setActiveInbox(i, false);
					}
				}
			}
		}
	}, [activeInboxList]);

	const addActiveInbox = (recordId, inboxType) => {
		const request = {
			acctId: userStatus.acctId,
			accessToken: localStorage.getItem("token"),
			email: userStatus.email,
			recordId: recordId,
			inboxType: inboxType,
			activeInboxList: activeInboxList,
		};
		console.log(request);
		dispatch(createActiveInbox(request));
	};

	React.useEffect(() => {
		gaPageView(window.location.pathname);
		const request = {
			acctId: userStatus.acctId,
			accessToken: localStorage.getItem("token"),
			email: userStatus.email,
			lastRecordSize: 0,
			requestRecordSize: 5,
			keyword: "",
		};
		dispatch(messageInboxInfo(request));
		const requestInbox = {
			acctId: userStatus.acctId,
			accessToken: localStorage.getItem("token"),
			email: userStatus.email,
		};
		dispatch(messageActiveInboxInfo(request));
		if (userStatus.acctTypeId === 1 || userStatus.acctTypeId === 3)
			setNewProfilePic(profilePic);
		else setNewProfilePic(corpProfilePic);
		history.replace(match.url.replace(match.params.recId, 0));
		if (
			match.params.recId > 0 ||
			match.params.recId.includes("N") ||
			match.params.recId.includes("C")
		) {
			console.log("load");
			if (match.params.recId.includes("C")) {
				var timer = setInterval(() => {
					var message = document.getElementById("conversationCard");
					var messageSection = document.getElementById(
						"messageConversationSection"
					);
					if (messageSection) messageSection.style.visibility = "hidden";
					if (message && messageSection) {
						console.log(message);
						clearInterval(timer);
						getChatroomNavigate(match.params.recId.replace("C", ""));
						setTimeout(() => {
							messageSection.style.visibility = "visible";
						}, 500);
					}
				}, 100);
			}
			if (match.params.recId.includes("N"))
				loadConversationByConversationId(match.params.recId.replace("N", ""));
			if (match.params.recId > 0) loadConversationByReadId(match.params.recId);
		} else {
			dispatch(messageChatroomListReset());
			dispatch(messageConversationReset());
		}
	}, []);

	React.useEffect(() => {
		loadChatroom(0);
		window.scrollTo(0, 0);
		mobileResize();
	}, []);

	const mobileResize = () => {
		if (isMobile) {
			var timer = setInterval(() => {
				var chatRoomCard = document.getElementById("chatRoomCard");
				if (chatRoomCard) {
					clearInterval(timer);
					chatRoomCard.style.height = window.innerHeight - 230 + "px";
				} else {
					setTimeout(() => {
						clearInterval(timer);
					}, 3000);
				}
			}, 50);
		}
	};

	function TabPanel(props) {
		const { children, value, index, ...other } = props;

		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
				{...other}
				style={{ backgroundColor: "#f2f3f7" }}
			>
				{value === index && <Box>{children}</Box>}
			</div>
		);
	}

	TabPanel.propTypes = {
		children: PropTypes.node,
		index: PropTypes.any.isRequired,
		value: PropTypes.any.isRequired,
	};

	function a11yProps(value, recId, type) {
		return {
			id: `simple-tab-${value}`,
			"aria-controls": `simple-tabpanel-${value}`,
		};
	}

	const handleClose = (event, inbox) => {
		event.stopPropagation();
		if (inbox) {
			var request = {
				inboxId: inbox.value,
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				activeInboxList: activeInboxList,
			};
			dispatch(deleteActiveInbox(request));
			setValue(0);
			loadChatroom(0);
		}
	};

	const connectionSelectStyles = {
		option: (provided, state) => ({
			...provided,
			fontWeight: 500,
			fontSize: "14px",
			backgroundColor: state.isSelected ? "#687bfe" : "white",
			color: state.isSelected ? "white" : "black",
			cursor: "pointer",
			overflow: "hidden",
			whiteSpace: "nowrap",
			textOverflow: "ellipsis",
		}),
		input: (provided, state) => ({
			...provided,
			fontWeight: 700,
			fontSize: "14px",
		}),
		singleValue: (provided, state) => ({
			...provided,
			fontWeight: 500,
			fontSize: "14px",
		}),
		placeholder: (provided, state) => ({
			...provided,
			fontWeight: 500,
			fontSize: "14px",
			overflow: "hidden",
			whiteSpace: "nowrap",
			textOverflow: "ellipsis",
		}),
		control: (provided, state) => ({
			...provided,
			height: "52px",
		}),
		noOptionsMessage: (provided, state) => ({
			...provided,
			fontWeight: 500,
			fontSize: "14px",
		}),
		loadingMessage: (provided, state) => (
			console.log(state),
			{
				...provided,
				fontWeight: 500,
				fontSize: "14px",
			}
		),
	};

	var imgHeight = classes.parallax;
	// if (isMobile)
	// 	imgHeight = classNames(classes.parallax, classes.parallaxMobile);
	return (
		<div style={{ overflow: "hidden" }} className={classes.messageGrid}>
			<Header />
			<div id="parallax" className={imgHeight}>
				{/* 	<Parallax
					image={require("assets/img/bg-13.jpg")}
					filter={isMobile ? "dark" : "primary"}
					className={imgHeight}
				/> */}
			</div>
			<div>
				<GridContainer justifyContent="center">
					<GridItem
						xs={12}
						sm={4}
						md={4}
						lg={3}
						style={{
							paddingRight: isMobileOnly ? "15px" : "0",
							backgroundColor: "#fff",
							borderBottom: "3px solid #D9E1E7",
						}}
					>
						<AsyncSelect
							cacheOptions
							value={""}
							defaultOptions={inboxList}
							styles={connectionSelectStyles}
							onChange={(event, target) => {
								// console.log(event);
								// setFieldValue("authorAcctId", event.value);
								// setInbox(event.value);
								addActiveInbox(event.value, event.type);
							}}
							formatOptionLabel={(inbox) => (
								<div className="connection-option">
									<img
										style={{
											borderRadius: "50%",
											verticalAlign: "middle",
											marginRight: 10,
											marginLeft: 2,
											width: "35px",
											height: "35px",
										}}
										src={inbox.profilePic ? inbox.profilePic : defaultAvatar}
										alt="inbox-image"
									/>
									<span
										style={{
											textTransform: "capitalize",
										}}
									>
										{inbox.name}
									</span>
								</div>
							)}
							loadOptions={(query) => {
								const body = {
									acctId: userStatus.acctId,
									accessToken: localStorage.getItem("token"),
									email: userStatus.email,
									lastRecordSize: 0,
									requestRecordSize: 5,
									keyword: query,
								};
								return new Promise((resolve, reject) => {
									axios({
										method: "GET",
										params: body,
										url: `${instanceUrl}/api/mm/get-inbox`,
										headers: header,
									})
										.then((res) => {
											if (res.status === 201) {
												var profileValues = [];
												for (
													var i = 0;
													i < res.data.payload.inboxList.length;
													i++
												) {
													var inbox = res.data.payload.inboxList[i];
													var name = "";
													var lastName =
														inbox.lastName == "" ? " " + inbox.lastName : "";
													var pageName =
														inbox.pageName != "" ? inbox.pageName : "Corporate";
													if (inbox.recordType == 1) {
														name = inbox.companyName + " (" + pageName + ")";
													} else if (inbox.recordType == 0) {
														name = inbox.firstName + lastName + " (You)";
													}
													profileValues.push({
														value: inbox.inboxId,
														recId: inbox.recordId,
														type: inbox.recordType,
														name: name,
														profilePic: inbox.profilePic,
													});
												}
												resolve(profileValues);
											}
										})
										.catch(reject);
								});
							}}
							placeholder="Search Your Inbox to open"
						/>
					</GridItem>
					<GridItem
						xs={12}
						sm={8}
						md={8}
						lg={9}
						style={{
							paddingLeft: isMobileOnly ? "15px" : "0",
							backgroundColor: "#fff",
							borderBottom: "3px solid #D9E1E7",
						}}
					>
						<Tabs
							value={value}
							onChange={handleChange}
							variant="scrollable"
							scrollButtons
							aria-label="visible arrows tabs example"
							sx={{
								[`& .${tabsClasses.scrollButtons}`]: {
									"&.Mui-disabled": { opacity: 0.3 },
								},
							}}
						>
							{activeInboxList.map((item, index) => (
								<Tab
									id={"activeInbox_" + item.value}
									key={"activeInbox_" + item.value}
									label={
										<div>
											<img
												src={item.profilePic ? item.profilePic : defaultAvatar}
												style={{
													width: "28px",
													height: "28px",
													borderRadius: "50%",
													float: "left",
												}}
											/>

											<p
												style={{
													fontSize: "14px",
													margin: "5px 0",
													whiteSpace: "nowrap",
													padding:
														item.type == 1 ? "0 25px 0 35px" : "0 0 0 35px",
													textTransform: "capitalize",
												}}
											>
												{item.name}
											</p>
											{item.type == 1 && (
												<IconButton
													component="div"
													onClick={(event) => handleClose(event, item)}
													type="reset"
													// className={classes.iconButton}
													style={{
														top: "2px",
														right: "0",
														position: "absolute",
													}}
													aria-label="cancel"
												>
													<CloseIcon />
												</IconButton>
											)}
										</div>
									}
									{...a11yProps(index)}
									style={{ padding: "12px 16px" }}
								/>
							))}
						</Tabs>
					</GridItem>
				</GridContainer>
			</div>
			{isMobileOnly ? (
				<GridContainer>
					<GridItem>
						<div
							className={
								(classNames(classes.main, classes.mainRaised),
								isMobileOnly
									? classes.mainRaisedMobile
									: classes.mainRaisedTablet)
							}
							id="message_end"
						>
							<Card className={classes.messageCardBorder} id="chatRoomCard">
								<CardHeader className={classes.messageCardHeader}>
									<h2
										className={classNames(
											classes.title,
											classes.textCenter,
											classes.conversationTitle
										)}
									>
										Message
									</h2>
									<GridContainer>
										<GridItem xs={12} sm={12} md={12}>
											<Formik
												initialValues={{
													keyword: "",
												}}
												onSubmit={(data) => {
													const requestProfile = {
														acctId: userStatus.acctId,
														accessToken: localStorage.getItem("token"),
														email: userStatus.email,
														keyword: data.keyword,
														lastRecordSize: 0,
														requestRecordSize: 15,
														searchResult: searchResult,
														bizId: 0,
													};
													dispatch(messageChatroomInfo(requestProfile));
												}}
											>
												{({
													values,
													handleChange,
													handleSubmit,
													setFieldValue,
												}) => (
													<form onSubmit={handleSubmit}>
														<GridContainer
															className={classes.chatRoomSearchBox}
														>
															<GridItem
																xs={12}
																sm={12}
																md={8}
																className={classes.searchBarInput}
															>
																<CustomSearchInput
																	labelText="Search Conversation"
																	id="keyword"
																	name="keyword"
																	values={values.keyword}
																	// onChange={handleChange}
																	onChange={(event) => {
																		setFieldValue(
																			"keyword",
																			event.target.value.trim()
																		);
																	}}
																	fullWidth
																	inputProps={{
																		autoComplete: "off",
																	}}
																	close={true}
																	className={classes.borderRadius}
																/>
																{/* <CustomInput
																	labelText="Keyword"
																	id="keyword"
																	name="keyword"
																	values={values.keyword}
																	onChange={handleChange}
																	formControlProps={{
																		fullWidth: true,
																	}}
																	inputProps={{
																		autoComplete: "off",
																	}}
																/>
																<Button
																	onClick={handleSubmit}
																	className={classes.searchBarButton}
																	color="success"
																	fullWidth
																>
																	Search
																</Button> */}
															</GridItem>
														</GridContainer>
													</form>
												)}
											</Formik>
										</GridItem>
									</GridContainer>
								</CardHeader>
								<CardBody
									id="conversation_list"
									className={classes.messageCardBodyMobile}
								>
									<MessageChatroomSection />
								</CardBody>
							</Card>
						</div>
					</GridItem>
					<MessageConversationSection />

					{/* <GridItem>
						<Footer />
					</GridItem> */}
				</GridContainer>
			) : (
				<GridContainer justifyContent="center">
					<GridItem
						xs={4}
						sm={4}
						md={4}
						lg={3}
						className={classes.leftGrid}
						style={{ zIndex: "0" }}
					>
						<div id="message_end">
							<Card
								className={
									classes.messageCardBorder + " " + classes.messageCard
								}
							>
								<CardHeader className={classes.messageCardHeader}>
									<GridContainer>
										<GridItem xs={12} sm={2} md={2} style={{ display: "flex" }}>
											{/* <Link
												to={
													userStatus.acctTypeId === 1 ||
													userStatus.acctTypeId === 3
														? {
																pathname: `/profile-page/${encryptData(
																	userStatus.acctId
																)} `,
														  }
														: {
																pathname: `/corporate-profile-page/${encryptData(
																	userStatus.acctId
																)} `,
														  }
												}
												target="_blank"
												color="transparent"
											>
												<Avatar
													alt="..."
													src={
														userStatus
															? newProfilePic
																? newProfilePic
																: userStatus.profilePic
																? userStatus.profilePic
																: userStatus.acctTypeId === 1 ||
																  userStatus.acctTypeId === 3
																? candidate_avatar
																: corporate_avatar
															: null
														// userStatus.profilePic != null
														// 	? userStatus.profilePic
														// 	: userStatus.acctTypeId === 1 ||
														// 	  userStatus.acctTypeId === 3
														// 	? candidate_avatar
														// 	: corporate_avatar
													}
												/>
											</Link> */}
										</GridItem>
										<GridItem xs={12} sm={8} md={8}>
											<h4
												id="messagingTitle"
												className={classNames(
													classes.title,
													classes.textCenter,
													classes.conversationTitle
												)}
											>
												Message
											</h4>
										</GridItem>
										<GridItem xs={12} sm={12} md={12}>
											<Formik
												initialValues={{
													keyword: "",
												}}
												onSubmit={(data) => {
													const requestProfile = {
														acctId: userStatus.acctId,
														accessToken: localStorage.getItem("token"),
														email: userStatus.email,
														keyword: data.keyword,
														lastRecordSize: 0,
														requestRecordSize: 15,
														searchResult: searchResult,
														bizId: 0,
													};
													dispatch(messageChatroomInfo(requestProfile));
												}}
											>
												{({
													values,
													handleChange,
													handleSubmit,
													setFieldValue,
												}) => (
													<form onSubmit={handleSubmit}>
														<GridContainer
															className={classes.chatRoomSearchBox}
															spacing={0}
														>
															<GridItem
																xs={12}
																sm={12}
																md={12}
																className={classes.searchBarInput}
															>
																<CustomSearchInput
																	style="text-align: center"
																	labelText="Search Conversation"
																	id="keyword"
																	name="keyword"
																	values={values.keyword}
																	// onChange={handleChange}
																	onChange={(event) => {
																		setFieldValue(
																			"keyword",
																			event.target.value.trim()
																		);
																	}}
																	formControlProps={{
																		fullWidth: true,
																	}}
																	inputProps={{
																		autoComplete: "off",
																	}}
																	close={true}
																	className={classes.borderRadius}
																/>
															</GridItem>
															{/* <GridItem xs={12} sm={12} md={4}>
																<Button
																	onClick={handleSubmit}
																	className={classes.searchBarButton}
																	color="success"
																	fullWidth
																>
																	Search
																</Button>
															</GridItem> */}
														</GridContainer>
													</form>
												)}
											</Formik>
										</GridItem>
									</GridContainer>
								</CardHeader>
								<CardBody
									id="conversation_list"
									className={classes.messageCardBody}
								>
									<MessageChatroomSection />
								</CardBody>
							</Card>
						</div>
					</GridItem>
					<GridItem
						xs={8}
						sm={8}
						md={8}
						lg={9}
						className={classes.rightGrid}
						id="rightGrid"
					>
						<div
							className={
								(classNames(classes.main, classes.mainRaised),
								classes.messageConversationCard)
							}
							id="message_end"
						>
							<MessageConversationSection />
						</div>
					</GridItem>
					{/* <GridItem
						xs={12}
						sm={2}
						md={2}
						className={(classes.gridPadding, classes.footerHeight)}
					>
						<FooterVertical />
					</GridItem> */}
				</GridContainer>
			)}
		</div>
	);
};
export default MessageDashboardPage;
