import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
	instanceUrl,
	header,
	emptyCache,
} from "configurations/configurations.js";
import { jobSelectReset } from "./jobManagementSlice";

const jobAppManagementSlice = createSlice({
	name: "jobApplicationManagement",
	initialState: {
		isUpdated: true,
		isLoading: false,
		jobPostList: [],
	},
	reducers: {
		JOB_APPLY_LOADING: (state) => {
			state.isLoading = true;
		},
		JOB_APPLY_SUCCESS: (state, action) => {
			state.isUpdated = true;
			state.isLoading = false;
			state.jobPostList.push(action.payload.jobPost);
			localStorage.setItem("token", action.payload.token);
		},
		JOB_APPLY_FAIL: (state, action) => {
			state.isUpdated = false;
			state.isLoading = false;
			localStorage.removeItem("token");
		},
		JOB_STATUS_UPDATE_SUCCESS: (state, action) => {
			state.isUpdated = true;
			state.isLoading = false;
			localStorage.setItem("token", action.payload.token);
		},
		JOB_STATUS_UPDATE_FAIL: (state, action) => {
			state.isUpdated = false;
			state.isLoading = false;
			localStorage.removeItem("token");
		},
	},
});

const {
	JOB_APPLY_FAIL,
	JOB_APPLY_LOADING,
	JOB_APPLY_SUCCESS,
	JOB_STATUS_UPDATE_FAIL,
	JOB_STATUS_UPDATE_SUCCESS,
} = jobAppManagementSlice.actions;

export const createJobApplication = ({
	acctId,
	accessToken,
	email,
	applicantId,
	jobPostId,
	applStatusId,
	applDate,
}) => async (dispatch) => {
	dispatch(JOB_APPLY_LOADING);
	const body = {
		acctId,
		accessToken,
		email,
		applicantId,
		jobPostId,
		applStatusId,
		applDate,
	};
	console.log(body);
	await axios({
		method: "POST",
		headers: header,
		url: `${instanceUrl}/api/am/create-job-application`,
		data: body,
	})
		.then((res) => {
			console.log(res);
			const response = {
				jobPost: {
					jobPostId: jobPostId,
					applStatusId: applStatusId,
				},
				token: res.data.token,
			};
			dispatch(JOB_APPLY_SUCCESS(response));
			dispatch(jobSelectReset());
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};
export const saveJobApplication = ({
	acctId,
	accessToken,
	jobPostId,
	email,
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		jobPostId,
		email,
	};
	await axios({
		method: "PUT",
		headers: header,
		url: `${instanceUrl}/api/jm/add-favourite-job-post`,
		data: body,
	})
		.then((res) => {
			console.log(res);
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export default jobAppManagementSlice.reducer;
