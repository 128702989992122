import { opptyDesignColor } from "assets/jss/material-kit-pro-react";
import {
	grayColor,
	primaryColor,
	title,
	blackColor,
	container,
} from "assets/jss/material-kit-pro-react.js";

const footerStyle = (theme) => ({
	footer: {
		"& ul li": {
			display: "inline-block",
		},
		"& h4, & h5": {
			color: grayColor[1],
			textDecoration: "none",
		},
		"& small": {
			fontSize: "75%",
			color: grayColor[10],
		},
		padding: "15px 0px 0px 0px !important",

		bottom: 0,
		left: 0,
		right: 0,
		[theme.breakpoints.down("md")]: {
			textAlign: "center",
		},
		/* textAlign: "center", */

		/* "@media (min-width: 768px)": {
			textAlign: "center",
		}, */
	},
	pullCenter: {
		display: "inline-block",
		float: "none",
	},
	container: {
		...container,
		zIndex: "5",
		"@media (min-width: 576px)": {
			maxWidth: "70%",
		},
		"@media (min-width: 768px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 960px)": {
			maxWidth: "80%",
		},
		"@media (min-width: 992px)": {
			maxWidth: "100%",
		},
		// "@media (min-width: 1024px)": {
		// 	maxWidth: "100%",
		// },
		"@media (min-width: 1200px)": {
			maxWidth: "1200px",
		},
		// [theme.breakpoints.down("sm")]: {
		// 	maxWidth: "80%",
		// },
	},
	linksVertical: {
		"& li": {
			display: "block !important",
			marginLeft: "-5px",
			marginRight: "-5px",
			"& a": {
				display: "block",
				position: "relative",
				borderRadius: "3px",
				padding: "5px",
				color: "inherit",
				fontWeight: "500",
				textDecoration: "none",
			},
			[theme.breakpoints.down("xs")]: {
				marginLeft: "-20px",
				marginRight: "-35px",
				textAlign: "left",
			},
		},
		/* "@media (min-width: 576px)": {
			align: "center",
		},
		"@media (min-width: 768px)": {
			align: "center",
		}, */
	},
	copyRight: {
		//paddingTop: "15px",
		textDecoration: "none",
	},
	liSocialButtons: {
		marginRight: "10px",
	},
	copyRightWordings: {
		color: opptyDesignColor + "!important",
		display: "inline-block",
		padding: "5px 0",
	},
	title: {
		...title,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		position: "relative",
		marginTop: "15px",
		marginBottom: "15px !important",
		/* 	minHeight: "32px", */
		textDecoration: "none",
		fontSize: "20px! important",
		color: blackColor + "!important",
		/* "& svg": {
			width: "32px",
			height: "32px",
			marginRight: "20px",
			marginTop: "10px",
		}, */
		[theme.breakpoints.down("sm")]: {
			fontSize: "16px !important",
		},
	},
	description: {
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		position: "relative",
		color: blackColor + "!important",
		fontSize: "17px !important",
		margin: "10px !important",
		[theme.breakpoints.down("sm")]: {
			fontSize: "14px !important",
		},
	},
	smallDescription: {
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		position: "relative",
		color: "#959595!important",
		fontSize: "15px !important",
		fontWeight: "400 !important",
		[theme.breakpoints.down("xs")]: {
			fontSize: "13px !important",
		},
	},
	cardFrame: {
		borderRadius: "18px",
		boxShadow: "none",
		[theme.breakpoints.down("sm")]: {
			margin: "auto",
			maxWidth: "90%",
		},
	},
	emailButton: {
		borderRadius: "10px",
		height: "70px",
		fontSize: "17px !important",

		[theme.breakpoints.down("md")]: {
			height: "60px",
		},
		[theme.breakpoints.down("sm")]: {
			height: "40px",
		},
	},
	whiteTitle: {
		color: "#fff !important",
	},
	socialButton: {
		fontSize: "20px",
		minWidth: "41px",
		paddingLeft: "12px",
		paddingRight: "12px",
		width: "50px",
		height: "50px",
		borderRadius: "12px",
		"& svg": {
			width: "40px",
			height: "40px",
		},
		[theme.breakpoints.down("sm")]: {
			width: "40px",
			height: "40px",
			minWidth: "40px",
			paddingLeft: "3px",
			paddingRight: "0px",
			borderRadius: "12px",
			"& svg": {
				width: "30px",
				height: "30px",
			},
		},
		[theme.breakpoints.down("xs")]: {
			width: "30px",
			height: "30px",
			minWidth: "30px",
			paddingLeft: "3px",
			paddingRight: "0px",
			borderRadius: "12px",
			"& svg": {
				width: "20px",
				height: "20px",
			},
		},
	},
	rightAlign: {
		[theme.breakpoints.down("xs")]: {
			textAlign: "right",
		},
	},
	footerTitleMargin: {
		marginBottom: "0px !important",
	},
	footerIconMargin: {
		"& svg": {
			width: "28px !important",
			height: "28px !important",
			textAlign: "left",
			padding: "1px",
			borderRadius: "5px",
			/* backgroundColor: "#000000", */
			[theme.breakpoints.down("xs")]: {
				width: "22px !important",
				height: "22px !important",
			},
		},
		display: "flex",
		alignItems: "flex-start",
		minWidth: "1px !important",
		float: "right",
		width: "30px !important",
		height: "30px !important",
		padding: "0px !important",
	},
	facebookIconColor: {
		"& svg": {
			color: "#4267B2",
		},
	},
	instagramIconColor: {
		"& svg": {
			color: "#FF5BDE",
		},
	},
	linkedinIconColor: {
		"& svg": {
			color: "#0A94F1",
		},
	},
	footerNav: {
		position: "fixed",
		left: 0,
		bottom: 0,
		width: "100%",
		textAlign: "center",
		alignItems: "center",
		margin: 0,
		border: "2px solid #D0DDE7",
	},
});
export default footerStyle;
