import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Header from 'views/Component/Header.js';
import FilterComponent from 'views/Page/SearchPage/FilterComponent.js';
import { useSelector, useDispatch } from 'react-redux';
import { searchJobInfo } from 'configurations/redux/searchJobSlice';

import LeftSidebar from 'views/Component/LeftSidebar.js';
import Hidden from '@material-ui/core/Hidden';

import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import searchJobPageStyle from 'assets/jss/material-kit-pro-react/views/pageStyle/SearchPage/searchJobPageStyle.js';
import JobComponentInPage from './JobComponentInPage';
import searchProfilePageStyle from 'assets/jss/material-kit-pro-react/views/pageStyle/SearchPage/searchProfilePageStyle.js';

const useStyles = makeStyles(searchProfilePageStyle);

export default function SearchJobPage({ match }) {
  const classes = useStyles();
  const searchParams = useSelector(state => state.searchJob.searchParams);
  const dispatch = useDispatch();
  const userStatus = useSelector(state => state.auth.user);
  const auth = useSelector(state => state.auth);

  React.useEffect(() => {
    const requestProfile = {
      acctId: auth.isPublic ? 0 : userStatus.acctId,
      accessToken: auth.isPublic ? auth.publicToken : localStorage.getItem('token'),
      email: auth.isPublic ? auth.publicEmail : userStatus.email,
      keyword: searchParams.keyword,
      countryId: searchParams.countryId,
      locationId: searchParams.locationId,
      companyName: '',
      industryId: searchParams.industryId,
      hotJobFlag: 0,
      jobPostTypeId: searchParams.jobPostTypeId,
      jobTypeId: searchParams.jobTypeId,
      lastRecordSize: 0,
      experienceId: searchParams.experienceId,
      currencyId: searchParams.currencyId,
      salaryMin: searchParams.salaryMin,
      salaryMax: searchParams.salaryMax,
      requestRecordSize: 12,
      genericSearch: true,
    };
    dispatch(searchJobInfo(requestProfile));
  }, []);

  return (
    <div>
      <Header />
      <FilterComponent match={match} />

      <div className={classes.filterBody}>
        <div className={classes.pageHeader} id="searchResultJob">
          <div className={classes.container}>
            <GridContainer spacing={2}>
              <Hidden smDown>
                <GridItem md={3}>{auth.isAuthenticated && <LeftSidebar />}</GridItem>
              </Hidden>
              <GridItem xs={12} sm={12} md={9}>
                <JobComponentInPage />
              </GridItem>
              <Hidden mdDown>
                <GridItem md={2}></GridItem>
              </Hidden>
            </GridContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
