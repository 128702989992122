import React from "react";
import axios from "axios";

import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";

import { instanceUrl } from "configurations/configurations.js";
import { updateBasicInfo } from "configurations/redux/userSlice.js";
import { buildProfile } from "configurations/redux/authSlice.js";
import { profileUpdateSchema } from "configurations/configurations.js";
import ProfileStrengthTip from "./ProfileStrengthTip.js";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Autocomplete, {
	createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Danger from "components/Typography/Danger.js";

import Close from "@material-ui/icons/Close";
import People from "@material-ui/icons/People";
import Phone from "@material-ui/icons/Phone";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js";
import { isDesktop } from "react-device-detect";
import IconLibrary from "views/Component/IconLibrary.js";

const useStyles = makeStyles(profilePageStyle);
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

const BasicProfileEditModal = ({ open, onClose, extension }) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	/* 	const filterOptions = createFilterOptions({
		matchFrom: "start",
		stringify: (option) => option.countryName,
	}); */

	const profileRecord = useSelector((state) => state.user.profileRecord);
	const user = useSelector((state) => state.user);
	const userStatus = useSelector((state) => state.auth.user);
	const buildProfileFlag = useSelector((state) => state.auth.buildProfileFlag);
	const [countryList, setCountryList] = React.useState([]);
	const [areaCodeList, setAreaCodeList] = React.useState([]);
	const [locationList, setLocationList] = React.useState([]);
	const [countryChange, setCountryChange] = React.useState();
	const [suggestedAreaCode, setSuggestedAreaCode] = React.useState();
	const [contactNoChange, setContactNoChange] = React.useState();

	React.useEffect(() => {
		let mounted = true;
		let CancelToken = axios.CancelToken;
		const source = CancelToken.source();
		const fetchCountryList = async () => {
			try {
				const res = await axios.get(`${instanceUrl}/api/md/get-all-country`, {
					cancelToken: source.token,
				});

				setCountryList(res.data.payload);
			} catch (error) {
				if (axios.isCancel(error)) {
					//console.log("unmount caused");
				} else {
					throw error;
				}
			}
		};
		const fetchAreaList = async () => {
			try {
				const res = await axios.get(`${instanceUrl}/api/md/get-all-area-code`, {
					cancelToken: source.token,
				});
				setAreaCodeList(res.data.payload);
			} catch (error) {
				if (axios.isCancel(error)) {
					//console.log("unmount caused");
				} else {
					throw error;
				}
			}
		};
		if (mounted) {
			fetchCountryList();
			fetchAreaList();
			// fetchLocationList();
		}

		return () => {
			mounted = false;
			source.cancel();
		};
	}, []);

	React.useEffect(() => {
		if (countryChange) {
			console.log(countryChange);
			let CancelToken = axios.CancelToken;
			const source = CancelToken.source();
			const fetchLocationList = async (countryId) => {
				try {
					const res = await axios.get(
						`${instanceUrl}/api/md/get-all-location-by-country`,
						{
							cancelToken: source.token,
							params: { countryId: countryId },
						}
					);
					setLocationList(res.data.payload);
				} catch (error) {
					if (axios.isCancel(error)) {
						//console.log("unmount caused");
					} else {
						throw error;
					}
				}
			};
			fetchLocationList(countryChange);
			const suggestedAreaCode =
				areaCodeList &&
				areaCodeList.filter((item) => {
					return item.countryId === countryChange;
				})[0];
			if (profileRecord.contactNo === "" || contactNoChange === "")
				setSuggestedAreaCode(suggestedAreaCode);
		}
	}, [countryChange]);

	const modalResize = () => {
		// var timer = setInterval(() => {
		var modalResize = document.getElementById("modalCard");
		if (modalResize) {
			// clearInterval(timer);
			var maxSize = window.innerHeight - 74 - 80 - 70;
			if (maxSize < 0) maxSize = 0;
			modalResize.style.height = maxSize * 0.8 + "px";

			// } else {
			// 	setTimeout(() => {
			// 		clearInterval(timer);
			// 	}, 3000);
		}
		// }, 300);
		// return () => clearInterval(timer);
	};

	// React.useEffect(() => {
	// 	window.addEventListener("resize", modalResize);
	// });

	React.useEffect(() => {
		if (open) {
			if (document.readyState === "complete") {
				if (profileRecord) {
					setCountryChange(profileRecord.countryId);
					setContactNoChange(profileRecord.contactNo);
				}
				setTimeout(() => {
					modalResize();
				}, 500);
			}
			window.addEventListener("resize", modalResize);
			return function cleanup() {
				window.removeEventListener("resize", modalResize);
			};
		}
	}, [open]);

	// React.useEffect(() => {
	// 	if (open) {
	// 		modalResize();
	// 		if (profileRecord) {
	// 			setCountryChange(profileRecord.countryId);
	// 		}
	// 	} else {
	// 		try {
	// 			window.removeEventListener("resize");
	// 		} catch (error) {}
	// 	}
	// 	return function cleanup() {
	// 		try {
	// 			window.removeEventListener("resize");
	// 		} catch (error) {}
	// 	};
	// }, [open]);

	return (
		<React.Fragment>
			<Dialog
				classes={{
					root: classes.modalRoot,
					paper: classes.modalProfileEdit + " " + classes.modal,
				}}
				open={open}
				// TransitionComponent={Transition}
				// keepMounted
				onClose={onClose}
				aria-labelledby="classic-modal-slide-title"
				aria-describedby="classic-modal-slide-description"
			>
				<DialogTitle
					id="signup-modal-slide-title"
					disableTypography
					// disableScrollLock
					className={classes.modalTitleEdit}
				>
					<Button simple className={classes.modalCloseButton} onClick={onClose}>
						<IconLibrary.Close />
						{/* <Close className={classes.modalClose} /> */}
					</Button>
					<h4 className={classes.title + " " + classes.titleAlign}>
						{buildProfileFlag === 0
							? "Please complete your profile before proceeding"
							: "Edit Your Profile"}
					</h4>
				</DialogTitle>

				<Formik
					initialValues={{
						surname: profileRecord.surname ? profileRecord.surname : "",
						firstName: profileRecord.firstName ? profileRecord.firstName : "",
						middleName: profileRecord.middleName
							? profileRecord.middleName
							: "",
						countryId: profileRecord.countryId,
						locationId: profileRecord.locationId,
						areaCodeId: profileRecord.areaCodeId,
						contactNo: profileRecord.contactNo ? profileRecord.contactNo : "",
						headline: profileRecord.headline ? profileRecord.headline : "",
						aboutMe: profileRecord.aboutMe ? profileRecord.aboutMe : "",
					}}
					validationSchema={profileUpdateSchema}
					onSubmit={(data) => {
						const updateRequest = {
							acctId: user.acctId,
							surname: data.surname.trim(),
							firstName: data.firstName.trim(),
							middleName: data.middleName.trim(),
							countryId: data.countryId,
							locationId: data.locationId,
							areaCodeId: data.areaCodeId,
							contactNo: data.contactNo.trim(),
							headline: data.headline.trim(),
							aboutMe: data.aboutMe.trim(),
							accessToken: localStorage.getItem("token"),
						};
						console.log("update request is ", updateRequest);
						//updateBasicProfile(updateRequest);
						dispatch(updateBasicInfo(updateRequest));
						if (buildProfileFlag === 0) {
							dispatch(buildProfile());
						}
						if (data) {
							onClose();
						}
					}}
				>
					{({
						handleChange,
						values,
						errors,
						touched,
						handleBlur,
						handleSubmit,
						setFieldValue,
					}) => (
						<form onSubmit={handleSubmit}>
							<div className={classes.autoHeightBody}>
								<DialogContent
									id="classic-modal-slide-description"
									className={classes.modalBody}
								>
									{extension && <ProfileStrengthTip extension={extension} />}
									<Card className={classes.modalCardBorder}>
										<CardBody
											className={
												isDesktop
													? classes.smallModalBodyDesktop +
													  " " +
													  classes.modalBackground
													: classes.smallModalBody +
													  " " +
													  classes.modalBackground
											}
											id="modalCard"
										>
											<GridContainer justifyContent="center">
												{/**FirstName */}
												<GridItem>
													<div>
														<h4
															className={
																classes.title + " " + classes.modalSubTitle
															}
														>
															Name
														</h4>
													</div>
												</GridItem>
												<GridItem
													xs={12}
													sm={4}
													md={4}
													className={classes.gridHeight}
												>
													<TextField
														variant="outlined"
														id="firstName"
														label="First Name"
														name="fisrtName"
														value={values.firstName}
														helperText={
															touched.firstName ? errors.firstName : ""
														}
														error={
															touched.firstName && Boolean(errors.firstName)
														}
														onChange={(event) => {
															setFieldValue("firstName", event.target.value);
														}}
														placeholder="Required"
														fullWidth
														InputProps={{
															startAdornment: (
																<InputAdornment position="start">
																	<People className={classes.inputIconsColor} />
																</InputAdornment>
															),
														}}
													/>
												</GridItem>
												<GridItem
													xs={12}
													sm={4}
													md={4}
													className={classes.gridHeight}
												>
													<TextField
														variant="outlined"
														id="middleName"
														label="Middle Name"
														name="middleName"
														value={values.middleName}
														helperText={
															touched.middleName ? errors.middleName : ""
														}
														error={
															touched.middleName && Boolean(errors.middleName)
														}
														onChange={(event) => {
															setFieldValue("middleName", event.target.value);
														}}
														fullWidth
														InputProps={{
															startAdornment: (
																<InputAdornment position="start">
																	<People className={classes.inputIconsColor} />
																</InputAdornment>
															),
														}}
													/>
												</GridItem>
												{/**LastName */}
												<GridItem
													xs={12}
													sm={4}
													md={4}
													className={classes.gridHeight}
												>
													<TextField
														variant="outlined"
														id="surname"
														label="Last Name/Surname"
														name="surname"
														value={values.surname}
														helperText={touched.surname ? errors.surname : ""}
														error={touched.surname && Boolean(errors.surname)}
														onChange={(event) => {
															setFieldValue("surname", event.target.value);
														}}
														placeholder="Required"
														fullWidth
														InputProps={{
															startAdornment: (
																<InputAdornment position="start">
																	<People className={classes.inputIconsColor} />
																</InputAdornment>
															),
														}}
													/>
												</GridItem>
												<GridItem>
													<div>
														<h4
															className={
																classes.title + " " + classes.modalSubTitle
															}
														>
															Address
														</h4>
													</div>
												</GridItem>

												<GridItem
													xs={12}
													sm={6}
													md={6}
													className={classes.gridHeight}
												>
													<Autocomplete
														options={countryList}
														value={
															countryList.filter((item) => {
																return item.countryId === values.countryId;
															})[0] || null
														}
														getOptionLabel={(option) =>
															option.countryName ? option.countryName : ""
														}
														getOptionSelected={(option, value) =>
															option.countryId === value.countryId
														}
														filterOptions={createFilterOptions({
															matchFrom: "start",
															stringify: (option) => option.countryName,
														})}
														onChange={(event, value) => {
															console.log(value);
															if (value) {
																setFieldValue("countryId", value.countryId);
																setFieldValue("locationId", "");
																setCountryChange(value.countryId);
															} else {
																setFieldValue("countryId", "");
																setFieldValue("locationId", "");
															}
														}}
														id="countryId"
														name="countryId"
														renderInput={(params) => (
															<TextField
																{...params}
																helperText={
																	touched.countryId ? errors.countryId : ""
																}
																error={
																	touched.countryId && Boolean(errors.countryId)
																}
																label={"Country"}
																variant="outlined"
															/>
														)}
													/>
												</GridItem>

												<GridItem
													xs={12}
													sm={6}
													md={6}
													className={classes.gridHeight}
												>
													<Autocomplete
														options={locationList}
														disabled={!values.countryId}
														value={
															locationList.filter((item) => {
																return item.locationId === values.locationId;
															})[0] || null
														}
														getOptionSelected={(option, value) =>
															option.locationName === value.locationName
														}
														getOptionLabel={(option) =>
															option.locationName ? option.locationName : ""
														}
														onChange={(event, value) => {
															if (value) {
																setFieldValue("locationId", value.locationId);
															} else {
																setFieldValue("locationId", "");
															}
														}}
														id="locationId"
														name="locationId"
														renderInput={(params) => (
															<TextField
																{...params}
																label={"Location"}
																variant="outlined"
																helperText={
																	touched.locationId ? errors.locationId : ""
																}
																error={
																	touched.locationId &&
																	Boolean(errors.locationId)
																}
															/>
														)}
													/>
												</GridItem>
												<GridItem className={classes.phoneContact}>
													<div>
														<h4
															className={
																classes.title + " " + classes.modalSubTitle
															}
														>
															Phone Contact
														</h4>
													</div>
												</GridItem>

												<GridItem
													xs={6}
													sm={4}
													md={4}
													className={classes.gridHeight}
												>
													<Autocomplete
														options={areaCodeList}
														className={classes.autoCompleteLabel}
														value={
															suggestedAreaCode ||
															areaCodeList.filter((item) => {
																return item.areaCodeId === values.areaCodeId;
															})[0] ||
															null
														}
														getOptionSelected={(option, value) => {
															if (option.areaCodeId === value.areaCodeId) {
																//console.log(option, value);
																return true;
															} else {
																return false;
															}
														}}
														getOptionLabel={(option) =>
															option.areaCode ? "+" + option.areaCode : ""
														}
														onChange={(event, value) => {
															console.log(value);
															if (value) {
																setFieldValue("areaCodeId", value.areaCodeId);
															} else {
																setFieldValue("areaCodeId", null);
															}
														}}
														id="areaCodeId"
														name="areaCodeId"
														renderInput={(params) => (
															<TextField
																{...params}
																label={"Area Code"}
																variant="outlined"
															/>
														)}
													/>
													{errors.areaCodeId && touched.areaCodeId && (
														<Danger>
															<h6>{errors.areaCodeId}</h6>
														</Danger>
													)}
												</GridItem>
												<GridItem
													xs={6}
													sm={8}
													md={8}
													className={classes.gridHeight}
												>
													<TextField
														variant="outlined"
														id="contactNo"
														label="Phone Number"
														name="contactNo"
														value={values.contactNo}
														helperText={
															touched.contactNo ? errors.contactNo : ""
														}
														error={
															touched.contactNo && Boolean(errors.contactNo)
														}
														onChange={(event) => {
															setFieldValue("contactNo", event.target.value);
															setContactNoChange(event.target.value);
															if (suggestedAreaCode) {
																setFieldValue(
																	"areaCodeId",
																	suggestedAreaCode.areaCodeId
																);
															}
															console.log(values.areaCodeId);
														}}
														fullWidth
													/>
												</GridItem>
												<GridItem>
													<div>
														<h4
															className={
																classes.title + " " + classes.modalSubTitle
															}
														>
															Headline
														</h4>
													</div>
												</GridItem>
												<GridItem
													xs={12}
													sm={12}
													md={12}
													className={classes.gridHeight}
												>
													<TextField
														variant="outlined"
														id="headline"
														label="Headline"
														name="headline"
														value={values.headline}
														helperText={touched.headline ? errors.headline : ""}
														error={touched.headline && Boolean(errors.headline)}
														onChange={(event) => {
															setFieldValue("headline", event.target.value);
														}}
														placeholder="Write an engaging profile headline that introduces what you do"
														fullWidth
														InputLabelProps={{
															shrink: true,
														}}
													/>
												</GridItem>
												<GridItem>
													<div>
														<h4
															className={
																classes.title + " " + classes.modalSubTitle
															}
														>
															About Me
														</h4>
													</div>
												</GridItem>
												<GridItem xs={12} sm={12} md={12}>
													<TextField
														variant="outlined"
														id="aboutMe"
														label="About Me"
														name="aboutMe"
														value={values.aboutMe}
														helperText={
															touched.aboutMe
																? errors.aboutMe
																	? errors.aboutMe
																	: `${values.aboutMe.length}/1200`
																: `${values.aboutMe.length}/1200`
														}
														error={touched.aboutMe && Boolean(errors.aboutMe)}
														onChange={(event) => {
															setFieldValue("aboutMe", event.target.value);
														}}
														fullWidth
														placeholder="Describe yourself and highlight why potential employers should consider you"
														InputProps={{
															multiline: true,
															rows: 5,
															maxLength: 1200,
														}}
														InputLabelProps={{
															shrink: true,
														}}
													/>
												</GridItem>
											</GridContainer>
										</CardBody>
									</Card>
								</DialogContent>
							</div>
							<DialogActions className={classes.modalFooterEdit}>
								<Button round onClick={handleSubmit} color="oppty">
									<b>Save</b>
								</Button>
							</DialogActions>
						</form>
					)}
				</Formik>
			</Dialog>
		</React.Fragment>
	);
};

export default BasicProfileEditModal;
