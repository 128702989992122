import React, { useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

// core components
import Button from "components/CustomButtons/Button.js";

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import Close from "@material-ui/icons/Close";

import profileViewActivityPollCardStyles from "../../../assets/jss/material-kit-pro-react/views/componentStyle/profileView/myActivity/profileViewActivityPollCardStyles.js";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/LandingPageFeed/CommentStyle.js";
import style from "assets/jss/material-kit-pro-react/modalStyle.js";
import IconLibrary from "views/Component/IconLibrary.js";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { getPollResultUserList } from "configurations/redux/socialFeedSlice.js";
import defaultAvatar from "assets/img/candidate_avatar.jpeg";
import { Link } from "react-router-dom";
import { instanceUrl, encryptData } from "configurations/configurations.js";
import {
	getCommentReactionUserList,
	getPostReactionUserList,
	getCommentReactionCount,
	getPostReactionCount,
} from "configurations/redux/socialFeedSlice.js";

import { ReactComponent as HandClapIcon } from "assets/svg/clapping.svg";
import { ReactComponent as SendIcon } from "assets/svg/send-svgrepo-com.svg";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import MoodBadOutlinedIcon from "@mui/icons-material/MoodBadOutlined";
import SentimentVerySatisfiedOutlinedIcon from "@mui/icons-material/SentimentVerySatisfiedOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
			style={{ backgroundColor: "#f2f3f7" }}
		>
			{value === index && <Box>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const useStyles = makeStyles(profilePageStyle);
const useStylesPoll = makeStyles(profileViewActivityPollCardStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

const ReactionUserListModal = ({
	open,
	onClose,
	comment,
	post,
	reactionList,
	reactionType,
}) => {
	// console.log(pictureSource);
	const classes = useStyles();
	const classesPoll = useStylesPoll();
	const [value, setValue] = React.useState(0);
	const dispatch = useDispatch();
	const userStatus = useSelector((state) => state.auth.user);
	const reactionUserList = useSelector(
		(state) => state.socialFeed.reactionUserList
	);
	const isLoadingReaction = useSelector(
		(state) => state.socialFeed.isLoadingReaction
	);
	const reactionCount = useSelector((state) => state.socialFeed.reactionCount);
	const [reactionCountRegroup, setReactionCountRegroup] = React.useState([]);
	useEffect(() => {
		if (reactionCount.length > 0) {
			for (var i = 0; i < reactionCount.length; i++) {
				if (reactionCount[i].reactionCount > 0) {
					setValue(reactionCount[i].reactionId - 1);
					loadReactionUserList(reactionCount[i].reactionId);
					console.log("reaction selected");
					return;
				}
			}
		}
	}, [open]);

	useEffect(() => {
		var reactionCountList = [];
		reactionList.map((item, index) => {
			var reaction = null;
			for (var i = 0; i < reactionCount.length; i++) {
				if (
					reactionCount[i].reactionId == item.reactionId &&
					reactionCount[i].reactionCount > 0
				)
					reaction = {
						reactionId: item.reactionId,
						reactionName: item.reactionName,
						reactionCount: reactionCount[i].reactionCount,
					};
			}
			if (reaction == null) {
				reaction = {
					reactionId: item.reactionId,
					reactionName: item.reactionName,
					reactionCount: 0,
				};
			}
			reactionCountList.push(reaction);
		});
		setReactionCountRegroup(reactionCountList);
	}, [reactionCount]);

	const loadReactionUserList = (reactionId) => {
		if (reactionType == "comment") {
			const request = {
				email: userStatus.email,
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				commentId: comment.commentId,
				reactionId: reactionId,
				lastRecordSize: 0,
				requestRecordSize: 1000,
			};
			console.log(request);
			dispatch(getCommentReactionUserList(request));
			const requestR = {
				email: userStatus.email,
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				commentId: comment.commentId,
				firstLoad: false,
			};
			console.log(requestR);
			dispatch(getCommentReactionCount(requestR));
		} else if (reactionType == "post") {
			const request = {
				email: userStatus.email,
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				postId: post.postId,
				reactionId: reactionId,
				lastRecordSize: 0,
				requestRecordSize: 1000,
			};
			console.log(request);
			dispatch(getPostReactionUserList(request));
			const requestR = {
				email: userStatus.email,
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				postId: post.postId,
				firstLoad: false,
			};
			console.log(requestR);
			dispatch(getPostReactionCount(requestR));
		}
	};

	const handleChange = (event, newValue) => {
		setValue(newValue);
		console.log(newValue);
	};

	const modalResize = () => {
		var modalResize = document.getElementById("userListCard");
		if (modalResize) {
			modalResize.style.maxHeight = "440px";
			var maxSize = window.innerHeight - 74 - 80 - 70;
			if (maxSize < 0) maxSize = 0;
			modalResize.style.height = maxSize + "px";
		}
	};

	React.useEffect(() => {
		if (open) {
			if (document.readyState === "complete") {
				setTimeout(() => {
					modalResize();
				}, 500);
			}
			window.addEventListener("resize", modalResize);
			return function cleanup() {
				window.removeEventListener("resize", modalResize);
			};
		}
	}, [open]);

	const ResultCards =
		reactionList &&
		reactionList.map((item, index) => (
			<TabPanel key={index} value={value} index={index}>
				<List sx={{ bgcolor: "background.paper" }}>
					{reactionUserList ? (
						reactionUserList.map((item, index) => (
							<Link
								key={"reactionUserList_" + index}
								to={{
									pathname: `/view-profile/${encryptData(item.acctId)}`,
								}}
								target="_blank"
								rel="noopener noreferrer"
							>
								<ListItem className={classesPoll.pollUserListItem}>
									<ListItemAvatar>
										<Avatar>
											<img
												style={{ width: "40px" }}
												src={item.profilePic ? item.profilePic : defaultAvatar}
												alt=""
											/>
										</Avatar>
									</ListItemAvatar>
									<ListItemText
										className={classesPoll.pollUserListText}
										primary={item.firstName + " " + item.surname}
										secondary={item.headline}
									/>
								</ListItem>
							</Link>
						))
					) : isLoadingReaction ? (
						<div>Loading...</div>
					) : null}
				</List>
			</TabPanel>
		));

	return (
		<React.Fragment>
			<Dialog
				classes={{
					root: classes.modalRoot,
					paper: classes.modalCommentEdit + " " + classes.modal,
				}}
				open={open}
				// TransitionComponent={Transition}
				// keepMounted
				// disableBackdropClick
				onClose={(event, reason) => {
					if (reason !== "backdropClick") {
						onClose(event, reason);
					}
				}}
				aria-labelledby="classic-modal-slide-title"
				aria-describedby="classic-modal-slide-description"
			>
				<DialogTitle
					id="classic-modal-slide-title"
					disableTypography
					className={classes.modalTitleEdit}
				>
					<Button
						simple
						className={classes.modalCloseButton}
						key="close"
						aria-label="Close"
						onClick={onClose}
					>
						<IconLibrary.Close />
					</Button>
					<h6 className={classes.title + " " + classes.titleAlign}>
						Reactions
					</h6>
				</DialogTitle>
				<DialogContent
					id="classic-modal-slide-description"
					className={classes.modalBody}
				>
					<div id="reactionUser" key="reactionUser">
						<Box sx={{ width: "100%" }}>
							<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
								<Tabs
									value={value}
									onChange={handleChange}
									variant="fullWidth"
									aria-label="basic tabs example"
									className={classesPoll.pollTabs}
								>
									{reactionList.map((item, index) => (
										<Tab
											style={{ color: "#6F52ED", fontSize: "11px" }}
											key={item.reactionId}
											icon={
												item.reactionId == 1 ? (
													<ThumbUpAltOutlinedIcon
														sx={{
															color: "#92929d",
															margin: "5px",
															transition: "all .5s",
															"&:hover": {
																transform: "scale(1.5)",
															},
														}}
													/>
												) : item.reactionId == 2 ? (
													<FavoriteBorderOutlinedIcon
														sx={{
															color: "#92929d",
															margin: "5px",
															transition: "all .5s",
															"&:hover": {
																transform: "scale(1.5)",
															},
														}}
													/>
												) : item.reactionId == 3 ? (
													<HandClapIcon
														style={{
															fill: "#92929d",
															width: "22px",
															height: "24px",
															margin: "5px",
															transition: "all .5s",
														}}
														className={classesPoll.clapIcon}
													/>
												) : item.reactionId == 4 ? (
													<SentimentVeryDissatisfiedIcon
														sx={{
															color: "#92929d",
															margin: "5px",
															transition: "all .5s",
															"&:hover": {
																transform: "scale(1.5)",
															},
														}}
													/>
												) : item.reactionId == 5 ? (
													<MoodBadOutlinedIcon
														sx={{
															color: "#92929d",
															margin: "5px",
															transition: "all .5s",
															"&:hover": {
																transform: "scale(1.5)",
															},
														}}
													/>
												) : item.reactionId == 6 ? (
													<SentimentVerySatisfiedOutlinedIcon
														sx={{
															color: "#92929d",
															margin: "5px",
															transition: "all .5s",
															"&:hover": {
																transform: "scale(1.5)",
															},
														}}
													/>
												) : null
											}
											label={
												item.reactionId == 1
													? reactionCountRegroup.map((x, index) =>
															x.reactionId == item.reactionId
																? item.reactionName + " • " + x.reactionCount
																: null
													  )
													: item.reactionId == 2
													? reactionCountRegroup.map((x, index) =>
															x.reactionId == item.reactionId
																? item.reactionName + " • " + x.reactionCount
																: null
													  )
													: item.reactionId == 3
													? reactionCountRegroup.map((x, index) =>
															x.reactionId == item.reactionId
																? item.reactionName + " • " + x.reactionCount
																: null
													  )
													: item.reactionId == 4
													? reactionCountRegroup.map((x, index) =>
															x.reactionId == item.reactionId
																? item.reactionName + " • " + x.reactionCount
																: null
													  )
													: item.reactionId == 5
													? reactionCountRegroup.map((x, index) =>
															x.reactionId == item.reactionId
																? item.reactionName + " • " + x.reactionCount
																: null
													  )
													: item.reactionId == 6
													? reactionCountRegroup.map((x, index) =>
															x.reactionId == item.reactionId
																? item.reactionName + " • " + x.reactionCount
																: null
													  )
													: null
											}
											{...a11yProps(item.reactionId)}
											onClick={() => {
												console.log(item.reactionId);
												loadReactionUserList(item.reactionId);
											}}
										/>
									))}
								</Tabs>
							</Box>
							<div
								id="userListCard"
								key="userListCard"
								style={{
									overflow: "auto",
									height: "440px",
									transition: "height 0.5s ease",
								}}
							>
								{ResultCards}
							</div>
						</Box>
					</div>
				</DialogContent>
				<div className={classes.modalFooter}></div>
			</Dialog>
		</React.Fragment>
	);
};
const mapStateToProps = (state) => ({
	userStatus: state.auth.user,
	// image: state.user.image,
});
export default connect(mapStateToProps, null)(ReactionUserListModal);
