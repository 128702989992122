import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
	departmentViewRoadmap,
	viewRoadmapRoleByRoleId,
} from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Department_Private_Banking from "assets/img/Career_Roadmap/Banking_Wealth_Management/Department_Private_Banking_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentPrivateBanking() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Private_Banking;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "pb",
				shape: "poly",
				coords: [70,393,63,395,58,399,54,404,53,410,53,451,55,458,58,462,62,466,69,468,176,469,182,466,187,464,191,457,193,450,194,412,191,404,187,398,181,394,175,393,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "409",
				shape: "poly",
				coords: [295,392,285,394,282,396,278,402,277,410,278,454,280,462,283,466,288,468,295,469,479,469,489,468,494,465,497,459,498,452,499,409,496,399,492,394,484,392,479,392,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "410",
				shape: "poly",
				coords: [734,392,726,394,722,397,720,401,718,408,718,452,722,462,724,466,730,468,738,469,920,469,931,468,935,464,938,460,940,453,940,407,938,399,932,394,926,392,920,392,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "411",
				shape: "poly",
				coords: [1171,392,1166,396,1162,398,1160,403,1159,409,1159,454,1161,461,1164,466,1168,468,1176,470,1365,469,1374,467,1378,463,1380,459,1381,452,1380,412,1379,400,1374,395,1368,393,1363,391,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "412",
				shape: "poly",
				coords: [1616,391,1608,394,1604,397,1602,401,1600,408,1600,455,1603,462,1606,466,1610,469,1617,470,1804,469,1814,466,1818,464,1821,459,1822,454,1822,406,1820,399,1815,395,1810,393,1805,392,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "pb") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo);
			dispatch(viewRoadmapRoleByRoleId(requestInfo));

			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
