import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
	departmentViewRoadmap,
	viewRoadmapRoleByRoleId,
} from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Department_SME from "assets/img/Career_Roadmap/Banking_SME_Corporate_Commercial/Department_SME_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentSME() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_SME;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "s",
				shape: "poly",
				coords: [74,392,63,395,58,399,54,404,52,410,53,452,55,457,59,461,64,465,71,468,176,467,182,466,188,462,191,456,194,450,194,410,191,402,188,397,182,394,176,392,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "477",
				shape: "poly",
				coords: [289,390,281,393,276,395,273,400,271,406,272,454,274,460,277,465,282,467,290,469,477,469,485,467,489,464,493,460,494,454,495,412,494,403,490,396,483,392,474,390,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "413",
				shape: "poly",
				coords: [613,391,606,393,601,396,599,401,597,407,598,456,599,460,602,464,607,468,613,469,804,469,811,467,815,463,818,460,820,453,819,404,818,399,812,394,807,392,802,391,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "414",
				shape: "poly",
				coords: [939,390,931,393,928,396,924,400,923,407,923,454,924,460,927,464,932,467,939,469,1130,468,1137,466,1141,464,1144,459,1145,454,1144,405,1143,399,1139,395,1135,392,1129,390,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "415",
				shape: "poly",
				coords: [1263,391,1255,395,1251,398,1249,403,1249,409,1249,452,1250,458,1252,463,1257,466,1263,469,1456,469,1463,466,1467,463,1469,460,1470,454,1470,404,1468,399,1465,394,1461,392,1454,389,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "479",
				shape: "poly",
				coords: [1590,391,1582,394,1578,397,1575,401,1574,407,1574,455,1576,461,1579,464,1584,467,1592,468,1779,469,1787,468,1791,465,1794,461,1795,456,1795,403,1794,398,1791,395,1784,391,1778,390,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "s") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo);
			dispatch(viewRoadmapRoleByRoleId(requestInfo));

			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
