import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
	departmentViewRoadmap,
	viewRoadmapRoleByRoleId,
} from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Industry_Civil_Engineering from "assets/img/Career_Roadmap/Civil_Engineering/Industry_Civil_Engineering_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function IndustryCivilEngineering() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Industry_Civil_Engineering;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "ce",
				shape: "poly",
				coords: [81,293,71,296,66,299,63,304,60,312,60,483,63,490,66,495,70,498,77,501,172,499,178,498,183,495,186,490,189,481,189,313,187,305,182,298,176,295,169,293,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "284",
				shape: "poly",
				coords: [221,293,213,295,211,296,208,301,207,306,207,355,209,363,212,366,217,368,223,370,353,370,359,368,364,365,367,360,368,354,369,310,367,303,363,298,357,292,352,292,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "285",
				shape: "poly",
				coords: [590,292,583,295,580,298,577,302,576,308,576,356,578,362,580,365,584,368,590,370,721,369,730,368,734,365,736,361,737,356,737,308,736,300,732,296,727,293,721,293,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "286",
				shape: "poly",
				coords: [958,293,952,296,949,299,947,303,946,308,947,357,949,362,952,366,956,369,962,369,1092,370,1101,367,1105,364,1107,360,1108,356,1109,312,1108,303,1105,299,1099,294,1092,293,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "287",
				shape: "poly",
				coords: [1331,292,1324,296,1320,299,1318,305,1317,312,1318,355,1319,361,1322,365,1327,367,1333,369,1463,369,1469,368,1475,365,1478,361,1480,356,1480,310,1478,302,1474,296,1468,293,1462,292,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "288",
				shape: "poly",
				coords: [1703,292,1695,295,1691,299,1689,304,1689,309,1688,356,1691,362,1693,364,1696,366,1700,368,1706,370,1843,369,1848,368,1853,364,1856,360,1857,355,1857,306,1855,299,1852,296,1846,293,1841,292,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "280",
				shape: "poly",
				coords: [216,425,210,427,206,430,203,435,202,443,202,485,203,493,206,497,211,500,217,502,346,502,355,500,359,497,362,494,364,488,365,444,363,433,358,428,352,426,347,424,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "281",
				shape: "poly",
				coords: [708,425,701,427,697,430,694,435,693,443,693,489,695,493,697,497,703,500,708,502,840,503,847,500,851,497,853,494,856,488,856,442,855,433,851,429,845,426,838,424,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "282",
				shape: "poly",
				coords: [1204,425,1195,427,1191,430,1189,437,1188,443,1188,488,1191,494,1193,498,1197,501,1203,501,1335,502,1341,500,1345,497,1348,493,1350,489,1351,442,1349,434,1345,429,1339,426,1333,425,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "283",
				shape: "poly",
				coords: [1705,424,1697,426,1693,429,1690,434,1688,439,1689,487,1690,492,1692,497,1698,501,1703,502,1842,502,1849,500,1854,496,1856,491,1857,486,1858,442,1856,431,1852,427,1847,426,1841,424,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "ce") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo);
			dispatch(viewRoadmapRoleByRoleId(requestInfo));

			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
