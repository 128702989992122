import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { departmentViewRoadmap, viewRoadmapRoleByRoleId } from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";
import ImageMapper from "react-image-mapper";
import Department_Investment_Securities from "assets/img/Career_Roadmap/Finance/Department_Investment_Securities_1.jpg";
import RoleInfoModal from "../RoleInfoModal.js";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DeparmentInvestmentSecurities() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Investment_Securities;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "is",
				shape: "poly",
				coords: [78,357,68,359,65,361,62,364,60,368,58,373,58,414,60,420,62,426,66,428,73,432,170,432,174,430,180,426,182,422,184,418,186,412,186,376,185,370,182,366,178,360,173,358,168,356,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "53",
				shape: "poly",
				coords: [226,356,215,357,211,358,206,360,202,366,200,372,200,418,202,425,204,430,209,432,214,434,349,434,354,432,357,428,360,425,362,419,361,369,359,363,356,360,350,358,344,356,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "54",
				shape: "poly",
				coords: [465,360,457,361,453,362,449,365,447,369,445,375,445,423,447,429,450,432,452,435,457,437,595,437,599,435,602,434,605,430,607,420,608,377,608,370,605,366,602,363,598,361,594,360,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "55",
				shape: "poly",
				coords: [706,361,696,363,692,367,690,372,688,378,688,428,692,433,697,437,705,439,836,438,841,436,844,435,848,432,850,422,850,373,848,368,845,366,841,362,837,362,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "56",
				shape: "poly",
				coords: [952,363,948,364,945,366,942,371,940,377,941,425,943,432,947,436,950,438,955,439,1089,438,1094,437,1096,435,1100,430,1102,423,1102,374,1100,369,1098,366,1093,363,1090,362,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "57",
				shape: "poly",
				coords: [1202,362,1192,362,1189,364,1186,367,1184,371,1183,378,1182,425,1184,430,1188,435,1191,438,1197,439,1332,438,1338,435,1340,433,1342,430,1344,426,1344,422,1344,374,1342,368,1339,365,1335,362,1330,361,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "58",
				shape: "poly",
				coords: [1440,360,1434,362,1431,364,1428,368,1426,374,1426,419,1426,428,1430,432,1433,436,1440,438,1570,438,1576,436,1581,434,1585,429,1587,420,1587,374,1586,369,1583,365,1579,362,1573,360,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "59",
				shape: "poly",
				coords: [1688,359,1680,360,1677,363,1674,366,1672,369,1671,375,1670,422,1672,429,1675,434,1680,436,1684,438,1815,437,1822,436,1825,435,1828,433,1830,430,1832,426,1833,422,1833,376,1832,369,1829,366,1827,364,1822,361,1818,360,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "is") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo)
			dispatch(viewRoadmapRoleByRoleId(requestInfo));
			
			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// console.log(coords.x + "," + coords.y + ",");
		// navigator.clipboard.writeText(coords.x + "," + coords.y + ",");
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}

	function enterArea(area) {
		console.log(area);
	}

	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
				onMouseEnter={(area) => enterArea(area)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
