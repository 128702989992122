const editResumeStyles = theme => ({
  dialogContainer: {
    position: 'absolute',
    zIndex: 1,
    top: '-100%',
    right: '-100%',
    bottom: '-100%',
    left: '-100%',
  },

  dialog: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '15px',
    backgroundColor: '#fff',
    padding: '70px 0 15px',
    width: '450px',
    /* 	height: "260px", */
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      //   height: '80vh',
      //   overflowY: 'scroll',
    },
  },

  dialogHeight: {
    '@media (max-height: 685px)': {
      height: 'calc(100vh - 50px)',
      overflowY: 'auto',
    },
  },

  dialogTitle: {
    margin: 0,
    color: '#000000',
    fontSize: '20px',
    fontWeight: 700,
    fontFamily: `"Roboto", "Helvetica", sans-serif !important`,

    textAlign: 'center',
  },

  dialogText: {
    color: '#6D7278',
    fontSize: '18px',
    fontWeight: 400,
    fontFamily: `"Roboto", "Helvetica", sans-serif !important`,

    textAlign: 'center',
  },

  dialogActions: {
    /* position: "absolute", */
    /* left: 0,
		right: 0, */
    /* bottom: "20px", */

    padding: '20px 0px 10px 0px',
    display: 'flex',
    justifyContent: 'space-evenly',

    '& > button': {
      borderRadius: '24px',
      width: '160px',
      height: '48px',
      fontSize: '16px',
      fontWeight: 500,
      fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
    },

    '& > :nth-child(1)': {
      color: '#687BFE',
    },

    '& > :nth-child(2)': {
      backgroundColor: '#687BFE',
      color: '#fff',
    },
    '& > button:disabled': {
      opacity: '0.65',
      pointerEvents: 'none',
    },
  },

  body: {
    borderRadius: '15px',
    backgroundColor: '#fff',
    padding: '15px 10px 40px',
  },

  heading: {
    marginBottom: '7px',
    paddingLeft: '5px',
    color: '#000000',
    fontSize: '18px',
    fontWeight: 700,
    fontFamily: 'Roboto, Helvetica, sans-serif',

    '&:not(:first-of-type)': {
      marginTop: '20px',
    },
  },

  resumeContainer: {
    backgroundColor: '#F8FAFF',
    padding: '10px 15px',
  },

  noResume: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    minHeight: '250px',

    '& > p': {
      width: '160px',
      color: '#000000',
      fontSize: '16px',
      fontWeight: 500,
      fontFamily: `"Roboto", "Helvetica", sans-serif !important`,

      textAlign: 'center',
    },
  },

  singleResumeCard: {
    width: '250px',
  },

  resumeThumbnail: {
    width: '250px',
    height: '250px',

    '& > img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
    },
  },

  resumeDetail: {
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      margin: '5px 0',
    },

    '& > div > div:first-child': {
      width: '110px',
    },

    '& > div > div': {
      color: '#0A1F44',
      fontSize: '14px',
      fontWeight: 400,
      fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
    },
  },

  deleteEducationBtn: {
    marginLeft: '68%',
    padding: '6px 8px 4px',
    color: '#E42B4D',
    fontSize: '12px',
    fontWeight: 500,
    fontFamily: `"Roboto", "Helvetica", sans-serif !important`,

    '& svg': {
      marginTop: '-3px',
      marginRight: '5px',
      width: '13px',
      height: '13px',
    },
  },

  dropZoneContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '15px',
  },

  dropZone: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: 'solid 2px #979797',
    borderStyle: 'dashed',
    backgroundColor: '#F8FAFF',
    padding: '25px',
    width: '450px',
    textAlign: 'center',

    '& img': {
      '-webkit-user-drag': 'none',
    },

    '& p': {
      userSelect: 'none',
    },

    '& > p:first-of-type': {
      margin: 0,
      color: '#000000',
      fontSize: '18px',
      fontWeight: 400,
      fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
    },

    '& > p:not(:first-of-type)': {
      margin: '10px 0 0',
      width: '230px',
      color: '#929293',
      fontSize: '16px',
      fontWeight: 400,
      fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  dropZoneHeading: {
    margin: '10px 0',
    color: '#000000',
    fontSize: '18px',
    fontWeight: 500,
    fontFamily: `"Roboto", "Helvetica", sans-serif !important`,

    userSelect: 'none',
  },

  dropZoneButton: {
    margin: '10px 0',
    border: 'none',
    background: 'transparent',
    color: '#6F52ED',
    fontSize: '18px',
    fontWeight: 500,
    fontFamily: `"Roboto", "Helvetica", sans-serif !important`,

    textTransform: 'capitalize',
    cursor: 'pointer',
  },

  uploadProgressCard: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '40px',
    width: '350px',
  },

  uploadProgressCardIcon: {
    width: '20%',
    textAlign: 'center',
  },

  uploadProgressCardInfoContainer: {
    width: '80%',
  },

  uploadProgressFileName: {
    margin: 0,
    color: '#000',
    fontSize: '14px',
    fontWeight: 700,
    fontFamily: `"Roboto", "Helvetica", sans-serif !important`,

    userSelect: 'none',
  },

  uploadProgressBar: {
    margin: '5px 0',
    width: '100%',
    backgroundColor: '#C7CCD5',

    '& > div': {
      backgroundColor: '#4491D8',
    },
  },

  uploadProgressInfo: {
    display: 'flex',
    justifyContent: 'space-between',

    '& > p': {
      margin: 0,
      fontSize: '14px',
      fontWeight: 500,
      fontFamily: `"Roboto", "Helvetica", sans-serif !important`,

      userSelect: 'none',
    },

    '& > p:first-child': {
      color: '#A7ADB9',
    },

    '& > p:last-child': {
      color: '#64A4DF',
    },
  },

  btnSave: {
    marginTop: '40px',
    border: 'solid 2px #687BFE',
    borderRadius: '24px',
    backgroundColor: '#687BFE',
    width: '160px',
    height: '48px',
    color: '#fff',
    fontSize: '16px',
    fontWeight: 500,
    fontFamily: `"Roboto", "Helvetica", sans-serif !important`,

    textTransform: 'uppercase',

    '&:hover': {
      color: '#687BFE',
    },
  },
  disabled: {
    opacity: '0.65',
    pointerEvents: 'none',
  },

  pdfRender: {
    '& > canvas': {
      margin: 'auto',
      width: '210px !important',
      height: '297px !important',
    },
    '& > section': {
      display: 'none',
      height: '0px',
    },
  },
  resumeTitle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
    display: '-webkit-box',
    fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
    fontWeight: 500,
    fontSize: '14px',
    textAlign: 'left !important',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    lineHeight: '1.2em',
    height: '2.4em',
    margin: '5px 0px !important',
  },
  resumeDescription: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
    display: '-webkit-box',
    fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
    fontWeight: 500,
    fontSize: '14px',
    textAlign: 'left !important',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    lineHeight: '1.2em',
    height: '1.2em',
    '& > b': {
      textTransform: 'none !important',
    },
    marginBottom: '5px !important',
  },

  resumeCard: {
    marginTop: '0px !important',
  },

  resumeDelete: {
    '& > svg': {
      marginRight: '7px',
    },
    color: '#ff0000 !important',
  },
});

export default editResumeStyles;
