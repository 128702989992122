import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.js";

import style from "assets/jss/material-kit-pro-react/modalStyle.js";
const useStyles = makeStyles(style);
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

export default function ProfileBuildModal({ acctId }) {
	const classes = useStyles();
	const userStatus = useSelector((state) => state.auth.user);
	const [dialogShow, setDialogShow] = React.useState(false);

	setTimeout(() => {
		setDialogShow(true);
	}, 1500);

	return (
		<React.Fragment>
			<Dialog
				classes={{
					root: classes.modalRoot,
					paper: classes.modalLoader,
				}}
				open={dialogShow}
				TransitionComponent={Transition}
				keepMounted
				aria-labelledby="classic-modal-slide-title"
				aria-describedby="classic-modal-slide-description"
			>
				<DialogTitle
					id="classic-modal-slide-title"
					disableTypography
					className={classes.modalHeader}
				>
					<h6>Profile Not Build Yet</h6>
				</DialogTitle>
				<DialogContent
					id="classic-modal-slide-description"
					className={classes.modalBody}
				>
					<DialogContentText id="alert-dialog-slide-description">
						You haven't set up your profile info yet. Please set up your profile
						to use OppTy!
					</DialogContentText>
				</DialogContent>
				<DialogActions className={classes.modalFooter}>
					<Button
						href={
							userStatus.acctTypeId === 2
								? `/corporate-profile-page/${acctId}`
								: `/profile-page/${acctId}`
						}
						color="opptyWrap"
						round
					>
						Build
					</Button>
				</DialogActions>
				{/* <DialogActions className={classes.modalFooter}>
                    <Link
                        className={classes.textDeco}
                        to={{
                            pathname:
                                userStatus.acctTypeId === 2
                                    ? `/corporate-profile-page/${acctId}`
                                    : `/profile-page/${acctId}`,
                        }}
                        color="transparent"
                    >
                        <Button color="primary">Build</Button>
                    </Link>
                </DialogActions> */}
			</Dialog>
		</React.Fragment>
	);
}
