const singleTestimonialCardStyles = (theme) => ({
	body: {
		display: "flex",
		borderBottom: "solid 1px #CFD0D2",
		padding: "15px 0",
		width: "100%",
		// height: "130px",
	},

	attestantProfile: {
		display: "flex",
		gap: "5px",
		width: "25%",
	},

	attestantProfilePic: {
		"& > img": {
			border: "solid 1px #fff",
			borderRadius: "50%",
			width: "40px",
			height: "40px",
		},
	},

	attestantProfileName: {
		color: "#171725",
		fontSize: "13px",
		fontWeight: 400,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
	},

	attestantDetail: {
		color: "#99B2C6",
		fontSize: "11px",
		fontWeight: 400,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
	},

	textContainer: {
		position: "relative",
		overflow: "hidden",
		padding: "0 15px",
		width: "56%",
	},

	textNoResponse: {
		color: "#171725",
		fontSize: "13px",
		fontWeight: 400,
		/* fontStyle: "italic", */
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
	},

	textRequestTestimonial: {
		color: "#171725",
		fontSize: "13px",
		fontWeight: 400,
		/* fontStyle: "italic", */
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
	},

	BtnNoResponse: {
		position: "absolute",
		bottom: 0,
		border: "none",
		background: "none",
		padding: 0,
		color: "#E42B4D",
		fontSize: "13px",
		fontWeight: 400,
		fontFamily: "Roboto, Helvetica, sans-serif",
		textTransform: "uppercase",
		cursor: "pointer",
	},

	BtnRequestTestimonial: {
		position: "absolute",
		bottom: 0,
		border: "none",
		background: "none",
		padding: 0,
		color: "#6F52ED",
		fontSize: "14px",
		fontWeight: 400,
		fontFamily: "Roboto, Helvetica, sans-serif",
		textTransform: "uppercase",
		cursor: "pointer",
	},

	textMyTestimonials: {
		display: "-webkit-box",
		"-webkit-line-clamp": 3,
		"-webkit-box-orient": "vertical",
		overflow: "hidden",
		textOverflow: "ellipsis",
		lineHeight: "1.3",
		color: "#171725",
		fontSize: "14px",
		fontWeight: 400,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		textAlign: "justify",

		"&:after": {
			content: "'See More'",
			position: "absolute",
			right: "10px",
			bottom: "15px",
			backgroundColor: "white",
			padding: "0 5px",
			color: "#6F52ED",
			fontSize: "14px",
			cursor: "pointer",
		},
	},

	textPendingApprovalMessage: {
		color: "#929293",
		fontSize: "12px",
		fontWeight: 400,
		fontStyle: "italic",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
	},

	textPendingApprovalTestimony: {
		display: "-webkit-box",
		"-webkit-line-clamp": 3,
		"-webkit-box-orient": "vertical",
		overflow: "hidden",
		textOverflow: "ellipsis",
		marginTop: "3px",
		color: "#171725",
		fontSize: "14px",
		fontWeight: 400,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		textAlign: "justify",

		"&:after": {
			content: "'See More'",
			position: "absolute",
			right: "10px",
			bottom: 0,
			backgroundColor: "white",
			padding: "0 5px",
			color: "#6F52ED",
			fontSize: "14px",
			cursor: "pointer",
		},
	},

	textTestimonialGivenMessage: {
		color: "#171725",
		fontSize: "14px",
		fontWeight: 400,
		fontStyle: "italic",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
	},

	textGivenTestimony: {
		display: "-webkit-box",
		"-webkit-line-clamp": 3,
		"-webkit-box-orient": "vertical",
		overflow: "hidden",
		textOverflow: "ellipsis",
		marginTop: "5px",
		color: "#171725",
		fontSize: "14px",
		fontWeight: 400,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		lineHeight: "1.3",
		textAlign: "justify",
	},

	buttonsContainer: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		width: "19%",

		"& > div": {
			display: "flex",
			alignItems: "center",
			height: "100%",

			"& > div": {
				display: "flex",
				alignItems: "center",
				height: "fit-content",
				fontSize: "14px",
				fontWeight: 400,
				fontFamily: "Roboto, Helvetica, sans-serif",
				textTransform: "uppercase",
				cursor: "pointer",
			},
		},
	},

	btnApprove: {
		color: "#0AB98D",

		"& svg": {
			width: "16px",
			height: "16px",
			marginRight: "6px",
		},
	},

	btnEditRequest: {
		color: "#6F52ED",

		"& svg": {
			width: "15px",
			height: "15px",
			marginRight: "6px",
		},
	},

	btnDismiss: {
		color: "#E42B4D",

		"& svg": {
			width: "11px",
			height: "11px",
			marginTop: "-1px",
			marginRight: "10px",
		},
	},

	btnDelete: {
		color: "#E42B4D",

		"& svg": {
			width: "13px",
			height: "13px",
			marginTop: "-3px",
			marginRight: "8px",
		},
	},

	testimonialContainer: {
		margin: "10px 0px",
		backgroundColor: "#f2f3f7",
		borderRadius: "18px",
		padding: "5px 10px",
	},
	cardFrame: {
		borderRadius: "18px",
		boxShadow: "none",
		margin: "10px 0px",
	},
	cardBody: {
		padding: "15px 15px !important",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	noTestimonial: {
		fontWeight: 700,
		fontSize: "14px",
		textAlign: "center",
	},
	noTestimonialButton: {
		border: "solid 1px #687BFE",
		backgroundColor: "#687BFE",
		color: "#fff",
		borderRadius: "18px",
		margin: "15px 10px",
		padding: "10px",
		"&:hover": {
			backgroundColor: "transparent",
			color: "#687BFE",
		},
	},
});

export default singleTestimonialCardStyles;
