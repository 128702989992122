import React from "react";
import ReactDOM from "react-dom";
import GoogleLogin from "react-google-login";
import { useDispatch } from "react-redux";
import { socialRegisterAction } from "configurations/redux/authSlice.js";

import Button from "components/CustomButtons/Button.js";

import { makeStyles } from "@material-ui/core/styles";

import registrationPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/RegistrationPage/registrationPageStyle.js";
import googleIcon from "assets/svg/google_1.png";
import { googleCliendId } from "configurations/configurations.js";
import { ReactComponent as GoogleIconSvg } from "assets/svg/Google__G__Logo.svg";

const useStyles = makeStyles(registrationPageStyle);

const GoogleRegisterBtn = () => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const responseGoogle = (response) => {
		console.log(response);
		//googleRegister(response.profileObj);
		const requestBody = {
			email: response.profileObj.email,
			password: "",
			surname: response.profileObj.familyName,
			firstName: response.profileObj.givenName,
			middleName: "",
			acctStatusId: 0,
			acctTypeId: 3,
			socialAcctTypeId: 3,
			createdAcctId: 0,
			updateAcctId: 0,
			socialAcctId: response.profileObj.googleId,
		};
		dispatch(socialRegisterAction(requestBody));
	};
	const failureResponse = (response) => {
		console.log(response);
		// alert("Google Register has not been successfully done");
	};
	//940536234362-4n2uk2pe5he54h2f4sv2a6rhtbc9r5dh.apps.googleusercontent.com
	return (
		<React.Fragment>
			<GoogleLogin
				clientId={googleCliendId}
				render={(renderProps) => (
					<div
						className={classes.googleButtonContainer}
						onClick={renderProps.onClick}
					>
						<GoogleIconSvg />
						<div className={classes.socialButtonText}>
							<span>Sign In With Google</span>
						</div>
					</div>
				)}
				buttonText="Login"
				onSuccess={responseGoogle}
				onFailure={failureResponse}
				cookiePolicy={"single_host_origin"}
			/>
		</React.Fragment>
	);
};

export default GoogleRegisterBtn;
