import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import RecommendedJobsStyle from "assets/jss/material-kit-pro-react/views/componentStyle/RecommendedJobsStyle.js";
import { Card, CardContent, Typography, Button } from "@material-ui/core";
import userProfile from "assets/images/userProfile.png";
import testimonialIcon from "assets/images/testimonial.png";

import { getRecomendedJobPost } from "configurations/configurations";

const useStyles = makeStyles(RecommendedJobsStyle);

export default function RecommendedJobs({ ...rest }) {
    const user = useSelector((state) => state.auth.user);

    React.useEffect(() => {
        let params = {
            acctId: user.acctId,
            email: user.email,
            keyword: "",
            lastRecordSize: 0,
            requestRecordSize: 10,
            accessToken: localStorage.getItem("token"),
        }

        getRecomendedJobPost(params)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);
    const classes = useStyles();

    return (
        <React.Fragment>
            <Card className={classes.recommendedJobsCard}>
                <CardContent>
                    <Typography component="div">
                        <h4>Recommended Jobs</h4>
                        <Typography component="div" className={classes.recommendedJobItem}>
                            <Typography
                                component="div"
                                className={classes.recommendedJobImage}
                            >
                                <img src={userProfile} alt="" />
                            </Typography>
                            <Typography
                                component="div"
                                className={classes.recommendedJobItemContent}
                            >
                                <h5>
                                    Grocery Team Member (Stocker, Inventory, Floor Services,
                                    Customer Services) -Part Time
                                </h5>
                                <h6>Whole foods market</h6>
                                <Typography varient="p">
                                    Los Angless, California, United States (On-site)
                                </Typography>
                                <Typography component="span">
                                    <img src={testimonialIcon} alt="" />
                                    Actively recruiting
                                </Typography>
                                <Typography component="span">25 minutes ago</Typography>
                            </Typography>
                        </Typography>

                        <Typography component="div" className={classes.recommendedJobItem}>
                            <Typography
                                component="div"
                                className={classes.recommendedJobImage}
                            >
                                <img src={userProfile} alt="" />
                            </Typography>
                            <Typography
                                component="div"
                                className={classes.recommendedJobItemContent}
                            >
                                <h5>
                                    Grocery Team Member (Stocker, Inventory, Floor Services,
                                    Customer Services) -Part Time
                                </h5>
                                <h6>Whole foods market</h6>
                                <Typography varient="p">
                                    Los Angless, California, United States (On-site)
                                </Typography>
                                <Typography component="span">
                                    <img src={testimonialIcon} alt="" />
                                    Actively recruiting
                                </Typography>
                                <Typography component="span">25 minutes ago</Typography>
                            </Typography>
                        </Typography>

                        <Typography component="div" className={classes.recommendedJobItem}>
                            <Typography
                                component="div"
                                className={classes.recommendedJobImage}
                            >
                                <img src={userProfile} alt="" />
                            </Typography>
                            <Typography
                                component="div"
                                className={classes.recommendedJobItemContent}
                            >
                                <h5>
                                    Grocery Team Member (Stocker, Inventory, Floor Services,
                                    Customer Services) -Part Time
                                </h5>
                                <h6>Whole foods market</h6>
                                <Typography varient="p">
                                    Los Angless, California, United States (On-site)
                                </Typography>
                                <Typography component="span">
                                    <img src={testimonialIcon} alt="" />
                                    Actively recruiting
                                </Typography>
                                <Typography component="span">25 minutes ago</Typography>
                            </Typography>
                        </Typography>
                        <Typography component="div" className={classes.seeAllBtnWrap}>
                            <Button>See all recommended jobs</Button>
                        </Typography>
                    </Typography>
                </CardContent>
            </Card>
        </React.Fragment>
    );
}
