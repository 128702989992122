import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { departmentViewRoadmap, viewRoadmapRoleByRoleId } from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Industry_Hospitality from "assets/img/Career_Roadmap/Hospitality/Industry_Hospitality_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function IndustryHospitality() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Industry_Hospitality;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "rd",
				shape: "poly",
				coords: [81,65,173,64,184,69,190,76,192,85,192,120,189,129,178,139,166,141,82,140,72,136,66,130,63,121,63,85,66,73,71,69,76,67,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "am",
				shape: "poly",
				coords: [84,188,174,189,181,192,188,197,191,205,192,244,189,254,183,260,172,264,85,264,74,262,69,258,65,251,63,241,63,210,65,201,69,194,74,191,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "h",
				shape: "poly",
				coords: [83,321,172,320,180,322,187,327,192,335,193,342,193,381,187,389,179,395,171,396,84,397,72,392,68,386,65,378,64,340,66,332,71,326,76,323,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "em",
				shape: "poly",
				coords: [84,444,175,444,181,447,186,449,192,462,193,511,191,523,185,530,178,534,169,535,84,535,74,531,68,526,65,519,65,462,69,453,74,448,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "tmo",
				shape: "poly",
				coords: [82,583,177,582,186,585,192,593,194,604,194,648,187,662,182,666,169,669,81,668,73,665,67,658,64,649,64,600,68,591,73,586,77,584,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "fo",
				shape: "poly",
				coords: [167,715,179,716,188,724,192,735,192,773,188,783,180,789,165,792,87,792,79,790,69,782,65,776,64,736,68,725,74,720,82,715,95,716,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "rd") {
			dispatch(departmentViewRoadmap(12));
		} else if (area.name === "am") {
			dispatch(departmentViewRoadmap(13));
		} else if (area.name === "h") {
			dispatch(departmentViewRoadmap(14));
		} else if (area.name === "em") {
			dispatch(departmentViewRoadmap(15));
		} else if (area.name === "tmo") {
			dispatch(departmentViewRoadmap(16));
		} else if (area.name === "fo") {
			dispatch(departmentViewRoadmap(17));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo)
			dispatch(viewRoadmapRoleByRoleId(requestInfo));
			
			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
