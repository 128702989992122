import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { messageConversationInfo } from "configurations/redux/messageConversationSlice.js";
import { messageChatroomListInfo } from "configurations/redux/messageChatroomListSlice.js";
import {
	updateChatroom,
	messageChatroomInfo,
} from "configurations/redux/messageChatroomSlice";
import { messageConversationSearchReset } from "configurations/redux/messageConversationSearchSlice.js";
import CardAvatar from "components/Card/CardAvatar.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import SpeakerNotesOffIcon from "@material-ui/icons/SpeakerNotesOff";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Search, Person } from "@material-ui/icons/";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { encryptData } from "configurations/configurations.js";
import { Link } from "react-router-dom";
import CustomDialog from "../../../components/CustomDialog/CustomDialog.js";
import CustomDialogSearchMessage from "../MessagePage/CustomDialogSearchMessage.js";
import defaultAvatar from "assets/img/placeholder.jpg";
import { isMobile, isMobileOnly, isTablet } from "react-device-detect";
import messageDashboardStyle from "assets/jss/material-kit-pro-react/views/pageStyle/MessagePage/messagePageStyle.js";
import candidate_avatar from "assets/img/candidate_avatar.jpeg";
import corporate_avatar from "assets/img/corporate_avatar.jpeg";
import { HmacSHA512 } from "crypto-js";

const useStyles = makeStyles(messageDashboardStyle);
const publicIp = require("public-ip");
export default function MessageConversationHeader({ children, props }) {
	// console.log(children);
	const classes = useStyles();
	const dispatch = useDispatch();
	const userStatus = useSelector((state) => state.auth.user);
	const searchChatroom = useSelector(
		(state) => state.messageChatroom.searchResult
	);
	const searchId = useSelector(
		(state) => state.messageConversationSearch.searchId
	);

	const customDialogDefault = {
		title: "Message Search",
		description: "Kindly enter the keyword that you want to search",
	};

	const [customDialog, setCustomDialog] = React.useState({
		isOpen: false,
		customDialogDefault,
	});

	const dialogDefault = {
		title: "",
		description: "",
		buttonYes: "",
		buttonNo: "",
	};
	const [confirmDialog, setConfirmDialog] = React.useState({
		isOpen: false,
		dialogDefault,
	});
	const showProfilePic =
		children.privacySetting &&
		children.privacySetting
			.filter((item) => {
				return item.settingName === "showProfilePic";
			})
			.map((item) => {
				return item.settingValue === 1;
			})[0];
	const showProfile =
		children.privacySetting &&
		children.privacySetting
			.filter((item) => {
				return item.settingName === "showProfile";
			})
			.map((item) => {
				return item.settingValue === 1;
			})[0];
	const showHeadline =
		children.privacySetting &&
		children.privacySetting
			.filter((item) => {
				return item.settingName === "showHeadline";
			})
			.map((item) => {
				return item.settingValue === 1;
			})[0];
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	const getProfileNavigate = (item) => {
		console.log(item);
		setAnchorEl(null);
		if (item.bizId > 0)
			window.open(
				`/corporate-profile-page/${encryptData(item.acctId)}/${encryptData(
					item.bizId
				)}`,
				"_blank"
			);
		else window.open(`/view-profile/${encryptData(item.acctId)}`, "_blank");
	};

	const updateChatroomStatus = (item, newStatus) => {
		setAnchorEl(null);
		if (item.crStatus <= 3 && userStatus.acctTypeId == 2) {
			if (newStatus == 3) {
				var dialogDefault = {
					title: "Alert",
					description: "Are you sure to close this conversation?",
					buttonYes: "Proceed",
					buttonNo: "Cancel",
				};
			} else if (newStatus < 3) {
				var dialogDefault = {
					title: "Alert",
					description: "Are you sure to start this conversation?",
					buttonYes: "Proceed",
					buttonNo: "Cancel",
				};
			}
			setConfirmDialog({
				isOpen: true,
				dialogDefault,
				onConfirm: () => {
					const chatroom = {
						acctId: userStatus.acctId,
						email: userStatus.email,
						accessToken: localStorage.getItem("token"),
						chatroomId: item.chatroomId,
						chatroomStatus: newStatus,
					};
					dispatch(updateChatroom(chatroom));
					setTimeout(() => {
						setConfirmDialog({
							isOpen: false,
							dialogDefault,
						});
						const requestProfile = {
							acctId: userStatus.acctId,
							email: userStatus.email,
							accessToken: localStorage.getItem("token"),
							lastRecordSize: 0,
							requestRecordSize: 15,
							chatroomId: item.chatroomId,
							keyword: "",
						};
						dispatch(messageChatroomListInfo(requestProfile));
						dispatch(messageConversationInfo(requestProfile));
						loadChatroom();
					}, 500);
				},
			});
		} else {
			const dialogDefault = {
				title: "Warning",
				description: "You are not allow to close this conversation",
				buttonYes: "",
				buttonNo: "OK",
			};
			setConfirmDialog({
				isOpen: true,
				dialogDefault,
			});
		}
	};

	const loadChatroom = () => {
		const requestInfo = {
			acctId: userStatus.acctId,
			accessToken: localStorage.getItem("token"),
			email: userStatus.email,
			lastRecordSize: 0,
			requestRecordSize: 15,
			searchResult: searchChatroom,
			keyword: "",
			bizId: 0,
		};
		// console.log(requestInfo);
		dispatch(messageChatroomInfo(requestInfo));
	};

	React.useEffect(() => {
		// console.log(searchId);
		if (searchId > 0) {
			var customDialogDefault = {
				title: "",
				description: "",
				chatroomId: 0,
				acctId: "",
				email: "",
			};
			setCustomDialog({
				isOpen: false,
				customDialogDefault,
			});
			dispatch(messageConversationSearchReset());
		}
	}, [searchId]);

	return (
		<React.Fragment>
			{isMobileOnly && (
				<CardAvatar
					plain
					profile
					className={
						classes.messageProfilePicMobile + " " + classes.messageProfile
					}
				>
					<img
						src={
							showProfile
								? showProfilePic && children.profilePic !== null
									? children.profilePic
									: children.acctTypeId === 1 || children.acctTypeId === 3
									? candidate_avatar
									: corporate_avatar
								: children.acctTypeId === 1 || children.acctTypeId === 3
								? candidate_avatar
								: corporate_avatar
						}
						alt="..."
						onClick={() => {
							console.log(children);
							getProfileNavigate(children);
						}}
					/>
				</CardAvatar>
			)}
			{isMobileOnly && (
				<GridContainer className={classes.messageTitleMobile}>
					<GridItem>
						<Link
							to={{
								pathname: ``,
							}}
							className={classes.textDeco}
							onClick={() => {
								getProfileNavigate(children);
							}}
							color="transparent"
						>
							<div>
								{children.companyName != null ? (
									<div>
										<h5
											className={
												classes.titleMobile + " " + classes.textOverflowHide
											}
										>
											{children.companyName}
										</h5>
										<h6 className={classes.textOverflowHide}>
											{children.fullName}
										</h6>
										{/* <h5>{children.companyLocation}</h5> */}
									</div>
								) : (
									<div>
										<h5
											className={
												classes.titleMobile + " " + classes.textOverflowHide
											}
										>
											{children.fullName}
										</h5>
										<h6 className={classes.textOverflowHide}>
											{showProfile ? (
												showHeadline &&
												children.headline !== null &&
												children.headline.length > 0 ? (
													children.headline
												) : (
													<br />
												)
											) : null}
										</h6>
										<h5>
											{/* {children.experienceLocation != null
												? children.experienceLocation
												: children.educationLocation != null
												? children.educationLocation
												: null} */}
										</h5>
									</div>
								)}
							</div>
						</Link>
					</GridItem>
				</GridContainer>
			)}
			{isMobileOnly && (
				<GridContainer>
					<GridItem className={classes.messageMargin}>
						<IconButton
							className="iconButton"
							variant="outlined"
							edge="start"
							aria-label="more"
							style={{ left: 0 }}
							onClick={handleClick}
						>
							<MoreVertIcon />
						</IconButton>
						<Popover
							id={id}
							open={open}
							anchorEl={anchorEl}
							onClose={handleClose}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "right",
							}}
							transformOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
						>
							<List component="nav">
								{children.crStatus < 3 ? (
									<ListItem
										button
										onClick={() => {
											updateChatroomStatus(children, 3);
										}}
									>
										<ListItemIcon>
											<SpeakerNotesOffIcon />
										</ListItemIcon>
										<ListItemText primary="Close Conversation" />
									</ListItem>
								) : (
									<ListItem
										button
										onClick={() => {
											updateChatroomStatus(children, 2);
										}}
									>
										<ListItemIcon>
											<SpeakerNotesOffIcon />
										</ListItemIcon>
										<ListItemText primary="Start Conversation" />
									</ListItem>
								)}
								<ListItem
									button
									onClick={() => {
										setAnchorEl(null);
										var customDialogDefault = {
											title: "Message Search",
											description:
												"Kindly enter the keyword that you want to search",
											chatroomId: children.chatroomId,
											acctId: userStatus.acctId,
											email: userStatus.email,
										};
										setCustomDialog({
											isOpen: true,
											customDialogDefault,
										});
									}}
								>
									<ListItemIcon>
										<Search />
									</ListItemIcon>
									<ListItemText primary="Search" />
								</ListItem>
								<ListItem
									button
									onClick={() => {
										getProfileNavigate(children);
									}}
								>
									<ListItemIcon>
										<Person />
									</ListItemIcon>
									<ListItemText primary="View Profile" />
								</ListItem>
							</List>
						</Popover>
					</GridItem>
				</GridContainer>
			)}
			{/* <CardHeader className={classes.messageCardHeader}>
				<Card className={classes.messageMargin0 + " " + classes.borderRadius}> */}
			{!isMobileOnly && (
				<GridContainer
					className={classes.gridMargin}
					justifyContent="center"
					alignContent="center"
					/* alignItems="center" */
				>
					<GridItem
						/* className={classes.messageMargin} */
						xs={4}
						sm={2}
						md={2}
						lg={1}
						className={classes.itemCenter + " " + classes.gridPadding}
					>
						{/* 	<CardAvatar
							plain
							profile
							className={
								(classes.messageProfilePic,
								classes.imgRoundedCircle,
								classes.messageProfile)
							}
						> */}

						<img
							className={
								(classes.messageProfilePic,
								classes.imgRoundedCircle,
								classes.messageProfile)
							}
							src={
								showProfile
									? showProfilePic && children.profilePic !== null
										? children.profilePic
										: children.acctTypeId === 1 || children.acctTypeId === 3
										? candidate_avatar
										: corporate_avatar
									: children.acctTypeId === 1 || children.acctTypeId === 3
									? candidate_avatar
									: corporate_avatar
							}
							alt="..."
							onClick={() => {
								console.log(children);
								getProfileNavigate(children);
							}}
						/>
						{/* </CardAvatar> */}
					</GridItem>

					<GridItem
						/* 	className={classes.messageMargin} */
						xs={4}
						sm={7}
						md={7}
						lg={9}
					>
						<Link
							to={{
								pathname: ``,
							}}
							className={classes.textDeco}
							onClick={() => {
								console.log(children);
								getProfileNavigate(children);
							}}
							color="transparent"
						>
							<div className={classes.messageTitle}>
								{children.companyName != null ? (
									<div>
										<h5
											className={classes.title + " " + classes.textOverflowHide}
										>
											{children.companyName}
										</h5>
										<h6 className={classes.textOverflowHide}>
											{children.fullName}
										</h6>
										{/* <h5>{children.companyLocation}</h5> */}
									</div>
								) : (
									<div>
										<h5
											className={classes.title + " " + classes.textOverflowHide}
										>
											{children.fullName}
										</h5>
										<h6 className={classes.textOverflowHide}>
											{showProfile ? (
												showHeadline &&
												children.headline !== null &&
												children.headline.length > 0 ? (
													children.headline
												) : (
													<br />
												)
											) : null}
										</h6>
										<h5>
											{/* {children.experienceLocation != null
												? children.experienceLocation
												: children.educationLocation != null
												? children.educationLocation
												: null} */}
										</h5>
									</div>
								)}
							</div>
						</Link>
					</GridItem>
					<GridItem
						className={classes.iconPosition}
						xs={4}
						sm={3}
						md={3}
						lg={2}
					>
						<IconButton
							className="iconButton"
							variant="outlined"
							edge="start"
							aria-label="more"
							onClick={() => {
								setAnchorEl(null);
								var customDialogDefault = {
									title: "Message Search",
									description:
										"Kindly enter the keyword that you want to search",
									chatroomId: children.chatroomId,
									acctId: userStatus.acctId,
									email: userStatus.email,
								};
								setCustomDialog({
									isOpen: true,
									customDialogDefault,
								});
							}}
						>
							<Search />
						</IconButton>
						<IconButton
							className="iconButton"
							variant="outlined"
							edge="start"
							aria-label="more"
							onClick={handleClick}
						>
							<MoreVertIcon />
						</IconButton>
						<Popover
							id={id}
							open={open}
							anchorEl={anchorEl}
							onClose={handleClose}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "right",
							}}
							transformOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							// className={{paper: classes.popoverFrame}}
						>
							<List component="nav">
								{children.crStatus < 3 ? (
									<ListItem
										button
										onClick={() => {
											updateChatroomStatus(children, 3);
										}}
									>
										<ListItemIcon>
											<SpeakerNotesOffIcon />
										</ListItemIcon>
										<ListItemText primary="Close Conversation" />
									</ListItem>
								) : (
									<ListItem
										button
										onClick={() => {
											updateChatroomStatus(children, 2);
										}}
									>
										<ListItemIcon>
											<SpeakerNotesOffIcon />
										</ListItemIcon>
										<ListItemText primary="Start Conversation" />
									</ListItem>
								)}
								{/* <ListItem
											button
											onClick={() => {
												setAnchorEl(null);
												var customDialogDefault = {
													title: "Message Search",
													description:
														"Kindly enter the keyword that you want to search",
													chatroomId: children.chatroomId,
													acctId: userStatus.acctId,
													email: userStatus.email,
												};
												setCustomDialog({
													isOpen: true,
													customDialogDefault,
												});
											}}
										>
											<ListItemIcon>
												<Search />
											</ListItemIcon>
											<ListItemText primary="Search" />
										</ListItem> */}
								<ListItem
									button
									onClick={() => {
										console.log(children);
										getProfileNavigate(children);
									}}
								>
									<ListItemIcon>
										<Person />
									</ListItemIcon>
									<ListItemText primary="View Profile" />
								</ListItem>
							</List>
						</Popover>
					</GridItem>
				</GridContainer>
			)}
			{/* 	</Card>
			</CardHeader> */}
			<CustomDialog
				confirmDialog={confirmDialog}
				setConfirmDialog={setConfirmDialog}
			/>
			<CustomDialogSearchMessage
				customDialog={customDialog}
				setCustomDialog={setCustomDialog}
			/>
		</React.Fragment>
	);
}
