import React from 'react';
import { CardMedia, Chip, CircularProgress, Divider } from '@material-ui/core';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import GridItem from 'components/Grid/GridItem';
import { Link } from 'react-router-dom';
import { encryptData } from 'configurations/configurations.js';
import SearchImage from 'assets/img/Search_Image.jpg';
import NoResultImage from 'assets/img/No_Result.jpg';
import { TimeFormatOnlyDate } from 'configurations/configurations.js';
import defaultAvatar from 'assets/img/corporate_avatar.jpeg';
import GridContainer from 'components/Grid/GridContainer';
import SmallCardLoading from '../SmallCardLoading';

import communityPageStyle from 'assets/jss/material-kit-pro-react/views/pageStyle/CommunityPage/communityPageStyle.js';
const useStyles = makeStyles(communityPageStyle);

export default function ArticleCard({ item }) {
  const classes = useStyles();
  const isLoading = useSelector(state => state.community.isLoading);
  const communityArticlePath = useSelector(state => state.community.communityArticlePath);
  const auth = useSelector(state => state.auth);
  function imageExists(image_url) {
    var http = new XMLHttpRequest();

    try {
      http.open('HEAD', image_url, false);
      http.send();
    } catch (error) {}

    return http.status == 200;
  }

  const ArticleComponent = () => {
    return (
      <Link
        className={classes.textDeco}
        target="_blank"
        to={{
          pathname: auth.isPublic
            ? `/public-community-detail-page/${encryptData(item.articleId)}`
            : `/community-detail-page/${encryptData(item.articleId)}`,
          //state: { acctId: item.acctId },
        }}
        color="transparent"
        key={item.articleId}
      >
        <div key={item.articleId}>
          <Card
            className={classes.cardClick + ' ' + classes.cardHeight + ' ' + classes.articleCardFrame}
            style={{ margin: '10px 0' }}
          >
            <CardMedia
              className={classes.cardImage}
              image={
                item.coverPic && imageExists(communityArticlePath + item.coverPic)
                  ? communityArticlePath + item.coverPic
                  : defaultAvatar
              }
              title={item.articleName}
            />
            {/* 		<CardBody className={classes.cardPadding}></CardBody> */}
            <CardBody className={classes.cardPadding}>
              <div>
                <GridContainer>
                  <GridItem xs={6}>
                    <Chip
                      key={item.subcategoryId}
                      label={
                        <p className={classes.createdDescription + ' ' + classes.createdDescFont}>{item.subcategory}</p>
                      }
                      className={classes.chip}
                      color={'secondary'}
                    />
                  </GridItem>
                  <GridItem xs={6} className={classes.textAlignRight}>
                    <span className={classes.createdDescFont}>{TimeFormatOnlyDate(item.publishDate)}</span>
                  </GridItem>
                </GridContainer>
              </div>
              <div className={classes.titleDiv}>
                <h4 className={classes.articleTitle + ' ' + classes.articleCardTitle}>{item.articleName}</h4>
              </div>
              <div>
                <span className={classes.createdDescFont}> By : </span>
                <span className={classes.createdDescription + ' ' + classes.createdDescFont}>{item.author}</span>
              </div>
            </CardBody>
          </Card>
        </div>
      </Link>
    );
  };
  return <React.Fragment>{isLoading ? <SmallCardLoading /> : <ArticleComponent />}</React.Fragment>;
}
