import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Box, makeStyles, Modal, Typography, IconButton } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import { errorCleanUp } from "configurations/redux/authSlice.js";
import Header from "views/Component/Header.js";
import LeftSidebar from "views/Component/LeftSidebar.js";
import EditProfile from "views/Component/ProfileView/EditProfile/EditProfile";
import Footer from "views/Component/Footer.js";
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import resumePageStyles from "assets/jss/material-kit-pro-react/views/pageStyle/ResumePage/resumePageStyles";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js";
import CloseIcon from "@mui/icons-material/Close";
import Hidden from "@material-ui/core/Hidden";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import AddIcon from "@mui/icons-material/Add";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import businessPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/BusinessPage/businessPageStyle";
import leftarrowPic from "assets/img/left_arow.png";
import checkcircel from "assets/img/check-circel.png";
import adduser from "assets/img/add-user.png";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import emailRegex from 'email-regex';
import {
  getSubscriptionPlan, getCurrentSubscribePack, checkoutSubscriptionPlan, getAllRoleOfAccount, getSubscriptionList, getSubscriptionPaymentInfo, getSubscriptionPlanCandidate, getAllAccountPages, getPermissionGrant, getlistingBusinessPages, cancelSubscription, deletePermission, activeCandidatePlan,
  checkoutFreeSubscription
} from "configurations/redux/subscriptionSetting";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import $ from "jquery";
import moment from 'moment';
import { encryptData } from "configurations/configurations.js";
import { Formik, useFormik } from "formik";
import { useSnackbar } from "notistack";
import { TextField } from "@material-ui/core";
import { setKeyword } from "configurations/redux/communitySlice";
import { ItemMeta } from "semantic-ui-react";

const SubscriptionSettingsPage = ({ match }) => {
  const styles = makeStyles(resumePageStyles)();
  const stylesBusiness = makeStyles(businessPageStyle)();
  const classes = makeStyles(profilePageStyle)(); //profilePageStyle
  const history = useHistory();
  const dispatch = useDispatch();
  const isError = useSelector((state) => state.auth.isError);
  const userStatus = useSelector((state) => state.auth.user);
  const searchResult = useSelector((state) => state.business.searchResult);
  const [connStatus, setConnStatus] = React.useState(null);
  const [isEditProfileOpen, setIsEditProfileOpen] = useState(false);
  const [width, height] = useWindowSize();
  const bizId = useSelector((state) => state.business.bizId);
  const [value, setValue] = React.useState(0);
  const [key, setKey] = React.useState(Math.random());
  const [storagePlan, setStoragePlan] = React.useState({});
  const [videoPlan, setVideoPlan] = React.useState({});
  const handleChange = (event, newValue) => {

    console.log(event);
    console.log(newValue);


    console.log('taisha');
    setValue(newValue);

    // const isValid = emailRegex().test('unicorn' + email);
    // if (isValid) {
    //   setEmailIsValid(false)
    // } else {
    //   setEmailIsValid(true)
    // }
    // return isValid;


  };
  const [open11, setOpen11] = React.useState(false);
  const handleOpen11 = () => setOpen11(true);
  const handleClose11 = () => setOpen11(false);
  const [open222, setOpen222] = React.useState(false);
  const handleOpen222 = () => setOpen222(true);
  const handleClose222 = () => setOpen222(false);
  const [open12, setOpen12] = React.useState(false);
  const handleOpen12 = () => setOpen12(true);
  const handleClose12 = () => setOpen12(false);
  const [emailIsValid, setEmailIsValid] = React.useState(false);

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }
  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 46,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 3,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#554BE9',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 20,
      height: 20,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#5b45e0' : '#E9E9EA',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));
  const rows = [
    createData('abx@gmail.com', 'Content Manager', 'Richdale Malaysia', 'Pending Acceptance', 'Remove Access'),
    createData('jansen@oppty.net', 'Recruiter Manager', 'OppTy Pte Ltd', 'Active', 'Remove Access'),
  ];
  const style1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const style2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div {...other}>
        {value === index &&
          <Box p={3}>{children}</Box>
        }
      </div>
    );
  }
  function useWindowSize() {
    const [size, setSize] = React.useState([0, 0]);
    React.useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }
  React.useEffect(() => {
    const request = {
      type: 1
    };
    dispatch(getSubscriptionPlan(request));
  }, []);
  React.useEffect(() => {
    const request = {
      type: 2
    };
    dispatch(getSubscriptionPlanCandidate(request));
  }, []);
  React.useEffect(() => {
    const request = {
      email: userStatus.email,
      accessToken: localStorage.getItem("token"),
      acctId: userStatus.acctId,
      recordId: userStatus.acctId,
      typeId: 1
    };
    dispatch(getCurrentSubscribePack(request));
  }, []);

  React.useEffect(() => {
    dispatch(getAllRoleOfAccount());
  }, []);

  const subscriptionPlans = useSelector((state) => state.subscriptionSetting.subscriptionPlans);
  const currentSubscribed = useSelector((state) => state.subscriptionSetting.currentSubscription);
  const paymentList = useSelector((state) => state.subscriptionSetting.paymentList);
  const subscriptionPlansCandidate = useSelector((state) => state.subscriptionSetting.getSubsCandidate);
  const getRolesManages = useSelector((state) => state.subscriptionSetting.roleNames);
  const accountPages = useSelector((state) => state.subscriptionSetting.accountPages)
  const businessPages = useSelector((state) => state.subscriptionSetting.businessPages)
  const candidateVideoPlan = useSelector((state) => state.subscriptionSetting.currentSubsPlanCandidate)
  const candidateStoragePlan = useSelector((state) => state.subscriptionSetting.currentSubsPlanOfStorage)
  const [indexData, setIndexData] = useState(0)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const onClickDismiss = (key) => () => {
    closeSnackbar(key);
  };
  const snackbarCustom = (title) => {
    const action = (key) => (
      <React.Fragment>
        <IconButton onClick={onClickDismiss(key)}>
          <CloseIcon />
        </IconButton>
      </React.Fragment>
    );
    snackBarShow(title, "default", action);
  };


  const snackBarShow = (message, variant, action) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, {
      variant: variant,
      action,
    });
  };
  React.useEffect(() => {
    if (isError) {
      dispatch(errorCleanUp());
    }
  }, []);

  React.useEffect(() => {
    const request = {
      acctId: userStatus.acctId,
    };
    dispatch(getSubscriptionList(request))
  }, []);

  const stripePayMethod = () => {
    if (paymentCde == '') {
      snackbarCustom('Please Select payment')
      return true
    }
    if (paymentCde == 'freePlan') {
      const request = {
        email: userStatus.email,
        acctId: userStatus.acctId,
        accessToken: localStorage.getItem("token"),
        typeId: 1,
        recordId: userStatus.acctId,
        planId: 1,
        expiryMonth: 6
      };
      dispatch(checkoutFreeSubscription(request, (result) => {
        if (result) {
          setOpen222(false)
          snackbarCustom(result.message)
        }
      }))
      setTimeout(() => {
        getActiveSubsCriptionPlan()
      }, 2500);
    }
    else {
      const request = {
        email: userStatus.email,
        acctId: userStatus.acctId,
        accessToken: localStorage.getItem("token"),
        priceCode: paymentCde
      };
      dispatch(checkoutSubscriptionPlan(request, (result) => {
        if (result.payload.updateResult) {
          window.open(result.payload.url, "_blank");
        } else {
          snackbarCustom('You have already subscription plan if you want to new subscription plan please cancel current plan')
        }
      }))
    }

  }
  const activeCandidatePlanOfStrorage = () => {
    const request = {
      email: userStatus.email,
      acctId: userStatus.acctId,
      recordId: userStatus.acctId,
      accessToken: localStorage.getItem("token"),
      typeId: 2,
      planId: 5
    };
    dispatch(activeCandidatePlan(request));
  }

  const activePlanOfVideo = () => {
    const request = {
      email: userStatus.email,
      acctId: userStatus.acctId,
      recordId: userStatus.acctId,
      accessToken: localStorage.getItem("token"),
      typeId: 2,
      planId: 6
    };
    dispatch(activeCandidatePlan(request));
  }
  React.useEffect(() => {
    activeCandidatePlanOfStrorage()
    activePlanOfVideo()
  }, []);

  const handlePaymentOfStripe = (priceCode) => {
    const request = {
      email: userStatus.email,
      acctId: userStatus.acctId,
      accessToken: localStorage.getItem("token"),
      priceCode: priceCode
    };
    dispatch(checkoutSubscriptionPlan(request, (result) => {
      if (result.payload.updateResult) {
        window.open(result.payload.url, "_blank");
      } else {
        return true
        snackbarCustom('You have already subscription plan if you want to new subscription plan please cancel current plan')
      }
    }))
  }
  const [payment, setPayment] = useState([])
  const handlePayment = (paymentData) => {
    setPayment(paymentData)
    setPaymentCde('')
    setOpen222(true)
  }
  const handlePaymentInfo = () => {
    const request = {
      email: userStatus.email,
      acctId: userStatus.acctId,
      accessToken: localStorage.getItem("token"),
    };
    dispatch(getSubscriptionPaymentInfo(request, (result) => {
      if (result.payload.updateResult) {
        window.open(result.payload.url, "_blank");
      }
    }))
  }

  const [paymentCde, setPaymentCde] = useState('')
  const handlePaymentData = (paymentCodeData) => {
    $('.subPlan').removeClass('active')
    if (paymentCodeData) {
      $('#' + paymentCodeData).addClass('active')
    } else {
      $('#freeSubPlan').addClass('active')
    }
    $('.selectPlanOuter').css('display', 'none')
    $('#show' + paymentCodeData).css('display', 'block')
    $('#freeSubPlan').css('display', 'block')
    if (paymentCodeData) {
      setPaymentCde(paymentCodeData)
    } else {
      setPaymentCde('freePlan')
    }
  }
  const [bizzId, setBizzId] = useState('')

  const handleChangeData = (event) => {

    setBizzId(event.target.value);
  };
  React.useEffect(() => {
    const request = {
      email: userStatus.email,
      acctId: userStatus.acctId,
      accessToken: localStorage.getItem("token"),
      lastRecordSize: 0,
      requestRecordSize: 10
    };
    dispatch(getAllAccountPages(request))
  }, []);

  const [businessPage, setBusinessPage] = useState([])

  const listingPage = () => {
    const request = {
      email: userStatus.email,
      bizId: bizzId,
      accessToken: localStorage.getItem("token"),
      lastRecordSize: 0,
      requestRecordSize: 100
    };
    dispatch(getlistingBusinessPages(request))
  }
  React.useEffect(() => {
    const request = {
      email: userStatus.email,
      bizId: bizzId,
      accessToken: localStorage.getItem("token"),
      lastRecordSize: 0,
      requestRecordSize: 100
    };
    dispatch(getlistingBusinessPages(request), (result) => {
      console.log('getlistingBusinessPages', result)
    })
  }, [bizzId]);


  const handlePermission = (data) => {
    if (bizzId == '') {
      snackbarCustom('Please Select Page')
      return true
    }
    if (data.roleId == '') {
      snackbarCustom('Please Select role')
      return true
    }
    if (data.email == '') {
      snackbarCustom('Please Enter invite email')
      return true
    } else if (data.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
      data.email
    )) {
      snackbarCustom('Please Enter valid email address')
      return true
    }

    const request = {
      email: userStatus.email,
      acctId: userStatus.acctId,
      accessToken: localStorage.getItem("token"),
      inviteEmail: data.email,
      bizId: bizzId,
      roleId: data.roleId
    };
    dispatch(getPermissionGrant(request, (result) => {
      if (result.payload.result == true) {
        snackbarCustom('Granted Access Request has been sent');
        setTimeout(() => {
          listingPage()
        }, 2000);
      } else {
        snackbarCustom("Granted Access failed, Please check for duplication email");
      }
    }))
  }
  const [subsIc, setSubsId] = useState({})
  const handleCancelSubscription = (data) => {
    setSubsId(data)
    setOpen11(true);
  }

  const handleCandidateIndex = (e) => {
    e.target.checked == true ? setIndexData(1) : setIndexData(0);
  }

  const handleCancelSubs = () => {
    const request = {
      email: userStatus.email,
      acctId: userStatus.acctId,
      accessToken: localStorage.getItem("token"),
      subscriptionId: subsIc.subscriptionId
    };

    dispatch(cancelSubscription(request, (result) => {
      console.log(result)
      if (result) {
        setOpen11(false);
        setOpen12(true)
      }
    }))
    setTimeout(() => {
      getActiveSubsCriptionPlan()
      activeCandidatePlanOfStrorage()
      activePlanOfVideo()
    }, 1500);
  }

  const getActiveSubsCriptionPlan = () => {
    const requestData = {
      email: userStatus.email,
      accessToken: localStorage.getItem("token"),
      acctId: userStatus.acctId,
      recordId: userStatus.acctId,
      typeId: 1
    };
    dispatch(getCurrentSubscribePack(requestData));
  }
  const handleRemovePermission = (row) => {
    const request = {
      email: row.email,
      acctId: userStatus.acctId,
      accessToken: localStorage.getItem("token"),
      bpUserId: row.bpUserId
    };
    dispatch(deletePermission(request, (result) => {
      if (result.payload.result) {
        snackbarCustom(result.message);
        setTimeout(() => {
          listingPage()
        }, 1500);
      } else {
        snackbarCustom(result.message);
      }
    }));
  }
  return (
    <div>
      <Header color={"transparent"} loggedInStatus={false} />
      <div className={styles.body}>
        <div id="pageHeader" className='pageHeaderSubscription'>
          <div className={styles.container}>
            <GridContainer justifyContent="center" spacing={1}>
              <Hidden mdDown>
                <GridItem xs={12} sm={12} md={2} lg={2}>
                  <LeftSidebar />
                </GridItem>
              </Hidden>
              <GridItem xs={12} sm={12} md={10} lg={10} >
                <div className="sub-page-outer">
                  <div className="subscription_link">
                    {/* <Link to={`/biz-page/${encryptData(userStatus.acctId)}`}>
                      <img src={leftarrowPic} />
                      <h4>Go to Business Page Settings</h4>
                    </Link> */}
                  </div>
                  <div className="heading-sub">
                    <h3>Subscription Settings</h3>
                  </div>
                  <Tabs className='customTabs1' value={value} onChange={handleChange}>
                    <Tab className="color_grey active" label="Business Plan" />
                    <Tab className="color_grey" label="Individual Plan" />
                    <Tab className="color_grey" label="Payment" />
                    <Tab className="color_grey" label="Account Management" />
                  </Tabs>
                  <TabPanel value={value} index={0}>
                    {
                      currentSubscribed && currentSubscribed.length > 0 && currentSubscribed[0].planId != 0 ?
                        <div className="small_box">
                          <div className='premiumOuter'>
                            <h5 className="text-capitalize">{currentSubscribed[0].planName} ( {moment(currentSubscribed[0].expiryDate).diff(moment(currentSubscribed[0].createdDate), 'months', true)} months ) {/*<span>USD $360/month</span> */}</h5>
                          </div>
                          <div className="smallBoxBody">
                            <label className='subLabel'>Subscription start date</label>
                            <p className='subP'>{moment(currentSubscribed[0].createdDate).format('MMMM DD, Y')}</p>
                            <label className='subLabel'>Subscription renewal date</label>
                            <p className='subP'>{moment(currentSubscribed[0].expiryDate).format('MMMM DD, Y')}</p>
                            <label className='subLabel'>Status</label>
                            <p className='subP'>{currentSubscribed[0].statusName}</p>
                            <Button className='viewPayment' onClick={() => handleCancelSubscription(currentSubscribed[0])}>Cancel Subscription</Button>
                            {/* <Link href="#" className='cancelSub'>Cancel Subscription</Link> */}
                          </div>
                        </div>
                        :
                        <div className="small_box">

                          <h5>You do not have any subscriptions currently</h5>
                          {/* <Button className='applyBtn' onClick={handleOpen12}>Subscription Cancelled</Button> */}
                          <div className="smallBoxBody">
                          </div>
                        </div>
                    }
                    <div className="user_list">
                      <ul>
                        <li className="list1">
                          <div className="top_heading">
                          </div>
                          <div className="bottom_text tet68">
                            <p>Internship Job Post</p>
                            <p>Candidate Search</p>
                            <p>Job Post or <br />
                              Digital Headhunting
                            </p>
                            <p>A.I. Chatbot</p>
                            <p>Boost Job Post</p>
                            <div className="buton_name Hidden">
                              <button className="MuiButtonBase-root MuiButton-root MuiButton-text makeStyles-button-309 followBtn1" tabIndex="0" type="button"><span className="MuiButton-label">Subscribe</span><span className="MuiTouchRipple-root"></span></button>
                            </div>
                          </div>
                        </li>
                        {
                          subscriptionPlans && subscriptionPlans.length > 0 &&
                          subscriptionPlans.map((item) => {
                            return (
                              <li className="list1" key={item.planId}>
                                <div className="top_heading">
                                  <h4 style={{ textTransform: "capitalize" }}>{item.planName}</h4>
                                  <p><del>${item.price}</del></p>
                                  <h1>${item.discountPrice}</h1>
                                  <p>Per month<br />
                                    Billed quarterly
                                  </p>
                                </div>
                                <div className="bottom_text">
                                  <p className="left_list6"><i className="fa-solid fa-circle-check"></i><span>{item.planDetail[0].quantity == -1 ? "Unlimited Internship" : item.planDetail[0].quantity} < br />
                                    Job Post</span>
                                  </p>

                                  {
                                    item.planDetail.map((key, index) => {
                                      if (key.detailTypeId == 1) {
                                        return (
                                          <p>{key.quantity == -1 ? "Unlimited" : key.quantity}</p>
                                        )
                                      }
                                    })
                                  }
                                  {
                                    item.planDetail.map((key, index) => {
                                      if (key.detailTypeId == 2) {
                                        return (
                                          <p>{key.quantity == -1 ? "Unlimited" : key.quantity}</p>
                                        )
                                      }
                                    })
                                  }
                                  {
                                    item.planDetail.map((key, index) => {
                                      if (key.detailTypeId == 6) {
                                        return (
                                          <div>
                                            {key.quantity == -1 ? <p className="left_list6"><i className="fa-solid fa-circle-check"></i><span>Full Features</span></p>
                                              : <p className="left_list6"><span>-</span></p>}
                                          </div>
                                        )
                                      }
                                    })
                                  }
                                  {
                                    item.planDetail.map((key, index) => {
                                      if (key.detailTypeId == 5) {
                                        return (
                                          <p>{key.quantity == -1 ? "Unlimited" : key.quantity}</p>
                                        )
                                      }
                                    })
                                  }
                                  <div className="buton_name">
                                    {/* <button className="MuiButtonBase-root MuiButton-root MuiButton-text makeStyles-button-309 followBtn1" onClick={() => stripePayMethod(item.planPrice[1])} tabIndex="0" type="button"><span className="MuiButton-label">Subscribe</span><span className="MuiTouchRipple-root"></span></button> */}

                                    {
                                      currentSubscribed && currentSubscribed.length > 0 && currentSubscribed[0].planId === item.planId ? <Button className="MuiButtonBase-root activePlan MuiButton-root MuiButton-text makeStyles-button-309 followBtn1" tabIndex="0" type="button"><span className="MuiButton-label ">Active Plan</span><span className="MuiTouchRipple-root"></span></Button> :
                                        <Button className="MuiButtonBase-root MuiButton-root MuiButton-text makeStyles-button-309 followBtn1" onClick={() => handlePayment(item.planPrice)} tabIndex="0" type="button"><span className="MuiButton-label">Subscribe</span><span className="MuiTouchRipple-root"></span></Button>
                                    }

                                  </div>
                                </div>
                              </li>
                            );
                          })
                        }
                      </ul>
                    </div>
                  </TabPanel>
                  <TabPanel className="value_color" value={value} index={1}>
                    <GridContainer justifyContent="center" spacing={4}>
                      <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                        {
                          candidateVideoPlan.length == 0 && candidateStoragePlan.length == 0 &&
                          <div className="small_box individualBox">
                            <h5>You do not have subscriptions currently</h5>
                            <div className="smallBoxBody">
                            </div>
                          </div>
                        }

                        {
                          candidateVideoPlan.length !== 0 &&
                          <div className="small_box individualBox">
                            <div className='premiumOuter'>
                              <h5 className="text-capitalize">{candidateVideoPlan[0].planName} (Monthly)</h5>
                            </div>
                            <div className="smallBoxBody">
                              <label className='subLabel'>Subscription start date</label>
                              <p className='subP'>{moment(candidateVideoPlan[0].createdDate).format('MMMM DD, Y')}</p>
                              <label className='subLabel'>Subscription renewal date</label>
                              <p className='subP'>{moment(candidateVideoPlan[0].expiryDate).format('MMMM DD, Y')}</p>
                              <label className='subLabel'>Status</label>
                              <p className='subP'>{candidateVideoPlan[0].statusName}</p>
                              <Button className='viewPayment' onClick={() => handleCancelSubscription(candidateVideoPlan[0])}>Cancel Subscription</Button>
                            </div>
                          </div>
                        }
                        {
                          candidateStoragePlan.length !== 0 &&
                          <div className="small_box individualBox">
                            <div className='premiumOuter'>
                              <h5 className="text-capitalize">{candidateStoragePlan[0].planName} (Monthly)</h5>
                            </div>
                            <div className="smallBoxBody">
                              <label className='subLabel'>Subscription start date</label>
                              <p className='subP'>{moment(candidateStoragePlan[0].createdDate).format('MMMM DD, Y')}</p>
                              <label className='subLabel'>Subscription renewal date</label>
                              <p className='subP'>{moment(candidateStoragePlan[0].expiryDate).format('MMMM DD, Y')}</p>
                              <label className='subLabel'>Status</label>
                              <p className='subP'>{candidateStoragePlan[0].statusName}</p>
                              <Button className='viewPayment' onClick={() => handleCancelSubscription(candidateStoragePlan[0])}>Cancel Subscription</Button>
                            </div>
                          </div>
                        }
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography className='boxCustom cusBox'>
                          <Typography id="modal-modal-title" className='getSReady' variant="h6" component="h2">
                            Get ready to boost <br />your career today.
                          </Typography>
                          <Typography className='trialText' id="modal-modal-description" sx={{ mt: 2 }}>
                            30-day free trial, cancel any time. No credit card required.
                          </Typography>
                          <Typography component='div' className='switchCustom'>
                            <label>Bill Monthly</label>
                            <Switch
                              checked={indexData == 1 ? true : false}
                              onChange={handleCandidateIndex}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                            {/* <FormGroup className='cusswitch' key={key} defaultChecked onChange={(e) => { handleCandidateIndex(e) }}>
                              <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} />}
                              />

                            </FormGroup> */}
                            <label>Bill Annually </label>
                            <span className='saveSpan'>Save 10%</span>
                          </Typography>
                          <Typography component='div' className='planOuter'>
                            {
                              subscriptionPlansCandidate && subscriptionPlansCandidate.length > 0 ?
                                subscriptionPlansCandidate.map((item, index) => {
                                  var btn = 'Sign Up Now'
                                  if (item.planId == 6 && candidateVideoPlan.length !== 0 && item.planId == candidateVideoPlan[0].planId) {
                                    var btn = 'Current Plan'
                                  }
                                  if (item.planId == 5 && candidateStoragePlan.length !== 0 && item.planId == candidateStoragePlan[0].planId) {
                                    var btn = 'Current Plan'
                                  }
                                  return (
                                    <Typography component='div' className={`planLeft ${index == 1 ? 'planRight' : ''}`}>
                                      <Typography className='priceTitle'>{item.planPrice[indexData].totalPrice} <sup>/{item.planPrice[indexData].period}</sup></Typography>
                                      <Typography className='storageTitle'>{item.planName}</Typography>
                                      <Typography className='descTitle'>Access your resume, portfolio files all from one place.</Typography>
                                      <Button className='signUpNow' onClick={() => handlePaymentOfStripe(item.planPrice[indexData].priceCode)}>{btn}</Button>
                                    </Typography>
                                  )
                                })
                                :
                                ''
                            }
                          </Typography>
                        </Typography >
                      </GridItem>
                    </GridContainer>
                  </TabPanel>
                  <TabPanel className="value_color" value={value} index={2}>
                    <Button className='viewPayment' onClick={handlePaymentInfo}>View Payment Information</Button>
                    <div className='paymentReceipts'>Payment Receipts</div>
                    <Table striped bordered hover className='customTable'>
                      <thead>
                        <tr>
                          <th style={{ "width": "30%" }}>DATE</th>
                          <th>TOTAL</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paymentList && paymentList.length > 0 && paymentList.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td style={{ "width": "30%" }}>{moment(item.createdDate).format('DD MMM, YYYY')}</td>
                              <td>USD ${item.totalAmount}</td>
                              <td colSpan={3}><a className="downloadA" href="#"><i class="fa-solid fa-download" onClick={() => window.location = item.invoice}></i></a></td>
                            </tr>
                          )
                        })
                        }
                      </tbody>
                    </Table>
                  </TabPanel>
                  <TabPanel className="value_color" value={value} index={3}>
                    <div className="accout_setting">
                      <div className="textAcoount">
                        <div className='accoutselect_outer'>
                          <p>Users with access to your Business Page</p>
                          <Box sx={{ minWidth: 200 }}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">Select Page</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={bizzId}
                                label="Select Page"
                                onChange={handleChangeData}
                                className='customSelect'                              >
                                {accountPages && accountPages.length > 0 &&
                                  accountPages.map((item) => {
                                    return (
                                      <MenuItem value={item.bizId}>{item.pageName}</MenuItem>
                                    )
                                  })
                                }
                              </Select>
                            </FormControl>
                          </Box>
                        </div>
                      </div>
                      {bizzId &&
                        <React.Fragment>
                          <Formik
                            initialValues={{
                              email: "",
                              roleId: "",
                            }}
                            onSubmit={(data) => {
                              handlePermission(data)
                            }}
                          >
                            {
                              ({
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                touched,
                                errors,
                              }) => (
                                <GridContainer spacing={2}>
                                  <GridItem sm={12} md={6}>
                                    <form onSubmit={handleSubmit}>
                                      <h4 className='grantAccess'><img src={adduser} /> Grant access to your page</h4>
                                      <p>Enter an email address you would like to grant access to:</p>

                                      <TextField
                                        className='EmailAddressInput'
                                        type='email'
                                        variant="outlined"
                                        id="email"
                                        name="email"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        fullWidth
                                      />
                                      {errors.email && touched.email && (
                                        <div className="custom_email">{errors.email}</div>
                                      )}
                                      {emailIsValid && <p className="custom_email">Email format is wrong</p>}

                                      <FormControl>
                                        <FormLabel id="demo-radio-buttons-group-label" className='acessTitle'>Access level:</FormLabel>
                                        <RadioGroup
                                          aria-labelledby="demo-radio-buttons-group-label"
                                          className='radioCustom'
                                          name="roleId"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        >
                                          {
                                            getRolesManages && getRolesManages.length > 0 && getRolesManages.map((item) => {
                                              return (
                                                <FormControlLabel value={item.roleId} control={
                                                  <Radio />
                                                } label={item.roleName} />
                                              )
                                            })
                                          }
                                        </RadioGroup>
                                      </FormControl>
                                      <div className="buttonOuter">
                                        <Button
                                          type="submit"
                                          onClick={() => {
                                            handleSubmit();
                                          }}
                                        >
                                          Grant Access
                                        </Button>
                                      </div>
                                    </form>
                                  </GridItem>
                                </GridContainer>
                              )
                            }
                          </Formik>
                        </React.Fragment>
                      }
                      {bizzId &&
                        <div className="tableRow">
                          {/* <h3>Users with access to your Business Page</h3> */}
                          <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Email address</TableCell>
                                  <TableCell align="right">Access level</TableCell>
                                  <TableCell align="right">Page Access</TableCell>
                                  <TableCell align="right">Status</TableCell>
                                  <TableCell align="right">Actions</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {businessPages && businessPages.map((row) => (
                                  <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  >
                                    <TableCell component="th" scope="row">
                                      {row.email}
                                    </TableCell>
                                    <TableCell align="right">{row.roleName}</TableCell>
                                    <TableCell align="right">{row.pageName}</TableCell>
                                    <TableCell align="right" className={row.statusName == 'active' ? 'activeg' : 'pendingAccep'} >{row.statusName}</TableCell>
                                    <TableCell align="right"  ><a className='accessRemove' onClick={() => handleRemovePermission(row)}>Remove Access</a></TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      }
                    </div>
                  </TabPanel>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div >
        <Modal
          open={open222}
          onClose={handleClose222}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className='customModal'
        >
          <Box className='customBox subscribeModalBody' sx={style}>
            <Typography className='subscriteT'>Subscribe</Typography>
            <Typography component={'div'} className='susbcribePText'>Choose your plan duration. Please note that all plans are
              non-refundable.</Typography>
            {
              payment.map((item) => {
                return (
                  <div className='subPlan' key={item.priceCode} id={item.priceCode ? item.priceCode : 'freeSubPlan'} onClick={(e) => handlePaymentData(item.priceCode)}>
                    <div className='monthlyOuter'>
                      <Link to="#" className='monthlyTitle'>{item.period}</Link>
                      <div className='MonthlyRight'>
                        <span className='offSpan'>{item.discount == '' ? 0 + '%' : item.discount} Off</span>
                        <span className='totalSpan'>${item.totalPrice} total</span>
                      </div>
                    </div>
                    <Link to="#" className='monthlyPrice'>${item.discountPrice} <sub>/month</sub></Link>
                    <div className="buton_name selectPlanOuter " style={{ display: 'none' }} id={`show${item.priceCode}`}>
                      <Button className='subBtn'>Selected Plan</Button>
                    </div>
                  </div>
                )
              })
            }
            <Typography component='p' className='susbcribePText'>By subscribing to our service, you are agreeing to our
              <a href='https://oppty.ai/terms-of-use/' target="_blank" className='termsA'>Terms of Use</a>.</Typography>
            <div className="buton_name subMain">
              <Button className='subBtn' onClick={stripePayMethod}>Subscribe</Button>
            </div>
          </Box>
        </Modal>
        <Modal
          open={open11}
          onClose={handleClose11}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className='customBox subscriptionsPopup' sx={style1}>
            <a className='crossBtn' onClick={handleClose11}>
              <i class="fas fa-xmark"></i></a>
            <Typography className='shortlistTitle popupHeading'>
              <h5>We’re sorry to see you go!</h5>
              <p>Your subscription is paid until {moment(subsIc.expiryDate).format('MMMM DD, Y')}. If you would like to proceed with cancelling your subscription, please select “Cancel Subscription” below.</p>
              <div className="iconOuter">
                <i className="fa-solid fa-triangle-exclamation"></i>
                <p>Are you sure? After {moment(subsIc.expiryDate).format('MMMM DD, Y')} you will not have the ability to post job posts for full-time staff or access paid features like the AI Chatbot.</p>
              </div>
            </Typography>
            <Typography component='div' className='dayOuter btnOuter rejectShortlist interviewBtns'>
              <Typography component='div' className='dayRight keepLeft5 subkeep'>
                <Button className='rejectBtn' onClick={handleClose11}>Keep my Subscription</Button>
              </Typography>
              <Typography component='div' className='dayLeft cancelLeft5 cancellbtn'>
                <Button className='applyBtn' onClick={handleCancelSubs}>Cancel Subscription</Button>
              </Typography>
            </Typography>
          </Box>
        </Modal>
        <Modal
          open={open12}
          onClose={handleClose12}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className='customBox subCancelled' sx={style2}>
            <a className='crossBtn' onClick={handleClose12}>
              <i className="fas fa-xmark" ></i></a>
            <Typography className='folderDiv checkCircel'><img src={checkcircel} /></Typography>
            <Typography className='shortlistTitle subCancelled1'>
              <h5><b>Your subscription has been cancelled!</b></h5>
              <p>Please note that the last day of your<br /> subscription is on {moment(subsIc.expiryDate).format('MMMM DD, Y')}.</p>
            </Typography>
          </Box>
        </Modal>
        <Footer />
      </div >
    </div >
  );
};
export default SubscriptionSettingsPage;
