const ownPostStyles = () => ({

  photosList: {
    display: "flex",
    overflow: "hidden",
    overflowX: "scroll",
    marginTop: "10px",

    "&::-webkit-scrollbar": {
      height: "0px",
    },

    '&:hover': {
      cursor: "grab",
    },
  },

  photosListContainer: {
    display: "flex",
    gap: "10px",
  },

  photosListItem: {
    overflow: "hidden",
    borderRadius: "15px",
    width: "128px",
    height: "128px",

    "& > div": {
      overflow: "hidden",
      width: "100%",
      height: "100%",
    },
  },

  photosListItemPhoto: {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    height: "100%",

    "user-drag": "none",
    "-webkit-user-drag": "none",
    "user-select": "none",
    "-moz-user-select": "none",
    "-webkit-user-select": "none",
    "-ms-user-select": "none",
  },
})

export default ownPostStyles;