const aboutGroupStyles = () => ({
  main: {
    marginTop: '15px',
    borderRadius: '15px',
    backgroundColor: '#fff',
    padding: '10px 12px',
    width: '100%',
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '30px',
  },

  title: {
    color: '#171725',
    fontSize: '13px',
    fontWeight: 500,
    fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
  },

  editBtn: {
    padding: '0 6px',
    minWidth: 'fit-content',
    height: '100%',
  },

  divider: {
    marginTop: '10px',
    marginLeft: '-4%',
    width: '108%',
    borderColor: '#F1F1F5',
  },

  aboutGroupText: {
    color: '#44444F',
    fontSize: '14px',
    display: '-webkit-box',
    '-webkit-line-clamp': 5,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },

  aboutGroupTypeTitle: {
    marginTop: '15px',
    color: '#B5B5BE',
    fontSize: '13px',
    fontWeight: 500,
    fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
  },

  aboutGroupTypeText: {
    color: '#44444F',
    fontSize: '13px',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    fontWeight: 500,
    fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
  },
});

export default aboutGroupStyles;
