import React, { Fragment, useEffect, useState, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import emailRegex from 'email-regex';
import Header from "views/Component/Header.js";
import Footer from "views/Component/Footer.js";
import Hidden from "@material-ui/core/Hidden";
import clsx from 'clsx';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import testimonialsPageStyles from 'assets/jss/material-kit-pro-react/views/pageStyle/TestimonialsPage/testimonialsPageStyles';
import { Box, Button, makeStyles, Modal, Typography, IconButton } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import Avatar from "@mui/material/Avatar";
import Skeleton from "@mui/material/Skeleton";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { decryptData, TimeFormat } from 'configurations/configurations.js';
import axios from "axios";
import Cookies from "js-cookie";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import dp from "assets/img/faces/marc.jpg";
import cannotMessage from "assets/img/cannot-message.png";
import jobLoader from "assets/img/job-loder.gif";
import Avtar from "assets/images/avtar.png";
import moment from 'moment';
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/style.css";
import TextareaAutosize from '@mui/material/TextareaAutosize';

import defaultAvatar from "assets/img/corporate_avatar.jpeg";
/*Sourabh sir start*/
import {
  updateJobPostStatus,
  getJobApplications,
  jobSelectReset,
  getJobApplicationsWithStatus,
  createUserEvent,
  createJobOffer,
  zoomAPIIntegrationById,
  getQuestionareOfJobs
} from "configurations/redux/jobManagementSlice.js";
import { getSelectJobPosts, updateApplicantStatus, getCandidateSelectJobPostsOnly } from "configurations/redux/jobManagementSlice.js";
import { getProfiles, addFavouriteProfileProvider } from "configurations/redux/userSlice.js";

import ApplicationTab from 'views/Page/ApplicationManagement/ApplicationTab';
import ShortlistedTab from 'views/Page/ApplicationManagement/ShortlistedTab';
import InterviewTab from 'views/Page/ApplicationManagement/InterviewTab';
import OfferTab from 'views/Page/ApplicationManagement/OfferTab';
import HiredTab from 'views/Page/ApplicationManagement/HiredTab';


import CreatePostModal from "../../components/CreatePostModal/CreatePostModal";

import JobEditModal from "views/Page/CorporateJobPage/JobEditModal.js";
//import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

import { useSnackbar } from "notistack";
/*Sourabh sir end*/
import Profile from "assets/img/profileImg.jpg";
import IconLibrary from "views/Component/IconLibrary.js";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Msg from "assets/img/msg.png";
import {
  activateEditType,
  deactivateEditType,
} from "configurations/redux/editProfileModalSlice";
import {
  createChatroom,
  createChatroomReset,
} from "configurations/redux/messageChatroomSlice.js";
import Tab from '@mui/material/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import TextField from '@mui/material/TextField';

// Loader start
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { Markup } from 'interweave';


import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import {
  countMonthYear
} from "configurations/configurations.js";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";

// Research how to combine multiple style files in makeStyles

const ApplicationManagement = ({ match }) => {
  const [progress, setProgress] = React.useState(10);

  /*   React.useEffect(() => {
     const timer = setInterval(() => {
       setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
     }, 8000);
     return () => {
       clearInterval(timer);
     };
   }, []); */

  // return <CircularProgressWithLabel value={progress} />;


  const [jobLoaderDiv, setJobLoaderDiv] = React.useState(false);
  const jobLoderStatusMethod = jobLoaderStatus => {
    setJobLoaderDiv(jobLoaderStatus);
  };

  const [openPreMove, setOpenPreMove] = React.useState(false);
  const handleOpenPreMove = () => setOpenPreMove(true);
  const handleClosePreMove = () => setOpenPreMove(false);



  const [open11, setOpen11] = React.useState(false);
  const handleOpen11 = () => setOpen11(true);
  const handleClose11 = () => setOpen11(false);
  const [open22, setOpen22] = React.useState(false);
  const handleOpen22 = () => setOpen22(true);
  const handleClose22 = () => setOpen22(false);
  const [open222, setOpen222] = React.useState(false);
  const handleOpen222 = () => setOpen222(true);
  const handleClose222 = () => setOpen222(false);
  const [open33, setOpen33] = React.useState(false);
  const handleOpen33 = () => setOpen33(true);
  const handleClose33 = () => setOpen33(false);


  const handleOpen2222 = () => setOpen2222(true);
  const handleClose2222 = () => setOpen2222(false);
  const [open2222, setOpen2222] = React.useState(false);
  const [email, setEmail] = React.useState([]);
  //console.log('email', email)

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const [valueTab, setValueTab] = React.useState('1');

  const handleChange111 = (event, newValue) => {
    setValueTab(newValue);
  };

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
      borderRadius: 10,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '16.5px 14px',
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 10,
        borderColor: '#3f51b5',
      },
    },
  }));


  const styles = makeStyles(testimonialsPageStyles)();
  const [optionToShow, setOption] = useState('pendingRequests');
  const getVideosHandler = () => {
  }
  const [attachmentType, setAttachmentType] = React.useState(4);
  const userStatus = useSelector(state => state.auth.user);
  //const isMe = decryptData(match.params.acctId) === userStatus.acctId;
  const tabs = ['myTestimonials', 'pendingRequests', 'archivedRequests'];
  // const tabs = ['myTestimonials', 'pendingRequests', 'archived'];

  const [payemtSubscriptionErr, setPayemtSubscriptionErr] = React.useState('');
  const [videoSizeErr, setvideoSizeErr] = React.useState('');
  const bytesToMegaBytes = size => size / (1024 ** 2);
  const history = useHistory();
  const dispatch = useDispatch();
  var isKicked = Cookies.get("isKicked");

  const auth = useSelector((state) => state.auth);
  const currUser = useSelector((state) => state.auth.user);
  const isError = useSelector((state) => state.auth.isError);
  const isVerified = useSelector((state) => state.auth.isVerified);
  const isResend = useSelector((state) => state.auth.isResend);
  const buildProfileFlag = useSelector((state) => state.auth.buildProfileFlag);
  const isViewed = useSelector((state) => state.user.isViewed);
  var user = useSelector((state) => state.user);
  console.log('=======user=======');
  console.log(user);
  const isLoading = useSelector((state) => state.user.isLoading);
  const blockCount = useSelector((state) => state.user.blockCount);
  const isUpdated = useSelector((state) => state.user.isUpdated);
  const questionaires = useSelector(state => state.jobManagement.questionaires);

  const [myself, setMyself] = useState(false);
  const [emailSend, setEmailSend] = useState(false);
  const [userData, setUserData] = useState(null);
  const [connStatus, setConnStatus] = useState(null);

  const [headerTab, setHeaderTab] = useState("myActivity");
  const [isEditProfileOpen, setIsEditProfileOpen] = useState(false);
  const [editOption, setEditOption] = useState("");
  const [isCannotMessageModalOpen, setIsCannotMessageModalOpen] = useState(
    false
  );
  const [createPost, setCreatePost] = React.useState(false);


  /*loaderstart start*/
  const [openLoader, setOpenLoader] = React.useState(false);
  const showLoader = () => {
    setOpenLoader(true);
  };
  const hideLoader = () => {
    setOpenLoader(false);
  };

  const [jobPostId, setJobPostId] = React.useState();
  const stateJobPostId = decryptData(match.params.jobPostId);

  /* Archive Model Pop start */
  const [archiveModelItem, setArchiveModelItem] = React.useState([]);
  const [archiveTabType, setArchiveTabType] = React.useState('application');
  const [archiveModelType, setArchiveModelType] = React.useState('');
  const [archiveModelStatus, setArchiveModelStatus] = React.useState(false);
  const [comfirmationModelStatus, setComfirmationModelStatus] = React.useState(false);
  const [jobOfferModelStatus, setJobOfferModelStatus] = React.useState(false);

  const [jobViewModal, setJobViewModal] = React.useState(false);


  const [savedProfileStatus, SetSavedProfileStatus] = React.useState(0);


  const selectedJobPost = useSelector(
    (state) => state.jobManagement.selectedJobPost
  );

  const applicantListMain = useSelector(
    (state) => state.jobManagement.applications
  );
  /*     const applicantList = useSelector(
      (state) => state.jobManagement.applicantList
    );
      const applications = useSelector(
      (state) => state.jobManagement.applications
    ); */

  const bizId = useSelector((state) => state.business.bizId);

  const [jobRecordId, setJobRecordId] = React.useState();
  const [updateJobModal, setUpdateJobModal] = React.useState(false);
  const [emailIsValid, setEmailIsValid] = React.useState(false);

  /* 	const applicationJobPost = useSelector(
      (state) => state.jobManagement
    );
    console.log('applicationJobPost1');
  	
    console.log(applicationJobPost);
    console.log('corpSelectedJobPost2');
    console.log(applicationJobPost.hired);
     */

  /*short  state sir start*/
  const [meetingType, setMeetingType] = useState(false);


  const [timeSlot1Date, SettimeSlot1Date] = useState('');
  const [timeSlot2Date, SettimeSlot2Date] = useState('');
  const [timeSlot3Date, SettimeSlot3Date] = useState('');

  const [timeSlot1Time, SettimeSlot1Time] = useState('');
  const [timeSlot2Time, SettimeSlot2Time] = useState('');
  const [timeSlot3Time, SettimeSlot3Time] = useState('');

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCfHT53fTL_qLqFyAzW2Az4WoSXzT9LqSs',
    libraries: ["places"],
  });

  React.useEffect(() => {
    let mounted = true;
    let CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    return () => {
      mounted = false;
      source.cancel("cancelling in tabs action switch");
    };

  }, []);


  React.useEffect(() => {
    if (selectedJobPost) {
      const requestProfile = {
        acctId: userStatus.acctId,
        email: userStatus.email,
        accessToken: localStorage.getItem("token"),
        jobPostId: stateJobPostId,
        lastRecordSize: 0,
        requestRecordSize: 9,
        ipAddr: localStorage.getItem("ipAddr"),
      };
      // console.log(requestProfile);
      //	dispatch(getJobApplications(requestProfile));
    }
  }, [selectedJobPost]);

  React.useEffect(() => {
    const requestProfile = {
      acctId: userStatus.acctId,
      email: userStatus.email,
      accessToken: localStorage.getItem("token"),
      jobPostId: stateJobPostId,
      ipAddr: localStorage.getItem("ipAddr"),
      lastRecordSize: 0,
      requestRecordSize: 1,
      bizId: bizId,
    };

    console.log(requestProfile);
    dispatch(getSelectJobPosts(requestProfile));
    getCandidateInfo();


  }, [stateJobPostId]);

  React.useEffect(() => {
    const request = {
      acctId: userStatus.acctId,
      email: userStatus.email,
      accessToken: localStorage.getItem("token"),
      jobPostId: stateJobPostId,
    };
    dispatch(getQuestionareOfJobs(request));
  }, []);


  React.useEffect(() => {
    const requestProfile = {
      acctId: userStatus.acctId,
      email: userStatus.email,
      accessToken: localStorage.getItem("token"),
      jobPostId: stateJobPostId,
      ipAddr: localStorage.getItem("ipAddr"),
      lastRecordSize: 0,
      requestRecordSize: 1,
      bizId: bizId,
    };

    console.log(requestProfile);
    //dispatch(getSelectJobPosts(requestProfile));
    dispatch(getCandidateSelectJobPostsOnly(requestProfile));

    getCandidateInfo();


  }, [stateJobPostId]);

  const zoomAPIIntegrationBy = () => {
    const requestProfile = {
      acctId: userStatus.acctId,
    };
    dispatch(zoomAPIIntegrationById(requestProfile))
  }

  // const zoomAPIIntegration = async function handleZoom() {
  //   await fetch(`https://api.zoom.us/v2/users/neelbhardwaj97@gmail.com/meetings`, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOm51bGwsImlzcyI6Im5qeEpzNUZyUUVTdU5WVTNqZF9menciLCJleHAiOjE2NTcwMjM5NzcsImlhdCI6MTY1NzAxODU3OH0.jAWYuBxqcRb9vSiu5r9KOyuIASXPx1W9CmYI7RA7pCE'
  //     },
  //     body: {
  //       "topic": "The title of your zoom meeting",
  //       "type": 2,
  //       "start_time": moment().format(),
  //       "duration": "45",
  //       "timezone": "Europe/Madrid",
  //       "agenda": "test",
  //       "recurrence": {
  //         "type": 1,
  //         "repeat_interval": 1
  //       },
  //       "settings": {
  //         "host_video": "true",
  //         "participant_video": "true",
  //         "join_before_host": "False",
  //         "mute_upon_entry": "False",
  //         "watermark": "true",
  //         "audio": "voip",
  //         "auto_recording": "cloud"
  //       }
  //     }
  //   })
  //     .then((response) => response.json())
  //     .then((response) => {
  //       console.log(response);
  //     })
  //     .catch((error) => {
  //       console.error('Error:', error);
  //     });
  // }


  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  const closeUpdateJobModal = () => {
    setUpdateJobModal(false);
    setJobRecordId();
  };


  const statusChangeHandler = (item) => {
    console.log(item);
    setCreatePost(false);

  };

  const createPostAction = () => {
    setCreatePost(true);

  };


  function removeHTML(str) {
    var tmp = document.createElement("DIV");
    tmp.innerHTML = str;
    return tmp.textContent || tmp.innerText || "";
  }


  React.useEffect(() => {
    let CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    return () => {
      source.cancel();
    };
  }, []);


  React.useEffect(() => {

    /* 	const requestProfile = {
        acctId: userStatus.acctId,
        email: userStatus.email,
        accessToken: localStorage.getItem("token"),
        jobPostId: stateJobPostId,
        ipAddr: localStorage.getItem("ipAddr"),
        lastRecordSize: 0,
        requestRecordSize: 1,
      }; */
    const requestProfile = {
      acctId: userStatus.acctId,
      email: userStatus.email,
      accessToken: localStorage.getItem("token"),
      jobPostId: stateJobPostId,
      ipAddr: localStorage.getItem("ipAddr"),
      lastRecordSize: 0,
      requestRecordSize: 100,
    };

    dispatch(getJobApplicationsWithStatus(requestProfile));
  }, []);


  /*snackbarCustom sir start*/
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const onClickDismiss = (key) => () => {
    closeSnackbar(key);
  };


  const snackbarCustom = (title) => {
    const action = (key) => (
      <React.Fragment>
        <IconButton onClick={onClickDismiss(key)}>
          <CloseIcon />
        </IconButton>
      </React.Fragment>
    );
    snackBarShow(title, "default", action);
  };
  const snackBarShow = (message, variant, action) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, {
      variant: variant,
      action,
    });
  };

  /*snackbarCustom sir end*/

  /* Message fumctionality start */
  const getMessageNavigate = async (item) => {
    var chatroomList = [];
    chatroomList.push({ chatroomListId: -1, acctId: userStatus.acctId });
    chatroomList.push({ chatroomListId: -1, acctId: item });
    const messageApplicant = {
      acctId: userStatus.acctId,
      accessToken: localStorage.getItem("token"),
      email: userStatus.email,
      chatroomTitle: "business_" + item + "_" + bizId,
      chatroomList: chatroomList,
      receiverId: item,
      bizId: bizId,
      receiverBizId: 0,
      typeId: 2,
    };
    console.log(messageApplicant);
    dispatch(createChatroom(messageApplicant));
  };

  const chatroomId = useSelector((state) => state.messageChatroom.chatroomId);
  const isCreated = useSelector((state) => state.messageChatroom.isCreated);

  React.useEffect(() => {
    console.log(chatroomId);
    if (isCreated && chatroomId > 0) {
      // window.open("/message-page/C" + chatroomId + "/", "_blank");
      window.open(`/message-page/0/C${chatroomId}/`, "_blank");
      // history.push("/message-page/C" + chatroomId + "/");
    }
    dispatch(createChatroomReset());
  }, [isCreated]);
  /** message functionality end  */



  const getCandidateInfo = () => {
    /* const requestProfile = {
    acctId: userStatus.acctId,
    email: userStatus.email,
    accessToken: localStorage.getItem("token"),
    jobPostId: stateJobPostId,
    ipAddr: localStorage.getItem("ipAddr"),
    lastRecordSize: 0,
    requestRecordSize: 1,
  }; */
    const requestProfile = {
      acctId: userStatus.acctId,
      email: userStatus.email,
      accessToken: localStorage.getItem("token"),
      jobPostId: stateJobPostId,
      ipAddr: localStorage.getItem("ipAddr"),
      lastRecordSize: 0,
      requestRecordSize: 100,
    };

    dispatch(getJobApplicationsWithStatus(requestProfile));
    setJobLoaderDiv(false);
    hideLoader();


  }

  const return_score_tab = (score) => {


    return (<SemiCircleProgressBar
      percentage={score}
      stroke={score < 33 ? '#D43837' : score < 50 ? '#FD6A0C' : '#02B732'}
      showPercentValue={true}
      diameter={75}
      strokeWidth={7}
    />

    );

    /*
        if (score < 50) {
          return (<div className="progress progressRed">
            <div className="barOverflow">
              <div className="bar"></div>
            </div>
            <span>{score}</span>
          </div>);
        }
        else {
          return (<div className="progress">
            <div className="barOverflow">
              <div className="bar"></div>
            </div>
            <span>{score}</span>
          </div>);
        }
        */



  }



  const displayJobPostHeading =
    selectedJobPost &&
    selectedJobPost.map((item) => {
      // console.log("item from job view page", item);
      return (<div><Typography className="appTitle">{item.title}</Typography>
        <ul className="appUl">
          <li>
            <i className="fa-solid fa-suitcase"></i> {item.industryName}
          </li>
          <li>
            <i className="fa-solid fa-dollar"></i>  {item.currencyCode} {item.salaryMin} - {item.salaryMax}
          </li>


          {item.locationName ? (<li>
            <i className="fa-solid fa-location-dot"></i> {item.locationName}
          </li>
          ) : ('')}

          <li>
            <i className="fa-regular fa-clock"></i> {item.jobType} / {item.jobPostType}
          </li>
        </ul>
        <p className="statusP"><b>Status</b> <span>Active</span></p>
      </div>)
    })

  const displayJobPostDetail =
    selectedJobPost &&
    selectedJobPost.map((item) => {
      //console.log("item from job view page", item);
      return (<div> <Typography className='perviewEdit'>
        This is a preview of what your job post looks like to applicants
        <Button
          onClick={(event) => {
            event.preventDefault();
            setJobRecordId(item.jobPostId);
            setUpdateJobModal(true);
          }}
          color="opptyWrap"
          className='editBtnn'
        >
          <IconLibrary.Pen /> EDIT
        </Button>
      </Typography>
        <div className='jobDetailDiv'>
          <GridContainer>
            <GridItem xs={12} sm={12} md={8} >
              <Typography className='jobDetailLeft'>
                <Typography className='jobDetailLeftUpper'>
                  <Typography className='pteTitle'>{item.companyName}</Typography>
                  <Typography className='researchTitle'>{item.title}</Typography>
                  <Typography className='dolloarPrice'>{item.currencyCode} {item.salaryMin ? item.salaryMin : '0'} -  {item.salaryMax ? item.salaryMax : '0'}</Typography>
                  <ul className='breadCrumb'>
                    {item.locationName ? (<><li>{item.locationName}</li> <li>/</li></>) : ('')}


                    <li>Posted {TimeFormat(item.createDate)}</li>
                  </ul>
                </Typography>
                <Typography className='jobDetailLeftBottom'>
                  <Typography className="jobDescTitle">Job Description</Typography>

                  <Markup content={item.responsibility} />
                </Typography>
                <Typography className='jobDetailLeftBottom'>
                  <Typography className="jobDescTitle">Job Requirements</Typography>

                  <Markup content={item.requirement} />

                </Typography>
              </Typography>
            </GridItem>
            <GridItem xs={12} sm={12} md={4} >
              <Typography className='joddetailRight'>

                <Typography className='applyNowDiv'>
                  <Button className='applyNowBtn'>Apply Now</Button>
                  <Button className='saveBtn'>Save</Button>
                  <Typography className='postLink'>Copy job post link <Link to="#"><i className="fa-solid fa-link"></i></Link></Typography>
                </Typography>

                <Typography className='applyNowDiv'>
                  <Typography className='jobType'>Job Type</Typography>
                  <Typography className='fullTime'>{item.jobType}</Typography>
                  <Typography className='jobType'>Industry</Typography>
                  <Typography className='fullTime'>{item.industryName}</Typography>
                  <Typography className='jobType'>Experience</Typography>
                  <Typography className='fullTime'>{item.experience}</Typography>
                  <Typography className='jobType'>Applicants</Typography>
                  <Typography className='fullTime'>{item.applCount} applied</Typography>
                  <Typography className='jobType'>Min. Qualifications</Typography>
                  <Typography className='fullTime'>{item.eduLvlName}</Typography>
                  <Typography className='jobType'>Role</Typography>
                  <Typography className='fullTime'>{item.role}</Typography>
                </Typography>
              </Typography>
            </GridItem>
          </GridContainer>
        </div></div>)
    })


  const IsolatedSaveMenu = props => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    return (<Typography className='applicationTopRight'>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <i className="fas fa-ellipsis"></i>
      </Button>
      <Menu

        id={'basic-menu'}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {(props.dataitem.savedProfile === 0) ? (
          <MenuItem className='saveCand' onClick={() => handleSaveCandidate(props.dataitem)}><i className="fa-regular fa-bookmark" ></i>{savedProfileStatus == 0 ? 'Save Candidate' : 'Saved'} </MenuItem>
        ) : (
          <MenuItem className='saveCand candiate_saved' ><i className="fa-regular fa-bookmark" ></i>Saved</MenuItem>
        )

        }
      </Menu>
    </Typography>)

  }


  const applicationTabData = () => {
    return (
      <div className='appTabContentInner'>
        <Typography className='appliTitle'>Applications</Typography>
        <Button className='inviteBtn'>Invite Candidates to Apply</Button>
        {applicantListMain && applicantListMain.applications[0].candidateCount > 0 && applicantListMain.applications.map((item, index) => {

          return (
            <Typography key={'application-' + index} className='applicationMain'>
              <Typography className='applicationTop'>

                <Link onClick={() => handleAcceptModelPopup(item, 'archive', 'application')} to="#" >
                  <span className='appSpan'>
                    <img src={item.profilePic ? item.profilePic : defaultAvatar} />
                  </span>
                  <Typography className='appCen'>
                    {return_score_tab(item.score)}

                  </Typography>
                </Link>
                <IsolatedSaveMenu dataindex={index} dataitem={item} />

              </Typography>
              <Link onClick={() => handleAcceptModelPopup(item, 'archive', 'application')} to="#" >
                <Typography className='appNamee'>{item.firstName}</Typography>
                <p className='desc'>{item.headline}</p>
                <span className="TimeeSpan">{TimeFormat(item.applicationDate)}</span>
              </Link>
              <Typography component='div' className='dayOuter btnOuter rejectShortlist'>
                <Typography component='div' className='dayRight'>
                  <Button onClick={() => handleAcceptModelPopup(item, 'reject', 'application')} className='rejectBtn1'>Reject</Button>
                </Typography>
                <Typography component='div' className='dayLeft'>
                  <Button className='applyBtn' onClick={() => handleAcceptModelPopup(item, 'accept', 'application')} >Shortlist </Button>                </Typography>
              </Typography>
            </Typography>

          )
        })

        }


        {applicantListMain && applicantListMain.applications[0].candidateCount == 0 ? (<GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className='NoEducationList'>No record found</div>
        </GridItem>) : ''}





      </div>

    );
  }


  const shortlistTabData = () => {
    return (

      <div className='appTabContentInner shortlisted'>
        <Typography className='appliTitle'>Shortlist</Typography>

        {applicantListMain && applicantListMain.shortlisted[0].candidateCount > 0 && applicantListMain.shortlisted.map((item, index) => {

          return (
            <Typography key={'shortlist-' + index} className='applicationMain'>

              <Typography className='applicationTop'>

                <Link onClick={() => handleAcceptModelPopup(item, 'archive', 'shortlist')} to="#" >
                  <span className='appSpan'>
                    <img src={item.profilePic ? item.profilePic : defaultAvatar} />
                  </span>
                  <Typography className='appCen'>
                    {return_score_tab(item.score)}
                  </Typography>
                </Link>
                <IsolatedSaveMenu dataindex={index} dataitem={item} />

              </Typography>
              <Link onClick={() => handleAcceptModelPopup(item, 'archive', 'shortlist')} to="#" >
                <Typography className='appNamee'>{item.firstName}</Typography>
                <p className='desc'>{item.headline}</p>
                <span className="TimeeSpan">{TimeFormat(item.applicationDate)}</span>
              </Link>
              <Typography component='div' className='dayOuter btnOuter rejectShortlist'>
                <Typography component='div' className='dayRight'>
                  <Button onClick={() => handleAcceptModelPopup(item, 'reject', 'shortlist')} className='rejectBtn1'>Reject</Button>
                </Typography>
                <Typography component='div' className='dayLeft'>

                  <Button className='applyBtn' onClick={() => handleAcceptModelPopup(item, 'interview', 'shortlist')} >Interview</Button>

                </Typography>
              </Typography>
            </Typography>

          )
        })

        }
        {applicantListMain && applicantListMain.shortlisted[0].candidateCount == 0 ? (<GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className='NoEducationList'>No record found</div>
        </GridItem>) : ''}




      </div >

    );
  }

  const interviewTabData = () => {
    return (
      <div className='appTabContentInner interview'>
        <Typography className='appliTitle'>Interview</Typography>

        {applicantListMain && applicantListMain.interview[0].candidateCount > 0 && applicantListMain.interview.map((item, index) => {

          return (
            <Typography key={'interview-' + index} className='applicationMain'>

              <Typography className='applicationTop'>
                <Link onClick={() => handleAcceptModelPopup(item, 'archive', 'interview')} to="#" >
                  <span className='appSpan'>
                    <img src={item.profilePic ? item.profilePic : defaultAvatar} />
                  </span>
                  <Typography className='appCen'>
                    {return_score_tab(item.score)}
                  </Typography>
                </Link>


                <IsolatedSaveMenu dataindex={index} dataitem={item} />


              </Typography>

              <Link onClick={() => handleAcceptModelPopup(item, 'archive', 'interview')} to="#" >
                <Typography className='appNamee'>{item.firstName}</Typography>
                <p className='desc'>{item.headline}</p>
                <span className="TimeeSpan">{TimeFormat(item.applicationDate)}</span>

              </Link>
              <Typography component='div' className='dayOuter btnOuter rejectShortlist'>
                <Typography component='div' className='dayRight'>
                  <Button onClick={() => handleAcceptModelPopup(item, 'reject', 'interview')} className='rejectBtn1'>Reject</Button>
                </Typography>
                <Typography component='div' className='dayLeft'>
                  <Button className='applyBtn' onClick={() => handleAcceptModelPopup(item, 'offer', 'interview')} >Offer</Button>
                </Typography>
              </Typography>
            </Typography>
          )
        })

        }
        {applicantListMain && applicantListMain.interview[0].candidateCount == 0 ? (<GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className='NoEducationList'>No record found</div>
        </GridItem>) : ''}



      </div >

    );
  }


  const offerTabData = () => {
    return (


      <div className='appTabContentInner offer'>
        <Typography className='appliTitle'>Offer</Typography>

        {applicantListMain && applicantListMain.offer[0].candidateCount > 0 && applicantListMain.offer.map((item, index) => {

          return (
            <Typography key={'offer-' + index} className='applicationMain'>

              <Typography className='applicationTop'>
                <Link onClick={() => handleAcceptModelPopup(item, 'archive', 'offer')} to="#" >
                  <span className='appSpan'>
                    <img src={item.profilePic ? item.profilePic : defaultAvatar} />
                  </span>
                  <Typography className='appCen'>
                    {return_score_tab(item.score)}
                  </Typography>
                </Link>


                <IsolatedSaveMenu dataindex={index} dataitem={item} />


              </Typography>

              <Link onClick={() => handleAcceptModelPopup(item, 'archive', 'offer')} to="#" >
                <Typography className='appNamee'>{item.firstName}</Typography>
                <p className='desc'>{item.headline}</p>
                <span className="TimeeSpan">{TimeFormat(item.applicationDate)}</span>

              </Link>
              <Typography component='div' className='dayOuter btnOuter rejectShortlist'>
                <Typography component='div' className='dayRight'>
                  <Button onClick={() => handleAcceptModelPopup(item, 'reject', 'offer')} className='rejectBtn1'>Reject</Button>
                </Typography>
                <Typography component='div' className='dayLeft'>

                  <Button className='applyBtn' onClick={() => handleAcceptModelPopup(item, 'accept', 'offer')} >Hired</Button>

                </Typography>
              </Typography>
            </Typography>

          )
        })

        }

        {applicantListMain && applicantListMain.offer[0].candidateCount == 0 ? (<GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className='NoEducationList'>No record found</div>
        </GridItem>) : ''}




      </div >

    );
  }


  const hiredTabData = () => {
    return (


      <div className='appTabContentInner hired'>
        <Typography className='appliTitle'>Hired</Typography>

        {applicantListMain && applicantListMain.hired[0].candidateCount > 0 && applicantListMain.hired.map((item, index) => {

          return (
            <Typography key={'hired-' + index} className='applicationMain'>

              <Typography className='applicationTop'>
                <Link onClick={() => handleAcceptModelPopup(item, 'archive', 'hired')} to="#" >
                  <span className='appSpan'>
                    <img src={item.profilePic ? item.profilePic : defaultAvatar} />
                  </span>
                  <Typography className='appCen'>
                    {return_score_tab(item.score)}
                  </Typography>
                </Link>


                <IsolatedSaveMenu dataindex={index} dataitem={item} />


              </Typography>

              <Link onClick={() => handleAcceptModelPopup(item, 'archive', 'hired')} to="#" >
                <Typography className='appNamee'>{item.firstName}</Typography>
                <p className='desc'>{item.headline}</p>
                <span className="TimeeSpan">{TimeFormat(item.applicationDate)}</span>

              </Link>
              <Typography component='div' className='dayOuter btnOuter rejectShortlist'>
                <Typography component='div' className='dayRight'>
                  <Button onClick={() => handleAcceptModelPopup(item, 'reject', 'hired')} className='rejectBtn1'>Cancel Hire</Button>
                </Typography>
                <Typography component='div' className='dayLeft'>

                  <Button className='applyBtn' onClick={() => handleAcceptModelPopup(item, 'accept', 'hired')} >Archive</Button>

                </Typography>
              </Typography>
            </Typography>

          )
        })

        }

        {applicantListMain && applicantListMain.hired[0].candidateCount == 0 ? (<GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className='NoEducationList'>No record found</div>
        </GridItem>) : ''}



      </div >

    );
  }

  const archivedTabData = () => {
    return (
      <Typography className='applicationMain archivedTab'>
        {applicantListMain && applicantListMain.archivedCount > 0 && applicantListMain.archived.map((item, index) => {
          return (
            <Link onClick={() => handleAcceptModelPopup(item, 'archive', 'achived')} to="#" >

              <Typography className='applicationMain'>
                <Typography className='applicationTop'>
                  <span className='appSpan'>
                    <img src={item.profilePic ? item.profilePic : defaultAvatar} />


                  </span>
                  <Typography className='appCen'>
                    {return_score_tab(item.score)}


                  </Typography>
                </Typography>
                <Typography className='appNamee'>{item.firstName}</Typography>
                <p className='desc'>{item.headline}</p>
                <span className="TimeeSpan">{TimeFormat(item.applicationDate)}</span>
                <Link to="#" className="keepView">{item.statusType}</Link>
              </Typography>
            </Link>

          )
        })

        }

        {
          applicantListMain && applicantListMain.archived[0] && applicantListMain.archived[0].archivedCount === 0 ? (
            <>
              <Typography className='perviewEdit'>
                Not record found.
              </Typography></>) : ('')
        }

      </Typography>
    );
  }


  const handleSaveCandidate = (item) => {

    if (item.savedProfile === 0) {
      const requestSaveProfile = {
        email: userStatus.email,
        acctId: userStatus.acctId,
        profileId: item.acctId,
        accessToken: localStorage.getItem("token"),
        bizId: bizId,
      };
      //console.log(requestSaveProfile);
      dispatch(addFavouriteProfileProvider(requestSaveProfile));
      SetSavedProfileStatus(1);
      snackbarCustom("Saved to favorite list.");
    }
    else {
      snackbarCustom("Already saved to favorite list.");
    }
  }

  const handleAcceptModelPopup = (item, type, tab) => {
    // alert(type);
    const requestAcrchiveProfile = {
      acctId: userStatus.acctId,
      email: userStatus.email,
      userId: item.acctId,
      jobPostId: item.applId,
      accessToken: localStorage.getItem("token"),
    };
    dispatch(getProfiles(requestAcrchiveProfile));

    setArchiveModelItem(item);
    setArchiveModelType(type);
    setArchiveTabType(tab);
    if (type == 'accept') {
      handleOpen11();
    }
    if (type == 'archive') {
      // alert(type);
      handleOpen33();
      SetSavedProfileStatus(0);

      if (item.savedProfile > 0) {
        SetSavedProfileStatus(item.savedProfile);
      }
    }
    if (type == 'interview' || type == 'interviewNew') {
      handleOpen22();
    }
    if (type == 'reject') {
      handleOpenPreMove();
    }
    if (type == 'offer') {
      //alert(type);
      handleOpen222();
    }


  }

  const updateJobStatus = async (stateJobPostId, applicantId) => {

    //console.log('------stateJobPostId---------' + stateJobPostId + '----applicantId--' + applicantId);
    /* 	const request = {
      acctId: userStatus.acctId,
      email: userStatus.email,
      accessToken: localStorage.getItem("token"),
  
      applicantId:applicantId,
      applId:stateJobPostId,
      applStatusId: 3
  
    }; */
    const request = {
      acctId: userStatus.acctId,
      email: userStatus.email,
      accessToken: localStorage.getItem("token"),
      //jobPostId: stateJobPostId,

      applicantId: applicantId,
      applId: stateJobPostId,
      applStatusId: 3

    };

    dispatch(updateApplicantStatus(request));
    handleClose11();
    handleClosePreMove();
    match.handleJobLoderStatusMethod(true);
    setTimeout(() => { getCandidateInfo() }, 3000);

  };


  const updateJobStatusNew = async (selectedType) => {

    let update_status = 1;
    if (archiveTabType == 'application' && selectedType == 'accept') {
      update_status = 3;
    }
    if (archiveTabType == 'application' && selectedType == 'reject') {
      update_status = 5;
    }
    if (archiveTabType == 'shortlist' && selectedType == 'accept') {
      update_status = 9;
      handleClose22();
    }
    if (archiveTabType == 'shortlist' && selectedType == 'reject') {
      update_status = 5; //1
      handleClose22();
    }

    if (archiveTabType == 'interview' && selectedType == 'accept') {
      update_status = 15; //12; //5
      // handleClose2222();
      handleOpen2222();
    }
    if (archiveTabType == 'interview' && selectedType == 'reject') {
      update_status = 5; //3
      handleClose22();
    }


    if (archiveTabType == 'interviewNew' && selectedType == 'accept') {
      update_status = 15; //12; //5
      // handleClose2222();
      handleOpen2222();
    }
    if (archiveTabType == 'interviewNew' && selectedType == 'reject') {
      update_status = 5; //3
      handleClose22();
    }


    if (archiveTabType == 'offer' && selectedType == 'accept') {
      update_status = 7; //5
      handleClose22();
    }
    if (archiveTabType == 'offer' && selectedType == 'reject') {
      update_status = 5; //9
      handleClose22();
    }

    if (archiveTabType == 'hired' && selectedType == 'reject') {
      update_status = 5; //15
      handleClose22();
    }

    if (archiveTabType == 'hired' && selectedType == 'accept') {
      update_status = 18; //15
      handleClose22();
    }
    /* 	if (archiveTabType == 'interview' && selectedType == 'accept') {
      {
        update_status = 15; //5
         handleClose2222();
      } */

    showLoader();
    const request = {
      acctId: userStatus.acctId,
      email: userStatus.email,
      accessToken: localStorage.getItem("token"),
      //jobPostId: stateJobPostId,

      applicantId: archiveModelItem.acctId,
      applId: archiveModelItem.applId,
      applStatusId: update_status

    };
    // console.log('updateRequest');
    //  console.log(request);
    dispatch(updateApplicantStatus(request));
    handleClose11();
    handleClose33();
    handleClosePreMove();

    //setJobLoaderDiv(true);
    setTimeout(() => { getCandidateInfo() }, 3000);

  }


  /* Archive Model Pop start */
  const handleArchiveModelPopup = (item, type, tab) => {
    //  console.log('handleArchiveModelPopup');
    //  console.log(type);
    const requestAcrchiveProfile = {
      acctId: userStatus.acctId,
      email: userStatus.email,
      userId: item.acctId,
      accessToken: localStorage.getItem("token"),
    };


    dispatch(getProfiles(requestAcrchiveProfile));
    handleOpen33();
    setArchiveModelItem(item);
    setArchiveModelType(type);
    setArchiveTabType(tab);
    setJobViewModal(true);

    setComfirmationModelStatus(true);
  }

  /* END RAHUL CODE */

  /*Offer Validation functions start*/

  const [jobPosition, setJobPosition] = useState(selectedJobPost && selectedJobPost[0].title ? selectedJobPost[0].title : '');
  const [servicePeriod, setServicePeriod] = useState(selectedJobPost && selectedJobPost[0].jobType == 'Full-time' ? 'N/A' : '');
  const [offerSalary, setOfferSalary] = useState(selectedJobPost && selectedJobPost[0].salaryMax ? selectedJobPost[0].salaryMax : '');
  const [jobType, setJobType] = useState(selectedJobPost && selectedJobPost[0].jobType ? selectedJobPost[0].jobType : '');

  function handleJobPositionInput(event) {
    setJobPosition(event.target.value)
  };

  function handleServicePeriodInput(event) {
    setServicePeriod(event.target.value)
  };

  function handleOfferSalaryInput(event) {
    setOfferSalary(event.target.value)
  };

  function handleJobTypeInput(event) {
    setJobType(event.target.value)
  };

  /*Offer Validation functions end*/


  /* Short List Validation functions start*/
  function handleInterviewTyoeInput(event) {
    setMeetingType(event.target.value)

  };

  function handleTimeSlot1DateInput(event) {

    SettimeSlot1Date(event.target.value)
  };

  function handleTimeSlot2DateInput(event) {

    SettimeSlot2Date(event.target.value)
  };

  function handleTimeSlot3DateInput(event) {
    SettimeSlot3Date(event.target.value)
  };

  function handleTimeSlot1TimeInput(event) {

    SettimeSlot1Time(event.target.value)
  };

  function handleTimeSlot2TimeInput(event) {

    SettimeSlot2Time(event.target.value)
  };

  function handleTimeSlot3TimeInput(event) {
    SettimeSlot3Time(event.target.value)
  };



  const moveToOffer = async (stateJobPostId, applicantId) => {
    if (jobPosition == '') {
      snackbarCustom("Enter Job Position");
    }
    else if (servicePeriod == '') {
      snackbarCustom("Enter Service Period.");
    }
    else if (offerSalary == '') {
      snackbarCustom("Enter Offer Salary.");
    }
    else if (jobType == '') {
      snackbarCustom("Enter Correct Job Type.");
    }
    else {
      handleOpen2222();

      const requestJobOffer = {
        acctId: userStatus.acctId,
        email: userStatus.email,
        accessToken: localStorage.getItem("token"),
        applId: stateJobPostId,
        jobPosition: jobPosition,
        jobPostTypeId: 1,
        servicePeriod: servicePeriod,
        offerSalary: offerSalary,
        remark: '',
      }
      dispatch(createJobOffer(requestJobOffer));
      updateJobStatusNew('accept');
      handleClose22();
      handleClose222();
      // handleClose2222();
    }
  }

  const moveToInterview = async (stateJobPostId, applicantId) => {

    var slot_one_time = moment(timeSlot1Date + ' ' + timeSlot1Time);
    var slot_two_time = moment(timeSlot2Date + ' ' + timeSlot2Time);
    var slot_three_time = moment(timeSlot3Date + ' ' + timeSlot3Time);
    const date1 = new Date(timeSlot1Date + ' ' + timeSlot1Time);
    const date2 = new Date(timeSlot2Date + ' ' + timeSlot2Time);
    const date3 = new Date(timeSlot3Date + ' ' + timeSlot3Time);
    if (meetingType == '') {
      snackbarCustom("Select meeting type");
    }
    else if (timeSlot1Date == '') {
      snackbarCustom("Select first time-slot date.");
    }
    else if (timeSlot1Time == '') {
      snackbarCustom("Select first time-slot time.");
    }
    else if (timeSlot2Date == '') {
      snackbarCustom("Select second time-slot date.");
    }
    else if (timeSlot2Time == '') {
      snackbarCustom("Select second time-slot time.");
    }
    //  else if (slot_one_time.isBefore(slot_two_time) == false) {
    else if (date1.getTime() >= date2.getTime()) {
      snackbarCustom("Second time-slot cannot be the same is First or Third.");
    }
    else if (timeSlot3Date == '') {
      snackbarCustom("Select third time-slot date.");
    }
    else if (timeSlot3Time == '') {
      snackbarCustom("Select third time-slot time.");
    }
    // else if (slot_two_time.isBefore(slot_three_time) == false) {
    else if (date2.getTime() >= date3.getTime()) {

      snackbarCustom("Third time-slot cannot be the same is First or Second.");
    }
    else {
      /* 	const requestProfile = {
    acctId: userStatus.acctId,
    email: userStatus.email,
    accessToken: localStorage.getItem("token"),
    bizId:bizId,
    title: "Interview Meeting",
    description: "",
    location:"",
    typeId:meetingType,
    statusId:1,
    recordTypeId:1,
    recordId:stateJobPostId,
    	
    	
    	
    jobPostId: stateJobPostId,
    ipAddr: localStorage.getItem("ipAddr"),
    lastRecordSize: 0,
    requestRecordSize: 1,
    }; */






      //snackbarCustom("Code is working");
      let timer_arra = []
      let guest_arra = []

      let temp = {};;
      temp['startDate'] = timeSlot1Date + ' ' + timeSlot1Time;
      temp['endDate'] = moment(timeSlot1Date + ' ' + timeSlot1Time).add(1, 'hours').format('YYYY-MM-DD HH:mm')
      timer_arra.push(temp);

      temp = {};;
      temp['startDate'] = timeSlot2Date + ' ' + timeSlot2Time;
      temp['endDate'] = moment(timeSlot2Date + ' ' + timeSlot2Time).add(1, 'hours').format('YYYY-MM-DD HH:mm')
      timer_arra.push(temp);

      temp = {};;
      temp['startDate'] = timeSlot3Date + ' ' + timeSlot3Time;
      temp['endDate'] = moment(timeSlot3Date + ' ' + timeSlot3Time).add(1, 'hours').format('YYYY-MM-DD HH:mm')
      timer_arra.push(temp);

      //timer_arra.push(timeSlot2Date+' '+timeSlot2Time);
      //timer_arra.push(timeSlot3Date+' '+timeSlot3Time);
      //timer_arra =  JSON.stringify(timer_arra);

      const requestProfile = {
        acctId: userStatus.acctId,
        email: userStatus.email,
        accessToken: localStorage.getItem("token"),
        bizId: bizId,
        title: "Interview Meeting",
        description: "",
        eventDate: "2022-06-24",
        location: "",
        typeId: meetingType,
        statusId: 1,
        recordTypeId: 1,
        recordId: stateJobPostId,
        schedules: timer_arra,
        guests: email
      };


      dispatch(createUserEvent(requestProfile));

      updateJobStatusNew('accept');
      //updateJobStatus(stateJobPostId,applicantId,9);
      setEmail([])
      handleClose22();


    }

  }

  /** Map start  */

  function Map() {
    const center = useMemo(() => ({ lat: 1.3722, lng: 103.8732 }), []);
    const [selected, setSelected] = React.useState('');
    const [value, setValue] = React.useState('');
    return (
      <>
        <div className="places-container">
          <PlacesAutocomplete setSelected={setSelected} />
        </div>

        <GoogleMap
          zoom={1}
          center={center}
          mapContainerClassName="map-container"
        >
          {selected && <Marker position={selected} />}
        </GoogleMap>
      </>
    );
  }

  const PlacesAutocomplete = ({ setSelected }) => {
    const {
      ready,
      value,
      setValue,
      suggestions: { status, data },
      clearSuggestions,
    } = usePlacesAutocomplete();

    const handleSelect = async (address) => {
      console.log('address');
      console.log(address);
      setValue(address, false);
      clearSuggestions();

      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      setSelected({ lat, lng });
    };

    const handleClick = async (address) => {
      console.log('address1');
      console.log(address);
      setValue(address, false);
      clearSuggestions();

      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      setSelected({ lat, lng });
    };




    return (
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          value={value}
          onChange={(e) => setValue(e.target.value)}
          disabled={!ready}
          className="combobox-input"
          placeholder="Search an address"
        />
        <ComboboxPopover>
          <ComboboxList>
            {status === "OK" &&
              data.map(({ place_id, description }) => (
                <ComboboxOption key={place_id} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    );
  };
  /** Map end */
  /*Validation functions end*/



  return (
    <>
      <div>
        <Header />
        <div className='applicationBody'>
          <div>
            <div className='container_custom'>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} >
                  <Typography component='div' className='app_man_div'>
                    <Typography component='div' className='app_man_divleft'>
                      <Link to={`/corporate-job-management-page`} className="arrowBack"><i className="fa-solid fa-angle-left"></i></Link>
                    </Typography>
                    <Typography component='div' className='app_man_divcenter'>
                      {displayJobPostHeading}



                    </Typography>
                    <Typography component='div' className='app_man_divright'>
                      <Button onClick={() => createPostAction()} className="sharePrmoteBtn">Share and Promote <i className="fas fa-share-nodes"></i></Button>
                    </Typography>
                  </Typography>
                </GridItem>
              </GridContainer>
              {createPost ? (
                <CreatePostModal

                  isOpen={createPost}
                  setPostsList={null}
                  user={userStatus}
                  oriVisibleOptions={null}
                  oriSocialPostTypeOptions={null}
                  oriCommentabilityOptions={null}
                  statusChangeHandler={statusChangeHandler}
                  handleCreatePostClick={null}
                  question={null}
                  options={null}
                  pollTiming={null}
                  removePoll={null}
                  savePostData={null}
                  selectPhotos={null}
                  selectVideos={null}
                  uploadedPhotos={[]}
                  uploadedVideos={[]}
                  removeAnUploadedPhoto={null}
                  removeAnUploadedVideo={null}
                  onPhotosListItemClick={null}
                  onVideosListItemClick={null}
                  onPhotosListItemDragStart={null}
                  onPhotosListItemDrop={null}
                  onVideosListItemDragStart={null}
                  onVideosListItemDrop={null}
                  isLoading={null}
                  setError={null}
                  editPost={null}
                  sharePost={null}
                  shareJob={selectedJobPost}
                  groupId={0}
                  postStatus={1}
                />
              ) : null}

              {updateJobModal && (
                <JobEditModal
                  open={updateJobModal}
                  onClose={closeUpdateJobModal}
                  jobPostId={jobRecordId}
                  jobPostRecord={
                    selectedJobPost
                      .filter((item) => {
                        return item.jobPostId === jobRecordId;
                      })
                      .map((item) => item)[0]
                  }
                />
              )}

              <GridContainer>
                <GridItem xs={12} sm={12} md={12} >
                  <Typography className="TabCustom">
                    <div className={styles.testimonialsContentTabsContainer}>
                      <div
                        className={clsx({
                          [styles.tab]: true,
                          [styles.tabActive]: optionToShow == 'myTestimonials',
                        })}
                        onClick={() => {
                          setOption('myTestimonials');
                        }}
                      >
                        <p onClick={getCandidateInfo} className={styles.tabTitle}>Candidates ({applicantListMain && applicantListMain.candidateCount ? applicantListMain.candidateCount : 0})</p>
                        <div
                          className={clsx({
                            [styles.tabActiveBorder]: optionToShow == 'myTestimonials',
                          })}
                        ></div>
                      </div>

                      <div
                        className={clsx({
                          [styles.tab]: true,
                          [styles.tabActive]: optionToShow == 'pendingRequests',
                        })}
                        onClick={() => {
                          setOption('pendingRequests');
                        }}
                      >
                        <p className={styles.tabTitle}>Job Details</p>
                        <div
                          className={clsx({
                            [styles.tabActiveBorder]: optionToShow == 'pendingRequests',
                          })}
                        ></div>
                      </div>


                      <div
                        className={clsx({
                          [styles.tab]: true,
                          [styles.tabActive]: optionToShow == 'archivedRequests',
                        })}
                        onClick={() => {
                          setOption('archivedRequests');
                        }}
                      >
                        <p className={styles.tabTitle}>Archived ({applicantListMain && applicantListMain.archivedCount ? applicantListMain.archivedCount : 0})</p>
                        <div
                          className={clsx({
                            [styles.tabActiveBorder]: optionToShow == 'archivedRequests',
                          })}
                        ></div>
                      </div>


                    </div>
                    <div className={styles.testimonialsContent}>
                      {optionToShow === tabs[0] &&
                        <div className='tab_content_custom applicationTabContent'>
                          {/*      ---  ApplicationTab ------       */}
                          {applicationTabData()}

                          {/*      ---  ShortlistedTab ------       */}

                          {shortlistTabData()}

                          {/*      ---  InterviewTab ------       */}
                          {interviewTabData()}

                          {/*      ---  OfferTab ------       */}
                          {offerTabData()}


                          {/*  ---  HiredTab ------       */}
                          {hiredTabData()}






                          <Modal
                            open={open11}
                            onClose={handleClose11}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box className='customBox' sx={style}>
                              <Link onClick={handleClose11} to="#" className='crossBtn'><i className="fas fa-xmark"></i></Link>
                              <Typography className='folderDiv'><i className="fas fa-folder"></i></Typography>


                              {(() => {
                                switch (archiveTabType) {
                                  case "application":
                                    return (
                                      <Typography className='shortlistTitle'>
                                        Move <b> {user.profileRecord.firstName ? user.profileRecord.firstName : ''} </b> to
                                        <span> Shortlist?</span>
                                      </Typography>);
                                  case "shortlist":
                                    return (
                                      <Typography className='shortlistTitle'>
                                        Move <b> {user.profileRecord.firstName ? user.profileRecord.firstName : ''} </b> to
                                        <span> Interview?</span>
                                      </Typography>);

                                  case "interview":
                                    return (
                                      <Typography className='shortlistTitle'>
                                        Move <b> {user.profileRecord.firstName ? user.profileRecord.firstName : ''} </b> to
                                        <span> Offer?</span>
                                      </Typography>);

                                  case "offer":
                                    return (
                                      <Typography className='shortlistTitle'>
                                        Move <b> {user.profileRecord.firstName ? user.profileRecord.firstName : ''} </b> to
                                        <span> Hired?</span>
                                      </Typography>);

                                  case "hired":
                                    return (
                                      <Typography className='shortlistTitle'>
                                        Move <b> {user.profileRecord.firstName ? user.profileRecord.firstName : ''} </b> to
                                        <span> Archive?</span>
                                      </Typography>);

                                  default: return "";
                                }
                              })()}




                              <Typography component='div' className='dayOuter btnOuter rejectShortlist interviewBtns'>
                                <Typography component='div' className='dayRight'>
                                  <Button onClick={handleClose11} className='rejectBtn1 undoBtn'>Undo</Button>
                                </Typography>
                                <Typography component='div' className='dayLeft'>
                                  <Button onClick={() => updateJobStatusNew('accept')} className='applyBtn'>Confirm Move</Button>
                                </Typography>
                              </Typography>
                            </Box>
                          </Modal>



                          <Modal
                            open={openPreMove}
                            onClose={handleClosePreMove}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box className='customBox' sx={style}>
                              <Link onClick={handleClosePreMove} to="#" className='crossBtn'><i className="fas fa-xmark"></i></Link>
                              <Typography className='folderDiv'><i className="fas fa-folder"></i></Typography>
                              {(() => {
                                switch (archiveTabType) {
                                  case "application":
                                    return (
                                      <Typography className='shortlistTitle'>
                                        Want <b> {user.profileRecord.firstName ? user.profileRecord.firstName : ''} </b> to
                                        <span> Reject?</span>
                                      </Typography>);
                                  case "shortlist":
                                    return (
                                      <Typography className='shortlistTitle'>
                                        Want <b> {user.profileRecord.firstName ? user.profileRecord.firstName : ''} </b> to
                                        <span> Reject?</span>
                                      </Typography>);


                                  case "interview":
                                    return (
                                      <Typography className='shortlistTitle'>
                                        Want <b> {user.profileRecord.firstName ? user.profileRecord.firstName : ''} </b> to
                                        <span> Reject?</span>
                                      </Typography>);

                                  case "offer":
                                    return (
                                      <Typography className='shortlistTitle'>
                                        Want <b> {user.profileRecord.firstName ? user.profileRecord.firstName : ''} </b> to
                                        <span> Reject?</span>
                                      </Typography>);

                                  case "hired":
                                    return (
                                      <Typography className='shortlistTitle'>
                                        Want <b> {user.profileRecord.firstName ? user.profileRecord.firstName : ''} </b> to
                                        <span> Reject?</span>
                                      </Typography>);

                                  default: return "";
                                }
                              })()}
                              <Typography component='div' className='dayOuter btnOuter rejectShortlist interviewBtns'>
                                <Typography component='div' className='dayRight'>
                                  <Button onClick={handleClosePreMove} className='rejectBtn1 undoBtn'>Undo</Button>
                                </Typography>
                                <Typography component='div' className='dayLeft'>
                                  <Button onClick={() => updateJobStatusNew('reject')} className='applyBtn'>Reject </Button>
                                </Typography>
                              </Typography>
                            </Box>
                          </Modal>




                          <Modal
                            open={open33}
                            onClose={handleClose33}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            className='customModal applicantModal'
                          >
                            <Box className='customBox' sx={style}>
                              <div className='CusBox'>
                                <div className='projectDiv'>
                                  <div className='projectDivLeft'>
                                    <img src={user.profilePic ? user.profilePic : Profile} />
                                    <div className='projectleftInner'>
                                      <div className='profileT'>{user.profileRecord.firstName ? user.profileRecord.firstName : ''} {user.profileRecord.surname ? user.profileRecord.surname : ''}</div>

                                      <p>{user.profileRecord.firstName ? user.profileRecord.headline : ''}</p>
                                    </div>
                                    <Typography className='appCen'>

                                      {return_score_tab(user.scoring ? user.scoring : 0)}
                                    </Typography>
                                  </div>
                                  <div className='projectDivRight'>
                                    <ul>
                                      <li><Link onClick={() => getMessageNavigate(user.acctId)} to="#"><i class="fa-solid fa-envelope"></i><span>Message</span></Link></li>
                                      <li className={savedProfileStatus && savedProfileStatus > 0 ? "activeicon" : ""}>
                                        <Link onClick={() => handleSaveCandidate(archiveModelItem)} to="#">
                                          <i class="fa-regular fa-star"></i><span>Save </span>
                                        </Link></li>
                                    </ul >
                                  </div >
                                </div >
                                <Box sx={{ width: '100%', typography: 'body1' }} className='customTabs'>
                                  <TabContext value={valueTab}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                      <TabList onChange={handleChange111} aria-label="lab API tabs example">
                                        <Tab className='active' label="Details" value="1" />
                                        <Tab label="Education" value="2" />
                                        <Tab label="Experience" value="3" />
                                        <Tab label="Resume" value="4" />
                                        <Tab label="Psychometric" value="5" />
                                        <Tab label="Video" value="6" />
                                      </TabList>
                                    </Box>
                                    <TabPanel value="1">
                                      <div className="basicInfo">
                                        <div className="basicInfoTitle">Basic Information</div>
                                        <div className="basicInfoBody">
                                          <GridContainer justifyContent="center" spacing={2}>
                                            <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                                              <label className="basicLabel">Name</label>
                                              <p className="basic_p">{user.profileRecord.firstName ? user.profileRecord.firstName : ''} {user.profileRecord.surname ? user.profileRecord.surname : ''}</p>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                                              <label className="basicLabel">Email</label>
                                              <p className="basic_p">{user.email ? user.email : ''}</p>
                                            </GridItem>
                                          </GridContainer>
                                          <GridContainer justifyContent="center" spacing={2}>
                                            <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                                              <label className="basicLabel">Phone Number</label>
                                              <p className="basic_p">{user.profileRecord.areaCode ? user.profileRecord.areaCode : ''} {user.profileRecord.contactNo ? user.profileRecord.contactNo : ''}</p>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                                              <label className="basicLabel">Origin</label>
                                              <p className="basic_p">{user.profileRecord.countryOrigin ? user.profileRecord.countryOrigin : '--'}</p>
                                            </GridItem>
                                          </GridContainer>
                                          <GridContainer justifyContent="center" spacing={2}>
                                            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                              <label className="basicLabel">Location</label>
                                              <p className="basic_p">{user.profileRecord.countryName ? user.profileRecord.countryName : ''}</p>
                                            </GridItem>
                                          </GridContainer>
                                        </div>
                                      </div>

                                      {
                                        questionaires && questionaires.length > 0 &&
                                        <div className="basicInfo">
                                          <div className="basicInfoTitle">Questionnaire Answers</div>
                                          <div className="basicInfoBody">
                                            {
                                              questionaires && questionaires.length > 0 &&
                                              questionaires.map((item) => {
                                                return (
                                                  <GridContainer justifyContent="center" spacing={2}>
                                                    <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                                      <label className="basicLabel">{item.quesDescription}</label>
                                                      <p className="basic_p">{item.answerId == 1 ? 'Yes' : 'No'}</p>
                                                    </GridItem>
                                                  </GridContainer>
                                                )
                                              })
                                            }
                                          </div>
                                        </div>
                                      }
                                    </TabPanel>
                                    <TabPanel value="2">
                                      <div className="basicInfoBody">
                                        <GridContainer justifyContent="center" spacing={2}>



                                          {user.educationRecords && user.educationRecords.map((itemedu, indexedu) => {
                                            return (
                                              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <div className='eduTab'>
                                                  <div className='universityTitel'>{itemedu.school}</div>
                                                  <p className='study_P'>{itemedu.studyField}</p>
                                                  <p className="basic_p">{itemedu.courseName}</p>
                                                  <label className="basicLabel">{itemedu.startDate} - {itemedu.endDate} </label>
                                                  {itemedu.description}
                                                </div>
                                              </GridItem>
                                            )
                                          })
                                          }

                                          {user.educationRecords && user.educationRecords.length == 0 ? (<GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <div className='NoEducationList'>No Education List</div>
                                          </GridItem>) : ''}

                                        </GridContainer>
                                      </div>


                                    </TabPanel>
                                    <TabPanel value="3">
                                      <div className="basicInfoBody eduBoday">
                                        <GridContainer justifyContent="center" spacing={2}>


                                          {user.experienceRecords && user.isViewed == true && user.experienceRecords.map((itemexp, indexexp) => {
                                            return (
                                              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <div className='eduTab'>
                                                  <label className="basicLabel">
                                                    {itemexp.isVisible == 0 ? (
                                                      'Not Visible to public'
                                                    ) : (
                                                      ' Visible to public'
                                                    )}


                                                  </label>
                                                  <div className='universityTitel'>{itemexp.title}</div>
                                                  <p>{itemexp.organization}</p>
                                                  <label className="basicLabel">{itemexp.startDate} - {itemexp.endDate}</label>
                                                  <hr />
                                                  {itemexp.description}

                                                </div>

                                              </GridItem>
                                            )
                                          })
                                          }
                                          {user.experienceRecords && user.isViewed == false ? (<GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <div className='NoEducationList'>No visible public</div>
                                          </GridItem>) : ''}

                                          {user.experienceRecords && user.isViewed == true && user.experienceRecords.length == 0 ? (<GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <div className='NoEducationList'>No record found</div>
                                          </GridItem>) : ''}
                                        </GridContainer>
                                      </div>

                                    </TabPanel>
                                    <TabPanel value="4">
                                      <div className="basicInfoBody eduBoday">
                                        <GridContainer justifyContent="center" spacing={2}>
                                          <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <div className='eduTab'>
                                              <Typography component="div" className='uploadMainDiv'>
                                                {
                                                  user.resume && user.resume.userAttachmentList && user.resume.userAttachmentList.length > 0 &&

                                                  user.resume.userAttachmentList.map(function (userAttadocument_itemchmentList, index) {

                                                    return (<Typography key={'user-resume-' + index} component="div" className='uploadHeader uploadData'>





                                                      <Typography component="div" className='documentName doc'>  {userAttadocument_itemchmentList.title}</Typography>
                                                      <Typography component="div" className='documentName dateUpload'>
                                                        <i className="fa-regular fa-eye"></i>
                                                        {userAttadocument_itemchmentList.isVisible == 0 ? (
                                                          <i className="fa-regular fa-eye"></i>
                                                        ) : (
                                                          <i className="fa-regular fa-eye-slash"></i>
                                                        )}

                                                        {userAttadocument_itemchmentList.createdDate}</Typography>
                                                      <Typography component="div" className='documentName fileSize'>{userAttadocument_itemchmentList.totalCount}MB</Typography>





                                                    </Typography>)

                                                  })

                                                }

                                                {user.resume && user.resume.userAttachmentList && user.resume.userAttachmentList.length == 0 ? (<GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                                  <div className='NoEducationList'>No record found</div>
                                                </GridItem>) : ''}

                                              </Typography>
                                            </div>
                                          </GridItem>
                                        </GridContainer>
                                      </div>

                                    </TabPanel>
                                    {/* <TabPanel value="5">
                                      <div className="basicInfoBody eduBoday">
                                        <GridContainer justifyContent="center" spacing={2}>
                                          <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <div className='eduTab'>
                                              <div className='universityTitel'>INTJ- The Analytical Mind</div>
                                              <label className="basicLabel">INTJs are analytical problem-solvers, eager to improve systems and processes with their innovative ideas. They have a talent for seeing possibilities for improvement, whether at work, at home, or in themselves.</label>

                                              <div className='universityTitel strength'>Strengths</div>
                                              <label className="basic_p ">Organisation</label>
                                              <p className="basicLabel">Excels in organisational work and meeting deadlines and tasks.</p>

                                            </div>
                                          </GridItem>
                                        </GridContainer>
                                      </div>
                                    </TabPanel> */}
                                    <TabPanel value="5">
                                      <div className="basicInfoBody eduBoday">
                                        <GridContainer justifyContent="center" spacing={2}>
                                          <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <div className='eduTab'>
                                              {
                                                user.psychometricResult && user.psychometricResult.resultType && user.psychometricResult.resultType.totalCount > 0 &&
                                                <div>
                                                  <div className='universityTitel'>{user.psychometricResult.resultType.title}-{user.psychometricResult.resultType.name}</div>
                                                  <label className="basicLabel">{user.psychometricResult.resultType.description}</label>
                                                </div>
                                              }
                                              {
                                                user.psychometricResult && user.psychometricResult.strengthList && user.psychometricResult.strengthList.length > 0 &&
                                                <div className='universityTitel strength'>Strengths</div>
                                              }
                                              {
                                                user.psychometricResult.strengthList && user.psychometricResult.strengthList && user.psychometricResult.strengthList.length > 0 &&
                                                user.psychometricResult.strengthList.map(function (itemsingle, index) {
                                                  return (
                                                    <div>
                                                      <div className='basic_p psyp'>{itemsingle.strengthTitle}</div>
                                                      <label className="basicLabel">{itemsingle.strengthDescription}</label>
                                                    </div>
                                                  )
                                                })
                                              }

                                              {
                                                user.psychometricResult && user.psychometricResult.strengthList && user.psychometricResult.strengthList.length == 0 ? (<GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                                  <div className='NoEducationList'>No record found</div>
                                                </GridItem>) : ''
                                              }

                                            </div>
                                          </GridItem>
                                        </GridContainer>
                                        <GridContainer justifyContent="center" spacing={2}>
                                          <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>

                                          </GridItem>
                                        </GridContainer>
                                      </div>


                                    </TabPanel>
                                    <TabPanel value="6">
                                      <div className="basicInfoBody eduBoday">
                                        <GridContainer justifyContent="center" spacing={2}>
                                          <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <div className='eduTab'>
                                              <Typography component="div" className='uploadMainDiv'>
                                                {
                                                  user.video && user.video.userAttachmentList && user.video.userAttachmentList.length > 0 &&

                                                  user.video.userAttachmentList.map(function (userAttachmentList, index) {

                                                    return (

                                                      <Typography component="div" className='videoMainOuter'>
                                                        <GridContainer spacing={2}>
                                                          <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                                                            <Typography component="div" className='videoOuter'>
                                                              <video id="vid" width="320" height="240" controls>
                                                                <source src={userAttachmentList.attachmentPath ? userAttachmentList.attachmentPath : ''} type="video/mp4" />
                                                                <source src={userAttachmentList.attachmentPath ? userAttachmentList.attachmentPath : ''} type="video/ogg" />
                                                                Your browser does not support the video tag.
                                                              </video>
                                                            </Typography>
                                                            <Typography className='videoTitle' >{userAttachmentList.title} <br /><span id="duration"> </span></Typography>

                                                          </GridItem>
                                                        </GridContainer>
                                                      </Typography>



                                                    )

                                                  })

                                                }

                                                {user.video && user.video.userAttachmentList && user.video.userAttachmentList.length == 0 ? (<GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                                  <div className='NoEducationList'>No record found</div>
                                                </GridItem>) : ''}

                                              </Typography>
                                            </div>
                                          </GridItem>
                                        </GridContainer>
                                      </div>

                                    </TabPanel>
                                  </TabContext>
                                  {(() => {
                                    switch (archiveTabType) {
                                      case "application":
                                        return (
                                          <Typography component='div' className='dayOuter btnOuter rejectShortlist'>
                                            <Typography component='div' className='dayRight'>
                                              <Button onClick={() => handleAcceptModelPopup(archiveModelItem, 'reject', 'application')} className='rejectBtn1'>Reject</Button>
                                            </Typography>
                                            <Typography component='div' className='dayLeft'>
                                              <Button onClick={() => handleAcceptModelPopup(archiveModelItem, 'accept', 'application')} className='applyBtn'>Shortlist</Button>
                                            </Typography>
                                          </Typography>);
                                      case "shortlist":
                                        return (
                                          <Typography component='div' className='dayOuter btnOuter rejectShortlist'>
                                            <Typography component='div' className='dayRight'>
                                              <Button onClick={() => handleAcceptModelPopup(archiveModelItem, 'reject', 'shortlist')} className='rejectBtn1'>Reject</Button>
                                            </Typography>
                                            <Typography component='div' className='dayLeft'>
                                              <Button onClick={() => handleAcceptModelPopup(archiveModelItem, 'interview', 'shortlist')} className='applyBtn'>Interview</Button>
                                            </Typography>
                                          </Typography>);

                                      case "interview":
                                        return (
                                          <Typography component='div' className='dayOuter btnOuter rejectShortlist'>
                                            <Typography component='div' className='dayRight'>
                                              <Button onClick={() => handleAcceptModelPopup(archiveModelItem, 'reject', 'interview')} className='rejectBtn1'>Reject</Button>
                                            </Typography>
                                            <Typography component='div' className='dayLeft'>
                                              <Button onClick={() => handleAcceptModelPopup(archiveModelItem, 'offer', 'interview')} className='applyBtn'>Offer</Button>
                                            </Typography>
                                          </Typography>);

                                      case "offer":
                                        return (
                                          <Typography component='div' className='dayOuter btnOuter rejectShortlist'>
                                            <Typography component='div' className='dayRight'>
                                              <Button onClick={() => handleAcceptModelPopup(archiveModelItem, 'reject', 'offer')} className='rejectBtn1'>Reject</Button>
                                            </Typography>
                                            <Typography component='div' className='dayLeft'>
                                              <Button onClick={() => handleAcceptModelPopup(archiveModelItem, 'accept', 'offer')} className='applyBtn'>Offer</Button>
                                            </Typography>
                                          </Typography>);


                                      case "offer":
                                        return (
                                          <Typography component='div' className='dayOuter btnOuter rejectShortlist'>
                                            <Typography component='div' className='dayRight'>
                                              <Button onClick={() => handleAcceptModelPopup(archiveModelItem, 'reject', 'offer')} className='rejectBtn1'>Reject</Button>
                                            </Typography>
                                            <Typography component='div' className='dayLeft'>
                                              <Button onClick={() => handleAcceptModelPopup(archiveModelItem, 'accept', 'offer')} className='applyBtn'>Offer</Button>
                                            </Typography>
                                          </Typography>);

                                      case "hired":
                                        return (
                                          <Typography component='div' className='dayOuter btnOuter rejectShortlist'>
                                            <Typography component='div' className='dayRight'>
                                              <Button onClick={() => handleAcceptModelPopup(archiveModelItem, 'reject', 'hired')} className='rejectBtn1'>Reject</Button>
                                            </Typography>

                                          </Typography>);


                                      default: return "";
                                    }
                                  })()}
                                </Box>
                              </div >
                            </Box >
                          </Modal >


                          <Modal
                            open={open22}
                            onClose={handleClose22}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            className='customModal'
                          >
                            <Box className='customBox' sx={style}>
                              <div className='CusBox'>
                                <Typography className='scheduleModal'>Schedule Interview {user.profileRecord.firstName ? user.profileRecord.firstName : ''}</Typography>
                                <Link to="#" onClick={handleClose22} className='crossBtn'><i className="fas fa-xmark"></i></Link>
                                <GridContainer justifyContent="center" spacing={2}>
                                  <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Typography className='inputOuter'>
                                      <label className='labelCustom'>Interview Method</label>
                                      <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Choose an option</InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          //value={age}
                                          label="Age"
                                          onChange={handleInterviewTyoeInput}
                                        >
                                          <MenuItem value={1}><i className="fas fa-video customIcon"></i>Zoom Meeting</MenuItem>
                                          <MenuItem value={2}><i className="fas fa-user customIcon"></i>In-Person Interview</MenuItem>
                                          <MenuItem value={3}><i className="fas fa-phone customIcon"></i>Phone Interview</MenuItem>
                                        </Select>
                                      </FormControl>
                                      {
                                        meetingType && meetingType == 2 ? (<><label className='labelCustom chooseLocation'>Choose Location</label>
                                          <FormControl fullWidth>
                                            <div className='customFormSelect'>
                                              {!isLoaded ? (<div>Loading...</div>) : <Map />}
                                              {/*
                                              <GooglePlacesAutocomplete
                                                apiKey="AIzaSyCfHT53fTL_qLqFyAzW2Az4WoSXzT9LqSs"
                                              /> */}
                                            </div>


                                          </FormControl>
                                        </>) : ('')
                                      }

                                    </Typography>
                                  </GridItem>

                                  <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                                    {
                                      meetingType && meetingType == 1 ? (<> <div className='zoomMessage'>
                                        <i className="fas fa-video"></i>
                                        <Button type="button" className='ReschedulBtn decline' onClick={zoomAPIIntegrationBy}>Join with Zoom</Button>
                                      </div>
                                      </>) : ('')
                                    }
                                  </GridItem>
                                </GridContainer>
                                <GridContainer justifyContent="center" spacing={2}>
                                  <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>

                                    <Typography className='inputOuter'>
                                      <label className='labelCustom'>Invite Other Attendees</label>
                                      <p className='inputdesc'>An email invite will be sent to all attendees.</p>
                                      {/* <FormControl className='attendeeOuter' variant="standard">
                                      <InputLabel placeholder="Enter email address(es) here" htmlFor="demo-customized-textbox">Age</InputLabel>
                                      <BootstrapInput id="demo-customized-textbox" />
                                    </FormControl> */}
                                      <ReactMultiEmail
                                        placeholder="Input your Email Address"
                                        emails={email}
                                        validateEmail={email => {
                                          const isValid = emailRegex().test('unicorn' + email);
                                          if (isValid) {
                                            setEmailIsValid(false)
                                          } else {
                                            setEmailIsValid(true)
                                          }
                                          return isValid;
                                        }}
                                        onChange={(_emails: string[]) => setEmail(_emails)}
                                        getLabel={(
                                          email: string,
                                          index: number,
                                          removeEmail: (index: number) => void
                                        ) => {
                                          return (
                                            <div data-tag key={index}>
                                              {email}
                                              <span data-tag-handle onClick={() => removeEmail(index)}>
                                                ×
                                              </span>
                                            </div>
                                          );
                                        }}
                                      />
                                      {emailIsValid && <p className="custom_email">Email format is wrong</p>}
                                    </Typography>
                                  </GridItem>
                                </GridContainer>
                                <Typography className='timeSlotDiv'>
                                  <label className='labelCustom slotLabel'>Please choose 3 available timeslots for the interview.</label>
                                  <GridContainer justifyContent="center" spacing={6}>
                                    <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                                      <Typography className='inputOuter1'>
                                        <label className='labelCustom'>Timeslot 1 Date</label>
                                        <input className='customInput custom1' type='date' min={disablePastDate()} onChange={handleTimeSlot1DateInput} />
                                      </Typography>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                                      <Typography className='inputOuter1'>
                                        <label className='labelCustom'>Timeslot 1 Time</label>
                                        <input className='customInput custom1' type='time' onChange={handleTimeSlot1TimeInput} />
                                      </Typography>
                                    </GridItem>
                                  </GridContainer>
                                  <GridContainer justifyContent="center" spacing={6}>
                                    <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                                      <Typography className='inputOuter1'>
                                        <label className='labelCustom'>Timeslot 2 Date</label>
                                        <input className='customInput custom1' type='date' min={disablePastDate()} onChange={handleTimeSlot2DateInput} />
                                      </Typography>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                                      <Typography className='inputOuter1'>
                                        <label className='labelCustom'>Timeslot 2 Time</label>
                                        <input className='customInput custom1' type='time' onChange={handleTimeSlot2TimeInput} />
                                      </Typography>
                                    </GridItem>
                                  </GridContainer>
                                  <GridContainer justifyContent="center" spacing={6}>
                                    <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                                      <Typography className='inputOuter1'>
                                        <label className='labelCustom'>Timeslot 3 Date</label>
                                        <input className='customInput custom1' type='date' min={disablePastDate()} onChange={handleTimeSlot3DateInput} />
                                      </Typography>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                                      <Typography className='inputOuter1'>
                                        <label className='labelCustom'>Timeslot 3 Time</label>
                                        <input className='customInput custom1' type='time' onChange={handleTimeSlot3TimeInput} />
                                      </Typography>
                                    </GridItem>
                                  </GridContainer>
                                </Typography>
                                <Typography component='div' className='dayOuter btnOuter rejectShortlist interviewBtns'>
                                  <Typography component='div' className='dayLeft'>
                                    <Button onClick={() => moveToInterview(archiveModelItem.applId, archiveModelItem.acctId)} className='applyBtn'>Submit</Button>
                                  </Typography>
                                </Typography>
                              </div>
                            </Box>
                          </Modal>



                          <Modal
                            open={open222}
                            onClose={handleClose222}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            className='customModal'
                          >
                            <Box className='customBox offerModal' sx={style}>
                              <div className='CusBox'>
                                <div className='projectDiv'>
                                  <div className='profileT'>Make a Job Offer</div>
                                </div>
                                <div className='offerBody'>
                                  <Typography className='applicationTop'>
                                    <div className='offerLeft'>
                                      <span className='appSpan'>
                                        <img src={archiveModelItem.profilePic ? archiveModelItem.profilePic : defaultAvatar} />
                                      </span>
                                      <div className='appCent'>
                                        <Typography className='appNamee'>{user.profileRecord.firstName ? user.profileRecord.firstName : ''}</Typography>
                                        <a className='viewProfile' onClick={() => handleAcceptModelPopup(archiveModelItem, 'archive', 'interviewNew')} href="#">View Profile</a>
                                      </div>
                                    </div>
                                    <Typography className='appCen'>
                                      {return_score_tab(user.scoring ? user.scoring : 0)}
                                    </Typography>
                                  </Typography>
                                  <Link onClick={handleClose222} to="#" className='crossBtn'><i className="fas fa-xmark"></i></Link>
                                  <Typography className='offerDetailTitle'>Offer Details:</Typography>
                                  <Typography className='timeSlotDiv'>
                                    <label className='labelCustom slotLabel'></label>
                                    <GridContainer justifyContent="center" spacing={6}>
                                      <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className='inputOuter1'>
                                          <label className='labelCustom'>Job Position</label>
                                          <input className='customInput custom1 boldplaceholder' placeholder='' value={jobPosition} type='text' maxlength="200" onChange={handleJobPositionInput} />
                                          {
                                            jobPosition.length > 200 && <p style={{ color: 'red' }}>Exceed text amount 200</p>
                                          }
                                        </Typography>
                                      </GridItem>
                                    </GridContainer>
                                    <GridContainer justifyContent="center" spacing={6}>
                                      <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className='inputOuter1'>
                                          <label className='labelCustom'>Service Period</label>
                                          <input className='customInput custom1 boldplaceholder' placeholder='' value={servicePeriod} type='text' maxlength="200" onChange={handleServicePeriodInput} />
                                          {
                                            servicePeriod.length > 200 && <p style={{ color: 'red' }}>Exceed text amount 200</p>
                                          }
                                        </Typography>
                                      </GridItem>
                                    </GridContainer>
                                    <GridContainer justifyContent="center" spacing={6}>
                                      <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography className='inputOuter1'>
                                          <label className='labelCustom'>Offer Salary ({selectedJobPost[0].currencyCode})</label>
                                          <div className='offerSalary'>
                                            <input className='customInput custom1 boldplaceholder' placeholder='' value={offerSalary} type='number' onChange={handleOfferSalaryInput} />
                                            <span>/ Month</span>
                                          </div>
                                        </Typography>
                                      </GridItem>
                                    </GridContainer>
                                    <GridContainer justifyContent="center" spacing={6}>
                                      <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Typography className='inputOuter1'>
                                          <label className='labelCustom'>Job Type</label>
                                          <input className='customInput custom1 boldplaceholder' placeholder='' value={jobType} type='text' maxlength="200" onChange={handleJobTypeInput} />
                                          {
                                            jobType.length > 200 && <p style={{ color: 'red' }}>Exceed text amount 200</p>
                                          }
                                        </Typography>
                                      </GridItem>
                                      <GridItem xs={12} sm={12} md={6} lg={6} xl={6}></GridItem>
                                    </GridContainer>
                                    <GridContainer justifyContent="center" spacing={6}>
                                      <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <p className='benefitsP'>Any other benefits, bonuses, and/or wage supplements:</p>
                                        <p className='borderP'>
                                          <TextareaAutosize
                                            aria-label="minimum height"
                                            className='customInput custom1 boldplaceholder autoreszie'
                                            maxLength='3000'
                                            minRows={3}
                                          />
                                        </p>
                                      </GridItem>
                                    </GridContainer>
                                  </Typography>
                                  <Typography component='div' className='dayOuter btnOuter rejectShortlist'>
                                    <Typography component='div' className='dayLeft'>
                                      <Button className='applyBtn' onClick={() => moveToOffer(archiveModelItem.applId, archiveModelItem.acctId)}  >Send Offer</Button>

                                    </Typography>
                                    <Typography component='div' className='dayRight'>
                                      <Button onClick={handleClose222} className='rejectBtn1 cancelBtn'>Cancel</Button>
                                    </Typography>
                                  </Typography>
                                </div>
                              </div>
                            </Box>
                          </Modal>


                          <Modal
                            open={open2222}
                            onClose={handleClose2222}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            className='customModal applicantModal offerSentModal'
                          >
                            <Box className='customBox' sx={style}>
                              <img className='msgImg' src={Msg} />
                              <div className='offerBody'>

                                <Link to="#" onClick={handleClose2222} className='crossBtn'><i className="fas fa-xmark"></i></Link>
                                <p className='jobOfferP'>Your job offer has been sent to {user.profileRecord.firstName ? user.profileRecord.firstName : ''}!</p>
                                <p className='jobOfferDesc'>You will receive a notification once {user.profileRecord.firstName ? user.profileRecord.firstName : ''} <br />
                                  has accepted your offer.</p>
                              </div>
                            </Box>
                          </Modal>



                        </div >
                      }
                      {
                        optionToShow === tabs[1] &&
                        <div className='tab_content_custom'>
                          {displayJobPostDetail}
                        </div>
                      }
                      {
                        optionToShow === tabs[2] &&

                        <div className='tab_content_custom'>
                          {archivedTabData()}
                        </div>
                      }
                    </div >




                    <Modal
                      open={open33}
                      onClose={handleClose33}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                      className='customModal applicantModal'
                    >
                      <Box className='customBox' sx={style}>
                        <div className='CusBox'>
                          <div className='projectDiv'>
                            <div className='projectDivLeft'>
                              <img src={user.profilePic ? user.profilePic : defaultAvatar} />
                              <div className='projectleftInner'>
                                <div className='profileT'>{user.profileRecord.firstName ? user.profileRecord.firstName : ''} {user.profileRecord.surname ? user.profileRecord.surname : ''}</div>

                                <p>{user.profileRecord.firstName ? user.profileRecord.headline : ''}</p>
                              </div>
                              <Typography className='appCen'>

                                {return_score_tab(user.scoring ? user.scoring : 0)}
                              </Typography>
                            </div>
                            <div className='projectDivRight'>
                              <ul>
                                <li><Link onClick={() => getMessageNavigate(user.acctId)} to="#"><i class="fa-solid fa-envelope"></i><span>Message</span></Link></li>
                                <li className={savedProfileStatus && savedProfileStatus > 0 ? "activeicon" : ""}>
                                  <Link onClick={() => handleSaveCandidate(archiveModelItem)} to="#">
                                    <i class="fa-regular fa-star"></i><span>Save </span>
                                  </Link></li>
                              </ul >
                            </div >
                          </div >
                          <Box sx={{ width: '100%', typography: 'body1' }} className='customTabs'>
                            <TabContext value={valueTab}>
                              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange111} aria-label="lab API tabs example">
                                  <Tab className='active' label="Details" value="1" />
                                  <Tab label="Education" value="2" />
                                  <Tab label="Experience" value="3" />
                                  <Tab label="Resume" value="4" />
                                  <Tab label="Psychometric" value="5" />
                                  <Tab label="Video" value="6" />
                                </TabList>
                              </Box>
                              <TabPanel value="1">
                                <div className="basicInfo">
                                  <div className="basicInfoTitle">Basic Information</div>
                                  <div className="basicInfoBody">
                                    <GridContainer justifyContent="center" spacing={2}>
                                      <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <label className="basicLabel">Name</label>
                                        <p className="basic_p">{user.profileRecord.firstName ? user.profileRecord.firstName : ''} {user.profileRecord.surname ? user.profileRecord.surname : ''}</p>
                                      </GridItem>
                                      <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <label className="basicLabel">Email</label>
                                        <p className="basic_p">{user.email ? user.email : ''}</p>
                                      </GridItem>
                                    </GridContainer>
                                    <GridContainer justifyContent="center" spacing={2}>
                                      <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <label className="basicLabel">Phone Number</label>
                                        <p className="basic_p">{user.profileRecord.areaCode ? user.profileRecord.areaCode : ''} {user.profileRecord.contactNo ? user.profileRecord.contactNo : ''}</p>
                                      </GridItem>
                                      <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <label className="basicLabel">Origin</label>
                                        <p className="basic_p">{user.profileRecord.countryOrigin ? user.profileRecord.countryOrigin : '--'}</p>
                                      </GridItem>
                                    </GridContainer>
                                    <GridContainer justifyContent="center" spacing={2}>
                                      <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <label className="basicLabel">Location</label>
                                        <p className="basic_p">{user.profileRecord.countryName ? user.profileRecord.countryName : ''}</p>
                                      </GridItem>
                                    </GridContainer>
                                  </div>
                                </div>

                                {
                                  questionaires && questionaires.length > 0 &&
                                  <div className="basicInfo">
                                    <div className="basicInfoTitle">Questionnaire Answers</div>
                                    <div className="basicInfoBody">
                                      {
                                        questionaires && questionaires.length > 0 &&
                                        questionaires.map((item) => {
                                          return (
                                            <GridContainer justifyContent="center" spacing={2}>
                                              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <label className="basicLabel">{item.quesDescription}</label>
                                                <p className="basic_p">{item.answerId == 1 ? 'Yes' : 'No'}</p>
                                              </GridItem>
                                            </GridContainer>
                                          )
                                        })
                                      }
                                    </div>
                                  </div>
                                }
                              </TabPanel>
                              <TabPanel value="2">
                                <div className="basicInfoBody">
                                  <GridContainer justifyContent="center" spacing={2}>



                                    {user.educationRecords && user.educationRecords.map((itemedu, indexedu) => {
                                      return (
                                        <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                          <div className='eduTab'>
                                            <div className='universityTitel'>{itemedu.school}</div>
                                            <p className='study_P'>{itemedu.studyField}</p>
                                            <p className="basic_p">{itemedu.courseName}</p>
                                            <label className="basicLabel">{moment(itemedu.startDate).format('MMM YYYY')} - {itemedu.endDate ? moment(itemedu.endDate).format('MMM YYYY') : 'Present'}  {countMonthYear(itemedu.startDate, itemedu.endDate) && " • " + countMonthYear(itemedu.startDate, itemedu.endDate)} </label>
                                            {itemedu.description}
                                          </div>
                                        </GridItem>
                                      )
                                    })
                                    }

                                    {user.educationRecords && user.educationRecords.length == 0 ? (<GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                      <div className='NoEducationList'>No Education List</div>
                                    </GridItem>) : ''}

                                  </GridContainer>
                                </div>


                              </TabPanel>
                              <TabPanel value="3">
                                <div className="basicInfoBody eduBoday">
                                  <GridContainer justifyContent="center" spacing={2}>


                                    {user.experienceRecords && user.isViewed == true && user.experienceRecords.map((itemexp, indexexp) => {
                                      return (
                                        <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                          <div className='eduTab'>
                                            <label className="basicLabel">
                                              {itemexp.isVisible == 0 ? (
                                                'Not Visible to public'
                                              ) : (
                                                ' Visible to public'
                                              )}


                                            </label>
                                            <div className='universityTitel'>{itemexp.title}</div>
                                            <p>{itemexp.organization}</p>
                                            <label className="basicLabel">{moment(itemexp.startDate).format('MMM YYYY')} - {itemexp.endDate ? moment(itemexp.endDate).format('MMM YYYY') : 'Present'} {countMonthYear(itemexp.startDate, itemexp.endDate) && " • " + countMonthYear(itemexp.startDate, itemexp.endDate)}</label>
                                            <hr />
                                            {itemexp.description}

                                          </div>

                                        </GridItem>
                                      )
                                    })
                                    }
                                    {user.experienceRecords && user.isViewed == false ? (<GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                      <div className='NoEducationList'>No visible public</div>
                                    </GridItem>) : ''}

                                    {user.experienceRecords && user.isViewed == true && user.experienceRecords.length == 0 ? (<GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                      <div className='NoEducationList'>No record found</div>
                                    </GridItem>) : ''}
                                  </GridContainer>
                                </div>

                              </TabPanel>
                              <TabPanel value="4">
                                <div className="basicInfoBody eduBoday">
                                  <GridContainer justifyContent="center" spacing={2}>

                                    {
                                      user.resume && user.resume.userAttachmentList && user.resume.userAttachmentList.length > 0 &&

                                      user.resume.userAttachmentList.map(function (userAttadocument_itemchmentList, index) {

                                        return (

                                          <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <div className='eduTab'>


                                              <Typography key={'user-resume-' + index} component="div" className='uploadHeader uploadData'>





                                                <Typography component="div" className='documentName doc'>  {userAttadocument_itemchmentList.title}</Typography>
                                                <Typography component="div" className='documentName dateUpload'>
                                                  <i className="fa-regular fa-eye"></i>
                                                  {userAttadocument_itemchmentList.isVisible == 0 ? (
                                                    <i className="fa-regular fa-eye"></i>
                                                  ) : (
                                                    <i className="fa-regular fa-eye-slash"></i>
                                                  )}

                                                  {userAttadocument_itemchmentList.createdDate}
                                                </Typography>
                                                <Typography component="div" className='documentName fileSize'>{userAttadocument_itemchmentList.totalCount}MB</Typography>





                                              </Typography>


                                            </div>
                                          </GridItem>


                                        )

                                      })

                                    }

                                    {user.resume && user.resume.userAttachmentList && user.resume.userAttachmentList.length == 0 ? (<GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                      <div className='NoEducationList'>No record found</div>
                                    </GridItem>) : ''}


                                  </GridContainer>
                                </div>

                              </TabPanel>
                              {/* <TabPanel value="5">
                                      <div className="basicInfoBody eduBoday">
                                        <GridContainer justifyContent="center" spacing={2}>
                                          <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <div className='eduTab'>
                                              <div className='universityTitel'>INTJ- The Analytical Mind</div>
                                              <label className="basicLabel">INTJs are analytical problem-solvers, eager to improve systems and processes with their innovative ideas. They have a talent for seeing possibilities for improvement, whether at work, at home, or in themselves.</label>

                                              <div className='universityTitel strength'>Strengths</div>
                                              <label className="basic_p ">Organisation</label>
                                              <p className="basicLabel">Excels in organisational work and meeting deadlines and tasks.</p>

                                            </div>
                                          </GridItem>
                                        </GridContainer>
                                      </div>
                                    </TabPanel> */}
                              <TabPanel value="5">
                                <div className="basicInfoBody eduBoday">
                                  <GridContainer justifyContent="center" spacing={2}>
                                    <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                      <div className='eduTab'>
                                        {
                                          user.psychometricResult && user.psychometricResult.resultType && user.psychometricResult.resultType.totalCount > 0 &&
                                          <div>
                                            <div className='universityTitel'>{user.psychometricResult.resultType.title}-{user.psychometricResult.resultType.name}</div>
                                            <label className="basicLabel">{user.psychometricResult.resultType.description}</label>
                                          </div>
                                        }
                                        {
                                          user.psychometricResult && user.psychometricResult.strengthList && user.psychometricResult.strengthList.length > 0 &&
                                          <div className='universityTitel strength'>Strengths</div>
                                        }
                                        {
                                          user.psychometricResult.strengthList && user.psychometricResult.strengthList && user.psychometricResult.strengthList.length > 0 &&
                                          user.psychometricResult.strengthList.map(function (itemsingle, index) {
                                            return (
                                              <div>
                                                <div className='basic_p'>{itemsingle.strengthTitle}</div>
                                                <label className="basicLabel">{itemsingle.strengthDescription}</label>
                                              </div>
                                            )
                                          })
                                        }

                                        {
                                          user.psychometricResult && user.psychometricResult.strengthList && user.psychometricResult.strengthList.length == 0 ? (<GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <div className='NoEducationList'>No record found</div>
                                          </GridItem>) : ''
                                        }

                                      </div>
                                    </GridItem>
                                  </GridContainer>
                                  <GridContainer justifyContent="center" spacing={2}>
                                    <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>

                                    </GridItem>
                                  </GridContainer>
                                </div>


                              </TabPanel>
                              <TabPanel value="6">
                                <div className="basicInfoBody eduBoday">
                                  <GridContainer justifyContent="center" spacing={2}>
                                    <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                      <div className='eduTab'>
                                        <Typography component="div" className='uploadMainDiv'>
                                          {
                                            user.video && user.video.userAttachmentList && user.video.userAttachmentList.length > 0 &&

                                            user.video.userAttachmentList.map(function (userAttachmentList, index) {

                                              return (

                                                <Typography component="div" className='videoMainOuter'>
                                                  <GridContainer spacing={2}>
                                                    <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                                                      <Typography component="div" className='videoOuter'>
                                                        <video id="vid" width="320" height="240" controls>
                                                          <source src={userAttachmentList.attachmentPath ? userAttachmentList.attachmentPath : ''} type="video/mp4" />
                                                          <source src={userAttachmentList.attachmentPath ? userAttachmentList.attachmentPath : ''} type="video/ogg" />
                                                          Your browser does not support the video tag.
                                                        </video>
                                                      </Typography>
                                                      <Typography className='videoTitle' >{userAttachmentList.title} <br /><span id="duration"> </span></Typography>

                                                    </GridItem>
                                                  </GridContainer>
                                                </Typography>



                                              )

                                            })

                                          }

                                          {user.video && user.video.userAttachmentList && user.video.userAttachmentList.length == 0 ? (<GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <div className='NoEducationList'>No record found</div>
                                          </GridItem>) : ''}

                                        </Typography>
                                      </div>
                                    </GridItem>
                                  </GridContainer>
                                </div>

                              </TabPanel>
                            </TabContext>
                            {(() => {
                              switch (archiveTabType) {
                                case "application":
                                  return (
                                    <Typography component='div' className='dayOuter btnOuter rejectShortlist'>
                                      <Typography component='div' className='dayRight'>
                                        <Button onClick={() => handleAcceptModelPopup(archiveModelItem, 'reject', 'application')} className='rejectBtn1'>Reject</Button>
                                      </Typography>
                                      <Typography component='div' className='dayLeft'>
                                        <Button onClick={() => handleAcceptModelPopup(archiveModelItem, 'accept', 'application')} className='applyBtn'>Shortlist</Button>
                                      </Typography>
                                    </Typography>);
                                case "shortlist":
                                  return (
                                    <Typography component='div' className='dayOuter btnOuter rejectShortlist'>
                                      <Typography component='div' className='dayRight'>
                                        <Button onClick={() => handleAcceptModelPopup(archiveModelItem, 'reject', 'shortlist')} className='rejectBtn1'>Reject</Button>
                                      </Typography>
                                      <Typography component='div' className='dayLeft'>
                                        <Button onClick={() => handleAcceptModelPopup(archiveModelItem, 'interview', 'shortlist')} className='applyBtn'>Interview</Button>
                                      </Typography>
                                    </Typography>);

                                case "interview":
                                  return (
                                    <Typography component='div' className='dayOuter btnOuter rejectShortlist'>
                                      <Typography component='div' className='dayRight'>
                                        <Button onClick={() => handleAcceptModelPopup(archiveModelItem, 'reject', 'interview')} className='rejectBtn1'>Reject</Button>
                                      </Typography>
                                      <Typography component='div' className='dayLeft'>
                                        <Button onClick={() => handleAcceptModelPopup(archiveModelItem, 'offer', 'interview')} className='applyBtn'>Offer</Button>
                                      </Typography>
                                    </Typography>);

                                case "offer":
                                  return (
                                    <Typography component='div' className='dayOuter btnOuter rejectShortlist'>
                                      <Typography component='div' className='dayRight'>
                                        <Button onClick={() => handleAcceptModelPopup(archiveModelItem, 'reject', 'offer')} className='rejectBtn1'>Reject</Button>
                                      </Typography>
                                      <Typography component='div' className='dayLeft'>
                                        <Button onClick={() => handleAcceptModelPopup(archiveModelItem, 'accept', 'offer')} className='applyBtn'>Offer</Button>
                                      </Typography>
                                    </Typography>);


                                case "offer":
                                  return (
                                    <Typography component='div' className='dayOuter btnOuter rejectShortlist'>
                                      <Typography component='div' className='dayRight'>
                                        <Button onClick={() => handleAcceptModelPopup(archiveModelItem, 'reject', 'offer')} className='rejectBtn1'>Reject</Button>
                                      </Typography>
                                      <Typography component='div' className='dayLeft'>
                                        <Button onClick={() => handleAcceptModelPopup(archiveModelItem, 'accept', 'offer')} className='applyBtn'>Offer</Button>
                                      </Typography>
                                    </Typography>);

                                case "hired":
                                  return (
                                    <Typography component='div' className='dayOuter btnOuter rejectShortlist'>
                                      <Typography component='div' className='dayRight'>
                                        <Button onClick={() => handleAcceptModelPopup(archiveModelItem, 'reject', 'hired')} className='rejectBtn1'>Reject</Button>
                                      </Typography>

                                    </Typography>);


                                default: return "";
                              }
                            })()}
                          </Box>
                        </div >
                      </Box >
                    </Modal >



                  </Typography >
                </GridItem >
              </GridContainer >
            </div >
          </div >
        </div >
        <Footer />
      </div >
      {
        jobLoaderDiv &&

        <Typography className='jobLoader'>
          <img className='jobLoaderImg' src={jobLoader} />
        </Typography>
      }
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoader}
        onClick={hideLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

    </>
  );
};



export default ApplicationManagement;
