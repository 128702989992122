import { createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import {
	instanceUrl,
	header,
	emptyCache,
} from "configurations/configurations.js";
import { logout } from "./authSlice";

import { getProfiles } from "configurations/redux/userSlice.js";
import { updateApplicantStatus } from "configurations/redux/jobManagementSlice.js";
// import { logout } from "configurations/redux/authSlice.js";
// const publicIp = require("public-ip");
const eventNotificationSlice = createSlice({
	name: "eventNotification",
	initialState: {
		searchCount: 0,
		searchResult: [],
		searchParams: [],
		lastRecordSize: 0,
		requestRecordSize: 0,
		isLoading: true,
		isEnd: false,
		isSearching: false,
		isUpdated: true,
		openNotification: false,
		navigateNotificationLink: false,
		keyword: "",
		item: [],
		notificationClick: null,
	},
	reducers: {
		NOTIFICATION_LOADING: (state, action) => {
			state.isLoading = true;
		},
		NOTIFICATION_SEARCH_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.searchResult = [
				...state.searchResult,
				...action.payload.searchResult,
			];
			state.searchParams = action.payload.searchParams;
			state.lastRecordSize =
				state.lastRecordSize + action.payload.requestRecordSize;
			state.requestRecordSize = action.payload.requestRecordSize;
			state.isLoading = false;
			state.openNotification = true;
			state.navigateNotificationLink = false;
			state.isUpdated = true;
			state.searchCount = 0;
			state.keyword = action.payload.keyword;
		},
		NOTIFICATION_SEARCH_END: (state, action) => {
			state.isEnd = true;
		},
		DEFAULT_NOTIFICATION_SEARCH: (state) => {
			state.searchResult = [];
			state.searchParams = [];
			state.lastRecordSize = 0;
			state.requestRecordSize = 0;
			state.isEnd = false;
			state.isSearching = true;
		},
		NOTIFICATION_SEARCH_FAIL: (state) => {
			state.isUpdated = false;
			state.isLoading = false;
			state.navigateNotificationLink = false;
		},
		NOTIFICATION_ADD_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.isUpdated = true;
			state.isLoading = false;
		},
		NOTIFICATION_CHECK_UNREAD: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.searchCount = action.payload.searchCount;
			state.isLoading = true;
		},
		NOTIFICATION_CHECK_UNREAD_END: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.searchCount = action.payload.searchCount;
			state.isUpdated = false;
		},
		NOTIFICATION_CHECK_UNREAD_FAIL: (state, action) => {
			state.searchCount = 0;
			state.isUpdated = false;
		},
		NOTIFICATION_UPDATE_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.isUpdated = true;
			state.isLoading = false;
			state.openNotification = null;
		},
		NOTIFICATION_NAVIGATE_LOADING: (state, action) => {
			state.navigateNotificationLink = true;
		},
		NOTIFICATION_NAVIGATE_SUCCESS: (state, action) => {
			// console.log(action.payload);
			// state.navigateNotificationLink = false;
			state.item = action.payload.recId;
		},
		NOTIFICATION_NAVIGATE_RESET: (state, action) => {
			// console.log(action.payload);
			state.navigateNotificationLink = false;
			state.item = 0;
			state.notificationClick = action.payload;
		},
		NOTIFICATION_SHOW: (state) => {
			state.openNotification = null;
		},
		NOTIFICATION_DELETE_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.isSearching = false;
			state.searchResult = action.payload.searchResult;
		},
	},
});

const {
	NOTIFICATION_LOADING,
	NOTIFICATION_SEARCH_SUCCESS,
	NOTIFICATION_SEARCH_END,
	DEFAULT_NOTIFICATION_SEARCH,
	NOTIFICATION_SEARCH_FAIL,
	NOTIFICATION_ADD_SUCCESS,
	NOTIFICATION_CHECK_UNREAD,
	NOTIFICATION_CHECK_UNREAD_END,
	NOTIFICATION_CHECK_UNREAD_FAIL,
	NOTIFICATION_UPDATE_SUCCESS,
	NOTIFICATION_NAVIGATE_LOADING,
	NOTIFICATION_NAVIGATE_SUCCESS,
	NOTIFICATION_NAVIGATE_RESET,
	NOTIFICATION_SHOW,
	NOTIFICATION_DELETE_SUCCESS,
} = eventNotificationSlice.actions;

export const eventNotificationGet = ({
	acctId,
	accessToken,
	email,
	lastRecordSize,
	requestRecordSize,
	keyword,
	bizId,
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		email,
		lastRecordSize,
		requestRecordSize,
		keyword,
		bizId,
	};
	dispatch(NOTIFICATION_LOADING());
	if (lastRecordSize === 0) {
		dispatch(DEFAULT_NOTIFICATION_SEARCH());
	}
	console.log(body);
	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/nm/get-notification`,
		params: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				const response = {
					searchResult: res.data.payload.notificationList,
					lastRecordSize,
					requestRecordSize: res.data.payload.notificationList.length,
					token: res.data.token,
					keyword: "",
				};
				dispatch(NOTIFICATION_SEARCH_SUCCESS(response));
				if (requestRecordSize > res.data.payload.notificationList.length) {
					dispatch(NOTIFICATION_SEARCH_END());
				}
				if (lastRecordSize >= 50) {
					dispatch(NOTIFICATION_SEARCH_END());
				}
			} else if (res.status === 202) {
				dispatch(NOTIFICATION_SEARCH_END());
			} else {
				dispatch(NOTIFICATION_SEARCH_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
			dispatch(NOTIFICATION_SEARCH_FAIL());
		});
};

export const eventNotificationSearch = ({
	acctId,
	accessToken,
	email,
	lastRecordSize,
	requestRecordSize,
	keyword,
	bizId,
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		email,
		lastRecordSize,
		requestRecordSize,
		keyword,
		bizId,
	};
	// console.log(body);
	dispatch(NOTIFICATION_LOADING());
	if (lastRecordSize === 0) {
		dispatch(DEFAULT_NOTIFICATION_SEARCH());
	}
	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/nm/search-notification`,
		params: body,
	})
		.then((res) => {
			// console.log(res)
			if (res.status === 201) {
				const response = {
					searchResult: res.data.payload.notificationList,
					lastRecordSize,
					requestRecordSize: res.data.payload.notificationList.length,
					token: res.data.token,
					keyword: keyword,
				};
				dispatch(NOTIFICATION_SEARCH_SUCCESS(response));
				if (requestRecordSize > res.data.payload.notificationList.length) {
					dispatch(NOTIFICATION_SEARCH_END());
				}
			} else if (res.status === 202) {
				dispatch(NOTIFICATION_SEARCH_END());
			} else {
				dispatch(NOTIFICATION_SEARCH_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const createEventNotification = ({
	acctId,
	accessToken,
	email,
	receiverId,
	eventTypeId,
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		email,
		receiverId,
		eventTypeId,
	};

	await axios({
		method: "POST",
		headers: header,
		url: `${instanceUrl}/api/nm/create-event`,
		data: body,
	})
		.then((res) => {
			console.log(res);

			const response = {
				token: res.data.token,
			};
			if (res.status === 201) {
				dispatch(NOTIFICATION_ADD_SUCCESS(response));
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const checkNotificationUnread = ({
	acctId,
	accessToken,
	email,
	bizId,
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		email,
		bizId,
	};
	// console.log(body)
	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/nm/check-unread-notification`,
		params: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				const response = {
					searchCount: res.data.payload.notificationCount,
					token: res.data.token,
				};
				dispatch(NOTIFICATION_CHECK_UNREAD(response));
			} else if (res.status === 202) {
				const response = {
					searchCount: res.data.payload.notificationCount,
					token: res.data.token,
				};
				dispatch(NOTIFICATION_CHECK_UNREAD_END(response));
			} else if (res.status === 203) {
				dispatch(logout(1));
			} else {
				dispatch(NOTIFICATION_CHECK_UNREAD_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
			// if (err.message == "Network Error") {
			// 	dispatch(logout(1));
			// }
		});
};

export const updateNotification = ({
	acctId,
	accessToken,
	email,
	notificationListId,
	eventListId,
	notificationStatus,
	item,
}) => async (dispatch) => {
	dispatch(NOTIFICATION_LOADING());
	dispatch(NOTIFICATION_NAVIGATE_LOADING());
	const body = {
		acctId,
		accessToken,
		email,
		notificationListId,
		eventListId,
		notificationStatus,
	};
	// console.log(item);
	await axios({
		method: "PUT",
		headers: header,
		url: `${instanceUrl}/api/nm/update-notification-status`,
		data: body,
	})
		.then((res) => {
			// console.log(res);
			if (res.status === 201) {
				const response = {
					notificationListId,
					token: res.data.token,
				};

				const viewProfile = async (item) => {
					console.log(item);
					if (item.applicationStatusId === 1) {
						const updateProfile = {
							acctId: acctId,
							accessToken: localStorage.getItem("token"),
							email: email,
							applicantId: item.receiptAcctId,
							applStatusId: 2,
							applId: item.recId,
						};
						console.log(updateProfile);
						dispatch(updateApplicantStatus(updateProfile));
					}
					if (item.eventTypeId === 1 || item.eventTypeId === 3) {
						const requestProfile = {
							acctId: item.receiptAcctId,
							userId: acctId,
							ipAddr: "",
							accessToken: localStorage.getItem("token"),
						};

						dispatch(getProfiles(requestProfile));
					}
				};
				viewProfile(item);

				dispatch(NOTIFICATION_UPDATE_SUCCESS(response));
				dispatch(NOTIFICATION_NAVIGATE_SUCCESS(item));
			} else {
				dispatch(NOTIFICATION_SEARCH_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const resetNotificationItem = () => async (dispatch) => {
	const dateNow = new Date();
	dispatch(NOTIFICATION_NAVIGATE_RESET(dateNow));
};

export const deleteNotification = ({
	acctId,
	accessToken,
	email,
	notificationListId,
	searchResult,
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		email,
		notificationListId,
		searchResult,
	};
	await axios({
		method: "DELETE",
		headers: header,
		url: `${instanceUrl}/api/nm/delete-notification`,
		data: body,
	})
		.then((res) => {
			if (res.status === 201) {
				searchResult = searchResult.filter(
					(item) => item.notificationListId !== notificationListId
				);
				const response = {
					searchResult,
					notificationListId,
					token: res.data.token,
				};
				dispatch(NOTIFICATION_DELETE_SUCCESS(response));
			} else {
				dispatch(NOTIFICATION_SEARCH_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const showNotification = () => async (dispatch) => {
	dispatch(NOTIFICATION_SHOW());
};

export default eventNotificationSlice.reducer;
