import React, { Fragment, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Input,
  IconButton,
  makeStyles,
  Popover,
} from "@material-ui/core";
import popover from "views/Component/popover.js";
import Comment from "./Comment.js";
import socialFeedStyles from "../../../assets/jss/material-kit-pro-react/views/componentStyle/socialFeed/socialFeedStyles.js";
import jobStyle from "assets/jss/material-kit-pro-react/views/componentStyle/socialFeedPosts/jobStyles.js";
import userProfile from "assets/images/userProfile.png";
import job from "assets/img/job.png";
import avatar from "assets/img/faces/avatar.jpg";
import postLeft from "assets/images/PostLeft.png";
import postRight1 from "assets/images/PostRight1.png";
import postRight2 from "assets/images/PostRight2.png";
import dotBtn from "assets/images/dotBtn.png";
import like from "assets/images/like.png";
import comment from "assets/images/comment.png";
import share from "assets/images/share.png";
import saved from "assets/images/saved.png";
import file from "assets/images/file.png";
import emoji from "assets/images/emoji.png";
import gallery from "assets/images/documentation.png";
import video from "assets/img/video.png";

const useStyles = makeStyles(socialFeedStyles);
const useJobStyles = makeStyles(jobStyle);

const Job = (props) => {
  const classes = useStyles();
  const jobClasses = useJobStyles();
  const ariaLabel = { "aria-label": "description" };

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorShareEl, setAnchorShareEl] = useState(null);

  return (
    <Fragment>
      <Card className={classes.socialFeedCard}>
        <CardContent className={classes.socialFeedCardContent}>
          <Typography component="div">
            <Typography component="div" className={jobClasses.postHeader}>
              <Typography component="div" className={classes.feedHeaderUserBx}>
                <Typography component="div" className={classes.feedUserImgBx}>
                  <img src={avatar} alt="" />
                </Typography>
                <Typography component="div" className={classes.feedUserNameBx}>
                  <Typography component="h6">
                    Ahmad Nur Fawaid
                    <Typography component="span">Recommended</Typography>
                  </Typography>
                  <Typography component="span">12 April at 09.28 PM</Typography>
                </Typography>
              </Typography>
              <button onClick={(e) => { setAnchorEl(e.currentTarget) }}>
                <img src={dotBtn} alt="" />
              </button>

              <Popover
                id={anchorEl ? "simple-popover" : undefined}
                open={anchorEl ? true : false}
                anchorEl={anchorEl}
                onClose={() => { setAnchorEl(null) }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <Typography component="div" className={classes.popoverListItem}>
                  <Typography component="div" className={classes.popoverListItemIcon}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                      <path id="Path" d="M10,20A10,10,0,1,1,20,10,10.013,10.013,0,0,1,10,20Z" transform="translate(1 1)" fill="#fff" stroke="#b6b7b8" stroke-width="2" />
                      <path id="Path-2" data-name="Path" d="M0,0H2V6H0Z" transform="translate(10 10)" fill="#b6b7b8" />
                      <path id="Path-3" data-name="Path" d="M0,0H2V2H0Z" transform="translate(10 6)" fill="#b6b7b8" />
                    </svg>
                  </Typography>
                  <Typography component="div" className={classes.popoverListItemText}>
                    <Typography component="p" className={classes.popoverListItemTitle}>
                      About OppTy Pte Ltd
                    </Typography>
                  </Typography>
                </Typography>

                <Typography component="div" className={classes.popoverListItem}>
                  <Typography component="div" className={classes.popoverListItemIcon}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="23.2" height="16" viewBox="0 0 23.2 16">
                      <path id="Path" d="M10.5,0A11.587,11.587,0,0,1,21,7a11.575,11.575,0,0,1-10.5,7A11.575,11.575,0,0,1,0,7,11.587,11.587,0,0,1,10.5,0" transform="translate(1.1 1)" fill="#fff" stroke="#b6b7b8" stroke-width="2" />
                      <path id="Path-2" data-name="Path" d="M3,0A3,3,0,1,1,0,3,3,3,0,0,1,3,0" transform="translate(8.6 5)" fill="none" stroke="#b6b7b8" stroke-width="2" />
                    </svg>

                  </Typography>
                  <Typography component="div" className={classes.popoverListItemText}>
                    <Typography component="p" className={classes.popoverListItemTitle}>
                      View all job recommendations
                    </Typography>
                  </Typography>
                </Typography>

                <Typography component="div" className={classes.popoverListItem}>
                  <Typography component="div" className={classes.popoverListItemIcon}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                      <g id="Path" transform="translate(0 0)" fill="#fff" stroke-miterlimit="10">
                        <path d="M7,0h8a7,7,0,0,1,7,7v8a7,7,0,0,1-7,7H7a7,7,0,0,1-7-7V7A7,7,0,0,1,7,0Z" stroke="none" />
                        <path d="M 7 2 C 4.242990493774414 2 2 4.242990493774414 2 7 L 2 15 C 2 17.75700950622559 4.242990493774414 20 7 20 L 15 20 C 17.75700950622559 20 20 17.75700950622559 20 15 L 20 7 C 20 4.242990493774414 17.75700950622559 2 15 2 L 7 2 M 7 0 L 15 0 C 18.86598968505859 0 22 3.134010314941406 22 7 L 22 15 C 22 18.86598968505859 18.86598968505859 22 15 22 L 7 22 C 3.134010314941406 22 0 18.86598968505859 0 15 L 0 7 C 0 3.134010314941406 3.134010314941406 0 7 0 Z" stroke="none" fill="#b5b5be" />
                      </g>
                      <g id="Path-2" data-name="Path" transform="translate(7 7)" fill="none" stroke-miterlimit="10">
                        <path d="M2.414,3.828.293,1.707A1,1,0,0,1,1.707.293L3.828,2.414,5.95.293A1,1,0,0,1,7.364,1.707L5.243,3.828,7.364,5.95A1,1,0,0,1,5.95,7.364L3.828,5.243,1.707,7.364A1,1,0,0,1,.293,5.95L2.414,3.828Z" stroke="none" />
                        <path d="M 1.000001907348633 -4.76837158203125e-07 C 1.255924701690674 -4.76837158203125e-07 1.511847019195557 0.09763193130493164 1.707107067108154 0.2928972244262695 L 3.828427076339722 2.414216995239258 L 5.949747085571289 0.2928972244262695 C 6.145007133483887 0.09763193130493164 6.40092945098877 -4.76837158203125e-07 6.656852245330811 -4.76837158203125e-07 C 6.912774562835693 -4.76837158203125e-07 7.168696880340576 0.09763193130493164 7.363956928253174 0.2928972244262695 C 7.754487037658691 0.6834168434143066 7.754487037658691 1.316586971282959 7.363956928253174 1.707107067108154 L 5.242637157440186 3.828427076339722 L 7.363956928253174 5.949747085571289 C 7.754487037658691 6.340267181396484 7.754487037658691 6.973437309265137 7.363956928253174 7.363956928253174 C 7.168696880340576 7.559222221374512 6.912774562835693 7.656854629516602 6.656852245330811 7.656854629516602 C 6.40092945098877 7.656854629516602 6.145007133483887 7.559222221374512 5.949747085571289 7.363956928253174 L 3.828427076339722 5.242637157440186 L 1.707107067108154 7.363956928253174 C 1.511847019195557 7.559222221374512 1.255924701690674 7.656854629516602 1.000001907348633 7.656854629516602 C 0.74407958984375 7.656854629516602 0.4881572723388672 7.559222221374512 0.2928972244262695 7.363956928253174 C -0.09763288497924805 6.973437309265137 -0.09763288497924805 6.340267181396484 0.2928972244262695 5.949747085571289 L 2.414216995239258 3.828427076339722 L 0.2928972244262695 1.707107067108154 C -0.09763288497924805 1.316586971282959 -0.09763288497924805 0.6834168434143066 0.2928972244262695 0.2928972244262695 C 0.4881572723388672 0.09763193130493164 0.74407958984375 -4.76837158203125e-07 1.000001907348633 -4.76837158203125e-07 Z" stroke="none" fill="#b5b5be" />
                      </g>
                    </svg>
                  </Typography>
                  <Typography component="div" className={classes.popoverListItemText}>
                    <Typography component="p" className={classes.popoverListItemTitle}>
                      Hide this job
                    </Typography>
                  </Typography>
                </Typography>
              </Popover>
            </Typography>

            <Typography component="div" className={jobClasses.jobHeader}>
              <Typography component="h3" className={jobClasses.jobTitle}>Full-Time UI/UX Designer</Typography>
              <Typography component="p" className={jobClasses.jobSalary}>
                <svg xmlns="http://www.w3.org/2000/svg" width="8.483" height="15" viewBox="0 0 8.483 15">
                  <path id="Path" d="M4.567,6.583c-1.892-.492-2.5-1-2.5-1.792,0-.908.842-1.542,2.25-1.542C5.8,3.25,6.35,3.958,6.4,5H8.242A3.322,3.322,0,0,0,5.567,1.825V0h-2.5V1.8C1.45,2.15.15,3.2.15,4.808c0,1.925,1.592,2.883,3.917,3.442,2.083.5,2.5,1.233,2.5,2.008,0,.575-.408,1.492-2.25,1.492-1.717,0-2.392-.767-2.483-1.75H0a3.491,3.491,0,0,0,3.067,3.192V15h2.5V13.208c1.625-.308,2.917-1.25,2.917-2.958C8.483,7.883,6.458,7.075,4.567,6.583Z" fill="#6d7278" />
                </svg>
                $2300 - $4000
              </Typography>
              <Typography component="p" className={jobClasses.jobType}>
                <svg xmlns="http://www.w3.org/2000/svg" width="8.483" height="15" viewBox="0 0 8.483 15">
                  <path id="Path" d="M4.567,6.583c-1.892-.492-2.5-1-2.5-1.792,0-.908.842-1.542,2.25-1.542C5.8,3.25,6.35,3.958,6.4,5H8.242A3.322,3.322,0,0,0,5.567,1.825V0h-2.5V1.8C1.45,2.15.15,3.2.15,4.808c0,1.925,1.592,2.883,3.917,3.442,2.083.5,2.5,1.233,2.5,2.008,0,.575-.408,1.492-2.25,1.492-1.717,0-2.392-.767-2.483-1.75H0a3.491,3.491,0,0,0,3.067,3.192V15h2.5V13.208c1.625-.308,2.917-1.25,2.917-2.958C8.483,7.883,6.458,7.075,4.567,6.583Z" fill="#6d7278" />
                </svg>
                Professional - Full-Time
              </Typography>
            </Typography>

            <Typography component="div" className={jobClasses.jobDescriptionContainer}>
              <Typography component="p" className={jobClasses.jobDescriptionHeading}>Job Requirements</Typography>
              <Typography component="p" className={jobClasses.jobDescription}>
                Looking for a full-time in-house designer for our new startup.
                We are seeking passioniate individuals who are keen on learning about the latest
                trends in UI/UX design and are able to work in a team environment.
                Strong communication skills are needed and we are will not providing training
                who are keen on learning about the latest trends in UI/UX design and are able to
                work in a team.
              </Typography>
            </Typography>

            {/* <Typography className={classes.feedTitle}>
              One of the perks of working in an international company is sharing
              knowledge with your colleagues.
            </Typography> */}

            <Typography component="div" className={jobClasses.jobGraphicalContent}>
              <Typography component="div" className={classes.postContentContainer}>

                {props.hasVideo ?
                  <img src={video} alt="" />
                  :
                  <img src={job} alt="" />
                }

              </Typography>
            </Typography>

            <Typography component="div" className={jobClasses.postActions}>
              <button className={jobClasses.applyNowBtn}>
                Apply Now
              </button>

              <button className={jobClasses.learnMoreBtn}>
                Learn More
              </button>

              <button className={jobClasses.regularAction}>
                <img src={saved} alt="" />
                Save Job
              </button>

              <button className={jobClasses.regularAction}>
                <img src={like} alt="" />
                120k Likes
              </button>

              <button className={jobClasses.regularAction} onClick={(e) => { setAnchorShareEl(e.currentTarget) }}>
                <img src={share} alt="" />
                Share
              </button>

              <Popover
                id={anchorShareEl ? "simple-popover" : undefined}
                open={anchorShareEl ? true : false}
                anchorEl={anchorShareEl}
                onClose={() => { setAnchorShareEl(null) }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                anchorPosition={{
                  top: 10,
                  left: 10,
                }}
                anchorReference='anchorEl'
              >
                <Typography component="div" className={classes.popoverListItem}>
                  <Typography component="div" className={classes.popoverListItemText}>
                    <Typography component="p" className={classes.popoverListItemTitle}>
                      Share to feed
                    </Typography>
                    <Typography component="p" className={classes.popoverListItemDescription}>
                      Share this post on your feed
                    </Typography>
                  </Typography>
                </Typography>

                <Typography component="div" className={classes.popoverListItem}>
                  <Typography component="div" className={classes.popoverListItemText}>
                    <Typography component="p" className={classes.popoverListItemTitle}>
                      Write post
                    </Typography>
                    <Typography component="p" className={classes.popoverListItemDescription}>
                      Compose a post with this content
                    </Typography>
                  </Typography>
                </Typography>

                <Typography component="div" className={classes.popoverListItem}>
                  <Typography component="div" className={classes.popoverListItemText}>
                    <Typography component="p" className={classes.popoverListItemTitle}>
                      Share to Chat
                    </Typography>
                  </Typography>
                </Typography>

                <Typography component="div" className={classes.popoverListItem}>
                  <Typography component="div" className={classes.popoverListItemText}>
                    <Typography component="p" className={classes.popoverListItemTitle}>
                      Share to Group
                    </Typography>
                  </Typography>
                </Typography>

                <Typography component="div" className={classes.popoverListItem}>
                  <Typography component="div" className={classes.popoverListItemText}>
                    <Typography component="p" className={classes.popoverListItemTitle}>
                      Copy Post Link
                    </Typography>
                  </Typography>
                </Typography>
              </Popover>
            </Typography>

            <Typography component="div" className={classes.feedCommentBx}>
              <Typography component="div" className={classes.feedCommentUserBx}>
                <img src={avatar} alt="" />
              </Typography>
              <Typography component="div" className={classes.commentInputWrap}>
                <Input
                  placeholder="Write your comment"
                  inputProps={ariaLabel}
                />
                <Typography
                  component="div"
                  className={classes.commentActionBtns}
                >
                  {/* <IconButton>
                    <img src={file} alt="" />
                  </IconButton> */}
                  <IconButton>
                    <img src={emoji} alt="" />
                  </IconButton>
                  <IconButton>
                    <img src={gallery} alt="" />
                  </IconButton>
                </Typography>
              </Typography>
            </Typography>

            <Typography component="div" className={classes.postCommentsMain}>
              {/* <Typography component="div" className={classes.recentCommentsButton}>
                <button>Most recent comments</button>
              </Typography> */}

              <Comment haveComments={false} />

              {/* <Typography component="div" className={classes.moreComments}>
                <button>
                  Load more comments
                </button>
              </Typography> */}
            </Typography>
          </Typography>
        </CardContent>
      </Card>
    </Fragment >
  )
}

export default Job;