import React from "react";
import GridItem from "components/Grid/GridItem.js";
import FavProfileCard from "./FavProfileCard";
import GridContainer from "components/Grid/GridContainer.js";
import {
	createChatroom,
	createChatroomReset,
} from "configurations/redux/messageChatroomSlice.js";
import { useSelector, useDispatch } from "react-redux";

export default function FavProfileSection({ profileList }) {
	const dispatch = useDispatch();
	const userStatus = useSelector((state) => state.auth.user);
	const bizId = useSelector((state) => state.business.bizId);
	const getMessageNavigate = async (item) => {
		var chatroomList = [];
		chatroomList.push({ chatroomListId: -1, acctId: userStatus.acctId });
		chatroomList.push({ chatroomListId: -1, acctId: item });
		const messageApplicant = {
			acctId: userStatus.acctId,
			accessToken: localStorage.getItem("token"),
			email: userStatus.email,
			chatroomTitle: "business_" + item + "_" + bizId,
			chatroomList: chatroomList,
			receiverId: item,
			bizId: bizId,
			receiverBizId: 0,
			typeId: 2,
		};
		console.log(messageApplicant);
		dispatch(createChatroom(messageApplicant));
	};

	const chatroomId = useSelector((state) => state.messageChatroom.chatroomId);
	const isCreated = useSelector((state) => state.messageChatroom.isCreated);
	React.useEffect(() => {
		console.log(chatroomId);
		if (isCreated && chatroomId > 0) {
			// window.open("/message-page/C" + chatroomId + "/", "_blank");
			window.open(`/message-page/${bizId}/C${chatroomId}/`, "_blank");
			// history.push("/message-page/C" + chatroomId + "/");
		}
		dispatch(createChatroomReset());
	}, [isCreated]);

	const ResultCard = profileList.map((item, index) => {
		return (
			<GridItem xs={12} sm={12} md={6} key={index}>
				<FavProfileCard
					key={item.profileId}
					item={item}
					getMessageNavigate={getMessageNavigate}
				/>
			</GridItem>
		);
	});

	return (
		<React.Fragment>
			<GridContainer>{profileList ? ResultCard : null}</GridContainer>
		</React.Fragment>
	);
}
