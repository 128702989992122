const pendingApprovalStyle = (theme) => ({
  title: {
    color: "#000000",
    fontSize: "25px",
    fontWeight: "bold",
    marginTop: "50px",
    textAlign: "center",
    display: "flex",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
  },
  cardsContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: "30px",
  },
  cardContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "white",
    width: "500px",
    height: "200px",
    borderRadius: "20px",
    alignItems: "center",
  },
  imgContainer: {
    backgroundColor: "#FC5A5A",
    borderRadius: "60px",
    height: "130px",
    width: "130px",
    marginLeft: "40px",
  },
  titleContainer2: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "25px",
  },
  txt1: {
    color: "#171725",
    fontSize: "14px",
    fontWeight: "600",
  },
  txt2: {
    color: "#4A4A4A",
    fontSize: "16px",
    fontWeight: "400",
  },
  btnContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "36px",
    width: "100px",
    backgroundColor: "#687BFE",
    border: "0px",
    borderRadius: "20px",
  },
  btnTxt: {
    fontSize: "14px",
    fontWeight: "400",
    color: "white",
  },
  btnParentContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: "20px",
    alignItems: "center",
  },
  btnContainer2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "36px",
    width: "100px",
    backgroundColor: "#FFFFFF",
    border: "2px solid #979797",
    borderRadius: "20px",
    marginLeft: "15px",
  },
  btnTxt2: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#929293",
  },
  img: {
    height: "8px",
    width: "36px",
    marginLeft: "15px",
    display: "flex",
    marginRight: "20px",
  },
  txt3: {
    color: "#0AB98D",
    fontSize: "14px",
    fontWeight: "500",
    marginTop: "20px",
  },
  txt4: {
    color: "#0062FF;",
    fontSize: "14px",
    fontWeight: "500",
    marginTop: "20px",
  },
});

export default pendingApprovalStyle;
