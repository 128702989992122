import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import axios from "axios";

import { Box, makeStyles, Modal, Typography } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import { errorCleanUp } from "configurations/redux/authSlice.js";

import Header from "views/Component/Header.js";
import EditProfile from "views/Component/ProfileView/EditProfile/EditProfile";
import Footer from "views/Component/Footer.js";
import RightSideBarUserList from "views/Component/RightSideBarUserList.js";
import LeftSidebar from "views/Component/LeftSidebar.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Hidden from "@material-ui/core/Hidden";
import resumePageStyles from "assets/jss/material-kit-pro-react/views/pageStyle/ResumePage/resumePageStyles";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js";
import { getAllPsychometricTest, submitUserPsychometricTest, getUserPsychometricResults } from "../../../configurations/redux/userSlice";
import businessPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/BusinessPage/businessPageStyle";
import psycompleted from "assets/img/completed.png";
import Checkbox from '@mui/material/Checkbox';
import Grid from '@material-ui/core/Grid'


import PsychometricTestLanding from "./PsychometricTestLanding";

const ResultView = ({ match }) => {
   const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

   const styles = makeStyles(resumePageStyles)();
   const stylesBusiness = makeStyles(businessPageStyle)();
   const classes = makeStyles(profilePageStyle)(); //profilePageStyle

   const history = useHistory();
   const dispatch = useDispatch();
   const isError = useSelector((state) => state.auth.isError);
   const userStatus = useSelector((state) => state.auth.user);
   const user = useSelector((state) => state.user);
   const allResults = useSelector((state) => state.user.psychometricUserResults);
   const [myself, setMyself] = useState(false);
   const [allPsychometricQuestions, setAllPsychometricQuestions] = useState([]);
   const [questionsAnswers, setQuestionsAnswers] = useState();
   const [allAnswers, setAllAnswers] = useState([]);
   const [createPost, setCreatePost] = React.useState(false);
   const [width, height] = useWindowSize();
   const [answerDone, setAnswersDone] = useState([]);
   const [openQuestionsFlow, setOpenQuestionsFlow] = React.useState(false);
   const [openAbortQuiz, setOpenAbortQuiz] = React.useState(false);
   const [open105, setOpen105] = React.useState(false);
   const handleOpen105 = () => setOpen105(true);
   const handleClose105 = () => setOpen105(false);
   const style01 = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 550,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
   };
   const style02 = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
   };

   const abortQuizNow = () => {
      setOpenAbortQuiz(true);
      setOpenQuestionsFlow(false);
   };
   const handleCloseAbortQuiz = () => setOpenAbortQuiz(false);
   const handleOpenQuestionsFlow = () => {
      setOpenQuestionsFlow(true);
      initializeData();
   }
   const handleCloseQuestionsFlow = () => setOpenQuestionsFlow(false);

   React.useEffect(() => {
      if (isError) {
         dispatch(errorCleanUp());
      }


      initializeData();

   }, []);

   function initializeData() {
      dispatch(getAllPsychometricTest({}, (result) => {
         if (result.length > 0) {
            setAllPsychometricQuestions(result);
            setQuestionsAnswers(result[0]);
            setAllAnswers(result[0].answer);
         }
      }));
   }
   console.log(questionsAnswers)
   const clickAnswerSelection = (ansId, questId) => {
      setAnswersDone(answerDone => [...answerDone, { "quesId": questId, "ansId": ansId }]);

      let views = allAnswers.slice();

      for (let i in views) {
         views[i].class = 'greyOuter';
         if (views[i].ansId == ansId) {
            views[i].class = 'blueOuter';
         } else {
            views[i].class = 'greyOuter';
         }
         setAllAnswers(views);
      }

      setTimeout(callSecondFunction(), 9000);

   };

   function callSecondFunction() {
      if (questionsAnswers.quesId != questionsAnswers.totalCount) {
         allPsychometricQuestions && allPsychometricQuestions.length > 0 && allPsychometricQuestions.map((ans, index) => {
            if (ans.quesId == questionsAnswers.quesId) {
               if (allPsychometricQuestions[index + 1]) {
                  setQuestionsAnswers(allPsychometricQuestions[index + 1]);

                  allPsychometricQuestions[index + 1] && (allPsychometricQuestions[index + 1]).answer.map((val, index) => {
                     var tempProps = JSON.parse(JSON.stringify(val));
                     tempProps.class = 'greyOuter';
                     Object.preventExtensions(tempProps);

                  });
                  setAllAnswers(allPsychometricQuestions[index + 1].answer);
               }

            }
         });
      }
   }

   const submitPsychometricTest = () => {
      const payloadRequested = {
         acctId: userStatus.acctId,
         email: userStatus.email,
         accessToken: localStorage.getItem('token'),
         results: answerDone
      }

      dispatch(submitUserPsychometricTest(payloadRequested, (result) => {
         //if (result.payload.updateResult) {
         setOpenQuestionsFlow(false);
         setOpen105(true);
         //}
      }));
   }

   const openQuizAgain = () => {
      setOpenAbortQuiz(false);

      setOpenQuestionsFlow(true);
   }

   const confirmToAbort = () => {
      // alert('aa')
      setOpenAbortQuiz(false);

      setAllAnswers([]);
      setQuestionsAnswers({});
   }

   const moveToResults = () => {
      setOpen105(false);

   }
   function previousQuestion() {
      if (questionsAnswers.quesId != questionsAnswers.totalCount) {
         allPsychometricQuestions && allPsychometricQuestions.length > 0 && allPsychometricQuestions.map((ans, index) => {
            console.log(ans.quesId, questionsAnswers.quesId)
            if (ans.quesId == questionsAnswers.quesId) {
               if (allPsychometricQuestions[index - 1]) {
                  setQuestionsAnswers(allPsychometricQuestions[index - 1]);

                  allPsychometricQuestions[index + 1] && (allPsychometricQuestions[index + 1]).answer.map((val, index) => {
                     var tempProps = JSON.parse(JSON.stringify(val));
                     tempProps.class = 'greyOuter';
                     Object.preventExtensions(tempProps);

                  });
                  setAllAnswers(allPsychometricQuestions[index + 1].answer);
               }

            }
         });
      }
   }
   function useWindowSize() {
      const [size, setSize] = React.useState([0, 0]);
      React.useLayoutEffect(() => {
         function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
         }
         window.addEventListener("resize", updateSize);
         updateSize();
         return () => window.removeEventListener("resize", updateSize);
      }, []);
      return size;
   }
   const [colorId, setColorId] = useState(0)
   const color = ['#1C4EA7', '#F06A40', '#6F52ED', '#097057']
   let x = 0


   React.useEffect(() => {
      if (isError) {
         dispatch(errorCleanUp());
      }

      callResults();
   }, []);

   function callResults() {
      const request = {
         email: userStatus.email,
         acctId: userStatus.acctId,
         accessToken: localStorage.getItem('token')
      };
      dispatch(getUserPsychometricResults(request));
   }

   const responseReceived = (response) => {
      console.log(response);
      if (response == 1) {
         callResults();
      }
   }

   return (
      <div>
         <Header color={"transparent"} loggedInStatus={false} />

         <div className={styles.body}>
            <div id="pageHeader" className={styles.pageHeader}>
               <div className={styles.container}>
                  <GridContainer justifyContent="center" spacing={2}>
                     <Hidden mdDown>
                        <GridItem md={2} lg={2}>
                           <LeftSidebar />
                        </GridItem>
                     </Hidden>

                     <GridItem xs={12} sm={12} md={8} lg={8}>
                        {allResults && allResults.resultType.totalCount > 0 && <div className="ResultViewOter">
                           <div className="psycompletedtext">
                              <h4>Your Psychometric Test</h4>
                              {allResults && allResults.resultType && <p><span className="leftOut">{allResults.resultType.totalCount} Out of 3 Tries {allResults.resultType.totalCount < 3 ? `left` : ''}</span>
                                 {allResults.resultType.totalCount < 3 && <Button variant="contained" onClick={handleOpenQuestionsFlow} >Retake Test</Button>}
                              </p>}
                           </div>

                           {/* <div className="checkinner">
                              <Checkbox {...label} defaultChecked /><span>Show my results to public
                              </span>
                           </div> */}
                           <hr className="hrouter" />
                           {allResults && allResults.resultType && <div className="analish">
                              <h4>{allResults.resultType.name}</h4>
                              <p>{allResults.resultType.description}</p>
                           </div>}
                           <div className="introMain">
                              <GridContainer justifyContent="center" spacing={2}>

                                 {allResults && allResults.traitList && allResults.traitList.length > 0 && allResults.traitList.map((trait, index) => {
                                    return (<GridItem xs={12} sm={12} md={6} lg={6}>
                                       <div className="introOuter" style={{ borderColor: color[index] }}>
                                          <h4 style={{ backgroundColor: color[index] }}>{trait.traitName}</h4>
                                          <div className="description">
                                             <div className="nameOuter">
                                                <span style={{ backgroundColor: color[index] }}>{trait.traitShort}</span>
                                             </div>
                                             <div className="rightDes">
                                                <p>{trait.traitDescription}</p>
                                             </div>
                                          </div>
                                       </div>
                                    </GridItem>)

                                 })}

                                 <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <div className="outerstrengths">
                                       <h3>Strengths</h3>
                                       {allResults && allResults.strengthList && allResults.strengthList.length > 0 && allResults.strengthList.map((strength, index) => {
                                          return (<div className="innerheading">
                                             <h5>{strength.strengthTitle}</h5>
                                             <p>{strength.strengthDescription}</p>
                                          </div>)
                                       })}
                                    </div>
                                 </Grid>
                              </GridContainer>
                           </div>

                        </div>}

                        {allResults && allResults.resultType.totalCount == 0 && <PsychometricTestLanding setModalResponse={(val) => responseReceived(val)} />}
                     </GridItem>

                     <Hidden mdDown>
                        <GridItem md={2}>
                           <RightSideBarUserList
                              createPostClick={setCreatePost}
                              isOwner={myself}
                           />
                        </GridItem>
                     </Hidden>
                  </GridContainer>

                  <Modal
                     open={openQuestionsFlow}
                     // open={true}
                     onClose={handleCloseQuestionsFlow}
                     aria-labelledby="modal-modal-title"
                     aria-describedby="modal-modal-description"
                  >
                     <Box className='PsychometricModal' sx={style01}>
                        <div className='PsychometricModalinner'>
                           <a className='crossBtn' onClick={handleCloseQuestionsFlow}><i className="fas fa-xmark"></i></a>
                           {questionsAnswers && <Typography className='innerText PsychometricText'>
                              <h4>Question {questionsAnswers.quesId} of {questionsAnswers.totalCount}</h4>
                              <h5>{questionsAnswers.question}</h5>
                              {allAnswers && allAnswers.length > 0 && allAnswers.map((questAnswer, index) => {
                                 return (
                                    <div className={`${questAnswer.class}`} onClick={() => { clickAnswerSelection(questAnswer.ansId, questionsAnswers.quesId) }}>
                                       <p>{questAnswer.answer}</p>
                                    </div>)
                              })}
                           </Typography>}

                           <Typography component='div' className='MuiTypography-root dayOuter btnOuter rejectShortlist interviewBtns MuiTypography-body2'>
                              <Typography component='div' className='dayRight '>
                                 <Button type="button" className='backBTN' onClick={previousQuestion}>Back</Button>
                              </Typography>

                              {questionsAnswers && <Typography component='div' className='dayLeft psychometricleft'>
                                 {(questionsAnswers.quesId != questionsAnswers.totalCount) && <Button type="button" className='applyBtn psychometricleft12' onClick={abortQuizNow}>Abort quiz</Button>}

                                 {(questionsAnswers.quesId == questionsAnswers.totalCount) && <Button type="button" className='applyBtn psychometricleft12' onClick={submitPsychometricTest}>Submit</Button>}
                              </Typography>}
                           </Typography>
                        </div>
                     </Box>
                  </Modal>

                  <Modal
                     open={openAbortQuiz}
                     onClose={handleCloseAbortQuiz}
                     aria-labelledby="modal-modal-title"
                     aria-describedby="modal-modal-description"
                  >
                     <Box className='psychometricleftQuik' sx={style02}>
                        <Typography className='innerText PsychometricText'>

                           <div className="quiz">
                              <h5>Abort Quiz?</h5>
                              <p>Your answers will not be saved and published.</p>
                           </div>

                        </Typography>
                        <Typography component='div' className='MuiTypography-root dayOuter btnOuter rejectShortlist interviewBtns MuiTypography-body2'>
                           <Typography component='div' className='dayRight '>
                              <Button type='button' className='backBTN' onClick={openQuizAgain}>Go back to quiz</Button>
                           </Typography>
                           <Typography component='div' className='dayLeft psychometricleft'>
                              <Button type='button' className='applyBtn' onClick={confirmToAbort}>Confirm</Button>
                           </Typography>
                        </Typography>
                     </Box>
                  </Modal>

                  <Modal
                     open={open105}
                     // open={true}
                     onClose={handleClose105}
                     aria-labelledby="modal-modal-title"
                     aria-describedby="modal-modal-description"
                  >
                     <Box className='PsychometricModal FinalPsych' sx={style01}>
                        <div className='psycinnerpopup'>
                           {/* <a className='crossBtn'>
                           <i class="fas fa-xmark"></i></a> */}
                           <Typography className='innerText psyCompletd'>
                              <img src={psycompleted} />
                              <h5>You have completed the<br /> Psychometric Test! </h5>
                           </Typography>

                           <Button variant="contained" className="seeyour"><a onClick={moveToResults}>See your results</a></Button>
                        </div>
                     </Box>
                  </Modal>
               </div>
            </div>
         </div>
      </div>

   );
};

export default ResultView;
