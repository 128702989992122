import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import loginPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/LoginPage/loginPageStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Logo from "assets/images/logo.png";
import Uimg from "assets/images/uimg.png";
import Cloud from "assets/images/cloud.png";
import { Typography } from '@material-ui/core';
import { decryptData, encryptData } from 'configurations/configurations.js';
import { getUserSharedAttachmentByFolderId } from 'configurations/redux/attachmentUserSlice';
import { getProfiles } from "configurations/redux/userSlice.js";

const useStyles = makeStyles(loginPageStyle);



//import { getVideoDurationInSeconds } from 'get-video-duration';


const UserDownloadAttachment = ({ match }) => {


    const history = useHistory();
    const dispatch = useDispatch();
    const userStatus = useSelector(state => state.auth.user);
    var user = useSelector((state) => state.user);
    console.log('=========user-------');
    console.log(user);
    const [createAcctId, setCreateAcctId] = React.useState(false);



    /*loaderstart start*/
    const [openLoader, setOpenLoader] = React.useState(false);
    const showLoader = () => {
        setOpenLoader(true);
    };
    const hideLoader = () => {
        setOpenLoader(false);
    };
    /*loaderstart end*/

    /*sourabh start*/

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };


    //console.log(match);
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        history.replace(match.url);
    }, []);




    //setCurrLocation(match.params.type);

    React.useEffect(() => {

    }, []);


    const sharedAttachmentList = useSelector(state => state.attachmentUser.sharedAttachmentList);
    console.log('sharedAttachmentList');
    console.log(sharedAttachmentList);
    React.useEffect(() => {
        const folderId = decryptData(match.params.recId);

        const request121 = {


            // email: userStatus.email,
            //acctId: userStatus.acctId,
            //accessToken: localStorage.getItem('token'),
            folderId: folderId,
            lastRecordSize: 0,
            requestRecordSize: 100
        };
        dispatch(getUserSharedAttachmentByFolderId(request121));



    }, []);

    React.useEffect(() => {

        if (sharedAttachmentList && sharedAttachmentList[0] && sharedAttachmentList[0].createAcctId) {
            const requestAcrchiveProfile = {
                acctId: sharedAttachmentList[0].createAcctId,
                email: '',//userStatus.email,
                userId: sharedAttachmentList[0].createAcctId,
                accessToken: localStorage.getItem("token"),
            };
            dispatch(getProfiles(requestAcrchiveProfile));
        }

    }, [sharedAttachmentList]);


    //End video code








    return (
        <>
            <div>
                <div
                    id="pageHeader"
                    style={{
                        backgroundSize: "cover",
                        backgroundPosition: "top center",
                    }}
                >
                    <div className='headerUser'>
                        <img className='takeTestlogoImg' src={Logo} alt='logo' />
                        <div className='userHeaderRight'>
                            <a href="#">Looking to hire the best candidates? </a>
                            <Button className='signupoppty' href={`/registration-page`}>Sign up for OppTy Now!</Button>
                        </div>
                    </div>
                    <div className='takeTestDiv'>
                        <GridContainer
                            justifyContent="center"
                            alignContent="center"
                            alignItems="center"
                        >
                            <GridItem xs={12} sm={12} md={4}>
                                <div className='takeTestDivLeft downloadDiv'>
                                    <img className='cloud' src={Cloud} alt='cloud' />
                                    <div className='downloadTitle'>{user && user.profileRecord && user.profileRecord.firstName ? user.profileRecord.firstName : 'Person'} has shared the following files with you:</div>

                                    {
                                        sharedAttachmentList && sharedAttachmentList.length > 0 &&

                                        sharedAttachmentList.map(function (item, index) {
                                            return (<div className='downloadTitle pdf'>{item.title}</div>)

                                        })
                                    }


                                    <div className='expireDiv'>
                                        <p>Expires in 7 days.</p>
                                        <Typography className='retakeResultBtns'>
                                            <a href={sharedAttachmentList && sharedAttachmentList[0].shareLink ? sharedAttachmentList[0].shareLink : ''} className='takeTest takethetest'>Download Files</a>
                                        </Typography>
                                    </div>
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={8}>
                                <div className='takeTestDivLeft rightTest'>
                                    <img className='limg' src={Uimg} alt='Uimg' />
                                </div>
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserDownloadAttachment;
