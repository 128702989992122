import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIdleTimer } from "react-idle-timer";
import CustomDialogWithTimer from "../../components/CustomDialog/CustomDialogWithTimer.js";
import CustomDialogNoCloseButton from "../../components/CustomDialog/CustomDialogNoCloseButton.js";
import {
	emptyCache,
	emptyCacheNoReload,
} from "../../configurations/configurations.js";
import {
	logout,
	refreshToken,
	checkAppVersion,
	checkAppVersionReset,
	publicMode,
} from "../../configurations/redux/authSlice.js";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
require("dotenv").config();

export default function IdleTimerContainer() {
	const dispatch = useDispatch();
	const location = useLocation();
	const tokenTimeoutDuration = process.env.REACT_APP_TOKEN_TIMEOUT_DURATION;
	const idleTimeoutDuration = process.env.REACT_APP_IDLE_TIMEOUT_DURATION;
	const activeTimeoutDuration = process.env.REACT_APP_ACTIVE_TIMEOUT_DURATION;
	const timeOut = process.env.REACT_APP_COUNT_DOWN_DURATION;
	const userStatus = useSelector((state) => state.auth.user);
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
	const isTokenExpired = useSelector((state) => state.auth.isTokenExpired);
	const isForceRefresh = useSelector((state) => state.auth.isForceRefresh);
	const isPublic = useSelector((state) => state.auth.isPublic);
	const dialogDefault = {
		title: "",
		description: "",
		buttonYes: "",
		buttonNo: "",
		countDown: 0,
	};
	const [confirmDialogVersion, setConfirmDialogVersion] = React.useState({
		isOpen: false,
		dialogDefault,
	});

	React.useEffect(() => {
		if (isForceRefresh) {
			localStorage.setItem("idleProcess", "stop");
			const dialogDefault = {
				title: "Warning",
				description:
					"The new version of system is detected and page refresh is required. Kindly click the button below to continue. Thank you.",
				buttonYes: "",
				buttonNo: "Proceed",
			};
			setConfirmDialogVersion({
				isOpen: true,
				dialogDefault,
				onCancel: () => {
					setConfirmDialogVersion({
						isOpen: false,
						dialogDefault,
					});
					localStorage.setItem("idleProcess", "start");
					Cookies.remove("isKicked");
					dispatch(checkAppVersionReset());
					setTimeout(() => {
						emptyCache();
					}, 1000);
				},
			});
		}
	}, [isForceRefresh]);

	const [confirmDialog, setConfirmDialog] = useState({
		isOpen: false,
		dialogDefault,
	});
	const [isIdle, setIsIdle] = useState(false);
	const handleOnIdle = (event) => {
		if (!isPublic) {
			console.log("idle");
			var token = localStorage.getItem("token");
			var idleProcess = localStorage.getItem("idleProcess");
			if (idleProcess != "stop") localStorage.setItem("idleProcess", "pause");
			else if (idleProcess == "stop" && token != null) {
				dispatch(logout(1));
				emptyCacheNoReload();
			}
			if (!isIdle && isAuthenticated) {
				var countDownDefault = timeOut * 60;
				var timerCountDown = setInterval(() => {
					var idleProcess = localStorage.getItem("idleProcess");
					if (idleProcess == "start") {
						clearInterval(timerCountDown);
					}
					countDownDefault = countDownDefault - 1;
					if (countDownDefault <= 0 || idleProcess == "stop") {
						clearInterval(timerCountDown);
						dispatch(logout(1));
						emptyCacheNoReload();
					}
				}, 1000);
				var dialogDefault = {
					title: "Warning",
					description:
						"You've been idle for a while and you will be logged out after",
					buttonYes: "Keep me signed in",
					buttonNo: "Log me out",
					countDown: timeOut * 60,
				};
				setConfirmDialog({
					isOpen: true,
					dialogDefault,
					checkToken: true,
					onConfirm: () => {
						const requestInfo = {
							acctId: userStatus.acctId,
							email: userStatus.email,
							accessToken: localStorage.getItem("token"),
						};
						dispatch(refreshToken(requestInfo));
						localStorage.setItem("idleProcess", "start");
						clearTimeout(timerCountDown);
						setConfirmDialog({
							isOpen: false,
							dialogDefault,
						});
						setIsIdle(false);
					},
					onCancel: () => {
						dispatch(logout(0));
						clearTimeout(timerCountDown);
						setConfirmDialog({
							isOpen: false,
							dialogDefault,
						});
						setIsIdle(false);
						emptyCacheNoReload();
					},
				});
				setIsIdle(true);
			}
		}
	};

	const handleOnAction = (event) => {
		var idleProcess = localStorage.getItem("idleProcess");
		if (idleProcess && !idleProcess == "stop") {
			var token = localStorage.getItem("token");
			localStorage.setItem("totalActiveTime", Date.now());
			// var idleProcess = localStorage.getItem("idleProcess");
			var lastActiveTime = localStorage.getItem("lastActiveTime");
			var totalActiveTime = localStorage.getItem("totalActiveTime");
			var diffTime = totalActiveTime - lastActiveTime;
			// console.log("login", isPublic, isAuthenticated, idleProcess, userStatus);
			// console.log(
			// 	"user did something",
			// 	idleProcess,
			// 	millisToMinutesAndSeconds(diffTime),
			// 	millisToMinutesAndSeconds(getRemainingTime())
			// );
			if (!isAuthenticated) {
				dispatch(checkAppVersion());
			}
			if (idleProcess == "stop" && token != null) {
				dispatch(logout(1));
				emptyCacheNoReload();
			}
			if (
				diffTime > 1000 * 60 * tokenTimeoutDuration &&
				idleProcess == "pause"
			) {
				dispatch(logout(1));
				emptyCacheNoReload();
			} else if (diffTime > 1000 * 60 * tokenTimeoutDuration) {
				Cookies.remove("isKicked");
			}
			if (isAuthenticated && token != null && idleProcess == "start") {
				if (diffTime > 1000 * 60 * activeTimeoutDuration && !isIdle) {
					const requestInfo = {
						acctId: userStatus.acctId,
						email: userStatus.email,
						accessToken: localStorage.getItem("token"),
					};
					dispatch(refreshToken(requestInfo));
					localStorage.setItem("lastActiveTime", Date.now());
					localStorage.setItem("idleProcess", "start");
				}
			}
		} else {
			// console.log("public");
			if (!isAuthenticated && location.pathname.includes("public-")) {
				var lastActiveTime = localStorage.getItem("lastActiveTime");
				var totalActiveTime = localStorage.getItem("totalActiveTime");
				var diffTime = totalActiveTime - lastActiveTime;
				console.log(diffTime);
				if (diffTime > 1000 * 60 * activeTimeoutDuration && !isIdle) {
					console.log("refresh public token");
					// const requestInfo = {
					// 	acctId: 0,
					// 	email: publicEmail,
					// 	accessToken: publicToken,
					// };
					dispatch(publicMode());
					localStorage.setItem("lastActiveTime", Date.now());
					localStorage.setItem("idleProcess", "start");
				}
			}
		}
	};

	const handleOnActive = (event) => {
		// setConfirmDialog({
		// 	isOpen: false,
		// 	dialogDefault,
		// });
		setIsIdle(false);
	};

	const millisToMinutesAndSeconds = (millis) => {
		var minutes = Math.floor(millis / 60000);
		var seconds = ((millis % 60000) / 1000).toFixed(0);
		//ES6 interpolated literals/template literals
		//If seconds is less than 10 put a zero in front.
		return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
	};

	const { getRemainingTime, getTotalActiveTime, reset, pause } = useIdleTimer({
		type: "localStorage",
		channelName: "idle-timer",
		timeout: 1000 * 60 * idleTimeoutDuration,
		onIdle: handleOnIdle,
		onAction: handleOnAction,
		onActive: handleOnActive,
		eventsThrottle: 2000,
		fallbackInterval: 2000,
		debounce: 500,
		crossTab: {
			type: "localStorage",
			channelName: "idle-timer",
			emitOnAllTabs: true,
		},
	});
	// console.log("start");

	React.useEffect(() => {
		if (isTokenExpired) {
			dispatch(logout(1));
			emptyCacheNoReload();
		}
	}, [isTokenExpired]);

	React.useEffect(() => {
		console.log("load");
		if (!isAuthenticated) {
			dispatch(checkAppVersion());
			var checkVersionTimer = setInterval(() => {
				dispatch(checkAppVersion());
			}, 1000 * 60);
			return () => clearInterval(checkVersionTimer);
		}
	}, []);

	return (
		<div>
			{isAuthenticated ? (
				<CustomDialogWithTimer
					confirmDialog={confirmDialog}
					setConfirmDialog={setConfirmDialog}
				/>
			) : null}
			<CustomDialogNoCloseButton
				confirmDialog={confirmDialogVersion}
				setConfirmDialog={setConfirmDialogVersion}
			/>
		</div>
	);
}
