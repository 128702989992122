import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import auth from "./authSlice.js";
import user from "./userSlice.js";
import corpUser from "./corpUserSlice.js";
import jobManagement from "./jobManagementSlice.js";
import searchProfile from "./searchProfileSlice.js";
import searchJob from "./searchJobSlice.js";
import favProfile from "./favProfileSlice.js";
import jobApplicationManagement from "./jobApplicationManagementSlice.js";
import favJob from "./favJobSlice.js";
import appliedJob from "./appliedJobSlice.js";
import roadmap from "./roadmapSlice.js";
import masterFunction from "./masterFunctionSlice.js";
import peopleYouMayKnow from "./peopleYouMayKnowSlice.js";
import recommendedGroups from "./recommendedGroupsSlice.js";
import checkDuplicate from "./checkDuplicateSlice.js";
import getUserAttachmentType from "./getUserAttachmentTypeSlice.js";
import getUserAttachmentByAcctId from "./getUserAttachmentByAcctIdSlice.js";
import createUserAttachment from "./createUserAttachmentSlice.js";
import deleteUserAttachment from "./deleteUserAttachmentSlice.js";
import editProfileModal from "./editProfileModalSlice.js";
import attachmentUser from "./attachmentUserSlice.js";
import testimonial from "./testimonialSlice.js";
import searchPost from "./searchPostSlice.js";
import manageConnection from "./manageConnectionSlice";

import localforage from "localforage";
import {
	createStateSyncMiddleware,
	initMessageListener,
	initStateWithPrevTab,
} from "redux-state-sync";

import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";

import eventNotification from "./eventNotificationSlice.js";
import messageConversation from "./messageConversationSlice.js";
import messageConversationSearch from "./messageConversationSearchSlice.js";
import messageConversationMedia from "./messageConversationMediaSlice.js";
import messageChatroom from "./messageChatroomSlice.js";
import messageChatroomList from "./messageChatroomListSlice.js";
import messageInbox from "./messageInboxSlice.js";
import community from "./communitySlice.js";
import socialFeed from "./socialFeedSlice.js";
import report from "./reportSlice.js";
import genericSearch from "./genericSearchSlice.js";
import groups from "./groupsSlice";
import business from "./businessSlice.js";
import subscriptionSetting from "./subscriptionSetting.js";

const appReducer = combineReducers({
	auth: auth,
	user: user,
	corpUser: corpUser,
	jobManagement: jobManagement,
	searchProfile: searchProfile,
	searchJob: searchJob,
	favProfile: favProfile,
	appliedJob: appliedJob,
	favJob: favJob,
	jobApplicationManagement: jobApplicationManagement,
	eventNotification: eventNotification,
	messageConversation: messageConversation,
	messageConversationSearch: messageConversationSearch,
	messageChatroom: messageChatroom,
	messageChatroomList: messageChatroomList,
	messageConversationMedia: messageConversationMedia,
	messageInbox: messageInbox,
	roadmap: roadmap,
	community: community,
	masterFunction: masterFunction,
	socialFeed: socialFeed,
	peopleYouMayKnow: peopleYouMayKnow,
	recommendedGroups: recommendedGroups,
	checkDuplicate: checkDuplicate,
	getUserAttachmentType: getUserAttachmentType,
	getUserAttachmentByAcctId: getUserAttachmentByAcctId,
	createUserAttachment: createUserAttachment,
	deleteUserAttachment: deleteUserAttachment,
	editProfileModal: editProfileModal,
	report: report,
	attachmentUser: attachmentUser,
	testimonial: testimonial,
	genericSearch: genericSearch,
	searchPost: searchPost,
	groups: groups,
	business: business,
	manageConnection: manageConnection,
	subscriptionSetting: subscriptionSetting,
});
const persistConfig = {
	key: "state",
	storage: localforage,
};

const reducer = (state, action) => {
	if (action.type === "auth/LOGOUT") {
		state = undefined;

		localStorage.removeItem("token");
	}
	return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, reducer);

var devToolsOption = process.env.REACT_APP_MODE === "dev" ? true : false;
const middleWares = [createStateSyncMiddleware(thunk)];
export const store = configureStore({
	reducer: persistedReducer,
	/* middleware: [thunk], */
	middleware: [thunk],
	devTools: devToolsOption,
});
/* initStateWithPrevTab(store);
initMessageListener(store); */
export default store;
