import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { departmentViewRoadmap, viewRoadmapRoleByRoleId } from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";
import ImageMapper from "react-image-mapper";
import Department_Investor_Relations from "assets/img/Career_Roadmap/Finance/Department_Investor_Relations_1.jpg";
import RoleInfoModal from "../RoleInfoModal.js";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentInvestorRelations() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Investor_Relations;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "ir",
				shape: "poly",
				coords: [78,356,72,358,68,360,64,363,60,368,60,375,58,410,59,416,62,421,64,426,70,430,76,432,166,433,174,431,178,428,183,424,185,420,188,412,187,374,185,367,182,364,178,360,174,358,168,356,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "49",
				shape: "poly",
				coords: [215,356,210,358,207,360,204,364,202,370,202,418,203,423,205,428,209,431,216,433,344,433,351,433,356,430,359,428,362,424,363,419,364,372,363,367,360,363,357,359,353,357,348,356,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "50",
				shape: "poly",
				coords: [678,356,669,357,664,358,660,363,658,368,656,374,656,417,656,423,659,428,665,432,672,434,800,434,806,433,810,431,813,428,816,424,818,417,819,372,816,365,814,362,810,359,804,356,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "51",
				shape: "poly",
				coords: [1182,358,1172,360,1169,363,1166,366,1164,372,1163,417,1165,425,1166,429,1171,433,1178,435,1306,436,1313,436,1318,433,1322,430,1324,424,1325,416,1325,378,1325,369,1322,364,1317,361,1313,359,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "52",
				shape: "poly",
				coords: [1699,359,1692,360,1688,362,1684,366,1682,370,1680,378,1680,417,1682,424,1684,430,1688,435,1697,437,1823,437,1834,436,1837,433,1840,428,1843,422,1843,375,1841,368,1838,364,1834,361,1829,359,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "ir") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo)
			dispatch(viewRoadmapRoleByRoleId(requestInfo));
			
			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// console.log(coords.x + "," + coords.y + ",");
		// navigator.clipboard.writeText(coords.x + "," + coords.y + ",");
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}

	function enterArea(area) {
		console.log(area);
	}

	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
				onMouseEnter={(area) => enterArea(area)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
