import {
	container,
	description,
	title,
	cardTitle,
	blackColor,
	whiteColor,
	grayColor,
	opptyDesignColor,
	hexToRgb,
	facebookColor,
} from "assets/jss/material-kit-pro-react.js";
import headersSection from "assets/jss/material-kit-pro-react/components/headerStyle.js";
const LandingPageFeedStyle = (theme) => ({
	...headersSection(theme),
	body: {
		// paddingTop: "20px",
		height: "calc(100vh - 100px)",
		marginTop: "93px",
		overflowX: "hidden",
		overflowY: "scroll",
	},
	bodyDiv: {
		paddingBottom: "60px",
	},
	pageHeader: {
		position: "relative",
		// display: "flex !important",
		// alignItems: "center",
		margin: "0",
		border: "0",
		/* minHeight: "100vh", */
		height: "auto",
		color: whiteColor,
	},

	container: {
		...container,
		zIndex: "5",

		"& > div": {
			// gap: "20px",
		},

		// "@media (min-width: 576px)": {
		// 	flexBasis: "98%",
		// },
		// "@media (min-width: 768px)": {
		// 	flexBasis: "98%",
		// },
		// "@media (min-width: 960px)": {
		// 	flexBasis: "98%",
		// },
		// "@media (min-width: 992px)": {
		// 	flexBasis: "98%",
		// },
		// "@media (min-width: 1200px)": {
		// 	flexBasis: "98%",
		// },

		"@media (min-width: 576px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 768px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 992px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 1200px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 1400px)": {
			maxWidth: "95vw",
		},
	},
	leftSideBar: {
		// flexBasis: "19%",
		// maxWidth: "250px",
		// padding: "16px 10px",
		padding: 0,

		// "@media (max-width: 1200px)": {
		// 	display: "none",
		// },
	},
	rightSidebar: {
		// position: "sticky",
		// top: "125px",
		scrollbarWidth: "none",
		// flexBasis: "23%",
		// maxWidth: "285px",
		// padding: "16px 10px",
		padding: 0,
		paddingTop: "10px",
		// height: "80vh",

		// "@media (max-width: 1200px)": {
		// 	display: "none",
		// },
	},
	middlePart: {
		// flexBasis: "50%",
		// maxWidth: "665px",
		// padding: "16px 10px",
		padding: 0,
		top: "15px",

		// "@media (max-width: 600px)": {
		// 	maxWidth: "100%",
		// 	flexBasis: "100%",
		// },
	},
	textinput: {
		backgroundColor: "white",
		// width: "50%",
		borderRadius: "20px",
		marginTop: 7,
		height: 50,
		padding: 10,
	},
	dropdown: {
		backgroundColor: "#F2F2F2",
		borderRadius: "10px",
		borderWidth: 2,
		border: "1px solid #4A4A4A",
		color: "#06152B",
		width: "100%",
	},
	middlePartTab: {
		flex: " 0 0 100%",
		maxWidth: "calc(100% - 250px)",
		flexBasis: "calc(100% - 250px)",

		"@media (max-width: 1200px)": {
			maxWidth: "100%",
			flexBasis: "100%",
		},
	},

	createPostBtn: {
		// backgroundColor: "#6F52ED",
		fontSize: "20px",
		fontWeight: "medium",
		borderRadius: "20px",
		textTransform: "uppercase",
		margin: "0px 0 20px", //"10px 0 20px",
		position: "sticky",
		top: "0px", //"100px",
		zIndex: "2",
	},

	footerNav: {
		position: "fixed",
		left: 0,
		bottom: 0,
		width: "100%",
		textAlign: "center",
		alignItems: "center",
		margin: 0,
		border: "2px solid #D0DDE7",
	},
});

export default LandingPageFeedStyle;
