import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { getPendingTestimonial } from 'configurations/redux/testimonialSlice.js';
import singleTestimonialCardStyles from '../../../assets/jss/material-kit-pro-react/views/pageStyle/TestimonialsPage/singleTestimonialCardStyles';
import { Button, makeStyles, Typography } from '@material-ui/core';

import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import TestimonialMenuComponent from './TestimonialMenuComponent.js';
import Pagination from 'views/Component/PaginationWithPage.js';
import TestimonialCard from './TestimonialCard.js';
import TestimonialLoadingCard from './TestimonialLoadingCard.js';
import { activateEditType, deactivateEditType } from 'configurations/redux/editProfileModalSlice';
import TimeFormatOnlyDate from 'configurations/configurations.js';
import { decryptData } from 'configurations/configurations.js';

const useStyles = makeStyles(singleTestimonialCardStyles);

export default function PendingTestimonialComponent({ match }) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const userStatus = useSelector(state => state.auth.user);
  const pendingTestimonialList = useSelector(state => state.testimonial.pendingTestimonialList);
  const isLoading = useSelector(state => state.testimonial.isLoading);

  const isDeleted = useSelector(state => state.testimonial.isDeleted);
  const isCreated = useSelector(state => state.testimonial.isCreated);
  const isStatusChanged = useSelector(state => state.testimonial.isStatusChanged);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [testimonialPerPage, setTestimonialPerPage] = React.useState(5);
  const indexOfLast = currentPage * testimonialPerPage;
  const indexOfFirst = indexOfLast - testimonialPerPage;

  const paginate = pageNumber => {
    scrollToTop();
    setCurrentPage(pageNumber);
    var indexOfFirst = pageNumber * testimonialPerPage - testimonialPerPage;

    /* const request = {
			email: userStatus.email,
			acctId: userStatus.acctId,
			accessToken: localStorage.getItem("token"),
			keyword: "",
			lastRecordSize: indexOfFirst,
			requestRecordSize: resumesPerPage,
		};
		dispatch(getAttachment(request)); */
    const request = {
      accessToken: localStorage.getItem('token'),
      acctId: decryptData(match.params.acctId),
      email: userStatus.email,
      lastRecordSize: indexOfFirst,
      requestRecordSize: testimonialPerPage,
    };
    dispatch(getPendingTestimonial(request));
  };
  React.useEffect(() => {
    if (isDeleted || isCreated || isStatusChanged) {
      var newPage = 0;
      if (pendingTestimonialList.length > 0) newPage = currentPage;
      else newPage = currentPage - 1;
      var indexOfFirst = newPage * testimonialPerPage - testimonialPerPage;
      setCurrentPage(newPage);

      const request = {
        accessToken: localStorage.getItem('token'),
        acctId: decryptData(match.params.acctId),
        email: userStatus.email,
        lastRecordSize: indexOfFirst,
        requestRecordSize: testimonialPerPage,
      };
      dispatch(getPendingTestimonial(request));
    }
  }, [isDeleted, isCreated, pendingTestimonialList]);

  React.useEffect(() => {
    let mounted = true;
    let CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    if (mounted) {
      const request = {
        cancelToken: source.token,
        accessToken: localStorage.getItem('token'),
        acctId: decryptData(match.params.acctId),
        email: userStatus.email,
        lastRecordSize: 0,
        requestRecordSize: 5,
      };
      dispatch(getPendingTestimonial(request));
    }
    return () => {
      mounted = false;
      source.cancel('cancelling in tabs action switch');
    };
  }, []);

  const scrollToTop = () => {
    var timer = setInterval(() => {
      var articleResult = document.getElementById('testimonialPage');
      console.log(articleResult);
      if (articleResult) {
        articleResult.scrollIntoView();
        window.scrollTo(0, 0);
        clearInterval(timer);
      }
    }, 100);
  };

  const TestimonialList =
    pendingTestimonialList &&
    pendingTestimonialList.length > 0 &&
    pendingTestimonialList.map(item => {
      const mine = item.ownerAcctId === userStatus.acctId;
      return (
        <div key={item.testimonialId}>
          <TestimonialCard item={item} tsStatus={2} match={match} />
        </div>
      );
    });
  const loadingFields = [];
  for (let i = 0; i < testimonialPerPage - pendingTestimonialList.length; i++) {
    loadingFields.push(
      <div key={i}>
        <TestimonialLoadingCard />
      </div>
    );
  }
  const NoTestimonial = () => {
    return (
      <Card className={classes.cardFrame}>
        <CardBody className={classes.cardBody}>
          <h4 className={classes.noTestimonial}>
            No Testimonial Received or Requested. Request or Submit your testimonial to your connection
            <div>
              <Button
                className={classes.noTestimonialButton}
                onClick={() => {
                  const response = {
                    type: 'REQUEST_TESTIMONIAL',
                  };
                  dispatch(activateEditType(response));
                }}
              >
                Request Testimonial
              </Button>
              <Button
                className={classes.noTestimonialButton}
                onClick={() => {
                  const response = {
                    type: 'SUBMIT_TESTIMONIAL',
                  };
                  dispatch(activateEditType(response));
                }}
              >
                Submit Testimonial
              </Button>
            </div>
          </h4>
        </CardBody>
      </Card>
    );
  };
  return (
    <div className={classes.testimonialContainer}>
      {isLoading &&
        loadingFields.map(item => {
          return item;
        })}
      {TestimonialList}

      {pendingTestimonialList && pendingTestimonialList.length > 0 && (
        <Pagination
          totalPosts={pendingTestimonialList[0].totalCount}
          postsPerPage={testimonialPerPage}
          paginate={paginate}
        />
      )}
      {!isLoading && pendingTestimonialList.length === 0 && <NoTestimonial />}
    </div>
  );
}
