import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { departmentViewRoadmap, viewRoadmapRoleByRoleId } from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";
import ImageMapper from "react-image-mapper";
import Department_Attractions_Management from "assets/img/Career_Roadmap/Hospitality/Department_Attractions_Management_1.jpg";
import RoleInfoModal from "../RoleInfoModal.js";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentAttractionsManagement() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Attractions_Management;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "am",
				shape: "poly",
				coords: [163,96,169,96,178,98,183,103,189,111,190,118,190,128,190,732,189,738,186,743,181,747,170,751,167,751,81,751,75,750,69,746,65,741,63,736,62,730,62,118,63,111,66,106,70,101,77,98,84,96,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "70",
				shape: "poly",
				coords: [377,95,385,95,393,99,397,103,399,109,399,115,399,175,398,181,393,186,389,189,379,191,225,191,217,189,212,187,210,183,209,178,208,115,209,109,212,102,216,99,222,97,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "69",
				shape: "poly",
				coords: [381,234,388,236,394,240,399,248,400,255,400,312,399,319,395,324,390,329,381,330,224,330,221,331,217,328,213,325,210,320,209,251,211,245,213,242,217,238,223,236,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "68",
				shape: "poly",
				coords: [378,376,386,377,392,381,397,386,399,396,399,456,397,462,392,467,387,471,381,471,228,471,220,470,215,468,209,463,209,457,207,397,209,390,212,383,216,380,224,377,227,377,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "67",
				shape: "poly",
				coords: [380,516,388,517,393,520,396,525,399,529,399,538,399,594,397,601,394,606,388,610,380,612,228,612,220,612,214,609,210,606,209,602,208,596,208,534,210,529,212,523,216,519,225,517,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "66",
				shape: "poly",
				coords: [379,655,388,658,395,663,397,667,397,672,397,734,396,740,393,746,389,749,378,751,225,751,219,751,214,750,210,745,207,739,207,672,209,666,212,662,217,659,224,656,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "71",
				shape: "poly",
				coords: [846,372,852,372,858,375,862,380,864,386,864,394,864,462,862,470,857,475,852,476,841,478,578,477,571,477,566,475,564,473,560,468,560,391,562,381,566,376,572,372,584,371,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "72",
				shape: "poly",
				coords: [1156,370,1162,371,1170,373,1176,377,1178,383,1179,391,1179,462,1177,466,1172,473,1160,477,985,477,977,475,972,471,970,466,969,461,969,390,970,382,974,377,978,374,988,371,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "73",
				shape: "poly",
				coords: [1490,370,1496,371,1505,374,1508,382,1508,389,1509,458,1508,463,1505,469,1501,473,1495,475,1488,476,1315,475,1309,475,1303,471,1301,467,1299,462,1298,387,1300,380,1302,377,1305,374,1311,371,1317,370,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "74",
				shape: "poly",
				coords: [1832,371,1838,372,1844,376,1847,381,1849,389,1850,457,1848,466,1844,472,1838,476,1826,477,1658,478,1651,477,1645,475,1642,470,1637,463,1636,391,1638,382,1642,377,1649,372,1661,371,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "am") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo)
			dispatch(viewRoadmapRoleByRoleId(requestInfo));
			
			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// console.log(coords.x + "," + coords.y + ",");
		// navigator.clipboard.writeText(coords.x + "," + coords.y + ",");
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}

	function enterArea(area) {
		console.log(area);
	}

	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
				onMouseEnter={(area) => enterArea(area)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
