import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import LeftSidebarStyle from "assets/jss/material-kit-pro-react/views/componentStyle/LeftSidebarStyle.js";
import hashTagStyle from "assets/jss/material-kit-pro-react/views/componentStyle/hashTagStyle.js";
import {
	Typography,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	Button,
} from "@material-ui/core";
import Video from "assets/svg/video.svg";
import Manage from "assets/svg/manage.svg";
import Resume from "assets/svg/resume.svg";
import Doc from "assets/svg/doc.svg";
import feedIcon from "assets/images/feed.png";
import { logout } from "configurations/redux/authSlice.js";
import companyIcon from "assets/images/companies.png";
import groupIcon from "assets/images/group.png";
import followIcon from "assets/images/following.png";
import manageIcon from "assets/images/manage-connection.png";
import testimonialIcon from "assets/images/testimonial.png";
import documentationIcon from "assets/images/documentation.png";
import cvIcon from "assets/images/cv.png";
import profileImage from "assets/images/leftsidebar-profile.png";
import groupProfile from "assets/images/groupProfile.png";
import Bag from "assets/images/bag.png";
import bag from "assets/svg/bag.svg";
import { LinkedCameraRounded } from "@material-ui/icons";
import GridItem from "components/Grid/GridItem";
import { useSelector, useDispatch } from "react-redux";
import { encryptData } from "configurations/configurations";
import candidate_avatar from "assets/img/candidate_avatar.jpeg";
import IconLibrary from "./IconLibrary";
import {
	faSignOutAlt,
	faNewspaper,
	faSignInAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import GridContainer from "components/Grid/GridContainer.js";
import FeedIcon from "@mui/icons-material/Feed";
import BusinessIcon from "@mui/icons-material/Business";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
const useStyles = makeStyles(LeftSidebarStyle);
const hashtagStyles = makeStyles(hashTagStyle);

export default function LeftSidebar({ ...rest }) {
	const classes = useStyles();
	const dispatch = useDispatch();

	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
	const user = useSelector((state) => state.auth.user);
	const location = useLocation();

	const [acctId, setAcctId] = useState();

	useEffect(() => {
		if (isAuthenticated) {
			const hashId = encryptData(user.acctId);
			setAcctId(hashId);
		}

		if (window.location.href && window.location.href.includes("https://www.")) {
			const newURL = window.location.href.replace("https://www.", "https://");
			window.location.replace(newURL);
		}
	}, [isAuthenticated]);
	function logoutHandle() {
		// closeNotification();
		dispatch(logout(0));
	}
	return (
		<React.Fragment>
			<Typography component="div" className={classes.LeftSidebar_box}>
				<Typography
					component={Link}
					to={`/view-profile/${acctId}`}
					className={classes.profileCard}
				>
					<Typography component="div" className={classes.profileCard_image}>
						<img
							style={{ width: "100%" }}
							src={user.profilePic ? user.profilePic : candidate_avatar}
							alt=""
						/>
					</Typography>
					<Typography component="div">
						<h5 style={{ fontSize: "14px", fontWeight: 500, color: "#000" }}>
							{user.firstName ? user.firstName : ""}
							{user.middleName ? " " + user.middleName : ""}
							{user.surname ? " " + user.surname : ""}
						</h5>
					</Typography>
				</Typography>
				{/* <Typography className={classes.sideBarMenuBx} component="div">
					<h6>Corporate</h6>
					<List component="nav">
						<ListItem component={Link} to={`/biz-page/${acctId}`}>
							<ListItemIcon>
								<img src={feedIcon} alt="" />
							</ListItemIcon>
							<ListItemText primary="Business Page" />
						</ListItem>
					</List>
				</Typography> */}
				<Typography className={classes.sideBarMenuBx} component="div">
					<h6>Corporate</h6>
					<List component="nav">
						<ListItem
							className={
								location.pathname.includes(`/biz-page/`)
									? classes.currentLinkItems
									: classes.navLinkItems
							}
							component={Link}
							to={`/biz-page/${acctId}`}
						>
							<ListItemIcon>
								<BusinessIcon />
							</ListItemIcon>
							<ListItemText primary="Business Page" />
						</ListItem>
					</List>
				</Typography>

				{/* <Typography className={classes.sideBarMenuBx} component="div">
					<h6>Networking</h6>
					<List component="nav">
						<ListItem component={Link} to="/social-feed">
							<ListItemIcon>
								<img src={feedIcon} alt="" />
							</ListItemIcon>
							<ListItemText primary="Feed" />
						</ListItem>
						<ListItem component={Link} to="/resources">
							<ListItemIcon>
								<IconLibrary.SignBoard width={20} height={20} />
							</ListItemIcon>
							<ListItemText primary="Resource" />
						</ListItem>
						<ListItem component={Link} to="/search-page-job">
							<ListItemIcon>
								<IconLibrary.Jobs width={20} height={20} />
							</ListItemIcon>
							<ListItemText primary="Job" />
						</ListItem>
						 <ListItem
							component={Link}
							to={`/groups/${encryptData(user.acctId)}`}
						>
							<ListItemIcon>
								<img src={groupIcon} alt="" />
							</ListItemIcon>
							<ListItemText primary="Groups" />
						</ListItem> 
						<ListItem
							component={Link}
							to={`/following-page/${encryptData(user.acctId)}`}
						>
							<ListItemIcon>
								<img src={followIcon} alt="" />
							</ListItemIcon>
							<ListItemText primary="Following" />
						</ListItem>
					</List>
				</Typography> */}
				<Typography className={classes.sideBarMenuBx} component="div">
					<h6>Networking</h6>
					<List component="nav">
						<ListItem
							className={
								location.pathname.includes(`/job-application-landing/`)
									? classes.currentLinkItems
									: classes.navLinkItems
							}
							component={Link}
							to={`/job-application-landing/${acctId}`}
						>
							<ListItemIcon>
								{/* <GroupAddIcon /> */}
								<img src={Bag} />
							</ListItemIcon>
							<ListItemText primary="Job" />
						</ListItem>
						<ListItem
							component={Link}
							to="/social-feed/1/0"
							className={
								location.pathname.includes(`/social-feed`)
									? classes.currentLinkItems
									: classes.navLinkItems
							}
						>
							<ListItemIcon>
								<FeedIcon />
							</ListItemIcon>
							<ListItemText primary="Feed" />
						</ListItem>
						<ListItem
							component={Link}
							to="/resources"
							className={
								location.pathname.includes(`/resources`)
									? classes.currentLinkItems
									: classes.navLinkItems
							}
						>
							<ListItemIcon>
								<IconLibrary.SignBoard width={20} height={20} />
							</ListItemIcon>
							<ListItemText primary="Resource" />
						</ListItem>

						<ListItem
							component={Link}
							to={`/groups/${acctId}`}
							className={
								location.pathname.includes(`/groups/`) ||
									location.pathname.includes(`/my-groups`) ||
									location.pathname.includes(`/browse-groups`)
									? classes.currentLinkItems
									: classes.navLinkItems
							}
						>
							<ListItemIcon>
								<WorkspacesIcon />
							</ListItemIcon>
							<ListItemText primary="Groups" />
						</ListItem>
						<ListItem
							component={Link}
							to={`/following-page/${acctId}`}
							className={
								location.pathname.includes(`/following-page/`)
									? classes.currentLinkItems
									: classes.navLinkItems
							}
						>
							<ListItemIcon>
								<BookmarkIcon />
							</ListItemIcon>
							<ListItemText primary="Following" />
						</ListItem>
					</List>
				</Typography>

				{/* 	<Typography className={classes.sideBarMenuBx} component="div">
					<h6>Your Profile</h6>
					<List component="nav" className={classes.sideBarMenu}>
						<ListItem component={Link} to={`/manage-connection/${acctId}`}>
							<ListItemIcon>
								<img src={manageIcon} alt="" />
							</ListItemIcon>
							<ListItemText primary="Connections" />
						</ListItem>
						<ListItem component={Link} to={`/job-status-page`}>
							<ListItemIcon>
								<IconLibrary.Jobs />
							</ListItemIcon>
							<ListItemText primary="Applications" />
						</ListItem>
						<ListItem component={Link} to={`/testimonials/${acctId}`}>
							<ListItemIcon>
								<img src={testimonialIcon} alt="" />
							</ListItemIcon>
							<ListItemText primary="Testimonials" />
						</ListItem>

						<ListItem component={Link} to={`/resume/${acctId}`}>
							<ListItemIcon>
								<img src={cvIcon} alt="" />
							</ListItemIcon>
							<ListItemText primary="Your Resume" />
						</ListItem>
					</List>
				</Typography> */}
				<Typography className={classes.sideBarMenuBx} component="div">
					<h6>Your Profile</h6>
					<List component="nav" className={classes.sideBarMenu}>


						<ListItem
							className={
								location.pathname.includes(`/job-application-landing/`)
									? classes.currentLinkItems
									: classes.navLinkItems
							}
							component={Link}
							to={`/job-application-landing/${acctId}`}
						>
							<ListItemIcon>
								{/* <GroupAddIcon /> */}
								<img src={Bag} />
							</ListItemIcon>
							<ListItemText primary="Job Applications" />
						</ListItem>

						<ListItem
							className={
								location.pathname.includes(`/manage-connection/`)
									? classes.currentLinkItems
									: classes.navLinkItems
							}
							component={Link}
							to={`/manage-connection/${acctId}`}
						>
							<ListItemIcon>
								<GroupAddIcon />
							</ListItemIcon>
							<ListItemText primary="Connections" />
						</ListItem>
						<ListItem
							className={
								location.pathname.includes(`/job-status-page`)
									? classes.currentLinkItems
									: classes.navLinkItems
							}
							component={Link}
							to={`/job-status-page`}
						>
							<ListItemIcon>
								<IconLibrary.Jobs />
							</ListItemIcon>
							<ListItemText primary="Applications" />
						</ListItem>

						<ListItem
							className={
								location.pathname.includes(`/testimonials/`)
									? classes.currentLinkItems
									: classes.navLinkItems
							}
							component={Link}
							to={`/testimonials/${acctId}`}
						>
							<ListItemIcon>
								<FormatQuoteIcon />
							</ListItemIcon>
							<ListItemText primary="Testimonials" />
						</ListItem>
						<ListItem className={
							location.pathname.includes(`/upload-attachment/video/`)
								? classes.currentLinkItems
								: classes.navLinkItems
						}
							component={Link}
							to={`/upload-attachment/video/${acctId}`}
						>
							<ListItemIcon>
								{/* <FormatQuoteIcon /> */}
								<img src={Video} />
							</ListItemIcon>
							<ListItemText primary="My Video" />
						</ListItem>

						<ListItem className={
							location.pathname.includes(`/upload-attachment/document/`)
								? classes.currentLinkItems
								: classes.navLinkItems
						}
							component={Link}
							to={`/upload-attachment/document/${acctId}`}
						>
							<ListItemIcon>
								{/* <FormatQuoteIcon /> */}
								<img src={Doc} />
							</ListItemIcon>
							<ListItemText primary="My Documents" />
						</ListItem>

						<ListItem
							className={
								location.pathname.includes(`/resume/`)
									? classes.currentLinkItems
									: classes.navLinkItems
							}
							component={Link}
							to={`/resume/${acctId}`}
						>
							<ListItemIcon>
								<FileOpenIcon />
							</ListItemIcon>
							<ListItemText primary="Your Resume" />
						</ListItem>
						{/* <ListItem className={
							location.pathname.includes(`/upload-attachment/`)
								? classes.currentLinkItems
								: classes.navLinkItems
						}
							component={Link}
							to={`/upload-attachment/${acctId}/video`}
						>
							<ListItemIcon>
								<img src={Video} />
							</ListItemIcon>
							<ListItemText primary="My Video" />
						</ListItem>
						<ListItem className={
							location.pathname.includes(`/upload-attachment/`)
								? classes.currentLinkItems
								: classes.navLinkItems
						}
							component={Link}
							to={`/upload-attachment/${acctId}/document`}
						>
							<ListItemIcon>
								<img src={Doc} />
							</ListItemIcon>
							<ListItemText primary="My Documents" />
						</ListItem> */}
						<ListItem
							className={
								location.pathname.includes(`/subscription-settings/`)
									? classes.currentLinkItems
									: classes.navLinkItems
							}
							component={Link}
							to={`/subscription-settings/${encryptData(acctId)}`}
						>
							<ListItemIcon>
								{/* <FileOpenIcon /> */}
								<img src={Manage} />
							</ListItemIcon>
							<ListItemText primary="Subscription" />

						</ListItem>
					</List>
				</Typography>

				<Button
					// className={classes.navLink}
					className='signoutbTN'
					startIcon={<FontAwesomeIcon icon={faSignOutAlt} size={"1x"} />}
					fullWidth
					onClick={() => {
						setTimeout(() => {
							logoutHandle();
						}, 1000);
					}}
				>
					Sign Out
				</Button>
			</Typography>
		</React.Fragment>
	);
}
