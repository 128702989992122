const editGeneralProfileStyles = () => ({
	dialogContainer: {
		position: "absolute",
		zIndex: 1,
		top: "-100%",
		right: "-100%",
		bottom: "-100%",
		left: "-100%",
		backgroundColor: "#00000080",
	},

	dialog: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		borderRadius: "15px",
		backgroundColor: "#fff",
		padding: "70px 0 15px",
		width: "450px",
		height: "260px",
	},

	chipTags: {
		margin: "0 2px 5px 2px",
	},
	tagDescription: {
		margin: "15px 0px !important",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		color: "#000",
		fontWeight: "500",
		whiteSpace: "pre-line",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},

	dialogTitle: {
		margin: 0,
		color: "#000000",
		fontSize: "20px",
		fontWeight: 700,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,

		textAlign: "center",
	},

	closeIcon: {
		margin: "3px 3px 0 0",
		fontSize: "19px",
	},

	dialogText: {
		color: "#6D7278",
		fontSize: "18px",
		fontWeight: 400,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,

		textAlign: "center",
	},

	dialogActions: {
		position: "absolute",
		left: 0,
		right: 0,
		bottom: "20px",
		display: "flex",
		justifyContent: "space-evenly",

		"& > button": {
			borderRadius: "24px",
			width: "160px",
			height: "48px",
			fontSize: "16px",
			fontWeight: 500,
			fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		},

		"& > :nth-child(1)": {
			color: "#687BFE",
		},

		"& > :nth-child(2)": {
			backgroundColor: "#687BFE",
			color: "#fff",
		},
	},

	body: {
		"& form": {
			margin: 0,
		},
	},

	formSection: {
		borderRadius: "15px",
		backgroundColor: "#fff",
		padding: "17px 20px 15px",

		"&:not(:first-of-type)": {
			marginTop: "20px",
		},

		"&:not(:last-of-type)": {
			marginBottom: "20px",
		},
	},

	header: {
		display: "flex",
		justifyContent: "space-between",
		marginBottom: "10px",

		"& button": {
			marginLeft: "5px",
			padding: "6px 8px 4px",
			fontSize: "13px",
			fontWeight: 500,
			fontFamily: "Roboto, Helvetica, sans-serif",
			textTransform: "uppercase",
		},
	},

	title: {
		color: "#000000",
		fontSize: "18px",
		fontWeight: 700,
		fontFamily: "Roboto, Helvetica, sans-serif",
	},

	saveProfileInformationBtn: {
		color: "#0AB98D",

		"& svg": {
			marginTop: "-3px",
			marginRight: "5px",
			width: "16px",
			height: "16px",
		},
	},

	cancelEditBtn: {
		color: "#4A4A4A",

		"& svg": {
			marginTop: "-2px",
			marginRight: "5px",
			width: "10px",
			height: "10px",
		},
	},

	gridContainer: {
		marginLeft: "-5px",
		marginRight: "-5px",
	},

	gridItemInputLabelContainer: {
		margin: "5px 0 0",
		// padding: "0 10px",

		"& > label": {
			color: "#000000",
			fontSize: "14px",
			fontWeight: 700,
			fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		},
	},

	gridItem: {
		margin: "10px 0",
		padding: "0 10px",
	},

	autoCompleteInputContainer: {
		padding: "0 40px 0 0",

		"& > div": {
			right: "9px",
		},
	},

	inputField: {
		fontSize: "14px",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
	},

	titleAlign: {
		textAlign: "center",
	},
	modalHeaderTitle: {
		fontFamily: "Roboto, Helvetica, sans-serif",
		fontSize: "16px",
		fontWeight: "700",
	},
	gridCheckBoxesHeight: {
		marginBottom: "20px",
	},
	grayFont: {
		color: "#99B2C6 !important",
	},
	checkMargin: {
		// height: "70px",
	},
	uncheckedIcon: {
		color: "#99B2C6",
	},
	checkBoxLabel: {
		fontFamily: "Roboto, Helvetica, sans-serift",
		fontSize: "16px",
	},

	footer: {
		"& > div > span": {
			color: "#000000",
			fontSize: "14px",
			fontWeight: 700,
			fontFamily: "Roboto, Helvetica, sans-serif",
		},

		"& > div > label": {
			marginLeft: "15px",

			"& span": {
				color: "#6200EE",
			},
		},

		"& > p": {
			marginTop: "5px",
			color: "#000000",
			fontSize: "14px",
			fontWeight: 400,
			fontFamily: "Roboto, Helvetica, sans-serif",
		},
	},
});

export default editGeneralProfileStyles;
