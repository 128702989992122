import React, { useState } from 'react';

import { Typography, makeStyles, Box } from '@material-ui/core';

import clsx from 'clsx';

import img1 from 'assets/img/examples/blog1.jpg';
import dp1 from 'assets/img/faces/ali.jpg';
import avatar from 'assets/img/candidate_avatar.jpeg';

import profileHeaderStyles from '../../../../assets/jss/material-kit-pro-react/views/componentStyle/profileView/profileHeaderStyles.js';
import Hidden from '@material-ui/core/Hidden';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

import ProfilePictureSection from 'views/Page/ProfilePage/ProfileSection/ProfilePictureSection.js';
import { useSelector, useDispatch } from 'react-redux';
import CoverPictureEditModal from 'views/Page/ProfilePage/ProfileSection/CoverPictureEditModal.js';
const useStyles = makeStyles(profileHeaderStyles);
const ProfileHeader = props => {
  const { backgroundPic, profilePic, firstName, middleName, surname, headline, loadTab } = props;
  const styles = useStyles();

  const [optionToShow, setOption] = useState('myActivity');
  const [isOpen, setOpen] = React.useState(false);
  const closeModal = () => {
    setOpen(false);
  };
  const user = useSelector(state => state.user);
  const isViewed = useSelector(state => state.user.isViewed);
  return (
    <Typography component="div" className={styles.main}>
      <Typography component="div" className={styles.coverPicture}>
        <div
          onClick={() => {
            setOpen(true);
          }}
          className={styles.coverPhotoOverlay}
        ></div>
        {backgroundPic ? <img src={backgroundPic} alt="" /> : <img src={img1} alt="" />}
      </Typography>
      {!isViewed && (
        <CoverPictureEditModal
          open={isOpen}
          onClose={closeModal}
          backgroundPic={user.backgroundPic}
          backgroundPicFileName={user.backgroundPicFileName}
          backgroundTemplateFlag={user.backgroundTemplateFlag}
          user={user}
        />
      )}

      <Typography component="div" className={styles.userDetails}>
        <ProfilePictureSection />

        <Box
          component="div"
        // onClick={() => {
        // 	setOpen(true);
        // }}
        >
          <Hidden smDown>
            <Typography component="h1" className={styles.name}>
              {firstName ? firstName : ''}
              {middleName ? ' ' + middleName : ''}
              {surname ? ' ' + surname : ''}
            </Typography>
            <Typography component="p" className={styles.headline}>
              {headline}
            </Typography>
          </Hidden>
        </Box>
      </Typography>

      <Box
        component="div"
        className={styles.tabsContainer + ' tabcontainerCustom'}
      // onClick={() => {
      // 	setOpen(true);
      // }}
      >
        <Hidden mdUp>
          <div style={{ padding: '0 15px', width: '100%' }}>
            <h4
              style={{
                color: '#000',
                textTransform: 'none',
                fontWeight: '700',
                fontSize: '20px',
              }}
            >
              {firstName ? firstName : ''}
              {middleName ? ' ' + middleName : ''}
              {surname ? ' ' + surname : ''}
            </h4>
            <h5
              style={{
                color: '#000',
                textTransform: 'none',
                fontWeight: '500',
              }}
            >
              {headline}
            </h5>
          </div>

          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <Typography
                component="div"
                className={clsx({
                  [styles.tab]: true,
                  [styles.tabActive]: optionToShow == 'myActivity',
                })}
                onClick={() => {
                  loadTab('myActivity');
                  setOption('myActivity');
                }}
              >
                <Typography component="p" className={styles.tabTitle}>
                  My Activity
                </Typography>
                <div
                  className={clsx({
                    [styles.tabActiveBorder]: optionToShow == 'myActivity',
                  })}
                ></div>
              </Typography>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Typography
                component="div"
                className={clsx({
                  [styles.tab]: true,
                  [styles.tabActive]: optionToShow == 'workExperience',
                })}
                onClick={() => {
                  loadTab('workExperience');
                  setOption('workExperience');
                }}
              >
                <Typography component="p" className={styles.tabTitle}>
                  Work Experience
                </Typography>
                <div
                  className={clsx({
                    [styles.tabActiveBorder]: optionToShow == 'workExperience',
                  })}
                ></div>
              </Typography>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Typography
                component="div"
                className={clsx({
                  [styles.tab]: true,
                  [styles.tabActive]: optionToShow == 'education',
                })}
                onClick={() => {
                  loadTab('education');
                  setOption('education');
                }}
              >
                <Typography component="p" className={styles.tabTitle}>
                  Education
                </Typography>
                <div
                  className={clsx({
                    [styles.tabActiveBorder]: optionToShow == 'education',
                  })}
                ></div>
              </Typography>
            </GridItem>
          </GridContainer>
        </Hidden>

        <Hidden smDown>
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={12} md={4}>
              <Typography
                component="div"
                className={clsx({
                  [styles.tab]: true,
                  [styles.tabActive]: optionToShow == 'myActivity',
                })}
                onClick={() => {
                  loadTab('myActivity');
                  setOption('myActivity');
                }}
              >
                <Typography component="p" className={styles.tabTitle}>
                  My Activity
                </Typography>
                <div
                  className={clsx({
                    [styles.tabActiveBorder]: optionToShow == 'myActivity',
                  })}
                ></div>
              </Typography>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Typography
                component="div"
                className={clsx({
                  [styles.tab]: true,
                  [styles.tabActive]: optionToShow == 'workExperience',
                })}
                onClick={() => {
                  loadTab('workExperience');
                  setOption('workExperience');
                }}
              >
                <Typography component="p" className={styles.tabTitle}>
                  Work Experience
                </Typography>
                <div
                  className={clsx({
                    [styles.tabActiveBorder]: optionToShow == 'workExperience',
                  })}
                ></div>
              </Typography>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Typography
                component="div"
                className={clsx({
                  [styles.tab]: true,
                  [styles.tabActive]: optionToShow == 'education',
                })}
                onClick={() => {
                  loadTab('education');
                  setOption('education');
                }}
              >
                <Typography component="p" className={styles.tabTitle}>
                  Education
                </Typography>
                <div
                  className={clsx({
                    [styles.tabActiveBorder]: optionToShow == 'education',
                  })}
                ></div>
              </Typography>
            </GridItem>
          </GridContainer>
        </Hidden>
      </Box>
    </Typography>
  );
};

export default ProfileHeader;
