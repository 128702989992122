/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "components/CustomButtons/Button.js";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import style from "assets/jss/material-kit-pro-react/components/reportModalStyle.js";
import IconLibrary from "./IconLibrary";
import { getReportReasonByReasonType } from "configurations/configurations";

const useStyles = makeStyles(style);

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

export default function ReportModal({
	open,
	onClose,
	recordType,
	reportReasonsList,
	reportTypeId,
	recordId,
	reportPost,
}) {
	const classes = useStyles();
	const [reasonId, setReasonId] = React.useState(
		`${reportReasonsList.length > 0 ? reportReasonsList[0].reasonId : null}`
	);
	const [reasonDesc, setReasonDesc] = React.useState("");
	const [getReportReasonsList, setGetReportReasonsList] = useState([]);

	useEffect(() => {
		console.log(reportReasonsList);
		if (reportReasonsList.length == 0) {
			fetchReportReasonList();
		} else {
			setGetReportReasonsList(reportReasonsList);
		}

		return () => {
			setReasonId();
			setReasonDesc();
			setGetReportReasonsList();
		};
	}, []);

	const handleChange = (event) => {
		setReasonId(event.target.value);
		var reasonDesc = getReportReasonsList.filter((item, index) => {
			return item.reasonId == event.target.value;
		});
		setReasonDesc(reasonDesc[0].reasonDesc);
	};

	const fetchReportReasonList = async () => {
		const res = await getReportReasonByReasonType(reportTypeId);
		console.log(res);
		setGetReportReasonsList(res.data.payload);
	};

	const ResultList =
		getReportReasonsList.length > 0 &&
		getReportReasonsList.map((x, idx) => (
			<FormControlLabel
				key={idx}
				value={`${x.reasonId}`}
				control={
					<Radio
						classes={{
							root: classes.radio,
							checked: classes.checked,
						}}
					/>
				}
				label={`${x.reasonDesc}`}
			/>
		));

	return (
		<React.Fragment>
			<Dialog
				classes={{
					root: classes.modalRoot,
					paper: classes.modalLoader,
				}}
				open={open}
				TransitionComponent={Transition}
				keepMounted
				// disableBackdropClick
				onClose={onClose}
				aria-labelledby="classic-modal-slide-title"
				aria-describedby="classic-modal-slide-description"
			>
				<DialogTitle
					id="classic-modal-slide-title"
					disableTypography
					className={classes.modalHeader}
				>
					<Button
						simple
						className={classes.modalCloseButton}
						key="close"
						aria-label="Close"
						onClick={onClose}
					>
						{" "}
						<IconLibrary.Close />
					</Button>
					<h4 onClick={() => console.log(reportReasonsList)}>
						<b>{recordType}</b>
					</h4>
				</DialogTitle>
				<DialogContent
					id="classic-modal-slide-description"
					className={classes.modalBody}
				>
					{/* <DialogContentText id="alert-dialog-slide-description"> */}
					<FormControl>
						<RadioGroup
							name="report"
							value={reasonId}
							onChange={handleChange}
							aria-labelledby="demo-radio-buttons-group-label"
						>
							<div className={classes.radioList}>{ResultList}</div>
						</RadioGroup>
					</FormControl>
					{/* </DialogContentText> */}
				</DialogContent>
				<DialogActions className={classes.modalFooter}>
					<Button
						onClick={() => {
							onClose();
							reportPost(reportTypeId, reasonId, reasonDesc, recordId);
						}}
						color="opptyWrap"
						round
					>
						Submit
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}
