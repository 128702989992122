import React from 'react';
import Header from 'views/Component/Header.js';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import searchPageStyle from 'assets/jss/material-kit-pro-react/views/pageStyle/SearchPage/searchPageStyle.js';
import { searchJobInfo } from 'configurations/redux/searchJobSlice.js';
import { searchProfileInfo } from 'configurations/redux/searchProfileSlice.js';
import { saveParams, searchPost, searchPostByPostId } from 'configurations/redux/searchPostSlice.js';
import PeopleComponent from './PeopleComponent.js';
import JobComponent from './JobComponent.js';
import PostComponent from './PostComponent.js';
import FilterComponent from './FilterComponent.js';
import { getArticleCategory, getArticleListGS, getArticleListByKeyword } from 'configurations/redux/communitySlice.js';
import LeftSidebar from 'views/Component/LeftSidebar.js';
import Hidden from '@material-ui/core/Hidden';
import Footer from 'views/Component/Footer.js';

import { Button } from '@material-ui/core';
import {
  getSocialPostByRecent,
  getSocialPostByPostId,
  resetPostGet,
  getHashtagMatch,
  resetHashtagMatch,
} from 'configurations/redux/socialFeedSlice.js';
import { decryptData } from 'configurations/configurations.js';
import { useLocation } from 'react-router-dom';
import ArticleComponent from './ArticleComponent.js';

const useStyles = makeStyles(searchPageStyle);

export default function SearchPage({ match }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const keyword = useSelector(state => state.genericSearch.keyword);
  const userStatus = useSelector(state => state.auth.user);
  const isPublic = useSelector(state => state.auth.isPublic);
  const auth = useSelector(state => state.auth);
  const [isPublicMode, setIsPublicMode] = React.useState(false);

  const getJobPost = () => {
    const requestProfile = {
      acctId: isPublic ? 0 : userStatus.acctId,
      accessToken: isPublic ? auth.publicToken : localStorage.getItem('token'),
      email: isPublic ? auth.publicEmail : userStatus.email,
      keyword: keyword,
      countryId: -1,
      locationId: -1,
      companyName: '',
      industryId: -1,
      hotJobFlag: 0,
      jobPostTypeId: 0,
      jobTypeId: 0,
      lastRecordSize: 0,
      experienceId: -1,
      currencyId: -1,
      salaryMin: -1,
      salaryMax: -1,
      requestRecordSize: 4,
      genericSearch: true,
    };
    console.log(requestProfile);
    dispatch(searchJobInfo(requestProfile));
  };
  const getProfile = () => {
    const requestProfile = {
      acctId: isPublic ? 0 : userStatus.acctId,
      accessToken: isPublic ? auth.publicToken : localStorage.getItem('token'),
      email: isPublic ? auth.publicEmail : userStatus.email,
      keyword: keyword,
      countryId: -1,
      locationId: -1,
      lastRecordSize: 0,
      requestRecordSize: 3,
      genericSearch: true,
    };
    console.log(requestProfile);
    dispatch(searchProfileInfo(requestProfile));
  };
  const getArticle = () => {
    const requestProfile = {
      /* acctId: userStatus.acctId,
			acctTypeId: userStatus.acctTypeId,
			accessToken: localStorage.getItem("token"),
			email: userStatus.email, */
      acctId: isPublic ? 0 : userStatus.acctId,
      accessToken: isPublic ? auth.publicToken : localStorage.getItem('token'),
      email: isPublic ? auth.publicEmail : userStatus.email,
      acctTypeId: 1,
      categoryIds: '',
      subCategoryIds: '',
      lastRecordSize: 0,
      requestRecordSize: 4,
      paginationClick: false,
      defaultLoad: false,
      keyword: keyword,
    };
    dispatch(getArticleListGS(requestProfile));
  };
  const getPost = () => {
    console.log(isPublic, match.params.type);
    if (match.params.type && match.params.type == 'post') {
      console.log('show only one post');
    } else {
      if (isPublic) {
        const requestProfile = {
          acctId: 0,
          accessToken: auth.publicToken,
          email: auth.publicEmail,
          keyword: keyword ? keyword : '',
          lastRecordSize: 0,
          requestRecordSize: 3,
          hashId: 0,
          userId: 0,
          groupId: 0,
          pageId: 0,
          sortBy: 'top',
          visibleIds: '1',
        };
        console.log(requestProfile);
        dispatch(searchPost(requestProfile));
      } else {
        var previousPostListType = localStorage.getItem('postListType');
        const requestProfile = {
          acctId: userStatus.acctId,
          accessToken: localStorage.getItem('token'),
          email: userStatus.email,
          lastRecordSize: 0,
          requestRecordSize: 3,
          hashId: 0,
          userId: 0,
          groupId: 0,
          pageId: 0,
          sortBy: previousPostListType ? previousPostListType : 'recent',
          keyword: keyword ? keyword : '',
          visibleIds: '1,2',
        };
        console.log(requestProfile);
        dispatch(saveParams(requestProfile));
        dispatch(getSocialPostByRecent(requestProfile));
      }
    }
  };

  React.useEffect(() => {
    if (!isPublicMode) setIsPublicMode(false);
  }, []);

  React.useEffect(() => {
    if (!location.pathname.includes('public-')) {
      getJobPost();
      getProfile();
      getPost();
      getArticle();
    }
  }, [keyword]);

  const scrollToTop = () => {
    var timer = setInterval(() => {
      var jobPostResult = document.getElementById('searchPageScroll');
      if (jobPostResult) {
        jobPostResult.scrollIntoView();
        window.scrollTo(0, 0);
        clearInterval(timer);
      }
    }, 100);
  };

  React.useEffect(() => {
    // console.log(auth.publicToken);
    if (!auth.isAuthenticated && auth.publicToken) {
      if (isPublic) setIsPublicMode(true);
      scrollToTop();
      getJobPost();
      getProfile();
      getPost();
      getArticle();
    }
  }, [auth.publicToken]);

  return (
    <div>
      <Header />

      <div className={classes.body}>
        <div className={classes.pageHeader} id="searchPageScroll">
          <div className={classes.container}>
            <GridContainer spacing={2}>
              <Hidden smDown>
                <GridItem md={3}>{auth.isAuthenticated && <LeftSidebar />}</GridItem>
              </Hidden>
              <GridItem xs={12} sm={12} md={9}>
                {match.params.type ? (
                  match.params.type == 'post' ? (
                    <div>
                      <PostComponent match={match} />
                      <JobComponent match={match} />
                      <PeopleComponent />
                      <ArticleComponent />
                    </div>
                  ) : (
                    match.params.type == 'job' && (
                      <div>
                        <JobComponent match={match} />
                        <PeopleComponent />
                        <ArticleComponent />
                        <PostComponent match={match} />
                      </div>
                    )
                  )
                ) : (
                  <div>
                    <PeopleComponent />
                    <JobComponent match={match} />
                    <ArticleComponent />
                    <PostComponent match={match} />
                  </div>
                )}
              </GridItem>
              <Hidden mdDown>
                <GridItem md={2}></GridItem>
              </Hidden>
            </GridContainer>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
