import React, { useState } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { useDropzone } from 'react-dropzone';

import { makeStyles, Typography, Button, LinearProgress, Box, Modal, TextField } from '@material-ui/core';
import axios from 'axios';

import uploadFileIcon from 'assets/img/upload-file-icon.png';

import editResumeStyles from 'assets/jss/material-kit-pro-react/views/componentStyle/profileView/editProfile/resume/editResumeStyles';
import { getUserAttachmentTypeInfo } from 'configurations/redux/getUserAttachmentTypeSlice';
import { getUserAttachmentByAcctIdInfo } from 'configurations/redux/getUserAttachmentByAcctIdSlice';
import { createUserAttachmentInfo } from 'configurations/redux/createUserAttachmentSlice';
import { checkDuplicateInfo, checkDuplicateReset } from 'configurations/redux/checkDuplicateSlice';

import { createAttachment, getAttachment } from 'configurations/redux/attachmentUserSlice.js';
import { deleteUserAttachmentInfo } from 'configurations/redux/deleteUserAttachmentSlice';
import { pdfjs, Document, Page } from 'react-pdf';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { instanceUrl, header, emptyCache } from 'configurations/configurations.js';
import ResumeSection from './ResumeSection.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import fileIcon from 'assets/svg/white-page-with-folded-corner.svg';
import { useModal } from 'hooks';
import ConfirmDialog from 'components/CustomDialog/ConfirmDialog.js';
import { deactivateEditType } from 'configurations/redux/editProfileModalSlice';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const EditResume = ({ saveDetailModalProps, onCancelChangesConfirm, setIsChanged }) => {
  const styles = makeStyles(editResumeStyles)();
  const [files, setFiles] = React.useState();
  const [preview, setPreview] = useState();

  const [errors, setErrors] = React.useState([]);
  const [fileInfoModal, setFileInfoModal] = React.useState(false);
  const [fileName, setFileName] = React.useState('');
  const [attachmentType, setAttachmentType] = React.useState(1);
  const dispatch = useDispatch();
  const [fileNameError, setFileNameError] = React.useState(2);
  const [isClick, setIsClick] = React.useState(false);
  const isMainModalCloseRequest = useSelector(state => state.editProfileModal.isMainModalCloseRequest);

  const cancelForm = {
    title: 'Discard Action',
    text: 'All the actions will be discarded',
    cancelText: 'No',
    confirmText: 'Yes',
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept:
      'image/jpeg, image/png, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword',
    maxSize: 10485760,
    onDrop: React.useCallback((acceptedFiles, fileRejections) => {
      setErrors([]);
      fileRejections.forEach(file => {
        file.errors.forEach(err => {
          if (err.code === 'file-invalid-type') {
            setErrors(`Error: File Invalid Type. JPEG, PNG, PDF, DOCX, DOC supported`);
          }

          if (err.code === 'file-too-large') {
            setErrors(`Error: File Size cannot exceed 10mb`);
          }
        });
      });

      if (fileRejections.length === 0) {
        setFiles(
          acceptedFiles.map(file =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
        setErrors([]);
        setIsChanged(true);
        setFileInfoModal(true);
      }
    }),
  });
  React.useEffect(() => {
    if (!files) {
      return;
    } else {
      URL.revokeObjectURL(files[0]);
    }

    // free memory when ever this component is unmounted
  }, [files]);

  const cancelUploadResumeModalProps = useModal();

  const userStatus = useSelector(state => state.auth.user);

  React.useEffect(() => {
    const request = {
      email: userStatus.email,
      acctId: userStatus.acctId,
      accessToken: localStorage.getItem('token'),
      keyword: '',
      lastRecordSize: 0,
      requestRecordSize: 6,
    };
    dispatch(getAttachment(request));
  }, []);

  React.useEffect(() => {
    if (errors && errors.length === 0 && files) {
      setFileName(files[0].path.split('.').shift());
      dispatch(checkDuplicateReset());
      const requestProfile1 = {
        acctId: userStatus.acctId,
        accessToken: localStorage.getItem('token'),
        email: userStatus.email,
        title: files[0].path.split('.').shift(),
      };
      const checkDuplicateTitle = async () => {
        await axios({
          method: 'GET',
          headers: header,
          url: `${instanceUrl}/api/um/check-user-attachment-title-by-acct-id`,
          params: requestProfile1,
        })
          .then(res => {
            if (res.status === 201) {
              const response = {
                checkDuplicate: res.data.payload.checkDuplicate,
                token: res.data.token,
              };
              console.log(response);
              setFileNameError(response.checkDuplicate);
            }
          })
          .catch(err => {
            setFileNameError(err);
          });
      };
      checkDuplicateTitle();
    }
  }, [files]);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDupDialogOpen, setIsDupDialogOpen] = useState(false);
  const [isSaveDialogOpen, setIsSaveDialogOpen] = useState(false);

  return (
    <div className={styles.body}>
      <Typography component="h2" className={styles.heading}>
        Uploaded Resume
      </Typography>
      <div className={styles.resumeContainer}>
        <ResumeSection />
      </div>

      <Typography component="h2" className={styles.heading}>
        Upload Your Resume
      </Typography>
      <Typography component="div" className={styles.dropZoneContainer}>
        <Typography component="div" {...getRootProps({ className: styles.dropZone })}>
          <input {...getInputProps()} />
          {!files && <img src={uploadFileIcon} alt="Upload file icon" />}

          {errors && errors.length === 0 && files && files[0].type.indexOf('image') !== -1 && (
            <React.Fragment>
              <img
                style={{
                  margin: 'auto',
                  width: '210px',
                  height: '297px',
                  objectFit: 'cover',
                  objectPosition: 'center center',
                }}
                src={files[0].preview}
              />
              <h5 style={{ fontWeight: 500, fontSize: '16px' }}>{fileName}</h5>
            </React.Fragment>
          )}
          {errors && errors.length === 0 && files && files[0].type === 'application/pdf' && (
            <React.Fragment>
              {/* <Document file={files[0].preview}>
									<Page
										height={297}
										pageNumber={1}
										className={styles.pdfRender}
									/>
								</Document> */}
              <Box
                style={{
                  width: '212px',
                  height: '297px',
                  /* backgroundColor: "#f2f3f7", */
                  display: 'flex',
                  margin: 'auto',
                  justifyContent: 'center',
                  alignContent: 'center',
                  flexDirection: 'column',
                  backgroundImage: `url(${fileIcon})`,
                  backgroundPosition: 'center center',
                  backgroundSize: '100% 100%',
                }}
              >
                <h4 style={{ fontSize: '18px', fontWeight: 700 }}>PDF</h4>
              </Box>
              <h5 style={{ fontWeight: 500, fontSize: '16px' }}>{fileName}</h5>
            </React.Fragment>
          )}
          {errors &&
            errors.length === 0 &&
            files &&
            (files[0].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
              files[0].type === 'application/msword') && (
              <React.Fragment>
                <Box
                  style={{
                    width: '212px',
                    height: '297px',
                    /* backgroundColor: "#f2f3f7", */
                    display: 'flex',
                    margin: 'auto',
                    justifyContent: 'center',
                    alignContent: 'center',
                    flexDirection: 'column',
                    backgroundImage: `url(${fileIcon})`,
                    backgroundPosition: 'center center',
                    backgroundSize: '100% 100%',
                  }}
                >
                  <h4 style={{ fontSize: '18px', fontWeight: 700 }}>DOCX</h4>
                </Box>
                <h5 style={{ fontWeight: 500, fontSize: '16px' }}>{fileName}</h5>
              </React.Fragment>
            )}

          <h2 className={styles.dropZoneHeading}>Drag and drop here</h2>
          <p>or</p>
          <button className={styles.dropZoneButton}>Select File</button>

          <p>Only JPEG, PNG, DOCX, DOC and PDF files with maximum size 10mb.</p>
        </Typography>

        {errors}

        <Button
          style={{ display: files ? 'block' : 'none' }}
          className={styles.btnSave}
          /* 	onClick={handleCheck} */
          onClick={() => {
            let fileFormData = new FormData();

            fileFormData.append('email', userStatus.email);
            fileFormData.append('acctId', userStatus.acctId);
            fileFormData.append('attachment', files[0]);
            fileFormData.append('title', fileName);
            fileFormData.append('accessToken', localStorage.getItem('token'));
            fileFormData.append('attachmentTypeId', attachmentType);

            console.log(fileFormData);
            dispatch(createAttachment(fileFormData));
            setFiles();
            setIsChanged(false);
          }}
        >
          SAVE
        </Button>
      </Typography>

      <ConfirmDialog
        {...{ ...saveDetailModalProps }}
        bodyContent={cancelForm}
        modalOpen={errors && errors.length === 0 && files !== undefined && saveDetailModalProps.modalOpen}
        handleSubmit={() => {
          setFiles();
          if (isMainModalCloseRequest) {
            setIsChanged(false);
            dispatch(deactivateEditType());
            saveDetailModalProps.setModalOpen(false);
          } else {
            onCancelChangesConfirm();
          }
        }}
      />
      {errors && errors.length === 0 && files && fileInfoModal && (
        <div>
          <Modal
            className={styles.dialogContainer}
            open={fileInfoModal}
            onClose={() => {
              setFileInfoModal(false);
              setFiles();
            }}
          >
            <div style={{ padding: '15px' }} className={styles.dialog}>
              <div className={styles.dialogHeight}>
                <h4 style={{ margin: '15px 0px' }} className={styles.dialogTitle}>
                  Upload Your Resume/Attachment
                </h4>

                <Box
                  component="div"
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {errors.length === 0 && files && files[0].type.indexOf('image') !== -1 && (
                    <React.Fragment>
                      <img
                        style={{
                          margin: 'auto',
                          width: '210px',
                          height: '297px',
                          objectFit: 'cover',
                          objectPosition: 'center center',
                        }}
                        src={files[0].preview}
                      />
                    </React.Fragment>
                  )}

                  {errors.length === 0 && files && files[0].type === 'application/pdf' && (
                    <React.Fragment>
                      <Box
                        style={{
                          width: '212px',
                          height: '297px',
                          /* backgroundColor: "#f2f3f7", */
                          display: 'flex',
                          margin: 'auto',
                          justifyContent: 'center',
                          alignContent: 'center',
                          flexDirection: 'column',
                          backgroundImage: `url(${fileIcon})`,
                          backgroundPosition: 'center center',
                          backgroundSize: '100% 100%',
                        }}
                      >
                        <h4 style={{ fontSize: '18px', fontWeight: 700 }}>PDF</h4>
                      </Box>
                    </React.Fragment>
                  )}
                  {errors.length === 0 &&
                    files &&
                    (files[0].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                      files[0].type === 'application/msword') && (
                      <Box
                        style={{
                          width: '212px',
                          height: '297px',
                          /* backgroundColor: "#f2f3f7", */
                          display: 'flex',
                          margin: 'auto',
                          justifyContent: 'center',
                          alignContent: 'center',
                          flexDirection: 'column',
                          backgroundImage: `url(${fileIcon})`,
                          backgroundPosition: 'center center',
                          backgroundSize: '100% 100%',
                        }}
                      >
                        <h4 style={{ fontSize: '18px', fontWeight: 700 }}>DOCX</h4>
                      </Box>
                    )}
                </Box>

                <h5 style={{ fontWeight: 500, fontSize: '16px', textAlign: 'center' }}>
                  {errors && errors.length === 0 && files[0].path}
                </h5>

                <Select
                  value={attachmentType}
                  variant="standard"
                  fullWidth
                  sx={{ margin: '15px 0px' }}
                  label="Attachment Type"
                  onChange={(event, value) => {
                    console.log(value, event);
                    setAttachmentType(event.target.value);
                  }}
                >
                  <MenuItem value={1}>Resume</MenuItem>
                  <MenuItem value={2}>Portfolio</MenuItem>
                  <MenuItem value={3}>Salary Slips</MenuItem>
                  <MenuItem value={4}>Others</MenuItem>
                </Select>

                <TextField
                  id="tagInput"
                  value={fileName}
                  fullWidth
                  label={'File Name'}
                  onChange={event => {
                    setFileName(event.target.value);
                    setIsClick(false);
                  }}
                  helperText={
                    isClick
                      ? fileNameError === 0
                        ? 'Valid File Name'
                        : 'Invalid File Name, Please Change'
                      : 'Please Verify File Name Before Upload'
                  }
                  error={fileNameError === 1}
                  onBlur={() => {
                    const requestProfile1 = {
                      acctId: userStatus.acctId,
                      accessToken: localStorage.getItem('token'),
                      email: userStatus.email,
                      title: fileName,
                    };

                    const checkDuplicateTitle = async () => {
                      await axios({
                        method: 'GET',
                        headers: header,
                        url: `${instanceUrl}/api/um/check-user-attachment-title-by-acct-id`,
                        params: requestProfile1,
                      })
                        .then(res => {
                          if (res.status === 201) {
                            const response = {
                              checkDuplicate: res.data.payload.checkDuplicate,
                              token: res.data.token,
                            };
                            console.log(response);
                            setFileNameError(response.checkDuplicate);
                          }
                        })
                        .catch(err => {
                          setFileNameError(err);
                        });
                    };
                    checkDuplicateTitle();
                  }}
                />
                <Button
                  onClick={() => {
                    const requestProfile1 = {
                      acctId: userStatus.acctId,
                      accessToken: localStorage.getItem('token'),
                      email: userStatus.email,
                      title: fileName,
                    };

                    const checkDuplicateTitle = async () => {
                      await axios({
                        method: 'GET',
                        headers: header,
                        url: `${instanceUrl}/api/um/check-user-attachment-title-by-acct-id`,
                        params: requestProfile1,
                      })
                        .then(res => {
                          if (res.status === 201) {
                            const response = {
                              checkDuplicate: res.data.payload.checkDuplicate,
                              token: res.data.token,
                            };
                            console.log(response);
                            setFileNameError(response.checkDuplicate);
                          }
                        })
                        .catch(err => {
                          setFileNameError(err);
                        });
                    };
                    checkDuplicateTitle();
                    setIsClick(true);
                  }}
                >
                  Verify
                </Button>
                <div className={styles.dialogActions}>
                  <Button
                    onClick={() => {
                      cancelUploadResumeModalProps.toggle();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={isClick && fileNameError === 0 ? false : true}
                    onClick={() => {
                      setFileInfoModal(false);
                      setIsClick(false);
                    }}
                  >
                    Ok
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
          <ConfirmDialog
            {...{ ...cancelUploadResumeModalProps }}
            bodyContent={cancelForm}
            handleSubmit={() => {
              setFileInfoModal(false);
              setFiles();
              setIsClick(false);
              setIsChanged(false);
              cancelUploadResumeModalProps.setModalOpen(false);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(EditResume);
