import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
	departmentViewRoadmap,
	viewRoadmapRoleByRoleId,
} from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Department_Speech_Therapy from "assets/img/Career_Roadmap/Healthcare/Department_Speech_Therapy_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentSpeechTherapy() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Speech_Therapy;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "st",
				shape: "poly",
				coords: [86,125,77,127,72,131,68,137,66,143,66,737,68,744,72,749,77,753,85,754,177,756,184,753,189,749,192,746,195,738,195,144,193,135,188,130,183,126,177,125,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "361",
				shape: "poly",
				coords: [225,404,219,408,216,411,213,415,213,421,213,488,215,492,218,496,223,499,230,501,343,500,349,496,353,494,355,488,356,484,356,423,355,415,351,409,345,406,340,405,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "362",
				shape: "poly",
				coords: [422,405,417,408,413,412,411,416,410,422,410,486,413,492,416,496,421,500,428,501,540,500,547,498,551,494,553,489,554,484,553,421,551,413,548,409,545,406,538,405,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "363",
				shape: "poly",
				coords: [624,405,615,407,611,411,609,416,608,421,609,488,611,493,614,497,619,499,626,500,739,499,746,496,749,493,750,487,752,482,752,422,751,414,749,409,743,406,737,405,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "364",
				shape: "poly",
				coords: [821,405,814,407,811,410,808,414,806,421,807,483,807,491,811,495,816,499,823,500,935,500,943,496,946,494,949,489,950,483,950,421,948,414,945,409,938,405,932,405,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "365",
				shape: "poly",
				coords: [1018,405,1012,408,1008,411,1004,414,1004,422,1004,484,1006,491,1010,496,1015,500,1021,501,1134,500,1140,496,1144,493,1146,490,1148,483,1147,419,1145,413,1141,408,1134,406,1129,404,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "368",
				shape: "poly",
				coords: [1216,123,1210,126,1206,129,1203,133,1202,139,1202,214,1204,221,1207,226,1212,228,1219,230,1394,230,1403,228,1408,223,1410,220,1412,214,1412,142,1411,131,1406,126,1400,124,1393,123,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "369",
				shape: "poly",
				coords: [1480,124,1474,126,1470,130,1467,135,1466,143,1466,215,1468,221,1469,224,1474,228,1481,230,1662,230,1669,226,1672,224,1674,220,1675,215,1675,144,1674,132,1671,127,1666,124,1658,124,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "370",
				shape: "poly",
				coords: [1218,299,1212,301,1207,304,1203,309,1201,316,1202,388,1203,395,1206,400,1212,403,1219,405,1397,406,1404,402,1408,398,1410,395,1412,388,1412,315,1409,307,1406,302,1399,299,1393,299,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "371",
				shape: "poly",
				coords: [1481,299,1474,301,1469,304,1467,309,1465,315,1466,387,1468,395,1470,399,1475,403,1481,405,1662,405,1667,402,1671,400,1674,395,1676,390,1675,313,1673,306,1670,302,1664,300,1658,298,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "366",
				shape: "poly",
				coords: [1217,474,1210,477,1206,480,1203,484,1202,490,1202,566,1204,572,1207,576,1212,579,1217,581,1399,580,1405,577,1409,574,1411,570,1411,564,1412,488,1409,481,1405,478,1400,475,1394,474,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "367",
				shape: "poly",
				coords: [1483,474,1474,476,1470,480,1467,485,1466,492,1466,565,1467,571,1469,575,1473,578,1479,581,1664,581,1669,577,1673,574,1675,569,1676,565,1675,489,1673,482,1669,477,1664,475,1656,474,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "372",
				shape: "poly",
				coords: [1218,649,1212,650,1205,654,1203,660,1202,666,1202,740,1204,748,1207,752,1212,755,1218,756,1397,756,1403,754,1406,751,1409,748,1411,742,1411,664,1410,658,1408,654,1402,651,1396,649,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "373",
				shape: "poly",
				coords: [1484,649,1475,651,1470,655,1467,659,1465,667,1466,738,1467,747,1471,751,1476,755,1482,756,1659,756,1669,753,1672,750,1675,745,1676,740,1676,668,1674,659,1670,654,1663,651,1656,650,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "st") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo);
			dispatch(viewRoadmapRoleByRoleId(requestInfo));

			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
