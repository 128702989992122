import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from 'components/CustomButtons/Button';
import { Avatar, Box, Button, CircularProgress, makeStyles } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { Form, Formik } from 'formik';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import IconLibrary from 'views/Component/IconLibrary';
import profilePageStyle from 'assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js';
import axios from 'axios';
import { instanceUrl } from 'configurations/configurations';
import { useDebounce } from 'hooks';
import { userGroupStatus, userGroupRoles } from 'configurations/configurations';
import { getGroupUserByGroupId, inviteUsersToGroup } from 'configurations/redux/groupsSlice';

const useStyles = makeStyles(profilePageStyle);

export default function InviteUserModal({ modalOpen, toggle, group }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [sendingInvites, setSendingInvites] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [search, setSearch] = useState('');
  const [users, setUsers] = useState([]);
  const debouncedSearch = useDebounce(search, 500);
  const user = useSelector(state => state.auth.user);
  const { groupId } = group;
  const accessToken = localStorage.getItem('token');
  const [allUsersGroup, setAllUsersGroup] = useState([]);

  const fullName = (firstName, surname) => `${firstName} ${surname}`;

  const getUsers = () => {
    setLoadingUsers(true);

    const body = {
      email: user.email,
      acctId: user.acctId,
      accessToken,
      keyword: search,
      requestRecordSize: 10,
      lastRecordSize: 0,
      countryId: -1,
      studyFieldId: -1,
      locationId: -1,
    };

    axios
      .get(`${instanceUrl}/api/portal/search-profile`, {
        params: body,
      })
      .then(res => {
        setLoadingUsers(false);
        setUsers(res.data.payload.profileList);
      });
  };

  const getMembers = () => {
    const body = {
      groupId,
      accessToken,
      keyword: '',
      lastRecordSize: 0,
      requestRecordSize: -1,
      email: user.email,
      roleTypeId: '1,2,3',
      statusTypeId: '1,2,3',
    };
    dispatch(getGroupUserByGroupId(body)).then(res => {
      setAllUsersGroup(res.data.payload.groupUserList);
    });
  };

  const onInviteUsers = values => {
    setSendingInvites(true);
    const requestBodies = values.usersToInvite.map(u => {
      return {
        accessToken,
        email: user.email,
        groupId,
        acctId: user.acctId,
        userId: u.acctId,
        userStatusId: userGroupStatus.INVITED.id,
        roleId: userGroupRoles.MEMBER.id,
      };
    });

    dispatch(inviteUsersToGroup(requestBodies))
      .then(() => {
        setSendingInvites(false);
        toggle();
        getMembers();
      })
      .catch(() => {
        setSendingInvites(false);
      });
  };

  useEffect(() => {
    if (debouncedSearch) {
      getUsers();
    }
  }, [debouncedSearch]);

  useEffect(() => {
    if (modalOpen) {
      getMembers();
    }
  }, [modalOpen]);

  const UserOption = ({ props, option }) => {
    const userStatusMessage = {
      1: 'already in group',
      2: 'already invited',
      3: 'waiting for approval',
    };
    const userFoundInGroup = allUsersGroup.find(u => u.acctId === option.acctId);
    return (
      <Button disabled={!!userFoundInGroup} fullWidth {...props}>
        <Box display="flex" alignItems="center">
          <Avatar src={option.profilePic} />
          <Typography marginLeft={2} variant="body2">
            {fullName(option.firstName, option.surname)}
          </Typography>
          {userFoundInGroup && (
            <Typography sx={{ position: 'absolute', left: '73%' }} variant="body2" color="GrayText">
              {userStatusMessage[userFoundInGroup.statusId.toString()]}
            </Typography>
          )}
        </Box>
      </Button>
    );
  };

  const clearSearch = () => {
    setUsers([]);
    setSearch('');
  };
  return (
    <>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalProfileEdit + ' ' + classes.modal,
        }}
        open={modalOpen}
        onClose={toggle}
      >
        <DialogTitle className={classes.modalTitle}>
          <CustomButton simple className={classes.modalCloseButton} key="close" aria-label="Close" onClick={toggle}>
            <IconLibrary.Close />
          </CustomButton>

          <div className={classes.title + ' ' + classes.titleAlign}>Invite user to group</div>
        </DialogTitle>
        <Formik
          onSubmit={onInviteUsers}
          validateOnChange={false}
          initialValues={{
            usersToInvite: [],
          }}
        >
          {({ handleChange, values, errors, touched, handleBlur, handleSubmit, setFieldValue }) => (
            <Form>
              <DialogContent>
                <GridContainer spacing={2} justifyContent="center">
                  <GridItem xs={12}>
                    <Autocomplete
                      multiple
                      options={users}
                      value={values.usersToInvite}
                      getOptionLabel={({ firstName, surname }) => fullName(firstName, surname)}
                      renderOption={(props, option) => <UserOption key={option.acctId} {...{ props, option }} />}
                      onOpen={getUsers}
                      onClose={clearSearch}
                      onChange={(event, value) => {
                        const isUserAdded = values.usersToInvite.find(
                          u => u.acctId === value[value.length - 1]?.acctId
                        );
                        !isUserAdded && setFieldValue('usersToInvite', value);
                      }}
                      loading={loadingUsers}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Find user to invite"
                          value={search}
                          onChange={event => setSearch(event.target.value)}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loadingUsers ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </GridItem>
                </GridContainer>
              </DialogContent>
              <DialogActions>
                <CustomButton disabled={sendingInvites} round onClick={handleSubmit} color="oppty">
                  <b>Invite</b>
                </CustomButton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}
