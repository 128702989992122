import React from "react";

import {
	deleteHashtag,
	updateHashtag,
} from "configurations/redux/userSlice.js";
import TextField from "@material-ui/core/TextField";

import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
/* import ProfileStrengthTip from "./ProfileStrengthTip.js"; */
import { deactivateMasterCode } from "configurations/redux/masterFunctionSlice.js";

import Chip from "@material-ui/core/Chip";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js";
import { useDispatch, useSelector } from "react-redux";
import ProfileStrengthTip from "views/Page/ProfilePage/ProfileSection/ProfileStrengthTip.js";

const useStyles = makeStyles(profilePageStyle);

export const TagSection = ({ extension }) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const hashTagsList = useSelector((state) => state.user.hashtags);
	const isViewed = useSelector((state) => state.user.isViewed);
	const user = useSelector((state) => state.user);
	const privacySetting = useSelector((state) => state.user.privacySetting);
	const functionCode = useSelector(
		(state) => state.masterFunction.functionCode
	);

	const [skillTag, setSkillTag] = React.useState([]);
	const [tagInput, setTagInput] = React.useState("");
	const [error, setError] = React.useState(false);
	const [maxLengthError, setMaxLengthError] = React.useState(false);
	const deleteTag = (value) => () => {
		console.log(value);
		const requestProfile = {
			email: user.email,
			acctId: user.acctId,
			accessToken: localStorage.getItem("token"),
			hashId: value.hashId,
		};
		console.log(requestProfile);
		dispatch(deleteHashtag(requestProfile));
	};
	const handleChange = (event) => {
		event.preventDefault();
		setTagInput(event.target.value);
	};
	const updateRequest = (hashtag) => {
		const requestProfile = {
			email: user.email,
			acctId: user.acctId,
			accessToken: localStorage.getItem("token"),
			hashId: -1,
			hashtag: hashtag,
		};
		dispatch(updateHashtag(requestProfile));
	};

	const updateTag = (data) => {
		if (data.charCode === 13) {
			if (data.target.value.trim().length > 0) {
				if (data.target.value.length > 50) {
					data.preventDefault();
					setMaxLengthError(true);
					// setTagInput("");
					setError(false);
				} else {
					setMaxLengthError(false);
					const index = hashTagsList.findIndex(
						(item) =>
							item.hashtag.toLowerCase() === data.target.value.toLowerCase()
					);

					if (index === -1) {
						updateRequest(data.target.value);
						data.preventDefault();
						setTagInput("");
						setError(false);
						dispatch(deactivateMasterCode());
					} else {
						data.preventDefault();
						setTagInput("");
						setError(true);
					}
				}
			}
		}
	};
	const showProfile =
		privacySetting &&
		privacySetting
			.filter((item) => {
				return item.settingName === "showProfile";
			})
			.map((item) => {
				return item.settingValue === 1;
			})[0];

	const HashTagList = () => {
		return (
			skillTag &&
			skillTag.map((item) => {
				if (isViewed) {
					return (
						<Chip
							key={item.hashId}
							label={<p className={classes.tagDescription}>{item.hashtag}</p>}
							className={classes.chip}
							color={"secondary"}
						/>
					);
				} else {
					return (
						<Chip
							key={item.hashId}
							label={<p className={classes.tagDescription}>{item.hashtag}</p>}
							className={classes.chip}
							onDelete={deleteTag(item)}
							color={"secondary"}
						/>
					);
				}
			})
		);
	};

	React.useEffect(() => {
		setSkillTag(hashTagsList);
	}, [hashTagsList]);

	return (
		<div>
			<Card
				className={classes.cardFrame + " " + classes.tagFrame}
				id="tagContainer"
			>
				<CardBody>
					<h4 className={classes.title}>Skills And Attributes</h4>
					{/* <ProfileStrengthTip
						tipCategory={null}
						Text={
							"Announce yourself to your profile visitors in Headline \n\n Announce yourself to your profile visitors in Headline \n Announce yourself to your profile visitors in Headline \n Announce yourself to your profile visitors in Headline"
						}
					/> */}
					{/* {!isViewed &&
						functionCode === "MF_PM_SECTION_SKILL_ATTRIBUTE" &&
						} */}

					{functionCode === "MF_PM_SECTION_SKILL_ATTRIBUTE" && extension && (
						<ProfileStrengthTip extension={extension} />
					)}
					{showProfile ? (
						<React.Fragment>
							<Card className={classes.cardBorder}>
								<CardBody>
									{error ? (
										<SnackbarContent
											message={<b>Duplicate Tag is not allowed</b>}
											close
											color="opptyWarning"
											icon="info_outline"
										/>
									) : null}
									{maxLengthError && (
										<SnackbarContent
											message={<b>Tag cannot exceed 50 characters</b>}
											close
											color="opptyWarning"
											icon="info_outline"
										/>
									)}
									{hashTagsList[0] && (
										<div className={classes.cardBodyMargin}>
											<HashTagList />
										</div>
									)}
									{isViewed ? null : (
										<TextField
											id="tagInput"
											value={tagInput}
											fullWidth
											disabled={skillTag.length >= 10 ? true : false}
											onKeyPress={updateTag}
											onKeyDown={() => {
												if (error || maxLengthError) {
													setError(false);
													setMaxLengthError(false);
												}
											}}
											label={
												skillTag.length >= 10 ? (
													<p>Maximum Tag Reached</p>
												) : (
													<p>Add Tag</p>
												)
											}
											onChange={handleChange}
										/>
									)}
								</CardBody>
							</Card>
						</React.Fragment>
					) : (
						<React.Fragment>
							{isViewed ? (
								<h5 className={classes.title}>
									"Skills {"&"} Attribute" Detail is currently private.
								</h5>
							) : (
								<React.Fragment>
									{error ? (
										<SnackbarContent
											message={<b>Duplicate Tag is not allowed</b>}
											close
											color="danger"
											icon="info_outline"
										/>
									) : null}
									{maxLengthError && (
										<SnackbarContent
											message={<b>Tag cannot exceed 50 characters</b>}
											close
											color="danger"
											icon="info_outline"
										/>
									)}
									<div className={classes.hashTagContainer}>
										<div className={classes.cardBodyMargin}>
											<HashTagList />
										</div>
										<TextField
											id="tagInput"
											value={tagInput}
											fullWidth
											disabled={skillTag.length >= 10 ? true : false}
											onKeyPress={updateTag}
											label={
												skillTag.length >= 10 ? (
													<p>Maximum Tag Reached</p>
												) : (
													<p>Add Tag</p>
												)
											}
											onChange={handleChange}
										/>
									</div>
								</React.Fragment>
							)}
						</React.Fragment>
					)}
				</CardBody>
			</Card>
		</div>
	);
};

export default TagSection;
