import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
	departmentViewRoadmap,
	viewRoadmapRoleByRoleId,
} from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Industry_Banking_SME_Corporate_Commercial from "assets/img/Career_Roadmap/Banking_SME_Corporate_Commercial/Industry_Banking_SME_Corporate_Commercial_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function IndustryBankingSMECorporateCommercial() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Industry_Banking_SME_Corporate_Commercial;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "s",
				shape: "poly",
				coords: [78,262,68,265,64,268,60,272,58,280,58,321,60,326,62,330,68,335,75,338,180,338,186,336,192,332,196,328,198,321,198,279,195,272,192,267,186,264,180,262,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "corb",
				shape: "poly",
				coords: [76,411,68,413,63,417,60,422,57,428,57,468,60,475,63,480,67,483,75,487,181,487,187,484,192,480,196,475,198,470,198,428,195,420,192,416,185,412,180,412,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "comb",
				shape: "poly",
				coords: [75,560,68,563,64,566,60,571,58,578,58,620,60,626,64,630,69,634,76,636,178,636,186,634,192,631,195,626,198,620,198,579,196,570,192,566,186,562,179,560,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "477",
				shape: "poly",
				coords: [304,261,295,263,291,267,288,272,288,279,288,324,290,331,294,335,298,338,305,339,494,339,500,336,504,334,508,329,510,322,509,279,508,270,505,265,498,262,492,261,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "413",
				shape: "poly",
				coords: [630,261,621,263,617,267,614,273,613,279,614,325,616,330,619,334,624,338,630,339,821,338,826,336,830,333,834,329,835,324,835,277,833,270,829,265,825,262,820,261,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "414",
				shape: "poly",
				coords: [953,261,947,263,944,266,940,271,939,276,939,326,941,331,944,335,948,337,955,338,1147,338,1153,336,1157,333,1160,328,1160,322,1160,276,1158,269,1155,265,1151,263,1145,261,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "415",
				shape: "poly",
				coords: [1280,261,1273,264,1268,266,1265,272,1264,279,1264,324,1266,329,1269,334,1274,338,1280,339,1471,338,1478,336,1482,332,1485,329,1486,323,1486,277,1485,270,1482,266,1477,263,1471,261,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "419",
				shape: "poly",
				coords: [307,409,296,412,292,415,289,420,288,426,288,472,290,479,294,483,299,486,306,488,494,488,502,485,506,482,509,477,510,470,510,428,509,420,505,415,499,411,490,410,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "420",
				shape: "poly",
				coords: [633,410,623,411,618,415,615,420,613,427,613,472,615,479,618,483,623,486,630,488,821,487,827,486,830,482,834,477,835,472,835,422,832,417,830,414,826,411,820,410,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "421",
				shape: "poly",
				coords: [955,409,946,411,942,415,940,421,939,426,939,472,941,479,943,483,948,486,955,488,1142,488,1152,485,1156,483,1159,478,1161,473,1160,424,1158,417,1154,413,1149,411,1142,410,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "422",
				shape: "poly",
				coords: [1277,409,1272,413,1268,415,1265,420,1264,425,1264,473,1266,479,1270,484,1275,487,1281,487,1470,487,1478,485,1483,481,1485,477,1486,471,1487,426,1485,419,1482,414,1476,411,1469,410,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "479",
				shape: "poly",
				coords: [1606,409,1599,411,1593,415,1590,420,1589,426,1590,473,1592,479,1595,484,1600,487,1607,488,1797,488,1804,485,1808,482,1810,477,1812,470,1812,424,1810,418,1807,414,1802,411,1796,410,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "478",
				shape: "poly",
				coords: [304,558,297,560,293,562,289,568,288,573,288,622,289,627,292,631,297,634,303,636,495,637,503,634,506,630,509,626,509,620,510,575,509,567,506,564,500,560,494,559,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "416",
				shape: "poly",
				coords: [627,559,621,561,616,565,614,571,613,577,613,623,616,629,618,632,623,635,629,636,820,636,827,634,830,631,833,628,834,623,835,572,833,566,830,562,824,560,819,558,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "417",
				shape: "poly",
				coords: [954,558,947,560,943,564,940,568,938,575,939,621,941,627,942,631,947,635,954,637,1145,636,1152,634,1157,631,1159,627,1160,621,1160,572,1158,566,1154,562,1148,559,1142,559,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "418",
				shape: "poly",
				coords: [1285,559,1274,559,1269,563,1265,569,1264,578,1264,623,1267,628,1269,632,1275,636,1280,637,1469,636,1478,634,1483,629,1485,625,1486,620,1486,574,1484,567,1481,563,1476,560,1469,558,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "s") {
			dispatch(departmentViewRoadmap(50));
		} else if (area.name === "corb") {
			dispatch(departmentViewRoadmap(51));
		} else if (area.name === "comb") {
			dispatch(departmentViewRoadmap(52));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo);
			dispatch(viewRoadmapRoleByRoleId(requestInfo));

			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
