import React, { useState } from "react";
import { instanceUrl, salaryRange } from "configurations/configurations.js";

import axios from "axios";
import { Typography } from '@material-ui/core';
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import {
	corporateEditJobSchema,
	getJobTypeIdByJobPostType,
} from "configurations/configurations.js";
import {
	editJobPosts,
	updateJobPostStatus,
} from "configurations/redux/jobManagementSlice.js";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Autocomplete, {
	createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Slider from "@material-ui/core/Slider";
import Input from "@material-ui/core/Input";
import Chip from "@material-ui/core/Chip";
import DialogActions from "@material-ui/core/DialogActions";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Danger from "components/Typography/Danger.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Hidden from "@material-ui/core/Hidden";

import LoadingModal from "views/Component/LoadingModal.js";

import WorkIcon from "@material-ui/icons/Work";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import Close from "@material-ui/icons/Close";

import corporateJobManagementPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/CorporateJobManagementPage/corporateJobManagementPageStyle.js";
import Backdrop from "../../Component/Backdrop.js";
import { isDesktop } from "react-device-detect";
import CustomDialog from "../../../components/CustomDialog/CustomDialog.js";
import IconLibrary from "views/Component/IconLibrary.js";
import ReactQuill, { Quill } from "react-quill";
import MagicUrl from "quill-magic-url";
import "react-quill/dist/quill.snow.css";
import { useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles(corporateJobManagementPageStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

export default function JobEditModal({
	open,
	onClose,
	jobPostId,
	jobPostRecord,
	jobViewPage,
}) {
	console.log('jobPostRecord', jobPostRecord)
	const classes = useStyles();
	const dispatch = useDispatch();
	const userStatus = useSelector((state) => state.auth.user);
	const jobPostList = useSelector((state) => state.jobManagement.jobPostList);
	const jobPost = useSelector(
		(state) => state.jobManagement.corpSelectedJobPost
	);
	const isLoading = useSelector((state) => state.jobManagement.isLoading);
	const isEdited = useSelector((state) => state.jobManagement.isEdited);

	const [hashtag, setTagInput] = React.useState("");
	const [industryList, setIndustryList] = React.useState([]);
	const [countryList, setCountryList] = React.useState([]);
	const [locationList, setLocationList] = React.useState([]);
	const [currencyList, setCurrencyList] = React.useState([]);
	const [educationList, setEducationList] = React.useState([]);
	const [experienceLevelList, setExperienceLevelList] = React.useState([]);
	const [jobPostTypeChange, setJobPostTypeChange] = React.useState();
	const [questionsHiring, setQuestionsHiring] = React.useState([]);
	const [tagError, setTagError] = React.useState(false);
	const [jobPostTypeList, setJobPostType] = React.useState([]);
	const [jobTypeList, setJobType] = React.useState([]);
	const [maxLengthError, setMaxLengthError] = React.useState(false);
	const [countryChange, setCountryChange] = React.useState();
	const bizId = useSelector((state) => state.business.bizId);
	const [questionStart, setQuestionStart] = React.useState()
	const [qustion, setQuestion] = React.useState(true);

	// const [questionsHiring, setQuestionsHiring] = React.useState([]);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const snackBarShow = (message, variant, action) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(message, {
			variant: variant,
			action,
		});
	};

	React.useEffect(() => {
		const arr = []
		jobPostRecord && jobPostRecord.questions && jobPostRecord.questions.length > 0 &&
			jobPostRecord.questions.map((item, index) => {
				const itemValue = {
					text: '',
					name: 'Question' + index + 1,
					value: item.quesDescription,
					yesclass: item.answerId == 1 ? 'active' : '',
					noclass: item.answerId == 2 ? 'active' : ''
				}
				arr.push(itemValue)
			})
		setQuestionStart(arr.length)
		setQuestionsHiring(arr)
	}, [])
	const handleFirstQuestionChange = (event) => {
		let views = questionsHiring.slice();
		for (let i in views) {
			if (views[i].name == event.target.name) {
				if (views[i].value == '') {
					setQuestion(false)
				}
				views[i].value = event.target.value;
				setQuestionsHiring(views);
				break;
			}
		}
	}


	const hiringQuestionAnswer = (data, bit) => {
		let split = data.split("_");
		let views = questionsHiring.slice();
		for (let i in views) {
			if (views[i].value == split[1]) {
				if (split[0] == '/affirm') {
					views[i].yesclass = 'active';
					views[i].noclass = "";
				} else {
					views[i].noclass = 'active';
					views[i].yesclass = "";
				}
				setQuestionsHiring(views);
				setQuestion(true)
				break;
			}
		}
	}

	const addNewQuestions = () => {
		if (questionsHiring.length < (10)) {
			let arr = {
				text: '',
				name: `Question${questionsHiring.length + 1}`,
				value: '',
				yesclass: 'button',
				noclass: 'button'
			}

			setQuestionsHiring(questionsHiring => [...questionsHiring, arr]);
		} else {
			snackbarCustom("There can only be maximum of 10 questions which can be added.");
		}

	}
	const onClickDismiss = (key) => () => {
		closeSnackbar(key);
	};

	const snackbarCustom = (title) => {
		const action = (key) => (
			<React.Fragment>
				<IconButton onClick={onClickDismiss(key)}>
					<CloseIcon />
				</IconButton>
			</React.Fragment>
		);
		snackBarShow(title, "default", action);
	};

	const handleTag = (event) => {
		event.preventDefault();
		setTagInput(event.target.value);
	};

	function yearMonthValue(monthValue) {
		if (monthValue >= 12) {
			if (monthValue % 12 === 0) {
				return " " + parseInt(monthValue / 12) + " Year";
			} else {
				return (
					" " +
					parseInt(monthValue / 12) +
					" Year " +
					(monthValue % 12) +
					" Month "
				);
			}
		} else {
			return " " + monthValue + " Month";
		}
	}
	React.useEffect(() => {
		let mounted = true;
		let CancelToken = axios.CancelToken;
		const source = CancelToken.source();

		const fetchCountryList = async () => {
			try {
				const res = await axios.get(`${instanceUrl}/api/md/get-all-country`, {
					cancelToken: source.token,
				});
				setCountryList(res.data.payload);
			} catch (error) {
				if (axios.isCancel(error)) {
					//console.log("unmount caused");
				} else {
					throw error;
				}
			}
		};
		const fetchExperienceLevelList = async () => {
			try {
				const res = await axios.get(
					`${instanceUrl}/api/md/get-all-job-experience`,
					{
						cancelToken: source.token,
					}
				);
				setExperienceLevelList(res.data.payload);
			} catch (error) {
				if (axios.isCancel(error)) {
					//console.log("unmount caused");
				} else {
					throw error;
				}
			}
		};
		const fetchLocationList = async () => {
			try {
				const res = await axios.get(`${instanceUrl}/api/md/get-all-location`, {
					cancelToken: source.token,
				});
				setLocationList(res.data.payload);
			} catch (error) {
				if (axios.isCancel(error)) {
					//console.log("unmount caused");
				} else {
					throw error;
				}
			}
		};
		const fetchEducationLevelList = async () => {
			try {
				const res = await axios.get(
					`${instanceUrl}/api/md/get-all-education-level`,
					{
						cancelToken: source.token,
					}
				);
				setEducationList(res.data.payload);
			} catch (error) {
				if (axios.isCancel(error)) {
					//console.log("unmount caused");
				} else {
					throw error;
				}
			}
		};
		const fetchCurrencyList = async () => {
			try {
				const res = await axios.get(`${instanceUrl}/api/md/get-all-currency`, {
					cancelToken: source.token,
				});
				setCurrencyList(res.data.payload);
			} catch (error) {
				if (axios.isCancel(error)) {
					//console.log("unmount caused");
				} else {
					throw error;
				}
			}
		};
		const fetchIndustryList = async () => {
			try {
				const res = await axios.get(`${instanceUrl}/api/md/get-all-industry`, {
					cancelToken: source.token,
				});
				setIndustryList(res.data.payload);
			} catch (error) {
				if (axios.isCancel(error)) {
					//console.log("unmount caused");
				} else {
					throw error;
				}
			}
		};
		const fetchJobTypeList = async () => {
			try {
				const res = await axios.get(`${instanceUrl}/api/md/get-all-job-type`, {
					cancelToken: source.token,
				});
				setJobType(res.data.payload);
			} catch (error) {
				if (axios.isCancel(error)) {
				} else {
					throw error;
				}
			}
		};
		const fetchJobPostTypeList = async () => {
			try {
				const res = await axios.get(
					`${instanceUrl}/api/md/get-all-job-post-type`,
					{
						cancelToken: source.token,
					}
				);
				setJobPostType(res.data.payload);
			} catch (error) {
				if (axios.isCancel(error)) {
				} else {
					throw error;
				}
			}
		};
		if (mounted) {
			fetchCountryList();
			// fetchLocationList();
			fetchExperienceLevelList();
			fetchCurrencyList();
			fetchIndustryList();
			fetchEducationLevelList();
			/* fetchJobTypeList(); */
			fetchJobPostTypeList();
		}

		return () => {
			mounted = false;
			source.cancel();
		};
	}, []);
	React.useEffect(() => {
		if (countryChange) {
			console.log(countryChange);
			let CancelToken = axios.CancelToken;
			const source = CancelToken.source();
			/* const test = axios.get(`${instanceUrl}/api/md/get-all-country`);
			console.log(test); */
			const fetchLocationList = async (countryId) => {
				try {
					const res = await axios.get(
						`${instanceUrl}/api/md/get-all-location-by-country`,
						{
							cancelToken: source.token,
							params: { countryId: countryId },
						}
					);
					setLocationList(res.data.payload);
				} catch (error) {
					if (axios.isCancel(error)) {
						//console.log("unmount caused");
					} else {
						throw error;
					}
				}
			};
			fetchLocationList(countryChange);
		}
	}, [countryChange]);
	React.useEffect(() => {
		// console.log(jobPostTypeChange);
		if (jobPostTypeChange) {
			let CancelToken = axios.CancelToken;
			const source = CancelToken.source();
			const fetchJobTypeByJobPostType = async (jobPostTypeId) => {
				try {
					const res = await axios.get(
						`${instanceUrl}/api/md/get-job-type-by-job-post-type-id`,
						{
							cancelToken: source.token,
							params: { jobPostTypeId },
						}
					);
					setJobType(res.data.payload);
				} catch (error) {
					if (axios.isCancel(error)) {
					} else {
						throw error;
					}
				}
			};
			fetchJobTypeByJobPostType(jobPostTypeChange);
		}
	}, [jobPostTypeChange]);
	const dialogDefault = {
		title: "",
		description: "",
		buttonYes: "",
		buttonNo: "",
	};
	const [confirmDialog, setConfirmDialog] = React.useState({
		isOpen: false,
		dialogDefault,
	});

	const modalResizeJob = () => {
		var modalResize = document.getElementById("modalJob");
		if (modalResize) {
			var maxSize = window.innerHeight - 74 - 80 - 70;
			if (maxSize < 0) maxSize = 0;
			modalResize.style.height = maxSize + "px";
		}
	};

	React.useEffect(() => {
		if (open) {
			if (document.readyState === "complete") {
				if (jobPostRecord) {
					setCountryChange(jobPostRecord.countryId);
					setJobPostTypeChange(jobPostRecord.jobPostTypeId);
				}
				setTimeout(() => {
					modalResizeJob();
				}, 500);
			}
			window.addEventListener("resize", modalResizeJob);
			return function cleanup() {
				window.removeEventListener("resize", modalResizeJob);
			};
		}
	}, [open]);

	const modules = {
		magicUrl: true,
	};
	const quillRefJReq = React.createRef(null);
	const quillRefJRes = React.createRef(null);
	Quill.register("modules/magicUrl", MagicUrl);

	const onQuillChangeJReq = (content, delta, source, editor) => {
		console.log(content, delta, source, editor);
		var counterReq = document.getElementById("counterJReq");
		var counter = document.getElementById("quillCounterJReq");
		var editorArea = document.getElementById("quillEditorJReq");
		if (counter) {
			var wordCount = editor.getLength() - 1;
			counterReq.innerHTML = wordCount;
			if (wordCount <= 3000) {
				counter.innerHTML = "(" + wordCount + "/3000)";
				counter.style.color = "#000000DE";
			} else {
				counter.innerHTML =
					"* Requirement cannot exceed 3000 Characters (" +
					wordCount +
					"/3000)";
				counter.style.color = "red";
			}
		}
		if (editorArea) editorArea.style.border = "0";
	};

	const onQuillChangeJRes = (content, delta, source, editor) => {
		console.log(content, delta, source, editor);
		var counterRes = document.getElementById("counterJRes");
		var counter = document.getElementById("quillCounterJRes");
		var editorArea = document.getElementById("quillEditorJRes");
		if (counter) {
			var wordCount = editor.getLength() - 1;
			counterRes.innerHTML = wordCount;
			if (wordCount <= 3000) {
				counter.innerHTML = "(" + wordCount + "/3000)";
				counter.style.color = "#000000DE";
			} else {
				counter.innerHTML =
					"* Responsibility cannot exceed 3000 Characters (" +
					wordCount +
					"/3000)";
				counter.style.color = "red";
			}
		}
		if (editorArea) editorArea.style.border = "0";
	};

	React.useEffect(() => {
		if (jobPostRecord) {
			if (jobPostRecord.responsibility) {
				let newQuill = new Quill("#quillEditorRes");
				setInitialContentRes(
					newQuill.clipboard.convert(jobPostRecord.responsibility)
				);
			}
			if (jobPostRecord.requirement) {
				let newQuill = new Quill("#quillEditorReq");
				setInitialContentReq(
					newQuill.clipboard.convert(jobPostRecord.requirement)
				);
			}
		}
	}, [open]);

	const [initialContentRes, setInitialContentRes] = useState("");
	const [initialContentReq, setInitialContentReq] = useState("");

	return (
		<React.Fragment>
			<div id="quillEditorReq" style={{ display: "none" }} />
			<div id="quillEditorRes" style={{ display: "none" }} />
			<CustomDialog
				confirmDialog={confirmDialog}
				setConfirmDialog={setConfirmDialog}
			/>
			<Dialog
				classes={{
					root: classes.modalRoot,
					paper: classes.modalProfileEdit + " " + classes.modal,
				}}
				open={open}
				// TransitionComponent={Transition}
				// keepMounted
				onClose={onClose}
				// disableBackdropClick
				aria-labelledby="classic-modal-slide-title"
				aria-describedby="classic-modal-slide-description"
			>
				{/* {console.log(jobPostRecord.experienceId, jobPostId)} */}
				<DialogTitle
					id="signup-modal-slide-title"
					disableTypography
					className={classes.modalTitleEdit}
				>
					<Button
						simple
						className={classes.modalCloseButton}
						key="close"
						aria-label="Close"
						onClick={onClose}
					>
						{" "}
						<IconLibrary.Close />
					</Button>
					<h3 className={classes.jobCreateTitle + " " + classes.titleAlign}>
						Edit Job Post
					</h3>
				</DialogTitle>

				{/* {console.log(jobPostRecord)} */}
				<Formik
					initialValues={{
						title:
							jobPostRecord && jobPostRecord.title ? jobPostRecord.title : "",
						industryId:
							jobPostRecord && jobPostRecord.industryId === 0
								? ""
								: jobPostRecord.industryId,
						countryId:
							jobPostRecord && jobPostRecord.countryId === 0
								? ""
								: jobPostRecord.countryId,
						locationId:
							jobPostRecord && jobPostRecord.locationId === 0
								? ""
								: jobPostRecord.locationId,
						location: jobPostRecord && jobPostRecord.location,
						eduLvlId:
							jobPostRecord && jobPostRecord.eduLvlId === 0
								? ""
								: jobPostRecord.eduLvlId,
						experienceId:
							jobPostRecord && jobPostRecord.experienceId === 0
								? 0
								: jobPostRecord.experienceId,

						role: jobPostRecord && jobPostRecord.role ? jobPostRecord.role : "",
						servicePeriod:
							jobPostRecord && jobPostRecord.servicePeriod
								? jobPostRecord.servicePeriod / 4
								: 1,
						responsibility:
							jobPostRecord && jobPostRecord.responsibility
								? jobPostRecord.responsibility
								: "",
						requirement:
							jobPostRecord && jobPostRecord.requirement
								? jobPostRecord.requirement
								: "",
						currencyId:
							jobPostRecord && jobPostRecord.currencyId === 0
								? ""
								: jobPostRecord.currencyId,
						salaryMax: jobPostRecord && jobPostRecord.salaryMax,
						salaryMin: jobPostRecord && jobPostRecord.salaryMin,
						statusId: jobPostRecord && jobPostRecord.statusId,
						hashTags:
							jobPostRecord && jobPostRecord.hashTags
								? jobPostRecord.hashTags
								: [],
						jobPostTypeId:
							jobPostRecord && jobPostRecord.jobPostTypeId === 0
								? ""
								: jobPostRecord.jobPostTypeId,
						jobTypeId:
							jobPostRecord && jobPostRecord.jobTypeId === 0
								? 1
								: jobPostRecord.jobTypeId
									? jobPostRecord.jobTypeId
									: 1,
						updateJobStatus: true,
						draft: -1,
					}}
					validationSchema={corporateEditJobSchema}
					onSubmit={(data, { setSubmitting }) => {
						setSubmitting(true);
						var counterReq = document.getElementById("counterJReq");
						var counterRes = document.getElementById("counterJRes");
						var editorRes = document.querySelector(
							"#quillEditorJRes .ql-editor"
						);
						var editorReq = document.querySelector(
							"#quillEditorJReq .ql-editor"
						);

						var quillJReq = { descHTML: "", descCount: 0 };
						var quillJRes = { descHTML: "", descCount: 0 };
						if (quillRefJReq.current) {
							var comment = quillRefJReq.current;
							// console.log(editorRes, editorReq);
							quillJReq = {
								descHTML: editorReq.innerHTML,
								descCount: counterReq.innerHTML,
							};
						}
						if (quillRefJRes.current) {
							var comment = quillRefJRes.current;
							console.log(comment);
							quillJRes = {
								descHTML: editorRes.innerHTML,
								descCount: counterRes.innerHTML,
							};
						}
						console.log(quillJReq, quillJRes);
						var questFinal = [];
						if (questionsHiring.length > 0) {
							questionsHiring.map((item, index) => {
								if (item.value != '') {
									questFinal.push({
										"quesId": -1,
										"answerId": item.yesclass == 'active' ? 1 : 2,
										"recordTypeId": 1,
										"quesDescription": item.value
									})
								}

							});
						}

						const updateRequest = {
							email: userStatus.email,
							acctId: userStatus.acctId,
							accessToken: localStorage.getItem("token"),
							jobPostId: jobPostId,
							title: data.title.trim(),
							industryId: data.industryId,
							countryId: data.countryId,
							locationId: data.locationId,
							location: data.location,
							eduLvlId: data.eduLvlId,
							role: data.role.trim(),
							jobPostTypeId: data.jobPostTypeId,
							jobTypeId: data.jobTypeId,
							servicePeriod:
								data.jobTypeId !== 1 ? data.servicePeriod * 4 : null,
							responsibility: quillJRes.descHTML, //data.responsibility.trim(),
							requirement: quillJReq.descHTML, //data.requirement.trim(),
							experienceId: data.experienceId,
							currencyId: data.currencyId,
							salaryMin: data.salaryMin,
							salaryMax: data.salaryMax,
							hashTags: data.hashTags,
							questionaires: questFinal,
							statusId: data.updateJobStatus
								? data.statusId === 0
									? 1
									: data.statusId === 1
										? 2
										: 1
								: data.statusId,
							jobViewPage: jobViewPage,
							bizId: bizId,
						};
						if (data.draft == -1) {
							if (quillJReq.descCount == 0 || quillJRes.descCount == 0) {
								if (quillJRes.descCount == 0) {
									var counter = document.getElementById("quillCounterJRes");
									var editor = document.getElementById("quillEditorJRes");
									if (counter) {
										counter.innerHTML =
											"* Please enter the responsibility (0/3000)";
										counter.style.color = "red";
									}
									if (editor) editor.style.border = "1px solid red";
								}
								if (quillJReq.descCount == 0) {
									var counter = document.getElementById("quillCounterJReq");
									var editor = document.getElementById("quillEditorJReq");
									if (counter) {
										counter.innerHTML =
											"* Please enter the responsibility (0/3000)";
										counter.style.color = "red";
									}
									if (editor) editor.style.border = "1px solid red";
								}
								setSubmitting(false);
								return;
							}
						}
						if (qustion == false) {
							snackbarCustom("Please fill the answer");
							setSubmitting(false);
							return;
						}
						if (quillJReq.descCount > 3000) {
							var editor = document.getElementById("quillEditorJReq");
							if (editor) editor.style.border = "1px solid red";
							snackbarCustom("Requirement cannot exceed 3000 Characters");
							setSubmitting(false);
							return;
						}
						if (quillJRes.descCount > 3000) {
							var editor = document.getElementById("quillEditorJRes");
							if (editor) editor.style.border = "1px solid red";
							snackbarCustom("Responsibility cannot exceed 3000 Characters");
							setSubmitting(false);
							return;
						}

						const updateStatus = {
							email: userStatus.email,
							acctId: userStatus.acctId,
							accessToken: localStorage.getItem("token"),
							jobPostId: jobPostId,
							statusId:
								jobPostRecord.statusId === 0
									? 1
									: jobPostRecord.statusId === 1
										? 2
										: 1,
						};


						if (data.updateJobStatus) {
							if (data.statusId === 1) {
								const dialogDefault = {
									title: "Alert",
									description:
										"Are you sure to deactivate this job post? All the new changes will be discarded if any.",
									buttonYes: "Proceed",
									buttonNo: "Cancel",
								};
								setConfirmDialog({
									isOpen: true,
									dialogDefault,
									onConfirm: () => {
										dispatch(updateJobPostStatus(updateStatus));
										setConfirmDialog({
											isOpen: false,
											dialogDefault,
										});
										onClose();
									},
									onCancel: () => {
										setConfirmDialog({
											isOpen: false,
											dialogDefault,
										});
										setSubmitting(false);
									},
								});
							} else {
								dispatch(editJobPosts(updateRequest));
								onClose();
							}
						} else {
							dispatch(editJobPosts(updateRequest));
							onClose();
						}
					}}
				>
					{({
						handleChange,
						values,
						errors,
						touched,
						setFieldValue,
						handleSubmit,
						handleBlur,
						isSubmitting,
					}) => (
						<form onSubmit={handleSubmit}>
							<div className={classes.autoHeightBody}>
								<DialogContent
									id="classic-modal-slide-description"
									className={classes.modalBody}
								>
									<Card className={classes.modalCardBorder}>
										<CardBody
											className={
												isDesktop
													? classes.smallModalBodyDesktop +
													" " +
													classes.modalBackground
													: classes.smallModalBody +
													" " +
													classes.modalBackground
											}
											id="modalJob"
										>
											<GridContainer justifyContent="center">
												<GridItem>
													<h4 className={classes.jobCreateTitle}>Job Info</h4>
												</GridItem>
												<GridItem className={classes.gridHeight}>
													<TextField
														label={
															touched.title && Boolean(errors.title)
																? errors.title
																: ""
														}
														variant="outlined"
														fullWidth
														onChange={(event) => {
															setFieldValue("title", event.target.value);
														}}
														value={values.title}
														error={touched.title && Boolean(errors.title)}
														/* helperText={touched.title ? errors.title : ""} */
														placeholder="Ex: UI Designer"
														id="title"
														name="title"
													/* InputProps={{
															startAdornment: (
																<InputAdornment position="start">
																	<WorkIcon
																		className={classes.inputIconsColor}
																	/>
																</InputAdornment>
															),
														}} */
													/>
												</GridItem>
												<GridItem>
													<GridContainer>
														<GridItem xs={12} sm={12} md={6}>
															<h4 className={classes.jobCreateTitle}>
																Job Type
															</h4>
														</GridItem>

														<GridItem xs={12} sm={12} md={6}>
															{(values.jobPostTypeId === 2 ||
																values.jobPostTypeId === 1) && (
																	<Hidden smDown>
																		<h4 className={classes.jobCreateTitle}>
																			Job Post Type
																		</h4>
																	</Hidden>
																)}
														</GridItem>
														<GridItem
															xs={12}
															sm={12}
															md={6}
															className={classes.gridHeight}
														>
															<Autocomplete
																options={jobPostTypeList}
																value={
																	jobPostTypeList.filter((item) => {
																		return (
																			item.jobPostTypeId ===
																			values.jobPostTypeId
																		);
																	})[0] || null
																}
																getOptionLabel={(option) =>
																	option.jobPostType ? option.jobPostType : ""
																}
																getOptionSelected={(option, value) => {
																	if (
																		option.jobPostTypeId === value.jobPostTypeId
																	) {
																		//console.log(option, value);
																		return true;
																	} else {
																		return false;
																	}
																}}
																onChange={(event, value) => {
																	if (value) {
																		setFieldValue(
																			"jobPostTypeId",
																			value.jobPostTypeId
																		);
																		setJobPostTypeChange(value.jobPostTypeId);
																		setFieldValue("jobTypeId", "");
																	} else {
																		setFieldValue("jobPostTypeId", "");
																		setFieldValue("jobTypeId", "");
																	}
																}}
																id="jobPostTypeId"
																name="jobPostTypeId"
																renderInput={(params) => (
																	<TextField
																		{...params}
																		/* label={"Job Post Type"} */
																		label={
																			touched.jobPostTypeId
																				? errors.jobPostTypeId
																				: ""
																		}
																		variant="outlined"
																		error={
																			touched.jobPostTypeId &&
																			Boolean(errors.jobPostTypeId)
																		}
																	/* helperText={
																		touched.jobPostTypeId
																			? errors.jobPostTypeId
																			: ""
																	} */
																	/>
																)}
															/>
														</GridItem>
														<Hidden mdUp>
															<GridItem xs={12} sm={12} md={6}>
																{(values.jobPostTypeId === 1 ||
																	values.jobPostTypeId === 2) && (
																		<h4 className={classes.jobCreateTitle}>
																			Job Post Type
																		</h4>
																	)}
															</GridItem>
														</Hidden>

														{(values.jobPostTypeId === 1 ||
															values.jobPostTypeId === 2) && (
																<GridItem
																	xs={12}
																	sm={12}
																	md={6}
																	className={classes.gridHeight}
																>
																	<Autocomplete
																		options={jobTypeList}
																		onBlur={handleBlur}
																		value={
																			jobTypeList.filter((item) => {
																				return (
																					item.jobTypeId === values.jobTypeId
																				);
																			})[0] || null
																		}
																		getOptionLabel={(option) =>
																			option.jobType ? option.jobType : ""
																		}
																		getOptionSelected={(option, value) => {
																			if (option.jobTypeId === value.jobTypeId) {
																				//console.log(option, value);
																				return true;
																			} else {
																				return false;
																			}
																		}}
																		onChange={(event, value) => {
																			if (value) {
																				setFieldValue(
																					"jobTypeId",
																					value.jobTypeId
																				);
																			}
																		}}
																		id="jobTypeId"
																		name="jobTypeId"
																		renderInput={(params) => (
																			<TextField
																				{...params}
																				/* label={"Job Type"} */
																				variant="outlined"
																				error={
																					touched.jobTypeId &&
																					Boolean(errors.jobTypeId)
																				}
																				label={
																					touched.jobTypeId
																						? errors.jobTypeId
																						: ""
																				}
																			/* helperText={
																				
																			} */
																			/>
																		)}
																	/>
																</GridItem>
															)}
													</GridContainer>
												</GridItem>
												<GridItem xs={12} sm={12} md={12}>
													<h4 className={classes.jobCreateTitle}>
														Company Industry
													</h4>
												</GridItem>
												<GridItem className={classes.gridHeight}>
													<Autocomplete
														options={industryList}
														onBlur={handleBlur}
														value={
															industryList.filter((item) => {
																return item.industryId === values.industryId;
															})[0] || null
														}
														getOptionLabel={(option) =>
															option.industryName ? option.industryName : ""
														}
														getOptionSelected={(option, value) => {
															if (option.industryId === value.industryId) {
																//console.log(option, value);
																return true;
															} else {
																return false;
															}
														}}
														onChange={(event, value) => {
															if (value) {
																setFieldValue("industryId", value.industryId);
															}
														}}
														id="industryId"
														name="industryId"
														renderInput={(params) => (
															<TextField
																{...params}
																/* label={"Company Industry"} */
																variant="outlined"
																error={
																	touched.industryId &&
																	Boolean(errors.industryId)
																}
																label={
																	touched.industryId ? errors.industryId : ""
																}
															/* helperText={
																
															} */
															/>
														)}
													/>
												</GridItem>
											</GridContainer>
											<GridContainer justifyContent="center">
												<GridItem xs={12} sm={6} md={6}>
													<h4 className={classes.jobCreateTitle}>Country</h4>
												</GridItem>

												<Hidden xsDown>
													<GridItem xs={12} sm={6} md={6}>
														<h4 className={classes.jobCreateTitle}>Location</h4>
													</GridItem>
												</Hidden>
												<GridItem
													xs={12}
													sm={6}
													md={6}
													className={classes.gridHeight}
												>
													<Autocomplete
														options={countryList}
														onBlur={handleBlur}
														filterOptions={createFilterOptions({
															matchFrom: "start",
															stringify: (option) => option.countryName,
														})}
														value={
															countryList.filter((item) => {
																return item.countryId === values.countryId;
															})[0] || null
														}
														getOptionLabel={(option) =>
															option.countryName ? option.countryName : ""
														}
														getOptionSelected={(option, value) => {
															if (option === value) {
																//console.log(option, value);
																return true;
															} else {
																return false;
															}
														}}
														onChange={(event, value) => {
															if (value) {
																setFieldValue("countryId", value.countryId);
																setCountryChange(value.countryId);
															} else {
																setFieldValue("countryId", "");
															}
															setFieldValue("locationId", "");
														}}
														id="countryId"
														name="countryId"
														renderInput={(params) => (
															<TextField
																{...params}
																/* 	label={"Country"} */
																label={
																	touched.countryId ? errors.countryId : ""
																}
																variant="outlined"
																error={
																	touched.countryId && Boolean(errors.countryId)
																}
															/* helperText={
																
															} */
															/>
														)}
													/>
												</GridItem>
												<Hidden smUp>
													<GridItem>
														<h4 className={classes.jobCreateTitle}>Location</h4>
													</GridItem>
												</Hidden>
												<GridItem
													xs={12}
													sm={6}
													md={6}
													className={classes.gridHeight}
												>
													{/* <CustomInput
													labelText="Location"
													id="location"
													name="location"
													values={values.location}
													onChange={handleChange}
													onBlur={handleBlur}
													formControlProps={{ fullWidth: true }}
													inputProps={{
														startAdornment: (
															<InputAdornment position="start">
																<LocationCityIcon
																	className={classes.inputIconsColor}
																/>
															</InputAdornment>
														),
														autoComplete: "off",
													}}
												/>
												{errors.location && touched.location && (
													<Danger>
														<h6>{errors.location}</h6>
													</Danger>
												)} */}
													<Autocomplete
														options={locationList}
														onBlur={handleBlur}
														disabled={!values.countryId}
														value={
															locationList.filter((item) => {
																return item.locationId === values.locationId;
															})[0] || null
														}
														getOptionLabel={(option) =>
															option.locationName ? option.locationName : ""
														}
														getOptionSelected={(option, value) => {
															if (option === value) {
																//console.log(option, value);
																return true;
															} else {
																return false;
															}
														}}
														onChange={(event, value) => {
															if (value) {
																setFieldValue("locationId", value.locationId);
															} else {
																setFieldValue("locationId", "");
															}
														}}
														id="locationId"
														name="locationId"
														renderInput={(params) => (
															<TextField
																{...params}
																/* label={"Location"} */
																label={
																	touched.locationId ? errors.locationId : ""
																}
																variant="outlined"
																error={
																	touched.locationId &&
																	Boolean(errors.locationId)
																}
															/* helperText={
																
															} */
															/>
														)}
													/>
												</GridItem>
											</GridContainer>
											<GridContainer justifyContent="center">
												<GridItem>
													<h4 className={classes.jobCreateTitle}>
														Minimum Level of Education Required
													</h4>
												</GridItem>
												<GridItem className={classes.gridHeight}>
													<Autocomplete
														options={educationList}
														onBlur={handleBlur}
														value={
															educationList.filter((item) => {
																return item.eduLvlId === values.eduLvlId;
															})[0] || null
														}
														getOptionSelected={(option, value) => {
															return option === value;
														}}
														getOptionLabel={(option) =>
															option.eduLvlName ? option.eduLvlName : ""
														}
														onChange={(event, value) => {
															if (value) {
																setFieldValue("eduLvlId", value.eduLvlId);
															}
														}}
														id="eduLvlId"
														name="eduLvlId"
														renderInput={(params) => (
															<TextField
																{...params}
																/* label={"Level of Education Required"} */
																label={touched.eduLvlId ? errors.eduLvlId : ""}
																variant="outlined"
																error={
																	touched.eduLvlId && Boolean(errors.eduLvlId)
																}
															/* 	helperText={
															
															} */
															/>
														)}
													/>
												</GridItem>
												<GridItem>
													<h4 className={classes.jobCreateTitle}>Role</h4>
												</GridItem>
												<GridItem className={classes.gridHeight}>
													<TextField
														label={
															touched.role && Boolean(errors.role)
																? errors.role
																: ""
														}
														variant="outlined"
														fullWidth
														value={values.role}
														onChange={(event) => {
															setFieldValue("role", event.target.value);
														}}
														error={touched.role && Boolean(errors.role)}
														/* helperText={touched.role ? errors.role : ""} */
														id="role"
														name="role"
													/>
												</GridItem>
												<GridItem>
													<h4 className={classes.jobCreateTitle}>
														Minimum Experience Level
													</h4>
													<h5
														className={
															classes.jobCreateTitle +
															" " +
															classes.grayDescription
														}
													>
														{/* {values.experienceId === 1
															? "Less than a Year"
															: values.experienceId === 12
															? "More than 10 Years"
															: values.experienceId + " Years"} */}
														{experienceLevelList[0] &&
															experienceLevelList[
																values.experienceId === 0
																	? values.experienceId
																	: values.experienceId - 1
															].experience}
													</h5>
												</GridItem>
												<GridItem>
													<GridContainer>
														<GridItem
															xs={2}
															sm={1}
															md={1}
															className={classes.textCenter}
														>
															<span
																className={
																	classes.jobCreateTitle +
																	" " +
																	classes.grayDescription
																}
															>
																0
															</span>
														</GridItem>
														<GridItem xs={7} sm={9} md={10}>
															<Slider
																value={
																	typeof values.experienceId === "number"
																		? values.experienceId
																		: 0
																}
																onChange={(event, value) => {
																	setFieldValue("experienceId", value);
																}}
																/* 	name="servicePeriod"
																	id="servicePeriod" */
																step={1}
																min={
																	experienceLevelList[0]
																		? experienceLevelList[0].experienceId
																		: 1
																}
																max={
																	experienceLevelList[0]
																		? experienceLevelList[
																			experienceLevelList.length - 1
																		].experienceId
																		: 12
																}
																aria-labelledby="input-slider"
															/>
														</GridItem>
														<GridItem
															xs={3}
															sm={2}
															md={1}
															className={classes.textCenter}
														>
															<span
																className={
																	classes.jobCreateTitle +
																	" " +
																	classes.grayDescription
																}
															>
																10+
															</span>
														</GridItem>
													</GridContainer>
												</GridItem>
												<GridItem>
													<h4 className={classes.jobCreateTitle}>Job Tags</h4>
												</GridItem>

												<GridItem className={classes.gridHeight}>
													{tagError ? (
														<SnackbarContent
															message={<b>Duplicate Tag is not allowed</b>}
															close
															color="opptyWarning"
															icon="info_outline"
														/>
													) : null}
													{maxLengthError && (
														<SnackbarContent
															message={<b>Tag cannot exceed 100 characters</b>}
															close
															color="opptyWarning"
															icon="info_outline"
														/>
													)}
													{values.hashTags.map((item, index) => {
														return (
															item.hashtag && (
																<Chip
																	key={index}
																	label={
																		<span className={classes.tagDescription}>
																			{item.hashtag}
																		</span>
																	}
																	className={classes.chip}
																	onDelete={() => {
																		console.log(item);
																		setFieldValue(
																			"hashTags",
																			values.hashTags.filter((tag) => {
																				return tag !== item;
																			})
																		);
																	}}
																/>
															)
														);
													})}

													<TextField
														id="tagInput"
														value={hashtag}
														variant="outlined"
														fullWidth
														disabled={
															values.hashTags.length >= 10 ? true : false
														}
														style={{ marginTop: "20px" }}
														onKeyPress={(data) => {
															if (data.target.value) {
																if (data.target.value.trim().length > 0) {
																	if (data.charCode === 13) {
																		if (data.target.value.length > 50) {
																			data.preventDefault();
																			setMaxLengthError(true);
																			setTagError(false);
																			// setTagInput("");
																		} else {
																			setMaxLengthError(false);
																			const index = values.hashTags.findIndex(
																				(item) => {
																					if (item.hashtag) {
																						return (
																							item.hashtag.toLowerCase() ===
																							hashtag.toLowerCase()
																						);
																					} else {
																						return item.hashtag === hashtag;
																					}
																				}
																			);
																			console.log(index);
																			if (index === -1) {
																				values.hashTags = values.hashTags.concat(
																					{
																						hashId: -1,
																						hashtag: hashtag,
																					}
																				);
																				data.preventDefault();
																				setTagInput("");
																				setTagError(false);
																			} else {
																				data.preventDefault();
																				setTagInput("");
																				setTagError(true);
																			}
																		}
																		//setFieldValue("hashTags", { tag: tagInput });
																	}
																}
															}
														}}
														onBlur={(data) => {
															if (data.target.value.trim().length > 0) {
																if (data.target.value.length > 50) {
																	data.preventDefault();
																	setMaxLengthError(true);
																	setTagError(false);
																	// setTagInput("");
																} else {
																	setMaxLengthError(false);
																	const index = values.hashTags.findIndex(
																		(item) => {
																			if (item.hashtag) {
																				return (
																					item.hashtag.toLowerCase() ===
																					hashtag.toLowerCase()
																				);
																			} else {
																				return item.hashtag === hashtag;
																			}
																		}
																	);
																	console.log(index);
																	if (index === -1) {
																		values.hashTags = values.hashTags.concat({
																			hashId: -1,
																			hashtag: hashtag,
																		});
																		data.preventDefault();
																		setTagInput("");
																		setTagError(false);
																	} else {
																		data.preventDefault();
																		setTagInput("");
																		setTagError(true);
																	}
																}
																//setFieldValue("hashTags", { tag: tagInput });
															}
														}}
														onKeyDown={() => {
															if (tagError || maxLengthError) {
																setTagError(false);
																setMaxLengthError(false);
															}
														}}
														label={
															values.hashTags.length >= 10 ? (
																<p>Maximum Tag Reached</p>
															) : (
																<p>Add Tag</p>
															)
														}
														onChange={handleTag}
													/>
													<Button
														justIcon
														style={{ boxShadow: "none" }}
														color="white"
														className={classes.addTagPos}
													>
														<IconLibrary.Create />
													</Button>
												</GridItem>
											</GridContainer>
											{values.jobPostTypeId !== 2 && (
												<GridContainer justifyContent="center">
													<GridItem>
														<h4 className={classes.jobCreateTitle}>
															Service Periods
														</h4>
														<h5
															className={
																classes.jobCreateTitle +
																" " +
																classes.grayDescription
															}
														>
															{yearMonthValue(values.servicePeriod)}
														</h5>
													</GridItem>
													<GridItem xs={8} sm={10} md={10}>
														<Slider
															value={
																typeof values.servicePeriod === "number"
																	? values.servicePeriod
																	: 0
															}
															onChange={(event, value) => {
																setFieldValue("servicePeriod", value);
															}}
															name="servicePeriod"
															id="servicePeriod"
															step={1}
															min={1}
															max={24}
															//marks={sizeMarks}
															aria-labelledby="input-slider"
														/>
														{errors.servicePeriod && touched.servicePeriod && (
															<Danger>
																<h6>{errors.servicePeriod}</h6>
															</Danger>
														)}
													</GridItem>
													<GridItem xs={4} sm={2} md={2}>
														<Input
															fullWidth
															className={classes.input}
															value={values.servicePeriod}
															variant="outlined"
															onChange={(event) => {
																const regex = /^[0-9\b]+$/;
																if (
																	event.target.value === "" ||
																	regex.test(event.target.value)
																) {
																	setFieldValue(
																		"servicePeriod",
																		event.target.value === ""
																			? ""
																			: Number(event.target.value)
																	);
																}
															}}
															inputProps={{
																step: 1,
																min: 0,
																max: 24,
															}}
														/>
													</GridItem>
												</GridContainer>
											)}
											{values.jobPostTypeId === 2 && values.jobTypeId !== 1 && (
												<GridContainer justifyContent="center">
													<GridItem>
														<h4 className={classes.jobCreateTitle}>
															Service Periods
														</h4>
														<h5
															className={
																classes.jobCreateTitle +
																" " +
																classes.grayDescription
															}
														>
															{yearMonthValue(values.servicePeriod)}
														</h5>
													</GridItem>
													<GridItem xs={8} sm={10} md={10}>
														<Slider
															value={
																typeof values.servicePeriod === "number"
																	? values.servicePeriod
																	: 0
															}
															onChange={(event, value) => {
																setFieldValue("servicePeriod", value);
															}}
															name="servicePeriod"
															id="servicePeriod"
															step={1}
															min={0}
															max={24}
															//marks={sizeMarks}
															aria-labelledby="input-slider"
														/>
														{errors.servicePeriod && touched.servicePeriod && (
															<Danger>
																<h6>{errors.servicePeriod}</h6>
															</Danger>
														)}
													</GridItem>
													<GridItem xs={4} sm={2} md={2}>
														<Input
															fullWidth
															className={classes.input}
															value={values.servicePeriod}
															variant="outlined"
															onChange={(event) => {
																const regex = /^[0-9\b]+$/;
																if (
																	event.target.value === "" ||
																	regex.test(event.target.value)
																) {
																	setFieldValue(
																		"servicePeriod",
																		event.target.value === ""
																			? ""
																			: Number(event.target.value)
																	);
																}
															}}
															inputProps={{
																step: 1,
																min: 0,
																max: 24,
															}}
														/>
													</GridItem>
												</GridContainer>
											)}
											<GridContainer justifyContent="center">
												<GridItem>
													<h4 className={classes.jobCreateTitle}>
														Job Requirement
													</h4>
												</GridItem>
												<GridItem className={classes.gridHeight}>
													{/* <TextField
														label={
															touched.requirement && Boolean(errors.requirement)
																? errors.requirement
																: ""
														}
														variant="outlined"
														fullWidth
														value={values.requirement}
														placeholder="Required"
														onChange={(event) => {
															setFieldValue("requirement", event.target.value);
														}}
														error={
															touched.requirement && Boolean(errors.requirement)
														}
														name="requirement"
														InputProps={{
															multiline: true,
															rows: 5,
														}}
													/> */}
													<ReactQuill
														ref={quillRefJReq}
														placeholder="Required"
														// className={classes.quillEditor}
														theme="snow"
														multiline={true}
														// contenteditable={true}
														modules={modules}
														defaultValue={initialContentReq}
														// onChange={setValue}
														// value={values.requirement}
														onChange={onQuillChangeJReq}
														id="quillEditorJReq"
													/>
													<div
														style={{ fontSize: "14px" }}
														id="quillCounterJReq"
													>
														(0/3000)
													</div>
													<div style={{ display: "none" }} id="counterJReq">
														0
													</div>
												</GridItem>
												<GridItem>
													<h4 className={classes.jobCreateTitle}>
														Job Responsibility
													</h4>
												</GridItem>
												<GridItem className={classes.gridHeight}>
													{/* <TextField
														label={
															touched.responsibility &&
															Boolean(errors.responsibility)
																? errors.responsibility
																: ""
														}
														variant="outlined"
														fullWidth
														value={values.responsibility}
														placeholder="Required"
														onChange={(event) => {
															setFieldValue(
																"responsibility",
																event.target.value
															);
														}}
														error={
															touched.responsibility &&
															Boolean(errors.responsibility)
														}
														name="responsibility"
														InputProps={{
															multiline: true,
															rows: 5,
														}}
													/> */}
													<ReactQuill
														ref={quillRefJRes}
														placeholder="Required"
														// className={classes.quillEditor}
														theme="snow"
														multiline={true}
														// contenteditable={true}
														modules={modules}
														defaultValue={initialContentRes}
														// onChange={setValue}
														// value={values.responsibility}
														onChange={onQuillChangeJRes}
														id="quillEditorJRes"
													/>
													<div
														style={{ fontSize: "14px" }}
														id="quillCounterJRes"
													>
														(0/3000)
													</div>
													<div style={{ display: "none" }} id="counterJRes">
														0
													</div>
												</GridItem>
											</GridContainer>
											<GridContainer justifyContent="center">
												<GridItem>
													<h4 className={classes.jobCreateTitle}>Salary</h4>
												</GridItem>
											</GridContainer>
											<GridContainer>
												<GridItem
													xs={12}
													sm={6}
													md={6}
													className={classes.gridHeight}
												>
													<Autocomplete
														options={currencyList}
														onBlur={handleBlur}
														value={
															currencyList.filter((item) => {
																return item.currencyId === values.currencyId;
															})[0] || ""
														}
														getOptionSelected={(option, value) => {
															if (!value) {
																return false;
															}
															return option.currencyId === value.currencyId;
														}}
														getOptionLabel={(option) =>
															option.currencyCode ? option.currencyCode : ""
														}
														onChange={(event, value) => {
															if (value) {
																setFieldValue("currencyId", value.currencyId);
																/* setFieldValue(
																		"salaryMin",
																		salaryRange[value.currencyId - 1].minSalary
																	);
																	setFieldValue(
																		"salaryMax",
																		salaryRange[value.currencyId - 1].maxSalary
																	); */
															} else {
																setFieldValue("currencyId", "");
															}
														}}
														id="currencyId"
														name="currencyId"
														renderInput={(params) => (
															<TextField
																{...params}
																label={
																	touched.currencyId &&
																		Boolean(errors.currencyId)
																		? errors.currencyId
																		: "Currency List"
																}
																variant="outlined"
																error={
																	touched.currencyId &&
																	Boolean(errors.currencyId)
																}
															/* helperText={
																touched.currencyId ? errors.currencyId : ""
															} */
															/>
														)}
													/>
												</GridItem>
												<GridItem
													xs={12}
													sm={3}
													md={3}
													className={classes.gridHeight}
												>
													<TextField
														fullWidth
														className={classes.input}
														value={values.salaryMin}
														/* label={
															touched.salaryMin && Boolean(errors.salaryMin)
																? errors.salaryMin
																: "Minimum Salary"
														} */
														error={
															touched.salaryMin && Boolean(errors.salaryMin)
														}
														label="Minimum Salary"
														/* helperText={
															touched.salaryMin ? errors.salaryMin : ""
														} */
														variant="outlined"
														onChange={(event) => {
															const regex = /^[0-9\b]+$/;
															if (
																event.target.value === "" ||
																regex.test(event.target.value)
															) {
																setFieldValue(
																	"salaryMin",
																	event.target.value === ""
																		? ""
																		: Number(event.target.value)
																);
															}
														}}
														InputProps={{
															/* step: values.currencyId
																	? salaryRange[values.currencyId - 1].step
																	: null,
																min: values.currencyId
																	? salaryRange[values.currencyId - 1].minRange
																	: null,
																max: values.currencyId
																	? salaryRange[values.currencyId - 1].maxRange
																	: null,
																type: "number", */

															"aria-labelledby": "input-slider",
														}}
													/>
													{errors.salaryMin && touched.salaryMin && (
														<Danger>
															<h6>{errors.salaryMin}</h6>
														</Danger>
													)}
												</GridItem>
												<GridItem
													xs={12}
													sm={3}
													md={3}
													className={classes.gridHeight}
												>
													<TextField
														fullWidth
														variant="outlined"
														className={classes.input}
														value={values.salaryMax}
														label="Maximum Salary"
														error={
															touched.salaryMax && Boolean(errors.salaryMax)
														}
														/* helperText={
															touched.salaryMax ? errors.salaryMax : ""
														} */
														onChange={(event) => {
															const regex = /^[0-9\b]+$/;
															if (
																event.target.value === "" ||
																regex.test(event.target.value)
															) {
																setFieldValue(
																	"salaryMax",
																	event.target.value === ""
																		? ""
																		: Number(event.target.value)
																);
															}
														}}
														onBlur={handleBlur}
														InputProps={{
															/* step: values.currencyId
																	? salaryRange[values.currencyId - 1].step
																	: null,
																min: values.currencyId
																	? salaryRange[values.currencyId - 1].minRange
																	: null,
																max: values.currencyId
																	? salaryRange[values.currencyId - 1].maxRange
																	: null, */
															type: "number",
														}}
													/>
													{errors.salaryMax && touched.salaryMax && (
														<Danger>
															<h6>{errors.salaryMax}</h6>
														</Danger>
													)}
												</GridItem>
											</GridContainer>
											{values.jobPostTypeId !== 2 && (
												<GridContainer>
													<GridItem>
														<h4 className={classes.jobCreateTitle}>
															Salary Range
														</h4>
														<h5
															className={
																classes.jobCreateTitle +
																" " +
																classes.grayDescription
															}
														>
															{values.salaryMin}{" "}
															{currencyList
																.filter((item) => {
																	return item.currencyId === values.currencyId;
																})
																.map((item) => {
																	return item.currencyCode;
																})}{" "}
															- {values.salaryMax}{" "}
															{currencyList
																.filter((item) => {
																	return item.currencyId === values.currencyId;
																})
																.map((item) => {
																	return item.currencyCode;
																})}
														</h5>
													</GridItem>

													<GridItem xs={12} sm={12} md={12}>
														<Slider
															value={[
																Number(values.salaryMin),
																Number(values.salaryMax),
															]}
															onChange={(event, value) => {
																if (value) {
																	setFieldValue("salaryMin", value[0]);
																	setFieldValue("salaryMax", value[1]);
																}
															}}
															step={100}
															min={0}
															max={
																values.salaryMax > values.salaryMin
																	? Number(values.salaryMax) + 1000
																	: Number(values.salaryMin) + 1000
															}
															aria-labelledby="input-slider"
														/>
													</GridItem>
													{/* {console.log(
														"current min and max is ",
														values.salaryMin,
														values.salaryMax
													)} */}
												</GridContainer>
											)}
											<GridContainer>
												<GridItem xs={12} sm={12} md={12}>
													<Card className={classes.cardHeight}>
														<CardBody>
															<Typography className='interviewDiv'>
																<Typography className='interviewDivLeft'>
																	<Typography className='interview_Question'>
																		Pre-Interview Questions</Typography>
																</Typography>
																<Typography className='interviewDivRight'>
																	<label className="labelCustom ideal_ans">Please choose the ideal answer</label>
																	<Typography className='idealAnswer idealAnswerFirst'>
																	</Typography>
																</Typography>
															</Typography>
															{questionsHiring.length > 0 && questionsHiring.map((message, index) => {
																return (<Typography className='interviewDiv'>
																	<Typography className='interviewDivLeft'>
																		<Typography className='interviewLeftInner'>
																			<span className='numberSpan'>{index + 1}.</span>
																			<input className='questionP' type="text" name={`${message.name}`} value={`${message.value}`} onChange={handleFirstQuestionChange} />
																		</Typography>
																	</Typography>
																	<Typography className='interviewDivRight'>
																		<Typography className='idealAnswer'>
																			<button type="button" className={`${message.yesclass}`} value={`/affirm_${message.value}`} onClick={() => { hiringQuestionAnswer(`/affirm_${message.value}`, 10) }}>Yes</button>
																			<button type="button" className={`${message.noclass}`} value={`/deny_${message.value}`} onClick={() => { hiringQuestionAnswer(`/deny_${message.value}`, 10) }}>No</button>
																		</Typography>
																	</Typography>
																</Typography>)
															})}

															<Typography className='addQuestionDiv'>
																<Typography className='idealAnswer'>
																	<button type="button" onClick={addNewQuestions}>Add Question</button>
																</Typography>
															</Typography>
														</CardBody>
													</Card>
												</GridItem>
											</GridContainer>
										</CardBody>
									</Card>
								</DialogContent>
							</div>
							<DialogActions className={classes.modalFooterEdit}>
								{jobPostRecord && jobPostRecord.statusId === 0 ? (
									<React.Fragment>
										<Button
											onClick={(event) => {
												values.updateJobStatus = true;

												handleSubmit();
											}}
											color="opptyWrapBorder"
											round
										>
											<b>Publish</b>
										</Button>

										<Button
											onClick={(event) => {
												values.updateJobStatus = false;
												values.draft = 0;
												handleSubmit();
											}}
											color="oppty"
											round
										>
											<b>Update</b>
										</Button>
									</React.Fragment>
								) : jobPostRecord && jobPostRecord.statusId === 1 ? (
									<React.Fragment>
										<Button
											onClick={(event) => {
												values.updateJobStatus = true;
												handleSubmit();
											}}
											color="grayWrapBorder"
											round
										>
											<b>Deactivate</b>
										</Button>

										<Button
											onClick={(event) => {
												values.updateJobStatus = false;
												handleSubmit();
											}}
											color="oppty"
											round
										>
											<b>Update</b>
										</Button>
									</React.Fragment>
								) : (
									<React.Fragment>
										<Button
											onClick={(event) => {
												values.updateJobStatus = true;
												handleSubmit();
											}}
											color="opptyWrapBorder"
											round
										>
											<b>Publish</b>
										</Button>

										<Button
											onClick={(event) => {
												values.updateJobStatus = false;
												handleSubmit();
											}}
											color="oppty"
											round
										>
											<b>Update</b>
										</Button>
									</React.Fragment>
								)}
							</DialogActions>

							{isSubmitting ? (
								// <LoadingModal />
								<Backdrop />
							) : null}
						</form>
					)}
				</Formik>
			</Dialog>
		</React.Fragment>
	);
}
