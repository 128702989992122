import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/CustomButtons/Button';
import { Box, makeStyles } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { deleteGroup, updateGroup } from 'configurations/redux/groupsSlice';
import { encryptData, groupValidationSchema, userGroupRoles } from 'configurations/configurations';
import { useModal } from 'hooks';
import { blobToB64 } from 'configurations/configurations';
import { checkGroupNameDuplicate } from 'configurations/redux/groupsSlice';
import GroupForm from '../GroupsPage/GroupForm';
import ConfirmModal from './ConfirmModal';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import profilePageStyle from 'assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js';
const useStyles = makeStyles(profilePageStyle);

export default function GroupSettings({
  currentUser,
  saveSettingsModalProps,
  onSaveChangesConfirm,
  setIsGroupSettingsChanged,
  isDesktop,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const deleteGroupModalProps = useModal();
  const user = useSelector(state => state.auth.user);
  const { groupById } = useSelector(state => state.groups);
  const accessToken = localStorage.getItem('token');
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const isUserOwner = currentUser.roleId === userGroupRoles.OWNER.id;
  const { groupId } = groupById;

  const onUpdateGroup = async (values, helpers) => {
    let isGroupDuplicate = false;
    if (values.groupName !== groupById.groupName) {
      isGroupDuplicate = await checkGroupNameDuplicate({
        email: user.email,
        groupName: values.groupName,
      });
    }
    if (isGroupDuplicate) {
      helpers.setFieldError('groupName', 'This group name already exists.Try another name.');
    } else {
      const body = {
        accessToken,
        acctId: user.acctId,
        email: user.email,
        groupStatusId: 1,
        groupId,
        ...values,
        customIndustryName: values.customIndustryName ?? '',
        permissionId: values.permissionId.length < 1 ? '0' : values.permissionId.toString(),
        countryId: Number(values.countryId),
        profilePic: values.profilePic?.blob ? await blobToB64(values.profilePic.blob) : groupById.profilePic,
      };
      console.log(body);
      dispatch(updateGroup(body)).then(() => {
        onSaveChangesConfirm();
        enqueueSnackbar('Group successfully updated', {
          variant: 'success',
        });
      });
    }
  };

  const onDeleteGroup = () => {
    const body = {
      accessToken,
      acctId: user.acctId,
      email: user.email,
      groupId,
    };
    dispatch(deleteGroup(body)).then(() => {
      const encryptedAcctId = encryptData(user.acctId);
      history.push(`/groups/${encryptedAcctId}`);
      enqueueSnackbar('Group successfully deleted', {
        variant: 'success',
      });
    });
  };

  return (
    <>
      <Formik
        onSubmit={onUpdateGroup}
        validateOnChange={false}
        validationSchema={groupValidationSchema}
        initialValues={{
          groupName: groupById?.groupName ?? '',
          groupDescription: groupById?.groupDescription ?? '',
          profilePic: groupById?.profilePic ?? '',
          industryId: groupById?.industryId ?? null,
          customIndustryName: groupById?.customIndustryName ?? null,
          countryId: groupById?.countryId ?? null,
          groupRule: groupById?.groupRule ?? '',
          visibleId: groupById?.visibleId ?? 1,
          permissionId: groupById?.permissionId?.split(',') ?? [],
        }}
      >
        {({ handleSubmit, ...rest }) => {
          if (rest.dirty) {
            setIsGroupSettingsChanged(rest.dirty);
          } else {
            setIsGroupSettingsChanged(false);
          }
          return (
            <>
              <div className={classes.groupSettingBody} style={{ padding: '20px 20px 0px', margin: 0 }}>
                <GroupForm {...{ ...rest }} />
              </div>
              <Box
                display="flex"
                flexDirection="row-reverse"
                gridGap="10px"
                bgcolor="#EFF3FE"
                justifyContent={isDesktop ? 'flex-start' : 'center'}
              >
                <Button round onClick={handleSubmit} size="sm" color="oppty">
                  <b>Update Group</b>
                </Button>
                {isUserOwner && (
                  <Button round onClick={deleteGroupModalProps.toggle} size="sm" color="danger">
                    <b>Delete Group</b>
                  </Button>
                )}
              </Box>
              <ConfirmModal
                {...{ ...saveSettingsModalProps, handleSubmit }}
                handleCancel={onSaveChangesConfirm}
                modalOpen={rest.dirty && saveSettingsModalProps.modalOpen}
                bodyContent={{
                  title: 'Group settings was changed',
                  text: 'Save changes?',
                  confirmText: 'Yes',
                  cancelText: 'No',
                }}
              />
            </>
          );
        }}
      </Formik>
      <ConfirmModal
        {...{ ...deleteGroupModalProps }}
        bodyContent={{
          title: 'Delete group',
          text: `Are you sure you want to delete group?`,
        }}
        handleSubmit={onDeleteGroup}
      />
    </>
  );
}
