import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { departmentViewRoadmap, viewRoadmapRoleByRoleId } from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";
import ImageMapper from "react-image-mapper";
import Department_Revenue_Distribution from "assets/img/Career_Roadmap/Hospitality/Department_Revenue_Distribution_1.jpg";
import RoleInfoModal from "../RoleInfoModal.js";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentRevenueDistribution() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Revenue_Distribution;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "rd",
				shape: "poly",
				coords: [173,209,187,213,192,216,196,226,196,567,192,578,187,584,182,586,170,587,83,587,74,582,70,576,68,568,67,229,69,220,74,214,81,211,89,209,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "60",
				shape: "poly",
				coords: [380,207,390,210,395,213,401,219,401,230,400,285,397,296,391,301,382,304,230,303,218,300,214,295,212,294,210,286,210,225,212,217,216,212,222,208,233,207,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "61",
				shape: "poly",
				coords: [829,200,838,201,845,205,851,213,851,221,850,290,846,299,842,304,834,306,658,306,647,303,643,297,641,289,641,221,642,212,648,205,655,202,668,200,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "64",
				shape: "poly",
				coords: [1313,203,1322,204,1330,208,1335,213,1336,225,1336,296,1333,303,1327,308,1314,310,1145,309,1136,308,1129,303,1126,290,1126,226,1127,217,1131,209,1136,205,1145,204,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "65",
				shape: "poly",
				coords: [1828,350,1840,352,1848,355,1852,360,1853,372,1852,422,1850,432,1843,437,1827,438,1682,438,1674,438,1666,432,1662,426,1663,367,1665,357,1671,351,1682,350,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "62",
				shape: "poly",
				coords: [834,482,843,485,852,496,852,507,851,574,848,583,842,588,835,588,658,587,650,586,644,581,642,575,643,500,645,490,651,485,661,482,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "63",
				shape: "poly",
				coords: [1317,483,1327,486,1333,493,1335,499,1335,571,1333,582,1327,588,1318,590,1142,590,1134,588,1129,585,1126,577,1125,504,1127,493,1133,486,1146,483,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "rd") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo)
			dispatch(viewRoadmapRoleByRoleId(requestInfo));
			
			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// console.log(coords.x + "," + coords.y + ",");
		// navigator.clipboard.writeText(coords.x + "," + coords.y + ",");
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}

	function enterArea(area) {
		console.log(area);
	}

	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
				onMouseEnter={(area) => enterArea(area)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
