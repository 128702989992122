import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
//import { Launcher } from 'react-chat-window';
import robot_img6 from 'assets/chatbot-images/robot_img6.png';
import Header from "views/Component/Header.js";
import { errorCleanUp } from "configurations/redux/authSlice.js";
import {
    encryptData
} from "configurations/configurations.js";
import "../../../assets/css/chatbot-style.css";
import './chatBot.css';

export const ChatBotRobot = ({ match }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isError = useSelector((state) => state.auth.isError);
    const userStatus = useSelector((state) => state.auth.user);
    const user = useSelector((state) => state.user);
    const bizId = useSelector((state) => state.business.bizId);
    console.log(match);

    React.useEffect(() => {
        if (isError) {
            dispatch(errorCleanUp());
        }

    }, []);

    return (
        <div>
            <Header color={"transparent"} loggedInStatus={false} />

            <section className="chatbox_outer">
                <div className="container">
                    <div className="chat_bi7">
                        <h5>AI Chatbot</h5>
                        <h2>The smarter way to hire</h2>
                        <p>Have a question about OppTy? Need help scheduling interviews? Jarvis <br />
                            is here to solve all your hiring needs.</p>
                        <div className="btn_iny7">
                            <Link to={{
                                pathname: `/chatbot-interface/${match.params.acctId}/${match.params.bizId}/${match.params.applId}`
                            }}>
                                <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    Start Chatting with Jarvis
                                </button>
                            </Link>

                        </div>
                        <div className="imgh7">
                            <img src={robot_img6} />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );

};
export default ChatBotRobot;