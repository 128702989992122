import React, { Fragment, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Header from "views/Component/Header.js";
import Footer from "views/Component/Footer.js";
import Hidden from "@material-ui/core/Hidden";
import clsx from 'clsx';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import testimonialsPageStyles from 'assets/jss/material-kit-pro-react/views/pageStyle/TestimonialsPage/testimonialsPageStyles';
import { Box, Button, makeStyles, Modal, Typography } from "@material-ui/core";
import Avatar from "@mui/material/Avatar";
import Skeleton from "@mui/material/Skeleton";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { decryptData,TimeFormat } from 'configurations/configurations.js';
import axios from "axios";
import Cookies from "js-cookie";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import dp from "assets/img/faces/marc.jpg";
import cannotMessage from "assets/img/cannot-message.png";

/*Sourabh sir start*/
import {
	updateJobPostStatus,
	getJobApplications, 
	jobSelectReset,
    getJobApplicationsWithStatus, 
} from "configurations/redux/jobManagementSlice.js";
import { getSelectJobPosts ,updateApplicantStatus} from "configurations/redux/jobManagementSlice.js";
/*Sourabh sir end*/
import Profile from "assets/img/profileImg.jpg";
import jobLoader from "assets/img/job-loder.gif";
import IconLibrary from "views/Component/IconLibrary.js";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

import {
	activateEditType,
	deactivateEditType,
} from "configurations/redux/editProfileModalSlice";
import {
	getProfiles
} from "configurations/redux/userSlice.js";


import Tab from '@mui/material/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';

import ArchivePopupModel from 'views/Page/ApplicationManagement/ArchivePopupModel'; 

// Research how to combine multiple style files in makeStyles


const ApplicationTab = (match) => {   

/*sandeep mam start*/
    const [progress, setProgress] = React.useState(10);
  
   /*  React.useEffect(() => {
      const timer = setInterval(() => {
        setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
      }, 800);
      return () => {
        clearInterval(timer);
      };
    }, []); */
  
    // return <CircularProgressWithLabel value={progress} />;
  const [open11, setOpen11] = React.useState(false);
  const handleOpen11 = () => setOpen11(true);
  const handleClose11 = () => setOpen11(false);
  const [open22, setOpen22] = React.useState(false);
  const handleOpen22 = () => setOpen22(true);
  const handleClose22 = () => setOpen22(false);
  const [open222, setOpen222] = React.useState(false);
  const handleOpen222 = () => setOpen222(true);
  const handleClose222 = () => setOpen222(false);
  const [open33, setOpen33] = React.useState(false);
  const handleOpen33 = () => setOpen33(true);
  const handleClose33 = () => setOpen33(false);
  
  /* Archive Model Pop start */
	const [archiveModelItem, setArchiveModelItem] = React.useState([]);
	const [archiveTabType, setArchiveTabType] = React.useState('application');
	const [archiveModelType, setArchiveModelType] = React.useState('');
	const [archiveModelStatus, setArchiveModelStatus] = React.useState(false);
	const [comfirmationModelStatus, setComfirmationModelStatus] = React.useState(false);
	const [jobOfferModelStatus, setJobOfferModelStatus] = React.useState(false);
	
	 const [jobViewModal, setJobViewModal] = React.useState(false);
  /* Archive Model Pop end */
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };
 const [value, setValue] = React.useState('1');

  const handleChange111 = (event, newValue) => {
    setValue(newValue);
  };

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
      borderRadius: 10,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '16.5px 14px',
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 10,
        borderColor: '#3f51b5',
      },
    },
  }));

/*sandeep mam end*/
  
    const styles = makeStyles(testimonialsPageStyles)();
    const [optionToShow, setOption] = useState('myTestimonials');
    const getVideosHandler = () => {
        console.log("I M CLICKED");
    }
    const [attachmentType, setAttachmentType] = React.useState(4);
    const userStatus = useSelector(state => state.auth.user);
    //const isMe = decryptData(match.params.acctId) === userStatus.acctId;
    const tabs = ['myTestimonials', 'pendingRequests', 'testimonialsGiven'];

    const [payemtSubscriptionErr, setPayemtSubscriptionErr] = React.useState('');
    const [videoSizeErr, setvideoSizeErr] = React.useState('');
    const bytesToMegaBytes = size => size / (1024 ** 2);
	const history = useHistory();
	const dispatch = useDispatch();
	var isKicked = Cookies.get("isKicked");

	const auth = useSelector((state) => state.auth);
	const currUser = useSelector((state) => state.auth.user);
	const isError = useSelector((state) => state.auth.isError);
	const isVerified = useSelector((state) => state.auth.isVerified);
	const isResend = useSelector((state) => state.auth.isResend);
	const buildProfileFlag = useSelector((state) => state.auth.buildProfileFlag);
	const isViewed = useSelector((state) => state.user.isViewed);
	var user = useSelector((state) => state.user);
	const isLoading = useSelector((state) => state.user.isLoading);
	const blockCount = useSelector((state) => state.user.blockCount);
	const isUpdated = useSelector((state) => state.user.isUpdated);

	const [myself, setMyself] = useState(false);
	const [emailSend, setEmailSend] = useState(false);
	const [userData, setUserData] = useState(null);
	const [connStatus, setConnStatus] = useState(null);

	const [headerTab, setHeaderTab] = useState("myActivity");
	const [isEditProfileOpen, setIsEditProfileOpen] = useState(false);
	const [editOption, setEditOption] = useState("");
	const [isCannotMessageModalOpen, setIsCannotMessageModalOpen] = useState(
		false
	);
	const [createPost, setCreatePost] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
	





	/*Sourabh sir start*/
	const [jobPostId, setJobPostId] = React.useState(153);
	//	const stateJobPostId = decryptData(match.params.jobPostId);
	const stateJobPostId = match.jobPostId;
		console.log('matchDatas'); 
		console.log(match);
		
	/* 	
	const selectedJobPost = useSelector(
		(state) => state.jobManagement.corpSelectedJobPost
	);
    const applicantList = useSelector(
		(state) => state.jobManagement.applicantList
	);
    const applications = useSelector(
		(state) => state.jobManagement.applications
	); */
    
	const bizId = useSelector((state) => state.business.bizId);
	
	//	console.log('applicationTabJobPost start');
	/* const applicationTabJobPost = useSelector(
		(state) => state.jobManagement.applications
	); */
	
	
	
	//console.log('corpSelectedJobPost');
	//console.log(applicationTabJobPost); 

	/* const IsolatedMenu = props => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	//console.log('props');
	const handleClick = (event) => {
	setAnchorEl(event.currentTarget);
	};

	//console.log(props.id.attachmentId );
	return()
	}
	*/


   const updateJobStatus = async (stateJobPostId,applicantId) => {

		console.log('------stateJobPostId---------' + stateJobPostId+'----applicantId--'+applicantId);
		/* 	const request = {
			acctId: userStatus.acctId,
			email: userStatus.email,
			accessToken: localStorage.getItem("token"),

			applicantId:applicantId,
			applId:stateJobPostId,
			applStatusId: 3

		}; */
		const request = {
		acctId: userStatus.acctId,
			email: userStatus.email,
		accessToken: localStorage.getItem("token"),
		//jobPostId: stateJobPostId,
			 
			applicantId:applicantId,
			applId:stateJobPostId,
			applStatusId: 3
			
		};

		dispatch(updateApplicantStatus(request));
		handleClose11();
		match.handleJobLoderStatusMethod(true);
		setTimeout(() => {getCandidateInfo()}, 3000);

    };
	
	
	 const getCandidateInfo = () => {
        /* const requestProfile = {
			acctId: userStatus.acctId,
			email: userStatus.email,
			accessToken: localStorage.getItem("token"),
			jobPostId: stateJobPostId,
			ipAddr: localStorage.getItem("ipAddr"),
			lastRecordSize: 0,
			requestRecordSize: 1,
		}; */
		
			const requestProfile = {
			acctId: userStatus.acctId,
			email: userStatus.email,
			accessToken: localStorage.getItem("token"),
			jobPostId: stateJobPostId,
			ipAddr: localStorage.getItem("ipAddr"),
			lastRecordSize: 0,
			requestRecordSize: 100,
		};

		dispatch(getJobApplicationsWithStatus(requestProfile));
		match.handleJobLoderStatusMethod(false);
        console.log("I am clicked Here in Rahul Code");
       // console.log(applications);
        console.log("I am clicked Here in Rahul Code");
        
    }
	
	
	 /* Archive Model Pop start */
	const handleArchiveModelPopup = (item,type,tab) => {
		console.log('handleArchiveModelPopup');
		console.log(type);
		const requestAcrchiveProfile = {
			acctId: 139, //item.acctId,
			email: 'hwanjinchoi0424@gmail.com',
			userId: 251,
			accessToken: localStorage.getItem("token"),
		};
		dispatch(getProfiles(requestAcrchiveProfile));
		handleOpen33();
		setArchiveModelItem(item);
		setArchiveModelType(type);
		setArchiveTabType(tab);
		setJobViewModal(true);
		
		setComfirmationModelStatus(true);
	}
	 /* Archive Model Pop end */
	
    /* END RAHUL CODE */

 const IsolatedMenu = props => {
	 
	
	 const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	 return (<Typography  className='applicationTopRight'>
					<Button
						id="basic-button"
						aria-controls={open ? 'basic-menu' + props.dataitem.acctId : undefined}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}
						onClick={handleClick}
					>
						<i class="fas fa-ellipsis"></i>
					</Button>
					<Menu
						elevation={0}
						id={'basic-menu' + props.dataitem.acctId}
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						MenuListProps={{
						'aria-labelledby': 'basic-button',
						}}
					>
						<MenuItem className='saveCand' onClick={handleClose}><i class="fa-regular fa-bookmark"></i> Save Candidate</MenuItem>
					</Menu>
					</Typography>)
 }


	return (
	
			
			<div  className='appTabContentInner'>
			   <Typography className='appliTitle'>Applications</Typography>
			   <Button className='inviteBtn'>Invite Candidates to Apply</Button>
			   {match.applicantListMainData && match.applicantListMainData.applications[0].candidateCount > 0 && match.applicantListMainData.applications.map((item, index) => {

					return(
			   <Typography  key={item.acctId}  className='applicationMain'>
				 <Typography className='applicationTop'>
					<span className='appSpan'></span>
					<Typography className='appCen'>
						<div className="progress">
						<div className="barOverflow">
						<div className="bar"></div>
						</div>
						<span>80</span>
						</div>
					</Typography>
					
					
					
					<IsolatedMenu dataitem={item} />
					
					
					
				 </Typography>
				 <Typography onClick={() => handleArchiveModelPopup(item,'archive','application')} className='appNamee'>{item.firstName}</Typography>
				 <p className='desc'>{item.headline}</p>
				 <span className="TimeeSpan">{TimeFormat(item.applicationDate)}</span>
				 <Typography component='div' className='dayOuter btnOuter rejectShortlist'>
					<Typography component='div' className='dayRight'>
					  <Button className='rejectBtn'>Reject</Button>
					</Typography>
					<Typography component='div' className='dayLeft'>

					

					 <Button className='applyBtn' onClick={handleOpen11}>Shortlist</Button>
						 {/* <Button className='applyBtn' onClick={() => handleArchiveModelPopup(item,'accept','application')} >Shortlist New</Button>*/}

					 <Modal
						open={open11}
						onClose={handleClose11}
						aria-labelledby="modal-modal-title"
						aria-describedby="modal-modal-description"
						>
						<Box className='customBox' sx={style}>
							<Link onClick={handleClose11} to="#" className='crossBtn'><i class="fas fa-xmark"></i></Link>
							<Typography className='folderDiv'><i class="fas fa-folder"></i></Typography>
							<Typography className='shortlistTitle'>
							 Move <b>{item.firstName}</b> to   
							 <span> Shortlist?</span>
							</Typography>
							<Typography component='div' className='dayOuter btnOuter rejectShortlist interviewBtns'>
								<Typography component='div' className='dayRight'>
								<Button onClick={handleClose11}  className='rejectBtn'>Undo</Button>
								</Typography>
								<Typography component='div' className='dayLeft'>
								<Button onClick={() => updateJobStatus(item.applId,item.acctId)} className='applyBtn'>Confirm Move</Button>
								</Typography>
							</Typography>
						</Box>
						</Modal>

					</Typography>
				 </Typography>
			   </Typography>
			   )
			   })
			   
			   }

			 
			 
			 {archiveModelType!='' ? <ArchivePopupModel
			 
				archiveModelItemData={archiveModelItem} 
				archiveTabTypeData = {archiveTabType}
				archiveModelTypeData = {archiveModelType}
				archiveModelStatusData = {archiveModelStatus}
				comfirmationModelStatusData = {comfirmationModelStatus}
				jobOfferModelStatusData = {jobOfferModelStatus}
			/> : null}
			 
			
			 
			 
			</div>

        
		
                                          
		
	);
};

export default ApplicationTab;
