import React from 'react';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import { useSelector, useDispatch } from 'react-redux';
import searchPageStyle from 'assets/jss/material-kit-pro-react/views/pageStyle/SearchPage/searchPageStyle.js';
import { Button } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { saveOption } from 'configurations/redux/genericSearchSlice.js';

import ProfileViewActivityCard from 'views/Component/ProfileView/MyActivity/ProfileViewActivityCard';
import SmallCardLoading from './SmallCardLoading.js';
import SocialFeedControl from '../LandingPageFeed/SocialFeedControl.js';
import PublicViewActivityCard from 'views/Component/ProfileView/MyActivity/PublicViewActivityCard';
import NoResultCard from './NoResultCard';
import { decryptData } from 'configurations/configurations.js';
import { getSocialPostByPostId } from 'configurations/redux/socialFeedSlice.js';
import { searchPost, searchPostByPostId } from 'configurations/redux/searchPostSlice';
import { publicMode } from 'configurations/redux/authSlice.js';

const useStyles = makeStyles(searchPageStyle);

export default function PostComponent({ match }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const searchResult = useSelector(state => state.searchPost.searchResult);
  const isLoading = useSelector(state => state.searchPost.isLoading);
  const auth = useSelector(state => state.auth);
  const userStatus = useSelector(state => state.auth.user);
  const history = useHistory();

  const PostList =
    searchResult &&
    searchResult.length > 0 &&
    searchResult.map(item => {
      return (
        <div key={item.postId}>
          <PublicViewActivityCard post={item} hideReactionBar={false} searchPost={false} isCopyPost={false} />
        </div>
      );
    });
  const loadingFields = [];
  for (let i = 0; i < 3; i++) {
    loadingFields.push(
      <div key={i}>
        <SmallCardLoading />
      </div>
    );
  }

  React.useEffect(() => {
    console.log(match);
    console.log(userStatus, auth.isAuthenticated);
    if (match.path == '/public-search-page/:type/:id') {
      dispatch(publicMode());
    } else if (match.params.type) {
      var type = match.params.type;
      var id = match.params.id;
      if (auth.isAuthenticated) history.replace(match.url.replace('public-', ''));
      if (type == 'post' && id.length > 1) {
        //get post by post id
        const requestProfile = {
          acctId: auth.isPublic ? 0 : userStatus.acctId,
          accessToken: auth.isPublic ? auth.publicToken : localStorage.getItem('token'),
          email: auth.isPublic ? auth.publicEmail : userStatus.email,
          lastRecordSize: 0,
          requestRecordSize: 1,
          postList: [],
          postId: decryptData(id),
          parentId: 0,
          requestRecordSizeComment: 3,
          commentRequestLength: 0,
          visibleIds: auth.isPublic ? '1' : '1,2',
          groupId: 0,
        };
        console.log(requestProfile);
        if (auth.isAuthenticated) dispatch(getSocialPostByPostId(requestProfile));
        else dispatch(searchPostByPostId(requestProfile));
      }
    }
  }, []);

  React.useEffect(() => {
    if (auth.isPublic && auth.publicToken) {
      if (match.params.type) {
        var type = match.params.type;
        var id = match.params.id;
        if (type == 'post' && id.length > 1) {
          //get post by post id
          const requestProfile = {
            acctId: auth.isPublic ? 0 : userStatus.acctId,
            accessToken: auth.isPublic ? auth.publicToken : localStorage.getItem('token'),
            email: auth.isPublic ? auth.publicEmail : userStatus.email,
            lastRecordSize: 0,
            requestRecordSize: 1,
            postList: [],
            postId: decryptData(id),
            parentId: 0,
            requestRecordSizeComment: 3,
            commentRequestLength: 0,
            visibleIds: auth.isPublic ? '1' : '1,2',
            groupId: 0,
          };
          console.log(requestProfile);
          if (auth.isAuthenticated) dispatch(getSocialPostByPostId(requestProfile));
          else dispatch(searchPostByPostId(requestProfile));
        }
      }
    }
    console.log('isPublic', auth.isPublic, auth.publicToken);
  }, [auth]);

  const onClosePost = () => {};

  console.log(searchResult);
  return (
    <Card className={classes.cardFrame}>
      <CardBody className={classes.cardBodyPadding}>
        <h4 className={classes.componentTitle}>Post</h4>
        {isLoading &&
          loadingFields.map(item => {
            return item;
          })}
        {!auth.isAuthenticated && auth.isPublic ? (
          <div className={classes.mainContainer}>
            {PostList}
            {!isLoading && searchResult.length === 0 && <NoResultCard showImage={false} />}
          </div>
        ) : (
          auth.isAuthenticated && (
            <div className={classes.mainContainer}>
              <SocialFeedControl
                match={null}
                hideAddon={true}
                showFeed={true}
                createPost={null}
                onClosePost={onClosePost}
                groupId={0}
                scrollableTarget={null}
                isSearch={true}
                isGroupMember={false}
                postStatus={1}
                isInGroup={false}
              />
            </div>
          )
        )}

        {!isLoading && (
          <Link
            className={classes.textDeco}
            to={auth.isPublic ? `/public-search-page-post` : `/search-page-post`}
            color="transparent"
          >
            <Button
              fullWidth
              onClick={() => {
                const response = {
                  option: auth.isPublic ? '/public-search-page-post' : '/search-page-post',
                };
                dispatch(saveOption(response));
              }}
              className={classes.buttonViewMore}
            >
              See All Post Result
            </Button>
          </Link>
        )}
      </CardBody>
    </Card>
  );
}
