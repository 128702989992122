import React from "react";
// import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { useSelector, useDispatch } from "react-redux";
import { checkToken } from "../../configurations/redux/authSlice.js";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.js";

import style from "assets/jss/material-kit-pro-react/modalStyle.js";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

export default function CustomDialogWithTimer(children) {
	const useStyles = makeStyles(style);
	const classes = useStyles();
	const { confirmDialog, setConfirmDialog } = children;
	const userStatus = useSelector((state) => state.auth.user);
	const dispatch = useDispatch();

	const [countDown, setCountDown] = React.useState(0);
	// console.log(confirmDialog.dialogDefault.countDown)
	let timerCountDown = null;
	var countDownDefault = 0;
	React.useEffect(() => {
		countDownDefault = confirmDialog.dialogDefault.countDown;
		if (countDownDefault > 0) {
			const requestInfo = {
				acctId: userStatus.acctId,
				email: userStatus.email,
				accessToken: localStorage.getItem("token"),
			};
			dispatch(checkToken(requestInfo));

			timerCountDown = setInterval(() => {
				var idleProcess = localStorage.getItem("idleProcess");
				if (idleProcess == "start") {
					confirmDialog.dialogDefault.countDown = 0;
					confirmDialog.isOpen = false;
				}
				if (confirmDialog.checkToken && countDownDefault % 10 == 0) {
					const requestInfo = {
						acctId: userStatus.acctId,
						email: userStatus.email,
						accessToken: localStorage.getItem("token"),
					};
					dispatch(checkToken(requestInfo));
				}
				setCountDown(countDownDefault);
				countDownDefault = countDownDefault - 1;
				if (countDownDefault <= 0) {
					clearInterval(timerCountDown);
				}
			}, 1000);
			return () => clearInterval(timerCountDown);
		}
	}, [confirmDialog.dialogDefault.countDown]);

	const handleExit = () => {
		console.log("close");
		setCountDown(0);
		confirmDialog.dialogDefault.countDown = 0;
	};

	return (
		<div>
			<Dialog
				classes={{
					root: classes.modalRoot,
					paper: classes.modalLoader,
				}}
				style={{ zIndex: "99999" }}
				open={confirmDialog.isOpen}
				onClose={handleExit}
				TransitionComponent={Transition}
				keepMounted
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle
					id="alert-dialog-slide-title"
					disableTypography
					className={classes.modalHeader}
				>
					<Button
						simple
						className={classes.modalCloseButton}
						key="close"
						aria-label="Close"
						onClick={() =>
							setConfirmDialog({ ...confirmDialog, isOpen: false })
						}
					>
						{" "}
						<Close className={classes.modalClose} />
					</Button>
					<h4>
						<b>{confirmDialog.dialogDefault.title}</b>
					</h4>
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						{confirmDialog.dialogDefault.description}
						<br />
						<b>{countDown > 0 ? countDown + " seconds" : "some time"}</b>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					{confirmDialog.onCancel ? (
						<Button onClick={confirmDialog.onCancel} color="opptyWrap" round>
							{confirmDialog.dialogDefault.buttonNo}
						</Button>
					) : null}

					{confirmDialog.onConfirm ? (
						<Button
							onClick={confirmDialog.onConfirm}
							color="opptyWrapBorder"
							round
						>
							{confirmDialog.dialogDefault.buttonYes}
						</Button>
					) : null}
				</DialogActions>
			</Dialog>
		</div>
	);
}
