import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
//Page Components
import Header from "views/Component/Header.js";
import Footer from "views/Component/Footer.js";

// import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";
// import loginPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/LoginPage/loginPageStyle.js";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Button from "components/CustomButtons/Button.js";
import BottomNavBar from "components/BottomNavBar/BottomNavBar.js";

import {
	getRoadMapList,
	viewRoadmap,
	resetRoadmap,
	industryViewRoadmap,
	departmentViewRoadmap,
} from "configurations/redux/roadmapSlice.js";
import ShowRoadmap from "./RoadMapComponent/ShowRoadmap.js";
// import { ConsoleView, isDesktop, isMobile } from "react-device-detect";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
// import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
// import careerRoadmapImage from "assets/img/Career_roadmap.png";
import { Hidden } from "@material-ui/core";
import { gaPageView } from "configurations/configurations";

const useStyles = makeStyles(opptyRoadMapPageStyle);

// const Transition = React.forwardRef(function Transition(props, ref) {
// 	return <Slide direction="up" ref={ref} {...props} />;
// });

function useWindowSize() {
	const [size, setSize] = React.useState([0, 0]);
	React.useLayoutEffect(() => {
		gaPageView(window.location.pathname);
		function updateSize() {
			setSize([window.innerWidth, window.innerHeight]);
		}
		window.addEventListener("resize", updateSize);
		updateSize();
		return () => window.removeEventListener("resize", updateSize);
	}, []);
	return size;
}

export default function OpptyRoadMapPage() {
	const dispatch = useDispatch();
	const classes = useStyles();

	const [width, height] = useWindowSize();

	const industryId = useSelector((state) => state.roadmap.industryKeyword);
	const departmentId = useSelector((state) => state.roadmap.departmentKeyword);
	// const industryListDefault = useSelector(
	// 	(state) => state.roadmap.industryList
	// );
	const industryList = useSelector((state) => state.roadmap.industryList);
	// React.useEffect(() => {
	// 	console.log(departmentId, departmentList);
	// }, [departmentId]);
	const [openDialog, setOpenDialog] = React.useState(false);
	const handleCloseDialog = () => {
		console.log(departmentId);
		// if (departmentId != null && departmentId != ""){
		// 	dispatch(departmentViewRoadmap(departmentId));
		// 	document.getElementById("roleId").value = departmentId - 1
		// }else{
		// 	dispatch(departmentViewRoadmap(null));
		// 	document.getElementById("roleId").value = ""
		// }
		setOpenDialog(false);
		// }
	};
	const initialList = [
		{
			roleName: "",
			roleId: 0,
		},
	];
	const [departmentList, setDepartmentList] = React.useState(initialList);
	const [industryChange, setIndustryChange] = React.useState();

	React.useEffect(() => {
		console.log(industryChange, industryList);
		if (industryChange && industryList) {
			console.log(industryChange);
			dispatch(industryViewRoadmap(null));
			dispatch(departmentViewRoadmap(null));
			setTimeout(() => {
				dispatch(industryViewRoadmap(industryChange));
			}, 100);
			if (
				industryList.find((item) => item.industryId == industryChange)
					.departmentList !== undefined
			) {
				setDepartmentList(
					industryList.find((item) => item.industryId == industryChange)
						.departmentList
				);
			} else {
				setDepartmentList(initialList);
			}
			// console.log(departmentList);
		}
	}, [industryChange]);

	React.useEffect(() => {
		dispatch(resetRoadmap());
		dispatch(getRoadMapList());
		window.dispatchEvent(new CustomEvent("navigationhandler"));
	}, []);

	const openDialogShow = () => {
		setOpenDialog(true);
	};

	React.useEffect(() => {
		if (openDialog) resizeImgMap();
	}, [openDialog]);

	const resizeImgMap = () => {
		let timer = setInterval(() => {
			var roadMap = document.getElementById("roadMap");
			var gridRoadMap = document.getElementById("gridRoadMap");
			var toolbarMap = document.getElementById("toolbarMap");
			var roadmapDialog = document.getElementById("roadmapDialog");
			if (roadMap !== null && gridRoadMap !== null && toolbarMap !== null) {
				if (roadmapDialog.clientHeight > 0 && roadMap.clientHeight > 0) {
					clearInterval(timer);
					var zoomPercent = (gridRoadMap.offsetWidth / 1920) * 100;
					roadMap.style.zoom = zoomPercent + "%";
					var paddingTop =
						(roadmapDialog.clientHeight +
							toolbarMap.clientHeight -
							roadMap.clientHeight * (zoomPercent / 100)) /
						2;
					if (paddingTop > 50) gridRoadMap.style.paddingTop = paddingTop + "px";
					else gridRoadMap.style.paddingTop = "50px";
				}
			}
		}, 100);
	};

	window.onresize = function (e) {
		pageResize();
		if (openDialog) resizeImgMap();
		// if (openDialog) resizeRoadmap();
	};

	window.addEventListener("navigationhandler", function (e) {
		if (window.location.href.includes("/oppty-road-map-page")) {
			console.log("load");
			window.history.pushState(null, document.title, window.location.href);
			window.addEventListener("popstate", function (event) {
				window.history.pushState(null, document.title, window.location.href);
			});
		}
	});

	React.useEffect(() => {
		pageResize();
	}, []);

	const pageResize = () => {
		var timer = setInterval(() => {
			var careerRoadMap = document.getElementById("careerRoadMap");
			var footer = document.getElementById("footer");
			if (careerRoadMap && footer) {
				clearInterval(timer);
				if (window.innerWidth >= 960) {
					careerRoadMap.style.minHeight = "498px";
					careerRoadMap.style.height =
						window.innerHeight - footer.clientHeight + "px";
				} else {
					careerRoadMap.style.minHeight = "1px";
					careerRoadMap.style.height = "auto";
				}
			} else {
				setTimeout(() => {
					clearInterval(timer);
				}, 3000);
			}
		}, 50);
	};
	return (
		<>
			<div className={classes.body}>
				<div
					id="careerRoadMap"
					className={classes.pageHeader}
					style={{
						backgroundSize: "cover",
						backgroundPosition: "top center",
					}}
				>
					<div className={classes.container} id="roadmapMainPage">
						<GridContainer
							justifyContent="center"
							alignContent="center"
							alignItems="center"
						>
							<GridItem xs={12} sm={12}>
								<Hidden mdUp>
									<div className={classes.infoArea}>
										<h2 className={classes.infoTitle}>
											Explore Your Career Possibilities
										</h2>
										<h5 className={classes.infoDescription}>
											Are you interested in finding out more about how your
											career journey and progression would look like? Our career
											roadmap is here to help you out by providing you with more
											information and details about each role across various
											different industies.
										</h5>
										{/* <img
										src={careerRoadmapImage}
										className={classes.infoImage}
										id="roadmapPhoto"
									/> */}
									</div>
								</Hidden>
							</GridItem>
							<GridItem xs={12} sm={12} md={6} className={classes.roadmapGrid}>
								<Card className={classes.roadmapCard}>
									<CardBody>
										<GridContainer
											justifyContent="center"
											alignContent="center"
											alignItems="center"
										>
											<GridItem xs={12} sm={12} md={12}>
												<GridItem>
													<div>
														<h4 className={classes.title}>
															Search for your career roadmap
														</h4>
													</div>
												</GridItem>
												{/* <h2 className={classes.textCenter + " " + classes.title}>
										Search for your Career Roadmap
									</h2> */}
												<Formik
													initialValues={{
														industryKeyword: industryId,
														departmentKeyword: departmentId,
													}}
													onSubmit={(data) => {
														const response = {
															industryKeyword: data.industryKeyword,
															departmentKeyword: data.departmentKeyword,
														};
														console.log(response);
														dispatch(viewRoadmap(response));
													}}
												>
													{({ setFieldValue, values, handleSubmit }) => (
														<form onSubmit={handleSubmit}>
															<GridContainer>
																<GridItem
																	xs={12}
																	sm={12}
																	md={12}
																	className={classes.autoCompleteSelect}
																>
																	<Autocomplete
																		options={
																			industryList && industryList.length > 0
																				? industryList
																				: initialList
																		}
																		// freeSolo
																		getOptionLabel={(option) =>
																			option.industryName
																				? option.industryName
																				: ""
																		}
																		value={
																			industryId === values.industryKeyword
																				? industryList &&
																				  industryList.find(
																						(item) =>
																							item.industryId ==
																							values.industryKeyword
																				  )
																				: (industryList &&
																						industryList.find(
																							(item) =>
																								item.industryId == industryId
																						)) ||
																				  ""
																		}
																		onChange={(event, value) => {
																			console.log(value);
																			if (value) {
																				setFieldValue(
																					"industryKeyword",
																					value.industryId
																				);
																				setIndustryChange(value.industryId);
																			} else {
																				setFieldValue("industryKeyword", "");
																				setIndustryChange(null);
																			}
																		}}
																		id="industryId"
																		name="industryId"
																		renderInput={(params) => (
																			<TextField
																				{...params}
																				label={"Industry"}
																				variant="outlined"
																			/>
																		)}
																	/>
																</GridItem>
																<GridItem
																	xs={12}
																	sm={12}
																	md={12}
																	className={classes.autoCompleteSelect}
																>
																	<Autocomplete
																		options={departmentList}
																		freeSolo
																		getOptionLabel={(option) =>
																			option.roleName ? option.roleName : ""
																		}
																		value={
																			!industryId
																				? null
																				: (departmentId ===
																				  values.departmentKeyword
																						? departmentList &&
																						  departmentList.find(
																								(item) =>
																									item.roleId ==
																									values.departmentKeyword
																						  )
																						: departmentList &&
																						  departmentList.find(
																								(item) =>
																									item.roleId == departmentId
																						  )) || ""
																		}
																		onChange={(event, value) => {
																			console.log(value);
																			if (value) {
																				setFieldValue(
																					"departmentKeyword",
																					value.roleId
																				);
																			} else {
																				setFieldValue("departmentKeyword", "");
																			}
																		}}
																		id="roleId"
																		name="roleId"
																		renderInput={(params) => (
																			<TextField
																				{...params}
																				label={"Department"}
																				variant="outlined"
																			/>
																		)}
																	/>
																</GridItem>
															</GridContainer>
															<GridContainer justifyContent="center">
																<GridItem
																	xs={12}
																	sm={12}
																	md={12}
																	className={classes.searchBarButton}
																>
																	<Button
																		onClick={() => {
																			handleSubmit();
																			openDialogShow();
																		}}
																		fullWidth
																		round
																		disabled={!industryId}
																		color="oppty"
																	>
																		<b>Search</b>
																	</Button>
																</GridItem>
																{/* <GridItem xs={12} sm={12} md={4}>
														<h6 className={classes.description}>
															Select Industry{" "}
														</h6>
														<Autocomplete
															options={industryList}
															freeSolo
															getOptionLabel={(option) =>
																option.industryName ? option.industryName : ""
															}
															value={
																(industryId === values.industryKeyword
																	? industryList[values.industryKeyword - 1]
																	: industryList[industryId - 1]) || ""
															}
															onChange={(event, value) => {
																if (value) {
																	setFieldValue(
																		"industryKeyword",
																		value.industryId
																	);
																	setIndustryChange(value.industryId);
																} else {
																	setFieldValue("industryKeyword", "");
																	setIndustryChange(null);
																}
															}}
															id="industryId"
															name="industryId"
															renderInput={(params) => (
																<TextField {...params} label={"Industry"} />
															)}
														/>
													</GridItem>
													<GridItem xs={12} sm={12} md={4}>
														<h6 className={classes.description}>
															Select Department
														</h6>

														<Autocomplete
															options={departmentList}
															freeSolo
															getOptionLabel={(option) =>
																option.roleName ? option.roleName : ""
															}
															value={
																!industryId
																	? null
																	: (departmentId === values.departmentKeyword
																			? departmentList[
																					values.departmentKeyword - 1
																			  ]
																			: departmentList[departmentId - 1]) || ""
															}
															onChange={(event, value) => {
																console.log(value);
																if (value) {
																	setFieldValue(
																		"departmentKeyword",
																		value.roleId
																	);
																} else {
																	setFieldValue("departmentKeyword", "");
																}
															}}
															// onInputChange={(event, value)=>{
															// 	if (value) {
															// 		var value = departmentList.filter(
															// 			(item) => item.roleName == value
															// 		);
															// 		console.log(value)
															// 		setFieldValue(
															// 			"departmentKeyword",
															// 			value[0].roleId
															// 		);
															// 	} else {
															// 		setFieldValue("departmentKeyword", "");
															// 	}
															// }}
															id="roleId"
															disabled={!industryId}
															name="roleId"
															renderInput={(params) => (
																<TextField {...params} label={"Department"} />
															)}
														/>
													</GridItem>

													<GridItem xs={12} sm={12} md={4}>
														<div className={classes.selectPaddingDiv}>
															<Button
																onClick={() => {
																	handleSubmit();
																	openDialogShow();
																}}
																fullWidth
																round
																disabled={!industryId}
																color="oppty"
															>
																View
															</Button>
														</div>
													</GridItem> */}
															</GridContainer>
														</form>
													)}
												</Formik>
											</GridItem>
										</GridContainer>
									</CardBody>
								</Card>
							</GridItem>

							<GridItem xs={false} sm={false} md={6} id="roadmapRightSide">
								<Hidden smDown>
									<div className={classes.infoArea}>
										<h2 className={classes.infoTitle}>
											Explore Your Career Possibilities
										</h2>
										<h5 className={classes.infoDescription}>
											Are you interested in finding out more about how your
											career journey and progression would look like? Our career
											roadmap is here to help you out by providing you with more
											information and details about each role across various
											different industies.
										</h5>
										{/* <img
										src={careerRoadmapImage}
										className={classes.infoImage}
										id="roadmapPhoto"
									/> */}
									</div>
								</Hidden>
							</GridItem>
						</GridContainer>
					</div>
					<div className={classes.roadmapContainer}>
						{/* {!isMobile ? ( */}
						<Dialog
							fullScreen
							open={openDialog}
							onClose={handleCloseDialog}
							// TransitionComponent={Transition}
							id="roadmapDialog"
						>
							<AppBar className={classes.appbar}>
								<Toolbar id="toolbarMap">
									<IconButton
										edge="start"
										color="inherit"
										onClick={handleCloseDialog}
										aria-label="close"
									>
										<ArrowBackIosIcon />
									</IconButton>
									<h4 className={classNames(classes.title, classes.textCenter)}>
										Career Roadmap
									</h4>
									{/* <TextField id="coordinateGet" label="Standard" multiline /> */}
								</Toolbar>
							</AppBar>
							{/* <GridContainer> */}
							<div>
								<div id="gridRoadMap" style={{ overflow: "hidden" }}>
									{/* <div id="gridRoadMap"> */}
									{/* <TransformWrapper>
										<TransformComponent> */}
									<div
										id="roadMap"
										style={
											{
												// width: "1920px",
												// visibility: "hidden",
												// transition: "visibility 0s, opacity 0.5s linear",
											}
										}
									>
										<ShowRoadmap />
									</div>
									{/* </TransformComponent>
									</TransformWrapper> */}
								</div>
							</div>
							{/* </GridContainer> */}
						</Dialog>
						{/* ) : (
						<GridContainer>
							<GridItem>
								<Card>
									<CardBody>
										<ShowRoadmap />
									</CardBody>
								</Card>
							</GridItem>
						</GridContainer>
					)} */}
					</div>
				</div>
				{/* <div id="footer">
					{width > 1200 ? (
						<Footer />
					) : (
						<div className={classes.footerNav}>
							<BottomNavBar />
						</div>
					)}
				</div> */}
				<Footer />
			</div>
		</>
	);
}
