import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Header from "../../Component/Header";
import LeftSidebar from "../../Component/LeftSidebar.js";

import {
	makeStyles,
	Hidden,
	Box,
	CircularProgress,
	IconButton,
	Typography,
} from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { useModal } from "hooks";
import groupPageStyles from "assets/jss/material-kit-pro-react/views/pageStyle/GroupsPage";
import { suggestedGroupData, browseByIndustryData } from "./mocks";
import CreateGroupModal from "./CreateGroupModal";
import {
	getAllIndustry,
	getAllCountries,
} from "configurations/redux/userSlice";
import { getGroupsByAcctId, getGroups } from "configurations/redux/groupsSlice";
import GroupContainer from "./GroupContainer";
import GroupCard from "./GroupCard";
import GroupsPageHeader from "./GroupsPageHeader";
import GroupByIndustryCard from "./GroupByIndustryCard";
import {
	userGroupRoles,
	userGroupStatus,
	groupPermissions,
} from "configurations/configurations";
import { joinUserGroup } from "configurations/redux/groupsSlice";
import Pagination from "views/Component/PaginationWithDisable.js";
import GroupLoader from "./GroupLoader.js";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import CustomButton from "components/CustomButtons/Button";
import NoResultCard from "./NoResultCard";

const useStyles = makeStyles(groupPageStyles);

export default function MyGroupComponent({ onSeeAllClick }) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const createGroupModalProps = useModal();

	const {
		groups,
		isGroupsLoading,
		groupsByAccount,
		isGroupsByAccountLoading,
		isStatusUpdated,
	} = useSelector((state) => state.groups);
	const user = useSelector((state) => state.auth.user);
	const [currentPage, setCurrentPage] = React.useState(1);
	const [profilePerPage, setProfilePerPage] = React.useState(8);
	const indexOfLast = currentPage * profilePerPage;
	const indexOfFirst = indexOfLast - profilePerPage;
	const [searchText, setSearchText] = React.useState("");
	const getMyGroups = () => {
		const body = {
			accessToken: localStorage.getItem("token"),
			statusId: 1,
			acctId: user.acctId,
			email: user.email,
			lastRecordSize: 0,
			requestRecordSize: 8,
			keyword: "",
		};
		dispatch(getGroupsByAcctId(body));
	};
	const paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
		var indexOfFirst = pageNumber * profilePerPage - profilePerPage;

		const body = {
			accessToken: localStorage.getItem("token"),
			statusId: 1,
			acctId: user.acctId,
			email: user.email,
			lastRecordSize: indexOfFirst,
			requestRecordSize: profilePerPage,
			keyword: "",
		};
		dispatch(getGroupsByAcctId(body));
	};

	const Loader = () => (
		<Box display="flex" justifyContent="center" alignItems="center">
			<CircularProgress />
		</Box>
	);
	useEffect(() => {
		getMyGroups();
	}, []);
	React.useEffect(() => {
		if (isStatusUpdated) {
			var newPage = 0;
			if (groupsByAccount.length > 0) newPage = currentPage;
			else newPage = currentPage - 1;
			var indexOfFirst = newPage * profilePerPage - profilePerPage;
			setCurrentPage(newPage);

			const body = {
				accessToken: localStorage.getItem("token"),
				statusId: 1,
				acctId: user.acctId,
				email: user.email,
				lastRecordSize: indexOfFirst,
				requestRecordSize: profilePerPage,
				keyword: "",
			};
			dispatch(getGroupsByAcctId(body));
		}
	}, [isStatusUpdated]);
	const loadingFields = [];
	for (let i = 0; i < profilePerPage - groupsByAccount.length; i++) {
		loadingFields.push(
			<GridItem xs={12} sm={6} md={6} lg={3} key={i}>
				<GroupLoader />
			</GridItem>
		);
	}
	const handleKeyPress = (event) => {
		if (event.key === "Enter") {
			console.log(event.target.value);
			const body = {
				accessToken: localStorage.getItem("token"),
				statusId: 1,
				acctId: user.acctId,
				email: user.email,
				lastRecordSize: 0,
				requestRecordSize: 8,
				keyword: event.target.value,
			};
			dispatch(getGroupsByAcctId(body));
		}
	};
	const handleSubmit = () => {
		const body = {
			accessToken: localStorage.getItem("token"),
			statusId: 1,
			acctId: user.acctId,
			email: user.email,
			lastRecordSize: 0,
			requestRecordSize: 8,
			keyword: searchText,
		};
		dispatch(getGroupsByAcctId(body));
	};
	return (
		<div>
			<Header />
			<div className={classes.body}>
				<div
					id="pageHeader"
					className={classes.pageHeader}
					style={{
						backgroundSize: "cover",
						backgroundPosition: "top center",
					}}
				>
					<div
						className={classes.container}
						style={{ background: "#f2f2f2" }}
						id="socialFeedPage"
					>
						<GridContainer justifyContent="center" spacing={2}>
							<Hidden mdDown>
								<GridItem md={2}>
									<LeftSidebar />
								</GridItem>
							</Hidden>
							<GridItem sm={12} md={10} lg={10}>
								<GridContainer style={{ marginTop: "10px" }}>
									<GridItem
										sm={5}
										style={{ display: "flex", alignItems: "center" }}
									>
										<Typography className={classes.headerText} variant="h5">
											Search My Groups
										</Typography>
									</GridItem>
									<GridItem sm={12} md={7} lg={7}>
										<div className={classes.headerActions}>
											<div className={classes.searchGroupsInPage}>
												<input
													placeholder="Search Groups"
													onKeyDown={handleKeyPress}
													className={classes.input}
													onChange={(event) => {
														setSearchText(event.target.value);
													}}
													value={searchText}
												/>
												<IconButton onClick={handleSubmit} sx={{ padding: 0 }}>
													<SearchIcon />
												</IconButton>
											</div>
										</div>
									</GridItem>
								</GridContainer>

								<GroupContainer
									title="My groups"
									subTitle="Groups which you created or joined."
									onSeeAllClick={onSeeAllClick}
									expanded={true}
								>
									<div>
										<GridContainer spacing={1}>
											{isGroupsByAccountLoading &&
												loadingFields.map((item) => {
													return item;
												})}
											{groupsByAccount.map((data, index) => (
												<GridItem
													xs={12}
													sm={6}
													md={6}
													lg={3}
													key={data.groupId}
												>
													<GroupCard group={data} />
												</GridItem>
											))}
										</GridContainer>

										{groupsByAccount && groupsByAccount.length > 0 && (
											<Pagination
												totalPosts={groupsByAccount[0].totalCount}
												postsPerPage={profilePerPage}
												paginate={paginate}
												page={currentPage}
												disabledStatus={isGroupsByAccountLoading ? true : false}
											/>
										)}
										{!isGroupsByAccountLoading &&
											groupsByAccount.length === 0 && (
												<NoResultCard
													description={
														"No available group. Please use different keyword or join to other groups"
													}
												/>
											)}
									</div>
								</GroupContainer>
							</GridItem>
						</GridContainer>
					</div>
				</div>
			</div>
			<CreateGroupModal {...{ ...createGroupModalProps }} />
		</div>
	);
}
