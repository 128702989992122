import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Typography, Button, makeStyles } from "@material-ui/core";

import { decryptData } from "configurations/configurations";

import educationStyles from "assets/jss/material-kit-pro-react/views/pageStyle/ProfileViewPage/educationStyles";
import EducationCardSection from "./EducationCardSection.js";
import { activateEditType } from "configurations/redux/editProfileModalSlice";

const Education = (props) => {
	const styles = makeStyles(educationStyles)();
	const dispatch = useDispatch();
	const user = useSelector((state) => state.auth.user);

	const [myself, setMyself] = useState(false);
	const isViewed = useSelector((state) => state.user.isViewed);

	return (
		<Typography component="div" className={styles.main}>
			<Typography component="div" className={styles.educationCard}>
				<Typography component="div" className={styles.educationCardHeader}>
					<Typography component="h2">Education</Typography>

					{!isViewed ? (
						<Button
							onClick={() => {
								const response = {
									type: "EDIT_PROFILE",
									tabType: "EDUCATION",
									isTabShow: true,
									extension: {
										recordId: -1,
									},
								};

								dispatch(activateEditType(response));
							}}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="20"
								viewBox="0 0 20 20"
							>
								<path
									id="icon-2"
									d="M4.709,20H1.176A1.178,1.178,0,0,1,0,18.824v-3.53a1.167,1.167,0,0,1,.345-.831L14.462.345a1.175,1.175,0,0,1,1.663,0l3.53,3.53a1.177,1.177,0,0,1,0,1.664L5.538,19.658A1.169,1.169,0,0,1,4.709,20Zm6.97-13.571h0L2.143,15.965v1.892H4.035L13.571,8.32,11.679,6.429Zm3.677-3.571h0L13.571,4.643l1.786,1.786,1.786-1.786L15.357,2.858Z"
									transform="translate(0 0)"
									fill="#6f52ed"
								/>
							</svg>
							EDIT
						</Button>
					) : (
						""
					)}
				</Typography>

				<EducationCardSection />
			</Typography>
		</Typography>
	);
};
export default Education;
