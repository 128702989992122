const postImageDialogStyles = () => ({
	modalRoot: {},

	modalPaper: {
		width: "500px",
		maxHeight: "600px",
		height: "100%",
	},

	modalHeader: {
		padding: "10px 20px", //"5px 0 0",
	},

	modalTitle: {
		textAlign: "center",
	},

	modalCloseButton: {
		position: "absolute",
		top: "16px",
		right: "16px",
		backgroundColor: "transparent",
		border: "none",
		color: "black",
		cursor: "pointer",
	},

	modalContentRoot: {
		position: "relative",
		overflow: "hidden",
		borderTop: "solid 1px #d9d9d9",
		backgroundColor: "#151515",
		padding: 0,

		"&::-webkit-scrollbar": {
			width: "0",
		},

		"& > img": {
			width: "100%",
			height: "100%",
			objectFit: "contain",
			objectPosition: "center",
		},
	},

	saveButton: {
		textAlign: "center",
		margin: "20px",

		"& > button": {
			border: "none",
			borderRadius: "7px",
			backgroundColor: "#6F52ED",
			padding: "10px 30px",
			color: "white",
			fontSize: "12px",
			fontWeight: "600",
			cursor: "pointer",
		},
	},
});

export default postImageDialogStyles;
