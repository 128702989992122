import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "views/Component/Header.js";
import FilterComponent from "views/Page/SearchPage/FilterComponent.js";
import { useSelector, useDispatch } from "react-redux";
import { searchJobInfo } from "configurations/redux/searchJobSlice";
import {
	getArticleCategory,
	getArticleList,
	getArticleListByKeyword,
	removeAllTag,
	addAllTag,
	searchTagAdd,
	searchTagRemove,
	// setKeyword,
	getCardAds,
	getBannerAds,
	// getCardAdsCount,
} from "configurations/redux/communitySlice.js";
import LeftSidebar from "views/Component/LeftSidebar.js";
import Hidden from "@material-ui/core/Hidden";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import ArticleComponentInPage from "./ArticleComponentInPage.js";
import SearchResultSection from "views/Page/CommunityPage/SearchResultSection.js";
import SearchResultCard from "views/Page/CommunityPage/SearchResultCard.js";

import searchProfilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/SearchPage/searchProfilePageStyle.js";

const useStyles = makeStyles(searchProfilePageStyle);

export default function SearchArticlePage({ match }) {
	const classes = useStyles();
	const searchParams = useSelector((state) => state.searchJob.searchParams);
	const dispatch = useDispatch();
	const userStatus = useSelector((state) => state.auth.user);
	const auth = useSelector((state) => state.auth);
	const searchTag = useSelector((state) => state.community.searchTag);
	const keyword = useSelector((state) => state.genericSearch.keyword);
	const bizId = useSelector((state) => state.business.bizId);

	React.useEffect(() => {
		if (searchTag.length > 0) {
			var mainCat = "0,";

			searchTag.forEach((tag) => (mainCat = mainCat + tag.mainCatId + ","));
			mainCat = mainCat.substring(0, mainCat.length - 1);
			var subCat = "0,";
			searchTag.forEach((tag) =>
				tag.subCat.forEach((ele) => (subCat = subCat + ele.subCatId + ","))
			);
			subCat = subCat.substring(0, subCat.length - 1);
			const requestProfile = {
				acctId: userStatus.acctId,
				acctTypeId: bizId > 0 ? 2 : userStatus.acctTypeId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				categoryIds: mainCat,
				subCategoryIds: subCat,
				lastRecordSize: 0,
				requestRecordSize: 12,
				paginationClick: false,
				defaultLoad: false,
				keyword: keyword,
			};
			dispatch(getArticleList(requestProfile));
		} else {
			const requestProfile = {
				acctId: userStatus.acctId,
				acctTypeId: bizId > 0 ? 2 : userStatus.acctTypeId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				categoryIds: "",
				subCategoryIds: "",
				lastRecordSize: 0,
				requestRecordSize: 12,
				paginationClick: false,
				defaultLoad: true,
				keyword: "",
			};
			dispatch(getArticleList(requestProfile));
		}

		dispatch(getArticleCategory(bizId > 0 ? 2 : userStatus.acctTypeId));
	}, []);
	return (
		<div>
			<Header />
			<FilterComponent match={match} />
			<div id="SearchSocialFeed" className={classes.filterBody}>
				<div className={classes.pageHeader} id="searchResultArticle">
					<div className={classes.container}>
						<GridContainer spacing={2}>
							<Hidden smDown>
								<GridItem md={3}>
									{auth.isAuthenticated && <LeftSidebar />}
								</GridItem>
							</Hidden>
							<GridItem xs={12} sm={12} md={9}>
								<ArticleComponentInPage />
							</GridItem>
						</GridContainer>
					</div>
				</div>
			</div>
		</div>
	);
}
