import React from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { Formik } from "formik";

import { makeStyles } from "@material-ui/core/styles";

import Parallax from "components/Parallax/Parallax.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

//Page Components
import Header from "views/Component/Header.js";
import Footer from "views/Component/Footer.js";

import notificationPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/NotificationPage/notificationPageStyle.js";
import searchProfilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/SearchProfilePage/searchProfilePageStyle.js";
import messageDashboardStyle from "assets/jss/material-kit-pro-react/views/pageStyle/MessagePage/messagePageStyle.js";
import SearchResultSection from "./SearchResultSection";
import { eventNotificationSearch } from "configurations/redux/eventNotificationSlice.js";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import TextField from "@material-ui/core/TextField";
import CustomSearchInput from "components/CustomInput/CustomSearchInput";

var useStyles = makeStyles(notificationPageStyle);
// if (isMobile) useStyles = makeStyles(messageDashboardStyle);
// else useStyles = makeStyles(favProfilePageStyle);

const NotificationPage = ({ match }) => {
	const userStatus = useSelector((state) => state.auth.user);
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();

	const [alertDialog, setAlertDialog] = React.useState();
	const bizId = useSelector((state) => state.business.bizId);

	React.useEffect(() => {
		history.replace(match.url.replace(match.params.recId, 0));
		if (match.params.recId > 0) {
			const requestProfile = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				lastRecordSize: 0,
				requestRecordSize: 10,
				keyword: match.params.recId,
				bizId: bizId,
			};
			dispatch(eventNotificationSearch(requestProfile));
			setAlertDialog(true);
		} else {
			const requestProfile = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				lastRecordSize: 0,
				requestRecordSize: 10,
				keyword: "",
				bizId: bizId,
			};
			dispatch(eventNotificationSearch(requestProfile));
		}
		window.scrollTo(0, 0);
	}, []);

	return (
		<div>
			<Header />
			<div className={classes.main + " " + classes.mainRaised}>
				<div className={classes.container}>
					{/* <GridContainer
						justifyContent="space-between"
						alignContent="center"
						alignItems="center"
					>
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<h4 className={classes.title}>Search For Your Notification</h4>
						</GridItem>
					</GridContainer> */}
					<GridContainer justifyContent="center">
						<GridItem xs={12} sm={12} md={12}>
							{alertDialog ? (
								<div>
									<GridContainer justifyContent="center">
										<GridItem xs={12} sm={8} md={6}>
											<SnackbarContent
												message={
													<b>
														Kindly click on search button in order to view more
														result
													</b>
												}
												close
												color="opptyInfo"
												icon="info_outline"
											/>
										</GridItem>
									</GridContainer>
								</div>
							) : null}
							<Formik
								initialValues={{
									keyword: "",
								}}
								onSubmit={(data) => {
									const requestProfile = {
										acctId: userStatus.acctId,
										accessToken: localStorage.getItem("token"),
										email: userStatus.email,
										keyword: data.keyword,
										lastRecordSize: 0,
										requestRecordSize: 10,
										bizId: bizId,
									};
									dispatch(eventNotificationSearch(requestProfile));
									setAlertDialog(false);
								}}
							>
								{({ values, handleChange, handleSubmit, setFieldValue }) => (
									<form onSubmit={handleSubmit}>
										<GridContainer
											justifyContent="center"
											alignContent="center"
											alignItems="center"
										>
											<GridItem
												xs={8}
												sm={9}
												md={10}
												className={classes.gridHeight}
											>
												{/* <TextField
																label="Keyword"
																variant="outlined"
																fullWidth
																onChange={handleChange}
																placeholder="Keyword"
																id="keyword"
																name="keyword"
															/> */}
												<CustomSearchInput
													labelText="Search Notification"
													id="keyword"
													name="keyword"
													values={values.keyword}
													onChange={handleChange}
													onClick={handleSubmit}
													fullWidth
													inputProps={{
														autoComplete: "off",
													}}
													close={false}
													className={classes.borderRadius}
												/>
												{/* <CustomInput
															labelText="Keyword"
															variant="outlined"
															id="keyword"
															name="keyword"
															values={values.keyword}
															onChange={handleChange}
															formControlProps={{ fullWidth: true }}
															inputProps={{
																autoComplete: "off",
															}}
														/> */}
											</GridItem>
											<GridItem
												xs={4}
												sm={3}
												md={2}
												className={classes.gridHeight}
											>
												<Button
													onClick={handleSubmit}
													color="oppty"
													round
													fullWidth
												>
													Search
												</Button>
											</GridItem>
										</GridContainer>
									</form>
								)}
							</Formik>
						</GridItem>
					</GridContainer>
					<GridContainer>
						<GridItem className={classes.gridCard}>
							<SearchResultSection />
						</GridItem>
					</GridContainer>
				</div>
			</div>
			<Footer />
		</div>
	);
};
export default NotificationPage;
