import {
	container,
	title,
	whiteColor,
	mainRaised,
	grayColor,
	blackColor,
	cardTitle,
	main,
	hexToRgb,
	coloredShadow,
	roseColor,
	sectionDark,
	mrAuto,
	mlAuto,
} from "assets/jss/material-kit-pro-react.js";

const termsConditionsPageStyle = {
	parallax: {
		height: "600px",
	},
	main: {
		...main,
	},
	mrAuto: {
		...mrAuto,
	},
	mlAuto: {
		...mlAuto,
	},
	mainRaised: {
		...mainRaised,
	},
	container: {
		...container,
		zIndex: "2",
		color: whiteColor,
	},
	blackTitle: {
		...title,
		display: "inline-block",
		position: "relative",
		marginTop: "30px",
		minHeight: "32px",
		color: blackColor,
		textDecoration: "none",
	},
	textCenter: {
		textAlign: "center",
	},
	whiteTitle: {
		...title,
		display: "inline-block",
		position: "relative",
		marginTop: "30px",
		minHeight: "32px",
		color: whiteColor,
		textDecoration: "none",
	},
	inheritTitle: {
		...title,
		color: "inherit",
	},
	description: {
		color: blackColor,
		fontSize: "19px",
	},
	accordionStyle: {
		"& title": {
			color: whiteColor,
		},
	},
};
export default termsConditionsPageStyle;
