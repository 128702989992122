/* eslint-disable react/prop-types */
import {
	Box,
	makeStyles,
	Paper,
	Tab,
	Tabs,
	Typography,
} from "@material-ui/core";
import OpptyRoadMapPage from "./OpptyRoadMapPage.js";
import React from "react";
import Header from "views/Component/Header.js";
import SearchCommunityPage from "views/Page/CommunityPage/SearchCommunityPage.js";
import resourcesStyle from "../../../assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/resourcesStyle";
import Footer from "views/Component/Footer.js";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

const useStyles = makeStyles(resourcesStyle);

const Resources = (props) => {
	const classes = useStyles();
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<div>
			<Header color={"transparent"} loggedInStatus={false} />
			<div className={classes.mainContainer}>
				<div className={classes.subHeader} id="resourcesPage">
					<p className={classes.textHeader}>Resources</p>
					<div className={classes.tabsContainer}>
						<Paper square elevation={0}>
							<Tabs
								value={value}
								onChange={handleChange}
								TabIndicatorProps={{
									style: { background: "#6F52ED" },
								}}
								aria-label="tabs"
							>
								<Tab
									label="Career Roadmap"
									className={value === 0 ? classes.active : classes.inactive}
								/>
								<Tab
									label="Articles"
									className={value === 1 ? classes.active : classes.inactive}
								/>
							</Tabs>
						</Paper>
					</div>
				</div>
				{value === 0 && <OpptyRoadMapPage />}
				{value === 1 && <SearchCommunityPage />}

				{/* <TabPanel value={value} index={0}>
					<SearchCommunityPage />
				</TabPanel>
				<TabPanel value={value} index={1}>
					<OpptyRoadMapPage />
				</TabPanel> */}
			</div>
			{/* 			<Footer /> */}
		</div>
	);
};

export default Resources;
