import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import history from "../../history.js";
import axios from "axios";
import { updateProfilePicForAuth, buildProfile } from "./authSlice.js";

import {
	instanceUrl,
	header,
	emptyCache,
	TimeFormatFirstDateOfMonth,
} from "configurations/configurations.js";

const userSlice = createSlice({
	name: "user",
	initialState: {
		acctId: null,
		acctStatusId: null,
		acctTypeId: null,
		blockCount: 0,
		cancelCount: 0,
		backgroundTemplateFlag: null,
		backgroundPic: null,
		backgroundPicFileName: null,
		caName: "",
		caId: null,
		connStatusId: null,
		connId: null,
		connReceiverAcctId: null,
		contactList: [],
		contactAcctList: [],
		email: null,
		errorMessage: null,
		educationRecords: [],
		experienceRecords: [],
		resume: [],
		video: [],
		psychometricResult: [],
		followCount: 0,
		followId: null,
		favouriteProfile: false,
		favId: null,
		googleAcctStatus: "",
		googleAcctToken: false,
		googleLastSyncDate: null,
		hashtags: [],
		isUpdated: true,
		isLoading: true,
		isViewed: false,
		isPrivacyUpdated: false,
		isPasswordUpdated: false,
		isCurrentPassword: true,
		isUploaded: false,
		loadingContactList: false,
		msAcctStatus: "",
		msAcctToken: false,
		msLastSyncDate: null,
		profileRecord: [],
		privacySetting: [],
		profilePic: null,
		profileStrength: [],
		educationLevels: [],
		interestedIndustry: [],
		schools: [],
		studyField: [],
		allIndustry: [],
		allCountries: [],
		allSetting: [],
		blockId: null,
		blockAcctId: null,
		isDeletedBlock: false,
		connectionList: null,
		isSendAllSuccess: false,
	},

	reducers: {
		CR_INT_INDUSTRY_SUCCESS: (state, action) => {
			state.interestedIndustry = action.payload.interestIndustry;
			localStorage.setItem("token", action.payload.token);
		},
		CR_INT_INDUSTRY_FAIL: (state, action) => {
			console.log(action.payload);
		},
		INT_INDUSTRY_SUCCESS: (state, action) => {
			console.log(action.payload);
			state.interestedIndustry = action.payload.interestedIndustry;
			localStorage.setItem("token", action.payload.token);
		},
		/* INTEREST_INDUSTRY_GET_SUCCESS:(state,action) => {
			state.interestedIndustry = action.payload.interestedIndustry
		} */
		INT_INDUSTRY_FAIL: (state, action) => {
			state.interestedIndustry = [];
		},

		ALL_INDUSTRY_SUCCESS: (state, action) => {
			state.allIndustry = action.payload;
		},
		ALL_COUNTRIES_SUCCESS: (state, action) => {
			state.allCountries = action.payload;
		},
		ALL_INDUSTRY_FAIL: (state, action) => {
			state.allIndustry = [];
		},
		ALL_COUNTRIES_FAIL: (state, action) => {
			state.allCountries = [];
		},
		STUDY_FIELD_SUCCESS: (state, action) => {
			state.studyField = action.payload;
		},
		STUDY_FIELD_FAIL: (state, action) => {
			state.studyField = [];
		},
		SCHOOLS_SUCCESS: (state, action) => {
			state.schools = action.payload;
		},
		SCHOOLS_FAIL: (state, action) => {
			state.schools = [];
		},
		ED_LEVEL_SUCCESS: (state, action) => {
			state.educationLevels = action.payload;
		},
		ED_LEVEL_FAIL: (state, action) => {
			state.educationLevels = [];
		},
		INT_INDUSTRY_DELETE_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.interestedIndustry = state.interestedIndustry.filter((item) => {
				return item.intsId !== action.payload.intsId;
			});
		},
		PROFILE_LOADING: (state) => {
			state.isLoading = true;
		},
		PROFILE_LOAD_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.profileRecord = action.payload.profileRecord;
			state.educationRecords = action.payload.educationRecords;
			state.educationRecords = educationSort(state.educationRecords);
			state.experienceRecords = action.payload.experienceRecords;
			state.experienceRecords = experienceSort(state.experienceRecords);
			state.resume = action.payload.resume;
			state.video = action.payload.video;
			state.psychometricResult = action.payload.psychometricResult;
			state.profileStrength = action.payload.profileStrength;
			state.hashtags = action.payload.hashtags;
			state.email = action.payload.email;
			state.connStatusId = action.payload.connStatusId;
			state.connId = action.payload.connId;
			state.acctId = action.payload.acctId;
			state.profilePic = action.payload.profilePic;
			state.backgroundPic = action.payload.backgroundPic;
			state.backgroundPicFileName = action.payload.backgroundPicFileName;
			state.backgroundTemplateFlag = action.payload.backgroundTemplateFlag;
			state.acctStatusId = action.payload.acctStatusId;
			state.acctTypeId = action.payload.acctTypeId;
			state.privacySetting = action.payload.privacySetting;
			state.isUpdated = true;
			state.isLoading = false;
			state.isViewed = false;
			state.isPrivacyUpdated = false;
			state.isPasswordUpdated = false;
			state.isCurrentPassword = true;
			state.blockCount = action.payload.blockCount;
			state.cancelCount = action.payload.cancelCount;
			state.connReceiverAcctId = action.payload.connReceiverAcctId;
			state.interestedIndustry = action.payload.interestedIndustry.interestList;
		},
		PROFILE_PRIVACY_UPDATE_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.privacySetting = action.payload.privacySetting;
			state.isPrivacyUpdated = true;
			state.profileStrength = action.payload.profileStrength;
		},
		PROFILE_VIEW_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.acctId = action.payload.acctId;
			state.acctTypeId = action.payload.acctTypeId;
			state.acctStatusId = action.payload.acctStatusId;
			state.blockCount = action.payload.blockCount;
			state.cancelCount = action.payload.cancelCount;
			state.backgroundPic = action.payload.backgroundPic;
			state.profileStrength = action.payload.profileStrength;
			state.backgroundPicFileName = action.payload.backgroundPicFileName;
			state.backgroundTemplateFlag = action.payload.backgroundTemplateFlag;
			state.connStatusId = action.payload.connStatusId;
			state.connId = action.payload.connId;
			state.email = action.payload.email;
			state.educationRecords = action.payload.educationRecords;
			state.educationRecords = educationSort(state.educationRecords);
			state.experienceRecords = action.payload.experienceRecords;
			state.experienceRecords = experienceSort(state.experienceRecords);
			state.resume = action.payload.resume;
			state.video = action.payload.video;
			state.psychometricResult = action.payload.psychometricResult;
			state.followCount = action.payload.followCount;
			state.favouriteProfile = action.payload.favouriteProfile;
			state.followId = action.payload.followId;
			state.favId = action.payload.favId;
			state.hashtags = action.payload.hashtags;
			state.isUpdated = true;
			state.isViewed = true;
			state.isLoading = false;
			state.isModalOpen = false;
			state.profilePic = action.payload.profilePic;
			state.profileRecord = action.payload.profileRecord;
			state.privacySetting = action.payload.privacySetting;
			state.connReceiverAcctId = action.payload.connReceiverAcctId;

			state.interestedIndustry = action.payload.interestedIndustry.interestList;
		},
		PROFILE_LOAD_FAIL: (state, action) => {
			localStorage.removeItem("token");
			state.profileRecord = null;
			state.educationRecords = null;
			state.experienceRecords = null;
			state.hashtags = null;
			state.privacySetting = null;
			state.isUpdated = false;
			state.isLoading = false;
		},
		BASIC_INFO_UPDATE_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.profileRecord = action.payload.profileRecord;
			state.profileStrength = action.payload.profileStrength;
		},
		PICTURE_UPDATE_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.profilePic = action.payload.profilePic;
			state.profileStrength = action.payload.profileStrength;
		},
		RESET_UPDATE_BACKGROUND: (state, action) => {
			state.isUploaded = false;
		},
		BACKGROUND_UPDATE_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.backgroundTemplateFlag = action.payload.backgroundTemplateFlag;
			if (action.payload.backgroundTemplateFlag === 0) {
				state.backgroundPic = action.payload.backgroundPic;
				state.backgroundPicFileName = null;
			} else {
				state.backgroundPic = action.payload.backgroundPicBase64;
				state.backgroundPicFileName = action.payload.backgroundPic;
			}
			state.isUploaded = true;
		},
		EDUCATION_UPDATE_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			/* state.educationRecords.push(action.payload.educationRecords); */
			console.log(action.payload.educationRecords);
			state.educationRecords = action.payload.educationRecords;
			state.profileStrength = action.payload.profileStrength;
		},
		EDUCATION_DELETE_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.educationRecords = state.educationRecords.filter((item) => {
				return item.eduRecId !== action.payload.eduRecId;
			});
			state.profileStrength = action.payload.profileStrength;
		},
		EXPERIENCE_UPDATE_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			/* state.experienceRecords.push(action.payload.experienceRecords); */
			console.log(action.payload.experienceRecords);
			state.experienceRecords = experienceSort(
				action.payload.experienceRecords
			);
			state.profileStrength = action.payload.profileStrength;
		},
		EXPERIENCE_DELETE_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.experienceRecords = state.experienceRecords.filter((item) => {
				return item.expRecId !== action.payload.expRecId;
			});
			state.profileStrength = action.payload.profileStrength;
		},
		HASHTAG_UPDATE_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.hashtags.push(action.payload.hashtags);
			state.profileStrength = action.payload.profileStrength;
		},
		HASHTAG_DELETE_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.hashtags = state.hashtags.filter((item) => {
				return item.hashId !== action.payload.hashId;
			});
			state.profileStrength = action.payload.profileStrength;
		},
		ADD_FAV_PROFILE: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.favouriteProfile = true;
			state.favId = action.payload.favId;
		},
		REMOVE_FAV_PROFILE: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.favouriteProfile = false;
			state.favId = -1;
		},
		EDUCATION_EDIT_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			/* 	var newEducationRecords = state.educationRecords.map((item) => {
				if (item.eduRecId === action.payload.educationRecords.eduRecId) {
					item = action.payload.educationRecords;
				}
				return item;
			});
			newEducationRecords = educationSort(newEducationRecords);
			return {
				...state,
				educationRecords: newEducationRecords,
				profileStrength: action.payload.profileStrength,
			}; */
			state.profileStrength = action.payload.profileStrength;
			console.log(action.payload.educationRecords);
			state.educationRecords = educationSort(action.payload.educationRecords);
		},
		EXPERIENCE_EDIT_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			/* var newExperienceRecords = state.experienceRecords.map((item) => {
				if (item.expRecId === action.payload.experienceRecords.expRecId) {
					item = action.payload.experienceRecords;
				}
				return item;
			}); */
			/* var newExperienceRecords = experienceSort(
				action.payload.experienceRecords
			); */
			/* return {
				...state,
				experienceRecords: newExperienceRecords,
				profileStrength: action.payload.profileStrength,
			}; */
			console.log(action.payload.experienceRecords);
			state.experienceRecords = experienceSort(
				action.payload.experienceRecords
			);
			state.profileStrength = action.payload.profileStrength;
			/* state.experienceRecords = newExperienceRecords; */
		},
		UPDATE_FAIL: (state) => {
			return state;
		},
		PASSWORD_RESET_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.isPasswordUpdated = true;
			state.isCurrentPassword = true;
		},
		PASSWORD_RESET_FAIL: (state, action) => {
			state.isPrivacyUpdated = false;
			state.isCurrentPassword = false;
		},
		PASSWORD_RESET_DEFAULT: (state, action) => {
			state.isCurrentPassword = true;
			state.isPasswordUpdated = false;
		},
		CONTACT_LOADING: (state) => {
			state.isLoading = true;
		},
		CONTACT_PAGINATION_LOADING: (state) => {
			state.loadingContactList = true;
		},
		CONTACT_LOAD_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.contactList = action.payload.contactList;
			state.isUpdated = true;
			state.isLoading = false;
			state.loadingContactList = false;
			state.caName = action.payload.caName;
			state.caId = action.payload.caId;
		},
		CONTACT_LOAD_FAIL: (state, action) => {
			localStorage.removeItem("token");
			state.contactList = null;
			state.isUpdated = false;
			state.isLoading = false;
			state.loadingContactList = false;
			state.caName = "";
			state.caId = null;
		},
		CONTACT_ACCT_LOAD_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.contactAcctList = action.payload.contactAcctList;
			state.isUpdated = true;
			state.isLoading = false;
			state.googleAcctStatus = action.payload.googleStatusName;
			state.googleAcctToken = action.payload.googleAcctToken;
			state.googleLastSyncDate = action.payload.googleLastSyncDate;
			state.msAcctStatus = action.payload.msStatusName;
			state.msAcctToken = action.payload.msAcctToken;
			state.msLastSyncDate = action.payload.msLastSyncDate;
			localStorage.setItem("GCA", action.payload.googleCAId);
			localStorage.setItem("MCA", action.payload.msCAId);
		},
		CONTACT_ACCT_LOAD_FAIL: (state, action) => {
			localStorage.removeItem("token");
			state.contactAcctList = null;
			state.isUpdated = false;
			state.isLoading = false;
		},
		CONTACT_ACCT_TOKEN_REMOVE_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.isUpdated = true;
			state.isLoading = false;
			if (action.payload && action.payload.caName == "microsoft") {
				localStorage.setItem("MCA", action.payload.caId);
				state.msLastSyncDate = action.payload.lastSyncDate;
				state.msAcctStatus = action.payload.statusName;
				state.msAcctToken = action.payload.acctToken;
			}
			if (action.payload && action.payload.caName == "google") {
				localStorage.setItem("GCA", action.payload.caId);
				state.googleLastSyncDate = action.payload.lastSyncDate;
				state.googleAcctStatus = action.payload.statusName;
				state.googleAcctToken = action.payload.acctToken;
			}
		},
		CONTACT_ACCT_TOKEN_REMOVE_FAIL: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.isUpdated = false;
			state.isLoading = false;
		},
		CONTACT_INVITATION_SEND_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.contactList = action.payload.contactList;
			state.loadingContactList = false;
		},
		CONTACT_ALL_INVITATION_SEND_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.isSendAllSuccess = true;
		},
		CONTACT_INVITATION_SEND_FAIL: (state, action) => {
			localStorage.removeItem("token");
			state.contactAcctList = null;
			state.loadingContactList = false;
		},
		CONTACT_ACCT_GOOGLE_STATUS: (state, action) => {
			state.googleAcctStatus = action.payload.statusName;
			state.googleAcctToken = action.payload.acctToken;
			state.googleLastSyncDate = action.payload.lastSyncDate;
		},
		CONTACT_ACCT_MS_STATUS: (state, action) => {
			state.msAcctStatus = action.payload.statusName;
			state.msAcctToken = action.payload.acctToken;
			state.msLastSyncDate = action.payload.lastSyncDate;
		},
		CONTACT_ACCT_ERROR_IMPORT: (state, action) => {
			state.errorMessage = action.payload.errorMessage;
		},
		POPUP_WINDOW_BLOCKED: (state, action) => {
			state.errorMessage = action.payload.errorMessage;
		},
		CONNECT_CREATE_SUCCESS: (state, action) => {
			state.connId = action.payload.connId;
			state.connStatusId = action.payload.connStatusId;
			state.connReceiverAcctId = action.payload.connReceiverAcctId;
			localStorage.setItem("token", action.payload.token);
		},
		PROFILE_BLOCK_SUCCESS: (state, action) => {
			state.blockAcctId = action.payload.blockAcctId;
			state.blockId = action.payload.blockId;
		},
		PROFILE_BLOCK_DELETE: (state, action) => {
			state.blockId = null;
			state.blockAcctId = null;
			state.isDeletedBlock = true;
		},
		PROFILE_BLOCK_FAIL: (state, action) => {
			state.blockAcctId = null;
			state.blockId = null;
			state.isDeletedBlock = false;
		},
		CONNECTION_LIST_GET_SUCCESS: (state, action) => {
			state.connectionList = action.payload.connectionList;
			localStorage.setItem("token", action.payload.token);
			state.isLoading = false;
		},
		CONNECT_UPDATE_SUCCESS: (state, action) => {
			state.connId = action.payload.connId;
			state.connStatusId = action.payload.connStatusId;
			localStorage.setItem("token", action.payload.token);
			state.cancelCount = action.payload.cancelCount;
		},
		PROFILE_FOLLOW_SUCCESS: (state, action) => {
			state.followId = action.payload.followId;
			state.followCount = action.payload.followCount;
			localStorage.setItem("token", action.payload.token);
		},
		PROFILE_UNFOLLOW_SUCCESS: (state, action) => {
			state.followId = null;
			state.followCount = 0;
			localStorage.setItem("token", action.payload.token);
		},
		////////////////////  PSYCHOMETRIC TEST  //////////////////////////////////
		ALL_PSYCHOMETRIC_SUCCESS: (state, action) => {
			state.allPsychometric = action.payload.testList;
		},
		ALL_PSYCHOMETRIC_FAIL: (state, action) => {
			state.allPsychometric = [];
		},
		PSYCHOMETRIC_RESULTS_SUCCESS: (state, action) => {
			state.psychometricUserResults = action.payload.result;
		},
		PSYCHOMETRIC_RESULTS_FAIL: (state, action) => {
			state.psychometricUserResults = [];
		},

		/* PROFILE_BLOCK_SUCCESS: (state, action) => {
			state.blockCount = 1;
			localStorage.setItem("token", action.payload.token);

		} */
	},
});

const {
	CR_INT_INDUSTRY_SUCCESS,
	CR_INT_INDUSTRY_FAIL,
	INT_INDUSTRY_SUCCESS,
	INT_INDUSTRY_FAIL,
	ALL_INDUSTRY_SUCCESS,
	ALL_COUNTRIES_SUCCESS,
	ALL_COUNTRIES_FAIL,
	ALL_INDUSTRY_FAIL,
	ALL_DEP_SUCCESS,
	ALL_DEP_FAIL,
	STUDY_FIELD_SUCCESS,
	STUDY_FIELD_FAIL,
	SCHOOLS_SUCCESS,
	SCHOOLS_FAIL,
	ED_LEVEL_SUCCESS,
	ED_LEVEL_FAIL,
	PROFILE_LOAD_SUCCESS,
	PROFILE_VIEW_SUCCESS,
	PROFILE_LOAD_FAIL,
	BASIC_INFO_UPDATE_SUCCESS,
	UPDATE_FAIL,
	PICTURE_UPDATE_SUCCESS,
	BACKGROUND_UPDATE_SUCCESS,
	RESET_UPDATE_BACKGROUND,
	EDUCATION_UPDATE_SUCCESS,
	EDUCATION_DELETE_SUCCESS,
	EXPERIENCE_DELETE_SUCCESS,
	EXPERIENCE_UPDATE_SUCCESS,
	HASHTAG_UPDATE_SUCCESS,
	HASHTAG_DELETE_SUCCESS,
	EDUCATION_EDIT_SUCCESS,
	EXPERIENCE_EDIT_SUCCESS,
	ADD_FAV_PROFILE,
	REMOVE_FAV_PROFILE,
	PROFILE_LOADING,
	PROFILE_PRIVACY_UPDATE_SUCCESS,
	PASSWORD_RESET_SUCCESS,
	PASSWORD_RESET_FAIL,
	PASSWORD_RESET_DEFAULT,
	CONTACT_PAGINATION_LOADING,
	CONTACT_LOAD_SUCCESS,
	CONTACT_LOAD_FAIL,
	CONTACT_ACCT_LOAD_SUCCESS,
	CONTACT_ACCT_LOAD_FAIL,
	CONTACT_ACCT_TOKEN_REMOVE_SUCCESS,
	CONTACT_ACCT_TOKEN_REMOVE_FAIL,
	CONTACT_INVITATION_SEND_SUCCESS,
	CONTACT_ALL_INVITATION_SEND_SUCCESS,
	CONTACT_INVITATION_SEND_FAIL,
	CONTACT_ACCT_GOOGLE_STATUS,
	CONTACT_ACCT_MS_STATUS,
	CONTACT_ACCT_ERROR_IMPORT,
	POPUP_WINDOW_BLOCKED,
	INT_INDUSTRY_DELETE_SUCCESS,
	CONNECT_CREATE_SUCCESS,
	PROFILE_BLOCK_SUCCESS,
	PROFILE_BLOCK_FAIL,
	PROFILE_BLOCK_DELETE,
	PROFILE_FOLLOW_SUCCESS,
	PROFILE_UNFOLLOW_SUCCESS,
	CONNECTION_LIST_GET_SUCCESS,
	CONNECT_UPDATE_SUCCESS,
	/////////  PSYCHOMETRIC TEST /////////////
	ALL_PSYCHOMETRIC_SUCCESS,
	ALL_PSYCHOMETRIC_FAIL,
	PSYCHOMETRIC_RESULTS_SUCCESS,
	PSYCHOMETRIC_RESULTS_FAIL
} = userSlice.actions;

export const getProfiles = (data) => async (
	dispatch
) => {


	dispatch(PROFILE_LOADING());
	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/pm/get-non-corporate-profile`,
		params: data,
	})
		.then((res) => {
			if (res.status === 201) {
				console.log(res);
				const response = {
					profileRecord: {
						aboutMe: res.data.payload.regularProfile.aboutMe,
						areaCodeId: res.data.payload.regularProfile.areaCodeId,
						contactNo: res.data.payload.regularProfile.contactNo,
						countryId: res.data.payload.regularProfile.countryId,
						firstName: res.data.payload.regularProfile.firstName,
						headline: res.data.payload.regularProfile.headline,
						locationId: res.data.payload.regularProfile.locationId,
						middleName: res.data.payload.regularProfile.middleName,
						surname: res.data.payload.regularProfile.surname,
						website: res.data.payload.regularProfile.url,
					},
					acctId: res.data.payload.regularProfile.acctId,
					acctStatusId: res.data.payload.regularProfile.acctStatusId,
					acctTypeId: res.data.payload.regularProfile.acctTypeId,
					blockCount: res.data.payload.regularProfile.blockCount,
					cancelCount: res.data.payload.regularProfile.cancelCount,
					backgroundPic: res.data.payload.regularProfile.backgroundPic,
					backgroundPicFileName:
						res.data.payload.regularProfile.backgroundPicFileName,
					backgroundTemplateFlag:
						res.data.payload.regularProfile.backgroundPicTemplateFlag,
					connStatusId: res.data.payload.regularProfile.connStatusId,
					connId: res.data.payload.regularProfile.connId,
					connReceiverAcctId:
						res.data.payload.regularProfile.connReceiverAcctId,
					email: res.data.payload.regularProfile.email,
					educationRecords: res.data.payload.regularProfile.educationRecords,
					experienceRecords: res.data.payload.regularProfile.experienceRecords,
					favouriteProfile: res.data.payload.regularProfile.favouriteProfile,
					favId: res.data.payload.regularProfile.favId,
					followCount: res.data.payload.regularProfile.followCount,
					followId: res.data.payload.regularProfile.followId,
					hashtags: res.data.payload.regularProfile.hashtags,
					profilePic: res.data.payload.regularProfile.profilePic,
					profileStrength: res.data.payload.regularProfile.profileStrength,
					privacySetting: res.data.payload.regularProfile.privacySetting,
					token: res.data.token,
					interestedIndustry: res.data.payload.regularProfile.interestIndustry,
					resume: res.data.payload.regularProfile.resume,
					video: res.data.payload.regularProfile.video,
					psychometricResult: res.data.payload.regularProfile.psychometricResult,
					scoring: res.data.payload.regularProfile.scoring,
				};

				if (data.acctId === data.userId) {
					dispatch(PROFILE_LOAD_SUCCESS(response));
				} else {
					dispatch(PROFILE_VIEW_SUCCESS(response));
				}

				// console.log(res.data);
			} else {
				console.log(res);
				dispatch(PROFILE_LOAD_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};
export const updateBasicInfo = ({
	acctId,
	surname,
	firstName,
	middleName,
	countryId,
	locationId,
	areaCodeId,
	contactNo,
	url,
	headline,
	aboutMe,
	accessToken,
}) => async (dispatch) => {
	const body = {
		acctId,
		surname,
		firstName,
		middleName,
		countryId,
		locationId,
		areaCodeId,
		contactNo,
		headline,
		aboutMe,
		url,
		accessToken,
	};

	await axios({
		method: "PUT",
		headers: header,
		url: `${instanceUrl}/api/pm/update-non-corporate-profile`,
		data: body,
	})
		.then((res) => {
			if (res.status === 201) {
				console.log(res);
				const response = {
					profileRecord: {
						surname,
						firstName,
						middleName,
						countryId,
						locationId,
						areaCodeId,
						contactNo,
						headline,
						aboutMe,
						website: url,
					},
					profileStrength: res.data.payload.result.profileStrength,

					token: res.data.token,
				};
				dispatch(BASIC_INFO_UPDATE_SUCCESS(response));
				dispatch(buildProfile());
			} else {
				dispatch(UPDATE_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const updateProfilePicture = ({
	acctId,
	email,
	profilePic,
	accessToken,
}) => async (dispatch) => {
	const body = {
		acctId,
		email,
		profilePic,
		accessToken: accessToken,
	};
	console.log(body);
	console.log(accessToken);
	await axios({
		method: "PUT",
		headers: header,
		url: `${instanceUrl}/api/pm/upload-profile-pic`,
		data: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				const response = {
					profilePic,
					token: res.data.token,
					profileStrength: res.data.payload.result.profileStrength,
				};
				const authResponse = {
					profilePic,
				};
				dispatch(updateProfilePicForAuth(authResponse));
				dispatch(PICTURE_UPDATE_SUCCESS(response));
			} else {
				dispatch(UPDATE_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const updateBackgroundPicture = ({
	acctId,
	email,
	backgroundPic,
	backgroundTemplateFlag,
	accessToken,
	backgroundPicBase64,
}) => async (dispatch) => {
	const body = {
		acctId,
		email,
		backgroundPic,
		backgroundTemplateFlag,
		accessToken: accessToken,
	};
	dispatch(RESET_UPDATE_BACKGROUND());
	await axios({
		method: "PUT",
		headers: header,
		url: `${instanceUrl}/api/pm/upload-background-pic`,
		data: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				const response = {
					backgroundPic,
					backgroundTemplateFlag,
					token: res.data.token,
					backgroundPicBase64: backgroundPicBase64,
				};
				dispatch(BACKGROUND_UPDATE_SUCCESS(response));
			} else {
				dispatch(UPDATE_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const updateEducationProfile = ({
	email,
	acctId,
	eduRecId,
	school,
	schoolId,
	countryId,
	locationId,
	studyFieldId,
	eduLvlId,
	courseName,
	startDate,
	endDate,
	currentInProgressFlag,
	achievement,
	description,
	extraCurricular,
	accessToken,
	studyField,
	requestNotifyRecord,
	educationAutoEnd,
	isVisible,
	ignoreFlag
}) => async (dispatch) => {
	const body = {
		email,
		acctId,
		eduRecId,
		school,
		schoolId,
		countryId,
		locationId,
		studyFieldId,
		eduLvlId,
		courseName,
		startDate,
		endDate,
		currentInProgressFlag,
		achievement,
		description,
		extraCurricular,
		accessToken,
		studyField,
		requestNotifyRecord,
		educationAutoEnd,
		isVisible,
		ignoreFlag
	};
	console.log(body);
	await axios({
		method: "PUT",
		headers: header,
		url: `${instanceUrl}/api/pm/insert-update-education-record`,
		data: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				const response = {
					/* educationRecords: {
						eduRecId: res.data.payload.recordId,
						school,
						schoolId,
						countryId,
						locationId,
						studyFieldId,
						eduLvlId,
						courseName,
						startDate,
						endDate,
						currentInProgressFlag,
						achievement,
						description,
						extraCurricular,
						studyField,
						requestNotifyRecord,
					}, */
					educationRecords: res.data.payload.educationRecords.educationRecords,
					token: res.data.token,
					profileStrength: res.data.payload.profileStrength,
				};
				dispatch(EDUCATION_UPDATE_SUCCESS(response));
			} else {
				dispatch(UPDATE_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const deleteEducationProfile = ({
	acctId,
	accessToken,
	email,
	eduRecId,
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		email,
		eduRecId,
	};
	await axios({
		method: "DELETE",
		headers: header,
		url: `${instanceUrl}/api/pm/remove-education-record`,
		data: body,
	})
		.then((res) => {
			console.log(res);
			const response = {
				eduRecId,
				token: res.data.token,
				profileStrength: res.data.payload.result.profileStrength,
			};
			if (res.status === 201) {
				dispatch(EDUCATION_DELETE_SUCCESS(response));
			} else {
				dispatch(UPDATE_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const updateExperienceProfile = ({
	expRecId,
	acctId,
	title,
	countryId,
	locationId,
	organization,
	startDate,
	endDate,
	currentInProgressFlag,
	achievement,
	description,
	email,
	industryId,
	departmentId,
	customIndustryName,
	customDepartmentName,
	accessToken,
	experienceAutoEnd,
	requestNotifyRecord,
	isVisible,
	ignoreFlag
}) => async (dispatch) => {
	const body = {
		expRecId,
		acctId,
		title,
		countryId,
		locationId,
		organization,
		startDate,
		endDate,
		currentInProgressFlag,
		achievement,
		description,
		email,
		industryId,
		departmentId,
		customIndustryName,
		customDepartmentName,
		accessToken,
		experienceAutoEnd,
		requestNotifyRecord,
		isVisible,
		ignoreFlag
	};
	console.log(body);
	await axios({
		method: "PUT",
		headers: header,
		url: `${instanceUrl}/api/pm/insert-update-experience-record`,
		data: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				const response = {
					/* experienceRecords: {
						expRecId: res.data.payload.recordId,
						title,
						organization,
						countryId,
						locationId,
						startDate,
						endDate,
						currentInProgressFlag,
						achievement,
						description,
					}, */
					experienceRecords:
						res.data.payload.experienceRecords.experienceRecords,
					token: res.data.token,
					profileStrength: res.data.payload.profileStrength,
				};
				dispatch(EXPERIENCE_UPDATE_SUCCESS(response));
			} else {
				console.log(res);
				dispatch(UPDATE_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const deleteExperienceRecord = ({
	acctId,
	accessToken,
	email,
	expRecId,
}) => async (dispatch) => {
	const body = {
		acctId,
		email,
		expRecId,
		accessToken,
	};

	await axios({
		method: "DELETE",
		headers: header,
		url: `${instanceUrl}/api/pm/remove-experience-record`,
		data: body,
	})
		.then((res) => {
			console.log(res);
			const response = {
				expRecId,
				token: res.data.token,
				profileStrength: res.data.payload.profileStrength,
			};

			if (res.status === 201) {
				dispatch(EXPERIENCE_DELETE_SUCCESS(response));
			} else {
				dispatch(UPDATE_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
			dispatch(UPDATE_FAIL());
		});
};

export const updateHashtag = ({
	acctId,
	accessToken,
	email,
	hashId,
	hashtag,
}) => async (dispatch) => {
	const body = { acctId, accessToken, email, hashId, hashtag };
	await axios({
		method: "PUT",
		headers: header,
		url: `${instanceUrl}/api/pm/insert-update-hashtag`,
		data: body,
	})
		.then((res) => {
			if (res.status === 201) {
				//console.log(res);
				//recordId,
				const response = {
					hashtags: {
						hashId: res.data.payload.recordId,
						hashtag,
					},
					token: res.data.token,
					profileStrength: res.data.payload.profileStrength,
				};
				dispatch(HASHTAG_UPDATE_SUCCESS(response));
			} else {
				dispatch(UPDATE_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			dispatch(UPDATE_FAIL());
		});
};

export const deleteHashtag = ({ acctId, accessToken, email, hashId }) => async (
	dispatch
) => {
	const body = {
		acctId,
		accessToken,
		email,
		hashId,
	};
	await axios({
		method: "DELETE",
		headers: header,
		url: `${instanceUrl}/api/pm/remove-hashtag`,
		data: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				const response = {
					hashId,
					token: res.data.token,
					profileStrength: res.data.payload.result.profileStrength,
				};
				dispatch(HASHTAG_DELETE_SUCCESS(response));
			} else {
				dispatch(UPDATE_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			dispatch(UPDATE_FAIL());
		});
};

export const editEducationProfile = ({
	email,
	acctId,
	eduRecId,
	school,
	schoolId,
	countryId,
	locationId,
	studyFieldId,
	eduLvlId,
	courseName,
	startDate,
	endDate,
	currentInProgressFlag,
	achievement,
	description,
	extraCurricular,
	accessToken,
	studyField,
	educationAutoEnd,
	isVisible,
	ignoreFlag
}) => async (dispatch) => {
	const body = {
		email,
		acctId,
		eduRecId,
		school,
		schoolId,
		countryId,
		locationId,
		studyFieldId,
		eduLvlId,
		courseName,
		startDate,
		endDate,
		currentInProgressFlag,
		achievement,
		description,
		extraCurricular,
		accessToken,
		studyField,
		educationAutoEnd,
		isVisible,
		ignoreFlag
	};
	console.log(body);
	await axios({
		method: "PUT",
		headers: header,
		url: `${instanceUrl}/api/pm/insert-update-education-record`,
		data: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				const response = {
					/* educationRecords: {
						eduRecId: res.data.payload.recordId,
						school,
						schoolId,
						countryId,
						locationId,
						studyFieldId,
						eduLvlId,
						courseName,
						startDate,
						endDate,
						currentInProgressFlag,
						achievement,
						description,
						extraCurricular,
						studyField,
					}, */
					educationRecords: res.data.payload.educationRecords.educationRecords,
					token: res.data.token,
					profileStrength: res.data.payload.profileStrength,
				};
				dispatch(EDUCATION_EDIT_SUCCESS(response));
			} else {
				dispatch(UPDATE_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const editExperienceProfile = ({
	acctId,
	accessToken,
	email,
	expRecId,
	title,
	organization,
	countryId,
	locationId,
	startDate,
	endDate,
	currentInProgressFlag,
	achievement,
	description,
	experienceAutoEnd,
	isVisible,
	ignoreFlag
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		email,
		expRecId,
		title,
		organization,
		countryId,
		locationId,
		startDate,
		endDate,
		currentInProgressFlag,
		achievement,
		description,
		experienceAutoEnd,
		isVisible,
		ignoreFlag
	};
	console.log(body);
	await axios({
		method: "PUT",
		headers: header,
		url: `${instanceUrl}/api/pm/insert-update-experience-record`,
		data: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				const response = {
					/* experienceRecords: {
						expRecId: res.data.payload.recordId,
						title,
						organization,
						countryId,
						locationId,
						startDate,
						endDate,
						currentInProgressFlag,
						achievement,
						description,
					}, */
					experienceRecords:
						res.data.payload.experienceRecords.experienceRecords,
					token: res.data.token,
					profileStrength: res.data.payload.profileStrength,
				};
				dispatch(EXPERIENCE_EDIT_SUCCESS(response));
			} else {
				console.log(res);
				dispatch(UPDATE_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};
export const addFavouriteProfile = ({
	email,
	acctId,
	profileId,
	accessToken,
}) => async (dispatch) => {
	const body = { email, acctId, profileId, accessToken };
	await axios({
		method: "POST",
		headers: header,
		url: `${instanceUrl}/api/pm/add-favourite-profile`,
		data: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				console.log(res.data);
				const response = {
					token: res.data.token,
					favId: res.data.payload.recordId,
				};
				dispatch(ADD_FAV_PROFILE(response));
			} else {
				dispatch(UPDATE_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const addFavouriteProfileProvider = ({
	email,
	acctId,
	profileId,
	accessToken,
	bizId,
}) => async (dispatch) => {
	const body = { email, acctId, profileId, accessToken, bizId };
	await axios({
		method: "POST",
		headers: header,
		url: `${instanceUrl}/api/pm/add-favourite-profile`,
		data: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				console.log(res.data);
				const response = {
					token: res.data.token,
					favId: res.data.payload.recordId,
				};
				dispatch(ADD_FAV_PROFILE(response));
			} else {
				dispatch(UPDATE_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const removeFavouriteProfile = ({
	email,
	acctId,
	favId,
	accessToken,
}) => async (dispatch) => {
	const body = { email, acctId, favId, accessToken };
	await axios({
		method: "DELETE",
		headers: header,
		url: `${instanceUrl}/api/pm/remove-favourite-profile`,
		data: body,
	})
		.then((res) => {
			console.log(res.data);
			if (res.status === 201) {
				console.log(res.data);
				const response = {
					token: res.data.token,
				};
				dispatch(REMOVE_FAV_PROFILE(response));
			} else {
				dispatch(UPDATE_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const updatePrivacySetting = ({
	email,
	acctId,
	privacySetting,
	accessToken,
}) => async (dispatch) => {
	const body = { email, acctId, privacySetting, accessToken };
	await axios({
		method: "PUT",
		headers: header,
		url: `${instanceUrl}/api/pm/update-privacy-settings`,
		data: body,
	})
		.then((res) => {
			if (res.status === 201) {
				const response = {
					token: res.data.token,
					privacySetting: privacySetting,
					profileStrength: res.data.payload.result.profileStrength,
				};
				dispatch(PROFILE_PRIVACY_UPDATE_SUCCESS(response));
			} else {
				dispatch(UPDATE_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const updatePrivacySingleSetting = (data, cb) => async (dispatch) => {
	const body = data
	await axios({
		method: "PUT",
		headers: header,
		url: `${instanceUrl}/api/pm/update-privacy-settings`,
		data: body,
	})
		.then((res) => {
			if (res.status === 201) {
				cb(res.data)
			} else {
				dispatch(UPDATE_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const getPrivacySettings = ({
	email,
	acctId,
	accessToken,
},) => async (dispatch) => {
	const body = { email, acctId, accessToken };
	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/pm/get-non-corporate-profile`,
		params: body,
	})
		.then((res) => {
			if (res.status === 201) {
				console.log(res.data)
			} else {
				dispatch(UPDATE_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const resetPassword = ({
	acctId,
	acctTypeId,
	socialAcctId,
	email,
	currentPassword,
	password,
	accessToken,
}) => async (dispatch) => {
	const body = {
		acctId,
		acctTypeId,
		socialAcctId,
		email,
		currentPassword,
		password,
		accessToken,
	};
	console.log(body);
	await axios({
		method: "PUT",
		headers: header,
		url: `${instanceUrl}/api/um/update-password`,
		data: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				const response = {
					token: res.data.payload.accessToken,
				};
				dispatch(PASSWORD_RESET_SUCCESS(response));
			} else {
				dispatch(PASSWORD_RESET_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};
export const resetPasswordMsg = () => async (dispatch) => {
	dispatch(PASSWORD_RESET_DEFAULT());
};

function educationSort(newEducationRecords) {
	return newEducationRecords
		.sort((a, b) => {
			var today = new Date();
			var date = today.getFullYear() + "-";
			date += today.getMonth() + 1;
			date += "-01";
			var present = TimeFormatFirstDateOfMonth(date);
			console.log(present);
			if (a.endDate == null) var a_endDate = present;
			else var a_endDate = TimeFormatFirstDateOfMonth(a.endDate);
			if (b.endDate == null) var b_endDate = present;
			else var b_endDate = TimeFormatFirstDateOfMonth(b.endDate);
			if (Number(new Date(a_endDate)) < Number(new Date(b_endDate))) {
				return -1;
			} else if (Number(new Date(a_endDate)) > Number(new Date(b_endDate))) {
				return 1;
			} else if (Number(new Date(a_endDate)) == Number(new Date(b_endDate))) {
				var a_startDate = TimeFormatFirstDateOfMonth(a.startDate);
				var b_startDate = TimeFormatFirstDateOfMonth(b.startDate);
				if (Number(new Date(a_startDate)) < Number(new Date(b_startDate))) {
					return -1;
				} else if (
					Number(new Date(a_startDate)) > Number(new Date(b_startDate))
				) {
					return 1;
				} else if (
					Number(new Date(a_startDate)) == Number(new Date(b_startDate))
				) {
					var a_school = a.school.toLowerCase();
					var b_school = b.school.toLowerCase();
					if (a_school < b_school) {
						return 1;
					} else if (a_school > b_school) {
						return -1;
					} else if (a_school == b_school) {
						var a_id = a.eduRecId;
						var b_id = b.eduRecId;
						if (a_id < b_id) {
							return 1;
						} else if (a_id > b_id) {
							return -1;
						}
					}
				}
			}
		})
		.reverse();
}

function experienceSort(newExperienceRecords) {
	return newExperienceRecords
		.sort((a, b) => {
			var today = new Date();
			var date = today.getFullYear() + "-";
			date += today.getMonth() + 1;
			date += "-01";
			var present = TimeFormatFirstDateOfMonth(date);
			console.log(present);
			if (a.endDate == null) var a_endDate = present;
			else var a_endDate = TimeFormatFirstDateOfMonth(a.endDate);
			if (b.endDate == null) var b_endDate = present;
			else var b_endDate = TimeFormatFirstDateOfMonth(b.endDate);
			if (Number(new Date(a_endDate)) < Number(new Date(b_endDate))) {
				return -1;
			} else if (Number(new Date(a_endDate)) > Number(new Date(b_endDate))) {
				return 1;
			} else if (Number(new Date(a_endDate)) == Number(new Date(b_endDate))) {
				var a_startDate = TimeFormatFirstDateOfMonth(a.startDate);
				var b_startDate = TimeFormatFirstDateOfMonth(b.startDate);
				if (Number(new Date(a_startDate)) < Number(new Date(b_startDate))) {
					return -1;
				} else if (
					Number(new Date(a_startDate)) > Number(new Date(b_startDate))
				) {
					return 1;
				} else if (
					Number(new Date(a_startDate)) == Number(new Date(b_startDate))
				) {
					var a_title = a.title.toLowerCase();
					var b_title = b.title.toLowerCase();
					if (a_title < b_title) {
						return 1;
					} else if (a_title > b_title) {
						return -1;
					} else if (a_title == b_title) {
						var a_id = a.expRecId;
						var b_id = b.expRecId;
						if (a_id < b_id) {
							return 1;
						} else if (a_id > b_id) {
							return -1;
						}
					}
				}
			}
		})
		.reverse();
}

export const googleContactSync = ({ acctId, email, accessToken }) => async (
	dispatch
) => {
	const body = {
		acctId,
		email,
		accessToken,
	};
	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/um/google-contact-sync`,
		params: body,
	})
		.then((res) => {
			console.log(res);
			if (res.data.url) {
				var left = window.innerWidth / 2 - 600 / 2;
				var top = window.innerHeight / 2 - 800 / 2;
				const newWindow = window.open(
					res.data.url,
					"mywindow1",
					"status=1, toolbar=1, scrollbars=1, resizable=1, width=600, height=800, top=" +
					top +
					", left=" +
					left
				);
				if (newWindow) newWindow.opener = null;
				setTimeout(() => {
					if (
						!newWindow ||
						newWindow.closed ||
						typeof newWindow.closed === "undefined"
					) {
						const response = {
							errorMessage:
								"Please allow pop-ups on your browser in order to import your contacts",
						};
						dispatch(POPUP_WINDOW_BLOCKED(response));
					}
				}, 2000);
			}
			// getContactAccountList(body);
		})
		.catch((err) => {
			console.log(err);
		});
};

export const googleContactStatusUpdate = (status) => async (dispatch) => {
	const response = {
		statusName: status,
	};
	dispatch(CONTACT_ACCT_GOOGLE_STATUS(response));
};

export const errorContactImport = (errorMessage) => async (dispatch) => {
	if (errorMessage === "Insufficient Permission")
		errorMessage =
			"OppTy has insufficient permission to retrieve your contact list. Please try again and give the permission.";
	const response = {
		errorMessage: errorMessage,
	};
	dispatch(CONTACT_ACCT_ERROR_IMPORT(response));
};

export const microsoftContactSync = ({ acctId, email, accessToken }) => async (
	dispatch
) => {
	const body = {
		acctId,
		email,
		accessToken,
	};
	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/um/microsoft-contact-sync`,
		params: body,
	})
		.then((res) => {
			console.log(res);
			if (res.data.url) {
				var left = window.innerWidth / 2 - 600 / 2;
				var top = window.innerHeight / 2 - 800 / 2;
				const newWindow = window.open(
					res.data.url,
					"mywindow1",
					"status=1, toolbar=1, scrollbars=1, resizable=1, width=600, height=800, top=" +
					top +
					", left=" +
					left
				);
				if (newWindow) newWindow.opener = null;
				setTimeout(() => {
					if (
						!newWindow ||
						newWindow.closed ||
						typeof newWindow.closed === "undefined"
					) {
						const response = {
							errorMessage:
								"Please allow pop-ups on your browser in order to import your contacts",
						};
						dispatch(POPUP_WINDOW_BLOCKED(response));
					}
				}, 2000);
			}
			// getContactAccountList(body);
		})
		.catch((err) => {
			console.log(err);
		});
};

export const microsoftContactStatusUpdate = (status) => async (dispatch) => {
	const response = {
		statusName: status,
	};
	dispatch(CONTACT_ACCT_MS_STATUS(response));
};

export const getContactAccount = ({
	acctId,
	email,
	accessToken,
	resetStatus,
}) => async (dispatch) => {
	const body = {
		acctId,
		email,
		accessToken,
		resetStatus,
	};
	var contactAcctList = [];
	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/um/get-contact-acct-by-user-id`,
		params: body,
	})
		.then((res) => {
			if (res.status === 201) {
				console.log(res);
				var googleCAId = "";
				var msCAId = "";
				var googleStatusName = "";
				var msStatusName = "";
				var googleAcctToken = "";
				var msAcctToken = "";
				var googleLastSyncDate = null;
				var msLastSyncDate = null;
				res.data.payload.contactAcctList.map((item) => {
					if (item.caName === "google") {
						googleCAId = item.caId;
						googleStatusName = item.statusName;
						googleAcctToken = item.acctToken;
						googleLastSyncDate = item.lastSyncDate;
					}
					if (item.caName === "microsoft") {
						msCAId = item.caId;
						msStatusName = item.statusName;
						msAcctToken = item.acctToken;
						msLastSyncDate = item.lastSyncDate;
					}
					const contactAcctDetail = {
						caId: item.caId,
						caName: item.caName,
						lastSyncDate: item.lastSyncDate,
						status: item.status,
						statusName: item.statusName,
						lastUpdateDate: item.lastUpdateDate,
					};
					contactAcctList.push(contactAcctDetail);
				});
				const response = {
					contactAcctListLocal: res.data.payload.contactAcctList,
					contactAcctList: contactAcctList,
					token: res.data.token,
					googleCAId: googleCAId,
					googleStatusName: googleStatusName,
					googleAcctToken: googleAcctToken,
					googleLastSyncDate: googleLastSyncDate,
					msCAId: msCAId,
					msStatusName: msStatusName,
					msAcctToken: msAcctToken,
					msLastSyncDate: msLastSyncDate,
				};
				// if (res.data.payload.contactAcctList.length > 0)
				dispatch(CONTACT_ACCT_LOAD_SUCCESS(response));
			} else {
				dispatch(CONTACT_ACCT_LOAD_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const removeContactAcctARToken = ({
	acctId,
	email,
	caId,
	accessToken,
}) => async (dispatch) => {
	const body = {
		acctId,
		email,
		caId,
		accessToken,
	};
	await axios({
		method: "PUT",
		headers: header,
		url: `${instanceUrl}/api/um/remove-contact-acct-token`,
		data: body,
	})
		.then((res) => {
			console.log(res);
			// if (account === "microsoft")
			// if (account === "google")
			// 	dispatch(googleContactStatusUpdate("Sync Completed"));
			const response = {
				token: res.data.token,
				caId: res.data.payload.user.caId,
				caName: res.data.payload.user.caName,
				lastSyncDate: res.data.payload.user.lastSyncDate,
				lastUpdateDate: res.data.payload.user.lastUpdateDate,
				statusId: res.data.payload.user.status,
				statusName: res.data.payload.user.statusName,
				acctToken: res.data.payload.user.acctToken,
			};
			if (res.status === 201) {
				dispatch(CONTACT_ACCT_TOKEN_REMOVE_SUCCESS(response));
			} else {
				dispatch(CONTACT_ACCT_TOKEN_REMOVE_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const getContactAccountStatus = ({
	acctId,
	email,
	accessToken,
	caId,
}) => async (dispatch) => {
	const body = {
		acctId,
		email,
		accessToken,
		caId,
	};
	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/um/check-contact-acct-status`,
		params: body,
	})
		.then((res) => {
			if (res.status === 201) {
				if (res.data.payload.length > 0) {
					var caName = "";
					var statusName = "";
					var lastSyncDate = null;
					var acctToken = false;
					if (localStorage.getItem("GCA") == caId) {
						caName = "google";
						statusName = res.data.payload[0].statusName;
						acctToken = res.data.payload[0].acctToken;
						lastSyncDate = res.data.payload[0].lastSyncDate;
					}
					if (localStorage.getItem("MCA") == caId) {
						caName = "microsoft";
						statusName = res.data.payload[0].statusName;
						acctToken = res.data.payload[0].acctToken;
						lastSyncDate = res.data.payload[0].lastSyncDate;
					}
					const response = {
						statusName: statusName,
						acctToken: acctToken,
						lastSyncDate: lastSyncDate,
						token: res.data.token,
					};
					if (caName === "google")
						dispatch(CONTACT_ACCT_GOOGLE_STATUS(response));
					if (caName === "microsoft")
						dispatch(CONTACT_ACCT_MS_STATUS(response));
				}
			} else {
				// dispatch(CONTACT_ACCT_LOAD_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const createInterestIndustry = ({
	email,
	acctId,
	interestList,
	accessToken,
}) => async (dispatch) => {
	const body = {
		email,
		acctId,
		interestList,
		accessToken,
	};
	await axios({
		method: "POST",
		headers: header,
		url: `${instanceUrl}/api/pm/create-interest-industry`,
		data: body,
	})
		.then((res) => {
			if (res.status === 201) {
				console.log(res);
				const response = {
					token: res.data.token,
					interestIndustry: res.data.payload.interestIndustryList.interestList,
				};
				dispatch(CR_INT_INDUSTRY_SUCCESS(response));
			} else {
				dispatch(CR_INT_INDUSTRY_FAIL());
			}
		})
		.catch((err) => {
			console.log(err);
			dispatch(CR_INT_INDUSTRY_FAIL());
		});
};

export const deleteInterestIndustry = ({
	email,
	acctId,
	intsId,
	accessToken,
}) => async (dispatch) => {
	const body = {
		email,
		acctId,
		intsId,
		accessToken,
	};

	await axios({
		method: "PUT",
		headers: header,
		url: `${instanceUrl}/api/pm/delete-interest-industry`,
		data: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				const response = {
					token: res.data.token,
					intsId,
				};
				dispatch(INT_INDUSTRY_DELETE_SUCCESS(response));
			} else {
				dispatch(UPDATE_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const getContacts = ({
	acctId,
	email,
	caId,
	caName,
	lastRecordSize,
	requestRecordSize,
	accessToken,
}) => async (dispatch) => {
	const body = {
		acctId,
		email,
		caId,
		accessToken,
		lastRecordSize,
		requestRecordSize,
	};
	// console.log(body);
	var contactList = [];
	dispatch(CONTACT_PAGINATION_LOADING());

	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/um/get-contact-by-contact-acct-id`,
		params: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				res.data.payload.contactList.map((item) => {
					const contactDetail = {
						ctId: item.ctId,
						firstName: item.firstName,
						lastName: item.lastName,
						email: item.email,
						contactNo: item.contactNo,
						status: item.status,
						statusName: item.statusName,
						lastUpdateDate: item.lastUpdateDate,
						contactCount: item.contactCount,
					};
					contactList.push(contactDetail);
				});
				const response = {
					caId: caId,
					caName: caName,
					contactList: contactList,
					token: res.data.token,
				};
				dispatch(CONTACT_LOAD_SUCCESS(response));
			} else {
				dispatch(CONTACT_LOAD_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const getAllEducationLevels = () => async (dispatch) => {
	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/md/get-all-education-level`,
	})
		.then((res) => {
			dispatch(ED_LEVEL_SUCCESS(res.data.payload));
		})
		.catch((err) => {
			console.log(err);
			dispatch(ED_LEVEL_FAIL());
		});
};

export const getAllSchools = () => async (dispatch) => {
	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/md/get-all-school`,
	})
		.then((res) => {
			dispatch(SCHOOLS_SUCCESS(res.data.payload));
		})
		.catch((err) => {
			console.log(err);
			dispatch(SCHOOLS_FAIL());
		});
};

export const getAllStudyField = () => async (dispatch) => {
	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/md/get-all-study-field`,
	})
		.then((res) => {
			dispatch(STUDY_FIELD_SUCCESS(res.data.payload));
		})
		.catch((err) => {
			console.log(err);
			dispatch(STUDY_FIELD_FAIL());
		});
};

export const getAllIndustry = () => async (dispatch) => {
	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/md/get-all-industry`,
	})
		.then((res) => {
			dispatch(ALL_INDUSTRY_SUCCESS(res.data.payload));
		})
		.catch((err) => {
			console.log(err);
			dispatch(ALL_INDUSTRY_FAIL());
		});
};
export const getAllCountries = () => async (dispatch) => {
	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/md/get-all-country`,
	})
		.then((res) => {
			dispatch(ALL_COUNTRIES_SUCCESS(res.data.payload));
		})
		.catch((err) => {
			dispatch(ALL_COUNTRIES_FAIL());
			console.log(err);
		});
};

export const getAllDep = (industryId) => async (dispatch) => {
	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/md/get-department-by-industry?industryId=${industryId}`,
	})
		.then((res) => {
			dispatch(ALL_DEP_SUCCESS(res.data.payload));
		})
		.catch((err) => {
			console.log(err);
			dispatch(ALL_DEP_FAIL());
		});
};

export const getInterestedIndustries = ({
	acctId,
	accessToken,
	email,
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		email,
	};
	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/pm/get-interest-industry-by-acct-id`,
		params: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				console.log(res.data.payload.interestList);
				const response = {
					interestedIndustry: res.data.payload.interestList,
					token: res.data.token,
				};
				dispatch(INT_INDUSTRY_SUCCESS(response));
			} else {
				dispatch(INT_INDUSTRY_FAIL());
			}
		})
		.catch((err) => {
			console.log(err);
			dispatch(INT_INDUSTRY_FAIL());
		});
};

export const sendContactInvitation = ({
	acctId,
	email,
	firstName,
	surname,
	accessToken,
	caId,
	ctId,
	ctEmail,
	contactList,
}) => async (dispatch) => {
	const body = {
		acctId,
		email,
		firstName,
		surname,
		accessToken,
		caId,
		ctId,
		ctEmail,
	};
	console.log(body);
	await axios({
		method: "PUT",
		headers: header,
		url: `${instanceUrl}/api/um/send-contact-invitation`,
		data: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				var newContactList = [];
				for (var i = 0; i < contactList.length; i++) {
					var status = contactList[i].status;
					var statusName = contactList[i].statusName;
					if (contactList[i].ctId === ctId && res.data.payload.result) {
						status = res.data.payload.result.statusId;
						statusName = res.data.payload.result.statusName;
					}
					const contact = {
						contactCount: contactList[i].contactCount,
						contactNo: contactList[i].contactNo,
						ctId: contactList[i].ctId,
						email: contactList[i].email,
						firstName: contactList[i].firstName,
						lastName: contactList[i].lastName,
						lastUpdateDate: contactList[i].lastUpdateDate,
						status: status,
						statusName: statusName,
					};
					newContactList.push(contact);
				}

				// console.log(newContactList);
				const response = {
					token: res.data.payload.accessToken,
					contactList: newContactList,
				};
				dispatch(CONTACT_INVITATION_SEND_SUCCESS(response));
			} else {
				dispatch(CONTACT_INVITATION_SEND_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const sendAllContactInvitation = ({
	acctId,
	email,
	accessToken,
	caId,
}) => async (dispatch) => {
	const body = {
		acctId,
		email,
		accessToken,
		caId,
	};
	console.log(body);
	await axios({
		method: "PUT",
		headers: header,
		url: `${instanceUrl}/api/um/send-all-contact-invitation`,
		data: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				const response = {
					token: res.data.payload.accessToken,
				};
				dispatch(CONTACT_ALL_INVITATION_SEND_SUCCESS(response));
			} else {
				dispatch(CONTACT_INVITATION_SEND_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const createBlockProfile = ({
	email,
	acctId,
	blockAcctId,
	accessToken,
}) => async (dispatch) => {
	const body = {
		email,
		acctId,
		blockAcctId,
		accessToken,
	};
	await axios({
		method: "POST",
		headers: header,
		url: `${instanceUrl}/api/um/create-block-profile`,
		data: body,
	})
		.then((res) => {
			if (res.status === 201) {
				console.log(res);
				const response = {
					token: res.data.token,
					blockId: res.data.payload.user.blockId,
					blockAcctId: blockAcctId,
				};
				dispatch(PROFILE_BLOCK_SUCCESS(response));
			} else {
				dispatch(PROFILE_BLOCK_FAIL());
			}
		})
		.catch((err) => {
			console.log(err);
			dispatch(PROFILE_BLOCK_FAIL());
		});
};

export const deleteBlockProfile = ({
	email,
	acctId,
	blockId,
	accessToken,
}) => async (dispatch) => {
	const body = {
		email,
		acctId,
		blockId,
		accessToken,
	};

	await axios({
		method: "PUT",
		headers: header,
		url: `${instanceUrl}/api/pm/delete-block-profile`,
		data: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				const response = {
					token: res.data.token,
				};
				dispatch(PROFILE_BLOCK_DELETE(response));
			} else {
				dispatch(PROFILE_BLOCK_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const resetBlockProfile = () => async (dispatch) => {
	dispatch(PROFILE_BLOCK_FAIL());
};

export const createConnect = ({
	receiverAcctId,
	acctId,
	email,
	connMessageDesc,
	accessToken,
}) => async (dispatch) => {
	const body = {
		receiverAcctId,
		acctId,
		email,
		connMessageDesc,
		accessToken,
	};
	axios({
		method: "POST",
		headers: header,
		url: `${instanceUrl}/api/um/create-connection-profile`,
		data: body,
	})
		.then((res) => {
			if (res.status === 201) {
				const response = {
					connId: res.data.payload.user.connId,
					connStatusId: res.data.payload.user.status,
					connReceiverAcctId: receiverAcctId,
					token: res.data.token,
				};
				dispatch(CONNECT_CREATE_SUCCESS(response));
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const updateConnect = ({
	receiverAcctId,
	acctId,
	email,
	connMessageDesc,
	accessToken,
	connId,
	connStatusId,
}) => async (dispatch) => {
	const body = {
		acctId,
		email,
		accessToken,
		connId,
		connStatusId,
	};
	console.log(body);

	axios({
		method: "PUT",
		headers: header,
		url: `${instanceUrl}/api/um/update-connection-profile-status`,
		data: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				console.log(res);
				const response = {
					connId: connStatusId === 4 || connStatusId === 2 ? null : connId,
					connStatusId:
						connStatusId === 4 || connStatusId === 2 ? null : connStatusId,
					token: res.data.token,
					cancelCount: connStatusId === 4 ? 1 : 0,
				};
				dispatch(CONNECT_UPDATE_SUCCESS(response));
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const getConnectionList = ({
	acctId,
	accessToken,
	email,
	lastRecordSize,
	requestRecordSize,
	statusId,
	keyword,
	sortBy,
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		email,
		lastRecordSize,
		requestRecordSize,
		statusId,
		keyword,
		sortBy,
	};

	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/um/get-connection-profile-by-acct-id`,
		params: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				var profileValues = [];
				for (var i = 0; i < res.data.payload.connectionList.length; i++) {
					profileValues.push({
						value: res.data.payload.connectionList[i].receiverAcctId,
						name:
							res.data.payload.connectionList[i].firstName +
							" " +
							res.data.payload.connectionList[i].lastName,
						profilePic: res.data.payload.connectionList[i].profilePic,
					});
				}
				const response = {
					connectionList: profileValues,
					token: res.data.token,
				};
				dispatch(CONNECTION_LIST_GET_SUCCESS(response));
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const followProfile = ({
	followAcctId,
	acctId,
	email,
	accessToken,
}) => async (dispatch) => {
	const body = {
		followAcctId,
		acctId,
		email,
		accessToken,
	};
	axios({
		method: "POST",
		headers: header,
		url: `${instanceUrl}/api/um/create-follow-profile`,
		data: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				console.log(res);
				const response = {
					followId: res.data.payload.user.flpfId,
					followCount: 1,
					token: res.data.payload.user.accessToken,
				};
				dispatch(PROFILE_FOLLOW_SUCCESS(response));
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const unfollowProfile = ({
	followId,
	acctId,
	email,
	accessToken,
}) => async (dispatch) => {
	const body = {
		followId,
		acctId,
		email,
		accessToken,
	};
	axios({
		method: "PUT",
		headers: header,
		url: `${instanceUrl}/api/um/delete-follow-profile`,
		data: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				console.log(res);
				const response = {
					token: res.data.token,
				};
				dispatch(PROFILE_UNFOLLOW_SUCCESS(response));
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

///////////////////////// PSYCHOMETRIC TEST ///////////////////////////////

export const getAllPsychometricTest = ({ }, cb) => async (dispatch) => {
	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/pt/get-all-psychometric-test`,
	})
		.then((res) => {
			res.data.payload && res.data.payload.testList.map((item, index) => {
				item.answer.map((ans, ind) => {
					ans.class = 'greyOuter';
				})
			});

			cb(res.data.payload.testList);
			//dispatch(ALL_PSYCHOMETRIC_SUCCESS(res.data.payload));
		})
		.catch((err) => {
			console.log(err);
			dispatch(ALL_PSYCHOMETRIC_FAIL());
		});
};

export const submitUserPsychometricTest = ({
	email,
	acctId,
	accessToken,
	results
}, cb) => async (dispatch) => {
	const body = {
		email,
		acctId,
		accessToken,
		results
	};
	await axios({
		method: "POST",
		headers: header,
		url: `${instanceUrl}/api/pt/create-user-psychometric-test`,
		data: body,
	})
		.then((res) => {
			if (res.status === 201) {
				cb(res.data);
			}
		})
		.catch((err) => {
			console.log(err);
			emptyCache();
		});
};

export const getUserPsychometricResults = ({
	accessToken,
	acctId,
	email
}) => async (dispatch) => {
	const body = {
		accessToken,
		acctId,
		email
	};

	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/pt/get-user-psychometric-result`,
		params: body
	})
		.then((res) => {
			dispatch(PSYCHOMETRIC_RESULTS_SUCCESS(res.data.payload));
		})
		.catch((err) => {
			console.log(err);
			dispatch(PSYCHOMETRIC_RESULTS_FAIL());
		});
};

export default userSlice.reducer;
