import React from "react";
import { connect, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import corporateProfilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/CorporateProfilePage/corporateProfilePageStyle.js";
import CorporateProfilePictureEditModal from "./CorporateProfilePictureEditModal.js";

import defaultAvatar from "assets/img/corporate_avatar.jpeg";

const useStyles = makeStyles(corporateProfilePageStyle);

export const CorporateProfilePictureSection = () => {
	const classes = useStyles();
	const [pictureEditModal, setPictureEditModal] = React.useState(false);
	const profilePic = useSelector((state) => state.corpUser.profilePic);
	const closePictureEditModal = () => {
		setPictureEditModal(false);
	};
	const imageClasses = classNames(
		classes.imgRaised,
		// classes.imgRoundedCircle,
		classes.imgProfile
	);
	const isViewed = useSelector((state) => state.corpUser.isViewed);
	return (
		<React.Fragment>
			{profilePic ? (
				<React.Fragment>
					<img
						src={profilePic}
						alt="..."
						className={imageClasses}
						onClick={() => setPictureEditModal(true)}
					/>
				</React.Fragment>
			) : (
				<React.Fragment>
					<img
						src={defaultAvatar}
						alt="..."
						className={imageClasses}
						onClick={() => setPictureEditModal(true)}
					/>
				</React.Fragment>
			)}
			{!isViewed && (
				<CorporateProfilePictureEditModal
					open={pictureEditModal}
					onClose={closePictureEditModal}
				/>
			)}
		</React.Fragment>
	);
};

export default CorporateProfilePictureSection;
