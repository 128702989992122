const hashTagStyle = {
	tagContainer: {
		//display: "flex",
		justifyContent: "left",
		flexWrap: "wrap",
		listStyle: "none",
		padding: "10px",
		margin: 0,
		width: "100%",
	},
	chip: {
		transition: "all 150ms ease-in",
		margin: "5px",
		minWidth: "80px",
		minHeight: "40px",
		borderRadius: "8px",
		backgroundColor: "#e3e3e3 !important",
		// "& svg": {
		// 	display: "none",
		// },
		"& p": {
			color: "#000 !important",
		},
		"&:hover": {
			cursor: "pointer",
			transition: "all 150ms ease-in",

			"& svg": {
				display: "block",
				color: "#000000 !important",
			},
		},
		"@media only screen and (max-width: 600px)": {
			minWidth: "0px",
			minHeight: "0px",
		},
	},
};
export default hashTagStyle;
