import React from "react";

import BasicProfileEditModal from "./BasicProfileEditModal.js";
import ProfilePictureEditModal from "./ProfilePictureEditModal.js";
import PrivacySettingModal from "./PrivacySettingModal.js";
import EducationEditModal from "./EducationEditModal.js";
import ExperienceEditModal from "./ExperienceEditModal.js";

import { useSelector, useDispatch } from "react-redux";
import { deactivateMasterCode } from "configurations/redux/masterFunctionSlice.js";

export default function ProfileMasterFunction() {
	const functionCode = useSelector(
		(state) => state.masterFunction.functionCode
	);
	const profileStrength = useSelector((state) => state.user.profileStrength);

	const user = useSelector((state) => state.user);
	const extension = useSelector((state) => state.masterFunction.extension);
	/* 	let container = document
		.getElementById("tagContainer")
		.getBoundingClientRect().top;
 */
	const triggerSkillsAttribute = ({ functionCode }) => {
		if (functionCode === "MF_PM_SECTION_SKILL_ATTRIBUTE") {
			var tagContainer = document.getElementById("tagContainer");
			tagContainer.scrollIntoView({
				behavior: "instant",
				block: "center",
			});
			var tagInput = document.getElementById("tagInput");
			if (tagInput) {
				tagInput.focus();
			}
		}
	};
	React.useEffect(() => {
		if (functionCode === "MF_PM_SECTION_SKILL_ATTRIBUTE") {
			var tagContainer = document.getElementById("tagContainer");
			tagContainer.scrollIntoView({
				behavior: "instant",
				block: "center",
			});
			var tagInput = document.getElementById("tagInput");
			if (tagInput) {
				tagInput.focus();
			}
		}
		/* triggerSkillsAttribute(functionCode); */
	}, [functionCode]);
	const dispatch = useDispatch();
	return (
		<React.Fragment>
			{functionCode === "MF_PM_MODAL_BASIC_PROFILE" && (
				<BasicProfileEditModal
					open={functionCode ? true : false}
					onClose={() => {
						dispatch(deactivateMasterCode());
					}}
					extension={
						profileStrength[0].strengthList
							.filter((item) => {
								return (
									item.improvementTip.functionCode ===
									"MF_PM_MODAL_BASIC_PROFILE"
								);
							})
							.filter((item) => {
								return item.value === 0;
							}).length > 0
							? profileStrength[0].strengthList
									.filter((item) => {
										return (
											item.improvementTip.functionCode ===
											"MF_PM_MODAL_BASIC_PROFILE"
										);
									})
									.filter((item) => {
										return item.value === 0;
									})
									.map((item) => {
										return item.improvementTip;
									})
							: null
					}
				/>
			)}
			{/* 	{functionCode === "MF_PM_SECTION_SKILL_ATTRIBUTE" && } */}
			{/* {functionCode === "MF_PM_MODAL_BASIC_PROFILE" && (
				<BasicProfileEditModal
					open={functionCode ? true : false}
					onClose={() => {
						dispatch(deactivateMasterCode());
					}}
					extension={extension}
				/>
			)}
			{functionCode === "MF_PM_MODAL_BASIC_PROFILE" && (
				<BasicProfileEditModal
					open={functionCode ? true : false}
					onClose={() => {
						dispatch(deactivateMasterCode());
					}}
					extension={extension}
				/>
			)} */}
			{functionCode === "MF_PM_MODAL_PROFILE_PIC" && (
				<ProfilePictureEditModal
					open={functionCode ? true : false}
					onClose={() => {
						dispatch(deactivateMasterCode());
					}}
					pictureSource={user.profilePic}
					extension={
						profileStrength[0].strengthList
							.filter((item) => {
								return (
									item.improvementTip.functionCode === "MF_PM_MODAL_PROFILE_PIC"
								);
							})
							.filter((item) => {
								return item.value === 0;
							}).length > 0
							? profileStrength[0].strengthList
									.filter((item) => {
										return (
											item.improvementTip.functionCode ===
											"MF_PM_MODAL_PROFILE_PIC"
										);
									})
									.filter((item) => {
										return item.value === 0;
									})
									.map((item) => {
										return item.improvementTip;
									})
							: null
					}
				/>
			)}
			{functionCode === "MF_PM_MODAL_PRIVACY_SETTING" && (
				<PrivacySettingModal
					open={functionCode ? true : false}
					onClose={() => {
						dispatch(deactivateMasterCode());
					}}
					extension={extension}
				/>
			)}
			{functionCode === "MF_PM_MODAL_EDUCATION" && (
				<EducationEditModal
					open={functionCode ? true : false}
					onClose={() => {
						dispatch(deactivateMasterCode());
					}}
					eduRecId={-1}
					eduRecord={null}
					extension={extension}
				/>
			)}
			{functionCode === "MF_PM_MODAL_EXPERIENCE" && (
				<ExperienceEditModal
					open={functionCode ? true : false}
					onClose={() => {
						dispatch(deactivateMasterCode());
					}}
					expRecId={-1}
					expRecord={null}
					extension={extension}
				/>
			)}
		</React.Fragment>
	);
}
