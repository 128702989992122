import React from 'react';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

import { connect, useDispatch, useSelector } from 'react-redux';
import { pdfjs, Document, Page } from 'react-pdf';
import Skeleton from '@mui/material/Skeleton';

import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import IframeResizer from 'iframe-resizer-react';
import editResumeStyles from 'assets/jss/material-kit-pro-react/views/componentStyle/profileView/editProfile/resume/editResumeStyles';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import { getAttachment, deleteAttachment } from 'configurations/redux/attachmentUserSlice.js';
import IconLibrary from 'views/Component/IconLibrary.js';
import Modal from '@mui/material/Modal';

import Pagination from 'views/Component/PaginationWithPage.js';
import Box from '@mui/material/Box';

import fileIcon from 'assets/svg/white-page-with-folded-corner.svg';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles(editResumeStyles);

const ResumeSection = (/* { userAttachmentList } */) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userAttachmentList = useSelector(state => state.attachmentUser.userAttachmentList);
  const ref = React.useRef(null);
  const userStatus = useSelector(state => state.auth.user);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [resumesPerPage, setResumesPerPage] = React.useState(6);

  const indexOfLast = currentPage * resumesPerPage;
  const indexOfFirst = indexOfLast - resumesPerPage;
  const isDeleted = useSelector(state => state.attachmentUser.isDeleted);
  const isUpdated = useSelector(state => state.attachmentUser.isUpdated);
  const isLoading = useSelector(state => state.attachmentUser.isLoading);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = React.useState(false);
  const [fileId, setFileId] = React.useState();

  const removeTextLayerOffset = () => {
    const textLayers = document.querySelectorAll('.react-pdf__Page__textContent');
    textLayers.forEach(layer => {
      const { style } = layer;
      style.top = '0';
      style.left = '0';
      style.transform = '';
    });
  };
  const paginate = pageNumber => {
    setCurrentPage(pageNumber);
    var indexOfFirst = pageNumber * resumesPerPage - resumesPerPage;

    const request = {
      email: userStatus.email,
      acctId: userStatus.acctId,
      accessToken: localStorage.getItem('token'),
      keyword: '',
      lastRecordSize: indexOfFirst,
      requestRecordSize: resumesPerPage,
    };
    dispatch(getAttachment(request));
  };
  React.useEffect(() => {
    if (isDeleted) {
      var newPage = 0;
      if (userAttachmentList.length > 0) newPage = currentPage;
      else newPage = currentPage - 1;
      var indexOfFirst = newPage * resumesPerPage - resumesPerPage;
      console.log(newPage);
      setCurrentPage(newPage);

      const request = {
        email: userStatus.email,
        acctId: userStatus.acctId,
        accessToken: localStorage.getItem('token'),
        keyword: '',
        lastRecordSize: indexOfFirst,
        requestRecordSize: resumesPerPage,
      };
      dispatch(getAttachment(request));
    }
  }, [isDeleted, userAttachmentList]);

  const NoAttachmentFile = () => {
    return (
      <GridItem>
        <Card style={{ borderRadius: '18px', boxShadow: 'none' }}>
          <CardBody>
            <h5 style={{ fontWeight: 500, fontSize: '18px' }}>You Have No Uploaded Resume.</h5>
          </CardBody>
        </Card>
      </GridItem>
    );
  };
  const AttachmentFile =
    userAttachmentList &&
    userAttachmentList.map(item => {
      return (
        <GridItem
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={4}
          style={{
            textAlign: 'center',
            width: '100%',
          }}
          key={item.attachmentId}
        >
          <Card className={classes.resumeCard}>
            <CardBody>
              {item.fileFormat.indexOf('image') !== -1 ? (
                <React.Fragment>
                  <img
                    style={{
                      margin: 'auto',
                      width: '210px',
                      height: '297px',
                      objectFit: 'cover',
                      objectPosition: 'center center',
                    }}
                    src={item.attachmentPath}
                  />
                </React.Fragment>
              ) : item.fileFormat === 'application/pdf' ? (
                <Box
                  style={{
                    width: '212px',
                    height: '297px',
                    /* backgroundColor: "#f2f3f7", */
                    display: 'flex',
                    margin: 'auto',
                    justifyContent: 'center',
                    alignContent: 'center',
                    flexDirection: 'column',
                    backgroundImage: `url(${fileIcon})`,
                    backgroundPosition: 'center center',
                    backgroundSize: '100% 100%',
                  }}
                >
                  <h4 style={{ fontSize: '18px', fontWeight: 700 }}>PDF</h4>
                </Box>
              ) : (
                <Box
                  style={{
                    width: '212px',
                    height: '297px',
                    /* backgroundColor: "#f2f3f7", */
                    display: 'flex',
                    margin: 'auto',
                    justifyContent: 'center',
                    alignContent: 'center',
                    flexDirection: 'column',
                    backgroundImage: `url(${fileIcon})`,
                    backgroundPosition: 'center center',
                    backgroundSize: '100% 100%',
                  }}
                >
                  <h4 style={{ fontSize: '18px', fontWeight: 700 }}>DOCX</h4>
                </Box>
              )}

              {/* {item.fileFormat.indexOf("image") !== -1 && (
								<React.Fragment>
									<img
										style={{
											margin: "auto",
											width: "210px",
											height: "297px",
											objectFit: "cover",
											objectPosition: "center center",
										}}
										src={item.attachmentPath}
									/>
								</React.Fragment>
							)}
							{item.fileFormat === "application/pdf" && (
								<Document
									file={{ url: item.attachmentPath }}
									loading={
										<Skeleton
											sx={{ margin: "auto" }}
											variant="rectangular"
											animation="wave"
											width={210}
											height={297}
										/>
									}
								>
									<Page
										className={classes.pdfRender}
										height={297}
										pageNumber={1}
										canvasRef={ref}
										renderAnnotationLayer={false}
										onLoadSuccess={removeTextLayerOffset}
									/>
								</Document>
							)}
							{item.fileFormat === "application/octet-st" && (
								<Box
									style={{
										width: "212px",
										height: "297px",
										display: "flex",
										margin: "auto",
										justifyContent: "center",
										alignContent: "center",
										flexDirection: "column",
										backgroundImage: `url(${fileIcon})`,
										backgroundPosition: "center center",
										backgroundSize: "100% 100%",
									}}
								>
									<h4 style={{ fontSize: "18px", fontWeight: 700 }}>DOCX</h4>
								</Box>
							)}
							{item.fileFormat === "OCTET_STREAM" && (
								<Box
									style={{
										width: "212px",
										height: "297px",
										display: "flex",
										margin: "auto",
										justifyContent: "center",
										alignContent: "center",
										flexDirection: "column",
										backgroundImage: `url(${fileIcon})`,
										backgroundPosition: "center center",
										backgroundSize: "100% 100%",
									}}
								>
									<h4 style={{ fontSize: "18px", fontWeight: 700 }}>DOCX</h4>
								</Box>
							)} */}
              <h4 className={classes.resumeTitle}>
                <b>{'Title: '}</b>
                {item.title}
              </h4>
              <p className={classes.resumeDescription}>
                <b>{'Date Uploaded: '}</b>
                {item.createdDate.substring(0, 10)}
              </p>
              <p style={{ textTransform: 'uppercase' }} className={classes.resumeDescription}>
                <b>{'Format: '}</b>
                {/* 	{item.fileFormat === "application/octet-st" ||
								item.fileFormat === "OCTET_STREAM"
									? "DOCX"
									: item.fileFormat
											.substring(item.fileFormat.indexOf("/") + 1)
											.trim()} */}

                {item.fileFormat.indexOf('image') !== -1
                  ? item.fileFormat.substring(item.fileFormat.indexOf('/') + 1).trim()
                  : item.fileFormat === 'application/pdf'
                  ? 'PDF'
                  : 'DOCX'}
              </p>
              <p className={classes.resumeDescription}>
                <b>{'Type: '}</b>
                {item.attactmentType}
              </p>

              <Button
                onClick={() => {
                  setFileId(item.attachmentId);
                  setIsCancelDialogOpen(true);
                }}
                className={classes.resumeDelete}
              >
                <IconLibrary.Trash />
                <span>Delete</span>
              </Button>
            </CardBody>
          </Card>
        </GridItem>
      );
    });
  return (
    <GridContainer>
      {userAttachmentList && userAttachmentList.length === 0 && <NoAttachmentFile />}
      {AttachmentFile}

      <GridItem>
        {userAttachmentList && userAttachmentList.length > 0 && (
          <Pagination totalPosts={userAttachmentList[0].totalCount} postsPerPage={resumesPerPage} paginate={paginate} />
        )}
      </GridItem>

      <Dialog
        open={isCancelDialogOpen}
        onClose={() => {
          setIsCancelDialogOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { width: '400px', borderRadius: '4px !important' } }}
      >
        <DialogTitle id="alert-dialog-title">{'Delete this file?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">This action cannot be undone.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsCancelDialogOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              const response = {
                email: userStatus.email,
                acctId: userStatus.acctId,
                accessToken: localStorage.getItem('token'),
                attachmentId: fileId,
              };
              dispatch(deleteAttachment(response));

              setIsCancelDialogOpen(false);
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </GridContainer>
  );
};

export default React.memo(ResumeSection);
