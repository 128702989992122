import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import axios from "axios";

import { Box, makeStyles, Modal, Typography } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import { errorCleanUp } from "configurations/redux/authSlice.js";

import Header from "views/Component/Header.js";
import LeftSidebar from "views/Component/LeftSidebar.js";
import EditProfile from "views/Component/ProfileView/EditProfile/EditProfile";
import Footer from "views/Component/Footer.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import resumePageStyles from "assets/jss/material-kit-pro-react/views/pageStyle/ResumePage/resumePageStyles";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js";
import Hidden from "@material-ui/core/Hidden";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import AddIcon from "@mui/icons-material/Add";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import businessPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/BusinessPage/businessPageStyle";
import leftarrowPic from "assets/img/left_arow.png";
import checkcircel from "assets/img/check-circel.png";
import adduser from "assets/img/add-user.png";


import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";


import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


const PaymentGateway = ({ match }) => {
	const styles = makeStyles(resumePageStyles)();
	const stylesBusiness = makeStyles(businessPageStyle)();
	const classes = makeStyles(profilePageStyle)(); //profilePageStyle

	const history = useHistory();
	const dispatch = useDispatch();
	const isError = useSelector((state) => state.auth.isError);
	const userStatus = useSelector((state) => state.auth.user);

	const searchResult = useSelector((state) => state.business.searchResult);
	const [connStatus, setConnStatus] = React.useState(null);

	const [isEditProfileOpen, setIsEditProfileOpen] = useState(false);

	const [width, height] = useWindowSize();

	const bizId = useSelector((state) => state.business.bizId);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [open11, setOpen11] = React.useState(false);
  const handleOpen11 = () => setOpen11(true);
  const handleClose11 = () => setOpen11(false);

    const [open12, setOpen12] = React.useState(false);
  const handleOpen12 = () => setOpen12(true);
  const handleClose12 = () => setOpen12(false);

  function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('abx@gmail.com', 'Content Manager', 'Richdale Malaysia', 'Pending Acceptance', 'Remove Access'),
    createData('jansen@oppty.net', 'Recruiter Manager', 'OppTy Pte Ltd', 'Active', 'Remove Access'),

];



const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};  
const style2 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};  


 function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}




function useWindowSize() {
	const [size, setSize] = React.useState([0, 0]);
	React.useLayoutEffect(() => {
		function updateSize() {
			setSize([window.innerWidth, window.innerHeight]);
		}
		window.addEventListener("resize", updateSize);
		updateSize();
		return () => window.removeEventListener("resize", updateSize);
	}, []);
	return size;
}
	React.useEffect(() => {
		if (isError) {
			dispatch(errorCleanUp());
		}
	}, []);


	return (
		<div>
			<Header color={"transparent"} loggedInStatus={false} />
		
			<div className={styles.body}>
				<div id="pageHeader" className={styles.pageHeader}>
					<div className={styles.container}>
						<GridContainer justifyContent="center" spacing={1}>
						

							<GridItem xs={12} sm={12} md={12} lg={11}>
							<div className="paymentOuter">
              <h3>Choose the plan that’s right for<br />
               your business</h3>

              <div className="radioOuter">
                <FormControl>
    
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="female" control={<Radio />} label="1 year (Save 25%)" />
        <FormControlLabel value="male" control={<Radio />} label="6 Months (Save 15%)" />
        <FormControlLabel value="other" control={<Radio />} label="3 Months (Save 10%)" />
        <FormControlLabel value="monthly" control={<Radio />} label="Monthly" />
      </RadioGroup>
    </FormControl>
              </div>
              <div className="user_list">
                  <ul>
                  <li className="list1">
<div className="top_heading">

  
</div>
<div className="bottom_text tet68">
<p>Internship Job Post</p>
<p>Candidate Search</p>
<p>Job Post or <br />
Digital Headhunting</p>
<p>A.I. Chatbot</p>
<p>Boost Job Post</p>
<div className="buton_name Hidden">
<button class="MuiButtonBase-root MuiButton-root MuiButton-text makeStyles-button-309 followBtn1" tabindex="0" type="button"><span class="MuiButton-label">Subscribe</span><span class="MuiTouchRipple-root"></span></button>
</div>
</div>
</li>

<li className="list1">
<div className="top_heading">
  <h4>Basic</h4>
  <p><del>$250</del></p>
  <h1>$162</h1>
  <p>Per month<br />
Billed quarterly</p>
  
</div>
<div className="bottom_text">
<p className="left_list6"><i class="fa-solid fa-circle-check"></i><span>Unlimited Internship <br />
Job Post</span></p>
<p>20</p>
<p>-</p>
<p>-</p>
<p>-</p>
<div className="buton_name">
<button class="MuiButtonBase-root MuiButton-root MuiButton-text makeStyles-button-309 followBtn1 currentPlan" tabindex="0" type="button"><span class="MuiButton-label">Current Plan</span><span class="MuiTouchRipple-root"></span></button>
</div>
</div>
</li>
    
    <li className="list1">
<div className="top_heading">
  <h4>Premium</h4>
  <p><del>$250</del></p>
  <h1>$360</h1>
  <p>Per month<br />
Billed quarterly</p>
  
</div>
<div className="bottom_text">
<p className="left_list6"><i class="fa-solid fa-circle-check"></i><span>Unlimited Internship <br />
Job Post</span></p>
<p>Unlimited</p>
<p>5</p>
<p className="left_list6"><i class="fa-solid fa-circle-check"></i><span>Full Features</span></p>
<p>1</p>
<div className="buton_name">
<button class="MuiButtonBase-root MuiButton-root MuiButton-text makeStyles-button-309 followBtn1" tabindex="0" type="button"><span class="MuiButton-label">Subscribe</span><span class="MuiTouchRipple-root"></span></button>
</div>
</div>
</li>
<li className="list1">
<div className="top_heading">
  <h4>Pro</h4>
  <p><del>$250</del></p>
  <h1>$585</h1>
  <p>Per month<br />
Billed quarterly</p>
  
</div>
<div className="bottom_text">
<p className="left_list6"><i class="fa-solid fa-circle-check"></i><span>Unlimited Internship <br />
Job Post</span></p>
<p>Unlimited</p>
<p>5</p>
<p className="left_list6"><i class="fa-solid fa-circle-check"></i><span>Full Features</span></p>
<p>1</p>
<div className="buton_name">
<button class="MuiButtonBase-root MuiButton-root MuiButton-text makeStyles-button-309 followBtn1" tabindex="0" type="button"><span class="MuiButton-label">Subscribe</span><span class="MuiTouchRipple-root"></span></button>
</div>
</div>
</li>
<li className="list1">
<div className="top_heading">
  <h4>Free</h4>
<p><del>$250</del></p>
  <h1>$0</h1>
  <p>Per month<br />
Billed quarterly</p>
  
</div>
<div className="bottom_text">
<p className="left_list6"><i class="fa-solid fa-circle-check"></i><span>Unlimited Internship <br />
Job Post</span></p>
<p>Unlimited</p>
<p>5</p>
<p className="left_list6"><i class="fa-solid fa-circle-check"></i><span>Full Features</span></p>
<p>1</p>
<div className="buton_name">
<button class="MuiButtonBase-root MuiButton-root MuiButton-text makeStyles-button-309 followBtn1" tabindex="0" type="button"><span class="MuiButton-label">Subscribe</span><span class="MuiTouchRipple-root"></span></button>
</div>
</div>
</li>             </ul>
                  </div>
							
                    </div>
								
	     </GridItem>

            </GridContainer>
                  </div>
                   </div>
                    </div>
                     </div>
      

	);
};

export default PaymentGateway;
