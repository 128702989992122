import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCandidateSelectJobPosts } from "configurations/redux/jobManagementSlice.js";
import { TimeFormat } from "configurations/configurations.js";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { Link } from "react-router-dom";

import Tooltip from "@material-ui/core/Tooltip";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Primary from "components/Typography/Primary.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";

import jobStatusPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/JobStatusPage/jobStatusPageStyle.js";
import Pagination from "../../Component/Pagination.js";
import ResultJobModal from "../SearchJobPage/ResultJobModal.js";
import WithdrawlModal from "./WithdrawlModal.js";
import NoRecordModal from "../../Component/NoRecordModal.js";

import defaultAvatar from "assets/img/corporate_avatar.jpeg";
import EditIcon from "@material-ui/icons/Edit";
import LoadingImage from "assets/img/Loading-pana.png";

import { Divider } from "@material-ui/core";
import {
  getAppliedJobPostList,
  getAppliedJobPost,
} from "configurations/redux/appliedJobSlice";
import Backdrop from "../../Component/Backdrop.js";
import { resetNotificationItem } from "configurations/redux/eventNotificationSlice";
import IconLibrary from "views/Component/IconLibrary.js";

const publicIp = require("public-ip");

const useStyles = makeStyles(jobStatusPageStyle);

export default function AppliedJobSection() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userStatus = useSelector((state) => state.auth.user);

  const appliedJobPostList = useSelector(
    (state) => state.appliedJob.jobPostList
  );
  const appliedJobPost = useSelector((state) => state.appliedJob.jobPost);

  const isLoading = useSelector((state) => state.appliedJob.isLoading);
  const isLoadingJob = useSelector((state) => state.appliedJob.isLoadingJob);

  const loadingAppliedJobList = useSelector(
    (state) => state.appliedJob.loadingJobList
  );

  const [currentPage, setCurrentPage] = React.useState(1);
  const [jobsPerPage, setJobsPerPage] = React.useState(20);
  const [clientIp, setClientIp] = React.useState();
  const [jobViewModal, setJobViewModal] = React.useState(false);
  const [withdrawModal, setWithdrawModal] = React.useState(false);
  const [noRecordModal, setNoRecordModal] = React.useState(false);

  const [jobPostId, setJobPostId] = React.useState();
  const [applId, setApplId] = React.useState();
  const notificationJobPostItem = useSelector(
    (state) => state.eventNotification.item
  );

  const onClose = () => {
    setJobPostId();
    setJobViewModal(false);
    setCurrentPage(currentPage);
    var indexOfFirstJob = currentPage * jobsPerPage - jobsPerPage;
    getJobPostList(indexOfFirstJob, false);
  };
  const noRecordOnClose = () => {
    setNoRecordModal(false);
  };

  const withdrawOnClose = () => {
    setApplId();
    setWithdrawModal(false);
  };

  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgProfile,
    classes.logoImage
  );
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = appliedJobPostList.slice(indexOfFirstJob, indexOfLastJob);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    var indexOfFirstJob = pageNumber * jobsPerPage - jobsPerPage;
    getJobPostList(indexOfFirstJob, true);
  };

  const getJobPost = async (item) => {
    console.log(item);
    if (item && item.jobPostStatusId === 1) {
      const requestProfile = {
        acctId: userStatus.acctId,
        accessToken: localStorage.getItem("token"),
        ipAddr: localStorage.getItem("ipAddr"), //await publicIp.v4(),
        jobPostId: item.jobPostId,
        item: item,
      };
      console.log(requestProfile);
      dispatch(getCandidateSelectJobPosts(requestProfile));
      setJobViewModal(true);
    } else {
      setNoRecordModal(true);
    }
  };

  const getJobPostList = async (indexOfFirstJob, scroll) => {
    const requestProfile = {
      acctId: userStatus.acctId,
      email: userStatus.email,
      accessToken: localStorage.getItem("token"),
      lastRecordSize: indexOfFirstJob,
      requestRecordSize: jobsPerPage,
      paginationClick: scroll,
    };
    dispatch(getAppliedJobPostList(requestProfile));
    if (scroll) window.scrollTo(0, 0);
  };

  React.useEffect(() => {
    if (notificationJobPostItem > 0) {
      console.log(notificationJobPostItem);
      const requestProfile = {
        acctId: userStatus.acctId,
        email: userStatus.email,
        accessToken: localStorage.getItem("token"),
        applId: notificationJobPostItem,
      };
      dispatch(getAppliedJobPost(requestProfile));
    }
  }, [notificationJobPostItem]);

  React.useEffect(() => {
    if (notificationJobPostItem > 0) {
      if (appliedJobPost) {
        getJobPost(appliedJobPost[0]);
        dispatch(resetNotificationItem());
      } else {
        setNoRecordModal(true);
        dispatch(resetNotificationItem());
      }
      pageResize();
    }
    pageResize();
  }, [appliedJobPost]);

  React.useEffect(() => {
    pageResize();
  }, [appliedJobPostList]);

  const pageResize = () => {
    var timerApplied = setInterval(() => {
      if (document.readyState === "complete") {
        var pageHeader = document.getElementById("pageHeader");
        var appliedCard = document.getElementById("appliedCard");
        if (pageHeader && appliedCard) {
          clearInterval(timerApplied);
          pageHeader.style.minHeight = "468px";
          if (appliedCard.clientHeight <= 450) {
            pageHeader.style.height = "468px";
          } else {
            pageHeader.style.minHeight = "1px";
            pageHeader.style.height = "auto";
          }
        } else {
          setTimeout(() => {
            clearInterval(timerApplied);
          }, 3000);
        }
      }
    }, 50);
    return () => clearInterval(timerApplied);
  };

  const AppliedJob = appliedJobPostList.map((item, index) => {
    var statusColor = classes.statusUnsuccessful;
    if (
      item.applStatusId === 1 ||
      item.applStatusId === 4 ||
      item.applStatusId === 7
    )
      statusColor = classes.statusSubmitted;
    else if (item.applStatusId === 2) statusColor = classes.statusReviewing;
    else if (item.applStatusId === 3) statusColor = classes.statusShortlisted;
    else if (item.applStatusId === 5) statusColor = classes.statusRejected;
    else if (item.applStatusId === 6) statusColor = classes.statusUnsuccessful;
    else if (item.applStatusId === 8) statusColor = classes.statusWithdraw;

    var jobCard = classes.cardClick + " " + classes.jobCard;
    if (appliedJobPostList) {
      if (appliedJobPostList[0].applId === item.applId) {
        jobCard =
          classes.cardClick +
          " " +
          classes.jobCard +
          " " +
          classes.jobCardFirst;
      } else if (
        appliedJobPostList[appliedJobPostList.length - 1].applId === item.applId
      ) {
        jobCard =
          classes.cardClick +
          " " +
          classes.jobCard +
          " " +
          classes.jobCardBorder +
          " " +
          classes.jobCardLast;
      } else {
        jobCard =
          classes.cardClick +
          " " +
          classes.jobCard +
          " " +
          classes.jobCardBorder;
      }
      if (appliedJobPostList.length === 1) {
        jobCard =
          classes.cardClick + " " + classes.jobCard + " " + classes.jobCardOnly;
      }
    }
    return (
      <Card
        // className={classes.cardClick + " " + classes.cardFrame}
        className={jobCard}
        key={item.applId}
      >
        <CardBody>
          <GridContainer>
            <GridItem>
              <GridContainer
                alignContent="center"
                alignItems="center"
                onClick={() => {
                  getJobPost(item);
                }}
              >
                <React.Fragment>
                  <GridItem xs={3} sm={2} md={1} className={classes.itemCenter}>
                    <img
                      // className={imageClasses}
                      className={classes.cardImage}
                      src={item.profilePic ? item.profilePic : defaultAvatar}
                      alt="..."
                    />
                  </GridItem>
                  <GridItem xs={7} sm={9} md={10}>
                    <GridContainer alignContent="center" alignItems="center">
                      <GridItem
                        xs={12}
                        sm={7}
                        md={8}
                        className={classes.gridItemMargin}
                      >
                        <h4 className={classes.jobTitle}>{item.title}</h4>
                        <h5
                          className={
                            classes.description + " " + classes.companyName
                          }
                        >
                          {item.companyName}
                        </h5>
                        <h5
                          className={
                            classes.description +
                            " " +
                            classes.applicationStatus +
                            " " +
                            statusColor
                          }
                        >
                          {item.applStatusName}
                        </h5>
                      </GridItem>
                      <GridItem
                        xs={12}
                        sm={5}
                        md={4}
                        className={
                          classes.gridItemMargin + " " + classes.rightGrid
                        }
                      >
                        <h5 className={classes.jobPostStatus}>
                          {item.jobPostType}{" "}
                          {item.jobPostTypeId === 2
                            ? " - " + item.jobTypeId
                            : null}
                        </h5>

                        <p className={classes.jobTime}>
                          Applied {TimeFormat(item.applDate)}
                        </p>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </React.Fragment>
                {item.applStatusId === 5 ||
                  item.applStatusId === 7 ||
                  item.applStatusId === 8 ||
                  item.jobPostStatusId === 2 ? (
                  <GridItem xs={2} sm={1} md={1}></GridItem> //Need Empty grid
                ) : (
                  <GridItem xs={2} sm={1} md={1} className={classes.itemCenter}>
                    <Tooltip
                      id="tooltip-top"
                      title="Withdraw Job Application"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Button
                        justIcon
                        style={{ boxShadow: "none" }}
                        color="transparent"
                        className={classes.buttonIconMargin}
                        onClick={(event) => {
                          event.stopPropagation();
                          setApplId(item.applId);
                          setWithdrawModal(true);
                        }}
                      >
                        <IconLibrary.Pen />
                      </Button>
                    </Tooltip>
                  </GridItem>
                )}
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );
  });

  const NoAppliedJobPost = () => {
    return (
      <Card className={classes.cardFrame}>
        <CardBody>
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={12} md={12} className={classes.itemCenter}>
              <h5 className={classes.title + " " + classes.titleWeight}>
                You haven't applied any jobs.
              </h5>
            </GridItem>
            <GridItem className={classes.itemCenter}>
              <Link
                className={classes.textDeco}
                to={{
                  pathname: "/search-job-page",
                }}
                color="transparent"
              >
                <Button round color="oppty">
                  <b>BROWSE JOB BOARD</b>
                </Button>
              </Link>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );
  };
  const LoadingJob = () => {
    return (
      <Card className={classes.cardFrame}>
        <CardBody>
          <img
            src={LoadingImage}
            className={classes.backGroundImage}
            alt="Expired Session"
          />
        </CardBody>
      </Card>
    );
  };

  return (
    <div id="appliedCard">
      <GridContainer>
        <GridItem>
          {appliedJobPostList.length > 0 ? (
            <React.Fragment>
              <Card className={classes.modalCardBorder}>
                <CardBody className={classes.modalCardPadding}>
                  {AppliedJob}
                </CardBody>
              </Card>
              <div className={classes.paginationAligned}>
                <Pagination
                  totalPosts={appliedJobPostList[0].jobCount}
                  postsPerPage={jobsPerPage}
                  paginate={paginate}
                />
              </div>
            </React.Fragment>
          ) : isLoading ? (
            <LoadingJob />
          ) : (
            <NoAppliedJobPost />
          )}
        </GridItem>
      </GridContainer>
      {isLoadingJob && <Backdrop />}
      {jobViewModal && (
        <ResultJobModal
          open={jobViewModal}
          onClose={onClose}
          jobPostId={jobPostId}
          clientIp={clientIp}
        />
      )}
      {noRecordModal && (
        <NoRecordModal
          open={noRecordModal}
          onClose={noRecordOnClose}
          recordType={"Job Post"}
        />
      )}
      {withdrawModal && (
        <WithdrawlModal
          open={withdrawModal}
          onClose={withdrawOnClose}
          applId={applId}
        />
      )}
    </div>
  );
}
