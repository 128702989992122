import React from "react";
import {
	getCountryId,
	getLocationId,
	getIndustryId,
	getJobPostType,
	getJobType,
	getLocationIdByCountry,
	getExperienceLevelList,
	getCurrencyId,
	getJobTypeIdByJobPostType,
	gaPageView,
} from "configurations/configurations.js";
import { useSelector, useDispatch } from "react-redux";
import { searchJobInfo } from "configurations/redux/searchJobSlice.js";
import { Formik } from "formik";
import { makeStyles } from "@material-ui/core/styles";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

import Autocomplete, {
	createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import StickyBox from "react-sticky-box";

//Page Components
import Header from "views/Component/Header.js";
import Footer from "views/Component/Footer.js";

import searchJobPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/SearchJobPage/searchJobPageStyle.js";
import SearchResultSection from "./SearchResultSection";
import { isDesktop } from "react-device-detect";
import Accordion from "components/Accordion/Accordion";
import { Hidden } from "@material-ui/core";
import { decryptData } from "configurations/configurations.js";
import { getCandidateSelectJobPosts } from "configurations/redux/jobManagementSlice.js";
import ResultJobModal from "./ResultJobModal.js";
import { useHistory } from "react-router-dom";
import CustomDialog from "../../../components/CustomDialog/CustomDialog.js";
import BottomNavBar from "components/BottomNavBar/BottomNavBar.js";

function useWindowSize() {
	const [size, setSize] = React.useState([0, 0]);
	React.useLayoutEffect(() => {
		function updateSize() {
			setSize([window.innerWidth, window.innerHeight]);
		}
		window.addEventListener("resize", updateSize);
		updateSize();
		return () => window.removeEventListener("resize", updateSize);
	}, []);
	return size;
}

const useStyles = makeStyles(searchJobPageStyle);

export default function SearchJobPage({ match }) {
	const stateJobPostId = match.params.jobPostId
		? decryptData(match.params.jobPostId)
		: "";
	// console.log(stateJobPostId);
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const userStatus = useSelector((state) => state.auth.user);
	const isJobListLoading = useSelector((state) => state.searchJob.isLoading);
	const [countryList, setCountryList] = React.useState([]);
	const [locationList, setLocationList] = React.useState([]);
	const [industryList, setIndustryList] = React.useState([]);
	const [jobPostTypeList, setJobPostType] = React.useState([]);
	const [jobTypeList, setJobType] = React.useState([]);
	const [openDialogFullScreen, setOpenDialogFullScreen] = React.useState(false);
	const [countryChange, setCountryChange] = React.useState();
	const [experienceLevelList, setExperienceLevelList] = React.useState([]);
	const [currencyList, setCurrencyList] = React.useState([]);
	const [jobPostTypeChange, setJobPostTypeChange] = React.useState(null);
	const [expanded, setExpanded] = React.useState("null");
	const [jobViewModal, setJobViewModal] = React.useState(false);
	const dialogDefault = {
		title: "Warning",
		description:
			"This job post is not available. Please try for other job post.",
		buttonYes: "",
		buttonNo: "OK",
	};
	const [confirmDialog, setConfirmDialog] = React.useState({
		isOpen: false,
		dialogDefault,
	});

	const [width, height] = useWindowSize();

	const onClose = () => {
		setJobViewModal(false);
	};

	const handleAccordionChange = (panel) => (event, newExpanded) => {
		console.log(newExpanded, panel);
		setExpanded(newExpanded ? panel : false);
	};

	const fetchCountryList = async () => {
		const res = await getCountryId();
		var list = [];
		list.push({ countryId: -1, countryName: "All" });
		for (var i = 0; i < res.data.payload.length; i++) {
			list.push({
				countryId: res.data.payload[i].countryId,
				countryName: res.data.payload[i].countryName,
			});
		}
		setCountryList(list);
	};
	const fetchExperienceLevelList = async () => {
		const res = await getExperienceLevelList();
		var list = [];
		list.push({ experienceId: -1, experience: "All" });
		for (var i = 0; i < res.data.payload.length; i++) {
			list.push({
				experienceId: res.data.payload[i].experienceId,
				experience: res.data.payload[i].experience,
			});
		}
		setExperienceLevelList(list);
	};
	// console.log(getCurrencyId());
	const fetchCurrencyList = async () => {
		const res = await getCurrencyId();

		var list = [];
		list.push({ currencyId: -1, currencyCode: "All" });
		for (var i = 0; i < res.data.payload.length; i++) {
			list.push({
				currencyId: res.data.payload[i].currencyId,
				currencyCode: res.data.payload[i].currencyCode,
			});
		}
		setCurrencyList(list);
	};
	// const fetchLocationList = async () => {
	// 	const res = await getLocationId();
	// 	setLocationList(res.data.payload);
	// };
	const fetchIndustryList = async () => {
		const res = await getIndustryId();
		var list = [];
		list.push({ industryId: -1, industryName: "All" });
		for (var i = 0; i < res.data.payload.length; i++) {
			list.push({
				industryId: res.data.payload[i].industryId,
				industryName: res.data.payload[i].industryName,
			});
		}
		setIndustryList(list);
	};
	const fetchJobPostTypeList = async () => {
		const res = await getJobPostType();
		var list = [];
		list.push({ jobPostTypeId: -1, jobPostType: "All" });
		for (var i = 0; i < res.data.payload.length; i++) {
			list.push({
				jobPostTypeId: res.data.payload[i].jobPostTypeId,
				jobPostType: res.data.payload[i].jobPostType,
			});
		}
		setJobPostType(list);
	};
	/* 	const fetchJobTypeList = async () => {
		const res = await getJobType();
		var list = [];
		list.push({ jobTypeId: 0, jobType: "All" });
		for (var i = 0; i < res.data.payload.length; i++) {
			list.push({
				jobTypeId: res.data.payload[i].jobTypeId,
				jobType: res.data.payload[i].jobType,
			});
		}
		setJobType(list);
	}; */
	React.useEffect(() => {
		gaPageView(window.location.pathname);
		fetchCountryList();
		// fetchLocationList();
		fetchIndustryList();
		fetchJobPostTypeList();
		/* 	fetchJobTypeList(); */
		fetchExperienceLevelList();
		fetchCurrencyList();
		const requestProfile = {
			acctId: userStatus.acctId,
			accessToken: localStorage.getItem("token"),
			email: userStatus.email,
			keyword: "",
			countryId: -1,
			locationId: -1,
			companyName: "",
			industryId: -1,
			hotJobFlag: 0,
			jobPostTypeId: 0,
			jobTypeId: 0,
			lastRecordSize: 0,
			experienceId: -1,
			currencyId: -1,
			salaryMin: -1,
			salaryMax: -1,
			requestRecordSize: 10,
			genericSearch: false,
		};
		dispatch(searchJobInfo(requestProfile));
		scrollToTop();
	}, []);

	const scrollToTop = () => {
		var timer = setInterval(() => {
			var jobPostResult = document.getElementById("jobPostResult");
			if (jobPostResult) {
				jobPostResult.scrollIntoView();
				window.scrollTo(0, 0);
				clearInterval(timer);
			}
		}, 100);
	};

	React.useEffect(() => {
		// console.log(countryChange);
		if (countryChange) {
			const fetchLocationListByCountry = async (countryId) => {
				const res = await getLocationIdByCountry(countryId);
				var list = [];
				list.push({ locationId: -1, locationName: "All" });
				for (var i = 0; i < res.data.payload.length; i++) {
					list.push({
						locationId: res.data.payload[i].locationId,
						locationName: res.data.payload[i].locationName,
					});
				}
				setLocationList(list);
			};
			fetchLocationListByCountry(countryChange);
		}
	}, [countryChange]);
	React.useEffect(() => {
		if (jobPostTypeChange !== null) {
			const fetchJobTypeByJobPostType = async (jobPostTypeId) => {
				const res = await getJobTypeIdByJobPostType(jobPostTypeId);
				var list = [];
				list.push({ jobTypeId: -1, jobType: "All" });
				for (var i = 0; i < res.data.payload.length; i++) {
					list.push({
						jobTypeId: res.data.payload[i].jobTypeId,
						jobType: res.data.payload[i].jobType,
					});
				}
				setJobType(list);
			};
			fetchJobTypeByJobPostType(jobPostTypeChange);
		}
	}, [jobPostTypeChange]);

	React.useEffect(() => {
		if (stateJobPostId !== "") {
			var timer = setInterval(() => {
				if (!isJobListLoading) {
					const requestProfile = {
						acctId: userStatus.acctId,
						accessToken: localStorage.getItem("token"),
						ipAddr: localStorage.getItem("ipAddr"), //await publicIp.v4(),
						jobPostId: stateJobPostId,
						item: null,
					};
					console.log(requestProfile);
					dispatch(getCandidateSelectJobPosts(requestProfile));
					setJobViewModal(true);
					clearInterval(timer);
				}
			}, 300);
		} else if (match.params.jobPostId) {
			setConfirmDialog({
				isOpen: true,
				dialogDefault,
				onCancel: () => {
					setConfirmDialog({
						isOpen: false,
						dialogDefault,
					});
				},
			});
		}
		// history.replace(match.url.replace("/" + match.params.jobPostId, ""));
	}, [stateJobPostId]);

	const modalResize = () => {
		var timerApplied = setInterval(() => {
			var header = document.getElementById("searchJob");
			var headerCard = document.getElementById("searchJobCard");
			// console.log(window.innerHeight, window.innerWidth, header.clientHeight);
			if (header && headerCard) {
				clearInterval(timerApplied);
				if (window.innerWidth >= 960) {
					header.style.height = "auto";
					if (window.innerHeight < header.clientHeight + 180) {
						header.style.height = window.innerHeight - 180 + "px";
						header.style.paddingRight = "5px";
						headerCard.style.paddingRight = "0";
					} else {
						header.style.height = "auto";
						header.style.paddingRight = "1px";
						headerCard.style.paddingRight = "15px";
					}
				} else {
					header.style.height = "auto";
					header.style.paddingRight = "5px";
					headerCard.style.paddingRight = "15px";
				}
				// } else {
				// 	setTimeout(() => {
				// 		clearInterval(timerApplied);
				// 	}, 3000);
			}
		}, 50);
		return () => clearInterval(timerApplied);
	};

	// React.useEffect(() => {
	// 	modalResize();
	// }, []);

	React.useEffect(() => {
		if (document.readyState === "complete") modalResize();
		window.addEventListener("resize", modalResize);
		return () => {
			window.removeEventListener("resize", modalResize);
		};
	}, []);

	// React.useEffect(() => {
	// 	window.addEventListener("resize", modalResize);
	// });

	return (
		<React.Fragment>
			<div className={classes.bodyDiv}>
				<Header />
				<div className={classes.container}>
					<GridContainer justifyContent="center">
						<GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
							<Card className={classes.searchCard}>
								<CardBody className={classes.searchCardBody} id="searchJobCard">
									<div id="searchJob" className={classes.searchCardDiv}>
										<Hidden mdUp>
											<Accordion
												name={"accordion"}
												expanded={expanded}
												onChange={handleAccordionChange("accordion")}
												className={classes.accordionStyle}
												rootClass={classes.accordionTitle}
												activeColor="primary"
												collapses={[
													{
														title: (
															<GridContainer
																justifyContent="center"
																alignContent="center"
																alignItems="center"
																className={classes.accordionGrid}
															>
																<GridItem>
																	<h3
																		className={
																			classes.title + " " + classes.textCenter
																		}
																	>
																		Search Jobs
																	</h3>
																</GridItem>
															</GridContainer>
														),
														content: (
															<Formik
																initialValues={{
																	countryId: "",
																	locationId: "",
																	industryId: "",
																	keyword: "",
																	companyName: "",
																	jobPostTypeId: "",
																	jobTypeId: "",
																	hotJobFlag: 0,
																	experienceId: "",
																	currencyId: "",
																	salaryMin: null,
																	salaryMax: null,
																}}
																onSubmit={(data) => {
																	const requestProfile = {
																		acctId: userStatus.acctId,
																		accessToken: localStorage.getItem("token"),
																		email: userStatus.email,
																		keyword: data.keyword,
																		countryId: data.countryId
																			? data.countryId
																			: -1,
																		locationId: data.locationId
																			? data.locationId
																			: -1,
																		companyName: data.companyName,
																		industryId: data.industryId
																			? data.industryId
																			: -1,
																		hotJobFlag: 0, //data.hotJobFlag,
																		jobPostTypeId: data.jobPostTypeId
																			? data.jobPostTypeId
																			: 0,
																		/* jobTypeId:
														data.jobPostTypeId === 2
															? data.jobTypeId
																? data.jobTypeId
																: 0
															: 0, */
																		jobTypeId: data.jobTypeId,

																		lastRecordSize: 0,
																		requestRecordSize: 8,
																		salaryMin: data.salaryMin
																			? Number(data.salaryMin)
																			: -1,
																		salaryMax: data.salaryMax
																			? Number(data.salaryMax)
																			: -1,
																		experienceId: data.experienceId
																			? data.experienceId
																			: -1,
																		currencyId: data.currencyId
																			? data.currencyId
																			: -1,
																		genericSearch: false,
																	};
																	console.log(requestProfile);
																	dispatch(searchJobInfo(requestProfile));
																	scrollToTop();
																	setExpanded("null");
																	/* if (isMobile) {
								setOpenDialogFullScreen(true);
							} */
																}}
															>
																{({
																	values,
																	handleChange,
																	handleSubmit,
																	setFieldValue,
																}) => (
																	<form onSubmit={handleSubmit}>
																		<GridContainer>
																			<GridItem
																				xs={12}
																				sm={12}
																				md={12}
																				className={classes.autoCompleteSelect}
																			>
																				<TextField
																					fullWidth
																					label={"Keyword"}
																					id="keyword"
																					name="keyword"
																					variant="outlined"
																					values={values.keyword}
																					onChange={handleChange}
																				/>
																			</GridItem>
																			<GridItem
																				xs={12}
																				sm={12}
																				md={12}
																				className={classes.autoCompleteSelect}
																			>
																				<Autocomplete
																					options={countryList}
																					filterOptions={createFilterOptions({
																						matchFrom: "start",
																						stringify: (option) =>
																							option.countryName,
																					})}
																					// freeSolo
																					value={
																						countryList.filter((item) => {
																							return (
																								item.countryId ===
																								values.countryId
																							);
																						})[0] || null
																					}
																					getOptionSelected={(
																						option,
																						value
																					) => {
																						if (!value) {
																							return false;
																						}
																						return (
																							option.countryId ===
																							value.countryId
																						);
																					}}
																					getOptionLabel={(option) =>
																						option.countryName
																							? option.countryName
																							: ""
																					}
																					onChange={(event, value) => {
																						if (value) {
																							setFieldValue(
																								"countryId",
																								value.countryId
																							);
																							setCountryChange(value.countryId);
																						} else {
																							setFieldValue("countryId", "");
																							setFieldValue("locationId", "");
																						}
																					}}
																					id="countryId"
																					name="countryId"
																					renderInput={(params) => (
																						<TextField
																							{...params}
																							label={"Country"}
																							variant="outlined"
																						/>
																					)}
																				/>
																			</GridItem>
																			<GridItem
																				xs={12}
																				sm={12}
																				md={12}
																				className={classes.autoCompleteSelect}
																			>
																				<Autocomplete
																					options={locationList}
																					// freeSolo
																					disabled={!values.countryId}
																					value={
																						locationList.filter((item) => {
																							return (
																								item.locationId ===
																								values.locationId
																							);
																						})[0] || null
																					}
																					getOptionSelected={(option, value) =>
																						option.locationid ===
																						value.locationId
																					}
																					getOptionLabel={(option) =>
																						option.locationName
																							? option.locationName
																							: ""
																					}
																					onChange={(event, value) => {
																						if (value) {
																							setFieldValue(
																								"locationId",
																								value.locationId
																							);
																						} else {
																							setFieldValue("locationId", "");
																						}
																					}}
																					id="locationId"
																					name="locationId"
																					renderInput={(params) => (
																						<TextField
																							{...params}
																							label={"Location"}
																							variant="outlined"
																						/>
																					)}
																				/>
																			</GridItem>
																			<GridItem
																				xs={12}
																				sm={12}
																				md={12}
																				className={classes.autoCompleteSelect}
																			>
																				<Autocomplete
																					options={industryList}
																					//onBlur={handleBlur}
																					// freeSolo
																					value={
																						industryList.filter((item) => {
																							return (
																								item.industryId ===
																								values.industryId
																							);
																						})[0] || null
																					}
																					getOptionLabel={(option) =>
																						option.industryName
																							? option.industryName
																							: ""
																					}
																					getOptionSelected={(
																						option,
																						value
																					) => {
																						if (
																							option.industryId ===
																							value.industryId
																						) {
																							//console.log(option, value);
																							return true;
																						} else {
																							return false;
																						}
																					}}
																					onChange={(event, value) => {
																						if (value) {
																							setFieldValue(
																								"industryId",
																								value.industryId
																							);
																						} else {
																							setFieldValue("industryId", "");
																						}
																					}}
																					id="industryId"
																					name="industryId"
																					renderInput={(params) => (
																						<TextField
																							{...params}
																							label={"Company Industry"}
																							variant="outlined"
																						/>
																					)}
																				/>
																			</GridItem>
																			<GridItem
																				xs={12}
																				sm={12}
																				md={12}
																				className={classes.autoCompleteSelect}
																			>
																				<Autocomplete
																					options={jobPostTypeList}
																					// freeSolo
																					value={
																						jobPostTypeList.filter((item) => {
																							return (
																								item.jobPostTypeId ===
																								values.jobPostTypeId
																							);
																						})[0] || null
																					}
																					getOptionLabel={(option) =>
																						option.jobPostType
																							? option.jobPostType
																							: ""
																					}
																					getOptionSelected={(
																						option,
																						value
																					) => {
																						if (
																							option.jobPostTypeId ===
																							value.jobPostTypeId
																						) {
																							//console.log(option, value);
																							return true;
																						} else {
																							return false;
																						}
																					}}
																					onChange={(event, value) => {
																						if (value) {
																							setFieldValue(
																								"jobPostTypeId",
																								value.jobPostTypeId
																							);
																							setJobPostTypeChange(
																								value.jobPostTypeId
																							);
																							setFieldValue("jobTypeId", "");
																						} else {
																							setFieldValue(
																								"jobPostTypeId",
																								""
																							);
																							setFieldValue("jobTypeId", "");
																						}
																					}}
																					id="jobPostTypeId"
																					name="jobPostTypeId"
																					renderInput={(params) => (
																						<TextField
																							{...params}
																							label={"Job Post Type"}
																							variant="outlined"
																						/>
																					)}
																				/>
																			</GridItem>
																			{(values.jobPostTypeId === 1 ||
																				values.jobPostTypeId === 2) && (
																				<GridItem
																					xs={12}
																					sm={12}
																					md={12}
																					className={classes.autoCompleteSelect}
																				>
																					<Autocomplete
																						options={jobTypeList}
																						// freeSolo
																						value={
																							jobTypeList.filter((item) => {
																								return (
																									item.jobTypeId ===
																									values.jobTypeId
																								);
																							})[0] || null
																						}
																						getOptionLabel={(option) =>
																							option.jobType
																								? option.jobType
																								: ""
																						}
																						getOptionSelected={(
																							option,
																							value
																						) => {
																							if (
																								option.jobTypeId ===
																								value.jobTypeId
																							) {
																								//console.log(option, value);
																								return true;
																							} else {
																								return false;
																							}
																						}}
																						onChange={(event, value) => {
																							if (value) {
																								setFieldValue(
																									"jobTypeId",
																									value.jobTypeId
																								);
																							} else {
																								setFieldValue("jobTypeId", "");
																							}
																						}}
																						id="jobTypeId"
																						name="jobTypeId"
																						renderInput={(params) => (
																							<TextField
																								{...params}
																								label={"Employment Type"}
																								variant="outlined"
																							/>
																						)}
																					/>
																				</GridItem>
																			)}

																			<GridItem
																				xs={12}
																				sm={12}
																				md={12}
																				className={classes.autoCompleteSelect}
																			>
																				<Autocomplete
																					options={experienceLevelList}
																					// freeSolo
																					value={
																						experienceLevelList.filter(
																							(item) => {
																								return (
																									item.experienceId ===
																									values.experienceId
																								);
																							}
																						)[0] || null
																					}
																					getOptionLabel={(option) =>
																						option.experience
																							? option.experience
																							: ""
																					}
																					getOptionSelected={(
																						option,
																						value
																					) => {
																						if (
																							option.experienceId ===
																							value.experienceId
																						) {
																							//console.log(option, value);
																							return true;
																						} else {
																							return false;
																						}
																					}}
																					onChange={(event, value) => {
																						if (value) {
																							setFieldValue(
																								"experienceId",
																								value.experienceId
																							);
																						} else {
																							setFieldValue("experienceId", "");
																						}
																					}}
																					renderInput={(params) => (
																						<TextField
																							{...params}
																							label={"Experience Level"}
																							variant="outlined"
																						/>
																					)}
																				/>
																			</GridItem>
																			<GridItem
																				xs={12}
																				sm={6}
																				md={6}
																				className={classes.autoCompleteSelect}
																			>
																				<TextField
																					fullWidth
																					label={"Minimum Salary"}
																					id="salaryMin"
																					name="salaryMin"
																					variant="outlined"
																					values={values.salaryMin}
																					onChange={handleChange}
																					InputLabelProps={{
																						shrink: true,
																					}}
																				/>
																			</GridItem>
																			<GridItem
																				xs={12}
																				sm={6}
																				md={6}
																				className={classes.autoCompleteSelect}
																			>
																				<TextField
																					fullWidth
																					label={"Maximum Salary"}
																					id="salaryMax"
																					name="salaryMax"
																					variant="outlined"
																					values={values.salaryMax}
																					onChange={handleChange}
																					InputLabelProps={{
																						shrink: true,
																					}}
																					/* style={{
																	textOverflow: "ellipsis",
																	overflow: "hidden",
																	whiteSpace: "nowrap",
																}} */
																				/>
																			</GridItem>
																			<GridItem
																				className={classes.autoCompleteSelect}
																			>
																				<Autocomplete
																					options={currencyList}
																					// freeSolo
																					value={
																						currencyList.filter((item) => {
																							return (
																								item.currencyId ===
																								values.currencyId
																							);
																						})[0] || null
																					}
																					getOptionLabel={(option) =>
																						option.currencyCode
																							? option.currencyCode
																							: ""
																					}
																					getOptionSelected={(
																						option,
																						value
																					) => {
																						if (
																							option.currencyId ===
																							value.currencyId
																						) {
																							//console.log(option, value);
																							return true;
																						} else {
																							return false;
																						}
																					}}
																					onChange={(event, value) => {
																						if (value) {
																							setFieldValue(
																								"currencyId",
																								value.currencyId
																							);
																						} else {
																							setFieldValue("currencyId", "");
																						}
																					}}
																					renderInput={(params) => (
																						<TextField
																							{...params}
																							label={"Currency"}
																							variant="outlined"
																						/>
																					)}
																				/>
																			</GridItem>
																		</GridContainer>

																		<GridContainer justifyContent="center">
																			<GridItem
																				xs={12}
																				sm={12}
																				md={12}
																				className={classes.searchBarButton}
																			>
																				<Button
																					onClick={handleSubmit}
																					color="oppty"
																					fullWidth
																					round
																				>
																					<b>Search</b>
																				</Button>
																			</GridItem>
																		</GridContainer>
																	</form>
																)}
															</Formik>
														),
													},
												]}
											/>
										</Hidden>
										<Hidden smDown>
											<h3 className={classes.title + " " + classes.textCenter}>
												Search Jobs
											</h3>

											<Formik
												initialValues={{
													countryId: "",
													locationId: "",
													industryId: "",
													keyword: "",
													companyName: "",
													jobPostTypeId: "",
													jobTypeId: "",
													hotJobFlag: 0,
													experienceId: "",
													currencyId: "",
													salaryMin: null,
													salaryMax: null,
												}}
												onSubmit={(data) => {
													const requestProfile = {
														acctId: userStatus.acctId,
														accessToken: localStorage.getItem("token"),
														email: userStatus.email,
														keyword: data.keyword,
														countryId: data.countryId ? data.countryId : -1,
														locationId: data.locationId ? data.locationId : -1,
														companyName: data.companyName,
														industryId: data.industryId ? data.industryId : -1,
														hotJobFlag: 0, //data.hotJobFlag,
														jobPostTypeId: data.jobPostTypeId
															? data.jobPostTypeId
															: 0,
														/* jobTypeId:
														data.jobPostTypeId === 2
															? data.jobTypeId
																? data.jobTypeId
																: 0
															: 0, */
														jobTypeId: data.jobTypeId,

														lastRecordSize: 0,
														requestRecordSize: 8,
														salaryMin: data.salaryMin
															? Number(data.salaryMin)
															: -1,
														salaryMax: data.salaryMax
															? Number(data.salaryMax)
															: -1,
														experienceId: data.experienceId
															? data.experienceId
															: -1,
														currencyId: data.currencyId ? data.currencyId : -1,
														genericSearch: false,
													};
													console.log(requestProfile);
													dispatch(searchJobInfo(requestProfile));
													scrollToTop();
													/* if (isMobile) {
								setOpenDialogFullScreen(true);
							} */
												}}
											>
												{({
													values,
													handleChange,
													handleSubmit,
													setFieldValue,
												}) => (
													<form onSubmit={handleSubmit}>
														<GridContainer>
															<GridItem
																xs={12}
																sm={12}
																md={12}
																className={classes.autoCompleteSelect}
															>
																<TextField
																	fullWidth
																	label={"Keyword"}
																	id="keyword"
																	name="keyword"
																	variant="outlined"
																	values={values.keyword}
																	onChange={handleChange}
																/>
															</GridItem>
															<GridItem
																xs={12}
																sm={12}
																md={12}
																className={classes.autoCompleteSelect}
															>
																<Autocomplete
																	options={countryList}
																	filterOptions={createFilterOptions({
																		matchFrom: "start",
																		stringify: (option) => option.countryName,
																	})}
																	// freeSolo
																	value={
																		countryList.filter((item) => {
																			return (
																				item.countryId === values.countryId
																			);
																		})[0] || null
																	}
																	getOptionSelected={(option, value) => {
																		if (!value) {
																			return false;
																		}
																		return option.countryId === value.countryId;
																	}}
																	getOptionLabel={(option) =>
																		option.countryName ? option.countryName : ""
																	}
																	onChange={(event, value) => {
																		if (value) {
																			setFieldValue(
																				"countryId",
																				value.countryId
																			);
																			setCountryChange(value.countryId);
																		} else {
																			setFieldValue("countryId", "");
																			setFieldValue("locationId", "");
																		}
																	}}
																	id="countryId"
																	name="countryId"
																	renderInput={(params) => (
																		<TextField
																			{...params}
																			label={"Country"}
																			variant="outlined"
																		/>
																	)}
																/>
															</GridItem>
															<GridItem
																xs={12}
																sm={12}
																md={12}
																className={classes.autoCompleteSelect}
															>
																<Autocomplete
																	options={locationList}
																	// freeSolo
																	disabled={!values.countryId}
																	value={
																		locationList.filter((item) => {
																			return (
																				item.locationId === values.locationId
																			);
																		})[0] || null
																	}
																	getOptionSelected={(option, value) =>
																		option.locationid === value.locationId
																	}
																	getOptionLabel={(option) =>
																		option.locationName
																			? option.locationName
																			: ""
																	}
																	onChange={(event, value) => {
																		if (value) {
																			setFieldValue(
																				"locationId",
																				value.locationId
																			);
																		} else {
																			setFieldValue("locationId", "");
																		}
																	}}
																	id="locationId"
																	name="locationId"
																	renderInput={(params) => (
																		<TextField
																			{...params}
																			label={"Location"}
																			variant="outlined"
																		/>
																	)}
																/>
															</GridItem>
															<GridItem
																xs={12}
																sm={12}
																md={12}
																className={classes.autoCompleteSelect}
															>
																<Autocomplete
																	options={industryList}
																	//onBlur={handleBlur}
																	// freeSolo
																	value={
																		industryList.filter((item) => {
																			return (
																				item.industryId === values.industryId
																			);
																		})[0] || null
																	}
																	getOptionLabel={(option) =>
																		option.industryName
																			? option.industryName
																			: ""
																	}
																	getOptionSelected={(option, value) => {
																		if (
																			option.industryId === value.industryId
																		) {
																			//console.log(option, value);
																			return true;
																		} else {
																			return false;
																		}
																	}}
																	onChange={(event, value) => {
																		if (value) {
																			setFieldValue(
																				"industryId",
																				value.industryId
																			);
																		} else {
																			setFieldValue("industryId", "");
																		}
																	}}
																	id="industryId"
																	name="industryId"
																	renderInput={(params) => (
																		<TextField
																			{...params}
																			label={"Company Industry"}
																			variant="outlined"
																		/>
																	)}
																/>
															</GridItem>
															<GridItem
																xs={12}
																sm={12}
																md={12}
																className={classes.autoCompleteSelect}
															>
																<Autocomplete
																	options={jobPostTypeList}
																	// freeSolo
																	value={
																		jobPostTypeList.filter((item) => {
																			return (
																				item.jobPostTypeId ===
																				values.jobPostTypeId
																			);
																		})[0] || null
																	}
																	getOptionLabel={(option) =>
																		option.jobPostType ? option.jobPostType : ""
																	}
																	getOptionSelected={(option, value) => {
																		if (
																			option.jobPostTypeId ===
																			value.jobPostTypeId
																		) {
																			//console.log(option, value);
																			return true;
																		} else {
																			return false;
																		}
																	}}
																	onChange={(event, value) => {
																		if (value) {
																			setFieldValue(
																				"jobPostTypeId",
																				value.jobPostTypeId
																			);
																			setJobPostTypeChange(value.jobPostTypeId);
																			setFieldValue("jobTypeId", "");
																		} else {
																			setFieldValue("jobPostTypeId", "");
																			setFieldValue("jobTypeId", "");
																		}
																	}}
																	id="jobPostTypeId"
																	name="jobPostTypeId"
																	renderInput={(params) => (
																		<TextField
																			{...params}
																			label={"Job Post Type"}
																			variant="outlined"
																		/>
																	)}
																/>
															</GridItem>
															{(values.jobPostTypeId === 1 ||
																values.jobPostTypeId === 2) && (
																<GridItem
																	xs={12}
																	sm={12}
																	md={12}
																	className={classes.autoCompleteSelect}
																>
																	<Autocomplete
																		options={jobTypeList}
																		// freeSolo
																		value={
																			jobTypeList.filter((item) => {
																				return (
																					item.jobTypeId === values.jobTypeId
																				);
																			})[0] || null
																		}
																		getOptionLabel={(option) =>
																			option.jobType ? option.jobType : ""
																		}
																		getOptionSelected={(option, value) => {
																			if (
																				option.jobTypeId === value.jobTypeId
																			) {
																				//console.log(option, value);
																				return true;
																			} else {
																				return false;
																			}
																		}}
																		onChange={(event, value) => {
																			if (value) {
																				setFieldValue(
																					"jobTypeId",
																					value.jobTypeId
																				);
																			} else {
																				setFieldValue("jobTypeId", "");
																			}
																		}}
																		id="jobTypeId"
																		name="jobTypeId"
																		renderInput={(params) => (
																			<TextField
																				{...params}
																				label={"Employment Type"}
																				variant="outlined"
																			/>
																		)}
																	/>
																</GridItem>
															)}

															<GridItem
																xs={12}
																sm={12}
																md={12}
																className={classes.autoCompleteSelect}
															>
																<Autocomplete
																	options={experienceLevelList}
																	// freeSolo
																	value={
																		experienceLevelList.filter((item) => {
																			return (
																				item.experienceId ===
																				values.experienceId
																			);
																		})[0] || null
																	}
																	getOptionLabel={(option) =>
																		option.experience ? option.experience : ""
																	}
																	getOptionSelected={(option, value) => {
																		if (
																			option.experienceId === value.experienceId
																		) {
																			//console.log(option, value);
																			return true;
																		} else {
																			return false;
																		}
																	}}
																	onChange={(event, value) => {
																		if (value) {
																			setFieldValue(
																				"experienceId",
																				value.experienceId
																			);
																		} else {
																			setFieldValue("experienceId", "");
																		}
																	}}
																	renderInput={(params) => (
																		<TextField
																			{...params}
																			label={"Experience Level"}
																			variant="outlined"
																		/>
																	)}
																/>
															</GridItem>
															<GridItem
																xs={12}
																sm={6}
																md={6}
																className={classes.autoCompleteSelect}
															>
																<TextField
																	fullWidth
																	label={"Minimum Salary"}
																	id="salaryMin"
																	name="salaryMin"
																	variant="outlined"
																	values={values.salaryMin}
																	onChange={handleChange}
																	InputLabelProps={{
																		shrink: true,
																	}}
																/>
															</GridItem>
															<GridItem
																xs={12}
																sm={6}
																md={6}
																className={classes.autoCompleteSelect}
															>
																<TextField
																	fullWidth
																	label={"Maximum Salary"}
																	id="salaryMax"
																	name="salaryMax"
																	variant="outlined"
																	values={values.salaryMax}
																	onChange={handleChange}
																	InputLabelProps={{
																		shrink: true,
																	}}
																	/* style={{
																	textOverflow: "ellipsis",
																	overflow: "hidden",
																	whiteSpace: "nowrap",
																}} */
																/>
															</GridItem>
															<GridItem className={classes.autoCompleteSelect}>
																<Autocomplete
																	options={currencyList}
																	// freeSolo
																	value={
																		currencyList.filter((item) => {
																			return (
																				item.currencyId === values.currencyId
																			);
																		})[0] || null
																	}
																	getOptionLabel={(option) =>
																		option.currencyCode
																			? option.currencyCode
																			: ""
																	}
																	getOptionSelected={(option, value) => {
																		if (
																			option.currencyId === value.currencyId
																		) {
																			//console.log(option, value);
																			return true;
																		} else {
																			return false;
																		}
																	}}
																	onChange={(event, value) => {
																		if (value) {
																			setFieldValue(
																				"currencyId",
																				value.currencyId
																			);
																		} else {
																			setFieldValue("currencyId", "");
																		}
																	}}
																	renderInput={(params) => (
																		<TextField
																			{...params}
																			label={"Currency"}
																			variant="outlined"
																		/>
																	)}
																/>
															</GridItem>
														</GridContainer>

														<GridContainer justifyContent="center">
															<GridItem
																xs={12}
																sm={12}
																md={12}
																className={classes.searchBarButton}
															>
																<Button
																	onClick={handleSubmit}
																	color="oppty"
																	fullWidth
																	round
																>
																	<b>Search</b>
																</Button>
															</GridItem>
														</GridContainer>
													</form>
												)}
											</Formik>
										</Hidden>
									</div>
								</CardBody>
							</Card>
						</GridItem>
						<GridItem
							xs={12}
							sm={12}
							md={8}
							lg={8}
							xl={8}
							className={classes.searchGrid}
						>
							<SearchResultSection size={60} thickness={6} />
						</GridItem>
					</GridContainer>
				</div>
			</div>{" "}
			{jobViewModal ? (
				<ResultJobModal open={jobViewModal} onClose={onClose} />
			) : null}
			<CustomDialog
				confirmDialog={confirmDialog}
				setConfirmDialog={setConfirmDialog}
			/>
			{width > 1200 ? (
				<Footer />
			) : (
				<div className={classes.footerNav}>
					<BottomNavBar />
				</div>
			)}
		</React.Fragment>
	);
}
