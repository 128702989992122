import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import "quill-mention";
import "react-quill/dist/quill.bubble.css";
import ReactQuill, { Quill } from "react-quill";
import MagicUrl from "quill-magic-url";
import commentStyle from "../../../assets/jss/material-kit-pro-react/views/componentStyle/socialFeed/commentStyles";
import { makeStyles } from "@material-ui/core";
import {
	getSocialHashtag,
	getSocialProfileTag,
	createSocialHashtag,
	resetCreatedHashtag,
	getSocialProfileTagByGroupId,
} from "configurations/redux/socialFeedSlice.js";
import defaultAvatar from "assets/img/candidate_avatar.jpeg";
import { debounce } from "lodash";

const CommentInput = ({
	comment,
	emojiChoose,
	resetEmoji,
	getLength,
	getText,
	getHTML,
	getContent,
	onSubmit,
	submitCommentId,
	parentId,
	quillRefPush,
}) => {
	const quillRef = React.createRef(null);
	const classes = makeStyles(commentStyle)();
	const dispatch = useDispatch();
	const hashtagList = useSelector((state) => state.socialFeed.hashtagList);
	const hashtagKeyword = useSelector(
		(state) => state.socialFeed.hashtagKeyword
	);
	const profileTagList = useSelector(
		(state) => state.socialFeed.profileTagList
	);
	const profileTagKeyword = useSelector(
		(state) => state.socialFeed.profileTagKeyword
	);
	const hashValue = useSelector((state) => state.socialFeed.hashValue);
	const createHashtagCommentId = useSelector(
		(state) => state.socialFeed.createHashtagCommentId
	);
	const createHashtagPostId = useSelector(
		(state) => state.socialFeed.createHashtagPostId
	);
	const enterKey = useSelector((state) => state.socialFeed.enterKey);

	const user = useSelector((state) => state.auth.user);
	const groupId = useSelector((state) => state.socialFeed.groupId);

	const mentionModuleConfig = {
		allowedChars: /^[A-Za-z0-9\sÅÄÖåäö]*$/,
		mentionDenotationChars: ["@", "#"],
		source: function (searchTerm, renderList, mentionChar) {
			console.log(searchTerm);

			if (mentionChar === "@") {
				renderList(profileTagList);
			} else {
				renderList(hashtagList);
			}

			if (
				profileTagKeyword != searchTerm &&
				mentionChar != "" &&
				mentionChar === "@"
			) {
				getProfileTagDebounce(searchTerm);
			} else if (
				hashtagKeyword != searchTerm &&
				mentionChar != "" &&
				mentionChar === "#"
			) {
				getHashtagDebounce(searchTerm);
				if (searchTerm.slice(-1) === " ") {
					var newHashTag = searchTerm.trim();
					// console.log(newHashTag);
					storeNewHashTag(newHashTag);
				}
			}
		},
		renderItem: function (item, searchTerm) {
			console.log(item.value);
			return `<div>
						${
							item.mentionChar == "@"
								? `<img
									style="width:40px;border-radius:50%;border:1px solid"
									src=${item.profilePic ? item.profilePic : defaultAvatar}
									alt=""/>
									<span style="margin-left:5px">${item.value}</span>`
								: `<span>${item.value}</span>`
						}
					</div>`;
		},
	};

	const getProfileTagDebounce = debounce((searchTerm) => {
		// console.log(searchTerm);
		getProfileTagList(searchTerm);
	}, 200);

	const getHashtagDebounce = debounce((searchTerm) => {
		// console.log(searchTerm);
		getHashTagList(searchTerm);
	}, 200);

	const modules = {
		mention: mentionModuleConfig,
		magicUrl: true,
		clipboard: {
			matchers: [
				[
					Node.ELEMENT_NODE,
					(node, delta) => {
						let ops = [];
						delta.ops.forEach((op) => {
							if (op.insert && typeof op.insert === "string") {
								ops.push({
									insert: op.insert.trim(),
								});
							}
						});
						delta.ops = ops;
						return delta;
					},
				],
			],
		},
	};

	useEffect(() => {
		// console.log(hashValue, createHashtagCommentId, comment);
		var commentId = comment ? comment.commentId : 0;
		if (createHashtagCommentId == commentId) {
			if (hashValue) {
				resetCreatedHashtag();
			}
			var quillEditor = null;
			var quillContent = null;
			quillEditor = quillRef.current;
			quillContent = quillRef.current.editor.getContents();
			if (quillEditor) {
				for (let i = 0; i < quillContent.ops.length; i++) {
					let insert = quillContent.ops[i].insert;
					try {
						console.log(insert.mention.denotationChar);
					} catch (e) {
						if (insert) {
							let hashtag = insert.replace("\n", "");
							if (hashtag.charAt(0) == " ") hashtag = hashtag.substring(1);
							var splitHash = hashtag.split(" ");
							console.log(splitHash);
							for (var j = 0; j < splitHash.length; j++) {
								if (splitHash[j].charAt(0) == "#") {
									var newEditor = quillEditor.getEditor();
									var unprivilegedEditor = quillEditor.makeUnprivilegedEditor(
										newEditor
									);

									let newHashtag =
										'<span class="mention" data-index="0" data-denotation-char="#" data-id="' +
										hashValue.id +
										'" data-value="<a href=&quot;' +
										hashValue.link +
										"&quot; target=_blank>" +
										hashValue.value +
										'" data-link="' +
										hashValue.link +
										'"><span contenteditable="false"><span class="ql-mention-denotation-char">#</span><a href="' +
										hashValue.link +
										'" target="_blank">' +
										hashValue.value +
										"</a></span></span>";
									var newDescription = unprivilegedEditor
										.getHTML()
										.replace(splitHash[j].replace("\n", ""), newHashtag);
									console.log(newDescription);
									let newQuill = new Quill("#quillEditor");
									let initialContent = newQuill.clipboard.convert(
										newDescription
									);
									quillEditor.editor.setContents(initialContent, "silent");
									quillEditor.editor.focus();
									quillEditor.editor.insertText(
										quillEditor.editor.getLength() - 1,
										enterKey ? "\n" : " "
									);
									quillEditor.editor.setSelection(
										quillEditor.editor.getLength(),
										quillEditor.editor.getLength()
									);
								}
							}
						}
					}
				}
			}
		}
	}, [hashValue, createHashtagCommentId]);

	const checkNewHashTag = (newHashtag, enterKey) => {
		var splitText = newHashtag.split(" ");
		for (var i = 0; i < splitText.length; i++) {
			if (splitText[i].charAt(0) == "#") {
				storeNewHashTag(splitText[i].substring(1).trim(), enterKey);
			}
		}
	};

	const storeNewHashTag = (name, enterKey) => {
		const requestProfile = {
			email: user.email,
			acctId: user.acctId,
			hashtagName: name,
			accessToken: localStorage.getItem("token"),
			commentId: comment ? comment.commentId : 0,
			postId: 0,
			enterKey: enterKey,
		};
		dispatch(createSocialHashtag(requestProfile));
	};

	const getHashTagList = (searchTerm) => {
		const profileRequest = {
			email: user.email,
			acctId: user.acctId,
			keyword: searchTerm,
			lastRecordSize: 0,
			requestRecordSize: 10,
			accessToken: localStorage.getItem("token"),
		};
		dispatch(getSocialHashtag(profileRequest));
	};

	const getProfileTagList = (searchTerm) => {
		const profileRequest = {
			email: user.email,
			acctId: user.acctId,
			keyword: searchTerm,
			groupId: groupId ? groupId : 0,
			lastRecordSize: 0,
			requestRecordSize: 10,
			accessToken: localStorage.getItem("token"),
		};
		if (groupId > 0) {
			dispatch(getSocialProfileTagByGroupId(profileRequest));
		} else {
			dispatch(getSocialProfileTag(profileRequest));
		}
	};

	const onQuillChange = (content, delta, source, editor) => {
		// console.log(content, delta, source, editor);
		// var counter = document.getElementById("quillCommentCounter");
		var counter = document.getElementsByClassName("quillCommentCounter");
		if (counter) {
			for (var i = 0; i < counter.length; i++) {
				var wordCount = editor.getLength() - 1;
				if (wordCount <= 200) {
					counter[i].innerHTML = "(" + wordCount + "/200)";
					counter[i].style.color = "#000000DE";
				} else {
					counter[i].innerHTML =
						"Description is excceding maximum character limit (" +
						wordCount +
						"/200)";
					counter[i].style.color = "red";
				}
			}
		}

		if (emojiChoose || hashValue) {
			setTimeout(() => {
				console.log("reset");
				dispatch(resetCreatedHashtag());
			}, 3000);
			getLength(editor.getText().length - 1);
			// getText(editor.getText());
			getHTML(editor.getHTML());
			getContent(editor.getContents());
		}

		debouceRequest(
			editor.getText().length - 1,
			editor.getHTML(),
			editor.getContents()
		);

		// quillRefPush(quillRef);
		// get
	};

	const debouceRequest = debounce((length, html, content) => {
		console.log(length, html, content);
		getLength(length);
		getHTML(html);
		getContent(content);

		// if (postClick && checkLastHashtag) {
		// 	console.log(postClick, checkLastHashtag);
		// 	var latestDescription = { length: length, html: html, content: content };
		// 	setCheckLastHashtag(false);
		// 	setTimeout(() => {
		// 		createPost(latestDescription);
		// 	}, 1000);
		// }
	}, 500);

	useEffect(() => {
		if (emojiChoose) {
			console.log(emojiChoose);
			quillRef.current.editor.insertText(
				quillRef.current.editor.getLength() - 1,
				emojiChoose,
				"true"
			);
		}
		resetEmoji(null);
	}, [emojiChoose]);

	useEffect(() => {
		if (parentId > 0)
			//Edit Comment
			editComment(comment);
	}, []);

	const editComment = (comment) => {
		var timer = setInterval(() => {
			console.log(quillRef);
			if (quillRef) {
				clearInterval(timer);
				let newQuill = new Quill("#quillEditor");
				let initialContent = newQuill.clipboard.convert(comment.description);
				quillRef.current.editor.setContents(initialContent, "silent");
				quillRef.current.editor.focus();
				quillRef.current.editor.setSelection(
					quillRef.current.editor.getLength(),
					quillRef.current.editor.getLength()
				);
			}
		}, 100);
	};

	React.useEffect(() => {
		console.log(quillRef);
	}, [quillRef]);

	Quill.register("modules/magicUrl", MagicUrl);

	return (
		<div>
			<ReactQuill
				ref={quillRef}
				placeholder="Write your commentssss"
				className={classes.quillCommentEditor}
				theme="bubble"
				multiline={true}
				// contenteditable={true}
				modules={modules}
				// defaultValue={textValue}
				// onChange={setValue}
				// value={value}
				onChange={onQuillChange}
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						checkNewHashTag(quillRef.current.editor.getText(), true);
						if (e.shiftKey) {
							e.preventDefault();
							// postReply(editQuillRef.current, comment.commentId);
							// onChange(quillRef.current);
							onSubmit(true);
							submitCommentId(parentId);
							// quillRef.current.editor.setText("");
						}
					}
				}}
			/>
			<div
				className={classes.quillPostCounter + " quillCommentCounter"}
				id="quillCommentCounter"
			>
				(0/200)
			</div>
		</div>
	);
};

export default React.memo(CommentInput);
