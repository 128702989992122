import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { departmentViewRoadmap, viewRoadmapRoleByRoleId } from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";
import ImageMapper from "react-image-mapper";
import Department_Front_Office from "assets/img/Career_Roadmap/Hospitality/Department_Front_Office_1.jpg";
import RoleInfoModal from "../RoleInfoModal.js";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentFrontOffice() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Front_Office;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "fo",
				shape: "poly",
				coords: [77,203,70,205,67,208,63,213,61,220,61,630,64,636,67,640,72,644,77,646,173,646,179,644,183,640,188,635,190,629,190,218,186,212,182,207,176,204,171,202,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "115",
				shape: "poly",
				coords: [220,552,214,554,211,557,208,560,207,565,207,636,210,640,212,643,217,646,222,647,386,646,390,644,393,641,395,638,398,634,397,564,396,560,393,556,388,553,384,552,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "116",
				shape: "poly",
				coords: [467,552,460,554,455,558,452,563,450,569,451,634,453,640,456,643,461,646,467,648,630,647,636,644,639,640,641,638,642,631,642,567,640,561,638,558,634,555,629,553,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "123",
				shape: "poly",
				coords: [706,102,703,105,699,108,697,112,695,116,695,183,697,189,699,193,703,195,707,197,872,197,877,195,881,192,883,190,886,185,886,118,885,112,882,107,877,104,871,102,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "120",
				shape: "poly",
				coords: [706,306,701,308,697,310,695,314,693,320,693,419,695,425,698,429,702,433,710,434,870,433,875,432,879,429,881,425,883,421,884,320,882,314,879,310,873,306,867,305,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "117",
				shape: "poly",
				coords: [709,553,703,556,700,558,697,562,695,567,695,636,697,640,699,644,704,647,709,649,872,649,877,646,881,643,884,639,886,634,886,568,884,562,881,559,877,555,869,554,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "124",
				shape: "poly",
				coords: [954,102,948,104,944,107,941,111,939,115,939,183,941,189,943,193,948,196,955,197,1116,197,1123,195,1126,191,1129,188,1131,180,1131,117,1129,112,1126,107,1122,104,1116,102,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "121",
				shape: "poly",
				coords: [952,306,947,309,943,312,941,317,939,323,939,422,942,427,945,430,950,433,957,434,1113,433,1120,432,1125,429,1128,426,1130,420,1131,322,1129,314,1126,310,1121,307,1116,305,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "118",
				shape: "poly",
				coords: [955,553,949,555,944,558,941,562,939,568,939,635,941,641,944,644,950,648,956,649,1113,649,1121,648,1124,645,1128,641,1130,636,1131,570,1129,563,1125,558,1121,555,1114,553,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "125",
				shape: "poly",
				coords: [1198,103,1192,105,1187,108,1184,112,1183,118,1183,182,1185,189,1187,193,1192,197,1199,198,1361,198,1366,196,1370,193,1372,189,1375,184,1374,119,1373,113,1371,109,1366,105,1361,103,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "122",
				shape: "poly",
				coords: [1199,305,1194,307,1190,310,1188,314,1186,319,1186,419,1187,424,1190,428,1195,431,1201,433,1363,432,1368,431,1372,428,1375,422,1376,415,1377,322,1376,314,1372,310,1367,306,1362,304,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "119",
				shape: "poly",
				coords: [1198,555,1192,557,1188,560,1185,563,1184,570,1184,636,1185,641,1188,646,1193,648,1199,650,1360,650,1366,648,1370,645,1372,640,1374,634,1375,571,1373,564,1369,559,1364,556,1359,554,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "129",
				shape: "poly",
				coords: [1643,103,1636,104,1633,107,1629,112,1627,118,1627,194,1629,201,1632,205,1636,208,1643,209,1823,209,1830,206,1834,204,1837,200,1839,193,1839,120,1837,113,1834,108,1829,105,1823,103,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "128",
				shape: "poly",
				coords: [1649,250,1640,252,1635,256,1633,261,1632,267,1633,342,1634,347,1636,352,1643,355,1649,357,1832,356,1837,354,1839,350,1843,346,1844,339,1845,266,1843,260,1839,255,1835,252,1829,250,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "127",
				shape: "poly",
				coords: [1647,398,1640,401,1636,404,1634,408,1632,413,1633,489,1635,495,1637,500,1642,504,1649,504,1833,504,1837,501,1841,498,1843,493,1844,486,1844,413,1842,407,1838,403,1834,401,1828,399,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "126",
				shape: "poly",
				coords: [1649,546,1640,548,1636,551,1632,556,1631,563,1632,641,1635,644,1637,648,1643,651,1648,652,1829,652,1835,649,1839,646,1841,643,1844,638,1843,560,1841,554,1838,550,1833,548,1827,546,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "fo") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo)
			dispatch(viewRoadmapRoleByRoleId(requestInfo));
			
			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// console.log(coords.x + "," + coords.y + ",");
		// navigator.clipboard.writeText(coords.x + "," + coords.y + ",");
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}

	function enterArea(area) {
		console.log(area);
	}

	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
				onMouseEnter={(area) => enterArea(area)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
