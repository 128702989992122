import React, { useState, useEffect } from "react";

import { Card, CardHeader, CardContent } from "@material-ui/core";
import { Skeleton } from "@mui/material";
import "../../../../src/assets/css/skeleton.css";

const CommentSkeleton = (props) => {
	return (
		<div
			style={{
				marginBottom: 10,
				marginTop: "-9px",
				marginLeft: "-16px",
			}}
		>
			<Card
				sx={{ maxWidth: 345, m: 2 }}
				style={{ boxShadow: "none", borderRadius: "18px" }}
			>
				<CardHeader
					avatar={
						<Skeleton
							animation="wave"
							variant="circular"
							width={40}
							height={40}
						/>
					}
					title={
						<div>
							<Skeleton
								animation="wave"
								height={10}
								width="80%"
								style={{ marginBottom: 6 }}
							/>
							<Skeleton
								animation="wave"
								height={10}
								width="80%"
								style={{ marginBottom: 6 }}
							/>
							<Skeleton
								animation="wave"
								height={10}
								width="80%"
								style={{ marginBottom: 6 }}
							/>
						</div>
					}
					subheader={<Skeleton animation="wave" height={10} width="40%" />}
				/>
				{/* <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" /> */}
				{/* <CardContent>
					<React.Fragment>
						<Skeleton
							animation="wave"
							height={10}
							style={{ marginBottom: 6 }}
						/>
						<Skeleton animation="wave" height={10} width="80%" />
					</React.Fragment>
				</CardContent> */}
			</Card>
		</div>
	);
};

export default CommentSkeleton;
