import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
	departmentViewRoadmap,
	viewRoadmapRoleByRoleId,
} from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Industry_Marine_Engineering from "assets/img/Career_Roadmap/Marine_Engineering/Industry_Marine_Engineering_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function IndustryMarineEngineering() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Industry_Marine_Engineering;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "pst",
				shape: "poly",
				coords: [82,289,76,292,72,295,69,300,67,307,67,356,69,362,72,366,77,370,86,373,191,373,198,370,204,367,208,362,210,355,210,306,207,300,203,294,199,291,191,288,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "ted",
				shape: "poly",
				coords: [88,425,80,428,76,431,72,436,70,444,70,492,72,497,75,502,81,507,85,509,195,509,202,507,207,503,210,498,213,492,213,441,210,435,207,431,201,427,196,425,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "294",
				shape: "poly",
				coords: [248,292,241,295,238,297,234,302,234,308,234,356,236,362,238,366,243,368,248,370,380,370,388,367,392,363,395,357,396,351,396,310,395,301,390,296,384,293,378,293,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "295",
				shape: "poly",
				coords: [594,292,589,296,586,298,583,302,582,307,583,357,585,362,588,365,592,368,596,370,730,370,736,368,739,365,743,361,745,356,744,309,742,301,737,296,732,293,727,292,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "296",
				shape: "poly",
				coords: [967,292,960,294,956,298,954,302,952,308,953,358,955,362,958,367,962,369,968,370,1100,370,1106,368,1110,364,1114,360,1116,352,1115,312,1114,304,1110,298,1105,294,1100,292,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "297",
				shape: "poly",
				coords: [1339,292,1331,295,1328,298,1326,302,1324,308,1325,356,1327,362,1329,366,1333,368,1336,370,1470,370,1479,367,1482,364,1485,360,1487,354,1487,309,1484,300,1480,296,1476,294,1470,292,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "298",
				shape: "poly",
				coords: [1709,292,1703,294,1699,296,1697,301,1695,306,1694,355,1697,361,1699,365,1706,368,1715,370,1845,370,1854,368,1858,365,1861,360,1864,355,1864,311,1862,301,1858,296,1853,293,1847,292,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "299",
				shape: "poly",
				coords: [245,424,238,426,234,430,230,436,228,442,229,482,230,490,232,493,234,497,238,501,243,502,375,502,384,500,388,496,391,491,392,483,392,446,391,436,388,431,383,426,377,424,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "300",
				shape: "poly",
				coords: [483,425,476,426,473,428,469,433,467,438,468,488,470,493,473,497,478,501,484,502,617,502,622,499,626,496,628,492,629,486,630,440,628,433,624,429,619,426,614,424,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "301",
				shape: "poly",
				coords: [724,422,716,425,712,428,708,432,706,439,706,486,708,492,711,496,716,500,722,500,850,501,859,499,863,496,867,492,868,485,869,444,868,435,865,429,860,425,853,423,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "302",
				shape: "poly",
				coords: [964,423,954,425,950,427,946,432,945,440,945,482,945,490,949,495,953,498,958,501,1086,501,1096,500,1102,496,1104,493,1107,486,1108,443,1106,434,1104,429,1099,425,1093,424,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "303",
				shape: "poly",
				coords: [1205,423,1193,426,1188,428,1185,433,1184,441,1183,484,1184,492,1187,496,1192,499,1199,500,1325,500,1336,500,1341,496,1344,490,1345,484,1346,444,1345,434,1341,428,1336,424,1326,423,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "304",
				shape: "poly",
				coords: [1438,423,1429,426,1425,429,1423,434,1422,442,1422,487,1424,494,1429,497,1433,500,1439,501,1566,500,1575,500,1579,496,1581,492,1584,487,1584,442,1584,432,1579,428,1573,424,1565,422,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "305",
				shape: "poly",
				coords: [1709,423,1701,427,1698,430,1695,434,1695,440,1695,487,1697,493,1700,496,1703,500,1710,502,1850,502,1858,498,1861,493,1863,488,1864,483,1864,437,1862,430,1859,427,1852,424,1847,424,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "pst") {
			dispatch(departmentViewRoadmap(36));
		} else if (area.name === "ted") {
			dispatch(departmentViewRoadmap(37));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo);
			dispatch(viewRoadmapRoleByRoleId(requestInfo));

			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
