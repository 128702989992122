import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { CardMedia, Chip } from "@material-ui/core";
import Header from '../../Component/Header.js';
import ProfileImg from 'assets/img/profileImg.jpg';
import Psy from 'assets/images/psy.png';
import Avtar from 'assets/images/avtar.png';
import Fb from 'assets/img/fb.png';
import Zoom from 'assets/img/zoom.png';
import LeftSidebar from 'views/Component/LeftSidebar.js';
// import RecommendedJobs from "views/Component/RecommendedJobs.js";
import SocialFeed from 'views/Component/SocialFeed/SocialFeed';
import SocialRecommendedFeed from 'views/Component/SocialFeed/SocialRecommendedFeed';
import CreatePostModal from '../../../components/CreatePostModal/CreatePostModal';
import CreatePollModal from '../../../components/CreatePostModal/CreatePollModal';
import PreviewPostPhotosModal from '../../Component/PreviewPostPhotosModal';
import PreviewPostVideosModal from '../../Component/PreviewPostVideosModal';
import RightSideBarUserList from 'views/Component/RightSideBarUserList.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import Footer from 'views/Component/Footer.js';
import Backdrop from '../../Component/Backdrop.js';
import ProfileStrengthSection from "../ProfilePage/ProfileSection/ProfileStrengthSection";
import CalendarSection from "../../Component/CalendarSection";
import { Box, makeStyles, Modal } from "@material-ui/core";
import Button from 'components/CustomButtons/Button.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from '../../../components/Grid/GridItem.js';
import WhatshotRoundedIcon from '@material-ui/icons/WhatshotRounded';
import { Divider } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import IconLibrary from 'views/Component/IconLibrary.js';
import { encryptData, TimeFormat } from 'configurations/configurations.js';
import defaultAvatar from 'assets/img/corporate_avatar.jpeg';
import searchJobPageStyle from 'assets/jss/material-kit-pro-react/views/pageStyle/SearchJobPage/searchJobPageStyle.js';
import ResultJobModal from '../SearchJobPage/ResultJobModal.js';
import ArticleComponentInPage from "../SearchPage/SearchArticlePage/ArticleComponentInPage.js";
import ArticleCard from '../SearchPage/SearchArticlePage/ArticleCard.js';
import { errorCleanUp } from 'configurations/redux/authSlice.js';
import loginImage from 'assets/img/Login_Image.png';
import { Grid, Hidden, Typography } from '@material-ui/core';
import Cookies from 'js-cookie';

import { getCandidateSelectJobPosts } from 'configurations/redux/jobManagementSlice.js';

import { getAppliedJobPostList } from "configurations/redux/appliedJobSlice";

import {
  getJobsRecommendedByAcct,
  getArticlesRecommendedByAcct
} from 'configurations/redux/userDashboardSlice';

import {
  getProfiles,
  getInterestedIndustries,
  createConnect,
} from "configurations/redux/userSlice.js";
/*sourabh end*/
import {
  getSocialPostByRecent,
  getSocialPostByTop,
  getSocialPostByPostId,
  resetPostGet,
  getHashtagMatch,
  getSocialPostByHashId,
  resetHashtagMatch,
  getRecommendedPostByAccId,
} from 'configurations/redux/socialFeedSlice.js';

import {
  decryptData,
  getVisibleOption,
  getSocialPostTypeOption,
  getCommentPermissionByPermissionType,
  getPollDurationOption,
  gaPageView
} from 'configurations/configurations';

import LandingPageFeedStyle from 'assets/jss/material-kit-pro-react/views/pageStyle/LandingPageFeed/LandingPageFeedStyle.js';
import communityPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/CommunityPage/communityPageStyle.js";
import SocialFeedControl from './SocialFeedControl';
import Switch from '@mui/material/Switch';
import MetatagHelmet from '../../Component/MetatagHelmet';
import dateFormat from 'dateformat';

const useStyles = makeStyles(LandingPageFeedStyle);
const useStyles1 = makeStyles(communityPageStyle);

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  React.useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const UserDashboard = ({ match }) => {
  const label1 = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const articleclasses = useStyles1();
  const dispatch = useDispatch();
  var isKicked = Cookies.get('isKicked');

  const auth = useSelector(state => state.auth);
  const isVerified = useSelector(state => state.auth.isVerified);
  const isResend = useSelector(state => state.auth.isResend);

  const [emailSend, setEmailSend] = useState(false);
  const isError = useSelector(state => state.auth.isError);

  // #region <------- Component States -------->
  const [isCreatePostModalOpen, setIsCreatePostModalOpen] = useState(false);
  const [isCreatePollModalOpen, setIsCreatePollModalOpen] = useState(false);
  const [question, setQuestion] = useState('');
  const [options, setOptions] = useState(['', '']);
  const [pollTiming, setPollTiming] = useState('1 day');
  const [postedData, setPostedData] = useState({});

  const [uploadedPhotos, setUploadedPhotos] = useState([]);
  const [isPreviewPostPhotosOpen, setIsPreviewPostPhotosOpen] = useState(false);
  const [previewPhoto, setPreviewPhoto] = useState();
  const [uploadedVideos, setUploadedVideos] = useState([]);
  const [isPreviewPostVideosOpen, setIsPreviewPostVideosOpen] = useState(false);
  const [previewVideo, setPreviewVideo] = useState();
  const [draggedItemindex, setDraggedItemIdex] = useState(null);
  const [uploadError, setError] = useState('');

  const [visibleOptions, setVisibleOptions] = useState(null);
  const [commentabilityOptions, setCommentabilityOptions] = useState(null);
  const [socialPostTypeOptions, setSocialPostTypeOptions] = useState(null);
  const [pollDurationOptions, setPollDurationOptions] = useState(null);
  const [postsList, setPostsList] = useState([]);
  const currUser = useSelector((state) => state.auth.user);

  const [width, height] = useWindowSize();

  const [candidateShow, setCandidateShow] = useState(true);
  const [corporateShow, setCorporateShow] = useState(false);
  const [cardAnimation, setCardAnimation] = useState('cardHidden');
  const [isLoading, setIsLoading] = useState(false);
  const [editPost, setEditPost] = useState(null);
  const [sharePost, setSharePost] = useState(null);
  const [shareJob, setShareJob] = useState(null);
  const hashtagMatch = useSelector(state => state.socialFeed.hashtagMatch);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [careerGuidesList, setCareerGuidesList] = useState([]);
  const [jobRecommendationsList, setJobRecommendationsList] = useState([]);

  const communityArticlePath = useSelector(
    (state) => state.community.communityArticlePath
  );
  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  /*sourabh start*/
  const [jobViewModal, setJobViewModal] = React.useState(false);
  const onJobModelClose = () => {
    setJobViewModal(false);
  };

  const getJobPostModel = async (jobPostSelId) => {


    const requestProfile = {
      acctId: userStatus.acctId,
      email: userStatus.email,
      accessToken: localStorage.getItem("token"),
      // acctId: auth.isPublic ? 0 : userStatus.acctId,
      // accessToken: auth.isPublic ? auth.publicToken : localStorage.getItem('token'),
      ipAddr: localStorage.getItem('ipAddr'), //await publicIp.v4(),
      jobPostId: jobPostSelId,
      item: null,
    };
    console.log(requestProfile);
    dispatch(getCandidateSelectJobPosts(requestProfile));
    setJobViewModal(true);
  };

  /*sourabh end*/


  function imageExists(image_url) {
    var http = new XMLHttpRequest();

    try {
      http.open("HEAD", image_url, false);
      http.send();
    } catch (error) { }

    return http.status == 200;
  }

  const userJobPostList = useSelector((state) => state.appliedJob.jobPostList);

  const isViewed = useSelector((state) => state.user.isViewed);
  const isUpdated = useSelector((state) => state.user.isUpdated);
  const userStatus = useSelector(state => state.auth.user);


  var user = useSelector((state) => state.user);
  console.log('=========user-------');
  console.log(user);


  const requestUserProfile = {
    acctId: userStatus.acctId,
    userId: decryptData(match.params.acctId),
    ipAddr: localStorage.getItem("ipAddr"), //clientIp,
    accessToken: localStorage.getItem("token"),
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  window.onbeforeunload = function () {
    // window.scrollTo({ top: 0, behavior: "smooth" });
    scrollToTop();
  };

  useEffect(() => {
    let isMounted = true;
    scrollToTop();
    // window.scrollTo({ top: 0, behavior: "smooth" });
    history.replace(match.url);
    if (isError) {
      dispatch(errorCleanUp());
    }

    const payloadRequested = {
      email: userStatus.email,
      acctId: userStatus.acctId,
      accessToken: localStorage.getItem('token'),
      lastRecordSize: 0,
      requestRecordSize: 6
    };

    dispatch(getJobsRecommendedByAcct(payloadRequested, (result) => {
      setJobRecommendationsList(result.payload.jobRecommendationList);
    }));

    dispatch(getArticlesRecommendedByAcct(payloadRequested, (result) => {
      setCareerGuidesList(result.payload.articleRecommendationList);
    }));

    dispatch(getProfiles(requestUserProfile));

    getVisibleOption()
      .then(res => {
        if (isMounted) setVisibleOptions(res.data.payload);
      })
      .catch(err => console.log(err));

    getSocialPostTypeOption()
      .then(res => {
        if (isMounted) setSocialPostTypeOptions(res.data.payload);
      })
      .catch(err => console.log(err));

    // Permission type 1 and 2
    getCommentPermissionByPermissionType('1,2')
      .then(res => {
        if (isMounted) setCommentabilityOptions(res.data.payload);
      })
      .catch(err => console.log(err));

    getPollDurationOption()
      .then(res => {
        if (isMounted) setPollDurationOptions(res.data.payload);
      })
      .catch(err => console.log(err));

    //  gaPageView(window.location.pathname);
    return () => {
      isMounted = false;
    };
  }, []);


  React.useEffect(() => {
    requestMyApplicationData();

  }, []);
  const requestMyApplicationData = async () => {
    const request = {
      email: userStatus.email,
      acctId: userStatus.acctId,
      accessToken: localStorage.getItem('token'),
      lastRecordSize: 0,
      requestRecordSize: 300,
      statusIds: -1,
    };
    dispatch(getAppliedJobPostList(request));
  };

  const scrollToTop = () => {
    var timer = setInterval(() => {
      var result = document.getElementById('socialFeedPage');
      if (result) {
        result.scrollIntoView();
        window.scrollTo(0, 0);
        clearInterval(timer);
      }
    }, 100);
  };

  const loadFeed = () => {
    var previousPostListType = localStorage.getItem('postListType');
    const requestProfile = {
      acctId: userStatus.acctId,
      accessToken: localStorage.getItem('token'),
      email: userStatus.email,
      lastRecordSize: 0,
      requestRecordSize: 5,
      hashId: 0,
      userId: 0,
      groupId: 0,
      pageId: 0,
      sortBy: previousPostListType ? previousPostListType : 'recent',
      keyword: '',
      visibleIds: '1,2',
    };
    // console.log(requestProfile);
    // console.log(previousPostListType);
    // if (previousPostListType || previousPostListType == "Recent")
    dispatch(getSocialPostByRecent(requestProfile));
    // else dispatch(getSocialPostByTop(requestProfile));
  };

  const return_label_class = (curStatus, name) => {

    switch (curStatus) {
      case "hired":
        return (<span className="pendingSpan hired">{name} </span>);
      case "shortlisted":
        return (<span className="pendingSpan hinterviewing">{name} </span>);
      case "rejected":
        return (<span className="pendingSpan rejected">{name} </span>);
      case "completed":
        return (<span className="pendingSpan hinterviewing">{name} </span>);
      case "withdraw":
        return (<span className="pendingSpan ">{name} </span>);
      case "submitted":
        return (<span className="pendingSpan hired">{name} </span>);
      default: return (<span className="pendingSpan ">{name} </span>);
    }

  }

  setTimeout(function () {
    setCardAnimation('');
  }, 700);

  function dateFormatCustom(inputDate, format) {

    //parse the input date
    const date = new Date(inputDate);

    //extract the parts of the date
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    //replace the month
    format = format.replace("MM", month.toString().padStart(2, "0"));

    //replace the year
    if (format.indexOf("yyyy") > -1) {
      format = format.replace("yyyy", year.toString());
    } else if (format.indexOf("yy") > -1) {
      format = format.replace("yy", year.toString().substr(2, 2));
    }

    //replace the day
    format = format.replace("dd", day.toString().padStart(2, "0"));

    return format;
  }

  const groupByData2 = userJobPostList && userJobPostList.reduce(
    // var tempkey = dateFormat(item[key], 'dd-mm-yyyy');
    (result, item) => ({


      ...result,
      [dateFormatCustom(item['applDate'], 'dd-MM-yyyy')]: [
        ...(result[dateFormatCustom(item['applDate'], 'dd-MM-yyyy')] || []),
        item,
      ],
    }),
    {},
  );

  console.log('userJobPostListItem', arrayNewSavedJob);
  console.log();
  var arrayNewSavedJob = groupByData2 && Object.keys(groupByData2).map(k => groupByData2[k]);
  console.log('groupByData2');
  console.log(arrayNewSavedJob);


  return (
    <React.Fragment>
      <div>
        <div id="UserDashboard" className={classes.body}>
          <Header />
          <div className='customcontainer' id="socialFeedPage">
            <GridContainer justifyContent="center" spacing={2}>
              <GridItem xs={12} sm={12} md={8} lg={8} xl={8} className={classes.middlePart}>
                <Card className='customCaRd'>
                  <CardBody className='customCardBody'>
                    <Typography className='viewedProfileDiv'>
                      <Typography className='viewedProfileLeft'>
                        <Link to={`/view-profile/${encryptData(userStatus.acctId)}`} >
                          <img className='profileImg' src={userStatus.profilePic ? userStatus.profilePic : Avtar} />

                        </Link>
                      </Typography>
                      <Typography className='viewedProfileRight'>
                        <Typography className='viewProfileRightUpper'>
                          <Typography className='viewProfileRightUpperLeft'>
                            <Typography className='proTitle'>{userStatus.firstName} {userStatus.middleName} {userStatus.surname}</Typography>
                            <p className='pText'>{user.interestedIndustry[0] && user.interestedIndustry[0].industryName ? user.interestedIndustry[0].industryName : ''}</p>
                          </Typography>
                          <Typography className='viewProfileRightUpperright'>
                            <p className='twenty'>20</p>
                            <p className='pText'>people viewed your profile in the past 7 days</p>
                          </Typography>
                        </Typography>

                        {/* <Link className='improveProfile'>Improve Your Profile</Link> */}

                        {isUpdated && !isLoading && isViewed ? null : (
                          <ProfileStrengthSection />
                        )}

                      </Typography>
                    </Typography>
                  </CardBody>
                </Card>
                <Typography component='div' className='upcomingDiv'>
                  <Typography component='div' className='upcomingDivLeft'>
                    <Typography className='UpcomingTitle'>Upcoming Interviews</Typography>
                  </Typography>
                  <Typography component='div' className='upcomingDivRight'>
                  </Typography>
                </Typography>
                <CalendarSection data='user' />
              </GridItem>
              {/* <Hidden smDown> */}
              <GridItem xs={12} sm={12} md={4} lg={4} xl={4} className={classes.middlePart}>
                <Card className='customCaRd'>
                  <CardBody className='customCardBody'>
                    <img className='psyImg' src={Psy} />
                    <Typography className='myApplicationDiv psyText'>
                      <Typography className='myAppTitle'>Your Psychometric Test</Typography>

                    </Typography>
                    <p className='quizText'>Take this quiz to discover the ideal career path and jobs for your personality type.</p>
                    {/* <button className='FindMore'>Find Out More</button> */}
                    {/* <Link className='takeTest FindMore' to={`/psychometric-result-view/${encryptData(currUser.acctId)}`}>FIND OUT MORE</Link> */}
                    <Button className='takeTest FindMore' href={`/psychometric-result-view/${encryptData(currUser.acctId)}`}>FIND OUT MORE</Button>
                  </CardBody>
                </Card>

                <Card className='customCaRd'>
                  <CardBody className='customCardBody MyAppBody'>
                    <Typography className='myApplicationDiv'>
                      <Typography className='myAppTitle'>My Applications</Typography>
                      <Link to={`/job-application-landing/${encryptData(userStatus.acctId)}`} className='manageLink'>Manage</Link>
                    </Typography>

                    <Typography className='myApplicationDivMain'>
                      {

                        arrayNewSavedJob && arrayNewSavedJob.length > 0 &&
                        arrayNewSavedJob.map(function (savedJob, index1) {
                          return (
                            savedJob.map(function (userJobPostListItem, index2) {

                              return (
                                <>

                                  {index2 === 0 ? (<Typography className='dateP'>{dateFormat(userJobPostListItem.applDate, "dS, mmmm yyyy")}</Typography>) : ('')}
                                  <Typography className='pendingReview' key={index2}>
                                    <Link to="#" onClick={() => getJobPostModel(userJobPostListItem.jobPostId)}  >
                                      {/* <span className="pendingSpan ">{userJobPostListItem.applStatusName}</span>*/
                                      }
                                      {return_label_class(userJobPostListItem.applStatusName, userJobPostListItem.applStatusName)}


                                      {/*
							  
							  <span className='pendingSpan rejected'>Rejected</span>
                              <span className='pendingSpan hired'>Hired</span>
                              <span className='pendingSpan hinterviewing'>Hired</span>  
								 
								*/
                                      }

                                      <p className='business_p'>{userJobPostListItem.title} {userJobPostListItem.jobTypeId ? '(' + userJobPostListItem?.jobType + ')' : ''} </p>
                                      <span className='companyName'>{userJobPostListItem.companyName}</span>
                                    </Link>
                                  </Typography>
                                </>
                              )
                            })
                          )
                        })
                      }

                    </Typography>
                    <a href={`/job-application-landing/${encryptData(userStatus.acctId)}`} className='ViewAll'>View All</a>
                  </CardBody>
                </Card>
              </GridItem>
              {/* </Hidden> */}
            </GridContainer>

            <GridContainer justifyContent="center" spacing={4}>
              <GridItem xs={12} sm={12} md={5} lg={5} xl={5} className={classes.middlePart}>
                <Typography className='myApplicationDiv recomdDiv'>
                  <Typography className='UpcomingTitle'>Recommended Jobs For You</Typography>
                  {/* <Link className='manageLink'>View All</Link> */}
                </Typography>

                <GridContainer spacing={4} className='customGridContainer'>
                  {(jobRecommendationsList.length > 0 && jobRecommendationsList.length <= 4) && jobRecommendationsList.map((item, index) => {
                    return (
                      <GridItem xs={12} sm={12} md={6} lg={6} xl={6} key={index}>
                        <Card className='customCaRd' onClick={() => getJobPostModel(item.jobPostId)}>
                          <CardBody className='customCardBody recommendedCard'>
                            <Link to="#" onClick={() => getJobPostModel(item.jobPostId)} >
                              <div className={classes.textCenter}>
                                <img className='cardimg' src={defaultAvatar} alt="..." />
                              </div>
                              <div className='titleDiv'>

                                <h4 className={classes.jobTitle + ' ' + classes.jobCardTitle}>{item.title.length > 23 ? (item.title).substring(0, 23) + '...' : item.title}</h4>

                              </div>
                              <div className='companyNameDiv'>
                                <h5 className={classes.companyName + ' ' + classes.textCenter}>{item.companyName}</h5>
                              </div>
                              <div className='jobCardDescription'>
                                <IconLibrary.Location width={20} height={16} />
                                <span className={classes.jobDescription}>{item.countryName}</span>
                              </div>
                              <div className='jobCardDescription'>
                                <IconLibrary.Dollar width={20} height={16} />
                                <span className={classes.jobDescription}>
                                  {item.currencyCode} {item.salaryMin} - {item.currencyCode} {item.salaryMax}
                                </span>
                              </div>
                              <div className='jobCardDescription'>
                                <IconLibrary.Calendar width={20} height={16} />
                                <span className={classes.jobDescription}>sfsdf</span>
                              </div>
                              <div className='dividerContainer'>
                                <Divider />
                              </div>
                              <div className={classes.viewCountDescription} style={{ display: 'none' }}>
                                <IconLibrary.Calendar width={20} height={16} />
                                <span className={classes.jobDescription}>{'Posted ' + TimeFormat(item.lastUpdateDate)}</span>
                              </div>
                            </Link>
                          </CardBody>
                        </Card>
                      </GridItem>
                    )
                  })}
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={12} md={7} lg={7} xl={7} className={classes.middlePart}>
                <Typography className='myApplicationDiv recomdDiv'>
                  <Typography className='UpcomingTitle'>Career Guides For You</Typography>
                  {careerGuidesList.length > 6 && <Link className='manageLink'>View All</Link>}
                </Typography>
                {/* <Typography className='recommenDesc'>&nbsp;</Typography> */}
                <GridContainer spacing={4} className='customGridContainer'>
                  {(careerGuidesList.length > 0 && careerGuidesList.length <= 6) && careerGuidesList.map((item, index) => {
                    return (
                      <GridItem xs={12} sm={6} md={6} lg={4} xl={3}>
                        <Link
                          className={classes.textDeco}
                          target="_blank"
                          to={{
                            pathname: auth.isPublic
                              ? `/public-community-detail-page/${encryptData(item.articleId)}`
                              : `/community-detail-page/${encryptData(item.articleId)}`,
                            //state: { acctId: item.acctId },
                          }}
                          color="transparent"
                          key={index}
                        >
                          <div style={{ height: "290px" }} key="0">
                            <Card
                              className='customCaRd'
                              style={{ height: "100%" }}
                            >
                              <CardMedia
                                className={articleclasses.cardImage}
                                image={
                                  item.coverPic &&
                                    imageExists(communityArticlePath + item.coverPic)
                                    ? communityArticlePath + item.coverPic
                                    : defaultAvatar
                                }
                                //  image={defaultAvatar}
                                title={item.articleName}
                              />

                              <CardBody className={articleclasses.cardPadding}>
                                <div>
                                  <GridContainer>
                                    <GridItem xs={12}>
                                      <Chip
                                        key="2"
                                        label={
                                          <p
                                            className={
                                              articleclasses.createdDescription +
                                              " " +
                                              articleclasses.createdDescFont
                                            }
                                          >
                                            {item.subcategory}
                                          </p>
                                        }
                                        className={articleclasses.chip}
                                        color={"secondary"}
                                      />
                                    </GridItem>
                                    {/* <GridItem xs={6} className={articleclasses.textAlignRight}>
                                <span className={articleclasses.createdDescFont}>
                                  
                                  16 June 2022
                                </span>
                              </GridItem> */}
                                  </GridContainer>
                                </div>
                                <div className={articleclasses.titleDiv}>
                                  <h4
                                    className={
                                      articleclasses.articleTitle + " " + articleclasses.articleCardTitle
                                    }
                                  >
                                    {item.articleName}
                                  </h4>
                                </div>
                                <div>
                                  <span className={articleclasses.createdDescFont}> By : </span>
                                  <span
                                    className={
                                      articleclasses.createdDescription +
                                      " " +
                                      articleclasses.createdDescFont
                                    }
                                  >
                                    Oppty
                                  </span>
                                </div>
                              </CardBody>
                            </Card>
                          </div>
                        </Link>
                      </GridItem>
                    )
                  })}

                </GridContainer>
              </GridItem>
            </GridContainer>

            {/* Recommended Posts */}
            <GridContainer justifyContent="center" spacing={2}>
              <GridItem xs={12} sm={12} md={7} lg={7} xl={7} className={classes.middlePart}>
                <Typography component='div' className='upcomingDiv'>
                  <Typography component='div' className='upcomingDivLeft'>
                    <Typography className='UpcomingTitle'>Recommended Posts</Typography>
                  </Typography>
                  <Typography component='div' className='upcomingDivRight'>
                  </Typography>
                </Typography>


                <div class="recommend_post_main">
                  <SocialRecommendedFeed />
                </div>

              </GridItem>
              <GridItem xs={12} sm={12} md={5} lg={5} xl={5} className={classes.middlePart}>
              </GridItem>
            </GridContainer>
            {/* END Recommended Posts */}
          </div >
          <Footer />
        </div >
        {jobViewModal ? <ResultJobModal open={jobViewModal} onClose={onJobModelClose} /> : null}
      </div >
      {isLoading ? <Backdrop /> : null}
    </React.Fragment >
  );
};

export default UserDashboard;
