import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
	departmentViewRoadmap,
	viewRoadmapRoleByRoleId,
} from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Department_Compliance from "assets/img/Career_Roadmap/Banking_Risk_Compliance/Department_Compliance_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentCompliance() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Compliance;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "c",
				shape: "poly",
				coords: [76,391,64,394,60,398,56,403,54,410,55,451,57,456,60,460,64,464,72,467,178,467,186,464,191,459,194,453,195,448,195,409,193,402,188,397,183,393,177,392,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "470",
				shape: "poly",
				coords: [296,390,287,390,282,394,279,398,278,403,277,452,279,458,282,462,285,466,293,468,484,468,491,465,496,462,498,458,499,452,500,406,498,398,494,394,488,391,480,389,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "471",
				shape: "poly",
				coords: [626,390,617,392,612,396,609,402,608,407,609,456,611,460,614,464,619,467,628,468,815,468,822,466,826,462,829,459,830,452,830,403,828,397,824,394,820,392,814,390,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "472",
				shape: "poly",
				coords: [961,389,947,392,944,395,941,399,939,407,940,452,943,460,946,464,952,467,960,468,1142,467,1152,466,1157,462,1160,458,1162,449,1161,406,1159,398,1155,393,1149,391,1144,390,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "473",
				shape: "poly",
				coords: [1290,390,1280,392,1275,395,1273,400,1271,408,1270,453,1273,459,1276,464,1280,467,1289,468,1473,467,1483,467,1489,462,1491,457,1493,451,1493,404,1489,397,1486,393,1480,391,1474,390,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "474",
				shape: "poly",
				coords: [1617,390,1609,393,1606,395,1603,402,1601,407,1602,454,1604,461,1608,464,1612,467,1619,468,1809,468,1816,466,1820,462,1822,458,1824,453,1823,404,1821,397,1817,393,1812,391,1807,390,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "c") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo);
			dispatch(viewRoadmapRoleByRoleId(requestInfo));

			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
