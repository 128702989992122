import React from 'react';

import { Typography, makeStyles, Button } from '@material-ui/core';

import manageGroupPageStyles from 'assets/jss/material-kit-pro-react/views/pageStyle/ManageGroupPage';
import { Link } from 'react-router-dom';
import { encryptData } from 'configurations/configurations';
import avatar from 'assets/img/candidate_avatar.jpeg';
import { Skeleton } from '@mui/material';
const useStyles = makeStyles(manageGroupPageStyles);

const GroupAdminCard = React.memo(({ groupOwner, isUserOwner, onAddClick, groupIsLoading }) => {
  const styles = useStyles();
  const encryptedAcctId = encryptData(groupOwner?.acctId);
  return (
    <Typography component='div' className={styles.groupAdmin}>
      {!groupIsLoading ? (
        <>
          <Typography component='div' className={styles.groupAdminHeader}>
            <Typography component='p'>Group Admin</Typography>

            {isUserOwner && (
              <Button onClick={onAddClick}>
                <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'>
                  <path
                    id='Path'
                    d='M10,8h7a1,1,0,0,1,0,2H10v7a1,1,0,0,1-2,0V10H1A1,1,0,0,1,1,8H8V1a1,1,0,0,1,2,0Z'
                    fill='#92929d'
                  />
                </svg>
                Add
              </Button>
            )}
          </Typography>

          <Typography component='div' className={styles.groupAdminMain}>
            <Typography component='div' className={styles.groupAdminProfile}>
              <Link to={`/view-profile/${encryptedAcctId}`}>
                <img src={groupOwner?.profilePic ? groupOwner?.profilePic : avatar} alt='' />
              </Link>

              <Typography component='div'>
                <Link to={`/view-profile/${encryptedAcctId}`}>
                  <Typography component='h3' className={styles.groupAdminName}>
                    {`${groupOwner?.firstName} ${groupOwner?.surname}`}
                  </Typography>
                </Link>
                {/* <Typography component='p' className={styles.groupAdminUsername}>
              12 April at 09.28 PM
            </Typography> */}
              </Typography>
            </Typography>
          </Typography>
        </>
      ) : (
        <>
		<Skeleton variant='circular' width={40} height={40}/>
		<Skeleton variant='text' />
		<Skeleton variant='text' />
		</>
      )}
    </Typography>
  );
});
export default GroupAdminCard;
