import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
	departmentViewRoadmap,
	viewRoadmapRoleByRoleId,
} from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Department_Corporate_Finance from "assets/img/Career_Roadmap/Banking_Investment_Banking/Department_Corporate_Finance_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentCorporateFinance() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Corporate_Finance;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "ib",
				shape: "poly",
				coords: [72,394,63,397,59,400,55,406,53,411,53,453,55,458,58,463,62,466,71,469,174,470,182,468,188,464,191,458,194,452,194,412,191,404,186,399,180,395,173,394,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "425",
				shape: "poly",
				coords: [294,392,285,396,281,398,278,404,277,410,277,457,280,463,283,467,288,470,296,472,478,470,488,469,493,466,496,461,498,456,498,410,496,401,492,396,484,392,478,392,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "426",
				shape: "poly",
				coords: [649,394,641,396,639,400,636,404,635,410,636,455,637,462,640,465,644,468,652,470,840,470,848,469,852,465,855,460,857,455,858,409,856,401,851,396,846,393,838,392,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "427",
				shape: "poly",
				coords: [1009,390,1002,392,999,395,996,399,994,406,994,451,996,459,1000,464,1005,466,1012,468,1151,468,1162,464,1166,462,1170,456,1171,450,1170,408,1168,398,1165,394,1159,390,1153,390,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "428",
				shape: "poly",
				coords: [1324,392,1315,396,1311,398,1308,403,1308,409,1308,456,1310,463,1314,468,1320,470,1327,471,1462,471,1475,468,1479,465,1482,462,1484,456,1484,410,1482,401,1479,397,1474,394,1468,393,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "429",
				shape: "poly",
				coords: [1634,393,1628,396,1624,399,1622,403,1620,410,1621,458,1624,463,1627,466,1632,470,1640,470,1782,471,1789,468,1793,464,1796,460,1798,454,1798,410,1796,401,1793,396,1788,394,1781,392,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "ib") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo);
			dispatch(viewRoadmapRoleByRoleId(requestInfo));

			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
