import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { departmentViewRoadmap, viewRoadmapRoleByRoleId } from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Department_Property_Asset_Management from "assets/img/Career_Roadmap/Real_Estate/Department_Property_Asset_Management_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentPropertyAssetManagement() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Property_Asset_Management;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "pam",
				shape: "poly",
				coords: [79,377,73,379,69,382,65,387,62,393,61,453,65,458,68,462,73,466,79,468,169,468,175,468,180,465,184,463,188,457,190,451,191,396,188,388,184,384,177,379,171,377,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "138",
				shape: "poly",
				coords: [219,376,212,378,207,379,204,383,203,387,201,392,201,445,203,452,207,457,213,460,220,462,392,460,399,458,403,457,406,452,408,446,408,392,407,385,402,381,397,378,390,377,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "139",
				shape: "poly",
				coords: [494,376,486,379,482,382,480,385,479,390,479,444,480,449,482,453,487,457,494,459,665,460,675,459,679,456,683,453,685,449,688,444,687,390,685,384,682,380,677,378,671,376,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "140",
				shape: "poly",
				coords: [776,377,766,378,763,381,760,385,757,390,757,447,760,454,765,458,772,460,947,461,954,459,959,455,962,451,964,445,965,394,963,386,960,382,957,379,952,378,947,377,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "141",
				shape: "poly",
				coords: [1051,377,1044,379,1040,381,1037,385,1035,392,1035,445,1037,452,1042,457,1049,460,1223,461,1232,459,1236,457,1241,453,1243,446,1243,394,1241,385,1238,381,1232,378,1226,376,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "142",
				shape: "poly",
				coords: [1332,376,1322,378,1318,382,1315,385,1314,392,1313,440,1313,448,1315,452,1318,456,1323,459,1330,460,1504,460,1511,459,1513,457,1516,455,1519,451,1521,442,1521,393,1519,385,1515,381,1510,378,1505,376,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "143",
				shape: "poly",
				coords: [1639,377,1634,379,1631,382,1628,387,1626,393,1626,446,1628,452,1631,456,1635,459,1641,461,1823,460,1826,458,1828,456,1831,454,1833,451,1834,445,1835,390,1832,384,1829,381,1825,378,1820,377,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "pam") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo)
			dispatch(viewRoadmapRoleByRoleId(requestInfo));
			
			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// console.log(coords.x + "," + coords.y + ",");
		// navigator.clipboard.writeText(coords.x + "," + coords.y + ",");
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
