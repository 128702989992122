import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

// core components
import Button from "components/CustomButtons/Button.js";

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

// import Close from "@material-ui/icons/Close";

import ImageCrop from "../../../Component/ImageCrop/ImageCrop.js";

// import style from "assets/jss/material-kit-pro-react/modalStyle.js";
import IconLibrary from "views/Component/IconLibrary.js";
import corporateProfilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/CorporateProfilePage/corporateProfilePageStyle.js";

const useStyles = makeStyles(corporateProfilePageStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

export const CorporateProfilePictureEditModal = ({
	open,
	onClose,
	userStatus,
	profilePic,
}) => {
	const classes = useStyles();
	return (
		<div>
			<React.Fragment>
				<Dialog
					classes={{
						root: classes.modalRoot,
						paper: classes.modalProfilePicEdit + " " + classes.modal,
					}}
					open={open}
					TransitionComponent={Transition}
					keepMounted
					// disableBackdropClick
					onClose={onClose}
					aria-labelledby="classic-modal-slide-title"
					aria-describedby="classic-modal-slide-description"
				>
					<DialogTitle
						id="classic-modal-slide-title"
						disableTypography
						className={classes.modalTitleEdit}
					>
						<Button
							simple
							className={classes.modalCloseButton}
							key="close"
							aria-label="Close"
							onClick={onClose}
						>
							{" "}
							<IconLibrary.Close />
						</Button>
						<h3 className={classes.title + " " + classes.titleAlign}>
							<b>Edit your profile picture</b>
						</h3>
					</DialogTitle>
					<DialogContent
						id="classic-modal-slide-description"
						className={classes.modalBody}
					>
						<ImageCrop
							pictureSource={profilePic}
							requestRecord={userStatus}
							onClose={onClose}
						/>
					</DialogContent>
					<div className={classes.modalFooter}></div>
				</Dialog>
			</React.Fragment>
		</div>
	);
};

const mapStateToProps = (state) => ({
	userStatus: state.auth.user,
	profilePic: state.corpUser.profilePic,
});

export default connect(mapStateToProps, null)(CorporateProfilePictureEditModal);
