import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { departmentViewRoadmap,viewRoadmapRoleByRoleId } from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";
import ImageMapper from "react-image-mapper";
import Department_Brand_Management from "assets/img/Career_Roadmap/Marketing_Communication/Department_Brand_Management_1.jpg";
import RoleInfoModal from "../RoleInfoModal.js";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentBrandManagement() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Brand_Management;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "bm",
				shape: "poly",
				coords: [
					77,353,161,353,170,355,177,360,181,365,182,369,183,374,183,408,182,413,180,419,175,424,170,427,163,429,158,430,77,430,68,428,60,423,57,418,55,415,54,408,55,372,56,366,59,361,63,357,67,355,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "23",
				shape: "poly",
				coords: [
					205,355,353,356,361,357,367,361,371,369,372,418,368,426,361,431,347,433,207,434,198,430,192,423,191,414,191,373,193,364,196,359,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "24",
				shape: "poly",
				coords: [
					491,356,638,356,646,358,653,363,655,369,655,376,655,419,652,426,646,431,635,433,491,432,483,431,477,425,475,417,474,376,477,366,482,358,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "25",
				shape: "poly",
				coords: [
					769,355,938,354,944,356,951,363,953,374,953,415,951,423,947,428,932,431,769,432,763,432,757,426,752,420,751,373,752,365,758,360,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "26",
				shape: "poly",
				coords: [
					1067,355,1209,355,1219,359,1224,365,1227,372,1225,416,1224,424,1220,429,1210,433,1072,433,1063,431,1057,428,1053,424,1051,377,1053,366,1057,360,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "27",
				shape: "poly",
				coords: [
					1346,356,1505,355,1511,358,1518,363,1520,376,1520,415,1517,423,1514,427,1505,433,1348,433,1338,431,1332,424,1330,413,1330,373,1332,365,1337,360,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "22",
				shape: "poly",
				coords: [
					1670,354,1838,354,1848,356,1857,363,1858,376,1858,413,1854,426,1845,432,1674,431,1658,427,1652,420,1652,371,1654,361,1660,356,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "bm") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo)
			dispatch(viewRoadmapRoleByRoleId(requestInfo));
			
			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// console.log(coords.x + "," + coords.y + ",");
		// navigator.clipboard.writeText(coords.x + "," + coords.y + ",");
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
