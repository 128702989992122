import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CoverImageCrop from "../../../Component/ImageCrop/CoverImageCrop.js";
import CoverPictureTemplateSection from "./CoverPictureTemplateSection.js";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js";
import { Divider } from "@material-ui/core";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
			style={{ backgroundColor: "#f2f3f7" }}
		>
			{value === index && <Box>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

// const useStyles = makeStyles((theme) => ({
// 	root: {
// 		flexGrow: 1,
// 		backgroundColor: theme.palette.background.paper,
// 	},
// }));

const StyledTabs = withStyles((theme) => ({
	indicator: {
		display: "flex",
		justifyContent: "center",
		backgroundColor: "transparent",
		"& > span": {
			width: "100%",
			backgroundColor: "#687BFE",
			margin: "-2px",
		},
	},
}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
	root: {
		textTransform: "none",
		color: "#959595",
		fontWeight: "500",
		fontSize: "16px",
		marginRight: theme.spacing(1),
		"&:focus": {
			color: "#687BFE",
		},
		"&$selected": {
			color: "#687BFE",
		},
	},
	selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles(profilePageStyle);

const CoverPictureTab = ({
	open,
	backgroundPic,
	backgroundPicFileName,
	backgroundTemplateFlag,
	user,
	bizId,
}) => {
	const classes = useStyles();
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	React.useEffect(() => {
		// console.log(backgroundTemplateFlag);
		if (backgroundTemplateFlag === 1 && bizId > 0) setValue(1);
	}, []);

	return (
		<div className={classes.tabStyle}>
			<AppBar position="static">
				<StyledTabs
					value={value}
					onChange={handleChange}
					aria-label="simple tabs example"
				>
					{bizId > 0 && <StyledTab label="Computer" {...a11yProps(0)} />}
					<StyledTab label="Template" {...a11yProps(bizId > 0 ? 1 : 0)} />
				</StyledTabs>
			</AppBar>
			<Divider />
			{bizId > 0 && (
				<TabPanel value={value} index={0}>
					<CoverImageCrop
						open={open}
						pictureSource={backgroundPic}
						backgroundTemplateFlag={backgroundTemplateFlag}
						user={user}
					/>
				</TabPanel>
			)}
			<TabPanel value={value} index={bizId > 0 ? 1 : 0}>
				<CoverPictureTemplateSection
					open={open}
					backgroundPic={backgroundPic}
					backgroundPicFileName={backgroundPicFileName}
					backgroundTemplateFlag={backgroundTemplateFlag}
					user={user}
				/>
			</TabPanel>
		</div>
	);
};
export default CoverPictureTab;
