import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { departmentViewRoadmap, viewRoadmapRoleByRoleId } from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";
import ImageMapper from "react-image-mapper";
import Department_Event_Management from "assets/img/Career_Roadmap/Hospitality/Department_Event_Management_1.jpg";
import RoleInfoModal from "../RoleInfoModal.js";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentEventManagement() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Event_Management;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "em",
				shape: "poly",
				coords: [82,202,75,204,70,208,65,212,63,217,62,224,62,622,63,628,65,635,68,639,72,643,80,647,167,645,175,645,181,642,185,638,189,632,192,626,191,229,189,216,188,210,183,207,179,203,172,202,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "94",
				shape: "poly",
				coords: [228,200,219,202,216,204,213,207,210,213,209,218,210,281,211,286,213,289,216,293,220,295,225,296,381,296,390,294,394,292,397,289,400,283,400,277,400,216,399,209,397,207,393,203,389,202,385,201,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "90",
				shape: "poly",
				coords: [230,374,220,376,215,379,211,382,209,386,208,391,208,453,209,460,210,463,213,466,218,470,223,470,383,471,390,469,393,466,396,463,398,459,400,453,399,393,398,386,395,382,393,379,389,377,385,376,382,376,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "86",
				shape: "poly",
				coords: [227,550,218,551,214,554,212,557,210,562,208,568,208,633,210,640,213,642,218,646,226,647,381,647,390,645,394,643,397,638,399,631,399,564,397,559,393,555,388,551,380,551,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "95",
				shape: "poly",
				coords: [585,201,576,201,573,205,569,209,567,217,567,281,568,287,572,291,577,296,583,297,738,296,747,296,751,293,755,289,758,284,758,278,758,219,756,211,755,207,750,204,745,201,740,201,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "91",
				shape: "poly",
				coords: [586,375,576,376,572,379,568,382,565,387,565,394,565,453,566,460,567,465,570,467,573,470,580,471,740,472,746,470,750,467,754,463,756,457,756,394,756,386,753,383,750,380,746,377,741,376,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "87",
				shape: "poly",
				coords: [591,550,580,551,573,553,569,558,567,563,566,570,566,632,568,637,571,642,576,645,582,646,734,646,746,645,751,641,755,636,756,631,756,569,756,562,754,558,750,554,745,552,739,551,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "96",
				shape: "poly",
				coords: [951,196,941,198,936,200,932,202,929,207,927,213,927,276,929,283,932,287,937,291,943,293,1104,293,1111,290,1113,288,1117,283,1119,278,1118,214,1118,207,1115,203,1111,199,1106,198,1101,198,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "92",
				shape: "poly",
				coords: [953,372,941,372,934,374,930,378,928,382,926,389,926,453,928,459,931,463,935,467,942,468,1095,469,1103,468,1107,466,1112,463,1116,458,1118,452,1117,392,1117,384,1115,380,1112,377,1108,374,1103,373,1098,372,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "88",
				shape: "poly",
				coords: [945,549,937,550,933,552,930,556,928,560,927,567,927,626,928,634,930,638,933,641,937,644,945,644,1095,644,1105,643,1111,640,1115,636,1117,629,1117,563,1115,558,1112,553,1107,550,1102,549,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "97",
				shape: "poly",
				coords: [1303,198,1296,199,1291,201,1288,203,1285,207,1283,213,1284,281,1286,285,1289,289,1294,292,1299,293,1459,293,1464,291,1468,289,1473,285,1475,278,1474,213,1473,206,1471,203,1468,201,1465,198,1460,198,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "93",
				shape: "poly",
				coords: [1301,372,1293,374,1287,378,1285,382,1282,389,1283,452,1284,458,1286,461,1290,465,1296,467,1457,468,1464,466,1469,463,1471,460,1474,451,1473,390,1471,382,1469,378,1465,375,1461,372,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "89",
				shape: "poly",
				coords: [1297,549,1292,550,1286,554,1283,560,1283,566,1283,628,1282,630,1283,635,1286,638,1290,642,1296,644,1455,644,1463,643,1466,640,1470,638,1472,634,1473,627,1473,565,1472,560,1470,555,1467,552,1463,550,1459,548,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "98",
				shape: "poly",
				coords: [1662,367,1650,368,1643,370,1640,376,1637,383,1636,458,1638,465,1641,469,1645,473,1653,475,1828,475,1837,473,1843,471,1846,466,1849,460,1848,388,1848,380,1846,375,1843,371,1837,368,1832,368,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "em") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo)
			dispatch(viewRoadmapRoleByRoleId(requestInfo));
			
			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// console.log(coords.x + "," + coords.y + ",");
		// navigator.clipboard.writeText(coords.x + "," + coords.y + ",");
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}

	function enterArea(area) {
		console.log(area);
	}

	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
				onMouseEnter={(area) => enterArea(area)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
