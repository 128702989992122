import { useDispatch } from "react-redux";
import React from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from '@material-ui/core'

// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.js";

import style from "assets/jss/material-kit-pro-react/modalStyle.js";
import IconLibrary from "views/Component/IconLibrary";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

export default function CustomAlertDialog(children) {
	const useStyles = makeStyles(style);
	const classes = useStyles();
	const { confirmDialog, setConfirmDialog } = children;
	return (
		<React.Fragment>
			<Dialog
				classes={{
					root: classes.modalRoot,
					paper: classes.modalLoader,
				}}
				// height: confirmDialog.dialogDefault.report ? "fit-content" : "250px"
				className={classes.modalIndex}
				open={confirmDialog.isOpen}
				TransitionComponent={Transition}
				keepMounted
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle
					id="alert-dialog-slide-title"
					disableTypography
					className={classes.modalHeader}
				>
					{/* <Button
						simple
						className={classes.modalCloseButton}
						key="close"
						aria-label="Close"
						onClick={() =>
							setConfirmDialog({ ...confirmDialog, isOpen: false })
						}
					>
						{" "}
						<IconLibrary.Close />
					</Button> */}
					<h4 style={confirmDialog.dialogDefault.center ? {
						textAlign: "center",
						padding: "0px 80px",
						margin: "0px"
					} : {}}>
						<b>{confirmDialog.dialogDefault.title}</b>
					</h4>
				</DialogTitle>
				<DialogContent
					id="classic-modal-slide-description"
					className={classes.modalBody}
					style={confirmDialog.dialogDefault.center ? {
						textAlign: "center",
					} : {}}
				>
					{confirmDialog.dialogDefault.report &&
						<>
						<DialogContentText id="alert-dialog-slide-description" style={confirmDialog.dialogDefault.center ? {
							padding: "0px 80px",
							margin: "0px"
						} : {}}>
						</DialogContentText>
							<FormControl>
								<FormLabel id="demo-radio-buttons-group-label">{confirmDialog.dialogDefault.question}</FormLabel>
								<RadioGroup
									aria-labelledby="demo-radio-buttons-group-label"
									defaultValue="Spam"
									name="radio-buttons-group"
								>
									<FormControlLabel value="Spam" control={<Radio />} label="This account is spam" />
									<FormControlLabel value="Hate Speech" control={<Radio />} label="Hate Speech" />
									<FormControlLabel value="False Information" control={<Radio />} label="False Information" />
									<FormControlLabel value="Intellectual Property Violation" control={<Radio />} label="Intellectual property violation" />
									<FormControlLabel value="Fraud" control={<Radio />} label="The Account is fraudalent" />
								</RadioGroup>
							</FormControl>
					</>
					}
					{confirmDialog.dialogDefault.description &&
						<DialogContentText id="alert-dialog-slide-description" style={confirmDialog.dialogDefault.center ? {
							padding: "0px 80px",
							margin: "0px"
						} : {}}>
							{confirmDialog.dialogDefault.description}
						</DialogContentText>}
					{confirmDialog.dialogDefault.textInput && <TextField
						style={{ width: "100%" }}
						id="standard-textarea"
						label="Personalise Invitation"
						placeholder="Your message"
						multiline
						variant="standard"
					/>}
				</DialogContent>
				<DialogActions className={classes.modalFooter} style={{ justifyContent: confirmDialog.dialogDefault.center ? "center" : "flex-end" }}>
					<Button onClick={confirmDialog.dialogDefault.handleLeftButton} color="opptyWrapBorder" style={{ border: "none", margin: "10px", boxShadow: "none" }} round>
						{confirmDialog.dialogDefault.buttonLeft}
					</Button>
					{confirmDialog.dialogDefault.buttonRight !== "" ? (
						confirmDialog.onCancel ? (
							<Button
								onClick={confirmDialog.dialogDefault.handleRightButton}
								color="opptyWrap"
								round
							>
								{confirmDialog.dialogDefault.buttonRight}
							</Button>
						) : (
							<Button
								onClick={confirmDialog.dialogDefault.handleRightButton}
								color="opptyWrap"
								round
							>
								{confirmDialog.dialogDefault.buttonRight}
							</Button>
						)
					) : null}
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}
