const groupCategoryCardStyles = () => ({

  main: {
    position: "relative",
    width: "20%",
    height: "230px",
  },

  header: {
    overflow: "hidden",
    borderRadius: "15px",
    width: "100%",
    height: "175px",

    '& > img': {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },

  content: {
    boxShadow: "0 5px 20px -10px #b5b5be",
    position: "absolute",
    bottom: 0,
    left: "50%",
    transform: "translate(-50%, 0)",
    borderRadius: "15px",
    backgroundColor: "#E9F3FB",
    padding: "20px 0",
    width: "130px",
    height: "125px",
    textAlign: "center",
  },

  icon: {

  },

  name: {
    marginTop: "15px",
    color: "#44444F",
    fontSize: "12px",
  },

})

export default groupCategoryCardStyles;