import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import { Button, List, ListItem, Popover, Typography } from "@material-ui/core";
import ActionButtonsStyles from "assets/jss/material-kit-pro-react/views/pageStyle/ProfileViewPage/ActionButtonsStyles";
import BlockIcon from "assets/svg/block_icon.svg";
import ReportIcon from "assets/svg/report_icon.svg";
import BookmarkIcon from "assets/svg/bookmark_icon.svg";
import SubmenuIcon from "assets/svg/submenu_icon.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import {
	activateEditType,
	deactivateEditType,
} from "configurations/redux/editProfileModalSlice";
import {
	hashKey,
	decryptData,
	instanceUrl,
	header,
} from "configurations/configurations.js";
import Fade from "@material-ui/core/Fade";
import { updateConnect } from "configurations/redux/userSlice.js";
import ListItemIcon from "@mui/material/ListItemIcon";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { createReport, resetReport } from "configurations/redux/reportSlice.js";
import {
	createChatroom,
	createChatroomReset,
} from "configurations/redux/messageChatroomSlice.js";

const useStyles = makeStyles(ActionButtonsStyles);

const ActionButtons = ({
	setIsCannotMessageModalOpen,
	handleConnect,
	handleMessage,
	connectDialog,
	setConnectDialog,
	cancelConnectionDialog,
	setConnectedDialog,
	connectedDialog,
	setCancelConnectionDialog,
	blockDialog,
	setBlockDialog,
	reportDialog,
	setReportDialog,
	handleFollowProfile,
	handleUnfollowProfile,
	match,
}) => {
	const styles = useStyles();
	const history = useHistory();
	const [anchorEl, setAnchorEl] = useState(null);
	const dispatch = useDispatch();
	const connId = useSelector((state) => state.user.connId);
	const followCount = useSelector((state) => state.user.followCount);
	const blockCount = useSelector((state) => state.user.blockCount);
	const cancelCount = useSelector((state) => state.user.cancelCount);
	const connReceiverAcctId = useSelector(
		(state) => state.user.connReceiverAcctId
	);
	const userStatus = useSelector((state) => state.auth.user);
	const profileId = useSelector((state) => state.user.acctId);
	const profile = useSelector((state) => state.user);
	const bizId = useSelector((state) => state.business.bizId);

	useEffect(() => {}, []);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const reportPost = (reportTypeId, reasonId, reportDesc, recordId) => {
		var requestProfile = {
			email: userStatus.email,
			acctId: userStatus.acctId,
			reportTypeId: reportTypeId,
			recordId: recordId,
			reasonId: reasonId,
			reportDesc: reportDesc,
			userId: null,
			accessToken: localStorage.getItem("token"),
		};
		dispatch(createReport(requestProfile));
	};

	const getMessageNavigate = async (item) => {
		var chatroomList = [];
		chatroomList.push({ chatroomListId: -1, acctId: userStatus.acctId });
		chatroomList.push({ chatroomListId: -1, acctId: item });
		const messageApplicant = {
			acctId: userStatus.acctId,
			accessToken: localStorage.getItem("token"),
			email: userStatus.email,
			chatroomTitle:
				bizId > 0
					? "business_" + item + "_" + bizId
					: "private_" + item + "_" + userStatus.acctId,
			chatroomList: chatroomList,
			receiverId: item,
			bizId: bizId > 0 ? bizId : 0,
			receiverBizId: 0,
			typeId: bizId > 0 ? 2 : 1,
		};
		console.log(messageApplicant);
		dispatch(createChatroom(messageApplicant));
	};
	const chatroomId = useSelector((state) => state.messageChatroom.chatroomId);
	const isCreated = useSelector((state) => state.messageChatroom.isCreated);
	React.useEffect(() => {
		console.log(chatroomId);
		if (isCreated && chatroomId > 0) {
			window.open("/message-page/0/C" + chatroomId + "/", "_blank");
			// history.push("/message-page/C" + chatroomId + "/");
		}
		dispatch(createChatroomReset());
	}, [isCreated]);

	const connStatusId = useSelector((state) => state.user.connStatusId);
	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;
	return (
		<div className={styles.actionButtons}>
			<div style={{ display: "flex" }}>
				{connStatusId === null ? (
					<Button
						className={styles.connectButton}
						variant="outlined"
						disabled={blockCount > 0 || cancelCount > 0}
						/* onClick={() => setConnectDialog({ ...connectDialog, isOpen: true })} */
						onClick={() => {
							const response = {
								type: "CONNECTION_REQUEST",
								extension: {
									receiverAcctId: decryptData(match.params.acctId),
								},
							};

							dispatch(activateEditType(response));
						}}
					>
						Connect
					</Button>
				) : connReceiverAcctId === profileId && connStatusId !== 1 ? (
					<Button
						className={styles.pendingButton}
						variant="contained"
						onClick={() => {
							const response = {
								type: "CANCEL_CONNECTION_REQUEST",
								extension: {
									connId: connId,
									connStatusId: connStatusId,
									receiverAcctId: decryptData(match.params.acctId),
								},
							};

							dispatch(activateEditType(response));
						}}
					>
						Pending
					</Button>
				) : connStatusId === 1 ? (
					<Button
						className={styles.connectedButton}
						variant="outlined"
						/* onClick={() =>
						setConnectedDialog({ ...connectedDialog, isOpen: true })
					} */
						onClick={() => {
							const response = {
								type: "REMOVE_CONNECTION",
								extension: {
									connId: connId,
									connStatusId: null,
									receiverAcctId: decryptData(match.params.acctId),
								},
							};

							dispatch(activateEditType(response));
						}}
					>
						Connected
					</Button>
				) : (
					<Button
						className={styles.pendingButton}
						variant="contained"
						onClick={() => {
							const requestProfile = {
								receiverAcctId: profileId,
								acctId: userStatus.acctId,
								email: userStatus.email,
								connMessageDesc: "",
								connId: connId,
								connStatusId: 1,

								accessToken: localStorage.getItem("token"),
							};
							console.log(requestProfile);
							dispatch(updateConnect(requestProfile));
						}}
					>
						Accept
					</Button>
				)}

				<Button
					variant="outlined"
					className={styles.messageButton}
					onClick={() => {
						if ((blockCount == 0 && connStatusId == 1) || bizId > 0) {
							getMessageNavigate(profileId);
						} else {
							setIsCannotMessageModalOpen(true);
						}
					}}
				>
					Message
				</Button>
			</div>

			<div>
				<IconButton onClick={handleClick}>
					<MoreVertIcon />
				</IconButton>
				<Menu
					TransitionComponent={Fade}
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					PaperProps={{
						elevation: 0,
						sx: {
							overflow: "visible",
							filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
							mt: 1.5,
							"& .MuiAvatar-root": {
								width: 32,
								height: 32,
								ml: -0.5,
								mr: 1,
							},
							"&:before": {
								content: '""',
								display: "block",
								position: "absolute",
								top: 0,
								right: 14,
								width: 10,
								height: 10,
								bgcolor: "background.paper",
								transform: "translateY(-50%) rotate(45deg)",
								zIndex: 0,
							},
						},
					}}
				>
					{followCount <= 0 ? (
						<MenuItem
							className={styles.menuItem}
							onClick={() => {
								handleClose();
								const response = {
									type: "FOLLOW_USER",
									extension: {
										acctId: decryptData(match.params.acctId),
										name: profile.profileRecord.firstName,
										followId: profile.followId,
									},
								};

								dispatch(activateEditType(response));
							}}
						>
							<ListItemIcon>
								<AddCircleOutlineIcon fontSize="small" />
							</ListItemIcon>
							Follow User
						</MenuItem>
					) : (
						<MenuItem
							className={styles.menuItem}
							onClick={() => {
								handleClose();
								const response = {
									type: "UNFOLLOW_USER",
									extension: {
										acctId: decryptData(match.params.acctId),
										name: profile.profileRecord.firstName,
										followId: profile.followId,
									},
								};

								dispatch(activateEditType(response));
							}}
						>
							<ListItemIcon>
								<RemoveCircleOutlineIcon fontSize="small" />
							</ListItemIcon>
							Unfollow User
						</MenuItem>
					)}

					<MenuItem
						className={styles.menuItem}
						onClick={() => {
							handleClose();
							const response = {
								type: "REPORT_USER",
								extension: {
									acctId: decryptData(match.params.acctId),
								},
							};

							dispatch(activateEditType(response));
						}}
					>
						<ListItemIcon>
							<ErrorOutlineIcon fontSize="small" />
						</ListItemIcon>
						Report User
					</MenuItem>
					<MenuItem
						className={styles.menuItem}
						onClick={() => {
							handleClose();
							const response = {
								type: "BLOCK_USER",
								extension: {
									blockAcctId: decryptData(match.params.acctId),
									name: profile.profileRecord.firstName,
								},
							};

							dispatch(activateEditType(response));
						}}
					>
						<ListItemIcon>
							<HighlightOffIcon fontSize="small" />
						</ListItemIcon>
						Block User
					</MenuItem>
				</Menu>
			</div>
		</div>
	);
};

export default ActionButtons;
