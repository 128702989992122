import { createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import {
	instanceUrl,
	header,
	headerFormData,
	emptyCache,
} from "configurations/configurations.js";

const attachmentUserSlice = createSlice({
	name: "attachmentUserSlice",
	initialState: {
		userAttachmentList: null,
		isUpdated: false,
		isLoading: false,
		isError: true,
		isDeleted: false,
		documentError: null,
		sharedAttachmentList: null,
	},
	reducers: {
		ATTACHMENT_LOADING: (state) => {
			state.isLoading = true;
		},
		ATTACHMENT_GET_SUCCESS: (state, action) => {
			state.isLoading = false;
			state.isUpdated = true;
			state.userAttachmentList = action.payload.userAttachmentList;
			state.isDeleted = false;
			localStorage.setItem("token", action.payload.token);
		},
		VIDEO_ATTACHMENT_GET_SUCCESS: (state, action) => {
			state.isLoading = false;
			state.isUpdated = true;
			state.userAttachmentList = state.userAttachmentList.filter((item) => {
				return (item.attachmentTypeId === 5);
			});
			// state.userAttachmentList = state.userAttachmentList.filter((item) => {
			// 	return item.attachmentPath.includes(".mp4");
			// });
			state.isDeleted = false;
			localStorage.setItem("token", action.payload.token);
		},
		DOCUMENT_ATTACHMENT_GET_SUCCESS: (state, action) => {
			console.log('DOCUMENT_ATTACHMENT_GET_SUCCESS');
			state.isLoading = false;
			state.isUpdated = true;
			state.documentError = null;
			state.userAttachmentList = state.userAttachmentList.filter((item) => {
				console.log('item.attachmentTypeId');
				console.log(item.attachmentTypeId);
				return (item.attachmentTypeId === 1);
			});
			// state.userAttachmentList = state.userAttachmentList.filter((item) => {
			// 	return item.attachmentPath.includes(".mp4");
			// });
			state.isDeleted = false;
			localStorage.setItem("token", action.payload.token);
		},
		VIDEO_ATTACHMENT_LIMIT_ERROR: (state, action) => {
			state.isLoading = false;
			state.isUpdated = true;
			state.userAttachmentList = state.userAttachmentList.filter((item) => {
				return (item.attachmentTypeId === 5);
			});
			// state.userAttachmentList = state.userAttachmentList.filter((item) => {
			// 	return item.attachmentPath.includes(".mp4");
			// });
			state.isDeleted = false;
			localStorage.setItem("token", action.payload.token);
			localStorage.setItem("limitError", 'Sorry! You can upload video maximum 3 times.');
		},
		ATTACHMENT_FAIL: (state, action) => {
			state.isLoading = false;
			state.isUpdated = false;
		},
		DOCUMENT_FAIL: (state, action) => {
			console.log('DOCUMENT_FAIL');

			console.log(action);
			state.isLoading = false;
			state.isUpdated = false;
			state.documentError = action.payload.documentError;
			localStorage.setItem("documentError", action.payload.documentError);
		},
		ATTACHMENT_CREATE_SUCCESS: (state, action) => {
			state.isLoading = false;
			state.isUpdated = true;
			state.userAttachmentList = action.payload.userAttachmentList;
			state.isDeleted = false;
			localStorage.setItem("token", action.payload.token);
		},
		ATTACHMENT_DELETE_SUCCESS: (state, action) => {
			state.isLoading = false;
			state.isUpdated = true;
			state.userAttachmentList = state.userAttachmentList.filter((item) => {
				return item.attachmentId !== action.payload.attachmentId;
			});
			state.isDeleted = true;
			localStorage.setItem("token", action.payload.token);
		},
		ATTACHMENT_VISIBILITY_SUCCESS: (state, action) => {
			state.isLoading = false;
			state.isUpdated = true;
			console.log('state.userAttachmentList');
			//console.log(action);
			state.userAttachmentList = state.userAttachmentList.map((item) => {
				console.log(item.title + '===' + action.payload.daata.title);
				if (item.title == action.payload.daata.title) {
					item.isVisible = action.payload.daata.isVisible;
				}

				return item;
			});
			/* state.userAttachmentList = state.userAttachmentList.filter((item) => {
				//return item.attachmentId !== action.payload.attachmentId;
				console.log(item.title);
			}); */



			/* 	let indexing=0;
				state.userAttachmentList = state.userAttachmentList.filter((item,index) => {
					console.log('indexing='+index);
					//console.log( );
					return item.attachmentId;
					indexing++
				}); */
			state.isDeleted = false;
			localStorage.setItem("token", action.payload.token);
		},

		ATTACHMENT_SHARELINK_SUCCESS: (state, action) => {
			state.isLoading = false;
			state.isUpdated = true;

			state.isDeleted = false;
			localStorage.setItem("token", action.payload.token);
		},
		SHARE_ATTACHMENT_LIST_SUCESS: (state, action) => {
			console.log('SHARE_ATTACHMENT_LIST_SUCESS');
			console.log(action);
			state.isLoading = false;
			state.isUpdated = true;
			state.sharedAttachmentList = action.payload.sharedAttachmentList;

		},
	},
});

const {
	ATTACHMENT_CREATE_SUCCESS,
	ATTACHMENT_FAIL,
	DOCUMENT_FAIL,
	ATTACHMENT_GET_SUCCESS,
	VIDEO_ATTACHMENT_GET_SUCCESS,
	DOCUMENT_ATTACHMENT_GET_SUCCESS,
	VIDEO_ATTACHMENT_LIMIT_ERROR,
	ATTACHMENT_LOADING,
	ATTACHMENT_DELETE_SUCCESS,
	ATTACHMENT_VISIBILITY_SUCCESS,
	ATTACHMENT_SHARELINK_SUCCESS,
	SHARE_ATTACHMENT_LIST_SUCESS,
} = attachmentUserSlice.actions;

export const createAttachment = (data) => async (dispatch) => {
	dispatch(ATTACHMENT_LOADING());

	await axios({
		method: "POST",
		headers: headerFormData,
		url: `${instanceUrl}/api/um/create-user-attachment`,
		data: data,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				const response = {
					userAttachmentList:
						res.data.payload.user.attachmentList.userAttachmentList,
					token: res.data.token,
				};
				dispatch(ATTACHMENT_CREATE_SUCCESS(response));
				dispatch(VIDEO_ATTACHMENT_GET_SUCCESS(response));

			} else {
				dispatch(ATTACHMENT_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			dispatch(ATTACHMENT_FAIL());

			console.log(err);
		});
};


export const createAttachmentVideo = (data, cb) => async (dispatch) => {
	dispatch(ATTACHMENT_LOADING());

	await axios({
		method: "POST",
		headers: headerFormData,
		url: `${instanceUrl}/api/um/create-user-attachment`,
		data: data,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				const response = {
					userAttachmentList: res.data.payload.user.attachmentList.userAttachmentList,
					status: res.status,

					token: res.data.token,
				};
				cb(response)
				dispatch(ATTACHMENT_CREATE_SUCCESS(response));
				dispatch(VIDEO_ATTACHMENT_GET_SUCCESS(response));

			} else {
				const response = {
					//userAttachmentList:res.data.payload.user.attachmentList.userAttachmentList,
					message: res.message,
					token: res.data.token,
					status: res.status,
					uploadedCount: res.data.payload.uploadedCount,
				};
				cb(response);
				dispatch(ATTACHMENT_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			dispatch(ATTACHMENT_FAIL());

			console.log(err);
		});
};

export const updateAttachment = (data, cb) => async (dispatch) => {
	dispatch(ATTACHMENT_LOADING());

	await axios({
		method: "PUT",
		headers: headerFormData,
		url: `${instanceUrl}/api/um/update-user-attachment`,
		data: data,
	})
		.then((res) => {
			console.log("res", res);


			if (res.status === 201) {
				const response = {
					userAttachmentList:
						res.data.payload.user.attachmentList.userAttachmentList,
					token: res.data.token,
					status: res.status,
				};
				cb(response);
				dispatch(VIDEO_ATTACHMENT_GET_SUCCESS(response));
			} else {

				console.log('attachment res');
				console.log(res);
				const response = {
					//userAttachmentList:res.data.payload.user.attachmentList.userAttachmentList,
					message: res.message,
					token: res.data.token,
					status: res.status,
					uploadedCount: res.data.payload.uploadedCount,
				};
				cb(response);
				dispatch(ATTACHMENT_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			dispatch(ATTACHMENT_FAIL());

			console.log(err);
		});
};
export const getAttachment = ({
	accessToken,
	acctId,
	email,
	keyword,
	lastRecordSize,
	requestRecordSize,
	optionId,
}) => async (dispatch) => {
	dispatch(ATTACHMENT_LOADING());
	const body = {
		acctId,
		accessToken,
		email,
		keyword,
		lastRecordSize,
		requestRecordSize,
		optionId,
	};
	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/um/get-user-attachment-by-acct-id`,
		params: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				console.log('redux');
				console.log(res.data);

				const response = {

					userAttachmentList: res.data.payload.userAttachmentList,
					token: res.data.token,
				};
				dispatch(ATTACHMENT_GET_SUCCESS(response));
				dispatch(VIDEO_ATTACHMENT_GET_SUCCESS(response));
			} else {
				dispatch(ATTACHMENT_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			dispatch(ATTACHMENT_FAIL());

			console.log(err);
		});
};
export const deleteAttachment = ({
	accessToken,
	acctId,
	email,
	attachmentId,
}) => async (dispatch) => {
	dispatch(ATTACHMENT_LOADING());
	const body = {
		acctId,
		accessToken,
		email,
		attachmentId,
	};
	await axios({
		method: "PUT",
		headers: header,
		url: `${instanceUrl}/api/um/delete-user-attachment`,
		data: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				const response = {
					attachmentId: res.data.payload.attachmentId,
					token: res.data.token,
				};
				dispatch(ATTACHMENT_DELETE_SUCCESS(response));
			} else {
				dispatch(ATTACHMENT_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			dispatch(ATTACHMENT_FAIL());

			console.log(err);
		});
};

export const updateAttachmentVisibility = ({
	accessToken,
	acctId,
	email,
	attachmentId,
	isVisible
}) => async (dispatch) => {
	dispatch(ATTACHMENT_LOADING());
	const body = {
		acctId,
		accessToken,
		email,
		attachmentId,
		isVisible
	};
	await axios({
		method: "PUT",
		headers: header,
		url: `${instanceUrl}/api/um/update-user-attachment-visibility`,
		data: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				console.log('res');
				console.log(res);
				const response = {
					daata: res.data.payload,
					token: res.data.token,
				};
				dispatch(ATTACHMENT_VISIBILITY_SUCCESS(response));
			} else {
				dispatch(ATTACHMENT_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			dispatch(ATTACHMENT_FAIL());

			console.log(err);
		});
};

export const createShareLink = ({
	accessToken,
	acctId,
	email,
	attachmentIds,
	expiryDay

}, cb) => async (dispatch) => {
	dispatch(ATTACHMENT_LOADING());
	const body = {
		acctId,
		accessToken,
		email,
		attachmentIds,
		expiryDay
	};
	await axios({
		method: "POST",
		headers: header,
		url: `${instanceUrl}/api/um/create-user-shared-attachment`,
		data: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				const response = {
					attachmentId: res.data.payload.attachmentId,
					recId: res.data.payload.recId,
					token: res.data.token,
				};
				dispatch(ATTACHMENT_SHARELINK_SUCCESS(response));

				cb(response);
			} else {
				dispatch(ATTACHMENT_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			dispatch(ATTACHMENT_FAIL());

			console.log(err);
		});
};



export const createDocumentAttachment = (data, cb) => async (dispatch) => {
	dispatch(ATTACHMENT_LOADING());

	await axios({
		method: "POST",
		headers: headerFormData,
		url: `${instanceUrl}/api/um/create-user-attachment`,
		data: data,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				const response = {
					userAttachmentList:
						res.data.payload.user.attachmentList.userAttachmentList,
					token: res.data.token,
					status: res.status
				};
				cb(response);
				//dispatch(ATTACHMENT_CREATE_SUCCESS(response));
				//dispatch(VIDEO_ATTACHMENT_GET_SUCCESS(response));
				//	dispatch(DOCUMENT_ATTACHMENT_GET_SUCCESS(response));
				console.log('err0');
			} else {
				console.log('err1');
				console.log(res)
				const response = {
					documentError:
						res.data.payload,
					status: res.status

				};
				cb(response);
				//	dispatch(DOCUMENT_FAIL(response));
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			dispatch(ATTACHMENT_FAIL());
			console.log('err');

			console.log(err);
		});
};


export const getUserSharedAttachmentByFolderId = (data) => async (dispatch) => {
	dispatch(ATTACHMENT_LOADING());

	await axios({
		method: "GET",
		headers: headerFormData,
		url: `${instanceUrl}/api/um/get-user-shared-attachment-by-folder-id`,
		params: data,
	})
		.then((res) => {
			console.log('------res-------');
			console.log(res);
			if (res.status === 201) {
				const response = {
					sharedAttachmentList:
						res.data.payload.sharedAttachmentList,
					token: res.data.token,
					status: res.status
				};

				dispatch(SHARE_ATTACHMENT_LIST_SUCESS(response));
				//dispatch(VIDEO_ATTACHMENT_GET_SUCCESS(response));
				//	dispatch(DOCUMENT_ATTACHMENT_GET_SUCCESS(response));
				console.log('err0');
			} else {
				console.log('err1');
				console.log(res)
				const response = {
					documentError:
						res.data.payload,
					status: res.status

				};

				//	dispatch(DOCUMENT_FAIL(response));
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			dispatch(ATTACHMENT_FAIL());
			console.log('err');

			console.log(err);
		});
};


export const getDocument = ({
	accessToken,
	acctId,
	email,
	keyword,
	lastRecordSize,
	requestRecordSize,
	optionId,
}) => async (dispatch) => {
	dispatch(ATTACHMENT_LOADING());
	const body = {
		acctId,
		accessToken,
		email,
		keyword,
		lastRecordSize,
		requestRecordSize,
		optionId,
	};
	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/um/get-user-attachment-by-acct-id`,
		params: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				console.log('redux');
				console.log(res.data);

				const response = {

					userAttachmentList: res.data.payload.userAttachmentList,
					token: res.data.token,
				};
				dispatch(ATTACHMENT_GET_SUCCESS(response));
				dispatch(DOCUMENT_ATTACHMENT_GET_SUCCESS(response));
			} else {
				dispatch(ATTACHMENT_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			dispatch(ATTACHMENT_FAIL());

			console.log(err);
		});
};


export const getVideo = ({
	accessToken,
	acctId,
	email,
	keyword,
	lastRecordSize,
	requestRecordSize,
	optionId,
}) => async (dispatch) => {
	dispatch(ATTACHMENT_LOADING());
	const body = {
		acctId,
		accessToken,
		email,
		keyword,
		lastRecordSize,
		requestRecordSize,
		optionId,
	};
	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/um/get-user-attachment-by-acct-id`,
		params: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				console.log('redux');
				console.log(res.data);

				const response = {

					userAttachmentList: res.data.payload.userAttachmentList,
					token: res.data.token,
				};
				dispatch(ATTACHMENT_GET_SUCCESS(response));
				dispatch(VIDEO_ATTACHMENT_GET_SUCCESS(response));
			} else {
				dispatch(ATTACHMENT_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			dispatch(ATTACHMENT_FAIL());

			console.log(err);
		});
};

/* export const createShareLink = ({
	accessToken,
	acctId,
	email,
	attachmentId,

	expiryDay
}) => async (dispatch) => {
	dispatch(ATTACHMENT_LOADING());
	const body = {
		acctId,
		accessToken,
		email,
		attachmentId,
		
		expiryDay
	};
	await axios({
		method: "POST",
		headers: header,
		url: `${instanceUrl}/api/um/create-user-shared-attachment`,
		data: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				const response = {
					attachmentId: res.data.payload.attachmentId,
					token: res.data.token,
				};
				dispatch(ATTACHMENT_GET_SUCCESS(response));
			} else {
				dispatch(ATTACHMENT_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			dispatch(ATTACHMENT_FAIL());

			console.log(err);
		});
};
 */

export default attachmentUserSlice.reducer;
