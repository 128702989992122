import { mlAuto, mrAuto } from "assets/jss/material-kit-pro-react.js";

const paginationStyle = (theme) => ({
	mlAuto,
	mrAuto,

	paginationContainer: {
		paddingTop: "20px",
		paddingBottom: "25px",
		...mlAuto,
		...mrAuto,
		[theme.breakpoints.down("xs")]: {
			paddingTop: "10px",
			paddingBottom: "0px",
			zoom: "80%",
		},
	},
});

export default paginationStyle;
