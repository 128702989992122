import React, { useState, useEffect } from "react";

import { Typography, makeStyles, Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import axios from "axios";

import {
	decryptData,
	getCountryId,
	getLocationId,
	getEducationLevelId,
	getSchoolId,
	getFieldStudyId,
	getIndustryId,
	instanceUrl,
} from "configurations/configurations";

import profileViewAboutMeStyles from "../../../assets/jss/material-kit-pro-react/views/componentStyle/profileView/profileViewAboutMeStyles.js";

const ProfileViewAboutMe = (props) => {
	const styles = makeStyles(profileViewAboutMeStyles)();
	const { onEdit } = props;
	{
		/** Jin Started from  */
	}
	const isViewed = useSelector((state) => state.user.isViewed);
	const profileRecord = useSelector((state) => state.user.profileRecord);
	const privacySetting = useSelector((state) => state.user.privacySetting);
	const educationRecords = useSelector((state) => state.user.educationRecords);
	const experienceRecords = useSelector(
		(state) => state.user.experienceRecords
	);

	const [countryList, setCountryList] = React.useState([]);
	const [locationList, setLocationList] = React.useState([]);
	const [educationLevelList, setEducationLevelList] = React.useState([]);

	const educationRecord =
		educationRecords && educationRecords[educationRecords.length - 1];
	const experienceRecord =
		experienceRecords && experienceRecords[experienceRecords.length - 1];

	const showProfile =
		privacySetting &&
		privacySetting
			.filter((item) => {
				return item.settingName === "showProfile";
			})
			.map((item) => {
				return item.settingValue === 1;
			})[0];
	const showAboutMe =
		privacySetting &&
		privacySetting
			.filter((item) => {
				return item.settingName === "showAboutMe";
			})
			.map((item) => {
				return item.settingValue === 1;
			})[0];
	const showEdu =
		privacySetting &&
		privacySetting
			.filter((item) => {
				return item.settingName === "showEdu";
			})
			.map((item) => {
				return item.settingValue === 1;
			})[0];
	const showExp =
		privacySetting &&
		privacySetting
			.filter((item) => {
				return item.settingName === "showExp";
			})
			.map((item) => {
				return item.settingValue === 1;
			})[0];

	const showHeadline =
		privacySetting &&
		privacySetting
			.filter((item) => {
				return item.settingName === "showHeadline";
			})
			.map((item) => {
				return item.settingValue === 1;
			})[0];

	React.useEffect(() => {
		let mounted = true;
		let CancelToken = axios.CancelToken;
		const source = CancelToken.source();
		const fetchCountryList = async () => {
			try {
				const res = await axios.get(`${instanceUrl}/api/md/get-all-country`, {
					cancelToken: source.token,
				});
				setCountryList(res.data.payload);
			} catch (error) {
				if (axios.isCancel(error)) {
					//console.log("unmount caused");
				} else {
					throw error;
				}
			}
		};
		const fetchLocationList = async () => {
			try {
				const res = await axios.get(`${instanceUrl}/api/md/get-all-location`, {
					cancelToken: source.token,
				});
				setLocationList(res.data.payload);
			} catch (error) {
				if (axios.isCancel(error)) {
					//console.log("unmount caused");
				} else {
					throw error;
				}
			}
		};
		const fetchEducationLevelList = async () => {
			try {
				const res = await axios.get(
					`${instanceUrl}/api/md/get-all-education-level`,
					{
						cancelToken: source.token,
					}
				);
				setEducationLevelList(res.data.payload);
			} catch (error) {
				if (axios.isCancel(error)) {
					//console.log("unmount caused");
				} else {
					throw error;
				}
			}
		};
		if (mounted) {
			fetchCountryList();
			fetchLocationList();
			fetchEducationLevelList();
		}
		return () => {
			mounted = false;
			source.cancel();
		};
	}, []);

	return (
		<Typography component="div" className={styles.main}>
			<Typography component="div" className={styles.header}>
				<Typography component="h2" className={styles.title}>
					About Me
				</Typography>
				{!isViewed && (
					<Button className={styles.editBtn} onClick={onEdit}>
						Edit
					</Button>
				)}
			</Typography>

			<hr className={styles.divider} />

			{isViewed ? (
				showProfile ? (
					showAboutMe ? (
						profileRecord.aboutMe && (
							<Typography component="p" className={styles.aboutMeText}>
								{profileRecord.aboutMe}
							</Typography>
						)
					) : (
						<Typography component="p" className={styles.aboutMeText}>
							"{profileRecord.firstName}" is not yet ready to show "About Me"
							detail
						</Typography>
					)
				) : (
					<Typography component="p" className={styles.aboutMeText}>
						"About Me" Detail is currently private.
					</Typography>
				)
			) : (
				profileRecord.aboutMe && (
					<Typography component="p" className={styles.aboutMeText}>
						{profileRecord.aboutMe}
					</Typography>
				)
			)}

			{profileRecord.countryId !== 0 && profileRecord.countryId && (
				<Typography component="div" className={styles.list}>
					<Typography component="div" className={styles.listItem}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="18"
							height="21.001"
							viewBox="0 0 18 21.001"
						>
							<path
								id="Combined_Shape"
								data-name="Combined Shape"
								d="M8.3,20.713,2.7,15.2A8.744,8.744,0,0,1,0,8.87,8.946,8.946,0,0,1,9,0a8.946,8.946,0,0,1,9,8.87,8.727,8.727,0,0,1-2.705,6.339L9.7,20.713a1,1,0,0,1-1.4,0ZM2,8.87a6.768,6.768,0,0,0,2.094,4.9L9,18.6l4.9-4.821A6.76,6.76,0,0,0,16,8.87,6.944,6.944,0,0,0,9,2,6.944,6.944,0,0,0,2,8.87ZM5,9a4,4,0,1,1,4,4A4,4,0,0,1,5,9ZM7,9A2,2,0,1,0,9,7,2,2,0,0,0,7,9Z"
								fill="#92929d"
							/>
						</svg>

						<Typography
							component="div"
							className={styles.listItemTextContainer}
						>
							<Typography component="p" className={styles.listItemTextTitle}>
								{profileRecord.countryId &&
									profileRecord.countryId !== 0 &&
									countryList
										.filter(
											(item) => item.countryId === profileRecord.countryId
										)
										.map((item) => item.countryName)}
								{profileRecord.locationId !== 0 &&
									profileRecord.locationId &&
									" , " +
									locationList
										.filter(
											(item) => item.locationId === profileRecord.locationId
										)
										.map((item) => item.locationName)}
							</Typography>
						</Typography>
					</Typography>
				</Typography>
			)}

			{profileRecord.website && (
				<Typography component="div" className={styles.listItem}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="22"
						height="22"
						viewBox="0 0 22 22"
					>
						<path
							id="Shape"
							d="M11,22A11,11,0,0,1,3.222,3.222,11,11,0,1,1,18.778,18.778,10.928,10.928,0,0,1,11,22Zm-.962-5.582a17.927,17.927,0,0,0-2.1.229,6.013,6.013,0,0,0,2.1,3.059Zm2,0v3.236a6.192,6.192,0,0,0,2.029-3.011A17.876,17.876,0,0,0,12.038,16.421Zm3.989.693a11.851,11.851,0,0,1-.829,1.849,9.009,9.009,0,0,0,1.982-1.419A11.552,11.552,0,0,0,16.026,17.114Zm-10.053,0a11.6,11.6,0,0,0-1.152.429A9.007,9.007,0,0,0,6.8,18.963,11.942,11.942,0,0,1,5.973,17.114Zm11-5.115a19.453,19.453,0,0,1-.408,3.189,13.053,13.053,0,0,1,1.942.771A9,9,0,0,0,19.945,12ZM2.055,12a9.017,9.017,0,0,0,1.434,3.96,13.071,13.071,0,0,1,1.943-.771A19.456,19.456,0,0,1,5.023,12H2.055Zm9.983,0v2.419a19.6,19.6,0,0,1,2.584.3A17.562,17.562,0,0,0,14.974,12ZM7.026,12a17.451,17.451,0,0,0,.352,2.714,19.784,19.784,0,0,1,2.66-.3V12ZM18.191,5.587a13.484,13.484,0,0,1-1.755.658A18.982,18.982,0,0,1,16.977,10h2.968A8.97,8.97,0,0,0,18.191,5.587ZM14.486,6.7a19.9,19.9,0,0,1-2.448.271V10h2.937A17.031,17.031,0,0,0,14.486,6.7Zm-6.971,0A16.961,16.961,0,0,0,7.026,10h3.011V6.979A19.9,19.9,0,0,1,7.514,6.7ZM3.809,5.587h0A8.969,8.969,0,0,0,2.055,10H5.023a19.052,19.052,0,0,1,.541-3.755,13.588,13.588,0,0,1-1.755-.658Zm6.229-3.293a5.392,5.392,0,0,0-1.874,2.49,18.008,18.008,0,0,0,1.874.192Zm2,.049V4.972a17.881,17.881,0,0,0,1.8-.187A5.558,5.558,0,0,0,12.038,2.343Zm3.16.694a11.487,11.487,0,0,1,.624,1.305c.307-.091.606-.192.887-.3A9.017,9.017,0,0,0,15.2,3.037Zm-8.4,0A9.056,9.056,0,0,0,5.291,4.042c.273.1.572.206.888.3A11.5,11.5,0,0,1,6.8,3.037Z"
							transform="translate(0 0)"
							fill="#92929d"
						/>
					</svg>

					<Typography component="div" className={styles.listItemTextContainer}>
						<Typography component="p" className={styles.listItemTextTitle}>
							<a target="_blank" href={`https://${profileRecord.website}`}>
								{profileRecord.website}
							</a>
						</Typography>
					</Typography>
				</Typography>
			)}

			{experienceRecords && experienceRecords.length > 0 && (
				<Typography component="div" className={styles.listItem}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="22"
						height="19"
						viewBox="0 0 22 19"
					>
						<path
							id="Combined_Shape"
							data-name="Combined Shape"
							d="M3,19a3,3,0,0,1-3-3V6A3,3,0,0,1,3,3H6A3,3,0,0,1,9,0h4a3,3,0,0,1,3,3h3a3,3,0,0,1,3,3V16a3,3,0,0,1-3,3Zm16-2a1,1,0,0,0,1-1V6a1,1,0,0,0-1-1h-.5V17Zm-2.5,0V5H5.5V17ZM2,6V16a1,1,0,0,0,1,1h.5V5H3A1,1,0,0,0,2,6ZM14,3a1,1,0,0,0-1-1H9A1,1,0,0,0,8,3Z"
							fill="#92929d"
						/>
					</svg>

					<Typography component="div" className={styles.listItemTextContainer}>
						<Typography component="p" className={styles.listItemTextTitle}>
							Work
						</Typography>
						<Typography
							component="p"
							className={styles.listItemTextDescription}
						>
							{isViewed ? (
								showProfile ? (
									showExp ? (
										<React.Fragment>
											{experienceRecord.endDate ? "" : "Currently a"}
											<span>{" " + experienceRecord.title}</span> at{" "}
											<a>{experienceRecord.organization}</a>
										</React.Fragment>
									) : (
										`"${profileRecord.firstName}" is not yet ready to show "Experience" Detail`
									)
								) : (
									`"Experience Detail is currently Private"`
								)
							) : (
								<React.Fragment>
									{experienceRecord.endDate ? "" : "Currently a"}
									<span>{" " + experienceRecord.title}</span> at{" "}
									<a>{experienceRecord.organization}</a>
								</React.Fragment>
							)}
						</Typography>
					</Typography>
				</Typography>
			)}

			{educationRecord && (
				<Typography component="div" className={styles.listItem}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="22"
						height="19"
						viewBox="0 0 22 19"
					>
						<path
							id="Combined_Shape"
							data-name="Combined Shape"
							d="M3,19a3,3,0,0,1-3-3V6A3,3,0,0,1,3,3H6A3,3,0,0,1,9,0h4a3,3,0,0,1,3,3h3a3,3,0,0,1,3,3V16a3,3,0,0,1-3,3Zm16-2a1,1,0,0,0,1-1V6a1,1,0,0,0-1-1h-.5V17Zm-2.5,0V5H5.5V17ZM2,6V16a1,1,0,0,0,1,1h.5V5H3A1,1,0,0,0,2,6ZM14,3a1,1,0,0,0-1-1H9A1,1,0,0,0,8,3Z"
							fill="#92929d"
						/>
					</svg>

					<Typography component="div" className={styles.listItemTextContainer}>
						<Typography component="p" className={styles.listItemTextTitle}>
							Education
						</Typography>
						<Typography
							component="p"
							className={styles.listItemTextDescription}
						>
							{isViewed ? (
								showProfile ? (
									showEdu ? (
										<React.Fragment>
											{educationLevelList
												.filter((item) => {
													return item.eduLvlId === educationRecord.eduLvlId;
												})
												.map((item) => {
													return item.eduLvlName;
												})}
											{` of `}
											{educationRecord.courseName}
											{` `}
											{educationRecord.studyField}
											{` at `}
											<a>{educationRecord.school}</a>
										</React.Fragment>
									) : (
										`"${profileRecord.firstName}" is not yet ready to show "Education" Detail`
									)
								) : (
									`"Education Detail is currently Private"`
								)
							) : (
								<React.Fragment>
									{educationLevelList
										.filter((item) => {
											return item.eduLvlId === educationRecord.eduLvlId;
										})
										.map((item) => {
											return item.eduLvlName;
										})}
									{` of `}
									{educationRecord.courseName}
									{` `}
									{educationRecord.studyField}
									{` at `}
									<a>{educationRecord.school}</a>
								</React.Fragment>
							)}
						</Typography>
					</Typography>
				</Typography>
			)}
		</Typography>
	);
};
export default ProfileViewAboutMe;
