const jobStyles = () => ({
  postHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 0 20px',

    '& > button': {
      border: "none",
      backgroundColor: "transparent",
      padding: "5px",
      cursor: "pointer"
    }
  },

  jobHeader: {
    marginTop: "25px",
  },

  jobTitle: {
    color: "#171725",
    fontSize: "20px",
    fontWeight: 600,
  },

  jobSalary: {
    marginTop: "5px",
    paddingLeft: "5px",
    color: "#171725",
    fontSize: "16px",
    fontWeight: 500,

    '& > svg': {
      marginRight: "10px",
      marginBottom: "-1px",
    },
  },

  jobType: {
    marginTop: "5px",
    paddingLeft: "5px",
    color: "#171725",
    fontSize: "16px",
    fontWeight: 500,

    '& > svg': {
      marginRight: "10px",
      marginBottom: "-1px",
    },
  },

  jobDescriptionContainer: {
    marginTop: "25px",
  },

  jobDescriptionHeading: {
    color: "#171725",
    fontSize: "16px",
    fontWeight: 600,
  },

  jobDescription: {
    display: "-webkit-box",
    "-webkit-line-clamp": "3",
    "-webkit-box-orient": "vertical",
    marginTop: "5px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#171725",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "1.3",
  },

  jobGraphicalContent: {
    overflow: "hidden",
    marginTop: "30px",
    borderRadius: "15px",
    width: "100%",
  },

  postActions: {
    display: 'flex',
    justifyContent: "space-evenly",
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTop: '1px solid #F1F1F5',
    margin: '15px 0 0',
    padding: '10px 0',

    '& > button': {
      height: "45px",
      cursor: "pointer",
    },
  },

  applyNowBtn: {
    boxSizing: "border-box",
    border: "solid 1px #6F52ED",
    borderRadius: "10px",
    backgroundColor: "#6F52ED",
    width: "125px",
    color: "#fff",
    fontSize: "15px",
    fontWeight: 600,

    '&:hover': {
      backgroundColor: "",
      color: "",
    },
  },

  learnMoreBtn: {
    boxSizing: "border-box",
    border: "solid 1px #6F52ED",
    borderRadius: "10px",
    backgroundColor: "#fff",
    width: "125px",
    color: "#6F52ED",
    fontSize: "15px",
    fontWeight: 600,

    '&:hover': {
      backgroundColor: "",
      color: "",
    },
  },

  regularAction: {
    border: "none",
    borderRadius: "4px",
    backgroundColor: "transparent",
    padding: "0 5px",
    color: "#44444F",
    fontSize: '14px',
    fontWeight: 400,

    '&:hover': {
      backgroundColor: "#0000000a",
      transition: "background-color 250ms",
    },

    '& > img': {
      marginRight: "5px",
      width: "25px",
    }
  },
})

export default jobStyles;