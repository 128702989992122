import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { removeFavProfile } from "configurations/redux/favProfileSlice.js";

import { makeStyles } from "@material-ui/core/styles";
import { encryptData, TimeFormat } from "configurations/configurations.js";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";

import Tooltip from "@material-ui/core/Tooltip";

import FavoriteIcon from "@material-ui/icons/Favorite";

import defaultAvatar from "assets/img/candidate_avatar.jpeg";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded";
import LocationOnRoundedIcon from "@material-ui/icons/LocationOnRounded";
import Divider from "@material-ui/core/Divider";

import favProfilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/FavouriteProfilePage/favProfilePageStyle.js";
import { Hidden } from "@material-ui/core";
import FontIcon from "views/Component/FontIcon.js";
import { faEye, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import IconLibrary from "views/Component/IconLibrary.js";

const useStyles = makeStyles(favProfilePageStyle);

export default function FavProfileCard({ item, getMessageNavigate }) {
	console.log(item);
	const classes = useStyles();
	const [acctId, setAcctId] = React.useState();
	const dispatch = useDispatch();

	const userStatus = useSelector((state) => state.auth.user);
	const deleteFavProfile = (item) => {
		const requestProfile = {
			email: userStatus.email,
			acctId: userStatus.acctId,
			favId: item.favId,
			accessToken: localStorage.getItem("token"),
		};
		console.log(requestProfile);
		dispatch(removeFavProfile(requestProfile));
	};
	React.useEffect(() => {
		const hashId = encryptData(item.profileId);
		setAcctId(hashId);
	}, []);
	const showProfile = item.privacySetting
		.filter((item) => {
			return item.settingName === "showProfile";
		})
		.map((item) => {
			return item.settingValue === 1;
		})[0];

	const showProfilePic = item.privacySetting
		.filter((item) => {
			return item.settingName === "showProfilePic";
		})
		.map((item) => {
			return item.settingValue === 1;
		})[0];
	const showHeadline = item.privacySetting
		.filter((item) => {
			return item.settingName === "showHeadline";
		})
		.map((item) => {
			return item.settingValue === 1;
		})[0];

	return (
		<div key={item.profileId}>
			{/* {console.log(item.privacySetting)} */}
			<Link
				className={classes.textDeco}
				target="_blank"
				to={{
					pathname: `/view-profile/${acctId}`,
					//state: { acctId: item.acctId },
				}}
				color="transparent"
			>
				<Card className={classes.cardClick + " " + classes.cardFrame}>
					<CardBody>
						<div className={classes.cardBody}>
							<GridContainer
								justifyContent="space-between"
								alignContent="center"
								alignItems="center"
							>
								<GridItem xs={10} sm={8} md={9} lg={8}>
									<GridContainer>
										<GridItem
											xs={3}
											sm={3}
											md={3}
											lg={3}
											className={classes.itemCenter}
										>
											<img
												className={classes.cardImage}
												src={
													showProfile
														? showProfilePic
															? item.profilePic
																? item.profilePic
																: defaultAvatar
															: defaultAvatar
														: defaultAvatar
												}
												alt="..."
											/>
										</GridItem>
										<GridItem xs={9} sm={9} md={9} lg={9}>
											<h4 className={classes.title + " " + classes.titleInfo}>
												{item.firstName}
												{item.middleName
													? " " + item.middleName + " ,"
													: null}{" "}
												{item.surname}
											</h4>
											<h5
												className={classes.description + " " + classes.descInfo}
											>
												{showProfile
													? showHeadline
														? item.headline
															? item.headline
															: ""
														: ""
													: ""}
											</h5>
											<div>
												<React.Fragment>
													<h4
														className={
															classes.candidateDescription +
															" " +
															classes.titleLocation
														}
													>
														{showProfile
															? item.countryName
																? item.countryName
																: null
															: null}
													</h4>{" "}
												</React.Fragment>

												{/* <div className={classes.iconWrapper}>
												<LocationOnRoundedIcon />
											</div>
											<h4 className={classes.candidateDescription}>

												{showProfile
													? item.countryName
													: "Private Country Info"}
											</h4> */}
											</div>

											{/* <h5 className={classes.description}>
											{showProfile
												? item.countryName
												: "Not available at this moment"}
										</h5> */}
										</GridItem>
									</GridContainer>
								</GridItem>
								<GridItem xs={2} sm={4} md={3} lg={4}>
									<div
										className={classes.rightAligned + " " + classes.itemCenter}
									>
										<Hidden mdUp>
											<Tooltip
												id="tooltip-top"
												title="Message This Candidate"
												placement="top"
												classes={{ tooltip: classes.tooltip }}
											>
												<Button
													onClick={(event) => {
														event.preventDefault();
														console.log(item);
														getMessageNavigate(item.profileId);
													}}
													color="opptyNoBorder"
													// justIcon
													className={classes.buttonMessage}
												>
													<IconLibrary.Mail />
													<Hidden xsDown>Send A Message</Hidden>
												</Button>
											</Tooltip>
										</Hidden>
										<Hidden smDown>
											<Tooltip
												id="tooltip-top"
												title="Message This Candidate"
												placement="top"
												classes={{ tooltip: classes.tooltip }}
											>
												<Button
													onClick={(event) => {
														event.preventDefault();
														console.log(item);
														getMessageNavigate(item.profileId);
													}}
													color="opptyNoBorder"
													className={classes.buttonMessage}
												>
													<IconLibrary.Mail />{" "}
													<Hidden mdDown>Send A Message</Hidden>
												</Button>
											</Tooltip>
										</Hidden>
										<Hidden xsDown>
											<Tooltip
												id="tooltip-top"
												title="Unsave This Profile"
												placement="top"
												classes={{ tooltip: classes.tooltip }}
											>
												<Button
													onClick={(event, value) => {
														event.preventDefault();
														deleteFavProfile(item);
													}}
													fullWidth
													justIcon
													round
													color="rose"
													className={classes.rightAligned}
												>
													<FavoriteIcon className={classes.followIcon} />
												</Button>

												{/* <Button
													onClick={(event) => {
														event.preventDefault();
														deleteFavProfile(item);
													}}
													fullWidth
													color="transparent"
													className={classes.buttonMessage}
												>
													<IconLibrary.Mail />
												</Button> */}
											</Tooltip>
										</Hidden>
									</div>
								</GridItem>
							</GridContainer>
							<Divider />
							<div className={classes.infoArea}>
								<GridContainer alignContent="center" alignItems="center">
									<GridItem xs={8} sm={12} md={12} lg={12}>
										<div>
											<FontIcon
												className={classes.grayFont + " " + classes.viewInfo}
												icon={faEye}
												text={<span>{item.viewCount} Views</span>}
											/>
										</div>
										{/* <div className={classes.iconWrapper}>
										<VisibilityRoundedIcon />
									</div> */}
										{/* <h4
										className={
											classes.candidateDescription +
											" " +
											classes.textOverflowHide
										}
									>
										{item.viewCount} Views
									</h4> */}
									</GridItem>
									<GridItem xs={4}>
										<Hidden smUp>
											<Tooltip
												id="tooltip-top"
												title="Unsave This Profile"
												placement="top"
												classes={{ tooltip: classes.tooltip }}
											>
												<Button
													onClick={(event, value) => {
														event.preventDefault();
														deleteFavProfile(item);
													}}
													fullWidth
													justIcon
													round
													color="rose"
													className={classes.rightAligned}
												>
													<FavoriteIcon className={classes.followIcon} />
												</Button>
											</Tooltip>
										</Hidden>
									</GridItem>
								</GridContainer>
							</div>
						</div>
					</CardBody>
				</Card>
			</Link>
		</div>
	);
}
