const commentStyles = (theme) => ({
	popoverListItem: {
		display: "flex",
		alignItems: "center",
		borderRadius: "5px",
		padding: "5px 10px 5px 5px",
		minWidth: "170px",
		cursor: "pointer",
		[theme.breakpoints.down("xs")]: {
			padding: "0",
			minWidth: "0",
		},

		"&:hover": {
			backgroundColor: "#e3e3e3",
		},
	},
	popoverListItemIcon: {
		display: "flex",
		justifyContent: "center",
		marginRight: "15px",
		width: "24px",
		textAlign: "center",
		[theme.breakpoints.down("xs")]: {
			width: "16px",
		},
	},
	popoverListItemText: {},
	popoverListItemTitle: {
		color: "#44444F",
		fontSize: "14px",
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
		},
	},
	popoverListItemDescription: {
		color: "#92929D",
		fontSize: "12px",
	},

	postSingleCommentWithReplies: {
		// display: "-webkit-box",
		// gap: "5px",
	},

	commenterProfilePic: {
		// flexBasis: "9%",
		// float: "right",
		marginRight: "10px",

		"& > img": {
			width: "40px",
			height: "auto",
			borderRadius: "50%",
			[theme.breakpoints.down("xs")]: {
				width: "25px",
			},
		},
	},

	feedCommentFlex: {
		display: "flex",
		justifyContent: "space-between",
	},

	postSingleCommentWithRepliesRight: {
		// flexBasis: "91%",
		width: "100%",
		minWidth: "0",
	},

	commenterName: {
		color: "#000000",
		marginTop: "0px",
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap",
	},

	commenterTime: {
		color: "#000000",
		display: "flex",
		justifyContent: "space-between",
		"& > div": {
			textOverflow: "ellipsis",
			overflow: "hidden",
			whiteSpace: "nowrap",
		},
	},

	commentDetails: {
		backgroundColor: "#F2F2F2",
		borderRadius: "10px",
		padding: "15px 20px 5px",
		[theme.breakpoints.down("xs")]: {
			padding: "5px 10px 5px",
		},
	},

	commentHeader: {
		display: "flex",
		justifyContent: "space-between",

		"& > h2": {
			fontSize: "16px",
			fontWeight: 600,
			[theme.breakpoints.down("xs")]: {
				fontSize: "14px",
				marginBottom: "0",
			},
		},

		"& > div": {
			fontSize: "14px",
			fontWeight: 500,
			[theme.breakpoints.down("xs")]: {
				fontSize: "11px",
				marginBottom: "0",
			},
		},

		"& > div > button": {
			marginLeft: "10px",
			border: "none",
			backgroundColor: "transparent",
			padding: 0,
			cursor: "pointer",
			marginBottom: "10px",
			"& > img": {
				[theme.breakpoints.down("xs")]: {
					width: "15px",
				},
			},
		},
	},

	commentHeadline: {
		fontSize: "15px",
		fontWeight: 500,
	},

	commentText: {
		margin: "5px 0 5px",
		color: "#4A4A4A",
		overflowWrap: "break-word",
		whiteSpace: "pre-wrap",
		// "& > p > a": {
		// 	fontWeight: "600",
		// },
		"& > div > p ": {
			margin: "0 0 5px",
			"& > .mention": {
				fontWeight: "600",
				color: "#7474a8",
				"& > span > a": {
					color: "#7474a8!important",
					textDecoration: "none",
				},
			},
			// "& > br": {
			// 	display: "none",
			// },
		},
	},

	commentActions: {
		display: "flex",
		alignItems: "center",
		margin: "10px 0",
		[theme.breakpoints.down("xs")]: {
			margin: "0",
		},

		"& > button": {
			backgroundColor: "transparent",
			border: "none",
			// color: "#6F52ED",
			fontSize: "16px",
			fontWeight: 600,
			cursor: "pointer",
			[theme.breakpoints.down("xs")]: {
				fontSize: "13px",
			},
		},

		"& > span": {
			marginLeft: "10px",
			fontSize: "14px",
			cusror: "pointer",
			textOverflow: "ellipsis",
			whiteSpace: "nowrap",
			overflow: "hidden",
			[theme.breakpoints.down("xs")]: {
				fontSize: "12px",
			},
		},

		"& .MuiButton-label": {
			"& img": {
				marginRight: "5px",
				width: "22px",
			},
		},
	},

	verticalLine: {
		margin: "0 10px",
		borderLeft: "1px solid #979797",
		height: "20px",
		[theme.breakpoints.down("xs")]: {
			margin: "0 3px",
		},
	},

	horizontalLine: {
		height: "10px",
	},

	moreReplies: {
		margin: "15px 0",
		color: "#4A4A4A",
		fontSize: "16px",
		fontWeight: 600,
		textAlign: "center",
		"& > button": {
			border: "none",
			backgroundColor: "transparent",
			padding: 0,
			cursor: "pointer",
		},
	},
	likePopper: {
		zIndex: "999",
		boxShadow:
			"0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
		borderRadius: "28px",
		top: "-2px!important",
		left: "30px!important",
		background: "white",
		"& > div": {
			boxShadow: "none",
			borderRadius: "18px",
		},
	},
	emojiPopper: {
		left: "-66px!important",
		zIndex: "999",
	},
	sendButton: {
		stroke: "rgb(0,0,0,0.54)",
	},
	attachmentList: {
		overflow: "auto",
		display: "flex",
		flexWrap: "wrap",
		padding: "10px 5px",
		height: "155px",
	},
	attachmentListItem: {
		position: "relative",
		margin: "15px 0px",
		width: "200px",
		// height: "120px",
		[theme.breakpoints.down("xs")]: {
			width: "100%",
		},

		"& > div": {
			overflow: "hidden",
			marginTop: "-20px",
			width: "100%",
			height: "100%",
		},
	},
	removeAttachment: {
		position: "relative",
		zIndex: "2",
		top: "-9px",
		right: "-9px",
		width: "20px",
		height: "auto",
		cursor: "pointer",
		float: "right",
	},
	attachmentListItemAttachmentContainerOverlay: {
		position: "absolute",
		top: "18px",
		right: 0,
		bottom: 0,
		left: 0,
		cursor: "pointer",
	},
	attachmentListItemAttachmentContainer: {
		display: "inline-block",
	},
	attachmentListItemContent: {
		width: "100%",
		height: "100%",
		objectFit: "cover",
		objectPosition: "center",
		border: "1px solid #92929D",
		borderRadius: "15px",
	},
	attachmentListItemDisplay: {
		position: "relative",
		margin: "0px 0px",
		width: "200px",
		// height: "120px",
		cursor: "pointer",
		[theme.breakpoints.down("xs")]: {
			width: "100%",
		},

		"& > div": {
			overflow: "hidden",
			marginTop: "0px",
			width: "100%",
			height: "100%",
		},
	},
	mentionsInput: {
		backgroundColor: "#FAFAFB",
		border: "1px solid #F1F1F5",
		borderRadius: "15px",
		padding: "15px 156px 10px 15px",
		[theme.breakpoints.down("xs")]: {
			padding: "15px 15px 10px 15px",
		},
	},
	quillCommentEditor: {
		"& > div > div ": {
			lineHeight: 1.42,
			height: "100%",
			outline: 0,
			overflowY: "auto",
			overflowX: "hidden",
			padding: "0",
			tabSize: 4,
			textAlign: "left",
			whiteSpace: "pre-wrap",
			wordWrap: "break-word",
			"& > p": {
				margin: "0 0 5px",
				"& > .mention": {
					fontWeight: "600",
					color: "#7474a8",
					"& > span > a": {
						color: "#7474a8!important",
						textDecoration: "none",
					},
				},
				// "& > br": {
				// 	display: "none",
				// },
			},
		},
		"& > div > .ql-clipboard": {
			// left: "-100000px",
			// height: "0px!important",
			// overflowY: "hidden",
			// position: "absolute",
			// top: "50%",
		},
		"& > div > .ql-tooltip": {
			left: "-100000px!important",
			height: "0px!important",
			overflowY: "hidden",
			position: "absolute",
			top: "50%!important",
		},
		"& .ql-container.ql-bubble:not(.ql-disabled) a:hover::before, .ql-container.ql-bubble:not(.ql-disabled) a:hover::after": {
			display: "none!important",
		},

		"& .ql-container.ql-bubble:not(.ql-disabled) a": {
			whiteSpace: "normal",
		},

		"& > div > .ql-mention-list-container": {
			top: "40px!important",
			// width: "270px",
			width: "auto",
			height: "200px",
			overflowY: "auto",
			border: "1px solid #f0f0f0",
			borderRadius: "4px",
			backgroundColor: "#fff",
			boxShadow: "0 2px 12px 0 rgba(30, 30, 30, 0.08)",
			zIndex: "1",
			"& > .ql-mention-list": {
				listStyle: "none",
				margin: 0,
				padding: 0,
				overflow: "hidden",
				"& > .ql-mention-list-item ": {
					cursor: "pointer",
					height: "47px",
					lineHeight: "44px",
					fontSize: "16px",
					padding: "0 8px",
					verticalAlign: "middle",
					whiteSpace: "nowrap",
				},
				"& > .ql-mention-list-item.selected ": {
					backgroundColor: "#687bfe",
					textDecoration: "none",
					color: "white",
				},
			},
		},
		"& .ql-editor.ql-blank::before": {
			whiteSpace: "nowrap",
			textOverflow: "ellipsis",
			overflow: "hidden",
		},
	},
	inputfield: {
		"& textarea": {
			border: "none",
			overflow: "hidden",
			font: "inherit!important",
			top: "-3px!important",
			"&::placeholder": {
				textOverflow: "ellipsis !important",
				color: "#9F9F9F",
			},
		},
		// height: "100%",
		// fontSize: 16,
		// // height: "19px",
		// fontWeight: 400,
		// fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		// "& > div > div": {
		// 	border: "2px!important",
		// },
		"& > div:nth-child(1)": {
			lineHeight: "1.5",

			"& strong": {
				position: "relative",
				zIndex: 1,
				top: "-2px",
				left: "1px",
				// backgroundColor: "#FAFAFB",
				color: "#7474a8",
				// fontWeight: "600!important",
			},

			"& .textarea": {
				zIndex: 0,
				// color: "transparent!important",
			},
		},

		"& > div:nth-child(2)": {
			boxShadow:
				"0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
			marginTop: "20px !important",
			borderRadius: "10px",
			border: "1px solid rgba(0,0,0,0.15)",
			backgroundColor: "white",
			padding: 5,

			"& li": {
				display: "flex",
				alignItems: "center",
				margin: 0,
				borderRadius: "5px",
				padding: "5px 10px 5px",
				color: "#44444F",
				cursor: "pointer",

				"&:hover": {
					backgroundColor: "#d7d7d7",
				},

				"&focused": {
					backgroundColor: "#cee4e5",
				},

				"& > img": {
					borderRadius: "50%",
					width: "20px",
				},

				"& > p": {
					margin: "0 0 0 7px",
				},
			},
		},
	},
	commentReplyClick: {
		cursor: "pointer",
		"&:hover": {
			textDecoration: "underline",
		},
	},
	likeReaction: {
		cursor: "pointer",
		"&:hover": {
			textDecoration: "underline",
		},
	},
	quillPostCounter: {
		fontSize: "14px",
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
		},
	},
});
export default commentStyles;
