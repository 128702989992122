import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
	instanceUrl,
	header,
	emptyCache,
} from "configurations/configurations.js";

const checkDuplicateSlice = createSlice({
	name: "checkDuplicate",
	initialState: {
		checkDuplicateResult: undefined,
		checkDuplicateParams: [],
		isUpdated: true,
		isEnd: false,
		isLoading: false,
	},
	reducers: {
		CHECK_DUPLICATE_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.checkDuplicateResult = action.payload.checkDuplicate;
			state.isUpdated = true;
		},
		CHECK_DUPLICATE_END: (state, action) => {
			state.isEnd = true;
		},
		CHECK_DUPLICATE_FAIL: (state) => {
			state.isUpdated = false;
		},
		CHECK_DUPLICATE_RESET: (state) => {
			state.checkDuplicateResult = undefined;
			state.isUpdated = true;
			state.isLoading = false;
		},
	},
});

const {
	CHECK_DUPLICATE_SUCCESS,
	CHECK_DUPLICATE_END,
	CHECK_DUPLICATE_FAIL,
	CHECK_DUPLICATE_RESET,
} = checkDuplicateSlice.actions;

export const checkDuplicateInfo = ({
	email,
	acctId,
	title,
	accessToken,
}) => async (dispatch) => {
	const body = {
		email,
		acctId,
		title,
		accessToken,
	};

	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/um/check-user-attachment-title-by-acct-id`,
		params: body,
	})
		.then((res) => {
			if (res.status === 201) {
				const response = {
					checkDuplicate: res.data.payload.checkDuplicate,
					token: res.data.token,
				};
				console.log(res.data.payload);
				dispatch(CHECK_DUPLICATE_SUCCESS(response));
			} else if (res.status === 202) {
				dispatch(CHECK_DUPLICATE_END());
			} else {
				dispatch(CHECK_DUPLICATE_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const checkDuplicateReset = () => (dispatch) => {
	dispatch(CHECK_DUPLICATE_RESET());
};

export default checkDuplicateSlice.reducer;
