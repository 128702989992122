import React from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { instanceUrl, header } from "configurations/configurations.js";
import { appVersion } from "configurations/configurations.js";
import { makeStyles } from "@material-ui/core/styles";
import pageNotFoundPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/PageNotFoundPage/pageNotFoundPageStyle.js";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import maintenanceImage from "assets/img/Maintenance_Image.png";

const useStyles = makeStyles(pageNotFoundPageStyle);

export default function RedirectCallBackPage(props) {
	// console.log(props.match.params.account);
	const dispatch = useDispatch();
	const userStatus = useSelector((state) => state.auth.user);
	const account = props.match.params.account;
	const params = new URLSearchParams(props.location.search);
	const code = params.get("code");
	const error = params.get("error");
	if (code && userStatus) {
		const submitCode = async () => {
			await axios({
				method: "GET",
				headers: header,
				url: `${instanceUrl}/api/um/` + account + `-contact-sync-callback`,
				params: {
					code: code,
				},
			})
				.then((res) => {
					console.log(res);
					if (res.request.status === 201) {
						console.log(account);
						if (res.data.accessToken) {
							var accessRefreshToken = res.data.accessToken;
							if (account === "google" && res.data.refreshToken)
								accessRefreshToken = res.data.refreshToken;
							var accessShortToken = res.data.accessToken;
							var accessToken = localStorage.getItem("token");
							console.log(userStatus);
							var acctId = userStatus.acctId;
							var caName = account;
							var syncToken = "";
							const body = {
								accessRefreshToken,
								accessShortToken,
								syncToken,
								accessToken,
								acctId,
								caName,
							};
							const getContactList = async (body) => {
								await axios({
									method: "POST",
									headers: header,
									url: `${instanceUrl}/api/um/add-contact-acct`,
									data: body,
								})
									.then((res) => {
										console.log(res);
										// if (account === "microsoft")
										window.close();
										// if (account === "google")
										// 	dispatch(googleContactStatusUpdate("Sync Completed"));
										// const response = {
										// 	token: res.data.token,
										// };
										// if (res.status === 201) {
										// 	dispatch(CORP_JOB_POST_SUCCESS(response));
										// } else {
										// 	dispatch(CORP_UPDATE_FAIL());
										// }
									})
									.catch((err) => {
										// if (err.message.includes("503")) {
										// 	emptyCache();
										// }
										console.log(err);
									});
							};
							getContactList(body);
						}
						// setEmailRequest(true);
					} else {
						// setEmailRequest(false);
					}
				})
				.catch((err) => {
					console.log(err);
					// setEmailRequest(false);
				});
		};
		submitCode();
	} else {
		setTimeout(() => {
			window.close();
		}, 3000);
	}
	if (error) {
		setTimeout(() => {
			window.close();
		}, 3000);
	}
	const classes = useStyles();
	return (
		<div className={classes.body}>
			<div className={classes.pageHeader}>
				<div className={classes.container}>
					<GridContainer
						justifyContent="center"
						alignContent="center"
						alignItems="center"
					>
						<GridItem xs={12} sm={12} md={7}>
							<div className={classes.infoArea}>
								<img
									src={maintenanceImage}
									className={classes.infoImage + " " + classes.textCenter}
								/>
							</div>
						</GridItem>
						<GridItem xs={12} sm={12} md={5}>
							<Card className={classes.pageNotFoundCard}>
								<CardBody className={classes.pageNotFoundCardBody}>
									<GridContainer
										justifyContent="center"
										alignContent="center"
										alignItems="center"
									>
										<GridItem xs={12} sm={12} md={12}>
											<h4 className={classes.title + " " + classes.textCenter}>
												{error
													? "We could not get your permission. Please try again."
													: userStatus
													? "Authorisation Successful"
													: "Some error occur. Please refresh the page and try again."}
											</h4>
										</GridItem>
									</GridContainer>
								</CardBody>
							</Card>
						</GridItem>
					</GridContainer>
				</div>
			</div>
		</div>
	);
}
