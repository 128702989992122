import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import profileViewStyles from "assets/jss/material-kit-pro-react/views/pageStyle/ProfileViewPage/profileViewStyles";
import Avatar from "@mui/material/Avatar";
import Skeleton from "@mui/material/Skeleton";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

const useStyles = makeStyles(profileViewStyles);

export default function LoadingSkeleton() {
	const styles = useStyles();

	return (
		<React.Fragment>
			<Card className={styles.cardFrame}>
				<CardBody>
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<Box sx={{ margin: 10 }}>
							<Skeleton variant="circular">
								<Avatar style={{ width: "140px", height: "140px" }} />
							</Skeleton>
						</Box>
						<Box sx={{ width: "100%", height: "50px" }}>
							<Skeleton width="100%" height={50}>
								<Typography>.</Typography>
							</Skeleton>
							<Skeleton width="80%" height={20}>
								<Typography>.</Typography>
							</Skeleton>
						</Box>
					</Box>
					<Box sx={{ width: "100%", height: "100%" }}>
						<Skeleton variant="rectangular" width="100%" height={"10vh"}>
							<div style={{ paddingTop: "57%" }} />
						</Skeleton>
					</Box>
				</CardBody>
			</Card>

			<GridContainer>
				<GridItem xs={12} sm={12} md={4}>
					<Card className={styles.cardFrame}>
						<CardBody>
							<Box>
								<Skeleton width="100%" height={50}>
									<Typography>.</Typography>
								</Skeleton>
								<Skeleton width="80%" height={30}>
									<Typography>.</Typography>
								</Skeleton>
								<Skeleton width="80%" height={30}>
									<Typography>.</Typography>
								</Skeleton>
								<Skeleton width="80%" height={30}>
									<Typography>.</Typography>
								</Skeleton>
							</Box>
						</CardBody>
					</Card>
					<Card className={styles.cardFrame}>
						<CardBody>
							<Box>
								<Skeleton width="100%" height={50}>
									<Typography>.</Typography>
								</Skeleton>
								<Skeleton width="80%" height={30}>
									<Typography>.</Typography>
								</Skeleton>
								<Skeleton width="80%" height={30}>
									<Typography>.</Typography>
								</Skeleton>
								<Skeleton width="80%" height={30}>
									<Typography>.</Typography>
								</Skeleton>
							</Box>
						</CardBody>
					</Card>
					<Card className={styles.cardFrame}>
						<CardBody>
							<Box>
								<Skeleton width="100%" height={50}>
									<Typography>.</Typography>
								</Skeleton>
								<Skeleton width="80%" height={30}>
									<Typography>.</Typography>
								</Skeleton>
								<Skeleton width="80%" height={30}>
									<Typography>.</Typography>
								</Skeleton>
								<Skeleton width="80%" height={30}>
									<Typography>.</Typography>
								</Skeleton>
							</Box>
						</CardBody>
					</Card>
					<Card className={styles.cardFrame}>
						<CardBody>
							<Box>
								<Skeleton width="100%" height={50}>
									<Typography>.</Typography>
								</Skeleton>
								<Skeleton width="80%" height={30}>
									<Typography>.</Typography>
								</Skeleton>
								<Skeleton width="80%" height={30}>
									<Typography>.</Typography>
								</Skeleton>
								<Skeleton width="80%" height={30}>
									<Typography>.</Typography>
								</Skeleton>
							</Box>
						</CardBody>
					</Card>
				</GridItem>

				<GridItem xs={12} sm={12} md={8}>
					<Card className={styles.cardFrame}>
						<CardBody>
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<Box sx={{ margin: 10 }}>
									<Skeleton variant="circular">
										<Avatar style={{ width: "40px", height: "40px" }} />
									</Skeleton>
								</Box>
								<Box sx={{ width: "100%", height: "50px" }}>
									<Skeleton width="100%" height={50}>
										<Typography>.</Typography>
									</Skeleton>
								</Box>
							</Box>
							<Skeleton variant="rectangular" width="100%" height={"10vh"}>
								<div style={{ paddingTop: "57%" }} />
							</Skeleton>
						</CardBody>
					</Card>
					<Card className={styles.cardFrame}>
						<CardBody>
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<Box sx={{ margin: 10 }}>
									<Skeleton variant="circular">
										<Avatar style={{ width: "40px", height: "40px" }} />
									</Skeleton>
								</Box>
								<Box sx={{ width: "100%", height: "50px" }}>
									<Skeleton width="100%" height={50}>
										<Typography>.</Typography>
									</Skeleton>
								</Box>
							</Box>
							<Skeleton variant="rectangular" width="100%" height={"10vh"}>
								<div style={{ paddingTop: "57%" }} />
							</Skeleton>
						</CardBody>
					</Card>
					<Card className={styles.cardFrame}>
						<CardBody>
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<Box sx={{ margin: 10 }}>
									<Skeleton variant="circular">
										<Avatar style={{ width: "40px", height: "40px" }} />
									</Skeleton>
								</Box>
								<Box sx={{ width: "100%", height: "50px" }}>
									<Skeleton width="100%" height={50}>
										<Typography>.</Typography>
									</Skeleton>
								</Box>
							</Box>
							<Skeleton variant="rectangular" width="100%" height={"10vh"}>
								<div style={{ paddingTop: "57%" }} />
							</Skeleton>
						</CardBody>
					</Card>
					<Card className={styles.cardFrame}>
						<CardBody>
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<Box sx={{ margin: 10 }}>
									<Skeleton variant="circular">
										<Avatar style={{ width: "40px", height: "40px" }} />
									</Skeleton>
								</Box>
								<Box sx={{ width: "100%", height: "50px" }}>
									<Skeleton width="100%" height={50}>
										<Typography>.</Typography>
									</Skeleton>
								</Box>
							</Box>
							<Skeleton variant="rectangular" width="100%" height={"10vh"}>
								<div style={{ paddingTop: "57%" }} />
							</Skeleton>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		</React.Fragment>
	);
}
