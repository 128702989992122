import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { departmentViewRoadmap, viewRoadmapRoleByRoleId } from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Department_AI_Applied_Research from "assets/img/Career_Roadmap/Data_Artificial_Intelligence/Department_AI_Applied_Research_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentAIAppliedResearch() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_AI_Applied_Research;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "aar",
				shape: "poly",
				coords: [75,390,67,394,62,397,60,402,57,409,57,465,59,471,62,475,67,479,74,482,167,482,174,480,179,476,183,471,185,465,185,410,184,403,179,397,174,393,167,391,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "177",
				shape: "poly",
				coords: [212,393,205,396,202,399,198,403,196,408,196,464,198,468,200,473,204,476,211,478,598,477,603,475,608,473,611,468,613,462,613,413,612,406,611,400,605,396,597,393,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "178",
				shape: "poly",
				coords: [896,389,890,392,886,395,884,400,882,406,882,464,885,471,889,475,896,477,1198,478,1203,476,1208,472,1211,468,1213,462,1213,406,1212,400,1209,396,1204,392,1199,390,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "179",
				shape: "poly",
				coords: [1543,390,1536,392,1533,395,1530,399,1528,405,1529,459,1530,465,1533,468,1537,471,1545,473,1816,473,1823,470,1827,468,1828,463,1829,458,1829,407,1828,400,1825,395,1820,391,1815,390,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "aar") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo)
			dispatch(viewRoadmapRoleByRoleId(requestInfo));
			
			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// console.log(coords.x + "," + coords.y + ",");
		// navigator.clipboard.writeText(coords.x + "," + coords.y + ",");
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
