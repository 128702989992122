import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { Hidden } from "@material-ui/core";
import { Link } from "react-router-dom";

import Carousel from "react-slick";
/* import { Carousel } from "react-responsive-carousel"; */
import { CardMedia, CardContent, CardHeader } from "@material-ui/core";
import Skeleton from "@mui/material/Skeleton";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import communityPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/CommunityPage/communityPageStyle.js";

const useStyles = makeStyles(communityPageStyle);

export default function CommunityBannerAds() {
	const classes = useStyles();

	const bannerAds = useSelector((state) => state.community.bannerAds);
	const adsLoading = useSelector((state) => state.community.bannerAdsLoading);

	const partnerCarouselSettings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
	};
	const CarouselLoading = (
		<Card
			className={
				classes.cardClick +
				" " +
				classes.cardFrame +
				" " +
				classes.bannerAdsCard
			}
		>
			<CardHeader
				avatar={
					<Skeleton
						animation="wave"
						variant="circular"
						width={40}
						height={40}
					/>
				}
				title={
					<Skeleton
						animation="wave"
						height={10}
						width="80%"
						style={{ marginBottom: 6 }}
					/>
				}
			/>
			<Hidden smDown>
				<Skeleton
					sx={{ height: "152px" }}
					animation="wave"
					variant="rectangular"
				/>
			</Hidden>
			<Hidden smUp>
				<Skeleton
					sx={{ height: "calc(12vh - 138px)" }}
					animation="wave"
					variant="rectangular"
				/>
			</Hidden>
			<CardBody>
				<React.Fragment>
					<Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
					<Skeleton animation="wave" height={10} width="80%" />
				</React.Fragment>
			</CardBody>
		</Card>
	);
	const CarouselBannerAds =
		adsLoading && bannerAds && bannerAds.length < 1
			? CarouselLoading
			: bannerAds &&
			bannerAds.map((item) => {
				return (
					<div key={item.adsId}>
						<Link
							className={classes.textDeco}
							target="_blank"
							to={{
								pathname: item.adsURL,
							}}
							color="transparent"
						>
							<Card
								className={
									classes.cardClick +
									" " +
									classes.cardFrame +
									" " +
									classes.bannerAds +
									" " +
									classes.bannerAdsCard
								}
								style={{
									backgroundImage: `url(${item.adsMedia})`,
								}}
							/>
						</Link>
					</div>
				);
			});

	return <Carousel {...partnerCarouselSettings}>{CarouselBannerAds}</Carousel>;
}
