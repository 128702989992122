import { borderRadius } from "@mui/system";
import { opptyDesignColor } from "assets/jss/material-kit-pro-react";
import {
	grayColor,
	primaryColor,
	title,
	blackColor,
	whiteColor,
	container,
} from "assets/jss/material-kit-pro-react.js";

const socialFeedStyles = (theme) => ({
	infiScroll: {
		scrollbarWidth: "none",
		"&::-webkit-scrollbar": {
			display: "none",
		},
	},
	socialFeedCard: {
		boxShadow: "none",
		borderRadius: "15px!important",
		padding: "20px 20px 10px",
		margin: "15px 0 0",

		"& h4": {
			fontSize: "18px",
			fontWeight: "600",
			color: "#4A4A4A",
			margin: "0",
		},

		"& .MuiCardContent-root": {
			paddingBottom: "0",
		},
	},

	socialFeedCardContent: {
		padding: 0,
	},

	feedHeader: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		margin: "0 0 20px",

		"& > button": {
			backgroundColor: "transparent",
			border: "none",
			cursor: "pointer",
		},
	},

	feedHeaderUserBx: {
		display: "flex",
		alignItems: "center",
	},

	feedUserImgBx: {
		overflow: "hidden",
		borderRadius: "50%",
		marginRight: "15px",
		width: "53px",
		maxHeight: "53px",

		"& > img": {
			width: "100%",
		},
	},
	feedUserNameBx: {
		"& h6": {
			color: "#171725",
			fontSize: "14px",
			fontWeight: 700,
		},

		"& > h6 > span": {
			marginLeft: "20px",
			border: "solid 1px #20F0A1",
			borderRadius: "10px",
			padding: "7px 20px 6px",
			color: "#20F0A1",
			fontSize: "15px",
			fontWeight: 500,
			textTransform: "capitalize",
		},

		"& span": {
			fontSize: "12px",
			color: "#92929D",
		},
	},

	postGraphicalContent: {
		// position: 'relative',
		overflow: "hidden",
		borderRadius: "15px",
		width: "100%",
	},
	postContentContainer: {
		position: "relative",
		display: "flex",
		justifyContent: "center",
		backgroundColor: "#e3e3e3",
		width: "100%",
		height: "500px",

		"& > button:hover": {
			backgroundColor: "#0f0f0f",
		},

		"& > button > img": {
			width: "10px",
			height: "auto",
		},

		"& > img": {
			width: "100%",
			height: "100%",
			objectFit: "cover",
		},
	},
	leftPicBtn: {
		position: "absolute",
		top: "50%",
		left: "10px",
		transform: "translate(0, -50%)",
		border: "none",
		borderRadius: "50%",
		backgroundColor: "black",
		padding: "18px 22px",
		cursor: "pointer",
	},
	rightPicBtn: {
		position: "absolute",
		top: "50%",
		right: "10px",
		transform: "translate(0, -50%)",
		border: "none",
		borderRadius: "50%",
		backgroundColor: "black",
		padding: "18px 22px",
		cursor: "pointer",
	},
	photosList: {
		display: "flex",
		overflow: "hidden",
		overflowX: "scroll",
		marginTop: "10px",

		"&::-webkit-scrollbar": {
			height: "0px",
		},
	},
	photosListContainer: {
		display: "flex",
		gap: "10px",
	},
	photosListItem: {
		overflow: "hidden",
		borderRadius: "15px",
		width: "128px",
		height: "128px",

		"& > div": {
			overflow: "hidden",
			width: "100%",
			height: "100%",
		},
	},
	removePhoto: {
		position: "relative",
		zIndex: "2",
		top: "-9px",
		right: "-87px",
		width: "20px",
		height: "auto",
		cursor: "pointer",
	},
	photosListItemPhotoContainer: {
		display: "inline-block",
	},
	selectedPhoto: {
		boxSizing: "border-box",
		border: "solid 3px #20F0A1",
	},
	photosListItemPhoto: {
		position: "relative",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: "auto",
		height: "100%",
	},

	// postPhotosContainer: {
	//     position: "absolute",
	//     top: 0,
	//     right: 0,
	//     bottom: 0,
	//     left: 0,

	//     '& > img': {
	//         position: "absolute",
	//         top: 0,
	//         right: 0,
	//         bottom: 0,
	//         left: 0,
	//         margin: "auto",
	//         maxWidth: "100%",
	//         maxHeight: "100%"
	//     }
	// },

	leftFeedGallery: {
		flex: "0 0 50%",
		maxWidth: "50%",
		padding: "0 6px",
	},

	rightFeedGallery: {
		flex: "0 0 50%",
		maxWidth: "50%",
		padding: "0 6px",
	},

	rightBottomFeed: {
		margin: "0 0 15px",
	},

	feedTitle: {
		color: "#44444F",
		fontSize: "14px",
		margin: "0 0 30px",
	},

	feedActions: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		borderTop: "1px solid #F1F1F5",
		margin: "15px 0 0",
		padding: "10px 0",
		[theme.breakpoints.down("xs")]: {
			margin: "7px 0 0",
			padding: "0",
		},

		"& > button": {
			color: "#4444 4F",
			fontSize: "14px",
			fontWeight: 400,
		},

		"& .MuiButton-label": {
			textTransform: "capitalize",

			"& img": {
				marginRight: "5px",
			},
		},
	},

	feedCommentBx: {
		display: "block",
		alignItems: "center",
		borderTop: "1px solid #F1F1F5",
		padding: "20px 0",

		"& .MuiTypography-body1, .MuiInputBase-root": {
			width: "100%",
		},
		"& textarea": {
			backgroundColor: "#FAFAFB",
			border: "1px solid #F1F1F5",
			borderRadius: "15px",
			padding: "15px 156px 15px 15px",
		},
		"& .MuiInput-underline:before, .Mui-focused:after": {
			display: "none",
		},
	},

	feedCommentUserBx: {
		// flex: "0 0 45px",
		// maxWidth: "45px",
		// maxHeight: "45px",
		// borderRadius: "50%",
		// overflow: "hidden",
		// margin: "0 15px 0 0",
		float: "right",

		"& > img": {
			// width: "100%",
			width: "40px",
			borderRadius: "50%",
		},
	},

	commentInputWrap: {
		position: "relative",
		minWidth: "0",
	},

	commentActionBtns: {
		position: "absolute",
		right: 0,
		top: 0,
		width: "100px!important",
	},

	popoverListItem: {
		display: "flex",
		alignItems: "center",
		borderRadius: "5px",
		padding: "5px 10px 5px 5px",
		minWidth: "250px",
		cursor: "pointer",

		"&:hover": {
			backgroundColor: "#e3e3e3",
		},
	},
	popoverListItemIcon: {
		display: "flex",
		justifyContent: "center",
		marginRight: "15px",
		width: "24px",
		textAlign: "center",
	},
	popoverListItemText: {},
	popoverListItemTitle: {
		color: "#44444F",
		fontSize: "14px",
	},
	popoverListItemDescription: {
		color: "#92929D",
		fontSize: "12px",
	},
	popoverBox: {
		padding: "10px",
		[theme.breakpoints.down("xs")]: {
			padding: "5px",
		},
	},
});
export default socialFeedStyles;
