import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/CustomButtons/Button";
import { makeStyles } from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconLibrary from "views/Component/IconLibrary";
import { Form, Formik } from "formik";
import { createGroup } from "configurations/redux/groupsSlice";
import { groupValidationSchema } from "configurations/configurations";
import { useIsFirstRender } from "hooks";
import { blobToB64 } from "configurations/configurations";
import { checkGroupNameDuplicate } from "configurations/redux/groupsSlice";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js";
import GroupForm from "./GroupForm";
const useStyles = makeStyles(profilePageStyle);

export default function CreateGroupModal({ modalOpen, toggle }) {
	const classes = useStyles();
	const isFirstRender = useIsFirstRender();
	const dispatch = useDispatch();
	const user = useSelector((state) => state.auth.user);
	const { isGroupCreateLoading, errorMessage } = useSelector(
		(state) => state.groups
	);

	const onCreateGroup = async (values, helpers) => {
		const isGroupDuplicate = await checkGroupNameDuplicate({
			email: user.email,
			groupName: values.groupName,
		});
		if (isGroupDuplicate) {
			helpers.setFieldError(
				"groupName",
				"This group name already exists.Try another name."
			);
		} else {
			const body = {
				accessToken: localStorage.getItem("token"),
				acctId: user.acctId,
				email: user.email,
				groupStatusId: 1,
				...values,
				permissionId:
					values.permissionId.length < 1 ? "0" : values.permissionId.toString(),
				countryId: Number(values.countryId),
				profilePic:
					values.profilePic?.blob && (await blobToB64(values.profilePic.blob)),
			};
			dispatch(createGroup(body));
		}
	};

	useEffect(() => {
		if (!isFirstRender && !errorMessage && !isGroupCreateLoading) {
			toggle();
		}
	}, [errorMessage, isGroupCreateLoading]);

	return (
		<>
			<Dialog
				/* s */
				/* 			className={classes.modal} */
				classes={{
					root: classes.modalRoot,
					paper: classes.modal + " " + classes.modalGroupRoot,
				}}
				style={{ height: "100% !important" }}
				open={modalOpen}
				onClose={toggle}
			>
				<DialogTitle className={classes.modalTitle}>
					<Button
						simple
						className={classes.modalCloseButton}
						key="close"
						aria-label="Close"
						onClick={toggle}
					>
						<IconLibrary.Close />
					</Button>

					<div className={classes.title + " " + classes.titleAlign}>
						Create group
					</div>
				</DialogTitle>

				<Formik
					onSubmit={onCreateGroup}
					validateOnChange={false}
					validationSchema={groupValidationSchema}
					initialValues={{
						groupName: "",
						groupDescription: "",
						profilePic: "",
						industryId: null,
						customIndustryName: null,
						countryId: null,
						groupRule: "",
						visibleId: 1,
						permissionId: [],
					}}
				>
					{({ handleSubmit, ...rest }) => (
						<Form>
							<DialogContent className={classes.groupModalBody}>
								<GroupForm {...{ ...rest }} />
							</DialogContent>
							<DialogActions>
								<Button
									round
									onClick={handleSubmit}
									color="oppty"
									disabled={isGroupCreateLoading}
								>
									<b>CREATE GROUP</b>
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</>
	);
}
