import { createSlice } from '@reduxjs/toolkit';

import axios from 'axios';
import { instanceUrl, header, emptyCache } from 'configurations/configurations.js';

const searchJobSlice = createSlice({
  name: 'searchJob',
  initialState: {
    searchResult: [],
    searchParams: [],
    lastRecordSize: 0,
    requestRecordSize: 0,
    isLoading: false,
    isEnd: false,
    isSearching: false,
    isUpdated: true,
  },
  reducers: {
    JOB_LOADING: (state, action) => {
      state.isLoading = true;
    },
    JOB_SEARCH_SUCCESS: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      if (!action.payload.genericSearch) {
        state.searchResult = [...state.searchResult, ...action.payload.searchResult];
      } else state.searchResult = action.payload.searchResult;
      state.searchParams = action.payload.searchParams;
      state.lastRecordSize = state.lastRecordSize + action.payload.requestRecordSize;
      state.requestRecordSize = action.payload.requestRecordSize;
      state.isLoading = false;
      state.isUpdated = true;
    },
    JOB_SEARCH_END: (state, action) => {
      state.isEnd = true;
    },
    DEFAULT_JOB_SEARCH: state => {
      state.searchResult = [];
      state.searchParams = [];
      state.lastRecordSize = 0;
      state.requestRecordSize = 0;
      state.isEnd = false;
      state.isSearching = true;
    },
    JOB_SEARCH_FAIL: state => {
      state.isUpdated = false;
    },
  },
});

const { JOB_LOADING, JOB_SEARCH_SUCCESS, JOB_SEARCH_END, DEFAULT_JOB_SEARCH, JOB_SEARCH_FAIL } = searchJobSlice.actions;

export const searchJobInfo = ({
  acctId,
  accessToken,
  email,
  keyword,
  countryId,
  locationId,
  companyName,
  industryId,
  hotJobFlag,
  jobPostTypeId,
  jobTypeId,
  lastRecordSize,
  requestRecordSize,
  experienceId,
  currencyId,
  salaryMin,
  salaryMax,
  genericSearch,
}) => async dispatch => {
  const body = {
    acctId,
    accessToken,
    email,
    keyword: keyword && keyword.charAt(0) == '#' ? keyword.slice(1) : keyword,
    countryId,
    locationId,
    companyName,
    industryId,
    hotJobFlag,
    jobTypeId,
    jobPostTypeId,

    lastRecordSize,
    requestRecordSize,
    experienceId,
    currencyId,
    salaryMin,
    salaryMax,
    genericSearch,
  };

  dispatch(JOB_LOADING());
  if (!genericSearch) {
    if (lastRecordSize === 0) {
      dispatch(DEFAULT_JOB_SEARCH());
    }
  }

  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/portal/search-job`,
    params: body,
  })
    .then(res => {
      console.log(res);
      if (res.status === 201) {
        const response = {
          searchResult: res.data.payload.jobList,
          searchParams: {
            keyword,
            countryId,
            locationId,
            industryId,
            companyName,
            hotJobFlag,
            jobPostTypeId,
            jobTypeId,
            experienceId,
            currencyId,
            salaryMin,
            salaryMax,
          },
          lastRecordSize,
          genericSearch,
          requestRecordSize: res.data.payload.jobList.length,
          token: res.data.token,
        };
        dispatch(JOB_SEARCH_SUCCESS(response));
        if (requestRecordSize > res.data.payload.jobList.length) {
          dispatch(JOB_SEARCH_END());
        }
      } else if (res.status === 202) {
        dispatch(JOB_SEARCH_END());
      } else {
        dispatch(JOB_SEARCH_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export default searchJobSlice.reducer;
