import React from "react";
import { Hidden, IconButton, makeStyles, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import CustomButton from "components/CustomButtons/Button";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import groupPageStyles from "assets/jss/material-kit-pro-react/views/pageStyle/GroupsPage";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

const useStyles = makeStyles(groupPageStyles);

const GroupsPageHeader = ({ openCreateGroupModal }) => {
	const classes = useStyles();
	const [searchText, setSearchText] = React.useState("");
	const history = useHistory();

	return (
		<GridContainer style={{ marginTop: "10px" }}>
			<GridItem sm={5} style={{ display: "flex", alignItems: "center" }}>
				<Typography className={classes.headerText} variant="h5">
					Discover Groups
				</Typography>
			</GridItem>
			<GridItem sm={12} md={7} lg={7}>
				<div className={classes.headerActions}>
					<div className={classes.searchGroups}>
						<input
							placeholder="Search Groups"
							onKeyDown={(event) => {
								if (event.key === "Enter") {
									setSearchText(event.target.value);
									history.replace("/browse-groups/" + searchText);
								}
							}}
							className={classes.input}
							onChange={(event) => {
								setSearchText(event.target.value);
							}}
							value={searchText || ""}
						/>
						<IconButton
							onClick={() => {
								history.replace("/browse-groups/" + searchText);
							}}
							sx={{ padding: 0 }}
						>
							<SearchIcon />
						</IconButton>
					</div>
					<CustomButton
						style={{ fontWeight: "700" }}
						round
						onClick={openCreateGroupModal}
						color="oppty"
					>
						<AddIcon />
						<Hidden xsDown>Create Group</Hidden>
					</CustomButton>
				</div>
			</GridItem>
		</GridContainer>
	);
};

export default GroupsPageHeader;
