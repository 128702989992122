import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
	instanceUrl,
	header,
	emptyCache,
} from "configurations/configurations.js";

const favJobSlice = createSlice({
	name: "favJob",
	initialState: {
		jobPostList: [],
		jobPostId: [],
		isUpdated: true,
		isLoading: false,
		isChanged: false,
		loadingJobList: false,
		isDeleted: false,
	},
	reducers: {
		FAV_JOB_LOADING: (state) => {
			state.isLoading = true;
		},
		FAV_JOB_PAGINATION_LOADING: (state) => {
			state.loadingJobList = true;
		},
		FAV_JOB_LOAD_SUCCESS: (state, action) => {
			state.isLoading = false;
			state.isUpdated = true;
			state.loadingJobList = false;
			state.isDeleted = false;
			state.jobPostList = action.payload.jobPostList;
			localStorage.setItem("token", action.payload.token);
		},
		FAV_JOB_LOAD_FAIL: (state) => {
			state.isLoading = false;
			state.isUpdated = false;
			state.loadingJobList = false;
			state.jobPostList = null;
			localStorage.removeItem("token");
		},
		FAV_JOB_REMOVE_SUCCESS: (state, action) => {
			state.isChanged = false;
			state.isUpdated = true;
			state.isDeleted = true;
			state.jobPostList = state.jobPostList.filter((item) => {
				return item.favId !== action.payload.favId;
			});
			localStorage.setItem("token", action.payload.token);
		},
		FAV_JOB_STATUS_LOADING: (state) => {
			state.isChanged = true;
		},
		FAV_JOB_NEW_SAVE_SUCCESS: (state, action) => {
			// state.jobPostList = [...state.jobPostList, action.payload];
			// state.jobPostList = state.jobPostList
			// 	.sort((a, b) => a.favId - b.favId)
			// 	.reverse();
			state.jobPostList = action.payload.jobPostList;
			localStorage.setItem("token", action.payload.token);
		},
	},
});

const {
	FAV_JOB_LOADING,
	FAV_JOB_PAGINATION_LOADING,
	FAV_JOB_LOAD_SUCCESS,
	FAV_JOB_LOAD_FAIL,
	FAV_JOB_REMOVE_SUCCESS,
	FAV_JOB_STATUS_LOADING,
	FAV_JOB_NEW_SAVE_SUCCESS,
} = favJobSlice.actions;

export const getFavJobPostList = ({
	acctId,
	accessToken,
	lastRecordSize,
	requestRecordSize,
	paginationClick,
}) => async (dispatch) => {
	const body = { acctId, accessToken, lastRecordSize, requestRecordSize };
	// console.log(body);
	if (paginationClick) dispatch(FAV_JOB_PAGINATION_LOADING());
	else dispatch(FAV_JOB_LOADING());

	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/jm/get-favourite-job-post-by-user-id`,
		params: body,
	})
		.then((res) => {
			// console.log(res);
			if (res.status === 201) {
				const response = {
					jobPostList: res.data.payload.jobPostList,
					token: res.data.token,
				};
				dispatch(FAV_JOB_LOAD_SUCCESS(response));
			} else {
				dispatch(FAV_JOB_LOAD_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const removeFavJob = ({ acctId, accessToken, email, favId }) => async (
	dispatch
) => {
	dispatch(FAV_JOB_PAGINATION_LOADING());
	const body = {
		acctId,
		accessToken,
		email,
		favId,
	};
	console.log(body);

	await axios({
		method: "DELETE",
		headers: header,
		url: `${instanceUrl}/api/jm/remove-favourite-job-post`,
		data: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				const response = {
					favId,
					token: res.data.token,
				};
				dispatch(FAV_JOB_REMOVE_SUCCESS(response));
			} else {
				dispatch(FAV_JOB_LOAD_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const favJobSave = ({
	acctId,
	accessToken,
	lastRecordSize,
	requestRecordSize,
	paginationClick,
}) => async (dispatch) => {
	const body = { acctId, accessToken, lastRecordSize, requestRecordSize };
	console.log(body);

	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/jm/get-favourite-job-post-by-user-id`,
		params: body,
	})
		.then((res) => {
			console.log(res);

			if (res.status === 201) {
				const response = {
					jobPostList: res.data.payload.jobPostList,
					token: res.data.token,
				};
				dispatch(FAV_JOB_NEW_SAVE_SUCCESS(response));
			} else {
				dispatch(FAV_JOB_LOAD_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};
// export const favJobSave = ({
// 	favId,
// 	companyName,
// 	favDate,
// 	profilePic,
// 	title,
// 	jobPostId,
// 	statusId,
// }) => (dispatch) => {
// 	const response = {
// 		favId,
// 		companyName,
// 		favDate,
// 		profilePic,
// 		title,
// 		jobPostId,
// 		statusId,
// 	};
// 	if (response) {
// 		dispatch(FAV_JOB_NEW_SAVE_SUCCESS(response));
// 	}
// };

export default favJobSlice.reducer;
