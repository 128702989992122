import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
	departmentViewRoadmap,
	viewRoadmapRoleByRoleId,
} from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Industry_Electrical_Engineering from "assets/img/Career_Roadmap/Electrical_Engineering/Industry_Electrical_Engineering_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function IndustryElectricalEngineering() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Industry_Electrical_Engineering;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "ee",
				shape: "poly",
				coords: [87,351,78,354,74,357,71,361,69,368,69,425,71,432,74,436,79,439,86,442,180,442,188,438,192,434,195,430,197,424,197,368,194,361,190,356,184,353,178,351,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "289",
				shape: "poly",
				coords: [226,356,221,358,218,361,215,366,214,371,215,420,218,427,220,430,224,432,229,433,364,432,369,430,372,427,375,423,376,418,377,372,375,364,372,360,364,356,358,356,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "290",
				shape: "poly",
				coords: [596,357,590,359,588,363,586,368,584,373,585,418,587,425,590,428,595,431,600,433,734,432,740,429,744,426,746,422,747,416,748,370,746,363,741,360,736,357,730,355,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "291",
				shape: "poly",
				coords: [968,356,963,359,960,362,958,364,956,372,956,420,958,426,962,430,966,432,974,434,1105,433,1112,431,1116,427,1117,424,1118,420,1119,370,1117,363,1114,359,1109,357,1104,356,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "292",
				shape: "poly",
				coords: [1342,356,1334,358,1331,361,1329,366,1328,371,1327,419,1328,425,1332,429,1336,431,1341,433,1476,433,1481,431,1484,428,1488,424,1490,418,1489,371,1487,362,1483,359,1476,356,1472,356,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "293",
				shape: "poly",
				coords: [1714,355,1707,357,1703,359,1701,362,1699,366,1698,371,1698,420,1701,426,1704,429,1710,432,1715,433,1852,432,1859,430,1863,426,1865,423,1866,418,1866,371,1865,363,1861,359,1856,356,1851,355,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "ee") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo);
			dispatch(viewRoadmapRoleByRoleId(requestInfo));

			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
