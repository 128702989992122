import {
	container,
	cardTitle,
	title,
	mlAuto,
	mrAuto,
	main,
	containerFluid,
	whiteColor,
	grayColor,
	blackColor,
	defaultFont,
	cardDetail,
	mainRaised,
	hexToRgb,
	opptyDesignColor,
} from "assets/jss/material-kit-pro-react.js";
import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";
import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.js";

const jobStatusPageStyle = (theme) => ({
	...imagesStyles,
	...tooltipsStyle,

	parallax: {
		height: "700px",
		backgroundPosition: "top center",
	},
	container: {
		...container,
		"@media (min-width: 576px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 768px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 992px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 1200px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 1400px)": {
			maxWidth: "80vw",
		},
	},
	cardFrame: {
		borderRadius: "18px",
		boxShadow: "none",
	},
	title: {
		...title,
		color: whiteColor + " !important",
	},
	cardTitle,
	cardClick: {
		cursor: "pointer",
		"&:hover,&:focus": {
			//color: whiteColor,
			//backgroundColor: grayColor[0],
			boxShadow:
				"0 9px 0px 0px white, 0 -9px 0px 0px white, 12px 0 15px -4px rgba(" +
				hexToRgb(grayColor[0]) +
				", 0.5), -12px 0 15px -4px rgba(" +
				hexToRgb(grayColor[0]) +
				", 0.5)",
			// "0 14px 26px -12px rgba(" +
			// hexToRgb(grayColor[0]) +
			// ", 0.42), 0 4px 23px 0px rgba(" +
			// hexToRgb(blackColor) +
			// ", 0.12), 0 8px 10px -5px rgba(" +
			// hexToRgb(grayColor[0]) +
			// ", 0.2)",
		},
	},
	main: {
		...main,
	},
	// mainRaised: {
	// 	marginTop: "20vh",
	// 	...mainRaised,
	// 	background: "#F2F3F7",
	// 	[theme.breakpoints.down("sm")]: {
	// 		marginTop: "200px",
	// 	},
	// 	minHeight: "1450px",
	// },
	mainRaised: {
		marginTop: "100px", //"20vh",
		...mainRaised,
		background: "#F2F3F7",
		boxShadow: "none",
		// minHeight: "1450px",
		"@media (max-width: 576px)": {
			marginTop: "100px",
		},
	},
	backGroundImage: {
		width: "100%",
		height: "100%",
	},
	textCenter: {
		textAlign: "center",
	},
	gridItem: {
		...mlAuto,
		...mrAuto,
	},
	applicationStatus: {
		// color: "#54c154 !important",
		textTransform: "uppercase",
		fontSize: "14px",
		fontWeight: "500 !important",
		[theme.breakpoints.down("xs")]: {
			fontSize: "11px",
			minHeight: "17px",
			margin: "0 0 10px 0!important",
		},
	},
	statusSubmitted: {
		color: "#1AD598",
	},
	statusReviewing: {
		color: "#FFAE00",
	},
	statusRejected: {
		color: "#F6777B",
	},
	statusUnsuccessful: {
		color: blackColor[0],
	},
	statusWithdraw: {
		color: "#687bfe",
	},
	statusShortlisted: {
		color: "#D277F6",
	},
	logoImage: {
		maxWidth: "150px",
		maxHeight: "150px",
		height: "150px",
		width: "150px",
		margin: "15px 0px",
	},
	gridItemMargin: {
		margin: "10px 0px",
		[theme.breakpoints.down("md")]: {
			margin: "0",
		},
	},
	rightGrid: {
		textAlign: "right",
		[theme.breakpoints.down("xs")]: {
			textAlign: "left",
		},
	},
	noDecoText: {
		textDecoration: "none",
		color: blackColor,
	},
	itemCenter: {
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		[theme.breakpoints.down("xs")]: {
			paddingRight: "0",
		},
	},
	buttonColor: {
		color: opptyDesignColor,
	},
	selectedTabButton: {
		height: "60px",
		backgroundColor: opptyDesignColor,
		borderRadius: "18px",
		color: whiteColor,
		fontSize: "18px",
		boxShadow:
			"0 2px 2px 0 rgba(" +
			hexToRgb(opptyDesignColor[0]) +
			", 0.14), 0 3px 1px -2px rgba(" +
			hexToRgb(opptyDesignColor[0]) +
			", 0.2), 0 1px 5px 0 rgba(" +
			hexToRgb(opptyDesignColor[0]) +
			", 0.12)",
		"&:hover,&:focus": {
			backgroundColor: opptyDesignColor,

			boxShadow:
				"0 14px 26px -12px rgba(" +
				hexToRgb(opptyDesignColor[0]) +
				", 0.42), 0 4px 23px 0px rgba(" +
				hexToRgb(blackColor) +
				", 0.12), 0 8px 10px -5px rgba(" +
				hexToRgb(opptyDesignColor[0]) +
				", 0.2)",
		},
		[theme.breakpoints.down("sm")]: {
			fontSize: "14px",
			whiteSpace: "normal",
			height: "50px",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
			whiteSpace: "normal",
			height: "50px",
		},
	},
	tabButton: {
		height: "60px",
		backgroundColor: "#f2f3f7",
		border: "#99B2C6 2px solid",
		borderRadius: "18px",
		fontSize: "18px",
		color: "#99B2C6",
		boxShadow:
			"0 2px 2px 0 rgba(" +
			hexToRgb("#99B2C6") +
			", 0.14), 0 3px 1px -2px rgba(" +
			hexToRgb("#99B2C6") +
			", 0.2), 0 1px 5px 0 rgba(" +
			hexToRgb("#99B2C6") +
			", 0.12)",
		"&:hover,&:focus": {
			backgroundColor: whiteColor,
			color: "#99B2C6",

			boxShadow:
				"0 14px 26px -12px rgba(" +
				hexToRgb("#99B2C6") +
				", 0.42), 0 4px 23px 0px rgba(" +
				hexToRgb(blackColor) +
				", 0.12), 0 8px 10px -5px rgba(" +
				hexToRgb("#99B2C6") +
				", 0.2)",
		},
		[theme.breakpoints.down("sm")]: {
			fontSize: "14px",
			whiteSpace: "normal",
			height: "50px",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
			whiteSpace: "normal",
			border: "#99B2C6 1px solid",
			height: "50px",
		},
	},
	description: {
		margin: "15px 0px !important",
		/* maxWidth: "600px", */
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		color: blackColor[0],
		fontWeight: "400",
		whiteSpace: "pre-line",
		"& p": {
			margin: "15px 0px",
		},
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		overflow: "hidden",
	},
	cardImage: {
		width: "90px", //"130px",
		// height: "130px",
		overflow: "hidden",
		padding: "0",
		[theme.breakpoints.up("xs")]: {
			width: "80px",
			// height: "140px",
		},
	},
	cardInfo: {
		backgroundColor: "#F2F3F7",
		borderRadius: "12px",
		boxShadow: "none",
	},
	smallDescription: {
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		color: blackColor[0],
		fontSize: "14px",
		fontWeight: "400",
	},
	title: {
		...title,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		position: "relative",
		marginTop: "25px",
		minHeight: "32px",
		textDecoration: "none",
		color: blackColor + "!important",
		"& svg": {
			width: "32px",
			height: "32px",
			marginRight: "20px",
			marginTop: "10px",
		},
	},
	titleWeight: {
		fontWeight: "400",
	},
	jobTitle: {
		...title,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		position: "relative",
		margin: "0 !important",

		textDecoration: "none",
		color: blackColor + "!important",
		"& svg": {
			width: "32px",
			height: "32px",
			marginRight: "20px",
			marginTop: "10px",
		},
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		overflow: "hidden",
		[theme.breakpoints.down("xs")]: {
			fontSize: "13px",
			minHeight: "13px",
		},
	},
	jobPostStatus: {
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		margin: "0",
		textDecoration: "none",
		color: grayColor[0] + "!important",
		fontWeight: "400",
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap",
		[theme.breakpoints.down("sm")]: {
			fontSize: "14px",
			minHeight: "18px",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
			minHeight: "18px",
		},
	},
	jobTime: {
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		margin: "0",
		textDecoration: "none",
		color: grayColor[0] + "!important",
		fontWeight: "400",
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap",
		fontSize: "14px",
		[theme.breakpoints.down("sm")]: {
			fontSize: "12px",
			minHeight: "18px",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "10px",
		},
	},
	companyName: {
		color: "#999",
		[theme.breakpoints.down("sm")]: {
			fontSize: "12px",
			margin: "0!important",
		},
		// textDecoration: "none",
		// color: opptyDesignColor + "!important",
		// fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		// fontWeight: "600",
		// fontSize: "16px",
		// [theme.breakpoints.down("sm")]: {
		// 	fontSize: "14px",
		// 	margin: "0!important",
		// },
	},
	modalCardBorder: {
		borderRadius: "18px",
		boxShadow: "none",
		padding: "10px 0px 10px 0px",
		marginBottom: "0px",
	},
	modalBackground: {
		background: "#fff",
	},
	titleAlign: {
		textAlign: "center",
	},
	modalCardPadding: {
		padding: "0px",
	},
	jobCard: {
		borderRadius: "0px",
		// borderBottom: "2px solid #f2f3f7",
		margin: "0px",
		boxShadow: "none",
	},
	jobCardBorder: {
		borderTop: "2px solid #f2f3f7",
	},
	jobCardFirst: {
		"&:hover,&:focus": {
			borderRadius: "18px 18px 0 0",
		},
	},
	jobCardLast: {
		"&:hover,&:focus": {
			borderRadius: "0 0 18px 18px",
		},
	},
	jobCardOnly: {
		"&:hover,&:focus": {
			borderRadius: "18px",
		},
	},
	description: {
		margin: "0px 0px 5px 0px",
		/* maxWidth: "600px", */
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		color: blackColor[0],
		fontWeight: "500",
		whiteSpace: "pre-line",
	},
	paginationAligned: {
		float: "right",
	},
	gridPadding: {
		paddingLeft: "3px",
		paddingRight: "3px",
	},
	buttonIconMargin: {
		"& svg": {
			width: "28px !important",
			height: "28px !important",
			color: opptyDesignColor,
			border: "3px solid" + opptyDesignColor,
			padding: "3px",
			borderRadius: "7px",
			/* backgroundColor: "#000000", */
			[theme.breakpoints.down("xs")]: {
				width: "22px !important",
				height: "22px !important",
				border: "2px solid" + opptyDesignColor,
			},
		},
		display: "flex",
		alignItems: "center",

		float: "right",
		/* margin: "5px 5px !important", */
		/* 	border: "3px solid" + opptyDesignColor, */

		padding: "0px !important",
	},
	buttonIcon: {
		"& svg": {
			width: "28px !important",
			height: "28px !important",
			color: blackColor[0],
			padding: "3px",
			borderRadius: "7px",
			/* backgroundColor: "#000000", */
		},
		display: "flex",
		alignItems: "center",

		float: "right",
		padding: "0px !important",
	},
});

export default jobStatusPageStyle;
