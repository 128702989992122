import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

import Clearfix from "components/Clearfix/Clearfix.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import footerStyle from "assets/jss/material-kit-pro-react/views/componentStyle/footerStyle.js";

import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import IconLibrary from "views/Component/IconLibrary.js";
import BottomNavBar from "components/BottomNavBar/BottomNavBar.js";
import { Grid, Hidden } from "@material-ui/core";
import { isMobile } from "react-device-detect";

const useStyles = makeStyles(footerStyle);

export default function AlphaFooter({ ...rest }) {
	const classes = useStyles();

	return (
		<React.Fragment>
			<Clearfix />
			<Footer
				className={classes.footer}
				content={
					<h5>
						<div
							className={
								classes.pullCenter +
								" " +
								classes.copyRight +
								" " +
								classes.smallDescription
							}
						>
							Copyright &copy; {1900 + new Date().getYear()}{" "}
							<a
								href="https://oppty.ai/home"
								target="_blank"
								className={classes.copyRightWordings}
							>
								OppTy
							</a>{" "}
							All Rights Reserved.
						</div>
					</h5>
				}
			>
				<div className={classes.footer}>
					<GridContainer
						justifyContent="space-between"
						alignContent="center"
						alignItems="center"
					>
						<GridItem xs={7} sm={7} md={4}>
							<GridContainer>
								<GridItem xs={6} sm={6} md={6}>
									<ul className={classes.linksVertical}>
										<li>
											<h5 className={classes.description}>
												<a href="https://oppty.ai/home" target="_blank">
													Home{" "}
												</a>
											</h5>
										</li>

										<li>
											<h5 className={classes.description}>
												<a href="https://oppty.ai/about" target="_blank">
													About
												</a>
											</h5>
										</li>
										<li>
											<h5 className={classes.description}>
												<a href="https://oppty.ai/features" target="_blank">
													Features
												</a>
											</h5>
										</li>
									</ul>
								</GridItem>
								<GridItem xs={6} sm={6} md={6}>
									<ul className={classes.linksVertical}>
										<li>
											<h5 className={classes.description}>
												<a
													href="https://oppty.ai/privacy-policy"
													target="_blank"
												>
													Privacy Policy
												</a>
											</h5>
										</li>
										<li>
											<h5 className={classes.description}>
												<a href="https://oppty.ai/terms-of-use" target="_blank">
													Terms of Use{" "}
												</a>
											</h5>
										</li>

										<li>
											<h5 className={classes.description}>
												<a href="https://oppty.ai/contact" target="_blank">
													Contact
												</a>
											</h5>
										</li>
									</ul>
								</GridItem>
							</GridContainer>
						</GridItem>
						<GridItem xs={5} sm={5} md={3} className={classes.rightAlign}>
							<h4 className={classes.title + " " + classes.footerTitleMargin}>
								Connect With Us
							</h4>
							<ul /* className={classes.socialButtons} */>
								<li className={classes.liSocialButtons}>
									<Button
										/* className={classes.socialButton} */
										/* color="facebook" */
										justIcon
										color="transparent"
										className={
											classes.footerIconMargin + " " + classes.facebookIconColor
										}
										onClick={() => {
											const win = window.open(
												"https://www.facebook.com/OppTyAI",
												"_blank"
											);
											win.focus();
										}}
									>
										{/* <FacebookIcon /> */}
										<IconLibrary.Facebook />
									</Button>
								</li>

								<li className={classes.liSocialButtons}>
									<Button
										className={
											classes.footerIconMargin +
											" " +
											classes.instagramIconColor
										}
										color="transparent"
										justIcon
										onClick={() => {
											const win = window.open(
												"https://www.instagram.com/oppty.ai/",
												"_blank"
											);
											win.focus();
										}}
									>
										<IconLibrary.Instagram />
										{/* <InstagramIcon /> */}
										{/* <IconLibrary.Instagram /> */}
										{/* <i className="fab fa-instagram fa-lg" /> */}
									</Button>
								</li>
								<li className={classes.liSocialButtons}>
									<Button
										className={
											classes.footerIconMargin + " " + classes.linkedinIconColor
										}
										color="transparent"
										justIcon
										onClick={() => {
											const win = window.open(
												"https://sg.linkedin.com/company/opptyai",
												"_blank"
											);
											win.focus();
										}}
									>
										{/* <LinkedInIcon /> */}
										<IconLibrary.LinkedIn />
										{/* <i className="fab fa-linkedin-in" /> */}
									</Button>
								</li>
							</ul>
						</GridItem>
						<GridItem xs={12} sm={12} md={5}>
							<Card className={classes.cardFrame}>
								<CardBody>
									<GridContainer
										justifyContent="space-between"
										alignContent="center"
										alignItems="center"
									>
										<GridItem xs={12} sm={12} md={12} lg={7}>
											<h4 className={classes.title}>Have a question?</h4>
											<span className={classes.smallDescription}>
												Whether you need technical support or have a general
												enquiry, just drop us a message and our team will get
												back to you as soon as we can.
											</span>
										</GridItem>
										<GridItem xs={12} sm={12} md={12} lg={5}>
											<Button
												className={classes.emailButton}
												color="oppty"
												fullWidth
												href="https://oppty.ai/contact"
												target="_blank"
											>
												<b>Contact Us</b>
											</Button>
										</GridItem>
									</GridContainer>
								</CardBody>
							</Card>
						</GridItem>
					</GridContainer>
				</div>
			</Footer>
		</React.Fragment>
	);
}
