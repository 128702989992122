import React from "react";

import { useSelector, useDispatch } from "react-redux";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import ConnectionCardStyle from "assets/jss/material-kit-pro-react/components/connectionPageStyle.js";
import { getFollowProfileList } from "configurations/redux/manageConnectionSlice.js";

import Pagination from "views/Component/PaginationWithPage.js";
import NoResultCard from "./NoResultCard.js";
import SmallCardLoading from "../ConnectionPage/SmallCardLoading.js";
import FollowCard from "./FollowCard.js";

const useStyles = makeStyles(ConnectionCardStyle);

export default function ProfileComponent() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const followRecords = useSelector(
		(state) => state.manageConnection.followRecords
	);
	const userStatus = useSelector((state) => state.auth.user);
	const [currentPage, setCurrentPage] = React.useState(1);
	const [profilePerPage, setProfilePerPage] = React.useState(10);
	const indexOfLast = currentPage * profilePerPage;
	const indexOfFirst = indexOfLast - profilePerPage;
	const isLoading = useSelector((state) => state.manageConnection.isLoading);
	const isStatusUpdated = useSelector(
		(state) => state.manageConnection.isStatusUpdated
	);
	const followProfileCount = useSelector(
		(state) => state.manageConnection.followProfileCount
	);

	React.useEffect(() => {
		const response = {
			acctId: userStatus.acctId,
			email: userStatus.email,
			accessToken: localStorage.getItem("token"),
			lastRecordSize: 0,
			requestRecordSize: 10,
			keyword: "",
		};
		dispatch(getFollowProfileList(response));
	}, []);
	React.useEffect(() => {
		if (isStatusUpdated) {
			var newPage = 0;
			if (followRecords.length > 0) newPage = currentPage;
			else newPage = currentPage - 1;
			var indexOfFirst = newPage * profilePerPage - profilePerPage;
			setCurrentPage(newPage);

			const requestProfile = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				keyword: "",
				lastRecordSize: indexOfFirst,
				requestRecordSize: profilePerPage,
			};
			dispatch(getFollowProfileList(requestProfile));
		}
	}, [isStatusUpdated]);
	const paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
		var indexOfFirst = pageNumber * profilePerPage - profilePerPage;

		const requestProfile = {
			acctId: userStatus.acctId,
			accessToken: localStorage.getItem("token"),
			email: userStatus.email,
			keyword: "",
			lastRecordSize: indexOfFirst,
			requestRecordSize: profilePerPage,
		};
		dispatch(getFollowProfileList(requestProfile));
	};
	const ProfileList =
		followRecords &&
		followRecords.length > 0 &&
		followRecords.map((item) => {
			return (
				<div key={item.flpfId}>
					<FollowCard item={item} status={"Profile"} />
				</div>
			);
		});
	const loadingFields = [];
	for (let i = 0; i < profilePerPage - followRecords.length; i++) {
		loadingFields.push(
			<div key={i}>
				<SmallCardLoading />
			</div>
		);
	}
	return (
		<Card className={classes.cardFrame}>
			<CardBody className={classes.cardBodyPadding}>
				<h4 className={classes.componentTitle}>Followed Profile</h4>
				{isLoading &&
					loadingFields.map((item) => {
						return item;
					})}
				<div className={classes.mainContainer}>
					{ProfileList}{" "}
					{!isLoading && followRecords.length === 0 && (
						<NoResultCard description={"You have no following profile"} />
					)}{" "}
				</div>
				{followRecords && followRecords.length > 0 && (
					<Pagination
						totalPosts={followProfileCount}
						postsPerPage={profilePerPage}
						paginate={paginate}
					/>
				)}
			</CardBody>
		</Card>
	);
}
