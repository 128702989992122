import React from "react";
import ReactDOM from "react-dom";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { socialRegisterAction } from "configurations/redux/authSlice.js";

import { useDispatch } from "react-redux";

import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";

import registrationPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/RegistrationPage/registrationPageStyle.js";
import facebookIcon from "assets/svg/facebook.png";
import { facebookAppId } from "configurations/configurations.js";
import { ReactComponent as FacebookIconSvg } from "assets/svg/facebook-square-brands.svg";

const useStyles = makeStyles(registrationPageStyle);

const FacebookRegisterBtn = () => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const responseFacebook = (response) => {
		console.log(response);
		if (response.error) {
			const requestBody = {
				email: null,
				password: "",
				surname: "",
				firstName: "",
				middleName: "",
				acctStatusId: 0,
				acctTypeId: 3,
				socialAcctTypeId: 1,
				createdAcctId: 0,
				updateAcctId: 0,
				socialAcctId: "",
			};
			dispatch(socialRegisterAction(requestBody));
		} else {
			const requestBody = {
				email: response.email,
				password: "",
				surname: response.name,
				firstName: "",
				middleName: "",
				acctStatusId: 0,
				acctTypeId: 3,
				socialAcctTypeId: 1,
				createdAcctId: 0,
				updateAcctId: 0,
				socialAcctId: response.userID,
			};
			dispatch(socialRegisterAction(requestBody));
		}
	};
	const failureResponse = (response) => {
		console.log(response);
		// alert("Facebook Register has not been successfully done");
	};

	return (
		<React.Fragment>
			{/**old app Id 816059319116657 */}
			{/**live app Id  390058009215591*/}
			{/** test app Id 2850933938505741 */}
			<FacebookLogin
				appId={facebookAppId}
				autoLoad={false}
				callback={responseFacebook}
				onFailure={failureResponse}
				disableMobileRedirect={true}
				fields="name,email,picture"
				render={(renderProps) => (
					<div
						className={classes.facebookButtonContainer}
						onClick={renderProps.onClick}
					>
						<FacebookIconSvg style={{ color: "#FFFFFF" }} />
						<div className={classes.socialButtonText}>
							<span>Continue with Facebook</span>
						</div>
					</div>
				)}
			/>
		</React.Fragment>
	);
};

export default FacebookRegisterBtn;
