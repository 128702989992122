import React, { Fragment, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Header from "views/Component/Header.js";
import Footer from "views/Component/Footer.js";
import Hidden from "@material-ui/core/Hidden";
import clsx from 'clsx';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import testimonialsPageStyles from 'assets/jss/material-kit-pro-react/views/pageStyle/TestimonialsPage/testimonialsPageStyles';
import { Box, Button, makeStyles, Modal, Typography, IconButton } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import Avatar from "@mui/material/Avatar";
import Skeleton from "@mui/material/Skeleton";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { decryptData, TimeFormat } from 'configurations/configurations.js';
import axios from "axios";
import Cookies from "js-cookie";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import dp from "assets/img/faces/marc.jpg";
import cannotMessage from "assets/img/cannot-message.png";

import { useSnackbar } from "notistack";

/*Sourabh sir start*/
import {
	updateJobPostStatus,
	getJobApplications,
	jobSelectReset,
	getJobApplicationsWithStatus,
} from "configurations/redux/jobManagementSlice.js";
import { getSelectJobPosts, createUserEvent, updateApplicantStatus } from "configurations/redux/jobManagementSlice.js";
/*Sourabh sir end*/
import Profile from "assets/img/profileImg.jpg";
import IconLibrary from "views/Component/IconLibrary.js";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

import {
	activateEditType,
	deactivateEditType,
} from "configurations/redux/editProfileModalSlice";

import Tab from '@mui/material/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
// Research how to combine multiple style files in makeStyles


const ShortlistedTab = (match) => {


	console.log('**************');
	//console.log(match);
	console.log('**************');
	/*sandeep mam start*/
	const [progress, setProgress] = React.useState(10);
	/*   
		React.useEffect(() => {
		  const timer = setInterval(() => {
			setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
		  }, 800);
		  return () => {
			clearInterval(timer);
		  };
		}, []);
	   */
	// return <CircularProgressWithLabel value={progress} />;
	const [open11, setOpen11] = React.useState(false);
	const handleOpen11 = () => setOpen11(true);
	const handleClose11 = () => setOpen11(false);

	const [openPreMove, setOpenPreMove] = React.useState(false);
	const handleOpenPreMove = () => setOpenPreMove(true);
	const handleClosePreMove = () => setOpenPreMove(false);


	const [open22, setOpen22] = React.useState(false);
	const handleOpen22 = () => setOpen22(true);
	const handleClose22 = () => setOpen22(false);
	const [open222, setOpen222] = React.useState(false);
	const handleOpen222 = () => setOpen222(true);
	const handleClose222 = () => setOpen222(false);
	const [open33, setOpen33] = React.useState(false);
	const handleOpen33 = () => setOpen33(true);
	const handleClose33 = () => setOpen33(false);
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
	};
	const [age, setAge] = React.useState('');

	const handleChange = (event) => {
		setAge(event.target.value);
	};
	const [value, setValue] = React.useState('1');

	const handleChange111 = (event, newValue) => {
		setValue(newValue);
	};

	const BootstrapInput = styled(InputBase)(({ theme }) => ({
		'& .MuiInputBase-input': {
			borderRadius: 10,
			position: 'relative',
			backgroundColor: theme.palette.background.paper,
			border: '1px solid #ced4da',
			fontSize: 16,
			padding: '16.5px 14px',
			// Use the system font instead of the default Roboto font.
			fontFamily: [
				'-apple-system',
				'BlinkMacSystemFont',
				'"Segoe UI"',
				'Roboto',
				'"Helvetica Neue"',
				'Arial',
				'sans-serif',
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(','),
			'&:focus': {
				borderRadius: 10,
				borderColor: '#3f51b5',
			},
		},
	}));

	/*sandeep mam end*/

	const styles = makeStyles(testimonialsPageStyles)();
	const [optionToShow, setOption] = useState('myTestimonials');
	const getVideosHandler = () => {
		console.log("I M CLICKED");
	}
	const [attachmentType, setAttachmentType] = React.useState(4);
	const userStatus = useSelector(state => state.auth.user);
	//const isMe = decryptData(match.params.acctId) === userStatus.acctId;
	const tabs = ['myTestimonials', 'pendingRequests', 'testimonialsGiven'];

	const [payemtSubscriptionErr, setPayemtSubscriptionErr] = React.useState('');
	const [videoSizeErr, setvideoSizeErr] = React.useState('');
	const bytesToMegaBytes = size => size / (1024 ** 2);
	const history = useHistory();
	const dispatch = useDispatch();
	var isKicked = Cookies.get("isKicked");

	const auth = useSelector((state) => state.auth);
	const currUser = useSelector((state) => state.auth.user);
	const isError = useSelector((state) => state.auth.isError);
	const isVerified = useSelector((state) => state.auth.isVerified);
	const isResend = useSelector((state) => state.auth.isResend);
	const buildProfileFlag = useSelector((state) => state.auth.buildProfileFlag);
	const isViewed = useSelector((state) => state.user.isViewed);
	var user = useSelector((state) => state.user);
	const isLoading = useSelector((state) => state.user.isLoading);
	const blockCount = useSelector((state) => state.user.blockCount);
	const isUpdated = useSelector((state) => state.user.isUpdated);

	const [myself, setMyself] = useState(false);
	const [emailSend, setEmailSend] = useState(false);
	const [userData, setUserData] = useState(null);
	const [connStatus, setConnStatus] = useState(null);

	const [headerTab, setHeaderTab] = useState("myActivity");
	const [isEditProfileOpen, setIsEditProfileOpen] = useState(false);
	const [editOption, setEditOption] = useState("");
	const [isCannotMessageModalOpen, setIsCannotMessageModalOpen] = useState(
		false
	);
	const [createPost, setCreatePost] = React.useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	/*snackbarCustom sir start*/
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const onClickDismiss = (key) => () => {
		closeSnackbar(key);
	};


	const snackbarCustom = (title) => {
		const action = (key) => (
			<React.Fragment>
				<IconButton onClick={onClickDismiss(key)}>
					<CloseIcon />
				</IconButton>
			</React.Fragment>
		);
		snackBarShow(title, "default", action);
	};
	const snackBarShow = (message, variant, action) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(message, {
			variant: variant,
			action,
		});
	};

	/*snackbarCustom sir end*/

	/*Sourabh sir start*/
	const bizId = useSelector((state) => state.business.bizId);

	const [jobPostId, setJobPostId] = React.useState();





	const [meetingType, setMeetingType] = useState(false);


	const [timeSlot1Date, SettimeSlot1Date] = useState('');
	const [timeSlot2Date, SettimeSlot2Date] = useState('');
	const [timeSlot3Date, SettimeSlot3Date] = useState('');

	const [timeSlot1Time, SettimeSlot1Time] = useState('');
	const [timeSlot2Time, SettimeSlot2Time] = useState('');
	const [timeSlot3Time, SettimeSlot3Time] = useState('');
	const stateJobPostId = match.jobPostId;
	//const stateJobPostId = 
	//console.log('match');
	//	console.log(stateJobPostId);


	/* const applicationTabJobPost = useSelector(
		(state) => state.jobManagement.applications
	); */
	/* Short List Validation functions start*/
	function handleInterviewTyoeInput(event) {
		setMeetingType(event.target.value)

	};

	function handleTimeSlot1DateInput(event) {
		console.log('handleTimeSlot1DateInput');
		console.log(event.target.value);
		console.log('handleTimeSlot1DateInput');
		SettimeSlot1Date(event.target.value)
	};

	function handleTimeSlot2DateInput(event) {
		console.log('handleTimeSlot 2 DateInput');
		console.log(event.target.value);
		console.log('handleTimeSlot 2 DateInput');
		SettimeSlot2Date(event.target.value)
	};

	function handleTimeSlot3DateInput(event) {
		SettimeSlot3Date(event.target.value)
	};

	function handleTimeSlot1TimeInput(event) {
		console.log('handleTimeSlot1TimeInput');
		console.log(event.target.value);
		console.log('handleTimeSlot1TimeInput');
		SettimeSlot1Time(event.target.value)
	};

	function handleTimeSlot2TimeInput(event) {
		console.log('handleTimeSlot 2 TimeInput');
		console.log(event.target.value);
		console.log('handleTimeSlot 2 TimeInput');
		SettimeSlot2Time(event.target.value)
	};

	function handleTimeSlot3TimeInput(event) {
		SettimeSlot3Time(event.target.value)
	};



	/*Validation functions end*/

	const updateJobStatus = async (stateJobPostId, applicantId, status_id) => {



		console.log('------stateJobPostId---------' + stateJobPostId + '----applicantId--' + applicantId);


		/* 	const request = {
		acctId: userStatus.acctId,
		email: userStatus.email,
		accessToken: localStorage.getItem("token"),

		applicantId:applicantId,
		applId:stateJobPostId,
		applStatusId: 3

		}; */
		const request = {
			acctId: userStatus.acctId,
			email: userStatus.email,
			accessToken: localStorage.getItem("token"),
			//jobPostId: stateJobPostId,

			applicantId: applicantId,
			applId: stateJobPostId,
			applStatusId: status_id

		};

		dispatch(updateApplicantStatus(request));
		if (status_id == 1) {

			handleClosePreMove();
		}


		match.handleJobLoderStatusMethod(true);
		setTimeout(() => { getCandidateInfo() }, 3000);


	};

	const moveToInterview = async (stateJobPostId, applicantId) => {



		if (meetingType == '') {
			snackbarCustom("Select meeting type");
		}
		else if (timeSlot1Date == '') {
			snackbarCustom("Select first time-slot date.");
		}
		else if (timeSlot1Time == '') {
			snackbarCustom("Select first time-slot time.");
		}
		else if (timeSlot2Date == '') {
			snackbarCustom("Select second time-slot date.");
		}
		else if (timeSlot2Time == '') {
			snackbarCustom("Select second time-slot time.");
		}
		else if (timeSlot3Date == '') {
			snackbarCustom("Select third time-slot date.");
		}
		else if (timeSlot3Time == '') {
			snackbarCustom("Select third time-slot time.");
		}
		else {
			/* 	const requestProfile = {
		acctId: userStatus.acctId,
		email: userStatus.email,
		accessToken: localStorage.getItem("token"),
		bizId:bizId,
		title: "Interview Meeting",
		description: "",
		location:"",
		typeId:meetingType,
		statusId:1,
		recordTypeId:1,
		recordId:stateJobPostId,
		
		
		
		jobPostId: stateJobPostId,
		ipAddr: localStorage.getItem("ipAddr"),
		lastRecordSize: 0,
		requestRecordSize: 1,
		}; */






			//snackbarCustom("Code is working");
			let timer_arra = []
			let guest_arra = []

			let temp = {};;
			temp['startDate'] = timeSlot1Date + ' ' + timeSlot1Time;
			temp['endDate'] = timeSlot1Date + ' ' + timeSlot1Time;
			timer_arra.push(temp);

			temp = {};;
			temp['startDate'] = timeSlot2Date + ' ' + timeSlot2Time;
			temp['endDate'] = timeSlot2Date + ' ' + timeSlot2Time;
			timer_arra.push(temp);

			temp = {};;
			temp['startDate'] = timeSlot1Date + ' ' + timeSlot3Time;
			temp['endDate'] = timeSlot1Date + ' ' + timeSlot3Time;
			timer_arra.push(temp);

			//timer_arra.push(timeSlot2Date+' '+timeSlot2Time);
			//timer_arra.push(timeSlot3Date+' '+timeSlot3Time);
			console.log(timer_arra);
			//timer_arra =  JSON.stringify(timer_arra);

			const requestProfile = {
				acctId: userStatus.acctId,
				email: userStatus.email,
				accessToken: localStorage.getItem("token"),
				bizId: bizId,
				title: "Interview Meeting",
				description: "",
				eventDate: "2022-06-24",
				location: "",
				typeId: meetingType,
				statusId: 1,
				recordTypeId: 1,
				recordId: stateJobPostId,
				schedules: timer_arra,
				guests: guest_arra
			};
			console.log("requestProfile");
			console.log(requestProfile);
			dispatch(createUserEvent(requestProfile));

			updateJobStatus(stateJobPostId, applicantId, 9);
			handleClose22();

		}

	}


	const getCandidateInfo = () => {
		/* const requestProfile = {
			acctId: userStatus.acctId,
			email: userStatus.email,
			accessToken: localStorage.getItem("token"),
			jobPostId: stateJobPostId,
			ipAddr: localStorage.getItem("ipAddr"),
			lastRecordSize: 0,
			requestRecordSize: 1, 
		}; */
		const requestProfile = {
			acctId: userStatus.acctId,
			email: userStatus.email,
			accessToken: localStorage.getItem("token"),
			jobPostId: stateJobPostId,
			ipAddr: localStorage.getItem("ipAddr"),
			lastRecordSize: 0,
			requestRecordSize: 100,
		};

		console.log("I am clickedrequestProfile");
		console.log(requestProfile);
		console.log("I am clickedrequestProfile");

		dispatch(getJobApplicationsWithStatus(requestProfile));
		match.handleJobLoderStatusMethod(false);


	}


	const onRejectHandling = (item) => {
		handleClosePreMove();
		updateJobStatus(item.applId, item.acctId, 5);


	}
	/* END RAHUL CODE */


	const IsolatedInterviewPop = props => {

	}

	return (<div className='appTabContentInner shortlisted'>
		<Typography className='appliTitle'>Shortlisted</Typography>
		{match.applicantListMainData && match.applicantListMainData.shortlisted[0].candidateCount > 0 && match.applicantListMainData.shortlisted.map((item, index) => {

			return (
				<Typography key={index} className='applicationMain'>
					<Typography className='applicationTop'>
						<span className='appSpan'></span>
						<Typography className='appCen'>
							<div className="progress progressRed">
								<div className="barOverflow">
									<div className="bar"></div>
								</div>
								<span>80</span>
							</div>
						</Typography>
						<Typography className='applicationTopRight'>


						</Typography>
					</Typography>
					<Typography className='appNamee'>{item.firstName}</Typography>
					<p className='desc'>{item.headline}</p>
					<span className="TimeeSpan">{TimeFormat(item.applicationDate)}</span>
					<Typography component='div' className='dayOuter btnOuter rejectShortlist'>
						<Typography component='div' className='dayRight'>
							<Button onClick={handleOpenPreMove} className='rejectBtn'>Reject</Button>
							<Modal
								open={openPreMove}
								onClose={handleClosePreMove}
								aria-labelledby="modal-modal-title"
								aria-describedby="modal-modal-description"
							>
								<Box className='customBox' sx={style}>
									<Link onClick={handleClosePreMove} to="#" className='crossBtn'><i class="fas fa-xmark"></i></Link>
									<Typography className='folderDiv'><i class="fas fa-folder"></i></Typography>
									<Typography className='shortlistTitle'>
										Move <b>{item.firstName}</b> to
										<span> Application?</span>
									</Typography>
									<Typography component='div' className='dayOuter btnOuter rejectShortlist interviewBtns'>
										<Typography component='div' className='dayRight'>
											<Button onClick={handleClosePreMove} className='rejectBtn'>Undo</Button>
										</Typography>
										<Typography component='div' className='dayLeft'>
											<Button onClick={() => onRejectHandling(item)} className='applyBtn'>Confirm Move</Button>
										</Typography>
									</Typography>
								</Box>
							</Modal>
						</Typography>
						<Typography component='div' className='dayLeft'>
							<Button className='applyBtn' onClick={handleOpen22}>Interview</Button>
							<Modal
								open={open22}
								onClose={handleClose22}
								aria-labelledby="modal-modal-title"
								aria-describedby="modal-modal-description"
								className='customModal'
							>
								<Box className='customBox' sx={style}>
									<Typography className='scheduleModal'>Schedule Interview {item.firstName}</Typography>
									<Link to="#" onClick={handleClose22} className='crossBtn'><i class="fas fa-xmark"></i></Link>
									<GridContainer justifyContent="center" spacing={2}>
										<GridItem xs={12} sm={12} md={5} lg={5} xl={5}>
											<Typography className='inputOuter'>
												<label className='labelCustom'>Interview Method</label>
												<FormControl fullWidth>
													<InputLabel id="demo-simple-select-label">Choose an option</InputLabel>
													<Select
														labelId="demo-simple-select-label"
														id="demo-simple-select"
														//value={age}
														label="Age"
														onChange={handleInterviewTyoeInput}
													>
														<MenuItem value={1}><i class="fas fa-video customIcon"></i>Zoom Meeting</MenuItem>
														<MenuItem value={2}><i class="fas fa-user customIcon"></i>In-Person Interview</MenuItem>
														<MenuItem value={3}><i class="fas fa-phone customIcon"></i>Phone Interview</MenuItem>
													</Select>
												</FormControl>
												<label className='labelCustom chooseLocation'>Choose Location</label>
												<FormControl fullWidth>
													<div className='customFormSelect'>
														<GooglePlacesAutocomplete
															apiKey="AIzaSyCfHT53fTL_qLqFyAzW2Az4WoSXzT9LqSs"
														/>
													</div>
												</FormControl>
											</Typography>
										</GridItem>
										<GridItem xs={12} sm={12} md={7} lg={7} xl={7}>
											<div className='zoomMessage'>
												<i class="fas fa-video"></i>
												<p>You are signed in to Zoom as <br />project@auracletech.com. <a href="#">Sign out</a></p>
											</div>
										</GridItem>
									</GridContainer>
									<GridContainer justifyContent="center" spacing={2}>
										<GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
											<Typography className='inputOuter'>
												<label className='labelCustom'>Invite Other Attendees</label>
												<p className='inputdesc'>An email invite will be sent to all attendees.</p>
												<FormControl className='attendeeOuter' variant="standard">
													<InputLabel placeholder="Enter email address(es) here" htmlFor="demo-customized-textbox">Age</InputLabel>
													<BootstrapInput id="demo-customized-textbox" />
												</FormControl>
											</Typography>
										</GridItem>
									</GridContainer>
									<Typography className='timeSlotDiv'>
										<label className='labelCustom slotLabel'>Please choose 3 available timeslots for the interview.</label>
										<GridContainer justifyContent="center" spacing={6}>
											<GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
												<Typography className='inputOuter1'>
													<label className='labelCustom'>Timeslot 1 Date</label>
													<input className='customInput custom1' type='date' onChange={handleTimeSlot1DateInput} />
												</Typography>
											</GridItem>
											<GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
												<Typography className='inputOuter1'>
													<label className='labelCustom'>Timeslot 1 Time</label>
													<input className='customInput custom1' type='time' onChange={handleTimeSlot1TimeInput} />
												</Typography>
											</GridItem>
										</GridContainer>
										<GridContainer justifyContent="center" spacing={6}>
											<GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
												<Typography className='inputOuter1'>
													<label className='labelCustom'>Timeslot 2 Date</label>
													<input className='customInput custom1' type='date' onChange={handleTimeSlot2DateInput} />
												</Typography>
											</GridItem>
											<GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
												<Typography className='inputOuter1'>
													<label className='labelCustom'>Timeslot 2 Time</label>
													<input className='customInput custom1' type='time' onChange={handleTimeSlot2TimeInput} />
												</Typography>
											</GridItem>
										</GridContainer>
										<GridContainer justifyContent="center" spacing={6}>
											<GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
												<Typography className='inputOuter1'>
													<label className='labelCustom'>Timeslot 3 Date</label>
													<input className='customInput custom1' type='date' onChange={handleTimeSlot3DateInput} />
												</Typography>
											</GridItem>
											<GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
												<Typography className='inputOuter1'>
													<label className='labelCustom'>Timeslot 3 Time</label>
													<input className='customInput custom1' type='time' onChange={handleTimeSlot3TimeInput} />
												</Typography>
											</GridItem>
										</GridContainer>
									</Typography>
									<Typography component='div' className='dayOuter btnOuter rejectShortlist interviewBtns'>
										<Typography component='div' className='dayLeft'>
											<Button onClick={() => moveToInterview(item.applId, item.acctId)} className='applyBtn'>Submit</Button>
										</Typography>
									</Typography>
								</Box>
							</Modal>
						</Typography>
					</Typography>
				</Typography>
			)
		})

		}
	</div>
	);
};

export default ShortlistedTab;
