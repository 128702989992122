import React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { deleteTestimonial, updateTestimonial } from 'configurations/redux/testimonialSlice';
import { Popover, makeStyles } from '@material-ui/core';

import { useSelector, useDispatch } from 'react-redux';
import { activateEditType, deactivateEditType } from 'configurations/redux/editProfileModalSlice';
import testimonialCardStyles from 'assets/jss/material-kit-pro-react/views/pageStyle/TestimonialsPage/testimonialCardStyles.js';
import Fade from '@material-ui/core/Fade';
import { useModal } from 'hooks';
import ConfirmDialog from 'components/CustomDialog/ConfirmDialog.js';

const useStyles = makeStyles(testimonialCardStyles);

export default function TestimonialMenuComponent({ tsBody, tsStatus }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const userStatus = useSelector(state => state.auth.user);
  const actionDialog = useModal();
  const deleteModalContent = {
    title: 'Delete Testimonial',
    text: 'Are you sure to delete this testimonial?',
    cancelText: 'No',
    confirmText: 'Yes',
  };
  const deleteTestimonialFunction = modalClose => {
    const request = {
      accessToken: localStorage.getItem('token'),
      email: userStatus.email,
      acctId: userStatus.acctId,
      testimonialId: tsBody.testimonialId,
      tsStatus,
    };

    dispatch(deleteTestimonial(request)).then(() => modalClose());
  };
  const MenuButton = ({ tsBody }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = event => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const ownTestimonial = tsBody.ownerAcctId === userStatus.acctId;

    const RequestedMenu = () => {
      return (
        <React.Fragment>
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              handleClose();
              /* const request = {
								accessToken: localStorage.getItem("token"),
								email: userStatus.email,
								acctId: userStatus.acctId,
								testimonialId: tsBody.testimonialId,
								tsStatus,
							};
							console.log(request);

							dispatch(deleteTestimonial(request)); */
              actionDialog.toggle();
            }}
          >
            Delete Testimonial Request
          </MenuItem>
        </React.Fragment>
      );
    };
    const PendingMenu = () => {
      return (
        <React.Fragment>
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              handleClose();
              const request = {
                accessToken: localStorage.getItem('token'),
                email: userStatus.email,
                acctId: userStatus.acctId,
                testimonialId: tsBody.testimonialId,
                tsStatus,
                statusId: 4,
              };
              console.log(request);

              dispatch(updateTestimonial(request));
            }}
          >
            Approve Testimonial
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              /* const request = {
								accessToken: localStorage.getItem("token"),
								email: userStatus.email,
								acctId: userStatus.acctId,
								testimonialId: tsBody.testimonialId,
								tsStatus,
								statusId: 5,
							};
							console.log(request);

							dispatch(updateTestimonial(request)); */
              handleClose();
              const response = {
                type: 'REQUEST_REVISION',
                extension: { tsBody: tsBody, tsStatus: tsStatus },
              };
              dispatch(activateEditType(response));
            }}
          >
            Request Revision for Testimonial
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              handleClose();
              /* const request = {
								accessToken: localStorage.getItem("token"),
								email: userStatus.email,
								acctId: userStatus.acctId,
								testimonialId: tsBody.testimonialId,
								tsStatus,
							};
							console.log(request);

							dispatch(deleteTestimonial(request)); */
              actionDialog.toggle();
            }}
          >
            Delete Testimonial Request
          </MenuItem>
        </React.Fragment>
      );
    };
    const RevisionMenu = () => {
      return (
        <React.Fragment>
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              handleClose();
              /* const request = {
								accessToken: localStorage.getItem("token"),
								email: userStatus.email,
								acctId: userStatus.acctId,
								testimonialId: tsBody.testimonialId,
								tsStatus,
							};
							console.log(request);

							dispatch(deleteTestimonial(request)); */
              actionDialog.toggle();
            }}
          >
            Delete Testimonial
          </MenuItem>
        </React.Fragment>
      );
    };
    const ApprovedMenu = () => {
      return (
        <React.Fragment>
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              handleClose();

              const response = {
                type: 'REQUEST_REVISION',
                extension: { tsBody: tsBody, tsStatus: tsStatus },
              };
              dispatch(activateEditType(response));
            }}
          >
            Request Revision for Testimonial
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              handleClose();
              /* const request = {
								accessToken: localStorage.getItem("token"),
								email: userStatus.email,
								acctId: userStatus.acctId,
								testimonialId: tsBody.testimonialId,
								tsStatus,
							};
							console.log(request);

							dispatch(deleteTestimonial(request)); */
              actionDialog.toggle();
            }}
          >
            Delete Testimonial
          </MenuItem>
        </React.Fragment>
      );
    };

    //for author
    const AuthorRequestedMenu = () => {
      return (
        <React.Fragment>
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              handleClose();

              const response = {
                type: 'REPLY_TESTIMONIAL',
                extension: { tsBody: tsBody, tsStatus: tsStatus },
              };
              dispatch(activateEditType(response));
            }}
          >
            Reply Testimonial Request
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              handleClose();
              const request = {
                accessToken: localStorage.getItem('token'),
                email: userStatus.email,
                acctId: userStatus.acctId,
                testimonialId: tsBody.testimonialId,
                tsStatus,
                statusId: 5,
              };
              console.log(request);

              dispatch(updateTestimonial(request));
            }}
          >
            Reject Testimonial Request
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              handleClose();
              /* const request = {
								accessToken: localStorage.getItem("token"),
								email: userStatus.email,
								acctId: userStatus.acctId,
								testimonialId: tsBody.testimonialId,
								tsStatus,
							};
							console.log(request);

							dispatch(deleteTestimonial(request)); */
              actionDialog.toggle();
            }}
          >
            Delete Testimonial Request
          </MenuItem>
        </React.Fragment>
      );
    };
    const AuthorPendingMenu = () => {
      return (
        <React.Fragment>
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              handleClose();
              /* const request = {
								accessToken: localStorage.getItem("token"),
								email: userStatus.email,
								acctId: userStatus.acctId,
								testimonialId: tsBody.testimonialId,
								tsStatus,
							};
							console.log(request);

							dispatch(deleteTestimonial(request)); */
              actionDialog.toggle();
            }}
          >
            Delete Testimonial
          </MenuItem>
        </React.Fragment>
      );
    };
    const AuthorRevisionMenu = () => {
      return (
        <React.Fragment>
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              handleClose();
              const request = {
                accessToken: localStorage.getItem('token'),
                email: userStatus.email,
                acctId: userStatus.acctId,
                testimonialId: tsBody.testimonialId,
                tsStatus,
                statusId: 5,
              };
              console.log(request);

              dispatch(updateTestimonial(request));
            }}
          >
            Reject Revision for Testimonial
          </MenuItem>

          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              handleClose();

              const response = {
                type: 'REPLY_TESTIMONIAL',
                extension: { tsBody: tsBody, tsStatus: tsStatus },
              };
              dispatch(activateEditType(response));
            }}
          >
            Reply Revision for Testimonial
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              handleClose();
              /* const request = {
								accessToken: localStorage.getItem("token"),
								email: userStatus.email,
								acctId: userStatus.acctId,
								testimonialId: tsBody.testimonialId,
								tsStatus,
							};
							console.log(request);

							dispatch(deleteTestimonial(request)); */
              actionDialog.toggle();
            }}
          >
            Delete Revision Request
          </MenuItem>
        </React.Fragment>
      );
    };
    const AuthorApprovedMenu = () => {
      return (
        <React.Fragment>
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              handleClose();
              /* 	const request = {
								accessToken: localStorage.getItem("token"),
								email: userStatus.email,
								acctId: userStatus.acctId,
								testimonialId: tsBody.testimonialId,
								tsStatus,
							};
							console.log(request);

							dispatch(deleteTestimonial(request)); */
              actionDialog.toggle();
            }}
          >
            Delete Testimonial
          </MenuItem>
        </React.Fragment>
      );
    };

    return (
      <React.Fragment>
        <div>
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            aria-label="Open to show more"
            title="Open to show more"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu TransitionComponent={Fade} anchorEl={anchorEl} open={open} onClose={handleClose}>
            {ownTestimonial && tsBody.statusId === 1 && <RequestedMenu />}
            {ownTestimonial && tsBody.statusId === 2 && <PendingMenu />}
            {ownTestimonial && tsBody.statusId === 3 && <RevisionMenu />}
            {ownTestimonial && tsBody.statusId === 4 && <ApprovedMenu />}
            {!ownTestimonial && tsBody.statusId === 1 && <AuthorRequestedMenu />}
            {!ownTestimonial && tsBody.statusId === 2 && <AuthorPendingMenu />}
            {!ownTestimonial && tsBody.statusId === 3 && <AuthorRevisionMenu />}
            {!ownTestimonial && tsBody.statusId === 4 && <AuthorApprovedMenu />}
          </Menu>
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <MenuButton tsBody={tsBody} tsStatus />
      <ConfirmDialog
        {...{ ...actionDialog }}
        bodyContent={deleteModalContent}
        handleSubmit={() => {
          deleteTestimonialFunction(actionDialog.toggle);
        }}
      />
    </React.Fragment>
  );
}
