import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import CircularProgress from "@material-ui/core/CircularProgress";

import corporateProfilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/CorporateProfilePage/corporateProfilePageStyle.js";
import { Hidden } from "@material-ui/core";

const useStyles = makeStyles(corporateProfilePageStyle);

export default function CorporateJobLinkSection() {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [loadingJob, setLoadingJob] = React.useState(false);
	const statusDraft = useSelector((state) => state.jobManagement.statusDraft);
	const statusPublished = useSelector(
		(state) => state.jobManagement.statusPublished
	);
	const statusDeactivated = useSelector(
		(state) => state.jobManagement.statusDeactivated
	);

	return (
		<div>
			<Card className={classes.cardFrame}>
				<CardBody>
					<GridContainer
						justifyContent="space-between"
						alignContent="center"
						alignItems="center"
					>
						<GridItem xs={8} sm={8} md={8}>
							<h4 className={classes.title}>Your Job Post</h4>
						</GridItem>
						<GridItem xs={4} md={4} sm={4}>
							<Link
								className={classes.textDeco}
								to={{ pathname: "/corporate-job-management-page" }}
							>
								<Button
									color="oppty"
									round
									fullWidth
									className={classes.buttonHeight}
								>
									<Hidden xsDown>
										<b>View More</b>
									</Hidden>
									<Hidden smUp>
										<b>View</b>
									</Hidden>
								</Button>
							</Link>
						</GridItem>

						{loadingJob ? (
							<GridItem>
								<CircularProgress />
							</GridItem>
						) : (
							<GridItem>
									<GridContainer justifyContent="space-between">
									<GridItem
										xs={4}
										sm={4}
										md={4}
										className={
											classes.textCenter + " " + classes.infoAreaContainer
										}
									>
										<h1 className={classes.title + " " + classes.jobCountTitle}>
											{statusDraft}
										</h1>

										<h6>Draft Job{statusDraft > 1 && "s"}</h6>
									</GridItem>
									<GridItem
										xs={4}
										sm={4}
										md={4}
										className={
											classes.textCenter + " " + classes.infoAreaContainer
										}
									>
										<h1 className={classes.title + " " + classes.jobCountTitle}>
											{statusPublished}
										</h1>

										<h6>Publish Job{statusPublished > 1 && "s"}</h6>
									</GridItem>
									<GridItem
										xs={4}
										sm={4}
										md={4}
										className={
											classes.textCenter + " " + classes.infoAreaContainer
										}
									>
										<h1 className={classes.title + " " + classes.jobCountTitle}>
											{statusDeactivated}
										</h1>

										<h6>Deactivated Job{statusDeactivated > 1 && "s"}</h6>
									</GridItem>
								</GridContainer>
							</GridItem>
						)}
					</GridContainer>
				</CardBody>
			</Card>
		</div>
	);
}
