import { createSlice } from '@reduxjs/toolkit';

import axios from 'axios';
import { instanceUrl, header, headerFormData, emptyCache, encryptData } from 'configurations/configurations.js';

const userDashboardSlice = createSlice({
    name: 'userdashboard',
    initialState: {
        acctId: null,
        acctStatusId: null,
        acctTypeId: null,
    },
    reducers: {
        CALENDAR_EVENT_DATA: (state, action) => {
            state.acctId = action.payload.acctId;
            state.email = action.payload.email;
            state.accessToken = action.payload.accessToken;
            state.lastRecordSize = action.payload.lastRecordSize;
            state.fromDate = action.payload.fromDate;
            state.toDate = action.payload.toDate;
        },
    },
});

const {
    CALENDAR_EVENT_DATA
} = userDashboardSlice.actions;

/* export const defaultSearch = () => (dispatch) => {
  dispatch(DEFAULT_PROFILE_SEARCH());
}; */
export const getEventsByAcct = (customBody, cb) => async dispatch => {

    await axios({
        method: 'GET',
        headers: header,
        url: `${instanceUrl}/api/um/get-user-event-by-acct-id`,
        params: customBody,
    })
        .then(res => {
            if (res.status === 201) {
                // dispatch(CALENDAR_EVENT_DATA(res));
                cb(res.data);
            }
        })
        .catch(err => {
            if (err.message.includes('503')) {
                emptyCache();
            }
            console.log(err);
        });
};

export const getJobsRecommendedByAcct = ({
    acctId,
    email,
    accessToken,
    lastRecordSize,
    requestRecordSize
}, cb) => async dispatch => {
    const body = {
        acctId,
        email,
        accessToken,
        lastRecordSize,
        requestRecordSize
    };

    await axios({
        method: 'GET',
        headers: header,
        url: `${instanceUrl}/api/um/get-user-job-recommendation-by-acct-id`,
        params: body,
    })
        .then(res => {
            if (res.status === 201) {
                cb(res.data);
            }
        })
        .catch(err => {
            if (err.message.includes('503')) {
                emptyCache();
            }
            console.log(err);
        });
};

export const getArticlesRecommendedByAcct = ({
    acctId,
    email,
    accessToken,
    lastRecordSize,
    requestRecordSize
}, cb) => async dispatch => {
    const body = {
        acctId,
        email,
        accessToken,
        lastRecordSize,
        requestRecordSize
    };

    await axios({
        method: 'GET',
        headers: header,
        url: `${instanceUrl}/api/um/get-user-article-recommendation-by-acct-id`,
        params: body,
    })
        .then(res => {
            if (res.status === 201) {
                cb(res.data);
            }
        })
        .catch(err => {
            if (err.message.includes('503')) {
                emptyCache();
            }
            console.log(err);
        });
};

export const getEventDetailByEventId = ({
    acctId,
    email,
    accessToken,
    eventId
}, cb) => async dispatch => {
    const body = {
        acctId,
        email,
        accessToken,
        eventId
    };

    await axios({
        method: 'GET',
        headers: header,
        url: `${instanceUrl}/api/um/get-user-event-by-event-id`,
        params: body,
    })
        .then(res => {
            if (res.status === 201) {
                cb(res.data);
            }
        })
        .catch(err => {
            if (err.message.includes('503')) {
                emptyCache();
            }
            console.log(err);
        });
};

export const confirmEventScheduleStatus = ({
    acctId,
    email,
    accessToken,
    eventId,
    scheduleId,
    statusId
}, cb) => async dispatch => {
    const body = {
        acctId,
        email,
        accessToken,
        eventId,
        scheduleId,
        statusId
    };

    await axios({
        method: 'PUT',
        headers: header,
        url: `${instanceUrl}/api/um/update-user-event-schedule-status`,
        data: body,
    })
        .then(res => {
            if (res.status === 201) {
                cb(res.data);
            }
        })
        .catch(err => {
            if (err.message.includes('503')) {
                emptyCache();
            }
            console.log(err);
        });
};

export const declineEventScheduleStatus = ({
    acctId,
    email,
    accessToken,
    eventId,
    statusId
}, cb) => async dispatch => {
    const body = {
        acctId,
        email,
        accessToken,
        eventId,
        statusId
    };

    await axios({
        method: 'PUT',
        headers: header,
        url: `${instanceUrl}/api/um/update-user-event-status`,
        data: body,
    })
        .then(res => {
            if (res.status === 201) {
                cb(res.data);
            }
        })
        .catch(err => {
            if (err.message.includes('503')) {
                emptyCache();
            }
            console.log(err);
        });
};

export const getReportReasonByType = ({
    reasonType
}, cb) => async dispatch => {
    const body = {
        reasonType
    };

    await axios({
        method: 'GET',
        headers: header,
        url: `${instanceUrl}/api/md/get-report-reason-by-reason-type`,
        params: body
    })
        .then(res => {
            if (res.status === 201) {
                cb(res.data);
            }
        })
        .catch(err => {
            if (err.message.includes('503')) {
                emptyCache();
            }
            console.log(err);
        });
};

export const updateUserEventReschedules = ({
    acctId,
    email,
    accessToken,
    eventId,
    schedules
}, cb) => async dispatch => {
    const body = {
        acctId,
        email,
        accessToken,
        eventId,
        schedules
    };

    await axios({
        method: 'PUT',
        headers: header,
        url: `${instanceUrl}/api/um/update-user-event-reschedule`,
        data: body,
    })
        .then(res => {
            if (res.status === 201) {
                cb(res.data);
            }
        })
        .catch(err => {
            if (err.message.includes('503')) {
                emptyCache();
            }
            console.log(err);
        });
};

export const createReport = ({
    acctId,
    email,
    accessToken,
    reportTypeId,
    recordId,
    reasonId,
    reportDesc
}, cb) => async dispatch => {
    const body = {
        acctId,
        email,
        accessToken,
        reportTypeId,
        recordId,
        reasonId,
        reportDesc
    };

    await axios({
        method: 'POST',
        headers: header,
        url: `${instanceUrl}/api/rpt/create-report`,
        data: body,
    })
        .then(res => {
            if (res.status === 201) {
                cb(res.data);
            }
        })
        .catch(err => {
            if (err.message.includes('503')) {
                emptyCache();
            }
            console.log(err);
        });
};

export default userDashboardSlice.reducer;
