import React from "react";
import {
	getCountryId,
	getCurrencyId,
	getIndustryId,
	getEducationLevelId,
} from "configurations/configurations.js";
import classNames from "classnames";
import history from "../../../history.js";
import { useLocation } from "react-router";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getSelectJobPosts } from "configurations/redux/jobManagementSlice.js";
import {
	updateJobPostStatus,
	getJobApplications,
	jobSelectReset,
} from "configurations/redux/jobManagementSlice.js";
//Page Components
import Header from "views/Component/Header.js";
import Footer from "views/Component/Footer.js";

import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";

import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Muted from "components/Typography/Muted.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";

import Divider from "@material-ui/core/Divider";

import defaultAvatar from "assets/img/corporate_avatar.jpeg";
import JobEditModal from "./JobEditModal.js";
import corporateJobManagementPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/CorporateJobManagementPage/corporateJobManagementPageStyle.js";
import LoadingModal from "views/Component/LoadingModal.js";
import JobApplicantList from "./JobApplicantList.js";
import NoActivityStatusModal from "../NoActivityStatusModal/NoActivityStatusModal.js";
import Primary from "components/Typography/Primary.js";
import Backdrop from "../../Component/Backdrop.js";
import LoadingImage from "assets/img/Loading-pana.png";

import { decryptData } from "configurations/configurations.js";
import { Hidden } from "@material-ui/core";
import IconLibrary from "views/Component/IconLibrary.js";
import NoResultImage from "assets/img/No_Result.jpg";

const useStyles = makeStyles(corporateJobManagementPageStyle);
const publicIp = require("public-ip");

export default function CorporateJobViewPage({ props, match }) {
	/* console.log(match);
	console.log(decryptData(match.params.jobPostId));
 */
	const classes = useStyles();
	const location = useLocation();
	const dispatch = useDispatch();

	const [clientIp, setClientIp] = React.useState();
	const [jobPostId, setJobPostId] = React.useState();
	const [industryList, setIndustryList] = React.useState([]);
	const [countryList, setCountryList] = React.useState([]);
	const [currencyList, setCurrencyList] = React.useState([]);
	const [educationList, setEducationList] = React.useState([]);
	const [jobEditModal, setJobEditModal] = React.useState(false);
	const [updateLoading, setUpdateLoading] = React.useState(false);

	const stateJobPostId = decryptData(match.params.jobPostId);

	const closeJobEditModal = (e) => {
		setJobEditModal(false);
		/* 	window.location.reload(false); */
	};

	const userStatus = useSelector((state) => state.auth.user);

	const isSelected = useSelector((state) => state.jobManagement.isSelected);
	const jobPostList = useSelector((state) => state.jobManagement.jobPostList); //get job post by acct Id
	const isLoading = useSelector((state) => state.jobManagement.isLoading);
	const isChanged = useSelector((state) => state.jobManagement.isChanged);
	const selectedJobPost = useSelector(
		(state) => state.jobManagement.corpSelectedJobPost
	);
	const navigateNotificationLink = useSelector(
		(state) => state.eventNotification.navigateNotificationLink
	);
	const imageClasses = classNames(
		// classes.imgRaised,
		// classes.imgRoundedCircle,
		classes.imgProfile
	);
	const bizId = useSelector((state) => state.business.bizId);
	function yearMonthValue(monthValue) {
		if (monthValue >= 12) {
			if (monthValue % 12 === 0) {
				return " " + parseInt(monthValue / 12) + " Year";
			} else {
				return (
					" " +
					parseInt(monthValue / 12) +
					" Year " +
					(monthValue % 12) +
					" Month "
				);
			}
		} else {
			return " " + monthValue + " Month";
		}
	}
	const handleUpdateStatus = (item) => {
		const updateStatus = {
			email: userStatus.email,
			acctId: userStatus.acctId,
			accessToken: localStorage.getItem("token"),
			jobPostId: item.jobPostId,
			statusId:
				item.jobPostInfo.statusId === 0
					? 1
					: item.jobPostInfo.statusId === 1
						? 2
						: 1,
		};

		dispatch(updateJobPostStatus(updateStatus));
		if (!isChanged) {
			setUpdateLoading(false);
		}
	};
	const fetCountryList = async () => {
		const res = await getCountryId();
		setCountryList(res.data.payload);
	};
	const fetchCurrencyList = async () => {
		const res = await getCurrencyId();
		setCurrencyList(res.data.payload);
	};
	const fetchIndustryList = async () => {
		const res = await getIndustryId();
		setIndustryList(res.data.payload);
	};
	const fetchEducationLevelList = async () => {
		const res = await getEducationLevelId();
		setEducationList(res.data.payload);
	};

	React.useEffect(() => {
		fetCountryList();
		fetchCurrencyList();
		fetchIndustryList();
		fetchEducationLevelList();
		dispatch(jobSelectReset());
	}, []);

	// console.log(jobPostList,location.state.jobPostId)

	React.useEffect(() => {
		const requestProfile = {
			acctId: userStatus.acctId,
			email: userStatus.email,
			accessToken: localStorage.getItem("token"),
			jobPostId: stateJobPostId,
			ipAddr: localStorage.getItem("ipAddr"),
			lastRecordSize: 0,
			requestRecordSize: 1,
			bizId: bizId,
		};
		console.log(requestProfile);
		dispatch(getSelectJobPosts(requestProfile));
	}, [stateJobPostId]);

	React.useEffect(() => {
		if (selectedJobPost) {
			const requestProfile = {
				acctId: userStatus.acctId,
				email: userStatus.email,
				accessToken: localStorage.getItem("token"),
				jobPostId: stateJobPostId,
				lastRecordSize: 0,
				requestRecordSize: 9,
				ipAddr: localStorage.getItem("ipAddr"),
			};
			console.log(requestProfile);
			dispatch(getJobApplications(requestProfile));
		}
	}, [selectedJobPost]);

	const displayJobPost =
		selectedJobPost &&
		selectedJobPost.map((item) => {
			// console.log("item from job view page", item);
			return (
				<div
					key={item.jobPostId}
					className={classNames(
						classes.main,
						classes.mainRaised,
						classes.jobViewMargin
					)}
				>
					<div className={classes.container}>
						<GridContainer justifyContent="center">
							<GridItem xs={12} sm={12} md={12}>
								<div className={classes.profile}>
									{item.profilePic ? (
										<img
											src={item.profilePic}
											alt="..."
											className={imageClasses}
										/>
									) : (
										<img
											src={defaultAvatar}
											alt="..."
											className={imageClasses}
										/>
									)}

									<div className={classes.jobInfo}>
										<h3 className={classes.jobViewTitle}>
											{item.jobPostInfo.title}
										</h3>
										<div>
											<h5
												className={
													classes.description + " " + classes.grayJobType
												}
											>
												{item.jobPostInfo.jobPostType}
												{item.jobPostInfo.jobPostTypeId === 2 ? " - " : " "}
												{item.jobPostInfo.jobType}
											</h5>
										</div>
										<div>
											{item.jobPostInfo.statusId === 1 ? (
												<p
													className={
														classes.description +
														" " +
														classes.applicationStatus +
														" " +
														classes.greenStatus
													}
												>
													{item.jobPostInfo.statusName}
												</p>
											) : item.jobPostInfo.statusId === 2 ? (
												<p
													className={
														classes.description +
														" " +
														classes.applicationStatus +
														" " +
														classes.blueStatus
													}
												>
													{item.jobPostInfo.statusName}
												</p>
											) : (
												<p
													className={
														classes.description +
														" " +
														classes.applicationStatus +
														" " +
														classes.redStatus
													}
												>
													{item.jobPostInfo.statusName}
												</p>
											)}
										</div>
									</div>
								</div>
							</GridItem>
						</GridContainer>

						<GridContainer>
							{/* <GridItem>
								<Divider />
							</GridItem> */}
							<GridItem xs={12} sm={12} md={7}>
								<h4 className={classes.title}>Job Detail</h4>
								<Card className={classes.cardFrame}>
									<CardBody>
										<GridContainer>
											<GridItem
												xs={12}
												sm={6}
												md={6}
												className={
													/* classes.paperClass + " " + */ classes.gridItemMargin
												}
											>
												{/* <h5 className={classes.title}>Application Applied:</h5>
												<p className={classes.jobDescription}>
													{item.applCount} Applicant(s)
												</p> */}
												<div className={classes.jobDetail}>
													<span>Application Applied:</span> {item.applCount}{" "}
													Applicant(s)
												</div>
											</GridItem>
											<GridItem
												xs={12}
												sm={6}
												md={6}
												className={classes.gridItemMargin}
											>
												{/* <h5 className={classes.title}>Views Counted:</h5>
												<p className={classes.jobDescription}>
													{item.viewCount} View(s)
												</p> */}
												{/* <div className={classes.jobDetail}>
													<span>Views Counted:</span> {item.viewCount} View(s)
												</div> */}
											</GridItem>
										</GridContainer>
									</CardBody>
								</Card>
								<Card className={classes.cardFrame}>
									<CardBody>
										<GridContainer>
											<GridItem
												xs={12}
												sm={6}
												md={6}
												className={
													/* 	classes.paperClass + " " +  */ classes.gridItemMargin
												}
											>
												<div className={classes.jobDetail}>
													<span>Job Post Date:</span>{" "}
													{item.createDate.substring(0, 10)}
												</div>
												{/* <h5 className={classes.title}>Job Post Date:</h5>
												<p className={classes.jobDescription}>
													
												</p> */}
											</GridItem>
											<GridItem
												xs={12}
												sm={6}
												md={6}
												className={classes.gridItemMargin}
											>
												<div className={classes.jobDetail}>
													<span>Job Expiry Date:</span>{" "}
													{item.expiryDate.substring(0, 10)}
												</div>

												{/* <h5 className={classes.title}>Job Expiry Date:</h5>
												<p className={classes.jobDescription}>
													
												</p> */}
											</GridItem>
										</GridContainer>
									</CardBody>
								</Card>

								<Card className={classes.cardFrame}>
									<CardBody>
										<GridContainer>
											<GridItem
												xs={12}
												sm={6}
												md={6}
												className={classes.gridItemMargin}
											>
												<div className={classes.jobDetail}>
													<span>Country:</span> {item.jobPostInfo.countryName}
												</div>

												{/* <h5 className={classes.title}>Country:</h5>
												<p className={classes.jobDescription}>
													}
												</p> */}
											</GridItem>
											<GridItem
												xs={12}
												sm={6}
												md={6}
												className={classes.gridItemMargin}
											>
												<div className={classes.jobDetail}>
													<span>Location:</span>{" "}
													{item.jobPostInfo.locationName &&
														item.jobPostInfo.locationName !== ""
														? item.jobPostInfo.locationName
														: "N/A"}
												</div>

												{/* <h5 className={classes.title}>Location:</h5>
												<p className={classes.jobDescription}>
													
												</p> */}
												{console.log("calling", item.jobPostInfo)}
											</GridItem>
										</GridContainer>
									</CardBody>
								</Card>
								<Card className={classes.cardFrame}>
									<CardBody>
										<GridContainer>
											<GridItem
												xs={12}
												sm={6}
												md={6}
												className={classes.gridItemMargin}
											>
												<div className={classes.jobDetail}>
													<span>Minimum Qualification:</span>{" "}
													{item.jobPostInfo.eduLvlName}
												</div>
												{/* 
												<h5 className={classes.title}>Qualification:</h5>
												<p className={classes.jobDescription}>
													{" "}
													
												</p> */}
											</GridItem>
											<GridItem
												xs={12}
												sm={6}
												md={6}
												className={classes.gridItemMargin}
											>
												<div className={classes.jobDetail}>
													<span>Industry:</span> {item.jobPostInfo.industryName}
												</div>

												{/* <h5 className={classes.title}>Industry:</h5>
												<p className={classes.jobDescription}>
													
												</p> */}
											</GridItem>
										</GridContainer>
									</CardBody>
								</Card>
								<Card className={classes.cardFrame}>
									<CardBody>
										<GridContainer>
											<GridItem
												xs={12}
												sm={6}
												md={6}
												className={
													/* 	classes.paperClass + " " + */ classes.gridItemMargin
												}
											>
												<div className={classes.jobDetail}>
													<span>Lengths of Service:</span>{" "}
													{item.jobPostInfo.servicePeriod &&
														item.jobPostInfo.servicePeriod > 0
														? yearMonthValue(item.jobPostInfo.servicePeriod / 4)
														: "N/A"}
												</div>

												{/* <h5 className={classes.title}>Lengths of Service:</h5>
												<p className={classes.jobDescription}>
													
												</p> */}
											</GridItem>
											<GridItem
												xs={12}
												sm={6}
												md={6}
												className={classes.gridItemMargin}
											>
												<div className={classes.jobDetail}>
													<span>Salary:</span>{" "}
													{item.jobPostInfo.salaryMax &&
														item.jobPostInfo.salaryMax > 0
														? item.jobPostInfo.salaryMin +
														" " +
														item.jobPostInfo.currencyCode +
														" - " +
														item.jobPostInfo.salaryMax +
														" " +
														item.jobPostInfo.currencyCode
														: "N/A"}
												</div>
												{/* 
												<h5 className={classes.title}>Salary:</h5>
												 */}
											</GridItem>
										</GridContainer>
									</CardBody>
								</Card>
								<Card className={classes.cardFrame}>
									<CardBody>
										<h5 className={classes.jobCreateTitle}>Job Tags:</h5>

										{item.jobPostInfo.hashTags &&
											item.jobPostInfo.hashTags.map((item, index) => {
												return (
													item.hashtag && (
														<Chip
															key={index}
															label={
																<span className={classes.tagDescription}>
																	{item.hashtag}
																</span>
															}
															className={classes.chip}
															color={"secondary"}
														/>
													)
												);
											})}
									</CardBody>
								</Card>

								<Card className={classes.cardFrame}>
									<CardBody>
										<h5 className={classes.jobCreateTitle}>Requirement:</h5>
										<div className={classes.jobDescription}>
											{/* <b> */}
											<div
												dangerouslySetInnerHTML={{
													__html: item.jobPostInfo.requirement,
												}}
											/>
											{/* </b> */}
										</div>
									</CardBody>
								</Card>
								<Card className={classes.cardFrame}>
									<CardBody>
										<h5 className={classes.jobCreateTitle}>Responsibility:</h5>
										<div className={classes.jobDescription}>
											{/* <b> */}
											<div
												dangerouslySetInnerHTML={{
													__html: item.jobPostInfo.responsibility,
												}}
											/>
											{/* </b> */}
										</div>
									</CardBody>
								</Card>
							</GridItem>
							<GridItem xs={12} sm={12} md={5}>
								<div className={classes.cardJobSummary}>
									<h4 className={classes.title}>Job Summary</h4>
									<Card className={classes.cardFrame}>
										<div className={classes.editIcon}>
											<Hidden mdUp>
												<Button
													onClick={(event) => {
														event.preventDefault();
														setJobEditModal(true);
													}}
													color="opptyWrapBorder"
													className={classes.buttonEdit}
												>
													<IconLibrary.Pen />
												</Button>
											</Hidden>
											<Hidden smDown>
												<Button
													onClick={(event) => {
														event.preventDefault();
														setJobEditModal(true);
													}}
													color="opptyWrap"
													className={classes.buttonEdit}
												>
													<IconLibrary.Pen /> EDIT
												</Button>
											</Hidden>
										</div>
										<CardBody>
											<div
												className={
													classes.jobSummary + " " + classes.jobSummaryTitle
												}
											>
												<span>Job Title:</span>
												{item.jobPostInfo.title}
											</div>
											<div className={classes.jobSummary}>
												<span>Company:</span>
												{item.companyName}
											</div>
											<div className={classes.jobSummary}>
												<span>Industry:</span>
												{item.jobPostInfo.industryName}
											</div>
											{item.jobPostInfo.locationName &&
												item.jobPostInfo.locationName != "" ? (
												<div className={classes.jobSummary}>
													<span>Location:</span>
													{item.jobPostInfo.locationName}
												</div>
											) : null}
											<div className={classes.jobSummary}>
												<span>Country:</span>
												{item.jobPostInfo.countryName}
											</div>
											<div className={classes.jobSummary}>
												<span>Post Date:</span>
												{item.createDate.substring(0, 10)}
											</div>
											{/* 	<h6>
											Post Date:{" "}
											<Muted>
												<h6>{item.createDate.substring(0, 10)}</h6>
											</Muted>
										</h6> */}
											<div className={classes.jobSummary}>
												<span>Minimum Qualification:</span>
												{item.jobPostInfo.eduLvlName}
											</div>
											{/* <h6>
											Qualification:{" "}
											<Muted>
												<h6>{item.jobPostInfo.eduLvlName}</h6>
											</Muted>
										</h6> */}
											<div className={classes.jobSummary}>
												<span>Length of Service:</span>
												{item.jobPostInfo.servicePeriod &&
													item.jobPostInfo.servicePeriod > 0
													? yearMonthValue(item.jobPostInfo.servicePeriod / 4)
													: "N/A"}
											</div>
											{/* 	<h6>
											Lengths of Service:{" "}
											<Muted>
												<h6>
													
												</h6>
											</Muted>
										</h6> */}
											<div className={classes.jobSummary}>
												<span>Salary:</span>
												{item.jobPostInfo.salaryMax &&
													item.jobPostInfo.salaryMax > 0
													? item.jobPostInfo.currencyCode +
													" " +
													item.jobPostInfo.salaryMin +
													" - " +
													item.jobPostInfo.currencyCode +
													" " +
													item.jobPostInfo.salaryMax
													: "N/A"}
											</div>
											{/* 	<h6>
											Salary:{" "}
											{item.jobPostInfo.salaryMax &&
											item.jobPostInfo.salaryMax > 0 ? (
												<h6>
													{item.jobPostInfo.currencyCode}{" "}
													{item.jobPostInfo.salaryMin}-{" "}
													{item.jobPostInfo.currencyCode}{" "}
													{item.jobPostInfo.salaryMax}
												</h6>
											) : (
												<h6>N/A</h6>
											)}
										</h6> */}
											<div className={classes.jobSummary}>
												<span>Job Post Type:</span>
												{item.jobPostInfo.jobPostType}
											</div>
											<div className={classes.jobSummary}>
												<span>Employment Type:</span>
												{item.jobPostInfo.jobType}
											</div>
											<div className={classes.jobSummary}>
												<span>Experience Level:</span>
												{item.jobPostInfo.experience &&
													item.jobPostInfo.experience !== ""
													? item.jobPostInfo.experience
													: "N/A"}
											</div>

											{/* <h6>
											Job Post Type:{" "}
											<Muted>
												<h6>
													
												</h6>
											</Muted>
										</h6>
 */}
											<GridContainer>
												<GridItem
													xs={6}
													sm={6}
													md={6}
													className={classes.gridItemMargin}
												>
													{/* <Button
														fullWidth
														color="opptyWrapBorder"
														round
														onClick={() => {
															setJobEditModal(true);
														}}
														className={classes.buttonHeight}
													>
														<b>Edit</b>
													</Button> */}
													<Button
														onClick={(value, event) => {
															setUpdateLoading(true);

															handleUpdateStatus(item);
														}}
														color="grayWrapBorder"
														round
														fullWidth
														disabled={
															item.jobPostInfo.statusId === 0 ||
																item.jobPostInfo.statusId === 2
																? true
																: false
														}
														className={classes.buttonHeight}
													>
														<b>Deactivate</b>
													</Button>
												</GridItem>
												<GridItem
													xs={6}
													sm={6}
													md={6}
													className={classes.gridItemMargin}
												>
													<Button
														onClick={(value, event) => {
															setUpdateLoading(true);

															handleUpdateStatus(item);
														}}
														color={
															item.jobPostInfo.statusId === 0 ||
																item.jobPostInfo.statusId === 1
																? "grayBorder"
																: "oppty"
														}
														round
														fullWidth
														disabled={
															item.jobPostInfo.statusId === 0 ||
																item.jobPostInfo.statusId === 1
																? true
																: false
														}
														className={classes.buttonHeight}
													>
														<b>Publish</b>
													</Button>
												</GridItem>
											</GridContainer>
										</CardBody>
									</Card>
								</div>
							</GridItem>
						</GridContainer>
						<JobApplicantList jobPostId={stateJobPostId} />

						<JobEditModal
							open={jobEditModal}
							onClose={closeJobEditModal}
							jobPostId={item.jobPostId}
							jobPostRecord={item.jobPostInfo}
							jobViewPage={true}
						/>
						{/* {console.log(item.jobPostInfo)} */}
					</div>
				</div>
			);
		});

	// console.log(isSelected, selectedJobPost, stateJobPostId);
	return (
		<div>
			{navigateNotificationLink && <Backdrop />}
			<div>
				<Header />
				{isLoading ? (
					<div className={classNames(classes.main, classes.mainRaised)}>
						<div className={classes.container}>
							<Backdrop />
							<GridContainer>
								<GridItem>
									<img
										src={LoadingImage}
										className={classes.backGroundImage}
										alt="Expired Session"
									/>
								</GridItem>
							</GridContainer>
						</div>
					</div>
				) : (
					<React.Fragment>
						{selectedJobPost && selectedJobPost.length > 0 ? (
							<React.Fragment>{displayJobPost}</React.Fragment>
						) : (
							// <NoActivityStatusModal />
							<div className={classNames(classes.main, classes.mainRaised)}>
								<div className={classes.container}>
									<GridContainer justifyContent="center">
										<Card className={classes.cardFrame}>
											<CardBody>
												<GridContainer justifyContent="center">
													<GridItem xs={12} sm={12} md={12}>
														<h5
															className={
																classes.title + " " + classes.textCenter
															}
														>
															This job post is not available. Please try for
															other job post.
														</h5>
													</GridItem>
													<GridItem
														xs={12}
														sm={12}
														md={6}
														className={classes.noResultGrid}
													>
														<img
															src={NoResultImage}
															alt="..."
															className={classes.noResultImage}
														/>
													</GridItem>
												</GridContainer>
											</CardBody>
										</Card>
									</GridContainer>
								</div>
							</div>
						)}
					</React.Fragment>
				)}

				<Footer />
			</div>
		</div>
	);
}
