import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { deleteExperienceRecord } from "configurations/redux/userSlice.js";

import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.js";

import style from "assets/jss/material-kit-pro-react/modalStyle.js";
import IconLibrary from "views/Component/IconLibrary.js";

const useStyles = makeStyles(style);
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

const DeleteExperienceModal = ({ open, onClose, expRecId }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user);
	const updateRequest = {
		acctId: user.acctId,
		email: user.email,
		accessToken: localStorage.getItem("token"),
		expRecId: expRecId,
	};

	return (
		<React.Fragment>
			<Dialog
				classes={{
					root: classes.modalRoot,
					paper: classes.modalLoader,
				}}
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={onClose}
				aria-labelledby="classic-modal-slide-title"
				aria-describedby="classic-modal-slide-description"
			>
				<DialogTitle
					id="classic-modal-slide-title"
					disableTypography
					className={classes.modalHeader}
				>
					<Button
						simple
						className={classes.modalCloseButton}
						key="close"
						aria-label="Close"
						onClick={onClose}
					>
						<IconLibrary.Close />
					</Button>
					<h4>
						<b>Delete Experience Record</b>
					</h4>
				</DialogTitle>
				<DialogContent
					id="classic-modal-slide-description"
					className={classes.modalBody}
				>
					<DialogContentText id="alert-dialog-slide-description">
						Are you sure to delete this experience record?
					</DialogContentText>
					{/* <h6>Are you sure to delete this experience record? </h6> */}
				</DialogContent>
				<DialogActions className={classes.modalFooter}>
					<Button
						onClick={() => {
							dispatch(deleteExperienceRecord(updateRequest));
							onClose();
						}}
						color="opptyWrap"
						round
					>
						<b>Delete</b>
					</Button>
					<Button color="opptyWrapBorder" round onClick={onClose}>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
};

export default DeleteExperienceModal;
