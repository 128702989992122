import React from "react";
import { useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/icons

import style from "assets/jss/material-kit-pro-react/modalStyle.js";
const useStyles = makeStyles(style);

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

export default function LoadingModal() {
	const classes = useStyles();
	return (
		<div>
			<Dialog
				classes={{
					root: classes.modalRoot,
					paper: classes.modalLoader,
				}}
				open={true}
				TransitionComponent={Transition}
				keepMounted
				// disableBackdropClick
				aria-labelledby="classic-modal-slide-title"
				aria-describedby="classic-modal-slide-description"
			>
				<DialogTitle>
					<p>Loading</p>
				</DialogTitle>
				<DialogContent
					id="classic-modal-slide-description"
					className={classes.modalBody}
				>
					<GridContainer>
						<GridItem className={classes.gridLoading}>
							<CircularProgress className={classes.loadingProgress} />
						</GridItem>
					</GridContainer>
				</DialogContent>
			</Dialog>
		</div>
	);
}
