const postSomethingCardStyles = () => ({
	body: {
		boxShadow: "none",
		overflow: "hidden",
		borderRadius: "15px!important",
		backgroundColor: "#fff",
		padding: "10px 15px",
		margin: "10px 0",
	},

	title: {
		color: "#171725",
		fontSize: "14px",
		fontWeight: 500,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
	},

	divider: {
		marginTop: "7px",
		marginBottom: "10px",
		marginLeft: "-5%",
		width: "110%",
	},

	main: {
		position: "relative",
		display: "flex",
	},

	profilePic: {
		height: "36px",
		aspectRatio: 1,
		borderRadius: "50%",
	},

	inputContainer: {
		display: "flex",
		flexBasis: "100%",
		marginLeft: "10px",
	},

	postInput: {
		flexBasis: "90%",
		minHeight: "46px",
		maxHeight: "150px",
		border: "none",
		paddingTop: "10px",
		fontSize: "14px",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,

		resize: "none",
	},

	galleryBtn: {
		position: "absolute",
		right: "-5px",
		bottom: 0,
		height: "fit-content",
		"& > span > svg": {
			stroke: "#92929d",
		},
	},
});

export default postSomethingCardStyles;
