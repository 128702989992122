import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCandidateSelectJobPosts } from "configurations/redux/jobManagementSlice.js";
import { TimeFormat } from "configurations/configurations.js";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { Link } from "react-router-dom";

import Tooltip from "@material-ui/core/Tooltip";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Primary from "components/Typography/Primary.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";

import jobStatusPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/JobStatusPage/jobStatusPageStyle.js";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js";
import Pagination from "../../../Component/PaginationWithPage.js";

import defaultAvatar from "assets/img/corporate_avatar.jpeg";
import EditIcon from "@material-ui/icons/Edit";
import LoadingImage from "assets/img/Loading-pana.png";

import { Divider } from "@material-ui/core";
import {
	getAppliedJobPostList,
	getAppliedJobPost,
} from "configurations/redux/appliedJobSlice";
import { resetNotificationItem } from "configurations/redux/eventNotificationSlice";
import IconLibrary from "views/Component/IconLibrary.js";
import {
	getContacts,
	sendContactInvitation,
} from "configurations/redux/userSlice.js";
import Backdrop from "../../../Component/Backdrop.js";
import { Hidden } from "@material-ui/core";
import { isDesktop } from "react-device-detect";
import CustomDialog from "../../../../components/CustomDialog/CustomDialog.js";
import { sendAllContactInvitation } from "configurations/redux/userSlice.js";

const publicIp = require("public-ip");

const useStyles = makeStyles(profilePageStyle); //profilePageStyle

export default function SyncContactListSection() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const userStatus = useSelector((state) => state.auth.user);

	const contactList = useSelector((state) => state.user.contactList);

	const loadingContactList = useSelector(
		(state) => state.user.loadingContactList
	);
	const caId = useSelector((state) => state.user.caId);
	const caName = useSelector((state) => state.user.caName);

	const [currentPage, setCurrentPage] = React.useState(1);
	const [contactPerPage, setContactPerPage] = React.useState(15);
	const [loading, setLoading] = React.useState(false);
	const [accountShow, setAccountShow] = React.useState();
	const contactAcctList = useSelector((state) => state.user.contactAcctList);

	const indexOfLastJob = currentPage * contactPerPage;
	const indexOfFirstJob = indexOfLastJob - contactPerPage;

	const paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
		var indexOfFirstJob = pageNumber * contactPerPage - contactPerPage;
		getContactList(indexOfFirstJob, true);
	};

	const getContactList = async (indexOfFirstContact, scroll) => {
		setLoading(true);
		var elmnt = document.getElementById("contactAccountSection");
		if (elmnt != null) {
			elmnt.scrollIntoView({
				behavior: "smooth",
			});
		}
		setTimeout(() => {
			const request = {
				caId: caId,
				caName: caName,
				acctId: userStatus.acctId,
				email: userStatus.email,
				accessToken: localStorage.getItem("token"),
				lastRecordSize: indexOfFirstContact,
				requestRecordSize: contactPerPage,
				paginationClick: scroll,
			};
			dispatch(getContacts(request));
			setLoading(false);
		}, 700);
	};

	const modalResize = () => {
		var modalResize = document.getElementById("templateCard");
		if (modalResize) {
			var maxSize = window.innerHeight - 74 - 120 - 70;
			if (maxSize < 0) maxSize = 0;
			modalResize.style.height = maxSize + "px";
		}
	};

	React.useEffect(() => {
		if (document.readyState === "complete") {
			setTimeout(() => {
				modalResize();
			}, 500);
		}
		window.addEventListener("resize", modalResize);
		return function cleanup() {
			window.removeEventListener("resize", modalResize);
		};
	});

	const clickChangeTab = (account) => {
		setCurrentPage(1);
		setAccountShow(account);
		console.log(contactAcctList);
		const caId =
			contactAcctList &&
			contactAcctList
				.filter((contactAcct) => contactAcct.caName === account)
				.map((contactAcct) => contactAcct.caId);
		const request = {
			caId: caId,
			caName: account,
			acctId: userStatus.acctId,
			email: userStatus.email,
			accessToken: localStorage.getItem("token"),
			lastRecordSize: 0,
			requestRecordSize: 15,
		};
		dispatch(getContacts(request));
	};

	const inviteAllContact = () => {
		const dialogDefault = {
			title: "Confirmation",
			description: `Are you sure to invite all contact(s) from ${accountShow}? The invitation will be processed on background automatically.`,
			buttonYes: "Proceed",
			buttonNo: "Cancel",
		};
		setConfirmDialog({
			isOpen: true,
			dialogDefault,
			onConfirm: () => {
				const request = {
					acctId: userStatus.acctId,
					email: userStatus.email,
					accessToken: localStorage.getItem("token"),
					caId: caId[0],
				};
				dispatch(sendAllContactInvitation(request));
				setConfirmDialog({
					isOpen: false,
					dialogDefault,
				});
			},
			onCancel: () => {
				setConfirmDialog({
					isOpen: false,
					dialogDefault,
				});
			},
		});
	};

	const dialogDefault = {
		title: "",
		description: "",
		buttonYes: "",
		buttonNo: "",
	};
	const [confirmDialog, setConfirmDialog] = React.useState({
		isOpen: false,
		dialogDefault,
	});

	React.useEffect(() => {
		if (document.readyState === "complete") {
			clickChangeTab("google");
		}
	}, [contactAcctList]);

	const ContactList =
		contactList &&
		contactList.map((item, index) => {
			var contactCard = classes.cardClick + " " + classes.contactCard;
			if (contactList) {
				if (contactList[0].ctId === item.ctId) {
					contactCard =
						classes.cardClick +
						" " +
						classes.contactCard +
						" " +
						classes.contactCardFirst;
				} else if (contactList[contactList.length - 1].ctId === item.ctId) {
					contactCard =
						classes.cardClick +
						" " +
						classes.contactCard +
						" " +
						classes.contactCardBorder +
						" " +
						classes.contactCardLast;
				} else {
					contactCard =
						classes.cardClick +
						" " +
						classes.contactCard +
						" " +
						classes.contactCardBorder;
				}
				if (contactList.length === 1) {
					contactCard =
						classes.cardClick +
						" " +
						classes.contactCard +
						" " +
						classes.contactCardOnly;
				}
			}
			return (
				<Card
					// className={classes.cardClick + " " + classes.cardFrame}
					className={contactCard}
					key={item.ctId}
				>
					<CardBody className={classes.cardPaddingLeft}>
						<GridContainer>
							<GridItem>
								<GridContainer alignContent="center" alignItems="center">
									<React.Fragment>
										<GridItem xs={10} sm={10} md={10}>
											<GridContainer alignContent="center" alignItems="center">
												<GridItem className={classes.gridItemMargin}>
													<h4 className={classes.contactTitle}>
														{item.firstName} {item.lastName}
													</h4>
													<h5
														className={
															classes.description + " " + classes.contactEmail
														}
													>
														{item.email}
													</h5>
													<h5
														className={
															classes.description +
															" " +
															classes.contactContactNo
														}
													>
														{item.contactNo}
													</h5>
												</GridItem>
											</GridContainer>
										</GridItem>
									</React.Fragment>
									{item.email === "" ? (
										<GridItem xs={2} sm={2} md={2}></GridItem> //Need Empty grid
									) : (
										<GridItem
											xs={2}
											sm={2}
											md={2}
											className={classes.itemCenter}
										>
											<Hidden mdUp>
												<Tooltip
													id="tooltip-top"
													title={item.statusName + " By Email"}
													placement="top"
													classes={{ tooltip: classes.tooltip }}
												>
													<Button
														onClick={(event) => {
															event.preventDefault();
															console.log(userStatus);
															const request = {
																acctId: userStatus.acctId,
																email: userStatus.email,
																accessToken: localStorage.getItem("token"),
																caId: caId,
																ctId: item.ctId,
																ctEmail: item.email,
																ctFirstName: item.firstName,
																ctLastName: item.lastName,
																contactList: contactList,
															};
															dispatch(sendContactInvitation(request));
														}}
														color={
															item.status === 0
																? "opptyNoBorder"
																: "grayWrapBorder"
														}
														disabled={item.status === 0 ? false : true}
														className={classes.buttonInvite}
													>
														{item.status === 2 ? (
															<IconLibrary.User />
														) : (
															<IconLibrary.Mail />
														)}{" "}
														<Hidden xsDown>{item.statusName}</Hidden>
													</Button>
												</Tooltip>
											</Hidden>
											<Hidden smDown>
												<Tooltip
													id="tooltip-top"
													title={item.statusName + " By Email"}
													placement="top"
													classes={{ tooltip: classes.tooltip }}
												>
													<Button
														onClick={(event) => {
															event.preventDefault();
															console.log(userStatus);
															const request = {
																acctId: userStatus.acctId,
																email: userStatus.email,
																firstName: userStatus.firstName,
																surname: userStatus.surname,
																accessToken: localStorage.getItem("token"),
																caId: caId,
																ctId: item.ctId,
																ctEmail: item.email,
																contactList: contactList,
															};
															dispatch(sendContactInvitation(request));
														}}
														color={
															item.status === 0
																? "opptyNoBorder"
																: "grayWrapBorder"
														}
														disabled={item.status === 0 ? false : true}
														className={classes.buttonInvite}
													>
														{item.status === 2 ? (
															<IconLibrary.User />
														) : (
															<IconLibrary.Mail />
														)}{" "}
														{item.statusName}
													</Button>
												</Tooltip>
											</Hidden>
										</GridItem>
									)}
								</GridContainer>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
			);
		});

	const NoContact = () => {
		return (
			<Card className={classes.cardFrame + " " + classes.cardFrameMargin}>
				<CardBody>
					<GridContainer justifyContent="center">
						<GridItem xs={12} sm={12} md={12} className={classes.itemCenter}>
							<h5 className={classes.title + " " + classes.titleWeight}>
								No contacts imported from this account yet
							</h5>
						</GridItem>
					</GridContainer>
				</CardBody>
			</Card>
		);
	};
	const LoadingContact = () => {
		return (
			<Card className={classes.cardFrame + " " + classes.cardFrameMargin}>
				<CardBody>
					<img
						src={LoadingImage}
						className={classes.backGroundImage}
						alt="Expired Session"
					/>
				</CardBody>
			</Card>
		);
	};
	// console.log(contactList);
	return (
		<React.Fragment>
			{confirmDialog && (
				<CustomDialog
					confirmDialog={confirmDialog}
					setConfirmDialog={setConfirmDialog}
				/>
			)}
			<Card className={classes.modalCardBorder + " " + classes.tabCardBorder}>
				<CardBody
					className={
						isDesktop
							? classes.smallModalBodyDesktop +
							  " " +
							  classes.modalBackground +
							  " " +
							  //   classes.modalBackgroundScroll +
							  " " +
							  classes.cropContainer
							: classes.smallModalBody +
							  " " +
							  classes.modalBackground +
							  " " +
							  //   classes.modalBackgroundScroll +
							  " " +
							  classes.cropContainer
					}
					id="templateCard"
				>
					<div id="contactAccountSection"></div>
					<GridContainer justifyContent="center">
						<GridItem xs={6} sm={6} md={6}>
							<Button
								fullWidth
								round
								className={
									accountShow === "google"
										? classes.selectedTabButton
										: classes.tabButton
								}
								onClick={() => {
									clickChangeTab("google");
								}}
							>
								<b>Google</b>
							</Button>
						</GridItem>
						<GridItem xs={6} sm={6} md={6}>
							<Button
								fullWidth
								round
								onClick={() => {
									clickChangeTab("microsoft");
								}}
								className={
									accountShow === "microsoft"
										? classes.selectedTabButton
										: classes.tabButton
								}
							>
								<b>Microsoft</b>
							</Button>
						</GridItem>
						<GridItem xs={4} sm={4} md={4}>
							<Button
								fullWidth
								round
								onClick={() => {
									inviteAllContact();
								}}
								className={classes.buttonInvite}
								color={"opptyWrapBorder"}
							>
								<b style={{ textTransform: "capitalize" }}>
									Invite All Contacts From {accountShow}
								</b>
							</Button>
						</GridItem>
					</GridContainer>

					<GridContainer>
						<GridItem>
							{accountShow && (
								<div id="contactCard">
									{/* <GridContainer>
									<GridItem> */}
									{loadingContactList || loading ? <Backdrop /> : null}
									{contactList && contactList.length > 0 ? (
										<React.Fragment>
											<div className={classes.contactPadding}>
												{ContactList}
											</div>
											<div className={classes.paginationAligned}>
												<Pagination
													totalPosts={contactList[0].contactCount}
													postsPerPage={contactPerPage}
													paginate={paginate}
													page={currentPage}
												/>
											</div>
										</React.Fragment>
									) : loadingContactList ? (
										<LoadingContact />
									) : (
										<NoContact />
									)}
									{/* </GridItem>
								</GridContainer> */}
								</div>
							)}
						</GridItem>
					</GridContainer>
				</CardBody>
			</Card>
		</React.Fragment>
	);
}
