import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import axios from "axios";

import { Box, makeStyles, Modal, Typography } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import { errorCleanUp } from "configurations/redux/authSlice.js";

import Header from "views/Component/Header.js";
import LeftSidebar from "views/Component/LeftSidebar.js";
import EditProfile from "views/Component/ProfileView/EditProfile/EditProfile";
import Footer from "views/Component/Footer.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import resumePageStyles from "assets/jss/material-kit-pro-react/views/pageStyle/ResumePage/resumePageStyles";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js";
import Hidden from "@material-ui/core/Hidden";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import AddIcon from "@mui/icons-material/Add";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import businessPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/BusinessPage/businessPageStyle";
import psycompleted from "assets/img/completed.png";



import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";


import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


const Psychometric = ({ match }) => {
   const styles = makeStyles(resumePageStyles)();
   const stylesBusiness = makeStyles(businessPageStyle)();
   const classes = makeStyles(profilePageStyle)(); //profilePageStyle

   const history = useHistory();
   const dispatch = useDispatch();
   const isError = useSelector((state) => state.auth.isError);
   const userStatus = useSelector((state) => state.auth.user);

   const searchResult = useSelector((state) => state.business.searchResult);
   const [connStatus, setConnStatus] = React.useState(null);

   const [isEditProfileOpen, setIsEditProfileOpen] = useState(false);

   const [width, height] = useWindowSize();

   const bizId = useSelector((state) => state.business.bizId);

   const [value, setValue] = React.useState(0);

   const handleChange = (event, newValue) => {
      setValue(newValue);
   };

   const [open13, setOpen13] = React.useState(false);
   const handleOpen13 = () => setOpen13(true);
   const handleClose13 = () => setOpen13(false);

   const [open103, setOpen103] = React.useState(false);
   const handleOpen103 = () => setOpen103(true);
   const handleClose103 = () => setOpen103(false);

   const [open105, setOpen105] = React.useState(false);
   const handleOpen105 = () => setOpen105(true);
   const handleClose105 = () => setOpen105(false);

   const [open14, setOpen14] = React.useState(false);
   const handleOpen14 = () => setOpen14(true);
   const handleClose14 = () => setOpen14(false);

   function createData(name, calories, fat, carbs, protein) {
      return { name, calories, fat, carbs, protein };
   }

   const rows = [
      createData('abx@gmail.com', 'Content Manager', 'Richdale Malaysia', 'Pending Acceptance', 'Remove Access'),
      createData('jansen@oppty.net', 'Recruiter Manager', 'OppTy Pte Ltd', 'Active', 'Remove Access'),

   ];

   const style01 = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 550,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
   };
   const style02 = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
   };

   function TabPanel(props) {
      const { children, value, index, ...other } = props;

      return (
         <div {...other}>
            {value === index && <Box p={3}>{children}</Box>}
         </div>
      );
   }

   function useWindowSize() {
      const [size, setSize] = React.useState([0, 0]);
      React.useLayoutEffect(() => {
         function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
         }
         window.addEventListener("resize", updateSize);
         updateSize();
         return () => window.removeEventListener("resize", updateSize);
      }, []);
      return size;
   }
   React.useEffect(() => {
      if (isError) {
         dispatch(errorCleanUp());
      }
   }, []);


   return (
      <div>
         <Header color={"transparent"} loggedInStatus={false} />

         <div className={styles.body}>
            <div id="pageHeader" className={styles.pageHeader}>
               <div className={styles.container}>
                  <GridContainer justifyContent="center" spacing={1}>


                     <GridItem xs={12} sm={12} md={12} lg={11}>
                        <div className="PsychometricOuter">

                           <Button className='applyBtn' onClick={handleOpen13}>Psychometric Test</Button>
                           <Modal
                              open={open13}
                              onClose={handleClose13}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description"
                           >
                              <Box className='PsychometricModal' sx={style01}>
                                 <Link className='crossBtn'>
                                    <i class="fas fa-xmark"></i></Link>
                                 <Typography className='innerText PsychometricText'>
                                    <h4>Question 1 of 20</h4>
                                    <h5>What do you most relate to?</h5>
                                    <Link href="#" className="greyOuter">
                                       <p>I prefer to spend most of my time in my own mind and thoughts.</p>
                                    </Link>
                                    <Link href="#" className="greyOuter blueOuter">
                                       <p>I prefer to spend most of my time interacting with people,<br /> things, and situations around me</p>
                                    </Link>
                                 </Typography>
                                 <Typography component='div' className='MuiTypography-root dayOuter btnOuter rejectShortlist interviewBtns MuiTypography-body2'>
                                    <Typography component='div' className='dayRight '>
                                       <Button className='rejectBtn psychometricright'>Back</Button>
                                    </Typography>
                                    <Typography component='div' className='dayLeft psychometricleft'>
                                       <Button className='applyBtn psychometricleft12'>Abort quiz</Button>
                                    </Typography>
                                 </Typography>
                              </Box>
                           </Modal>

                           <Button className='applyBtn' onClick={handleOpen14}>Abort Quiz</Button>
                           <Modal
                              open={open14}
                              onClose={handleClose14}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description"
                           >
                              <Box className='psychometricleftQuik' sx={style02}>
                                 <Link className='crossBtn'>
                                    <i class="fas fa-xmark"></i></Link>
                                 <Typography className='innerText PsychometricText'>

                                    <div className="quiz">
                                       <h5>Abort Quiz?</h5>
                                       <p>Your answers will not be saved and published.</p>
                                    </div>

                                 </Typography>
                                 <Typography component='div' className='MuiTypography-root dayOuter btnOuter rejectShortlist interviewBtns MuiTypography-body2'>
                                    <Typography component='div' className='dayRight '>
                                       <Button className='rejectBtn psychometricright'>Go back to quiz</Button>
                                    </Typography>
                                    <Typography component='div' className='dayLeft psychometricleft'>
                                       <Button className='applyBtn'>Confirm</Button>
                                    </Typography>
                                 </Typography>
                              </Box>
                           </Modal>

                           <Button className='applyBtn' onClick={handleOpen103}>Final Question</Button>
                           <Modal
                              open={open103}
                              onClose={handleClose103}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description"
                           >
                              <Box className='PsychometricModal FinalPsych' sx={style01}>
                                 <Link className='crossBtn'>
                                    <i class="fas fa-xmark"></i></Link>
                                 <Typography className='innerText PsychometricText'>
                                    <h4>Question 20 of 20</h4>
                                    <h5>What do you most relate to?</h5>
                                    <Link href="#" className="greyOuter">
                                       <p>I prefer to spend most of my time in my own mind and thoughts.</p>
                                    </Link>
                                    <Link href="#" className="greyOuter blueOuter">
                                       <p>I prefer to spend most of my time interacting with people,<br /> things, and situations around me</p>
                                    </Link>
                                 </Typography>
                                 <Typography component='div' className='MuiTypography-root dayOuter btnOuter rejectShortlist interviewBtns MuiTypography-body2'>
                                    <Typography component='div' className='dayRight '>
                                       <Button className='rejectBtn'>Back</Button>
                                    </Typography>
                                    <Typography component='div' className='dayLeft'>
                                       <Button className='applyBtn psychometricleft12'>Submit</Button>
                                    </Typography>
                                 </Typography>
                              </Box>
                           </Modal>
                           <Button className='applyBtn' onClick={handleOpen105}>Psychometric Test Completed</Button>
                           <Modal
                              open={open105}
                              onClose={handleClose105}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description"
                           >
                              <Box className='PsychometricModal FinalPsych' sx={style01}>
                                 <Link className='crossBtn'>
                                    <i class="fas fa-xmark"></i></Link>
                                 <Typography className='innerText psyCompletd'>
                                    <img src={psycompleted} />
                                    <h5>You have completed the<br /> Psychometric Test! </h5>
                                 </Typography>

                                 <Button variant="contained" className="seeyour">See your results</Button>
                              </Box>
                           </Modal>



                        </div>

                     </GridItem>

                  </GridContainer>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Psychometric;
