import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { connect, useSelector, useDispatch } from 'react-redux';
import { decryptData, gaPageView,communityArticlePath} from 'configurations/configurations.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Modal,Grid, Hidden, Typography } from "@material-ui/core";
import Pimg from 'assets/img/pimg.jpg';

import corporateProfilePageStyle from 'assets/jss/material-kit-pro-react/views/pageStyle/CorporateProfilePage/corporateProfilePageStyle.js';
import { getArticleSubcategoryByCategoryId,getArticleBySubcategoryId } from 'configurations/redux/communitySlice.js';

export default function CorporateJobTemplateSection() {
	
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
	};	
	
	const dispatch = useDispatch();
	const isLoading = useSelector(state => state.corpUser.isLoading);
	const isUpdated = useSelector(state => state.corpUser.isUpdated);
	const userStatus = useSelector(state => state.auth.user);
	const user = useSelector(state => state.corpUser);
	// const buildProfileFlag = useSelector((state) => state.auth.buildProfileFlag);
	const isViewed = useSelector(state => state.corpUser.isViewed);

	const [clientIp, setClientIp] = React.useState();
	const useStyles = makeStyles(corporateProfilePageStyle);
	const classes = useStyles();
	
	const [open11, setOpen11] = React.useState(false);
	const handleOpen11 = () => setOpen11(true);
	const handleClose11 = () => setOpen11(false);
	
	//const articleList = useSelector((state) => state.community);
	const articleList = useSelector(
		(state) => state.community.selectedArticle
	);
	console.log('--------articleList-------------');
	console.log(articleList);
	
	React.useEffect(() => {
		
		const requestCorpProfile = {
			accessToken: localStorage.getItem('token'),
			email: 'fengip4@gmail.com',
			catId: 3,
			lastRecordSize: 0,
			requestRecordSize:10,
			
			//ipAddr: localStorage.getItem('ipAddr'), //clientIp,
			//userId: userStatus.acctId,
			
		};
		dispatch(getArticleSubcategoryByCategoryId(requestCorpProfile));
		
	}, []);
	
	const openArticleBox = (id) => {
		
		alert(id);
		
				const requestCorpProfile = {
			accessToken: localStorage.getItem('token'),
			email: 'fengip4@gmail.com',
			subCatId: id,
			lastRecordSize: 0,
			requestRecordSize:10,
			keyword:''
			//ipAddr: localStorage.getItem('ipAddr'), //clientIp,
			//userId: userStatus.acctId,
			
		};
		dispatch(getArticleBySubcategoryId(requestCorpProfile));
	}
	
	
	return( <GridContainer justifyContent="center" spacing={2}>
			{articleList && articleList.map((item, index) => {
             return (
              <GridItem xs={12} sm={12} md={3} lg={3} xl={3} className={classes.middlePart}>
                <Link to="#">
                  <div className='jobtemplateDiv'>
				  {item.subCatName} 
                  </div>
                </Link>
                
              </GridItem>
			  )
		  })
		}
              <Modal
                  open={open11}
                  onClose={handleClose11}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  className='customModal calendarPopup'
                >
                  <Box className='customBox calendarBox' sx={style}>
                    <div className='calendarUpper'>
                      <Typography className='manageTitle warehouseTitle'>Warehouse Associate Job Description Template</Typography>
                      <Link className='crossBtn'><i class="fas fa-xmark"></i></Link>
                      <p className='wareTitleP'>This Warehouse Associate job description template includes the list of most important
                        Warehouse Associate's duties and responsibilities. It is customizable and ready to post to job boards. Use this Warehouse Associate job
                        description template to save time, attract qualified candidates and hire best employees.</p>
                      <img className='pimg' src={Pimg} alt='pimg' />
                      <Typography className='manageTitle warehouseTitle textLeft'>Warehouse Associate job description</Typography>
                      <p className='wareTitleP'>This Warehouse Associate job description template will help you save time, attract qualified candidates and
                        hire best employees.</p>
                      <p className='wareTitleP'>In order to attract Warehouse Associates that best matches your needs, it is very important to write a clear and precise
                        Warehouse Associate job description.</p>
                      <Typography className='manageTitle warehouseTitle textLeft'>Warehouse Associate job profile</Typography>
                      <p className='wareTitleP'>To write an effective Warehouse Associate job description, you need to understand what exactly a Warehouse Associate
                        does.</p>
                      <p className='wareTitleP'>Warehouse Associates are responsible for performing an array of duties such as receiving and processing incoming stock
                        and materials, picking and filling orders from stock, packing and shipping orders, or managing, organizing and retrieving stock
                        and other.</p>
                      <Typography className='manageTitle warehouseTitle textLeft'>Warehouse Associate job description: Intro</Typography>
                      <p className='wareTitleP'>Warehouse Associate job description should start with an interesting, eye-catching introduction.</p>
                      <p className='wareTitleP'>Here is an idea of what this introduction to Warehouse Associate job description may look like:</p>
                      <p className='wareTitleP paddingLeft'>We are looking for a hardworking Warehouse Associate. You will be responsible for performing an array of duties such as
                        receiving and processing incoming stock and materials, picking and filling orders from stock, packing and shipping
                        orders, or managing, organizing and retrieving stock and other.</p>
                      <Typography className='manageTitle warehouseTitle textLeft'>Warehouse Associate duties and responsibilities</Typography>
                      <p className='wareTitleP'>Warehouse Associate job description should contain the following duties and responsibilities:</p>
                      <ul className='wareUl'>
                        <li>Welcome delivery trucks</li>
                        <li>Receive and track shipments</li>
                        <li>Embark merchandise in delivery trucks using appropriate tools</li>
                        <li>Accommodate and carefully handle fragile merchandise</li>
                        <li>Track and document exact shipments arrival and departure times</li>
                        <li>Tag and label merchandise</li>
                        <li>Label and stockpile merchandise according to size, shape and type.</li>
                        <li>Box, wrap and pack merchandise in accordance with relevant procedures and standards</li>
                        <li>Perpare all orders for shipment</li>
                        <li>Record and manage all impaired or damaged merchandise</li>
                      </ul>
                    </div>
                  </Box>
                </Modal>
            </GridContainer>)
}