import { container } from "assets/jss/material-kit-pro-react.js";

const profileViewStyles = (theme) => ({
	editProfileModalBox: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		overflow: "hidden",
		borderRadius: "15px",
		backgroundColor: "#fff",
		/* maxWidth: "1000px", */
		/* 		minWidth: "720px", */
		width: "80vw",
		maxHeight: "1000px",
		height: "90vh",

		"& > button": {
			position: "absolute",
			top: "13px",
			right: "15px",
			padding: "0",
			minWidth: "fit-content",

			"& svg": {
				width: "15px",
				height: "15px",
			},
		},
		"@media (max-width: 768px)": {
			width: "100vw !important",
		},
	},

	cannotMessageModalBox: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		borderRadius: "15px",
		backgroundColor: "#fff",
		padding: "50px 40px 30px 40px",
		width: "460px",
		height: "480px",
		textAlign: "center",

		"& > button": {
			position: "absolute",
			top: "20px",
			right: "20px",
			padding: "0",
			minWidth: "fit-content",
		},

		"& > h2": {
			marginTop: "30px",
			padding: "0px 60px",
			color: "#000000",
			fontSize: "20px",
			fontWeight: 700,
			fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		},

		"& > p": {
			marginTop: "20px",
			color: "#6D7278",
			fontSize: "18px",
			fontWeight: 400,
			fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		},
	},

	body: {
		overflowY: "scroll",
		overflowX: "hidden",
		marginTop: "93px",
		height: "calc(100vh - 100px)",
		[theme.breakpoints.down("md")]: {
			marginTop: "85px",
		},
	},

	pageHeader: {
		position: "relative",
		display: "flex !important",
		alignItems: "center",
		margin: "0",
		border: "0",
		/* minHeight: "100vh", */
		height: "auto",
		// color: "#fff",
	},

	container: {
		...container,
		zIndex: "5",
		/* "@media (min-width: 576px)": {
			flexBasis: "98%",
		},
		"@media (min-width: 768px)": {
			flexBasis: "98%",
		},
		"@media (min-width: 960px)": {
			flexBasis: "98%",
		},
		"@media (min-width: 992px)": {
			flexBasis: "98%",
		},
		"@media (min-width: 1200px)": {
			flexBasis: "98%",
		}, */
		"@media (min-width: 576px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 768px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 992px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 1200px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 1400px)": {
			maxWidth: "95vw",
		},
	},
	leftSidebBar: {
		flexBasis: "19%",
		maxWidth: "250px",
		padding: "16px 10px",

		"@media (max-width: 1200px)": {
			display: "none",
		},
	},
	rightSideBar: {
		flexBasis: "23%",
		maxWidth: "285px",
		padding: "16px 10px",

		"@media (max-width: 1200px)": {
			display: "none",
		},
	},

	main: {
		maxWidth: "795px",
		padding: "16px 10px",

		"@media (max-width: 1200px)": {
			maxWidth: "100%",
		},
	},

	mainGridContainer: {
		width: "100%",
		margin: 0,
	},

	profileHeader: {
		flexBasis: "100%",
		padding: 0,
	},

	mainLeft: {
		maxWidth: "37%",
		// flexBasis: "37%",
		padding: 0,
	},

	cardHeader: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},

	cardTitle: {
		color: "#171725",
		fontSize: "14px",
		fontWeight: 500,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
	},
	cardFrame: {
		borderRadius: "18px",
		boxShadow: "none",
		marginTop: "10px",
		marginBottom: "10px",
		[theme.breakpoints.down("xs")]: {
			marginTop: "10px",
			marginBottom: "10px",
		},
	},
	cardTitleBtn: {
		color: "#6F52ED",
		fontSize: "12px",
		fontWeight: 500,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
	},

	divider: {
		marginLeft: "-4%",
		width: "108%",
		borderColor: "#F1F1F5",
	},

	cardMain: {},

	interestedIndustriesBody: {
		marginTop: "10px",
		borderRadius: "15px",
		backgroundColor: "#fff",
		padding: "10px 10px",
		width: "100%",
	},

	industryNamesContainer: {
		display: "flex",
		flexWrap: "wrap",
	},

	industryName: {
		marginRight: "5px",
		marginBottom: "5px",
		border: "solid 1px #979797",
		borderRadius: "8px",
		padding: "2px 10px",
		color: "#171725",
		fontSize: "12px",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		cursor: "pointer",
		transition: "background-color 250ms",

		"&:hover": {
			backgroundColor: "#e2ddeb",
		},
	},

	cvBody: {
		marginTop: "10px",
		borderRadius: "15px",
		backgroundColor: "#fff",
		padding: "10px 10px 20px",
		width: "100%",
	},

	cvsContainer: {
		display: "flex",
		flexWrap: "wrap",
		gap: "5px",
	},

	CV: {
		flexBasis: "32%",
		overflow: "hidden",
		borderRadius: "10px",

		"& > img": {
			width: "100%",
			aspectRatio: 1,
		},
	},

	testimonialBody: {
		marginTop: "10px",
		borderRadius: "15px",
		backgroundColor: "#fff",
		padding: "10px 10px",
		width: "100%",
	},

	attestentProfile: {
		display: "flex",
		alignItems: "center",
		padding: "0px 5px",

		"& > img": {
			borderRadius: "50%",
			width: "30px",
			aspectRatio: 1,
		},
	},

	attestentName: {
		marginLeft: "15px",
		color: "#171725",
		fontSize: "12px",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
	},

	testimonyStatement: {
		marginTop: "10px",
		padding: "0px 5px",
		color: "#171725",
		fontSize: "12px",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
	},

	mainRight: {
		maxWidth: "62%",
		// flexBasis: "62%",
		padding: "0 0 0 15px",
		minWidth: "470px",
	},
	tab: {
		position: "relative",
		zIndex: 3,
		boxSizing: "border-box",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: "10px 0 0",
		width: "50%",
		height: "100%",
		color: "#92929D",
		cursor: "pointer",
		transition: "0.2s ease-in-out",
	},

	tabActive: {
		color: "#6F52ED",
	},

	tabTitle: {
		fontSize: "14px",
		fontWeight: 500,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
	},

	tabActiveBorder: {
		position: "absolute",
		left: 0,
		right: 0,
		bottom: "0",
		marginTop: "10px",
		border: "none",
		borderTop: "solid 3px #6F52ED",
		borderRadius: "10px 10px 0 0",
		width: "100%",
	},
	testimonialsContentTabsContainer: {
		display: "flex",
		borderBottom: "solid 1px #979797",
		backgroundColor: "#fff",
		height: "50px",
		textTransform: "uppercase",
	},
	menuItem: {
		display: "flex",
		/* alignItems: "center" */
		padding: "6px 16px !important",
		cursor: "pointer",
		justifyContent: "flex-start",
	},
	createPostBtn: {
		// backgroundColor: "#6F52ED",
		fontSize: "20px",
		fontWeight: "medium",
		borderRadius: "20px",
		textTransform: "uppercase",
		margin: "0 0 20px",
	},
});

export default profileViewStyles;
