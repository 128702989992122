import React from 'react';
import { Button, InputLabel, makeStyles, TextField, Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import {
  instanceUrl,
  header,
  emptyCache,
  TimeFormatFirstDateOfMonth,
  getRelationshipByAcctId,
  getPositionByAcctId,
  writeTestimonialWithoutRequestSchema,
  replyTestimonialSchema,
} from 'configurations/configurations.js';
import {
  getConnectionList,
  createTestimonial,
  updateTestimonialContent,
} from 'configurations/redux/testimonialSlice.js';
import { Form, Formik } from 'formik';
import AsyncSelect from 'react-select/async';
import defaultAvatar from 'assets/img/candidate_avatar.jpeg';
import Select from 'react-select';
import writeTestimonialStyles from '../../../assets/jss/material-kit-pro-react/views/pageStyle/TestimonialsPage/requestTestimonialStyles';
import { activateEditType, deactivateEditType } from 'configurations/redux/editProfileModalSlice';
import ConfirmDialog from 'components/CustomDialog/ConfirmDialog.js';

export default function WriteTestimonialComponentWithRequest({
  isChanged,
  onClose,
  saveSettingsModalProps,
  setIsChanged,
}) {
  const styles = makeStyles(writeTestimonialStyles)();
  const dispatch = useDispatch();
  const [relationshipNature, setRelationshipNature] = React.useState([]);
  const [relationChange, setRelationChange] = React.useState();
  const userStatus = useSelector(state => state.auth.user);
  const isCreated = useSelector(state => state.testimonial.isCreated);
  const connectionList = useSelector(state => state.testimonial.connectionList);
  const testimonialExtension = useSelector(state => state.editProfileModal.extension);
  const extension = testimonialExtension && testimonialExtension.tsBody;
  const cancelForm = {
    title: 'Discard Action',
    text: 'All the actions will be discarded',
    cancelText: 'No',
    confirmText: 'Yes',
  };
  return (
    <div className={styles.body}>
      <h3 className={styles.title}>
        Testimonial Requested from {extension.ownerFirstName + ' ' + extension.ownerLastName}
      </h3>

      <div className={styles.formContainer}>
        <Formik
          enableReinitialize
          validationSchema={replyTestimonialSchema}
          initialValues={{
            testimonial: '',
          }}
          onSubmit={(values, actions) => {
            const request = {
              authorAcctId: extension.authorAcctId,
              tsPosition: extension.tsPosition,
              relationship: extension.relationship,
              message: extension.message,
              ownerAcctId: extension.ownerAcctId,
              email: userStatus.email,
              acctId: userStatus.acctId,
              accessToken: localStorage.getItem('token'),
              testimonial: values.testimonial,
              statusId: 2, //requesting
              tsStatus: testimonialExtension.tsStatus,
              testimonialId: extension.testimonialId,
            };
            console.log(request);
            dispatch(updateTestimonialContent(request));
            setTimeout(() => {
              dispatch(deactivateEditType());
            }, 1300);
          }}
        >
          {({ handleChange, values, errors, touched, handleBlur, handleSubmit, setFieldValue, resetForm }) => (
            <div style={{ height: '100%' }} onSubmit={handleSubmit}>
              <div className={styles.formArea}>
                <h4 className={styles.inputLabel}>Testimonial Requested from</h4>
                <div style={{ display: 'flex' }}>
                  <img
                    src={extension.ownerProfilePic ? extension.ownerProfilePic : defaultAvatar}
                    style={{
                      width: '48px',
                      height: '48px',
                      borderRadius: '50%',
                    }}
                  />
                  <h4
                    style={{
                      marginLeft: '10px',
                      fontWeight: 700,
                      fontSize: '16px',
                      color: 'rgba(0,0,0,0.6)',
                    }}
                  >
                    {extension.ownerFirstName + ' ' + extension.ownerLastName}
                  </h4>
                </div>

                {errors.ownerAcctId && touched.ownerAcctId && (
                  <h5 className={styles.errorMessage}>{errors.ownerAcctId}</h5>
                )}
                <h4 className={styles.inputLabel}>What is the nature of your relationship?</h4>
                <h4
                  style={{
                    fontWeight: 700,
                    fontSize: '16px',
                    color: 'rgba(0,0,0,0.6)',
                  }}
                >
                  {extension.relationship}
                </h4>
                {errors.relationship && touched.relationship && (
                  <h5 className={styles.errorMessage}>{errors.relationship}</h5>
                )}
                <InputLabel className={styles.inputLabel}>
                  What was the organisation/project/company that you worked on or in together?{' '}
                </InputLabel>
                <h4
                  style={{
                    fontWeight: 700,
                    fontSize: '16px',
                    color: 'rgba(0,0,0,0.6)',
                  }}
                >
                  {extension.tsPosition}
                </h4>
                {errors.position && touched.position && <h5 className={styles.errorMessage}>{errors.position}</h5>}
                <InputLabel className={styles.inputLabel}>Write Your Testimonial Here</InputLabel>
                <TextField
                  className={styles.textField}
                  variant="outlined"
                  fullWidth
                  id="testimonial"
                  name="testimonial"
                  placeholder="Your testimonial should be no more than 3000 characters."
                  value={values.testimonial}
                  onChange={event => {
                    handleChange(event);
                    setIsChanged(true);
                  }}
                  onBlur={handleBlur}
                  InputProps={{
                    multiline: true,
                    rows: 10,
                    border: '4px solid hsl(0, 0%, 80%) ',
                  }}
                  inputProps={{ className: styles.inputField }}
                />
                <h5 style={{ fontSize: '0.75rem' }}> {values.testimonial.length + '/3000'}</h5>
                {errors.testimonial && touched.testimonial && (
                  <h5 className={styles.errorMessage}>{errors.testimonial}</h5>
                )}
              </div>

              <div className={styles.submitButtonContainer}>
                <Button type="submit" onClick={handleSubmit} className={styles.submitButton}>
                  SUBMIT
                </Button>
              </div>
              <ConfirmDialog
                {...{ ...saveSettingsModalProps }}
                bodyContent={cancelForm}
                modalOpen={isChanged && saveSettingsModalProps.modalOpen}
                handleSubmit={() => {
                  setIsChanged(false);
                  onClose();
                }}
              />
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
}
