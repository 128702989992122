import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
	departmentViewRoadmap,
	viewRoadmapRoleByRoleId,
} from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Department_Merger_Acquisition from "assets/img/Career_Roadmap/Banking_Investment_Banking/Department_Merger_Acquisition_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentMergerAcquisition() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Merger_Acquisition;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "ma",
				shape: "poly",
				coords: [75,387,67,391,62,394,60,398,58,406,58,445,60,452,64,457,69,460,77,463,180,463,187,460,194,455,196,450,198,444,198,403,196,397,191,392,186,388,180,386,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "436",
				shape: "poly",
				coords: [241,386,231,388,227,390,225,394,222,401,222,449,225,456,229,460,234,462,240,464,428,463,435,461,440,458,443,454,444,448,445,405,443,395,439,389,434,386,425,385,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "437",
				shape: "poly",
				coords: [732,386,723,390,720,393,719,397,718,403,719,448,720,454,723,458,728,462,736,464,923,464,931,461,936,458,939,454,940,448,940,402,938,395,935,390,930,386,924,386,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "438",
				shape: "poly",
				coords: [1232,386,1223,388,1219,391,1216,396,1214,404,1215,449,1217,455,1220,460,1224,462,1232,463,1375,463,1382,461,1386,457,1389,453,1391,448,1391,403,1390,393,1385,389,1379,386,1373,386,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "439",
				shape: "poly",
				coords: [1679,386,1672,388,1668,391,1666,395,1665,402,1666,446,1668,456,1670,459,1676,463,1684,463,1823,463,1834,461,1838,458,1840,454,1842,450,1842,404,1840,394,1838,390,1834,388,1826,385,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "ma") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo);
			dispatch(viewRoadmapRoleByRoleId(requestInfo));

			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
