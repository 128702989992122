const educationStyles = () => ({
	main: {},

	header: {
		display: "flex",
		justifyContent: "end",
		textAlign: "right",
	},

	addNewBtn: {
		display: "flex",
		alignItems: "center",
		border: "solid 2px #687BFE",
		borderRadius: "8px",
		backgroundColor: "transparent",
		padding: "0 10px 0 8px",
		height: "29px",
		color: "#687BFE",
		fontSize: "13px",
		fontWeight: 400,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		cursor: "pointer",
		transition: "250ms",

		"&:hover": {
			backgroundColor: "#687bfe21",
		},

		"& svg": {
			marginRight: "10px",
			width: "11px",
			height: "11px",
		},
	},

	educationCard: {
		marginTop: "15px",
		borderRadius: "15px",
		backgroundColor: "#fff",
		padding: "10px 15px",
	},

	educationCardHeader: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",

		"& > h2": {
			color: "#000000",
			fontSize: "16px",
			fontWeight: 700,
			fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		},

		"& > button": {
			color: "#6F52ED",
			fontSize: "15px",
			fontWeight: 500,
			fontFamily: `"Roboto", "Helvetica", sans-serif !important`,

			"& svg": {
				marginRight: "5px",
				width: "14px",
				height: "14px",
			},
		},
	},
});

export default educationStyles;
