import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

import CommunityCardAds from "./CommunityCardAds.js";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { TimeFormat } from "configurations/configurations.js";

import defaultAvatar from "assets/img/corporate_avatar.jpeg";

import communityPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/CommunityPage/communityPageStyle.js";
import { CardMedia, Chip, CircularProgress, Divider } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Link } from "react-router-dom";
import { encryptData } from "configurations/configurations.js";
import SearchImage from "assets/img/Search_Image.jpg";
import NoResultImage from "assets/img/No_Result.jpg";
import { TimeFormatOnlyDate } from "configurations/configurations.js";

const useStyles = makeStyles(communityPageStyle);
const publicIp = require("public-ip");
var reWhiteSpace = new RegExp("\\s+");

export default function SearchResultCard({ articleList }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const userStatus = useSelector((state) => state.auth.user);
	const cardAdsCount = useSelector((state) => state.community.cardAdsCount);
	const isLoading = useSelector((state) => state.community.isLoading);
	const communityArticlePath = useSelector(
		(state) => state.community.communityArticlePath
	);
	function imageExists(image_url) {
		var http = new XMLHttpRequest();

		try {
			http.open("HEAD", image_url, false);
			http.send();
		} catch (error) {}

		return http.status == 200;
	}
	console.log(articleList);
	const ArticleList = articleList.map((item, index) => {
		return (
			<React.Fragment key={index}>
				{articleList.length > 4 ? (
					cardAdsCount > 0 && articleList[4].articleId === item.articleId ? (
						<React.Fragment>
							<GridItem xs={12} sm={6} md={6} lg={4} xl={3}>
								<CommunityCardAds />
							</GridItem>
						</React.Fragment>
					) : null
				) : null}

				<GridItem xs={12} sm={6} md={6} lg={4} xl={3}>
					<Link
						className={classes.textDeco}
						target="_blank"
						to={{
							pathname: `/community-detail-page/${encryptData(item.articleId)}`,
							//state: { acctId: item.acctId },
						}}
						color="transparent"
						key={item.articleId}
					>
						<div style={{ height: "290px" }} key={item.articleId}>
							<Card
								className={
									classes.cardClick +
									" " +
									classes.cardHeight +
									" " +
									classes.cardFrame
								}
								style={{ height: "100%" }}
							>
								<CardMedia
									className={classes.cardImage}
									image={
										item.coverPic &&
										imageExists(communityArticlePath + item.coverPic)
											? communityArticlePath + item.coverPic
											: defaultAvatar
									}
									title={item.articleName}
								/>
								{/* 		<CardBody className={classes.cardPadding}></CardBody> */}
								<CardBody className={classes.cardPadding}>
									<div>
										<GridContainer>
											<GridItem xs={6}>
												<Chip
													key={item.subcategoryId}
													label={
														<p
															className={
																classes.createdDescription +
																" " +
																classes.createdDescFont
															}
														>
															{item.subcategory}
														</p>
													}
													className={classes.chip}
													color={"secondary"}
												/>
											</GridItem>
											<GridItem xs={6} className={classes.textAlignRight}>
												<span className={classes.createdDescFont}>
													{TimeFormatOnlyDate(item.publishDate)}
												</span>
											</GridItem>
										</GridContainer>
									</div>
									<div className={classes.titleDiv}>
										<h4
											className={
												classes.articleTitle + " " + classes.articleCardTitle
											}
										>
											{item.articleName}
										</h4>
									</div>
									<div>
										<span className={classes.createdDescFont}> By : </span>
										<span
											className={
												classes.createdDescription +
												" " +
												classes.createdDescFont
											}
										>
											{item.author}
										</span>
									</div>
								</CardBody>
							</Card>
						</div>
					</Link>
				</GridItem>
				{articleList &&
				articleList.length < 5 &&
				cardAdsCount > 0 &&
				articleList[articleList.length - 1].articleId === item.articleId ? (
					<React.Fragment>
						<GridItem xs={12} sm={6} md={6} lg={4} xl={3}>
							<CommunityCardAds />
						</GridItem>
					</React.Fragment>
				) : null}
			</React.Fragment>
		);
	});

	const NoArticleList = () => {
		return (
			<Card className={classes.cardFrame}>
				<CardBody>
					<GridContainer justifyContent="center">
						<GridItem xs={12} sm={12} md={12}>
							{!isLoading && articleList.length == 0 && (
								<h5 className={classes.title + " " + classes.textCenter}>
									No available Article. Please use different filter options or
									keyword.
								</h5>
							)}
						</GridItem>
						<GridItem xs={12} sm={12} md={6}>
							<img
								src={isLoading ? SearchImage : NoResultImage}
								alt="..."
								className={classes.loadingImage}
							/>
						</GridItem>
					</GridContainer>
				</CardBody>
			</Card>
		);
	};

	return (
		<GridContainer className={classes.jobCardGrid}>
			{articleList.length > 0 ? ArticleList : <NoArticleList />}
			{/* 	<CommunityCardAds /> */}
		</GridContainer>
	);
}
