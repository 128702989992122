const suggestedGroupsStyles = () => ({

  main: {
    overflow: "hidden",
    marginTop: "20px",
    borderRadius: "15px",
    backgroundColor: "#fff",
    padding: "20px",
    width: "100%",
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  mainTitle: {
    color: "#171725",
    fontSize: "18px",
    fontWeight: 600,
  },

  mainDescription: {
    marginTop: "5px",
    color: "#92929D",
    fontSize: "14px",
    fontWeight: 400,
  },

  seeAllBtn: {
    color: "#0062FF",
    fontSize: "12px",
    fontWeight: 500,
  },

  groupCardsContainer: {
    display: "flex",
    gap: "15px",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginTop: "30px",
  },

})

export default suggestedGroupsStyles;