import { createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import {
	instanceUrl,
	header,
	emptyCache,
} from "configurations/configurations.js";

const messageInboxSlice = createSlice({
	name: "messageInbox",
	initialState: {
		searchCount: 0,
		searchResult: [],
		lastRecordSize: 0,
		requestRecordSize: 0,
		isLoading: true,
		isEnd: false,
		isSearching: false,
		isUpdated: true,
		inboxId: 0,
		isCreated: false,
		activeInboxList: [],
		isActiveInboxLoading: false,
		isActiveInboxUpdated: false,
		isActiveInboxCreated: false,
	},
	reducers: {
		INBOX_LOADING: (state, action) => {
			state.isLoading = true;
		},
		INBOX_SEARCH_SUCCESS: (state, action) => {
			// console.log(action);
			localStorage.setItem("token", action.payload.token);
			state.searchResult = [...action.payload.searchResult];
			// state.lastRecordSize =
			// 	state.lastRecordSize + action.payload.requestRecordSize;
			state.lastRecordSize = state.searchResult.length;
			state.requestRecordSize = action.payload.requestRecordSize;
			state.isLoading = false;
		},
		INBOX_SEARCH_END: (state, action) => {
			state.isEnd = true;
		},
		DEFAULT_INBOX_SEARCH: (state) => {
			state.searchResult = [];
			state.lastRecordSize = 0;
			state.requestRecordSize = 0;
			state.isEnd = false;
			state.isSearching = true;
		},
		INBOX_SEARCH_FAIL: (state) => {
			state.isUpdated = false;
		},
		ACTIVE_INBOX_ADD_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.isActiveInboxUpdated = true;
			state.isActiveInboxLoading = false;
			state.activeInboxList = action.payload.activeInboxList;
			state.isActiveInboxCreated = true;
		},
		ACTIVE_INBOX_DELETE_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.activeInboxList = action.payload.activeInboxList;
		},
		ACTIVE_INBOX_GET_SUCCESS: (state, action) => {
			// console.log(action)
			localStorage.setItem("token", action.payload.token);
			state.activeInboxList = action.payload.activeInboxList;
			state.isActiveInboxLoading = false;
		},
		ACTIVE_INBOX_LOADING: (state, action) => {
			state.isActiveInboxLoading = true;
		},
		DEFAULT_ACTIVE_INBOX_GET: (state) => {
			state.activeInboxList = [];
			state.isActiveInboxLoading = false;
		},
		ACTIVE_INBOX_GET_FAIL: (state) => {
			state.isActiveInboxUpdated = false;
		},
	},
});

const {
	INBOX_LOADING,
	INBOX_SEARCH_SUCCESS,
	INBOX_SEARCH_END,
	DEFAULT_INBOX_SEARCH,
	INBOX_SEARCH_FAIL,
	ACTIVE_INBOX_ADD_SUCCESS,
	ACTIVE_INBOX_DELETE_SUCCESS,
	ACTIVE_INBOX_GET_SUCCESS,
	ACTIVE_INBOX_LOADING,
	DEFAULT_ACTIVE_INBOX_GET,
	ACTIVE_INBOX_GET_FAIL,
} = messageInboxSlice.actions;

export const messageInboxInfo = ({
	acctId,
	accessToken,
	email,
	lastRecordSize,
	requestRecordSize,
	keyword,
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		email,
		lastRecordSize,
		requestRecordSize,
		keyword,
	};
	dispatch(INBOX_LOADING());
	if (lastRecordSize === 0) {
		dispatch(DEFAULT_INBOX_SEARCH());
	}

	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/mm/get-inbox`,
		params: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				var profileValues = [];
				for (var i = 0; i < res.data.payload.inboxList.length; i++) {
					var inbox = res.data.payload.inboxList[i];
					var name = "";
					var lastName = inbox.lastName == "" ? " " + inbox.lastName : "";
					var pageName = inbox.pageName != "" ? inbox.pageName : "Corporate";
					if (inbox.recordType == 1) {
						name = inbox.companyName + " (" + pageName + ")";
					} else if (inbox.recordType == 0) {
						name = inbox.firstName + lastName + " (You)";
					}
					profileValues.push({
						value: inbox.recordId,
						type: inbox.recordType,
						name: name,
						profilePic: inbox.profilePic,
					});
				}
				const response = {
					searchResult: profileValues,
					lastRecordSize,
					requestRecordSize: res.data.payload.inboxList.length,
					token: res.data.token,
				};
				dispatch(INBOX_SEARCH_SUCCESS(response));
				if (requestRecordSize > res.data.payload.inboxList.length) {
					dispatch(INBOX_SEARCH_END());
				}
			} else if (res.status === 202) {
				dispatch(INBOX_SEARCH_END());
			} else {
				dispatch(INBOX_SEARCH_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const messageActiveInboxInfo = ({
	acctId,
	accessToken,
	email,
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		email,
	};
	dispatch(DEFAULT_ACTIVE_INBOX_GET());
	dispatch(ACTIVE_INBOX_LOADING());

	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/mm/get-active-inbox`,
		params: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				var profileValues = [];
				for (var i = 0; i < res.data.payload.activeInboxList.length; i++) {
					var inbox = res.data.payload.activeInboxList[i];
					var name = "";
					var surname = inbox.surname != "" ? " " + inbox.surname : "";
					var pageName = inbox.pageName != "" ? inbox.pageName : "Corporate";
					if (inbox.inboxType == 1) {
						name = inbox.companyName + " (" + pageName + ")";
					} else if (inbox.inboxType == 0) {
						name = inbox.firstName + surname + " (You)";
					}
					profileValues.push({
						value: inbox.inboxId,
						recId: inbox.recordId,
						type: inbox.inboxType,
						name: name,
						profilePic: inbox.profilePic,
					});
				}
				const response = {
					activeInboxList: profileValues,
					token: res.data.token,
				};
				dispatch(ACTIVE_INBOX_GET_SUCCESS(response));
			} else {
				dispatch(ACTIVE_INBOX_GET_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const createActiveInbox = ({
	acctId,
	accessToken,
	email,
	recordId,
	inboxType,
	activeInboxList,
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		email,
		recordId,
		inboxType,
	};

	await axios({
		method: "POST",
		headers: header,
		url: `${instanceUrl}/api/mm/create-active-inbox`,
		data: body,
	})
		.then((res) => {
			console.log(res);
			// activeInboxList = JSON.parse(JSON.stringify(activeInboxList));
			// console.log(activeInboxList);
			// activeInboxList.push(res.data.payload.activeInboxList[0]);
			var profileValues = [...activeInboxList];
			if (res.data.payload.activeInboxList.length > 0) {
				var inbox = res.data.payload.activeInboxList[0];
				console.log(inbox);
				var name = "";
				var surname = inbox.surname != "" ? " " + inbox.surname : "";
				var pageName = inbox.pageName != "" ? inbox.pageName : "Corporate";
				if (inbox.inboxType == 1) {
					name = inbox.companyName + " (" + pageName + ")";
				} else if (inbox.inboxType == 0) {
					name = inbox.firstName + surname + " (You)";
				}
				var newInbox = {
					value: inbox.inboxId,
					recId: inbox.recordId,
					type: inbox.inboxType,
					name: name,
					profilePic: inbox.profilePic,
				};

				var record = profileValues.find((item) => item.value == newInbox.value);
				console.log(record);
				if (record) {
					profileValues = profileValues.map(function (item) {
						return item.value == newInbox.value ? newInbox : item;
					});
				} else {
					profileValues.push(newInbox);
				}
				// profileValues = profileValues.filter((item, index) => {
				// 	return item.value != inbox.inboxId;
				// });
				// console.log(profileValues);
				// profileValues.push(newInbox);
			}
			console.log(profileValues);
			const response = {
				activeInboxList: profileValues,
				token: res.data.token,
			};
			if (res.status === 201) {
				dispatch(ACTIVE_INBOX_ADD_SUCCESS(response));
			} else {
				dispatch(ACTIVE_INBOX_GET_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const deleteActiveInbox = ({
	acctId,
	accessToken,
	email,
	inboxId,
	activeInboxList,
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		email,
		inboxId,
	};
	console.log(body)
	await axios({
		method: "PUT",
		headers: header,
		url: `${instanceUrl}/api/mm/delete-active-inbox`,
		data: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				activeInboxList = activeInboxList.filter(
					(item) => item.value != inboxId
				);
				console.log(activeInboxList);
				const response = {
					activeInboxList,
					inboxId,
					token: res.data.token,
				};
				dispatch(ACTIVE_INBOX_DELETE_SUCCESS(response));
			} else {
				dispatch(ACTIVE_INBOX_GET_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export default messageInboxSlice.reducer;
