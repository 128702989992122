import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { getCandidateSelectJobPosts } from 'configurations/redux/jobManagementSlice.js';

import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import { TimeFormat } from 'configurations/configurations.js';

import defaultAvatar from 'assets/img/corporate_avatar.jpeg';

import searchJobPageStyle from 'assets/jss/material-kit-pro-react/views/pageStyle/SearchJobPage/searchJobPageStyle.js';
import ResultJobModal from './ResultJobModal.js';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import AttachMoneyRoundedIcon from '@material-ui/icons/AttachMoneyRounded';
import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import WhatshotRoundedIcon from '@material-ui/icons/WhatshotRounded';
import { Divider } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

import FontIcon from 'views/Component/FontIcon.js';
import { faMapMarkerAlt, faDollarSign, faEye, faCalendarAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import IconLibrary from 'views/Component/IconLibrary.js';

const useStyles = makeStyles(searchJobPageStyle);
const publicIp = require('public-ip');
var reWhiteSpace = new RegExp('\\s+');

export default function SearchResultCard({ item }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userStatus = useSelector(state => state.auth.user);
  const auth = useSelector(state => state.auth);

  const [jobViewModal, setJobViewModal] = React.useState(false);
  const onClose = () => {
    setJobViewModal(false);
  };

  const getJobPost = async () => {
    const requestProfile = {
      acctId: auth.isPublic ? 0 : userStatus.acctId,
      accessToken: auth.isPublic ? auth.publicToken : localStorage.getItem('token'),
      ipAddr: localStorage.getItem('ipAddr'), //await publicIp.v4(),
      jobPostId: item.jobPostId,
      item: null,
    };
    console.log(requestProfile);
    dispatch(getCandidateSelectJobPosts(requestProfile));
    setJobViewModal(true);
  };
  return (
    <React.Fragment>
      <div key={item.jobPostId} onClick={getJobPost}>
        <Card className={classes.cardClick + ' ' + classes.cardHeight + ' ' + classes.cardFrame}>
          <CardBody>
            <div className={classes.textCenter}>
              <img className={classes.cardImage} src={item.profilePic ? item.profilePic : defaultAvatar} alt="..." />
            </div>

            {item.hotJobFlag && (
              <Tooltip title="Popular Job" placement="top" classes={{ tooltip: classes.tooltip }}>
                <WhatshotRoundedIcon className={classes.hotjobIconWrapper} />
              </Tooltip>
            )}

            <div className={classes.titleDiv}>
              <h4 className={classes.jobTitle + ' ' + classes.jobCardTitle}>{item.title}</h4>
            </div>
            <div className={classes.companyNameDiv}>
              <h5 className={classes.companyName + ' ' + classes.textCenter}>{item.companyName}</h5>
            </div>
            <div className={classes.jobCardDescription}>
              <IconLibrary.Location width={20} height={16} />
              <span className={classes.jobDescription}>{item.countryName}</span>
            </div>
            <div className={classes.jobCardDescription + ' ' + classes.jobCardTitle}>
              <IconLibrary.Dollar width={20} height={16} />
              <span className={classes.jobDescription}>
                {item.currencyCode} {item.salaryMin} - {item.currencyCode} {item.salaryMax}
              </span>
            </div>
            <div className={classes.jobCardDescription}>
              <IconLibrary.Calendar width={20} height={16} />
              <span className={classes.jobDescription}>{'Posted ' + TimeFormat(item.lastUpdateDate)}</span>
            </div>
            {/* <FontIcon
							icon={faMapMarkerAlt}
							color={"gray"}
							text={
								<span className={classes.jobDescription}>
									{item.countryName}
								</span>
							}
						/>
						<FontIcon
							icon={faDollarSign}
							color={"gray"}
							text={
								<span className={classes.jobDescription}>
									{item.currencyCode} {item.salaryMin} - {item.currencyCode}{" "}
									{item.salaryMax}
								</span>
							}
						/>
						<FontIcon
							icon={faCalendarAlt}
							color={"gray"}
							text={
								<span className={classes.jobDescription}>
									{"Posted " + TimeFormat(item.lastUpdateDate)}
								</span>
							}
						/> */}

            {/* <div className={classes.infoArea}>
							<div className={classes.iconWrapper}>
								<LocationOnRoundedIcon />
							</div>
							<h4 className={classes.jobDescription}>{item.countryName}</h4>
						</div>
						<div className={classes.infoArea}>
							<div className={classes.iconWrapper}>
								<AttachMoneyRoundedIcon />
							</div>
							<h4 className={classes.jobDescription}>
								{item.currencyCode} {item.salaryMin} - {item.currencyCode}{" "}
								{item.salaryMax}
							</h4>
						</div>
						<div className={classes.infoArea}>
							<div className={classes.iconWrapper}>
								<DateRangeRoundedIcon />
							</div>
							<h4 className={classes.jobDescription}>
								{"Posted " + TimeFormat(item.lastUpdateDate)}
							</h4>
						</div> */}
            <div className={classes.dividerContainer}>
              <Divider />
            </div>

            {/* 	<div className={classes.infoArea}>
							<div className={classes.iconWrapper}>
								<VisibilityRoundedIcon />
							</div>
							<h4 className={classes.jobDescription}>{item.viewCount} Views</h4>
						</div> */}
            {/**Change the eye icon later */}
            <div className={classes.viewCountDescription} style={{ display: 'none' }}>
              <IconLibrary.Calendar width={20} height={16} />
              <span className={classes.jobDescription}>{item.viewCount} Views</span>
            </div>
            {/* 	<FontIcon
							icon={faEye}
							color={"gray"}
							text={
								<span className={classes.jobDescription}>
									{item.viewCount} Views
								</span>
							}
						/> */}
          </CardBody>
        </Card>
      </div>

      {jobViewModal ? <ResultJobModal open={jobViewModal} onClose={onClose} /> : null}
    </React.Fragment>
  );
}
