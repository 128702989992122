import React from "react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { useSelector, useDispatch } from "react-redux";

import searchPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/SearchPage/searchPageStyle.js";

import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import SmallCardLoading from "../SmallCardLoading.js";
import {
	encryptData,
	instanceUrl,
	header,
} from "configurations/configurations.js";
import Pagination from "views/Component/PaginationWithPage.js";
import { searchProfileInfo } from "configurations/redux/searchProfileSlice.js";
import PeopleCard from "./PeopleCard.js";
import NoResultCard from "../NoResultCard";

const useStyles = makeStyles(searchPageStyle);
export default function PeopleComponentInPage() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const searchResult = useSelector((state) => state.searchProfile.searchResult);
	const searchParams = useSelector((state) => state.searchProfile.searchParams);
	const userStatus = useSelector((state) => state.auth.user);
	const isLoading = useSelector((state) => state.searchProfile.isLoading);
	const auth = useSelector((state) => state.auth);
	const [currentPage, setCurrentPage] = React.useState(1);
	const [profilePerPage, setProfilePerPage] = React.useState(10);
	const indexOfLast = currentPage * profilePerPage;
	const indexOfFirst = indexOfLast - profilePerPage;

	const paginate = (pageNumber) => {
		window.scrollTo({ top: 0, behavior: "smooth" });
		setCurrentPage(pageNumber);
		var indexOfFirst = pageNumber * profilePerPage - profilePerPage;

		const requestProfile = {
			acctId: auth.isPublic ? 0 : userStatus.acctId,
			accessToken: auth.isPublic
				? auth.publicToken
				: localStorage.getItem("token"),
			email: auth.isPublic ? auth.publicEmail : userStatus.email,
			keyword: searchParams.keyword,
			countryId: searchParams.countryId,
			locationId: searchParams.locationId,
			lastRecordSize: indexOfFirst,
			requestRecordSize: profilePerPage,
			genericSearch: true,
		};
		dispatch(searchProfileInfo(requestProfile));
		scrollToTop();
	};
	const scrollToTop = () => {
		var timer = setInterval(() => {
			var jobPostResult = document.getElementById("searchResultPeople");
			if (jobPostResult) {
				jobPostResult.scrollIntoView();
				window.scrollTo(0, 0);
				clearInterval(timer);
			}
		}, 100);
	};
	const ProfileList =
		searchResult &&
		searchResult.length > 0 &&
		searchResult.map((item) => {
			return (
				<div key={item.acctId}>
					<PeopleCard item={item} />
				</div>
			);
		});
	const loadingFields = [];
	for (let i = 0; i < profilePerPage - searchResult.length; i++) {
		loadingFields.push(
			<div key={i}>
				<SmallCardLoading />
			</div>
		);
	}

	return (
		<Card className={classes.cardFrame}>
			<CardBody className={classes.cardBodyPadding}>
				<h4 className={classes.componentTitle}>People</h4>
				{isLoading &&
					loadingFields.map((item) => {
						return item;
					})}
				<div className={classes.mainContainer}>
					{ProfileList}{" "}
					{!isLoading && searchResult.length === 0 && (
						<NoResultCard showImage={true} />
					)}{" "}
				</div>
				{searchResult && searchResult.length > 0 && (
					<Pagination
						totalPosts={searchResult[0].totalCount}
						postsPerPage={profilePerPage}
						paginate={paginate}
					/>
				)}
			</CardBody>
		</Card>
	);
}
