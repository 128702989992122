import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  instanceUrl,
  header,
  emptyCache,
} from "configurations/configurations.js";

const getUserAttachmentTypeSlice = createSlice({
  name: "getUserAttachmentType",
  initialState: {
    getUserAttachmentTypeResult: {},
    getUserAttachmentTypeParams: [],
    isUpdated: true,
    isEnd: false,
  },
  reducers: {
    GET_USER_ATTACHMENT_TYPE_SUCCESS: (state, action) => {
      state.getUserAttachmentTypeResult =
        action.payload.getUserAttachmentTypeResult;
      state.isUpdated = true;
    },
    GET_USER_ATTACHMENT_TYPE_END: (state, action) => {
      state.isEnd = true;
    },
    GET_USER_ATTACHMENT_TYPE_FAIL: (state) => {
      state.isUpdated = false;
    },
  },
});

const {
  GET_USER_ATTACHMENT_TYPE_SUCCESS,
  GET_USER_ATTACHMENT_TYPE_END,
  GET_USER_ATTACHMENT_TYPE_FAIL,
} = getUserAttachmentTypeSlice.actions;

export const getUserAttachmentTypeInfo = () => async (dispatch) => {
  await axios({
    method: "GET",
    headers: header,
    url: `${instanceUrl}/api/md/get-user-attachment-type
    `,
  })
    .then((res) => {
      if (res.status === 201) {
        const response = {
          getUserAttachmentTypeResult: res.data.payload,
        };
        dispatch(GET_USER_ATTACHMENT_TYPE_SUCCESS(response));
      } else if (res.status === 202) {
        dispatch(GET_USER_ATTACHMENT_TYPE_END());
      } else {
        dispatch(GET_USER_ATTACHMENT_TYPE_FAIL());
      }
    })
    .catch((err) => {
      if (err.message.includes("503")) {
        emptyCache();
      }
      console.log(err);
    });
};

export default getUserAttachmentTypeSlice.reducer;
