import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { Alert, AlertTitle } from "@material-ui/lab";
import * as ChatElements from "react-chat-elements";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import { Formik } from "formik";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Fab } from "@material-ui/core";
import VerticalAlignBottomIcon from "@material-ui/icons/VerticalAlignBottom";
import {
	TimeFormat,
	TimeFormatWithoutTimeAgo,
	TimeFormatUniqueId,
	dataURLtoFile,
} from "configurations/configurations.js";
import InfiniteScroll from "react-infinite-scroll-component";
import CustomDialog from "../../../components/CustomDialog/CustomDialog.js";
import {
	updateChatroom,
	messageNewChatroomInfo,
} from "configurations/redux/messageChatroomSlice.js";
import {
	messageConversationInfo,
	createConversation,
	deleteConversation,
	messageConversationByReplyIdInfo,
	messageSenderConversationInfo,
	deleteConversationReset,
} from "configurations/redux/messageConversationSlice.js";
import { encryptData } from "configurations/configurations.js";
import Backdrop from "../../Component/Backdrop.js";
import messageDashboardStyle from "assets/jss/material-kit-pro-react/views/pageStyle/MessagePage/messagePageStyle.js";

import { useSnackbar } from "notistack";
import CloseIcon from "@material-ui/icons/Close";
import SendIcon from "@material-ui/icons/Send";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import { isMobile, isMobileOnly, isTablet } from "react-device-detect";
import Linkify from "react-linkify";
import CustomMessageInput from "components/CustomInput/CustomMessageInput";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import cross from "assets/img/cross.png";

const useStyles = makeStyles(messageDashboardStyle);

export default function MessageConversationDetails({ children, props }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [replyClick, setReplyClick] = React.useState();
	const [scrollToBottom, setScrollToBottom] = React.useState(false);
	const [loadingConversation, setLoadingConversation] = React.useState(false);
	const dialogDefault = {
		title: "",
		description: "",
		buttonYes: "",
		buttonNo: "",
	};
	const [confirmDialog, setConfirmDialog] = React.useState({
		isOpen: false,
		dialogDefault,
	});
	const userStatus = useSelector((state) => state.auth.user);
	const receiptList = useSelector(
		(state) => state.messageChatroomList.searchResult
	);
	const isEnd = useSelector((state) => state.messageConversation.isEnd);
	const searchResult = useSelector(
		(state) => state.messageConversation.searchResult
	);
	const currentSize = useSelector(
		(state) => state.messageConversation.lastRecordSize
	);
	const searchId = useSelector(
		(state) => state.messageConversationSearch.searchId
	);
	const isCreating = useSelector(
		(state) => state.messageConversation.isCreating
	);
	const chatroomId = useSelector((state) => state.messageChatroom.chatroomId);
	const isLoading = useSelector((state) => state.messageChatroomList.isLoading);
	const searchChatroom = useSelector(
		(state) => state.messageChatroom.searchResult
	);
	const isDeleted = useSelector((state) => state.messageConversation.isDeleted);
	const activeBizId = useSelector((state) => state.messageChatroom.activeBizId);

	const [convAttachment, setConvAttachment] = React.useState(null);

	const [fileName, setFileName] = React.useState(null);

	useEffect(() => {
		if (convAttachment) {
			if (convAttachment.size > 62914560) {
				snackbarCustom(
					"error",
					"The attachment exceed allowed size of 60MB and cannot be uploaded"
				);
				setConvAttachment(null);
			}
		}
		var reply = 0;
		if (replyClick) {
			reply = document.getElementsByClassName("conversationReply")[0];
			if (reply != null) reply = reply.clientHeight + 4;
		}
		var attachment = 0;
		if (convAttachment) attachment = 75;
		var description = document.getElementById("description");
		if (description != null)
			messageInputSize(
				description.value.split("\n").length,
				attachment + reply
			);
		else messageInputSize(1, attachment + reply);
	}, [convAttachment]);

	const inputKeyDown = (e, inputDesc) => {
		var attachment = 0;
		if (convAttachment) attachment = 75;
		var reply = 0;
		if (replyClick) {
			reply = document.getElementsByClassName("conversationReply")[0];
			if (reply != null) reply = reply.clientHeight + 4;
		}
		var lineCount = inputDesc.split("\n").length;
		// if (e.key == "Backspace") lineCount -= 1;
		if (e.key == "Enter") lineCount += 1;
		messageInputSize(lineCount, reply + attachment);
	};

	const messageInputSize = (lineCount, reply) => {
		if (window.innerHeight > 350) {
			if (lineCount > 9) lineCount = 9;
			if (lineCount < 1) lineCount = 1;
			if (reply > 0 && lineCount > 5) lineCount = 5;
			if (reply > 0) reply -= 5;
			var defaultHeight = 18;
			console.log(lineCount);
			var enterHeight = defaultHeight * lineCount;
			var desc = document.getElementById("description");
			var info = document.getElementById("conversationInfo");
			var data = document.getElementById("conversationCard"); //chatroom
			var input = document.getElementById("conversationInput");
			var rightGrid = document.getElementById("rightGrid");

			if (lineCount >= 1 && (desc != null || info != null) && data != null) {
				var height = 0;
				if (desc != null) {
					if (lineCount > 1) desc.style.height = enterHeight + "px";
					else desc.style.height = "auto";
					if (isMobileOnly) height = desc.offsetHeight - 60;
					else {
						height = desc.offsetHeight + 155;
						if (lineCount > 2) height = desc.offsetHeight + 133;
						else if (lineCount > 1) height = desc.offsetHeight + 137;
					}
					if (isTablet) height -= 5;
				}
				if (info != null) {
					if (isMobileOnly) height = info.offsetHeight - 100;
					else height = info.offsetHeight + 100;
				}
				data.style.height = "100vh";
				if (!isMobileOnly) {
					//  data.style.height = rightGrid.offsetHeight - 120 - 100 + "px";
					data.style.height = data.offsetHeight - height - reply - 162 + "px";
					data.style.overflowY = "scroll";
					// input.style.height = 10 + "vh";
				} else {
					if (isMobileOnly)
						data.style.height = data.offsetHeight - height - reply - 180 + "px";
					// data.style.height = 70 + "vh";
					// else
					// 	data.style.height = data.offsetHeight - height - reply - 205 + "px";
				}
			}

			if (!isMobileOnly) {
				var list = document.getElementById("conversation_list");
				var listScroll = document.getElementById("conversation_list_scroll");

				if (
					list != null &&
					listScroll != null &&
					(input != null || info != null)
				) {
					if (input != null) {
						list.style.height =
							data.offsetHeight +
							input.offsetHeight +
							-20 +
							"px"; /* + input.offsetHeight + 48 + "px"; */
						/* data.offsetHeight; */
						listScroll.style.height =
							data.offsetHeight + input.offsetHeight - 20 + "px";
					} else if (info != null) {
						list.style.height =
							data.offsetHeight +
							info.offsetHeight +
							-20 +
							"px"; /* + info.offsetHeight + 48 + "px"; */
						/* data.offsetHeight; */
						listScroll.style.height =
							data.offsetHeight + info.offsetHeight + -20 + "px";
					}
				}
			}
		} else {
		}
	};

	window.onresize = function (e) {
		// console.log("load");
		var attachment = 0;
		if (convAttachment) attachment = 75;
		var description = document.getElementById("description");
		var reply = document.getElementsByClassName("conversationReply")[0];
		if (reply != null) reply = reply.clientHeight + 4;
		else reply = 0;
		if (description != null)
			messageInputSize(
				description.value.split("\n").length,
				reply + attachment
			);
		else messageInputSize(1, 0 + attachment);
	};
	console.log(searchResult);
	const ResultCard = searchResult.map((item, index) => {
		var replyTitle = item.replyFullName;
		var replyDescription = item.replyDescription;
		if (item.replyAcctId === userStatus.acctId) replyTitle = "You";
		if (replyDescription && replyDescription.split("\n").length > 5) {
			var replySplit = replyDescription.split("\n");
			replyDescription = "";
			for (var i = 0; i < 5; i++) {
				if (i < 4) replyDescription += replySplit[i] + "\n";
				else replyDescription += replySplit[i] + "...";
			}
		}
		return item.acctId == userStatus.acctId ? (
			<div id={item.conversationId} key={item.conversationId}>
				<ChatElements.MessageBox
					key={item.conversationId}
					position={"right"}
					type={"text"}
					text={
						<span>
							{item.description.length > 0 ? (
								<Linkify
									componentDecorator={(decoratedHref, decoratedText, key) => (
										<a target="blank" href={decoratedHref} key={key}>
											{decoratedText}
										</a>
									)}
								>
									{item.description}
								</Linkify>
							) : null}
							{item.description.length > 0 &&
							item.attachments.attachmentList.length > 0 ? (
								<br />
							) : null}
							{item.attachments.attachmentList.length > 0 ? (
								<span>
									<Button
										className={classes.attachmentButton}
										onClick={() => {
											window.open(
												item.attachments.attachmentList[0].attachmentPath
											);
										}}
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="16"
											height="20"
											viewBox="0 0 16 20"
										>
											<path
												id="Shape"
												d="M1.99,20A2,2,0,0,1,0,18V2A2.006,2.006,0,0,1,2,0h8l6,6V18a2.006,2.006,0,0,1-2,2Z"
											/>
										</svg>
										<span>
											{item.attachments.attachmentList[0].attachmentName}
										</span>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="19.642"
											height="17.857"
											viewBox="0 0 19.642 17.857"
										>
											<path
												id="Shape"
												d="M0,17.857v-2.1H19.642v2.1ZM0,6.3H5.612V0h8.419V6.3h5.611L9.822,13.656Z"
												fill="#6f52ed"
											/>
										</svg>
									</Button>
								</span>
							) : null}
						</span>
					}
					dateString={TimeFormatWithoutTimeAgo(item.createDate)}
					status={item.statusName}
					replyButton={true}
					onReplyClick={() => {
						trancateMsg(item);
					}}
					reply={
						item.replyId != null
							? {
									title: replyTitle,
									titleColor: "#8717ae",
									message:
										replyDescription.length > 0
											? replyDescription
											: "Attachment...",
							  }
							: null
					}
					onReplyMessageClick={() => {
						loadReplyMessage(item.replyId);
					}}
					// forwarded={true}
					removeButton={true}
					onRemoveMessageClick={(e) => {
						deleteHandle(item);
					}}
				/>
			</div>
		) : (
			<div
				id={item.conversationId}
				key={item.conversationId}
				className={classes.conversationBox}
			>
				<ChatElements.MessageBox
					key={item.conversationId}
					position={"left"}
					type={"text"}
					titleColor={"#687bfe"}
					title={item.fullName != null ? item.fullName : null}
					onTitleClick={() => {
						getProfileNavigate(item);
					}}
					text={
						<span>
							{item.description.length > 0 ? (
								<Linkify
									componentDecorator={(decoratedHref, decoratedText, key) => (
										<a target="blank" href={decoratedHref} key={key}>
											{decoratedText}
										</a>
									)}
								>
									{item.description}
								</Linkify>
							) : null}
							{item.description.length > 0 &&
							item.attachments.attachmentList.length > 0 ? (
								<br />
							) : null}
							{item.attachments.attachmentList.length > 0 ? (
								<span>
									<Button
										className={classes.attachmentButton}
										onClick={() => {
											window.open(
												item.attachments.attachmentList[0].attachmentPath
											);
										}}
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="16"
											height="20"
											viewBox="0 0 16 20"
										>
											<path
												id="Shape"
												d="M1.99,20A2,2,0,0,1,0,18V2A2.006,2.006,0,0,1,2,0h8l6,6V18a2.006,2.006,0,0,1-2,2Z"
											/>
										</svg>
										<span>
											{item.attachments.attachmentList[0].attachmentName}
										</span>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="19.642"
											height="17.857"
											viewBox="0 0 19.642 17.857"
										>
											<path
												id="Shape"
												d="M0,17.857v-2.1H19.642v2.1ZM0,6.3H5.612V0h8.419V6.3h5.611L9.822,13.656Z"
												fill="#6f52ed"
											/>
										</svg>
									</Button>
								</span>
							) : null}
						</span>
					}
					dateString={TimeFormatWithoutTimeAgo(item.createDate)}
					status={item.statusName}
					replyButton={true}
					onReplyClick={() => {
						trancateMsg(item);
					}}
					reply={
						item.replyId != null
							? {
									title: replyTitle,
									titleColor: "#687bfe",
									message:
										replyDescription.length > 0
											? replyDescription
											: "Attachment...",
							  }
							: null
					}
					onReplyMessageClick={() => {
						loadReplyMessage(item.replyId);
					}}
					// forwarded={true}
					removeButton={true}
					onRemoveMessageClick={(e) => {
						deleteHandle(item);
					}}
				/>
			</div>
		);
	});

	const deleteHandle = (item) => {
		if (item.statusName == "read" || item.acctId != userStatus.acctId) {
			const dialogDefault = {
				title: "Warning",
				description: "You are not allow to delete this message",
				buttonYes: "",
				buttonNo: "OK",
			};
			setConfirmDialog({
				isOpen: true,
				dialogDefault,
			});
		} else {
			const dialogDefault = {
				title: "Alert",
				description: "Are you sure to delete this message?",
				buttonYes: "Delete",
				buttonNo: "Cancel",
			};
			setConfirmDialog({
				isOpen: true,
				dialogDefault,
				onConfirm: () => {
					const conversation = {
						conversationId: item.conversationId,
						acctId: userStatus.acctId,
						email: userStatus.email,
						accessToken: localStorage.getItem("token"),
						chatroomList: receiptList,
						searchResult: searchResult,
					};
					dispatch(deleteConversation(conversation));
					setTimeout(() => {
						loadNewChatroom();
					}, 500);
					setConfirmDialog({
						isOpen: false,
						dialogDefault,
					});
				},
			});
		}
	};

	React.useEffect(() => {
		if (isDeleted == false) {
			const dialogDefault = {
				title: "Warning",
				description: "You are not allow to delete this message",
				buttonYes: "",
				buttonNo: "OK",
			};
			setConfirmDialog({
				isOpen: true,
				dialogDefault,
			});
			dispatch(deleteConversationReset({ isDeleted: true }));
		}
	}, [isDeleted]);

	const getProfileNavigate = (item) => {
		if (item.acctTypeId == 2)
			window.open(
				"/corporate-profile-page/" + encryptData(item.acctId),
				"_blank"
			);
		else window.open("/view-profile/" + encryptData(item.acctId), "_blank");
	};

	const trancateMsg = (item) => {
		console.log(item);
		var title = item.fullName;
		if (item.acctId === userStatus.acctId) title = "You";
		var replyMsg = {
			conversationId: item.conversationId,
			fullName: title,
			description: item.description.trim(),
			attachments: item.attachments,
		};
		setReplyClick(replyMsg);
	};

	const loadReplyMessage = (replyId) => {
		var elmnt = document.getElementById(replyId);
		if (elmnt != null) {
			elmnt.scrollIntoView({
				behavior: "smooth",
				block: "nearest",
			});
			elmnt.style.backgroundColor = "#D7D7DF";
			elmnt.style.borderRadius = "18px";
			setTimeout(() => {
				elmnt.style.backgroundColor = "transparent";
				elmnt.style.borderRadius = "0";
			}, 2000);
			// window.scrollTo(0, 0);
		} else {
			setLoadingConversation(true);
			loadConversationByReplyId(replyId);
			let timer = setInterval(() => {
				var elmnt = document.getElementById(replyId);
				if (elmnt != null) {
					clearInterval(timer);
					setLoadingConversation(false);
					elmnt.scrollIntoView({
						behavior: "smooth",
						block: "nearest",
					});
					elmnt.style.backgroundColor = "#D7D7DF";
					elmnt.style.borderRadius = "18px";
					setTimeout(() => {
						elmnt.style.backgroundColor = "transparent";
						elmnt.style.borderRadius = "0";
					}, 2000);
					// window.scrollTo(0,0);
				}
				// console.log(elmnt);
			}, 500);
			return () => clearInterval(timer);
		}
	};

	const loadConversation = () => {
		const requestProfile = {
			acctId: userStatus.acctId,
			accessToken: localStorage.getItem("token"),
			email: userStatus.email,
			lastRecordSize: currentSize,
			requestRecordSize: isTablet ? 30 : 15,
			chatroomId: children.chatroomId,
			searchResult: searchResult,
			keyword: "",
		};
		// console.log(requestProfile);

		dispatch(messageConversationInfo(requestProfile));
	};

	const loadConversationByReplyId = (replyId) => {
		var conversationId = 0;
		if (searchResult.length > 0)
			conversationId = searchResult[0].conversationId;
		const requestProfile = {
			acctId: userStatus.acctId,
			accessToken: localStorage.getItem("token"),
			email: userStatus.email,
			chatroomId: children.chatroomId,
			searchResult: searchResult,
			conversationId: conversationId,
			replyId: replyId,
			lastRecordSize: currentSize,
		};
		// console.log(requestProfile);

		dispatch(messageConversationByReplyIdInfo(requestProfile));
	};

	React.useEffect(() => {
		// console.log(searchId);
		if (searchId > 0) {
			setTimeout(() => {
				loadReplyMessage(searchId);
			}, 100);
		}
	}, [searchId]);

	React.useEffect(() => {
		var attachment = 0;
		if (convAttachment) attachment = 75;
		var reply = 0;
		if (replyClick) {
			reply = document.getElementsByClassName("conversationReply")[0];
			if (reply != null) reply = reply.clientHeight + 4;
		}
		var desc = document.getElementById("description");
		if (desc != null) desc = desc.value.split("\n").length;
		else desc = 0;
		messageInputSize(desc, reply + attachment);
	}, [replyClick]);

	const scrollToBottomLoad = () => {
		var conversationDataScroll = document.getElementById(conversation_data);
		if (conversationDataScroll != null) {
			conversationDataScroll.addEventListener("scroll", function (e) {
				handleNavigation(e);
			});
			conversationDataScroll.addEventListener("touchmove", function (e) {
				handleNavigation(e);
			});
		}
		messageInputSize(1, 0);
	};

	function handleNavigation(e) {
		if (e.target != undefined) {
			const elem = e.target;
			// console.log(elem.scrollTop);
			if (elem.id == conversation_data) {
				if (elem.scrollTop >= -10) {
					setScrollToBottom(false);
				}
				if (elem.scrollTop < -10) {
					if (scrollToBottom == false) {
						setScrollToBottom(true);
					}
				}
			}
		}
	}

	React.useEffect(() => {
		if (document.getElementById("description") != null || convAttachment) {
			if (
				!isCreating &&
				(document.getElementById("description").value != "" || convAttachment)
			) {
				loadSenderConversation();
				loadNewChatroom();
				document.getElementById("description").value = "";
				setReplyClick(null);
				setConvAttachment(null);
				// console.log(searchResult)
				if (searchResult != null && searchResult.length > 0) {
					var elmnt = document.getElementById(searchResult[0].conversationId);
					if (elmnt != null) {
						elmnt.scrollIntoView({
							behavior: "smooth",
							block: "nearest",
						});
					}
				}
				messageInputSize(1, 0);
			}
		}
	}, [isCreating, chatroomId]);

	React.useEffect(() => {
		setReplyClick(null);
	}, [isLoading]);

	const loadSenderConversation = () => {
		var conversationId = 0;
		if (searchResult.length > 0)
			conversationId = searchResult[0].conversationId;
		const requestProfile = {
			acctId: userStatus.acctId,
			accessToken: localStorage.getItem("token"),
			email: userStatus.email,
			conversationId: conversationId,
			chatroomId: children.chatroomId,
			searchResult: searchResult,
			lastRecordSize: currentSize,
		};
		// console.log(requestProfile);
		dispatch(messageSenderConversationInfo(requestProfile));
	};

	const loadNewChatroom = () => {
		const requestProfile = {
			acctId: userStatus.acctId,
			accessToken: localStorage.getItem("token"),
			email: userStatus.email,
			searchResult: searchChatroom,
			bizId: activeBizId,
		};
		// console.log(requestProfile);

		dispatch(messageNewChatroomInfo(requestProfile));
	};

	window.addEventListener("navigationhandler", function (e) {
		if (isMobile && window.location.href.includes("/message-page/")) {
			window.history.pushState(null, document.title, window.location.href);
			window.addEventListener("popstate", function (event) {
				window.history.pushState(null, document.title, window.location.href);
			});
		}
	});

	React.useEffect(() => {
		setTimeout(() => {
			scrollToBottomLoad();
		}, 500);
	}, [receiptList]);

	React.useEffect(() => {
		window.dispatchEvent(new CustomEvent("navigationhandler"));
	}, []);

	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const snackBarShow = (message, variant, action) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(message, {
			variant: variant,
			action,
		});
	};

	const snackbarCustom = (title, description) => {
		const action = (key) => (
			<React.Fragment>
				<IconButton onClick={onClickDismiss(key)}>
					<CloseIcon />
				</IconButton>
			</React.Fragment>
		);
		snackBarShow(description, title, action);
	};

	const onClickDismiss = (key) => () => {
		closeSnackbar(key);
	};

	var footerClass;
	var conversationMargin;
	var conversationData;
	var messageCardFooter;
	var fab;
	var conversation_data = "conversation_data";
	if (isMobile) {
		footerClass = classes.messageCardFooterMobile + " " + classes.borderRadius;
		conversationMargin =
			classes.conversationMargin0Mobile + " " + classes.borderRadiusConv;
		conversationData = classes.conversationDataMobile;
		messageCardFooter = classes.messageCardFooterMobile;
		fab = classes.fabMobile;
	} else {
		footerClass = classes.messageCardFooter + " " + classes.borderRadius;
		conversationMargin =
			classes.conversationMargin0 + " " + classes.borderRadiusConv;
		conversationData = classes.conversationData;
		messageCardFooter = classes.messageCardFooter;
		fab = classes.fab;
	}
	if (isTablet) {
		conversation_data = "conversation_data_tablet";
	}

	return (
		<React.Fragment>
			{/* <CardBody className={classes.messageCardBodyList}>
				<Card className={conversationMargin} id="conversationCard"> */}
			<div id="conversationCard">
				<div className={conversationData} id={conversation_data}>
					<InfiniteScroll
						dataLength={searchResult.length}
						next={loadConversation}
						hasMore={!isEnd}
						style={{
							display: "flex",
							flexDirection: "column-reverse",
							overflow: "hidden",
						}}
						inverse={true}
						scrollThreshold={0.6}
						// height={searchResult.length < 4 ? 520 : 600}
						loader={<CircularProgress className={classes.loadingProgress} />}
						endMessage={
							searchResult.length > 15 ? (
								<h6 className={classes.endMessage}>End of message</h6>
							) : null
						}
						scrollableTarget={conversation_data}
					>
						{/* <GridContainer> */}
						{scrollToBottom ? (
							<Fab
								className={fab}
								size="small"
								color="primary"
								aria-label="scroll to bottom"
								onClick={() => {
									var elmnt = document.getElementById(
										searchResult[0].conversationId
									);
									if (elmnt != null) {
										elmnt.scrollIntoView({
											behavior: "smooth",
											block: "nearest",
										});
									}
									// window.scrollTo(0, 0);
								}}
							>
								<VerticalAlignBottomIcon />
							</Fab>
						) : null}
						{ResultCard}
						{/* </GridContainer> */}
					</InfiniteScroll>
				</div>
			</div>
			{/* 	</Card>
			</CardBody> */}

			{/* <CardFooter className={classes.messageCardBodyList}>
					<Card className={footerClass} id="conversationInput"> */}
			{children.crStatus < 3 ? (
				<div id="conversationInput">
					<div className={classes.conversationInput}>
						<Formik
							initialValues={{
								description: "",
							}}
							onSubmit={(data, { resetForm }) => {
								if (data.description.trim().length > 0 || convAttachment) {
									let convFormData = new FormData();
									convFormData.append("acctId", userStatus.acctId);
									convFormData.append(
										"accessToken",
										localStorage.getItem("token")
									);
									convFormData.append("email", userStatus.email);
									convFormData.append("chatroomId", children.chatroomId);
									convFormData.append("description", data.description.trim());
									console.log(receiptList);
									for (let i = 0; i < receiptList.length; i++) {
										convFormData.append(
											`chatroomList[${i}][acctId]`,
											receiptList[i].acctId
										);
										convFormData.append(
											`chatroomList[${i}][acctTypeId]`,
											receiptList[i].acctTypeId
										);
										convFormData.append(
											`chatroomList[${i}][chatroomId]`,
											receiptList[i].chatroomId
										);
										convFormData.append(
											`chatroomList[${i}][chatroomListId]`,
											receiptList[i].chatroomListId
										);
										convFormData.append(
											`chatroomList[${i}][companyLocation]`,
											receiptList[i].companyLocation
										);
										convFormData.append(
											`chatroomList[${i}][companyName]`,
											receiptList[i].companyName
										);
										convFormData.append(
											`chatroomList[${i}][crStatus]`,
											receiptList[i].crStatus
										);
										convFormData.append(
											`chatroomList[${i}][crStatusName]`,
											receiptList[i].crStatusName
										);
										convFormData.append(
											`chatroomList[${i}][educationLocation]`,
											receiptList[i].educationLocation
										);
										convFormData.append(
											`chatroomList[${i}][experienceLocation]`,
											receiptList[i].experienceLocation
										);
										convFormData.append(
											`chatroomList[${i}][fullName]`,
											receiptList[i].fullName
										);
										convFormData.append(
											`chatroomList[${i}][headline]`,
											receiptList[i].headline
										);
										convFormData.append(
											`chatroomList[${i}][profilePic]`,
											receiptList[i].profilePic
										);
										convFormData.append(
											`chatroomList[${i}][status]`,
											receiptList[i].status
										);
									}
									convFormData.append(
										"replyConversationId",
										replyClick ? replyClick.conversationId : null
									);
									convFormData.append("attachments", convAttachment);
									// const requestProfile = {
									// 	acctId: userStatus.acctId,
									// 	accessToken: localStorage.getItem("token"),
									// 	email: userStatus.email,
									// 	chatroomId: children.chatroomId,
									// 	description: data.description.trim(),
									// 	chatroomList: receiptList,
									// 	replyConversationId: replyClick
									// 		? replyClick.conversationId
									// 		: null,
									// };
									// console.log(requestProfile);
									console.log(...convFormData);
									dispatch(
										createConversation(convFormData, children.chatroomId)
									);
									if (children.crStatus < 2) {
										const chatroom = {
											acctId: userStatus.acctId,
											email: userStatus.email,
											accessToken: localStorage.getItem("token"),
											chatroomId: children.chatroomId,
											chatroomStatus: 2,
										};
										dispatch(updateChatroom(chatroom));
									}
								} else {
									const action = (key) => (
										<React.Fragment>
											<IconButton onClick={onClickDismiss(key)}>
												<CloseIcon />
											</IconButton>
										</React.Fragment>
									);
									snackbarCustom(
										"error",
										"You are not allow to send empty message"
									);
								}
								resetForm({});
							}}
						>
							{({ values, handleChange, handleSubmit, setFieldValue }) => (
								<form
									onSubmit={handleSubmit}
									className={classes.messageMargin0}
								>
									<GridContainer justifyContent="space-between">
										<GridItem className={isMobileOnly ? classes.message : null}>
											{replyClick ? (
												<div>
													<ChatElements.MessageBox
														titleColor={"#687bfe"}
														className={"conversationReply"}
														position={"right"}
														type={"text"}
														title={
															<div>
																{replyClick.fullName}
																<IconButton
																	onClick={() => {
																		setReplyClick(null);
																	}}
																	className={classes.iconButton}
																	aria-label="cancel"
																>
																	<CancelIcon />
																</IconButton>
															</div>
														}
														text={
															<span>
																{replyClick.description.length > 0
																	? replyClick.description
																	: null}
																{replyClick.description.length > 0 &&
																replyClick.attachments.attachmentList.length >
																	0 ? (
																	<br />
																) : null}
																{replyClick.attachments.attachmentList.length >
																0 ? (
																	<span>
																		<Button
																			className={classes.attachmentButton}
																			onClick={() => {
																				window.open(
																					replyClick.attachments
																						.attachmentList[0].attachmentPath
																				);
																			}}
																		>
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				width="16"
																				height="20"
																				viewBox="0 0 16 20"
																			>
																				<path
																					id="Shape"
																					d="M1.99,20A2,2,0,0,1,0,18V2A2.006,2.006,0,0,1,2,0h8l6,6V18a2.006,2.006,0,0,1-2,2Z"
																				/>
																			</svg>
																			<span>
																				{
																					replyClick.attachments
																						.attachmentList[0].attachmentName
																				}
																			</span>
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				width="19.642"
																				height="17.857"
																				viewBox="0 0 19.642 17.857"
																			>
																				<path
																					id="Shape"
																					d="M0,17.857v-2.1H19.642v2.1ZM0,6.3H5.612V0h8.419V6.3h5.611L9.822,13.656Z"
																					fill="#6f52ed"
																				/>
																			</svg>
																		</Button>
																	</span>
																) : null}
															</span>
														}
													/>
												</div>
											) : null}
											{convAttachment ? (
												<div>
													<ChatElements.MessageBox
														titleColor={"#687bfe"}
														className={"conversationReply"}
														position={"right"}
														type={"text"}
														title={
															<div>
																Attachment
																<IconButton
																	onClick={() => {
																		setConvAttachment(null);
																	}}
																	className={classes.iconButton}
																	aria-label="cancel"
																>
																	<CancelIcon />
																</IconButton>
															</div>
														}
														text={
															convAttachment.name +
															" - (" +
															(convAttachment.size / 1024 / 1024).toFixed(2) +
															"MB)"
														}
													/>
												</div>
											) : null}
										</GridItem>
										<GridItem className={isMobileOnly ? classes.message : null}>
											<CustomMessageInput
												labelText="Type your message here..."
												id="description"
												name="description"
												values={setFieldValue}
												onChange={handleChange}
												onClick={(e) => handleSubmit()}
												onKeyPress={(e) => {
													if (e.charCode == 13) {
														if (e.shiftKey) {
															e.preventDefault();
															handleSubmit();
														}
													}
												}}
												onKeyDown={(e) => {
													inputKeyDown(e, values.description);
												}}
												fullWidth
												inputProps={{
													multiline: true,
													rows: 1,
												}}
												className={classes.borderRadius}
												setFieldValue={setFieldValue}
												isCreating={isCreating}
												setConvAttachment={setConvAttachment}
											/>
										</GridItem>
									</GridContainer>
								</form>
							)}
						</Formik>
					</div>
				</div>
			) : (
				<CardFooter className={classes.messageCardBodyFooter}>
					<Card className={messageCardFooter} id="conversationInfo">
						<div className={classes.conversationInfo}>
							<GridContainer>
								<GridItem>
									<SnackbarContent
										message={
											<span>
												<b>Info</b>
											</span>
										}
										color="opptyWarning"
										icon="info"
										size="message"
									/>
									<span className={classes.alertPadding}>
										This conversation has been <strong>closed!</strong>
									</span>
								</GridItem>
							</GridContainer>
						</div>
					</Card>
				</CardFooter>
			)}
			{loadingConversation || isCreating ? <Backdrop /> : null}
			<CustomDialog
				confirmDialog={confirmDialog}
				setConfirmDialog={setConfirmDialog}
			/>
		</React.Fragment>
	);
}
