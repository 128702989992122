import React from "react";
import { useDispatch, useSelector } from "react-redux";

import IndustryMarketingCommunication from "./Marketing_Communication/IndustryMarketingCommunication.js";
import IndustryHospitality from "./Hospitality/IndustryHospitality.js";
import DepartmentAdvertising from "./Marketing_Communication/DepartmentAdvertising.js";
import DepartmentPublicRelation from "./Marketing_Communication/DepartmentPublicRelation.js";
import DepartmentMarketing from "./Marketing_Communication/DepartmentMarketing.js";
import DepartmentMarketingResearch from "./Marketing_Communication/DepartmentMarketingResearch.js";
import DepartmentBrandManagement from "./Marketing_Communication/DepartmentBrandManagement.js";
import IndustryFinance from "./Finance/IndustryFinance.js";
import DepartmentRevenueDistribution from "./Hospitality/DepartmentRevenueDistribution.js";
import DepartmentAttractionsManagement from "./Hospitality/DepartmentAttractionsManagement.js";
import DepartmentHousekeeping from "./Hospitality/DepartmentHousekeeping.js";
import DepartmentEventManagement from "./Hospitality/DepartmentEventManagement.js";
import DepartmentTravelManagementOperations from "./Hospitality/DepartmentTravelManagementOperations.js";
import DepartmentFrontOffice from "./Hospitality/DepartmentFrontOffice.js";
import DepartmentPersonalFinance from "./Finance/DepartmentPersonalFinance.js";
import DepartmentInvestorRelations from "./Finance/DepartmentInvestorRelations.js";
import DeparmentInvestmentSecurities from "./Finance/DepartmentInvestmentSecurities.js";
import DepartmentPublicFinanceManagement from "./Finance/DepartmentPublicFinanceManagement.js";
import DepartmentHedgeFund from "./Finance/DepartmentHedgeFund.js";
import DepartmentAccounting from "./Finance/DepartmentAccounting.js";
import IndustryDataArtificialIntelligence from "./Data_Artificial_Intelligence/IndustryDataArtificialIntelligence.js";
import IndustryRealEstate from "./Real_Estate/IndustryRealEstate.js";
import DepartmentSalesLeasing from "./Real_Estate/DepartmentSalesLeasing.js";
import DepartmentValuations from "./Real_Estate/DepartmentValuations.js";
import DepartmentResearchConsultancy from "./Real_Estate/DepartmentResearchConsultancy.js";
import DepartmentPropertyAssetManagement from "./Real_Estate/DepartmentPropertyAssetManagement.js";
import DepartmentUrbanPlanning from "./Real_Estate/DepartmentUrbanPlanning.js";
import DepartmentBusinessIntelligence from "./Data_Artificial_Intelligence/DepartmentBusinessIntelligence.js";
import DepartmentDataEngineering from "./Data_Artificial_Intelligence/DepartmentDataEngineering.js";
import DepartmentDataScienceAIScience from "./Data_Artificial_Intelligence/DepartmentDataScienceAIScience.js";
import DepartmentAIAppliedResearch from "./Data_Artificial_Intelligence/DepartmentAIAppliedResearch.js";
import DepartmentMachineLearningEngineering from "./Data_Artificial_Intelligence/DepartmentMachineLearningEngineering.js";
import IndustryAerospaceEngineering from "./Aerospace_Engineering/IndustryAerospaceEngineering.js";
import DepartmentAircraftMaintenance from "./Aerospace_Engineering/DepartmentAircraftMaintenance.js";
import DepartmentAircraftEngineComponentMaintenance from "./Aerospace_Engineering/DepartmentAircraftEngineComponentMaintenance.js";
import DepartmentManufacturing from "./Aerospace_Engineering/DepartmentManufacturing.js";
import DepartmentFleetManagement from "./Aerospace_Engineering/DepartmentFleetManagement.js";
import IndustryBiomedicalEngineering from "./Biomedical_Engineering/IndustryBiomedicalEngineering.js";
import IndustryChemicalEngineering from "./Chemical_Engineering/IndustryChemicalEngineering.js";
import IndustryCivilEngineering from "./Civil_Engineering/IndustryCivilEngineering.js";
import IndustryElectricalEngineering from "./Electrical_Engineering/IndustryElectricalEngineering.js";
import IndustryMarineEngineering from "./Marine_Engineering/IndustryMarineEngineering.js";
import IndustryMechanicalEngineering from "./Mechanical_Engineering/IndustryMechanicalEngineering.js";
import IndustryProductEngineering from "./Product_Engineering/IndustryProductEngineering.js";
import IndustrySoftwareEngineering from "./Software_Engineering/IndustrySoftwareEngineering.js";
import DepartmentPortSeaTransport from "./Marine_Engineering/DepartmentPortSeaTransport.js";
import DepartmentTheEngineDepartment from "./Marine_Engineering/DepartmentTheEngineDepartment.js";
import IndustryHealthcare from "./Healthcare/IndustryHealthcare.js";
import DepartmentOperations from "./Healthcare/DepartmentOperations.js";
import DepartmentOccupationalTherapy from "./Healthcare/DepartmentOccupationalTherapy.js";
import DepartmentPharmacySupport from "./Healthcare/DepartmentPharmacySupport.js";
import DepartmentPhysiotherapy from "./Healthcare/DepartmentPhysiotherapy.js";
import DepartmentSpeechTherapy from "./Healthcare/DepartmentSpeechTherapy.js";
import DepartmentNursing from "./Healthcare/DepartmentNursing.js";
import IndustryBankingWealthManagement from "./Banking_Wealth_Management/IndustryBankingWealthManagement.js";
import IndustryBankingSMECorporateCommercial from "./Banking_SME_Corporate_Commercial/IndustryBankingSMECorporateCommercial.js";
import IndustryBankingInvestmentBanking from "./Banking_Investment_Banking/IndustryBankingInvestmentBanking.js";
import IndustryBankingRiskCompliance from "./Banking_Risk_Compliance/IndustryBankingRiskCompliance.js";
import DepartmentRetailBanking from "./Banking_Wealth_Management/DepartmentRetailBanking.js";
import DepartmentAffluenceBanking from "./Banking_Wealth_Management/DepartmentAffluenceBanking.js";
import DepartmentPrivateBanking from "./Banking_Wealth_Management/DepartmentPrivateBanking.js";
import DepartmentSME from "./Banking_SME_Corporate_Commercial/DepartmentSME.js";
import DepartmentCorporateBanking from "./Banking_SME_Corporate_Commercial/DepartmentCorporateBanking.js";
import DepartmentCommercialBanking from "./Banking_SME_Corporate_Commercial/DepartmentCommercialBanking.js";
import DepartmentCapitalMarket from "./Banking_Investment_Banking/DepartmentCapitalMarket.js";
import DepartmentMergerAcquisition from "./Banking_Investment_Banking/DepartmentMergerAcquisition.js";
import DepartmentCorporateFinance from "./Banking_Investment_Banking/DepartmentCorporateFinance.js";
import DepartmentTrading from "./Banking_Investment_Banking/DepartmentTrading.js";
import DepartmentRisk from "./Banking_Risk_Compliance/DepartmentRisk.js";
import DepartmentCompliance from "./Banking_Risk_Compliance/DepartmentCompliance.js";

export default function ShowRoadmap() {
	const industryId = useSelector((state) => state.roadmap.industryKeyword);
	const departmentId = useSelector((state) => state.roadmap.departmentKeyword);

	const RenderRoadmap = () => {
		if (departmentId) {
			if (industryId === 1) {
				if (departmentId === 1) {
					return <DepartmentAdvertising />;
				} else if (departmentId === 2) {
					return <DepartmentPublicRelation />;
				} else if (departmentId === 3) {
					return <DepartmentMarketingResearch />;
				} else if (departmentId === 4) {
					return <DepartmentMarketing />;
				} else if (departmentId === 5) {
					return <DepartmentBrandManagement />;
				} else {
					return <IndustryMarketingCommunication />;
				}
			} else if (industryId === 2) {
				if (departmentId === 6) {
					return <DepartmentPersonalFinance />;
				} else if (departmentId === 10) {
					return <DepartmentInvestorRelations />;
				} else if (departmentId === 11) {
					return <DeparmentInvestmentSecurities />;
				} else if (departmentId === 9) {
					return <DepartmentPublicFinanceManagement />;
				} else if (departmentId === 7) {
					return <DepartmentHedgeFund />;
				} else if (departmentId === 8) {
					return <DepartmentAccounting />;
				} else {
					return <IndustryFinance />;
				}
			} else if (industryId === 3) {
				if (departmentId === 12) {
					return <DepartmentRevenueDistribution />;
				} else if (departmentId === 13) {
					return <DepartmentAttractionsManagement />;
				} else if (departmentId === 14) {
					return <DepartmentHousekeeping />;
				} else if (departmentId === 15) {
					return <DepartmentEventManagement />;
				} else if (departmentId === 16) {
					return <DepartmentTravelManagementOperations />;
				} else if (departmentId === 17) {
					return <DepartmentFrontOffice />;
				} else {
					return <IndustryHospitality />;
				}
			} else if (industryId === 4) {
				if (departmentId === 18) {
					return <DepartmentSalesLeasing />;
				} else if (departmentId === 20) {
					return <DepartmentValuations />;
				} else if (departmentId === 21) {
					return <DepartmentResearchConsultancy />;
				} else if (departmentId === 19) {
					return <DepartmentPropertyAssetManagement />;
				} else if (departmentId === 22) {
					return <DepartmentUrbanPlanning />;
				} else {
					return <IndustryRealEstate />;
				}
			} else if (industryId === 5) {
				if (departmentId === 23) {
					return <DepartmentBusinessIntelligence />;
				} else if (departmentId === 24) {
					return <DepartmentDataEngineering />;
				} else if (departmentId === 26) {
					return <DepartmentDataScienceAIScience />;
				} else if (departmentId === 27) {
					return <DepartmentAIAppliedResearch />;
				} else if (departmentId === 25) {
					return <DepartmentMachineLearningEngineering />;
				} else {
					return <IndustryDataArtificialIntelligence />;
				}
			} else if (industryId === 6) {
				if (departmentId === 28) {
					return <DepartmentAircraftMaintenance />;
				} else if (departmentId === 29) {
					return <DepartmentAircraftEngineComponentMaintenance />;
				} else if (departmentId === 30) {
					return <DepartmentManufacturing />;
				} else if (departmentId === 31) {
					return <DepartmentFleetManagement />;
				} else {
					return <IndustryAerospaceEngineering />;
				}
			} else if (industryId === 7) {
				return <IndustryBiomedicalEngineering />;
			} else if (industryId === 8) {
				return <IndustryChemicalEngineering />;
			} else if (industryId === 9) {
				return <IndustryCivilEngineering />;
			} else if (industryId === 10) {
				return <IndustryElectricalEngineering />;
			} else if (industryId === 11) {
				if (departmentId === 36) {
					return <DepartmentPortSeaTransport />;
				} else if (departmentId === 37) {
					return <DepartmentTheEngineDepartment />;
				} else {
					return <IndustryMarineEngineering />;
				}
			} else if (industryId === 12) {
				return <IndustryMechanicalEngineering />;
			} else if (industryId === 13) {
				return <IndustryProductEngineering />;
			} else if (industryId === 14) {
				return <IndustrySoftwareEngineering />;
			} else if (industryId === 15) {
				if (departmentId === 41) {
					return <DepartmentOperations />;
				} else if (departmentId === 42) {
					return <DepartmentOccupationalTherapy />;
				} else if (departmentId === 43) {
					return <DepartmentPharmacySupport />;
				} else if (departmentId === 44) {
					return <DepartmentPhysiotherapy />;
				} else if (departmentId === 45) {
					return <DepartmentSpeechTherapy />;
				} else if (departmentId === 46) {
					return <DepartmentNursing />;
				} else {
					return <IndustryHealthcare />;
				}
			} else if (industryId === 16) {
				if (departmentId === 47) {
					return <DepartmentRetailBanking />;
				} else if (departmentId === 48) {
					return <DepartmentAffluenceBanking />;
				} else if (departmentId === 49) {
					return <DepartmentPrivateBanking />;
				} else {
					return <IndustryBankingWealthManagement />;
				}
			} else if (industryId === 17) {
				if (departmentId === 50) {
					return <DepartmentSME />;
				} else if (departmentId === 51) {
					return <DepartmentCorporateBanking />;
				} else if (departmentId === 52) {
					return <DepartmentCommercialBanking />;
				} else {
					return <IndustryBankingSMECorporateCommercial />;
				}
			} else if (industryId === 18) {
				if (departmentId === 53) {
					return <DepartmentCapitalMarket />;
				} else if (departmentId === 54) {
					return <DepartmentMergerAcquisition />;
				} else if (departmentId === 55) {
					return <DepartmentCorporateFinance />;
				} else if (departmentId === 56) {
					return <DepartmentTrading />;
				} else {
					return <IndustryBankingInvestmentBanking />;
				}
			} else if (industryId === 19) {
				if (departmentId === 57) {
					return <DepartmentRisk />;
				} else if (departmentId === 58) {
					return <DepartmentCompliance />;
				} else {
					return <IndustryBankingRiskCompliance />;
				}
			} else {
				return null;
			}
		} else {
			if (industryId === 1) {
				return <IndustryMarketingCommunication />;
			} else if (industryId === 2) {
				return <IndustryFinance />;
			} else if (industryId === 3) {
				return <IndustryHospitality />;
			} else if (industryId === 4) {
				return <IndustryRealEstate />;
			} else if (industryId === 5) {
				return <IndustryDataArtificialIntelligence />;
			} else if (industryId === 6) {
				return <IndustryAerospaceEngineering />;
			} else if (industryId === 7) {
				return <IndustryBiomedicalEngineering />;
			} else if (industryId === 8) {
				return <IndustryChemicalEngineering />;
			} else if (industryId === 9) {
				return <IndustryCivilEngineering />;
			} else if (industryId === 10) {
				return <IndustryElectricalEngineering />;
			} else if (industryId === 11) {
				if (departmentId === 36) {
					return <DepartmentPortSeaTransport />;
				} else if (departmentId === 37) {
					return <DepartmentTheEngineDepartment />;
				} else {
					return <IndustryMarineEngineering />;
				}
			} else if (industryId === 12) {
				return <IndustryMechanicalEngineering />;
			} else if (industryId === 13) {
				return <IndustryProductEngineering />;
			} else if (industryId === 14) {
				return <IndustrySoftwareEngineering />;
			} else if (industryId === 15) {
				return <IndustryHealthcare />;
			} else if (industryId === 16) {
				return <IndustryBankingWealthManagement />;
			} else if (industryId === 17) {
				return <IndustryBankingSMECorporateCommercial />;
			} else if (industryId === 18) {
				return <IndustryBankingInvestmentBanking />;
			} else if (industryId === 19) {
				return <IndustryBankingRiskCompliance />;
			} else {
				return null;
			}
		}
	};
	return <RenderRoadmap />;
}
