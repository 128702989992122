import React from "react";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import { instanceUrl, header } from "configurations/configurations.js";

import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Email from "@material-ui/icons/Email";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Danger from "components/Typography/Danger.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

import passwordRecoveryPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/PasswordRecoveryPage/passwordRecoveryPageStyle.js";
// import loginPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/LoginPage/loginPageStyle.js";

import Header from "views/Component/Header.js";
import Footer from "views/Component/Footer.js";

import image from "assets/img/bg7.jpg";
import passwordResetImage from "assets/img/Recovery_password.png";
import { isDesktop } from "react-device-detect";
import { Hidden, TextField } from "@material-ui/core";
import { Link } from "react-router-dom";
import { gaPageView } from "configurations/configurations";

const useStyles = makeStyles(passwordRecoveryPageStyle);

const passwordRecoverySchema = Yup.object({
	email: Yup.string()
		.required("* Email is required")
		.email("* Email Type is incorrect"),
	acctTypeId: Yup.number().required("* Account Type should be selected"),
});
export default function PasswordRecoveryPage() {
	const [emailRequest, setEmailRequest] = React.useState();
	const classes = useStyles();
	React.useEffect(() => {
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;
	});
	React.useEffect(() => {
		gaPageView(window.location.pathname);
	}, []);
	const submitData = async (data) => {
		setEmailRequest(null);
		await axios({
			method: "GET",
			headers: header,
			url: `${instanceUrl}/api/um/request-password-recovery`,
			params: {
				email: data.email,
				acctTypeId: data.acctTypeId,
			},
		})
			.then((res) => {
				if (res.request.status === 202) {
					setEmailRequest(false);
				} else {
					setEmailRequest(true);
				}
			})
			.catch((err) => {
				console.log(err);
				setEmailRequest(false);
			});
	};
	console.log(emailRequest);

	return (
		<>
			<Header color={"transparent"} loggedInStatus={false} />
			<div className={classes.body}>
				<div
					id="mainPage"
					className={classes.pageHeader + " " + classes.pageHeaderHeight}
					style={{
						backgroundSize: "cover",
						backgroundPosition: "top center",
					}}
				>
					<div className={classes.container} id="passwordRecoveryMainPage">
						<GridContainer
							justifyContent="center"
							alignContent="center"
							// alignItems="center"
						>
							<GridItem xs={12} sm={12} md={7}>
								<div className={classes.infoArea}>
									<h2 className={classes.leftInfoTitle}>
										Forgot your password?
									</h2>
									<h5 className={classes.leftInfoDescription}>
										Just provide us with the email address associated with your
										account and we’ll send you your password reset instructions.
									</h5>
									{/* <img src={passwordResetImage} className={classes.infoImage} /> */}
								</div>
							</GridItem>
							<GridItem xs={12} sm={12} md={5} className={classes.loginGrid}>
								{emailRequest && (
									<SnackbarContent
										message={
											<span>
												<b>Request Password Successful. Check your Email.</b>
											</span>
										}
										close
										color="opptyInfo"
										icon="info_outline"
										className={classes.snackbarMobile}
									/>
								)}
								{emailRequest === false && (
									<SnackbarContent
										message={
											<span>
												<b>Request Password Failed. Email is not registered.</b>
											</span>
										}
										close
										color="opptyWarning"
										icon="info_outline"
										className={classes.snackbarMobile}
									/>
								)}
								<Card className={classes.passwordCard}>
									<CardBody className={classes.passwordCardBody}>
										<GridContainer
											justifyContent="center"
											alignContent="center"
											alignItems="center"
										>
											<GridItem xs={12} sm={12} md={12}>
												<GridItem>
													<div>
														<h4 className={classes.title}>
															Account for Reset Password
														</h4>
													</div>
												</GridItem>{" "}
												<Formik
													initialValues={{ email: "", acctTypeId: 1 }}
													validationSchema={passwordRecoverySchema}
													onSubmit={(data) => {
														const requestProfile = {
															email: data.email,
															acctTypeId: data.acctTypeId === "2" ? 2 : 1,
														};
														console.log(requestProfile);
														submitData(requestProfile);
													}}
												>
													{({
														handleChange,
														values,
														errors,
														touched,
														handleBlur,
														handleSubmit,
													}) => (
														<form onSubmit={handleSubmit}>
															<GridItem className={classes.gridHeight}>
																<h5 className={classes.textFieldLabel}>
																	Email
																</h5>
																<TextField
																	variant="outlined"
																	id="email"
																	label={
																		touched.email && Boolean(errors.email)
																			? errors.email
																			: ""
																	}
																	name="email"
																	// helperText={touched.email ? errors.email : ""}
																	error={touched.email && Boolean(errors.email)}
																	onChange={handleChange}
																	onBlur={handleBlur}
																	// placeholder="Required"
																	fullWidth
																	InputProps={{
																		type: "text",
																		// startAdornment: (
																		// 	<InputAdornment position="start">
																		// 		<Email
																		// 			className={classes.inputIconsColor}
																		// 		/>
																		// 	</InputAdornment>
																		// ),
																	}}
																/>
															</GridItem>
															<GridItem className={classes.marginTop}>
																<Button
																	onClick={handleSubmit}
																	fullWidth
																	color="oppty"
																	className={
																		classes.buttonBorder +
																		" " +
																		classes.buttonSend
																	}
																>
																	Send Request
																</Button>
															</GridItem>
														</form>
													)}
												</Formik>
											</GridItem>
										</GridContainer>
										<GridContainer>
											<GridItem>
												<div className={classes.textCenter}>
													<Link to="/login-page">
														<h5
															className={
																classes.infoTitle + " " + classes.textCenter
															}
														>
															Login Now
														</h5>
													</Link>
												</div>
											</GridItem>
										</GridContainer>
									</CardBody>
								</Card>
							</GridItem>
						</GridContainer>
					</div>
				</div>
				<div id="recoveryFooter">
					<Footer />
				</div>
			</div>
		</>
	);
}
