import React from "react";

import { connect, useSelector, useDispatch } from "react-redux";
import { updatePrivacySetting } from "configurations/redux/userSlice.js";
import { makeStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

import Slide from "@material-ui/core/Slide";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import ProfileStrengthTip from "./ProfileStrengthTip.js";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Close from "@material-ui/icons/Close";
import IconLibrary from "views/Component/IconLibrary.js";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js";
import { isDesktop } from "react-device-detect";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles(profilePageStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

const PrivacySettingModal = ({ open, onClose, extension }) => {
	const classes = useStyles();
	const userStatus = useSelector((state) => state.auth.user);
	const initialSettings = useSelector((state) => state.user.privacySetting);
	const isPrivacyUpdated = useSelector((state) => state.user.isPrivacyUpdated);
	const [showProfileModal, setShowProfileModal] = React.useState(false);

	const pDispatch = useDispatch();

	const privacyReducer = (state, action) => {
		switch (action.type) {
			case "CHANGE_PRIVACY_TRUE":
				return state.map((item) => {
					if (item.settingName === action.settingName) {
						return { ...item, settingValue: 1 };
					} else {
						return item;
					}
				});
			case "CHANGE_PRIVACY_FALSE":
				return state.map((item) => {
					if (item.settingName === action.settingName) {
						return { ...item, settingValue: 0 };
					} else {
						return item;
					}
				});
			default:
				return state;
		}
	};
	const [setting, dispatch] = React.useReducer(privacyReducer, initialSettings);

	const handleChange = (e) => {
		let currentSetting = setting
			.filter((items) => {
				if (items.settingName === e.target.name) {
					return items;
				}
			})
			.map((items) => {
				return items;
			});
		if (currentSetting[0].settingValue === 1) {
			dispatch({
				type: "CHANGE_PRIVACY_FALSE",
				settingName: currentSetting[0].settingName,
			});
		} else {
			dispatch({
				type: "CHANGE_PRIVACY_TRUE",
				settingName: currentSetting[0].settingName,
			});
		}
	};
	const handleSubmit = () => {
		const requestProfile = {
			email: userStatus.email,
			acctId: userStatus.acctId,
			privacySetting: setting,
			accessToken: localStorage.getItem("token"),
		};
		console.log(requestProfile);
		pDispatch(updatePrivacySetting(requestProfile));
		console.log(isPrivacyUpdated);
		setTimeout(() => {
			onClose();
		}, 1500);
	};
	const onShowProfileClose = () => {
		setShowProfileModal(false);
	};
	const ShowProfileModalWindow = () => {
		return (
			<Dialog
				open={showProfileModal}
				onClose={(event, reason) => {
					if (reason && reason == "backdropClick") return;
					setShowProfileModal(false);
				}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"Do you want to turn off your Privacy setting?"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Turning off your privacy setting will affect in your career
						matching. If you don't want this to happen, please turn on the
						privacy setting.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						color="opptyWrap"
						round
						onClick={() => {
							dispatch({
								type: "CHANGE_PRIVACY_FALSE",
								settingName: setting[0].settingName,
							});
							setShowProfileModal(false);
						}}
						autoFocus
					>
						<b>Confirm</b>
					</Button>
					<Button
						onClick={() => {
							setShowProfileModal(false);
						}}
						round
						color="opptyWrapBorder"
					>
						<b>Cancel</b>
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	const modalResize = () => {
		// var timer = setInterval(() => {
		var modalResize = document.getElementById("modalPrivacy");
		if (modalResize) {
			// clearInterval(timer);
			modalResize.style.maxHeight = "540px";
			var maxSize = window.innerHeight - 76 - 40 - 70;
			if (maxSize < 0) maxSize = 0;
			modalResize.style.height = maxSize + "px";

			// } else {
			// 	setTimeout(() => {
			// 		clearInterval(timer);
			// 	}, 3000);
		}
		// }, 300);
		// return () => clearInterval(timer);
	};

	React.useEffect(() => {
		if (open) {
			if (document.readyState === "complete") {
				setTimeout(() => {
					modalResize();
				}, 500);
			}
			window.addEventListener("resize", modalResize);
			return function cleanup() {
				window.removeEventListener("resize", modalResize);
			};
		}
	}, [open]);

	return (
		<div>
			<Dialog
				classes={{
					root: classes.modalRoot,
					paper: classes.modalProfileEdit + " " + classes.modal,
				}}
				open={open}
				// TransitionComponent={Transition}
				keepMounted
				onClose={(event, reason) => {
					if (reason && reason == "backdropClick") return;
					onClose();
				}}
				aria-labelledby="classic-modal-slide-title"
				aria-describedby="classic-modal-slide-description"
			>
				<DialogTitle
					id="signup-modal-slide-title"
					disableTypography
					className={classes.modalTitleEdit}
				>
					<Button
						simple
						className={classes.modalCloseButton}
						key="close"
						aria-label="Close"
						onClick={onClose}
					>
						<IconLibrary.Close />
					</Button>
					<h4 className={classes.title + " " + classes.titleAlign}>
						Edit Privacy Setting
					</h4>
				</DialogTitle>
				<div className={classes.autoHeightBody}>
					<div>
						<DialogContent className={classes.modalBody}>
							{extension && <ProfileStrengthTip extension={extension} />}
							<GridContainer
								className={
									isDesktop
										? classes.smallModalBodyDesktop
										: classes.smallModalBody
								}
								id="modalPrivacy"
							>
								<GridItem xs={12} sm={12} md={6} className={classes.gridItem}>
									<Card className={classes.cardFrame}>
										<CardBody>
											<GridContainer
												justifyContent="center"
												className={classes.verticalAligned}
											>
												<GridItem xs={9} sm={8}>
													<h5 className={classes.title}>
														Show "Profile" To Public
													</h5>
												</GridItem>
												<GridItem xs={3} sm={2}>
													<div className={classes.switchCenter}>
														<FormControlLabel
															control={
																<Switch
																	checked={setting[0].settingValue === 1}
																	onChange={(e) => {
																		if (setting[0].settingValue === 1) {
																			setShowProfileModal(true);
																		} else {
																			handleChange(e);
																		}
																	}}
																	name="showProfile"
																	classes={{
																		root: classes.switchRoot,
																		switchBase: classes.switchBase,
																		checked: classes.switchChecked,
																		thumb: classes.switchIcon,
																		track: classes.switchBar,
																	}}
																/>
															}
														/>
													</div>
												</GridItem>
											</GridContainer>
										</CardBody>
									</Card>
									<Card className={classes.cardFrame}>
										<CardBody>
											<GridContainer
												justifyContent="center"
												className={classes.verticalAligned}
											>
												<GridItem xs={9} sm={8}>
													<h5 className={classes.title}>
														Show "Education" To Public
													</h5>
												</GridItem>
												<GridItem xs={3} sm={2}>
													<div className={classes.switchCenter}>
														<FormControlLabel
															control={
																<Switch
																	checked={
																		setting[0].settingValue === 1
																			? setting[1].settingValue === 1
																			: false
																	}
																	onChange={handleChange}
																	//value="checkedA"
																	name="showEdu"
																	disabled={
																		setting[0].settingValue === 1 ? false : true
																	}
																	classes={{
																		root: classes.switchRoot,
																		switchBase: classes.switchBase,
																		checked: classes.switchChecked,
																		thumb: classes.switchIcon,
																		track: classes.switchBar,
																	}}
																/>
															}
														/>
													</div>
												</GridItem>
											</GridContainer>
										</CardBody>
									</Card>
									<Card className={classes.cardFrame}>
										<CardBody>
											<GridContainer
												justifyContent="center"
												className={classes.verticalAligned}
											>
												<GridItem xs={9} sm={8}>
													<h5 className={classes.title}>
														Show "Headline" To Public
													</h5>
												</GridItem>
												<GridItem xs={3} sm={2}>
													<div className={classes.switchCenter}>
														<FormControlLabel
															control={
																<Switch
																	checked={
																		setting[0].settingValue === 1
																			? setting[5].settingValue === 1
																			: false
																	}
																	onChange={handleChange}
																	//value="checkedA"
																	name="showHeadline"
																	disabled={
																		setting[0].settingValue === 1 ? false : true
																	}
																	classes={{
																		root: classes.switchRoot,
																		switchBase: classes.switchBase,
																		checked: classes.switchChecked,
																		thumb: classes.switchIcon,
																		track: classes.switchBar,
																	}}
																/>
															}
														/>
													</div>
												</GridItem>
											</GridContainer>
										</CardBody>
									</Card>
									<Card className={classes.cardFrame}>
										<CardBody>
											<GridContainer
												justifyContent="center"
												className={classes.verticalAligned}
											>
												<GridItem xs={9} sm={8}>
													<h5 className={classes.title}>
														Show "Contact Number" To Public
													</h5>
												</GridItem>
												<GridItem xs={3} sm={2}>
													<div className={classes.switchCenter}>
														<FormControlLabel
															control={
																<Switch
																	checked={
																		setting[0].settingValue === 1
																			? setting[6].settingValue === 1
																			: false
																	}
																	onChange={handleChange}
																	//value="checkedA"
																	name="showContactNo"
																	disabled={
																		setting[0].settingValue === 1 ? false : true
																	}
																	classes={{
																		root: classes.switchRoot,
																		switchBase: classes.switchBase,
																		checked: classes.switchChecked,
																		thumb: classes.switchIcon,
																		track: classes.switchBar,
																	}}
																/>
															}
														/>
													</div>
												</GridItem>
											</GridContainer>
										</CardBody>
									</Card>
									<Hidden mdUp>
										<Card className={classes.cardFrame}>
											<CardBody>
												<GridContainer
													justifyContent="center"
													className={classes.verticalAligned}
												>
													<GridItem xs={9} sm={8}>
														<h5 className={classes.title}>
															Show "Experience" To Public
														</h5>
													</GridItem>
													<GridItem xs={3} sm={2}>
														<div className={classes.switchCenter}>
															<FormControlLabel
																control={
																	<Switch
																		checked={
																			setting[0].settingValue === 1
																				? setting[2].settingValue === 1
																				: false
																		}
																		onChange={handleChange}
																		//value="checkedA"
																		name="showExp"
																		disabled={
																			setting[0].settingValue === 1
																				? false
																				: true
																		}
																		classes={{
																			root: classes.switchRoot,
																			switchBase: classes.switchBase,
																			checked: classes.switchChecked,
																			thumb: classes.switchIcon,
																			track: classes.switchBar,
																		}}
																	/>
																}
															/>
														</div>
													</GridItem>
												</GridContainer>
											</CardBody>
										</Card>
										<Card className={classes.cardFrame}>
											<CardBody>
												<GridContainer
													justifyContent="center"
													className={classes.verticalAligned}
												>
													<GridItem xs={9} sm={8}>
														<h5 className={classes.title}>
															Show "Profile Picture" To Public
														</h5>
													</GridItem>
													<GridItem xs={3} sm={2}>
														<div className={classes.switchCenter}>
															<FormControlLabel
																control={
																	<Switch
																		checked={
																			setting[0].settingValue === 1
																				? setting[3].settingValue === 1
																				: false
																		}
																		onChange={handleChange}
																		//value="checkedA"
																		name="showProfilePic"
																		disabled={
																			setting[0].settingValue ? false : true
																		}
																		classes={{
																			root: classes.switchRoot,
																			switchBase: classes.switchBase,
																			checked: classes.switchChecked,
																			thumb: classes.switchIcon,
																			track: classes.switchBar,
																		}}
																	/>
																}
															/>
														</div>
													</GridItem>
												</GridContainer>
											</CardBody>
										</Card>
										<Card className={classes.cardFrame}>
											<CardBody>
												<GridContainer
													justifyContent="center"
													className={classes.verticalAligned}
												>
													<GridItem xs={9} sm={8}>
														<h5 className={classes.title}>
															Show "About Me" To Public
														</h5>
													</GridItem>
													<GridItem xs={3} sm={2}>
														<div className={classes.switchCenter}>
															<FormControlLabel
																control={
																	<Switch
																		checked={
																			setting[0].settingValue === 1
																				? setting[4].settingValue === 1
																				: false
																		}
																		onChange={handleChange}
																		//value="checkedA"
																		name="showAboutMe"
																		disabled={
																			setting[0].settingValue === 1
																				? false
																				: true
																		}
																		classes={{
																			root: classes.switchRoot,
																			switchBase: classes.switchBase,
																			checked: classes.switchChecked,
																			thumb: classes.switchIcon,
																			track: classes.switchBar,
																		}}
																	/>
																}
															/>
														</div>
													</GridItem>
												</GridContainer>
											</CardBody>
										</Card>
										<Card className={classes.cardFrame}>
											<CardBody>
												<GridContainer
													justifyContent="center"
													className={classes.verticalAligned}
												>
													<GridItem xs={9} sm={8}>
														<h5 className={classes.title}>
															Show "Email" To Public
														</h5>
													</GridItem>
													<GridItem xs={3} sm={2}>
														<div className={classes.switchCenter}>
															<FormControlLabel
																control={
																	<Switch
																		checked={
																			setting[0].settingValue === 1
																				? setting[7].settingValue === 1
																				: false
																		}
																		onChange={handleChange}
																		//value="checkedA"
																		name="showEmail"
																		disabled={
																			setting[0].settingValue === 1
																				? false
																				: true
																		}
																		classes={{
																			root: classes.switchRoot,
																			switchBase: classes.switchBase,
																			checked: classes.switchChecked,
																			thumb: classes.switchIcon,
																			track: classes.switchBar,
																		}}
																	/>
																}
															/>
														</div>
													</GridItem>
												</GridContainer>
											</CardBody>
										</Card>
									</Hidden>
								</GridItem>
								<Hidden smDown>
									<GridItem xs={12} sm={12} md={6} className={classes.gridItem}>
										<Card className={classes.cardFrame}>
											<CardBody>
												<GridContainer
													justifyContent="center"
													className={classes.verticalAligned}
												>
													<GridItem xs={9} sm={8}>
														<h5 className={classes.title}>
															Show "Experience" To Public
														</h5>
													</GridItem>
													<GridItem xs={3} sm={2}>
														<div className={classes.switchCenter}>
															<FormControlLabel
																control={
																	<Switch
																		checked={
																			setting[0].settingValue === 1
																				? setting[2].settingValue === 1
																				: false
																		}
																		onChange={handleChange}
																		name="showExp"
																		disabled={
																			setting[0].settingValue === 1
																				? false
																				: true
																		}
																		classes={{
																			root: classes.switchRoot,
																			switchBase: classes.switchBase,
																			checked: classes.switchChecked,
																			thumb: classes.switchIcon,
																			track: classes.switchBar,
																		}}
																	/>
																}
															/>
														</div>
													</GridItem>
												</GridContainer>
											</CardBody>
										</Card>
										<Card className={classes.cardFrame}>
											<CardBody>
												<GridContainer
													justifyContent="center"
													className={classes.verticalAligned}
												>
													<GridItem xs={9} sm={8}>
														<h5 className={classes.title}>
															Show "Profile Picture" To Public
														</h5>
													</GridItem>
													<GridItem xs={3} sm={2}>
														<div className={classes.switchCenter}>
															<FormControlLabel
																control={
																	<Switch
																		checked={
																			setting[0].settingValue === 1
																				? setting[3].settingValue === 1
																				: false
																		}
																		onChange={handleChange}
																		//value="checkedA"
																		name="showProfilePic"
																		disabled={
																			setting[0].settingValue ? false : true
																		}
																		classes={{
																			root: classes.switchRoot,
																			switchBase: classes.switchBase,
																			checked: classes.switchChecked,
																			thumb: classes.switchIcon,
																			track: classes.switchBar,
																		}}
																	/>
																}
															/>
														</div>
													</GridItem>
												</GridContainer>
											</CardBody>
										</Card>
										<Card className={classes.cardFrame}>
											<CardBody>
												<GridContainer
													justifyContent="center"
													className={classes.verticalAligned}
												>
													<GridItem xs={9} sm={8}>
														<h5 className={classes.title}>
															Show "About Me" To Public
														</h5>
													</GridItem>
													<GridItem xs={3} sm={2}>
														<div className={classes.switchCenter}>
															<FormControlLabel
																control={
																	<Switch
																		checked={
																			setting[0].settingValue === 1
																				? setting[4].settingValue === 1
																				: false
																		}
																		onChange={handleChange}
																		//value="checkedA"
																		name="showAboutMe"
																		disabled={
																			setting[0].settingValue === 1
																				? false
																				: true
																		}
																		classes={{
																			root: classes.switchRoot,
																			switchBase: classes.switchBase,
																			checked: classes.switchChecked,
																			thumb: classes.switchIcon,
																			track: classes.switchBar,
																		}}
																	/>
																}
															/>
														</div>
													</GridItem>
												</GridContainer>
											</CardBody>
										</Card>
										<Card className={classes.cardFrame}>
											<CardBody>
												<GridContainer
													justifyContent="center"
													className={classes.verticalAligned}
												>
													<GridItem xs={9} sm={8}>
														<h5 className={classes.title}>
															Show "Email" To Public
														</h5>
													</GridItem>
													<GridItem xs={3} sm={2}>
														<div className={classes.switchCenter}>
															<FormControlLabel
																control={
																	<Switch
																		checked={
																			setting[0].settingValue === 1
																				? setting[7].settingValue === 1
																				: false
																		}
																		onChange={handleChange}
																		//value="checkedA"
																		name="showEmail"
																		disabled={
																			setting[0].settingValue === 1
																				? false
																				: true
																		}
																		classes={{
																			root: classes.switchRoot,
																			switchBase: classes.switchBase,
																			checked: classes.switchChecked,
																			thumb: classes.switchIcon,
																			track: classes.switchBar,
																		}}
																	/>
																}
															/>
														</div>
													</GridItem>
												</GridContainer>
											</CardBody>
										</Card>
									</GridItem>
								</Hidden>
							</GridContainer>
						</DialogContent>
					</div>
				</div>
				<DialogActions
					className={classes.modalFooterPrivacy + " " + classes.modalFooter}
				>
					<Button round onClick={handleSubmit} color="oppty">
						<b>Save</b>
					</Button>
				</DialogActions>
			</Dialog>
			<ShowProfileModalWindow />
		</div>
	);
};

export default PrivacySettingModal;
