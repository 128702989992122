import React from "react";

import { Typography, makeStyles } from "@material-ui/core";

import groupCategoryCardStyles from "assets/jss/material-kit-pro-react/views/componentStyle/discoverGroups/groupCategoryCardStyles"
const useStyles = makeStyles(groupCategoryCardStyles);

const GroupCategoryCard = (props) => {
  const styles = useStyles();

  return (
    <Typography component="div" className={styles.main}>
      <Typography component="div" className={styles.header}>
        <img src={props.image} alt="" />
      </Typography>
      <Typography component="div" className={styles.content}>
        <Typography component="div" className={styles.icon}>
          {props.icon}
        </Typography>
        <Typography component="p" className={styles.name}>
          {props.name}
        </Typography>

      </Typography>
    </Typography>
  )
}
export default GroupCategoryCard;