import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';

// core components
import Button from 'components/CustomButtons/Button.js';

import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import Close from '@material-ui/icons/Close';

import profileViewActivityPostCardStyles from '../../../assets/jss/material-kit-pro-react/views/componentStyle/profileView/myActivity/profileViewActivityPostCardStyles.js';
import profileViewActivityPollCardStyles from '../../../assets/jss/material-kit-pro-react/views/componentStyle/profileView/myActivity/profileViewActivityPollCardStyles.js';
import profilePageStyle from 'assets/jss/material-kit-pro-react/views/pageStyle/LandingPageFeed/CommentStyle.js';
import style from 'assets/jss/material-kit-pro-react/modalStyle.js';
import IconLibrary from 'views/Component/IconLibrary.js';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { getPollResultUserList } from 'configurations/redux/socialFeedSlice.js';
import defaultAvatar from 'assets/img/candidate_avatar.jpeg';
import { Link } from 'react-router-dom';
import { instanceUrl, encryptData } from 'configurations/configurations.js';
import ImageGallery from 'react-image-gallery';
import GridItem from '../../../components/Grid/GridItem.js';
import GridContainer from '../../../components/Grid/GridContainer.js';
import PostVideoList from 'views/Component/ProfileView/MyActivity/PostVideoList';
import CreatePostModalStyle from 'assets/jss/material-kit-pro-react/components/CreatePostModalStyle.js';
import JobCard from '../../Page/SearchPage/SearchJobPage/JobCard';
import ProfileViewActivityCard from '../../Component/ProfileView/MyActivity/ProfileViewActivityCard';

const useStyles = makeStyles(profilePageStyle);
const useStylesPost = makeStyles(profileViewActivityPostCardStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const PublicMediaModal = React.memo(({ open, onClose, post }) => {
  // console.log(pictureSource);
  const classes = useStyles();
  const classesPoll = makeStyles(CreatePostModalStyle)();
  const stylesPost = makeStyles(profileViewActivityPostCardStyles)();
  const classesPost = useStylesPost();
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();

  console.log(post);

  const modalResize = () => {
    var modalResize = document.getElementById('pollResultUser');
    if (modalResize) {
      modalResize.style.maxHeight = '440px';
      var maxSize = window.innerHeight - 74 - 80 - 70;
      if (maxSize < 0) maxSize = 0;
      modalResize.style.height = maxSize + 'px';
    }
  };

  React.useEffect(() => {
    if (open) {
      if (document.readyState === 'complete') {
        setTimeout(() => {
          modalResize();
        }, 500);
      }
      window.addEventListener('resize', modalResize);
      return function cleanup() {
        window.removeEventListener('resize', modalResize);
      };
    }
  }, [open]);

  return (
    <React.Fragment>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalCommentEdit + ' ' + classes.modal,
        }}
        open={open}
        // TransitionComponent={Transition}
        // keepMounted
        // disableBackdropClick
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            onClose(event, reason);
          }
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalTitleEdit}>
          <Button simple className={classes.modalCloseButton} key="close" aria-label="Close" onClick={onClose}>
            <IconLibrary.Close />
          </Button>
        </DialogTitle>
        <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
          {post.typeOptionId == 1 && (
            <div className={classesPost.imageGalleryMargin}>
              <ImageGallery
                showThumbnails={false}
                items={post.postTypes}
                showFullscreenButton={false}
                showPlayButton={false}
                lazyLoad={true}
              />
            </div>
          )}
          {post.typeOptionId == 2 && <PostVideoList attachmentList={post.postTypes} />}
          {post.typeOptionId == 3 && (
            <div className={classesPoll.pollSectionInPost}>
              <div className={classesPoll.QuestionandOptions}>
                <div className={classesPoll.Question}>
                  <span>{post.postTypes[0].poolList[0] && post.postTypes[0].poolList[0].question}</span>
                </div>
                <div className={classesPoll.optionGroup}>
                  {post.postTypes[0].poolList[0].poolOptionList
                    ? post.postTypes[0].poolList[0].poolOptionList.map((item, index) => (
                        <div className={classesPoll.Options} key={'Options ' + index}>
                          <span>{item.optionDesc}</span>
                        </div>
                      ))
                    : null}
                </div>
                <div className={classesPoll.pollDetails}>
                  {/* <span className={classesPoll.viewandresult}>0 votes</span> */}
                  <span className={classesPoll.daysLeft}>{post.postTypes[0].poolList[0].durationName} </span>
                  {/* <span className={classesPoll.viewandresult}>view result</span> */}
                </div>
              </div>
            </div>
          )}
          {post.typeOptionId == 4 &&
          post.sharedPost &&
          post.sharedPost.postList &&
          post.sharedPost.postList.length > 0 ? (
            <div className={stylesPost.sharedPost}>
              <ProfileViewActivityCard
                // key={post.postId}
                post={post.sharedPost.postList[0]}
                reportTypesList={null}
                reportReasonsList={null}
                updateFeedHandler={null}
                isCopyPost={false}
                handleEditPostClick={null}
                hashtagMatch={null}
                hideReactionBar={true}
                handleSharePostClick={null}
                searchPost={false}
                isGroupMember={false}
              />
            </div>
          ) : null}
          {post.typeOptionId == 5 && (
            <div className={classesPoll.sharePost}>
              <JobCard item={post.sharedJob[0]} />
            </div>
          )}
        </DialogContent>
        <div className={classes.modalFooter}></div>
      </Dialog>
    </React.Fragment>
  );
});
const mapStateToProps = state => ({
  // userStatus: state.auth.user,
  // image: state.user.image,
});
export default connect(mapStateToProps, null)(PublicMediaModal);
