import React from "react";

import {
	getCountryId,
	getLocationId,
	getLocationIdByCountry,
	getCurrencyId,
	getIndustryId,
	getEducationLevelId,
	getJobPostType,
	getJobType,
	getExperienceLevelList,
	getJobTypeIdByJobPostType,
	salaryRange,
} from "configurations/configurations.js";
import { roadmapJobCreateDone } from "configurations/redux/roadmapSlice.js";

import { Formik } from "formik";
import { corporateCreateJobSchema } from "configurations/configurations.js";
import { updateJobPost } from "configurations/redux/jobManagementSlice.js";
import { useDispatch, useSelector } from "react-redux";
import history from "../../../history.js";

//Page Components
import Header from "views/Component/Header.js";
import Footer from "views/Component/Footer.js";

import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete, {
	createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Slider from "@material-ui/core/Slider";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import Chip from "@material-ui/core/Chip";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Grid from "@material-ui/core/Grid";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Danger from "components/Typography/Danger.js";
import Parallax from "components/Parallax/Parallax.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Hidden from "@material-ui/core/Hidden"
import { Typography } from '@material-ui/core';
import WorkIcon from "@material-ui/icons/Work";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import LoadingModal from "views/Component/LoadingModal.js";
import Backdrop from "../../Component/Backdrop.js";
import corporateJobManagementPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/CorporateJobManagementPage/corporateJobManagementPageStyle.js";
import { Tooltip } from "@material-ui/core";
import IconLibrary from "views/Component/IconLibrary.js";
import { isDesktop, isMobile } from "react-device-detect";
import ReactQuill, { Quill } from "react-quill";
import MagicUrl from "quill-magic-url";
import "react-quill/dist/quill.snow.css";
import { useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles(corporateJobManagementPageStyle);
export default function CorporateJobCreatePage() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const userStatus = useSelector((state) => state.auth.user);
	const isRedirected = useSelector((state) => state.roadmap.isRedirected);
	const selectedRoadmapRole = useSelector(
		(state) => state.roadmap.selectedRoadMapRole
	);
	const [tooltipShow, setTooltipShow] = React.useState(false);
	const [hashtag, setTagInput] = React.useState("");
	const [industryList, setIndustryList] = React.useState([]);
	const [countryList, setCountryList] = React.useState([]);
	const [locationList, setLocationList] = React.useState([]);
	const [currencyList, setCurrencyList] = React.useState([]);
	const [educationList, setEducationList] = React.useState([]);
	const [experienceLevelList, setExperienceLevelList] = React.useState([]);
	const [questionsHiring, setQuestionsHiring] = React.useState([]);

	const [jobPostTypeList, setJobPostType] = React.useState([]);
	const [jobTypeList, setJobType] = React.useState([]);
	const [countryChange, setCountryChange] = React.useState();
	const [jobPostTypeChange, setJobPostTypeChange] = React.useState(null);
	const [qustion, setQuestion] = React.useState(true);
	const [tagError, setTagError] = React.useState(false);
	const [maxLengthError, setMaxLengthError] = React.useState(false);
	const bizId = useSelector((state) => state.business.bizId);

	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const snackBarShow = (message, variant, action) => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(message, {
			variant: variant,
			action,
		});
	};
	const onClickDismiss = (key) => () => {
		closeSnackbar(key);
	};

	const snackbarCustom = (title) => {
		const action = (key) => (
			<React.Fragment>
				<IconButton onClick={onClickDismiss(key)}>
					<CloseIcon />
				</IconButton>
			</React.Fragment>
		);
		snackBarShow(title, "default", action);
	};

	const fetCountryList = async () => {
		const res = await getCountryId();
		setCountryList(res.data.payload);
	};
	const fetLocationList = async () => {
		const res = await getLocationId();
		setLocationList(res.data.payload);
	};
	const fetchCurrencyList = async () => {
		const res = await getCurrencyId();
		setCurrencyList(res.data.payload);
	};
	const fetchIndustryList = async () => {
		const res = await getIndustryId();
		setIndustryList(res.data.payload);
	};
	const fetchEducationLevelList = async () => {
		const res = await getEducationLevelId();
		setEducationList(res.data.payload);
	};
	const fetchJobPostTypeList = async () => {
		const res = await getJobPostType();
		setJobPostType(res.data.payload);
	};
	const fetchJobTypeList = async () => {
		const res = await getJobType();
		setJobType(res.data.payload);
	};
	const fetchExperienceLevelList = async () => {
		const res = await getExperienceLevelList();
		setExperienceLevelList(res.data.payload);
	};
	const handleTag = (event) => {
		event.preventDefault();
		setTagInput(event.target.value);
	};
	function yearMonthValue(monthValue) {
		if (monthValue >= 12) {
			if (monthValue % 12 === 0) {
				return " " + parseInt(monthValue / 12) + " Year";
			} else {
				return (
					" " +
					parseInt(monthValue / 12) +
					" Year " +
					(monthValue % 12) +
					" Month "
				);
			}
		} else {
			return " " + monthValue + " Month";
		}
	}
	React.useEffect(() => {
		fetCountryList();
		// fetLocationList();
		fetchCurrencyList();
		fetchIndustryList();
		fetchEducationLevelList();
		fetchJobPostTypeList();
		// fetchJobTypeList();
		fetchExperienceLevelList();

		// const arr = [{
		// 	text: '',
		// 	name: 'Question1',
		// 	value: '',
		// 	yesclass: 'button',
		// 	noclass: 'button'
		// },
		// ]


		const arr = []

		setQuestionsHiring(arr);
	}, []);

	React.useEffect(() => {
		if (countryChange) {
			const fetLocationListByCountry = async (countryId) => {
				const res = await getLocationIdByCountry(countryId);
				setLocationList(res.data.payload);
			};
			fetLocationListByCountry(countryChange);
		}
	}, [countryChange]);
	React.useEffect(() => {
		if (jobPostTypeChange !== null) {
			const fetchJobTypeByJobPostType = async (jobPostTypeId) => {
				const res = await getJobTypeIdByJobPostType(jobPostTypeId);

				setJobType(res.data.payload);
			};
			fetchJobTypeByJobPostType(jobPostTypeChange);
		}
	}, [jobPostTypeChange]);
	// console.log(selectedRoadmapRole);
	React.useEffect(() => {
		console.log(tooltipShow);
	}, [tooltipShow]);

	const modules = {
		magicUrl: true,
	};
	const quillRefJReq = React.createRef(null);
	const quillRefJRes = React.createRef(null);
	Quill.register("modules/magicUrl", MagicUrl);

	const onQuillChangeJReq = (content, delta, source, editor) => {
		// console.log(content, delta, source, editor);
		var counterReq = document.getElementById("counterJReq");
		var counter = document.getElementById("quillCounterJReq");
		var editorArea = document.getElementById("quillEditorJReq");
		var editorClass = document.querySelector(
			"#quillEditorJReq .ql-container.ql-snow"
		);
		if (counter) {
			var wordCount = editor.getLength() - 1;
			counterReq.innerHTML = wordCount;
			if (wordCount <= 3000) {
				counter.innerHTML = "(" + wordCount + "/3000)";
				counter.style.color = "#000000DE";
			} else {
				counter.innerHTML =
					"* Requirement cannot exceed 3000 Characters (" +
					wordCount +
					"/3000)";
				counter.style.color = "red";
			}
		}
		if (editorArea) {
			editorArea.style.border = "0";
			if (wordCount > 0 && editorClass) {
				editorClass.style.height = "auto";
			} else {
				editorClass.style.height = editorClass.style.maxHeight;
			}
		}
	};

	const onQuillChangeJRes = (content, delta, source, editor) => {
		// console.log(content, delta, source, editor);
		var counterRes = document.getElementById("counterJRes");
		var counter = document.getElementById("quillCounterJRes");
		var editorArea = document.getElementById("quillEditorJRes");
		var editorClass = document.querySelector(
			"#quillEditorJRes .ql-container.ql-snow"
		);
		if (counter) {
			var wordCount = editor.getLength() - 1;
			counterRes.innerHTML = wordCount;
			if (wordCount <= 3000) {
				counter.innerHTML = "(" + wordCount + "/3000)";
				counter.style.color = "#000000DE";
			} else {
				counter.innerHTML =
					"* Requirement cannot exceed 3000 Characters (" +
					wordCount +
					"/3000)";
				counter.style.color = "red";
			}
		}
		if (editorArea) {
			editorArea.style.border = "0";
			if (wordCount > 0 && editorClass) {
				editorClass.style.height = "auto";
			} else {
				editorClass.style.height = editorClass.style.maxHeight;
			}
		}
	};

	const handleFirstQuestionChange = (event) => {
		let views = questionsHiring.slice();
		for (let i in views) {
			if (views[i].name == event.target.name) {
				if (views[i].value == '') {
					setQuestion(false)
				}
				views[i].value = event.target.value;
				setQuestionsHiring(views);
				break;
			}
		}
	}

	const hiringQuestionAnswer = (data, bit) => {
		let split = data.split("_");
		let views = questionsHiring.slice();
		for (let i in views) {
			if (views[i].value == split[1]) {
				if (split[0] == '/affirm') {
					views[i].yesclass = 'active';
					views[i].noclass = "";
				} else {
					views[i].noclass = 'active';
					views[i].yesclass = "";
				}
				setQuestionsHiring(views);
				setQuestion(true)
				break;
			}
		}
	}

	const addNewQuestions = () => {
		if (questionsHiring.length < 10) {
			let arr = {
				text: '',
				name: `Question${questionsHiring.length + 1}`,
				value: '',
				yesclass: 'button',
				noclass: 'button'
			}

			setQuestionsHiring(questionsHiring => [...questionsHiring, arr]);
		} else {
			snackbarCustom("There can only be maximum of 10 questions which can be added.");
		}

	}

	return (
		<div>
			<Header />

			<div className={classNames(classes.main, classes.mainRaised)}>
				<div className={classes.container}>
					<Formik
						initialValues={{
							title: isRedirected ? selectedRoadmapRole[0].roleName : "",
							industryId: "",
							countryId: "",
							locationId: "",
							location: "",
							eduLvlId: "",
							role: "",
							experienceId: 1,
							servicePeriod: 6,
							responsibility: isRedirected
								? selectedRoadmapRole[0].jobDescription
								: "",
							requirement: isRedirected
								? selectedRoadmapRole[0].qualification
								: "",
							currencyId: 1,
							salaryMax: 600,
							salaryMin: 0,
							hashTags: [],
							jobPostTypeId: "",
							jobTypeId: "",
							draft: -1,
						}}
						validationSchema={corporateCreateJobSchema}
						onSubmit={(data, { setSubmitting }) => {
							setSubmitting(true);
							const date = new Date();
							var questFinal = [];
							if (questionsHiring.length > 0) {
								questionsHiring.map((item, index) => {
									if (item.value != '') {
										questFinal.push({
											"quesId": -1,
											"answerId": item.yesclass == 'active' ? 1 : 2,
											"recordTypeId": 1,
											"quesDescription": item.value
										})
									}

								});
							}
							var counterReq = document.getElementById("counterJReq");
							var counterRes = document.getElementById("counterJRes");
							var quillJReq = { descHTML: "", descCount: 0 };
							var quillJRes = { descHTML: "", descCount: 0 };
							if (quillRefJReq.current) {
								var comment = quillRefJReq.current;
								console.log(comment);
								quillJReq = {
									descHTML: comment.state.value ? comment.state.value : "",
									descCount: counterReq.innerHTML,
								};
							}
							if (quillRefJRes.current) {
								var comment = quillRefJRes.current;
								quillJRes = {
									descHTML: comment.state.value ? comment.state.value : "",
									descCount: counterRes.innerHTML,
								};
							}
							console.log(quillJReq, quillJRes);

							const updateRequest = {
								email: userStatus.email,
								acctId: userStatus.acctId,
								accessToken: localStorage.getItem("token"),
								title: data.title.trim(),
								industryId: data.industryId,
								countryId: data.countryId,
								locationId: data.locationId,
								location: data.location,
								eduLvlId: data.eduLvlId,
								role: data.role.trim(),
								jobPostTypeId: data.jobPostTypeId,
								jobTypeId: data.jobTypeId,
								servicePeriod:
									data.jobTypeId !== 1 ? data.servicePeriod * 4 : 0,
								responsibility: quillJRes.descHTML, //data.responsibility.trim(),
								requirement: quillJReq.descHTML, //data.requirement.trim(),
								currencyId: data.currencyId,
								statusId: data.draft ? 0 : 1,
								salaryMin: data.salaryMin,
								salaryMax: data.salaryMax,
								hashTags: data.hashTags,
								experienceId: data.experienceId,
								expiryDate:
									date.getFullYear() +
									10 +
									"-" +
									(date.getMonth() + 1) +
									"-" +
									date.getDate() +
									" " +
									date.getHours() +
									":" +
									date.getMinutes(),
								complimentaryFlag: 1,
								bizId: bizId,
								questionaires: questFinal
							};

							if (!data.draft) {
								if (quillJReq.descCount == 0 || quillJRes.descCount == 0) {
									if (quillJRes.descCount == 0) {
										var counter = document.getElementById("quillCounterJRes");
										var editor = document.getElementById("quillEditorJRes");
										if (counter) {
											counter.innerHTML =
												"* Please enter the responsibility (0/3000)";
											counter.style.color = "red";
										}
										if (editor) editor.style.border = "1px solid red";
									}

									if (quillJReq.descCount == 0) {
										var counter = document.getElementById("quillCounterJReq");
										var editor = document.getElementById("quillEditorJReq");
										if (counter) {
											counter.innerHTML =
												"* Please enter the responsibility (0/3000)";
											counter.style.color = "red";
										}
										if (editor) editor.style.border = "1px solid red";
									}
									setSubmitting(false);
									return;
								}
							}
							if (qustion == false) {
								snackbarCustom("Please fill the answer");
								setSubmitting(false);
								return;
							}
							if (quillJReq.descCount > 3000) {
								var editor = document.getElementById("quillEditorJReq");
								if (editor) editor.style.border = "1px solid red";
								snackbarCustom("Requirement cannot exceed 3000 Characters");
								setSubmitting(false);
								return;
							}
							if (quillJRes.descCount > 3000) {
								var editor = document.getElementById("quillEditorJRes");
								if (editor) editor.style.border = "1px solid red";
								snackbarCustom("Responsibility cannot exceed 3000 Characters");
								setSubmitting(false);
								return;
							}

							if (data) {
								dispatch(updateJobPost(updateRequest), (result) => {
									if (result.data.payload.recordId == null) {
										setSubmitting(false);
										return true
									}
								});
							}
							setTimeout(() => {
								setSubmitting(false);
								history.push("/corporate-job-management-page");
							}, 2500);
						}}
					>
						{({
							handleChange,
							values,
							errors,
							touched,
							setFieldValue,
							handleSubmit,
							handleBlur,
							isSubmitting,
							push,
						}) => (
							<form onSubmit={handleSubmit}>
								<GridContainer
									justifyContent="space-between"
									alignContent="center"
									alignItems="center"
								>
									<GridItem xs={12} sm={12} md={12} lg={4}>
										<h3 className={classes.title}>Post Your Job</h3>
									</GridItem>
									<GridItem xs={12} sm={12} md={12} lg={7}>
										<GridContainer
											justifyContent="flex-end"
											alignContent="center"
											alignItems="center"
										>
											<GridItem xs={4} sm={4} md={4} lg={3}>
												<Button
													onClick={() => {
														history.push("/corporate-job-management-page");
														dispatch(roadmapJobCreateDone());
													}}
													fullWidth
													round
													color="discard"
												>
													<b>Discard</b>
												</Button>
											</GridItem>
											<GridItem xs={4} sm={4} md={4} lg={3}>
												<Button
													onClick={() => {
														values.draft = 1;
														handleSubmit();
														dispatch(roadmapJobCreateDone());
													}}
													disabled={!!isSubmitting}
													fullWidth
													round
													color="draft"
												>
													<b>Save Draft</b>
												</Button>
											</GridItem>

											<GridItem xs={4} sm={4} md={4} lg={3}>
												<Button
													onClick={(e) => {
														values.draft = 0;
														dispatch(roadmapJobCreateDone());

														handleSubmit();
													}}
													disabled={!!isSubmitting}
													fullWidth
													round
													color="oppty"
												>
													<b>Publish</b>
												</Button>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>
								<GridContainer justifyContent="center">
									<GridItem xs={12} sm={12} md={6}>
										<Card className={classes.cardHeight}>
											<CardBody>
												<GridContainer justifyContent="center">
													<GridItem>
														<h4 className={classes.jobCreateTitle}>
															Job Title
														</h4>
													</GridItem>
													<GridItem className={classes.gridHeight}>
														<TextField
															value={values.title ? values.title : ""}
															label={
																touched.title && Boolean(errors.title)
																	? errors.title
																	: ""
															}
															variant="outlined"
															fullWidth
															onChange={(event) => {
																setFieldValue("title", event.target.value);
															}}
															error={touched.title && Boolean(errors.title)}
															/* helperText={touched.title ? errors.title : ""} */
															placeholder="Ex: UI Designer"
															id="title"
															name="title"
														/* InputProps={{
															startAdornment: (
																<InputAdornment position="start">
																	<WorkIcon
																		className={classes.inputIconsColor}
																	/>
																</InputAdornment>
															),
														}} */
														/>
													</GridItem>
													<GridItem>
														<GridContainer>
															<GridItem xs={12} sm={6} md={6}>
																<h4 className={classes.jobCreateTitle}>
																	Job Type
																</h4>
															</GridItem>
															<Hidden xsDown>
																<GridItem xs={12} sm={6} md={6}>
																	{(values.jobPostTypeId === 2 ||
																		values.jobPostTypeId === 1) && (
																			<h4 className={classes.jobCreateTitle}>
																				Job Post Type
																			</h4>
																		)}
																</GridItem>
															</Hidden>
															<GridItem
																xs={12}
																sm={6}
																md={6}
																className={classes.gridHeight}
															>
																<Autocomplete
																	options={jobPostTypeList}
																	value={
																		jobPostTypeList.filter((item) => {
																			return (
																				item.jobPostTypeId ===
																				values.jobPostTypeId
																			);
																		})[0] || null
																	}
																	getOptionLabel={(option) =>
																		option.jobPostType ? option.jobPostType : ""
																	}
																	getOptionSelected={(option, value) => {
																		if (
																			option.jobPostTypeId ===
																			value.jobPostTypeId
																		) {
																			//console.log(option, value);
																			return true;
																		} else {
																			return false;
																		}
																	}}
																	onChange={(event, value) => {
																		if (value) {
																			setFieldValue(
																				"jobPostTypeId",
																				value.jobPostTypeId
																			);
																			setJobPostTypeChange(value.jobPostTypeId);
																			setFieldValue("jobTypeId", "");
																		} else {
																			setFieldValue("jobPostTypeId", "");
																			setFieldValue("jobTypeId", "");
																		}
																	}}
																	id="jobPostTypeId"
																	name="jobPostTypeId"
																	renderInput={(params) => (
																		<TextField
																			{...params}
																			/* label={"Job Post Type"} */
																			label={
																				touched.jobPostTypeId
																					? errors.jobPostTypeId
																					: ""
																			}
																			variant="outlined"
																			error={
																				touched.jobPostTypeId &&
																				Boolean(errors.jobPostTypeId)
																			}
																		/* helperText={
																			
																		} */
																		/>
																	)}
																/>
															</GridItem>
															<Hidden smUp>
																<GridItem xs={12} sm={12} md={6}>
																	{(values.jobPostTypeId === 1 ||
																		values.jobPostTypeId === 2) && (
																			<h4 className={classes.jobCreateTitle}>
																				Job Post Type
																			</h4>
																		)}
																</GridItem>
															</Hidden>
															{(values.jobPostTypeId === 1 ||
																values.jobPostTypeId === 2) && (
																	<GridItem
																		xs={12}
																		sm={6}
																		md={6}
																		className={classes.gridHeight}
																	>
																		<Autocomplete
																			options={jobTypeList}
																			onBlur={handleBlur}
																			value={
																				jobTypeList.filter((item) => {
																					return (
																						item.jobTypeId === values.jobTypeId
																					);
																				})[0] || null
																			}
																			getOptionLabel={(option) =>
																				option.jobType ? option.jobType : ""
																			}
																			getOptionSelected={(option, value) => {
																				if (
																					option.jobTypeId === value.jobTypeId
																				) {
																					//console.log(option, value);
																					return true;
																				} else {
																					return false;
																				}
																			}}
																			onChange={(event, value) => {
																				if (value) {
																					setFieldValue(
																						"jobTypeId",
																						value.jobTypeId
																					);
																				}
																			}}
																			id="jobTypeId"
																			name="jobTypeId"
																			renderInput={(params) => (
																				<TextField
																					{...params}
																					/* label={"Job Type"} */
																					label={
																						touched.jobTypeId
																							? errors.jobTypeId
																							: ""
																					}
																					variant="outlined"
																					error={
																						touched.jobTypeId &&
																						Boolean(errors.jobTypeId)
																					}
																				/* helperText={
																				
																				} */
																				/>
																			)}
																		/>
																	</GridItem>
																)}
														</GridContainer>
													</GridItem>
													<GridItem xs={12} sm={12} md={12}>
														<h4 className={classes.jobCreateTitle}>
															Company Industry
														</h4>
													</GridItem>
													<GridItem className={classes.gridHeight}>
														<Autocomplete
															options={industryList}
															onBlur={handleBlur}
															value={
																industryList.filter((item) => {
																	return item.industryId === values.industryId;
																})[0] || null
															}
															getOptionLabel={(option) =>
																option.industryName ? option.industryName : ""
															}
															getOptionSelected={(option, value) => {
																if (option.industryId === value.industryId) {
																	//console.log(option, value);
																	return true;
																} else {
																	return false;
																}
															}}
															onChange={(event, value) => {
																if (value) {
																	setFieldValue("industryId", value.industryId);
																}
															}}
															id="industryId"
															name="industryId"
															renderInput={(params) => (
																<TextField
																	{...params}
																	/* label={"Company Industry"} */
																	label={
																		touched.industryId &&
																			Boolean(errors.industryId)
																			? errors.industryId
																			: ""
																	}
																	variant="outlined"
																	error={
																		touched.industryId &&
																		Boolean(errors.industryId)
																	}
																/* helperText={
																	touched.industryId ? errors.industryId : ""
																} */
																/>
															)}
														/>
													</GridItem>
												</GridContainer>

												<GridContainer justifyContent="center">
													<GridItem xs={12} sm={6} md={6}>
														<h4 className={classes.jobCreateTitle}>Country</h4>
													</GridItem>

													<Hidden xsDown>
														<GridItem xs={12} sm={6} md={6}>
															<h4 className={classes.jobCreateTitle}>
																Location
															</h4>
														</GridItem>
													</Hidden>
													<GridItem
														xs={12}
														sm={6}
														md={6}
														className={classes.gridHeight}
													>
														<Autocomplete
															options={countryList}
															onBlur={handleBlur}
															value={
																countryList.filter((item) => {
																	return item.countryId === values.countryId;
																})[0] || null
															}
															filterOptions={createFilterOptions({
																matchFrom: "start",
																stringify: (option) => option.countryName,
															})}
															getOptionLabel={(option) =>
																option.countryName ? option.countryName : ""
															}
															getOptionSelected={(option, value) => {
																if (option === value) {
																	//console.log(option, value);
																	return true;
																} else {
																	return false;
																}
															}}
															onChange={(event, value) => {
																if (value) {
																	setFieldValue("countryId", value.countryId);
																	setCountryChange(value.countryId);
																} else {
																	setFieldValue("countryId", "");
																	setFieldValue("locationId", "");
																}
															}}
															id="countryId"
															name="countryId"
															renderInput={(params) => (
																<TextField
																	{...params}
																	label={
																		touched.countryId &&
																			Boolean(errors.countryId)
																			? errors.countryId
																			: ""
																	}
																	variant="outlined"
																	error={
																		touched.countryId &&
																		Boolean(errors.countryId)
																	}
																/* helperText={
																	touched.countryId ? errors.countryId : ""
																} */
																/>
															)}
														/>
													</GridItem>
													<Hidden smUp>
														<GridItem>
															<h4 className={classes.jobCreateTitle}>
																Location
															</h4>
														</GridItem>
													</Hidden>
													<GridItem
														xs={12}
														sm={6}
														md={6}
														className={classes.gridHeight}
													>
														<Autocomplete
															options={locationList}
															onBlur={handleBlur}
															disabled={!values.countryId}
															value={
																locationList.filter((item) => {
																	return item.locationId === values.locationId;
																})[0] || null
															}
															getOptionLabel={(option) =>
																option.locationName ? option.locationName : ""
															}
															getOptionSelected={(option, value) => {
																if (option === value) {
																	//console.log(option, value);
																	return true;
																} else {
																	return false;
																}
															}}
															onChange={(event, value) => {
																if (value) {
																	setFieldValue("locationId", value.locationId);
																} else {
																	setFieldValue("locationId", "");
																}
															}}
															id="locationId"
															name="locationId"
															renderInput={(params) => (
																<TextField
																	{...params}
																	/* label={"Location"} */
																	label={
																		touched.locationId &&
																			Boolean(errors.locationId)
																			? errors.locationId
																			: ""
																	}
																	variant="outlined"
																	error={
																		touched.locationId &&
																		Boolean(errors.locationId)
																	}
																/* helperText={
																	touched.locationId ? errors.locationId : ""
																} */
																/>
															)}
														/>
													</GridItem>
												</GridContainer>
												<GridContainer justifyContent="center">
													<GridItem>
														<h4 className={classes.jobCreateTitle}>
															Minimum Level of Education Required
														</h4>
													</GridItem>
													<GridItem className={classes.gridHeight}>
														<Autocomplete
															options={educationList}
															onBlur={handleBlur}
															value={
																educationList.filter((item) => {
																	return item.eduLvlId === values.eduLvlId;
																})[0] || null
															}
															getOptionSelected={(option, value) => {
																return option === value;
															}}
															getOptionLabel={(option) =>
																option.eduLvlName ? option.eduLvlName : ""
															}
															onChange={(event, value) => {
																if (value) {
																	setFieldValue("eduLvlId", value.eduLvlId);
																}
															}}
															id="eduLvlId"
															name="eduLvlId"
															renderInput={(params) => (
																<TextField
																	{...params}
																	/* label={"Level of Education Required"} */
																	label={
																		touched.eduLvlId && Boolean(errors.eduLvlId)
																			? errors.eduLvlId
																			: ""
																	}
																	variant="outlined"
																	error={
																		touched.eduLvlId && Boolean(errors.eduLvlId)
																	}
																/* helperText={
																	touched.eduLvlId ? errors.eduLvlId : ""
																} */
																/>
															)}
														/>
													</GridItem>
													<GridItem>
														<h4 className={classes.jobCreateTitle}>Role</h4>
													</GridItem>
													<GridItem className={classes.gridHeight}>
														<TextField
															/* label="Role" */
															placeholder="Ex: Manager"
															variant="outlined"
															fullWidth
															onChange={(event) => {
																setFieldValue("role", event.target.value);
															}}
															label={
																touched.role && Boolean(errors.role)
																	? errors.role
																	: ""
															}
															error={touched.role && Boolean(errors.role)}
															id="role"
															name="role"
														/>
													</GridItem>
													<GridItem>
														<h4 className={classes.jobCreateTitle}>
															Minimum Experience Level{" "}
															{isRedirected &&
																selectedRoadmapRole[0].experience !== "" && (
																	<Tooltip
																		id="tooltip-top"
																		title={
																			<div>
																				<span>Recommended: </span>
																				<p>
																					{selectedRoadmapRole[0].experience +
																						" year(s)"}
																				</p>
																			</div>
																		}
																		placement="top"
																		classes={{ tooltip: classes.tooltip }}
																		open={
																			tooltipShow === "experience"
																				? true
																				: false
																		}
																	>
																		<Button
																			justIcon
																			className={classes.infoIcon}
																			color="white"
																			onClick={() => {
																				console.log(tooltipShow, isDesktop);
																				if (
																					tooltipShow === "experience" &&
																					!isDesktop
																				)
																					setTooltipShow("");
																				else setTooltipShow("experience");
																			}}
																			onMouseOver={() => {
																				if (isDesktop)
																					setTooltipShow("experience");
																			}}
																			onMouseLeave={() => {
																				setTooltipShow("");
																			}}
																		>
																			<IconLibrary.Info />
																		</Button>
																	</Tooltip>
																)}
														</h4>
														<h5
															className={
																classes.jobCreateTitle +
																" " +
																classes.grayDescription
															}
														>
															{/* {values.expId === 0
																? "Less than a Year"
																: values.expId === 11
																? "More than 10 Years"
																: values.expId + " Years"} */}
															{experienceLevelList[0] &&
																experienceLevelList[
																	values.experienceId === 0
																		? values.experienceId
																		: values.experienceId - 1
																].experience}
														</h5>
													</GridItem>
													<GridItem>
														<GridContainer>
															<GridItem xs={2} sm={1} md={1}>
																<span
																	className={
																		classes.jobCreateTitle +
																		" " +
																		classes.grayDescription
																	}
																>
																	0
																</span>
															</GridItem>
															<GridItem xs={7} sm={9} md={9}>
																<Slider
																	value={
																		typeof values.experienceId === "number"
																			? values.experienceId
																			: 0
																	}
																	onChange={(event, value) => {
																		setFieldValue("experienceId", value);
																	}}
																	/* 	name="servicePeriod"
																	id="servicePeriod" */
																	step={1}
																	min={
																		experienceLevelList[0]
																			? experienceLevelList[0].experienceId
																			: 1
																	}
																	max={
																		experienceLevelList[0]
																			? experienceLevelList[
																				experienceLevelList.length - 1
																			].experienceId
																			: 12
																	}
																	//marks={sizeMarks}
																	aria-labelledby="input-slider"
																/>
															</GridItem>
															<GridItem xs={3} sm={2} md={2}>
																<span
																	className={
																		classes.jobCreateTitle +
																		" " +
																		classes.grayDescription
																	}
																>
																	10+
																</span>
															</GridItem>
														</GridContainer>
													</GridItem>
													<GridItem>
														<h4 className={classes.jobCreateTitle}>Job Tags</h4>
													</GridItem>
													<GridItem className={classes.gridHeight}>
														{tagError ? (
															<SnackbarContent
																message={<b>Duplicate Tag is not allowed</b>}
																close
																color="opptyWarning"
																icon="info_outline"
															/>
														) : null}
														{maxLengthError && (
															<SnackbarContent
																message={
																	<b>Tag cannot exceed 100 characters</b>
																}
																close
																color="opptyWarning"
																icon="info_outline"
															/>
														)}
														{values.hashTags.map((item, index) => {
															return (
																<Chip
																	key={index}
																	label={
																		<span className={classes.tagDescription}>
																			{item.hashtag}
																		</span>
																	}
																	className={classes.chip}
																	onDelete={() => {
																		setFieldValue(
																			"hashTags",
																			values.hashTags.filter((tag) => {
																				return tag !== item;
																			})
																		);
																	}}
																/>
															);
														})}
														<TextField
															id="tagInput"
															value={hashtag}
															variant="outlined"
															fullWidth
															disabled={
																values.hashTags.length >= 10 ? true : false
															}
															style={{ marginTop: "20px" }}
															onKeyPress={(data) => {
																if (data.charCode === 13) {
																	if (data.target.value.trim().length > 0) {
																		if (data.target.value.length > 100) {
																			data.preventDefault();
																			setMaxLengthError(true);
																			// setTagInput("");
																			setTagError(false);
																		} else {
																			setMaxLengthError(false);
																			const index = values.hashTags.findIndex(
																				(item) =>
																					item.hashtag.toLowerCase() ===
																					hashtag.toLowerCase()
																			);

																			if (index === -1) {
																				values.hashTags = values.hashTags.concat(
																					{
																						hashId: -1,
																						hashtag: hashtag,
																					}
																				);
																				data.preventDefault();
																				setTagInput("");
																				setTagError(false);
																			} else {
																				data.preventDefault();
																				setTagInput("");
																				setTagError(true);
																			}
																		}
																		//setFieldValue("hashTags", { tag: tagInput });
																	}
																}
															}}
															// onKeyDown={(data) => {
															// 	alert(data.keyCode);
															// }}
															onBlur={(data) => {
																if (data.target.value.trim().length > 0) {
																	if (data.target.value.length > 100) {
																		data.preventDefault();
																		setMaxLengthError(true);
																		// setTagInput("");
																		setTagError(false);
																	} else {
																		setMaxLengthError(false);
																		const index = values.hashTags.findIndex(
																			(item) =>
																				item.hashtag.toLowerCase() ===
																				hashtag.toLowerCase()
																		);

																		if (index === -1) {
																			values.hashTags = values.hashTags.concat({
																				hashId: -1,
																				hashtag: hashtag,
																			});
																			data.preventDefault();
																			setTagInput("");
																			setTagError(false);
																		} else {
																			data.preventDefault();
																			setTagInput("");
																			setTagError(true);
																		}
																	}
																	//setFieldValue("hashTags", { tag: tagInput });
																}
															}}
															onKeyDown={() => {
																if (tagError || maxLengthError) {
																	setTagError(false);
																	setMaxLengthError(false);
																}
															}}
															label={
																values.hashTags.length >= 10 ? (
																	<p>Maximum Tag Reached</p>
																) : (
																	<p>Add Tag</p>
																)
															}
															onChange={handleTag}
														/>
														<Button
															justIcon
															style={{ boxShadow: "none" }}
															color="white"
															className={classes.addTagPos}
														>
															<IconLibrary.Create />
														</Button>
													</GridItem>
												</GridContainer>

												{values.jobPostTypeId !== 2 && (
													<GridContainer justifyContent="center">
														<GridItem>
															<h4 className={classes.jobCreateTitle}>
																Service Periods
															</h4>
															<h5
																className={
																	classes.jobCreateTitle +
																	" " +
																	classes.grayDescription
																}
															>
																{yearMonthValue(values.servicePeriod)}
															</h5>
														</GridItem>
														<GridItem xs={8} sm={10} md={10}>
															<Slider
																value={
																	typeof values.servicePeriod === "number"
																		? values.servicePeriod
																		: 0
																}
																onChange={(event, value) => {
																	setFieldValue("servicePeriod", value);
																}}
																name="servicePeriod"
																id="servicePeriod"
																step={1}
																min={0}
																max={24}
																//marks={sizeMarks}
																aria-labelledby="input-slider"
															/>
															{errors.servicePeriod && touched.servicePeriod && (
																<Danger>
																	<h6>{errors.servicePeriod}</h6>
																</Danger>
															)}
														</GridItem>
														<GridItem xs={4} sm={2} md={2}>
															<Input
																fullWidth
																className={classes.input}
																value={values.servicePeriod}
																variant="outlined"
																onChange={(event) => {
																	const regex = /^[0-9\b]+$/;
																	if (
																		event.target.value === "" ||
																		regex.test(event.target.value)
																	) {
																		setFieldValue(
																			"servicePeriod",
																			event.target.value === ""
																				? ""
																				: Number(event.target.value)
																		);
																	}
																}}
																inputProps={{
																	step: 1,
																	min: 0,
																	max: 24,
																}}
															/>
														</GridItem>
													</GridContainer>
												)}
												{values.jobPostTypeId === 2 && values.jobTypeId !== 1 && (
													<GridContainer justifyContent="center">
														<GridItem>
															<h4 className={classes.jobCreateTitle}>
																Service Periods
															</h4>
															<h5
																className={
																	classes.jobCreateTitle +
																	" " +
																	classes.grayDescription
																}
															>
																{yearMonthValue(values.servicePeriod)}
															</h5>
														</GridItem>
														<GridItem xs={8} sm={10} md={10}>
															<Slider
																value={
																	typeof values.servicePeriod === "number"
																		? values.servicePeriod
																		: 0
																}
																onChange={(event, value) => {
																	setFieldValue("servicePeriod", value);
																}}
																name="servicePeriod"
																id="servicePeriod"
																step={1}
																min={0}
																max={24}
																//marks={sizeMarks}
																aria-labelledby="input-slider"
															/>
															{errors.servicePeriod && touched.servicePeriod && (
																<Danger>
																	<h6>{errors.servicePeriod}</h6>
																</Danger>
															)}
														</GridItem>
														<GridItem xs={4} sm={2} md={2}>
															<Input
																fullWidth
																className={classes.input}
																value={values.servicePeriod}
																variant="outlined"
																onChange={(event) => {
																	const regex = /^[0-9\b]+$/;
																	if (
																		event.target.value === "" ||
																		regex.test(event.target.value)
																	) {
																		setFieldValue(
																			"servicePeriod",
																			event.target.value === ""
																				? ""
																				: Number(event.target.value)
																		);
																	}
																}}
																inputProps={{
																	step: 1,
																	min: 0,
																	max: 24,
																}}
															/>
														</GridItem>
													</GridContainer>
												)}
												<GridContainer justifyContent="center">
													<GridItem>
														<h4 className={classes.jobCreateTitle}>
															Salary{" "}
															{isRedirected &&
																selectedRoadmapRole[0].salaryRange !== "" && (
																	<Tooltip
																		id="tooltip-top"
																		title={
																			<div>
																				<span>Recommended: </span>
																				<p>
																					{selectedRoadmapRole[0].salaryRange}
																				</p>
																			</div>
																		}
																		placement="top"
																		classes={{ tooltip: classes.tooltip }}
																		open={
																			tooltipShow === "salary" ? true : false
																		}
																	>
																		<Button
																			justIcon
																			className={classes.infoIcon}
																			color="white"
																			onClick={() => {
																				if (
																					tooltipShow === "salary" &&
																					!isDesktop
																				)
																					setTooltipShow("");
																				else setTooltipShow("salary");
																			}}
																			onMouseOver={() => {
																				if (isDesktop) setTooltipShow("salary");
																			}}
																			onMouseLeave={() => {
																				setTooltipShow("");
																			}}
																		>
																			<IconLibrary.Info />
																		</Button>
																	</Tooltip>
																)}
														</h4>
													</GridItem>
												</GridContainer>
												<GridContainer>
													<GridItem
														xs={12}
														sm={6}
														md={6}
														className={classes.gridHeight}
													>
														<Autocomplete
															options={currencyList}
															onBlur={handleBlur}
															value={
																currencyList.filter((item) => {
																	return item.currencyId === values.currencyId;
																})[0] || ""
															}
															getOptionSelected={(option, value) => {
																if (!value) {
																	return false;
																}
																return option.currencyId === value.currencyId;
															}}
															getOptionLabel={(option) =>
																option.currencyCode ? option.currencyCode : ""
															}
															onChange={(event, value) => {
																if (value) {
																	setFieldValue("currencyId", value.currencyId);
																} else {
																	setFieldValue("currencyId", "");
																}
															}}
															id="currencyId"
															name="currencyId"
															renderInput={(params) => (
																<TextField
																	{...params}
																	label={"Currency List"}
																	variant="outlined"
																	error={
																		touched.currencyId &&
																		Boolean(errors.currencyId)
																	}
																	helperText={
																		touched.currencyId ? errors.currencyId : ""
																	}
																/>
															)}
														/>
													</GridItem>

													<GridItem
														xs={12}
														sm={3}
														md={3}
														className={classes.gridHeight}
													>
														<TextField
															fullWidth
															className={classes.input}
															value={values.salaryMin}
															label="Minimum Salary"
															InputLabelProps={{
																shrink: true,
															}}
															variant="outlined"
															error={
																touched.salaryMin && Boolean(errors.salaryMin)
															}
															helperText={
																touched.salaryMin ? errors.salaryMin : ""
															}
															onChange={(event) => {
																const regex = /^[0-9\b]+$/;
																if (
																	event.target.value === "" ||
																	regex.test(event.target.value)
																) {
																	setFieldValue(
																		"salaryMin",
																		event.target.value === ""
																			? ""
																			: Number(event.target.value)
																	);
																}
															}}
														/>
													</GridItem>

													<GridItem
														xs={12}
														sm={3}
														md={3}
														className={classes.gridHeight}
													>
														<TextField
															fullWidth
															variant="outlined"
															className={classes.input}
															value={values.salaryMax}
															label="Maximum Salary"
															InputLabelProps={{
																shrink: true,
															}}
															error={
																touched.salaryMax && Boolean(errors.salaryMax)
															}
															helperText={
																touched.salaryMax ? errors.salaryMax : ""
															}
															onChange={(event) => {
																const regex = /^[0-9\b]+$/;
																if (
																	event.target.value === "" ||
																	regex.test(event.target.value)
																) {
																	setFieldValue(
																		"salaryMax",
																		event.target.value === ""
																			? ""
																			: Number(event.target.value)
																	);
																}
															}}
															onBlur={handleBlur}
														/>
													</GridItem>
												</GridContainer>
												{values.jobPostTypeId !== 2 && (
													<GridContainer>
														<GridItem>
															<h4 className={classes.jobCreateTitle}>
																Salary Range{" "}
																{isRedirected &&
																	selectedRoadmapRole[0].salaryRange !== "" && (
																		<Tooltip
																			id="tooltip-top"
																			title={
																				<div>
																					<span>Recommended: </span>
																					<p>
																						{selectedRoadmapRole[0].salaryRange}
																					</p>
																				</div>
																			}
																			placement="top"
																			classes={{ tooltip: classes.tooltip }}
																			open={
																				tooltipShow === "salaryRange"
																					? true
																					: false
																			}
																		>
																			<Button
																				justIcon
																				className={classes.infoIcon}
																				color="white"
																				onClick={() => {
																					if (
																						tooltipShow === "salaryRange" &&
																						!isDesktop
																					)
																						setTooltipShow("");
																					else setTooltipShow("salaryRange");
																				}}
																				onMouseOver={() => {
																					if (isDesktop)
																						setTooltipShow("salaryRange");
																				}}
																				onMouseLeave={() => {
																					setTooltipShow("");
																				}}
																			>
																				<IconLibrary.Info />
																			</Button>
																		</Tooltip>
																	)}
															</h4>
															<h5
																className={
																	classes.jobCreateTitle +
																	" " +
																	classes.grayDescription
																}
															>
																{values.salaryMin}{" "}
																{currencyList
																	.filter((item) => {
																		return (
																			item.currencyId === values.currencyId
																		);
																	})
																	.map((item) => {
																		return item.currencyCode;
																	})}{" "}
																- {values.salaryMax}{" "}
																{currencyList
																	.filter((item) => {
																		return (
																			item.currencyId === values.currencyId
																		);
																	})
																	.map((item) => {
																		return item.currencyCode;
																	})}
															</h5>
														</GridItem>

														<GridItem xs={12} sm={12} md={12}>
															<Slider
																value={[
																	Number(values.salaryMin),
																	Number(values.salaryMax),
																]}
																onChange={(event, value) => {
																	if (value) {
																		setFieldValue("salaryMin", value[0]);
																		setFieldValue("salaryMax", value[1]);
																	}
																}}
																step={100}
																min={0}
																max={
																	values.salaryMax
																		? Number(values.salaryMax) + 1000
																		: 1500
																}
																aria-labelledby="input-slider"
															/>
														</GridItem>
													</GridContainer>
												)}

												<Hidden mdUp>
													<GridContainer justifyContent="center">
														<GridItem>
															<h4 className={classes.jobCreateTitle}>
																Job Requirement
															</h4>
														</GridItem>
														<GridItem className={classes.gridHeight}>
															{/* <TextField
																value={
																	values.requirement ? values.requirement : ""
																}
																variant="outlined"
																fullWidth
																placeholder="Ex. Expert in design"
																onChange={(event) => {
																	setFieldValue(
																		"requirement",
																		event.target.value
																	);
																}}
																error={
																	touched.requirement &&
																	Boolean(errors.requirement)
																}
																label={
																	touched.requirement &&
																	Boolean(errors.requirement)
																		? errors.requirement
																		: ""
																}
																id="requirement"
																name="requirement"
																InputProps={{
																	multiline: true,
																	rows: 5,
																}}
															/> */}
															<ReactQuill
																ref={quillRefJReq}
																placeholder="Required"
																className={classes.quillEditorSingleRow}
																theme="snow"
																multiline={true}
																// contenteditable={true}
																modules={modules}
																// defaultValue={textValue}
																// onChange={setValue}
																// value={value}
																onChange={onQuillChangeJReq}
																id="quillEditorJReq"
															/>
															<div
																style={{ fontSize: "14px" }}
																id="quillCounterJReq"
															>
																(0/3000)
															</div>
															<div
																style={{ display: "none" }}
																id="counterJReq"
															></div>
														</GridItem>
														<GridItem>
															<h4 className={classes.jobCreateTitle}>
																Job Responsibility
															</h4>
														</GridItem>
														<GridItem className={classes.gridHeight}>
															{/* <TextField
																value={
																	values.responsibility
																		? values.responsibility
																		: ""
																}
																variant="outlined"
																fullWidth
																placeholder="Discipline"
																onChange={(event) => {
																	setFieldValue(
																		"responsibility",
																		event.target.value
																	);
																}}
																error={
																	touched.responsibility &&
																	Boolean(errors.responsibility)
																}
																label={
																	touched.responsibility &&
																	Boolean(errors.responsibility)
																		? errors.responsibility
																		: ""
																}
																id="responsibility"
																name="responsibility"
																InputProps={{
																	multiline: true,
																	rows: 5,
																}}
															/> */}
															<ReactQuill
																ref={quillRefJRes}
																placeholder="Required"
																className={classes.quillEditorSingleRow}
																theme="snow"
																multiline={true}
																// contenteditable={true}
																modules={modules}
																// defaultValue={textValue}
																// onChange={setValue}
																// value={value}
																onChange={onQuillChangeJRes}
																id="quillEditorJRes"
															/>
															<div
																style={{ fontSize: "14px" }}
																id="quillCounterJRes"
															>
																(0/3000)
															</div>
															<div
																style={{ display: "none" }}
																id="counterJRes"
															></div>
														</GridItem>
													</GridContainer>
												</Hidden>
											</CardBody>
										</Card>
									</GridItem>
									<Hidden smDown>
										<GridItem xs={12} sm={12} md={6}>
											<Card className={classes.cardFrame}>
												<CardBody>
													<GridContainer justifyContent="center">
														<GridItem>
															<h4 className={classes.jobCreateTitle}>
																Job Requirement
															</h4>
														</GridItem>
														<GridItem className={classes.gridHeight}>
															{/* <TextField
																value={
																	values.requirement ? values.requirement : ""
																}
																variant="outlined"
																fullWidth
																placeholder="Required"
																onChange={(event) => {
																	setFieldValue(
																		"requirement",
																		event.target.value
																	);
																}}
																error={
																	touched.requirement &&
																	Boolean(errors.requirement)
																}
																label={
																	touched.requirement &&
																	Boolean(errors.requirement)
																		? errors.requirement
																		: ""
																}
																id="requirement"
																name="requirement"
																InputProps={{
																	multiline: true,
																	rows: 5,
																}}
															/> */}
															<ReactQuill
																ref={quillRefJReq}
																placeholder="Required"
																className={classes.quillEditor}
																theme="snow"
																multiline={true}
																// contenteditable={true}
																modules={modules}
																// defaultValue={textValue}
																// onChange={setValue}
																// value={value}
																onChange={onQuillChangeJReq}
																id="quillEditorJReq"
															/>
															<div
																style={{ fontSize: "14px" }}
																id="quillCounterJReq"
															>
																(0/3000)
															</div>
															<div
																style={{ display: "none" }}
																id="counterJReq"
															></div>
														</GridItem>
														<GridItem>
															<h4 className={classes.jobCreateTitle}>
																Job Responsibility
															</h4>
														</GridItem>
														<GridItem className={classes.gridHeight}>
															{/* <TextField
																value={
																	values.responsibility
																		? values.responsibility
																		: ""
																}
																variant="outlined"
																fullWidth
																placeholder="Required"
																onChange={(event) => {
																	setFieldValue(
																		"responsibility",
																		event.target.value
																	);
																}}
																error={
																	touched.responsibility &&
																	Boolean(errors.responsibility)
																}
																label={
																	touched.responsibility &&
																	Boolean(errors.responsibility)
																		? errors.responsibility
																		: ""
																}
																id="responsibility"
																name="responsibility"
																InputProps={{
																	multiline: true,
																	rows: 5,
																}}
															/> */}
															<ReactQuill
																ref={quillRefJRes}
																placeholder="Required"
																className={classes.quillEditor}
																theme="snow"
																multiline={true}
																// contenteditable={true}
																modules={modules}
																// defaultValue={textValue}
																// onChange={setValue}
																// value={value}
																onChange={onQuillChangeJRes}
																id="quillEditorJRes"
															/>
															<div
																style={{ fontSize: "14px" }}
																id="quillCounterJRes"
															>
																(0/3000)
															</div>
															<div
																style={{ display: "none" }}
																id="counterJRes"
															></div>
														</GridItem>
													</GridContainer>
												</CardBody>
											</Card>
										</GridItem>
									</Hidden>
									{/* <GridItem xs={12} sm={12} md={6}>
										<Card className={classes.cardHeight}>
											<CardBody></CardBody>
										</Card>{" "}
									</GridItem> */}
								</GridContainer>

								<GridContainer>
									<GridItem xs={12} sm={12} md={12}>
										<Card className={classes.cardHeight}>
											<CardBody>
												<Typography className='interviewDiv'>
													<Typography className='interviewDivLeft'>
														<Typography className='interview_Question'>
															Pre-Interview Questions</Typography>
													</Typography>
													<Typography className='interviewDivRight'>
														<label className="labelCustom ideal_ans">Please choose the ideal answer</label>
														<Typography className='idealAnswer idealAnswerFirst'>
														</Typography>
													</Typography>
												</Typography>
												{questionsHiring.length > 0 && questionsHiring.map((message, index) => {
													return (<Typography className='interviewDiv'>
														<Typography className='interviewDivLeft'>
															<Typography className='interviewLeftInner'>
																<span className='numberSpan'>{index + 1}.</span>
																<input className='questionP' type="text" name={`${message.name}`} maxlength="1500" value={`${message.value}`} onChange={handleFirstQuestionChange} />
															</Typography>
														</Typography>
														<Typography className='interviewDivRight'>
															<Typography className='idealAnswer'>
																<button type="button" className={`${message.yesclass}`} value={`/affirm_${message.value}`} onClick={() => { hiringQuestionAnswer(`/affirm_${message.value}`, 10) }}>Yes</button>

																<button type="button" className={`${message.noclass}`} value={`/deny_${message.value}`} onClick={() => { hiringQuestionAnswer(`/deny_${message.value}`, 10) }}>No</button>
															</Typography>
														</Typography>
													</Typography>)
												})}

												<Typography className='addQuestionDiv'>
													<Typography className='idealAnswer'>
														<button type="button" onClick={addNewQuestions}>Add Question</button>
													</Typography>
												</Typography>
											</CardBody>
										</Card>
									</GridItem>
								</GridContainer>

								{/* <GridContainer justifyContent="center">
									<GridItem
										xs={12}
										sm={12}
										md={3}
										className={classes.updateButton}
									>
										<Button
											onClick={(e) => {
												values.draft = true;
												handleSubmit();
											}}
											disabled={!!isSubmitting}
											color="primary"
											fullWidth
										>
											Save as Draft
										</Button>
									</GridItem>
									<GridItem
										xs={12}
										sm={12}
										md={3}
										className={classes.updateButton}
									>
										<Button
											onClick={(e) => {
												values.draft = false;
												handleSubmit();
											}}
											disabled={!!isSubmitting}
											color="success"
											fullWidth
										>
											Publish
										</Button>
									</GridItem>
									<GridItem
										xs={12}
										sm={12}
										md={3}
										className={classes.updateButton}
									>
										<Button
											onClick={() => {
												history.push("/corporate-job-management-page");
											}}
											color="info"
											fullWidth
										>
											Cancel
										</Button>
									</GridItem>
								</GridContainer> */}
								{isSubmitting ? (
									// <LoadingModal />
									<Backdrop />
								) : null}
							</form>
						)}
					</Formik>
				</div>
			</div>
			<Footer />
		</div>
	);
}
