import React from "react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { useSelector, useDispatch } from "react-redux";

import SmallCardLoading from "../SmallCardLoading";
import { makeStyles } from "@material-ui/core/styles";
import defaultAvatar from "assets/img/candidate_avatar.jpeg";
import searchPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/SearchPage/searchPageStyle.js";

import { getCandidateSelectJobPosts } from "configurations/redux/jobManagementSlice.js";
import ResultJobModal from "views/Page/SearchJobPage/ResultJobModal.js";

const useStyles = makeStyles(searchPageStyle);

export default function JobCard({ item }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const auth = useSelector((state) => state.auth);
	const userStatus = useSelector((state) => state.auth.user);
	const searchResult = useSelector((state) => state.searchJob.searchResult);
	const requestRecordSize = useSelector(
		(state) => state.searchJob.requestRecordSize
	);
	const isLoading = useSelector((state) => state.searchJob.isLoading);
	const [jobViewModal, setJobViewModal] = React.useState(false);
	const [jobItem, setJobItem] = React.useState();
	const onClose = () => {
		setJobViewModal(false);
	};
	const CardComponent = () => {
		return (
			<Card
				key={item.jobPostId}
				className={classes.cardFrame + " " + classes.cardClick}
				onClick={() => {
					setJobItem(item);
					getJobPost(item);
				}}
			>
				<CardBody>
					<div className={classes.resultContainer}>
						<div className={classes.imgContainer}>
							<img src={item.profilePic ? item.profilePic : defaultAvatar} />
						</div>
						<div className={classes.descriptionContainer}>
							<div>
								<h4 className={classes.title}>{item.title}</h4>
								<h4 className={classes.description}>{item.companyName}</h4>
								<h4 className={classes.description}>{item.countryName}</h4>
								<h4 className={classes.description}>{item.industryName}</h4>
							</div>
						</div>
					</div>
				</CardBody>
			</Card>
		);
	};
	const getJobPost = async (item) => {
		const requestProfile = {
			acctId: auth.isPublic ? 0 : userStatus.acctId,
			accessToken: auth.isPublic
				? auth.publicToken
				: localStorage.getItem("token"),
			ipAddr: localStorage.getItem("ipAddr"), //await publicIp.v4(),
			jobPostId: item.jobPostId,
			item: null,
		};
		console.log(requestProfile);
		dispatch(getCandidateSelectJobPosts(requestProfile));
		setJobViewModal(true);
	};
	return (
		<div>
			{isLoading ? <SmallCardLoading /> : <CardComponent />}{" "}
			{jobViewModal ? (
				<ResultJobModal open={jobViewModal} onClose={onClose} item={jobItem} />
			) : null}{" "}
		</div>
	);
}
