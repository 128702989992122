import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import groupPageStyles from "assets/jss/material-kit-pro-react/views/pageStyle/GroupsPage";
import CustomButton from "components/CustomButtons/Button";
import { Link } from "react-router-dom";
import { encryptData } from "configurations/configurations";
import { useDispatch, useSelector } from "react-redux";
import GroupLoader from "./GroupLoader.js";
import { useModal } from "hooks";
import {
	getGroupById,
	joinUserGroup,
	getGroupUserByGroupId,
	leaveUserGroup,
	leaveUserGroupInPage,
} from "configurations/redux/groupsSlice";
import {
	userGroupRoles,
	userGroupStatus,
	groupPermissions,
} from "configurations/configurations";
import ConfirmModal from "./ConfirmModal.js";
import Chip from "@mui/material/Chip";
const useStyles = makeStyles(groupPageStyles);

const GroupCard = ({ group, isGroupJoined = false, joiningGroup }) => {
	const classes = useStyles();
	const encryptedGroupId = encryptData(group.groupId);
	const { isGroupsLoading, isGroupsByAccountLoading } = useSelector(
		(state) => state.groups
	);
	const user = useSelector((state) => state.auth.user);
	const leaveUserModalProps = useModal();
	const cancelRequestModalProps = useModal();

	const dispatch = useDispatch();

	const handleClick = () => {
		if (group.userRoleId === 2 || group.userRoleId === 3) {
			if (group.userStatusId === 3) {
				cancelRequestModalProps.toggle();
			} else {
				leaveUserModalProps.toggle();
			}
		} else if (group.userGroupId === null) {
			joinGroup();
		} else {
		}
	};
	function leaveGroup(closeModalOnSuccess) {
		const body = {
			accessToken: localStorage.getItem("token"),
			email: user.email,
			acctId: user.acctId,
			groupUserId: group.userGroupId,
			groupId: group.groupId,
		};
		console.log(body);
		dispatch(leaveUserGroupInPage(body)).then(() => {
			closeModalOnSuccess();
		});
	}
	const joinGroup = () => {
		const { ALLOW_ANYONE_TO_JOIN } = groupPermissions;
		const userStatusId =
			group.permissionId.indexOf(`${ALLOW_ANYONE_TO_JOIN.id}`) === -1
				? userGroupStatus.REQUESTED.id
				: userGroupStatus.ACTIVE.id;

		const body = {
			accessToken: localStorage.getItem("token"),
			email: user.email,
			groupId: group.groupId,
			acctId: user.acctId,
			userId: user.acctId,
			userStatusId,
			roleId: userGroupRoles.MEMBER.id,
		};
		dispatch(joinUserGroup(body));
	};
	const leaveUserModalContent = {
		title: "Leave group",
		text: "Are you sure you want to leave this group?",
		cancelText: "No",
		confirmText: "Yes",
	};
	const cancelRequestModalContent = {
		title: "Cancel Request",
		text: "Are you sure you want to cancel request to this group?",
		cancelText: "No",
		confirmText: "Yes",
	};
	const GroupStatus = () => {
		var string = "";
		if (group.userGroupId) {
			if (group.userRoleId === 1) {
				string = "Owner";
			} else if (group.userStatusId === 3) {
				string = "Cancel";
			} else {
				string = "Leave";
			}
		} else {
			string = "Join";
		}
		return string;
	};
	return (
		<React.Fragment>
			{isGroupsLoading || isGroupsByAccountLoading ? (
				<GroupLoader />
			) : (
				<Link
					className={classes.cardContainer}
					to={`/manage-group/${encryptedGroupId}`}
				>
					<div className={classes.cardImage}>
						<img src={group?.profilePic} />
					</div>
					<div className={classes.cardContent}>
						<div className={classes.cardInnerContent}>
							{group.visibleId && (
								<div
									style={{
										display: "flex",
										width: "100%",
										justifyContent: "flex-end",
									}}
								>
									<Chip
										label={
											<b>{group.visibleId === 1 ? "Public" : "Private"}</b>
										}
									/>
								</div>
							)}

							<Typography className={classes.cardContentHeader}>
								{group?.groupName}
							</Typography>
							<div className={classes.subContent}>
								<Typography className={classes.cardContentDetails}>
									{group?.groupDescription}
								</Typography>
							</div>
						</div>
						<CustomButton
							className={classes.cardButton}
							onClick={(e) => {
								e.preventDefault();
								handleClick();
							}}
							/* disabled={joiningGroup || Boolean(isGroupJoined)} */
							disabled={group.userRoleId === 1 ? true : false}
						>
							<Typography className={classes.cardButtonText}>
								<GroupStatus />
							</Typography>
						</CustomButton>
					</div>
				</Link>
			)}
			<ConfirmModal
				{...{ ...leaveUserModalProps }}
				bodyContent={leaveUserModalContent}
				handleSubmit={() => leaveGroup(leaveUserModalProps.toggle)}
			/>
			<ConfirmModal
				{...{ ...cancelRequestModalProps }}
				bodyContent={cancelRequestModalContent}
				handleSubmit={() => leaveGroup(cancelRequestModalProps.toggle)}
			/>
		</React.Fragment>
	);
};

export default GroupCard;
