import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { departmentViewRoadmap,viewRoadmapRoleByRoleId } from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";
import ImageMapper from "react-image-mapper";
import Department_Market_Research from "assets/img/Career_Roadmap/Marketing_Communication/Department_Market_Research_1.jpg";
import RoleInfoModal from "../RoleInfoModal.js";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentMarketingResearch() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Market_Research;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "mr",
				shape: "poly",
				coords: [
					72,351,157,351,163,352,170,355,176,362,177,365,178,371,178,403,178,410,175,417,170,423,165,426,157,427,72,427,62,425,55,420,51,414,49,408,50,372,51,365,53,360,58,355,62,353,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "12",
				shape: "poly",
				coords: [
					205,353,339,353,348,355,355,359,359,366,359,374,359,411,358,421,353,426,342,431,204,430,194,428,189,421,187,409,187,372,189,364,193,357,197,354,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "13",
				shape: "poly",
				coords: [
					433,351,580,351,588,353,594,357,597,363,597,369,597,412,594,421,591,426,586,428,578,429,433,429,426,428,419,422,416,418,415,372,416,365,418,358,423,354,427,352,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "14",
				shape: "poly",
				coords: [
					678,353,854,353,861,356,866,360,868,364,870,371,870,411,868,419,865,426,858,430,845,430,682,430,672,429,666,423,663,418,663,372,664,362,671,356,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "15",
				shape: "poly",
				coords: [
					953,355,1106,354,1114,355,1119,358,1124,361,1127,368,1126,374,1126,415,1125,422,1120,428,1111,432,952,432,944,429,939,426,937,421,937,371,939,363,943,358,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "16",
				shape: "poly",
				coords: [
					1207,356,1312,356,1319,359,1325,366,1326,416,1323,425,1318,430,1308,433,1208,433,1199,431,1193,424,1191,416,1191,372,1192,364,1197,359,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "6",
				shape: "poly",
				coords: [
					1408,356,1534,355,1545,361,1551,369,1550,416,1548,426,1542,430,1530,433,1407,433,1397,431,1394,427,1392,418,1391,374,1392,366,1397,360,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "7",
				shape: "poly",
				coords: [
					1665,354,1836,354,1844,355,1850,360,1855,368,1857,376,1855,416,1852,423,1848,429,1836,431,1667,432,1656,429,1650,424,1647,417,1648,374,1650,363,1655,357,
				],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "mr") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo)
			dispatch(viewRoadmapRoleByRoleId(requestInfo));
			
			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// console.log(coords.x + "," + coords.y + ",");
		// navigator.clipboard.writeText(coords.x + "," + coords.y + ",");
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
