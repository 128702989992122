import { createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import {
	instanceUrl,
	header,
	emptyCache,
} from "configurations/configurations.js";

const messageConversationMediaSlice = createSlice({
	name: "messageConversationMedia",
	initialState: {
		searchCount: 0,
		searchResult: [],
		searchParams: [],
		lastRecordSize: 0,
		requestRecordSize: 0,
		isLoading: true,
		isEnd: false,
		isSearching: false,
		isUpdated: true,
	},
	reducers: {
		CONVERSATION_MEDIA_LOADING: (state, action) => {
			state.isLoading = true;
		},
		CONVERSATION_MEDIA_LOADING: (state, action) => {
			state.isLoading = true;
		},
		CONVERSATION_MEDIA_SEARCH_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.searchResult = [
				...state.searchResult,
				...action.payload.searchResult,
			];
			state.searchParams = action.payload.searchParams;
			state.lastRecordSize =
				state.lastRecordSize + action.payload.requestRecordSize;
			state.requestRecordSize = action.payload.requestRecordSize;
			state.isLoading = false;
		},
		CONVERSATION_MEDIA_SEARCH_END: (state, action) => {
			state.isEnd = true;
		},
		DEFAULT_CONVERSATION_MEDIA_SEARCH: (state) => {
			state.searchResult = [];
			state.searchParams = [];
			state.lastRecordSize = 0;
			state.requestRecordSize = 0;
			state.isEnd = false;
			state.isSearching = true;
		},
		CONVERSATION_MEDIA_SEARCH_FAIL: (state) => {
			state.isUpdated = false;
		},
		CONVERSATION_MEDIA_ADD_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.isUpdated = true;
			state.isLoading = false;
		},
		CONVERSATION_MEDIA_CHECK_UNREAD: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.searchCount = action.payload.searchCount;
			state.isLoading = false;
		},
		CONVERSATION_MEDIA_CHECK_UNREAD_END: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.searchCount = action.payload.searchCount;
			state.isUpdated = false;
		},
		CONVERSATION_MEDIA_CHECK_UNREAD_FAIL: (state, action) => {
			state.searchCount = 0;
			state.isUpdated = false;
		},
		CONVERSATION_MEDIA_UPDATE_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.isUpdated = true;
			state.isLoading = false;
		},
		CONVERSATION_MEDIA_DELETE_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.isSearching = false;
			state.searchResult = action.payload.searchResult;
		},
	},
});

const {
	CONVERSATION_MEDIA_LOADING,
	CONVERSATION_MEDIA_SEARCH_SUCCESS,
	CONVERSATION_MEDIA_SEARCH_END,
	DEFAULT_CONVERSATION_MEDIA_SEARCH,
	CONVERSATION_MEDIA_SEARCH_FAIL,
	CONVERSATION_MEDIA_ADD_SUCCESS,
	CONVERSATION_MEDIA_UPDATE_SUCCESS,
	CONVERSATION_MEDIA_DELETE_SUCCESS,
} = messageConversationMediaSlice.actions;

export const messageConversationMediaInfo = ({
	acctId,
	accessToken,
	email,
	lastRecordSize,
	requestRecordSize,
	conversationId,
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		email,
		lastRecordSize,
		requestRecordSize,
		conversationId,
	};
	dispatch(CONVERSATION_MEDIA_LOADING());
	if (lastRecordSize === 0) {
		dispatch(DEFAULT_CONVERSATION_MEDIA_SEARCH());
	}

	await axios({
		method: "GET",
		headers: header,
		url: `${instanceUrl}/api/mm/search-conversation-media`,
		params: body,
	})
		.then((res) => {
			if (res.status === 201) {
				const response = {
					searchResult: res.data.payload.conversationMediaList,
					lastRecordSize,
					requestRecordSize: res.data.payload.conversationMediaList.length,
					token: res.data.token,
				};
				dispatch(CONVERSATION_MEDIA_SEARCH_SUCCESS(response));
				if (requestRecordSize > res.data.payload.conversationMediaList.length) {
					dispatch(CONVERSATION_MEDIA_SEARCH_END());
				}
			} else if (res.status === 202) {
				dispatch(CONVERSATION_MEDIA_SEARCH_END());
			} else {
				dispatch(CONVERSATION_MEDIA_SEARCH_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const createConversationMedia = ({
	acctId,
	accessToken,
	email,
	mediaId,
	conversationId,
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		email,
		mediaId,
		conversationId,
	};

	await axios({
		method: "POST",
		headers: header,
		url: `${instanceUrl}/api/mm/create-conversation-media`,
		data: body,
	})
		.then((res) => {
			console.log(res);

			const response = {
				token: res.data.token,
			};
			if (res.status === 201) {
				dispatch(CONVERSATION_MEDIA_ADD_SUCCESS(response));
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const updateConversationMedia = ({
	acctId,
	accessToken,
	email,
	mediaId,
	conversationId,
	conversationMediaStatus,
}) => async (dispatch) => {
	dispatch(CONVERSATION_MEDIA_LOADING());
	const body = {
		acctId,
		accessToken,
		email,
		mediaId,
		conversationId,
		conversationMediaStatus,
	};
	console.log(body);
	await axios({
		method: "PUT",
		headers: header,
		url: `${instanceUrl}/api/mm/update-conversation-media-status`,
		data: body,
	})
		.then((res) => {
			console.log(res);
			if (res.status === 201) {
				const response = {
					mediaId,
					token: res.data.token,
				};
				dispatch(CONVERSATION_MEDIA_UPDATE_SUCCESS(response));
			} else {
				dispatch(CONVERSATION_MEDIA_SEARCH_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export const deleteConversation = ({
	acctId,
	accessToken,
	email,
	mediaId,
	conversationId,
	searchResult,
}) => async (dispatch) => {
	const body = {
		acctId,
		accessToken,
		email,
		mediaId,
		conversationId,
		searchResult,
	};
	await axios({
		method: "DELETE",
		headers: header,
		url: `${instanceUrl}/api/mm/delete-conversation-media`,
		data: body,
	})
		.then((res) => {
			if (res.status === 201) {
				searchResult = searchResult.filter((item) => item.mediaId != mediaId);
				const response = {
					searchResult,
					mediaId,
					token: res.data.token,
				};
				dispatch(CONVERSATION_MEDIA_DELETE_SUCCESS(response));
			} else {
				dispatch(CONVERSATION_MEDIA_SEARCH_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export default messageConversationMediaSlice.reducer;
