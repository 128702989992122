import React from "react";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import loginPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/LoginPage/loginPageStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Logo from "assets/images/logo.png";
import Limg from "assets/images/limg.png";
import { Typography } from '@material-ui/core';
const useStyles = makeStyles(loginPageStyle);

const TakeTest = ({ match }) => {


	return (
		<>
			<div>
				<div
					id="pageHeader"
					style={{
						backgroundSize: "cover",
						backgroundPosition: "top center",
					}}
				>
					<div className='takeTestDiv'>
					    <img className='takeTestlogoImg' src={Logo} alt='logo' />
						<GridContainer
							justifyContent="center"
							alignContent="center"
							alignItems="center"
						>
							<GridItem xs={12} sm={12} md={6}>
								<div className='takeTestDivLeft'>
								  <div className='discoverTitle'>Discover The Ideal <br />Career For You</div>
								  <p>Take this quiz to discover the ideal career path and <br/>jobs for your personality type.</p>
								  <Typography className='retakeResultBtns'>
									<Link className='takeTest takethetest'>Take The Test</Link>
								  </Typography>
								</div>
							</GridItem>
							<GridItem xs={12} sm={12} md={6}>
							   <div className='takeTestDivLeft rightTest'>
							    <img className='limg' src={Limg} alt='limg' />
							   </div>
							</GridItem>
						</GridContainer>
					</div>
				</div>
			</div>
		</>
	);
};

export default TakeTest;
