var regular = 400;
var medium = 500;
var semiBold = 600;
var bold = 700;
var Roboto = "'Roboto', 'Helvetica', 'Arial', sans-serif";
var Poppins = "'Poppins', 'Helvetica', 'Arial', sans-serif";

const profileViewActivityPollCardStyles = (theme) => ({
	modalPositionCenter: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
	},

	main: {
		boxShadow: "none",
		borderRadius: "15px!important",
		padding: "20px 20px 10px",
		margin: "0 0 15px",

		"& h4": {
			fontSize: "18px",
			fontWeight: "600",
			color: "#4A4A4A",
			margin: "0",
		},

		"& .MuiCardContent-root": {
			paddingBottom: "0",
		},
	},

	socialFeedCardContent: {
		padding: 0,
	},

	postHeader: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		margin: "0 0 20px",

		"& > button": {
			backgroundColor: "transparent",
			border: "none",
			cursor: "pointer",
		},
	},

	postAuthorDetails: {
		display: "flex",
		alignItems: "center",
	},

	postAuthorProfilePic: {
		overflow: "hidden",
		borderRadius: "50%",
		marginRight: "15px",
		width: "53px",
		maxHeight: "53px",

		"& > img": {
			width: "100%",
		},
	},

	postAuthorName: {
		color: "#171725",
		fontSize: "14px",
		fontWeight: 700,

		"& > span": {
			marginLeft: "20px",
			border: "solid 1px #20F0A1",
			borderRadius: "10px",
			padding: "7px 20px 6px",
			color: "#20F0A1",
			fontSize: "15px",
			fontWeight: 500,
			textTransform: "capitalize",
		},
	},

	postCreationDate: {
		fontSize: "12px",
		color: "#92929D",
		fontWeight: 500,
	},

	postText: {
		overflowWrap: "anywhere",
		color: "#44444F",
		fontSize: "14px",
		margin: "0 0 30px",

		"& > p": {
			whiteSpace: "break-spaces",
			wordWrap: "break-word",
		},
	},
	pollSection: {
		border: "1px solid #F2F2F2",
		borderRadius: "15px",
		padding: "10px 15px 0",
		[theme.breakpoints.down("xs")]: {
			padding: "5px 7px 0",
		},
	},
	QuestionandOptions: {
		// paddingBottom: "1rem",
	},
	Question: {
		display: "flex",
		fontSize: "1.125rem",
		fontWeight: "500",
		fontStyle: "Poppins",
		margin: "1rem 0rem",
	},

	Options: {
		display: "flex",
		justifyContent: "center",
		width: "75%",
		height: "auto",
		minHeight: "2.625rem",
		borderRadius: "1.5rem",
		backgroundColor: "#EFF3FE",
		border: "1px solid #6F52ED",
		color: "#6F52ED",
		fontSize: "1rem",
		fontWeight: "500",
		fontStyle: "Poppins",
		margin: "1rem 12.5%",
		padding: ".5rem",
		cursor: "pointer",
		textAlign: "center",
		[theme.breakpoints.down("xs")]: {
			fontSize: "13px",
			margin: "10px 12.5%",
			padding: "5px 10px",
			minHeight: "0",
		},
	},
	optionDesc: {
		width: "90%",
		overflowWrap: "anywhere",
	},
	Optionselected: {
		display: "flex",
		justifyContent: "space-between",
		// width: "100%",
		height: "auto",
		minHeight: "2.625rem",
		borderRadius: ".5rem",
		backgroundColor: "#EFF3FE",
		// border: "1px solid #6F52ED",
		color: "#000000",
		fontSize: "1rem",
		fontWeight: "500",
		fontStyle: "Poppins",
		margin: "1rem",
		padding: ".5rem",
		[theme.breakpoints.down("xs")]: {
			fontSize: "13px",
			margin: "10px",
			padding: "5px 10px",
			minHeight: "0",
		},
	},
	Optionnotselected: {
		display: "flex",
		justifyContent: "space-between",
		// width: "100%",
		height: "auto",
		minHeight: "2.625rem",
		borderRadius: ".5rem",
		backgroundColor: "#FAFAFA",
		// border: "1px solid #6F52ED",
		color: "#000000",
		fontSize: "1rem",
		fontWeight: "500",
		fontStyle: "Poppins",
		margin: "1rem",
		padding: ".5rem",
		[theme.breakpoints.down("xs")]: {
			fontSize: "13px",
			margin: "10px",
			padding: "5px 10px",
			minHeight: "0",
		},
	},
	percentage: {
		// marginLeft: "85%",
	},
	pollUndo: {
		color: "#6F52ED",
		cursor: "pointer",
	},
	pollClosed: {
		color: "#92929D",
	},
	pollDetails: {
		margin: "0rem 0rem 1rem 12.5%",
		[theme.breakpoints.down("xs")]: {
			fontSize: "13px",
		},
	},
	pollVote: {
		cursor: "pointer",
		color: "#6F52ED",
	},
	daysLeft: {
		fontSize: ".875rem",
		fontWeight: "400",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		marginLeft: ".5rem",
		marginRight: ".5rem",
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
		},
	},
	pollUserListText: {
		"& > span": {
			color: "#000",
			fontWeight: "500",
		},
	},
	pollTabs: {
		// "& > div > span": {
		// 	backgroundColor: "#6F52ED",
		// },
	},
	pollUserListItem: {
		borderBottom: "1px solid",
		borderColor: "rgba(0, 0, 0, 0.12)!important",
	},
	pollGallery: {
		width: "100%",
	},
	pollTitle: {
		fontSize: "1.25rem",
		fontWeight: "500",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		paddingLeft: "15px",
		[theme.breakpoints.down("xs")]: {
			fontSize: "16px",
		},
		overflowWrap: "anywhere",
	},
	feedTitle: {
		color: "#44444F",
		fontSize: "14px",
		margin: "0 0 30px",
	},

	feedActions: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		borderTop: "1px solid #F1F1F5",
		margin: "15px 0 0",
		padding: "10px 0",
		[theme.breakpoints.down("xs")]: {
			margin: "7px 0 0",
			padding: "0",
		},

		"& > button": {
			color: "#44444F",
			fontSize: "12px",
			fontWeight: 400,
		},

		"& .MuiButton-label": {
			textTransform: "capitalize",

			"& img": {
				marginRight: "5px",
				width: "22px",
			},
		},
	},

	postCreateCommentSection: {
		display: "flex",
		alignItems: "center",
		borderTop: "1px solid #F1F1F5",
		padding: "20px 0",
		width: "100%",

		"& .MuiTypography-body1, .MuiInputBase-root": {
			width: "100%",
		},

		"& .MuiInput-underline:before, .Mui-focused:after": {
			display: "none",
		},
	},

	postCommenterProfilePic: {
		flex: "0 0 8%",
		alignSelf: "first baseline",
		maxWidth: "45px",
		maxHeight: "45px",
		borderRadius: "50%",
		overflow: "hidden",
		margin: "0 15px 0 0",

		"& > img": {
			width: "100%",
		},
	},

	commentInputWrap: {
		position: "relative",
		display: "flex",
	},

	input: {
		flexBasis: "calc(93% - 15px)",
		border: "1px solid #F1F1F5",
		borderRadius: "15px",
		backgroundColor: "#FAFAFB",
		maxHeight: "10rem",
		padding: "15px 156px 15px 15px",

		"& textarea": {
			fontSize: 14,
			fontWeight: 400,
			fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
			background: "none",
			border: "none",
			padding: "14px",

			"&::placeholder": {
				color: "#afafb0",
			},
		},

		"& > div:nth-child(1)": {
			lineHeight: "1.3",

			"& strong": {
				position: "relative",
				zIndex: 1,
				top: 0,
				left: 1,
				backgroundColor: "#fff",
				color: "#7474a8",
			},

			"& .textarea": {
				zIndex: 0,
			},
		},

		"& > div:nth-child(2)": {
			boxShadow:
				"0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
			marginTop: "20px !important",
			borderRadius: "10px",
			border: "1px solid rgba(0,0,0,0.15)",
			backgroundColor: "white",
			padding: 5,

			"& li": {
				display: "flex",
				alignItems: "center",
				margin: 0,
				borderRadius: "5px",
				padding: "5px 10px 5px",
				color: "#44444F",
				cursor: "pointer",

				"&:hover": {
					backgroundColor: "#d7d7d7",
				},

				"&focused": {
					backgroundColor: "#cee4e5",
				},

				"& > img": {
					borderRadius: "50%",
					width: "20px",
				},

				"& > p": {
					margin: "0 0 0 7px",
				},
			},
		},
	},

	clapIcon: {
		"&:hover": {
			transform: "scale(1.5)",
		},
	},
	commentActionBtns: {
		position: "absolute",
		top: "3px",
		right: 0,
		width: "90px!important",

		"& > button": {
			width: "45px",
			height: "45px",
		},
	},

	postCommentsMain: {
		marginTop: "10px",
	},

	recentCommentsButton: {
		marginBottom: "30px",

		"& > button": {
			border: "none",
			backgroundColor: "transparent",
			color: "#44444F",
			fontSize: "14px",
			fontWeight: 600,
			fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
			cursor: "",
		},
	},

	moreComments: {
		marginTop: "10px",
		color: "#4A4A4A",
		textAlign: "center",
		fontSize: "16px",
		fontWeight: 600,
		cursor: "pointer",

		"& > button": {
			border: "none",
			backgroundColor: "transparent",
			padding: "5px 15px",
		},
	},

	// #region popover styles

	popoverListItem: {
		display: "flex",
		alignItems: "center",
		borderRadius: "5px",
		padding: "5px 10px 5px 5px",
		minWidth: "250px",
		cursor: "pointer",

		"&:hover": {
			backgroundColor: "#e3e3e3",
		},
	},
	popoverListItemIcon: {
		display: "flex",
		justifyContent: "center",
		marginRight: "15px",
		width: "24px",
		textAlign: "center",
	},
	popoverListItemText: {},
	popoverListItemTitle: {
		color: "#44444F",
		fontSize: "14px",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	popoverListItemDescription: {
		color: "#92929D",
		fontSize: "12px",
	},
	clapIcon: {
		"&:hover": {
			transform: "scale(1.5)",
		},
	},

	// #endregion
});

export default profileViewActivityPollCardStyles;
