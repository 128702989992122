import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
	instanceUrl,
	header,
	emptyCache,
} from "configurations/configurations.js";

const deleteUserAttachmentSlice = createSlice({
	name: "deleteUserAttachment",
	initialState: {
		isLoading: false,
		isEnd: false,
		isSearching: false,
		isUpdated: true,
	},
	reducers: {
		DUA_LOADING: (state, action) => {
			state.isLoading = true;
		},
		DUA_SUCCESS: (state, action) => {
			localStorage.setItem("token", action.payload.token);
			state.isLoading = false;
			state.isUpdated = true;
		},
		DUA_END: (state, action) => {
			state.isEnd = true;
		},
		DUA_FAIL: (state) => {
			state.isUpdated = false;
		},
	},
});

const {
	DUA_LOADING,
	DUA_SUCCESS,
	DUA_END,
	DUA_FAIL,
} = deleteUserAttachmentSlice.actions;

export const deleteUserAttachmentInfo = ({
	email,
	acctId,
	attachmentId,
	accessToken,
}) => async (dispatch) => {
	const body = {
		email,
		acctId,
		attachmentId,
		accessToken,
	};

	dispatch(DUA_LOADING());
	await axios({
		method: "PUT",
		headers: header,
		url: `${instanceUrl}/api/um/delete-user-attachment`,
		params: body,
	})
		.then((res) => {
			if (res.status === 201) {
				const response = {
					token: res.data.token,
				};
				console.log(res.data.payload);
				dispatch(DUA_SUCCESS(response));
			} else if (res.status === 202) {
				dispatch(DUA_END());
			} else {
				dispatch(DUA_FAIL());
			}
		})
		.catch((err) => {
			if (err.message.includes("503")) {
				emptyCache();
			}
			console.log(err);
		});
};

export default deleteUserAttachmentSlice.reducer;
