import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

// core components
import Button from "components/CustomButtons/Button.js";

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import Close from "@material-ui/icons/Close";

import CoverImageCrop from "../../../Component/ImageCrop/CoverImageCrop.js";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js";
import IconLibrary from "views/Component/IconLibrary.js";
import CoverPictureTab from "./CoverPictureTab.js";

const useStyles = makeStyles(profilePageStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

const CoverPictureEditModal = ({
	open,
	onClose,
	backgroundPic,
	backgroundPicFileName,
	backgroundTemplateFlag,
	user,
}) => {
	const classes = useStyles();
	const isUploaded = useSelector((state) => state.user.isUploaded);
	const isUploadedCorp = useSelector((state) => state.corpUser.isUploaded);
	const bizId = useSelector((state) => state.business.bizId);

	React.useEffect(() => {
		console.log(isUploaded);
		if (isUploaded) onClose();
	}, [isUploaded]);

	React.useEffect(() => {
		console.log(isUploadedCorp);
		if (isUploadedCorp) onClose();
	}, [isUploadedCorp]);

	return (
		<React.Fragment>
			<Dialog
				classes={{
					root: classes.modalRoot,
					paper: classes.modalProfilePicEdit + " " + classes.modal,
				}}
				open={open}
				// TransitionComponent={Transition}
				// keepMounted
				// disableBackdropClick
				onClose={onClose}
				aria-labelledby="classic-modal-slide-title"
				aria-describedby="classic-modal-slide-description"
			>
				<DialogTitle
					id="classic-modal-slide-title"
					disableTypography
					className={classes.modalTitleEdit}
				>
					<Button
						simple
						className={classes.modalCloseButton}
						key="close"
						aria-label="Close"
						onClick={onClose}
					>
						<IconLibrary.Close />
					</Button>
					<h3 className={classes.title + " " + classes.titleAlign}>
						Edit your cover picture
					</h3>
				</DialogTitle>
				<DialogContent
					id="classic-modal-slide-description"
					className={classes.modalBody}
				>
					<div className={classes.tabHeader}>
						<CoverPictureTab
							open={open}
							backgroundPic={backgroundPic}
							backgroundPicFileName={backgroundPicFileName}
							backgroundTemplateFlag={backgroundTemplateFlag}
							user={user}
							bizId={bizId}
						/>
					</div>
				</DialogContent>
				<div className={classes.modalFooter}></div>
			</Dialog>
		</React.Fragment>
	);
};
export default CoverPictureEditModal;
