import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";

import {
	instanceUrl,
	countMonthYear,
	getMonthData,
} from "configurations/configurations.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Tooltip from "@material-ui/core/Tooltip";
import Divider from "@material-ui/core/Divider";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Primary from "components/Typography/Primary.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import EditIcon from "@material-ui/icons/CreateOutlined";
import SchoolIcon from "@material-ui/icons/School";
import ClearIcon from "@material-ui/icons/DeleteOutlineOutlined";
import MoreIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js";
import DeleteEducationModal from "views/Page/ProfilePage/ProfileSection/DeleteEducationModal.js";
import EducationEditModal from "views/Page/ProfilePage/ProfileSection/EducationEditModal.js";
import { activateEditType } from "configurations/redux/editProfileModalSlice";

import IconLibrary from "views/Component/IconLibrary.js";
const useStyles = makeStyles(profilePageStyle);
export default function EducationCardSection() {
	const classes = useStyles();
	const monthDataList = getMonthData();
	const educationRecords = useSelector((state) => state.user.educationRecords);
	const isViewed = useSelector((state) => state.user.isViewed);
	const extension = useSelector((state) => state.editProfileModal.extension);

	const [studyFieldList, setStudyFieldList] = React.useState([]);
	const [countryList, setCountryList] = React.useState([]);
	const [educationLevelList, setEducationLevelList] = React.useState([]);
	const [deleteEducationModal, setDeleteEducationModal] = React.useState(false);
	const [udpateEducationModal, setUpdateEducationModal] = React.useState(false);
	const [recordId, setRecordId] = React.useState();
	const dispatch = useDispatch();
	const closeEducationDeleteModal = () => {
		setDeleteEducationModal(false);
		setRecordId();
	};
	const closeUpdateEducationModal = () => {
		setUpdateEducationModal(false);
		setRecordId();
	};
	const privacySetting = useSelector((state) => state.user.privacySetting);

	React.useEffect(() => {
		let mounted = true;
		let CancelToken = axios.CancelToken;
		const source = CancelToken.source();
		const fetchCountryList = async () => {
			try {
				const res = await axios.get(`${instanceUrl}/api/md/get-all-country`, {
					cancelToken: source.token,
				});
				setCountryList(res.data.payload);
			} catch (error) {
				if (axios.isCancel(error)) {
					//console.log("unmount caused");
				} else {
					throw error;
				}
			}
		};
		const fetchStudyFieldList = async () => {
			try {
				const res = await axios.get(
					`${instanceUrl}/api/md/get-all-study-field`,
					{
						cancelToken: source.token,
					}
				);
				setStudyFieldList(res.data.payload);
			} catch (error) {
				if (axios.isCancel(error)) {
					//console.log("unmount caused");
				} else {
					throw error;
				}
			}
		};
		const fetchEducationLevelList = async () => {
			try {
				const res = await axios.get(
					`${instanceUrl}/api/md/get-all-education-level`,
					{
						cancelToken: source.token,
					}
				);
				setEducationLevelList(res.data.payload);
			} catch (error) {
				if (axios.isCancel(error)) {
					//console.log("unmount caused");
				} else {
					throw error;
				}
			}
		};
		if (mounted) {
			fetchStudyFieldList();
			fetchCountryList();
			fetchEducationLevelList();
		}
		return () => {
			mounted = false;
			source.cancel();
		};
	}, []);

	const NoEducationList = () => {
		if (isViewed) {
			return (
				<div>
					<div>
						<Card className={classes.cardBorder}>
							<CardBody>
								<h5>
									<b>No Education List</b>
								</h5>
							</CardBody>
						</Card>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<Card className={classes.cardBorder}>
						<CardBody>
							<h5>
								<b>
									No information filled yet. Please update your education
									records
								</b>
							</h5>
						</CardBody>
					</Card>
				</div>
			);
		}
	};
	const showProfile =
		privacySetting &&
		privacySetting
			.filter((item) => {
				return item.settingName === "showProfile";
			})
			.map((item) => {
				return item.settingValue === 1;
			})[0];
	const showEdu =
		privacySetting &&
		privacySetting
			.filter((item) => {
				return item.settingName === "showEdu";
			})
			.map((item) => {
				return item.settingValue === 1;
			})[0];

	const educationList = educationRecords.map((educationData) => {
		/* 	console.log(countMonthYear(educationData.startDate, educationData.endDate)); */
		return (
			<div key={educationData.eduRecId}>
				<Card className={classes.cardInfo}>
					<CardBody>
						{isViewed ? (
							educationData.isVisible === 1 ? (
								<GridContainer
									justifyContent="space-between"
									alignContent="center"
									alignItems="center"
								>
									<GridItem
										xs={9}
										sm={10}
										md={10}
										className={classes.cardPadding}
									>
										<div className={classes.cardDetail}>
											<h4 className={classes.titleDescription}>
												{educationData.school}
											</h4>
											<div className={classes.descriptionContainer}>
												<h5 className={classes.smallDescription}>
													{educationLevelList
														.filter((item) => {
															return item.eduLvlId === educationData.eduLvlId;
														})
														.map((item) => {
															return item.eduLvlName;
														})}
													{` of `}

													{educationData.courseName}
												</h5>
												<h5 className={classes.mediumDescription}>
													{educationData.studyField}
												</h5>
											</div>

											<div className={classes.descriptionContainer}>
												<span
													className={
														classes.smallDescription +
														" " +
														classes.grayFontCard
													}
												>
													<b>
														{monthDataList
															.filter(
																(month) =>
																	month.monthId ===
																	educationData.startDate.substring(5, 7)
															)
															.map((month) => month.monthName)}{" "}
														{educationData.startDate.substring(0, 4)}
														{" - "}
														{educationData.currentInProgressFlag !== 1 &&
															monthDataList
																.filter(
																	(month) =>
																		month.monthId ===
																		educationData.endDate.substring(5, 7)
																)
																.map((month) => month.monthName)}{" "}
														{educationData.currentInProgressFlag === 1
															? `Present`
															: educationData.endDate.substring(0, 4)}
													</b>
												</span>

												<span
													className={
														classes.smallDescription +
														" " +
														classes.mobileSpan +
														" " +
														classes.grayFontCard
													}
												>
													{" "}
													<b>
														{countMonthYear(
															educationData.startDate,
															educationData.endDate
														) &&
															" • " +
															countMonthYear(
																educationData.startDate,
																educationData.endDate
															)}
													</b>
												</span>

												<p
													className={
														classes.smallDescription +
														" " +
														classes.grayFontCard
													}
												>
													<b>
														{countryList
															.filter((item) => {
																return (
																	item.countryId === educationData.countryId
																);
															})
															.map((item) => {
																return item.countryName;
															})}
													</b>
												</p>
											</div>

											{/* <span className={classes.divider}>{"  |  "}</span> */}
										</div>
									</GridItem>

									<GridItem xs={3} sm={2} md={2} className={classes.menuIcon}>
										{!isViewed && (
											<React.Fragment>
												<Tooltip
													id="tooltip-top"
													title={
														<p className={classes.tooltipDescription}>
															Delete Education
														</p>
													}
													placement="top"
													classes={{ tooltip: classes.tooltip }}
												>
													<Button
														onClick={() => {
															/* setRecordId(educationData.eduRecId); */
															setDeleteEducationModal(true);
															const response = {
																/* type: "EDIT_PROFILE",
														tabType: "EDUCATION",
														isTabShow: false, */

																extension: {
																	recordId: educationData.eduRecId,
																},
															};
															/* setRecordId(-1); */

															dispatch(activateEditType(response));
														}}
														justIcon
														className={
															classes.buttonIconMargin +
															" " +
															classes.grayButton
														}
														color="transparent"
													>
														<IconLibrary.Trash />
													</Button>
												</Tooltip>
												<Tooltip
													title={
														<p className={classes.tooltipDescription}>
															Edit Education
														</p>
													}
													placement="top"
													classes={{ tooltip: classes.tooltip }}
												>
													<Button
														justIcon
														style={{ boxShadow: "none" }}
														color="transparent"
														className={classes.buttonIconMargin}
														onClick={() => {
															/* setRecordId(educationData.eduRecId); */
															setUpdateEducationModal(true);
															const response = {
																type: "EDIT_PROFILE",
																tabType: "EDUCATION",
																isTabShow: true,

																extension: {
																	recordId: educationData.eduRecId,
																},
															};
															/* setRecordId(-1); */

															dispatch(activateEditType(response));
														}}
													>
														<IconLibrary.Pen />
													</Button>
												</Tooltip>
											</React.Fragment>
										)}
									</GridItem>
									<GridItem className={classes.cardPadding}>
										<div>
											{educationData.achievement ? (
												<div className={classes.description}>
													<Divider />
													<p className={classes.description}>
														{educationData.achievement}
													</p>
												</div>
											) : null}
										</div>

										<div>
											{educationData.description ? (
												<div className={classes.description}>
													{educationData.achievement ? null : <Divider />}
													<p className={classes.description}>
														{educationData.description}
													</p>
												</div>
											) : null}
										</div>

										<div>
											{educationData.extraCurricular ? (
												<div className={classes.description}>
													{educationData.achievement ? null : educationData.description ? null : (
														<Divider />
													)}
													<p className={classes.description}>
														{educationData.extraCurricular}
													</p>
												</div>
											) : null}
										</div>
									</GridItem>
								</GridContainer>
							) : (
								<Card className={classes.cardBorder}>
									<CardBody>
										<h5>
											<b>This Education Record is private</b>
										</h5>
									</CardBody>
								</Card>
							)
						) : (
							<GridContainer
								justifyContent="space-between"
								alignContent="center"
								alignItems="center"
							>
								<GridItem
									xs={9}
									sm={10}
									md={10}
									className={classes.cardPadding}
								>
									<div className={classes.cardDetail}>
										<h4 className={classes.titleDescription}>
											{educationData.school}
										</h4>
										<div className={classes.descriptionContainer}>
											<h5 className={classes.smallDescription}>
												{educationLevelList
													.filter((item) => {
														return item.eduLvlId === educationData.eduLvlId;
													})
													.map((item) => {
														return item.eduLvlName;
													})}
												{` of `}

												{educationData.courseName}
											</h5>
											<h5 className={classes.mediumDescription}>
												{educationData.studyField}
											</h5>
										</div>

										<div className={classes.descriptionContainer}>
											<span
												className={
													classes.smallDescription + " " + classes.grayFontCard
												}
											>
												<b>
													{monthDataList
														.filter(
															(month) =>
																month.monthId ===
																educationData.startDate.substring(5, 7)
														)
														.map((month) => month.monthName)}{" "}
													{educationData.startDate.substring(0, 4)}
													{" - "}
													{educationData.currentInProgressFlag !== 1 &&
														monthDataList
															.filter(
																(month) =>
																	month.monthId ===
																	educationData.endDate.substring(5, 7)
															)
															.map((month) => month.monthName)}{" "}
													{educationData.currentInProgressFlag === 1
														? `Present`
														: educationData.endDate.substring(0, 4)}
												</b>
											</span>

											<span
												className={
													classes.smallDescription +
													" " +
													classes.mobileSpan +
													" " +
													classes.grayFontCard
												}
											>
												{" "}
												<b>
													{countMonthYear(
														educationData.startDate,
														educationData.endDate
													) &&
														" • " +
														countMonthYear(
															educationData.startDate,
															educationData.endDate
														)}
												</b>
											</span>

											<p
												className={
													classes.smallDescription + " " + classes.grayFontCard
												}
											>
												<b>
													{countryList
														.filter((item) => {
															return item.countryId === educationData.countryId;
														})
														.map((item) => {
															return item.countryName;
														})}
												</b>
											</p>
										</div>

										{/* <span className={classes.divider}>{"  |  "}</span> */}
									</div>
								</GridItem>

								<GridItem xs={3} sm={2} md={2} className={classes.menuIcon}>
									{!isViewed && (
										<React.Fragment>
											<Tooltip
												id="tooltip-top"
												title={
													<p className={classes.tooltipDescription}>
														Delete Education
													</p>
												}
												placement="top"
												classes={{ tooltip: classes.tooltip }}
											>
												<Button
													onClick={() => {
														/* setRecordId(educationData.eduRecId); */
														setDeleteEducationModal(true);
														const response = {
															/* type: "EDIT_PROFILE",
														tabType: "EDUCATION",
														isTabShow: false, */

															extension: {
																recordId: educationData.eduRecId,
															},
														};
														/* setRecordId(-1); */

														dispatch(activateEditType(response));
													}}
													justIcon
													className={
														classes.buttonIconMargin + " " + classes.grayButton
													}
													color="transparent"
												>
													<IconLibrary.Trash />
												</Button>
											</Tooltip>
											<Tooltip
												title={
													<p className={classes.tooltipDescription}>
														Edit Education
													</p>
												}
												placement="top"
												classes={{ tooltip: classes.tooltip }}
											>
												<Button
													justIcon
													style={{ boxShadow: "none" }}
													color="transparent"
													className={classes.buttonIconMargin}
													onClick={() => {
														/* setRecordId(educationData.eduRecId); */
														setUpdateEducationModal(true);
														const response = {
															type: "EDIT_PROFILE",
															tabType: "EDUCATION",
															isTabShow: true,

															extension: {
																recordId: educationData.eduRecId,
															},
														};
														/* setRecordId(-1); */

														dispatch(activateEditType(response));
													}}
												>
													<IconLibrary.Pen />
												</Button>
											</Tooltip>
										</React.Fragment>
									)}
								</GridItem>
								<GridItem className={classes.cardPadding}>
									<div>
										{educationData.achievement ? (
											<div className={classes.description}>
												<Divider />
												<p className={classes.description}>
													{educationData.achievement}
												</p>
											</div>
										) : null}
									</div>

									<div>
										{educationData.description ? (
											<div className={classes.description}>
												{educationData.achievement ? null : <Divider />}
												<p className={classes.description}>
													{educationData.description}
												</p>
											</div>
										) : null}
									</div>

									<div>
										{educationData.extraCurricular ? (
											<div className={classes.description}>
												{educationData.achievement ? null : educationData.description ? null : (
													<Divider />
												)}
												<p className={classes.description}>
													{educationData.extraCurricular}
												</p>
											</div>
										) : null}
									</div>
								</GridItem>
							</GridContainer>
						)}
					</CardBody>
				</Card>
			</div>
		);
	});
	return (
		<div>
			{extension && extension.recordId && (
				<DeleteEducationModal
					open={deleteEducationModal}
					onClose={closeEducationDeleteModal}
					eduRecId={extension.recordId}
				/>
			)}
			{educationRecords.length > 0 ? (
				isViewed ? (
					showProfile ? (
						showEdu ? (
							educationList
						) : (
							<Card className={classes.cardBorder}>
								<CardBody>
									<h5>
										<b>Education Record is currently private</b>
									</h5>
								</CardBody>
							</Card>
						)
					) : (
						<Card className={classes.cardBorder}>
							<CardBody>
								<h5>
									<b>This user is currently private</b>
								</h5>
							</CardBody>
						</Card>
					)
				) : (
					educationList
				)
			) : (
				<NoEducationList />
			)}
		</div>
	);
}
