import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { departmentViewRoadmap, viewRoadmapRoleByRoleId } from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Department_Business_Intelligence from "assets/img/Career_Roadmap/Data_Artificial_Intelligence/Department_Business_Intelligence_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentBusinessIntelligence() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Business_Intelligence;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "bi",
				shape: "poly",
				coords: [78,393,70,397,67,399,64,405,61,411,61,454,65,461,68,464,73,468,82,470,173,469,180,467,184,464,188,459,190,452,190,411,187,403,183,399,177,395,170,394,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "160",
				shape: "poly",
				coords: [216,393,211,395,207,399,204,403,203,408,202,458,204,463,207,467,209,470,217,473,599,472,603,470,607,467,610,462,611,456,611,409,609,402,606,398,602,394,596,393,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "161",
				shape: "poly",
				coords: [862,394,857,396,853,397,850,400,849,404,847,409,847,458,849,464,852,467,856,470,862,472,1245,471,1249,468,1252,465,1255,462,1255,456,1255,406,1253,401,1250,398,1245,395,1240,393,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "162",
				shape: "poly",
				coords: [1526,391,1521,392,1518,395,1516,397,1514,401,1513,405,1512,458,1514,463,1515,467,1518,470,1521,472,1526,474,1820,473,1825,472,1828,470,1831,467,1833,464,1835,459,1834,402,1832,397,1828,394,1825,392,1820,391,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "bi") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo)
			dispatch(viewRoadmapRoleByRoleId(requestInfo));
			
			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// console.log(coords.x + "," + coords.y + ",");
		// navigator.clipboard.writeText(coords.x + "," + coords.y + ",");
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
