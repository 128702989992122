import React from "react";

import { connect, useSelector, useDispatch } from "react-redux";
import {
	getContactAccount,
	googleContactSync,
	microsoftContactSync,
	removeContactAcctARToken,
	errorContactImport,
} from "configurations/redux/userSlice.js";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js";
import defaultAvatar from "assets/img/candidate_avatar.jpeg";

import CoverPictureEditModal from "./CoverPictureEditModal.js";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "components/CustomButtons/Button.js";
import CreateIcon from "@material-ui/icons/Create";
import default_cover from "assets/img/default_cover.png";
import googleIcon from "assets/svg/google_1.png";
import { isDesktop } from "react-device-detect";
import { Hidden } from "@material-ui/core";
import IconLibrary from "views/Component/IconLibrary.js";
import { fontSize } from "@mui/system";
import { TimeFormat } from "configurations/configurations.js";
import CustomDialog from "../../../../components/CustomDialog/CustomDialog.js";
import SyncContactGoogleModal from "./SyncContactGoogleModal.js";

const useStyles = makeStyles(profilePageStyle);
const SyncContactAccountSection = ({ user }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [isOpen, setOpen] = React.useState(false);
	const [syncContactGoogleModal, setSyncContactGoogleModal] = React.useState(
		false
	);
	const userStatus = useSelector((state) => state.user);
	const contactAcctList = useSelector((state) => state.user.contactAcctList);
	const googleAcctStatus = useSelector((state) => state.user.googleAcctStatus);
	const msAcctStatus = useSelector((state) => state.user.msAcctStatus);
	const msLastSyncDate = useSelector((state) => state.user.msLastSyncDate);
	const googleLastSyncDate = useSelector(
		(state) => state.user.googleLastSyncDate
	);
	const googleAcctToken = useSelector((state) => state.user.googleAcctToken);
	const msAcctToken = useSelector((state) => state.user.msAcctToken);
	const errorMessage = useSelector((state) => state.user.errorMessage);
	const closeModal = () => {
		setOpen(false);
	};
	const closeSyncContactGoogleModal = () => {
		setSyncContactGoogleModal(false);
	};
	const defaultAcct = {
		caId: 0,
		caName: "",
		lastUpdateDate: "",
		status: 0,
		statusName: "",
	};
	const [googleAcct, setGoogleAcct] = React.useState(defaultAcct);
	const [msAcct, setMsAcct] = React.useState(defaultAcct);
	const dialogDefault = {
		title: "",
		description: "",
		buttonYes: "",
		buttonNo: "",
	};
	const [confirmDialog, setConfirmDialog] = React.useState({
		isOpen: false,
		dialogDefault,
	});
	const modalResize = () => {
		var cardSize = document.getElementById("accountContactCard");
		var modalResize = document.getElementById("templateCard");
		if (modalResize && cardSize) {
			var maxSize = 74 + cardSize.clientHeight;
			if (maxSize < 0) maxSize = 0;
			modalResize.style.height = maxSize + "px";
		}
	};

	React.useEffect(() => {
		if (errorMessage) {
			const dialogDefault = {
				title: "Warning",
				description: errorMessage,
				buttonYes: "",
				buttonNo: "OK",
			};
			setConfirmDialog({
				isOpen: true,
				dialogDefault,
			});
			dispatch(errorContactImport(null));
		}
	}, [errorMessage]);

	React.useEffect(() => {
		if (document.readyState === "complete") {
			setTimeout(() => {
				modalResize();
			}, 500);
		}
		window.addEventListener("resize", modalResize);
		return function cleanup() {
			window.removeEventListener("resize", modalResize);
		};
	});
	React.useEffect(() => {
		console.log(googleAcctStatus, msAcctStatus);
		console.log(contactAcctList);
		if (contactAcctList && contactAcctList.length > 0) {
			const google = contactAcctList.filter((item) => {
				return item.caName === "google";
			});
			if (google && google.length > 0) setGoogleAcct(google[0]);
			const ms = contactAcctList.filter((item) => {
				return item.caName === "microsoft";
			});
			if (ms && ms.length > 0) setMsAcct(ms[0]);
		}
		console.log(googleAcct, msAcct);
	}, [contactAcctList]);

	React.useEffect(() => {
		console.log("load");
		const request = {
			acctId: user.acctId,
			email: user.email,
			accessToken: localStorage.getItem("token"),
			resetStatus: 1,
		};
		dispatch(getContactAccount(request));
	}, []);

	const GoogleContact = () => {
		return (
			<Card
				className={
					classes.cardClick +
					" " +
					classes.contactCard +
					" " +
					classes.contactCardFirst
				}
				key={googleAcct.caId}
			>
				<CardBody className={classes.cardPaddingLeft}>
					<GridContainer>
						<GridItem>
							<GridContainer alignContent="center" alignItems="center">
								<React.Fragment>
									<GridItem xs={8} sm={8} md={8}>
										<GridContainer alignContent="center" alignItems="center">
											<GridItem className={classes.gridItemMargin}>
												<h4 className={classes.contactTitle}>
													<IconLibrary.GoogleContact />
													Google
													<span
														className={
															classes.smallDescription +
															" " +
															classes.mobileSpan +
															" " +
															classes.grayFontCard
														}
													>
														<b>
															{googleLastSyncDate
																? " • " + TimeFormat(googleLastSyncDate)
																: null}
														</b>
													</span>
												</h4>
											</GridItem>
										</GridContainer>
									</GridItem>
								</React.Fragment>
								<GridItem xs={4} sm={4} md={4} className={classes.itemCenter}>
									<Button
										onClick={() => {
											const request = {
												acctId: userStatus.acctId,
												email: userStatus.email,
												caId: googleAcct.caId,
												accessToken: localStorage.getItem("token"),
											};
											dispatch(removeContactAcctARToken(request));
										}}
										round
										color="grayTransparent"
										className={classes.buttonRemove + " " + classes.buttonSync}
										disabled={!googleAcctToken ? true : false}
									>
										<b>Remove</b>
									</Button>
									<Tooltip
										id="tooltip-top"
										title={
											googleAcctStatus !== "" ? googleAcctStatus : " Import Now"
										}
										placement="top"
										classes={{ tooltip: classes.tooltip }}
									>
										<Button
											onClick={(event) => {
												event.preventDefault();
												setSyncContactGoogleModal(true);
												// const requestProfile = {
												// 	acctId: userStatus.acctId,
												// 	email: userStatus.email,
												// 	accessToken: localStorage.getItem("token"),
												// };
												// dispatch(googleContactSync(requestProfile));
											}}
											color={
												googleAcctStatus !== "Import Now" &&
												googleAcctStatus !== ""
													? "grayWrapBorder"
													: "opptyNoBorder"
											}
											disabled={
												googleAcctStatus !== "Import Now" &&
												googleAcctStatus !== ""
													? true
													: false
											}
											className={classes.buttonInvite}
										>
											<IconLibrary.Sync />{" "}
											<Hidden xsDown>
												{googleAcctStatus !== ""
													? googleAcctStatus
													: " Import Now"}
											</Hidden>
										</Button>
									</Tooltip>
								</GridItem>
							</GridContainer>
						</GridItem>
					</GridContainer>
				</CardBody>
			</Card>
		);
	};

	const MsContact = () => {
		return (
			<Card
				className={
					classes.cardClick +
					" " +
					classes.contactCard +
					" " +
					classes.contactCardLast
				}
				key={msAcct.caId}
			>
				<CardBody className={classes.cardPaddingLeft}>
					<GridContainer>
						<GridItem>
							<GridContainer alignContent="center" alignItems="center">
								<React.Fragment>
									<GridItem xs={8} sm={8} md={8}>
										<GridContainer alignContent="center" alignItems="center">
											<GridItem className={classes.gridItemMargin}>
												<h4 className={classes.contactTitle}>
													<IconLibrary.MicrosoftContact />
													Microsoft
													<span
														className={
															classes.smallDescription +
															" " +
															classes.mobileSpan +
															" " +
															classes.grayFontCard
														}
													>
														<b>
															{msLastSyncDate
																? " • " + TimeFormat(msLastSyncDate)
																: null}
														</b>
													</span>
												</h4>
											</GridItem>
										</GridContainer>
									</GridItem>
								</React.Fragment>
								<GridItem xs={4} sm={4} md={4} className={classes.itemCenter}>
									<Button
										onClick={() => {
											const request = {
												acctId: userStatus.acctId,
												email: userStatus.email,
												caId: msAcct.caId,
												accessToken: localStorage.getItem("token"),
											};
											dispatch(removeContactAcctARToken(request));
										}}
										// fullWidth
										round
										color="grayTransparent"
										className={classes.buttonRemove + " " + classes.buttonSync}
										disabled={!msAcctToken ? true : false}
									>
										<b>Remove</b>
									</Button>
									<Tooltip
										id="tooltip-top"
										title={msAcctStatus !== "" ? msAcctStatus : " Import Now"}
										placement="top"
										classes={{ tooltip: classes.tooltip }}
									>
										<Button
											onClick={(event) => {
												event.preventDefault();
												const requestProfile = {
													acctId: userStatus.acctId,
													email: userStatus.email,
													accessToken: localStorage.getItem("token"),
												};
												dispatch(microsoftContactSync(requestProfile));
											}}
											color={
												msAcctStatus !== "Import Now" && msAcctStatus !== ""
													? "grayWrapBorder"
													: "opptyNoBorder"
											}
											disabled={
												msAcctStatus !== "Import Now" && msAcctStatus !== ""
													? true
													: false
											}
											className={classes.buttonInvite}
										>
											<IconLibrary.Sync />{" "}
											<Hidden xsDown>
												{msAcctStatus !== "" ? msAcctStatus : " Import Now"}
											</Hidden>
										</Button>
									</Tooltip>
								</GridItem>
							</GridContainer>
						</GridItem>
					</GridContainer>
				</CardBody>
			</Card>
		);
	};

	return (
		<React.Fragment>
			<Card className={classes.modalCardBorder + " " + classes.tabCardBorder}>
				<CardBody
					className={
						isDesktop
							? classes.smallModalBodyDesktop +
							  " " +
							  classes.modalBackground +
							  " " +
							  classes.modalBackgroundScroll +
							  " " +
							  classes.cropContainer
							: classes.smallModalBody +
							  " " +
							  classes.modalBackground +
							  " " +
							  classes.modalBackgroundScroll +
							  " " +
							  classes.cropContainer
					}
					id="templateCard"
				>
					<GridContainer justifyContent="center">
						<GridItem>
							<div className={classes.contactPadding} id="accountContactCard">
								<GoogleContact />
								<MsContact />
							</div>
							{/* <Button
								onClick={() => {
									const requestProfile = {
										acctId: userStatus.acctId,
										email: userStatus.email,
										accessToken: localStorage.getItem("token"),
									};
									dispatch(googleContactSync(requestProfile));
								}}
								color="google"
								fullWidth
								style={{ backgroundColor: "#4175DF" }}
								className={classes.buttonBorder}
								startIcon={
									<img
										src={googleIcon}
										style={{
											width: "32px",
											position: "absolute",
											top: "6px",
											left: "6px",
										}}
									/>
								}
							>
								<span className={classes.socialMargin}>
									Sign In with Google
								</span>
							</Button> */}
						</GridItem>
						{/* <GridItem>
							<Button
								// onClick={function () {
								// 	init_people_api();
								// }}
								onClick={() => {
									dispatch(microsoftContactSync());
								}}
								color="google"
								fullWidth
								style={{ backgroundColor: "#4175DF" }}
								className={classes.buttonBorder}
								startIcon={
									<img
										src={googleIcon}
										style={{
											width: "32px",
											position: "absolute",
											top: "6px",
											left: "6px",
										}}
									/>
								}
							>
								<span className={classes.socialMargin}>
									Sign In with Microsoft
								</span>
							</Button>
						</GridItem> */}
					</GridContainer>
				</CardBody>
			</Card>
			<CustomDialog
				confirmDialog={confirmDialog}
				setConfirmDialog={setConfirmDialog}
			/>
			<SyncContactGoogleModal
				open={syncContactGoogleModal}
				onClose={closeSyncContactGoogleModal}
				userStatus={userStatus}
			/>
		</React.Fragment>
	);
};
export default SyncContactAccountSection;
