import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

import Typography from "@material-ui/core/Typography";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { useHistory } from "react-router-dom";
import { getJobPosts } from "configurations/redux/jobManagementSlice.js";
import { getAppliedJobPostList } from "configurations/redux/appliedJobSlice";
import {
	updateNotification,
	deleteNotification,
} from "configurations/redux/eventNotificationSlice.js";
import Dialog from "../../../components/CustomDialog/CustomDialog.js";
import { TimeFormat } from "configurations/configurations";

import { encryptData } from "configurations/configurations.js";
import notificationPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/NotificationPage/notificationPageStyle.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import IconLibrary from "views/Component/IconLibrary.js";
import { Link } from "react-router-dom";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import FiberManualRecordSharp from "@material-ui/icons/FiberManualRecordSharp";

const useStyles = makeStyles(notificationPageStyle);

export default function SearchResultCard({ item }) {
	// console.log(item);
	// const useStyles = makeStyles(() => ({
	// 	listItemUnread: {
	// 		backgroundColor: "rgb(105,105,247,.2)",
	// 	},
	// 	listSpaceRemoved: {
	// 		paddingTop: "0px!important",
	// 		paddingBottom: "0px!important",
	// 	},
	// }));
	const classes = useStyles();
	const dispatch = useDispatch();
	const userStatus = useSelector((state) => state.auth.user);
	const [clientIp, setClientIp] = React.useState();
	const searchResult = useSelector(
		(state) => state.eventNotification.searchResult
	);
	const jobPostList = useSelector((state) => state.jobManagement.jobPostList);
	const appliedJobPostList = useSelector(
		(state) => state.appliedJob.jobPostList
	);

	const dialogDefault = {
		title: "Alert",
		description: "Are you sure to delete this record?",
		buttonYes: "Delete",
		buttonNo: "Cancel",
		notificationId: item.notificationListId,
	};
	const [confirmDialog, setConfirmDialog] = React.useState({
		isOpen: false,
		dialogDefault,
	});

	const requestProfile = {
		acctId: userStatus.acctId,
		email: userStatus.email,
		accessToken: localStorage.getItem("token"),
		ipAddr: clientIp,
		lastRecordSize: 0,
		requestRecordSize: 1,
		paginationClick: false,
		statusId: -1,
	};
	const applyJobRequest = {
		acctId: userStatus.acctId,
		email: userStatus.email,
		accessToken: localStorage.getItem("token"),
		lastRecordSize: 0,
		requestRecordSize: 1,
		paginationClick: false,
	};
	const requestNotificationStatusUpdate = {
		acctId: userStatus.acctId,
		email: userStatus.email,
		accessToken: localStorage.getItem("token"),
		ipAddr: clientIp,
		notificationListId: item.notificationListId,
		eventListId: item.eventListId,
		notificationStatus: "read",
		searchResult: searchResult,
		item: item,
	};
	const history = useHistory();
	const getNotificationNavigate = (item) => {
		if (item.eventTypeId === 1) {
			//job application
			if (!jobPostList.length > 0) dispatch(getJobPosts(requestProfile));
		} else if (item.eventTypeId === 4) {
			if (!appliedJobPostList.length > 0) {
				dispatch(getAppliedJobPostList(applyJobRequest));
			}
		}
		// console.log(requestNotificationStatusUpdate);
		dispatch(updateNotification(requestNotificationStatusUpdate));
		history.push(linkTarget.pathname, linkTarget.state);
	};

	var linkTarget = {};
	if (item.eventTypeId === 1 || item.eventTypeId === 3) {
		linkTarget = {
			pathname: `/corporate-job-view-page/${encryptData(item.jobPostId)}`,
			// state: {
			// 	jobPostId: item.jobPostId,
			// },
		};
	} else if (item.eventTypeId === 4) {
		linkTarget = {
			pathname: "/job-status-page",
		};
	} else if (item.eventTypeId == 11 || item.eventTypeId == 12) {
		linkTarget = {
			pathname: `/manage-connection/${encryptData(userStatus.acctId)}`,
		};
	} else if (item.eventTypeId >= 13 && item.eventTypeId <= 26) {
		if (item.groupId && item.groupId > 0) {
			linkTarget = {
				pathname: `/manage-group/${encryptData(item.groupId)}/${encryptData(
					item.postId
				)}`,
			};
		} else {
			linkTarget = {
				pathname: `/social-feed/2/${encryptData(item.postId)}`,
			};
		}
	} else if (item.eventTypeId >= 28 && item.eventTypeId <= 33) {
		linkTarget = {
			pathname: `/testimonials/${encryptData(userStatus.acctId)}`,
		};
	}

	const deleteHandle = () => {
		setConfirmDialog({
			isOpen: true,
			dialogDefault,
			onConfirm: () => {
				dispatch(deleteNotification(requestNotificationStatusUpdate));
				setConfirmDialog({
					isOpen: false,
					dialogDefault,
				});
			},
		});
	};

	var contentClass = null;
	if (item.statusName == "read") contentClass = classes.grayFont;

	return (
		<React.Fragment>
			<div key={item.acctId}>
				<Card className={classes.cardClick + " " + classes.cardFrame}>
					<CardBody>
						<div className={classes.cardBody}>
							<GridContainer
								justifyContent="space-between"
								alignContent="center"
								alignItems="center"
							>
								<GridItem
									// xs={10}
									// sm={11}
									// md={11}
									// lg={11}
									onClick={() => {
										getNotificationNavigate(item);
									}}
									style={{
										justifyContent: "space-between",
										alignItems: "center",
										display: "flex",
										alignContent: "center",
									}}
								>
									<GridContainer>
										<GridItem>
											<h4
												className={
													classes.title +
													" " +
													classes.titleInfo +
													" " +
													contentClass
												}
											>
												{item.title}
												{item.statusName == "unread" ? (
													<FiberManualRecordSharp
														color="error"
														className={classes.unreadIcon}
													/>
												) : null}
											</h4>
											<div>
												<h5
													className={
														classes.description +
														" " +
														classes.descInfo +
														" " +
														contentClass
													}
												>
													{item.description}
												</h5>
											</div>
											<h5 className={classes.viewInfo + " " + contentClass}>
												{TimeFormat(item.createDate)}
											</h5>
										</GridItem>
									</GridContainer>
									{/* </GridItem>

								<GridItem
									xs={2}
									sm={1}
									md={1}
									lg={1}
									className={classes.buttonGrid}
								> */}
									<Button
										onClick={(event) => {
											event.preventDefault();
											deleteHandle(item);
										}}
										color="grayWrapBorder"
										className={classes.buttonDelete}
									>
										<IconLibrary.Trash />
										{/* <FontIcon
												icon={faEnvelope}
												text={
													<Hidden mdDown>
														<span>Message</span>
													</Hidden>
												}
												className={classes.messageIcon}
											/> */}
										{/* <EmailRoundedIcon className={classes.followIcon} />
												<b>Message</b> */}
									</Button>
								</GridItem>
							</GridContainer>
						</div>
					</CardBody>
				</Card>

				<Dialog
					confirmDialog={confirmDialog}
					setConfirmDialog={setConfirmDialog}
				/>
			</div>
		</React.Fragment>
	);
}
