import {
  container,
  title,
  whiteColor,
  mainRaised,
  grayColor,
  blackColor,
  cardTitle,
  main,
  hexToRgb,
  sectionDark,
} from "assets/jss/material-kit-pro-react.js";

const landingPageStyle = {
  container: {
    color: blackColor,
    ...container,
    zIndex: "2",
    backgroundColor: "red",
  },
  whiteColor: {
    color: whiteColor + "!important",
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: whiteColor,
    textDecoration: "none",
  },
  main: {
    ...main,
  },
  tab: {
    backgroundColor: "red",
  },
  mainRaised: {
    ...mainRaised,
  },
  informationSectionContainer: {
    ...container,
    textAlign: "center",
    //marginBottom: "70px",
    padding: "70px 0",
  },

  cardTitle: {
    ...cardTitle,
  },
  cardDescription: {
    color: grayColor[0],
  },
  sectionDark: {
    ...sectionDark,
    "& $cardDescription": {
      color: "rgba(" + hexToRgb(whiteColor) + ", 0.76)",
    },
    "& $cardTitle": {
      color: whiteColor,
    },
    backgroundSize: "550% 450%",
    //borderBottomRightRadius: "6px",
    //borderBottomLeftRadius: "6px",
    textAlign: "center",
    //marginBottom: "70px",
    padding: "70px 0",
  },
  marginAuto: {
    marginLeft: "auto !important",
    marginRight: "auto !important",
  },
  cardHeight: {
    marginTop: "90px!important",
    marginBottom: "70px!important",
    height: "200px",
  },
  avatarPadding: {
    marginTop: "10px",
  },
  logoImage: {
    width: "90px",
    height: "90px",
  },
  emailButton: {
    marginTop: "30px",
  },
  cookiePolicyBanner: {
    position: "fixed",
    float: "bottom",
    bottom: 10,
    width: "90%",
    top: "auto",
  },
};

export default landingPageStyle;
