import React from "react";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

import passwordRecoveryPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/PasswordRecoveryPage/passwordRecoveryPageStyle.js";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import NavPills from "components/NavPills/NavPills.js";

import Header from "views/Component/Header.js";

import image from "assets/img/bg7.jpg";
import { errorCleanUp } from "configurations/redux/authSlice.js";
import maintenanceImage from "assets/img/Maintenance_Image.png";
import { Hidden } from "@material-ui/core";
const useStyles = makeStyles(passwordRecoveryPageStyle);

const MaintenancePage = ({ match }) => {
	console.log(match);
	const history = useHistory();
	const classes = useStyles();
	const dispatch = useDispatch();

	React.useEffect(() => {
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;
	});

	React.useEffect(() => {
		dispatch(errorCleanUp());
		history.replace(match.url);
	}, []);

	const [cardAnimation, setCardAnimation] = React.useState("cardHidden");
	setTimeout(function () {
		setCardAnimation("");
	}, 700);

	return (
		<div className={classes.body}>
			{/* <Header color={"transparent"} loggedInStatus={false} /> */}
			<div
				className={classes.pageHeader}
				style={{
					backgroundSize: "cover",
					backgroundPosition: "top center",
				}}
			>
				<div className={classes.container}>
					<GridContainer
						justifyContent="center"
						alignContent="center"
						alignItems="center"
					>
						<GridItem xs={12} sm={12} md={7}>
							<div className={classes.infoArea}>
								<h2 className={classes.leftInfoTitle}>Welcome Back!</h2>
								<h5 className={classes.leftInfoDescription}>
									Log in now to see if you've landed your dream job.
								</h5>
								<Hidden smDown>
									<img src={maintenanceImage} className={classes.infoImage} />
								</Hidden>
							</div>
						</GridItem>
						<GridItem xs={12} sm={12} md={5} className={classes.loginGrid}>
							<Card className={classes.passwordCard}>
								<CardBody className={classes.passwordCardBody}>
									<GridContainer
										justifyContent="center"
										alignContent="center"
										alignItems="center"
									>
										<GridItem xs={12} sm={12} md={12}>
											<h4 className={classes.title + " " + classes.textCenter}>
												Important Notice!
											</h4>

											<h4
												className={
													classes.infoDescription + " " + classes.textCenter
												}
											>
												Oops!! Sorry, our system is currently undergoing system
												maintenance.
											</h4>

											<h4
												className={
													classes.infoDescription + " " + classes.textCenter
												}
											>
												We will getting a little impatient too for our new
												release!
												<br />
												See you soon!
											</h4>
										</GridItem>
									</GridContainer>
								</CardBody>
							</Card>
						</GridItem>
					</GridContainer>
				</div>
			</div>
		</div>
	);
};

export default MaintenancePage;
