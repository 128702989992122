import { createSlice } from '@reduxjs/toolkit';

import axios from 'axios';
import { instanceUrl, header, emptyCache } from 'configurations/configurations.js';

const messageChatroomSlice = createSlice({
  name: 'messageChatroom',
  initialState: {
    searchCount: 0,
    searchResult: [],
    searchParams: [],
    lastRecordSize: 0,
    requestRecordSize: 0,
    isLoading: true,
    isEnd: false,
    isSearching: false,
    isUpdated: true,
    chatroomId: 0,
    isCreated: false,
    activeBizId: 0,
  },
  reducers: {
    CHATROOM_LOADING: (state, action) => {
      state.isLoading = true;
    },
    CHATROOM_SEARCH_SUCCESS: (state, action) => {
      // console.log(action);
      localStorage.setItem('token', action.payload.token);
      state.searchResult = [...state.searchResult, ...action.payload.searchResult];
      state.searchParams = action.payload.searchParams;
      // state.lastRecordSize =
      // 	state.lastRecordSize + action.payload.requestRecordSize;
      state.lastRecordSize = state.searchResult.length;
      state.requestRecordSize = action.payload.requestRecordSize;
      state.isLoading = false;
      state.activeBizId = action.payload.bizId;
    },
    CHATROOM_SEARCH_END: (state, action) => {
      state.isEnd = true;
    },
    DEFAULT_CHATROOM_SEARCH: (state, action) => {
      state.searchResult = [];
      state.searchParams = [];
      state.lastRecordSize = 0;
      state.requestRecordSize = 0;
      state.isEnd = false;
      state.isSearching = true;
      state.activeBizId = 0;

      if (action.payload == 1) {
        console.log('chatroom id reset', action.payload);
        state.chatroomId = 0;
      }
    },
    CHATROOM_SEARCH_FAIL: state => {
      state.isUpdated = false;
    },
    CHATROOM_ADD_SUCCESS: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      state.isUpdated = true;
      state.isLoading = false;
      state.chatroomId = action.payload.recId;
      state.isCreated = true;
    },
    CHATROOM_ADD_RESET: (state, action) => {
      state.isCreated = false;
    },
    CHATROOM_CHECK_UNREAD: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      state.searchCount = action.payload.searchCount;
      state.isLoading = false;
    },
    CHATROOM_CHECK_UNREAD_END: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      state.searchCount = action.payload.searchCount;
      state.isUpdated = true;
    },
    CHATROOM_CHECK_UNREAD_FAIL: (state, action) => {
      state.searchCount = 0;
      state.isUpdated = false;
    },
    CHATROOM_UPDATE_SUCCESS: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      state.isUpdated = true;
      state.isLoading = false;
    },
    CHATROOM_DELETE_SUCCESS: (state, action) => {
      localStorage.setItem('token', action.payload.token);
      state.isSearching = false;
      state.searchResult = action.payload.searchResult;
    },
    CHATROOM_NEW_SUCCESS: (state, action) => {
      // console.log(action)
      localStorage.setItem('token', action.payload.token);
      state.searchResult = [...action.payload.chatroomList, ...action.payload.searchResult];
      state.searchParams = action.payload.searchParams;
      state.isLoading = false;
    },
  },
});

const {
  CHATROOM_LOADING,
  CHATROOM_SEARCH_SUCCESS,
  CHATROOM_SEARCH_END,
  DEFAULT_CHATROOM_SEARCH,
  CHATROOM_SEARCH_FAIL,
  CHATROOM_ADD_SUCCESS,
  CHATROOM_CHECK_UNREAD,
  CHATROOM_CHECK_UNREAD_END,
  CHATROOM_CHECK_UNREAD_FAIL,
  CHATROOM_UPDATE_SUCCESS,
  CHATROOM_DELETE_SUCCESS,
  CHATROOM_NEW_SUCCESS,
  CHATROOM_ADD_RESET,
} = messageChatroomSlice.actions;

export const messageChatroomInfo = ({
  acctId,
  accessToken,
  email,
  lastRecordSize,
  requestRecordSize,
  keyword,
  searchResult,
  bizId,
}) => async dispatch => {
  const body = {
    acctId,
    accessToken,
    email,
    lastRecordSize,
    requestRecordSize,
    keyword,
    bizId,
  };
  dispatch(CHATROOM_LOADING());
  if (lastRecordSize === 0) {
    dispatch(DEFAULT_CHATROOM_SEARCH(0));
  }

  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/mm/get-chatroom`,
    params: body,
  })
    .then(res => {
      if (res.status === 201) {
        console.log(searchResult);
        const response = {
          searchResult: res.data.payload.chatroomList,
          lastRecordSize,
          requestRecordSize: res.data.payload.chatroomList.length,
          token: res.data.token,
          bizId: bizId,
        };
        dispatch(CHATROOM_SEARCH_SUCCESS(response));
        if (requestRecordSize > res.data.payload.chatroomList.length) {
          dispatch(CHATROOM_SEARCH_END());
        }
      } else if (res.status === 202) {
        dispatch(CHATROOM_SEARCH_END());
      } else {
        dispatch(CHATROOM_SEARCH_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const messageNewChatroomInfo = ({ acctId, accessToken, email, searchResult, bizId }) => async dispatch => {
  const body = {
    acctId,
    accessToken,
    email,
    bizId,
  };
  dispatch(CHATROOM_LOADING());
  console.log(body);
  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/mm/get-new-chatroom`,
    params: body,
  })
    .then(res => {
      console.log(res);
      if (res.status === 201) {
        // console.log(searchResult);
        // console.log(res.data.payload.chatroomList);
        var chatroomList = [];
        var newChatroomList = [];
        var previousChatroom = [];
        if (res.data.payload.chatroomList.length > 0) {
          chatroomList = res.data.payload.chatroomList;
          previousChatroom = chatroomList.filter(filterItem =>
            searchResult.some(item => filterItem.chatroomId === item.chatroomId)
          );
          // chatroomList
          // 	.filter((filterItem) =>
          // 		searchResult.some(
          // 			(item) => filterItem.chatroomId === item.chatroomId
          // 		)
          // 	)
          // 	.map((item) => {
          // 		var profilePic = searchResult.find(
          // 			(itemFilter) => item.chatroomId === itemFilter.chatroomId
          // 		);
          // 		previousChatroom.push({
          // 			acctId: item.acctId,
          // 			acctTypeId: item.acctTypeId,
          // 			chatroomId: item.chatroomId,
          // 			lastAcctId: item.lastAcctId,
          // 			lastMessage: item.lastMessage,
          // 			lastUpdateDate: item.lastUpdateDate,
          // 			privacySetting: item.privacySetting,
          // 			profilePic: profilePic.profilePic,
          // 			readId: item.readId,
          // 			readStatus: item.readStatus,
          // 			status: item.status,
          // 			statusName: item.statusName,
          // 			title: item.title,
          // 		});
          // 	});
          newChatroomList = chatroomList.filter(filterItem =>
            searchResult.every(item => filterItem.chatroomId !== item.chatroomId && filterItem.readStatus > 0)
          );
          console.log(previousChatroom, newChatroomList);
        }
        const response = {
          searchResult: previousChatroom,
          chatroomList: newChatroomList,
          token: res.data.token,
        };
        dispatch(CHATROOM_NEW_SUCCESS(response));
      } else if (res.status === 202) {
        dispatch(CHATROOM_SEARCH_END());
      } else {
        dispatch(CHATROOM_SEARCH_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const createChatroom = ({
  acctId,
  accessToken,
  email,
  chatroomTitle,
  chatroomList,
  receiverId,
  bizId,
  receiverBizId,
  typeId,
}) => async dispatch => {
  const body = {
    acctId,
    accessToken,
    email,
    chatroomTitle,
    chatroomList,
    receiverId,
    bizId,
    receiverBizId,
    typeId,
  };

  await axios({
    method: 'POST',
    headers: header,
    url: `${instanceUrl}/api/mm/create-chatroom`,
    data: body,
  })
    .then(res => {
      // console.log(res);

      const response = {
        recId: res.data.payload.recordId,
        token: res.data.token,
      };
      if (res.status === 201) {
        dispatch(CHATROOM_ADD_SUCCESS(response));
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const createChatroomReset = () => async dispatch => {
  dispatch(CHATROOM_ADD_RESET());
};

export const checkChatroomUnread = ({ acctId, accessToken, email, chatroomId }) => async dispatch => {
  const body = {
    acctId,
    accessToken,
    email,
    chatroomId,
  };
  console.log(body);
  await axios({
    method: 'GET',
    headers: header,
    url: `${instanceUrl}/api/mm/check-unread-chatroom`,
    params: body,
  })
    .then(res => {
      console.log(res);
      if (res.status === 201) {
        const response = {
          searchCount: res.data.payload.chatroomCount,
          token: res.data.token,
        };
        dispatch(CHATROOM_CHECK_UNREAD(response));
      } else if (res.status === 202) {
        const response = {
          searchCount: res.data.payload.chatroomCount,
          token: res.data.token,
        };
        dispatch(CHATROOM_CHECK_UNREAD_END(response));
      } else {
        dispatch(CHATROOM_CHECK_UNREAD_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const updateChatroom = ({ acctId, accessToken, email, chatroomId, chatroomStatus }) => async dispatch => {
  dispatch(CHATROOM_LOADING());
  const body = {
    acctId,
    accessToken,
    email,
    chatroomId,
    chatroomStatus,
  };
  // console.log(body);
  await axios({
    method: 'PUT',
    headers: header,
    url: `${instanceUrl}/api/mm/update-chatroom-status`,
    data: body,
  })
    .then(res => {
      // console.log(res);
      if (res.status === 201) {
        const response = {
          chatroomId,
          token: res.data.token,
        };
        dispatch(CHATROOM_UPDATE_SUCCESS(response));
      } else {
        dispatch(CHATROOM_SEARCH_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const deleteChatroom = ({ acctId, accessToken, email, chatroomId, searchResult }) => async dispatch => {
  const body = {
    acctId,
    accessToken,
    email,
    chatroomId,
    searchResult,
  };
  await axios({
    method: 'DELETE',
    headers: header,
    url: `${instanceUrl}/api/mm/delete-chatroom`,
    data: body,
  })
    .then(res => {
      if (res.status === 201) {
        searchResult = searchResult.filter(item => item.chatroomId != chatroomId);
        const response = {
          searchResult,
          chatroomId,
          token: res.data.token,
        };
        dispatch(CHATROOM_DELETE_SUCCESS(response));
      } else {
        dispatch(CHATROOM_SEARCH_FAIL());
      }
    })
    .catch(err => {
      if (err.message.includes('503')) {
        emptyCache();
      }
      console.log(err);
    });
};

export const messageChatroomReset = () => async dispatch => {
  dispatch(DEFAULT_CHATROOM_SEARCH(1));
};

export default messageChatroomSlice.reducer;
