import {
	container,
	cardTitle,
	title,
	mlAuto,
	mrAuto,
	main,
	mainRaised,
	grayColor,
	whiteColor,
	blackColor,
	hexToRgb,
	opptyDesignColor,
} from "assets/jss/material-kit-pro-react.js";
import customSelectStyle from "assets/jss/material-kit-pro-react/customSelectStyle.js";
import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js";
import customCheckboxRadioSwitchStyle from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.js";
import headersSection from "assets/jss/material-kit-pro-react/components/headerStyle.js";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.js";
import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.js";

const notificationPageStyle = (theme) => ({
	...headersSection(theme),
	...modalStyle(theme),
	...styles,
	...customSelectStyle,
	container: {
		...container,
		position: "relative",
		zIndex: "2",
		"& h1, & h4, & h6": {
			color: blackColor,
		},
		"@media (min-width: 576px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 768px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 992px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 1200px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 1400px)": {
			maxWidth: "80vw",
		},
	},
	...tooltipsStyle,

	main: {
		...main,
		textAlign: "center",
	},

	mainRaised: {
		...mainRaised,
		boxShadow: "none",
		margin: 0,
		marginTop: "14vh",
		// minHeight: "1450px",
		background: "#F2F3F7",
		textAlign: "center",

		[theme.breakpoints.down("sm")]: {
			marginTop: "120px",
		},
	},

	mlAuto,
	mrAuto,
	cardTitle,
	...styles,
	...customCheckboxRadioSwitchStyle,
	...customSelectStyle,
	textCenter: {
		textAlign: "center",
	},
	avatarPadding: {
		marginTop: "10px",
	},
	parallax: {
		height: "700px",
		backgroundPosition: "top center",
	},
	searchBarButton: {
		marginTop: "17px",
	},
	loadingImage: {
		width: "100%",
		height: "100%",
		borderRadius: "12px",
	},
	cardImage: {
		overflow: "hidden",
		boxShadow:
			"0 16px 38px -12px rgba(" +
			hexToRgb(blackColor) +
			", 0.56), 0 4px 25px 0px rgba(" +
			hexToRgb(blackColor) +
			", 0.12), 0 8px 10px -5px rgba(" +
			hexToRgb(blackColor) +
			", 0.2)",
		margin: "-50px auto 0",
		padding: "0",
		width: "80px",
		height: "140px",
		borderRadius: "50%",

		[theme.breakpoints.down("xs")]: {
			width: "70px",
		},

		"&$cardAvatarPlain": {
			marginTop: "0",
		},
	},
	cardClick: {
		cursor: "pointer",
		"&:hover,&:focus": {
			//backgroundColor: grayColor[0],
			boxShadow:
				"0 14px 26px -12px rgba(" +
				hexToRgb(grayColor[0]) +
				", 0.42), 0 4px 23px 0px rgba(" +
				hexToRgb(blackColor) +
				", 0.12), 0 8px 10px -5px rgba(" +
				hexToRgb(grayColor[0]) +
				", 0.2)",
		},
	},

	cardHeight: {
		boxShadow: "none",
		height: "auto",
		paddingTop: "15px",
		paddingBottom: "15px",
		borderRadius: "18px",
	},
	cardViewCount: {
		marginTop: "20px",
	},
	cardFooterBorder: {
		borderTop: "1px solid rgba(" + hexToRgb(blackColor) + ", 0.35)",
		cursor: "default !important",
		"&:hover,&:focus": {
			//backgroundColor: grayColor[0],
			boxShadow: "none",
		},
	},
	cardResultSection: {
		paddingTop: "15px",
		paddingBottom: "15px",
		//overflowY: "scroll",
	},
	profileResult: {
		minHeight: "420px",
		height: "700px",
		overflowY: "scroll",
	},
	cardBody: {
		[theme.breakpoints.down("xs")]: {
			margin: "-5px -15px -15px -15px",
		},
	},
	cardBodyPadding: {
		paddingRight: "10px",
		paddingLeft: "10px",
	},
	textDeco: {
		textDecoration: "none",
		color: "inherit",
		"&:hover,&:focus": {
			color: "inherit",
		},
	},

	cardFrame: {
		borderRadius: "18px",
		boxShadow: "none",
		margin: "10px 0",
		[theme.breakpoints.down("sm")]: {
			margin: "5px 0",
		},
	},
	title: {
		...title,
		position: "relative",
		marginTop: "25px",
		minHeight: "32px",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		color: blackColor,
		textDecoration: "none",
		textAlign: "center",
		// "& svg": {
		// 	width: "32px",
		// 	height: "32px",
		// 	marginRight: "20px",
		// 	marginTop: "10px",
		// },
	},
	cardTitle: {
		cursor: "pointer",
		"&:hover,&:focus": {
			//color: whiteColor,
			//backgroundColor: grayColor[0],
			boxShadow:
				"0 14px 26px -12px rgba(" +
				hexToRgb(grayColor[0]) +
				", 0.42), 0 4px 23px 0px rgba(" +
				hexToRgb(blackColor) +
				", 0.12), 0 8px 10px -5px rgba(" +
				hexToRgb(grayColor[0]) +
				", 0.2)",
		},
	},

	backGroundImage: {
		width: "100%",
		height: "100%",
	},
	gridItem: {
		...mlAuto,
		...mrAuto,
	},
	applicationStatus: {
		color: "#54c154 !important",
		textTransform: "uppercase",
		fontSize: "14px",
		fontWeight: "500 !important",
	},
	logoImage: {
		maxWidth: "150px",
		maxHeight: "150px",
		height: "100%",
		width: "100%",
		margin: "15px 0px",
	},

	gridItemMargin: {
		margin: "15px 0px",
	},
	rightGrid: {
		textAlign: "right",
		[theme.breakpoints.down("md")]: {
			textAlign: "left",
		},
	},
	noDecoText: {
		textDecoration: "none",
		color: blackColor,
	},
	itemCenter: {
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		[theme.breakpoints.down("xs")]: {
			paddingRight: "0",
		},
	},
	buttonColor: {
		color: opptyDesignColor + "!important",
	},
	selectedTabButton: {
		height: "80px",
		backgroundColor: opptyDesignColor,
		borderRadius: "18px",
		color: whiteColor,
		fontSize: "18px",
		boxShadow:
			"0 2px 2px 0 rgba(" +
			hexToRgb(opptyDesignColor[0]) +
			", 0.14), 0 3px 1px -2px rgba(" +
			hexToRgb(opptyDesignColor[0]) +
			", 0.2), 0 1px 5px 0 rgba(" +
			hexToRgb(opptyDesignColor[0]) +
			", 0.12)",
		"&:hover,&:focus": {
			backgroundColor: opptyDesignColor,

			boxShadow:
				"0 14px 26px -12px rgba(" +
				hexToRgb(opptyDesignColor[0]) +
				", 0.42), 0 4px 23px 0px rgba(" +
				hexToRgb(blackColor) +
				", 0.12), 0 8px 10px -5px rgba(" +
				hexToRgb(opptyDesignColor[0]) +
				", 0.2)",
		},
	},
	tabButton: {
		height: "80px",
		backgroundColor: whiteColor,
		border: grayColor[0] + " 3px solid",
		borderRadius: "18px",
		fontSize: "18px",
		color: grayColor[0],
		boxShadow:
			"0 2px 2px 0 rgba(" +
			hexToRgb(grayColor[0]) +
			", 0.14), 0 3px 1px -2px rgba(" +
			hexToRgb(grayColor[0]) +
			", 0.2), 0 1px 5px 0 rgba(" +
			hexToRgb(grayColor[0]) +
			", 0.12)",
		"&:hover,&:focus": {
			backgroundColor: whiteColor,
			color: grayColor[0],

			boxShadow:
				"0 14px 26px -12px rgba(" +
				hexToRgb(grayColor[0]) +
				", 0.42), 0 4px 23px 0px rgba(" +
				hexToRgb(blackColor) +
				", 0.12), 0 8px 10px -5px rgba(" +
				hexToRgb(grayColor[0]) +
				", 0.2)",
		},
	},
	description: {
		margin: "15px 0px !important",
		/* maxWidth: "600px", */
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		color: blackColor[0],
		fontWeight: "400",
		whiteSpace: "pre-line",
		"& p": {
			margin: "15px 0px",
		},
	},
	smallDescription: {
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		color: blackColor[0],
		fontSize: "14px",
		fontWeight: "400",
	},

	titleMargin: {
		marginBottom: "20px",
	},
	jobTitle: {
		...title,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		position: "relative",
		margin: "15px 0 !important",

		textDecoration: "none",
		color: blackColor + "!important",
		"& svg": {
			width: "32px",
			height: "32px",
			marginRight: "20px",
			marginTop: "10px",
		},
	},
	jobPostStatus: {
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		margin: "15px 0",
		textDecoration: "none",
		color: grayColor[0] + "!important",
		fontWeight: "400",
	},
	jobTime: {
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		margin: "15px 0",
		textDecoration: "none",
		color: grayColor[0] + "!important",
		fontWeight: "400",
	},
	gridHeight: {
		/* 	height: "90px", */
		padding: "20px 15px !important",
		[theme.breakpoints.down("xs")]: {
			padding: "0 !important",
		},
	},
	gridCard: {
		[theme.breakpoints.down("xs")]: {
			padding: "0 !important",
		},
	},
	jobDescription: {
		color: blackColor + "!important",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		fontWeight: "400",
		fontSize: "16px",
		whiteSpace: "pre-line",
	},
	candidateDescription: {
		textDecoration: "none",
		//marginTop: "30px !important",
		color: grayColor[0] + "!important",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		fontWeight: "400",
		fontSize: "16px",
		textAlign: "left",
		//padding: "0 !important",
	},
	infoArea: {
		padding: "10px 0 5px 0",
	},
	iconWrapper: {
		float: "left",
		width: "36px",
		height: "36px",
		color: grayColor[0] + "!important",
		marginTop: "10px",
		marginRight: "10px",
	},
	titleInfo: {
		// minHeight: "1px",
		margin: "0!important",
		textAlign: "left",
		[theme.breakpoints.down("xs")]: {
			// textAlign: "center",
			fontSize: "13px",
			minHeight: "13px",
		},
	},
	titleLocation: {
		// minHeight: "24px",
		margin: "0!important",
		marginTop: "5px!important",
		color: "#959595!important",
		fontWeight: "normal",
		[theme.breakpoints.down("xs")]: {
			// textAlign: "center",
			fontSize: "12px",
			minHeight: "18px",
		},
	},
	descInfo: {
		// minHeight: "26px",
		margin: "0!important",
		marginTop: "5px!important",
		fontSize: "14px",
		color: blackColor[0],
		textAlign: "left",
		[theme.breakpoints.down("xs")]: {
			// textAlign: "center",
			fontSize: "12px",
			minHeight: "18px",
		},
	},
	viewInfo: {
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap",
		color: "#959595",
		fontSize: "15px",
		textAlign: "left",
		[theme.breakpoints.down("xs")]: {
			fontSize: "13px!important",
		},
	},
	rightAligned: {
		float: "right",
		[theme.breakpoints.down("xs")]: {
			height: "20px",
			width: "20px",
			minWidth: "20px",
		},
	},
	grayFont: {
		color: "#99B2C6 !important",
	},
	followIcon: {
		[theme.breakpoints.down("xs")]: {
			width: "15px!important",
			height: "15px!important",
		},
	},
	paginationAligned: {
		float: "right",
	},
	buttonMessage: {
		borderRadius: "8px",
		padding: "5px 10px 5px 15px",
		marginRight: "5px",
		fontSize: "12px",
		textTransform: "none",
		whiteSpace: "nowrap",
		// borderRadius: "8px",
		// padding: "5px 10px 5px 15px",
		// marginRight: "10px",
		// [theme.breakpoints.down("xs")]: {
		// 	padding: "0px 11px 1px 15px",
		// 	borderRadius: "4px",
		// },
	},
	messageIcon: {
		[theme.breakpoints.down("xs")]: {
			overflow: "visible!important",
			padding: "0!important",
		},
	},
	borderRadius: {
		borderRadius: "50px",
		boxShadow: "none",
		border: "2px solid",
		borderColor: "#959595",
		padding: "4px",
	},
	timeInfo: {
		float: "right",
		fontSize: "13px",
		paddingTop: "3px",
		borderColor: "#959595",
	},
	buttonDelete: {
		borderRadius: "7px",
		"& svg": {
			marginRight: "0px",
			width: "20px!important",
			// [theme.breakpoints.up("md")]: {
			// 	marginRight: "10px",
			// },
		},
		textTransform: "none",
		fontSize: "16px",
		padding: "5px !important",
		marginLeft: "10px",
		[theme.breakpoints.down("sm")]: {
			marginLeft: "0px",
		},
	},
	loadingContainer: {
		padding: "30px 0px",
		alignItems: "center",
		display: "flex",
		justifyContent: "center",
	},
	endMessage: {
		textAlign: "center",
	},
	buttonGrid: {
		[theme.breakpoints.down("xs")]: {
			paddingLeft: "0px",
		},
	},
	unreadIcon: {
		height: "10px",
		width: "15px",
	},
});
export default notificationPageStyle;
