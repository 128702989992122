import React from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import landingPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/LandingPage/landingPageStyle.js";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(landingPageStyle);

export default function InquirySections() {
  const classes = useStyles();

  return (
    <div className={classes.informationSectionContainer}>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={12}>
          <h2 className={classes.cardTitle}>Oppty is waiting for you</h2>
          <h6 className={classes.cardDescription}>
            Any inquiries to raise up? We are here for you. Just drop an email
            to us
          </h6>
        </GridItem>

        {/* <GridItem xs={12} sm={3} md={3}>
          <Button
            className={classes.emailButton}
            href="mailto:inquiry@richdaleintles.com"
            color="primary"
            fullWidth
          >
            Email
          </Button>
        </GridItem> */}
      </GridContainer>
    </div>
  );
}
