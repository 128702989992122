import React, { useReducer } from "react";
import Popover from "views/Component/popover";
import { makeStyles } from "@material-ui/core/styles";
import { instanceUrl } from "configurations/configurations.js";

import dotBtn from "assets/images/dotBtn.png";
import avatar from 'assets/img/candidate_avatar.jpeg'
import ConnectionCardStyle from "../../assets/jss/material-kit-pro-react/components/connectionCardStyle";
import { Button } from "@material-ui/core";

const useStyles = makeStyles(ConnectionCardStyle);

function ConnectionCard({
  profilePic,
  firstName,
  lastName,
  headline,
  style,
  isDropdown = true,
  onClick,
  image,
  action,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className={classes.container} style={{ ...style, }}>
      <div className={classes.cardContainer}>
        <div className={classes.imgContainer} >
          {profilePic ? <img src={profilePic} alt="Profile Picture" className={classes.imgContainer} /> : <img src={avatar} alt="" className={classes.imgContainer} />}
        </div>
        <p className={classes.txt1}>{firstName}{' '}{lastName}</p>
        {headline ? <p className={classes.txt2}>{headline}</p> : <></>}
        <div className={classes.actionContainer} style={{ justifyContent: isDropdown ? "space-around" : "center", }} >
          <Button
            onClick={onClick}
            className={classes.btnContainer}
            style={{
              color: isDropdown ? "#6F52ED" : "white",
              backgroundColor: isDropdown ? "white" : "#687BFE",
              border: isDropdown ? "3px solid #6F52ED" : "0px",
              borderRadius: isDropdown ? 10 : 20,
              textTransform: "capitalize",
            }}
          >
            {action}
          </Button>
          {isDropdown && (
            <>
              <Button onClick={(event) => handleClick(event)} className={classes.dotContainer}>
                <img src={dotBtn} alt="" style={{ width: 30 }} />
              </Button>
              <Popover anchorEl={anchorEl} handleClose={() => handleClose()} />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ConnectionCard;
