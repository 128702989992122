import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
	departmentViewRoadmap,
	viewRoadmapRoleByRoleId,
} from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Department_Retail_Banking from "assets/img/Career_Roadmap/Banking_Wealth_Management/Department_Retail_Banking_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentRetailBanking() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Retail_Banking;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "rb",
				shape: "poly",
				coords: [79,376,70,377,66,381,62,385,59,393,60,432,62,439,65,444,70,448,76,450,178,451,187,449,193,446,198,441,200,433,200,396,198,386,192,380,187,377,180,375,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "392",
				shape: "poly",
				coords: [300,373,292,376,288,379,285,383,284,390,283,437,286,442,287,446,292,450,298,451,486,451,496,450,500,446,503,442,506,435,506,388,503,382,499,378,494,375,488,374,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "393",
				shape: "poly",
				coords: [742,374,733,376,729,378,726,382,724,390,724,437,727,445,730,448,736,451,744,452,926,452,936,450,940,448,943,446,946,440,946,388,943,381,940,378,934,374,929,373,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "394",
				shape: "poly",
				coords: [1184,374,1173,376,1170,378,1167,382,1166,389,1166,436,1169,444,1172,448,1176,450,1186,452,1372,452,1380,450,1384,446,1387,441,1388,433,1387,392,1385,382,1380,376,1375,374,1369,373,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "395",
				shape: "poly",
				coords: [1624,374,1616,376,1611,378,1608,384,1607,390,1607,438,1609,444,1612,447,1616,450,1624,452,1812,452,1819,450,1823,447,1826,443,1828,438,1828,389,1826,381,1822,378,1817,374,1812,373,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "rb") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo);
			dispatch(viewRoadmapRoleByRoleId(requestInfo));

			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
