import React from "react";
import {
	getCountryId,
	getFieldStudyId,
	getLocationId,
	getLocationIdByCountry,
	gaPageView,
} from "configurations/configurations.js";
import { useSelector, useDispatch } from "react-redux";
import { searchProfileInfo } from "configurations/redux/searchProfileSlice.js";
import classNames from "classnames";
import { Formik } from "formik";

import { makeStyles } from "@material-ui/core/styles";

import Parallax from "components/Parallax/Parallax.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import Autocomplete, {
	createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

//Page Components
import Header from "views/Component/Header.js";
import Footer from "views/Component/Footer.js";
import FooterVertical from "views/Component/FooterVertical.js";

import searchProfilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/SearchProfilePage/searchProfilePageStyle.js";
import SearchResultSection from "./SearchResultSection";
import CustomDialogFullScreen from "../../../components/CustomDialog/CustomDialogFullScreen.js";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import Accordion from "components/Accordion/Accordion";
import { Hidden } from "@material-ui/core";

const useStyles = makeStyles(searchProfilePageStyle);
export default function SearchProfilePage() {
	const [countryList, setCountryList] = React.useState([]);
	const [locationList, setLocationList] = React.useState([]);
	const [studyFieldList, setStudyFieldList] = React.useState([]);
	const userStatus = useSelector((state) => state.auth.user);
	const searchResult = useSelector((state) => state.searchProfile.searchResult);
	const [openDialogFullScreen, setOpenDialogFullScreen] = React.useState(false);
	const [countryChange, setCountryChange] = React.useState();
	const [expanded, setExpanded] = React.useState("null");

	const handleAccordionChange = (panel) => (event, newExpanded) => {
		console.log(newExpanded, panel);
		setExpanded(newExpanded ? panel : false);
	};
	const fetchCountryList = async () => {
		const res = await getCountryId();
		var list = [];
		list.push({ countryId: -1, countryName: "All" });
		for (var i = 0; i < res.data.payload.length; i++) {
			list.push({
				countryId: res.data.payload[i].countryId,
				countryName: res.data.payload[i].countryName,
			});
		}
		setCountryList(list);
	};
	// const fetchLocationList = async () => {
	// 	const res = await getLocationId();
	// 	setLocationList(res.data.payload);
	// };
	/* const fetchStudyFieldList = async () => {
		const res = await getFieldStudyId();
		var list = [];
		list.push({ studyFieldId: -1, studyFieldName: "All" });
		for (var i = 0; i < res.data.payload.length; i++) {
			list.push({
				studyFieldId: res.data.payload[i].studyFieldId,
				studyFieldName: res.data.payload[i].studyFieldName,
			});
		}
		setStudyFieldList(list);
	}; */

	React.useEffect(() => {
		gaPageView(window.location.pathname);
		fetchCountryList();
		// fetchLocationList();
		/* fetchStudyFieldList(); */
		const requestProfile = {
			acctId: userStatus.acctId,
			accessToken: localStorage.getItem("token"),
			email: userStatus.email,
			keyword: "",
			countryId: -1,
			locationId: -1,
			/* 	studyFieldId: -1, */
			lastRecordSize: 0,
			requestRecordSize: 8,
			genericSearch: false,
		};
		dispatch(searchProfileInfo(requestProfile));
		scrollToTop();
	}, []);

	const scrollToTop = () => {
		var timer = setInterval(() => {
			var jobPostResult = document.getElementById("profileResult");
			if (jobPostResult) {
				jobPostResult.scrollIntoView();
				window.scrollTo(0, 0);
				clearInterval(timer);
			}
		}, 100);
	};

	const classes = useStyles();
	const dispatch = useDispatch();

	React.useEffect(() => {
		// console.log(countryChange);
		if (countryChange) {
			const fetchLocationListByCountry = async (countryId) => {
				const res = await getLocationIdByCountry(countryId);
				var list = [];
				list.push({ locationId: -1, locationName: "All" });
				for (var i = 0; i < res.data.payload.length; i++) {
					list.push({
						locationId: res.data.payload[i].locationId,
						locationName: res.data.payload[i].locationName,
					});
				}
				setLocationList(list);
			};
			fetchLocationListByCountry(countryChange);
		}
	}, [countryChange]);

	const modalResize = () => {
		var timerApplied = setInterval(() => {
			var header = document.getElementById("searchProfile");
			var headerCard = document.getElementById("searchProfileCard");
			if (header && headerCard) {
				clearInterval(timerApplied);
				if (window.innerWidth >= 960) {
					header.style.height = "auto";
					if (window.innerHeight < header.clientHeight + 180) {
						header.style.height = window.innerHeight - 180 + "px";
						header.style.paddingRight = "5px";
						headerCard.style.paddingRight = "0";
					} else {
						header.style.height = "auto";
						header.style.paddingRight = "1px";
						headerCard.style.paddingRight = "15px";
					}
				} else {
					header.style.height = "auto";
					header.style.paddingRight = "5px";
					headerCard.style.paddingRight = "15px";
				}
			}
		}, 50);
		return () => clearInterval(timerApplied);
	};

	React.useEffect(() => {
		if (document.readyState === "complete") modalResize();
		window.addEventListener("resize", modalResize);
		return function cleanup() {
			window.removeEventListener("resize", modalResize);
		};
	});

	return (
		<React.Fragment>
			<div className={classes.bodyDiv}>
				<Header />
				<div className={classes.container}>
					<GridContainer justifyContent="center">
						<GridItem xs={12} sm={12} md={4} lg={3}>
							<Card className={classes.searchCard}>
								<CardBody
									className={classes.searchCardBody}
									id="searchProfileCard"
								>
									<div id="searchProfile" className={classes.searchCardDiv}>
										<Hidden mdUp>
											<Accordion
												name={"accordion"}
												expanded={expanded}
												onChange={handleAccordionChange("accordion")}
												className={classes.accordionStyle}
												rootClass={classes.accordionTitle}
												divRootClass={classes.accordionRoot}
												accordionDetailClass={classes.accordionDetailClass}
												activeColor="primary"
												collapses={[
													{
														title: (
															<GridContainer
																justifyContent="center"
																alignContent="center"
																alignItems="center"
																className={classes.accordionGrid}
															>
																<GridItem>
																	<h3
																		className={
																			classes.title + " " + classes.textCenter
																		}
																	>
																		Search For Your Talents
																	</h3>
																</GridItem>
															</GridContainer>
														),
														content: (
															<Formik
																initialValues={{
																	countryId: "",
																	locationId: "",
																	/* 	studyFieldId: "", */
																	keyword: "",
																}}
																onSubmit={(data) => {
																	const requestProfile = {
																		acctId: userStatus.acctId,
																		accessToken: localStorage.getItem("token"),
																		email: userStatus.email,
																		keyword: data.keyword,
																		countryId: data.countryId
																			? data.countryId
																			: -1,
																		locationId: data.locationId
																			? data.locationId
																			: -1,
																		/* studyFieldId: data.studyFieldId
													? data.studyFieldId
													: -1, */
																		lastRecordSize: 0,
																		requestRecordSize: isTablet ? 9 : 8,
																		genericSearch: false,
																	};
																	dispatch(searchProfileInfo(requestProfile));
																	scrollToTop();
																	setExpanded("null");
																	// if (isMobile) {
																	// 	setOpenDialogFullScreen(true);
																	// }
																}}
															>
																{({
																	values,
																	handleChange,
																	handleSubmit,
																	setFieldValue,
																}) => (
																	<form onSubmit={handleSubmit}>
																		<GridContainer>
																			<GridItem
																				xs={12}
																				sm={12}
																				md={12}
																				className={classes.autoCompleteSelect}
																			>
																				<TextField
																					label="Keyword"
																					id="keyword"
																					variant="outlined"
																					name="keyword"
																					values={values.keyword}
																					onChange={(event) => {
																						setFieldValue(
																							"keyword",
																							event.target.value
																						);
																					}}
																					fullWidth
																				/>
																			</GridItem>
																			<GridItem
																				xs={12}
																				sm={12}
																				md={12}
																				className={classes.autoCompleteSelect}
																			>
																				<Autocomplete
																					options={countryList}
																					value={
																						countryList.filter((item) => {
																							return (
																								item.countryId ===
																								values.countryId
																							);
																						})[0] || null
																					}
																					filterOptions={createFilterOptions({
																						matchFrom: "start",
																						stringify: (option) =>
																							option.countryName,
																					})}
																					getOptionSelected={(option, value) =>
																						option.countryId === value.countryId
																					}
																					getOptionLabel={(option) =>
																						option.countryName
																							? option.countryName
																							: ""
																					}
																					onChange={(event, value) => {
																						if (value) {
																							setFieldValue(
																								"countryId",
																								value.countryId
																							);
																							setCountryChange(value.countryId);
																						} else {
																							setFieldValue("countryId", "");
																							setFieldValue("locationId", "");
																						}
																					}}
																					id="countryId"
																					name="countryId"
																					renderInput={(params) => (
																						<TextField
																							{...params}
																							label={"Country"}
																							variant="outlined"
																						/>
																					)}
																				/>
																			</GridItem>
																			<GridItem
																				xs={12}
																				sm={12}
																				md={12}
																				className={classes.autoCompleteSelect}
																			>
																				<Autocomplete
																					options={locationList}
																					disabled={!values.countryId}
																					value={
																						locationList.filter((item) => {
																							return (
																								item.locationId ===
																								values.locationId
																							);
																						})[0] || null
																					}
																					getOptionSelected={(option, value) =>
																						option.locationid ===
																						value.locationId
																					}
																					getOptionLabel={(option) =>
																						option.locationName
																							? option.locationName
																							: ""
																					}
																					onChange={(event, value) => {
																						if (value) {
																							setFieldValue(
																								"locationId",
																								value.locationId
																							);
																						} else {
																							setFieldValue("locationId", "");
																						}
																					}}
																					id="locationId"
																					name="locationId"
																					renderInput={(params) => (
																						<TextField
																							{...params}
																							label={"Location"}
																							variant="outlined"
																						/>
																					)}
																				/>
																			</GridItem>
																			{/* <GridItem
														xs={12}
														sm={12}
														md={12}
														className={classes.gridHeight}
													>
														<Autocomplete
															options={studyFieldList}
															value={
																studyFieldList.filter((item) => {
																	return (
																		item.studyFieldId === values.studyFieldId
																	);
																})[0] || null
															}
															getOptionSelected={(option, value) =>
																option.studyFieldId === value.studyFieldId
															}
															getOptionLabel={(option) =>
																option.studyFieldName
																	? option.studyFieldName
																	: ""
															}
															onChange={(event, value) => {
																if (value) {
																	setFieldValue(
																		"studyFieldId",
																		value.studyFieldId
																	);
																} else {
																	setFieldValue("studyFieldId", "");
																}
															}}
															id="studyFieldId"
															name="studyFieldId"
															renderInput={(params) => (
																<TextField
																	{...params}
																	label={"Field of Study"}
																	variant="outlined"
																/>
															)}
														/>
													</GridItem> */}
																		</GridContainer>
																		<GridContainer>
																			<GridItem
																				xs={12}
																				sm={12}
																				md={12}
																				className={classes.searchBarButton}
																			>
																				<Button
																					onClick={handleSubmit}
																					className={classes.searchBarButton}
																					color="oppty"
																					fullWidth
																					round
																				>
																					<b>Search</b>
																				</Button>
																			</GridItem>
																		</GridContainer>
																	</form>
																)}
															</Formik>
														),
													},
												]}
											/>
										</Hidden>
										<Hidden smDown>
											<h3 className={classes.title + " " + classes.textCenter}>
												Search For Your Talents
											</h3>

											<Formik
												initialValues={{
													countryId: "",
													locationId: "",
													/* 	studyFieldId: "", */
													keyword: "",
												}}
												onSubmit={(data) => {
													const requestProfile = {
														acctId: userStatus.acctId,
														accessToken: localStorage.getItem("token"),
														email: userStatus.email,
														keyword: data.keyword,
														countryId: data.countryId ? data.countryId : -1,
														locationId: data.locationId ? data.locationId : -1,
														/* studyFieldId: data.studyFieldId
													? data.studyFieldId
													: -1, */
														lastRecordSize: 0,
														requestRecordSize: isTablet ? 9 : 8,
														genericSearch: false,
													};
													dispatch(searchProfileInfo(requestProfile));
													scrollToTop();
													if (isMobile) {
														setOpenDialogFullScreen(true);
													}
												}}
											>
												{({
													values,
													handleChange,
													handleSubmit,
													setFieldValue,
												}) => (
													<form onSubmit={handleSubmit}>
														<GridContainer>
															<GridItem
																xs={12}
																sm={12}
																md={12}
																className={classes.autoCompleteSelect}
															>
																<TextField
																	label="Keyword"
																	id="keyword"
																	variant="outlined"
																	name="keyword"
																	values={values.keyword}
																	onChange={(event) => {
																		setFieldValue(
																			"keyword",
																			event.target.value
																		);
																	}}
																	fullWidth
																/>
															</GridItem>
															<GridItem
																xs={12}
																sm={12}
																md={12}
																className={classes.autoCompleteSelect}
															>
																<Autocomplete
																	options={countryList}
																	value={
																		countryList.filter((item) => {
																			return (
																				item.countryId === values.countryId
																			);
																		})[0] || null
																	}
																	filterOptions={createFilterOptions({
																		matchFrom: "start",
																		stringify: (option) => option.countryName,
																	})}
																	getOptionSelected={(option, value) =>
																		option.countryId === value.countryId
																	}
																	getOptionLabel={(option) =>
																		option.countryName ? option.countryName : ""
																	}
																	onChange={(event, value) => {
																		if (value) {
																			setFieldValue(
																				"countryId",
																				value.countryId
																			);
																			setCountryChange(value.countryId);
																		} else {
																			setFieldValue("countryId", "");
																			setFieldValue("locationId", "");
																		}
																	}}
																	id="countryId"
																	name="countryId"
																	renderInput={(params) => (
																		<TextField
																			{...params}
																			label={"Country"}
																			variant="outlined"
																		/>
																	)}
																/>
															</GridItem>
															<GridItem
																xs={12}
																sm={12}
																md={12}
																className={classes.autoCompleteSelect}
															>
																<Autocomplete
																	options={locationList}
																	disabled={!values.countryId}
																	value={
																		locationList.filter((item) => {
																			return (
																				item.locationId === values.locationId
																			);
																		})[0] || null
																	}
																	getOptionSelected={(option, value) =>
																		option.locationid === value.locationId
																	}
																	getOptionLabel={(option) =>
																		option.locationName
																			? option.locationName
																			: ""
																	}
																	onChange={(event, value) => {
																		if (value) {
																			setFieldValue(
																				"locationId",
																				value.locationId
																			);
																		} else {
																			setFieldValue("locationId", "");
																		}
																	}}
																	id="locationId"
																	name="locationId"
																	renderInput={(params) => (
																		<TextField
																			{...params}
																			label={"Location"}
																			variant="outlined"
																		/>
																	)}
																/>
															</GridItem>
															{/* <GridItem
														xs={12}
														sm={12}
														md={12}
														className={classes.gridHeight}
													>
														<Autocomplete
															options={studyFieldList}
															value={
																studyFieldList.filter((item) => {
																	return (
																		item.studyFieldId === values.studyFieldId
																	);
																})[0] || null
															}
															getOptionSelected={(option, value) =>
																option.studyFieldId === value.studyFieldId
															}
															getOptionLabel={(option) =>
																option.studyFieldName
																	? option.studyFieldName
																	: ""
															}
															onChange={(event, value) => {
																if (value) {
																	setFieldValue(
																		"studyFieldId",
																		value.studyFieldId
																	);
																} else {
																	setFieldValue("studyFieldId", "");
																}
															}}
															id="studyFieldId"
															name="studyFieldId"
															renderInput={(params) => (
																<TextField
																	{...params}
																	label={"Field of Study"}
																	variant="outlined"
																/>
															)}
														/>
													</GridItem> */}
														</GridContainer>
														<GridContainer>
															<GridItem
																xs={12}
																sm={12}
																md={12}
																className={classes.searchBarButton}
															>
																<Button
																	onClick={handleSubmit}
																	className={classes.searchBarButton}
																	color="oppty"
																	fullWidth
																	round
																>
																	<b>Search</b>
																</Button>
															</GridItem>
														</GridContainer>
													</form>
												)}
											</Formik>
										</Hidden>
									</div>
								</CardBody>
							</Card>
						</GridItem>

						<GridItem
							xs={12}
							sm={12}
							md={8}
							lg={8}
							className={classes.searchGrid}
						>
							<SearchResultSection />
						</GridItem>
					</GridContainer>
				</div>
			</div>
			<Footer />
		</React.Fragment>
	);
}
