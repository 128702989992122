import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

// core components
import Button from "components/CustomButtons/Button.js";

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import GridItem from "components/Grid/GridItem.js";
import { isDesktop } from "react-device-detect";
import DialogActions from "@material-ui/core/DialogActions";
import Pagination from "../../../Component/PaginationWithPage.js";

import Close from "@material-ui/icons/Close";

import CoverImageCrop from "../../../Component/ImageCrop/CoverImageCrop.js";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js";

// import { createApi } from "unsplash-js";
import CoverPictureListCard from "./CoverPictureListCard.js";
import Backdrop from "../../../Component/Backdrop.js";
import { Formik } from "formik";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomSearchInput from "components/CustomInput/CustomSearchInput";
import IconLibrary from "views/Component/IconLibrary.js";
import { getBackgroundPictureTemplates } from "configurations/configurations.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
import { updateBackgroundPicture } from "configurations/redux/userSlice.js";
import { updateCorporateBackgroundPicture } from "configurations/redux/corpUserSlice.js";

const useStyles = makeStyles(profilePageStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

const CoverPictureTemplateSection = ({
	open,
	backgroundPic,
	backgroundPicFileName,
	backgroundTemplateFlag,
	user,
}) => {
	// console.log(user);
	const classes = useStyles();
	const dispatch = useDispatch();
	// const user = useSelector((state) => state.user);
	const [currentSize, setCurrentSize] = React.useState(0);
	const [coverPicList, setCoverPicList] = React.useState(null);
	const [scrollHeight, setScrollHeight] = React.useState(600);
	const [isSelected, setIsSelected] = React.useState(null);
	const [isDefault, setIsDefault] = React.useState(null);
	const [isLoading, setIsLoading] = React.useState(null);
	const [isEnd, setIsEnd] = React.useState(false);
	const [selectedCover, setSelectedCover] = React.useState();
	const bizId = useSelector((state) => state.business.bizId);
	const backgroundList = async () => {
		if (currentSize === 0) setIsLoading(true);
		const res = await getBackgroundPictureTemplates(currentSize, 7);
		console.log(res);
		// console.log(res.data.payload, backgroundPicFileName, user);
		console.log(res.data.payload, backgroundPicFileName);
		if (res.data.payload.length < 7) setIsEnd(true);
		if (coverPicList && coverPicList.length > 0) {
			var newArray = [];
			var deductCount = 0;
			if (
				backgroundPicFileName &&
				backgroundPic &&
				backgroundTemplateFlag === 1
			) {
				newArray.push({
					fileName: backgroundPicFileName,
					image: backgroundPic,
				});
			}
			for (var i = 0; i < coverPicList.length; i++) {
				if (coverPicList[i].fileName !== backgroundPicFileName)
					newArray.push(coverPicList[i]);
			}
			for (var i = 0; i < res.data.payload.length; i++) {
				if (res.data.payload[i].fileName !== backgroundPicFileName)
					newArray.push(res.data.payload[i]);
			}

			var finalArray = newArray
				.map((e) => e["fileName"])
				.map((e, i, final) => final.indexOf(e) === i && i)
				.filter((obj) => newArray[obj])
				.map((e) => newArray[e]);

			console.log(finalArray);
			setCoverPicList(finalArray);
			setCurrentSize(finalArray.length - 1);
		} else {
			var newArray = [];
			if (
				backgroundPicFileName &&
				backgroundPic &&
				backgroundTemplateFlag === 1
			) {
				newArray.push({
					fileName: backgroundPicFileName,
					image: backgroundPic,
				});
			}
			for (var i = 0; i < res.data.payload.length; i++) {
				if (res.data.payload[i].fileName !== backgroundPicFileName)
					newArray.push(res.data.payload[i]);
			}

			var finalArray = newArray
				.map((e) => e["fileName"])
				.map((e, i, final) => final.indexOf(e) === i && i)
				.filter((obj) => newArray[obj])
				.map((e) => newArray[e]);

			setCoverPicList(finalArray);
			setCurrentSize(finalArray.length - 1);
			setIsLoading(false);
		}
		if (backgroundPicFileName && backgroundTemplateFlag === 1) {
			setIsSelected(backgroundPicFileName);
			setIsDefault(backgroundPicFileName);
		}
		// setCoverPicList(res.data.payload);
	};

	const updateSelectedPic = () => {
		var response = {
			acctId: user.acctId,
			email: user.email,
			backgroundPic: isSelected,
			backgroundTemplateFlag: 1,
			accessToken: localStorage.getItem("token"),
			backgroundPicBase64: selectedCover,
			bizId: bizId,
		};
		console.log(response);
		if (bizId > 0) {
			dispatch(updateCorporateBackgroundPicture(response));
		} else {
			dispatch(updateBackgroundPicture(response));
		}
	};

	React.useEffect(() => {
		backgroundList();
	}, []);

	const modalResize = () => {
		// var timer = setInterval(() => {
		var modalResize = document.getElementById("templateCard");
		if (modalResize) {
			// clearInterval(timer);
			var maxSize = window.innerHeight - 74 - 120 - 70;
			if (maxSize < 0) maxSize = 0;
			modalResize.style.height = maxSize + "px";
			setScrollHeight(maxSize - 20);
			// } else {
			// 	setTimeout(() => {
			// 		clearInterval(timer);
			// 	}, 3000);
		}
		// }, 300);
		// return () => clearInterval(timer);
	};

	// React.useEffect(() => {
	// 	window.addEventListener("resize", modalResize);
	// });

	React.useEffect(() => {
		if (document.readyState === "complete") {
			setTimeout(() => {
				modalResize();
			}, 500);
		}
		window.addEventListener("resize", modalResize);
		return function cleanup() {
			window.removeEventListener("resize", modalResize);
		};
	});

	// React.useEffect(() => {
	// 	if (open) {
	// 		modalResize();
	// 	} else {
	// 		try {
	// 			window.removeEventListener("resize");
	// 		} catch (error) {}
	// 	}
	// 	return function cleanup() {
	// 		try {
	// 			window.removeEventListener("resize");
	// 		} catch (error) {}
	// 	};
	// }, [open]);

	const ResultCard =
		coverPicList &&
		coverPicList.map((item, index) => {
			return (
				<CoverPictureListCard
					key={item.fileName}
					item={item}
					isSelected={isSelected}
					isDefault={isDefault}
					setIsSelected={setIsSelected}
					setSelectedCover={setSelectedCover}
				/>
			);
		});

	return (
		<React.Fragment>
			{/* {isLoading ? <Backdrop /> : null} */}
			<Card className={classes.modalCardBorder + " " + classes.tabCardBorder}>
				<CardBody
					className={
						isDesktop
							? classes.smallModalBodyDesktop +
							  " " +
							  classes.modalBackground +
							  " " +
							  classes.modalBackgroundScroll +
							  " " +
							  classes.cropContainer
							: classes.smallModalBody +
							  " " +
							  classes.modalBackground +
							  " " +
							  classes.modalBackgroundScroll +
							  " " +
							  classes.cropContainer
					}
					id="templateCard"
				>
					<GridContainer justifyContent="center">
						<GridItem>
							<div className={classes.conversationSearch}>
								{isLoading ? (
									<CircularProgress className={classes.loadingProgress} />
								) : null}
								{coverPicList && coverPicList.length > 0 && (
									<InfiniteScroll
										dataLength={coverPicList.length}
										next={backgroundList}
										hasMore={!isEnd}
										// style={{
										// 	overflow: "hidden",
										// }}
										// scrollThreshold={0.8}
										height={scrollHeight}
										loader={
											<CircularProgress className={classes.loadingProgress} />
										}
										endMessage={null}
									>
										<div>{ResultCard}</div>
									</InfiniteScroll>
								)}
							</div>
						</GridItem>
					</GridContainer>
				</CardBody>
			</Card>

			<div className={classes.cardBodyFooter}>
				<Button
					round
					color="oppty"
					onClick={() => updateSelectedPic()}
					disabled={isSelected === isDefault ? true : false}
				>
					<b>UPLOAD</b>
				</Button>
			</div>
		</React.Fragment>
	);
};
export default CoverPictureTemplateSection;
