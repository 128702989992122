const pendingRequestStyle = (theme) => ({
  title: {
    color: "#000000",
    fontSize: "25px",
    fontWeight: "bold",
    marginTop: "50px",
    textAlign: "center",
    display: "flex",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
  },
  cardsContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "15px",
    flexWrap: "wrap",
    marginTop: "30px",
  },
});

export default pendingRequestStyle;
