import React from "react";
import Popover from "@mui/material/Popover";
import remove_circle from "assets/images/remove_circle.png";
import report from "assets/images/report.png";
import block from "assets/images/block.png";
import saved from "assets/images/saved.png";
import { makeStyles } from "@material-ui/core/styles";

import PopoverStyle from "assets/jss/material-kit-pro-react/components/popoverStyle";
const useStyles = makeStyles(PopoverStyle);

export default function Popover2({ anchorEl, handleClose }) {
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const classes = useStyles();
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <div className={classes.container}>
        <div className={classes.optContainer}>
          <img src={remove_circle} alt="" height={30} width={30} />
          <text className={classes.txt1}>Remove Connection</text>
        </div>
        <div className={classes.optContainer2}>
          <img
            src={block}
            alt=""
            height={23}
            width={23}
            style={{ marginLeft: 3 }}
          />
          <text className={classes.txt2}>Block User</text>
        </div>
        <div className={classes.optContainer2}>
          <img src={report} alt="" height={30} width={30} />
          <text className={classes.txt1}>Report User</text>
        </div>
        <div className={classes.optContainer2}>
          <img
            src={saved}
            alt=""
            height={23}
            width={23}
            style={{ marginLeft: 2 }}
          />
          <text className={classes.txt3}>Follow</text>
        </div>
      </div>
    </Popover>
  );
}
