import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import {
	departmentViewRoadmap,
	viewRoadmapRoleByRoleId,
} from "configurations/redux/roadmapSlice.js";
import { useDispatch, useSelector } from "react-redux";

// import opptyRoadMapPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/OpptyRoadMapPage/opptyRoadMapPageStyle.js";

import RoleInfoModal from "../RoleInfoModal.js";
import ImageMapper from "react-image-mapper";
import Department_Port_Sea_Transport from "assets/img/Career_Roadmap/Marine_Engineering/Department_Port_(Sea Transport)_1.jpg";

// const useStyles = makeStyles(opptyRoadMapPageStyle);

export default function DepartmentPortSeaTransport() {
	const dispatch = useDispatch();
	const [roleModal, setRoleModal] = React.useState(false);
	const onClose = () => {
		setRoleModal(false);
	};
	const userStatus = useSelector((state) => state.auth.user);

	const preFillColor = "rgba(0, 0, 0, 0)";
	const fillColor = "rgba(0, 0, 0, 0.07)";
	const strokeColor = "rgba(0, 0, 0, 0)";
	const URL = Department_Port_Sea_Transport;
	const MAP = {
		name: "my-map",
		areas: [
			{
				name: "pst",
				shape: "poly",
				coords: [85,381,78,384,73,387,69,393,66,400,67,448,69,455,73,460,78,464,86,466,192,466,198,464,204,460,208,453,210,446,210,402,208,392,204,388,198,383,190,382,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "294",
				shape: "poly",
				coords: [247,385,241,388,238,391,236,394,234,400,233,448,235,455,238,458,242,461,250,463,378,463,388,462,391,458,394,454,396,448,396,399,393,392,389,388,384,386,378,386,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "295",
				shape: "poly",
				coords: [600,385,591,388,588,391,584,394,582,400,582,446,584,455,587,459,592,461,598,463,728,464,736,461,741,457,743,453,745,447,744,400,743,393,740,390,734,386,727,385,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "296",
				shape: "poly",
				coords: [965,384,960,388,956,392,954,396,953,402,952,444,955,452,956,457,961,462,968,463,1102,463,1108,460,1111,457,1114,454,1115,448,1115,402,1113,394,1110,391,1106,387,1101,385,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "297",
				shape: "poly",
				coords: [1336,385,1330,389,1328,392,1325,398,1324,404,1324,447,1326,454,1328,458,1332,461,1338,463,1469,463,1481,459,1484,454,1486,451,1487,446,1487,404,1485,394,1482,390,1476,387,1471,386,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
			{
				name: "298",
				shape: "poly",
				coords: [1708,384,1701,387,1698,390,1695,395,1695,400,1695,450,1698,455,1700,458,1705,461,1710,462,1849,462,1856,459,1860,457,1862,453,1864,449,1864,400,1861,393,1858,389,1853,386,1848,385,],
				preFillColor: preFillColor,
				fillColor: fillColor,
				strokeColor: strokeColor,
			},
		],
	};

	function clicked(area) {
		console.log(area);
		if (area.name === "pst") {
			dispatch(departmentViewRoadmap(null));
		} else {
			const requestInfo = {
				acctId: userStatus.acctId,
				accessToken: localStorage.getItem("token"),
				email: userStatus.email,
				roleId: area.name,
			};
			console.log(requestInfo);
			dispatch(viewRoadmapRoleByRoleId(requestInfo));

			const viewport = document.querySelector('meta[name="viewport"]');
			if (viewport) {
				viewport.content = ("content_original", (viewport.content = "content"));
				viewport.content =
					("content",
					"user-scalable=yes, width=device-width minimum-scale=1, maximum-scale=1");
			}
			setRoleModal(true);
			if (viewport)
				viewport.content =
					("content", "user-scalable=yes, width=device-width initial-scale=1");
		}
	}

	function clickedOutside(evt) {
		// const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
		// document.getElementById("coordinateGet").value =
		// 	coords.x +
		// 	"," +
		// 	coords.y +
		// 	"," +
		// 	document.getElementById("coordinateGet").value;
	}
	return (
		<div>
			<ImageMapper
				src={URL}
				map={MAP}
				width={1920}
				// height={910}
				onClick={(area, evt) => clicked(area, evt)}
				onImageClick={(evt) => clickedOutside(evt)}
			/>
			<RoleInfoModal open={roleModal} onClose={onClose} />
		</div>
	);
}
