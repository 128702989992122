import React, { useEffect, useState } from 'react';

import Button from 'components/CustomButtons/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import profilePageStyle from 'assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js';
import IconLibrary from 'views/Component/IconLibrary';
import { Box, makeStyles } from '@material-ui/core';
import Pagination from 'views/Component/PaginationWithPage.js';
import Media from './Media';

const useStyles = makeStyles(profilePageStyle);

const MediaListModal = React.memo(({ modalOpen, toggle, mediaList, getMedia }) => {
  const classes = useStyles();
  const perPageCount = 8;
  const [mediaIsLoading, setMediaIsLoading] = useState(true);

  function paginateMedia(pageNumber) {
    setMediaIsLoading(true);
    const indexOfFirst = pageNumber * perPageCount - perPageCount;
    getMedia({ lastRecordSize: indexOfFirst, requestRecordSize: perPageCount }).then(() => {
      setMediaIsLoading(false);
    });
  }

  useEffect(() => {
    if (modalOpen) {
      paginateMedia(1);
    }
  }, [modalOpen]);

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal + ' ' + classes.modalGroupRoot,
      }}
      fullWidth
      maxWidth="sm"
      open={modalOpen}
      onClose={toggle}
    >
      <DialogTitle className={classes.modalTitle}>
        <Button simple className={classes.modalCloseButton} key="close" aria-label="Close" onClick={toggle}>
          <IconLibrary.Close />
        </Button>

        <div className={classes.title + ' ' + classes.titleAlign}>Media</div>
      </DialogTitle>
      <DialogContent>
        <Media
          mediaList={mediaList}
          rowHeight={140}
          minItemWidth={180}
          mediaIsLoading={mediaIsLoading}
          loadersCount={perPageCount}
        />
      </DialogContent>
      {mediaList.length > 0 && (
        <Box width="100%">
          <Pagination totalPosts={mediaList[0].totalCount} postsPerPage={perPageCount} paginate={paginateMedia} />
        </Box>
      )}
    </Dialog>
  );
});
export default MediaListModal;
