import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Header from "views/Component/Header";

import Footer from "views/Component/Footer.js";
import LeftSidebar from "views/Component/LeftSidebar.js";
import clsx from "clsx";
import axios from "axios";
import { decryptData } from "configurations/configurations.js";

import { makeStyles, Button, Modal, Box } from "@material-ui/core";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import {
	instanceUrl,
	header,
	getFollowHashtagByAcctId,
	getFollowProfileByAcctId,
} from "configurations/configurations";
import { getNetworkCount } from "configurations/redux/manageConnectionSlice.js";

import noFollowing from "assets/img/no-following.png";
import avatar from "assets/img/candidate_avatar.jpeg";
import dotBtn from "assets/images/dotBtn.png";
import Hidden from "@material-ui/core/Hidden";
import ConnectionPageStyle from "assets/jss/material-kit-pro-react/components/connectionPageStyle";
import ProfileComponent from "./ProfileComponent";
import HashtagComponent from "./HashtagComponent";
import RightSideBarUserList from "views/Component/RightSideBarUserList";
const useStyles = makeStyles(ConnectionPageStyle);

const FollowingPage = ({ match }) => {
	const styles = useStyles();
	const dispatch = useDispatch();
	const user = useSelector((state) => state.auth.user);
	const followCount = useSelector(
		(state) => state.manageConnection.followProfileCount
	);
	const hashtagCount = useSelector(
		(state) => state.manageConnection.followHashtagCount
	);
	const isStatusUpdated = useSelector(
		(state) => state.manageConnection.isStatusUpdated
	);

	const [optionToShow, setOption] = useState("hashtags");
	const [followingHashtagsList, setFollowingHashtagsList] = useState([]);
	const [followingProfilesList, setFollowingProfilesList] = useState([]);
	const [
		isUnfollowHashtagDialogOpen,
		setIsUnfollowHashtagDialogOpen,
	] = useState(false);
	const [
		isUnfollowProfileDialogOpen,
		setIsUnfollowProfileDialogOpen,
	] = useState(false);
	const [dialogDetails, setDialogDetails] = useState(null);

	useEffect(() => {
		let mounted = true;
		/* 
		getFollowHashtagByAcctId({
			email: user.email,
			acctId: user.acctId,
			keyword: "",
			lastRecordSize: 0,
			requestRecordSize: 10,
			accessToken: localStorage.getItem("token"),
		})
			.then((res) => {
				if (!res.data.error) {
					console.log(res.data.message);
					if (mounted)
						setFollowingHashtagsList(res.data.payload.followHashtagList);
				} else {
					console.log(res.data.message);
				}
			})
			.catch((err) => console.log(err));

		getFollowProfileByAcctId({
			email: user.email,
			acctId: user.acctId,
			keyword: "",
			lastRecordSize: 0,
			requestRecordSize: 10,
			accessToken: localStorage.getItem("token"),
		})
			.then((res) => {
				if (!res.data.error) {
					console.log(res.data.message);
					if (mounted)
						setFollowingProfilesList(res.data.payload.followProfileList);
				} else {
					console.log(res.data.message);
				}
			})
			.catch((err) => console.log(err)); */
		const request = {
			acctId: decryptData(match.params.acctId),
			accessToken: localStorage.getItem("token"),
			email: user.email,
		};
		dispatch(getNetworkCount(request));

		return () => (mounted = false);
	}, [isStatusUpdated]);

	// const handleCancel = () => {
	//   setUnfollowDialog({ ...unfollowDialog, isOpen: false });
	// };

	const confirmUnfollow = (obj, type) => {
		if (type === "hashtag") {
			setDialogDetails({
				name: obj.hashtagName,
			});
			setIsUnfollowHashtagDialogOpen(true);
		} else if (type === "profile") {
			setDialogDetails({
				name: `${obj.firstName}${obj.middleName ? " " + obj.middleName : ""}${
					" " + obj.lastName
				}`,
			});
			setIsUnfollowProfileDialogOpen(true);
		}
	};

	// const handleUnfollowRequest = () => { };
	const FollowTab = () => {
		return (
			<div className={styles.tabContainer} style={{ marginTop: "10px" }}>
				<div
					className={styles.contentContainer}
					onClick={() => setOption("hashtags")}
				>
					<p
						style={{
							color: optionToShow == "hashtags" ? "#6F52ED" : "#92929D",
						}}
						className={styles.tabTxt1}
					>
						Hashtags
					</p>
					<span className={styles.tabTxt2}>{hashtagCount}</span>

					{optionToShow === "hashtags" && (
						<div className={styles.tabIndicator} />
					)}
				</div>

				<div
					className={styles.contentContainer}
					onClick={() => setOption("profiles")}
				>
					<p
						style={{
							color: optionToShow == "profiles" ? "#6F52ED" : "#92929D",
						}}
						className={styles.tabTxt1}
					>
						Profiles
					</p>
					<span className={styles.tabTxt2}>{followCount}</span>

					{optionToShow === "profiles" && (
						<div className={styles.tabIndicator} />
					)}
				</div>
			</div>
		);
	};
	return (
		<>
			<Header />
			<div className={styles.body}>
				<div className={styles.pageHeader}>
					<div className={styles.container}>
						<GridContainer spacing={2}>
							<Hidden smDown>
								<GridItem md={3} lg={2}>
									<LeftSidebar />
								</GridItem>
							</Hidden>
							<GridItem xs={12} sm={12} md={9} lg={8}>
								<FollowTab />
								{optionToShow === "hashtags" && <HashtagComponent />}
								{optionToShow === "profiles" && <ProfileComponent />}
							</GridItem>
							<Hidden mdDown>
								<GridItem md={2}>
									<RightSideBarUserList />
								</GridItem>
							</Hidden>
						</GridContainer>

						<Modal open={isUnfollowHashtagDialogOpen && dialogDetails}>
							<Box className={styles.dialogContainer}>
								<div className={styles.dialog}>
									<h2 className={styles.dialogTitle}>
										Unfollow {dialogDetails?.name}?
									</h2>
									<p className={styles.dialogText}>
										This action cannot be undone.
									</p>

									<div className={styles.dialogActions}>
										<Button
											onClick={() => {
												setIsUnfollowHashtagDialogOpen(false);
												setDialogDetails(null);
											}}
										>
											Cancel
										</Button>
										<Button
											onClick={() => {
												setIsUnfollowHashtagDialogOpen(false);
												setDialogDetails(null);
											}}
										>
											Confirm
										</Button>
									</div>
								</div>
							</Box>
						</Modal>

						<Modal open={isUnfollowProfileDialogOpen && dialogDetails}>
							<Box className={styles.dialogContainer}>
								<div className={styles.dialog}>
									<h2 className={styles.dialogTitle}>
										Unfollow {dialogDetails?.name}?
									</h2>
									<p className={styles.dialogText}>
										{dialogDetails?.name} will not be notified.
									</p>

									<div className={styles.dialogActions}>
										<Button
											onClick={() => {
												setIsUnfollowProfileDialogOpen(false);
												setDialogDetails(null);
											}}
										>
											Cancel
										</Button>
										<Button
											onClick={() => {
												setIsUnfollowProfileDialogOpen(false);
												setDialogDetails(null);
											}}
										>
											Confirm
										</Button>
									</div>
								</div>
							</Box>
						</Modal>

						<Footer />
					</div>
				</div>
			</div>
		</>
	);
};

export default FollowingPage;
