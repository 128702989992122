import React from "react";
// import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { instanceUrl } from "configurations/configurations.js";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.js";
import axios from "axios";

import style from "assets/jss/material-kit-pro-react/modalStyle.js";
import requestStyle from "assets/jss/material-kit-pro-react/requestModalStyle";
import IconLibrary from "views/Component/IconLibrary";
import cross from "assets/images/cross.png";
import tick from "assets/images/round_tick.png";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function RequestDailog(children) {
  const useStyles = makeStyles(style);
  const useStyles2 = makeStyles(requestStyle);
  const userStatus = useSelector((state) => state.auth.user);

  const classes = useStyles();
  const classes2 = useStyles2();
  const { confirmDialog, setConfirmDialog } = children;
  const [isCancelled, setIsCancelled] = React.useState("cancel");

  const cancelRequest = async () => {
    try {
      const res = await axios.put(
        `${instanceUrl}/api/um/update-connection-profile-status`, {
        email: userStatus.email,
        acctId: userStatus.acctId,
        accessToken: localStorage.getItem(
          "token"
        ),
        connId: confirmDialog.connId,
        connStatusId: 4

      });
      if (res.status == 201) {
        setIsCancelled("success")
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("unmount caused", error);
      } else {
        throw error;
      }
    }
  };


  return (
    <React.Fragment>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes2.modalLoader,
        }}
        className={classes.modalIndex}
        open={confirmDialog.isOpen}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {isCancelled == "cancel" && (
          <div className={classes2.contentContainer}>
            <div>
              <text className={classes2.title}>Cancel Connection Request?</text>
              <div className={classes2.btnContainer}>
                <text
                  className={classes2.cancel}
                  onClick={() =>
                    setConfirmDialog({ ...confirmDialog, isOpen: false })
                  }
                >
                  NO
                </text>
                <div
                  className={classes2.yesBtn}
                  onClick={() => {
                    cancelRequest()
                  }}
                >
                  <text className={classes2.yes}>YES</text>
                </div>
              </div>
            </div>
          </div>
        )}

        {isCancelled == "success" && (
          <div className={classes2.contentContainer}>
            <img
              src={cross}
              alt=""
              height={24}
              width={24}
              className={classes2.img}
              onClick={() => {
                setConfirmDialog({ ...confirmDialog, isOpen: false });
                setIsCancelled("cancel");
              }}
            />
            <img src={tick} alt="" height={75} width={75} />
            <text className={classes2.txt2}>Your connection request has</text>
            <text className={classes2.txt3}>been cancelled.</text>
          </div>
        )}
      </Dialog>
    </React.Fragment>
  );
}
