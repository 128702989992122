import headerLinksStyle from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";
import {
	mlAuto,
	grayColor,
	opptyDesignColor,
	blackColor,
} from "assets/jss/material-kit-pro-react.js";

const headerStyle = (theme) => ({
	mlAuto: {
		...mlAuto,
		[theme.breakpoints.down("md")]: {
			overflow: "auto",
			height: "auto",
		},
		[theme.breakpoints.up("md")]: {
			float: "right",
		},
	},
	textAutoComplete: {
		width: "300px",
		[theme.breakpoints.down("xs")]: {
			/* "& .MuiAutocomplete-input": {
				display: "none",
			},
			"& .MuiAutocomplete-input": {
				border: "0",
			}, */
			width: "100%",
			"& .MuiAutocomplete-listbox": {
				width: "100%",
			},
		},
		transition: "max-width 1s ease-in-out 1s",
	},

	list: {
		[theme.breakpoints.up("md")]: {
			WebkitBoxAlign: "center",
			MsFlexAlign: "center",
			alignItems: "center",
			WebkitBoxOrient: "horizontal",
			WebkitBoxDirection: "normal",
			MsFlexDirection: "row",
			flexDirection: "row",
		},
		[theme.breakpoints.down("md")]: {
			display: "block",
			margin: 0,
		},

		marginTop: "0px",
		display: "flex",
		paddingLeft: "0",
		marginBottom: "0",
		listStyle: "none",
		padding: "0",
	},
	listItem: {
		float: "left",
		color: "#99B2C6",
		alignItems: "center",
		position: "relative",
		display: "block",
		width: "100px",
		margin: "0",

		"&:hover,&:focus": {
			color: blackColor,
		},
		[theme.breakpoints.down("sm")]: {
			float: "none",
			"& ul": {
				/* maxHeight: "400px", */
				overflow: "scroll",
			},
			width: "100%",
			// "&:not(:last-child)": {
			// 	"&:after": {
			// 		width: "calc(100% - 30px)",
			// 		content: '""',
			// 		display: "block",
			// 		height: "1px",
			// 		marginLeft: "15px",
			// 		backgroundColor: grayColor[14],
			// 	},
			// },
		},
	},
	navLink: {
		color: "#C0CEFF",
		position: "relative",
		padding: "0px 0.9375rem",
		fontWeight: "400",
		alignItems: "center",
		textAlign: "center",
		fontSize: "12px",
		/* textTransform: "uppercase", */
		lineHeight: "20px",
		textDecoration: "none",
		margin: "0px",
		display: "inline-flex",
		"&:hover,&:focus": {
			color: "#6F52ED",
		},
		"& .fab,& .far,& .fal,& .fas,& .material-icons": {
			position: "relative",
			top: "2px",
			marginTop: "-4px",
			marginRight: "4px",
			marginBottom: "0px",
			fontSize: "1.25rem",
		},
		"& svg,& path": {
			margin: "0 !important",
			width: "30px",
			height: "30px",
		},

		[theme.breakpoints.down("sm")]: {
			width: "calc(100% - 30px)",
			// marginLeft: "15px",
			// marginBottom: "8px",
			// padding: "0.9375rem",
			// marginTop: "8px",
			textAlign: "center",
			/* "& svg": {
		width: "30px",
	height: "30px",
			}, */
			"& > span:first-child": {
				justifyContent: "flex-start",
			},
			"& svg,& path,& img": {
				marginRight: "4px !important",
			},
			margin: "0",
			paddingTop: "5px",
		},
	},
	bizNavLink: {
		color: "#C0CEFF",
		position: "relative",
		padding: "0px 0.9375rem",
		fontWeight: "400",
		alignItems: "center",
		textAlign: "center",
		fontSize: "12px",
		/* textTransform: "uppercase", */
		lineHeight: "20px",
		textDecoration: "none",
		margin: "0px",
		display: "inline-flex",
		"&:hover,&:focus": {
			color: "#fff",
		},
		"& .fab,& .far,& .fal,& .fas,& .material-icons": {
			position: "relative",
			top: "2px",
			marginTop: "-4px",
			marginRight: "4px",
			marginBottom: "0px",
			fontSize: "1.25rem",
		},
		"& svg,& path": {
			margin: "0 !important",
			width: "30px",
			height: "30px",
		},

		[theme.breakpoints.down("sm")]: {
			// width: "calc(100% - 30px)",
			// marginLeft: "15px",
			// marginBottom: "8px",
			// padding: "0.9375rem",
			// marginTop: "8px",
			textAlign: "center",
			/* "& svg": {
		width: "30px",
	height: "30px",
			}, */
			// "& > span:first-child": {
			// 	justifyContent: "flex-start",
			// },
			"& svg,& path,& img": {
				marginRight: "4px !important",
			},
			margin: "0",
			paddingTop: "5px",
		},
	},
	drawerButton: {
		"& svg": {
			fontSize: "24px",
		},
		[theme.breakpoints.up("lg")]: {
			display: "none",
		},
		fontSize: "14px",
		textTransform: "capitalize",
	},
	headerButton: {
		"& svg": {
			fontSize: "24px",
		},
		[theme.breakpoints.down("sm")]: {
			display: "block",
		},
		fontSize: "14px",
		textTransform: "capitalize",
	},
	badgeColor: {
		backgroundColor: opptyDesignColor,
		color: "white",
		top: "5px",
		right: "0px",
		[theme.breakpoints.down("md")]: {
			right: "5px",
		},
	},
	bizBadgeColor: {
		backgroundColor: "white",
		color: opptyDesignColor,
		top: "5px",
		right: "0px",
		[theme.breakpoints.down("md")]: {
			right: "5px",
		},
	},
	selectedIcon: {
		color: "#6F52ED",
		padding: "0px !important",
		fontWeight: "bold",
		"&:hover": {
			color: "#6F52ED",
		},
	},
	bizSelectedIcon: {
		color: "#fff",
		padding: "0px !important",
		fontWeight: "bold",
		"&:hover": {
			color: "#C0CEFF",
		},
	},
	profilePicture: {
		width: "30px",
		height: "30px",
		borderRadius: "50%",
		border: "2px solid" + grayColor[0],
		[theme.breakpoints.down("md")]: {
			width: "26px",
			height: "26px",
		},
	},
	navButton: {
		position: "relative",
		fontWeight: "400",
		fontSize: "14px",
		textTransform: "uppercase",
		lineHeight: "20px",
		textDecoration: "none",
		margin: "0px",
		display: "inline-flex",
		[theme.breakpoints.down("md")]: {
			// width: "calc(100% - 30px)",
			// marginLeft: "15px",
			marginBottom: "5px",
			marginTop: "5px",
			textAlign: "center",
			"& > span:first-child": {
				justifyContent: "flex-start",
			},
		},
		/* "& $icons": {
		marginRight: "3px",
		}, */
	},
	textDeco: {
		width: "100%",
		textDecoration: "none",
		color: "inherit",
		"&:hover,&:focus": {
			color: "inherit",
		},
	},
	cardFrame: {
		margin: "0",
		// [theme.breakpoints.up("md")]: {
		boxShadow: "none",
		// },
	},
	cardBody: {
		display: "flex",
		alignItems: "center",
		padding: "0",
		/* 
		"@media (max-width: 1279px)": {
			flexDirection: "column",
			alignItems: "start",
		}, */
	},
	searchBarContainer: {
		display: "flex",
		alignItems: "center",
		border: "solid 1px #979797",
		borderRadius: "15px",
		width: "30vw",
		justifyContent: "space-between",
		[theme.breakpoints.down("sm")]: {
			width: "auto",
		},
		// [theme.breakpoints.down("md")]: {
		// 	display: "none",
		// },

		"& .MuiTextField-root": {
			width: "250px",

			"& .MuiOutlinedInput-notchedOutline": {
				border: "none",
			},
		},

		"& button": {
			border: "solid 1px #6F52ED",
			borderRadius: "10px",
			backgroundColor: "#6F52ED",
			width: "35px",
			height: "34px",
			marginRight: "10px",

			"& svg": {
				width: "14px",
				height: "14px",
			},
		},
	},
	searchBar: {
		height: "40px",
		width: "30vw",
	},
	listPadding: {
		color: "#99B2C6",
		padding: "0 10px",

		"&:hover,&:focus": {
			color: blackColor,
		},

		"& button > span > div > div > div ": {
			padding: "0",
			[theme.breakpoints.down("md")]: {
				maxWidth: "none",
			},
		},
		[theme.breakpoints.down("md")]: {
			float: "none",
			"& ul": {
				margin: 0,
				maxHeight: "400px",
				overflow: "scroll",
			},
			width: "100%",
			padding: "8px 5px",
		},
	},

	autoComplete: {
		"& .MuiInputLabel-outlined": {
			transform: "translate(14px, 25px) scale(1)",
		},
		"& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
			transform: "translate(14px, -6px) scale(0.75)",
		},
	},
	footerNav: {
		position: "fixed",
		left: 0,
		bottom: 0,
		width: "100%",
		textAlign: "center",
		alignItems: "center",
		margin: 0,
		border: "2px solid #D0DDE7",
		zIndex: 9999,
	},
});
export default headerStyle;
