import React from "react";
import { Typography, makeStyles, Button } from "@material-ui/core";

import suggestedGroupCardStyles from "assets/jss/material-kit-pro-react/views/componentStyle/suggestedGroups/suggestedGroupCardStyles.js"

const useStyles = makeStyles(suggestedGroupCardStyles);

const SuggestedGroupCard = (props) => {
  const styles = useStyles();

  return (
    <Typography component="div" className={styles.main}>
      <Typography component="div" className={styles.groupImageContainer}>
        <img src={props.image} alt="" />
      </Typography>

      <Typography component="div" className={styles.contentContainer}>
        <Typography component="h3" className={styles.groupName}>
          {props.name}
        </Typography>
        <Typography component="div" className={styles.groupDetails}>
          <Typography component="p" className={styles.groupDescription}>
            {props.description}
          </Typography>
          <Typography component="p" className={styles.groupMembers}>
            <svg xmlns="http://www.w3.org/2000/svg" width="5.296" height="4.981" viewBox="0 0 5.296 4.981">
              <ellipse id="Oval" cx="2.648" cy="2.49" rx="2.648" ry="2.49" fill="#d5d5dc" />
            </svg>
            {props.members} Members
          </Typography>
        </Typography>

        <Button className={styles.joinBtn}>Join Group</Button>
      </Typography>

    </Typography>
  )
}

export default SuggestedGroupCard;