import React from "react";
import AppBar from "@mui/material/AppBar";
import { Button } from "@material-ui/core";
import Toolbar from "@mui/material/Toolbar";
import { useSelector, useDispatch } from "react-redux";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Link, useLocation, useHistory, Redirect } from "react-router-dom";

import searchPageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/SearchPage/searchPageStyle";
import { makeStyles } from "@material-ui/core/styles";
import IconLibrary from "views/Component/IconLibrary";
import IconButton from "@mui/material/IconButton";
import { saveOption } from "configurations/redux/genericSearchSlice.js";

import { Formik } from "formik";
import Autocomplete, {
	createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import {
	getCountryId,
	getFieldStudyId,
	getLocationId,
	getLocationIdByCountry,
} from "configurations/configurations.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import SelectOption from "react-select";
import FilterDrawerOption from "./FilterDrawer";

const useStyles = makeStyles(searchPageStyle);
function FilterComponent({ match }) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const history = useHistory();
	const option = useSelector((state) => state.genericSearch.option);
	const keyword = useSelector((state) => state.genericSearch.keyword);
	const auth = useSelector((state) => state.auth);

	const [drawer, setDrawer] = React.useState(false);

	const [countryList, setCountryList] = React.useState([]);
	const [locationList, setLocationList] = React.useState([]);
	const [countryChange, setCountryChange] = React.useState();
	const connectionSelectStyles = {
		option: (provided, state) => ({
			...provided,
			fontWeight: 500,
			fontSize: "14px",
			backgroundColor: state.isSelected ? "#687bfe" : "white",
			color: state.isSelected ? "white" : "black",

			color: "black",
			cursor: "pointer",
		}),
		input: (provided, state) => ({
			...provided,
			fontWeight: 700,
			fontSize: "14px",
		}),
		singleValue: (provided, state) => ({
			...provided,
			fontWeight: 500,
			fontSize: "14px",
		}),
		placeholder: (provided, state) => ({
			...provided,
			fontWeight: 500,
			fontSize: "14px",
		}),
		control: (provided, state) => ({
			...provided,
			height: "40px",
		}),
		noOptionsMessage: (provided, state) => ({
			...provided,
			fontWeight: 500,
			fontSize: "14px",
		}),
		loadingMessage: (provided, state) => ({
			...provided,
			fontWeight: 500,
			fontSize: "14px",
		}),
		menu: (provided, state) => ({ ...provided, zIndex: 9999 }),
	};
	const fetchCountryList = async () => {
		const res = await getCountryId();
		var list = [];
		list.push({ value: -1, label: "All" });
		for (var i = 0; i < res.data.payload.length; i++) {
			list.push({
				value: res.data.payload[i].countryId,
				label: res.data.payload[i].countryName,
			});
		}
		setCountryList(list);
	};
	const fetchLocationListByCountry = async (countryId) => {
		const res = await getLocationIdByCountry(countryId);
		var list = [];
		list.push({ value: -1, label: "All" });
		for (var i = 0; i < res.data.payload.length; i++) {
			list.push({
				value: res.data.payload[i].locationId,
				label: res.data.payload[i].locationName,
			});
		}
		setLocationList(list);
	};

	const FilterDrawer = () => {
		return (
			<SwipeableDrawer
				className={classes.drawer}
				open={drawer}
				disableEnforceFocus
				anchor={"right"}
				onOpen={() => {
					setDrawer(true);
				}}
				onClose={() => {
					setDrawer(false);
				}}
			>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						top: 0,
					}}
				>
					<h4 className={classes.title} style={{ marginLeft: "10px" }}>
						Filter Option
					</h4>
					<IconButton
						onClick={() => {
							setDrawer(false);
						}}
					>
						<IconLibrary.Close width={18} height={18} />
					</IconButton>
				</div>

				<div
					style={{
						borderTop: "1px solid rgba(0,0,0,.15)",
						display: "flex",
						flexFlow: "row nowwrap",
						overflow: "hidden",

						height: "100%",
						flexDirection: "column",
					}}
				>
					<FilterDrawerOption match={match} setDrawer={setDrawer} />
				</div>
			</SwipeableDrawer>
		);
	};
	const FilterOption = () => {
		return (
			<FormControl>
				<Select
					value={option ? option : match.path}
					className={classes.select}
					onChange={(event, value) => {
						console.log(event.target.value);
					}}
				>
					<MenuItem
						onClick={() => {
							const response = {
								option: auth.isPublic
									? "/public-search-page-people"
									: "/search-page-people",
							};
							dispatch(saveOption(response));

							history.replace(
								auth.isPublic
									? "/public-search-page-people"
									: "/search-page-people"
							);
						}}
						value={
							auth.isPublic
								? "/public-search-page-people"
								: "/search-page-people"
						}
					>
						People
					</MenuItem>
					<MenuItem
						onClick={() => {
							const response = {
								option: auth.isPublic
									? "/public-search-page-job"
									: "/search-page-job",
							};
							dispatch(saveOption(response));
							history.replace(
								auth.isPublic ? "/public-search-page-job" : "/search-page-job"
							);
						}}
						value={
							auth.isPublic ? "/public-search-page-job" : "/search-page-job"
						}
					>
						Job
					</MenuItem>
					<MenuItem
						onClick={() => {
							const response = {
								option: auth.isPublic
									? "/public-search-page-article"
									: "/search-page-article",
							};
							dispatch(saveOption(response));
							history.replace(
								auth.isPublic
									? "/public-search-page-article"
									: "/search-page-article"
							);
						}}
						value={
							auth.isPublic
								? "/public-search-page-article"
								: "/search-page-article"
						}
					>
						Article
					</MenuItem>
					<MenuItem
						onClick={() => {
							const response = {
								option: auth.isPublic
									? "/public-search-page-post"
									: "/search-page-post",
							};
							dispatch(saveOption(response));
							history.replace(
								auth.isPublic ? "/public-search-page-post" : "/search-page-post"
							);
						}}
						value={
							auth.isPublic ? "/public-search-page-post" : "/search-page-post"
						}
					>
						Post
					</MenuItem>
				</Select>
			</FormControl>
		);
	};
	return (
		<AppBar className={classes.appBarStyle} position="fixed">
			<Toolbar>
				<FilterOption />
				<Button
					onClick={() => {
						setDrawer(true);
					}}
					className={classes.filterButton}
				>
					Filter
				</Button>
			</Toolbar>
			<FilterDrawer />
		</AppBar>
	);
}

export default React.memo(FilterComponent);
