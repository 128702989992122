import React from "react";
import { searchProfileInfo } from "configurations/redux/searchProfileSlice.js";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Header from "views/Component/Header.js";
import FilterComponent from "views/Page/SearchPage/FilterComponent.js";

import LeftSidebar from "views/Component/LeftSidebar.js";
import Hidden from "@material-ui/core/Hidden";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import searchProfilePageStyle from "assets/jss/material-kit-pro-react/views/pageStyle/SearchPage/searchProfilePageStyle.js";
import PeopleComponentInPage from "./PeopleComponentInPage";
const useStyles = makeStyles(searchProfilePageStyle);

export default function SearchPeoplePage({ match }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const searchParams = useSelector((state) => state.searchProfile.searchParams);
	const userStatus = useSelector((state) => state.auth.user);
	const auth = useSelector((state) => state.auth);

	React.useEffect(() => {
		const requestProfile = {
			acctId: auth.isPublic ? 0 : userStatus.acctId,
			accessToken: auth.isPublic
				? auth.publicToken
				: localStorage.getItem("token"),
			email: auth.isPublic ? auth.publicEmail : userStatus.email,
			keyword: searchParams.keyword,
			countryId: searchParams.countryId,
			locationId: searchParams.locationId,
			lastRecordSize: 0,
			requestRecordSize: 10,
			genericSearch: true,
		};
		dispatch(searchProfileInfo(requestProfile));
	}, []);
	return (
		<div>
			<Header />
			<FilterComponent match={match} />
			<div className={classes.filterBody}>
				<div className={classes.pageHeader} id="searchResultPeople">
					<div className={classes.container}>
						<GridContainer spacing={2}>
							<Hidden smDown>
								<GridItem md={3}>
									{auth.isAuthenticated && <LeftSidebar />}
								</GridItem>
							</Hidden>
							<GridItem xs={12} sm={12} md={9}>
								<PeopleComponentInPage />
							</GridItem>
							<Hidden mdDown>
								<GridItem md={2}></GridItem>
							</Hidden>
						</GridContainer>
					</div>
				</div>
			</div>
		</div>
	);
}
