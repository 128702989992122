import React, { useState } from 'react';

import AddNewWorkExperienceCard from './AddNewWorkExperirenceCard';
import SingleWorkExperienceCard from '../../WorkExperience/SingleWorkExperienceCard';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles, Typography, Button } from '@material-ui/core';

import noWorkExperiencePic from 'assets/img/no-work-experience.png';
import editWorkExperienceStyles from 'assets/jss/material-kit-pro-react/views/componentStyle/profileView/editProfile/workExperience/editWorkExperienceStyles';
import ExperienceCardSection from './ExperienceCardSection.js';
import EditExperienceModal from './EditExperienceModal.js';

import { activateEditType } from 'configurations/redux/editProfileModalSlice';
import ProfileStrengthTip from 'views/Page/ProfilePage/ProfileSection/ProfileStrengthTip';

const EditWorkExperience = props => {
  const styles = makeStyles(editWorkExperienceStyles)();
  const dispatch = useDispatch();
  const { match, workExperience, masterFunction, saveDetailModalProps, onCancelChangesConfirm, setIsChanged } = props;

  const [formOpen, setFormOpen] = useState(false);
  const [recordId, setRecordId] = React.useState();
  const [expRecord, setExpRecord] = React.useState();
  const experienceRecords = useSelector(state => state.user.experienceRecords);

  const type = useSelector(state => state.editProfileModal.type);
  const extension = useSelector(state => state.editProfileModal.extension);
  const isShow = useSelector(state => state.editProfileModal.isShow);
  const isTabShow = useSelector(state => state.editProfileModal.isTabShow);

  return (
    <Typography component="div">
      {props.masterExtension && <ProfileStrengthTip extension={props.masterExtension} />}
      <Typography component="div" className={styles.addNewBtnContainer}>
        <Button
          className={styles.addNewBtn}
          onClick={() => {
            const response = {
              type: 'EDIT_PROFILE',
              tabType: 'EXPERIENCE',
              isTabShow: true,
              extension: {
                recordId: -1,
              },
            };
            dispatch(activateEditType(response));
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
            <path
              id="icon-3_path"
              data-name="icon-3 path"
              d="M8.468,17a2.117,2.117,0,0,1-2.085-2.147V10.616h-4.3a2.118,2.118,0,0,1,.061-4.235H6.383V2.147A2.117,2.117,0,0,1,8.468,0H8.53a2.117,2.117,0,0,1,2.088,2.147V6.382h4.3a2.118,2.118,0,0,1-.061,4.235H10.617v4.3A2.117,2.117,0,0,1,8.5,17Z"
              transform="translate(0)"
              fill="#687bfe"
            />
          </svg>
          <span>ADD NEW</span>
        </Button>
      </Typography>

      {isTabShow && (
        <EditExperienceModal
          expRecId={extension.recordId}
          expRecord={
            experienceRecords
              .filter(item => {
                return item.expRecId === extension.recordId;
              })
              .map(item => item)[0]
          }
          key={extension.recordId}
          saveDetailModalProps={props.saveDetailModalProps}
          onCancelChangesConfirm={props.onCancelChangesConfirm}
          setIsChanged={props.setIsChanged}
        />
      )}

      <ExperienceCardSection />
    </Typography>
  );
};

export default EditWorkExperience;
