import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import LeftSidebarStyle from "assets/jss/material-kit-pro-react/views/componentStyle/LeftSidebarStyle.js";
import hashTagStyle from "assets/jss/material-kit-pro-react/views/componentStyle/hashTagStyle.js";
import {
	Typography,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	Button,
} from "@material-ui/core";
import feedIcon from "assets/images/feed.png";
import companyIcon from "assets/images/companies.png";
import groupIcon from "assets/images/group.png";
import Bag from "assets/images/bag.png";
import followIcon from "assets/images/following.png";
import manageIcon from "assets/images/manage-connection.png";
import testimonialIcon from "assets/images/testimonial.png";
import documentationIcon from "assets/images/documentation.png";
import cvIcon from "assets/images/cv.png";
import profileImage from "assets/images/leftsidebar-profile.png";
import groupProfile from "assets/images/groupProfile.png";
import groupProfile1 from "assets/images/groupProfile1.png";
import { LinkedCameraRounded } from "@material-ui/icons";
import GridItem from "components/Grid/GridItem";
import { useSelector } from "react-redux";
import { encryptData } from "configurations/configurations";
import candidate_avatar from "assets/img/candidate_avatar.jpeg";

import IconLibrary from "./IconLibrary";

import FeedIcon from "assets/svg/feed.svg";
import bag from "assets/svg/bag.svg";
import Doc from "assets/svg/doc.svg";
import Follow from "assets/svg/follow.svg";
import Group from "assets/svg/group.svg";
import Job from "assets/svg/job.svg";
import Manage from "assets/svg/manage.svg";
import Resume from "assets/svg/resume.svg";
import Testimonial from "assets/svg/testimonial.svg";
import Video from "assets/svg/video.svg";
import BusinessIcon from "@mui/icons-material/Business";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards";
const useStyles = makeStyles(LeftSidebarStyle);
const hashtagStyles = makeStyles(hashTagStyle);

export default function LeftSidebar({ ...rest }) {
	const classes = useStyles();
	const hashtagClasses = hashtagStyles();

	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
	const user = useSelector((state) => state.auth.user);

	const [acctId, setAcctId] = useState();
	const location = useLocation();
	console.log('acctId =>', user.acctId)
	useEffect(() => {
		if (isAuthenticated) {
			const hashId = encryptData(user.acctId);
			setAcctId(hashId);
		}

		if (window.location.href && window.location.href.includes("https://www.")) {
			const newURL = window.location.href.replace("https://www.", "https://");
			window.location.replace(newURL);
		}
	}, [isAuthenticated]);

	return (
		<React.Fragment>
			<div className={classes.LeftSidebar_box} id="socialFeedLeftBar">
				{/* <Typography component="div" className={classes.profileCard}> */}
				<Typography
					component={Link}
					to={`/view-profile/${acctId}`}
					className={classes.profileCard}
				>
					<Typography component="div" className={classes.profileCard_image}>
						<img
							style={{ width: "100%" }}
							src={user.profilePic ? user.profilePic : candidate_avatar}
							alt=""
						/>
					</Typography>
					<Typography component="div">
						<h5 style={{ fontSize: "14px", fontWeight: 600, color: "#000", margin: "0px", fontFamily: "Poppins, sans-serif", }}>
							{user.firstName ? user.firstName : ""}
							{user.middleName ? " " + user.middleName : ""}
							{user.surname ? " " + user.surname : ""}
						</h5>
						{/* <span className="customspan">@fawait</span> */}
					</Typography>
				</Typography>
				{/* </Typography> */}

				<Typography className={classes.sideBarMenuBx} component="div">
					<h6 className='customSix'>Corporate</h6>
					<List component="nav" className="customnav">
						<ListItem
							className={
								location.pathname.includes(`/biz-page/`)
									? classes.currentLinkItems
									: classes.navLinkItems
							}
							component={Link}
							to={`/biz-page/${acctId}`}
						>
							<ListItemIcon>
								{/* <BusinessIcon /> */}
								<img src={bag} />
							</ListItemIcon>
							<ListItemText primary="Business Pages" />
						</ListItem>
					</List>
				</Typography>

				<Typography className={classes.sideBarMenuBx} component="div">
					<h6 className='customSix'>Networking</h6>
					<List component="nav" className="customnav">
						<ListItem
							component={Link}
							to="/social-feed/1/0"
							className={
								location.pathname.includes(`/social-feed`)
									? classes.currentLinkItems
									: classes.navLinkItems
							}
						>
							<ListItemIcon>
								<img src={FeedIcon} />
							</ListItemIcon>
							<ListItemText primary="Feed" />
						</ListItem>
						<ListItem
							component={Link}
							to="/resources"
							className={
								location.pathname.includes(`/resources`)
									? classes.currentLinkItems
									: classes.navLinkItems
							}
						>
							<ListItemIcon>
								<IconLibrary.SignBoard width={20} height={20} />
							</ListItemIcon>
							<ListItemText primary="Resource" />
						</ListItem>

						<ListItem
							component={Link}
							to={`/groups/${acctId}`}
							className={
								location.pathname.includes(`/groups/`) ||
									location.pathname.includes(`/my-groups`) ||
									location.pathname.includes(`/browse-groups`)
									? classes.currentLinkItems
									: classes.navLinkItems
							}
						>
							<ListItemIcon>
								{/* <WorkspacesIcon /> */}
								<img src={Group} />
							</ListItemIcon>
							<ListItemText primary="Groups" />
						</ListItem>
						<ListItem
							component={Link}
							to={`/following-page/${acctId}`}
							className={
								location.pathname.includes(`/following-page/`)
									? classes.currentLinkItems
									: classes.navLinkItems
							}
						>
							<ListItemIcon>
								{/* <BookmarkIcon /> */}
								<img src={Follow} />
							</ListItemIcon>
							<ListItemText primary="Following" />
						</ListItem>
					</List>
				</Typography>

				<Typography className={classes.sideBarMenuBx} component="div">
					<h6 className='customSix'>Your Profile</h6>
					<List component="nav" className="customnav">

						<ListItem
							className={
								location.pathname.includes(`/job-application-landing/`)
									? classes.currentLinkItems
									: classes.navLinkItems
							}
							component={Link}
							to={`/job-application-landing/${acctId}`}
						>
							<ListItemIcon>
								{/* <GroupAddIcon /> */}
								<img src={Bag} />
							</ListItemIcon>
							<ListItemText primary="Job Applications" />
						</ListItem>

						<ListItem
							className={
								location.pathname.includes(`/manage-connection/`)
									? classes.currentLinkItems
									: classes.navLinkItems
							}
							component={Link}
							to={`/manage-connection/${acctId}`}
						>
							<ListItemIcon>
								{/* <GroupAddIcon /> */}
								<img src={Manage} />
							</ListItemIcon>
							<ListItemText primary="Manage Connection" />
						</ListItem>
						<ListItem
							className={
								location.pathname.includes(`/testimonials/`)
									? classes.currentLinkItems
									: classes.navLinkItems
							}
							component={Link}
							to={`/testimonials/${acctId}`}
						>
							<ListItemIcon>
								{/* <IconLibrary.Jobs /> */}
								<img src={Testimonial} />
							</ListItemIcon>
							<ListItemText primary="Testimonials" />
						</ListItem>

						<ListItem className={
							location.pathname.includes(`/upload-attachment/video/`)
								? classes.currentLinkItems
								: classes.navLinkItems
						}
							component={Link}
							to={`/upload-attachment/video/${acctId}`}
						>
							<ListItemIcon>
								{/* <FormatQuoteIcon /> */}
								<img src={Video} />
							</ListItemIcon>
							<ListItemText primary="My Video" />
						</ListItem>

						<ListItem className={
							location.pathname.includes(`/upload-attachment/document/`)
								? classes.currentLinkItems
								: classes.navLinkItems
						}
							component={Link}
							to={`/upload-attachment/document/${acctId}`}
						>
							<ListItemIcon>
								{/* <FormatQuoteIcon /> */}
								<img src={Doc} />
							</ListItemIcon>
							<ListItemText primary="My Documents" />
						</ListItem>

						<ListItem
							className={
								location.pathname.includes(`/resume/`)
									? classes.currentLinkItems
									: classes.navLinkItems
							}
							component={Link}
							to={`/upload-attachment/resume/${acctId}`}
						>
							<ListItemIcon>
								{/* <FileOpenIcon /> */}
								<img src={Resume} />
							</ListItemIcon>
							<ListItemText primary="Resume" />

						</ListItem>
						<ListItem
							className={
								location.pathname.includes(`/subscription-settings/`)
									? classes.currentLinkItems
									: classes.navLinkItems
							}
							component={Link}
							to={`/subscription-settings/${encryptData(acctId)}`}
						>
							<ListItemIcon>
								{/* <FileOpenIcon /> */}
								<img src={Manage} />
							</ListItemIcon>
							<ListItemText primary="Subscription Settings" />

						</ListItem>
						{/* <ListItem
							className={
								location.pathname.includes(`/upload-videos/`)
									? classes.currentLinkItems
									: classes.navLinkItems
							}
							component={Link}
							to={`/upload-videos/${acctId}`}
						>
							<ListItemIcon>
								<FileOpenIcon />
							</ListItemIcon>
							<ListItemText primary="Your Videos" />

						</ListItem> */}
					</List>
				</Typography>

				<Typography className={classes.sideBarMenuBx} component="div">
					<Typography className={classes.sideBarGroupHead} component="div">
						<h6 className='customSix'>Your Groups</h6>
						<Button className="seeAll">See All</Button>
					</Typography>
					<List component="nav" className={classes.sideBarMenu}>
						<ListItem>
							<ListItemIcon>
								<img src={groupProfile1} alt="" />
							</ListItemIcon>
							<ListItemText className="grouptext" primary="Football FC" />
						</ListItem>
						<ListItem>
							<ListItemIcon>
								<img src={groupProfile1} alt="" />
							</ListItemIcon>
							<ListItemText className="grouptext" primary="Badminton Club" />
						</ListItem>
						<ListItem>
							<ListItemIcon>
								<img src={groupProfile1} alt="" />
							</ListItemIcon>
							<ListItemText className="grouptext" primary="UI/UX Community" />
						</ListItem>
						<ListItem>
							<ListItemIcon>
								<img src={groupProfile1} alt="" />
							</ListItemIcon>
							<ListItemText className="grouptext" primary="Web Designer" />
						</ListItem>
					</List>
				</Typography>
				<Typography className={classes.sideBarMenuBx} component="div">
					<Typography className={classes.sideBarGroupHead} component="div">
						<h6 className='customSix'>Your Hashtags</h6>
						<Button className="seeAll">See All</Button>
					</Typography>
					<List component="nav" className={classes.sideBarMenu}>
						<span className="hastags">#graduatejobs</span>
						<span className="hastags">#sg</span>
						<span className="hastags">#frsehgrad</span>
						<span className="hastags">#investmentbanking</span>
					</List>
				</Typography>
			</div>
		</React.Fragment>
	);
}
