import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import profilePageStyle from 'assets/jss/material-kit-pro-react/views/pageStyle/ProfilePage/profilePageStyle.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import { instanceUrl } from 'configurations/configurations.js';
import { deleteInterestIndustry, createInterestIndustry } from 'configurations/redux/userSlice.js';
import { makeStyles, Chip, TextField, Typography, Button, Box } from '@material-ui/core';
import axios from 'axios';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import ConfirmDialog from 'components/CustomDialog/ConfirmDialog.js';
import { deactivateEditType } from 'configurations/redux/editProfileModalSlice';
import { Formik } from 'formik';
const useStyles = makeStyles(profilePageStyle);

const filter = createFilterOptions();

export const InterestIndustrySection = ({ saveDetailModalProps, onCancelChangesConfirm, setIsChanged }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const interestIndustryList = useSelector(state => state.user.interestedIndustry);
  const ref0 = React.useRef(null);
  const cardBoxRef = React.useRef(null);
  const [industryMasterDataList, setIndustryMasterDataList] = React.useState([]);
  const [buttonVisible, setButtonVisible] = React.useState(false);

  const [industryKeyword, setIndustryKeyword] = React.useState([]);
  const user = useSelector(state => state.user);
  const extension = useSelector(state => state.editProfileModal.extension);

  const scrollToInterest = () => {
    cardBoxRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  };

  const deleteIndustry = value => () => {
    console.log(value);
    const requestProfile = {
      email: user.email,
      acctId: user.acctId,
      accessToken: localStorage.getItem('token'),
      intsId: value.intsId,
    };
    console.log(requestProfile);
    dispatch(deleteInterestIndustry(requestProfile));
  };
  const isMainModalCloseRequest = useSelector(state => state.editProfileModal.isMainModalCloseRequest);

  React.useEffect(() => {
    if (extension && extension.type === 'EDIT_INTEREST_INDUSTRY') {
      var interestContainer = document.getElementById('interestContainer');
    }
  }, [extension]);
  const cancelForm = {
    title: 'Discard Action',
    text: 'All the actions will be discarded',
    cancelText: 'No',
    confirmText: 'Yes',
  };
  React.useEffect(() => {
    let mounted = true;
    let CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchIndustryMasterDataList = async () => {
      try {
        const res = await axios.get(`${instanceUrl}/api/md/get-all-industry`, {
          cancelToken: source.token,
        });

        setIndustryMasterDataList(res.data.payload);
      } catch (error) {
        if (axios.isCancel(error)) {
          //console.log("unmount caused");
        } else {
          throw error;
        }
      }
    };
    if (mounted) {
      fetchIndustryMasterDataList();
    }

    return () => {
      mounted = false;
      source.cancel();
    };
  }, []);

  const IndustryList = () => {
    return (
      interestIndustryList[0] &&
      interestIndustryList.map(item => {
        return (
          <Chip
            key={item.intsId}
            label={
              <p className={classes.tagDescription}>
                {item.industryName ? item.industryName : item.customIndustryName}
              </p>
            }
            className={classes.chip}
            color={'secondary'}
            onDelete={deleteIndustry(item)}
          />
        );
      })
    );
  };
  const handleClick = () => {
    setButtonVisible(true);
  };

  const InterestIndustryTextBox = () => {
    return (
      <React.Fragment>
        <Formik
          initialValues={{
            interestList: [],
          }}
          onSubmit={data => {
            console.log(data);
            const testResult = data.interestList.map(item => {
              if (item.industryId) {
                const test = {
                  industryId: item.industryId,
                  customIndustryName: null,
                };
                return test;
              } else {
                const test = {
                  industryId: 0,
                  customIndustryName: item,
                };
                return test;
              }
            });
            const requestProfile = {
              email: user.email,
              acctId: user.acctId,
              accessToken: localStorage.getItem('token'),
              interestList: testResult,
            };
            console.log(requestProfile);
            dispatch(createInterestIndustry(requestProfile));
            const ele = ref0.current.querySelector('button');
            if (ele) {
              ele.click();
            }
          }}
        >
          {({ handleSubmit, setFieldValue, values, resetForm, dirty }) => {
            return (
              <form onSubmit={handleSubmit}>
                <GridContainer justifyContent="center">
                  <GridItem xs={12} className={classes.buttonHeader + ' ' + classes.gridItem}>
                    <h4 className={classes.title}>Industries Interested In</h4>
                    <Typography
                      component="div"
                      style={{
                        visibility: values.interestList.length > 0 ? 'visible' : 'hidden',
                      }}
                    >
                      <Button className={classes.saveProfileInformationBtn} onClick={handleSubmit}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 24 18">
                          <path
                            id="Path"
                            d="M7.636,14.239,1.909,8.6,0,10.478,7.636,18,24,1.881,22.091,0Z"
                            fill="#0ab98d"
                          />
                        </svg>
                        <span>SAVE</span>
                      </Button>
                    </Typography>
                  </GridItem>
                </GridContainer>

                <Card className={classes.infoCardBorder} style={{ scrollMargin: '30px' }}>
                  <CardBody>
                    <Box sx={{ marginBottom: '10px' }}>{interestIndustryList[0] && <IndustryList />}</Box>
                    <Autocomplete
                      options={industryMasterDataList}
                      freeSolo
                      multiple
                      ref={ref0}
                      id="interestInput"
                      getOptionLabel={option => (option.industryName ? option.industryName : option)}
                      onChange={(event, value, reason) => {
                        setFieldValue('interestList', value);
                      }}
                      getOptionDisabled={option =>
                        values.interestList.length >= 10 ? (option = true) : (option = false)
                      }
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            key={index}
                            label={
                              <p className={classes.tagDescription}>
                                {option.industryName ? option.industryName : option}
                              </p>
                            }
                            className={classes.chip}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      disabled={interestIndustryList.length >= 10 ? true : false}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label={'Interest Industry'}
                          variant="outlined"
                          id="interestInput"
                          inputProps={{
                            ...params.inputProps,
                            onKeyDown: e => {
                              if (e.key === 'Enter' && values.interestList.length >= 10) {
                                e.stopPropagation();
                              }
                            },
                            maxLength: 100,
                            disabled: values.interestList.length + interestIndustryList.length >= 10 ? true : false,
                          }}
                        />
                      )}
                    />
                  </CardBody>
                </Card>
                {/* <ConfirmDialog
                  {...{ ...saveDetailModalProps }}
                  bodyContent={cancelForm}
                  modalOpen={values.interestList.length > 0 && saveDetailModalProps.modalOpen}
                  handleSubmit={() => {
                    resetForm();
                    setButtonVisible(false);
                    if (isMainModalCloseRequest) {
                      dispatch(deactivateEditType());
                      saveDetailModalProps.setModalOpen(false);
                    } else {
                      onCancelChangesConfirm();
                    }
                  }}
                /> */}
              </form>
            );
          }}
        </Formik>
      </React.Fragment>
    );
  };
  return (
    <div ref={cardBoxRef}>
      <Card className={classes.cardFrame + ' ' + classes.tagFrame}>
        <CardBody>
          <InterestIndustryTextBox />
        </CardBody>
      </Card>
      <div id="interestContainer"></div>
    </div>
  );
};

export default InterestIndustrySection;
