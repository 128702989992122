import {
	container,
	cardTitle,
	title,
	mlAuto,
	mrAuto,
	main,
	mainRaised,
	grayColor,
	blackColor,
	hexToRgb,
	opptyDesignColor,
} from "assets/jss/material-kit-pro-react.js";
import customSelectStyle from "assets/jss/material-kit-pro-react/customSelectStyle.js";
import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js";
import customCheckboxRadioSwitchStyle from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.js";
import headersSection from "assets/jss/material-kit-pro-react/components/headerStyle.js";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.js";
const searchProfilePageStyle = (theme) => ({
	...headersSection(theme),
	...modalStyle(theme),
	...styles,
	...customSelectStyle,

	main,
	mainRaised,
	mlAuto,
	mrAuto,
	cardTitle,
	...title,
	...styles,
	...customCheckboxRadioSwitchStyle,
	...customSelectStyle,

	container: {
		...container,
		"@media (min-width: 576px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 768px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 992px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 1200px)": {
			maxWidth: "100%",
		},
		"@media (min-width: 1400px)": {
			maxWidth: "90vw",
		},
	},
	textCenter: {
		textAlign: "center",
	},
	avatarPadding: {
		marginTop: "10px",
	},
	parallax: {
		height: "20vh",
		backgroundPosition: "top center",
	},
	mainRaisedGrid: {
		margin: "-60px 0px 0px 0px",
		boxShadow: "",
	},
	cardImage: {
		maxWidth: "60px", //"130px",
		height: "60px",
		width: "100%",
		// height: "90px", //"130px",
		overflow: "hidden",
		padding: "0",
		borderRadius: "50%",
		/* boxShadow:
			"0 16px 38px -12px rgba(" +
			hexToRgb(blackColor) +
			", 0.56), 0 4px 25px 0px rgba(" +
			hexToRgb(blackColor) +
			", 0.12), 0 8px 10px -5px rgba(" +
			hexToRgb(blackColor) +
			", 0.2)", */
		///margin: "-50px auto 0",
		// [theme.breakpoints.up("lg")]: {
		// 	width: "140px",
		// 	height: "140px",
		// },
	},
	cardClick: {
		cursor: "pointer",
		"&:hover,&:focus": {
			//backgroundColor: grayColor[0],
			boxShadow:
				"0 14px 26px -12px rgba(" +
				hexToRgb(grayColor[0]) +
				", 0.42), 0 4px 23px 0px rgba(" +
				hexToRgb(blackColor) +
				", 0.12), 0 8px 10px -5px rgba(" +
				hexToRgb(grayColor[0]) +
				", 0.2)",
		},
	},
	bodyDiv: {
		paddingBottom: "60px",
	},
	cardHeight: {
		height: "360px",
		paddingTop: "15px",
		paddingBottom: "15px",
	},
	cardViewCount: {
		marginTop: "20px",
	},
	cardFooterBorder: {
		borderTop: "1px solid rgba(" + hexToRgb(blackColor) + ", 0.35)",
		padding: "0px",
	},
	cardResultSection: {
		paddingTop: "15px",
		paddingBottom: "15px",
		//overflowY: "scroll",
	},
	profileResult: {
		minHeight: "420px",
		height: "700px",
		overflowY: "scroll",
	},
	profileCardGrid: {
		/* 	marginLeft: "-10px",
		marginRight: "-10px", */
	},
	endMessage: {
		textAlign: "center",
	},
	cardBody: {
		[theme.breakpoints.down("sm")]: {
			margin: "-5px -15px -15px -15px",
		},
	},
	cardBodyPadding: {
		paddingRight: "10px",
		paddingLeft: "10px",
	},
	gridMargin: {
		marginLeft: "0px",
		marginRight: "0px",
	},
	gridPadding: {
		paddingRight: "10px",
		paddingLeft: "10px",
	},
	gridPaddingMobile: {
		paddingRight: "10px",
		paddingLeft: "10px",
	},
	searchBarHeight: {
		maxHeight: "75vh",
		overflowY: "auto",
		overflowX: "hidden",
	},
	footerHeight: {
		maxHeight: "70vh",
		overflowY: "auto",
		overflowX: "hidden",
	},
	searchCard: {
		position: "sticky",
		top: "125px",
		marginTop: "125px",
		borderRadius: "18px",
		boxShadow: "none",
		paddingTop: "15px",
		paddingBottom: "15px",
		[theme.breakpoints.down("sm")]: {
			paddingTop: "0",
			paddingBottom: "0",
			marginBottom: "0",
		},
	},
	searchCardBody: {
		// maxHeight: "80vh",
		// overflowX: "auto",
		[theme.breakpoints.down("sm")]: {
			paddingTop: "0",
			paddingBottom: "0",
		},
		padding: "0px 15px",
	},
	searchGrid: {
		marginTop: "95px",
		[theme.breakpoints.down("sm")]: {
			marginTop: "0",
		},
	},
	title: {
		...title,
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		position: "relative",
		marginTop: "5px", //"25px",
		marginBottom: "5px",
		minHeight: "0px", //"32px",
		margin: "5px 0",
		textDecoration: "none",
		verticalAlign: "middle",
		color: blackColor + "!important",
		fontSize: "16px",
		"& svg": {
			width: "32px",
			height: "32px",
			marginRight: "20px",
			marginTop: "10px",
		},
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap",
		[theme.breakpoints.down("xs")]: {
			marginTop: "0!important",
			marginBottom: "5px!important",
		},
	},
	description: {
		overflow: "hidden",
		margin: "15px 0px !important",
		/* maxWidth: "600px", */
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		color: blackColor,
		fontWeight: "400",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		"& p": {
			margin: "15px 0px",
		},
	},
	cardFrame: {
		borderRadius: "18px",
		boxShadow: "none",
		marginBottom: "10px",
	},
	itemCenter: {
		[theme.breakpoints.down("md")]: {
			alignItems: "center",
			justifyContent: "center",
		},
		display: "flex",
		marginBottom: "10px",
		/* paddingRight: "0", */
	},
	infoArea: {
		padding: "10px 0 5px 0",
		minHeight: "50px",
	},
	iconWrapper: {
		float: "left",
		width: "36px",
		height: "36px",
		color: grayColor[0] + "!important",
		marginTop: "10px",
		marginRight: "10px",
	},
	candidateDescription: {
		textDecoration: "none",
		//marginTop: "30px !important",
		color: grayColor[0] + "!important",
		fontFamily: `"Roboto", "Helvetica", sans-serif !important`,
		fontWeight: "400",
		fontSize: "14px !important",
		textAlign: "left",
		//padding: "0 !important",
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap",
	},
	gridHeight: {
		height: "90px",
		[theme.breakpoints.down("sm")]: {
			height: "70px",
		},
	},
	loadingImage: {
		width: "100%",
		height: "100%",
		borderRadius: "12px",
	},
	loadingContainer: {
		padding: "30px 0px",
		alignItems: "center",
		display: "flex",
		justifyContent: "center",
	},
	headlineHeight: {
		[theme.breakpoints.down("sm")]: {
			// minHeight: "82px",
		},

		display: "flex",
		verticalAlign: "middle",
		justifyContent: "flex-start",
		alignItems: "center",
		textAlign: "left",
	},
	buttonBorder: {
		borderRadius: "10px",
		[theme.breakpoints.down("sm")]: {
			borderRadius: "10px",
			padding: "10px 20px",
			float: "right",
		},
	},
	titleInfo: {
		minHeight: "1px",
		margin: "0!important",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		overflow: "hidden",
		[theme.breakpoints.down("sm")]: {
			fontSize: "15px",
			minHeight: "15px",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "13px",
			minHeight: "13px",
		},
	},
	titleLocation: {
		minHeight: "26px",
		margin: "0 0 10px 0!important",
		color: "#959595!important",
		fontWeight: "normal",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		overflow: "hidden",

		[theme.breakpoints.down("sm")]: {
			fontSize: "14px",
			minHeight: "18px",
		},

		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
			minHeight: "18px",
		},
	},
	descInfo: {
		minHeight: "19px",
		margin: "0!important",
		marginTop: "5px!important",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		overflow: "hidden",
		color: opptyDesignColor,
		fontWeight: "bold",
		fontSize: "14px",
		[theme.breakpoints.up("md")]: {
			paddingRight: "25px",
		},
		[theme.breakpoints.down("sm")]: {
			fontSize: "14px",
			minHeight: "18px",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "12px",
			minHeight: "18px",
		},
	},
	viewInfo: {
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap",
		[theme.breakpoints.down("sm")]: {
			fontSize: "15px!important",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "13px!important",
		},
	},
	grayFont: {
		color: "#99B2C6 !important",
	},
	followIcon: {
		[theme.breakpoints.down("xs")]: {
			width: "15px!important",
			height: "15px!important",
		},
	},
	cardSpace: {
		margin: "0 0 -25px 0",
	},
	buttonMessage: {
		borderRadius: "11px",
		/* padding: "5px 10px 5px 15px", */
		/* marginRight: "10px",
		[theme.breakpoints.down("xs")]: {
			padding: "0px 11px 1px 15px",
			borderRadius: "4px",
		}, */
		"& svg": {
			width: "25px !important",
			height: "25px !important",
			[theme.breakpoints.up("md")]: {
				marginRight: "10px",
			},
		},
		fontWeight: "700",
		textTransform: "none",
		fontSize: "15px",
		padding: "10px !important",
	},
	messageIcon: {
		[theme.breakpoints.down("xs")]: {
			overflow: "visible!important",
			padding: "0!important",
		},
	},
	gridRightLeftMargin: {
		padding: "0px 3px !important",
	},
	gridContainerPadding: {
		padding: "0px 10px !important",
	},
	itemRight: {
		display: "flex",
		justifyContent: "flex-end",
	},
	viewAreaStyle: {
		"& svg": {
			width: "20px !important",
			height: "20px !important",

			marginRight: "15px",
		},
		color: "#99B2C6 !important",
		display: "flex",
		alignItems: "center",
		fontWeight: "700",
		fontSize: "14px",
		margin: "15px 0px 0px 0px",
		[theme.breakpoints.down("md")]: {
			marginBottom: "15px",
		},
	},
	/* infoGrid: {
		[theme.breakpoints.up("md")]: {
			paddingLeft: "15px !important",
		},
	}, */
	endSearch: {
		margin: "35px 0px !important",
	},
	accordionTitle: {
		// padding: "0",
		borderBottom: "0",
	},
	accordionDetailClass: {
		padding: "0 0 0 20px",
	},
	accordionLabel: {
		cursor: "pointer",
		display: "inline-flex",
		alignItems: "center",
		marginLeft: "-16px",
		marginRight: "16px",
		verticalAlign: "middle",
		WebkitTapHighlightColor: "transparent",
		color: "#AAAAAA",
		fontWeight: "400",
	},
	accordionGrid: {
		width: "100%",
		paddingLeft: "24px",
	},
	autoCompleteSelect: {
		marginTop: "15px !important",
		marginBottom: "5px !important",
		[theme.breakpoints.down("sm")]: {
			marginTop: "0 !important",
			marginBottom: "15px !important",
		},
	},
	searchBarButton: {
		marginTop: "10px !important",
		/* marginBottom: "10px !important", */
		[theme.breakpoints.down("xs")]: {
			marginTop: "0!important",
			marginBottom: "0!important",
		},
	},
	searchCardDiv: {
		overflowX: "hidden",
	},
});
export default searchProfilePageStyle;
