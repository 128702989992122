import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { Alert, AlertTitle } from "@material-ui/lab";

import messageDashboardStyle from "assets/jss/material-kit-pro-react/views/pageStyle/MessagePage/messagePageStyle.js";
import MessageImage from "assets/img/Message_page.png";
import LoadingImage from "assets/img/Loading-pana.png";

const useStyles = makeStyles(messageDashboardStyle);

export default function MessageConversationDefault(children) {
	const classes = useStyles();
	return (
		<React.Fragment>
			<GridContainer justifyContent="center" alignContent="center" alignItems="center">
				<GridItem xs={12} sm={12} md={8} className={classes.defaultGrid}>
					{children.type === "default" ? (
						<div className={classes.defaultInfo}>
							<h2 className={classes.infoTitle}>Hi There!</h2>
							<h5 className={classes.infoDescription}>
								Start your conversation by selecting from the chat list!
							</h5>
							<img src={MessageImage} className={classes.infoImage} />
						</div>
					) : (
						<div className={classes.defaultInfo}>
							<img src={LoadingImage} className={classes.infoImage} />
						</div>
					)}
				</GridItem>
			</GridContainer>
		</React.Fragment>
	);
}
