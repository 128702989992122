import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { instanceUrl, encryptData, getMonthData, countMonthYear } from 'configurations/configurations.js';
import { updateApplicantStatus } from 'configurations/redux/jobManagementSlice.js';
import { createChatroom, createChatroomReset } from 'configurations/redux/messageChatroomSlice.js';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Chip } from '@material-ui/core';
import InfoArea from 'components/InfoArea/InfoArea.js';
import DialogActions from '@material-ui/core/DialogActions';
import SnackbarContent from 'components/Snackbar/SnackbarContent.js';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import Button from 'components/CustomButtons/Button.js';

import Close from '@material-ui/icons/Close';
import SchoolIcon from '@material-ui/icons/School';
import WorkIcon from '@material-ui/icons/Work';

import defaultAvatar from 'assets/img/candidate_avatar.jpeg';
import NoActivityStatusModal from '../NoActivityStatusModal/NoActivityStatusModal';
import Pagination from '../../Component/Pagination.js';

import corporateJobManagementPageStyle from 'assets/jss/material-kit-pro-react/views/pageStyle/CorporateJobManagementPage/corporateJobManagementPageStyle.js';
import { isDesktop } from 'react-device-detect';
import LockIcon from '@material-ui/icons/Lock';
import Hidden from '@material-ui/core/Hidden';
import IconLibrary from 'views/Component/IconLibrary';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles(corporateJobManagementPageStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ApplicantProfileModal({ open, onClose, selectedAppl }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userStatus = useSelector(state => state.auth.user);
  const applicantList = useSelector(state => state.jobManagement.applicantList);
  const monthDataList = getMonthData();
  const [countryList, setCountryList] = React.useState([]);
  const [areaCodeList, setAreaCodeList] = React.useState([]);
  const [locationList, setLocationList] = React.useState([]);
  const [studyFieldList, setStudyFieldList] = React.useState([]);
  const [educationLevelList, setEducationLevelList] = React.useState([]);
  const privacySetting = useSelector(state => state.user.privacySetting);

  const [currentEduPage, setCurrentEduPage] = React.useState(1);
  const [eduPerPage, setEduPerPage] = React.useState(1);
  const [currentExpPage, setCurrentExpPage] = React.useState(1);
  const [expPerPage, setExpPerPage] = React.useState(1);

  const isUpdated = useSelector(state => state.user.isUpdated);
  const isLoading = useSelector(state => state.user.isLoading);
  const user = useSelector(state => state.user);

  const experienceRecord = useSelector(state => state.user.experienceRecords);
  const educationRecord = useSelector(state => state.user.educationRecords);
  const chatroomId = useSelector(state => state.messageChatroom.chatroomId);
  const isCreated = useSelector(state => state.messageChatroom.isCreated);
  const bizId = useSelector(state => state.business.bizId);
  const indexOfLastEdu = currentEduPage * eduPerPage;
  const indexOfFirstEdu = indexOfLastEdu - eduPerPage;
  const currentEdu = educationRecord ? educationRecord.slice(indexOfFirstEdu, indexOfLastEdu) : null;

  const indexOfLastExp = currentExpPage * expPerPage;
  const indexOfFirstExp = indexOfLastExp - expPerPage;
  const currentExp = experienceRecord ? experienceRecord.slice(indexOfFirstExp, indexOfLastExp) : null;

  const eduPaginate = pageNumber => {
    setCurrentEduPage(pageNumber);
  };
  const expPaginate = pageNumber => {
    setCurrentExpPage(pageNumber);
  };
  const history = useHistory();
  const rejectProfile = async item => {
    const updateProfile = {
      acctId: userStatus.acctId,
      accessToken: localStorage.getItem('token'),
      email: userStatus.email,
      applicantId: item.acctId,
      applStatusId: 5,
      applId: applicantList
        .filter(list => {
          return list.acctId === item.acctId;
        })
        .map(list => {
          return list.applId;
        })[0],
    };
    console.log(updateProfile);
    dispatch(updateApplicantStatus(updateProfile));
  };
  const showProfile =
    privacySetting &&
    privacySetting
      .filter(item => {
        return item.settingName === 'showProfile';
      })
      .map(item => {
        return item.settingValue === 1;
      })[0];
  const showAboutMe =
    privacySetting &&
    privacySetting
      .filter(item => {
        return item.settingName === 'showAboutMe';
      })
      .map(item => {
        return item.settingValue === 1;
      })[0];
  const showEdu =
    privacySetting &&
    privacySetting
      .filter(item => {
        return item.settingName === 'showEdu';
      })
      .map(item => {
        return item.settingValue === 1;
      })[0];
  const showExp =
    privacySetting &&
    privacySetting
      .filter(item => {
        return item.settingName === 'showExp';
      })
      .map(item => {
        return item.settingValue === 1;
      })[0];

  const showHeadline =
    privacySetting &&
    privacySetting
      .filter(item => {
        return item.settingName === 'showHeadline';
      })
      .map(item => {
        return item.settingValue === 1;
      })[0];
  const showProfilePic =
    privacySetting &&
    privacySetting
      .filter(item => {
        return item.settingName === 'showProfilePic';
      })
      .map(item => {
        return item.settingValue === 1;
      })[0];

  const getMessageNavigate = async item => {
    // var middleName = "",
    // 	surname = "";
    // if (item.profileRecord.middleName.length > 0)
    // 	middleName = " " + item.profileRecord.middleName;
    // if (item.profileRecord.surname.length > 0)
    // 	surname = " " + item.profileRecord.surname;
    var chatroomList = [];
    chatroomList.push({ chatroomListId: -1, acctId: userStatus.acctId });
    chatroomList.push({ chatroomListId: -1, acctId: item.acctId });
    const messageApplicant = {
      acctId: userStatus.acctId,
      accessToken: localStorage.getItem('token'),
      email: userStatus.email,
      chatroomTitle: 'business_' + item.acctId + '_' + bizId,
      chatroomList: chatroomList,
      receiverId: item.acctId,
      applStatusId: 4,
      applId: applicantList
        .filter(list => {
          return list.acctId === item.acctId;
        })
        .map(list => {
          return list.applId;
        })[0],
      bizId: bizId,
      receiverBizId: 0,
      typeId: 2,
    };
    console.log(messageApplicant);
    dispatch(createChatroom(messageApplicant));

    if (
      (selectedAppl && selectedAppl.applicationStatusId <= 2) ||
      applicantList
        .filter(item => {
          return item.acctId === user.acctId;
        })
        .map(item => {
          return item.applicationStatusId <= 2;
        })[0]
    ) {
      const updateProfile = {
        acctId: userStatus.acctId,
        accessToken: localStorage.getItem('token'),
        email: userStatus.email,
        applicantId: item.acctId,
        applStatusId: 3,
        applId: applicantList
          .filter(list => {
            return list.acctId === item.acctId;
          })
          .map(list => {
            return list.applId;
          })[0],
      };
      console.log(updateProfile);
      dispatch(updateApplicantStatus(updateProfile));
      console.log('appl status updated');
    } else {
      console.log('no need update appl status');
    }
  };

  React.useEffect(() => {
    console.log(chatroomId);
    if (isCreated && chatroomId > 0) {
      // history.push("/message-page/C" + chatroomId + "/");
      window.open(`/message-page/0/C${chatroomId}/`, '_blank');
    }
    dispatch(createChatroomReset());
  }, [isCreated]);

  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgProfile,
    classes.companyModalLogo
  );
  const experienceCard = currentExp
    ? currentExp.map(experienceData => {
        return (
          <div key={experienceData.expRecId}>
            <Card className={classes.cardInfo}>
              <CardBody>
                <GridContainer justifyContent="space-between" alignContent="center" alignItems="center">
                  <GridItem>
                    <div className={classes.cardDetail}>
                      <div className={classes.descriptionContainer}>
                        <h4 className={classes.jobTitle}>{experienceData.title}</h4>
                        <h5 className={classes.smallDescription}>{experienceData.organization}</h5>
                      </div>

                      <div className={classes.descriptionContainer}>
                        <span className={classes.smallDescription + ' ' + classes.grayFontCard}>
                          <b>
                            {monthDataList
                              .filter(month => month.monthId === experienceData.startDate.substring(5, 7))
                              .map(month => month.monthName)}{' '}
                            {experienceData.startDate.substring(0, 4)}
                            {' - '}
                            {experienceData.currentInProgressFlag !== 1 &&
                              monthDataList
                                .filter(month => month.monthId === experienceData.endDate.substring(5, 7))
                                .map(month => month.monthName)}{' '}
                            {experienceData.currentInProgressFlag === 1
                              ? `Present`
                              : experienceData.endDate.substring(0, 4)}
                          </b>
                        </span>
                        <span
                          className={classes.smallDescription + ' ' + classes.mobileSpan + ' ' + classes.grayFontCard}
                        >
                          <b>
                            {countMonthYear(experienceData.startDate, experienceData.endDate) &&
                              ' • ' + countMonthYear(experienceData.startDate, experienceData.endDate)}
                          </b>
                        </span>

                        <p className={classes.smallDescription + ' ' + classes.grayFontCard}>
                          <b>
                            {countryList
                              .filter(item => {
                                return item.countryId === experienceData.countryId;
                              })
                              .map(item => {
                                return item.countryName;
                              })}
                          </b>
                        </p>
                      </div>
                    </div>
                  </GridItem>
                  <GridItem>
                    <div>
                      {experienceData.achievement ? (
                        <div>
                          <Divider />
                          <p className={classes.description}>{experienceData.achievement}</p>
                        </div>
                      ) : null}
                    </div>

                    <div>
                      {experienceData.description ? (
                        <div>
                          {experienceData.achievement ? null : <Divider />}
                          <p className={classes.description}>{experienceData.description}</p>
                        </div>
                      ) : null}
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </div>
        );
      })
    : null;
  const educationCard = currentEdu
    ? currentEdu.map(educationData => {
        return (
          <div key={educationData.eduRecId}>
            <Card className={classes.cardInfo}>
              <CardBody>
                <GridContainer justifyContent="space-between" alignContent="center" alignItems="center">
                  <GridItem>
                    <div className={classes.cardDetail}>
                      <h4 className={classes.titleDescription}>{educationData.school}</h4>
                      <div className={classes.descriptionContainer}>
                        <h5 className={classes.smallDescription}>
                          {educationLevelList
                            .filter(item => {
                              return item.eduLvlId === educationData.eduLvlId;
                            })
                            .map(item => {
                              return item.eduLvlName;
                            })}
                          {` of `}

                          {educationData.courseName}
                        </h5>
                        <h5 className={classes.mediumDescription}>
                          {studyFieldList
                            .filter(item => {
                              return item.studyFieldId === educationData.studyFieldId;
                            })
                            .map(item => {
                              return item.studyFieldName;
                            })}{' '}
                        </h5>
                      </div>

                      <div className={classes.descriptionContainer}>
                        <span className={classes.smallDescription + ' ' + classes.grayFontCard}>
                          <b>
                            {monthDataList
                              .filter(month => month.monthId === educationData.startDate.substring(5, 7))
                              .map(month => month.monthName)}{' '}
                            {educationData.startDate.substring(0, 4)}
                            {' - '}
                            {educationData.currentInProgressFlag !== 1 &&
                              monthDataList
                                .filter(month => month.monthId === educationData.endDate.substring(5, 7))
                                .map(month => month.monthName)}{' '}
                            {educationData.currentInProgressFlag === 1
                              ? `Present`
                              : educationData.endDate.substring(0, 4)}
                          </b>
                        </span>

                        <span
                          className={classes.smallDescription + ' ' + classes.mobileSpan + ' ' + classes.grayFontCard}
                        >
                          {' '}
                          <b>
                            {countMonthYear(educationData.startDate, educationData.endDate) &&
                              ' • ' + countMonthYear(educationData.startDate, educationData.endDate)}
                          </b>
                        </span>

                        <p className={classes.smallDescription + ' ' + classes.grayFontCard}>
                          <b>
                            {countryList
                              .filter(item => {
                                return item.countryId === educationData.countryId;
                              })
                              .map(item => {
                                return item.countryName;
                              })}
                          </b>
                        </p>
                      </div>

                      {/* <span className={classes.divider}>{"  |  "}</span> */}
                    </div>
                  </GridItem>

                  <GridItem>
                    <div>
                      {educationData.achievement ? (
                        <div className={classes.description}>
                          <Divider />
                          <p className={classes.description}>{educationData.achievement}</p>
                        </div>
                      ) : null}
                    </div>

                    <div>
                      {educationData.description ? (
                        <div className={classes.description}>
                          {educationData.achievement ? null : <Divider />}
                          <p className={classes.description}>{educationData.description}</p>
                        </div>
                      ) : null}
                    </div>

                    <div>
                      {educationData.extraCurricular ? (
                        <div className={classes.description}>
                          {educationData.achievement ? null : educationData.description ? null : <Divider />}
                          <p className={classes.description}>{educationData.extraCurricular}</p>
                        </div>
                      ) : null}
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </div>
        );
      })
    : null;
  React.useEffect(() => {
    let mounted = true;
    let CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchCountryList = async () => {
      try {
        const res = await axios.get(`${instanceUrl}/api/md/get-all-country`, {
          cancelToken: source.token,
        });
        setCountryList(res.data.payload);
      } catch (error) {
        if (axios.isCancel(error)) {
          //console.log("unmount caused");
        } else {
          throw error;
        }
      }
    };
    const fetchAreaList = async () => {
      try {
        const res = await axios.get(`${instanceUrl}/api/md/get-all-area-code`, {
          cancelToken: source.token,
        });
        setAreaCodeList(res.data.payload);
      } catch (error) {
        if (axios.isCancel(error)) {
          //console.log("unmount caused");
        } else {
          throw error;
        }
      }
    };
    const fetchLocationList = async () => {
      try {
        const res = await axios.get(`${instanceUrl}/api/md/get-all-location`, {
          cancelToken: source.token,
        });
        setLocationList(res.data.payload);
      } catch (error) {
        if (axios.isCancel(error)) {
          //console.log("unmount caused");
        } else {
          throw error;
        }
      }
    };
    const fetchStudyFieldList = async () => {
      try {
        const res = await axios.get(`${instanceUrl}/api/md/get-all-study-field`, {
          cancelToken: source.token,
        });
        setStudyFieldList(res.data.payload);
      } catch (error) {
        if (axios.isCancel(error)) {
          //console.log("unmount caused");
        } else {
          throw error;
        }
      }
    };
    const fetchEducationLevelList = async () => {
      try {
        const res = await axios.get(`${instanceUrl}/api/md/get-all-education-level`, {
          cancelToken: source.token,
        });
        setEducationLevelList(res.data.payload);
      } catch (error) {
        if (axios.isCancel(error)) {
          //console.log("unmount caused");
        } else {
          throw error;
        }
      }
    };
    if (mounted) {
      fetchCountryList();
      fetchAreaList();
      fetchLocationList();
      fetchStudyFieldList();
      fetchEducationLevelList();
    }

    return () => {
      mounted = false;
      source.cancel();
    };
  }, []);

  const modalResizeApplicant = () => {
    // var timer = setInterval(() => {
    var modalResize = document.getElementById('modalApplicant');
    if (modalResize) {
      // clearInterval(timer);
      var maxSize = window.innerHeight - 74 - 40 - 70;
      if (maxSize < 0) maxSize = 0;
      modalResize.style.height = maxSize + 'px';
      // } else {
      // 	setTimeout(() => {
      // 		clearInterval(timer);
      // 	}, 3000);
    }
    // }, 300);
    // return () => clearInterval(timer);
  };

  React.useEffect(() => {
    if (open) {
      if (document.readyState === 'complete') {
        setTimeout(() => {
          modalResizeApplicant();
        }, 500);
      }
      window.addEventListener('resize', modalResizeApplicant);
      return function cleanup() {
        window.removeEventListener('resize', modalResizeApplicant);
      };
    }
  }, [open]);
  // React.useEffect(() => {
  // 	window.addEventListener("resize", modalResize);
  // });

  // React.useEffect(() => {
  // 	if (open) {
  // 		modalResize();
  // 	} else {
  // 		try {
  // 			window.removeEventListener("resize");
  // 		} catch (error) {}
  // 	}
  // 	return function cleanup() {
  // 		try {
  // 			window.removeEventListener("resize");
  // 		} catch (error) {}
  // 	};
  // }, [open]);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const snackBarShow = (message, variant, action) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, {
      variant: variant,
      action,
    });
  };
  const onClickDismiss = key => () => {
    closeSnackbar(key);
  };

  const snackbarCustom = title => {
    const action = key => (
      <React.Fragment>
        <IconButton onClick={onClickDismiss(key)}>
          <CloseIcon />
        </IconButton>
      </React.Fragment>
    );
    snackBarShow(title, 'default', action);
  };

  React.useEffect(() => {
    console.log(applicantList);

    var test = applicantList
      .filter(item => {
        return item.acctId === user.acctId;
      })
      .map(item => {
        return item.applicationStatusId === 5;
      })[0];
    if (test && selectedAppl.applicationStatusId !== 5) snackbarCustom('The Applicant has been rejected.');
    console.log(test);
  }, [applicantList]);

  return (
    <div>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modalProfileEdit + ' ' + classes.modal,
        }}
        open={open}
        // TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle id="signup-modal-slide-title" disableTypography className={classes.modalTitleEdit}>
          <Button simple className={classes.modalCloseButton} key="close" aria-label="Close" onClick={onClose}>
            {' '}
            <IconLibrary.Close />
          </Button>
          <p className={classes.jobCreateTitle + ' ' + classes.titleAlign}>Profile Preview</p>
        </DialogTitle>
        <div
          className={
            isDesktop
              ? classes.modalBodyHeightJobDesktop + ' ' + classes.modalBackgroundTransition
              : classes.modalBodyHeightJob + ' ' + classes.modalBackgroundTransition
          }
          id="modalApplicant"
        >
          <DialogContent className={classes.modalBody}>
            <GridContainer justifyContent="center" alignContent="center" alignItems="center">
              {isLoading ? (
                <GridItem xs={12} sm={12} md={2}>
                  <CircularProgress />
                </GridItem>
              ) : (
                <React.Fragment>
                  {isUpdated ? (
                    <GridItem>
                      <Card className={classes.cardFrame}>
                        <CardBody className={classes.cardBody}>
                          <GridContainer justifyContent="center" className={classes.verticalAligned}>
                            <GridItem xs={3} sm={2} md={2} className={classes.itemCenter}>
                              <Link
                                className={classes.textDeco}
                                target="_blank"
                                to={{
                                  pathname: `/view-profile/${encryptData(user.acctId)}`,
                                }}
                                color="transparent"
                              >
                                {showProfile ? (
                                  showProfilePic ? (
                                    <img
                                      src={user.profilePic ? user.profilePic : defaultAvatar}
                                      alt="..."
                                      // className={imageClasses}
                                      className={classes.cardImage + ' ' + classes.cardImageCandidate}
                                    />
                                  ) : (
                                    <img
                                      src={defaultAvatar}
                                      alt="..."
                                      // className={imageClasses}
                                      className={classes.cardImage + ' ' + classes.cardImageCandidate}
                                    />
                                  )
                                ) : (
                                  <img
                                    src={defaultAvatar}
                                    alt="..."
                                    // className={imageClasses}
                                    className={classes.cardImage + ' ' + classes.cardImageCandidate}
                                  />
                                )}
                              </Link>
                            </GridItem>
                            <GridItem xs={9} sm={6} md={7}>
                              {showProfile ? (
                                <React.Fragment>
                                  <Link
                                    className={classes.textDeco}
                                    target="_blank"
                                    to={{
                                      pathname: `/view-profile/${encryptData(user.acctId)}`,
                                    }}
                                    color="transparent"
                                  >
                                    <h4 className={classes.title + ' ' + classes.titleInfo}>
                                      {user.profileRecord.firstName}
                                      {user.profileRecord.middleName
                                        ? ' ' + user.profileRecord.middleName + ' ,'
                                        : null}{' '}
                                      {user.profileRecord.surname}
                                    </h4>
                                  </Link>

                                  <h5 className={classes.title + ' ' + classes.titleLocation}>
                                    {countryList
                                      .filter(item => {
                                        return item.countryId === user.profileRecord.countryId;
                                      })
                                      .map(item => {
                                        return item.countryName;
                                      })}

                                    {user.profileRecord.locationId !== 0 &&
                                      user.profileRecord.locationId &&
                                      ', ' +
                                        locationList
                                          .filter(item => {
                                            return item.locationId === user.profileRecord.locationId;
                                          })
                                          .map(item => {
                                            return item.locationName;
                                          })}
                                  </h5>
                                  <Hidden xsDown>
                                    {showHeadline ? (
                                      <h5 className={classes.description + ' ' + classes.descInfo}>
                                        {user.profileRecord.headline}
                                      </h5>
                                    ) : (
                                      <h5 className={classes.title + ' ' + classes.descInfo}>
                                        "{user.profileRecord.firstName}" is not yet ready to show "Headline"
                                      </h5>
                                    )}
                                  </Hidden>
                                </React.Fragment>
                              ) : (
                                <Link
                                  className={classes.textDeco}
                                  target="_blank"
                                  to={{
                                    pathname: `/view-profile/${encryptData(user.acctId)}`,
                                  }}
                                  color="transparent"
                                >
                                  <h4 className={classes.title + ' ' + classes.titleInfo}>
                                    {user.profileRecord.firstName}
                                    {user.profileRecord.middleName
                                      ? ' ' + user.profileRecord.middleName + ' ,'
                                      : null}{' '}
                                    {user.profileRecord.surname}
                                  </h4>
                                </Link>
                              )}
                            </GridItem>
                            <Hidden smUp>
                              <GridItem xs={12} sm={false} md={false}>
                                {showHeadline ? (
                                  <h5
                                    className={
                                      classes.description + ' ' + classes.descInfo + ' ' + classes.descInfoMobile
                                    }
                                  >
                                    {user.profileRecord.headline}
                                  </h5>
                                ) : (
                                  <h5 className={classes.title + ' ' + classes.descInfo + ' ' + classes.descInfoMobile}>
                                    "{user.profileRecord.firstName}" is not yet ready to show "Headline"
                                  </h5>
                                )}
                              </GridItem>
                            </Hidden>
                            <GridItem xs={12} sm={4} md={3}>
                              <GridContainer>
                                <GridItem xs={6} sm={12} md={12} className={classes.cardBody}>
                                  <Link
                                    className={classes.textDeco}
                                    target="_blank"
                                    to={{
                                      pathname: `/view-profile/${encryptData(user.acctId)}`,
                                    }}
                                    color="transparent"
                                  >
                                    <Button fullWidth round color="grayWrapBorder" className={classes.buttonModal}>
                                      <b>View Profile</b>
                                    </Button>
                                  </Link>
                                </GridItem>
                                <GridItem xs={6} sm={12} md={12} className={classes.cardBody}>
                                  <Button
                                    fullWidth
                                    round
                                    color="opptyWrap"
                                    onClick={() => {
                                      getMessageNavigate(user);
                                    }}
                                    className={classes.buttonModal}
                                  >
                                    <b>Message</b>
                                  </Button>
                                </GridItem>
                              </GridContainer>
                            </GridItem>
                          </GridContainer>
                        </CardBody>
                      </Card>
                      {showProfile ? (
                        <React.Fragment>
                          {/**About Me Component */}
                          {user.profileRecord.aboutMe && (
                            <Card className={classes.cardFrame}>
                              <CardBody>
                                <GridContainer>
                                  <GridItem>
                                    {showAboutMe ? (
                                      <React.Fragment>
                                        <h5 className={classes.jobCreateTitle}>About Me</h5>
                                        <h5 className={classes.description}>{user.profileRecord.aboutMe}</h5>
                                      </React.Fragment>
                                    ) : (
                                      <div className={classes.privacyTitle}>
                                        <h5 className={classes.description}>
                                          <LockIcon />
                                          <br />"{user.profileRecord.firstName}" is not yet ready to show "About Me"
                                        </h5>
                                      </div>
                                    )}
                                  </GridItem>
                                </GridContainer>
                              </CardBody>
                            </Card>
                          )}

                          {/**Experiecne Section */}
                          {experienceRecord.length > 0 ? (
                            <React.Fragment>
                              <Card className={classes.cardFrame}>
                                {/* <CardBody>
																		<h5 className={classes.title}>
																			Work Experiences
																		</h5>
																		{experienceCard}
																		<Pagination
																			totalPosts={experienceRecord.length}
																			postsPerPage={expPerPage}
																			paginate={expPaginate}
																		/>
																	</CardBody> */}
                                <CardBody>
                                  <GridContainer className={classes.verticalAligned}>
                                    {showExp ? (
                                      <GridItem xs={12} sm={6}>
                                        <h5 className={classes.jobCreateTitle}>Work Experiences</h5>
                                      </GridItem>
                                    ) : null}

                                    {showExp && (
                                      <GridItem xs={12} sm={6}>
                                        <Hidden xsDown>
                                          <div className={classes.paginationAligned}>
                                            <Pagination
                                              totalPosts={experienceRecord.length}
                                              postsPerPage={expPerPage}
                                              paginate={expPaginate}
                                            />
                                          </div>
                                        </Hidden>
                                      </GridItem>
                                    )}
                                  </GridContainer>
                                  {showExp ? (
                                    <div>
                                      {experienceCard}
                                      <Hidden smUp className={classes.verticalAligned}>
                                        <div className={classes.paginationAligned}>
                                          <Pagination
                                            totalPosts={experienceRecord.length}
                                            postsPerPage={expPerPage}
                                            paginate={expPaginate}
                                          />
                                        </div>
                                      </Hidden>
                                    </div>
                                  ) : (
                                    <div className={classes.privacyTitle}>
                                      <h5 className={classes.description}>
                                        <LockIcon />
                                        <br />"{user.profileRecord.firstName}" is not yet ready to show "Experience"
                                      </h5>
                                    </div>
                                  )}
                                </CardBody>
                              </Card>
                            </React.Fragment>
                          ) : (
                            <Card className={classes.cardFrame}>
                              <CardBody>
                                <h5 className={classes.jobCreateTitle}>No Experience Records</h5>
                              </CardBody>
                            </Card>
                          )}
                          {/**Education Component */}
                          {educationRecord.length > 0 ? (
                            <React.Fragment>
                              <Card className={classes.cardFrame}>
                                <CardBody>
                                  <GridContainer>
                                    {/* <GridItem>
																				<h5 className={classes.title}>
																					Education
																				</h5>
																				{educationCard}{" "}
																				<Pagination
																					totalPosts={educationRecord.length}
																					postsPerPage={eduPerPage}
																					paginate={eduPaginate}
																				/>
																			</GridItem> */}
                                    <GridItem>
                                      <GridContainer className={classes.verticalAligned}>
                                        {showEdu ? (
                                          <GridItem xs={12} sm={6}>
                                            <h5 className={classes.jobCreateTitle}>Education</h5>
                                          </GridItem>
                                        ) : null}

                                        {showEdu && (
                                          <GridItem xs={12} sm={6}>
                                            <Hidden xsDown>
                                              <div className={classes.paginationAligned}>
                                                <Pagination
                                                  totalPosts={educationRecord.length}
                                                  postsPerPage={eduPerPage}
                                                  paginate={eduPaginate}
                                                />
                                              </div>
                                            </Hidden>
                                          </GridItem>
                                        )}
                                      </GridContainer>
                                      {showEdu ? (
                                        <div>
                                          {educationCard}
                                          <Hidden smUp className={classes.verticalAligned}>
                                            <div className={classes.paginationAligned}>
                                              <Pagination
                                                totalPosts={educationRecord.length}
                                                postsPerPage={eduPerPage}
                                                paginate={eduPaginate}
                                              />
                                            </div>
                                          </Hidden>
                                        </div>
                                      ) : (
                                        <div className={classes.privacyTitle}>
                                          <h5 className={classes.description}>
                                            <LockIcon />
                                            <br />"{user.profileRecord.firstName}" is not yet ready to show "Education"
                                          </h5>
                                        </div>
                                      )}
                                    </GridItem>
                                  </GridContainer>
                                </CardBody>
                              </Card>
                            </React.Fragment>
                          ) : (
                            <Card className={classes.cardFrame}>
                              <CardBody>
                                <h5 className={classes.jobCreateTitle}>No Education Records</h5>
                              </CardBody>
                            </Card>
                          )}
                          {/**HashTag Component */}
                          {user.hashtags[0] && (
                            <Card className={classes.cardFrame}>
                              <CardBody>
                                <GridContainer>
                                  <GridItem>
                                    <h5 className={classes.jobCreateTitle}>Skills And Attributes</h5>
                                    {showProfile ? (
                                      user.hashtags.map((item, index) => {
                                        return (
                                          <Chip
                                            key={index}
                                            label={<p className={classes.hashtagDescription}>{item.hashtag}</p>}
                                            className={classes.chip}
                                            color="secondary"
                                          />
                                        );
                                      })
                                    ) : (
                                      <h5 className={classes.title}>Profile is currently private.</h5>
                                    )}
                                  </GridItem>
                                </GridContainer>
                              </CardBody>
                            </Card>
                          )}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <Card className={classes.cardFrame}>
                            <CardBody>
                              <h5 className={classes.title + ' ' + classes.privacyTitle + ' ' + classes.textCenter}>
                                Profile is currently private.
                              </h5>
                            </CardBody>
                          </Card>
                        </React.Fragment>
                      )}
                    </GridItem>
                  ) : (
                    <NoActivityStatusModal />
                  )}
                </React.Fragment>
              )}
            </GridContainer>
          </DialogContent>
        </div>
        <DialogActions className={classes.modalFooterPrivacy + ' ' + classes.modalFooter}>
          <Button
            onClick={() => {
              rejectProfile(user);
            }}
            disabled={
              selectedAppl
                ? selectedAppl.applicationStatusId === 5
                : applicantList
                    .filter(item => {
                      return item.acctId === user.acctId;
                    })
                    .map(item => {
                      return item.applicationStatusId === 5;
                    })[0]
            }
            round
            color="danger"

            /* 	onClick={() => applyJob()} */
          >
            <b>Reject</b>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
