import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import ConnectionCard from "../Component/ConnectionCard.js";

import { makeStyles } from "@material-ui/core";

import axios from "axios";
import { instanceUrl } from "configurations/configurations.js";

import PendingRequestStyle from "assets/jss/material-kit-pro-react/components/pendingRequestStyle";

const useStyles = makeStyles(PendingRequestStyle);

export default function PendinRequest({ onClick }) {
  const classes = useStyles();
  const userStatus = useSelector((state) => state.auth.user);

  const [requests, setRequests] = useState([]);

  useEffect(() => {
    let mounted = true;
    let CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    if (mounted) {
      axios.get(`${instanceUrl}/api/um/get-connection-profile-by-acct-id?email=${userStatus.email}&acctId=${userStatus.acctId}&accessToken=${localStorage.getItem("token")}&lastRecordSize=0&requestRecordSize=50&statusId=0`)
        .then((res) => {
          if (!res.data.error) {
            setRequests(res.data.payload.connectionList);
          } else {
            console.log(res.data.message);
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log("unmount caused", error);
          } else {
            throw error;
          }
        })
    }
    return () => {
      source.cancel();
      mounted = false;
    };
  }, []);

  return (
    <div>
      <div className={classes.titleContainer}>
        <text className={classes.title}>Pending Connection Requests</text>
      </div>
      <div className={classes.cardsContainer}>
        {
          requests?.map((item, index) => {
            return (
              <ConnectionCard
                key={index}
                onClick={() => {
                  onClick(item.connId)
                }}
                firstName={item.firstName}
                lastName={item.lastName}
                headline={item.headline}
                action={"Cancel"}
                isDropdown={false}
                style={{ marginLeft: 30 }}
                image={item.profilePic}
              />
            )
          })
        }
      </div>
    </div>
  );
}
